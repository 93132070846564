import React, { FC, useState } from "react";

import {
  Stack,
  Text,
  ITextStyles,
  mergeStyles,
  IStackStyles,
  DefaultPalette,
    Icon
} from "@fluentui/react";

const labelStyle: ITextStyles = {
  root: {
    fontWeight: "bold",
  },
};

const labelStyleHovered: ITextStyles = {
  root: {
    fontWeight: "bold",
    color: DefaultPalette.themePrimary,
  },
};

const stackStyles: IStackStyles = {
  root: {
    cursor: "pointer",
    height: 30,
  },
};

const iconClass = mergeStyles({
  marginRight: 15,
  fontSize: 20,
});

const iconClassHovered = mergeStyles({
    marginRight: 15,
    fontSize: 20,
    color: DefaultPalette.themePrimary
});

interface IProps {
  iconName: string;
  label: string;
  onClick: () => void;
}

const PersonCalloutListItem: FC<IProps> = ({ iconName, label, onClick }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Stack
      onMouseLeave={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}
      horizontal
      verticalAlign="center"
      styles={stackStyles}
      onClick={onClick}
    >
      <Icon
        iconName={iconName}
        className={hovered ? iconClassHovered : iconClass}
      />
      <Text variant="large" styles={hovered ? labelStyleHovered : labelStyle}>
        {label}
      </Text>
    </Stack>
  );
};

export default PersonCalloutListItem;
