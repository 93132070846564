import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    //PrimaryButton, //FontIcon, 
    //IButtonStyles,
    //DefaultButton
} from "@fluentui/react";
import { DealerProfilePivot } from "pages/dealerProfilePage/pivot/DealerProfilePivot";
import { Stack } from "@fluentui/react/lib/Stack";
//import {
//  IPersonaSharedProps,
//  Persona,
//  PersonaSize,
//  PersonaPresence,
//} from "@fluentui/react/lib/Persona";
import { styles } from "./styles";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
//import { formatPathname } from "utils/helperFunctions";
//import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import {
  handleGetUserProfile,
  handleUpdateDealerProfile,
} from "store/userProfile/userProfile.action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import * as Yup from "yup";
//import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
//import { handleUploadUserPhoto } from "store/userProfile/userProfile.action";
//import { styles as acStyles } from "pages/accountSettingsPage/styles";
import { validURL } from "utils/helperFunctions";
import { validTenDigitPhone } from "utils/helperFunctions";
import { CustomFontText } from "components/customFontText/CustomFontText";

//const buttonStyle: IButtonStyles = {
  //root: {
    //paddingTop: "20px !important",
    //paddingBottom: "20px !important",
  //},
//};

const DealerProfilePage = () => {
  //const [pathname, setPathname] = useState("");
  const [hideButton, setHideButton] = useState(true);
  //const location = useLocation();
  //const updateDealerProfileLoading = useSelector(
    //(state: AppState) => state.userProfile.updateDealerProfileLoading
  //);
  const dispatch = useDispatch();
  const loggedUserId = useSelector(
    (state: AppState) => state.auth.loggedUserId
  );
  const dealerProfile = useSelector(
    (state: AppState) => state.userProfile.userProfile.dealerProfile
  );
    
  //const userProfile = useSelector(
  //  (state: AppState) => state.userProfile.userProfile
  //);
  //const fileSelectionHandler = (e) => {
  //    const uploadedFile = e.target.files[0];

  //    const formData = new FormData();
  //    formData.append("avatar", uploadedFile, "test.jpeg");

  //    dispatch<any>(handleUploadUserPhoto(loggedUserId, formData));
  //};
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
      return this.matches(/^\d+$/, 'Numerals only allowed')
  });
  // const loading = useSelector((state: AppState) => state.userProfile.loading);
  const formik = useFormik({
    initialValues: {
      legalName: "",
      companyAlias: [],
      einNumber: "",
      physicalAddress1: "",
      physicalAddress2: "",
      zip: "",
      city: "",
      county:"",
      state: "",
      floorPlanCompanyName: "",
      phoneNumber: "",
      faxNumber: "",
      website: "",
      mailingAddress1: "",
      mailingAddress2: "",
      mailingZip: "",
      mailingCity: "",
      mailingCounty: "",
      mailingState: "",
    },
    validationSchema: Yup.object({
      legalName: Yup.string().required("Required"),
      companyAlias: Yup.array(),
      einNumber: Yup.string()
                    .test("len", "Invalid EIN.", (val) => {
                        if (val !== undefined) {
                            const val_length_without_dashes = val.replace(/-|_/g, "").length;
                            if (val_length_without_dashes > 0)
                                return val_length_without_dashes >= 9;
                        }
                        return true;
                    })
                    .required("Required"),
      physicalAddress1: Yup.string().required("Required"),
      physicalAddress2: Yup.string(),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits").required("Required"),
      city: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      floorPlanCompanyName: Yup.string(),
      phoneNumber: Yup.string()
                      .test("len", "Invalid phone number", (val) => {
                          if (val === undefined)
                              return true;
                          return validTenDigitPhone(val)
                      })  
                      .required("Required"),
      faxNumber: Yup.string().digitsOnly().min(10, "Fax number must be 10 digit").max(10,"Fax number must be 10 digit"),
      website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),
      mailingAddress1: Yup.string(),
      mailingAddress2: Yup.string(),
      mailingZip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits"),
      mailingCity: Yup.string(),
      mailingCounty: Yup.string(),
      mailingState: Yup.string(),
      creditApplicationRedirect: Yup.string(),
      stateInspectionId: Yup.number()
    }),
    onSubmit: (values) => {
        
        values.einNumber = values.einNumber.replace(/-|_/g, "");
        values.phoneNumber = values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10);
      dispatch<any>(handleUpdateDealerProfile(loggedUserId, values));
    },
  });

  React.useEffect(() => {
    formik.setValues({
      legalName: dealerProfile?.legalName,
      companyAlias: dealerProfile?.companyAlias,
      einNumber: dealerProfile?.einNumber,
      physicalAddress1: dealerProfile?.physicalAddress1,
      physicalAddress2: dealerProfile?.physicalAddress2,
      zip: dealerProfile?.zip,
      city: dealerProfile?.city,
      county: dealerProfile?.county,
      state: dealerProfile?.state,
      floorPlanCompanyName: dealerProfile?.floorPlanCompanyName,
      phoneNumber: dealerProfile?.phoneNumber,
      faxNumber: dealerProfile?.faxNumber,
      website: dealerProfile?.website,
      mailingAddress1: dealerProfile?.mailingAddress1,
      mailingAddress2: dealerProfile?.mailingAddress2,
      mailingZip: dealerProfile?.mailingZip,
      mailingCity: dealerProfile?.mailingCity,
      mailingCounty: dealerProfile?.mailingCounty,
      mailingState: dealerProfile?.mailingState,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerProfile]);

  useEffect(() => {
    dispatch<any>(handleGetUserProfile(loggedUserId));
    console.log('hideButton',hideButton)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //useEffect(() => {
  //  setPathname(location.pathname);
  //}, [location]);

  //const handleSaveChanges = () => {
    //formik.handleSubmit();
 // };

  //const renderSpinner = () => {
    //return updateDealerProfileLoading ? (
     // <Spinner
        //styles={{
         // root: {
           // paddingRight: 5,
          //},
        //}}
        //size={SpinnerSize.medium}
      ///>
    //) : null;
  //};

  //const examplePersona: IPersonaSharedProps = {
  //  imageUrl: userProfile
  //    ? `${process.env.REACT_APP_IMAGE_SERVER}/${userProfile.avatar}`
  //    : "",
  //  imageInitials: userProfile?.firstName ? `${userProfile.firstName[0]} ${userProfile?.lastName ? userProfile.lastName[0] : ""}` : "",
  //  text: userProfile?.firstName ? `${userProfile.firstName} ${userProfile?.lastName ? userProfile.lastName : ""}` : "",
  //  secondaryText: userProfile ? userProfile.email : "",
  //  tertiaryText:
  //    userProfile.roles && userProfile?.roles[0] === "ROLE_MASTER"
  //      ? "dealer account"
  //      : "",
  //};

  return (
    <>
      {/*<Loader show={loading}/>*/}
      <Stack horizontalAlign="start">
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
         {/*  <Stack className="goBackSettings">
              <GoBackInfo mainPage="Settings" secondPage="Business Info" />
          </Stack> */}
        </Stack>
        <Stack
           horizontal
           verticalAlign="center"
           horizontalAlign="space-between"
           className="pageTitleRow"
        >
           <CustomFontText className="pageTitleFont pageName pageHeaderFont"> Business Info</CustomFontText>
        </Stack>
        <Row style={{width:"100%",marginTop:"15px"}}>
          <Col
            className="dealer-account"
            style={styles.col}
            xs={12}
            sm={12}
            md={12}
            xl={12}
            xxl={12}
          >
            <DealerProfilePivot formik={formik} setHideButton={setHideButton} />
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={styles.buttonsTokens}
              styles={styles.buttonsStyles}
            >
                {/*{!hideButton && (
              <PrimaryButton
                  styles={buttonStyle}
                  disabled={!formik.dirty || !formik.isValid}
                  onRenderIcon={renderSpinner}
                  text="Save Changes"
                  onClick={handleSaveChanges}
                />
              )}*/}
            </Stack>
          </Col>

        </Row>
      </Stack>
    </>
  );
};

export default DealerProfilePage;
