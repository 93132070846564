import { call, put, select, takeLeading } from "redux-saga/effects";

import {
  pendingGetUserProfile,
  resolvedGetUserProfile,
  pendingUpdateDealerProfile,
  resolvedUpdateDealerProfile,
  pendingUploadUserPhoto,
  rejectedUpdateDealerProfile,
  pendingGetOwners,
  resolvedGetOwners,
  rejectedGetOwners,
  pendingUpdateOwner,
  rejectedUpdateOwner,
  resolvedUpdateOwner
} from "store/userProfile/userProfile.action";
import { handleRefreshToken } from "store/auth/auth.action";
import {
  getUserProfile,
  getEmployeeProfile,
  updateDealerProfile,
  //updatePassword,
  //updateEmployeePassword,
  uploadAvatar,
  uploadEmployeeAvatar,
  getOwners,
  updateOwner,
} from "store/userProfile/userProfile.api";

import {
  HANDLE_GET_USER_PROFILE,
  HandleGetUserProfile,
  HandleUpdateUserProfile,
  HANDLE_UPDATE_USER_PROFILE,
  HANDLE_UPLOAD_USER_PHOTO,
  HANDLE_UPDATE_DEALER_PROFILE,
  HandleUpdateDealerProfile,
  HandleUploadUserPhoto,
  HANDLE_GET_OWNERS,
  HANDLE_UPDATE_OWNER,
  HandleUpdateOwner,
} from "store/userProfile/userProfile.types";

import {
  pendingUpdateUserProfile,
  //resolvedUpdateUserProfile,
  rejectedUpdateUserProfile,
} from "store/userProfile/userProfile.action";

import {
  updateUserProfile,
  updateEmployeeProfile,
  getDealerProfile
} from "store/userProfile/userProfile.api";

import { AppState } from "../index";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
// import {HANDLE_ADD_EMPLOYEE_TO_GROUP, HandleAddEmployeeToGroup} from "../groups/groups.types";
// import {addEmployeeToGroupSaga} from "../groups/groups.saga";

export function* getUserProfileSaga(action: HandleGetUserProfile) {
  //console.log('get user payload', action.payload)
  yield put(pendingGetUserProfile());
    
  if (action.payload.isEmployee) {
    
    const { error, data } = yield getEmployeeProfile(action.payload.id);
    
    if (error) {
      return;
    }

    yield put(resolvedGetUserProfile(data));
    return;
  }

  const { error, data } = yield getUserProfile(action.payload.id);
  const {dealerData,dealerError} = yield getDealerProfile();
  
  if (error || dealerError) {
    return;
  }
   data["dealerProfile"] = dealerData;
  yield put(resolvedGetUserProfile(data));
}

function* updateUserProfileSaga(action: HandleUpdateUserProfile) {
  //console.log(action);
    yield put(switchGlobalLoader(true));
  // TODO updating employee user profile
  yield put(pendingUpdateUserProfile());

  const userProfile = yield select(
    (state: AppState) => state.userProfile.userProfile
  );
  if (userProfile?.roles?.includes("ROLE_MASTER")) {
    
    const { error } = yield updateUserProfile(
      action.payload.id,
      action.payload.data
    );

    if (error) {
        yield put(rejectedUpdateUserProfile(error.violations as any));
        yield put(switchGlobalLoader(false));
      return;
    }
    //yield put(resolvedUpdateUserProfile(data));
    //if (action.payload.password) {
    //  yield updatePassword(action.payload.id, action.payload.password);
    //}
  } else {
    
    const { error } = yield updateEmployeeProfile(action.payload.id, action.payload.data);

    if (error) {
        yield put(rejectedUpdateUserProfile(error.violations as any));
        yield put(switchGlobalLoader(false));
      return;
    }
    //yield put(resolvedUpdateUserProfile(data));
    //if (action.payload.password) {
    //  yield updateEmployeePassword(action.payload.id, action.payload.password);
    //}
  }

    yield put(handleRefreshToken());
    yield put(switchGlobalLoader(false));
}

function* updateDealerProfileSaga(action: HandleUpdateDealerProfile) {
    
    yield put(pendingUpdateDealerProfile());
    yield put(switchGlobalLoader(true));

    const { error, data } = yield updateDealerProfile(action.payload.data);

    if (error) {
        yield put(rejectedUpdateDealerProfile((error.violations? error.violations :error) as any));
        yield put(switchGlobalLoader(false));
    return;
  }

  yield put(resolvedUpdateDealerProfile(data));

  yield put(switchGlobalLoader(false));
}

function* uploadUserPhotoSaga(action: HandleUploadUserPhoto) {
  yield put(switchGlobalLoader(true));
  yield put(pendingUploadUserPhoto());

  const userProfile = yield select(
    (state: AppState) => state.userProfile.userProfile
  );

  if (userProfile?.roles?.includes("ROLE_MASTER")) {
    const { error } = yield uploadAvatar(
      action.payload.id,
      action.payload.formData
    );

    if (error) {
        yield put(switchGlobalLoader(false));
      return;
    }

    //calling saga from saga
      const actions: HandleGetUserProfile = {
      payload: { id: action.payload.id, isEmployee:false },
      type: HANDLE_GET_USER_PROFILE,
    };

    yield call(getUserProfileSaga, actions);
  } else {
    const { error } = yield uploadEmployeeAvatar(
      action.payload.id,
      action.payload.formData
    );

    if (error) {
        yield put(switchGlobalLoader(false));
      return;
    }

    // calling saga from saga
    const actions: HandleGetUserProfile = {
      payload: { id: action.payload.id, isEmployee: true },
      type: HANDLE_GET_USER_PROFILE,
    };

    yield call(getUserProfileSaga, actions);

    const uP = yield select((state: AppState) => state.userProfile.userProfile);
    yield put(
      resolvedGetUserProfile({
        ...uP,
      })
    );
    }
    yield put(switchGlobalLoader(false));
}

/* Get Owners*/
function* getOwnersSaga() {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetOwners());
    try {
        const { data, error } = yield getOwners();
        if (error) {
            yield put(rejectedGetOwners(error.violations as any));
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(resolvedGetOwners(data.results));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetOwners(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* updateOwnerSaga(action: HandleUpdateOwner) {
   
    yield put(pendingUpdateOwner());
    yield put(switchGlobalLoader(true));
    const { owners } = action.payload;

    const { data,error } = yield updateOwner(owners);
    if (error) {
        yield put(rejectedUpdateOwner(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(resolvedUpdateOwner(data));

    yield put(switchGlobalLoader(false));
}

export default function* UserProfileSaga() {
  yield takeLeading(HANDLE_GET_USER_PROFILE, getUserProfileSaga);
  yield takeLeading(HANDLE_UPDATE_USER_PROFILE, updateUserProfileSaga);
  yield takeLeading(HANDLE_UPDATE_DEALER_PROFILE, updateDealerProfileSaga);
  yield takeLeading(HANDLE_UPLOAD_USER_PHOTO, uploadUserPhotoSaga);

  //Owner
  yield takeLeading(HANDLE_GET_OWNERS, getOwnersSaga);
  yield takeLeading(HANDLE_UPDATE_OWNER, updateOwnerSaga);
}
