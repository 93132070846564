import {ISelectList} from "types/selectListTypes";
import * as actions from "store/selectList/selectList.types";
//import { IPagination, IPaginationResponse } from "types/paginationTypes";

// GET_SELECT_LISTS
export const pendingGetSelectList = (): actions.GetSelectListPending => {
  return {
    type: actions.GET_SELECT_LIST_PENDING,
  };
};

export const handleGetSelectList = (category:string,isActive : string | number | boolean) => {
    //
  return {
    type: actions.HANDLE_GET_SELECT_LIST,
    payload: {category,isActive},
  };
};

export const resolvedGetSelectList = (
  results: ISelectList[],
  category: string
): actions.GetSelectListResolved => {
  return {
    type: actions.GET_SELECT_LIST_RESOLVED,
    payload: {results,category},
  };
};

export const rejectedGetSelectList = (
  errors: any
): actions.GetSelectListRejected => {
  return {
    type: actions.GET_SELECT_LIST_REJECTED,
    payload: errors,
  };
};



export const handleGetFinanceProduct = ({
    category, isActive,callback
}): actions.HandleGetFinanceProduct => {

    return {
        type: actions.HANDLE_GET_FINANCE_PRODUCT,
        payload: {category,isActive,callback},
    };
};

/** VIEW_SELECT_LIST */
export const handleViewSelectListItem = (
 formData:any
): actions.HandleViewSelectListItem => {
  return {
    type: actions.HANDLE_VIEW_SELECT_LIST_ITEM,
      payload: formData,
  };
};



/** UPDATE_SELECT_LIST */
export const handleUpdateSelectListItem = (formData:any
): actions.HandleUpdateSelectListItem => {
  return {
    type: actions.HANDLE_UPDATE_SELECT_LIST_ITEM,
    payload: formData,
  };
};



// CREATE SELECT_LIST
export const handleCreateSelectListItem = (
 formData:any
): actions.HandleCreateSelectListItem => {
  return {
    type: actions.HANDLE_CREATE_SELECT_LIST_ITEM,
    payload: formData
  };
};



/** ACTIVATE SELECT_LIST */
export const handleActivateSelectListItem = (
 formData:any

): actions.HandleActivateSelectListItem => {
    return {
        type: actions.HANDLE_ACTIVATE_SELECT_LIST_ITEM,
        payload: formData,
    };
};

/** DEACTIVATE_SELECT_LIST */
export const handleDeactivateSelectListItem = (
 formData:any
): actions.HandleDeactivateSelectListItem => {
  return {
    type: actions.HANDLE_DEACTIVATE_SELECT_LIST_ITEM,
    payload: formData,
  };
};

