import React, { useEffect, useState } from "react";
import {
  Panel,
  IPanelStyles,
  PanelType,
} from "@fluentui/react/lib/Panel";
import {
  IconButton,
  Stack,
  DefaultButton,
  PrimaryButton,
  TextField,
  ComboBox,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "../../dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { getTheme } from "@fluentui/react";
import { dropdownStyles, filterPanelStyles as content } from "constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  handleGetInventoryList,
  setInventoryFilters,
  cleanInventoryFilters,
  handleSaveFilterInventory,
  handleDeleteFilterInventory
} from "store/inventory/inventory.action";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { getLocationsForDropdown } from "store/locations/locations.api";
import dropDownsValues from "constants/inventoryDropdownsValues";
import ListItem from "components/ListItem/ListItem";
import FinancialInformationPanel from "components/panels/inventoryFilterPanel/FinancialInformationPanel";
import EngineAndDrivePanel from "components/panels/inventoryFilterPanel/EngineAndDrivePanel";
import BodyAndColorPanel from "components/panels/inventoryFilterPanel/BodyAndColorPanel";
import OptionsPanel from "components/panels/inventoryFilterPanel/OptionsPanel";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { IPickerItem } from "types/sharedTypes";
import { handleGetMakes, handleGetModels } from "store/shared/shared.action";
import CurrencyInput from 'react-currency-input-field'
import {PRICE_PREFIX} from "constants/constants";
import { InventorySaveDialog } from "../../../components/dialogs/inventorySavedialog/InventorySaveDialog";

const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
  content: {
    paddingLeft: 0,
  },
  contentInner: {
    marginTop: 0,
    marginLeft: 0,
  },
};

const styles = {
  fullWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 15,
  },
};

const contentStyles = content();
const theme = getTheme();

const conditionValues = dropDownsValues.conditionValues.map((item) => {
  return { key: item, text: item };
});

const historyValues = dropDownsValues.historyValues.map((item) => ({
  key: item,
  text: item,
}));

const bodyStyleValues = dropDownsValues.bodyStyleValues.map((item) => {
  return { key: item, text: item };
});

const yearValues = dropDownsValues.yearValues.map((item) => {
  return { key: item, text: item };
});

enum NestedPanels {
  none = "none",
  financialInformation = "Financial information",
  engineAndDrive = "Engine and drive",
  bodyAndColor = "Body and color",
  options = "Options",
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  filterName: string,
  filteredId: any;
  onFilterIdChange: (id: any, name?: string) => void;
}

const InventoryFilterPanel: React.FC<IProps> = ({ isOpen, onClose, filterName, filteredId, onFilterIdChange }) => {
  const [locations, setLocations] = useState([]);
  const [showSecondPanel, setShowSecondPanel] = useState(NestedPanels.none);
  const [secondaryDialogContent, setSecondaryDialogContent] = useState(null);
  const filters = useSelector((state: AppState) => state.inventory.filters);
  
  const [makeValues, setMakeValues] = useState([]);
  const [modelValues, setModelValues] = useState([]);
  const [showModels, setShowModels] = useState(false);
  const [filterDataID, setfilterDataID] = useState(0);
  const [isSaveDialogOpen, setisSaveDialogOpen] = useState(false);
  const loadingMakes = useSelector(
    (state: AppState) => state.shared.loadingMakes
  );
  const loadingModels = useSelector(
    (state: AppState) => state.shared.loadingModels
  );
  const models: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.models
  );
  const makes: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.makes
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(handleGetMakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (makes.length > 0) {
      const values = makes.map((item) => {
        return { key: item.id, text: item.label };
      });
      setMakeValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makes]);

  useEffect(() => {
    if (filters.make.text !== "") {
      setShowModels(true);

      // formik.setFieldValue("model", { key: 0, text: "" });
      dispatch<any>(handleGetModels(filters.make?.key?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.make]);

  useEffect(() => {
    setfilterDataID(filteredId);
  }, [filteredId])

  useEffect(() => {
    if (models.length > 0) {
      const values = models.map((item) => {
        return { key: item.id, text: item.label };
      });
      setModelValues(values);

      // if (modelFromVin !== "") {
      //   console.log(modelFromVin);
      //
      //   const vinModel = values.find((item: any) => {
      //     return item.text === modelFromVin;
      //   }) as any;
      //   
      //   if (Boolean(vinModel)) {
      //     
      //     formik.setFieldValue("model", {
      //       key: vinModel.key,
      //       text: vinModel.text,
      //     });
      //   }
      // }
    }
  }, [models]);

  useEffect(() => {
    switch (showSecondPanel) {
      case NestedPanels.none:
        // setShowFooter(true);
        // setSecondaryDialogTitle("");
        setSecondaryDialogContent(null);
        break;
      case NestedPanels.financialInformation:
        // setShowFooter(false);
        // setSecondaryDialogTitle("Financial information");
        setSecondaryDialogContent(
          <FinancialInformationPanel
            label={NestedPanels.financialInformation}
            isOpen={true}
            onClose={() => setShowSecondPanel(NestedPanels.none)}
            cleanFilters={cleanFilters}
            handleFilter={handleFilter}
          />
        );
        break;

      case NestedPanels.engineAndDrive:
        setSecondaryDialogContent(
          <EngineAndDrivePanel
            isOpen={true}
            label={NestedPanels.engineAndDrive}
            onClose={() => setShowSecondPanel(NestedPanels.none)}
            cleanFilters={cleanFilters}
            handleFilter={handleFilter}
          />
        );
        break;
      case NestedPanels.bodyAndColor:
        setSecondaryDialogContent(
            <BodyAndColorPanel
                isOpen={true}
                label={NestedPanels.bodyAndColor}
                onClose={() => setShowSecondPanel(NestedPanels.none)}
                cleanFilters={cleanFilters}
                handleFilter={handleFilter}
            />
        );
        break;
      case NestedPanels.options:
        setSecondaryDialogContent(
            <OptionsPanel
                isOpen={true}
                label={NestedPanels.options}
                onClose={() => setShowSecondPanel(NestedPanels.none)}
                cleanFilters={cleanFilters}
                handleFilter={handleFilter}
            />
        );
        break;
      default:
        console.log(`default switch`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSecondPanel]);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data } = await getLocationsForDropdown();
      const options = data?.results.map((item) => {
        return { key: item.id, text: item.legalName };
      });

      setLocations(options);
    };
    fetchLocations().then();
  }, []);

  const handleMultipleDropdownValueChangeStringOptions = (e, item) => {
    const property = e.target.id;
    let newValues = [...filters[property]];

    if (newValues.includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.text);
    } else {
      newValues.push(item.text);
    }

    dispatch<any>(
      setInventoryFilters({
        ...filters,
        [property]: newValues,
      })
    );
  };

  const handleDropdownChange = (e, item) => {
    

    dispatch<any>(
      setInventoryFilters({
        ...filters,
        [e.target.id]: item.key,
      })
    );
  };
  const handleMakeChange = (e, item) => {
    dispatch<any>(
      setInventoryFilters({
        ...filters,
        make: item,
      })
    );
  };

  const handleModelChange = (e, item) => {
    dispatch<any>(
      setInventoryFilters({
        ...filters,
        model: item,
      })
    );
  };

  const handleChange = (e, item) => {
    dispatch<any>(
      setInventoryFilters({
        ...filters,
        [e.target.id]: item,
      })
    );
  };

  const handleChangePrice = (e,name) => {
    dispatch<any>(
        setInventoryFilters({
          ...filters,
          [name]: e,
        })
    );
  }

  const cleanFilters = () => {
    setfilterDataID(0);
    dispatch<any>(cleanInventoryFilters());
    onFilterIdChange(0);
    dispatch<any>(
      handleGetInventoryList({
        page: 1,
      })
    );
  };

  const saveFilters = () => {
    setisSaveDialogOpen(true);
  };

  const deleteFilters = () => {
    console.log('filterID', filterDataID);
    if (filterDataID && (filterDataID !== 0)) {
      dispatch<any>(handleDeleteFilterInventory(filterDataID));
      onFilterIdChange(0);
    }
    setfilterDataID(0);
  };

  const handleFilter = () => {
    dispatch<any>(
      handleGetInventoryList({
        page: 1,
      })
    );
    onClose();
  };

  const onDismissSaveDialog = () => {
    setisSaveDialogOpen(false);
  };

  const _handleSaveDialog = (id, name) => {
    console.log('Save filterID', filterDataID);
    let filterId = id;
    dispatch<any>(handleSaveFilterInventory({
      page: 1,
      filterId,
      filterName: name,
      callback: (msg, isSuccess) => {
        if (isSuccess) {
          setisSaveDialogOpen(false);
          onFilterIdChange(filterId, name);
          onClose(); 
        } else {
          console.log('Save Inventory Err msg', msg)
        }
      } 
    }));   
  };

  const _renderContent = () => {
    return (
      <>
        <Dropdown
          id="location"
          label="Location"
          options={locations}
          selectedKey={filters.location}
          onChange={handleDropdownChange}
          placeholder=""
        />
        <Dropdown
          id="condition"
          label="Condition"
          onChange={handleMultipleDropdownValueChangeStringOptions}
          multiSelect
          options={conditionValues}
          selectedKeys={filters.condition}
          placeholder=""
        />
        <Dropdown
          id="bodyStyle"
          label="Body Style"
          onChange={handleDropdownChange}
          options={bodyStyleValues}
          selectedKey={filters.bodyStyle}
          placeholder=""
        />

        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div style={styles.fullWidth}>
          <label className="lbl-text">Min Price</label>
            <CurrencyInput
                customInput={TextField }
               // thousandSeparator={true}
                // decimalScale={2}
                prefix={PRICE_PREFIX}
                id="minPrice"
                name="minPrice"
                //label="Min Price"
                value={filters.minPrice ? filters.minPrice.replace(/,/g, '') : ""}
                onChange={(e)=>{handleChangePrice(e,'minPrice')}}
            />
          </div>
          <div style={styles.fullWidth}>
          <label className="lbl-text">Max Price</label>
            <CurrencyInput
                customInput={TextField }
               // thousandSeparator={true}
                // decimalScale={2}
                //fixedDecimalScale
                prefix={PRICE_PREFIX}
                id="maxPrice"
                name="maxPrice"
                //label="Max Price"
                value={filters.maxPrice ? filters.maxPrice.replace(/,/g, '') : ""}
                onChange={(e)=>{handleChangePrice(e,'maxPrice')}}
            />
          </div>
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div style={styles.fullWidth}>
            <Dropdown
              id="minYear"
              label="Min Year"
              onChange={handleDropdownChange}
              options={yearValues}
              selectedKey={filters.minYear}
              placeholder=""
            />
          </div>
          <div style={styles.fullWidth}>
            <Dropdown
              id="maxYear"
              label="Max Year"
              onChange={handleDropdownChange}
              options={yearValues}
              selectedKey={filters.maxYear}
              placeholder=""
            />
          </div>
        </Stack>

        <Dropdown
          id="vehicleHistory"
          label="Vehicle History"
          onChange={handleMultipleDropdownValueChangeStringOptions}
          multiSelect
          options={historyValues}
          selectedKeys={filters.vehicleHistory}
          placeholder=""
        />

        <TextField
          type="number"
          id="mileage"
          name="mileage"
          label="Mileage"
          value={filters.mileage}
          onChange={handleChange}
        />

        {loadingMakes ? (
          <Spinner
            // styles={styles.spinnerStyles}
            style={{ marginTop: 20 }}
            size={SpinnerSize.medium}
          />
        ) : (
          <>
            <ComboBox
              id="make"
              // componentRef={comboBoxRef}
              // defaultSelectedKey="C"
              label="Make"
              allowFreeform
              autoComplete="on"
              options={makeValues}
              // TODO
              onChange={handleMakeChange}
              selectedKey={filters.make.key}
              // selectedKey={formik.values.make.key}
            />
          </>
        )}
        {showModels ? (
          loadingModels ? (
            <Spinner
              style={{ marginTop: 20 }}
              // styles={styles.spinnerStyles}
              size={SpinnerSize.medium}
            />
          ) : (
            <Dropdown
              id="model"
              label="Model"
              placeholder=""
              options={modelValues}
              styles={dropdownStyles}
              // TODO
              onChange={handleModelChange}
              selectedKey={filters.model.key}
            />
          )
        ) : null}

        <div style={styles.marginTop}>
          <ListItem
            title={NestedPanels.financialInformation}
            subTitles={["Invoice", "Leasing"]}
            onClick={() =>
              setShowSecondPanel(NestedPanels.financialInformation)
            }
          />
        </div>

        <div style={styles.marginTop}>
          <ListItem
            title={NestedPanels.engineAndDrive}
            subTitles={[
              "Driver Type",
              "Fuel Type",
              "Transmission",
              "Cylinders",
            ]}
            onClick={() => setShowSecondPanel(NestedPanels.engineAndDrive)}
          />
        </div>
        <div style={styles.marginTop}>
          <ListItem
            title={NestedPanels.bodyAndColor}
            subTitles={[
              "Body Style",
              "Interior Color",
              "Exterior Color",
              "Doors",
            ]}
            onClick={() => setShowSecondPanel(NestedPanels.bodyAndColor)}
          />
        </div>

        {/*<div style={styles.marginTop}>*/}
        {/*  <ListItem*/}
        {/*    title={NestedPanels.options}*/}
        {/*    subTitles={["Audio/Video", "Power Equipment", "Saftey Features"]}*/}
        {/*    onClick={() => setShowSecondPanel(NestedPanels.options)}*/}
        {/*  />*/}
        {/*</div>*/}


      </>
    );
  };

  return (
    <>
      <Panel
        styles={panelStyles}
        type={PanelType.medium}
        isOpen={isOpen}
        onDismiss={onClose}
        hasCloseButton={false}
      >
        <Stack verticalAlign={"space-between"}>
          <div className={contentStyles.header}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ width: "100%" }}
            >
              <GoBackToMainDialogBtn label="Filters" onClick={onClose} />
              <IconButton
                styles={{
                  root: {
                    color: theme.palette.neutralPrimary,
                    marginLeft: "auto",
                    marginTop: "4px",
                    marginRight: "2px",
                    // position: 'absolute',
                    // // zIndex: 1111,
                    // right: 0,
                  },
                  rootHovered: {
                    color: theme.palette.neutralDark,
                  },
                }}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={onClose}
              />
            </Stack>
          </div>
          {/*<div style={{width: 600}}>test width</div>*/}
          <div className={contentStyles.content}>{_renderContent()}</div>
          <div className={contentStyles.footer}>
            <Stack horizontal horizontalAlign="space-between">
              <DefaultButton text="Delete" onClick={deleteFilters} 
              disabled={filteredId ? false : true}/>
              <DefaultButton text="Save Filters" onClick={saveFilters} />
              <DefaultButton text="Clear" onClick={cleanFilters} />
              <PrimaryButton text="View results" onClick={handleFilter} />
            </Stack>
          </div>
          
          <InventorySaveDialog
            loading={false}
            description=""
            title="Save Filter"
            filterName={filterName}
            filterId={filteredId}
            isOpen={isSaveDialogOpen}
            onAction={_handleSaveDialog}
            onDismissSavePopup={onDismissSaveDialog}
          />
        </Stack>
        {secondaryDialogContent}
      </Panel>
    </>
  );
};

export default InventoryFilterPanel;
