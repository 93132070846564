import React, { useEffect } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  Spinner,
  SpinnerSize,
  ISpinnerStyles,
} from "@fluentui/react/lib/Spinner";
import { useMediaQuery } from "react-responsive";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton } from "@fluentui/react";
import { Link } from "@fluentui/react/lib/Link";
import { Toggle } from "@fluentui/react/lib/Toggle";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "./styles";
import PasswordTextInput from "components/customInputs/passwordTextInput/PasswordTextInput";
import { handleLogin, clearServerErrorMessage } from "store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppState } from "store";
import { useLocation, useNavigate  } from "react-router-dom";
import * as Yup from "yup";
import { queries } from "constants/constants";
import {getRemember} from "utils/localStorage/usersTokensLocalStorage";

const spinnerStyles: ISpinnerStyles = {
  root: {
    position: "absolute",
    right: 150,
  },
};

const LoginPage: React.FC = () => {
  const loading = useSelector((state: AppState) => state.auth.loading);
  const serverError = useSelector(



    (state: AppState) => (state.auth as any).loginError
    );

    //const logoutMessage = useSelector(
    //    (state: AppState) => (state.auth as any).logoutMessage
    //);
  const isTablet = useMediaQuery(queries.tablet);
  const navigate = useNavigate ();
  const location = useLocation ();
  const dispatch = useDispatch();
  const rememberInfo = JSON.parse(getRemember());
  const userToken = Boolean(useSelector((state: AppState) => state.auth.userToken));

    //Clear the server error message while click on browser back button
    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            dispatch<any>(clearServerErrorMessage());
        });
    });

  const userInfo = rememberInfo ? rememberInfo : { username: "", password: "", remember: true };
  const formik = useFormik({
    initialValues: { username: userInfo.username, password: "", remember: userInfo.remember},
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Must be at least 3 characters")
        .max(250,'Maximum 250 characters')
        .email("Field must be valid email user")
        .required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch<any>(
        handleLogin({
          email: values.username,
          password: values.password,
          remember: values.remember,
        })
      );
    },
  });

  const handleToggle = () => {
    formik.setValues(
      { ...formik.values, remember: !formik.values.remember },
      true
    );
  };

  const handleLoginClick = async () => {
    dispatch<any>(clearServerErrorMessage());
    formik.handleSubmit();
  };

  const handleForgotPasswordClick = () => {
        dispatch<any>(clearServerErrorMessage());
        navigate("/reset-password");
  };
  
  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
      <div>
          {location.pathname !== "/dashboard" && !userToken ?
      <AuthLayoutPage>
          <form noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleLoginClick().then();
        }}
      >
        <Stack.Item
        className="loginPage-greenTop "
          styles={
            isTablet ? styles.stackTabletItemStyles : styles.stackItemStyles
          }
        >
          <Stack
            verticalAlign="space-between"
            styles={styles.loginContentStyles}
          >
            <Stack.Item>
                <span className="formTitle">QoreDMS Login</span>
            </Stack.Item>
           
                      {/*
                          logoutMessage !== "" && logoutMessage !== null ? <p className="serverSuccessMsg"><span>{logoutMessage}</span></p> : null
                      */}
                      {
                          serverError ? <p className="genericErrorColor"><span>{serverError.toString()}</span></p> : null
                      }
            <Stack.Item>
               <Stack className="loginForm commonForm" tokens={styles.textFields}>
                <TextField
                  required={true}
                  id="username"
                  name="username"
                  label="Email"
                  placeholder=" "
                  //iconProps={styles.usernameIcon}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                      formik.touched.username && formik.errors.username
                      ? formik.errors.username.toString()
                      : null
                  }
                />
                <PasswordTextInput
                  required={true}
                  id="password"
                  name="password"
                  label="Password"
                  placeholder=""
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password.toString()
                      : null
                  }
                />
                <Toggle
                  id="remember"
                  label="Remember my email"
                  inlineLabel
                  checked={formik.values.remember}
                  onChange={handleToggle}
                />
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack className="formAction loginFormAction" tokens={styles.buttonTokens}>
                <PrimaryButton
                  type="submit"
                  onRenderIcon={renderSpinner}
                  text="Log In"
                  onClick={handleLoginClick}
                />
                {/* <DefaultButton text="Register" onClick={handleRegisterClick} /> */}
                
                
              
              </Stack>
            </Stack.Item>
            <Stack.Item>
             <Link className="ln-nw-link forgetPwdLink" onClick={handleForgotPasswordClick}>
                Forgot password?
              </Link>
              {/* { <Link className="ln-nw-link" onClick={() =>  navigate("/register")}>
                Not registered? Create an account.
              </Link> } */}
              
            </Stack.Item>
            <Stack.Item>
              
              
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </form>
    </AuthLayoutPage>

    :
    <>
    </>
    }
      </div>
  );
};

export default LoginPage;
