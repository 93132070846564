import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import {
  //getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { AppState } from "store/index";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { handleGetFinanceDetailReports,handlePrintFinanceDetailReports } from "store/dealTracker/dealTracker.action";
import { handleGetFinanceProduct} from "store/selectList/selectList.action";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";

import moment from "moment";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';

const container: IStackStyles = {
    root: {
        marginTop: 10,
        minHeight: 500
    },
};

//const credentialModuleName = "DealTracker";
export const FinanceDetailReports = () => {
    const dispatch = useDispatch();

    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [commonServerError, setcommonServerError] = useState(null);
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [departmentFilter, setDepartmentFilter] = useState(0);
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [closeDateFromFilter, setCloseDateFromFilter] = React.useState<Date | undefined>(firstDay);
    const [closeDateToFilter, setCloseDateToFilter] = React.useState<Date | undefined>(undefined);
    const [departmentList, setDepartmentList] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [locationFilter, setLocationFilter] = useState(0);
    const [financePersonList, setFinancePersonList] = React.useState(null);
    const [managerFilter, setManagerFilter] = React.useState(0);
    const [finalData, setFinalData] = useState(null);
    const [financeProductList, setFinanceProductList] = React.useState([]);
    const [productFilter, setProductFilter] = React.useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('closeDate');
    const [statesFlag, setStatesFlag] = useState(false);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
    const [statePageNumber, setStatePageNumber] = useState(1);
    const [primaryLocation, setPrimaryLocation] = React.useState(null);
  

   useEffect(()=>{
       setStatesFlag(true);
       
   },[])

    const onDepartmentChange = (e: { value: any }) => {
        setDepartmentFilter(e.value);
    }

    const handleCloseDateFromChange = (val: any) => {
        setCloseDateFromFilter(val.target.value)
    };
    
    const handleCloseDateToChange = (val: any) => {
        setCloseDateToFilter(val.target.value)
    };

    const handleManagerDropdownChange = (e) => {
     setManagerFilter(e.value)
    };

   const handleProductDropdownChange = (e) => {
     setProductFilter(e.value)
    };

    useEffect(() => {
        if (departments) {
            const departList = [...departments];
            departList.unshift({ id: 0, name: 'All' });
            setDepartmentList(departList);
        } else {
            setDepartmentList([]);
        }
    }, [departments]);



    useEffect(() => {
        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    useEffect(()=> {
            let person = [];
            if (employees.results.length > 0) {
                employees.results.map((item: any) => {
                    return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                });
                person.unshift({ key: 0 ,text: 'All'});
                setFinancePersonList(person);
            }
    }, [employees])

    useEffect(() => {
        dispatch<any>(handleGetSelectList('departments', 1));
        dispatch<any>(handleGetFinanceProduct({
               category:"financeProducts",
               isActive:1,
               callback:(financeProducts) => {
                  
                       let finProduct = [];
                       if (financeProducts.length > 0) {
                           financeProducts.map((item: any) => {
                               return finProduct.push({ key: item.id, text: item.name })
                           });
                           finProduct.unshift({ key: 0 ,text: 'All'});
                           setFinanceProductList(finProduct);
                       } 
           
                   } 
    }));
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"financePerson" } })
        dispatch<any>(handleGetLocations());
        setcommonServerError(null);
        setSortOrder(1);
        setSortField('closeDate');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

       const handlePopulateTableData = (response) => {

       

        for (var i in response.results) {
            const deal = response.results[i].deal;
            const customer = deal.customer;

           response.results[i]["closeDate"] = response.results[i].deal.dealDelivery.statusDateTime ? moment(response.results[i].deal.dealDelivery.statusDateTime).format('MM/DD/YY hh:mm a') : ''; 
            //response.results[i]["closeDate"] = substClosedDate ? moment(substClosedDate).format('MM/DD/YY hh:mm a') : ''; //convertUTCToLocalFormat(response.results[i].closedDate, 'MM/DD/YYYY');
            response.results[i]["locationName"] = deal && deal.location && deal.location?.legalName;
            response.results[i]["dealNumber"] = deal.dealNumber;
            response.results[i]["department"] = response.results[i].deal.dealDelivery.department ? response.results[i].deal.dealDelivery.department.name : '';
            if (deal && deal.vehicle) {
                response.results[i]["vehicleStockNumber"] = deal.vehicle.stockNumber ? deal.vehicle.stockNumber : '';
                response.results[i]["vehicle"] = deal.vehicle.year + ' ' + deal.vehicle.model;
            }
            response.results[i]["sales"] = deal && deal.salesPerson1 ? deal.salesPerson1.firstName + ' ' + deal.salesPerson1.lastName : '';
            response.results[i]["salesManager"] = deal && deal.salesManager ? deal.salesManager.firstName + ' ' + deal.salesManager.lastName : '';
            response.results[i]["finance1"] = deal && deal.financePerson1 ? deal.financePerson1.firstName + ' ' + deal.financePerson1.lastName : '';
            response.results[i]["finance2"] = deal && deal.financePerson2 ? deal.financePerson2.firstName + ' ' + deal.financePerson2.lastName : '';
            response.results[i]["financeProduct"] = response.results[i].financeProduct && response.results[i].financeProduct.name;
            response.results[i]["financeAmount"] = response.results[i]?.amount ;
            response.results[i]["customer"] = customer.businessName ? customer.businessName : (customer.firstName + ' ' + customer.lastName);
        }

        setFinalData(response.results);
    };



    const getReportList =(isFilterApplied)=>{       
	     const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
                sort: sortOrder === 1 ? 'ASC' : 'DESC',
                column: sortField,
                locationId:locationFilter?locationFilter:0,
                departmentId:departmentFilter?departmentFilter:0,
                financePersonId:managerFilter?managerFilter:0,
                financeProductId:productFilter?productFilter:0,
                fromClosedDate: closeDateFromFilter ? moment(closeDateFromFilter).format('YYYY-MM-DD') : '',
                toClosedDate: closeDateToFilter ? moment(closeDateToFilter).format('YYYY-MM-DD') : '',
            };
            setFilters(formData);
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetFinanceDetailReports({
                formData,
                callback: (response) => {
                    
                    if (response) {
                        setTotalRecords(response.totalItems);
                        handlePopulateTableData(response);
                        
                    } else {
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}
    

    const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	const getdata = (isFilterApplied = false) => {
        setcommonServerError('');
        if (statesFlag && userProfileLoadingFlag) {
             getReportList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getdata(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationFilter,departmentFilter,managerFilter,productFilter,closeDateFromFilter,closeDateToFilter,statesFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);

    useEffect(() => {
        if (user && user.roles) {
            setUserProfileLoadingFlag(true)
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const handleResetWorkbook = () => {
        
        setDepartmentFilter(0);
        setCloseDateFromFilter(firstDay);
        setCloseDateToFilter(null);
        setProductFilter(0);
        setManagerFilter(0);
        setLocationFilter(primaryLocation ? primaryLocation : 0);
        setSortField('pendingDateTime');
        setSortOrder(-1); 
        setcommonServerError(null);
        
    };

    const getNames = (person1, person2) => {
        let person1Name = '';
        let person2Name = '';
        if (person1) {
            person1Name = person1.firstName + " " + person1.lastName;
        }
        if (person2) {
            person2Name = person2.firstName + " " + person2.lastName;
        }
        return person1Name + ' ' + person2Name;
    }

    const  exportPdf = ()=>{
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handlePrintFinanceDetailReports({
            formData,
            callback: (response) => {
                   if(response){
                         console.log("handlePrintPendingDeal",response)
                   }else{
                       setcommonServerError('Failed to print the pdf');
                   }
            }
        }));
    }

    const exportExcel = () => {     
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetFinanceDetailReports({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results.length) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
                                   
				                     ({
									 "Close Date":item.deal.dealDelivery.statusDateTime ? moment(item.deal.dealDelivery.statusDateTime).local().format('MM/DD/YY hh:mm a'):" ",
                                     'Deal Number': item.deal.dealNumber,
                                     "Location / Department": ((item.deal.location?.legalName) ? item.deal.location.legalName : '-') + ' / ' + (item.deal.dealDelivery.department ? item.deal.dealDelivery.department.name : '-'),
                                     'Finance Person (s)': getNames(item.deal.financePerson1, item.deal.financePerson2),  
                                     'Manager Sales Person 1': getNames(item.deal.salesPerson1, item.deal.salesManager), 
                                     'Customer': item.deal.customer.businessName ? item.deal.customer.businessName:(item.deal.customer.firstName + ' ' + item.deal.customer.lastName),
                                     "Stock": item.deal.vehicle?.stockNumber ? item.deal.vehicle?.stockNumber :"",
                                     "Vehicle": ((item.deal.vehicle?.year) ? item.deal.vehicle?.year : '-') + ' ' + ((item.deal.vehicle?.model) ? item.deal.vehicle?.model : ''),
                                     'Finance Amount':item.amount && item.amount,
                                     'Finance Product':item.financeProduct && item.financeProduct.name
      
				                   }));
				                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'Finance-detail-report');
                                   });
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    const header = (
        
                     <div>
                         <div>
                             <Row>
                                 <Col md={12}>
                                     <Row>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Location</label>
                                                 <Dropdown
                                                     id="locationId"
                                                     placeholder=""
                                                     value={locationFilter}
                                                     options={cityDealerList}
                                                     onChange={onLocationChange}
                                                     optionLabel="legalName"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Department</label>
                                                 <Dropdown
                                                     id="departmentId"
                                                     placeholder=""
                                                     value={departmentFilter}
                                                     options={departmentList}
                                                     onChange={onDepartmentChange}
                                                     optionLabel="name"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                     
                                         <Col md={1.5}>
                                              <div>
                                                  <label className="lbl-text">Finance Person</label>
                                                  <Dropdown
                                                      id="financePerson"
                                                      value={managerFilter}
                                                      options={financePersonList}
                                                      onChange={handleManagerDropdownChange}
                                                      optionLabel="text"
                                                      optionValue="key"
                                                      appendTo="self"
                                                      className="custom-p-dropdown write-ups-location-dd"
                                                      filter={true}
                                                  />
                                              </div>
                                         </Col>
                                         <Col md={1.5}  className="columnBtwSpace">
                                              <div>
                                                   <label className="lbl-text">Finance Product</label>
                                                   <Dropdown
                                                       id="product"
                                                       value={productFilter}
                                                       options={financeProductList}
                                                       onChange={handleProductDropdownChange}
                                                       optionLabel="text"
                                                       optionValue="key"
                                                       appendTo="self"
                                                       className="custom-p-dropdown write-ups-location-dd"
                                                       filter={false}
                                                   />
                                              </div>
                                         </Col>
                                         <Col md={3}>
                                             <div className="date-filter-section">
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">Close Date</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="closeDateFromFilter"
                                                                 style={{ width: "100%" }}
                                                                 value={closeDateFromFilter}
                                                                 showIcon
                                                                 onChange={(e) => handleCloseDateFromChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 maxDate={closeDateToFilter}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <label className="lbl-text" style={{ margin: "36px 10px 0px" }}>To</label>
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">&nbsp;</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="closeDateToFilter"
                                                                 style={{ width: "100%" }}
                                                                 value={closeDateToFilter}
                                                                 showIcon
                                                                 onChange={(e) => handleCloseDateToChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 minDate={closeDateFromFilter}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </Col>

                                         <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "30px", maxWidth: "40%" }} >
                                                   <PrimaryButton onClick={handleResetWorkbook} >
                                                      Reset
                                                   </PrimaryButton>
                                         </Col>
                                     </Row>
                                    
                                 </Col>
                                 <Col>
                                 </Col>
                             </Row>
                     
                     
                         </div> 
                         <div >            
                         <div style={{float:"right",marginBottom:"10px",marginTop:'-50px'}}>
                            {/* <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                 Export to Excel
		                     </Link>*/}
                             <Row>
                                 <Col>
                                     <PrimaryButton
                                             onClick={exportPdf}
                                             text="PDF"
                                             className="tradeInCancelBtn btnHoverColor"
                                         />
                                 </Col>
                                 <Col>
                                     <PrimaryButton
                                             onClick={exportExcel}
                                             text="Excel"
                                             className="tradeInCancelBtn btnHoverColor"
                                         />
                                 </Col>
                             </Row>
                         </div>
                     </div>
                     </div>

          
    )

   

    const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                //handleEditLocation(data.value[0].rowData.id);
            }
        }
    };

    const closeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Close Date</span>
                {rowData.closeDate}
            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };
    const customerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customer}
            </React.Fragment>
        );
    };
    const stockNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Stock Number</span>
                {( rowData.vehicleStockNumber)}
            </React.Fragment>
        );
    };

   const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle</span>
                {( rowData.vehicle)}
            </React.Fragment>
        );
   };

   const locationDeptBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Location / Dept</span>
                {rowData.locationName}/{rowData.department}
            </React.Fragment>
        );
    };

    const salesMangerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Manager</span>
                <div style={{marginBottom:"5px"}}> {rowData.salesManager} </div>
                <div> {rowData.sales} </div>
            </React.Fragment>
        );
    };
    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person</span>
                
                <div style={{marginBottom:"5px"}}> {rowData.finance1} </div>
                <div> {rowData.finance2} </div>
            </React.Fragment>
        );
    };
    const  financeAmountBodyTemplate  = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Amount</span>
                {currencyNegValFractionalFormat( rowData.financeAmount)}
            </React.Fragment>
        );
    };
    const financeProductBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Product</span>
                {( rowData.financeProduct)}
            </React.Fragment>
        );
    };

     return (

    	isKarlProfileType ? <>
          <div>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>

				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							 <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Finance Detail Report </CustomFontText>
						</Col>
					</Row>
				</Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
				<div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                    <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                         <DataTable
                             value={finalData}
                             paginator={false}
                             header={header}
                             footer={footer}
                             sortField={sortField}
                             sortOrder={sortOrder}
                             className="p-datatable-customers reportHeaderPart"
                             onSort={handleColumnSort}                             
                             onSelectionChange={e => captureCellSelection(e)}>
                             <Column field="closedDateTime" header="Close Date" body={closeDateBodyTemplate} sortable />
                             <Column field="dealNumber" header="Deal"  body={dealNumberBodyTemplate} sortable />
                             <Column field="location" header="Location /Dept" body={locationDeptBodyTemplate} sortable />
                             <Column field="financePerson" header="Finance Person(s)"  body={financePersonBodyTemplate} sortable />
                             <Column field="salesPerson" header="Manager Sales Person 1" body={salesMangerBodyTemplate} sortable />
                             <Column field="customer" header="Customer" body={customerNameBodyTemplate} sortable  />
                             <Column field="stockNumber" header="Stock" body={stockNumberBodyTemplate} sortable />
                             <Column field="vehicle" header="Vehicle"  body={vehicleBodyTemplate} sortable />                                            
                             <Column field="financeProductName" header="Finance Amount" body={financeAmountBodyTemplate} sortable  />
                             <Column field="financeProductAmount" header="Finance Product" body={financeProductBodyTemplate} sortable />
                         </DataTable>
					</div>
				</div>
			</Stack>
        </div>
		</> : null
  );
};


