export enum NotificationType {
    success = "success",
    error = "error",
    warning = "warning"
}

export interface INotification {
    type: NotificationType,
    message: string
}
