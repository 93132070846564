import {
  put,
  takeLeading,
  /*call,*/ takeEvery,
  select,
} from "redux-saga/effects";
import {
  pendingGetGroupsPermissions,
  rejectedGetGroupsPermissions,
  resolvedGetGroupsPermissions,
  resolvedGetPermissions,
  rejectedUpdatePermissions,
  // resolvedUpdatePermissions,
  pendingUpdatePermissions,
  // handleUpdatePermissions,
  handleGetGroupsPermissions,
  setSortGroupsPermissions,
} from "store/groupsPermissions/groupsPermissions.action";
import {
  getGroupsPermissions,
  getPermissions,
  updatePermission,
} from "store/groupsPermissions/groupsPermissions.api";

import {
  HANDLE_GET_GROUPS_PERMISSIONS,
  HANDLE_UPDATE_PERMISSIONS,
  HandleGetGroupsPermissions,
  HandleUpdatePermissions,
} from "./groupsPermissions.types";
// import {IPagination} from 'types/paginationTypes'
// import {IGroupPermissionUpdate} from 'types/groupPermissionTypes'
import { getGroupHeaders, getGroupItems } from "utils/groupsHelpers";
import { AppState } from "store";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

export function* getGroupsPermissionsSaga(action: HandleGetGroupsPermissions) {
  yield put(switchGlobalLoader(true));
  yield put(pendingGetGroupsPermissions());
  try {
    // const {error, data} = yield getGroupsPermissions(action.payload);
    // const {perError, perData} = yield getPermissions();
    const sort = yield select(
      (state: AppState) => state.groupsPermissions.sort
    );
    const sortColumn = yield select(
      (state: AppState) => state.groupsPermissions.sortColumn
    );
    yield put(
      setSortGroupsPermissions(action.payload.sort ? action.payload.sort : sort)
    );
    const { data } = yield getGroupsPermissions({
      ...action.payload,
      column: sortColumn,
    });

    // console.log(data)
    const { perData } = yield getPermissions();
    const calculations = getGroupItems(data.results, perData);

    const itemsData = {
      items: { ...data, results: calculations.items },
      groups: getGroupHeaders(data.results, calculations.calculatedIndexes),
    };
    yield put(resolvedGetPermissions(perData));
    yield put(resolvedGetGroupsPermissions({ ...itemsData }));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetGroupsPermissions(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

export function* updatePermissionsSaga(action: HandleUpdatePermissions) {
  const items = action.payload;
  yield put(pendingUpdatePermissions());

  try {
    for (let item of items) {
      yield updatePermission(item.permissionId, item.groupId, item.method);
    }

    yield put(handleGetGroupsPermissions({ ...items[0].pag }));
    // yield put(resolvedUpdatePermissions())
  } catch (error) {
    console.log(error);
    yield put(rejectedUpdatePermissions(error));
  }
}

export default function* GroupsPermissionsPermissionsSaga() {
  yield takeLeading(HANDLE_GET_GROUPS_PERMISSIONS, getGroupsPermissionsSaga);
  yield takeEvery(HANDLE_UPDATE_PERMISSIONS, updatePermissionsSaga);
}
