import React, { useEffect,useState} from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    //DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
    //Dropdown
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { validTenDigitPhone } from "utils/helperFunctions";
//import { handleGetCustomers } from "store/customers/customers.action";
import { handleGetLocations } from "store/locations/locations.action";
import { handleSaveDealRecord } from "store/dealTracker/dealTracker.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { 
handleGetEmployeeSaleFinance
} from "store/employees/employees.action";
import { 
HANDLE_GET_EMPLOYEES, HANDLE_GET_EMPLOYEES_FINANCE
} from "store/employees/employees.types";
import * as _ from "lodash";


interface IProps {
    isOpen: boolean;
    onDismiss: (res) => void;
    id:any;
    ConfirmData?: (res) => void;
    editCustomer?: ({ dealNumber, locationId, customerNumber }) => void;
    newCusData?: any;
    newCusNo?: any;
}

//const stackTokens = { childrenGap: 7 };

export const EditDealCustomerRecordDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    id,
    ConfirmData,
    editCustomer,
    newCusData,
    newCusNo
}) => {
    
    const loading = useSelector((state: AppState) => state.employees.loading);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [createDealError, setCreateDealError] = useState("");
    const [editDealsDialog, setEditDealsDialog] = React.useState('PUT');

    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const employeesFinance = useSelector((state: AppState) => state.employees.employeesFinance) as any;

    const [employeeList, setemployeeList] = useState([]);
    const [finance1, setfinance1] = React.useState([]);
    const [finance2, setfinance2] = React.useState([]);
    const [sales1, setsales1] = React.useState([]);
    const [sales2, setsales2] = React.useState([]);


    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const dealTrackerData = useSelector((state: AppState) => state.dealTracker.selectedDealRecord);

    const dispatch = useDispatch();
    const CustomerOlddata = {
                    "businessName": newCusNo ? "" : (newCusData ? newCusData.businessName : dealTrackerData.customer?.businessName),
                    "firstName": newCusNo ? "" : (newCusData ? newCusData.firstName : dealTrackerData.customer?.firstName),
                    "lastName": newCusNo ? "" : (newCusData ? newCusData.lastName : dealTrackerData.customer?.lastName),
                    "email": newCusNo ? "" : (newCusData ? newCusData.email : dealTrackerData.customer?.email),
                    "phoneNumber": newCusNo ? "" : (newCusData ? newCusData.phoneNumber : dealTrackerData.customer?.phoneNumber),
                    "customerType": newCusNo ? 1 : (newCusData ? newCusData.customerType : dealTrackerData.customer?.customerType),
                    "customerNumber": newCusNo ? newCusNo.customerNumber : (newCusData ? newCusData.customerNumber : dealTrackerData.customer?.customerNumber),

            };
    useEffect(() => {
        setCreateDealError('');
        dispatch<any>(handleGetLocations());
        dispatch<any>(handleGetEmployeeSaleFinance({
        
                isActive:1,role:"salesManager",     callback: (response) => {
                 let salesManager = [];
                if (response && response.results) {
                    response.results.map((item: any) => {
                        return salesManager.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                    });
                    setemployeeList(salesManager);
                }
                else {
                    setemployeeList([]);
                }
            }

        }));
        //setCustomerList(null)
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"salesPerson" } })
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES_FINANCE, payload: { isActive: 1,role:"financePerson" } })

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (locations && locations.results) {
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
        
    }, [locations]);



       useEffect(()=> { 
            setsales1([]);
            setsales2([]);
            
            let sales1 = [];
            let sales2 = [];
            if (employees.results.length > 0) {
                employees.results.map((item: any) => {
                    return sales1.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                });

               setsales1(sales1.filter(x => !dealTrackerData.salesPerson2 || x.key !== dealTrackerData.salesPerson2.id));
                sales2 = [...sales1];
                sales2.unshift({ key: 0, text: '---Please Select---' });
                setsales2(sales2.filter(x => !dealTrackerData.salesPerson1 || x.key !== dealTrackerData.salesPerson1.id));
            }     
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees]);

    useEffect(()=> {
        setfinance1([]);
        setfinance2([]);

            let finance1 = [];
            let finance2 = [];
        if (employeesFinance.results.length > 0) {
            employeesFinance.results.map((item: any) => {
                return finance1.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
             finance1.unshift({ key: 0,text: '---Please Select---'});
             setfinance1(finance1.filter(x => !dealTrackerData.financePerson2 || x.key !== dealTrackerData.financePerson2.id));
             finance2 = [...finance1];          
             setfinance2(finance2.filter(x => !dealTrackerData.financePerson1 || x.key !== dealTrackerData.financePerson1.id));

        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeesFinance]);

    const formik = useFormik({
        initialValues: {
                id:"",
                firstName: newCusNo ? "": (newCusData? newCusData.firstName:dealTrackerData.customer?.firstName),
                lastName:  newCusNo ? "":  (newCusData? newCusData.lastName:dealTrackerData.customer?.lastName),  
                businessName: newCusNo ? "": (newCusData? newCusData.businessName:dealTrackerData.customer?.businessName), 
                dealNumber: newCusNo ? newCusNo.dealNumber : (newCusData && newCusData.dealNumber ? newCusData.dealNumber : (dealTrackerData?.dealNumber)),
                locationId: newCusNo ? newCusNo.locationId : (newCusData && newCusData.locationId ? newCusData.locationId :  (dealTrackerData.location ? dealTrackerData.location.id:"")),
                phoneNumber: newCusNo ? "": (newCusData? newCusData.phoneNumber:dealTrackerData.customer?.phoneNumber), 
                email: newCusNo ? "": (newCusData? newCusData.email:dealTrackerData.customer?.email), 
                customerType: newCusNo ? 1 : (newCusData? newCusData.customerType:dealTrackerData.customer?.customerType), 
                salesPerson1Id:dealTrackerData.salesPerson1? dealTrackerData.salesPerson1.id:"",
                salesPerson2Id:dealTrackerData.salesPerson2? dealTrackerData.salesPerson2.id:"",
                financePerson1Id:dealTrackerData.financePerson1? dealTrackerData.financePerson1.id:"",
                financePerson2Id:dealTrackerData.financePerson2? dealTrackerData.financePerson2.id:"",
                salesManagerId: dealTrackerData.salesManager ? dealTrackerData.salesManager.id: "",
                customerNumber: newCusNo ? newCusNo.customerNumber: (newCusData? newCusData.customerNumber:dealTrackerData.customer?.customerNumber),
        },
        validationSchema: Yup.object({
            dealNumber:Yup.string().required("Required"),
            locationId: Yup.string().required("Required"),
            businessName: Yup.string()
                .when('customerType',
                    {
                        is: 1,
                        then: schema => schema,
                        otherwise: Yup.string().required('Business Name required.')
                    })
                .max(100, "Business Name must be at most 100 characters"),
            firstName: Yup.string()
                .when('customerType',
                {
                    is: 2,
                    then: schema => schema,
                    otherwise: Yup.string().required('First Name required.')
                })
                .max(50, "First Name must be at most 50 characters"),
            lastName: Yup.string()
                .when('customerType',
                    {
                        is: 2,
                        then: schema => schema,
                        otherwise: Yup.string().required('Last Name required.')
                    })
                .max(50, "Last Name must be at most 50 characters"),
            email: Yup.string().max(50, "Email must be at most 50 characters")
                .email("This field must be valid email user")
                .required("Required"),
            phoneNumber: Yup.string()
                .test("required", "Phone required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Phone required"),
            salesPerson1Id: Yup.string().required("Required"),
            salesPerson2Id: Yup.string(),
            financePerson1Id:Yup.string(),
            financePerson2Id:Yup.string(),
            customerType: Yup.number(),
            salesManagerId: Yup.string().required("Required"),
            customerNumber: Yup.string().required("Customer No required").max(32, "Customer No must be at most 32 digits"),
            id: Yup.number(),
        }),
        onSubmit: (values) => {

            setCreateDealError('');
            values.phoneNumber = values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10);
            if (values.customerType === 1) {
                values.businessName = '';
            } else if (values.customerType === 2) {
                values.firstName = '';
                values.lastName = '';
            }
            const data = {
                "businessName": values.businessName,
                "firstName": values.firstName,
                "lastName": values.lastName,
                "email": values.email,
                "phoneNumber": values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10),
                "salesPerson1Id": values.salesPerson1Id,
                "salesPerson2Id": values.salesPerson2Id,
                "financePerson1Id": values.financePerson1Id,
                "financePerson2Id": values.financePerson2Id,
                "customerType": values.customerType,
                "salesManagerId": values.salesManagerId,
                "customerNumber": values.customerNumber,
                "dealNumber": values.dealNumber,
                "locationId": values.locationId,
            };
            const CustomerRecordNewdata = {
                         businessName: values.businessName,
                         customerNumber: values.customerNumber,
                         customerType: values.customerType,
                         email: values.email,
                         firstName: values.firstName,
                         lastName: values.lastName,
                         phoneNumber: values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10),
                     };
            if (_.isEqual(CustomerOlddata, CustomerRecordNewdata) === true || newCusNo){
                dispatch<any>(handleSaveDealRecord({
                    id,
                    deal: data,
                    callback: (response) => {
                        if (typeof response === 'number') {
                            console.log('dalied res',response)
                            // setCreateDealError('Failed to Save the Data.');
                            onDismiss(response);
                            window.scrollTo(0, 0);
                        } else {
                            onDismiss(response);
                        }

                    }
                }));

             }else{
                ConfirmData( data) 
             }
        }
    });


    useEffect(() => {
     //console.log('dealTrackerDatares',dealTrackerData)
        if (dealTrackerData && dealTrackerData.type !== '') {
            setEditDealsDialog('PATCH')
            formik.dirty = true;
        } 
        //console.log('formik',formik)

       // if(dealTrackerData && dealTrackerData.customer.customerType === 1){
       //          setIndividualCustomer(true);
       //          setActiveFlagBussiness(false);
       //
       // 
       // }else{
       //         setIndividualCustomer(false);
       //         setActiveFlagBussiness(true);
       //          handleBussiness();
       // }
       //
    }, [dealTrackerData, formik]);
    
    

    const handleUpdateDeal = () => {
        formik.handleSubmit();
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };
    

    const handleMaskInput = (e) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    const onLocationChange = () => {

    }
    
    const handleChangeCustomer =() =>{
         editCustomer({
                    dealNumber: formik.values.dealNumber,
                    locationId: formik.values.locationId,
                    customerNumber: formik.values.customerNumber, 
         });
    }

    const handleIndividual = ( ) => {
        formik.setValues(
            {
                ...formik.values,
                'customerType':1,
            }
        );
        //setType:1
    };


    const handleBussiness = ( ) => {
        formik.setValues(
            {
                ...formik.values,
                'customerType': 2,
            }
        );
    };



    //const handleDropdownSalesValue = (e, item) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            [e.target.id]: item.key
    //        }
    //    );
    //    // aaffect Sales & Finance Dropdown
    //};

    const handleDropdownSalesValue = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value
            }
        );
        // aaffect Sales & Finance Dropdown
    };

    //const handleDropdownValue = (e, item) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            [e.target.id]: item.key
    //        }
    //    );
    //    // aaffect Sales & Finance Dropdown
    //    changesSalesFinanceDropdownVa(e.target.id, item.key);
    //};

    const handleDropdownValue = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value
            }
        );
        // aaffect Sales & Finance Dropdown
        changesSalesFinanceDropdownVa(e.target.id, e.value);
    };

    const changesSalesFinanceDropdownVa = (id, key) => {
        if (id === 'salesPerson1Id') {
            var sales2 = [];
            employees.results.forEach((ele)=>{
                const itemCopy = { ...ele };
                if(itemCopy.id === key)  {

                } else {
                    return sales2.push({ key: ele.id, text: (ele.firstName + ' ' + ele.lastName) });
                }
            });
            sales2.unshift({ key: 0 ,text: '---Please Select---'});
            setsales2(sales2);
        } else if (id === 'salesPerson2Id') {
            var sales1 = [];
            employees.results.forEach((ele)=>{
                const itemCopy = { ...ele };
                if(itemCopy.id === key)  {

                } else {
                    return sales1.push({ key: ele.id, text: (ele.firstName + ' ' + ele.lastName) });
                }
            });
            //sales1.unshift({ key: 0 ,text: '---Please Select---'});
            setsales1(sales1);
        }

        if (id === 'financePerson1Id') {
            var finance2 = [];
            employeesFinance.results.forEach((ele)=>{
                const itemCopy = { ...ele };
                if(itemCopy.id === key)  {

                } else {
                    return finance2.push({ key: ele.id, text: (ele.firstName + ' ' + ele.lastName) });
                }
            });
            finance2.unshift({ key: 0 ,text: '---Please Select---'});
            setfinance2(finance2);
        } else if (id === 'financePerson2Id') {
            var finance1 = [];
            employeesFinance.results.forEach((ele)=>{
                const itemCopy = { ...ele };
                if(itemCopy.id === key)  {

                } else {
                    return finance1.push({ key: ele.id, text: (ele.firstName + ' ' + ele.lastName) });
                }
            });
            finance1.unshift({ key: 0 ,text: '---Please Select---'});
            setfinance1(finance1);
        }

    }


    return (
        
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord dealRecordHeights updateEmployeeDialogHeight edit-cust-dialog"
        >
            <div className={contentStyles.header+" inventory-popup"}>
                   <CustomFontText className="formTitle">Edit Deal Record</CustomFontText>
                   <IconButton
                       styles={iconButtonStyles}
                       iconProps={cancelIcon}
                       ariaLabel="Close popup modal"
                       onClick={onDismiss}
                   />
            </div>
            <div className={contentStyles.body+" deal-popup-body"} >
                
                
                <Stack>
                    {
                        createDealError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>{createDealError}</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                        {/*<Row>
                             <Col md={6}  className="businessInfoInputEINTextField">
                                  <TextField
										id="dealNumber"
										name="dealNumber"
										label="Deal Number"
										placeholder=""
										autoComplete="off"
										value={formik.values.dealNumber}
                                        readOnly={true}
										required={true}
										onChange={formik.handleChange}
                                        style={{height:"25px"}}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.dealNumber &&
                                            formik.errors && formik.errors.dealNumber &&
                                            formik.errors.dealNumber.toString()
										}
									/>                               
                             </Col>
                             <Col md={6} className="businessInfoInputDropDownHide" >
                                <label className="lbl-text required-Label">Location</label>
                                  <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={formik.values.locationId}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                    editable= {false}
                                    maxLength={1}
                                   style={{height:"25px"}}
                                />
                                
                             </Col>
                        </Row>*/}
                        <Row>
                             <Col md={3.5} className="businessInfoInputEINTextField editCusDealLocatioWidth">
                                  <TextField
										id="dealNumber"
										name="dealNumber"
										label="Deal Number"
										placeholder=""
										autoComplete="off"
										value={formik.values.dealNumber}
                                        readOnly={true}
										//required={true}
										onChange={formik.handleChange}
                                        style={{height:"25px"}}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.dealNumber &&
                                            formik.errors && formik.errors.dealNumber &&
                                            formik.errors.dealNumber.toString()
										}
									/>                               
                             </Col>
                             <Col md={3.5} className="businessInfoInputDropDownHide editCusDealLocatioWidth locOptionTextWrab" >
                                <label className="lbl-text ">Location</label>
                                  <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={formik.values.locationId}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                    editable= {false}
                                    maxLength={1}
                                   style={{height:"auto"}}
                                />
                                
                             </Col>
                             <Col md={2.5} className="businessInfoInputEINTextField editCusNoWidth">
                                  <TextField
										id="customerNumber"
										name="customerNumber"
										label="Customer#"
										placeholder=""
										autoComplete="off"
									    value={formik.values.customerNumber}
                                        readOnly={true}
										//required={true}
										onChange={formik.handleChange}
                                        style={{height:"25px"}}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.customerNumber &&
                                            formik.errors && formik.errors.customerNumber &&
                                            formik.errors.customerNumber.toString()
										}
									/>                            
                             </Col>
                            <Col md={2.5} style={{marginTop:"20px"}}>
                                  <PrimaryButton
                                      text="Edit Deal"
                                      onClick={handleChangeCustomer}
                                      //className="editdealEditCusBtn"
                                  />
                             </Col>
                        </Row>
                        <div >
                            <hr/>
                        </div>
                        
                        <Row >                           
                             <Col md={12}  >

                                   <Row style={{width:"auto",}} className="dealRecordMobButAlign ">  
                                     <Col  className="editDealRecordWidth columnBtwSpace" style={{textAlign: "right"}}>

                                         <PrimaryButton
                                             text="Individual"
                                             onClick={handleIndividual} 
                                             style={{width:"90px"}}
                                             className={formik.values.customerType === 1 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"} 
                                            
                                         />
                                     </Col>                                   
                                     <Col className="editDealRecordWidth columnBtwSpace" style={{textAlign: "left"}}>
                                         <PrimaryButton                                 
                                             text="Business" 
                                             onClick={handleBussiness}
                                             style={{width:"90px"}}
                                             className={formik.values.customerType === 2 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"} 
                                         />
                                     </Col>
                                   </Row>
                             </Col>
                        </Row>

                        {formik.values.customerType ===1 ? 
                          <Row>
                            <Col md={6}>
                                <TextField
										id="firstName"
										name="firstName"
										label="First Name"
										placeholder=""
										autoComplete="off"
										value={formik.values.firstName}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.firstName &&
                                            formik.errors && formik.errors.firstName &&
                                            formik.errors.firstName.toString()
										}
									/>
                            </Col>
                            <Col md={6}>
                                <TextField
										id="lastName"
										name="lastName"
										label="Last Name"
										placeholder=""
										autoComplete="off"
										value={formik.values.lastName}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.lastName &&
                                            formik.errors && formik.errors.lastName &&
                                            formik.errors.lastName.toString()
										}
									/>
                            </Col>
                          </Row> :""}
                        {formik.values.customerType ===2 ? 
                          <Row>
                            <Col md={12} >
                                <TextField
										id="businessName"
										name="businessName"
										label="Business Name"
										placeholder=""
										autoComplete="off"
										value={formik.values.businessName}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.businessName &&
                                            formik.errors && formik.errors.businessName &&
                                            formik.errors.businessName.toString()
										}
									/>
                            </Col>
                        </Row>:""
                        }
                        
                        <Row>
                            <Col md={6}>
                                <div className="ms-TextField root-155">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text required-Label">Phone</label>
                                        <div className={formik.touched.phoneNumber &&
                                            formik.errors.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                            <InputMask
                                                autoClear={false}
                                                required={true}
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                mask="(999)999-9999"
                                                placeholder=""
                                                value={formik.values.phoneNumber}
                                                className="mask-TextField"
                                                onChange={(e) => handleMaskInput(e)}
                                                onBlur={formik.handleBlur}>
                                            </InputMask>
                                        </div>
                                        <span className="error-message">{
                                            formik.touched.phoneNumber &&
                                            formik.errors.phoneNumber &&
                                            formik.errors.phoneNumber.toString()
                                        }</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <TextField
										id="email"
										name="email"
										label="Email"
										placeholder=""
										autoComplete="off"
										value={formik.values.email}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.email &&
                                            formik.errors && formik.errors.email &&
                                            formik.errors.email.toString()
										}
									/>
                            </Col>
                        </Row>
                        <div style={{ paddingTop:"15px"}}>
                             <hr/>
                        </div>
                        <Row>
                            <Col md={6}>
                            {/*<Dropdown
                                                  id="salesManagerId"
                                                  label="Sales Manager"
                                                  placeholder=""
                                                  selectedKey={formik.values.salesManagerId}
                                                  options={employeeList}
                                                  onChange={handleDropdownSalesValue}
                                                  required={true} 
                                                  //className="addTeamWidth"
                                                  //className={iconClass}
                                     /> */}
                            <label className="lbl-text required-Label">Sales Manager</label>
                            <PRDropdown
                                id="salesManagerId"
                                placeholder=""
                                value={formik.values.salesManagerId}
                                options={employeeList}
                                onChange={handleDropdownSalesValue}
                                optionLabel="text"
                                optionValue="key"
                                appendTo="self"
                                className="invMakeDropdown labelTextBorder full-width"
                                filter={true}
                                resetFilterOnHide={true}
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Row>
                                     <Col>
                                           
                                    {/*<Dropdown
                                                  id="salesPerson1Id"
                                                  label="Sales 1"
                                                  placeholder=""
                                                  selectedKey={formik.values.salesPerson1Id}
                                                  options={sales1}
                                                  onChange={handleDropdownValue}
                                                  required={true}
                                                  //className="addTeamWidth"
                                                  //className={iconClass}
                                           /> */}
                                    <label className="lbl-text required-Label">Sales 1</label>
                                    <PRDropdown
                                        id="salesPerson1Id"
                                        placeholder=""
                                        value={formik.values.salesPerson1Id}
                                        options={sales1}
                                        onChange={handleDropdownValue}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="invMakeDropdown labelTextBorder full-width"
                                        filter={true}
                                        resetFilterOnHide={true}
                                    />
                                     </Col>
                                </Row>
                                <Row>
                                     <Col>
                                           
                                    {/*<Dropdown
                                                  id="salesPerson2Id"
                                                  label="Sales 2"
                                                  placeholder=""
                                                  selectedKey={formik.values.salesPerson2Id}
                                                  options={sales2}
                                                  onChange={handleDropdownValue}
                                                  //required={true}
                                        // className="addTeamWidth"
                                        //className={iconClass}
                                        /> */}
                                    <label className="lbl-text">Sales 2</label>
                                    <PRDropdown
                                        id="salesPerson2Id"
                                        placeholder=""
                                        value={formik.values.salesPerson2Id}
                                        options={sales2}
                                        onChange={handleDropdownValue}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="invMakeDropdown labelTextBorder full-width"
                                        filter={true}
                                        resetFilterOnHide={true}
                                    />
                                     </Col>
                                </Row>
                            </Col>
                            <Col md={6} >
                                 <Row>
                                     <Col>
                                    {/*<Dropdown
                                             id="financePerson1Id"
                                             label="Finance 1"
                                             placeholder=""
                                             selectedKey={formik.values.financePerson1Id}
                                             options={finance1}
                                             //className="addTeamWidth"
                                             onChange={handleDropdownValue}
                                             //required={true}
                                           />*/}
                                    <label className="lbl-text">Finance 1</label>
                                    <PRDropdown
                                        id="financePerson1Id"
                                        placeholder=""
                                        value={formik.values.financePerson1Id}
                                        options={finance1}
                                        onChange={handleDropdownValue}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="invMakeDropdown labelTextBorder full-width"
                                        filter={true}
                                        resetFilterOnHide={true}
                                    />
                                     </Col>
                                </Row>
                                <Row>

                                     <Col>
                                           
                                    {/*<Dropdown
                                             id="financePerson2Id"
                                             label="Finance 2"
                                             placeholder=""
                                             selectedKey={formik.values.financePerson2Id}
                                             options={finance2}
                                             className="addTeamWidth"
                                             onChange={handleDropdownValue}
                                             //required={true}
                                        />*/}
                                    <label className="lbl-text">Finance 2</label>
                                    <PRDropdown
                                        id="financePerson2Id"
                                        placeholder=""
                                        value={formik.values.financePerson2Id}
                                        options={finance2}
                                        onChange={handleDropdownValue}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="invMakeDropdown labelTextBorder full-width"
                                        filter={true}
                                        resetFilterOnHide={true}
                                    />
                                     </Col>
                                </Row>
                             </Col>
                        </Row>
                </Stack>
            </div>
            <div className={contentStyles.footer}>
                <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">
                    
                    <PrimaryButton
                        //disabled={!formik.dirty || !formik.isValid}
                        onRenderIcon={renderSpinner}
                        text="Save"
                        onClick={handleUpdateDeal}
                        disabled={((!formik.isValid || Object.keys(formik.errors).length > 0) && (editDealsDialog === 'PATCH'))}
                    />
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 650,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 85,
        paddingRight: 85,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
