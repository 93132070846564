import {
    Icon,
    Link,
    TextField as OffTextField,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-grid-system";
//import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import HomeIcon from '@mui/icons-material/Home';
import AddCircle from '@mui/icons-material/AddCircle';

// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ShopWorkBookMoreActionsSubmenu } from "components/table/inventory/dropdown/ShopWorkbookMoreActionsSubmenu";
import { PRICE_PREFIX, dealTrackerOperationPermissions, themeStyles } from "constants/constants";
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import CurrencyInput from 'react-currency-input-field';
import { useDispatch, useSelector } from "react-redux";
import {useParams,useNavigate, useLocation } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { AppState } from "store";
import {
    getModulePermissions,
    getWriteUpsElementPermission,
    isKarlProfile,
    isUserMaster
} from "utils/permissions/permissionsHelpers";
//import TextField from '@material-ui/core/TextField';
import { AddInventoryFoundDialog } from "components/dialogs/addInventoryDialog/AddInventoryFoundDialog";
import { AddInventorySearchDialog } from "components/dialogs/addInventoryDialog/AddInventorySearchDialog";
import { AddInventoryTrimDialog } from "components/dialogs/addInventoryDialog/AddInventoryTrimDialog";
import { AddUpdateProductDialog } from "components/dialogs/dealTrackerDialog/AddUpdateProductDialog";
import { DealPageSaveNotification } from "components/dialogs/dealTrackerDialog/DealPageSaveNotification";
import { OrderNumberFoundDialog } from "components/dialogs/dealTrackerDialog/OrderNumberFoundDialog";
import { StockNumberFoundDialog } from "components/dialogs/dealTrackerDialog/StockNumberFoundDialog";
import masterDropdownValues from "constants/masterDropdownValues";
import moment from "moment";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import {
    HandleSaveDeliveryNote,
    handleCreateVehicleStockItem,
    //HandleGetDeliveryNotes,
    handleGetDealNotes,
    handleGetDealTracker,
    handleGetDelivery,
    handleGetSalesFinanceList,
    handleGetServiceContractList,
    handleSaveDelivery,
    handleUpdateVehicleStockItem
    // handleGetAllFinanceDeliveryPerson
    ,
    handleViewDealTracker,
    handleViewServiceContractItem
} from "store/dealTracker/dealTracker.action";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { stringToCodePhNoformat } from 'utils/helperFunctions';
//import { handleGetLocations } from "store/locations/locations.action";
import EditIcon from '@mui/icons-material/Edit';
import { SaveSuccessDialog } from "components/dialogs/addInventoryDialog/SaveSuccessDialog";
import { FinanceStatusWarningDialog } from "../../components/dialogs/dealTrackerDialog/FinanceStatusWarningDialog";
import { DealRecordFoundDialog } from "components/dialogs/dealTrackerDialog/DealRecordFoundDialog";
import { EditVehicleInfoDialog } from "components/dialogs/dealTrackerDialog/EditVehicleInfoDialog";
import { VehicleFoundDialog } from "components/dialogs/dealTrackerDialog/VehicleFoundDialog";
import { CustomerNotFoundDialog } from "components/dialogs/dealTrackerDialog/dealDialog/CustomerNotFoundDialog";
import { EditDealCustomerConfirmDialog } from "components/dialogs/dealTrackerDialog/dealDialog/EditDealCustomerConfirmDialog";
import { EditDealCustomerRecordDialog } from "components/dialogs/dealTrackerDialog/dealDialog/EditDealCustomerRecordDialog";
import { EditDealEditCustomerDialog } from "components/dialogs/dealTrackerDialog/dealDialog/EditDealEditCustomerDialog";
import { VehicleRecordFoundDialog } from 'components/dialogs/deals/VehicleRecordFoundDialog';
import { ServiceContract } from "components/tabView/dealTracker/ServiceContract";
import { Task } from "components/tabView/dealTracker/Task";
import CustomTable from "components/table/datatable/CustomTable";
import { trimTypes } from "constants/constants";
import { useFormik } from "formik";
import { useMediaQuery } from "react-responsive";
import { handleGetFinanceProduct, handleViewFinanceProductItem } from "store/dealTracker/dealTracker.action";
import { handleGetVehDetails, handleGetVehDetailsSuccess } from 'store/inventory/inventory.action';
import { convertLocalToUTCModified, convertUTCToLocalDateOnlyModified, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import * as Yup from "yup";
import { emplyeePermissionsAction } from "../../constants/constants";
//import { Cancellation } from "components/tabView/dealTracker/Cancellation";
// import {Calendar as SDCalendar, momentLocalizer, Views  } from 'react-big-calendar';
import { ScheduleManager } from "components/tabView/dealTracker/Schedule";
import * as _ from "lodash";
// import { AddScheduleDialog } from 'components/dialogs/dealTrackerDialog/AddScheduleDialog';
import { TransferWarningDialog } from 'components/dialogs/dealTrackerDialog/vehicleTrackerDialog/TransferWarningDialog';
import { Appointment } from "components/tabView/dealTracker/Appointment";
import { Cancellation } from "components/tabView/dealTracker/Cancellation";
import { TradeIns } from "components/tabView/dealTracker/TradeIns";
import { VehiclePrep } from "components/tabView/dealTracker/VehiclePrep";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

// const useStyles = makeStyles((theme: ThemeStyle) =>
//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme?.palette?.background.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography?.pxToRem(15),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography?.pxToRem(15),
//             color: theme.palette?.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 20,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette?.divider}`,
//             padding: theme?.spacing?.(1, 2),
//         },
//         link: {
//             color: theme.palette?.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//         breadCrumblink: {
//             display: 'flex',
//         },
//         instructions: {
//             marginTop: theme?.spacing?.(1),
//             marginBottom: theme?.spacing?.(1),
//         },
//         breadCrumbicon: {
//             marginRight: theme?.spacing?.(0.5),
//             width: 20,
//             height: 20,
//         },
//         startTime: {
//         },
//         textFieldSmall: {
//             '& .ms-TextField-fieldGroup,& .auctionStartTime .MuiInput-formControl .shopInventoryEdit ': {
//                 width: '125px !important',
//             },
//         },
//         shopInventoryEdit: {
//           paddingTop: '0px !important',
//         }
//     }),
// );

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: any;
//     value: any;
// }


function TabPanelView(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>

                    <Typography component={'div'}>{children}</Typography>
                </Box>
                //<Typography component={'div'}>{children}</Typography>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


// interface ParamTypes {
//     dealID: string;
// }

const credentialModuleName = "Inventory";

/* const resourceMap = [
    { resourceId: 1, resourceTitle: 'Troy' },
    { resourceId: 2, resourceTitle: 'Peter' },
    { resourceId: 3, resourceTitle: 'Dan' },
    { resourceId: 4, resourceTitle: 'Brett' },
    { resourceId: 5, resourceTitle: 'John' },
    { resourceId: 6, resourceTitle: 'Paul' },
    { resourceId: 7, resourceTitle: 'Tom' },
    { resourceId: 8, resourceTitle: 'Ray' }
]; */

export const DealTrackerPage = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    //const  classes:any  = useStyles();
    const theme: any = useTheme();
    const navigate = useNavigate ();
    const location:any = useLocation ();
    const params = useParams<any>();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    //const [product, setProduct] = React.useState([]);
    const [dealHistory, setDealHistory] = React.useState([]);
    const [credentials, setCredentials] = useState([]);
    //const yearRange = new Date().getFullYear() + ":" + (new Date().getFullYear() + 1);
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const [reqDeliveryDate, setRegDeliveryDate] = React.useState<any>(undefined);
    const [statusDate, setStatusDate] = React.useState<any>(new Date());

    const [cdrDate, setCDRDate] = React.useState<Date | Date[] | undefined | any>(undefined);
    const [isAddProductDialog, setisAddProductDialog] = useState(false);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    //const selectedSalesFinanceData = useSelector((state: AppState) => state.dealTracker.selectedSalesFinanceList);
    const dealTrackerData = useSelector((state: AppState) => state.dealTracker.selectedDealRecord);
    //const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const lenders = useSelector((state: AppState) => state.selectList.lenders) as any;
    const financeProductList = useSelector((state: AppState) => state.dealTracker.financeProductList) as any;
    const serviceContractList = useSelector((state: AppState) => state.dealTracker.serviceContractList) as any;

    const [isInventorySearchDialog, setisInventorySearchDialog] = useState(false);
    const [vehicleFoundDialogOpen, setvehicleFoundDialogOpen] = useState(false);
    const [dealID, setdealID] = useState(null);
    const [dealNumber, setDealNumber] = useState(null);
    const [existingData, setExistingData] = useState(null);
    const [isInventoryTriDialog, setisInventoryTriDialog] = useState(false);
    const [isVTStockCheckWarningDialog, setisVTStockCheckWarningDialog] = useState(false);
    const [isInventoryFoundDialog, setisInventoryFoundDialog] = useState(false);
    const [isSaveNotification, setisSaveNotification] = useState(false);
    const [nextRoutingPath, setnextRoutingPath] = useState(null);
    const [rawTabVal, setrawTabVal] = useState(0);
    const [initialDeliveryVal, setinitialDeliveryVal] = useState(null);
    const [isTradeInVehicleFoundDialog, setTradeInVehicleFoundDialog] = useState(false);
    const [trimValues, settrimValues] = React.useState([]);
    const [vinforTrim, setvinforTrim] = React.useState(null);
    const [ymmforTrim, setymmforTrim] = React.useState(null);
    const [vehicleID, setVehicleID] = useState('');
    const [recentDeal, setRecentDeal] = useState('');
    const [deliveryNote, setDeliveryNote] = React.useState(null);
    const [deliveryNoteError, setDeliveryNoteError] = React.useState(false);
    const [notesList, setNotesList] = React.useState([]);
    //const [cityDealerList, setCityDealerList] = React.useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const [updateAppSectionIndx, setupdateAppSectionIndx] = useState(null);
    const [updateTradeSectionIndx, setupdateTradeSectionIndx] = useState(null);
    // const [updateServSectionIndx, setupdateServSectionIndx] = useState(1);
    const [updateCanSectionIndx, setupdateCanSectionIndx] = useState(null);
    const [updateTaskSectionIndx, setupdateTaskSectionIndx] = useState(null);
    const [saveFormOnRouteMsgTask, setsaveFormOnRouteMsgTask] = useState(false);
    const [saveFormOnRouteMsgAppointment, setsaveFormOnRouteMsgAppointment] = useState(false);
    const [saveFormOnRouteMsgTradeIn, setsaveFormOnRouteMsgTradeIn] = useState(false);
    const [saveFormOnRouteMsgServ, setsaveFormOnRouteMsgServ] = useState(false);
    const [saveFormOnRouteMsgCancel, setsaveFormOnRouteMsgCancel] = useState(false); 
    const [displayDialog, setDisplayDialog] = useState<boolean>(false);
    const [existingVechileDeal, setExistingVechileDeal] = useState(null);

    const [saveSuccessDialog, setSaveSuccessDialog] = React.useState(false);
    const [pageLoadingFlag, setPageLoadingFlag] = React.useState(false);
    const [vehicleData, setVehicleData] = React.useState(null);
    const [tableData, setTableData] = useState([])
    const emptyData = { financeProductId: [], amount: '', id: "" };
    const emptyserviceData = { financeProduct: '', closeDate: "", referralEmployeeId: "", price: "", cost: "", profit: "", deductable: "", term: "", mileage: "", comments: "", };
    const [data, setData] = useState(emptyData);
    const [serviceFormData, setserviceFormData] = useState(null);
    const [taskFormData, setTaskFormData] = useState(null);
    const [appointmentFormData, setAppointmentFormData] = useState(null);
    const [tradeInFormData, setTradeInFormData] = useState(null);
    // const [appraisalData, setAppraisalData] = useState(emptyAppraisalData);
    const [productListID, setProductListID] = useState('');
    //const [statusUpdateError, setStatusUpdateError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEditCustomerDialog, setIsEditCustomerDialog] = useState(false);
    const [editCustomerVal, setEditCustomerVal] = useState([]);
    const [newDealData, setNewDealData] = useState(null);
    const [cusNoRetain, setCusNoRetain] = useState(null);
    const [dataValue, setDataValue] = useState(null);
    const [isCustomerNoFound, setIsCustomerNoFound] = useState(false);
    const [isOpenVehicleDia, setIsOpenVehicleDia] = useState(false);
      const [dealFoundDialogOpen, setDealFoundDialogOpen] = useState(false);
    //const [dealID, setdealID] = useState(0);
    const [trimType, setTrimType] = useState(2);
    const [orderStock, setorderStock] = useState(null);
    // const [isAddScheduleDialog, setisAddScheduleDialog] = useState(false);
    const [selectedEv, setselectedEv] = useState(null);
    const [serviceContractWorkbook, setServiceContractWorkBook] = useState(true);
    const [serviceContractData, setServiceContractData] = useState([]);
    const [serviceData, setServiceData] = useState(emptyserviceData);
    const [showDeliveryView, setShowDeliveryView] = useState(null);
    const [cancellationFormData, setCancellationFormData] = useState(null);
    const [isCustomerRecordConfirm, setIsCustomerRecordConfirm] = useState(false);
    const [isCustomerNewData, setIsCustomerNewData] = useState(false);
    const [writeUpsStatus, setWriteUpsStatus] = useState([]);
    const [writeUpsElePermission, setWriteUpsElePermission] = useState(null);
    const [isMaster, setMaster] = useState(null);
    const [aggregatedDefaultPolicies, setAggregatedDefaultPolicies] = useState(null);
    const [actualWriteUpsStatus, setActualWriteUpsStatus] = useState(null);
    const [reqDeliveryTime, setReqDeliveryTime] = useState(null);
    const [productId, setProductId] = useState([]);
    const [tabSwitchFlag, setTabSwitchFlag] = useState(false);
    const [isStockNoFound, setIsStockNoFound] = useState(false);
    const [stockValFound, setStockValFound] = useState(null);
    const [stockNoConflict, setStockNoConflict] = useState(null);
    const [popupName, setPopupName] = useState('');
    const [writeUpsReadOnly, setWriteUpsReadOnly] = useState(null);
    const [orderValFound, setOrderValFound] = useState(null);
    const [orderNoConflict, setOrderNoConflict] = useState(null);
    const [isOrderNoFound, setIsOrderNoFound] = useState(false);
    const [isVinYmmFlow, setIsVinYmmFlow] = useState(false);
    const [vtStockConflictVal, setVtStockConflictVal] = useState(null);
    const [addVehicleSection, setAddVehicleSection] = useState(null);

    const boxStatus = ["", "No", "Yes"];
    const dealType = ["", "Write-Up", "Trade-in", "Appointments"]

    const handleChangeIndex = (index: number) => {
        setValue(index);
        //setServiceContractWorkBook(true);
    };

    // const localizer = momentLocalizer(moment);

    useEffect(() => {
        // temporarily added
        //setcancellationTabTemporary(false);

        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        //setProduct([]);
        setAddVehicleSection(null)
        setDealHistory([]);
        setRegDeliveryDate(null);
        setStatusDate(new Date(convertUTCToLocalModified(new Date())));
        handleGetDeliveryInfo();
        dispatch<any>(handleGetSelectList('departments,lenders,appraisalBidders,financeProducts', 1));
        dispatch<any>(handleGetFinanceProduct(params.dealID));
        dispatch<any>(handleGetServiceContractList(params.dealID));

        if (location?.state?.tabIndex) {
            setValue(location.state.tabIndex);
            //setServiceContractWorkBook(true);
            setrawTabVal(location.state.tabIndex);
            if (productId && !tabSwitchFlag) {
                setServiceContractWorkBook(false);
                let itemId = location.state.productId
                handleEditServiceContract(itemId);
            } else {
                setServiceContractWorkBook(true);
            }
        }

        if (location?.state?.openSchedDialog) {
            // ScheduleManager(true);
            let selectedEvents = {
                financeCalendar: null,
                deliveryCalendar: null,
                isEditable: location?.state?.isEditable ? true : false,
                dealId: null,
                scheduleId: location?.state?.scheduleId,
                financeReq: location?.state?.financeReq,
                deliveryReq: location?.state?.deliveryReq,
                noFinance: location?.state?.noFinance
            }
            if (location?.state?.financeCalData) {
                selectedEvents.financeCalendar = location?.state?.financeCalData
                selectedEvents.dealId = location?.state?.financeCalData.dealId
            }
            if (location?.state?.deliveryCalData) {
                selectedEvents.deliveryCalendar = location?.state?.deliveryCalData
                selectedEvents.dealId = location?.state?.deliveryCalData.dealId
            }
            setselectedEv(selectedEvents)
        }
        setActualWriteUpsStatus(null);
        setnextRoutingPath(null);

        /*  else {
            setisAddScheduleDialog(false);
        } */


        // Finance Person
        /* dispatch<any>(handleGetAllFinanceDeliveryPerson({ locationId: 4, callback: (res) =>  {
            console.log('FINANCE PERSON', res.results);
            let financeResource = [];
            res.results.map((data) => {
                financeResource.push({
                    resourceId: data.id,
                    resourceTitle: data.firstName + ' ' + data.lastName
                });
            });
            setresourceFinance(financeResource);
        }})); */

        // Delivery Person
        /* dispatch<any>(handleGetAllFinanceDeliveryPerson({ locationId: 4, callback: (res) =>  {
            console.log('DELIVERY PERSON', res);
            let deliveryResource = [];
            res.results.map((data) => {
                deliveryResource.push({
                    resourceId: data.id,
                    resourceTitle: data.firstName + ' ' + data.lastName
                });
            });
            setresourceDelivery(deliveryResource);
        }})); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stockValFound && !vtStockConflictVal) {
            setIsOpenVehicleDia(false);
            setisInventoryTriDialog(false);
            setExistingData(null);
            setisInventoryFoundDialog(false);
            setIsStockNoFound(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockValFound]);

    useEffect(() => {

        if (vtStockConflictVal) {
            setIsOpenVehicleDia(false);
            setisInventoryTriDialog(false);
            setExistingData(null);
            setisInventoryFoundDialog(false);
            setisVTStockCheckWarningDialog(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vtStockConflictVal]);

    useEffect(() => {

        if (orderValFound && !stockValFound) {
            setIsOpenVehicleDia(false);
            setisInventoryTriDialog(false);
            setExistingData(null);
            setisInventoryFoundDialog(false);
            setIsOrderNoFound(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderValFound, stockValFound]);

    useEffect(() => {
        let statusArr = [];
        if (user) {
            const writeUpsPermissions = getWriteUpsElementPermission(user);
            setWriteUpsElePermission(writeUpsPermissions);

            const master = isUserMaster(user.roles);
            setMaster(master);
            setAggregatedDefaultPolicies(user.aggregatedDefaultPolicies);

            const writeUpsUpdateAccess = user.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_UPDATE) : master;
            setWriteUpsReadOnly(!writeUpsUpdateAccess);

            if (master) {
                masterDropdownValues.dealTrackerStatus.forEach((item: any) => {
                    statusArr.push(item);
                });
            }
            else if (user.defaultGroups) {
                const isAccess = user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19 || x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 5);
                const arr = [...masterDropdownValues.dealTrackerStatus];
                arr.forEach((item: any) => {

                    if (item.key === 3 || item.key === 4) {
                        item["disabled"] = !isAccess;
                    }
                    statusArr.push(item);
                });
            }
        }
        setWriteUpsStatus(statusArr);

    }, [user]);


    useEffect(() => {

        if (value === 0) {
            setTrimType(trimTypes.karl);
            handleGetDeliveryInfo();
        }
        else if (value === 2) {
            setTrimType(trimTypes.tradeIn);
        } else if (value === 6) {
            dispatch<any>(handleGetServiceContractList(params.dealID));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleViewDealData=()=>{
        dispatch<any>(handleViewDealTracker(params.dealID));
    }

    useEffect(() => {
        setisInventorySearchDialog(false);
        if (params.dealID) {
            if (value === 0) {
                setTrimType(trimTypes.karl);
                handleGetDeliveryInfo();
            }
            handleViewDealData();
            
            dispatch<any>(handleGetSalesFinanceList({
                id: params.dealID,
                callBck: (res) => {
                    //dispatch<any>(handleGetSelectedSalesFinanceList({
                    //    id: params.dealID,
                    //}))
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.dealID]);

    // useEffect(() => {
    //     console.log('location', location)
    //     return history.listen((action, location) => {
    //         console.log(`You changed the page to: ${action}`)
    //         console.log(`You changed the page to: ${location}`)
    //         // history.goBack();

    //     })
    // }, [history, location])

    useEffect(() => {
        if (dealTrackerData) {
            console.log('dealTrackerData', dealTrackerData);
            setPageLoadingFlag(true);
            if (dealTrackerData.vehicle) {
                handleGetDeliveryInfo();
                setTimeout(() => {
                    setVehicleData(dealTrackerData.vehicle);
                }, 100)
            } else {
                setVehicleData(null);
            }
        }

        if (dealTrackerData?.vehicle?.isAddedByVin) {
            setIsVinYmmFlow(true)
        } else {
            setIsVinYmmFlow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealTrackerData]);

    const statusDateLabel = { "1": "Pending Date", "2": "Order Out Date", "3": "Closed Date", "4": "Void Date", "5": "Shipped Date" };
    const statusDateProperty = { "1": "pendingDate", "2": "orderOutDate", "3": "closedDate", "4": "voidDate", "5": "shippedDate" };

    const deliveryFormik = useFormik({
        initialValues: {
            id: "",
            lenderId: "",
            //locationId: "",
            departmentId: "",
            requestDeliveryDate: "",
            closedDate: "",
            pendingDate: "",
            shippedDate: "",
            voidDate: "",
            orderOutDate: "",
            requestDeliveryTime: "",
            status: "",
            deliveryType: "",
            cdr: 1,
            cdrDate: "",
            fleet: 1,
            fleetNumber: "",
            cpo: 1,
            sfe: 1,
            cda: 1,
            locate: 1,
            deliveryCode: "",
            financeStatus: "",
            deposit: "",
            grossProfit: "",
            //financeIncome: "",
            creditScore: "",
            //rONumber: "", 
            //recallsVerifiedCompletedDate: "",
            //nhstaCheckedDate: "",
            //activeRecalls: "",
            //serviceBulletins: "",
        },
        validationSchema: Yup.object({
            lenderId: Yup.string(),
            departmentId: Yup.string().required('Department required.'),
            requestDeliveryDate: Yup.string(),
            closedDate: Yup.string().when('status',
                {
                    is: 3,
                    then: Yup.string().required('Close date required.'),
                    otherwise: schema => schema
                }),
            pendingDate: Yup.string().when('status',
                {
                    is: 1,
                    then: Yup.string().required('Pending date required.'),
                    otherwise: schema => schema
                }),
            voidDate: Yup.string().when('status',
                {
                    is: 4,
                    then: Yup.string().required('Void date required.'),
                    otherwise: schema => schema
                }),
            orderOutDate: Yup.string().when('status',
                {
                    is: 2,
                    then: Yup.string().required('Order out date required.'),
                    otherwise: schema => schema
                }),
            shippedDate: Yup.string().when('status',
                {
                    is: 5,
                    then: Yup.string().required('Shipped date required.'),
                    otherwise: schema => schema
                }),
            requestDeliveryTime: Yup.string(),
            status: Yup.number().required('Status required.'),
            deliveryType: Yup.number().required('Delivery type required.'),
            cdr: Yup.number(),
            cdrDate: Yup.string(),
            fleet: Yup.number(),
            fleetNumber: Yup.string()
                .when('fleet',
                    {
                        is: 2,
                        then: Yup.string().required('Fleet required.'),
                        otherwise: schema => schema
                    }),
            cpo: Yup.number(),
            sfe: Yup.number(),
            cda: Yup.number(),
            locate: Yup.number(),
            financeStatus: Yup.number(),
            deliveryCode: Yup.number(),
            deposit: Yup.string(),
            grossProfit: Yup.string(),
            //financeIncome: Yup.string(),
            creditScore: Yup.number(),
            id: Yup.string()
        }),
        onSubmit: (values) => {

            //values.financeIncome = values.financeIncome?.replace('$', '').replace(',', '');
            values.deposit = values.deposit?.replace('$', '').replace(',', '');
            values.grossProfit = values.grossProfit?.replace('$', '').replace(',', '');
            values.deliveryCode = ((values.fleet === 2) || (values.sfe === 2) ? (values.deliveryCode) : "")

            let startTime;
            if (values.requestDeliveryTime) {
                const dateObj = values.requestDeliveryDate ? new Date(values.requestDeliveryDate + ' ' + values.requestDeliveryTime) : values.requestDeliveryTime;

                let utcStart = moment(dateObj, "HH:mm").utc();
                startTime = utcStart.format();
            }
            const data = {
                "cdr": values.cdr,
                "cdrDate": values.cdrDate ? convertLocalToUTCModified(values.cdrDate) : '',
                "cpo": values.cpo,
                "creditScore": values.creditScore ? Number(values.creditScore) : '',
                "status": values.status,
                "deliveryType": values.deliveryType,
                "departmentId": values.departmentId,
                "deposit": values.deposit?.replace(/\$|,/g, ''),
                "cda": values.cda,
                //"financeIncome": values.financeIncome?.replace(/\$|,/g, ''),    
                "financeStatus": values.financeStatus,
                "deliveryCode": values.deliveryCode,
                "fleet": values.fleet,
                "fleetNumber": values.fleetNumber,
                "grossProfit": values.grossProfit?.replace(/\$|,/g, ''),
                "lenderId": values.lenderId,
                "locate": values.locate,
                //"locationId": values.locationId,
                "requestDeliveryDate": values.requestDeliveryDate ? convertLocalToUTCModified(values.requestDeliveryDate) : '',
                //"pendingDate": '',
                //"orderOutDate": '',
                //"closedDate": '',
                //"voidDate": '',
                "sfe": values.sfe,
                "requestDeliveryTime": startTime,
                "id": values.id,
                //"rONumber": values.rONumber, 
                //"recallsVerifiedCompletedDate": values.recallsVerifiedCompletedDate,
                //"nhstaCheckedDate": values.nhstaCheckedDate,
                //"activeRecalls": values.activeRecalls,
                //"serviceBulletins": values.serviceBulletins,
            };
            console.log("Payload:", data);
            if (!data.fleetNumber) {
                delete data.fleetNumber;
            }
            if (data.cdrDate === '') {
                delete data.cdrDate;
            }
            //if (!data.status) {
            //    delete data.status;
            //}
            if (!data.deliveryType) {
                delete data.deliveryType;
            }

            if (!data.financeStatus) {
                delete data.financeStatus;
            }
            if (!data.deliveryCode) {
                delete data.deliveryCode;
            }
            if (!data.lenderId) {
                delete data.lenderId;
            }
            if (data.requestDeliveryDate === '') {
                delete data.requestDeliveryDate;
            }
            //if (data.closedDate === '') {
            //    delete data.closedDate;
            //}
            if (!data.creditScore) {
                delete data.creditScore;
            }
            if (values.status && values[statusDateProperty['' + values.status]]) {
                const prop = statusDateProperty['' + values.status];
                data[prop] = convertLocalToUTCModified(values[prop]);
            }

            dispatch<any>(handleSaveDelivery({
                deal: data, id: params.dealID, callback: (res) => {
                    if (res) {
                        setSaveSuccessDialog(true);
                        setinitialDeliveryVal(values);
                        // after saving Route automatically to another tab
                        if (isSaveNotification) {
                            reroute();
                        }

                    } else {
                        setcommonServerError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                        setisSaveNotification(false);
                    }
                }
            }));
        }
    });

    const setDeliveryFormik = (data) => {
        let local = '';
        setReqDeliveryTime('hold');

        if (data.requestDeliveryTime) {
            let reqDate = moment.utc(data.requestDeliveryTime).format('YYYY-MM-DD HH:mm:ss');
            let stillUtc = moment.utc(reqDate).toDate();
            local = moment(stillUtc).local().format('HH:mm');
            setReqDeliveryTime(moment(stillUtc).local());
        } else {
            setReqDeliveryTime(null);
        }

        let newObj = {
            "cdr": data.cdr ? data.cdr : 1,
            "cdrDate": data.cdrDate ? convertUTCToLocalModified(data.cdrDate) : '',
            "cpo": data.cpo ? data.cpo : 1,
            "creditScore": data.creditScore,
            "status": data.status,
            "deliveryType": data.deliveryType,
            "departmentId": data.department && data.department.id,
            "deposit": data.deposit === '0.00' ? '' : data.deposit,
            "cda": data.cda ? data.cda : 1,
            //"financeIncome": data.financeIncome === '0.00' ? '' : data.financeIncome,
            "financeStatus": data.financeStatus,
            "deliveryCode": data.deliveryCode,
            "fleet": data.fleet ? data.fleet : 1,
            "fleetNumber": data.fleetNumber ? data.fleetNumber : '',
            "grossProfit": data.grossProfit === '0.00' ? '' : data.grossProfit,
            "lenderId": data.lender && data.lender.id,
            "locate": data.locate ? data.locate : 1,
            "requestDeliveryDate": data.requestDeliveryDate ? convertUTCToLocalModified(data.requestDeliveryDate) : '',
            "pendingDate": data.pendingDate ? convertUTCToLocalModified(data.pendingDate) : moment(new Date()).format("YYYY-MM-DD"),
            "orderOutDate": data.orderOutDate ? convertUTCToLocalModified(data.orderOutDate) : moment(new Date()).format("YYYY-MM-DD"),
            "shippedDate": data.shippedDate ? convertUTCToLocalModified(data.shippedDate) : moment(new Date()).format("YYYY-MM-DD"),
            "closedDate": data.closedDate ? convertUTCToLocalModified(data.closedDate) : moment(new Date()).format("YYYY-MM-DD"),
            "voidDate": data.voidDate ? convertUTCToLocalModified(data.voidDate) : moment(new Date()).format("YYYY-MM-DD"),
            "requestDeliveryTime": local,
            "sfe": data.sfe ? data.sfe : 1,
            "id": data.id,
            //"rONumber": data.rONumber,
            //"recallsVerifiedCompletedDate": data.recallsVerifiedCompletedDate,
            //"nhstaCheckedDate": data.nhstaCheckedDate,
            //"activeRecalls": data.activeRecalls,
            //"serviceBulletins": data.serviceBulletins,

        }

        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                ...newObj
            });
        setActualWriteUpsStatus(data.status);
        setinitialDeliveryVal(newObj);
        setRegDeliveryDate(data.requestDeliveryDate ? new Date(convertUTCToLocalDateOnlyModified(data.requestDeliveryDate)) : null);
        if (data.status && data[statusDateProperty['' + data.status]])
            setStatusDate(new Date(convertUTCToLocalModified(data[statusDateProperty['' + data.status]])));
        else
            setStatusDate(new Date());
        setCDRDate(data.cdrDate ? new Date(convertUTCToLocalDateOnlyModified(data.cdrDate)) : null);
    };

    const handleGetDeliveryInfo = () => {
        dispatch<any>(handleGetDelivery({
            id: params.dealID, callback: (res) => {
                setShowDeliveryView(false);
                if (!res) {
                    setcommonServerError('Failed to Fetch the Data.');
                    window.scrollTo(0, 0);
                } else if (!res.noContentStatus) {
                    setDeliveryFormik(res);
                    //if (res.dealDeliveryNotes)
                    //setNotesList(res.dealDeliveryNotes.reverse());
                    setShowDeliveryView(true);
                }
            }
        }));
    }

    const handleCreatDelivery = () => {
        dispatch<any>(handleSaveDelivery({
            deal: {}, id: params.dealID, callback: (res) => {
                if (res) {
                    setDeliveryFormik(res);
                    setShowDeliveryView(true);
                } else {
                    setcommonServerError('Failed to Save the Data.');
                    window.scrollTo(0, 0);
                }
            }
        }));
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number, pageNavigation = false) => {
        console.log('newValue', newValue)
        console.log('value', value)
        setrawTabVal(newValue);
        setcommonServerError('');
        setTabSwitchFlag(true)
        if (value === 0) {
            if (_.isEqual(initialDeliveryVal, deliveryFormik.values) === false && initialDeliveryVal) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
            }
        } else if (value === 1) {
            if (_.isEqual(appointmentFormData?.oldData, appointmentFormData?.newData) === false) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
                // setisSaveNotification(false);
            }
        } else if (value === 2) {
            if (_.isEqual(tradeInFormData?.oldData, tradeInFormData?.newData) === false) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
            }
        } else if (value === 3) {
            if (_.isEqual(cancellationFormData?.oldData, cancellationFormData?.newData) === false) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
                // setisSaveNotification(false);
            }
        } else if (value === 5) {
            if (_.isEqual(taskFormData?.oldData, taskFormData?.newData) === false) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
                // setisSaveNotification(false);
            }
        } else if (value === 6) {
            if (_.isEqual(serviceFormData?.oldData, serviceFormData?.newData) === false) {
                if (pageNavigation) {
                    return true;
                } else {
                    setisSaveNotification(true);
                    return false;
                }
                // setisSaveNotification(false);
            }
        }
        //console.log('appraisalFormik', appraisalFormik);
        setValue(newValue);
        setServiceContractWorkBook(true);
        if (pageNavigation) {
            return false;
        }
    };

    useEffect(() => {
        if (financeProductList && financeProductList.results) {
            let products = [];
            financeProductList.results.forEach((item, index) => {
                let obj = { amount: item.amount, financeProduct: item.financeProduct.name, id: item.id };
                //
                products.push(obj);
            });

            setTableData(products);
        } else {
            setTableData([]);
        }
    }, [financeProductList]);

    useEffect(() => {
        //
        if (serviceContractList) {
            for (var i in serviceContractList.results) {
                serviceContractList.results[i]["closeDate"] = convertUTCToLocalDateOnlyModified(serviceContractList.results[i].closeDate);
                serviceContractList.results[i]["product"] = serviceContractList.results[i].financeProduct ? serviceContractList.results[i].financeProduct.name : '';
                // serviceContractList.results[i]["term"] = serviceContractList.results[i].term ;
                serviceContractList.results[i]["empName"] = serviceContractList.results[i].referral ? serviceContractList.results[i].referral.firstName + ' ' + serviceContractList.results[i].referral.lastName : '';
            }
            setServiceContractData(serviceContractList.results);
        } else {
            setServiceContractData([]);
        }
    }, [serviceContractList]);


    useEffect(() => {
        const stateVal: any = location?.state;
        setProductId(null);

        if (stateVal?.reloadData) {
            setProductId(stateVal?.productId);
            setTabSwitchFlag(false);
        }
        else if (stateVal?.workbookName) {
            setProductId(stateVal?.productId)
        }

    }, [location]);

    const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        }
        else if (data && data.value.length) {
            navigate(`/${data.value[0].rowData.id}/shop-inventory`);

        }
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (

                    <ShopWorkBookMoreActionsSubmenu
                        modulePermissions={user.aggregatedDefaultPolicies}
                        vehicleID={rowData.id}
                        status={rowData.status}
                        onPurchase={(vehicleID) => { }}
                        onEdit={(vehicleID) => { }}
                        onStatusChange={(vehicleID, status) => { }}
                    />
                )}


            </React.Fragment>
        );
    };

    const navigatetoOpenProductDialog = () => {
        setData(emptyData);
        setisAddProductDialog(true)
    };

    const handleAddFinanceProductDismiss = () => {
        setisAddProductDialog(false);
        setProductListID(productListID)
    };



    const navtoOpenServiceContractPage = () => {
        setServiceData(emptyserviceData);
        setServiceContractWorkBook(false);

    };

    const navtoOpenServiceContractWorkbook = () => {
        dispatch<any>(handleGetServiceContractList(params.dealID));
        setServiceContractWorkBook(true)
    };

    const handleAddInventoryDialogDismiss = (vehicleId, vin) => {
        setDealNumber(null);
        if (vehicleId && Number.isInteger(vehicleId)) {
            setcommonServerError('');
            if (trimType === trimTypes.tradeIn) {
                dispatch<any>(handleGetDealTracker({
                    id: vehicleId, callback: (res) => {
                        setisInventorySearchDialog(false);
                        if (res) {
                            res["vin"] = vin;
                            setRecentDeal(res);
                            setTradeInVehicleFoundDialog(true);
                        } else {
                            setcommonServerError('Failed to Fetch Data.');
                        }
                    }
                }));
            } else if (trimType === trimTypes.editDelivery && vehicleId) {
                // KARL - 
                setdealID(vehicleId);
                setvehicleFoundDialogOpen(true)
                // navigate(`/${vehicleId}/dealtracker`);
            } else if (trimType === trimTypes.karl) {
                dispatch<any>(handleGetDealTracker({
                    id: vehicleId, callback: (res) => {
                        setisInventorySearchDialog(false);
                        if (res) {
                            setExistingData(res);
                            if (res && res.dealDelivery && res.dealDelivery.status && parseInt(res.dealDelivery.status) <= 2) {
                                setDealNumber(res.dealNumber);
                                setdealID(vehicleId);
                                setvehicleFoundDialogOpen(true);
                            } else {
                                dispatch<any>(handleGetVehDetailsSuccess(res.vehicle));
                                setVehicleID(vehicleId);
                                setExistingVechileDeal(res);
                                //setExistingData(res);
                                setisInventoryFoundDialog(true);
                            }
                        } else {
                            setcommonServerError('Failed to Fetch Data.');
                        }
                    }
                }));
            } else {
                setVehicleID(vehicleId);
                // setisInventoryFoundDialog(true);
            }
        } else {
            if (vehicleId === 'YMM order Number') {
                setPopupName(vehicleId);
            } else {
                setPopupName(null);
            }
            setisInventorySearchDialog(false);
        }
    }

    const openTrimDialog = ({ vin, trim, ymm }) => {
        resetVinYmm();
        setisInventorySearchDialog(false);
        settrimValues(trim);
        setisInventoryTriDialog(true);
        if (vin) {
            setvinforTrim(vin);
        } else {
            setymmforTrim(ymm);
        }
    };

    const openTrimDialogEditVeh = ({ vin, trim, ymm, order, stock }) => {
        if (vin) {
            setvinforTrim(vin);
        } else {
            setymmforTrim(ymm);
        }

        setorderStock({
            orderNumber: order,
            stockNumber: stock,
            dealId: params.dealID,
            vehicleId: dealTrackerData.vehicle.id,
            vin: vin
        });

        setTrimType(trimTypes.editDelivery)
        resetVinYmm();
        setisInventorySearchDialog(false);
        settrimValues(trim);
        setIsOpenVehicleDia(false);
        setisInventoryTriDialog(true);
    };

    const openVehDialogFound = (vehicleId) => {
        setdealID(vehicleId);
        setIsOpenVehicleDia(false);
        dispatch<any>(handleGetDealTracker({
            id: vehicleId, callback: (res) => {
                if (res) {
                    setDealNumber(res.dealNumber);
                    setExistingData(res);
                    setvehicleFoundDialogOpen(true);
                } else {
                    setcommonServerError('Failed to Fetch Data.');
                }
            }
        }));
    }

    const openStockNumberFound = (res) => {
        setStockValFound(res)
    }

    const openStockConflictFound = (res) => {
        setStockNoConflict(res)
    }

    const openOrderNumberFound = (res) => {
        setOrderValFound(res)
    }

    const openOrderConflictFound = (res) => {
        setOrderNoConflict(res)
    }

    const handleSaveStockNumber = (cancelOrderValFound = false) => {

        setIsStockNoFound(false);
        if (orderValFound && !cancelOrderValFound) {
            setIsOrderNoFound(true);
        }
        else if (stockNoConflict && popupName === "New Vin stock Number") {
            let data = { ...stockNoConflict };
            if (cancelOrderValFound) {
                data["orderNumber"] = null;
            }
            let trimType = trimTypes.karl

            dispatch<any>(handleGetVehDetails({
                vehDet: data, trimType,
                callback: (success) => {
                    if (success) {
                        setPopupName(null)
                    }
                }
            }));

        } else if (stockNoConflict && popupName === "Edit Existing Vin") {
            let data = { ...stockNoConflict };
            if (cancelOrderValFound) {
                data["orderNumber"] = null;
            }

            dispatch<any>(handleUpdateVehicleStockItem({
                id: params.dealID,
                vehicleId: dealTrackerData.vehicle.id,
                formData: data,
                callback: (res) => {
                    if (res.id) {
                        handleViewDealData();
                        setPopupName(null)
                    } else if (res.status === 409) {
                        setdealID(res.data);
                        setvehicleFoundDialogOpen(true);
                        setPopupName(null)
                    }
                }
            }));

        } else if (stockNoConflict) {
            dispatch<any>(handleCreateVehicleStockItem({
                id: params.dealID,
                formData: stockNoConflict,
                callback: (res) => {
                    if (res) {
                        handleViewDealData();
                        setPopupName(null);
                    }
                }
            }))
        }

    }


    const handleWarningDialogClose = () => {

        setisVTStockCheckWarningDialog(false);
        setVtStockConflictVal(null)
        if (stockValFound) {
            setIsStockNoFound(true);
        }

    }


    const handleSaveOrderNumber = () => {
        //setIsSaveStockNo(true);
        setIsOrderNoFound(false);
        if (orderNoConflict && popupName === "YMM order Number") {
            let trimType = trimTypes.karl

            //dispatch<any>(handleGetTrimUsingYmm({
            //    ymm: orderNoConflict,
            //    trimType,
            //    callback: (trimData) => {
            //        if (trimData) {
            //            if (trimData.length === 0) {
            //                console.log('Faild');
            //            } else if (trimData.length > 1) {
            //                const values = trimData.map((item, index) => {
            //                    return { key: item, text: item };
            //                });
            //                // settrimValues(values);
            //                openTrimDialog({
            //                    vin: null,
            //                    trim: values,
            //                    ymm: orderNoConflict
            //                });
            //            } else if (trimData.length === 1) {
            //                let vinTrim = {
            //                    style: trimData[0],
            //                    ...orderNoConflict
            //                }
            //                dispatch<any>(handleGetVehDetails({
            //                    vehDet: vinTrim, trimType,
            //                    callback: (success) => {
            //                        if (!success) {
            //                           console.log('Faild')
            //                        }
            //                    }
            //                }));
            //            }
            //        } else {
            //            console.log('Faild');
            //        }
            //    }
            //}));
            dispatch<any>(handleGetVehDetails({
                vehDet: orderNoConflict, trimType,
                callback: (success) => {
                    if (!success) {
                        console.log('Faild')
                    }
                }
            }));

        }
        else if (orderNoConflict && popupName === "New Vin stock Number") {
            let trimType = trimTypes.karl

            dispatch<any>(handleGetVehDetails({
                vehDet: orderNoConflict, trimType,
                callback: (success) => {
                    if (success) {
                        setPopupName(null)
                    }
                }
            }));

        } else if (orderNoConflict && popupName === "Edit Existing Vin") {
            //let data = { ...orderNoConflict };

            //if (!stockValFound) {
            //    data["stockNumber"] = null;
            //}

            dispatch<any>(handleUpdateVehicleStockItem({
                id: params.dealID,
                vehicleId: dealTrackerData.vehicle.id,
                formData: orderNoConflict,
                callback: (res) => {
                    if (res.id) {
                        handleViewDealData();
                        setPopupName(null)
                    } else if (res.status === 409) {
                        setdealID(res.data);
                        setvehicleFoundDialogOpen(true);
                        setPopupName(null)
                    }
                }
            }));

        } else if (orderNoConflict) {
            dispatch<any>(handleCreateVehicleStockItem({
                id: params.dealID,
                formData: orderNoConflict,
                callback: (res) => {
                    if (res) {
                        handleViewDealData();
                        setPopupName(null);
                    }
                }
            }))
        }

    }

    const stockDialogDismiss = () => {

        setIsStockNoFound(false);
        if (orderValFound) {
            let data = { ...orderNoConflict };

            data["stockNumber"] = null;

            setOrderNoConflict(data);
            setIsOrderNoFound(true);
        }
        else if (popupName === 'New Vin stock Number') {
            setisInventoryTriDialog(true);
        } else if (popupName === 'Edit Existing Vin') {
            setIsOpenVehicleDia(true);
        } else {
            setisInventoryFoundDialog(true);
        }

    }

    const orderDialogDismiss = () => {

        setIsOrderNoFound(false);

        if (stockValFound && orderNoConflict && orderNoConflict.stockNumber) {
            handleSaveStockNumber(true);
        }
        else if (popupName === 'YMM order Number') {
            setisInventorySearchDialog(true);
        }
        else if (popupName === 'New Vin stock Number') {
            setisInventoryTriDialog(true);
        } else if (popupName === 'Edit Existing Vin') {
            setIsOpenVehicleDia(true);
        } else {
            setisInventoryFoundDialog(true);
        }

    }

    const handleWarningDialogDismiss = () => {

        setisVTStockCheckWarningDialog(false);


        if (popupName === 'New Vin stock Number') {
            setisInventoryTriDialog(true);
        }
        else if (popupName === 'Edit Existing Vin') {
            setIsOpenVehicleDia(true);
        } else {
            setisInventoryFoundDialog(true);
        }

    }


    const trimDialogDismisss = (msg) => {
        setisInventoryTriDialog(false);
        setPopupName(msg);


    }

    const resetVinYmm = () => {
        setvinforTrim(null);
        setymmforTrim(null);
    }

    const handleBoxClick = (e, val, id, isCDR = true) => {
        if ((e.target.nodeName !== 'SPAN' && e.target.nodeName !== 'SELECT' && e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'INPUT') || isCDR) {

            if (val === 1) {
                val = 2;
            } else {
                val--;
            }
            deliveryFormik.setValues(
                {
                    ...deliveryFormik.values,
                    [id]: val,
                }
            );
        }
    }

    const handleDealTrackerFormikDDChange = (e: { value: any, target: any }) => {
        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                [e.target.id]: e.value,
            }
        );
        console.log(convertUTCToLocalModified(deliveryFormik.values.pendingDate))
        if (e.target.id === 'status') {
            if (e.value === 1) {
                setStatusDate(deliveryFormik.values.pendingDate ? new Date(convertUTCToLocalModified(deliveryFormik.values.pendingDate)) : null);
            }
            else if (e.value === 2) {
                setStatusDate(deliveryFormik.values.orderOutDate ? new Date(convertUTCToLocalModified(deliveryFormik.values.orderOutDate)) : null);
            }
            else if (e.value === 3) {
                setStatusDate(deliveryFormik.values.closedDate ? new Date(convertUTCToLocalModified(deliveryFormik.values.closedDate)) : null);
            }
            else if (e.value === 4) {
                setStatusDate(deliveryFormik.values.voidDate ? new Date(convertUTCToLocalModified(deliveryFormik.values.voidDate)) : null);
            } else if (e.value === 5) {
                setStatusDate(deliveryFormik.values.shippedDate ? new Date(convertUTCToLocalModified(deliveryFormik.values.shippedDate)) : null);
            }
        }
    }

    const handleDealTrackerFormikSumbit = async () => {
        const selectedFinanceStatus = Number(deliveryFormik.values?.financeStatus ?? null);
        const selectedStatus = Number(deliveryFormik.values?.status ?? null);        

        const closedStatusKey = masterDropdownValues.dealTrackerStatus.find(status => status.text === 'Closed')?.key;
        const pendingFinanceKey = masterDropdownValues.dealFinanceStatus.find(status => status.text === 'Pending')?.key;
        const qualifiedFinanceKey = masterDropdownValues.dealFinanceStatus.find(status => status.text === 'Qualified')?.key;
        const isFinanceStatusEmpty = !deliveryFormik.values.financeStatus    ;

        const isClosedWithEmptyFinanceStatus = selectedStatus === closedStatusKey && isFinanceStatusEmpty    ;

        if (isClosedWithEmptyFinanceStatus) {
            setDisplayDialog(true);
            setSaveSuccessDialog(false);
        } else {
            if (selectedStatus === closedStatusKey) {
                if (selectedFinanceStatus === pendingFinanceKey || selectedFinanceStatus === qualifiedFinanceKey) {
                    setDisplayDialog(true);
                    setSaveSuccessDialog(false);
                } else if (selectedFinanceStatus === masterDropdownValues.dealFinanceStatus.find(status => status.text === 'Approved')?.key || selectedFinanceStatus === masterDropdownValues.dealFinanceStatus.find(status => status.text === 'Cash')?.key) {
                    setSaveSuccessDialog(true);
                    deliveryFormik.handleSubmit();  
                    setDisplayDialog(false);
                } else {
                    setSaveSuccessDialog(true);
                    deliveryFormik.handleSubmit();
                    setDisplayDialog(false);
                }
            } else {
                setSaveSuccessDialog(true);
                deliveryFormik.handleSubmit();
                setDisplayDialog(false);
            }
        }
    };


    const handleDateChange = (val, propName) => {
        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                [propName]: val ? moment(val).format("YYYY-MM-DD") : ''
            }

        );
        if (propName === "requestDeliveryDate") {
            setRegDeliveryDate(val)
        } else {
            setCDRDate(val)
        }
    };



    const handleStatusDateChange = (val) => {
        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                [statusDateProperty['' + deliveryFormik.values.status]]: val ? moment(val).format("YYYY-MM-DD") : ''
            }

        );
        setStatusDate(val);
    };

    const handleReqDeliveryTimeChange = (value) => {
        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                'requestDeliveryTime': value ? value.format('HH:mm') : ''
            }
        );
    }

    const getDeliveryNotesDetails = () => {
        dispatch<any>(handleGetDealNotes({
            dealId: params.dealID,
            //deliveryId: deliveryFormik.values.id,
            callback: (response) => {
                setNotesList(response);
            }
        }));
    }

    useEffect(() => {
        if (value === 0) {
            getDeliveryNotesDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    console.log("valuevaluevalue", value)

    const saveDeliveryNotesDetails = () => {
        if (deliveryNote && deliveryNote.length > 0) {
            setDeliveryNoteError(false);
            setDeliveryNote(null)
            dispatch<any>(HandleSaveDeliveryNote({
                dealId: params.dealID,
                deliveryId: deliveryFormik.values.id,
                details: {
                    description: deliveryNote
                },
                callback: (response) => {
                    getDeliveryNotesDetails();
                }
            }));
        } else {
            setDeliveryNoteError(true);
        }
    }

    const handleDeliveryNoteChange = (e, newValue) => {
        setDeliveryNote(newValue);
    }

    const amountBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Amount</span>
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                    {currencyNegValFractionalFormat(rowData.amount)}
                </div>

            </React.Fragment>
        );
    };

    const addEditTemplate = (rowData) => {

        return (
            <React.Fragment>
                <span className="p-column-title">Finance Product</span>
                <div style={{ textAlign: "left", width: "100%" }}>
                    <div style={{ textAlign: "left", width: "30%" }}>
                        <EditIcon className={"addExpense"} color="inherit" style={{ ...themeStyles.icon, cursor: "pointer", float: "left", color: "#5995d2", width: "30px" }} />
                    </div>
                    <div style={{ float: "left", width: "70%", wordBreak: "normal" }}>
                        {rowData.financeProduct}
                    </div>

                </div>

            </React.Fragment>
        );
    }

    const logDateServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log Date</span>
                <div >
                    {moment(new Date(rowData.createdAt)).local().format("MM/DD/YYYY")}
                </div>

            </React.Fragment>
        );
    };

    const financeFirstServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance 1</span>
                {rowData.empName}

            </React.Fragment>
        );
    };

    //const financeSecondServiceBodyTemplate = (rowData) => {
    //    return (
    //        <React.Fragment>
    //            <span className="p-column-title">Finance 2</span>
    //            <div >
    //            </div>
    //
    //        </React.Fragment>
    //    );
    //};

    const productServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product</span>
                <div>
                    {rowData.product}
                </div>

            </React.Fragment>
        );
    };

    const priceServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Price</span>
                <div>
                    {rowData.price}
                </div>

            </React.Fragment>
        );
    };

    const costServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cost</span>
                <div >
                    {rowData.cost}
                </div>

            </React.Fragment>
        );
    };

    const deductibleServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deductible</span>
                <div >
                    {rowData.deductable}
                </div>

            </React.Fragment>
        );
    };

    const profitServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Profit</span>
                <div >
                    {rowData.profit}
                </div>

            </React.Fragment>
        );
    };

    const termBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Term</span>

                {rowData.term}

            </React.Fragment>
        );
    };

    const mileageServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mileage</span>
                <div >
                    {rowData.mileage}
                </div>

            </React.Fragment>
        );
    };

    const closeDateServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Close Date</span>
                <div>
                    {convertUTCToLocalDateOnlyModified(rowData.closeDate)}
                </div>

            </React.Fragment>
        );
    };

    const settingServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(id) => handleEditServiceContract(id)}
                    />
                )}


            </React.Fragment>
        );
    };

    // Cancellation




    /*
        const lenderCancellationAddressLine1BodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title">Lender Cancellation AddressLine1 </span>
                    {rowData.lenderCancellationAddressLine1}
    
                </React.Fragment>
            );
        };
    
        const lenderCancellationAddressLine2BodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title">Lender Cancellation AddressLine2 </span>
                    {rowData.lenderCancellationAddressLine2}
    
                </React.Fragment>
            );
        };
    
        const loanNumberBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title">Loan # </span>
                    {rowData.loanNumber}
    
                </React.Fragment>
            );
        };
    
        const customerAddressLine1BodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title"> Customer AddressLine1 </span>
                    {rowData.customerAddressLine1}
    
                </React.Fragment>
            );
        };
    
    
        const customerAddressLine2BodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title"> Customer AddressLine2 </span>
                    {rowData.customerAddressLine2}
    
                </React.Fragment>
            );
        };
    
        const proofLoanPaidOffBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <span className="p-column-title"> Proof loan is paid off </span>
                    {rowData.proofLoanPaidOff}
    
                </React.Fragment>
            );
        };
    
    */



    const fp_columns_list = {
        "financeProduct": "Finance Product",
        "amount": "Amount",
    };

    const serviceContract_columns_list = {
        "logDate": "Log Date",
        "empName": "Finance 1",
        //"financeSecond": "Finance 2",
        "product": "Product",
        "price": "Price",
        "cost": "Cost",
        "deductable": "Deductible",
        "profit": "Profit",
        "term": "Term",
        "mileage": "Mileage",
        "closeDate": "Close Date",
        "settings": "Actions"

    };

    const handleDeleteFinanceProductData = (response) => {
        if (response && response.results) {
            setisAddProductDialog(false)
            // call get Pricing Table Data
            setTableData(response.results);
        }
        //else{
        //    setStatusUpdateError('Failed to delete')
        //}
    }

    const handleEdit = (financeproductId) => {
        dispatch<any>(handleViewFinanceProductItem({
            id: params.dealID, productID: financeproductId, callback: (res) => {
                if (res) {
                    setData(res);
                }
                //else{
                //    setStatusUpdateError('Failed to fetch data')
                //}

                //setItemId(id);
                setisAddProductDialog(true);
            }
        }));
    };

    const handleEditServiceContract = (serviceContractItemId) => {

        dispatch<any>(handleViewServiceContractItem({
            id: params.dealID, productID: serviceContractItemId, callback: (res) => {
                if (res) {
                    console.log('have response', res)
                    setServiceData(res);
                    setServiceContractWorkBook(false);
                }

            }
        }));
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_VIEW?.toString())) : isMaster;
            if (cred) {
                handleEdit(event.data.id);
            }
        }
    };



    const handleServiceContractRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            if (cred || role || isKarlProfile(user.profileType)) {
                handleEditServiceContract(event.data.id);
            }
        }
    };

    const captureCellSelectionFinanceTbl = (data) => {
        if (data && data.value.length) {
            const cred = aggregatedDefaultPolicies ? aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_VIEW?.toString())) : isMaster;
            if (cred) {
                handleEdit(data.value[0].rowData.id);
            }
        }
    };

    const handleEditDealTrackerDialogDismiss = (res) => {
        setIsOpen(false);
        setDataValue(null);
        setNewDealData(null);
        if (res && res.id) {
            const  state = location?.state
            navigate(`/${res.id}/dealtracker`,{state});

        } else if (typeof res === 'number') {
            setdealID(res)
            setDealFoundDialogOpen(true);
        } else if (!res || !res.target) {
            setcommonServerError('Failed to Save the Data');
        }
    }


    const handleEditDealEditCustomerDialogDismiss = (res) => {
        setIsEditCustomerDialog(false);
        //setIsOpen(true);
        if (res.length > 0 && res[0].id) {
            setNewDealData(null)
            setDataValue(res[0])
            setIsOpen(true);
        } else if (res.dealNumber) {
            setNewDealData(res)
            setCusNoRetain(res)
            setIsCustomerNoFound(true);
        } else if (typeof res === 'number') {
            setdealID(res)
            setDealFoundDialogOpen(true);
            setIsOpen(false);
        } else {
            setIsOpen(true);
            setCusNoRetain(null);
            setDataValue(null);
            setNewDealData(null);
        }
    }

    const handleEditVehicleInfoDialogDismiss = (msg) => {
        //
        setIsOpenVehicleDia(false);
        setPopupName(msg)
    }

    const handleFoundDialogDismiss = (msg) => {

        if (msg && (typeof msg === 'string' || msg instanceof String)) {
            setcommonServerError(msg);
        }
        setExistingVechileDeal(null);
        setExistingData(null);
        setisInventoryFoundDialog(false);
        setTradeInVehicleFoundDialog(false);
        //setPopupName(msg)
    };

    const handleRouteBeforeSave = (route) => {
        if (route === 'yes') {
            console.log('location', location);
            setisSaveNotification(false);
            reroute();
        } else {
            // Save Automatically and navigate
            if (value === 0) {
                deliveryFormik.handleSubmit();
                if (!deliveryFormik.dirty || !deliveryFormik.isValid) {
                    setisSaveNotification(false);
                }
            } else if (value === 1) {
                // appointmentFormData
                setsaveFormOnRouteMsgAppointment(true);
            } else if (value === 2) {
                // tradeInFormData
                setsaveFormOnRouteMsgTradeIn(true);
                //  appraisalFormik.handleSubmit();
                //  if (!appraisalFormik.dirty || !appraisalFormik.isValid) {
                //      setisSaveNotification(false);
                //}
            } else if (value === 3) {
                // CancellationFormData
                setsaveFormOnRouteMsgCancel(true)
                // setisSaveNotification(false);
            } else if (value === 5) {
                // taskFormData
                setsaveFormOnRouteMsgTask(true)
                // setisSaveNotification(false);
            } else if (value === 6) {
                // serviceFormData
                setsaveFormOnRouteMsgServ(true)
                // setisSaveNotification(false);
            }
            // setnextRoutingPath(null);
        }
    };

    const handleNotificationDialogDismiss = () => {

        setisSaveNotification(false);

    };

    const reroute = () => {
        resetFormIfUnsavedVal();
        setTimeout(() => {
            if (nextRoutingPath) {
                navigate(nextRoutingPath);
            }
        }, 500);
        setValue(rawTabVal);
        setServiceContractWorkBook(true);
        setisSaveNotification(false);
    }

    const resetFormIfUnsavedVal = (newObj = { oldData: 1, newData: 1 }) => {
        // Delivery
        setinitialDeliveryVal(deliveryFormik.values);
        // appointments
        setAppointmentFormData(newObj);
        // tradeIns
        setTradeInFormData(newObj);
        // Task
        setTaskFormData(newObj);
        // service contract
        setserviceFormData(newObj);
        // Cancellation
        setCancellationFormData(newObj);
    };

    const onServiceFormChange = (oldData, newData) => {
        let newObj = {
            oldData,
            newData
        }
        if (oldData && newData) {
            setserviceFormData(newObj);
        }
    };

    const onAppointmentFormChange = (oldData, newData) => {
        let newObj = {
            oldData,
            newData
        }
        if (oldData && newData) {
            setAppointmentFormData(newObj);
        }
    };

    const onTradeInFormChange = (oldData, newData) => {
        let newObj = {
            oldData,
            newData
        }
        if (oldData && newData) {
            setTradeInFormData(newObj);
        }
    };

    const onTaskFormChange = (oldData, newData) => {
        let newObj = {
            oldData,
            newData
        }
        if (oldData && newData) {
            setTaskFormData(newObj);
        }
    };

    const onCancellationFormChange = (oldData, newData) => {
        let newObj = {
            oldData,
            newData
        }
        if (oldData && newData) {
            setCancellationFormData(newObj);
        }
    };

    const dismissSaveNotMsgTask = (val) => {
        if (val === 'no') {
            setsaveFormOnRouteMsgTask(false)
            setisSaveNotification(false);
        } else {
            setsaveFormOnRouteMsgTask(false)
            reroute();
        }
    }

    const dismissSaveNotMsgAppointment = (val) => {
        if (val === 'no') {
            setsaveFormOnRouteMsgAppointment(false)
            setisSaveNotification(false);
        } else {
            setsaveFormOnRouteMsgAppointment(false)
            reroute();
        }
    }

    const dismissSaveNotMsgTradeIn = (val) => {
        if (val === 'no') {
            setsaveFormOnRouteMsgTradeIn(false)
            setisSaveNotification(false);
        } else {
            setsaveFormOnRouteMsgTradeIn(false)
            reroute();
        }
    }

    const dismissSaveNotMsgServ = (val) => {
        if (val === 'no') {
            setsaveFormOnRouteMsgServ(false)
            setisSaveNotification(false);
        } else {
            setsaveFormOnRouteMsgServ(false)
            reroute();
        }
    }

    const dismissSaveNotMsgCancel = (val) => {
        if (val === 'no') {
            setsaveFormOnRouteMsgCancel(false)
            setisSaveNotification(false);
        } else {
            setsaveFormOnRouteMsgCancel(false)
            reroute();
        }
    }

    const handleBackToUsers = () => {
        console.log(value)
        // appointment
        if (value === 1) {
            // updateAppSectionIndx
            if (updateAppSectionIndx === 2) {
                setupdateAppSectionIndx(1);
                return;
            }
        } else if (value === 2) {   // trade in
            // updateTradeSectionIndx
            if (updateTradeSectionIndx === 2) {
                setupdateTradeSectionIndx(1);
                return;
            }
        } else if (value === 3) {   // cancellation 
            // updateCanSectionIndx
            if (updateCanSectionIndx === 2) {
                setupdateCanSectionIndx(1);
                return;
            }
        } else if (value === 5) {   // task
            // updateTaskSectionIndx
            if (updateTaskSectionIndx >= 2) {
                setupdateTaskSectionIndx(1);
                return;
            }
        } else if (value === 6) {   // serv
            // updateServSectionIndx
            if (!serviceContractWorkbook) {
                setServiceContractWorkBook(true);
                return;
            }
        }

        if (location?.state?.pathfrom) {
            const state = location?.state
            navigate( location?.state?.pathfrom,{state});
        } else {
            navigate("/dashboard");
        }
    }

    /* const handleSelectSlot = ({ start, end, resourceId }) => {
         const title = window.prompt('New Event name')
         var currentDate = new Date(start);
         var endTime = new Date(currentDate.getTime() + 45*60000);
 
         const obj = {
             title,
             start,
             end: endTime,
             resourceId: resourceId
         };
 
         let newScheduleSlots: any = [
             ...scheduleSlotEvents,
             obj
         ];
 
         let newDeliverySlots: any = [
             ...deliverySlotEvents,
             obj
         ];
         if (title) {
             setScheduleSlotEvents(newScheduleSlots);
         }
 
         setdeliverySlotEvents(newDeliverySlots);
     }
     
     const eventColors = (event) => {
         var backgroundColor = "event-";
         event.class
           ? (backgroundColor = backgroundColor + event.class)
           : (backgroundColor = backgroundColor + "default");
         return {
           className: backgroundColor,
           display: 'background',
           backgroundColor: '#123123'
         };
     }; */

    // const verifyRoute = (loc) => {

    //     setnextRoutingPath();
    //     setisSaveNotification(true)
    // }

    const confirmDataDialog = (res) => {
        setIsCustomerNewData(res);
        setIsCustomerRecordConfirm(true);
    };

    const handleEditCustomerData = (res) => {
        setEditCustomerVal(res)
        setIsEditCustomerDialog(true)
        setIsOpen(false)
    }

    const changeCustomerDataConfirm = () => {
        setIsCustomerRecordConfirm(false);
    };

    const confirmCreateCustomerRecord = () => {
        setIsOpen(false);
    };

    const handleConfirmCreateDealTrackerDialogDismiss = (res) => {
        setNewDealData(null);
        setIsCustomerRecordConfirm(false);
    };

    const handleVinEdit = () => {
        setisInventorySearchDialog(true);
        setIsOpenVehicleDia(false);
        setAddVehicleSection(null)
    }

    const handleAddVehicleSectionIndex = (value: any) => {
        if (value === 1) {
            setAddVehicleSection(1)
        } else if (value === 2) {
            setAddVehicleSection(2)
        }
        setisInventorySearchDialog(true);
        setIsOpenVehicleDia(false);
    }

    const handleEditDealFlow = () => {

        setIsOpen(true);
    };

    const handleSearchAgainCusNumber = () => {
        setIsCustomerNoFound(false);
        setIsEditCustomerDialog(true);
    }


    const handleCreateNewCustomer = () => {
        setIsCustomerNoFound(false);
        setIsOpen(true);
        setCusNoRetain(null);
    }

    const handleCustomerNotFoundDialogDismiss = () => {
        setIsCustomerNoFound(false)
        setCusNoRetain(null)
        setIsEditCustomerDialog(true);
    }


    const handleVTStockFoundDialog = (res) => {
        // setisVTStockCheckWarningDialog(true)
        // setisInventoryTriDialog(false)
        setVtStockConflictVal(res);
    }

    const handlePriceChange = (e: any, name: any) => {
        deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                [name]: e
            }
        );
    };
    

    return (<>
        {/* <Prompt
            when={true}
            // message={location => `Are you sure you want to go to ${location.pathname}`}
            message={() => {
                if (handleChange(null, value, true)) {
                    verifyRoute(location);
                    return false;
                } else {
                    return true;
                }
            } } 
        /> */}
        {
            isTradeInVehicleFoundDialog && trimType === trimTypes.tradeIn ?
                <VehicleFoundDialog
                    onDismiss={(msg) => handleFoundDialogDismiss(msg)}
                    recentDeal={recentDeal}
                    trimType={trimType}
                /> : null
        }
        {isInventorySearchDialog ?
            <AddInventorySearchDialog
                onDismiss={(vehicleId, vin) => handleAddInventoryDialogDismiss(vehicleId, vin)}
                openTrimDialog={openTrimDialog}
                trimType={trimType}
                orderConflictData={openOrderConflictFound}
                orderFoundDealData={openOrderNumberFound}
                addVehicleSection={addVehicleSection}
            /> : null}

        {
            vehicleFoundDialogOpen && (<VehicleRecordFoundDialog
                dealID={dealID}
                dealNumber={dealNumber}
                onDismiss={() => { setExistingData(null); setvehicleFoundDialogOpen(false) }}
                existingData={existingData}
            />)
        }

        {isInventoryTriDialog ?
            <AddInventoryTrimDialog
                onDismiss={trimDialogDismisss}
                trimValues={trimValues}
                vin={vinforTrim}
                ymm={ymmforTrim}
                vehID={null}
                trimType={trimType}
                orderStock={orderStock}
                stockData={openStockNumberFound}
                dealId={params.dealID}
                stockConflictData={openStockConflictFound}
                stockFoundDealData={openStockNumberFound}
                orderConflictData={openOrderConflictFound}
                orderFoundDealData={openOrderNumberFound}
                vtStockFoundDealData={handleVTStockFoundDialog}
            />
            : null}


        {isVTStockCheckWarningDialog ?
            <TransferWarningDialog
                isOpen={isVTStockCheckWarningDialog}
                onDismiss={handleWarningDialogDismiss}
                warningClose={handleWarningDialogClose}
            />
            : null}

        {isInventoryFoundDialog ?
            <AddInventoryFoundDialog
                //onDismiss={(msg) => setisInventoryFoundDialog(false)}

                onDismiss={(msg) => {handleFoundDialogDismiss(msg);handleViewDealData();setExistingVechileDeal(null)}}
                vehicleId={vehicleID}
                stockNumber={existingVechileDeal?.stockNumber}
                stockConflictData={openStockConflictFound}
                stockFoundDealData={openStockNumberFound}
                vtStockFoundDealData={handleVTStockFoundDialog}
                id={params.dealID}
                existingData={existingData}
            />
            : null}

        {isSaveNotification ?
            <DealPageSaveNotification
                onDismiss={handleNotificationDialogDismiss}
                handleRoute={(route) => handleRouteBeforeSave(route)}
            />
            : null
        }

        {isAddProductDialog ?
            <AddUpdateProductDialog
                onDismiss={() => handleAddFinanceProductDismiss()}
                formData={data}
                id={params.dealID}
                onDelete={handleDeleteFinanceProductData}
                allowDeactivate={aggregatedDefaultPolicies ? aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_DELETE?.toString())) : isMaster}
                readOnly={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_EDIT?.toString())) : isMaster)}
            />
            : null}
        {displayDialog && (
            <FinanceStatusWarningDialog
                displayDialog={displayDialog}
                onDismiss={() => setDisplayDialog(false)}
            />
        )}
        {saveSuccessDialog ?
            <SaveSuccessDialog
                onDismiss={() => { setSaveSuccessDialog(false); }}
            /> : null}

        {isOpen && (
            <EditDealCustomerRecordDialog
                isOpen={isOpen}
                id={params.dealID}
                ConfirmData={confirmDataDialog}
                editCustomer={handleEditCustomerData}
                newCusData={dataValue}
                newCusNo={newDealData}
                onDismiss={(msg) => handleEditDealTrackerDialogDismiss(msg)}
            />
        )}

        {isEditCustomerDialog && (
            <EditDealEditCustomerDialog
                isOpen={isEditCustomerDialog}
                data={editCustomerVal}
                retainData={cusNoRetain}
                onDismiss={(msg) => handleEditDealEditCustomerDialogDismiss(msg)}
            />
        )}

        {isCustomerNoFound && (

            <CustomerNotFoundDialog
                isOpen={isCustomerNoFound}
                openEditDialog={handleCreateNewCustomer}
                openSearchDialog={handleSearchAgainCusNumber}
                onDismiss={() => handleCustomerNotFoundDialogDismiss()}
            />
        )}

        {isCustomerRecordConfirm && (
            <EditDealCustomerConfirmDialog
                isOpen={isCustomerRecordConfirm}
                onDismiss={(msg) => handleConfirmCreateDealTrackerDialogDismiss(msg)}
                newData={isCustomerNewData}
                id={params.dealID}
                changeCustomerData={changeCustomerDataConfirm}
                confirmCustomerData={confirmCreateCustomerRecord}
            />
        )}

        {isOpenVehicleDia ?
            <EditVehicleInfoDialog
                isOpen={isOpenVehicleDia}
                onDismiss={(msg) => {handleEditVehicleInfoDialogDismiss(msg);handleViewDealData();}}
                openTrimDialog={openTrimDialogEditVeh}
                vehFound={openVehDialogFound}
                stockData={openStockNumberFound}
                dealId={params.dealID}
                vehicleVinYmmFlow={isVinYmmFlow}
                onVinEdit={handleVinEdit}
                addVehicleSectionIndex={handleAddVehicleSectionIndex}
                stockConflictData={openStockConflictFound}
                stockFoundDealData={openStockNumberFound}
                orderConflictData={openOrderConflictFound}
                orderFoundDealData={openOrderNumberFound}
                vtStockFoundDealData={handleVTStockFoundDialog}
            /> : null}

        {
            dealFoundDialogOpen && (<DealRecordFoundDialog
                dealID={dealID}
                onDismiss={() => setDealFoundDialogOpen(false)}
            />)
        }

        {
            isStockNoFound && (<StockNumberFoundDialog
                onDismiss={stockDialogDismiss}
                stockValFound={stockValFound}
                stockSave={handleSaveStockNumber}
            />)
        }

        {
            isOrderNoFound && (<OrderNumberFoundDialog
                onDismiss={orderDialogDismiss}
                orderValFound={orderValFound}
                orderSave={handleSaveOrderNumber}
            />)
        }

        <Stack tokens={{ childrenGap: 10 }} className="shopInventoryEdit dealTrackerPage">
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    bgcolor: 'background.paper',
                    display: 'block',
                }}
            >
                <div className={" vehicle-container"} style={{ paddingTop: "30px" }}>
                    {/*  { updateQValueSection ? */}
                    {
                        commonServerError ?
                            <Row><Col>
                                <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                            </Col> </Row> : null
                    }
                    <Row>
                        <Col sm={12} md={12} xs={12} lg={12}>
                            {/* <div className="qvalueNavigation">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link color="inherit">
                                    <HomeIcon className={classes.icon} />
                                    Deal Tracker
                              </Link>
                            </Breadcrumbs>

                        </div> */}

                            <img
                                alt="Back"
                                key={"back"}
                                className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                                onClick={handleBackToUsers}
                                src={`${require("assets/back_pagination.png")}`}
                            />
                        </Col>

                    </Row>
                    {pageLoadingFlag && (<>
                        <div className="deal-tracker-header">
                            <Row justify="center" className='headersecDeal'>
                                <Col>
                                    {
                                        isMobile ?
                                            <>
                                                <div > {/* style={{ paddingLeft: "150px", paddingRight: "150px" }} */}
                                                    <Row className="rowPaddingBtwSpace">
                                                        <Col>
                                                            <div>
                                                                {
                                                                    dealTrackerData.createdAt && (<div className="dealtracker-txt-info text-center">
                                                                        Logged  {moment(new Date(dealTrackerData.createdAt)).local().format('MM/DD/YY')}
                                                                    </div>)
                                                                }
                                                                <div style={{ textAlign: "center", marginTop: "15px", marginBottom: "-15px" }}>
                                                                    <span className="blueShadedText fontWeightSection editWorkBookFirstRowDeal">{dealTrackerData?.dealNumber} - {dealTrackerData?.location?.legalName} &nbsp;</span>
                                                                    <PrimaryButton onClick={handleEditDealFlow} > Edit Deal </PrimaryButton>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="editWorkBookFirstRow dealTrackerHeaderRow editWorkBookFirstRowDeal rowPaddingBtwSpace">
                                                        <Col>
                                                            <span className="blueShadedText fontWeightSection">
                                                                {
                                                                    dealTrackerData.customer ?
                                                                        (dealTrackerData.customer.customerType === 1 ?
                                                                            (dealTrackerData.customer.firstName + ' ' + dealTrackerData.customer.lastName)
                                                                            :
                                                                            (dealTrackerData.customer.businessName)
                                                                        )
                                                                        : null
                                                                }
                                                            </span>
                                                        </Col>
                                                        <Col>
                                                            <span className="blueShadedText fontWeightSection">{dealTrackerData?.customer?.phoneNumber ? stringToCodePhNoformat(dealTrackerData.customer.phoneNumber) : ''}</span>
                                                        </Col>
                                                        <Col>
                                                            <span className="blueShadedTextBox fontWeightSection float-right">
                                                                {dealTrackerData?.customer?.email}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="rowPaddingBtwSpace readOnlyFieldRowHead">
                                                        <Col xs={7} className="display-flex">
                                                            <strong className="dealtracker-txt-info-right left-title">Sales:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    !dealTrackerData.salesPerson1 && !dealTrackerData.salesPerson2 ?
                                                                        '--' :
                                                                        (
                                                                            (dealTrackerData.salesPerson1 ? (dealTrackerData.salesPerson1.firstName + ' ' + dealTrackerData.salesPerson1.lastName) : '-- ')
                                                                            + ', ' +
                                                                            (dealTrackerData.salesPerson2 ? (dealTrackerData.salesPerson2.firstName + ' ' + dealTrackerData.salesPerson2.lastName) : '--')
                                                                        )
                                                                }
                                                            </strong>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <strong className="dealtracker-txt-info-right">Sales Mgr - </strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    dealTrackerData.salesPerson1 && dealTrackerData.salesPerson1.manager ?
                                                                        (dealTrackerData.salesPerson1.manager.firstName + ' ' + dealTrackerData.salesPerson1.manager.lastName)
                                                                        : '--'
                                                                }
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className="rowPaddingBtwSpace readOnlyFieldRowHead">
                                                        <Col xs={7} className="display-flex">
                                                            <strong className="dealtracker-txt-info-right left-title">Finance:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    !dealTrackerData.financePerson1 && !dealTrackerData.financePerson2 ?
                                                                        '--' :
                                                                        (
                                                                            (dealTrackerData.financePerson1 ? (dealTrackerData.financePerson1.firstName + ' ' + dealTrackerData.financePerson1.lastName) : '-- ')
                                                                            + ', ' +
                                                                            (dealTrackerData.financePerson2 ? (dealTrackerData.financePerson2.firstName + ' ' + dealTrackerData.financePerson2.lastName) : '--')
                                                                        )
                                                                }
                                                            </strong>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <strong className="dealtracker-txt-info-right">Finance Mgr - </strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    dealTrackerData.financePerson1 && dealTrackerData.financePerson1.manager ?
                                                                        (dealTrackerData.financePerson1.manager.firstName + ' ' + dealTrackerData.financePerson1.manager.lastName)
                                                                        : '--'
                                                                }
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </> :
                                            <>
                                                <div>
                                                    <Row className="rowPaddingBtwSpace">
                                                        <Col>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <div>
                                                                    <span className="blueShadedText fontWeightSection editWorkBookFirstRowDeal editWorkBookFirstRow">{dealTrackerData?.dealNumber} - {dealTrackerData?.location?.legalName} &nbsp;</span>
                                                                    <PrimaryButton onClick={handleEditDealFlow} > Edit Deal </PrimaryButton>
                                                                </div>
                                                                {
                                                                    dealTrackerData.createdAt && (<div className="dealtracker-txt-info">
                                                                        Logged  {moment(new Date(dealTrackerData.createdAt)).local().format('MM/DD/YY')}
                                                                    </div>)
                                                                }

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="editWorkBookFirstRow dealTrackerHeaderRow editWorkBookFirstRowDeal rowPaddingBtwSpace">
                                                        <Col>
                                                            <span className="blueShadedText fontWeightSection">
                                                                {
                                                                    dealTrackerData.customer ?
                                                                        (dealTrackerData.customer.customerType === 1 ?
                                                                            (dealTrackerData.customer.firstName + ' ' + dealTrackerData.customer.lastName)
                                                                            :
                                                                            (dealTrackerData.customer.businessName)
                                                                        )
                                                                        : null
                                                                }
                                                            </span>
                                                        </Col>
                                                        <Col>
                                                            <span className="blueShadedText fontWeightSection">{dealTrackerData?.customer?.phoneNumber ? stringToCodePhNoformat(dealTrackerData.customer.phoneNumber) : ''}</span>
                                                        </Col>
                                                        <Col>
                                                            <span className="blueShadedTextBox fontWeightSection float-right">
                                                                {dealTrackerData?.customer?.email}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="rowPaddingBtwSpace readOnlyFieldRowHead">
                                                        <Col className="display-flex">
                                                            <strong className="dealtracker-txt-info-right left-title">Sales:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    !dealTrackerData.salesPerson1 && !dealTrackerData.salesPerson2 ?
                                                                        '--' :
                                                                        (
                                                                            (dealTrackerData.salesPerson1 ? (dealTrackerData.salesPerson1.firstName + ' ' + dealTrackerData.salesPerson1.lastName) : '-- ')
                                                                            + ', ' +
                                                                            (dealTrackerData.salesPerson2 ? (dealTrackerData.salesPerson2.firstName + ' ' + dealTrackerData.salesPerson2.lastName) : '--')
                                                                        )
                                                                }
                                                            </strong>
                                                        </Col>
                                                        <Col className="display-flex">
                                                            <strong className="dealtracker-txt-info-right right-title">Sales Mgr:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    dealTrackerData.salesManager && dealTrackerData.salesManager ?
                                                                        (dealTrackerData.salesManager.firstName + ' ' + dealTrackerData.salesManager.lastName)
                                                                        : '--'
                                                                }
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className="rowPaddingBtwSpace readOnlyFieldRowHead">
                                                        <Col className="display-flex">
                                                            <strong className="dealtracker-txt-info-right left-title">Finance:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    !dealTrackerData.financePerson1 && !dealTrackerData.financePerson2 ?
                                                                        '--' :
                                                                        (
                                                                            (dealTrackerData.financePerson1 ? (dealTrackerData.financePerson1.firstName + ' ' + dealTrackerData.financePerson1.lastName) : '-- ')
                                                                            + ', ' +
                                                                            (dealTrackerData.financePerson2 ? (dealTrackerData.financePerson2.firstName + ' ' + dealTrackerData.financePerson2.lastName) : '--')
                                                                        )
                                                                }
                                                            </strong>
                                                        </Col>
                                                        <Col className="display-flex">
                                                            <strong className="dealtracker-txt-info-right right-title">Finance Mgr:</strong>
                                                            <strong className="dealtracker-txt-info">
                                                                {
                                                                    dealTrackerData.financePerson1 && dealTrackerData.financePerson1.manager ?
                                                                        (dealTrackerData.financePerson1.manager.firstName + ' ' + dealTrackerData.financePerson1.manager.lastName)
                                                                        : '--'
                                                                }
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div className=" shopEditWorkBookTabColor">
                            <AppBar position="static" className="shopEditWorkBook  dealTrackerTabHeight shopEditWorkBookBottom" style={{ marginTop: "25px" }} >
                                <Row className="firstRowTabSec">
                                    <Col className="firstRowTabColSec">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            // variant="scrollable"
                                            variant="fullWidth"
                                            //scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs "
                                            className=" shopEditWorkBookTabtextColorWhite"
                                        >
                                            <Tab label="Write-ups" {...a11yProps(0)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="Appointments" {...a11yProps(1)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="Trade-ins" {...a11yProps(2)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="Cancellations" {...a11yProps(3)} />
                                            <Tab style={{ display: "none" }} label="Scheduling" {...a11yProps(4)} />
                                            <Tab style={{ display: "none" }} label="Tasks" {...a11yProps(5)} />
                                            <Tab style={{ display: "none" }} label="After Sale Products" {...a11yProps(6)} />
                                            <Tab style={{ display: "none" }} label="Vehicle Prep" {...a11yProps(7)} />
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Row className='secRowTab'>
                                    <Col className="secRowTabColSec">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            // variant="scrollable"
                                            variant="fullWidth"
                                            //scrollButtons="on"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="scrollable force tabs "
                                            className=" shopEditWorkBookTabtextColorWhite"
                                        >
                                            <Tab style={{ display: "none" }} label="Write-ups" {...a11yProps(0)} />
                                            <Tab style={{ display: "none" }} label="Appointments" {...a11yProps(1)} />
                                            <Tab style={{ display: "none" }} label="Trade-ins" {...a11yProps(2)} />
                                            <Tab style={{ display: "none" }} label="Trade-In" {...a11yProps(3)} />
                                            <Tab label="Scheduling" {...a11yProps(4)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="Tasks" {...a11yProps(5)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="After Sale Products" {...a11yProps(6)} className=" shopEditWorkBookTabBorderTop" />
                                            <Tab label="Vehicle Prep" {...a11yProps(7)} />
                                        </Tabs>
                                    </Col>
                                </Row>
                            </AppBar>
                        </div>
                        <SwipeableViews className="writeUpsFont shopInventory"
                            axis={theme?.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanelView value={value} index={0}>
                                {
                                    showDeliveryView != null ? <>
                                        <div className="dealTracker">
                                            {
                                                deliveryFormik.values.id ?
                                                    <>
                                                        {
                                                            isMobile ?
                                                                <>
                                                                    <Row justify="center">
                                                                        <Col>
                                                                            {
                                                                                vehicleData ?
                                                                                    <>
                                                                                        <Row className="readOnlyFieldRowHead">
                                                                                            <Col md={3}>
                                                                                                <PrimaryButton onClick={() => { setIsOpenVehicleDia(true) }} > Edit Vehicle </PrimaryButton>
                                                                                            </Col>
                                                                                            <Col md={9}>
                                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                    <div>
                                                                                                        <strong style={{ minWidth: "1px" }}>Stock : </strong>
                                                                                                        <span>{dealTrackerData.stockNumber ? dealTrackerData.stockNumber : "NA"}</span>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <strong style={{ minWidth: "1px" }}>Order : </strong>
                                                                                                        <span>{dealTrackerData.orderNumber ? dealTrackerData.orderNumber : "NA"}</span>
                                                                                                    </div>
                                                                                                    {
                                                                                                        vehicleData.vin && (<div>
                                                                                                            <strong>{vehicleData.vin}</strong>
                                                                                                        </div>)
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="font-color-1 PadBottomSpace">
                                                                                                    {vehicleData.year} &nbsp;&nbsp; {vehicleData.make} &nbsp;&nbsp; {vehicleData.model} &nbsp;&nbsp; {vehicleData.style}
                                                                                                </div>
                                                                                            </Col>

                                                                                        </Row>
                                                                                    </> :
                                                                                    <>
                                                                                        <div className="addPurchaseRow" style={{ padding: "0px 0px 14px" }} > {/* className="main-section-body" */}
                                                                                            <Row className="rowSpaceHeight readOnlyFieldRowHead mainQValuPageSec ">
                                                                                                <Col xs={4}>
                                                                                                    <strong style={{ marginTop: "5px", marginRight: "-30px" }} className="">Purchase Vehicle</strong>
                                                                                                </Col>
                                                                                                <Col xs={8}>
                                                                                                    <PrimaryButton onClick={() => { setisInventorySearchDialog(true) }}>Add Purchase Vehicle</PrimaryButton>
                                                                                                </Col>

                                                                                        </Row>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                        {/*<Row className="readOnlyFieldRowHead" style={{marginBottom: "25px", marginTop: "20px"}}>
                                                                          <Col sm={3}>
                                                                          </Col>
                                                                          <Col>
                                                                          <Row style={{ marginTop: "5px" }}>
                                                                            <Col sm={12}>
                                                                              <strong>NHSTA Checked :</strong>
                                                                              <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                                                                                 {deliveryFormik.values.nhstaCheckedDate != null
                                                                                   ? moment.utc(deliveryFormik.values.nhstaCheckedDate).local().format('MM/DD/YY')
                                                                                 : ''}
                                                                              </span>
                                                                            </Col>
                                                                          </Row>
                                                                          <Row>
                                                                            <Col sm={6}>
                                                                              <strong>Active Recalls :</strong>
                                                                              <span style={{ fontSize: "15px", marginLeft: "5px" }}>{deliveryFormik.values.activeRecalls}</span>
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                              <strong>Service Bulletins :</strong>
                                                                              <span style={{ fontSize: "15px", marginLeft: "5px" }}>{deliveryFormik.values.serviceBulletins}</span>
                                                                            </Col>
                                                                          </Row>
                                                                          <Row style={{ marginTop: "5px" }}>
                                                                            <Col sm={6}>
                                                                            <div className="auctionDate date-picker" style={{ width: "100%" }}>
                                                                              <strong>Recalls Verified Complete :</strong>
                                                                              <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                  <Calendar
                                                                                      style={{ display: "flex" }}
                                                                                      showIcon
                                                                                      monthNavigator
                                                                                      yearNavigator
                                                                                      yearRange={yearRange}
                                                                                      value={new Date(deliveryFormik.values.recallsVerifiedCompletedDate)}
                                                                                      onChange={(e) => handleDateChange(e.value, 'recallsVerifiedCompletedDate')}
                                                                                  />
                                                                              </div>
                                                                              </div>
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                              <div>
                                                                                  <strong>RO Number :</strong>
                                                                                  <TextField
                                                                                     value={deliveryFormik.values.rONumber}
                                                                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => deliveryFormik.setFieldValue('rONumber', e.target.value)}
                                                                                     className={`${classes.textFieldSmall} ${classes.shopInventoryEdit}`}
                                                                                  />
                                                                              </div>
                                                                            </Col>
                                                                          </Row>      
                                                                          </Col>
                                                                        </Row>*/}
                                                                        <div>
                                                                                <strong className="writeUpsFont">Desking </strong> <hr/>
                                                                        </div>
                                                                        <div className="delivery-section" style={{ padding: "0px 0px 14px" }} > {/* className="main-section-body" */}
                                                                            <Row className="rowSpace  rowSpaceHeight rowPaddingBtwSpace mainQValuPageSec" justify="center" >
                                                                                <Col xs={7}>
                                                                                    {
                                                                                        writeUpsElePermission.status && writeUpsElePermission.status > 0 ?
                                                                                            <>
                                                                                                <div style={{ width: "50%", float: "left", paddingRight: "5px" }}>
                                                                                                    <label className="lbl-text required-Label">Status</label>
                                                                                                    <PRDropdown
                                                                                                        id="status"
                                                                                                        placeholder=""
                                                                                                        value={deliveryFormik.values.status}
                                                                                                        options={writeUpsStatus}
                                                                                                        onChange={handleDealTrackerFormikDDChange}
                                                                                                        optionLabel="text"
                                                                                                        optionValue="key"
                                                                                                        // appendTo="self"
                                                                                                        className="custom-p-dropdown"
                                                                                                        filter={false}
                                                                                                        disabled={
                                                                                                            writeUpsReadOnly ||
                                                                                                            (writeUpsElePermission.status && writeUpsElePermission.status === 1) ||
                                                                                                            ((actualWriteUpsStatus === 3 ||actualWriteUpsStatus === 4) && user && user.defaultGroups &&
                                                                                                                !user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 19))
                                                                                                        }
                                                                                                        resetFilterOnHide={true}
                                                                                                    />
                                                                                                    <span className="error-message">
                                                                                                        {
                                                                                                            deliveryFormik.touched && deliveryFormik.touched.status &&
                                                                                                            deliveryFormik.errors && deliveryFormik.errors.status &&
                                                                                                            deliveryFormik.errors.status.toString()
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                {
                                                                                                    (deliveryFormik.values.status && Number(deliveryFormik.values.status)) > 0 ?

                                                                                                            <div className="ms-TextField" style={{ width: "50%", float: "left", paddingLeft: "5px" }}>
                                                                                                                <div className="ms-TextField-wrapper">
                                                                                                                    <label className="lbl-text required-Label">{statusDateLabel['' + deliveryFormik.values.status]}</label>
                                                                                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                                        <Calendar
                                                                                                                            id="statusDate"
                                                                                                                            style={{ width: "100%" }}
                                                                                                                            value={statusDate}
                                                                                                                            showIcon
                                                                                                                            onChange={(e) => handleStatusDateChange(e.value)}
                                                                                                                            monthNavigator
                                                                                                                            yearNavigator
                                                                                                                            disabled={
                                                                                                                                writeUpsReadOnly ||
                                                                                                                                ((actualWriteUpsStatus === 3 || actualWriteUpsStatus === 4) && user && user.defaultGroups &&
                                                                                                                                    !user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 19))
                                                                                                                            }
                                                                                                                            yearRange={yearRange} />
                                                                                                                    </div>
                                                                                                                    <span className="error-message">
                                                                                                                        {
                                                                                                                            deliveryFormik.errors && (
                                                                                                                                (deliveryFormik.errors.closedDate) ||
                                                                                                                                (deliveryFormik.errors.pendingDate) ||
                                                                                                                                (deliveryFormik.errors.orderOutDate) ||
                                                                                                                                (deliveryFormik.errors.voidDate) ||
                                                                                                                                (deliveryFormik.errors.shippedDate)
                                                                                                                            ) && ("Date required")
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            : null
                                                                                                    }
                                                                                                </> : null
                                                                                        }

                                                                                    </Col>
                                                                                    <Col xs={5}>
                                                                                        <label className="lbl-text required-Label">Department</label>
                                                                                        <PRDropdown
                                                                                            id="departmentId"
                                                                                            placeholder=""
                                                                                            value={deliveryFormik.values.departmentId}
                                                                                            options={departments}
                                                                                            onChange={handleDealTrackerFormikDDChange}
                                                                                            optionLabel="name"
                                                                                            optionValue="id"
                                                                                            // appendTo="self"
                                                                                            className="custom-p-dropdown"
                                                                                            filter={false}
                                                                                            resetFilterOnHide={true}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace mainQValuPageSec " justify="center" >
                                                                                    <Col xs={7} className="request-delivery request-deliveryText">
                                                                                        <div className="date-time-group">
                                                                                            {
                                                                                                writeUpsElePermission.deliveryDate && writeUpsElePermission.deliveryDate > 0 ?
                                                                                                    <div className="auctionDate date-picker" style={{ width: "100%" }}>
                                                                                                        <label style={{ display: "block" }} className="lbl-text">Requested Delivery</label>
                                                                                                        <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                            <Calendar
                                                                                                                id="requestDeliveryDate"
                                                                                                                value={reqDeliveryDate}
                                                                                                                //label=" Requested Delivery"
                                                                                                                //style={{ width: "100%" }}
                                                                                                                style={{ display: "flex" }}
                                                                                                                showIcon
                                                                                                                onChange={(e) => handleDateChange(e.value, 'requestDeliveryDate')}
                                                                                                                monthNavigator
                                                                                                                yearNavigator
                                                                                                                yearRange={yearRange}
                                                                                                                disabled={writeUpsReadOnly || (writeUpsElePermission.deliveryDate && writeUpsElePermission.deliveryDate === 1)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div> : null
                                                                                            }
                                                                                            {
                                                                                                writeUpsElePermission.deliveryTime && writeUpsElePermission.deliveryTime > 0 ?
                                                                                                    <div className="auctionStartTime time-picker" style={{ width: "100%" }}>
                                                                                                        {(reqDeliveryTime !== 'hold') ?
                                                                                                            <TimePicker
                                                                                                                showSecond={false}
                                                                                                                defaultValue={reqDeliveryTime}
                                                                                                                className="mask-FieldGroup rc-time-picker-input"
                                                                                                                onChange={handleReqDeliveryTimeChange}
                                                                                                                format="h:mm a"
                                                                                                                use12Hours
                                                                                                                inputReadOnly
                                                                                                                minuteStep={15}
                                                                                                                popupClassName="req-del-time-panel"
                                                                                                                placeholder="Time"
                                                                                                                disabled={writeUpsReadOnly}
                                                                                                                inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                                            /> : null}
                                                                                                    </div> : null
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col xs={5}>
                                                                                        <label className="lbl-text required-Label">Delivery Type</label>
                                                                                        <PRDropdown
                                                                                            id="deliveryType"
                                                                                            placeholder=""
                                                                                            value={deliveryFormik.values.deliveryType}
                                                                                            options={masterDropdownValues.dealDeliveryTypes}
                                                                                            onChange={handleDealTrackerFormikDDChange}
                                                                                            optionLabel="text"
                                                                                            optionValue="key"
                                                                                            // appendTo="self"
                                                                                            className="custom-p-dropdown"
                                                                                            filter={false}
                                                                                            resetFilterOnHide={true}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>

                                                                            </div>

                                                                            <hr />
                                                                            <div className="delivery-section" style={{ padding: "10px 0px" }}>
                                                                                <Row className="rowPaddingBtwSpace">
                                                                                    <Col xs={6} className="box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cdr, 'cdr', false)} className="box-link">
                                                                                            <div className={"box-section mob-view box-status-" + deliveryFormik.values.cdr}>
                                                                                                <div className="blueShadedText">
                                                                                                    CDR
                                                                                                </div>
                                                                                                {deliveryFormik.values.cdr === 2 ? <>
                                                                                                    <div className="request-delivery" style={{ display: "flex" }}>
                                                                                                        <span className="greyShadedText box-status" style={{ padding: "5px 4px" }}>Yes</span>
                                                                                                        {
                                                                                                            writeUpsElePermission.cdrDate && writeUpsElePermission.cdrDate > 0 ?
                                                                                                                <div className="cdrDate" style={{ width: "auto" }}>
                                                                                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                                        <Calendar
                                                                                                                            id="cdrDate"
                                                                                                                            value={cdrDate}
                                                                                                                            style={{ width: "100%" }}
                                                                                                                            showIcon
                                                                                                                            onChange={(e) => handleDateChange(e.value, 'cdrDate')}
                                                                                                                            monthNavigator
                                                                                                                            yearNavigator
                                                                                                                            appendTo="self"
                                                                                                                            disabled={writeUpsReadOnly || (writeUpsElePermission.cdrDate && writeUpsElePermission.cdrDate === 1)}
                                                                                                                            yearRange={yearRange} />
                                                                                                                    </div>
                                                                                                                </div> : null
                                                                                                        }

                                                                                                    </div>
                                                                                                </> :
                                                                                                    <>
                                                                                                        <div className="greyShadedText box-status">
                                                                                                            {boxStatus[deliveryFormik.values.cdr]}
                                                                                                        </div>
                                                                                                    </>
                                                                                                }

                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col xs={6} className="box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.fleet, 'fleet', false)} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={"box-section box-status-" + deliveryFormik.values.fleet}>
                                                                                                <div className={`blueShadedText ${deliveryFormik.values.fleet === 2 ? "required-Label" : ""}`}>
                                                                                                    Fleet
                                                                                                </div>
                                                                                                {deliveryFormik.values.fleet === 2 ? <>
                                                                                                    <div className=" fleetNumberWidth request-delivery" style={{ display: "flex", marginLeft: "-5px" }}>
                                                                                                        <span className="greyShadedText box-status" style={{ padding: "4px 11px 0px 7px" }}>Yes</span>

                                                                                                        <div className="cdrDate" style={{ width: "auto" }}>
                                                                                                            <TextField
                                                                                                                id="fleetNumber"
                                                                                                                name="fleetNumber"
                                                                                                                style={{ width: "auto" }}
                                                                                                                value={deliveryFormik.values.fleetNumber}
                                                                                                                onChange={deliveryFormik.handleChange}
                                                                                                                onBlur={deliveryFormik.handleBlur}
                                                                                                                disabled={writeUpsReadOnly}
                                                                                                                errorMessage={
                                                                                                                    deliveryFormik.touched.fleetNumber &&
                                                                                                                    deliveryFormik.errors.fleetNumber &&
                                                                                                                    deliveryFormik.errors.fleetNumber.toString()
                                                                                                                }
                                                                                                            />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </> :
                                                                                                    <>
                                                                                                        <div className="greyShadedText box-status">
                                                                                                            {boxStatus[deliveryFormik.values.fleet]}
                                                                                                        </div>
                                                                                                    </>
                                                                                                }

                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col xs={6} className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cpo, 'cpo')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.cpo}>
                                                                                                <div className="blueShadedText">
                                                                                                    CPO
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.cpo]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col xs={6} className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.sfe, 'sfe')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.sfe}>
                                                                                                <div className="blueShadedText">
                                                                                                    SFE
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.sfe]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col xs={6} className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cda, 'cda')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.cda}>
                                                                                                <div className="blueShadedText">
                                                                                                    CDA
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.cda]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col xs={6} className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.locate, 'locate')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.locate}>
                                                                                                <div className="blueShadedText">
                                                                                                    Locate
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.locate]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="delivery-section" style={{ padding: "0px 0px", marginBottom: "10px" }} >
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal " justify="center" style={{ display: "block" }}>
                                                                                    <Col>
                                                                                        <label className="lbl-text ">Deposit</label>
                                                                                        <CurrencyInput
                                                                                            allowNegativeValue={false}
                                                                                            customInput={OffTextField}
                                                                                            // thousandSeparator={true}
                                                                                            prefix={PRICE_PREFIX}
                                                                                            id="deposit"
                                                                                            name="deposit"
                                                                                            //label="Deposit"
                                                                                            decimalScale={2}
                                                                                            value={deliveryFormik.values.deposit?deliveryFormik.values.deposit.replace(/,/g, ''):''}
                                                                                            onValueChange={(e) => handlePriceChange(e, 'deposit')}
                                                                                            onBlur={deliveryFormik.handleBlur}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                        <span className="error-message">
                                                                                            {deliveryFormik.touched.deposit &&
                                                                                                deliveryFormik.errors.deposit &&
                                                                                                deliveryFormik.errors.deposit.toString()}
                                                                                        </span>

                                                                                    </Col>
                                                                                    <Col>
                                                                                        {(deliveryFormik.values.sfe === 2 || deliveryFormik.values.fleet === 2) ?
                                                                                            <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal ">
                                                                                                <Col>
                                                                                                    <label className="lbl-text">Delivery Code</label>
                                                                                                    <PRDropdown
                                                                                                        id="deliveryCode"
                                                                                                        placeholder=""
                                                                                                        value={deliveryFormik.values.deliveryCode}
                                                                                                        options={masterDropdownValues.deliveryCodes}
                                                                                                        onChange={handleDealTrackerFormikDDChange}
                                                                                                        optionLabel="text"
                                                                                                        optionValue="key"
                                                                                                        // appendTo="self"
                                                                                                        className="custom-p-dropdown"
                                                                                                        filter={false}
                                                                                                        resetFilterOnHide={true}
                                                                                                        disabled={writeUpsReadOnly}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row> : ""}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div >
                                                                                <strong className="writeUpsFont" >Finance </strong> <hr />
                                                                            </div>
                                                                            <div className="delivery-section" style={{ padding: "0px 0px" }} > {/* className="main-section-body" */}
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal" justify="center">

                                                                                    <Col>
                                                                                        <label className="lbl-text">Finance Status</label>
                                                                                        <PRDropdown
                                                                                            id="financeStatus"
                                                                                            placeholder=""
                                                                                            value={deliveryFormik.values.financeStatus}
                                                                                            options={masterDropdownValues.dealFinanceStatus}
                                                                                            onChange={handleDealTrackerFormikDDChange}
                                                                                            optionLabel="text"
                                                                                            optionValue="key"
                                                                                            // appendTo="self"
                                                                                            className="custom-p-dropdown"
                                                                                            filter={false}
                                                                                            resetFilterOnHide={true}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal" justify="center">
                                                                                    <Col>
                                                                                        <OffTextField
                                                                                            id="creditScore"
                                                                                            name="creditScore"
                                                                                            label="Credit Score"
                                                                                            resizable={false}
                                                                                            value={deliveryFormik.values.creditScore}
                                                                                            onChange={deliveryFormik.handleChange}
                                                                                            onBlur={deliveryFormik.handleBlur}
                                                                                            errorMessage={
                                                                                                deliveryFormik.touched.creditScore &&
                                                                                                deliveryFormik.errors.creditScore &&
                                                                                                deliveryFormik.errors.creditScore.toString()
                                                                                            }
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <label className="lbl-text">Lender</label>
                                                                                        <PRDropdown
                                                                                            id="lenderId"
                                                                                            placeholder=""
                                                                                            value={deliveryFormik.values.lenderId}
                                                                                            options={lenders}
                                                                                            onChange={handleDealTrackerFormikDDChange}
                                                                                            optionLabel="name"
                                                                                            optionValue="id"
                                                                                            // appendTo="self"
                                                                                            className="custom-p-dropdown"
                                                                                            filter={false}
                                                                                            resetFilterOnHide={true}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal" justify="center">
                                                                                    <Col>
                                                                                        <label className="lbl-text ">Gross Profit</label>
                                                                                        <CurrencyInput
                                                                                            allowNegativeValue={true}
                                                                                            customInput={OffTextField}
                                                                                            // thousandSeparator={true}
                                                                                            prefix={PRICE_PREFIX}
                                                                                            id="grossProfit"
                                                                                            name="grossProfit"
                                                                                            //label="Gross Profit"
                                                                                            decimalScale={2}
                                                                                            // className="float-right"
                                                                                            value={deliveryFormik.values.grossProfit?deliveryFormik.values.grossProfit.replace(/,/g, ''):""}
                                                                                            onValueChange={(e) => handlePriceChange(e, 'grossProfit')}
                                                                                            onBlur={deliveryFormik.handleBlur}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                        <span className="error-message">
                                                                                            {deliveryFormik.touched.grossProfit &&
                                                                                                deliveryFormik.errors.grossProfit &&
                                                                                                deliveryFormik.errors.grossProfit.toString()}
                                                                                        </span>

                                                                                    </Col>
                                                                                    {/*<Col>
                                                                                    <CurrencyInput
                                                                                        allowNegativeValue={false}
                                                                                        customInput={OffTextField}
                                                                                       // thousandSeparator={true}
                                                                                        prefix={PRICE_PREFIX}
                                                                                        id="financeIncome"
                                                                                        name="financeIncome"
                                                                                        label="Finance Income"
                                                                                        decimalScale={2}
                                                                                        value={deliveryFormik.values.financeIncome}
                                                                                        onChange={deliveryFormik.handleChange}
                                                                                        onBlur={deliveryFormik.handleBlur}
                                                                                        errormessage={
                                                                                            deliveryFormik.touched.financeIncome &&
                                                                                            deliveryFormik.errors.financeIncome &&
                                                                                            deliveryFormik.errors.financeIncome.toString()
                                                                                        }

                                                                                    />
                                                                                </Col>*/}
                                                                                </Row>
                                                                                <Row justify="center">
                                                                                    <Col>
                                                                                        <div className="table-section" >
                                                                                            <div className={isMobile ? "card datatable-mob-view" : "card p-datatable-customers-list"} >
                                                                                                <DataTable
                                                                                                    value={tableData}
                                                                                                    className="p-datatable-customers p-datatable-hoverable-rows"
                                                                                                    dataKey="id"
                                                                                                    //cellSelection
                                                                                                    onSelectionChange={e => captureCellSelectionFinanceTbl(e)}>
                                                                                                    <Column field="financeProduct" header="Finance Product" />
                                                                                                    <Column field="amount" header="Amount" />
                                                                                                </DataTable>
                                                                                            </div>
                                                                                            <div style={{ textAlign: "right", margin: "-20px 15px 10px" }}>
                                                                                                <Link
                                                                                                    disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                                                                                    color="inherit" onClick={navigatetoOpenProductDialog}>
                                                                                                    <AddCircle style={{ ...themeStyles.icon, color: "#70af60", margin: "0px 5px" }} />
                                                                                                    Product
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                                {
                                                                                    !writeUpsReadOnly && (<Row style={{ padding: "17px 0px 7px" }} justify="center">
                                                                                        <Col className="vertical-center">
                                                                                            <PrimaryButton
                                                                                                disabled={!deliveryFormik.dirty || !deliveryFormik.isValid}
                                                                                                onClick={handleDealTrackerFormikSumbit}>
                                                                                                Save
                                                                                            </PrimaryButton>
                                                                                        </Col>
                                                                                    </Row>)
                                                                                }

                                                                            </div>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Accordion className="inventory-filter-accordion" defaultExpanded>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1c-content"
                                                                                            id="panel1c-header"
                                                                                            style={{ paddingTop: "0px" }}
                                                                                        >
                                                                                            <div style={{ ...themeStyles.column, paddingTop: "0px" }}>
                                                                                                <Typography component={'h3'} style={themeStyles.heading}>Notes</Typography>
                                                                                            </div>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails style={themeStyles.details}>
                                                                                            <Row>
                                                                                                <Col className="notesSection">
                                                                                                    <OffTextField
                                                                                                        id="deliveryNote"
                                                                                                        name="deliveryNote"
                                                                                                        label=""
                                                                                                        multiline
                                                                                                        resizable={false}
                                                                                                        className="full-width fontWeightSection"
                                                                                                        value={deliveryNote}
                                                                                                        onChange={(e, newValue) => handleDeliveryNoteChange(e, newValue)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {
                                                                                                deliveryNoteError ?
                                                                                                    <div className="">
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <span className="genericErrorColor"><span>Please enter details</span></span>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div> : null
                                                                                            }

                                                                                            <Row className="rowPaddingBtwSpace">
                                                                                                <Col className="text-right">
                                                                                                    <PrimaryButton style={{ marginTop: 10 }} onClick={() => saveDeliveryNotesDetails()}>Add Note</PrimaryButton>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <h3 style={{ border: "none" }}>History</h3>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                   <hr />
                                                                                                    {notesList?.map((item) => (
                                                                                                     <div key={"boxes-" + item.id}>
                                                                                                         <Row className="rowSpaceNotes rowSpace" style={{ padding: "0 20px" }}>
                                                                                                             <Col md={3} xs={4}>
                                                                                                                 <div className="writeUpsNoteText">{moment(new Date(item.createdAt)).local().format("MM/DD/YYYY").toString()}</div>
                                                                                                                 <div className="writeUpsNoteText">{item?.firstName} {item?.lastName}</div>
                                                                                                             </Col>
                                                                                                             <Col md={3} xs={4}>
                                                                                                                 <div className="writeUpsNoteText">{dealType[item?.type]}</div>
                                                                                                     
                                                                                                             </Col>
                                                                                                             <Col md={6} xs={4} className="writeUpsNoteText">{item.note}</Col>
                                                                                                         </Row>
                                                                                                         <hr />
                                                                                                     </div>
                                                                                                     ))}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="dealHistory">
                                                                                <Col>
                                                                                    <Accordion className="inventory-filter-accordion" defaultExpanded>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1c-content"
                                                                                            id="panel1c-header"
                                                                                        >
                                                                                            <div style={{ ...themeStyles.column, paddingTop: "0px" }}>
                                                                                                <Typography component={'h3'} style={themeStyles.heading} className={"fontWeightSection"}>Deal History</Typography>
                                                                                            </div>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails style={themeStyles.details}>
                                                                                            <Row className="dealHistoryRow">
                                                                                                <Col>
                                                                                                    <DataTable
                                                                                                        value={dealHistory}
                                                                                                        className="p-datatable-customers p-datatable-hoverable-rows"
                                                                                                        dataKey="id"
                                                                                                        //cellSelection
                                                                                                        onSelectionChange={e => captureCellSelection(e)}>
                                                                                                        <Column field="deal" header="Deal" />
                                                                                                        <Column field="log" header="Log" />
                                                                                                        <Column field="status" header="Status" />
                                                                                                        <Column field="mileage" header="Customer" />
                                                                                                        <Column field="sourcetye" header="Dept" />
                                                                                                        <Column field="buyPrice" header="Sales" />
                                                                                                        <Column field="qValue" header="Sales Mgr" />
                                                                                                        <Column field="sellPrice" header="Finance" />
                                                                                                        <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                                                                                                    </DataTable>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </> :
                                                                <>
                                                                    <Row justify="center">

                                                                    <Col>
                                                                        {vehicleData ? (
                                                                          <>
                                                                            <Row className="vehicl-data-info">
                                                                              <Col md={3}>
                                                                                <PrimaryButton onClick={() => { setIsOpenVehicleDia(true) }}> Edit Vehicle </PrimaryButton>
                                                                              </Col>
                                                                              <Col md={9}>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                  <div>
                                                                                    <strong style={{ minWidth: "1px" }}>Stock : </strong>
                                                                                    <span style={{ fontSize: "15px" }}>{dealTrackerData.stockNumber ? dealTrackerData.stockNumber : "NA"}</span>
                                                                                  </div>
                                                                                  <div>
                                                                                    <strong style={{ minWidth: "1px" }}>Order : </strong>
                                                                                    <span style={{ fontSize: "15px" }}>{dealTrackerData.orderNumber ? dealTrackerData.orderNumber : "NA"}</span>
                                                                                  </div>
                                                                                  <div style={{ minWidth: "140px" }}>
                                                                                    <strong>{vehicleData.vin}</strong>
                                                                                  </div>
                                                                                </div>
                                                                                <div className="font-color-1 PadBottomSpace">
                                                                                  {vehicleData.year} &nbsp;&nbsp; {vehicleData.make} &nbsp;&nbsp; {vehicleData.model} &nbsp;&nbsp; {vehicleData.style}
                                                                                </div>
                                                                              </Col>
                                                                            </Row>
                                                                        
                                                                            {/*<Row style={{ marginBottom: "25px", marginTop: "20px" }}>
                                                                              <Col sm={3}></Col>
                                                                              <Col>
                                                                                <Row>
                                                                                  <Col sm={12}>
                                                                                    <strong>NHSTA Checked :</strong>
                                                                                    <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                                                                                      {deliveryFormik.values.nhstaCheckedDate != null
                                                                                        ? moment.utc(deliveryFormik.values.nhstaCheckedDate).local().format('MM/DD/YY')
                                                                                        : ''}
                                                                                    </span>
                                                                                  </Col>
                                                                                </Row>

                                                                                <Row style={{ marginTop: "5px" }}>
                                                                                  <Col sm={6}>
                                                                                    <strong>Active Recalls :</strong>
                                                                                    <span style={{ fontSize: "15px", marginLeft: "5px", color: parseInt(deliveryFormik.values.activeRecalls, 10) > 0 ? 'red' : 'inherit' }}>
                                                                                      {parseInt(deliveryFormik.values.activeRecalls, 10) > 0 ? deliveryFormik.values.activeRecalls : 'None'}
                                                                                    </span>

                                                                                  </Col>
                                                                                  <Col sm={6}>
                                                                                    <strong>Service Bulletins :</strong>
                                                                                    <span style={{ fontSize: "15px", marginLeft: "5px", color: deliveryFormik.values.serviceBulletins > 0 ? 'red' : 'inherit' }}>
                                                                                      {deliveryFormik.values.serviceBulletins > 0 ? deliveryFormik.values.serviceBulletins : 'None'}
                                                                                    </span>
                                                                                  </Col>
                                                                                </Row>
                                                                                <Row style={{ marginTop: "5px" }}>
                                                                                  <Col sm={6}>
                                                                                    <div className="auctionDate date-picker" style={{ width: "100%" }}>
                                                                                      <strong>Recalls Verified Complete :</strong>
                                                                                      <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                        <Calendar
                                                                                            style={{ display: "flex" }}
                                                                                            showIcon
                                                                                            monthNavigator
                                                                                            yearNavigator
                                                                                            yearRange={yearRange}
                                                                                            value={new Date(deliveryFormik.values.recallsVerifiedCompletedDate)}
                                                                                            onChange={(e) => handleDateChange(e.value, 'recallsVerifiedCompletedDate')}
                                                                                        />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col sm={6}>
                                                                                                    <div>
                                                                                                        <strong>RO Number :</strong>
                                                                                                        <TextField
                                                                                                            value={deliveryFormik.values.rONumber}
                                                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => deliveryFormik.setFieldValue('rONumber', e.target.value)}
                                                                                                            style={{ ...themeStyles.textFieldSmall, ...themeStyles.shopInventoryEdit }} />

                                                                                    </div>
                                                                                  </Col>
                                                                                </Row>
                                                                              </Col>
                                                                            </Row>*/}
                                                                          </>
                                                                        ) : (
                                                                          <Row className="readOnlyFieldRowHead rowHeadPadding PadBottomSpace">
                                                                            <Col>
                                                                              <strong className="tradeVehicle">Purchase Vehicle</strong>
                                                                              <PrimaryButton onClick={() => { setisInventorySearchDialog(true) }}>Add Purchase Vehicle</PrimaryButton>
                                                                            </Col>
                                                                          </Row>
                                                                        )}
                                                                        <div  style={{fontWeight:"bold"}}>
                                                                               <strong className="writeUpsFont">Desking </strong>  <hr/>
                                                                        </div>
                                                                        <div className="delivery-section" style={{ padding: "0px 0px 14px" }} > {/* className="main-section-body" */}
                                                                            <Row className="rowSpace  rowSpaceHeight rowPaddingBtwSpace mainQValuPageSec" justify="center" >
                                                                                <Col>
                                                                                    {
                                                                                        writeUpsElePermission.status && writeUpsElePermission.status > 0 ?
                                                                                            <div style={{ width: "50%", float: "left" }}>
                                                                                                <label className="lbl-text required-Label">Status</label>
                                                                                                <PRDropdown
                                                                                                    id="status"
                                                                                                    placeholder=""
                                                                                                    value={deliveryFormik.values.status}
                                                                                                    options={writeUpsStatus}
                                                                                                    onChange={handleDealTrackerFormikDDChange}
                                                                                                    optionLabel="text"
                                                                                                    optionValue="key"
                                                                                                    // appendTo="self"
                                                                                                    className="custom-p-dropdown"
                                                                                                    filter={false}
                                                                                                    disabled={
                                                                                                        writeUpsReadOnly ||
                                                                                                        (writeUpsElePermission.status && writeUpsElePermission.status === 1) || 
                                                                                                        ((actualWriteUpsStatus === 3 ||actualWriteUpsStatus === 4) && user && user.defaultGroups &&
                                                                                                            !user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 19))
                                                                                                    }
                                                                                                    resetFilterOnHide={true}
                                                                                                />
                                                                                                <span className="error-message">
                                                                                                    {
                                                                                                        deliveryFormik.touched && deliveryFormik.touched.status &&
                                                                                                        deliveryFormik.errors && deliveryFormik.errors.status &&
                                                                                                        deliveryFormik.errors.status.toString()
                                                                                                    }
                                                                                                </span>
                                                                                            </div>:null
                                                                                    }
                                                                                    
                                                                                    {
                                                                                        (deliveryFormik.values.status && Number(deliveryFormik.values.status)) > 0 ?
                                                                                        
                                                                                        <div className="ms-TextField" style={{ width: "50%",float:"left",paddingLeft:"10px" }}>
                                                                                                <div className="ms-TextField-wrapper">
                                                                                                    <label className="lbl-text required-Label">{statusDateLabel[''+deliveryFormik.values.status]}</label>
                                                                                                                <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                                    <Calendar
                                                                                                                        id="statusDate"
                                                                                                                        style={{ width: "100%" }}
                                                                                                                        value={statusDate}
                                                                                                                        showIcon
                                                                                                                        onChange={(e) => handleStatusDateChange(e.value)}
                                                                                                                        monthNavigator
                                                                                                                        yearNavigator
                                                                                                                        disabled={
                                                                                                                            writeUpsReadOnly ||
                                                                                                                            ((actualWriteUpsStatus === 3 ||actualWriteUpsStatus === 4) && user && user.defaultGroups &&
                                                                                                                            !user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 19))
                                                                                                                        }
                                                                                                                        yearRange={yearRange} />
                                                                                                                </div>
                                                                                                                <span className="error-message">
                                                                                                                    {
                                                                                                                        deliveryFormik.errors && (
                                                                                                                        (deliveryFormik.errors.closedDate) ||
                                                                                                                        (deliveryFormik.errors.pendingDate) ||
                                                                                                                        (deliveryFormik.errors.orderOutDate) ||
                                                                                                                        (deliveryFormik.errors.voidDate) || 
                                                                                                                        (deliveryFormik.errors.shippedDate)
                                                                                                                        ) && ("Date required")
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                    </div>
                                                                                        : null
                                                                                    }                                                                                                                     
                                                                                </Col>
                                                                                <Col>
                                                                                    <label className="lbl-text required-Label">Department</label>
                                                                                    <PRDropdown
                                                                                        id="departmentId"
                                                                                        placeholder=""
                                                                                        value={deliveryFormik.values.departmentId}
                                                                                        options={departments}
                                                                                        onChange={handleDealTrackerFormikDDChange}
                                                                                        optionLabel="name"
                                                                                        optionValue="id"
                                                                                        // appendTo="self"
                                                                                        className="custom-p-dropdown"
                                                                                        filter={false}
                                                                                        resetFilterOnHide={true}
                                                                                        disabled={writeUpsReadOnly}
                                                                                    />
                                                                                </Col>
                                                                            </Row>                                                         
                                                                            <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace mainQValuPageSec " justify="center" >
                                                                                <Col className="request-delivery-writeups request-deliveryText">
                                                                                    <div className="date-time-group">
                                                                                        {
                                                                                            writeUpsElePermission.deliveryDate && writeUpsElePermission.deliveryDate > 0 ?
                                                                                                <div className="auctionDate date-picker " style={{ width: "100%" }}>
                                                                                                    <label style={{ display: "block" }} className="lbl-text">Requested Delivery</label>
                                                                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                        <Calendar
                                                                                                            id="requestDeliveryDate"
                                                                                                            value={reqDeliveryDate}
                                                                                                            //label=" Requested Delivery"
                                                                                                            //style={{ width: "100%" }}
                                                                                                            style={{ display: "flex" }}
                                                                                                            showIcon
                                                                                                            onChange={(e) => handleDateChange(e.value, 'requestDeliveryDate')}
                                                                                                            monthNavigator
                                                                                                            yearNavigator
                                                                                                            yearRange={yearRange}
                                                                                                            disabled={writeUpsReadOnly || (writeUpsElePermission.deliveryDate && writeUpsElePermission.deliveryDate === 1)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>:null
                                                                                        }
                                                                                        
                                                                                        {
                                                                                            writeUpsElePermission.deliveryTime && writeUpsElePermission.deliveryTime > 0 ?
                                                                                                <div className="auctionStartTime time-picker" style={{ width: "100%", paddingLeft: "8px" }}>
                                                                                                    { (reqDeliveryTime !== 'hold') ?
                                                                                                    <TimePicker
                                                                                                        showSecond={false}
                                                                                                        defaultValue={reqDeliveryTime}
                                                                                                        className="mask-FieldGroup rc-time-picker-input"
                                                                                                        onChange={handleReqDeliveryTimeChange}
                                                                                                        format="h:mm a"
                                                                                                        use12Hours
                                                                                                        inputReadOnly
                                                                                                        minuteStep={15}
                                                                                                        popupClassName="req-del-time-panel"
                                                                                                        placeholder="Time"
                                                                                                        disabled={writeUpsReadOnly}
                                                                                                        inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                                    /> : null }
                                                                                                </div>:null
                                                                                        }
                                                                                    </div>

                                                                                    </Col>
                                                                                    <Col>
                                                                                        <label className="lbl-text required-Label">Delivery Type</label>
                                                                                        <PRDropdown
                                                                                            id="deliveryType"
                                                                                            placeholder=""
                                                                                            value={deliveryFormik.values.deliveryType}
                                                                                            options={masterDropdownValues.dealDeliveryTypes}
                                                                                            onChange={handleDealTrackerFormikDDChange}
                                                                                            optionLabel="text"
                                                                                            optionValue="key"
                                                                                            // appendTo="self"
                                                                                            className="custom-p-dropdown"
                                                                                            filter={false}
                                                                                            resetFilterOnHide={true}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>

                                                                            </div>

                                                                            <hr />
                                                                            <div className="delivery-section delivery-box-section" style={{ padding: "10px 0px" }}>
                                                                                <Row className="box-row">
                                                                                    <Col className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cdr, 'cdr', false)} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.cdr}>
                                                                                                <div className="blueShadedText">
                                                                                                    CDR
                                                                                                </div>
                                                                                                {deliveryFormik.values.cdr === 2 ? <>
                                                                                                    <div className="request-delivery" style={{ display: "flex", marginLeft: "-5px" }}>
                                                                                                        <span className="greyShadedText box-status" style={{ padding: "4px 11px 0px 7px" }}>Yes</span>
                                                                                                        {
                                                                                                            writeUpsElePermission.cdrDate && writeUpsElePermission.cdrDate > 0 ?
                                                                                                                <div className="cdrDate" style={{ width: "auto" }}>
                                                                                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                                                                        <Calendar
                                                                                                                            id="cdrDate"
                                                                                                                            value={cdrDate}
                                                                                                                            style={{ width: "100%" }}
                                                                                                                            showIcon
                                                                                                                            onChange={(e) => handleDateChange(e.value, 'cdrDate')}
                                                                                                                            monthNavigator
                                                                                                                            yearNavigator
                                                                                                                            appendTo="self"
                                                                                                                            disabled={writeUpsReadOnly || (writeUpsElePermission.cdrDate && writeUpsElePermission.cdrDate === 1)}
                                                                                                                            yearRange={yearRange} />
                                                                                                                    </div>
                                                                                                                </div> : null
                                                                                                        }

                                                                                                    </div>
                                                                                                </> :
                                                                                                    <>
                                                                                                        <div className="greyShadedText box-status">
                                                                                                            {boxStatus[deliveryFormik.values.cdr]}
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.fleet, 'fleet', false)} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.fleet}>
                                                                                                <div className={`blueShadedText ${deliveryFormik.values.fleet === 2 ? "required-Label" : ""}`}>
                                                                                                    Fleet
                                                                                                </div>
                                                                                                {deliveryFormik.values.fleet === 2 ? <>
                                                                                                    <div className=" fleetNumberWidth request-delivery" style={{ display: "flex", marginLeft: "-5px" }}>
                                                                                                        <span className="greyShadedText box-status" style={{ padding: "4px 11px 0px 7px" }}>Yes</span>

                                                                                                        <div className="cdrDate" style={{ width: "auto" }}>
                                                                                                            <TextField
                                                                                                                id="fleetNumber"
                                                                                                                name="fleetNumber"
                                                                                                                style={{ width: "auto" }}
                                                                                                                value={deliveryFormik.values.fleetNumber}
                                                                                                                onChange={deliveryFormik.handleChange}
                                                                                                                onBlur={deliveryFormik.handleBlur}
                                                                                                                errorMessage={
                                                                                                                    deliveryFormik.touched.fleetNumber &&
                                                                                                                    deliveryFormik.errors.fleetNumber &&
                                                                                                                    deliveryFormik.errors.fleetNumber.toString()
                                                                                                                }
                                                                                                                disabled={writeUpsReadOnly}
                                                                                                            />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </> :
                                                                                                    <>
                                                                                                        <div className="greyShadedText box-status">
                                                                                                            {boxStatus[deliveryFormik.values.fleet]}
                                                                                                        </div>
                                                                                                    </>
                                                                                                }

                                                                                            </div>


                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cpo, 'cpo')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.cpo}>
                                                                                                <div className="blueShadedText">
                                                                                                    CPO
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.cpo]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="boxColRowSpaces box-row">
                                                                                    <Col className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.sfe, 'sfe')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.sfe}>
                                                                                                <div className="blueShadedText">
                                                                                                    SFE
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.sfe]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col className=" box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.cda, 'cda')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.cda}>
                                                                                                <div className="blueShadedText">
                                                                                                    CDA
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.cda]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                    <Col className="box-column">
                                                                                        <Link onClick={(e) => handleBoxClick(e, deliveryFormik.values.locate, 'locate')} disabled={writeUpsReadOnly} className="box-link">
                                                                                            <div className={" box-section box-status-" + deliveryFormik.values.locate}>
                                                                                                <div className="blueShadedText">
                                                                                                    Locate
                                                                                                </div>
                                                                                                <div className="greyShadedText box-status">
                                                                                                    {boxStatus[deliveryFormik.values.locate]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className="delivery-section" style={{ padding: "0px 0px 14px" }} >
                                                                                <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal " justify="center">
                                                                                    <Col>
                                                                                        <label className="lbl-text ">Deposit</label>
                                                                                        <CurrencyInput
                                                                                            allowNegativeValue={false}
                                                                                            customInput={OffTextField}
                                                                                            // thousandSeparator={true}
                                                                                            prefix={PRICE_PREFIX}
                                                                                            id="deposit"
                                                                                            name="deposit"
                                                                                            //label="Deposit"
                                                                                            decimalScale={2}
                                                                                            value={deliveryFormik.values.deposit ?deliveryFormik.values.deposit.replace(/,/g, ''):''}
                                                                                            onValueChange={(e) => handlePriceChange(e, 'deposit')}
                                                                                            onBlur={deliveryFormik.handleBlur}
                                                                                            disabled={writeUpsReadOnly}
                                                                                        />
                                                                                        <span className="error-message">
                                                                                            {deliveryFormik.touched.deposit &&
                                                                                                deliveryFormik.errors.deposit &&
                                                                                                deliveryFormik.errors.deposit.toString()}
                                                                                        </span>

                                                                                    </Col>
                                                                                    <Col>
                                                                                        {(deliveryFormik.values.sfe === 2 || deliveryFormik.values.fleet === 2) ?
                                                                                            <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal ">
                                                                                                <Col>
                                                                                                    <label className="lbl-text">Delivery Code</label>
                                                                                                    <PRDropdown
                                                                                                        id="deliveryCode"
                                                                                                        placeholder=""
                                                                                                        value={deliveryFormik.values.deliveryCode}
                                                                                                        options={masterDropdownValues.deliveryCodes}
                                                                                                        onChange={handleDealTrackerFormikDDChange}
                                                                                                        optionLabel="text"
                                                                                                        optionValue="key"
                                                                                                        // appendTo="self"
                                                                                                        className="custom-p-dropdown"
                                                                                                        filter={false}
                                                                                                        resetFilterOnHide={true}
                                                                                                        disabled={writeUpsReadOnly}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row> : ""}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div >
                                                                                <strong className="writeUpsFont">Finance </strong><hr />
                                                                            </div>
                                                                            <div className="delivery-section" style={{ padding: "0px 0px" }} > {/* className="main-section-body" */}
                                                                                {/*<Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal " justify="center">
                                                                                <Col md={6}>
                                                                                    <CurrencyInput
                                                                                        allowNegativeValue={false}
                                                                                        customInput={OffTextField}
                                                                                       // thousandSeparator={true}
                                                                                        prefix={PRICE_PREFIX}
                                                                                        id="deposit"
                                                                                        name="deposit"
                                                                                        label="Deposit"
                                                                                        decimalScale={2}
                                                                                        value={deliveryFormik.values.deposit}
                                                                                        onChange={deliveryFormik.handleChange}
                                                                                        onBlur={deliveryFormik.handleBlur}
                                                                                        errormessage={
                                                                                            deliveryFormik.touched.deposit &&
                                                                                            deliveryFormik.errors.deposit &&
                                                                                            deliveryFormik.errors.deposit.toString()
                                                                                        } />
                                                                                    

                                                                                </Col>
                                                                             <Col>
                                                                                    <CurrencyInput
                                                                                        allowNegativeValue={false}
                                                                                        customInput={OffTextField}
                                                                                       // thousandSeparator={true}
                                                                                        prefix={PRICE_PREFIX}
                                                                                        id="grossProfit"
                                                                                        name="grossProfit"
                                                                                        label="Gross Profit"
                                                                                        decimalScale={2}
                                                                                        // className="float-right"
                                                                                        value={deliveryFormik.values.grossProfit}
                                                                                        onChange={deliveryFormik.handleChange}
                                                                                        onBlur={deliveryFormik.handleBlur}
                                                                                        errormessage={
                                                                                            deliveryFormik.touched.grossProfit &&
                                                                                            deliveryFormik.errors.grossProfit &&
                                                                                            deliveryFormik.errors.grossProfit.toString()
                                                                                        }
                                                                                    />
                                                                                </Col> 
                                                                            </Row> */}

                                                                            <Row className="rowSpace rowSpaceHeight rowPaddingBtwSpace main-deal " justify="center">
                                                                                <Col>                                                                                   
                                                                                    <Row className="rowSpaceHeight" justify="center">
                                                                                        <Col >
                                                                                            <label className="lbl-text">Finance Status</label>
                                                                                            <PRDropdown
                                                                                              id="financeStatus"
                                                                                              placeholder=""
                                                                                              value={deliveryFormik.values.financeStatus}
                                                                                              options={masterDropdownValues.dealFinanceStatus}
                                                                                              onChange={handleDealTrackerFormikDDChange}
                                                                                              optionLabel="text"
                                                                                              optionValue="key"
                                                                                              className="custom-p-dropdown"
                                                                                              filter={false}
                                                                                              resetFilterOnHide={true}
                                                                                              disabled={writeUpsReadOnly}
                                                                                            />
                                                                                                                                                                             
                                                                                            
                                                                                         </Col>
                                                                                    </Row>
                                                                                    <Row className="" justify="center">
                                                                                        <Col md={5} style={{ display: "flex" }} >

                                                                                                <OffTextField
                                                                                                    id="creditScore"
                                                                                                    name="creditScore"
                                                                                                    label="Credit Score"
                                                                                                    resizable={false}
                                                                                                    value={deliveryFormik.values.creditScore}
                                                                                                    onChange={deliveryFormik.handleChange}
                                                                                                    onBlur={deliveryFormik.handleBlur}
                                                                                                    errorMessage={
                                                                                                        deliveryFormik.touched.creditScore &&
                                                                                                        deliveryFormik.errors.creditScore &&
                                                                                                        deliveryFormik.errors.creditScore.toString()
                                                                                                    }
                                                                                                    disabled={writeUpsReadOnly}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col md={7}>
                                                                                                <label className="lbl-text">Lender</label>
                                                                                                <PRDropdown
                                                                                                    id="lenderId"
                                                                                                    placeholder=""
                                                                                                    value={deliveryFormik.values.lenderId}
                                                                                                    options={lenders}
                                                                                                    onChange={handleDealTrackerFormikDDChange}
                                                                                                    optionLabel="name"
                                                                                                    optionValue="id"
                                                                                                    // appendTo="self"
                                                                                                    className="custom-p-dropdown"
                                                                                                    filter={false}
                                                                                                    resetFilterOnHide={true}
                                                                                                    disabled={writeUpsReadOnly}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <label className="lbl-text ">Gross Profit</label>
                                                                                                <CurrencyInput
                                                                                                    allowNegativeValue={true}
                                                                                                    customInput={OffTextField}
                                                                                                    // thousandSeparator={true}
                                                                                                    prefix={"$"}
                                                                                                    id="grossProfit"
                                                                                                    name="grossProfit"
                                                                                                    // label="Gross Profit"
                                                                                                    decimalScale={2}
                                                                                                    // className="float-right"
                                                                                                    value={deliveryFormik.values.grossProfit ? deliveryFormik.values.grossProfit.replace(/,/g, ''):""}
                                                                                                    onValueChange={(e) => handlePriceChange(e, 'grossProfit')}
                                                                                                    onBlur={deliveryFormik.handleBlur}
                                                                                                    disabled={writeUpsReadOnly}
                                                                                                />
                                                                                                <span className="error-message">
                                                                                                    {deliveryFormik.touched.grossProfit &&
                                                                                                        deliveryFormik.errors.grossProfit &&
                                                                                                        deliveryFormik.errors.grossProfit.toString()}
                                                                                                </span>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {/*<Row className="rowSpaceHeight financeField" justify="center">
                                                                                        <Col >
                                                                                            <CurrencyInput
                                                                                                allowNegativeValue={false}
                                                                                                customInput={OffTextField}
                                                                                               // thousandSeparator={true}
                                                                                                prefix={PRICE_PREFIX}
                                                                                                id="financeIncome"
                                                                                                name="financeIncome"
                                                                                                label="Finance Income"
                                                                                                decimalScale={2}
                                                                                                value={deliveryFormik.values.financeIncome}
                                                                                                onChange={deliveryFormik.handleChange}
                                                                                                onBlur={deliveryFormik.handleBlur}
                                                                                                errormessage={
                                                                                                    deliveryFormik.touched.financeIncome &&
                                                                                                    deliveryFormik.errors.financeIncome &&
                                                                                                    deliveryFormik.errors.financeIncome.toString()
                                                                                                }

                                                                                            />
                                                                                        </Col>
                                                                                    </Row>*/}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="datatable-filter-mobTable table-section datatab-Section">
                                                                                            <div className="card  p-datatable-customers-list " >
                                                                                                <CustomTable
                                                                                                    column_list={fp_columns_list}
                                                                                                    paginator={false}
                                                                                                    data={tableData}
                                                                                                    customTemplates={{
                                                                                                        "amount": amountBodyTemplate,
                                                                                                        "financeProduct": addEditTemplate,
                                                                                                    }}
                                                                                                    emptyMessage="No data found."
                                                                                                    onRowClick={(event) => handleRowClick(event)}
                                                                                                />
                                                                                            </div>
                                                                                            <div style={{ textAlign: "right", margin: "-20px 15px 10px" }}>
                                                                                                <Link
                                                                                                    disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                                                                                    color="inherit"
                                                                                                    onClick={navigatetoOpenProductDialog}>
                                                                                                    <AddCircle style={{ ...themeStyles.icon, color: "#70af60", margin: "0px 5px" }} />
                                                                                                    Product
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="rowSpace" justify="center">
                                                                                    <Col className="vertical-center">
                                                                                        {
                                                                                            !writeUpsReadOnly && (<PrimaryButton
                                                                                                disabled={!deliveryFormik.dirty || !deliveryFormik.isValid}
                                                                                                onClick={handleDealTrackerFormikSumbit}>
                                                                                                Save
                                                                                            </PrimaryButton>)
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Accordion className="inventory-filter-accordion" defaultExpanded>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            aria-controls="panel1c-content"
                                                                                            id="panel1c-header"
                                                                                            style={{ paddingTop: "0px" }}
                                                                                        >
                                                                                            <div className={"writeUpsFont"} style={{ ...themeStyles.column, paddingTop: "0px" }}>
                                                                                                <Typography component={'h3'} style={themeStyles.heading}>Notes</Typography>
                                                                                            </div>
                                                                                        </AccordionSummary>
                                                                                        <AccordionDetails style={themeStyles.details}>
                                                                                            <Row>
                                                                                                <Col className=" notesSection">
                                                                                                    <OffTextField
                                                                                                        id="deliveryNote"
                                                                                                        name="deliveryNote"
                                                                                                        label=""
                                                                                                        multiline
                                                                                                        resizable={false}
                                                                                                        className=" full-width fontWeightSection"
                                                                                                        value={deliveryNote}
                                                                                                        style={{ fontSize: "14px", fontWeight: 600 }}
                                                                                                        onChange={(e, newValue) => handleDeliveryNoteChange(e, newValue)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {
                                                                                                deliveryNoteError ?
                                                                                                    <div className="">
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <span className="genericErrorColor"><span>Please enter details</span></span>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div> : null
                                                                                            }

                                                                                            <Row className="rowPaddingBtwSpace">
                                                                                                <Col className="text-right">
                                                                                                    <PrimaryButton style={{ marginTop: 10 }} onClick={() => saveDeliveryNotesDetails()}>Add Note</PrimaryButton>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <strong style={{ border: "none" }} className="writeUpsFont">History</strong>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <hr />
                                                                                                    {notesList?.map((item) => (

                                                                                                        <div key={"boxes-" + item.id}>
                                                                                                            <Row className="rowSpaceNotes rowSpace" style={{ padding: "0 20px" }}>
                                                                                                                <Col md={3} xs={4}>
                                                                                                                    <div className="writeUpsNoteText">{moment(new Date(item.createdAt)).local().format("MM/DD/YYYY").toString()}</div>
                                                                                                                    <div className="writeUpsNoteText">{item?.firstName} {item?.lastName}</div>
                                                                                                                </Col>
                                                                                                                <Col md={3} xs={4}>
                                                                                                                    <div className="writeUpsNoteText">{dealType[item?.type]}</div>

                                                                                                                </Col>
                                                                                                                <Col md={6} xs={4} className="writeUpsNoteText">{item.note}</Col>
                                                                                                            </Row>
                                                                                                            <hr />
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Col>
                                                                            </Row>
                                                                            {/* <Row>
                                                                            <Col>
                                                                                <Accordion className="inventory-filter-accordion" defaultExpanded>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMoreIcon />}
                                                                                        aria-controls="panel1c-content"
                                                                                        id="panel1c-header"
                                                                                    >
                                                                                        <div className={classes.column} style={{ paddingTop: "0px" }}>
                                                                                            <Typography component={'h3'} className={classes.heading + "fontWeightSection"}>Deal History</Typography>
                                                                                        </div>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails className={classes.details}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <DataTable
                                                                                                    value={dealHistory}
                                                                                                    className="p-datatable-customers p-datatable-hoverable-rows"
                                                                                                    dataKey="id"
                                                                                                    //cellSelection
                                                                                                    onSelectionChange={e => captureCellSelection(e)}>
                                                                                                    <Column field="deal" header="Deal" />
                                                                                                    <Column field="log" header="Log" />
                                                                                                    <Column field="status" header="Status" />
                                                                                                    <Column field="mileage" header="Customer" />
                                                                                                    <Column field="sourcetye" header="Dept" />
                                                                                                    <Column field="buyPrice" header="Sales" />
                                                                                                    <Column field="qValue" header="Sales Mgr" />
                                                                                                    <Column field="sellPrice" header="Finance" />
                                                                                                    <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                                                                                                </DataTable>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            </Col>
                                                                        </Row>*/}
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                        }
                                                    </> :
                                                    <>
                                                        <div className="delivery-default-section">
                                                            <strong>A desking manager has not created a write-up for this deal.</strong>
                                                            <PrimaryButton
                                                                disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_CREATE) : isMaster)}
                                                                onClick={handleCreatDelivery}>
                                                                Create Write-up
                                                            </PrimaryButton>
                                                        </div>
                                                    </>


                                            }

                                        </div>
                                    </> : null
                                }

                            </TabPanelView>

                            <TabPanelView value={value} index={1}>
                                <Appointment dealId={params.dealID}
                                    onError={(msg) => { setcommonServerError(msg) }}
                                    onFormikChange={(oldData, newData) => onAppointmentFormChange(oldData, newData)}
                                    saveFormOnRoute={saveFormOnRouteMsgAppointment}
                                    dismissSaveNot={(val) => dismissSaveNotMsgAppointment(val)}
                                    userCredentials={credentials}
                                    user={user}
                                    classes={themeStyles}
                                    updateSectionIndex={(msg) => { setupdateAppSectionIndx(msg) }}
                                    setSectionIndx={updateAppSectionIndx}
                                    productId={productId}
                                    tabRoute={tabSwitchFlag}
                                />
                            </TabPanelView>

                            <TabPanelView className="tradein-Tab" value={value} index={2} >
                                <TradeIns dealId={params.dealID}
                                    onError={(msg) => { setcommonServerError(msg) }}
                                    onFormikChange={(oldData, newData) => onTradeInFormChange(oldData, newData)}
                                    saveFormOnRoute={saveFormOnRouteMsgTradeIn}
                                    dismissSaveNot={(val) => dismissSaveNotMsgTradeIn(val)}
                                    userCredentials={credentials}
                                    user={user}
                                    onCreateTardeIn={(flag) => { setisInventorySearchDialog(flag) }}
                                    classes={themeStyles}
                                    updateSectionIndex={(msg) => { setupdateTradeSectionIndx(msg) }}
                                    setSectionIndx={updateTradeSectionIndx}
                                    writeUpsId={deliveryFormik.values.id && Number(deliveryFormik.values.id)}
                                    productId={productId}
                                    tabRoute={tabSwitchFlag}
                                />
                            </TabPanelView>

                            <TabPanelView value={value} index={3}>
                                <Cancellation
                                    dealId={params.dealID}
                                    onFormikChange={(oldData, newData) => onCancellationFormChange(oldData, newData)}
                                    dismissSaveNot={(val) => dismissSaveNotMsgCancel(val)}
                                    saveFormOnRoute={saveFormOnRouteMsgCancel}
                                    userCredentials={credentials}
                                    user={user}
                                    onError={(msg) => { setcommonServerError(msg) }}
                                    updateSectionIndex={(msg) => { setupdateCanSectionIndx(msg) }}
                                    setSectionIndx={updateCanSectionIndx}
                                    productId={productId}
                                    tabRoute={tabSwitchFlag}
                                />
                            </TabPanelView>


                            <TabPanelView value={value} index={4}>
                                {/* {isAddScheduleDialog ?
                                <AddScheduleDialog
                                    isOpen={isAddScheduleDialog}
                                    title="Create Request"
                                    role="sales"
                                    financeDrp={resourceFinance}
                                    deliveryDrp={resourceDelivery}
                                    selectionDetails={selectionDetails}
                                    onDismiss={() => { setisAddScheduleDialog(false)}}
                                />
                                : null} */}

                                {/* <TimePicker minuteStep={15} secondStep={10} /> */}
                                <div className="main-section calendarContainer">
                                    {(Number(params.dealID) === Number(dealTrackerData ? dealTrackerData?.id : 0)) ?
                                        <ScheduleManager
                                            title="Create Request"
                                            role="sales"
                                            selectedEv={selectedEv}
                                            dealId={params.dealID}
                                            selectedDate={null}
                                            onPageChange={(val) => console.log('val', val)}
                                            locationId={dealTrackerData?.location?.id}
                                        />
                                        : null}


                                    {/* <div className='calendarFinance'>                                                                          
                                    <SDCalendar
                                        selectable
                                        events={scheduleSlotEvents}
                                        localizer={localizer}
                                        defaultView={Views.DAY}
                                        views={['day', 'work_week']}
                                        min={new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate(), 8)}
                                        max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18)}
                                        step={15}
                                        defaultDate={new Date(2018, 0, 29)}
                                        resources={resourceMap}
                                        resourceIdAccessor="resourceId"
                                        resourceTitleAccessor="resourceTitle"
                                        onSelectEvent={event => alert(event.title)}
                                        onSelectSlot={handleSelectSlot}
                                        eventPropGetter={eventColors}
                                        />
                                </div>  
                               <div className='calendarDelivery'>                                                                          
                                    <SDCalendar
                                        selectable
                                        events={deliverySlotEvents}
                                        localizer={localizer}
                                        defaultView={Views.DAY}
                                        views={['day', 'work_week']}
                                        min={new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate(), 8)}
                                        max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18)}
                                        step={15}
                                        defaultDate={new Date(2018, 0, 29)}
                                        resources={resourceMap}
                                        resourceIdAccessor="resourceId"
                                        resourceTitleAccessor="resourceTitle"
                                        onSelectEvent={event => alert(event.title)}
                                        onSelectSlot={handleSelectSlot}
                                        eventPropGetter={eventColors}
                                        />
                                </div> */}
                                </div>
                            </TabPanelView>

                            <TabPanelView value={value} index={5}>
                                <Task dealId={params.dealID}
                                    onError={(msg) => { setcommonServerError(msg) }}
                                    onFormikChange={(oldData, newData) => onTaskFormChange(oldData, newData)}
                                    saveFormOnRoute={saveFormOnRouteMsgTask}
                                    dismissSaveNot={(val) => dismissSaveNotMsgTask(val)}
                                    updateSectionIndex={(msg) => { setupdateTaskSectionIndx(msg) }}
                                    setSectionIndx={updateTaskSectionIndx}
                                    productId={productId}
                                    tabRoute={tabSwitchFlag}
                                    userProfile={user}
                                />
                            </TabPanelView>

                            <TabPanelView value={value} index={6}>

                                {/*<ServiceContract
                                 onFormikChange={(oldData, newData) => onServiceFormChange(oldData,newData)}/>*/}
                                {serviceContractWorkbook ?
                                    <Row justify="center">
                                        <Col md={12}>
                                            <div className="datatable-filter-demo noMaxWidthTable addIcon-Click">

                                                <div className="card mouse-hover" >
                                                    <div style={{ textAlign: "left" }}>
                                                        <Link color="inherit" onClick={navtoOpenServiceContractPage}>
                                                            <AddCircle style={themeStyles.icon} className={" circle-icon "} />
                                                            Add Service Contract
                                                        </Link>
                                                    </div>

                                                    <CustomTable
                                                        column_list={serviceContract_columns_list}
                                                        //paginator={false}
                                                        data={serviceContractData}
                                                        rows={25}
                                                        rowsPerPageOptions={[25, 50, 100]}
                                                        customTemplates={{
                                                            "logDate": logDateServiceBodyTemplate,
                                                            "empName": financeFirstServiceBodyTemplate,
                                                            //"financeSecond": financeSecondServiceBodyTemplate,
                                                            "product": productServiceBodyTemplate,
                                                            "price": priceServiceBodyTemplate,
                                                            "cost": costServiceBodyTemplate,
                                                            "deductable": deductibleServiceBodyTemplate,
                                                            "profit": profitServiceBodyTemplate,
                                                            "term": termBodyTemplate,
                                                            "mileage": mileageServiceBodyTemplate,
                                                            "closeDate": closeDateServiceBodyTemplate,
                                                            "settings": settingServiceBodyTemplate,
                                                        }}
                                                        emptyMessage="No data found."
                                                        onRowClick={(event) => handleServiceContractRowClick(event)}
                                                    />
                                                </div>
                                                {/*   <div  style={{ textAlign: "right", margin: "-20px 15px 10px"}}>
                                                     <Link color="inherit" onClick={navtoOpenServiceContractPage}>
                                                         <AddCircle className={classes.icon + " circle-icon "} />
									                     Add Service Contract
									                  </Link>
                                             </div>*/}

                                            </div>
                                        </Col>
                                    </Row> :
                                    <>
                                        <div className="serviceContractUIWidth">
                                            <ServiceContract
                                                onFormikChange={(oldData, newData) => onServiceFormChange(oldData, newData)}
                                                onDismiss={navtoOpenServiceContractWorkbook}
                                                saveFormOnRoute={saveFormOnRouteMsgServ}
                                                dismissSaveNot={(val) => dismissSaveNotMsgServ(val)}
                                                formData={serviceData} />
                                        </div>
                                    </>
                                }
                            </TabPanelView>

                            <TabPanelView value={value} index={7}>
                                <div>
                                    <VehiclePrep deal={dealTrackerData} />
                                </div>
                            </TabPanelView>
                        </SwipeableViews>
                    </>)}


                </div>
            </Box>
        </Stack>

    </>);

};


