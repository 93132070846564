import {
    call,
    put,
    takeEvery,
    takeLeading,
    select,
    takeLatest,
} from "redux-saga/effects";

import {
    pendingCreateEmployee,
    pendingDeleteEmployee,
    pendingGetEmployees,
    pendingGetEmployeesForAddGroupAssignEmployee,
    pendingGetEmployeesForAddGroupAssignedEmployee,
    pendingUpdateEmployee,
    pendingViewEmployee,
    rejectedCreateEmployee,
    rejectedGetEmployees,
    rejectedUpdateEmployee,
    rejectedGetEmployeeTasks,
    resolvedCreateEmployee,
    resolvedDeleteEmployee,
    resolvedGetEmployees,
    resolvedUpdateEmployee,
    resolvedViewEmployee,
    resolvedGetEmployeesForAddGroupAssignedEmployee,
    resolvedGetEmployeesForAddGroupAssignEmployee,
    //setEmployeePasswordError,
    pendingGetEmployeeTasks,
    resolvedGetEmployeeTasks,
    pendingGetEmployeesAssignedProspect,
    rejectedGetEmployeesAssignedProspect,
    resolvedGetEmployeesAssignedProspect,
    pendingEmployeeActivate,
    rejectedEmployeeActivate,
    rejectedSendInvite,
    resolvedSendInvite,
    rejectedViewEmployee,
    pendingGetEmployeesFinance,
    resolvedGetEmployeesFinance,
    rejectedGetEmployeesFinance,
    pendingViewEmployeeWeeklyHours,
    resolvedViewEmployeeWeeklyHours,
    rejectedViewEmployeeWeeklyHours

} from "store/employees/employees.action";

import {
    createEmployee,
    deleteEmployee,
    getEmployees,
    getEmployeesForAddGroupAssignedEmployee,
    getEmployeesForAddGroupAssignEmployee,
    updateEmployee,
    viewEmployee,
    //updatePassword,
    //removeEmployeeFromGroup,
    // getEmployeeTasks,
    getUserMasterTasks,
    addEmployeeProfile,
    activateEmployee,
    sendInvite,
    updateEmployeePermissions,
    getEmployeePermissions,
    getEmployeeManagers,
    getEmployeesSaleFinance,
    createEmployeeWeeklyHours,
    getEmployeeWeeklyHours,
    updateEmployeeTmpAvail,
    updateEmployeeTimeOff,
    getEmployeeMonthlyCalendar,
    deleteEmployeeMonthlyEvents,
} from "store/employees/employees.api";
import {
    HANDLE_CREATE_EMPLOYEE,
    HANDLE_DELETE_EMPLOYEE,
    HANDLE_GET_EMPLOYEES,
    HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
    HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE,
    HANDLE_UPDATE_EMPLOYEE,
    HANDLE_VIEW_EMPLOYEE,
    HandleCreateEmployee,
    HandleDeleteEmployee,
    HandleGetEmployees,
    HandleGetEmployeesForAddGroupAssignEmployee,
    HandleGetEmployeesForAddGroupAssignedEmployee,
    HandleUpdateEmployee,
    HandleViewEmployee,
    HANDLE_GET_EMPLOYEE_TASKS,
    HandleGetEmployeeTasks,
    HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT,
    HandleGetEmployeesAssignedProspect,
    HandleEmployeeActivate,
    HANDLE_EMPLOYEE_ACTIVATE,
    HandleSendInvite,
    HANDLE_SEND_INVITE,
    HandleSaveEmployeePermissions,
    HANDLE_SAVE_EMPLOYEE_PERMISSIONS,
    HANDLE_GET_EMPLOYEE_MANAGERS,
    HandleGetEmployeeManagers,
    HANDLE_GET_EMPLOYEES_SALE_FINANCE,
    HandleGetEmployeeSaleFinance,
    HANDLE_GET_EMPLOYEES_FINANCE,
    HandleGetEmployeeFinance,
    HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS,
    HandleSaveEmployeeWeeklyHours,
    HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS,
    HandleViewEmployeeWeeklyHours,
    HANDLE_EMPLOYEE_TMP_AVAIL,
    HANDLE_EMPLOYEE_TIME_OFF,
    HandleEmployeeTmpAvail,
    HandleEmployeeTimeOff,
    HANDLE_EMPLOYEE_GET_MONTH_CAL,
    HandleEmployeeGetMonthCal,
    HandleEmployeeDeleteEvents,
    HANDLE_EMPLOYEE_DELETE_EVENTS


} from "store/employees/employees.types";
//import { addEmployeeToGroupSaga } from "store/groups/groups.saga";
//import {
//HANDLE_ADD_EMPLOYEE_TO_GROUP,
//HandleAddEmployeeToGroup,
//} from "store/groups/groups.types";
import { AppState } from "store";
//import { setSortEmployees } from "store/employees/employees.action";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

//export function* getEmployeesSaga(actions: HandleGetEmployees) {
//  yield put(switchGlobalLoader(true));
//  yield put(pendingGetEmployees());

//  // const {email, password, firstName, lastName } = action.payload;

//  // console.log(actions.payload);
//  const sortStore = yield select((state: AppState) => state.employees.sort);
//  const sortColumn = yield select((state: AppState) => state.employees.column);
//  const {
//    limit,
//    page,
//    withoutGroup,
//    withGroup,
//    sort,
//    column,
//  } = actions.payload;

//  yield put(setSortEmployees(sort ? sort : sortStore));
//  //const rowsPerPage = yield select((state: AppState) => state.employees.rowsPerPage);
//  // const currentPage = yield select((state: AppState) => state.employees.employees.currentPage);

//  const { error, data } = yield getEmployees({
//    limit,
//    page,
//    withoutGroup,
//    withGroup,
//    sort: sort ? sort : sortStore,
//    column: column ? column : sortColumn,
//  });

//  if (error) {
//    yield put(rejectedGetEmployees(error.violations as any));
//    yield put(switchGlobalLoader(false));
//    return;
//  }

//  yield put(resolvedGetEmployees(data));
//  yield put(switchGlobalLoader(false));
//  //history.push("/success");
//}

export function* getEmployeesSaga(actions: HandleGetEmployees) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployees());
    const {
        isActive, role, departmentId
    } = actions.payload;

    const { error, data } = yield getEmployees({
        isActive, role, departmentId
    });

    if (error) {
        yield put(rejectedGetEmployees(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedGetEmployees(data));
    yield put(switchGlobalLoader(false));
    //history.push("/success");
}

export function* getEmployeesForAddGroupAssignEmployeeSaga(
    actions: HandleGetEmployeesForAddGroupAssignEmployee
) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployeesForAddGroupAssignEmployee());
    const { limit, page, withoutGroup } = actions.payload;

    const { error, data } = yield getEmployeesForAddGroupAssignEmployee({
        limit,
        page,
        withoutGroup,
    });
    if (error) {
        //yield put(rejectedGetEmployeesForAddGroupAssignEmployee(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedGetEmployeesForAddGroupAssignEmployee(data));
    yield put(switchGlobalLoader(false));
}

export function* getEmployeesForAddGroupAssignedEmployeeSaga(
    actions: HandleGetEmployeesForAddGroupAssignedEmployee
) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployeesForAddGroupAssignedEmployee());
    const { limit, page, withGroup } = actions.payload;

    const { error, data } = yield getEmployeesForAddGroupAssignedEmployee({
        limit,
        page,
        withGroup,
    });
    if (error) {
        //yield put(rejectedGetEmployeesForAddGroupAssignedEmployee(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedGetEmployeesForAddGroupAssignedEmployee(data));
    yield put(switchGlobalLoader(false));
}

//function* createEmployeeSaga(actions: HandleCreateEmployee) {
//  yield put(pendingCreateEmployee());

//  try {
//    const { data } = yield createEmployee(actions.payload.employee);

//    // //todo assignToGroup
//    // console.log(action.payload.assignTo);
//    // console.log(data.id);
//    // yield call(handleAddEmployeeToGroup( data.id,  parseInt(action.payload.assignTo) ));

//    //action.payload.dispatch<any>(handleAddEmployeeToGroup, {data.id, parseInt(action.payload.assignTo)});

//    // yield call(
//    //   handleAddEmployeeToGroup,data.id, parseInt(action.payload.assignTo)
//    // );
//    //

//    const action: HandleAddEmployeeToGroup = {
//      payload: {
//        employeeIds: [data.id],
//        groupId: parseInt(actions.payload.assignTo),
//      },
//      type: HANDLE_ADD_EMPLOYEE_TO_GROUP,
//    };
//    yield call(addEmployeeToGroupSaga, action);
//    const rowsPerPage = yield select(
//      (state: AppState) => state.employees.rowsPerPage
//    );

//    const getEmployeesAction: HandleGetEmployees = {
//        payload: {
//            isActive:true,
//            limit: rowsPerPage, page: 1
//        },
//      type: HANDLE_GET_EMPLOYEES,
//    };
//    yield put(resolvedCreateEmployee(data));
//    yield call(getEmployeesSaga, getEmployeesAction);

//    yield call(actions.payload.callback);
//  } catch (error) {
//    yield put(rejectedCreateEmployee(error.violations));
//    return;
//  }
//}

function* createEmployeeSaga(actions: HandleCreateEmployee) {
    yield put(pendingCreateEmployee());
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield createEmployee(actions.payload.employee);
        //const action: HandleAddEmployeeToGroup = {
        //    payload: {
        //        employeeIds: [data.id],
        //        groupId: parseInt(actions.payload.assignTo),
        //    },
        //    type: HANDLE_ADD_EMPLOYEE_TO_GROUP,
        //};
        //yield call(addEmployeeToGroupSaga, action);
        //const rowsPerPage = yield select(
        //    (state: AppState) => state.employees.rowsPerPage
        //);
        if (error) {
            yield put(rejectedCreateEmployee(error));
            yield put(switchGlobalLoader(false));
            return;
        }


        const profileImg: any = actions.payload.employee.avatar;
        if (profileImg && typeof profileImg.name == 'string') {
            const formData = new FormData();
            formData.append("avatar", profileImg, (profileImg as any).name);
            const { error } = yield addEmployeeProfile(data.id, formData);
            if (error) {
                yield put(rejectedCreateEmployee(error));
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        if (actions.payload.employee.defaultGroupIds && actions.payload.employee.defaultGroupIds.length > 0) {
            let arr = [];
            actions.payload.employee.defaultGroupIds.forEach(function (item, index) {
                let groupId = { "defaultGroupId": item};
                arr.push(groupId);
            });
            let obj = {
                "permissions":arr
            }
            const { error } = yield updateEmployeePermissions(data.id, obj);
            if (error) {
                yield put(rejectedCreateEmployee(error));
                yield put(switchGlobalLoader(false));
                return;
            }
        }

        const getEmployeesAction: HandleGetEmployees = {
            payload: {
                isActive: 1,
                limit: 1000, page: 1
            },
            type: HANDLE_GET_EMPLOYEES,
        };
        yield put(resolvedCreateEmployee(data));
        yield call(getEmployeesSaga, getEmployeesAction);
        yield put(switchGlobalLoader(false));
        yield call(actions.payload.callback);
    } catch (error) {
        yield put(switchGlobalLoader(false));
        yield put(rejectedCreateEmployee(error.violations));
        return;
    }
}

function* deleteEmployeeSaga(action: HandleDeleteEmployee) {
    const { ids, callback } = action.payload;
    yield put(pendingDeleteEmployee());
    //console.log(action.payload);
    // const {error} = yield deleteEmployee(action.payload.id);
    for (const employeeId of ids) {
        const { error } = yield deleteEmployee(employeeId);
        if (error) {
            return;
        }
    }
    // console.log(typeof action.payload.callback === "function");
    if (typeof callback === "function") {
        callback();
    }
    // todo refresh

    yield put(resolvedDeleteEmployee());
    const rowsPerPage = yield select(
        (state: AppState) => state.employees.rowsPerPage
    );
    const page = yield select(
        (state: AppState) => state.employees.employees.currentPage
    );
    const getEmployeesAction: HandleGetEmployees = {
        payload: {
            isActive: 1,
            limit: rowsPerPage, page: page
        },
        type: HANDLE_GET_EMPLOYEES,
    };
    yield call(getEmployeesSaga, getEmployeesAction);
    //yield call(getEmployeesSaga);
}

function* viewEmployeeSaga(action: HandleViewEmployee) {
    yield put(switchGlobalLoader(true));
    yield put(pendingViewEmployee());
    const { error, data } = yield viewEmployee(action.payload.id);
    if (data) {
        const { dataFilter, errorFilter } = yield getEmployeePermissions(data.id);
        if (errorFilter) {
            yield put(switchGlobalLoader(false));
            yield put(rejectedViewEmployee(errorFilter));
            return;
        }
        let managers;
        if (action.payload.isKarlProfile) {
            managers = yield getEmployeeManagers();
            if (managers.errorFilter) {
                yield put(switchGlobalLoader(false));
                yield put(rejectedViewEmployee(errorFilter));
                return;
            }
        }
        yield put(resolvedViewEmployee({ employee: data, permissions: dataFilter.results, managers: managers && managers.dataFilter?managers.dataFilter.results:[] }));

    } else {
        yield put(rejectedViewEmployee(error));
    }
    return;
}

function* viewEmployeeWeeklyHoursSaga(action: HandleViewEmployeeWeeklyHours) {

    yield put(switchGlobalLoader(false));
    try {
        yield put(pendingViewEmployeeWeeklyHours());
        const { dataFilter, errorFilter } = yield getEmployeeWeeklyHours(action.payload.id);
        if (dataFilter) {
            yield put(resolvedViewEmployeeWeeklyHours(dataFilter.results));
        } else {
            yield put(rejectedViewEmployeeWeeklyHours(errorFilter));
        }
        yield put(switchGlobalLoader(false));
    } catch (errorFilter) {
        yield put(rejectedViewEmployeeWeeklyHours(errorFilter));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* updateEmployeeSaga(action: HandleUpdateEmployee) {
    yield put(switchGlobalLoader(true));
    const { employees } = action.payload;
    yield put(pendingUpdateEmployee());
    // const {error} = yield updateEmployee(
    //     action.payload.data.id,
    //     action.payload.data
    // );
    try {
        //for (const employee of employees) {
        //  const { error } = yield updateEmployee(employee.id, employee);
        //  if (error) {
        //    return;
        //  }
        //  if (employee.userMasterGroup === "undefined") {
        //    const { error, data } = yield removeEmployeeFromGroup(employee.id);
        //    if (error) {
        //    }

        //    if (data) {
        //    }
        //  } else {
        //    const addToGroupAction: HandleAddEmployeeToGroup = {
        //      payload: {
        //        employeeIds: [employee.id],
        //        groupId: parseInt(employee.userMasterGroup.id),
        //      },
        //      type: HANDLE_ADD_EMPLOYEE_TO_GROUP,
        //    };

        //    yield call(addEmployeeToGroupSaga, addToGroupAction);
        //  }

        //  if (employee.password) {
        //    const { data, error } = yield updatePassword(
        //      employee.id,
        //      employee.password
        //    );

        //    if (error) {
        //      yield put(setEmployeePasswordError(error.message));
        //      return;
        //    }
        //    console.log(data);
        //    console.log(error);
        //  }
        //}

        let employee = employees[0];
        if (!employee.managerId || employee.managerId === '0') {
            delete employee.managerId;
        }
        const { error, data } = yield updateEmployee(employee.id, employee);
        if (error) {
            yield put(rejectedUpdateEmployee(error));
            yield put(switchGlobalLoader(false));
            return;
        }

        const avatar: any = employee.avatar;
        if (avatar && typeof avatar.name == 'string') {
            const formData = new FormData();
            formData.append("avatar", avatar, (avatar as any).name);
            const { error } = yield addEmployeeProfile(employee.id, formData);
            if (error) {
                yield put(rejectedUpdateEmployee(error));
                yield put(switchGlobalLoader(false));
                return;
            }
        }


        const rowsPerPage = yield select(
            (state: AppState) => state.employees.rowsPerPage
        );
        const page = yield select(
            (state: AppState) => state.employees.employees.currentPage
        );

        const getEmployeesAction: HandleGetEmployees = {
            payload: {
                isActive: employee.isActive ? 1 : 0,
                limit: rowsPerPage, page: page
            },
            type: HANDLE_GET_EMPLOYEES,
        };
        yield call(getEmployeesSaga, getEmployeesAction);
        yield put(resolvedUpdateEmployee(data));
    } catch (error) {
        yield put(rejectedUpdateEmployee(error));
        yield put(switchGlobalLoader(false));
        return;
    }



    //todo if user master group  unusign
    // usuwac z grupy

    if (typeof action.payload.callback === "function") {
        action.payload.callback();
    }

    //yield put(setEmployeePasswordError(""));
}

export function* getEmployeeTasksSaga(action: HandleGetEmployeeTasks) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployeeTasks());

    const { employeeId, isUserMaster } = action.payload;

    try {
        let employeeTasks;

        if (isUserMaster) {


            const { data, error } = yield getUserMasterTasks(employeeId);

            employeeTasks = data;

            if (error) {
                yield put(rejectedGetEmployeeTasks(error));
                return
            }
        } else {
            // Not required for now while EMployee access dashboard
            /* const { data , error} = yield getEmployeeTasks(employeeId);
            
            employeeTasks = data;
            if(error){
              yield put(rejectedGetEmployeeTasks(error));
              return
            } */
        }



        yield put(resolvedGetEmployeeTasks(employeeTasks));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetEmployeeTasks(error));
        yield put(switchGlobalLoader(false));
    }
}

export function* getProspectEmployeesSaga(
    action: HandleGetEmployeesAssignedProspect
) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployeesAssignedProspect());

    const {
        prospectId,
        assignedPagination,
        unassignedPagination,
    } = action.payload;



    console.log(prospectId, assignedPagination, unassignedPagination);
    // const {email, password, firstName, lastName } = action.payload;



    // console.log(actions.payload);
    //const sortStore = yield select((state: AppState) => state.employees.sort);
    //const sortColumn = yield select((state: AppState) => state.employees.column);
    //const rowsPerPage = yield select((state: AppState) => state.employees.rowsPerPage);
    // const currentPage = yield select((state: AppState) => state.employees.employees.currentPage);

    const {
        error: errorEmployeesUnassigned,
        data: employeesUnassigned,
    } = yield getEmployees({
        isActive: 1,
        //limit: unassignedPagination.limit,
        //page: unassignedPagination.page,
        //sort: unassignedPagination.sort ? unassignedPagination.sort : sortStore,
        //column: unassignedPagination.column
        //  ? unassignedPagination.column
        //  : sortColumn,
        //withoutProspect: prospectId,
    });


    const {
        error: errorEmployeesAssigned,
        data: employeesAssigned,
    } = yield getEmployees({
        isActive: 1,
        //limit: assignedPagination.limit,
        //page: assignedPagination.page,
        //sort: assignedPagination.sort ? assignedPagination.sort : sortStore,
        //column: assignedPagination.column ? assignedPagination.column : sortColumn,
        //withProspect: prospectId,
    });

    if (errorEmployeesUnassigned || errorEmployeesAssigned) {
        yield put(
            rejectedGetEmployeesAssignedProspect(
                errorEmployeesUnassigned
                    ? (errorEmployeesUnassigned.violations as any)
                    : errorEmployeesAssigned
                        ? (errorEmployeesAssigned.violations as any)
                        : ""
            )
        );
        yield put(switchGlobalLoader(false));
        return;
    }

    console.log('emplo assi', employeesAssigned);

    console.log('emplo unas', employeesUnassigned)


    yield put(
        resolvedGetEmployeesAssignedProspect({
            assigned: employeesAssigned,
            unassigned: employeesUnassigned,
        })
    );
    yield put(switchGlobalLoader(false));
    //history.push("/success");
}

function* activateEmployeeSaga(action: HandleEmployeeActivate) {
    const { id, isActive } = action.payload;
    yield put(pendingEmployeeActivate());

    const { error } = yield activateEmployee(id, isActive);
    if (error) {
        yield put(rejectedEmployeeActivate(error));
        return;
    }
    // todo refresh

    yield put(resolvedDeleteEmployee());
    const rowsPerPage = yield select(
        (state: AppState) => state.employees.rowsPerPage
    );
    const page = yield select(
        (state: AppState) => state.employees.employees.currentPage
    );
    const getEmployeesAction: HandleGetEmployees = {
        payload: {
            isActive: isActive ? 0 : 1,
            limit: rowsPerPage, page: page
        },
        type: HANDLE_GET_EMPLOYEES,
    };
    yield call(getEmployeesSaga, getEmployeesAction);
    //yield call(getEmployeesSaga);
}

function* sendInviteSaga(action: HandleSendInvite) {
    yield put(switchGlobalLoader(true));
    const { id } = action.payload;
    yield put(pendingEmployeeActivate());

    const { error } = yield sendInvite(id);
    if (error) {
        yield put(rejectedSendInvite(error));
        yield put(switchGlobalLoader(false));
        return;
    }
    // todo refresh

    yield put(resolvedSendInvite());
    const rowsPerPage = yield select(
        (state: AppState) => state.employees.rowsPerPage
    );
    const page = yield select(
        (state: AppState) => state.employees.employees.currentPage
    );
    const getEmployeesAction: HandleGetEmployees = {
        payload: {
            isActive: 1,
            limit: rowsPerPage, page: page
        },
        type: HANDLE_GET_EMPLOYEES,
    };
    yield call(getEmployeesSaga, getEmployeesAction);
    //yield call(getEmployeesSaga);
}

function* saveEmployeePermissionSaga(action: HandleSaveEmployeePermissions) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, permissions } = action.payload;
        const { data } = yield updateEmployeePermissions(id, permissions);
        if (typeof action.payload.callback === "function") {
            if (data) {
                
                const { error, data } = yield getEmployees({ isActive: 1 });
                if (error) {
                    yield put(rejectedGetEmployees(error.violations as any));
                }
                yield put(resolvedGetEmployees(data));
            }
            action.payload.callback(data);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* getEmployeeManagersSaga(action: HandleGetEmployeeManagers) {
    yield put(switchGlobalLoader(true));
    yield put(pendingViewEmployee());
    try {
        const { dataFilter } = yield getEmployeeManagers();
        if (typeof action.payload.callback === 'function') {
            action.payload.callback(dataFilter);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        action.payload.callback(null);
        yield put(switchGlobalLoader(false));
        return;
    }
   
}

/***Get sales and Finance Employee ***/
function* getEmployeesRoleSaleFinanceSaga(action: HandleGetEmployeeSaleFinance) {
    yield put(switchGlobalLoader(true));

    const { isActive, role,callback } = action.payload;
    try {
           const {data } = yield getEmployeesSaleFinance({ isActive, role });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

export function* getEmployeesRoleFinanceSaga(actions: HandleGetEmployeeFinance) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetEmployeesFinance());
    const {
        isActive, role, departmentId
    } = actions.payload;

    const { error, data } = yield getEmployeesSaleFinance({
        isActive, role, departmentId
    });

    if (error) {
        yield put(rejectedGetEmployeesFinance(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedGetEmployeesFinance(data));
    yield put(switchGlobalLoader(false));
    //history.push("/success");
}

function* saveEmployeeWeeklyHoursSaga(action: HandleSaveEmployeeWeeklyHours) {

    yield put(switchGlobalLoader(true));
    try {
        const { id,weeklyHours } = action.payload;
        const { data } = yield createEmployeeWeeklyHours(id,weeklyHours);
        if (typeof action.payload.callback === "function") {

            action.payload.callback(data);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

/* Monthly calendar */
function* getEmployeeMonthlyCalendarSaga(action: HandleEmployeeGetMonthCal) {
    yield put(switchGlobalLoader(true));
    try {
        let delApi = null;
        let returnData = {
            finance: null,
            delivery: null
        }
        if (action.payload.data && action.payload.data.role2) {
            delApi = action.payload.data.role2;
            delete action.payload.data.role2;
        }
        const { data, error } = yield getEmployeeMonthlyCalendar(action.payload.data);
        returnData.finance = data;
        if (data) {
            if (delApi) {
                action.payload.data.role = delApi;
                const { data, error } = yield getEmployeeMonthlyCalendar(action.payload.data);
                if (data) {
                    returnData.delivery = data;
                    action.payload.callback(returnData);
                }
                if (error) {
                    action.payload.callback(returnData);
                    return;
                }
            } else {
                action.payload.callback(data);
            }
        }
        if (error) {
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    // yield put(switchGlobalLoader(false));
}

function* updateEmployeeTmpAvailSaga(action: HandleEmployeeTmpAvail) {
        yield put(switchGlobalLoader(true));
        try {
            let eventId = action.payload.data?.eventId;
            delete action.payload.data?.eventId;
            const { data, error } = yield updateEmployeeTmpAvail(action.payload.id, action.payload.data, action.payload.method, eventId);
            if (data) {
                action.payload.callback(data);
            }
            if (error) {
                yield put(switchGlobalLoader(false));
                return;
            }
        } catch (error) {
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
}

function* updateEmployeeTimeOffSaga(action: HandleEmployeeTimeOff) {
    yield put(switchGlobalLoader(true));
    try {
        let eventId = action.payload.data?.eventId;
        delete action.payload.data?.eventId;
        const { data, error } = yield updateEmployeeTimeOff(action.payload.id, action.payload.data, action.payload.method, eventId);
        if (data) {
            action.payload.callback(data);
        }
        if (error) {
            action.payload.callback(error);
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* deleteEmployeeMonthlyEventsSaga(action: HandleEmployeeDeleteEvents) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield deleteEmployeeMonthlyEvents(action.payload.id, action.payload.eventId);
        if (data) {
            action.payload.callback(data);
        }
        if (error) {
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

export default function* EmployeesSaga() {
    yield takeLeading(HANDLE_GET_EMPLOYEES, getEmployeesSaga);
    yield takeLeading(
        HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
        getEmployeesForAddGroupAssignEmployeeSaga
    );
    yield takeLeading(
        HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE,
        getEmployeesForAddGroupAssignedEmployeeSaga
    );
    yield takeLeading(HANDLE_VIEW_EMPLOYEE, viewEmployeeSaga);
    yield takeLeading(HANDLE_CREATE_EMPLOYEE, createEmployeeSaga);
    yield takeEvery(HANDLE_DELETE_EMPLOYEE, deleteEmployeeSaga);
    yield takeEvery(HANDLE_UPDATE_EMPLOYEE, updateEmployeeSaga);
    yield takeEvery(HANDLE_EMPLOYEE_ACTIVATE, activateEmployeeSaga);
    yield takeEvery(HANDLE_SEND_INVITE, sendInviteSaga);
    yield takeLeading(HANDLE_GET_EMPLOYEE_TASKS, getEmployeeTasksSaga);
    yield takeLatest(
        HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT,
        getProspectEmployeesSaga
    );
    yield takeLeading(HANDLE_SAVE_EMPLOYEE_PERMISSIONS, saveEmployeePermissionSaga);
    yield takeLeading(HANDLE_GET_EMPLOYEE_MANAGERS, getEmployeeManagersSaga);
    yield takeLeading( HANDLE_GET_EMPLOYEES_SALE_FINANCE, getEmployeesRoleSaleFinanceSaga);
    yield takeLeading( HANDLE_GET_EMPLOYEES_FINANCE, getEmployeesRoleFinanceSaga);
    yield takeLeading( HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS, saveEmployeeWeeklyHoursSaga);
    yield takeLeading( HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS, viewEmployeeWeeklyHoursSaga);
   // yield takeLeading( HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS, saveEmployeeWeeklyHoursSaga);
    yield takeLeading( HANDLE_EMPLOYEE_GET_MONTH_CAL, getEmployeeMonthlyCalendarSaga);
    yield takeLeading( HANDLE_EMPLOYEE_TMP_AVAIL, updateEmployeeTmpAvailSaga);
    yield takeLeading( HANDLE_EMPLOYEE_TIME_OFF, updateEmployeeTimeOffSaga);
    yield takeLeading( HANDLE_EMPLOYEE_DELETE_EVENTS, deleteEmployeeMonthlyEventsSaga);
}


