import React from "react";
import { Route } from "react-router-dom";
import Login from "pages/auth/loginPage/LoginPage";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";
import { AppState } from "store";
import { useNavigate  } from "react-router-dom";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { karlProfileURLKeys } from 'constants/constants';
import { GuidedSetUpPage } from "pages/primarySetupPage/GuidedSetUpPage";
import Dashboard from "pages/dashboard/Dashboard";


const PrivateRoute: React.FC<RouteProps> = ({ component,path, ...options }:any) => {
  const user = useSelector((state: AppState) => state.auth.userToken);
  // const a = useSelector((state: AppState) => state.auth);
    const u = useSelector((state: AppState) => state.userProfile.userProfile);
    const guidedSetup = useSelector((state: AppState) => state.primarySetup.guidedSetup);
    
  // console.log(u, a)
    const navigate = useNavigate ();
    if (u && u.profileType && isKarlProfile(u.profileType)) {
        const pathName = path && path.toString().replace('/', '');
        const isAllowed = karlProfileURLKeys.includes(pathName);
        console.log('guidedSetup', guidedSetup)
        const finalComponent = user ? (isAllowed ? component : (!guidedSetup || !guidedSetup.isCompleted ? GuidedSetUpPage : Dashboard) ) : Login;
        if (!isAllowed && guidedSetup && guidedSetup.isCompleted) {
            navigate('/dashboard', { replace: true });
        }
        return <Route {...options} element={finalComponent} />;
    } else {
        const finalComponent = user ? (!guidedSetup || !guidedSetup.isCompleted ? GuidedSetUpPage : component) : Login;
        return <Route {...options} element={finalComponent} />;
    }
    
};

export default PrivateRoute;
