import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "../../customFontText/CustomFontText";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: () => void;
    stockValFound:any;
    stockSave: () => void;

}

export const StockNumberFoundDialog: FunctionComponent<IProps> = ({
    onDismiss,
    stockValFound,
    stockSave
}) => {
    const [dealInfo, setDealInfo] = useState(null);

    const handleSubmit = () => {
        stockSave();
    };

    useEffect(() => {

        if (stockValFound) {
            stockValFound.sort((a, b) => (a.location.legalName > b.location.legalName) ? 1 : -1);
            let obj = {location:'',dealNo:[]};
            let list = [];
            stockValFound.forEach(function (item, index) {

                if (index > 0 && stockValFound[index - 1].location.legalName === item.location.legalName) {
                    obj.dealNo.push(item.dealNumber);
                } else {
                    if (obj.location) {
                        list.push(obj.location + ' - ' + obj.dealNo.join(', '));
                        obj = { location: '', dealNo: [] };
                    }
                    obj.location = item.location.legalName;
                    obj.dealNo.push(item.dealNumber);
                }

                if (stockValFound.length - 1 === index) {
                    list.push(obj.location + ' - ' + obj.dealNo.join(', '));
                    obj = { location: '', dealNo: [] };
                }

            });
            setDealInfo(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog dealFoundDiaHeight"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Stock No. Found</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "25px",marginTop: "-25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text">This Stock Number has been used at this location.</label>
                    </div>
                    <div>
                        <label className="lbl-text">
                            {dealInfo && dealInfo.join(', ')}
                        </label>
                    </div>
                </div>
            </div>
            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center",marginTop:"-15px" }}>
                    <PrimaryButton
                        text="OK"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </Modal>
    );
};



