import * as actions from "store/subscriptions/subscriptions.types";
import { ISubscription } from "types/subscriptionTypes";

interface IState {
  loading: boolean;
  subscriptions: ISubscription | null;
  session: string;
  error: any;

  gettingCarts: boolean;
  removingCart: boolean;
  addingCart: boolean;

  creditCarts: any[]

}

const initialState: IState = {
  subscriptions: null,
  loading: false,
  error: null,
  session: "",
  gettingCarts: false,
  removingCart: false,
  addingCart: false,

  creditCarts: []
};

export const SubscriptionsReducer = (
  state: IState = initialState,
  action: actions.SubscriptionsActions
) => {
  switch (action.type) {
    case actions.GET_SUBSCRIPTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SUBSCRIPTIONS_RESOLVED:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
      };
    case actions.GET_SUBSCRIPTIONS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.PAYMENT_PENDING:
      return {
        ...state,
        loading: true
      };
    case actions.PAYMENT_RESOLVED:
      return {
        ...state,
        loading: false,
        session: action.payload
      };
    case actions.PAYMENT_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case actions.CANCEL_SUBSCRIPTION_PENDING:
      return{
        ...state,
        loading: true
      };
    case actions.CANCEL_SUBSCRIPTION_RESOLVED:
      return{
        ...state,
        loading: false
      };
    case actions.CANCEL_SUBSCRIPTION_REJECTED:
      return{
        ...state,
        loading: false,
        error: action.payload
      };


    case actions.GET_CREDIT_CARTS_PENDING: {
      return {
        ...state,
        gettingCarts: true
      }
    }
    case actions.GET_CREDIT_CARTS_RESOLVED: {
      return {
        ...state,
        gettingCarts: false,
        creditCarts: action.payload,
      }
    }
    case actions.GET_CREDIT_CARTS_REJECTED: {
      return {
        ...state,
        gettingCarts: false
      }
    }
    case actions.ADD_CREDIT_CART_PENDING: {
      return {
        ...state,
        addingCart: true
      }
    }
    case actions.ADD_CREDIT_CART_RESOLVED: {
      return {
        ...state,
        addingCart: false,
        creditCarts: [...state.creditCarts, action.payload]
      }
    }
    case actions.ADD_CREDIT_CART_REJECTED: {
      return {
        ...state,
        addingCart: false
      }
    }
    case actions.REMOVE_CREDIT_CART_PENDING: {
      return {
        ...state,
        removingCart: true
      }
    }
    case actions.REMOVE_CREDIT_CART_RESOLVED: {
      return {
        ...state,
        removingCart: false,
        creditCarts: state.creditCarts.filter(cart => cart.stripeId !== action.payload)
      }
    }
    case actions.REMOVE_CREDIT_CART_REJECTED: {
      return {
        ...state,
        removingCart: false
      }
    }
    default:
      return state;
  }
};
