import React, { useEffect,useState} from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    //DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
   // Dropdown
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
import { handleGetLocations } from "store/locations/locations.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
/// import { handleSoldVehicleTransfer} from "store/dealTracker/dealTracker.action";



interface IProps {

    isOpen: boolean;
    onDismiss: (res) => void;
    onsave: (val) => void;
    locationID?:any;
    vehicleTransferID?:any;
    vehId?:any;
}

//const stackTokens = { childrenGap: 7 };

export const AddDealRecordDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    locationID,
    onsave,
    vehicleTransferID,
    vehId
  
}) => {
    
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [createDealError, setCreateDealError] = useState("");
    const dispatch = useDispatch();
    const locations = useSelector((state: AppState) => state.locations.locations) as any;

    const formik = useFormik({
        initialValues: {
            location: locationID,
            dealNumber: "",
        },
        validationSchema: Yup.object({
            location: Yup.string(),
            dealNumber: Yup.string().required("Required")
        }),
        onSubmit: (values) => {

            onsave(values.dealNumber);

            //   const formData ={
            //       dealNumber:values.dealNumber
            //   }
            //     dispatch<any>(handleSoldVehicleTransfer({
            //         id:vehId,
            //         vehTransferId:vehicleTransferID,
            //         formData,
            //         callback: (response) => {
            //         
            //              if(response.id) {
            //                   onsave()
            //              } else {
            // 
            //                   setCreateDealError(`Deal number ${values.dealNumber} is not found for this location`)
            //              }
            // 
            //         }
            //     }));
        },
    });



    useEffect(() => {

        if (locations && locations.results) {
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }

    }, [locations]);

    

    useEffect(() => {

        dispatch<any>(handleGetLocations())
        setCreateDealError('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 


    const handleUpdateDeal = () => {
         formik.handleSubmit()
    };

    const onLocationChange = (value) => {

    }
    return (
        
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord dealRecordHeights updateEmployeeDialogHeight edit-cust-dialog"
        >


            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText className="formTitle">Add Deal Record</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body+" deal-popup-body"} >
                
                
                <Stack>
                    {
                        createDealError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>{createDealError}</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                        <Row>
                             
                             <Col className="businessInfoInputDropDownHide editCusDealLocatioWidth locOptionTextWrab" >
                                <label className="lbl-text ">Location</label>
                                  <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={formik.values.location}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                    editable= {false}
                                    maxLength={1}
                                   style={{height:"auto"}}
                                />
                                
                             </Col>
                         </Row>
                         <Row>
                             <Col>
                                 <TextField
                                     id="dealNumber"
                                     name="dealNumber"
                                     label="Deal Number"
                                     className="employeeeSearchLabelText"
                                     value={formik.values.dealNumber}
                                     onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                     errorMessage={
                                         formik.touched.dealNumber &&
                                         formik.errors.dealNumber &&
                                         formik.errors.dealNumber.toString()
                                     }
                                 />                          
                             </Col>
                        </Row>
                   

                      
                </Stack>
            </div>
            <div className={contentStyles.footer}>
                <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">
                    
                    <PrimaryButton
                        disabled={!formik.dirty || !formik.isValid}
                        //onRenderIcon={renderSpinner}
                        text="Search"
                        onClick={handleUpdateDeal}
                        //disabled={((!formik.isValid ))}
                    />
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 400,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
