import React from 'react';
//import { useNavigate  } from "react-router-dom";

import {
  Stack,
  IStackStyles,
  IStackTokens,
  IStackItemStyles
} from "@fluentui/react";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import { Depths } from "@uifabric/fluent-theme";
import "./policyPgStyles.css";

import { DefaultPalette } from "@fluentui/react/lib/Styling";
//const navigate = useNavigate ();

/*
const styles: IStackStyles = {
    root: {
        background: DefaultPalette.green,
        padding: 50,
        zIndex: 1,
    },
};
*/

const stackStyles: IStackStyles = {
    root: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        width: '80%',
        marginLeft: "10%",
        marginRight: "10%",
        zIndex: 1
    }
};

const stackItemStyles: IStackItemStyles = {
    root: {
        /*background: DefaultPalette.themePrimary,*/
        width: '96%',
        float: 'left',
        paddingLeft: "2%",
        paddingRight: "2%",
    },
};

// Tokens definition
const containerStackTokens: IStackTokens = { childrenGap: 5 };

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

const CookiesPolicy = () => {
    return (
	  <div><AuthLayoutPage>
           <p>&nbsp;</p>
        </AuthLayoutPage>
            <div className={'ms-Grid'}>
                <div className={'ms-Grid-row'}>
                    <div className={'ms-Grid-col ms-sm12 ms-md12 ms-lg12'}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
            <div className={'ms-Grid'}>
                <div className={'ms-Grid-row'}>
                    <div className={'ms-Grid-col ms-sm12 ms-md12 ms-lg12'}>

                        <Stack tokens={containerStackTokens}>
                            <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                                <Stack.Item align="center" styles={stackItemStyles}>
                                    <div className={"policyPgDraftLyr"}>
                                    <h1 className={"hdrH1"}>FiQore Cookie Policy</h1>
                                    <p className={"hdrDt"}>Last modified: March 2021</p>

                                    <p>This Cookie Policy explains how ProSource Finance, LLC dba FiQore, FiQore Technology, FiQore Technologies, FiQore Finance and/or FiQore Agency uses cookies and similar technologies to recognize you when you visit our websites (<a href="https://www.fiqore.com/" target="_blank" rel="noopener noreferrer">fiqore.com</a>, <a href="https://www.fiqore.com/" target="_blank" rel="noopener noreferrer">qoredms.com</a>, <a href="https://www.fiqore.com/" target="_blank" rel="noopener noreferrer">prosourcefinance.com</a>). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

                                    <h2>About Cookies</h2>
                                    <p>A cookie is a small piece of data that a website - when visited by you the user - asks your browser to store on your device in order to remember information about you. Cookies provide a convenience feature to save you time, or tell the web server that you have returned to a specific page.</p>

                                    <p>Cookies set by us are &ldquo;first party cookies&rdquo;. Cookies set by others are &ldquo;third party cookies&rdquo; which enable features or functionality to be provided on or through the website (e.g. interactive content and analytics). The parties that set these third party cookies can recognize your device both when it visits the website in question and also when it visits certain other websites.</p>

                                    <h2>Use of Cookies</h2>
                                    <p>When you visit our websites, we may store cookies on your browser to collect information. The information collected might be related to you, your preferences or your device, and is mostly used to make the site work as you expect it to and to provide a more personalized web experience. However, you can choose not to allow certain types of cookies, which may impact your experience of the sites and the services we are able to offer.</p>

                                    <h3 className={"hdrH3"}>Strictly Necessary Cookies</h3>
                                    <p className={"paraH3"}>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your preferences, logging in or filling out forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not function as intended.</p>

                                    <h3 className={"hdrH3"}>Functional Cookies</h3>
                                    <p className={"paraH3"}>These cookies may enable our websites to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.</p>

                                    <h3 className={"hdrH3"}>Performance Cookies</h3>
                                    <p className={"paraH3"}>These cookies may allow us to count visits and traffic sources so we can measure and improve the performance of our sites. They help us to know which pages are the most and least popular and see how visitors move around the sites. If you do not allow these cookies, your activity will not be used to monitor site performance.</p>

                                    <h3 className={"hdrH3"}>Targeting Cookies</h3>
                                    <p className={"paraH3"}>These cookies may be set through our sites by partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will have a less targeted experience.</p>


                                    <h3 className={"hdrH3"}>Social Networking Cookies</h3>
                                    <p className={"paraH3"}>These cookies may be used to enable you to share pages and content on our websites through third party social networking and other websites. They may also be used for advertising purposes.</p>


                                    <h2>Controlling Cookies</h2>
                                    <p>You have the right to decide whether to accept or reject cookies.</p>
                                    <p>You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our websites though your access to some functionality and areas of our website may be restricted. Visit your browser's help menu for more information.</p>

                                    <h2>Other Tracking Technologies</h2>
                                    <p>Pixel tags, web beacons, clear GIFs and other similar technologies are typically small pieces of data that are embedded in images on websites, apps or certain email communications. They may involve the transmission of information directly to us, to another party on our behalf or to another party in accordance with its privacy policy. We may use these technologies to bring together information we collect about you.</p>
                                    <p>Certain applications on mobile devices may transmit information to us about your location. We may use that information to improve the products and services we provide to you as well as improving our services generally in your area.</p>

                                    <h2>Further Information</h2>
                                    <p>If you have any questions or concerns regarding this Privacy Policy, please feel free to contact us:</p>

                                    <p>
                                        <strong>Email:</strong> <a href="mailto:compliance@fiqore.com" rel="noopener noreferrer" target="_blank">compliance@fiqore.com</a><br />
                                        <strong>Telephone Number:</strong> 515.498.4500<br />
                                        <strong>Mailing Address:</strong><br />
                                        FiQore<br />
                                        Attention: Compliance<br />
                                        2540 106th Street, Ste 101<br />
                                        Urbandale, Iowa 50322
                                    </p>
                                    </div>
                                </Stack.Item>

                            </Stack>
                        </Stack>
                    </div>
                </div>
            </div>
            <div className={'ms-Grid'}>
                <div className={'ms-Grid-row'}>
                    <div className={'ms-Grid-col ms-sm12 ms-md12 ms-lg12'}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>     
   
    )
}

export default CookiesPolicy;
