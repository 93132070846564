import { call, put, select, takeEvery, takeLeading } from "redux-saga/effects";
import {
  getEmployeesForAddGroupAssignEmployeeSaga,
  getEmployeesForAddGroupAssignedEmployeeSaga,
} from "store/employees/employees.saga";
import {
  pendingAddEmployeeToGroup,
  pendingCreateGroup,
  pendingGetGroups,
  pendingRemoveEmployeeFromGroup,
  pendingViewGroup,
  rejectedAddEmployeeToGroup,
  rejectedGetGroups,
  resolvedAddEmployeeToGroup,
  resolvedCreateGroup,
  resolvedGetGroups,
  resolvedRemoveEmployeeFromGroup,
  resolvedViewGroup,
  pendingUpdateGroup,
  resolvedUpdateGroup,
  pendingDeleteGroup,
  resolvedDeleteGroup,
  rejectedDeleteGroup,
  // rejectedUpdateGroup,
  handleGetGroups
} from "store/groups/groups.action";
import {
  addEmployeeToGroup,
  createGroup,
  getGroups,
  removeEmployeeFromGroup,
  viewGroup,
  updateGroup,
  deleteGroupApi,
} from "store/groups/groups.api";

// import {} from 'store/groupsPermissions/groupsPermissions.types';
import { HandleGetGroupsPermissions,HANDLE_GET_GROUPS_PERMISSIONS } from "store/groupsPermissions/groupsPermissions.types";
import { handleGetGroupsPermissions } from "store/groupsPermissions/groupsPermissions.action";
import { IPagination } from "types/paginationTypes";

import {
  HANDLE_ADD_EMPLOYEE_TO_GROUP,
  HANDLE_CREATE_GROUP,
  HANDLE_GET_GROUPS,
  HANDLE_REMOVE_EMPLOYEE_FROM_GROUP,
  HANDLE_VIEW_GROUP,
  HANDLE_UPDATE_GROUP,
  CREATE_GROUP_AND_ASSIGN_EMPLOYEES,
  HandleAddEmployeeToGroup,
  HandleUpdateGroup,
  HandleCreateGroup,
  HandleRemoveEmployeeFromGroup,
  HandleViewGroup,
  CreateGroupAndAssignEmployees,
  HANDLE_DELETE_GROUP,
  HandleDeleteGroup,
} from "./groups.types";

import {
  HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
  HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE,
  HandleGetEmployeesForAddGroupAssignEmployee,
  HandleGetEmployeesForAddGroupAssignedEmployee,
} from "store/employees/employees.types";
import { AppState } from "store";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

export function* getGroupsSaga() {
  
  
  yield put(switchGlobalLoader(true));
  yield put(pendingGetGroups());

  try {
    const { data } = yield getGroups();
    yield put(resolvedGetGroups(data.results));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetGroups(error));
    yield put(switchGlobalLoader(false));
    return;
  }

  //history.push("/success");
}

export function* createGroupSaga(action: HandleCreateGroup) {
  yield put(pendingCreateGroup());

  

  try {
    const { data } = yield createGroup(action.payload.name);
    yield put(resolvedCreateGroup(data));

    const itemsPerPage = yield select(
      (state: AppState) => state.groupsPermissions.rowsPerPage
    );

    const pagination: IPagination = {
      sort: "DESC",
      page: 1,
      limit: itemsPerPage,
    };
    yield put(handleGetGroupsPermissions(pagination));
    if (typeof action.payload.callback === "function") {
      yield call(action.payload.callback());
    }
  } catch (error) {
    //yield put(rejectedCreateGroup(error));
    return;
  }
}

export function* viewGroupSaga(action: HandleViewGroup) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewGroup());

  try {
    const { data } = yield viewGroup(action.payload);

    yield put(resolvedViewGroup(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(switchGlobalLoader(false));
    return;
  }
}

export function* addEmployeeToGroupSaga(action: HandleAddEmployeeToGroup) {
  console.log("SAGA GROUS", action.payload);

  

  try {
    yield put(pendingAddEmployeeToGroup());

    for (const employeeId of action.payload.employeeIds) {
      const { error } = yield addEmployeeToGroup(
        employeeId,
        action.payload.groupId
      );

      if (error) {
        yield put(rejectedAddEmployeeToGroup(error));
        return;
      }
    }

    const actions: HandleViewGroup = {
      payload: action.payload.groupId,
      type: HANDLE_VIEW_GROUP,
    };

    yield call(viewGroupSaga, actions);

    const limit = yield select(
      (state: AppState) => state.employees.rowsPerPageForAddGroupAssignEmployee
      // (state: AppState) => state.groups.rowsPerPageAddGroupEmployees
    );
    const currPage = yield select(
      (state: AppState) =>
        state.employees.employeesForAddGroupAssignEmployee.currentPage
    );

    const actionsEmployees: HandleGetEmployeesForAddGroupAssignEmployee = {
      payload: {
        limit: limit,
        page: currPage,
        withoutGroup: action.payload.groupId,
      },
      type: HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
      // withoutGroup: action.payload.groupId
    };

    yield call(getEmployeesForAddGroupAssignEmployeeSaga, actionsEmployees);
    yield put(resolvedAddEmployeeToGroup());

    if (typeof action.payload.callback === "function") {
      action.payload.callback();
    }
  } catch (e) {
    console.log("ERROR");
    console.log(e);
  }
}

export function* removeEmployeeFromGroupSaga(
  action: HandleRemoveEmployeeFromGroup
) {
  yield put(pendingRemoveEmployeeFromGroup());

  for (const employeeId of action.payload.employeeIds) {
    try {
      yield removeEmployeeFromGroup(employeeId);
    } catch (error) {
      return;
    }
  }

  const actions: HandleViewGroup = {
    payload: action.payload.groupId,
    type: HANDLE_VIEW_GROUP,
  };

  yield call(viewGroupSaga, actions);

  const limit = yield select(
    (state: AppState) => state.employees.rowsPerPageForAddGroupAssignedEmployee
    // (state: AppState) => state.groups.rowsPerPageAddGroupEmployees
  );
  const currPage = yield select(
    (state: AppState) =>
      state.employees.employeesForAddGroupAssignedEmployee.currentPage
  );

  const actionsEmployees: HandleGetEmployeesForAddGroupAssignedEmployee = {
    payload: {
      limit: limit,
      page: currPage,
      withGroup: action.payload.groupId,
    },
    type: HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE,
    // withoutGroup: action.payload.groupId
  };

  yield call(getEmployeesForAddGroupAssignedEmployeeSaga, actionsEmployees);
  yield put(resolvedRemoveEmployeeFromGroup());

  if (typeof action.payload.callback === "function") {
    console.log(action);
    yield action.payload.callback();
  }
}

function* updateGroupSaga(action: HandleUpdateGroup) {
  yield put(pendingUpdateGroup());

  console.log('update group payload',action.payload);
  
  try {
    const { data } = yield updateGroup(action.payload.id, action.payload.name);
    //
    // console.log(data);
    // 

    if (data && action.payload.extra.toAdd.length > 0 ) {
      for (const employee of action.payload.extra.toAdd) {
        const { error } = yield addEmployeeToGroup(employee.id, action.payload.id);

        if (error) {
          return;
        }
      }
    }
    if (data && action.payload.extra.toRemove.length > 0 ) {
      
      for (const employee of action.payload.extra.toRemove) {
        const { error } = yield removeEmployeeFromGroup(employee.id);

        if (error) {
          
          return;
        }
      }
    }

    if(typeof action.payload.extra.callback === 'function'){
      action.payload.extra.callback();
    }

    yield put(resolvedUpdateGroup(data));
  } catch (error) {
    //yield put(rejectedUpdateGroup(error))
    return;
  }
}

function* createGroupAndAssignSaga(action: CreateGroupAndAssignEmployees) {
  yield put(pendingUpdateGroup());

  const { data, error } = yield createGroup(action.payload.name);

  if (error) {
    return;
  }

  if (data && action.payload.selectedEmployees ) {
    const { id } = data;

    for (const employee of action.payload.selectedEmployees) {
      const { error } = yield addEmployeeToGroup(employee.id, id);

      

      if (error) {
        return;
      }
    }
  }

  yield put(resolvedUpdateGroup(data));
  // yield put(resolvedCreateGroup(data));

  yield put(handleGetGroups())

  const actions: HandleGetGroupsPermissions = {
    type: HANDLE_GET_GROUPS_PERMISSIONS,
    payload: {page: 1}
  };
  // yield call(addEmployeeToGroupSaga, action);
  yield put(handleGetGroupsPermissions(actions.payload))

    action.payload.callback();

  // yield put(switchGlobalLoader(true));
  // yield put(pendingGetGroups());
  // try {
  //   const { data } = yield getGroups();
  //   yield put(resolvedGetGroups(data.results));
  //   yield put(switchGlobalLoader(false));
  // } catch (error) {
  //   yield put(rejectedGetGroups(error));
  //   yield put(switchGlobalLoader(false));
  //   return;
  // }



}

function* deleteGroupSaga(action: HandleDeleteGroup) {
  yield put(pendingDeleteGroup());

  const { error } = yield deleteGroupApi(action.payload.id);

  if (error) {
    yield put(rejectedDeleteGroup(error));
    return;
  }

  action.payload.callback();
  yield put(resolvedDeleteGroup());
}

export default function* GroupsSaga() {
  yield takeLeading(HANDLE_GET_GROUPS, getGroupsSaga);
  yield takeLeading(HANDLE_UPDATE_GROUP, updateGroupSaga);
  yield takeEvery(HANDLE_ADD_EMPLOYEE_TO_GROUP, addEmployeeToGroupSaga);
  yield takeLeading(HANDLE_CREATE_GROUP, createGroupSaga);
  yield takeLeading(HANDLE_VIEW_GROUP, viewGroupSaga);
  yield takeEvery(
    HANDLE_REMOVE_EMPLOYEE_FROM_GROUP,
    removeEmployeeFromGroupSaga
  );

  yield takeEvery(
    CREATE_GROUP_AND_ASSIGN_EMPLOYEES,
    createGroupAndAssignSaga
  );
  yield takeLeading(HANDLE_DELETE_GROUP, deleteGroupSaga);
}
