import React from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {dropdownStyles} from 'constants/styles'
import inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const bodyStyleValues = inventoryDropdownsValues.bodyStyleValues.map((item) => {
  return { key: item, text: item };
});
const exteriorColorValues = inventoryDropdownsValues.exteriorColorValues.map((item) => {
  return { key: item, text: item };
});

const interiorColorValues = inventoryDropdownsValues.interiorColorValues.map((item) => {
  return { key: item, text: item };
});
const doorsValue = inventoryDropdownsValues.doorValues.map((item) => {
  return { key: item, text: item };
});

interface IProps {
  inventory: any;
  setInventory: (inventory) => void;
}
const BodyAndColor: React.FC<IProps> = ({ inventory, setInventory }) => {
  const handleChange = (e, item) => {
    const value = item.text;

    setInventory((i) => {
      return { ...i, [e.target.id]: value };
    });
  };

  return (
    <div>
      <Dropdown
        id="body"
        label="Body Type"
        selectedKey={inventory.body}
        onChange={handleChange}
        placeholder="any..."
        options={bodyStyleValues}
        styles={dropdownStyles}
      />
      <Dropdown
        id="doors"
        label="Doors"
        selectedKey={inventory.doors}
        onChange={handleChange}
        placeholder=""
        options={doorsValue}
        styles={dropdownStyles}
      />
      <Dropdown
        id="exteriorColor"
        label="Exterior Color"
        selectedKey={inventory.exteriorColor}
        onChange={handleChange}
        placeholder="any..."
        options={exteriorColorValues}
        styles={dropdownStyles}
      />
      <Dropdown
        id="interiorColor"
        label="Interior Color"
        selectedKey={inventory.interiorColor}
        onChange={handleChange}
        placeholder=""
        options={interiorColorValues}
        styles={dropdownStyles}
      />
    </div>
  );
};

export default BodyAndColor;
