import {
    Checkbox,
    IconButton,
    Modal,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import {
    cancelIcon,
    contentStyles as content,
    iconButtonStyles,
} from "constants/styles";
import { useFormik } from "formik";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Col, Row } from "react-grid-system";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { useNavigate  } from "react-router-dom";
import { AppState } from "store";
import {
    handleCheckVehicleStock,
    handleCreateVehicleStockItem, handleVehicleTrackerCheckStock
} from "store/dealTracker/dealTracker.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import * as Yup from "yup";
import { CustomFontText } from "../../customFontText/CustomFontText";

const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    vehicleId?: any;
    stockNumber?: any;
    stockData?: (res) => void;
    stockConflictData?: (res) => void;
    stockFoundDealData?: (res) => void;
    vtStockFoundDealData?: (res) => void;
    id?: any;
    existingData?: any
}

export const AddInventoryFoundDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    vehicleId,
    stockNumber,
    stockData,
    stockConflictData,
    stockFoundDealData,
    id,
    existingData,
    vtStockFoundDealData
}) => {
    const dispatch = useDispatch();
    const userProfile: any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);
    const navigate = useNavigate ();
    const formik = useFormik({
        initialValues: { stockNumber: "" },
        validationSchema: Yup.object({
            stockNumber: Yup.string()
        }),
        onSubmit: (values) => {
            let data = { stockNumber: isNewStockChecked ? values.stockNumber : stockNumber };
            let formData = { vehicleId: inventoryInfo?.id, stockNumber: isNewStockChecked ? values.stockNumber : stockNumber }

            if (isKarlProfileType) {
                let val = { stockNumber: isNewStockChecked ? values.stockNumber : stockNumber };
                dispatch<any>(handleVehicleTrackerCheckStock({
                    id,
                    formData: val,
                    callback: (res) => {
                        if (res) {
                            if (res.conflicts) {
                                vtStockFoundDealData(res)
                            }

                            dispatch<any>(handleCheckVehicleStock({
                                id,
                                data,
                                callback: (res) => {
                                    if (res.id) {
                                        dispatch<any>(handleCreateVehicleStockItem({
                                            id,
                                            formData,
                                            callback: (res) => {
                                                if (res) {
                                                    onDismiss('');
                                                }
                                            }
                                        }))
                                    } else if (res) {
                                        stockFoundDealData(res.conflicts)
                                        stockConflictData(formData)
                                        onDismiss('');
                                    } else {
                                        onDismiss('Failed to Save Data.');
                                    }
                                }
                            }))

                        } else {
                            onDismiss('Failed to Save Data.');
                        }
                    }
                }))
            } else {

                if (!isKarlProfileType) {
                    navigate(`/${vehicleId}/shop-inventory`);
                    onDismiss('');
                }
            }



        },
    });

    const handleVehicleDetails = () => {
        formik.handleSubmit();
    };



    const inventoryInfo = useSelector((state: AppState) => state.inventory.shopVehicleList);

    const shopVehicleList = useSelector((state: AppState) => state.inventory.shopVehicleList);

    const [isNewStockChecked, setIsNewStockChecked] = React.useState(false);
    const handleOrderChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsNewStockChecked(checked);
        },
        [],
    );

   return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            className="vehicleFoundDialog"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Existing Vehicle Found</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body} style={{ paddingLeft: "20px" }}>
                <div style={{ marginLeft: "25px" }}>
                    <Row>
                        <Col>
                            {
                                existingData && <label className="lbl-text">{existingData?.dealNumber + " - " + existingData?.location?.legalName}</label>
                            }
                            <label className="lbl-text">{shopVehicleList?.year + " " + shopVehicleList?.make + " " + shopVehicleList?.model}</label>
                            <label className="lbl-text">{shopVehicleList?.style}</label>
                        </Col>
                    </Row>
                    {userProfile.profileType && isKarlProfile(userProfile.profileType) ? <Stack

                        verticalAlign="start"
                        horizontalAlign="space-between"
                    >
                        <div style={{ marginTop: "20px" }}>
                            <Row className="inventoryRowSection rowPaddingBtwSpace">
                                <Col sm={10} md={6} xs={12} >
                                    <Checkbox className="padding-0" onChange={handleOrderChange} label="New Stock Number" />
                                </Col>
                                {isNewStockChecked === true ? <Col sm={12} md={6} xs={12} >
                                    <TextField
                                        id="stockNumber"
                                        name="stockNumber"
                                        label="Stock No"
                                        className=" order-no-field-stock contentFieldSpace"
                                        required={true}
                                        value={formik.values.stockNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.stockNumber &&
                                            formik.errors.stockNumber &&
                                            formik.errors.stockNumber.toString()
                                        }
                                    /> </Col>
                                    : null
                                }
                            </Row>

                            <Row className="inventoryRowSection" >
                                <Col className="inventoryColDivider pad-right-5" sm={5} md={5} xs={6}>
                                    <div>   <Checkbox className="padding-0" label="Stock Number " /> </div>
                                </Col>
                                <Col className="inventoryColDivider pad-left-5" sm={5} md={5} xs={6}>
                                    <div> <strong >{stockNumber ?stockNumber:""}</strong></div>
                                </Col>
                            </Row>
                        </div> </Stack> : <div>
                        <p>This vehicle already exists in your records.</p>
                        <p>You can review your notes, update values, and move the vehicle between archived & active workbooks.</p>
                    </div>
                    }
                </div>
            </div>

            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center" }}>
                    <PrimaryButton
                        text={userProfile.profileType && isKarlProfile(userProfile.profileType) ? "Save" : "View Vehicle"}
                        onClick={handleVehicleDetails}
                    />
                </div>
            </div>
        </Modal>
    );
};



