import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import AddCircle from '@mui/icons-material/AddCircle';

import {
    IStackStyles,
    //TextField,
    Icon,
    Link,
    TextField as OffTextField,
    PrimaryButton,
    Stack
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
//import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { EditActionNoSubMenu } from "components/table/dropdowns/EditActionNoSubMenu";
//import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import {
    handleCreateVehicleTransferList,
    handleCreateVehicleTransferNote, handleGetVehicleTransferNote,
    handleUpdateVehicleTransferList,
    handleVehicleTrackerWriteUpCheck,
    handleViewVehicleTracker,
    handleViewVehicleTrackerTransfer, handleViewVehicleTransferList
} from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { SaveSuccessDialog } from "components/dialogs/addInventoryDialog/SaveSuccessDialog";
import { DealPageSaveNotification } from "components/dialogs/dealTrackerDialog/DealPageSaveNotification";
import { AddDealRecordDialog } from 'components/dialogs/dealTrackerDialog/vehicleTrackerDialog/AddDealRecordDialog';
import { TransferWarningDialog } from 'components/dialogs/dealTrackerDialog/vehicleTrackerDialog/TransferWarningDialog';
import { themeStyles } from "constants/constants";
import { useFormik } from "formik";
import * as _ from "lodash";
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { useMediaQuery } from "react-responsive";
import { useNavigate , useLocation, useParams } from "react-router-dom";
import {
    HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import {
    convertLocalToUTCModified,
    convertUTCToLocalDateOnlyModified,
} from "utils/dateTimeFunctions";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import * as Yup from "yup";
import masterDropdownValues from "../../constants/masterDropdownValues";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

// const useStyles = makeStyles((theme: ThemeStyle) =>

//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme?.palette?.background.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography?.pxToRem(15),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography?.pxToRem(15),
//             color: theme.palette?.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 25,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette?.divider}`,
//             padding: theme?.spacing?.(1, 2),
//         },
//         link: {
//             color: theme.palette?.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//         breadCrumblink: {
//             display: 'flex',
//         },
//         instructions: {
//             marginTop: theme?.spacing?.(1),
//             marginBottom: theme?.spacing?.(1),
//         },
//         breadCrumbicon: {
//             marginRight: theme?.spacing?.(0.5),
//             width: 20,
//             height: 20,
//         },
//         startTime: {
//         }
//     }),
// );

interface ParamTypes {
    vehId: string;
}
const credentialModuleName = "DealTracker";
export const VehicleTrackerPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    //const  classes:any  = useStyles();
    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([0]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [credentials, setCredentials] = useState([]);
    const todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('sentDate');
    const [totalRecords, setTotalRecords] = useState(null);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [note, setNote] = useState(null);
    const [noteData, setNoteData] = useState(null);
    const [finalData, setFinalData] = useState(null);
    const [employeeList, setEmployeeList] = useState(null);
    const [transferList, setTransferList] = useState(null);
    const [statePageNumber, setStatePageNumber] = useState(1);
    const [transferVehicleUI, setTransferVehicleUI] = useState(false);
    const [isSoldDialog, setIsSoldDialog] = useState(false);
    const [reponseData, setReponseData] = useState(null);
    const [show, setShow] = useState(false);
    //const [currentUser, setCurrentUser] = useState(null);
    const [sentDateFilter, setSentDateFilter] =React.useState<Date  | undefined>(undefined);
    const [wholeSaleComplteDate, setWholeSaleComplteDate] =React.useState<Date  | undefined>(undefined);
    const params = useParams<ParamTypes | any>();
    const [settingPermission, setSettingPermission] = React.useState(null);
    const [isSaveNotification, setisSaveNotification] = useState(false);
    const [isWarningDialog, setIsWarningDialog] = useState(false);
    const today =new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'});
    const boxStatus = ["", "No", "Yes"];
    const [locationFilter, setLocationFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [sentTime, setSentTime] = useState(moment(new Date(), 'HH:mm'));
    const [blurFlag, setBlurFlag] = useState(false);
    const [blurFlagTime, setBlurFlagTime] = useState(false);
    const [isLoaner , setIsLoaner ] = useState(false);
    const [statusOption, setStatusOption] = useState(null);
    const [formData, setFormData] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);
    const [warningData, setWarningData] = useState(null);
    const [saveSuccessDialog, setSaveSuccessDialog] = React.useState(false);
    const [isViewable, setIsViewable] = React.useState(false);
    const [dealNumber, setDealNumber] = React.useState(null);
    const formik = useFormik({
        initialValues: {
            id: "",
            requestedBy: user ? user.id :"",
            transferTo: "",
            sentDate: today, 
            sentTime: moment(new Date()).format('HH:mm'),
            wholeSaleCompleteDate:"",
            status: 1,
            customer: "",
            title: 1,
            secondKey: 1, 
            folder: 1,
            
        },
        validationSchema: Yup.object({
            sentDate: Yup.string().required('Sent Date required.'),
            wholeSaleCompleteDate: Yup.string(),
            sentTime: Yup.string().required('Sent Time required.'),
            requestedBy: Yup.string().required('Requested By required.'),
            transferTo: Yup.string(),
            status: Yup.string().required('Status required.'),
            customer: Yup.string(),
            title: Yup.number(),
            secondKey: Yup.number(),
            folder: Yup.number(),
            id: Yup.string()
        }),
        onSubmit: (values) => {
            
       
        }
    }); 
    

    useEffect(()=>{
        

        if(transferList ){

            if(!transferList?.isActive || settingPermission !== 1){
                setIsViewable(true);
            }else{
                setIsViewable(false)
            }
        }else if(settingPermission === 1){
            setIsViewable(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[settingPermission,transferList])

    useEffect(()=>{
        if(dealNumber){
            saveWriteUoCheck();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dealNumber])


    useEffect(()=>{
        
         let status =[...masterDropdownValues.vehicleTrackerStatus]
        if(!formik.values.transferTo){
            let arr = status.filter(item=>{
                if(item.key === 6 || item.key === 7  ){
                    return item
                }
                return null
            })
            setStatusOption(arr)
        }else{
            setStatusOption(status)
        }
    },[formik.values])


    
    const onStatusChange = (e: { value: any }) => {
        formik.setValues(
            {
                 ...formik.values,
                status: e.value,
            }
        );
        setDealNumber(null)
    }
     
    const onRequestedByChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                requestedBy: e.value,
            }
        );
    }
    
    const onTransferedToChange = (e: { value: any }) => {
        
        if(!e.value){
            setIsLoaner(true)
            formik.setValues(
              {
                  ...formik.values,
                  transferTo: e.value,
                  status:7
              }
            );
        }else{
            formik.setValues(
              {
                  ...formik.values,
                  transferTo: e.value,
              }
            );
        }
        
    }

    const handleSentDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                "sentDate": val? moment(val).format("YYYY-MM-DD"):val

             }
        );
        setSentDateFilter(new Date(convertUTCToLocalDateOnlyModified(val)));
        //onFormikChange(1, 2);
    };
    
    const handleWholeSaleCompleteDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                "wholeSaleCompleteDate": val? moment(val).format("YYYY-MM-DD"):""
        
             }
        );
        setWholeSaleComplteDate(new Date(convertUTCToLocalDateOnlyModified(val)));
        //onFormikChange(1, 2);
    };

    const handleSentTimeChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                'sentTime': value ? value.format('HH:mm') : ''
            }
        );
        setSentTime(moment(value, 'HH:mm'));
    }

    const handleDateBlur = () => {
        setBlurFlag(true);
    }
    const handleTimePanelOpen = () => {
        setBlurFlagTime(true);
    }

    useEffect(() => {
       
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1 } })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       
        if ((user.roles?.some(x => x === "ROLE_MASTER"))||( user.defaultGroups && user.defaultGroups.some(x => x.defaultGroupId === 5 || x.defaultGroupId === 19))){
            setSettingPermission(1);
        }
        else {
            setSettingPermission(2);
        }
        
    }, [user]);

 //  useEffect(()=>{
 //      
 //    if(vehicleDetails?.sentDateTime){
 //        if(vehicleDetails && finalData  && lastData?.status !== 6 ){
 //              setShow(false)
 //        }else if(vehicleDetails &&  finalData && lastData?.status === 6 ){
 //              setShow(true)
 //        }else if( vehicleDetails && finalData === null ){
 //              setShow(true)
 //        }
 //    } else if(newVehData){
 //        if( newVehData && finalData && lastData?.status === 6 ){
 //              setShow(true)
 //        }else if(newVehData && finalData  && lastData?.status !== 6 ){
 //              setShow(false)
 //        }else if( newVehData  && finalData === null ){
 //              setShow(true)
 //        }
 //    }
 //      
 //  },[vehicleDetails,newVehData,finalData,lastData])
    



    useEffect(()=> { 

            
            let list = [...employees.results];
            let employeeLists=[];

             if (employees.results.length > 0) {
             const val = list.filter(item =>{
                 
                if(item.employeeDefaultGroups.some(x => x.defaultGroup.id === 5 || x.defaultGroup.id === 19)){
                    return item
                 
                }
                return null
                
             })
             
             val.map((item: any) => {
                 return employeeLists.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
             });

       
              setEmployeeList(employeeLists)
             }     
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees]);



    function dynamicSort(property) {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }




    useEffect(() => {
        
        if (vehicleData && locations.results) {
            
              
            if(vehicleData.location.id){
                 let  locationList =  locations.results.filter ((item )=>{
                       if(item.id !== vehicleData.location.id){
                           return item
                       }
                       return null 
                 })
                 let val ;
                 if(locationList){
                      
                     val = locationList ? locationList.sort(dynamicSort("legalName")) : '';
                  
                  }
                  val.push({ id: 0 ,legalName: 'Loaner'});
                  
                  setCityDealerList(val);
             }
           
        } else {
            setCityDealerList([]);
        }
   
    }, [vehicleData,locations]);

    const getStatus = (x) => {
        
        if (x.status !== 4) {
           return  masterDropdownValues.vehicleTrackerStatus.find(item => item.key === x.status).text;

        }else {
            if(x?.deal?.dealDelivery?.status){
                let val = masterDropdownValues.dealTrackerStatus.filter(item => item.key === x?.deal?.dealDelivery?.status)[0]?.text;


               return  `Sold - ${val}`
            }else if(x.status === 4){
                return "Sold -"
            }
        }

    }

    const vehicleLocation =(locVal)=>{
        if(locVal?.transferToLocation?.id){
             return locVal?.transferToLocation?.legalName
        }else{
            return "Loaner "
        }
    }

   
   const handlePopulate = (response) => {
          if(response.length){
              for (var i in response) {
                  
                   response[i]["sentDateTime"] = moment(response[i].sentDateTime).local().format('MM/DD/YY hh:mm a');
                   //response[i]["sentDateTime"] = convertUTCToLocalDateOnlyModified(response[i].sentDate);
                   response[i]["locationName"] = vehicleLocation(response[i]);
                   response[i]["statusName"] = response[i].status &&  getStatus(response[i]);
                   response[i]["recentYear"] = response[i].vehicleTracker.vehicle.year;
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["requestedByName"] = response[i].requestedBy ? response[i].requestedBy.firstName + " " + response[i].requestedBy.lastName : '';
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   //response[i]["vehicleTransferID"] = response[i].id ;
                   response[i]["isEdit"] = response[i].isActive ; ;
                
              }
              	 return response;
          } else{
              setFinalData(null);
          }
	};


    
    

    const vehicleTrackerList =(isFilterApplied)=>{   

	        const formData = {
                 page: isFilterApplied ? statePageNumber : pages,
                 limit,
            };
    
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleViewVehicleTrackerTransfer({
              id:params.vehId,
              formData,
              callback:  (response) => {
                  if(response.results){
                   
                      setTotalRecords(response.totalItems);
                      setReponseData(response)
                     
                  }else{
                     setcommonServerError("Fail to fetch the data")
                     setFinalData(null);
                
                  } 
              }
            }));

            dispatch<any>(handleViewVehicleTracker({
              id:params.vehId,
              callback:  (response) => {
                  if(response){
                      setVehicleData(response)
                      if(response?.lastActiveTransfer?.id){
                          setShow(false)
                      }else{
                          setShow(true)
                      }
                  }else{
                     setcommonServerError("Fail to fetch the data")
                  } 
              }
        }));
            
            
	}
    useEffect(()=>{
        if(reponseData){
            const data = handlePopulate(reponseData.results);
            setFinalData(data); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reponseData])


   

    useEffect(() => {
        setSortOrder(1);
        setSortField('sentDate');
        setSentDateFilter(new Date(todaydate) )
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

  

    

   const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	const getDataList = (isFilterApplied = false) => {
        setcommonServerError('');
   
        vehicleTrackerList(isFilterApplied)
        
    }



    useEffect(() => {
     
       if(transferVehicleUI === false){
           getDataList();
       }else{
           if(vehicleData?.lastActiveTransfer){
               handleNavigate(vehicleData?.id,vehicleData.lastActiveTransfer)
           }
           
       }
      
       //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferVehicleUI]);
    



    const handleBoxClick = (e, val, id) => {
        if ((e.target.nodeName !== 'SPAN' && e.target.nodeName !== 'SELECT' && e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'INPUT') && !isViewable ) {

            if (val === 1) {
                val = 2;
            } else {
                val--;
            }
            formik.setValues(
                {
                    ...formik.values,
                    [id]: val,
                }
            );
        } 
    }



    const navigateToTransferVehicleNew=()=>{
        setIsSave(false)
        setTransferVehicleUI(true)
        setTransferList(null);
        formik.setValues(
        {
           ...formik.initialValues
        
        });
        let val = moment(new Date());
        setSentTime(val);
       
    }
    const navigateToTransferVehicleEdit=()=>{
        setIsSave(false)
        
        if(vehicleData?.id &&  vehicleData?.lastActiveTransfer ){
            handleNavigate(vehicleData?.id,vehicleData.lastActiveTransfer)
        }
       
    }
    
   
    const handleDeliveryNoteChange = (e, newValue) => {
        setNote(newValue);
    }

    //Datatable Templates
	const header = (
		<>  

		
		<div>
            <div>
                
               { show ?
		              <Row>
                          <Col md={4}>
			              </Col>
		                  <Col md={4}>
			                   <Row>
			                       <Col>
			                            <div>  
                                           
                                        <CustomFontText className="pageTitleFont pageName pageHeaderFont">Vehicle is Home</CustomFontText>
                                        </div>

                                      
                                        <div >
                                           <Link
                                               //disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                               color="inherit"
                                               style={{fontSize:"19px"}}
                                               onClick={navigateToTransferVehicleNew}>
                                               <AddCircle style={{ ...themeStyles.icon, color: "#70af60", height: "29px" }} className={" iconHeight"} />				      			               &nbsp;Transfer Vehicle 
				      			            </Link>
                                           
                                        </div> 
			                   	</Col>
			                   </Row>
				          </Col>
		                  <Col md={4}>
			              </Col>
		              </Row> 
                      :
                      <Row>
                          <Col md={4}>
			              </Col>
		                  <Col md={4}>
			                   <Row>
			                       <Col>
			                            <div>  
                                              { vehicleData?.lastActiveTransfer?.status === 4 ?
                                                  <CustomFontText className="pageTitleFont pageName pageHeaderFont"> Vehicle is Sold </CustomFontText>:
                                                  <CustomFontText className="pageTitleFont pageName pageHeaderFont">Vehicle Out : {vehicleData?.lastActiveTransfer?.isLoaner ? "Loaner":vehicleData?.lastActiveTransfer?.transferToLocation?.legalName}</CustomFontText>
                                              }
                                             
                                        </div>
                               
                                        <div>
                                           <Link
                                               //disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                               color="inherit"
                                               style={{fontSize:"19px"}}
                                               onClick={navigateToTransferVehicleEdit}>
                                               <AddCircle style={{ ...themeStyles.icon, color: "#70af60", height: "29px" }} className={" iconHeight"} />
				      			               &nbsp; Edit Transfer 
				      			            </Link>
                                         </div>
			                   	</Col>
			                   </Row>
				          </Col>
		                  <Col md={4}>
			              </Col>
		              </Row> }
                
                
                
            </div>
            <div className="display-row total-export-row">
                <div>
                     <strong className="writeUpsFont">Transfer History </strong>
                </div>
            </div>
		</div>

		</>
	);

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );


    const sentDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.date}

            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                {rowData.customerName}

            </React.Fragment>
        );
    };

    const noteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.notes}

            </React.Fragment>
        );
    };

  
    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.sentDateTime}
            </React.Fragment>
        );
    };
    
    

    const locationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.locationName}

            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.statusName}

            </React.Fragment>
        );
    };



    const customerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                {rowData.customerName}

            </React.Fragment>
        );
    };

    const requestedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.requestedByName}

            </React.Fragment>
        );
    };

    

    const commentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.lastNote}

            </React.Fragment>
        );
    };


    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {credentials && user.roles && (
                    <EditActionNoSubMenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        handleEdit={(vehicleId) => handleNavigate(vehicleId,rowData)}
                        isEdit={true}

                    />
                )}
            </React.Fragment>
        );
    };

    
    const handleNavigate = (id,data) => {
          setTransferVehicleUI(true)

          dispatch<any>(handleViewVehicleTransferList({
                       id:params.vehId,
                       vehTransferId:data.id,
                       callback: (response) => { 
                            if(response){
                                
                                setTransferList(response)
                                
                            }else{
                                setcommonServerError("Fail to fetch the data")
                            }
                       }
          }));

    }








    const getNotes =()=>{

      
      if(transferList){
           dispatch<any>(handleGetVehicleTransferNote({
                     id:params.vehId,
                     vehTransferId:transferList.id,
                     callback: (response) => {
                         
                             if(response){
                                 
                                 handlePopulateNotes(response.results)
                                 
                             }else{
                                 setcommonServerError("Fail to fetch the notes")
                             }
                             
                     }
           }));
      }
          
    }

    useEffect(()=>{
        if(transferList){
            getNotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[transferList])

    const handlePopulateNotes = (response) => {



          if(response.length){
              for (var i in response) {
                   response[i]["date"] = moment(response[i].createdAt).local().format('MM/DD/YY hh:mm a');
                   response[i]["customerName"] = response[i].employee ? response[i].employee.firstName + " " + response[i].employee.lastName : '';
                   response[i]["notes"] = (response[i].notes && response[i].notes) ;
  
              }
              	 //return response;
                   setNoteData(response)
          }else{
              setNoteData(null)
          }
          
	      
	};


    const handleRowClick = (event) => {
        
      //  let target = event.originalEvent.target;
      //  if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
      //      target?.lastElementChild?.lastElementChild?.firstElementChild.click();
      //  } else {
      // 
      //      handleNavigate(event.data.id, event.data);
      //  }
      if(event.data ){
          handleNavigate(event.data.id, event.data);
      }
       

    };



    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);
    
    useEffect(() => {
    
       if(transferList){
           
             let localTime = '';
               if (transferList.sentDateTime && Date.parse(transferList.sentDateTime) > 0) {
                   var date = moment.utc(transferList.sentDateTime).format('YYYY-MM-DD HH:mm:ss');
                   var stillUtc = moment.utc(date).toDate();
                   localTime = moment(stillUtc).local().format('HH:mm');
                   setSentTime(moment(stillUtc).local());
               }
            formik.setValues(
               {
                 ...formik.values,
                    sentDate:transferList? moment(transferList.sentDateTime).local().format('MM/DD/YY'): today,
                    wholeSaleCompleteDate:transferList?.wholeSaleCompleteDate ? moment(transferList?.wholeSaleCompleteDate).local().format('MM/DD/YY'): "",
                    sentTime: localTime,
                    requestedBy:transferList? transferList?.requestedBy?.id:user.id,
                    transferTo: transferList?.isLoaner ? 0 : transferList?.transferToLocation?.id,
                    status:transferList? transferList.status:1,
                    customer: transferList? transferList.customer:"",
                    title:transferList?.title? transferList.title:1,
                    secondKey:transferList?.secondKey? transferList?.secondKey:1,
                    folder:transferList?.folder? transferList.folder:1,
                    id:transferList? transferList?.id:"",
               },
               true
              
             );
             setNote(null);
             let data = moment(transferList.sentDateTime).local().format('MM/DD/YY ');
             setSentDateFilter(new Date(data))
             let wholeSaleCompleteDateVal = transferList?.wholeSaleCompleteDate ? moment(transferList?.wholeSaleCompleteDate).local().format('MM/DD/YY '):'';
             setWholeSaleComplteDate(new Date(wholeSaleCompleteDateVal));
       } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferList]);


    const initialValues = {
  
            sentDate:transferList? moment(transferList.sentDateTime).local().format('MM/DD/YY'): today,
            wholeSaleCompleteDate:transferList?.wholeSaleCompleteDate ? moment(transferList?.wholeSaleCompleteDate).local().format('MM/DD/YY'): "",
            requestedBy:transferList? transferList.requestedBy.id: user.id,
            transferTo:transferList ? transferList?.isLoaner? 0 : transferList?.transferToLocation?.id :"",
            status:transferList? transferList.status:1,
            customer: transferList? transferList.customer:"",
            title:transferList?.title? transferList.title:1,
            secondKey:transferList?.secondKey? transferList.secondKey:1,
            folder:transferList?.folder? transferList.folder:1,
            sentTime: transferList? sentTime?.format('HH:mm') : moment(new Date()).format('HH:mm'),
            id:transferList? transferList.id:"",
        }


    const handleBackToUsers = () => {      

      if(transferVehicleUI){
    
            if (_.isEqual(initialValues ,formik.values ) === true  || isSave){
                setTransferVehicleUI(false)
            }else{
               setisSaveNotification(true)
            }
      }else{

        const state = {
            locationFilter ,
            statusFilter,
            formData
         }

          navigate(`/vehicle-tracker-workbook-page`, {state});
          
      }
        
       
    }
    
    const handleSave = () => {
      
        if( formik.values.status === 4){
            setIsSoldDialog(true)
        }else{
            saveWriteUoCheck()
           // formik.handleSubmit()
        }
       
    }

    const saveWriteUoCheck =()=>{
        dispatch<any>(handleVehicleTrackerWriteUpCheck({
                    
                     id:params.vehId,
                     callback: (response) => {
                         if(response.dealNumber){
                             setWarningData(response);
                             setIsWarningDialog(true);
                             
                         } else if(response.id){
                             savetransferList()
                         }
                             
                     }
         }));
    }

    const savetransferList= ()=>{
       let sentDateTime;
       if (formik.values.sentDate && formik.values.sentTime ) {
                sentDateTime = moment(new Date(formik.values.sentDate + ' ' + formik.values.sentTime)).utc().format()//moment(values.appointmentTime, "HH:mm").utc();
                //appointTime = utcStart.format()
       }

       const formData ={
           requestedByEmployeeId:formik.values.requestedBy,
           //transferToLocationId:formik.values.transferTo ? formik.values.transferTo :"",
           sentDate:formik.values.sentDate ? convertLocalToUTCModified(formik.values.sentDate):"",
           sentDateTime:sentDateTime,
           customer:formik.values.customer,
           status:formik.values.status,
           secondKey:formik.values.secondKey,
           folder:formik.values.folder,
           title:formik.values.title,
       }

       if(dealNumber){
           formData["dealNumber"]= dealNumber;
       } 
       
       if(formik.values.wholeSaleCompleteDate){
           formData["wholeSaleCompleteDate"]= formik.values.wholeSaleCompleteDate ? convertLocalToUTCModified(formik.values.wholeSaleCompleteDate):"";
       }


       if(formik.values.transferTo){
           formData['transferToLocationId']= formik.values.transferTo ? formik.values.transferTo :""
       } else{
           formData['isLoaner']=isLoaner
       }
       if(transferList){
                dispatch<any>(handleUpdateVehicleTransferList({
                    id:params.vehId,
                    vehTransferId:transferList.id,
                    formData,
                    callback: (response) => {
                        if(response.id){
                            getDataList();
                            setIsSave(true);
                            setSaveSuccessDialog(true);
                        }else if(response.code){
                            setcommonServerError(`Deal number ${dealNumber} is not found for this location`)
                        }else{
                            setcommonServerError("Fail to Save the data")
                        }
                            
                    }
                }));
       }else{


                  dispatch<any>(handleCreateVehicleTransferList({
                         id:params.vehId,
                         formData,
                         callback: (response) => {
                             
                             if(response.dealNumber){
                                  setWarningData(response);
                                  setIsWarningDialog(true);
                             }else if(response.id){
                                 getDataList();
                                 setTransferList(response)
                                 setIsSave(true);
                                 setSaveSuccessDialog(true);
                              
                              }else if(response.code){
                                  setcommonServerError(`Deal number ${dealNumber} is not found for this location`)
                             }else{
                                  setcommonServerError("Fail to Save the data")
                             }
                                 
                         }
                  }));
               
                           
       }
        
        
    }


   
    
    const handleSaveNote = () => {
        
                 const formData={
                     notes:note
                 }
                
                 dispatch<any>(handleCreateVehicleTransferNote({
                     id:params.vehId,
                     vehTransferId:transferList.id,
                     formData,
                     callback: (response) => {
                         if(response){
                             getDataList();
                             getNotes();
                             setNote(null)
                         }else{
                             setcommonServerError("Fail to Save the note")
                         }
                             
                     }
                 }));
       
    }
    const handleDialogDismiss = () => {
      
       setIsSoldDialog(false)
       
    }
    const handleSaveSoldDialog = (data) => {
       setDealNumber(data)
       ///saveWriteUoCheck(data);
       setIsSoldDialog(false);
    }

   
    
    const handleWarningClose = () => {
      

       savetransferList();
       setIsWarningDialog(false)
      
    }

    useEffect(() => {

    	const stateVal: any = location?.state
        
        if (stateVal?.val?.sentDateTime ) {
            handleNavigate(params.vehId ,stateVal?.val)
        }else{
            setTransferVehicleUI(false)
        }
        
        if (stateVal?.locationFilter) {
          setLocationFilter(stateVal?.locationFilter);
        }
        
        if (stateVal?.statusFilter) {
          setStatusFilter(stateVal?.statusFilter);
        }

        if (stateVal?.formData) {
          setFormData(stateVal?.formData);
        }
    	 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleNotificationDialogDismiss = () => {

         setisSaveNotification(false);

    };
    
    const handleWarningDialogDismiss = () => {

         setIsWarningDialog(false);
         setTransferVehicleUI(false);
    };

    const handleRouteBeforeSave = (route) => {
       if(route === 'yes'){
           setTransferVehicleUI(false)
           setisSaveNotification(false);
       } else{
           
           saveWriteUoCheck()
           setisSaveNotification(false);
       }
        
    };

    console.log('transferList',transferList)

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Row>
                        <Col sm={12} md={12} xs={12} lg={12}>
                            <img
                                alt="Back"
                                key={"back"}
                                className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                                onClick={handleBackToUsers}
                                src={`${require("assets/back_pagination.png")}`}
                            />
                        </Col>
                 </Row>
                 {
                 isSoldDialog ?
                    <AddDealRecordDialog
                        isOpen={isSoldDialog}
                        locationID={formik.values.transferTo}
                        vehId={params.vehId}
                        vehicleTransferID ={formik.values.id}
                        onDismiss={handleDialogDismiss}
                        onsave={handleSaveSoldDialog}
                    />
                    : null
                  }
                  {
                  isWarningDialog ?
                     <TransferWarningDialog
                        isOpen={isWarningDialog}
                        onDismiss={handleWarningDialogDismiss}
                        transferDetails={warningData}
                        warningClose={handleWarningClose}
                    />
                    : null
                  }
                  {isSaveNotification ?
                      <DealPageSaveNotification
                          onDismiss={handleNotificationDialogDismiss }
                          handleRoute={(route) => handleRouteBeforeSave(route)}

                      />
                      : null
                  }
                  {saveSuccessDialog ?
                   <SaveSuccessDialog
                       onDismiss={() => { setSaveSuccessDialog(false); }}
                   /> : null}
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >
                    
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Vehicle Tracker</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }

                <Row>
		            <Col md={10}>
			             <Row>
			                
                            
			             	<Col md={10}>
			        		     
                                 <strong className="writeUpsFont">
                                   Home Location : {vehicleData?.location?.legalName} &nbsp;&nbsp;Stock Number : {vehicleData?.vehicle?.stockNumber}
                                 </strong>
			             	</Col>
                             
                            
			             </Row>
			        </Col>
		            <Col>
			        </Col>
		        </Row>
                <Row>
                   
                    <Col md={10}>
			             
                         <strong className="writeUpsFont">
                             {vehicleData?.vehicle.year +' '+ vehicleData?.vehicle.make +' '+ vehicleData?.vehicle.model}
                         </strong>
			        </Col>
		            <Col>
			        </Col>
		        </Row>

                { transferVehicleUI ?
                    
                
                <>
                   <Row>
                       <Col md={3}>
			           </Col>
		               <Col md={6}>
			                <Row>
			                   
                                 <Col style={{display:"flex"}} className="vt-delivery request-delivery" md={6}>
                                       <div className="auctionDate date-picker auctionDateAppointment">
                                           <label style={{ display: "block" }} className="lbl-text required-Label">Sent Date</label>
                                           <div className="ms-TextField-fieldGroup mask-FieldGroup">                        
                                               <Calendar
                                                  id="date"
                                                  //className="modal-calendar"
                                                  value={sentDateFilter}
                                                  style={{ width: "100%" }}
                                                  showIcon
                                                  onChange={(e) => handleSentDateChange(e.value)}
                                                  onBlur={handleDateBlur}
                                                  monthNavigator
                                                  required={true}
                                                  // appendTo="self"
                                                  yearRange={yearRange}
                                                  disabled={isViewable}
                                                  yearNavigator />

                                           </div>
                                           <span className="error-message error-message-text " >
                                              {blurFlag && formik.errors && formik.errors.sentDate &&
                                              formik.errors.sentDate.toString()}
                                           </span>
                                       </div>
                                       <div>
                                       &nbsp;&nbsp;
                                       </div>
                                       <div style={{ width: "50%" }}>
                                           <label style={{ display: "block" }} className="lbl-text">&nbsp;</label>
                                           <TimePicker
                                               id="sentTime"
                                               showSecond={false}
                                               disabled={isViewable}
                                               value={sentTime}
                                               className="mask-FieldGroup rc-time-picker-input"
                                               onChange={handleSentTimeChange}
                                               onOpen={handleTimePanelOpen}
                                               format="h:mm a"
                                               use12Hours
                                               inputReadOnly
                                               minuteStep={15}
                                               popupClassName="appointment-time-panel"
                                               inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                               placeholder="Time"
                                           />
                                           <span className="error-message" style={{ display: "flex" }}>
                                               {blurFlagTime && formik.errors && formik.errors.sentTime &&
                                                   formik.errors.sentTime}
                                           </span>
                                       </div>
                                </Col>
                                <Col>
                                    <div >
                                        <label className="lbl-text">Requested By</label>
                                        <Dropdown
                                            id="requestedBy"
                                            placeholder=""
                                            value={formik.values.requestedBy}
                                            options={employeeList}
                                            onChange={onRequestedByChange}
                                            disabled={isViewable}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={true}
                                            resetFilterOnHide={true}
                                            editable= {false}
                                            maxLength={1}
                                            style={{height:"auto"}}
                                        />
                                  
                                    </div>
                                </Col>
			                </Row>
				       </Col>
		               <Col md={6}>
			           </Col>
		           </Row>
                   <Row>
                       <Col md={3}>
			           </Col>
		               <Col md={6}>
			                <Row>
			                    <Col>
                                    <div className="locationDropPosition">
                                        <label className="lbl-text" style={{ paddingBottom: "0px" }}>Transfer To</label>
                                        <Dropdown
                                            id="transferTo"
                                            placeholder=""
                                            value={formik.values.transferTo}
                                            options={cityDealerList}
                                            onChange={onTransferedToChange}
                                            disabled={isViewable}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={true}
                                            resetFilterOnHide={true}                                       
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="locationDropPosition">
                                        <label className="lbl-text" style={{ paddingBottom: "0px" }}>Status</label>
                                        <Dropdown
                                            id="status"
                                            placeholder=""
                                            value={formik.values.status}
                                            disabled={isViewable}
                                            options={statusOption}
                                            onChange={onStatusChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>                        
			                </Row>
				       </Col>
		               <Col md={6}>
			           </Col>
		           </Row>
                   <Row>
                       <Col md={3}>
			           </Col>
		               <Col md={6}>
			                <Row>
			                     <Col md={6}>
                                   <div className="employeeeSearchLabelText">
                                     <OffTextField
                                         id="customer"
                                         name="customer"
                                         label="Customer "
                                         resizable={false}
                                         value={formik.values.customer}
                                         disabled={isViewable}
                                         onChange={formik.handleChange}
                                         onBlur={formik.handleBlur}
                                         errorMessage={
                                             formik.touched.customer &&
                                             formik.errors.customer &&
                                             formik.errors.customer.toString()
                                         }
                                     />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="main-deal ms-TextField">
                                        <div className="main-deal ms-TextField-wrapper">
                                            <label className="lbl-text">Wholesale Complete </label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup ">
                                                <Calendar
                                                    id="wholeSaleComplete"
                                                    style={{ width: "100%" }}
                                                    className="p-datepicker-today"
                                                    value={wholeSaleComplteDate}
                                                    showIcon
                                                    onChange={(e) => handleWholeSaleCompleteDateChange(e.value)}
                                                    // maxDate={new Date()}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange} />
                                            </div>
                                            <span className="error-message error-message-text " >
                                              {blurFlag && formik.errors && formik.errors.wholeSaleCompleteDate &&
                                              formik.errors.wholeSaleCompleteDate.toString()}
                                           </span>
                                        </div>
                                    </div>
                                </Col>
			                </Row>
				       </Col>
		               <Col md={3}>
			           </Col>
		           </Row>
                   <Row>
                       <Col md={3}>
			           </Col>
                       <Col md={6}>
                           <div className="delivery-section" style={{ padding: "10px 0px" }}>
                                  <Row className="rowPaddingBtwSpace">
                                      
                                      <Col className=" box-column1 box-column" style={{textAlign:"right"}}>
                                          <Link onClick={(e) => handleBoxClick(e, formik.values.secondKey, 'secondKey')}  className="box-link-Vehicle-tracker box-link">
                                              <div className={" box-link-Vehicle-height box-section box-status-" + formik.values.secondKey}>
                                                  <div className="blueShadedText">
                                                      2nd Key
							                        </div>
                                                  <div className="greyShadedText box-status">
                                                     {boxStatus[formik.values.secondKey]}
                                                  </div>
                                              </div>
                                          </Link>
                                      </Col>
                                      
                                      <Col className=" box-column1 box-column" style={{textAlign:"center"}}>
                                          <Link onClick={(e) => handleBoxClick(e, formik.values.folder, 'folder')}  className="box-link-Vehicle-tracker box-link">
                                              <div className={" box-link-Vehicle-height box-section box-status-" + formik.values.folder}>
                                                  <div className="blueShadedText">
                                                     Folder
							                        </div>
                                                  <div className="greyShadedText box-status">
                                                      {boxStatus[formik.values.folder]}
                                                  </div>
                                              </div>
                                          </Link>
                                      </Col>
                                      <Col  className=" box-column1 box-column" style={{textAlign:"left"}}>
                                          <Link onClick={(e) => handleBoxClick(e, formik.values.title, 'title')}  className="box-link-Vehicle-tracker box-link">
                                              <div className={" box-link-Vehicle-height box-section box-status-" + formik.values.title}>
                                                  <div className="blueShadedText">
                                                      Title
							                        </div>
                                                   <div className="greyShadedText box-status">
                                                      {boxStatus[formik.values.title]}
                                                  </div>
                                              </div>
                                          </Link>
                                      </Col>
                                      
                                  </Row>
                               </div>
                       </Col>
                       <Col md={3}>
			           </Col>
                   </Row>
                   <Row>
                       <Col md={12}>
                         <div style={{ textAlign: "center" }}>
                         {
                             transferList ?
                              <PrimaryButton
                                 text="Save"
                                 onClick={handleSave}
                                 disabled={!formik.dirty || !formik.isValid || isViewable}
                             /> :
                             <PrimaryButton
                                 text="Save"
                                 onClick={handleSave}
                                 disabled={!formik.dirty || !formik.isValid  }
                             />
                         }
                             
                         </div>
			           </Col>
		               
		           </Row>
                   { transferList &&
                     <>
                       <Row>
                           <Col md={3}>
			               </Col>
                           <Col md={6} className=" notesSection">
                                   <OffTextField
                                       id="deliveryNote"
                                       name="deliveryNote"
                                       label="Notes"
                                       multiline
                                       resizable={false}
                                       className=" full-width fontWeightSection"
                                       value={note}
                                       style={{fontSize:"14px",fontWeight:600}}
                                       onChange={(e, newValue) => handleDeliveryNoteChange(e, newValue)}
                                   />
                                                                                        
			               </Col>
		                   <Col md={3}>
			               </Col>
		               </Row>
                       <Row>
                           <Col md={3}>
			               </Col>
                           <Col md={6}>
                             <div style={{ textAlign: "right" }}>
                                 <PrimaryButton
                                     text="Add Note"
                                     onClick={handleSaveNote}
                                     //disabled={settingPermission !== 1}
                                 />
                             </div>
			               </Col>  
		                   
		               </Row>
                       <Row>
                           <Col md={3}>
			               </Col>
                           <Col md={6}>
                              <label className="lbl-text" >Note Log</label>
                              <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                                 <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                                     <DataTable
                                         value={noteData}
                                         paginator={false}
                                         //header={header}
                                         //footer={footer}
                                         sortField={sortField}
                                         sortOrder={sortOrder}
                                         onSort={handleColumnSort}
                                         lazy
                                         //rows={25}
                                         className="p-datatable-customers"
                                         //rowsPerPageOptions={[25, 50, 100]}
                                         emptyMessage="No Data found."
                                         //onRowClick={(event) => handleRowClick(event)}
                                         >
                                         <Column sortable field="sentDate" style={{ width: '25%' }} sortField="sentDate"  body={sentDateBodyTemplate} />
                                         <Column sortable field="name" style={{ width: '25%' }} header="Name" body={nameBodyTemplate} />
                                         <Column sortable field="note" style={{ width: '25%' }} header="Note" body={noteBodyTemplate} />
                                         <Column  style={{ width: '25%' }} />
                                        
                                      </DataTable>
                                     
                                 </div>
                              </div> 
			               </Col>
                           <Col md={3}>
			               </Col>
		                   
		               </Row>
                   </>
                   }
                   
                </>

                :

                <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                    <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                        <DataTable
                            value={finalData}
                            paginator={false}
                            header={header}
                            footer={footer}
                            //sortField={sortField}
                            //sortOrder={sortOrder}
                            //onSort={handleColumnSort}
                            lazy
                            //rows={25}
                            className=" p-datatable-customers"
                            //rowsPerPageOptions={[25, 50, 100]}
                            emptyMessage="No Data found."
                            onRowClick={(event) => handleRowClick(event)}>
                            <Column sortable field="sentDateTime" sortField="sentDate" header="Sent Date" body={dateBodyTemplate} />
                            <Column sortable field="location" header="Location" body={locationBodyTemplate} />
                            <Column sortable field="status" header="Status" body={statusBodyTemplate} />
                            <Column sortable field="requestedBy" header="Requested By" body={requestedByBodyTemplate} />
                            <Column sortable field="customer" header="Customer" body={customerBodyTemplate} />
                            <Column sortable field="comments" header="Notes" body={commentsBodyTemplate} />
                            <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                         </DataTable>
                        
                    </div>
                </div> 
                }
            </Stack>
        </> : null
    );
};
