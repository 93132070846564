import * as actions from "store/brands/brands.types";
import { IBrands, IBrand } from "types/brandsTypes";

interface IState {
  deleting: boolean;
  loading: boolean;
  brands: IBrands | null;
  error: null | object;
  rowsPerPage: number;
  selectedBrand: null | IBrand;
  updateLoading: boolean;
  column: string;
  sort: string;
  brandOptions?: IBrands;
}

const initialState: IState = {
  deleting: false,
  loading: false,
  brands: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
  error: null,
  rowsPerPage: 15,
  selectedBrand: null,
  updateLoading: false,
  column: 'name',
  sort: 'ASC',
};

export const BrandsReducer = (
  state: IState = initialState,
  action: actions.BrandsActions
) => {
  switch (action.type) {
    case actions.CREATE_BRAND_PENDING:
      return {
        ...state,
        loading: true,
      };
      
    case actions.CREATE_BRAND_REJECTED:
      return { ...state, loading: false, error: action.payload };
    case actions.CREATE_BRAND_RESOLVED:
      return {
        ...state,
        loading: false,
        brands: action.payload
      };
    case actions.GET_BRANDS_DROPDOWN_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BRANDS_DROPDOWN_REJECTED:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_BRANDS_DROPDOWN_RESOLVED:
      return {
        ...state,
        loading: false,
        brandOptions: action.payload,
      };
    case actions.GET_BRANDS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BRANDS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.GET_BRANDS_RESOLVED:
      return {
        ...state,
        loading: false,
        brands: action.payload,


      };
    case actions.SET_ROWS_PER_PAGE_BRANDS:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case actions.VIEW_BRAND_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.VIEW_BRAND_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedBrand: action.payload,
      };
    }

    case actions.VIEW_BRAND_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_BRAND_PENDING: {
      return {
        ...state,
        deleting: true,
        //loading: true,
      };
    }

    case actions.DELETE_BRAND_RESOLVED: {
      return {
        ...state,
        deleting: false,
        //loading: false,
      };
    }

    case actions.DELETE_BRAND_REJECTED: {
      return {
        ...state,
        deleting: false,
      };
    }
    case actions.UPDATE_BRAND_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.UPDATE_BRAND_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_BRAND_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
