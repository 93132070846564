import {
    Checkbox,
    ChoiceGroup,
    //PrimaryButton,
    Dropdown,
    IChoiceGroupOption,
    Link,
    //MaskedTextField,
    TextField
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
//import moment from "moment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Col, Row } from "react-grid-system";
//import AccordionActions from '@material-ui/core/AccordionActions';
import { Box, Typography } from '@mui/material';
//import Divider from '@material-ui/core/Divider';
import { useFormik } from "formik";
//import { getAddreessByZip } from "services/api/smartyStreets";
import masterDropdownValues from "constants/masterDropdownValues";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
//import { dropdownStyles } from "constants/styles";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { themeStyles } from "constants/constants";
import muiThemeStyle from "constants/muiThemeStyle";
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { HandleZipCodeFlatData } from "store/shared/shared.action";
import { validTenDigitPhone, validURL } from "utils/helperFunctions";

const iconClass = mergeStyles({
    fontSize: 15,
    height: 10,
    width: 10,
    margin: '0 25px',
});

const theme = {
    heading: {
        fontSize: muiThemeStyle.typography?.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: muiThemeStyle.typography?.pxToRem(15),
        color: muiThemeStyle.palette?.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
}

const LocationAccordion = ({ owner, onClickRemove, index, locationForm }) => {

    const [cityList, setCityList] = useState([]);
    const [countyList, setCountyList] = useState([]);
    const [zipApiMsg, setZipApiMsg] = useState("");
    let form = locationForm ? (locationForm.values ? locationForm.values : locationForm) : null;
    const [locationIssueDate, setlocationIssueDate] = useState(form ? new Date(form.dealerLicenseDateOfIssue) : undefined);
    const dispatch = useDispatch();
    const yearRange = "1980:" + (new Date().getFullYear());
    const [blurFlag, setBlurFlag] = useState(false);

    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    let initialDealerForm = {
        legalName: "",
        //companyAlias: "",
        dealerLicenseNumber: "",
        dealerLicenseDateOfIssue: '',
        dealerLicenseState: "US",
        dealerType: "",
        collateral: "auto",
        // service:"yes",
        inventoryCondition: [],
        isMaintenanceFacilityAvailable: true,
        street: "",
        city: "",
        state: "",
        zip: "",
        county: "",
        phoneNumber: "",
        faxNumber: "",
        website: ""
        // email: ""
    };

    const formik = useFormik({
        initialValues: locationForm ? (locationForm.values ? locationForm.values : locationForm) : initialDealerForm,
        validationSchema: Yup.object({
            legalName: Yup.string().required("Required"),
            //companyAlias: Yup.string(),
            dealerLicenseNumber: Yup.string().required("Required").min(1, "Dealer License Number must be Min. 1 Character").max(50, "Dealer License Number must be Max. 50 Character"),
            dealerLicenseDateOfIssue: Yup.string().required("Required"),
            dealerLicenseState: Yup.string(),//.required("Required"),
            dealerType: Yup.string().required("Required"),
            collateral: Yup.string().required("Required"),
            isMaintenanceFacilityAvailable: Yup.boolean(),
            // service: Yup.string().required("Required"),
            inventoryCondition: Yup.array().nullable().min(1, "Required"),
            street: Yup.string().required("Required"),
            city: Yup.string(),
            state: Yup.string(),
            zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
            county: Yup.string(),
            faxNumber: Yup.string()
                .test("len", "Invalid fax number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }),
            website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),//optional 
            // email: Yup.string().required("Required").email("Enter valid email"),
            phoneNumber: Yup.string()
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                })
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^\d]/g, "").length > 0
                }).required("Required"),
            // role: Yup.string().required("Required"),
            // ownershipPercentage: Yup.string().required("Required"),
            //email: Yup.string()
            //    .required("Required")
            //    .email("Enter valid email")
        }),
        onSubmit: (values) => {
        },
        validateOnMount: true
    });

    const options: IChoiceGroupOption[] = [
        { key: 'true', text: 'Yes' },
        { key: 'false', text: 'No' },
    ];

    //const collateralOptions: IChoiceGroupOption[] = [
    //    { key: 'auto', text: 'Auto' },
    //    { key: 'rv', text: 'RV' },
    //];

    useEffect(() => {
        let zipFields = formik.values.zip;
        if (zipFields && zipFields.length === 5 && !isNaN(zipFields as any)) {
            dispatch<any>(switchGlobalLoader(true));
            //(getAddreessByZip(zipFields) as any).then((response) => {

            dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (response) => {
                    if (response) {

                        setCityList(response.Cities);
                        setCountyList(response.Counties);
                        if (formik.values.city === '' &&
                            formik.values.county === '' &&
                            formik.values.state === '') {
                            formik.setValues(
                                {
                                    ...formik.values,
                                    'county': response.DefaultCounty,
                                    'city': response.DefaultCity,
                                    'state': response.State
                                }
                            );
                        }
                        setZipApiMsg("");

                    }
                    else {
                        formik.setValues(
                            {
                                ...formik.values,
                                'county': "",
                                'city': "",
                                'state': ""
                            }
                        );
                        setZipApiMsg('Invalid ZIP Code.');
                        setCityList([]);
                        setCountyList([]);
                    }
                    dispatch<any>(switchGlobalLoader(false));
                }
            }));
        } else {
            formik.setValues(
                {
                    ...formik.values,
                    'county': "",
                    'city': "",
                    'state': ""
                }
            );
            setCityList([]);
            setCountyList([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.zip]);

    const handleZipCodeChangeForDealer = (e) => {
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            formik.setValues(
                {
                    ...formik.values,
                    'zip': e.target.value
                });
        }
    };

    const handleDateBlur = () => {
        setBlurFlag(true);
    }


    const handleDropdownValue = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key
            }
        );
    };

    const handleRemoveDealerItem = (name) => {
        onClickRemove();
    };

    useEffect(() => {
        owner(formik, index);
        if (locationForm && (!locationForm.isValid || !locationForm.dirty)) {
            formik.handleSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        owner(formik, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, formik.errors, formik.touched]);

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                'dealerLicenseDateOfIssue': val
            }
        );
        setlocationIssueDate(val);
    };


    const handleDropdownRadioChange = (e, item) => {
        let field = e.target.name ? e.target.name : e.target.id;
        if (field.includes('_')) {
            field = field.split('_');
            field = field[0];
        }

        formik.setValues(
            {
                ...formik.values,
                [field]: item.key,
            }
        );
    };

    const onChangeCheckBox = (e, isChecked) => {
        let field = e.target.id;
        if (field.includes('_')) {
            field = field.split('_');
            field = field[0];
        }
        if (isChecked) {
            if (!formik.values.inventoryCondition.includes(field)) {
                let arr = formik.values.inventoryCondition;
                arr.push(field);

                formik.setValues(
                    {
                        ...formik.values,
                        'inventoryCondition': arr
                    }
                );
            }
        } else {
            if (formik.values.inventoryCondition.includes(field)) {

                formik.setValues(
                    {
                        ...formik.values,
                        'inventoryCondition': formik.values.inventoryCondition.filter(item => item !== field) as any,
                    }
                );
            }
        }
    };

    const handleMaskInputLocation = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    return (

        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                bgcolor: 'background.paper',
                display: 'block',
            }}
        >
            <div key={index} >
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        className="accordion-summary-location"
                    >
                        <div style={themeStyles.column}>
                            <Typography component={'h3'} style={theme.heading}>Location {index}</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={themeStyles.details}>

                        <div className="tabRowSection">
                            <Row>
                                <Col sm={6} xs={12} md={6} lg={6}>
                                    <TextField
                                        id="legalName"
                                        name="legalName"
                                        label="Location Name"
                                        placeholder=""
                                        value={formik.values.legalName}
                                        required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.legalName &&
                                            formik.errors.legalName &&
                                            formik.errors.legalName.toString()
                                        }
                                    />
                                </Col>
                                <Col sm={6} xs={12} md={6} lg={6}>
                                    <Link
                                        className={index}
                                        onClick={() => handleRemoveDealerItem(index)}
                                        style={{ float: "right" }}
                                        underline={false}
                                    ><FontIcon aria-label="Delete" iconName="Delete" className={iconClass} /> Delete Location</Link>
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "0px 250px" }} className="tabRowSection dealership-info">
                            <Row>
                                <Col sm={8} xs={12} md={8} lg={8} >
                                    <TextField
                                        id="street"
                                        name="street"
                                        label="Street Address"
                                        className="gs-business-input"
                                        placeholder=""
                                        value={formik.values.street}
                                        required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.street &&
                                            formik.errors.street &&
                                            formik.errors.street.toString()
                                        }
                                    />
                                </Col>
                                <Col sm={4} xs={12} md={4} lg={4}>
                                    <TextField
                                        id="zip"
                                        name="zip"
                                        label="Zip"
                                        placeholder=""
                                        className="gs-business-input-zip"
                                        value={formik.values.zip}
                                        required={true}
                                        onChange={(e) => handleZipCodeChangeForDealer(e)}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            (formik.touched.zip &&
                                                formik.errors.zip) ?
                                                formik.errors.zip.toString()
                                                : zipApiMsg ? zipApiMsg : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <div className="address-zip" style={{ marginRight: "50px" }}>
                                <Row>
                                    <Col sm={9} xs={12} md={9} lg={9}>
                                        <Dropdown
                                            id="city"
                                            label="City"
                                            placeholder=""
                                            required={true}
                                            selectedKey={formik.values.city}
                                            options={cityList}
                                            onChange={handleDropdownValue}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.city &&
                                                formik.errors.city &&
                                                formik.errors.city.toString()
                                            }
                                        />
                                    </Col>
                                    <Col sm={3} xs={12} md={3} lg={3}>
                                        <TextField
                                            id="state"
                                            name="state"
                                            label="State"
                                            placeholder=""
                                            className="gs-business-input"
                                            readOnly={true}
                                            value={formik.values.state}
                                            required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.state &&
                                                formik.errors.state &&
                                                formik.errors.state.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={9} xs={12} md={9} lg={9}>
                                        <Dropdown
                                            id="county"
                                            label="County"
                                            placeholder=""
                                            selectedKey={formik.values.county}
                                            options={countyList}
                                            onChange={handleDropdownValue}
                                            required={true}
                                            errorMessage={
                                                formik.touched && formik.touched.dealerForm &&
                                                formik.touched.county && formik.errors.dealerForm &&
                                                formik.errors.county &&
                                                formik.errors.county.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col sm={6} xs={12} md={6} lg={6}>
                                    <div className="ms-TextField root-155">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Business Phone</label>
                                            <div className={formik.touched.phoneNumber &&
                                                formik.errors.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                <InputMask
                                                    autoClear={false}
                                                    required={true}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    mask="(999)999-9999"
                                                    placeholder=""
                                                    value={formik.values.phoneNumber}
                                                    className="mask-TextField"
                                                    onChange={(e) => handleMaskInputLocation(e)}
                                                    onBlur={formik.handleBlur}>
                                                </InputMask>
                                            </div>
                                            <span className="error-message">{
                                                formik.touched.phoneNumber &&
                                                formik.errors.phoneNumber &&
                                                formik.errors.phoneNumber.toString()
                                            }</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} xs={12} md={6} lg={6}>
                                    <div className="ms-TextField root-155">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text">Fax</label>
                                            <div className={formik.touched && formik.touched.faxNumber &&
                                                formik.errors && formik.errors.faxNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                <InputMask
                                                    autoClear={false}
                                                    required={true}
                                                    id="faxNumber"
                                                    name="faxNumber"
                                                    mask="(999)999-9999"
                                                    placeholder=""
                                                    value={formik.values.faxNumber}
                                                    className="mask-TextField"
                                                    onChange={(e) => handleMaskInputLocation(e)}
                                                    onBlur={formik.handleBlur}>
                                                </InputMask>
                                            </div>
                                            <span className="error-message">{
                                                formik.touched && formik.touched.faxNumber &&
                                                formik.errors && formik.errors.faxNumber &&
                                                formik.errors.faxNumber.toString()
                                            }</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} xs={12} md={12} lg={12}>
                                    <TextField
                                        id="website"
                                        name="website"
                                        label="Website"
                                        placeholder=""
                                        value={formik.values.website}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.website &&
                                            formik.errors.website &&
                                            formik.errors.website.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div style={{ padding: "0px 250px" }} className="tabRowSection">
                            <Row>
                                <Col>
                                    <span className="locationDetail">Location Details</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={7} xs={12} md={7} lg={7}>
                                    <TextField
                                        id="dealerLicenseNumber"
                                        name="dealerLicenseNumber"
                                        label="Dealer License Number"
                                        placeholder=""
                                        value={formik.values.dealerLicenseNumber}
                                        required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.dealerLicenseNumber &&
                                            formik.errors.dealerLicenseNumber &&
                                            formik.errors.dealerLicenseNumber.toString()
                                        }
                                    />
                                </Col>
                                <Col sm={4} xs={12} md={4} lg={4}>
                                    <div className="ms-TextField gs-business-input">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Date of Issue</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                <Calendar
                                                    id="navigators"
                                                    style={{ marginBottom: 0 }}
                                                    showIcon
                                                    placeholder=""
                                                    value={locationIssueDate}
                                                    maxDate={new Date()}
                                                    //ariaLabelledBy="Select a date"
                                                    // onSelect={(e) => handleDateChange(e)}
                                                    onChange={(e) => handleDateChange(e.value)}
                                                    required={true}
                                                    onBlur={handleDateBlur}
                                                    monthNavigator
                                                    yearRange={yearRange}
                                                    yearNavigator
                                                />
                                            </div>
                                            <span className="error-message">
                                                {blurFlag && formik.errors && formik.errors.dealerLicenseDateOfIssue &&
                                                    formik.errors.dealerLicenseDateOfIssue.toString()}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={8} xs={12} md={8} lg={8}>
                                    <Dropdown
                                        id="dealerType"
                                        label="Dealership Type"
                                        placeholder=""
                                        className="gs-business-input"
                                        options={masterDropdownValues.dealerTypeList}
                                        selectedKey={formik.values.dealerType}
                                        onChange={handleDropdownValue}
                                        required={true}
                                        errorMessage={
                                            formik.touched.dealerType &&
                                            formik.errors.dealerType &&
                                            formik.errors.dealerType.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ paddingTop: "15px" }}>
                                    <div style={{ display: "flex" }}>
                                        <label className="width-420 lbl-text choice-lbl labelArea check-box-label required-Label">Is your inventory new or used?</label>
                                        <label className="firstInputEvt checkboxArea">
                                            <Checkbox
                                                id={`new_${index}`}
                                                label="New"
                                                onChange={onChangeCheckBox}
                                                checked={formik.values.inventoryCondition && formik.values.inventoryCondition.includes("new")}
                                            />
                                        </label>

                                        <label className="firstInputEvt checkboxArea">
                                            <Checkbox
                                                label="Used"
                                                id={`used_${index}`}
                                                // id="used"
                                                onChange={onChangeCheckBox}
                                                checked={formik.values.inventoryCondition && formik.values.inventoryCondition.includes("used")}
                                            />
                                        </label>
                                    </div>
                                    <span style={{ paddingLeft: "15px" }} className="error-message">{
                                        formik.touched.inventoryCondition && formik.errors.inventoryCondition
                                            ? formik.errors.inventoryCondition.toString()
                                            : null
                                    }</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ChoiceGroup
                                        id={`isMaintenanceFacilityAvailable_${index}`}
                                        name={`isMaintenanceFacilityAvailable_${index}`}
                                        required={true}
                                        className="guidedRadio choice-lbl"
                                        defaultSelectedKey="true"
                                        options={options}
                                        selectedKey={formik.values.isMaintenanceFacilityAvailable && formik.values.isMaintenanceFacilityAvailable.toString()}
                                        onChange={handleDropdownRadioChange}
                                        label="Does this dealership have a service department or repair faciliity?" />
                                </Col>
                            </Row>
                        </div>
                    </AccordionDetails>
                    {/*<Divider />
                <AccordionActions>
                    <PrimaryButton
                        className={index}
                        onClick={() => handleRemoveDealerItem(index)}
                        style={{ float: "right" }}
                        // onClick={nextStep}
                        text="Delete"
                        iconProps={{ iconName: "Delete" }}
                    />
                </AccordionActions>*/}
                </Accordion>
            </div>

        </Box>
    )
};

export default LocationAccordion;