import React from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
//import { IIconStyles } from "@fluentui/react";
//import { colors } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { DefaultButton } from "@fluentui/react";


interface IProps {
    modulePermissions: IPermission[];
    handleEdit: (vehicleID: any) => void;
    itemId?: any;
    isEdit?: any;
}

export const EditActionNoSubMenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    //onEdit,
    handleEdit,
    itemId,
    isEdit,
}) => {

    return (
        <>
             { !isEdit ?

                 <>
                    <IconButton
                      className="iconButton icon-ellipse-pad pad-left-btn icon-ellipse-disable icon-ellipse-h deskDisplay"
                      styles={buttonStyles}
                      text="Group Action"
                      //onClick={(itemId) => handleEdit(itemId)}
                     />
                     <DefaultButton text="Actions" className="mobileActionVisibility actionMobData"/>

                 </>

                 :
                 <>
                    <IconButton
                      className="iconButton icon-ellipse-pad pad-left-btn icon-ellipse-h deskDisplay"
                      styles={buttonStyles}
                      text="Group Action"
                      onClick={(itemId) => handleEdit(itemId)}
                     />
                     <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" onClick={(itemId) => handleEdit(itemId)} />
                  </>
             }
        </>
    );
};

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};
