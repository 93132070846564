import React, { useState } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  IIconProps,
  PrimaryButton,
  TextField,
  Dropdown
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/loader/Loader";
import { handleDeleteProspect } from "store/prospects/prospects.action";
import { FormikValues } from "formik";
import { AppState } from "store/index";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { styles } from "pages/accountSettingsPage/styles";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import {
  iconButtonStyles,
  contentStyles as contentStyle,
  dropdownStyles
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { ViewAdditionalActionsDropdown } from "components/table/dropdowns/ViewAdditionalActionsDropdown";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { getModulePermissions } from "utils/permissions/permissionsHelpers";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
// import {setInventoryFilters} from "../../../../store/inventory/inventory.action";
import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

const contentStyles = contentStyle();
const cancelIcon: IIconProps = { iconName: "Cancel" };

interface IProps {
  onDismiss: () => void;
  handleGoBack: () => void;
  prospectId?: number;
  formik?: FormikValues;
  handleEditProspect: () => void;
}

export const EmploymentDialog: React.FunctionComponent<IProps> = ({
  handleGoBack,
  onDismiss,
  prospectId,
  formik,
  handleEditProspect,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) => state.prospects.loading);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);

  const handleDropdownChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key,
            },
            true
        );
  };
    //Employer Zip 
    const [employerZipApiMsg, setEmployerZipApiMsg] = useState("");
    const [employerCities, setEmployerCities] = useState([]);
    const [employerCounties, setEmployerCounties] = useState([]);
    const handleEmployerZipChange = (e) => {
        if (e.target.value.length === 5 && !isNaN(e.target.value)) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {

                if (response.Status) {
                    setEmployerCities(response.Cities);
                    setEmployerCounties(response.Counties);
                    formik.setValues(
                        {
                            ...formik.values,
                            employerState: response.State,
                            employerCity: response.DefaultCity,
                            employerCounty: response.DefaultCounty
                        },
                        true
                    );

                }
                else {

                    setEmployerZipApiMsg(response.ErrorMessage);
                    resetEmployerCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
        }
        else {
            setEmployerZipApiMsg("");
            resetEmployerCityCountyState();
        }
    };

    const resetEmployerCityCountyState = () => {
        formik.setValues(
            {
                ...formik.values,
                employerState: "",
                employerCity: "",
                employerCounty: ""
            },
            true
        );
        setEmployerCounties([]);
        setEmployerCities([]);
    }

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      {/*<Loader show={loading} />*/}
      <ConfirmDialog
        loading={loading}
        description="Please confirm deletion of prospect"
        title="Confirm delete prospect"
        isOpen={isConfirmBoxOpen}
        onAction={() => dispatch<any>(handleDeleteProspect([prospectId]))}
        onDismiss={() => setIsConfirmBoxOpen(false)}
      />
      <div className={contentStyles.header+" inventory-popup"}>
        <GoBackToMainDialogBtn label="Employment" onClick={handleGoBack} />
        {/*<CustomFontText>Personal Information</CustomFontText>*/}
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>

      <div className={`noScrollbar ${contentStyles.body}`}>
        <Stack>
          <Stack tokens={styles.textFields}>
            <WithOptionalLabel>
              <TextField
                id="employerName"
                name="employerName"
                label="Employer Name"
                value={formik.values.employerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerName && formik.errors.employerName
                    ? formik.errors.employerName.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <WithOptionalLabel>
              <TextField
                id="lenthAtJob"
                name="lenthAtJob"
                label="Length at Job"
                value={formik.values.lenthAtJob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.lenthAtJob && formik.errors.lenthAtJob
                    ? formik.errors.lenthAtJob.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <WithOptionalLabel>
            <label className="lbl-text required-Label">Monthly Income</label>
              <CurrencyInput
                customInput={TextField}
               // thousandSeparator={true}
                // decimalScale={2}
                prefix={PRICE_PREFIX}
                id="monthlyIncome"
                name="monthlyIncome"
                //label="Monthly Income"
                value={formik.values.monthlyIncome?formik.values.monthlyIncome.replace(/,/g, ''):""}
                onValueChange ={(e) => {
                  formik.setValues(
                      {
                          ...formik.values,
                          "monthlyIncome": e
                      }
                  );
              }}
                onBlur={formik.handleBlur}
              />

              <span className="error-message">
                    {
                      formik.errors.monthlyIncome &&
                      formik.errors.monthlyIncome.toString()}
                  </span>

              {/*<TextField*/}
              {/*  id="monthlyIncome"*/}
              {/*  name="monthlyIncome"*/}
              {/*  label="Monthly Income"*/}
              {/*  prefix="$"*/}
              {/*  type="number"*/}
              {/*  value={formik.values.monthlyIncome}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.monthlyIncome && formik.errors.monthlyIncome*/}
              {/*      ? formik.errors.monthlyIncome.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
            </WithOptionalLabel>

            <WithOptionalLabel>
              <TextField
                id="employerAddress1"
                name="employerAddress1"
                label="Employer Address 1"
                value={formik.values.employerAddress1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerAddress1 &&
                  formik.errors.employerAddress1
                    ? formik.errors.employerAddress1.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <WithOptionalLabel>
              <TextField
                id="employerAddress2"
                name="employerAddress2"
                label="Employer Address 2"
                value={formik.values.employerAddress2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerAddress2 &&
                  formik.errors.employerAddress2
                    ? formik.errors.employerAddress2.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <Row>
              <Col style={{ flex: 2, paddingRight: 0 }}>
                <TextField
                  id="employerZip"
                  name="employerZip"
                  label="ZIP"
                  value={formik.values.employerZip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
				  onKeyUp={handleEmployerZipChange}
                  errorMessage={
                    formik.touched.employerZip && formik.errors.employerZip
                      ? formik.errors.employerZip.toString()
                      : employerZipApiMsg? employerZipApiMsg : null
                  }
                />
              </Col>
              {/*<Col style={isTablet ? cityTablet : city}>*/}
              <Col style={{ flex: 4 }}>
                <Dropdown
                      id="employerCity"
                      label="City"
                      selectedKey={formik.values.employerCity}
                      onChange={handleDropdownChange}
                      options={employerCities}
                      styles={dropdownStyles}
                      errorMessage={
                          formik.touched.employerCity && formik.errors.employerCity
                              ? formik.errors.employerCity.toString()
                              : null
                      }
                  />
              </Col>
            </Row>
            <Row>
                <Col style={{ flex: 6 }}>
                    <Dropdown
                        id="employerCounty"
                        label="County"
                        selectedKey={formik.values.employerCounty}
                        onChange={handleDropdownChange}
                        options={employerCounties}
                        styles={dropdownStyles}
                        errorMessage={
                            formik.touched.employerCounty && formik.errors.employerCounty
                                ? formik.errors.employerCounty.toString()
                                : null
                        }
                    />
                </Col>
                <Col style={{ flex: 2 }}>
                    <TextField
                        id="employerState"
                        name="employerState"
                        label="State"
                        value={formik.values.employerState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        errorMessage={
                            formik.touched.employerState && formik.errors.employerState
                                ? formik.errors.employerState.toString()
                                : null
                        }
                    />

                </Col>
            </Row>
          </Stack>
        </Stack>
      </div>
      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}

          // styles={contentStyles.buttonsStyles}
        >
          <div>
            <ViewAdditionalActionsDropdown
              modulePermissions={getModulePermissions(
                user.auths || [],
                "Prospects"
              )}
            />
          </div>
          <div>
            <DefaultButton
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />
            <PrimaryButton
              text="Save Changes"
              onClick={handleEditProspect}
              disabled={!formik.isValid}
            />
          </div>
        </Stack>
      </div>
    </Modal>
  );
};
