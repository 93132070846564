import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { colors } from "constants/constants";
//import CompanyInformation from "pages/dealerProfilePage/companyInformation/CompanyInformation";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import Ownership from "pages/dealerProfilePage/ownership/Ownership"
import AddressPage from "pages/dealerProfilePage/AddressInfo/AddressPage";


const border = {
  borderTop: `2px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};

interface IProps {
  formik: any;
  setHideButton: (boolean) => void;
}
const credentialModuleName = "Config";
export const DealerProfilePivot: React.FC<IProps> = ({
  formik,
  setHideButton,
}) => {
  // const [credentials, setCredentials] = useState([]);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  useEffect(() => {
    if (user && user.auths && !isUserMaster(user.roles)) {
      console.log(getModulePermissions(user.auths, credentialModuleName));
      // setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Pivot
      style={{ padding: 0, margin: 0 }}
      className="pivotItem delar-body"
      defaultSelectedKey="2"
       onLinkClick={(item?: any) => {
        //if (item.key === ".1") {
          //setHideButton(true);
        //} else {
          //setHideButton(false);
        //}
      }}
    >
      <PivotItem
        className="pivotItem"
        style={border}
        headerText="Address"
        headerButtonProps={{
          "data-order": 1,
            "data-title": "Address",
        }}
      >
      <AddressPage />
              {/*<CompanyInformation formik={formik} />*/}
      </PivotItem>
      <PivotItem
          className="pivotItem"
          style={border}
          headerText="Owners"
          headerButtonProps={{
              "data-order": 1,
              "data-title": "Owners",
          }}
      >
      <Ownership />
      </PivotItem>
      {/* <PivotItem
        className="pivotItem"
        style={border}
        headerText="Power of Attorney"
      >
        <PowerOfAttorney formik={formik} />
      </PivotItem> */}

      {/* <PivotItem
        className="pivotItem"
        style={border}
        headerText="Password Policy"
      >
        {user.roles && (isUserMaster(user.roles) || credentials) && (
          <WithCredentials
            groupAuths={credentials}
            alias={permissionsModules.Config}
            type={eTypes.WRITE}
            roles={user.roles}
          >
            <PasswordPolicy />{" "}
          </WithCredentials>
        )}
      </PivotItem> */}
    </Pivot>
  );
};
