import React, { useState, useEffect } from "react";
import {
    Stack,
    IStackStyles,
    Link
} from "@fluentui/react";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { handleResetPasswordRequest, clearServerErrorMessage } from "store/auth/auth.action";

const styles: IStackStyles = {
  root: {
    boxShadow: Depths.depth8,
    background: DefaultPalette.white,
    padding: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 2,
    width: 300,
    height: 350,
    zIndex: 1,
    borderTop: "10px solid #65BC50"
  },
};

const RequestSuccessPage: React.FC = () => {
  const dispatch = useDispatch();
  const [resendEmail, setResendEmail] = useState('');
  const resetEmail = useSelector((state: AppState) => state.auth.resetEmail);

  const resetPasswordReqError = useSelector(
      (state: AppState) => (state.auth as any).resetPasswordReqError
  );

  const resendSuccessMessage = useSelector(
      (state: AppState) => (state.auth as any).resendSuccessMessage
  );

  const resendPasswordLink = () => {
      dispatch<any>(clearServerErrorMessage());
      dispatch<any>(handleResetPasswordRequest(resendEmail));
  };

  useEffect(() => {
    if (resetEmail !== null) {
      setResendEmail(resetEmail);
    } 
  }, [resetEmail]);

  return (
    <AuthLayoutPage>
      <Stack styles={styles} verticalAlign="space-between">
         <span className="formTitle loginFormRequestHeader">Reset Password</span>
              {
                  resendSuccessMessage ? <p className="loginServerSuccessMsg loginFormSuccess"><span>{resendSuccessMessage}</span></p> : null
              }
              {
                  resetPasswordReqError ? <p className="genericErrorColor"><span>{resetPasswordReqError.toString()}</span></p> : null
              }
        <span className="loginFormSuccess">
                 <div className="loginFormSuccessText">Check your inbox for a link to reset your password.</div>
                <div>Link is valid for 24 hours.</div>
        </span>

        {/* <DefaultButton
          text="Continue Account Recovery"
          // onClick={() => navigate("/reset-password-confirm")}
          // disabled={!formik.dirty || !formik.isValid}
          // type="submit"
          // onRenderIcon={renderSpinner}
        /> */}
        
        <Link className="ln-nw-link" onClick={() => resendPasswordLink()}>
          {/*Didn't receive email? Resend now.*/}
        </Link>
      </Stack>
    </AuthLayoutPage>
  );
};

export default RequestSuccessPage;
