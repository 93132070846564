import * as React from "react";
import { ITextFieldProps } from "@fluentui/react/lib/TextField";
import { Callout } from "@fluentui/react/lib/Callout";
import { Stack, IStackTokens, IStackStyles} from "@fluentui/react/lib/Stack";
import { FontIcon } from "@fluentui/react";
import { colors } from "constants/constants";
import useId from "@mui/material/utils/useId";

const stackTokens: IStackTokens = {
	childrenGap: 4,
	// maxWidth: 300,
};

const labelCalloutStackStyles: Partial<IStackStyles> = {
	root: { padding: 10, paddingRight:60 },
};

export const PasswordWithRequirementInfo = (props: ITextFieldProps): JSX.Element => {
	// const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(
	//   false
	// );
	const [isCalloutVisible, toggleIsCalloutVisible] = React.useState(false);
	
	const descriptionId: string = useId("description");
	const iconButtonId: string = useId("iconButton");
	return (
		<>
			<Stack
				horizontal
				verticalAlign="center"
				tokens={stackTokens}
			>
				<label className={props.required ? "lbl-text required-Label" : "lbl-text"}>{props.label}</label>
				<FontIcon
					style={{
						cursor: "pointer",
						marginLeft: 10,
						color: colors.primaryBlue,
					}}
					color={colors.primaryBlue}
					id={iconButtonId}
					iconName="Info"
					onMouseEnter={() => toggleIsCalloutVisible(true)}
					onMouseLeave={() => toggleIsCalloutVisible(false)}
				/>
			</Stack>
			{isCalloutVisible && (
				<Callout
					target={"#" + iconButtonId}
					setInitialFocus
					onDismiss={() => toggleIsCalloutVisible(false)}
					ariaDescribedBy={descriptionId}
					role="alertdialog"
					className="inputFieldTooltip"
				>
					<Stack
						tokens={stackTokens}
						horizontalAlign="start"
						styles={labelCalloutStackStyles}
					>
						<ul className="dashed">
							<b>{props.id && props.id === "resetpassword" ?"You cannot reuse a previous password. ":""}Make sure your new password contains:</b>

							<li>
								between 10-30 characters
                            </li>
							<li>
								uppercase and lowercase letters
                            </li>
							<li>
								at least one number
                            </li>
							<li>
								at least one special character
                            </li>
						</ul>
					</Stack>
				</Callout>
			)}
		</>
	);
};