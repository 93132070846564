import React from "react";
import {
    DefaultPalette,
    IStackItemStyles,
    Stack,
} from "@fluentui/react";
import { Depths } from "@uifabric/fluent-theme";
import { LeftMenu } from "components/navigation/leftMenu/LeftMenu";
import {
    SearchBox,
    ISearchBoxStyles,
} from "@fluentui/react/lib/SearchBox";
import { sizes } from "constants/constants";

const nonShrinkingStackItemStyles: IStackItemStyles = {
    root: {
        background: DefaultPalette.white,
        // color: DefaultPalette.white,
        boxShadow: Depths.depth8,
        //boxShadow: '3px 0px 31px -1px rgba(0,0,0,0.75)',
        // border: '3px solid red',
        // display: 'flex',
        // alignItems: 'center',
        // //height: 50,
        // justifyContent: 'center',
        overflow: "hidden",
        width: sizes.DRAWER,
        // height: '200%',
        zIndex: 10,
        // position: 'absolute',
        position: "fixed",
        left: 0,
        height: "100%",
        // alignSelf: 'stretch'
    },
};

const searchBox: ISearchBoxStyles = {
    root: {
        margin: 10,
    },
    iconContainer: {
        marginLeft: -10,
    },
};

const Drawer = (props) => {
    return (
        <Stack
            className={`drawer ${props.showLeftNav && "mob-lft-slide-menu"}`}
            // align="stretch"
            // disableShrink
            styles={nonShrinkingStackItemStyles}
        >
            <div className='leftSticky'>
                <SearchBox placeholder="Search" underlined styles={searchBox} />
                <LeftMenu handleNavButton={props.handleNavButton} />
            </div>
        </Stack>
    );
};

export default Drawer;
