import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "./styles";
import { MaskedTextField, PrimaryButton, Link, MessageBar} from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
  handleGetSMSSettings,
  handleUpdateSMSSettings,
} from "store/shared/shared.action";


const TwilioSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const smsSettings = useSelector(
    (state: AppState) => state.shared.SMSSettings
  );
  const loading = useSelector(
    (state: AppState) => state.shared.loadingSMSSettings
  );

  const formik = useFormik({
    initialValues: {
      sid: "",
      token: "",
      fromPhone: "",
    },
    validationSchema: Yup.object({
      sid: Yup.string().required("Required"),
      token: Yup.string().required("Required"),
      fromPhone: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch<any>(handleUpdateSMSSettings(values));
    },
  });

  useEffect(() => {
    if (user.roles.includes("ROLE_SMS_TEXT")) {
      dispatch<any>(handleGetSMSSettings());
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smsSettings) {
      formik.setValues({
        ...formik.values,
        fromPhone: smsSettings.fromPhone.slice(2),
        sid: smsSettings.sid,
        token: smsSettings.token,
      });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsSettings]);

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>

      <Stack
        styles={styles.headerStyles}
        horizontal
        verticalAlign="start"
        horizontalAlign="end"
      >
        {/*<FontIcon iconName="Edit" className={styles.iconStyles} />*/}
          <MessageBar>
              Text messages depend on twilio service
              <Link className="ln-nw-link" href="https://www.twilio.com/" target="_blank">
                  Visit twilio website.
              </Link>
          </MessageBar>
      </Stack>
      <Stack tokens={styles.textFields} style={{ marginBottom: 25 }}>



        <TextField
          id="sid"
          name="sid"
          label="SID"
          value={formik.values.sid}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.touched.sid && formik.errors.sid
              ? formik.errors.sid.toString()
              : null
          }
        />
        <TextField
          id="token"
          name="token"
          label="Token"
          value={formik.values.token}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.touched.token && formik.errors.token
              ? formik.errors.token.toString()
              : null
          }
        />
          <MaskedTextField
              mask="(999)999-9999"
              id="fromPhone"
              name="fromPhone"
              label="From Phone"
              value={formik.values.fromPhone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                  formik.touched.fromPhone && formik.errors.fromPhone
                      ? formik.errors.fromPhone.toString()
                      : null
              }
          />
        {/*<TextField*/}
        {/*  id="fromPhone"*/}
        {/*  name="fromPhone"*/}
        {/*  label="From Phone"*/}
        {/*  value={formik.values.fromPhone}*/}
        {/*  onChange={formik.handleChange}*/}
        {/*  onBlur={formik.handleBlur}*/}
        {/*  errorMessage={*/}
        {/*    formik.touched.fromPhone && formik.errors.fromPhone*/}
        {/*      ? formik.errors.fromPhone.toString()*/}
        {/*      : null*/}
        {/*  }*/}
        {/*/>*/}
      </Stack>

      <Stack.Item>
        <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="end">
          <PrimaryButton
            onRenderIcon={renderSpinner}
            text="Save Changes"
            onClick={handleSubmit}
          />
        </Stack>
      </Stack.Item>
    </>
  );
};

export default TwilioSettings;
