import React, { useState, useEffect } from "react";
import { Stack, Link } from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useNavigate  } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { AddInventoryTrimDialog } from "components/dialogs/addInventoryDialog/AddInventoryTrimDialog";
import { AddInventoryFoundDialog } from "components/dialogs/addInventoryDialog/AddInventoryFoundDialog";
import { AddInventorySearchDialog } from "components/dialogs/addInventoryDialog/AddInventorySearchDialog";
import { AddInventoryDialog } from "components/dialogs/addInventoryDialog/AddInventoryDialog";
import { emplyeePermissionsAction, emplyeePermissionsScreen } from "constants/constants";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { getVehiclesStats } from "store/inventory/inventory.action";
import { isDefaultProfile } from "utils/permissions/permissionsHelpers";
import { currencyNegValFormat } from "utils/helperFunctions";
import { IdleTimerAutoReload } from "components/IdleTimer/IdleTimerAutoReload";
import { useMediaQuery } from "react-responsive";

const DefaultProfileDashboard = () => {

    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const navigate = useNavigate ();
    const [isOpen, setIsOpen] = useState(false);
    const [isInventorySearchDialog, setisInventorySearchDialog] = useState(false);
    const [isInventoryTriDialog, setisInventoryTriDialog] = useState(false);
    const [isInventoryFoundDialog, setisInventoryFoundDialog] = useState(false);
    const [vehicleID, setVehicleID] = useState('');
    const [trimValues, settrimValues] = React.useState([]);
    const [vinforTrim, setvinforTrim] = React.useState(null);
    const [ymmforTrim, setymmforTrim] = React.useState(null);
    const [stats, setStats] = React.useState({ countAppraisal: 0, countActive: 0, countSold: 0, countSoldMTB: 0 ,soldProfileMTD: 0});
    const [commonServerError, setcommonServerError] = React.useState(null);
    const isMobile = useMediaQuery({ maxWidth:576 });

    useEffect(() => {
        handleGetStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const handleGetStats = () => {
        dispatch<any>(getVehiclesStats((response) => {
            if (response) {
                setStats(response);
            }
            else {
                setcommonServerError('Failed to fetch Inventory data');
            }
        }));
    }

    const now = new Date();

    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // const handleViewWorkBookClick = () => {
    //     navigate('/appraisal');
    // };
    const handleSearchVehiclesClick = () => {
        navigate('/inventory');
    };
    //const handleAppraisalVehiclesClick = () => {
    //    navigate('/appraisal');
    //};
    //const handleActiveVehiclesClick = () => {
    //    navigate('/acquired');
    //};
    //const handleSoldVehiclesClick = () => {
    //    navigate('/sold');
    //};

    const redirectToWorkbook = (url, date) => {
        const  state = {
            filterFromDate: firstDay,
            filterToDate: lastDay,
            fromKeymetrics: 'key-metrics'
        }
        navigate(`/${url}`, {state});
    };

    const navigatetoAddVeh = () => {
        setisInventorySearchDialog(true)
    };

    const handleAddInventoryDialogDismiss = (vehicleId) => {
        setisInventorySearchDialog(false);
        if (vehicleId) {
            setVehicleID(vehicleId);
            setisInventoryFoundDialog(true);
        }
    }

    const openTrimDialog = ({ vin, trim, ymm }) => {
        resetVinYmm();
        setisInventorySearchDialog(false);
        settrimValues(trim);
        setisInventoryTriDialog(true);
        if (vin) {
            setvinforTrim(vin);
        } else {
            setymmforTrim(ymm);
        }
    };

    const resetVinYmm = () => {
        setvinforTrim(null);
        setymmforTrim(null);
    }

    const onDismiss = () => {
        setIsOpen(false);
    };

    return (
        user && user.profileType && isDefaultProfile(user.profileType) ? (
            <>
                <IdleTimerAutoReload onIdle={handleGetStats} />
                {isOpen ? <AddInventoryDialog onDismiss={onDismiss} /> : null}

                {isInventorySearchDialog ?
                    <AddInventorySearchDialog
                        onDismiss={(vehicleId) => handleAddInventoryDialogDismiss(vehicleId)}
                        openTrimDialog={openTrimDialog}
                    /> : null}


                {isInventoryTriDialog ?
                    <AddInventoryTrimDialog
                        onDismiss={() => setisInventoryTriDialog(false)}
                        trimValues={trimValues}
                        vin={vinforTrim}
                        ymm={ymmforTrim}
                        vehID={null}
                        orderStock={null}
                    />
                    : null}

                {isInventoryFoundDialog ?
                    <AddInventoryFoundDialog
                        onDismiss={() => setisInventoryFoundDialog(false)}
                        vehicleId={vehicleID}
                    />
                    : null}
                {/*  {user && user.roles && user.roles.includes('ROLE_MASTER') &&  */}
                <Stack
                    className="default-dashboard"
                    horizontalAlign="start">
                    {commonServerError ?
                        <div>
                            <Row>
                                <Col>
                                    <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                </Col>
                            </Row>
                        </div> : null
                    }
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                    >
                       {/* <CustomFontText className=" pageTitleFont pageName">Dashboard</CustomFontText>*/}
                    </Stack>
                    <Row style={{ width: "100%",marginTop:"15px" }}>
                        <Col>
                            <Stack tokens={{ childrenGap: "20" }}>
                                <Stack.Item>
                                    <Row style={{ textAlign: "center", alignItems: "flex-end" }}>
                                        <Col>
                                            <EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsAction.VEHICLE_ADD}
                                                roles={user.roles}
                                            >
                                                <Link className="ln-nw-link" onClick={navigatetoAddVeh} style={{ textAlign: "center" }}>
                                                    <img alt="Add Vehicle" src={`${require("assets/dashboard_addvehicle.png")}`} />
                                                    <span className="ln-text">Add a Vehicle</span>
                                                </Link>
                                            </EmployeeActionPermission>
                                        </Col>
                                        <Col>
                                            <EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.WORKBOOK_SHOP}
                                                roles={user.roles}
                                            >
                                                {/* <Link className="ln-nw-link" onClick={handleViewWorkBookClick} style={{ textAlign: "center" }}>
                                                <img alt="View Workbook" src={`${require("assets/dashboard_workbook.png")}`} />
                                                <span className="ln-text">View Workbook</span>
                                            </Link> */}
                                                <Link className="ln-nw-link" onClick={handleSearchVehiclesClick} style={{ textAlign: "center" }}>
                                                    <img alt="Search Vehicles" src={`${require("assets/Icon awesome-search.png")}`} />
                                                    <span className="ln-text">Search Vehicles</span>
                                                </Link>
                                            </EmployeeActionPermission>
                                        </Col>
                                    </Row>
                                </Stack.Item>
                                <Stack.Item>

                                    <Stack className="InventoryBox"
                                        horizontal
                                        verticalAlign="center"
                                        horizontalAlign="space-between"
                                    >
                                        <Stack.Item>
                                            <CustomFontText className=" pageTitleFont pageName" >Snapshot</CustomFontText>
                                        </Stack.Item>
                                        {
                                            isMobile ?
                                            <Stack.Item>
                                            <Row style={{ marginTop: "10px", marginBottom:"10px" }}>
                                                <Col onClick={() => redirectToWorkbook('appraisal','')} className="defaultDashboardGrid " >
                                                    <div style={{ paddingTop: "10px"}} className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countAppraisal} </h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinFields">Appraisals In-Progress</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col onClick={() => redirectToWorkbook('acquired','')}  className="defaultDashboardGrid ">
                                                    <div style={{ paddingTop: "10px"}} className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countActive} </h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinFields">Active Inventory</span>
                                                        </Link>
                                                    </div>
                                                </Col> 
                                                
                                            </Row>
                                            <Row style={{ marginTop: "10px", marginBottom:"10px" }}>
                                                
                                                <Col onClick={() => redirectToWorkbook('sold', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}))}  className="defaultDashboardGrid ">
                                                    <div style={{ paddingTop: "20px"}} className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countSoldMTB} </h2>  
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinFields">Sold MTD</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col onClick={() => redirectToWorkbook('sold', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}))}  className="defaultDashboardGrid ">
                                                    <div style={{ paddingTop: "20px"}} className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {currencyNegValFormat(stats.soldProfileMTD)}</h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinFields">Profit MTD</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Stack.Item>

                                            :
                                            <Stack.Item>
                                            <Row style={{ marginTop: "10px", marginBottom:"10px" }}>
                                                <Col onClick={() => redirectToWorkbook('appraisal','')} className="defaultDashboardGrid " >
                                                    <div className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countAppraisal} </h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinField">Appraisals In-Progress</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col onClick={() => redirectToWorkbook('acquired','')}  className="defaultDashboardGrid ">
                                                    <div className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countActive} </h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinField">Active Inventory</span>
                                                        </Link>
                                                    </div>
                                                </Col> 
                                                <Col onClick={() => redirectToWorkbook('sold', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}))}  className="defaultDashboardGrid ">
                                                    <div className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {stats.countSoldMTB} </h2>  
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinField">Sold MTD</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col  onClick={() => redirectToWorkbook('sold', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}))} className="defaultDashboardGrid ">
                                                    <div className=" InventorySubBox">
                                                        <h2 className="InventoryStatusLabel"> {currencyNegValFormat(stats.soldProfileMTD)}</h2>
                                                        <Link className="ln-nw-link" style={{ textAlign: "center" }}>
                                                            <span className="ln-textinField">Profit MTD</span>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Stack.Item>

                                        }
                                        
                                    </Stack>

                                </Stack.Item>
                                <Stack.Item>
                                    <Stack className="contentBox">
                                        <Stack.Item>
                                            <span className="dashboard-box-title">Support</span>
                                        </Stack.Item>

                                        <Stack.Item>
                                            <span className="dashboard-box-content">
                                                For any questions, contact your FiQore Team:<br /> QoreSupport@FiQore.com (515) 498-4550
                                                    </span>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>


                            </Stack>
                        </Col>

                    </Row>
                </Stack>

            </>
        ) : null
    );
};

export default DefaultProfileDashboard;

