import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { permissionsModules } from "constants/constants";
import { hiddenStyle } from "constants/styles";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { IPermission } from "types/groupPermissionTypes";

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};

interface Props {
  onSet?: (action: string, state: boolean) => void;
  modulePermissions: IPermission[];
}

export const GroupsTableDropdown: React.FunctionComponent<Props> = ({
  onSet,
  modulePermissions,
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const menuItems: IContextualMenuItem[] = [
    {
      key: "setView",
      text: "Set View",
      subMenuProps: {
        items: [
          {
            key: "activeView",
            text: "Active",
            onClick: () => onSet("view", true),
          },
          {
            key: "inactiveView",
            text: "Inactive",
            onClick: () => onSet("view", false),
          },
        ],
      },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Auth,
        user.roles
      )
        ? hiddenStyle
        : null,
    },
    {
      key: "setAdd",
      text: "Set Add",
      subMenuProps: {
        items: [
          {
            key: "activeAdd",
            text: "Active",
            onClick: () => onSet("add", true),
          },
          {
            key: "inactiveAdd",
            text: "Inactive",
            onClick: () => onSet("add", false),
          },
        ],
      },
      style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Auth,
          user.roles
      )
          ? hiddenStyle
          : null,
    },
    {
      key: "setUpdate",
      text: "Set Update",
      subMenuProps: {
        items: [
          {
            key: "activeUpdate",
            text: "Active",
            onClick: () => onSet("update", true),
          },
          {
            key: "inactiveUpdate",
            text: "Inactive",
            onClick: () => onSet("update", false),
          },
        ],
      },
      style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Auth,
          user.roles
      )
          ? hiddenStyle
          : null,
    },
    {
      key: "setDelete",
      text: "Set Delete",
      subMenuProps: {
        items: [
          {
            key: "activeDelete",
            text: "Active",
            onClick: () => onSet("delete", true),
          },
          {
            key: "inactiveDelete",
            text: "Inactive",
            onClick: () => onSet("delete", false),
          },
        ],
      },
      style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Auth,
          user.roles
      )
          ? hiddenStyle
          : null,
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
    useTargetAsMinWidth: true,
    // useTargetWidth: true,
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};
