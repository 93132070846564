import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  FontIcon,
  ActionButton,
  IButtonStyles,
  Modal,
  IconButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
  Image,
  ImageFit,
} from "@fluentui/react";
import { Pagination } from "components/table/pagination/Pagination";
import { connect } from "react-redux";

import { /**Image, ImageFit, */ mergeStyles } from "@fluentui/react";
import { IInventory } from "types/inventoryTypes";
// import Loader from "../../loader/Loader";
import { EditLocationDialog } from "../locations/EditLocationDialog";
import { ViewInventoryDialog } from "../viewInventoryDialog/ViewInventoryDialog";

import {
  HANDLE_DELETE_INVENTORY,
  HANDLE_GET_INVENTORY,
  HANDLE_SORT_INVENTORY_TABLE,
  HANDLE_UPDATE_INVENTORY,
  SET_ROWS_PER_PAGE_INVENTORY,
} from "store/inventory/inventory.types";

import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as contentStyle,
  searchStyle as searchStyles,
  cellLineHeight,
} from "constants/styles";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { sortColumns } from "utils/helperFunctions";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";

const searchStyle = searchStyles();
const contentStyles = contentStyle(1080, "auto");

const cellMaxHeight: string = "35px";
const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};
const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
  },
};

const textStyles = mergeStyles({
  fontSize: 14,
});

//
// const statuses = [
//     "active",
//     "pending",
//     "new",
//     "unassigned",
//     "dead"
// ]

export interface IDocument {
  id: number;
  key: string;
  photoUrls: string[];
  make: string;
  model: string;
  stock: string;
  year: string;
  vin: string;
  status: number;
  isFavourite: boolean;
  createdAt: string;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 5,
  },
};

const cellStyles: IStackStyles = {
  root: {
    lineHeight: cellLineHeight,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

class AddInventoryToEmailDialog extends React.Component<any, any> {
  private readonly _items: any;
  private readonly _selection: Selection;

  // private _contextMenuRef: any;

  constructor(props: {}) {
    super(props);

    this._items = [];

    const columns: any[] = [
      {
        key: "photo",
        name: "Photo",
        fieldName: "photoUrls",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        onRender: (item: IDocument) => {
          return (
            <Image
              src={
                item.photoUrls[0]
                  ? `${process.env.REACT_APP_IMAGE_SERVER}/${item.photoUrls[0]}`
                  : `${require("assets/image-placeholder.jpg")}`
              }
              width={100}
              height={cellLineHeight}
              imageFit={ImageFit.cover}
            />
          );
        },
        isPadded: false,
      },
      {
        key: "make",
        name: "Make",
        fieldName: "make",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "string",
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.make}</span>;
        },
        isPadded: false,
      },
      {
        key: "model",
        name: "Model",
        fieldName: "model",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.model}</span>;
        },
        isPadded: false,
      },
      {
        key: "year",
        name: "Year",
        fieldName: "year",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.model}</span>;
        },
        // onColumnClick: _onColumnClick,
        isPadded: false,
      },
      {
        key: "stock",
        name: "Stock",
        fieldName: "stock",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.stock}</span>;
        },
      },
      {
        key: "vin",
        name: "VIN",
        fieldName: "vin",
        minWidth: 130,
        maxWidth: 130,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.vin}</span>;
        },
      },
      {
        key: "isFavourite",
        name: "Favorites",
        fieldName: "isFavourite",
        minWidth: 60,
        maxWidth: 60,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          let iconName;
          let iconHoveredName;

          if (item.isFavourite) {
            iconName = "FavoriteStarFill";
            iconHoveredName = "Unfavorite";
          } else {
            iconName = "AddFavorite";
            iconHoveredName = "FavoriteStarFill";
          }

          return (
            <FontIcon
              onClick={() => this._handleUpdateFavourites(item)}
              onMouseEnter={() => {
                this.setState({
                  iconHovered: item.id,
                  columns: [...this.state.columns],
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  iconHovered: null,
                  columns: [...this.state.columns],
                });
              }}
              style={{ fontSize: 22, color: DefaultPalette.themePrimary }}
              iconName={
                this.state.iconHovered === item.id ? iconHoveredName : iconName
              }
            />
          );

          //return <span className={textStyles}>ICON</span>;
        },
        isPadded: false,
      },

      {
        key: "action",
        name: "Actions",
        fieldName: "action",
        minWidth: 100,
        maxWidth: 100,

        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: any) => {
          return (
            <>
              {/*<div id={`item_${item.id}`} />*/}
              <Stack
                horizontal
                horizontalAlign="start"
                style={{
                  width: "100%",
                  display: this.state.hovered === item.id ? "flex" : "none",
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  //tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    horizontal
                    //tokens={{ childrenGap: 5 }}
                    onClick={() => this._handleView(item.id)}
                  >
                    <ActionButton
                      style={{ padding: 0 }}
                      styles={actionButton}
                      // onClick={() => this._handleView(item.id)}
                      iconProps={{ iconName: "Car" }}
                    >
                      View
                    </ActionButton>
                  </Stack>
                </Stack>
              </Stack>
            </>
          );
        },
        isPadded: false,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._items,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      iconHovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      currentPage: 1,
      isEditDialogOpen: false,
      isViewDialogOpen: false,
      inventoryId: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      itemData: null,
      mounted: false,
    };
  }

  public componentDidMount() {
    this.props.handleGetInventory({
      page: 1,
      limit: this.props.rowsPerPage,
      column: this.props.sortColumn,
      sort: this.props.sort,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }

    if (
      !this.state.mounted &&
      this.props.selectedInventories &&
      this.props.items
    ) {
      for (const inv of this.props.selectedInventories) {
        this._selection.setIndexSelected(
          this.props.items.findIndex((item: any) => inv.id === item.id),
          true,
          false
        );
      }
      this.setState({ mounted: true });
    }
  }

  public clearSelection = () => {
    this._selection.setAllSelected(false);
  };

  private _handleUpdateFavourites(item) {
    const newItem = { ...item, isFavourite: !item.isFavourite };
    this.props.handleUpdateInventory([newItem]);
  }

  private _closeEditLocationDialog() {
    this.props.handleGetLocations({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
    });
    this.setState({
      isEditDialogOpen: false,
    });
  }

  private _handleViewDismiss() {
    this.setState({
      isViewDialogOpen: false,
      inventoryId: null,
      itemData: null,
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    this.props.handleGetInventoryList({
      page: parseInt(this.props.currentPage),
      limit: this.props.rowsPerPage,
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      column: currColumn.fieldName,
    });

    // set sort
    this.props.handleSortTable({
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      sortColumn: currColumn.fieldName,
    });

    this.setState({
      columns: [...sortColumns(newColumns, currColumn)],
    });
  }

  //                     =============ACTIONS!! ========================================

  private _handleView(id: number) {
    // console.log(id);
    // console.log("handleView");
    // console.log(this.props.items)
    const item = this.props.items.find((item: IInventory) => item.id === id);

    this.setState({
      isViewDialogOpen: true,
      inventoryId: id,
      itemData: item,
    });

    // this._closeEditLocationDialog();
  }

  private _handleEdit(id: number) {
    this.setState({
      inventoryId: id,
      isEditDialogOpen: true,
    });
  }

  private _handleCustomAction(id: number) {
    console.log("CUSTOM ACTION", id);

    this._closeEditLocationDialog();
  }

  private _handleFavouritesList(id: number) {
    console.log(id);

    this._closeEditLocationDialog();
  }

  private _handleSetStatus(id: number) {
    console.log(id);
    this._closeEditLocationDialog();
  }

  private async _handleDelete(id?: number) {
    // console.log(this._selection.getSelection());
    // get all items from selection and then delete each separately
    await this._selection
      .getSelection()
      .forEach((element: any) => this.props.handleDeleteLocation(element.id));

    //TODO: errors need to be managed
    this._selection.setAllSelected(false);
    this.setState({ isConfirmBoxOpen: false });
    this._closeEditLocationDialog();
  }

  //                     =============ACTIONS END!! ========================================

  // private handleHeaderDropDownChange(e, item) {
  //   this.setState({
  //     selectedInHeaderDropDown: item,
  //   });
  // }
  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    this.props.setRowsPerPage(rowsOnPage);
    this.props.handleGetInventory({
      page: this.props.currentPage,
      limit: rowsOnPage,
    });
  }

  private _handlePageChange(page: number) {
    this.props.handleGetInventory({
      page: page,
      limit: this.props.rowsPerPage,
    });
  }

  private _renderSpinner = () => {
    return this.props.loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  private handleAddInventoryToEmail = () => {
    this.props.onInventorySelection(
      this._selection.getSelection().map((item: any) => {
        return { id: item.id, stock: item.stock };
      })
    );

    this.props.onDismiss();
  };

  //               ===Pagination sector END =====
  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <Modal
        isOpen={true}
        onDismiss={this.props.onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          {/*<CustomFontText>Add Vehicle To Deal</CustomFontText>*/}
          <GoBackToMainDialogBtn
            label="Add Inventory to Email"
            onClick={this.props.onDismiss}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={this.props.onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Fabric>
            <div style={{ backgroundColor: "white" }}>
              {/*<Loader show={updateLoading || deleteLoading} />*/}
              <EditLocationDialog
                locationId={this.state.locationId}
                isOpen={this.state.isEditDialogOpen}
                onDismiss={this._closeEditLocationDialog.bind(this)}
              />

              {this.state.itemData && this.state.isViewDialogOpen && (
                <ViewInventoryDialog
                  onDismiss={this._handleViewDismiss.bind(this)}
                  itemData={this.state.itemData}
                />
              )}
              <DetailsList
                styles={tableStyles}
                items={this.props.items ? this.props.items : []}
                columns={columns}
                onRenderDetailsFooter={() => (
                  <Pagination
                    rowsPerPage={this.props.rowsPerPage}
                    currentPage={this.props.currentPage}
                    totalItems={this.props.totalItems}
                    onRowsNumberChange={this._handleRowsNumberChange.bind(this)}
                    onPageChange={this._handlePageChange.bind(this)}
                  />
                )}
                onRenderDetailsHeader={(props, defaultRender) => (
                  <>
                    <Stack
                      tokens={stackTokens}
                      styles={stackStyles}
                      horizontal
                      verticalAlign="center"
                      horizontalAlign="space-between"
                    >
                      <p
                        style={{
                          color: DefaultPalette.themePrimary,
                          width: 100,
                        }}
                      >
                        {selectionDetails.length === 0
                          ? "No items selected"
                          : selectionDetails.length + " items selected"}
                      </p>
                      <SearchBox
                        className={searchStyle}
                        placeholder="search in table ..."
                        iconProps={{
                          styles: { root: { color: DefaultPalette.black } },
                        }}
                        onSearch={(newValue) =>
                          console.log("value is: " + newValue)
                        }
                      />
                    </Stack>
                    <span>{defaultRender(props)}</span>
                  </>
                )}
                // onRenderCheckbox={(props, defaultRender) => {
                //     console.log(props)
                //     console.log("propsporps")
                //     return (
                //         <Stack styles={cellStyles} verticalAlign='center' >
                //             {defaultRender(props)}
                //         </Stack>
                //     );
                // }}

                onRenderRow={(props, defaultRender) => {
                  return (
                    <Stack
                      styles={cellStyles}
                      className="centered"
                      verticalAlign="center"
                    >
                      <div
                        onMouseEnter={() => {
                          this.setState({ hovered: props.item.id });
                        }}
                        onMouseLeave={() => this.setState({ hovered: null })}
                      >
                        {defaultRender(props)}
                      </div>
                    </Stack>
                  );
                }}
                // onRenderCheckbox={(props, defaultRender) => {
                //     return <span>{defaultRender(props)}</span>;
                // }}
                selection={this._selection}
                selectionMode={SelectionMode.multiple}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onColumnHeaderClick={(ev, column) =>
                  this._onColumnClick(ev, column)
                }
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={() => {}}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
              />
            </div>
          </Fabric>
        </div>

        <div className={contentStyles.footer}>
          <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
            <DefaultButton text="Close" onClick={this.props.onDismiss} />
            <PrimaryButton
              disabled={this._selection.getSelection().length === 0}
              onRenderIcon={this._renderSpinner}
              text="Add Inventory to Email"
              onClick={this.handleAddInventoryToEmail.bind(this)}
            />
          </Stack>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    items: state.inventory.items?.results,
    totalItems: state.inventory.items?.totalItems,
    currentPage: state.inventory.items?.currentPage,
    rowsPerPage: state.inventory?.rowsPerPageInventory,
    sortColumn: state.inventory?.sortColumn,
    sort: state.inventory?.sort,
    // updateLoading: state.inventory.updateLoading,
    // deleteLoading: state.inventory.deleteLoading,
    loading: state.inventory.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetInventory: (payload) =>
      dispatch({ type: HANDLE_GET_INVENTORY, payload }),
    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_INVENTORY, payload }),
    handleDeleteInventory: (ids, callback?) =>
      dispatch({ type: HANDLE_DELETE_INVENTORY, payload: { ids, callback } }),
    handleUpdateInventory: (payload) =>
      dispatch({
        type: HANDLE_UPDATE_INVENTORY,
        payload,
      }),
    handleSortTable: (payload) =>
      dispatch({
        type: HANDLE_SORT_INVENTORY_TABLE,
        payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInventoryToEmailDialog);
