import { takeLeading, takeEvery, put, select, call } from "redux-saga/effects";
import {
  HANDLE_ADD_INVENTORY_PHOTO,
  HANDLE_CREATE_INVENTORY,
  HANDLE_UPLOAD_INVENTORY,
  HANDLE_DELETE_INVENTORY,
  HANDLE_DELETE_INVENTORY_PHOTO,
  HANDLE_GET_INVENTORY,
  HANDLE_UPDATE_INVENTORY,
  HANDLE_VIEW_INVENTORY,
  HANDLE_PDF_INFO_INVENTORY,
  HANDLE_PDF_GUIDE_INVENTORY,
  HANDLE_EXPORT_INVENTORY,
  HANDLE_EDIT_INVENTORY,
  HANDLE_GET_VALUATION_INFORMATION,
  HandleAddInventoryPhoto,
  HandleCreateInventory,
  HandleDeleteInventory,
  HandleDeleteInventoryPhoto,
  HandleGetInventory,
  HandleUploadInventories,
  HandleUpdateInventory,
  HandleViewInventory,
  HandlePDFInfoInventory,
  HandlePDFGuideInventory,
  HandleExportInventory,
  HandleGetFromVin,
  HANDLE_GET_FROM_VIN,
  HandleEditInventory,
  HandleGetValuationInformation,
  HANDLE_SAVE_INVENTORY_FILTER,
  HandleSaveFilterInventory,
  HANDLE_DELETE_INVENTORY_FILTER,
  HandleDeleteFilterInventory,
  HANDLE_GET_INVENTORY_FILTER_LIST,
  HANDLE_GET_SHOP_WORKBOOK_LIST,
  HANDLE_GET_PURCHASE_WORKBOOK_LIST,
  HANDLE_GET_SOLD_WORKBOOK_LIST,
  HANDLE_GET_TRIM_VIN,
  HANDLE_GET_Vehicle_ID,
  HANDLE_SAVE_SOURCE_WORKBOOK,
  HANDLE_GET_SOURCE_WORKBOOK,
  HANDLE_GET_LIVE_RETAIL_REPORTS,
  HANDLE_PUT_LIVE_RETAIL_REPORTS,
  HANDLE_GET_QVALUE_DROPDOWN_MASTER,
  HANDLE_SAVE_QVALUES,
  HANDLE_GET_QVALUATIONS,
  HANDLE_GET_TRIM_YMM,
  HANDLE_GET_VEH_DETAILS,
  HANDLE_PUT_VEH_DETAILS,
  HANDLE_GET_ADJUSTER_LIST,
  HANDLE_PUT_ADJUSTER_LIST,
  HANDLE_GET_VEH_SELLER_DETAILS,
  HANDLE_GET_SELECTED_FILTER_DATA,
  HANDLE_VEHICLE_STATUS,
  HANDLE_PURCHASE_VEHICLE,
  HANDLE_SELL_VEHICLE,
  HANDLE_SAVE_VEHICLE_EXPENSES,
  HANDLE_GET_VEHICLE_EXPENSES,
  HANDLE_GET_VEHICLES,
  HandleGetInventoryFilterList,
  handleGetShopWorkBookList,
  handleGetPurchaseWorkBookList,
  handleGetSoldWorkBookList,
  handleGetTrimUsingYmm,
  handleGetVehDetails,
  handleGetAdjusterList,
  handlePutAdjusterList,
  handlePutVehDetails,
  handleGetVehSellerDetails,
  handleGetTrimUsingVin,
  handleGetVehicleUsingID,
  handleSaveSourceWorkBook,
  handleGetSourceWorkBook,
  handleGetLiveRetailReports,
  handlePutLiveRetailReports,
  handleGetQValueDropdownMaster,
  handleSaveQValues,
  handleGetQValuations,
  HandleVehicleStatus,
  HandlePurchaseVehicle,
  HandleSellVehicle,
  handleSaveVehicleExpenses,
  handleGetVehicleExpenses,
  HANDLE_SAVE_Pricing_ID,
  HANDLE_GET_Pricing_ID,
  HANDLE_Pricing_Categories_ID,
  handleGetPricingUsingID,
  handleSavePricingUsingID,
  handlePricingCategoriesID,
  HANDLE_GET_QVALUE_PLOT_ID,
  handleGetQValuePlotUsingID,
  HandleManageFavoriteVehicle,
  HANDLE_MANAGE_FAVORITE_VEHICLE,
  HANDLE_DELETE_EXPENSE,
  HandleDeleteExpense,
  GET_VEHICLES_STATS,
  GetVehiclesStats,
  HANDLE_GET_QVALUE_NOTES_ID,
  handleGetQValueNotesUsingID,
  HANDLE_SAVE_QVALUE_NOTES_ID,
  HandleVehicleUpdate,
  HANDLE_VEHICLE_UPDATE,
  HANDLE_PURCHASE_VEHICLE_UNDO,
  HandlePurchaseVehicleUndo,
  HANDLE_SELL_VEHICLE_UNDO,
  HandleSellVehicleUndo,
  HandleVehicleLiveRetailFilters,
  HANDLE_VEHICLE_LIVE_RETAIL_FILTERS,
  HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS,
  handleGetFilterLiveRetailReports
} from "./inventory.types";
import {
  // handleGetInventoryList,
  pendingGetInventoryList,
  cleanInventoryFilters,
  rejectedGetInventoryList,
  rejectedSaveInventoryList,
  resolvedGetInventoryList,
  // handleUpdateInventory,
  pendingUpdateInventory,
  rejectedUpdateInventory,
  resolvedUpdateInventory,
  // handleDeleteInventory,
  pendingDeleteInventory,
  rejectedDeleteInventory,
  resolvedDeleteInventory,
  // handleViewInventory,
  pendingViewInventory,
  rejectedViewInventory,
  resolvedViewInventory,
  rejectedPDFInfoInventory,
  rejectedPDFGuideInventory,
  pendingCreateInventory,
  rejectedCreateInventory,
  resolvedCreateInventory,
  // handleAddPhotoInventory,
  // handleDeletePhotoInventory,
  pendingAddPhotoInventory,
  pendingDeletePhotoInventory,
  rejectedAddPhotoInventory,
  rejectedDeletePhotoInventory,
  /*   resolvedAddPhotoInventory,
      resolvedDeletePhotoInventory*/
  pendingEditInventory,
  resolvedEditInventory,
  rejectedEditInventory,
  pendingGetFromVin,
  resolvedGetFromVin,
  rejectedGetFromVin,
  pendingGetValuationInformation,
  rejectedGetValuationInformation,
  resolvedGetValuationInformationn,
  setInventoryFilters,
  resolvedGetInventoryFilterList,
  resolvedShopWorkbookList,
  resolvedPurchaseWorkbookList,
  resolvedSoldWorkbookList,
  // resolvedGetTrimValue,
  handleGetVehDetailsSuccess,
  resolvedGetVehicles,
  rejectedGetVehicles,
  pendingGetVehicles,
  rejectedManageFavoriteVehicle,
} from "./inventory.action";
import {
  getInventoryList,
  saveInventoryFilter,
  updateInventory,
  deleteInventory,
  createInventory,
  viewInventory,
  printInventoryInfoPDF,
  printInventoryGuidePDF,
  exportInvenotory,
  addPhoto,
  addVideo,
  uploadInventories,
  deletePhoto,
  deleteVideo,
  getDataFromVin,
  editInventory,
  getValuationInformation,
  deleteInventoryFilter,
  getInventoryFilterList,
  getShopWorkBookList,
  getPurchaseWorkBookList,
  getSoldWorkBookList,
  getInventoryTrimYMM,
  getInventoryVehDetails,
  getInventoryAdjusterList,
  putInventoryAdjusterList,
  putInventoryVehDetails,
  getInventoryVehSellerDetails,
  getInventoryTrimVIN,
  getSourceWorkBook,
  saveSourceWorkBook,
  getLiveRetailReports,
  putLiveRetailReports,
  getInventoryVehByID,
  getInventorySelectedFilterContent,
  getValuationsNadaStyles,
  getValuationsKBBStyles,
  getValuationsBlackBookStyles,
  putValuationsNada,
  putValuationsKBB,
  putValuationsBlackBook,
  getValuations,
  archiveVehicle,
  activateVehicle,
  purchaseVehicle,
  sellVehicle,
  saveVehicleExpenses,
  getVehicleExpenses,
  getInventoryPricingCategory,
  getInventoryPricing,
  saveInventoryPricing,
  getInventoryQvaluePlot,
  getVehicles,
  deleteExpense,
  getVehicleStats,
  manageFavoriteInventory,
  getInventoryQvalueNotes,
  saveInventoryQvalueNotes,
  updateVehicle,
  purchaseVehicleUndo,
  sellVehicleUndo,
  vehicleLiveRetailFilter,
  getFilterLiveRetailReports
} from "./inventory.api";
// import {IInventory} from 'types/inventoryTypes'
import { AppState } from "../index";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action"; 
import { saveAppraisal, viewAppraisal } from "../dealTracker/dealTracker.api";
import { handleSelectedTradeIn } from "../dealTracker/dealTracker.action";
import vinMapping from "./vinMapping.json";

import { stringFromCurrencyFormat} from "utils/helperFunctions";
import { initialFilterState } from "./inventory.reducer";
import { showSnackbar } from "store/notifier/notifier.action";
import { NotificationType } from "types/notificationType";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { handleViewDealTracker } from "store/dealTracker/dealTracker.action";
import { trimTypes } from "constants/constants";

/** SHARED FUNCTIONS*/

export function* callBackgroundGetInventory() {
  const rowsPerPage = yield select(
    (state: AppState) => state.inventory.rowsPerPageInventory
  );
  const page = yield select(
    (state: AppState) => state.inventory.items.currentPage
  );
  const sort = yield select((state: AppState) => state.inventory.sort);

  const sortColumn = yield select(
    (state: AppState) => state.inventory.sortColumn
  );
  

  const getInventoryAction: HandleGetInventory = {
    payload: { limit: rowsPerPage, page, sort, column: sortColumn },
    type: HANDLE_GET_INVENTORY,
  };
  yield call(getInventorySaga, getInventoryAction);
}

/** ======================= */
function* getInventorySaga(action: HandleGetInventory) {
  
  yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());


  try {
    const filters = yield select((state: AppState) => state.inventory.filters);

    const newFilters = {...filters}

    newFilters.minPrice = Boolean(stringFromCurrencyFormat(filters.minPrice)) ? stringFromCurrencyFormat(filters.minPrice): null;
    newFilters.maxPrice = Boolean(stringFromCurrencyFormat(filters.maxPrice)) ? stringFromCurrencyFormat(filters.maxPrice): null ;


    const { data, error } = yield getInventoryList(action.payload, newFilters);

    if (error) {
      yield put(rejectedGetInventoryList(error));
      return;
    }
    //console.log("===SAGA GET ALL INVENTORY ITEMS =============", data);

    yield put(resolvedGetInventoryList(data));
  } catch (error) {
    yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));
}


// get Inventory Filter List
function* getInventoryFilterListSaga(action: HandleGetInventoryFilterList) {
  
  // yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());

  try {
    const { dataFilter, errorFilter } = yield getInventoryFilterList(action.payload);
      
      if (dataFilter) {
        yield put(resolvedGetInventoryFilterList(dataFilter.results));
      }
      if (errorFilter) {
        yield put(rejectedSaveInventoryList(errorFilter));
        return;
      }
      return;
  } catch (error) {
    yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));
}

// for getting all vehicles 
function* getVehiclesSaga() {
    yield put(pendingGetVehicles());
    yield put(switchGlobalLoader(true));

    try {
        const { dataFilter, errorFilter } = yield getVehicles();

        if (dataFilter) {
            yield put(resolvedGetVehicles(dataFilter.results));
        }
        if (errorFilter) {
             yield put(rejectedGetVehicles(errorFilter));
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(rejectedGetVehicles(error));
    }
    yield put(switchGlobalLoader(false));

}

// for getting Workbook list
function* getShopWorkBookListSaga(action: handleGetShopWorkBookList) {
  
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getShopWorkBookList(action.payload);
      
      if (dataFilter) {
        yield put(resolvedShopWorkbookList(dataFilter.results));
      }
      if (errorFilter) {
          // yield put(showSnackbar(NotificationType.error, 'Failed to fetch TRIM Data'));
          yield put(switchGlobalLoader(false));
        return;
      }
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));

}

// for getting purchase Workbook list
function* getPurchaseWorkBookListSaga(action: handleGetPurchaseWorkBookList) {

    yield put(switchGlobalLoader(true));

    try {
        const { dataFilter, errorFilter } = yield getPurchaseWorkBookList();

        if (dataFilter) {
            yield put(resolvedPurchaseWorkbookList(dataFilter.results));
        }
        if (errorFilter) {
            yield put(rejectedGetVehicles(errorFilter));
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(rejectedGetVehicles(error));
    }
    yield put(switchGlobalLoader(false));

}

// for getting sold Workbook list
function* getSoldWorkBookListSaga(action: handleGetSoldWorkBookList) {

    yield put(switchGlobalLoader(true));

    try {
        const { dataFilter, errorFilter } = yield getSoldWorkBookList();

        if (dataFilter) {
            yield put(resolvedSoldWorkbookList(dataFilter.results));
        }
        if (errorFilter) {
            // yield put(showSnackbar(NotificationType.error, 'Failed to fetch TRIM Data'));
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
    }
    yield put(switchGlobalLoader(false));

}

function* getInventoryTrimVINSaga(action: handleGetTrimUsingVin) { 
  yield put(switchGlobalLoader(true));

    try {
        const uP = yield select(
            (state: AppState) => state.userProfile.userProfile
        );
        const selectedDeal = yield select((state: AppState) => state.dealTracker.selectedDealRecord);
        const isKarlProfileType = isKarlProfile(uP.profileType);
        
        const { dataFilter, errorFilter } = yield getInventoryTrimVIN(action.payload.vin, isKarlProfileType ? selectedDeal.id : 0, isKarlProfileType, action.payload.trimType);
      
    if (dataFilter) {
      // yield put(resolvedGetTrimValue(dataFilter.results));
      if (isKarlProfileType && selectedDeal.id) {
        yield put(handleViewDealTracker(selectedDeal.id));
      }
      action.payload.callback(dataFilter);
    }

    if (errorFilter) {
        if (action.payload.trimType === trimTypes.tradeIn || action.payload.trimType === trimTypes.karl) {
            action.payload.callback(errorFilter);
        } else {
            let errorObj = errorFilter;
            if (errorFilter.data && Number.isInteger(errorFilter.data)) {
                const { dataFilter } = yield getInventoryVehByID(errorFilter.data);
                if (!dataFilter) {
                    errorObj.status = 500;
                } else {
                    yield put(handleGetVehDetailsSuccess(dataFilter));
                }
            }
            action.payload.callback(errorObj);
        }
        
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* getInventoryVehByIDSaga(action: handleGetVehicleUsingID) {
  const flag = window.location.pathname.endsWith('inventory'); 
     if (!flag)
        yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getInventoryVehByID(action.payload.id);
      console.log('dataFilter', dataFilter)
    if (dataFilter) {
        if (!dataFilter.vin)
            dataFilter["vin"] = '';
        if (!dataFilter.stockNumber)
            dataFilter["stockNumber"] = '';
        if (!dataFilter.location)
            dataFilter["location"] = null;
        if (!dataFilter.appraisedBy)
            dataFilter["appraisedBy"] = null;
      yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
        action.payload.callback(false);
        if (!flag)
            yield put(switchGlobalLoader(false));
      return;
      }
      if (!flag)
        yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
      if (!flag)
        yield put(switchGlobalLoader(false));
    }
    if (!flag)
        yield put(switchGlobalLoader(false));

}

function* getInventoryCategoryIDSaga(action: handlePricingCategoriesID) {
  yield put(switchGlobalLoader(true));
  try {
    const { dataFilter, errorFilter } = yield getInventoryPricingCategory();
      console.log('PRICING CATEGORY LIST', dataFilter)
    if (dataFilter) {
      action.payload.callback(dataFilter);
      // action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* getInventoryPricingVehByIDSaga(action: handleGetPricingUsingID) {
  yield put(switchGlobalLoader(true));
  try {
    const { dataFilter, errorFilter } = yield getInventoryPricing(action.payload.id);
      console.log('PRICING PAGE LIST', dataFilter)
    if (dataFilter) {
      action.payload.callback(dataFilter);
      // action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* saveInventoryVehByIDSaga(action: handleSavePricingUsingID) {
  yield put(switchGlobalLoader(true));
  try {
    let vehId = action.payload.vehInfo.id;
    const { dataFilter, errorFilter } = yield saveInventoryPricing(action.payload.vehInfo);
      let savedData = dataFilter;
    if (savedData) {
      yield put(handleGetVehDetailsSuccess(savedData.vehicle))
      const { dataFilter, errorFilter } = yield getInventoryPricing(vehId);
      if (dataFilter) {
        action.payload.callback(dataFilter);
      }
      if (errorFilter) {
        action.payload.callback(false);
        yield put(switchGlobalLoader(false));
        return;
      }
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* getInventoryQValuePlotSaga(action: handleGetQValuePlotUsingID) {
  yield put(switchGlobalLoader(true));
  try {
    const { dataFilter, errorFilter } = yield getInventoryQvaluePlot(action.payload.vehId);
    if (dataFilter) {
      console.log('PLOT DATA response', dataFilter)
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* getInventoryQValueNotesSaga(action: handleGetQValueNotesUsingID) {
  yield put(switchGlobalLoader(true));
  try {
    const { dataFilter, errorFilter } = yield getInventoryQvalueNotes(action.payload.vehId);
    if (dataFilter) {
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* saveInventoryQValueNotesSaga(action: handleGetQValueNotesUsingID) {
  yield put(switchGlobalLoader(true));
  try {
    const { dataFilter, errorFilter } = yield saveInventoryQvalueNotes(action.payload.vehId, action.payload.details);
    if (dataFilter) {
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* saveSourceWorkBookSaga(action: handleSaveSourceWorkBook) { 
  yield put(switchGlobalLoader(true));

  try {
      
    const { dataFilter, errorFilter } = yield saveSourceWorkBook(action.payload.sourceData);
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* getSourceWorkBookSaga(action: handleGetSourceWorkBook) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getSourceWorkBook(action.payload.id);
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      //  action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* saveVehicleExpensesSaga(action: handleSaveVehicleExpenses) {
    yield put(switchGlobalLoader(true));
    
    try {
        const { dataFilter, errorFilter } = yield saveVehicleExpenses(action.payload.expenses);
        if (errorFilter) {
            action.payload.callback(errorFilter);
            yield put(switchGlobalLoader(false));
            return;
        }
        action.payload.callback(dataFilter);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

function* purchaseVehicleUndoSaga(action: HandlePurchaseVehicleUndo) {

    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield purchaseVehicleUndo(id);
        callback(data);

          const { dataFilter } = yield getInventoryVehByID(id);
          if (dataFilter) {
                yield put(handleGetVehDetailsSuccess(dataFilter));
          }

          const getPurchaseAction: handleGetPurchaseWorkBookList = {
            type: HANDLE_GET_PURCHASE_WORKBOOK_LIST,
          };
          yield call(getPurchaseWorkBookListSaga, getPurchaseAction);

        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* sellVehicleUndoSaga(action: HandleSellVehicleUndo) {  
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield sellVehicleUndo(id);
        callback(data);

          const { dataFilter } = yield getInventoryVehByID(id);
          if (dataFilter) {
                yield put(handleGetVehDetailsSuccess(dataFilter));
          }

         const getSoldAction: handleGetSoldWorkBookList = {
            type: HANDLE_GET_SOLD_WORKBOOK_LIST,
          };
          yield call(getSoldWorkBookListSaga, getSoldAction);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* getVehicleExpensesSaga(action: handleGetVehicleExpenses) {
    yield put(switchGlobalLoader(true));

    try {
        const { dataFilter, errorFilter } = yield getVehicleExpenses(action.payload.id);   

        if (dataFilter) {
            // yield put(handleGetVehDetailsSuccess(dataFilter));
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            //  action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

function* getLiveRetailReportsSaga(action: handleGetLiveRetailReports) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getLiveRetailReports(action.payload.vehID);
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      //  action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* getFilterLiveRetailReportsSaga(action: handleGetFilterLiveRetailReports) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getFilterLiveRetailReports(action.payload.vehID);
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      //  action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* putLiveRetailReportsSaga(action: handlePutLiveRetailReports) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield putLiveRetailReports(action.payload.vehInfo);
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      //  action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* vehicleLiveRetailFilterSaga(action: HandleVehicleLiveRetailFilters) { 
    
  yield put(switchGlobalLoader(true));

  try {
    const { id,formData,callback } = action.payload;
    const { result } = yield vehicleLiveRetailFilter(id,formData);
    callback(result)

    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* saveQValuesSaga(action: handleSaveQValues) {
    yield put(switchGlobalLoader(true));
    const { qValuesData } = action.payload;
    try {
        
        let qValuesInfo = {
            mileage: qValuesData.miles?parseInt(qValuesData.miles):0,
            state: qValuesData.state,
            zip: qValuesData.zip,
            defaultVehicleCondition:qValuesData.defaultVehicleCondition,
            style: qValuesData.nadaStyle,
            series:null
        }
        let master = { nadaInfo: [], kbbInfo: [], bbInfo: [], errorInfo: [] ,vehicles:[]};
        if (qValuesData.nadaStyle) {
            const { nadaData, nadaError } = yield putValuationsNada(qValuesData.id, qValuesInfo);
            if (nadaData) {
                master.nadaInfo.push(nadaData);
            } else if (nadaError) {
                master.errorInfo.push('Failed to fetch data for Nada.');
            }
        }
        if (qValuesData.kbbStyle) {
            qValuesInfo.style = qValuesData.kbbStyle;
            const { kbbData, kbbError } = yield putValuationsKBB(qValuesData.id, qValuesInfo);
            if (kbbData) {
                master.kbbInfo.push(kbbData);
            } else if (kbbError){
                master.errorInfo.push('Failed to fetch data for KBB.');
            }
        }
        if (qValuesData.bbStyle) {
            qValuesInfo.style = qValuesData.bbStyle;
            qValuesInfo.series = qValuesData.bbSeries;
            const { bbData, bbError } = yield putValuationsBlackBook(qValuesData.id, qValuesInfo);
            if (bbData) {
                master.bbInfo.push(bbData);
            }
            else if (bbError) {
                master.errorInfo.push('Failed to fetch data for Black Book.');
            }
        }
        

        const { dataFilter } = yield getInventoryVehByID(qValuesData.id);

        if (dataFilter) {
                master.vehicles.push(dataFilter);
        }
        action.payload.callback(master);

        //if (errorFilter) {
        //    //  action.payload.callback(false);
        //    yield put(switchGlobalLoader(false));
        //    return;
        //}
        yield put(switchGlobalLoader(true));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

function* updateVehicleStatusSaga(action: HandleVehicleStatus) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, status, callback } = action.payload;
        if (status === 'active') {
            const { errorFilter } = yield archiveVehicle(id);
            if (errorFilter) {
                typeof callback === "function" && callback(errorFilter.code === 400 ? errorFilter.message : 'Something went wrong');
            }
        } else if (status === 'archive')
        {
            const { errorFilter } = yield activateVehicle(id);
            if (errorFilter) {
                typeof callback === "function" && callback(errorFilter.code === 400 ? errorFilter.message : 'Something went wrong');
            }
        }
        const { dataFilter } = yield getInventoryVehByID(id);

        if (dataFilter) {
            yield put(handleGetVehDetailsSuccess(dataFilter));
            // action.payload.callback(dataFilter);
        }
        typeof callback === "function" && callback()
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* purchaseVehicleSaga(action: HandlePurchaseVehicle) {
    yield put(switchGlobalLoader(true));
    try {
        const { purchaseVehicleInfo, callback } = action.payload;
        const vehicleId = purchaseVehicleInfo.id;
        delete purchaseVehicleInfo.id;
        const { errorFilter } = yield purchaseVehicle(vehicleId,purchaseVehicleInfo);
        if (errorFilter) {
            typeof callback === "function" && callback(errorFilter.code === 400 ? errorFilter.message : 'Something went wrong');
        }
        else {
          if (window.location.pathname.indexOf('shop-inventory') > 0) {         
                   const { dataFilter } = yield getInventoryVehByID(vehicleId);
                if (dataFilter) {
                    yield put(handleGetVehDetailsSuccess(dataFilter));
                }
            } else {
                const { dataFilter } = yield getShopWorkBookList('active');

                if (dataFilter) {
                    yield put(resolvedShopWorkbookList(dataFilter.results));
                }
            }
            typeof callback === "function" && callback();
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* sellVehicleSaga(action: HandleSellVehicle) {
    yield put(switchGlobalLoader(true));
    try {
        const { sellVehicleInfo, callback } = action.payload;
        const vehicleId = sellVehicleInfo.id;
        delete sellVehicleInfo.id;
        const { errorFilter } = yield sellVehicle(vehicleId,sellVehicleInfo);
        if (errorFilter) {
            typeof callback === "function" && callback(errorFilter.code === 400 ? errorFilter.message : 'Something went wrong');
        }
        else {

          if (window.location.pathname.indexOf('shop-inventory') > 0) {         
            const { dataFilter } = yield getInventoryVehByID(vehicleId);
                if (dataFilter) {
                    yield put(handleGetVehDetailsSuccess(dataFilter));
                }
            } else {
                const { dataFilter } = yield getPurchaseWorkBookList();

                if (dataFilter) {
                    yield put(resolvedPurchaseWorkbookList(dataFilter.results));
                }
            }
            typeof callback === "function" && callback();
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* getQValueDropdownMasterSaga(action: handleGetQValueDropdownMaster) {  
    yield put(switchGlobalLoader(true));

    try {
        const { nadaData } = yield getValuationsNadaStyles(action.payload.id);
        const { kbbData } = yield getValuationsKBBStyles(action.payload.id);
        const { bbData } = yield getValuationsBlackBookStyles(action.payload.id);
        let master = { nadaStyle: null, kbbStyle: null, bbStyle: null };
        if (nadaData) {
            master.nadaStyle = nadaData
        }
        if (kbbData) {
            master.kbbStyle = kbbData
        }
        if (bbData) {
            master.bbStyle = bbData
        }
        action.payload.callback(master);
        //if (errorFilter) {
        //    //  action.payload.callback(false);
        //    yield put(switchGlobalLoader(false));
        //    return;
        //}
        //yield put(switchGlobalLoader(false));
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    //yield put(switchGlobalLoader(false));

}

function* getQValuationsSaga(action: handleGetQValuations) {
    yield put(switchGlobalLoader(true));

    try {
        const { data, error } = yield getValuations(action.payload.id);
        if (error) {
            action.payload.callback(null);
        }
        else {
            action.payload.callback(data.results);
        }
        //if (errorFilter) {
        //    //  action.payload.callback(false);
        //    yield put(switchGlobalLoader(false));
        //    return;
        //}
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

function* getInventoryTrimYMMSaga(action: handleGetTrimUsingYmm) {
    yield put(switchGlobalLoader(true));
    
    try {
        const uP = yield select(
            (state: AppState) => state.userProfile.userProfile
        );

        const selectedDeal = yield select((state: AppState) => state.dealTracker.selectedDealRecord);
        const isKarlProfileType = isKarlProfile(uP.profileType);
        const { dataFilter, errorFilter } = yield getInventoryTrimYMM(action.payload.ymm, isKarlProfileType ? selectedDeal.id : 0, isKarlProfileType, action.payload.trimType);

        if (dataFilter) {
            if (isKarlProfileType && selectedDeal.id) {
                yield put(handleViewDealTracker(selectedDeal.id));
            }
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Inventory Data'));
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

function* getInventoryVehDetailsSaga(action: handleGetVehDetails) {
    yield put(switchGlobalLoader(true));

    try {
        const uP = yield select(
            (state: AppState) => state.userProfile.userProfile
        );
        const selectedDeal = yield select((state: AppState) => state.dealTracker.selectedDealRecord);
        const isKarlProfileType = isKarlProfile(uP.profileType);
        if (action.payload.trimType === trimTypes.tradeIn) {
            const selectedTradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
            if (!selectedTradeInInfo) {
                const appraisalInfo = yield saveAppraisal({}, selectedDeal.id);
                if (appraisalInfo && appraisalInfo.data) {
                    
                    const { dataFilter, errorFilter } = yield getInventoryVehDetails(action.payload.vehDet, isKarlProfileType ? selectedDeal.id : 0, isKarlProfileType, action.payload.trimType, appraisalInfo.data.id);
                    if (dataFilter) {
                        yield put(handleGetVehDetailsSuccess(dataFilter));
                        const tradeInDetail = {
                            id: appraisalInfo.data.id,
                            vehicle: dataFilter
                        }
                        yield put(handleSelectedTradeIn(tradeInDetail));
                        action.payload.callback(dataFilter);
                    }
                    else if (errorFilter) {
                        yield put(handleSelectedTradeIn(appraisalInfo.data));
                        action.payload.callback(false);
                    }
                    yield put(switchGlobalLoader(false));
                    return;
                }
            } else {
                const { dataFilter, errorFilter } = yield getInventoryVehDetails(action.payload.vehDet, isKarlProfileType ? selectedDeal.id : 0, isKarlProfileType, action.payload.trimType, selectedTradeInInfo.id);
                if (dataFilter) {
                    yield put(handleGetVehDetailsSuccess(dataFilter));
                    if (isKarlProfileType && selectedDeal.id) {
                        const { data } = yield viewAppraisal(selectedDeal.id, selectedTradeInInfo.id);
                        if (data) {
                            yield put(handleSelectedTradeIn(data));
                        }
                    }
                    action.payload.callback(dataFilter);
                }
                else if (errorFilter) {
                    // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
                    action.payload.callback(false);
                }
            }
        } else {
            const { dataFilter, errorFilter } = yield getInventoryVehDetails(action.payload.vehDet, isKarlProfileType ? selectedDeal.id : 0, isKarlProfileType, action.payload.trimType);
            if (dataFilter) {
                yield put(handleGetVehDetailsSuccess(dataFilter));
                if (isKarlProfileType && selectedDeal.id) {
                    yield put(handleViewDealTracker(selectedDeal.id));
                }
                action.payload.callback(dataFilter);
            }
            else if (errorFilter) {
                // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
                action.payload.callback(errorFilter);
            }
        }
        yield put(switchGlobalLoader(false));
        return;
       
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getInventoryAdjusterListSaga(action: handleGetAdjusterList) { 
  yield put(switchGlobalLoader(true));

  try {
    const { adjusterList, errorFilter } = yield getInventoryAdjusterList(action.payload.id, action.payload.reportType);
      
    if (adjusterList) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(adjusterList);
    }
    if (errorFilter) {
      // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* putInventoryAdjusterListSaga(action: handlePutAdjusterList) { 
  yield put(switchGlobalLoader(true));

  try {
    const { adjusterList, errorFilter } = yield putInventoryAdjusterList(action.payload.id, action.payload.reportType, action.payload.data);
      
    if (adjusterList) {
      console.log('PUT adjusters', adjusterList);
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(adjusterList);
    }
    if (errorFilter) {
      // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));
}

function* putInventoryVehDetailsSaga(action: handlePutVehDetails) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield putInventoryVehDetails(action.payload.id, action.payload.vehDet);
      
    if (dataFilter) {
      yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter);
    }
    if (errorFilter) {
      // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

function* getInventoryVehSellerDetailsSaga(action: handleGetVehSellerDetails) { 
  yield put(switchGlobalLoader(true));

  try {
    const { dataFilter, errorFilter } = yield getInventoryVehSellerDetails();
      
    if (dataFilter) {
      // yield put(handleGetVehDetailsSuccess(dataFilter));
      action.payload.callback(dataFilter.results);
    }
    if (errorFilter) {
      // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Seller Details'));
      action.payload.callback(false);
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(switchGlobalLoader(false));
    return;
  } catch (error) {
    // yield put(rejectedGetInventoryList(error));
    yield put(switchGlobalLoader(false));
  }
  yield put(switchGlobalLoader(false));

}

// get Inventory Filter SELECTED DATA
function* getInventorySelectedFilterData(action: HandleGetInventoryFilterList) {
  
  yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());

  try {
    const { dataFilter, errorFilter } = yield getInventorySelectedFilterContent(action.payload);
    
    if (dataFilter) {
      yield put(setInventoryFilters(dataFilter.searchConfig))
      // yield put(resolvedGetInventoryFilterList(dataFilter.results));
    }
    if (errorFilter) {
      yield put(rejectedSaveInventoryList(errorFilter));
      return;
    }

    const filters = yield select((state: AppState) => state.inventory.filters);
    const newFilters = {...filters}

    newFilters.minPrice = Boolean(stringFromCurrencyFormat(filters.minPrice)) ? stringFromCurrencyFormat(filters.minPrice): null;
    newFilters.maxPrice = Boolean(stringFromCurrencyFormat(filters.maxPrice)) ? stringFromCurrencyFormat(filters.maxPrice): null ;


    const { data, error } = yield getInventoryList(action.payload, newFilters);
    
    if (error) {
      yield put(rejectedGetInventoryList(error));
      return;
    }
    //console.log("===SAGA GET ALL INVENTORY ITEMS =============", data);
    yield put(resolvedGetInventoryList(data));
    
  } catch (error) {
    yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));
}

// save Inventory
function* saveInventoryFilterSaga(action: HandleSaveFilterInventory) {
  
  yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());

  try {
    const filters = yield select((state: AppState) => state.inventory.filters);
    
    if (JSON.stringify(initialFilterState) === JSON.stringify(filters)) {
      yield put(showSnackbar(NotificationType.warning, 'No Data to Save'));
      action.payload.callback("No Data to Save", false);
      yield put(switchGlobalLoader(false));
      return;
    }

    const newFilters = {...filters}

    newFilters.minPrice = Boolean(stringFromCurrencyFormat(filters.minPrice)) ? stringFromCurrencyFormat(filters.minPrice): null;
    newFilters.maxPrice = Boolean(stringFromCurrencyFormat(filters.maxPrice)) ? stringFromCurrencyFormat(filters.maxPrice): null ;

    const filtersList = yield select((state: AppState) => state.inventory.filterList);
    let payload;
    
    // check for update Saved filters
    if (action.payload && action.payload.filterId) {
      const filteredItem = filtersList.filter((ele) => ele.id === action.payload.filterId)
      payload = {
        id: action.payload.filterId,
        name: filteredItem[0].name,
        searchConfig: newFilters
      };
    } else {
      payload = {
        name: action.payload.filterName,
        searchConfig: newFilters,
        module: "inventory"
      };
    }

    // place for SAVE INVENTORY API CALL
    const { saveError } = yield saveInventoryFilter(action.payload, payload);
    if (saveError) {
      yield put(rejectedSaveInventoryList(saveError));
      action.payload.callback(saveError, false);
      yield put(switchGlobalLoader(false));
      return;
    }

    const { dataFilter, errorFilter } = yield getInventoryFilterList("inventory");
    if (dataFilter) {
      yield put(resolvedGetInventoryFilterList(dataFilter.results));
    }
    
    if (errorFilter) {
      yield put(rejectedSaveInventoryList(errorFilter));
      action.payload.callback(null, true);
      yield put(switchGlobalLoader(false));
      return;
    }
    
    const { data, error } = yield getInventoryList(action.payload, newFilters);
    if (error) {
      yield put(rejectedGetInventoryList(error));
      action.payload.callback(null, true);
      yield put(switchGlobalLoader(false));
      return;
    }

    yield put(resolvedGetInventoryList(data));
    yield call(callBackgroundGetInventory);
    action.payload.callback(null, true);
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));
}

// delete Inventory
function* deleteInventoryFilterSaga(action: HandleDeleteFilterInventory) {
  
  yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());

  try {
    yield put(cleanInventoryFilters());

    const filters = yield select((state: AppState) => state.inventory.filters);

    const newFilters = {...filters}

    newFilters.minPrice = Boolean(stringFromCurrencyFormat(filters.minPrice)) ? stringFromCurrencyFormat(filters.minPrice): null;
    newFilters.maxPrice = Boolean(stringFromCurrencyFormat(filters.maxPrice)) ? stringFromCurrencyFormat(filters.maxPrice): null ;
 
    const payload = {
      name: "",
      searchConfig: "",
      module: "inventory"
    };

    // place for SAVE INVENTORY API CALL
    const { saveerror } = yield deleteInventoryFilter(action.payload, payload);
    if (saveerror) {
      yield put(rejectedSaveInventoryList(saveerror));
      return;
    }

    const { data, error } = yield getInventoryList(action.payload, newFilters);

    if (error) {
      yield put(rejectedGetInventoryList(error));
      return;
    }
    //console.log("===SAGA GET ALL INVENTORY ITEMS =============", data);

    yield put(resolvedGetInventoryList(data));

    
    const getInventoryFilterList: HandleGetInventoryFilterList = {
      payload: "inventory",
      type: HANDLE_GET_INVENTORY_FILTER_LIST,
    };

    yield call(getInventoryFilterListSaga, getInventoryFilterList);

  } catch (error) {
    yield put(rejectedGetInventoryList(error));
  }
  yield put(switchGlobalLoader(false));
}

function* createInventorySaga(action: HandleCreateInventory) {
  yield put(pendingCreateInventory());
  const item = action.payload.inventory;

  const photos: any[] = item.photoUrls;
  const video: any[] = item.videoUrl;
  try {
    const result = yield createInventory(item);

    
    for (let photo of photos) {
      const formData = new FormData();
      formData.append("photo", photo, (photo as any).name);
      const { error } = yield addPhoto(result.data.id, formData);
      if (error) {
        return;
      }
    }
  
    for (let videoEle of video) {
      const formData = new FormData();
      formData.append("video", videoEle, (videoEle as any).name);
      const { error } = yield addVideo(result.data.id, formData);
      if (error) {
        return;
      }
    }

    yield put(resolvedCreateInventory(result));
    action.payload.callback();
    yield call(callBackgroundGetInventory);
  } catch (error) {
    yield put(rejectedCreateInventory(error));
  }
}

function* uploadInventoriesSaga(action: HandleUploadInventories) {
  yield put(switchGlobalLoader(true));
  yield put(pendingGetInventoryList());
  try {
    
    /* const formData = new FormData();
    formData.append("inventoryFile", action.payload, action.payload?.name); */
    
    const { error } = yield uploadInventories(action.payload);
    if (error) {
      yield put(switchGlobalLoader(false));
      let errMsg = error?.message ? error.message : 'Failed to Upload Inventories';
      yield put(showSnackbar(NotificationType.warning, errMsg));
      action.payload.callback(error, false);
      return;
    }
    yield put(showSnackbar(NotificationType.success, 'Successfully Uploaded Inventories'));
    action.payload.callback(null, true);
    yield call(callBackgroundGetInventory);
  } catch (error) {
    yield put(rejectedCreateInventory(error));
  }
}

function* updateInventorySaga(action: HandleUpdateInventory) {
  yield put(pendingUpdateInventory());
  /** GROUP ACTION!*/
  const items = action.payload; // Array of IInventory
  
  try {
    for (const item of items) {
      const { error } = yield updateInventory(item.id, item);

      
      if (error) {
        return;
      }
    }

    yield call(callBackgroundGetInventory);
    yield put(resolvedUpdateInventory());
  } catch (error) {
    yield put(rejectedUpdateInventory(error));
  }
}

function* deleteInventorySaga(action: HandleDeleteInventory) {
  
  yield put(pendingDeleteInventory());

  /** GROUP ACTION!*/
  const { ids, callback } = action.payload; // Array of string

  try {
    for (const item of ids) {
      const { error } = yield deleteInventory(item);
      if (error) {
        return;
      }
    }

    yield put(resolvedDeleteInventory());
    callback();
    yield call(callBackgroundGetInventory);
  } catch (error) {
    yield put(rejectedDeleteInventory(error));
  }
}

function* viewInventorySaga(action: HandleViewInventory) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewInventory());
  const item = action.payload;
  try {
    const { data, error } = yield viewInventory(item);
    if (error) {
      yield put(showSnackbar(NotificationType.warning, 'Failed to View Inventory'));
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(resolvedViewInventory(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedViewInventory(error));
    yield put(switchGlobalLoader(false));
  }
}

function* PrintInventoryPDFInfoSaga(action: HandlePDFInfoInventory) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewInventory());
  const item = action.payload;
  try {
    yield printInventoryInfoPDF(item);
    // yield put(resolvedViewInventory(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedPDFInfoInventory(error));
    yield put(switchGlobalLoader(false));
  }
}

function* PrintInventoryPDFGuideSaga(action: HandlePDFGuideInventory) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewInventory());
  const item = action.payload;
  try {
    yield printInventoryGuidePDF(item);

    // yield put(resolvedViewInventory(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedPDFGuideInventory(error));
    yield put(switchGlobalLoader(false));
  }
}

function* ExportInventorySaga(action: HandleExportInventory) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewInventory());
  const item = action.payload;
  try {
    yield exportInvenotory(item);

    // yield put(resolvedViewInventory(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    // yield put(rejectedPDFGuideInventory(error));
    yield put(switchGlobalLoader(false));
  }
}

function* addInventoryPhotoSaga(action: HandleAddInventoryPhoto) {
  const { inventoryId, fileData } = action.payload;
  yield put(pendingAddPhotoInventory());
  try {
    const inventoryData = yield addPhoto(inventoryId, fileData);

    yield put(resolvedViewInventory(inventoryData));
  } catch (error) {
    yield put(rejectedAddPhotoInventory(error));
  }
}

function* deleteInventoryPhotoSaga(action: HandleDeleteInventoryPhoto) {
  const { inventoryId, photo } = action.payload;
  yield put(pendingDeletePhotoInventory());

  try {
    const inventoryData = yield deletePhoto(inventoryId, photo);

    yield put(resolvedViewInventory(inventoryData));
  } catch (error) {
    yield put(rejectedDeletePhotoInventory(error));
  }
}

function* editInventorySaga(action: HandleEditInventory) {
  yield put(pendingEditInventory());

  
  const {
    arrayOfInventories,
    additionalData: { locationId, inventoryId, filesToRemove, videoFilesToRemove },
    callback,
  } = action.payload;
  

  try {
    for (const item of arrayOfInventories) {
      const { error } = yield editInventory({
        locationId: locationId,
        inventoryId: inventoryId,
        data: item,
      });

      if (error) {
        return;
      }

      const photos: any[] = item.photoUrls;

      for (const photo of photos) {
        const formData = new FormData();
        formData.append("photo", photo, (photo as any).name);

        const { error } = yield addPhoto(inventoryId, formData);

        if (error) {
          return;
        }
      }

      if (videoFilesToRemove) {
        const { videoError } = yield deleteVideo(inventoryId);
        if (videoError) {
          return;
        }
      }
      
      if (item.videoUrl && item.videoUrl.length) {
        const video: any[] = item.videoUrl;
        for (let videoEle of video) {
          const formData = new FormData();
          formData.append("video", videoEle, (videoEle as any).name);
          const { error } = yield addVideo(inventoryId, formData);
          if (error) {
            yield put(showSnackbar(NotificationType.warning, 'Failed to Upload Video'));
            return;
          }
        }
      }
      

      for (const fileToRemove of filesToRemove) {
        const { error } = yield deletePhoto(inventoryId, fileToRemove);
        if (error) {
          return;
        }
      }
    }
    
    typeof callback === "function" && callback();
    yield call(callBackgroundGetInventory);

    yield put(resolvedEditInventory());
  } catch (error) {
    yield put(rejectedEditInventory(error));
  }
}

function* getFromVinSaga(action: HandleGetFromVin) {
  yield put(pendingGetFromVin());
  const { vin, callback } = action.payload;

  try {
    const { data } = yield getDataFromVin(vin);
    const mapped = yield Object.keys(vinMapping)
      .map((key: string) => {
        const value = data.Results.find((d: any) => d.VariableId === +key);
        if (vinMapping[key] === "error" && value.Value[0] === "0") return null;

        return {
          id: vinMapping[key],
          value: value.Value ? value.Value.trim() : null,
        };
      })
      .filter((key: any) => key && key !== undefined);

    yield put(resolvedGetFromVin());
    callback(mapped);
    // here comes the mapping
  } catch (error) {
    yield put(rejectedGetFromVin(error));
  }
}

/* DELETE EXPENSE */

function* deleteExpenseSaga(action: HandleDeleteExpense) {
    yield put(switchGlobalLoader(true));
    const { id, vehiclePricing} = action.payload;
    
    try {
         const { dataFilter, errorFilter } = yield deleteExpense (id, vehiclePricing);
         console.log('DELETE PRICING LIST', dataFilter)
        if (dataFilter) {
           // const {vehId, callback } = action.payload;
           //let vehId =id;
         const { dataFilter, errorFilter } = yield getInventoryPricing(action.payload.id);
         console.log('PRICING PAGE LIST', dataFilter)
         if (dataFilter) {
           action.payload.callback(dataFilter);
         }
         if (errorFilter) {
           action.payload.callback(false);
           yield put(switchGlobalLoader(false));
           return;
         }
       }
       if (errorFilter) {
         action.payload.callback(false);
         yield put(switchGlobalLoader(false));
         return;
       }
       yield put(switchGlobalLoader(false));
       return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    
}

/* Get Active Appraisal,Active and Sold MTD*/
function* getVehiclesStatsSaga(action: GetVehiclesStats) {
  yield put(switchGlobalLoader(true));
   
  try {
       const { data } = yield getVehicleStats();
       action.payload.callback(data);
       yield put(switchGlobalLoader(false));
     return;
  } catch (error) {
      yield put(switchGlobalLoader(false));
      return;
  }
  
}


function* getValuationInformationSaga(action: HandleGetValuationInformation) {
  const { inventoryId, source } = action.payload;

  yield put(pendingGetValuationInformation(source));
  const { data, error } = yield getValuationInformation(inventoryId, source);

  
  
  if (error) {
    yield put(rejectedGetValuationInformation(error));
  }

  let value = 0;
  if (source === "black") {
    value = data.base_retail_avg;
  }
  if (source === "kelley") {
  }
  if (source === "nada") {
  }

  yield put(resolvedGetValuationInformationn({ data: value, source }));

  
}

function* manageFavoriteVehicleSaga(action: HandleManageFavoriteVehicle) {
    yield put(switchGlobalLoader(true));
    
    try {
        const { inventoryId, isFavorite, status } = action.payload;
        console.log('status',status)
        const { errorFilter } = yield manageFavoriteInventory(inventoryId, isFavorite);
        if (errorFilter) {
            yield put(rejectedManageFavoriteVehicle(errorFilter));
            yield put(switchGlobalLoader(false));
            action.payload.callback(false);
            return;
        }
        else {
            // const { dataFilter, errorFilter } = yield getShopWorkBookList(status);
            /* if (errorFilter) {
                yield put(rejectedManageFavoriteVehicle(errorFilter));
                yield put(switchGlobalLoader(false));
                return;
            } */

            //yield put(resolvedShopWorkbookList(dataFilter.results));
            action.payload.callback(true);
            yield put(switchGlobalLoader(false));
            return;
        }

    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}

/** Update Vehicle - stockAppLoc */
function* updateVehicleSaga(action: HandleVehicleUpdate) {
    yield put(switchGlobalLoader(true));
    const { id, stockAppLoc } = action.payload;

    try {
        const { data, error } = yield updateVehicle(id, stockAppLoc);
        if (data) {
            if (!data.vin)
                data["vin"] = '';
            if (!data.stockNumber)
                data["stockNumber"] = '';
            if (!data.location)
                data["location"] = null;
            if (!data.appraisedBy)
                data["appraisedBy"] = null;
            yield put(handleGetVehDetailsSuccess(data));
            if (data) {
                action.payload.callback(data);
            }
        }
        if (error) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }

}

export default function* InventorySaga() {
  yield takeLeading(HANDLE_GET_INVENTORY, getInventorySaga);
  yield takeLeading(HANDLE_SAVE_INVENTORY_FILTER, saveInventoryFilterSaga);
  yield takeLeading(HANDLE_GET_INVENTORY_FILTER_LIST, getInventoryFilterListSaga);
  yield takeLeading(HANDLE_GET_SHOP_WORKBOOK_LIST, getShopWorkBookListSaga);
  yield takeLeading(HANDLE_GET_VEHICLES, getVehiclesSaga);
  yield takeLeading(HANDLE_GET_PURCHASE_WORKBOOK_LIST, getPurchaseWorkBookListSaga);
  yield takeLeading(HANDLE_GET_SOLD_WORKBOOK_LIST, getSoldWorkBookListSaga);
  yield takeLeading(HANDLE_GET_TRIM_VIN, getInventoryTrimVINSaga);
  yield takeLeading(HANDLE_GET_Vehicle_ID, getInventoryVehByIDSaga);
  yield takeLeading(HANDLE_GET_Pricing_ID, getInventoryPricingVehByIDSaga);
  yield takeLeading(HANDLE_SAVE_Pricing_ID, saveInventoryVehByIDSaga);
  yield takeLeading(HANDLE_GET_QVALUE_PLOT_ID, getInventoryQValuePlotSaga);
  yield takeLeading(HANDLE_GET_QVALUE_NOTES_ID, getInventoryQValueNotesSaga);
  yield takeLeading(HANDLE_SAVE_QVALUE_NOTES_ID, saveInventoryQValueNotesSaga);
  yield takeLeading(HANDLE_Pricing_Categories_ID, getInventoryCategoryIDSaga);
  yield takeLeading(HANDLE_SAVE_SOURCE_WORKBOOK, saveSourceWorkBookSaga);
  yield takeLeading(HANDLE_GET_SOURCE_WORKBOOK, getSourceWorkBookSaga);
  yield takeLeading(HANDLE_SAVE_VEHICLE_EXPENSES, saveVehicleExpensesSaga);
  yield takeLeading(HANDLE_GET_VEHICLE_EXPENSES, getVehicleExpensesSaga);
  yield takeLeading(HANDLE_GET_LIVE_RETAIL_REPORTS, getLiveRetailReportsSaga);
  yield takeLeading(HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS, getFilterLiveRetailReportsSaga);
  yield takeLeading(HANDLE_PUT_LIVE_RETAIL_REPORTS, putLiveRetailReportsSaga);
  yield takeLeading(HANDLE_VEHICLE_LIVE_RETAIL_FILTERS, vehicleLiveRetailFilterSaga);
  yield takeLeading(HANDLE_GET_QVALUE_DROPDOWN_MASTER, getQValueDropdownMasterSaga);
  yield takeLeading(HANDLE_GET_QVALUATIONS, getQValuationsSaga);
  yield takeLeading(HANDLE_SAVE_QVALUES, saveQValuesSaga);
  yield takeLeading(HANDLE_VEHICLE_STATUS, updateVehicleStatusSaga);
  yield takeLeading(HANDLE_PURCHASE_VEHICLE, purchaseVehicleSaga);
  yield takeLeading(HANDLE_SELL_VEHICLE, sellVehicleSaga);
  yield takeLeading(HANDLE_GET_TRIM_YMM, getInventoryTrimYMMSaga);
  yield takeLeading(HANDLE_GET_VEH_DETAILS, getInventoryVehDetailsSaga);
  yield takeLeading(HANDLE_PUT_VEH_DETAILS, putInventoryVehDetailsSaga);
  yield takeLeading(HANDLE_GET_ADJUSTER_LIST, getInventoryAdjusterListSaga);
  yield takeLeading(HANDLE_PUT_ADJUSTER_LIST, putInventoryAdjusterListSaga);
  yield takeLeading(HANDLE_GET_VEH_SELLER_DETAILS, getInventoryVehSellerDetailsSaga);
  yield takeLeading(HANDLE_GET_SELECTED_FILTER_DATA, getInventorySelectedFilterData);
  yield takeLeading(HANDLE_DELETE_INVENTORY_FILTER, deleteInventoryFilterSaga);
  yield takeLeading(HANDLE_CREATE_INVENTORY, createInventorySaga);
  yield takeLeading(HANDLE_UPLOAD_INVENTORY, uploadInventoriesSaga);
  yield takeEvery(HANDLE_UPDATE_INVENTORY, updateInventorySaga);
  yield takeEvery(HANDLE_DELETE_INVENTORY, deleteInventorySaga);
  yield takeLeading(HANDLE_VIEW_INVENTORY, viewInventorySaga);
  yield takeLeading(HANDLE_PDF_INFO_INVENTORY, PrintInventoryPDFInfoSaga);
  yield takeLeading(HANDLE_PDF_GUIDE_INVENTORY, PrintInventoryPDFGuideSaga);
  yield takeLeading(HANDLE_EXPORT_INVENTORY, ExportInventorySaga);
  yield takeLeading(HANDLE_ADD_INVENTORY_PHOTO, addInventoryPhotoSaga);
  yield takeLeading(HANDLE_DELETE_INVENTORY_PHOTO, deleteInventoryPhotoSaga);
  yield takeLeading(HANDLE_GET_FROM_VIN, getFromVinSaga);
  yield takeEvery(HANDLE_EDIT_INVENTORY, editInventorySaga);
  yield takeLeading(
    HANDLE_GET_VALUATION_INFORMATION,
    getValuationInformationSaga
    );
  yield takeLeading(HANDLE_MANAGE_FAVORITE_VEHICLE, manageFavoriteVehicleSaga);
  yield takeEvery(HANDLE_DELETE_EXPENSE, deleteExpenseSaga);
  yield takeEvery(GET_VEHICLES_STATS, getVehiclesStatsSaga);
  yield takeLeading(HANDLE_VEHICLE_UPDATE, updateVehicleSaga); 
  yield takeLeading(HANDLE_PURCHASE_VEHICLE_UNDO,purchaseVehicleUndoSaga);
  yield takeLeading(HANDLE_SELL_VEHICLE_UNDO,sellVehicleUndoSaga);
}
