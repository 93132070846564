import client from "services/api/client";

export const getSubscriptionsApi = async () => {
  const query = `user/master/payment/available/list`;
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const paymentApi = async (product) => {
  const query = `user/master/payment/intent`;
  return client(query, {
    body: {
      product: product,
      successUrl: `${process.env.REACT_APP_FRONT_URL}subscriptions/success/subscriptions`,
      cancelUrl: `${process.env.REACT_APP_FRONT_URL}subscriptions/cancel/subscriptions`,
    },
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const cancelSubscriptionApi = async (sub: string) => {
  const query = `user/master/payment/cancel`;
  return client(query, {
    method: "DELETE",
    body: {
      subscription: sub,
    },
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getCreditCartsApi = async () => {
  const query = `user/master/payment/cards`;
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const addCreditCartApi = async (cart: any) => {
  const query = `user/master/payment/card`;
  return client(query, {
    body: {
      token: cart
    },
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const removeCreditCartApi = async (cartId: number | string) => {

  return client(`user/master/payment/card`, {
    method: "DELETE",
    body: {
      token: cartId
    },
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
