import React, { useState } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  PrimaryButton,
  DefaultPalette,
  MessageBar,
  IMessageBarStyles,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { styles } from "pages/accountSettingsPage/styles";
import { AppState } from "store/index";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as constantStyle,
  notificationColors,
} from "constants/styles";
// import { IOption } from "types/optionsTypes";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddInventoryToEmailDialog from "components/dialogs/addInventoryToEmailDialog/AddInventoryToEmailDialog";
import { handleSendEmail } from "store/shared/shared.action";


const contentStyles = constantStyle(700, "auto");

interface IProps {
  onDismiss: () => void;
  receiversEmails: string[];
}

// const templates: IOption[] = [
//   { key: "0", value: "0", text: "Custom template 1" },
//   { key: "1", value: "1", text: "Custom template 3" },
//   {
//     key: "2",
//     value: "2",
//     text: "Custom template 4",
//   },
// ];

// const attachments = [
//   { name: "custom.pdf", size: "2.7 Mb", icon: "Pdf" },
//   { name: "Inventory.pdf", size: "5.7 Mb", icon: "Car" },
// ];

const messageStyles: Partial<IMessageBarStyles> = {
  root: {
    margin: "5px 0",
    ...notificationColors,
  },
  icon: {
    color: DefaultPalette.themePrimary,
  },
};

export const CreateEmailDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  receiversEmails,
}) => {
  const [attachments, setAttachments] = useState([]);
  const [inventories, setInventories] = useState([]);

  const [isAddInventoryDialogOpen, setIsAddInventoryDialogOpen] = useState(false);

  const loading = useSelector((state: AppState) => state.shared.loading);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const emptyForm = {
    senderEmail: user.username,
    receiverEmail: receiversEmails.length > 0 ? receiversEmails : [],
    subject: "",
    template: "",
    message: "",
    // attachments: [...attachments],
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...emptyForm,
    },

    validationSchema: Yup.object({
      senderEmail: Yup.string().email("This field must be valid email user"),
      receiverEmail: Yup.array().of(Yup.string()).required("Required"),
      template: Yup.string(),
      message: Yup.string().required("Required"),
      subject: Yup.string().required("Required"),
      // attachments: Yup.array().of(Yup.object()),
    }),
    onSubmit: (values) => {
      dispatch<any>(
        handleSendEmail(
          {
            email: formik.values.receiverEmail,
            title: formik.values.subject,
            message: formik.values.message,
            attachments,
            inventories: inventories.map((inventory: any) => inventory.id),
          },
          onDismiss
        )
      );
    },
  });

  React.useEffect(() => {
    const email = Boolean(user.username) ? user.username : user.email
    formik.setFieldValue('senderEmail',email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])


  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  // const handleChangeTemplate = (e, item) => {
  //   console.log(e.target, item);
  //   formik.setFieldValue("template", item.value);
  // };

  const handleDeleteAttachment = (attachment) => {
    setAttachments((attachments) =>
      attachments.filter((a) => a.name !== attachment.name)
    );
  };
  const handleDeleteInventory = (inventory) => {
    setInventories((inventories) =>
      inventories.filter((i) => i.id !== inventory.id)
    );
  };

  const handleEmailChange = (e: any) => {
    const emails: string[] = e.target.value.split(",");
    console.log(emails);

    formik.setFieldValue(
      "receiverEmail",
      emails.map((email: string) => email.trim())
    );
  };

  const fileSelectionHandler = (e) => {
    const uploadedFile = e.target.files[0];

    

    const formData = new FormData();
    formData.append("avatar", uploadedFile, "test.jpeg");

    setAttachments((attachments) => [...attachments, uploadedFile]);

    //dispatch<any>(handleUploadUserPhoto(loggedUserId, formData));
  };

  const handleShowAddInventoryDialog = () => {
    
    setIsAddInventoryDialogOpen(true);
  };

  return (
    <>
      {isAddInventoryDialogOpen && (
        <AddInventoryToEmailDialog
          onDismiss={() => setIsAddInventoryDialogOpen(false)}
          onInventorySelection={(selectedInventories) =>{

         setInventories(selectedInventories)

            // setInventories((inventories) => [
            //   ...inventories,
            //   ...selectedInventories,
            // ])
          }
          }
          selectedInventories={inventories.length > 0 && inventories}
        />
      )}
      <Modal
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <CustomFontText>Create New Email</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        {/*{user && user.username && (*/}
          <div className={contentStyles.body}>
            <TextField
              id="senderEmail"
              name="senderEmail"
              label="From"
              disabled={true}
              value={formik.values.senderEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.senderEmail && formik.errors.senderEmail
                  ? formik.errors.senderEmail.toString()
                  : null
              }
            />
            <TextField
              id="receiverEmail"
              name="receiverEmail"
              label="To"
              disabled={true}
              value={formik.values.receiverEmail.join(", ")}
              onChange={handleEmailChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.receiverEmail && formik.errors.receiverEmail
                  ? formik.errors.receiverEmail.toString()
                  : null
              }
            />
            <TextField
              id="subject"
              name="subject"
              label="Message Subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.subject &&
                formik.errors.subject &&
                formik.errors.subject.toString()
              }
            />
            {/* TEMPORARY COMMENTED */}
            {/*<Dropdown*/}
            {/*  id="template"*/}
            {/*  selectedKey={formik.values.template}*/}
            {/*  onChange={handleChangeTemplate}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  options={templates}*/}
            {/*  placeholder="Select template..."*/}
            {/*  styles={fullLengthDropdownStyles}*/}
            {/*  label="Select Message Template"*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.template && formik.errors.template*/}
            {/*      ? formik.errors.template.toString()*/}
            {/*      : null*/}
            {/*  }*/}
            {/*/>*/}
            {/*<div>*/}
            {/*  <strong>Message Content</strong>*/}
            {/*</div>*/}
            <TextField
              multiline
              rows={8}
              label="Message"
              id="subject"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.message &&
                formik.errors.message &&
                formik.errors.message.toString()
              }
            />

            <div style={{ marginTop: 10, marginBottom: 10 }}>Attachments</div>
            {attachments.map((attachment: any, index: number) => (
              <MessageBar
                isMultiline={true}
                onDismiss={() => handleDeleteAttachment(attachment)}
                dismissButtonAriaLabel="Close"
                messageBarIconProps={{ iconName: attachment.icon }}
                key={index}
                styles={messageStyles}
              >
                {attachment.name} ({attachment.size})
              </MessageBar>
            ))}
            {inventories.map((inventory: any, index: number) => (
              <MessageBar
                isMultiline={true}
                onDismiss={() => handleDeleteInventory(inventory)}
                dismissButtonAriaLabel="Close"
                // messageBarIconProps={{ iconName: inventory.icon }}
                key={index}
                styles={messageStyles}
              >
                {inventory.stock}
              </MessageBar>
            ))}

            <Stack horizontal>
              <DefaultButton
                text="Add Attachment"
                onClick={() => {}}
                iconProps={{ iconName: "Upload" }}
                style={{ marginRight: 10 }}
                type="file"
              >
                <input
                  style={{
                    padding: 20,
                    // border: '1px solid red',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: 107,
                    height: 1,
                    overflow: "hidden",
                    opacity: 0,
                    marginLeft: -10,
                  }}
                  type="file"
                  onChange={fileSelectionHandler}
                />
              </DefaultButton>
              <DefaultButton
                text="AddInventory"
                onClick={handleShowAddInventoryDialog}
                iconProps={{ iconName: "Car" }}
              />
            </Stack>
          </div>
        {/*// )}*/}
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <DefaultButton text="Close" onClick={onDismiss} />
            <PrimaryButton
              onRenderIcon={renderSpinner}
              text="Send email"
              onClick={() => formik.handleSubmit()}
              disabled={!formik.isValid || !formik.dirty}
            />
          </Stack>
        </div>
      </Modal>
    </>
  );
};
