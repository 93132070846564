import { IPrimarySetup } from "types/primarySetupTypes";
import client, { clientFile, clientV2 }from "services/api/client";

export const savePrimarySetup = (setup: IPrimarySetup,userId) => {
const data = {
   "guidedInitialSetup": setup
  };
  return client(
      `user/master/guidedInitialSetup/${userId}`,
      { body: data, method: "PATCH" }
  ).then(
      (data) => {
      return { result: data, error: null };
    },
      (error) => {
          console.log('test error : '+ error);
          return { result: null, error };
    }
  );
};

export const addCompanyLogo = (userId: number, fileData: any) => {
    return clientFile(`user/master/companyLogo/${userId}`, {
        body: fileData,
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {
            return { result: null, error };
        }
    );
};

export const addDealerProfilePhoto = (userId: number, fileData: any) => {
    return clientFile(`user/master/dealerProfilePhoto/${userId}`, {
        body: fileData,
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {
            return { result: null, error };
        }
    );
};

/** Guided setup changes */
export const verifyEINNumber = (verifyEIN: any) => {
    /* const data = {
    "guidedInitialSetup": setupDetails
    }; */
    return clientV2(
        `user/master/ein`,
        { body: {einNumber: verifyEIN}, method: "POST" }
    ).then(
        (data) => {
            return { result: data, error: null };
    },
        (error) => {
        return { result: null, error };
    }
    );
};

export const requestEINAccess = (verifyEIN: any) => {
    /* const data = {
    "guidedInitialSetup": setupDetails
    }; */
    return client(
        `user/master/ein/request`,
        { body: {einNumber: verifyEIN}, method: "POST" },false
    ).then(
        (data) => {
        return { result: data, error: null };
    },
    (error) => {
        return { result: null, error };
    }
    );
};

export const requestEINProfile = (id: any) => {
    return client(
        `user/master/ein/${id}`,
        { body: null, method: "GET" },false
    ).then(
        (data) => {
        return { resultProfile: data, errorProfile: null };
    },
    (error) => {
        return { resultProfile: null, errorProfile: error };
    }
    );
};

export const updateGuidedSetupDetails = (setupDetails: any) => {
    /* const data = {
    "guidedInitialSetup": setupDetails
    }; */
    return client(
        `user/master/setup`,
        { body: setupDetails, method: "PATCH" }
    ).then(
        (data) => {
        return { result: data, error: null };
    },
        (error) => {
            console.log('test error : '+ error);
            return { result: null, error };
    }
    );
};

export const getGuidedSetupDetails = () => {
    return client(`user/master/setup`,
    { body: null, method: "GET" }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {
            return { result: null, error };
        }
    );
};
    