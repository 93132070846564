import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PrimaryButton } from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../accountSettingsPage/styles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { handleAddCreditCart} from "store/subscriptions/subscriptions.action";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#000",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#A19F9D",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

interface IProps {
  add?: boolean;
  onDismiss?: () => void;
}

export const CheckoutForm: React.FC<IProps> = ({ add, onDismiss }) => {
  return (
    <Elements stripe={stripePromise}>
      <PayWithCard add={add} onDismiss={onDismiss} />
    </Elements>
  );
};


const PayWithCard: React.FC<IProps> = ({ add, onDismiss }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const loading = useSelector((state: AppState) => state.subscriptions.addingCart);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    console.log("event", event);
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);

      //
      console.log("token:result-payent", result.token);
      let callback = () => {};
      if(add && onDismiss){
        callback = onDismiss;
      }
      dispatch<any>(handleAddCreditCart(result.token, callback));
      // Send the token to your server.
      // await stripeTokenHandler(result.token);
    }
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStylesAdd} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div style={{ marginBottom: 5 }}>Credit or debit card1</div>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      {/*<button type="submit">Submit Payment</button>*/}

      <PrimaryButton
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: "100%",
          padding: 20,
          fontSize: 16,
        }}
        onRenderIcon={renderSpinner}
        type="submit"
        text={add ? "Add Cart" : "Subscribe"}
        onClick={() => {}}
      />
    </form>
  );
};

// // POST the token ID to your backend.
// async function stripeTokenHandler(token) {
//   const response = await fetch("/charge", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ token: token.id }),
//   });
//
//   return response.json();
// }
