import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
	Stack,
	IStackStyles,
	//TextField,
	PrimaryButton,
	Link
} from "@fluentui/react";
import {
	//useDispatch,
	useSelector
} from "react-redux";
import { AppState } from "store/index";
//import {
//	getModulePermissions,
//	isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetTaskList } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
	useNavigate , 
	useLocation
} from "react-router-dom";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import masterDropdownValues from "constants/masterDropdownValues";
import { EditShopWorkBookMoreActionSubmenu } from "components/table/inventory/EditShopWorkBookMoreActionSubmenu";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import {
	convertUTCToLocalFormatModified,
	//convertUTCToLocalModified,
	//convertUTCToLocalMoment
} from "utils/dateTimeFunctions";
import moment from "moment";
import { Paginator } from 'primereact/paginator';
import { DebounceInput } from 'react-debounce-input';
import { classNames } from "primereact/utils";


const container: IStackStyles = {
	root: {
		marginTop: 10,
	},
};

//const credentialModuleName = "DealTracker";
export const TaskWorkbookV2Page = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
	//const [appointmentId, setAppointmentId] = useState(0);
	const [taskData, setTaskData] = useState([]);
	const [commonServerError, setcommonServerError] = useState(null);
	const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
	//const tasks = useSelector((state: AppState) => state.dealTracker.tasks) as any;
	const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

	const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

	//const [credentials, setCredentials] = useState([]);
	const [glFilter, setGLFilter] = useState('');
	const [locationFilter, setLocationFilter] = useState(0);
	const [statusFilter, setStatusFilter] = useState('N');
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
	const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
	const [primaryLocation, setPrimaryLocation] = useState(0);
	const [sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState('dueDate');
	const [statesFlag, setStatesFlag] = useState(false);
	const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
	const [totalRecords, setTotalRecords] = useState(null);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
	const [statePageNumber, setStatePageNumber] = useState(1);

    const handleFirstNameFilter = (val: any) => {
		const inputValue = val.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }
		if(val.target.value === ''){
			setGLFilter('');
	    }
	};


	const onLocationChange = (e: { value: any }) => {
		setLocationFilter(e.value);

	}

	const onStatusChange = (e: { value: any }) => {
		setStatusFilter(e.value);
	}


    const handleFromLogDateChange = (val: any) => {
		setLogDateFromFilter(val.target.value)
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
    };

    useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

	useEffect(() => {
        if (user && user.roles) {
            setUserProfileLoadingFlag(true);
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps



	useEffect(() => {
		setSortOrder(1);
		setSortField('dueDate');
		setStatesFlag(null);
		//if (user && user.auths && !isUserMaster(user.roles)) {
		//	setCredentials(getModulePermissions(user.auths, credentialModuleName));
		//}
		dispatch<any>(handleGetLocations())
	}, []); // eslint-disable-line react-hooks/exhaustive-deps



	const populateTaskData = (tasks) => {
		let taskWorkbook = [];
		let hiddenStr;
		tasks.forEach(function (task, index) {
			hiddenStr = '';
			if (task.deal) {
				const deal = task.deal
				if (deal.vehicle) {
					const vehicle = deal.vehicle;
					if (vehicle?.orderNumber) {
						hiddenStr = vehicle?.orderNumber;
					}
					if (vehicle?.stockNumber) {
						hiddenStr = hiddenStr + ' ' + vehicle?.stockNumber;
					}
					if (vehicle?.vin) {
						hiddenStr = hiddenStr + ' ' + vehicle?.vin;
					}
				}
				if (deal.salesPerson1)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
				if (deal.salesPerson2)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
				if (deal.financePerson1)
					hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
				if (deal.financePerson2)
					hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
				hiddenStr = hiddenStr + ' ' + deal.customer?.customerNumber;
			}
			let taskObj = {
				key: ''+index,
				data: {
					startDate: convertUTCToLocalFormatModified(task.startDate, "MM/DD/YYYY"), //moment(task.startDate).format("MM/DD/YYYY"),
					dueDate: convertUTCToLocalFormatModified(task.dueDate, "MM/DD/YYYY"),//moment(task.dueDate).format("MM/DD/YYYY"),
					description: task.description,
					dealNumber: task.deal.dealNumber,
					customer: task.deal.customer.businessName ? task.deal.customer.businessName:(task.deal.customer.firstName + ' ' + task.deal.customer.lastName),
					assignedBy: task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
					assignedTo: task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
					isCompleted: task.isCompleted ? "Complete" : null,
					assignedByDepartmentId: getDeptName(task.assignedByDepartmentId),
					assignedToDepartmentId: getDeptName(task.assignedToDepartmentId),
					dealId: task.deal.id,
					location: task.deal.location?.id,
					logDate: task.createdAt,	
					id: task.id,
					hiddenCol: hiddenStr
				},
				children: getNotes(task.dealTaskNotes, index, task.deal.id)
			}
			taskWorkbook.push(taskObj);
		});
		setTaskData(taskWorkbook)


	};



	const getNotes = (notes, index,id) => {
		if (notes && notes.length > 0) {
			let taskNotesWorkbook = []
			notes.forEach(function (item, i) {
				let taskObj = {
					key: index+'-'+i,
					data: {
						startDate: null,
						dueDate: convertUTCToLocalFormatModified(item.createdAt, "MM/DD hh:mm A"),
                        description: (item.employee?.firstName + ' ' + item.employee?.lastName) + ': ' + item.notes,
						dealNumber: null,
						customer: null,
						assignedBy: null,
						assignedTo: null,
						isCompleted: null,
						assignedByDepartmentId: null,
						assignedToDepartmentId: null,
						dealId: id,
						hiddenCol:''
					}
				}
				taskNotesWorkbook.push(taskObj);

			});
			return taskNotesWorkbook;
		} else
			return [];
		
	};

	const getDeptName = (id) => {
		let item = masterDropdownValues.departmentOptions.filter(x => x.key === id);
		if (item.length === 1) {
			return item[0].text;
		}
		return '';
	};

	const isCompleteVal=(data)=>{

	   if(data === 'Y'){
		   return 1
	   }else if(data === 'N'){
		   return 0
	   } else{
		   return null
	   }

	}
	

    const getTaskList =(isFilterApplied)=>{

	     const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
                sort: sortOrder === 1 ? 'ASC' : 'DESC',
                column: sortField,
                locationId:locationFilter?locationFilter:"",
                fromStartDate: logDateFromFilter ? moment(logDateFromFilter).format('YYYY-MM-DD'):'',
                toStartDate: logDateToFilter ? moment(logDateToFilter).format('YYYY-MM-DD') : '',
                search: glFilter,
				isCompleted:isCompleteVal(statusFilter)
            };
            setFilters(formData);
			if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetTaskList({
                formData,
                callback: (response) => {
                    if (response) {
                        setTotalRecords(response.totalItems);
                        populateTaskData(response.results);
                        
                    } else {
						setTaskData(null)
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}

	const handleResetWorkbook = () => {   
		setGLFilter("");
		setLocationFilter(primaryLocation);
		setStatusFilter('N');
		setLogDateFromFilter(null);
		setlogDateToFilter(null);
		setSortField('dueDate');
		setSortOrder(-1);
		setLimit(25);
        setPages(1);

	};


	const exportExcel = () => {

        const formData = { page: 1, limit: 10000, ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetTaskList({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
 
                         let data = [...response.results]

			             let exportData = [];
			             
			             data.forEach(function (item, index) {
			             	const parent = getExportData([item]);
			             	exportData.push(...parent);
			             	//exportData.concat(parent);
			             	if (item.dealTaskNotes) {
			             		const children = getExportChildData(item.dealTaskNotes);
			             		exportData.push(...children);
			             		//exportData.concat(children);
                             }
			             });
			             
			             import('xlsx').then(xlsx => {
			             	const worksheet = xlsx.utils.json_to_sheet(exportData);
			             	const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			             	const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			             	saveAsExcelFile(excelBuffer, 'desking-report');
			             });
                        
                    }
                } else {
                    
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }


	const getExportData = (data) => {
		
		return data.map((task) =>
			({
				"Start Date": convertUTCToLocalFormatModified(task.startDate, "MM/DD/YYYY"), 
				"Due Date": convertUTCToLocalFormatModified(task.dueDate, "MM/DD/YYYY"),
				"Description": task.description,
				"Deal#": task.deal.dealNumber,
				"Customer": task.deal.customer.firstName + ' ' + task.deal.customer.lastName,
				"Assigned By Dept": getDeptName(task.assignedByDepartmentId),
				"Assigned By": task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
				"Assigned To Dept": getDeptName(task.assignedToDepartmentId),
				"Assigned to": task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
				"Status": task.isCompleted ? "Complete" : null,
			}));
    }

    const getExportChildData = (data) => {

		return data.map((task) =>
			({
				"Start Date": null,
				"Due Date": convertUTCToLocalFormatModified(task.createdAt, "MM/DD hh:mm A"),
                "Description": (task.employee?.firstName + ' ' + task.employee?.lastName) + ': ' + task.notes,
				"Deal#": null,
				"Customer": null,
				"Assigned By Dept": null,
				"Assigned By": null,
				"Assigned To Dept": null,
				"Assigned to": null,
				"Status": null
			}));
    }

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
			}
		});
	}

	//Datatable Templates
const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			             <Col>
							  <div className="table-header employeeeSearchLabelText">
			                  	{/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={glFilter}
                                          debounceTimeout={750}
                                          onChange={(e) => handleFirstNameFilter(e)} />
                                  </div>
			                  </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div> 
						</Col>
						<Col>
							<div className="dd-control">
								<label className="lbl-text">Task Complete</label>
								<Dropdown
									id="status"
									placeholder=""
									value={statusFilter}
									options={masterDropdownValues.taskCompleteStatus}
									onChange={onStatusChange}
									optionLabel="text"
									optionValue="key"
									appendTo="self"
									className="custom-p-dropdown write-ups-location-dd"
									filter={false}
									resetFilterOnHide={true}
								/>
							</div>
						</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"32px"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
						 
			         	
						</Row>
				   <Row>
				   		<Col className="display-row" style={{ marginTop: "15px" }}>
				   			{
				   				totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
				   			}
				   			<Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
				   				Export to Excel
						   </Link>
				   		</Col>
				   </Row>
				</Col>
		    </Row>

		</div>:
		<div>
			<div>
				<Row>
					<Col md={11}>
			         <Row>
			             <Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	{/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={glFilter}
                                          debounceTimeout={750}
                                          onChange={(e) => handleFirstNameFilter(e)} />
                                  </div>
			                  </div>
			         	</Col>
					    <Col md={3}>
					    	<div className="dd-control">
					    		<label className="lbl-text">Location</label>
					    		<Dropdown
					    			id="locationId"
					    			placeholder=""
					    			value={locationFilter}
					    			options={cityDealerList}
					    			onChange={onLocationChange}
					    			optionLabel="legalName"
					    			optionValue="id"
					    			appendTo="self"
					    			className="custom-p-dropdown write-ups-location-dd"
					    			filter={false}
					    			resetFilterOnHide={true}
					    		/>
					    	</div>
					    </Col>
			         	<Col  md={2}>
						  <div className="dd-control">
			                  <label className="lbl-text">Task Complete</label>
                              <Dropdown
                                    id="status"
                                    placeholder=""
                                    value={statusFilter}
                                    options={masterDropdownValues.taskCompleteStatus}
                                    onChange={onStatusChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
			         	<Col md={3}>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
                        <Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"32px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>

			         </Row>
				</Col>
					<Col>
					</Col>
				</Row>
			</div>
			<div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);

    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

	useEffect(() => {
		
		const stateVal: any = location?.state
		if (stateVal && stateVal.searchInput) {
			setGLFilter(stateVal.searchInput);
		}
		if (stateVal?.fromKeymetrics) {
			setLocationFilter(stateVal?.locationId);
		}

		if (stateVal?.locationFilter) {
			setLocationFilter(stateVal?.locationFilter);
		}

		if(primaryLocation === 0 && stateVal?.locationFilter === 0){
            setLocationFilter(0);
        }
		if (stateVal?.logDateFromFilter) {
			setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
		}

		if (stateVal?.logDateToFilter) {
			setlogDateToFilter(new Date(stateVal.logDateToFilter));
		}

		if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) * stateVal.limit);
            }
        }

		if (stateVal?.statusFilter) {
			setStatusFilter(stateVal?.statusFilter);
        }
		setStatesFlag(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		if (serverError) {
			if (serverError.detail) {
				setcommonServerError(serverError.detail);
			} else {
				setcommonServerError('Something went wrong');
			}
			window.scrollTo(0, 0);
		} else {
			setcommonServerError(null);
		}
	}, [serverError]);

	const rowClassName = (node) => {
		return { 'childRow': (node.key && node.key.includes('-')) };
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<div className="contextMenu">
					<EditShopWorkBookMoreActionSubmenu
						disableIcon={false}
						reportType={rowData}
						onEdit={(reportType) => mainTableAction(reportType)}
					/>
				</div>
			</React.Fragment>
		);
	};

	const mainTableAction = (reportType) => {
		const state = {
			searchInput: glFilter,
			pathfrom: '/tasks',
			tabIndex: 5,
			productId:`${reportType.data.id}`,
			workbookName: 'task',
			sortOrder,
			sortField,
			pages,
			limit,
			logDateFromFilter,
			logDateToFilter,
			locationFilter,
			statusFilter
		}
		navigate(`/${reportType.data.dealId}/dealtracker`,{state});
	}

	const handleRowClick = (event) => {
		let target = event.originalEvent.target;
		if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
			(target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
			target.lastElementChild.lastElementChild.firstElementChild.click();
		} else {
			mainTableAction(event.node);
		}
	}

	const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

    const getdataList = (isFilterApplied = false) => {

        setcommonServerError('');
        if (statesFlag && userProfileLoadingFlag ) {
             getTaskList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getdataList(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glFilter,statusFilter,locationFilter,logDateFromFilter,logDateToFilter,userProfileLoadingFlag,statesFlag]);

    useEffect(() => {
        getdataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);

	const togglerTemplate = (node, options) => {
        if (!node) {
            return;
        }

        const expanded = options.expanded;
        const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
            'pi-plus-circle': !expanded,
            'pi-minus-circle': expanded
        });

        return (
            <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{color:'#ffffff',backgroundColor:'#0065cb'}} tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

	return (
		isKarlProfileType ? <>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row className="pageTitleRow">
						<Col className="alignHeaders">
							<CustomFontText className="pageTitleFont pageName pageHeaderFont task">Task</CustomFontText>
						</Col>
					</Row>
				</Stack>
				{
					commonServerError ?
						<div>
							<p className="genericErrorColor"><span>{commonServerError}</span></p>
						</div> : null
				}
				<div className="taskWorkBookPagenator datatable-filter-demo noMaxWidthTable cust-tree-tbl task-workbook treetable-responsive-demo">
					<div className="lazy-loading-workbook-task card reportsTableVal">
						<TreeTable
							value={taskData}
							onRowClick={(event) => handleRowClick(event)}
							paginator={false}
							//rows={25}
							//rowsPerPageOptions={[25,50,100]}
							footer={footer}
							selectionMode="single"
							header={header}
							sortField={sortField}
							sortOrder={sortOrder}
							onSort={handleColumnSort}
							//globalFilter={glFilter}
							togglerTemplate={togglerTemplate}
							rowClassName={rowClassName} >
							<Column sortable field="startDate" className="startDate-Picker" style={{ width: '9em' }} header="Start Date" expander></Column>
							<Column bodyClassName="sm-invisible" sortable sortField="dueDate" field="dueDate" style={{ width: '8em' }} header="Due Date"></Column>
							<Column bodyClassName="sm-invisible" sortable field="description" className="textAlignStart" style={{ width: '23em',textAlign:"left",paddingLeft:"5px" }} header="Description"></Column>
							<Column bodyClassName="sm-invisible" sortable field="dealNumber" header="Deal#"></Column>
							<Column bodyClassName="sm-invisible" sortable field="customer" header="Customer"></Column>
							<Column bodyClassName="sm-invisible" sortable field="assignedByDepartmentId" header="Assigned By Dept"></Column>
							<Column bodyClassName="sm-invisible" sortable field="assignedBy" header="Assigned By"></Column>
							<Column bodyClassName="sm-invisible" sortable field="assignedToDepartmentId" header="Assigned To Dept"></Column>
							<Column bodyClassName="sm-invisible" sortable field="assignedTo" header="Assigned to"></Column>
							<Column bodyClassName="sm-invisible" sortable field="isCompleted" style={{ width: '6em' }} header="Status"></Column>
							<Column bodyClassName="sm-invisible" field="actions" style={{ width: '5em' }} header="Actions" body={actionBodyTemplate}></Column>
							{
								totalRecords > 0 && <Column style={{ display: "none" }} field="hiddenCol"></Column>
							}
						</TreeTable>
					</div>
				</div>
			</Stack>
		</> : null
	);
};
