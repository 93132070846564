import * as React from "react";
import { useState } from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
// import { ConfigureGroupPivot } from "components/pivots/configureGroup/ConfigureGroupPivot";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import {
  /*handleViewGroup,*/ /*handleUpdateGroup*/
  createGroupAndAssignEmployees,
} from "store/groups/groups.action";
import {
  ISpinnerStyles,
  Spinner,
  SpinnerSize,
} from "@fluentui/react/lib/Spinner";
import { cancelIcon, iconButtonStyles } from "constants/styles";
import ConfigureGroupAssignTable from "components/table/configureGroup/ConfigureGroupAssignTable";

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  noActions: boolean;
}

const spinnerStyles: ISpinnerStyles = {
  root: {
    //position: "absolute",
    //right: 150,
  },
};

export const AddGroup: React.FunctionComponent<IProps> = ({
  isOpen,
  onDismiss,
  noActions,
}) => {
  const [groupName, setGroupName] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState(null);

  const updateLoading = useSelector(
    (state: AppState) => state.groups.loadingUpdate
  );

  const dispatch = useDispatch();

  const handleGroupNameChange = (e: any) => {
    setGroupName(e.target.value);
  };

  const callback = () => {
    onDismiss();
    setGroupName("")

  }
  const handleSaveChanges = () => {
    dispatch<any>(
      createGroupAndAssignEmployees(groupName, selectedEmployees, callback)
    );
  };

  const renderSpinner = () => {
    return updateLoading ? (
      <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <Modal
      className="configureGroup"
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <CustomFontText>Configure Group</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <TextField
          label="Group Name"
          value={groupName}
          onChange={handleGroupNameChange}
        />
        <div style={{ paddingTop: 20 }}>
          <ConfigureGroupAssignTable
              noActions={noActions}
            onSelection={(selection) => {
              setSelectedEmployees(selection);
            }}
          />
          {/*<ConfigureGroupPivot />*/}
        </div>
      </div>
      <div className={contentStyles.footer}>
        <Stack
          tokens={styles.buttonTokens}
          horizontal
          // horizontalAlign="space-between"
          horizontalAlign="end"
        >
          {/*<div>*/}
          {/*  <DefaultButton*/}
          {/*    onClick={() => {}}*/}
          {/*    iconProps={{ iconName: "Delete" }}*/}
          {/*    text="Delete Group"*/}
          {/*  />*/}
          {/*</div>*/}
          <Stack tokens={{ childrenGap: 5 }} horizontal>
            <DefaultButton text="Close" onClick={onDismiss} />
            <PrimaryButton
              // disabled={!formik.dirty || !formik.isValid}
              onRenderIcon={renderSpinner}
              text="Save Changes"
              onClick={handleSaveChanges}
            />
          </Stack>
        </Stack>
      </div>
    </Modal>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    // width: "50%",
    // width: 2000,
    // display: 'flex',
    // flexFlow: 'column nowrap',
    // alignItems: 'stretch',
  },
  header: {
    // width: '800px',
    flex: "1 1 auto",
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "20px 30px",
  },

  body: {
    // width: 600,
    // flex: "4 4 auto",
    // padding: "0 24px 24px 24px",
    //   padding: '75px',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: "40px",
    // overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  footer: {
    padding: "0px 30px",
    paddingBottom: 40,
  },
});
