import React from "react";
import Dashboard from "pages/dashboard/Dashboard";
import { AdminAccountPage } from "pages/adminAccountPage/AdminAccountPage";
import { useSelector } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { AppState } from "store";
import {
  // isPermitted,
  // getModulePermissions,
  isEmployeePermitted,
} from "utils/permissions/permissionsHelpers";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { karlProfileURLKeys } from 'constants/constants';

interface IProtectedRoute extends RouteProps {
    permissionModule: any;
    permissionRequired?: boolean;
    component?: any;
    path?: any;
    [key: string]: any; // Allow any other additional props
}

// keyof typeof permissionsModules

const ProtectedRoute: React.FunctionComponent<IProtectedRoute> = ({
  component,
  permissionModule,
  path,
  permissionRequired = false,
  ...options
}) => {
  const user = useSelector((state: AppState) => state.auth.userToken);
  const u = useSelector((state: AppState) => state.userProfile.userProfile);
    
  // const permissions =
  // u && u.auths ? getModulePermissions(u.auths, permissionModule) : [];
    const pathName = path && path.toString().replace('/', '');
    if (u && u.roles && u.roles.includes('ROLE_ADMIN')) {
        return <Route {...options} element={permissionModule === 'Accounts' ? component : AdminAccountPage} />;
    } else if (u && u.profileType && isKarlProfile(u.profileType)) {
        

        const finalComponent = user && isKarlProfile(u.profileType)
            /*   isPermitted(
                  permissions,
                  eTypes.READ,
                  permissionModule,
                  u.roles ? u.roles : []
              ) */
            && (!permissionRequired || 
            isEmployeePermitted(
                u.aggregatedDefaultPolicies ? u.aggregatedDefaultPolicies : [],
                permissionModule,
                u.roles ? u.roles : []
            )) 
            && karlProfileURLKeys.includes(pathName)
            ? component
            : Dashboard;
        return <Route {...options} element={finalComponent} />;
    }
    else {
     const finalComponent = user &&
        /*   isPermitted(
              permissions,
              eTypes.READ,
              permissionModule,
              u.roles ? u.roles : []
          ) */
          (isEmployeePermitted(
            u.aggregatedDefaultPolicies ? u.aggregatedDefaultPolicies : [],
            permissionModule,
            u.roles ? u.roles : []
         ) || (isKarlProfile(u.profileType))) 
          ? component
          : Dashboard;
      return <Route {...options} element={finalComponent} />;
    }
   
		  
  // user && isPermitted(permissions, eTypes.READ, permissionModule, u.roles ? u.roles : []) ? navigate(typeof options.path === 'string' ? options.path : options.path[0]) : navigate('/dashboard');
 // return <Route {...options} component={finalComponent} />;
};

export default ProtectedRoute;
