import React from "react";
import { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
  Stack,
  IStackStyles,
  Link,
  //Dropdown,
  PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import masterDropdownValues from "constants/masterDropdownValues";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { DataTable } from 'primereact/datatable';  
import { Column } from 'primereact/column';
import { handleGetSalesPersonFinanceReports } from "store/dealTracker/dealTracker.action"; 
import { handleGetSelectList } from "store/selectList/selectList.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';

const container: IStackStyles = {
	root: {
		marginTop: 10,
	},
};
//const credentialModuleName = "Location";
export const SalesPersonFinanceReports = () => {
  const dispatch = useDispatch();
  //const navigate = useNavigate ();

  const currentMonth = new Date().getMonth() +1
  const currentYear = new Date().getFullYear()
  const financeProducts = useSelector((state: AppState) => state.selectList.financeProducts) as any;
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const locations = useSelector((state: AppState) => state.locations.locations) as any; 
  const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
  const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
  const [endMonthFilter, setEndMonthFilter] = React.useState(null);
  const [yearFilter, setYearFilter] = React.useState(currentYear);
  const [cityDealerList, setCityDealerList] = React.useState([]);
  const [isPrimaryLocation, setIsPrimaryLocation] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState(!isPrimaryLocation ? isPrimaryLocation: 0);
  const [isFinalData,setIsFinalData] = useState([]);
  const [exportData,setExportData] = useState(null);
  const [endMonthOption, setEndMonthOption] = useState([]);
  const [financeProductList, setFinanceProductList] = React.useState([]);
  const [financeResData, setFinanceResData] = React.useState(null);
  const monthsOptions = masterDropdownValues.getMonth;
  const ExcelJS = require('exceljs');
const { saveAs } = require('file-saver');

//const workbook = new ExcelJS.Workbook();
  /// const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
  ///   return { key: item, text: item };
  /// });

    const handleStartMonthDropdownChange = (e) => {

     handleEndMonthOptions(e.value);
    };

    const handleEndMonthDropdownChange = (e) => {

     setEndMonthFilter(e.value)

    };

    const handleYearDropdownChange = (e) => {

     setYearFilter(e.value)

    };

    const handleLocationDropdownChange = (e) => {
       setLocationFilter(e.value)

    };

    const handleEndMonthOptions = (startMonthFilter) => {
        if (startMonthFilter > 0 ) {
            if (monthsOptions) {
               const results = monthsOptions.filter(x => x.key > startMonthFilter);
                setEndMonthOption(results);
            }
            setStartMonthFilter(startMonthFilter)
            if (endMonthFilter && startMonthFilter >= endMonthFilter) {
                setEndMonthFilter(null);
            }
        } 
    }


    const handleFilters = () => {
        if (startMonthFilter || endMonthFilter || yearFilter || locationFilter ) {

             const formData = {
               startMonth:startMonthFilter,
               endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
               year: yearFilter,
               locationId:locationFilter
              };
            dispatch<any>(handleGetSalesPersonFinanceReports({
            
                formData,
                callback: (response) => {
                        if(response.length > 0){
                                setFinanceResData(response)
                        }else{
                             setIsFinalData(null)
                        }
            
                    } 
            }));
            
        } 
    }

    const handlePopulateReports = (response) => {

     if (response && response.length) {

         let personData =[];
         
         response.forEach(function (response, index) {
               
               let SalesPersonVal ={
                      salesPerson: response.firstName +' '+response.lastName,
                      totalUnit:   response.totalUnits
               }
               financeProducts.forEach(function(item,index){
               const data = response.financeProducts.find(x => {return x.financeProductId === item.id});
                    SalesPersonVal[item.name] = data?data.units.toFixed(1):'0.0';
               });
               personData.push(SalesPersonVal)
         });
         setIsFinalData(personData);


     } 
     else{

         setIsFinalData(null)
     }
   };

  useEffect(() => {

    dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"financePerson" } })

    dispatch<any>(handleGetLocations());
    dispatch<any>(handleGetSelectList("financeProducts",1));
    handleEndMonthOptions(startMonthFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonthFilter,endMonthFilter ,yearFilter,locationFilter]);

  useEffect(() => {
        handlePopulateReports(financeResData);
        setExportData(financeResData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeProductList,financeResData]);

  useEffect(()=> {

            let list = [];
            if (locations.results.length > 0) {
                locations.results.map((item: any) => {
                    return list.push({ key: item.id, text: (item.legalName) })
                });
                list.unshift({ key: 0 ,text: 'All'});
                setCityDealerList(list);
            }
   }, [locations])

   //function dynamicSort(property) {
   //    var sortOrder = 1;
       
   //    if(property[0] === "-") {
   //        sortOrder = -1;
   //        property = property.substr(1);
   //    }
       
   //    return function (a,b) {
   //        if(sortOrder === -1){
   //            return b[property].localeCompare(a[property]);
   //        }else{
   //            return a[property].localeCompare(b[property]);
   //        }        
   //    }
   //}

   useEffect(()=> {
   
       //var list = financeProducts.sort(dynamicSort("name"));
       setFinanceProductList(financeProducts)
       
   }, [financeProducts]);

   useEffect(() => {
     
       if (user && user.roles) {
           if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
               setIsPrimaryLocation(user.primaryLocationId);
               setLocationFilter(user.primaryLocationId);
           }
       }
   }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

//    function getSheetData(data, header) {

//       var fields = Object.keys(data[0]);
//       var sheetData = data.map(function (row) {
//         return fields.map(function (fieldName) {
//           return row[fieldName]

//         });
//       });
//       sheetData.unshift(header);
//       return sheetData;
//    }


   const exportExcel = () => {
    if (exportData) {
        let data = [];
        let header = ["Sales Person", "Total Unit"];

        financeProducts.forEach(function (item, index) {
            header.push(item.name);
        });

        exportData.forEach(function (response, index) {

            let personVal = {
                salesPerson: response.firstName + ' ' + response.lastName,
                totalUnit: response.totalUnits
            };
            financeProducts.forEach(function (item, index) {

                const data = response.financeProducts.find(x => {
                    return x.financeProductId === item.id
                });
                let d = data?.units?.toFixed(1);
                personVal[item.name] = data ? Number(d) : (Number('0.0'));

            });
            data.push(personVal);
        });

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sales Person Finance Reports');

        // Add header row
        worksheet.addRow(header);

        // Add data rows
        data.forEach(row => {
            worksheet.addRow(Object.values(row));
        });

        // Generate buffer
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'Sales Person Finance Reports.xlsx');
        });
    }
};

  // const saveAsExcelFile = (buffer, fileName) => {
  //      import('file-saver').then(module => {
  //          if (module && module.default) {
  //              let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //              let EXCEL_EXTENSION = '.xlsx';
  //              const data = new Blob([buffer], {
  //                  type: EXCEL_TYPE
  //              });
  //
  //              module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //          }
  //      });
  // }

   const handleResetWorkbook = () => {   

       setStartMonthFilter(currentMonth);
       setEndMonthFilter(null);
       setYearFilter(currentYear);
       setLocationFilter(!isPrimaryLocation  ? isPrimaryLocation:0)
   };

   const handleEditLocation = (id) => {
   
   };

   const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                handleEditLocation(data.value[0].rowData.id);
            }
        }
   };

   const SalesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person</span>
                {rowData.salesPerson}
            </React.Fragment>
        );
   };


   const totalUnitBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total Unit</span>
                {rowData.totalUnit.toFixed(1)}
            </React.Fragment>
        );
   };


  return (

    	isKarlProfileType ? <>
          <div className="reportFinanceProductWidth">
			<Stack styles={container} tokens={{ childrenGap: 10 }}>

				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							 <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Sales Person Finance Report </CustomFontText>
						</Col>
					</Row>
				</Stack>
				<div className="reportsTable finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                    <div >
                      <div className=" table-header "  style={{marginBottom:"20px",display:"block"}} > 
                          <Row  style={{marginLeft:"-8px"}}>
                               <Col md={12} >
                                     <Row>
                                             <Col md={2}  className="columnBtwSpace"  >
                                              {/*<Dropdown
                                                       id="startMonth"
                                                       label="Start Month"
                                                       selectedKey={startMonthFilter}
                                                       options={masterDropdownValues.getMonth}
                                                       onChange={handleStartMonthDropdownChange}
                                                       placeholder=""
                                                       className=" dropDownBorderColor" 
                                                   />*/}
                                              <label className="lbl-text">Start Month</label>
                                              <PRDropdown
                                                  id="startMonth"
                                                  value={startMonthFilter}
                                                  options={masterDropdownValues.getMonth}
                                                  onChange={handleStartMonthDropdownChange}
                                                  optionLabel="text"
                                                  optionValue="key"
                                                  appendTo="self"
                                                  className="custom-p-dropdown write-ups-location-dd"
                                                  filter={false}
                                              />
                                             </Col>
                                             <Col md={2}  className="columnBtwSpace">
                                              {/*<Dropdown
                                                       id="endMonth"
                                                       label="End Month"
                                                       selectedKey={endMonthFilter }
                                                       options={endMonthOption}
                                                       onChange={handleEndMonthDropdownChange}
                                                       placeholder=""
                                                   />*/}
                                              <label className="lbl-text">End Month</label>
                                              <PRDropdown
                                                  id="endMonth"
                                                  value={endMonthFilter}
                                                  options={endMonthOption}
                                                  onChange={handleEndMonthDropdownChange}
                                                  optionLabel="text"
                                                  optionValue="key"
                                                  appendTo="self"
                                                  className="custom-p-dropdown write-ups-location-dd"
                                                  filter={false}
                                              />
                                             </Col>
                                             <Col md={2}  className="columnBtwSpace">
                                              {/*<Dropdown
                                                       id="year"
                                                       label="Year"
                                                       //required={true}
                                                       selectedKey={yearFilter}
                                                       //value={yearFilter}
                                                       options={yearValues}
                                                       onChange={handleYearDropdownChange}
                                                       placeholder=""
                                                   /> */}
                                              <label className="lbl-text">Year</label>
                                              <PRDropdown
                                                  id="year"
                                                  value={yearFilter}
                                                  options={masterDropdownValues.from2022years}
                                                  onChange={handleYearDropdownChange}
                                                  optionLabel="text"
                                                  optionValue="key"
                                                  appendTo="self"
                                                  className="custom-p-dropdown write-ups-location-dd"
                                                  filter={false}
                                                  resetFilterOnHide={true}
                                              />
                                             </Col>
                                             <Col md={2}  className="columnBtwSpace">
                                              {/*<Dropdown
                                                       id="location"
                                                       label="Location"
                                                       selectedKey={locationFilter}
                                                       options={cityDealerList}
                                                       onChange={handleLocationDropdownChange}
                                                       placeholder=""
                         
                                                   /> */}
                                              <label className="lbl-text">Location</label>
                                              <PRDropdown
                                                  id="location"
                                                  value={locationFilter}
                                                  options={cityDealerList}
                                                  onChange={handleLocationDropdownChange}
                                                  optionLabel="text"
                                                  optionValue="key"
                                                  appendTo="self"
                                                  className="custom-p-dropdown write-ups-location-dd"
                                                  filter={false}
                                                  resetFilterOnHide={true}
                                              />
                                             </Col>
                                             <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                                   <PrimaryButton onClick={handleResetWorkbook} >
                                                      Reset
                                                   </PrimaryButton>
                                             </Col>
                    
                                     </Row>
                               </Col>
                          </Row>
                    
                      </div>
                      <div  style={{marginTop:"-15px"}}>  
                            <strong className="blueShadedText readField"> For single month, only select start month </strong>
                      </div>
                    
                       <div style={{float:"right",fontSize:"16px",marginTop:"-30px"}}>
                           <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
		                          Export to Excel
		                   </Link>    
                           
                      </div>
                    </div>
					<div className="reportsTable" style={{width:"100%"}}>
                         <DataTable
                             value={isFinalData}
                             //header={header}
                             className="reportsTable p-datatable-customers "
                             paginator={false}
                             rows={100}
                             //cellSelection
                             onSelectionChange={e => captureCellSelection(e)}>
                             <Column field="SalesPerson" header="Sales Person" body={SalesPersonBodyTemplate} sortable />
                             
                             {financeProductList && financeProductList.filter(x => x.name.toLowerCase() === 'reserve').map((item, index) => (
                                <Column key={"Column-" + index} field= {item.name} header={item.name}  sortable />
                                
                             ))}

                             {financeProductList && financeProductList.filter(x => x.name.toLowerCase() !== 'reserve').map((item, index) => (

                                 <Column key={"Column-" + index} field={item.name} header={item.name}   sortable />
                                
                             ))}
                             <Column field="totalUnit" header="Total Units" body={totalUnitBodyTemplate} sortable />
                         </DataTable>
					</div>
				</div>
			</Stack>
        </div>
		</> : null
  );
};
