import React, { FunctionComponent, useEffect } from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    //contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
//import { getAddreessByZip } from "services/api/smartyStreets";
import { handlePutLiveRetailReports } from "store/inventory/inventory.action";
//import CurrencyInput from 'react-currency-input-field'
import { HandleZipCodeFlatData } from "store/shared/shared.action";

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 600,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 80,
        paddingRight: 80,
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});

interface IProps {
    onSubmitFormik: (formikData: any) => void;
    onDismiss: () => void;
    retailDetail: any;
}

export const UpdateLiveRetailDialog: FunctionComponent<IProps> = ({
    onSubmitFormik,
    onDismiss,
    retailDetail
}) => {
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    const dispatch = useDispatch();
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });
    const [qvalueZipApiMsg, setQValueZipApiMsg] = React.useState("");
    const digitPattern = /^\d+$/;
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
      return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    // const [zipFlag, setZipFlag] = React.useState(false);
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 

    const formik = useFormik({
        initialValues: {
            miles: '50',
            zip: '',
            minMileage: '',
            maxMileage: '',
            dayRange: '30',
            state: ''
        },
        validationSchema: Yup.object({
            miles: Yup.number().required("Required"),
            zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
            minMileage: Yup.string(),
            maxMileage: Yup.string(),
            dayRange: Yup.number().required("Required"),
            state: Yup.string()
        }),
        onSubmit: (values) => {
            let data = {
                id: retailDetail.id,
                miles: parseInt(values.miles),
                minMileage: values.minMileage ? parseInt(values.minMileage.toString().replace(',', '')) : 0,
                maxMileage: values.maxMileage ? parseInt(values.maxMileage.toString().replace(',', '')) : null,
                dayRange: parseInt(values.dayRange),
                zip: values.zip,
                state: values.state
            };
            if (data.maxMileage === null) {
                delete data.maxMileage;
            }

            dispatch<any>(handlePutLiveRetailReports({
                vehInfo: data,
              callback: (response) => {
                if (response) {
                  // console.log(' GET LIVE RETAIL REPORTS', response)
                  if (response.stats && response.stats.trim) {
                    // settrimSoldStats(response.stats.trim.sold_statistics);
                    // settrimActiveStats(response.stats.trim.active_statistics);
                  }
                  if (response.stats && response.stats.allTrims) {
                    // setallTrimSoldStats(response.stats.allTrims.sold_statistics);
                    // setallTrimActiveStats(response.stats.allTrims.active_statistics);
                  }
                  onSubmitFormik(response);
                  // set either ACTIVE or SOLD
                }
              }
            }));
        },
    });

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleZipCodeChangeForDealer = (e) => {
        // setZipFlag(true);
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            formik.setValues(
                {
                    ...formik.values,
                    zip: e.target.value
                }
            );
        }
    };

    const handleMileageChange = (e) => {
        if (e.target.value === '' || (digitPattern.test(e.target.value) && parseInt(e.target.value) > 0))
        {
            formik.setValues(
              {
                  ...formik.values,
                  'miles': e.target.value
              }
          );
        } 
    }

    const handleUdateQValue = () => {
        formik.handleSubmit();
    }

    useEffect(() => {
        console.log('zip changed')
        let zipFields = formik.values.zip;
        if (zipFields.length === 5 && !isNaN(zipFields as any)) {
            dispatch<any>(switchGlobalLoader(true));           
             dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (res) => {
                        let state = '';
                        let errorMsg = 'Invalid ZIP Code.';
                        if(res){
                            state = res.State;
                            errorMsg = ''
                        }
                        formik.setValues(
                         {
                             ...formik.values,
                             'state': state
                         });
                         setQValueZipApiMsg(errorMsg);
                    }
                    
                }));
            

            //getAddreessByZip(zipFields) as any).then((response) => {
            //    console.log('response state', response)
            //    if (response.Status) {
            //        formik.setValues(
            //            {
            //                ...formik.values,
            //                'state': response.State
            //            }
            //        );
            //        // setlocationZipApiMsg("");
            //        // updateForm("State", response.State);
            //    }
            //    else {
            //        // setZipApiMsg(response.ErrorMessage);
            //        // setlocationZipApiMsg(response.ErrorMessage);
            //        formik.setValues(
            //            {
            //                ...formik.values,
            //                'state': ""
            //            }
            //        );
            //    }
            //    dispatch<any>(switchGlobalLoader(false));
            //});
        }
        else {
            formik.setValues(
                {
                    ...formik.values,
                    'state': ""
                }
            );
            // setlocationZipApiMsg("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.zip]);

    useEffect(() => {
        if (locations && locations.results.length > 0) {
            // eslint-disable-next-line array-callback-return
            const location = locations.results.sort((a, b) => {
                if (a.id !== b.id) {
                    return a.id - b.id
                }
            });
            // setZipFlag(false);
            formik.setValues(
                {
                    ...formik.values,
                    'zip': location[0].zip,
                    'state': location[0].state
                }
            );
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    useEffect(() => {
        console.log('retailDetail UPDATE POPUP', retailDetail);
        if (retailDetail) {
            formik.setValues(
                {
                    ...formik.values,
                    'miles': retailDetail.miles,
                    'minMileage': retailDetail.minMileage,
                    'maxMileage': retailDetail.maxMileage,
                    'zip': retailDetail.zip,
                    'state': retailDetail?.state,
                    'dayRange': retailDetail.days
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retailDetail]);

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            className="createDealRecord createCustomerRecordWidth  updateEmployeeDialogHeight"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="font-lexend formTitle">Order Live Retail Market</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body +" deal-popup-body"}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                    className=" updateLiveRetailPopup"
                >
                    <Row >
                        <Col md={5.5} className="columnBtwSpace">  
                            {/*<label className="alignItemsLabelWith alignItemsLabelMob alignItemsLabelwidth">Radius</label>*/}
                            <TextField
                                id="miles"
                                name="miles"
                                label="Search Miles"
                                required={true}
                                // suffix="miles"
                                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                                value={formik.values.miles}
                                onChange={(e) => handleMileageChange(e)}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.miles &&
                                    formik.errors.miles &&
                                    formik.errors.miles.toString()
                                }
                            // style={{ width: "calc(100% - 130px)" }}
                            />
                        </Col>
                        <Col md={1} className="alignItemsLabelMob columnBtwSpace"   style={{marginTop:"10px",textAlign:"center"}} >
                           <label className="alignItemsLabel alignItemsLabelMob alignItemsLabelwidth ofTxt">of</label>
                        </Col>
                        <Col md={5.5} className="columnBtwSpace">  
                            <TextField
                                id="zip"
                                name="zip"
                                label="Zip"
                                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                                value={formik.values.zip}
                                required={ true}
                                onChange={(e) => handleZipCodeChangeForDealer(e)}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                formik.touched.zip && formik.errors.zip ?
                                formik.errors.zip.toString() : qvalueZipApiMsg
                                }
                            // style={{ width: "calc(100% - 130px)" }}
                            />
                        </Col>
                    </Row>
                     <Row >
                        <Col md={5.5} className="columnBtwSpace">  
                            
                            <TextField
                               id="dayRange"
                               name="dayRange"
                               label="Sold in last (days)"
                               //suffix="days"
                               required={true}
                               // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                               value={formik.values.dayRange}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               errorMessage={
                                   formik.touched.dayRange &&
                                   formik.errors.dayRange &&
                                   formik.errors.dayRange.toString()
                               }
                           // style={{ width: "calc(100% - 130px)" }}
                           />
                        </Col>
                        <Col md={1} className="alignItemsLabelMob columnBtwSpace"   style={{marginTop:"10px",textAlign:"center"}} >
                          
                        </Col>
                        <Col md={5.5} className="columnBtwSpace">  
                           
                        </Col>
                    </Row>
                    
                   {/*<Row className="inventoryRowSection mob-row" >
                       <Col className="inventoryColDivider col-right-align" sm={6} md={6} xs={6}>
                           {/*<TextField
                               id="minMileage"
                               name="minMileage"
                               label="Min. Mileage"
                               // suffix="miles"
                               // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                               value={formik.values.minMileage}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                           // style={{ width: "calc(100% - 130px)" }}
                           />*/}
                   {/*        <CurrencyInput
                               customInput={TextField}
                              // thousandSeparator={true}
                               id="minMileage"
                               name="minMileage"
                               label="Vehicle Min Odo"
                               className="alignItemsMinMilegeSoldwidth"
                               decimalScale={0}
                               value={formik.values.minMileage}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                           />
                       </Col>
                       <Col className="inventoryColDivider alignItemsSection" sm={6} md={6} xs={6}>
                           <label className="alignItemsLabel alignItemsLabelMob alignItemsLabelwidth ofTxt">to</label>
                           {/*<TextField
                               id="maxMileage"
                               name="maxMileage"
                               label="Max. Mileage"
                               // suffix="miles"
                               // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                               value={formik.values.maxMileage}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                           // style={{ width: "calc(100% - 130px)" }}
                           />*/}
                       {/*    <CurrencyInput
                               customInput={TextField}
                              // thousandSeparator={true}
                               id="maxMileage"
                               name="maxMileage"
                               label="Vehicle Max Odo"
                               decimalScale={0}
                               value={formik.values.maxMileage}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                           />
                       </Col>
                   </Row>*/}
                   {/*<Row className="inventoryRowSection mob-row"  style={{float:"left"}}>
                       <Col className="inventoryColDivider col-right-align" sm={6} md={6} xs={6}>
                           <TextField
                               id="dayRange"
                               name="dayRange"
                               label="Sold in last"
                               //suffix="days"
                               required={true}
                               // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                               value={formik.values.dayRange}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               errorMessage={
                                   formik.touched.dayRange &&
                                   formik.errors.dayRange &&
                                   formik.errors.dayRange.toString()
                               }
                           // style={{ width: "calc(100% - 130px)" }}
                           />
                           
                           
                       </Col>
                       <Col sm={6} md={6} xs={6} className="sold-day-txt alignItemsSection"><label>days</label></Col>
                   </Row>*/}
                    <Row className="inventoryRowSection mob-row rowPaddingBtwSpace">
                        <Col className="inventoryColDivider col-center-align" sm={12} md={12} xs={12} style={{marginTop:"15px",marginBottom:"15px"}}>
                            <PrimaryButton
                                disabled={!formik.dirty || !formik.isValid}
                                onRenderIcon={renderSpinner}
                                text="Order Live Retail"
                                onClick={handleUdateQValue}
                            />

                        </Col>
                    </Row>
                </Stack>
            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
