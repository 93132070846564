import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
	Stack,
	IStackStyles,
	TextField,
	Link
} from "@fluentui/react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
	//useDispatch,
	useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
	getModulePermissions,
	isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetAppraisals } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { useNavigate , useLocation } from "react-router-dom";
import masterDropdownValues from "../../constants/masterDropdownValues";
// import { convertUTCToLocalDateOnlyModified } from "utils/dateTimeFunctions";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import moment from "moment";

const container: IStackStyles = {
	root: {
		marginTop: 10,
		minHeight:500
	},
};

const credentialModuleName = "DealTracker";
export const AppraisalWorkbookPage = () => {
	const dispatch = useDispatch();
    const location = useLocation();

	const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
	//const [appointmentId, setAppointmentId] = useState(0);
	const [appraisalData, setAppraisalData] = useState([]);
	const [commonServerError, setcommonServerError] = useState(null);
	const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
	const appraisals = useSelector((state: AppState) => state.dealTracker.appraisals) as any;
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const navigate = useNavigate ();
	const [cityDealerList, setCityDealerList] = React.useState();

	const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

	const [credentials, setCredentials] = useState([]);
	const yearRange = "1980:" + (new Date().getFullYear()+1);
	const isMobile = useMediaQuery({ maxWidth:960 });

	const [glFilter, setGLFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
	const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
	const [primaryLocation, setPrimaryLocation] = useState(0);
	const [statesFlag, setStatesFlag] = useState(null);
	const[sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState('appraiserFlag');
	const [totalRecords, setTotalRecords] = useState(null);

     const handleFirstNameFilter = (e) => {
		setGLFilter(e.target.value);
        handleFilters(locationFilter, logDateFromFilter ,logDateToFilter,e.target.value);
		//dt.current.filter(val, 'firstName', 'contains');
	};

	//const handleLocationFilter = (val: any) => {
	//	setLocationFilter(val.target.value);
    //    handleFilters(glFilter,val.target.value ,logDateFromFilter,logDateToFilter);
	//	//dt.current.filter(val, 'firstName', 'contains');
	//};

	const onLocationChange = (e: { value: any }) => {
		setLocationFilter(e.value);
		handleFilters(e.value ,logDateFromFilter,logDateToFilter,glFilter);
    }


    const handleFromLogDateChange = (val: any) => {
		setLogDateFromFilter(val.target.value)
		handleFilters(locationFilter, val.target.value ,logDateToFilter,glFilter);
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
        handleFilters(locationFilter,logDateFromFilter,val.target.value,glFilter );
    };



    const handleFilters = (locationFilt,logDateFromFilter,logDateToFilter,glFilter) => {
	
        if (locationFilt || logDateFromFilter || logDateToFilter || glFilter) {

            let results = appraisals;
            if (results) {
				if (locationFilt > 0) {
					results = results.filter(x => x.deal.location.id === locationFilt);
				}
				/** Log date Filter**/
				if (logDateFromFilter) {
					results = results.filter(x => new Date(x.appraisalDate) >= new Date(logDateFromFilter));
					//results = results.filter(x => x => x.createdAt <= convertLocalToUTC (logDateFromFilter)); 

				}

				if (logDateToFilter) {
					let rawToLogDate = moment(new Date(logDateToFilter)).format("MM/DD/YYYY");
					// subst = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0,rawExpenseDate.indexOf("T")) : rawExpenseDate;

					results = results.filter(x =>  {
						// let subst = '';
						let rawApprDate = moment(new Date(x.appraisalDate)).local().format("MM/DD/YYYY");
						// subst = rawApprDate.indexOf("T") ? rawApprDate.substr(0,rawApprDate.indexOf("T")) : rawApprDate;

						if (new Date(rawApprDate) <= new Date(rawToLogDate)) {
							return true;
						}
						return false 
					});
					// results = results.filter(x => new Date(x.appraisalDate) <= new Date(logDateToFilter));

				}

				if (glFilter && glFilter.length > 1) {

					const filterKeys = ['appraiser', 'vehicleInfo', 'rollBack', 'appraisalStatus', 'higherBidder', 'subject', 'salesManager', 'salesPerson1Name', 'dealNumber', 'customerName','hiddenCol','vin'];
                    const multiFilter = (data = [], filterKeys = [], value = '') => data.filter((item) => filterKeys.some(key => item[key]?.toString().toLowerCase().includes(value) && item[key]));
                    results = multiFilter(results, filterKeys, glFilter?.toLowerCase());
                }

                // expandableTableRows(results);
				setAppraisalData(results);
				setTotalRecords(results.length);
            }
            
        } else {
            // expandableTableRows(vehicleList);
			setAppraisalData(appraisals);
			setTotalRecords(appraisals.length);
        }
    }

	useEffect(() => {
		if (appraisals && statesFlag) {
			if (appraisals.length > 0 && user.profileType) {
				handlePopulateAppraisals();
			}
			else {
				setAppraisalData([]);
				setTotalRecords(appraisals.length);
			}
		}
	}, [appraisals, statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

	useEffect(() => {
		if (user && user.profileType && appraisals && appraisals.length > 0) {
			handlePopulateAppraisals();
		}
		else {
			setAppraisalData([]);
		}

		if (user && user.roles) {
			if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
				setPrimaryLocation(user.primaryLocationId);
				setLocationFilter(user.primaryLocationId);
			}
		}
	}, [user]);// eslint-disable-line react-hooks/exhaustive-deps

	const handlePopulateAppraisals = () => {
		for (var i in appraisals) {
			let deal = appraisals[i].deal;
			let customer = deal.customer;
			let hiddenStr = '';
			let dealTradeInAppraisalsInfo = appraisals[i].dealTradeInAppraisals ? appraisals[i].dealTradeInAppraisals.at(-1) : null;
			appraisals[i]["appraisalDate"] = (appraisals[i].createdAt);
			appraisals[i]["appraiser"] = dealTradeInAppraisalsInfo ? (dealTradeInAppraisalsInfo.employee.firstName + ' ' + dealTradeInAppraisalsInfo.employee.lastName) : null;
			appraisals[i]["rollBack"] = dealTradeInAppraisalsInfo && currencyNegValFractionalFormat(dealTradeInAppraisalsInfo.rollback);
			appraisals[i]["vehicleInfo"] = appraisals[i].vehicle && appraisals[i].vehicle?.year + ' ' + appraisals[i].vehicle?.model;
			appraisals[i]["appraiserFlag"] = dealTradeInAppraisalsInfo ? 1 : 0;
			appraisals[i]["higherBidder"] = appraisals[i].appraisalBidder?.name;
			appraisals[i]["subject"] = appraisals[i].subjectToSee ? "Yes" : appraisals[i].subjectToSee === false ? "No" : "";
			appraisals[i]["appraisalStatus"] = getStatus(appraisals[i].status);
			appraisals[i]["dealNumber"] = deal.dealNumber;
			appraisals[i]["customerName"] = customer.businessName ? customer.businessName : (customer.firstName + ' ' + customer.lastName);
			appraisals[i]["salesPerson1Name"] = getName(deal.salesPerson1);
			appraisals[i]["salesManager"] = getName(deal.salesManager);
			if (appraisals[i].vehicle && appraisals[i].vehicle.vin)
				appraisals[i]["vin"] = appraisals[i].vehicle.vin;

			if (deal.vehicle)
				hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
			if (deal.salesPerson2)
				hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
			if (deal.financePerson1)
				hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
			if (deal.financePerson2)
				hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
			hiddenStr = hiddenStr + ' ' + customer?.customerNumber;
			appraisals[i]["hiddenCol"] = hiddenStr;
		}
		
		handleFilters(locationFilter, logDateFromFilter, logDateToFilter,glFilter);

		//if (locationFilter) {
		//	const results = appraisals.filter(x => x.deal.location.id === locationFilter);
		//	setAppraisalData(results);
		//} else {
		//	setAppraisalData(appraisals);
		//}
	};

	const getStatus = (id) => {
		let item = masterDropdownValues.tradeInStatus.filter(x => x.key === id);
		if (item.length === 1) {
			return item[0].text;
		}
		return '';
	};

	const getName = (person) => {
		if (person) {
			return person.firstName + ' ' + person.lastName;
		}
		return '';
	};

	const MINUTE_MS = 300000;
	useEffect(() => {
		setStatesFlag(null);
		setSortOrder(1);
		setSortField('appraiserFlag');
		let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
		if (user && user.auths && !isUserMaster(user.roles)) {
			setCredentials(getModulePermissions(user.auths, credentialModuleName));
		}
		dispatch<any>(handleGetLocations());
		dispatch<any>(handleGetAppraisals());
		const interval = setInterval(() => {
			dispatch<any>(handleGetAppraisals());
		}, MINUTE_MS);
		return () => clearInterval(interval);
		//setLocationFilter(user.primaryLocation.id)
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleResetWorkbook = () => {   
		setGLFilter(null);
		setLocationFilter(primaryLocation);
		setlogDateToFilter(null);
		let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
		setSortOrder(1);
		setSortField('appraisalLog');
		handleFilters(primaryLocation, new Date(todaydate), null,null);
		//if (primaryLocation) {
		//	const results = appraisals.filter(x => x.deal.location.id === primaryLocation);
		//	setAppraisalData(results);
		//} else {
		//	setAppraisalData(appraisals);
		//}
	};

	const exportExcel = () => {
		if (appraisalData && appraisalData.length) {
			let data = [...appraisalData]

			//const exportData = data.map(({ id, appointmentDate, appointmentResult, appointmentTime, createdAt, employee, deal, ...keepAttrs }) => keepAttrs);
			const exportData = data.map((item) =>
				({
					"Appraisal Date": moment(new Date(item.appraisalDate)).local().format("MM/DD/YYYY"),
					"Appraiser": item.appraiser,
					"Vin":item.vin,
					"Vehicle": item.vehicleInfo,
					"Rollback": item.rollBack,
					"Subject to See": item.subject,
					"Sales Mgr": item.salesManager,
					"Sales Person 1": item.salesPerson1Name,
					"Deal #": item.dealNumber,
					"Customer": item.customerName,
					"Higher Bidder": item.higherBidder,
					"Status": item.appraisalStatus
				}));

			import('xlsx').then(xlsx => {
				const worksheet = xlsx.utils.json_to_sheet(exportData);
				const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
				const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
				saveAsExcelFile(excelBuffer, 'trade-ins-workbook');
			});
		}
	}

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
			}
		});
	}

	//Datatable Templates
	const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			             <Col>
			                  <div className=" employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="legalName"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
			         	</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"32px",maxWidth:"40%"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
			        </Row>
					<Row>
						<Col className="display-row" style={{ marginTop: "15px" }}>
							{
								totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
							}
							<Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
								Export to Excel
							</Link>
						</Col>
					</Row>
				</Col>
		    </Row>
		</div>:
		<div>
			<div>
				<Row>
					<Col md={10}>
						<Row>
							<Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
			         		<Col  md={3}>
						  <div className="dd-control">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
							<Col md={4}>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
							<Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"24px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>
						</Row>
					</Col>
					<Col>
					</Col>
				</Row>
			</div>
			<div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
               </div>
            </div>
		</div>}

		</>
	);


	//const conditionBodyTemplate = (rowData) => {
	//	return (
	//		<React.Fragment>
	//			<span className="p-column-title">Appraisal Conditions</span>
	//			{rowData.appraisalConditions}
	//		</React.Fragment>
	//	);
	//};

	const bidderBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Higher Bidder</span>
				 {rowData.appraiser ? rowData.higherBidder : <span style={{color:"#AF2B01"}}> { rowData.higherBidder} </span>}
			</React.Fragment>
		);
	};

	const subjectBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Subject to See</span>
				 {rowData.appraiser ? rowData.subject : <span style={{color:"#AF2B01"}}> { rowData.subject} </span>}
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Status</span>
				 {rowData.appraiser ? rowData.appraisalStatus : <span style={{color:"#AF2B01"}}> { rowData.appraisalStatus} </span>}
			</React.Fragment>
		);
	};

	const appraisalDateBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Appraisal Date</span>
				 {rowData.appraiser ? 
				 moment(new Date(rowData.appraisalDate)).local().format("MM/DD/YYYY") : 
				 <span style={{color:"#AF2B01"}}> { moment(new Date(rowData.appraisalDate)).local().format("MM/DD/YYYY")} </span>}
			</React.Fragment>
		);
	};

	const appraiserBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Appraiser</span>
				{rowData.appraiser}
			</React.Fragment>
		);
	};

	const salesManagerBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Sales Mgr</span>
				 {rowData.appraiser ? rowData.salesManager : <span style={{color:"#AF2B01"}}> { rowData.salesManager} </span>}
			</React.Fragment>
		);
	};

	const salesPersonBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Sales Person 1</span>
				 {rowData.appraiser ? rowData.salesPerson1Name : <span style={{color:"#AF2B01"}}> { rowData.salesPerson1Name} </span>}
			</React.Fragment>
		);
	};

	const dealNumberBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Deal #</span>
				 {rowData.appraiser ? rowData.dealNumber : <span style={{color:"#AF2B01"}}> { rowData.dealNumber} </span>}
			</React.Fragment>
		);
	};

	const customerBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Customer</span>
				 {rowData.appraiser ? rowData.customerName : <span style={{color:"#AF2B01"}}> { rowData.customerName} </span>}
			</React.Fragment>
		);
	};

	const settingBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title" style={{ float: "left" }}>Actions</span>
				{credentials && user.roles && (
					<EditMoreActionsSubmenu
						modulePermissions={credentials}
						itemId={rowData.deal.id}
						onEdit={(appointmentId) => handleEditAppointment(appointmentId)}
					/>
				)}
			</React.Fragment>
		);
	};

	const vinBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Vin</span>
				{rowData.vin}
			</React.Fragment>
		);
	};

	const vehicleInfoBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Vehicle</span>
				{rowData.vehicleInfo}
			</React.Fragment>
		);
	};

	const rollBackBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Rollback</span>
				{rowData.rollBack}
			</React.Fragment>
		);
	};

	const handleEditAppointment = (id) => {
		const selectedTradeIns = appraisalData.find(x => x.id === id);
		if (selectedTradeIns) {
			handleNavigateToTradeIns(selectedTradeIns.deal.id, id);
		}
	};

	const handleRowClick = (event) => {
		let target = event.originalEvent.target;
		if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
			target?.lastElementChild?.lastElementChild?.firstElementChild.click();
		} else {
			//const role = isUserMaster(user.roles);
			//const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
			//if (cred || role || isKarlProfile(user.profileType)) {
				handleNavigateToTradeIns(event.data.deal.id,event.data.id);
				// navigate(`/${event.data.deal.id}/dealtracker`, { tabIndex: 2 });
				//navigate({
				//	pathname: `/${event.data.deal.id}/dealtracker`,
				//	state: {
				//	  searchInput: glFilter,
				//	  pathfrom: '/appraisals',
				//	  tabIndex: 2,
				//	  productId:`${event.data.id}`,
    //                  workbookName:'tradeIN',
				//	  // sortOrder,
				//	  // sortField
				//	}
				//});
			//}
		}
	};

	const handleNavigateToTradeIns = (dealId, id) => {
		const state = {
			searchInput: glFilter,
			pathfrom: '/appraisals',
			tabIndex: 2,
			productId: `${id}`,
			workbookName: 'tradeIN',
			sortOrder,
			sortField,
			logDateFromFilter,
			logDateToFilter,
			locationFilter
		}
		navigate(`/${dealId}/dealtracker`, {state} );
	}

    useEffect(() => {        
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
		if (stateVal?.fromKeymetrics) {
			setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
		}

		if (stateVal?.locationFilter) {
			setLocationFilter(stateVal?.locationFilter);
		}

		if (stateVal?.logDateFromFilter) {
			setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
		}

		if (stateVal?.logDateToFilter) {
			setlogDateToFilter(new Date(stateVal.logDateToFilter));
		}

		if (stateVal?.sortField) {
			setSortField(stateVal.sortField);
			setSortOrder(stateVal.sortOrder);
		}

		setStatesFlag(true);
    }, [location]);

	useEffect(() => {
		if (serverError) {
			if (serverError.detail) {
				setcommonServerError(serverError.detail);
			} else {
				setcommonServerError('Something went wrong');
			}
			window.scrollTo(0, 0);
		} else {
			setcommonServerError(null);
		}
	}, [serverError]);

	const table_columns = {
		"appraisalDate": "Appraisal Date",
		"appraiser": "Appraiser",
		"vin":"Vin",
		"vehicleInfo": "Vehicle",
		"rollBack": "Rollback",
		"subject": "Subject to See",
		"salesManager":"Sales Mgr",
		"salesPerson1Name": "Sales Person 1",
		"dealNumber": "Deal #",
		"customerName":"Customer",
		"higherBidder": "Higher Bidder",
		"appraisalStatus": "Status",
		"settings": "Actions",
		"hiddenCol": "Hiddedn Col"
	}

	const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		isKarlProfileType ? <>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							<CustomFontText className="pageTitleFont pageName pageHeaderFont">Trade-ins</CustomFontText>
						</Col>
					</Row>
				</Stack>
				{
					commonServerError ?
						<div>
							<p className="genericErrorColor"><span>{commonServerError}</span></p>
						</div> : null
				}
				<div className="datatable-filter-demo noMaxWidthTable trade-in-workbook">
					<div className="card last-col-hide">
						<CustomTable
							column_list={table_columns}
							data={appraisalData}
							header={header}
							rows={25}
                            rowsPerPageOptions={[25,50,100]}
							sortField={sortField}
							sortOrder={sortOrder}
							onSort={handleColumnSort}
							customTemplates={{
								"appraisalDate": appraisalDateBodyTemplate,
								"appraiser": appraiserBodyTemplate,
								"vin": vinBodyTemplate,
								"vehicleInfo": vehicleInfoBodyTemplate,
								"rollBack": rollBackBodyTemplate,
								"appraisalStatus": statusBodyTemplate,
								"higherBidder": bidderBodyTemplate,
								"subject": subjectBodyTemplate,
								"salesManager": salesManagerBodyTemplate,
								"salesPerson1Name": salesPersonBodyTemplate,
								"dealNumber": dealNumberBodyTemplate,
								"customerName": customerBodyTemplate,
								"settings": settingBodyTemplate,
							}}
							emptyMessage="No appraisals found."
							onRowClick={(event) => handleRowClick(event)}
							//globalFilter={glFilter} 
							/>
					</div>
				</div>
			</Stack>
		</> : null
	);
};
