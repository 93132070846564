import { IGroup } from "types/groupTypes";

/** GET_GROUPS */
export const HANDLE_GET_GROUPS = "HANDLE_GET_GROUPS ";

export interface HandleGetGroups {
  type: typeof HANDLE_GET_GROUPS;
}

export const GET_GROUPS_PENDING = "GET_GROUPS_PENDING";

export interface GetGroupsPending {
  type: typeof GET_GROUPS_PENDING;
}

export const GET_GROUPS_RESOLVED = "GET_GROUPS_RESOLVED";

export interface GetGroupResolved {
  type: typeof GET_GROUPS_RESOLVED;
  payload: IGroup[];
}

export const GET_GROUPS_REJECTED = "GET_GROUPS_REJECTED";

export interface GetGroupRejected {
  type: typeof GET_GROUPS_REJECTED;
  payload: object[];
}

// VIEW_GROUP
export const HANDLE_VIEW_GROUP = "HANDLE_VIEW_GROUP ";

export interface HandleViewGroup {
  type: typeof HANDLE_VIEW_GROUP;
  payload: string | number;
}

export const VIEW_GROUP_PENDING = "VIEW_GROUP_PENDING";

export interface ViewGroupPending {
  type: typeof VIEW_GROUP_PENDING;
}

export const VIEW_GROUP_RESOLVED = "VIEW_GROUP_RESOLVED";

export interface ViewGroupResolved {
  type: typeof VIEW_GROUP_RESOLVED;
  payload: IGroup;
}

export const VIEW_GROUP_REJECTED = "VIEW_GROUP_REJECTED";

export interface ViewGroupRejected {
  type: typeof VIEW_GROUP_REJECTED;
  payload: any;
}

// CREATE_GROUP
export const HANDLE_CREATE_GROUP = "HANDLE_CREATE_GROUP ";

export interface HandleCreateGroup {
  type: typeof HANDLE_CREATE_GROUP;
  payload: { name: string; callback: Function };
}

export const CREATE_GROUP_PENDING = "CREATE_GROUP_PENDING";

export interface createGroupPending {
  type: typeof CREATE_GROUP_PENDING;
}

export const CREATE_GROUP_RESOLVED = "CREATE_GROUP_RESOLVED";

export interface createGroupResolved {
  type: typeof CREATE_GROUP_RESOLVED;
  payload: IGroup;
}

export const CREATE_GROUP_REJECTED = "CREATE_GROUP_REJECTED";

export interface createGroupRejected {
  type: typeof CREATE_GROUP_REJECTED;
  payload: object[];
}

// UPDATE_GROUP
export const HANDLE_UPDATE_GROUP = "HANDLE_UPDATE_GROUP";

export interface HandleUpdateGroup {
  type: typeof HANDLE_UPDATE_GROUP;
  payload: { id: number; name: string, extra: any };
}

export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING";

export interface UpdateGroupPending {
  type: typeof UPDATE_GROUP_PENDING;
}

export const UPDATE_GROUP_RESOLVED = "UPDATE_GROUP_RESOLVED";

export interface UpdateGroupResolved {
  type: typeof UPDATE_GROUP_RESOLVED;
  payload: IGroup;
}

export const UPDATE_GROUP_REJECTED = "UPDATE_GROUP_REJECTED";

export interface UpdateGroupRejected {
  type: typeof UPDATE_GROUP_REJECTED;
  payload: any;
}

// DELETE_GROUP
export const HANDLE_DELETE_GROUP = "HANDLE_DELETE_GROUP";

export interface HandleDeleteGroup {
  type: typeof HANDLE_DELETE_GROUP;
  payload: { id: string, callback: () => void };
}

export const DELETE_GROUP_PENDING = "DELETE_GROUP_PENDING";
export interface DeleteGroupPending {
  type: typeof DELETE_GROUP_PENDING;
}

export const DELETE_GROUP_RESOLVED = "DELETE_GROUP_RESOLVED";
export interface DeleteGroupResolved {
  type: typeof DELETE_GROUP_RESOLVED;
}

export const DELETE_GROUP_REJECTED = "DELETE_GROUP_REJECTED";
export interface DeleteGroupRejected {
  type: typeof DELETE_GROUP_REJECTED;
  payload: any;
}

//ADD_EMPLOYEE_TO_GROUP
export const HANDLE_ADD_EMPLOYEE_TO_GROUP = "HANDLE_ADD_EMPLOYEE_TO_GROUP";

export interface HandleAddEmployeeToGroup {
  type: typeof HANDLE_ADD_EMPLOYEE_TO_GROUP;
  payload: { employeeIds: number[]; groupId: number; callback?: Function };
}

export const ADD_EMPLOYEE_TO_GROUP_PENDING = "ADD_EMPLOYEE_TO_GROUP_PENDING";

export interface AddEmployeeToGroupPending {
  type: typeof ADD_EMPLOYEE_TO_GROUP_PENDING;
}

export const ADD_EMPLOYEE_TO_GROUP_RESOLVED = "ADD_EMPLOYEE_TO_GROUP_RESOLVED";

export interface AddEmployeeToGroupResolved {
  type: typeof ADD_EMPLOYEE_TO_GROUP_RESOLVED;
}

export const ADD_EMPLOYEE_TO_GROUP_REJECTED = "ADD_EMPLOYEE_TO_GROUP_REJECTED";

export interface AddEmployeeToGroupRejected {
  type: typeof ADD_EMPLOYEE_TO_GROUP_REJECTED;
  payload: object[];
}

//REMOVE_EMPLOYEE_FROM_GROUP
export const HANDLE_REMOVE_EMPLOYEE_FROM_GROUP =
  "HANDLE_REMOVE_EMPLOYEE_FROM_GROUP";

export interface HandleRemoveEmployeeFromGroup {
  type: typeof HANDLE_REMOVE_EMPLOYEE_FROM_GROUP;
  payload: { employeeIds: number[]; groupId: number; callback?: Function };
}

export const REMOVE_EMPLOYEE_FROM_GROUP_PENDING =
  "REMOVE_EMPLOYEE_FROM_GROUP_PENDING";

export interface RemoveEmployeeFromGroupPending {
  type: typeof REMOVE_EMPLOYEE_FROM_GROUP_PENDING;
}

export const REMOVE_EMPLOYEE_FROM_GROUP_RESOLVED =
  "REMOVE_EMPLOYEE_FROM_GROUP_RESOLVED";

export interface RemoveEmployeeFromGroupResolved {
  type: typeof REMOVE_EMPLOYEE_FROM_GROUP_RESOLVED;
}

export const REMOVE_EMPLOYEE_FROM_GROUP_REJECTED =
  "REMOVE_EMPLOYEE_FROM_GROUP_REJECTED";

export interface RemoveEmployeeFromGroupRejected {
  type: typeof REMOVE_EMPLOYEE_FROM_GROUP_REJECTED;
  payload: object[];
}

export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export interface SetRowsPerPage {
  type: typeof SET_ROWS_PER_PAGE;
  payload: number;
}
export const SET_ROWS_PER_PAGE_ADD_GROUP_EMPLOYEES =
  "SET_ROWS_PER_PAGE_ADD_GROUP_EMPLOYEES";
export interface SetRowsPerPageAddGroupEmployees {
  type: typeof SET_ROWS_PER_PAGE_ADD_GROUP_EMPLOYEES;
  payload: number;
}
export const SET_ROWS_PER_PAGE_EDIT_GROUP_EMPLOYEES =
  "SET_ROWS_PER_PAGE_EDIT_GROUP_EMPLOYEES";
export interface SetRowsPerPageEditGroupEmployees {
  type: typeof SET_ROWS_PER_PAGE_EDIT_GROUP_EMPLOYEES;
  payload: number;
}

export const CREATE_GROUP_AND_ASSIGN_EMPLOYEES =
  "CREATE_GROUP_AND_ASSIGN_EMPLOYEES";
export interface CreateGroupAndAssignEmployees {
  type: typeof CREATE_GROUP_AND_ASSIGN_EMPLOYEES;
  payload: any;
}

export type GroupActions =
  | HandleGetGroups
  | GetGroupsPending
  | GetGroupResolved
  | GetGroupRejected
  | HandleAddEmployeeToGroup
  | AddEmployeeToGroupPending
  | AddEmployeeToGroupRejected
  | AddEmployeeToGroupResolved
  | HandleCreateGroup
  | createGroupPending
  | createGroupResolved
  | createGroupRejected
  | HandleViewGroup
  | ViewGroupPending
  | ViewGroupRejected
  | ViewGroupResolved
  | HandleRemoveEmployeeFromGroup
  | RemoveEmployeeFromGroupPending
  | RemoveEmployeeFromGroupRejected
  | RemoveEmployeeFromGroupResolved
  | SetRowsPerPage
  | SetRowsPerPageAddGroupEmployees
  | SetRowsPerPageEditGroupEmployees
  | HandleUpdateGroup
  | UpdateGroupPending
  | UpdateGroupRejected
  | UpdateGroupResolved
  | CreateGroupAndAssignEmployees
  | HandleDeleteGroup
  | DeleteGroupPending
  | DeleteGroupRejected
  | DeleteGroupResolved;
