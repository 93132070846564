import React from "react";
import { Col, Container, Row } from "react-grid-system";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import { TextFieldWithLabelAndInfoIcon } from "components/textFieldWithLabelAndInfoIcon/TextFieldWithLabelAndInfoIcon";
import { styles } from "pages/dealSettingsPage/styles";

interface IProps {
  formik: any;
}

const BasicSetting: React.FC<IProps> = ({ formik }) => {
  return (
    <Container style={styles.container} fluid>
      <Row gutterWidth={75}>
        <Col sm={12} md={12} xl={6}>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              id="dealsActivity"
              name="dealsActivity"
              label="Deals Activity in Days"
              value={formik.values.dealsActivity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.dealsActivity &&
                formik.errors.dealsActivity &&
                formik.errors.dealsActivity.toString()
              }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              id="dealsInactivity"
              name="dealsInactivity"
              label="Deals Inactivity in Days"
              value={formik.values.dealsInactivity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.dealsInactivity &&
                formik.errors.dealsInactivity &&
                formik.errors.dealsInactivity.toString()
              }
            />
          </WithOptionalLabel>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicSetting;
