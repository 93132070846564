import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
} from "@fluentui/react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { useDispatch } from "react-redux";
//import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetDealTrackerSummary, handleGetDealerTradesV2 } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    useNavigate ,
    useLocation
} from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
//import { convertUTCToLocalDateOnly } from "utils/dateTimeFunctions";
import { Paginator } from 'primereact/paginator';
import moment from "moment";
import { DebounceInput } from 'react-debounce-input';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import masterDropdownValues from "constants/masterDropdownValues";
import { handleGetVehicleTrackerList } from "store/dealTracker/dealTracker.action";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

//const credentialModuleName = "DealTracker";
export const UniversalSearchWorkbookV2Page = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate ();
    const isMobile = useMediaQuery({ maxWidth: 960 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const locations = useSelector((state: AppState) => state.locations.locations) as any;

    //const [credentials, setCredentials] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [commonServerError, setCommonServerError] = useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [search, setGLFilter] = useState('');
    const [locationId, setLocationFilter] = useState(0);
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('logDate');
    const [totalRecords, setTotalRecords] = useState(0);
    const [dealerTradeTotalRecords, setDealerTradeTotalRecords] = useState(0);
    const [vtTotalRecords, setVTTotalRecords] = useState(0);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(1);
    const [statePageNumber, setStatePageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [sectionIndex, setSectionIndex] = useState(1);
    const [flag, setFlag] = useState(true);
    const [finalData, setFinalData] = useState(null);

    const handleSearchChange = (val: any) => {
        const inputValue = val.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }
        if(val.target.value === ''){
			setGLFilter('');
	    }
    };

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
    };

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    const handlePopulateData = (deals) => {
        if (deals.length) {
            for (var i in deals) {
                //let statusDate = '';
                //if (deals[i].dealDelivery && deals[i].dealDelivery.statusDateTime) {
                //    let rawStatusDate = deals[i].dealDelivery.statusDateTime;
                //    statusDate = rawStatusDate.indexOf("T") ? rawStatusDate.substr(0, rawStatusDate.indexOf("T")) : rawStatusDate;
                //}

                //deals[i]["logDate"] = convertUTCToLocalDateOnly(deals[i].createdAt);
                deals[i]["locationName"] = deals[i].location?.legalName;
                deals[i]["customerName"] = deals[i].customer ?
                    deals[i].customer.customerType === 1 ?
                        deals[i].customer.firstName + ' ' + deals[i].customer.lastName :
                        deals[i].customer.businessName
                    : null;
                if (deals[i].vehicle) {

                    deals[i]["yearModel"] = deals[i].vehicle.year + ' ' + deals[i].vehicle.model;
                    if (deals[i].stockNumber || deals[i].orderNumber) {
                        deals[i]["stockOrder"] = (deals[i].stockNumber ? deals[i].stockNumber : '--')
                            + ' / ' +
                            (deals[i].orderNumber ? deals[i].orderNumber : '--');
                    }

                }

                if (deals[i].dealDelivery && deals[i].dealDelivery.statusDateTime) {
                    deals[i]["statusDateTime"] = moment(deals[i].dealDelivery.statusDateTime).local().format('MM/DD/YY hh:mm a');
                    deals[i]["status"] = getDeliveryStatus(deals[i].dealDelivery.status);

                }
                deals[i]["sales1"] = getPersonName(deals[i].salesPerson1);
                deals[i]["sales2"] = getPersonName(deals[i].salesPerson2);
                deals[i]["finance1"] = getPersonName(deals[i].financePerson1);
                deals[i]["finance2"] = getPersonName(deals[i].financePerson2);
                deals[i]["haveAppointments"] = deals[i].dealAppointments && deals[i].dealAppointments.length > 0;
                deals[i]["haveTradeIns"] = deals[i].dealTradeIns && deals[i].dealTradeIns.length > 0;
                deals[i]["haveCancels"] = deals[i].dealCancellations && deals[i].dealCancellations.length > 0;
                deals[i]["haveTasks"] = deals[i].dealTasks && deals[i].dealTasks.length > 0;
                deals[i]["haveWriteups"] = deals[i].dealDelivery && deals[i].dealDelivery.id > 0;
                deals[i]["haveContractService"] = deals[i].dealServiceContracts && deals[i].dealServiceContracts.length > 0;
            }
            return deals;
        }
        return [];
    };

    const getDeliveryStatus = (statusId) => {
        if (statusId) {
            return masterDropdownValues.dealTrackerStatus.filter(x => x.key === statusId)[0].text;
        }
        return '';
    }

    const getPersonName = (salesPerson) => {
        if (salesPerson) {
            return salesPerson?.firstName + ' ' + salesPerson?.lastName;
        }
        return '';
    };

    useEffect(() => {
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
            setUserProfileLoadingFlag(true);
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTableData([]);
        dispatch<any>(handleGetLocations());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleResetWorkbook = () => {
        if (search) {
            setFlag(true);
        }
        setGLFilter('');
        setLocationFilter(primaryLocation);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setSortOrder(-1);
        setSortField('logDate');
        setLimit(25);
    };

    const handleShowDealerTradeGrid = () => {
       
        const formData = {
            search,
            limit: 10000
        };
        dispatch<any>(handleGetDealerTradesV2({
            formData,
            callback: (response) => {
                if (response) {
                    handleDealertradeMapping(response.results);
                } else {                    
                    setCommonServerError('Failed to Fetch the Data.');
                }
            }
        }));
       
    }
    
    const handleShowVehicleTrackerGrid = () => {
         setSectionIndex(3)
    }


    //Datatable Templates
    const header = (
        <>
            {
                isMobile ?
                    <div>
                        <Row>
                            <Col style={{ marginTop: "2px" }}>
                                <div className="employeeeSearchLabelText">
                                    {/*<TextField
                                        value={search}
                                        type="search"
                                        label="Search Deals"
                                        className="ownerSearchText"
                                        onChange={(e) => handleSearchChange(e)}
                                        onKeyUp={(event) => {
                                            if ((event.key === 'Enter') || (event.key === 'Backspace' && search === '' && tempSearch !== ''))
                                                getSummary();
                                        }}
                                    />*/}
                                    <label className="lbl-text">Search Deals</label>
                                    <div className="ms-TextField-fieldGroup debounce-input-group">
                                        <DebounceInput
                                            minLength={1}
                                            className="ms-TextField-field"
                                            value={search}
                                            debounceTimeout={750}
                                            onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="locationDropPosition">
                                    <label className="lbl-text">Location</label>
                                    <Dropdown
                                        id="locationId"
                                        placeholder=""
                                        value={locationId}
                                        options={cityDealerList}
                                        onChange={onLocationChange}
                                        optionLabel="legalName"
                                        optionValue="id"
                                        appendTo="self"
                                        className="custom-p-dropdown"
                                        filter={false}
                                        resetFilterOnHide={true}
                                    />
                                </div>
                            </Col>

                        </Row>
                        <Row style={{ width: "auto" }} >
                            <Col>
                                <div className="date-filter-section">
                                    <div className="table-header">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text">Log Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                <Calendar
                                                    id="fromLogDate"
                                                    style={{ width: "100%" }}
                                                    value={logDateFromFilter}
                                                    showIcon
                                                    onChange={(e) => handleFromLogDateChange(e)}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                    maxDate={logDateToFilter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                    <div className="table-header">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text">&nbsp;</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                <Calendar
                                                    id="toLogDate"
                                                    style={{ width: "100%" }}
                                                    value={logDateToFilter}
                                                    showIcon
                                                    onChange={(e) => handleToLogDateChange(e)}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                    minDate={logDateFromFilter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                <PrimaryButton style={{ float: "right" }}
                                    onClick={handleResetWorkbook}
                                >
                                    Reset
                               </PrimaryButton>
                            </Col>
                        </Row>
                       
                        <Row style={{ marginTop: "15px" }}>
                            <Col >
                               
                            </Col>
                            
                            <Col>
                                {
                                    vtTotalRecords !== null && <label className="lbl-text">{vtTotalRecords}  Transfers Found</label>
                                }
                            </Col>
                            <Col>
                                <PrimaryButton disabled={!vtTotalRecords} onClick={handleShowVehicleTrackerGrid}>
                                    View Transfers
                               </PrimaryButton>
                            </Col>
                        </Row>

                        
                        
                        <Row style={{ marginTop: "15px" }}>
                            <Col >
                                {
                                    totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                                }
                            </Col>
                            <Col>
                                {
                                    dealerTradeTotalRecords !== null && <label className="lbl-text">{dealerTradeTotalRecords} Dealer Trades Found</label>
                                }
                            </Col>
                            <Col>
                                <PrimaryButton disabled={!dealerTradeTotalRecords} onClick={handleShowDealerTradeGrid}>
                                    View Trades
                               </PrimaryButton>
                            </Col>
                        </Row>
                        
                    </div> :
                    <div>
                        <Row>
                            <Col md={10}>
                                <Row>
                                    <Col md={3}>
                                        <div className="table-header employeeeSearchLabelText">
                                            {/*<TextField
                                            value={search}
                                            type="search"
                                            label="Search Deals"
                                            className="ownerSearchText"
                                            onChange={(e) => handleSearchChange(e)}
                                            onKeyUp={(event) => {
                                                if ((event.key === 'Enter') || (event.key === 'Backspace' && search === '' && tempSearch !== ''))
                                                    getSummary();
                                            }}
                                            />*/}
                                            <label className="lbl-text">Search Deals</label>
                                            <div className="ms-TextField-fieldGroup debounce-input-group">
                                                <DebounceInput
                                                    minLength={1}
                                                    className="ms-TextField-field"
                                                    value={search}
                                                    debounceTimeout={750}
                                                    onChange={handleSearchChange} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Location</label>
                                            <Dropdown
                                                id="locationId"
                                                placeholder=""
                                                value={locationId}
                                                options={cityDealerList}
                                                onChange={onLocationChange}
                                                optionLabel="legalName"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="date-filter-section">
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">Log Date</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                        <Calendar
                                                            id="fromLogDate"
                                                            style={{ width: "100%" }}
                                                            value={logDateFromFilter}
                                                            showIcon
                                                            onChange={(e) => handleFromLogDateChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            maxDate={logDateToFilter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">&nbsp;</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                        <Calendar
                                                            id="toLogDate"
                                                            style={{ width: "100%" }}
                                                            value={logDateToFilter}
                                                            showIcon
                                                            onChange={(e) => handleToLogDateChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            minDate={logDateFromFilter}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={1} >
                                        <div className="table-header employeeeSearchLabelText" style={{ marginTop: "24px" }}>
                                            <PrimaryButton
                                                onClick={handleResetWorkbook}
                                            >
                                                Reset
                               </PrimaryButton>
                                        </div>

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                 
                        <Row className="rowPaddingBtwSpace" style={{marginTop:"-40px"}}>
                            <Col className="display-row">
                                <div>
                                    
                                </div>
                                <div className="display-row">
                                    {
                                        vtTotalRecords !== null && <label className="lbl-text">{vtTotalRecords} Transfers Found</label>
                                    }
                                    <PrimaryButton disabled={!vtTotalRecords} style={{ marginLeft: "15px", marginRight: "-15px",width:"47%" }} onClick={handleShowVehicleTrackerGrid}>
                                          View Transfers
                               </PrimaryButton>
                                </div>
                            </Col>
                        </Row>
                        <Row className="rowPaddingBtwSpace">
                            <Col className="display-row">
                                <div>
                                    {
                                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                                    }
                                </div>
                                <div className="display-row">
                                    {
                                        dealerTradeTotalRecords !== null && <label className="lbl-text">{dealerTradeTotalRecords} Dealer Trades Found</label>
                                    }
                                    <PrimaryButton disabled={!dealerTradeTotalRecords} style={{ marginLeft: "15px", marginRight: "-15px" }} onClick={handleShowDealerTradeGrid}>
                                        View Trades
                               </PrimaryButton>
                                </div>
                            </Col>
                        </Row>
                    </div>
            }

        </>
    );

    const dealerTradeHeader = (
        <>
            {
                <div>
                    <Row>
                        <Col >
                            {
                                dealerTradeTotalRecords !== null && <label className="lbl-text">Record Count: {dealerTradeTotalRecords}</label>
                            }
                        </Col>
                    </Row>

                </div>
            }

        </>
    ); 
    
    const vtheader = (
        <>
            {
                <div>
                    <Row>
                        <Col >
                            {
                                vtTotalRecords !== null && <label className="lbl-text">Record Count: {vtTotalRecords}</label>
                            }
                        </Col>
                    </Row>

                </div>
            }

        </>
    );

    //const logDateBodyTemplate = (rowData) => {
    //    return (
    //        <React.Fragment>
    //            <span className="p-column-title">DR Date</span>
    //            {moment(new Date(rowData.logDate)).local().format('MM/DD/YY')}
    //        </React.Fragment>
    //    );
    //};

    const statusDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status Date</span>
                {rowData.statusDateTime}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales</span>
                {rowData.sales1}
                <br />
                {rowData.sales2}
            </React.Fragment>
        );
    };

    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance</span>
                {rowData.finance1}
                <br />
                {rowData.finance2}
            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Location</span>
                {rowData.locationName}
            </React.Fragment>
        );
    };

    const custNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Name</span>
                {rowData.customerName}
            </React.Fragment>
        );
    };

    const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle<br />Stock # / Order #</span>
                {rowData.yearModel}
                <br />
                {rowData.stockOrder}
            </React.Fragment>
        );
    }

    const tasksBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Tasks</span>
                {
                    rowData.haveTasks && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const cancellationsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cancels</span>
                {
                    rowData.haveCancels && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const tradeInsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade-ins</span>
                {
                    rowData.haveTradeIns && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const appointmentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appt</span>
                {
                    rowData.haveAppointments && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const writesUpsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Write-ups</span>
                {
                    rowData.haveWriteups && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const afterSaleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">After Sale</span>
                {
                    rowData.haveContractService && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            const state = {
                search,
                pathfrom: '/search-inventory',
                tabIndex: 0,
                sortOrder,
                sortField,
                pages,
                limit,
                locationId,
                logDateFromFilter,
                logDateToFilter                   
        }
            navigate(`/${event.data.id}/dealtracker`,{state});
            //}
        }
    };

    const getSummary = (isFilterApplied = false, isSearchApplied = false) => {
        setCommonServerError('');
        if (loadingFlag && userProfileLoadingFlag) {
            const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
                sort: sortOrder === 1 ? 'ASC' : 'DESC',
                column: sortField,
                locationId,
                fromLogDate: logDateFromFilter ? moment(logDateFromFilter).format('YYYY-MM-DD') : '',
                toLogDate: logDateToFilter ? moment(logDateToFilter).format('YYYY-MM-DD') : '',
                search,
                isSearchApplied
            };
            if (isFilterApplied && statePageNumber > 1) {
                setStatePageNumber(1);
            }

            setFlag(false);

            dispatch<any>(handleGetDealTrackerSummary({
                formData,
                callback: (response) => {
                    if (response) {
                        if (isSearchApplied) {
                            setDealerTradeTotalRecords(response.dealerTradesResponse.totalItems);
                        }
                        setTotalRecords(response.totalItems);
                        const result = handlePopulateData(response.results);
                        setTableData(result);
                    } else {
                        setTotalRecords(0);
                        setDealerTradeTotalRecords(0);
                        setCommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
 
        }
    }
    
    const getVTSummary = (isFilterApplied = false, isSearchApplied = false) => {
        setCommonServerError('');
        if (loadingFlag && userProfileLoadingFlag) {
           const formData = {
                 limit:10000,
                 status:[1,2,3,4,5,7],
                 searchStockOnly : search
            };
            if (isFilterApplied && statePageNumber > 1) {
                setStatePageNumber(1);
            }

            setFlag(false);

    
            dispatch<any>(handleGetVehicleTrackerList({
                formData,
                callback: (response) => {
               
                    if (response) {
                        setVTTotalRecords(response.totalItems);
                        const data = handlePopulateVT(response.results);
                        setFinalData(data);
                    } else {
                        setVTTotalRecords(0);
                        setCommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
        }
    }

    const getStatus = (x) => {
   
        if (x.status !== 4) {
            return  masterDropdownValues.vehicleTrackerStatus.find(item => item.key === x.status).text;

        }else {
            if(x?.deal?.dealDelivery?.status){
                let val = masterDropdownValues.dealTrackerStatus.filter(item => item.key === x.deal?.dealDelivery?.status)[0].text;


               return  `Sold - ${val}`
            }else if(x.status === 4){
                return "Sold -"
            }
             
        }
    }

    const vehicleLocation =(locVal)=>{
        if(locVal?.transferToLocation?.id){
             return locVal?.transferToLocation?.legalName
        }else{
            return "Loaner "
        }
    }

    const handlePopulateVT = (response) => {



          if(response.length){
              for (var i in response) {
                      
              

                   response[i]["sentDate_Time"] = moment(response[i].sentDateTime).local().format('MM/DD/YY hh:mm a');
                  
                   response[i]["locationName"] = '';
                   response[i]["statusName"] = response[i].status &&  getStatus(response[i]);
                   response[i]["vehicle"] = (response[i].vehicleTracker.vehicle.year +' '+ response[i].vehicleTracker.vehicle.model) ; 
                   response[i]["stockNumber"] = response[i].vehicleTracker.vehicle.stockNumber;
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["requestedByName"] = response[i].requestedBy ? response[i].requestedBy.firstName + " " + response[i].requestedBy.lastName : '';
                   response[i]["transferedtolocation"] = vehicleLocation(response[i]);
                   response[i]["homelocation"] = response[i].vehicleTracker.location && response[i].vehicleTracker.location.legalName
                   response[i]["vehicleId"] = response[i].vehicleTracker && response[i].vehicleTracker.id ;
                   response[i]["vehicleTransferID"] = response[i].id;
              }
              	 return response;                
          }
          return []
	      
	};

    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal && stateVal.search) {
            setGLFilter(stateVal.search);
        }
        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) *stateVal.limit);
            }
        }
        if (stateVal?.locationId) {
            setLocationFilter(stateVal?.locationId);
        }
        if(primaryLocation === 0 && stateVal?.locationId === 0){
            setLocationFilter(0);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(stateVal.logDateFromFilter);
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(stateVal?.logDateToFilter);
        }
        setLoadingFlag(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (loadingFlag && userProfileLoadingFlag) {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
            getSummary(true, flag);
            getVTSummary(true, flag);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, logDateFromFilter, logDateToFilter, userProfileLoadingFlag, loadingFlag]);

    useEffect(() => {
        if (loadingFlag && userProfileLoadingFlag) {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
            getSummary(true,true);
            getVTSummary(true,true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getSummary();
        getVTSummary();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);

    //const table_columns = {
    //    "logDate": "Log Date",
    //    "dealNumber": "Deal Number",
    //    "locationName": "Location",
    //    "customerName": "Customer Name",
    //    "vehicleInfo": "Vehicle \nStock #/Order #",
    //    "closedDate": "Closed Date",
    //    "sales": "Sales",
    //    "finance": "Finance",
    //    "writeUps": "Write-ups",
    //    "appointments": "Appt",
    //    "tradeIns": "Trade-ins",
    //    "cancellations": "Cancels",
    //    "tasks": "Tasks",
    //    "afterSale": "After Sale",
    //}

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }
    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

    

    const vehStockOrderHeader = "Vehicle \nStock #/Order #";

    /****vehicle tracker Implementation****/

    const [sortOrder_VT, setSortOrder_VT] = useState(null);
    const [sortField_VT, setSortField_VT] = useState('sentDateTime');

  

    const handleColumnSort_VT = (e) => {
        setSortField_VT(e.sortField);
        setSortOrder_VT(e.sortOrder);
    }


/****Dealer Trade Implementation****/

    const [tableData_DT, setTableData_DT] = useState([]);
    const [sortOrder_DT, setSortOrder_DT] = useState(null);
    const [sortField_DT, setSortField_DT] = useState('tradeDate');

    //useEffect(() => {
    //    const formData = {
    //        sort: sortOrder_DT === 1 ? 'ASC' : 'DESC',
    //        column: sortField_DT,
    //        search
    //    };
        
    //    dispatch<any>(handleGetDealerTradesV2({
    //        formData,
    //        callback: (response) => {
    //            console.log('response', response);
    //            if (response) {
    //                handleDealertradeMapping(response.results);
    //            } else {
    //                setDealerTradeRes(null);
    //                setDealerTradeTotalRecords(0);
    //                setCommonServerError('Failed to Fetch the Data.');
    //            }
    //        }
    //    }));

    //}, [setSortOrder_DT, setSortField_DT]);

    const handleColumnSort_DT = (e) => {
        setSortField_DT(e.sortField);
        setSortOrder_DT(e.sortOrder);
    }

    const handleBackToUsers = () => {
        setTableData_DT([]);
        setSectionIndex(1);
    }

    const tradeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Date</span>
                {rowData.tradeDate}
            </React.Fragment>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Type</span>
                {rowData.type}
            </React.Fragment>
        );
    };

    const tradeManagerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Mgr</span>
                {rowData.tradeManager}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate_DT = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person</span>
                {rowData.salesPerson}
            </React.Fragment>
        );
    };

    const customerBodyTemplate_DT = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customer}
            </React.Fragment>
        );
    };

    const dealerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Dealer</span>
                {rowData.dealerName}
            </React.Fragment>
        );
    };

    const dealerCityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Dealer City</span>
                {rowData.dealerCity}
            </React.Fragment>
        );
    };

    const dealerCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">D-Code</span>
                {rowData.dealerCode}
            </React.Fragment>
        );
    };

    const vehicleInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle In</span>
                {
                    rowData.vehicleIn ? <>
                        <div className="display-row">
                            <div>
                                {
                                    rowData.vehicleIn.year + ' ' + rowData.vehicleIn.make + ' ' + rowData.vehicleIn.model
                                }
                                {
                                    rowData.vehicleIn.vin ? <> <br /> {rowData.vehicleIn.vin}</> : null
                                }
                                <br />
                                Stock/Order: {
                                    (rowData.vehicleIn.stockNumber ? rowData.vehicleIn.stockNumber : 'NA') + ' / ' +
                                    (rowData.vehicleIn.orderNumber ? rowData.vehicleIn.orderNumber : 'NA')
                                }
                            </div>
                        </div>
                    </> : null

                }
            </React.Fragment>
        );
    };

    const vehicleOutBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle Out</span>
                
                {
                    

                    rowData.vehicleOut ? <>
                        <div className="display-row">
                            <div>
                                {
                                    rowData.vehicleOut.year + ' ' + rowData.vehicleOut.make + ' ' + rowData.vehicleOut.model
                                }
                                {
                                    rowData.vehicleOut.vin ? <><br /> {rowData.vehicleOut.vin} </> : null
                                }
                                <br />
                                 Stock/Order : {
                                    (rowData.vehicleOut.stockNumber ? rowData.vehicleOut.stockNumber : 'NA') + ' / ' +
                                    (rowData.vehicleOut.orderNumber ? rowData.vehicleOut.orderNumber : 'NA')
                                }
                            </div>
                        </div>
                    </> : null

                }
            </React.Fragment>
        );
    };

    const commentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Comments</span>
                {rowData.comments}
            </React.Fragment>
        );
    };

    const handleDealertradeMapping = (res) => {
        
        if (res.length) {
            let data = [];
            for (var i in res) {

                let obj = {
                    tradeDate: moment(res[i].createdAt).local().format('MM/DD/YYYY'),
                    type: res[i].type === 1 ? "Ours" : "Theirs",
                    salesPerson: res[i].salesPerson? res[i].salesPerson.firstName + ' ' + res[i].salesPerson.lastName: '',
                    tradeManager: res[i].createdBy? res[i].createdBy.firstName + ' ' + res[i].createdBy.lastName: '',
                    customer: res[i].customerName,
                    dealerName: res[i].dealerName,
                    dealerCity: res[i].dealerCity,
                    dealerCode: res[i].dealerCode,
                    comments: res[i].comments,
                    vehicleIn: null,
                    vehicleOut: null
                }

                if (res[i].vehicleIn) {
                    obj.vehicleIn = res[i].vehicleIn;
                    //obj.vehicleIn["ymm"] = (res[i].vehicleIn.year + ' ' + res[i].vehicleIn.make + ' ' + res[i].vehicleIn.model);
                }
                if (res[i].vehicleOut) {
                    obj.vehicleOut = res[i].vehicleOut;
                    //obj.vehicleOut["ymm"] = (res[i].vehicleOut.year + ' ' + res[i].vehicleOut.make + ' ' + res[i].vehicleOut.model);
                }
                data.push(obj);               
            }
            console.log('data', data);
            setTableData_DT(data);
        } else {
            setTableData_DT([]);
        }
        setSectionIndex(2);
    }

  


    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sent Date</span>
                {rowData.sentDate_Time}
            </React.Fragment>
        );
    };
    
    const stockBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Stock</span>
                {rowData.stockNumber}
            </React.Fragment>
        );
    };
    
    const transferedtoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Transfered To</span>
                {rowData.transferedtolocation} 
                
            </React.Fragment>
        );
    };

    const locationVTBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Home Location</span>
                {rowData.homelocation} 

            </React.Fragment>
        );
    };

  

    const vehiclevtBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                  <span className="p-column-title">Vehicle</span>
                {rowData.vehicle }
            </React.Fragment>
        );
    };



    const requestedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Requested By</span>
                {rowData.requestedByName}
            </React.Fragment>
        );
    };



    const commentsvtBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Notes</span>
                {rowData.lastNote }
            </React.Fragment>
        );
    };

    const statusVTBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                 <span className="p-column-title">Status</span>
                {rowData.statusName }
            </React.Fragment>
        );
    };




    return (
        isKarlProfileType ? <>
            {
                sectionIndex === 1 && (<Stack styles={container} tokens={{ childrenGap: 10 }}>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Search All</CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    {
                        commonServerError ?
                            <div>
                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                            </div> : null
                    }
                    <div className="datatable-filter-demo noMaxWidthTable universal-search-workbook universal-search-workbook-v2"> {/* locations */}
                        <div className="card mouse-hover lazy-loading-workbook">
                            <DataTable
                                value={tableData}
                                className="p-datatable-customers"
                                paginator={false}
                                header={header}
                                footer={footer}
                                emptyMessage="No data founds"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={handleColumnSort}
                                onRowClick={(event) => handleRowClick(event)}>
                                {/*<Column sortable field="logDate" header="DR Date" body={logDateBodyTemplate} />*/}
                                <Column sortable field="dealNumber" header="Deal Number" body={dealNumberBodyTemplate} />
                                <Column sortable field="locationName" header="Location" body={locationBodyTemplate} />
                                <Column sortable field="customerName" header="Customer Name" body={custNameBodyTemplate} />
                                <Column field="vehicleInfo" header={vehStockOrderHeader} body={vehicleBodyTemplate} />
                                <Column sortable field="statusDateTime" header="Status Date" body={statusDateBodyTemplate} />
                                <Column sortable field="status" header="Status" body={statusBodyTemplate} />
                                <Column sortable field="sales" header="Sales" body={salesPersonBodyTemplate} />
                                <Column sortable field="finance" header="Finance" body={financePersonBodyTemplate} />
                                <Column field="writeUps" header="Writeups" body={writesUpsBodyTemplate} />
                                <Column field="appointments" header="Appt" body={appointmentsBodyTemplate} />
                                <Column field="tradeIns" header="Trade-ins" body={tradeInsBodyTemplate} />
                                <Column field="cancellations" header="Cancels" body={cancellationsBodyTemplate} />
                                <Column field="tasks" header="Tasks" body={tasksBodyTemplate} />
                                <Column field="afterSale" header="After Sale" body={afterSaleBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </Stack>)
            }
            {
                sectionIndex === 2 && (
                    <>
                        <Row>
                            <Col>
                                <Row style={{ marginTop: "25px" }}>
                                    <Col>
                                        {/* <Link onClick={handleBackToUsers}><ArrowBackIcon /><span className="arrow-back-text">Back</span></Link> */}
                                        <img
                                            alt="Back"
                                            key={"back"}
                                            className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                                            onClick={handleBackToUsers}
                                            src={`${require("assets/back_pagination.png")}`}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="alignHeaders">
                                        <span className="pageName pageHeaderFont">Search All - Dealer Trades Found</span>
                                    </Col>
                                    {
                                        commonServerError ?
                                            <Col>
                                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                            </Col> : null
                                    }
                                </Row>
                                <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                                    <div className="card mouse-hover">
                                        <DataTable
                                            value={tableData_DT}
                                            className="p-datatable-customers"
                                            paginator={false}
                                            header={dealerTradeHeader}
                                            //footer={footer}
                                            emptyMessage="No data founds"
                                            sortField={sortField_DT}
                                            sortOrder={sortOrder_DT}
                                            onSort={handleColumnSort_DT}
                                            //onRowClick={(event) => handleRowClick(event)}
                                        >
                                            <Column sortable field="tradeDate" header="Trade Date" body={tradeDateBodyTemplate} />
                                            <Column sortable field="type" header="Type" body={typeBodyTemplate} />
                                            <Column sortable field="tradeManager" header="Trade Mgr" body={tradeManagerBodyTemplate} />
                                            <Column sortable field="salesPerson" header="Sales Person" body={salesPersonBodyTemplate_DT} />
                                            <Column sortable field="customer" header="Customer" body={customerBodyTemplate_DT} />
                                            <Column sortable field="dealerName" header="Dealer" body={dealerNameBodyTemplate} />
                                            <Column sortable field="dealerCity" header="Dealer City" body={dealerCityBodyTemplate} />
                                            <Column sortable field="dealerCode" header="D-Code" body={dealerCodeBodyTemplate} />
                                            <Column sortable field="vehicleIn" style={{ width: "12em" }}  header="Vehicle In" body={vehicleInBodyTemplate} />
                                            <Column sortable field="vehicleOut" style={{ width: "12em" }}  header="Vehicle Out" body={vehicleOutBodyTemplate} />
                                            <Column sortable field="comments" header="Comments" body={commentsBodyTemplate} />
                                        </DataTable>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                    )
            }
            {
                sectionIndex === 3 && (
                    <>
                        <Row>
                            <Col>
                                <Row style={{ marginTop: "25px" }}>
                                    <Col>
                                        {/* <Link onClick={handleBackToUsers}><ArrowBackIcon /><span className="arrow-back-text">Back</span></Link> */}
                                        <img
                                            alt="Back"
                                            key={"back"}
                                            className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                                            onClick={handleBackToUsers}
                                            src={`${require("assets/back_pagination.png")}`}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="alignHeaders">
                                        <span className="pageName pageHeaderFont">Search All - Transfers Found</span>
                                    </Col>
                                    {
                                        commonServerError ?
                                            <Col>
                                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                            </Col> : null
                                    }
                                </Row>
                                <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                                     <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                                         <DataTable
                                             value={finalData}
                                             paginator={false}
                                             header={vtheader}
                                             //footer={footer}
                                             sortField={sortField_VT}
                                             sortOrder={sortOrder_VT}
                                             onSort={handleColumnSort_VT}
                                             className=" p-datatable-customers reportHeaderPart"
                                             emptyMessage="No Data found.">
                                             <Column sortable field="sentDateTime" header="Sent Time/Date" body={dateBodyTemplate} />
                                             <Column sortable field="stockNumber" header="Stock" body={stockBodyTemplate} />
                                             <Column sortable field="vehicle" header="Vehicle" body={vehiclevtBodyTemplate} />
                                              <Column sortable field="locationName" header="Home Location" body={locationVTBodyTemplate} />
                                             <Column sortable field="transferTo" header="Transfered To" body={transferedtoBodyTemplate} />
                                             <Column sortable field="status" header="Status" body={statusVTBodyTemplate} />
                                             <Column sortable field="requestedBy" header="Requested By" body={requestedByBodyTemplate} />
                                             <Column sortable field="lastNote" header="Notes" body={commentsvtBodyTemplate} />
                                            
                                          </DataTable>
                                     </div>
                                 </div>
                            </Col>
                        </Row>
                    </>
                    )
            }
        </> : null
    );
};
