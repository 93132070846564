import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
    IContextualMenuProps,
    IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors } from "constants/constants";

const iconMenuStyles: IIconStyles = {
    root: {
        color: colors.lighterBlack,
    },
};

interface IProps {
    disableIcon: any;
    onEdit: (reportType: any) => void;
    reportType: any;
}

export const EditShopWorkBookMoreActionSubmenu: React.FunctionComponent<IProps> = ({
    disableIcon,
    onEdit,
    reportType
}) => {
    const [menuProps, setMenuProps] = useState(null);

    useEffect(() => {
        const menuItems: IContextualMenuItem[] = [
            {
                key: "edit", //with submenu
                name: "Edit",
                iconProps: { iconName: "Edit", styles: iconMenuStyles },
                onClick: () => onEdit(reportType),
            }
        ];

        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: menuItems,
        };

        setMenuProps(menuProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType]);

    return (
        <IconButton
            className="iconButton icon-ellipse-h"
            disabled={disableIcon}
            /* className="iconButton moreIcon"
            iconProps={{
                iconName: "More",
            }} */
            styles={buttonStyles}
            text="Group Action"
            menuProps={menuProps}
        />
    );
};

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};
