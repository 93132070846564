import React, { useEffect, useState} from "react";
import { Stack, IStackStyles } from "@fluentui/react";
import Drawer from "components/navigation/drawer/Drawer";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "store";
import { Only } from "components/layouts/only/Only";
import { TopMenu } from "components/navigation/topMenu/TopMenu";

const stackStyles: IStackStyles = {
    root: {
        //height: "100vh",
        // border: "3px solid red",
        // width: '100%',
        // width: `calc(100% - ${300})`,

        //marginLeft: sizes.DRAWER,

        //   paddingLeft: sizes.DRAWER,
        height: "100%",
        // paddingBottom: 500,

        width: "100%",

        //    background:`url(${require("../../../assets/background.jpg")})right bottom no-repeat`,
        // backgroundSize:'cover',
    },
};

const stackStylesTablet: IStackStyles = {
    root: {
        //height: "100vh",
        // border: "3px solid red",
        // width: '100%',
        // height: "100%",
        // paddingBottom: 500
    },
};

const wrapper: IStackStyles = {
    root: {
        width: "100%",
        border: "1px dotted green",
        // marginLeft: 200
        paddingLeft: 40,
        paddingRight: 40,
    },
};

interface IProps {
    children: any;
    handleNavButton: (status) => void;
    showLeftNav?: any;
    showHide?:any;
}

const MainSection: React.FC<IProps> = ({ children, showLeftNav, handleNavButton,showHide }) => {
    const isTablet = useMediaQuery({ maxWidth: 1224 });
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const location = useLocation ();
    const guidedSetup = useSelector(
        (state: AppState) => state.primarySetup.guidedSetup
    );
    const user = useSelector((state: AppState) => state.userProfile.userProfile);

    const userToken = Boolean(useSelector((state: AppState) => state.auth.userToken));
    const [guidedSetupCompleted, setGuidedSetupCompleted] = useState(guidedSetup && guidedSetup.isCompleted);


    useEffect(() => {
            if (guidedSetup && guidedSetup.isCompleted) {
                setGuidedSetupCompleted(location.pathname.indexOf("accessRequested") === -1);
            }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location,guidedSetup])

    useEffect(() => {

        setGuidedSetupCompleted(guidedSetup && guidedSetup.isCompleted)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidedSetup])

    return (
        <Stack
            className={
                Boolean(userToken) && (guidedSetupCompleted || (user && user.roles && user.roles.includes('ROLE_ADMIN'))) ? "mainContainer" : "mainContainerWhenLogout" 
            }
            horizontal={!(Boolean(userToken) && (guidedSetupCompleted || (user && user.roles && user.roles.includes('ROLE_ADMIN')))) || showLeftNav}
            styles={isTablet ? stackStylesTablet : stackStyles}
        >

          {!showHide ?
            
            <Only when={Boolean(userToken) && location.pathname !== "/login" && ((user && user.roles && user.roles.includes('ROLE_ADMIN')) || guidedSetupCompleted)}>
                {

                    isMobile ? <Drawer handleNavButton={handleNavButton} showLeftNav={showLeftNav} /> :
                        <TopMenu />
                }
            </Only> :""
          }
            {/* <Stack className="main-overlay bg-overlay" styles={stackStyles} /> */}
            <Stack
                verticalAlign="center"
                horizontalAlign="center"
                styles={wrapper}
                className={`${Boolean(userToken)&& location.pathname !== "/login" && ((user && user.roles && user.roles.includes('ROLE_ADMIN')) || guidedSetupCompleted) ? 
                    "mainContent" : "mainContentWhenLogout"} ${showLeftNav && "bg-overlay"} ${showHide && "display-board-content"}`}
            >
                <div style={{ marginTop: "40px" }}>
                    {children}
                </div>
            </Stack>
        </Stack>
    );
};

export default MainSection;
