import { ICreateLocation, ILocation } from "types/locationsTypes";
import client, { clientFile } from "services/api/client";

export const getLocationsForDropdown = async () => {
  const page = 1;
  const limit = 1000;

  const query = `user/master/locations?page=${page}&limit=${limit}`;
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getLocations = async () => {
  const query = `user/master/locations?sort=ASC&page=1&limit=1000&column=legalName`;
  // console.log(query);
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getPostVacation = async (id, method, data = null) => {
  const query = `user/master/employees/${id}/vacations`;
  // console.log(query);
  return client(query, { method, body: data ? data : null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getVacationStats = async (id) => {
  const query = `user/master/employees/${id}/vacations/stats`;
  // console.log(query);
  return client(query, { body: null }).then(
    (data) => {
      return { stats: data, error: null };
    },
    (error) => {
      return { stats: null, error };
    }
  );
};

export const createLocations = (location: ICreateLocation) => {
  return client("user/master/locations", { body: location },false).then(
    (data) => {
      //

      //close modal
      return { data, error: null };
    },
    (error) => {
      //
      return { data: null, error };
    }
  );
};

export const viewLocation = (id: number | string) => {
  return client(`user/master/locations/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteLocation = (id: number | string) => {
  return client(`user/master/locations/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateLocation = (id: number | string, data: ILocation) => {
  return client(`user/master/locations/${id}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const addLocationLogo = (id: number, fileData: any) => {
    return clientFile(`user/master/locations/${id}/logo`, {
        body: fileData,
        method: "POST"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};
