import React, {useRef } from 'react';
import IdelTimer from 'react-idle-timer';

interface IProps {
    onIdle: () => void;
}

export const IdleTimerAutoReload: React.FunctionComponent<IProps> = ({ onIdle}) => {
    const sessionTimeOutRef = useRef(null);
    const sessionTime = 600000; //10 mins


    const handleIdle = () => {
        sessionTimeOutRef.current = setTimeout(() => {
            onIdle();
        }, 0);
    }


    return (
        <IdelTimer timeout={sessionTime} onIdle={handleIdle}/>
    )
}

