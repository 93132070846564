import React from "react";
import { Col, Container, Row } from "react-grid-system";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import { TextFieldWithLabelAndInfoIcon } from "components/textFieldWithLabelAndInfoIcon/TextFieldWithLabelAndInfoIcon";
import { styles } from "pages/dealSettingsPage/styles";
import { PRICE_PREFIX } from "constants/constants";
import CurrencyInput from 'react-currency-input-field'

interface IProps {
  formik: any;
}

const GlobalFees: React.FC<IProps> = ({ formik }) => {
  return (
    <Container style={styles.container} fluid>
      <Row gutterWidth={75}>
        <Col sm={12} md={12} xl={6}>
          <WithOptionalLabel>
            <label className="lbl-text ">License Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="licenseFee"
              name="licenseFee"
              //label="License Fee"
              value={formik.values.licenseFee?formik.values.licenseFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "licenseFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.licenseFee &&
                formik.errors.licenseFee &&
                formik.errors.licenseFee.toString()}
            </span>


            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="licenseFee"*/}
            {/*  name="licenseFee"*/}
            {/*  label="License Fee"*/}
            {/*  value={formik.values.licenseFee}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.licenseFee &&*/}
            {/*    formik.errors.licenseFee &&*/}
            {/*    formik.errors.licenseFee*/}
            {/*  }*/}
            {/*/>*/}


          </WithOptionalLabel>

          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="docFee"*/}
            {/*  name="docFee"*/}
            {/*  label="Doc Fee"*/}
            {/*  value={formik.values.docFee}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.docFee &&*/}
            {/*    formik.errors.docFee &&*/}
            {/*    formik.errors.docFee*/}
            {/*  }*/}
            {/*/>*/}
            <label className="lbl-text ">Doc Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="docFee"
              name="docFee"
              //label="Doc Fee"
              value={formik.values.docFee?formik.values.docFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "docFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.docFee &&
                formik.errors.docFee &&
                formik.errors.docFee.toString()}
            </span>
          </WithOptionalLabel>
          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="registrationFee"*/}
            {/*  name="registrationFee"*/}
            {/*  label="Registration Fee"*/}
            {/*  value={formik.values.registrationFee}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.registrationFee &&*/}
            {/*    formik.errors.registrationFee &&*/}
            {/*    formik.errors.registrationFee*/}
            {/*  }*/}
            {/*/>*/}


            <label className="lbl-text ">Registration Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="registrationFee"
              name="registrationFee"
              //label="Registration Fee"
              value={formik.values.registrationFee?formik.values.registrationFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "registrationFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.registrationFee &&
                formik.errors.registrationFee &&
                formik.errors.registrationFee.toString()}
            </span>

          </WithOptionalLabel>
          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*    prefix="$"*/}
            {/*    id="titleFee"*/}
            {/*    name="titleFee"*/}
            {/*    label="Title Fee"*/}
            {/*    value={formik.values.titleFee}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    onBlur={formik.handleBlur}*/}
            {/*    errorMessage={*/}
            {/*        formik.touched.titleFee &&*/}
            {/*        formik.errors.titleFee &&*/}
            {/*        formik.errors.titleFee.toString()*/}
            {/*    }*/}
            {/*/>*/}

            <label className="lbl-text ">Title Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="titleFee"
              name="titleFee"
              //label="Title Fee"
              value={formik.values.titleFee?formik.values.titleFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "titleFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.titleFee &&
                formik.errors.titleFee &&
                formik.errors.titleFee.toString()}
            </span>

          </WithOptionalLabel>
        </Col>

        <Col sm={12} md={12} xl={6}>

          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="tireFee"*/}
            {/*  name="tireFee"*/}
            {/*  label="Tire Fee"*/}
            {/*  value={formik.values.tireFee}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.tireFee &&*/}
            {/*    formik.errors.tireFee &&*/}
            {/*    formik.errors.tireFee.toString()*/}
            {/*  }*/}
            {/*/>*/}


            <label className="lbl-text ">Tire Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="tireFee"
              name="tireFee"
              //label="Tire Fee"
              value={formik.values.tireFee?formik.values.tireFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "tireFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}

            />
            <span className="error-message">
              {formik.touched.tireFee &&
                formik.errors.tireFee &&
                formik.errors.tireFee.toString()}
            </span>
          </WithOptionalLabel>

          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="lenderProcessing"*/}
            {/*  name="lenderProcessing"*/}
            {/*  label="Lender Processing"*/}
            {/*  value={formik.values.lenderProcessing}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.lenderProcessing &&*/}
            {/*    formik.errors.lenderProcessing &&*/}
            {/*    formik.errors.lenderProcessing.toString()*/}
            {/*  }*/}
            {/*/>*/}


            <label className="lbl-text ">Lender Processing</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="lenderProcessing"
              name="lenderProcessing"
              //label="Lender Processing"
              value={formik.values.lenderProcessing?formik.values.lenderProcessing.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "lenderProcessing": e
                    }
                );
            }}
              onBlur={formik.handleBlur}

            />
            <span className="error-message">
              {formik.touched.lenderProcessing &&
                formik.errors.lenderProcessing &&
                formik.errors.lenderProcessing.toString()}
            </span>


          </WithOptionalLabel>
          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*    prefix="$"*/}
            {/*    id="lienFee"*/}
            {/*    name="lienFee"*/}
            {/*    label="Lien Fee"*/}
            {/*    value={formik.values.lienFee}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    onBlur={formik.handleBlur}*/}
            {/*    errorMessage={*/}
            {/*        formik.touched.lienFee &&*/}
            {/*        formik.errors.lienFee &&*/}
            {/*        formik.errors.lienFee.toString()*/}
            {/*    }*/}
            {/*/>*/}


            <label className="lbl-text ">Lien Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="lienFee"
              name="lienFee"
              //label="Lien Fee"
              value={formik.values.lienFee?formik.values.lienFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "lienFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}

            />
            <span className="error-message">
              {formik.touched.lienFee &&
                formik.errors.lienFee &&
                formik.errors.lienFee.toString()}
            </span>
          </WithOptionalLabel>
          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*    prefix="$"*/}
            {/*    id="otherFee"*/}
            {/*    name="otherFee"*/}
            {/*    label="Other Fee"*/}
            {/*    value={formik.values.otherFee}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    onBlur={formik.handleBlur}*/}
            {/*    errorMessage={*/}
            {/*        formik.touched.otherFee &&*/}
            {/*        formik.errors.otherFee &&*/}
            {/*        formik.errors.otherFee.toString()*/}
            {/*    }*/}
            {/*/>*/}

            <label className="lbl-text ">Other Fee</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="otherFee"
              name="otherFee"
              //label="Other Fee"
              value={formik.values.otherFee?formik.values.otherFee.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "otherFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}

            />
            <span className="error-message">
              {formik.touched.licenseFee &&
                formik.errors.otherFee &&
                formik.errors.otherFee.toString()}
            </span>
          </WithOptionalLabel>
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalFees;
