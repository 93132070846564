import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import {
    useDispatch
} from "react-redux";
import { handleCreateDealRecord } from "store/dealTracker/dealTracker.action";

const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (response) => void;
    isOpen: boolean;
    newData?: any;
    oldData?: any;
    changeCustomerData: () => void;
    confirmCustomerData: () => void;
}

export const CustomerRecordConfirmDialog: FunctionComponent<IProps> = ({
    onDismiss,
    isOpen,
    newData,
    oldData,
    changeCustomerData,
    confirmCustomerData,
}) => {

    const dispatch = useDispatch();

    const handleDelete = () => {
        changeCustomerData();
    };

    const handleCreateCustomer = () => {
           dispatch<any>(handleCreateDealRecord({
                deal: newData,
                callback: (response) => {
                    if (typeof response === 'number') {
                        console.log('dalied res',response)
                        // setCreateDealError('Failed to Save the Data.');
                        onDismiss(response);
                        window.scrollTo(0, 0);
                    } else {
                        onDismiss(response);
                    }

                }
            }));

          confirmCustomerData();


    };
    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog dealFoundDiaHeight diaFootBtnWidth"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Confirm Customer Update</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "15px",marginTop: "-25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text"> This will update customer information for ALL DealTracker records with this Customer No . </label>
                    </div>
                    <div>
                        <label className="lbl-text"> Please confirm customer update. </label>
                    </div>
                </div>
            </div>

            <div className="dealRecFound body-auto-hight body-min-height-50">
                <Row>
                    <Col>   
                            <Row style={{paddingBottom:"10px"}}>
                                    <Col>
                                              <div style={{fontWeight:"bold"}}> Current </div>
                                    </Col>
                            </Row>
                            <Row>
                                   <Col style={{display:"flex",justifyContent:"center"}}>
                                        <div> Customer #: </div>  <div> {oldData.customerNumber} </div>
                                   </Col>
                            </Row>
                            <Row>
                                   <Col>
                                         <div> {oldData.firstName} {oldData.lastName} {oldData.businessName} </div>
                                   </Col>
                            </Row>
                            <Row>
                                   <Col>
                                         <div> {oldData.phoneNumber} </div>
                                   </Col>
                            </Row>
                            <Row>
                                  <Col>
                                         <div> {oldData.email} </div>
                                  </Col>
                            </Row>
                    </Col>
                    <Col>
                            <Row style={{paddingBottom:"10px"}}>
                                    <Col>
                                              <div style={{fontWeight:"bold"}}> New </div>
                                    </Col>
                            </Row>
                            <Row>
                                  <Col style={{display:"flex",justifyContent:"center"}}>
                                       <div> Customer #: </div>  <div> {newData.customerNumber} </div>
                                  </Col>
                            </Row>
                            <Row>
                                  <Col>
                                        <div> {newData.firstName}  {newData.lastName} {newData.businessName}</div>
                                  </Col>
                            </Row>
                            <Row>
                                  <Col>
                                        <div> {newData.phoneNumber} </div>
                                 </Col>
                            </Row>
                            <Row>
                                 <Col>
                                      <div> {newData.email} </div>
                                 </Col>
                            </Row>
                    </Col>
                </Row>
            </div>

            <div className={contentStyles.footer + " inventory-popup-footer"} style={{ padding: "40px 60px " }}>
                 <Row className="inventoryRowSection footerBtn ">
                      <Col>
                          <PrimaryButton
                              style={{ float: "left",backgroundColor:"#af2b01",width:'75%'}}
                              onClick={handleDelete}
                              text="Cancel"
                              className="btnHoverColor"
                          />
                      </Col>
                      <Col>
                          <PrimaryButton
                              text="Save"
                              style={{ float: "right" }}
                              onClick={handleCreateCustomer}
                          />

                      </Col>
                    </Row>
            </div>
        </Modal>
    );
};



