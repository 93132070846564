import React, { useState, useEffect } from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    DefaultButton,
    Modal,
    IconButton,
    TextField,
    //MaskedTextField,
    PrimaryButton,
    Dropdown,
    Checkbox,
    DatePicker,
    ChoiceGroup,
    IChoiceGroupOption,
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "./styles";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { validURL } from "utils/helperFunctions";

import {
    handleUpdateLocation,
    handleViewLocation,
} from "store/locations/locations.action";

import { AppState } from "store";
import { cancelIcon, iconButtonStyles } from "constants/styles";
import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import masterDropdownValues from "constants/masterDropdownValues";
import './style.css';
import moment from "moment";
import { validTenDigitPhone } from "utils/helperFunctions";
import { InputMask } from 'primereact/inputmask';
//import { Dropdown as PRDropdown } from 'primereact/dropdown';

const emptyForm = {
    legalName: "",
    dealerLicenseNumber: "",
    dealerLicenseDateOfIssue: moment(new Date()).format("YYYY-MM-DD"),
    dealerLicenseState: "US",
    dealerType: "",
    collateral: "auto",
    inventoryCondition: [],
    isMaintenanceFacilityAvailable: true,
    street: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    phoneNumber: "",
    faxNumber: "",
    website: "",
    img: "",
    logo: ""
};

interface IProps {
    isOpen: boolean;
    onDismiss: () => void;
    locationId: number;
}

const options: IChoiceGroupOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' },
];
const collateralOptions: IChoiceGroupOption[] = [
    { key: 'auto', text: 'Auto' },
    { key: 'rv', text: 'RV' },
];
export const EditLocationDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    locationId,
}) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: AppState) => state.locations.loading);
    const location = useSelector(
        (state: AppState) => state.locations.selectedLocation
    );
    const serverError = useSelector((state: AppState) => state.locations.error) as any;

    const examplePersona: IPersonaSharedProps = {
        imageUrl: `${require("assets/building-image-placeholder.jpg")}`,
        text: "",
        secondaryText: "",
    };

    const [commonServerError, setcommonServerError] = React.useState(null);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [locationZipApiMsg, setlocationZipApiMsg] = React.useState("");
    const [persona, setPersona] = React.useState(examplePersona);
    const [uploadError, setUploadError] = useState("");
    const [issuedDate, setIssuedDate] = useState(new Date());
    //const [selectedState, setSelectedState] = useState<any>(null);

    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const formik = useFormik({
        initialValues: {
            ...emptyForm,
        },

        validationSchema: Yup.object({
            legalName: Yup.string().required("Required"),
            dealerLicenseNumber: Yup.string().required("Required").min(1, "Dealer License Number must be Minimum 1 Character").max(50, "Dealer License Number must be Maximum 50 Character"),
            dealerLicenseDateOfIssue: Yup.string().required("Required"),
            dealerLicenseState: Yup.string(),//.required("Required"),
            dealerType: Yup.string().required("Required"),
            collateral: Yup.string().required("Required"),
            isMaintenanceFacilityAvailable: Yup.boolean(),
            inventoryCondition: Yup.array().nullable().min(1, "Required"),
            street: Yup.string().required("Required"),
            city: Yup.string(),
            state: Yup.string(),
            zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
            county: Yup.string(),
            faxNumber: Yup.string()
                .test("len", "Invalid fax number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }),
            website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),//optional 
            phoneNumber: Yup.string()
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Required"),
            img: Yup.string()
        }),
        onSubmit: (values) => {
            values.phoneNumber = values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10);
            const {
                legalName,
                street,
                zip,
                city,
                county,
                state,
                phoneNumber,
                faxNumber,
                website,
                dealerLicenseNumber,
                dealerLicenseDateOfIssue,
                dealerLicenseState,
                dealerType,
                collateral,
                inventoryCondition,
                isMaintenanceFacilityAvailable,
                img
            } = values;
            dispatch<any>(
                handleUpdateLocation({
                    location: {
                        id: location.id,
                        legalName,
                        street,
                        zip,
                        city,
                        county,
                        state,
                        phoneNumber: phoneNumber.replace(/\D/g, '').slice(0, 10),
                        faxNumber: (faxNumber && faxNumber.length > 0) ? faxNumber.replace(/\D/g, '').slice(0, 10) : '',
                        website,
                        dealerLicenseNumber,
                        dealerLicenseDateOfIssue,
                        dealerLicenseState,
                        dealerType,
                        collateral,
                        inventoryCondition,
                        isMaintenanceFacilityAvailable: isMaintenanceFacilityAvailable.toString() === 'true',
                        img,
                        physicalAddress1: street
                    },
                    callback: () => onDismiss(),
                })
            );

            // to be removed and fire only when no errors
        },
    });

    const handleLogoChange = e => {
        e.preventDefault();
        setUploadError('');
        let reader = new FileReader();
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }
        //setError('');
        reader.onloadend = () => {
            examplePersona.imageUrl = reader.result.toString();
            setPersona(examplePersona);
            formik.setValues(
                {
                    ...formik.values,
                    "img": file,
                },
                true
            );
        }
        reader.readAsDataURL(file)
    }

    const handleZipCodeChangeForDealer = (e) => {
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            formik.setValues(
                {
                    ...formik.values,
                    zip: e.target.value
                }
            );
        }
    };

    const handleDateFormat = (e) => {
        return moment(e).format("MM/DD/YYYY");
    };

    const handleDropdownValue = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key
            }
        );
    };
   
    const handleDateChange = (e) => {
        formik.setValues(
            {
                ...formik.values,
                dealerLicenseDateOfIssue: moment(e).format("YYYY-MM-DD")
            }
        );

        setIssuedDate(new Date(e));
        //updateForm("IssuedDate", issuedDate);
    };

    const onChangeCheckBox = (e, isChecked) => {
        if (isChecked) {
            if (!formik.values.inventoryCondition.includes(e.target.id)) {
                let arr = formik.values.inventoryCondition;
                arr.push(e.target.id);
                formik.setValues(
                    {
                        ...formik.values,
                        inventoryCondition: arr,
                    },
                    true
                );
            }
        } else {
            if (formik.values.inventoryCondition.includes(e.target.id)) {
                formik.setValues(
                    {
                        ...formik.values,
                        inventoryCondition: formik.values.inventoryCondition.filter(item => item !== e.target.id) as any,
                    },
                    true
                );
            }
        }
    }

    const handleDropdownRadioChange = (e, item) => {
        let field = e.target.name ? e.target.name : e.target.id
        formik.setValues(
            {
                ...formik.values,
                [field]: item.key
            }
        );
    };

    useEffect(() => {
        let zipFields = formik.values.zip;
        if (zipFields.length === 5 && !isNaN(zipFields as any)) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(zipFields) as any).then((response) => {

                if (response.Status) {
                    setCityDealerList(response.Cities);
                    //setcountyDealerList(response.Counties);
                    if (formik.values.city === '' &&
                        formik.values.county === '' &&
                        formik.values.state === '') {
                        formik.setValues(
                            {
                                ...formik.values,
                                'county': response.DefaultCounty,
                                'city': response.DefaultCity,
                                'state': response.State
                            }
                        );
                    }
                    setlocationZipApiMsg("");
                    // updateForm("State", response.State);
                }
                else {
                    // setZipApiMsg(response.ErrorMessage);
                    setlocationZipApiMsg(response.ErrorMessage);
                    formik.setValues(
                        {
                            ...formik.values,
                            'county': "",
                            'city': "",
                            'state': ""
                        }
                    );
                    setCityDealerList([]);
                    //setcountyDealerList([]);
                    // resetCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
        } else {
            formik.setValues(
                {
                    ...formik.values,
                    'county': "",
                    'city': "",
                    'state': ""
                }
            );
            setCityDealerList([]);
            setlocationZipApiMsg("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.zip]);

    useEffect(() => {
        if (isOpen) {
            dispatch<any>(handleViewLocation(locationId));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {

        if (location) {
            formik.setValues({
                legalName: location.legalName,
                street: location.physicalAddress1,
                zip: location.zip,
                city: location.city,
                county: location.county,
                state: location.state,
                phoneNumber: location.phoneNumber,
                faxNumber: location.faxNumber,
                website: location.website,
                dealerLicenseNumber: location.dealerLicenseNumber,
                dealerLicenseDateOfIssue: location.dealerLicenseDateOfIssue as any,
                dealerLicenseState: location.dealerLicenseState,
                dealerType: location.dealerType,
                collateral: location.collateral,
                inventoryCondition: location.inventoryCondition,
                isMaintenanceFacilityAvailable: location.isMaintenanceFacilityAvailable as any,
                logo: location.logo,
                img: location.logo
            }, true);

            examplePersona.imageUrl = location.logo
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${location.logo}`
                : `${require("assets/building-image-placeholder.jpg")}`;
            setPersona(examplePersona);

            //if (location.zip)
            //    getPrimaryZipDetails(location.zip, false, location.mailingZip);
            //if (location.zip !== location.mailingZip)
            //    getMailingZipDetails(location.mailingZip, false);
            setIssuedDate(new Date(location.dealerLicenseDateOfIssue));
            //setSelectedState({ code: location.dealerLicenseState, name: location.dealerLicenseState});
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail.split('\n')[0]);
            } else {
                setcommonServerError('Failed to Save the Data');
            }
            window.scrollTo(0, 0);
        }
    }, [serverError]);

    const handleEditLocation = () => {
        console.log("sending form: ", formik);
        formik.handleSubmit();
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleMaskInputLocation = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    //const handleStateChange = (e: { value: any }) => {
    //    setSelectedState(e.value);
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            'dealerLicenseState': e.value ? e.value.name : ""
    //        }
    //    );
    //}

    //const selectedStateTemplate = (option: { name: string, code: string }, props: { placeholder: string }) => {
    //    if (option) {
    //        return (
    //            <div className="state-item state-item-value">
    //                <div>{option.name}</div>
    //            </div>
    //        );
    //    }

    //    return (
    //        <span>
    //            {props.placeholder}
    //        </span>
    //    );
    //}

    //const stateOptionTemplate = (option: any) => {
    //    return (
    //        <div className="state-item">
    //            <div>{option.name}</div>
    //        </div>
    //    );
    //}

    const resetModal = () => {
        formik.setValues({ ...emptyForm });
        formik.resetForm();
        examplePersona.imageUrl = `${require("assets/building-image-placeholder.jpg")}`;
        setPersona(examplePersona);
        setIssuedDate(null);
        //setSelectedState(null);
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            onDismissed={resetModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            {/*<Loader show={loading} />*/}
            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText>Edit Location</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                <div>
                    <h2 className="tab-inst">What name do you use to market your dealership on your website, in advertising, etc.?</h2>
                    {
                        commonServerError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                </div>
                <div className="location">
                    <div className="tabRowSection">
                        <Row>
                            <Col>
                                {/* <label className="lbl-text">Dealership Name</label> */}
                                <h3>Dealership Location Name</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3} style={{ paddingTop: "30px" }}>
                                <TextField
                                    id="legalName"
                                    name="legalName"
                                    label=""
                                    placeholder=""
                                    value={formik.values.legalName}
                                    autoComplete="off"
                                    //required={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.legalName &&
                                        formik.errors && formik.errors.legalName &&
                                        formik.errors.legalName.toString()
                                    }
                                />
                            </Col>
                            <Col>
                                <Persona
                                    {...persona}
                                    styles={styles.personStyles}
                                    size={PersonaSize.size100}
                                    presence={PersonaPresence.none}
                                    hidePersonaDetails={false}
                                    imageAlt="Annie Lindqvist, status is dnd"
                                />
                                <DefaultButton
                                    styles={styles.uploadButton}
                                    text="Upload Logo"
                                    type="file"
                                    iconProps={{ iconName: "Upload" }}
                                >
                                    <input
                                        style={{
                                            padding: 20,
                                            // border: '1px solid red',
                                            position: "absolute",
                                            left: 0,
                                            top: 0,
                                            width: 107,
                                            height: 1,
                                            overflow: "hidden",
                                            opacity: 0,
                                            marginLeft: -10,
                                        }}
                                        type="file"
                                        onChange={handleLogoChange}
                                    />
                                </DefaultButton>
                                {
                                    uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                                }
                            </Col>
                        </Row>
                    </div>

                    <div className="tabRowSection dealership-info">
                        <Row>
                            <Col>
                                {/* <label className="lbl-text">Physical Address</label> */}
                                <h3>Complete the following information for this dealership location</h3>
                            </Col>
                        </Row>
                        <Row className="rowSpace">
                            <Col sm={6}>
                                <TextField
                                    id="street"
                                    name="street"
                                    label="Street"
                                    placeholder=""
                                    autoComplete="off"
                                    value={formik.values.street}
                                    // onChange={(e) => handleLegalNameChange(e,'firstName')}
                                    //onBlur={formik.handleBlur}
                                    required={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.street &&
                                        formik.errors && formik.errors.street &&
                                        formik.errors.street.toString()
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                <TextField
                                    id="zip"
                                    name="zip"
                                    label="Zip"
                                    placeholder=""
                                    autoComplete="off"
                                    value={formik.values.zip}
                                    onChange={(e) => handleZipCodeChangeForDealer(e)}
                                    //onBlur={formik.handleBlur}
                                    required={true}
                                    // onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        (formik.touched && formik.touched.zip &&
                                            formik.errors && formik.errors.zip) ?
                                            formik.errors.zip.toString()
                                            : locationZipApiMsg ? locationZipApiMsg : null
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                <Dropdown
                                    id="city"
                                    label="City"
                                    placeholder=""
                                    selectedKey={formik.values.city}
                                    options={cityDealerList}
                                    onChange={handleDropdownValue}
                                    required={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <TextField
                                    id="state"
                                    name="state"
                                    label="State"
                                    placeholder=""
                                    autoComplete="off"
                                    value={formik.values.state}
                                    required={true}
                                    readOnly={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.state &&
                                        formik.errors && formik.errors.state &&
                                        formik.errors.state.toString()
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                {/*<MaskedTextField
                                    mask="(999)999-9999"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Business Phone"
                                    autoComplete="off"
                                    value={formik.values.phoneNumber}
                                    // onChange={(e) => handleLegalNameChange(e,'firstName')}
                                    //onBlur={formik.handleBlur}
                                    required={true}
                                    onChange={(e) => handleMaskInputLocation(e)}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.phoneNumber &&
                                        formik.errors && formik.errors.phoneNumber &&
                                        formik.errors.phoneNumber.toString()
                                    }
                                />*/}
                                <div className="ms-TextField root-155">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text required-Label">Business Phone</label>
                                        <div className={formik.touched && formik.touched.phoneNumber &&
                                            formik.errors && formik.errors.phoneNumber ? "mask-FieldGroup invalid-input" : "mask-FieldGroup"}>
                                            <InputMask
                                                autoClear={false}
                                                required={true}
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                mask="(999)999-9999"
                                                placeholder=""
                                                value={formik.values.phoneNumber}
                                                className="mask-TextField"
                                                onChange={(e) => handleMaskInputLocation(e)}
                                                onBlur={formik.handleBlur}>
                                            </InputMask>
                                        </div>
                                        <span className="error-message">{
                                            formik.touched && formik.touched.phoneNumber &&
                                            formik.errors && formik.errors.phoneNumber &&
                                            formik.errors.phoneNumber.toString()
                                        }</span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                {/*<MaskedTextField
                                    mask="(999)999-9999"
                                    id="faxNumber"
                                    name="faxNumber"
                                    label="Fax"
                                    autoComplete="off"
                                    value={formik.values.faxNumber}
                                    onChange={(e) => handleMaskInputLocation(e)}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.faxNumber &&
                                        formik.errors && formik.errors.faxNumber &&
                                        formik.errors.faxNumber.toString()
                                    }
                                />*/}
                                <div className="ms-TextField root-155">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Fax</label>
                                        <div className={formik.touched && formik.touched.faxNumber &&
                                            formik.errors && formik.errors.faxNumber ? "mask-FieldGroup invalid-input" : "mask-FieldGroup"}>
                                            <InputMask
                                                autoClear={false}
                                                required={true}
                                                id="faxNumber"
                                                name="faxNumber"
                                                mask="(999)999-9999"
                                                placeholder=""
                                                value={formik.values.faxNumber}
                                                className="mask-TextField"
                                                onChange={(e) => handleMaskInputLocation(e)}
                                                onBlur={formik.handleBlur}>
                                            </InputMask>
                                        </div>
                                        <span className="error-message">{
                                            formik.touched && formik.touched.faxNumber &&
                                            formik.errors && formik.errors.faxNumber &&
                                            formik.errors.faxNumber.toString()
                                        }</span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <TextField
                                    id="website"
                                    name="website"
                                    label="Website"
                                    placeholder=""
                                    prefix="https://"
                                    ariaLabel="Example text field with https:// prefix"
                                    autoComplete="off"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.website &&
                                        formik.errors && formik.errors.website &&
                                        formik.errors.website.toString()
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="tabRowSection">
                        <Row>
                            <Col>
                                <h3>Dealer License</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <TextField
                                    id="dealerLicenseNumber"
                                    name="dealerLicenseNumber"
                                    label="Dealer License Number"
                                    placeholder=""
                                    autoComplete="off"
                                    value={formik.values.dealerLicenseNumber}
                                    required={true}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched && formik.touched.dealerLicenseNumber &&
                                        formik.errors && formik.errors.dealerLicenseNumber &&
                                        formik.errors.dealerLicenseNumber.toString()
                                    }
                                />
                            </Col>
                            <Col sm={3}>
                                <DatePicker
                                    style={{ marginBottom: 0 }}
                                    placeholder=""
                                    value={issuedDate}
                                    maxDate={new Date()}
                                    ariaLabel="Select a date"
                                    onSelectDate={(e) => handleDateChange(e)}
                                    allowTextInput
                                    formatDate={(date) => handleDateFormat(date)}
                                    showGoToToday={true}
                                    isRequired={true}
                                    label="Dealer License Date of Issue"
                                />
                            </Col>
                            {/*<Col sm={3}>
                                <label style={{ display: "block" }} className="lbl-text required-Label">Dealer License State</label>
                                <PRDropdown
                                    className={formik.errors && formik.errors.dealerLicenseState ? "invalid-input" : ""}
                                    value={selectedState}
                                    options={masterDropdownValues.states}
                                    onChange={handleStateChange}
                                    optionLabel="name"
                                    appendTo="self"
                                    filter
                                    id="dealerLicenseState"
                                    name="dealerLicenseState"
                                    showClear
                                    filterBy="name"
                                    onBlur={formik.handleBlur}
                                    placeholder="Select a state"
                                    valueTemplate={selectedStateTemplate}
                                    itemTemplate={stateOptionTemplate} />
                                <span className="error-message">
                                    {formik.errors && formik.errors.dealerLicenseState &&
                                        formik.errors.dealerLicenseState.toString()}
                                </span>
                            </Col>*/}
                            <Col sm={3}>
                                <Dropdown
                                    id="dealerType"
                                    label="Dealer Type"
                                    placeholder=""
                                    options={masterDropdownValues.dealerTypeList}
                                    selectedKey={formik.values.dealerType}
                                    onChange={handleDropdownValue}
                                    required={true}
                                    errorMessage={
                                        formik.touched && formik.touched.dealerType &&
                                        formik.errors && formik.errors.dealerType &&
                                        formik.errors.dealerType.toString()
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="rowSpace">

                            <Col>
                                <div>
                                    <Row className="emptySection"><span></span></Row>
                                    <Row>
                                        <ChoiceGroup id="collateral" name="collateral"
                                            required={true}
                                            className="guidedRadio"
                                            options={collateralOptions}
                                            onChange={handleDropdownRadioChange}
                                            selectedKey={formik.values.collateral}
                                            label="What type of collateral does this dealership primarily sell?" />
                                        <span className="error-message">{
                                            formik.touched && formik.touched.collateral &&
                                                formik.errors && formik.errors.collateral
                                                ? formik.errors.collateral.toString()
                                                : null
                                        }</span>
                                    </Row>
                                </div>
                            </Col>
                            <Col>
                                <div id="inventoryUsed">
                                    <Row className="emptySection"><span></span></Row>
                                    <Row>
                                        <label className="lbl-text labelArea inventoryLabel required-Label">Is your inventory new or used?</label>
                                        <label className="firstInputEvt checkboxArea">
                                            <Checkbox
                                                label="New"
                                                id="new"
                                                onChange={onChangeCheckBox}
                                                checked={formik.values.inventoryCondition.includes("new")}
                                            />
                                        </label>

                                        <label className="firstInputEvt checkboxArea">
                                            <Checkbox
                                                label="Used"
                                                id="used"
                                                onChange={onChangeCheckBox}
                                                checked={formik.values.inventoryCondition.includes("used")}
                                            />
                                        </label>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="emptySection"><span></span></Row>
                                <Row>
                                    <ChoiceGroup
                                        id="isMaintenanceFacilityAvailable"
                                        className="guidedRadio"
                                        name="isMaintenanceFacilityAvailable"
                                        defaultSelectedKey="true"
                                        options={options}
                                        required={true}
                                        selectedKey={formik.values.isMaintenanceFacilityAvailable.toString()}
                                        onChange={handleDropdownRadioChange}
                                        label="Does this dealership have a service department or repair faciliity?" />
                                </Row>
                            </Col>
                            <Col><span></span></Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className={contentStyles.footer}>
                <Stack
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: 10 }}

                // styles={contentStyles.buttonsStyles}
                >
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <DefaultButton text="Close" onClick={onDismiss} />
                        <PrimaryButton
                            onRenderIcon={renderSpinner}
                            text="Save"
                            onClick={() => handleEditLocation()}
                            disabled={!formik.isValid || !formik.dirty}
                        />
                    </Stack>
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        width: "90%",
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "20px 30px",
    },

    body: {
        // flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingBottom: "40px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 40,
    },
});
