import React, { useState, useEffect } from "react";
import { Nav, INavLink, INavStyles } from "@fluentui/react/lib/Nav";

//import LeftMenuItem from "components/navigation/leftMenuItem/LeftMenuItem";

import { useNavigate , useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store/index";
import {
    // getModulePermissions,
    isEmployeePermitted,
    // isPermitted,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
// import { eTypes } from "hoc/withCredentials";
import { registerIcons } from '@fluentui/react/lib/Styling';
import { karlProfileURLKeys, defaultProfileURLKeys } from 'constants/constants';
import { isKarlProfile, isDefaultProfile } from "utils/permissions/permissionsHelpers";


const navStyles: Partial<INavStyles> = {
    // chevronButton: undefined,
    // chevronIcon: undefined,
    // compositeLink: undefined,
    // group: undefined,
    // groupContent: undefined,
    // link: {},
    linkText: {},
    navItem: {
        fontWeight: "bold",
    },
    // navItems: {
    //   // paddingLeft: 10
    // },
    root: {
        width: 235,
        //height: '100%',
        // border: '1px solid red'
        // overflowY: "auto",
    },
};

interface IProps {
    handleNavButton: (status) => void;
}

//const Dashboard = (
//    <LeftMenuItem label="Dashboard" iconName="Home" notificationCount={0} />
//);

//const Inventory = <LeftMenuItem label="Workbook" iconName="Car" />;
//const Deals = <LeftMenuItem label="Deals" notificationCount={0} />;
//const Prospects = <LeftMenuItem label="Prospects" notificationCount={0} />;
//const DeskADeal = <LeftMenuItem label="Desk a Deal" />
//const Marketing = <LeftMenuItem label="Marketing" />
// const GuidedSetup = <LeftMenuItem label="Guided Setup" />
// const Settings = <LeftMenuItem label="Settings" notificationCount={0} />;

registerIcons({
    icons: {
        'deal-tracker-icon': (
            <svg id="Appraise_AdobeStock_409675288" data-name="Appraise AdobeStock_409675288" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14.917 28">
                <path id="Path_141"
                    data-name="Path 141"
                    d="M340.024,204.741a7.065,7.065,0,0,0-2.611-1.57V200.1a2.222,2.222,0,0,1,.964.28,3.207,3.207,0,0,1,.855.728l1.114-1.435a4.389,4.389,0,0,0-1.293-.931,5.055,5.055,0,0,0-1.64-.436v-1.2h-1.263v1.225a4.04,4.04,0,0,0-2.307,1.079,3.076,3.076,0,0,0-.892,2.268,2.71,2.71,0,0,0,.711,1.9,7.309,7.309,0,0,0,2.481,1.517v3.432a2.416,2.416,0,0,1-1.2-.322,4.082,4.082,0,0,1-1.108-.978l-1.222,1.3a4.669,4.669,0,0,0,1.531,1.251,5.419,5.419,0,0,0,2.01.533v1.468h1.267v-1.469a4.079,4.079,0,0,0,2.447-1.071,3.235,3.235,0,0,0,.926-2.393A3.018,3.018,0,0,0,340.024,204.741Zm-3.873-1.978a2.59,2.59,0,0,1-.816-.579,1.171,1.171,0,0,1-.325-.8,1.2,1.2,0,0,1,.328-.878,1.6,1.6,0,0,1,.813-.409Zm2.217,5.357a1.67,1.67,0,0,1-.951.445v-3.054a2.861,2.861,0,0,1,.974.636,1.32,1.32,0,0,1,.356.92,1.447,1.447,0,0,1-.383,1.053Z"
                    transform="translate(-329.241 -189.696)"
                    fill="#0065ca" />
                <path id="Path_142"
                    data-name="Path 142"
                    d="M301.476,103.371A2.38,2.38,0,0,0,299.1,101h-10.17a2.381,2.381,0,0,0-2.374,2.373v23.254A2.379,2.379,0,0,0,288.934,129H299.1a2.38,2.38,0,0,0,2.374-2.373Zm-9.19-1.1h3.466a.309.309,0,1,1,0,.618h-3.466a.309.309,0,1,1,0-.618Zm7.756,23.563H288V104.164h12.038Z"
                    transform="translate(-286.56 -101)"
                    fill="#0065ca" />
            </svg>),
        'home-icon': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 28.445 24.001">
                <g id="Layer_2" data-name="Layer 2" transform="translate(-0.002 0.001)">
                    <g id="Layer_1" data-name="Layer 1" transform="translate(0.002 -0.001)">
                        <path id="Path_123" data-name="Path 123" d="M27.931,10.28,23.113,6.542V2.222a.446.446,0,0,0-.445-.445H20a.446.446,0,0,0-.445.445V3.783L15.042.279a1.333,1.333,0,0,0-1.634,0L.518,10.28a1.333,1.333,0,1,0,1.635,2.107l11.4-8.849a1.181,1.181,0,0,1,.667-.286,1.179,1.179,0,0,1,.666.286L26.3,12.386a1.333,1.333,0,1,0,1.635-2.107Z" transform="translate(-0.002 0.001)" fill="#fff" />
                        <path id="Path_124" data-name="Path 124" d="M195.983,245.416l-9.965-7.731a.6.6,0,0,0-.7,0l-9.965,7.731a1.028,1.028,0,0,0-.351.717V256.3a.447.447,0,0,0,.445.444h7.111A.446.446,0,0,0,183,256.3v-7.111a.447.447,0,0,1,.444-.445h4.444a.447.447,0,0,1,.444.445V256.3a.447.447,0,0,0,.445.444h7.111a.447.447,0,0,0,.445-.444V246.133A1.029,1.029,0,0,0,195.983,245.416Z" transform="translate(-171.444 -232.746)" fill="#fff" />
                    </g>
                </g>
            </svg>),
        'work-book-icon': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 30.699 23">
                <g id="Layer_2" data-name="Layer 2" transform="translate(0.516 0.5)">
                    <g id="Layer_1" data-name="Layer 1">
                        <path id="Path_147" data-name="Path 147"
                            d="M112.271,37.3h-2.616L107.613,34a4.426,4.426,0,0,0-3.577-1.942H93.108A4.424,4.424,0,0,0,89.531,34L87.482,37.3H84.866c-1.2,0-1.594,1.9-.45,2.253l1.39.435L85.18,41c-.8,1.283-.981,3.491-.981,5.539v6.783a.744.744,0,0,0,.742.742h3.151a.744.744,0,0,0,.742-.742V51.506H108.3v1.807a.744.744,0,0,0,.741.742h3.151a.744.744,0,0,0,.741-.742V46.534c0-2.049-.18-4.251-.981-5.539l-.625-1.01,1.39-.435C113.86,39.193,113.471,37.3,112.271,37.3ZM87.982,39.128l2.729-4.393a3.057,3.057,0,0,1,2.4-1.285h10.928a3.052,3.052,0,0,1,2.4,1.285l2.73,4.393a.532.532,0,0,1-.455.857H88.434A.531.531,0,0,1,87.982,39.128Zm2.335,6.929H87.634a1.624,1.624,0,1,1,.548-3.152l2.516.9a1.161,1.161,0,0,1-.381,2.253Zm12.295,3.229a.466.466,0,0,1-.463.464H95a.464.464,0,0,1-.463-.464v-.834A.463.463,0,0,1,95,47.989h7.138a.464.464,0,0,1,.463.463Zm6.906-3.229H106.83a1.161,1.161,0,0,1-.394-2.253l2.519-.9a1.624,1.624,0,1,1,.548,3.152Z"
                            transform="translate(-83.734 -32.06)"
                            fill="#fff" />
                    </g>
                </g>
            </svg>),
        'search-icon': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 25 25">
                <path
                    id="Icon_awesome-search"
                    data-name="Icon awesome-search"
                    d="M.34,21.614l4.869-4.868a1.171,1.171,0,0,1,.83-.342h.8a10.153,10.153,0,1,1,1.758,1.758v.8a1.171,1.171,0,0,1-.342.83L3.382,24.656a1.167,1.167,0,0,1-1.655,0L.345,23.274a1.177,1.177,0,0,1,0-1.66Zm14.5-5.209a6.249,6.249,0,1,0-6.25-6.249A6.246,6.246,0,0,0,14.843,16.4Z"
                    transform="translate(0)"
                    fill="#fff" />
            </svg>),
        'setting-icon': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 31.299 32">
                <path id="Icon_material-settings" data-name="Icon material-settings" d="M29.145,19.47a11.69,11.69,0,0,0,.1-1.47,11.69,11.69,0,0,0-.1-1.47l3.165-2.475a.757.757,0,0,0,.18-.96l-3-5.19a.754.754,0,0,0-.915-.33l-3.735,1.5a10.959,10.959,0,0,0-2.535-1.47l-.57-3.975A.731.731,0,0,0,21,3H15a.731.731,0,0,0-.735.63L13.7,7.605a11.524,11.524,0,0,0-2.535,1.47l-3.735-1.5a.732.732,0,0,0-.915.33l-3,5.19a.74.74,0,0,0,.18.96L6.855,16.53A11.9,11.9,0,0,0,6.75,18a11.9,11.9,0,0,0,.105,1.47L3.69,21.945a.757.757,0,0,0-.18.96l3,5.19a.754.754,0,0,0,.915.33l3.735-1.5a10.959,10.959,0,0,0,2.535,1.47l.57,3.975A.731.731,0,0,0,15,33h6a.731.731,0,0,0,.735-.63l.57-3.975a11.524,11.524,0,0,0,2.535-1.47l3.735,1.5a.732.732,0,0,0,.915-.33l3-5.19a.757.757,0,0,0-.18-.96L29.145,19.47ZM18,23.25A5.25,5.25,0,1,1,23.25,18,5.256,5.256,0,0,1,18,23.25Z" transform="translate(-2.343 -2)" fill="none" stroke="#0065ca" strokeWidth="2" />
            </svg>)
    },
});

export const LeftMenu: React.FunctionComponent<IProps> = ({ handleNavButton }) => {
    const [settingsOpened, setSettingsOpen] = useState(true);
    const [inventoryOpen, setinventoryOpen] = useState(true);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const inventoryInfo = useSelector((state: AppState) => state.inventory.shopVehicleList);
   
    let userLinks = [];
    let urlKeys = [];
    if (user && user.roles && user.roles.includes('ROLE_ADMIN')) {
        userLinks = [{
            url: "/admin-accounts",
            name: "Accounts",
            key: "accounts",
            module: "Accounts",
            screen: "admin",
        }]
        urlKeys = ['accounts'];
    } else {
        if (isKarlProfile(user.profileType)) {
            urlKeys = karlProfileURLKeys;
            userLinks = [
                {
                    url: "/dashboard",
                    name: "Dashboard",
                    key: "dashboard",
                },
                {
                    url: "/appointments",
                    name: "Appointments",
                    key: "appointments",
                },
                {
                    url: "/appraisals",
                    name: "Trade-ins",
                    key: "appraisals",
                },
                {
                    url: "/write-ups",
                    name: "Write-ups",
                    key: "write-ups",
                },
                {
                    url: "/scheduling",
                    name: "Scheduling",
                    key: "scheduling",
                },
                {
                    url: "/tasks",
                    name: "Tasks",
                    key: "tasks",
                },
                {
                    name: "After Sales",
                    links: [
                        {
                            name: "After Sale Products",
                            url: "/service-contract",
                            key: "service-contract"
                        },
                        {
                            name: "Cancellations",
                            url: "/cancellations",
                            key: "cancellations"
                        }
                    ],
                    isExpanded: true,
                }
            ]
        } else if (isDefaultProfile(user.profileType)) {
            urlKeys = defaultProfileURLKeys;
            userLinks = [
                {
                    url: "/dashboard",
                    name: "Dashboard",
                    key: "dashboard",
                    icon: "home-icon",
                },
                //{
                //    url: "",
                //    name: "Search",
                //    key: "",
                //    icon: "search-icon",
                //},
                // {
                //    name: "Inventory",
                //    url: "/inventory",
                //    key: "inventory",
                //    icon: "work-book-icon",
                //    links: [
                        {
                            name: "Appraisal",
                            url: "/appraisal",
                            //icon: "ShoppingCart",
                            key: "appraisal",
                            module: "Inventory",
                            screen: "workbook_shop",
                        },
                        {
                            name: "Inventory",
                            url: "/acquired",
                            //icon: "CircleDollar",
                            key: "acquired",
                            module: "Inventory",
                            screen: "workbook_purchase",
                        },
                        {
                            name: "Sold",
                            url: "/sold",
                            //icon: "ShoppingCartSolid",
                            key: "sold",
                            module: "Inventory",
                            screen: "workbook_sold",
                        },
                        {
                            name: "",
                            url: "/inventory",
                            icon: "Search",
                            key: "inventory",
                            module: "Inventory",
                            className:"inv-src-left-lnk"
                            //screen: "workbook_sold",
                        }
                //    ],
                //    isExpanded: true
                //},

                /* {
                    url: "/inventory",
                    name: Inventory,
                    key: "inventory",
                    icon: "work-book-icon",
                    module: "Inventory",
                },
                {
                  name: "Inventory",
                  links: [
                    {
                      name: "Shop",
                      url: "/inventory",
                      icon: "ShoppingCart",
                      key: "inventory",
                      module: "Inventory",
                    },
                    {
                      name: "Acquired",
                      url: "/acquired",
                      icon: "CircleDollar",
                      key: "acquired",
                      module: "ShopInventory",
                    },
                    {
                      name: "Sold",
                      url: "/sold",
                      icon: "ShoppingCartSolid",
                      key: "sold",
                      module: "ShopInventory",
                    }
                  ],
                  isExpanded: inventoryOpen
                }, 
                {
                  url: "/prospects",
                  name: Prospects,
                  key: "prospects",
                  icon: "UserFollowed",
                  module: "Prospects",
                },
                {
                  url: "/desk-a-deal",
                  name: DeskADeal,
                  key: "desk",
                  icon: "Calculator",
                  module: "Deals",
                },
                {
                  url: "/marketing",
                  name: Marketing,
                  key: "marketingPage",
                  icon: "Chart",
                  module: "Prospect",
                },
                {
                  url: "/deals",
                  name: Deals,
                  key: "deals",
                  icon: "PageEdit",
                  module: "Deals",
                },*/
                /* {
                  name: GuidedSetup,
                  url: "/guided-setup",
                  icon: "Calculator",
                  key: "guided",
                  module: "GuidedSetup",
                }, */
                ,{
                    name: "Settings",
                    url: "/settings",
                    key: "settings",
                    icon: "setting-icon",
                    links: [
                        {
                            name: "Users",
                            url: "/users",
                            key: "users",
                            module: "Employee",
                            screen: "setting_user_view",
                        },
                        {
                            name: "Locations",
                            url: "/locations",
                            key: "locations",
                            module: "Location",
                            screen: "setting_location_view",
                        },
                        {
                            name: "Business Info",
                            url: "/business-info",
                            key: "business-info",
                            module: "DealerProfile",
                            screen: "setting_business_info_view",
                        },
                        /*{
                          name: "Brands",
                          url: "/brands",
                          key: "brands",
                          module: "Brands",
                        },
                        {
                          name: "Websites",
                          url: "/websites",
                          key: "websites",
                          module: "Websites",
                        },*/

                        /*{
                          name: "Permissions",
                          url: "/permissions",
                          key: "permissions",
                          // module: "Permission",
                          module: "Auth"
                        },
                        {
                          name: "Deal Settings",
                          url: "/deal-settings",
                          key: "deal-settings",
                          module: "DealSettings",
                        },
                        {
                          name: "Subscriptions",
                          url: "/subscriptions",
                          key: "subscriptions",
                          module: "Subscriptions",
                        },*/
                    ],
                    isExpanded: settingsOpened,
                }
            ]
        }

    }
    const navLinkGroups: any[] = [
        {
            links: userLinks,
        },
    ];
    const [active, setActive] = useState<string | undefined>();
    const navigate = useNavigate ();
    const location = useLocation();

    // const filteredLinksGroups = navLinkGroups[0].links.find((link: any) => link.key === '/settings');
    // const settingsIndex = navLinkGroups[0].links.indexOf(filteredLinksGroups);
    const [filteredNavLinks, setFilteredNavLinks] = useState([]);

    const checkPermissionsForMenuItem = (items: any[]) => {
        return items.filter((link: any) => {

            let l: any = null;
            if (link.name === 'Dashboard') l = link;

            if (user && user.roles && user.roles.includes('ROLE_ADMIN')) l = link;

            // const modulePermissions = getModulePermissions(user.auths, link.module);

            if (link.links) {
                const links = checkPermissionsForMenuItem(link.links);
                link.links = links;
                l = link;
            }
            if (
                /* isPermitted(
                  modulePermissions,
                  eTypes.READ,
                  link.module,
                  user.roles ? user.roles : []
                ) || */
                isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    link.screen,
                    user.roles ? user.roles : []
                ) ||
                (link.links && link.links.length > 0) || isKarlProfile(user.profileType))
                return (l = link);

            return l;
        });
    };

    useEffect(() => {
        const pathName = location.pathname.replace('/', '');
        if (urlKeys.includes(pathName)) {
            setActive(pathName);
        } else if (isKarlProfile(user.profileType)) {
            setActive('');
        }
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        console.log('location.pathname', location.pathname)
        console.log('inventoryInfo', inventoryInfo);
        if (location.pathname.indexOf('shop-inventory') > 0 && inventoryInfo) {
            if (inventoryInfo.stage === 'shop') {
                setActive('appraisal');
            } else if (inventoryInfo.stage === 'purchase') {
                setActive('acquired');
            } else if (inventoryInfo.stage === 'sold') {
                setActive('sold');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryInfo]);

    useEffect(() => {
        if (user && user.roles) {
            //@TODO: check if this would cause displaying menu if there's no user
            /** If user is master show all elements*/
            if (isUserMaster(user.roles)) {
                setFilteredNavLinks([...navLinkGroups]);
                return;
            }

            /** If user is not master -> filter all menu elements and return only permited*/
            let filtered = checkPermissionsForMenuItem(navLinkGroups[0].links);
            filtered = filtered.filter(x => !x.links || x.links.length > 0)
            const tempNavLinks = [...navLinkGroups];
            tempNavLinks[0].links = [...filtered];
            setFilteredNavLinks([...tempNavLinks]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, settingsOpened, inventoryOpen]);

    const _onLinkClick = (
        event?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        event && event.preventDefault();
        if (item && item.key) {
            navigate(item.url);
            setActive(item.url);
        }
        handleNavButton(false)
    };

    const onLinkExpanded = (
        event?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        if (item && item.name === "Inventory") {
            setinventoryOpen(!inventoryOpen)
        } else {
            setSettingsOpen(!settingsOpened)
        }
    };

    return (
        <Nav
            className="leftNav"
            onLinkClick={_onLinkClick}
            selectedKey={active}
            ariaLabel="Nav"
            styles={navStyles}
            groups={filteredNavLinks}
            onLinkExpandClick={onLinkExpanded}
        />
    );
};
