import React, { useEffect, useState } from "react";
import { DefaultPalette, Stack, IStackTokens } from "@fluentui/react";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { ReactComponent as LogoWhite } from "assets/logoWhite.svg";
import { CustomFontText } from "components/customFontText/CustomFontText";
//import AddButton from "components/buttons/add/AddButton";
import Hamburger from "components/buttons/hamburger/Hamburger";
import { Person } from "components/navigation/person/Person";

import { useSelector } from "react-redux";
import { AppState } from "store";
import { Only } from "components/layouts/only/Only";
import { Hidden } from "react-grid-system";
import { useLocation } from "react-router-dom";
//import { eTypes, WithCredentials } from "hoc/withCredentials";
//import {
//    getModulePermissions,
//    isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { permissionsModules } from "constants/constants";

const appBarStyles = mergeStyles({
    backgroundColor: DefaultPalette.themePrimary,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 35,
    paddingRight: 20,
});

const rightPartToken: IStackTokens = {
    childrenGap: 30,
};

const AppBar = (props) => {
    const location = useLocation ();

    let userToken = Boolean(useSelector((state: AppState) => state.auth.userToken))
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const [passwordSetupFlag, setPasswordSetupFlag] = useState(location.pathname.includes("/initial-setup"))
    //const guidedSetup = useSelector(
    //    (state: AppState) => state.primarySetup.guidedSetup
    //);
    //const [showAppBar, setShowAppBar] = useState(guidedSetup && guidedSetup.isCompleted);
    useEffect(() => {
        setPasswordSetupFlag(location.pathname.includes("/initial-setup"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    //useEffect(() => {
    //    setShowAppBar(guidedSetup && guidedSetup.isCompleted);
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [guidedSetup])

    return (
        <Only when={((Boolean(userToken) || passwordSetupFlag)&& location.pathname !== "/login")}>
            <section className={`${appBarStyles} appBar ${props.showHide  && "displayNone"}`}>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                >
                    <Hamburger onClick={props.handleNavButton} showLeftNav={props.showLeftNav} />
                    {passwordSetupFlag ?<>
                        <Stack
                            className="appBar-applicationName"
                            verticalAlign="center"
                            horizontalAlign="center"
                        >
                            <Hidden xs sm>
                                <CustomFontText color="white" size={14} bold>
                                            QoreDMS Guided Setup
                                </CustomFontText>
                            </Hidden>
                        </Stack>

                        </> : <><LogoWhite />
                            <Stack
                                className="appBar-applicationName"
                                verticalAlign="center"
                                horizontalAlign="center"
                            >
                                <Hidden xs sm>
                                    {user && user.roles && user.roles.includes('ROLE_ADMIN') ?
                                        <CustomFontText color="white" size={14} bold>
                                            QoreDMS Administration
                                        </CustomFontText> :
                                        user && user.dealerProfile && user.dealerProfile.legalName ?
                                            <CustomFontText color="white" size={14} style={{ textTransform: "uppercase" }} bold>
                                                 {user.dealerProfile.legalName}
                                            </CustomFontText> :
                                            user && user.roles && user.roles.includes('ROLE_MASTER') ?
                                                <CustomFontText color="white" size={14} bold>
                                                                    QoreDMS Guided Setup
                                                </CustomFontText>:<span></span>
                                    }

                                </Hidden>
                            </Stack>

                            <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalAlign="center"
                                tokens={rightPartToken}
                            >
                                {/*{showAppBar && user && user.roles && (
                            <Hidden xs sm md lg>
                                <WithCredentials
                                    groupAuths={
                                        isUserMaster(user.roles)
                                            ? []
                                            : getModulePermissions(
                                                user.auths,
                                                permissionsModules.Inventory
                                            )
                                    }
                                    alias={"Inventory"}
                                    type={eTypes.WRITE}
                                    roles={user.roles}
                                >
                                    <AddButton onClick={() => navigate("/inventory", "add")}>
                                        Add inventory
                                    </AddButton>
                                </WithCredentials>
                                <WithCredentials
                                    groupAuths={
                                        isUserMaster(user.roles)
                                            ? []
                                            : getModulePermissions(
                                                user.auths,
                                                permissionsModules.Deals
                                            )
                                    }
                                    alias={"Deals"}
                                    type={eTypes.WRITE}
                                    roles={user.roles}
                                >
                                    <AddButton onClick={() => navigate("/deals", "add")}>
                                        Add deal
                                    </AddButton>
                                </WithCredentials>
                            </Hidden>
                        )}*/}
                                {/*<Ringer*/}
                                {/*  number={1}*/}
                                {/*  light*/}
                                {/*  onClick={() => {*/}
                                {/*    console.log("test ringer click");*/}
                                {/*  }}*/}
                                {/*/>*/}
                                <Person />
                            </Stack>
                        </>
                    }
                </Stack>
            </section>
        </Only>
    );
};

export default AppBar;
