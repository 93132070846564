import React, {  FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    //DefaultButton,
    IconButton,
    //TextField,
    Stack
} from "@fluentui/react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-grid-system";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { handleSellVehicleUndo } from "store/inventory/inventory.action";
//import { useNavigate  } from "react-router-dom";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    vehicleId:any;
    soldDate:any
}


export const SoldVehicleInventoryDialog: FunctionComponent<IProps> = ({
    onDismiss,vehicleId,soldDate
}) => {
     

    const soldDates = new Date(soldDate).toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
    const dispatch = useDispatch();
    //const navigate = useNavigate ();
    const handleClosePopup = () => {
        onDismiss('');
    }

    const handleUndo =() => {
            
            dispatch<any>(handleSellVehicleUndo({id:vehicleId, callback: (res) => {
              if(res){

                   onDismiss('');
              }
            }}));
    }
    return (
        <Modal
            isOpen={true}
            onDismiss={handleClosePopup}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <span className="formTitle">Sold Vehicle</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleClosePopup}
                />
            </div>
            <div style={{ overflow: "hidden",width:"auto",height:"120px"}} className={`${contentStyles.body}`} >
                <Stack
                    styles={stackStyles}
                    className="sell-vehicle-dialog-width"
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <div >
                        <div style={{margin:"0px 50px"}}>
                        <Row className="purchase-row" justify="center">

                              <Col>
                                     <div> <label className="lbl-text">Sold Date</label> </div> <div> {soldDates} </div>
                              </Col>
                              <Col >
           
                                     <PrimaryButton
                                            className="soldDiaUndoBut"
                                            //style={{ float: "right" }}
                                            //disabled={!formik.dirty || !formik.isValid}
                                            text="Undo"
                                            onClick={handleUndo}
                                     />
                                    
                              </Col>

                        </Row>
                        </div>

                    </div>
                </Stack>
            </div>


        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
