import React, { useEffect, useState } from 'react';
import {
    // PrimaryButton,
    // ChoiceGroup,
    // IChoiceGroupOption,
    // Link
} from "@fluentui/react";
import moment from "moment";
/* import { useFormik } from "formik";
import * as Yup from "yup"; */
import { AddScheduleDialog } from "components/dialogs/dealTrackerDialog/AddScheduleDialog";
import { Calendar as SDCalendar, momentLocalizer, Views } from 'react-big-calendar'
import { SheduleCreateRequestDialog } from 'components/dialogs/dealTrackerDialog/SheduleCreateRequestDialog';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetAllFinanceDeliveryPerson, handleGetAllFinanceDeliverySchedule } from 'store/dealTracker/dealTracker.action';
import { useNavigate , useParams } from 'react-router-dom';
import { HandleEmployeeGetMonthCal } from 'store/employees/employees.action';
import { AppState } from "store";
import { isUserMaster } from 'utils/permissions/permissionsHelpers';
import { switchGlobalLoader } from 'store/globalLoading/globalLoading.action';
// import employeeGroup from "store/employees/employeeGroup.json";

interface ParamTypes {
    dealID: string;
}

interface IProps {
    title: any;
    dealId: any;
    locationId: any;
    selectedEv: any;
    selectedDate: any;
    onPageChange: (val) => void;
    role: any;
}

enum dayOfTheWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export const ScheduleManager: React.FunctionComponent<IProps> = ({
    title,
    dealId,
    locationId,
    selectedDate,
    selectedEv,
    onPageChange,
    role
}) => {
    const [isAddScheduleDialog, setisAddScheduleDialog] = useState(false);
    const [sheduleModalOpenDialog, setSheduleModalOpenDialog] = useState(false);
    const [scheduleDialogTitle, setscheduleDialogTitle] = useState(null);
    const [resourceFinance, setresourceFinance] = useState([]);
    const [rawResourceFinance, setrawResourceFinance] = useState([]);
    const [resourceDelivery, setresourceDelivery] = useState([]);
    const [rawResourceDelivery, setrawResourceDelivery] = useState([]);
    const [resourceDeliveryDropd, setresourceDeliveryDropd] = useState([]);
    const [selectionDetails, setselectionDetails] = useState(null);
    const [userRoles, setuserRoles] = useState(null);
    const [scheduleSlotEvents, setScheduleSlotEvents] = useState([]);
    const [financeAvailSlotEvents, setFinanceAvailSlotEvents] = useState([]);
    const [deliveryAvailSlotEvents, setDeliveryAvailSlotEvents] = useState([]);
    const [deliverySelectedDate, setdeliverySelectedDate] = useState(selectedDate ? new Date(selectedDate) : new Date());
    const [deliverySlotEvents, setdeliverySlotEvents] = useState([]);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
	const navigate = useNavigate ();
    const [initLoadIndex, setinitLoadIndex] = useState(0);

    const dispatch = useDispatch();
    const params = useParams<ParamTypes |any>();

    const localizer = momentLocalizer(moment);
    // const groupList: any = employeeGroup.groups;
    // const isEdit=true;

    useEffect(() => {
        // Finance & Delivery Person List
        // reset Values
        // setdeliverySelectedDate(new Date());
        setselectionDetails(null);
        setresourceFinance([]);
        setrawResourceFinance([]);
        setresourceDelivery([]);
        setrawResourceDelivery([]);
        setinitLoadIndex(0);
        dispatch<any>(handleGetAllFinanceDeliveryPerson({ locationId: locationId , callback: (res) =>  {
            setinitLoadIndex(1);
            if (res) {
                // finance persons
                let deliveryResource = [];
                let financeResource = [];
                if (res.finance && res.finance.length) {
                    res.finance.map((data) => {
                        let workExt = data.workPhoneExtension ? ` (${data.workPhoneExtension})` : '';
                        return financeResource.push({
                            resourceId: data.id,
                            resourceTitle: data.firstName + ' ' + data.lastName,
                            resourceTitleHead: data.firstName + ' ' + data.lastName + workExt,
                            selectable: 'asd'
                        });
                    });
                   // setresourceFinance(financeResource);
                    setrawResourceFinance(financeResource)
                }
                
                // Delivery Person
                if (res.delivery && res.delivery.length) {
                    let delDropVal = [];
                    // if (role === 'sales') {
                        deliveryResource = [
                            {
                                resourceId: -1,
                                resourceTitle: 'Req Del',
                                resourceTitleHead: 'Req Del',
                                selectable: 'asd'
                            },
                            {
                                resourceId: -2,
                                resourceTitle: 'Req Del',
                                resourceTitleHead: 'Req Del',
                                selectable: 'asd'
                            }
                        ];
                    // }

                    res.delivery.map((data) => {
                        let workExt = data.workPhoneExtension ? ` (${data.workPhoneExtension})` : '';
                        return deliveryResource.push({
                            resourceId: data.id,
                            resourceTitle: data.firstName + ' ' + data.lastName,
                            resourceTitleHead: data.firstName + ' ' + data.lastName + workExt,
                            selectable: 'asd'
                        });
                    });
                    delDropVal = JSON.parse(JSON.stringify(deliveryResource));
                    // if (role === 'sales') {
                        delDropVal.splice(0,2);
                    // }
                    setrawResourceDelivery(deliveryResource);
                    // setresourceDeliveryDropd(delDropVal);
                   // setresourceDelivery(deliveryResource);
                    
                }
                let resource = {
                    finance: financeResource,
                    delivery: deliveryResource
                }
                
                if(selectedEv && (selectedEv.financeCalendar || selectedEv.deliveryCalendar)) {
                    if (selectedEv.financeCalendar?.start) {
                        setdeliverySelectedDate(new Date(selectedEv.financeCalendar.start));
                        // Get Schedule List for Finance & delivery
                        getAllFinanDelSchedules(new Date(selectedEv.financeCalendar.start), resource);
                    } else if (selectedEv.deliveryCalendar?.start) {
                        setdeliverySelectedDate(new Date(selectedEv.deliveryCalendar.start));
                        // Get Schedule List for Finance & delivery
                        getAllFinanDelSchedules(new Date(selectedEv.deliveryCalendar.start), resource);
                    } else {
                        // Get Schedule List for Finance & delivery
                        getAllFinanDelSchedules(new Date(deliverySelectedDate), resource);
                    }
                    setselectionDetails({
                        ...selectedEv
                    })
                    setisAddScheduleDialog(true);
                } else {
                    // Get Schedule List for Finance & delivery
                    getAllFinanDelSchedules(new Date(deliverySelectedDate), resource);
                }
            }
        }}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId]);

    useEffect(() => {
        if (selectedDate && role === 'manager') {
            changeHeaderHght(true);
            if (initLoadIndex > 0) {
                getAllFinanDelSchedules(new Date(selectedDate), null);
            }
            setdeliverySelectedDate(new Date(selectedDate));
            // onPageChange(new Date(selectedDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    
    useEffect(() => {
        if (user) {
            console.group('user', user)
            const master = isUserMaster(user.roles);
            setuserRoles({
                master,
                roles: user.aggregatedDefaultPolicies
            })
        }
    }, [user]);

    useEffect(() => {
        changeHeaderHght();
    },[deliverySlotEvents]);

    const changeHeaderHght = (reset: any = false) => {
        // Matching the Height
        const calendarDelivery = document.getElementsByClassName('calendarDelivery')[0];
        const calendarFinance = document.getElementsByClassName('calendarFinance')[0];
        const delHeight: any = calendarDelivery.getElementsByClassName('rbc-time-header')[0].clientHeight;
        const delHeader: any = calendarDelivery.getElementsByClassName('rbc-time-header')[0];
        const finHeight: any = calendarFinance.getElementsByClassName('rbc-time-header')[0].clientHeight;
        const finHeader: any = calendarFinance.getElementsByClassName('rbc-time-header')[0];

        if (reset) {
            finHeader.style = null;
            delHeader.style = null;
        } else {
            if (finHeight > 0 || delHeight > 0) {
                if (delHeight > finHeight) {
                    finHeader.style.height = delHeight+"px";
                } else {
                    delHeader.style.height = finHeight+"px";
                }
            }

        }
    }

    // Get Schedule List for Finance & delivery
    const getAllFinanDelSchedules = (selectedRawDate, resource) => {
        let rawResource = null;
        if (resource) {
            rawResource = resource;
        }
        dispatch<any>(handleGetAllFinanceDeliverySchedule({ date: moment(selectedRawDate).format("YYYY-MM-DD") , callback: (res) =>  {
            let financeSchedules = [];
            let deliverySchedules = [];

            if (res.financeSchedule) {
                res.financeSchedule.forEach((element,index) => {
                    let date;
                    if (element.startsAt && element.startsAt.includes("T00:00:00")) {
                        let rawExpenseDate = element.startsAt;
                        date = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0,rawExpenseDate.indexOf("T")) : rawExpenseDate;
                        date = date ? date.slice(8,10) : '0';
                    } else {
                        date = new Date(element.startsAt).getDate();
                    }
                    let year = new Date(element.startsAt).getFullYear();
                    let month = new Date(element.startsAt).getMonth();
                    let startHrs = new Date(element.startsAt).getUTCHours();
                    let startMin = new Date(element.startsAt).getUTCMinutes();
                    let endHrs = new Date(element.endsAt).getUTCHours();
                    let endMin = new Date(element.endsAt).getUTCMinutes();
                    
                    let appendTitle = '';
                    if (element?.deal && element?.deal?.customer) {
                        let fullName = element?.deal?.customer.customerType === 2 ? element?.deal?.customer.businessName ? capitalizeFirstLetter(element?.deal?.customer.businessName) : '' : capitalizeFirstLetter(element?.deal?.customer.firstName) + ' ' +capitalizeFirstLetter(element?.deal.customer.lastName);
                        appendTitle = fullName;
                    }
                    if (element.status === 1) { // role === 'sales' && 
                        financeSchedules.push({
                            id: index,
                            title: element?.reason ? element?.reason : appendTitle,
                            start: new Date(year, month, date, startHrs, startMin, 0),
                            end: new Date(year, month, date, endHrs, endMin, 0),
                            resourceId: element?.assignedTo?.id,
                            scheduleId: element?.id,
                            class: element?.deal?.id ? element?.deal?.id === Number(dealId) ? "currentDeal initiate" : "blocked initiate" : "unavailable initiate",
                            dealId: element?.deal?.id ? element?.deal?.id : null,
                            reason: element?.reason,
                            status: element?.status,
                            selectable: true
                        })
                    } else {
                        financeSchedules.push({
                            id: index,
                            title: element?.reason ? element?.reason : appendTitle,
                            start: new Date(year, month, date, startHrs, startMin, 0),
                            end: new Date(year, month, date, endHrs, endMin, 0),
                            resourceId: element?.assignedTo?.id,
                            scheduleId: element?.id,
                            class: element?.deal?.id ? element?.deal?.id === Number(dealId) ? "currentDeal" : "blocked" : "unavailable",
                            dealId: element?.deal?.id ? element?.deal?.id : null,
                            reason: element?.reason,
                            status: element?.status,
                            selectable: true
                        })
                    }
                });
            }
            // Delivery
            if (res.deliverySchedule) {
                res.deliverySchedule.forEach((element,index) => {
                    let date;
                    if (element.startsAt && element.startsAt.includes("T00:00:00")) {
                        let rawExpenseDate = element.startsAt;
                        date = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0,rawExpenseDate.indexOf("T")) : rawExpenseDate;
                        date = date ? date.slice(8,10) : '0';
                    } else {
                        date = new Date(element.startsAt).getDate();
                    }
                    let year = new Date(element.startsAt).getFullYear();
                    let month = new Date(element.startsAt).getMonth();
                    let startHrs = new Date(element.startsAt).getUTCHours();
                    let startMin = new Date(element.startsAt).getUTCMinutes();
                    let endHrs = new Date(element.endsAt).getUTCHours();
                    let endMin = new Date(element.endsAt).getUTCMinutes();

                    let appendTitle = '';
                    if (element?.deal && element?.deal?.customer) {
                        let fullName = element?.deal?.customer.customerType === 2 ? element?.deal?.customer.businessName ? capitalizeFirstLetter(element?.deal?.customer.businessName) : '' : capitalizeFirstLetter(element?.deal?.customer.firstName) + ' ' + capitalizeFirstLetter(element?.deal.customer.lastName);
                        appendTitle = fullName;
                    }

                    if (element.type === 3) {
                        if (element?.deal?.location?.id === Number(locationId)) {
                            let selectedStartTime = new Date(year, month, date, startHrs, startMin, 0);
                            let selectedEndTime = new Date(year, month, date, endHrs, endMin, 0);
    
                            let resourceId = -1;
                            if (deliverySchedules.length) {
                                deliverySchedules.forEach((data: any) => {
                                    if (data?.resourceId === -1) {
                                        /* if ((selectedStartTime.getTime() >= data?.start?.getTime() && selectedStartTime.getTime() <= data?.end?.getTime()) ||
                                        (selectedEndTime.getTime() >= data?.start?.getTime() && selectedEndTime.getTime() <= data?.end?.getTime())) { */
                                        if (data?.start?.getTime() < selectedEndTime.getTime() && data?.end?.getTime() > selectedStartTime.getTime()) {
                                            resourceId = -2;
                                        }
                                    }
                                })
                            }
                            deliverySchedules.push({
                                id: index,
                                title: element?.reason ? element?.reason : appendTitle,
                                start: selectedStartTime,
                                end: selectedEndTime,
                                resourceId: resourceId,
                                scheduleId: element?.id,
                                class: element?.deal?.id ? element?.deal?.id === Number(dealId) ? "currentDeal initiate" : "blocked initiate" : "unavailable initiate",
                                dealId: element?.deal?.id ? element?.deal?.id : null,
                                reason: element?.reason,
                                status: element?.status
                            })
                        }
                    } else {
                        deliverySchedules.push({
                            id: index,
                            title: element?.reason ? element?.reason : appendTitle,
                            start: new Date(year, month, date, startHrs, startMin, 0),
                            end: new Date(year, month, date, endHrs, endMin, 0),
                            resourceId: element?.assignedTo?.id,
                            scheduleId: element?.id,
                            class: element?.deal?.id ? element?.deal?.id === Number(dealId) ? "currentDeal" : "blocked" : "unavailable",
                            dealId: element?.deal?.id ? element?.deal?.id : null,
                            reason: element?.reason,
                            status: element?.status
                        })
                    }
                });
            }
        
            let selectedDate = new Date(selectedRawDate);
            let finMonthlyData = {
                locationId : Number(locationId),
                month : Number(selectedDate.getMonth()+1),
                year : Number(selectedDate.getFullYear()),
                role : 'financePerson',
                role2: 'deliveryCoordinator'
            };
            let delMonthlyData = {
                locationId : Number(locationId),
                month : Number(selectedDate.getMonth()+1),
                year : Number(selectedDate.getFullYear()),
                role : 'deliveryCoordinator'
            };

            // get Monthly Calendar events
            dispatch<any>(HandleEmployeeGetMonthCal({ data: finMonthlyData , callback: (res) =>  {

                if (res.finance) {
                    if (res.finance && res.finance.length) {
                        financeSchedules = mapMOnthlyCalData(res.finance, finMonthlyData, financeSchedules, 'finance', selectedRawDate, rawResource);
                    } else {
                        dispatch<any>(switchGlobalLoader(false));
                    }
                    setScheduleSlotEvents(financeSchedules);
                } else {
                    dispatch<any>(switchGlobalLoader(false));
                }
                if (res.delivery) {
                    if (res.delivery && res.delivery.length) {
                        deliverySchedules = mapMOnthlyCalData(res.delivery, delMonthlyData, deliverySchedules, 'delivery', selectedRawDate, rawResource);
                    } else {
                        dispatch<any>(switchGlobalLoader(false));
                    }
                    /* if (role === 'sales') {
                        let year = new Date(selectedRawDate).getFullYear();
                        let month = new Date(selectedRawDate).getMonth();
                        let seldate = new Date(selectedRawDate).getDate();

                        deliverySchedules.push(
                            {
                                id: deliverySchedules.length + 1,
                                title: '',
                                start: new Date(year, month, seldate, 8, 0, 0),
                                end: new Date(year, month, seldate, 21, 0, 0),
                                resourceId: 1,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false
                            },{
                                id: deliverySchedules.length + 2,
                                title: '',
                                start: new Date(year, month, seldate, 8, 0, 0),
                                end: new Date(year, month, seldate, 21, 0, 0),
                                resourceId: 2,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false
                            }
                        );
                    } */
                    setdeliverySlotEvents(deliverySchedules);
                } else {
                    dispatch<any>(switchGlobalLoader(false));
                }
            }}))
        }}));
    }

    const mapMOnthlyCalData = (res, monthlyData, scheduleData, type, selectedDate, resource = null) => {
        let availableEveList = [];
        let resouceIdsList = [];
        // financeAvailSlotEvents
        dispatch<any>(switchGlobalLoader(true));
        res.map((empl) => {
            let emplEve = Object.keys(empl.employeeEvents).map(key => {
                return empl.employeeEvents[key];
            })

            let emplRevEve = Object.keys(empl.employeeRecurringPatterns).map(key => {
                return empl.employeeRecurringPatterns[key];
            })

            let index = 123;
            let availResObj = {
                resourceId: empl.id,
                events: []
            }
            emplRevEve.map((eve) => { // dayOfTheWeek
                // let selectedDate: any = monthlyData.year + '-' + monthlyData.month + '-' + eve.dayOfTheWeek;
                let dayWeek: any = moment(new Date(selectedDate)).startOf('month').day(dayOfTheWeek[eve.dayOfTheWeek]);

                if (dayWeek.date() > 7) dayWeek.add(7,'d');
                let month = dayWeek.month();
                index++;
                while(month === dayWeek.month()){
                    let year = new Date(dayWeek).getFullYear();
                    //let month = new Date(dayWeek).getMonth();
                    let date = new Date(dayWeek).getDate();
                    let timeArryStart = eve.startsAt.split(":");
                    let timeArryEnd = eve.endsAt.split(":");
                    let startHrs = timeArryStart[0];
                    let startMin = timeArryStart[1];
                    let endHrs = timeArryEnd[0];
                    let endMin = timeArryEnd[1];

                    let actualFirstStartNonHrs = 8;
                    let actualFirstEndNonHrs = startHrs;
                    let actualSecStartNonHrs = endHrs;
                    let actualSecEndNonHrs = 21;
                    
                    if (Number(startHrs) > 8 || (Number(startHrs) === 8 && Number(startMin) > 0)) {
                        actualFirstStartNonHrs = 8;
                        actualFirstEndNonHrs = startHrs;
                        if (Number(endHrs) < 21) {
                            actualSecStartNonHrs = endHrs;
                            actualSecEndNonHrs = 21;
                        }
                    }

                   
                    if (eve?.location?.id === Number(locationId)) {
                        if (new Date(selectedDate).getDate() === date) {
                            resouceIdsList.push(empl.id)
                        }
                        if (Number(startHrs) > 8 || (Number(startHrs) === 8 && Number(startMin) > 0)) {
                            scheduleData.push({
                                id: index,
                                title: '',
                                start: new Date(year, month, date, Number(actualFirstStartNonHrs), 0, 0),
                                end: new Date(year, month, date, Number(actualFirstEndNonHrs), startMin, 0),
                                resourceId: empl.id,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false,
                                nonSelectable: true
                            })
                            index++;
                        }

                        if (Number(endHrs) < 21) {
                            scheduleData.push({
                                id: index,
                                title: '',
                                start: new Date(year, month, date, Number(actualSecStartNonHrs), endMin, 0),
                                end: new Date(year, month, date, Number(actualSecEndNonHrs), 0, 0),
                                resourceId: empl.id,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false,
                                nonSelectable: true
                            })
                        }
                    }

                    let availEveObj = {
                        startsAt: new Date(year, month, date, startHrs, startMin, 0).getTime(),
                        endsAt: new Date(year, month, date, endHrs, endMin, 0).getTime(),
                        date: date
                    }
                    
                    if (eve?.location?.id === Number(locationId)) {
                        availResObj.events.push(availEveObj);
                    }
                    dayWeek.add(7,'d');
                    index++;
                }
                return scheduleData;
            });

            emplEve.map((eve) => {
                if (eve.type === 3) {
                    let month = moment(new Date(eve.startsAt)).month();
                    let year = new Date(eve.startsAt).getFullYear();
                    //let month = new Date(eve.startsAt).getMonth();
                    let date = new Date(eve.startsAt).getDate();
                    let startHrs = new Date(eve.startsAt).getUTCHours();
                    let startMin = new Date(eve.startsAt).getUTCMinutes();
                    let endHrs = new Date(eve.endsAt).getUTCHours();
                    let endMin = new Date(eve.endsAt).getUTCMinutes();
                    let actualFirstStartNonHrs;
                    let actualFirstEndNonHrs;
                    let actualSecStartNonHrs;
                    let actualSecEndNonHrs;
                    if (startHrs >= 8) { // startHrs !== 8
                        actualFirstStartNonHrs = 8;
                        actualFirstEndNonHrs = startHrs;
                        if (endHrs <= 21) { // endHrs !== 21 
                            actualSecStartNonHrs = endHrs;
                            actualSecEndNonHrs = 21;
                        }
                    }
                    //if (eve?.location?.id === Number(locationId)) {
                        if (new Date(selectedDate).getDate() === date && (new Date(selectedDate).getMonth() === month)) {
                            resouceIdsList.push(empl.id)
                        }
                        if (Number(startHrs) > 8 || (Number(startHrs) === 8 && Number(startMin) > 0)) {
                            // resouceIdsList.push(empl.id)
                            scheduleData.push({
                                id: index,
                                title: '',
                                start: new Date(year, month, date, Number(actualFirstStartNonHrs), 0, 0),
                                end: new Date(year, month, date, Number(actualFirstEndNonHrs), startMin, 0),
                                resourceId: empl.id,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false,
                                nonSelectable: true
                            })
                            index++;
                        }

                        if (Number(endHrs) < 21) {
                            scheduleData.push({
                                id: index,
                                title: '',
                                start: new Date(year, month, date, Number(actualSecStartNonHrs), endMin, 0),
                                end: new Date(year, month, date, Number(actualSecEndNonHrs), 0, 0),
                                resourceId: empl.id,
                                scheduleId: null,
                                class: "availableTime",
                                dealId: null,
                                reason: '',
                                status: null,
                                selectable: false,
                                nonSelectable: true
                            })
                        }
                    //}

                    let availEveObj = {
                        startsAt: new Date(year, month, date, startHrs, startMin, 0).getTime(),
                        endsAt: new Date(year, month, date, endHrs, endMin, 0).getTime(),
                        date: date
                    }
                    //if (eve?.location?.id === Number(locationId)) {
                        availResObj.events.push(availEveObj);
                    //}
                    index++;
                } else {
                    // let date = new Date(eve.date).getDate();

                    let subst = '';
                    let substMon = '';
                    let substRaw = '';
                    if (eve.date) {
                        let rawExpenseDate = eve.date;
                        substRaw = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0,rawExpenseDate.indexOf("T")) : rawExpenseDate;
                        subst = substRaw ? substRaw.slice(8,10) : '0';
                        substMon = substRaw ? substRaw.slice(5,7) : '0';
                    }

                    if (new Date(selectedDate).getDate() === Number(subst) &&
                    ((new Date(selectedDate).getMonth() +1) === Number(substMon))) {
                        resouceIdsList = resouceIdsList.filter(data => {
                            if (data === empl.id) {
                                return false;
                            }
                            return true
                        });
                    }
                }
                return scheduleData;
            })

            availableEveList.push(availResObj);
            return true;
        });
        if (type === 'finance') {
            let rawresourceFinanceList = JSON.parse(JSON.stringify(resource ? resource.finance : rawResourceFinance));
            
            const filteredVal = rawresourceFinanceList.filter(data => {
                if (resouceIdsList.includes(data.resourceId)) {
                    return true;
                }
                return false
            })
            setresourceFinance(filteredVal);
            setFinanceAvailSlotEvents(availableEveList);
            
        } else {
            let rawresourceDeliveryList = JSON.parse(JSON.stringify(resource ? resource.delivery : rawResourceDelivery));
            let filteredVal = rawresourceDeliveryList.filter(data => {
                if (resouceIdsList.includes(data.resourceId)) {
                    return true;
                }
                return false
            })
            filteredVal.unshift(rawresourceDeliveryList[1])
            filteredVal.unshift(rawresourceDeliveryList[0]);
            setresourceDelivery(filteredVal);
            let delDropVal = JSON.parse(JSON.stringify(filteredVal));
            delDropVal.splice(0,2);
            setresourceDeliveryDropd(delDropVal);
            setDeliveryAvailSlotEvents(availableEveList);
        }
        dispatch<any>(switchGlobalLoader(false));

        return scheduleData;
    }

    const capitalizeFirstLetter = (str) => {
        // converting first letter to uppercase
        const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
        return capitalized;
    }

    const handleScheduleDialogDismiss = (val) => {
        setisAddScheduleDialog(false);
        if (val === 'reloadCalendar') {
            getAllFinanDelSchedules(new Date(deliverySelectedDate), null);
        }
    };

    const handleSelectFinanceSlot = ({ start, end, resourceId, status, type }:any) => {
        const filData = scheduleSlotEvents.filter(item => item.dealId !== null && item.scheduleId !== null && item.resourceId === resourceId);
        // eslint-disable-next-line array-callback-return
        const filteredResult = filData.filter((item:any) => {
            const itemStart = item.start.getTime();
            const itemEnd = item.end.getTime();
            const filtStart = start.getTime();
            const filtEnd = end.getTime();
            if(filtStart >= itemStart && itemEnd >= filtEnd){
            return item;
            }
        });
        
       if(filteredResult.length && filData.length){
        handleSelectedfinanceSlot(filteredResult[0]);
       }else{
        handleSelectDragFinanceSlot({ start, end, resourceId, status, type })
       }

    }

    // Scheduling Fiannce Functions
    const handleSelectDragFinanceSlot = ({ start, end, resourceId, status, type }) => {   // { start, end, resourceId, status, selectable } 
        // resourceId, events --> startsAt, endsAt
        let financeAvailSelection = JSON.parse(JSON.stringify(financeAvailSlotEvents));
        let startTime = new Date(start).getTime();
        let availableTime = false;
        
        let grpRoles: any = true;
        if (user && user.employeeDefaultGroups && user.employeeDefaultGroups.length) {
            const rawList = user.employeeDefaultGroups.filter((item, index) => { return (item.defaultGroup.id === 16); });
            if (rawList && rawList.length > 0) {
                grpRoles = true;
            } else {
                grpRoles = false;
            }
        }

        financeAvailSelection.forEach((empitem) => {
            if(empitem.resourceId === resourceId) {
                empitem.events.forEach((item) => {
                    if (startTime >= item.startsAt && startTime < item.endsAt && (item.date === new Date(start).getDate())) {
                        availableTime = true;
                    }
                });
            }
        });

        if (availableTime && (userRoles.master || (userRoles?.roles?.includes("deal_schedule_initiate") 
        || userRoles?.roles?.includes("deal_schedule_finance_update") || grpRoles))) {
            let financeCalendar = {
                start,
                end,
                status,
                resourceId,
                salesPerson: (userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false
            }
            setscheduleDialogTitle('finance');
            setselectionDetails({
                start,
                end,
                resourceId,
                status,
                dealId,
                isEditable : false,
                financeCalendar,
                deliveryCalendar: null,
                financeReq: true,
                financeOnly: true,
                deliveryReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                type,
                salesPerson: (userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                
            })
            if (role === 'manager') {
                setSheduleModalOpenDialog(true);
            } else {
                setisAddScheduleDialog(true);
            }
        }
    }

    // Scheduling Delivery Functions
    const handleSelectDeliverySlot = ({ start, end, resourceId, status, type }:any) => {
        const filData = deliverySlotEvents.filter(item => item.dealId !== null && item.scheduleId !== null && item.resourceId === resourceId);
        // eslint-disable-next-line array-callback-return
        const filteredResult = filData.filter(item => {
            const itemStart = item.start.getTime();
            const itemEnd = item.end.getTime();
            const filtStart = start.getTime();
            const filtEnd = end.getTime();
            if(filtStart >= itemStart && itemEnd >= filtEnd){
            return item;
            }
        });

       if(filteredResult.length && filData.length){
           handleSelectedDelvSlot(filteredResult[0]);
       }else{
         handleSelectDragDeliverySlot({ start, end, resourceId, status, type })
       }
    }

    const handleSelectDragDeliverySlot = ({ start, end, resourceId, status, type }) => {
        let grpRoles: any = true;
        if (user && user.employeeDefaultGroups && user.employeeDefaultGroups.length) {
            const rawList = user.employeeDefaultGroups.filter((item, index) => { return (item.defaultGroup.id === 7 || item.defaultGroup.id === 8 || item.defaultGroup.id === 16 || item.defaultGroup.id === 19); });
            if (rawList && rawList.length > 0) {
                grpRoles = true;
            } else {
                grpRoles = false;
            }
        }
        
        if ((resourceId !== -1 && resourceId !== -2 && (userRoles?.master || !(userRoles?.roles?.includes("deal_schedule_time_disabled")) || userRoles?.roles?.includes("deal_schedule_dc_update"))) 
        || (userRoles?.roles?.includes("deal_schedule_time_disabled") && (resourceId === -1 || resourceId === -2))
        || (grpRoles && (resourceId === -1 || resourceId === -2))) {
            let deliveryAvailSelection = JSON.parse(JSON.stringify(deliveryAvailSlotEvents));
            let startTime = new Date(start).getTime();
            let availableTime = false;

            if (resourceId !== -1 && resourceId !== -2) {
                deliveryAvailSelection.forEach((empitem) => {
                    if(empitem.resourceId === resourceId) {
                        empitem.events.forEach((item) => {
                            if (startTime >= item.startsAt && startTime < item.endsAt && (item.date === new Date(start).getDate())) {
                                availableTime = true;
                            }
                        });
                    }
                });
            } else {
                availableTime = true;
            }

            if (availableTime && ((userRoles.master || (userRoles?.roles?.includes("deal_schedule_initiate") || userRoles?.roles?.includes("deal_schedule_dc_update")))
            || (userRoles?.roles?.includes("deal_schedule_time_disabled") && (resourceId === -1 || resourceId === -2))
            || (grpRoles && (resourceId === -1 || resourceId === -2)))) {
                //employeeGroup
                let deliveryCalendar = {
                    start,
                    end,
                    resourceId,
                    status,
                    salesPerson: (userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                    noFinance: true
                }
                setscheduleDialogTitle('delviery');
                if (role !== 'manager') {
                    start = null;
                }
                setselectionDetails({
                    start,
                    end,
                    resourceId,
                    dealId,
                    status,
                    isEditable: false,
                    financeCalendar: null,
                    deliveryCalendar,
                    deliveryReq: true,
                    financeReq: (userRoles.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                    type,
                    salesPerson: (userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                    noFinance: true
                })
                if (role === 'manager') {
                    setSheduleModalOpenDialog(true);
                } else {
                    setisAddScheduleDialog(true);
                }
            }
        }
    }

    const handleNavigatePage = (data) => {
        // reset height
        changeHeaderHght(true);
        //setTimeout(() => {
        getAllFinanDelSchedules(new Date(data), null);
        setdeliverySelectedDate(new Date(data));
        onPageChange(new Date(data));
        //}, 100)
    }

    const handleDelNavigatePage = (data) => {
        console.log('date', data)
    }

    const handleSelectedfinanceSlot = (ev) => {
        if (!ev.nonSelectable) {
            if (ev.dealId && role === 'manager') {
                const state= {
                    pathfrom: window.location.pathname,
                    tabIndex: 4,
                    openSchedDialog: true,
                    financeCalData: ev,
                    deliveryCalData: null,
                    isEditable: true,
                    scheduleId: ev.scheduleId,
                    type: ev.type,
                    financeReq: true,
                    deliveryReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false
                }
                navigate(`/${ev.dealId}/dealtracker`,{state});

            } else if (!ev.dealId && role === 'manager') {
                let financeCalendar = {
                    start: ev.start,
                    end: ev.end,
                    status: ev.status,
                    resourceId: ev.resourceId,
                    dealId: null,
                    scheduleId: ev.scheduleId,
                }
                setscheduleDialogTitle('delviery');
                setselectionDetails({
                    start: ev.start,
                    end: ev.end,
                    status: ev.status,
                    resourceId: ev.resourceId,
                    dealId: null,
                    scheduleId: ev.scheduleId,
                    isEditable: false,
                    isManager: true,
                    financeOnly: true,
                    reason: ev.reason,
                    financeCalendar,
                    deliveryCalendar: null,
                    type: ev.type,
                    financeReq: true,
                    deliveryReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false
                })
                setisAddScheduleDialog(true);
                // EDIT FLOW
                // isEditable : false
            } else if (ev.dealId && role === 'sales') {
                let disabledState = (ev?.dealId && Number(ev.dealId) !==  Number(params.dealID)) ? true : false;
                if (!disabledState) {
                    let financeCalendar = {
                        start: ev.start,
                        end: ev.end,
                        status: ev.status,
                        resourceId: ev.resourceId,
                        dealId: ev.dealId,
                        scheduleId: ev.scheduleId
                    }
                    setscheduleDialogTitle('delviery');
                    setselectionDetails({
                        start: ev.start,
                        end: ev.end,
                        status: ev.status,
                        resourceId: ev.resourceId,
                        dealId: ev.dealId,
                        isEditable: true,
                        financeCalendar,
                        deliveryCalendar: null,
                        scheduleId: ev.scheduleId,
                        type: ev.type,
                        financeReq: true,
                        deliveryReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false
                    })
                    setisAddScheduleDialog(true);
                }
                // EDIT FLOW
                // isEditable : false
            }
        }
        
        // setisAddScheduleDialog(true);
    }
    
    const handleSelectedDelvSlot = (ev) => {
        
        if (!ev.nonSelectable) {
            if (ev.dealId && role === 'manager') {
                const  state = {
                    pathfrom: window.location.pathname,
                    tabIndex: 4,
                    openSchedDialog: true,
                    financeCalData: null,
                    deliveryCalData: ev,
                    isEditable: true,
                    scheduleId: ev.scheduleId,
                    type: ev.type,
                    financeReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                    deliveryReq: true
                }
                navigate(`/${ev.dealId}/dealtracker`,{state});
            } else if (!ev.dealId && role === 'manager') {
                let deliveryCalendar = {
                    start: ev.start,
                    end: ev.end,
                    status: ev.status,
                    resourceId: ev.resourceId,
                    dealId: null,
                    scheduleId: ev.scheduleId,
                }
                setscheduleDialogTitle('delivery');
                setselectionDetails({
                    start: ev.start,
                    end: ev.end,
                    status: ev.status,
                    resourceId: ev.resourceId,
                    dealId: null,
                    scheduleId: ev.scheduleId,
                    isEditable: false,
                    isManager: true,
                    deliveryOnly: true,
                    reason: ev.reason,
                    financeCalendar: null,
                    deliveryCalendar,
                    type: ev.type,
                    financeReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                    deliveryReq: true
                })
                setisAddScheduleDialog(true);
                // EDIT FLOW
                // isEditable : false
            } else if (ev.dealId && role === 'sales') {
                let disabledState = (ev?.dealId && Number(ev.dealId) !==  Number(params.dealID)) ? true : false;
                if (!disabledState) {
                    let deliveryCalendar = {
                        start: ev.start,
                        end: ev.end,
                        status: ev.status,
                        resourceId: ev.resourceId,
                        dealId: ev.dealId,
                        scheduleId: ev.scheduleId
                    }
                    setscheduleDialogTitle('delviery');
                    setselectionDetails({
                        start: ev.start,
                        end: ev.end,
                        resourceId: ev.resourceId,
                        status: ev.status,
                        dealId: ev.dealId,
                        isEditable: true,
                        financeCalendar: null,
                        deliveryCalendar,
                        scheduleId: ev.scheduleId,
                        type: ev.type,
                        financeReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : false,
                        deliveryReq: true
                    })
                    setisAddScheduleDialog(true);
                }
            }
        }
        // setisAddScheduleDialog(true);
    }

    const eventColors = (event) => {
        var backgroundColor = "event-";
        event.class
          ? (backgroundColor = backgroundColor + event.class)
          : (backgroundColor = backgroundColor + "default");
        return {
          className: backgroundColor,
          display: 'background',
          backgroundColor: '#123123'
        };
    };

    const handleScheduleModalDismiss = (deal) => {
        setSheduleModalOpenDialog(false);
        if (deal === 'reloadCalendar') {
            // setisAddScheduleDialog(true);
            getAllFinanDelSchedules(new Date(deliverySelectedDate), null);
        } else if (deal.dealId) {
            let noFinance;
            if (selectionDetails.financeCalendar) {
                selectionDetails.financeCalendar.dealId = deal.dealId;
            } else {
                noFinance = true;
            }
            if (selectionDetails.deliveryCalendar) {
                selectionDetails.deliveryCalendar.dealId = deal.dealId;
            }
            const  state = {
                pathfrom: window.location.pathname,
                tabIndex: 4,
                openSchedDialog: true,
                financeCalData: selectionDetails.financeCalendar,
                deliveryCalData: selectionDetails.deliveryCalendar,
                isEditable: false,
                noFinance,
                scheduleId: selectionDetails.scheduleId,
                financeReq: (userRoles?.master || userRoles?.roles.includes("deal_schedule_time_disabled")) ? true : selectionDetails.financeCalendar ? true : false,
                deliveryReq: (userRoles?.master || userRoles?.roles?.includes("deal_schedule_time_disabled")) ? true : selectionDetails.deliveryCalendar ? true : false
            }
            navigate(`/${deal.dealId}/dealtracker`,{state});
        }
    };
    
    
    return (<>
        {isAddScheduleDialog ? 
        <AddScheduleDialog
            isOpen={isAddScheduleDialog}
            title={title}
            isEditFlow={false}
            role={role}
            financeDrp={resourceFinance}
            deliveryDrp={resourceDeliveryDropd}
            selectionDetails={selectionDetails}
            onDismiss={(val) => handleScheduleDialogDismiss(val)}
        />
        : null }
        {
            sheduleModalOpenDialog && (<SheduleCreateRequestDialog
                locationId={locationId}
                selectedScheduleData = {selectionDetails}
                calendarViewName = {scheduleDialogTitle}
                isOpen={sheduleModalOpenDialog}
                onDismiss={(deal) => handleScheduleModalDismiss(deal)}
            />)
        }
        {/* <div className="main-section calendarContainer workBook"> */}
            <div className='calendarFinance'>                                                                          
                <SDCalendar
                    selectable
                    events={scheduleSlotEvents}
                    localizer={localizer}
                    defaultView={Views.DAY}
                    views={['day', '']} // , 'work_week'
                    min={new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate(), 8)}
                    max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 21)}
                    step={15}
                    defaultDate={deliverySelectedDate}
                    date={deliverySelectedDate}
                    resources={resourceFinance}
                    resourceIdAccessor="resourceId"
                    resourceTitleAccessor="resourceTitleHead"
                    onSelectEvent={event => handleSelectedfinanceSlot(event)}
                    onSelectSlot={handleSelectFinanceSlot}
                    eventPropGetter={eventColors}
                    onNavigate={handleNavigatePage}
                />
            </div>  
            <div className='calendarDelivery'>
                <SDCalendar
                    selectable
                    events={deliverySlotEvents}
                    localizer={localizer}
                    defaultView={Views.DAY}
                    views={['day']}    // , 'work_week'
                    min={new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate(), 8)}
                    max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 21)}
                    step={15}
                    defaultDate={deliverySelectedDate}
                    date={deliverySelectedDate}
                    resources={resourceDelivery}
                    resourceIdAccessor="resourceId"
                    resourceTitleAccessor="resourceTitleHead"
                    onSelectEvent={event => handleSelectedDelvSlot(event)}
                    onSelectSlot={handleSelectDeliverySlot}
                    eventPropGetter={eventColors}
                    onNavigate={handleDelNavigatePage}
                />
            </div>
        {/* </div> */}
    </>)

}