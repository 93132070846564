export const fuelTypeValues = [
  "Diesel",
  "Electric",
  "Gasoline",
  "Hybrid",
  "Other",
];

export const engineCylindersValues = [
  { name: "1 Cyl", value: 1 },
  { name: "2 Cyl", value: 2 },
  { name: "3 Cyl", value: 3 },
  { name: "4 Cyl", value: 4 },
  { name: "6 Cyl", value: 6 },
  { name: "8 Cyl", value: 8 },
  { name: "10 Cyl", value: 10 },
  { name: "12 Cyl", value: 12 },
];

export const transmissionValues = ["Automatic", "Manual", "Undefined"];

export const bodyStyleValues = [
  "4 Door",
  "Convertible",
  "Convertible 2 Door",
  "Coupe",
  "Coupe 2 Door",
  "Cruiser",
  "Enduro",
  "Hatchback 2 Door",
  "Hatchback 2 Dr.",
  "Hatchback 4 Door",
  "Hatchback 4 Dr.",
  "Racer",
  "Roadster",
  "Scooter",
  "Sedan",
  "Sedan 2 Door",
  "Sedan 4 Door",
  "Sedan 4 Dr.",
  "Sport",
  "Sport Utility Vehicle",
  "Sport Utility Vehicle 4 Door",
  "Station Wagon",
  "Suv 4 Door",
  "Touring",
  "Truck",
  "Traditional",
  "Utility",
  "Wagon",
  "Wagon 4 Door",
  "Wagon 4 Dr.",
];

export const exteriorColorValues = [
  "Beige",
  "Black",
  "Blue",
  "Brown",
  "Burgundy",
  "Champagne",
  "Cream",
  "Dark Blue",
  "Dark Brown",
  "Gold",
  "Gray",
  "Green",
  "Light Blue",
  "Maroon",
  "Navy",
  "Orange",
  "Pewter",
  "Purple",
  "Red",
  "Silver",
  "Tan",
  "Teal",
  "Turquoise",
  "White",
  "Yellow",
];

export const interiorColorValues = [
  "Beige",
  "Black",
  "Blue",
  "Brown",
  "Burgundy",
  "Champagne",
  "Cream",
  "Dark Brown",
  "Gray",
  "Maroon",
  "Orange",
  "Red",
  "Silver",
  "Tan",
  "White",
];

export const doorValues = ["2", "3", "4", "5"];

export const driveTypeValues = ["AWD", "FWD", "RWD"];

const _getYearsUntilNow = () => {
  const years = [];
  const endYear = new Date().getFullYear();

  let startYear = 1960;

  while (startYear <= endYear) {
    years.push(startYear);
    startYear++;
    }
    years.push(startYear);
  return years;
};

export const yearValues = [..._getYearsUntilNow().reverse()];

const _getYearsUntilNextYear = () => {
  const years = [];
  const endYear = new Date().getFullYear()+1;

  let startYear = 1960;

  while (startYear <= endYear) {
    years.push(startYear);
    startYear++;
    }
    years.push(startYear);
  return years;
};

export const yearNewValues = [..._getYearsUntilNextYear().reverse()];

export const conditionValues = ["New", "Used", "Certified"];

export const historyValues = ["No Accidents", "Single Owner"];

const audioOptions = [
  "AM/FM",
  "Anti-Theft",
  "Bluetooth",
  "C.B",
  "CD Changer",
  "CD Player",
  "DVD Player",
  "Digital Instruments",
  "FUSE HandsFree Link",
  "HD Radio",
  "MP3",
  "Navigation System",
  "OnStar",
  "OnBoard Hard Drive",
  "Premium Sound",
  "Reverse Camera",
  "Satellite Radio",
  "Separate Tweeters",
  "Subwoofer",
  "Surround Sound SYNC",
  "Touch Screen",
  "USB Port",
  "Video System",
  "Voice Activated",
  "Voice Control",
];

const powerEquipmentOptions = [
  "115V Power Outlet",
  "12V Power Outlet",
  "Dual Power Seats",
  "Dual Power Sliding Doors",
  "Electronic Trunk Closer",
  "Power Adjustable Pedals",
  "Power Antenna",
  "Power Driver's Seat",
  "Power Lift Gate",
  "Power Locks",
  "Power Mirrors",
  "Power Passenger Seat",
  "Power Rear Sunshade",
  "Power Seat",
  "Power Sliding Door",
  "Power Steering",
  "Power Windows",
  "Remote Hatch Release",
];

const interiorOptions = [
  "2 Hi-Back Bucket Seats",
  "3rd Row Seating",
  "All Wheather Floor Mats",
  "Bench Seat",
  "Carbon Fiber Trim",
  "Carpeted Floor Mats",
  "Center Arm Rest",
  "Center Console",
  "Clock",
  "Compass And Temperature Display",
  "Cup Holders",
  "Digital Clock",
  "Floor Mats",
  "Overhead Console",
  "PCM",
  "Reclining Seats",
  "Split Folding Rear Seat",
  "Split Front Bench",
  "Split Seats",
  "Sport Seats",
  "Tachometer",
  "Trip Computer",
  "Trip Odometer",
  "Vanity Mirrors",
];

const exteriorOptions = [
  "Accent Stripes",
  "Aluminum Box",
  "Bedliner",
  "Body Side Moldings",
  "Chrome Exhaust Tips",
  "Clearcoat Paint",
  "Custom Bumper",
  "Dual Exhaust",
  "Exterior Keypad Entry",
  "Grille Guard",
  "Hard Tonneau Cover",
  "Hydraulic Lift",
  "Locking Fuel Cap",
  "Locking Tailgate",
  "Metallic Paint",
  "Off Road Tires",
  "Optional Fuel Tank",
  "Pickup Shell",
  "Premium Alloy Wheels",
  "Premium Wheels",
  "Rear Fog Lamps",
  "Rear Spoiler",
  "Rear Wiper",
  "Remote Fuel Door",
  "Removable Hard Top",
  "Roof Rack",
  "Running Boards",
  "Rust Proofing",
  "Sliding Rear Window",
  "Spoiler",
  "Sunroof",
  "Tow Hooks",
  "Tow Package",
  "Tow Tone Paint",
  "Underbody Hoist",
  "Wide Tires",
  "Winch",
  "Wire Wheel Covers",
  "Wire Wheels",
];

const safetyOptions = [
  "ABS Brakes",

  "Adaptive headlights",

  "Alarm System",

  "Child Proof Door Locks",

  "Daytime Running Lights",

  "Disc Brakes",

  "Distronic",

  "Driver Airbag",
  "Dual Air Bags",
  "Dynamic Stability",
  "FR Stabilizing Bars",
  "Fog Lights",
  "HID Headlamps",

  "Head-Protection System",

  "Integrated Child Seat",
  "Intermittent Wipers",
  "Keyless Entry",
  "Knee Air Bag",
  "Parking Sensors",
  "Passenger Airbag",
  "Power Brakes",
  "Rear Defogger",
  "Rear-Side Airbags",
  "Roll Bar",
  "Roll Stability Control",
  "Security System",
  "Side Airbags",
  "Side Curtain Airbags",
  "Traction Control",
  "Variable Assist Steering",
  "Xenon",
];

const luxuryOptions = [
  "Air Conditioned Seats",
  "Air Conditioning",
  "Auto Climate Control",
  "Auto Day/Night Mirrors",
  "Chrome Wheels",
  "Cruise Control",
  "Digital Info Center",
  "Dual Zone Climate Control",
  "FAST - KEY entry system",
  "Front AC Seats",
  "Front Heated Seats",
  "Gold Trim",
  "Heads Up Display",
  "Heated Door Locks",
  "Heated Mirrors",
  "Heated Seats",
  "Heated Steering Wheel",
  "Heated Windshield",
  "Homelink System",
  "Leather Shifter",
  "Leather steering Wheel",
  "Luxury Seats",
  "Memory Seat Position",
  "Memory System",
  "Moonroof",
  "Night Vision",
  "Panoramic Roof",
  "Privacy Glass/Tinted",
  "Rear AC Seats",
  "Rear Air Conditioning",
  "Rear Heated Seats",
  "Rear Radio Control",
  "Remote Start",
  "Remote Trunk Lid",
  "Sport Suspension",
  "Steering Radio Controls",
  "Telescoping Steering Wheel",
  "Tilt Steering Wheel",
  "Tire Pressure",
  "Windows",
  "Wood Trim",
];
const otherOptions = [
  "Adjustable Ride Control",
  "Air Suspension",
  "Auto Leveling",
  "Conventional Spare",
  "Diffrential Locks",
  "Fuel Injection",
  "GPS System",
  "Ground Effects",
  "Infiniti Communicator",
  "Limited Slip Differential",
  "Map Lights",
  "Remote Control Entry",
  "Teleaide System",
  "Trailer Hitch",
];

export const conditionDropDown = [{ key: "New", text: "New" }, { key: "Used", text: "Used" }, { key: "Certified", text: "Certified" }];

const VehicleCondition = [
    {key:1, value: 'roughCondition', text: 'Rough', data:'rough, fair, low market'},
    {key:2, value: 'averageCondition', text: 'Average', data: 'average, good, fair market'},
    {key:3, value: 'cleanCondition', text: 'Clean', data: 'very good, clean, high market'},
    {key:4, value: 'excellentCondition', text: 'Excellent', data: 'excellent, extra clean, *defaults highest level if 3-tiers'}
]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fuelTypeValues,
  engineCylindersValues,
  transmissionValues,
  bodyStyleValues,
  exteriorColorValues,
  interiorColorValues,
  doorValues,
  driveTypeValues,
  yearValues,
  yearNewValues,
  conditionValues,
  historyValues,
  audioOptions,
  powerEquipmentOptions,
  interiorOptions,
  exteriorOptions,
  safetyOptions,
  luxuryOptions,
  otherOptions,
  conditionDropDown,
  VehicleCondition
};
