import {
    IconButton,
    Modal,
    PrimaryButton,
} from "@fluentui/react";
import { trimTypes } from "constants/constants";
import {
    cancelIcon,
    contentStyles as content,
    iconButtonStyles,
} from "constants/styles";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Col, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useNavigate , useLocation } from "react-router-dom";
import { handleGetDTVehicleTrimUsingVin } from "store/dealTracker/dealTracker.action";
import { handleGetVehDetails } from "store/inventory/inventory.action";
import { convertUTCToLocalDateModified } from "utils/dateTimeFunctions";
import { CustomFontText } from "../../customFontText/CustomFontText";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    recentDeal: any;
    trimType: any;
}

export const VehicleFoundDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    recentDeal,
    trimType
}) => {
    const dispatch = useDispatch();
    const [commonServerError, setCommonServerError] = React.useState(null);
    const navigate = useNavigate ();
    const location = useLocation();

    const [existingTradeIns, setExistingTradeIns] = useState(null);

    useEffect(() => {

        if (recentDeal.dealTradeIns) {
            const existingData = recentDeal.dealTradeIns.find(function (x) {
                return x.vehicle && x.vehicle.vin === recentDeal.vin;
            });
            setExistingTradeIns(existingData);
        } else {
            setExistingTradeIns(null);
        }

    }, [recentDeal]);

    const handleNavigateToRecentDeal = () => {
        setCommonServerError('');
        onDismiss(null);
        if (trimType === trimTypes.tradeIn && existingTradeIns) {
            const state: any = location?.state;
            state["tabIndex"] = 2;
            state["productId"] = `${existingTradeIns.id}`;
            state["reloadData"] = true;
            navigate(`/${recentDeal.id}/dealtracker`,{state});
            
        } else {
            if (recentDeal && recentDeal.id)
                navigate(`/${recentDeal.id}/dealtracker`);
        }
    };

    const handleAddVehicle = () => {
        setCommonServerError('');

        if (trimType === trimTypes.tradeIn) {
            let vinTrim;
            if (recentDeal.dealTradeIns && existingTradeIns && existingTradeIns.vehicle) {
                const vehicle = existingTradeIns.vehicle;
                vinTrim = {
                    style: vehicle.style,
                    vin: vehicle.vin
                }
            } else if (recentDeal.dealTradeIns && recentDeal.dealTradeIns.length && recentDeal.vin) {
                const existingVehicle = recentDeal.dealTradeIns.filter(x => x.vehicle && x.vehicle.vin && x.vehicle.vin === recentDeal.vin);
                if (existingVehicle && existingVehicle.length) {
                    vinTrim = {
                        style: existingVehicle[0].vehicle?.style,
                        vin: existingVehicle[0].vehicle?.vin
                    }
                }
            }
            if (vinTrim) {
                dispatch<any>(handleGetDTVehicleTrimUsingVin({
                    vin: vinTrim.vin,
                    trimType,
                    id: recentDeal.id,
                    tradeType: 'getInventoryTrimVIN',
                    callback: (trimData) => {
                        if (trimData && !trimData.status) {
                            dispatch<any>(handleGetVehDetails({
                                vehDet: vinTrim, trimType,
                                callback: (success) => {
                                    if (success) {
                                        onDismiss(null);
                                    } else {
                                        setCommonServerError('Failed to Save the Data.');
                                    }
                                }
                            }));
                        }
                    }
                }));
            }
            else {
                setCommonServerError('Something went wrong');
            }
        }
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            className="deal-vehicle-found"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <CustomFontText className="formTitle">Vehicle Record</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                {
                    commonServerError ?
                        <Row><Col>
                            <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                        </Col> </Row> : (recentDeal ? <>

                            <Row>
                                <Col>
                                    <div>
                                        <p>This vehicle was recently appraised as part of another deal.</p>
                                        <p>Deal Number {recentDeal.dealNumber + ' - ' + recentDeal?.location?.legalName} on {convertUTCToLocalDateModified(recentDeal.createdAt)}</p>
                                    </div>
                                </Col>
                            </Row>
                        </> : null)
                }
            </div>
            {
                recentDeal ?
                    <div className={contentStyles.footer + " inventory-popup-footer"}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <PrimaryButton
                                text={"View Deal " + recentDeal.dealNumber}
                                onClick={handleNavigateToRecentDeal}
                            />
                            <PrimaryButton
                                text="Add to Current Deal"
                                onClick={handleAddVehicle}
                            />
                        </div>
                    </div> : null
            }

        </Modal>
    );
};



