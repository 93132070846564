import React, { useEffect,useState} from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
    //MaskedTextField,
    //ITextFieldProps,
    Persona,
    PersonaPresence,
    PersonaSize,
    IPersonaSharedProps,
    Link, Checkbox
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import masterDropdownValues from "constants/masterDropdownValues";

import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { handleCreateEmployee, handleGeteEmployeeManagers } from "store/employees/employees.action";
//import { IFormError } from "types/errorTypes";
//import { handleGetGroups } from "store/groups/groups.action";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { validTenDigitPhone } from "utils/helperFunctions";
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Tooltip } from "components/tooltip/Tooltip";
import { Dropdown } from 'primereact/dropdown';
import { CustomFontText } from "components/customFontText/CustomFontText";


interface IProps {
    isOpen: boolean;
    isKarlProfile: boolean;
    onDismiss: (res) => void;
}

const stackTokens = { childrenGap: 7 };

export const NewEmployeeDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    isKarlProfile,
    onDismiss,
}) => {

    let examplePersona: IPersonaSharedProps = {
        imageUrl: `${require("assets/avatar-user-placeholder.png")}`,
        //text: "FirstName LastName",
        //secondaryText: "testmail@gmail.com"
    };
    
    const [uploadError, setUploadError] = useState("");
    const [persona, setPersona] = useState(examplePersona);
    //const [isShowUnknownPersonaCoin, setShowUnknownPersonaCoin] = useState(true);
    const [commonServerError, setcommonServerError] = useState(null);
    const [managerList, setManagerList] = useState([]);
    const serverError = useSelector((state: AppState) => state.employees.serverError) as any; 

    //const [permissionFlag, setPermissionFlag] = useState(true);
    //setPermissionFlag(true);
    let imgFileRef: any = React.createRef();

    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            workPhone: "",
            alternatePhone: "",
            workPhoneUsage: [],
            alternatePhoneUsage: [],
            textConsent: false,
            jobTitle: [],
            avatar: "",
            defaultGroupIds: [3],
            managerId:"",
            departmentId:"",
            workPhoneExtension:""
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(50, "First Name must be at most 50 characters").required("Required"),
            lastName: Yup.string().max(50, "Last Name must be at most 50 characters").required("Required"),
            email: Yup.string().max(50, "Email must be at most 50 characters")
                .email("This field must be valid email user")
                .required("Required"),
            workPhone: Yup.string()
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Required"),
            alternatePhone: Yup.string()
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }),
            workPhoneUsage: Yup.array().nullable(),
            alternatePhoneUsage: Yup.array().nullable(),
            textConsent: Yup.boolean(),
            jobTitle: Yup.array().nullable(),//.min(1, "Required"),
            avatar: Yup.string(),
            defaultGroupIds: Yup.array().nullable(),
            managerId: Yup.string(),
            departmentId:Yup.string(),
            workPhoneExtension: Yup.string().max(10,'Extension must be at most 10 characters')}),
        onSubmit: (values) => {

            if (values.defaultGroupIds && values.defaultGroupIds.length === 0 && !isKarlProfile) {
                values.defaultGroupIds.push(1);//Default User
            }else if(isKarlProfile){
                values.defaultGroupIds =[];
            }

            
            

            

            if(isKarlProfile){
                const formdata ={
                    email :values.email ,
                    firstName:values.firstName,
                    lastName:values.lastName,
                    jobTitle:values.jobTitle,
                    workPhone:values.workPhone.replace(/\D/g, '').slice(0, 10),
                    alternatePhone:values.alternatePhone.replace(/\D/g, '').slice(0, 10),
                    workPhoneUsage:values.workPhoneUsage,
                    alternatePhoneUsage:values.alternatePhoneUsage,
                    textConsent:values.textConsent,
                    avatar:values.avatar,
                    defaultGroupIds:values.defaultGroupIds,
                    workPhoneExtension:values.workPhoneExtension
                }
                if(values.departmentId){
                     formdata['departmentId'] = values.departmentId
                }
                if(values.managerId){
                     formdata['managerId'] = values.managerId
                }



                dispatch<any>(
                    handleCreateEmployee(
                        formdata,
                        null,
                        createEmployeeCallback
                    )
                );
            }else{
                const {
                    email,
                    firstName,
                    lastName,
                    jobTitle,
                    workPhone,
                    alternatePhone,
                    workPhoneUsage,
                    alternatePhoneUsage,
                    textConsent,
                    avatar,
                    defaultGroupIds
                } = values;
                dispatch<any>(

                    handleCreateEmployee(
                        {
                            email,
                            firstName,
                            lastName,
                            jobTitle,
                            workPhone: workPhone.replace(/\D/g, '').slice(0, 10),
                            alternatePhone: alternatePhone.replace(/\D/g, '').slice(0, 10),
                            workPhoneUsage,
                            alternatePhoneUsage,
                            textConsent,
                            isActive: true,
                            avatar,
                            defaultGroupIds
                        },
                        null,
                        createEmployeeCallback
                    )
                );
            }

            
        },
    });


    const createEmployeeCallback = () => {
        handleDialogDismiss('Success');
    }

    const handleDialogDismiss = (res) => {
       
        setcommonServerError(null);
        formik.resetForm({ values: formik.initialValues });
        examplePersona.imageUrl = `${require("assets/avatar-user-placeholder.png")}`;
        setPersona(examplePersona);
        onDismiss(res);
    }

    const handleAddEmployee = () => {
        formik.handleSubmit();
    };

    
    //const handleCheckBoxChange = (e, isChecked) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            [e.target.id]: isChecked
    //        }
    //    );
    //};

    const handleRoleCheckBoxChange = (e, isChecked) => {
        if (isChecked) {
            if (!formik.values.jobTitle.includes(e.target.id)) {
                let arr = formik.values.jobTitle;
                arr.push(e.target.id);

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: arr
                    }
                );
            }
        } else {
            if (formik.values.jobTitle.includes(e.target.id)) {

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: formik.values.jobTitle.filter(item => item !== e.target.id) as any,
                    }
                );
            }
        }
    };

    //const handleWorkPhoneCheckBoxChange = (e, isChecked) => {
    //    if (isChecked) {
    //        if (!formik.values.workPhoneUsage.includes(e.target.id)) {
    //            let arr = formik.values.workPhoneUsage;
    //            arr.push(e.target.id);

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    workPhoneUsage: arr
    //                }
    //            );
    //        }
    //    } else {
    //        if (formik.values.workPhoneUsage.includes(e.target.id)) {

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    workPhoneUsage: formik.values.workPhoneUsage.filter(item => item !== e.target.id) as any,
    //                }
    //            );
    //        }
    //    }
    //};

    //const handleAlternatePhoneCheckBoxChange = (e, isChecked) => {
    //    let usage = e.target.id.replace("alternatePhone-", "");
    //    if (isChecked) {
    //        if (!formik.values.alternatePhoneUsage.includes(usage)) {
    //            let arr = formik.values.alternatePhoneUsage;
    //            arr.push(usage);

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    alternatePhoneUsage: arr
    //                }
    //            );
    //        }
    //    } else {
    //        if (formik.values.alternatePhoneUsage.includes(usage)) {

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    alternatePhoneUsage: formik.values.alternatePhoneUsage.filter(item => item !== usage) as any,
    //                }
    //            );
    //        }
    //    }
    //};

    const handleMaskInput = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    const handleLogoChange = e => {
        console.log('start')
        e.preventDefault();
        setUploadError('');
        //setShowUnknownPersonaCoin(false);
        let reader = new FileReader();
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }
        //setError('');
        reader.onloadend = () => {
            console.log(reader.result.toString())
            examplePersona.imageUrl = reader.result.toString();
            setPersona(examplePersona);
            formik.setValues(
                {
                    ...formik.values,
                    "avatar": file,
                },
                true
            );
        }
        reader.readAsDataURL(file)
        console.log('end')
    }

    const handleUploadLink = () => {
        imgFileRef.current.click();
    }

    useEffect(() => {
        if (serverError) {
            setcommonServerError('Failed to Save the Data');
            if (serverError.detail) {
                let arr = serverError.detail.split('\n');
                if (arr.length > 1)
                    setcommonServerError(serverError.detail.split('\n')[1].replace('email: ', ''));
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    useEffect(() => {
        setcommonServerError(null);
        if (isOpen && isKarlProfile) {
            dispatch<any>(handleGeteEmployeeManagers({
                callback: (res) => {
                    if (res && res.results) {
                        let arr = [];
                        res.results.forEach(function (item, index) {
                            let obj = { key: item.id, text: item.firstName + ' ' + item.lastName }
                            arr.push(obj);
                        });

                        setManagerList(arr);
                    } else {
                        setManagerList([]);
                        setcommonServerError('Failed to Fetch the Data')
                    }
                }
            }))
        } else {
            setManagerList([]);
        }
    }, [isOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSettingAdminToggleChange = (ev: any, checked?: boolean) => {
       
        if (checked) {
            
            let arr = formik.values.defaultGroupIds;
            arr.push(2);
            formik.setValues(
                {
                    ...formik.values,
                    defaultGroupIds: arr
                }
            );
        } else {
            formik.setValues(
                {
                    ...formik.values,
                    defaultGroupIds: formik.values.defaultGroupIds.filter(item => item !== 2) as any,
                }
            );
        }
       
    };

    //const handleBuyerToggleChange = (ev: any, checked?: boolean) => {

    //    if (checked) {
    //        let arr = formik.values.defaultGroupIds;
    //        arr.push(3);
    //        formik.setValues(
    //            {
    //                ...formik.values,
    //                defaultGroupIds: arr
    //            }
    //        );
    //    } else {
    //        formik.setValues(
    //            {
    //                ...formik.values,
    //                defaultGroupIds: formik.values.defaultGroupIds.filter(item => item !== 3) as any,
    //            }
    //        );
    //    }

    //};

    const handleManagerChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                managerId: e.value,
            }
        );
    }


    const handleDepartmentChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container }
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord "
        >
            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText className="formTitle">Add New User</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleDialogDismiss}
                />
            </div>
            <div className={contentStyles.body + "  deal-popup-body "}>
                <Row>
                    <Col>
                        <Persona
                            {...persona}
                            styles={styles.personInGridStyles}
                            size={PersonaSize.size100}
                            presence={PersonaPresence.none}
                            //showUnknownPersonaCoin={isShowUnknownPersonaCoin?true:false}
                            imageAlt="Maor Sharett, status unknown"
                            className="persona-square"
                        />
                        <Link onClick={handleUploadLink }>Upload photo
                            <input
                                style={{
                                    padding: 20,
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    width: 107,
                                    height: 1,
                                    overflow: "hidden",
                                    opacity: 0,
                                    marginLeft: -10,
                                }}
                                type="file"
                                ref={imgFileRef}
                                onChange={handleLogoChange}
                            /></Link>
                        {
                            uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                        }
                    </Col>
                    {/*<Col style={{ textAlign: "right" }}>
                        <Link>Inactivate User</Link>
                        <br />
                        <Link>(Activate User)</Link>
                    </Col>*/}
                </Row>
                <hr />
                <Stack>
                    {
                        commonServerError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                    {
                        isKarlProfile ? <>
                            <Row className="contentFieldSpace" >
                                <Col md={6}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First name"
                                        value={formik.values.firstName}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.firstName && formik.errors.firstName
                                                ? formik.errors.firstName.toString()
                                                : null
                                        }
                                    />
                                </Col>
                                <Col md={6}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last name"
                                        value={formik.values.lastName}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.lastName && formik.errors.lastName
                                                ? formik.errors.lastName.toString()
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="contentFieldSpace">
                                <Col>
                                    <div className="yearMake">
                                        <label className="lbl-text">Department</label>
                                        <Dropdown
                                            id="departmentId"
                                            options={masterDropdownValues.departmentOptions}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            filter={false}
                                            className="custom-p-dropdown customInputField"
                                            value={formik.values.departmentId}
                                            resetFilterOnHide={true}
                                            onChange={handleDepartmentChange}
                                            required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="contentFieldSpace">
                                <Col>
                                    <div className="yearMake">
                                        <label className="lbl-text">Manager</label>
                                        <Dropdown
                                            id="managerId"
                                            options={managerList}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            filter
                                            className="custom-p-dropdown customInputField"
                                            value={formik.values.managerId}
                                            resetFilterOnHide={true}
                                            onChange={handleManagerChange}
                                            required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="contentFieldSpace">
                                <Col>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        className="full-width"
                                        value={formik.values.email}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.email && formik.errors.email
                                                ? formik.errors.email.toString()
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="contentFieldSpace">
                                <Col md={6}>
                                    <div className="ms-TextField root-155">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Phone</label>
                                            <div className={formik.touched.workPhone &&
                                                formik.errors.workPhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                <InputMask
                                                    //autoClear={false}
                                                    //required={true}
                                                    id="workPhone"
                                                    name="workPhone"
                                                    mask="(999)999-9999"
                                                    placeholder=""
                                                    value={formik.values.workPhone}
                                                    className="mask-TextField"
                                                    onChange={(e) => handleMaskInput(e)}
                                                    onBlur={formik.handleBlur}>
                                                </InputMask>
                                            </div>
                                            <span className="error-message">{
                                                formik.touched.workPhone &&
                                                formik.errors.workPhone &&
                                                formik.errors.workPhone.toString()
                                            }</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="ms-TextField root-155">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text">Alt phone</label>
                                            <div className={formik.touched.alternatePhone &&
                                                formik.errors.alternatePhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                <InputMask
                                                    //autoClear={false}
                                                    //required={true}
                                                    id="alternatePhone"
                                                    name="alternatePhone"
                                                    mask="(999)999-9999"
                                                    placeholder=""
                                                    value={formik.values.alternatePhone}
                                                    className="mask-TextField"
                                                    onChange={(e) => handleMaskInput(e)}
                                                    onBlur={formik.handleBlur}>
                                                </InputMask>
                                            </div>
                                            <span className="error-message">{
                                                formik.touched.alternatePhone &&
                                                formik.errors.alternatePhone &&
                                                formik.errors.alternatePhone.toString()
                                            }</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="contentFieldSpace">
                                <Col md={6}>
                                    <TextField
                                        id="workPhoneExtension"
                                        name="workPhoneExtension"
                                        label="Extension"
                                        value={formik.values.workPhoneExtension}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.workPhoneExtension && formik.errors.workPhoneExtension
                                                ? formik.errors.workPhoneExtension.toString()
                                                : null
                                        }
                                    />
                                </Col>
                                <Col md={6}>
                                    
                                </Col>
                            </Row>
                        </> : <>
                                <Row className="contentFieldSpace">
                                    <Col md={6}>
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            label="First name"
                                            value={formik.values.firstName}
                                            //required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.firstName && formik.errors.firstName
                                                    ? formik.errors.firstName.toString()
                                                    : null
                                            }
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            label="Last name"
                                            value={formik.values.lastName}
                                            //required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.lastName && formik.errors.lastName
                                                    ? formik.errors.lastName.toString()
                                                    : null
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="contentFieldSpace">
                                    <Col md={6}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formik.values.email}
                                            required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.email && formik.errors.email
                                                    ? formik.errors.email.toString()
                                                    : null
                                            }
                                        />
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row className="contentFieldSpace">
                                    <Col md={6}>
                                        <div className="ms-TextField root-155">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text required-Label">Phone</label>
                                                <div className={formik.touched.workPhone &&
                                                    formik.errors.workPhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                    <InputMask
                                                        //autoClear={false}
                                                        //required={true}
                                                        id="workPhone"
                                                        name="workPhone"
                                                        mask="(999)999-9999"
                                                        placeholder=""
                                                        value={formik.values.workPhone}
                                                        className="mask-TextField"
                                                        onChange={(e) => handleMaskInput(e)}
                                                        onBlur={formik.handleBlur}>
                                                    </InputMask>
                                                </div>
                                                <span className="error-message">{
                                                    formik.touched.workPhone &&
                                                    formik.errors.workPhone &&
                                                    formik.errors.workPhone.toString()
                                                }</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="ms-TextField root-155">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Alt phone</label>
                                                <div className={formik.touched.alternatePhone &&
                                                    formik.errors.alternatePhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                    <InputMask
                                                        //autoClear={false}
                                                        //required={true}
                                                        id="alternatePhone"
                                                        name="alternatePhone"
                                                        mask="(999)999-9999"
                                                        placeholder=""
                                                        value={formik.values.alternatePhone}
                                                        className="mask-TextField"
                                                        onChange={(e) => handleMaskInput(e)}
                                                        onBlur={formik.handleBlur}>
                                                    </InputMask>
                                                </div>
                                                <span className="error-message">{
                                                    formik.touched.alternatePhone &&
                                                    formik.errors.alternatePhone &&
                                                    formik.errors.alternatePhone.toString()
                                                }</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowPaddingBtwSpace">
                                    <Col md={6}>
                                        <Stack tokens={stackTokens} className="checkbox-list">
                                            <label style={{ fontSize: "14px" }} className="lbl-text">What role(s) does this user play? Select all that apply.</label>
                                            <Checkbox
                                                label="Owner"
                                                id="owner"
                                                onChange={handleRoleCheckBoxChange}
                                                checked={formik.values.jobTitle.includes("owner")}
                                            />
                                            <Checkbox
                                                label="General Manager"
                                                id="general-manager"
                                                onChange={handleRoleCheckBoxChange}
                                                checked={formik.values.jobTitle.includes("general-manager")}
                                            />
                                            <Checkbox
                                                label="Office Manager"
                                                id="office-manager"
                                                onChange={handleRoleCheckBoxChange}
                                                checked={formik.values.jobTitle.includes("office-manager")}
                                            />
                                            <Checkbox
                                                label="Sales"
                                                id="sales"
                                                onChange={handleRoleCheckBoxChange}
                                                checked={formik.values.jobTitle.includes("sales")}
                                            />
                                            <Checkbox
                                                label="F&I"
                                                id="f-and-i"
                                                onChange={handleRoleCheckBoxChange}
                                                checked={formik.values.jobTitle.includes("f-and-i")}
                                            />
                                        </Stack>
                                    </Col>
                                    <Col md={6} style={{width:"100px"}}>
                                        <>
                                            <label style={{ fontSize: "14px" }} className="lbl-text">Permissions</label>
                                            <Stack className="user-permissions" tokens={stackTokens} style={{ border: "1px solid black", padding: "15px" }}>
                                                <div className="permission-toggle">
                                                    <Toggle
                                                        label="Settings Admin"
                                                        defaultChecked={false}
                                                        id="settingAdmin"
                                                        onChange={handleSettingAdminToggleChange}
                                                        inlineLabel
                                                        onText=" "
                                                        offText=" " />
                                                    <Tooltip
                                                        desc="The Settings Admin role allows this user to add/remove other users and change account, payment, and service settings." />
                                                </div>
                                                {/*<div className="permission-toggle">
                                                    <Toggle
                                                        label="Manager"
                                                        id="buyer"
                                                        defaultChecked={false}
                                                        onChange={handleBuyerToggleChange}
                                                        inlineLabel
                                                        onText=" "
                                                        offText=" " />
                                                    <Tooltip
                                                        desc="The Manager role allows this user to change vehicle inventory statuses, record vehicle expenses, and change vehicle list prices." />
                                                </div>*/}
                                            </Stack>
                                        </>
                                    </Col>
                                </Row>
                            </>
                    }
                    
                </Stack>
            </div>
            <div className={contentStyles.footer + " modal-footer user-dialog-footer"} >
                <Stack className="user-popup-action addUserFootSpace" tokens={styles.buttonTokens} horizontal>
                    <DefaultButton text="Close" onClick={handleDialogDismiss} />
                    <PrimaryButton
                        disabled={!formik.dirty || !formik.isValid}
                        text="Save"
                        onClick={handleAddEmployee}
                    />
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 600,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "20px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: "20px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 40,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
