import React, { useState, useEffect } from "react";
import {
    DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
    Dropdown,
    getTheme,
    mergeStyleSets,
    FontWeights
} from "@fluentui/react";

import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";

import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import masterDropdownValues from "constants/masterDropdownValues";
// import InputColor from 'react-input-color';

import {
    handleUpdateBrand,
    handleViewBrand,
    handleDeleteBrand,
    // handleGetLocations,
} from "store/brands/brands.action";

import { AppState } from "store";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import {
    cancelIcon,
    iconButtonStyles,
    colrDropdownStyles,
    //contentStyles as content
} from "constants/styles";

//const contentStyles = content();
const emptyForm = {
    name: "",
    color: "#0867ccff",
    fontFamily: "",
    logo: "",
    img: "",
    id: 0
};

interface IProps {
    isOpen: boolean;
    onDismiss: () => void;
    id: number;
}
const deleteContent = 'Please confirm deletion of brand';
export const EditBrandDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    id,
}) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: AppState) => state.brands.loading);
    const brand = useSelector(
        (state: AppState) => state.brands.selectedBrand
    );
    const [uploadError, setUploadError] = useState("");
    const [confirmContent, setConfirmContent] = useState(deleteContent);

    useEffect(() => {
        
        if (brand) {
            formik.setValues({
                id: brand.id,
                name: brand.name,
                color: brand.color,
                fontFamily: brand.fontFamily,
                logo: brand.logo,
                img: ""
            }, true);

            examplePersona.imageUrl = brand.logo
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${brand.logo}`
                : `${require("assets/image-placeholder.jpg")}`;
            setPersona(examplePersona);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand]);

    const examplePersona: IPersonaSharedProps = {
        imageUrl: "",
        text: "",
        secondaryText: "",
    };
    const [persona, setPersona] = React.useState(examplePersona);
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);


    const formik = useFormik({
        initialValues: {
            ...emptyForm,
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            color: Yup.string().required("Required"),
            fontFamily: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const {
                id,
                name,
                color,
                fontFamily,
                logo,
                img
            } = values;
            dispatch<any>(
                handleUpdateBrand({
                    brand: {
                        id,
                        name,
                        color,
                        fontFamily,
                        logo,
                        img
                    },
                    callback: () => onDismiss(),
                })
            );

            // to be removed and fire only when no errors
        },
    });

    React.useEffect(() => {
        if (isOpen && id > 0) {
            dispatch<any>(handleViewBrand(id));
        }
        if (!isOpen) {
            setUploadError('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    React.useEffect(() => {
        if (brand) {
            formik.setValues({
                id: brand.id,
                name: brand.name,
                color: brand.color,
                fontFamily: brand.fontFamily,
                logo: brand.logo,
                img: ""
            }, true);

            examplePersona.imageUrl = brand.logo
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${brand.logo}`
                : `${require("assets/image-placeholder.jpg")}`;
            setPersona(examplePersona);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand]);

    const handleEditBrand = () => {
        formik.handleSubmit();
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDelete = () => {
        setConfirmContent(deleteContent);
        setIsConfirmBoxOpen(true);
    };
    // const handleColorChange = (color) => {

    //     formik.setValues(
    //         {
    //             ...formik.values,
    //             color: color.hex,
    //         },
    //         true
    //     );
    // };
    const handleDropdownChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key,
            },
            true
        );
    };


    const handleLogoChange = e => {
        e.preventDefault();
        setUploadError('');
        let reader = new FileReader();
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }

        reader.onloadend = () => {
            examplePersona.imageUrl = reader.result.toString();
            setPersona(examplePersona);
            formik.setValues(
                {
                    ...formik.values,
                    "img": file,
                },
                true
            );
        }
        reader.readAsDataURL(file)
    }

    const resetModal = () => {
        formik.setValues({ ...emptyForm });
        formik.resetForm();
        examplePersona.imageUrl = `${require("assets/image-placeholder.jpg")}`;
        setPersona(examplePersona);
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            onDismissed={resetModal}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            {/*<Loader show={loading} />*/}
            <ConfirmDialog
                loading={loading}
                description={confirmContent}
                title="Confirm delete brand"
                isOpen={isConfirmBoxOpen}
                onlyDismiss={confirmContent !== deleteContent}
                onAction={() => dispatch<any>(handleDeleteBrand(id, (msg) =>
                {
                    if (msg) {
                        setConfirmContent(msg)
                    } else {
                        setIsConfirmBoxOpen(false);
                        onDismiss();
                    }
                }))}
                onDismiss={() => setIsConfirmBoxOpen(false)}
            />
            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText>Edit Brand</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body}>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    required={true}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={
                        formik.touched.name &&
                            formik.errors.name
                            ? formik.errors.name.toString()
                            : null
                    }
                />


                <Dropdown
                    style={{ fontFamily: formik.values.fontFamily }}
                    id="fontFamily"
                    label="Font Family"
                    required={true}
                    options={masterDropdownValues.fontFamilyList}
                    selectedKey={formik.values.fontFamily}
                    styles={colrDropdownStyles}
                    onChange={handleDropdownChange}
                    errorMessage={
                        formik.touched.fontFamily && formik.errors.fontFamily
                            ? formik.errors.fontFamily.toString()
                            : null
                    }
                />
                <label className="lbl-text required-Label">
                    Color
                </label>
                {/* <InputColor initialValue={formik.values.color} placement="right" onChange={handleColorChange} /> */}
                <label className="lbl-text required-Label">
                    Logo
                 </label>
                <Persona
                    {...persona}
                    styles={styles.personBrandStyles}
                    size={PersonaSize.size100}
                    presence={PersonaPresence.none}
                    hidePersonaDetails={false}
                    imageAlt="Brand Logo"
                />

                <DefaultButton
                    styles={styles.brandUpload}
                    text="Upload logo"
                    type="file"
                    iconProps={{ iconName: "Upload" }}
                >
                    <input
                        style={{
                            padding: 20,
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: 107,
                            height: 1,
                            overflow: "hidden",
                            opacity: 0,
                            marginLeft: -10,
                        }}
                        type="file"
                        onChange={handleLogoChange}
                    />
                </DefaultButton>
                {
                    uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                }
            </div>
            <div className={contentStyles.footer}>
                <Stack
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: 10 }}

                // styles={contentStyles.buttonsStyles}
                >
                    <div>
                        <DefaultButton
                            onClick={handleDelete}
                            iconProps={{ iconName: "Delete" }}
                            text="Delete Brand"
                        />
                    </div>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <DefaultButton text="Close" onClick={onDismiss} />
                        <PrimaryButton
                            onRenderIcon={renderSpinner}
                            text="Save Brand"
                            onClick={() => handleEditBrand()}
                            disabled={!formik.isValid || !formik.dirty}
                        />
                    </Stack>
                </Stack>
            </div>
        </Modal>
    );
};


const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 500,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "20px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: "40px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 40,
    },
});