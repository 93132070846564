import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { IIconStyles } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { colors, permissionsModules } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { prospectStatuses } from "constants/constants";



const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
  },
  rootHovered: {},
  label: { fontWeight: 400 },
};

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};

interface Props {
  id: number;
  modulePermissions: IPermission[];
  handleAddToFavourite: () => void;
  handleRemoveFromFavourite: () => void;
  handleSetStatus: (status: any) => void;
  handleDelete: () => void;
}

export const ProspectEditAdditionalActionsDropdown: React.FunctionComponent<Props> = ({
  id,
  modulePermissions,
  handleSetStatus,
  handleAddToFavourite,
  handleRemoveFromFavourite,
  handleDelete,
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const menuItems: IContextualMenuItem[] = [
    {
      key: "favourites", //with submenu
      name: "Favourites",
      iconProps: { iconName: "FavoriteList", styles: iconMenuStyles },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => {},
      subMenuProps: {
        items: [
          {
            key: "addToFavorite",
            text: "Add",
            onClick: handleAddToFavourite,
          },
          {
            key: "removeFromFavorite",
            text: "Remove",
            onClick: handleRemoveFromFavourite,
          },
        ],
      },
    },
    {
      key: "setStatus", //with submenu
      name: "Set Status",
      iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
      onClick: () => {},
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      subMenuProps: {
        items: [
          {
            key: prospectStatuses[0].text,
            text: prospectStatuses[0].text,
            onClick: () => handleSetStatus(prospectStatuses[0].value),
          },
          {
            key: prospectStatuses[1].text,
            text: prospectStatuses[1].text,
            onClick: () => handleSetStatus(prospectStatuses[1].value),
          },

        ],
      },
    },

    {
      key: "startNewDeal",
      name: "Start New Deal",
      iconProps: { iconName: "TextDocument", styles: iconMenuStyles },
      onClick: () => {},
      style:
        isPermitted(
          modulePermissions,
          eTypes.WRITE,
          permissionsModules.Deals,
          user.roles
        ) &&
        isPermitted(
          modulePermissions,
          eTypes.READ,
          permissionsModules.Inventory,
          user.roles
        ) &&
        isPermitted(
          modulePermissions,
          eTypes.READ,
          permissionsModules.Prospects,
          user.roles
        )
          ? null
          : hiddenStyle,
    },
    {
      key: "delete", //with submenu
      iconProps: { iconName: "Delete", styles: iconMenuStyles },
      name: "Delete",
      style: !handleDelete && !isPermitted(
        modulePermissions,
        eTypes.DELETE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: handleDelete,
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
    useTargetWidth: false,
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Additional Action"
      menuProps={menuProps}
    />
  );
};
