import { takeLeading, put } from "redux-saga/effects";
import {
  HANDLE_GET_SAVED_DESK_A_DEALS,
  HANDLE_CREATE_DESK_A_DEAL,
  HANDLE_DELETE_DESK_A_DEAL,
  HANDLE_UPDATE_DESK_A_DEAL,
  HandleUpdateDeskADeal,
  HandleDeleteDeskADeal,
  HandleCreateDeskADeal,
} from "store/deskADeal/deskADeal.types";
import {
  pendingCreateDeskADeal,
  resolvedGetSavedDeskADeals,
  rejectedGetSavedDeskADeals,
  resolvedCreateDeskADeal,
  rejectedCreateDeskADeal,
  pendingGetSavedDeskADeals,
  pendingDeleteDeskADeal,
  resolvedDeleteDeskADeal,
  rejectedDeleteDeskADeal,
  pendingUpdateDeskADeal,
  resolvedUpdateDeskADeal,
  rejectedUpdateDeskADeal,
} from "store/deskADeal/deskADeal.action";

import {
  createDeskADealApi,
  getDeskADealsApi,
  deleteDeskADealApi,
  updateDeskADealApi,
} from "store/deskADeal/deskADeal.api";

function* createDealSaga(action: HandleCreateDeskADeal) {
  const { deskADeal, prospectId, inventoryId } = action.payload;

  yield put(pendingCreateDeskADeal());

  const { data, error } = yield createDeskADealApi(
    deskADeal,
    prospectId,
    inventoryId
  );

  if (error) {
    yield put(rejectedCreateDeskADeal(error));
    return;
  }

  yield put(resolvedCreateDeskADeal(data));
}

function* getSavedDeskADealsSaga() {
  yield put(pendingGetSavedDeskADeals());

  const { error, data } = yield getDeskADealsApi();

  if (error) {
    yield put(rejectedGetSavedDeskADeals(error));
    return;
  }

  yield put(resolvedGetSavedDeskADeals(data));
}

function* deleteDeskADealSaga(action: HandleDeleteDeskADeal) {
  yield put(pendingDeleteDeskADeal());

  const { id, callback } = action.payload;

  const { error } = yield deleteDeskADealApi(id);

  if (error) {
    yield put(rejectedDeleteDeskADeal(error));
    return;
  }

  callback();
  yield put(resolvedDeleteDeskADeal());
}

function* updateDeskADealSaga(action: HandleUpdateDeskADeal){
  yield put(pendingUpdateDeskADeal())

  // const {deskADeal, deskADealId, inventoryId, prospectId} = action.payload

  const {data, error} = yield updateDeskADealApi({...action.payload});

  if(error){
    yield put(rejectedUpdateDeskADeal(error))
    return
  }


  yield put(resolvedUpdateDeskADeal(data))

}

export default function* DeskADealSaga() {
  yield takeLeading(HANDLE_CREATE_DESK_A_DEAL, createDealSaga);
  yield takeLeading(HANDLE_GET_SAVED_DESK_A_DEALS, getSavedDeskADealsSaga);
  yield takeLeading(HANDLE_DELETE_DESK_A_DEAL, deleteDeskADealSaga);
  yield takeLeading(HANDLE_UPDATE_DESK_A_DEAL, updateDeskADealSaga);
}
