import * as React from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, permissionsModules } from "constants/constants";
import { useSelector } from "react-redux";
import { IPermission } from "types/groupPermissionTypes";
import { AppState } from "store";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};
interface IProps {
  onDelete: () => void;
  onFavouriteClick: () => void;
  amountOfDaysToMoveTask?: (days: number | string) => void;
  onDeleteTasks?: () => void;
  modulePermissions: IPermission[];
  onEdit?: () => void;
  onStartNewDeal?: () => void;
  onSetStatus: (status: string) => void;
  onSendSMS: () => void;
  onSendEmail: () => void;
}

export const ProspectMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
  onDelete,
  onDeleteTasks,
  onFavouriteClick,
  amountOfDaysToMoveTask,
  modulePermissions,
  onEdit,
  onStartNewDeal,
  onSetStatus,
  onSendSMS,
  onSendEmail,
}) => {
  const [menuProps, setMenuProps] = React.useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  React.useEffect(() => {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "view",
        name: "View",
        iconProps: { iconName: "Car", styles: iconMenuStyles },
        onClick: () => {},
        style: !isPermitted(
          modulePermissions,
          eTypes.READ,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
      },
      {
        key: "setStatus", //with submenu
        name: "Set Status",
        iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
        onClick: () => {},
        style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
        subMenuProps: {
          items: [
            {
              key: "new",
              text: "new",
              onClick: () => onSetStatus("new"),
            },
            {
              key: "active",
              text: "active",
              onClick: () => onSetStatus("active"),
            },
          ],
        },
      },
      {
        key: "schedule", //with submenu
        name: "Schedule",
        iconProps: { iconName: "Calendar", styles: iconMenuStyles },
        onClick: () => {},
        style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
        subMenuProps: {
          items: [
            {
              key: "MoveTommorow",
              text: "Move to tommorow",
              onClick: () => amountOfDaysToMoveTask("tomorrow"),
            },
            {
              key: "moveTwoDaye",
              text: "Move by 2 days",
              onClick: () => amountOfDaysToMoveTask(2),
            },
            {
              key: "moveWeek",
              text: "Move it a week",
              onClick: () => amountOfDaysToMoveTask(7),
            },
            {
              key: "deleteAll",
              text: "Delete All Tasks",
              onClick: onDeleteTasks,
            },
          ],
        },
      },

      {
        key: "saveAsPDF", //with submenu
        name: "Save as PDF",
        iconProps: { iconName: "PDF", styles: iconMenuStyles },
        onClick: () => {},
      },
      {
        key: "edit", //with submenu
        name: "Edit",
        iconProps: { iconName: "Edit", styles: iconMenuStyles },
        onClick: () => onEdit(),
        style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
      },
      {
        key: "sendEmail", //with submenu
        name: "Send Email",
        iconProps: { iconName: "Send", styles: iconMenuStyles },
        onClick: () => onSendEmail(),
        style: !isPermitted(
          modulePermissions,
          eTypes.WRITE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
      },

      {
        key: "sendSMS", //with submenu
        name: "Send SMS",
        iconProps: { iconName: "Mail", styles: iconMenuStyles },
        onClick: () => onSendSMS(),
        style: !isPermitted(
          modulePermissions,
          eTypes.WRITE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : user.roles.includes("ROLE_SMS_TEXT")
          ? null
          : hiddenStyle,
      },
      {
        key: "startNewDeal",
        name: "Start New Deal",
        iconProps: { iconName: "TextDocument", styles: iconMenuStyles },
        onClick: () => onStartNewDeal(),
        style:
          isPermitted(
            modulePermissions,
            eTypes.WRITE,
            permissionsModules.Deals,
            user.roles
          ) &&
          isPermitted(
            modulePermissions,
            eTypes.READ,
            permissionsModules.Inventory,
            user.roles
          ) &&
          isPermitted(
            modulePermissions,
            eTypes.READ,
            permissionsModules.Prospects,
            user.roles
          )
            ? null
            : hiddenStyle,
      },
      {
        key: "delete", //with submenu
        iconProps: { iconName: "Delete", styles: iconMenuStyles },
        name: "Delete",
        onClick: () => onDelete(),
        style: !isPermitted(
          modulePermissions,
          eTypes.DELETE,
          permissionsModules.Prospects,
          user.roles
        )
          ? hiddenStyle
          : null,
      },
    ];

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconButton
      className="iconButton"
      iconProps={{ iconName: "MoreVertical" }}
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
