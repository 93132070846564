/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  /* Checkbox,
  MaskedTextField, */
  Stack,
  TextField,
  PrimaryButton,
  // DefaultButton,
  Dropdown
} from "@fluentui/react";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import GoBack from "components/buttons/goBack/GoBack";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { AppState } from "store";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { handleGetTrimUsingVin, handleGetTrimUsingYmm } from "store/inventory/inventory.action";
// import { handleSaveGuidedSetupForm, handleVerifyEinNumber, handleRequestEinAccess } from "store/primarySetup/primarySetup.action"
import { useNavigate  } from "react-router-dom";
import { useFormik } from "formik";
import { dropdownStyles } from "constants/styles";
import * as Yup from "yup";
// import masterDropdownValues from "constants/masterDropdownValues";
import { Link } from "@fluentui/react/lib/Link";
import { validURL } from "utils/helperFunctions";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { handleGetMakes, handleGetModels } from "store/shared/shared.action";
import { IPickerItem } from "types/sharedTypes";
import { AddInventoryTrimDialog } from "components/dialogs/addInventoryDialog/AddInventoryTrimDialog";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }


/* function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
} */

export const AddInventoryPage = () => {
  // const  classes:any  = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate ();
  const userProfile = useSelector(
    (state: AppState) => state.userProfile.userProfile
  );

  // const [ownerChildFormValid, setOwnerChildFormValid] = React.useState(true);
  const [vehicleType, setvehicleType] = React.useState('vin');
  
  const [makeValues, setMakeValues] = React.useState([]);
  const [modelValues, setModelValues] = React.useState([]);
  const [isInventoryTriDialog, setisInventoryTriDialog]= React.useState(false);
  const [isInventoryYMM, setisInventoryYMM]= React.useState(false);
  const [vinDataError, setvinDataError] = React.useState(false);
  // const [sourceTypeVal, setsourceTypeVal] = React.useState('auction');
  // const [locationIssueDate, setlocationIssueDate] = React.useState(new Date());
  const [showModels, setShowModels] = React.useState(false);
  const [trimValues, settrimValues] = React.useState([]);

  const models: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.models
  );

  const makes: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.makes
  );

  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  Yup.addMethod(Yup.string, 'vinOnly', function (message) {
    console.log('message 1', message);
    return (this.matches(/^[A-Z0-9]{17}$/,
      "Must Contain at 17 Characters, Uppercase letters and numbers"))
  });
  
  Yup.addMethod(Yup.object, 'makeOnly', function (message) {
    console.log('message 2', message);
    return (vehicleType === 'make');
  });

  Yup.addMethod(Yup.object, 'modelOnly', function (message) {
    console.log('message 3', message);
    return (vehicleType === 'make');
  });
  
  Yup.addMethod(Yup.number, 'yearOnly', function (message) {
    console.log('message 4', message);
    return (vehicleType === 'make');
  });
  
  const formik = useFormik({
    initialValues: {
      // make: "Nissan",
      make: "",
      model: { key: 0, text: null },
      //year: null,
      year: 2000,
      mileage: "",
      vin: "",
      zip: "",
      nickName: "",
      vehicletype: "vin",
      date: moment(new Date()).format("YYYY-MM-DD"),
      startTime: "",
      url: "",
      sourceType: "auction",
      searchCRM: "",
      dealerSourceName: "",
      privateSourceName: "",
      dealID: "",
      dealerhipsName: "",
      carID: "",
      shippingFrom: ""
    },
    validationSchema: Yup.object({
      make: Yup.string().required("Required"),
      model: Yup.object().modelOnly(),
      /* model: Yup.object().shape({
        key: Yup.number().required("Required").min(1),
        text: Yup.string().required("Required"),
      }).modelOnly(), */
      nickName: Yup.string().required("Required"),
      year: Yup.number().yearOnly(),
      mileage: Yup.string(),
      vin: Yup.string().vinOnly(),
      vehicletype: Yup.string().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required")
    }),
    onSubmit: (values) => {

      // dispatch<any>(handleCreateInventory(inventoryData, onDismiss));
    },
  });

  let initialDealerForm = {
    dealerForm: {
      einNumber: "",
      legalName: "",
      companyAlias: "",
      street: "",
      zip: "",
      city: "",
      county: "",
      state: "",
    }
  };

  const dealerFormik = useFormik({
    initialValues: initialDealerForm,
    validationSchema: Yup.object({
      dealerForm: Yup.object({
        einNumber: Yup.string()
          .test("len", "Required", (val) => {
            if (val === undefined)
              return true;
            const val_length_without_dashes = val.replace(/-|_/g, "").replace(/[{()}]/g, '').length;
            return val_length_without_dashes === 9;
          }),
        companyAlias: Yup.string(),
        legalName: Yup.string().required("Required").min(3, "Legal Name must be Minimum 3 characters"),
        street: Yup.string().required("Required"),
        zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
        city: Yup.string().required("Required"),
        county: Yup.string().required("Required"),
        state: Yup.string().required("Required")
      })
    }),
    onSubmit: (values) => {
    },
  });

  let initialLocationForm = {
    locationForm: {
      legalName: "",
      companyAlias: "",
      dealerLicenseNumber: "",
      dealerLicenseDateOfIssue: moment(new Date()).format("YYYY-MM-DD"),
      dealerLicenseState: "",
      dealerType: "",
      collateral: "auto",
      // service:"yes",
      inventoryCondition: [],
      isMaintenanceFacilityAvailable: true,
      street: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      phoneNumber: "",
      faxNumber: "",
      website: "",
      // email: ""
    }
    // countries: []      
  };

  const locationFormik = useFormik({
    initialValues: initialLocationForm,
    validationSchema: Yup.object({
      locationForm: Yup.object({
        legalName: Yup.string().required("Required"),
        companyAlias: Yup.string(),
        dealerLicenseNumber: Yup.string().required("Required").min(1, "Dealer License Number must be Minimum 1 Character").max(50, "Dealer License Number must be Maximum 50 Character"),
        dealerLicenseDateOfIssue: Yup.string().required("Required"),
        dealerLicenseState: Yup.string().required("Required"),
        dealerType: Yup.string().required("Required"),
        collateral: Yup.string().required("Required"),
        isMaintenanceFacilityAvailable: Yup.boolean(),
        // service: Yup.string().required("Required"),
        inventoryCondition: Yup.array().nullable().min(1, "Required"),
        street: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
        county: Yup.string().required("Required"),
        faxNumber: Yup.string()
          .test("len", (val) => {
            if (val === undefined)
              return true;
            const val_length_without_dashes = val.replace(/-|_/g, "").replace(/[{()}]/g, '').length;
            return (val_length_without_dashes <= 50 && val_length_without_dashes > 1);
          }),
        website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),//optional 
        // email: Yup.string().required("Required").email("Enter valid email"),
        phoneNumber: Yup.string()
          .test("len", "Required", (val) => {
            if (val === undefined)
              return true;
            const val_length_without_dashes = val.replace(/-|_/g, "").replace(/[{()}]/g, '').length;
            return val_length_without_dashes === 10;
          })
          .required("Required"),
        // role: Yup.string().required("Required"),
        //email: Yup.string()
        //    .required("Required")
        //    .email("Enter valid email")
      }),
    }),
    onSubmit: (values) => {
    },
  });

  let initialOwnerForm = {
    ownerForm: {
      firstName: "",
      lastName: "",
      // role: "",
      ownershipPercentage: 100,
      phoneNumber: "",
      email: ""
    }
  };

  const ownerFormik = useFormik({
    initialValues: initialOwnerForm,
    validationSchema: Yup.object({
      ownerForm: Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().required("Required").email("Enter valid email"),
        phoneNumber: Yup.string()
          .test("len", (val) => {
            if (val === undefined)
              return true;
            const val_length_without_dashes = val.replace(/-|_/g, "").replace(/[{()}]/g, '').length;
            return val_length_without_dashes === 10;
          }),
        // role: Yup.string().required("Required"),
        ownershipPercentage: Yup.number().required("Required"),
        //email: Yup.string()
        //    .required("Required")
        //    .email("Enter valid email")
      }),
    }),
    onSubmit: (values) => {
    },
  });

  const [dealerForm, setdealerForm] = React.useState({
    form: initialDealerForm,
    transitions: {
      enterRight: `animated enterRight`,
      enterLeft: `animated enterLeft`,
      exitRight: `animated exitRight`,
      exitLeft: `animated exitLeft`,
      intro: `animated intro`,
    },
  });

  const nextStep = (e) => {
   // setValue(e);
    if (e === 1) {
     // setdealerTab(false);
    } else if (e === 2) {
     // setownerTab(false);
    } else if (e === 3) {
     // setcontactTab(false);
    }
  };

  /** user guided setup initial data */
  useEffect(() => {
    if (userProfile && userProfile.guidedSetup) {
      setvehicleType('vin');
      // dealer tab
      if ((typeof userProfile.guidedSetup === 'object') &&
        (typeof userProfile.guidedSetup.dealer === 'object')) {
        if (Array.isArray(userProfile.guidedSetup.dealer.companyAlias)) {
          userProfile.guidedSetup.dealer.companyAlias = userProfile.guidedSetup.dealer.companyAlias.join();
        }
        dealerFormik.setValues(
          {
            dealerForm: {
              ...dealerFormik.values.dealerForm,
              ...userProfile.guidedSetup.dealer
            }
          }
        );
        if (userProfile.guidedSetup.dealer.einNumber) {
         // setverifiedEIN(true);
        //  setduplicateEIN(false);
          //setdisableEIN(true);
        } else {
         // setverifiedEIN(false);
         // setduplicateEIN(false);
          //setdisableEIN(false);
        }
      }

      // location tab
      if ((typeof userProfile.guidedSetup === 'object') &&
        (userProfile.guidedSetup.locations.length)) {

        //setdealerTab(false);
        nextStep(1);

       // setlocationShip([]);
        userProfile.guidedSetup.locations.map((form, index) => {
          if (index === 0) {
            locationFormik.setValues(
              {
                locationForm: {
                  ...form
                }
              }
            );
            // setlocationIssueDate(new Date(form.dealerLicenseDateOfIssue));
          }
        });
      }

      // owner tab
      if ((typeof userProfile.guidedSetup === 'object') &&
        (userProfile.guidedSetup.owners.length)) {

        //setownerTab(false);
        nextStep(2);
       // setOwnerShip([]);
        userProfile.guidedSetup.owners.map((form, index) => {
          if (index === 0) {
            ownerFormik.setValues(
              {
                ownerForm: {
                  ...form
                }
              }
            );
          }
        });
      }

      // setshowGuidedSteps(true);
    }
    setModelValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    let zipFields = locationFormik.values.locationForm.zip;
    if (zipFields.length === 5) {
      dispatch<any>(switchGlobalLoader(true));
      (getAddreessByZip(zipFields) as any).then((response) => {

        if (response.Status) {
          const { form } = dealerForm;

          // setCityDealerList(response.Cities);
          // setCountryDealerList(response.Counties);

          locationFormik.setValues(
            {
              ...locationFormik.values,
              locationForm: {
                ...locationFormik.values.locationForm,
                'county': response.DefaultCounty,
                'city': response.DefaultCity,
                'state': response.State
                //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
              }
            }
          );
          setdealerForm({
            ...dealerForm,
            form,
          });
          //setlocationZipApiMsg("");
          // updateForm("State", response.State);
        }
        else {
          // setZipApiMsg(response.ErrorMessage);
         // setlocationZipApiMsg(response.ErrorMessage);
          locationFormik.setValues(
            {
              ...locationFormik.values,
              locationForm: {
                ...locationFormik.values.locationForm,
                'county': "",
                'city': "",
                'state': ""
                //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
              }
            }
          );
         // setCityDealerList([]);
         // setCountryDealerList([]);
          // resetCityCountyState();
        }
        dispatch<any>(switchGlobalLoader(false));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFormik.values.locationForm.zip]);

  useEffect(() => {
    let zipFields = dealerFormik.values.dealerForm.zip;
    if (zipFields.length === 5) {
      dispatch<any>(switchGlobalLoader(true));
      (getAddreessByZip(zipFields) as any).then((response) => {

        if (response.Status) {

         // setCityCompanyList(response.Cities);
        //setCountryCompanyList(response.Counties);

          dealerFormik.setValues(
            {
              ...dealerFormik.values,
              dealerForm: {
                ...dealerFormik.values.dealerForm,
                'county': response.DefaultCounty,
                'city': response.DefaultCity,
                'state': response.State
              }
            }
          );
          //setlocationZipApiMsg("");
        }
        else {
         // setdealerZipApiMsg(response.ErrorMessage)
          dealerFormik.setValues(
            {
              ...dealerFormik.values,
              dealerForm: {
                ...dealerFormik.values.dealerForm,
                'county': "",
                'city': "",
                'state': ""
              }
            }
          );
         // setCityCompanyList([]);
          // setCountryCompanyList([]);
          //  setZipApiMsg(response.ErrorMessage);
          //  resetCityCountyState();
        }
        dispatch<any>(switchGlobalLoader(false));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerFormik.values.dealerForm.zip]);

  useEffect(() => {
    if (makes.length > 0) {
      const values = makes.map((item) => {
        return { key: item.id, text: item.label };
      });
      setMakeValues(values);
    }
  }, [makes]);

  useEffect(() => {
    if (models.length > 0) {
      const values = models.map((item) => {
        return { key: item.id, text: item.label };
      });
      setModelValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  useEffect(() => {
    if (formik.values.make !== "unknown") {
      setShowModels(true);

      formik.setFieldValue("model", { key: 0, text: "" });
      dispatch<any>(handleGetModels(formik.values.make?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.make]);

  useEffect(() => {
    dispatch<any>(handleGetMakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const yearValues = dropDownsValues.yearValues.map((item) => {
    return { key: item, text: item };
  });

  /* const handleDropdownMultiChange = (e, item) => {
    const id = e.target.id;
    let newValues = [...formik.values[id]];

    if (formik.values[id].includes(item.text)) {
      newValues = newValues.filter((element) => element !== item.text);
    } else {
      newValues.push(item.text);
    }

    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: newValues,
      },
      true
    );
  }; */
  
  const changeToUppercase = (e: any) => {
    formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
  };

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      }
    );
    /* if (e.target.id === 'sourceType') {
      setsourceTypeVal(item.key);
    } */
  };

  const handleAddInventory = () => {
    // formik.handleSubmit();
    /* if (formik.values.vehicletype === 'make') {
      setisInventoryTriDialog(true)
      // navigate('/shop-inventory');
    } else {
      // setisInventoryTriDialog(false)
      navigate('/shop-inventory');
    } */
    console.log('formik.values.vin', formik.values.vin);
    dispatch<any>(handleGetTrimUsingVin({ 
      vin: formik.values.vin,
      callback: (trimData) => {
        if (trimData?.trim.length === 0) {
          setvinDataError(true);
        } else if (trimData.trim.length > 1) {
          const values = trimData.trim.map((item, index) => {
            return { key: index, text: item };
          });
          settrimValues(values);
          setisInventoryTriDialog(true);
        } else {
          navigate('/shop-inventory');
        }
      }})
    );
  };

  const handleAddYmmInventory = () => {
    // formik.handleSubmit();
    /* if (formik.values.vehicletype === 'make') {
      setisInventoryTriDialog(true)
      // navigate('/shop-inventory');
    } else {
      // setisInventoryTriDialog(false)
      navigate('/shop-inventory');
    } */
    let ymm = {
      make: formik.values.make,
      model: formik.values.model.text,
      year: formik.values.year
    };
    console.log('YMM', ymm);
    dispatch<any>(handleGetTrimUsingYmm({ 
      ymm: ymm,
      callback: (trimData) => {
        if (trimData?.trim.length === 0) {
          setvinDataError(true);
        } else if (trimData.trim.length > 1) {
          const values = trimData.trim.map((item, index) => {
            return { key: index, text: item };
          });
          settrimValues(values);
          setisInventoryTriDialog(true);
        } else {
          navigate('/shop-inventory');
        }
      }}))
  };
  
  const handleMakeChange = (e: {value: any}) => {
    console.log('e.value', e.value)
    formik.setValues(
      {
        ...formik.values,
        make: e.value ? e.value : '',
      }
    );
  };

  const handleModelChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        model: item,
      }
    );
  };

  const handleNoVIN = () => {
    setisInventoryYMM(true)
  };

  return (
    <Stack tokens={{ childrenGap: 30 }} style={{ marginTop: 10 }} className="addInventoryScreen">
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        tokens={{ childrenGap: 30 }}
      >
        <GoBack label="Inventory Management" />
      </Stack>

      <Stack tokens={{ childrenGap: 30 }} style={{ marginTop: 10 }}>
        
        {isInventoryTriDialog && (
          <AddInventoryTrimDialog
            onDismiss={() => setisInventoryTriDialog(false)}
            trimValues={trimValues}
            vin={formik.values.vin}
            ymm={null}
            vehID={null}
            orderStock={null}
          />
        )}
            
        <div className="addInventory">
          <CustomFontText size={20} style={{ marginBottom: 20, fontWeight: 'bold' }}>
            Add New Vehicle
          </CustomFontText>
              
            {
              vinDataError ?

              <div>
                  <Row>
                      <Col>
                          <p className="genericErrorColor"><span>Failed to Fetch Data</span></p>
                      </Col>
                  </Row>
              </div> : null
            }

              { isInventoryYMM ? 
              <div>
                <Row className="inventoryRowSection">
                  <Col sm={6} md={4} xs={6}>
                    <div className="yearMake">
                      <Dropdown
                          id="year"
                          label="Year"
                          required={true}
                          selectedKey={
                            formik.values.year ? formik.values.year : undefined
                          }
                          onChange={handleDropdownChange}
                          placeholder=""
                          options={yearValues}
                          styles={dropdownStyles}
                          onBlur={formik.handleBlur}
                          errorMessage={
                            formik.touched.year && formik.errors.year
                              ? formik.errors.year.toString()
                              : null
                          }
                        />
                    </div>
                  </Col>
                </Row>
                <Row className="inventoryRowSection">
                  <Col sm={6} md={4} xs={6}>
                    <div>
                      {/* <ComboBox
                        id="make"
                        // componentRef={comboBoxRef}
                        // defaultSelectedKey="C"
                        label="Make"
                        required={true}
                        allowFreeform
                        autoComplete="on"
                        options={makeValues}
                        onChange={handleMakeChange}
                        selectedKey={formik.values.make.key}
                      /> */}
                      <h5 style={{ margin: "5px 0"}}>Make</h5>
                      <PRDropdown id="make" value={formik.values.make} className="invMakeDropdown"
                      options={makeValues} onChange={handleMakeChange} optionLabel="text" optionValue="key"
                      virtualScrollerOptions={{ itemSize: 31 }} placeholder="Select Item"/>
                    </div>
                  </Col>
                </Row>
                <Row className="inventoryRowSection">
                  <Col sm={6} md={4} xs={6}>
                      <div>
                        <Dropdown
                            id="model"
                            label="Model"
                            required={true}
                            disabled={!showModels}
                            // selectedKeys={
                            //   formik.values.model ? formik.values.model : undefined
                            // }

                            selectedKey={formik.values.model.key}
                            // multiSelect
                            onChange={handleModelChange}
                            placeholder=""
                            options={modelValues}
                            styles={dropdownStyles}
                            onBlur={formik.handleBlur}
                            errorMessage={
                              formik.touched.model && formik.errors.model
                                ? formik.errors.model.text.toString()
                                : null
                            }
                          />
                      </div>
                    </Col>
                </Row>
              
                <Row className="inventoryRowSection">
                  <Col sm={6} md={4} xs={6} className="submitBtn">
                    <PrimaryButton
                      // disabled={!formik.isValid || !formik.dirty}
                      // onRenderIcon={renderSpinner}
                      text="Decode"
                      onClick={handleAddYmmInventory}
                    />
                  </Col>
                </Row>
              </div>
              :
              <div>
              <Row className="inventoryRowSection inventorySplitRow">
                <Col className="inventoryColDivider" sm={4} md={4} xs={12}>
                      <TextField
                        id="vin"
                        name="vin"
                        label="VIN"
                        iconProps={{ iconName: "Camera" }}
                        value={formik.values.vin}
                        onChange={changeToUppercase}
                        onBlur={formik.handleBlur}
                        errorMessage={
                          formik.touched.vin &&
                          formik.errors.vin &&
                          formik.errors.vin.toString()
                        }
                        // style={{ width: "calc(100% - 130px)" }}
                      />  
                </Col>
              </Row>
              <Row className="noVin">
                <Col>
                  <Link onClick={handleNoVIN}>
                    I don't have the VIN
                  </Link>
                </Col>
              </Row>
              
              <Row className="inventoryRowSection submitBtn">
                <PrimaryButton
                  // disabled={!formik.isValid || !formik.dirty}
                  // onRenderIcon={renderSpinner}
                  text="Decode"
                  onClick={handleAddInventory}
                />
              </Row>
            </div>
            }
              
        </div>
      </Stack>
    </Stack>
  );
};
