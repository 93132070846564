const USER_TOKEN = "USER_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const REMEMBER = "REMEMBER";

export const saveRemember = (value) => {
    localStorage.setItem(REMEMBER, JSON.stringify(value));
};

export const getRemember = () => {
    return localStorage.getItem(REMEMBER);
};


export const removeRemember = () => {
    localStorage.removeItem(REMEMBER);
};


export const saveUserToken = (token: string) => {
    localStorage.setItem(USER_TOKEN, token);
};

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN);
};


export const removeUserToken = () => {
    localStorage.removeItem(USER_TOKEN);
};

export const saveRefreshToken = (refreshToken: string) => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
};


export const removeRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};
