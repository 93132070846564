import * as React from "react";
import {
  TextField,
  ITextFieldProps,
} from "@fluentui/react/lib/TextField";
import {
  DefaultButton,
} from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";
import {
  IStackTokens,
  Stack,
  IStackStyles,
} from "@fluentui/react/lib/Stack";
import { FontIcon } from "@fluentui/react";
import { colors } from "constants/constants";
import useId from "@mui/material/utils/useId";

// export interface ITextFieldCustomRenderExampleState {
//   isCalloutVisible: boolean;
// }

const stackTokens: IStackTokens = {
  childrenGap: 4,
  // maxWidth: 300,
};

const labelCalloutStackStyles: Partial<IStackStyles> = {
  root: { padding: 20 },
};


// const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: -3 } };
// const iconProps = { iconName: "Info" };

// const onRenderDescription = (props: ITextFieldProps): JSX.Element => {
//   const theme = getTheme();
//   return (
//     <Text variant="small" styles={getDescriptionStyles(theme)}>
//       {props.description}
//     </Text>
//   );
// };
//
// const onWrapDefaultLabelRenderer = (
//   props: ITextFieldProps,
//   defaultRender: IRenderFunction<ITextFieldProps>
// ): JSX.Element => {
//   return (
//     <>
//       <Stack horizontal verticalAlign="center" tokens={stackTokens}>
//         <span>{defaultRender(props)}</span>
//         <Icon
//           iconName="Globe"
//           title="Globe"
//           ariaLabel="Globe"
//           styles={iconStyles}
//         />
//       </Stack>
//     </>
//   );
// };

const CustomLabel = (props: ITextFieldProps): JSX.Element => {
  // const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(
  //   false
  // );
  const [isCalloutVisible, toggleIsCalloutVisible] = React.useState(false);

  const descriptionId: string = useId("description");
  const iconButtonId: string = useId("iconButton");

  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        tokens={stackTokens}
        style={{ marginBottom: 5 }}
      >
        <span className={props.required ? "lbl-text required-Label" : "lbl-text"} id={props.id}>{props.label}</span>
        {/*<IconButton*/}
        {/*  id={iconButtonId}*/}
        {/*  iconProps={iconProps}*/}
        {/*  title="Info"*/}
        {/*  ariaLabel="Info"*/}
        {/*  onClick={toggleIsCalloutVisible}*/}
        {/*  styles={iconButtonStyles}*/}
        {/*/>*/}

        <FontIcon
          style={{
            cursor: "pointer",
            marginLeft: 10,
            color: colors.primaryBlue,
          }}
          color={colors.primaryBlue}
          id={iconButtonId}
          iconName="Info"
          onMouseEnter={() => toggleIsCalloutVisible(true)}
          onMouseLeave={() => toggleIsCalloutVisible(false)}
        />
      </Stack>
      {isCalloutVisible && (
        <Callout
          target={"#" + iconButtonId}
          setInitialFocus
          onDismiss={() => toggleIsCalloutVisible(false)}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
        >
          <Stack
            tokens={stackTokens}
            horizontalAlign="start"
            styles={labelCalloutStackStyles}
          >
            <span id={descriptionId}>
                          {props.description}
            </span>
            <DefaultButton onClick={() => toggleIsCalloutVisible(false)}>
              Close
            </DefaultButton>
          </Stack>
        </Callout>
      )}
    </>
  );
};

interface IProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (event) => void;
  onBlur?: (event) => void;
  errorMessage?: any;
  prefix?: any;
  disabled?: boolean;
  required?: boolean;
  description?: string;
}

export const TextFieldWithLabelAndInfoIcon: React.FunctionComponent<IProps> = ({
  onChange,
  onBlur,
  errorMessage,
  id,
  name,
  value,
  label,
  prefix,
  required,
  description = ""
}) => {
  const labelId: string = useId("label");
  const onRenderLabel = (props: ITextFieldProps) => (
    <CustomLabel id={labelId} {...props} />
  );

  return (
    <Stack tokens={stackTokens} style={{ marginBottom: 5 }}>
      <TextField
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
        style={{ width: "100%" }}
        aria-labelledby={labelId}
        // eslint-disable-next-line react/jsx-no-bind
        onRenderLabel={onRenderLabel}
        // description="Click the (i) icon!"
        prefix={prefix}
      />
    </Stack>
  );
};

export const TextFieldWithLabelAndInfoIconDisabled: React.FunctionComponent<IProps> = ({
  onChange,
  onBlur,
  errorMessage,
  id,
  name,
  value,
  label,
  prefix,
}) => {
  const labelId: string = useId("label");
  const onRenderLabel = (props: ITextFieldProps) => (
    <CustomLabel id={labelId} {...props} />
  );

  return (
    <Stack tokens={stackTokens} style={{ marginBottom: 5 }}>
      <TextField
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        errorMessage={errorMessage}
        style={{ width: "100%" }}
        aria-labelledby={labelId}
        // eslint-disable-next-line react/jsx-no-bind
        onRenderLabel={onRenderLabel}
        // description="Click the (i) icon!"
        prefix={prefix}
        disabled
      />
    </Stack>
  );
};
