import { IGroupResponse, IGroupPermissionUpdate} from "types/groupPermissionTypes";
import {IPagination} from "types/paginationTypes";
export const SET_SORT_GROUPS_PERMISSIONS = 'SET_SORT_GROUPS_PERMISSIONS';
export interface SetSortGroupsPermissions {
    type: typeof SET_SORT_GROUPS_PERMISSIONS;
    payload: string;
}

/** GET_GROUPS_PERMISSIONS */
export const HANDLE_GET_GROUPS_PERMISSIONS = "HANDLE_GET_GROUPS_PERMISSIONS ";

export interface HandleGetGroupsPermissions {
    type: typeof HANDLE_GET_GROUPS_PERMISSIONS;
    payload: IPagination;
}

export const GET_GROUPS_PERMISSIONS_PENDING = "GET_GROUPS_PERMISSIONS_PENDING";

export interface GetGroupsPermissionsPending {
    type: typeof GET_GROUPS_PERMISSIONS_PENDING;
}

export const GET_GROUPS_PERMISSIONS_RESOLVED =
    "GET_GROUPS_PERMISSIONS_RESOLVED";

export interface GetGroupPermissionsResolved {
    type: typeof GET_GROUPS_PERMISSIONS_RESOLVED;
    payload: {items: IGroupResponse, groups:any};
}

export const GET_GROUPS_PERMISSIONS_REJECTED =
    "GET_GROUPS_PERMISSIONS_REJECTED";

export interface GetGroupPermissionsRejected {
    type: typeof GET_GROUPS_PERMISSIONS_REJECTED;
    payload: object[];
}

export const SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS = "SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS";

export interface SetRowsPerPage {
    type: typeof SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS;
    payload: number;
}

// ==========HANDLE GET PERMISSIONS

export const HANDLE_GET_PERMISSIONS = "HANDLE_GET_PERMISSIONS ";

export interface HandleGetPermissions {
    type: typeof HANDLE_GET_PERMISSIONS;
}

export const GET_PERMISSIONS_PENDING = "GET_PERMISSIONS_PENDING";

export interface GetPermissionsPending {
    type: typeof GET_PERMISSIONS_PENDING;
}

export const GET_PERMISSIONS_RESOLVED = "GET_PERMISSIONS_RESOLVED";

export interface GetPermissionsResolved {
    type: typeof GET_PERMISSIONS_RESOLVED;
    payload: IGroupResponse; ////////
}

export const GET_PERMISSIONS_REJECTED =
    "GET_PERMISSIONS_REJECTED";

export interface GetPermissionsRejected {
    type: typeof GET_PERMISSIONS_REJECTED;
    payload: object[];
}

// ==========UPDATE PERMISSIONS

export const HANDLE_UPDATE_PERMISSIONS = "HANDLE_UPDATE_PERMISSIONS ";

export interface HandleUpdatePermissions {
    type: typeof HANDLE_UPDATE_PERMISSIONS;
    payload: IGroupPermissionUpdate[]
}

export const UPDATE_PERMISSIONS_PENDING = "UPDATE_PERMISSIONS_PENDING";

export interface UpdatePermissionsPending {
    type: typeof UPDATE_PERMISSIONS_PENDING;
}

export const UPDATE_PERMISSIONS_RESOLVED = "UPDATE_PERMISSIONS_RESOLVED";

export interface UpdatePermissionsResolved {
    type: typeof UPDATE_PERMISSIONS_RESOLVED;
    payload: IGroupResponse; //////// < =============
}

export const UPDATE_PERMISSIONS_REJECTED =
    "UPDATE_PERMISSIONS_REJECTED";

export interface UpdatePermissionsRejected {
    type: typeof UPDATE_PERMISSIONS_REJECTED;
    payload: object[];
}

export type GroupsPermissionsActions =
    | SetSortGroupsPermissions
    | HandleGetGroupsPermissions
    | GetGroupsPermissionsPending
    | GetGroupPermissionsResolved
    | GetGroupPermissionsRejected
    | HandleGetPermissions
    | GetPermissionsPending
    | GetPermissionsResolved
    | GetPermissionsRejected
    | HandleUpdatePermissions
    | UpdatePermissionsPending
    | UpdatePermissionsResolved
    | UpdatePermissionsRejected
    | SetRowsPerPage;
