import React, { FunctionComponent } from "react";
import {
  DefaultButton,
  IconButton,
  Modal,
  PrimaryButton,
  TextField,
  Toggle,
    Checkbox
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  cancelIcon,
  contentStyles as contentStyle,
  iconButtonStyles,
} from "constants/styles";
import {colors} from "constants/constants";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useFormik } from "formik";
import WithOptionalLabel from "../../customInputs/withOptionalLabel/WithOptionalLabel";
import {TextFieldWithLabelAndInfoIcon} from "../../textFieldWithLabelAndInfoIcon/TextFieldWithLabelAndInfoIcon";

const contentStyles = contentStyle(600, "auto");

interface IProps {
  onDismiss: () => void;
  serviceName: string;
}

export const ServiceConfigurationDialog: FunctionComponent<IProps> = ({
  onDismiss,
  serviceName,
}) => {
  const loading = false;

  const formik = useFormik({
    initialValues: {
      isServiceActive: false,
      customField1: "",
      customField2: "",
      customField3: "",
      customField4: "",
      cutomOption1: false,
      customOption2: false,
      customOption3: false,
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const renderSpinner = () => {
    return loading ? <Spinner styles={{}} size={SpinnerSize.medium} /> : null;
  };

  // const handleDropdownChange = (e, item) => {
  //   formik.setValues(
  //     {
  //       ...formik.values,
  //       [e.target.id]: item.key,
  //     },
  //     true
  //   );
  // };

  const handleToggle = () => {
    formik.setFieldValue("isServiceActive", !formik.values.isServiceActive);
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <CustomFontText>{serviceName}</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack>
          <Stack.Item>
            <Stack>
              <Toggle
                id="isServiceActive"
                checked={formik.values.isServiceActive}
                onBlur={formik.handleBlur}
                onChange={handleToggle}
                label="Service Status"
                inlineLabel
                onText="active"
                offText="inactive"
              />
            </Stack>
          </Stack.Item>
        </Stack>

          <TextField
              id="assignedVehicle"
              name="assignedVehicle"
              label="Custom Field"
              // value={
              //   selectedInventory
              //     ? `${selectedInventory.make} ${selectedInventory.model}`
              //     : ""
              // }
              // errorMessage={
              //   formik.touched.assignedVehicle &&
              //   formik.errors.assignedVehicle
              //     ? formik.errors.assignedVehicle.toString()
              //     : null
              // }
          />

          <TextField
              id="assignedVehicle"
              name="assignedVehicle"
              label="Custom Field"

          />



          <WithOptionalLabel>
              <TextFieldWithLabelAndInfoIcon
                  id="customField2"
                  prefix="$"
                  name="customField2"
                  label="Custom Field 2"
                  value={formik.values.customField2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                      formik.touched.customField2 &&
                      formik.errors.customField2 &&
                      formik.errors.customField2.toString()
                  }
              />
          </WithOptionalLabel>
          <TextFieldWithLabelAndInfoIcon
              prefix="$"
              id="customField3"
              name="customField3"
              label="Custom Field 3"
              value={formik.values.customField3}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                  formik.touched.customField3 &&
                  formik.errors.customField3 &&
                  formik.errors.customField3.toString()
              }
          />


          <Stack tokens={{childrenGap: 15}} style={{marginBottom: 20, marginTop: 20}}>
              <Checkbox label="Custom Option" checked={true} onChange={() => {}} />
              <Checkbox label="Custom Option" checked={false} onChange={() => {}} />
              <Checkbox label="Custom Option" checked={true} onChange={() => {}} />
          </Stack>


      <div style={{color: colors.grayText, paddingBottom: 20}}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores at,
        blanditiis commodi consectetur culpa cumque dolore dolores ea earum ex
        fugiat impedit maiores modi, necessitatibus nihil numquam odit omnis
        quibusdam quidem recusandae repudiandae sed tempore unde, ut veniam vero
        voluptate! Ipsum laudantium nesciunt nihil quas sed similique tempore
        unde, voluptates!
      </div>
      </div>
      <div className={contentStyles.footer}>
        <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
          <DefaultButton text="Close" onClick={onDismiss} />
          <PrimaryButton
            disabled={!formik.dirty || !formik.isValid}
            onRenderIcon={renderSpinner}
            text="Save Changes"
            onClick={() => {}}
          />
        </Stack>
      </div>
    </Modal>
  );
};
