import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { IIconStyles } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { colors, permissionsModules } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
  },
  rootHovered: {},
  label: { fontWeight: 400 },
};

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};

interface Props {
  modulePermissions: IPermission[];
  onDelete?: () => void;
}
export const ViewAdditionalActionsDropdown: React.FunctionComponent<Props> = ({
  modulePermissions,
  onDelete,
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const menuItems: IContextualMenuItem[] = [
    {
      key: "favourites", //with submenu
      name: "Favourites",
      iconProps: { iconName: "FavoriteList", styles: iconMenuStyles },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => {},
      subMenuProps: {
        items: [
          {
            key: "addToFavorite",
            text: "Add",
            onClick: () => {},
          },
          {
            key: "removeFromFavorite",
            text: "Remove",
            onClick: () => {},
          },
        ],
      },
    },
    {
      key: "setStatus", //with submenu
      name: "Set Status",
      iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
      onClick: () => {},
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      subMenuProps: {
        items: [
          {
            key: "inProgress",
            text: "In progress",
            onClick: () => {},
          },
          {
            key: "available",
            text: "Available",
            onClick: () => {},
          },
          {
            key: "reservation",
            text: "Reservation",
            onClick: () => {},
          },
          {
            key: "notAvailable",
            text: "Not available",
            onClick: () => {},
          },
        ],
      },
    },

    {
      key: "startNewDeal",
      name: "Start New Deal",
      iconProps: { iconName: "TextDocument", styles: iconMenuStyles },
      onClick: () => {},
      style:
        isPermitted(
          modulePermissions,
          eTypes.WRITE,
          permissionsModules.Deals,
          user.roles
        ) &&
        isPermitted(
          modulePermissions,
          eTypes.READ,
          permissionsModules.Inventory,
          user.roles
        ) &&
        isPermitted(
          modulePermissions,
          eTypes.READ,
          permissionsModules.Prospects,
          user.roles
        )
          ? null
          : hiddenStyle,
    },
    {
      key: "delete", //with submenu
      iconProps: { iconName: "Delete", styles: iconMenuStyles },
      name: "Delete",
      style: !isPermitted(
        modulePermissions,
        eTypes.DELETE,
        permissionsModules.Inventory,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => onDelete(),
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
    useTargetWidth: false,
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Additional Action"
      menuProps={menuProps}
    />
  );
};
