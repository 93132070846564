import { ICreateDealRecord } from "types/dealTypes";
import {IFinanceProduct, IAppraisalTable, IServiceContract} from "types/dealTypes";

export const HANDLE_GET_SALES_FINANCE_LIST = "HANDLE_GET_SALES_FINANCE_LIST";
export interface handleGetSalesFinanceList {
  type: typeof HANDLE_GET_SALES_FINANCE_LIST;
  payload: any;
}

export const HANDLE_PUT_SALES_FINANCE_LIST = "HANDLE_PUT_SALES_FINANCE_LIST";
export interface handlePutSalesFinanceList {
  type: typeof HANDLE_PUT_SALES_FINANCE_LIST;
  payload: any;
}

export const HANDLE_GET_SELECTED_SALES_FINANCE_LIST = "HANDLE_GET_SELECTED_SALES_FINANCE_LIST";
export interface handleGetSelectedSalesFinanceList {
  type: typeof HANDLE_GET_SELECTED_SALES_FINANCE_LIST;
  payload: any;
}

export const HANDLE_GET_SALES_FINANCE_SUCCESS = "HANDLE_GET_SALES_FINANCE_SUCCESS";
export interface handleGetSalesFinanceSuccess {
  type: typeof HANDLE_GET_SALES_FINANCE_SUCCESS;
  payload: any;
}

export const HANDLE_GET_SELECTED_SALES_FINANCE_SUCCESS = "HANDLE_GET_SELECTED_SALES_FINANCE_SUCCESS";
export interface handleGetSelectedSalesFinanceSuccess {
  type: typeof HANDLE_GET_SELECTED_SALES_FINANCE_SUCCESS;
  payload: any;
}

export const HANDLE_GET_FINANCE_LIST = "HANDLE_GET_FINANCE_LIST";
export interface handleGetFinanceList {
  type: typeof HANDLE_GET_FINANCE_LIST;
  payload: any;
}

export const HANDLE_PUT_FINANCE_LIST = "HANDLE_PUT_FINANCE_LIST";
export interface handlePutFinanceList {
  type: typeof HANDLE_PUT_FINANCE_LIST;
  payload: any;
}


 /*** Search Customer Number ****/

export const HANDLE_SEARCH_DEAL_RECORD = "HANDLE_SEARCH_DEAL_RECORD";

export interface HandleSearchDealRecord {
    type: typeof HANDLE_SEARCH_DEAL_RECORD;
    payload: { deal: any; callback: Function };
}
export const HANDLE_CREATE_DEAL_RECORD = "HANDLE_CREATE_DEAL_RECORD";

export interface HandleCreateDealRecord {
    type: typeof HANDLE_CREATE_DEAL_RECORD;
    payload: { deal: ICreateDealRecord; callback: Function };
}

export const HANDLE_SAVE_DEAL_RECORD = "HANDLE_SAVE_DEAL_RECORD";

export interface HandleSaveDealRecord {
    type: typeof HANDLE_SAVE_DEAL_RECORD;
    payload: { deal: any; callback: Function,id: number | string };
}

/***View  Delivery***/
export const HANDLE_GET_DELIVERY = "HANDLE_GET_DELIVERY";

export interface handleGetDelivery {
    type: typeof HANDLE_GET_DELIVERY;
    payload: any;
}

/***Create or Save  Delivery***/
export const HANDLE_SAVE_DELIVERY = "HANDLE_SAVE_DELIVERY";

export interface HandleSaveDelivery {
    type: typeof HANDLE_SAVE_DELIVERY;
    payload: { deal: any; callback: Function, id: number | string };
}

/** VIEW_Deal */
export const HANDLE_VIEW_DEAL_TRACKER = "HANDLE_VIEW_DEAL_TRACKER";

export interface HandleViewDealTracker {
    type: typeof HANDLE_VIEW_DEAL_TRACKER;
    payload: any;
}

export const HANDLE_VIEW_DEAL_NO_TRACKER = "HANDLE_VIEW_DEAL_NO_TRACKER";

export interface HandleViewDealNoTracker {
    type: typeof HANDLE_VIEW_DEAL_NO_TRACKER;
    payload: any;
}

export const VIEW_DEAL_TRACKER_PENDING = "VIEW_DEAL_TRACKER_PENDING";

export interface ViewDealTrackerPending {
    type: typeof VIEW_DEAL_TRACKER_PENDING;
}

export const VIEW_DEAL_TRACKER_RESOLVED = "VIEW_DEAL_TRACKER_RESOLVED";

export interface ViewDealTrackerResolved {
    type: typeof VIEW_DEAL_TRACKER_RESOLVED;
    payload: any;
}

export const UPDATE_DEL_VEH_TRACKER_RESOLVED = "UPDATE_DEL_VEH_TRACKER_RESOLVED";

export interface UpdateDelVehTrackerResolved {
    type: typeof UPDATE_DEL_VEH_TRACKER_RESOLVED;
    payload: any;
}

export const VIEW_DEAL_TRACKER_REJECTED = "VIEW_DEAL_TRACKER_REJECTED";

export interface ViewDealTrackerRejected {
    type: typeof VIEW_DEAL_TRACKER_REJECTED;
    payload: object;
}

/** Deal List */
export const HANDLE_GET_DEALS_TRACKER = "HANDLE_GET_DEALS_TRACKER";

export interface HandleGetDealsTracker {
    type: typeof HANDLE_GET_DEALS_TRACKER;
}

export const GET_DEALS_TRACKER_PENDING = "GET_DEALS_TRACKER_PENDING";

export interface GetDealsTrackerPending {
    type: typeof GET_DEALS_TRACKER_PENDING;
}

export const GET_DEALS_TRACKER_RESOLVED = "GET_DEALS_TRACKER_RESOLVED";

export interface GetDealsTrackerResolved {
    type: typeof GET_DEALS_TRACKER_RESOLVED;
    payload: any;
}

export const GET_DEALS_TRACKER_REJECTED = "GET_DEALS_TRACKER_REJECTED";

export interface GetDealsTrackerRejected {
    type: typeof GET_DEALS_TRACKER_REJECTED;
    payload: object;
}


/* Delivery Notes*/

export const HANDLE_SAVE_DELIVERY_NOTE = "HANDLE_SAVE_DELIVERY_NOTE";
export interface handleSaveDeliveryNote {
  type: typeof HANDLE_SAVE_DELIVERY_NOTE;
  payload: any;
}

export const HANDLE_GET_DELIVERY_NOTES = "HANDLE_GET_DELIVERY_NOTES";
export interface handleGetDeliveryNotes {
    type: typeof HANDLE_GET_DELIVERY_NOTES;
  payload: any;
}

/* Deals TradeIn Notes*/

export const HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID = "HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID";
export interface handleSaveDealsTradeInNotesUsingID {
  type: typeof HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID;
  payload: any;
}

export const HANDLE_GET_DEALS_TRADEIN_NOTES_ID = "HANDLE_GET_DEALS_TRADEIN_NOTES_ID";
export interface handleGetDealsTradeInNotesUsingID {
  type: typeof HANDLE_GET_DEALS_TRADEIN_NOTES_ID;
  payload: any;
}

/* Deals Appointments Notes*/


export const HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID = "HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID";
export interface HandleSaveDealsAppointmentsNotesUsingID {
  type: typeof HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID;
  payload: any;
}

export const HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID = "HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID";
export interface HandleGetDealsAppointmentsNotesUsingID {
  type: typeof HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID;
  payload: any;
}

export const HANDLE_GET_DEAL_NOTES = "HANDLE_GET_DEAL_NOTES";
export interface HandleGetDealNotes {
  type: typeof HANDLE_GET_DEAL_NOTES;
  payload: any;
}

/* Finance List */
/* GET */
export const HANDLE_GET_FINANCEPRODUCT_LIST = "HANDLE_GET_FINANCEPRODUCT_LIST";

export interface HandleGetFinanceProduct {
  type: typeof HANDLE_GET_FINANCEPRODUCT_LIST;
  payload: any
}

export const GET_FINANCEPRODUCT_LIST_PENDING = "GET_FINANCEPRODUCT_LIST_PENDING";

export interface GetFinanceProductPending {
  type: typeof GET_FINANCEPRODUCT_LIST_PENDING;
}

export const GET_FINANCEPRODUCT_LIST_RESOLVED = "GET_FINANCEPRODUCT_LIST_RESOLVED";

export interface GetFinanceProductResolved {
  type: typeof GET_FINANCEPRODUCT_LIST_RESOLVED;
  payload: {results:IFinanceProduct[]};
}

export const GET_FINANCEPRODUCT_LIST_REJECTED = "GET_FINANCEPRODUCT_LIST_REJECTED";

export interface GetFinanceProductRejected {
  type: typeof GET_FINANCEPRODUCT_LIST_REJECTED;
  payload: object[];
}

/** VIEW */
export const HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM = "HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM";

export interface HandleViewFinanceProductItem {
  type: typeof HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM;
  payload: { id:number; productID:number; formData: IFinanceProduct;callback?: (res) => void };
}



/** UPDATE */
export const HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM = "HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM";

export interface HandleUpdateFinanceProductItem {
  type: typeof HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM;
  payload: { id:number; productID:number; formData: IFinanceProduct;callback?: (res) => void };
}



/** CREATE */
export const HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM = "HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM";

export interface HandleCreateFinanceProductItem {
  type: typeof HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM;
  payload:any;
}

/** DELETE */
export const HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM = "HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM";

export interface HandleDeleteFinanceProductItem {
  type: typeof HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM;
  payload: { id: number; productFinanceListId: number; formData: IFinanceProduct; callback?: (res) => void };
}


/** Display Board */
export const HANDLE_GET_DISPLAYBOARD = "HANDLE_GET_DISPLAYBOARD";

export interface HandleGetDisplayboard {
    type: typeof HANDLE_GET_DISPLAYBOARD;
    payload:any;
}

/** Appointment List */
export const HANDLE_GET_APPOINTMENTS = "HANDLE_GET_APPOINTMENTS";

export interface HandleGetAppointments {
    type: typeof HANDLE_GET_APPOINTMENTS;
}

export const GET_APPOINTMENTS_PENDING = "GET_APPOINTMENTS_PENDING";

export interface GetAppointmentsPending {
    type: typeof GET_APPOINTMENTS_PENDING;
}

export const GET_APPOINTMENTS_RESOLVED = "GET_APPOINTMENTS_RESOLVED";

export interface GetAppointmentsResolved {
    type: typeof GET_APPOINTMENTS_RESOLVED;
    payload: any;
}

export const GET_APPOINTMENTS_REJECTED = "GET_APPOINTMENTS_REJECTED";

export interface GetAppointmentsRejected {
    type: typeof GET_APPOINTMENTS_REJECTED;
    payload: object;
}

/***Get Appointments By Deal***/
export const HANDLE_GET_APPOINTMENTS_BY_DEAL = "HANDLE_GET_APPOINTMENTS_BY_DEAL";
export interface HandleGetAppointmentsByDeal {
    type: typeof HANDLE_GET_APPOINTMENTS_BY_DEAL;
    payload: any;
}

/***Save Appointment***/
export const HANDLE_SAVE_APPOINTMENT = "HANDLE_SAVE_APPOINTMENT";

export interface HandleSaveAppointment {
    type: typeof HANDLE_SAVE_APPOINTMENT;
    payload: { appointment: any; callback: Function, dealId: number | string };
}

/***View Appointment***/
export const HANDLE_GET_APPOINTMENT_INFO = "HANDLE_GET_APPOINTMENT_INFO";
export interface handleGetAppointmentInfo {
    type: typeof HANDLE_GET_APPOINTMENT_INFO;
    payload: any;
}

/** Appraisal Workbook List */
export const HANDLE_GET_APPRAISALS = "HANDLE_GET_APPRAISALS";

export interface HandleGetAppraisals {
    type: typeof HANDLE_GET_APPRAISALS;
}

export const GET_APPRAISALS_PENDING = "GET_APPRAISALS_PENDING";

export interface GetAppraisalsPending {
    type: typeof GET_APPRAISALS_PENDING;
}

export const GET_APPRAISALS_RESOLVED = "GET_APPRAISALS_RESOLVED";

export interface GetAppraisalsResolved {
    type: typeof GET_APPRAISALS_RESOLVED;
    payload: any;
}

export const GET_APPRAISALS_REJECTED = "GET_APPRAISALS_REJECTED";

export interface GetAppraisalsRejected {
    type: typeof GET_APPRAISALS_REJECTED;
    payload: object;
}

export const HANDLE_SELECTED_TRADEIN = "HANDLE_SELECTED_TRADEIN";

export interface HandleSelectedTradeIn {
    type: typeof HANDLE_SELECTED_TRADEIN;
    payload: any;
}

/***Get Appraisal By Deal***/
export const HANDLE_GET_APPRAISALS_BY_DEAL = "HANDLE_GET_APPRAISALS_BY_DEAL";
export interface HandleGetAppraisalsByDeal {
    type: typeof HANDLE_GET_APPRAISALS_BY_DEAL;
    payload: any;
}

/***Save Appraisal***/
export const HANDLE_SAVE_APPRAISAL = "HANDLE_SAVE_APPRAISAL";

export interface HandleSaveAppraisal {
    type: typeof HANDLE_SAVE_APPRAISAL;
    payload: { appraisal: any; callback: Function, dealId: number | string };
}

/***View Appraisal***/
export const HANDLE_GET_APPRAISAL_INFO = "HANDLE_GET_APPRAISAL_INFO";
export interface handleGetAppraisalInfo {
    type: typeof HANDLE_GET_APPRAISAL_INFO;
    payload: any;
}

/***Delete Appraisal***/
export const HANDLE_DELETE_APPRAISAL_ITEM = "HANDLE_DELETE_APPRAISAL_ITEM";
export interface HandleDeleteAppraisalItem {
    type: typeof HANDLE_DELETE_APPRAISAL_ITEM;
    payload: any;
}

/***Existing Vehicle Found***/
export const HANDLE_CREATE_VEHICLE_STOCK_ITEM = "HANDLE_CREATE_VEHICLE_STOCK_ITEM";

export interface HandleCreateVehicleStockItem {
    type: typeof HANDLE_CREATE_VEHICLE_STOCK_ITEM;
    payload: any;
}

export const HANDLE_CREATE_VEHICLE_ORDER_ITEM = "HANDLE_CREATE_VEHICLE_ORDER_ITEM";

export interface HandleCreateVehicleOrderItem {
    type: typeof HANDLE_CREATE_VEHICLE_ORDER_ITEM;
    payload: any;
}

/* Appraisal table List */
/* GET */
export const HANDLE_GET_APPRAISAL_TABLE_LIST = "HANDLE_GET_APPRAISAL_TABLE_LIST";

export interface HandleGetAppraisalTable {
  type: typeof HANDLE_GET_APPRAISAL_TABLE_LIST;
  payload: any
}

export const GET_APPRAISAL_TABLE_LIST_PENDING = "GET_APPRAISAL_TABLE_LIST_PENDING";

export interface GetAppraisalTablePending {
  type: typeof GET_APPRAISAL_TABLE_LIST_PENDING;
}

export const GET_APPRAISAL_TABLE_LIST_RESOLVED = "GET_APPRAISAL_TABLE_LIST_RESOLVED";
export interface GetAppraisalTableResolved {
  type: typeof GET_APPRAISAL_TABLE_LIST_RESOLVED;
  payload: {results:IAppraisalTable[]};
}

export const GET_APPRAISAL_TABLE_LIST_REJECTED = "GET_APPRAISAL_TABLE_LIST_REJECTED";

export interface GetAppraisalTableRejected {
  type: typeof GET_APPRAISAL_TABLE_LIST_REJECTED;
  payload: object[];
}

/** CREATE */
export const HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM = "HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM";

export interface HandleCreateAppraisalTableItem {
  type: typeof HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM;
  payload:any;
}

export const HANDLE_UPDATE_VEHICLE_STOCK_ITEM = "HANDLE_UPDATE_VEHICLE_STOCK_ITEM";

export interface HandleUpdateVehicleStockItem {
    type: typeof HANDLE_UPDATE_VEHICLE_STOCK_ITEM;
    payload: any;
}


/***Get Deal Tracker***/
export const HANDLE_GET_DEAL_TRACKER = "HANDLE_GET_DEAL_TRACKER";
export interface handleGetDealTracker{
    type: typeof HANDLE_GET_DEAL_TRACKER;
    payload: any;
}

/****Service Contract***/
export const HANDLE_GET_SERVICE_CONTRACT = "HANDLE_GET_SERVICE_CONTRACT";

export interface HandleGetServiceContract {
    type: typeof HANDLE_GET_SERVICE_CONTRACT;
}

export const GET_SERVICE_CONTRACT_PENDING = "GET_SERVICE_CONTRACT_PENDING";

export interface GetServiceContractPending {
    type: typeof GET_SERVICE_CONTRACT_PENDING;
}

export const GET_SERVICE_CONTRACT_RESOLVED = "GET_SERVICE_CONTRACT_RESOLVED";

export interface GetServiceContractResolved {
    type: typeof GET_SERVICE_CONTRACT_RESOLVED;
    payload: any;
}

export const GET_SERVICE_CONTRACT_REJECTED = "GET_SERVICE_CONTRACT_REJECTED";

export interface GetServiceContractRejected {
    type: typeof GET_SERVICE_CONTRACT_REJECTED;
    payload: object;
}

/** SERVICE CONTRACT ***/

/***Save ServiceContract***/
export const HANDLE_SAVE_SERVICE_CONTRACT = "HANDLE_SAVE_SERVICE_CONTRACT";

export interface HandleSaveServiceContract {
    type: typeof HANDLE_SAVE_SERVICE_CONTRACT;
    payload: { serviceContract: any; callback: Function, id: number | string };
}



/**** Get Service Contract ***/
export const HANDLE_GET_SERVICE_CONTRACT_LIST = "HANDLE_GET_SERVICE_CONTRACT_LIST";

export interface HandleGetServiceContractList {
  type: typeof HANDLE_GET_SERVICE_CONTRACT_LIST;
  payload: any
}

export const GET_SERVICE_CONTRACT_LIST_PENDING = "GET_SERVICE_CONTRACT_LIST_PENDING";

export interface GetServiceContractListPending {
  type: typeof GET_SERVICE_CONTRACT_LIST_PENDING;
}

export const GET_SERVICE_CONTRACT_LIST_RESOLVED = "GET_SERVICE_CONTRACT_LIST_RESOLVED";

export interface GetServiceContractListResolved {
  type: typeof GET_SERVICE_CONTRACT_LIST_RESOLVED;
  payload: {results:IServiceContract[]};
}

export const GET_SERVICE_CONTRACT_LIST_REJECTED = "GET_SERVICE_CONTRACT_LIST_REJECTED";

export interface GetServiceContractListRejected {
  type: typeof GET_SERVICE_CONTRACT_LIST_REJECTED;
  payload: object[];
}

/** View Service Contract ***/
export const HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM = "HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM";

export interface HandleViewServiceContractItem {
  type: typeof HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM;
  payload: { id:number; productID:number; formData: IServiceContract;callback?: (res) => void };
}

/** Update Service Contract */
export const HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM = "HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM";

export interface HandleUpdateServiceContractItem {
  type: typeof HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM;
  payload: { id:number; productID:number; formData: IServiceContract;callback?: (res) => void };
}



/** Task List */
export const HANDLE_GET_TASKS = "HANDLE_GET_TASKS";
export interface HandleGetTasks {
    type: typeof HANDLE_GET_TASKS;
}

export const GET_TASKS_PENDING = "GET_TASKS_PENDING";

export interface GetTasksPending {
    type: typeof GET_TASKS_PENDING;
}

export const GET_TASKS_RESOLVED = "GET_TASKS_RESOLVED";

export interface GetTasksResolved {
    type: typeof GET_TASKS_RESOLVED;
    payload: any;
}

export const GET_TASKS_REJECTED = "GET_TASKS_REJECTED";

export interface GetTasksRejected {
    type: typeof GET_TASKS_REJECTED;
    payload: object;
}

/***Get Tasks By Deal***/
export const HANDLE_GET_TASKS_BY_DEAL = "HANDLE_GET_TASKS_BY_DEAL";
export interface HandleGetTasksByDeal {
    type: typeof HANDLE_GET_TASKS_BY_DEAL;
    payload: any;
}

/***View Task***/
export const HANDLE_GET_TASK = "HANDLE_GET_TASK";
export interface HandleGetTask {
    type: typeof HANDLE_GET_TASK;
    payload: any;
}

/***Create Task***/
export const HANDLE_CREATE_TASK = "HANDLE_CREATE_TASK";

export interface HandleCreateTask {
    type: typeof HANDLE_CREATE_TASK;
    payload: { task: any; callback: Function, id: number | string };
}

/***Update Task Status***/
export const HANDLE_UPDATE_TASK_STATUS = "HANDLE_UPDATE_TASK_STATUS";

export interface HandleUpdateTaskStatus {
    type: typeof HANDLE_UPDATE_TASK_STATUS;
    payload: { taskStatus: any; callback: Function, id: number | string, taskId: number | string };
}

/*Get Task Notes*/
export const HANDLE_GET_TASK_NOTES = "HANDLE_GET_TASK_NOTES";
export interface HandleGetTaskNotes {
    type: typeof HANDLE_GET_TASK_NOTES;
    payload: any;
}

/*Save Task Note*/
export const HANDLE_SAVE_TASK_NOTE = "HANDLE_SAVE_TASK_NOTE";
export interface HandleSaveTaskNote {
    type: typeof HANDLE_SAVE_TASK_NOTE;
    payload: any;
}

/*Sales Hierarchy*/
export const HANDLE_GET_SALES_HIERARCHY = "HANDLE_GET_SALES_HIERARCHY";
export interface HandleGetSalesHierarchy {
    type: typeof HANDLE_GET_SALES_HIERARCHY;
}

export const GET_SALES_HIERARCHY_PENDING = "GET_SALES_HIERARCHY_PENDING";

export interface GetSalesHierarchyPending {
    type: typeof GET_SALES_HIERARCHY_PENDING;
}

export const GET_SALES_HIERARCHY_RESOLVED = "GET_SALES_HIERARCHY_RESOLVED";

export interface GetSalesHierarchyResolved {
    type: typeof GET_SALES_HIERARCHY_RESOLVED;
    payload: any;
}

export const GET_SALES_HIERARCHY_REJECTED = "GET_SALES_HIERARCHY_REJECTED";

export interface GetSalesHierarchyRejected {
    type: typeof GET_SALES_HIERARCHY_REJECTED;
    payload: object;
}


/***Get Sales Target***/
export const HANDLE_GET_SALES_TARGET = "HANDLE_GET_SALES_TARGET";

export interface HandleGetSalesTarget {
    type: typeof HANDLE_GET_SALES_TARGET;
    payload: { year: string; isFirstTime: boolean; callback: Function };
}

/***Save Sales Target***/
export const HANDLE_SAVE_SALES_TARGET = "HANDLE_SAVE_SALES_TARGET";

export interface HandleSaveSalesTarget {
    type: typeof HANDLE_SAVE_SALES_TARGET;
    payload: { salesTarget: any; callback: Function };
}


/** Cancellation List */
export const HANDLE_GET_CANCELLATIONS = "HANDLE_GET_CANCELLATIONS";
export interface HandleGetCancellations {
    type: typeof HANDLE_GET_CANCELLATIONS;
}

export const GET_CANCELLATIONS_PENDING = "GET_CANCELLATIONS_PENDING";

export interface GetCancellationsPending {
    type: typeof GET_CANCELLATIONS_PENDING;
}

export const GET_CANCELLATIONS_RESOLVED = "GET_CANCELLATIONS_RESOLVED";

export interface GetCancellationsResolved {
    type: typeof GET_CANCELLATIONS_RESOLVED;
    payload: any;
}

export const GET_CANCELLATIONS_REJECTED = "GET_CANCELLATIONS_REJECTED";

export interface GetCancellationsRejected {
    type: typeof GET_CANCELLATIONS_REJECTED;
    payload: object;
}

/** Schedule Changes */
export const HANDLE_GET_ALL_FIN_Del_PERSON = "HANDLE_GET_ALL_FIN_Del_PERSON";
export interface handleGetAllFinDelPerson {
    type: typeof HANDLE_GET_ALL_FIN_Del_PERSON;
    payload: any;
}

export const HANDLE_GET_ALL_FIN_Del_APPT_DATE = "HANDLE_GET_ALL_FIN_Del_APPT_DATE";
export interface handleGetAllFinDelApptDate {
    type: typeof HANDLE_GET_ALL_FIN_Del_APPT_DATE;
    payload: any;
}

export const HANDLE_POST_ALL_FIN_Del_APPT_DATE = "HANDLE_POST_ALL_FIN_Del_APPT_DATE";
export interface handlePostAllFinDelApptDate {
    type: typeof HANDLE_POST_ALL_FIN_Del_APPT_DATE;
    payload: any;
}

export const HANDLE_GET_ALL_FIN_Del_BY_DEAL = "HANDLE_GET_ALL_FIN_Del_BY_DEAL";
export interface handleGetAllFinanceDeliveryByDeal {
    type: typeof HANDLE_GET_ALL_FIN_Del_BY_DEAL;
    payload: any;
}

export const HANDLE_POST_ALL_FIN_Del_DEAL = "HANDLE_POST_ALL_FIN_Del_DEAL";
export interface handlePostAllFinanceDeliveryDeal {
    type: typeof HANDLE_POST_ALL_FIN_Del_DEAL;
    payload: any;
}

export const HANDLE_DEL_ALL_FIN_Del_DEAL = "HANDLE_DEL_ALL_FIN_Del_DEAL";
export interface handleDelAllFinanceDeliveryDeal {
    type: typeof HANDLE_DEL_ALL_FIN_Del_DEAL;
    payload: any;
}

export const HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL = "HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL";
export interface handlePatchDelAllFinanceDeliveryDeal {
    type: typeof HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL;
    payload: any;
}

export const HANDLE_GET_ALL_FIN_Del_SCHEDULE = "HANDLE_GET_ALL_FIN_Del_SCHEDULE";
export interface handleGetAllFinanceDeliverySchedule {
    type: typeof HANDLE_GET_ALL_FIN_Del_SCHEDULE;
    payload: any;
}
/** ENd of Schedule Changes */

/***Get Cancellations By Deal***/
export const HANDLE_GET_CANCELLATIONS_BY_DEAL = "HANDLE_GET_CANCELLATIONS_BY_DEAL";
export interface HandleGetCancellationsByDeal {
    type: typeof HANDLE_GET_CANCELLATIONS_BY_DEAL;
    payload: any;
}

/***Get Cancellation Info***/
export const HANDLE_GET_CANCELLATION_INFO = "HANDLE_GET_CANCELLATION_INFO";
export interface HandleGetCancellationInfo {
    type: typeof HANDLE_GET_CANCELLATION_INFO;
    payload: any;
}

/***Save Cancellation ***/
export const HANDLE_SAVE_CANCELLATION = "HANDLE_SAVE_CANCELLATION";

export interface HandleSaveCancellation{
    type: typeof HANDLE_SAVE_CANCELLATION;
    payload: { formData: any; callback: Function, dealId: number | string };
}

/***Print  Cancellation ***/


export const HANDLE_PRINT_DEAL_CANCELLATION = "HANDLE_PRINT_DEAL_CANCELLATION";

export interface HandlePrintDealCancellation{
    type: typeof HANDLE_PRINT_DEAL_CANCELLATION;
    payload: any;
}

export const HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM = "HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM";

export interface HandlePrintDealCancellationListItem{
    type: typeof HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM;
    payload: any;
}


/***Get Deal Summary***/
export const HANDLE_GET_DEALS_SUMMARY = "HANDLE_GET_DEALS_SUMMARY";
export interface HandleGetDealsSummary {
    type: typeof HANDLE_GET_DEALS_SUMMARY;
    payload: any;
}

/***Vehicle Preps***/
export const HANDLE_GET_PREPS = "HANDLE_GET_PREPS";
export interface HandleGetPreps {
    type: typeof HANDLE_GET_PREPS;
    payload: any;
}

export const HANDLE_GET_PREPS_BY_DEAL = "HANDLE_GET_PREPS_BY_DEAL";
export interface HandleGetPrepsByDeal {
    type: typeof HANDLE_GET_PREPS_BY_DEAL;
    payload: any;
}

export const HANDLE_GET_PREPS_INFO = "HANDLE_GET_PREPS_INFO";
export interface HandleGetPrepsInfo {
    type: typeof HANDLE_GET_PREPS_INFO;
    payload: any;
}

export const HANDLE_SAVE_PREPS = "HANDLE_SAVE_PREPS";
export interface HandleSavePreps {
    type: typeof HANDLE_SAVE_PREPS;
    payload: any;
}

export const HANDLE_SAVE_PREPS_BY_DEAL = "HANDLE_SAVE_PREPS_BY_DEAL";
export interface HandleSavePrepsByDeal {
    type: typeof HANDLE_SAVE_PREPS_BY_DEAL;
    payload: any;
}

export const HANDLE_DELETE_PREPS_INFO = "HANDLE_DELETE_PREPS_INFO";
export interface HandleDeletePrepsInfo {
    type: typeof HANDLE_DELETE_PREPS_INFO;
    payload: any;
}
/***End of Vehicle Preps***/

/***Dealer Trades***/
export const HANDLE_GET_DEALER_TRADES = "HANDLE_GET_DEALER_TRADES";
export interface HandleGetDealerTrades {
    type: typeof HANDLE_GET_DEALER_TRADES;
    payload: any;
}

export const HANDLE_GET_DEALER_TRADES_V2 = "HANDLE_GET_DEALER_TRADES_V2";
export interface HandleGetDealerTradesV2 {
    type: typeof HANDLE_GET_DEALER_TRADES_V2;
    payload: any;
}

export const HANDLE_GET__DEALER_TRADE_INFO = "HANDLE_GET__DEALER_TRADE_INFO";
export interface HandleGetDealerTradeInfo {
    type: typeof HANDLE_GET__DEALER_TRADE_INFO;
    payload: any;
}

export const HANDLE_SAVE_DEALER_TRADE = "HANDLE_SAVE_DEALER_TRADE";
export interface HandleSaveDealerTrade {
    type: typeof HANDLE_SAVE_DEALER_TRADE;
    payload: any;
}
/***End of Dealer Trades***/

/***Dealer Trades Vehicle***/
export const HANDLE_GET_DT_VEHICLE_TRIM_VIN = "HANDLE_GET_DT_VEHICLE_TRIM_VIN";
export interface HandleGetDTVehicleTrimUsingVin {
    type: typeof HANDLE_GET_DT_VEHICLE_TRIM_VIN;
    payload: any;
}

export const HANDLE_GET_DT_VEHICLE_TRIM_YMM = "HANDLE_GET_DT_VEHICLE_TRIM_YMM";
export interface HandleGetDTVehicleTrimUsingYmm {
    type: typeof HANDLE_GET_DT_VEHICLE_TRIM_YMM;
    payload: any;
}

export const HANDLE_GET_DT_VEHICLE_DETAILS = "HANDLE_GET_DT_VEHICLE_DETAILS";
export interface HandleGetDTVehicleDetails {
    type: typeof HANDLE_GET_DT_VEHICLE_DETAILS;
    payload: any;
}

export const HANDLE_CREATE_DT_VEHICLE_STOCK = "HANDLE_CREATE_DT_VEHICLE_STOCK";

export interface HandleCreateDTVehicleStock {
    type: typeof HANDLE_CREATE_DT_VEHICLE_STOCK;
    payload: any;
}

export const HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM = "HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM";

export interface HandleUpdateDTVehicleStockItem {
    type: typeof HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM;
    payload: any;
}

export const HANDLE_VEHICLE_TRACKER_STOCK_CHECK = "HANDLE_VEHICLE_TRACKER_STOCK_CHECK";

export interface HandleVehicleTrackerCheckStock {
    type: typeof HANDLE_VEHICLE_TRACKER_STOCK_CHECK;
    payload: any;
}


export const HANDLE_CHECK_VEHICLE_STOCK = "HANDLE_CHECK_VEHICLE_STOCK";

export interface HandleCheckVehicleStock {
    type: typeof HANDLE_CHECK_VEHICLE_STOCK;
    payload: any;
}

export const HANDLE_CHECK_VEHICLE_ORDER = "HANDLE_CHECK_VEHICLE_ORDER";

export interface HandleCheckVehicleOrder {
    type: typeof HANDLE_CHECK_VEHICLE_ORDER;
    payload: any;
}

export const HANDLE_DEALER_TRADE_CHECK = "HANDLE_DEALER_TRADE_CHECK";

export interface HandleDealerTradeCheck {
    type: typeof HANDLE_DEALER_TRADE_CHECK;
    payload: any;
}

export const HANDLE_CHECK_DT_VEHICLE_STOCK = "HANDLE_CHECK_DT_VEHICLE_STOCK";

export interface HandleCheckDTVehicleStock {
    type: typeof HANDLE_CHECK_DT_VEHICLE_STOCK;
    payload: any;
}

export const HANDLE_CHECK_DT_VEHICLE_ORDER = "HANDLE_CHECK_DT_VEHICLE_ORDER";

export interface HandleCheckDTVehicleOrder {
    type: typeof HANDLE_CHECK_DT_VEHICLE_ORDER;
    payload: any;
}
/***Reports***/

export const HANDLE_GET_FINNANCE_DESKING_REPORTS = "HANDLE_GET_FINNANCE_DESKING_REPORTS";

export interface HandleGetFinnaceDeskingReports {
    type: typeof HANDLE_GET_FINNANCE_DESKING_REPORTS;
    payload: any;
}

export const HANDLE_GET_LENDER_COUNT_REPORTS = "HANDLE_GET_LENDER_COUNT_REPORTS";

export interface HandleGetLenderCountReports {
    type: typeof HANDLE_GET_LENDER_COUNT_REPORTS;
    payload: any;
}

export const HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS = "HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS";

export interface HandleGetFinanceProductSalesReports { 
    type: typeof HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS;
    payload: any;
}

export const HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS = "HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS";

export interface HandleGetFinanceReserveSplitsReports {
    type: typeof HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS;
    payload: any;
}

export const HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS = "HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS";

export interface HandleGetFinanceProductSplitsReports { 
    type: typeof HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS;
    payload: any;
}

export const HANDLE_GET_KARL_DOC_REPORTS = "HANDLE_GET_KARL_DOC_REPORTS";

export interface HandleGetKarlDocReports { 
    type: typeof HANDLE_GET_KARL_DOC_REPORTS;
    payload: any;
}

export const HANDLE_GET_PER_CAR_TOTALS = "HANDLE_GET_PER_CAR_TOTALS";

export interface HandleGetPerCarTotals { 
    type: typeof HANDLE_GET_PER_CAR_TOTALS;
    payload: any;
}

export const HANDLE_GET_DASHBOARD_MIKE_REPORTS = "HANDLE_GET_DASHBOARD_MIKE_REPORTS";

export interface HandleGetDashboardMikeReports { 
    type: typeof HANDLE_GET_DASHBOARD_MIKE_REPORTS;
    payload: any;
}

export const HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS = "HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS";

export interface HandleGetFinancePersonStackRankReports { 
    type: typeof HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS;
    payload: any;
}

export const HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS = "HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS";

export interface HandleGetFinancePersonMonthEndReports { 
    type: typeof HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS;
    payload: any;
}

export const HANDLE_GET_VACATION_REPORTS = "HANDLE_GET_VACATION_REPORTS";

export interface HandleGetVacationReports {
    type: typeof HANDLE_GET_VACATION_REPORTS;
    payload: any;
}

export const HANDLE_GET_SALES_PERSON_FINANCE_REPORTS = "HANDLE_GET_SALES_PERSON_FINANCE_REPORTS";

export interface HandleGetSalesPersonFinanceReports { 
    type: typeof HANDLE_GET_SALES_PERSON_FINANCE_REPORTS;
    payload: any;
}

export const HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS = "HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS";

export interface HandleGetSalesPersonMonthEndReports { 
    type: typeof HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS;
    payload: any;
}

export const HANDLE_GET_CDR_CPO_REPORTS = "HANDLE_GET_CDR_CPO_REPORTS";

export interface HandleGetCdrCpoReports {
    type: typeof HANDLE_GET_CDR_CPO_REPORTS;
    payload: any;
}

/***KPI Stats***/
export const HANDLE_GET_DEALS_KPI_STATS = "HANDLE_GET_DEALS_KPI_STATS";

export interface HandleGetDealsKPIStats {
    type: typeof HANDLE_GET_DEALS_KPI_STATS;
    payload: any;
}

/** Pending deals Print **/

export const HANDLE_PRINT_PENDING_DEAL = "HANDLE_PRINT_PENDING_DEAL";

export interface HandlePrintPendingDeal{
    type: typeof HANDLE_PRINT_PENDING_DEAL;
    payload: any;
}

/***WriteUps List***/
export const HANDLE_GET_WRITEUPS_LIST = "HANDLE_GET_WRITEUPS_LIST";

export interface HandleGetWriteUpsList {
    type: typeof HANDLE_GET_WRITEUPS_LIST;
    payload: any;
}

/***Appraisal List***/
export const HANDLE_GET_APPRAISALS_LIST = "HANDLE_GET_APPRAISALS_LIST";

export interface HandleGetAppraisalList {
    type: typeof HANDLE_GET_APPRAISALS_LIST;
    payload: any;
}

/***Appointment List***/
export const HANDLE_GET_APPOINTMENT_LIST = "HANDLE_GET_APPOINTMENT_LIST";

export interface HandleGetAppointmentList {
    type: typeof HANDLE_GET_APPOINTMENT_LIST;
    payload: any;
}


/***Service Contract List***/
export const HANDLE_GET_SERVICE_CONTRACT_LISTS = "HANDLE_GET_SERVICE_CONTRACT_LISTS";

export interface HandleGetServiceContractLists {
    type: typeof HANDLE_GET_SERVICE_CONTRACT_LISTS;
    payload: any;
}

/***Cancellation List***/
export const HANDLE_GET_CANCELLATION_LISTS = "HANDLE_GET_CANCELLATION_LISTS";

export interface HandleGetCancellationLists {
    type: typeof HANDLE_GET_CANCELLATION_LISTS;
    payload: any;
}

/***Finance Detail Report***/
export const HANDLE_GET_FINANCE_DETAIL_REPORTS = "HANDLE_GET_FINANCE_DETAIL_REPORTS";

export interface HandleGetFinanceDetailReports {
    type: typeof HANDLE_GET_FINANCE_DETAIL_REPORTS;
    payload: any;
}

/** Finance Detail Print pdf **/

export const HANDLE_PRINT_FINANCE_DETAIL_REPORTS = "HANDLE_PRINT_FINANCE_DETAIL_REPORTS";

export interface HandlePrintFinanceDetailReports{
    type: typeof HANDLE_PRINT_FINANCE_DETAIL_REPORTS;
    payload: any;
}

/***Task List***/
export const HANDLE_GET_TASK_LIST = "HANDLE_GET_TASK_LIST";

export interface HandleGetTaskList {
    type: typeof HANDLE_GET_TASK_LIST;
    payload: any;
}

/***Sales Pending Report***/
export const HANDLE_GET_SALES_PENDING_REPORT = "HANDLE_GET_SALES_PENDING_REPORT";

export interface HandleGetSalesPendingReport {
    type: typeof HANDLE_GET_SALES_PENDING_REPORT;
    payload: any;
}

/** Sales Pending Print pdf **/

export const HANDLE_PRINT_SALES_PENDING_REPORTS = "HANDLE_PRINT_SALES_PENDING_REPORTS";

export interface HandlePrintSalesPendingReports{
    type: typeof HANDLE_PRINT_SALES_PENDING_REPORTS;
    payload: any;
}

/***Get Deal Summary***/
export const HANDLE_GET_DEAL_TRACKER_SUMMARY = "HANDLE_GET_DEAL_TRACKER_SUMMARY";

export interface HandleGetDealTrackerSummary {
    type: typeof HANDLE_GET_DEAL_TRACKER_SUMMARY;
    payload: any;
}

/***Finance Pending Report List***/
export const HANDLE_GET_FINANCE_PENDING_REPORT = "HANDLE_GET_FINANCE_PENDING_REPORT";

export interface HandleGetFinancePendingReport {
    type: typeof HANDLE_GET_FINANCE_PENDING_REPORT;
    payload: any;
}

export const HANDLE_PRINT_FINANCE_PENDING_REPORT = "HANDLE_PRINT_FINANCE_PENDING_REPORT";

export interface HandlePrintFinancePendingReport {
    type: typeof HANDLE_PRINT_FINANCE_PENDING_REPORT;
    payload: any;
}

/** Vehicle Tracker **/

export const HANDLE_CREATE_VEHICLE_TRACKER_LIST = "HANDLE_CREATE_VEHICLE_TRACKER_LIST";

export interface HandleCreateVehicleTrackerList {
    type: typeof HANDLE_CREATE_VEHICLE_TRACKER_LIST;
    payload: any;
}

/***Get vehicle tracker***/
export const HANDLE_GET_VEHICLE_TRACKER_LIST = "HANDLE_GET_VEHICLE_TRACKER_LIST";

export interface HandleGetVehicleTrackerList {
    type: typeof HANDLE_GET_VEHICLE_TRACKER_LIST;
    payload: any;
}
/** Vehicle Tracker **/

export const HANDLE_CREATE_VEHICLE_TRANSFER_LIST = "HANDLE_CREATE_VEHICLE_TRANSFER_LIST";

export interface HandleCreateVehicleTransferList {
    type: typeof HANDLE_CREATE_VEHICLE_TRANSFER_LIST;
    payload: any;
}

export const HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER = "HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER";

export interface HandleViewVehicleTrackerTransfer {
    type: typeof HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER;
    payload: any;
}

export const HANDLE_UPDATE_VEHICLE_TRANSFER_LIST = "HANDLE_UPDATE_VEHICLE_TRANSFER_LIST";

export interface HandleUpdateVehicleTransferList {
    type: typeof HANDLE_UPDATE_VEHICLE_TRANSFER_LIST;
    payload: any;
}

export const HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST = "HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST";

export interface HandleViewVehicleTransferList {
    type: typeof HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST;
    payload: any;
}

/*** Vehicle transfer note**/

export const HANDLE_CREATE_VEHICLE_TRANSFER_NOTE = "HANDLE_CREATE_VEHICLE_TRANSFER_NOTE";

export interface HandleCreateVehicleTransferNote {
    type: typeof HANDLE_CREATE_VEHICLE_TRANSFER_NOTE;
    payload: any;
}

export const HANDLE_GET_VEHICLE_TRANSFER_NOTE = "HANDLE_GET_VEHICLE_TRANSFER_NOTE";

export interface HandleGetVehicleTransferNote {
    type: typeof HANDLE_GET_VEHICLE_TRANSFER_NOTE;
    payload: any;
}

export const HANDLE_VIEW_VEHICLE_TRACKER = "HANDLE_VIEW_VEHICLE_TRACKER";

export interface HandleViewVehicleTracker {
    type: typeof HANDLE_VIEW_VEHICLE_TRACKER;
    payload: any;
}


/*** sold transfer **/

export const HANDLE_SOLD_VEHICLE_TRANSFER = "HANDLE_SOLD_VEHICLE_TRANSFER";

export interface HandleSoldVehicleTransfer {
    type: typeof HANDLE_SOLD_VEHICLE_TRANSFER;
    payload: any;
}


export const HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK = "HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK";

export interface HandleVehicleTrackerWriteUpCheck {
    type: typeof HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK;
    payload: any;
}

export type DealTrackerActions =
    | handleGetSalesFinanceList
    | handlePutSalesFinanceList
    | handleGetSalesFinanceSuccess
    | handleGetSelectedSalesFinanceSuccess
    | handlePutFinanceList
    | HandleCreateDealRecord
    | HandleSaveDealRecord
    | HandleViewDealTracker
    | HandleViewDealNoTracker
    | ViewDealTrackerPending
    | ViewDealTrackerResolved
    | UpdateDelVehTrackerResolved
    | ViewDealTrackerRejected
    | handleSaveDeliveryNote
    | handleGetDeliveryNotes
    | handleSaveDealsTradeInNotesUsingID
    | handleGetDealsTradeInNotesUsingID
    | ViewDealTrackerRejected
    | HandleGetDealsTracker
    | GetDealsTrackerPending
    | GetDealsTrackerRejected
    | GetDealsTrackerResolved
    | HandleGetFinanceProduct
    | GetFinanceProductPending
    | GetFinanceProductResolved
    | GetFinanceProductRejected
    | HandleViewFinanceProductItem
    | HandleUpdateFinanceProductItem
    | HandleCreateFinanceProductItem
    | HandleDeleteFinanceProductItem
    | HandleGetAppointments
    | GetAppointmentsPending
    | GetAppointmentsResolved
    | GetAppointmentsRejected
    | HandleSaveAppointment
    | handleGetAppointmentInfo
    | HandleGetAppointmentsByDeal
    | HandleGetAppraisals
    | GetAppraisalsPending
    | GetAppraisalsResolved
    | GetAppraisalsRejected
    | HandleSaveAppraisal
    | handleGetAppraisalInfo
    | HandleDeleteAppraisalItem
    | HandleGetAppraisalsByDeal
    | HandleCreateVehicleStockItem
    | HandleGetAppraisalTable
    | GetAppraisalTablePending
    | GetAppraisalTableResolved
    | GetAppraisalTableRejected
    | HandleSelectedTradeIn
    | HandleUpdateVehicleStockItem
    | handleGetDealTracker
    | HandleGetServiceContract
    | GetServiceContractPending
    | GetServiceContractResolved
    | GetServiceContractRejected
    | HandleSaveServiceContract
    | HandleGetServiceContractList
    | GetServiceContractListPending
    | GetServiceContractListResolved
    | GetServiceContractListRejected
    | HandleViewServiceContractItem
    | HandleUpdateServiceContractItem
    | HandleGetTasks
    | GetTasksPending
    | GetTasksResolved
    | GetTasksRejected
    | HandleGetTask
    | HandleCreateTask
    | HandleUpdateTaskStatus
    | HandleGetTaskNotes
    | HandleSaveTaskNote
    | HandleGetSalesHierarchy
    | GetSalesHierarchyPending
    | GetSalesHierarchyResolved
    | GetSalesHierarchyRejected
    | HandleSaveSalesTarget
    | HandleGetCancellations
    | GetCancellationsPending
    | GetCancellationsResolved
    | GetCancellationsRejected
    | handleGetAllFinDelPerson
    | handleGetAllFinDelApptDate
    | handlePostAllFinDelApptDate
    | handleGetAllFinanceDeliveryByDeal
    | handlePostAllFinanceDeliveryDeal
    | handleDelAllFinanceDeliveryDeal
    | handlePatchDelAllFinanceDeliveryDeal
    | handleGetAllFinanceDeliverySchedule
    | HandleGetCancellationsByDeal
    | HandleGetCancellationInfo
    | HandleSaveCancellation
    | HandleSearchDealRecord
    | HandlePrintDealCancellationListItem
    | HandlePrintDealCancellation
    | HandleSearchDealRecord
    | HandleGetDealsSummary
    | HandleGetPreps
    | HandleGetPrepsByDeal
    | HandleSavePreps
    | HandleSavePrepsByDeal
    | HandleGetPrepsInfo
    | HandleGetDealerTrades
    | HandleGetDealerTradesV2
    | HandleGetDealerTradeInfo
    | HandleSaveDealerTrade
    | HandleGetDTVehicleTrimUsingVin
    | HandleGetDTVehicleTrimUsingYmm
    | HandleGetDTVehicleDetails
    | HandleCreateDTVehicleStock
    | HandleUpdateDTVehicleStockItem
    | HandleCheckVehicleStock
    | HandleVehicleTrackerCheckStock
    | HandleCheckVehicleOrder
    | HandleGetFinnaceDeskingReports
    | HandleGetLenderCountReports
    | HandleGetFinanceProductSalesReports
    | HandleGetDealsKPIStats
    | HandleGetFinanceReserveSplitsReports
    | HandleGetFinanceProductSplitsReports
    | HandleGetKarlDocReports
    | HandleGetPerCarTotals
    | HandleGetDashboardMikeReports
    | HandleGetFinancePersonStackRankReports
    | HandleGetSalesPersonFinanceReports
    | HandleGetFinancePersonMonthEndReports
    | HandleGetCdrCpoReports
    | HandleGetDisplayboard
    | HandlePrintPendingDeal
    | HandleGetWriteUpsList
    | HandleGetAppraisalList
    | HandleGetAppointmentList
    | HandleGetServiceContractLists
    | HandleGetCancellationLists
    | HandleGetFinanceDetailReports
    | HandlePrintFinanceDetailReports
    | HandlePrintFinancePendingReport
    | HandlePrintSalesPendingReports
    | HandleGetTaskList
    | HandleGetFinancePendingReport
    | HandleGetSalesPendingReport
    | HandleGetDealTrackerSummary
    | HandleCreateVehicleTrackerList
    | HandleGetVehicleTrackerList
    | HandleCreateVehicleTransferList
    | HandleUpdateVehicleTransferList
    | HandleViewVehicleTransferList
    | HandleCreateVehicleTransferNote
    | HandleGetVehicleTransferNote
    | HandleSoldVehicleTransfer
    | HandleViewVehicleTracker
    | HandleVehicleTrackerWriteUpCheck
    | HandleGetDealsAppointmentsNotesUsingID
    | HandleSaveDealsAppointmentsNotesUsingID
    | HandleGetDealNotes
    | HandleDealerTradeCheck;




