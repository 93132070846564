import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    //TextField,
    PrimaryButton,
    Link
} from "@fluentui/react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { isUserMaster, isKarlProfile, getModulePermissions } from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetCancellations } from "store/dealTracker/dealTracker.action";
import { handleGetCancellationLists } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action"; 
import {
    convertUTCToLocalDateModified,
    //convertUTCToLocalModified,
    //convertUTCToLocalMoment
} from "utils/dateTimeFunctions";
import { useNavigate , useLocation } from "react-router-dom";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { useMediaQuery } from "react-responsive";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from "moment";
import { Paginator } from 'primereact/paginator';
import { DebounceInput } from 'react-debounce-input';

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "DealTracker";
export const CancellationWorkbookV2Page = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 

    const [cancellationData, setCancellationData] = useState(null);
    const [commonServerError, setcommonServerError] = useState(null);
    const [locationFilter, setLocationFilter] = useState(0);
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [statesFlag, setStatesFlag] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('logDate');
    //const [filteredData, setFilteredData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(null);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
    const [statePageNumber, setStatePageNumber] = useState(1);

    const navigate = useNavigate ();

    const isMobile = useMediaQuery({ maxWidth: 960 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);


    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);
    const [glFilter, setGLFilter] = useState('');

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
        //console.log('locationslocationslocations',locations)   
    }, [locations]);


    useEffect(() => {
        
        if (user && user.roles) {
            setUserProfileLoadingFlag(true)
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateData = (cancellations) => {
        if (cancellations) {
            //let hiddenStr;
            for (var i in cancellations) {
                let deal = cancellations[i].deal;
                //hiddenStr = '';
                cancellations[i]["logDate"] = convertUTCToLocalDateModified(cancellations[i].createdAt);
                cancellations[i]["dealNumber"] = deal.dealNumber;
                cancellations[i]["customer"] = getCustomerName(deal?.customer);
                cancellations[i]["financeProduct"] = cancellations[i].financeProduct?.name;
                cancellations[i]["cancelDate"] = convertUTCToLocalDateModified(cancellations[i].cancelDate);
                cancellations[i]["financePersonCancelling"] = getFinancePersonName(cancellations[i].financePersonCancelling);
                cancellations[i]["refundMethod"] = getMethodName(cancellations[i].refundMethod1, cancellations[i].refundMethod2);
                cancellations[i]["refundAmount"] = cancellations[i].refundAmount && currencyNegValFractionalFormat(cancellations[i].refundAmount);
                cancellations[i]["refundDate"] = cancellations[i].refundAmount && convertUTCToLocalDateModified(cancellations[i].refundDate);
                cancellations[i]["dealId"] = deal.id;

                //if (deal.vehicle)
                //    hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
                //if (deal.salesPerson1)
                //    hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
                //if (deal.salesPerson2)
                //    hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
                //if (deal.financePerson1)
                //    hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
                //if (deal.financePerson2)
                //    hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
                //hiddenStr = hiddenStr + ' ' + deal?.customer?.customerNumber;
                //cancellations[i]["hiddenCol"] = hiddenStr;
              }
	        	 return cancellations;
            }
	        return []
	};



    const getMethodName = (method1, method2) => {
        if (method1 === 1) {
            if (method2 === 1)
                return 'Applied to Deal';
            if (method2 === 2)
                return 'Karl to Customer';
            if (method2 === 3)
                return 'Karl to Lender';
        }
        else if (method1 === 2) {
            return 'Product Co. to Customer';
        }
        else if (method1 === 3) {
            return 'Product Co. to Lender';
        }
        else if (method1 === 4) {
            return 'Lender Debits Dealer';
        }
        return '';
    };

    const getCustomerName = (customer) => {
        if (customer) {
            if (customer.customerType === 1) {
                return customer?.firstName + ' ' + customer?.lastName;
            }
            return customer?.businessName;
        }
        return '';
    }

    const getFinancePersonName = (person) => {
        if (person) {
            return person?.firstName + ' ' + person?.lastName;
        }
        return '';
    }

    useEffect(() => {
        setSortOrder(-1);
        setSortField('logDate');
        setStatesFlag(null);
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetCancellations())
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchChange = (e) => {
        const inputValue = e.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }

        if(e.target.value === ''){
			setGLFilter('');
	    }
    }

    const handleLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
        
    }

    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
       
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
       
    };

    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const cancellationList =(isFilterApplied)=>{       
	     const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
                sort: sortOrder === 1 ? 'ASC' : 'DESC',
                column: sortField,
                locationId:locationFilter?locationFilter:0,
                fromCancelDate: logDateFromFilter ? moment(logDateFromFilter).format('YYYY-MM-DD'):'',
                toCancelDate: logDateToFilter ? moment(logDateToFilter).format('YYYY-MM-DD') : '',
                search: glFilter
            };
            setFilters(formData);
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetCancellationLists({
                formData,
                callback: (response) => {
                    if (response) {
                        setTotalRecords(response.totalItems);
                        const data = handlePopulateData(response.results);
                        setCancellationData(data);
                    } else {
                        setCancellationData(null)
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}
    



	const getCancel = (isFilterApplied = false) => {
        setcommonServerError('');
        if (statesFlag && userProfileLoadingFlag) {
             cancellationList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getCancel(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glFilter,locationFilter,logDateFromFilter,logDateToFilter,statesFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getCancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);


    const handleResetWorkbook = () => {
        setGLFilter('');
        setLocationFilter(primaryLocation);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setSortOrder(-1);
        setLimit(25);
        setPages(1);
        setSortField('logDate');

        //if (primaryLocation) {
        //    const results = cancellations.filter(x => x.deal.location.id === primaryLocation);
        //    setCancellationData(results);
        //} else {
        //    setCancellationData(cancellations);
        //}
    };


    const exportExcel = () => {
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetCancellationLists({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
                                   
				                     ({
									 "Log Date": convertUTCToLocalDateModified(item.createdAt),
                                     "Deal #": item.deal.dealNumber,
                                     "Customer": getCustomerName(item.deal?.customer),
                                     "Product Name": item.financeProduct?.name,
                                     "Finance Person Cancelling": getFinancePersonName(item.financePersonCancelling),
                                     "Method": getMethodName(item.refundMethod1, item.refundMethod2),
                                     "Cancel Date": convertUTCToLocalDateModified(item.cancelDate),
                                     "Refund Date": item.refundAmount && convertUTCToLocalDateModified(item.refundDate),
                                     "Refund Amount": item.refundAmount
				                   }));
				                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'Cancellation-Workbook');
                                   });
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    //Datatable Templates
    const logDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title"> Log Date</span>
                {moment(new Date(rowData.logDate)).local().format("MM/DD/YYYY")}
            </React.Fragment>
        );
    };


    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal #</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };

    const customerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customer}
            </React.Fragment>
        );
    };

    const productNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product Name</span>
                {rowData.financeProduct}
            </React.Fragment>
        );
    };

    const cancellationDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cancel Date</span>
                {rowData.cancelDate}
            </React.Fragment>
        );
    };

    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person Cancelling</span>
                {rowData.financePersonCancelling}
            </React.Fragment>
        );
    };

    const refundAmountBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Refund Amount</span>
                {rowData.refundAmount}
            </React.Fragment>
        );
    };

    const refundDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Refund Date</span>
                {rowData.refundDate}
            </React.Fragment>
        );
    };

    const methodBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Method</span>
                {rowData.refundMethod}
            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(id) => handleEditcancellation(id)}
                    />
                )}


            </React.Fragment>
        );
    };

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

    const header = (
        <>
            {isMobile ?
                <div>
                    <Row> 
                        <Col style={{ marginTop: "2px" }}>
                            <div className="locationDropPosition">
                                {/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
                                <label className="lbl-text">Search</label>
                                <div className="ms-TextField-fieldGroup debounce-input-group">
                                    <DebounceInput
                                        minLength={1}
                                        className="ms-TextField-field"
                                        value={glFilter}
                                        debounceTimeout={750}
                                        onChange={(e) => handleSearchChange(e)} />
                                </div>
                            </div>
			         	</Col>
                        <Col>
                            <div className="locationDropPosition">
                                <label className="lbl-text" style={{ paddingBottom: "0px" }}>Location</label>
                                <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: "auto" }} >
                        <Col>
                            <div className="date-filter-section">
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Cancel Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                maxDate={logDateToFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                minDate={logDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                            <PrimaryButton style={{ float: "right" }}
                                onClick={handleResetWorkbook}
                            >
                                Reset
                               </PrimaryButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="display-row" style={{ marginTop: "15px" }}>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                            <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
						    </Link>
                        </Col>
                    </Row>
                </div> :
                <div>
                    <div>
                        <Row>
                        <Col md={10}>
                            <Row>
                                
                                <Col  md={3}>
			                          <div className="table-header employeeeSearchLabelText">
			                          	{/*<TextField
			                          		value={glFilter}
			                          		type="search"
			                          		label="Search Appraisals"
			                          		className="ownerSearchText"
			                          		onChange={(e) => handleFirstNameFilter(e)}
			                          	/>*/}
						        		  <label className="lbl-text">Search</label>
                                          <div className="ms-TextField-fieldGroup debounce-input-group">
                                              <DebounceInput
                                                  minLength={1}
                                                  className="ms-TextField-field"
                                                  value={glFilter}
                                                  debounceTimeout={750}
                                                  onChange={(e) => handleSearchChange(e)} />
                                          </div>
			                          </div>
			         	        </Col>
                                <Col md={3}>
                                    <div className="dd-control">
                                        <label className="lbl-text">Location</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={locationFilter}
                                            options={cityDealerList}
                                            onChange={handleLocationChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Cancel Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="fromLogDate"
                                                        style={{ width: "100%" }}
                                                        value={logDateFromFilter}
                                                        showIcon
                                                        onChange={(e) => handleFromLogDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={logDateToFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="toLogDate"
                                                        style={{ width: "100%" }}
                                                        value={logDateToFilter}
                                                        showIcon
                                                        onChange={(e) => handleToLogDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={logDateFromFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div className="table-header employeeeSearchLabelText" style={{ marginTop: "24px" }}>
                                        <PrimaryButton
                                            onClick={handleResetWorkbook}
                                        >
                                            Reset
                               </PrimaryButton>
                                    </div>

                                </Col>

                            </Row>
                        </Col>
                        <Col>

                        </Col>

                        </Row>
                    </div>
                    <div className="display-row total-export-row">
                        <div>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                        </div>
                        <div>
                            <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
		                    </Link>
                        </div>
                    </div>
                </div>}

        </>
    );

    const handleEditcancellation = (id) => {
        const selectedItem = cancellationData.find(x => x.id === id);
        if (selectedItem) {
            handleNavigationToCancellation(selectedItem.dealId, id);
        }
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role || isKarlProfile(user.profileType)) {
            handleNavigationToCancellation(event.data.dealId, event.data.id);
            //}
        }
    };

    const handleNavigationToCancellation = (dealId, id) => {
        const  state = {
            searchInput: glFilter,
            pathfrom: '/cancellations',
            tabIndex: 3,
            productId:`${id}`,
            workbookName: 'cancellation',
            sortOrder,
            sortField,
            logDateFromFilter,
            logDateToFilter,
            locationFilter
        }
        navigate(`/${dealId}/dealtracker`,{state});
    };

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    useEffect(() => {

        setStatesFlag(true);
        const stateVal: any = location?.state
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
        }

        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }

        if(primaryLocation === 0 && stateVal?.locationFilter === 0){
            setLocationFilter(0);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(new Date(stateVal.logDateToFilter));
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) * stateVal.limit);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const table_columns = {
        "logDate": "Log Date",
        "dealNumber": "Deal #",
        "customer": "Customer",
        "financeProduct": "Product Name",
        "financePersonCancelling": "Finance Person Cancelling",
        "refundMethod":"Method",
        "cancelDate": "Cancel Date",
        "refundDate": "Refund Date",
        "refundAmount": "Refund Amount",
        "setting": "Action",
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }


    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Cancellations</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <Row>
                    <Col md={12}>
                        <div className="datatable-filter-demo noMaxWidthTable gl-cancellation-workbook">
                            <div className="lazy-loading-workbook card mouse-hover" >
                                <CustomTable
                                    column_list={table_columns}
                                    data={cancellationData}
                                    paginator={false}
                                    //rows={25}
                                    //rowsPerPageOptions={[25,50,100]}
                                    header={header}
                                    footer={footer}
                                    style={{ width: "100%" }}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    onSort={handleColumnSort}
                                    lazy
                                    //onValueChange={filteredData => setFilteredData(filteredData)}
                                    customTemplates={{
                                        "logDate": logDateBodyTemplate,
                                        "dealNumber": dealNumberBodyTemplate,
                                        "customer": customerNameBodyTemplate,
                                        "financeProduct": productNameBodyTemplate,
                                        "cancelDate": cancellationDateBodyTemplate,
                                        "financePersonCancelling": financePersonBodyTemplate,
                                        "refundMethod": methodBodyTemplate,
                                        "refundAmount": refundAmountBodyTemplate,
                                        "refundDate": refundDateBodyTemplate,
                                        "setting": settingBodyTemplate
                                    }}
                                    emptyMessage="No cancellations found."
                                    onRowClick={(event) => handleRowClick(event)}
                                    //globalFilter={glFilter}
                                    
                                />
                            </div>
                        </div>
                    </Col>
                </Row>


            </Stack>
        </> : null
    );
};
