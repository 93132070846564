import React, { useState, useEffect } from "react";
import {
  FontIcon,
  IStackStyles,
  Stack
} from "@fluentui/react";
import { colors, rowsPerPageOptions } from "constants/constants";
import { Dropdown } from "@fluentui/react/lib/Dropdown";

const paginationStyles: IStackStyles = {
  root: {
    padding: 20,
    paddingRight: 0,
  },
};

interface IPaginationProps {
  currentPage?: string;
  totalItems?: number;
  onRowsNumberChange?: (rows: number) => any;
  onPageChange?: (page: number) => any;
  rowsPerPage?: number;
  showPageInput?: boolean;
}

export const Pagination: React.FC<IPaginationProps> = ({
  totalItems,
  currentPage,
  onRowsNumberChange,
  onPageChange,
  rowsPerPage,
  showPageInput = true,
}): JSX.Element => {
  const currentPagesNumber = parseInt(currentPage);
  const startItemsNumber = (currentPagesNumber - 1) * rowsPerPage + 1;
  // const [pageNumberInput, setPageNumberInput] = useState<string>();

  let endItemsNumber = currentPagesNumber * rowsPerPage;
  endItemsNumber = totalItems < endItemsNumber ? totalItems : endItemsNumber;

  const [rowsPerPageNumber, setRowsPerPageNumber] = useState("");
  const lastPage = totalItems ? Math.ceil(totalItems / rowsPerPage) : 1;

  useEffect(() => {
    setRowsPerPageNumber(rowsPerPage.toString());
  }, [rowsPerPage]);

  const handleRowsNumberChange = (event: any, item: any) => {
    const v = parseInt(item.value);
    setRowsPerPageNumber(item.value);
    onRowsNumberChange(v);
  };

  // const handlePageNumberInputChange = (event: any, item: string) => {
  //   const page = parseInt(item) < lastPage ? parseInt(item) : lastPage;
  //   setPageNumberInput(page > 1 ? page.toString() : "1");
  // };
  //
  // const handlePageSubmit = (event: any) => {
  //   event.preventDefault();
  //   onPageChange(parseInt(pageNumberInput));
  // };

  return (
    <Stack
      styles={paginationStyles}
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
    >
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
        <div style={{ fontSize: 14, color: colors.grayText }}>
          Rows per page
        </div>
        <Dropdown
          selectedKey={rowsPerPageNumber}
          onChange={handleRowsNumberChange}
          options={rowsPerPageOptions}
        />
        <span style={{ fontSize: 14, color: colors.grayText }}>
          {`${startItemsNumber}-${endItemsNumber} of ${totalItems}`}
        </span>
      </Stack>

      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 25 }}>
        {/*{showPageInput ? (*/}
        {/*  <form onSubmit={handlePageSubmit}>*/}
        {/*    <TextField*/}
        {/*      placeholder="enter page number..."*/}
        {/*      type="number"*/}
        {/*      value={pageNumberInput}*/}
        {/*      onChange={handlePageNumberInputChange}*/}
        {/*    />*/}
        {/*  </form>*/}
        {/*) : null}*/}

        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <PaginationIcon
            iconName="DoubleChevronLeft"
            disabled={currentPagesNumber === 1}
            onClick={() => onPageChange(1)}
          />
          <PaginationIcon
            iconName="ChevronLeft"
            disabled={currentPagesNumber === 1}
            onClick={() => onPageChange(currentPagesNumber - 1)}
          />

          <PaginationIcon
            iconName="ChevronRight"
            disabled={lastPage === currentPagesNumber}
            onClick={() => onPageChange(currentPagesNumber + 1)}
          />
          <PaginationIcon
            iconName="DoubleChevronRight"
            onClick={() => onPageChange(lastPage)}
            disabled={lastPage === currentPagesNumber}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

interface IPaginationIconProps {
  iconName: string;
  disabled?: boolean;
  onClick?: () => void;
}

const PaginationIcon: React.FC<IPaginationIconProps> = ({
  iconName,
  disabled,
  onClick,
}) => {
  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <FontIcon
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={disabled ? null : onClick}
      style={{
        cursor: disabled ? "default" : "pointer",
        borderStyle: "solid",
        borderWidth: 1,
        padding: 9,
        borderColor: disabled
          ? colors.grayText
          : hovered
          ? colors.black
          : colors.lighterBlack,
        color: disabled
          ? colors.grayText
          : hovered
          ? colors.black
          : colors.lighterBlack,
      }}
      iconName={iconName}
    />
  );
};

// import * as React from "react";
// import {FontIcon, IStackStyles, Stack, TextField} from "@fluentui/react";
// import {colors, rowsPerPageOptions} from "constants/constants";
// import {Dropdown} from "@fluentui/react/lib/Dropdown";
//
//
// const paginationStyles: IStackStyles = {
//     root: {
//         padding: 20,
//         paddingRight: 0,
//     },
// };
//
//
// interface IPaginationProps {
//     rowsPerPage?: string
//
// }
//
// export const Pagination:React.FC<IPaginationProps> = ({rowsPerPage="15"}): JSX.Element => {
//     return (
//         <Stack
//             styles={paginationStyles}
//             horizontal
//             verticalAlign="center"
//             horizontalAlign="space-between"
//         >
//             <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
//                 <p style={{ fontSize: 14, color: colors.grayText }}>Rows per page</p>
//                 <Dropdown
//                     selectedKey={rowsPerPage}
//                     onChange={() => {}}
//                     options={rowsPerPageOptions}
//                 />
//                 <span style={{ fontSize: 14, color: colors.grayText }}>
//             {`${1}-${24} of ${55}`}
//           </span>
//             </Stack>
//             <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 25 }}>
//                 <TextField placeholder="enter page number..." type="number" />
//                 <Stack horizontal tokens={{ childrenGap: 10 }}>
//                     <PaginationIcon
//                         iconName="DoubleChevronLeft"
//                         disabled
//                         onClick={() => console.log("double left")}
//                     />
//                     <PaginationIcon iconName="ChevronLeft" disabled />
//                     <PaginationIcon iconName="ChevronRight" />
//                     <PaginationIcon
//                         iconName="DoubleChevronRight"
//                         onClick={() => console.log("double right")}
//                     />
//                 </Stack>
//             </Stack>
//         </Stack>
//     );
// };
//
// interface IPaginationIconProps {
//     iconName: string;
//     disabled?: boolean;
//     onClick?: () => void;
// }
// const PaginationIcon: React.FC<IPaginationIconProps> = ({
//                                                         iconName,
//                                                         disabled,
//                                                         onClick,
//                                                     }) => {
//     const [hovered, setHovered] = React.useState(false);
//
//     const handleMouseEnter = () => {
//         setHovered(true);
//     };
//
//     const handleMouseLeave = () => {
//         setHovered(false);
//     };
//
//     return (
//         <FontIcon
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//             onClick={onClick}
//             style={{
//                 cursor: disabled ? "default" : "pointer",
//                 borderStyle: "solid",
//                 borderWidth: 1,
//                 padding: 9,
//                 borderColor: disabled
//                     ? colors.grayText
//                     : hovered
//                         ? colors.black
//                         : colors.lighterBlack,
//                 color: disabled
//                     ? colors.grayText
//                     : hovered
//                         ? colors.black
//                         : colors.lighterBlack,
//             }}
//             iconName={iconName}
//         />
//     );
// };
