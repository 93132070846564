import React, { useEffect } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, emplyeePermissionsAction } from "constants/constants";
import { hiddenStyle } from "constants/styles";
import { useSelector } from "react-redux";
import { AppState } from "store/index";
//import { eTypes } from "hoc/withCredentials";
import { IPermission } from "types/groupPermissionTypes";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";
import { DefaultButton } from "@fluentui/react";

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};
interface IProps {
  modulePermissions: IPermission[];
  onEdit: (locationId: number) => void;
  onDelete: (locationId: number) => void;
  locationId: number
}

export const LocationsMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
  modulePermissions,
  onEdit,
  onDelete,
  locationId
}) => {
  const [menuProps, setMenuProps] = React.useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
   
  // const dispatch = useDispatch();
  const menuItems: IContextualMenuItem[] = [
    // {
    //   key: "view",
    //   name: "View",
    //   iconProps: { iconName: "RedEye", styles: iconMenuStyles },
    //   onClick: () => {},
    //   style: !isPermitted(
    //     modulePermissions,
    //     eTypes.READ,
    //     permissionsModules.Location,
    //     user.roles
    //   )
    //     ? hiddenStyle
    //     : null,
    // },
    //{
    //  key: "setStatus", //with submenu
    //  name: "Set Status",
    //  iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
    //  style: !isPermitted(
    //    modulePermissions,
    //    eTypes.UPDATE,
    //    permissionsModules.Location,
    //    user.roles
    //  )
    //    ? hiddenStyle
    //    : null,
    //  onClick: () => {},
    //  subMenuProps: {
    //    items: [
    //      {
    //        key: "active",
    //        text: "Active",
    //        onClick: () => onSetStatus(true),
    //      },
    //      {
    //        key: "inActive",
    //        text: "inactive",
    //        onClick: () => onSetStatus(false),
    //      },
    //    ],
    //  },
    //},
    // {
    //   key: "saveAsPDF", //with submenu
    //   name: "Save as PDF",
    //   iconProps: { iconName: "PDF", styles: iconMenuStyles },
    //   onClick: () => {},
    // },
    {
        key: "edit", //with submenu
          name: "Edit",
        iconProps: { iconName: "Edit", styles: iconMenuStyles },
        onClick: () => onEdit(locationId),
        style: !isEmployeePermitted(
                user.aggregatedDefaultPolicies,
                emplyeePermissionsAction.SETTING_LOCATION_UPDATE,
                user.roles
            ) ? hiddenStyle
            : null,
    },
    {
      key: "delete", //with submenu
        name: "Delete",
      iconProps: { iconName: "Delete", styles: iconMenuStyles },
      onClick: () => onDelete(locationId),
      style: !isEmployeePermitted(
            user.aggregatedDefaultPolicies,
            emplyeePermissionsAction.SETTING_LOCATION_DELETE,
            user.roles
        ) ? hiddenStyle
        : null,
    },
  ];

  useEffect(() => {
    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return (
      <>
          <IconButton
              className="iconButton icon-ellipse-h deskDisplay"
              styles={buttonStyles}
              // className="iconButton moreIcon"
              /* iconProps={{
                iconName: "More",
              }} */
              text="Group Action"
              menuProps={menuProps}
          />
          <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" menuProps={menuProps} />
      </>
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
