import {
    //call,
    put,
    //takeEvery,
    takeLeading,
    //select,
    //takeLatest,
} from "redux-saga/effects";

import {
    pendingGetSelectList,
    rejectedGetSelectList,
    resolvedGetSelectList,
} from "store/selectList/selectList.action";

import {

    getSelectList,
    getSelectListItem,
    addSelectListItem,
    saveSelectListItem,
    activateSelectListItem,
    deactivateSelectListItem
 
} from "store/selectList/selectList.api";

import {
    HANDLE_GET_SELECT_LIST,
    HANDLE_VIEW_SELECT_LIST_ITEM,
    HANDLE_UPDATE_SELECT_LIST_ITEM,
    HANDLE_CREATE_SELECT_LIST_ITEM,
    HANDLE_ACTIVATE_SELECT_LIST_ITEM,
    HANDLE_DEACTIVATE_SELECT_LIST_ITEM,
    HANDLE_GET_FINANCE_PRODUCT,
    HandleGetSelectList,
    HandleViewSelectListItem,
    HandleUpdateSelectListItem,
    HandleCreateSelectListItem,
    HandleActivateSelectListItem,
    HandleDeactivateSelectListItem,
    HandleGetFinanceProduct
} from "store/selectList/selectList.types";

import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

export function* getSelectListSaga(actions: HandleGetSelectList) {
    yield put(switchGlobalLoader(true));
    try{
        yield put(pendingGetSelectList());
        const { category, isActive } = actions.payload;
        if (category) {
            const categoryArr = category.split(',');
            const length = categoryArr.length;
            for (let i = 0; i < length; i++) {
                const { error, data } = yield getSelectList({ isActive, category: categoryArr[i] });
                if (error) {
                    yield put(rejectedGetSelectList(error.violations as any));

                } else if (data) {
                    yield put(resolvedGetSelectList(data.results, categoryArr[i]));
                }
            }
        }
        
    }
    catch (error) {
        yield put(rejectedGetSelectList(error));
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
    return;
}

function* getFinanceProductSaga(action: HandleGetFinanceProduct) {
    yield put(switchGlobalLoader(true));
    try{
        yield put(pendingGetSelectList());
        const { category, isActive,callback } = action.payload;
        if (category) {
            const categoryArr = category.split(',');
            const length = categoryArr.length;
            for (let i = 0; i < length; i++) {
                const {  data } = yield getSelectList({ isActive, category: categoryArr[i] });
                 if (data) {
                    callback(data.results);
                }
            }
        }
        
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* viewSelectListSaga(action: HandleViewSelectListItem) {
    yield put(switchGlobalLoader(true));
    try{
        const {id,category} = action.payload;
        const { data } = yield getSelectListItem({id,category});
        if (typeof action.payload.callback === "function"){
            action.payload.callback(data);
        }

    }catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* createSelectListSaga(action: HandleCreateSelectListItem) {
    yield put(switchGlobalLoader(true));
    
    try {
        const { formData,category } = action.payload;
        const { result } = yield addSelectListItem(category, formData);
        if (typeof action.payload.callback === "function") {
            if (result) {
                
                const { error, data } = yield getSelectList({category, isActive: 1 });                
                if (error) {
                    yield put(rejectedGetSelectList(error.violations as any));
                }else{
                    yield put(resolvedGetSelectList(data.results,category));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* updateSelectListSaga(action: HandleUpdateSelectListItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData, category } = action.payload;
        const { result } = yield saveSelectListItem(id,category,formData);
        if (typeof action.payload.callback === "function") {
            if (result) {
                
                const { error, data } = yield getSelectList({category, isActive: +result.isActive });
                if (error) {
                    yield put(rejectedGetSelectList(error.violations as any));
                }else{
                    yield put(resolvedGetSelectList(data.results,category));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* activateSelectListSaga(action: HandleActivateSelectListItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, category } = action.payload;
        const { result } = yield activateSelectListItem(id,category);
        if (typeof action.payload.callback === "function") {
            if (result) {
                
                const { error, data } = yield getSelectList({category, isActive: 0 });
                if (error) {
                    yield put(rejectedGetSelectList(error.violations as any));
                }else{
                    yield put(resolvedGetSelectList(data.results,category));
                }
                
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* deactivateSelectListSaga(action: HandleDeactivateSelectListItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, category } = action.payload;
        const { result } = yield deactivateSelectListItem(id,category);
        if (typeof action.payload.callback === "function") {
            if (result) {
                
                const { error, data } = yield getSelectList({category, isActive: 1 });
                if (error) {
                    yield put(rejectedGetSelectList(error.violations as any));
                }else{
                    yield put(resolvedGetSelectList(data.results,category));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

export default function* SelectListSaga() {
    yield takeLeading(HANDLE_GET_SELECT_LIST, getSelectListSaga);
    yield takeLeading(HANDLE_CREATE_SELECT_LIST_ITEM, createSelectListSaga);
    yield takeLeading(HANDLE_UPDATE_SELECT_LIST_ITEM, updateSelectListSaga);
    yield takeLeading(HANDLE_VIEW_SELECT_LIST_ITEM, viewSelectListSaga);
    yield takeLeading(HANDLE_ACTIVATE_SELECT_LIST_ITEM, activateSelectListSaga);
    yield takeLeading(HANDLE_DEACTIVATE_SELECT_LIST_ITEM, deactivateSelectListSaga);
    yield takeLeading(HANDLE_GET_FINANCE_PRODUCT, getFinanceProductSaga);
}