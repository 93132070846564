import { ISubscription } from "types/subscriptionTypes";
import * as actions from "store/subscriptions/subscriptions.types";

// GET SUBSCRIPTIONS
export const handleGetSubscriptions = (): actions.HandleGetSubscriptions => {
  return {
    type: actions.HANDLE_GET_SUBSCRIPTIONS,
  };
};

export const pendingGetSubscriptions = (): actions.GetSubscriptionsPending => {
  return {
    type: actions.GET_SUBSCRIPTIONS_PENDING,
  };
};

export const resolvedGetSubscriptions = (
  sub: ISubscription
): actions.GetSubscriptionsResolved => {
  return {
    type: actions.GET_SUBSCRIPTIONS_RESOLVED,
    payload: sub,
  };
};

export const rejectedGetSubscriptions = (
  error: any
): actions.GetSubscriptionsRejected => {
  return {
    type: actions.GET_SUBSCRIPTIONS_REJECTED,
    payload: error,
  };
};

// PAYMENT
export const handlePayment = ({
  product,
  shouldAddCreditCart,
  callback,
}: {
  product: string;
  shouldAddCreditCart: boolean;
  callback?: Function;
}): actions.HandlePayment => {
  return {
    type: actions.HANDLE_PAYMENT,
    payload: { product, shouldAddCreditCart, callback },
  };
};

export const pendingPayment = (): actions.PaymentPending => {
  return {
    type: actions.PAYMENT_PENDING,
  };
};

export const resolvedPayment = (session: string): actions.PaymentResolved => {
  return {
    type: actions.PAYMENT_RESOLVED,
    payload: session,
  };
};

export const rejectedPayment = (error: any): actions.PaymentRejected => {
  return {
    type: actions.PAYMENT_REJECTED,
    payload: error,
  };
};

// CANCEL SUBSCRIPTION
export const handleCancelSubscription = (
  id,
  callback
): actions.HandleCancelSubscription => {
  return {
    type: actions.HANDLE_CANCEL_SUBSCRIPTION,
    payload: { id, callback },
  };
};

export const pendingCancelSubscription = (): actions.CancelSubscriptionPending => {
  return {
    type: actions.CANCEL_SUBSCRIPTION_PENDING,
  };
};

export const resolvedCancelSubscription = (): actions.CancelSubscriptionResolved => {
  return {
    type: actions.CANCEL_SUBSCRIPTION_RESOLVED,
  };
};

export const rejectedCancelSubscription = (
  error: any
): actions.CancelSubscriptionRejected => {
  return {
    type: actions.CANCEL_SUBSCRIPTION_REJECTED,
    payload: error,
  };
};

/** ADD_CREDIT_CART */
export const handleAddCreditCart = (
  cart: any,
  callback: Function
): actions.HandleAddCreditCart => {
  return {
    type: actions.HANDLE_ADD_CREDIT_CART,
    payload: { cart, callback },
  };
};

export const pendingAddCreditCart = (): actions.AddCreditCartPending => {
  
  return {
    type: actions.ADD_CREDIT_CART_PENDING,
  };
};

export const resolveAddCreditCart = (
  cart: any
): actions.AddCreditCartResolved => {
  return {
    type: actions.ADD_CREDIT_CART_RESOLVED,
    payload: cart,
  };
};

export const rejectedAddCreditCart = (error): actions.AddCreditCartRejected => {
  return {
    type: actions.ADD_CREDIT_CART_REJECTED,
    payload: error,
  };
};

/** REMOVE_CREDIT_CART */
export const handleRemoveCreditCart = (
  cartId: number | string,
  callback: Function
): actions.HandleRemoveCreditCart => {
  return {
    type: actions.HANDLE_REMOVE_CREDIT_CART,
    payload: { cartId, callback },
  };
};

export const pendingRemoveCreditCart = (): actions.RemoveCreditCartPending => {
  
  return {
    type: actions.REMOVE_CREDIT_CART_PENDING,
  };
};

export const resolveRemoveCreditCart = (
  id: string
): actions.RemoveCreditCartResolved => {
  return {
    type: actions.REMOVE_CREDIT_CART_RESOLVED,
    payload: id,
  };
};

export const rejectedRemoveCreditCart = (
  error
): actions.RemoveCreditCartRejected => {
  return {
    type: actions.REMOVE_CREDIT_CART_REJECTED,
    payload: error,
  };
};

/** GET_CREDIT_CARTS */

export const handleGetCreditCarts = (): actions.HandleGetCreditCarts => {
  return {
    type: actions.HANDLE_GET_CREDIT_CARTS,
  };
};

export const pendingGetCreditCarts = (): actions.GetCreditCartsPending => {
  return {
    type: actions.GET_CREDIT_CARTS_PENDING,
  };
};
export const resolvedGetCreditCarts = (
  carts: any[]
): actions.GetCreditCartsResolved => {
  return {
    type: actions.GET_CREDIT_CARTS_RESOLVED,
    payload: carts,
  };
};
export const rejectedGetCreditCarts = (
  error: any
): actions.GetCreditCartsRejected => {
  return {
    type: actions.GET_CREDIT_CARTS_REJECTED,
    payload: error,
  };
};
