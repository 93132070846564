import * as actions from "./userProfile.types";
import { IOwner } from "../../types/userProfileTypes";

interface IState {
    loading: boolean;
    updateLoading: boolean;
    updateDealerProfileLoading: boolean;
    userProfile: {} | any;
    error: null | object;
    einValidation: string;
    ownerships: Array<IOwner>;
    deleting: boolean;
    ownerUpdateError: string;
}

const initialState: IState = {
    loading: false,
    updateLoading: false,
    updateDealerProfileLoading: false,
    userProfile: {},
    error: null,
    einValidation: null,
    ownerships: [],
    deleting: false,
    ownerUpdateError: null,
};

export const UserProfileReducer = (
    state: IState = initialState,
    action: actions.UserProfileActions
) => {
    switch (action.type) {
        case actions.GET_USER_PROFILE_PENDING: {
            return {
                ...state,
                loading: true,
                einValidation: null
            };
        }
        case actions.GET_USER_PROFILE_RESOLVED: {
           
            console.log(action.payload)
            return {
                ...state,
                loading: false,
                userProfile: {
                    ...state.userProfile,
                    ...action.payload,
                }
            };
        }
        case actions.GET_USER_PROFILE_REJECTED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case actions.UPDATE_USER_PROFILE_PENDING:
            return {
                ...state,
                updateLoading: true
            }
        case actions.UPDATE_USER_PROFILE_REJECTED:
            return {
                ...state,
                updateLoading: false,
                error: action.payload
            }
        case actions.UPDATE_USER_PROFILE_RESOLVED:
            return {
                ...state,
                updateLoading: false,
                userProfile: {
                    ...state.userProfile,
                    dealerProfile: action.payload
                },
            };
        case actions.HANDLE_CLEAN_USER:
            return {
                ...state,
                userProfile: {},
                ownerships:[]
            }

        case actions.UPDATE_DEALER_PROFILE_PENDING: {
            return {
                ...state,
                updateDealerProfileLoading: true,
                einValidation: null
            }
        }

        case actions.UPDATE_DEALER_PROFILE_REJECTED: {
            return {
                ...state,
                updateDealerProfileLoading: false,
                einValidation: action.payload,
            }
        }

        case actions.UPDATE_DEALER_PROFILE_RESOLVED: {
            
            return {
                ...state,
                updateDealerProfileLoading: false,
                userProfile: {
                    ...state.userProfile,
                    dealerProfile: action.payload,
                },
               // ownership: action.payload.owners,
            }
        }

            //Owner
        case actions.GET_OWNERS_PENDING:
            return {
                ...state,
                loading: true,
            };

        case actions.GET_OWNERS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actions.GET_OWNERS_RESOLVED:
            return {
                ...state,
                loading: false,
                ownerships: action.payload,
            };

        case actions.UPDATE_OWNER_PENDING: {
            return {
                ...state,
                loading: true,
                ownerUpdateError:null
            };
        }

        case actions.UPDATE_OWNER_RESOLVED: {
            return {
                ...state,
                loading: false,
                ownerships: action.payload,
            };
        }

        case actions.UPDATE_OWNER_REJECTED: {
            return {
                ...state,
                loading: false,
                ownerUpdateError:'Failed to Save the Data'
            };
        }

        default:
            return state;
    }
};
