import * as actions from "./employees.types";
import { IEmployee, IEmployeesProspect } from "types/employeesTypes";
import { IPaginationResponse } from "types/paginationTypes";
import { ITasks } from "types/taskTypes";
// import {
//   EMPLOYEES_ASSIGNED_PROSPECT_PENDING,
//   HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT,
// } from "./employees.types";

interface IState {
  employeePasswordError: string;
  loading: boolean;
  updateLoading: boolean;
  loadingGetEmployees: boolean;
  employees: IPaginationResponse<IEmployee>;
  rowsPerPage: number;
  loadingEmployeeDelete: boolean;

  employeesForAddGroupAssignEmployee: IPaginationResponse<IEmployee>;
  rowsPerPageForAddGroupAssignEmployee: number;
  loadingEmployeesForAddGroupAssignEmployee: boolean;
  errorForAddGroupAssignEmployee: null | object;

  employeesForAddGroupAssignedEmployee: IPaginationResponse<IEmployee>;
  rowsPerPageForAddGroupAssignedEmployee: number;
  loadingEmployeesForAddGroupAssignedEmployee: boolean;
  errorForAddGroupAssignedEmployee: null | object;
  //currPageAddGroupAssignToGroup: string;
  selectedEmployee: null | IEmployee;
  error: null | object;
  serverError: null | object;
  updateEmployeeError: null | object;
  employeeActivateError: null | object;
  column: string;
  sort: string;
  employeeTasks: ITasks | null;
  employeesProspect: IEmployeesProspect;
  employeePermissions: null | object[];
  employeeWeeklyHours: null | object[];
  managers: null | object[];
  employeesFinance: IPaginationResponse<IEmployee>;
}

const initialState: IState = {
  employeePasswordError: "",
  loading: false,
  loadingEmployeeDelete: false,
  updateLoading: false,
  loadingGetEmployees: false,
  employees: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
  rowsPerPage: 15,

  employeesForAddGroupAssignEmployee: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
  rowsPerPageForAddGroupAssignEmployee: 10,
  loadingEmployeesForAddGroupAssignEmployee: false,
  errorForAddGroupAssignEmployee: null,

  employeesForAddGroupAssignedEmployee: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
  rowsPerPageForAddGroupAssignedEmployee: 10,
  loadingEmployeesForAddGroupAssignedEmployee: false,
  errorForAddGroupAssignedEmployee: null,

  selectedEmployee: null,
  error: null,
  serverError: null,
  updateEmployeeError:null,
  employeeActivateError:null,
  column: "employee.firstName",
  sort: "ASC",
  employeeTasks: null,
  employeesProspect: {
    assigned: null,
    unassigned: null,
    },
  employeePermissions: null,
  employeeWeeklyHours: null,
  managers: null,
  employeesFinance: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
};

export const EmployeesReducer = (
  state: IState = initialState,
  action: actions.EmployeesActions
) => {
  switch (action.type) {
    case actions.CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED: {
      return {
        ...state,
        employeesForAddGroupAssignEmployee: {
          ...initialState.employeesForAddGroupAssignEmployee,
        },
        employeesForAddGroupAssignedEmployee: {
          ...initialState.employeesForAddGroupAssignedEmployee,
        },
      };
    }
    case actions.SET_ROWS_PER_PAGE_EMPLOYEES: {
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    }

    case actions.SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE: {
      return {
        ...state,
        rowsPerPageForAddGroupAssignEmployee: action.payload,
      };
    }

    case actions.SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE: {
      return {
        ...state,
        rowsPerPageForAddGroupAssignedEmployee: action.payload,
      };
    }

    case actions.GET_EMPLOYEES_PENDING: {
      return {
        ...state,
          loadingGetEmployees: true,
          employeeActivateError:null
        // employees: {
        //   results: [],
        //   totalItems: 0,
        //   currentPage: "1",
        // },
      };
    }
    case actions.GET_EMPLOYEES_RESOLVED: {
      return {
        ...state,
        loadingGetEmployees: false,
        employees: action.payload,
      };
    }
    case actions.GET_EMPLOYEES_REJECTED: {
      return {
        ...state,
        loadingGetEmployees: false,
        error: action.payload,
      };
    }

    //
    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_PENDING: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignEmployee: true,
      };
    }
    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignEmployee: false,
        employeesForAddGroupAssignEmployee: action.payload,
      };
    }
    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignEmployee: false,
        errorForAddGroupAssignEmployee: action.payload,
      };
    }

    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_PENDING: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignedEmployee: true,
      };
    }
    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignedEmployee: false,
        employeesForAddGroupAssignedEmployee: action.payload,
      };
    }
    case actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loadingGetEmployeesForAddGroupAssignedEmployee: false,
        errorForAddGroupAssignedEmployee: action.payload,
      };
    }

    case actions.CREATE_EMPLOYEE_PENDING: {
      return {
        ...state,
        loading: true,
        serverError: null,
      };
    }

    case actions.CREATE_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loading: false,
        serverError: null,
      };
    }

    case actions.CREATE_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loading: false,
        serverError: action.payload,
      };
    }
    case actions.DELETE_EMPLOYEE_PENDING: {
      return {
        ...state,
        loadingEmployeeDelete: true,
      };
    }

    case actions.DELETE_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loadingEmployeeDelete: false,
      };
    }

    case actions.DELETE_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loadingEmployeeDelete: false,
      };
    }

    case actions.VIEW_EMPLOYEE_PENDING: {
      return {
        ...state,
          loading: true,
          serverError: null,
          updateEmployeeError: null,
          employeePermissions: null,
          employeeWeeklyHours:null,
          managers: null
      };
    }

    case actions.VIEW_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedEmployee: action.payload.employee,
        employeePermissions: action.payload.permissions,
        employeeWeeklyHours: action.payload.permissions,
        managers: action.payload.managers
      };
    }

    case actions.VIEW_EMPLOYEE_REJECTED: {
      return {
        ...state,
          loading: false,
          serverError:'Failed to fetch data.'
      };
    }
 
    case actions.UPDATE_EMPLOYEE_PENDING: {
      return {
        ...state,
          updateLoading: true,
          serverError: null,
          updateEmployeeError: null
      };
    }

    case actions.UPDATE_EMPLOYEE_RESOLVED: {
      return {
        ...state,
          updateLoading: false,
          serverError: null,
          updateEmployeeError: null,
          selectedEmployee: action.payload
      };
    }
    case actions.UPDATE_EMPLOYEE_REJECTED: {
      return {
        ...state,
          updateLoading: false,
          updateEmployeeError: action.payload,
      };
    }

    case actions.SET_EMPLOYEE_PASSWORD_ERROR: {
      return {
        ...state,
        employeePasswordError: action.payload,
      };
    }

    case actions.GET_EMPLOYEE_TASKS_PENDING: {
      return {
        ...state,
        updateLoading: true,
      };
    }

    case actions.GET_EMPLOYEE_TASKS_RESOLVED: {
      return {
        ...state,
        employeeTasks: action.payload,
        updateLoading: false,
      };
    }
    case actions.GET_EMPLOYEE_TASKS_REJECTED: {
      return {
        ...state,
        updateLoading: false,
        error: action.payload,
      };
    }
    case actions.EMPLOYEES_ASSIGNED_PROSPECT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.EMPLOYEES_ASSIGNED_PROSPECT_RESOLVED: {
      return {
        ...state,
        employeesProspect: action.payload,
        loading: false,
      };
    }
    case actions.EMPLOYEES_ASSIGNED_PROSPECT_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case actions.EMPLOYEE_ACTIVATE_PENDING: {
        return {
            ...state,
            employeeActivateError: null,
        };
    }
    case actions.EMPLOYEE_ACTIVATE_REJECTED: {
        return {
            ...state,
            employeeActivateError: action.payload,
        };
    }
    case actions.SEND_INVITE_PENDING: {
        return {
            ...state,
            employeeActivateError: null,
        };
    }
    case actions.SEND_INVITE_REJECTED: {
        return {
            ...state,
            employeeActivateError: action.payload,
        };
    }
    case actions.GET_EMPLOYEES_FINANCE_PENDING: {
      return {
        ...state,
          loadingGetEmployees: true,
          employeeActivateError:null
        // employees: {
        //   results: [],
        //   totalItems: 0,
        //   currentPage: "1",
        // },
      };
    }
    case actions.GET_EMPLOYEES_FINANCE_RESOLVED: {
      return {
        ...state,
        loadingGetEmployees: false,
        employeesFinance: action.payload,
      };
    }
    case actions.GET_EMPLOYEES_FINANCE_REJECTED: {
      return {
        ...state,
        loadingGetEmployees: false,
        error: action.payload,
      };
    }

    case actions.VIEW_EMPLOYEE_WEEKLY_HOURS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.VIEW_EMPLOYEE_WEEKLY_HOURS_RESOLVED: {
      return {
        ...state,
        employeeWeeklyHours: action.payload,
        loading: false,
      };
    }
    case actions.VIEW_EMPLOYEE_WEEKLY_HOURS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
