import React, { useEffect, useState } from "react";
import {
  Modal,
  PrimaryButton,
  IconButton,
  Button,
  Stack,
  TextField,
  DatePicker,
  MaskedTextField,
  Toggle,
  Dropdown
} from "@fluentui/react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { PRICE_PREFIX, salutations } from "constants/constants";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
  dropdownStyles
} from "constants/styles";
import { styles } from "pages/accountSettingsPage/styles";
import { Col, Row } from "react-grid-system";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleEditCosigner } from "store/cosigners/cosigners.action";
import CurrencyInput from 'react-currency-input-field'
import {
  stringFromCurrencyFormat,
  stringToCurrenyFormatForEditInputs,
} from "utils/helperFunctions";
import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

const contentStyles = content();

interface IProps {
  onDismiss: () => void;
  setCosigner: any;
  cosigners: any;
  index: 0 | 1;
}

export const EditCosignerDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  cosigners,
  setCosigner,
  index,
}) => {
  const dispatch = useDispatch();

  const [maskValue, setMaskValue] = React.useState(false);
  // const loading = useSelector((state: AppState) => state.deals.loading);
  const loadingEditCosigner = useSelector(
    (state: AppState) => state.cosigners.loading
  );
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
      return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  const formik = useFormik({
    initialValues: {
      salutation: 3, //number
      firstName: "",
      lastName: "",

      //optional
      middleName: "",
      homePhoneNumber: "",
      phone: "",
      workPhoneNumber: "",
      email: "",
      dateOfBirth: "",
      currentAddress1: "",
      currentAddress2: "",
      zip: "",
      city: "",
      county: "",
      state: "",
      suffix: "",
      driverLicense: "",
      socialSecurityNumber: "",
      socialSecurityNumberMasked: false,

      // employment
      employerName: "",
      lenthAtJob: null, //number
      employerAddress1: "",
      employerAddress2: "",
      employerZip: "",
      employerCity: "",
      employerCounty: "",
      employerState: "",
      monthlyIncome: null, //number,
      additionalIncomeAmount: null, //number,
      sourceOfIncome: "",
    },

    validationSchema: Yup.object({
      salutation: Yup.number().required("Required"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),

      //optional
      dateOfBirth: Yup.string(),
      currentAddress1: Yup.string(),
      currentAddress2: Yup.string(),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits"),
      city: Yup.string(),
      county: Yup.string(),
      state: Yup.string(),
      suffix: Yup.string(),
      driverLicense: Yup.string().required(),
      socialSecurityNumber: Yup.string(),
      socialSecurityNumberMasked: Yup.boolean(),

      //employment
      employerName: Yup.string(),
      lenthAtJob: Yup.number().nullable(),
      employerAddress1: Yup.string(),
      employerAddress2: Yup.string(),
      employerZip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits"),
      employerCity: Yup.string(),
      employerCounty: Yup.string(),
      employerState: Yup.string(),
      monthlyIncome: Yup.string().nullable(),
      additionalIncomeAmount: Yup.string().nullable(), //number,
      sourceOfIncome: Yup.string(),
    }),
    onSubmit: (values) => {
      const cosigner = {
        ...values,
        monthlyIncome: parseInt(stringFromCurrencyFormat(values.monthlyIncome)),
        additionalIncomeAmount: parseInt(
          stringFromCurrencyFormat(values.additionalIncomeAmount)
        ),
      };
      dispatch<any>(
        handleEditCosigner(
          cosigner,
          ({ createdCosigner }) => {
            setCosigner(createdCosigner);
            onDismiss();
          },
          cosigners[index].id
        )
      );
    },
  });

  useEffect(() => {
    formik.setValues({
      salutation: cosigners[index] !== null ? cosigners[index].salutation : 3,
      firstName: cosigners[index] !== null ? cosigners[index].firstName : "",
      lastName: cosigners[index] !== null ? cosigners[index].lastName : "",

      //optional
      middleName: cosigners[index] !== null ? cosigners[index].middleName : "",
      homePhoneNumber:
        cosigners[index] !== null ? cosigners[index].homePhoneNumber : "",
      phone: cosigners[index] !== null ? cosigners[index].phone : "",
      workPhoneNumber:
        cosigners[index] !== null ? cosigners[index].workPhoneNumber : "",
      email: cosigners[index] !== null ? cosigners[index].email : "",
      dateOfBirth:
        cosigners[index] !== null ? cosigners[index].dateOfBirth : new Date(),
      currentAddress1:
        cosigners[index] !== null ? cosigners[index].currentAddress1 : "",
      currentAddress2:
        cosigners[index] !== null ? cosigners[index].currentAddress2 : "",
      zip: cosigners[index] !== null ? cosigners[index].zip : "",
      city: cosigners[index] !== null ? cosigners[index].city : "",
      county: cosigners[index] !== null ? cosigners[index].county : "",
      state: cosigners[index] !== null ? cosigners[index].state : "",
      suffix: cosigners[index] !== null ? cosigners[index].suffix : "",
      driverLicense:
        cosigners[index] !== null ? cosigners[index].driverLicense : "",
      socialSecurityNumber:
        cosigners[index] !== null ? cosigners[index].socialSecurityNumber : "",
      socialSecurityNumberMasked:
        cosigners[index] !== null
          ? cosigners[index].socialSecurityNumberMasked
          : false,

      // employment
      employerName:
        cosigners[index] !== null ? cosigners[index].employerName : "",
      lenthAtJob:
        cosigners[index] !== null ? cosigners[index].lenthAtJob : null,
      employerAddress1:
        cosigners[index] !== null ? cosigners[index].employerAddress1 : "",
      employerAddress2:
        cosigners[index] !== null ? cosigners[index].employerAddress2 : "",
      employerZip:
        cosigners[index] !== null ? cosigners[index].employerZip : "",
      employerCity:
        cosigners[index] !== null ? cosigners[index].employerCity : "",
       employerCounty:
        cosigners[index] !== null ? cosigners[index].employerCounty : "",
      employerState:
        cosigners[index] !== null ? cosigners[index].employerState : "",
      monthlyIncome:
        cosigners[index] !== null
          ? stringToCurrenyFormatForEditInputs(
              cosigners[index].monthlyIncome
                ? cosigners[index].additionalIncomeAmount
                : ""
            )
          : null,
      additionalIncomeAmount:
        cosigners[index] !== null
          ? stringToCurrenyFormatForEditInputs(
              cosigners[index].additionalIncomeAmount
                ? cosigners[index].additionalIncomeAmount
                : ""
            )
          : null,
      sourceOfIncome:
        cosigners[index] !== null ? cosigners[index].sourceOfIncome : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleMaskedValue = (val) => {
    setMaskValue((value) => !value);
  };

  const renderSpinner = () => {
    return loadingEditCosigner ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
    );
  };

  const handleDateChange = (date) => {
    formik.setFieldValue("dateOfBirth", date);
  };

    //Zip
    const [zipApiMsg, setZipApiMsg] = useState("");
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const handleZipChange = (e) => {
        if (e.target.value.length === 5 && !isNaN(e.target.value)) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {

                if (response.Status) {
                    setCities(response.Cities);
                    setCounties(response.Counties);
                    formik.setValues(
                        {
                            ...formik.values,
                            state: response.State,
                            city: response.DefaultCity,
                            county: response.DefaultCounty
                        },
                        true
                    );

                }
                else {

                    setZipApiMsg(response.ErrorMessage);
                    resetCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
        }
        else {
            setZipApiMsg("");
            resetCityCountyState();
        }
    };

    const resetCityCountyState = () => {
        formik.setValues(
            {
                ...formik.values,
                state: "",
                city: "",
                county: ""
            },
            true
        );
        setCounties([]);
        setCities([]);
    }

    //Employer Zip 
    const [employerZipApiMsg, setEmployerZipApiMsg] = useState("");
    const [employerCities, setEmployerCities] = useState([]);
    const [employerCounties, setEmployerCounties] = useState([]);
    const handleEmployerZipChange = (e) => {
        if (e.target.value.length === 5 && !isNaN(e.target.value)) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {

                if (response.Status) {
                    setEmployerCities(response.Cities);
                    setEmployerCounties(response.Counties);
                    formik.setValues(
                        {
                            ...formik.values,
                            employerState: response.State,
                            employerCity: response.DefaultCity,
                            employerCounty: response.DefaultCounty
                        },
                        true
                    );

                }
                else {

                    setEmployerZipApiMsg(response.ErrorMessage);
                    resetEmployerCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
        }
        else {
            setEmployerZipApiMsg("");
            resetEmployerCityCountyState();
        }
    };

    const resetEmployerCityCountyState = () => {
        formik.setValues(
            {
                ...formik.values,
                employerState: "",
                employerCity: "",
                employerCounty: ""
            },
            true
        );
        setEmployerCounties([]);
        setEmployerCities([]);
    }  

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={` ${contentStyles.header}`}>
        <GoBackToMainDialogBtn label="Edit Cosigner" onClick={onDismiss} />
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack>
          <Stack tokens={styles.textFields}>
            <Row>
              <Col style={{ maxWidth: "110px" }}>
                <Dropdown
                  id="salutation"
                  label="Salutation"
                  selectedKey={
                    formik.values.salutation !== 3
                      ? formik.values.salutation
                      : 3
                  }
                  onChange={handleDropdownChange}
                  placeholder="Mr"
                  required={true}
                  options={salutations}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.salutation && formik.errors.salutation
                      ? formik.errors.salutation.toString()
                      : null
                  }
                />
              </Col>
              <Col>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  required={true}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName.toString()
                      : null
                  }
                />
              </Col>
            </Row>

              <TextField
                id="middleName"
                name="middleName"
                label="Middle Name"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.middleName && formik.errors.middleName
                    ? formik.errors.middleName.toString()
                    : null
                }
              />

            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              required={true}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName.toString()
                  : null
              }
            />

              <MaskedTextField
                mask="(999)999-9999"
                id="homePhoneNumber"
                name="homePhoneNumber"
                label="Home Phone Number"
                value={formik.values.homePhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.homePhoneNumber &&
                  formik.errors.homePhoneNumber
                    ? formik.errors.homePhoneNumber.toString()
                    : null
                }
              />
            
              <MaskedTextField
                mask="(999)999-9999"
                id="phone"
                name="phone"
                label="Cell Phone Number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone.toString()
                    : null
                }
              />

              {/*<TextField*/}
              {/*  id="phone"*/}
              {/*  name="phone"*/}
              {/*  label="Cell Phone Number"*/}
              {/*  value={formik.values.phone}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.phone && formik.errors.phone*/}
              {/*      ? formik.errors.phone.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
           
              <MaskedTextField
                mask="(999)999-9999"
                id="workPhoneNumber"
                name="workPhoneNumber"
                label="Work Phone Number"
                value={formik.values.workPhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.workPhoneNumber &&
                  formik.errors.workPhoneNumber
                    ? formik.errors.workPhoneNumber.toString()
                    : null
                }
              />

              {/*<TextField*/}
              {/*  id="workPhoneNumber"*/}
              {/*  name="workPhoneNumber"*/}
              {/*  label="Work Phone Number"*/}
              {/*  value={formik.values.workPhoneNumber}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.workPhoneNumber &&*/}
              {/*    formik.errors.workPhoneNumber*/}
              {/*      ? formik.errors.workPhoneNumber.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
           
              <TextField
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email.toString()
                    : null
                }
              />
            
              <DatePicker // @TODO: Need to be changed as search not selected date
                // className={dateStyle}
                // onSelectDate={(e) => handleDateChange(e)}
                onSelectDate={(e) => handleDateChange(e)}
                value={
                  formik.values.dateOfBirth !== "" &&
                  new Date(formik.values.dateOfBirth)
                }
                style={{ marginBottom: 0 }}
                label="Date of Birth"
                placeholder="or select a date..."
                ariaLabel="Select a date"
                showGoToToday={false}
                // isMonthPickerVisible={false}
              />
            
              <TextField
                id="currentAddress1"
                name="currentAddress1"
                label="Current Address 1"
                value={formik.values.currentAddress1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.currentAddress1 &&
                  formik.errors.currentAddress1
                    ? formik.errors.currentAddress1.toString()
                    : null
                }
              />
            
              <TextField
                id="currentAddress2"
                name="currentAddress2"
                label="Current Address 2"
                value={formik.values.currentAddress2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.currentAddress2 &&
                  formik.errors.currentAddress2
                    ? formik.errors.currentAddress2.toString()
                    : null
                }
              />
            {/* zip city state */}

            <Row>
              <Col style={{ flex: 2, paddingRight: 0 }}>
                <TextField
                  id="zip"
                  name="zip"
                  label="ZIP"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
				  onKeyUp={handleZipChange}
                  errorMessage={
                    formik.touched.zip && formik.errors.zip
                      ? formik.errors.zip.toString()
                      : zipApiMsg ? zipApiMsg : null
                  }
                />
              </Col>
              {/*<Col style={isTablet ? cityTablet : city}>*/}
              <Col style={{ flex: 4 }}>
                <Dropdown
                    id="city"
                    label="City"
                    selectedKey={formik.values.city}
                    onChange={handleDropdownChange}
                    options={cities}
                    styles={dropdownStyles}
                    errorMessage={
                        formik.touched.city && formik.errors.city
                            ? formik.errors.city.toString()
                            : null
                    }
                />
              </Col>
            </Row>
            <Row>
                <Col style={{ flex: 6 }}>
                    <Dropdown
                        id="county"
                        label="County"
                        selectedKey={formik.values.county}
                        onChange={handleDropdownChange}
                        options={counties}
                        styles={dropdownStyles}
                        errorMessage={
                            formik.touched.county && formik.errors.county
                                ? formik.errors.county.toString()
                                : null
                        }
                    />
                </Col>
                <Col style={{ flex: 2 }}>
                    <TextField
                        id="state"
                        name="state"
                        label="State"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        errorMessage={
                            formik.touched.state && formik.errors.state
                                ? formik.errors.state.toString()
                                : null
                        }
                    />
                </Col>
            </Row>
              <TextField
                id="suffix"
                name="suffix"
                label="Suffix"
                value={formik.values.suffix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.suffix && formik.errors.suffix
                    ? formik.errors.suffix.toString()
                    : null
                }
              />

            <TextField
              id="driverLicense"
              name="driverLicense"
              label="Driver License"
              required={true}
              value={formik.values.driverLicense}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.driverLicense && formik.errors.driverLicense
                  ? formik.errors.driverLicense.toString()
                  : null
              }
            />

              <MaskedTextField
                type={maskValue ? "password" : null}
                id="socialSecurityNumber"
                name="socialSecurityNumber"
                label="Social Security Number"
                mask="999 - 99 - 9999"
                value={formik.values.socialSecurityNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.socialSecurityNumber &&
                  formik.errors.socialSecurityNumber &&
                  formik.errors.socialSecurityNumber.toString()
                }
              />

            <div style={{ marginTop: 20 }}>
              <Toggle
                id="socialSecurityNumberMasked"
                checked={maskValue}
                label="Social Security Number Masked"
                inlineLabel
                onChange={handleToggleMaskedValue}
                onText="active"
                offText="inactive"
              />
            </div>
          </Stack>
        </Stack>

        {/* employment */}
        <div style={{ fontSize: 16, marginTop: 30, marginBottom: 15 }}>
          {" "}
          Employment{" "}
        </div>
        <Stack style={{ paddingBottom: 30 }}>
          <Stack tokens={styles.textFields}>
              <TextField
                id="employerName"
                name="employerName"
                label="Employer Name"
                value={formik.values.employerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerName && formik.errors.employerName
                    ? formik.errors.employerName.toString()
                    : null
                }
              />
            
              <TextField
                id="lenthAtJob"
                name="lenthAtJob"
                label="Length at Job"
                value={formik.values.lenthAtJob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.lenthAtJob && formik.errors.lenthAtJob
                    ? formik.errors.lenthAtJob.toString()
                    : null
                }
              />
            
              <TextField
                id="employerAddress1"
                name="employerAddress1"
                label="Employer Address 1"
                value={formik.values.employerAddress1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerAddress1 &&
                  formik.errors.employerAddress1
                    ? formik.errors.employerAddress1.toString()
                    : null
                }
              />
           
              <TextField
                id="employerAddress2"
                name="employerAddress2"
                label="Employer Address 2"
                value={formik.values.employerAddress2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.employerAddress2 &&
                  formik.errors.employerAddress2
                    ? formik.errors.employerAddress2.toString()
                    : null
                }
              />
            <Row>
              <Col style={{ flex: 2, paddingRight: 0 }}>
                <TextField
                  id="employerZip"
                  name="employerZip"
                  label="ZIP"
                  value={formik.values.employerZip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyUp={handleEmployerZipChange}
                  errorMessage={
                    formik.touched.employerZip && formik.errors.employerZip
                      ? formik.errors.employerZip.toString()
                      : employerZipApiMsg? employerZipApiMsg : null
                  }
                />
              </Col>
              {/*<Col style={isTablet ? cityTablet : city}>*/}
              <Col style={{ flex: 4 }}>
                <Dropdown
                      id="employerCity"
                      label="City"
                      selectedKey={formik.values.employerCity}
                      onChange={handleDropdownChange}
                      options={employerCities}
                      styles={dropdownStyles}
                      errorMessage={
                          formik.touched.employerCity && formik.errors.employerCity
                              ? formik.errors.employerCity.toString()
                              : null
                      }
                  />
              </Col>
            </Row>
            <Row>
                <Col style={{ flex: 6 }}>
                    <Dropdown
                        id="employerCounty"
                        label="County"
                        selectedKey={formik.values.employerCounty}
                        onChange={handleDropdownChange}
                        options={employerCounties}
                        styles={dropdownStyles}
                        errorMessage={
                            formik.touched.employerCounty && formik.errors.employerCounty
                                ? formik.errors.employerCounty.toString()
                                : null
                        }
                    />
                </Col>
                <Col style={{ flex: 2 }}>
                    <TextField
                        id="employerState"
                        name="employerState"
                        label="State"
                        value={formik.values.employerState}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        errorMessage={
                            formik.touched.employerState && formik.errors.employerState
                                ? formik.errors.employerState.toString()
                                : null
                        }
                    />

                </Col>
            </Row>
              <label className="lbl-text required-Label">Monthly Income</label>
              <CurrencyInput
                customInput={TextField}
               // thousandSeparator={true}
                id="monthlyIncome"
                name="monthlyIncome"
                //label="Monthly Income"
                prefix={PRICE_PREFIX}
                value={
                  formik.values.monthlyIncome ? formik.values.monthlyIncome.replace(/,/g, '') : ""
                }
                onValueChange ={(e) => {
                  formik.setValues(
                      {
                          ...formik.values,
                          "monthlyIncome": e
                      }
                  );
              }}
                onBlur={formik.handleBlur}
              />
              <span className="error-message">
              {
                formik.errors.monthlyIncome &&
                formik.errors.monthlyIncome.toString()}
            </span>
              {/*<TextField*/}
              {/*  id="monthlyIncome"*/}
              {/*  name="monthlyIncome"*/}
              {/*  label="Monthly Income"*/}
              {/*  prefix="$"*/}
              {/*  type="number"*/}
              {/*  value={formik.values.monthlyIncome}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.monthlyIncome && formik.errors.monthlyIncome*/}
              {/*      ? formik.errors.monthlyIncome.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
              <label className="lbl-text required-Label">Additional Income Amount</label>
              <CurrencyInput
                customInput={TextField}
               // thousandSeparator={true}
                id="additionalIncomeAmount"
                name="additionalIncomeAmount"
                //label="Additional Income Amount"
                prefix={PRICE_PREFIX}
                value={
                  formik.values.additionalIncomeAmount
                    ? formik.values.additionalIncomeAmount.replace(/,/g, '')
                    : ""
                }
                onValueChange ={(e) => {
                  formik.setValues(
                      {
                          ...formik.values,
                          "additionalIncomeAmount": e
                      }
                  );
              }}
                onBlur={formik.handleBlur}
              />
            <span className="error-message">
              {
                formik.errors.additionalIncomeAmount &&
                formik.errors.additionalIncomeAmount.toString()}
            </span>

              {/*<TextField*/}
              {/*  id="additionalIncomeAmount"*/}
              {/*  name="additionalIncomeAmount"*/}
              {/*  label="Additional Income Amount"*/}
              {/*  prefix="$"*/}
              {/*  type="number"*/}
              {/*  value={formik.values.additionalIncomeAmount}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.additionalIncomeAmount &&*/}
              {/*    formik.errors.additionalIncomeAmount*/}
              {/*      ? formik.errors.additionalIncomeAmount.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
            
              <TextField
                id="sourceOfIncome"
                name="sourceOfIncome"
                label="Source of Income"
                prefix="$"
                type="number"
                value={formik.values.sourceOfIncome}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.sourceOfIncome && formik.errors.sourceOfIncome
                    ? formik.errors.sourceOfIncome.toString()
                    : null
                }
              />
          </Stack>
        </Stack>
      </div>

      <div className={contentStyles.footer}>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <Button
            text="Close"
            style={{ marginRight: "10px" }}
            onClick={() => onDismiss()}
          />
          <PrimaryButton
            disabled={!formik.isValid || !formik.dirty}
            text="Edit Co-Signer"
            onClick={() => formik.handleSubmit()}
            onRenderIcon={renderSpinner}
          />
        </Stack>
      </div>
    </Modal>
  );
};
