import {
    ICreateAccount,
    IAccount,
} from "types/accountsTypes";
import { IPaginationResponse } from "types/paginationTypes";

/** GET_ACCOUNTS */
export const HANDLE_GET_ACCOUNTS = "HANDLE_GET_ACCOUNTS";

export interface HandleGetAccounts {
    type: typeof HANDLE_GET_ACCOUNTS;
    payload: {
        isActive: number;
    };
}

export const SET_SORT_ACCOUNTS = "SET_SORT_ACCOUNTS";

export interface SetSortAccounts {
    type: typeof SET_SORT_ACCOUNTS;
    payload: string;
}

export const GET_ACCOUNTS_PENDING = "GET_ACCOUNTS_PENDING";

export interface GetAccountsPending {
    type: typeof GET_ACCOUNTS_PENDING;
}

export const GET_ACCOUNTS_RESOLVED = "GET_ACCOUNTS_RESOLVED";

export interface GetAccountsResolved {
    type: typeof GET_ACCOUNTS_RESOLVED;
    payload: IPaginationResponse<IAccount>;
}

export const GET_ACCOUNTS_REJECTED = "GET_ACCOUNTS_REJECTED";

export interface GetAccountsRejected {
    type: typeof GET_ACCOUNTS_REJECTED;
    payload: object[];
}



/** CREATE_ACCOUNT */
export const HANDLE_CREATE_ACCOUNT = "HANDLE_CREATE_ACCOUNT";

export interface HandleCreateAccount {
    type: typeof HANDLE_CREATE_ACCOUNT;
    payload: {
        account: ICreateAccount;
        callback: () => void;
    };
}

export const CREATE_ACCOUNT_PENDING = "CREATE_ACCOUNT_PENDING";

export interface CreateAccountPending {
    type: typeof CREATE_ACCOUNT_PENDING;
}

export const CREATE_ACCOUNT_RESOLVED = "CREATE_ACCOUNT_RESOLVED";

export interface CreateAccountResolved {
    type: typeof CREATE_ACCOUNT_RESOLVED;
    payload: IAccount;
}

export const CREATE_ACCOUNT_REJECTED = "CREATE_ACCOUNT_REJECTED";

export interface CreateAccountRejected {
    type: typeof CREATE_ACCOUNT_REJECTED;
    payload: object[];
}


/** VIEW_ACCOUNT */
export const HANDLE_VIEW_ACCOUNT = "HANDLE_VIEW_ACCOUNT";

export interface HandleViewAccount {
    type: typeof HANDLE_VIEW_ACCOUNT;
    payload: string | number;
}

export const VIEW_ACCOUNT_PENDING = "VIEW_ACCOUNT_PENDING";

export interface ViewAccountPending {
    type: typeof VIEW_ACCOUNT_PENDING;
}

export const VIEW_ACCOUNT_RESOLVED = "VIEW_ACCOUNT_RESOLVED";

export interface ViewAccountResolved {
    type: typeof VIEW_ACCOUNT_RESOLVED;
    payload: IAccount;
}

export const VIEW_ACCOUNT_REJECTED = "VIEW_ACCOUNT_REJECTED";

export interface ViewAccountRejected {
    type: typeof VIEW_ACCOUNT_REJECTED;
    payload: object;
}

/** UPDATE_ACCOUNT */
export const HANDLE_UPDATE_ACCOUNT = "HANDLE_UPDATE_ACCOUNT";

export interface HandleUpdateAccount {
    type: typeof HANDLE_UPDATE_ACCOUNT;
    payload: { account: IAccount; callback: () => void };
}

export const UPDATE_ACCOUNT_PENDING = "UPDATE_ACCOUNT_PENDING";

export interface UpdateAccountPending {
    type: typeof UPDATE_ACCOUNT_PENDING;
}

export const UPDATE_ACCOUNT_RESOLVED = "UPDATE_ACCOUNT_RESOLVED";

export interface UpdateAccountResolved {
    type: typeof UPDATE_ACCOUNT_RESOLVED;
    payload: IAccount;
}

export const UPDATE_ACCOUNT_REJECTED = "UPDATE_ACCOUNT_REJECTED";

export interface UpdateAccountRejected {
    type: typeof UPDATE_ACCOUNT_REJECTED;
    payload: object;
}


/** ACCOUNT ACTIVATE **/
export const HANDLE_ACCOUNT_ACTIVATE = "HANDLE_ACCOUNT_ACTIVATE";

export interface HandleAccountActivate {
    type: typeof HANDLE_ACCOUNT_ACTIVATE;
    payload: { id: number, isActive: boolean };
}

export const ACCOUNT_ACTIVATE_PENDING = "ACCOUNT_ACTIVATE_PENDING";

export interface AccountActivatePending {
    type: typeof ACCOUNT_ACTIVATE_PENDING;
}

export const ACCOUNT_ACTIVATE_REJECTED =
    "ACCOUNT_ACTIVATE_REJECTED";

export interface AccountActivateRejected {
    type: typeof ACCOUNT_ACTIVATE_REJECTED;
    payload: object;
}

export const ACCOUNT_ACTIVATE_RESOLVED =
    "ACCOUNT_ACTIVATE_RESOLVED";

export interface AccountActivateResolved {
    type: typeof ACCOUNT_ACTIVATE_RESOLVED;
}


/** SEND INVITE **/
export const HANDLE_ACCOUNT_SEND_INVITE = "HANDLE_ACCOUNT_SEND_INVITE";

export interface HandleAccountSendInvite {
    type: typeof HANDLE_ACCOUNT_SEND_INVITE;
    payload: { id: number };
}

export const ACCOUNT_SEND_INVITE_PENDING = "ACCOUNT_SEND_INVITE_PENDING";

export interface AccountSendInvitePending {
    type: typeof ACCOUNT_SEND_INVITE_PENDING;
}

export const ACCOUNT_SEND_INVITE_REJECTED = "ACCOUNT_SEND_INVITE_REJECTED";

export interface AccountSendInviteRejected {
    type: typeof ACCOUNT_SEND_INVITE_REJECTED;
    payload: object;
}

export const ACCOUNT_SEND_INVITE_RESOLVED = "ACCOUNT_SEND_INVITE_RESOLVED";

export interface AccountSendInviteResolved {
    type: typeof ACCOUNT_SEND_INVITE_RESOLVED;
}

/** EXPORT CSV **/
export const HANDLE_ACTIVE_EXPORT_CSV = "HANDLE_ACTIVE_EXPORT_CSV";

export interface HandleActiveExportCsv {
    type: typeof HANDLE_ACTIVE_EXPORT_CSV;
    payload: {
        isActive: number
    };
}

export type AccountsActions =
    | HandleGetAccounts
    | GetAccountsPending
    | GetAccountsResolved
    | GetAccountsRejected
    | HandleCreateAccount
    | CreateAccountPending
    | CreateAccountResolved
    | CreateAccountRejected
    | HandleUpdateAccount
    | UpdateAccountPending
    | UpdateAccountRejected
    | UpdateAccountResolved
    | ViewAccountPending
    | ViewAccountRejected
    | ViewAccountResolved
    | HandleViewAccount
    | SetSortAccounts
    | HandleAccountActivate
    | AccountActivatePending
    | AccountActivateRejected
    | AccountActivateResolved
    | AccountSendInvitePending
    | HandleAccountSendInvite
    | AccountSendInviteRejected
    | AccountSendInviteResolved
    | HandleActiveExportCsv
    //| HandleCsvAccount
   // | AccountActiveCsv
   // | AccountInactiveCsv;
