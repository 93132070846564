import React from "react";
import { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
  Stack,
  PrimaryButton,
  IPersonaSharedProps,
  Persona,
  TextField,
  PersonaPresence,
  PersonaSize,
} from "@fluentui/react";
import { useNavigate , useLocation } from "react-router-dom";
//import LocationsTable from "components/table/locations/LocationsTable";
//import { AddLocationDialog } from "components/dialogs/locations/AddLocationDialog";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { AppState } from "store/index";
// import Loader from "components/loader/Loader";
import { EmployeeActionPermission } from "hoc/withCredentials";
import {
  getModulePermissions,
  //isKarlProfile,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { handleGetLocations, handleDeleteLocation } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
import { LocationsMoreActionsSubmenu } from "components/table/locations/dropdown/LocationsMoreActionsSubmenu";
import { styles } from "components/dialogs/locations/styles";
//import { EditLocationDialog } from "components/dialogs/locations/EditLocationDialog";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";

const credentialModuleName = "Location";
export const LocationsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate ();
  const location = useLocation();

  //const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  //const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [confirmContent, setConfirmContent] = useState("");
  const [globalFilter, setGlobalFilter] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState('legalName');

  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const locations = useSelector((state: AppState) => state.locations.locations) as any; 

  const [credentials, setCredentials] = useState([]);

  //useEffect(() => {
  //    setIsOpenAddDialog(Boolean(history.location?.state));
  //},[history.location])


  //const handleOnDismiss = () => {
  //    setIsOpenAddDialog(false);
  //};

  //const handleOnEditDismiss = () => {
  //    setIsOpenEditDialog(false);
  //};

  const handleLocationNameFilter = (e) => {
      setGlobalFilter(e.target.value);
  };

  useEffect(() => {
    if (user && user.auths && !isUserMaster(user.roles)) {
      setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (locations && locations.results.length > 0) {

          let results = locations.results.map(function (item) {
              item["address"] = item.city + ', ' + item.state + ', ' + item.zip;
              return item;
          })

          setLocationList(results);
      }
  }, [locations]);


  useEffect(() => {
      dispatch<any>(handleGetLocations());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //const header = (
  //    <div className="table-header">
  //        {/*<Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />*/}
  //        <span className="p-input-icon-left">
  //            <i className="pi pi-search" />
  //            {/*<InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />*/}
  //            <InputText type="search" onChange={(e) => handleLocationNameFilter(e.target.value)} placeholder="Search by location" />
  //        </span>
  //    </div>
  //  );

    const header = (
        <div className="table-header">
            <TextField
                value={globalFilter}
                type="search"
                label="Search Locations"
                className="locationSearch"
                onChange={(e) => handleLocationNameFilter(e)}
            />
        </div>
    );
   
    const legalNameBodyTemplate = (rowData) => {

        const examplePersona: IPersonaSharedProps = {
            imageUrl: rowData.logo
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${rowData.logo}`
                : `${require("assets/building-image-placeholder.jpg")}`,
            text: rowData.legalName,
        };

       return (
           <React.Fragment>
               <span className="p-column-title" style={{ float:"left" }}>Location Name</span>
               <div>
                    <Persona
                        {...examplePersona}
                       styles={styles.personInGridStyles}
                       size={PersonaSize.size48}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is dnd"
                   />
                </div>


           </React.Fragment>
       );
   };

   const handleEditLocation = (id) => {
    const state = {
        searchInput: globalFilter,
        pathfrom: '/locations',
        workbookName: 'locations',
        sortOrder,
        sortField,
    }
    navigate(`/${id}/location-detail`, { state });    
   };

    const handleRowClick = (event) => {
       let target = event.originalEvent.target;
       if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
           target?.lastElementChild?.lastElementChild?.firstElementChild.click();
       } else {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_LOCATION_VIEW?.toString())) : true; 
            if (cred || role) {
                handleEditLocation(event.data.id);
            }
       }
   };

   const handleRemoveLocation = (id) => {
      
      setLocationId(id);
      setConfirmContent("Are you sure you want to delete this location?");
      setIsConfirmBoxOpen(true);
   };
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <LocationsMoreActionsSubmenu
                        modulePermissions={credentials}
                        locationId={rowData.id}
                        onEdit={(locationId) => handleEditLocation(locationId)}
                        onDelete={(locationId) => handleRemoveLocation(locationId)}
                    />
                )}

                
            </React.Fragment>
        );
    };

  const table_columns = {
    "legalName": "Location Name", 
    "address":"City, State, Zip", 
    "action": "Actions"
  }

  const handleColumnSort = (e) => {
      setSortField(e.sortField);
      setSortOrder(e.sortOrder);
  }

    useEffect(() => {
        const stateVal: any = location?.state
        console.log('stateVal-mj', stateVal);
        if (stateVal && stateVal.searchInput) {
            setGlobalFilter(stateVal.searchInput);
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }
    }, [location]);

  return (
    <>
      {/*<Loader show={loading} />*/}
          <Stack horizontalAlign="start">
                {/* <Stack className="goBackSettings">
                    <GoBackInfo mainPage="Settings" secondPage="Locations" />
                </Stack> */}
              <Row >
                  <Col>
                    <Row className="pageTitleRow">
                  <Col className="alignHeaders">
                      <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Locations</CustomFontText>
                      {credentials && user.roles && (                              
                            <EmployeeActionPermission
                                permissionList={user.aggregatedDefaultPolicies}
                                alias={emplyeePermissionsAction.SETTING_LOCATION_ADD}
                                roles={user.roles}
                            >
                              <PrimaryButton
                                 style={{ marginBottom: "-2px"}}
                                 onClick={() => { navigate(`/add-location-detail`); }}
                                 text="Add Location"
                                 className="addInventoryBtnDesk"
                              />
                          </EmployeeActionPermission>
                      )}
                  </Col>
              </Row>

                      {/*<AddLocationDialog
                        isOpen={isOpenAddDialog}
                  onDismiss={() => handleOnDismiss()}
                    />
                    <EditLocationDialog
                        locationId={locationId}
                  isOpen={isOpenEditDialog}
                  onDismiss={handleOnEditDismiss}
                    />*/}
                    <ConfirmDialog
                        cancelBtnLabel={confirmContent !== "Are you sure you want to delete this location?"?"Close":"No"}
                        confirmBtnLabel="Yes"
                        loading={false}
                        description={confirmContent}
                        title="Confirm delete location"
                        onlyDismiss={confirmContent !== "Are you sure you want to delete this location?"}
                        isOpen={isConfirmBoxOpen}
                        onAction={() => dispatch<any>(handleDeleteLocation(locationId, (msg) => {
                            if (msg) {
                                setConfirmContent(msg);
                            }
                            else {
                                setIsConfirmBoxOpen(false);
                            }

                  }))}
                  onDismiss={() => setIsConfirmBoxOpen(false)}
                    />
                    <div className="datatable-filter-demo locations">
                          <CustomTable
                              column_list={table_columns}
                              data={locationList}
                              header={header}
                              sortField={sortField}
                              sortOrder={sortOrder}
                              onSort={handleColumnSort}
                              customTemplates={{
                                  "legalName": legalNameBodyTemplate,
                                  "action": actionBodyTemplate,
                              }}
                              emptyMessage="No locations found."
                              onRowClick={(event) => handleRowClick(event)}
                              globalFilter={globalFilter} />
                      </div>
                  </Col>
              </Row>
          </Stack>
    </>
  );
};
