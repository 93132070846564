import {
    FontWeights,
    IconButton,
    //DefaultButton,
    Modal,
    PrimaryButton,
    TextField,
    getTheme,
    mergeStyleSets,
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Stack } from "@fluentui/react/lib/Stack";
import { CustomFontText } from "components/customFontText/CustomFontText";
import VinScanner from "components/dialogs/barCodeScanner/VinScanner.js";
import { styles } from "components/dialogs/employees/styles";
import { trimTypes } from "constants/constants";
import { cancelIcon } from "constants/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { handleGetDTVehicleTrimUsingVin, handleGetDealerTradeInfo, handleUpdateDTVehicleStockItem } from "store/dealTracker/dealTracker.action";
import * as Yup from "yup";
//import { getVehicleVinEditPermission } from "utils/permissions/permissionsHelpers";
import { Link } from "@fluentui/react/lib/Link";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { Dropdown as PRDropdown } from 'primereact/dropdown';

interface IProps {
    onDismiss: (res) => void;
    isOpen,
    openTrimDialog: (any) => void;
    addVehicleSectionIndex?: (any) => void;
    vehFound: (any) => void;
    dealerTradeId: any;
    tradeType: any;
    vehicleInfo: any;
    vehicleAction: string;
    onVinEdit: () => void;
}

const yearValues = dropDownsValues.yearNewValues.map((item) => {
    return { key: item, text: item };
});

export const EditDTVehicleInfoDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    isOpen,
    openTrimDialog,
    vehFound,
    dealerTradeId,
    tradeType,
    addVehicleSectionIndex,
    vehicleInfo,
    vehicleAction,
    onVinEdit
}) => {
    const loading = useSelector((state: AppState) => state.employees.loading);
    //const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [isVINScan, setisVINScan] = useState(false);
    const dispatch = useDispatch();
    const [vinDataError, setvinDataError] = useState(false);
    const [vehicleDetails, setVehicleDetails] = useState(null);



    useEffect(() => {
        dispatch<any>(handleGetDealerTradeInfo({
            id: dealerTradeId, callback: (res) => {
                if (res) {

                    if (vehicleAction === 'vehicleIn') {
                        const vehIn = res.vehicleIn ? res.vehicleIn : null;
                        setVehicleDetails(vehIn);
                    } else if (vehicleAction === 'vehicleOut') {

                        const vehOut = res.vehicleOut ? res.vehicleOut : null;
                        setVehicleDetails(vehOut);
                    }

                }
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formik = useFormik({
        initialValues: {
            orderNumber: vehicleInfo.orderNumber,
            vin: vehicleInfo.vin,
            stockNumber: vehicleInfo.stockNumber,
            year: vehicleInfo.year,
            make: vehicleInfo.make,
            model: vehicleInfo.model,
        },
        validationSchema: Yup.object({
            orderNumber: Yup.string(),
            //vin: Yup.string().vinOnly(),
            stockNumber: Yup.string(),
            year: Yup.string(),
            make: Yup.string(),
            model: Yup.string(),

        }),
        onSubmit: (values) => {
            const formData = {
                orderNumber: values.orderNumber,
                style: vehicleInfo.style,
                vin: values.vin,
                year: values.year,
                make: values.make,
                model: values.model,
                stockNumber: values.stockNumber,
            };
            const trimType = trimTypes.karl;
            if (vehicleInfo.vin && vehicleDetails?.isAddedByVin) {
                formData['vin'] = vehicleInfo.vin;
                updateVehicleDetails(formData);
            } else {
                // handle 409
                if (values.vin && vehicleDetails?.isAddedByVin) {
                    dispatch<any>(handleGetDTVehicleTrimUsingVin({
                        vin: values.vin, trimType, id: dealerTradeId, tradeType,
                        callback: (trimData) => {
                            if (!trimData.status) {
                                if (trimData.style.length === 0) {
                                    setvinDataError(true);
                                } else if (trimData.style.length > 1) {
                                    const values = trimData.style.map((item, index) => {
                                        return { key: item, text: item };
                                    });
                                    openTrimDialog({
                                        vin: formData.vin,
                                        trim: values,
                                        ymm: null,
                                        order: formData.orderNumber,
                                        stock: formData.stockNumber,
                                        vehicleId: vehicleInfo.id
                                    });

                                } else if (trimData.style.length === 1) {
                                    //let vinTrim = {
                                    //    style: trimData.style[0],
                                    //    vin: values.vin
                                    //}
                                    formData['style'] = trimData.style[0];
                                    updateVehicleDetails(formData);
                                }
                            } else if (trimData.status === 409) {
                                // onDismiss('Failed to Save Data.');
                                vehFound(trimData.data);
                            }
                        }
                    }));
                } else if (values.vin && !vehicleDetails?.isAddedByVin) {
                    updateVehicleDetails(formData);
                } else {
                    updateVehicleDetails(formData);
                }
            }
        }
    });

    useEffect(() => {
        formik.setValues(
            {
                ...formik.values,
                "vin": vehicleInfo.vin,
            },
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleInfo])

    const updateVehicleDetails = (formData) => {
        dispatch<any>(handleUpdateDTVehicleStockItem({
            id: dealerTradeId,
            vehicleAction,
            vehicleId: vehicleInfo.id,
            formData,
            callback: (res) => {
                if (res) {
                    onDismiss(res);
                } else if (res.status === 409) {
                    // onDismiss('Failed to Save Data.');
                    vehFound(res.data);
                } else {
                    onDismiss('Failed to Save Data.');
                }
            }
        }));
    }

    const handleYearChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                year: value ? value : '',
            }
        );

    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const changeToUppercase = (e: any) => {
        const re = /^[a-zA-Z0-9]*$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            //this.setState({ value: e.target.value })
            formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
        }
    };

    const handleUpdateVehicle = () => {
        formik.handleSubmit()
    }

    const handleScanVIN = (bool) => {
        if (vehicleInfo?.vin && vehicleInfo?.vin !== '') {
            // On disabled scenario
        } else {
            setvinDataError(false);
            setisVINScan(bool)
        }
    }
    const handleEditVehicleFlow = () => {
        if (vehicleDetails?.isAddedByVin) {
            addVehicleSectionIndex(1)
        } else {
            addVehicleSectionIndex(2)
        }
    };

    const onDismissModal = () => {
        if (isVINScan) {
            handleScanVIN(false);
        }
        else {
            onDismiss('')
        }
    }

    const onScanSubmit = (vin) => {
        let capturedVIN = vin.split(',');
        // When scanned with QR Code, contains multiple options. so picking VIn data alone
        let truncatedVIn = capturedVIN[0];
        if (capturedVIN[0].length === 18) {
            // When contains LEADING letter I
            truncatedVIn = capturedVIN[0].substring(1);
        }
        formik.setFieldValue("vin", truncatedVIn.trim().toUpperCase());
        handleScanVIN(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismissModal}
            isBlocking={true}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord vehicleInfoHeights updateEmployeeDialogHeight"
            containerClassName={`${contentStyles.container} inv-srch-mod ${isVINScan ? 'scanner' : ''}`}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <CustomFontText className="formTitle">Edit Vehicle Info</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div>{
                vinDataError ?
                    <div>
                        <Row>
                            <Col>
                                <p className="genericErrorColor"><span>VIN is not recognized, please re-enter VIN.</span></p>
                            </Col>
                        </Row>
                    </div> : null
            }
            </div>
            {isVINScan ?
                (<VinScanner onScanSubmit={(vin) => onScanSubmit(vin)} />)
                :
                <>
                    <div className={contentStyles.body} >
                        <Stack>
                            <Row justify="center">
                                <Col md={9}>
                                    <TextField
                                        id="stockNumber"
                                        name="stockNumber"
                                        label="Stock No"
                                        placeholder=""
                                        autoComplete="off"
                                        value={formik.values.stockNumber}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched && formik.touched.stockNumber &&
                                            formik.errors && formik.errors.stockNumber &&
                                            formik.errors.stockNumber.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col md={9} >
                                    <TextField
                                        id="orderNumber"
                                        name="orderNumber"
                                        label="Order No"
                                        placeholder=""
                                        autoComplete="off"
                                        value={formik.values.orderNumber}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched && formik.touched.orderNumber &&
                                            formik.errors && formik.errors.orderNumber &&
                                            formik.errors.orderNumber.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="inventoryRowSection" justify="center">
                                <Col md={9} >
                                    <div className="yearMake">
                                        <h5 className="primeDropdownLabel" style={vehicleDetails?.isAddedByVin ? { color: 'gray', margin: "5px 0" } : { margin: "5px 0" }} >Year</h5>
                                        <PRDropdown
                                            id="model_addInventory"
                                            value={formik.values.year}
                                            className="invMakeDropdown labelTextBorder full-width"
                                            options={yearValues}
                                            onChange={(e) => handleYearChange(e.value)}
                                            optionLabel="text"
                                            optionValue="text"
                                            scrollHeight="150px"
                                            virtualScrollerOptions={{ itemSize: 31 }}
                                            disabled={vehicleDetails?.isAddedByVin}
                                            placeholder="Select a Year"
                                            appendTo="self"
                                            filter
                                            resetFilterOnHide={true}
                                            required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col md={9} >
                                    <TextField
                                        id="make"
                                        name="make"
                                        label="Make"
                                        placeholder=""
                                        autoComplete="off"
                                        value={formik.values.make}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        disabled={vehicleDetails?.isAddedByVin}
                                        onBlur={formik.handleBlur}
                                    /* errorMessage={
                                        formik.touched && formik.touched.orderNumber &&
                                        formik.errors && formik.errors.orderNumber &&
                                        formik.errors.orderNumber.toString()
                                    } */
                                    />
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col md={9} >
                                    <TextField
                                        id="model"
                                        name="model"
                                        label="Model"
                                        placeholder=""
                                        autoComplete="off"
                                        value={formik.values.model}
                                        disabled={vehicleDetails?.isAddedByVin}
                                        //required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    /* errorMessage={
                                        formik.touched && formik.touched.orderNumber &&
                                        formik.errors && formik.errors.orderNumber &&
                                        formik.errors.orderNumber.toString()
                                    } */
                                    />
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col md={9} >
                                    {
                                        vehicleDetails?.isAddedByVin ?
                                            <>
                                                <div>
                                                    <div style={{ width: "180px" }}>
                                                        <TextField
                                                            id="vin"
                                                            name="vin"
                                                            label="VIN"
                                                            iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true) }}
                                                            value={formik.values.vin}
                                                            disabled={true}
                                                            onChange={changeToUppercase}
                                                            onBlur={formik.handleBlur}
                                                            errorMessage={
                                                                formik.touched.vin &&
                                                                formik.errors.vin &&
                                                                formik.errors.vin.toString()
                                                            }

                                                        />
                                                    </div>
                                                    <PrimaryButton
                                                        //disabled={!formik.dirty || !formik.isValid}
                                                        text="Edit"
                                                        onClick={onVinEdit}
                                                        className="vehicle-vin-edit-btn"
                                                    />
                                                </div>
                                            </> :
                                            <>
                                                <TextField
                                                    id="vin"
                                                    name="vin"
                                                    label="VIN"
                                                    iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true) }}
                                                    value={formik.values.vin}
                                                    //disabled={(dealTrackerData?.vehicle?.vin) ? dealTrackerData.vehicle.vin !== '' : false}
                                                    onChange={changeToUppercase}
                                                    onBlur={formik.handleBlur}
                                                    errorMessage={
                                                        formik.touched.vin &&
                                                        formik.errors.vin &&
                                                        formik.errors.vin.toString()
                                                    }
                                                // style={{ width: "calc(100% - 130px)" }}
                                                />
                                            </>
                                    }
                                </Col>
                            </Row>
                            <Row className="noVin" justify="center">
                                <Col md={9}>
                                    <Link onClick={handleEditVehicleFlow} style={{ float: "right", marginTop: "10px" }}>
                                        {vehicleDetails?.isAddedByVin ? "Add vehicle manually" : "Add vehicle by VIN"}
                                    </Link>
                                </Col>
                            </Row>
                        </Stack>
                    </div>
                    <div className={contentStyles.footer}>
                        <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">

                            <PrimaryButton
                                //disabled={!formik.dirty || !formik.isValid}
                                onRenderIcon={renderSpinner}
                                text="Save"
                                onClick={handleUpdateVehicle}
                            />
                        </Stack>
                    </div>
                </>
            }
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 400,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
