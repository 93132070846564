import {
  // IRegister,
  ILoginResponse,
  ILogin,
  IResetPassword, IConfirmAccount, IConfirmEmail, IResendEmail
} from "types/authTypes";
import * as actions from "./auth.types";

export const setIdForLoggedUser = (
  id: number
): actions.setIdForLoggedUserResolved => {
  return {
    type: actions.SET_ID_FOR_LOGGED_USER_RESOLVED,
    payload: id,
  };
};

export const setExpPasswordForLoggedUser = (
  date: string
): actions.setExpPasswordForLoggedUser => {
  return {
    type: actions.SET_EXP_PASSWORD_FOR_LOGGED_USER_RESOLVED,
    payload: date,
  };
};

// export const handleRegister = ({
//   email,
//   password,
//   firstName,
//   lastName,
//   nickName,
//   token,
//   consentNewsOffersUpdates
// }: IRegister): actions.HandleRegister => ({
//   type: actions.HANDLE_REGISTER,
//         payload: { email, password, firstName, lastName, nickName, token, consentNewsOffersUpdates },
// });

// export const pendingRegister = (): actions.RegisterPending => {
//   return {
//     type: actions.REGISTER_PENDING,
//   };
// };

// export const resolvedRegister = (): any => {
//   return {
//     type: actions.REGISTER_RESOLVED,
//   };
// };

// export const successEmailRegister = ({email}): any => {
//   return {
//     type: actions.SUCCESS_REGISTER_EMAIL,
//     payload: {email}
//   };
// };

// export const rejectedRegister = (
//   errors: {
//     propertyPath: string;
//     title: string;
//     parameters: object;
//     type: string;
//   }[]
// ): actions.RegisterRejected => {
//   return {
//     type: actions.REGISTER_REJECTED,
//     payload: errors,
//   };
// };

export const handleLogin = ({
  email,
  password,
  remember,
}: ILogin): actions.HandleLogin => {
  return {
    type: actions.HANDLE_LOGIN,
    payload: { email, password, remember },
  };
};

export const pendingLogin = (): actions.LoginPending => {
  return {
    type: actions.LOGIN_PENDING,
  };
};

export const clearServerErrorMessage = (): actions.ClearServerMessage => {
    return {
        type: actions.CLEAR_SERVER_MESSAGE,
    };
};

export const resolvedLogin = ({
  token,
  refresh_token,
}: ILoginResponse): actions.LoginResolved => {
  return {
    type: actions.LOGIN_RESOLVED,
    payload: { token, refresh_token },
  };
};

export const rejectedLogin = (error: Error | string): actions.LoginRejected => {
  return {
    type: actions.LOGIN_REJECTED,
    payload: error,
  };
};

export const handleLoginByToken = (location): actions.HandleLoginByToken => {
  return {
    type: actions.HANDLE_LOGIN_BY_TOKEN,
    payload: location,
  };
};

export const loginByTokenPending = (): actions.LoginByTokenPending => {
  return {
    type: actions.LOGIN_BY_TOKEN_PENDING,
  };
};

export const loginByTokenResolved = ({
  token,
  refresh_token,
}: ILoginResponse): actions.LoginByTokenResolved => {
  return {
    type: actions.LOGIN_BY_TOKEN_RESOLVED,
    payload: { token, refresh_token },
  };
};

export const loginByTokenRejected = (error: Error | string) => {
  return {
    type: actions.LOGIN_BY_TOKEN_REJECTED,
    payload: error,
  };
};

export const handleLogout = (isSessionTimeout = false): actions.HandleLogout => {
  return {
      type: actions.HANDLE_LOGOUT,
      payload: isSessionTimeout
  };
};

export const logoutPending = (): actions.LogoutPending => {
  return {
    type: actions.LOGOUT_PENDING,
  };
};

export const logoutResolved = (): actions.LogoutResolved => {
  return {
    type: actions.LOGOUT_RESOLVED,
  };
};

export const handleRefreshToken = (): actions.HandleTokenRefresh => {
  
  return {
    type: actions.HANDLE_TOKEN_REFRESH,
  };
};

export const handleLogoutAndLogin = (): actions.HandleLogoutAndLogin => {
    return {
        type: actions.HANDLE_LOGOUT_LOGIN,
    };
};
// RESET PASSWORD REQUEST
export const handleResetPasswordRequest = (
  email: string
): actions.HandleResetPasswordRequest => {
  return {
    type: actions.HANDLE_RESET_PASSWORD_REQUEST,
    payload: email,
  };
};

export const ResetPasswordRequestPending = (): actions.ResetPasswordRequestPending => {
  return {
    type: actions.RESET_PASSWORD_REQUEST_PENDING,
  };
};

export const ResetPasswordRequestResolved = (email: string): actions.ResetPasswordRequestResolved => {
  return {
    type: actions.RESET_PASSWORD_REQUEST_RESOLVED,
    payload: email
  };
};
export const ResetPasswordRequestRejected = (): actions.ResetPasswordRequestRejected => {
  return {
    type: actions.RESET_PASSWORD_REQUEST_REJECTED,
  };
};

// Reset password Link expired flow
export const ResetPasswordLinkExpired = ({
  code,
  data
}): actions.ResetPasswordLinkExpired => {
  return {
    type: actions.RESET_PASSWORD_LINK_EXPIRED,
    payload: { code, data }
  };
};

// RESET PASSWORD CONFIRM
export const handleResetPasswordConfirm = (
  obj: IResetPassword
): actions.HandleResetPasswordConfirm => {
  return {
    type: actions.HANDLE_RESET_PASSWORD_CONFIRM,
    payload: obj,
  };
};
export const resetPasswordConfirmPending = (): actions.ResetPasswordConfirmPending => {
  return {
    type: actions.RESET_PASSWORD_CONFIRM_PENDING,
  };
};

export const resetPasswordConfirmResolved = (): actions.ResetPasswordConfirmResolved => {
  return {
    type: actions.RESET_PASSWORD_CONFIRM_RESOLVED,
  };
};
export const resetPasswordConfirmRejected = (
  error: any
): actions.ResetPasswordConfirmRejected => {
  return {
    type: actions.RESET_PASSWORD_CONFIRM_REJECTED,
    payload: error,
  };
};

/**  CONFIRM_ACCOUNT */
export const handleConfirmAccount = ({
  email
  // confirmCode
}: IConfirmAccount): actions.HandleConfirmAccount => {
  return {
    type: actions.HANDLE_CONFIRM_ACCOUNT,
    payload: { email }, //confirmCode
  };
};

/**  CONFIRM_EMAIL */
export const handleConfirmEmail = ({
  email,
  verificationToken
}: IConfirmEmail): actions.HandleConfirmEmail => {
  return {
    type: actions.HANDLE_CONFIRM_EMAIL,
    payload: { email, verificationToken } //confirmCode
  };
};

/** RESEND EMAIL */
export const handleResendEmailConfirmation = ({email}: IResendEmail): actions.HandleResendEmail => {
  return {
      type: actions.HANDLE_RESEND_EMAIL_CONFIRMATION,
      payload: {email}
  };
};

/** CONFIRM EMAIL SUCCESS SCREEN */
export const confirmEmailSuccessStatus = ({
  code,
  data
}): actions.HandleConfirmEmailStatus => {
  return {
    type: actions.HANDLE_CONFIRM_EMAIL_STATUS,
    payload: {code, data},
  };
};

/** CONFIRM EMAIL SUCCESS SCREEN */
export const reConfirmEmailSuccessStatus = ({
  code,
  data
}): actions.HandleReConfirmEmailStatus => {
  return {
    type: actions.HANDLE_RECONFIRM_EMAIL_STATUS,
    payload: {code, data},
  };
};

export const pendingConfirmAccount = (): actions.ConfirmAccountPending => {
  return {
    type: actions.CONFIRM_ACCOUNT_PENDING,
  };
};

export const resolvedConfirmAccount = (): actions.ConfirmAccountResolved => {
  return {
    type: actions.CONFIRM_ACCOUNT_RESOLVED,
  };
};

export const rejectedConfirmAccount = (
  error: Error | string
): actions.ConfirmAccountRejected => {
  return {
    type: actions.CONFIRM_ACCOUNT_REJECTED,
    payload: error,
  };
};
