import * as actions from "./config.types";

interface IState {
    updateLoading: boolean,
    loading: boolean,
    config: any | null,
    error: null | object,
}

const initialState: IState = {
    updateLoading: false,
    loading: false,
    config: null,
    error: null,
};


export const ConfigReducer = (
    state: IState = initialState,
    action: actions.ConfigActions
) => {
    switch (action.type) {
        case actions.GET_CONFIG_PENDING: {
            return {
                ...state,
                loading: true,
            }
        }
        case actions.GET_CONFIG_RESOLVED: {
            return {
                ...state,
                loading: false,
                config: action.payload
            }
        }
        case actions.GET_CONFIG_REJECTED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        case actions.UPDATE_CONFIG_PENDING: {
            return {
                ...state,
                updateLoading: true,
            }
        }
        case actions.UPDATE_CONFIG_RESOLVED: {
            return {
                ...state,
                updateLoading: false,
            }
        }
        case actions.UPDATE_CONFIG_REJECTED: {
            return {
                ...state,
                updateLoading: false,
                error: action.payload,
            };
        }

        default:
            return state;
    }
};

