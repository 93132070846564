import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppState } from "store";
import { useNavigate , useLocation } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { convertUTCToLocalDateOnlyModified } from "utils/dateTimeFunctions";
import masterDropdownValues from "constants/masterDropdownValues";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { getModulePermissions, isUserMaster } from "utils/permissions/permissionsHelpers";
import { handleGetWriteUpsList } from "store/dealTracker/dealTracker.action";
import {
    HANDLE_GET_EMPLOYEES,
    HANDLE_GET_EMPLOYEES_FINANCE
} from "store/employees/employees.types";
import {
    Stack,
    IStackStyles,
    //TextField,
    PrimaryButton,
    Link
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from 'primereact/dropdown';
import { handleGetLocations } from "store/locations/locations.action";
import { Calendar } from 'primereact/calendar';
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { Paginator } from 'primereact/paginator';
import moment from "moment";
import { DebounceInput } from 'react-debounce-input';
import { IdleTimerAutoReload } from "components/IdleTimer/IdleTimerAutoReload";
import { MultiSelect } from "primereact/multiselect";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "Inventory";
export const WriteUpsWorkbookV2Page = () => {
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const employeesSales = useSelector((state: AppState) => state.employees.employees) as any;
    const employeesFinance = useSelector((state: AppState) => state.employees.employeesFinance) as any;
    const isMobile = useMediaQuery({ maxWidth: 960 });

    const navigate = useNavigate ();
    const dispatch = useDispatch();
    const location = useLocation();

    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const [credentials, setCredentials] = useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [statusList, setStatusList] = React.useState([]);
    const [search, setGLFilter] = useState('');
    const [locationId, setLocationFilter] = useState(0);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [departmentId, setDepartmentFilter] = useState(0);
    const [salesPerson1Id, setSales1Filter] = useState(0);
    const [salesPerson2Id, setSales2Filter] = useState(0);
    const [financePerson1Id, setFinance1Filter] = useState(0);
    const [financePerson2Id, setFinance2Filter] = useState(0);
    const [deliverycode, setdeliverycode] = useState([]);
    const [cdrId, setCDRFilter] = useState(null);
    const [fromStatusDate, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [toStatusDate, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [statusId, setDealStatus] = useState(0);
    const [salesPersons, setSalesPersons] = useState(null);
    const [financePersons, setFinancePersons] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('statusDateTime');
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(1);
    const [statePageNumber, setStatePageNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (employeesSales && employeesSales.results.length > 0) {
            let salesPersonList = [];
            const sales = [...employeesSales.results];
            sales.map((item: any) => {
                return salesPersonList.push({ id: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            salesPersonList.unshift({ id: 0, text: 'All' });
            setSalesPersons(salesPersonList);
        } else {
            setSalesPersons([]);
        }
    }, [employeesSales]);

    useEffect(() => {
        if (employeesFinance && employeesFinance.results.length > 0) {
            let finance = [];
            const fiananceList = [...employeesFinance.results];
            fiananceList.map((item: any) => {
                return finance.push({ id: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            finance.unshift({ id: 0, text: 'All' });
            setFinancePersons(finance);
        } else {
            setFinancePersons([]);
        }
    }, [employeesFinance]);

    useEffect(() => {
        if (departments && departments.length > 0) {
            departments.unshift({ id: 0, name: 'All' });
        }
    }, [departments]);


    const handleSearchChange = (event: any) => {
        const inputValue = event.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }
        if(event.target.value === ''){
			setGLFilter('');
	    }
    };

    const onDeliveryCodeChange = (e: { value: any }) => {
        setdeliverycode([...e.value]);
    }


    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const onDepartmentChange = (e: { value: any }) => {
        setDepartmentFilter(e.value);
    }

    const onSales1Change = (e: { value: any }) => {
        setSales1Filter(e.value);
    }

    const onFinance1Change = (e: { value: any }) => {
        setFinance1Filter(e.value);
    }

    const onSales2Change = (e: { value: any }) => {
        setSales2Filter(e.value);
    }

    const onFinance2Change = (e: { value: any }) => {
        setFinance2Filter(e.value);
    }

    const onCDRChange = (e: { value: any }) => {
        setCDRFilter(e.value);
    }
   
    const onStatusChange = (e: { value: any }) => {
        setDealStatus(e.value);
    }

    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
    };

    const handleResetWorkbook = () => {
        setGLFilter('');
        setLocationFilter(primaryLocation);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setdeliverycode([]);
        setDealStatus(0);
        setDepartmentFilter(0);
        setCDRFilter(null);
        setSales1Filter(0);
        setFinance1Filter(0);
        setSales2Filter(0);
        setFinance2Filter(0);
        setSortOrder(-1);
        setLimit(25);
        setSortField('statusDateTime');
    };

    
    const getDeliveryCode = (codeId) => {
        if (codeId) {
            return masterDropdownValues.deliveryCodes.filter(x => x.key === codeId)[0].shortText;
        }
        return '';
    }

    const exportExcel = () => {
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetWriteUpsList({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
                        let list = response.results.filter(x => x.dealDelivery);
                        if (list) {
                            const exportData = list.map((item) =>
                                ({
                                    "Deal Number": item.dealNumber,
                                    "Status Date": item.dealDelivery.statusDateTime ? dateTimeStamp(item.dealDelivery.statusDateTime) : '',
                                    "Status": getDeliveryStatus(item.dealDelivery.status),
                                    "Year": item.vehicle?.year,
                                    "Model": item.vehicle?.model,
                                    "Stock / Order": ((item?.stockNumber) ? item?.stockNumber : '-') + '' + ((item?.orderNumber) ? ' / '+item?.orderNumber : ''),
                                    "Location / Department": ((item.location?.legalName) ? item.location.legalName : '-') + ' / ' + (item.dealDelivery.department ? item.dealDelivery.department.name : '-'),
                                    "Customer": item.customer && (item.customer.customerType === 1 ? (item.customer?.firstName +' '+ item.customer?.lastName) :
                                        (item.customer?.businessName)),
                                    "Request Delivery": convertUTCToLocalDateOnlyModified(item.dealDelivery.requestDeliveryDate),
                                    "Delivery Type": getDeliveryTypeName(item.dealDelivery.deliveryType),
                                    "Sales": getNames(item.salesPerson1, item.salesPerson2),
                                    "Finance": getNames(item.financePerson1, item.financePerson2),
                                    "SFE": status[item.dealDelivery.sfe],
                                    "CDR": status[item.dealDelivery.cdr],
                                    "CDR Date": item.dealDelivery.cdrDate ? convertUTCToLocalDateOnlyModified(item.dealDelivery.cdrDate) : '',
                                    "Flt": status[item.dealDelivery.fleet],
                                    "CPO": status[item.dealDelivery.cpo],
                                    "Delivery Code": item.dealDelivery?.deliveryCode ? getDeliveryCode(item.dealDelivery?.deliveryCode):'',
                                    "Desking Note": item.dealDelivery?.lastDeskingNote,
                                    "Finance Note": item.dealDelivery?.lastFinanceNote,
                                    "Gross": item.dealDelivery.grossProfit && currencyNegValFractionalFormat(item.dealDelivery.grossProfit),
                                }));

                            import('xlsx').then(xlsx => {
                                const worksheet = xlsx.utils.json_to_sheet(exportData);
                                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                saveAsExcelFile(excelBuffer, 'write-ups-workbook');
                            });
                        }
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const logBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log</span>
                {rowData.statusDateTime}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </React.Fragment>
        );
    };

    const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Year</span>
                {rowData.year}
            </React.Fragment>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Model</span>
                {rowData.model}
            </React.Fragment>
        );
    };

    const dealBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };

    const custBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData._customer}
            </React.Fragment>
        );
    };

    const stockBodyTemplate = (rowData) => {
        
        return (
            <React.Fragment>
                <span className="p-column-title">Stock / Order</span>
                <span>{rowData.stockNumber} </span>
                {
                    rowData.orderNumber ?
                        <>
                            / <span className="dealDeptRowVal separateRowVal"> {rowData.orderNumber} </span>
                        </>
                        : ''
                }
            </React.Fragment>
        );
    };

    const locBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Loc/Dept</span>
                <span className="loc"> {rowData.locationName} </span> / <span className="dealDeptRowVal separateRowVal"> {rowData.dept} </span>
            </React.Fragment>
        );
    };

    const salesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales</span>
                <span className={"separateRowVal"}>
                    {rowData.salesPerson1Name}
                </span>
                <span className={"one separateRowVal"}>
                    {rowData.salesPerson2Name}
                </span>
            </React.Fragment>
        );
    };

    const financeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance</span>
                <span className={"separateRowVal"}>
                    {rowData.financePerson1Name}
                </span>
                <span className={"one separateRowVal"}>
                    {rowData.financePerson2Name}
                </span>
            </React.Fragment>
        );
    };

    const reqDelBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <span className="p-column-title">Request Delivery</span>
                {rowData.requestDeliveryDate}
            </React.Fragment>
        );
    };

    const deliveryTypeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Delivery Type</span>
                {rowData.deliveryType}
            </React.Fragment>
        );
    };

    const sfeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">SFE</span>
                {rowData.sfe}
            </React.Fragment>
        );
    };

    const cdrBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR</span>
                {rowData.cdr}
            </React.Fragment>
        );
    };

    const cdrDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR Date</span>
                {rowData.cdrDate}
            </React.Fragment>
        );
    };

    const fltBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Flt</span>
                {rowData.fleet}
            </React.Fragment>
        );
    };

    const cpoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CPO</span>
                {rowData.cpo}
            </React.Fragment>
        );
    };

    const deskingNoteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Desking Note</span>
                <div className="notes-tbl-col">{rowData.lastDeskingNote}</div>
            </React.Fragment>
        );
    };

    const financeNoteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Note</span>
                <div className="notes-tbl-col">{rowData.lastFinanceNote}</div>
            </React.Fragment>
        );
    };

    const grossBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Gross</span>
                <div className="notes-tbl-col">{rowData.grossProfit}</div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(dealId) => editDeals(dealId)}
                    />
                )}
            </React.Fragment>
        );
    };

    const header = (
        <>
            {isMobile ?
                <div>
                    <Row>
                        <Col>
                            <div className="locationDropPosition">
                                {/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
                                <label className="lbl-text">Search</label>
                                <div className="writeupsearchInput ms-TextField-fieldGroup debounce-input-group">
                                    <DebounceInput
                                        minLength={1}
                                        className="writeupsearchInput ms-TextField-field "
                                        value={search}
                                        debounceTimeout={750}
                                        onChange={(e) => handleSearchChange(e)} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Location</label>
                                <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationId}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Department</label>
                                <Dropdown
                                    id="department"
                                    placeholder=""
                                    value={departmentId}
                                    options={departments}
                                    onChange={onDepartmentChange}
                                    optionLabel="name"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">CDR</label>
                                <Dropdown
                                    id="cdr"
                                    placeholder=""
                                    value={cdrId}
                                    options={masterDropdownValues.cdrStatus}
                                    onChange={onCDRChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Sales 1</label>
                                <Dropdown
                                    id="sales1"
                                    placeholder=""
                                    value={salesPerson1Id}
                                    options={salesPersons}
                                    onChange={onSales1Change}
                                    optionLabel="text"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Finance 1</label>
                                <Dropdown
                                    id="finance1"
                                    placeholder=""
                                    value={financePerson1Id}
                                    options={financePersons}
                                    onChange={onFinance1Change}
                                    optionLabel="text"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Sales 2</label>
                                <Dropdown
                                    id="sales2"
                                    placeholder=""
                                    value={salesPerson2Id}
                                    options={salesPersons}
                                    onChange={onSales2Change}
                                    optionLabel="text"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Finance 2</label>
                                <Dropdown
                                    id="finance2"
                                    placeholder=""
                                    value={financePerson2Id}
                                    options={financePersons}
                                    onChange={onFinance2Change}
                                    optionLabel="text"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ width: "auto" }} >
                        <Col>
                            <div className="date-filter-section">
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Status Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={fromStatusDate}
                                                showIcon
                                                onChange={(e) => handleFromLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                maxDate={toStatusDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={toStatusDate}
                                                showIcon
                                                onChange={(e) => handleToLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                minDate={fromStatusDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text">Status</label>
                                <Dropdown
                                    id="status"
                                    placeholder=""
                                    value={statusId}
                                    options={statusList}
                                    onChange={onStatusChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control cdr-cpo-dd-filter">
                                <label className="lbl-text">Delivery Code</label>
                                <MultiSelect
                                    value={deliverycode}
                                    options={masterDropdownValues.deliveryCodes}
                                    style={{ minWidth: "175px" }}
                                    onChange={onDeliveryCodeChange}
                                    placeholder="Select delivery code"
                                    optionLabel="text"
                                    optionValue="key"
                                    className="custom-p-dropdown"
                                    maxSelectedLabels={2}
                                />
                            </div>
                        </Col>
                        <Col  >
                            <PrimaryButton style={{ float: "right",marginTop:'30px' }}
                                onClick={handleResetWorkbook}
                            >
                                Reset
                            </PrimaryButton>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="display-row" style={{ marginTop: "15px" }}>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                            <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
						    </Link>
                        </Col>
                    </Row>
                </div> :
                <div>
                    <div>
                        <Row>
                            <Col md={11}>
                                <Row>
                                    <Col md={3}>
                                        <div className="table-header employeeeSearchLabelText">
                                            {/*<TextField
                                                value={search}
                                                type="search"
                                                label="Search"
                                                className="ownerSearchText"
                                                onChange={(e) => handleSearchChange(e)}
                                            />*/}
                                            <label className="lbl-text">Search</label>
                                            <div className="ms-TextField-fieldGroup debounce-input-group">
                                                <DebounceInput
                                                    minLength={1}
                                                    className="ms-TextField-field"
                                                    value={search}
                                                    debounceTimeout={750}
                                                    onChange={(e) => handleSearchChange(e)} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Location</label>
                                            <Dropdown
                                                id="locationId"
                                                placeholder=""
                                                value={locationId}
                                                options={cityDealerList}
                                                onChange={onLocationChange}
                                                optionLabel="legalName"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Department</label>
                                            <Dropdown
                                                id="department"
                                                placeholder=""
                                                value={departmentId}
                                                options={departments}
                                                onChange={onDepartmentChange}
                                                optionLabel="name"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">CDR</label>
                                            <Dropdown
                                                id="cdr"
                                                placeholder=""
                                                value={cdrId}
                                                options={masterDropdownValues.cdrStatus}
                                                onChange={onCDRChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Sales 1</label>
                                            <Dropdown
                                                id="sales1"
                                                placeholder=""
                                                value={salesPerson1Id}
                                                options={salesPersons}
                                                onChange={onSales1Change}
                                                optionLabel="text"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown "
                                                filter={true}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Finance 1</label>
                                            <Dropdown
                                                id="finance1"
                                                placeholder=""
                                                value={financePerson1Id}
                                                options={financePersons}
                                                onChange={onFinance1Change}
                                                optionLabel="text"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown"
                                                filter={true}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="date-filter-section">
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">Status Date</label>
                                                    <div style={{width:'100%'}} className="ms-TextField-fieldGroup mask-FieldGroup">
                                                        <Calendar
                                                            id="fromLogDate"
                                                            style={{ width: "100%" }}
                                                            value={fromStatusDate}
                                                            showIcon
                                                            onChange={(e) => handleFromLogDateChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            maxDate={toStatusDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">&nbsp;</label>
                                                    <div style={{width:'100%'}} className="ms-TextField-fieldGroup mask-FieldGroup">
                                                        <Calendar
                                                            id="toLogDate"
                                                            style={{ width: "100%" }}
                                                            value={toStatusDate}
                                                            showIcon
                                                            onChange={(e) => handleToLogDateChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            minDate={fromStatusDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Status</label>
                                            <Dropdown
                                                id="status"
                                                placeholder=""
                                                value={statusId}
                                                options={statusList}
                                                onChange={onStatusChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Sales 2</label>
                                            <Dropdown
                                                id="sales2"
                                                placeholder=""
                                                value={salesPerson2Id}
                                                options={salesPersons}
                                                onChange={onSales2Change}
                                                optionLabel="text"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown "
                                                filter={true}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="dd-control">
                                            <label className="lbl-text">Finance 2</label>
                                            <Dropdown
                                                id="finance2"
                                                placeholder=""
                                                value={financePerson2Id}
                                                options={financePersons}
                                                onChange={onFinance2Change}
                                                optionLabel="text"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown"
                                                filter={true}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col  md={3}>
                                        <div className="dd-control cdr-cpo-dd-filter">
                                            <label className="lbl-text">Delivery Code</label>
                                            <MultiSelect
                                                value={deliverycode}
                                                options={masterDropdownValues.deliveryCodes}
                                                style={{ minWidth: "175px" }}
                                                onChange={onDeliveryCodeChange}
                                                placeholder="Select delivery code"
                                                optionLabel="text"
                                                optionValue="key"
                                                className="custom-p-dropdown"
                                                maxSelectedLabels={2}
                                            />
                                        </div>
                                    </Col>
                                    {/*<Col md={3}>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Close Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="fromCloseDate"
                                                        style={{ width: "100%" }}
                                                        value={closeDateFromFilter}
                                                        showIcon
                                                        onChange={(e) => handleFromCloseDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={closeDateToFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="toCloseDate"
                                                        style={{ width: "100%" }}
                                                        value={closeDateToFilter}
                                                        showIcon
                                                        onChange={(e) => handleToCloseDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={closeDateFromFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>*/}
                                     <Col md={3}>
                                        <div className="dd-control">
                                            <PrimaryButton className=" writeUps-reset-btn" onClick={handleResetWorkbook}>
                                                Reset
                                            </PrimaryButton>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                    </div>
                    <div className="display-row" style={{ marginTop: "15px" }}>
                        <div>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                        </div>
                        <div>
                            <Link className="workbook-export-btn" disabled={totalRecords === 0} color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
		                    </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    );
    
    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }
    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

    const editDeals = (dealEditId) => {
        const state = {
            search,
            pathfrom: '/write-ups',
            tabIndex: 0,
            sortOrder,
            sortField,
            pages,
            limit,
            locationId,
            departmentId,
            salesPerson1Id,
            salesPerson2Id,
            financePerson1Id,
            financePerson2Id,
            deliverycode,
            cdrId,
            fromStatusDate,
            toStatusDate,
            statusId,
        }
        navigate(`/${dealEditId}/dealtracker`, {state});
    }

    const captureCellSelection = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
                editDeals(event.data.id);
        }
    };

    useEffect(() => {
        setTableData([]);
        //setDealStatus(null);
        setSortOrder(-1);
        setSortField('statusDateTime');
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        // setTableData(latestDealData);
        setcommonServerError('');
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "salesPerson" } });
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES_FINANCE, payload: { isActive: 1, role: "financePerson" } });
        dispatch<any>(handleGetSelectList('departments', 1));
        dispatch<any>(handleGetLocations())
        const statusId = [...masterDropdownValues.dealTrackerStatus];
        statusId.unshift({ key: 0, text: 'All' });
        setStatusList(statusId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user && user.roles) {
            setUserProfileLoadingFlag(true);
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    const status = ["", "No", "Yes", "UNK"];

    const getManagerName = (arr) => {
        if (arr) {
            return arr.map((manager, index) => {
                return manager.firstName + ' ' + manager.lastName + ((index === (arr.length - 1)) ? '' : ', ')
            });
        }
        return '';

    }

    const getNames = (person1, person2) => {
        let person1Name = '';
        let person2Name = '';
        if (person1) {
            person1Name = person1.firstName + " " + person1.lastName;
        }
        if (person2) {
            person2Name = person2.firstName + " " + person2.lastName;
        }
        return person1Name + ' ' + person2Name;
    }

    const getDeliveryTypeName = (typeId) => {
        if (typeId) {
            return masterDropdownValues.dealDeliveryTypes.filter(x => x.key === typeId)[0].text;
        }
        return '';
    }

    const getDeliveryStatus = (statusId) => {
        if (statusId) {
            return masterDropdownValues.dealTrackerStatus.filter(x => x.key === statusId)[0].text;
        }
        return '';
    }

    //const getStatusDate = (delivery) => {
    //    if (delivery && delivery.status) {
    //        if (delivery.status === 1)
    //            return convertUTCToLocalDateOnlyModified(delivery.pendingDate);
    //        if (delivery.status === 2)
    //            return convertUTCToLocalDateOnlyModified(delivery.orderOutDate);
    //        if (delivery.status === 3)
    //            return convertUTCToLocalDateOnlyModified(delivery.closedDate);
    //        if (delivery.status === 4)
    //            return convertUTCToLocalDateOnlyModified(delivery.voidDate);
    //    }
    //    return null;
    //}

    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setDealStatus(stateVal?.status);

            if (stateVal.filterDateLabel === 'writeUpClosedToday') {
                setLogDateFromFilter(new Date(stateVal?.filterDate));
                setlogDateToFilter(new Date(stateVal?.filterDate));

            } else if (stateVal.filterDateLabel === 'writeUpPendingToday') {
                setlogDateToFilter(new Date(stateVal?.filterDate));
            } else {
                setLogDateFromFilter(new Date(stateVal?.filterDate));
                setlogDateToFilter(new Date(stateVal?.filterDate));
            }
        }

        if (stateVal?.searchInput) {
            setGLFilter(stateVal.searchInput);
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) * stateVal.limit);
            }
        }

        if (stateVal?.locationId) {
            setLocationFilter(stateVal?.locationId);
        }
        if(primaryLocation === 0 && stateVal?.locationId === 0){
            setLocationFilter(0);
        }

        if (stateVal?.departmentId) {
            setDepartmentFilter(stateVal?.departmentId);
        }

        if (stateVal?.salesPerson1Id) {
            setSales1Filter(stateVal?.salesPerson1Id);
        }

        if (stateVal?.salesPerson2Id) {
            setSales2Filter(stateVal?.salesPerson2Id);
        }

        if (stateVal?.financePerson1Id) {
            setFinance1Filter(stateVal?.financePerson1Id);
        }

        if (stateVal?.financePerson2Id) {
            setFinance2Filter(stateVal?.financePerson2Id);
        }

        if (stateVal?.cdrId) {
            setCDRFilter(stateVal?.cdrId);
        }

        if (stateVal?.fromStatusDate) {
            setLogDateFromFilter(stateVal.fromStatusDate);
        }

        if (stateVal?.toStatusDate) {
            setlogDateToFilter(stateVal?.toStatusDate);
        }

        if (stateVal?.statusId) {
            setDealStatus(stateVal?.statusId);
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }

        if (stateVal?.search) {
            setGLFilter(stateVal.search)
        }
        if (stateVal?.deliverycode) {
            setdeliverycode(stateVal.deliverycode)
        }
        setLoadingFlag(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const handleGetWriteUps = (isFilterApplied = false) => {
        setcommonServerError('');
        if (loadingFlag && userProfileLoadingFlag) {
            getWriteUps(isFilterApplied);
        }
    }

    const dateTimeStamp = (timeStamp) => {
        if(timeStamp){
            let date = moment.utc(timeStamp).format('YYYY-MM-DD HH:mm:ss');
            let stillUtc = moment.utc(date).toDate();
            return moment(stillUtc).local().format('MM/DD/YY hh:mm a');
        }
    }

    const getWriteUps = (isFilterApplied) => {
        const formData = {
            page: isFilterApplied ? statePageNumber : pages,
            limit,
            sort: sortOrder === 1 ? 'ASC' : 'DESC',
            column: sortField.replace('_',''),
            locationId,
            salesPerson1Id,
            salesPerson2Id,
            financePerson1Id,
            financePerson2Id,
            deliverycode,
            departmentId,
            cdrId: cdrId === 'Yes' ? 2 : (cdrId === 'No' ? 1 : 0),
            statusId,
            fromStatusDate: fromStatusDate ? moment(fromStatusDate).format('YYYY-MM-DD') : '',
            toStatusDate: toStatusDate ? moment(toStatusDate).format('YYYY-MM-DD') : '',
            search: search
        };
        setFilters(formData);
        if (isFilterApplied && statePageNumber > 1) {
            setStatePageNumber(1);
        }
        dispatch<any>(handleGetWriteUpsList({
            formData,
            callback: (response) => {
                if (response) {
                    setTotalRecords(response.totalItems);
                    //setCurrentPage(response.currentPage)
                    const writesUpsData = handlePopulateTableData(response.results);
                    setTableData(writesUpsData);
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));
    }

    const handlePopulateTableData = (dealsList) => {
        if (dealsList.length) {
            let list = dealsList.filter(x => x.dealDelivery);
            //let hiddenStr;
            for (var i in list) {
                //hiddenStr = '';
                list[i]["financeMgrName"] = getManagerName(list[i].financeManagers);
                list[i]["salesMgrName"] = getManagerName(list[i].salesManagers);
                list[i]["statusDateTime"] = list[i].dealDelivery.statusDateTime? dateTimeStamp(list[i].dealDelivery.statusDateTime):"";
                list[i]["grossProfit"] = list[i].dealDelivery.grossProfit && currencyNegValFractionalFormat(list[i].dealDelivery.grossProfit);
                list[i]["_customer"] = list[i].customer && (list[i].customer.customerType === 1 ? (list[i].customer?.firstName +' '+ list[i].customer?.lastName) :
                    (list[i].customer?.businessName));
                list[i]["salesPerson1Name"] = list[i].salesPerson1 ? list[i].salesPerson1.firstName + " " + list[i].salesPerson1.lastName : '';
                list[i]["salesPerson2Name"] = list[i].salesPerson2 ? list[i].salesPerson2.firstName + " " + list[i].salesPerson2.lastName : '';
                list[i]["sales"] = list[i].salesPerson1Name + ' ' + list[i].salesPerson2Name;
                list[i]["financePerson1Name"] = list[i].financePerson1 ? list[i].financePerson1.firstName + " " + list[i].financePerson1.lastName : '';
                list[i]["financePerson2Name"] = list[i].financePerson2 ? list[i].financePerson2.firstName + " " + list[i].financePerson2.lastName : '';
                list[i]["finance"] = list[i].financePerson1Name + ' ' + list[i].financePerson2Name;
                list[i]["deliveryType"] = getDeliveryTypeName(list[i].dealDelivery.deliveryType);
                list[i]["requestDeliveryDate"] = convertUTCToLocalDateOnlyModified(list[i].dealDelivery.requestDeliveryDate);
                list[i]["cpo"] = status[list[i].dealDelivery.cpo];
                list[i]["sfe"] = status[list[i].dealDelivery.sfe];
                list[i]["cdr"] = status[list[i].dealDelivery.cdr];
                list[i]["fleet"] = status[list[i].dealDelivery.fleet];
                list[i]["dept"] = list[i].dealDelivery.department ? list[i].dealDelivery.department.name : '-';
                list[i]["locationName"] = (list[i].location?.legalName) ? list[i].location.legalName : '-';
                list[i]["stockNumber"] = (list[i]?.stockNumber) ? list[i]?.stockNumber : '-';
                list[i]["orderNumber"] = (list[i]?.orderNumber) ? list[i]?.orderNumber : 0;
                list[i]["status"] = getDeliveryStatus(list[i].dealDelivery.status);
                list[i]["lastDeskingNote"] = list[i].dealDelivery?.lastDeskingNote;
                list[i]["lastFinanceNote"] = list[i].dealDelivery?.lastFinanceNote;
                if (list[i].dealDelivery.cdrDate)
                    list[i]["cdrDate"] = convertUTCToLocalDateOnlyModified(list[i].dealDelivery.cdrDate);

                if (list[i].vehicle?.year)
                    list[i]["year"] = list[i].vehicle?.year;
                if (list[i].vehicle?.model)
                    list[i]["model"] = list[i].vehicle?.model;

                //if (list[i].vehicle?.vin)
                //    hiddenStr = list[i].vehicle?.vin;
                //if (list[i].customer)
                //    hiddenStr = hiddenStr + ' ' + list[i].customer?.firstName + ' ' + list[i].customer?.lastName + ' ' + list[i].customer?.businessName + ' ' + list[i].customer?.customerNumber;
                //hiddenStr = hiddenStr + ' ' + list[i]["stockNumber"] + ' ' + list[i]["orderNumber"];
                //if (list[i].dealDelivery.grossProfit)
                //    hiddenStr = hiddenStr + ' ' + list[i].dealDelivery.grossProfit;
                //list[i]["hiddenCol"] = hiddenStr;
            }
            return list;
        }
        return []
    }

    useEffect(() => {
        if (loadingFlag && userProfileLoadingFlag) {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
            handleGetWriteUps(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, fromStatusDate, toStatusDate, departmentId, cdrId, salesPerson1Id,deliverycode, salesPerson2Id,
            financePerson1Id, financePerson2Id, statusId, search, loadingFlag, userProfileLoadingFlag]);

    useEffect(() => {
        handleGetWriteUps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);


    return (
        user && (
            <>
                <IdleTimerAutoReload onIdle={() => getWriteUps(false)} />
                <Stack styles={container}>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Write-ups</CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    {
                        commonServerError ?
                            <Row><Col>
                                <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                            </Col> </Row> : null
                    }
                    <div className="datatable-filter-demo noMaxWidthTable writeUps-workbook">
                        <div className="card mouse-hover lazy-loading-workbook" >
                            <DataTable
                                value={tableData}
                                className="p-datatable-customers"
                                paginator={false}
                                header={header}
                                footer={footer}
                                //cellSelection
                                lazy
                                emptyMessage="No write-ups found."
                                //rows={25}
                                //rowsPerPageOptions={[25, 50, 100]}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={handleColumnSort}
                                //globalFilter={search}
                                //onValueChange={filteredData => setFilteredData(filteredData)}
                                onRowClick={e => captureCellSelection(e)}>
                                <Column sortable field="dealNumber" header="Deal Number" body={dealBodyTemplate} />
                                <Column sortable field="statusDateTime" header="Status Date" body={logBodyTemplate} />
                                <Column sortable field="status" header="Status" body={statusBodyTemplate} />
                                <Column sortable field="year" header="Year" body={yearBodyTemplate} />
                                <Column sortable field="model" header="Model" body={modelBodyTemplate} />
                                <Column sortable field="stock" header="Stock / Order" body={stockBodyTemplate} />
                                {/*<Column sortable field="closedDate" header="Deal Closed" body={closedDateBodyTemplate} />*/}
                                <Column sortable field="locationName" sortField="locationName" header="Location / Department" body={locBodyTemplate} />
                                <Column sortable field="_customer" header="Customer" body={custBodyTemplate} />
                                <Column sortable field="requestDeliveryDate" header="Request Delivery" body={reqDelBodyTemplate} />
                                <Column sortable field="deliveryType" header="Delivery Type" body={deliveryTypeBodyTemplate} />
                                <Column sortable field="sales" header="Sales" body={salesBodyTemplate} />
                                <Column sortable field="finance" header="Finance" body={financeBodyTemplate} />
                                <Column sortable field="sfe" header="SFE" body={sfeBodyTemplate} />
                                <Column sortable field="cdr" header="CDR" body={cdrBodyTemplate} />
                                <Column sortable field="cdrDate" header="CDR Date" body={cdrDateBodyTemplate} />
                                <Column sortable field="fleet" header="Flt" body={fltBodyTemplate} />
                                <Column sortable field="cpo" header="CPO" body={cpoBodyTemplate} />
                                <Column sortable field="lastDeskingNote" className="notes-tbl-col" header="Desking Note" body={deskingNoteBodyTemplate} />
                                <Column sortable field="lastFinanceNote" className="notes-tbl-col" header="Finance Note" body={financeNoteBodyTemplate} />
                                <Column sortable sortField="dealDelivery.grossProfit" field="grossProfit" header="Gross" body={grossBodyTemplate} />
                                <Column field="Settings" header="Actions" body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                        <div>

                        </div>
                    </div>
                </Stack>
            </>
        )
    );
};

