import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsListStyles,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  DefaultPalette,
  FontIcon,
  IStackStyles,
  IStackTokens,
  /**Image, ImageFit, */ mergeStyles,
  Stack,
} from "@fluentui/react";
import { Pagination } from "components/table/pagination/Pagination";
import { connect } from "react-redux";
import { EditProspectDialog } from "components/dialogs/prospects/EditProspectDialog";
import { ProspectTableDropdown } from "components/table/prospects/dropdown/ProspectTableDropdown";
import { ProspectMoreActionsSubmenu } from "components/table/prospects/dropdown/ProspectMoreActionsSubmenu";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { EditProspectSchedulerDialog } from "components/dialogs/prospects/EditProspectSchedulerDialog";
import { EditProspectDealsDialog } from "components/dialogs/prospects/EditProspectDealsDialog";
import { CreateEmailDialog } from "components/dialogs/createEmailDialog/createEmailDialog";
import {
  HANDLE_DELETE_PROSPECT,
  HANDLE_GET_PROSPECTS,
  HANDLE_REMOVE_TASK,
  HANDLE_UPDATE_PROSPECT,
  HANDLE_UPDATE_TASK,
  SET_ROWS_PER_PAGE_PROSPECTS,
} from "store/prospects/prospects.types";
import { HANDLE_UPDATE_FAVOURITE } from "store/shared/shared.types";
import { IDeal } from "types/dealTypes";
import { colourBlack, colourDefault, colourRed } from "constants/styles";
import { IDates, ITask, ITaskToDelete, ITaskToUpdate } from "types/taskTypes";
import { IProspect } from "types/prospectTypes";
import { groupByDate } from "utils/dateTimeFunctions";
import { SendSMSDialog } from "components/dialogs/prospects/SendSMSDialog";
import {
  FavouriteAction,
  FavouriteEndpoints,
  permissionsModules,
} from "constants/constants";
import moment from "moment";
import {
  getModulePermissions,
  isPermitted,
} from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import {
  badgeCircle,
  badgeBlack,
  badgeBlackEmpty,
  badgeContainer,
  badgeGrey,
  badgeRed,
  badgeStandard,
} from "constants/styles";
import { IPermission } from "types/groupPermissionTypes";
import { IUserProfile } from "types/userProfileTypes";
import { ConfirmDialogWithLink } from "components/dialogs/confirmDialog/ConfirmDialogWithLink";
import { withRouter } from "utils/withRouter";

const cellFontSize: number = 13;
const cellMaxHeight: string = "35px";
// const actionButton: IButtonStyles = {
//   icon: {
//     paddingLeft: 0,
//     marginLeft: 0,
//     maxHeight: cellMaxHeight,
//   },
//   label: {
//     color: DefaultPalette.themePrimary,
//   },
// };

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: "auto",
  },
};

const textStyles = mergeStyles({
  fontSize: 16,
});

export interface IDocument {
  id: number;
  key: string;
  date: string;
  firstName: string;
  lastName: string;
  deals: IDeal[];
  status: number;
  scheduler: any;
  city: string;
  zip: string;
  phone: string;
  email: string;
  isFavourite: boolean;
  tasks: ITask[];
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 20,
    paddingTop: 20,
  },
};

const height = 35;
const cellStyles: IStackStyles = {
  root: {
    //height: height,
    lineHeight: height,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

const iconStyle = {
  color: DefaultPalette.themePrimary,
  marginLeft: 7,
  fontSize: 16,
  height: "16px",
};

const cellStyle = {
  fontSize: cellFontSize,
  display: "flex",
  // justifyContent: 'center',
  // alignItems:'center'
};

interface IProps {
  items?: IProspect[];
  totalItems?: number;
  currentPage?: number;
  rowsPerPage?: number;
  sortColumn?: string;
  sort?: string;
  permissions?: IPermission[];
  user?: IUserProfile;
  handleGetProspects?: Function;
  setRowsPerPage?: Function;
  handleDeleteProspect?: Function;
  handleUpdateTask?: Function;
  handleRemoveTasks?: Function;
  handleUpdateProspect?: Function;
  handleUpdateFavourites?: Function;
  navigate: any;
  smtpSettings?: any;
  loading?: boolean;
}
interface IState {
  items: IProspect[];
  columns: IColumn[];
  selectionDetails: any;
  hovered: number;
  iconHovered: any;
  selectedInHeaderDropDown: any;
  rowsPerPage: number;
  currentPage: number;
  isEditDialogOpen: boolean;
  isViewDialogOpen: boolean;
  selectedProspect: IProspect;
  activeId: number;
  loading: boolean;
  isConfirmBoxOpen: boolean;
  amountOfDaysToMoveTask: string | number;
  isConfirmBoxForDeleteTasksOpen: boolean;
  isSchedulerOpen: boolean;
  schedulerData: IProspect;
  selectedDeals: IDeal[];
  isDealsDialogOpen: boolean;
  isEmailOpen: boolean;
  selectedEmails: string[];
  inventoryId: number;
  prospect: IProspect;
  isSendSMSModalOpen: boolean;
  addressees: string[] | null;
  showSMTPInfoDialog: boolean;
}

class ProspectsTable extends React.Component<IProps, IState> {
  private readonly _items: any;
  private readonly _selection: Selection;
  //private _contextMenuRef: any;

  constructor(props: IProps) {
    super(props);

    this._items = [];

    const columns: any[] = [
      {
        key: "firstName",
        name: "First name",
        fieldName: "firstName",
        minWidth: 100,
        maxWidth: 180,
        isResizable: true,
        isRowHeader: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: false,
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.firstName}</span>;
        },
      },
      {
        key: "lastName",
        name: "Last name",
        fieldName: "lastName",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        data: "string",
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.lastName}</span>;
        },
        isPadded: false,
      },
      {
        key: "deals",
        name: "Deals",
        fieldName: "deals",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "string",
        onRender: (item: IDocument) => {
          const deals = this._calculateDealStatus(item.deals);
          return (
            <div
              className={badgeContainer}
              onClick={(e) => this._openDealsDialog(e, item.id)}
            >
              {deals.active ? (
                <div className={badgeCircle} style={badgeStandard}>
                  {deals.active > 9 ? "9+" : deals.active}
                </div>
              ) : null}
              {deals.other ? (
                <div className={badgeCircle} style={badgeGrey}>
                  {deals.other > 9 ? "9+" : deals.other}
                </div>
              ) : null}
            </div>
          );
        },
        isPadded: false,
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          // const status = prospectStatuses.find((i) => {
          //   return i.key === item.status;
          // });
          const status = item.status;
          return (
            <span
              className={textStyles}
              style={{
                ...(item.status === 0 ? colourBlack : colourRed),
                ...cellStyle,
              }}
            >
              {status}
            </span>
          );
        },
        isPadded: false,
      },

      {
        key: "scheduler",
        name: "Scheduler",
        fieldName: "tasks",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        onRender: (item: IDocument) => {
          const recent: IDates = groupByDate(item.tasks)[0];
          /** Badge colors and styles depends on date type*/
          return recent ? (
            <div
              className={badgeContainer}
              style={{ justifyContent: "start" }}
              onClick={(e) =>
                this._openSchedulerModal(
                  e,
                  this.props.items.find((it: IProspect) => it.id === item.id)
                )
              }
            >
              {this._getBadge(recent)}
            </div>
          ) : null;
        },

        isPadded: false,
      },
      {
        key: "place",
        name: "City, zip code ",
        fieldName: "city",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.city}, {item.zip}
              <FontIcon style={iconStyle} iconName="MapDirections" />
            </span>
          );
        },
        // onColumnClick: _onColumnClick,
        isPadded: false,
      },
      {
        key: "phone",
        name: "Mobile Phone",
        fieldName: "phone",
        minWidth: 120,
        maxWidth: 170,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.phone}
              <FontIcon style={iconStyle} iconName="Phone" />
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.email}
              <span onClick={() => this._handleSendEmail([item.email])}>
                <FontIcon style={iconStyle} iconName="Send" />
              </span>
            </span>
          );
        },
      },
      {
        key: "favorites",
        name: "Favorites",
        fieldName: "isFavourite",
        minWidth: 60,
        maxWidth: 110,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          let iconName;
          let iconHoveredName;

          if (item.isFavourite) {
            iconName = "FavoriteStarFill";
            iconHoveredName = "Unfavorite";
          } else {
            iconName = "AddFavorite";
            iconHoveredName = "FavoriteStarFill";
          }

          return (
            <FontIcon
              onClick={() =>
                isPermitted(
                  this.props.permissions,
                  eTypes.WRITE,
                  permissionsModules.Prospects,
                  this.props.user.roles
                )
                  ? this._handleUpdateIsFavourite(item)
                  : {}
              }
              onMouseEnter={() => {
                this.setState({
                  iconHovered: item.id,
                  columns: [...this.state.columns],
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  iconHovered: null,
                  columns: [...this.state.columns],
                });
              }}
              style={{
                fontSize: 22,
                color: DefaultPalette.themePrimary,
                display:
                  this.state.hovered === item.id || item.isFavourite
                    ? "flex"
                    : "none",
              }}
              iconName={
                this.state.iconHovered === item.id ? iconHoveredName : iconName
              }
            />
          );

          //return <span className={textStyles}>ICON</span>;
        },
        isPadded: false,
      },

      {
        key: "action",
        name: "Actions",
        fieldName: "action",
        minWidth: 100,
        maxWidth: 150,

        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: any) => {
          return (
            <>
              {/*<div id={`item_${item.id}`} />*/}
              <Stack
                horizontal
                horizontalAlign="start"
                style={{
                  width: "100%",
                  display: this.state.hovered === item.id ? "flex" : "none",
                  maxHeight: cellMaxHeight,
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  //tokens={{ childrenGap: 10 }}
                >


                  <ProspectMoreActionsSubmenu
                    onDelete={() => this.setState({ isConfirmBoxOpen: true })}
                    onDeleteTasks={() =>
                      this.setState({
                        isConfirmBoxForDeleteTasksOpen: true,
                      })
                    }
                    amountOfDaysToMoveTask={(days) =>
                      this.setState({ amountOfDaysToMoveTask: days })
                    }
                    onFavouriteClick={() => this._handleUpdateFavourites(item)}
                    modulePermissions={this.props.permissions}
                    onEdit={() =>
                      this.setState({
                        selectedProspect: this.props.items.find(
                          (item: IProspect) => item.id === this.state.hovered
                        ),
                        isViewDialogOpen: true,
                      })
                    }
                    onStartNewDeal={() =>
                      this._handleCreateNewDealFromProspect(item.id)
                    }
                    onSetStatus={(status: string) =>
                      this._handleSetStatus(status)
                    }
                    onSendSMS={() =>
                      this.handleSendSMSClick(this._getSelectedSMS())
                    }
                    onSendEmail={() => this._handleSendEmail([item.email])}
                  />
                </Stack>
              </Stack>
            </>
          );
        },
        isPadded: false,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._items,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      iconHovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      currentPage: 1,
      isEditDialogOpen: false,
      isViewDialogOpen: false,
      selectedProspect: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      amountOfDaysToMoveTask: null,
      isConfirmBoxForDeleteTasksOpen: false,
      isSchedulerOpen: false,
      schedulerData: null,
      selectedDeals: null,
      isDealsDialogOpen: false,
      isEmailOpen: false,
      selectedEmails: null,
      inventoryId: null,
      prospect: null,
      isSendSMSModalOpen: false,
      addressees: [],
      showSMTPInfoDialog: false,
    };

    //console.log('PROSPECTS', this.props.items)
  }

  public componentDidMount() {
    this.props.handleGetProspects({
      pagination: {
        page: 1,
        limit: this.props.rowsPerPage,
      },
      type: 1,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }
  }

  private _handleUpdateIsFavourite(item) {
    this.props.handleUpdateFavourites(
      [{ endpoint: FavouriteEndpoints.prospect, id: item.id }],
      () => this._selection.setAllSelected(false),
      item.isFavourite ? FavouriteAction.remove : FavouriteAction.add
    );
  }

  private _handleUpdateIsFavouriteArray(action: FavouriteAction) {
    const selectedArray = this._selection.getSelection();

    this.props.handleUpdateFavourites(
      selectedArray.map((item: any) => {
        return { endpoint: FavouriteEndpoints.prospect, id: item.id };
      }),
      () => this._selection.setAllSelected(false),
      action
    );
  }

  private _handleUpdateFavourites(item) {
    const newItem = { ...item, isFavourite: !item.isFavourite };
    this.props.handleUpdateProspect([newItem]);
  }

  private _openSchedulerModal(e: any, scheduler: IProspect) {
    this.setState({
      isSchedulerOpen: true,
      schedulerData: scheduler,
      prospect: scheduler,
    });
  }

  private _closeEditDealsDialog() {
    this.setState({
      selectedDeals: null,
      isDealsDialogOpen: false,
    });
  }

  private _openDealsDialog(e: any, prospectId: number) {
    this.setState({
      isDealsDialogOpen: true,
      selectedProspect: this.props.items.find(
        (prospect: IProspect) => prospect.id === prospectId
      ),
    });
  }

  private _calculateDealStatus(deals: any) {
    let active = 0;
    let other = 0;

    deals.forEach((deal: any) => {
      deal.status === 0 ? active++ : other++;
    });
    return { active, other };
  }

  private _closeEditProspectDialog() {
    this.setState({
      isEditDialogOpen: false,
      selectedProspect: null,
    });
    // this.props.handleGetProspects({
    //   page: this.props.currentPage,
    //   limit: this.props.rowsPerPage,
    // });
  }

  private _closeSchedulerDialog() {
    this.setState({
      isSchedulerOpen: false,
    });
    this.props.handleGetProspects({pagination: {
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
    }});
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _handleCreateNewDealFromProspect(prospectId: number) {
    const prospect = this.props.items.find(
      (item: IProspect) => item.id === prospectId
    );

    this.props.navigate("/deals", { action: "add", prospect });
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {

    if (column.key === "action") {
      return;
    }

    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    // this.props.handleGetProspects({
    //   page: this.props.currentPage,
    //   limit: this.props.rowsPerPage,
    //   sort: currColumn.isSortedDescending ? "DESC" : "ASC",
    //   column: currColumn.fieldName,
    // });

    this.props.handleGetProspects({pagination: {
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      column: currColumn.fieldName === "deals" || currColumn.fieldName === "tasks"  ? `${currColumn.fieldName}.id`  : `prospect.${currColumn.fieldName}`,
    }});

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState({
      columns: newColumns,
    });
  }

  //                     =============ACTIONS!! ========================================

  private _handleView(id: number) {
    this.setState({
      isViewDialogOpen: true,
      inventoryId: id,
    });
    // this._closeEditProspectDialog();
  }

  private _handleSetStatus(status: string) {
    const item = this._selection.getSelection().map((item: any) => {
      return { ...item, status: status };
    });

    this.props.handleUpdateProspect({
      prospects: item,
      callback: () => this._selection.setAllSelected(false),
    });
    this._closeEditProspectDialog();
  }

  public handleDeleteCallback() {
    //TODO: errors need to be managed
    this._selection.setAllSelected(false);
    this.setState({ isConfirmBoxOpen: false });
    this._closeEditProspectDialog();
  }

  private _handleDelete() {
    const ids = [];
    this._selection
      .getSelection()
      .forEach((element: any) => ids.push(element.id));

    this.props.handleDeleteProspect(ids, this.handleDeleteCallback.bind(this));
  }

  private _handleDeleteTasks() {
    const tasksToDelete: ITaskToDelete[] = [];

    for (const prospect of this._selection.getSelection() as IProspect[]) {
      const { tasks } = prospect;

      if (tasks.length !== 0) {
        tasks.map((task) =>
          tasksToDelete.push({ prospectId: prospect.id, taskId: task.id })
        );
      }
    }

    if (tasksToDelete.length > 0) {
      this.props.handleRemoveTasks(tasksToDelete, () =>
        this.setState({ isConfirmBoxForDeleteTasksOpen: false })
      );
    } else {
      this.setState({ isConfirmBoxForDeleteTasksOpen: false });
    }
  }

  //                     =============ACTIONS END!! ========================================
  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    console.log("Rows on page", rowsOnPage);
    
    this.props.setRowsPerPage(rowsOnPage);

    // pagination: {
    //   page: 1,
    //       limit: this.props.rowsPerPage,
    // },
    // type: 1,
    this.props.handleGetProspects({
      pagination: {
        limit: rowsOnPage,
      },
    });
  }

  private _handlePageChange(page: number) {
    this.props.handleGetProspects({
      pagination: {
        page: page,
        limit: this.props.rowsPerPage,
      },
    });
  }

  private _handleCloseEmail() {
    this.setState({
      isEmailOpen: false,
      selectedEmails: null,
    });
  }

  private _handleSendEmail(emails: string[]) {
    console.log(this.props.smtpSettings);
    

    if (this.props.smtpSettings) {
      this.setState({
        isEmailOpen: true,
        selectedEmails: [...emails],
      });
    } else {
      this.setState({
        showSMTPInfoDialog: true,
      });
    }
  }

  private _getBadge(scheduler: IDates) {
    let badge = {};
    let color = {};
    let text = "";
    switch (scheduler.date) {
      case "Overdue":
        badge = badgeRed;
        color = colourRed;
        text = scheduler.date;
        break;
      case "Today":
        badge = badgeStandard;
        color = colourDefault;
        text = scheduler.date;
        break;
      case "Tomorrow":
        badge = badgeBlack;
        color = colourBlack;
        text = scheduler.date;
        break;
      default:
        badge = badgeBlackEmpty;
        color = colourBlack;
        text = "Incoming";
    }

    return (
      <>
        <div className={badgeCircle} style={badge}>
          {scheduler.tasks.length <= 9 ? scheduler.tasks.length : "9+"}
        </div>
        <div style={{ lineHeight: "25px", fontSize: cellFontSize, ...color }}>
          {text}
        </div>
      </>
    );
  }

  private _moveToOrBy = () => {
    const days = this.state.amountOfDaysToMoveTask;

    const tasksToUpdate: ITaskToUpdate[] = [];

    for (const prospect of this._selection.getSelection() as IProspect[]) {
      const { tasks } = prospect;
      const recent: IDates = groupByDate(tasks)[0];
      if (!recent) continue;

      // eslint-disable-next-line array-callback-return
      recent?.tasks?.map((t) => {
        const date =
          days === "tomorrow"
            ? moment(new Date()).add(1, "days").format("YYYY-MM-DD")
            : moment(t.date).add(days, "days").format("YYYY-MM-DD");

        const task = { ...t, date: date };
        tasksToUpdate.push({
          prospectId: prospect.id,
          task: task,
        });
      });
    }

    this.props.handleUpdateTask(tasksToUpdate, () =>
      this.setState({ amountOfDaysToMoveTask: null })
    );
  };

  private _getSelectedEmails() {
    const emails = this._selection
      .getSelection()
      .map((item: any) => item.email);
    return emails;
  }

  private _getSelectedSMS() {
    return this._selection.getSelection().map((item: any) => item.phone);
  }

  private handleSendSMSClick = (addressees) => {
    if(this.props.user.roles.includes('ROLE_SMS_TEXT')){
      this.setState({
        isSendSMSModalOpen: true,
        addressees: addressees,
      });
    }else{
      this.props.navigate('/subscriptions');
      return
    }
  };

  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <>
        <Fabric>
          <div
            style={{
              paddingLeft: 25,
              paddingRight: 25,
              backgroundColor: "white",
            }}
          >
            {this.state.isSendSMSModalOpen && (
              <SendSMSDialog
                addressees={[...this.state.addressees]}
                onDismiss={() => this.setState({ isSendSMSModalOpen: false })}
              />
            )}

            {this.state.isViewDialogOpen && this.state.selectedProspect && (
              <EditProspectDialog
                prospect={this.state.selectedProspect}
                onDismiss={this._closeEditProspectDialog.bind(this)}
              />
            )}
            {this.state.isEmailOpen && (
              <CreateEmailDialog
                onDismiss={this._handleCloseEmail.bind(this)}
                receiversEmails={[...this.state.selectedEmails]}
              />
            )}

            {this.state.isSchedulerOpen && this.state.schedulerData ? (
              <EditProspectSchedulerDialog
                onDismiss={this._closeSchedulerDialog.bind(this)}
                prospectId={this.state.schedulerData.id}
                view
              />
            ) : null}
            {this.state.isDealsDialogOpen && this.state.selectedProspect ? (
              <EditProspectDealsDialog
                onDismiss={this._closeEditDealsDialog.bind(this)}
                // dealsData={this.state.selectedDeals}
                view
                prospectId={this.state.selectedProspect.id}
              />
            ) : null}

            <DetailsList
              compact={true}
              styles={tableStyles}
              items={this.props.items}
              columns={columns}
              onRenderDetailsFooter={() => (
                <Pagination
                  rowsPerPage={this.props.rowsPerPage}
                  currentPage={
                    this.props.currentPage && this.props.currentPage.toString()
                  }
                  totalItems={this.props.totalItems}
                  onRowsNumberChange={this._handleRowsNumberChange.bind(this)}
                  onPageChange={this._handlePageChange.bind(this)}
                />
              )}
              onRenderDetailsHeader={(props, defaultRender) => (
                <>
                  <Stack
                    tokens={stackTokens}
                    styles={stackStyles}
                    horizontal
                    verticalAlign="center"
                  >
                    <p
                      style={{ color: DefaultPalette.themePrimary, width: 100 }}
                    >
                      {selectionDetails.length === 0
                        ? "No items selected"
                        : selectionDetails.length + " items selected"}
                    </p>
                    {selectionDetails.length > 0 && (
                      <ProspectTableDropdown
                        handleUpdateIsFavourite={(action) =>
                          this._handleUpdateIsFavouriteArray(action)
                        }
                        onDelete={() =>
                          this.setState({ isConfirmBoxOpen: true })
                        }
                        onDeleteTasks={() =>
                          this.setState({
                            isConfirmBoxForDeleteTasksOpen: true,
                          })
                        }
                        amountOfDaysToMoveTask={(days) =>
                          this.setState({ amountOfDaysToMoveTask: days })
                        }
                        modulePermissions={this.props.permissions}
                        onSendEmail={() =>
                          this._handleSendEmail(this._getSelectedEmails())
                        }
                        onSetStatus={(status: string) =>
                          this._handleSetStatus(status)
                        }
                        onSendSMS={() =>
                          this.handleSendSMSClick(this._getSelectedSMS())
                        }
                      />
                    )}
                  </Stack>
                  <span>{defaultRender(props)}</span>
                </>
              )}
              onRenderRow={(props, defaultRender) => {
                return (
                  <Stack
                    styles={cellStyles}
                    className="centered"
                    verticalAlign="center"
                  >
                    <div
                      onMouseEnter={() => {
                        this.setState({ hovered: props.item.id });
                      }}
                      onMouseLeave={() => this.setState({ hovered: null })}
                    >
                      {defaultRender(props)}
                    </div>
                  </Stack>
                );
              }}
              // onRenderCheckbox={(props, defaultRender) => {
              //     return <span>{defaultRender(props)}</span>;
              // }}
              selection={this._selection}
              selectionMode={SelectionMode.multiple}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onColumnHeaderClick={(ev, column) =>
                this._onColumnClick(ev, column)
              }
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={() =>
                this.setState({
                  selectedProspect: this.props.items.find(
                    (item: IProspect) => item.id === this.state.hovered
                  ),
                  isViewDialogOpen: true,
                })
              }
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
            />
          </div>
        </Fabric>
        <ConfirmDialogWithLink
          title="You haven't configured SMTP settings yet."
          isOpen={this.state.showSMTPInfoDialog}
          onDismiss={() => this.setState({ showSMTPInfoDialog: false })}
          onAction={() =>
            this.props.navigate("/account-settings", "goToSMTPSettings")
          }
          description="Please go to account settings to configure your SMTP settings"
          loading={false}
        />
        <ConfirmDialog
           loading={this.props.loading}
          description="Please confirm deletion of prospect"
          title="Confirm delete prospect"
          isOpen={this.state.isConfirmBoxOpen}
          onAction={() => this._handleDelete()}
          onDismiss={() => this.setState({ isConfirmBoxOpen: false })}
        />
        <ConfirmDialog
          loading={this.props.loading}
          description="Please confirm deletion of tasks connected with this prospect"
          title="Confirm delete tasks for the prospect"
          isOpen={this.state.isConfirmBoxForDeleteTasksOpen}
          onAction={() => this._handleDeleteTasks()}
          onDismiss={() =>
            this.setState({ isConfirmBoxForDeleteTasksOpen: false })
          }
        />

        <ConfirmDialog
          loading={this.props.loading}
          description="Please confirm moving task date"
          title="Confirm moving task date"
          isOpen={this.state.amountOfDaysToMoveTask !== null}
          onAction={this._moveToOrBy.bind(this)}
          onDismiss={() => this.setState({ amountOfDaysToMoveTask: null })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.prospects.items?.results ? state.prospects.items?.results : [],
    totalItems: state.prospects.items?.totalItems,
    currentPage: state.prospects.items?.currentPage,
    rowsPerPage: state.prospects?.rowsPerPageProspects,
    sortColumn: state.prospects?.column,
    sort: state.prospects?.sort,
    permissions: getModulePermissions(
      state.userProfile?.userProfile.auths
        ? state.userProfile?.userProfile.auths
        : [],
      permissionsModules.Prospects
    ),
    loading: state.prospects.loading,
    user: state.userProfile?.userProfile,
    smtpSettings: state.shared.SMTPSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetProspects: (payload) =>
      dispatch({ type: HANDLE_GET_PROSPECTS, payload }),
    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_PROSPECTS, payload }),

    handleDeleteProspect: (ids, callback?) =>
      dispatch({ type: HANDLE_DELETE_PROSPECT, payload: { ids, callback } }),
    handleUpdateTask: (tasks, callback) =>
      dispatch({ type: HANDLE_UPDATE_TASK, payload: { tasks, callback } }),
    handleRemoveTasks: (tasksToRemove, callback) =>
      dispatch({
        type: HANDLE_REMOVE_TASK,
        payload: { tasksToRemove, callback },
      }),
    handleUpdateProspect: (payload) =>
      dispatch({
        type: HANDLE_UPDATE_PROSPECT,
        payload,
      }),

    handleUpdateFavourites: (
      handleData: any[],
      callback: () => void,
      action: FavouriteAction
    ) =>
      dispatch({
        type: HANDLE_UPDATE_FAVOURITE,
        payload: { handleData, callback, action },
      }),
  };
};

export default connect<{}, {}, IProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProspectsTable));
