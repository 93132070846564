import React, { useEffect, useState } from "react";
import {
  Panel,
  PanelType,
  IPanelStyles,
} from "@fluentui/react/lib/Panel";
import {
  DefaultButton,
  getTheme,
  IconButton,
  PrimaryButton,
  Stack,
  IStackStyles,
  IButtonStyles,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { filterPanelStyles as content } from "constants/styles";
import { useSelector, useDispatch } from "react-redux";
import { setInventoryFilters } from "store/inventory/inventory.action";
import { AppState } from "store";
import ListItem from "components/ListItem/ListItem";
import Options from "components/dialogs/addInventoryDialog/secondaryDialogs/options/Options";
import  inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const contentStyles = content();
const theme = getTheme();
const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
};

const closeBtnStyles: IButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens = { childrenGap: 10 };

const stackStyles: IStackStyles = {
  root: {
    marginTop: 20,
  },
};

enum OptionSteps {
  Options = "Options",
  Audio = "Audio/Video",
  PowerEquipment = "Power Equipment",
  Interior = "Interior",
  Exterior = "Exterior",
  Safety = "Safety Features",
  Luxury = "Luxury",
  Other = "Other",
}

interface IProps {
  isOpen: boolean;
  label: string;
  onClose: () => void;
  cleanFilters: () => void;
  handleFilter: () => void;
}

const OptionsPanel: React.FC<IProps> = ({
  isOpen,
  label,
  onClose,
  cleanFilters,
  handleFilter,
}) => {
  const filters = useSelector((state: AppState) => state.inventory.filters);
  const dispatch = useDispatch();

  const [optionsStep, setOptionsStep] = useState(OptionSteps.Options);
  const [optionsTitle, setOptionsTitle] = useState("");
  const [optionsContent, setOptionsContent] = useState(null);

  const handleOptionChange = (item, option) => {

    console.log('ite',item)
    console.log('eoption',option)

    dispatch<any>(setInventoryFilters({
      ...filters, [option]: [...filters[option], item]
    }))
  }

  useEffect(() => {
    switch (optionsStep) {
      case OptionSteps.Options:
        // setSecondaryOptionsDialogTitle("Options");
        // setSecondaryOptionsDialogContent(null);
        // setBack("main");
        break;

      case OptionSteps.Audio:
        setOptionsTitle(OptionSteps.Audio);
        setOptionsContent(
          <Options
            category="optionsAudio"
            values={inventoryDropdownsValues.audioOptions}
            onOptionChange={(e) => handleOptionChange(e, 'audioOptions')}
            selectedOptions={filters.audioOptions}
            // onOptionChange={onOptionChange}
            // selectedOptions={selectedOptions.optionsAudio}
          />
        );
        break;
      case OptionSteps.PowerEquipment:
        setOptionsTitle(OptionSteps.PowerEquipment);
        setOptionsContent(
          <Options
            category="optionsPower"
            values={inventoryDropdownsValues.powerEquipmentOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;
      case OptionSteps.Interior:
        setOptionsTitle(OptionSteps.Interior);
        setOptionsContent(
          <Options
            category="optionsInterior"
            values={inventoryDropdownsValues.interiorOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;
      case OptionSteps.Exterior:
        setOptionsTitle(OptionSteps.Exterior);
        setOptionsContent(
          <Options
            category="optionsExterior"
            values={inventoryDropdownsValues.exteriorOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;

      case OptionSteps.Safety:
        setOptionsTitle(OptionSteps.Safety);
        setOptionsContent(
          <Options
            category="optionsSafteyFeatures"
            values={inventoryDropdownsValues.safetyOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;
      case OptionSteps.Luxury:
        setOptionsTitle(OptionSteps.Luxury);
        setOptionsContent(
          <Options
            category="optionsLuxury"
            values={inventoryDropdownsValues.luxuryOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;
      case OptionSteps.Other:
        setOptionsTitle(OptionSteps.Other);
        setOptionsContent(
          <Options
            category="optionsOther"
            values={inventoryDropdownsValues.otherOptions}
            onOptionChange={() => {}}
            selectedOptions={["C.B", "DVD Player"]}
          />
        );
        break;
      default:
        console.log(`default switch`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsStep, filters]);

  const handleClose = () => {
    OptionSteps.Options === optionsStep ? onClose() : _goBackToOptions();
  };

  const _goBackToOptions = () => {
    setOptionsStep(OptionSteps.Options);
  };

  const handleItemClick = (e, item) => {
    setOptionsStep(item);
  };
  return (
    <Panel
      styles={panelStyles}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onClose}
      hasCloseButton={false}
      onRenderHeader={() => (
        <div className={contentStyles.bottomShadow}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ width: "100%" }}
          >
            <GoBackToMainDialogBtn
              label={OptionSteps.Options === optionsStep ? label : optionsTitle}
              onClick={handleClose}
            />

            <div className={contentStyles.marginRight}>
              <IconButton
                styles={closeBtnStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={handleClose}
              />
            </div>
          </Stack>
        </div>
      )}
      onRenderFooter={() => (
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">
            <DefaultButton text="Clear" onClick={cleanFilters} />
            <PrimaryButton text="View results" onClick={handleFilter} />
          </Stack>
        </div>
      )}
    >
      <Stack tokens={stackTokens} styles={stackStyles}>
        {OptionSteps.Options === optionsStep ? (
          <>
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Audio)}
              title={OptionSteps.Audio}
              subTitles={[
                "AM/FM",
                "Anti-Theft",
                "Bluetooth",
                "C.B",
                "CD Changer",
                "CD Player",
                "Cassette",
              ]}
              // count={
              //   selectedOptions.optionsAudio.length > 0 &&
              //   selectedOptions.optionsAudio.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.PowerEquipment)}
              title={OptionSteps.PowerEquipment}
              subTitles={["Power Steering", "Power Antenna", "Power Mirrors"]}
              // count={
              //   selectedOptions.optionsPower.length > 0 &&
              //   selectedOptions.optionsPower.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Interior)}
              title={OptionSteps.Interior}
              subTitles={[
                "2 Hi-Back Bucket Seats",
                "3rd Row Seating",
                "All Weather Floor Mats",
              ]}
              // count={
              //   selectedOptions.optionsInterior.length > 0 &&
              //   selectedOptions.optionsInterior.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Exterior)}
              title={OptionSteps.Exterior}
              subTitles={[
                "Accent Stripes",
                "Aluminium Box",
                "Bedliner",
                "Body Side Molding",
              ]}
              // count={
              //   selectedOptions.optionsExterior.length > 0 &&
              //   selectedOptions.optionsExterior.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Safety)}
              title={OptionSteps.Safety}
              subTitles={[
                "ABS Brakes",
                "Adaptive headlights",
                "Alarm System",
                "Child Proof Door Locks",
              ]}
              // count={
              //   selectedOptions.optionsSafteyFeatures.length > 0 &&
              //   selectedOptions.optionsSafteyFeatures.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Luxury)}
              title={OptionSteps.Luxury}
              subTitles={[
                "Air Conditioned Seats",
                "Air Conditioning",
                "Auto Climate Control",
              ]}
              // count={
              //   selectedOptions.optionsLuxury.length > 0 &&
              //   selectedOptions.optionsLuxury.length.toString()
              // }
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Other)}
              title={OptionSteps.Other}
              subTitles={[
                "Air Suspension",
                "Auto Leveling",
                "Conventional Spare",
                "Differential Locks",
              ]}
              // count={
              //   selectedOptions.optionsOther.length > 0 &&
              //   selectedOptions.optionsOther.length.toString()
              // }
            />
          </>
        ) : (
          optionsContent
        )}
      </Stack>
    </Panel>
  );
};

export default OptionsPanel;
