import * as actions from "store/shared/shared.types";
import {ISMTPSettings, IConnection, IPickerItem, ISMSSettings} from "types/sharedTypes";

interface IState {
  loading: boolean;
  loadingConnection: boolean;
  loadingModels: boolean;
  loadingMakes: boolean;
  makes: IPickerItem[] | [];
  dataOneMakes: any[],
  dataOneModels: any[],
  models: IPickerItem[] | [];
  connection: IConnection;
  error: null | object;
  SMTPSettings: null | ISMTPSettings,
  loadingSMSSettings: boolean,
  SMSSettings: null | ISMSSettings,
  SMSSettingsError: any,
  getSMTPSettingsError: any,
  updateSMTPSettingsError: any
}

const initialState: IState = {
  loading: false,
  loadingConnection: false,
  loadingModels: false,
  loadingMakes: false,
  makes: [],
  dataOneMakes: [],
  dataOneModels: [],
  models: [],
  connection: {
    connection: null
  },
  error: null,
  SMTPSettings: null,
  loadingSMSSettings: false,
  SMSSettings: null,
  SMSSettingsError: null,
  getSMTPSettingsError: null,
  updateSMTPSettingsError: null
};

export const SharedReducer = (
  state: IState = initialState,
  action: actions.SharedActions
) => {
  switch (action.type) {
    case actions.ADD_FAVOURITE_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.ADD_FAVOURITE_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ADD_FAVOURITE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.REMOVE_FAVOURITE_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.REMOVE_FAVOURITE_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.REMOVE_FAVOURITE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.SEND_EMAIL_PENDING:
    case actions.SEND_SMS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.SEND_EMAIL_RESOLVED:
    case actions.SEND_SMS_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.SEND_EMAIL_REJECTED:
    case actions.SEND_SMS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }


    case actions.GET_SMTP_SETTINGS_PENDING: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_SMTP_SETTINGS_RESOLVED: {
      return {
        ...state,
        loading: false,
        SMTPSettings: action.payload
      }
    }
    case actions.GET_SMTP_SETTINGS_REJECTED: {
      return {
        ...state,
        loading: false,
        getSMTPSettingsError: action.payload
      }
    }

    case actions.GET_SMS_SETTINGS_PENDING: {
      return {
        ...state,
        loadingSMSSettings: true
      }
    }
    case actions.GET_SMS_SETTINGS_RESOLVED: {
      return {
        ...state,
        loadingSMSSettings: false,
        SMSSettings: action.payload
      }
    }
    case actions.GET_SMS_SETTINGS_REJECTED: {
      return {
        ...state,
        loadingSMSSettings: false,
        SMSSettingsError: action.payload
      }
    }
    case actions.UPDATE_SMS_SETTINGS_PENDING: {
      return {
        ...state,
        loadingSMSSettings: true
      }
    }
    case actions.UPDATE_SMS_SETTINGS_RESOLVED: {
      return {
        ...state,
        loadingSMSSettings: false,
        SMSSettings: action.payload
      }
    }
    case actions.UPDATE_SMS_SETTINGS_REJECTED: {
      return {
        ...state,
        loadingSMSSettings: false,
        SMSSettingsError: action.payload
      }
    }



    case actions.UPDATE_SMTP_SETTINGS_PENDING: {
      return {
        ...state,
        loading: true
      }
    }
    case actions.UPDATE_SMTP_SETTINGS_RESOLVED: {
      return {
        ...state,
        loading: false,
        SMTPSettings: action.payload

      }
    }
    case actions.UPDATE_SMTP_SETTINGS_REJECTED: {
      return {
        ...state,
        loading: false,
        SMTPSettings: null,
        updateSMTPSettingsError: action.payload
      }
    }


    case actions.TEST_CONNECTION_PENDING: {
      return{
        ...state,
        loadingConnection: true
      }
    }
    case actions.TEST_CONNECTION_RESOLVED: {
      return{
        ...state,
        loadingConnection: false,
        connection: action.payload
      }
    }
    case actions.TEST_CONNECTION_REJECTED: {
      return{
        ...state,
        loadingConnection: false
      }
    }

    case actions.GET_MAKES_PENDING: {
      return {
        ...state,
        loadingMakes: true
      }
    }
    case actions.GET_MAKES_RESOLVED: {
      return {
        ...state,
        loadingMakes: false,
        makes: action.payload
      }
    }
    case actions.HANDLE_GET_DATAONE_MAKES_RESOLVED: {
      return {
        ...state,
        loadingMakes: false,
        dataOneMakes: action.payload
      }
    }
    case actions.GET_MAKES_REJECTED: {
      return {
        ...state,
        loadingMakes: false
      }
    }

    case actions.GET_MODELS_PENDING: {
      return {
        ...state,
        loadingModels: true
      }
    }
    case actions.GET_MODELS_RESOLVED: {
      return {
        ...state,
        loadingModels: false,
        models: action.payload
      }
    }
    case actions.HANDLE_GET_DATAONE_MODELS_RESOLVED: {
      return {
        ...state,
        loadingModels: false,
        dataOneModels: action.payload
      }
    }
    case actions.GET_MODELS_REJECTED: {
      return {
        ...state,
        loadingModels: false
      }
    }

    case actions.CLEAR_SMTP_SETTINGS: {
      
      
      console.log("clear smtp settings redux")

      return {
        ...state,
        SMTPSettings: null,
        SMSSettings: null
      }
    }

    default:
      return state;
  }
};
