import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
    IContextualMenuProps,
    IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, emplyeePermissionsAction } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";
// import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { isDefaultProfile } from "utils/permissions/permissionsHelpers";
import { DefaultButton } from "@fluentui/react";

const iconMenuStyles: IIconStyles = {
    root: {
        color: colors.lighterBlack,
    },
};

interface IProps {
    modulePermissions: IPermission[];
    onEdit: (vehicleID: any) => void;
 
    itemId: any;
}

export const EditMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    onEdit,
  
    itemId,
}) => {
    const [menuProps, setMenuProps] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);

    // const dispatch = useDispatch();

    useEffect(() => {
        setMenuProps(null);
        const menuItems: IContextualMenuItem[] = [
            {
                key: "edit", //with submenu
                name: "Edit",
                iconProps: { iconName: "Edit", styles: iconMenuStyles },
                onClick: () => onEdit(itemId),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_EDIT,
                    user.roles
                ) && isDefaultProfile(user.profileType)
                    ? hiddenStyle
                    : null,
            }
        ];

        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: menuItems,
        };

        setMenuProps(menuProps);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId]);


 

    return (
        <>
            
                  <IconButton
                   className="iconButton icon-ellipse-pad icon-ellipse-h deskDisplay"
                   styles={buttonStyles}
                   text="Group Action"
                   menuProps={menuProps}
                  />
                  <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" menuProps={menuProps} />
             
            
            
        </>
    );
};

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,     
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};
