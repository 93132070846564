import React from "react";
import { useEffect, useState } from "react";
import GoBack from "components/buttons/goBack/GoBack";
import { formatPathname } from "utils/helperFunctions";
import {
  Stack,
  IStackStyles,
  PrimaryButton,
  SearchBox,
  mergeStyles,
} from "@fluentui/react";
import { useLocation,useNavigate  } from "react-router-dom";
 import ProspectsTable from "components/table/prospects/ProspectsTable";
import { AddProspectDialog } from "components/dialogs/prospects/AddProspectDialog";
import { useSelector } from "react-redux";
import { AppState } from "store";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { eTypes, WithCredentials } from "hoc/withCredentials";
import { permissionsModules } from "constants/constants";
import { handleGetProspects } from "store/prospects/prospects.action";
import { useDispatch } from "react-redux";
import ProspectsFilterPanel from "components/panels/ProspectsFilterPanel";


const container: IStackStyles = {
  root: {
    marginTop: 10,
  },
};

const searchStyle = mergeStyles({
  marginLeft: 30,
});
const credentialModuleName = "Prospects";
export const ProspectsPage = () => {
  const [pathname, setPathname] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const location = useLocation();
  const [totalItems, setTotalItems] = useState(0);
  const items = useSelector((state: AppState) => state.prospects.items);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const [credentials, setCredentials] = useState([]);

  const dispatch = useDispatch();
  // const loading = useSelector((state: AppState) => state.prospects.loading);
  const navigate = useNavigate ();


  useEffect(() => {
    setPathname(location.pathname);
    if (location.state && location.state === "add") {
      setIsOpen(true);
    }
  }, [location]);

  useEffect(() => {
    items && setTotalItems(items.totalItems);
  }, [items]);

  useEffect(() => {
    if (user && user.auths && !isUserMaster(user.roles)) {
      // console.log(getModulePermissions(user.auths, credentialModuleName));
      setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleOnDismiss = () => {
  //   setIsOpen(false);
  //   dispatch<any>(
  //     handleGetLocations({
  //       page: 1,
  //       limit: rowsPerPage,
  //     })
  //   );
  // };
  const onDismiss = () => {
    setIsOpen(false);
  };

  // const handleViewDismiss = () => {
  //   setIsViewOpen(false)
  // }

  const handleSearch = (phrase: string) => {
    dispatch<any>(
      handleGetProspects({
        page: 1,
        phrase,
      })
    );
  };

  const handleShowFilterPanel = () => {
    setIsPanelOpen(true);
  };

  return (
    <>
      <AddProspectDialog isOpen={isOpen} onDismiss={onDismiss} />
      {/*<Loader show={loading} />*/}
      <Stack styles={container} tokens={{ childrenGap: 10 }}>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
            <GoBack label={formatPathname(pathname)} count={totalItems} />
            {credentials && user.roles && (
              <WithCredentials
                groupAuths={credentials}
                alias={permissionsModules.Prospects}
                type={eTypes.WRITE}
                roles={user.roles}
              >
                <PrimaryButton
                  onClick={() => setIsOpen(true)}
                  text="Add new prospect"
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                />
              </WithCredentials>
            )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              onClick={handleShowFilterPanel}
              text="Show Filters"
              iconProps={{ iconName: "FilterSolid" }}
            />
            {/*<FiltersContextMenu />*/}

            <div>
              <SearchBox
                className={searchStyle}
                placeholder="Search"
                onSearch={handleSearch}
              />
            </div>
          </Stack>
        </Stack>
        <ProspectsFilterPanel
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
        />
         <ProspectsTable navigate={navigate}/>
      </Stack>
    </>
  );
};
