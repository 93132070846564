import * as actions from "store/notifications/notifications.types";

interface IState {
  loading: boolean;
  error: null | object;
  list: any[];
  types: any[];
}

const initialState: IState = {
  loading: false,
  error: null,
  list: [],
  types: [],
};

export const NotificationsReducer = (
  state: IState = initialState,
  action: actions.NotificationsActions
) => {
  switch (action.type) {
    case actions.GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_NOTIFICATIONS_RESOLVED:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case actions.GET_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.GET_NOTIFICATIONS_TYPES_PENDING:
      return {
        ...state,
        loading: true,
      };

    case actions.GET_NOTIFICATIONS_TYPES_RESOLVED:
      return {
        ...state,
        loading: false,
        types: action.payload,
      };
    case actions.GET_NOTIFICATIONS_TYPES_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.UPDATE_NOTIFICATION_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_NOTIFICATION_RESOLVED:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_NOTIFICATION_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
