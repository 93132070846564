import { IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import {
  DefaultPalette,
  FontWeights,
  getTheme,
  IDatePickerStyles,
  IIconProps,
  IMessageBarStyles,
  mergeStyles,
  mergeStyleSets,
} from "@fluentui/react";
import { IToggleStyles } from "@fluentui/react/lib/Toggle";
import { Depths } from "@uifabric/fluent-theme";

const theme = getTheme();

export const fullLengthDropdownStyles: Partial<IDropdownStyles> = {
  root: { width: "100%" },
  dropdown: { width: "100%" },
};

export const fullLengthDatePicker: Partial<IDatePickerStyles> = {
  root: { width: "100%" },
  textField: { width: "100%" },
  callout: { width: "100%" },
};

export const colourDefault = {
  color: DefaultPalette.themePrimary,
};

export const colourGrey = {
  color: DefaultPalette.blackTranslucent40,
};
export const colourBlack = {
  color: DefaultPalette.black,
};
export const colourRed = {
  color: DefaultPalette.redDark,
};

export const hiddenStyle = {
  display: "none",
};

export const messageBarStyles: IMessageBarStyles = {
  iconContainer: {
    display: "none",
  },
  root: {
    margin: "5px auto",
  },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    maxHeight: 300,
  },
  dropdownItemsWrapper: { maxHeight: 200 },
};

export const standDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    display: "inline-block"
  },
  dropdown: {
    maxHeight: 300,
  },
  dropdownItemsWrapper: { maxHeight: 200 },
};

export const standToggleStyles: Partial<IToggleStyles> = {
  root: {
    display: "inline-block",
  },
};

export const colrDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    margin: "6px 0"
  },
  dropdown: {
    maxHeight: 300,
  },
  dropdownItemsWrapper: { maxHeight: 200 },
};

export const contentStyles = (
  dialogWidth: number = 700,
  height?: number | string,
  fixedFooter?: boolean
) => {
  return mergeStyleSets({
    container: {
      // width: '300px',
      height: height ? height : "100%",
      maxHeight: height ? height : "100%",
    },
    brandContainer: {
      // width: '300px',
     height: height ? height : "90%",
      maxHeight: height ? height : "100%",
    },
    dealerWebsiteContainer: {
      // width: '300px',
     //height: height ? height : "90%",
      maxHeight: height ? height : "100%",
    },
    contenerTest: {
      height: '100vh'
    },

    addVehicleToDealDialogContainer:{
      height: "70%"
    },
    footerForDialogContainer: {
      bottom: 0,
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: "relative",
      backgroundColor: "white",
      zIndex: 10,
      boxShadow: Depths.depth8,
      width: dialogWidth - 60,
    },

    header: {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "10px 30px 5px",
      position: "fixed",
      backgroundColor: "white",
      zIndex: 10,
      boxShadow: Depths.depth8,
      width: dialogWidth - 60,
    },
    headerNoShadow: {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: "fixed",
      backgroundColor: "white",
      zIndex: 10,
      // boxShadow: Depths.depth8,
      width: dialogWidth - 60,
    },
    padding: {
      padding: 10,
    },

    body: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      height: height ? `calc(${height} - 200px)` : "calc(100vh - 200px)",
      width: dialogWidth - 60,
      overflow: "auto",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
    brandBody: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 100,
      height: "calc(125vh - 450px)",
      width: dialogWidth - 60,
      overflow: "auto",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
    launchBody: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 100,
      minHeight: "685px",
      width: dialogWidth - 60,
      overflow: "auto",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
      paddingBottom: 18
    },
    standardBody: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 100,
      height: "calc(100vh - 263px)",
      width: dialogWidth - 60,
      overflow: "auto",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
    footer: {
      bottom: 0,
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: fixedFooter ? "fixed" : "relative",
      backgroundColor: "white",
      zIndex: 10,
      boxShadow: Depths.depth8,
      width: dialogWidth - 60,
    },
    footerNoShadow: {
      bottom: 0,
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: fixedFooter ? "fixed" : "relative",
      backgroundColor: "white",
      zIndex: 10,
      // boxShadow: Depths.depth8,
      width: dialogWidth - 60,
      marginTop: 40,
    },
    footerSubscription: {
      // bottom: 0,
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "10px 30px",
      // position: fixedFooter ? "fixed" : "relative",
      backgroundColor: "white",
      zIndex: 10,
      // boxShadow: Depths.depth8,
      // width: dialogWidth - 60,
    },

    label: {
      fontWeight: 600,
      paddingTop: 15,
      paddingBottom: 5,
    },
  });
};

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  }
};

export const cancelIcon: IIconProps = { iconName: "Cancel" };

export const searchStyle = (marginTop?: number | string) => {
  return mergeStyles({
    marginLeft: 30,
    marginTop: marginTop ? marginTop : 30,
    width: 200,
    color: DefaultPalette.black,
  });
};

export const cellLineHeight = 35;

export const windowStyle = {
  paddingLeft: 25,
  paddingRight: 25,
  backgroundColor: "white",
  boxShadow: Depths.depth8,
};

export const notificationColors = {
  backgroundColor: "#deecf9",
  color: DefaultPalette.themePrimary,
};

export const badgeContainer = mergeStyles({
  display: "flex",
  flexDirection: "row",
  fontSize: 13,
  width: "100%",
  cursor: "pointer",
});
export const badgeCircle = mergeStyles({
  width: 20,
  minWidth: 20,
  height: 20,
  borderRadius: "50%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: DefaultPalette.white,
  padding: 1,
  marginRight: 5,
});

export const badgeStandard = {
  backgroundColor: DefaultPalette.themePrimary,
};

export const badgeGrey = {
  backgroundColor: DefaultPalette.blackTranslucent40,
};
export const badgeBlack = {
  backgroundColor: DefaultPalette.black,
};
export const badgeRed = {
  backgroundColor: DefaultPalette.redDark,
};

export const badgeBlackEmpty = {
  color: DefaultPalette.black,
  borderColor: DefaultPalette.black,
  borderWidth: 1,
  borderStyle: "solid",
};

// FILTER PANEL

export const filterPanelStyles = (
  dialogWidth: number = 600,
  height?: number | string,
  fixedFooter?: boolean
) => {
  return mergeStyleSets({
    // container: {
    //   width: dialogWidth,
    //   height: height ? height : "100%",
    //   maxHeight: height ? height : "100%",
    // },
    bottomShadow: {
      boxShadow: Depths.depth8,
      width: "100%",
      paddingLeft: 20,
      paddingBottom: 20,
      paddingTop: 20,
      // paddingBottom: 20,
      //      marginTop: 20,
      // marginRight: 20
      // paddingRight: 50
      // paddingTop: 20,
      // paddingBottom: 20
    },
    header: {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: "fixed",
      backgroundColor: "white",
      zIndex: 10,
      boxShadow: Depths.depth8,
      width: 584,
      // width: "100%",
      // width: dialogWidth - 60,
    },
    content: {
      paddingTop: 100,
      paddingLeft: 20,
      paddingRight: 20,
    },
    marginRight: {
      marginRight: 30,
    },
    footer: {
      bottom: 0,
      right: 0,
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "20px 30px",
      position: "fixed",
      backgroundColor: "white",
      zIndex: 10,
      boxShadow: Depths.depth8,
      width: 584,
      // width: dialogWidth - 60,
    },
  });
};
