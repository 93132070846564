import React, { FunctionComponent,useEffect} from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    DefaultButton,
    IconButton,
    TextField,
    Stack,
    //Dropdown,
} from "@fluentui/react";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
//import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
import CurrencyInput from 'react-currency-input-field'
import { ConfirmDeleteDialog} from "components/dialogs/confirmDeleteDialog/ConfirmDeleteDialog";
import { useSelector } from "react-redux";
import { PRICE_PREFIX } from "constants/constants";
import { useFormik } from "formik";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";
import { handleGetSelectList} from "store/selectList/selectList.action";
import { useDispatch } from "react-redux";
import { handleCreateFinanceProductItem, handleUpdateFinanceProductItem , handleDeleteFinanceProductItem} from "store/dealTracker/dealTracker.action";

const contentStyles = content(600, "auto");

interface IProps {
    
    onDismiss: () => void;
    formData?:any
    id:any
    onDelete: any
    allowDeactivate: boolean
    readOnly: boolean

}

const emptyForm = {
    id:'',
	amount: '',
    financeProductId:  '',
};

export const AddUpdateProductDialog: FunctionComponent<IProps> = ({
    onDismiss,
    formData,
    id,
    onDelete,
    allowDeactivate,
    readOnly
}) => {

    const financeProducts = useSelector((state: AppState) => state.selectList.financeProducts) as any;
    const [financeProductList, setFinanceProductList] = React.useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

   const formik = useFormik({
       initialValues: {
			...emptyForm,
		},
       validationSchema: Yup.object({
           id:Yup.string(),
           amount: Yup.string().required("Required"),
           financeProductId: Yup.string().required("Required")
       }),
       onSubmit: (values) => {
               values.amount = values.amount.replace('$', '').replace(',', '')

              if(formData && formData.id){

               dispatch<any>(
                     handleUpdateFinanceProductItem(
                         {
                         id,
                         formData:values,
                         callback: (res) => {
                            if(res){
                               onDismiss();
                            }
                         }})
                 )

              }else{

              dispatch<any>(
                     handleCreateFinanceProductItem(
                         {
                         id,
                         formData:values,
                         callback: (res) => {
                            if(res){
                               onDismiss();
                            }
                         }})
                 )

              }
                 }
              });

   const handleformikDDChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
            ...formik.values,
            [e.target.id]: e.value,
            }
        );
   }
    const handleDelete = () => {
        //setConfirmContent(deleteContent);
        setIsConfirmBoxOpen(true);
    };

    const renderSpinner = () => {
         return loading ? (
           <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
         ) : null;
    };

//    function dynamicSort(property) {
//    var sortOrder = 1;

//    if(property[0] === "-") {
//        sortOrder = -1;
//        property = property.substr(1);
//    }

//    return function (a,b) {
//        if(sortOrder === -1){
//            return b[property].localeCompare(a[property]);
//        }else{
//            return a[property].localeCompare(b[property]);
//        }        
//    }
//}
    
    //
    useEffect(() => {
    
       if(formData && formData.financeProduct){
             formik.setValues(
              {
                ...formik.values,
                'amount': formData.amount,
                'financeProductId': formData.financeProduct.id,
                'id':formData.id,
              });
        }
        dispatch<any>(handleGetSelectList("financeProducts",1));
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
    
        var list = [...financeProducts];
        //list.reverse();
        list.sort(function (x, y) { return x.name.toLowerCase() === 'reserve' ? -1 : y.name === 'reserve' ? 1 : 0; });
        setFinanceProductList(list)
    }, [financeProducts]);

    const handleAddFinanceProduct = () => {
        formik.handleSubmit();
    };
    return (
        
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container }
            >
            <ConfirmDeleteDialog
                loading={loading}
                description="Confirm Delete?"
                isOpen={isConfirmBoxOpen}                           
                onAction={(values) =>dispatch<any>( handleDeleteFinanceProductItem(
                    {id,
                        productFinanceListId:formData.id,
                        callback: (res) => {
                        setIsConfirmBoxOpen(false);
                            onDismiss();
                        onDelete(res);
                    }
                })
                )}
                onDismiss={() => setIsConfirmBoxOpen(false)}
            />
            <div className={contentStyles.header + " inventory-popup modal-header"} style={{width:"auto"}}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle" style={{width:"320px"}}>Finance Product</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " expenseDialogPopup inv-srch-bdy body-auto-hight"}  style={{width:"auto",overflow:"hidden"}} >
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={8} md={8} xs={8}>
                            
                            <label className="lbl-text required-Label">Product</label>
                            <PRDropdown
                                id="financeProductId"
                                placeholder=""
                                value={formik.values.financeProductId}
                                options={financeProductList}
                                onChange={handleformikDDChange}
                                optionLabel="name"
                                optionValue="id"
                                appendTo="self"
                                className="custom-p-dropdown"
                                filter={false}
                                resetFilterOnHide={true}
                                scrollHeight="150px"
                                disabled={readOnly}
                            />
                        </Col>
                    </Row>
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={8} md={8} xs={8}>
                           <label className="lbl-text required-Label">Amount</label>
                            <CurrencyInput
                                customInput={TextField}
                               // thousandSeparator={true}
                                prefix={PRICE_PREFIX}
                                id="amount"
                                name="amount"
                                //label="Amount"
                                decimalScale={2}
                                value={formik.values.amount?formik.values.amount.replace(/,/g, ''):""}
                                onValueChange ={(e) => {
                                    formik.setValues(
                                        {
                                            ...formik.values,
                                            "amount": e
                                        }
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                //required={true}
                                disabled={readOnly}
                                //errorMessage={
                                //    formik.touched.amount &&
                                //    formik.errors.amount &&
                                //    formik.errors.amount.toString()
                                //}
                            />
                            <span className="error-message">
                                        {
                                            formik.touched && formik.touched.amount &&
                                            formik.errors && formik.errors.amount &&
                                            formik.errors.amount.toString()
                                        }
                                    </span>
                        </Col>
                    </Row>
                    <Row className="inventoryRowSection footerBtn"  style={{marginTop: "30px",paddingBottom: "30px"  }}>
                        {formData && formData.id ?
                            <>
                                <Col>
                                    <DefaultButton
                                        className="expenseDelDialog"
                                        style={{ float: "left", marginLeft: 60 }}
                                        onClick={handleDelete}
                                        disabled={!allowDeactivate}
                                        //iconProps={{ iconName: "Delete" }}
                                        text="Delete"
                                    />
                                </Col>
                                <Col>
                                    <PrimaryButton
                                        text="Save"
                                        style={{ float: "right", marginRight: 60 }}
                                        onClick={handleAddFinanceProduct}
                                        onRenderIcon={renderSpinner}
                                        disabled={!formik.dirty || !formik.isValid || readOnly}
                                    />

                                </Col>
                            </> :
                            <Col style={{ textAlign:"center"}}>
                                <PrimaryButton
                                    text="Save"
                                    onClick={handleAddFinanceProduct}
                                    onRenderIcon={renderSpinner}
                                    disabled={!formik.dirty || !formik.isValid || readOnly}
                                />

                            </Col>  
                        }
                    </Row>

                </Stack>

            </div>

        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
