import {ISelectList} from "types/selectListTypes";

/** GET_SELECTED_LIST*/
export const HANDLE_GET_SELECT_LIST = "HANDLE_GET_SELECT_LIST";

export interface HandleGetSelectList {
  type: typeof HANDLE_GET_SELECT_LIST;
  payload: any
}

export const GET_SELECT_LIST_PENDING = "GET_SELECT_LIST_PENDING";

export interface GetSelectListPending {
  type: typeof GET_SELECT_LIST_PENDING;
}

export const GET_SELECT_LIST_RESOLVED = "GET_SELECT_LIST_RESOLVED";

export interface GetSelectListResolved {
  type: typeof GET_SELECT_LIST_RESOLVED;
  payload: {category:string,results:ISelectList[]};
}

export const GET_SELECT_LIST_REJECTED = "GET_SELECT_LIST_REJECTED";

export interface GetSelectListRejected {
  type: typeof GET_SELECT_LIST_REJECTED;
  payload: object[];
}

export const HANDLE_GET_FINANCE_PRODUCT = "HANDLE_GET_FINANCE_PRODUCT";

export interface HandleGetFinanceProduct {
  type: typeof HANDLE_GET_FINANCE_PRODUCT;
  payload: any
}

/** VIEW_SELECTLIST */
export const HANDLE_VIEW_SELECT_LIST_ITEM = "HANDLE_VIEW_SELECT_LIST_ITEM";

export interface HandleViewSelectListItem {
  type: typeof HANDLE_VIEW_SELECT_LIST_ITEM;
  payload:{ id:number; category: string;callback?: (res) => void };
}



/** UPDATE_SELECTLIST */
export const HANDLE_UPDATE_SELECT_LIST_ITEM = "HANDLE_UPDATE_SELECT_LIST_ITEM";

export interface HandleUpdateSelectListItem {
  type: typeof HANDLE_UPDATE_SELECT_LIST_ITEM;
  payload: { id:number; formData: ISelectList; category: string;callback?: (res) => void };
}



/** CREATE_SELECTLIST */
export const HANDLE_CREATE_SELECT_LIST_ITEM = "HANDLE_CREATE_SELECT_LIST_ITEM";

export interface HandleCreateSelectListItem {
  type: typeof HANDLE_CREATE_SELECT_LIST_ITEM;
  payload:any;
}



/** SELECTLIST ACTIVATE **/
export const HANDLE_ACTIVATE_SELECT_LIST_ITEM = "HANDLE_ACTIVATE_SELECT_LIST_ITEM";

export interface HandleActivateSelectListItem {
    type: typeof HANDLE_ACTIVATE_SELECT_LIST_ITEM;
    payload: { id: number; category: string;callback?: (res) => void };
}



/** DEACTIVATE_SELECTLIST */
export const HANDLE_DEACTIVATE_SELECT_LIST_ITEM = "HANDLE_DEACTIVATE_SELECT_LIST_ITEM";

export interface HandleDeactivateSelectListItem {
  type: typeof HANDLE_DEACTIVATE_SELECT_LIST_ITEM;
  payload: { id: number; category: string;callback?: (res) => void };
}




export type SelectListActions =
    |HandleGetSelectList
    |GetSelectListPending
    |GetSelectListResolved
    |GetSelectListRejected
    |HandleViewSelectListItem
    |HandleUpdateSelectListItem
    |HandleCreateSelectListItem
    |HandleActivateSelectListItem
    |HandleDeactivateSelectListItem
    |HandleGetFinanceProduct;
    