/** HANDLE_GET_NOTIFICATIONS */
export const HANDLE_GET_NOTIFICATIONS = "HANDLE_GET_NOTIFICATIONS";
export interface HandleGetNotifications {
  type: typeof HANDLE_GET_NOTIFICATIONS;
}

export const GET_NOTIFICATIONS_PENDING = "GET_NOTIFICATIONS_PENDING";
export interface GetNotificationsPending {
  type: typeof GET_NOTIFICATIONS_PENDING;
}

export const GET_NOTIFICATIONS_RESOLVED = "GET_NOTIFICATIONS_RESOLVED";
export interface GetNotificationsResolved {
  type: typeof GET_NOTIFICATIONS_RESOLVED;
  payload: any[];
}

export const GET_NOTIFICATIONS_REJECTED = "GET_NOTIFICATIONS_REJECTED";
export interface GetNotificationsRejected {
  type: typeof GET_NOTIFICATIONS_REJECTED;
  payload: object[];
}

/** HANDLE_GET_NOTIFICATIONS_TYPES */
export const HANDLE_GET_NOTIFICATIONS_TYPES = "HANDLE_GET_NOTIFICATIONS_TYPES";
export interface HandleGetNotificationsTypes {
  type: typeof HANDLE_GET_NOTIFICATIONS_TYPES;
}

export const GET_NOTIFICATIONS_TYPES_PENDING =
  "GET_NOTIFICATIONS_TYPES_PENDING";
export interface GetNotificationsTypesPending {
  type: typeof GET_NOTIFICATIONS_TYPES_PENDING;
}

export const GET_NOTIFICATIONS_TYPES_RESOLVED =
  "GET_NOTIFICATIONS_TYPES_RESOLVED";
export interface GetNotificationsTypesResolved {
  type: typeof GET_NOTIFICATIONS_TYPES_RESOLVED;
  payload: any[];
}

export const GET_NOTIFICATIONS_TYPES_REJECTED =
  "GET_NOTIFICATIONS_TYPES_REJECTED";
export interface GetNotificationsTypesRejected {
  type: typeof GET_NOTIFICATIONS_TYPES_REJECTED;
  payload: object[];
}

/** HANDLE_UPDATE_NOTIFICATION */
export const HANDLE_UPDATE_NOTIFICATION = "HANDLE_UPDATE_NOTIFICATION";

export interface HandleUpdateNotification {
  type: typeof HANDLE_UPDATE_NOTIFICATION;
  payload: { id: number | string; status: any };
}

export const UPDATE_NOTIFICATION_PENDING = "UPDATE_NOTIFICATION_PENDING";
export interface UpdateNotificationPending {
  type: typeof UPDATE_NOTIFICATION_PENDING;
}

export const UPDATE_NOTIFICATION_RESOLVED = "UPDATE_NOTIFICATION_RESOLVED";
export interface UpdateNotificationResolved {
  type: typeof UPDATE_NOTIFICATION_RESOLVED;
}

export const UPDATE_NOTIFICATION_REJECTED = "UPDATE_NOTIFICATION_REJECTED";
export interface UpdateNotificationRejected {
  type: typeof UPDATE_NOTIFICATION_REJECTED;
  payload: object;
}

export type NotificationsActions =
  | HandleGetNotifications
  | HandleGetNotificationsTypes
  | HandleUpdateNotification
  | GetNotificationsPending
  | GetNotificationsRejected
  | GetNotificationsResolved
  | GetNotificationsTypesResolved
  | GetNotificationsTypesRejected
  | GetNotificationsTypesPending
  | UpdateNotificationPending
  | UpdateNotificationResolved
  | UpdateNotificationRejected;
