import client from "services/api/client";


export const getCustomers = ({
    locationId, customerNumber
}: {
     locationId?: number;
     customerNumber?: string;
}) => {
       let url = `user/master/customers?page=1&limit=1000`;
       if (locationId) {
            url = url + '&locationId=' + locationId;
       }
        if (customerNumber) {
            url = url + '&customerNumber=' + customerNumber;
        }
       return client(
           url,
           { body: null },false
       ).then(
       (data) => {
           return { data, error: null };
       },
       (error) => {

           return { data: null, error };
       }
    );
};