import React, { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
    IStackStyles,
    Link,
    //Dropdown,
    PrimaryButton,
    Stack
} from "@fluentui/react";
//import { useNavigate  } from "react-router-dom";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
import { AppState } from "store/index";
import { handleGetLocations } from "store/locations/locations.action";
import {
    //getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
//import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
//import { Dropdown } from "@fluentui/react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import {
    HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { handleGetFinancePersonStackRankReports } from "store/dealTracker/dealTracker.action";
//import  XLSX  from "xlsx";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { currencyNegValFractionalFormat } from "utils/helperFunctions";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
//const credentialModuleName = "Location";
export const FinancePersonStackRankReports = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate ();

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    //const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [financePersonList, setFinancePersonList] = React.useState([]);
    const [isPrimaryLocation, setIsPrimaryLocation] = React.useState(null);
    const [locationFilter, setLocationFilter] = React.useState(isPrimaryLocation);
    const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
    const [endMonthFilter, setEndMonthFilter] = React.useState(null);
    const [yearFilter, setYearFilter] = React.useState(currentYear);
    const [endMonthOption, setEndMonthOption] = useState([]);
    const [perCarData, setPerCarData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productRes, setProductRes] = useState(null);
    const [totalRes, setTotalRes] = useState(null);
    const [tab, setTab] = useState([]);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('perCar');
    const ExcelJS = require('exceljs');
    const { saveAs } = require('file-saver');

    const monthsOptions = masterDropdownValues.getMonth;

    // const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
    //   return { key: item, text: item };
    // });

    const handleStartMonthDropdownChange = (e) => {

        handleEndMonthOptions(e.value);
    };

    const handleEndMonthDropdownChange = (e) => {

        setEndMonthFilter(e.value)
        //handleFilters(startMonthFilter,e.value ,yearFilter);
    };

    const handleYearDropdownChange = (e) => {

        setYearFilter(e.value)
        //handleFilters(startMonthFilter,endMonthFilter ,e.value);
    };



    const handleEndMonthOptions = (startMonthFilter) => {
        if (startMonthFilter > 0) {
            if (monthsOptions) {
                const results = monthsOptions.filter(x => x.key > startMonthFilter);
                setEndMonthOption(results);
            }
            setStartMonthFilter(startMonthFilter)
            if (endMonthFilter && startMonthFilter >= endMonthFilter) {
                setEndMonthFilter(null);
            }
        }
    }

    const handleLocationDropdownChange = (e) => {

        setLocationFilter(e.value)
        //setLocationVal()
    };
    const handleFilters = () => {
        if (startMonthFilter || endMonthFilter || yearFilter) {

            const formData = {
                startMonth: startMonthFilter,
                endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
                year: yearFilter,
                // primaryLocationId:locationFilter > 0 ?locationFilter:""
            };
            dispatch<any>(handleGetFinancePersonStackRankReports({
                formData,
                callback: (response) => {
                    if (response) {
                        //handlePopulatePerCarReports(response.perCarFinancePersons)
                        //handlePopulateFinanceProductsReports(response.financeProducts)
                        setTotalRes(response.perCarFinancePersons)
                        setProductRes(response.financeProducts);
                    } else {
                        setPerCarData(null)
                        setProductList(null)
                        setProductRes(null)
                        setTotalRes(null)
                    }

                }
            }));
        }
    }

    const handlePopulatePerCarReports = (response) => {
        if (response && response.length) {

            let tableData = []
            let result = [...response]

            if (locationFilter > 0) {
                let arr = cityDealerList?.filter(x => {
                    return x.key === locationFilter
                })

                result = result.filter(x => x.financePersonPrimaryLocation === arr[0].text);
            }


            result.forEach(function (response, index) {
                let Obj = {
                    financePerson: response.financePersonFirstName + ' ' + response.financePersonLastName,
                    primaryLocation: response.financePersonPrimaryLocation,
                    amount: (response.perCar),
                    perCar: Number(response.perCar)
                }
                tableData.push(Obj);
            });
            setPerCarData(tableData);
        } else {
            setPerCarData([])
        }
    };

    const handlePopulateFinanceProductsReports = (response) => {
        if (response && response.length) {
            let tbls = [];
            let name = [];
            response.forEach(function (response, index) {
                name.push(response.name)
                let productData = []
                let result = [...financePersonList]
                if (locationFilter > 0 && result) {
                    result = result.filter(x => x.primaryLocId === locationFilter);
                }
                result.forEach(function (item, index) {


                    let Obj = {
                        financePerson: item.text,
                        primaryLocation: item.primaryLoc,
                    }

                    response.financePersons.forEach(function (val, index) {

                        const data = response.financePersons.find(x => { return x.financePersonId === item.key });

                        Obj["penetrationPer"] = (data ? Number(data.penetration.toFixed(1)) : 0.0);
                        Obj["penetration"] = data ? data.penetration : 0;
                        Obj["productName"] = data ? data.financeProductName : " ";

                    });


                    productData.push(Obj);
                });

                tbls.push(productData);

            });
            setProductList(tbls);
            setTab(name)
        }
        else {
            setProductList([])
            setTab([])
        }

    };

    useEffect(() => {

        handlePopulateFinanceProductsReports(productRes);
        handlePopulatePerCarReports(totalRes)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financePersonList, productRes, totalRes, locationFilter]);

    useEffect(() => {

        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "financePerson" } })
        setSortOrder(-1);
        dispatch<any>(handleGetLocations());
        handleEndMonthOptions(startMonthFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startMonthFilter, endMonthFilter, yearFilter, locationFilter]);

    useEffect(() => {

        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setIsPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let person = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName), primaryLoc: (item?.primaryLocation?.legalName), primaryLocId: (item?.primaryLocation?.id) })
            });
            setFinancePersonList(person);
        }
    }, [employees])


    //    const exportExcel = () => {
    //        if(perCarData){
    //            let flatData = productList.reduce((acc, curVal) => { 
    //               return acc.concat(curVal)
    //            }, []);

    //            var wb = XLSX.utils.book_new(),

    //            ws=XLSX.utils.sheet_add_json(ws, perCarData, {
    //              origin: 2
    //            });
    //            XLSX.utils.sheet_add_json(ws, [{}], {
    //              header: ["finance product"],
    //              origin: 'E1'
    //            });
    //            XLSX.utils.sheet_add_json(ws, flatData, { origin: 'E3'});
    //            XLSX.utils.book_append_sheet(wb,ws,"Mysheet1");
    //            XLSX.writeFile(wb,"MyExcel.xlsx");
    //        }
    //}
    
    // function getSheetData(data, header) {

    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName] === "" ? "" : row[fieldName]

    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    const exportExcel = () => {
        if (perCarData && perCarData.length) {
            let carData = [...perCarData];
            let header = ["Finance Person", "Primary Location", "Per Car"];
            let arr = []

            const add = (val) => {

                let data = arr.slice(-1)

                let value = Number(data) + val

                return parseInt(value)
            }
            if (carData) {
                carData.sort(descOrdeByCar);
                const exportData = carData.map((item) =>
                ({
                    "financePerson": item.financePerson,
                    "primaryLocation": item.primaryLocation,
                    "perCar": item.amount
                }));

                arr.push(exportData.length + 3)
                arr.push(exportData.length + 4)

                if (productList && productList.length) {

                    productList.forEach(function (item, index) {

                        let d = add(item.length);
                        arr.push(d + 2)
                        arr.push(d + 3)

                        exportData.push({ "financePerson": '', "primaryLocation": '', "perCar": '' });
                        exportData.push({ "financePerson": tab[index], "primaryLocation": '', "perCar": '' });
                        exportData.push({ "financePerson": 'Finance Person', "primaryLocation": 'Primary Location', "perCar": 'Penetration %' });
                        item.sort(descOrdeByPenetration);
                        const dynamicData = item.map((detail) =>
                        ({
                            "financePerson": detail.financePerson,
                            "primaryLocation": detail.primaryLocation,
                            "perCar": detail.penetrationPer
                        }));
                        exportData.push(...dynamicData);
                    });
                }


                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Finance Person Stack Rank Reports');

                // Add header row
                worksheet.addRow(header);

                // Add data rows
                exportData.forEach((row) => {
                    worksheet.addRow([row.financePerson, row.primaryLocation, row.perCar]);
                });

                // Generate buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'Finance Person Stack Rank Reports.xlsx');
                });
            }
        }
    }

    // const saveAsExcelFile = (buffer, fileName) => {
    //     import('file-saver').then(module => {
    //         if (module && module.default) {
    //             let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //             let EXCEL_EXTENSION = '.xlsx';
    //             const data = new Blob([buffer], {
    //                 type: EXCEL_TYPE
    //             });
    //             module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //         }
    //     });
    // }

    function descOrdeByPenetration(a, b) {
        if (a.penetration > b.penetration) {
            return -1;
        }
        if (a.penetration < b.penetration) {
            return 1;
        }
        return 0;
    }

    function descOrdeByCar(a, b) {
        if (a.perCar > b.perCar) {
            return -1;
        }
        if (a.perCar < b.perCar) {
            return 1;
        }
        return 0;
    }

    const handleResetWorkbook = () => {
        setStartMonthFilter(currentMonth);
        setEndMonthFilter(null);
        setYearFilter(currentYear);
        setLocationFilter(isPrimaryLocation)
        //handleFilters(currentMonth,null ,currentYear)
    };

    useEffect(() => {

        let list = [];
        if (locations.results.length > 0) {
            locations.results.map((item: any) => {
                return list.push({ key: item.id, text: (item.legalName) })
            });
            list.unshift({ key: 0, text: 'All' });
            setCityDealerList(list);
        } else {
            setCityDealerList(null);
        }
    }, [locations])

    const header = (
        <div >
            <div className=" table-header " style={{ marginBottom: "10px", display: "block" }} >
                <Row  >
                    <Col md={12}  >
                        <Row  >
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="location"
                                         label="Location"
                                         selectedKey={locationFilter}
                                         options={cityDealerList}
                                         onChange={handleLocationDropdownChange}
                                         placeholder=""
           
                                     />*/}
                                <label className="lbl-text">Location</label>
                                <PRDropdown
                                    id="location"
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace"  >
                                {/*<Dropdown
                                         id="startMonth"
                                         label="Start Month"
                                         selectedKey={startMonthFilter}
                                         options={masterDropdownValues.getMonth}
                                         onChange={handleStartMonthDropdownChange}
                                         placeholder=""
                                         className=" dropDownBorderColor" 
                                     />*/}
                                <label className="lbl-text">Start Month</label>
                                <PRDropdown
                                    id="startMonth"
                                    value={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleStartMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="endMonth"
                                         label="End Month"
                                         selectedKey={endMonthFilter }
                                         options={endMonthOption}
                                         onChange={handleEndMonthDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">End Month</label>
                                <PRDropdown
                                    id="endMonth"
                                    value={endMonthFilter}
                                    options={endMonthOption}
                                    onChange={handleEndMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="year"
                                         label="Year"
                                         //required={true}
                                         selectedKey={yearFilter}
                                         //value={yearFilter}
                                         options={yearValues}
                                         onChange={handleYearDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">Year</label>
                                <PRDropdown
                                    id="year"
                                    value={yearFilter}
                                    options={masterDropdownValues.from2022years}
                                    onChange={handleYearDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>

                            <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                <PrimaryButton onClick={handleResetWorkbook} >
                                    Reset
                                </PrimaryButton>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </div>
            <div style={{ marginLeft: "-8px", marginTop: "-20px" }}>
                <strong className="blueShadedText readField"> For single month, only select start month </strong>
            </div>

            <div style={{ float: "right", marginRight: "-15px", fontSize: "16px", marginTop: "-15px" }}>
                <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                    Export to Excel
                </Link>

            </div>
        </div>
    );




    const handleEditLocation = (id) => {


    };

    const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                handleEditLocation(data.value[0].rowData.id);
            }
        }
    };

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const perCarBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Per Car</span>
                {currencyNegValFractionalFormat(rowData.perCar)}
            </React.Fragment>
        );
    };

    return (

        isKarlProfileType ? <>
            <div className="reportFinanceProductWidth">
                <Stack styles={container} tokens={{ childrenGap: 10 }}>

                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Finance Person Stack Rank </CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    <div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                        <div >
                            <DataTable
                                value={perCarData}
                                header={header}
                                className=" p-datatable-customers "
                                paginator={false}
                                rows={100}
                                //cellSelection
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={handleColumnSort}
                                onSelectionChange={e => captureCellSelection(e)}>
                                <Column field="financePerson" header="Finance Person" sortable />
                                <Column field="primaryLocation" header="Primary Location" sortable />
                                <Column field="amount" sortField="perCar" header="Per Car" body={perCarBodyTemplate} sortable />
                            </DataTable>
                        </div>
                    </div>

                    <div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">

                        {productList && productList.filter(x => x.productName === 'reserve').map((item, index) => (

                            <Row key={"boxes-" + index}>
                                <Col id={"delivery-section" + index}>
                                    <div  >
                                        <div className="monthEndProductTitle"> Reserve</div>
                                        <ProductTable tableData={item} />
                                    </div>
                                </Col>
                            </Row>

                        ))}

                        {productList && productList.filter(x => x.productName !== 'reserve').map((item, index) => (

                            <Row key={"boxes-" + index}>
                                <Col id={"delivery-section" + index}>
                                    <div  >
                                        <div className="monthEndProductTitle"> {tab[index]}</div>
                                        <ProductTable tableData={item} />
                                    </div>
                                </Col>
                            </Row>

                        ))}
                    </div>


                </Stack>
            </div>
        </> : null
    );
};



interface IProps {
    tableData: any;
}

export const ProductTable: React.FunctionComponent<IProps> = ({ tableData }) => {
    useEffect(() => {
        setSortOrder(-1);

    }, []);

    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('penetration');

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const penetrationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Penetration</span>
                {rowData.penetrationPer ? rowData.penetrationPer + "%" : "0.0%"}
            </React.Fragment>
        );
    };

    return (
        <>
            <DataTable
                value={tableData}
                className=" p-datatable-customers "
                paginator={false}
                rows={100}
                //cellSelection
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={handleColumnSort}>
                <Column field="financePerson" header="Finance Person" sortable />
                <Column field="primaryLocation" header="Primary Location" sortable />
                <Column field="penetrationPer" sortField="penetration" header="Penetration %" body={penetrationBodyTemplate} sortable />
            </DataTable>
        </>
    );
};
