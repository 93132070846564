import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    PrimaryButton
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { useDispatch } from "react-redux";
import {
    //handleGetPrepsByDeal,
    handleGetPreps
} from "store/dealTracker/dealTracker.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import {
//    useNavigate ,
//    useLocation
//} from "react-router-dom";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import masterDropdownValues from "constants/masterDropdownValues";
import { Calendar } from 'primereact/calendar';
import { useMediaQuery } from "react-responsive";
import { VehiclePrepDialog } from "components/dialogs/dealTrackerDialog/VehiclePrepDialog";
import CustomTable from "components/table/datatable/CustomTable";
import moment from "moment";
import AddCircle from '@mui/icons-material/AddCircle';


const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

interface IProps {
    deal: any;
}

const credentialModuleName = "DealTracker";
export const VehiclePrep: React.FunctionComponent<IProps> = ({ deal }) => {
    const dispatch = useDispatch();
    //const location = useLocation();

    //const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const [tableData, setTableData] = useState([]);
    const [needByTime, setNeedByTime] = useState('');
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    //const navigate = useNavigate ();
    const [prepDialog, setPrepDialog] = React.useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);
    //const [glFilter, setGLFilter] = useState('');
    const [prepId, setPrepId] = useState(0);
    const [dateFilter, setDateFilter] = React.useState<Date | undefined>(undefined);
    const [carType, setCarType] = useState(2);
    const slot = ["07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM"];

    useEffect(() => {
        setcommonServerError('');
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        setDateFilter(new Date());
        //dispatch<any>(handleGetPrepsByDeal({
        //    dealId: deal.id,
        //    date: moment(new Date()).format("YYYY-MM-DD"),
        //    carType: carType, callback: (res) => populateTableData(res)
        //}));
        dispatch<any>(handleGetPreps({
            locationId: deal?.location?.id,
            date: moment(new Date()).format("YYYY-MM-DD"),
            carType: carType,
            callback: (res) => populateTableData(res)
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //useEffect(() => {
    //    const stateVal: any = location?.state
    //    
    //    if (stateVal && stateVal.searchInput) {
    //        setGLFilter(stateVal.searchInput);
    //    }
    //    if (stateVal?.sortField) {
    //        // setSortField(stateVal.sortField);
    //        // setSortOrder(stateVal.sortOrder);
    //    }
    //}, [location]);

    //useEffect(() => {
    //    if (serverError) {
    //        if (serverError.detail) {
    //            setcommonServerError(serverError.detail);
    //        } else {
    //            setcommonServerError('Something went wrong');
    //        }
    //        window.scrollTo(0, 0);
    //    } else {
    //        setcommonServerError(null);
    //    }
    //}, [serverError]);

    const handleDateChange = (val: any) => {
        setDateFilter(val.target.value);
        setcommonServerError('');
        //dispatch<any>(handleGetPrepsByDeal({
        //    dealId: deal.id,
        //    date: moment(new Date(val.target.value)).format("YYYY-MM-DD"),
        //    carType: carType, callback: (res) => populateTableData(res)
        //}));
        dispatch<any>(handleGetPreps({
            locationId: deal?.location?.id,
            date: moment(new Date(val.target.value)).format("YYYY-MM-DD"),
            carType: carType,
            callback: (res) => populateTableData(res)
        }));
    };

    const populateTableData = (res) => {
        if (res && res.results) {
            let prepWorkbook = [];
            slot.forEach(function (time, index) {
                const arr = res.results.filter(x => x.needByTime && extractTime(x.needByTime).toString() === time);
                const len = arr.length;
                for (let i = 0; i < 4; i++) {
                    if (len > i) {
                        prepWorkbook.push({
                            index: i,
                            id: arr[i].id,
                            needByTime: time,
                            customerArrivalTime: extractTime(arr[i].customerArrivalTime),
                            updated: arr[i].updated,
                            stock: arr[i].stock,
                            model: arr[i].model,
                            color: arr[i].color,
                            salesPerson: arr[i].salesPerson.firstName + ' ' + arr[i].salesPerson.lastName,
                            vehicleLocation: getName(arr[i].vehicleLocation, 'prepVehicleLocationOptions'),
                            keyLocation: getName(arr[i].keyLocation, 'prepKeyLocationOptions'),
                            gas: getName(arr[i].gas, 'gasOptions'),
                            salesNotes: arr[i].salesNotes,
                            washNotes: arr[i].washNotes,
                            completed: arr[i].isCompleted ? "Yes" : "No",
                            isCompleted: arr[i].isCompleted
                        });
                    } else {
                        prepWorkbook.push({
                            index: i,
                            id: 0,
                            needByTime: time,
                            customerArrivalTime: '',
                            updated: '',
                            stock: '',
                            model: '',
                            color: '',
                            salesPerson: '',
                            vehicleLocation: '',
                            keyLocation: '',
                            gas: '',
                            salesNotes: '',
                            washNotes: '',
                            completed: ''
                        });
                    }
                }
            });
            setTableData(prepWorkbook);
        } else if (!res) {
            setcommonServerError('Failed to fetch the data.');
        }

    };

    const getName = (id, name) => {
        if (id) {
            return masterDropdownValues[name].filter(x => x.key === id)[0].text;
        }
        return '';
    }

    const extractTime = (dateTime) => {
        var date = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format('hh:mm A')
    };

    const handleToggleChange = (flag) => {
        setCarType(flag);
        setcommonServerError('');
        //dispatch<any>(handleGetPrepsByDeal({
        //    dealId: deal.id,
        //    date: moment(new Date(dateFilter)).format("YYYY-MM-DD"),
        //    carType: flag, callback: (res) => populateTableData(res)
        //}));
        dispatch<any>(handleGetPreps({
            locationId: deal?.location?.id,
            date: moment(new Date(dateFilter)).format("YYYY-MM-DD"),
            carType: flag,
            callback: (res) => populateTableData(res)
        }));
    };

    //Datatable Templates
    const header = (
        <>
            {
                isMobile ?
                    <>
                        <div>
                            <Row>
                                <Col style={{ display: "flex", justifyContent:"space-evenly" }}>
                                    <div>
                                        <PrimaryButton
                                            text="New Car"
                                            onClick={() => handleToggleChange(2)}
                                            className={carType === 2 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                                            style={{ marginRight: "5px" }}
                                        />
                                        <PrimaryButton
                                            text="Used Car"
                                            onClick={() => handleToggleChange(1)}
                                            className={carType === 1 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                                        />
                                    </div>
                                    <div>
                                        <div style={{marginTop:"-24px"}}>
                                            <label className="lbl-text">Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                <Calendar
                                                    id="date"
                                                    style={{ width: "100%" }}
                                                    value={dateFilter}
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e)}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                    minDate={new Date('01-01-1990')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        {/* <Row>
                            <Col>
                                <div>
                                    <span className="blueShadedText fontWeightSection">Status: Prep-Not Scheduled / Scheduled 3/18, 9.00 AM / Completed</span>
                                </div>
                            </Col>
                        </Row>*/}
                    </>
                    :
                    <>
                        <div style={{ width: "425px" }}>
                            <Row>
                                <Col>
                                    <PrimaryButton
                                        text="New Car"
                                        onClick={() => handleToggleChange(2)}
                                        className={carType === 2 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                                        style={{ marginRight: "5px" }}
                                    />
                                    <PrimaryButton
                                        text="Used Car"
                                        onClick={() => handleToggleChange(1)}
                                        className={carType === 1 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                                    />
                                </Col>
                            </Row>

                        </div>
                        <Row>
                            {/*<Col style={{ display: "flex" }}>
                                <div>
                                    <label className="lbl-text">Date</label>
                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                        <Calendar
                                            id="date"
                                            style={{ width: "100%" }}
                                            value={dateFilter}
                                            showIcon
                                            onChange={(e) => handleDateChange(e)}
                                            monthNavigator
                                            yearNavigator
                                            yearRange={yearRange}
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="vertical-bottom" style={{ width: "100%" }}>
                                    {<span style={{ fontSize: "20px" }} className="blueShadedText fontWeightSection">Status: Prep-Not Scheduled / Scheduled 3/18, 9.00 AM / Completed</span>
                                </div>
                            </Col>*/}
                            <Col>
                                <label className="lbl-text">Date</label>
                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                    <Calendar
                                        id="date"
                                        style={{ width: "100%" }}
                                        value={dateFilter}
                                        showIcon
                                        onChange={(e) => handleDateChange(e)}
                                        monthNavigator
                                        yearNavigator
                                        yearRange={yearRange}
                                        minDate={new Date('01-01-1990')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </>

            }

        </>
    );

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
            (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON')) {
            target.lastElementChild.lastElementChild.firstElementChild.click();
        }
        else if (event.data.id > 0 || target.nodeName === 'svg' || target.nodeName === 'path') {
            setcommonServerError('');
            setNeedByTime(event.data.needByTime);
            setPrepId(event.data.id);
            setPrepDialog(true);
        }
    }

    //Datatable body templates
    const needByTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Need By</span>

                <div className={rowData.index === 0 ? "first-prep-row" : "text-right"}>
                    {rowData.index === 0 && rowData.needByTime}
                    {
                        rowData.id === 0 && (<div>
                            <AddCircle
                                style={{ color: "#70af60" }}
                                className="plus-icon svgPlus-icon" />
                        </div>)
                    }
                </div>

            </React.Fragment>
        );
    };

    const customerArrivalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Arrival</span>
                {rowData.customerArrivalTime}
            </React.Fragment>
        );
    };

    const updatedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Updated</span>
                {rowData.updated}
            </React.Fragment>
        );
    };

    const stockBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Stock</span>
                {rowData.stock}
            </React.Fragment>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Model</span>
                {rowData.model}
            </React.Fragment>
        );
    };

    const colorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Color</span>
                {rowData.color}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales</span>
                {rowData.salesPerson}
            </React.Fragment>
        );
    };

    const vehicleLocationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle Location</span>
                {rowData.vehicleLocation}
            </React.Fragment>
        );
    };

    const keyLocationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Key Location</span>
                {rowData.keyLocation}
            </React.Fragment>
        );
    };

    const gasBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Gas</span>
                {rowData.gas}
            </React.Fragment>
        );
    };

    const salesNotesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Notes</span>
                {rowData.salesNotes}
            </React.Fragment>
        );
    };

    const washNotesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Wash Notes</span>
                {rowData.washNotes}
            </React.Fragment>
        );
    };

    const completedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Completed</span>
                {rowData.completed}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Actions</span>
                {credentials && user.roles && rowData.id > 0 && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(id) => handleEditPrep(id)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditPrep = (id) => {
        setcommonServerError('');
        const selectedItem = tableData.filter(x => x.id === id)
        if (selectedItem && selectedItem.length) {
            setPrepId(id);
            setNeedByTime(selectedItem[0].needByTime);
            setPrepDialog(true);
        }
    }

    const table_columns = {
        "needByTime": "Need By",
        "customerArrivalTime": "Customer Arrival",
        "updated": "Updated",
        "stock": "Stock",
        "model": "Model",
        "color": "Color",
        "salesPerson": "Sales",
        "vehicleLocation": "Vehicle Location",
        "keyLocation": "Key Location",
        "gas": "Gas",
        "salesNotes": "Sales Notes",
        "washNotes": "Wash Notes",
        "completed": "Completed",
        "settings": ""
    }

    const handlePrepDialogDismiss = (res) => {
        setPrepDialog(false);
        if (res.id) {
            //dispatch<any>(handleGetPrepsByDeal({
            //    dealId: deal.id,
            //    date: moment(new Date(dateFilter)).format("YYYY-MM-DD"),
            //    carType, callback: (res) => populateTableData(res)
            //}));
            dispatch<any>(handleGetPreps({
                locationId: deal?.location?.id,
                date: moment(new Date(dateFilter)).format("YYYY-MM-DD"),
                carType: carType,
                callback: (res) => populateTableData(res)
            }));
        }
        else if (typeof res === 'string') {
            setcommonServerError(res);
            window.scrollTo(0, 0);
        }
    }

    const rowClassName = (node) => {
        return { 'vehicle-prep-completed': (node.isCompleted) };
    }

    return (
        isKarlProfileType ? <>
            {
                prepDialog? <VehiclePrepDialog
                    isOpen={prepDialog}
                    needByDate={dateFilter?.toString()}
                    needByTime={needByTime}
                    carType={carType}
                    deal={deal}
                    prepId={prepId}
                    onDismiss={(res) => handlePrepDialogDismiss(res)}
                />:null
            }
            <Stack styles={container} tokens={{ childrenGap: 5 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable task-workbook vehicle-prep-tbl">
                    <div className="card reportsTableVal">
                        <CustomTable
                            column_list={table_columns}
                            data={tableData}
                            header={header}
                            paginator={false}
                            rowClassName={rowClassName}
                            customTemplates={{
                                "needByTime": needByTimeBodyTemplate,
                                "customerArrivalTime": customerArrivalBodyTemplate,
                                "updated": updatedBodyTemplate,
                                "stock": stockBodyTemplate,
                                "model": modelBodyTemplate,
                                "color": colorBodyTemplate,
                                "salesPerson": salesPersonBodyTemplate,
                                "vehicleLocation": vehicleLocationBodyTemplate,
                                "keyLocation": keyLocationBodyTemplate,
                                "gas": gasBodyTemplate,
                                "salesNotes": salesNotesBodyTemplate,
                                "washNotes": washNotesBodyTemplate,
                                "completed": completedBodyTemplate,
                               "settings": actionBodyTemplate,
                            }}
                            emptyMessage='Please wait...'
                            onRowClick={(event) => handleRowClick(event)}
                        />
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
