import { getUserToken } from "../../utils/localStorage/usersTokensLocalStorage";
/* import store from "store";
import { HANDLE_TOKEN_REFRESH } from "../../store/auth/auth.types";
import { showSnackbar } from "../../store/notifier/notifier.action";
import { NotificationType } from "../../types/notificationType"; */
import history from "utils/history/history";
import axios from "axios";

export const clientFile = (endpoint, { body, ...customConfig }) => {
  const token = getUserToken();

  const local = new Date();
  let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
  let timezoneOffset = minutes.toFixed();

  let headers = {
    Authorization: `Bearer ${token}`
  };

  if (!endpoint.includes("/login")) {
    headers = {
      Authorization: `Bearer ${token}`,
      "X-TIMEZONE-OFFSET" : timezoneOffset
    };
  }
  

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: "POST", // 'GET', 'PUT', 'DELETE', etc.
    body: body, // Coordinate the body type with 'Content-Type'
    headers: { ...headers },
  }).then(async (response) => {
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
};

const client = (endpoint, { body, ...customConfig }, isShowNotifier = true) => {
  const token = getUserToken();

  const local = new Date();
  let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
  let timezoneOffset = minutes.toFixed();

  let headers = { "Content-Type": "application/json" };

  if (!endpoint.includes("/login")) {
    headers = {
      "Content-Type": "application/json",
      "X-TIMEZONE-OFFSET" : timezoneOffset
    };
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    // responseType: "arraybuffer",
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  return window
    .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
      .then(async (response) => {
      if ((response.status === 401) && !(history.location.pathname.includes("/login"))) {
        //console.log('UNAUTHORIZE!')
        /* store.dispatch<any>({
          type: HANDLE_TOKEN_REFRESH,
        }); */
        //dispatch loguot
        // return
      }
      // const x = await response.json();
        if (isShowNotifier) {
            if (response.status >= 201 && response.status < 300) {
                /* store.dispatch<any>(
                    showSnackbar(NotificationType.success, "Operation Completed")
                ); */
            }

            if (response.status >= 500) {
                /* store.dispatch<any>(
                    showSnackbar(NotificationType.error, "Something went wrong")
                ); */
            }
        }
      

      const data = await response.json();

      if (response.status >= 400 && response.status < 500 && isShowNotifier) {
        // store.dispatch<any>(showSnackbar(NotificationType.warning, data.message));
      }

      if (response.ok) {
        return data;
      } else {
          return Promise.reject(data);
      }
    });
};

export const clientV2 = (endpoint, { body, ...customConfig }, isShowNotifier = true) => {
    const token = getUserToken();

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset = minutes.toFixed();

    let headers = { "Content-Type": "application/json" };

    if (!endpoint.includes("/login")) {
      headers = {
        "Content-Type": "application/json",
        "X-TIMEZONE-OFFSET" : timezoneOffset
      };
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const config = {
        method: body ? "POST" : "GET",
        ...customConfig,
        // responseType: "arraybuffer",
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }
    return window
        .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
        .then(async (response) => {
            if ((response.status === 401) && !(history.location.pathname.includes("/login"))) {
                //console.log('UNAUTHORIZE!')
                /* store.dispatch<any>({
                  type: HANDLE_TOKEN_REFRESH,
                }); */
                //dispatch loguot
                // return
            }
            // const x = await response.json();
            if (isShowNotifier) {
                if (response.status >= 201 && response.status < 300) {
                    /* store.dispatch<any>(
                        showSnackbar(NotificationType.success, "Operation Completed")
                    ); */
                }

                if (response.status >= 500) {
                    /* store.dispatch<any>(
                        showSnackbar(NotificationType.error, "Something went wrong")
                    ); */
                }
            }


            const data = await response.json();

            if (response.status >= 400 && response.status < 500 && isShowNotifier) {
                // store.dispatch<any>(showSnackbar(NotificationType.warning, data.message));
            }

            if (response.ok) {
                return data;
            } else {
                return Promise.reject({ 'data': data, 'status': response.status });
            }
        });
};

//Handle empty response body
export const clientV3 = (endpoint, { body, ...customConfig }, isShowNotifier = true) => {
    const token = getUserToken();

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset = minutes.toFixed();

    let headers = { "Content-Type": "application/json" };
  
    if (!endpoint.includes("/login")) {
      headers = {
        "Content-Type": "application/json",
        "X-TIMEZONE-OFFSET" : timezoneOffset
      };
    }
    
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const config = {
        method: body ? "POST" : "GET",
        ...customConfig,
        // responseType: "arraybuffer",
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };
    if (body) {
        config.body = JSON.stringify(body);
    }
    return window
        .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
        .then(async (response) => {
            if ((response.status === 401) && !(history.location.pathname.includes("/login"))) {
                //console.log('UNAUTHORIZE!')
                /* store.dispatch<any>({
                  type: HANDLE_TOKEN_REFRESH,
                }); */
                //dispatch loguot
                // return
            }
            // const x = await response.json();
            if (isShowNotifier) {
                if (response.status >= 201 && response.status < 300) {
                    /* store.dispatch<any>(
                        showSnackbar(NotificationType.success, "Operation Completed")
                    ); */
                }

                if (response.status >= 500) {
                    /* store.dispatch<any>(
                        showSnackbar(NotificationType.error, "Something went wrong")
                    ); */
                }
            }

            
            if (response.status === 204) {
                const data = { 'noContentStatus':204};
                return data;
            } else {
                const data = await response.json();

                if (response.ok) {
                    return data;
                } else {
                    return Promise.reject(data);
                }
            }
            
        });
};

export const clientPrint = (endpoint, { body,method, ...customConfig }, isShowNotifier = true) => {
  const token = getUserToken();

  const local = new Date();
  let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
  let timezoneOffset = minutes.toFixed();

  let headers = { "Content-Type": "application/json" };

  if (!endpoint.includes("/login")) {
    headers = {
      "Content-Type": "application/json",
      "X-TIMEZONE-OFFSET" : timezoneOffset
    };
  }
  
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  axios(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: method ? method : 'GET',
    responseType: 'blob', //Force to receive data in a Blob Format
    headers
  })
  .then(response => {
    // Create a Blob from the PDF Stream
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    // Open the URL on new Window
    window.open(fileURL,"_blank");
  })
  .catch(error => {
    console.log(error);
    // return Promise.reject(error);
  });
};

export const clientExport = (endpoint, { body, ...customConfig },fileName = 'report.csv', isShowNotifier = true) => {
  const token = getUserToken();

  const local = new Date();
  let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
  let timezoneOffset = minutes.toFixed();

  let headers = { };

  if (!endpoint.includes("/login")) {
    headers = { "X-TIMEZONE-OFFSET" : timezoneOffset };
  }
  
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  axios(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method: 'GET',
    responseType: 'blob', //Force to receive data in a Blob Format
    headers
  })
  .then(response => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    // Create a Blob from the PDF Stream
    const file = new Blob(
      [response.data], 
      {type: "text/csv"});
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    a.href = fileURL;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(fileURL);
  })
  .catch(error => {
    console.log(error);
    // return Promise.reject(error);
  });
};

export const jsonData = (file) => {
  return fetch(`shopWorkBook_${file}.json`
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
  .then(async function(response){
    console.log(response)
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

export default client;
