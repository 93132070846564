import React, { useState, useEffect } from "react";
import { Link } from "@fluentui/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppState } from "store";
import { useNavigate  } from "react-router-dom";
import { Col, Row } from "react-grid-system";
//import { emplyeePermissionsAction, emplyeePermissionsScreen } from "constants/constants";
//import { EmployeeActionPermission } from "hoc/withCredentials";
// import { CreateDealRecordDialog } from "components/dialogs/dealTrackerDialog/CreateDealRecordDialog";
import { CreateCustomerDealDialog } from "components/dialogs/dealTrackerDialog/dealDialog/CreateCustomerDealDialog";
import { DealRecordFoundDialog } from "components/dialogs/dealTrackerDialog/DealRecordFoundDialog";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Dropdown } from 'primereact/dropdown';
import { handleGetLocations } from "store/locations/locations.action";
import ImportContacts from '@mui/icons-material/ImportContacts';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import AssignmentOutlined from '@mui/icons-material/AssignmentOutlined';
//import { useMediaQuery } from "react-responsive";

import { CustomerNotFoundDialog } from "components/dialogs/dealTrackerDialog/dealDialog/CustomerNotFoundDialog";
import { CustomerEditDealDialog } from "components/dialogs/dealTrackerDialog/dealDialog/CustomerEditDealDialog";
import { CustomerRecordConfirmDialog } from "components/dialogs/dealTrackerDialog/dealDialog/CustomerRecordConfirmDialog";
import { handleGetDealsKPIStats,handleGetDashboardMikeReports } from "store/dealTracker/dealTracker.action";
import { emplyeePermissionsScreen } from "../../constants/constants";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { isUserMaster } from "utils/permissions/permissionsHelpers";
import { IdleTimerAutoReload } from "components/IdleTimer/IdleTimerAutoReload";

const KarlProfileDashboard = () => {
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    //const isMobile = useMediaQuery({ maxWidth: 768 });

    const navigate = useNavigate ();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [editDealRecord, setEditDealRecord] = useState(false);
    const [isCustomerNoFound, setIsCustomerNoFound] = useState(false);
    const [isCustomerRecordConfirm, setIsCustomerRecordConfirm] = useState(false);
    const [isCustomerNewData, setIsCustomerNewData] = useState(false);
    // const [isCustomerOldData, setIsCustomerOldData] = useState(false); 
    const [isDealLocationCusNonData, setIsDealLocationCusNonData] = React.useState([]);
    const [isData, setIsData] = React.useState([]);
    // const [isDealData, setIsDealData] = React.useState([]);
    //const [isDealvalues, setIsDealValues] = React.useState([]);
    const [isRetainData, setIsRetainData] = React.useState([]);

    const [dealID, setdealID] = useState(0);
    const [dealFoundDialogOpen, setDealFoundDialogOpen] = useState(false);
    const [commonServerError, setcommonServerError] = useState(null);
    const [locationList, setLocationList] = React.useState([0]);
    const [ locList, setLocList] = React.useState([]);
    const [selectedLocation, setSelectedLocation] = React.useState(0);
    const [primaryLocation, setPrimaryLocation] = React.useState(null);
    const [kpiStats, setKPIStats] = React.useState(null);
    const [keyMatData, setKeyMatData] = React.useState([]);
    const [settingPermission, setSettingPermission] = React.useState(null);

    const navigateToUniversalSearch = () => {
        navigate(`/search-inventory`);
    };

    const handleCreateDealFlow = () => {
        setcommonServerError('');
        setIsRetainData(null);
        setIsOpen(true);
    };
    useEffect(() => {
        setKPIStats(null);
        dispatch<any>(handleGetLocations());

        if (user?.roles?.some(item => item === "ROLE_EMPLOYEE") && user?.primaryLocationId) {
            setPrimaryLocation(user.primaryLocation.id);
            setSelectedLocation(user.primaryLocationId);
            dispatch<any>(handleGetDealsKPIStats({ locationId: user.primaryLocation.id, callback: (res) => populateKPIStats(res) }));
        } else {
            dispatch<any>(handleGetDealsKPIStats({ locationId: null, callback: (res) => populateKPIStats(res) }));
        }      
        dispatch<any>(handleGetDashboardMikeReports({
            callback: (response) => {
                if (response ) {
            
                    console.log('keyMatrics',response)
                    handlePopulateMikeReports(response) 
                }else{
                    setcommonServerError('Failed to fetch the mike reports data');
                    setKeyMatData([])
                }
            }
        }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if(locations){
        let locList = locations && locations.results ? [...locations.results] : [...locations];
        locList.unshift({ id: 0, legalName: 'All' });
        setLocationList(locList);
        let locationName =[ ...locations.results]
        setLocList(locationName);
      }
    }, [locations]);

    useEffect(() => {
        if (user && (isUserMaster(user.roles) || (user.defaultGroups && user.defaultGroups.some(x => x.defaultGroupId === 4)))) {
            setSettingPermission(2);
        }
        else if ((user.defaultGroups && user.defaultGroups.some(x => x.defaultGroupId === 5 || x.defaultGroupId === 19))){
            setSettingPermission(1);
        }
        else {
            setSettingPermission(null);
        }
    }, [user]);

    const populateKPIStats = (res) => {
        setKPIStats(res);
    };

    const handlePopulateMikeReports = (res) => {
        setKeyMatData(res)
    };

    const onLocationChange = (e: { value: any }) => { 
        setSelectedLocation(e.value);
        dispatch<any>(handleGetDealsKPIStats({ locationId: e.value, callback: (res) => populateKPIStats(res) }));
    }

    /* const handleAddDealTrackerDialogDismiss = (res) => {
        setIsOpen(false);
        if (res && res.id) {
            navigate(`/${res.id}/dealtracker`);
        } else if (typeof res === 'number') {
            setdealID(res)
            setDealFoundDialogOpen(true);
        } else if (!res || !res.target) {
            setcommonServerError('Failed to Save the Data');
        }
    } */

    const handleGetData = () => {
        dispatch<any>(handleGetDealsKPIStats({ locationId: selectedLocation, callback: (res) => populateKPIStats(res) }));
        dispatch<any>(handleGetDashboardMikeReports({
            callback: (response) => {
                if (response) {

                    console.log('keyMatrics', response)
                    handlePopulateMikeReports(response)
                } else {
                    setcommonServerError('Failed to fetch the mike reports data');
                    setKeyMatData([])
                }
            }
        }));
    }

    const confirmDataDialog = (res) => {
        setIsCustomerNewData(res);
        setIsCustomerRecordConfirm(true);
    };


    const openDealDialog = (res) => {
        setIsDealLocationCusNonData(res);
    };

    const handleAddDealTrackerDialogDismiss = (res) => {
        setIsOpen(false);
        if (res && res.id) {
            setIsData(res)
            setIsOpen(false);
            setEditDealRecord(true);
        } else if (typeof res === 'number') {
            setdealID(res)
            setDealFoundDialogOpen(true);
            setIsOpen(false);
        } else if (!res || !res.target) {
            setIsCustomerNoFound(true);
        }
    }


    const handleCreateDealTrackerDialogDismiss = (res) => {
        setEditDealRecord(false);

        if (res && res.id) {
            navigate(`/${res.id}/dealtracker`);
        } else if (!res || !res.target) {
            setcommonServerError('Failed to Save the Data');
        }

    }


    const handleConfirmCreateDealTrackerDialogDismiss = (res) => {
        setIsCustomerRecordConfirm(false);

        if (res && res.id) {
            navigate(`/${res.id}/dealtracker`);
        } else if (!res || !res.target) {
            setcommonServerError('Failed to Save the Data');
        }
    }


    const createNewCustomerDialog = () => {
        setIsData(null)
        setIsOpen(false);
        setIsCustomerNoFound(false);
        setEditDealRecord(true);
    };


    const SearchAgainDialog = () => {
        setIsRetainData(isDealLocationCusNonData)
        setIsCustomerNoFound(false);
        setIsOpen(true);
    };


    const changeCustomerDataConfirm = () => {
        setIsCustomerRecordConfirm(false);
    };

    const confirmCreateCustomerRecord = () => {
        setEditDealRecord(false);
    };



    const redirectToWorkbook = (url, date, status,filterLabel) => {
        const  state = {
            locationId: selectedLocation,
            filterDate: date,
            status: status,
            filterDateLabel:filterLabel,
            fromKeymetrics: 'key-metrics'
        }
        navigate(`/${url}`,{state});
    };

    return (
        user && (
            <>
                <IdleTimerAutoReload onIdle={handleGetData} />
                {isOpen && (
                    <CreateCustomerDealDialog
                        isOpen={isOpen}
                        formData={openDealDialog}
                        retainData={isRetainData}
                        primaryLocation={primaryLocation}
                        onDismiss={(msg) => handleAddDealTrackerDialogDismiss(msg)}
                    />
                )}

                {editDealRecord && (

                    <CustomerEditDealDialog
                        isOpen={editDealRecord}
                        dealData={isDealLocationCusNonData}
                        data={isData}
                        ConfirmData={confirmDataDialog}
                        onDismiss={(msg) => handleCreateDealTrackerDialogDismiss(msg)}
                    />
                )}

                {isCustomerNoFound && (

                    <CustomerNotFoundDialog
                        isOpen={isCustomerNoFound}
                        openEditDialog={createNewCustomerDialog}
                        openSearchDialog={SearchAgainDialog}
                        onDismiss={() => setIsCustomerNoFound(false)}
                    />

                )}

                {isCustomerRecordConfirm && (
                    <CustomerRecordConfirmDialog
                        isOpen={isCustomerRecordConfirm}
                        onDismiss={(msg) => handleConfirmCreateDealTrackerDialogDismiss(msg)}
                        newData={isCustomerNewData}
                        oldData={isData}
                        changeCustomerData={changeCustomerDataConfirm}
                        confirmCustomerData={confirmCreateCustomerRecord}
                    />
                )}

                {
                    dealFoundDialogOpen && (<DealRecordFoundDialog
                        dealID={dealID}
                        onDismiss={() => setDealFoundDialogOpen(false)}
                    />)
                }
                <Row className="dt-dashboard-section">
                    <Col style={{ textAlign: "center", bottom: "15px" }}>
                        {
                            commonServerError ?
                                <Row><Col>
                                    <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                                </Col> </Row> : null
                        }
                        <Row className="main-section deal-tracker-dashboard rowPaddingBtwSpace">
                            <Col>
                                <Row className="rowPaddingBtwSpace">
                                    <Col style={{ textAlign: "left"}}>
                                        <CustomFontText className="pageTitleFont pageName">Dealtracker Dashboard</CustomFontText>
                                        <div className="location-dd">
                                            <label className="lbl-text">Location</label>
                                            <Dropdown
                                                id="selectedLocation"
                                                placeholder=""
                                                value={selectedLocation}
                                                options={locationList}
                                                onChange={onLocationChange}
                                                optionLabel="legalName"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div style={{marginTop:"10px"}} className="display-row">
                                            <div className="shadowBox-radius dashboardBoxes icon-box">
                                                <Link className="ln-nw-link"
                                                    onClick={navigateToUniversalSearch}
                                                    style={{ textAlign: "center" }}>
                                                    <img alt="View Workbook" src={`${require("assets/Icon-awesome-search-dt-dashboard.png")}`} />
                                                    <span className="ln-text box-ln-text">Search Deal Records</span>
                                                </Link>
                                            </div>
                                            <div className="shadowBox-radius dashboardBoxes icon-box">
                                                <Link className="ln-nw-link" onClick={handleCreateDealFlow} style={{ textAlign: "center" }}>
                                                    <img alt="Add Vehicle" src={`${require("assets/dashboard_addvehicle.png")}`} />
                                                    <span className="ln-text box-ln-text">Add Deal Record</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{padding: "20px 0px" }}>
                                        <div className="mikeReportScroll">
                                            <table className="mikeTable">
                                                 <tbody>
                                                     <tr>
                                                         <td>
                                                              {
                                                                  keyMatData && (<table  >
                                                                     
                                                                      <tbody>
                                                                          <tr style={{ backgroundColor: "#dde0e3" }}>
                                                                              <td className="headcolGrey" >
                                                                                 <div  style={{ textAlign: "center" ,width:"100px"}}>  </div>
                                                                              </td>
                                                                              { keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-0" + index} >
                                                                                     <div style={{ textAlign: "center",fontWeight:"bold",width:"100px"}} >{item.legalName}</div>
                                                                                  </td>
                                                                              )) : locList.map((item, index) => (
                                                                                  <td key={"td-0" + index} >
                                                                                     <div style={{ textAlign: "center",fontWeight:"bold",width:"100px"}} >{item.legalName}</div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> New </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-1" + index} style={{ textAlign: "center" }}>
                                                              
                                                                                      <div style={{ textAlign: "center" }}> {item.new}</div>
                                                              
                                                                                  </td>
                                                                              )) : locList.map((item, index) => (
                                                                                  <td key={"td-1" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol" >
                                                                                  <div style={{ textAlign: "center" }}> New O/O </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-2" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.newOO}</div>
                                                                                  </td>
                                                                              )) : locList.map((item, index) => (
                                                                                  <td key={"td-2" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> Used </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-3" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.usedKarlCare}</div>
                                                                                  </td>
                                                                              )) : locList.map((item, index) => (
                                                                                  <td key={"td-3" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> Used CPO </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-4" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.usedCertified}</div>
                                                                                  </td>
                                                                              )): locList.map((item, index) => (
                                                                                  <td key={"td-4" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> Comm New </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-5" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.commNew}</div>
                                                                                  </td>
                                                                              )): locList.map((item, index) => (
                                                                                  <td key={"td-5" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> Comm O/O </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-6" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.commNewOO}</div>
                                                                                  </td>
                                                                              )): locList.map((item, index) => (
                                                                                  <td key={"td-6" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol">
                                                                                  <div style={{ textAlign: "center" }}> Comm Used </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-7" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.commUsed}</div>
                                                                                  </td>
                                                                              )): locList.map((item, index) => (
                                                                                  <td key={"td-7" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))
                                                                              }
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                          <tr>
                                                                              <td className="headcol" style={{ textAlign: "center" }}>
                                                                                  <div style={{ textAlign: "center" }}> Wholesale </div>
                                                                              </td>
                                                                              {keyMatData && keyMatData.length ? keyMatData.map((item, index) => (
                                                                                  <td key={"td-8" + index} style={{ textAlign: "center" }}>
                                                                                      <div style={{ textAlign: "center" }}> {item.wholesale}</div>
                                                                                  </td>
                                                                              )): locList.map((item, index) => (
                                                                                  <td key={"td-8" + index} >
                                                                                     <div style={{ textAlign: "center" }} >  0 </div>
                                                                                  </td>
                                                                              ))}
                                                                          </tr>
                                                                          <tr>
                                                                              <td colSpan={keyMatData && keyMatData.length ? keyMatData.length + 1 : locList.length + 1}><hr className="dashboardMikeReportsHr" /></td>
                                                                          </tr>
                                                                      </tbody>
                                                                  </table>)
                                                              }
                                                         </td>
                                                     </tr>
                                                 </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowPaddingBtwSpace">
                                    <Col>
                                        <Row>
                                            <Col className="key-metrics">
                                                <strong className="blueShadedText readField"> Key Metrics </strong>
                                            </Col>
                                        </Row>
                                        <div className="key-metrics-section">
                                            <div className="display-row">
                                                < div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('write-ups', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), 1,'writeUpToday')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Write-Ups</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayPendingDeliveries}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('write-ups', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), 1,'writeUpPendingToday')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Pending</strong><br />
                                                        <strong>Now</strong><br />
                                                        <strong>{kpiStats?.pendingDeliveries}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('write-ups', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), 3,'writeUpClosedToday')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Closed</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayClosedDeliveries}</strong>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="display-row">
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('appraisals', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Trade-Ins</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayStillWorkingTradeIns}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('appointments', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Appts</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayAppointments}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('scheduling', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Sched. Del</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayScheduledDeliveries}</strong>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="display-row">
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('tasks', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Tasks</strong><br />
                                                        <strong>Open</strong><br />
                                                        <strong>{kpiStats?.openedTasks}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('service-contract', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>After Sale</strong><br />
                                                        <strong>Prod. Today</strong><br />
                                                        <strong>{kpiStats?.todayClosedServiceContracts}</strong>
                                                    </Link>
                                                </div>
                                                <div className="shadowBox-radius dashboardBoxes deal-state">
                                                    <Link className="ln-nw-link"
                                                        onClick={() => redirectToWorkbook('cancellations', new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'}), '','')}
                                                        style={{ textAlign: "center" }}>
                                                        <strong>Cancels</strong><br />
                                                        <strong>Today</strong><br />
                                                        <strong>{kpiStats?.todayCancellations}</strong>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="rowPaddingBtwSpace deal-tracker-dashboard-links">
                            <Col>
                                <div className="link-title">
                                    <ImportContacts />
                                    <strong className="blueShadedText">Workbooks</strong>
                                </div>
                                <div className="links-section">
                                    <Link className="ln-nw-link" onClick={() => { navigate('/appointments') }}>
                                        <span className="ln-text">Appointments</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/appraisals') }}>
                                        <span className="ln-text">Trade-ins</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/write-ups') }}>
                                        <span className="ln-text">Write-ups</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/scheduling') }}>
                                        <span className="ln-text">Scheduling</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/tasks') }}>
                                        <span className="ln-text">Tasks</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/service-contract') }}>
                                        <span className="ln-text">After Sale Products</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/cancellations') }}>
                                        <span className="ln-text">Cancellations</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/vehicle-prep') }}>
                                        <span className="ln-text">Prep</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/dealer-trade') }}>
                                        <span className="ln-text">Dealer Trades</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/vehicle-tracker-workbook-page') }}>
                                        <span className="ln-text">Vehicle Tracker</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col className={settingPermission ? "second-col-links" : "border-left"}>
                                <div className="link-title">
                                    <AssignmentOutlined />
                                    <strong className="blueShadedText">Reports</strong>
                                </div>
                                <div className="links-section"> 
                                    <Link className="ln-nw-link" onClick={() => { navigate('/doc-reports') }}>
                                        <span className="ln-text">Doc Report</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/sales-month-end-reports') }}>
                                        <span className="ln-text">Sales Month End</span>  
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/sales-finance-reports') }}>
                                        <span className="ln-text">Sales Finance </span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-desking-reports') }}>   
                                        <span className="ln-text">Finance Desking</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-person-month-end-reports') }}>
                                        <span className="ln-text">Finance Month End</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-product-sales-reports') }}>
                                        <span className="ln-text">Finance Product Sales</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-stack-rank') }}>
                                        <span className="ln-text">Finance Stack Rank</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-reserve-splits-reports') }}>
                                        <span className="ln-text">Finance Reserve Split </span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-product-splits-reports') }}>
                                        <span className="ln-text">Finance Product Split</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/vacation-reports') }}>
                                        <span className="ln-text">Finance Vacation Report</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-person-lender-count-reports') }}>
                                        <span className="ln-text">Lender Count</span>
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/cdr-cpo-reports') }}>
                                        <span className="ln-text">CDR - CPO</span>
                                    </Link>
                                    {/*<Link className="ln-nw-link" onClick={handlePrintPendingDeals}>
                                        <span className="ln-text">Pending Deals</span>
                                    </Link>*/}

                                    <Link className="ln-nw-link" onClick={() => { navigate('/pending-reports') }}>
                                        <span className="ln-text">Pending Deals</span> 
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/sales-pending-reports') }}>
                                        <span className="ln-text">Sales Pending</span> 
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/finance-detail-reports') }}>
                                        <span className="ln-text">Finance Detail</span> 
                                    </Link>
                                    <Link className="ln-nw-link" onClick={() => { navigate('/Finance-Pending-Report') }}>
                                        <span className="ln-text">Finance Pending</span> 
                                    </Link>
                                </div>
                            </Col>
                            {
                                settingPermission === 2 && (<Col>   
                                    <div className="link-title">
                                        <SettingsOutlined />
                                        <strong className="blueShadedText">Settings</strong>
                                    </div>
                                    <div className="links-section">
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.SETTING_USER_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/users') }}>
                                                    <span className="ln-text">Users</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.SETTING_LOCATION_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/locations') }}>
                                                    <span className="ln-text">Locations</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.DEPARTMENT_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/departments') }}>
                                                    <span className="ln-text">Veh Departments</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.LENDER_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/lenders') }}>
                                                    <span className="ln-text">Lenders</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.FINANCE_PRODUCT_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/finance-products') }}>
                                                    <span className="ln-text">Finance Products</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.APPRAISAL_BIDDER_LIST?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/appraisal-bidders') }}>
                                                    <span className="ln-text">Bidders</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.SALES_TARGET?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/sales-goal') }}>
                                                    <span className="ln-text">Sales Targets</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }

                                    </div>
                                </Col>)
                            }
                            {
                                settingPermission === 1 && (<Col>
                                    <div className="link-title">
                                        <SettingsOutlined />
                                        <strong className="blueShadedText">Settings</strong>
                                    </div>
                                    <div className="links-section">                                     
                                        {
                                            user && user.roles && (<EmployeeActionPermission
                                                permissionList={user.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsScreen.SALES_TARGET?.toString()}
                                                roles={user.roles}>
                                                <Link className="ln-nw-link" onClick={() => { navigate('/sales-goal') }}>
                                                    <span className="ln-text">Sales Targets</span>
                                                </Link>
                                            </EmployeeActionPermission>)
                                        }

                                    </div>
                                </Col>)
                            }
                        </Row>

                    </Col>

                </Row >

            </>
        )
    );
};

export default KarlProfileDashboard;
