import client, {clientExport} from "services/api/client";
import { ICreateAccount } from "types/accountsTypes";


export const getAccounts = ({
    isActive
}: {
    isActive: number;
}) => {

    return client(
        `user/admin/accounts?active=${isActive}&page=1&limit=10000`,
        { body: null }, false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const createAccount = (account: ICreateAccount) => {
    
    return client("user/admin/accounts", { body: account, method: "POST", }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewAccount = (id: number | string) => {
    return client(`user/admin/accounts/${id}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateAccount = (id: number | string, data: any) => {
    
    return client(`user/admin/accounts/${id}`, {
        body: data,
        method: "PATCH",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const activateAccount = (id: number | string, isActive: boolean) => {

    const url = isActive ? `user/admin/accounts/${id}/activate` : `user/admin/accounts/${id}/deactivate`;
    return client(url, {
        body: null,
        method: "PATCH",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const deactivateAccount = (id: number | string) => {
    return client(`user/admin/accounts/${id}/deactivate`, {
        body: null,
        method: "PATCH",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const sendInvite = (id: number | string) => {
    return client(`user/admin/accounts/${id}/invite`, {
        body: null,
        method: "PATCH",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const exportCsv = (isActive: number) => {
    return clientExport(`user/admin/accounts/csv?active=${isActive}`, {body:null},isActive === 1?'ActiveAccounts.csv':'DeactiveAccounts.csv');
};


