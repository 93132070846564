
import React, { useState, useEffect } from 'react';
/* import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react"; */
import {
  Stack,
  IStackStyles,
  PrimaryButton,
  TextField,
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize,
} from "@fluentui/react";
import { useNavigate , useLocation } from "react-router-dom";
import { styles } from "components/dialogs/locations/styles";
import { AddInventoryDialog } from "components/dialogs/addInventoryDialog/AddInventoryDialog";
import { useSelector, useDispatch } from "react-redux";
// import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { AppState } from "store";
import { AddInventoryTrimDialog } from "components/dialogs/addInventoryDialog/AddInventoryTrimDialog";
import { AddInventoryFoundDialog } from "components/dialogs/addInventoryDialog/AddInventoryFoundDialog";
import { AddInventorySearchDialog } from "components/dialogs/addInventoryDialog/AddInventorySearchDialog";
import { PurchaseTransitionDialog } from "components/dialogs/addInventoryDialog/PurchaseTransitionDialog";
import InventoryFilterPanel from "components/panels/inventoryFilterPanel/InventoryFilterPanel";
import { handleGetShopWorkBookList, handleVehicleStatus, handleManageFavoriteVehicle } from "store/inventory/inventory.action";
// added for New inventory changes
import 'primeicons/primeicons.css';
//import { useFormik } from "formik";
//import * as Yup from "yup";
import { currencyNegValFormat, numberFormat } from "utils/helperFunctions";
import { EmployeeActionPermission } from "hoc/withCredentials";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { ShopWorkBookMoreActionsSubmenu } from "components/table/inventory/dropdown/ShopWorkbookMoreActionsSubmenu";
import masterDropdownValues from "constants/masterDropdownValues";
import Link from '@mui/material/Link';
import { Col, Row } from "react-grid-system";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetLocations } from "store/locations/locations.action";
// import CustomTable from "components/table/datatable/CustomTable"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";

const container: IStackStyles = {
  root: {
    marginTop: 10,
  },
};

/* const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
}; */

const credentialModuleName = "Inventory";
export const ShopInventoryPage = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [savedFilterID, setsavedFilterID] = useState(0);
  const [savedFilterName, setsavedFilterName] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isInventorySearchDialog, setisInventorySearchDialog] = useState(false);
  const [isInventoryTriDialog, setisInventoryTriDialog] = useState(false);
  const [isInventoryFoundDialog, setisInventoryFoundDialog] = useState(false);
  const [isPurchaseTransDialog, setIsPurchaseTransDialog] = React.useState(false);
  
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const locations = useSelector((state: AppState) => state.locations.locations) as any; 
  const [credentials, setCredentials] = useState([]);
  const [trimValues, settrimValues] = React.useState([]);
  const [vinforTrim, setvinforTrim] = React.useState(null);
  const [ymmforTrim, setymmforTrim] = React.useState(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate ();
  const [globalFilter, setGlobalFilter] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isActivestatus, setStatus] = useState(true);
  const [vehicleID, setVehicleID] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [locationFilter, setLocationFilter] = useState(0);
  const [cityDealerList, setCityDealerList] = React.useState(null);
  const isMobileWorkbook = useMediaQuery({ maxWidth:960});

  // added for new Table resposneive 
    const [products, setProducts] = useState([]);
 // const [expandedRows, setExpandedRows] = useState(null);


  //let initialformikForm = {
  //  workBookStatus: ["active"]
  //};

  //const formik = useFormik({
  //  initialValues: initialformikForm,
  //  validationSchema: Yup.object({
  //    workBookStatus: Yup.array().nullable().min(1, "Required"),
  //  }),
  //  onSubmit: (values) => {
  //  },
  //});

  /* const dynamicColumns = columns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} body={rowBodyTemplate} sortable filter />;
  }); */
  // const productService = new ProductService();

  const handleStatusChange = () => {
      if (isActivestatus) {
          dispatch<any>(handleGetShopWorkBookList('archive'));
          setStatus(false);
          //setProducts([]);
      } else {
          dispatch<any>(handleGetShopWorkBookList('active'));
          setStatus(true);
      }
      setErrorMsg('');
      if (globalFilter) {
          setGlobalFilter('');
      }
      
  }

  useEffect(() => {
    // setPathname(location.pathname);
    if (location.state && (location.state as any).action === "add") {
      setIsOpen(true);
    }
    
    const stateVal: any = location?.state
      
    if (stateVal) {
      if (stateVal && stateVal.searchInput) {
          setGlobalFilter(stateVal.searchInput);
      }
      if (stateVal?.locationFilter) {
          setLocationFilter(stateVal?.locationFilter);
      }
      if (stateVal.activeStatus === undefined || stateVal.activeStatus) {
        dispatch<any>(handleGetShopWorkBookList('active'));
        setStatus(true);
      } else {
        dispatch<any>(handleGetShopWorkBookList('archive'));
        setStatus(false);
      }
    }
    if (stateVal?.sortField) {
      setSortField(stateVal.sortField);
      setSortOrder(stateVal.sortOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    dispatch<any>(handleGetLocations());
    dispatch<any>(handleGetShopWorkBookList("active"));
    if (user && user.auths && !isUserMaster(user.roles)) {
      setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    // dispatch<any>(handleGetInventoryFilterList("inventory"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (user.roles?.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
          setLocationFilter(user.primaryLocationId);
      }
  }, [user])

  useEffect(() => {

      if (locations && locations.results) {
          locations.results.unshift({ id: 0, legalName: 'All' });
          setCityDealerList(locations.results);
      } else {
          setCityDealerList(locations);
      }
      //console.log('locationslocationslocations',locations)   
  }, [locations]);
    
  // const [arrayFilters, setarrayFilters] = useState([{key: 0 , text: '--Select Saved Filters--'}]);

  const filtersList = useSelector((state: AppState) => state.inventory.filterList);
  const shopWorkBookList = useSelector((state: AppState) => state.inventory.shopWorkBookList);
  const serverError = useSelector((state: AppState) => state.inventory.error);
  
    useEffect(() => {
        if (isActivestatus) {
            // expandableTableRows(shopWorkBookList);
            setProducts(shopWorkBookList);
        } else {
            setProducts(shopWorkBookList.slice(0,10));
        }
        if (globalFilter || locationFilter) {
            handleVehicleSearch();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopWorkBookList]);

  useEffect(() => {
      if (serverError) {
          setErrorMsg('Failed to get the Data');
      } else {
          setErrorMsg('');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  useEffect(() => {
    let filterArry = [{key: 0 , text: '--Select Saved Filters--'}];
    if (filtersList && (filtersList.length > 0)) {
      filtersList.map((item) => {
        return filterArry.push({ key: item.id, text: item.name });
      });
      const filteredItem = filterArry.filter((ele) => ele.text === savedFilterName);
      if (filteredItem.length > 0) {
        setsavedFilterID(filteredItem[0].key)
      } else {
        setsavedFilterID(0);
      }
      // setarrayFilters(filterArry);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersList]);

  const onDismiss = () => {
    setIsOpen(false);
  };

  const onFilterIdChange = (key: any, name?: string) => {
    setsavedFilterID(key);
    if (name) {
      setsavedFilterName(name);
    }    
  }

  /* const expandableTableRows = (data) => {
    let _expandedRows = {};
    data.forEach(p => {
      if (p.vin && p.vin !== '') {
        _expandedRows[`${p.id}`] = true;
      }
    });
    setExpandedRows(_expandedRows);
  } */

    const navigatetoAddVeh = () => {
        if (globalFilter) {
            setGlobalFilter('');
        }
    // navigate('/add-inventory');
    setisInventorySearchDialog(true)
  };

  const handleSearchChange = (e) => {
      setGlobalFilter(e.target.value);
  }

  const onLocationChange = (e: { value: any }) => {
      setLocationFilter(e.value);
  }

  useEffect(() => {
      handleVehicleSearch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, locationFilter]);

    const handleVehicleSearch = () => {
        if (globalFilter || locationFilter) {
            let results = shopWorkBookList;
            if (globalFilter && globalFilter.length > 2) {
                const lowerCaseVal = globalFilter.toLowerCase().split(" ");

                lowerCaseVal.forEach(function (item) {
                    if (item !== '') {
                        results = results.filter(x => x.make.toLowerCase().includes(item) ||
                            x.model.toLowerCase().includes(item) ||
                            x.style?.toLowerCase().includes(item) ||
                            x.year.toString().toLowerCase().includes(item) ||
                            (x.vin && x.vin.toLowerCase().includes(item)));
                    }
                });

            }
            if (locationFilter > 0) {
                results = results.filter(x => x.location?.id === locationFilter);
            }

            setProducts(results);

        }
        else if (isActivestatus) {
            setProducts(shopWorkBookList);

        } else {
            setProducts(shopWorkBookList.slice(0, 10));
        }
    }

    const handleResetWorkbook = () => {
        setGlobalFilter(null);
        setLocationFilter(user.primaryLocationId ? user.primaryLocationId:0);
    };

    const header = (
        <>
            <div>
                <div>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <EmployeeActionPermission
                                    permissionList={user.aggregatedDefaultPolicies}
                                    alias={emplyeePermissionsAction.VEHICLE_SEARCH}
                                    roles={user.roles}
                                >
                                    <Col md={3}>
                                        <div className="table-header employeeeSearchLabelText">
                                            <TextField
                                                value={globalFilter}
                                                type="search"
                                                label="Search "
                                                className="ownerSearchText"
                                                onChange={(e) => handleSearchChange(e)}
                                            />

                                        </div>
                                    </Col>
                                </EmployeeActionPermission>
                                
                                <Col md={3}>
                                    <div className="dd-control">
                                        <label className="lbl-text">Location</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={locationFilter}
                                            options={cityDealerList}
                                            onChange={onLocationChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col className=" employeeeSearchLabelText" style={{ marginTop: "25px", maxWidth: "40%" }}>
                                    <PrimaryButton onClick={handleResetWorkbook}>
                                        Reset
                                    </PrimaryButton>
                                </Col>
                                <Col>
                                    <div className="table-footer table-footer-action appraisalLinkSec" style={{float:"right",marginTop:"35px"}}>
                                        <Link onClick={handleStatusChange} className="link-font-text"> {isActivestatus ? "Archived Appraisals" : "Active Appraisals"}</Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </div>

            </div>
        </>
    );

    const mileageBodyTemplate = (rowData) => {
        let truncatedVin = rowData.vin ? rowData.vin.substr(-8) : '';
        return (
            <React.Fragment>
            {true ?
            <>
              <div className="vinMobSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">VIN <span>{truncatedVin}</span></span>
                <span className="p-column-title">Odo <span>{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span></span>
              </div>
            </>
            : 
            <div className="vinOdoSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">Odo</span>
                <span className="mobileDisplay">{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span>
            </div> 
            }
            <div className="vinOdoSec mobileDisplayNone">
                <span className="p-column-title">Odo</span>
                {rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}
            </div>
            </React.Fragment>
        );
    };

    

  const favoriteBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <span className="p-column-title">
              </span>
              {!rowData.isFavorite || rowData.isFavorite === false ? <img alt="Favorite" src={`${require("assets/Icon-feather-star-no-fill.png")}`} /> : 
                  <img alt="Favorite" src={`${require("assets/Icon-feather-star-fill.png")}`} />
               }
              
          </React.Fragment>
      );
  };

  const yearBodyTemplate = (rowData) => {
    return (
        <React.Fragment >
            {/* <span className="p-column-title">Year</span> */}
            {rowData.year}
            <span className="vinDisplayPos">{rowData.vin}</span>
        </React.Fragment>
    );
  };
  
  const askingPriceBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Seller Asking</span>
            {rowData.vehicleExpense && rowData.vehicleExpense.sellersAsking && currencyNegValFormat(rowData.vehicleExpense.sellersAsking)}
        </React.Fragment>
    );
  };
  
  const sourceTypeBodyTemplate = (rowData) => {
        let source = null;
        if (rowData.vehicleSeller) {
            let vehicleSeller = masterDropdownValues.sourceTypeList.filter(x => x.key === rowData.vehicleSeller.source);
            if (vehicleSeller && vehicleSeller.length) {
                source = vehicleSeller[0].text;
            }
        }
    return (
        <React.Fragment>
            <span className="p-column-title">Source</span>
            {source}
        </React.Fragment>
    );
  };
  
  const bidPriceBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Purchase Price</span>
            {rowData.vehicleExpense && rowData.vehicleExpense.myOffer && currencyNegValFormat(rowData.vehicleExpense.myOffer)}
        </React.Fragment>
  );
  };

  const myListPriceBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <span className="p-column-title">My List</span>
              {rowData.vehicleExpense && rowData.vehicleExpense.targetListPrice && currencyNegValFormat(rowData.vehicleExpense.targetListPrice)}
          </React.Fragment>
      );
  };

  const estimatedGrossBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <span className="p-column-title">Profit</span>
            {rowData.estimatedGross}
        </React.Fragment>
    );
  };

  const qValuePriceBodyTemplate = (rowData) => {
    const examplePersona: IPersonaSharedProps = {
        imageUrl: rowData.isQValueEnabled
            // ? `${process.env.REACT_APP_IMAGE_SERVER}/${rowData.logo}`
            ? `${require("assets/qvalue_ordered.png")}`
            : `${require("assets/qvalue_notordered.png")}`,
    };

    return (
        <React.Fragment>
            {/* <span className="p-column-title">QValue</span> */}
            <div className="qValueImg">
                <Persona
                    {...examplePersona}
                    styles={styles.personInGridStyles}
                    size={PersonaSize.size56}
                    presence={PersonaPresence.none}
                    hidePersonaDetails={false}
                    imageAlt="Annie Lindqvist, status is dnd"
                />
            </div>
        </React.Fragment>
    );
  };

 const handleEditInventory = (vehicleID) => {
  const state = {
    activeStatus: isActivestatus,
    searchInput: globalFilter,
    pathfrom: '/appraisal',
    sortField,
    sortOrder,
    locationFilter
  }
      navigate(`/${vehicleID}/shop-inventory`,{state});
  };

  /* const handleRowClick = (event) => {
      //let target = event.originalEvent.target;
      //
      //if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
          
      //    target.lastElementChild.lastElementChild.click()
      //    return;
      //} else {
      //    navigate(`/${event.data.id}/shop-inventory`);
      //}
      navigate(`/${event.data.id}/shop-inventory`);
  }; */

  const rowClassName = (node) => {
    return { 'vinRow': (node.vin && node.vin !== '') };
  }

  const handleInventoryStatusChange = (vehicleID,status) => {
      setErrorMsg('');
      dispatch<any>(handleVehicleStatus(vehicleID, status, (res) => {
          if (res) {
              setErrorMsg(res.message);
          }
          else {
              if (isActivestatus) {
                  dispatch<any>(handleGetShopWorkBookList('active'));
              } else {
                  dispatch<any>(handleGetShopWorkBookList('archive'));
              }
          }

      }));
  };

  const settingBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
            {credentials && user.roles && (
                
                  <ShopWorkBookMoreActionsSubmenu
                      modulePermissions={user.aggregatedDefaultPolicies}
                      vehicleID={rowData.id}
                      status={rowData.status}
                      onPurchase={(vehicleID) => openPurchaseTransitionDialog(vehicleID) }
                      onEdit={(vehicleID) => handleEditInventory(vehicleID)}
                      onStatusChange={(vehicleID, status) => handleInventoryStatusChange(vehicleID, status) }
                  />
            )}


        </React.Fragment>
    );
};

  const openPurchaseTransitionDialog = (vehicleID) => {
        setVehicleID(vehicleID);
        setIsPurchaseTransDialog(true);
  };

  const resetVinYmm = () => {
    setvinforTrim(null);
    setymmforTrim(null);
  }

  const openTrimDialog = ({vin, trim, ymm}) => {
    resetVinYmm();
    setisInventorySearchDialog(false);
    settrimValues(trim);
    setisInventoryTriDialog(true);
    if (vin) {
      setvinforTrim(vin);
    } else {
      setymmforTrim(ymm);
    }
  };
  /* const table_columns = {
    "year": "Year", "make": "Make", "model": "Model",
    "mileage": "Odo", "sourcetye": "Source",
    "buyPrice": "Asking Price", "qValue": "Qvalue",
    "sellPrice": "Bid Price", "dealerList": "My List Price",
    "estimatedGross": "Estimated Gross", "Settings": "Actions"
    } */

  const handleAddInventoryDialogDismiss = (vehicleId) => {
      setisInventorySearchDialog(false);
      if (vehicleId) {
          setVehicleID(vehicleId);
          setisInventoryFoundDialog(true);
      }
  }

  /* const rowExpansionTemplate = (data) => {
    if (data.vin && (data.vin !== '')) {
      return (
          <div className={`vin-subtable ${data.id}`} onClick={() => vinRowSelection(data)}>
              <span>{data.vin}</span>
          </div>
      );
    } else {
      return false;
    }
  }; */

  /* const vinRowSelection = (row) => {
    if (row && row) {
      navigate(`/${row.id}/shop-inventory`);
    }
  } */

    const captureCellSelection = (data) => {
        if (data && data?.value?.length && (data?.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        }
        else if (data && data?.value?.length && (data?.value[0].field === 'isFavorite')) {           
            const isFavorite = !data?.value[0]?.value || data?.value[0].value === false ? true : false;
            dispatch<any>(handleManageFavoriteVehicle(
              data.value[0].rowData.id,
              isFavorite,
              isActivestatus ? 'active' :'archive',
              (res) => {
                if (res) {
                  // Map the corresponding the Favorite Value in the row Index
                  let newArr = [...products]; // copying the old datas array
                  newArr.map((obj) => {
                    if (obj.id === data.value[0].rowData.id) {
                      // Change the val of Fav Field
                      obj.isFavorite = !obj.isFavorite; 
                    }
                    return obj;
                  })
                  setProducts(newArr);
                }
              }));
        }
        //else if (data && data.value.length && (data.value[0].field === 'favorite')) {
        //    if (data.value[0].value) {
        //        data.value[0].value = false;
        //        data.originalEvent.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAcdJREFUOE+VkjtLA0EUheeOCQpWVhZaWBhbCwsRGxHBTvAHCIKK+CBIsuzs+kilmdk1G0F8QFAQ/ANWClYiqJWWNrERtBMCKhZidjwREjYkWeJWO3vv/ebcc5ZYyKOy6pz57McyrIlGbRQGcDxHo14USRH5N0BlVIqIOAYjWusvqEjXgzRUAMDfzblcLlL4KHwC0NoUQGZlL2laYZoVMLRRGgJMcuLtRSru2gn7KQgiFFchdRgDMUYsppl+wvsFhuPBRuWpPZzHiVEv6nn05rHaDcmMnAV9koq0YppmPszUcs113Zgf8Xe0r8/+PAB9AeQB7DzbDADpHEHpvZW0DismOllnBpJG8HEq9N/w1ClWvhIJcVzqq0oBkEUYSGbS3K8HcT13CUZqGHlQrlcBsMo8VAzZhj1dD4ALTsinW9Mwc40Ae1DwKAxRuSEIAmAZxvUFE6pewXOuoCCFhmuomUNcsgTgjFu49UhuyxHOeQpmj9ZXkFFvcNeCSWtQchnlUbvU+K2/Fc5jPvPTgG1BYWcNQErZwaP8DoWHFtZiG4bxHJQvd2QP97lC3P1t0bbBeDz+XpPCZnazaz2x/hoWo+M43UKIl3LPLzJCwSVUV2QeAAAAAElFTkSuQmCC';
        //    } else {
        //        data.value[0].value = true;
        //        data.originalEvent.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAN5JREFUKFOVko8RwTAUhxMT2IANMAGdABsYwQTEJjbABGqD2oANugHfr5peRZOrd/e79vLe9/4l1iTs5YyzKBZiYw7ABb4rykiQd8WlYAFzlANnveFWVc90Vv+pXIN7KLXtLTcD5t+ZW7sDS/CWgyWaomFqgbWv5Fugi2ABmm/SA/Qhd3VWtf1ngg/oTNnM3DNBA6ro18JIsOLslGh/TcWz94eww6FNx+zQfnEhrKzafMwuwOqushDWFWjrT6R3fWSUjf7RCBWczWLww0NhaZ8EeNwJJ9qtXLoRXZGPewPgUEWOPWvmQQAAAABJRU5ErkJggg==';
        //    }
        //}
        else if (data && data?.value?.length) {
          const role = isUserMaster(user.roles);
          const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true; 
          if (cred || role) {
            const  state = {
              activeStatus: isActivestatus,
              searchInput: globalFilter,
              pathfrom: '/appraisal',
              sortField,
              sortOrder,
              locationFilter
            }
            navigate(`/${data.value[0].rowData.id}/shop-inventory`,{state});
          }
        }
    };

    const onSort = (event) => {
        if (event.sortField === 'isFavorite') {
            setSortField(null);
            setSortOrder(0);
            let newTabData = [...products];
            newTabData.sort(function(a, b) {
              var nameA = a.isFavorite;
              var nameB = b.isFavorite; 
              return (nameA === nameB)? 0 : nameA? -1 : 1;
            });
            setProducts(newTabData);
        } else {
            setSortField(event.sortField);
            setSortOrder(event.sortOrder);
        }
    }
    
  return (
    <>
      {isPanelOpen && (
        <InventoryFilterPanel
          isOpen={isPanelOpen}
          filterName={savedFilterName}
          onClose={() => setIsPanelOpen(false)}
          filteredId={savedFilterID}
          onFilterIdChange={onFilterIdChange}
        />
      )}

      {isOpen ? <AddInventoryDialog onDismiss={onDismiss} /> : null}

      { isInventorySearchDialog ? 
          <AddInventorySearchDialog
            onDismiss={(vehicleId) => handleAddInventoryDialogDismiss(vehicleId)}
            openTrimDialog={openTrimDialog}
          /> : null}

      
        {isInventoryTriDialog ? 
          <AddInventoryTrimDialog
            onDismiss={() => setisInventoryTriDialog(false)}
            trimValues={trimValues}
            vin={vinforTrim}
            ymm={ymmforTrim}
            vehID={null}
            orderStock={null}
          />
        : null}

          {isInventoryFoundDialog ?
              <AddInventoryFoundDialog
                  onDismiss={() => setisInventoryFoundDialog(false)}
                  vehicleId={vehicleID}
              />
              : null}
          {isPurchaseTransDialog ?
              <PurchaseTransitionDialog
                  onDismiss={(msg) => { setErrorMsg(msg); setIsPurchaseTransDialog(false); }}
                  vehicleId={vehicleID}
              />
              : null}

      {/*<AddInventoryDialog isOpen={isOpen} onDismiss={onDismiss} />*/}
      {/*<Loader show={loading} />*/}
      <Stack styles={container} tokens={{ childrenGap: 10 }} className="shopWorkBookPg appraisalWorkBook">
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          {credentials && user.roles && (

<>
  {/* <WithCredentials
  groupAuths={credentials}
  // alias={"Shop Workbook"}
  alias={permissionsModules.ShopWorkbook}
  type={eTypes.WRITE}
  roles={user.roles}
  > */}
  
    
  <Row>
    <Col className="alignHeaders">
        <CustomFontText className="pageTitleAppraisal inventoryTitle  pageName fontColorChange " >{isActivestatus ? "Appraisal Inventory - Active" :"Appraisal Inventory - Archived" }</CustomFontText>
         {credentials && user.roles && (
            
              <EmployeeActionPermission
                permissionList={user.aggregatedDefaultPolicies}
                alias={emplyeePermissionsAction.VEHICLE_ADD}
                roles={user.roles}
              >
                <PrimaryButton
                  onClick={navigatetoAddVeh}
                  text="Add Vehicle"
                  style={{marginBottom:"0"}}
                  className="addInventoryBtnDesk"
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                />
             </EmployeeActionPermission>
            )} 
          </Col>
        </Row>
      {/*   <PrimaryButton
          onClick={() => setIsImportOpen(true)}
          text="Import Inventories"
          iconProps={{ iconName: "Upload" }}
        /> */}
        {/* <ImportInventoriesDialog
          loading={false}
          description=""
          title="Import Inventory"
          isOpen={isImportOpen}
          onAction={_handleImportDialog}
          onDismiss={() => onDismissUploadDialog(false)}
        />  */}               
      {/* </WithCredentials> */}
      </>
      )}
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
            {/* <GoBack label={formatPathname(pathname)} count={totalItems} /> */}

            
            {/*<AddLocationDialog*/}
            {/*  isOpen={isOpen}*/}
            {/*  onDismiss={() => handleOnDismiss()}*/}
            {/*/>*/}
          </Stack>
        </Stack>
        
        <div>
              <EmployeeActionPermission
                permissionList={user.aggregatedDefaultPolicies}
                alias={emplyeePermissionsAction.VEHICLE_ADD}
                roles={user.roles}
              >
                <PrimaryButton
                  onClick={navigatetoAddVeh}
                  text="Add Vehicle"
                  className="addInventoryBtnMob"
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                />
              </EmployeeActionPermission>
        </div>
        {
            errorMsg ?
                <div>
                          <p className="genericErrorColor"><span>{errorMsg}</span></p>
                </div> : null
        }
        <Row>
          <Col>
            <div className="tableColumnTitle datatable-filter-demo noMaxWidthTable shop-inventory-tbl appraisalTable">
                <div className="card mouse-hover">

                       <DataTable 
                         rowClassName={rowClassName}
                         value={products}
                         header={header}
                         paginator={isMobileWorkbook ? false :true }
                         rows={isMobileWorkbook ? 10000 :25}
                         className="p-datatable-customers p-datatable-hoverable-rows"
                         // onRowClick={(event) => handleRowClick(event)}
                         // expandedRows={expandedRows}
                         // onRowToggle={(e) => setExpandedRows(e.data)}
                         // rowExpansionTemplate={rowExpansionTemplate} 
                         dataKey="id"
                         // //cellSelection
                         // selection={selectedProduct2}
                         onRowClick={e => captureCellSelection(e)}
                         //onRowExpand={onRowExpand}
                         //onRowCollapse={onRowCollapse}
                         onSort={onSort}
                         sortField={sortField}
                         sortOrder={sortOrder}
                         emptyMessage={!isActivestatus ? "Use search to find Archived Vehicles." :null}>
                         {/* onRowDoubleClick={(e)=> generateReports(e)}> */}
                                  {/* <Column expander style={{ width: '3em' }} /> */}
                         <Column field="isFavorite" headerClassName="favourite" body={favoriteBodyTemplate} className="favoritesCol" sortable />
                         <Column field="year" header="Year" body={yearBodyTemplate} sortable className="yrMakeModel yrOly"/>
                         <Column field="make" header="Make" sortable className="yrMakeModel"/>
                         <Column field="model" header="Model" sortable className="yrMakeModel"/>
                         <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable/>
                         <Column field="sourcetye" header="Source" body={sourceTypeBodyTemplate} sortable className="mobileDisplayNone"/>
                         <Column field="buyPrice" header="Asking Price" body={askingPriceBodyTemplate} sortable/>
                         <Column field="qValue" header="Qvalue" body={qValuePriceBodyTemplate} className="qValCol" />
                         <Column field="sellPrice" header="Purchase Price" body={bidPriceBodyTemplate} sortable className="mobileDisplayNone" />
                         <Column field="dealerList" header="My List Price" body={myListPriceBodyTemplate} sortable/>
                         <Column field="estimatedGross" header="Profit" body={estimatedGrossBodyTemplate}  sortable/>
                         <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                       </DataTable>

                </div>
            </div>
          </Col>
        </Row>
      </Stack>
    </>
  );
};
