import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
    Link
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetServiceContract } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    convertUTCToLocalDateOnlyModified,
    //convertUTCToLocalModified,
    convertUTCToLocalMoment
} from "utils/dateTimeFunctions";
import { useNavigate , useLocation } from "react-router-dom";
//import masterDropdownValues from "../../constants/masterDropdownValues";
//import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "DealTracker";
export const ServiceContractWorkbookPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    //const [appointmentId, setAppointmentId] = useState(0);
    const [serviceContractData, setServiceContractData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    //const appointments = useSelector((state: AppState) => state.dealTracker.appointments) as any;
    const serviceContract = useSelector((state: AppState) => state.dealTracker.serviceContract) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 

    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState(["All"]);
	
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);

    const [glFilter, setGLFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [statesFlag, setStatesFlag] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('logDate');
    const [filteredData, setFilteredData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(null);

    const handleFirstNameFilter = (val: any) => {
		setGLFilter(val.target.value);
        //handleFilters(val.target.value,locationFilter,logDateFromFilter,logDateToFilter);
		//dt.current.filter(val, 'firstName', 'contains');
	};

	//const handleLocationFilter = (val: any) => {
	//	setLocationFilter(val.target.value);
    //    handleFilters(glFilter,val.target.value ,logDateFromFilter,logDateToFilter);
	//	//dt.current.filter(val, 'firstName', 'contains');
	//};

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
		handleFilters(e.value ,logDateFromFilter,logDateToFilter);
    }


    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value);
		handleFilters(locationFilter, val.target.value ,logDateToFilter);
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter  (val.target.value)
        handleFilters(locationFilter,logDateFromFilter,val.target.value );
    };



    const handleFilters = (locationFilt,logDateFromFilter,logDateToFilter) => {
	
        if (locationFilt || logDateFromFilter || logDateToFilter) {

            let results = serviceContract;
            if (results) {
                /** location **/

                if (locationFilt > 0) {
                   results = results.filter(x => x.deal.location.id === locationFilt);
                }

                if (logDateFromFilter) {
                    //results = results.filter(x => new Date(x.logDate) >= new Date(logDateFromFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.logDate)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
                    results = results.filter(x => convertUTCToLocalMoment(x.logDate) >= convertUTCToLocalMoment(logDateFromFilter));
                }

                if (logDateToFilter) {
                    //results = results.filter(x => new Date(x.logDate) <= new Date(logDateToFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.logDate)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
                    results = results.filter(x => convertUTCToLocalMoment(x.logDate) <= convertUTCToLocalMoment(logDateToFilter));

                }
                // expandableTableRows(results);
                setServiceContractData(results);
            }
            
        } else {
            // expandableTableRows(vehicleList);
            setServiceContractData(serviceContract);
        }
    }


    useEffect(() => {
        if (serviceContract && statesFlag) {
            if (serviceContract.length > 0 && user.profileType) {
                handlePopulateAppointments();
            }
            else {
                setServiceContractData([]);
            }
        }
    }, [serviceContract, statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

    useEffect(() => {
        if (user && user.profileType && serviceContract && serviceContract.length > 0) {
            handlePopulateAppointments();
        }
        else {
            setServiceContractData([]);
        }
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateAppointments = () => {
        if (serviceContract) {
            let hiddenStr;
            for (var i in serviceContract) {
                let deal = serviceContract[i].deal;
                let customer = deal.customer;
                hiddenStr = '';
               // serviceContract[i]["appointDateTime"] = convertUTCToLocalDateOnly(serviceContract[i].appointmentDate) + ' ' + extractTime(serviceContract[i].appointmentTime);
                serviceContract[i]["dealNumber"] = deal.dealNumber;
                serviceContract[i]["firstName"] = customer.firstName;
                serviceContract[i]["lastName"] = customer.lastName;
                serviceContract[i]["customerEmail"] = customer.email;
                serviceContract[i]["customerPhone"] = customer.phoneNumber;
                serviceContract[i]["closeDate"] = convertUTCToLocalDateOnlyModified(serviceContract[i].closeDate );
                serviceContract[i]["empName"] = serviceContract[i].referral?serviceContract[i].referral.firstName +' '+serviceContract[i].referral.lastName:'';
                serviceContract[i]["financeMgrNameFirst"] = deal.financeManagers?getManagerName(deal.financeManagers) :'';
                serviceContract[i]["financeMgrNameSecond"] = deal.financeManagers?.length===2 ?getManagerNameSecond(deal.financeManagers) :'';
                serviceContract[i]["product"] = serviceContract[i].financeProduct?.name;
                serviceContract[i]["logDate"] = moment(new Date(serviceContract[i].createdAt)).local().format('MM/DD/YY');
                serviceContract[i]["id"] = serviceContract[i].id ? serviceContract[i].id : '';

                if (deal.vehicle)
                    hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
                if (deal.salesPerson1)
                    hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
                if (deal.salesPerson2)
                    hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
                if (deal.financePerson1)
                    hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
                if (deal.financePerson2)
                    hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
                hiddenStr = hiddenStr + ' ' + customer?.customerNumber + ' ' + customer?.businessName + ' ' + customer.firstName + ' ' + customer.lastName;
                serviceContract[i]["hiddenCol"] = hiddenStr;
            }
            handleFilters(locationFilter, logDateFromFilter, logDateToFilter);
            //if (locationFilter) {
            //    const results = serviceContract.filter(x => x.deal.location.id === locationFilter);
            //    setServiceContractData(results);
            //} else {
            //    setServiceContractData(serviceContract);
            //}
        }
    };


    const getManagerName = (financeManagers) => {
        if (financeManagers && financeManagers.length > 0) {
            return financeManagers[0].firstName + ' ' + financeManagers[0].lastName ;
        }
        return '';
    };


    const getManagerNameSecond = (financeManagers) => {
        if (financeManagers.length===2) {
            return financeManagers[1].firstName + ' ' + financeManagers[1].lastName ;
        }
        return '';
    };


    useEffect(() => {
        setSortOrder(-1);
        setSortField('logDate');
        setStatesFlag(null);
        setServiceContractData([]);
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetServiceContract())  
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        computeTotalRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceContractData, filteredData]);

    //Datatable Templates
    
	const handleResetWorkbook = () => {   
        setGLFilter(null);
        setLocationFilter(primaryLocation);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setSortOrder(-1);
        setSortField('logDate');
        handleFilters(primaryLocation, null, null);
        //if (primaryLocation) {
        //    const results = serviceContract.filter(x => x.deal.location.id === primaryLocation);
        //    setServiceContractData(results);
        //} else {
        //    setServiceContractData(serviceContract);
        //}
    };

    const computeTotalRecords = () => {
        if (serviceContractData && serviceContractData.length) {
            let data = [];
            if (glFilter) {
                data = [...filteredData];
                if (data) {
                    if (locationFilter || logDateFromFilter || logDateToFilter) {
                        if (locationFilter > 0) {
                            data = data.filter(x => x.deal.location.id === locationFilter);
                        }
                        if (logDateFromFilter) {
                            //data = data.filter(x => convertUTCToLocalModified(new Date(x.logDate)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
                            data = data.filter(x => convertUTCToLocalMoment(x.logDate) >= convertUTCToLocalMoment(logDateFromFilter));
                        }
                        if (logDateToFilter) {
                            //data = data.filter(x => convertUTCToLocalModified(new Date(x.logDate)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
                            data = data.filter(x => convertUTCToLocalMoment(x.logDate) <= convertUTCToLocalMoment(logDateToFilter));
                        }
                    }
                }
            }
            else
                data = [...serviceContractData];
            setTotalRecords(data.length);
        }
        else {
            setTotalRecords(0);
        }
    }

    const exportExcel = () => {
        if (serviceContractData && serviceContractData.length) {
            let data = [];
            if (glFilter) {
                data = [...filteredData];
                if (data) {
                    if (locationFilter || logDateFromFilter || logDateToFilter) {
                        if (locationFilter > 0) {
                            data = data.filter(x => x.deal.location.id === locationFilter);
                        }
                        if (logDateFromFilter) {
                            //data = data.filter(x => convertUTCToLocalModified(new Date(x.logDate)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
                            data = data.filter(x => convertUTCToLocalMoment(x.logDate) >= convertUTCToLocalMoment(logDateFromFilter));
                        }
                        if (logDateToFilter) {
                            //data = data.filter(x => convertUTCToLocalModified(new Date(x.logDate)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
                            data = data.filter(x => convertUTCToLocalMoment(x.logDate) <= convertUTCToLocalMoment(logDateToFilter));
                        }
                    }
                }
            }
            else
                data = [...serviceContractData];

            //const exportData = data.map(({ id, appointmentDate, appointmentResult, appointmentTime, createdAt, employee, deal, ...keepAttrs }) => keepAttrs);
            if (data) {
                const exportData = data.map((item) =>
                    ({
                        "Log Date": item.logDate,
                        "Deal #": item.dealNumber,
                        "Finance 1": item.empName,
                        "Product": item.product,
                        "First Name": item.firstName,
                        "Last Name": item.lastName,
                        "Phone #": item.customerPhone,
                        "Email Address": item.customerEmail,
                        "Price": item.price,
                        "Cost": item.cost,
                        "Deductible": item.deductable,
                        "Profit": item.profit,
                        "Term": item.term,
                        "Mileage": item.mileage,
                        "Close Date": item.closeDate
                    }));

                import('xlsx').then(xlsx => {
                    const worksheet = xlsx.utils.json_to_sheet(exportData);
                    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                    saveAsExcelFile(excelBuffer, 'after-sale-products-workbook');
                });
            }
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


	const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			             <Col>
			                  <div className=" employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Service Contracts"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
			                <div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
			         	</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"27px"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
                   </Row>
                   <Row>
                       <Col className="display-row" style={{ marginTop: "15px" }}>
                           {
                               totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                           }
                           <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS">
                                        Export to Excel
				            </Link>
                       </Col>
                   </Row>
				</Col>
		    </Row>

		</div>:
		<div>
            <div>
		        <Row>
		            <Col md={10}>
			            <Row>
			                <Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	    </Col>
			         	    <Col  md={3}>
						        <div className="dd-control">
			                        <label className="lbl-text">Location</label>
                                    <Dropdown
                                          id="locationId"
                                          placeholder=""
                                          value={locationFilter}
                                          options={cityDealerList}
                                          onChange={onLocationChange}
                                          optionLabel="legalName"
                                          optionValue="id"
                                          appendTo="self"
                                          className="custom-p-dropdown write-ups-location-dd"
                                          filter={ false}
                                          resetFilterOnHide={true}
                                      />
							    </div>
			         	    </Col>
			         	    <Col md={4}>
							    <div className="date-filter-section">
								    <div className="table-header">
								    	<div className="ms-TextField-wrapper">
								    		<label className="lbl-text">Log Date</label>
								    		<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
								    			<Calendar
								    				id="fromLogDate"
								    				style={{ width: "100%" }}
								    				value={logDateFromFilter}
								    				showIcon
								    				onChange={(e) => handleFromLogDateChange(e)}
								    				monthNavigator
								    				yearNavigator
								    				yearRange={yearRange}
								    				maxDate={logDateToFilter}
								    			/>
								    		</div>
								    	</div>
								    </div>
								    <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								    <div className="table-header">
								    	<div className="ms-TextField-wrapper">
								    		<label className="lbl-text">&nbsp;</label>
								    		<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
								    			<Calendar
								    				id="toLogDate"
								    				style={{ width: "100%" }}
								    				value={logDateToFilter}
								    				showIcon
								    				onChange={(e) => handleToLogDateChange(e)}
								    				monthNavigator
								    				yearNavigator
								    				yearRange={yearRange}
								    				minDate={logDateFromFilter}
								    			/>
								    		</div>
								    	</div>
								    </div>
							    </div>
						    </Col>
                            <Col  md={1} >
						        <div className="table-header employeeeSearchLabelText" style={{marginTop:"25px"}}>
			                        <PrimaryButton
							              onClick={handleResetWorkbook}
							           >
                                        Reset
                                    </PrimaryButton>
						        </div>
			         	    </Col>
			         </Row>
				    </Col>
		            <Col>
                        
				    </Col>
                </Row>
            </div>
            <div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);

    const logDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log Date</span>
                {moment(new Date(rowData.logDate)).local().format('MM/DD/YY')}

            </React.Fragment>
        );
    };


    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}

            </React.Fragment>
        );
    };

    const financeFirstBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance 1</span>
                {rowData.empName}

            </React.Fragment>
        );
    };

    const finnaceSecondBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance 2</span>
                {rowData.financeMgrNameSecond}

            </React.Fragment>
        );
    };

    const productBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product</span>
                {rowData.product}

            </React.Fragment>
        );
    };

    const firstNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">First Name</span>
                {rowData.firstName}

            </React.Fragment>
        );
    };

    const lastNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Last Name</span>
                {rowData.lastName}

            </React.Fragment>
        );
    };

    const emailAddressBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email Address</span>
                {rowData.customerEmail}

            </React.Fragment>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Phone</span>
                {rowData.customerPhone}

            </React.Fragment>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Price</span>

                {rowData.price}

            </React.Fragment>
        );
    };

    const costBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cost</span>

                {rowData.cost}

            </React.Fragment>
        );
    };    

    const deductibleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deductible</span>

                {rowData.deductable}

            </React.Fragment>
        );
    }; 
    
    const profitBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Profit</span>

                {rowData.profit}

            </React.Fragment>
        );
    }; 

    const termBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Term</span>

                {rowData.term}

            </React.Fragment>
        );
    }; 

    const mileageBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mileage</span>
                {rowData.mileage}

            </React.Fragment>
        );
    }; 

   //const referralBodyTemplate = (rowData) => {
   //    return (
   //        <React.Fragment>
   //            <span className="p-column-title">Referral</span>
   //            {rowData.empName}
   //
   //        </React.Fragment>
   //    );
   //}; 

    const closeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Close Date</span>
                {convertUTCToLocalDateOnlyModified(rowData.closeDate)}

            </React.Fragment>
        );
    }; 

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Actions</span>
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.deal.id}
                        onEdit={(appointmentId) => handleEditAppointment(appointmentId)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditAppointment = (id) => {
        const selectedItem = serviceContractData.find(x => x.id === id);
        if (selectedItem) {
            handleNavigateToAfterSalesProduct(selectedItem.deal.id, id);
        }
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role || isKarlProfile(user.profileType)) {
                handleNavigateToAfterSalesProduct(event.data.deal.id, event.data.id);
                // navigate(`/${event.data.deal.id}/dealtracker`, { tabIndex: 1 });
                //navigate({
                //    pathname: `/${event.data.deal.id}/dealtracker`,
                //    state: {
                //      searchInput: glFilter,
                //      pathfrom: '/service-contract',
                //      tabIndex: 6,
                //      productId:`${event.data.id}`,
                //      workbookName:'Service Contract',
                //      // sortOrder,
                //      // sortField
                //    }
                //});
            //}
        }
    };

    const handleNavigateToAfterSalesProduct = (dealId, id) => {
        const  state = {
            searchInput: glFilter,
            pathfrom: '/service-contract',
            tabIndex: 6,
            productId: `${id}`,
            workbookName: 'Service Contract',
            sortOrder,
            sortField,
            logDateFromFilter,
            logDateToFilter,
            locationFilter
        }
        navigate(`/${dealId}/dealtracker`,{state});
    }

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);
    
    useEffect(() => { 
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
        }

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(new Date(stateVal.logDateToFilter));
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }

        setStatesFlag(true);
    }, [location]);

	const table_columns = {
		"logDate": "Log Date",
		"dealNumber": "Deal #",
		"empName": "Finance 1",
		//"financeMgrNameSecond":"Finance 2",
		"product": "Product",
		"firstName": "First Name",
		"lastName":"Last Name",
		"customerPhone": "Phone #",
		"customerEmail": "Email Address",
		"price": "Price",
		"cost": "Cost",
		"deductable":"Deductible",
		"profit": "Profit",
		"term": "Term",
		"mileage":"Mileage",
		//"empName": "Referral",
		"closeDate": "Close Date",
        "settings": "Actions",
        "hiddenCol":""
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }


    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row className="pageTitleRow">
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">After Sale Products</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <Row>
                     <Col md={12}>
                                <div className="datatable-filter-demo noMaxWidthTable">
                                    <div className="card mouse-hover last-col-hide" >
                                      <CustomTable
                                          column_list={table_columns}
                                          data={serviceContractData}
                                          header={header}
                                          rows={25}
                                          rowsPerPageOptions={[25,50,100]}
                                          style={{width:"100%"}}
                                          sortField={sortField}
                                          sortOrder={sortOrder}
                                          onSort={handleColumnSort}
                                          onValueChange={filteredData => setFilteredData(filteredData)}
				              			customTemplates={{
				              			      "logDate": logDateBodyTemplate,
                                              "dealNumber": dealNumberBodyTemplate,
                                              "empName": financeFirstBodyTemplate,
                                              "financeMgrNameSecond": finnaceSecondBodyTemplate,
				              				  "product": productBodyTemplate,
                                              "firstName":firstNameBodyTemplate,
                                              "lastName": lastNameBodyTemplate,
                                              "customerPhone": phoneNumberBodyTemplate,
				              				  "customerEmail":emailAddressBodyTemplate,
                                              "price": priceBodyTemplate,
                                              "cost": costBodyTemplate,
                                              "deductable": deductibleBodyTemplate,
				              				  "profit": profitBodyTemplate,
				              				  "term": termBodyTemplate,
                                              "mileage": mileageBodyTemplate,
                                              //"empName": referralBodyTemplate,
                                              "closeDate": closeDateBodyTemplate,
				              				  "settings": settingBodyTemplate,
				              			}}
                                          emptyMessage="No service contract found."
                                          onRowClick={(event) => handleRowClick(event)}
                                          globalFilter={glFilter} />
                                  </div>
                              </div>
                     </Col>
                </Row>

 
            </Stack>
        </> : null
    );
};
