import { ITemplate, IWebsite } from "types/websiteTypes";

/** GET_TEMPLATES */
export const HANDLE_GET_TEMPLATES = "HANDLE_GET_TEMPLATES";

export interface HandleGetTemplates {
  type: typeof HANDLE_GET_TEMPLATES;
}

export const GET_TEMPLATES_PENDING = "GET_TEMPLATES_PENDING";

export interface GetTemplatesPending {
  type: typeof GET_TEMPLATES_PENDING;
}

export const GET_TEMPLATES_RESOLVED = "GET_TEMPLATES_RESOLVED";

export interface GetTemplatesResolved {
  type: typeof GET_TEMPLATES_RESOLVED;
  payload: ITemplate[];
}

export const GET_TEMPLATES_REJECTED = "GET_TEMPLATES_REJECTED";

export interface GetTemplatesRejected {
  type: typeof GET_TEMPLATES_REJECTED;
  payload: object;
}

/** GET_WEBSITE */
export const HANDLE_GET_WEBSITE = "HANDLE_GET_WEBSITE";
export interface HandleGetWebsite {
  type: typeof HANDLE_GET_WEBSITE;
}

export const GET_WEBSITE_PENDING = "GET_WEBSITE_PENDING";
export interface GetWebsitePending {
  type: typeof GET_WEBSITE_PENDING;
}

export const GET_WEBSITE_RESOLVED = "GET_WEBSITE_RESOLVED";
export interface GetWebsiteResolved {
  type: typeof GET_WEBSITE_RESOLVED;
  payload: IWebsite;
}

export const GET_WEBSITE_REJECTED = "GET_WEBSITE_REJECTED";
export interface GetWebsiteRejected {
  type: typeof GET_WEBSITE_REJECTED;
  payload: { code: number, message: string};
}

/** UPDATE_WEBSITE */
export const HANDLE_UPDATE_WEBSITE = "HANDLE_UPDATE_WEBSITE";
export interface HandleUpdateWebsite {
  type: typeof HANDLE_UPDATE_WEBSITE;
  payload: { website: IWebsite; callback: () => void };
}

export const UPDATE_WEBSITE_PENDING = "UPDATE_WEBSITE_PENDING";
export interface UpdateWebsitePending {
  type: typeof UPDATE_WEBSITE_PENDING;
}

export const UPDATE_WEBSITE_RESOLVED = "UPDATE_WEBSITE_RESOLVED";
export interface UpdateWebsiteResolved {
  type: typeof UPDATE_WEBSITE_RESOLVED;
  payload: IWebsite;
}

export const UPDATE_WEBSITE_REJECTED = "UPDATE_WEBSITE_REJECTED";
export interface UpdateWebsiteRejected {
  type: typeof UPDATE_WEBSITE_REJECTED;
  payload: { code: number, message: string} ;
}

export const CLEAR_WEBSITE = "CLEAR_WEBSITE";
export interface ClearWebsite {
  type: typeof CLEAR_WEBSITE;
}

export type WebsiteActions =
  | HandleGetTemplates
  | GetTemplatesPending
  | GetTemplatesRejected
  | GetTemplatesResolved
  | HandleGetWebsite
  | GetWebsitePending
  | GetWebsiteRejected
  | GetWebsiteResolved
  | HandleUpdateWebsite
  | UpdateWebsitePending
  | UpdateWebsiteRejected
  | UpdateWebsiteResolved
  | ClearWebsite;
