import React, { useEffect} from "react";
import {Stack, IStackStyles, PrimaryButton} from "@fluentui/react";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { useNavigate  } from "react-router-dom";
import { AppState } from "store";
import { useSelector } from "react-redux";

const styles: IStackStyles = {
    root: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        padding: 50,
        paddingLeft: 80,
        paddingRight: 80,
        borderRadius: 2,
        width: 400,
        minHeight: 200,
        maxHeight: 280,
        zIndex: 1,
    },
};

const LoggedOutPage: React.FC = () => {
    const navigate = useNavigate ();
    const logoutMessage = useSelector(
        (state: AppState) => (state.auth as any).logoutMessage
    );
    useEffect(() => {
        if (!logoutMessage) {
            navigate("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <AuthLayoutPage>
            <Stack styles={styles} tokens={{ childrenGap: 30 }} verticalAlign="space-between">
                {/*<CustomFontText size={30}>Logged out</CustomFontText>*/}
                <CustomFontText size={20}>
                    {logoutMessage ? logoutMessage:"You have successfully logged out"}
                </CustomFontText>
                
                <Stack tokens={{ childrenGap: 1 }}>
                    <PrimaryButton
                        text="Back to login"
                        onClick={() => navigate("/login")}
                    />
                </Stack>
                {/*<Stack tokens={{childrenGap: 1}}>
                    <PrimaryButton
                        text="Back to login"
                        onClick={() => navigate("/login")}
                    />
                    <DefaultButton text="Register"  onClick={() => navigate("/register")} />
                </Stack>
                <Link onClick={() => navigate("/reset-password")}>Forgot username/password?</Link>*/}
            </Stack>
        </AuthLayoutPage>
    );
};

export default LoggedOutPage;
