import React, { FunctionComponent } from "react";
import { ETypes } from "types/groupPermissionTypes";
import { isEmployeePermitted, isPermitted } from "utils/permissions/permissionsHelpers";
import { permissionsModules } from "../constants/constants";

export const eTypes = ETypes;
interface IProps {
  groupAuths: any; //userMasterGroupAuths filtered array
  type: ETypes;
  alias: keyof typeof permissionsModules;
  roles: string[];
  children?:any;
}

interface IEmpProps {
  permissionList: any;
  alias: any; // keyof typeof emplyeePermissionsAction;
  roles: string[];
  children?:any;
}
export const WithCredentials: FunctionComponent<IProps> = (props) => {
  const { children, groupAuths, alias, type, roles } = props;
  return <>{isPermitted(groupAuths, type, alias, roles) ? children : null}</>;
};

export const EmployeeActionPermission: FunctionComponent<IEmpProps> = (props) => {
  const { children, permissionList, alias, roles } = props;
  return <>{isEmployeePermitted(permissionList, alias, roles) ? children : null}</>;
};