import React, { useEffect, useState } from "react";
import {
  Modal,
  IconButton,
  PrimaryButton,
  Button,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { ViewAdditionalActionsDropdown } from "components/table/dropdowns/ViewAdditionalActionsDropdown";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { colors } from "constants/constants";
import { EmployeeTable } from "components/dialogs/assignedToDialog/employeeTable";

/** ===============MOCK!!*/
// import { employees } from "./assignedEmployeesMock";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { getModulePermissions } from "utils/permissions/permissionsHelpers";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
// import { IEmployeesProspect } from "types/employeesTypes";
import { handleGetEmployeesAssignedProspect } from "store/employees/employees.action";
import {
  handleProspectAssignEmployee,
  handleProspectUnassignEmployee,
} from "store/prospects/prospects.action";
import { IPagination } from "types/paginationTypes";
const contentStyles = content();

interface IProps {
  groupName?: string;
  prospectId?: number;
  onDismiss: () => void;
  // employees: IEmployeesProspect;
}
const pagination = {
  limit: 15,
  page: 1,
  sort: "ASC",
  column: "employee.firstName",
};

export const AssignedToDialog: React.FunctionComponent<IProps> = ({
  groupName,
  prospectId,
  onDismiss,
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const [assignedPagination, setAssignedPagination] = useState<IPagination>(
    pagination
  );
  const [unassignedPagination, setUnassignedPagination] = useState<IPagination>(
    pagination
  );

  const employees = useSelector(
    (state: AppState) => state.employees.employeesProspect
  );
  // const loading = useSelector((state: AppState) => state.employees.loading);
  const assigningLoading = useSelector(
    (state: AppState) => state.prospects.loading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (prospectId) {
      dispatch<any>(
        handleGetEmployeesAssignedProspect(
          prospectId,
          assignedPagination,
          unassignedPagination
        )
      );
      setAssignedPagination(pagination);
      setUnassignedPagination(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectId]);

  ///// @FIXME: OR!!!!!!!!!!!!!!!!!   whole Pagination object???

  const refreshAssigned = (newPag: IPagination) => {
    setAssignedPagination({ ...newPag });
    dispatch<any>(
      handleGetEmployeesAssignedProspect(
        prospectId,
        { ...newPag },
        unassignedPagination
      )
    );
  };
  const refreshUnassigned = (newPag: IPagination) => {
    setUnassignedPagination({ ...newPag });
    dispatch<any>(
      handleGetEmployeesAssignedProspect(prospectId, assignedPagination, {
        ...newPag,
      })
    );
  };

  const handleAssignedChangePage = (page: number) => {
    refreshAssigned({
      ...assignedPagination,
      page,
    });
  };
  const handleUnassignedChangePage = (page: number) => {
    refreshUnassigned({
      ...unassignedPagination,
      page,
    });
  };
  const handleAssignedChangeLimit = (limit: number) => {
    refreshAssigned({
      ...assignedPagination,
      limit,
    });
  };
  const handleUnassignedChangeLimit = (limit: number) => {
    refreshUnassigned({
      ...unassignedPagination,
      limit,
    });
  };

  const handleUnassignedChangeSort = ({
    column,
    sort,
  }: {
    column: string;
    sort: string;
  }) => {
    refreshUnassigned({
      ...unassignedPagination,
      column,
      sort,
    });
  };

  const handleAssignedChangeSort = ({
    column,
    sort,
  }: {
    column: string;
    sort: string;
  }) => {
    refreshAssigned({
      ...assignedPagination,
      column,
      sort,
    });
  };

  const assignEmployee = (employeeId: number) => {
    dispatch<any>(
      handleProspectAssignEmployee({
        prospectId,
        employeeId,
        callback: () => {
          dispatch<any>(
            handleGetEmployeesAssignedProspect(
              prospectId,
              assignedPagination,
              unassignedPagination
            )
          );
        },
      })
    );
  };
  const unAssignEmployee = (employeeId: number) => {
    dispatch<any>(
      handleProspectUnassignEmployee({
        prospectId,
        employeeId,
        callback: () => {
          dispatch<any>(
            handleGetEmployeesAssignedProspect(
              prospectId,
              assignedPagination,
              unassignedPagination
            )
          );
        },
      })
    );
  };

  return (
    <Modal
      className="configureGroup"
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <GoBackToMainDialogBtn label={"Assigned To"} onClick={onDismiss} />

        {/*<CustomFontText>Assigned To</CustomFontText>*/}
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>

      <div className={contentStyles.body}>
        <div style={{ paddingTop: 20 }}>
          <Pivot style={{ padding: 0, margin: 0 }} onLinkClick={() => {}}>
            <PivotItem
              className="pivotItem"
              style={border}
              headerText="Assign to Employees"
              headerButtonProps={{
                "data-order": 2,
                "data-title": "Company information",
              }}
            >
              <EmployeeTable
                employees={
                  employees.unassigned && employees.unassigned.results
                    ? employees.unassigned.results
                    : []
                }
                // selection={this._selection}
                onLimitChange={handleUnassignedChangeLimit}
                onPageChange={handleUnassignedChangePage}
                type="Assign"
                onAction={(employeeId) => assignEmployee(employeeId)}
                isLoading={assigningLoading}
                totalItems={
                  employees.unassigned &&
                  employees.unassigned.totalItems &&
                  employees.unassigned.totalItems
                }
                pagination={unassignedPagination}
                onSort={handleUnassignedChangeSort}
              />
            </PivotItem>
            <PivotItem
              className="pivotItem"
              style={border}
              headerText={`Assigned Employees (${
                employees.assigned && employees.assigned.totalItems
              })`}
            >
              <EmployeeTable
                employees={
                  employees.assigned && employees.assigned.results
                    ? employees.assigned.results
                    : []
                }
                onLimitChange={handleAssignedChangeLimit}
                onPageChange={handleAssignedChangePage}
                type="Unassign"
                onAction={(employeeId) => unAssignEmployee(employeeId)}
                isLoading={assigningLoading}
                totalItems={
                  employees.assigned &&
                  employees.assigned.totalItems &&
                  employees.assigned.totalItems
                }
                pagination={assignedPagination}
                onSort={handleAssignedChangeSort}
              />
            </PivotItem>
          </Pivot>
        </div>
      </div>

      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div>
            <ViewAdditionalActionsDropdown
              modulePermissions={getModulePermissions(
                user.auths || [],
                "Inventory"
              )}
            />
          </div>
          <div>
            <Button
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />
            <PrimaryButton text="Save Changes" onClick={() => onDismiss()} />
          </div>
        </Stack>
      </div>
    </Modal>
  );
};

const border = {
  borderTop: `1px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};
