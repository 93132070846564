import { IGroup } from "types/groupTypes";
import * as actions from "./groups.types";
import { IFormError } from "types/errorTypes";

// GET_GROUPS
export const handleGetGroups = (): actions.HandleGetGroups => {
  return {
    type: actions.HANDLE_GET_GROUPS,
  };
};

export const pendingGetGroups = (): actions.GetGroupsPending => {
  return {
    type: actions.GET_GROUPS_PENDING,
  };
};

export const resolvedGetGroups = (
  groups: IGroup[]
): actions.GetGroupResolved => {
  return {
    type: actions.GET_GROUPS_RESOLVED,
    payload: groups,
  };
};

export const rejectedGetGroups = (
  errors: IFormError[]
): actions.GetGroupRejected => {
  return {
    type: actions.GET_GROUPS_REJECTED,
    payload: errors,
  };
};

// VIEW_GROUP
export const handleViewGroup = (id): actions.HandleViewGroup => {
  return {
    type: actions.HANDLE_VIEW_GROUP,
    payload: id,
  };
};

export const pendingViewGroup = (): actions.ViewGroupPending => {
  return {
    type: actions.VIEW_GROUP_PENDING,
  };
};

export const resolvedViewGroup = (group: IGroup): actions.ViewGroupResolved => {
  return {
    type: actions.VIEW_GROUP_RESOLVED,
    payload: group,
  };
};

export const rejectedViewGroup = (
  errors: IFormError[]
): actions.ViewGroupRejected => {
  return {
    type: actions.VIEW_GROUP_REJECTED,
    payload: errors,
  };
};


// UPDATE_GROUP
export const handleUpdateGroup = (id, name, extra?: any): actions.HandleUpdateGroup => {
  return {
    type: actions.HANDLE_UPDATE_GROUP,
    payload: {id,name, extra},
  };
};

export const pendingUpdateGroup = (): actions.UpdateGroupPending => {
  return {
    type: actions.UPDATE_GROUP_PENDING,
  };
};

export const resolvedUpdateGroup = (group: IGroup): actions.UpdateGroupResolved => {
  return {
    type: actions.UPDATE_GROUP_RESOLVED,
    payload: group,
  };
};

export const rejectedUpdateGroup = (
    errors: IFormError[]
): actions.UpdateGroupRejected => {
  return {
    type: actions.UPDATE_GROUP_REJECTED,
    payload: errors,
  };
};

// CREATE_GROUP
export const createGroupHandler = (
  name: string,
  callback: Function
): actions.HandleCreateGroup => {
  return {
    type: actions.HANDLE_CREATE_GROUP,
    payload: { name, callback },
  };
};

export const pendingCreateGroup = (): actions.createGroupPending => {
  return {
    type: actions.CREATE_GROUP_PENDING,
  };
};

export const resolvedCreateGroup = (group): actions.createGroupResolved => {
  return {
    type: actions.CREATE_GROUP_RESOLVED,
    payload: group,
  };
};

export const rejectedCreateGroup = (
  errors: IFormError[]
): actions.createGroupRejected => {
  return {
    type: actions.CREATE_GROUP_REJECTED,
    payload: errors,
  };
};

// ADD_EMPLOYEE_TO_GROUP
export const handleAddEmployeeToGroup = (
  employeeIds: number[],
  groupId: number,
  callback?: Function
): actions.HandleAddEmployeeToGroup => {
  return {
    type: actions.HANDLE_ADD_EMPLOYEE_TO_GROUP,
    payload: { employeeIds, groupId, callback },
  };
};

export const pendingAddEmployeeToGroup = (): actions.AddEmployeeToGroupPending => {
  return {
    type: actions.ADD_EMPLOYEE_TO_GROUP_PENDING,
  };
};

export const resolvedAddEmployeeToGroup = (): actions.AddEmployeeToGroupResolved => {
  return {
    type: actions.ADD_EMPLOYEE_TO_GROUP_RESOLVED,
  };
};

export const rejectedAddEmployeeToGroup = (
  errors: IFormError[]
): actions.AddEmployeeToGroupRejected => {
  return {
    type: actions.ADD_EMPLOYEE_TO_GROUP_REJECTED,
    payload: errors,
  };
};

// REMOVE_EMPLOYEE_FROM_GROUP
export const handleRemoveEmployeeFromGroup = (
  employeeIds: number[],
  groupId: number,
  callback?: Function
): actions.HandleRemoveEmployeeFromGroup => {
  return {
    type: actions.HANDLE_REMOVE_EMPLOYEE_FROM_GROUP,
    payload: { employeeIds, groupId, callback },
  };
};

export const pendingRemoveEmployeeFromGroup = (): actions.RemoveEmployeeFromGroupPending => {
  return {
    type: actions.REMOVE_EMPLOYEE_FROM_GROUP_PENDING,
  };
};

export const resolvedRemoveEmployeeFromGroup = (): actions.RemoveEmployeeFromGroupResolved => {
  return {
    type: actions.REMOVE_EMPLOYEE_FROM_GROUP_RESOLVED,
  };
};

export const rejectedRemoveEmployeeFromGroup = (
  errors: IFormError[]
): actions.RemoveEmployeeFromGroupRejected => {
  return {
    type: actions.REMOVE_EMPLOYEE_FROM_GROUP_REJECTED,
    payload: errors,
  };
};

//SET_ROWS_PER_PAGE
export const setRowsPerPage = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE,
    payload: rows,
  };
};
export const setRowsPerPageAddGroupEmployee = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE_ADD_GROUP_EMPLOYEES,
    payload: rows,
  };
};
export const setRowsPerPageEditGroupEmployee = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE_EDIT_GROUP_EMPLOYEES,
    payload: rows,
  };
};


export const createGroupAndAssignEmployees = (name: any, selectedEmployees: any[], callback: () => void) => {
  return {
    type: actions.CREATE_GROUP_AND_ASSIGN_EMPLOYEES,
    payload: {name, selectedEmployees, callback}
  }
}

//DELETE_GROUP
export const handleDeleteGroup = (id: string, callback: () => void): actions.HandleDeleteGroup => {
  return {
    type: actions.HANDLE_DELETE_GROUP,
    payload: {id, callback},
  };
};

export const pendingDeleteGroup  = (): actions.DeleteGroupPending => {
  return {
    type: actions.DELETE_GROUP_PENDING,
  };
};

export const resolvedDeleteGroup  = (): actions.DeleteGroupResolved => {
  return {
    type: actions.DELETE_GROUP_RESOLVED,
  };
};

export const rejectedDeleteGroup  = (
    errors: IFormError[]
): actions.DeleteGroupRejected => {
  return {
    type: actions.DELETE_GROUP_REJECTED,
    payload: errors,
  };
};
