import React, { useEffect, useState, FunctionComponent } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  IconButton,
  Stack,
  Toggle
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";

const contentStyles = content(600, "auto");

interface IProps {
  adjusterList: any;
  onDismiss: () => void;
  handleUpdate: (customAdjusterList) => void;
  isFirstTime?: boolean;
  /* vin: any;
  trimValues: any[];
  ymm: any;
  vehID: any; */
}


export const AddCustomizedAdjustmentsDialog: FunctionComponent<IProps> = ({
  adjusterList,
  onDismiss,
  handleUpdate,
  isFirstTime
}) => {
  const [customAdjusterList, setcustomAdjusterList] = useState([]);

  useEffect(() => {
      setcustomAdjusterList(adjusterList);
  }, [adjusterList]);

  const onAdjustChange = (modItem) => {
    const values = customAdjusterList.map((item) => {
      if (item.key === modItem.key) {
        return { key: modItem.key, text: modItem.text, checked: !modItem.checked };
      }
      return item;
    });
    setcustomAdjusterList(values)
  };

    const handleSkipForNow = () => {
        const values = customAdjusterList.map((item) => {
            item.checked = false;
            return item;
        });
        handleUpdate(values);
    };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container+" customAdjusterDialogContainer"}
    >
      <div className={contentStyles.header+" inventory-popup customAdjuster"}>
		  {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
        <CustomFontText>Edit Options</CustomFontText>

        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack
          styles={stackStyles}
          verticalAlign="start"
          horizontalAlign="space-between"
        >
                  {
                      isFirstTime && (<PrimaryButton
                          text="Skip for Now"
                          className="updateAdjBtn"
                          style={{
                              width: 200,
                              textAlign: 'center',
                              margin: 'auto',
                              marginBottom: 20
                          }}
                          onClick={() => handleSkipForNow()}
                      // onRenderIcon={renderSpinner}
                      />)
                  }

          {customAdjusterList ? customAdjusterList.map((item, index) => (
            <div key={"boxes-" + index}>
              <Row className="adjustPopupContent">
                  <Col sm={6} xs={6} className="labelAdjustment">
                    <label>{item.text}</label>
                  </Col>
                  {/* <Col sm={3}>
                    <label>{item.amt}</label>
                  </Col> */}
                  <Col sm={3} xs={6}>
                    <Toggle
                        label=""
                        inlineLabel
                        //onText="active"
                        //offText="inactive"
                        onChange={() => onAdjustChange(item)}
                        checked={item.checked}
                        //checked={formik.values.status}
                        />
                  </Col>
              </Row>
            </div>
          )) : null}
          
            <PrimaryButton
                //text="Update Options"
                text="Save"
                className="updateAdjBtn"
                style={{ float: "right", width: 200,
                textAlign: 'center',
                margin: '0 auto',
                marginTop: 40,
                marginBottom: 40 }}
                onClick={() => handleUpdate(customAdjusterList)}
                // onRenderIcon={renderSpinner}
            />
          
        </Stack>

      </div>
    </Modal>
  );
};


const stackStyles: IStackStyles = {
  root: {},
};
