import React from "react";
import { Col, Container, Row } from "react-grid-system";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import { TextFieldWithLabelAndInfoIcon } from "components/textFieldWithLabelAndInfoIcon/TextFieldWithLabelAndInfoIcon";
import { styles } from "pages/dealSettingsPage/styles";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";

interface IProps {
  formik: any;
}

const GlobalTaxes: React.FC<IProps> = ({ formik }) => {
  return (
    <Container style={styles.container} fluid>
      <Row gutterWidth={75}>
        <Col sm={12} md={12} xl={6}>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              prefix="%"
              id="stateTax"
              name="stateTax"
              label="State Tax"
              value={formik.values.stateTax}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.stateTax &&
                formik.errors.stateTax &&
                formik.errors.stateTax
              }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              prefix="%"
              id="countryTax"
              name="countryTax"
              label="Country Tax"
              value={formik.values.countryTax}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.countryTax &&
                formik.errors.countryTax &&
                formik.errors.countryTax.toString()
              }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              prefix="%"
              id="contractStateTax"
              name="contractStateTax"
              label="Service Contract State Tax"
              value={formik.values.contractStateTax}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.contractStateTax &&
                formik.errors.contractStateTax &&
                formik.errors.contractStateTax.toString()
              }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <TextFieldWithLabelAndInfoIcon
              prefix="%"
              id="contractLocalTax"
              name="contractLocalTax"
              label="Service Contract Local Tax"
              value={formik.values.contractLocalTax}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.contractLocalTax &&
                formik.errors.contractLocalTax &&
                formik.errors.contractLocalTax.toString()
              }
            />
          </WithOptionalLabel>
        </Col>

        <Col sm={12} md={12} xl={6}>
          <WithOptionalLabel>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="stateTaxMax"*/}
            {/*  name="stateTaxMax"*/}
            {/*  label="State Tax Max Tax Per Sale"*/}
            {/*  value={formik.values.stateTaxMax}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.stateTaxMax &&*/}
            {/*    formik.errors.stateTaxMax &&*/}
            {/*    formik.errors.stateTaxMax.toString()*/}
            {/*  }*/}
            {/*/>*/}
            <label className="lbl-text ">State Tax Max Tax Per Sale</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="stateTaxMax"
              name="stateTaxMax"
              // label="State Tax Max Tax Per Sale"
              value={formik.values.stateTaxMax?formik.values.stateTaxMax.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "stateTaxMax": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.stateTaxMax &&
                formik.errors.stateTaxMax &&
                formik.errors.stateTaxMax.toString()}
            </span>
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text ">Country Tax Max Tax Per Sale</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="countryTaxMax"
              name="countryTaxMax"
              //label="Country Tax Max Tax Per Sale"
              value={formik.values.countryTaxMax?formik.values.countryTaxMax.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "countryTaxMax": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.countryTaxMax &&
                formik.errors.countryTaxMax &&
                formik.errors.countryTaxMax.toString()}
            </span>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="countryTaxMax"*/}
            {/*  name="countryTaxMax"*/}
            {/*  label="Country Tax Max Tax Per Sale"*/}
            {/*  value={formik.values.countryTaxMax}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.countryTaxMax &&*/}
            {/*    formik.errors.countryTaxMax &&*/}
            {/*    formik.errors.countryTaxMax.toString()*/}
            {/*  }*/}
            {/*/>*/}
          </WithOptionalLabel>

          <WithOptionalLabel>
            <label className="lbl-text ">Service Contract State Max Per Sale</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="contractStateTaxMax"
              name="contractStateTaxMax"
              //label="Service Contract State Max Per Sale"
              value={formik.values.contractStateTaxMax?formik.values.contractStateTaxMax.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "contractStateTaxMax": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.contractStateTaxMax &&
                formik.errors.contractStateTaxMax &&
                formik.errors.contractStateTaxMax.toString()}
            </span>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="contractStateTaxMax"*/}
            {/*  name="contractStateTaxMax"*/}
            {/*  label="Service Contract State Max Per Sale"*/}
            {/*  value={formik.values.contractStateTaxMax}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.contractStateTaxMax &&*/}
            {/*    formik.errors.contractStateTaxMax &&*/}
            {/*    formik.errors.contractStateTaxMax.toString()*/}
            {/*  }*/}
            {/*/>*/}
          </WithOptionalLabel>

          <WithOptionalLabel>
            <label className="lbl-text ">Service Contract Local Max Tax Per Sale</label>
            <CurrencyInput
              // thousandSeparator={true}
              customInput={TextFieldWithLabelAndInfoIcon}
              prefix={PRICE_PREFIX}
              id="contractLocalTaxMax"
              name="contractLocalTaxMax"
              //label="Service Contract Local Max Tax Per Sale"
              value={formik.values.contractLocalTaxMax?formik.values.contractLocalTaxMax.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "contractLocalTaxMax": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
            />
            <span className="error-message">
              {formik.touched.contractLocalTaxMax &&
                formik.errors.contractLocalTaxMax &&
                formik.errors.contractLocalTaxMax.toString()}
            </span>
            {/*<TextFieldWithLabelAndInfoIcon*/}
            {/*  prefix="$"*/}
            {/*  id="contractLocalTaxMax"*/}
            {/*  name="contractLocalTaxMax"*/}
            {/*  label="Service Contract Local Max Tax Per Sale"*/}
            {/*  value={formik.values.contractLocalTaxMax}*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*  errorMessage={*/}
            {/*    formik.touched.contractLocalTaxMax &&*/}
            {/*    formik.errors.contractLocalTaxMax &&*/}
            {/*    formik.errors.contractLocalTaxMax.toString()*/}
            {/*  }*/}
            {/*/>*/}
          </WithOptionalLabel>
        </Col>
      </Row>
    </Container>
  );
};

export default GlobalTaxes;
