import { put, takeLeading, select, takeEvery } from "redux-saga/effects";
import { AppState } from "store";

import {
    pendingGetBrands,
    resolvedGetBrands,
    rejectedGetBrands,
    pendingCreateBrand,
   // resolvedCreateBrand,
    rejectedCreateBrand,
    pendingViewBrand,
    resolvedViewBrand,
    pendingDeleteBrand,
    resolvedDeleteBrand,
    rejectedDeleteBrand,
    handleGetBrands,
    pendingUpdateBrand,
    setSortBrands,
    rejectedUpdateBrand,
    resolvedGetBrandsForDropdown,
    pendingGetgetBrandsForDropdown
} from "store/brands/brands.action";

import {
    getBrands,
    createBrands,
    viewBrand,
    getBrandsForDropdown,
    deleteBrand,
    updateBrand,
    addBrandLogo,
} from "store/brands/brands.api";

import {
    HANDLE_CREATE_BRAND,
    HANDLE_GET_BRANDS,
    HandleCreateBrand,
    HANDLE_VIEW_BRAND,
    HandleViewBrand,
    HANDLE_DELETE_BRAND,
    HANDLE_UPDATE_BRAND,
    HandleUpdateBrand,
    HandleDeleteBrand,
    HandleGetBrandsForDropdown,
    GET_BRANDS_DROPDOWN,
} from "store/brands/brands.types";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

function* getBrandsSaga(action: any) {
    yield put(switchGlobalLoader(true));
    const { page, limit, sort, column } = action.payload;

    const rowsPerPage = yield select(
        (state: AppState) => state.brands.rowsPerPage
    );
    const sortStore = yield select((state: AppState) => state.brands.sort);
    const sortColumn = yield select((state: AppState) => state.brands.column);

    yield put(setSortBrands(sort ? sort : sortStore));

    yield put(pendingGetBrands());
    try {
        const { data } = yield getBrands({
            page,
            limit: limit ? limit : rowsPerPage,
            sort: sort ? sort : sortStore,
            column: column ? column : sortColumn,
        });
        // console.log(data)
        yield put(resolvedGetBrands(data));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetBrands(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* createBrandSaga(action: HandleCreateBrand) {
    yield put(switchGlobalLoader(true));
    yield put(pendingCreateBrand());
    try {
        const { brand, callback } = action.payload;
        const { data, error } = yield createBrands(brand);
        if (error) {
            yield put(rejectedCreateBrand(error));
            yield put(switchGlobalLoader(false));
            return;
        }
        const brandPhoto: any = action.payload.brand.img;
        if (brandPhoto && typeof brandPhoto.name == 'string') {
            const formData = new FormData();
            formData.append("logo", brandPhoto, (brandPhoto as any).name);
            const { error } = yield addBrandLogo(data.id, formData);
            if (error) {
                yield put(rejectedCreateBrand(error));
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        //yield put(resolvedCreateBrand(data));

        //yield call(action.payload.callback);

        typeof callback === "function" && callback()
        const rowsPerPage = yield select(
            (state: AppState) => state.brands.rowsPerPage
        );
        const page = yield select(
            (state: any) => state.brands.brands.currentPage
        );

        const request = { page: page, limit: rowsPerPage };
        yield put(handleGetBrands(request));

        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        yield put(rejectedCreateBrand(error.violations as any));
        return;
    }
}

function* viewBrandSaga(action: HandleViewBrand) {
    yield put(switchGlobalLoader(true));

    yield put(pendingViewBrand());
    try {
        const { data } = yield viewBrand(action.payload);

        yield put(resolvedViewBrand(data));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getBrandForDropDownSaga(action: HandleGetBrandsForDropdown) {
    yield put(switchGlobalLoader(true));

    yield put(pendingGetgetBrandsForDropdown());
    try {
        const { data } = yield getBrandsForDropdown();

        yield put(resolvedGetBrandsForDropdown(data));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* deleteBrandSaga(action: HandleDeleteBrand) {
    yield put(switchGlobalLoader(true));
    const { id, callback } = action.payload;
    
    yield put(pendingDeleteBrand());
    try {
        const { error } = yield deleteBrand(id);
        if (error) {
            yield put(rejectedDeleteBrand(error.message));
            yield put(switchGlobalLoader(false));
            typeof callback === "function" && callback(error.code === 400 ?error.message:'Something went wrong')
            return;
        }

        yield put(resolvedDeleteBrand());
        typeof callback === "function" && callback()
        const rowsPerPage = yield select(
            (state: AppState) => state.brands.rowsPerPage
        );
        const page = yield select(
            (state: any) => state.brands.brands.currentPage
        );
        const request = { page: page, limit: rowsPerPage };
        yield put(handleGetBrands(request));
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        yield put(rejectedDeleteBrand(error));
        return;
    }
    
}

function* updateBrandSaga(action: HandleUpdateBrand) {
    yield put(pendingUpdateBrand());
    yield put(switchGlobalLoader(true));
    const { brand, callback } = action.payload;

    const { error } = yield updateBrand(brand.id, brand);
    if (error) {
        yield put(rejectedUpdateBrand(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }
    const brandPhoto: any = action.payload.brand.img;
    if (brandPhoto && typeof brandPhoto.name == 'string') {
        const formData = new FormData();
        formData.append("logo", brandPhoto, (brandPhoto as any).name);
        const { error } = yield addBrandLogo(brand.id, formData);
        if (error) {
            yield put(rejectedUpdateBrand(error));
            yield put(switchGlobalLoader(false));
            return;
        }
    }
    
    typeof callback === "function" && callback()
    const rowsPerPage = yield select(
        (state: AppState) => state.brands.rowsPerPage
    );
    const page = yield select(
        (state: any) => state.brands.brands.currentPage
    );

    const request = { page: page, limit: rowsPerPage };
    yield put(handleGetBrands(request));

    yield put(switchGlobalLoader(false));
}

export default function* BrandsSaga() {
    yield takeLeading(HANDLE_GET_BRANDS, getBrandsSaga);
    yield takeLeading(HANDLE_CREATE_BRAND, createBrandSaga);
    yield takeLeading(GET_BRANDS_DROPDOWN,getBrandForDropDownSaga)
    yield takeLeading(HANDLE_VIEW_BRAND, viewBrandSaga);
    yield takeEvery(HANDLE_DELETE_BRAND, deleteBrandSaga);
    yield takeEvery(HANDLE_UPDATE_BRAND, updateBrandSaga);
}
