import * as actions from './globalLoading.types';

interface IState {
    loading: boolean;
}

const initialState: IState = {
    loading: false
};

export const GlobalLoadingReducer = (
    state: IState = initialState,
    action: actions.GlobalLoadingActions
) => {
    switch (action.type) {
        case actions.SWITCH_GLOBAL_LOADER:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
