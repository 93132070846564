import React, { useEffect, useState, FunctionComponent } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, permissionsModules } from "constants/constants";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { IPermission } from "types/groupPermissionTypes";

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};

interface IProps {
  onDelete?: () => void;
  setStatuses?: (string) => void;
  modulePermissions: IPermission[];
  onEdit: () => void;
}

export const DealMoreActionsSubmenu: FunctionComponent<IProps> = ({
  onDelete,
  setStatuses,
  modulePermissions,
  onEdit,
}) => {
  const [menuProps, setMenuProps] = useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  // const dispatch = useDispatch();

  useEffect(() => {
    const menuItems: IContextualMenuItem[] = [
      // {
      //   key: "view",
      //   name: "View",
      //   iconProps: { iconName: "Car", styles: iconMenuStyles },
      //   onClick: () => {},
      //   style: !isPermitted(
      //     modulePermissions,
      //     eTypes.READ,
      //     permissionsModules.Deals,
      //     user.roles
      //   )
      //     ? hiddenStyle
      //     : null,
      // },
      {
        key: "setStatus", //with submenu
        name: "Set Status",
        iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
        onClick: () => {},
        style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Deals,
          user.roles
        )
          ? hiddenStyle
          : null,
        subMenuProps: {
          items: [
            {
              key: "inactive",
              text: "Inactive",
              onClick: () => setStatuses("inactive"),
            },
            {
              key: "pending",
              text: "pending",
              onClick: () => setStatuses("pending"),
            },
            {
              key: "active",
              text: "active",
              onClick: () => setStatuses("active"),
            },
            {
              key: "dead",
              text: "dead",
              onClick: () => setStatuses("dead"),
            },
          ],
        },
      },

      {
        key: "saveAsPDF", //with submenu
        name: "Save as PDF",
        iconProps: { iconName: "PDF", styles: iconMenuStyles },
        onClick: () => {},
      },
      {
        key: "edit", //with submenu
        name: "Edit",
        iconProps: { iconName: "Edit", styles: iconMenuStyles },
        onClick: () => onEdit(),
        style: !isPermitted(
          modulePermissions,
          eTypes.UPDATE,
          permissionsModules.Deals,
          user.roles
        )
          ? hiddenStyle
          : null,
      },
      {
        key: "delete", //with submenu
        iconProps: { iconName: "Delete", styles: iconMenuStyles },
        name: "Delete",
        style: !isPermitted(
          modulePermissions,
          eTypes.DELETE,
          permissionsModules.Deals,
          user.roles
        )
          ? hiddenStyle
          : null,
        onClick: () => onDelete(),
      },
    ];

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IconButton
      className="iconButton"
      iconProps={{ iconName: "MoreVertical" }}
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
