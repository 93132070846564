import React, { useEffect, useState } from "react";
import {
    Modal,
    IconButton,
    PrimaryButton,
    TextField,
    Button,
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
// import Loader from "components/loader/Loader";

import {
    cancelIcon,
    colourRed,
    contentStyles as content,
    dropdownStyles,
    iconButtonStyles,
} from "constants/styles";

import {
    handleUpdateProspect,
    handleDeleteProspect,
    handleViewProspect,
} from "store/prospects/prospects.action";

import { handleUpdateFavourite } from "store/shared/shared.action";

import { AppState } from "store/index";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { styles } from "pages/accountSettingsPage/styles";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import ListItem from "components/ListItem/ListItem";
import {
    employmentItemList,
    FavouriteAction,
    personalInformationItemList,
    prospectLeadType,
    prospectStatuses,
    salutations,
    tradeItemList,
    vehicleItemList,
} from "constants/constants";
import { Col, Row } from "react-grid-system";
import { PersonalInformationDialog } from "components/dialogs/prospects/secondaryDialogs/PersonalInformationDialog";

import WysiwygEditor from "components/dialogs/addInventoryDialog/WysiwygEditor";
// import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

//import { ViewAdditionalActionsDropdown } from "components/table/inventory/dropdown/ViewAdditionalActionsDropdown";
import { ProspectEditAdditionalActionsDropdown } from "components/dialogs/prospects/dropdown/ProspectEditAdditionalActionsDropdown";
import { EditProspectDealsDialog } from "components/dialogs/prospects/EditProspectDealsDialog";
import { EditProspectSchedulerDialog } from "components/dialogs/prospects/EditProspectSchedulerDialog";
import { AssignedToDialog } from "components/dialogs/assignedToDialog/AssignedToDialog";
import { VehicleWishDialog } from "components/dialogs/vehicleWishDialog/VehicleWishDialog";
import { TradeInDialog } from "components/dialogs/tradeInDialog/TradeInDialog";
import { EmploymentDialog } from "components/dialogs/prospects/secondaryDialogs/EmploymentDialog";
import { getModulePermissions } from "utils/permissions/permissionsHelpers";
import moment from "moment";
import { IProspect } from "types/prospectTypes";
import { IDeal } from "types/dealTypes";
// import { IDates } from "types/taskTypes";
import { groupByDate } from "utils/dateTimeFunctions";
import { IEmployee } from "types/employeesTypes";

const contentStyles = content();

interface IProps {
    onDismiss: () => void;
    prospect: IProspect;
    returnProspect?: (prospect: IProspect) => void;
}

enum FormStep {
    Main,
    Deals,
    Scheduler,
    AssignedTo,
    PersonalInformation,
    Employment,
    VehicleWish,
    TradeIn,
}

export const EditProspectFromCosignersDialog: React.FunctionComponent<IProps> = ({
                                                                        onDismiss,
                                                                        prospect,
                                                                        returnProspect,
                                                                    }) => {
    const dispatch = useDispatch();

    // const selectedProspect = useSelector(
    //   (state: AppState) => state.prospects.selectedProspect
    // );
    const selectedProspect = useSelector(
        (state: AppState) => state.prospects.selectedProspect
    );

    const loading = useSelector((state: AppState) => state.prospects.loading);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const [deals, setDeals] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [assigned, setAssigned] = useState(null);

    const calculateDealStatus = (deals: IDeal[]) => {
        let active = 0;
        let other = 0;

        deals.forEach((deal: any) => {
            deal.status === 'active' ? active++ : other++;
        });
        return { active, other };
    };

    useEffect(() => {
        if (prospect) dispatch<any>(handleViewProspect(prospect.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prospect]);

    useEffect(() => {
        if (selectedProspect) {
            console.log(selectedProspect);
            formik.setValues({
                // ...emptyForm,
                email: selectedProspect?.email ? selectedProspect.email : "",
                status: selectedProspect?.status ? selectedProspect.status : "",
                phone: selectedProspect?.phone ? selectedProspect.phone : "",
                leadType: selectedProspect?.leadType ? selectedProspect.leadType : "",
                salutation: selectedProspect?.salutation
                    ? selectedProspect.salutation
                    : 1,
                firstName: selectedProspect?.firstName
                    ? selectedProspect.firstName
                    : "",
                middleName: selectedProspect?.middleName
                    ? selectedProspect.middleName
                    : "",
                lastName: selectedProspect?.lastName ? selectedProspect.lastName : "",
                id: selectedProspect?.id,
                dateOfBirth: selectedProspect?.dateOfBirth
                    ? selectedProspect.dateOfBirth
                    : null,
                // homePhoneNumber: '6767676767676',
                homePhoneNumber: selectedProspect?.homePhoneNumber
                    ? selectedProspect.homePhoneNumber
                    : "",
                workPhoneNumber: selectedProspect?.workPhoneNumber
                    ? selectedProspect.workPhoneNumber
                    : "",
                gender: selectedProspect?.gender ? selectedProspect.gender : null,
                currentAddress1: selectedProspect?.currentAddress1
                    ? selectedProspect?.currentAddress1
                    : "",
                currentAddress2: selectedProspect?.currentAddress2
                    ? selectedProspect?.currentAddress2
                    : "",
                zip: selectedProspect?.zip ? selectedProspect?.zip : "",
                city: selectedProspect?.city ? selectedProspect?.city : "",
                county: selectedProspect?.county ? selectedProspect?.county : "",
                state: selectedProspect?.state ? selectedProspect?.state : "",
                suffix: selectedProspect?.suffix ? selectedProspect?.suffix : "",
                driverLicense: selectedProspect?.driverLicense
                    ? selectedProspect?.driverLicense
                    : "",
                socialSecurityNumber: selectedProspect?.socialSecurityNumber
                    ? selectedProspect?.socialSecurityNumber
                    : "",
                socialSecurityNumberMasked: selectedProspect?.socialSecurityNumberMasked
                    ? selectedProspect?.socialSecurityNumberMasked
                    : false,
                employerName: selectedProspect?.employerName
                    ? selectedProspect?.employerName
                    : "",
                lenthAtJob: selectedProspect?.lenthAtJob
                    ? selectedProspect?.lenthAtJob
                    : 0,
                monthlyIncome: selectedProspect?.monthlyIncome
                    ? selectedProspect?.monthlyIncome
                    : null,
                employerAddress1: selectedProspect?.employerAddress1
                    ? selectedProspect?.employerAddress1
                    : "",
                employerAddress2: selectedProspect?.employerAddress2
                    ? selectedProspect?.employerAddress2
                    : "",
                employerZip: selectedProspect?.employerZip
                    ? selectedProspect?.employerZip
                    : "",
                employerCity: selectedProspect?.employerCity
                    ? selectedProspect?.employerCity
                    : "",
                employerCounty: selectedProspect?.employerCounty
                    ? selectedProspect?.employerCounty
                    : "",
                employerState: selectedProspect?.employerState
                    ? selectedProspect?.employerState
                    : "",
                vehicleCondition: selectedProspect?.vehicleCondition
                    ? selectedProspect?.vehicleCondition
                    : [],
                vehicleMake: selectedProspect?.vehicleMake
                    ? selectedProspect?.vehicleMake
                    : [],
                vehicleModel: selectedProspect?.vehicleModel
                    ? selectedProspect?.vehicleModel
                    : [],
                vehicleTrim: selectedProspect?.vehicleTrim
                    ? selectedProspect?.vehicleTrim
                    : 0,
                vehicleMaxPrice: selectedProspect?.vehicleMaxPrice
                    ? selectedProspect?.vehicleMaxPrice
                    : 0,
                vehicleMinYear: selectedProspect?.vehicleMinYear
                    ? selectedProspect?.vehicleMinYear
                    : 0,
                vehicleMileage: selectedProspect?.vehicleMileage
                    ? selectedProspect?.vehicleMileage
                    : 0,
                vehicleBodyType: selectedProspect?.vehicleBodyType
                    ? selectedProspect?.vehicleBodyType
                    : [],
                vehicleExteriorColor: selectedProspect?.vehicleExteriorColor
                    ? selectedProspect?.vehicleExteriorColor
                    : [],
                vehicleInterested: selectedProspect?.vehicleInterested
                    ? selectedProspect?.vehicleInterested
                    : false,
                tasks: selectedProspect?.tasks ? selectedProspect?.tasks : [],
                tradeMake: selectedProspect?.tradeMake
                    ? selectedProspect?.tradeMake
                    : "",
                tradeModel: selectedProspect?.tradeModel
                    ? selectedProspect?.tradeModel
                    : "",
                vehicleNote: selectedProspect?.vehicleNote
                    ? selectedProspect?.vehicleNote
                    : "",
                note: selectedProspect?.note ? selectedProspect?.note : "",
                tradeTrim: selectedProspect?.tradeTrim
                    ? selectedProspect?.tradeTrim
                    : "",
                tradeYear: selectedProspect?.tradeYear
                    ? selectedProspect?.tradeYear
                    : 0,
                tradeVin: selectedProspect?.tradeVin ? selectedProspect?.tradeVin : "",
                tradeMileage: selectedProspect?.tradeMileage
                    ? selectedProspect?.tradeMileage
                    : 0,
                employees: selectedProspect?.employees
                    ? selectedProspect?.employees
                    : [],
                deals: selectedProspect?.deals ? selectedProspect?.deals : [],
            });
            setDeals(calculateDealStatus(selectedProspect.deals));
            setTasks(groupByDate(selectedProspect.tasks));
            setAssigned(selectedProspect.employees);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProspect]);

    // const [htmlToSend, setHtmlToSend] = React.useState("");
    // const [
    //   htmlToSendForVehicleWish,
    //   setHtmlToSendForVehicleWish,
    // ] = React.useState("");
    const [wysiwygEditorState, setWysiwygEditorState] = React.useState(
        EditorState.createEmpty()
    );
    const [
        wysiwygEditorStateVehicleWish,
        setWysiwygEditorStateVehicleWish,
    ] = React.useState(EditorState.createEmpty());

    //const [] = React.useState(EditorState.createEmpty());

    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);

    const [stepForm, setStepForm] = React.useState(FormStep.Main);
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const formik = useFormik({
        initialValues: {
            // ...emptyForm,
            email: selectedProspect?.email ? selectedProspect.email : "",
            status: selectedProspect?.status ? selectedProspect.status : "",
            phone: selectedProspect?.phone ? selectedProspect.phone : "",
            leadType: selectedProspect?.leadType ? selectedProspect.leadType : "1",
            salutation: selectedProspect?.salutation
                ? selectedProspect.salutation
                : 1,
            firstName: selectedProspect?.firstName ? selectedProspect.firstName : "",
            middleName: selectedProspect?.middleName
                ? selectedProspect.middleName
                : "",
            lastName: selectedProspect?.lastName ? selectedProspect.lastName : "",
            id: selectedProspect?.id,
            dateOfBirth: selectedProspect?.dateOfBirth
                ? selectedProspect.dateOfBirth
                : null,
            // homePhoneNumber: '6767676767676',
            homePhoneNumber: selectedProspect?.homePhoneNumber
                ? selectedProspect.homePhoneNumber
                : "",
            workPhoneNumber: selectedProspect?.workPhoneNumber
                ? selectedProspect.workPhoneNumber
                : "",
            gender: selectedProspect?.gender ? selectedProspect.gender : null,
            currentAddress1: selectedProspect?.currentAddress1
                ? selectedProspect?.currentAddress1
                : "",
            currentAddress2: selectedProspect?.currentAddress2
                ? selectedProspect?.currentAddress2
                : "",
            zip: selectedProspect?.zip ? selectedProspect?.zip : "",
            city: selectedProspect?.city ? selectedProspect?.city : "",
            county: selectedProspect?.county ? selectedProspect?.county : "",
            state: selectedProspect?.state ? selectedProspect?.state : "",
            suffix: selectedProspect?.suffix ? selectedProspect?.suffix : "",
            driverLicense: selectedProspect?.driverLicense
                ? selectedProspect?.driverLicense
                : "",
            socialSecurityNumber: selectedProspect?.socialSecurityNumber
                ? selectedProspect?.socialSecurityNumber
                : "",
            socialSecurityNumberMasked: selectedProspect?.socialSecurityNumberMasked
                ? selectedProspect?.socialSecurityNumberMasked
                : false,
            employerName: selectedProspect?.employerName
                ? selectedProspect?.employerName
                : "",
            lenthAtJob: selectedProspect?.lenthAtJob
                ? selectedProspect?.lenthAtJob
                : 0,
            monthlyIncome: selectedProspect?.monthlyIncome
                ? selectedProspect?.monthlyIncome
                : null,
            employerAddress1: selectedProspect?.employerAddress1
                ? selectedProspect?.employerAddress1
                : "",
            employerAddress2: selectedProspect?.employerAddress2
                ? selectedProspect?.employerAddress2
                : "",
            employerZip: selectedProspect?.employerZip
                ? selectedProspect?.employerZip
                : "",
            employerCity: selectedProspect?.employerCity
                ? selectedProspect?.employerCity
                : "",
            employerCounty: selectedProspect?.employerCounty
                ? selectedProspect?.employerCounty
                : "",
            employerState: selectedProspect?.employerState
                ? selectedProspect?.employerState
                : "",
            vehicleCondition: selectedProspect?.vehicleCondition
                ? selectedProspect?.vehicleCondition
                : [],
            vehicleMake: selectedProspect?.vehicleMake
                ? selectedProspect?.vehicleMake
                : [],
            vehicleModel: selectedProspect?.vehicleModel
                ? selectedProspect?.vehicleModel
                : [],
            vehicleTrim: selectedProspect?.vehicleTrim
                ? selectedProspect?.vehicleTrim
                : 0,
            vehicleMaxPrice: selectedProspect?.vehicleMaxPrice
                ? selectedProspect?.vehicleMaxPrice
                : 0,
            vehicleMinYear: selectedProspect?.vehicleMinYear
                ? selectedProspect?.vehicleMinYear
                : 0,
            vehicleMileage: selectedProspect?.vehicleMileage
                ? selectedProspect?.vehicleMileage
                : 0,
            vehicleBodyType: selectedProspect?.vehicleBodyType
                ? selectedProspect?.vehicleBodyType
                : [],
            vehicleExteriorColor: selectedProspect?.vehicleExteriorColor
                ? selectedProspect?.vehicleExteriorColor
                : [],
            vehicleInterested: selectedProspect?.vehicleInterested
                ? selectedProspect?.vehicleInterested
                : false,
            tasks: selectedProspect?.tasks ? selectedProspect?.tasks : [],
            tradeMake: selectedProspect?.tradeMake ? selectedProspect?.tradeMake : "",
            tradeModel: selectedProspect?.tradeModel
                ? selectedProspect?.tradeModel
                : "",
            vehicleNote: selectedProspect?.vehicleNote
                ? selectedProspect?.vehicleNote
                : "",
            note: selectedProspect?.note ? selectedProspect?.note : "",
            tradeTrim: selectedProspect?.tradeTrim ? selectedProspect?.tradeTrim : "",
            tradeYear: selectedProspect?.tradeYear ? selectedProspect?.tradeYear : 0,
            tradeVin: selectedProspect?.tradeVin ? selectedProspect?.tradeVin : "",
            tradeMileage: selectedProspect?.tradeMileage
                ? selectedProspect?.tradeMileage
                : 0,
            employees: selectedProspect?.employees ? selectedProspect?.employees : [],
            deals: selectedProspect?.deals ? selectedProspect?.deals : [],
        },

        validationSchema: Yup.object({
            email: Yup.string()
                .email("This field must be valid email user")
                .required("Required"),
            firstName: Yup.string().required("Required"),
            middleName: Yup.string().nullable(),
            lastName: Yup.string().required("Required"),
            phone: Yup.string().required("Required"),
            salutation: Yup.number().required("Required"),
            status: Yup.string().nullable(),
            leadType: Yup.string().nullable(),
            homePhoneNumber: Yup.string().nullable(),
            workPhoneNumber: Yup.string().nullable(),
            gender: Yup.number().nullable(),
            dateOfBirth: Yup.string().nullable(),
            currentAddress1: Yup.string().nullable(),
            currentAddress2: Yup.string().nullable(),
            zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits").nullable(),
            city: Yup.string().nullable(),
            county: Yup.string().nullable(),
            state: Yup.string().nullable(),
            suffix: Yup.string().nullable(),
            driverLicense: Yup.string().nullable(),
            socialSecurityNumber: Yup.string().nullable(),
            socialSecurityNumberMasked: Yup.boolean().nullable(),
            employerName: Yup.string().nullable(),
            lenthAtJob: Yup.number().nullable(),
            monthlyIncome: Yup.number().nullable(),
            employerAddress1: Yup.string().nullable(),
            employerAddress2: Yup.string().nullable(),
            employerZip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits").nullable(),
            employerCity: Yup.string().nullable(),
            employerCounty: Yup.string().nullable(),
            employerState: Yup.string().nullable(),
            vehicleCondition: Yup.array().of(Yup.number()),
            vehicleMake: Yup.array().of(Yup.number()),
            vehicleModel: Yup.array().of(Yup.number()),
            vehicleTrim: Yup.string().nullable(),
            vehicleMaxPrice: Yup.number().nullable(),
            vehicleMinYear: Yup.number().nullable(),
            vehicleMileage: Yup.number().nullable(),
            vehicleBodyType: Yup.array().of(Yup.number()),
            vehicleExteriorColor: Yup.array().of(Yup.number()),
            vehicleInterested: Yup.boolean().nullable(),
            tradeMake: Yup.string().nullable(),
            tradeModel: Yup.string().nullable(),
            tradeTrim: Yup.string().nullable(),
            tradeYear: Yup.number().nullable(),
            tradeVin: Yup.string().nullable(),
            tradeMileage: Yup.number().nullable(),
            note: Yup.string().nullable(),
            employees: Yup.array().of(Yup.object()),
            deals: Yup.array().of(Yup.object()),
        }),
        onSubmit: (values) => {
            const {
                id,
                email,
                firstName,
                middleName,
                lastName,
                phone,
                salutation,
                status,
                leadType,
                homePhoneNumber,
                workPhoneNumber,
                gender,
                dateOfBirth,
                currentAddress1,
                currentAddress2,
                zip,
                city,
                county,
                state,
                suffix,
                driverLicense,
                socialSecurityNumber,
                socialSecurityNumberMasked,
                employerName,
                lenthAtJob,
                monthlyIncome,
                employerAddress1,
                employerAddress2,
                employerZip,
                employerCity,
                employerCounty,
                employerState,
                vehicleCondition,
                vehicleMake,
                vehicleModel,
                vehicleTrim,
                vehicleMaxPrice,
                vehicleMinYear,
                vehicleMileage,
                vehicleBodyType,
                vehicleExteriorColor,
                vehicleInterested,
                // vehicleNote,
                tasks,
                tradeMake,
                tradeModel,
                tradeTrim,
                tradeYear,
                tradeVin,
                tradeMileage,
                // note,
                employees,
                deals,
            } = values;

            const date = dateOfBirth
                ? moment(dateOfBirth).format("YYYY-MM-DD")
                : null;
            const length =
                typeof lenthAtJob === "string" ? parseInt(lenthAtJob) : lenthAtJob;

            // const noteToSend = Boolean(htmlToSend) ? htmlToSend : note;
            const noteToSend = draftToHtml(
                convertToRaw(wysiwygEditorState.getCurrentContent())
            );

            // const vehicleNoteToSend = Boolean(htmlToSendForVehicleWish)
            //   ? htmlToSendForVehicleWish
            //   : vehicleNote;
            // console.log(wysiwygEditorState)
            const vehicleNoteToSend = draftToHtml(
                convertToRaw(wysiwygEditorStateVehicleWish.getCurrentContent())
            );

            // console.log(vehicleNoteToSend);
            

            dispatch<any>(
                handleUpdateProspect(
                    [
                        {
                            id,
                            email,
                            firstName,
                            middleName,
                            lastName,
                            phone,
                            salutation,
                            status,
                            leadType,
                            homePhoneNumber,
                            workPhoneNumber,
                            gender,
                            dateOfBirth: date,
                            currentAddress1,
                            currentAddress2,
                            zip,
                            city,
                            county,
                            state,
                            suffix,
                            driverLicense,
                            socialSecurityNumber,
                            socialSecurityNumberMasked,
                            employerName,
                            lenthAtJob: length,
                            monthlyIncome,
                            employerAddress1,
                            employerAddress2,
                            employerZip,
                            employerCity,
                            employerCounty,
                            employerState,
                            vehicleCondition,
                            vehicleMake,
                            vehicleModel,
                            vehicleTrim:
                                typeof vehicleTrim === "string"
                                    ? parseInt(vehicleTrim)
                                    : vehicleTrim,
                            vehicleMaxPrice,
                            vehicleMinYear:
                                typeof vehicleMinYear === "string"
                                    ? parseInt(vehicleMinYear)
                                    : vehicleMinYear,
                            vehicleMileage:
                                typeof vehicleMileage === "string"
                                    ? parseInt(vehicleMileage)
                                    : vehicleMileage,
                            vehicleBodyType,
                            vehicleExteriorColor,
                            vehicleInterested,
                            tasks,
                            tradeMake,
                            tradeModel,
                            tradeTrim,
                            tradeYear,
                            tradeVin,
                            tradeMileage,
                            note: noteToSend,
                            vehicleNote: vehicleNoteToSend,
                            employees,
                            deals,
                        },
                    ],
                    () => {
                        returnProspect && returnProspect(values);
                        onDismiss();
                    }
                )
            );
        },
    });

    useEffect(() => {
        const html = selectedProspect?.note ? selectedProspect?.note : "";
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setWysiwygEditorState(editorState);
        }

        const htmlVehicle = selectedProspect?.vehicleNote
            ? selectedProspect?.vehicleNote
            : "";
        const contentBlockVehicle = htmlToDraft(htmlVehicle);
        if (contentBlockVehicle) {
            const contentStateVehicle = ContentState.createFromBlockArray(
                contentBlockVehicle.contentBlocks
            );
            const editorStateVehicle = EditorState.createWithContent(
                contentStateVehicle
            );
            setWysiwygEditorStateVehicleWish(editorStateVehicle);
        }
    }, [selectedProspect]);

    const calcFilledFields = (array: any[]): number => {
        let count = 0;
        if (array.length > 0) {
            array.forEach((item) => {
                // console.log(formik.values[item]);

                if (Array.isArray(formik.values[item])) {
                    if (formik.values[item].length > 0) {
                        count += 1;
                    }
                } else {
                    if (formik.values[item]) {
                        count += 1;
                    }
                }
            });
        }
        return count;
    };

    const setVehicleHtmlLocally = (e: any) => {
        // console.log(e);

        formik.setFieldValue("vehicleNote", e);
        // const contentBlockVehicle = htmlToDraft(e);
        // if (contentBlockVehicle) {
        //   const contentStateVehicle = ContentState.createFromBlockArray(
        //       contentBlockVehicle.contentBlocks
        //   );
        //   const editorStateVehicle = EditorState.createWithContent(contentStateVehicle);
        //   setWysiwygEditorStateVehicleWish(editorStateVehicle);
        // }
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDropdownChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key,
            },
            true
        );
    };

    // const handleDelete = () => {
    //     setIsConfirmBoxOpen(true);
    // };

    const handleDismissPersonalInfo = () => {
        onDismiss();
    };

    const handleSetEditorStateVehicleWish = (state) => {
        setWysiwygEditorStateVehicleWish(state);
    };

    const composeSchedulerText = () => {
        // let text = "";
        // console.log(tasks);
        const numberOfTasks = {
            Overdue: 0,
            Today: 0,
            Tomorrow: 0,
            Incoming: 0,
        };
        tasks.forEach((task: any) => {
            // console.log(task);
            switch (task.date) {
                case "Overdue":
                    numberOfTasks.Overdue = task.tasks.length;
                    break;
                case "Today":
                    numberOfTasks.Today = task.tasks.length;
                    break;
                case "Tomorrow":
                    numberOfTasks.Tomorrow = task.tasks.length;
                    break;
                default:
                    numberOfTasks.Incoming += task.tasks.length;
                    break;
            }
        });

        return `${numberOfTasks.Overdue} Overdue, ${numberOfTasks.Today} Today, ${numberOfTasks.Tomorrow} Tommorow and ${numberOfTasks.Incoming} Incoming`;
    };

    const composeAssignedText = () => {
        const isUser = assigned.find(
            (employee: IEmployee) => employee.id === user.id
        );
        const otherEmployees = assigned.reduce(
            (acc: number, employee: IEmployee) => {
                return acc + (!!isUser && employee.id === isUser.id ? 0 : 1);
            },
            0
        );

        return `${!!isUser ? "You " : ""} ${
            !!isUser && otherEmployees ? "and " : ""
        } ${otherEmployees ? otherEmployees + " more..." : ""}`;
    };

    const _renderDialog = () => {
        switch (stepForm) {
            case FormStep.Main:
                return null;
            case FormStep.Deals:
                return (
                    <EditProspectDealsDialog
                        formik={formik}
                        handleEditProspect={handleEditProspect}
                        onDismiss={() => setStepForm(FormStep.Main)}
                        prospectId={selectedProspect?.id}
                    />
                );

            case FormStep.Scheduler:
                return (
                    <EditProspectSchedulerDialog
                        onDismiss={() => setStepForm(FormStep.Main)}
                        prospectId={selectedProspect?.id}
                        editable
                    />
                );
            case FormStep.AssignedTo:
                return (
                    <AssignedToDialog
                        prospectId={selectedProspect.id}
                        onDismiss={() => setStepForm(FormStep.Main)}
                    />
                );
            case FormStep.PersonalInformation:
                return (
                    <PersonalInformationDialog
                        formik={formik}
                        handleEditProspect={handleEditProspect}
                        onDismiss={handleDismissPersonalInfo}
                        handleGoBack={() => setStepForm(FormStep.Main)}
                        prospectId={1}
                    />
                );

            case FormStep.Employment:
                return (
                    <EmploymentDialog
                        handleEditProspect={handleEditProspect}
                        formik={formik}
                        onDismiss={() => setStepForm(FormStep.Main)}
                        handleGoBack={() => setStepForm(FormStep.Main)}
                    />
                );
            case FormStep.VehicleWish:
                return (
                    <VehicleWishDialog
                        formik={formik}
                        handleEditProspect={handleEditProspect}
                        onDismiss={() => setStepForm(FormStep.Main)}
                        // htmlToSendForVehicleWish={htmlToSendForVehicleWish}
                        // setHtmlToSendForVehicleWish={() => setHtmlToSendForVehicleWish}
                        setHtmlToSendForVehicleWish={setVehicleHtmlLocally}
                        setWysiwygEditorStateVehicleWish={handleSetEditorStateVehicleWish}
                        wysiwygEditorStateVehicleWish={wysiwygEditorStateVehicleWish}
                    />
                );
            case FormStep.TradeIn:
                return (
                    <TradeInDialog
                        formik={formik}
                        handleEditProspect={handleEditProspect}
                        onDismiss={() => setStepForm(FormStep.Main)}
                    />
                );
            default:
                console.log(`default switch`);
        }
    };

    const handleEditProspect = () => {
        formik.handleSubmit();
    };

    const handleToggleFavourite = (id: number, action: string) => {
        const favouriteAction =
            action === "add" ? FavouriteAction.add : FavouriteAction.remove;
        dispatch<any>(
            handleUpdateFavourite(
                [{ endpoint: "prospect", id: id }],
                () => {},
                favouriteAction
            )
        );
    };

    const handleSetStatus = (status: string) => {
        const newProspect = { ...prospect, status: status };
        const items = [newProspect];
        dispatch<any>(handleUpdateProspect(items, () => {}));
    };

    const handleDelete = () => {
        setIsConfirmBoxOpen(true);
    };

    const _handleDeleteProspect = () => {
        onDismiss();
        dispatch<any>(handleDeleteProspect([prospect.id]));
    };

    return (
        selectedProspect && (
            <Modal
                isOpen={true}
                onDismiss={onDismiss}
                isBlocking={true}
                containerClassName={contentStyles.container}
            >
                {_renderDialog()}
                <ConfirmDialog
                    loading={loading}
                    description="Please confirm deletion of prospect"
                    title="Confirm delete prospect"
                    isOpen={isConfirmBoxOpen}
                    onAction={_handleDeleteProspect}
                    onDismiss={() => setIsConfirmBoxOpen(false)}
                />
                <div className={contentStyles.header}>
                    <CustomFontText>Edit Prospect</CustomFontText>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onDismiss}
                    />
                </div>

                <div className={`noScrollbar ${contentStyles.body}`}>
                    <Stack>
                        <Stack tokens={styles.textFields}>
                            <ListItem
                                onClick={() => setStepForm(FormStep.Deals)}
                                title="Deals"
                                subTitles={[
                                    deals
                                        ? `${deals.active} active and ${deals.other} in history`
                                        : "No deals...",
                                ]}
                                count={
                                    selectedProspect.deals && selectedProspect.deals.length <= 9
                                        ? selectedProspect.deals.length.toString()
                                        : "9+"
                                }
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.Scheduler)}
                                title="Scheduler"
                                subTitles={[
                                    selectedProspect && tasks
                                        ? composeSchedulerText()
                                        : "No tasks...",
                                ]}
                                count={
                                    selectedProspect.tasks.length <= 9
                                        ? selectedProspect.tasks.length.toString()
                                        : "9+"
                                }
                                backgroundColor={colourRed.color}
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.AssignedTo)}
                                title="Assigned To..."
                                subTitles={[
                                    selectedProspect && assigned
                                        ? composeAssignedText()
                                        : "No assigned employees...",
                                ]}
                                count={
                                    selectedProspect.employees.length <= 9
                                        ? selectedProspect.employees.length.toString()
                                        : "9+"
                                }
                            />

                            <Dropdown
                                id="status"
                                label="Set Status"
                                selectedKey={formik.values.status}
                                onChange={handleDropdownChange}
                                placeholder=""
                                options={prospectStatuses}
                                styles={dropdownStyles}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.status && formik.errors.status
                                        ? formik.errors.status.toString()
                                        : null
                                }
                            />

                            <Dropdown
                                id="leadType"
                                label="Lead Type"
                                selectedKey={
                                    formik.values.leadType ? formik.values.leadType : ""
                                }
                                onChange={handleDropdownChange}
                                placeholder=""
                                options={prospectLeadType}
                                styles={dropdownStyles}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.leadType && formik.errors.leadType
                                        ? formik.errors.leadType.toString()
                                        : null
                                }
                            />

                            <TextField
                                id="email"
                                name="email"
                                label="Email address"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.email && formik.errors.email
                                        ? formik.errors.email.toString()
                                        : null
                                }
                            />

                            <TextField
                                id="phone"
                                name="phone"
                                label="Phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.phone && formik.errors.phone
                                        ? formik.errors.phone.toString()
                                        : null
                                }
                            />

                            <Row>
                                <Col style={{ maxWidth: "110px" }}>
                                    <Dropdown
                                        id="salutation"
                                        label="Salutation"
                                        selectedKey={
                                            formik.values.salutation
                                                ? formik.values.salutation
                                                : undefined
                                        }
                                        onChange={handleDropdownChange}
                                        placeholder="Mr"
                                        options={salutations}
                                        styles={dropdownStyles}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.salutation && formik.errors.salutation
                                                ? formik.errors.salutation.toString()
                                                : null
                                        }
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.firstName && formik.errors.firstName
                                                ? formik.errors.firstName.toString()
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                            <WithOptionalLabel>
                                <TextField
                                    id="middleName"
                                    name="middleName"
                                    label="Middle Name"
                                    value={formik.values.middleName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched.middleName && formik.errors.middleName
                                            ? formik.errors.middleName.toString()
                                            : null
                                    }
                                />
                            </WithOptionalLabel>

                            <TextField
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.lastName && formik.errors.lastName
                                        ? formik.errors.lastName.toString()
                                        : null
                                }
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.PersonalInformation)}
                                /*setFormStep(FormStep.Financial)*/
                                title="Personal Information"
                                subTitles={[
                                    "Date of birth",
                                    "Address",
                                    "ZIP",
                                    "City",
                                    "State",
                                    "Suffix",
                                    "SSN",
                                ]}
                                count={
                                    calcFilledFields(personalInformationItemList) > 0 &&
                                    calcFilledFields(personalInformationItemList).toString()
                                }
                                optional={calcFilledFields(personalInformationItemList) === 0}
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.Employment)}
                                title="Employment"
                                subTitles={["Employer Name", "Length at Job", "Monthly Income"]}
                                count={
                                    calcFilledFields(employmentItemList) > 0 &&
                                    calcFilledFields(employmentItemList).toString()
                                }
                                optional={calcFilledFields(employmentItemList) === 0}
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.VehicleWish)}
                                title="Vehicle Wish"
                                subTitles={[
                                    "Make",
                                    "Model",
                                    "Year",
                                    "Price",
                                    "Max Mileage",
                                    "Body Type",
                                    "Color",
                                ]}
                                count={
                                    calcFilledFields(vehicleItemList) > 0 &&
                                    calcFilledFields(vehicleItemList).toString()
                                }
                                optional={calcFilledFields(vehicleItemList) === 0}
                            />

                            <ListItem
                                onClick={() => setStepForm(FormStep.TradeIn)}
                                title="Trade In"
                                subTitles={["Year", "Make", "Model", "Trim", "VIN"]}
                                count={
                                    calcFilledFields(tradeItemList) > 0 &&
                                    calcFilledFields(tradeItemList).toString()
                                }
                                optional={calcFilledFields(tradeItemList) === 0}
                            />
                            <div style={{ marginBottom: 20 }} />

                            <WysiwygEditor
                                editorState={wysiwygEditorState}
                                setEditorState={setWysiwygEditorState}
                                // htmlToSend={htmlToSend}
                                // setHtmlToSend={setHtmlToSend}
                                htmlToSend={""}
                                setHtmlToSend={() => {}}
                            />
                        </Stack>
                    </Stack>
                </div>
                <div className={contentStyles.footer}>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        tokens={{ childrenGap: 10 }}

                        // styles={contentStyles.buttonsStyles}
                    >
                        <div>
                            <ProspectEditAdditionalActionsDropdown
                                id={prospect.id}
                                modulePermissions={getModulePermissions(
                                    user.auths || [],
                                    "Prospects"
                                )}
                                handleAddToFavourite={() =>
                                    handleToggleFavourite(prospect.id, FavouriteAction.add)
                                }
                                handleRemoveFromFavourite={() =>
                                    handleToggleFavourite(prospect.id, FavouriteAction.remove)
                                }
                                handleSetStatus={handleSetStatus}
                                handleDelete={handleDelete}
                            />
                        </div>
                        <div>
                            <Button
                                text="Close"
                                style={{ marginRight: "10px" }}
                                onClick={() => onDismiss()}
                            />
                            <PrimaryButton
                                text="Save Changes"
                                onClick={handleEditProspect}
                                onRenderIcon={renderSpinner}
                                disabled={!formik.isValid}
                            />
                        </div>
                    </Stack>
                </div>
            </Modal>
        )
    );
};
