import { ICreateDeal, IDeal } from "types/dealTypes";
import client from "services/api/client";
import { IPagination } from "types/paginationTypes";
import moment from "moment";

export const getDeals = async (request: IPagination) => {
  const {
    sort,
    page,
    limit,
    column,
    parentId,
    phrase,
    dateFrom,
    dateTo,
    dealStatuses,
    dealTypes,
    haveCoSigner,
    isFavourite,
  } = request;

  const from = dateFrom === null ? "" : moment(dateFrom).format("YYYY-MM-DD");
  const to = dateTo === null ? "" : moment(dateTo).format("YYYY-MM-DD");

  const query = `user/master/deals?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=${limit}&column=${column ? column : "id"}${
    parentId ? `&prospect=${parentId}` : ""
  }
  ${Boolean(dealStatuses) ? `&status=${dealStatuses}` : ""} 
  ${Boolean(dealTypes) ? `&type=${dealTypes}` : ""} 
  ${Boolean(isFavourite) ? `&isFavourite=1` : ""}
  ${Boolean(haveCoSigner) ? `&haveCoSigner=1` : ""}
&filter=${phrase}&dateFrom${"=" + from}&dateTo${"=" + to}`;

  // console.log(query);
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      

      return { data: null, error };
    }
  );
};

export const createDeals = (deal: ICreateDeal) => {
  const data = {
    type: deal.type,
    status: deal.status,
    isSent: deal.isSent,
  };
  console.log("CREATING DEAL IN API==============");
  return client(
    `user/master/deal/prospect/${deal.prospect}/inventory/${deal.inventory}`,
    { body: data, method: "POST" }
  ).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const viewDeal = (id: number | string) => {
  return client(`user/master/deals/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteDeal = (id: number | string) => {
  return client(`user/master/deal/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateDeal = (id: number | string, deal: IDeal) => {
  console.log(deal);
  
  return client(
    `user/master/deal/${deal.id}/prospect/${deal.prospect.id}/inventory/${deal.inventory.id}`,
    {
      body: deal,
      method: "PATCH",
    }
  ).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const assignSigner = (dealId: number, signerId: number) => {
  return client(`user/master/deal/${dealId}/signer/${signerId}`, {
    body: null,
    method: "POST",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const unassignSigner = (dealId: number, signerId: number) => {
  return client(`user/master/deal/${dealId}/signer/${signerId}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getDealSettings = () => {
  return client(`user/master//deals/settings`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
export const updateDealSettings = (dealSettings) => {
  console.log("deals settigns w api", dealSettings);
  return client(`user/master/deals/settings`, {
    body: dealSettings,
    method: "PATCH",
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};
