import {
  SWITCH_GLOBAL_LOADER,
  SwitchGlobalLoader,
} from "./globalLoading.types";

export const switchGlobalLoader = (state): SwitchGlobalLoader => {
  return {
    type: SWITCH_GLOBAL_LOADER,
    payload: state,
  };
};
