import {
  IProspect,
  /**ICreateProspect,*/ IProspects, IProspectsFilterSettings,
} from "types/prospectTypes";
import * as actions from "store/prospects/prospects.types";
import { ITasks } from "types/taskTypes";
import { CheckIfProspectExistState } from "constants/constants";

interface IState {
  documentData: any;
  loadingDocument: boolean;
  errorDocument: any;
  loading: boolean;
  checkingProspectState: keyof typeof CheckIfProspectExistState | null;
  items: IProspects;
  error: null | object;
  rowsPerPageProspects: number;
  selectedProspect: null | IProspect;
  updateLoading: boolean;
  column: string;
  sort: string;
  tasks: ITasks;
  isTaskLoading: boolean;
  loadingAddTask: boolean;
  filters: IProspectsFilterSettings;
}

const prospectInit: IProspects = {
  results: [],
  totalItems: 0,
  currentPage: 1,
};

const tasksInit: ITasks = {
  results: [],
  totalItems: 0,
  currentPage: 1,
};

const initialState: IState = {
  documentData: null,
  loadingDocument: false,
  errorDocument: null,
  loading: false,
  checkingProspectState: null,
  items: prospectInit,
  error: null,
  rowsPerPageProspects: 10,
  selectedProspect: null,
  updateLoading: false,
  column: "id",
  sort: "ASC",
  tasks: tasksInit,
  isTaskLoading: false,
  loadingAddTask: false,
  filters: {
    haveDeals: false,
    isFavourite: false,
    tradeIn: false,
    vehicleInterested: false,
    availableEmail: false,
    availablePhone: false,
    assignToMe: false,
    newProspect: false,
    vehicleMake: { key: 0, text: "" },
    vehicleModel: { key: 0, text: "" },
    scheduler: [],
    leadType: [],
    minMonthlyIncome: null,
    maxMonthlyIncome: null,
    zip: null,
    city: null,
    state: null,
    vehicleCondition: [],
    vehicleMaxPrice: null,
    vehicleMinYear: null,
    vehicleMaxYear: null,
    vehicleMaxMileage: null,
    vehicleBodyType: [],
    vehicleExteriorColor: null
  },
};

export const ProspectsReducer = (
  state: IState = initialState,
  action: actions.ProspectsActions
) => {
  switch (action.type) {
    case actions.CHECK_IF_PROSPECT_EXIST: {
      return {
        ...state,
        checkingProspectState: action.payload,
      };
    }
    // case actions.CHECK_IF_PROSPECT_EXIST_RESOLVED: {
    //   return {
    //     ...state,
    //     checkingProspectState: false
    //   }
    // }
    // case actions.CHECK_IF_PROSPECT_EXIST_REJECTED: {
    //   return {
    //     ...state,
    //     checkingProspectState: false
    //   }
    // }
    case actions.CREATE_PROSPECT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_PROSPECT_REJECTED:
      return { ...state, loading: false, error: action.payload };
    case actions.CREATE_PROSPECT_RESOLVED:
      return {
        ...state,
        loading: false,
        //prospects: action.payload
      };
    case actions.GET_PROSPECTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROSPECTS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.GET_PROSPECTS_RESOLVED:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case actions.SET_ROWS_PER_PAGE_PROSPECTS:
      return {
        ...state,
        rowsPerPageProspects: action.payload,
      };
    case actions.VIEW_PROSPECT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.VIEW_PROSPECT_RESOLVED: {
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        selectedProspect: action.payload,
      };
    }

    case actions.VIEW_PROSPECT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_PROSPECT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.DELETE_PROSPECT_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_PROSPECT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_PROSPECT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.UPDATE_PROSPECT_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_PROSPECT_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.PROSPECT_ASSIGN_EMPLOYEE_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.PROSPECT_ASSIGN_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.PROSPECT_ASSIGN_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: { ...action.payload },
      };
    }
    case actions.PROSPECT_UNASSIGN_EMPLOYEE_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.PROSPECT_UNASSIGN_EMPLOYEE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: { ...action.payload },
      };
    }
    case actions.ADD_TASK_PENDING: {
      return {
        ...state,
        loadingAddTask: true,
      };
    }
    case actions.ADD_TASK_RESOLVED: {
      return {
        ...state,
        loadingAddTask: false,
      };
    }
    case actions.ADD_TASK_REJECTED: {
      return {
        ...state,
        loadingAddTask: false,
        error: { ...action.payload },
      };
    }
    case actions.REMOVE_TASK_PENDING: {
      return {
        ...state,
        isTaskLoading: true,
      };
    }
    case actions.REMOVE_TASK_RESOLVED: {
      return {
        ...state,
        isTaskLoading: false,
      };
    }
    case actions.REMOVE_TASK_REJECTED: {
      return {
        ...state,
        isTaskLoading: false,
        error: { ...action.payload },
      };
    }
    //case actions.GET_TASKS_PENDING: {
    //  return {
    //    ...state,
    //    loading: true,
    //  };
    //}
    //case actions.GET_TASKS_RESOLVED: {
    //  return {
    //    ...state,
    //    loading: false,
    //    tasks: { ...action.payload },
    //  };
    //}
    //case actions.GET_TASKS_REJECTED: {
    //  return {
    //    ...state,
    //    loading: false,
    //    error: { ...action.payload },
    //  };
    //}
    case actions.UPDATE_TASK_PENDING: {
      return {
        ...state,
        isTaskLoading: true,
      };
    }
    case actions.UPDATE_TASK_RESOLVED: {
      return {
        ...state,
        isTaskLoading: false,
        // ???
      };
    }
    case actions.UPDATE_TASK_REJECTED: {
      return {
        ...state,
        isTaskLoading: false,
        error: { ...action.payload },
      };
    }

    case actions.CLEAN_PROSPECT_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }

    case actions.SET_PROSPECT_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case actions.DOCUMENT_READER_PENDING:
      return {
        ...state,
        loadingDocument: true,
        errorDocument: null,
      };
    case actions.DOCUMENT_READER_RESOLVED:
      return {
        ...state,
        loadingDocument: false,
        documentData: action.payload,
        errorDocument: null,
      };
    case actions.DOCUMENT_READER_REJECTED:
      return {
        ...state,
        loadingDocument: false,
        errorDocument: action.payload,
      };

    case actions.CLEAR_DOCUMENT_AND_ERROR_READER:
      return {
        ...state,
        loadingDocument: false,
        errorDocument: null,
        documentData: null
      };
    default:
      return state;
  }
};
