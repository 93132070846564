import React, { Component } from "react";
import { Stack, DefaultButton } from "@fluentui/react";
import "./Dropzone.css";
import { colors } from "constants/constants";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import uploadPhoto from "assets/uploadPhoto.svg";

interface IProps {
  disabled?: boolean;
  // onFilesAdded: (array) => void;

  // files: any[];
  initFiles: string[];
  setFiles: (files) => void;

  filesToAdd: any[];
  setFilesToAdd: (files) => void;

  filesToRemove?: any[];
  setFilesToRemove?: (files) => void;
}

interface IState {
  hightlight: boolean;
  files: string[];
}

class AddInventoryDropzone extends Component<IProps, IState> {
  private readonly fileInputRef;

  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      // files: [],
      files: [],
    };

    this.fileInputRef = React.createRef<HTMLInputElement>();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
  }

  componentDidMount() {
    this.setState({
      files: [...this.props.initFiles],
    });
  }

  // componentDidUpdate(
  //   prevProps: Readonly<IProps>,
  //   prevState: Readonly<IState>,
  //   snapshot?: any
  // ) {
  //   if (prevProps.initFiles !== this.props.initFiles) {
  //     this.setState({
  //       files: [...this.props.initFiles],
  //     });
  //   }
  // }

  // componentDidUpdate(
  //   prevProps: Readonly<IProps>,
  //   prevState: Readonly<IState>,
  //   snapshot?: any
  // ) {
  //   if (prevProps.filesToAdd !== this.props.filesToAdd) {
  //     this.setState({
  //       files: [...this.props.initFiles],
  //     });
  //   }
  // }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;

    const files = evt.target.files;
    const newFiles = [];
    const filesToSend = [];

    for (const file of files) {
      filesToSend.push(file);
      newFiles.push(URL.createObjectURL(file));
    }

    console.log(newFiles);
    console.log(filesToSend);
    

    const filesToSet = [...this.state.files, ...newFiles];

    //this.props.setFiles(filesToSet);
    this.setState({
      files: filesToSet,
    });

    // console.log('event',evt.target.files)
    evt.target.files = undefined;

    //
    this.props.setFiles(filesToSet);

    this.props.setFilesToAdd([...this.props.filesToAdd, ...filesToSend]);
  }

  onDragOver(evt) {
    evt.preventDefault();
    // console.log(evt)

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    //const formData = new FormData();
    // formData.append('avatar', uploadedFile, 'test.jpeg');

    const files = event.dataTransfer.files;
    const newFiles = [];
    const filesToSend = [];

    for (const file of files) {
      filesToSend.push(file);
      newFiles.push(URL.createObjectURL(file));
    }
    if (this.props.disabled) return;

    const filesToSet = [...this.state.files, ...newFiles];

    //this.props.setFiles(filesToSet);
    this.setState({
      files: filesToSet,
    });


    this.props.setFiles(filesToSet);

    this.props.setFilesToAdd([...this.props.filesToAdd, ...filesToSend]);

    this.setState({ hightlight: false });
  }

  handleRemoveImage(e: any, file: any) {
    const { filesToRemove, setFilesToRemove } = this.props;

    // removing from view
    const newFiles = [...this.state.files].filter((f) => f !== file);
    this.setState({
      files: newFiles,
    });

    if (filesToRemove) {
      let updatedFilesToRemove = [...filesToRemove];
      if (filesToRemove.includes(file)) {
        updatedFilesToRemove = updatedFilesToRemove.filter((f) => f !== file);
      } else {
        updatedFilesToRemove.push(file);
      }
      setFilesToRemove(updatedFilesToRemove);

      (document.getElementById("multifile") as HTMLInputElement).value = "";
    }
  }

  private _checkImagePath(image: string) {
    const img = image.includes("blob")
      ? image
      : `${process.env.REACT_APP_IMAGE_SERVER}/${image}`;

    console.log("img", img);
    return img;
  }

  render() {
    return (
      <>
        <Stack
          style={{ paddingBottom: 5 }}
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <div style={{ fontWeight: "bold", fontSize: 16 }}>Photos</div>
            {/*<CustomFontText
            size={"14px"}
            color={colors.grayText}
            className={"optionalLabel"}
            optional
          >
            optional
          </CustomFontText>*/}
        </Stack>
        <div
          className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            multiple
            id="multifile"
            onChange={this.onFilesAdded}
          />
          <Stack horizontalAlign="center" verticalAlign="center">
            <img
              // style={{ backgroundColor: colors.grayText }}
              alt="upload"
              className="Icon"
              src={uploadPhoto}
              // src="baseline-cloud_upload-24px.svg"
            />
            <div style={{ fontSize: 18, color: colors.grayText }}>
              Drag and drop or click here
            </div>
            <div style={{ fontSize: 14, color: colors.grayText }}>
              to upload your image (max 2MB)
            </div>
          </Stack>
        </div>
        <Stack horizontal wrap style={{ marginTop: 20 }}>
          {this.state.files.map((file, index) => {
            console.log("file", file);
            return (
              <Stack key={index} style={{ marginRight: 20 }}>
                <div
                  style={{
                    width: 100,
                    height: 80,
                    //background: `#fcfcfc url(${file}) right bottom no-repeat`,
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${this._checkImagePath(file)})`,
                    backgroundSize: "cover",
                  }}
                />
                {/*<img style={{ width: 100, height: 150 }} src={file} />*/}
                <DefaultButton
                  style={{ marginTop: 10, marginBottom: 10 }}
                  text="Remove"
                  onClick={(e) => this.handleRemoveImage(e, file)}
                />
              </Stack>
            );
          })}
        </Stack>
      </>
    );
  }
}

export default AddInventoryDropzone;
