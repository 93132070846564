import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { DefaultPalette, IIconStyles } from "@fluentui/react";
import {
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react/lib/ContextualMenu";
import {
  colors,
  FavouriteAction,
  permissionsModules,
} from "constants/constants";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { IPermission } from "types/groupPermissionTypes";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};
interface Props {
  handleUpdateIsFavourite: (action: FavouriteAction) => void;
  onDelete?: () => void;
  setStatuses: (string) => void;
  modulePermissions: IPermission[];
}

export const DealTableDropdown: React.FunctionComponent<Props> = ({
  handleUpdateIsFavourite,
  setStatuses,
  onDelete,
  modulePermissions,
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const menuItems: IContextualMenuItem[] = [
    {
      key: "favourites", //with submenu
      name: "Favourites",
      iconProps: { iconName: "FavoriteList", styles: iconMenuStyles },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Deals,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => {},
      subMenuProps: {
        items: [
          {
            key: "addToFavorite",
            text: "Add",
            onClick: () => handleUpdateIsFavourite(FavouriteAction.add),
          },
          {
            key: "removeFromFavorite",
            text: "Remove",
            onClick: () => handleUpdateIsFavourite(FavouriteAction.remove),
          },
        ],
      },
    },
    {
      key: "setStatus", //with submenu
      name: "Set Status",
      iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
      onClick: () => {},
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Deals,
        user.roles
      )
        ? hiddenStyle
        : null,
      subMenuProps: {
        items: [
          {
            key: "inactive",
            text: "Inactive",
            onClick: () => setStatuses("inactive"),
          },
          {
            key: "pending",
            text: "pending",
            onClick: () => setStatuses("pending"),
          },
          {
            key: "active",
            text: "active",
            onClick: () => setStatuses("active"),
          },
          {
            key: "dead",
            text: "dead",
            onClick: () => setStatuses("dead"),
          },
        ],
      },
    },

    {
      key: "delete", //with submenu
      iconProps: { iconName: "Delete", styles: iconMenuStyles },
      name: "Delete",
      onClick: onDelete,
      style: !isPermitted(
        modulePermissions,
        eTypes.DELETE,
        permissionsModules.Deals,
        user.roles
      )
        ? hiddenStyle
        : null,
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
    useTargetWidth: false,
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};
