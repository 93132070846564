import React from "react";
import {
  Panel,
  IPanelStyles,
  PanelType,
} from "@fluentui/react/lib/Panel";

import {
  IconButton,
  Stack,
  DefaultButton,
  PrimaryButton,
  DatePicker,
  Checkbox,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "../dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { getTheme } from "@fluentui/react";
import { filterPanelStyles as content } from "constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  setDealFilters,
  cleanDealFilters,
  handleGetDeals,
} from "store/deals/deals.action";
import moment from "moment";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { dealStatuses, dealTypes } from "constants/constants";

const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
  content: {
    paddingLeft: 0,
  },
  contentInner: {
    marginTop: 0,
    marginLeft: 0,
  },
};
const contentStyles = content();
const theme = getTheme();

enum DateRanges {
  from,
  to,
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const DealsFilterPanel: React.FC<IProps> = ({ isOpen, onClose }) => {
  const filters = useSelector((state: AppState) => state.deals.filters);
  const dispatch = useDispatch();

  const handleDateChange = (date, flag) => {
    const value = flag === DateRanges.from ? "dateFrom" : "dateTo";
    dispatch<any>(setDealFilters({ ...filters, [value]: date }));
  };

  const handleMultipleDropdownValueChangeStringOptions = (e, item) => {
    const property = e.target.id;

    let newValues = [...filters[property]];

    if (newValues.includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.key);
    } else {
      newValues.push(item.text);
    }

    dispatch<any>(
      setDealFilters({
        ...filters,
        [property]: newValues,
      })
    );
  };

  const handleMultipleDropdownValueChangeIntOptions = (e, item) => {
    const property = e.target.id;

    let newValues = [...filters[property]];

    if (newValues.includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.key);
    } else {
      newValues.push(item.key);
    }

    dispatch<any>(
      setDealFilters({
        ...filters,
        [property]: newValues,
      })
    );
  };

  const cleanFilters = () => {
    dispatch<any>(cleanDealFilters());
  };

  const handleFilter = () => {
    dispatch<any>(
      handleGetDeals({
        page: 1,
        // limit: this.props.rowsPerPage,
        //
        // sort: this.props.sort,
        // column: this.props.column,
      })
    );
    onClose();
  };

  const handleToggleCheckbox = (e, checked) => {
    dispatch<any>(
      setDealFilters({
        ...filters,
        [e.target.id]: Boolean(checked),
      })
    );
  };

  const _renderContent = () => {
    return (
      <>
        <Dropdown
          id="dealStatuses"
          label="Deal Status"
          multiSelect
          options={dealStatuses}
          selectedKeys={filters["dealStatuses"]}
          onChange={handleMultipleDropdownValueChangeStringOptions}
          placeholder=""
        />
        <Dropdown
          id="dealTypes"
          label="Deal Type"
          onChange={handleMultipleDropdownValueChangeIntOptions}
          multiSelect
          options={dealTypes}
          selectedKeys={filters["dealTypes"]}
          placeholder=""
        />

        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <DatePicker
            onSelectDate={(e) => handleDateChange(e, DateRanges.from)}
            value={
              filters.dateFrom !== null && moment(filters.dateFrom).toDate()
            }
            style={{ marginBottom: 0, width: "100%" }}
            label="Date From"
            placeholder="select date..."
            ariaLabel="Select a date from"
            showGoToToday={false}
            // isMonthPickerVisible={false}
          />
          <DatePicker
            onSelectDate={(e) => handleDateChange(e, DateRanges.to)}
            value={filters.dateTo !== null && moment(filters.dateTo).toDate()}
            style={{ marginBottom: 0, width: "100%" }}
            label="Date To"
            placeholder="select date..."
            ariaLabel="Select a date to"
            showGoToToday={false}
            // isMonthPickerVisible={false}
          />
        </Stack>
        <div style={{ marginTop: 15 }}>
          <Checkbox
            id="haveCoSigner"
            label="Have Co-Signer(s)"
            checked={filters.haveCoSigner}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <Checkbox
            id="isFavourite"
            label="Favourite"
            checked={filters.isFavourite}
            onChange={handleToggleCheckbox}
          />
        </div>
      </>
    );
  };

  return (
    <Panel
      styles={panelStyles}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onClose}
      hasCloseButton={false}
      // isLightDismiss={true}
      // // Use this prop to do special handling *only* for light dismiss.
      // // If you provide this, the normal onDismiss won't be called for light dismiss.
      // onLightDismissClick={onClose}

      // closeButtonAriaLabel="Close"
    >
      <Stack verticalAlign={"space-between"}>
        <div className={contentStyles.header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ width: "100%" }}
          >
            <GoBackToMainDialogBtn label="Filters" onClick={onClose} />
            <IconButton
              styles={{
                root: {
                  color: theme.palette.neutralPrimary,
                  marginLeft: "auto",
                  marginTop: "4px",
                  marginRight: "2px",
                  // position: 'absolute',
                  // // zIndex: 1111,
                  // right: 0,
                },
                rootHovered: {
                  color: theme.palette.neutralDark,
                },
              }}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={onClose}
            />
          </Stack>
        </div>
        {/*<div style={{width: 600}}>test width</div>*/}
        <div className={contentStyles.content}>{_renderContent()}</div>
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">
            <DefaultButton text="Clear" onClick={cleanFilters} />
            <PrimaryButton text="View results" onClick={handleFilter} />
          </Stack>
        </div>
      </Stack>
    </Panel>
  );
};

export default DealsFilterPanel;
