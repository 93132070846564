import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate  } from "react-router-dom";
import { Stack } from "@fluentui/react";
import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
    Link,
    TextField,
    PrimaryButton,
    DefaultButton
} from "@fluentui/react";
import {  dropdownStyles } from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleUpdateAccount, handleViewAccount, handleCreateAccount, handleAccountActivate } from "store/accounts/accounts.action";
import { validTenDigitPhone } from "utils/helperFunctions";

interface ParamTypes {
    accountID: string;
}



const emptyForm = {
    nickName: "",
    profileType: "",
    firstName: "",
    lastName: "",
    workPhone: "",
    email: "",
    notes: "",
    isActive: true
};

export const AccountDetailPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const params = useParams<ParamTypes|any>();

    const serverError = useSelector((state: AppState) => state.accounts.serverError) as any;
    const activateDeavtiveError = useSelector((state: AppState) => state.accounts.accountActivateError) as any;

    const account = useSelector((state: AppState) => state.accounts.selectedAccount);

    const [commonServerError, setcommonServerError] = React.useState(null);
    const [emailError, setEmailError] = useState("");
    const [linkName, setLinkName] = useState("");
    const [emailReadOnly, setEmailReadOnly] = useState(false);



    const formik = useFormik({
        initialValues: {
            ...emptyForm,
        },

        validationSchema: Yup.object({
            nickName: Yup.string().required("Required").max(255, 'Salesforce Account Name must be at most 255 characters'),
            profileType: Yup.string().required("Required"),
            firstName: Yup.string().required("Required").max(50, 'First Name must be at most 50 characters'),
            lastName: Yup.string().required("Required").max(50, 'Last Name must be at most 50 characters'),
            workPhone: Yup.string().required("Required").test("len", "Invalid phone number", (val) => {
                if (val === undefined)
                    return true;
                return validTenDigitPhone(val)
            }),
            email: Yup.string().required("Required").email("This field must be valid email"),
            notes: Yup.string(),
            isActive: Yup.boolean()
        }),
        onSubmit: (values) => {
            values.workPhone = values.workPhone.replace(/[^\d]/g, "").slice(0, 10);
            const {
                nickName,
                profileType,
                firstName,
                lastName,
                workPhone,
                email,
                notes
            } = values;

            let accountData = {
                nickName,
                profileType,
                firstName,
                lastName,
                email,
                notes,
                workPhone: workPhone.replace(/\D/g, '').slice(0, 10),
            };
            if (params.accountID) {
                accountData["id"] = params.accountID;
                dispatch<any>(
                    handleUpdateAccount({
                        account: accountData,
                        callback: () => { if (!serverError) { navigate('/admin-accounts') }; },
                    })
                );
            } else {
                dispatch<any>(
                    handleCreateAccount({
                        account: accountData,
                        callback: () => { if (!serverError) { navigate('/admin-accounts') }; },
                    })
                );
            }


            // to be removed and fire only when no errors
        },
    });
    const profileTypevalues = [{text:"QoreValue",value: "PROFILE_TYPE_DEFAULT"}, {text:"Karl",value: "PROFILE_TYPE_KARL"}].map((item) => {
        return { key: item.value, text: item.text };
      });

    /** initial data */
    useEffect(() => {
        if (params && params.accountID) {
            dispatch<any>(handleViewAccount(params.accountID));
        }
        formik.setValues({
            ...formik.values,
            nickName: "",
            profileType: "",
            firstName: "",
            lastName: "",
            workPhone: "",
            email: "",
            notes: "",
            isActive: true
        }, true);
        resetErrorTxt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {

        if (params.accountID && parseInt(params.accountID) > 0 && account && account.id === parseInt(params.accountID)) {
            formik.setValues({
                nickName: account.nickName,
                profileType: account.profileType,
                firstName: account.firstName,
                lastName: account.lastName,
                workPhone: account.workPhone,
                email: account.email,
                notes: account.notes,
                isActive: account.isActive
            }, true);
            if (account.email) {
                setEmailReadOnly(true);
            }
            if (account.isActive) {
                setLinkName('Deactivate Account');
            } else {
                setLinkName('Activate Account');
            }
        }
        else {

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    // resetting all error fields text to empty
    const resetErrorTxt = () => {
        setEmailError("");
    };

    useEffect(() => {
        if (serverError) {
            if (serverError.code) {
                if (serverError.code === 406) {
                    setcommonServerError('User token invalid.');
                }
            } else {
                (serverError as any).map((error) => {
                    // reset error before setting new Error Value
                    resetErrorTxt();
                    switch (error.propertyPath) {
                        case "email":
                            setEmailError(error.title);
                            break;
                        default:
                            setcommonServerError(error.title)
                    }

                    return null;
                });
            }
        } else {
            setcommonServerError(null);
        }
        // eslint-disable-next-line
    }, [serverError]);

    useEffect(() => {
        if (activateDeavtiveError) {
            setcommonServerError(activateDeavtiveError);
        }
        else {
            setcommonServerError(null);
        }
        // eslint-disable-next-line
    }, [activateDeavtiveError]);

    const handleSaveLocation = () => {
        formik.handleSubmit();
    };

    const handleDropdownChange = (e, item) => {
        formik.setValues(
          {
            ...formik.values,
            [e.target.id]: item.key,
          },
          true
          //   false
        );
      };

    const handleMaskInputLocation = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    const handleNameChange = (e) => {
        const re = /^[a-zA-Z ]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            formik.setFieldValue(e.target.id, e.target.value);
        }
    };

    const handleActivateUser = () => {
        dispatch<any>(handleAccountActivate({ id: params.accountID, isActive: !formik.values.isActive }));
    }


    return (
        <>
            <Stack horizontalAlign="start" className="locationDetails">
                <div>
                    <GoBackInfo mainPage="Account Details" secondPage="" />

                </div>

                <Row className="accountDetail">
                    <Col>
                        <Row>
                            <Col sm={8}>
                                <CustomFontText className="pageName">Account Details</CustomFontText>
                                {
                                    commonServerError ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                            </Col>
                            <Col sm={4} style={{ paddingTop: "30px", textAlign: "end" }}>
                                {params && params.accountID && (<Link onClick={handleActivateUser}>{linkName}</Link>)}
                            </Col>
                        </Row>
                        <Row className="accountDeatailSection" >
                            <Col>
                                <Row >
                                    <Col sm={6} >
                                        <TextField
                                            id="nickName"
                                            name="nickName"
                                            label="Salesforce Account Name"
                                            
                                            placeholder=""
                                            value={formik.values.nickName}
                                            autoComplete="off"
                                            required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched && formik.touched.nickName &&
                                                formik.errors && formik.errors.nickName &&
                                                formik.errors.nickName.toString()
                                            }
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Dropdown
                                            id="profileType"
                                            label="Account profile"
                                            placeholder=""
                                            selectedKey={
                                                formik.values.profileType ? formik.values.profileType : undefined
                                            }
                                            //autoComplete="off"
                                            required={true}
                                            styles={dropdownStyles}
                                            options={profileTypevalues}
                                            onChange={handleDropdownChange}
                                            onBlur={formik.handleBlur} //check
                                            errorMessage={
                                                formik.touched && formik.touched.profileType &&
                                                formik.errors && formik.errors.profileType &&
                                                formik.errors.profileType.toString()
                                            }
                                        />
                                    </Col>

                                </Row>
                                <hr style={{ marginTop: "20px" }} />
                                <Row>
                                    <Col sm={6}>
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            label="First Name"
                                            value={formik.values.firstName}
                                            required={true}
                                            onChange={(e) => handleNameChange(e)}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched && formik.touched.firstName &&
                                                formik.errors && formik.errors.firstName &&
                                                formik.errors.firstName.toString()
                                            }
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            value={formik.values.lastName}
                                            required={true}
                                            onChange={(e) => handleNameChange(e)}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched && formik.touched.lastName &&
                                                formik.errors && formik.errors.lastName &&
                                                formik.errors.lastName.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <div className="ms-TextField root-155">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text required-Label">Phone</label>
                                                <div className={formik.touched && formik.touched.workPhone &&
                                                    formik.errors && formik.errors.workPhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
                                                    <InputMask
                                                        autoClear={false}
                                                        required={true}
                                                        id="workPhone"
                                                        name="workPhone"
                                                        mask="(999)999-9999"
                                                        placeholder=""
                                                        value={formik.values.workPhone}
                                                        className="mask-TextField"
                                                        onChange={(e) => handleMaskInputLocation(e)}
                                                        onBlur={formik.handleBlur}>
                                                    </InputMask>
                                                </div>
                                                <span className="error-message">{
                                                    formik.touched && formik.touched.workPhone &&
                                                    formik.errors && formik.errors.workPhone &&
                                                    formik.errors.workPhone.toString()
                                                }</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formik.values.email}
                                            required={true}
                                            readOnly={emailReadOnly}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                emailError
                                                    ? emailError
                                                    : formik.touched.email && formik.errors.email
                                                        ? formik.errors.email.toString()
                                                        : null
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <TextField
                                            id="notes"
                                            name="notes"
                                            label="Notes"
                                            style={{ minHeight: "150px" }}
                                            value={formik.values.notes}
                                            multiline={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched && formik.touched.notes &&
                                                formik.errors && formik.errors.notes &&
                                                formik.errors.notes.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="actionRow">
                                    <Col>
                                        <DefaultButton
                                            onClick={() => { navigate('/admin-accounts') }}
                                            text="Close"
                                        />
                                        <PrimaryButton
                                            disabled={!formik.isValid || !formik.dirty}
                                            onClick={() => handleSaveLocation()}
                                            text="Save"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Stack>
        </>
    )
};

