/* eslint-disable array-callback-return */
import {
  IconButton,
  Stack
} from "@fluentui/react";
import React, { useEffect } from "react";
/* import {
  TextField as OffTextField
} from "@fluentui/react"; */
import {
  iconButtonStyles
} from "constants/styles";
import moment from "moment";
// import { makeStyles, Theme, createStyles } from '@mui/material/styles';
import { useFormik } from "formik";
import { Col, Row } from "react-grid-system";
import * as Yup from "yup";
//import { getAddreessByZip } from "services/api/smartyStreets";
//import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { AddCustomizedAdjustmentsDialog } from "components/dialogs/addInventoryDialog/AddCustomizedAdjustmentsDialog";
import { TreeTable } from 'primereact/treetable';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useParams } from "react-router-dom";
import { AppState } from "store";
import { handleGetAdjusterList, handleGetSourceWorkBook, handleGetVehSellerDetails, handleGetVehicleUsingID, handlePutAdjusterList, handleSaveSourceWorkBook } from "store/inventory/inventory.action";
// import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useMediaQuery } from "react-responsive";
import { currencyFormat, numberFormat } from "utils/helperFunctions";
// handleGetTrimUsingYmm


interface ParamTypes {
  vehicleID: string;
}

/* const useStyles = makeStyles((theme: ThemeStyle) =>

  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme?.palette?.background.paper,
      display: 'block',
    },
    heading: {
      fontSize: theme.typography?.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography?.pxToRem(15),
      color: theme.palette?.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette?.divider}`,
      padding: theme?.spacing?.(1, 2),
    },
    link: {
      color: theme.palette?.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    breadCrumblink: {
      display: 'flex',
    },
    instructions: {
      marginTop: theme?.spacing?.(1),
      marginBottom: theme?.spacing?.(1),
    },
    breadCrumbicon: {
      marginRight: theme?.spacing?.(0.5),
      width: 20,
      height: 20,
    },
    startTime: {
    }
  }),
); */

interface IProps {
  reportsData: any;
  retailVehInfo: any;
  adjustedData: (items) => void;
}

export const ShopInventoryRetailReportsComp: React.FunctionComponent<IProps> = ({ reportsData, retailVehInfo, adjustedData }) => {
  // const  classes:any  = useStyles();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes | any>();
  const navigate = useNavigate ();
  const [compTable, setcompTable] = React.useState([]);
  const isMobile = useMediaQuery({ maxWidth: 576 });

  // const [showVinField, setshowVinField] = React.useState(true);
  const [isCustomizedAdjustmentsDialog, setisCustomizedAdjustmentsDialog] = React.useState(false);
  const [adjusterList, setadjusterList] = React.useState(null);
  const [adjustedDataUpdated, setAdjustedDataUpdated] = React.useState(false);
  // const [customAdjustTable, setcustomAdjustTable] = React.useState(false);
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  const shopVehicleList = useSelector((state: AppState) => state.inventory.shopVehicleList);

  const formik = useFormik({
    initialValues: {
      searchVin: '',
      make: "",
      model: '',
      year: '',
      mileage: "",
      vin: "",
      shippingFrom: ""
    },
    validationSchema: Yup.object({
      searchVin: Yup.string(),
      make: Yup.string(),
      model: Yup.string(),
      year: Yup.number(),
      mileage: Yup.string(),
      vin: Yup.string(),
      shippingFrom: Yup.string()
    }),
    onSubmit: (values) => {
    },
  });

  const sourceVehicleformik = useFormik({
    initialValues: {
      auctionDate: '',
      auctionStartTime: '',
      url: '',
      name: '',
      location: '',
      sourceType: 'auction',
      sellerType: "business",
      firstName: "",
      lastName: "",
      businessName: "",
      address: "",
      zip: "",
      city: "",
      county: "",
      state: "",
    },
    validationSchema: Yup.object({
      sellerType: Yup.string().required("Required"),
      sourceType: Yup.string().required("Required"),
      auctionDate: Yup.string(),
      auctionStartTime: Yup.string(),
      name: Yup.string(), //.required("Required")
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      ),
      //location: Yup.string(),
      location: Yup.string().matches(/^[aA-zZ0-9\s]+$/, 'Special Characters not allowed'),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      businessName: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
      city: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
      state: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      let startTime;
      if (values.auctionStartTime) {
        let utcStart = moment(values.auctionStartTime, "HH:mm").utc();
        startTime = utcStart.format()
      }
      const sourceData: any = {
        isIndividual: (values.sellerType !== 'business'), // values.setStatus,
        isBusiness: (values.sellerType === 'business'), // values.condition,
        firstName: values.firstName,
        lastName: values.lastName,
        businessName: values.businessName,
        address: values.address,
        zip: values.zip,
        city: values.city,
        county: values.county,
        state: values.state,
        phone: '',
        source: values.sourceType.toLowerCase(),
        sourceData: {
          name: values.name,
          auctionUrl: values.url,
          time: startTime,
          date: values.auctionDate
        },
        shippingFrom: values.location,
        id: params.vehicleID
      };

      dispatch<any>(handleSaveSourceWorkBook(sourceData));
    }
  });

  // Map Table data once the Reports data are modified
  useEffect(() => {
    let _expandedKeys = {};
    _expandedKeys['1'] = true;
    _expandedKeys['2'] = true;

    let tableList = [];

    // setExpandedKeys(_expandedKeys);
    console.log('retailVehInfo', retailVehInfo)
    if (retailVehInfo) {
      retailVehInfo.price_history.map((data, index) => {
        let obj = {
          "key": index.toString(),
          "data": {
            "date": data.change_date,
            "price": currencyFormat(data.price),
            "odo": `Odo ${numberFormat(data.mileage)}`
          },
          "children": []
        }
        tableList.push(obj);
      })

      setcompTable(tableList);
    }
    // eslint-disable-next-line
  }, [retailVehInfo]);

  //useEffect(() => {
  //  let zipFields = sourceVehicleformik.values.zip;
  //  if (zipFields.length === 5 && !isNaN(zipFields as any)) {
  //    dispatch<any>(switchGlobalLoader(true));
  //    (getAddreessByZip(zipFields) as any).then((response) => {
  //
  //      if (response.Status) {
  //        if (sourceVehicleformik.values.city === '' &&
  //          sourceVehicleformik.values.county === '' &&
  //          sourceVehicleformik.values.state === '') {
  //          sourceVehicleformik.setValues(
  //            {
  //              ...sourceVehicleformik.values,
  //              'county': response.DefaultCounty,
  //              'city': response.DefaultCity,
  //              'state': response.State
  //            }
  //          );
  //        }
  //        // setlocationZipApiMsg("");
  //        // updateForm("State", response.State);
  //      }
  //      else {
  //        // setZipApiMsg(response.ErrorMessage);
  //        // setlocationZipApiMsg(response.ErrorMessage);
  //        sourceVehicleformik.setValues(
  //          {
  //            ...sourceVehicleformik.values,
  //            'county': "",
  //            'city': "",
  //            'state': ""
  //          }
  //        );
  //        // resetCityCountyState();
  //      }
  //      dispatch<any>(switchGlobalLoader(false));
  //    });
  //  }
  //  else {
  //    sourceVehicleformik.setValues(
  //      {
  //        ...sourceVehicleformik.values,
  //        'county': "",
  //        'city': "",
  //        'state': ""
  //      }
  //    );
  //    // setlocationZipApiMsg("");
  //  }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [sourceVehicleformik.values.zip]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shopVehicleList && shopVehicleList.id) {
      dispatch<any>(handleGetAdjusterList({
        id: shopVehicleList.id,
        reportType: 'blackbook',
        callback: (rawList) => {
          let list = [];
          rawList.map((item) => {
            list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
          });
          setadjusterList(list);
          if (list.length === 0) {
            // setdisableAdjuster(true);
          }
        }
      }));
    }

    dispatch<any>(handleGetVehSellerDetails({
      callback: (vehDetails) => {
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adjustedDataUpdated) {
      if (shopVehicleList && shopVehicleList.id) {
        dispatch<any>(handleGetAdjusterList({
          id: shopVehicleList.id,
          reportType: 'blackbook',
          callback: (rawList) => {
            let list = [];
            rawList.map((item) => {
              list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
            });
            setadjusterList(list);
            if (list.length === 0) {
              // setdisableAdjuster(true);
            }
            setAdjustedDataUpdated(false);
          }
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedDataUpdated]);

  useEffect(() => {
    if (shopVehicleList && shopVehicleList.id) {
      formik.setValues(
        {
          ...formik.values,
          'vin': shopVehicleList.vin,
          'make': shopVehicleList.make,
          'model': shopVehicleList.model,
          'year': shopVehicleList.year
        }
      );

      /* if (shopVehicleList.vin) {
        setshowVinField(true);
      } else {
        setshowVinField(false);
      } */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopVehicleList])

  useEffect(() => {
    if (params.vehicleID) {
      // if (!shopVehicleList) {
      dispatch<any>(handleGetVehicleUsingID({
        id: params.vehicleID,
        callback: (response) => {
          if (!response) {
            navigate(`/appraisal`);
          }
        }
      }));

      dispatch<any>(handleGetSourceWorkBook({
        id: params.vehicleID,
        callback: (response) => {
          if (response) {
            if (response.firstName && response.id) {
              let local = null;
              if (response.sourceData?.time) {

                var date = moment.utc(response.sourceData.time).format('YYYY-MM-DD HH:mm:ss');
                var stillUtc = moment.utc(date).toDate();
                local = moment(stillUtc).local().format('HH:mm');
              }

              sourceVehicleformik.setValues(
                {
                  ...sourceVehicleformik.values,
                  'sellerType': response.isBusiness ? 'business' : 'individual',
                  'firstName': response.firstName,
                  'lastName': response.lastName,
                  'businessName': response.businessName,
                  'address': response.address,
                  'zip': response.zip,
                  'city': response.city,
                  'county': response.county,
                  'state': response.state,
                  'sourceType': response.source,
                  'name': response.sourceData?.name,
                  'url': response.sourceData?.auctionUrl,
                  'auctionStartTime': local,
                  'auctionDate': response.sourceData?.date,
                  'location': response.shippingFrom,
                }
              );
            }
          }
        }
      }));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.vehicleID]);

  const handleUpdateTable = (items) => {
    let adjusters = {
      applied_adjusters: [],
      unapplied_adjusters: [],
    };
    items.map((item) => {
      if (item.checked) {
        adjusters.applied_adjusters.push(item.key);
      } else {
        adjusters.unapplied_adjusters.push(item.key);
      }
    });
    dispatch<any>(handlePutAdjusterList({
      id: shopVehicleList.id,
      reportType: 'blackbook',
      data: adjusters,
      callback: (list) => {
        adjustedData(list);
        setAdjustedDataUpdated(true);
      }
    }));
    // setcustomAdjustTable(true);
    setisCustomizedAdjustmentsDialog(false);
  }

  const rowClassName = (node) => {
    return { 'childRow': (node.key && node.key.includes('-')) };
  }

  const routeUrl = (url: any) => {
    if (url) {
      window.open(url, "_blank");
    }
  }

  const dealerRouteUrl = (url: any) => {
    if (url) {
      var a = document.createElement("a");
      a.href = url;
      var baseURL = '';
      if (a.protocol) {
        baseURL = a.protocol + '//';
      }
      window.open(baseURL + a.hostname, "_blank");
    }
  }

  const dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/* <span className="currentPriceDate">{rowData.key === '0' ? 'Current' : ''} </span> */}
        {/* <span className="p-column-title" style={{ float: "left" }}>Date</span> */}
        {rowData.data.date}
      </React.Fragment>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className="shopInventory retailReportComp">

      {isCustomizedAdjustmentsDialog ?
        <AddCustomizedAdjustmentsDialog
          adjusterList={adjusterList}
          onDismiss={() => setisCustomizedAdjustmentsDialog(false)}
          handleUpdate={(items) => handleUpdateTable(items)}
        /> : null}

      <div >

        <div className=" rowCenterAlignItems tabSection qValueReport">
          <Row>
            <Col>
              <h4 className="subHeaderBG comp-vehicle-header">Comp Vehicle</h4>
            </Col>
          </Row>
          {
            isMobile ? <>
              <Row>
                <Col className="vehicleStatus">
                  <h5 className={retailVehInfo.listing_type}>{retailVehInfo.listing_type}</h5>
                  <Row>
                    <Col>
                      <strong className="blueShadedText">{retailVehInfo.vin}</strong>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <span className="greyShadedText">{retailVehInfo.model_year} {retailVehInfo.make} {retailVehInfo.model}
                        {retailVehInfo.series} {retailVehInfo.style}</span>
                    </Col>
                  </Row>
                  <Row className="retailDetails rowSpace mobile-rowSpace">
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">Odo</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0">
                      <span className="greyShadedText">{numberFormat(retailVehInfo.mileage)}</span>
                    </Col>
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">Stock</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0">
                      <span className="greyShadedText">{retailVehInfo.stock_type}</span>
                    </Col>
                  </Row>

                  <Row className="rowSpace mobile-rowSpace">
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">Dealer Type</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0">
                      <span className="greyShadedText">{retailVehInfo.dealer_type === 'F' ? 'Franchise' : 'Independent'}</span>
                    </Col>
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">CPO</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0">
                      <span className="greyShadedText">{retailVehInfo.certified ? 'Y' : 'N'}</span>
                    </Col>
                  </Row>

                  <Row className="rowSpace mobile-rowSpace">
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">Ext color</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0">
                      <span className="greyShadedText">{retailVehInfo.exterior_color_category}</span>
                    </Col>
                    <Col xs={3} className="padding-r-0" style={{ textAlign: 'right' }}>
                      <strong className="blueShadedText">Int color</strong>
                    </Col>
                    <Col xs={3} className="padding-r-0" >
                      <span className="greyShadedText">{retailVehInfo.interior_color_category}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                      <h4 className="blueShadedText" style={{ marginBottom: "0px" }}>VIN Decode</h4>
                      <div className="vinBorderSection">
                        <Row justify="center">
                          <Col>
                            <Row>
                              <Col style={{ textAlign: 'right' }}>
                                <strong className="blueShadedText">Engine</strong>
                              </Col>
                              <Col>
                                <span className="greyShadedText">{retailVehInfo.engine_from_vin}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col>
                            <Row>
                              <Col style={{ textAlign: 'right' }}>
                                <strong className="blueShadedText">Drive</strong>
                              </Col>
                              <Col>
                                <span className="greyShadedText">{retailVehInfo.drivetrain_from_vin}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col>
                            <Row>
                              <Col style={{ textAlign: 'right' }}>
                                <strong className="blueShadedText">Transmission</strong>
                              </Col>
                              <Col>
                                <span className="greyShadedText">{retailVehInfo.transmission_from_vin}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col>
                            <Row>
                              <Col style={{ textAlign: 'right' }}>
                                <strong className="blueShadedText">Fuel</strong>
                              </Col>
                              <Col>
                                <span className="greyShadedText">{retailVehInfo.fuel_type_from_vin}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>

                      <Row className="priceHistoryTable">
                        <Col>
                          <h4 className="subHeaderBG">Price History - Days on Market {retailVehInfo.days_on_market}</h4>
                          <TreeTable value={compTable} rowClassName={rowClassName} sortField="date"
                            sortOrder={-1} emptyMessage="No listings found. Increase Miles from Zip">
                            <Column field="date" header="" body={dateBodyTemplate}></Column>
                            <Column field="price" header=""></Column>
                            <Column field="odo" header=""></Column>
                          </TreeTable>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="rowSpace" >
                    <Col>
                      <Row>
                        <Col>
                          <strong className="blueShadedText">Listing</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="greyShadedText">
                            <div className="">{retailVehInfo.listing_heading}</div>
                            <div className="urlSection" onClick={() => routeUrl(retailVehInfo.listing_url)}>
                              <IconButton
                                styles={iconButtonStyles}
                                iconProps={{
                                  iconName: "OpenInNewWindow",
                                }}
                                ariaLabel="Open Listing URL"

                              /> View Listing
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <strong className="blueShadedText">Dealer</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="greyShadedText">
                            <div>{retailVehInfo.dealer_name}</div>
                            <div>{retailVehInfo.dealer_street}</div>
                            <div>{retailVehInfo.dealer_city}, {retailVehInfo.dealer_state} {retailVehInfo.dealer_zipcode}</div>
                            <div>{retailVehInfo.dealer_phone}</div>

                            <div className="urlSection" onClick={() => dealerRouteUrl(retailVehInfo.listing_url)}>
                              <IconButton
                                styles={iconButtonStyles}
                                iconProps={{
                                  iconName: "OpenInNewWindow",
                                }}
                                ariaLabel="Open Listing URL"


                              /> {retailVehInfo.dealer_url}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                  </Row>

                </Col>
              </Row>
            </> :
              <>
                <Row>
                  <Col sm={6} className="vehicleStatus">
                    <h5 className={retailVehInfo.listing_type}>{retailVehInfo.listing_type}</h5>
                    <Row>
                      <Col>
                        <strong className="blueShadedText">{retailVehInfo.vin}</strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="greyShadedText">{retailVehInfo.model_year} {retailVehInfo.make} {retailVehInfo.model}
                          {retailVehInfo.series} {retailVehInfo.style}</span>
                      </Col>
                    </Row>
                    <Row className="retailDetails rowSpace">
                      <Col sm={6}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">Odo</strong>
                          </Col>
                          <Col sm={6}>
                            <span className="greyShadedText">{numberFormat(retailVehInfo.mileage)}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={5}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">Stock</strong>
                          </Col>
                          <Col sm={6}>
                            <span className="greyShadedText">{retailVehInfo.stock_type}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="rowSpace">
                      <Col sm={6}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">Dealer Type</strong>
                          </Col>
                          <Col sm={6}>
                            <span className="greyShadedText">{retailVehInfo.dealer_type === 'F' ? 'Franchise' : 'Independent'}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={5}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">CPO</strong>
                          </Col>
                          <Col sm={6}>
                            <span className="greyShadedText">{retailVehInfo.certified ? 'Y' : 'N'}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="rowSpace">
                      <Col sm={6}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">Ext color</strong>
                          </Col>
                          <Col sm={6}>
                            <span className="greyShadedText">{retailVehInfo.exterior_color_category}</span>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={5}>
                        <Row>
                          <Col sm={6} style={{ textAlign: 'right' }}>
                            <strong className="blueShadedText">Int color</strong>
                          </Col>
                          <Col sm={6} >
                            <span className="greyShadedText">{retailVehInfo.interior_color_category}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="rowSpace" >
                      <Col sm={3} style={{ textAlign: 'right' }}>
                        <strong className="blueShadedText">Listing</strong>
                      </Col>
                      <Col sm={8}>
                        <div className="greyShadedText">
                          <div className="">{retailVehInfo.listing_heading}</div>
                          <div className="urlSection" onClick={() => routeUrl(retailVehInfo.listing_url)}>
                            <IconButton
                              styles={iconButtonStyles}
                              iconProps={{
                                iconName: "OpenInNewWindow",
                              }}
                              ariaLabel="Open Listing URL"

                            /> View Listing
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="rowSpace">
                      <Col sm={3} style={{ textAlign: 'right' }}>
                        <strong className="blueShadedText">Dealer</strong>
                      </Col>
                      <Col sm={8}>
                        <div className="greyShadedText">
                          <div>{retailVehInfo.dealer_name}</div>
                          <div>{retailVehInfo.dealer_street}</div>
                          <div>{retailVehInfo.dealer_city}, {retailVehInfo.dealer_state} {retailVehInfo.dealer_zipcode}</div>
                          <div>{retailVehInfo.dealer_phone}</div>

                          <div className="urlSection" onClick={() => dealerRouteUrl(retailVehInfo.listing_url)}>
                            <IconButton
                              styles={iconButtonStyles}
                              iconProps={{
                                iconName: "OpenInNewWindow",
                              }}
                              ariaLabel="Open Listing URL"


                            /> {retailVehInfo.dealer_url}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6}>
                    <h4 className="blueShadedText">VIN Decode</h4>
                    <div className="vinBorderSection">
                      <Row justify="center">
                        <Col sm={6}>
                          <Row>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                              <strong className="blueShadedText">Engine</strong>
                            </Col>
                            <Col sm={6} >
                              <span className="greyShadedText">{retailVehInfo.engine_from_vin}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col sm={6}>
                          <Row>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                              <strong className="blueShadedText">Drive</strong>
                            </Col>
                            <Col sm={6} >
                              <span className="greyShadedText">{retailVehInfo.drivetrain_from_vin}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col sm={6}>
                          <Row>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                              <strong className="blueShadedText">Transmission</strong>
                            </Col>
                            <Col sm={6} >
                              <span className="greyShadedText">{retailVehInfo.transmission_from_vin}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col sm={6}>
                          <Row>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                              <strong className="blueShadedText">Fuel</strong>
                            </Col>
                            <Col sm={6} >
                              <span className="greyShadedText">{retailVehInfo.fuel_type_from_vin}</span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <Row className="priceHistoryTable">
                      <Col>
                        <h4 className="subHeaderBG">Price History - Days on Market {retailVehInfo.days_on_market}</h4>
                        <TreeTable value={compTable} rowClassName={rowClassName} sortField="date"
                          sortOrder={-1} emptyMessage="No listings found. Increase Miles from Zip">
                          <Column field="date" header="" body={dateBodyTemplate}></Column>
                          <Column field="price" header=""></Column>
                          <Column field="odo" header=""></Column>
                        </TreeTable>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
          }

          <Row>
            <Col>
              <h4 className="subHeaderBG">Seller Notes</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="greyShadedText">{retailVehInfo.seller_notes}</div>
              <br />
              {/* <br/>
                <div className="greyShadedText">{retailVehInfo.standard_features}</div> */}
            </Col>
          </Row>


        </div>
      </div>
    </Stack>
  );
};
