
import React, { useState, useEffect } from 'react';
import {
    Stack,
    IStackStyles,
    TextField
} from "@fluentui/react";
import { useNavigate  } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { handleGetSoldWorkBookList } from "store/inventory/inventory.action";
import { handleGetLocations } from "store/locations/locations.action";
import 'primeicons/primeicons.css';
import { currencyNegValFormat, numberFormat } from "utils/helperFunctions";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { SoldWorkbookMoreActionsSubmenu } from "components/table/inventory/dropdown/SoldWorkbookMoreActionsSubmenu";
import { Col, Row } from "react-grid-system";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "Inventory";
export const SoldInventoryPage = () => {

    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const [credentials, setCredentials] = useState([]);
    const location = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [globalFilter, setGlobalFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    const [products, setProducts] = useState([]);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
	const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [cityDealerList, setCityDealerList] = React.useState(null);
    const yearRange = "1980:" + (new Date().getFullYear()+1);
    // const [expandedRows, setExpandedRows] = useState(null);
    const isMobileWorkbook = useMediaQuery({ maxWidth:960});

    useEffect(() => {
        dispatch<any>(handleGetSoldWorkBookList());
        dispatch<any>(handleGetLocations())
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if (user.roles?.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setLocationFilter(user.primaryLocationId);
            }
    },[user])


    const soldWorkBookList = useSelector((state: AppState) => state.inventory.soldWorkBookList);

    useEffect(() => {
        if (soldWorkBookList && soldWorkBookList.length > 0) {

            let results = soldWorkBookList.map(function (item) {
                item.soldDate = moment(item.soldDate).format('MM-DD-YYYY');
                item.mileage = item.mileage === 0 ? '' : numberFormat(item.mileage);

                item.sellingPrice = currencyNegValFormat(item.sellingPrice);
                // item.totalExpenses = item.totalExpenses && currencyNegValFormat(item.totalExpenses)
                item.totalExpenses = item.totalCost && currencyNegValFormat(item.totalCost);
                item.frontGross = item.frontGross && currencyNegValFormat(item.frontGross);
                return item;
            })
            // expandableTableRows(results);
            setProducts(results);
        } else {
            // expandableTableRows(soldWorkBookList);
            setProducts(soldWorkBookList);
        }
        handleVehicleSearch();
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [soldWorkBookList]);

    useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGlobalFilter(stateVal.searchInput);
        }
        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(new Date(stateVal?.logDateFromFilter));
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(new Date(stateVal?.logDateToFilter));
        }

        if (stateVal?.fromKeymetrics) {
            
            setLogDateFromFilter(new Date(stateVal?.filterFromDate));
            setlogDateToFilter(new Date(stateVal?.filterToDate));
        } 
    }, [location]);

    /* const expandableTableRows = (data) => {
        let _expandedRows = {};
        data.forEach(p => {
            if (p.vin && p.vin !== '') {
                _expandedRows[`${p.id}`] = true;
            }
        });
        setExpandedRows(_expandedRows);
    } */

    const handleSearchChange = (e) => {
        setGlobalFilter(e.target.value);
        //handleVehicleSearch(e.target.value);
    }

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);

    }

    const handleFromLogDateChange = (val: any) => {
		setLogDateFromFilter(val.target.value)
		
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
        
    };

    useEffect(() => {
        handleVehicleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter,logDateFromFilter,logDateToFilter ,locationFilter]);

    const handleVehicleSearch = () => {
        if(globalFilter || locationFilter || logDateFromFilter || logDateToFilter){
          let results = soldWorkBookList;
          /** Search Filter **/
          if (globalFilter && globalFilter.length > 2) {
                  
                  const lowerCaseVal = globalFilter.toLowerCase().split(" ");
              
                  lowerCaseVal.forEach(function (item) {
                      if (item !== '') {
                          results = results.filter(x => x.make.toLowerCase().includes(item) ||
                              x.model.toLowerCase().includes(item) ||
                              x.style?.toLowerCase().includes(item) ||
                              x.year.toString().toLowerCase().includes(item) ||
                              (x.vin && x.vin.toLowerCase().includes(item)));
                      }
                  });
              
                  
          }
          //else if (isActivestatus) {
          //    expandableTableRows(soldWorkBookList);
          //    setProducts(soldWorkBookList);
          
          //}

          /** location Filter **/
          
          if (locationFilter > 0) {
					results = results.filter(x => x.location?.id === locationFilter);
		  }

          /** sold Date From Filter **/
          if (logDateFromFilter) {
			  results = results.filter(x => new Date(x.soldDate) >= new Date(logDateFromFilter));
		  }

          /** sold Date To Filter **/
		  if (logDateToFilter) {
		  	let rawToLogDate = moment(new Date(logDateToFilter)).format("MM/DD/YYYY");
          
		  	results = results.filter(x =>  {
		  		let rawApprDate = moment(new Date(x.soldDate)).local().format("MM/DD/YYYY");
		  		if (new Date(rawApprDate) <= new Date(rawToLogDate)) {
		  			return true;
		  		}
		  		return false 
		  	});
		  }

          setProducts(results);


        }else {
            setProducts(soldWorkBookList);
        }




        
        
    }


    const header = (
        <>
          <div>
			<div>
				<Row>
					<Col md={10}>
						<Row>
							<Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	<TextField
			                  		value={globalFilter}
			                  		type="search"
			                  		label="Search "
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleSearchChange(e)}
			                  	/>
			                  </div>
			         	</Col>
                        <Col  md={3}>
						  <div className="dd-control">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
					    <Col md={4}>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Sold Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
			
						</Row>
					</Col>
		
				</Row>
			</div>

		</div>
        </>
    );

    const mileageBodyTemplate = (rowData) => {
        let truncatedVin = rowData.vin ? rowData.vin.substr(-8) : '';
        return (
            <React.Fragment>
            <div className="vinMobSec">
              <span className="p-column-title">Stock <span>{rowData.stockNumber}</span></span>
              <span className="p-column-title">Sold <span>{rowData.soldDate}</span></span>
            </div>
            {true ?
            <>
              <div className="vinMobSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">VIN <span>{truncatedVin}</span></span>
                <span className="p-column-title">Odo <span>{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span></span>
              </div>
            </>
            : 
            <div className="vinOdoSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">Odo</span>
                <span className="mobileDisplay">{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span>
            </div> 
            }
            <div className="vinOdoSec mobileDisplayNone">
                <span className="p-column-title">Odo</span>
                {rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}
            </div>
            </React.Fragment>
        );
    };

    const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.year}
                <span className="vinDisplayPos">{rowData.vin}</span>
            </React.Fragment>
        );
    };
    
    const stockBodyTemplate = (rowData) => {
        return (
            <React.Fragment >
                <div>
                    {rowData.stockNumber}
                </div>
            </React.Fragment>
        );
    };

    const soldDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sold</span>
                {rowData.soldDate}
            </React.Fragment>
        );
    };

    const soldPriceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sold Price</span>
                {rowData.sellingPrice}
            </React.Fragment>
        );
    };

    const expTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Expense</span>
                {rowData.totalExpenses}
            </React.Fragment>
        );
    };

    const frontGrossTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Profit</span>
                {rowData.frontGross}
            </React.Fragment>
        );
    };

    const handleEditInventory = (vehicleID) => {
        const state =
        {
            searchInput: globalFilter,
            pathfrom: '/sold',
            sortOrder,
            sortField,
            logDateFromFilter,
            logDateToFilter,
            locationFilter

        }
        navigate(`/${vehicleID}/shop-inventory`, { state });
    };

    const rowClassName = (node) => {
        return { 'vinRow': (node.vin && node.vin !== '') };
    }

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <SoldWorkbookMoreActionsSubmenu
                        modulePermissions={credentials}
                        vehicleID={rowData.id}
                        onEdit={(vehicleID) => handleEditInventory(vehicleID)}
                    />
                )}


            </React.Fragment>
        );
    };

    /* const rowExpansionTemplate = (data) => {
        if (data.vin && (data.vin !== '')) {
            return (
                <div className="vin-subtable" onClick={() => vinRowSelection(data)}>
                    <span>{data.vin}</span>
                </div>
            );
        } else {
            return false;
        }
    }; */

    /* const vinRowSelection = (row) => {
        if (row && row)
        navigate(`/${row.id}/shop-inventory`);
    } */

    // const captureCellSelection = (data) => {
    //     if (data && data?.value?.length && (data.value[0].field === 'Settings')) {
    //         data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
    //     } else if (data && data?.value?.length) {
    //         const role = isUserMaster(user.roles);
    //         const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true; 
    //         if (cred || role) {
    //             navigate({
    //                 pathname: `/${data.value[0].rowData.id}/shop-inventory`,
    //                 state: {
    //                     searchInput: globalFilter,
    //                     pathfrom: '/sold',
    //                     sortOrder,
    //                     sortField,
    //                     logDateFromFilter,
    //                     logDateToFilter,
    //                     locationFilter
    //                 }
    //             });
    //         }
    //     }
    // };
    const captureCellSelection = (event) => {
        let target = event.originalEvent.target;
        if (target?.nodeName === 'TD' && target?.hasChildNodes() && target.lastElementChild?.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {

            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true; 
            if (cred || role) {
                const  state = {
                    searchInput: globalFilter,
                    pathfrom: '/sold',
                    sortOrder,
                    sortField,
                    logDateFromFilter,
                    logDateToFilter,
                    locationFilter
                }
                navigate(`/${event.data.id}/shop-inventory`,{state});
            }
        }
    };

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
    }

    return (
        <>

            <Stack styles={container} tokens={{ childrenGap: 10 }} className="shopWorkBookPg">
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                >
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText style={{ marginLeft: "0px", marginBottom: "0px" }} className=" pageTitleFont pageName inventoryTitle fontColorChangeSold">Sold Inventory</CustomFontText>

                        </Col>
                    </Row>
                </Stack>
                <Row>
                    <Col>
                        <div className="tableColumnTitle datatable-filter-demo noMaxWidthTable shop-inventory-tbl">
                            <div className="card mouse-hover">
                                <DataTable
                                    rowClassName={rowClassName}
                                    value={products}
                                    header={header}
                                    paginator={isMobileWorkbook ? false :true }
                                    rows={isMobileWorkbook ? null :25}
                                    className="p-datatable-customers p-datatable-hoverable-rows"
                                    onSort={onSort}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    // expandedRows={expandedRows}
                                    // onRowToggle={(e) => setExpandedRows(e.data)}
                                    // rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
                                    ////cellSelection
                                    // onSelectionChange={e => captureCellSelection(e)}> 
                                    onRowClick={(event) => captureCellSelection(event)}>
                                    <Column field="stockNumber" header="Stock" body={stockBodyTemplate} sortable className="mobileDisplayNone stockCol"/>
                                    <Column field="year" header="Year" body={yearBodyTemplate} className="yrMakeModel yrOly" sortable />
                                    <Column field="make" header="Make" sortable className="yrMakeModel" />
                                    <Column field="model" header="Model" sortable className="yrMakeModel" />
                                    <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable />
                                    <Column field="soldDate" header="Sold" body={soldDateBodyTemplate} sortable className="mobileDisplayNone"/>
                                    <Column field="sellingPrice" header="Sold Price" body={soldPriceBodyTemplate} sortable />
                                    <Column field="totalExpenses" header="Exp" body={expTemplate} sortable />
                                    <Column field="frontGross" header="Profit" body={frontGrossTemplate} sortable />
                                    <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                                </DataTable>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Stack>
        </>
    );
};
