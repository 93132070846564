import React, { useEffect,useState} from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    //DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
//import { handleGetCustomers } from "store/customers/customers.action";
import { handleGetLocations } from "store/locations/locations.action";
//import { handlePutSalesFinanceList } from "store/dealTracker/dealTracker.action";
//import { useParams } from "react-router-dom";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { handleSearchDealRecord } from "store/dealTracker/dealTracker.action";


interface IProps {
    //isOpen: boolean;
    isOpen?: any;
    onDismiss: (res) => void; 
    formData?: ({ dealNumber, locationId, customerNumber }) => void;
    retainData?: any;
    primaryLocation?: any;

}


//const stackTokens = { childrenGap: 7 };

export const CreateCustomerDealDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    formData,
    retainData,
    primaryLocation
}) => {
  
    const loading = useSelector((state: AppState) => state.employees.loading);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [createDealError, setCreateDealError] = useState("");
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const dispatch = useDispatch();

    useEffect(() => {

        setCreateDealError('');
        dispatch<any>(handleGetLocations());

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (locations && locations.results) {
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }

    }, [locations]);



    const formik = useFormik({
        initialValues: {

            dealNumber: retainData ? retainData.dealNumber: "",
            locationId: retainData ? retainData.locationId: primaryLocation ? primaryLocation:"",
            customerNumber: retainData  ? retainData.customerNumber: ""
        },
        validationSchema: Yup.object({
            dealNumber:Yup.string().required("Deal Number required"),
            locationId: Yup.string().required("Location required"),         
            customerNumber: Yup.string().required("Customer No required").max(32, "Customer No must be at most 32 digits")
        }),
        onSubmit: (values) => {
            dispatch<any>(handleSearchDealRecord({
                deal: values,
                callback: (response) => {
                    if (typeof response === 'number') {
         
                        console.log('dalied res',response)

                        onDismiss(response);
                        window.scrollTo(0, 0);
                    } else if (response && response.id) {
                    

                        formData({
                                   dealNumber: formik.values.dealNumber,
                                   locationId: formik.values.locationId,
                                   customerNumber: formik.values.customerNumber, 
                               });
                        onDismiss(response);

                    } if( !response || !response.target){
                        onDismiss(response);
                        formData({
                                   dealNumber: formik.values.dealNumber,
                                   locationId: formik.values.locationId,
                                   customerNumber: formik.values.customerNumber,
                               });
                        
                    }

                }
            }));
                   
        }
    });


    const handleCreateDeal = () => {
        formik.handleSubmit()
    }


    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };
    
    const onLocationChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                locationId: e.value,
            }
        );
    }

    const handleCustomerNumberChange = (e) => {
        if (!e.target.value || !isNaN(e.target.value)) {
            formik.setValues(
                {
                    ...formik.values,
                    'customerNumber': e.target.value.trim()
                }
            );
        }
    };

    const handlechangeDealNumber = (e) => {
            formik.setValues(
                {
                    ...formik.values,
                    'dealNumber': e.target.value.trim()
                }
            );
    };

    return (
        
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord createCustomerRecordWidth  updateEmployeeDialogHeight"
        >

            <div className={contentStyles.header+" inventory-popup"}>

                <CustomFontText className="formTitle">Create Deal Record</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body +" deal-popup-body"} style={{paddingBottom:"100px"}}>
                
                
                <Stack>
                    {
                        createDealError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>{createDealError}</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                        <Row>
                             <Col md={6} className="columnBtwSpace">  
                                  <TextField
										id="dealNumber"
										name="dealNumber"
										label="Deal Number"
										placeholder=""
										autoComplete="off"
										value={formik.values.dealNumber}
										required={true}
										onChange={handlechangeDealNumber}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.dealNumber &&
                                            formik.errors && formik.errors.dealNumber &&
                                            formik.errors.dealNumber.toString()
										}
									/>                               
                             </Col>
                             <Col md={6} className="columnBtwSpace location-p-dropdown-items">
                                <label className="lbl-text required-Label">Location</label>
                                  <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={formik.values.locationId}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="location-p-dropdown-items custom-p-dropdown .customInputField "
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
                             </Col>
                        </Row>
                        <Row> 
                             <Col md={6} className="deal-cust-number columnBtwSpace" >
                                  <TextField
										id="customerNumber"
										name="customerNumber"
										label="Customer No."
										placeholder=""
										autoComplete="off"
										value={formik.values.customerNumber}
										required={true}
										onChange={handleCustomerNumberChange}
										onBlur={formik.handleBlur}
										errorMessage={
                                            formik.touched && formik.touched.customerNumber &&
                                            formik.errors && formik.errors.customerNumber &&
                                            formik.errors.customerNumber.toString()
										}
									/>                               
                             </Col> 


                             <Col md={6} className="deal-cust-number columnBtwSpace createCusFootBtn" style={{paddingTop:"30px", textAlign: "right"}}>
                                    {   
                                        retainData ?
                                          <PrimaryButton
                                             onRenderIcon={renderSpinner}
                                             text="Search"
                                             onClick={handleCreateDeal}
                                             //className="btnHoverColor"
                                         /> :
                                         <PrimaryButton
                                             disabled={!formik.dirty || !formik.isValid}
                                             onRenderIcon={renderSpinner}
                                             text="Search"
                                             onClick={handleCreateDeal}
                                             //className="btnHoverColor"
                                         /> 
                                    }
                             </Col>  
                        </Row>                        
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 480,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 80,
        paddingRight: 80,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
