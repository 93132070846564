import {
  IDeals,
  IDeal,
  ICreateDeal,
  IDealSettings,
  IDealFilters
} from "types/dealTypes";

import { IPagination } from "types/paginationTypes";

export const SET_SORT_DEALS = "SET_SORT_DEALS";

export interface SetSortDeals {
  type: typeof SET_SORT_DEALS;
  payload: string;
}

export const HANDLE_GET_DEALS = "HANDLE_GET_DEALS";

export interface HandleGetDeals {
  type: typeof HANDLE_GET_DEALS;
  payload: IPagination;
}

export const GET_DEALS_PENDING = "GET_DEALS_PENDING";

export interface GetDealsPending {
  type: typeof GET_DEALS_PENDING;
}

export const GET_DEALS_RESOLVED = "GET_DEALS_RESOLVED";

export interface GetDealsResolved {
  type: typeof GET_DEALS_RESOLVED;
  payload: IDeals;
}

export const GET_DEALS_REJECTED = "GET_DEALS_REJECTED";

export interface GetDealsRejected {
  type: typeof GET_DEALS_REJECTED;
  payload: object[];
}

export const HANDLE_CREATE_DEAL = "HANDLE_CREATE_DEAL";

export interface HandleCreateDeal {
  type: typeof HANDLE_CREATE_DEAL;
  payload: { deal: ICreateDeal; callback: Function };
}

export const CREATE_DEAL_PENDING = "CREATE_DEAL_PENDING";

export interface CreateDealPending {
  type: typeof CREATE_DEAL_PENDING;
}

export const CREATE_DEAL_RESOLVED = "CREATE_DEAL_RESOLVED";

export interface CreateDealResolved {
  type: typeof CREATE_DEAL_RESOLVED;
  payload: any;
}

export const SET_ROWS_PER_PAGE_DEALS = "SET_ROWS_PER_PAGE_DEALS";

export interface SetRowsPerPageDeals {
  type: typeof SET_ROWS_PER_PAGE_DEALS;
  payload: number;
}

/** VIEW_Deal */
export const HANDLE_VIEW_DEAL = "HANDLE_VIEW_DEAL";

export interface HandleViewDeal {
  type: typeof HANDLE_VIEW_DEAL;
  payload: string | number;
}

export const VIEW_DEAL_PENDING = "VIEW_DEAL_PENDING";

export interface ViewDealPending {
  type: typeof VIEW_DEAL_PENDING;
}

export const VIEW_DEAL_RESOLVED = "VIEW_DEAL_RESOLVED";

export interface ViewDealResolved {
  type: typeof VIEW_DEAL_RESOLVED;
  payload: IDeal;
}

export const VIEW_DEAL_REJECTED = "VIEW_DEAL_REJECTED";

export interface ViewDealRejected {
  type: typeof VIEW_DEAL_REJECTED;
  payload: object;
}

export const CREATE_DEAL_REJECTED = "CREATE_DEAL_REJECTED";

export interface CreateDealRejected {
  type: typeof CREATE_DEAL_REJECTED;
  payload: object[];
}

export const HANDLE_UPDATE_DEALS = "HANDLE_UPDATE_DEALS";

export interface HandleUpdateDeal {
  type: typeof HANDLE_UPDATE_DEALS;
  payload: { deals: IDeal[]; callback?: Function };
}

export const UPDATE_DEAL_PENDING = "UPDATE_DEAL_PENDING";

export interface UpdateDealPending {
  type: typeof UPDATE_DEAL_PENDING;
}

export const UPDATE_DEAL_RESOLVED = "UPDATE_DEAL_RESOLVED";

export interface UpdateDealResolved {
  type: typeof UPDATE_DEAL_RESOLVED;
}

export const UPDATE_DEAL_REJECTED = "UPDATE_DEAL_REJECTED";

export interface UpdateDealRejected {
  type: typeof UPDATE_DEAL_REJECTED;
  payload: object;
}

/** DELETE_Deal */
export const HANDLE_DELETE_DEALS = "HANDLE_DELETE_DEALS";

export interface HandleDeleteDeal {
  type: typeof HANDLE_DELETE_DEALS;
  payload: { ids: string[] | number[]; callback?: Function };
}

export const DELETE_DEAL_PENDING = "DELETE_DEAL_PENDING";

export interface DeleteDealPending {
  type: typeof DELETE_DEAL_PENDING;
}

export const DELETE_DEAL_RESOLVED = "DELETE_DEAL_RESOLVED";

export interface DeleteDealResolved {
  type: typeof DELETE_DEAL_RESOLVED;
}

export const DELETE_DEAL_REJECTED = "DELETE_DEAL_REJECTED";

export interface DeleteDealRejected {
  type: typeof DELETE_DEAL_REJECTED;
  payload: object;
}

/** ASSIGN SIGNER*/

export const HANDLE_DEAL_ASSIGN_SIGNER = "HANDLE_DEAL_ASSIGN_SIGNER";

export interface HandleDealAssignSigner {
  type: typeof HANDLE_DEAL_ASSIGN_SIGNER;
  payload: { dealId: number; signerId: number };
}

export const DEAL_ASSIGN_SIGNER_PENDING = "DEAL_ASSIGN_SIGNER_PENDING";

export interface DealAssignSignerPending {
  type: typeof DEAL_ASSIGN_SIGNER_PENDING;
}

export const DEAL_ASSIGN_SIGNER_RESOLVED = "DEAL_ASSIGN_SIGNER_RESOLVED";

export interface DealAssignSignerResolved {
  type: typeof DEAL_ASSIGN_SIGNER_RESOLVED;
}

export const DEAL_ASSIGN_SIGNER_REJECTED = "DEAL_ASSIGN_SIGNER_REJECTED";

export interface DealAssignSignerRejected {
  type: typeof DEAL_ASSIGN_SIGNER_REJECTED;
  payload: object;
}

/** UNASSIGN SIGNER*/

export const HANDLE_DEAL_UNASSIGN_SIGNER = "HANDLE_DEAL_UNASSIGN_SIGNER";

export interface HandleDealUnassignSigner {
  type: typeof HANDLE_DEAL_UNASSIGN_SIGNER;
  payload: { dealId: number; signerId: number };
}

export const DEAL_UNASSIGN_SIGNER_PENDING = "DEAL_UNASSIGN_SIGNER_PENDING";

export interface DealUnassignSignerPending {
  type: typeof DEAL_UNASSIGN_SIGNER_PENDING;
}

export const DEAL_UNASSIGN_SIGNER_RESOLVED = "DEAL_UNASSIGN_SIGNER_RESOLVED";

export interface DealUnassignSignerResolved {
  type: typeof DEAL_UNASSIGN_SIGNER_RESOLVED;
}

export const DEAL_UNASSIGN_SIGNER_REJECTED = "DEAL_UNASSIGN_SIGNER_REJECTED";

export interface DealUnassignSignerRejected {
  type: typeof DEAL_UNASSIGN_SIGNER_REJECTED;
  payload: object;
}

export const HANDLE_GET_DEAL_SETTINGS = "HANDLE_GET_DEAL_SETTINGS";

export interface HandleGetDealSettings {
  type: typeof HANDLE_GET_DEAL_SETTINGS;
}

export const GET_DEAL_SETTINGS_PENDING = "GET_DEAL_SETTINGS_PENDING";

export interface GetDealSettingsPending {
  type: typeof GET_DEAL_SETTINGS_PENDING;
}

export const GET_DEAL_SETTINGS_RESOLVED = "GET_DEAL_SETTINGS_RESOLVED";

export interface GetDealSettingsResolved {
  type: typeof GET_DEAL_SETTINGS_RESOLVED;
  payload: IDealSettings;
}

export const GET_DEAL_SETTINGS_REJECTED = "GET_DEAL_SETTINGS_REJECTED";

export interface GetDealSettingsRejected {
  type: typeof GET_DEAL_SETTINGS_REJECTED;
  payload: object[];
}

export const HANDLE_UPDATE_DEAL_SETTINGS = "HANDLE_UPDATE_DEAL_SETTINGS";

export interface HandleUpdateDealSettings {
  type: typeof HANDLE_UPDATE_DEAL_SETTINGS;
  payload: IDealSettings;
}

export const UPDATE_DEAL_SETTINGS_PENDING = "UPDATE_DEAL_SETTINGS_PENDING";

export interface UpdateDealSettingsPending {
  type: typeof UPDATE_DEAL_SETTINGS_PENDING;
}

export const UPDATE_DEAL_SETTINGS_RESOLVED = "UPDATE_DEAL_SETTINGS_RESOLVED";

export interface UpdateDealSettingsResolved {
  type: typeof UPDATE_DEAL_SETTINGS_RESOLVED;
  payload?: IDealSettings;
}

export const UPDATE_DEAL_SETTINGS_REJECTED = "UPDATE_DEAL_SETTINGS_REJECTED";

export interface UpdateDealSettingsRejected {
  type: typeof UPDATE_DEAL_SETTINGS_REJECTED;
  payload: object[];
}

// FILTERS
export const CLEAN_DEAL_FILTERS = "CLEAN_DEAL_FILTERS";
export interface CleanDealFilters {
  type: typeof CLEAN_DEAL_FILTERS;
}

export const SET_DEAL_FILTERS = "SET_DEAL_FILTERS";
export interface SetDealFilters {
  type: typeof SET_DEAL_FILTERS;
  payload: IDealFilters;
}

export type DealsActions =
  | HandleGetDeals
  | GetDealsPending
  | GetDealsResolved
  | GetDealsRejected
  | HandleCreateDeal
  | CreateDealPending
  | CreateDealResolved
  | CreateDealRejected
  | SetRowsPerPageDeals
  | HandleUpdateDeal
  | UpdateDealPending
  | UpdateDealResolved
  | UpdateDealRejected
  | HandleViewDeal
  | ViewDealPending
  | ViewDealResolved
  | ViewDealRejected
  | HandleDeleteDeal
  | DeleteDealPending
  | DeleteDealResolved
  | DeleteDealRejected
  | SetSortDeals
  | HandleDealAssignSigner
  | DealAssignSignerPending
  | DealAssignSignerResolved
  | DealAssignSignerRejected
  | HandleDealUnassignSigner
  | DealUnassignSignerPending
  | DealUnassignSignerResolved
  | DealUnassignSignerRejected
  | HandleGetDealSettings
  | HandleUpdateDealSettings
  | GetDealSettingsPending
  | GetDealSettingsRejected
  | GetDealSettingsResolved
  | UpdateDealSettingsPending
  | UpdateDealSettingsRejected
  | UpdateDealSettingsResolved
  | CleanDealFilters
  | SetDealFilters;
