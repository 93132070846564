import {IBrand, IBrands} from "types/brandsTypes";
import {IPagination} from "types/paginationTypes";
import * as actions from "store/brands/brands.types";
import {SET_SORT_BRANDS} from "store/brands/brands.types";

export const handleCreateBrand = ({
  brand,
  callback,
}): actions.HandleCreateBrand => {
  return {
    type: actions.HANDLE_CREATE_BRAND,
      payload: { brand, callback },
  };
};

export const pendingCreateBrand = (): actions.CreateBrandPending => {
  return {
    type: actions.CREATE_BRAND_PENDING,
  };
};

export const resolvedCreateBrand = (
  brand: IBrand
): actions.CreateBrandResolved => {
  return {
    type: actions.CREATE_BRAND_RESOLVED,
    payload: brand,
  };
};

export const rejectedCreateBrand = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.CreateBrandRejected => {
  return {
    type: actions.CREATE_BRAND_REJECTED,
    payload: errors,
  };
};

export const getBrandsForDropdown = () => {
  return {
    type: actions.GET_BRANDS_DROPDOWN
    }
}

export const pendingGetgetBrandsForDropdown = (): actions.GetBrandsDropdownPending => {
  return {
    type: actions.GET_BRANDS_DROPDOWN_PENDING,
  };
};

export const resolvedGetBrandsForDropdown = (
  brands: IBrands
): actions.GetBrandsDropdownResolved => {
  return {
    type: actions.GET_BRANDS_DROPDOWN_RESOLVED,
    payload: brands,
  };
};

export const rejectedGetBrandsForDropdown = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetBrandsDropdownRejected => {
  return {
    type: actions.GET_BRANDS_DROPDOWN_REJECTED,
  };
};



export const handleGetBrands = (request: IPagination) => {
  return {
    type: actions.HANDLE_GET_BRANDS,
    payload: request,
  };
};

export const pendingGetBrands = (): actions.GetBrandsPending => {
  return {
    type: actions.GET_BRANDS_PENDING,
  };
};

export const resolvedGetBrands = (
  brands: IBrand[]
): actions.GetBrandsResolved => {
  return {
    type: actions.GET_BRANDS_RESOLVED,
    payload: brands,
  };
};

export const rejectedGetBrands = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetBrandsRejected => {
  return {
    type: actions.GET_BRANDS_REJECTED,
    payload: errors,
  };
};
export const setRowsPerPage = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_BRANDS,
    payload: rows,
  };
};

/** UPDATE_BRAND */
export const handleUpdateBrand = ({
  brand,
  callback,
}): actions.HandleUpdateBrand => {
  
  return {
    type: actions.HANDLE_UPDATE_BRAND,
    payload: { brand, callback },
  };
};

export const pendingUpdateBrand = (): actions.UpdateBrandPending => {
  return {
    type: actions.UPDATE_BRAND_PENDING,
  };
};

export const resolvedUpdateBrand = (): actions.UpdateBrandResolved => {
  return {
    type: actions.UPDATE_BRAND_RESOLVED,
  };
};

export const rejectedUpdateBrand = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateBrandRejected => {
  return {
    type: actions.UPDATE_BRAND_REJECTED,
    payload: errors,
  };
};

/** VIEW_BRAND */
export const handleViewBrand = (
  id: number | string
): actions.HandleViewBrand => {
  return {
    type: actions.HANDLE_VIEW_BRAND,
    payload: id,
  };
};

export const pendingViewBrand = (): actions.ViewBrandPending => {
  return {
    type: actions.VIEW_BRAND_PENDING,
  };
};

export const resolvedViewBrand = (
  brand: IBrand
): actions.ViewBrandResolved => {
  return {
    type: actions.VIEW_BRAND_RESOLVED,
    payload: brand,
  };
};

export const rejectedViewBrand = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewBrandRejected => {
  return {
    type: actions.VIEW_BRAND_REJECTED,
    payload: errors,
  };
};

/** DELETE_Brand */
export const handleDeleteBrand = (id: number, callback): actions.HandleDeleteBrand => {
  return {
    type: actions.HANDLE_DELETE_BRAND,
    payload: {id: id, callback},
  };
};

export const pendingDeleteBrand = (): actions.DeleteBrandPending => {
  return {
    type: actions.DELETE_BRAND_PENDING,
  };
};

export const resolvedDeleteBrand = (): actions.DeleteBrandResolved => {
  return {
    type: actions.DELETE_BRAND_RESOLVED,
  };
};

export const rejectedDeleteBrand = (errors: object): actions.DeleteBrandRejected => {
  return {
    type: actions.DELETE_BRAND_REJECTED,
    payload: errors
  };
};

export const setSortBrands = (sort: string): actions.SetSortBrands => {
  return {
    type: SET_SORT_BRANDS,
    payload: sort
  }
}
