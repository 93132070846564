import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import {
  //getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { AppState } from "store/index";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import masterDropdownValues from "constants/masterDropdownValues";
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { handleGetSalesPendingReport,handlePrintSalesPendingReports } from "store/dealTracker/dealTracker.action";
//import { handleGetFinanceProduct} from "store/selectList/selectList.action";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";

import moment from "moment";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';

const container: IStackStyles = {
    root: {
        marginTop: 10,
        minHeight: 500
    },
};

//const credentialModuleName = "DealTracker";
export const SalesPendingReport = () => {
    const dispatch = useDispatch();
    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [commonServerError, setcommonServerError] = useState(null);
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [departmentFilter, setDepartmentFilter] = useState(0);
    const [cdrFilter, setCDRFilter] = useState(0);
    const [sfeFilter, setSFEFilter] = useState(0);
    const [fleetFilter, setFleetFilter] = useState(0);
    const [cpoFilter, setCPOFilter] = useState(0);
    const [cdaFilter, setCDAFilter] = useState(0);
    const [cdrDateFilterFrom, setCDRDateFilterFrom] = React.useState<Date | undefined>(undefined);
    const [cdrDateFilterTo, setCDRDateFilterTo] = React.useState<Date | undefined>(undefined);  
    const [departmentList, setDepartmentList] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [locationFilter, setLocationFilter] = useState(0);
    const [selectedDelCodes, setSelectedDelCodes] = useState(null);
    const [financePersonList, setFinancePersonList] = React.useState(null);
    const [managerFilter, setManagerFilter] = React.useState(0);
    const [finalData, setFinalData] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('pendingDateTime');
    const [statesFlag, setStatesFlag] = useState(false);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
    const [statePageNumber, setStatePageNumber] = useState(1);
    const [primaryLocation, setPrimaryLocation] = React.useState(null);
  
    const filterStatus = [
        { key: 2, text: 'Y' },
        { key: 1, text: 'N' },
        { key: 0, text: 'All' }
    ];

   const handlePopulateTableData = (response) => {

        for (var i in response.results) {
            const deal = response.results[i].deal;
            const customer = deal.customer;

            let substCdrDate = '';
            let substrequestDeliveryDate = '';
          
            if (response.results[i].cdrDate) {
                let rawExpenseDate = response.results[i].cdrDate;
                substCdrDate = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0, rawExpenseDate.indexOf("T")) : rawExpenseDate;
            }
            if (response.results[i].requestDeliveryDate) {
                let rawExpenseDate = response.results[i].requestDeliveryDate;
                substrequestDeliveryDate = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0, rawExpenseDate.indexOf("T")) : rawExpenseDate;
            }

            response.results[i]["pendingDateTime"] = response.results[i].statusDateTime ? moment(response.results[i].statusDateTime).format('MM/DD/YY hh:mm a') : ''; 
            response.results[i]["cdrDate"] = substCdrDate ? moment(substCdrDate).format('MM/DD') : '';
            response.results[i]["requestDeliveryDate"] = substrequestDeliveryDate ? moment(substrequestDeliveryDate).format('MM/DD') : '';
            response.results[i]["locationName"] =  deal.location ? deal.location?.legalName : '-';
            response.results[i]["dealNumber"] = deal.dealNumber;
            response.results[i]["department"] = response.results[i].department ? response.results[i].department.name : '';
            response.results[i]["lender"] = response.results[i].lender ? response.results[i].lender.name : '';
            if (deal && deal.vehicle) {
                response.results[i]["vehicleStockNumber"] = deal.vehicle.stockNumber ? deal.vehicle.stockNumber : '';
                response.results[i]["vehicle"] = deal.vehicle.year + ' ' + deal.vehicle.model;
            }
            response.results[i]["sales"] = deal && deal.salesPerson1 ? deal.salesPerson1.firstName + ' ' + deal.salesPerson1.lastName : '';
            response.results[i]["salesManager"] = deal && deal.salesPerson1 ? deal.salesPerson1.firstName + ' ' + deal.salesPerson1.lastName : '';
            
            response.results[i]["customer"] = customer.businessName ? customer.businessName : (customer.firstName + ' ' + customer.lastName);
            response.results[i]["cdr"] = status[ response.results[i].cdr];
            //response.results[i]["deposit"] = response.results[i]?.deposit;
            response.results[i]["lastDeskingNote"] = response.results[i]?.lastDeskingNote ? response.results[i]?.lastDeskingNote:"";
            response.results[i]["lastFinanceNote"] = response.results[i]?.lastFinanceNote? response.results[i]?.lastFinanceNote :"";
            response.results[i]["cpo"] = status[response.results[i].cpo];
            response.results[i]["cda"] = status[response.results[i].cda];
            response.results[i]["sfe"] = status[response.results[i].sfe];
            response.results[i]["fleet"] = status[response.results[i].fleet];
            response.results[i]["financeStatus"] = response.results[i].financeStatus ? getStatus(response.results[i].financeStatus) :" ";
            response.results[i]["deliveryCode"] = getDeliveryCode(response.results[i]?.deliveryCode);
        }
        
        setFinalData(response.results);
    };
    const getDeliveryCode = (codeId) => {
        if (codeId) {
            return masterDropdownValues.deliveryCodes.filter(x => x.key === codeId)[0].shortText;
        }
        return '';
    }

    const status = ["", "No", "Yes", "UNK"];

    const getStatus= (item)=>{
         const value = masterDropdownValues.dealFinanceStatus.find ((val )=>(
              val.key === item 
              
         ))
           return value.text
    }


   useEffect(()=>{
       setStatesFlag(true);
       //handlePopulateReports()
   },[])

    const onDepartmentChange = (e: { value: any }) => {
        setDepartmentFilter(e.value);
    }

    const onCDRChange = (e: { value: any }) => {
        setCDRFilter(e.value);
    }

    const onFleetChange = (e: { value: any }) => {
        setFleetFilter(e.value);
    }

    const onSFEChange = (e: { value: any }) => {
        setSFEFilter(e.value);
    }

    const onCPOChange = (e: { value: any }) => {
        setCPOFilter(e.value);
    }  
    const onCDAChange = (e: { value: any }) => {
        setCDAFilter(e.value);
    }

    const handleCDRDateFromChange = (val: any) => {
        setCDRDateFilterFrom(val.target.value)
    };

    const handleCDRDateToChange = (val: any) => {
        setCDRDateFilterTo(val.target.value)
    };

    const handleManagerDropdownChange = (e) => {
     setManagerFilter(e.value)
    };

    useEffect(() => {
        if (departments) {
            const departList = [...departments];
            departList.unshift({ id: 0, name: 'All' });
            setDepartmentList(departList);
        } else {
            setDepartmentList([]);
        }
    }, [departments]);



    useEffect(() => {
        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    useEffect(()=> {
            let person = [];
            if (employees.results.length > 0) {
                employees.results.map((item: any) => {
                    return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                });
                person.unshift({ key: 0 ,text: 'All'});
                setFinancePersonList(person);
            }
    }, [employees])

    useEffect(() => {
        dispatch<any>(handleGetSelectList('departments', 1));
     
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"salesPerson" } })
        dispatch<any>(handleGetLocations());
        setcommonServerError(null);
        setSortOrder(1);
        setSortField('pendingDateTime');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

    const getReportList =(isFilterApplied)=>{       
	     const formData = {
            fromCdrDate: cdrDateFilterFrom ? moment(cdrDateFilterFrom).format('YYYY-MM-DD') : '',
            toCdrDate: cdrDateFilterTo ? moment(cdrDateFilterTo).format('YYYY-MM-DD') : '',
            cdr: cdrFilter ? cdrFilter : null,
            sfe: sfeFilter ? sfeFilter : null,
            fleet: fleetFilter ? fleetFilter : null,
            cpo: cpoFilter ? cpoFilter : null,
            cda: cdaFilter ? cdaFilter : null,
            departmentId: departmentFilter ? departmentFilter : null,
            locationId: locationFilter ? locationFilter : null,
            salesPersonId :managerFilter ? managerFilter : null,
            deliveryCode: selectedDelCodes?.toString(),
            page: isFilterApplied ? statePageNumber : pages,
            limit,
            sort: sortOrder === 1 ? 'ASC' : 'DESC',
            column: sortField,
        };
            setFilters(formData);
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetSalesPendingReport({
                formData,
                callback: (response) => {
                    if (response) {
                        setTotalRecords(response.totalItems);
                        handlePopulateTableData(response);
                    } else {
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}
    

    const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	const getdata = (isFilterApplied = false) => {
        setcommonServerError('');
        if (statesFlag && userProfileLoadingFlag) {
             getReportList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getdata(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cdrDateFilterFrom,cdrDateFilterTo,cdrFilter,sfeFilter,fleetFilter,cpoFilter,cdaFilter,locationFilter,departmentFilter,managerFilter,selectedDelCodes,statesFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);

    useEffect(() => {
        if (user && user.roles) {
            setUserProfileLoadingFlag(true)
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const handleResetWorkbook = () => {
        setDepartmentFilter(0);
        setManagerFilter(0);
        setCDAFilter(0);
        setCDRFilter(0);
        setSFEFilter(0);
        setFleetFilter(0);
        setCPOFilter(0);
        setCDRDateFilterFrom(null);
        setCDRDateFilterTo(null);
        setSortField('pendingDateTime');
        setSortOrder(-1);
        setLocationFilter(primaryLocation ? primaryLocation : 0);
        setcommonServerError(null);
        setSelectedDelCodes(null);
    };

    const getNames = (person1, person2) => {
        let person1Name = '';
        let person2Name = '';
        if (person1) {
            person1Name = person1.firstName + " " + person1.lastName;
        }
        if (person2) {
            person2Name = person2.firstName + " " + person2.lastName;
        }
        return person1Name + ' ' + person2Name;
    }

   const  exportPdf = ()=>{
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handlePrintSalesPendingReports({
            formData,
            callback: (response) => {
                   if(response){
                         console.log("handlePrintPendingDeal",response)
                   }else{
                       setcommonServerError('Failed to print the pdf');
                   }
            }
        }));
    }

    const exportExcel = () => {
        
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetSalesPendingReport({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results.length) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
                                   
				                     ({
									 "Date":item.statusDateTime ? moment(item.statusDateTime).local().format('MM/DD/YY hh:mm a'):" ",
                                     'Deal Number': item.deal.dealNumber,
                                     'Customer': item.deal.customer.businessName ? item.deal.customer.businessName:(item.deal.customer.firstName + ' ' + item.deal.customer.lastName),
                                     "Stock": item.deal.vehicle?.stockNumber ? item.deal.vehicle?.stockNumber :"",
                                     "Vehicle": ((item.deal.vehicle?.year) ? item.deal.vehicle?.year : '-') + '  ' + ((item.deal.vehicle?.model) ? item.deal.vehicle?.model : ''),
                                     'Location / Dept': ((item.deal.location) ? item.deal.location.legalName : '-') + ' / ' + (item.department ? item.department.name : '-'),
                                     'Manager Sales Person 1': getNames(item.deal.salesPerson1, item.deal.salesManager), 
                                     "Req Del":item.requestDeliveryDate ? moment.utc(item?.requestDeliveryDate).format('MM/DD'):"",
                                     'CDR' :item.cdr &&  status[item?.cdr],
                                     "CDR Done": item.cdrDate ? moment(item?.cdrDate).local().format('MM/DD'):"",
                                     'SFE' :item.sfe && status[item?.sfe],
                                     'CDA' :item.cda && status[item?.cda],
                                     'CPO' :item.cpo && status[item?.cpo],
                                     'Fleet' :item.fleet && status[item?.fleet],
                                     'Delivery Code' :getDeliveryCode(item?.deliveryCode),
                                     'Fin Stat' :item?.financeStatus && getStatus(item?.financeStatus),
                                     'CB':item.deposit && item.deposit,
                                     'Sales Note':item.lastDeskingNote && item.lastDeskingNote,
                                     'Finance Note':item.lastFinanceNote && item.lastFinanceNote
      
				                   }));
		                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'Sales-pending-report');
                                   });
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const header = (
        
                     <div>
                         <div>
                             <Row>
                                 <Col md={12}>
                                     <Row>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Location</label>
                                                 <Dropdown
                                                     id="locationId"
                                                     placeholder=""
                                                     value={locationFilter}
                                                     options={cityDealerList}
                                                     onChange={onLocationChange}
                                                     optionLabel="legalName"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Department</label>
                                                 <Dropdown
                                                     id="departmentId"
                                                     placeholder=""
                                                     value={departmentFilter}
                                                     options={departmentList}
                                                     onChange={onDepartmentChange}
                                                     optionLabel="name"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                     
                                         <Col md={1.5}>
                                              <div>
                                                  <label className="lbl-text">Sales</label>
                                                  <Dropdown
                                                      id="financePerson"
                                                      value={managerFilter}
                                                      options={financePersonList}
                                                      onChange={handleManagerDropdownChange}
                                                      optionLabel="text"
                                                      optionValue="key"
                                                      appendTo="self"
                                                      className="custom-p-dropdown write-ups-location-dd"
                                                      filter={true}
                                                  />
                                              </div>
                                         </Col>
                                         <Col md={1.5}>
                                             <div>
                                                 <label className="lbl-text">CDR</label>
                                                 <Dropdown
                                                     id="cdrFilter"
                                                     placeholder=""
                                                     value={cdrFilter}
                                                     options={filterStatus}
                                                     onChange={onCDRChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         
                                         <Col md={3}>
                                             <div className="date-filter-section">
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">CDR Date</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="closeDateFilterFrom"
                                                                 style={{ width: "100%" }}
                                                                 value={cdrDateFilterFrom}
                                                                 showIcon
                                                                 onChange={(e) => handleCDRDateFromChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 maxDate={cdrDateFilterTo}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <label className="lbl-text" style={{ margin: "36px 10px 0px" }}>To</label>
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">&nbsp;</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="cdrDateFilterTo"
                                                                 style={{ width: "100%" }}
                                                                 value={cdrDateFilterTo}
                                                                 showIcon
                                                                 onChange={(e) => handleCDRDateToChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 minDate={cdrDateFilterFrom}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </Col>
                                         <Col md={3}>
                     
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col md={1.5} style={{width:"100%"}}>
                                           
                                             <div>
                                                 <label className="lbl-text">SFE</label>
                                                 <Dropdown
                                                     id="sfeFilter"
                                                     placeholder=""
                                                     value={sfeFilter}
                                                     options={filterStatus}
                                                     onChange={onSFEChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className=" custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                          </Col>
                                          <Col md={1.5}>
                                             <div>
                                                 <label className="lbl-text">Fleet</label>
                                                 <Dropdown
                                                     id="fleetFilter"
                                                     placeholder=""
                                                     value={fleetFilter}
                                                     options={filterStatus}
                                                     onChange={onFleetChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className=" custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>                                     
                                         </Col>
                                         <Col md={1.5}>
                                             <div className= "reportMultiSelect cdr-cpo-dd-filter" >
                                                 <label className="lbl-text">Delivery Code</label>
                                                 <MultiSelect
                                                     value={selectedDelCodes}
                                                     options={masterDropdownValues.deliveryCodes}
                                                     onChange={(e) => {
                                                         setSelectedDelCodes(e.value);
                                                     }}
                                                     placeholder="Select Del Code"
                                                     optionLabel="shortText"
                                                     optionValue="key"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     maxSelectedLabels={2}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}>
                                             <div >
                                                 <label className="lbl-text">CPO</label>
                                                 <Dropdown
                                                     id="cpoFilter"
                                                     placeholder=""
                                                     value={cpoFilter}
                                                     options={filterStatus}
                                                     onChange={onCPOChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         
                                         
                                         <Col md={1.5}>
                                             <div >
                                                 <label className="lbl-text">CDA</label>
                                                 <Dropdown
                                                     id="cdaFilter"
                                                     placeholder=""
                                                     value={cdaFilter}
                                                     options={filterStatus}
                                                     onChange={onCDAChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}>
                                             <div className="table-header employeeeSearchLabelText" style={{ marginTop: "30px",float:"right" }}>
                                                 <PrimaryButton onClick={handleResetWorkbook}>Reset</PrimaryButton>
                                             </div>
                                         </Col>
                                       
                                     </Row>
                                 </Col>
                                 <Col>
                                 </Col>
                             </Row>
                     
                     
                         </div> 
                         <div>
                       
                              <div style={{float:"right",marginBottom:"10px",marginTop:'-50px'}}>
                                 {/* <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                      Export to Excel
		                          </Link>*/}
                                  <Row>
                                      <Col>
                                          <PrimaryButton
                                                  onClick={exportPdf}
                                                  text="PDF"
                                                  className="tradeInCancelBtn btnHoverColor"
                                              />
                                      </Col>
                                      <Col>
                                          <PrimaryButton
                                                  onClick={exportExcel}
                                                  text="Excel"
                                                  className="tradeInCancelBtn btnHoverColor"
                                              />
                                      </Col>
                                  </Row>
                              </div>
                         </div>
                     </div>
          
    )

   

    const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                //handleEditLocation(data.value[0].rowData.id);
            }
        }
    };


    const pendingDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Date</span>
                {rowData.pendingDateTime}
            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };
    const customerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customer}
            </React.Fragment>
        );
    };
    const stockNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Stock Number</span>
                {( rowData.vehicleStockNumber)}
            </React.Fragment>
        );
    };

   const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle</span>
                {( rowData.vehicle)}
            </React.Fragment>
        );
   };

   const locationDeptBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Location / Dept</span>
                <span className="loc"> {rowData.locationName} </span> / <span className="dealDeptRowVal separateRowVal"> {rowData.department} </span>
            </React.Fragment>
        );
    };

    const salesMangerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Manager</span>
                <div style={{marginBottom:"5px"}}> {rowData.salesManager} </div>
                <div> {rowData.sales} </div>
            </React.Fragment>
        );
    };

    const  reqDeliveryTemplate  = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Req Del</span>
                {( rowData.requestDeliveryDate)}
            </React.Fragment>
        );
    };
    const cdrBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR</span>
                {( rowData.cdr)}
            </React.Fragment>
        );
    };
   const cdrDoneBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR Done</span>
                {rowData.cdrDate}
            </React.Fragment>
        );
    };

    const finStatBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Fin Stat</span>
                {rowData.financeStatus}
            </React.Fragment>
        );
    };
    const sfeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">SFE</span>
                {rowData.sfe}
            </React.Fragment>
        );
    };
    const fltBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">FLT</span>
                {( rowData.fleet)}
            </React.Fragment>
        );
    };
    const cpoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CPO</span>
                {rowData.cpo}
            </React.Fragment>
        );
    };
    const cdaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDA</span>
                {( rowData.cda)}
            </React.Fragment>
        );

    };
    const delCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Del Code</span>
                {rowData.deliveryCode}
            </React.Fragment>
        );
    };
    const depositBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deposit</span>
                { currencyNegValFractionalFormat( rowData.deposit ? rowData.deposit :"")}
            </React.Fragment>
        );

    };
    const salesNoteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Note</span>
                {( rowData.lastDeskingNote)}
            </React.Fragment>
        );
    };
    const financeNoteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Note</span>
                {( rowData.lastFinanceNote)}
            </React.Fragment>
        );
    };

     return (

    	isKarlProfileType ? <>
          <div>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>

				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							 <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Sales Pending Report </CustomFontText>
						</Col>
					</Row>
				</Stack>
                {
                        commonServerError ?
                            <Row><Col>
                                <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                            </Col> </Row> : null
                }
				<div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                    <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                         <DataTable
                             value={finalData}
                             paginator={false}
                             header={header}
                             footer={footer}
                             sortField={sortField}
                             sortOrder={sortOrder}
                             className="p-datatable-customers reportHeaderPart"
                             onSort={handleColumnSort}  
                             
                             onSelectionChange={e => captureCellSelection(e)}
                             >
                             <Column field="pendingDateTime" header="Date" body={pendingDateBodyTemplate} sortable />
                             <Column field="dealNumber" header="Deal Number"  body={dealNumberBodyTemplate} sortable />
                             <Column field="customer" header="Customer Name" body={customerNameBodyTemplate} sortable  />
                             <Column field="stockNumber" header="Stock" body={stockNumberBodyTemplate} sortable />
                             <Column field="vehicle" header="Vehicle"  body={vehicleBodyTemplate} sortable />                                            
                             <Column field="location" header="Location /Dept" body={locationDeptBodyTemplate} sortable />
                             <Column field="salesPerson" header="Sales(s)" body={salesMangerBodyTemplate} sortable />
                             <Column field="requestedDeliveryDate" header="Req Del" body={reqDeliveryTemplate} sortable  />
                             <Column field="cdr" header="CDR" body={cdrBodyTemplate} sortable />
                             <Column field="cdrDate" header="CDR Done"  body={cdrDoneBodyTemplate} sortable />  
                             <Column field="sfe" header="SFE"  body={sfeBodyTemplate} sortable />  
                             <Column field="fleet" header="Flt"  body={fltBodyTemplate} sortable />  
                             <Column field="cpo" header="CPO"  body={cpoBodyTemplate} sortable />  
                             <Column field="cda" header="CDA"  body={cdaBodyTemplate} sortable />  
                             <Column field="deliveryCode" header="Del Code"  body={delCodeBodyTemplate} sortable />  
                             <Column field="financeStatus" header="Fin Stat" body={finStatBodyTemplate} sortable />
                             <Column field="deposit" header="Deposit"  body={depositBodyTemplate} sortable />                                            
                             <Column field="lastDeskingNote" header="Sales Note" body={salesNoteBodyTemplate} sortable />
                             <Column field="lastFinanceNote" header="Finance Note" body={financeNoteBodyTemplate} sortable />

                         </DataTable>
					</div>
				</div>
			</Stack>
        </div>
		</> : null
  );
};




