import React from "react";
import { Dropdown, TextField } from "@fluentui/react";
import { dropdownStyles } from "constants/styles";
import inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const driveTypeValues = inventoryDropdownsValues.driveTypeValues.map((item) => {
  return { key: item, text: item };
});
const fuelTypeValues = inventoryDropdownsValues.fuelTypeValues.map((item) => {
  return { key: item, text: item };
});

// const fuelEconomyValues = [
//   { id: 1, name: "10 - 15 MPG" },
//   { id: 2, name: "30 - 39 MPG" },
// ].map((item) => {
//   return { key: item.name, text: item.name };
// });

const engineCylindersValues = inventoryDropdownsValues.engineCylindersValues.map(
  (item) => {
    return { key: item.value, text: item.name };
  }
);
// const engineDisplacementValues = ["1.2 L", "2.0 L", "2.4 L"].map((item) => {
//   return { key: item, value: item, text: item };
// });
const transmissionValues = inventoryDropdownsValues.transmissionValues.map(
  (item) => {
    return { key: item, value: item, text: item };
  }
);

interface IProps {
  inventory: any;
  setInventory: (inventory) => void;
  formik: any;
}
const EngineAndDrive: React.FC<IProps> = ({
  inventory,
  setInventory,
  formik,
}) => {
  const handleChange = (e, item) => {
    const value = item.key;
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: value,
      },
      true
    );

    formik.setFieldError(e.target.id, false);

    // setInventory((i) => {
    //   return { ...i, [e.target.id]: value };
    // });
  };

  return (
    <div>
      <Dropdown
        id="driveType"
        label="Drive Train"
        //selectedKey={inventory.driverType}
        selectedKey={formik.values.driveType}
        onChange={handleChange}
        placeholder="any..."
        options={driveTypeValues}
        styles={dropdownStyles}
        errorMessage={
          formik.touched.driveType && formik.errors.driveType
            ? formik.errors.driveType.toString()
            : null
        }
      />

      <Dropdown
        id="engineCylinders"
        label="Engine Cylinders"
        selectedKey={formik.values.engineCylinders}
        onChange={handleChange}
        placeholder=""
        options={engineCylindersValues}
        styles={dropdownStyles}
        errorMessage={
          formik.touched.engineCylinders && formik.errors.engineCylinders
            ? formik.errors.engineCylinders.toString()
            : null
        }
      />
      <Dropdown
        id="fuelType"
        label="Fuel Type"
        selectedKey={formik.values.fuelType}
        onChange={handleChange}
        placeholder="any..."
        options={fuelTypeValues}
        styles={dropdownStyles}
        errorMessage={
          formik.touched.fuelType && formik.errors.fuelType
            ? formik.errors.fuelType.toString()
            : null
        }
      />

      <TextField
        id="engineDescription"
        name="engineDescription"
        label="Engine Description"
        suffix="cm3"
        value={formik.values.engineDescription}
        onChange={formik.handleChange}
        errorMessage={
          formik.touched.engineDescription && formik.errors.engineDescription
            ? formik.errors.engineDescription.toString()
            : null
        }
      />

      <Dropdown
        id="transmissionDescription"
        label="Transmission"
        selectedKey={formik.values.transmissionDescription}
        onChange={handleChange}
        placeholder=""
        options={transmissionValues}
        styles={dropdownStyles}
        errorMessage={
          formik.touched.transmissionDescription &&
          formik.errors.transmissionDescription
            ? formik.errors.transmissionDescription.toString()
            : null
        }
      />
    </div>
  );
};

export default EngineAndDrive;
