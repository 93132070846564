import {IPrimarySetup} from "types/primarySetupTypes";
import * as actions from "store/primarySetup/primarySetup.types";
//import {
//    HANDLE_SAVE_PRIMARY_SETUP,
//    SAVE_PRIMARY_SETUP_PENDING,
//    SAVE_PRIMARY_SETUP_RESOLVED,
//    SAVE_PRIMARY_SETUP_REJECTED
//} from "store/primarySetup/primarySetup.types";

export const handleSavePrimarySetup = (setup: IPrimarySetup, id): actions.HandleSavePrimarySetup => {
    
    return {
      type: actions.HANDLE_SAVE_PRIMARY_SETUP,
      payload: { data: setup,userId:id},
  };
};

export const handleSaveGuidedSetupForm = (guidedSetup: any, callback): actions.HandleSaveGuidedSetupForm => {
    
  return {
    type: actions.HANDLE_SAVE_GUIDED_SETUP_FORM,
    payload: { data: guidedSetup, callback },
  };
};

export const handleVerifyEinNumber = (verifyNum: any, callback): actions.HandleVerifyEIN => {
  return {
    type: actions.HANDLE_VERIFY_EIN,
    payload: { data: verifyNum, callback}
  };
};

export const handleRequestEinAccess = (verifyNum: any, callback): actions.HandleRequestEINAccess => {
  return {
    type: actions.HANDLE_REQUEST_EIN_ACCESS,
    payload: { data: verifyNum, callback}
  };
};

export const resolvedSaveGuidedSetupForm = (): actions.SaveGuidedSetupResolved => {
  return {
      type: actions.SAVE_GUIDED_SETUP_RESOLVED,
  };
};

export const rejectedSaveGuidedSetupForm = (error: any): actions.SaveGuidedSetupRejected => {
  return {
      type: actions.SAVE_GUIDED_SETUP_REJECTED,
      error: error
  };
};

export const pendingSavePrimarySetup = (): actions.SavePrimarySetupPending => {
  return {
      type: actions.SAVE_PRIMARY_SETUP_PENDING,
  };
};

export const resolvedSavePrimarySetup = (): actions.SavePrimarySetupResolved => {
  return {
      type: actions.SAVE_PRIMARY_SETUP_RESOLVED,
  };
};

export const rejectedSavePrimarySetup = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
    }[]
): actions.SavePrimarySetupRejected => {
  return {
    type: actions.SAVE_PRIMARY_SETUP_REJECTED,
    payload: errors,
  };
};

/** guided setup changes */
export const resolvedGetGuidedSetup = (
    guidedSetupChange: any
): actions.GetGuidedSetupChanges => {
    return {
        type: actions.GET_GUIDED_SETUP_CHANGES,
        payload: guidedSetupChange,
    };
};

export const HandleCleanGuidedSetup = (): actions.HandleCleanGuidedSetup => {
    return {
        type: actions.HANDLE_CLEAN_GUIDEDSETUP
    }
};

