import { ICosigner } from "types/cosignerTypes";
import * as actions from "store/cosigners/cosigners.types";

export const handleCreateCosigner = (
  cosigner,
  callback
): actions.HandleCreateCosigner => {
  return {
    type: actions.HANDLE_CREATE_COSIGNER,
    payload: { cosigner, callback },
  };
};

export const pendingCreateCosigner = (): actions.CreateCosignerPending => {
  return {
    type: actions.CREATE_COSIGNER_PENDING,
  };
};

export const resolvedCreateCosigner = (
  cosigner: ICosigner
): actions.CreateCosignerResolved => {
  return {
    type: actions.CREATE_COSIGNER_RESOLVED,
    payload: cosigner,
  };
};

export const rejectedCreateCosigner = (
  errors
): actions.CreateCosignerRejected => {
  return {
    type: actions.CREATE_COSIGNER_REJECTED,
    payload: errors,
  };
};

export const handleEditCosigner = (
  cosigner,
  callback,
  id
): actions.HandleEditCosigner => {
  return {
    type: actions.HANDLE_EDIT_COSIGNER,
    payload: { cosigner, callback, id },
  };
};

export const pendingEditCosigner = (): actions.EditCosignerPending => {
  return {
    type: actions.EDIT_COSIGNER_PENDING,
  };
};

export const resolvedEditCosigner = (
  cosigner: ICosigner
): actions.EditCosignerResolved => {
  return {
    type: actions.EDIT_COSIGNER_RESOLVED,
    payload: cosigner,
  };
};

export const rejectedEditCosigner = (errors): actions.EditCosignerRejected => {
  return {
    type: actions.EDIT_COSIGNER_REJECTED,
    payload: errors,
  };
};
