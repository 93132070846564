import {ILocation} from "types/locationsTypes";
import {IPagination} from "types/paginationTypes";
import * as actions from "store/locations/locations.types";
import {SET_SORT_LOCATIONS} from "store/locations/locations.types";

export const handleCreateLocation = ({
  location,
  callback,
}): actions.HandleCreateLocation => {
  return {
    type: actions.HANDLE_CREATE_LOCATION,
    payload: { location, callback },
  };
};

export const pendingCreateLocation = (): actions.CreateLocationPending => {
  return {
    type: actions.CREATE_LOCATION_PENDING,
  };
};

export const resolvedCreateLocation = (
  location: ILocation
): actions.CreateLocationResolved => {
  return {
    type: actions.CREATE_LOCATION_RESOLVED,
    payload: location,
  };
};

export const rejectedCreateLocation = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.CreateLocationRejected => {
  return {
    type: actions.CREATE_LOCATION_REJECTED,
    payload: errors,
  };
};

export const getLocationsForDropdown = (request: IPagination) => {
  return {
    type: actions,
    payload: request
  }
}


export const handleGetLocations = () => {
  return {
    type: actions.HANDLE_GET_LOCATIONS
  };
};

export const HandleGetPostVacation = (id: any, method: any, data: any, callback: any): actions.HandleGetPostVacation => {
  return {
    type: actions.HANDLE_GET_POST_VACATION,
    payload: { id, method, data, callback }
  };
};

export const pendingGetLocations = (): actions.GetLocationsPending => {
  return {
    type: actions.GET_LOCATIONS_PENDING,
  };
};

export const resolvedGetLocations = (
  locations: ILocation[]
): actions.GetLocationsResolved => {
  return {
    type: actions.GET_LOCATIONS_RESOLVED,
    payload: locations,
  };
};

export const rejectedGetLocations = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetLocationsRejected => {
  return {
    type: actions.GET_LOCATIONS_REJECTED,
    payload: errors,
  };
};
export const setRowsPerPage = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_LOCATIONS,
    payload: rows,
  };
};

/** UPDATE_LOCATION */
export const handleUpdateLocation = ({
  location,
  callback,
}): actions.HandleUpdateLocation => {
  
  return {
    type: actions.HANDLE_UPDATE_LOCATION,
    payload: { location, callback },
  };
};

export const pendingUpdateLocation = (): actions.UpdateLocationPending => {
  return {
    type: actions.UPDATE_LOCATION_PENDING,
  };
};

export const resolvedUpdateLocation = (): actions.UpdateLocationResolved => {
  return {
    type: actions.UPDATE_LOCATION_RESOLVED,
  };
};

export const rejectedUpdateLocation = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateLocationRejected => {
  return {
    type: actions.UPDATE_LOCATION_REJECTED,
    payload: errors,
  };
};

/** VIEW_LOCATION */
export const handleViewLocation = (
  id: number | string
): actions.HandleViewLocation => {
  return {
    type: actions.HANDLE_VIEW_LOCATION,
    payload: id,
  };
};

export const pendingViewLocation = (): actions.ViewLocationPending => {
  return {
    type: actions.VIEW_LOCATION_PENDING,
  };
};

export const resolvedViewLocation = (
  location: ILocation
): actions.ViewLocationResolved => {
  return {
    type: actions.VIEW_LOCATION_RESOLVED,
    payload: location,
  };
};

export const rejectedViewLocation = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewLocationRejected => {
  return {
    type: actions.VIEW_LOCATION_REJECTED,
    payload: errors,
  };
};

/** DELETE_Location */
export const handleDeleteLocation = (id: string | number, callback): actions.HandleDeleteLocation => {
  return {
    type: actions.HANDLE_DELETE_LOCATION,
    payload: {id, callback},
  };
};

export const pendingDeleteLocation = (): actions.DeleteLocationPending => {
  return {
    type: actions.DELETE_LOCATION_PENDING,
  };
};

export const resolvedDeleteLocation = (): actions.DeleteLocationResolved => {
  return {
    type: actions.DELETE_LOCATION_RESOLVED,
  };
};

export const rejectedDeleteLocation = (errors: object): actions.DeleteLocationRejected => {
  return {
    type: actions.DELETE_LOCATION_REJECTED,
    payload: errors
  };
};

export const setSortLocations = (sort: string): actions.SetSortLocations => {
  return {
    type: SET_SORT_LOCATIONS,
    payload: sort
  }
}
