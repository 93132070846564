/* eslint-disable array-callback-return */
import {
  Link,
  PrimaryButton,
  Stack,
  TextField
} from "@fluentui/react";
import React, { useEffect } from "react";
//import {
//  TextField as OffTextField
//} from "@fluentui/react"; 
import { DefaultPalette } from "@fluentui/react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
//import makeStyles from '@mui/styles/makeStyles';
import { useFormik } from "formik";
import { Col, Row } from "react-grid-system";
import * as Yup from "yup";
//import { getAddreessByZip } from "services/api/smartyStreets";
//import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { AddCustomizedAdjustmentsDialog } from "components/dialogs/addInventoryDialog/AddCustomizedAdjustmentsDialog";
import { UpdateLiveRetailDialog } from "components/dialogs/addInventoryDialog/UpdateLiveRetailDialog";
import { TreeTable } from 'primereact/treetable';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useParams } from "react-router-dom";
import { AppState } from "store";
import { handleGetSourceWorkBook, handleGetVehicleUsingID, handlePutAdjusterList, handleSaveSourceWorkBook, handleVehicleLiveRetailFilters } from "store/inventory/inventory.action";
// import { DataTable } from 'primereact/datatable';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { EditShopWorkBookMoreActionSubmenu } from "components/table/inventory/EditShopWorkBookMoreActionSubmenu";
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { useMediaQuery } from "react-responsive";
import { currencyFormat, numberFormat } from "utils/helperFunctions";
import { ShopInventoryRetailReportsComp } from "./ShopInventoryRetailReportsComp";
//import CurrencyInput from 'react-currency-input-field'
//import { parseInt } from 'lodash';
import { Checkbox } from "@fluentui/react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
/* import { DataTable } from "primereact/datatable"; */
//import masterDropdownValues from "constants/masterDropdownValues";
import Box from '@mui/material/Box';
import { themeStyles } from "constants/constants";
import muiThemeStyle from "constants/muiThemeStyle";
import { classNames } from "primereact/utils";



// handleGetTrimUsingYmm

interface ParamTypes {
  vehicleID: string;
}

// const useStyles = makeStyles((theme: ThemeStyle) =>

//   createStyles({
//     root: {
//       flexGrow: 1,
//       width: '100%',
//       backgroundColor: theme?.palette?.background.paper,
//       display: 'block',
//     },
//     heading: {
//       fontSize: theme.typography?.pxToRem(15),
//     },
//     secondaryHeading: {
//       fontSize: theme.typography?.pxToRem(15),
//       color: theme.palette?.text.secondary,
//     },
//     icon: {
//       verticalAlign: 'bottom',
//       height: 20,
//       width: 20,
//     },
//     details: {
//       alignItems: 'center',
//     },
//     column: {
//       flexBasis: '33.33%',
//     },
//     helper: {
//       borderLeft: `2px solid ${theme.palette?.divider}`,
//       padding: theme?.spacing?.(1, 2),
//     },
//     link: {
//       color: theme.palette?.primary.main,
//       textDecoration: 'none',
//       '&:hover': {
//         textDecoration: 'underline',
//       },
//     },
//     breadCrumblink: {
//       display: 'flex',
//     },
//     instructions: {
//       marginTop: theme?.spacing?.(1),
//       marginBottom: theme?.spacing?.(1),
//     },
//     breadCrumbicon: {
//       marginRight: theme?.spacing?.(0.5),
//       width: 20,
//       height: 20,
//     },
//     startTime: {
//     }
//   }),
// );

const theme = {
  heading: {
    fontSize: muiThemeStyle.typography?.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: muiThemeStyle.typography?.pxToRem(15),
    color: muiThemeStyle.palette?.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
}

interface IProps {
  reportsData: any;
  filterReportsData: any;
  reportsVehInfo: any;
  showLiveRetail: any;
  refreshData?: () => void;
  compVehicle: (items) => void;
  onSubmitLive: (formikData: any) => void;
}

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};

export const ShopInventoryRetailReports: React.FunctionComponent<IProps> = ({ reportsData, filterReportsData, reportsVehInfo, showLiveRetail, refreshData, compVehicle, onSubmitLive }) => {
  //const  classes:any  = useStyles();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes |any>();
  const navigate = useNavigate ();
  const [xltTable, setxltTable] = React.useState([]);
  const [expandedKeys, setExpandedKeys] = React.useState({});

  const [allStylesTable, setallStylesTable] = React.useState([]);
  const [mainTable, setmainTable] = React.useState([]);

  // const [showVinField, setshowVinField] = React.useState(true);
  const [isCustomizedAdjustmentsDialog, setisCustomizedAdjustmentsDialog] = React.useState(false);
  const [liveRetailUpdateDialog, setliveRetailUpdateDialog] = React.useState(false);
  const [adjustedDataUpdated, setAdjustedDataUpdated] = React.useState(false);
  const [compDetailsScreen, setcompDetailsScreen] = React.useState(false);
  const [retailVehInfo, setretailVehInfo] = React.useState(null);
  // const [trimSoldStats, settrimSoldStats] = React.useState(null);
  // const [allTrimSoldStats, setallTrimSoldStats] = React.useState(null);
  const [trimActiveStats, settrimActiveStats] = React.useState(null);
  const [allTrimActiveStats, setallTrimActiveStats] = React.useState(null);
  const [listingTable, setListingTable] = React.useState(null);
  const [allTrimStats, setallTrimStats] = React.useState(null);
  const [series, setSeries] = React.useState(null);
  const [style, setStyle] = React.useState(null);
  const [engine, setEngine] = React.useState(null);
  const [drive, setDrive] = React.useState(null);
  const [activeListCheck, setActiveListCheck] = React.useState(false);
  const [soldListCheck, setSoldListCheck] = React.useState(false);
  const [allListCheck, setAllListCheck] = React.useState(true);
  /*   const [fromOdo, setFromOdo] = React.useState(null);
    const [toOdo, setToOdo] = React.useState(null); */
  const [trimStats, settrimStats] = React.useState(null);
  const [filters, setFilter] = React.useState(null);
  const [checkValue, setCheckValue] = React.useState('all');
  const [isVal, setIsVal] = React.useState(true);

  const typeList = [
    { key: 1, text: 'active' },
    { key: 2, text: 'sold' },
    { key: 3, text: 'all' },
  ];

  const [liveRetailReportData, setliveRetailReportData] = React.useState(null);
  const [liveRetailReportDropdownData, setliveRetailReportDropdownData] = React.useState({
    seriesDrp: [],
    styleDrp: [],
    engineDrp: [],
    driveDrp: []
  });
  // const [customAdjustTable, setcustomAdjustTable] = React.useState(false);
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const shopVehicleList = useSelector((state: AppState) => state.inventory.shopVehicleList);

  //const options =[{id:1,name:"arul A"},{id:2,name:"Francis"},{id:3,name:"Ram"},{id:4,name:"hari"},{id:5,name:"ramesh"},{id:6,name:"siva"}]

  const formik = useFormik({
    initialValues: {
      searchVin: '',
      make: "",
      model: '',
      year: '',
      mileage: "",
      vin: "",
      shippingFrom: "",
      fromOdo: "",
      toOdo: ""
    },
    validationSchema: Yup.object({
      searchVin: Yup.string(),
      make: Yup.string(),
      model: Yup.string(),
      year: Yup.number(),
      mileage: Yup.string(),
      vin: Yup.string(),
      shippingFrom: Yup.string(),
      fromOdo: Yup.string(),
      toOdo: Yup.string()
    }),
    onSubmit: (values) => {
    },
  });

  const sourceVehicleformik = useFormik({
    initialValues: {
      auctionDate: '',
      auctionStartTime: '',
      url: '',
      name: '',
      location: '',
      sourceType: 'auction',
      sellerType: "business",
      firstName: "",
      lastName: "",
      businessName: "",
      address: "",
      zip: "",
      city: "",
      county: "",
      state: "",
    },
    validationSchema: Yup.object({
      sellerType: Yup.string().required("Required"),
      sourceType: Yup.string().required("Required"),
      auctionDate: Yup.string(),
      auctionStartTime: Yup.string(),
      name: Yup.string(), //.required("Required")
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      ),
      //location: Yup.string(),
      location: Yup.string().matches(/^[aA-zZ0-9\s]+$/, 'Special Characters not allowed'),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      businessName: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
      city: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
      state: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      let startTime;
      if (values.auctionStartTime) {
        let utcStart = moment(values.auctionStartTime, "HH:mm").utc();
        startTime = utcStart.format()
      }
      const sourceData: any = {
        isIndividual: (values.sellerType !== 'business'), // values.setStatus,
        isBusiness: (values.sellerType === 'business'), // values.condition,
        firstName: values.firstName,
        lastName: values.lastName,
        businessName: values.businessName,
        address: values.address,
        zip: values.zip,
        city: values.city,
        county: values.county,
        state: values.state,
        phone: '',
        source: values.sourceType.toLowerCase(),
        sourceData: {
          name: values.name,
          auctionUrl: values.url,
          time: startTime,
          date: values.auctionDate
        },
        shippingFrom: values.location,
        id: params.vehicleID
      };

      dispatch<any>(handleSaveSourceWorkBook(sourceData));
    }
  });

  // Map Table data once the Reports data are modified
  useEffect(() => {
    let _expandedKeys = {};
    _expandedKeys['1'] = true;
    _expandedKeys['2'] = true;

    setExpandedKeys(_expandedKeys);


    // auction table
    // if (reportsData && reportsData.auction && reportsData.auction.adjustedValue) {

    if (listingTable) {
      let tableList = [];
      listingTable.map((data, index) => {
        let obj = {
          "key": index.toString(),
          "data": {
            "trim": data.series,
            "list": currencyFormat(data.price),
            "dealerName": data.dealer_name,
            "style": data.style,
            "engine": data.engine_from_vin,
            "fuel": data.fuel_type_from_vin,
            "drive": data.drivetrain_from_vin,
            "odo": numberFormat(data.mileage),
            "daysOnMarket": data.days_on_market,
            "priceChanges": data.number_price_changes,
            "cpo": data.certified ? 'Y' : 'N',
            "intExtColor": (data.interior_color_category ? (data.interior_color_category + ' / ') : 'NA / ') + (data.exterior_color_category ?
              data.exterior_color_category : 'NA'),
            "sold": data.listing_dropoff_date ? moment(data.listing_dropoff_date).format('MM/DD') : '-',
            "website": data.listing_url,
            "actions": "",
            "listingData": data
          },
          "children": []
        };
        tableList.push(obj);
      })
      setmainTable(tableList);
    }

    // Trade-In Table
    // if (reportsData && reportsData.tradeIn && reportsData.tradeIn.adjustedValue) {
    // }
    // eslint-disable-next-line
  }, [trimStats, allTrimStats, listingTable]);

  useEffect(() => {
    if (isVal === true) {
      handleFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [series, style, engine, drive, checkValue, formik.values.fromOdo, formik.values.toOdo, isVal])

  useEffect(() => {
    if (filters) {
      formik.setValues(
        {
          ...formik.values,
          'fromOdo': filters.fromOdo ? filters.fromOdo : "",
          'toOdo': filters.toOdo ? filters.toOdo : "",

        }
      )
      if (filters.type) {
        if (filters.type === 1) {
          setCheckValue('active');
          setActiveListCheck(true);
          setSoldListCheck(false)
          setAllListCheck(false)
        } else if (filters.type === 2) {
          setCheckValue('sold');
          setSoldListCheck(true);
          setAllListCheck(false)
          setActiveListCheck(false)

        } else if (filters.type === 3) {
          setCheckValue('all');
          setAllListCheck(true);
          setActiveListCheck(false)
          setSoldListCheck(false)

        }
      }
      setSeries(filters.series)
      setStyle(filters.style)
      setEngine(filters.engine)
      setDrive(filters.drive)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [filters])



  const handleSetFilters = () => {
    let val;
    val = typeList.find((item) => (
      item.text === checkValue
    ))
    const formData = {
      type: val.key,
      fromOdo: formik.values.fromOdo ? Number(formik.values.fromOdo) : 0,
      toOdo: formik.values.toOdo ? Number(formik.values.toOdo) : 0,
      series: series ? series : [],
      style: style ? style : [],
      engine: engine ? engine : [],
      drive: drive ? drive : []
    }
    setIsVal(true)
    handleFilter();

    if (series || style || engine || drive || checkValue || formik.values.fromOdo || formik.values.toOdo) {
      dispatch<any>(handleVehicleLiveRetailFilters({
        id: params.vehicleID,
        formData,
        callback: (res) => {
          refreshData()
          console.log("retail values", res)
        }
      }))

    }
  }

  const handleFilter = () => {

    if (series || style || engine || drive || checkValue || formik.values.fromOdo || formik.values.toOdo) {
      let rawTableData = listingTable;

      if (series && series.length) {
        rawTableData = rawTableData.filter(obj => {
          return series.includes(obj.series)
        });
      }

      if (style && style.length) {
        rawTableData = rawTableData.filter(obj => {
          return style.includes(obj.style)
        });
      }

      if (engine && engine.length) {
        rawTableData = rawTableData.filter(obj => {
          return engine.includes(obj.engine_from_vin)
        });
      }

      if (drive && drive.length) {
        rawTableData = rawTableData.filter(obj => {
          return drive.includes(obj.drivetrain_from_vin)
        });
      }

      // From ODO
      if (formik.values.fromOdo && formik.values.fromOdo !== '') {
        rawTableData = rawTableData.filter(obj => {
          return (obj.mileage >= formik.values.fromOdo)
        });
      }

      // To ODO
      if (formik.values.toOdo && formik.values.toOdo !== '') {
        rawTableData = rawTableData.filter(obj => {
          return (obj.mileage <= formik.values.toOdo)
        });
      }

      let tableListRaw = [];
      rawTableData?.map((data, index) => {
        if (checkValue && (checkValue.toLowerCase() === data.listing_type?.toLowerCase() || checkValue.toLowerCase() === 'all')) {
          let obj = {
            "key": index.toString(),
            "data": {
              "trim": data.series,
              "list": currencyFormat(data.price),
              "dealerName": data.dealer_name,
              "style": data.style,
              "engine": data.engine_from_vin,
              "fuel": data.fuel_type_from_vin,
              "drive": data.drivetrain_from_vin,
              "odo": numberFormat(data.mileage),
              "daysOnMarket": data.days_on_market,
              "priceChanges": data.number_price_changes,
              "cpo": data.certified ? 'Y' : 'N',
              "intExtColor": (data.interior_color_category ? (data.interior_color_category + ' / ') : 'NA / ') + (data.exterior_color_category ?
                data.exterior_color_category : 'NA'),
              "sold": data.listing_dropoff_date ? moment(data.listing_dropoff_date).format('MM/DD') : '-',
              "website": data.listing_url,
              "actions": ""
            },
            "children": [],
            "listingData": data
          };
          tableListRaw.push(obj);
        }
      })

      setmainTable(tableListRaw);
    }
  }

  //useEffect(() => {
  //  let zipFields = sourceVehicleformik.values.zip;
  //  if (zipFields.length === 5 && !isNaN(zipFields as any)) {
  //    dispatch<any>(switchGlobalLoader(true));
  //    (getAddreessByZip(zipFields) as any).then((response) => {
  //
  //      if (response.Status) {
  //        if (sourceVehicleformik.values.city === '' &&
  //          sourceVehicleformik.values.county === '' &&
  //          sourceVehicleformik.values.state === '') {
  //          sourceVehicleformik.setValues(
  //            {
  //              ...sourceVehicleformik.values,
  //              'county': response.DefaultCounty,
  //              'city': response.DefaultCity,
  //              'state': response.State
  //            }
  //          );
  //        }
  //        // setlocationZipApiMsg("");
  //        // updateForm("State", response.State);
  //      }
  //      else {
  //        // setZipApiMsg(response.ErrorMessage);
  //        // setlocationZipApiMsg(response.ErrorMessage);
  //        sourceVehicleformik.setValues(
  //          {
  //            ...sourceVehicleformik.values,
  //            'county': "",
  //            'city': "",
  //            'state': ""
  //          }
  //        );
  //        // resetCityCountyState();
  //      }
  //      dispatch<any>(switchGlobalLoader(false));
  //    });
  //  }
  //  else {
  //    sourceVehicleformik.setValues(
  //      {
  //        ...sourceVehicleformik.values,
  //        'county': "",
  //        'city': "",
  //        'state': ""
  //      }
  //    );
  //    // setlocationZipApiMsg("");
  //  }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [sourceVehicleformik.values.zip]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (filterReportsData) {
      settrimActiveStats(filterReportsData?.filteredListings?.active_statistics);
      handleSetTrimTableData(filterReportsData);
      setallTrimActiveStats(filterReportsData?.listings?.active_statistics);
      handleSetAllStylesTableData(filterReportsData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterReportsData]);

  useEffect(() => {
    if (adjustedDataUpdated) {
      if (shopVehicleList && shopVehicleList.id) {
        /* dispatch<any>(handleGetAdjusterList({
            id: shopVehicleList.id,
            reportType: 'blackbook',
            callback: (rawList) => {
                let list = [];
                rawList.map((item) => {
                    list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
                });
                setadjusterList(list);
                if (list.length === 0) {
                    // setdisableAdjuster(true);
                }
                setAdjustedDataUpdated(false);
            }
        })); */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedDataUpdated]);

  useEffect(() => {
    if (shopVehicleList && shopVehicleList.id) {
      formik.setValues(
        {
          ...formik.values,
          'vin': shopVehicleList.vin,
          'make': shopVehicleList.make,
          'model': shopVehicleList.model,
          'year': shopVehicleList.year,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopVehicleList])

  useEffect(() => {
    if (params.vehicleID) {
      // if (!shopVehicleList) {
      dispatch<any>(handleGetVehicleUsingID({
        id: params.vehicleID,
        callback: (response) => {
          if (!response) {
            navigate(`/appraisal`);
          }
        }
      }));

      dispatch<any>(handleGetSourceWorkBook({
        id: params.vehicleID,
        callback: (response) => {
          if (response) {
            if (response.firstName && response.id) {
              let local = null;
              if (response.sourceData?.time) {

                var date = moment.utc(response.sourceData.time).format('YYYY-MM-DD HH:mm:ss');
                var stillUtc = moment.utc(date).toDate();
                local = moment(stillUtc).local().format('HH:mm');
              }

              sourceVehicleformik.setValues(
                {
                  ...sourceVehicleformik.values,
                  'sellerType': response.isBusiness ? 'business' : 'individual',
                  'firstName': response.firstName,
                  'lastName': response.lastName,
                  'businessName': response.businessName,
                  'address': response.address,
                  'zip': response.zip,
                  'city': response.city,
                  'county': response.county,
                  'state': response.state,
                  'sourceType': response.source,
                  'name': response.sourceData?.name,
                  'url': response.sourceData?.auctionUrl,
                  'auctionStartTime': local,
                  'auctionDate': response.sourceData?.date,
                  'location': response.shippingFrom,
                }
              );
            }
          }
        }
      }));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.vehicleID]);

  useEffect(() => {
    console.log('showLiveRetail', showLiveRetail);
    if (showLiveRetail && showLiveRetail.show) {
      setcompDetailsScreen(true);
    } else {
      setcompDetailsScreen(false);
    }
  }, [showLiveRetail]);

  useEffect(() => {
    if (reportsData) {
      handleRetailLiveData(reportsData);
      setFilter(reportsData.savedFilters)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsData])

  const handleSetAllStylesTableData = (reportsData) => {
    setallStylesTable([
      {
        key: "0",
        data: {
          allStyles: "Vehicles",
          price: reportsData?.listings?.active_statistics.vehicle_count,
        },
        children: []
      },
      {
        key: "1",
        data: {
          allStyles: "Price Range",
          price: currencyFormat(reportsData?.listings?.active_statistics.minimum_price) + "-" + currencyFormat(reportsData?.listings?.active_statistics.maximum_price),
        },
        children: [{
          key: `1-0`,
          data: {
            "allStyles": "Average Price",
            "price": currencyFormat(reportsData?.listings?.active_statistics.mean_price),
          }
        }
          //QD20-1965 => Remove Median Price from live retail market - D
          //,{
          // key: `1-1`,
          // data: {
          //   "allStyles": "Median Price",
          //   "price": currencyFormat(reportsData?.stats?.allTrims?.active_statistics.median_price),
          // }
          //}
        ]
      },
      {
        key: "2",
        data: {
          allStyles: "Mileage",
          price: numberFormat(reportsData?.listings?.active_statistics.minimum_mileage) + "-" + numberFormat(reportsData?.listings?.active_statistics.maximum_mileage),
        },
        children: [{
          key: `2-0`,
          data: {
            "allStyles": "Average Mileage",
            "price": numberFormat(reportsData?.listings?.active_statistics.mean_mileage),
          }
        }]
      }
    ]);
  };

  const handleSetTrimTableData = (reportsData) => {
    let filterObj = reportsData?.filteredListings?.active_statistics;
    if (allListCheck) {
      filterObj = reportsData?.filteredListings?.all_statistics;
    } else if (soldListCheck) {
      filterObj = reportsData?.filteredListings?.sold_statistics;
    }

    setxltTable([
      {
        "key": "0",
        "data": {
          "xlt": "Vehicles",
          "price": filterObj?.vehicle_count,
        },
        "children": []
      },
      {
        "key": "1",
        "data": {
          "xlt": "Price Range",
          "price": currencyFormat(filterObj?.minimum_price) + "-" + currencyFormat(filterObj?.maximum_price)
        },
        "children": [{
          "key": `1-0`,
          "data": {
            "xlt": "Average Price",
            "price": currencyFormat(filterObj?.mean_price),
          }
        }
          // QD20-1965 => Remove Median Price from live retail market - D
          //, {
          //  "key": `1-1`,
          //  "data": {
          //    "xlt": "Median Price",
          //    "price": currencyFormat(reportsData?.stats?.trim?.active_statistics.median_price),
          //  }
          //}
        ]
      },
      {
        "key": "2",
        "data": {
          "xlt": "Mileage",
          "price": numberFormat(filterObj?.minimum_mileage) + "-" + numberFormat(filterObj?.maximum_mileage),
        },
        "children": [{
          "key": `2-0`,
          "data": {
            "xlt": "Average Mileage",
            "price": numberFormat(filterObj?.mean_mileage),
          }
        }]
      }
    ]);
  };

  const handleLiveRetail = () => {
    setliveRetailUpdateDialog(true);

  }

  const handleRetailLiveData = (response) => {
    /* if (response.filteredListings) {
      // settrimSoldStats(response.stats.trim.sold_statistics);
      settrimActiveStats(response.filteredListings.active_statistics);
      handleSetTrimTableData(response);
    }
    if (response.listings) {
      // setallTrimSoldStats(response.stats.allTrims.sold_statistics);
      setallTrimActiveStats(response.listings.active_statistics);
      handleSetAllStylesTableData(response);
    } */
    // set either ACTIVE or SOLD
    settrimStats(trimActiveStats);
    setallTrimStats(allTrimActiveStats);

    console.log('response CHANGE REPORT', response);
    setliveRetailReportData({
      zip: response.zip,
      style: response.style,
      miles: response.miles,
      days: response.dayRange,
      minMileage: response.minMileage,
      maxMileage: response.maxMileage,
      id: params.vehicleID
    });
    setListingTable(response.listings);
    // set Filter dropdown data
    let filterDrp: any = {
      seriesDrp: [],
      styleDrp: [],
      engineDrp: [],
      driveDrp: []
    }

    if (response && response.filters) {
      // series
      if (response.filters.series && response.filters.series.length) {
        response.filters.series.map(data => {
          filterDrp.seriesDrp.push({
            key: data,
            name: data
          })
        });
      }

      // style
      if (response.filters.style && response.filters.style.length) {
        response.filters.style.map(data => {
          filterDrp.styleDrp.push({
            key: data,
            name: data
          })
        });
      }

      // engine
      if (response.filters.engine && response.filters.engine.length) {
        response.filters.engine.map(data => {
          filterDrp.engineDrp.push({
            key: data,
            name: data
          })
        });
      }

      // 
      if (response.filters.drive && response.filters.drive.length) {
        response.filters.drive.map(data => {
          filterDrp.driveDrp.push({
            key: data,
            name: data
          })
        });
      }
    }

    setliveRetailReportDropdownData(filterDrp)

  }

  const mainTableAction = (reportType) => {
    console.log('edit Main Table', reportType);
    setcompDetailsScreen(true);
    setretailVehInfo(reportType.listingData);
    compVehicle(true);
    window.scroll(0, 0);
  }

  const handleRowClick = (event) => {
    let target = event.originalEvent.target;
    if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
      (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
      target.lastElementChild.lastElementChild.firstElementChild.click();
    } else if (target.nodeName === 'BUTTON' || target.nodeName === 'I'
      || (target.nodeName === 'SPAN' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'SPAN')
      || (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON')) {
      if (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') {
        routeUrl(event.node.data.website);
      }
    } else {
      mainTableAction(event.node);
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="contextMenu">
          <EditShopWorkBookMoreActionSubmenu
            disableIcon={false}
            reportType={rowData}
            onEdit={(reportType) => mainTableAction(reportType)}
          />
        </div>
      </React.Fragment>
    );
  };

  const routeUrl = (url) => {
    window.open(url, "_blank")
  }

  const websiteBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <IconButton
          disabled={false}
          className="iconButton moreIcon newWindowIcon"
          iconProps={{
            iconName: "OpenInNewWindow",
          }}
          styles={buttonStyles}
          text="New Window"
          onClick={() => routeUrl(rowData.data.website)}
        // menuProps={menuProps}
        />
      </React.Fragment>
    );
  };

  // const intExtColorTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <div className="interiorColrs"> 
  //         {rowData.data.intExtColor}
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  const handleUpdateTable = (items) => {
    let adjusters = {
      applied_adjusters: [],
      unapplied_adjusters: [],
    };
    items.map((item) => {
      if (item.checked) {
        adjusters.applied_adjusters.push(item.key);
      } else {
        adjusters.unapplied_adjusters.push(item.key);
      }
    });
    dispatch<any>(handlePutAdjusterList({
      id: shopVehicleList.id,
      reportType: 'blackbook',
      data: adjusters,
      callback: (list) => {
        // adjustedData(list);
        setAdjustedDataUpdated(true);
      }
    }));
    // setcustomAdjustTable(true);
    setisCustomizedAdjustmentsDialog(false);
  }

  const rowClassName = (node) => {
    return { 'childRow': (node.key && node.key.includes('-')) };
  }

  const onSubmitUpdateQValue = (res) => {
    console.log('on Update', res)
    setliveRetailUpdateDialog(false);
    handleRetailLiveData(res);
    onSubmitLive(res);
    refreshData();
  }


  const handleOrderChange = (index) => {

    setIsVal(false)
    if (index === 1) {
      setCheckValue('active');
      setActiveListCheck(true);
      setSoldListCheck(false)
      setAllListCheck(false)
    } else if (index === 2) {
      setCheckValue('sold');
      setSoldListCheck(true);
      setAllListCheck(false)
      setActiveListCheck(false)

    } else if (index === 3) {
      setCheckValue('all');
      setAllListCheck(true);
      setActiveListCheck(false)
      setSoldListCheck(false)

    }
  };

  const handleFromOdoChange = (e) => {
    setIsVal(false)
    //setFromOdo(e.target.value);
    formik.setValues(
      {
        ...formik.values,
        fromOdo: e.target.value,
      }
    );
  }


  const handleToOdoChange = (e) => {
    setIsVal(false)
    //setToOdo(e.target.value);
    formik.setValues(
      {
        ...formik.values,
        toOdo: e.target.value,
      }
    );
  }

  const handleRemoveSeries = (val) => {
    setIsVal(false)
    const list = series?.filter(x => x !== val);
    setSeries(list);
  }

  const handleRemoveStyle = (val) => {
    setIsVal(false)
    const list = style?.filter(x => x !== val);
    setStyle(list);
  }

  const handleRemoveEngine = (val) => {
    setIsVal(false)
    const list = engine?.filter(x => x !== val);
    setEngine(list);
  }

  const handleRemoveDrive = (val) => {
    setIsVal(false)
    const list = drive?.filter(x => x !== val);
    setDrive(list);
  }

  const handleSeries = (val) => {
    setIsVal(false)
    setSeries(val.value);
  }

  const handleStyle = (val) => {
    setIsVal(false)
    setStyle(val.value);
  }

  const handleDrive = (val) => {
    setIsVal(false)
    setDrive(val.value);
  }

  const handleEngine = (val) => {

    setIsVal(false)
    setEngine(val.value);
  }

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
      'pi-plus-circle': !expanded,
      'pi-minus-circle': expanded
    });

    return (
      <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{ color: '#ffffff', backgroundColor: '#0065cb' }} tabIndex={-1} onClick={options.onClick}>
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className="  liveRetailAccordian shopInventory shopInventoryEdit shopInvLivRetail">

      {isCustomizedAdjustmentsDialog ?
        <AddCustomizedAdjustmentsDialog
          adjusterList={null}
          onDismiss={() => setisCustomizedAdjustmentsDialog(false)}
          handleUpdate={(items) => handleUpdateTable(items)}
        /> : null}

      {liveRetailUpdateDialog ?

        <UpdateLiveRetailDialog
          onSubmitFormik={(res) => onSubmitUpdateQValue(res)}
          onDismiss={() => setliveRetailUpdateDialog(false)}
          retailDetail={liveRetailReportData}
        /> : null
      }

      {compDetailsScreen ?
        <ShopInventoryRetailReportsComp reportsData={null}
          retailVehInfo={retailVehInfo} adjustedData={null} />
        :

        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
            bgcolor: 'background.paper',
            display: 'block',
          }}
        >
          <div className="liveRetailTreetableCol tabSection retailStyle" style={{ marginTop: "-40px" }}>

            {isMobile ? <>
              <Row className="readOnlyFieldRow">
                <Col xs={4}>
                  <strong className="pageTitleHeaderFont min-width-25">Odo</strong>
                  <span className="pageTitleGreyFont">{reportsVehInfo ? reportsVehInfo.mileage : null} </span>
                </Col>
                <Col xs={8}>
                  <strong className=" pageTitleHeaderFont min-width-25">Listings within </strong>
                  <span className="pageTitleGreyFont"> {liveRetailReportData ? liveRetailReportData.miles : 0} </span>
                  &nbsp;
                  <strong className=" pageTitleHeaderFont min-width-25">miles of  </strong>
                  <span className="pageTitleGreyFont"> {liveRetailReportData ? liveRetailReportData.zip : 0} </span>
                </Col>

              </Row>


              <Row className="readOnlyFieldRow">
                <Col xs={6}>
                  <strong className=" pageTitleHeaderFont min-width-25"/*style={{ minWidth: 0, marginLeft:"-15px"}}*/>Zip</strong>
                  <span className="pageTitleGreyFont">{liveRetailReportData ? liveRetailReportData.zip : null}</span>
                </Col>
                {/*<Col xs={9}>
              <strong className="pageTitleHeaderFont">Mileage Range</strong>
              <span className="pageTitleGreyFont">
              {
                    liveRetailReportData ? (liveRetailReportData.minMileage === 0 && liveRetailReportData.maxMileage === 0 ? 'All': (liveRetailReportData.minMileage === 0?'All': numberFormat(liveRetailReportData.minMileage)) +' - '+(liveRetailReportData.maxMileage === 0?'All': numberFormat(liveRetailReportData.maxMileage))):null
              }
              </span> 
            </Col>*/}
              </Row>
              <Row>
                <Col className="dd-center" >
                  <PrimaryButton
                    className="select-update-live-retail retailUpdatBut"
                    //className="updateLiveRetailBut selectOptionsButInventory"
                    // disabled={disableAdjuster}
                    text="Update Live Retail"
                    // style={{ float: "left", marginTop:"-10px",marginLeft:"15px" }}
                    onClick={() => handleLiveRetail()}
                  // onRenderIcon={renderSpinner}
                  />
                </Col>
              </Row>
              <Row>
                <Col >
                  <Accordion className="accordianTable inventory-filter-accordion" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                    >
                      <div style={themeStyles.column}>
                        <Typography component={'h3'} style={theme.heading}>Filters</Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={themeStyles.details}>
                      <Row>
                        <Col>
                          <Row>
                            <Col md={6}>
                              <Row>
                                <Col>
                                  <Checkbox checked={activeListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(1)} label="Active" />
                                </Col>
                                <Col>
                                  <Checkbox checked={soldListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(2)} label="Sold" />
                                </Col>
                                <Col>
                                  <Checkbox checked={allListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(3)} label="All" />
                                </Col>


                              </Row>
                            </Col>

                            <Col md={6}>
                              <Row>
                                <Col >
                                  <TextField
                                    className="fromOdoPad inventory-home-filter"
                                    value={formik.values.fromOdo}
                                    onChange={(e) => handleFromOdoChange(e)}
                                    onBlur={formik.handleBlur}
                                    label="Odo"
                                    id="fromOdo"
                                    name="fromOdo"

                                  />
                                </Col>
                                <Col>
                                  <TextField
                                    className="toOdoPad inventory-home-filter to-range"
                                    value={formik.values.toOdo}
                                    onChange={(e) => handleToOdoChange(e)}
                                    onBlur={formik.handleBlur}
                                    id="toYear"
                                    name="toYear"
                                    label="  "

                                  //errorMessage={toYearMsg}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col >
                              <Row>
                                <Col className="multiSelectLiveRetail">
                                  <label className="lbl-text" style={{ marginBottom: "-2px" }}>Series</label>
                                  <MultiSelect
                                    value={series}
                                    options={liveRetailReportDropdownData?.seriesDrp}
                                    filter
                                    onChange={(e) => handleSeries(e)}

                                    showSelectAll={false}

                                    virtualScrollerOptions={{ itemSize: 34 }}
                                    placeholder="Select Make"
                                    //appendTo="self"
                                    optionLabel="label"
                                    optionValue="label"
                                    className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                    fixedPlaceholder={true}
                                    resetFilterOnHide={true}
                                  />
                                  <div className="p-multiselect-selected-item">
                                    {series && series.map((item, index) => (
                                      <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                        <Link onClick={() => handleRemoveSeries(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                                <Col className="multiSelectLiveRetail">
                                  <label className="lbl-text" style={{ marginBottom: "-2px" }}>Style</label>
                                  <MultiSelect
                                    value={style}
                                    options={liveRetailReportDropdownData?.styleDrp}
                                    filter
                                    onChange={(e) => handleStyle(e)}

                                    showSelectAll={false}

                                    virtualScrollerOptions={{ itemSize: 34 }}
                                    placeholder="Select Make"
                                    //appendTo="self"
                                    optionLabel="label"
                                    optionValue="label"
                                    className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                    fixedPlaceholder={true}
                                    resetFilterOnHide={true}
                                  />
                                  <div className="p-multiselect-selected-item">
                                    {style && style.map((item, index) => (
                                      <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                        <Link onClick={() => handleRemoveStyle(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                              </Row>

                            </Col>
                            <Col >
                              <Row>
                                <Col className="multiSelectLiveRetail">
                                  <label className="lbl-text" style={{ marginBottom: "-2px" }}>Engine</label>
                                  <MultiSelect
                                    value={engine}
                                    options={liveRetailReportDropdownData?.engineDrp}
                                    filter
                                    onChange={(e) => handleDrive(e)}

                                    showSelectAll={false}

                                    virtualScrollerOptions={{ itemSize: 34 }}
                                    placeholder="Select Make"
                                    //appendTo="self"
                                    optionLabel="label"
                                    optionValue="label"
                                    className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                    fixedPlaceholder={true}
                                    resetFilterOnHide={true}
                                  />
                                  <div className="p-multiselect-selected-item">
                                    {engine && engine.map((item, index) => (
                                      <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                        <Link onClick={() => handleRemoveEngine(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                                <Col className="multiSelectLiveRetail">
                                  <label className="lbl-text" style={{ marginBottom: "-2px" }}>Drive</label>
                                  <MultiSelect
                                    value={drive}
                                    options={liveRetailReportDropdownData?.driveDrp}
                                    filter
                                    onChange={(e) => handleEngine(e)}
                                    showSelectAll={false}

                                    virtualScrollerOptions={{ itemSize: 34 }}
                                    placeholder="Select Make"
                                    //appendTo="self"
                                    optionLabel="label"
                                    optionValue="label"
                                    className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                    fixedPlaceholder={true}
                                    resetFilterOnHide={true}
                                  />
                                  <div className="p-multiselect-selected-item">
                                    {drive && drive.map((item, index) => (
                                      <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                        <Link onClick={() => handleRemoveDrive(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                      </div>
                                    ))}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PrimaryButton
                            className="select-update-live-retail retailUpdatBut"
                            text="Set Filters"
                            style={{ float: "right", marginTop: '10px' }}
                            onClick={handleSetFilters}
                          />
                        </Col>
                      </Row>
                    </AccordionDetails>


                  </Accordion>
                </Col>
              </Row>
              <Row className="maxWidthRetail">
                <Col sm={6}>
                  <div className=" tablePadSizeWidth tableReport hiddenIconsTable trimRetailTable">
                    <div className="card reportsTableVal">
                      <TreeTable value={xltTable} rowClassName={rowClassName} togglerTemplate={togglerTemplate} expandedKeys={expandedKeys}
                        onToggle={e => setExpandedKeys(e.value)} >
                        <Column field="xlt" header="Filtered Results" expander></Column>
                        <Column field="price" header=""></Column>
                      </TreeTable>
                    </div>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className=" tablePadSizeWidth tableReport hiddenIconsTable trimRetailTable">
                    <div className="card reportsTableVal">
                      <TreeTable value={allStylesTable} rowClassName={rowClassName}
                        expandedKeys={expandedKeys} togglerTemplate={togglerTemplate} onToggle={e => setExpandedKeys(e.value)}
                    /* selectionKeys={selectedNodeKey1} onSelectionChange={e => setSelectedNodeKey1(e.value)} */>
                        <Column field="allStyles" header="All Styles - Active Listings" expander></Column>
                        <Column field="price" header=""></Column>
                      </TreeTable>
                    </div>
                  </div>
                </Col>

              </Row>
              <Row>
                <Col>
                  {/*  <div className=" tablePadSizeWidth tableReport workBookReport trimListingTable  maxWidthRetail ">
                 <div className="card reportsTableVal"> */}
                  <div className="liveRetailTreetableCol tablePadSizeWidth tableReport workBookReport trimListingTable noMaxWidthTable ">
                    <div className="rowLine card reportsTableVal">

                      <TreeTable
                        className="rowLine"
                        value={mainTable}
                        onRowClick={(event) => handleRowClick(event)}
                        selectionMode="single"
                        rowClassName={rowClassName}
                        togglerTemplate={togglerTemplate}
                        paginator={false}
                        rows={6}>
                        <Column field="list" header="List" style={{ textAlign: "center" }} sortable></Column>
                        <Column field="odo" header="Odo" sortable></Column>
                        <Column field="dealerName" header="Dealer" sortable></Column>
                        <Column field="trim" header="Series" sortable></Column>
                        <Column field="style" header="Style" sortable></Column>
                        <Column field="engine" header="Engine" sortable></Column>
                        <Column field="fuel" header="Fuel" sortable></Column>
                        <Column field="drive" header="Drive" sortable></Column>
                        <Column field="daysOnMarket" header="Days on Market" sortable></Column>
                        <Column field="cpo" header="CPO" sortable></Column>
                        <Column field="sold" header="Sold" sortable></Column>
                        <Column field="website" header="Website" body={websiteBodyTemplate}></Column>

                        <Column field="actions" header="Actions" body={actionBodyTemplate}></Column>
                      </TreeTable>

                      {/*   <DataTable
                      value={mainTable}
                      onRowClick={(event) => handleRowClick(event)}
                      selectionMode="single"
                      rowClassName={rowClassName} 
                      className="rowLine"
                      paginator = {false}
                      rows={10}  > */}
                      {/* onRowDoubleClick={(e)=> generateReports(e)}> */}
                      {/* <Column expander style={{ width: '3em' }} /> */}

                      {/* <Column field="list" header="List" style={{textAlign:"center"}} sortable></Column>
                        <Column field="odo" header="Odo" sortable></Column>
                        <Column field="dealerName" header="Dealer" sortable></Column>
                        <Column field="trim" header="Series" sortable></Column>
                        <Column field="style" header="Style" sortable></Column>
                        <Column field="engine" header="Engine" sortable></Column>
                        <Column field="fuel" header="Fuel" sortable></Column>
                        <Column field="drive" header="Drive" sortable></Column>
                        <Column field="daysOnMarket" header="Days on Market" sortable></Column>
                        <Column field="cpo" header="CPO" sortable></Column>
                        <Column field="sold" header="Sold" sortable></Column>
                        <Column field="website" header="Website" body={websiteBodyTemplate} ></Column>

                        <Column field="actions" header="Actions" body={actionBodyTemplate} ></Column> */}

                      {/* <Column field="isFavorite" headerClassName="favourite" body={favoriteBodyTemplate} className="favoritesCol" sortable />
                        <Column field="year" header="Year" body={yearBodyTemplate} sortable className="yrMakeModel yrOly" />
                        <Column field="make" header="Make" sortable className="yrMakeModel" />
                        <Column field="model" header="Model" sortable className="yrMakeModel" />
                        <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable />
                        <Column field="sourcetye" header="Source" body={sourceTypeBodyTemplate} sortable className="mobileDisplayNone" />
                        <Column field="buyPrice" header="Asking Price" body={askingPriceBodyTemplate} sortable />
                        <Column field="qValue" header="Qvalue" body={qValuePriceBodyTemplate} className="qValCol" />
                        <Column field="sellPrice" header="Purchase Price" body={bidPriceBodyTemplate} sortable className="mobileDisplayNone" />
                        <Column field="dealerList" header="My List Price" body={myListPriceBodyTemplate} sortable />
                        <Column field="estimatedGross" header="Profit" body={estimatedGrossBodyTemplate} sortable />
                        <Column field="Settings" header="Actions" body={settingBodyTemplate} /> */}
                      {/*  </DataTable> */}
                    </div>
                  </div>
                </Col>
              </Row>


            </> : <>
              <div className="rowCenterAlignItems ">
                <Row>
                  <Col  >
                    <Row style={{ width: "80%" }}>
                      <Col md={6}>
                        <Row className="  readOnlyFieldRow" >
                          <Col md={12} style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                              <strong className="pageTitleHeaderFont">Odo</strong>
                              <span className="pageTitleGreyFont" style={{ fontSize: "15px" }}>{reportsVehInfo ? reportsVehInfo.mileage : null} </span>
                            </div>
                            <div style={{ width: "50%" }}>
                              <strong className="pageTitleHeaderFont" >Zip</strong>
                              <span className="pageTitleGreyFont" style={{ fontSize: "15px" }}>{liveRetailReportData ? liveRetailReportData.zip : null}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="  readOnlyFieldRow">
                          <Col style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                              <strong className="pageTitleHeaderFont">Listings within </strong>
                              <span className="pageTitleGreyFont" style={{ fontSize: "15px" }}> {liveRetailReportData ? liveRetailReportData.miles : 0} </span>
                            </div>
                            <div style={{ width: "50%" }}>
                              <strong className="pageTitleHeaderFont"> miles of </strong>
                              <span className="pageTitleGreyFont" style={{ fontSize: "15px" }}> {liveRetailReportData ? liveRetailReportData.zip : 0} </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <PrimaryButton
                          className="updateLiveRetailBut retailUpdatBut updateRetailLivButInventory"
                          // disabled={disableAdjuster}
                          text="Update Live Retail"
                          style={{ margin: "35px 0px 0px 0px" }}
                          onClick={() => handleLiveRetail()}
                        // onRenderIcon={renderSpinner}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/*<Row className=" readOnlyFieldRow" style={{ marginTop:"20px"}}>
            <Col md={6} >
              <strong className="pageTitleHeaderFont">Mileage Range</strong>
              <span className="pageTitleGreyFont" style={{fontSize:"15px"}}>
              {
                    liveRetailReportData ? (liveRetailReportData.minMileage === 0 && liveRetailReportData.maxMileage === 0 ? 'All': (liveRetailReportData.minMileage === 0?'All': numberFormat(liveRetailReportData.minMileage)) +' - '+(liveRetailReportData.maxMileage === 0?'All': numberFormat(liveRetailReportData.maxMileage))):null
              }
              </span> 
            </Col>

          </Row>*/}


                <Row>
                  <Col style={{ marginBottom: "15px" }}>
                    <Accordion className="accordianTable inventory-filter-accordion" defaultExpanded style={{ width: "70%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <div style={themeStyles.column}>
                          <Typography component={'h3'} style={theme.heading}>Filters</Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={themeStyles.details}>
                        <Row>
                          <Col>
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <Checkbox checked={activeListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(1)} label="Active" />
                                  </Col>
                                  <Col>
                                    <Checkbox checked={soldListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(2)} label="Sold" />
                                  </Col>
                                  <Col>
                                    <Checkbox checked={allListCheck} className="liveRetailCheckBox padding-0" onChange={() => handleOrderChange(3)} label="All" />
                                  </Col>


                                </Row>
                              </Col>

                              <Col md={6}>
                                <Row>
                                  <Col >
                                    <TextField
                                      className="fromOdoPad inventory-home-filter"
                                      value={formik.values.fromOdo}
                                      onChange={(e) => handleFromOdoChange(e)}
                                      onBlur={formik.handleBlur}
                                      label="Odo"
                                      id="fromOdo"
                                      name="fromOdo"

                                    />
                                  </Col>
                                  <Col>
                                    <TextField
                                      className="toOdoPad inventory-home-filter to-range"
                                      value={formik.values.toOdo}
                                      onChange={(e) => handleToOdoChange(e)}
                                      onBlur={formik.handleBlur}
                                      id="toYear"
                                      name="toYear"
                                      label="  "

                                    //errorMessage={toYearMsg}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Col style={{ marginBottom: "10px", marginTop: "5px" }}>
                                <Row>
                                  <Col className="multiSelectLiveRetail">
                                    <label className="lbl-text" style={{ marginBottom: "-2px" }}>Series</label>
                                    <MultiSelect
                                      value={series}
                                      options={liveRetailReportDropdownData?.seriesDrp}
                                      filter
                                      onChange={(e) => handleSeries(e)}
                                      showSelectAll={false}
                                      //scrollHeight="auto"
                                      //virtualScrollerOptions={{ itemSize: 34 }}
                                      placeholder="Select Series"
                                      //appendTo="self"
                                      optionLabel="name"
                                      optionValue="name"
                                      className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                      fixedPlaceholder={true}
                                    //resetFilterOnHide={true}
                                    />
                                    <div className="p-multiselect-selected-item">
                                      {series && series.map((item, index) => (
                                        <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                          <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                          <Link onClick={() => handleRemoveSeries(item)}><span className="p-multiselect-token-icon pi pi-times" style={{ fontSize: "medium" }}></span></Link>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                  <Col className="multiSelectLiveRetail">
                                    <label className="lbl-text" style={{ marginBottom: "-2px" }}>Style</label>
                                    <MultiSelect
                                      value={style}
                                      options={liveRetailReportDropdownData?.styleDrp}
                                      filter
                                      onChange={(e) => handleStyle(e)}
                                      showSelectAll={false}
                                      //virtualScrollerOptions={{ itemSize: 34 }}
                                      placeholder="Select Style"
                                      //appendTo="self"
                                      optionLabel="name"
                                      optionValue="name"
                                      className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                      fixedPlaceholder={true}
                                    //resetFilterOnHide={true}
                                    />
                                    <div className="p-multiselect-selected-item">
                                      {style && style.map((item, index) => (
                                        <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                          <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                          <Link onClick={() => handleRemoveStyle(item)}><span className="p-multiselect-token-icon pi pi-times" style={{ fontSize: "medium" }}></span></Link>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                </Row>

                              </Col>
                              <Col style={{ marginBottom: "10px", marginTop: "5px" }}>
                                <Row>
                                  <Col className="multiSelectLiveRetail">
                                    <label className="lbl-text" style={{ marginBottom: "-2px" }}>Engine</label>
                                    <MultiSelect
                                      value={engine}
                                      options={liveRetailReportDropdownData?.engineDrp}
                                      filter
                                      onChange={(e) => handleEngine(e)}
                                      showSelectAll={false}
                                      placeholder="Select Engine"
                                      //appendTo="self"
                                      optionLabel="name"
                                      optionValue="name"
                                      className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                      fixedPlaceholder={true}
                                    //resetFilterOnHide={true}
                                    />
                                    <div className="p-multiselect-selected-item">
                                      {engine && engine.map((item, index) => (
                                        <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                          <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                          <Link onClick={() => handleRemoveEngine(item)}><span className="p-multiselect-token-icon pi pi-times" style={{ fontSize: "medium" }}></span></Link>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                  <Col className="multiSelectLiveRetail">
                                    <label className="lbl-text" style={{ marginBottom: "-2px" }}>Drive</label>
                                    <MultiSelect
                                      value={drive}
                                      options={liveRetailReportDropdownData?.driveDrp}
                                      filter
                                      onChange={(e) => handleDrive(e)}
                                      showSelectAll={false}

                                      //virtualScrollerOptions={{ itemSize: 34 }}
                                      placeholder="Select Drive"
                                      //appendTo="self"
                                      optionLabel="name"
                                      optionValue="name"
                                      className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                      fixedPlaceholder={true}
                                      resetFilterOnHide={true}
                                    />
                                    <div className="p-multiselect-selected-item">
                                      {drive && drive.map((item, index) => (
                                        <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                          <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                          <Link onClick={() => handleRemoveDrive(item)}><span className="p-multiselect-token-icon pi pi-times" style={{ fontSize: "medium" }}></span></Link>
                                        </div>
                                      ))}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PrimaryButton
                              className="select-update-live-retail retailUpdatBut"
                              text="Set Filters"
                              style={{ float: "right" }}
                              onClick={handleSetFilters}
                            />
                          </Col>
                        </Row>
                      </AccordionDetails>


                    </Accordion>
                  </Col>
                </Row>
              </div>
              <div>
                <div className="rowCenterAlignItems  " >
                  <Row className="maxWidthRetail">
                    <Col sm={6}>
                      <div className=" tablePadSizeWidth  tableReport hiddenIconsTable trimRetailTable">
                        <div className="card reportsTableVal">
                          <TreeTable value={xltTable} rowClassName={rowClassName} togglerTemplate={togglerTemplate} expandedKeys={expandedKeys}
                            onToggle={e => setExpandedKeys(e.value)} >
                            <Column field="xlt" header="Filtered Results" expander></Column>
                            <Column field="price" header=""></Column>
                          </TreeTable>
                        </div>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className=" tablePadSizeWidth tableReport hiddenIconsTable trimRetailTable">
                        <div className="card reportsTableVal">
                          <TreeTable value={allStylesTable} rowClassName={rowClassName}
                            expandedKeys={expandedKeys} togglerTemplate={togglerTemplate} onToggle={e => setExpandedKeys(e.value)}
                         /* selectionKeys={selectedNodeKey1} onSelectionChange={e => setSelectedNodeKey1(e.value)} */>
                            <Column field="allStyles" header="Active Listings" expander></Column>
                            <Column field="price" header=""></Column>
                          </TreeTable>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </div>

                <div className="liveRetailTreetableCol tablePadSizeWidth tableReport workBookReport trimListingTable noMaxWidthTable ">
                  <div className="rowLine card reportsTableVal">
                    <TreeTable
                      value={mainTable}
                      onRowClick={(event) => handleRowClick(event)}
                      selectionMode="single"
                      rowClassName={rowClassName}
                      togglerTemplate={togglerTemplate}
                      className="rowLine"
                      paginator={false}
                      rows={10}>

                      <Column field="list" header="List" style={{ textAlign: "center" }} sortable></Column>
                      <Column field="odo" header="Odo" sortable></Column>
                      <Column field="dealerName" header="Dealer" sortable></Column>
                      <Column field="trim" header="Series" sortable></Column>
                      <Column field="style" header="Style" sortable></Column>
                      <Column field="engine" header="Engine" sortable></Column>
                      <Column field="fuel" header="Fuel" sortable></Column>
                      <Column field="drive" header="Drive" sortable></Column>
                      <Column field="daysOnMarket" header="Days on Market" sortable></Column>
                      <Column field="cpo" header="CPO" sortable></Column>
                      <Column field="sold" header="Sold" sortable></Column>
                      <Column field="website" header="Website" body={websiteBodyTemplate} ></Column>

                      <Column field="actions" header="Actions" body={actionBodyTemplate} ></Column>
                    </TreeTable>

                    {/* <DataTable
                      value={mainTable}
                      onRowClick={(event) => handleRowClick(event)}
                      selectionMode="single"
                      rowClassName={rowClassName} 
                      className="rowLine"
                      paginator = {false}
                      rows={10}  > */}
                    {/* onRowDoubleClick={(e)=> generateReports(e)}> */}
                    {/* <Column expander style={{ width: '3em' }} /> */}

                    {/*  <Column field="list" header="List" style={{textAlign:"center"}} sortable></Column>
                        <Column field="odo" header="Odo" sortable></Column>
                        <Column field="dealerName" header="Dealer" sortable></Column>
                        <Column field="trim" header="Series" sortable></Column>
                        <Column field="style" header="Style" sortable></Column>
                        <Column field="engine" header="Engine" sortable></Column>
                        <Column field="fuel" header="Fuel" sortable></Column>
                        <Column field="drive" header="Drive" sortable></Column>
                        <Column field="daysOnMarket" header="Days on Market" sortable></Column>
                        <Column field="cpo" header="CPO" sortable></Column>
                        <Column field="sold" header="Sold" sortable></Column>
                        <Column field="website" header="Website" body={websiteBodyTemplate} ></Column>

                        <Column field="actions" header="Actions" body={actionBodyTemplate} ></Column> */}

                    {/* <Column field="isFavorite" headerClassName="favourite" body={favoriteBodyTemplate} className="favoritesCol" sortable />
                        <Column field="year" header="Year" body={yearBodyTemplate} sortable className="yrMakeModel yrOly" />
                        <Column field="make" header="Make" sortable className="yrMakeModel" />
                        <Column field="model" header="Model" sortable className="yrMakeModel" />
                        <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable />
                        <Column field="sourcetye" header="Source" body={sourceTypeBodyTemplate} sortable className="mobileDisplayNone" />
                        <Column field="buyPrice" header="Asking Price" body={askingPriceBodyTemplate} sortable />
                        <Column field="qValue" header="Qvalue" body={qValuePriceBodyTemplate} className="qValCol" />
                        <Column field="sellPrice" header="Purchase Price" body={bidPriceBodyTemplate} sortable className="mobileDisplayNone" />
                        <Column field="dealerList" header="My List Price" body={myListPriceBodyTemplate} sortable />
                        <Column field="estimatedGross" header="Profit" body={estimatedGrossBodyTemplate} sortable />
                        <Column field="Settings" header="Actions" body={settingBodyTemplate} /> */}
                    {/* </DataTable> */}


                  </div>
                </div>
              </div>
            </>
            }

          </div>
        </Box>
      }
    </Stack>
  );
};
