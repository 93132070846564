import { IPagination } from "types/paginationTypes";
import {
  IInventory,
  IInventoryResponse,
  ICreateInventory,
  IUpdateInventory,
  IInventoryFilterResponse,
} from "types/inventoryTypes";
import { IInventoryFilters } from "types/inventoryTypes";

export const SET_SORT_INVENTORY = "SET_SORT_INVENTORY";

export interface SetSortInventory {
  type: typeof SET_SORT_INVENTORY;
  payload: string;
}

/** GET_INVENTORY_ITEM */
export const HANDLE_GET_INVENTORY = "HANDLE_GET_INVENTORY";

export interface HandleGetInventory {
  type: typeof HANDLE_GET_INVENTORY;
  payload: IPagination;
}

export const HANDLE_GET_INVENTORY_FILTER_LIST = "HANDLE_GET_INVENTORY_FILTER_LIST";

export interface HandleGetInventoryFilterList {
  type: typeof HANDLE_GET_INVENTORY_FILTER_LIST;
  payload: any;
}

export const HANDLE_GET_SHOP_WORKBOOK_LIST = "HANDLE_GET_SHOP_WORKBOOK_LIST";
export interface handleGetShopWorkBookList {
  type: typeof HANDLE_GET_SHOP_WORKBOOK_LIST;
  payload: any;
}

export const HANDLE_GET_PURCHASE_WORKBOOK_LIST = "HANDLE_GET_PURCHASE_WORKBOOK_LIST";
export interface handleGetPurchaseWorkBookList {
    type: typeof HANDLE_GET_PURCHASE_WORKBOOK_LIST;
}

export const HANDLE_GET_SOLD_WORKBOOK_LIST = "HANDLE_GET_SOLD_WORKBOOK_LIST";
export interface handleGetSoldWorkBookList {
    type: typeof HANDLE_GET_SOLD_WORKBOOK_LIST;
}

export const HANDLE_GET_TRIM_VIN = "HANDLE_GET_TRIM_VIN";
export interface handleGetTrimUsingVin {
  type: typeof HANDLE_GET_TRIM_VIN;
  payload: any;
}

export const HANDLE_GET_Vehicle_ID = "HANDLE_GET_Vehicle_ID";
export interface handleGetVehicleUsingID {
  type: typeof HANDLE_GET_Vehicle_ID;
  payload: any;
}

export const HANDLE_Pricing_Categories_ID = "HANDLE_Pricing_Categories_ID";
export interface handlePricingCategoriesID {
  type: typeof HANDLE_Pricing_Categories_ID;
  payload: any;
}

export const HANDLE_GET_Pricing_ID = "HANDLE_GET_Pricing_ID";
export interface handleGetPricingUsingID {
  type: typeof HANDLE_GET_Pricing_ID;
  payload: any;
}

export const HANDLE_SAVE_Pricing_ID = "HANDLE_SAVE_Pricing_ID";
export interface handleSavePricingUsingID {
  type: typeof HANDLE_SAVE_Pricing_ID;
  payload: any;
}

export const HANDLE_GET_QVALUE_PLOT_ID = "HANDLE_GET_QVALUE_PLOT_ID";
export interface handleGetQValuePlotUsingID {
  type: typeof HANDLE_GET_QVALUE_PLOT_ID;
  payload: any;
}

export const HANDLE_SAVE_QVALUE_NOTES_ID = "HANDLE_SAVE_QVALUE_NOTES_ID";
export interface handleSaveQValueNotesUsingID {
  type: typeof HANDLE_SAVE_QVALUE_NOTES_ID;
  payload: any;
}

export const HANDLE_GET_QVALUE_NOTES_ID = "HANDLE_GET_QVALUE_NOTES_ID";
export interface handleGetQValueNotesUsingID {
  type: typeof HANDLE_GET_QVALUE_NOTES_ID;
  payload: any;
}

export const HANDLE_SAVE_SOURCE_WORKBOOK = "HANDLE_SAVE_SOURCE_WORKBOOK";
export interface handleSaveSourceWorkBook {
  type: typeof HANDLE_SAVE_SOURCE_WORKBOOK;
  payload: any;
}

export const HANDLE_GET_SOURCE_WORKBOOK = "HANDLE_GET_SOURCE_WORKBOOK";
export interface handleGetSourceWorkBook {
  type: typeof HANDLE_GET_SOURCE_WORKBOOK;
  payload: any;
}

export const HANDLE_SAVE_VEHICLE_EXPENSES = "HANDLE_SAVE_VEHICLE_EXPENSES";
export interface handleSaveVehicleExpenses {
    type: typeof HANDLE_SAVE_VEHICLE_EXPENSES;
    payload: any;
}

export const HANDLE_GET_VEHICLE_EXPENSES = "HANDLE_GET_VEHICLE_EXPENSES";
export interface handleGetVehicleExpenses {
    type: typeof HANDLE_GET_VEHICLE_EXPENSES;
    payload: any;
}

export const HANDLE_GET_LIVE_RETAIL_REPORTS = "HANDLE_GET_LIVE_RETAIL_REPORTS";
export interface handleGetLiveRetailReports {
  type: typeof HANDLE_GET_LIVE_RETAIL_REPORTS;
  payload: any;
}

export const HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS = "HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS";
export interface handleGetFilterLiveRetailReports {
  type: typeof HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS;
  payload: any;
}

export const HANDLE_PUT_LIVE_RETAIL_REPORTS = "HANDLE_PUT_LIVE_RETAIL_REPORTS";
export interface handlePutLiveRetailReports {
  type: typeof HANDLE_PUT_LIVE_RETAIL_REPORTS;
  payload: any;
}

export const HANDLE_VEHICLE_LIVE_RETAIL_FILTERS = "HANDLE_VEHICLE_LIVE_RETAIL_FILTERS";
export interface HandleVehicleLiveRetailFilters {
  type: typeof HANDLE_VEHICLE_LIVE_RETAIL_FILTERS;
  payload: any;
}

export const HANDLE_GET_QVALUE_DROPDOWN_MASTER = "HANDLE_GET_QVALUE_DROPDOWN_MASTER";
export interface handleGetQValueDropdownMaster {
    type: typeof HANDLE_GET_QVALUE_DROPDOWN_MASTER;
    payload: any;
}

export const HANDLE_GET_QVALUATIONS = "HANDLE_GET_QVALUATIONS";
export interface handleGetQValuations {
    type: typeof HANDLE_GET_QVALUATIONS;
    payload: any;
}

export const HANDLE_SAVE_QVALUES = "HANDLE_SAVE_QVALUES";
export interface handleSaveQValues {
    type: typeof HANDLE_SAVE_QVALUES;
    payload: any;
}

export const HANDLE_GET_TRIM_YMM = "HANDLE_GET_TRIM_YMM";
export interface handleGetTrimUsingYmm {
  type: typeof HANDLE_GET_TRIM_YMM;
  payload: any;
}

export const HANDLE_GET_VEH_DETAILS = "HANDLE_GET_VEH_DETAILS";
export interface handleGetVehDetails {
  type: typeof HANDLE_GET_VEH_DETAILS;
  payload: any;
}

export const HANDLE_PUT_VEH_DETAILS = "HANDLE_PUT_VEH_DETAILS";
export interface handlePutVehDetails {
  type: typeof HANDLE_PUT_VEH_DETAILS;
  payload: any;
}

export const HANDLE_GET_VEH_DETAILS_SUCCESS = "HANDLE_GET_VEH_DETAILS_SUCCESS";
export interface handleGetVehDetailsSuccess {
  type: typeof HANDLE_GET_VEH_DETAILS_SUCCESS;
  payload: any;
}

export const HANDLE_GET_VEH_SELLER_DETAILS = "HANDLE_GET_VEH_SELLER_DETAILS";
export interface handleGetVehSellerDetails {
  type: typeof HANDLE_GET_VEH_SELLER_DETAILS;
  payload: any;
}

export const HANDLE_GET_ADJUSTER_LIST = "HANDLE_GET_ADJUSTER_LIST";
export interface handleGetAdjusterList {
  type: typeof HANDLE_GET_ADJUSTER_LIST;
  payload: any;
}

export const HANDLE_PUT_ADJUSTER_LIST = "HANDLE_PUT_ADJUSTER_LIST";
export interface handlePutAdjusterList {
  type: typeof HANDLE_PUT_ADJUSTER_LIST;
  payload: any;
}

/** Vehicle Status */
export const HANDLE_VEHICLE_STATUS = "HANDLE_VEHICLE_STATUS";

export interface HandleVehicleStatus {
    type: typeof HANDLE_VEHICLE_STATUS;
    payload: { id: string | number,status: string, callback: Function };
}

export const HANDLE_GET_SELECTED_FILTER_DATA = "HANDLE_GET_SELECTED_FILTER_DATA";

export interface HandleGetSelectedFilterData {
  type: typeof HANDLE_GET_SELECTED_FILTER_DATA;
  payload: any;
}


export const HANDLE_SAVE_INVENTORY_FILTER = "HANDLE_SAVE_INVENTORY_FILTER";

export interface HandleSaveFilterInventory {
  type: typeof HANDLE_SAVE_INVENTORY_FILTER;
  payload: any;
}

export const HANDLE_DELETE_INVENTORY_FILTER = "HANDLE_DELETE_INVENTORY_FILTER";

export interface HandleDeleteFilterInventory {
  type: typeof HANDLE_DELETE_INVENTORY_FILTER;
  payload: any;
}

export const GET_INVENTORY_PENDING = "GET_INVENTORY_PENDING";

export interface GetInventoryPending {
  type: typeof GET_INVENTORY_PENDING;
}

export const GET_INVENTORY_RESOLVED = "GET_INVENTORY_RESOLVED";

export interface GetInventoryResolved {
  type: typeof GET_INVENTORY_RESOLVED;
  payload: IInventoryResponse;
}

export const GET_INVENTORY_FILTER_RESOLVED = "GET_INVENTORY_FILTER_RESOLVED";

export interface GetInventoryFilterResolved {
  type: typeof GET_INVENTORY_FILTER_RESOLVED;
  payload: IInventoryFilterResponse;
}

export const GET_SHOP_WORKBOOK_RESOLVED = "GET_SHOP_WORKBOOK_RESOLVED";
export interface GetShopWorkBookResolved {
  type: typeof GET_SHOP_WORKBOOK_RESOLVED;
  payload: any;
}

export const GET_PURCHASE_WORKBOOK_RESOLVED = "GET_PURCHASE_WORKBOOK_RESOLVED";
export interface GetPurchaseWorkBookResolved {
    type: typeof GET_PURCHASE_WORKBOOK_RESOLVED;
    payload: any;
}

export const GET_SOLD_WORKBOOK_RESOLVED = "GET_SOLD_WORKBOOK_RESOLVED";
export interface GetSoldWorkBookResolved {
    type: typeof GET_SOLD_WORKBOOK_RESOLVED;
    payload: any;
}

export const GET_TRIM_RESOLVED = "GET_TRIM_RESOLVED";
export interface GetTrimResolved {
  type: typeof GET_TRIM_RESOLVED;
  payload: any;
}

export const GET_INVENTORY_REJECTED = "GET_INVENTORY_REJECTED";

export interface GetInventoryRejected {
  type: typeof GET_INVENTORY_REJECTED;
  payload: object[];
}

export const SAVE_INVENTORY_REJECTED = "SAVE_INVENTORY_REJECTED";

export interface SaveInventoryRejected {
  type: typeof SAVE_INVENTORY_REJECTED;
  payload: object[];
}

export const SET_ROWS_PER_PAGE_INVENTORY = "SET_ROWS_PER_PAGE_INVENTORY";
export interface SetRowsPerPage {
  type: typeof SET_ROWS_PER_PAGE_INVENTORY;
  payload: number;
}

/**============= CREATE_INVENTORY ITEM*/
export const HANDLE_CREATE_INVENTORY = "HANDLE_CREATE_INVENTORY ";

export interface HandleCreateInventory {
  type: typeof HANDLE_CREATE_INVENTORY;
  payload: { inventory: ICreateInventory; callback: () => void };
}


export const HANDLE_UPLOAD_INVENTORY = "HANDLE_UPLOAD_INVENTORY ";

export interface HandleUploadInventories {
  type: typeof HANDLE_UPLOAD_INVENTORY;
  payload: { formData: any; location: any; callback: (msg: any, isSuccess: boolean) => void };
}

export const CREATE_INVENTORY_PENDING = "CREATE_INVENTORY_PENDING";

export interface CreateInventoryPending {
  type: typeof CREATE_INVENTORY_PENDING;
}

export const CREATE_INVENTORY_RESOLVED = "CREATE_INVENTORY_RESOLVED";

export interface CreateInventoryResolved {
  type: typeof CREATE_INVENTORY_RESOLVED;
  payload: IInventory;
}

export const CREATE_INVENTORY_REJECTED = "CREATE_INVENTORY_REJECTED";

export interface CreateInventoryRejected {
  type: typeof CREATE_INVENTORY_REJECTED;
  payload: object[];
}

/** VIEW_Inventory */
export const HANDLE_VIEW_INVENTORY = "HANDLE_VIEW_INVENTORY";

export interface HandleViewInventory {
  type: typeof HANDLE_VIEW_INVENTORY;
  payload: number | string;
}

export const HANDLE_PDF_INFO_INVENTORY = "HANDLE_PDF_INFO_INVENTORY";

export interface HandlePDFInfoInventory {
  type: typeof HANDLE_PDF_INFO_INVENTORY;
  payload: number | string;
}

export const HANDLE_PDF_GUIDE_INVENTORY = "HANDLE_PDF_GUIDE_INVENTORY";

export interface HandlePDFGuideInventory {
  type: typeof HANDLE_PDF_GUIDE_INVENTORY;
  payload: number | string;
}

export const HANDLE_EXPORT_INVENTORY = "HANDLE_EXPORT_INVENTORY";

export interface HandleExportInventory {
  type: typeof HANDLE_EXPORT_INVENTORY;
  payload: any;
}

export const VIEW_INVENTORY_PENDING = "VIEW_INVENTORY_PENDING";

export interface ViewInventoryPending {
  type: typeof VIEW_INVENTORY_PENDING;
}

export const VIEW_INVENTORY_RESOLVED = "VIEW_INVENTORY_RESOLVED";

export interface ViewInventoryResolved {
  type: typeof VIEW_INVENTORY_RESOLVED;
  payload: IInventory;
}

export const VIEW_INVENTORY_REJECTED = "VIEW_INVENTORY_REJECTED";

export interface ViewInventoryRejected {
  type: typeof VIEW_INVENTORY_REJECTED;
  payload: object;
}

export const PDF_INFO_INVENTORY_REJECTED = "PDF_INFO_INVENTORY_REJECTED";

export interface PdfInfoInventoryRejected {
  type: typeof PDF_INFO_INVENTORY_REJECTED;
  payload: any;
}

export const PDF_GUIDE_INVENTORY_REJECTED = "PDF_GUIDE_INVENTORY_REJECTED";

export interface PdfGuideInventoryRejected {
  type: typeof PDF_GUIDE_INVENTORY_REJECTED;
  payload: any;
}

export const HANDLE_UPDATE_INVENTORY = "HANDLE_UPDATE_INVENTORY";

export interface HandleUpdateInventory {
  type: typeof HANDLE_UPDATE_INVENTORY;
  payload: IInventory[];
}

export const UPDATE_INVENTORY_PENDING = "UPDATE_INVENTORY_PENDING";

export interface UpdateInventoryPending {
  type: typeof UPDATE_INVENTORY_PENDING;
}

export const UPDATE_INVENTORY_RESOLVED = "UPDATE_INVENTORY_RESOLVED";

export interface UpdateInventoryResolved {
  type: typeof UPDATE_INVENTORY_RESOLVED;
}

export const UPDATE_INVENTORY_REJECTED = "UPDATE_INVENTORY_REJECTED";

export interface UpdateInventoryRejected {
  type: typeof UPDATE_INVENTORY_REJECTED;
  payload: object;
}

/** EDIT INVENTORY */
export const HANDLE_EDIT_INVENTORY = "HANDLE_EDIT_INVENTORY";

export interface HandleEditInventory {
  type: typeof HANDLE_EDIT_INVENTORY;
  payload: {
    arrayOfInventories: IUpdateInventory[];
    additionalData: {
      locationId: number | string;
      inventoryId: number | string;
      filesToRemove: string[];
      videoFilesToRemove: boolean;
    };
    callback?: () => void;
  };
}

export const EDIT_INVENTORY_PENDING = "EDIT_INVENTORY_PENDING";

export interface EditInventoryPending {
  type: typeof EDIT_INVENTORY_PENDING;
}

export const EDIT_INVENTORY_RESOLVED = "EDIT_INVENTORY_RESOLVED";

export interface EditInventoryResolved {
  type: typeof EDIT_INVENTORY_RESOLVED;
}

export const EDIT_INVENTORY_REJECTED = "EDIT_INVENTORY_REJECTED";

export interface EditInventoryRejected {
  type: typeof EDIT_INVENTORY_REJECTED;
  payload: object;
}

/** DELETE_Inventory */
export const HANDLE_DELETE_INVENTORY = "HANDLE_DELETE_INVENTORY";

export interface HandleDeleteInventory {
  type: typeof HANDLE_DELETE_INVENTORY;
  payload: { ids: string[] | number[]; callback?: () => void };
}

export const DELETE_INVENTORY_PENDING = "DELETE_INVENTORY_PENDING";

export interface DeleteInventoryPending {
  type: typeof DELETE_INVENTORY_PENDING;
}

export const DELETE_INVENTORY_RESOLVED = "DELETE_INVENTORY_RESOLVED";

export interface DeleteInventoryResolved {
  type: typeof DELETE_INVENTORY_RESOLVED;
}

export const DELETE_INVENTORY_REJECTED = "DELETE_INVENTORY_REJECTED";

export interface DeleteInventoryRejected {
  type: typeof DELETE_INVENTORY_REJECTED;
  payload: object;
}

/** Delete INVENTORY PHOTO*/
export const HANDLE_DELETE_INVENTORY_PHOTO = "HANDLE_DELETE_INVENTORY_PHOTO";

export interface HandleDeleteInventoryPhoto {
  type: typeof HANDLE_DELETE_INVENTORY_PHOTO;
  payload: { inventoryId: string | number; photo: string };
}

export const DELETE_INVENTORY_PHOTO_PENDING = "DELETE_INVENTORY_PHOTO_PENDING";

export interface DeleteInventoryPhotoPending {
  type: typeof DELETE_INVENTORY_PHOTO_PENDING;
}

export const DELETE_INVENTORY_PHOTO_RESOLVED =
  "DELETE_INVENTORY_PHOTO_RESOLVED";

export interface DeleteInventoryPhotoResolved {
  type: typeof DELETE_INVENTORY_PHOTO_RESOLVED;
  payload: any;
}

export const DELETE_INVENTORY_PHOTO_REJECTED =
  "DELETE_INVENTORY_PHOTO_REJECTED";

export interface DeleteInventoryPhotoRejected {
  type: typeof DELETE_INVENTORY_PHOTO_REJECTED;
  payload: object;
}

/** ADD INVENTORY PHOTO*/
export const HANDLE_ADD_INVENTORY_PHOTO = "HANDLE_ADD_INVENTORY_PHOTO";

export interface HandleAddInventoryPhoto {
  type: typeof HANDLE_ADD_INVENTORY_PHOTO;
  payload: { inventoryId: string | number; fileData: any };
}

export const ADD_INVENTORY_PHOTO_PENDING = "ADD_INVENTORY_PHOTO_PENDING";

export interface AddInventoryPhotoPending {
  type: typeof ADD_INVENTORY_PHOTO_PENDING;
}

export const ADD_INVENTORY_PHOTO_RESOLVED = "ADD_INVENTORY_PHOTO_RESOLVED";

export interface AddInventoryPhotoResolved {
  type: typeof ADD_INVENTORY_PHOTO_RESOLVED;
  payload: IInventory; // inventory Object
}

export const ADD_INVENTORY_PHOTO_REJECTED = "ADD_INVENTORY_PHOTO_REJECTED";

export interface AddInventoryPhotoRejected {
  type: typeof ADD_INVENTORY_PHOTO_REJECTED;
  payload: object;
}

export const HANDLE_SORT_INVENTORY_TABLE = "HANDLE_SORT_INVENTORY_TABLE";

export interface HandleSortInventoryTable {
  type: typeof HANDLE_SORT_INVENTORY_TABLE;
  payload: { sort: string; sortColumn: string };
}

/** GET DATA BY VIN*/
export const HANDLE_GET_FROM_VIN = "HANDLE_GET_FROM_VIN";

export interface HandleGetFromVin {
  type: typeof HANDLE_GET_FROM_VIN;
  payload: {
    vin: string;
    callback: (dataFromVin: any) => void;
  };
}

export const GET_FROM_VIN_PENDING = "GET_FROM_VIN_PENDING";

export interface GetFromVinPending {
  type: typeof GET_FROM_VIN_PENDING;
}

export const GET_FROM_VIN_RESOLVED = "GET_FROM_VIN_RESOLVED";

export interface GetFromVinResolved {
  type: typeof GET_FROM_VIN_RESOLVED;
}

export const GET_FROM_VIN_REJECTED = "GET_FROM_VIN_REJECTED";

export interface GetFromVinRejected {
  type: typeof GET_FROM_VIN_REJECTED;
  payload: object;
}

export const HANDLE_GET_VALUATION_INFORMATION =
  "HANDLE_GET_VALUATION_INFORMATION";
export interface HandleGetValuationInformation {
  type: typeof HANDLE_GET_VALUATION_INFORMATION;
  payload: {
    inventoryId: string;
    source: string;
  };
}

export const GET_VALUATION_INFORMATION_PENDING =
  "GET_VALUATION_INFORMATION_PENDING";

export interface GetValuationInformationPending {
  type: typeof GET_VALUATION_INFORMATION_PENDING;
  payload: string;
}

export const GET_VALUATION_INFORMATION_RESOLVED =
  "GET_VALUATION_INFORMATION_RESOLVED";

export interface GetValuationInformationResolved {
  type: typeof GET_VALUATION_INFORMATION_RESOLVED;
  payload: any;
}

export const GET_VALUATION_INFORMATION_REJECTED =
  "GET_VALUATION_INFORMATION_REJECTED";

export interface GetValuationInformationRejected {
  type: typeof GET_VALUATION_INFORMATION_REJECTED;
  payload: object;
}

export const CLEAN_VALUATION_INFORMATION = "CLEAN_VALUATION_INFORMATION";

export interface CleanValuationInformation {
  type: typeof CLEAN_VALUATION_INFORMATION;
}

// FILTERS
export const CLEAN_INVENTORY_FILTERS = "CLEAN_INVENTORY_FILTERS";
export interface CleanInventoryFilters {
  type: typeof CLEAN_INVENTORY_FILTERS;
}

// SAVE INVENTORY FILTER
export const SAVE_INVENTORY_FILTERS = "SAVE_INVENTORY_FILTERS";
export interface SaveInventoryFilters {
  type: typeof SAVE_INVENTORY_FILTERS;
  payload: IInventoryFilters;
}

// DELETE INVENTORY FILTER
export const DELETE_INVENTORY_FILTERS = "DELETE_INVENTORY_FILTERS";
export interface DeleteInventoryFilters {
  type: typeof DELETE_INVENTORY_FILTERS;
  payload: any;
}

export const SET_INVENTORY_FILTERS = "SET_INVENTORY_FILTERS";
export interface SetInventoryFilters {
  type: typeof SET_INVENTORY_FILTERS;
  payload: IInventoryFilters;
}

/** Purchase Vehicle */
export const HANDLE_PURCHASE_VEHICLE = "HANDLE_PURCHASE_VEHICLE";

export interface HandlePurchaseVehicle {
    type: typeof HANDLE_PURCHASE_VEHICLE;
    payload: { purchaseVehicleInfo: any, callback: Function };
}

/** Sold Vehicle */
export const HANDLE_SELL_VEHICLE = "HANDLE_SELL_VEHICLE";

export interface HandleSellVehicle {
    type: typeof HANDLE_SELL_VEHICLE;
    payload: { sellVehicleInfo: any, callback: Function };
}

export const HANDLE_PURCHASE_VEHICLE_UNDO = "HANDLE_PURCHASE_VEHICLE_UNDO";

export interface HandlePurchaseVehicleUndo {
    type: typeof HANDLE_PURCHASE_VEHICLE_UNDO;
    payload: { id: any, callback: Function };
}

export const HANDLE_SELL_VEHICLE_UNDO = "HANDLE_SELL_VEHICLE_UNDO";

export interface HandleSellVehicleUndo {
    type: typeof HANDLE_SELL_VEHICLE_UNDO;
    payload: { id: any, callback: Function };
}


/** GET VEHICLES */
export const GET_VEHICLES_PENDING = "GET_VEHICLES_PENDING";

export interface GetVehiclesPending {
    type: typeof GET_VEHICLES_PENDING;
}

export const HANDLE_GET_VEHICLES = "HANDLE_GET_VEHICLES";

export interface HandleGetVehicles {
    type: typeof HANDLE_GET_VEHICLES;
}

export const GET_VEHICLES_RESOLVED = "GET_VEHICLES_RESOLVED";
export interface GetVehiclesResolved {
    type: typeof GET_VEHICLES_RESOLVED;
    payload: any;
}

export const GET_VEHICLES_REJECTED = "GET_VEHICLES_REJECTED";

export interface GetVehiclesRejected {
    type: typeof GET_VEHICLES_REJECTED;
    payload: any;
}

/** MANAGE FAVORITE VEHICLES */
export const MANAGE_FAVORITE_VEHICLE_PENDING = "MANAGE_FAVORITE_VEHICLE_PENDING";

export interface ManageFavoriteVehiclePending {
    type: typeof MANAGE_FAVORITE_VEHICLE_PENDING;
}

export const HANDLE_MANAGE_FAVORITE_VEHICLE = "HANDLE_MANAGE_FAVORITE_VEHICLE";

export interface HandleManageFavoriteVehicle {
    type: typeof HANDLE_MANAGE_FAVORITE_VEHICLE;
    payload: any;
}

export const MANAGE_FAVORITE_VEHICLE_REJECTED = "MANAGE_FAVORITE_VEHICLE_REJECTED";

export interface ManageFavoriteVehicleRejected {
    type: typeof MANAGE_FAVORITE_VEHICLE_REJECTED;
    payload: any;
}
/** DELETE_EXPENSE */
export const HANDLE_DELETE_EXPENSE = "HANDLE_DELETE_EXPENSE";
export interface HandleDeleteExpense {
    type: typeof HANDLE_DELETE_EXPENSE;
    payload: {id: string | number, vehiclePricing: string | number, callback: Function} ;
}


/* Get Active Appraisal,Active and Sold MTD*/
export const GET_VEHICLES_STATS = "GET_VEHICLES_STATS";

export interface GetVehiclesStats {
    type: typeof GET_VEHICLES_STATS;
    payload: {callback: Function}
}

/** Update Vehicle - stockAppLoc */
export const HANDLE_VEHICLE_UPDATE = "HANDLE_VEHICLE_UPDATE";
export interface HandleVehicleUpdate {
    type: typeof HANDLE_VEHICLE_UPDATE;
    payload: { id: string | number, stockAppLoc: object, callback: Function };
}


export type InventoryActions =
  | HandleGetInventory
  | HandleGetInventoryFilterList
  | handleGetShopWorkBookList
  | handleGetPurchaseWorkBookList
  | handleGetSoldWorkBookList
  | handleGetTrimUsingVin
  | handleGetVehicleUsingID
  | handleGetPricingUsingID
  | handleSavePricingUsingID
  | handleGetQValuePlotUsingID
  | handleGetQValueNotesUsingID
  | handleSaveQValueNotesUsingID
  | handleSaveSourceWorkBook
  | handleGetSourceWorkBook
  | handleGetLiveRetailReports
  | handleGetFilterLiveRetailReports
  | handlePutLiveRetailReports
  | HandleVehicleLiveRetailFilters
  | handleSaveQValues
  | handleGetQValueDropdownMaster
  | handleGetTrimUsingYmm
  | handleGetVehDetails
  | handlePutVehDetails
  | handleGetVehDetailsSuccess
  | handleGetVehSellerDetails
  | handleGetAdjusterList
  | handlePutAdjusterList
  | HandleGetSelectedFilterData
  | HandleSaveFilterInventory
  | HandleDeleteFilterInventory
  | HandleDeleteInventory
  | GetInventoryPending
  | GetInventoryResolved
  | GetInventoryFilterResolved
  | GetShopWorkBookResolved
  | GetPurchaseWorkBookResolved
  | GetSoldWorkBookResolved
  | GetTrimResolved
  | GetInventoryRejected
  | SaveInventoryRejected
  | SetRowsPerPage
  | HandleCreateInventory
  | HandleUploadInventories
  | CreateInventoryPending
  | CreateInventoryResolved
  | CreateInventoryRejected
  | HandleViewInventory
  | HandleExportInventory
  | HandlePDFGuideInventory
  | HandlePDFInfoInventory
  | ViewInventoryPending
  | ViewInventoryResolved
  | ViewInventoryRejected
  | PdfInfoInventoryRejected
  | PdfGuideInventoryRejected
  | HandleUpdateInventory
  | UpdateInventoryPending
  | UpdateInventoryResolved
  | UpdateInventoryRejected
  | HandleDeleteInventory
  | DeleteInventoryPending
  | DeleteInventoryResolved
  | DeleteInventoryRejected
  | HandleAddInventoryPhoto
  | AddInventoryPhotoPending
  | AddInventoryPhotoResolved
  | AddInventoryPhotoRejected
  | HandleDeleteInventoryPhoto
  | DeleteInventoryPhotoPending
  | DeleteInventoryPhotoResolved
  | DeleteInventoryPhotoRejected
  | HandleSortInventoryTable
  | HandleGetFromVin
  | GetFromVinPending
  | GetFromVinResolved
  | GetFromVinRejected
  | HandleEditInventory
  | EditInventoryPending
  | EditInventoryResolved
  | EditInventoryRejected
  | HandleGetValuationInformation
  | GetValuationInformationPending
  | GetValuationInformationResolved
  | GetValuationInformationRejected
  | CleanValuationInformation
  | CleanInventoryFilters
  | SaveInventoryFilters
  | SetInventoryFilters
  | HandlePurchaseVehicle
  | HandleSellVehicle
  | HandlePurchaseVehicleUndo
  | HandleSellVehicleUndo
  | HandleGetVehicles
  | GetVehiclesResolved
  | GetVehiclesPending
  | GetVehiclesRejected
  | HandleDeleteExpense
  | GetVehiclesStats
  | ManageFavoriteVehiclePending
  | HandleManageFavoriteVehicle
  | ManageFavoriteVehicleRejected
  | HandleVehicleUpdate;
