import React, { useState, useEffect } from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "store/index";
import {
    isEmployeePermitted,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { karlProfileURLKeys, defaultProfileURLKeys } from 'constants/constants';
import { isKarlProfile, isDefaultProfile } from "utils/permissions/permissionsHelpers";
import { Menubar } from 'primereact/menubar';
import { emplyeePermissionsScreen } from "constants/constants";

export const TopMenu = () => {
    const navigate = useNavigate ();
    const location = useLocation();
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const inventoryInfo = useSelector((state: AppState) => state.inventory.shopVehicleList);

    const [filteredNavLinks, setFilteredNavLinks] = useState([]);
    const [active, setActive] = useState('');

    const handleNavigation = (event) => {
        setActive(event.item.key);

        ////console.log('e', event.originalEvent.target)
        //var t = document.getElementsByClassName("pr-menuitem-active")[0];
        //if (t) {
        //    t.classList.remove('pr-menuitem-active')
        //}

        //event.originalEvent.target.closest('li').classList.add('pr-menuitem-active')
        navigate(event.item.path);

        //console.log('topLinks', topLinks)
    }

    const handleNavigationSubMenu = (event) => {

        navigate(event.item.path);

    }

    let topLinks = [];
    //let urlKeys = [];

    if (user && user.roles && user.roles.includes('ROLE_ADMIN')) {
        topLinks = [{
            command: (e) => handleNavigation(e),
            path: "/admin-accounts",
            label: "Accounts",
            key: "accounts",
            module: "Accounts",
            screen: "admin",
            className: ''
        }]
        //urlKeys = ['accounts'];
    } else {
        if (isKarlProfile(user.profileType)) {
            //urlKeys = karlProfileURLKeys;
            topLinks = [
                {
                    command: (e) => handleNavigation(e),
                    path: "/dashboard",
                    label: "Dashboard",
                    key: "dashboard",
                    icon: "pi pi-home",
                    className: ''
                },
                {
                    command: (e) => handleNavigation(e),
                    path: "/appointments",
                    label: "Appointments",
                    key: "appointments",
                    className: ''
                },
                {
                    command: (e) => handleNavigation(e),
                    path: "/appraisals",
                    label: "Trade-ins",
                    key: "appraisals",
                    className: ''
                },
                {
                    command: (e) => handleNavigation(e),
                    path: "/write-ups",
                    label: "Write-ups",
                    key: "write-ups",
                    className: ''
                },
                {
                    command: (e) => handleNavigation(e),
                    path: "/scheduling",
                    label: "Scheduling",
                    key: "scheduling",
                    className: ''
                },
                {
                    command: (e) => handleNavigation(e),
                    path: "/tasks",
                    label: "Tasks",
                    key: "tasks",
                    className: ''
                },
                {
                    label: "After Sales",
                    key: "afterSale",
                    className: '',
                    items: [
                        {
                            label: "After Sale Products",
                            command: (e) => handleNavigation(e),
                            path: "/service-contract",
                            key: "afterSale",
                            className: ''
                        },
                        {
                            label: "Cancellations",
                            command: (e) => handleNavigation(e),
                            path: "/cancellations",
                            key: "afterSale",
                            className: ''
                        }
                    ]
                }
            ]
        } else if (isDefaultProfile(user.profileType)) {
            //urlKeys = defaultProfileURLKeys;
            topLinks = [
                {
                    command: (e) => handleNavigation(e),
                    path: "/dashboard",
                    label: "Dashboard",
                    key: "dashboard",
                    icon: "pi pi-home",
                    className: ''
                },
                {
                    label: "Appraisal",
                    path: "/appraisal",
                    command: (e) => handleNavigation(e),
                    key: "appraisal",
                    module: "Inventory",
                    screen: "workbook_shop",
                    className: ''
                },
                {
                    label: "Inventory",
                    path: "/acquired",
                    command: (e) => handleNavigation(e),
                    key: "acquired",
                    module: "Inventory",
                    screen: "workbook_purchase",
                    className: ''
                },
                {
                    label: "Sold",
                    path: "/sold",
                    command: (e) => handleNavigation(e),
                    key: "sold",
                    module: "Inventory",
                    screen: "workbook_sold",
                    className: ''
                },
                {
                    label: "",
                    path: "/inventory",
                    command: (e) => handleNavigation(e),
                    key: "inventory",
                    icon: "pi pi-search",
                    className: 'inventory-search-link'
                },
                {
                    label: "Settings",
                    path: "/settings",
                    command: (e) => handleNavigation(e),
                    key: "settings",
                    icon: "setting-icon",
                    className: '',
                    items: [
                        {
                            label: "Users",
                            path: "/users",
                            command: (e) => handleNavigationSubMenu(e),
                            key: "users",
                            module: "Employee",
                            screen: "setting_user_view",
                            className: !isEmployeePermitted(
                                user.aggregatedDefaultPolicies,
                                emplyeePermissionsScreen.SETTING_USER_LIST,
                                user.roles
                            ) ? 'display-none' : null
                        },
                        {
                            label: "Locations",
                            path: "/locations",
                            command: (e) => handleNavigationSubMenu(e),
                            key: "locations",
                            module: "Location",
                            screen: "setting_location_view",
                            className: !isEmployeePermitted(
                                user.aggregatedDefaultPolicies,
                                emplyeePermissionsScreen.SETTING_LOCATION_LIST,
                                user.roles
                            ) ? 'display-none' : null
                        },
                        {
                            label: "Business Info",
                            path: "/business-info",
                            command: (e) => handleNavigationSubMenu(e),
                            key: "business-info",
                            module: "DealerProfile",
                            screen: "setting_business_info_view",
                            className: ''
                        },
                    ]
                }
            ]
        }

    }

    const checkPermissionsForMenuItem = (items: any[]) => {

        return items.filter((link: any) => {

            let l: any = null;
            if (link.name === 'Dashboard') l = link;

            if (user && user.roles && user.roles.includes('ROLE_ADMIN')) l = link;

            // const modulePermissions = getModulePermissions(user.auths, link.module);

            if (link.links) {
                const links = checkPermissionsForMenuItem(link.links);
                link.links = links;
                l = link;
            }
            if (
                /* isPermitted(
                  modulePermissions,
                  eTypes.READ,
                  link.module,
                  user.roles ? user.roles : []
                ) || */
                isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    link.screen,
                    user.roles ? user.roles : []
                ) ||
                (link.links && link.links.length > 0) || isKarlProfile(user.profileType))
                return (l = link);

            return l;
        });
    };

    useEffect(() => {
        const pathName = location.pathname;
        const obj = filteredNavLinks;
        if (Array.isArray(obj)) {
            const pos = obj.findIndex(x => x?.path && x?.path === pathName);
            if (pos > -1) {
                setActive(obj[pos].key);
            } else {
                let key;
                const subMenus = obj.filter(x => x.items && x.items.length)
                for (var i in subMenus) {
                    if (subMenus[i].items.findIndex(x => x.path && x.path === pathName) > -1) {
                        key = subMenus[i].key;
                        break;
                    }
                }
                if (key) {
                    setActive(key);
                } else {
                    let obj = filteredNavLinks;
                    const index = obj.findIndex(x => x.className && x.className === 'pr-menuitem-active');
                    if (index > -1)
                        obj[index].className = '';
                    setActive('');
                }

            }
        }
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (location.pathname.indexOf('shop-inventory') > 0 && inventoryInfo) {
            if (inventoryInfo.stage === 'shop') {
                setActive('appraisal');
            } else if (inventoryInfo.stage === 'purchase') {
                setActive('acquired');
            } else if (inventoryInfo.stage === 'sold') {
                setActive('sold');
            }
            console.log('test3', active)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryInfo]);

    useEffect(() => {
        if (user && user.roles) {
            //@TODO: check if this would cause displaying menu if there's no user
            /** If user is master show all elements*/
            if (isUserMaster(user.roles)) {
                setFilteredNavLinks([...topLinks]);
                return;
            }

            /** If user is not master -> filter all menu elements and return only permited*/
            let filtered = checkPermissionsForMenuItem(topLinks);
            filtered = filtered.filter(x => !x.links || x.links.length > 0)
            const tempNavLinks = [...topLinks];
            tempNavLinks[0].links = [...filtered];
            setFilteredNavLinks([...tempNavLinks]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (active) {
            setFilteredNavLinks(null);
            let obj = filteredNavLinks;
            if (Array.isArray(obj)) {
                const index = obj?.findIndex(x => x.className && x.className === 'pr-menuitem-active');
                if (index > -1)
                    obj[index].className = '';
                const pos = obj.findIndex(x => x.key && x.key === active);
                if (pos > -1 && location.pathname.indexOf('/dealtracker') === -1) {
                    obj[pos].className = 'pr-menuitem-active';
                }
                setTimeout(() => {
                    setFilteredNavLinks(obj);
                }, 10)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    return (
        <div className="card top-menu">
            <Menubar className='topMenuColor' model={filteredNavLinks} />
        </div>
    );
}
