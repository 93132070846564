import { IColumn } from "@fluentui/react/lib/DetailsList";
import * as _ from "lodash";

export const stringFromCurrencyFormat = (currency: string | null | number) => {
  if(currency === undefined){
    return;
  }

  let newValue = "";
  if(currency === null){
    return null
  }
  if(currency === ""){
    return null;
  }
  if(typeof currency === 'number') {

     currency = currency.toString();
     return currency
  }
  let hasDot = false;
  for (const item of currency) {
    if (item === "," || item === "$" || item === " ") {
      continue;
    }
    if (item === ".") {
      hasDot = true;
      continue;
    }
    newValue += item;
  }

  if (!hasDot) {
    newValue += "00";
  }

  return newValue;
};


export const stringToFilterInput = (price: number | string | null) => {

}


export const stringToCodePhNoformat = (number: number | string | null) => {
  let givenString: any = number;
  let insertAtPosition = 3;
  let insertAtPosition2 = 7;
  let stringToInsert = '-';

  if(typeof number === "string") {
    let arrGivenString = [...givenString];
    arrGivenString.splice(insertAtPosition, 0, stringToInsert);
    arrGivenString.splice(insertAtPosition2, 0, stringToInsert);

    let output = arrGivenString.join('');

    return output
  }
  return number;
}

export const stringToCurrenyFormatForEditInputs = (price: number | string) => {
 let currency = "";
  if(typeof price === "number"){
   currency = price.toString();
  }else {
    currency = price;
  }
  const dot = ".";

  const position = currency.length - 2;
  const output = [
    currency.slice(0, position),
    dot,
    currency.slice(position),
  ].join("");

  return output;
};




export const stringToCurrencyFormatForLabels = (price: string | number) => {
  let newPrice = typeof price === "number" ? price.toString() : price;
  if(newPrice === "0"){
    return newPrice
  }
  const dot = ".";
  const position = newPrice.length - 2;
  let partBeforeDot = newPrice.slice(0, position);

  let x = "";
  for (let i = 0; i < partBeforeDot.length; i++) {
    if(i === 0){
    x += partBeforeDot[i] + ",";
      continue;
    }
    if((i)  % 3 === 0){
      if(i !== partBeforeDot.length - 1){

      x += partBeforeDot[i] + ",";
      continue;
      }
    }
    x +=  partBeforeDot[i];
  }
  newPrice = [partBeforeDot.length > 3 ? x : partBeforeDot, dot, newPrice.slice(position)].join("");
  return newPrice;
};

export const formatPathname = (pathname: string) => {
  if (pathname.includes("login") || pathname.includes("register")) {
    return "Dashboard";
  }
  let returnValue = "";
  const array = pathname.split("/");

  if (array.length > 1) {
    const temp = array[array.length - 1];
    for (let i = 0; i < temp.length; i++) {
      if (i === 0) {
        returnValue = temp[i].toUpperCase();
      } else {
        returnValue += temp[i];
      }
    }
  }
  if (returnValue.includes("-")) {
    returnValue = returnValue.replace("-", " ");
  }

  return returnValue;
};

export const sortColumns = (
  columns: IColumn[],
  currentColumn: IColumn
): IColumn[] => {
  columns.forEach((newCol: IColumn) => {
    if (newCol === currentColumn) {
      currentColumn.isSortedDescending = !currentColumn.isSortedDescending;
      currentColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });
  return columns;
};

export const validURL = (str) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    
    if (str === '' || str === undefined) {
        return true;
    }
        
    return !!urlPattern.test(str);
}

export const validTenDigitPhone = (str) => {
    let phoneLen = str.replace(/[^\d]/g, "").length;
    return phoneLen === 0 || phoneLen >= 10;
}

export const isValidDate = (dateString) => {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const currencyFractionFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const numberFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 0
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const currencyFormat = (val) => {
    if (val === undefined) {
      return currencyFormatter.format(0);
    }
    if (isNaN(val) || val === '') {
        return val;
    }
    if (typeof val === 'string') {
      return currencyFormatter.format(parseInt(val));
    }
    return currencyFormatter.format(val);
}

export const currencyNegValFormat = (val) => {
    if (val === undefined) {
      return currencyFormatter.format(0);
    }
    if (isNaN(val) || val === '') {
        return val;
    }
    if (typeof val === 'string') {
      let num = parseInt(val);
      if (num < 0) {
        return '('+currencyFormatter.format(Math.abs(num))+')';
      } else {
        return currencyFormatter.format(Math.abs(num));
      }
    } else {
      if (val < 0) {
        return '('+currencyFormatter.format(Math.abs(val))+')';
      } else {
        return currencyFormatter.format(Math.abs(val));
      }
    }
}

export const currencyNegValFractionalFormat = (val) => {
    let result;
    let num;
    if (val === undefined) {
      return currencyFormatter.format(0);
    }
    if (isNaN(val) || val === '') {
        return val;
    }
    num = typeof val === 'string' ? Number(val) : val;
   // if (num % 1 !== 0) {
        result = currencyFractionFormatter.format(Math.abs(num));
   //} else {
   //    result = currencyFormatter.format(Math.abs(num));
   //}

    if (result.includes('-') || num < 0) {
        result = '(' + result + ')';
    }
    return result;
}

export const numberFormat = (val) => {
    if (isNaN(val) || val === '') {
        return val;
    }
    if (typeof val === 'string') {
        return numberFormatter.format(parseInt(val));
    }
    return numberFormatter.format(val);
}

export const findClosest = (arr,num) => {
  if(arr === null || (arr.length === 0)){
    return
  }
  return arr.reduce((prev,current) => Math.abs(current - num)<Math.abs(prev - num) ? current : prev);
}

export const groupBy = (list, keyGetter)=> {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export const groupByRange = (list, keyGetter, range_lst)=> {
  const map = new Map();
  let new_list = JSON.parse(JSON.stringify(list))
  range_lst.forEach((range)=>{
    new_list.forEach((item, index) => {
        var value = keyGetter(item);
        if(!Number.isInteger(value)){
          value = parseFloat(value.replace(/,/g, ''))
        }
        if (value <=range){
          const collection = map.get(range);
          if (!collection) {
              map.set(range, [item]);
          } else {
              collection.push(item);
          }
          new_list.splice(index, 1);
        }
        
    });
  })
  return map;
}

export const get_chart_range_list=(data_list, step, field)=>{
  data_list.sort((a, b) => {
    if (parseFloat(a[field]) > parseFloat(b[field])) {
      return 1;
    }
    if (parseFloat(a[field]) < parseFloat(b[field])) {
      return -1;
    }
    return 0;
  });
  var start = data_list[0][field], end = data_list[data_list.length-1][field]
  if(!Number.isInteger(start) && !Number.isInteger(end)){
    start = parseFloat(start.replace(/,/g, ''))
    end = parseFloat(end.replace(/,/g, ''));
  }
  var arr = _.range(0, 1000000, step)
  start = arr.indexOf(findClosest(arr, start))
  end = arr.indexOf(findClosest(arr, end))
  start = arr && start!==0&& arr[start-1]? start-1 : start
  end = arr && arr[end+2] ? end+2 : end
  return arr.slice(start, end)
}

export const titleCase = (str) => {
var splitStr = str.toLowerCase().split(' ');
for (var i = 0; i < splitStr.length; i++) {
splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
}
return splitStr.join(' ');
}

export const titleCaseWithArray = (arr) => {
if (arr) {
    const tempArr = arr;
    let result = []
    tempArr.forEach(function(item){
        var splitStr = item.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
    let val = splitStr.join(' ');
    result.push({ key: val, text: val });
    });
    return result;
}
return [];
}