import React, { useRef } from "react";
import Card from "components/card/Card";
import { DefaultButton, Separator, Stack } from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { PRICE_PREFIX } from "constants/constants";
import { useReactToPrint } from "react-to-print";
import CurrencyMaskedInput from 'react-currency-masked-input';

interface IProps {
  formik: any;
  total: number;
  grandTotal: number;
  totalTax: number;
  monthlyPaymentVariants: any[];
  selection: any[];
  productTaxRate: any;
  productTaxTotal: any;
  vehicleTaxRate: any;
  vehicleTaxTotal: any;
}

const Summary: React.FC<IProps> = ({
  formik,
  total,
  totalTax,
  grandTotal,
  monthlyPaymentVariants,
  selection,
  productTaxRate,
  productTaxTotal,
  vehicleTaxRate,
  vehicleTaxTotal,
}) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "AwesomeFileName",
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    // onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return (
    <div ref={componentRef}>
      <Card style={{ backgroundColor: `#F6F9FC` }}>
        <Stack verticalAlign="space-between" style={{ height: "100%" }}>
          <div>
            <Stack>
              <CustomFontText
                size={20}
                color="#2D80D3"
                style={{ marginBottom: 25 }}
              >
                Summary
              </CustomFontText>
              {formik.values.variants &&
                formik.values.variants.map((variant, index) => {
                  //&& selection includes
                  if (
                    monthlyPaymentVariants[index].value === 0 &&
                    !selection.some((s) => {
                      return s.key === index;
                    })
                  ) {
                    return null;
                  } else {
                    return (
                      <Stack
                        tokens={{ childrenGap: 5 }}
                        style={{ marginBottom: 30 }}
                      >
                        <CustomFontText
                          size={16}
                          color="#2D80D3"
                          style={{ marginBottom: 15 }}
                        >
                          Variant {` ${index + 1}`}
                        </CustomFontText>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Total Adjusted Vehicle and Services
                          </CustomFontText>
                          {total !== null && (
                            <CustomFontText size={18}>
                              <CurrencyMaskedInput
                                customInput={(value) => (
                                  <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={total / 100}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                              />
                            </CustomFontText>
                          )}
                        </Stack>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Product Tax Rate
                          </CustomFontText>
                          {productTaxRate !== null && (
                            <CustomFontText
                              size={18}
                            >{`${productTaxRate}%`}</CustomFontText>
                          )}
                        </Stack>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Product Tax Total
                          </CustomFontText>
                          {productTaxTotal !== null && (
                            <CustomFontText size={18}>
                              <CurrencyMaskedInput
                                customInput={(value) => (
                                  <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={productTaxTotal / 100}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                              />
                            </CustomFontText>
                          )}
                        </Stack>

                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Vehicle Tax Rate
                          </CustomFontText>
                          {vehicleTaxRate !== null && (
                            <CustomFontText
                              size={18}
                            >{`${vehicleTaxRate}%`}</CustomFontText>
                          )}
                        </Stack>

                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Vehicle Tax Total
                          </CustomFontText>
                          {totalTax !== null && (
                            <CustomFontText size={18}>
                              <CurrencyMaskedInput
                                customInput={(value) => (
                                  <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={vehicleTaxTotal / 100}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                              />
                            </CustomFontText>
                          )}
                        </Stack>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>Total Tax</CustomFontText>
                          {totalTax !== null && (
                            <CustomFontText size={18}>
                              <CurrencyMaskedInput
                                customInput={(value) => (
                                  <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={totalTax / 100}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                              />
                            </CustomFontText>
                          )}
                        </Stack>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Total to be Financed
                          </CustomFontText>
                          {grandTotal !== null && (
                            <CustomFontText size={18}>
                              <CurrencyMaskedInput
                                customInput={(value) => (
                                  <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={grandTotal / 100}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                              />
                            </CustomFontText>
                          )}
                        </Stack>

                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>Term</CustomFontText>
                          {formik.values.variants[index].term !== "" && (
                            <CustomFontText size={18}>
                              {`${formik.values.variants[index].term}`}
                            </CustomFontText>
                          )}
                        </Stack>
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>Rate</CustomFontText>
                          {formik.values.variants[index].rate !== "" && (
                            <CustomFontText size={18}>
                              {`${formik.values.variants[index].rate}%`}
                            </CustomFontText>
                          )}
                        </Stack>

                        <Stack
                            horizontal
                            verticalAlign="center"
                            horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>Finance Charge</CustomFontText>
                          {!Number.isNaN(
                              monthlyPaymentVariants[index].value
                          ) && (
                              <CustomFontText size={18}>
                                <CurrencyMaskedInput
                                    customInput={(value) => (
                                        <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                    )}
                                    value={(monthlyPaymentVariants[index].value * formik.values.variants[index].term ) - grandTotal/100}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={"text"}
                                    prefix={PRICE_PREFIX}
                                />
                              </CustomFontText>
                          )}
                        </Stack>
                        <Stack
                            horizontal
                            verticalAlign="center"
                            horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>Total Finance (including interest)</CustomFontText>
                          {!Number.isNaN(
                              monthlyPaymentVariants[index].value
                          ) && (
                              <CustomFontText size={18}>
                                <CurrencyMaskedInput
                                    customInput={(value) => (
                                        <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                    )}
                                    value={(monthlyPaymentVariants[index].value * formik.values.variants[index].term )}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={"text"}
                                    prefix={PRICE_PREFIX}
                                />
                              </CustomFontText>
                          )}
                        </Stack>
                        <Separator />
                        <Stack
                          horizontal
                          verticalAlign="center"
                          horizontalAlign="space-between"
                        >
                          <CustomFontText size={18}>
                            Monthly Payment
                          </CustomFontText>

                          {/*{!Number.isNaN(*/}
                          {/*  monthlyPaymentVariants[index].value*/}
                          {/*) && (*/}
                          {/*  <CustomFontText*/}
                          {/*    size={18}*/}
                          {/*  >{`${monthlyPaymentVariants[index].value.toFixed(*/}
                          {/*    2*/}
                          {/*  )}`}</CustomFontText>*/}
                          {/*)}*/}
                          <CustomFontText size={18}>
                            <CurrencyMaskedInput
                                customInput={(value) => (
                                    <span style={{ fontSize: 18 }}>
                                    {value.value}
                                  </span>
                                )}
                                value={(monthlyPaymentVariants[index].value )}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                prefix={PRICE_PREFIX}
                            />
                          </CustomFontText>

                        </Stack>
                      </Stack>
                    );
                  }
                })}
            </Stack>
          </div>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
            style={{ marginTop: 15 }}
          >
            <DefaultButton
              onClick={handlePrint}
              text="Print Calculation"
              iconProps={{ iconName: "Print" }}
            />
          </Stack>
        </Stack>
      </Card>
    </div>
  );
};

export default Summary;
