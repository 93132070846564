import React, { useEffect } from 'react';
import {
    PrimaryButton,
    IconButton,
    Icon,
    Modal
} from "@fluentui/react";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
//import { TimePicker } from 'antd';
import TimePicker from 'rc-time-picker';
import { HandleEmployeeDeleteEvents, HandleEmployeeTmpAvail } from 'store/employees/employees.action';
import { convertUTCToLocalDateOnlyModified, convertUTCToLocalFormatModified } from 'utils/dateTimeFunctions';
const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (msg) => void;
    isOpen: boolean;
    selectedData: any;
    selectionDetails: any;
}

// interface ParamTypes {
//     dealID: string;
// }

export const TempAvailableDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    selectedData,
    selectionDetails,
    isOpen
}) => {
    const yearRange = "1980:" + (new Date().getFullYear()+1);
    const [tempAvailDate, settempAvailDate] = React.useState(new Date());
    const [tempAvailLocationList, settempAvailLocationList] = React.useState(null);
    const [startTime, setstartTime] = React.useState(null);
    const [timePickerOpen, settimePickerOpen] = React.useState(true);
    const [timePickerOpenDel, settimePickerOpenDel] = React.useState(true);
    const [endTime, setendTime] = React.useState(null);
    const [commonServerError, setcommonServerError] = React.useState(null);
    const [timeValidationError, settimeValidationError] = React.useState(null);
    const dispatch = useDispatch();
    //const disabledSec = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
    // 36,37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

    const scheduleFormik = useFormik({
        initialValues: {
            locationId: '',
            startsAt: selectedData?.startsAt ? moment(new Date(selectedData.startsAt).toLocaleTimeString(), 'h:mm a') : '',
            endsAt: selectedData?.endsAt ? moment(new Date(selectedData.endsAt).toLocaleTimeString(), 'h:mm a') : '',
            date: selectedData?.date ? new Date(selectedData?.date) : ''
        },
        validationSchema: Yup.object({
            locationId: Yup.string(),
            startsAt: Yup.string(),
            endsAt: Yup.string(),
            date: Yup.string()
        }),
        
        onSubmit: (values) => {
            setcommonServerError(null);
            settimeValidationError(null);
            console.log(' scheduleDeliveryTime values', values);
            if (values.startsAt === '' || values.endsAt === '') { // validate start and end time
                settimeValidationError('Start/End Time cannot be empty.');
                return;
            } else if (startTime.isSame(endTime)) {
                settimeValidationError('Start/End Time cannot be same.');
                return;
            } else if (endTime.isBefore(startTime)) {
                settimeValidationError('End time cannot be before start time');
                return;
            }
            values['eventId'] = selectedData?.eventId;
            values['date'] = convertUTCToLocalFormatModified(values.date, "YYYY-MM-DD");
            //let scheduleTime;
            
            dispatch<any>(HandleEmployeeTmpAvail({
                id: selectedData?.id,
                method: selectedData.method,
                data: values,
                callback: (res) =>  {
                if (res) {
                    // setcommonMngServerError(false);
                    onDismiss('reloadCalendar');
                } else {
                    setcommonServerError(true);
                }
            }}));            
        }
    });

    useEffect(() => {
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            let childTwo: any = document.getElementsByClassName('req-del-time-panel')[1];
            child?.parentNode?.parentNode?.classList.add("activeTEST")
            if (childTwo) {
                childTwo?.parentNode?.parentNode?.classList.add("activeTESTDel")
            }
            
            const check = document.querySelector(".req-del-time-panel");
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTEST')[0];
                    let child2Del: any = document.getElementsByClassName('activeTESTDel')[1] ? document.getElementsByClassName('activeTESTDel')[1] : document.getElementsByClassName('activeTESTDel')[0];
                            
                    if (child2) {
                        const parentEle = document.getElementById("timeAvl-schedulePopup");
                        let li = document.createElement('div');
                        li.appendChild(child2);
                        li.appendChild(child2Del);
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                    settimePickerOpen(false)
                    settimePickerOpenDel(false)
                }
            }, 500)
        }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (selectedData) {
            let rawDateVal =  Object.assign({}, selectedData);
            let selectedRawStartDateVal: any = new Date(rawDateVal.startsAt);
            let selectedRawEndDateVal: any = new Date(rawDateVal.endsAt);
            let utcStartLocalTime = convert24To12Hours(addZeroIfLess(selectedRawStartDateVal.getUTCHours()) + ':' + addZeroIfLess(selectedRawStartDateVal.getUTCMinutes()) + ':00');
            let utcEndLocalTime = convert24To12Hours(addZeroIfLess(selectedRawEndDateVal.getUTCHours()) + ':' + addZeroIfLess(selectedRawEndDateVal.getUTCMinutes()) + ':00');

            settempAvailLocationList(selectedData?.locationList);
            let startsAt: any = selectedData?.startsAt ? moment(utcStartLocalTime, 'h:mm a') : null;
            let endsAt: any = selectedData?.endsAt ? moment(utcEndLocalTime, 'h:mm a') : null;
            if (startsAt) {
                setstartTime(startsAt)
            }
            if (endsAt) {
                setendTime(endsAt)
            }

            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    'locationId': selectedData?.locationId,
                    'date': moment(new Date(selectedData?.date)).format("YYYY-MM-DD"),
                    'startsAt': startsAt ? startsAt._i : '',
                    'endsAt': endsAt ? endsAt._i : '',
                }
            );
            settempAvailDate(new Date(convertUTCToLocalDateOnlyModified(selectedData?.date)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData]);

    //const handleStartTimeChange = (time: any, timeString: string) => {
    const handleStartTimeChange = (value) => {
        let financeTimeRaw: any = moment(value.format('h:mm a'));
        let financeTime: any = moment(financeTimeRaw._i, 'h:mm a');

        // let financeTime: any = moment(timeString, 'h:mm a');
        if (financeTime) {
            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    'startsAt': convertTime12To24(financeTime._i)
                }
            );
            setstartTime(financeTime)
        }
    }

    const testOpen = (test) => {
        settimePickerOpen(true);
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            child?.parentNode?.parentNode?.classList.add("activeTEST")
            const check = document.querySelector(".req-del-time-panel");
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTEST')[0];
                    const parentEle = document.getElementById("timeAvl-schedulePopup");
                        
                    if (child2) {
                        let li = document.createElement('div');
                        li.appendChild(child2);  
                
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                }
            }, 500)
        }, 500)
    }

    const testClose = () => {
        settimePickerOpen(false);
    };

    const testOpenDel = (test) => {
        settimePickerOpenDel(true);
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            child?.parentNode?.parentNode?.classList.add("activeTESTDel")
            
            const check = document.querySelector(".req-del-time-panel");
            //settimePickerOpen(false);
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTESTDel')[1] ? document.getElementsByClassName('activeTESTDel')[1] : document.getElementsByClassName('activeTESTDel')[0];
                    const parentEle = document.getElementById("timeAvl-schedulePopup");
                        
                    if (child2) {
                        let li = document.createElement('div');
                        li.appendChild(child2);               
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                }
            }, 500)
        }, 500)
    }

    const testCloseDel = () => {
        settimePickerOpenDel(false);
    };

    // const handleEndTimeChange = (time: any, timeString: string) => {
    const handleEndTimeChange = (value) => {
        let delvieryTimeRaw: any = moment(value.format('h:mm a'));
        let delvieryTime: any = moment(delvieryTimeRaw._i, 'h:mm a');

        // let delvieryTime: any = moment(timeString, 'h:mm a');
        if (delvieryTime) {
            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    'endsAt': convertTime12To24(delvieryTime._i)
                }
            );
            setendTime(delvieryTime)
        }
    }

    const convertTime12To24 = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
           hours = '00';
        }
        if (modifier?.toUpperCase() === 'PM') {
           hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}:00`;
    };

    const convert24To12Hours = (time) => {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        
        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }
    
    const addZeroIfLess = (val) => {
        if (Number(val) < 10) {
            return '0'+ Number(val)
        }
        return val;
    }
    
    const handleSubmit = () => {
        scheduleFormik.handleSubmit();
    }
    
    const handleDelete = () => {
        // delete event
        if (selectedData?.eventId) {
            // Delete selected time off
            dispatch<any>(HandleEmployeeDeleteEvents({
                id: selectedData?.id,
                eventId: selectedData?.eventId,
                callback: (res) =>  {
                console.log('DELETE TIME OFF res', res)
                if (res) {
                    // setcommonMngServerError(false);
                    onDismiss('reloadCalendar');
                } else {
                    // setcommonMngServerError(true);
                }
            }}));
        }
    }

    const handleDateChange = (val) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                "date": moment(val).format("YYYY-MM-DD")
            }
        );
        settempAvailDate(val);
    };

    return (<>
        <Modal
            isOpen={isOpen}
            onDismiss={() => onDismiss(null)}
            isBlocking={true}
            styles={{ root: { zIndex: 10 } }}
            className=" dealRecordHeight updateEmployeeDialogHeight scrollView"
        >
            <div  className="editDiv" style={{width:"auto"}}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle" style={{width:"480px"}}>Temp Availability</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => onDismiss(null)}
                    className="close-Crossbtn"
                />
            </div>

            {
                commonServerError ?
                <div>
                    <Row>
                        <Col>
                            <p className="genericErrorColor" style={{paddingLeft: "35px", marginBottom: '0', marginTop: '10px'}}><span>Failed to Save.</span></p>
                        </Col>
                    </Row>
                </div> : null
            }
            
            {
                (timeValidationError) ?
                <div>
                    <Row>
                        <Col>
                            <p className="genericErrorColor" style={{paddingLeft: "35px", marginBottom: '0', marginTop: '10px'}}><span>{timeValidationError}</span></p>
                        </Col>
                    </Row>
                </div> : null
            }

            <div className="tmpAvailabilityPopup" id="timeAvl-schedulePopup">
                { ((selectionDetails?.isManager && selectionDetails?.financeOnly) || (!selectionDetails?.isManager)) ?
                <>
                    <div >
                        <Row className="optionGroup" justify="center">
                            <Col md={10} className="radio-options">
                                <label className="lbl-text lbl-txts">{selectedData.name}</label>
                            </Col>
                            
                        </Row>
                    </div>
                    <div id="delivery-section" className="delivery-section  sectionWidth" style={{ padding: "0px 0px" }} > 
                        <Row className="rowSpace rowfield rowSpaceHeight" justify="center">
                            <Col md={10} className="widthSpecify" >
                                <Dropdown
                                    id="location"
                                    label="Location"
                                    //required={true}
                                    selectedKey={scheduleFormik.values.locationId}
                                    //options={yearValues}
                                    options={tempAvailLocationList}
                                    // onChange={(e, item)=> handleLocationChange(e, item)}
                                    placeholder=""
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        <Row className="rowSpace rowfield rowSpaceHeight " justify="center" >
                            <Col md={10} className={"request-delivery request-deliveryText "}>
                                <div className="date-time-group">
                                    <div className="auctionDate date-picker tmpDatePicker" style={{ width: "100%" }}>
                                        <label style={{ display: "block" }} className="lbl-text">Date </label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup dateTimeFieldTempAvail pickDate">
                                            <Calendar
                                                id="availDate"
                                                //label=" Requested Delivery"
                                                style={{ display: "flex" }}
                                                value={tempAvailDate}
                                                onChange={(e) => handleDateChange(e.value)}
                                                appendTo="self"
                                                showIcon
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                className="requiredSize fieldWidth"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Col md={5}>
                                    <div className="auctionStartTime time-pickerTmpAvail " style={{ width: "100%"}}>
                                        <label className="lbl-text  registerSuccess sheduleCalLabelMob " >Start</label>
                                        {/* <TimePicker minuteStep={15} showNow={false} 
                                            // use12Hours={true}
                                            //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                            hideDisabledOptions={true}
                                            format="h:mm a"
                                            value={startTime}
                                            onChange={handleStartTimeChange}
                                            allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24]}
                                            getPopupContainer={() => document.getElementById("delivery-section")}
                                            disabledSeconds={() => disabledSec}
                                            // value
                                            // onChange function(time: moment, timeString: string):
                                            // onSelect function(time: moment)
                                        /> */}
                                        
                                        <TimePicker
                                            showSecond={false}
                                            hideDisabledOptions={true}
                                            defaultValue={startTime}
                                            className="mask-FieldGroup rc-time-picker-input"
                                            onChange={handleStartTimeChange}
                                            placement="bottomLeft"
                                            focusOnOpen={true}
                                            onOpen={testOpen}
                                            onClose={testClose}
                                            open={timePickerOpen}
                                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                            format="h:mm a"
                                            use12Hours
                                            inputReadOnly
                                            minuteStep={15}
                                            popupClassName="req-del-time-panel"
                                            placeholder="Time"
                                            inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                        />
                                    </div>
                            </Col>
                            <Col md={5}>
                                    <div className="auctionStartTime time-pickerTmpAvail " style={{ width: "100%"}}>
                                        <label className="lbl-text  registerSuccess sheduleCalLabelMob " >End</label>
                                        {/* <TimePicker minuteStep={15} showNow={false} 
                                            // use12Hours={true}
                                            //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                            hideDisabledOptions={true}
                                            format="h:mm a"
                                            value={endTime}
                                            onChange={handleEndTimeChange}
                                            allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24]}
                                            getPopupContainer={() => document.getElementById("delivery-section")}
                                            disabledSeconds={() => disabledSec}
                                            // value
                                            // onChange function(time: moment, timeString: string):
                                            // onSelect function(time: moment)
                                        /> */}
                                        
                                        <TimePicker
                                            showSecond={false}
                                            hideDisabledOptions={true}
                                            defaultValue={endTime}
                                            className="mask-FieldGroup rc-time-picker-input"
                                            onChange={handleEndTimeChange}
                                            placement="bottomLeft"
                                            focusOnOpen={true}
                                            onOpen={testOpenDel}
                                            onClose={testCloseDel}
                                            open={timePickerOpenDel}
                                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                            format="h:mm a"
                                            use12Hours
                                            inputReadOnly
                                            minuteStep={15}
                                            popupClassName="req-del-time-panel"
                                            placeholder="Time"
                                            inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                        />
                                    </div>
                            </Col>
                        </Row>
                    </div>
                </>
                : null }
                
                <div className={contentStyles.footer + " inventory-popup-footer scheduleTimeAvailFooter"}>
                    <Row className="inventoryRowSection  cusNoNotFoundFoot ">
                        <Col style={{paddingLeft: 0}}>
                            <PrimaryButton
                                style={{ float: "left",backgroundColor:"#af2b01",width:'50%'}}
                                onClick={handleDelete}
                                text="Delete"
                                className="btnHoverColor cusNoNotFoundDiaBtnHeight"
                            />
                        </Col>
                        <Col style={{paddingRight: 0}}>
                            <PrimaryButton
                                text="Save"
                                style={{ float: "right",width:'50%' }}
                                onClick={handleSubmit}
                                className=" cusNoNotFoundDiaBtnHeight"
                            />

                        </Col>
                        </Row>
                </div>
            </div>
        </Modal>
    </>)
}