import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    PrimaryButton,
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
    TextField,
    Link,
    Stack,
    Checkbox
} from "@fluentui/react";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validTenDigitPhone } from "utils/helperFunctions";
import { handleUpdateEmployee, handleEmployeeActivate, handleViewEmployee, handleSaveEmployeePermissions } from "store/employees/employees.action";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "components/dialogs/employees/styles";
import { colors } from "constants/constants";
import { AppState } from "store";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Dropdown } from 'primereact/dropdown';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Tooltip } from "components/tooltip/Tooltip";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import employeeGroup from "store/employees/employeeGroup.json";
import masterDropdownValues from "constants/masterDropdownValues";
import { SheduleRecurringCalender } from "pages/dealTrackerPage/SheduleRecurringCalender";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { EmployeeActionPermission } from "hoc/withCredentials";
import { emplyeePermissionsAction } from "../../constants/constants";
import { useMediaQuery } from "react-responsive";

interface IProps {
    isEdit: boolean;
    onDismiss: () => void;
    employeeId: number;
}

const emptyForm = {
    firstName: "",
    lastName: "",
    email: "",
    workPhone: "",
    alternatePhone: "",
    workPhoneUsage: [],
    alternatePhoneUsage: [],
    textConsent: false,
    jobTitle: [],
    departmentId:"",
    primaryLocationId: "",
    avatar: "",
    isActive: true,
    defaultGroupIds: [],
    managerId: "",
    workPhoneExtension:""
}

const stackTokens = { childrenGap: 7 };

export const EmployeeDetailPivot: React.FunctionComponent<IProps> = ({
    isEdit,
    onDismiss,
    employeeId
}) => {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const examplePersona: IPersonaSharedProps = {
        imageUrl: `${require("assets/avatar-user-placeholder.png")}`
    };

    const employee = useSelector((state: AppState) => state.employees.selectedEmployee);
    const employeePermissions = useSelector((state: AppState) => state.employees.employeePermissions);
    const managers = useSelector((state: AppState) => state.employees.managers);
    const userProfile: any = useSelector((state: AppState) => state.userProfile.userProfile);
    const locationInfo = useSelector((state: AppState) => state.locations.locations) as any;

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);

    const groupList: any = employeeGroup.groups;

    const sysAdmin = '4';
    const settingAdmin = '2';
    //const buyer = '3';
    const leaderShip = '19';

    const serverError = useSelector((state: AppState) => state.employees.updateEmployeeError) as any;

    const border = {
        borderTop: `2px solid ${colors.separator}`,
        width: "100% !important",
        display: "block !important",
        paddingTop: "15px"
    };

    const [persona, setPersona] = useState(examplePersona);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [commonServerError, setcommonServerError] = useState(null);
    const [permissionFlag, setPermissionFlag] = useState(false);
    const [locations, setLocations] = useState([]);
    const [leadershipTeam, setLeadershipTeam] = useState(false);
    const [managerList, setManagerList] = useState([]);
    const [isEmpName, setEmpName] = useState("");
    const [cityDealerLists, setCityDealerLists] = React.useState([]);

    let imgFileRef: any = React.createRef();
    const [uploadError, setUploadError] = useState("");



    const formik = useFormik({
        initialValues: {
            ...emptyForm,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().max(50, "First Name must be at most 50 characters").required("Required"),
            lastName: Yup.string().max(50, "Last Name must be at most 50 characters").required("Required"),
            email: Yup.string().max(50, "Email must be at most 50 characters")
                .email("This field must be valid email user")
                .required("Required"),
            workPhone: Yup.string()
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Required"),
            alternatePhone: Yup.string()
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }),
            workPhoneUsage: Yup.array().nullable(),
            alternatePhoneUsage: Yup.array().nullable(),
            textConsent: Yup.boolean(),
            jobTitle: Yup.array().nullable(),//.min(1, "Required"),
            departmentId: Yup.string(),
            primaryLocationId: Yup.string(),
            avatar: Yup.string(),
            isActive: Yup.boolean(),
            defaultGroupIds: Yup.array().nullable(),
            managerId: Yup.string(),
            workPhoneExtension: Yup.string().max(10,'Extension must be at most 10 characters'),
        }),
        onSubmit: (values) => {
            if (values.defaultGroupIds && values.defaultGroupIds.length === 0) {
                values.defaultGroupIds.push(1);//Default User
            } else if (values.defaultGroupIds && values.defaultGroupIds.length > 1 && values.defaultGroupIds.includes(1)) {
                values.defaultGroupIds = values.defaultGroupIds.filter(item => item !== 1)
            }

            const newValues = {
                id: employee.id,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                jobTitle: values.jobTitle,
                departmentId:values.departmentId,
                workPhone: values.workPhone.replace(/\D/g, '').slice(0, 10),
                workPhoneUsage: values.workPhoneUsage,
                alternatePhone: values.alternatePhone.replace(/\D/g, '').slice(0, 10),
                alternatePhoneUsage: values.alternatePhoneUsage,
                textConsent: values.textConsent,
                avatar: values.avatar,
                isActive: values.isActive,
                managerId: managerList.some(x => x.key === values.managerId)? values.managerId:'0',
                workPhoneExtension: values.workPhoneExtension,
            };


            if (values.primaryLocationId) {
                newValues["primaryLocationId"] = values.primaryLocationId;
            }

            dispatch<any>(handleUpdateEmployee([newValues], editEmployeeCallback));
        },
    });

    const handleBackToUsers = () => {
        formik.resetForm({ values: emptyForm });
        setcommonServerError(null);
        examplePersona.imageUrl = `${require("assets/avatar-user-placeholder.png")}`;
        setPersona(examplePersona);
        setLoadingFlag(false);
        onDismiss();
    }

    useEffect(() => {
        dispatch<any>(handleGetLocations());
        //setCityDealerList1(cityDealerList.unshift({ id: 0 ,legalName: 'All'}))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (serverError) {
            setcommonServerError('Failed to Save the Data');
            window.scrollTo(0, 0);
        }
        else {
            setcommonServerError(null);
        }
    }, [serverError]);

    function dynamicSort(property) {
        var sortOrder = 1;

        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }
        }
    }

    useEffect(() => {
        if (locationInfo.results) {
            setLocations(locationInfo.results);
            let locationVal = [...locationInfo.results]
            locationVal.unshift({ id: 0 ,legalName: 'All'});
            var list = locationVal.sort(dynamicSort("legalName"));
            setCityDealerLists(list);
        }
        else {
            setLocations([]);
            setCityDealerLists([]);
        }
    }, [locationInfo]);

    useEffect(() => {
        if (isEdit && employeeId > 0) {
            dispatch<any>(handleViewEmployee(employeeId, isKarlProfileType));
            setLoadingFlag(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit]);


    useEffect(() => {
        if (employee && loadingFlag) {
            //console.log('employeelist',employee)
            let arr = [];
            if (employeePermissions && employeePermissions.length > 0) {

                employeePermissions.forEach(function (item, index) {
                    let comboId = item.defaultGroup.id + (item.location ? "-" + item.location.id : "");
                    arr.push(comboId);
                });
                setLeadershipTeam(arr.filter(x => x === leaderShip).length === 1)
            }

            formik.setValues({
                firstName: employee.firstName,
                lastName: employee.lastName,
                email: employee.email,
                jobTitle: employee.jobTitle,
                departmentId:employee.departmentId,
                primaryLocationId:employee.primaryLocation ? employee.primaryLocation.id : 0,
                workPhone: employee.workPhone,
                workPhoneUsage: employee.workPhoneUsage,
                alternatePhone: employee.alternatePhone,
                alternatePhoneUsage: employee.alternatePhoneUsage,
                textConsent: employee.textConsent,
                avatar: employee.avatar,
                isActive: employee.isActive,
                managerId: employee.manager && employee.manager.id,
                workPhoneExtension: employee.workPhoneExtension ,
                defaultGroupIds: arr
            }, true);

            examplePersona.imageUrl = employee.avatar
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${employee.avatar}` : "";
            examplePersona.imageInitials = employee?.firstName ? `${employee.firstName[0]} ${employee?.lastName ? employee.lastName[0] : ""}` : "";
            examplePersona.text = employee.firstName + ' ' + employee.lastName;
            examplePersona.secondaryText = employee.email;
            setPersona(examplePersona);
            setPermissionFlag(true);

            let empName=  employee.firstName + ' ' + employee.lastName;
            setEmpName(empName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee]);

    useEffect(() => {

        if (managers) {
            let arr = [];
            managers.filter(x => x.id !== employee.id).forEach(function (item, index) {
                let obj = { key: item.id, text: item.firstName + ' ' + item.lastName }
                arr.push(obj);
            });

            setManagerList(arr);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managers]);


    const handleUploadLink = () => {
        imgFileRef.current.click();
    }

    const handleMaskInput = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    const handleLogoChange = e => {
        e.preventDefault();
        setUploadError('');
        //setShowUnknownPersonaCoin(false);
        let reader = new FileReader();
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }
        //setError('');
        reader.onloadend = () => {
            let profile = persona;
            profile.imageUrl = reader.result.toString();

            setPersona(profile);
            formik.setValues(
                {
                    ...formik.values,
                    "avatar": file,
                },
                true
            );
        }
        reader.readAsDataURL(file)
    }

    const handleActivateUser = () => {
        dispatch<any>(handleEmployeeActivate({ id: employee.id, isActive: !formik.values.isActive }));
        onDismiss();
    }

    const editEmployeeCallback = () => {
        formik.resetForm({ values: emptyForm });
        examplePersona.imageUrl = `${require("assets/avatar-user-placeholder.png")}`;
        setPersona(examplePersona);
        onDismiss();
    };

    const handleAddEmployee = () => {
        formik.handleSubmit();
    };

    const handleEmployeePermissions = () => {
        setcommonServerError('');
        let permissions = [];

        if (isKarlProfileType) {
          

            if(!leadershipTeam){

               formik.values.defaultGroupIds.forEach(function (item, index) {
                    const comboId = item.toString().split('-');
                    let obj = {
                        "defaultGroupId": Number(comboId[0]),
                    };
                    if (comboId.length > 0) {
                        obj["locationId"] = Number(comboId[1])
                    }
                    permissions.push(obj);
                });

            } else {

                formik.values.defaultGroupIds.forEach(function (item, index) {
                    const comboId = item.toString().split('-');
                    if(comboId[0] ===sysAdmin || comboId[0] ===leaderShip){
                        let obj = {
                            "defaultGroupId": Number(comboId[0]),
                        };
                      // if (comboId.length > 0) {
                      //    obj["locationId"] = Number(comboId[1])
                      // }
                        permissions.push(obj);
                    }
                    
                });            
            }    

            
        //    if (leadershipTeam) {
        //        if (permissions.filter(x => x.defaultGroupId === Number(leaderShip)).length === 0)
        //            permissions.push({ "defaultGroupId": Number(leaderShip) });
        //    }
        //    else
        //        permissions = permissions.filter(x => x.defaultGroupId !== Number(leaderShip));
        } else
        {
            //
            //if (formik.values.defaultGroupIds && formik.values.defaultGroupIds.length === 0) {
            //    let obj = {
            //        "defaultGroupId": 1,
            //    };//Default User
            //    permissions.push(obj);
            //} else if (formik.values.defaultGroupIds && formik.values.defaultGroupIds.length > 0) {

            //    formik.values.defaultGroupIds.filter(x => x !== '1').forEach(function(item, index){
            //        let obj = {
            //            "defaultGroupId": item,
            //        };
            //        permissions.push(obj);
            //    });
            //}

            if (formik.values.defaultGroupIds && formik.values.defaultGroupIds.some(x => x === '2')) {

                let obj = {
                    "defaultGroupId": 2,
                };
                permissions.push(obj);
            }

            
            if (!permissions.some(x => x.defaultGroupId === 3)) {
                let obj = {
                    "defaultGroupId": 3,
                };
                permissions.push(obj);
            }
        }
       
        const obj = {
            "permissions": permissions
        };
        dispatch<any>(handleSaveEmployeePermissions(employee.id, obj, (res) => {
            if (!res) {
                setcommonServerError('Failed to Save the Data');
            }
        }));
    };



    const handleRoleCheckBoxChange = (e, isChecked) => {
        if (isChecked) {
            if (!formik.values.jobTitle.includes(e.target.id)) {
                let arr = formik.values.jobTitle;
                arr.push(e.target.id);

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: arr
                    }
                );
            }
        } else {
            if (formik.values.jobTitle.includes(e.target.id)) {

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: formik.values.jobTitle.filter(item => item !== e.target.id) as any,
                    }
                );
            }
        }
    };

    const handleSettingAdminToggleChange = (ev: any, checked?: boolean) => {
        handleGroupChange(checked, settingAdmin);
    };

    const handleKarlGroupSystemAdminToggleChange = (ev: any, checked?: boolean) => {
        handleGroupChange(checked, sysAdmin);
    };

    const handleKarlGroupLeaderShipToggleChange = (ev: any, checked?: boolean) => {
        handleLeardershipTeamChange(checked, leaderShip);

    };

    const handleKarlGroupToggleChange = (e: any, checked?: boolean) => {
        handleGroupChange(checked, e.target.id);
    };

    const handleGroupChange = (checked,id) => {

        if (checked) {
            if (!formik.values.defaultGroupIds.some(x => x === id)) {
                let arr = formik.values.defaultGroupIds;
                arr.push(id);

                formik.setValues(
                    {
                        ...formik.values,
                        defaultGroupIds: arr
                    }
                );
            }
        } else {
            if (formik.values.defaultGroupIds.some(x => x === id)) {
                let arr = formik.values.defaultGroupIds.filter(item => item !== id) as any;
                formik.setValues(
                    {
                        ...formik.values,
                        defaultGroupIds: arr,
                    }
                );
            }
        }

    };

    //const handleBuyerToggleChange = (ev: any, checked?: boolean) => {
    //    handleGroupChange(checked, buyer);
    //};


    const handleLeardershipTeamChange = (checked,id) => {

        if (checked) {
            if (!formik.values.defaultGroupIds.some(x => x === id)) {
                let arr = formik.values.defaultGroupIds;
                arr.push(id);

                formik.setValues(
                    {
                        ...formik.values,
                        defaultGroupIds: arr
                    }
                );
            }
            setLeadershipTeam(true);
        } else {
              const arr = formik.values.defaultGroupIds.filter(item => item === sysAdmin);
              formik.setValues(
                  {
                      ...formik.values,
                      defaultGroupIds: arr,
                  }
              );
              setLeadershipTeam(false);
        }
   
    };



   //const handleLeardershipTeamChange  = (checked,id) => {
   //
   //    if (checked) {
   //        let arr = formik.values.defaultGroupIds.filter(item => item === sysAdmin);
   //       locations.forEach(function (location, index) {
   //           groupList.slice(1).forEach(function (group, i) {
   //               //arr.push(group.id + '-' + location.id);
   //               arr.push(group.id);
   //           });
   //       });
   //
   //        formik.setValues(
   //            {
   //                ...formik.values,
   //                defaultGroupIds: arr
   //            }
   //        );
   //        setLeadershipTeam(true);
   //    } else {
   //        const arr = formik.values.defaultGroupIds.filter(item => item === sysAdmin);
   //        formik.setValues(
   //            {
   //                ...formik.values,
   //                defaultGroupIds: arr,
   //            }
   //        );
   //        setLeadershipTeam(false);
   //    }
   //
   //};

    const handleManagerChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                managerId: e.value,
            }
        );
    }

    const handleDepartmentOptionsChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                departmentId: e.value,
            }
        );
    }

    const onchangeWeeklyHoursDismiss = (msg) => {
        onDismiss();
    }

    const onLocationChange = (e: { value: any }) => {
        formik.setValues(
            {
                ...formik.values,
                primaryLocationId: e.value,
            }
        );
    }

    return (<>
        <Row className="user-edit-sec-width user-edit-section ">
            <Col>
                <Row style={{ marginTop: "25px" }}>
                    <Col>
                        {/* <Link onClick={handleBackToUsers}><ArrowBackIcon /><span className="arrow-back-text">Back</span></Link> */}
                        <img
                            alt="Back"
                            key={"back"}
                            className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                            onClick={handleBackToUsers}
                            src={`${require("assets/back_pagination.png")}`}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="alignHeaders">
                        <span className="pageName pageHeaderFont">Edit User</span>
                    </Col>
                    {
                        commonServerError ?
                            <Col>
                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                            </Col> : null
                    }
                </Row>
                {permissionFlag === true && (
                    <Row className="rowPaddingBtwSpace">
                        <Col>
                            <Persona
                                {...persona}
                                styles={styles.personInGridStyles}
                                size={PersonaSize.size100}
                                presence={PersonaPresence.none}
                                //showUnknownPersonaCoin={isShowUnknownPersonaCoin ? true : false}
                                imageAlt="Maor Sharett, status unknown"
                                className="persona-square"
                            />
                            <Link onClick={handleUploadLink}>Upload photo
                            <input
                                    style={{
                                        padding: 20,
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        width: 107,
                                        height: 1,
                                        overflow: "hidden",
                                        opacity: 0,
                                        marginLeft: -10,
                                    }}
                                    type="file"
                                    ref={imgFileRef}
                                    onChange={handleLogoChange}
                                /></Link>
                            {
                                uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                            }
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            {userProfile && userProfile.roles && (
                                <EmployeeActionPermission
                                    permissionList={userProfile.aggregatedDefaultPolicies}
                                    alias={emplyeePermissionsAction.SETTING_USER_DELETE}
                                    roles={userProfile.roles}
                                >
                                    <Link className="user-link" onClick={handleActivateUser}>{formik.values.isActive ? "Deactivate User" : "Activate User"}</Link>
                                </EmployeeActionPermission>
                            )}
                        </Col>
                    </Row>
                )}

                <Row className="rowPaddingBtwSpace">
                    <Col>
                        <Pivot
                            style={{ padding: 0, margin: 0 }}
                            className="pivotItem delar-body"
                            defaultSelectedKey="3"
                            onLinkClick={(item?: any) => {
                            }}
                        >
                            <PivotItem
                                className="pivotItem"
                                style={border}
                                headerText="User Details"
                                headerButtonProps={{
                                    "data-order": 1,
                                    "data-title": "User Details",
                                }}
                            >
                                {permissionFlag === true && (
                                    <div className="user-detail-section">
                                        {
                                            isKarlProfileType ? <>
                                           
                                            <Row className="rowPaddingBtwSpace">
                                                <Col md={12} >
                                                     <Row>
                                                         <Col md={6}>
                                                             <TextField
                                                                 id="firstName"
                                                                 name="firstName"
                                                                 label="First name"
                                                                 value={formik.values.firstName}
                                                                 //required={true}
                                                                 onChange={formik.handleChange}
                                                                 className="full-width"
                                                                 onBlur={formik.handleBlur}
                                                                 errorMessage={
                                                                     formik.touched.firstName && formik.errors.firstName
                                                                         ? formik.errors.firstName.toString()
                                                                         : null
                                                                 }
                                                             />
                                                         </Col>
                                                         <Col md={6}>
                                                             <TextField
                                                                 id="lastName"
                                                                 name="lastName"
                                                                 label="Last name"
                                                                 value={formik.values.lastName}
                                                                 //required={true}
                                                                 onChange={formik.handleChange}
                                                                 className="full-width"
                                                                 onBlur={formik.handleBlur}
                                                                 errorMessage={
                                                                     formik.touched.lastName && formik.errors.lastName
                                                                         ? formik.errors.lastName.toString()
                                                                         : null
                                                                 }
                                                             />
                                                         </Col>
                                                     </Row>
                                                </Col>
                                                <Col md={12} >
                                                    <div className="yearMake">

                                                        <label className="lbl-text ">Primary Location</label>
                                                        <PRDropdown
                                                            id="primaryLocation"
                                                            placeholder=""
                                                            value={formik.values.primaryLocationId}
                                                            options={cityDealerLists}
                                                            onChange={onLocationChange}
                                                            optionLabel="legalName"
                                                            optionValue="id"
                                                            appendTo="self"
                                                            className="location-p-dropdown-items custom-p-dropdown .customInputField "
                                                            filter={ false}
                                                            resetFilterOnHide={true}
                                                        />

                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="yearMake">
                                                        <label className="lbl-text">Department</label>
                                                        <Dropdown
                                                            id="departmentId"
                                                            value={formik.values.departmentId}
                                                            options={masterDropdownValues.departmentOptions}
                                                            optionLabel="text"
                                                            optionValue="key"
                                                            appendTo="self"
                                                            className="custom-p-dropdown customInputField"
                                                            resetFilterOnHide={true}
                                                            onChange={handleDepartmentOptionsChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="yearMake">
                                                        <label className="lbl-text">Manager</label>
                                                        <Dropdown
                                                            id="managerId"
                                                            options={managerList}
                                                            optionLabel="text"
                                                            optionValue="key"
                                                            appendTo="self"
                                                            filter
                                                            className="custom-p-dropdown customInputField"
                                                            value={formik.values.managerId}
                                                            resetFilterOnHide={true}
                                                            onChange={handleManagerChange}
                                                            required={true} />
                                                    </div>
                                                </Col>
                                            </Row>
                                                <Row className="rowPaddingBtwSpace" style={{marginTop:"-17px"}}>
                                                    <Col md={6}>
                                                        <div className="ms-TextField root-155">
                                                            <div className="ms-TextField-wrapper">
                                                                <label className="lbl-text required-Label">Phone</label>
                                                                <div className={formik.touched.workPhone &&
                                                                    formik.errors.workPhone ? "mask-FieldGroup full-width ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup full-width ms-TextField-fieldGroup"}>
                                                                    <InputMask
                                                                        //autoClear={false}
                                                                        required={true}
                                                                        id="workPhone"
                                                                        name="workPhone"
                                                                        mask="(999)999-9999"
                                                                        placeholder=""
                                                                        value={formik.values.workPhone}
                                                                        className="mask-TextField"
                                                                        onChange={(e) => handleMaskInput(e)}
                                                                        onBlur={formik.handleBlur}>
                                                                    </InputMask>
                                                                </div>
                                                                <span className="error-message">{
                                                                    formik.touched.workPhone &&
                                                                    formik.errors.workPhone &&
                                                                    formik.errors.workPhone.toString()
                                                                }</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="ms-TextField root-155">
                                                            <div className="ms-TextField-wrapper">
                                                                <label className="lbl-text">Alt phone</label>
                                                                <div className={formik.touched.alternatePhone &&
                                                                    formik.errors.alternatePhone ? "mask-FieldGroup full-width ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup full-width ms-TextField-fieldGroup"}>
                                                                    <InputMask
                                                                        //autoClear={false}
                                                                        required={true}
                                                                        id="alternatePhone"
                                                                        name="alternatePhone"
                                                                        mask="(999)999-9999"
                                                                        placeholder=""
                                                                        value={formik.values.alternatePhone}
                                                                        className="mask-TextField"
                                                                        onChange={(e) => handleMaskInput(e)}
                                                                        onBlur={formik.handleBlur}>
                                                                    </InputMask>
                                                                </div>
                                                                <span className="error-message">{
                                                                    formik.touched.alternatePhone &&
                                                                    formik.errors.alternatePhone &&
                                                                    formik.errors.alternatePhone.toString()
                                                                }</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="contentFieldSpace">
                                                    <Col>
                                                        <TextField
                                                            id="workPhoneExtension"
                                                            name="workPhoneExtension"
                                                            label="Extension"
                                                            value={formik.values.workPhoneExtension}
                                                            //required={true}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            errorMessage={
                                                                formik.touched.workPhoneExtension && formik.errors.workPhoneExtension
                                                                    ? formik.errors.workPhoneExtension.toString()
                                                                    : null
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        
                                                    </Col>
                                                </Row>
                                            </> :
                                                <>
                                                     <Row className="rowPaddingBtwSpace">
                                                        <Col md={6}>
                                                            <TextField
                                                                id="firstName"
                                                                name="firstName"
                                                                label="First name"
                                                                value={formik.values.firstName}
                                                                //required={true}
                                                                onChange={formik.handleChange}
                                                                className="full-width"
                                                                onBlur={formik.handleBlur}
                                                                errorMessage={
                                                                    formik.touched.firstName && formik.errors.firstName
                                                                        ? formik.errors.firstName.toString()
                                                                        : null
                                                                }
                                                            />
                                                        </Col>
                                                        <Col md={6}>
                                                            <TextField
                                                                id="lastName"
                                                                name="lastName"
                                                                label="Last name"
                                                                value={formik.values.lastName}
                                                                //required={true}
                                                                onChange={formik.handleChange}
                                                                className="full-width"
                                                                onBlur={formik.handleBlur}
                                                                errorMessage={
                                                                    formik.touched.lastName && formik.errors.lastName
                                                                        ? formik.errors.lastName.toString()
                                                                        : null
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>                                                
                                                    <Row className="rowPaddingBtwSpace">
                                                        <Col md={12} >
                                                            <div className="yearMake">
                                                                <label className="lbl-text">Primary Location</label>
                                                                <PRDropdown
                                                                    id="primaryLocationId"
                                                                    options={cityDealerLists}
                                                                    optionLabel="legalName"
                                                                    optionValue="id"
                                                                    appendTo="self"
                                                                    //filter
                                                                    className="custom-p-dropdown customInputField"
                                                                    value={formik.values.primaryLocationId}
                                                                    resetFilterOnHide={true}
                                                                    onChange={onLocationChange}
                                                                    filter={ false}
                                                                    required={true} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="rowPaddingBtwSpace">
                                                        <Col md={6}>

                                                            <div className="ms-TextField root-155">
                                                                <div className="ms-TextField-wrapper">
                                                                    <label className="lbl-text required-Label">Phone</label>
                                                                    <div className={formik.touched.workPhone &&
                                                                        formik.errors.workPhone ? "mask-FieldGroup full-width ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup full-width ms-TextField-fieldGroup"}>
                                                                        <InputMask
                                                                            //autoClear={false}
                                                                            required={true}
                                                                            id="workPhone"
                                                                            name="workPhone"
                                                                            mask="(999)999-9999"
                                                                            placeholder=""
                                                                            value={formik.values.workPhone}
                                                                            className="mask-TextField"
                                                                            onChange={(e) => handleMaskInput(e)}
                                                                            onBlur={formik.handleBlur}>
                                                                        </InputMask>
                                                                    </div>
                                                                    <span className="error-message">{
                                                                        formik.touched.workPhone &&
                                                                        formik.errors.workPhone &&
                                                                        formik.errors.workPhone.toString()
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="ms-TextField root-155">
                                                                <div className="ms-TextField-wrapper">
                                                                    <label className="lbl-text">Alt phone</label>
                                                                    <div className={formik.touched.alternatePhone &&
                                                                        formik.errors.alternatePhone ? "mask-FieldGroup full-width ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup full-width ms-TextField-fieldGroup"}>
                                                                        <InputMask
                                                                            //autoClear={false}
                                                                            required={true}
                                                                            id="alternatePhone"
                                                                            name="alternatePhone"
                                                                            mask="(999)999-9999"
                                                                            placeholder=""
                                                                            value={formik.values.alternatePhone}
                                                                            className="mask-TextField"
                                                                            onChange={(e) => handleMaskInput(e)}
                                                                            onBlur={formik.handleBlur}>
                                                                        </InputMask>
                                                                    </div>
                                                                    <span className="error-message">{
                                                                        formik.touched.alternatePhone &&
                                                                        formik.errors.alternatePhone &&
                                                                        formik.errors.alternatePhone.toString()
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Stack tokens={stackTokens} className="checkbox-list">
                                                                <label style={{ fontSize: "14px" }} className="lbl-text">What role(s) does this user play? Select all that apply.</label>
                                                                <Checkbox
                                                                    label="Owner"
                                                                    id="owner"
                                                                    onChange={handleRoleCheckBoxChange}
                                                                    checked={formik.values.jobTitle.includes("owner")}
                                                                />
                                                                <Checkbox
                                                                    label="General Manager"
                                                                    id="general-manager"
                                                                    onChange={handleRoleCheckBoxChange}
                                                                    checked={formik.values.jobTitle.includes("general-manager")}
                                                                />
                                                                <Checkbox
                                                                    label="Office Manager"
                                                                    id="office-manager"
                                                                    onChange={handleRoleCheckBoxChange}
                                                                    checked={formik.values.jobTitle.includes("office-manager")}
                                                                />
                                                                <Checkbox
                                                                    label="Sales"
                                                                    id="sales"
                                                                    onChange={handleRoleCheckBoxChange}
                                                                    checked={formik.values.jobTitle.includes("sales")}
                                                                />
                                                                <Checkbox
                                                                    label="F&I"
                                                                    id="f-and-i"
                                                                    onChange={handleRoleCheckBoxChange}
                                                                    checked={formik.values.jobTitle.includes("f-and-i")}
                                                                />
                                                            </Stack>
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                        <Row className="rowPaddingBtwSpace">
                                            <Col className="user-action">
                                                {userProfile && userProfile.roles && (
                                                    <EmployeeActionPermission
                                                        permissionList={userProfile.aggregatedDefaultPolicies}
                                                        alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                        roles={userProfile.roles}
                                                    >
                                                        <PrimaryButton
                                                            // disabled={!formik.dirty || !formik.isValid}
                                                            text="Save"
                                                            className="float-right"
                                                            onClick={handleAddEmployee}
                                                        />
                                                    </EmployeeActionPermission>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                )}


                            </PivotItem>
                            <PivotItem
                                className="pivotItem"
                                style={border}
                                headerText="Permissions"
                                headerButtonProps={{
                                    "data-order": 1,
                                    "data-title": "Permissions",
                                }}
                            >
                                <div className="permission-section">
                                    {
                                        isKarlProfileType ? <>
                                            <Row>
                                                <Col>
                                                    {permissionFlag ? <>
                                                        <Stack className="user-permissions" tokens={stackTokens}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div className="permission-toggle-edit-karl">
                                                                        <Toggle
                                                                            label="System Admin"
                                                                            defaultChecked={formik.values.defaultGroupIds && formik.values.defaultGroupIds.some(x => x === sysAdmin)}
                                                                            id={sysAdmin}
                                                                            onChange={handleKarlGroupSystemAdminToggleChange}
                                                                            inlineLabel
                                                                            onText=" "
                                                                            offText=" " />
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className="permission-toggle-edit-karl">
                                                                        <Toggle
                                                                            label="Leadership Team"
                                                                            id={leaderShip}
                                                                            defaultChecked={formik.values.defaultGroupIds && formik.values.defaultGroupIds.some(x => x === leaderShip)}
                                                                            onChange={handleKarlGroupLeaderShipToggleChange}
                                                                            inlineLabel
                                                                            onText=" "
                                                                            offText=" " />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Stack>
                                                    </> : null
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={11}>
                                                    <div id="table-scroll" className="table-scroll">
                                                        <div className="table-wrap">
                                                            <table className="permission-table">
                                                                <thead>
                                                                    <tr className="border-bottom">
                                                                        <th scope="col" className="fixed-side">
                                                                        </th>
                                                                        {
                                                                            groupList && groupList.length && groupList.slice(2).map((item, index) => (
                                                                                <th key={"header-" + index} scope="col" className={item.id === 8 || item.id === 7 ? "border-right" : ""}><label className="lbl-text">{item.name}</label></th>
                                                                            ))
                                                                        }

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {locations && locations.length && locations.map((item, index) => (
                                                                        <tr className="border-bottom locations-row" key={"boxes-" + index}>
                                                                            <td className="fixed-side">
                                                                                <label className="lbl-text">{item.legalName}</label>
                                                                            </td>
                                                                            {groupList && groupList.length && groupList.slice(2).map((group, i) => (
                                                                                <td key={"checkbox-" + i} className={group.id === 8 || group.id === 7 ? "border-right" : ""}>
                                                                                    <Checkbox
                                                                                        label=""
                                                                                        id={group.id + '-' + item.id}
                                                                                        onChange={handleKarlGroupToggleChange}
                                                                                        disabled={leadershipTeam}
                                                                                        checked={! leadershipTeam && formik.values.defaultGroupIds.includes(group.id + '-' + item.id)}
                                                                                    />
                                                                                </td>
                                                                            ))
                                                                            }


                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </> : <>
                                                <Row>
                                                    <Col>
                                                        {permissionFlag ? <>
                                                            <Stack className="user-permissions" tokens={stackTokens} style={{ padding: "15px" }}>
                                                                <div className="permission-toggle-edit">
                                                                    <Toggle
                                                                        label="Settings Administrator"
                                                                        defaultChecked={formik.values.defaultGroupIds && formik.values.defaultGroupIds.includes(settingAdmin)}
                                                                        id="2"
                                                                        onChange={handleSettingAdminToggleChange}
                                                                        inlineLabel
                                                                        onText=" "
                                                                        offText=" " />
                                                                    <Tooltip
                                                                        desc="The Settings Admin role allows this user to add/remove other users and change account, payment, and service settings." />
                                                                </div>
                                                                {/*<div className="permission-toggle-edit">
                                                                    <Toggle
                                                                        label="Manager"
                                                                        id="3"
                                                                        defaultChecked={formik.values.defaultGroupIds && formik.values.defaultGroupIds.includes(buyer)}
                                                                        onChange={handleBuyerToggleChange}
                                                                        inlineLabel
                                                                        onText=" "
                                                                        offText=" " />
                                                                    <Tooltip
                                                                        desc="The Manager role allows this user to change vehicle inventory statuses, record vehicle expenses, and change vehicle list prices." />
                                                                </div>*/}
                                                            </Stack>
                                                        </> : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                    }

                                    <Row className="rowPaddingBtwSpace">
                                        <Col className="user-action">
                                            {userProfile && userProfile.roles && (
                                                <EmployeeActionPermission
                                                    permissionList={userProfile.aggregatedDefaultPolicies}
                                                    alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                    roles={userProfile.roles}
                                                >
                                                    <PrimaryButton
                                                        // disabled={!formik.dirty || !formik.isValid}
                                                        text="Save"
                                                        className="float-right"
                                                        onClick={handleEmployeePermissions}
                                                    />
                                                </EmployeeActionPermission>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </PivotItem>

                            {  isKarlProfileType && (
                                <PivotItem
                                    className="pivotItem"
                                    style={border}
                                    headerText="Weekly Hours"
                                    headerButtonProps={{
                                        "data-order": 1,
                                        "data-title": "Weekly Hours",
                                    }}
                                >
                                    < SheduleRecurringCalender
                                        title = {isEmpName}
                                        employeesID={employeeId}
                                        isEdits={isEdit}
                                        onDismiss={onchangeWeeklyHoursDismiss}
                                    />
                                </PivotItem>
                            )}
                        </Pivot>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
};