import client, { clientPrint, clientExport, clientFile, clientV2 } from "services/api/client";
import { IPagination } from "types/paginationTypes";

import {
  ICreateInventory,
  IInventory,
  IUpdateInventory,
} from "types/inventoryTypes";
import { trimTypes } from "constants/constants";

export const getInventoryList = async (request: IPagination, filters: any) => {
  const { sort, page, limit, column, phrase } = request;

  console.log(filters);

  //DONE
  //location: number;
  // bodyStyle: string;
  // minPrice: string;
  // maxPrice: string;
  // minYear: number;
  // maxYear: number;
  // mileage: string;
  // vehicleHistory: string[];
  // invoice: boolean;
  // leasing: boolean;
  // make: { key: number; text: string };
  // model: { key: number; text: string };
  // driveType: string;
  // fuelType: string;
  // engineCylinders: string;
  // engineDescription: string;
  // transmission: string;
  // exteriorColor: string;
  // intColorGeneric: string;
  // doors: string;
  // body: string;

  //TODO
  // condition: string[];

  // audioOptions: string[];
  // powerOptions: string[];
  // interiorOptions: string[];
  // exteriorOptions: string[];
  // safetyOptions: string[];
  // luxuryOptions: string[];
  // otherOptions: string[];

  

  const query = `user/master/locations/inventories?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=${limit}&column=${column ? column : "inventory.id"}&location=${
    filters.location
  }&body=${filters.body ? filters.body : ""}&minPrice=${
    filters.minPrice ? filters.minPrice : null
  }&maxPrice=${filters.maxPrice ? filters.maxPrice : null}&minYear=${
    filters.minYear
  }&maxYear=${filters.maxYear}&mileage=${filters.mileage}&vehHistory=${
    filters.vehicleHistory
  }&invoice=${filters.invoice ? 1 : 0}&leasing=${
    filters.leasing ? 1 : 0
  }&make=${filters.make.text}&models=${filters.model.text}&driveType=${
    filters.driveType ? filters.driveType : ""
  }&fuelType=${filters.fuelType ? filters.fuelType : ""}&engineCylinders=${
    filters.engineCylinders ? filters.engineCylinders : ""
  }&engineDescription=${
    filters.engineDescription ? filters.engineDescription : ""
  }&transmission=${
    filters.transmission ? filters.transmission : ""
  }&exteriorColor=${
    filters.exteriorColor ? filters.exteriorColor : ""
  }&intColorGeneric=${
    filters.intColorGeneric ? filters.intColorGeneric : ""
  }&doors=${filters.doors ? filters.doors : ""}&filter=${
    Boolean(phrase) ? phrase : ""
  }
`;

  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getInventoryFilterList = async (moduleName: string) => {
  const query = `user/master/filter?module=${moduleName}`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getVehicles = async () => {
    return client('user/master/vehicles?limit=100000&page=1', { body: null }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getShopWorkBookList = async (status: string) => {
    let query = '?limit=10000&page=1&status=' + status;
    if (status === 'archive') {
        query += '&&column=statusUpdatedAt';
    }

    return client('user/master/vehicles/shop' + query, { body: null }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getPurchaseWorkBookList = async () => {
    const query = '?limit=10000&page=1';

    return client('user/master/vehicles/purchase' + query, { body: null }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getSoldWorkBookList = async () => {
    const query = '?limit=10000&page=1';

    return client('user/master/vehicles/sold' + query, { body: null }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getInventoryTrimVIN = async (vin: string, id: number, isKarlProfile: boolean, trimType: number) => {
    console.log('getInventoryTrimVIN')
    let body = {
        vin
    };
    let method = 'POST';
    let query = `user/master/vehicles/search/vin`;
    if (isKarlProfile) {
        query = trimType === trimTypes.tradeIn ? `user/master/deals/${id}/tradeIns/search/vin` : `user/master/deals/${id}/vehicles/search/vin`;
    }
        
    return clientV2(query, { body, method }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };  
        }
    );
};

export const getInventoryVehByID = async (vehID: any) => {
  const query = `user/master/vehicles/${vehID}`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getInventoryPricing = async (vehID: any) => {
  const query = `user/master/vehicles/${vehID}/pricings`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveInventoryPricing = async (vehInfo: any) => {
  let query;
  if (vehInfo.method === 'PUT') {
    query = `user/master/vehicles/${vehInfo.id}/pricings/${vehInfo.pricingID}`;
  } else {
    query = `user/master/vehicles/${vehInfo.id}/pricings`;
  }
  let method = vehInfo.method
  delete vehInfo.id;
  delete vehInfo.method;
  delete vehInfo.pricingID;
  return client(query, { body: vehInfo, method: method }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getInventoryQvaluePlot = async (vehID: any) => {
  const query = `user/master/vehicles/${vehID}/live/retail/plots`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getInventoryQvalueNotes = async (vehID: any) => {
  const query = `user/master/vehicles/${vehID}/notes`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveInventoryQvalueNotes = async (vehID: any, details: any) => {
  const query = `user/master/vehicles/${vehID}/notes`;
  return client(query, { body: details, method: 'POST' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const purchaseVehicleUndo = async (id: any) => {
  const query = `user/master/vehicles/${id}/purchase/undo`;
  return client(query, { body: null, method: 'POST'}).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const sellVehicleUndo = async (id: any) => {
  const query = `user/master/vehicles/${id}/sell/undo`;
  return client(query, { body: null, method: 'POST'}).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getInventoryPricingCategory = async () => {
  const query = `user/master/vehicles/pricings/categories`;   
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveSourceWorkBook = async (vehDetails: any) => {
  let body = {
    ...vehDetails
  };
  let method = 'PUT';

  const query = `user/master/vehicles/${vehDetails.id}/seller`;
  return client(query, { body, method }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveVehicleExpenses = async (expenses: any) => {
    let body = {
        ...expenses
    };
    let method = 'PUT';

    const query = `user/master/vehicles/${expenses.id}/expenses`;
    return client(query, { body, method }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getVehicleExpenses = async (id: any) => {

    const query = `user/master/vehicles/${id}/expenses`;
    return client(query, { body: null }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getLiveRetailReports = async (vehId: any) => {

  const query = `user/master/vehicles/${vehId}/live/retail`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getFilterLiveRetailReports = async (vehId: any) => {

  const query = `user/master/vehicles/${vehId}/live/retail/stats/v2`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const putLiveRetailReports = async (vehInfo: any) => {
  let body = {
    ...vehInfo
  };

  const query = `user/master/vehicles/${vehInfo.id}/live/retail`;
  delete body.id;
  
  return client(query, { body, method: 'PUT' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const activateVehicle = async (id: number | string) => {
    return client(`user/master/vehicles/${id}/activate`, {body: null, method: "PATCH"}).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const archiveVehicle = async (id: number | string) => {
    return client(`user/master/vehicles/${id}/archive`, { body: null, method: "PATCH" }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const purchaseVehicle = async (id: number | string, purchaseVehicleInfo: any) => {
  const method = purchaseVehicleInfo.httpMethod ? purchaseVehicleInfo.httpMethod : 'POST';
  delete purchaseVehicleInfo.httpMethod;

  return client(`user/master/vehicles/${id}/purchase`, { body: purchaseVehicleInfo, method }).then(
      (dataFilter) => {
          return { dataFilter, errorFilter: null };
      },
      (errorFilter) => {
          return { dataFilter: null, errorFilter };
      }
  );
};

export const sellVehicle = async (id:number | string, sellVehicleInfo: any) => {
    return client(`user/master/vehicles/${id}/sell`, { body: sellVehicleInfo, method: "POST" }).then(
      
        (dataFilter) => {
          
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getSourceWorkBook = async (vehId: any) => {
  const query = `user/master/vehicles/${vehId}/seller`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getValuations = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations`;
    return client(query, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getValuationsNada = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/nada`;
    return client(query, { body: null }).then(
        (nadaData) => {
            return { nadaData, nadaError: null };
        },
        (nadaError) => {
            return { nadaData: null, nadaError };
        }
    );
};

export const getValuationsKBB = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/kbb`;
    return client(query, { body: null }).then(
        (kbbData) => {
            return { kbbData, kbbError: null };
        },
        (kbbError) => {
            return { kbbData: null, kbbError };
        }
    );
};

export const getValuationsBlackBook = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/blackbook`;
    return client(query, { body: null }).then(
        (bbData) => {
            return { bbData, bbError: null };
        },
        (bbError) => {
            return { bbData: null, bbError };
        }
    );
};

export const getValuationsNadaStyles = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/nada/styles`;
    return client(query, { body: null }).then(
        (nadaData) => {
            return { nadaData, nadaError: null };
        },
        (nadaError) => {
            return { nadaData: null, nadaError };
        }
    );
};

export const getValuationsKBBStyles = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/kbb/styles`;
    return client(query, { body: null }).then(
        (kbbData) => {
            return { kbbData, kbbError: null };
        },
        (kbbError) => {
            return { kbbData: null, kbbError };
        }
    );
};

export const getValuationsBlackBookStyles = async (vehId: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/blackbook/styles`;
    return client(query, { body: null }).then(
        (bbData) => {
            return { bbData, bbError: null };
        },
        (bbError) => {
            return { bbData: null, bbError };
        }
    );
};

export const putValuationsNada = async (vehId: any, qValue: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/nada`;

    return client(query, { body: qValue, method: 'PUT' }).then(
        (nadaData) => {
            return { nadaData, nadaError: null };
        },
        (nadaError) => {
            return { nadaData: null, nadaError };
        }
    );
};

export const vehicleLiveRetailFilter = async (id: any, formData: any) => {
    const query = `user/master/vehicles/${id}/live/retail/filters`;

    return client(query, { body: formData, method: 'PUT' }).then(
        (result) => {
            return { result, error: null };
        },
        (error) => {
            return { result: null, error };
        }
    );
};

export const putValuationsKBB = async (vehId: any, qValue: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/kbb`;

    return client(query, { body: qValue, method: 'PUT' }).then(
        (kbbData) => {
            return { kbbData, kbbError: null };
        },
        (kbbError) => {
            return { kbbData: null, kbbError };
        }
    );
};

export const putValuationsBlackBook = async (vehId: any, qValue: any) => {
    const query = `user/master/vehicles/${vehId}/valuations/blackbook`;

    return client(query, { body: qValue, method: 'PUT' }).then(
        (bbData) => {
            return { bbData, bbError: null };
        },
        (bbError) => {
            return { bbData: null, bbError };
        }
    );
};

export const getInventoryTrimYMM = async (ymm: any, id: number, isKarlProfile: boolean, trimType: number) => {
    console.log('getInventoryTrimYMM')
    let query = `user/master/vehicles/search/ymm`;
    if (isKarlProfile) {
        query = trimType === trimTypes.tradeIn ? `user/master/deals/${id}/tradeIns/search/ymm` : `user/master/deals/${id}/vehicles/search/ymm`;
    }
    return client(query, { body: ymm, method: 'POST' }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getInventoryVehDetails = async (ymm: any, id: number, isKarlProfile: boolean, trimType: number, tradeInId?: number) => {
    console.log('getInventoryVehDetails')
    let query;
    if (ymm && ymm.year) {
        query = `user/master/vehicles/ymm`;
        if (isKarlProfile) {
            query = trimType === trimTypes.tradeIn ? `user/master/deals/${id}/tradeIns/${tradeInId}/ymm` : `user/master/deals/${id}/vehicles/ymm`;
        }
            
    } else {
        query = `user/master/vehicles/vin`;
        if (isKarlProfile) {
            query = trimType === trimTypes.tradeIn ? `user/master/deals/${id}/tradeIns/${tradeInId}/vin` : `user/master/deals/${id}/vehicles/vin`;
        }
            
    }
    return client(query, { body: ymm, method: 'POST' }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getInventoryAdjusterList = async (id: any, reportType) => {
  let query = `user/master/vehicles/${id}/valuations/${reportType}/adjusters`;

  return client(query, { body: null, method: 'GET' }).then(
    (adjusterList) => {
      return { adjusterList, errorFilter: null };
    },
    (errorList) => {
      return { adjusterList: null, errorList };
    }
  );
};

export const putInventoryAdjusterList = async (id: any, reportType, payload) => {
  let query = `user/master/vehicles/${id}/valuations/${reportType}/adjusters`;

  return client(query, { body: payload, method: 'PUT' }).then(
    (adjusterList) => {
      return { adjusterList, errorFilter: null };
    },
    (errorList) => {
      return { adjusterList: null, errorList };
    }
  );
};

export const putInventoryVehDetails = async (id: any, ymm: any) => {
  let query = `user/master/vehicles/${id}`;
  
  return client(query, { body: ymm, method: 'PUT' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getInventoryVehSellerDetails = async () => {
  // const query = `user/master/vehicles/${id}/seller`;
  const query = `user/master/vehicles/sellers`;
  return client(query, { body: null, method: 'GET' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getInventorySelectedFilterContent = async (id: string) => {

  const query = `user/master/filter/${id}`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveInventoryFilter = async (request: any, filters: any) => {  
  let query;
  let body;
  let method;
  if (request.filterId) {
    query = `user/master/filter/${request.filterId}`;
    body = filters;
    method = 'PATCH';
  } else {
    query = `user/master/filter`;
    body = filters;
    method = 'POST';
  }

  // const query = `user/master/filter`;
  return client(query, { body, method }).then(
    (saveData) => {
      return { saveData, saveError: null };
    },
    (saveError) => {
      return { saveData: null, saveError };
    }
  );

};

export const deleteInventoryFilter = async (filterName: any, filters: any) => {
  const query = `user/master/filter/${filterName}`;
  return client(query, { body: null, method: "DELETE"}).then(
    (data) => {
      return { data, saveerror: null };
    },
    (saveerror) => {
      return { data: null, saveerror };
    }
  );

};

export const createInventory = (inventory: ICreateInventory) => {
  const id = inventory.locationId;

  delete inventory.locationId;
  delete inventory.photoUrls;
  delete inventory.isFavourite;  

  const query = `user/master/location/${id}/inventory`;
  return client(query, { body: inventory }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const viewInventory = (id: number | string) => {
  return client(`user/master/locations/inventory/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const printInventoryInfoPDF = async (id: number | string) => {
  
  return clientPrint(`user/master/locations/inventory/${id}/pdf/info`, { 
    body: null,
    //responseType: 'application/pdf'
    method: "GET",

   });
};

export const printInventoryGuidePDF = (id: number | string) => {
  return clientPrint(`user/master/locations/inventory/${id}/pdf/guide`, { body: null, method: 'GET' });
};

export const exportInvenotory = (ids: any) => {
  if (ids.length) {
    return clientExport(`user/master/locations/inventories/csv?id=${ids.toString()}`, { body: null });
  } else {
    return clientExport(`user/master/locations/inventories/csv`, { body: null });
  }
};

export const deleteInventory = (id: number | string) => {
  return client(`user/master/locations/inventory/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateInventory = (id: number | string, data: IInventory) => {
  return client(`user/master/location/${data.location.id}/inventory/${id}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const editInventory = ({
  locationId,
  inventoryId,
  data,
}: {
  locationId: number | string;
  inventoryId: number | string;
  data: IUpdateInventory;
    }) => {
    
  return client(`user/master/location/${locationId}/inventory/${inventoryId}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const addPhoto = (inventoryId: string | number, fileData: any) => {
  return clientFile(`user/master/locations/inventory/${inventoryId}/photo`, {
    body: fileData,
  }).then(
    (data) => {
      console.log(data);
      
      return { data, error: null };
    },
    (error) => {
      console.log(error);
      
      return { data: null, error };
    }
  );
};

export const addVideo = (inventoryId: string | number, fileData: any) => {
  return clientFile(`user/master/locations/inventory/${inventoryId}/video`, {
    body: fileData,
  }).then(
    (data) => {
      console.log(data);
      
      return { data, error: null };
    },
    (error) => {
      console.log(error);
      
      return { data: null, error };
    }
  );
};

export const uploadInventories = (payload: any) => {
  
  return clientFile(`user/master/location/${payload.location}/inventory/csv`, {
    body: payload.formData
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const deletePhoto = (inventoryId: string | number, photo: string) => {
  return client(`user/master/locations/inventory/${inventoryId}/photo`, {
    body: { photo: photo },
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteVideo = (inventoryId: string | number) => {
  return client(`user/master/locations/inventory/${inventoryId}/video`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, videoError: null };
    },
    (videoError) => {
      return { data: null, videoError };
    }
  );
};

export const getDataFromVin = (vin: string) => {
  return client(`user/master/nhtsa/vin/${vin}`, {
    body: null,
    method: "GET",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getValuationInformation = (
  inventoryId: string,
  apiSource: string
) => {
  
  return client(`user/master/valuation/inventory/${inventoryId}/${apiSource}`, {
    body: null,
    method: "GET",
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const manageFavoriteInventory = (inventoryId,isFavorite) => {
    let url = `user/master/vehicles/${inventoryId}/favorite/add`;
    if (!isFavorite)
        url = `user/master/vehicles/${inventoryId}/favorite/remove`;

    return client(url, {
        body: null,
        method: "PATCH",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


/* DELETE EXPENSE */

export const deleteExpense = async (id: any, vehiclePricing: any) => {

  const query = `user/master/vehicles/${id}/pricings/${vehiclePricing}`;
  return client(query, { body: null, method: 'DELETE'  }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

/* Get Active Appraisal,Active and Sold MTD*/

export const getVehicleStats = async () => {

    return client(`user/master/vehicles/stats`, { body: null, method: 'GET'  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      console.log('StatsError',error)
      return { data: null, error };
    }
  );
};

/** Update Vehicle - stockAppLoc */
export const updateVehicle = (id, stockAppLoc) => {
    return client(`user/master/vehicles/${id}/stockAppLoc`, {
        body: stockAppLoc,
        method: "PATCH",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};