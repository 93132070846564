import {
  IProspect,
  IProspects,
  IProspectsFilterSettings,
} from "types/prospectTypes";
import * as actions from "store/prospects/prospects.types";
import {
  HANDLE_PROSPECT_ASSIGN_EMPLOYEE,
  PROSPECT_ASSIGN_EMPLOYEE_PENDING,
  PROSPECT_ASSIGN_EMPLOYEE_RESOLVED,
  PROSPECT_ASSIGN_EMPLOYEE_REJECTED,
  SET_SORT_PROSPECTS,
  HANDLE_PROSPECT_UNASSIGN_EMPLOYEE,
  PROSPECT_UNASSIGN_EMPLOYEE_PENDING,
  PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED,
  PROSPECT_UNASSIGN_EMPLOYEE_REJECTED,
  HANDLE_ADD_TASK,
  ADD_TASK_REJECTED,
  ADD_TASK_RESOLVED,
  ADD_TASK_PENDING,
  HANDLE_REMOVE_TASK,
  REMOVE_TASK_PENDING,
  REMOVE_TASK_REJECTED,
  REMOVE_TASK_RESOLVED,
  //HANDLE_GET_TASKS,
  //GET_TASKS_PENDING,
  //GET_TASKS_REJECTED,
  //GET_TASKS_RESOLVED,
  HANDLE_UPDATE_TASK,
  UPDATE_TASK_PENDING,
  UPDATE_TASK_REJECTED,
  UPDATE_TASK_RESOLVED,
} from "store/prospects/prospects.types";
import { IPagination } from "types/paginationTypes";
import {
    ITask,
    //ITasks,
    ITaskToUpdate
} from "types/taskTypes";
import { CheckIfProspectExistState } from "constants/constants";

export const handleCheckIfProspectExist = (
  email
): actions.HandleCheckIfProspectExist => {
  
  return {
    type: actions.HANDLE_CHECK_IF_PROSPECT_EXIST,
    payload: email,
  };
};

export const checkIfProspectExist = (
  state: keyof typeof CheckIfProspectExistState
): actions.CheckIfProspectExist => {
  return {
    type: actions.CHECK_IF_PROSPECT_EXIST,
    payload: state,
  };
};

export const handleCreateProspect = (
  prospect,
  callback
): actions.HandleCreateProspect => {
  return {
    type: actions.HANDLE_CREATE_PROSPECT,
    payload: { prospect, callback },
  };
};

export const pendingCreateProspect = (): actions.CreateProspectPending => {
  return {
    type: actions.CREATE_PROSPECT_PENDING,
  };
};

export const resolvedCreateProspect = (
  prospect: IProspect
): actions.CreateProspectResolved => {
  return {
    type: actions.CREATE_PROSPECT_RESOLVED,
    payload: prospect,
  };
};

export const rejectedCreateProspect = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.CreateProspectRejected => {
  return {
    type: actions.CREATE_PROSPECT_REJECTED,
    payload: errors,
  };
};

export const handleGetProspects = (request: IPagination, type: number = 1) => {
  return {
    type: actions.HANDLE_GET_PROSPECTS,
    payload: { pagination: request, type },
  };
};

export const pendingGetProspects = (): actions.GetProspectsPending => {
  return {
    type: actions.GET_PROSPECTS_PENDING,
  };
};

export const resolvedGetProspects = (
  prospects: IProspects
): actions.GetProspectsResolved => {
  return {
    type: actions.GET_PROSPECTS_RESOLVED,
    payload: prospects,
  };
};

export const rejectedGetProspects = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetProspectsRejected => {
  return {
    type: actions.GET_PROSPECTS_REJECTED,
    payload: errors,
  };
};
export const setRowsPerPage = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_PROSPECTS,
    payload: rows,
  };
};

/** UPDATE_PROSPECT */
export const handleUpdateProspect = (
  prospects,
  callback
): actions.HandleUpdateProspect => {
  
  return {
    type: actions.HANDLE_UPDATE_PROSPECT,
    payload: { prospects, callback },
  };
};

export const pendingUpdateProspect = (): actions.UpdateProspectPending => {
  return {
    type: actions.UPDATE_PROSPECT_PENDING,
  };
};

export const resolvedUpdateProspect = (): actions.UpdateProspectResolved => {
  return {
    type: actions.UPDATE_PROSPECT_RESOLVED,
  };
};

export const rejectedUpdateProspect = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateProspectRejected => {
  return {
    type: actions.UPDATE_PROSPECT_REJECTED,
    payload: errors,
  };
};

/** VIEW_PROSPECT */
export const handleViewProspect = (
  id: number | string
): actions.HandleViewProspect => {
  return {
    type: actions.HANDLE_VIEW_PROSPECT,
    payload: id,
  };
};

export const pendingViewProspect = (): actions.ViewProspectPending => {
  return {
    type: actions.VIEW_PROSPECT_PENDING,
  };
};

export const resolvedViewProspect = (
  prospect: IProspect
): actions.ViewProspectResolved => {
  return {
    type: actions.VIEW_PROSPECT_RESOLVED,
    payload: prospect,
  };
};

export const rejectedViewProspect = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewProspectRejected => {
  return {
    type: actions.VIEW_PROSPECT_REJECTED,
    payload: errors,
  };
};

/** DELETE_Prospect */
export const handleDeleteProspect = (
  id: string[] | number[],
  callback?: Function
): actions.HandleDeleteProspect => {
  console.log("id from action", id);
  return {
    type: actions.HANDLE_DELETE_PROSPECT,
    payload: { ids: id, callback },
  };
};

export const pendingDeleteProspect = (): actions.DeleteProspectPending => {
  return {
    type: actions.DELETE_PROSPECT_PENDING,
  };
};

export const resolvedDeleteProspect = (): actions.DeleteProspectResolved => {
  return {
    type: actions.DELETE_PROSPECT_RESOLVED,
  };
};

export const rejectedDeleteProspect = (
  errors: object
): actions.DeleteProspectRejected => {
  return {
    type: actions.DELETE_PROSPECT_REJECTED,
    payload: errors,
  };
};

export const setSortProspects = (sort: string): actions.SetSortProspects => {
  return {
    type: SET_SORT_PROSPECTS,
    payload: sort,
  };
};

export const handleProspectAssignEmployee = ({
  prospectId,
  employeeId,
  callback,
}): actions.HandleProspectAssignEmployee => {
  return {
    type: HANDLE_PROSPECT_ASSIGN_EMPLOYEE,
    payload: { prospectId, employeeId, callback },
  };
};

export const pendingProspectAssignEmployee = (): actions.ProspectAssignEmployeePending => {
  return {
    type: PROSPECT_ASSIGN_EMPLOYEE_PENDING,
  };
};

export const resolvedProspectAssignEmployee = (): actions.ProspectAssignEmployeeResolved => {
  return {
    type: PROSPECT_ASSIGN_EMPLOYEE_RESOLVED,
  };
};

export const rejectedProspectAssignEmployee = (
  error
): actions.ProspectAssignEmployeeRejected => {
  return {
    type: PROSPECT_ASSIGN_EMPLOYEE_REJECTED,
    payload: error,
  };
};

export const handleProspectUnassignEmployee = ({
  prospectId,
  employeeId,
  callback,
}): actions.HandleProspectUnassignEmployee => {
  return {
    type: HANDLE_PROSPECT_UNASSIGN_EMPLOYEE,
    payload: { prospectId, employeeId, callback },
  };
};

export const pendingProspectUnassignEmployee = (): actions.ProspectUnassignEmployeePending => {
  return {
    type: PROSPECT_UNASSIGN_EMPLOYEE_PENDING,
  };
};

export const resolvedProspectUnassignEmployee = (): actions.ProspectUnassignEmployeeResolved => {
  return {
    type: PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED,
  };
};

export const rejectedProspectUnassignEmployee = (
  error
): actions.ProspectUnassignEmployeeRejected => {
  return {
    type: PROSPECT_UNASSIGN_EMPLOYEE_REJECTED,
    payload: error,
  };
};

export const handleAddTask = ({
  prospectId,
  task,
  callback,
}: {
  prospectId: number;
  task: ITask;
  callback?: () => void;
}): actions.HandleAddTask => {
  return {
    type: HANDLE_ADD_TASK,
    payload: { prospectId, task, callback },
  };
};

export const pendingAddTask = (): actions.AddTaskPending => {
  return {
    type: ADD_TASK_PENDING,
  };
};

export const resolvedAddTask = (): actions.AddTaskResolved => {
  return {
    type: ADD_TASK_RESOLVED,
  };
};

export const rejectedAddTask = (error): actions.AddTaskRejected => {
  return {
    type: ADD_TASK_REJECTED,
    payload: error,
  };
};

export const handleRemoveTask = ({
  tasksToRemove,
  callback,
  isFromScheduler,
}): actions.HandleRemoveTask => {
  return {
    type: HANDLE_REMOVE_TASK,
    payload: {
      tasksToRemove: tasksToRemove,
      callback: callback,
      isFromScheduler: isFromScheduler,
    },
  };
};

export const pendingRemoveTask = (): actions.RemoveTaskPending => {
  return {
    type: REMOVE_TASK_PENDING,
  };
};

export const resolvedRemoveTask = (): actions.RemoveTaskResolved => {
  return {
    type: REMOVE_TASK_RESOLVED,
  };
};

export const rejectedRemoveTask = (error): actions.RemoveTaskRejected => {
  return {
    type: REMOVE_TASK_REJECTED,
    payload: error,
  };
};

//export const handleGetTasks = (
//  request: IPagination,
//  prospectId: number
//): actions.HandleGetTasks => {
//  return {
//    type: HANDLE_GET_TASKS,
//    payload: { request, prospectId },
//  };
//};

//export const pendingGetTasks = (): actions.GetTasksPending => {
//  return {
//    type: GET_TASKS_PENDING,
//  };
//};

//export const resolvedGetTasks = (tasks: ITasks): actions.GetTasksResolved => {
//  return {
//    type: GET_TASKS_RESOLVED,
//    payload: tasks,
//  };
//};

//export const rejectedGetTasks = (error): actions.GetTasksRejected => {
//  return {
//    type: GET_TASKS_REJECTED,
//    payload: error,
//  };
//};

export const handleUpdateTask = (
  tasks: ITaskToUpdate[],
  callback: Function
): actions.HandleUpdateTask => {
  return {
    type: HANDLE_UPDATE_TASK,
    payload: { tasks, callback },
  };
};

export const pendingUpdateTask = (): actions.UpdateTaskPending => {
  return {
    type: UPDATE_TASK_PENDING,
  };
};

export const resolvedUpdateTask = (task: ITask): actions.UpdateTaskResolved => {
  return {
    type: UPDATE_TASK_RESOLVED,
    payload: task,
  };
};

export const rejectedUpdateTask = (error): actions.UpdateTaskRejected => {
  return {
    type: UPDATE_TASK_REJECTED,
    payload: error,
  };
};

// FILTERS
export const cleanProspectFilters = (): actions.CleanProspectFilters => {
  return {
    type: actions.CLEAN_PROSPECT_FILTERS,
  };
};

export const setProspectFilters = (
  filters: IProspectsFilterSettings
): actions.SetProspectFilters => {
  return {
    type: actions.SET_PROSPECT_FILTERS,
    payload: filters,
  };
};

// SCAN DOCUMENT
export const documentReader = (photo: any): actions.HandleDocumentReader => ({
  type: actions.HANDLE_DOCUMENT_READER,
  payload: photo,
});

export const documentReaderPending = (): actions.DocumentReaderPending => ({
  type: actions.DOCUMENT_READER_PENDING,
});

export const documentReaderResolved = (
  documentData: any
): actions.DocumentReaderResolved => ({
  type: actions.DOCUMENT_READER_RESOLVED,
  payload: documentData,
});

export const documentReaderRejected = (
  error: string | object
): actions.DocumentReaderRejected => ({
  type: actions.DOCUMENT_READER_REJECTED,
  payload: error,
});

export const clearDocumentAndErrorReaderDocument = (): actions.ClearDocumentAndErrorReaderDocument => ({
  type: actions.CLEAR_DOCUMENT_AND_ERROR_READER
})
