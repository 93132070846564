import * as actions from "./accounts.types";
import { IAccount } from "types/accountsTypes";
import { IPaginationResponse } from "types/paginationTypes";

interface IState {
    accounts: IPaginationResponse<IAccount>;
    rowsPerPage: number;
    error: null | object;
    serverError: null | object;
    updateAccountError: null | object;
    accountActivateError: null | object;
    column: string;
    sort: string;
    selectedAccount: null | IAccount;
}

const initialState: IState = {
    accounts: {
        results: [],
        totalItems: 0,
        currentPage: "1",
    },
    rowsPerPage: 15,
    error: null,
    serverError: null,
    updateAccountError: null,
    accountActivateError: null,
    column: "account.firstName",
    sort: "ASC",
    selectedAccount:null
};

export const AccountsReducer = (
    state: IState = initialState,
    action: actions.AccountsActions
) => {
    switch (action.type) {

        case actions.GET_ACCOUNTS_PENDING: {
            return {
                ...state,
                loadingGetAccounts: true,
                accountActivateError: null,
                serverError:null
            };
        }
        case actions.GET_ACCOUNTS_RESOLVED: {
            return {
                ...state,
                loadingGetAccounts: false,
                accounts: action.payload,
            };
        }
        case actions.GET_ACCOUNTS_REJECTED: {
            return {
                ...state,
                loadingGetAccounts: false,
                error: action.payload,
            };
        }

        case actions.CREATE_ACCOUNT_PENDING: {
            return {
                ...state,
                loading: true,
                serverError: null,
            };
        }

        case actions.CREATE_ACCOUNT_RESOLVED: {
            return {
                ...state,
                loading: false,
                serverError: null,
            };
        }

        case actions.CREATE_ACCOUNT_REJECTED: {
            return {
                ...state,
                loading: false,
                serverError: action.payload,
            };
        }

        case actions.VIEW_ACCOUNT_PENDING: {
            return {
                ...state,
                loading: true,
                serverError: null,
                updateAccountError: null
            };
        }

        case actions.VIEW_ACCOUNT_RESOLVED: {
            return {
                ...state,
                loading: false,
                selectedAccount: action.payload,
            };
        }

        case actions.VIEW_ACCOUNT_REJECTED: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.UPDATE_ACCOUNT_PENDING: {
            return {
                ...state,
                updateLoading: true,
                serverError: null,
                updateAccountError: null
            };
        }

        case actions.UPDATE_ACCOUNT_RESOLVED: {
            return {
                ...state,
                updateLoading: false,
                serverError: null,
                updateAccountError: null,
                selectedAccount: action.payload
            };
        }
        case actions.UPDATE_ACCOUNT_REJECTED: {
            return {
                ...state,
                updateLoading: false,
                updateAccountError: action.payload,
            };
        }

        case actions.ACCOUNT_ACTIVATE_PENDING: {
            return {
                ...state,
                accountActivateError: null,
            };
        }
        case actions.ACCOUNT_ACTIVATE_REJECTED: {
            return {
                ...state,
                accountActivateError: action.payload,
            };
        }
        case actions.ACCOUNT_SEND_INVITE_PENDING: {
            return {
                ...state,
                serverError: null,
            };
        }
        case actions.ACCOUNT_SEND_INVITE_REJECTED: {
            return {
                ...state,
                serverError: action.payload,
            };
        }
        default:
            return state;
    }
};
