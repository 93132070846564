import {
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    IPersonaStyles
} from "@fluentui/react";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { IIconProps, mergeStyles, IButtonStyles } from "@fluentui/react";
import { ISpinnerStyles } from "@fluentui/react/lib/Spinner";
import { colors } from "constants/constants";


interface IStyles {
    header: IStackItemStyles;
    tabletWrapper: any,
    wrapper: any,
    wrapperNoShadow: any,
    stackItemStyles: IStackItemStyles;
    stackTabletItemStyles: IStackItemStyles;
    buttonTokens: IStackTokens;
    textFields: IStackTokens;
    usernameIcon: IIconProps;
    passwordIcon: IIconProps;
    spinnerStyles: ISpinnerStyles;
    spinnerStylesAdd: ISpinnerStyles;
    rulesList: any;
    rulesListItem: any;
    headerStyles: IStackStyles,
    stackStyles: IStackStyles,
    uploadButton: IButtonStyles,
    excelButton: IButtonStyles,
    iconStyles: any,
    personStyles: Partial<IPersonaStyles>,
    personInGridStyles: Partial<IPersonaStyles>,
}

export const styles: IStyles = {

    stackStyles: {
        root: {
            margin: 0,
            marginRight: 0,
            left: 0,
        }
    },
    personInGridStyles: {
        tertiaryText: {
            color: colors.black
        },
        secondaryText: {
            paddingTop: 4,
            paddingBottom: 2,
            display: 'block'
        }
    },
    personStyles: {
        root: {
            display: "inline-block",
            paddingRight: 30
        },
        tertiaryText: {
            color: colors.black
        },
        secondaryText: {
            paddingTop: 4,
            paddingBottom: 2
        }
    },
    uploadButton: {
        root: {
            height: 35,
            width: 150,
            paddingTop: 3,
            paddingBottom: 3,
            verticalAlign: "top",
            display: "initial",
            marginTop: 30
        },
        icon: {
            marginLeft: 0
        },

        label: {
            padding: 0,
            marginLeft: 0
        }
    },
    excelButton: {
        root: {
            height: 35,
            width: 100,
            paddingTop: 3,
            paddingBottom: 3,
            float: "right",
            marginTop: 20
        },
        icon: {
            marginLeft: 0
        },

        label: {
            padding: 0,
            marginLeft: 0
        }
    },
    wrapper: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        borderRadius: 2,
        // width: 250,
        // height: 400,
        zIndex: 1,
        padding: 40,
        paddingTop: 10
    },
    wrapperNoShadow: {
        background: DefaultPalette.white,
        borderRadius: 2,
        // width: 250,
        // height: 400,
        zIndex: 1,
        padding: 40,
        paddingTop: 10
    },
    tabletWrapper: {
        // boxShadow: Depths.depth8,
        background: DefaultPalette.white,

        borderRadius: 2,
        // width: 250,
        // height: 400,
        zIndex: 1,
        // width: '100% !important',
        //  marginLeft: '0px !important',

        //margin: '0px !important',
        padding: "20px 5vw",

    },
    headerStyles: {
        root: {
            // width: "100%",

            paddingTop: 30,
            paddingBottom: 30,
        },
    },
    iconStyles: mergeStyles({
        borderRadius: 5,
        padding: 5,
        color: "white",
        backgroundColor: colors.grayText,
    }),
    spinnerStyles: {
        root: {
            paddingRight: 5
        },
    },
    spinnerStylesAdd: {
        root: {
            // paddingLeft:
        },
    },
    rulesList: mergeStyles({
        color: colors.grayText,
        listStyle: "none",
        paddingLeft: 0,
        marginBottom: 0
    }),
    rulesListItem: mergeStyles({}),
    header: {
        root: {
            marginBottom: 30,
        },
    },
    stackItemStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 50,
            borderRadius: 2,
            width: 250,
            // height: 400,
            zIndex: 1,
        },
    },
    stackTabletItemStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 50,
            borderRadius: 2,
            // width: "70vw",
            // height: 400,
            zIndex: 1,
        },
    },

    buttonTokens: {
        childrenGap: "10",
    },
    textFields: {
        childrenGap: "10",
    },
    usernameIcon: { iconName: "Signin" },
    passwordIcon: { iconName: "Hide" },
};
