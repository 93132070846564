import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    //TextField,
    PrimaryButton,
    Link
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
//import { handleGetServiceContract } from "store/dealTracker/dealTracker.action";
import { handleGetServiceContractLists } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    convertUTCToLocalDateOnlyModified,
    //convertUTCToLocalModified,
    //convertUTCToLocalMoment
} from "utils/dateTimeFunctions";
import { useNavigate , useLocation } from "react-router-dom";
//import masterDropdownValues from "../../constants/masterDropdownValues";
//import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Paginator } from 'primereact/paginator';
import { DebounceInput } from 'react-debounce-input';

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "DealTracker";
export const ServiceContractWorkbookV2Page = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    //const [appointmentId, setAppointmentId] = useState(0);
    const [serviceContractData, setServiceContractData] = useState(null);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    //const appointments = useSelector((state: AppState) => state.dealTracker.appointments) as any;
    //const serviceContract = useSelector((state: AppState) => state.dealTracker.serviceContract) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 

    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState(["All"]);
	
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);

    const [glFilter, setGLFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [statesFlag, setStatesFlag] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('logDate');
    //const [filteredData, setFilteredData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(null);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
    const [statePageNumber, setStatePageNumber] = useState(1);

    const handleFirstNameFilter = (val: any) => {
        const inputValue = val.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }
        if(val.target.value === ''){
			setGLFilter('');
	    }
	};



    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
		
    }


    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value);
		
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter  (val.target.value)
        
    };




    useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

    useEffect(() => {
       
        if (user && user.roles) {
            setUserProfileLoadingFlag(true)
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }

    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateData = (serviceContract) => {
        if (serviceContract) {
            //let hiddenStr;
            for (var i in serviceContract) {
                let deal = serviceContract[i].deal;
                let customer = deal.customer;
                //hiddenStr = '';
               // serviceContract[i]["appointDateTime"] = convertUTCToLocalDateOnly(serviceContract[i].appointmentDate) + ' ' + extractTime(serviceContract[i].appointmentTime);
                serviceContract[i]["dealNumber"] = deal.dealNumber;
                serviceContract[i]["customerFirstname"] = customer.firstName;
                serviceContract[i]["customerLastname"] = customer.lastName;
                serviceContract[i]["customerEmail"] = customer.email;
                serviceContract[i]["customerPhoneNumber"] = customer.phoneNumber;
                serviceContract[i]["closeDate"] = convertUTCToLocalDateOnlyModified(serviceContract[i].closeDate );
                serviceContract[i]["referral"] = serviceContract[i].referral?serviceContract[i].referral.firstName +' '+serviceContract[i].referral.lastName:'';
                serviceContract[i]["financeMgrNameFirst"] = deal.financeManagers?getManagerName(deal.financeManagers) :'';
                serviceContract[i]["financeMgrNameSecond"] = deal.financeManagers?.length===2 ?getManagerNameSecond(deal.financeManagers) :'';
                serviceContract[i]["financeProduct"] = serviceContract[i].financeProduct?.name;
                serviceContract[i]["logDate"] = moment(new Date(serviceContract[i].createdAt)).local().format('MM/DD/YY');
                serviceContract[i]["id"] = serviceContract[i].id ? serviceContract[i].id : '';

                //if (deal.vehicle)
                //    hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
                //if (deal.salesPerson1)
                //    hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
                //if (deal.salesPerson2)
                //    hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
                //if (deal.financePerson1)
                //    hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
                //if (deal.financePerson2)
                //    hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
                //hiddenStr = hiddenStr + ' ' + customer?.customerNumber + ' ' + customer?.businessName + ' ' + customer.firstName + ' ' + customer.lastName;
                //serviceContract[i]["hiddenCol"] = hiddenStr;
            }

            //if (locationFilter) {
            //    const results = serviceContract.filter(x => x.deal.location.id === locationFilter);
            //    setServiceContractData(results);
            //} else {
            //    setServiceContractData(serviceContract);
            //}
	        	 return serviceContract;
            }
	        return []
	};


    const getManagerName = (financeManagers) => {
        if (financeManagers && financeManagers.length > 0) {
            return financeManagers[0].firstName + ' ' + financeManagers[0].lastName ;
        }
        return '';
    };


    const getManagerNameSecond = (financeManagers) => {
        if (financeManagers.length===2) {
            return financeManagers[1].firstName + ' ' + financeManagers[1].lastName ;
        }
        return '';
    };


    useEffect(() => {
        setSortOrder(-1);
        setSortField('logDate');
        setStatesFlag(null);
        setServiceContractData([]);
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        //dispatch<any>(handleGetServiceContract())  
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const serviceContractList =(isFilterApplied)=>{       
	     const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
                sort: sortOrder === 1 ? 'ASC' : 'DESC',
                column: sortField,
                locationId:locationFilter?locationFilter:0,
                fromLogDate: logDateFromFilter ? moment(logDateFromFilter).format('YYYY-MM-DD'):'',
                toLogDate: logDateToFilter ? moment(logDateToFilter).format('YYYY-MM-DD') : '',
                search: glFilter
            };
            setFilters(formData);
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetServiceContractLists({
                formData,
                callback: (response) => {
                    if (response) {
                        setTotalRecords(response.totalItems);
                        const data = handlePopulateData(response.results);
                        setServiceContractData(data);
                    } else {
                        setServiceContractData(null)
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}
    



	const getService = (isFilterApplied = false) => {
        setcommonServerError('');
        if (statesFlag && userProfileLoadingFlag) {
             serviceContractList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getService(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [glFilter,locationFilter,logDateFromFilter,logDateToFilter,statesFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);

    //Datatable Templates
    
	const handleResetWorkbook = () => {   
        setGLFilter('');
        setLocationFilter(primaryLocation);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setSortOrder(-1);
        setLimit(25);
        setPages(1);
        setSortField('logDate');
        
        //if (primaryLocation) {
        //    const results = serviceContract.filter(x => x.deal.location.id === primaryLocation);
        //    setServiceContractData(results);
        //} else {
        //    setServiceContractData(serviceContract);
        //}
    };



    const exportExcel = () => {
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetServiceContractLists({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
                                   
				                     ({
									    "Log Date": moment(new Date(item.createdAt)).local().format('MM/DD/YY'),
                                        "Deal #": item.deal.dealNumber,
                                        "Finance 1": item.referral?item.referral.firstName +' '+item.referral.lastName:'',
                                        "Product": item.financeProduct?.name,
                                        "First Name": item.deal.customer.firstName,
                                        "Last Name": item.deal.customer.lastName,
                                        "Phone #": item.deal.customer.phoneNumber,
                                        "Email Address": item.deal.customer.email,
                                        "Price": item.price,
                                        "Cost": item.cost,
                                        "Deductible": item.deductable,
                                        "Profit": item.profit,
                                        "Term": item.term,
                                        "Mileage": item.mileage,
                                        "Close Date": convertUTCToLocalDateOnlyModified(item.closeDate )
				                   }));
				                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'Service-Contract-workbook');
                                   });
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }


    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );


	const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			           
                         <Col>
                             <div className="locationDropPosition">
			                  	{/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={glFilter}
                                          debounceTimeout={750}
                                          onChange={(e) => handleFirstNameFilter(e)} />
                                  </div>
			                </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text"  style={{ paddingBottom: "0px" }}>Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
			                <div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
			         	</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"27px"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
                   </Row>
                   <Row>
                       <Col className="display-row" style={{ marginTop: "15px" }}>
                           {
                               totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                           }
                           <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS">
                                        Export to Excel
				            </Link>
                       </Col>
                   </Row>
				</Col>
		    </Row>

		</div>:
		<div>
            <div>
		        <Row>
		            <Col md={10}>
			            <Row>
			                
                            <Col  md={3}>
			                          <div className="table-header employeeeSearchLabelText">
			                          	{/*<TextField
			                          		value={glFilter}
			                          		type="search"
			                          		label="Search Appraisals"
			                          		className="ownerSearchText"
			                          		onChange={(e) => handleFirstNameFilter(e)}
			                          	/>*/}
						        		  <label className="lbl-text">Search</label>
                                          <div className="ms-TextField-fieldGroup debounce-input-group">
                                              <DebounceInput
                                                  minLength={1}
                                                  className="ms-TextField-field"
                                                  value={glFilter}
                                                  debounceTimeout={750}
                                                  onChange={(e) => handleFirstNameFilter(e)} />
                                          </div>
			                          </div>
			         	        </Col>
			         	    <Col  md={3}>
						        <div className="dd-control">
			                        <label className="lbl-text">Location</label>
                                    <Dropdown
                                          id="locationId"
                                          placeholder=""
                                          value={locationFilter}
                                          options={cityDealerList}
                                          onChange={onLocationChange}
                                          optionLabel="legalName"
                                          optionValue="id"
                                          appendTo="self"
                                          className="custom-p-dropdown write-ups-location-dd"
                                          filter={ false}
                                          resetFilterOnHide={true}
                                      />
							    </div>
			         	    </Col>
			         	    <Col md={4}>
							    <div className="date-filter-section">
								    <div className="table-header">
								    	<div className="ms-TextField-wrapper">
								    		<label className="lbl-text">Log Date</label>
								    		<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
								    			<Calendar
								    				id="fromLogDate"
								    				style={{ width: "100%" }}
								    				value={logDateFromFilter}
								    				showIcon
								    				onChange={(e) => handleFromLogDateChange(e)}
								    				monthNavigator
								    				yearNavigator
								    				yearRange={yearRange}
								    				maxDate={logDateToFilter}
								    			/>
								    		</div>
								    	</div>
								    </div>
								    <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								    <div className="table-header">
								    	<div className="ms-TextField-wrapper">
								    		<label className="lbl-text">&nbsp;</label>
								    		<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
								    			<Calendar
								    				id="toLogDate"
								    				style={{ width: "100%" }}
								    				value={logDateToFilter}
								    				showIcon
								    				onChange={(e) => handleToLogDateChange(e)}
								    				monthNavigator
								    				yearNavigator
								    				yearRange={yearRange}
								    				minDate={logDateFromFilter}
								    			/>
								    		</div>
								    	</div>
								    </div>
							    </div>
						    </Col>
                            <Col  md={1} >
						        <div className="table-header employeeeSearchLabelText" style={{marginTop:"25px"}}>
			                        <PrimaryButton
							              onClick={handleResetWorkbook}
							           >
                                        Reset
                                    </PrimaryButton>
						        </div>
			         	    </Col>
			         </Row>
				    </Col>
		            <Col>
                        
				    </Col>
                </Row>
            </div>
            <div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);

    const logDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log Date</span>
                {moment(new Date(rowData.logDate)).local().format('MM/DD/YY')}

            </React.Fragment>
        );
    };


    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}

            </React.Fragment>
        );
    };

    const financeFirstBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance 1</span>
                {rowData.referral}

            </React.Fragment>
        );
    };

    const finnaceSecondBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance 2</span>
                {rowData.financeMgrNameSecond}

            </React.Fragment>
        );
    };

    const productBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product</span>
                {rowData.financeProduct}

            </React.Fragment>
        );
    };

    const firstNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">First Name</span>
                {rowData.customerFirstname}

            </React.Fragment>
        );
    };

    const lastNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Last Name</span>
                {rowData.customerLastname}

            </React.Fragment>
        );
    };

    const emailAddressBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email Address</span>
                {rowData.customerEmail}

            </React.Fragment>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Phone</span>
                {rowData.customerPhoneNumber}

            </React.Fragment>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Price</span>

                {rowData.price}

            </React.Fragment>
        );
    };

    const costBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cost</span>

                {rowData.cost}

            </React.Fragment>
        );
    };    

    const deductibleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deductible</span>

                {rowData.deductable}

            </React.Fragment>
        );
    }; 
    
    const profitBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Profit</span>

                {rowData.profit}

            </React.Fragment>
        );
    }; 

    const termBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Term</span>

                {rowData.term}

            </React.Fragment>
        );
    }; 

    const mileageBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mileage</span>
                {rowData.mileage}

            </React.Fragment>
        );
    }; 

   //const referralBodyTemplate = (rowData) => {
   //    return (
   //        <React.Fragment>
   //            <span className="p-column-title">Referral</span>
   //            {rowData.empName}
   //
   //        </React.Fragment>
   //    );
   //}; 

    const closeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Close Date</span>
                {convertUTCToLocalDateOnlyModified(rowData.closeDate)}

            </React.Fragment>
        );
    }; 

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(serviceContractId) => handleEditServiceContract(serviceContractId)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditServiceContract = (id) => {
        const selectedItem = serviceContractData.find(x => x.id === id);
        if (selectedItem) {
            handleNavigateToAfterSalesProduct(selectedItem.deal.id, id);
        }
    };

    const handleRowClick = (event) => {
        
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role || isKarlProfile(user.profileType)) {
                handleNavigateToAfterSalesProduct(event.data.deal.id, event.data.id);
                // navigate(`/${event.data.deal.id}/dealtracker`, { tabIndex: 1 });
                //navigate({
                //    pathname: `/${event.data.deal.id}/dealtracker`,
                //    state: {
                //      searchInput: glFilter,
                //      pathfrom: '/service-contract',
                //      tabIndex: 6,
                //      productId:`${event.data.id}`,
                //      workbookName:'Service Contract',
                //      // sortOrder,
                //      // sortField
                //    }
                //});
            //}
        }
    };

    const handleNavigateToAfterSalesProduct = (dealId, id) => {
        const  state = {
            searchInput: glFilter,
            pathfrom: '/service-contract',
            tabIndex: 6,
            productId: `${id}`,
            workbookName: 'Service Contract',
            sortOrder,
            sortField,
            logDateFromFilter,
            logDateToFilter,
            locationFilter
        }
        navigate( `/${dealId}/dealtracker`, {state});
    }

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);
    
    useEffect(() => { 
        setStatesFlag(true);

        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
        }

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }

        if(primaryLocation === 0 && stateVal?.locationFilter === 0){
            setLocationFilter(0);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(new Date(stateVal.logDateToFilter));
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) * stateVal.limit);
            }
        }
        
    }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

	const table_columns = {
		"logDate": "Log Date",
		"dealNumber": "Deal #",
		"referral": "Finance 1",
		//"financeMgrNameSecond":"Finance 2",
		"financeProduct": "Product",
		"customerFirstname": "First Name",
		"customerLastname":"Last Name",
		"customerPhoneNumber": "Phone #",
		"customerEmail": "Email Address",
		"price": "Price",
		"cost": "Cost",
		"deductable":"Deductible",
		"profit": "Profit",
		"term": "Term",
		"mileage":"Mileage",
		//"empName": "Referral",
		"closeDate": "Close Date",
        "settings": "Actions",
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }


    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row className="pageTitleRow">
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">After Sale Products</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <Row>
                     <Col md={12}>
                                <div className="datatable-filter-demo noMaxWidthTable">
                                    <div className=" lazy-loading-workbook card mouse-hover" >
                                      <CustomTable
                                          column_list={table_columns}
                                          data={serviceContractData}
                                          paginator={false}
                                          //rows={25}
                                          //rowsPerPageOptions={[25,50,100]}
                                          header={header}
                                          footer={footer}
                                          style={{width:"100%"}}
                                          sortField={sortField}
                                          sortOrder={sortOrder}
                                          onSort={handleColumnSort}
                                          lazy
                                          //onValueChange={filteredData => setFilteredData(filteredData)}
				              			  customTemplates={{
				              			      "logDate": logDateBodyTemplate,
                                              "dealNumber": dealNumberBodyTemplate,
                                              "referral": financeFirstBodyTemplate,
                                              "financeMgrNameSecond": finnaceSecondBodyTemplate,
				              				  "financeProduct": productBodyTemplate,
                                              "customerFirstname":firstNameBodyTemplate,
                                              "customerLastname": lastNameBodyTemplate,
                                              "customerPhoneNumber": phoneNumberBodyTemplate,
				              				  "customerEmail":emailAddressBodyTemplate,
                                              "price": priceBodyTemplate,
                                              "cost": costBodyTemplate,
                                              "deductable": deductibleBodyTemplate,
				              				  "profit": profitBodyTemplate,
				              				  "term": termBodyTemplate,
                                              "mileage": mileageBodyTemplate,
                                              //"empName": referralBodyTemplate,
                                              "closeDate": closeDateBodyTemplate,
				              				  "settings": settingBodyTemplate,
				              			  }}
                                          emptyMessage="No service contract found."
                                          onRowClick={(event) => handleRowClick(event)}
                                         // globalFilter={glFilter} 
                                          
                                          />
                                  </div>
                              </div>
                     </Col>
                </Row>

 
            </Stack>
        </> : null
    );
};
