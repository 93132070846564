import React from "react";
import {
  Panel,
  PanelType,
  IPanelStyles,
} from "@fluentui/react/lib/Panel";
import {
  DefaultButton,
  getTheme,
  IconButton,
  PrimaryButton,
  Stack,
  Checkbox,
  IStackStyles,
  IButtonStyles,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { filterPanelStyles as content } from "constants/styles";
import { useSelector, useDispatch } from "react-redux";
import { setInventoryFilters } from "store/inventory/inventory.action";
import { AppState } from "store";

const contentStyles = content();
const theme = getTheme();
const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
};

const closeBtnStyles: IButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px"
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens = { childrenGap: 10 };

const stackStyles: IStackStyles = {
  root: {
    marginTop: 20,
  },
};

interface IProps {
  isOpen: boolean;
  label: string;
  onClose: () => void;
  cleanFilters: () => void;
  handleFilter: () => void;
}

const FinancialInformationPanel: React.FC<IProps> = ({
  isOpen,
  label,
  onClose,
  cleanFilters,
  handleFilter,
}) => {
  const filters = useSelector((state: AppState) => state.inventory.filters);
  const dispatch = useDispatch();

  const onChange = (
    ev: React.FormEvent<HTMLElement>,
    checked: boolean
  ): void => {
    dispatch<any>(
      setInventoryFilters({ ...filters, [ev.currentTarget.id]: !!checked })
    );
  };

  return (
    <Panel
      styles={panelStyles}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onClose}
      hasCloseButton={false}
      onRenderHeader={() => (
        <div className={contentStyles.bottomShadow}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ width: "100%" }}
          >
            <GoBackToMainDialogBtn label={label} onClick={onClose} />

            <div className={contentStyles.marginRight}>
              <IconButton
                styles={closeBtnStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={onClose}
              />
            </div>
          </Stack>
        </div>
      )}
      onRenderFooter={() => (
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">
            <DefaultButton text="Clear" onClick={cleanFilters} />
            <PrimaryButton text="View results" onClick={handleFilter} />
          </Stack>
        </div>
      )}
    >
      <Stack tokens={stackTokens} styles={stackStyles}>
        <Checkbox
          id="invoice"
          label="Invoice"
          checked={filters.invoice}
          onChange={onChange}
        />
        <Checkbox
          id="leasing"
          label="Leasing"
          checked={filters.leasing}
          onChange={onChange}
        />
      </Stack>
    </Panel>
  );
};

export default FinancialInformationPanel;
