import React, { useEffect, useState, FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
    Dropdown
} from "@fluentui/react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
//import { useNavigate  } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import dropDownsValues from "constants/inventoryDropdownsValues";
// import { handleGetdataOneMakes, handleGetdataOneModels } from "store/shared/shared.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
// import { VinCameraSelection} from "components/dialogs/vinCameraSelection/VinCameraSelection";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetLocations } from "store/locations/locations.action";
import { useMediaQuery } from "react-responsive";  
import {
    handleCreateVehicleTrackerList
} from "store/dealTracker/dealTracker.action";
//const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: () => void;
    vehicleRecordUi:(response)=> void;
    defaultLocation?:any
}




const yearValues = dropDownsValues.yearNewValues.map((item) => {
    return { key: item, text: item };
});

export const AddVehicleTrackerDialog: FunctionComponent<IProps> = ({
    onDismiss,
    vehicleRecordUi,
    defaultLocation

}) => {

    const [vehicleType, setvehicleType] = useState('vin');
    //const [makeValues, setMakeValues] = useState([]);
   // const [modelValues, setModelValues] = useState([]);
    const [isVINScan, setisVINScan] = useState(false);
    const [trimError, setTrimErrorr] = useState(false);
    const dispatch = useDispatch();
    //const navigate = useNavigate ();
    // const [showModels, setShowModels] = useState(false);
    // const [vinCameraDialog, setvinCameraDialog] = React.useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [locationFilter, setLocationFilter] = useState(defaultLocation);
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const [cityDealerList, setCityDealerList] = React.useState([]);

   // const dataOneModels: any = useSelector(
   //     (state: AppState) => state.shared.dataOneModels
   // );

   // const dataOneMakes: any = useSelector(
   //     (state: AppState) => state.shared.dataOneMakes
   // );

    const userProfile: any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);

    Yup.addMethod(Yup.string, 'vinOnly', function (message) {
        return (this.matches(/^[A-Z0-9]{17}$/,
            "Must Contain at 17 Characters, Uppercase letters and numbers"))
    });

    Yup.addMethod(Yup.object, 'modelOnly', function (message) {
        return (vehicleType === 'make');
    });
   
    Yup.addMethod(Yup.number, 'yearOnly', function (message) {
        return (vehicleType === 'make');
    });

    const formik = useFormik({
        initialValues: {
            make: "",
            model: "",
            //year: null,
            year: new Date().getFullYear(),
            locationId: defaultLocation ? defaultLocation:"",
            stockNumber: ""
            
        },
        validationSchema: Yup.object({
            locationId: Yup.string().required("Required"),
            stockNumber: Yup.string().required("Required"),
            make: Yup.string().max(50, "Make must be at most 50 characters").required("Required"),
            model:Yup.string().max(50, "Model must be at most 50 characters").required("Required"),
            year: Yup.number()
            
        }),
        onSubmit: (values) => {
         
                 dispatch<any>(handleCreateVehicleTrackerList({
                     formData: values,
                     callback: (response) => {
                         if(response){
                             vehicleRecordUi(response)
                         }else{
                             setTrimErrorr(true);
                         }
                             
                     }
                 }));
      
        }
    });

   // useEffect(() => {
   //     if (dataOneMakes.length > 0) {
   //         const values = dataOneMakes.map((item) => {
   //             return { key: item.label, text: item.label };
   //         });
   //         setMakeValues(values);
   //     } else {
   //         setMakeValues([]);
   //     }
   // 
   // }, [dataOneMakes]);

   //  useEffect(() => {
   // 
   //      if (dataOneModels.length > 0) {
   //          const values = dataOneModels.map((item) => {
   //              return { key: item.label, text: item.label };
   //          });
   //          setModelValues(values);
   //      } else {
   //          setModelValues([]);
   //      }
   //      // eslint-disable-next-line react-hooks/exhaustive-deps
   //  }, [dataOneModels]);

  //  useEffect(() => {
  //      if (formik.values.year) {
  //          dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
  //      }
  //      // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [formik.values.year]);

    useEffect(() => {

        if (locations && locations.results) {
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }

    }, [locations]);

      useEffect(() => {
          if (formik.values.make !== "") {
              ///setShowModels(true);
      
              formik.setFieldValue("model", null);
             // dispatch<any>(handleGetdataOneModels({
             //     make: formik.values.make?.toString(),
             //     year: formik.values.year
             // }));
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [formik.values.make]);

    useEffect(() => {
        setvehicleType('vin');
       // dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
         dispatch<any>(handleGetLocations())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

 

    const handleAddYmmVehicle = () => { 
     
       formik.handleSubmit();
    };

    // const handleMakeChange = (value) => {
    //     formik.setValues(
    //         {
    //             ...formik.values,
    //             make: value ? value : '',
    //         }
    //     );
    // };

    const handleYearChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                year: value ? value : '',
            }
        );
        //setModelValues([]);
        //setShowModels(false);
    };

 

    const onLocationChange = (e) => {
        setLocationFilter(e.target.value);
        formik.setValues(
            {
                ...formik.values,
                locationId: e.target ? e.target.value : '',
            }
        );
    }

   //  const handleModelChange = (val) => {
   // 
   //      formik.setValues(
   //          {
   //              ...formik.values,
   //              model: val ? val : '',
   //          }
   //      );
   //  };


    const handleScanVIN = (bool, fromCamera = false) => {
        if (fromCamera) {
            // setvinCameraDialog(true);
        }
        setTrimErrorr(false);
        setisVINScan(bool)
    }



    const onBackDismiss = () => {
        if (isVINScan) {
            handleScanVIN(false)
        }
        else {
             onDismiss()
        }
    }

    /* const vinCameraAction = (action) => {
        setvinCameraDialog(false);
        if (action === 'ocr') {
            setvinScanConfig(action);
            setisVINScan(true)
        } else {
            setvinScanConfig(action);
            setisVINScan(true)
        }
    } */

    return (
        <Modal
            isOpen={true}
            onDismiss={onBackDismiss}
            isBlocking={true}
            className={isKarlProfileType  ? "header-width ymm-dialog-karl" : "ymm-dialog"}
            containerClassName={`${contentStyles.container} inv-srch-mod ${isVINScan ? 'scanner' : ''}`}
        >
            <div className={contentStyles.header + " inventory-popup"} >
                {/*<GoBackToMainDialogBtn label="" onClick={onBackDismiss} />*/}
                <CustomFontText className="formTitle">Add Vehicle</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onBackDismiss}
                />
            </div>

            {/* { vinCameraDialog ?
                <VinCameraSelection
                    loading={false}
                    description="Vin Camera"
                    isOpen={vinCameraDialog}
                    onAction={vinCameraAction}
                    onDismiss={() => setvinCameraDialog(false)}
                /> : null
            } */}
            <div className={`${contentStyles.body} inv-srch-bdy body-auto-hight`} >
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <div className="addInventoryDialog vin-body-new addInventoryDialog">
                     
                        {
                            trimError ?
                                <div>
                                    <Row>
                                        <Col>
                                            <p className="genericErrorColor"><span>Faild to fetch data.</span></p>
                                        </Col>
                                    </Row>
                                </div> : null
                        }
                      
                        <div>
                           
                            {isMobile ?

                                <div>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                            <Dropdown
                                                id="locationId"
                                                label="Location"
                                                selectedKey={locationFilter}
                                                required={true}
                                                onChange={onLocationChange}
                                                placeholder="Select a locationId"
                                                options={cityDealerList}

                                            />
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                            <TextField
                                                       id="stockNumber"
                                                       name="stockNumber"
                                                       label="Stock Number"
                                                       className="employeeeSearchLabelText"
                                                       value={formik.values.stockNumber}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       errorMessage={
                                                           formik.touched.stockNumber &&
                                                           formik.errors.stockNumber &&
                                                           formik.errors.stockNumber.toString()
                                                       }
                                                   /> 
                                        </Col>
                                    </Row>

                                    <Row className="inventoryRowSection" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                            <Dropdown
                                                id="model_addInventory"
                                                label="Year"
                                                selectedKey={formik.values.year}
                                                required={true}
                                                onChange={(e, item) => handleYearChange(item.key)}
                                                placeholder="Select a Year"
                                                options={yearValues}

                                            />
                                        </Col>
                                    </Row>

                                    <Row className="inventoryRowSection" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                           {/*} <Dropdown
                                                id="make_addInventory"
                                                label="Make"
                                                selectedKey={formik.values.make}
                                                required={true}
                                                onChange={(e, item) => handleMakeChange(item.key)}
                                                placeholder="Select a Make"
                                                options={makeValues}
                                            //disabled={!showModels}
                                            />*/}
                                            <label className="lbl-text required-Label">Make</label>
                                            <TextField
                                                id="make_addInventory"
                                                name="make_addInventory"
                                               // label="Stock Number"
                                                className="employeeeSearchLabelText"
                                                value={formik.values.make}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched.make &&
                                                    formik.errors.make &&
                                                    formik.errors.make.toString()
                                                }
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                            {/*}<Dropdown
                                                id="model_addInventory"
                                                label="Model"
                                                selectedKey={formik.values.model}
                                                required={true}
                                                onChange={(e, item) => handleModelChange(item.key)}
                                                placeholder="Select a Model"
                                                options={modelValues}
                                                disabled={!showModels}
                                            />*/}
                                            <label className="lbl-text required-Label">Make</label>
                                            <TextField
                                                id="model_addInventory"
                                                name="model_addInventory"
                                               // label="Stock Number"
                                                className="employeeeSearchLabelText"
                                                value={formik.values.model}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched.model &&
                                                    formik.errors.model &&
                                                    formik.errors.model.toString()
                                                }
                                            /> 
                                        </Col>
                                    </Row>

                                </div>

                                :
                                <div>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col sm={10} md={10} xs={12} >
                                           <Row >
                                               <Col md={6} >
                                                   
			        	                           <div >
			                                           <label className="lbl-text required-Label">Home Location</label>
                                                         <PRDropdown
                                                           id="locationId"
                                                           placeholder=""
                                                           value={locationFilter}
                                                           options={cityDealerList}
                                                           onChange={onLocationChange}
                                                           optionLabel="legalName"
                                                           optionValue="id"
                                                           appendTo="self"
                                                           className="locationId-p-dropdown-items custom-p-dropdown .customInputField "
                                                           filter={ false}
                                                           resetFilterOnHide={true}
                                                       />
			        	                           </div>
			                                       	
                                               </Col>
                                               <Col md={6}>
                                                   <label className="lbl-text required-Label">Stock Number</label>
                                                   <TextField
                                                       id="stockNumber"
                                                       name="stockNumber"
                                                      // label="Stock Number"
                                                       className="employeeeSearchLabelText"
                                                       value={formik.values.stockNumber}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       errorMessage={
                                                           formik.touched.stockNumber &&
                                                           formik.errors.stockNumber &&
                                                           formik.errors.stockNumber.toString()
                                                       }
                                                   /> 
                                               </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col sm={10} md={10} xs={12} >
                                            <div className="yearMake">
                                                <h5 className="primeDropdownLabel required-Label" style={{ margin: "5px 0" }}>Year</h5>
                                                <PRDropdown
                                                    id="model_addInventory"
                                                    value={formik.values.year}
                                                    className="custom-p-dropdown-label invMakeDropdown labelTextBorder full-width"
                                                    
                                                    options={yearValues}
                                                    onChange={(e) => handleYearChange(e.value)}
                                                    optionLabel="text"
                                                    optionValue="text"
                                                    scrollHeight="150px"
                                                    virtualScrollerOptions={{ itemSize: 31 }}
                                                    placeholder="Select a Year"
                                                    appendTo="self"
                                                    filter
                                                    resetFilterOnHide={true}
                                                    required={true} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                        {/*}<Col sm={10} md={10} xs={12}>
                                            <div>
                                                
                                                <h5 className="primeDropdownLabel required-Label" style={{ margin: "5px 0" }}>Make</h5>
                                                <PRDropdown
                                                    id="make_addInventory"
                                                    value={formik.values.make}
                                                    className="custom-p-dropdown-label invMakeDropdown labelTextBorder full-width "
                                                    options={makeValues}
                                                    onChange={(e) => handleMakeChange(e.value)}
                                                    optionLabel="text"
                                                    optionValue="text"
                                                    scrollHeight="150px"
                                                    virtualScrollerOptions={{ itemSize: 31 }}
                                                    placeholder="Select a Make"
                                                    appendTo="self"
                                                    filter
                                                    panelClassName="dropdownCustHgt optionPlacementTop makeDropdown"
                                                    resetFilterOnHide={true}
                                                    required={true} />
                                            </div>
                                        </Col>*/}
                                        <Col sm={10} md={10} xs={12}>
                                            <TextField
										        id="make"
								            	name="make"
								            	label="Make"
								            	placeholder=""
                                                className="employeeeSearchLabelText"
								            	value={formik.values.make}
								            	required={true}
								            	onChange={formik.handleChange}
								            	onBlur={formik.handleBlur}
								            	
								            />
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                       {/*} <Col sm={10} md={10} xs={12}>
                                            <div>
                                             
                                                <h5 className="primeDropdownLabel required-Label" style={{ margin: "5px 0" }}>Model</h5>
                                                <PRDropdown
                                                    id="model_addInventory"
                                                    value={formik.values.model}
                                                    className="custom-p-dropdown-label invMakeDropdown labelTextBorder full-width "
                                                    options={modelValues}
                                                    onChange={(e) => handleModelChange(e.value)}
                                                    optionLabel="text"
                                                    optionValue="text"
                                                    scrollHeight="150px"
                                                    virtualScrollerOptions={{ itemSize: 31 }}
                                                    placeholder="Select a Model"
                                                    appendTo="self"
                                                    disabled={!showModels}
                                                    required={true}
                                                    filter
                                                    resetFilterOnHide={true}
                                                    panelClassName="dropdownCustHgt optionPlacementTop"
                                                />
                                              
                                            </div>
                                        </Col>*/}
                                        <Col sm={10} md={10} xs={12}>
                                            <TextField
										        id="model"
								            	name="model"
								            	label="Model"
								            	placeholder=""
                                                className="employeeeSearchLabelText"
								            	value={formik.values.model}
								            	required={true}
                                               // disabled={!showModels}
								            	onChange={formik.handleChange}
								            	onBlur={formik.handleBlur}
								            	
								            />
                                        </Col>
                                    </Row>
                                </div>

                            }

                           
                            <Row className="inventoryRowSection ymm" justify="center">
                                <Col sm={10} md={10} xs={12} className="submitBtn">
                                    <PrimaryButton
                                        className="decodeBtn"
                                        text="Submit"
                                        onClick={handleAddYmmVehicle}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Stack>
            </div>


        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 500,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
