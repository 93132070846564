import React, { FunctionComponent, useEffect, useState } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  IconButton,
  TextField,
  Stack,
  Dropdown,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { useNavigate  } from "react-router-dom";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
//import { dropdownStyles } from "constants/styles";
import { handleGetVehDetails, handlePutVehDetails } from "store/inventory/inventory.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { trimTypes } from "constants/constants";
import { handleUpdateVehicleStockItem,handleVehicleTrackerCheckStock, handleCheckVehicleStock, handleCheckVehicleOrder, handleDealerTradeCheck } from "store/dealTracker/dealTracker.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { DealerTradeFoundDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/DealerTradeFoundDialog";

const contentStyles = content(600, "auto");

interface IProps {
  onDismiss: (msg) => void;
  vin: any;
  trimValues: any[];
  ymm: any;
  vehID: any;
  orderStock: any;
  trimType?: number;
  stockData?:(res)=> void;
  dealId?: any;
  stockConflictData?:(res)=> void;
  stockFoundDealData?: (res) => void;
  orderConflictData?: (res) => void;
  orderFoundDealData?: (res) => void;
  vtStockFoundDealData?: (res) => void;
}

export const AddInventoryTrimDialog: FunctionComponent<IProps> = ({
  onDismiss,
  vin,
  trimValues,
  ymm,
  vehID,
  orderStock,
  stockData,
  trimType = trimTypes.nonKarl,
  dealId,
  stockConflictData,
  stockFoundDealData,
  orderConflictData,
  orderFoundDealData,
  vtStockFoundDealData
}) => {
  const navigate = useNavigate ();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: AppState) => state.prospects.loadingAddTask
  );
  let initialTrimForm = {
      trim: trimValues && trimValues.length === 1 ? trimValues[0].key:"",
      stockNumber: "",
      orderNumber: ""
  };
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const userProfile: any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const [bodyStyle, setBodyStyle] = useState({minHeight:"105px",paddingTop:"5px",paddingBottom:"5px"});
    const [isDDClosed, setDDClosed] = useState(false);
    const [conflictData, setConflictData] = useState([]);
    const [formVal, setFormVal] = useState(null);
    const [modalIndex, setModalIndex] = useState(1);

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);

  const trimFormik = useFormik({
    initialValues: initialTrimForm,
    validationSchema: Yup.object({
        trim: Yup.string(),
        stockNumber: Yup.string(),
        orderNumber: Yup.string()
    }),
    onSubmit: (values) => {
    },
  });


    useEffect(() => {
        if(isKarlProfileType){
            stockConflictData(null);
            stockFoundDealData(null);
            orderConflictData(null);
            orderFoundDealData(null);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateNewTask = () => {
    if (trimFormik.values.trim) {
      if (trimType === trimTypes.editDelivery) {
        // onDismiss();
        //orderStock.orderNumber, orderStock.stockNumber
        const formData = {
          orderNumber: orderStock.orderNumber,
          style: trimFormik.values.trim,
          vin: orderStock.vin,
          stockNumber: orderStock.stockNumber,
        };
        
        dispatch<any>(handleUpdateVehicleStockItem({       
            id: orderStock.dealId,
            vehicleId: orderStock.vehicleId,
            formData,
            callback: (res) => {
              if (res) {
                onDismiss('');
              } else{
                onDismiss('');
              }
            }
        }));
      } else {
        let vinTrim;
        if (vin) {
          vinTrim = {
            style: trimFormik.values.trim,
            vin: vin
          };
        } else {
          vinTrim = {
            style: trimFormik.values.trim,
            ...ymm
          };
        }
        
        if (isKarlProfileType && trimType === trimTypes.karl && vin) {
            vinTrim["stockNumber"] = trimFormik.values.stockNumber;
            vinTrim["orderNumber"] = trimFormik.values.orderNumber;
        }
          
        if (vehID) {
          dispatch<any>(handlePutVehDetails({
              id: vehID,
              vehDet : vinTrim,
              callback: (success) => {
                  if (success) {
                    
                    if (!isKarlProfileType) {
                        navigate(`/${success.id}/shop-inventory`);
                    } 
                  onDismiss('');
                }
          }}));
        } else {
            
            if (trimType === 2 && vin && (vinTrim.vin || vinTrim.stockNumber || vinTrim.orderNumber)) {
                setFormVal(vinTrim);
                dealerTradeCheck(vinTrim);
            }
            else
            {
               getVehicleDetails(vinTrim);
            }
        }
      }
    }
  };

    const dealerTradeCheck = (vinTrim) => {
        const data = {
            vin: vinTrim.vin,
            stockNumber: vinTrim.stockNumber,
            orderNumber: vinTrim.orderNumber
        };
        dispatch<any>(handleDealerTradeCheck({
            id: dealId,
            data,
            callback: (res) => {
                if (res && res.conflicts) {
                    res.conflicts.forEach(function (item, index) {
                        let obj = {
                            tradeDate: moment(item.createdAt.date).local().format('MM/DD/YYYY'),
                            tradeMgr: item.createdBy?.firstName + ' ' + item.createdBy?.lastName,
                            tradeType: item.type === 1 ? "Our Trade" : "Their Trade",
                            vin: null,
                            stockNumber: null,
                            orderNumber: null
                        };

                        if (vinTrim.vin === item.vehicleIn?.vin || vinTrim.vin === item.vehicleOut?.vin) {
                            obj.vin = vinTrim.vin;
                        }
                        if (vinTrim.stockNumber === item.vehicleIn?.stockNumber || vinTrim.stockNumber === item.vehicleOut?.stockNumber) {
                            obj.stockNumber = vinTrim.stockNumber;
                        }
                        if (vinTrim.orderNumber === item.vehicleIn?.orderNumber || vinTrim.orderNumber === item.vehicleOut?.orderNumber) {
                            obj.orderNumber = vinTrim.orderNumber;
                        }
                        setConflictData(x => [...x, obj]);
                    });
                    setModalIndex(2);
                }
                else {
                    if (trimFormik.values.trim && trimFormik.values.stockNumber) {
                        checkVehicleTrackerStockNumberExist(vinTrim);
                    }
                    else if (trimFormik.values.trim && trimFormik.values.orderNumber) {
                        checkOrderNumberExist(vinTrim);
                    }
                    else if(trimFormik.values.trim){
                        getVehicleDetails(vinTrim)
                    }
                }
            }
        }));
    }

    const checkStockNumberExist = (vinTrim) => {
        let data = { stockNumber: vinTrim.stockNumber };
        dispatch<any>(handleCheckVehicleStock({
            id: dealId,
            data,
            callback: (res) => {
                if (res.id) {
                    if (trimFormik.values.trim && trimFormik.values.orderNumber) {
                        checkOrderNumberExist(vinTrim);
                    } else {
                        getVehicleDetails(vinTrim);
                    }
                } else if (res) {
                    stockFoundDealData(res.conflicts)
                    stockConflictData(vinTrim)
                    if (trimFormik.values.trim && trimFormik.values.orderNumber) {
                        checkOrderNumberExist(vinTrim,true);
                    }
                    else {
                        onDismiss('New Vin stock Number');
                    }                    
                }

            }
        }))
    }  
    
    const checkVehicleTrackerStockNumberExist = (vinTrim) => {
        let formData = { stockNumber: vinTrim.stockNumber };
        dispatch<any>(handleVehicleTrackerCheckStock({
            id: dealId,
            formData,
            callback: (res) => {
                if (res.id) {
                    if (vinTrim.stockNumber) {
                        checkStockNumberExist(vinTrim);
                    }
                    
                } else if (res) {
                    vtStockFoundDealData(res.conflicts)
                    onDismiss('New Vin stock Number');
                    if (vinTrim.stockNumber) {
                        checkStockNumberExist(vinTrim);
                        
                    }
                    
                } else {
                    onDismiss('Failed to Save Data.');
                }

            }
        }))
    }

    const checkOrderNumberExist = (vinTrim,isStockExist = false) => {
        let data = { orderNumber: vinTrim.orderNumber };
        dispatch<any>(handleCheckVehicleOrder({
            id: dealId,
            data,
            callback: (res) => {
                if (res.id) {
                    if (isStockExist) {
                        onDismiss('New Vin stock Number');
                    } else {
                        getVehicleDetails(vinTrim);
                    }
                    
                } else if (res) {
                    orderFoundDealData(res.conflicts);
                    orderConflictData(vinTrim);
                    onDismiss('New Vin stock Number');
                }

            }
        }))
    }

    const getVehicleDetails = (vinTrim) => {
        dispatch<any>(handleGetVehDetails({
            vehDet: vinTrim, trimType,
            callback: (success) => {
                if (success.id) {
                    if (!isKarlProfileType) {
                        navigate(`/${success.id}/shop-inventory`);
                    }
                    onDismiss('')
                }
            }
        }));
    }

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  //  const handleDropdownValue = (e: { value: any }) => {
  //      trimFormik.setValues(
  //        {
  //          ...trimFormik.values,
  //              'trim': e.value
  //        }
  //      );
  //  };

    const handleDropdownValue = (val) => {
        
        trimFormik.setValues(
            {
                ...trimFormik.values,
               'trim': val ? val : '',
            }
        );
    };
    
    const onDropDownClose = () => {
        setBodyStyle({ ...bodyStyle, minHeight: "105px" });
        setDDClosed(false);
    }


    const onDropDownFocus = () => {
        if (isDDClosed)
        {
            if (isKarlProfileType && trimType === trimTypes.karl && vin)
            {
                if (trimValues && trimValues.length > 4)
                    setBodyStyle({ ...bodyStyle, minHeight: "350px" });
                else if (trimValues && trimValues.length === 4)
                    setBodyStyle({ ...bodyStyle, minHeight: "300px" });
                else if (trimValues && trimValues.length === 3)
                    setBodyStyle({ ...bodyStyle, minHeight: "270px" });
                else if (trimValues && trimValues.length === 2)
                    setBodyStyle({ ...bodyStyle, minHeight: "240px" });
                else if (trimValues && trimValues.length === 1)
                    setBodyStyle({ ...bodyStyle, minHeight: "210px" });
            } else
            {
                if (trimValues && trimValues.length > 4)
                    setBodyStyle({ ...bodyStyle, minHeight: "310px" });
                else
                    setBodyStyle({ ...bodyStyle, minHeight: "230px" });
            }
        }
    }

    const onMouseDown = (e) => {
        setDDClosed(true);
    }

    const handleDealerTradeWarning = (vinTrim) => {
       
        if (trimFormik.values.trim && trimFormik.values.stockNumber) {
            checkStockNumberExist(vinTrim);
        }
        else if (trimFormik.values.trim && trimFormik.values.orderNumber) {
            checkOrderNumberExist(vinTrim);
        } else {
            getVehicleDetails(vinTrim);
        }
    }

    return (
        <>
            {
                modalIndex === 1 && (<Modal
                    isOpen={true}
                    onDismiss={onDismiss}
                    isBlocking={true}
                    className={isKarlProfileType && trimType === trimTypes.karl ? "style-dialog-karl" : "style-dialog style-dialog-view "}
                    containerClassName={contentStyles.container}
                >
                    <div className={contentStyles.header + " inventory-popup"}>
                        {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                        <CustomFontText className="formTitle">Add Vehicle</CustomFontText>

                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={onDismiss}
                        />
                    </div>
                    <div className={contentStyles.body + " inventory-style-popup-body"} style={bodyStyle}>
                        <Stack
                            styles={stackStyles}
                            verticalAlign="start"
                            horizontalAlign="space-between"
                        >
                            {ymm ?
                                // <CustomFontText size="15px"><strong style={{ marginRight: "15px" }}>VIN Decode</strong>{ymm.year + " " + ymm.make + " " + ymm.model}</CustomFontText>
                                <CustomFontText color="#1C2E47" size="15px">{ymm.year + " " + ymm.make + " " + ymm.model}</CustomFontText>
                                : null}

                            {isKarlProfileType && trimType === trimTypes.karl && vin && (<>
                                <div className={!isMobile ? "display-row" : ""}>
                                    <TextField
                                        id="stockNumber"
                                        name="stockNumber"
                                        label="Stock No"
                                        className="stock-no-field contentFieldSpace"
                                        value={trimFormik.values.stockNumber}
                                        onChange={trimFormik.handleChange}
                                        onBlur={trimFormik.handleBlur}
                                    />
                                    <TextField
                                        id="orderNumber"
                                        name="orderNumber"
                                        label="Order No"
                                        className="stock-no-field contentFieldSpace"
                                        value={trimFormik.values.orderNumber}
                                        onChange={trimFormik.handleChange}
                                        onBlur={trimFormik.handleBlur}
                                    />
                                </div>
                            </>)}
                            {/*<Dropdown
                      id="trim"
                      label="Style"
                      className="contentFieldSpace"
                      selectedKey={trimFormik.values.trim}
                      required={true}
                      onChange={handleDropdownValue}
                      placeholder="Select a Style"
                      options={trimValues}
                      styles={dropdownStyles}
                      errorMessage={
                          trimFormik.touched.trim &&
                          trimFormik.errors.trim &&
                          trimFormik.errors.trim.toString()
                      }
                    />*/}


                            {isMobile ?

                                <div>
                                    <Dropdown
                                        id="trim"
                                        label="Style"
                                        selectedKey={trimFormik.values.trim}
                                        required={true}
                                        onChange={(e, item) => handleDropdownValue(item.key)}
                                        placeholder="Select a Style"
                                        options={trimValues}
                                    />
                                </div>

                                :
                                <div>
                                    <label className="lbl-text required-Label">Style</label>
                                    <PRDropdown
                                        id="trim"
                                        placeholder="Select a Style"
                                        value={trimFormik.values.trim}
                                        options={trimValues}
                                        onChange={(e) => handleDropdownValue(e.value)}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="invMakeDropdown labelTextBorder full-width"
                                        filter={false}
                                        resetFilterOnHide={true}
                                        onHide={onDropDownClose}
                                        onFocus={onDropDownFocus}
                                        onMouseDown={onMouseDown}
                                    />
                                    <span className="error-message error-message-text" >
                                        {trimFormik.touched.trim &&
                                            trimFormik.errors.trim &&
                                            trimFormik.errors.trim.toString()}
                                    </span>
                                </div>

                            }
                            <div className="inventory-popup-footer  style-dialog-foot">
                                <div style={{ textAlign: "center", margin: "15px 0px" }}>
                                    <PrimaryButton
                                        text="Save"
                                        disabled={!trimFormik.values.trim}
                                        onClick={() => handleCreateNewTask()}
                                        onRenderIcon={renderSpinner}
                                    />
                                </div>
                            </div>
                        </Stack>
                    </div>
                </Modal>)
            }

            {
                modalIndex === 2 ?
                    <DealerTradeFoundDialog
                        conflictData={conflictData}
                        onDismiss={() => { onDismiss(''); }}
                        onSave={() => { handleDealerTradeWarning(formVal) }}
                    />
                    : null
            }
        </>
        
  );
};


const stackStyles: IStackStyles = {
  root: {},
};
