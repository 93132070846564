// import http from "services/api/createApiClient";
import {
  ICredentials,
  IResetPassword /*ILoginResponse*/,
} from "types/authTypes";
import client from "services/api/client";

export const login = ({ email, password }: ICredentials) => {
    return client("user/login", { body: { username: email, password } }, false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

// export const register = ({ email, password, firstName, lastName,nickName, token, consentNewsOffersUpdates }) => {
    
//     return client("user/register", {
//         body: { email, password, firstName, lastName, nickName, token, consentNewsOffersUpdates },
//   }, false).then(
//     (data) => {
//       return { data, error: null };
//     },
//     (error) => {
//       return { data: null, error };
//     }
//   );
// };

export const confirmAccountApi = ({ email }) => {  //confirmCode
  return client("user/confirm", {
    body: { email },  //confirmCode
  }).then(
      (data) => {
        return { data, error: null };
      },
      (error) => {
        return { data: null, error };
      }
  );
};

// export const login =  async ({ email, password }: ICredentials) => {
//
//   let response;
//
//   try{
//     response = await http().post("/user/login", {
//       username: email,
//       password,
//     });
//
//   }catch(e){
//
//     console.log(JSON.stringify(e));
//    throw new Error(e)
//   }
//
//   return response
// };

export const refreshTokenApi = async (refreshToken: string) => {
  return client("token/refresh", {
    body: { refresh_token: refreshToken },
  }).then(
    (data) => {
      console.log("%c REFRESH TOKEN GRANTED !", `color: yellow`, data);
      //token and refresh token
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const validateEmailAccount = async ({email, verificationToken}) => {
  return client("user/validate", {
    body: {email, verificationToken},
  },false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const validateResetPswdLink = async ({email, verificationToken}) => {
  return client(`user/reset/${email}/validate`, {
    body: {verificationToken},
  },false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const reValidateEmailAccount = async ({email}) => {
  return client(`user/validate/${email}`, { body: {email: ''} }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const resetPasswordRequest = async (email: string) => {
  return client(`user/reset/${email}`, { body: null, method: "POST" },false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const resetPasswordConfirm = async (object: IResetPassword) => {
  return client(`user/reset/${object.email}/confirm`, {
    body: {
      verificationToken: object.verificationToken,
      password: object.password,
    },
  },false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
