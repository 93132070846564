import client from "services/api/client";
import { IDeskADeal } from "types/deskADealTypes";

// export const viewDeal = (id: number | string) => {
//   return client(`user/master/deal/${id}`, { body: null }).then(
//     (data) => {
//       return { data, error: null };
//     },
//     (error) => {
//       return { data: null, error };
//     }
//   );
// };

export const getDeskADealsApi = () => {
  return client(`user/master/deskadeals?sort=ASC`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const createDeskADealApi = (
  deskADeal: IDeskADeal,
  prospectId: number | string,
  inventoryId: number | string
) => {
  return client(
    `user/master/deskadeal/prospect/${prospectId}/inventory/${inventoryId}`,
    {
      body: deskADeal,
      method: "POST",
    }
  ).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteDeskADealApi = (id: number) => {
  return client(`user/master/deskadeal/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};


export const updateDeskADealApi = ({deskADeal, deskADealId, prospectId, inventoryId}) => {
    return client(`user/master/deskadeal/${deskADealId}/prospect/${prospectId}/inventory/${inventoryId}`, {
        body: deskADeal,
        method: "PATCH",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

