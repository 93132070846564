import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import { Col, Row } from "react-grid-system";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";


const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: () => void;
}

export const SaveSuccessDialog: FunctionComponent<IProps> = ({
    onDismiss
}) => {

    const handleClosePopup = () => {
        onDismiss();
    }

    return (
        <Modal
            isOpen={true}
            onDismiss={handleClosePopup}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
        >
            <div className={contentStyles.header + " inventory-popup success-dialog-header"}>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleClosePopup}
                />
            </div>
            <div style={{width:"auto"}} className={`${contentStyles.body} body-auto-hight success-dialog-body`}>
                <Row className="purchase-row" justify="center">
                    <Col style={{ textAlign: "center" }}>
                        <span className="formTitle saveSuccessFul">Save Successful</span>
                    </Col>
                </Row>
                <Row className="purchase-row" >
                    <Col style={{ textAlign: "center" }}>
                        <PrimaryButton
                            //className="ms-Button--primary-2"
                            text="Ok"
                            onClick={handleClosePopup}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
