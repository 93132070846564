import React, { FC, /*useEffect,*/ useState } from "react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import {
  Stack,
  IStackStyles,
  Icon,
  DefaultPalette,
} from "@fluentui/react";
import { useNavigate , useLocation } from "react-router-dom";
import { colors } from "constants/constants";

const stackStyles: IStackStyles = {
  root: {
    height: 50,
    paddingTop: 40,
    paddingBottom: 40,
    cursor: "pointer",
  },
};

const iconWrapper: IStackStyles = {
  root: {
    backgroundColor: DefaultPalette.themePrimary,
    borderRadius: "50%",
    color: "white",
    marginRight: 15,
    fontSize: 14,
    padding: 8,
  },
};

const iconWrapperHovered: IStackStyles = {
  root: {
    backgroundColor: DefaultPalette.themeDark,
    borderRadius: "50%",
    color: "white",
    marginRight: 15,
    fontSize: 14,
    padding: 8,
  },
};

interface IProps {
  label: string;
  count?: string | number|any;
}

const GoBack: FC<IProps> = ({ label, count }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate ();
  const location = useLocation();

  return (
    <Stack styles={stackStyles} horizontal verticalAlign="center" className="back_sec">
      <Stack
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        styles={hovered ? iconWrapperHovered : iconWrapper}
        verticalAlign="center"
        horizontalAlign="center"
        className="icn_cir_badge"
      >
        <Icon iconName="Back" className="icn_back" onClick={() => navigate(-1)} />
      </Stack>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <CustomFontText>{label}</CustomFontText>
        {(location.pathname === "/prospects" ||
          location.pathname === "/appraisal" ||
          location.pathname === "/deals") && (
          <CustomFontText style={{ color: colors.grayText, fontSize: 24 }}>
            {count > 0 && `(${count})`}
          </CustomFontText>
        )}
      </Stack>
    </Stack>
  );
};

export default GoBack;
