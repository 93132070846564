import React, { useState, useEffect, FunctionComponent } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  PrimaryButton,
  TextField,
  DatePicker,
  mergeStyles,
  MaskedTextField,
  Toggle,
  Dropdown
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { useDispatch, useSelector } from "react-redux";
import { handleDeleteProspect } from "store/prospects/prospects.action";
import { AppState } from "store/index";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { styles } from "pages/accountSettingsPage/styles";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import { salutations} from "constants/constants";
import { Col, Row } from "react-grid-system";
import { ViewAdditionalActionsDropdown } from "components/table/dropdowns/ViewAdditionalActionsDropdown";
import GoBackToMainDialogBtn from "../../addInventoryDialog/GoBackToMainDialogBtn";
import {
  dropdownStyles,
  cancelIcon,
  iconButtonStyles,
  contentStyles as contentStyle,
} from "constants/styles";
import { getModulePermissions } from "../../../../utils/permissions/permissionsHelpers";
import { formatDateInventory } from "utils/dateTimeFunctions";
import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";



const contentStyles = contentStyle();

interface IProps {
  onDismiss: () => void;
  handleGoBack: () => void;
  prospectId?: number;
  formik: any;
  handleEditProspect: () => void;
}

const dateStyle = mergeStyles({
  margin: "0px 0 15px 0",
  // maxWidth: '300px'
});

export const PersonalInformationDialog: FunctionComponent<IProps> = ({
  handleGoBack,
  onDismiss,
  prospectId,
  formik,
  handleEditProspect,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) => state.prospects.loading);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const [maskValue, setMaskValue] = useState(false);

  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);

  useEffect(() => {
    console.log("=========FORMIK", formik);
  }, [formik]);

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
    );
  };

  const handleDateChange = (date) => {
    console.log(date);
    
    formik.setValues(
      {
        ...formik.values,
        dateOfBirth: date,
      },
      true
    );
  };

  const handleToggleMaskedValue = (val) => {
    console.log(val);
    
    setMaskValue((value) => !value);
  };

    //Auto populate the state, county and city using zip
    const [zipApiMsg, setZipApiMsg] = useState("");
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const handleZipChange = (e) => {
        if (e.target.value.length === 5 && !isNaN(e.target.value)) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {

                if (response.Status) {
                    setCities(response.Cities);
                    setCounties(response.Counties);
                    formik.setValues(
                        {
                            ...formik.values,
                            state: response.State,
                            city: response.DefaultCity,
                            county: response.DefaultCounty
                        },
                        true
                    );

                }
                else {

                    setZipApiMsg(response.ErrorMessage);
                    resetCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
        }
        else {
            setZipApiMsg("");
            resetCityCountyState();
        }
    };

    const resetCityCountyState = () => {
        formik.setValues(
            {
                ...formik.values,
                state: "",
                city: "",
                county: ""
            },
            true
        );
        setCounties([]);
        setCities([]);
    }  

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      {/*<Loader show={loading} />*/}
      <ConfirmDialog
        loading={loading}
        description="Please confirm deletion of prospect"
        title="Confirm delete prospect"
        isOpen={isConfirmBoxOpen}
        onAction={() => dispatch<any>(handleDeleteProspect([prospectId]))}
        onDismiss={() => setIsConfirmBoxOpen(false)}
      />
      <div className={contentStyles.header}>
        <GoBackToMainDialogBtn
          label="Personal Information"
          onClick={handleGoBack}
        />
        {/*<CustomFontText>Personal Information</CustomFontText>*/}
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={`noScrollbar ${contentStyles.body}`}>
        <Stack>
          <Stack tokens={styles.textFields}>
            <WithOptionalLabel>
              <MaskedTextField
                  mask="(999)999-9999"
                  id="homePhoneNumber"
                  name="homePhoneNumber"
                  label="Home Phone Number"
                  value={formik.values.homePhoneNumber ? formik.values.homePhoneNumber : ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.homePhoneNumber && formik.errors.homePhoneNumber
                        ? formik.errors.homePhoneNumber.toString()
                        : null
                  }
              />


              {/*<TextField*/}
              {/*  id="homePhoneNumber"*/}
              {/*  name="homePhoneNumber"*/}
              {/*  label="Home Phone Number"*/}
              {/*  value={formik.values.homePhoneNumber}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.homePhoneNumber &&*/}
              {/*    formik.errors.homePhoneNumber*/}
              {/*      ? formik.errors.homePhoneNumber.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
            </WithOptionalLabel>

            <WithOptionalLabel>
              <MaskedTextField
                  mask="(999)999-9999"
                  id="workPhoneNumber"
                  name="workPhoneNumber"
                  label="Work Phone Number"
                  value={formik.values.workPhoneNumber ? formik.values.workPhoneNumber : ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.workPhoneNumber && formik.errors.workPhoneNumber
                        ? formik.errors.workPhoneNumber.toString()
                        : null
                  }
              />


              {/*<TextField*/}
              {/*  id="workPhoneNumber"*/}
              {/*  name="workPhoneNumber"*/}
              {/*  label="Work Phone Number"*/}
              {/*  value={formik.values.workPhoneNumber}*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  errorMessage={*/}
              {/*    formik.touched.workPhoneNumber &&*/}
              {/*    formik.errors.workPhoneNumber*/}
              {/*      ? formik.errors.workPhoneNumber.toString()*/}
              {/*      : null*/}
              {/*  }*/}
              {/*/>*/}
            </WithOptionalLabel>

            <WithOptionalLabel>
              <Dropdown
                id="gender"
                label="Gender"
                selectedKey={formik.values.gender ? formik.values.gender : 1}
                onChange={handleDropdownChange}
                placeholder=""
                options={salutations}
                styles={dropdownStyles}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.gender && formik.errors.gender
                    ? formik.errors.gender.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <WithOptionalLabel>
              <DatePicker // @TODO: Need to be changed as search not selected date
                className={dateStyle}
                style={{ marginBottom: 0 }}
                label="Date of Birth"
                placeholder="or select a date..."
                ariaLabel="Select a date"
                showGoToToday={false}
                // value={formik.values.dateOfBirth}
                value={ new Date(formik.values.dateOfBirth)}
                onSelectDate={(e) => handleDateChange(e)}

                // formatDate?: (date?: Date) => string;
                formatDate={(date) => formatDateInventory(date.toString())}
                allowTextInput

              />
            </WithOptionalLabel>
            <WithOptionalLabel>
              <TextField
                id="currentAddress1"
                name="currentAddress1"
                label="Current Address 1"
                value={formik.values.currentAddress1 ? formik.values.currentAddress1 : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.currentAddress1 &&
                  formik.errors.currentAddress1
                    ? formik.errors.currentAddress1.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            <WithOptionalLabel>
              <TextField
                id="currentAddress2"
                name="currentAddress2"
                label="Current Address 2"
                value={formik.values.currentAddress2 ? formik.values.currentAddress2 :""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.currentAddress2 &&
                  formik.errors.currentAddress2
                    ? formik.errors.currentAddress2.toString()
                    : null
                }
              />
            </WithOptionalLabel>
            {/* zip city state */}

            <Row>
              <Col style={{ flex: 2, paddingRight: 0 }}>
                <TextField
                  id="zip"
                  name="zip"
                  label="ZIP"
                  value={formik.values.zip ? formik.values.zip : ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
				  onKeyUp={handleZipChange}
                  errorMessage={
                      formik.touched.zip && formik.errors.zip
                          ? formik.errors.zip.toString()
                          : zipApiMsg ? zipApiMsg : null
                  }
                />
              </Col>
              {/*<Col style={isTablet ? cityTablet : city}>*/}
              <Col style={{ flex: 4 }}>
                <Dropdown
                    id="city"
                    label="City"
                    selectedKey={formik.values.city}
                    onChange={handleDropdownChange}
                    options={cities}
                    styles={dropdownStyles}
                    errorMessage={
                        formik.touched.city && formik.errors.city
                            ? formik.errors.city.toString()
                            : null
                    }
                />
              </Col>
            </Row>
            <Row>
                <Col style={{ flex: 6 }}>
                    <Dropdown
                        id="county"
                        label="County"
                        selectedKey={formik.values.county}
                        onChange={handleDropdownChange}
                        options={counties}
                        styles={dropdownStyles}
                        errorMessage={
                            formik.touched.county && formik.errors.county
                                ? formik.errors.county.toString()
                                : null
                        }
                    />
                </Col>
                <Col style={{ flex: 2 }}>
                    <TextField
                        id="state"
                        name="state"
                        label="State"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        errorMessage={
                            formik.touched.state && formik.errors.state
                                ? formik.errors.state.toString()
                                : null
                        }
                    />
                </Col>
            </Row>
            <WithOptionalLabel>
              <TextField
                id="suffix"
                name="suffix"
                label="Suffix"
                value={formik.values.suffix ? formik.values.suffix : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.suffix && formik.errors.suffix
                    ? formik.errors.suffix.toString()
                    : null
                }
              />
            </WithOptionalLabel>

            <WithOptionalLabel>
              <TextField
                id="driverLicense"
                name="driverLicense"
                label="Driver License"
                value={formik.values.driverLicense ? formik.values.driverLicense : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.driverLicense && formik.errors.driverLicense
                    ? formik.errors.driverLicense.toString()
                    : null
                }
              />
            </WithOptionalLabel>

            <WithOptionalLabel>
              <MaskedTextField
                type={maskValue ? "password" : undefined}
                id="socialSecurityNumber"
                name="socialSecurityNumber"
                label="Social Security Number"
                mask="999 - 99 - 9999"
                value={formik.values.socialSecurityNumber ? formik.values.socialSecurityNumber : ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.socialSecurityNumber &&
                  formik.errors.socialSecurityNumber &&
                  formik.errors.socialSecurityNumber.toString()
                }
              />
            </WithOptionalLabel>

            <div style={{ marginTop: 20 }}>
              <Toggle
                id="socialSecurityNumberMasked"
                checked={maskValue}
                label="Social Security Number Masked"
                inlineLabel
                onChange={handleToggleMaskedValue}
                onText="active"
                offText="inactive"
              />
            </div>
          </Stack>
        </Stack>
      </div>
      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}

          // styles={contentStyles.buttonsStyles}
        >
          <div>
            <ViewAdditionalActionsDropdown
              modulePermissions={getModulePermissions(
                user.auths || [],
                "Prospects"
              )}
            />
          </div>
          <div>
            <DefaultButton
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />
            <PrimaryButton
              text="Save Changes"
              onClick={handleEditProspect}
              disabled={!formik.isValid}
            />
          </div>
        </Stack>
      </div>
    </Modal>
  );
};
