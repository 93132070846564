import { takeLeading, put, call, select } from "redux-saga/effects";
import { AppState } from "../index";

/* import {
  getDeals
} from "store/dealTracker/dealTracker.api"; */

import {
    handleGetSalesFinanceSuccess,
    handleGetSelectedSalesFinanceSuccess,
    pendingViewDealTracker,
    resolvedViewDealTracker,
    rejectedViewDealTracker,
    pendingGetDealsTracker, rejectedGetDealsTracker, resolvedGetDealsTracker,
    resolvedGetFinanceProduct,
    pendingGetFinanceProduct,
    rejectedGetFinanceProduct,
    pendingGetAppointments,
    resolvedGetAppointments,
    rejectedGetAppointments,
    pendingGetAppraisals,
    resolvedGetAppraisals,
    rejectedGetAppraisals,
    handleSelectedTradeIn,
    resolvedGetAppraisalTable,
    pendingGetAppraisalTable,
    rejectedGetAppraisalTable,
    resolvedUpdateDelVehTracker,
    pendingGetServiceContract,
    resolvedGetServiceContract,
    rejectedGetServiceContract,
    pendingGetServiceContractList,
    resolvedGetServiceContractList,
    rejectedGetServiceContractList,
    pendingGetTasks,
    resolvedGetTasks,
    rejectedGetTasks,
    pendingGetSalesHierarchy,
    resolvedGetSalesHierarchy,
    rejectedGetSalesHierarchy,
    pendingGetCancellations,
    resolvedGetCancellations,
    rejectedGetCancellations
} from './dealTracker.action'
import {
    financeManager,
    salesManager,
    selectedFinanceManager,
    selectedSalesManager,
    createDealRecord,
    saveDealRecord,
    viewDealRecord, getDealRecordList,
    getFinanceProduct,
    getFinanceProductItem,
    addFinanceProductItem,
    saveFinanceProductItem,
    deleteFinanceProductItem,
    saveDelivery,
    getKarlDealNotes,
    saveKarlDealNotes,
    saveAppointment,
    getAppointments,
    viewAppointment,
    getAppointmentsByDeal,
    saveAppraisal,
    getAppraisals,
    viewAppraisal,
    getAppraisalsByDeal,
    addVehicleStockItem,
    addVehicleOrderItem,
    updateVehicleStockItem,
    saveKarlDealTradeInNotes,
    getKarlDealTradeInNotes,
    getAppraisalTable,
    addAppraisalTableItem,
    saveServiceContract,
    getServiceContract,
    viewServiceContractItem,
    getServiceContractItem,
    updateServiceContractItem,
    viewTask,
    saveTask,
    updateTaskStatus,
    getTaskNotes,
    saveTaskNote,
    getTasks,
    getSalesHierarchy,
    getSalesTarget,
    saveSalesTarget,
    getDelivery,
    getTasksByDeal,
    getCancellations,
    getAllFinDelPerson,
    // getFinDelPersonApptByDate,
    postFinDelPersonApptByDate,
    postFinDelPersonApptDeal,
    getFinDelPersonSchedule,
    getFinDelPersonAppByDeal,
    getCancellationsByDeal,
    getCancellationInfo,
    saveCancellation,
    viewDealNoRecord,
    getDealsSummary,
    searchDealRecord,
    printCancellation,
    printCancellationListItem,
    patchDelFinDelPersonApptDeal,
    getPreps,
    getPrepsByDeal,
    getPrepsInfo,
    savePreps,
    savePrepsByDeal,
    deletePrepsInfo,
    getDealerTrades,
    getDealerTradesV2,
    getDealerTradeInfo,
    saveDealerTrade,
    getDTVehicleTrimVIN,
    getDTVehDetails,
    getDTVehicleTrimYMM,
    addDTVehicleStockItem,
    updateDTVehicleStockItem,
    dealerTradeCheck,
    vehicleStockCheck,
    vehicleOrderCheck,
    dealerTradeVehicleStockCheck,
    dealerTradeVehicleOrderCheck,
    financeDeskingReports,
    lenderCountReports,
    financeProductSalesReports,
    financeReserveSplitsReports,
    financeProductSplitsReports,
    karlDocReports,
    getPerCarTotals,
    financePersonStackRankReports,
    financePersonMonthEndReports,
    vacationReports,
    dashboardMikeReports,
    salesPersonFinanceReports,
	salesPersonMonthEndReports,
    getDealsKPIStats,
    getDisplayboard,
    cdrCPOReports,
    getWriteUpsList,
    getDealTrackerSummary,
    printPendingDeals,
    getAppraisalList,
    getAppointmentList,
    getServiceContractList,
    getCancellationList,
    getTaskList,
    getSalesPending,
    printSalesPendingReport,
    getFinanceDetailReports,
    printfinanceDetailReport,
    deleteAppraisalItem,
    getFinancePending,
    printFinancePending,
    saveVehicleTrackerTrimYMM,
    getVehicleTrackerList,
    createVehicleTransferList,
    viewVehicleTransfer,
    viewVehicleTransferList,
    updateVehicleTransferList,
    createVehicleTransferNote,
    getVehicleTransferNote,
    viewVehicleTracker,
    soldVehicleTransfer,
    vehicleTrackerCheckStock,
    vehicleTrackerWriteUpCheck,
    getKarlDealsAppointmentNotes,
    saveKarlDealsAppointmentNotes,
    getDealNotes

} from "./dealTracker.api";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import {
    handleGetSalesFinanceList,
    handlePutSalesFinanceList,
    HandleCreateDealRecord,
    HandleSaveDealRecord,
    HandleViewDealTracker,
    HandleGetDealsTracker,
    HandleSaveDelivery,
    HANDLE_GET_SALES_FINANCE_LIST,
    HANDLE_GET_SELECTED_SALES_FINANCE_LIST,
    HANDLE_PUT_SALES_FINANCE_LIST,
    HANDLE_CREATE_DEAL_RECORD,
    HANDLE_SAVE_DEAL_RECORD,
    HANDLE_SAVE_DELIVERY_NOTE,
    handleSaveDeliveryNote,
    HANDLE_GET_DELIVERY_NOTES,
    handleGetDeliveryNotes,
    HANDLE_VIEW_DEAL_TRACKER, HANDLE_GET_DEALS_TRACKER,
    HandleGetFinanceProduct,
    HandleViewFinanceProductItem,
    HandleUpdateFinanceProductItem,
    HandleCreateFinanceProductItem,
    HandleDeleteFinanceProductItem,
    HANDLE_GET_FINANCEPRODUCT_LIST,
    HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM,
    HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM,
    HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM,
    HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM,
    HANDLE_SAVE_DELIVERY,
    HandleGetAppointments,
    HANDLE_GET_APPOINTMENTS,
    HandleSaveAppointment,
    HANDLE_SAVE_APPOINTMENT,
    handleGetAppointmentInfo,
    HANDLE_GET_APPOINTMENT_INFO,
    HandleGetAppointmentsByDeal,
    HANDLE_GET_APPOINTMENTS_BY_DEAL,
    HandleGetAppraisals,
    HANDLE_GET_APPRAISALS,
    HandleSaveAppraisal,
    HANDLE_SAVE_APPRAISAL,
    handleGetAppraisalInfo,
    HANDLE_GET_APPRAISAL_INFO,
    HandleDeleteAppraisalItem,
    HANDLE_DELETE_APPRAISAL_ITEM,
    HandleGetAppraisalsByDeal,
    HANDLE_GET_APPRAISALS_BY_DEAL,
    HANDLE_CREATE_VEHICLE_STOCK_ITEM,
    HandleCreateVehicleStockItem,
    HANDLE_CREATE_VEHICLE_ORDER_ITEM,
    HandleCreateVehicleOrderItem,
    HANDLE_GET_DEALS_TRADEIN_NOTES_ID,
    HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID,
    handleGetDealsTradeInNotesUsingID,
    HandleGetAppraisalTable,
    HandleCreateAppraisalTableItem,
    HANDLE_GET_APPRAISAL_TABLE_LIST,
    HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM,
    handleGetDealTracker,
    HANDLE_GET_DEAL_TRACKER,
    HANDLE_UPDATE_VEHICLE_STOCK_ITEM,
    HandleUpdateVehicleStockItem,
    HandleGetServiceContract,
    HANDLE_GET_SERVICE_CONTRACT,
    HandleSaveServiceContract,
    HANDLE_SAVE_SERVICE_CONTRACT,
    HandleGetServiceContractList,
    HANDLE_GET_SERVICE_CONTRACT_LIST,
    HandleViewServiceContractItem,
    HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM,
    HandleUpdateServiceContractItem,
    HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM,
    HandleGetTasks,
    HANDLE_GET_TASKS,
    HandleGetTask,
    HANDLE_GET_TASK,
    HandleCreateTask,
    HANDLE_CREATE_TASK,
    HandleUpdateTaskStatus,
    HANDLE_UPDATE_TASK_STATUS,
    HandleGetTaskNotes,
    HANDLE_GET_TASK_NOTES,
    HandleSaveTaskNote,
    HANDLE_SAVE_TASK_NOTE,
    HandleGetSalesHierarchy,
    HANDLE_GET_SALES_HIERARCHY,
    HANDLE_GET_SALES_TARGET,
    HandleGetSalesTarget,
    HANDLE_SAVE_SALES_TARGET,
    HandleSaveSalesTarget,
    HANDLE_GET_DELIVERY,
    handleGetDelivery,
    HandleGetTasksByDeal,
    HANDLE_GET_TASKS_BY_DEAL,
    HandleGetCancellations,
    HANDLE_GET_CANCELLATIONS,
    HANDLE_GET_ALL_FIN_Del_PERSON,
    handleGetAllFinDelPerson,
    // handleGetAllFinDelApptDate,
    HANDLE_POST_ALL_FIN_Del_APPT_DATE,
    // HANDLE_GET_ALL_FIN_Del_APPT_DATE,
    handlePostAllFinDelApptDate,
    HANDLE_POST_ALL_FIN_Del_DEAL,
    handleGetAllFinanceDeliverySchedule,
    handlePostAllFinanceDeliveryDeal,
    HANDLE_GET_ALL_FIN_Del_SCHEDULE,
    HANDLE_GET_ALL_FIN_Del_BY_DEAL,
    HandleGetCancellationsByDeal,
    HANDLE_GET_CANCELLATIONS_BY_DEAL,
    HandleGetCancellationInfo,
    HANDLE_GET_CANCELLATION_INFO,
    HandleSaveCancellation,
    HANDLE_SAVE_CANCELLATION,
    HANDLE_VIEW_DEAL_NO_TRACKER,
    HANDLE_GET_DEALS_SUMMARY,
    HandleGetDealsSummary,
    HANDLE_SEARCH_DEAL_RECORD,
    HandleSearchDealRecord,
    HANDLE_PRINT_DEAL_CANCELLATION,
    HandlePrintDealCancellation,
    HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM,
    HandlePrintDealCancellationListItem,
    HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL,
    handlePatchDelAllFinanceDeliveryDeal,
    HandleGetPreps,
    HANDLE_GET_PREPS,
    HandleGetPrepsByDeal,
    HANDLE_GET_PREPS_BY_DEAL,
    HandleGetPrepsInfo,
    HANDLE_GET_PREPS_INFO,
    HandleSavePrepsByDeal,
    HANDLE_SAVE_PREPS_BY_DEAL,
    HandleSavePreps,
    HANDLE_SAVE_PREPS,
    HandleDeletePrepsInfo,
    HANDLE_DELETE_PREPS_INFO,
    HandleGetDealerTrades,
    HANDLE_GET_DEALER_TRADES,
    HandleGetDealerTradesV2,
    HANDLE_GET_DEALER_TRADES_V2,
    HandleGetDealerTradeInfo,
    HANDLE_GET__DEALER_TRADE_INFO,
    HandleSaveDealerTrade,
    HANDLE_SAVE_DEALER_TRADE,
    HandleGetDTVehicleTrimUsingVin,
    HANDLE_GET_DT_VEHICLE_TRIM_VIN,
    HandleGetDTVehicleDetails,
    HANDLE_GET_DT_VEHICLE_DETAILS,
    HandleGetDTVehicleTrimUsingYmm,
    HANDLE_GET_DT_VEHICLE_TRIM_YMM,
    HandleCreateDTVehicleStock,
    HANDLE_CREATE_DT_VEHICLE_STOCK,
    HandleDealerTradeCheck,
    HANDLE_DEALER_TRADE_CHECK,
    HandleUpdateDTVehicleStockItem,
    HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM,
    HANDLE_CHECK_VEHICLE_STOCK,
    HANDLE_CHECK_VEHICLE_ORDER,
    HANDLE_CHECK_DT_VEHICLE_STOCK,
    HANDLE_CHECK_DT_VEHICLE_ORDER,
    HANDLE_GET_FINNANCE_DESKING_REPORTS,
    HandleGetFinnaceDeskingReports,
    HANDLE_GET_LENDER_COUNT_REPORTS,
    HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS,
    HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS,
    HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS,
    HandleGetDashboardMikeReports,
    HANDLE_GET_DASHBOARD_MIKE_REPORTS,
    HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS,
    HandleGetFinancePersonStackRankReports,
    HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS,
    HandleGetFinancePersonMonthEndReports,
    HANDLE_GET_VACATION_REPORTS,
    HandleGetVacationReports,
    HANDLE_GET_SALES_PERSON_FINANCE_REPORTS,
    HandleGetSalesPersonFinanceReports,
	HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS,
    HandleGetSalesPersonMonthEndReports,
    HANDLE_GET_KARL_DOC_REPORTS,
    HANDLE_GET_PER_CAR_TOTALS,
    HANDLE_GET_DEALS_KPI_STATS,
    HandleGetDealsKPIStats,
    HandleGetDisplayboard,
    HANDLE_GET_DISPLAYBOARD,
    HANDLE_GET_CDR_CPO_REPORTS,
    HandleGetCdrCpoReports,
    HandlePrintPendingDeal,
    HANDLE_PRINT_PENDING_DEAL,
    HandleGetWriteUpsList,
    HANDLE_GET_WRITEUPS_LIST,
    HandleGetAppraisalList,
    HANDLE_GET_APPRAISALS_LIST,
    HandleGetAppointmentList,
    HANDLE_GET_APPOINTMENT_LIST,
    HandleGetServiceContractLists,
    HANDLE_GET_SERVICE_CONTRACT_LISTS,
    HandleGetCancellationLists,
    HANDLE_GET_CANCELLATION_LISTS,
    HandleGetFinanceDetailReports,
    HANDLE_GET_FINANCE_DETAIL_REPORTS,
    HandlePrintFinanceDetailReports,
    HANDLE_PRINT_FINANCE_DETAIL_REPORTS,
    HandleGetTaskList,
    HANDLE_GET_TASK_LIST,
    HandleGetSalesPendingReport,
    HANDLE_GET_SALES_PENDING_REPORT,
    HandlePrintSalesPendingReports,
    HANDLE_PRINT_SALES_PENDING_REPORTS,
    HandleGetDealTrackerSummary,
    HANDLE_GET_DEAL_TRACKER_SUMMARY,
    HandleGetFinancePendingReport,
    HANDLE_GET_FINANCE_PENDING_REPORT,
    HandlePrintFinancePendingReport,
    HANDLE_PRINT_FINANCE_PENDING_REPORT,
    HandleCreateVehicleTrackerList,
    HANDLE_CREATE_VEHICLE_TRACKER_LIST,
    HandleGetVehicleTrackerList,
    HANDLE_GET_VEHICLE_TRACKER_LIST,
    HandleCreateVehicleTransferList,
    HANDLE_CREATE_VEHICLE_TRANSFER_LIST,
    HandleViewVehicleTrackerTransfer,
    HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER,
    HandleViewVehicleTransferList,
    HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST,
    HandleUpdateVehicleTransferList,
    HANDLE_UPDATE_VEHICLE_TRANSFER_LIST,
    HandleCreateVehicleTransferNote,
    HANDLE_CREATE_VEHICLE_TRANSFER_NOTE,
    HandleGetVehicleTransferNote,
    HANDLE_GET_VEHICLE_TRANSFER_NOTE,
    HandleSoldVehicleTransfer,
    HANDLE_SOLD_VEHICLE_TRANSFER,
    HandleViewVehicleTracker,
    HANDLE_VIEW_VEHICLE_TRACKER,
    HandleVehicleTrackerCheckStock,
    HANDLE_VEHICLE_TRACKER_STOCK_CHECK,
    HandleVehicleTrackerWriteUpCheck,
    HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK,
    HandleSaveDealsAppointmentsNotesUsingID,
    HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID,
    HandleGetDealsAppointmentsNotesUsingID,
    HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID,
    HandleGetDealNotes,
    HANDLE_GET_DEAL_NOTES
} from './dealTracker.types';

function* getDealTrackerSalesFinanceSaga(action: handleGetSalesFinanceList) {
    yield put(switchGlobalLoader(true));
    try {
        const { salesData, errorFilter } = yield salesManager(action.payload, 'GET');
        if (salesData) {
            const { financeData, errorFilter } = yield financeManager(action.payload, 'GET');
            let getTeam = {
                sales: salesData,
                finance: financeData
            }
            yield put(handleGetSalesFinanceSuccess(getTeam))
            action.payload.callBck(getTeam);
            if (errorFilter) {
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        if (errorFilter) {
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}


function* getDealTrackerSelectedSalesFinanceSaga(action: handleGetSalesFinanceList) {
    yield put(switchGlobalLoader(true));
    try {
        const { salesData, errorFilter } = yield selectedSalesManager(action.payload, 'GET', action.payload.id);
        if (salesData) {
            const { financeData, errorFilter } = yield selectedFinanceManager(action.payload, 'GET', action.payload.id);
            let getTeam = {
                sales: salesData,
                finance: financeData
            }
            yield put(handleGetSelectedSalesFinanceSuccess(getTeam));
            if (errorFilter) {
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        if (errorFilter) {
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* saveDealTrackerSalesFinanceSaga(action: handlePutSalesFinanceList) {
    yield put(switchGlobalLoader(true));
    try {
        let salesReq = {
            salesManagerIds: action.payload.salesInfo
        };
        let financeReq = {
            financeManagerIds: action.payload.financeInfo
        };
        const { salesData, errorFilter } = yield selectedSalesManager(salesReq, 'PUT', action.payload.id);
        if (salesData) {
            const { financeData, errorFilter } = yield selectedFinanceManager(financeReq, 'PUT', action.payload.id);

            if (financeData) {
                const { salesData } = yield selectedSalesManager(action.payload, 'GET', action.payload.id);
                if (salesData) {
                    const { financeData } = yield selectedFinanceManager(action.payload, 'GET', action.payload.id);
                    let getTeam = {
                        sales: salesData,
                        finance: financeData
                    }
                    yield put(handleGetSelectedSalesFinanceSuccess(getTeam));
                }
            }

            if (errorFilter) {
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        if (errorFilter) {
            yield put(switchGlobalLoader(false));
            return;
        }

        /* const getSalesFinanceList: handleGetSalesFinanceList = {
          payload: null,
          type: HANDLE_GET_SALES_FINANCE_LIST,
        };
        yield call(getDealTrackerSalesFinanceSaga, getSalesFinanceList); */

        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}


 /*** Search Customer Number ****/

 function* searchDealRecordSaga(action: HandleSearchDealRecord) {

    yield put(switchGlobalLoader(true));
    try {
        const { deal, callback } = action.payload;
        const { data, error } = yield searchDealRecord(deal);
        if (data) {
            callback(data);
            yield put(switchGlobalLoader(false));
            return;
        }
        callback(error);
        // yield put(resolvedCreateDeal(data));
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}


function* createDealRecordSaga(action: HandleCreateDealRecord) {
    yield put(switchGlobalLoader(true));
    try {
        const { deal, callback } = action.payload;
        const { data, error } = yield createDealRecord(deal);
        if (data) {
            callback(data);
            yield put(switchGlobalLoader(false));
            return;
        }
        callback(error);
        // yield put(resolvedCreateDeal(data));
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* saveDealRecordSaga(action: HandleSaveDealRecord) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, deal, callback } = action.payload;

        const { data, error } = yield saveDealRecord(deal, id);
        if (data) {
            yield put(resolvedViewDealTracker(data));
        }
        callback(error ? error : data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

/***Get Delivery***/
function* getDeliverySaga(action: handleGetDelivery) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield getDelivery(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* saveDeliverySaga(action: HandleSaveDelivery) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, deal, callback } = action.payload;
        const { data } = yield saveDelivery(deal, id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* viewDealSaga(action: HandleViewDealTracker) {
    yield put(switchGlobalLoader(false));
    try {
        yield put(pendingViewDealTracker());
        const { data, error } = yield viewDealRecord(action.payload.id);
        if (data) {
            if (action.payload.callBck) {
                action.payload.callBck(true)
            }
            yield put(resolvedViewDealTracker(data));
        } else {
            if (action.payload.callBck) {
                action.payload.callBck(false)
            }
            yield put(rejectedViewDealTracker(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedViewDealTracker(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* viewDealNoSaga(action: HandleViewDealTracker) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield viewDealNoRecord(action.payload.id, action.payload.locationId);
        if (data) {
            action.payload.callBck(data)
            // yield put(resolvedViewDealTracker(data));
        } else {
            console.log(error)
            action.payload.callBck(false)
            // yield put(rejectedViewDealTracker(error));
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedViewDealTracker(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getDealTrackerSaga(action: handleGetDealTracker) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield viewDealRecord(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/* Delivery Notes*/

function* getDeliveryNotesSaga(action: handleGetDeliveryNotes) {
    yield put(switchGlobalLoader(true));
    try {
        const { dataFilter, errorFilter } = yield getKarlDealNotes(action.payload.dealId, action.payload.deliveryId);
        if (dataFilter) {
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* saveDeliveryNotesSaga(action: handleSaveDeliveryNote) {
    yield put(switchGlobalLoader(true));
    try {
        const { dataFilter, errorFilter } = yield saveKarlDealNotes(action.payload.dealId, action.payload.deliveryId, action.payload.details);
        if (dataFilter) {
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* getDealNotesSaga (action: HandleGetDealNotes) {
    yield put(switchGlobalLoader(true));
    try {
        const { dataFilter, errorFilter } = yield getDealNotes (action.payload.dealId);
        if (dataFilter) {
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}
function* getKarlDealsAppointmentNotesSaga(action: HandleGetDealsAppointmentsNotesUsingID) {
    yield put(switchGlobalLoader(true));
    try {
        const { dataFilter, errorFilter } = yield getKarlDealsAppointmentNotes(action.payload.dealId, action.payload.appointmentId);
        if (dataFilter) {
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* saveKarlDealsAppointmentNotesSaga(action: HandleSaveDealsAppointmentsNotesUsingID) {
    yield put(switchGlobalLoader(true));
    try {
        const { dataFilter, errorFilter } = yield saveKarlDealsAppointmentNotes(action.payload.dealId, action.payload.appointmentId, action.payload.details);
        if (dataFilter) {
            action.payload.callback(dataFilter);
        }
        if (errorFilter) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

/* deals TradeIn Notes*/

function* getKarlDealsTradeInNotesSaga(action: handleGetDealsTradeInNotesUsingID) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId } = action.payload;
        const tradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
        if (tradeInInfo) {
            const { dataFilter } = yield getKarlDealTradeInNotes(dealId, tradeInInfo.id);
            action.payload.callback(dataFilter);
        }else
            action.payload.callback(null);
        yield put(switchGlobalLoader(false));
        return;       
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;       
    }
}

function* saveKarlDealsTradeInNotesSaga(action: handleGetDealsTradeInNotesUsingID) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, details } = action.payload;
        const selectedTradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
        if (!selectedTradeInInfo) {
            const appraisalInfo = yield saveAppraisal({}, dealId);
            if (appraisalInfo && appraisalInfo.data) {
                yield put(handleSelectedTradeIn(appraisalInfo.data));
                const { dataFilter } = yield saveKarlDealTradeInNotes(dealId, appraisalInfo.data.id, details);
                action.payload.callback(dataFilter);
                yield put(switchGlobalLoader(false));
                return;
            }         
            
        } else {
            const { dataFilter } = yield saveKarlDealTradeInNotes(dealId, selectedTradeInInfo.id, details);
            action.payload.callback(dataFilter);
            yield put(switchGlobalLoader(false));
            return;
        }
        action.payload.callback(null);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* getDealRecordsSaga(action: HandleGetDealsTracker) {
    yield put(switchGlobalLoader(false));
    try {
        yield put(pendingGetDealsTracker());
        const { data, error } = yield getDealRecordList();
        if (data) {
            yield put(resolvedGetDealsTracker(data.results));
        } else {
            yield put(rejectedGetDealsTracker(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetDealsTracker(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

/* FINANCE PRODUCT*/

export function* getFinanceProductSaga(actions: HandleGetFinanceProduct) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetFinanceProduct());
        const { id } = actions.payload;

        const { error, data } = yield getFinanceProduct({ id });

        if (error) {
            yield put(rejectedGetFinanceProduct(error.violations as any));
            yield put(switchGlobalLoader(false));
            return;
        }

        yield put(resolvedGetFinanceProduct(data.results));
    }
    catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* viewFinanceProductSaga(action: HandleViewFinanceProductItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, productID, formData } = action.payload;
        const { data } = yield getFinanceProductItem(id, productID, formData);
        if (typeof action.payload.callback === "function") {
            action.payload.callback(data);
        }

    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* createFinanceProductSaga(action: HandleCreateFinanceProductItem) {
    yield put(switchGlobalLoader(true));

    try {
        const { id, formData } = action.payload;
        const { result } = yield addFinanceProductItem(id, formData);
        if (typeof action.payload.callback === "function") {
            if (result) {

                const { error, data } = yield getFinanceProduct({ id });
                if (error) {
                    yield put(rejectedGetFinanceProduct(error.violations as any));
                } else {
                    yield put(resolvedGetFinanceProduct(data.results));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* updateFinanceProductSaga(action: HandleUpdateFinanceProductItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData } = action.payload;
        const { result } = yield saveFinanceProductItem(id, formData.id, formData);
        if (typeof action.payload.callback === "function") {
            if (result) {

                const { error, data } = yield getFinanceProduct({ id });
                if (error) {
                    yield put(rejectedGetFinanceProduct(error.violations as any));
                } else {
                    yield put(resolvedGetFinanceProduct(data.results));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* deleteFinanceProductSaga(action: HandleDeleteFinanceProductItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, productFinanceListId, formData } = action.payload;
        const { result } = yield deleteFinanceProductItem(id, productFinanceListId, formData);
        if (typeof action.payload.callback === "function") {
            if (result) {

                const { error, data } = yield getFinanceProduct({ id });
                if (error) {
                    yield put(rejectedGetFinanceProduct(error.violations as any));
                } else {
                    yield put(resolvedGetFinanceProduct(data.results));
                }
            }
            action.payload.callback(result);
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* createVehicleStockSaga(action: HandleCreateVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData, callback } = action.payload;
        const { result,error } = yield addVehicleStockItem(id, formData);

        if (result) {
            yield put(resolvedViewDealTracker(result));
            callback(result);
        }else{
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* createVehicleOrderSaga(action: HandleCreateVehicleOrderItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData, callback } = action.payload;
        const { result, error } = yield addVehicleOrderItem(id, formData);

        if (result) {
            yield put(resolvedViewDealTracker(result));
            callback(result);
        } else {
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* updateVehicleStockSaga(action: HandleUpdateVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, vehicleId, formData, callback } = action.payload;
        const { result,error } = yield updateVehicleStockItem(id, vehicleId, formData);
        if (result) {
            yield put(resolvedUpdateDelVehTracker(result));
            callback(result);
        }else{
            callback(error);
        }
        
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

/***Display Board***/
function* getDisplayBoardSaga(action: HandleGetDisplayboard) {
    yield put(switchGlobalLoader(true));
    try {
        // yield put(pendingGetAppointments());
        const { data, error } = yield getDisplayboard(action.payload.data);
        if (data) {
            action.payload.callback(data)
        } else {
            action.payload.callback(error)
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Appointments***/
function* getAppointmentsSaga(action: HandleGetAppointments) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetAppointments());
        const { data, error } = yield getAppointments();
        if (data) {
            yield put(resolvedGetAppointments(data.results));
        } else {
            yield put(rejectedGetAppointments(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetAppointments(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getAppointmentsByDealSaga(action: HandleGetAppointmentsByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield getAppointmentsByDeal(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* saveAppointmentSaga(action: HandleSaveAppointment) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, appointment, callback } = action.payload;
        const { data } = yield saveAppointment(appointment, dealId);
        callback(data);
        if (!appointment.id || !data)
            yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getAppointmentInfoSaga(action: handleGetAppointmentInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, appointmentId, callback } = action.payload;
        const { data } = yield viewAppointment(dealId, appointmentId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/* Appraisal Table*/

export function* getAppraisalTableSaga(actions: HandleGetAppraisalTable) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetAppraisalTable());
        const { dealId } = actions.payload;
        const tradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
        if (tradeInInfo) {
            const { error, data } = yield getAppraisalTable({ dealId, tradeInId:tradeInInfo.id });
            if (data) {
                yield put(resolvedGetAppraisalTable(data.results));
            }
            if (error) {
                yield put(rejectedGetAppraisalTable(error.violations as any));
            }
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(rejectedGetAppraisalTable('Something went wrong.'));
        yield put(switchGlobalLoader(false));
        return;
    }
    catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}


function* createAppraisalTableSaga(action: HandleCreateAppraisalTableItem) {
    yield put(switchGlobalLoader(true));

    try {
        const { dealId, formData } = action.payload;
        const selectedTradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
        if (!selectedTradeInInfo) {
            const appraisalInfo = yield saveAppraisal({}, dealId);
            if (appraisalInfo && appraisalInfo.data) {
                yield put(handleSelectedTradeIn(appraisalInfo.data));
                const { result } = yield addAppraisalTableItem(dealId, appraisalInfo.data.id, formData);
                if (typeof action.payload.callback === "function") {
                    if (result) {
                        const { error, data } = yield getAppraisalTable({ dealId, tradeInId: appraisalInfo.data.id });
                        if (error) {
                            yield put(rejectedGetAppraisalTable(error.violations as any));
                        } else {
                            yield put(resolvedGetAppraisalTable(data.results));
                        }
                    }
                    action.payload.callback(result);
                    yield put(switchGlobalLoader(false));
                    return;
                }
            }
            
        } else {
            const { result } = yield addAppraisalTableItem(dealId, selectedTradeInInfo.id, formData);
            if (typeof action.payload.callback === "function") {
                if (result) {

                    const { error, data } = yield getAppraisalTable({ dealId, tradeInId: selectedTradeInInfo.id });
                    if (error) {
                        yield put(rejectedGetAppraisalTable(error.violations as any));
                    } else {
                        yield put(resolvedGetAppraisalTable(data.results));
                    }
                }
                action.payload.callback(result);
                yield put(switchGlobalLoader(false));
                return;
            }
        }
        action.payload.callback(null);
        yield put(switchGlobalLoader(false));
        return;
        
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Appraisal***/
function* getAppraisalsSaga(action: HandleGetAppraisals) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetAppraisals());
        const { data, error } = yield getAppraisals();
        if (data) {
            yield put(resolvedGetAppraisals(data.results));
        } else {
            yield put(rejectedGetAppraisals(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetAppraisals(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getAppraisalsByDealSaga(action: HandleGetAppraisalsByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(handleSelectedTradeIn(null));
        const { dealId, callback } = action.payload;
        const { data } = yield getAppraisalsByDeal(dealId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* saveAppraisalSaga(action: HandleSaveAppraisal) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, appraisal, callback } = action.payload;
        const selectedTradeInInfo = yield select((state: AppState) => state.dealTracker.selectedTradeIn);
        const { data } = yield saveAppraisal(appraisal, dealId, selectedTradeInInfo?.id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getAppraisalInfoSaga(action: handleGetAppraisalInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, appraisalId, callback } = action.payload;
        const { data } = yield viewAppraisal(dealId, appraisalId);
        if (data) {
            yield put(handleSelectedTradeIn(data));
        }
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* deleteAppraisalItemSaga(action: HandleDeleteAppraisalItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, appraisalId, callback } = action.payload;
        const { data } = yield deleteAppraisalItem(dealId, appraisalId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***ServiceContract***/  
function* getServiceContractSaga(action: HandleGetServiceContract) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetServiceContract());
        const { data, error } = yield getServiceContract();
        if (data) {
            yield put(resolvedGetServiceContract(data.results));
        } else {
            yield put(rejectedGetServiceContract(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetServiceContract(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* saveServiceContractSaga(action: HandleSaveServiceContract) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, serviceContract} = action.payload;
        const { data } = yield saveServiceContract(serviceContract, id);
        if (typeof action.payload.callback === "function") {
            if (data) {

                const { error, data } = yield getServiceContractItem({ id });
                if (error) {
                    yield put(rejectedGetServiceContractList(error.violations as any));
                } else {
                    yield put(resolvedGetServiceContractList(data.results));
                }
            }
            action.payload.callback(data);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

export function* getServiceContractItemSaga(actions: HandleGetServiceContractList) {

    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetServiceContractList());
        const { id } = actions.payload;

        const { error, data } = yield getServiceContractItem({ id });

        if (error) {
            yield put(rejectedGetServiceContractList(error.violations as any));
            yield put(switchGlobalLoader(false));
            return;
        }

        yield put(resolvedGetServiceContractList(data.results));
    }
    catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}


function* viewServiceContractSaga(action: HandleViewServiceContractItem) { 
    yield put(switchGlobalLoader(true));

    try {
        const { id, productID, formData } = action.payload;
        const { data } = yield viewServiceContractItem(id, productID, formData);
        if (typeof action.payload.callback === "function") {
            action.payload.callback(data);
        }

    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
    yield put(switchGlobalLoader(false));
}

function* updateServiceContractSaga(action: HandleUpdateServiceContractItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData } = action.payload;
        const { result } = yield updateServiceContractItem(id, formData.id, formData);
         if (typeof action.payload.callback === "function") {
            if (result) {

                const { error, data } = yield getServiceContractItem({ id });
                if (error) {
                    yield put(rejectedGetServiceContractList(error.violations as any));
                } else {
                    yield put(resolvedGetServiceContractList(data.results));
                }
            }
            action.payload.callback(result);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}
/***Task List***/
function* getTasksSaga(action: HandleGetTasks) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetTasks());
        const { data, error } = yield getTasks();
        if (data) {
            yield put(resolvedGetTasks(data));
        } else {
            yield put(rejectedGetTasks(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetTasks(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getTasksByDealSaga(action: HandleGetTasksByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield getTasksByDeal(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getTaskSaga(action: HandleGetTask) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, taskId, callback } = action.payload;
        const { data } = yield viewTask(dealId, taskId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* createTaskSaga(action: HandleCreateTask) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, task, callback } = action.payload;
        const { data } = yield saveTask(task, id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* updateTaskStatusSaga(action: HandleUpdateTaskStatus) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, taskId, taskStatus, callback } = action.payload;
        const { data } = yield updateTaskStatus(id, taskId, taskStatus);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Task Notes***/
function* getTaskNotesSaga(action: HandleGetTaskNotes) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, taskId, callback } = action.payload;
        const { data, error } = yield getTaskNotes(id, taskId);
        if (data) {
            callback(data);
        }
        if (error) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* saveTaskNoteSaga(action: HandleSaveTaskNote) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, taskId, note, callback } = action.payload;
        const { data, error } = yield saveTaskNote(note, id, taskId);
        if (data) {
            callback(data);
        }
        if (error) {
            callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

/***Sales Hierarchy List***/
function* getSalesHierarchySaga(action: HandleGetSalesHierarchy) {
    try {
        yield put(pendingGetSalesHierarchy());
        const { data, error } = yield getSalesHierarchy();
        if (data) {
            yield put(resolvedGetSalesHierarchy(data));
        } else {
            yield put(rejectedGetSalesHierarchy(error));
        }
    } catch (error) {
        yield put(rejectedGetSalesHierarchy(error));
        return;
    }
}

/***Get Sales Target***/
function* getSalesTargetSaga(action: HandleGetSalesTarget) {
    yield put(switchGlobalLoader(true));
    try {
        const { year, isFirstTime, callback } = action.payload;
        if (isFirstTime) {
            const getSalesHierarchyAction: HandleGetSalesHierarchy = {
                type: HANDLE_GET_SALES_HIERARCHY,
            };
            yield call(getSalesHierarchySaga, getSalesHierarchyAction);
        }
        const { data, error } = yield getSalesTarget(year);
        if (data) {
            const salesHierarchyList = yield select(
                (state: AppState) => state.dealTracker.salesHierarchyList
            );
            callback({ salesTarget: data, salesHierarchy: salesHierarchyList});
        }
        if (error) {
            action.payload.callback(false);
            yield put(switchGlobalLoader(false));
            return;
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

/***Save Sales Target***/
function* saveSalesTargetSaga(action: HandleSaveSalesTarget) {
    yield put(switchGlobalLoader(true));
    try {
        const { salesTarget, callback } = action.payload;
        const { data } = yield saveSalesTarget(salesTarget);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

/***Cancellations List***/
function* getCancellationsSaga(action: HandleGetCancellations) {
    yield put(switchGlobalLoader(true));
    try {
        yield put(pendingGetCancellations());
        const { data, error } = yield getCancellations();
        if (data) {
            yield put(resolvedGetCancellations(data));
        } else {
            yield put(rejectedGetCancellations(error));
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedGetCancellations(error));
        yield put(switchGlobalLoader(false));
        return;
    }
    
}

function* getCancellationsByDealSaga(action: HandleGetCancellationsByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, callback } = action.payload;
        const { data } = yield getCancellationsByDeal(dealId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

 function* getCancellationInfoSaga(action: HandleGetCancellationInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, cancellationId, callback } = action.payload;
        const { data } = yield getCancellationInfo(dealId, cancellationId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
} 

function* saveCancellationSaga(action: HandleSaveCancellation) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, formData, callback } = action.payload;
        const { data } = yield saveCancellation(formData, dealId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Get Finanac e& Delivery Person List***/
function* getAllFinDelPersonSaga(action: handleGetAllFinDelPerson) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield getAllFinDelPerson('financePerson&active=1&limit=1000');
        let result = {
            'finance': data?.results
        }
        if (data) {
            const { data, error } = yield getAllFinDelPerson(`deliveryCoordinator&locationId=${action.payload.locationId}&active=1&limit=1000`);
            if (data) {
                result['delivery'] = data?.results;
                action.payload.callback(result);
                return;
            } else {
                action.payload.callback(error);
                yield put(switchGlobalLoader(false));
                return;
            }    
        } else {
            action.payload.callback(error);
            yield put(switchGlobalLoader(false));
            return;
        }
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Get Finanace & Delivery Person Appoitns based on Date ***/
/* function* getFinDelPersonApptByDateSaga(action: handleGetAllFinDelApptDate) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield getFinDelPersonApptByDate(action.payload);
        if (data) {
            action.payload.callback(data);
        } else {
            action.payload.callback(error);
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
} */

/***POST Finanace & Delivery Person Appoitns based on Date ***/
function* postFinDelPersonApptByDateSaga(action: handlePostAllFinDelApptDate) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield postFinDelPersonApptByDate(action.payload.scheduleData);
        if (data) {
            action.payload.callback(data);
        } else {
            action.payload.callback(error);
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***GET Finanace & Delivery SCHEDULE based on Date ***/
function* getFinDelPersonScheduleSaga(action: handleGetAllFinanceDeliverySchedule) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield getFinDelPersonSchedule('financePerson', action.payload.date);
        let result = {
            'financeSchedule': data
        }
        if (data) {
            const { data, error } = yield getFinDelPersonSchedule('deliveryCoordinator', action.payload.date);
            if (data) {
                result['deliverySchedule'] = data;
                action.payload.callback(result);
                return;
            } else {
                action.payload.callback(error);
                yield put(switchGlobalLoader(false));
                return;
            }    
        } else {
            action.payload.callback(error);
            yield put(switchGlobalLoader(false));
            return;
        }
        // yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***POST Finanace & Delivery PSCHEDULE ***/
function* getFinDelPersonAppByDealSaga(action: handlePostAllFinanceDeliveryDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield getFinDelPersonAppByDeal(action.payload.dealId, 'finance');
        console.log('error in Finance',error)
        let result = {
            'financeSchedule': data ? data : false
        }
        if (result) {
            const { data, error } = yield getFinDelPersonAppByDeal(action.payload.dealId, 'dc');
            console.log('error in Delivery',error)
            result['deliverySchedule'] = data ? data : false;
            action.payload.callback(result); 
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***POST Finanace & Delivery PSCHEDULE ***/
function* postFinDelPersonApptDealSaga(action: handlePostAllFinanceDeliveryDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield postFinDelPersonApptDeal(action.payload.dealId, action.payload.scheduleData);
        if (data) {
            action.payload.callback(data);
        } else {
            console.log('error', error)
            action.payload.callback(false);
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***PATCH & DELETE Finanace & Delivery Non DEAL ***/
function* patchDelFinDelPersonApptDealSaga(action: handlePatchDelAllFinanceDeliveryDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield patchDelFinDelPersonApptDeal(action.payload.dealId, action.payload.role, action.payload.method, action.payload.scheduleData);
        if (data) {
            action.payload.callback(data);
        } else {
            console.log('error', error)
            action.payload.callback(false);
        }
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/** Print Cancellation**/
function* printDealCancellationSaga(action: HandlePrintDealCancellation) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, callback } = action.payload;
        const { result } = yield printCancellation( dealId);
    
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* printDealCancellationListItemSaga(action: HandlePrintDealCancellationListItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId,cancellationId, callback } = action.payload;
        const { result } = yield printCancellationListItem( dealId,cancellationId);
    
        callback(result);
        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getDealsSummarySaga(action: HandleGetDealsSummary) {
    yield put(switchGlobalLoader(true));
    try {
        const { callback } = action.payload;
        const { data } = yield getDealsSummary();
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Vehicle Preps***/
function* getPrepsSaga(action: HandleGetPreps) {
    yield put(switchGlobalLoader(true));
    try {
        const { date, carType, locationId, callback } = action.payload;
        const { data } = yield getPreps(date, carType, locationId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getPrepsByDealSaga(action: HandleGetPrepsByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, date, carType, callback } = action.payload;
        const { data } = yield getPrepsByDeal(dealId, date, carType);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getPrepsInfoSaga(action: HandleGetPrepsInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield getPrepsInfo(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* savePrepsSaga(action: HandleSavePreps) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield savePreps(formData);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* savePrepsByDealSaga(action: HandleSavePrepsByDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const { dealId, formData, callback } = action.payload;
        const { data } = yield savePrepsByDeal(dealId, formData);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* deletePrepsInfoSaga(action: HandleDeletePrepsInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield deletePrepsInfo(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/***Vehicle Preps***/
function* getDealerTradesSaga(action: HandleGetDealerTrades) {
    yield put(switchGlobalLoader(true));
    try {
        const { tradeType, callback } = action.payload;
        const { data } = yield getDealerTrades(tradeType);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getDealerTradesV2Saga(action: HandleGetDealerTradesV2) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { dealerData } = yield getDealerTradesV2({ formData });
        callback(dealerData);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getDealerTradeInfoSaga(action: HandleGetDealerTradeInfo) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, callback } = action.payload;
        const { data } = yield getDealerTradeInfo(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* saveDealerTradeSaga(action: HandleSaveDealerTrade) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield saveDealerTrade(formData);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getVehicleTrimVINSaga(action: HandleGetDTVehicleTrimUsingVin) {
    yield put(switchGlobalLoader(true));

    try {
        let id = action.payload.id;
        if (!id) {
            const { data } = yield saveDealerTrade({ type: action.payload.tradeType });
            id = data?.id;
        }
        if (id) {
            const { dataFilter, errorFilter } = yield getDTVehicleTrimVIN(action.payload.vin, id);
            
            if (dataFilter) {
                if (!action.payload.id) {
                    dataFilter["id"] = id;
                }
                action.payload.callback(dataFilter);
            }

            if (errorFilter) {
                if (!action.payload.id) {
                    errorFilter["id"] = id;
                }
                action.payload.callback(errorFilter);
            }
        } else {
            action.payload.callback(null);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));
}

function* getVehicleTrimYMMSaga(action: HandleGetDTVehicleTrimUsingYmm) {
    yield put(switchGlobalLoader(true));

    try {
        let id = action.payload.id;
        if (!id) {
            const { data } = yield saveDealerTrade({ type: action.payload.tradeType });
            id = data?.id;
        }
        if (id) {
            const { dataFilter, errorFilter } = yield getDTVehicleTrimYMM(action.payload.ymm, id);

            if (dataFilter) {
                if (!action.payload.id) {
                    dataFilter["id"] = id;
                }
                action.payload.callback(dataFilter);
            }
            if (errorFilter) {
                action.payload.callback(false);
            }
        } else {
            action.payload.callback(null);
        }
        
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
    }
    yield put(switchGlobalLoader(false));

}  



function* getVehDetailsSaga(action: HandleGetDTVehicleDetails) {
    
    yield put(switchGlobalLoader(true));

    try {
        let id = action.payload.id;
        if (!id) {
            const { data } = yield saveDealerTrade({ type: action.payload.tradeType });
            id = data?.id;
        }
        if(id){
            const { dataFilter, errorFilter } = yield getDTVehDetails(action.payload.vehDet, id, action.payload.action);
            if (dataFilter) {
                //yield put(handleGetVehDetailsSuccess(dataFilter));
                //if (isKarlProfileType && selectedDeal.id) {
                //    yield put(handleViewDealTracker(selectedDeal.id));
                //}
                action.payload.callback({...dataFilter,dealerId:id});
            }
            else if (errorFilter) {
                // yield put(showSnackbar(NotificationType.error, 'Failed to fetch Vehicle Data'));
                action.payload.callback(false);
            }
            yield put(switchGlobalLoader(false));
            return;
        }

    } catch (error) {
        // yield put(rejectedGetInventoryList(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* createDealerTradeVehicleStockSaga(action: HandleCreateDTVehicleStock) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData, vehicleAction, callback } = action.payload;
        const { result } = yield addDTVehicleStockItem(id, formData, vehicleAction);
        //if (result) {
        //    yield put(resolvedViewDealTracker(result));
        //}
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* updateDTVehicleStockSaga(action: HandleUpdateDTVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, vehicleId, formData, vehicleAction, callback } = action.payload;
        const { result } = yield updateDTVehicleStockItem(id, vehicleId, formData, vehicleAction);
        if (result) {
            yield put(resolvedUpdateDelVehTracker(result));
        }
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* dealerTradeCheckSaga(action: HandleDealerTradeCheck) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, data, callback } = action.payload;
        const { result, error } = yield dealerTradeCheck(id, data);
        if (result) {
            callback(result);
        } else {
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* vehicleStockCheckSaga(action: HandleUpdateDTVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, data,callback } = action.payload;
        const { result,error } = yield vehicleStockCheck(id, data);
           if (result) {
               callback(result);
           }else{
               callback(error);
           }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* vehicleTrackerCheckStockSaga(action: HandleVehicleTrackerCheckStock) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, formData,callback } = action.payload;
        const { result,error } = yield vehicleTrackerCheckStock(id, formData);
           if (result) {
               callback(result);
           }else{
               callback(error);
           }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* vehicleOrderCheckSaga(action: HandleUpdateDTVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, data, callback } = action.payload;
        const { result, error } = yield vehicleOrderCheck(id, data);
        if (result) {
            callback(result);
        } else {
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* dealerTradeVehicleStockCheckSaga(action: HandleUpdateDTVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, data, callback } = action.payload;
        const { result, error } = yield dealerTradeVehicleStockCheck(id, data);
        if (result) {
            callback(result);
        } else {
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* dealerTradeVehicleOrderCheckSaga(action: HandleUpdateDTVehicleStockItem) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, data, callback } = action.payload;
        const { result, error } = yield dealerTradeVehicleOrderCheck(id, data);
        if (result) {
            callback(result);
        } else {
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}
/**** Reports ***/

function* finnaceDeskingReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financeDeskingReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* lenderCountReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield lenderCountReports({formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* financeProductSalesReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financeProductSalesReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* financeReserveSplitsReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financeReserveSplitsReports({formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {   
        return;
    }
}

function* financeProductSplitsReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financeProductSplitsReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* karlDocReportsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield karlDocReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* getPerCarTotalsSaga(action: HandleGetFinnaceDeskingReports) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getPerCarTotals({ formData });
        
        callback(data); 

        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}



function* dashboardMikeReportsSaga(action: HandleGetDashboardMikeReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {callback } = action.payload;
        const { data } = yield dashboardMikeReports();

        callback(data);
        yield put(switchGlobalLoader(false));     
        return;
    } catch (error) {
        return;
    }
}

function* financePersonStackRankReportsSaga(action: HandleGetFinancePersonStackRankReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financePersonStackRankReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}
function* financePersonMonthEndReportsSaga(action: HandleGetFinancePersonMonthEndReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield financePersonMonthEndReports({formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* vacationReportsSaga(action: HandleGetVacationReports) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield vacationReports({ formData });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* salesPersonFinanceReportsSaga(action: HandleGetSalesPersonFinanceReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield salesPersonFinanceReports({formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* salesPersonMonthEndReportsSaga(action: HandleGetSalesPersonMonthEndReports) {
    yield put(switchGlobalLoader(true));
    try {
       const {formData,callback } = action.payload;
        const { data } = yield salesPersonMonthEndReports({formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
		yield put(switchGlobalLoader(false));
        return;
    }
}

function* cdrCpoReportsSaga(action: HandleGetCdrCpoReports) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield cdrCPOReports({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Deal KPI stats ***/
function* getDealsKPIStatsSaga(action: HandleGetDealsKPIStats) {
    yield put(switchGlobalLoader(true));
    try {
        const { locationId, callback } = action.payload;
        const { data } = yield getDealsKPIStats(locationId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

/*** print Pending Deals**/

function* printPendingDealsSaga(action: HandlePrintPendingDeal) {
    yield put(switchGlobalLoader(true));
    try {
        const {formData, callback } = action.payload;
        const { result } = yield printPendingDeals({formData});
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** WriteUps List ***/
function* getWriteUpsListSaga(action: HandleGetWriteUpsList) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getWriteUpsList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Appraisal List ***/
function* getAppraisalListSaga(action: HandleGetAppraisalList) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getAppraisalList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Appointment List ***/
function* getAppointmentListSaga(action: HandleGetAppointmentList) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getAppointmentList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Service Contract List ***/
function* getServiceContractListSaga(action: HandleGetServiceContractLists) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getServiceContractList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Cancellation List ***/
function* getCancellationListSaga(action: HandleGetCancellationLists) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getCancellationList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}
/**** Finance Detail Reports ***/
function* getFinanceDetailReportsSaga(action: HandleGetFinanceDetailReports) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getFinanceDetailReports({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* printfinanceDetailReportSaga(action: HandlePrintFinanceDetailReports) {
    yield put(switchGlobalLoader(true));
    try {
        const {formData, callback } = action.payload;
        const { result } = yield printfinanceDetailReport({formData});
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Task List ***/
function* getTaskListSaga(action: HandleGetTaskList) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getTaskList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

/**** Sales Pending Report ***/
function* getSalesPendingSaga(action: HandleGetSalesPendingReport) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getSalesPending({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* printSalesPendingReportSaga(action: HandlePrintSalesPendingReports) {
    yield put(switchGlobalLoader(true));
    try {
        const {formData, callback } = action.payload;
        const { result } = yield printSalesPendingReport({formData});
        callback(result);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}


/**** Get Deal Tracker Summary ***/
function* getDealTrackerSummarySaga(action: HandleGetDealTrackerSummary) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getDealTrackerSummary({ formData });
        if (data && formData.isSearchApplied) {

            const queryData = {
                search: formData.search,
                limit:1
            }
            const { dealerData } = yield getDealerTradesV2({ formData: queryData });
            if (dealerData) {
                const result = data;
                result['dealerTradesResponse'] = dealerData;
                callback(result);
                yield put(switchGlobalLoader(false));
                return;
            }
            callback(null);
            yield put(switchGlobalLoader(false));
            return;
        }
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}
/**** Get Deal Tracker Summary ***/
function* getFinancePendingSaga(action: HandleGetFinancePendingReport) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getFinancePending({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* printFinancePendingSaga(action: HandlePrintFinancePendingReport) {

    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield printFinancePending({ formData });
     
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* getVehicleTrackerListSaga(action: HandleGetVehicleTrackerList) {

    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield getVehicleTrackerList({ formData });
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* handleCreateVehicleTrackerListSaga(action: HandleCreateVehicleTrackerList) {
    yield put(switchGlobalLoader(true));
    try {
        const { formData, callback } = action.payload;
        const { data } = yield saveVehicleTrackerTrimYMM(formData);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* createVehicleTransferListSaga (action: HandleCreateVehicleTransferList) {
    yield put(switchGlobalLoader(true));
    try {
        const {id,formData, callback } = action.payload;
        const { data,error } = yield createVehicleTransferList(id,formData);
        if(data){
            callback(data);
        }else{
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* viewVehicleTransferSaga (action: HandleViewVehicleTrackerTransfer) {

    yield put(switchGlobalLoader(true));
    try {
        const {id,formData, callback } = action.payload;
        const { data } = yield viewVehicleTransfer(id,formData);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* updateVehicleTransferListSaga (action: HandleUpdateVehicleTransferList) {
    yield put(switchGlobalLoader(true));
    try {
        const {id,vehTransferId,formData, callback } = action.payload;
        const { data,error } = yield updateVehicleTransferList(id,vehTransferId,formData);
         if(data){
            callback(data);
        }else{
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* viewVehicleTransferListSaga (action: HandleViewVehicleTransferList) {

    yield put(switchGlobalLoader(true));
    try {
        const {id,vehTransferId, callback } = action.payload;
        const { data } = yield viewVehicleTransferList(id,vehTransferId);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* createVehicleTransferNoteSaga (action: HandleCreateVehicleTransferNote) {

    yield put(switchGlobalLoader(true));
    try {
        const {id,vehTransferId,formData, callback } = action.payload;
        const { data,error } = yield createVehicleTransferNote(id,vehTransferId,formData);
        if(data){
            callback(data);
        }else{
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* getVehicleTransferNoteSaga (action: HandleGetVehicleTransferNote) {

    yield put(switchGlobalLoader(true));
    try {
        const {id,vehTransferId, callback } = action.payload;
        const { data,error } = yield getVehicleTransferNote(id,vehTransferId);
        if(data){
            callback(data);
        }else{
            callback(error);
        }
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* viewVehicleTrackerSaga (action: HandleViewVehicleTracker) {

    yield put(switchGlobalLoader(true));
    try {
        const {id, callback } = action.payload;
        const { data } = yield viewVehicleTracker(id);
        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* soldVehicleTransferSaga (action: HandleSoldVehicleTransfer) {

    yield put(switchGlobalLoader(true));
    try {
        const {id,vehTransferId,formData, callback } = action.payload;
        const { data,error } = yield soldVehicleTransfer (id,vehTransferId,formData);
        if(data){
            callback(data);
        }else{
            callback(error);
        }
        
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

function* vehicleTrackerWriteUpCheckSaga (action: HandleVehicleTrackerWriteUpCheck) {

    yield put(switchGlobalLoader(true));
    try {
        const {id, callback } = action.payload;
        const { data,error } = yield vehicleTrackerWriteUpCheck (id);
        if(data){
            callback(data);
        }else{
            callback(error);
        }
        
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}


export default function* DealTrackerSaga() {
    yield takeLeading(HANDLE_GET_SALES_FINANCE_LIST, getDealTrackerSalesFinanceSaga);
    yield takeLeading(HANDLE_PUT_SALES_FINANCE_LIST, saveDealTrackerSalesFinanceSaga);
    yield takeLeading(HANDLE_GET_SELECTED_SALES_FINANCE_LIST, getDealTrackerSelectedSalesFinanceSaga);
    yield takeLeading(HANDLE_CREATE_DEAL_RECORD, createDealRecordSaga);
    yield takeLeading(HANDLE_SAVE_DEAL_RECORD, saveDealRecordSaga);
    yield takeLeading(HANDLE_VIEW_DEAL_TRACKER, viewDealSaga);
    yield takeLeading(HANDLE_VIEW_DEAL_NO_TRACKER, viewDealNoSaga);
    yield takeLeading(HANDLE_GET_DELIVERY_NOTES, getDeliveryNotesSaga);
    yield takeLeading(HANDLE_SAVE_DELIVERY_NOTE, saveDeliveryNotesSaga);
    yield takeLeading(HANDLE_GET_DEALS_TRADEIN_NOTES_ID, getKarlDealsTradeInNotesSaga);
    yield takeLeading(HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID, saveKarlDealsTradeInNotesSaga);
    yield takeLeading(HANDLE_GET_DEAL_NOTES, getDealNotesSaga);
    yield takeLeading(HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID, getKarlDealsAppointmentNotesSaga);
    yield takeLeading(HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID, saveKarlDealsAppointmentNotesSaga);
    yield takeLeading(HANDLE_GET_DEALS_TRACKER, getDealRecordsSaga);
    yield takeLeading(HANDLE_GET_FINANCEPRODUCT_LIST, getFinanceProductSaga);
    yield takeLeading(HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM, viewFinanceProductSaga);
    yield takeLeading(HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM, updateFinanceProductSaga);
    yield takeLeading(HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM, createFinanceProductSaga);
    yield takeLeading(HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM, deleteFinanceProductSaga);
    yield takeLeading(HANDLE_SAVE_DELIVERY, saveDeliverySaga);
    yield takeLeading(HANDLE_GET_DISPLAYBOARD, getDisplayBoardSaga);
    yield takeLeading(HANDLE_GET_APPOINTMENTS, getAppointmentsSaga);
    yield takeLeading(HANDLE_SAVE_APPOINTMENT, saveAppointmentSaga);
    yield takeLeading(HANDLE_GET_APPOINTMENT_INFO, getAppointmentInfoSaga);
    yield takeLeading(HANDLE_GET_APPOINTMENTS_BY_DEAL, getAppointmentsByDealSaga);
    yield takeLeading(HANDLE_GET_APPRAISALS, getAppraisalsSaga);
    yield takeLeading(HANDLE_SAVE_APPRAISAL, saveAppraisalSaga);
    yield takeLeading(HANDLE_GET_APPRAISAL_INFO, getAppraisalInfoSaga);
    yield takeLeading(HANDLE_DELETE_APPRAISAL_ITEM, deleteAppraisalItemSaga);
    yield takeLeading(HANDLE_GET_APPRAISALS_BY_DEAL, getAppraisalsByDealSaga);
    yield takeLeading(HANDLE_CREATE_VEHICLE_STOCK_ITEM, createVehicleStockSaga);
    yield takeLeading(HANDLE_CREATE_VEHICLE_ORDER_ITEM, createVehicleOrderSaga);
    yield takeLeading(HANDLE_GET_APPRAISAL_TABLE_LIST, getAppraisalTableSaga);
    yield takeLeading(HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM, createAppraisalTableSaga);
    yield takeLeading(HANDLE_UPDATE_VEHICLE_STOCK_ITEM, updateVehicleStockSaga);
    yield takeLeading(HANDLE_GET_DEAL_TRACKER, getDealTrackerSaga);
    yield takeLeading(HANDLE_GET_SERVICE_CONTRACT, getServiceContractSaga);
    yield takeLeading(HANDLE_SAVE_SERVICE_CONTRACT, saveServiceContractSaga);
    yield takeLeading(HANDLE_GET_SERVICE_CONTRACT_LIST, getServiceContractItemSaga);
    yield takeLeading( HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM, viewServiceContractSaga);
    yield takeLeading( HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM, updateServiceContractSaga);
    yield takeLeading(HANDLE_GET_TASKS, getTasksSaga);
    yield takeLeading(HANDLE_GET_TASK, getTaskSaga);
    yield takeLeading(HANDLE_CREATE_TASK, createTaskSaga);
    yield takeLeading(HANDLE_UPDATE_TASK_STATUS, updateTaskStatusSaga);
    yield takeLeading(HANDLE_GET_TASK_NOTES, getTaskNotesSaga);
    yield takeLeading(HANDLE_SAVE_TASK_NOTE, saveTaskNoteSaga);
    yield takeLeading(HANDLE_GET_SALES_HIERARCHY, getSalesHierarchySaga);
    yield takeLeading(HANDLE_GET_SALES_TARGET, getSalesTargetSaga);
    yield takeLeading(HANDLE_SAVE_SALES_TARGET, saveSalesTargetSaga);
    yield takeLeading(HANDLE_GET_DELIVERY, getDeliverySaga);
    yield takeLeading(HANDLE_GET_TASKS_BY_DEAL, getTasksByDealSaga);  
    yield takeLeading(HANDLE_GET_CANCELLATIONS, getCancellationsSaga);
    yield takeLeading(HANDLE_POST_ALL_FIN_Del_APPT_DATE, postFinDelPersonApptByDateSaga);
    yield takeLeading(HANDLE_GET_ALL_FIN_Del_SCHEDULE, getFinDelPersonScheduleSaga);
    yield takeLeading(HANDLE_GET_ALL_FIN_Del_BY_DEAL, getFinDelPersonAppByDealSaga);
    yield takeLeading(HANDLE_POST_ALL_FIN_Del_DEAL, postFinDelPersonApptDealSaga);
    yield takeLeading(HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL, patchDelFinDelPersonApptDealSaga);
    yield takeLeading(HANDLE_GET_ALL_FIN_Del_PERSON, getAllFinDelPersonSaga);
    yield takeLeading(HANDLE_GET_CANCELLATIONS_BY_DEAL, getCancellationsByDealSaga);
    yield takeLeading(HANDLE_GET_CANCELLATION_INFO, getCancellationInfoSaga);
    yield takeLeading(HANDLE_SAVE_CANCELLATION, saveCancellationSaga);
    yield takeLeading(HANDLE_SEARCH_DEAL_RECORD, searchDealRecordSaga);
    yield takeLeading(HANDLE_PRINT_DEAL_CANCELLATION, printDealCancellationSaga);
    yield takeLeading(HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM, printDealCancellationListItemSaga);
    yield takeLeading(HANDLE_GET_DEALS_SUMMARY, getDealsSummarySaga);
    yield takeLeading(HANDLE_GET_PREPS, getPrepsSaga);
    yield takeLeading(HANDLE_GET_PREPS_BY_DEAL, getPrepsByDealSaga);
    yield takeLeading(HANDLE_GET_PREPS_INFO, getPrepsInfoSaga);
    yield takeLeading(HANDLE_SAVE_PREPS, savePrepsSaga);
    yield takeLeading(HANDLE_DELETE_PREPS_INFO, deletePrepsInfoSaga);
    yield takeLeading(HANDLE_SAVE_PREPS_BY_DEAL, savePrepsByDealSaga);
    yield takeLeading(HANDLE_GET_DEALER_TRADES, getDealerTradesSaga);
    yield takeLeading(HANDLE_GET_DEALER_TRADES_V2, getDealerTradesV2Saga);
    yield takeLeading(HANDLE_GET__DEALER_TRADE_INFO, getDealerTradeInfoSaga);
    yield takeLeading(HANDLE_SAVE_DEALER_TRADE, saveDealerTradeSaga);
    yield takeLeading(HANDLE_GET_DT_VEHICLE_TRIM_VIN, getVehicleTrimVINSaga);
    yield takeLeading(HANDLE_GET_DT_VEHICLE_TRIM_YMM, getVehicleTrimYMMSaga);
    yield takeLeading(HANDLE_GET_DT_VEHICLE_DETAILS, getVehDetailsSaga);
    yield takeLeading(HANDLE_CREATE_DT_VEHICLE_STOCK, createDealerTradeVehicleStockSaga);   
    yield takeLeading(HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM, updateDTVehicleStockSaga);
    yield takeLeading(HANDLE_DEALER_TRADE_CHECK, dealerTradeCheckSaga);
    yield takeLeading(HANDLE_CHECK_VEHICLE_STOCK, vehicleStockCheckSaga);
    yield takeLeading(HANDLE_VEHICLE_TRACKER_STOCK_CHECK, vehicleTrackerCheckStockSaga);
    yield takeLeading(HANDLE_CHECK_VEHICLE_ORDER, vehicleOrderCheckSaga);
    yield takeLeading(HANDLE_CHECK_DT_VEHICLE_STOCK, dealerTradeVehicleStockCheckSaga);
    yield takeLeading(HANDLE_CHECK_DT_VEHICLE_ORDER, dealerTradeVehicleOrderCheckSaga);
    yield takeLeading(HANDLE_GET_FINNANCE_DESKING_REPORTS, finnaceDeskingReportsSaga);
    yield takeLeading(HANDLE_GET_LENDER_COUNT_REPORTS, lenderCountReportsSaga);  
    yield takeLeading(HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS, financeProductSalesReportsSaga); 
    yield takeLeading(HANDLE_GET_DEALS_KPI_STATS, getDealsKPIStatsSaga);
    yield takeLeading(HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS, financeReserveSplitsReportsSaga);
	yield takeLeading(HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS, financeProductSplitsReportsSaga);   
    yield takeLeading(HANDLE_GET_KARL_DOC_REPORTS, karlDocReportsSaga);
    yield takeLeading(HANDLE_GET_PER_CAR_TOTALS, getPerCarTotalsSaga);
    yield takeLeading(HANDLE_GET_DASHBOARD_MIKE_REPORTS, dashboardMikeReportsSaga);
    yield takeLeading(HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS, financePersonStackRankReportsSaga);
    yield takeLeading(HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS, financePersonMonthEndReportsSaga);
    yield takeLeading(HANDLE_GET_VACATION_REPORTS, vacationReportsSaga);
    yield takeLeading(HANDLE_GET_CDR_CPO_REPORTS, cdrCpoReportsSaga);
    yield takeLeading(HANDLE_GET_SALES_PERSON_FINANCE_REPORTS, salesPersonFinanceReportsSaga);
    yield takeLeading(HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS, salesPersonMonthEndReportsSaga);
    yield takeLeading(HANDLE_PRINT_PENDING_DEAL, printPendingDealsSaga);
    yield takeLeading(HANDLE_GET_WRITEUPS_LIST, getWriteUpsListSaga);
    yield takeLeading(HANDLE_GET_APPRAISALS_LIST, getAppraisalListSaga);
    yield takeLeading(HANDLE_GET_SERVICE_CONTRACT_LISTS, getServiceContractListSaga);
    yield takeLeading(HANDLE_GET_CANCELLATION_LISTS, getCancellationListSaga);
    yield takeLeading(HANDLE_GET_FINANCE_DETAIL_REPORTS, getFinanceDetailReportsSaga);
    yield takeLeading(HANDLE_PRINT_FINANCE_DETAIL_REPORTS, printfinanceDetailReportSaga);
    yield takeLeading(HANDLE_GET_APPOINTMENT_LIST, getAppointmentListSaga);
    yield takeLeading(HANDLE_GET_TASK_LIST, getTaskListSaga);
    yield takeLeading(HANDLE_GET_SALES_PENDING_REPORT, getSalesPendingSaga);
    yield takeLeading(HANDLE_PRINT_SALES_PENDING_REPORTS, printSalesPendingReportSaga);
    yield takeLeading(HANDLE_GET_DEAL_TRACKER_SUMMARY, getDealTrackerSummarySaga);
    yield takeLeading(HANDLE_GET_FINANCE_PENDING_REPORT, getFinancePendingSaga);
    yield takeLeading(HANDLE_PRINT_FINANCE_PENDING_REPORT, printFinancePendingSaga);
    yield takeLeading(HANDLE_CREATE_VEHICLE_TRACKER_LIST, handleCreateVehicleTrackerListSaga);
    yield takeLeading(HANDLE_GET_VEHICLE_TRACKER_LIST, getVehicleTrackerListSaga);
    yield takeLeading(HANDLE_CREATE_VEHICLE_TRANSFER_LIST, createVehicleTransferListSaga);
    yield takeLeading(HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER, viewVehicleTransferSaga); 
    yield takeLeading(HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST, viewVehicleTransferListSaga);
    yield takeLeading(HANDLE_UPDATE_VEHICLE_TRANSFER_LIST, updateVehicleTransferListSaga);
    yield takeLeading(HANDLE_CREATE_VEHICLE_TRANSFER_NOTE, createVehicleTransferNoteSaga);
    yield takeLeading(HANDLE_GET_VEHICLE_TRANSFER_NOTE, getVehicleTransferNoteSaga);
    yield takeLeading(HANDLE_VIEW_VEHICLE_TRACKER, viewVehicleTrackerSaga);
    yield takeLeading(HANDLE_SOLD_VEHICLE_TRANSFER, soldVehicleTransferSaga);
    yield takeLeading(HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK, vehicleTrackerWriteUpCheckSaga);
}


