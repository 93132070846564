import React, { useEffect, useState, FunctionComponent } from "react";
import { Modal, DefaultButton, PrimaryButton } from "@fluentui/react";

import { Stack } from "@fluentui/react/lib/Stack";
import ListItem from "components/ListItem/ListItem";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import Options from "components/dialogs/addInventoryDialog/secondaryDialogs/options/Options";

import { contentStyles as content } from "constants/styles";
import { styles } from "pages/accountSettingsPage/styles";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

import inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const contentStyles = content();

export enum OptionSteps {
  Options,
  Audio,
  PowerEquipment,
  Interior,
  Exterior,
  Safety,
  Luxury,
  Other,
}

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  getOptions: (options) => void;
  selectedOptions: any;
  onOptionChange: (options, category) => any;
  handleClear?: () => void;
  handleOnActionClick: () => void;
  formik: any;
  loading: boolean;
  actionButtonLabel: string;
}

export const NewOptionsDialog: FunctionComponent<IProps> = ({
  isOpen,
  onDismiss,
  selectedOptions,
  onOptionChange,
  handleClear,
  actionButtonLabel,
  handleOnActionClick,
  formik,
  loading,
}) => {
  const [optionsStep, setOptionsStep] = useState(OptionSteps.Options);
  const [back, setBack] = useState("main");

  const [
    secondaryOptionsDialogTitle,
    setSecondaryOptionsDialogTitle,
  ] = useState("");
  const [
    secondaryOptionsDialogContent,
    setSecondaryOptionsDialogContent,
  ] = useState(null);

  useEffect(() => {
    switch (optionsStep) {
      case OptionSteps.Options:
        setSecondaryOptionsDialogTitle("Options");
        setSecondaryOptionsDialogContent(null);
        setBack("main");
        break;

      case OptionSteps.Audio:
        setSecondaryOptionsDialogTitle("Audio/Video");
        setBack("options");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsAudio"
            values={inventoryDropdownsValues.audioOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsAudio}
          />
        );
        break;
      case OptionSteps.PowerEquipment:
        setSecondaryOptionsDialogTitle("Power Equipment");
        setBack("options");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsPower"
            values={inventoryDropdownsValues.powerEquipmentOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsPower}
          />
        );
        break;
      case OptionSteps.Interior:
        setBack("options");
        setSecondaryOptionsDialogTitle("Interior");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsInterior"
            values={inventoryDropdownsValues.interiorOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsInterior}
          />
        );
        break;
      case OptionSteps.Exterior:
        setBack("options");
        setSecondaryOptionsDialogTitle("Exterior");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsExterior"
            values={inventoryDropdownsValues.exteriorOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsExterior}
          />
        );
        break;

      case OptionSteps.Safety:
        setBack("options");
        setSecondaryOptionsDialogTitle("Safety Features");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsSafteyFeatures"
            values={inventoryDropdownsValues.safetyOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsSafety}
          />
        );
        break;
      case OptionSteps.Luxury:
        setBack("options");
        setSecondaryOptionsDialogTitle("Luxury");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsLuxury"
            values={inventoryDropdownsValues.luxuryOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsLuxury}
          />
        );
        break;
      case OptionSteps.Other:
        setBack("options");
        setSecondaryOptionsDialogTitle("Other");
        setSecondaryOptionsDialogContent(
          <Options
            category="optionsOther"
            values={inventoryDropdownsValues.otherOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions.optionsOther}
          />
        );
        break;
      default:
        console.log(`default switch`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsStep, selectedOptions]);

  const handleItemClick = (e, item) => {
    setOptionsStep(item);
  };

  const handleDismiss = () => {
    setOptionsStep(OptionSteps.Options);
  };

  const handleClose = () => {
    onDismiss();
  };

  const _renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <GoBackToMainDialogBtn
          label={secondaryOptionsDialogTitle}
          onClick={back === "options" ? handleDismiss : handleClose}
        />
      </div>
      <div className={`noScrollbar ${contentStyles.body}`}>
        {OptionSteps.Options === optionsStep ? (
          <div style={{ marginTop: 10 }}>
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Audio)}
              title="Audio/Video"
              subTitles={[
                "AM/FM",
                "Anti-Theft",
                "Bluetooth",
                "C.B",
                "CD Changer",
                "CD Player",
                "Cassette",
              ]}
              count={
                selectedOptions.optionsAudio?.length > 0 &&
                selectedOptions.optionsAudio?.length.toString()
              }
              optional={selectedOptions.optionsAudio?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.PowerEquipment)}
              title="Power Equipment"
              subTitles={["Power Steering", "Power Antenna", "Power Mirrors"]}
              count={
                selectedOptions.optionsPower?.length > 0 &&
                selectedOptions.optionsPower?.length.toString()
              }
              optional={selectedOptions.optionsPower?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Interior)}
              title="Interior"
              subTitles={[
                "2 Hi-Back Bucket Seats",
                "3rd Row Seating",
                "All Weather Floor Mats",
              ]}
              count={
                selectedOptions.optionsInterior?.length > 0 &&
                selectedOptions.optionsInterior?.length.toString()
              }
              optional={selectedOptions.optionsInterior?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Exterior)}
              title="Exterior"
              subTitles={[
                "Accent Stripes",
                "Aluminium Box",
                "Bedliner",
                "Body Side Molding",
              ]}
              count={
                selectedOptions.optionsExterior?.length > 0 &&
                selectedOptions.optionsExterior?.length.toString()
              }
              optional={selectedOptions.optionsExterior?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Safety)}
              title="Safety Features"
              subTitles={[
                "ABS Brakes",
                "Adaptive headlights",
                "Alarm System",
                "Child Proof Door Locks",
              ]}
              count={
                selectedOptions.optionsSafteyFeatures?.length > 0 &&
                selectedOptions.optionsSafteyFeatures?.length.toString()
              }
              optional={selectedOptions.optionsSafteyFeatures?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Luxury)}
              title="Luxury"
              subTitles={[
                "Air Conditioned Seats",
                "Air Conditioning",
                "Auto Climate Control",
              ]}
              count={
                selectedOptions.optionsLuxury?.length > 0 &&
                selectedOptions.optionsLuxury?.length.toString()
              }
              optional={selectedOptions.optionsLuxury?.length === 0}
            />
            <ListItem
              onClick={(e) => handleItemClick(e, OptionSteps.Other)}
              title="Other"
              subTitles={[
                "Air Suspension",
                "Auto Leveling",
                "Conventional Spare",
                "Differential Locks",
              ]}
              count={
                selectedOptions.optionsOther?.length > 0 &&
                selectedOptions.optionsOther?.length.toString()
              }
              optional={selectedOptions.optionsOther?.length === 0}
            />
          </div>
        ) : (
          <Stack>{secondaryOptionsDialogContent}</Stack>
        )}
      </div>

      <div className={contentStyles.footer}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton text="Clear" onClick={handleClear} />
            {/* temporary hide */}
            {/*<DefaultButton text="Save Draft" onClick={() => {}} />*/}
          </Stack>
          <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="end">
            <DefaultButton text="Close" onClick={onDismiss} />
            <PrimaryButton
              disabled={!formik.isValid || !formik.dirty}
              onRenderIcon={_renderSpinner}
              text={actionButtonLabel}
              onClick={handleOnActionClick}
            />
          </Stack>
        </Stack>
      </div>
    </Modal>
  );
};
