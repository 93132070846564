import { IUserProfile, IOwner, IUserProfileData } from 'types/userProfileTypes';
import {IFormError} from "types/errorTypes";

import * as actions from "./userProfile.types";

export const handleGetUserProfile = (id:number, isEmployee?: boolean) => {
    return {
        type: actions.HANDLE_GET_USER_PROFILE,
        payload: {id, isEmployee},
    };
};

export const pendingGetUserProfile = (): actions.GetUserProfilePending => {
    return {
        type: actions.GET_USER_PROFILE_PENDING,
    };
};

export const resolvedGetUserProfile = (
    userProfile: any
): actions.GetUserProfileResolved => {
    return {
        type: actions.GET_USER_PROFILE_RESOLVED,
        payload: userProfile,
    };
};

export const rejectedGetUserProfile = (
    errors: IFormError[]
): actions.GetUserProfileRejected => {
    return {
        type: actions.GET_USER_PROFILE_REJECTED,
        payload: errors,
    };
};



/** UPDATE_USER_PROFILE*/
export const handleUpdateUserProfile = (
    id,
    data: IUserProfileData
): actions.HandleUpdateUserProfile => {
    return {
        type: actions.HANDLE_UPDATE_USER_PROFILE,
        payload: { id, data },
    };
};

export const pendingUpdateUserProfile = (): actions.UpdateUserProfilePending => {
    return {
        type: actions.UPDATE_USER_PROFILE_PENDING,
    };
};

export const resolvedUpdateUserProfile = (userProfile: IUserProfile): actions.UpdateUserProfileResolved => {
    return {
        type: actions.UPDATE_USER_PROFILE_RESOLVED,
        payload: userProfile
    };
};

export const rejectedUpdateUserProfile = (
    errors: IFormError[]
): actions.UpdateUserProfileRejected => {
    return {
        type: actions.UPDATE_USER_PROFILE_REJECTED,
        payload: errors,
    };
};



// UPDATE_DEALER_PROFILE
export const handleUpdateDealerProfile = (
    id,
    data: any
): actions.HandleUpdateDealerProfile => {
    console.log(id, data)
    return {
        type: actions.HANDLE_UPDATE_DEALER_PROFILE,
        payload: { id, data },
    };
};

export const pendingUpdateDealerProfile = (): actions.UpdateDealerProfilePending => {
    return {
        type: actions.UPDATE_DEALER_PROFILE_PENDING,
    };
};

export const resolvedUpdateDealerProfile = (dealerProfile: any): actions.UpdateDealerProfileResolved => {
    return {
        type: actions.UPDATE_DEALER_PROFILE_RESOLVED,
        payload: dealerProfile
    };
};

export const rejectedUpdateDealerProfile = (
    errors: IFormError[]
): actions.UpdateDealerProfileRejected => {
    return {
        type: actions.UPDATE_DEALER_PROFILE_REJECTED,
        payload: errors,
    };
};

// UPLOAD_USER_PHOTO
export const handleUploadUserPhoto = (
    id,
    formData: any
): actions.HandleUploadUserPhoto => {


    return {
        type: actions.HANDLE_UPLOAD_USER_PHOTO,
        payload: { id, formData },
    };
};

export const pendingUploadUserPhoto = (): actions.UploadUserPhotoPending => {
    return {
        type: actions.UPLOAD_USER_PHOTO_PENDING,
    };
};

export const resolvedUploadUserPhoto= (data: any): actions.UploadUserPhotoResolved => {
    return {
        type: actions.UPLOAD_USER_PHOTO_RESOLVED,
        payload: data
    };
};

export const rejectedUploadUserPhoto = (
    errors: IFormError[]
): actions.UploadUserPhotoRejected => {
    return {
        type: actions.UPLOAD_USER_PHOTO_REJECTED,
        payload: errors,
    };
};

export const handleCleanUser = (): actions.HandleCleanUser => {
    return {
        type: actions.HANDLE_CLEAN_USER
    }
};


/** Get Owner List **/
export const handleGetOwners = () => {
    return {
        type: actions.HANDLE_GET_OWNERS,
    };
};

export const pendingGetOwners = (): actions.GetOwnersPending => {
    return {
        type: actions.GET_OWNERS_PENDING,
    };
};

export const resolvedGetOwners = (
    owners: IOwner[]
): actions.GetOwnersResolved => {
    return {
        type: actions.GET_OWNERS_RESOLVED,
        payload: owners,
    };
};

export const rejectedGetOwners = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.GetOwnersRejected => {
    return {
        type: actions.GET_OWNERS_REJECTED,
        payload: errors,
    };
};

/** Update Owners */
export const handleUpdateOwner = (
    owners: IOwner[]
): actions.HandleUpdateOwner => {
    
    return {
        type: actions.HANDLE_UPDATE_OWNER,
        payload: { owners },
    };
};

export const pendingUpdateOwner = (): actions.UpdateOwnerPending => {
    return {
        type: actions.UPDATE_OWNER_PENDING,
    };
};

export const resolvedUpdateOwner = (owners: IOwner[]): actions.UpdateOwnerResolved => {
    return {
        type: actions.UPDATE_OWNER_RESOLVED,
        payload: owners,
    };
};

export const rejectedUpdateOwner = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.UpdateOwnerRejected => {
    return {
        type: actions.UPDATE_OWNER_REJECTED,
        payload: errors,
    };
};






