import {ILocation, ICreateLocation} from "types/locationsTypes";

export const SET_SORT_LOCATIONS = 'SET_SORT_LOCATIONS';

export interface SetSortLocations {
    type: typeof SET_SORT_LOCATIONS,
    payload: string
}

export const HANDLE_GET_LOCATIONS = "HANDLE_GET_LOCATIONS";

export interface HandleGetLocations {
    type: typeof HANDLE_GET_LOCATIONS;
}

export const HANDLE_GET_POST_VACATION = "HANDLE_GET_POST_VACATION";

export interface HandleGetPostVacation {
    type: typeof HANDLE_GET_POST_VACATION,
    payload: any
}

export const GET_LOCATIONS_PENDING = "GET_LOCATIONS_PENDING";

export interface GetLocationsPending {
    type: typeof GET_LOCATIONS_PENDING;
}

export const GET_LOCATIONS_RESOLVED = "GET_LOCATIONS_RESOLVED";

export interface GetLocationsResolved {
    type: typeof GET_LOCATIONS_RESOLVED;
    payload: ILocation[];
}

export const GET_LOCATIONS_REJECTED = "GET_LOCATIONS_REJECTED";

export interface GetLocationsRejected {
    type: typeof GET_LOCATIONS_REJECTED;
    payload: object[];
}

export const HANDLE_CREATE_LOCATION = "HANDLE_CREATE_LOCATION";

export interface HandleCreateLocation {
    type: typeof HANDLE_CREATE_LOCATION;
    payload: { location: ICreateLocation; callback: () => void };
}

export const CREATE_LOCATION_PENDING = "CREATE_LOCATION_PENDING";

export interface CreateLocationPending {
    type: typeof CREATE_LOCATION_PENDING;
}

export const CREATE_LOCATION_RESOLVED = "CREATE_LOCATION_RESOLVED";

export interface CreateLocationResolved {
    type: typeof CREATE_LOCATION_RESOLVED;
    payload: ILocation;
}

export const SET_ROWS_PER_PAGE_LOCATIONS = "SET_ROWS_PER_PAGE_LOCATIONS";

export interface SetRowsPerPage {
    type: typeof SET_ROWS_PER_PAGE_LOCATIONS;
    payload: number;
}

/** VIEW_Location */
export const HANDLE_VIEW_LOCATION = "HANDLE_VIEW_LOCATION";

export interface HandleViewLocation {
    type: typeof HANDLE_VIEW_LOCATION;
    payload: string | number;
}

export const VIEW_LOCATION_PENDING = "VIEW_LOCATION_PENDING";

export interface ViewLocationPending {
    type: typeof VIEW_LOCATION_PENDING;
}

export const VIEW_LOCATION_RESOLVED = "VIEW_LOCATION_RESOLVED";

export interface ViewLocationResolved {
    type: typeof VIEW_LOCATION_RESOLVED;
    payload: ILocation;
}

export const VIEW_LOCATION_REJECTED = "VIEW_LOCATION_REJECTED";

export interface ViewLocationRejected {
    type: typeof VIEW_LOCATION_REJECTED;
    payload: object;
}

export const CREATE_LOCATION_REJECTED = "CREATE_LOCATION_REJECTED";

export interface CreateLocationRejected {
    type: typeof CREATE_LOCATION_REJECTED;
    payload: object[];
}

export const HANDLE_UPDATE_LOCATION = "HANDLE_UPDATE_LOCATION";

export interface HandleUpdateLocation {
    type: typeof HANDLE_UPDATE_LOCATION;
    payload: { location: ILocation; callback: () => void };
}

export const UPDATE_LOCATION_PENDING = "UPDATE_LOCATION_PENDING";

export interface UpdateLocationPending {
    type: typeof UPDATE_LOCATION_PENDING;
}

export const UPDATE_LOCATION_RESOLVED = "UPDATE_LOCATION_RESOLVED";

export interface UpdateLocationResolved {
    type: typeof UPDATE_LOCATION_RESOLVED;
}

export const UPDATE_LOCATION_REJECTED = "UPDATE_LOCATION_REJECTED";

export interface UpdateLocationRejected {
    type: typeof UPDATE_LOCATION_REJECTED;
    payload: object;
}

/** DELETE_Location */
export const HANDLE_DELETE_LOCATION = "HANDLE_DELETE_LOCATION";

export interface HandleDeleteLocation {
    type: typeof HANDLE_DELETE_LOCATION;
    payload: {id: string | number, callback: Function} ;
}

export const DELETE_LOCATION_PENDING = "DELETE_LOCATION_PENDING";

export interface DeleteLocationPending {
    type: typeof DELETE_LOCATION_PENDING;
}

export const DELETE_LOCATION_RESOLVED = "DELETE_LOCATION_RESOLVED";

export interface DeleteLocationResolved {
    type: typeof DELETE_LOCATION_RESOLVED;
}

export const DELETE_LOCATION_REJECTED = "DELETE_LOCATION_REJECTED";

export interface DeleteLocationRejected {
    type: typeof DELETE_LOCATION_REJECTED;
    payload: object;
}

export type LocationsActions =
    | HandleGetLocations
    | HandleGetPostVacation
    | GetLocationsPending
    | GetLocationsResolved
    | GetLocationsRejected
    | HandleCreateLocation
    | CreateLocationPending
    | CreateLocationResolved
    | CreateLocationRejected
    | SetRowsPerPage
    | HandleUpdateLocation
    | UpdateLocationPending
    | UpdateLocationResolved
    | UpdateLocationRejected
    | HandleViewLocation
    | ViewLocationPending
    | ViewLocationResolved
    | ViewLocationRejected
    | HandleDeleteLocation
    | DeleteLocationPending
    | DeleteLocationResolved
    | DeleteLocationRejected
    | SetSortLocations;
