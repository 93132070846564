import client, { clientFile } from "services/api/client";
import { IWebsite } from "types/websiteTypes";

export const getTemplatesApi = () => {
  return client(`user/master/website/templates`, {
    body: null,
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getWebsiteApi = () => {
  return client(`user/master/website/config`, {
    body: null,
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateWebsiteApi = (website: IWebsite) => {
  return client(`user/master/website/config`, {
    body: website,
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const uploadLogoApi = (formData: any) => {
  return clientFile(`user/master/website/logo`, { body: formData }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
