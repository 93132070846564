import { applyMiddleware, combineReducers,legacy_createStore as createStore} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import { handleLogout } from "store/auth/auth.action";
import jwt_decode from "jwt-decode";
import {
    getUserToken,
    removeUserToken,
} from "utils/localStorage/usersTokensLocalStorage";
import {
    removeTrackerStatus
} from "utils/localStorage/vehicleTrackerStatusLocalStorage";
import createSagaMiddleware from "redux-saga";
import { call, all } from "redux-saga/effects";

import { handleLogout } from "store/auth/auth.action"; // handleRefreshToken

//REDUCERS
import { UserProfileReducer } from "store/userProfile/userProfile.reducer";
import { GroupsPermissionsReducer } from "store/groupsPermissions/groupsPermissions.reducer";
import { ConfigReducer } from "store/config/config.reducer";
import { AuthReducer } from "store/auth/auth.reducer";
import { EmployeesReducer } from "store/employees/employees.reducer";
import { LocationsReducer } from "store/locations/locations.reducer";
import { GroupsReducer } from "store/groups/groups.reducer";
import { InventoryReducer } from "store/inventory/inventory.reducer";
import { ProspectsReducer } from "store/prospects/prospects.reducer";
import { DealsReducer } from "store/deals/deals.reducer";
import { DealTrackerReducer } from "store/dealTracker/dealTracker.reducer";
import { GlobalLoadingReducer } from "store/globalLoading/globalLoading.reducer";
import { SharedReducer } from "store/shared/shared.reducer";
import { CosignersReducer } from "store/cosigners/cosigners.reducer";
import { SubscriptionsReducer } from "store/subscriptions/subscriptions.reducer";
import { WebsiteReducer } from "store/website/website.reducer";
import NotifierReducer from "store/notifier/notifier.reducer";
import { DeskADealReducer } from "store/deskADeal/deskADeal.reducer";
import { NotificationsReducer } from "store/notifications/notifications.reducer";
import { PrimarySetupReducer } from "store/primarySetup/primarySetup.reducer";
import { BrandsReducer } from "store/brands/brands.reducer";
import { DealerWebsiteReducer } from "store/dealerWebsite/dealerwebsite.reducer";
import { AccountsReducer } from "store/accounts/accounts.reducer";
import { SelectListReducer } from "store/selectList/selectList.reducer";

// SAGAS
import SubscriptionSaga from "store/subscriptions/subscriptions.saga";
import AuthSaga from "store/auth/auth.saga";
import EmployeesSaga from "store/employees/employees.saga";
import LocationsSaga from "store/locations/locations.saga";
import UserProfileSaga from "store/userProfile/userProfile.saga";
import ConfigSaga from "store/config/config.saga";
import GroupsSaga from "store/groups/groups.saga";
import GroupsPermissionsSaga from "store/groupsPermissions/groupsPermissions.saga";
import InventorySaga from "store/inventory/inventory.saga";
import ProspectSaga from "store/prospects/prospects.saga";
import DealSaga from "store/deals/deals.saga";
import DealTrackerSaga from "store/dealTracker/dealTracker.saga";
import CosignersSaga from "store/cosigners/cosigners.saga";
import SharedSaga from "store/shared/shared.saga";
import WebsiteSaga from "store/website/website.saga";
import DeskADealSaga from "store/deskADeal/deskADeal.saga";
import NotificationsSaga from "store/notifications/notifications.saga";
import PrimarySetupSaga from "store/primarySetup/primarySetup.saga";
import BrandsSaga from "store/brands/brands.saga";
import DealerWebsiteSaga from "store/dealerWebsite/dealerwebsite.saga"
import AccountsSaga from "store/accounts/accounts.saga";
import CustomersSaga from "store/customers/customers.saga";
import SelectListSaga from "store/selectList/selectList.saga";

export const reducer = combineReducers({
    auth: AuthReducer,
    employees: EmployeesReducer,
    groups: GroupsReducer,
    locations: LocationsReducer,
    userProfile: UserProfileReducer,
    config: ConfigReducer,
    groupsPermissions: GroupsPermissionsReducer,
    inventory: InventoryReducer,
    prospects: ProspectsReducer,
    deals: DealsReducer,
    dealTracker: DealTrackerReducer,
    globalLoading: GlobalLoadingReducer,
    cosigners: CosignersReducer,
    shared: SharedReducer,
    subscriptions: SubscriptionsReducer,
    website: WebsiteReducer,
    notifier: NotifierReducer,
    deskADeal: DeskADealReducer,
    notifications: NotificationsReducer,
    primarySetup: PrimarySetupReducer,
    brands: BrandsReducer,
    dealerWebsite: DealerWebsiteReducer,
    accounts: AccountsReducer,
    selectList: SelectListReducer
});

export function* rootSaga() {
    yield all([
        call(AuthSaga),
        call(EmployeesSaga),
        call(LocationsSaga),
        call(UserProfileSaga),
        call(ConfigSaga),
        call(GroupsSaga),
        call(GroupsPermissionsSaga),
        call(InventorySaga),
        call(ProspectSaga),
        call(DealSaga),
        call(DealTrackerSaga),
        call(CosignersSaga),
        call(SharedSaga),
        call(SubscriptionSaga),
        call(WebsiteSaga),
        call(DeskADealSaga),
        call(NotificationsSaga),
        call(PrimarySetupSaga),
        call(BrandsSaga),
        call(DealerWebsiteSaga),
        call(AccountsSaga),
        call(CustomersSaga),
        call(SelectListSaga)
    ]);
}

export type AppState = ReturnType<typeof reducer>;
export type MiddlewareFunction = (
    store: any
) => (next: any) => (action: any) => any;

const refreshTokenMiddleware: MiddlewareFunction = ({ getState, dispatch }) => {
    return (next) => async (action) => {
        const localStorageToken = getUserToken();
        
        try {
            if (localStorageToken) {
                const decoded = jwt_decode(localStorageToken);
                const currentTime = Math.round(new Date().getTime() / 1000);

                if (decoded.exp - currentTime <= 0) {
                    removeUserToken();
                    removeTrackerStatus();
                    dispatch(handleLogout());
                    // dispatch(handleRefreshToken());
                    return;
                }

                return next(action);
            }
        } catch (err) {
            console.error("CUSTOM ERROR LOGOUT MIDDLEWARE", err);
        }
        return next(action);
    };
};

export const sagaMiddleware = createSagaMiddleware();

export default createStore(
    reducer,
    composeWithDevTools(applyMiddleware(refreshTokenMiddleware, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
