import React, { useEffect, useState } from "react";
import {
  Panel,
  IPanelStyles,
  PanelType,
} from "@fluentui/react/lib/Panel";
import {
  IconButton,
  Stack,
  DefaultButton,
  PrimaryButton,
  Checkbox,
  TextField,
  ComboBox,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "../dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { getTheme } from "@fluentui/react";
import { dropdownStyles, filterPanelStyles as content } from "constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { schedulerValues } from "constants/constants";
import { Col, Row } from "react-grid-system";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { IPickerItem } from "types/sharedTypes";
import { handleGetMakes, handleGetModels } from "store/shared/shared.action";
import {
  setProspectFilters,
  cleanProspectFilters,
  handleGetProspects,
} from "store/prospects/prospects.action";
import { prospectLeadTypeFilters } from "constants/constants";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
import masterDropdownValues from "constants/masterDropdownValues";

const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
  content: {
    paddingLeft: 0,
  },
  contentInner: {
    marginTop: 0,
    marginLeft: 0,
  },
};

const conditionValues = dropDownsValues.conditionValues.map((item) => {
  return { key: item, text: item };
});

const yearValues = dropDownsValues.yearValues.map((item) => {
  return { key: item, text: item };
});
const bodyStyleValues = dropDownsValues.bodyStyleValues.map((item) => {
  return { key: item, text: item };
});

const exteriorColorValues = dropDownsValues.exteriorColorValues.map((item) => {
  return { key: item, text: item };
});

const contentStyles = content();
const theme = getTheme();

const styles = {
  fullWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 15,
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProspectsFilterPanel: React.FC<IProps> = ({ isOpen, onClose }) => {
  const [makeValues, setMakeValues] = useState([]);
  const [modelValues, setModelValues] = useState([]);
  const [showModels, setShowModels] = useState(false);
  const loadingMakes = useSelector(
    (state: AppState) => state.shared.loadingMakes
  );
  const loadingModels = useSelector(
    (state: AppState) => state.shared.loadingModels
  );
  const models: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.models
  );
  const makes: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.makes
  );

  const filters = useSelector((state: AppState) => state.prospects.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(handleGetMakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (makes.length > 0) {
      const values = makes.map((item) => {
        return { key: item.id, text: item.label };
      });
      setMakeValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makes]);

  useEffect(() => {
    

    if (filters.vehicleMake.text !== "") {
      setShowModels(true);

      // formik.setFieldValue("model", { key: 0, text: "" });
      dispatch<any>(handleGetModels(filters.vehicleMake?.key?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.vehicleMake]);

  useEffect(() => {
    if (models.length > 0) {
      const values = models.map((item) => {
        return { key: item.id, text: item.label };
      });
      setModelValues(values);

      // if (modelFromVin !== "") {
      //   console.log(modelFromVin);
      //
      //   const vinModel = values.find((item: any) => {
      //     return item.text === modelFromVin;
      //   }) as any;
      //   
      //   if (Boolean(vinModel)) {
      //     
      //     formik.setFieldValue("model", {
      //       key: vinModel.key,
      //       text: vinModel.text,
      //     });
      //   }
      // }
    }
  }, [models]);

  const handleMultipleDropdownValueChangeStringOptions = (e, item) => {
    
    const property = e.target.id;

    let newValues = [...filters[property]];

    if (newValues.includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.key);
    } else {
      newValues.push(item.text);
    }

    dispatch<any>(
      setProspectFilters({
        ...filters,
        [property]: newValues,
      })
    );
  };

  // const handleMultipleDropdownValueChangeIntOptions = (e, item) => {
  //   const property = e.target.id;
  //
  //   let newValues = [...filters[property]];
  //
  //   if (newValues.includes(item.key)) {
  //     newValues = newValues.filter((element) => element !== item.key);
  //   } else {
  //     newValues.push(item.key);
  //   }
  //
  //   //TODO
  //   // dispatch<any>(
  //   //   setDealFilters({
  //   //     ...filters,
  //   //     [property]: newValues,
  //   //   })
  //   // );
  // };

  const cleanFilters = () => {
    dispatch<any>(cleanProspectFilters());
  };

  const handleFilter = () => {
    dispatch<any>(
      handleGetProspects({
        page: 1,
        // limit: this.props.rowsPerPage,
        //
        // sort: this.props.sort,
        // column: this.props.column,
      })
    );
    onClose();
  };

  const handleToggleCheckbox = (e, checked) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        [e.target.id]: Boolean(checked),
      })
    );
  };

  const handleMakeChange = (e, item) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        vehicleMake: item,
      })
    );
  };

  const handleModelChange = (e, item) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        vehicleModel: item,
      })
    );
  };

  const handleChangePrice = (e,name) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        [name]: e,
      })
    );
  };

  const handleChange = (e, item) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        [e.target.id]: item,
      })
    );
  };
  const handleChangeDropdown = (e, item) => {
    dispatch<any>(
      setProspectFilters({
        ...filters,
        [e.target.id]: item.text,
      })
    );
  };
  const _renderContent = () => {
    return (
      <>
        <div>
          <Checkbox
            id="haveDeals"
            label="Have a Deal(s)"
            checked={filters.haveDeals}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={styles.marginTop}>
          <Checkbox
            id="assignToMe"
            label="Assign To Me"
            checked={filters.assignToMe}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={styles.marginTop}>
          <Checkbox
            id="isFavourite"
            label="Favourite"
            checked={filters.isFavourite}
            onChange={handleToggleCheckbox}
          />
        </div>
        {/*<div style={styles.marginTop}>*/}
        {/*  <Checkbox*/}
        {/*    id="newProspect"*/}
        {/*    label="New Prospects"*/}
        {/*    checked={filters.newProspect}*/}
        {/*    onChange={handleToggleCheckbox}*/}
        {/*  />*/}
        {/*</div>*/}
        <div style={styles.marginTop}>
          <Checkbox
            id="tradeIn"
            label={`With ${"Trade In"}`}
            checked={filters.tradeIn}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={styles.marginTop}>
          <Checkbox
            id="vehicleInterested"
            label="Vehicle Interested"
            checked={filters.vehicleInterested}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={styles.marginTop}>
          <Checkbox
            id="availableEmail"
            label="Available Email Address"
            checked={filters.availableEmail}
            onChange={handleToggleCheckbox}
          />
        </div>
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Checkbox
            id="availablePhone"
            label="Available Phone Number"
            checked={filters.availablePhone}
            onChange={handleToggleCheckbox}
          />
        </div>
        <Dropdown
          id="scheduler"
          label="Scheduler"
          multiSelect
          options={schedulerValues}
          placeholder=""
          onChange={handleMultipleDropdownValueChangeStringOptions}
          selectedKeys={filters.scheduler}
        />
        <Dropdown
          id="leadType"
          label="Lead Type"
          multiSelect
          options={prospectLeadTypeFilters}
          placeholder=""
          selectedKeys={filters.leadType}
          onChange={handleMultipleDropdownValueChangeStringOptions}
        />
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div style={styles.fullWidth}>
          <label className="lbl-text required-Label">Min Monthly Income</label>
              <CurrencyInput
                  customInput={TextField }
                 // thousandSeparator={true}
                  // decimalScale={2}
                  id="minMonthlyIncome"
                  name="minMonthlyIncome"
                  //label="Min Monthly Income"
                  prefix={PRICE_PREFIX}
                  value={filters.minMonthlyIncome ? filters.minMonthlyIncome.replace(/,/g, '') : ""}
                  onChange={(e)=>{handleChangePrice(e,'minMonthlyIncome')}}
              />
            {/*<TextField*/}
            {/*  type="number"*/}
            {/*  id="minMonthlyIncome"*/}
            {/*  name="minMonthlyIncome"*/}
            {/*  label="Min Monthly Income"*/}
            {/*  prefix="$"*/}
            {/*  value={filters.minMonthlyIncome}*/}
            {/*  onChange={handleChange}*/}
            {/*/>*/}
          </div>
          <div style={styles.fullWidth}>
          <label className="lbl-text required-Label">Max Monthly Income</label>
              <CurrencyInput
                  customInput={TextField }
                 // thousandSeparator={true}
                  // decimalScale={2}
                  id="maxMonthlyIncome"
                  name="maxMonthlyIncome"
                  //label="Max Monthly Income"
                  prefix={PRICE_PREFIX}
                  value={filters.maxMonthlyIncome ? filters.maxMonthlyIncome.replace(/,/g, '') : ""}
                  onChange={(e)=>{handleChangePrice(e,'maxMonthlyIncome')}}
              />

            {/*<TextField*/}
            {/*  type="number"*/}
            {/*  id="maxMonthlyIncome"*/}
            {/*  name="maxMonthlyIncome"*/}
            {/*  label="Max Monthly Income"*/}
            {/*  prefix="$"*/}
            {/*  value={filters.maxMonthlyIncome}*/}
            {/*  onChange={handleChange}*/}
            {/*/>*/}
          </div>
        </Stack>
        <Row>
          <Col style={{ flex: 2 }}>
            <TextField
              id="zip"
              name="zip"
              label="ZIP"
              value={filters.zip}
              onChange={handleChange}
            />
          </Col>
          <Col style={{ flex: 4 }}>
            <TextField
              id="city"
              name="city"
              label="City"
              value={filters.city}
              onChange={handleChange}
            />
          </Col>
          <Col style={{ flex: 2 }}>
            {/*<TextField
              id="state"
              name="state"
              label="State"
              value={filters.state}
              onChange={handleChange}
            />*/}
             <Dropdown
                      id="state"
                      label="State"
                      selectedKey={filters.state}
                      onChange={handleChangeDropdown}
                      options={masterDropdownValues.stateCodes}
                      styles={dropdownStyles}
                  />
          </Col>
        </Row>

        <div style={{ fontSize: 18, marginTop: 30, marginBottom: 15 }}>
          Vehicle Wish
        </div>
        <Dropdown
          id="vehicleCondition"
          label="Condition"
          multiSelect
          options={conditionValues}
          placeholder=""
          onChange={handleMultipleDropdownValueChangeStringOptions}
          selectedKeys={filters.vehicleCondition}
        />
        {loadingMakes ? (
          <Spinner
            // styles={styles.spinnerStyles}
            style={{ marginTop: 20 }}
            size={SpinnerSize.medium}
          />
        ) : (
          <>
            <ComboBox
              id="vehicleMake"
              // componentRef={comboBoxRef}
              // defaultSelectedKey="C"
              label="Make"
              allowFreeform
              autoComplete="on"
              options={makeValues}
              // TODO
              onChange={handleMakeChange}
              selectedKey={filters.vehicleMake.key}
            />
          </>
        )}
        {showModels ? (
          loadingModels ? (
            <Spinner
              style={{ marginTop: 20 }}
              // styles={styles.spinnerStyles}
              size={SpinnerSize.medium}
            />
          ) : (
            <Dropdown
              id="vehicleModel"
              label="Model"
              placeholder=""
              options={modelValues}
              styles={dropdownStyles}
              onChange={handleModelChange}
              selectedKey={filters.vehicleModel.key}
            />
          )
        ) : null}
        <div style={styles.fullWidth}>
        <label className="lbl-text required-Label">Max Price</label>
            <CurrencyInput
                customInput={TextField }
               // thousandSeparator={true}
                // decimalScale={2}
                id="vehicleMaxPrice"
                name="vehicleMaxPrice"
                //label="Max Price"
                prefix={PRICE_PREFIX}
                value={filters.vehicleMaxPrice ? filters.vehicleMaxPrice.replace(/,/g, '') : ""}
                onChange={(e)=>{handleChangePrice(e,'vehicleMaxPrice')}}
            />

          {/*<TextField*/}
          {/*  type="number"*/}
          {/*  id="vehicleMaxPrice"*/}
          {/*  name="vehicleMaxPrice"*/}
          {/*  label="Max Price"*/}
          {/*  prefix="$"*/}
          {/*  value={filters.vehicleMaxPrice}*/}
          {/*  onChange={handleChange}*/}
          {/*/>*/}
        </div>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div style={styles.fullWidth}>
            <Dropdown
              id="vehicleMinYear"
              label="Minimum Year"
              placeholder=""
              options={yearValues}
              onChange={handleChangeDropdown}
              selectedKey={filters.vehicleMinYear}
            />
          </div>
          <div style={styles.fullWidth}>
            <Dropdown
              id="vehicleMaxYear"
              label="Maximum Year"
              placeholder=""
              options={yearValues}
              onChange={handleChangeDropdown}
              selectedKey={filters.vehicleMaxYear}
            />
          </div>
        </Stack>
        <TextField
          type="number"
          id="vehicleMaxMileage"
          name="vehicleMaxMileage"
          label="Max Mileage"
          value={filters.vehicleMaxMileage}
          onChange={handleChange}
        />

        <Dropdown
          id="vehicleBodyType"
          label="Body Type"
          multiSelect
          placeholder=""
          options={bodyStyleValues}
          onChange={handleMultipleDropdownValueChangeStringOptions}
          selectedKeys={filters.vehicleBodyType}
        />
        <div style={{ marginBottom: 80 }}>
          <Dropdown
            id="vehicleExteriorColor"
            label="Exterior Color"
            placeholder=""
            options={exteriorColorValues}
            onChange={handleChangeDropdown}
            selectedKey={filters.vehicleExteriorColor}
          />
        </div>
      </>
    );
  };

  return (
    <Panel
      styles={panelStyles}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onClose}
      hasCloseButton={false}
      // isLightDismiss={true}
      // // Use this prop to do special handling *only* for light dismiss.
      // // If you provide this, the normal onDismiss won't be called for light dismiss.
      // onLightDismissClick={onClose}

      // closeButtonAriaLabel="Close"
    >
      <Stack verticalAlign={"space-between"}>
        <div className={contentStyles.header}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ width: "100%" }}
          >
            <GoBackToMainDialogBtn label="Filters" onClick={onClose} />
            <IconButton
              styles={{
                root: {
                  color: theme.palette.neutralPrimary,
                  marginLeft: "auto",
                  marginTop: "4px",
                  marginRight: "2px",
                  // position: 'absolute',
                  // // zIndex: 1111,
                  // right: 0,
                },
                rootHovered: {
                  color: theme.palette.neutralDark,
                },
              }}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={onClose}
            />
          </Stack>
        </div>
        {/*<div style={{width: 600}}>test width</div>*/}
        <div className={contentStyles.content}>{_renderContent()}</div>
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">
            <DefaultButton text="Clear" onClick={cleanFilters} />
            <PrimaryButton text="View results" onClick={handleFilter} />
          </Stack>
        </div>
      </Stack>
    </Panel>
  );
};

export default ProspectsFilterPanel;
