import React, { Component } from "react";
// import { EditorState, convertToRaw } from 'draft-js';
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';
import { Stack } from "@fluentui/react";
//import { CustomFontText } from "components/customFontText/CustomFontText";
//import { colors } from "constants/constants";

interface IProps {
  //onWysiwygAdded: (html) => void
  htmlToSend: string;
  setHtmlToSend: (html) => void;
  editorState: any;
  setEditorState: (eState) => void;
  label?: string;
}

interface IState {
  //editorState: any
}

class WysiwygEditor extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      //editorState: EditorState.createEmpty(),
    };
  }

  onEditorStateChange: Function = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.setHtmlToSend(html);
    // this.props.setEditorState(editorState);
    this.props.setEditorState(editorState)
  };

  render() {
    const { editorState } = this.props;
    return (
      <>
        <Stack
          style={{ paddingBottom: 5 }}
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <div style={{ fontWeight: 600, fontSize: 14 }}>
            {this.props.label ? this.props.label : "Description"}
          </div>
           {/*<CustomFontText
            size={"14px"}
            color={colors.grayText}
            className={"optionalLabel"}
          >
            optional
          </CustomFontText>*/}
        </Stack>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          //onChange={this.handleChange}
        />
      </>
    );
  }
}

export default WysiwygEditor;
