import React, { useEffect, useState } from "react";
import ListItem from "components/ListItem/ListItem";
import { PartnerSettingsStatus } from "constants/constants";
import {Stack} from '@fluentui/react'

interface IProps {
  status: PartnerSettingsStatus;
  title: string;
  callback: () => void;
}

const PartnerSettingsListItem: React.FC<IProps> = ({
  status,
  callback,
  title,
}) => {
  const [subtitle, setSubtitle] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    switch (status) {
      case PartnerSettingsStatus.disabled:
        setSubtitle("the service has been disabled");
        setError(false);
        break;
      case PartnerSettingsStatus.requiresConfiguration:
        setSubtitle("service requires configuration");
        setError(true);
        break;
      case PartnerSettingsStatus.configured:
        setSubtitle("the service has been set up");
        setError(false);
        break;
      default:
        console.log("default");
    }
  }, [status]);

  return (
    <Stack style={{ marginBottom: 15 }}>
      <ListItem
        title={title}
        partner
        error={error}
        subTitles={[subtitle]}
        onClick={callback}
      />
    </Stack>
  );
};

export default PartnerSettingsListItem;
