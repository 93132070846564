import React from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    IconButton,
    PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
import moment from "moment";

interface IProps {
    isOpen?: any;
    onDismiss: () => void; 
    data: any;
}

export const DaysTakenDialog: React.FunctionComponent<IProps> = ({

    isOpen,
    onDismiss,
    data

}) => {

    return (
        
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord createCustomerRecordWidth  updateEmployeeDialogHeight"
        >

            <div className={contentStyles.header+" inventory-popup"}>

                <CustomFontText className="formTitle">Days Taken</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body +" deal-popup-body"} >

                <Stack>
                    

                    <Row>
                        <Col>
                            <strong className="dealtracker-txt-info-right left-title">{data.financePerson}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <strong className="dealtracker-txt-info-right left-title">Hire Date : {data.hireDateStr}</strong>
                        </Col>
                    </Row> 
                    {
                        data && data.vacationDates && data && data.vacationDates.map((item, index) => (

                            <Row>
                                <Col>
                                    <div> {item && moment(item).local().format('MM/DD/YYYY')} </div>
                                </Col>
                            </Row> 

                        ))
                    }
                    
                </Stack>
                <div className={contentStyles.footer}>
                    <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">
                        
                        <PrimaryButton
                            text="Close"
                            onClick={onDismiss}
                        />
                    </Stack>
                </div>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 480,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 80,
        paddingRight: 80,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
