import { takeEvery, takeLeading, put, call, select } from "redux-saga/effects";
import {
  HANDLE_CREATE_DEAL,
  HANDLE_DELETE_DEALS,
  HANDLE_GET_DEALS,
  HANDLE_DEAL_ASSIGN_SIGNER,
  HANDLE_DEAL_UNASSIGN_SIGNER,
  HANDLE_UPDATE_DEALS,
  HANDLE_VIEW_DEAL,
  HANDLE_UPDATE_DEAL_SETTINGS,
  HANDLE_GET_DEAL_SETTINGS,
  HandleDealAssignSigner,
  HandleCreateDeal,
  HandleDeleteDeal,
  HandleGetDeals,
  HandleDealUnassignSigner,
  HandleUpdateDeal,
  HandleViewDeal,
  HandleGetDealSettings,
  HandleUpdateDealSettings
} from "store/deals/deals.types";
import {
  rejectedCreateDeal,
  rejectedDeleteDeal,
  rejectedGetDeals,
  rejectedDealAssignSigner,
  rejectedDealUnassignSigner,
  rejectedUpdateDeal,
  rejectedViewDeal,
  pendingDealAssignSigner,
  pendingCreateDeal,
  pendingDeleteDeal,
  pendingGetDeals,
  pendingDealUnassignSigner,
  pendingUpdateDeal,
  pendingViewDeal,
  setSortDeals,
  // setRowsPerPage,
  resolvedCreateDeal,
  resolvedDeleteDeal,
  resolvedGetDeals,
  resolvedDealAssignSigner,
  resolvedDealUnassignSigner,
  resolvedUpdateDeal,
  pendingUpdateDealSettings,
  resolvedGetDealSettings,
  resolvedUpdateDealSettings,
  rejectedUpdateDealSettings
} from "store/deals/deals.action";

import {
  getDeals,
  assignSigner,
  unassignSigner,
  createDeals,
  deleteDeal,
  updateDeal,
  viewDeal,
  updateDealSettings,
  getDealSettings
} from "store/deals/deals.api";
import { AppState } from "../index";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

//import {IDeals} from 'types/dealTypes'

/**SHARED FUNCTIONS*/
export function* callBackgroundGetDeals() {
  const rowsPerPage = yield select(
    (state: AppState) => state.deals.rowsPerPageDeals
  );
  const page: number = yield select(
    (state: AppState) => state.deals.items.currentPage
  );
  const sort = yield select((state: AppState) => state.deals.sort);
  const sortColumn = yield select((state: AppState) => state.deals.column);

  const getDealsAction: HandleGetDeals = {
    payload: { limit: rowsPerPage, page, sort, column: sortColumn },
    type: HANDLE_GET_DEALS,
  };
  yield call(getDealsSaga, getDealsAction);
}

/*** MAIN FUNCTIONS */
function* getDealsSaga(action: HandleGetDeals) {
  yield put(switchGlobalLoader(true));
  const { page, limit, sort, column, parentId } = action.payload;

  const phrase = action.payload.phrase ? action.payload.phrase : "";

  yield put(pendingGetDeals());

  const rowsPerPage = yield select(
    (state: AppState) => state.deals.rowsPerPageDeals
  );
  const sortStore = yield select((state: AppState) => state.deals.sort);
  const sortColumn = yield select((state: AppState) => state.deals.column);
  const filterSettings = yield select((state: AppState) => state.deals.filters);

  

  // const dateFrom = filterSettings.dateFrom !== null && filterSettings.dateFrom ;
  // const dateTo = filterSettings.dateTo !== null && filterSettings.dateTo ;

  const dealStatuses = filterSettings.dealStatuses.join(",");
  const dealTypes = filterSettings.dealTypes.join(",");
  const dateFrom = filterSettings.dateFrom;
  const dateTo = filterSettings.dateTo;

  const haveCoSigner = filterSettings.haveCoSigner;
  const isFavourite = filterSettings.isFavourite;


  yield put(setSortDeals(sort ? sort : sortStore));

  try {
    const { data } = yield getDeals({
      page,
      limit: limit ? limit : rowsPerPage,
      sort: sort ? sort : sortStore,
      column: column ? column : sortColumn,
      parentId,
      phrase,
      dealStatuses,
      dealTypes,
      dateFrom,
      dateTo,
      haveCoSigner, isFavourite
    });

    console.log("deals from saga:", data);
    yield put(resolvedGetDeals(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetDeals(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* createDealSaga(action: HandleCreateDeal) {
  const { deal, callback } = action.payload;

  const cosigners = deal.cosigners;

  delete deal.cosigners;

  yield put(pendingCreateDeal());

  try {
    const { data } = yield createDeals(deal);
    yield put(resolvedCreateDeal(data));

    if (cosigners && cosigners.length > 0) {
      for (const cosigner of cosigners) {
        if (cosigner) {
          const { error } = yield assignSigner(data.id, cosigner.id);
          if (error) {
            return;
          }
        }
      }
    }

    callback();
    yield call(callBackgroundGetDeals);
  } catch (error) {
    yield put(rejectedCreateDeal(error));
    return;
  }
}

function* viewDealSaga(action: HandleViewDeal) {
  yield put(switchGlobalLoader(false));
  yield put(pendingViewDeal());
  const id = action.payload;

  try {
    const data = yield viewDeal(id);
    yield put(resolvedCreateDeal(data.data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedViewDeal(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* deleteDealSaga(action: HandleDeleteDeal) {
  // const dealIds = action.payload;

  const { ids, callback } = action.payload;
  yield put(pendingDeleteDeal());
  
  try {
    for (const id of ids) {
      const { error } = yield deleteDeal(id);
      if (error) {
        return;
      }
    }

    yield put(resolvedDeleteDeal());
    callback();
    yield call(callBackgroundGetDeals);
  } catch (error) {
    yield put(rejectedDeleteDeal(error));
    return;
  }
}

function* updateDealSaga(action: HandleUpdateDeal) {
  const { deals, callback } = action.payload;
  // yield put(switchGlobalLoader(true));

  yield put(pendingUpdateDeal());

  try {
    for (const item of deals) {
      console.log(item);
      // delete item.prospect;
      // delete item.inventory;

      const cosignersToAssign = (item as any).cosignersToAssign;
      const cosignersToUnassigned = (item as any).cosignersToUnassigned;

      

      delete item.signers;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.isFavourite;

      const { error } = yield updateDeal(item.id, item);

      if (error) {
        return;
      }
      if (Boolean(cosignersToAssign)) {
        for (const cosToAssign of cosignersToAssign) {
          if (cosToAssign !== null) {
            const { error } = yield assignSigner(item.id, cosToAssign.id);
            if (error) {
              return;
            }
          }
        }
      }

      if (Boolean(cosignersToUnassigned)) {
        for (const cosToUnassigned of cosignersToUnassigned) {
          if (cosToUnassigned !== null) {
            const { error } = yield unassignSigner(item.id, cosToUnassigned.id);
            if (error) {
              return;
            }
          }
        }
      }
    }
    yield put(resolvedUpdateDeal());
    callback();
    yield call(callBackgroundGetDeals);
    // yield put(switchGlobalLoader(false));
  } catch (error) {
    console.log(error);
    
    yield put(rejectedUpdateDeal(error));
    // yield put(switchGlobalLoader(false));
    return;
  }
}

function* dealAssignSignerSaga(action: HandleDealAssignSigner) {
  yield put(pendingDealAssignSigner());
  const { dealId, signerId } = action.payload;
  try {
    const { error } = yield assignSigner(dealId, signerId);
    if (error) {
      return;
    }
    yield put(resolvedDealAssignSigner());
    yield call(callBackgroundGetDeals);
  } catch (error) {
    yield put(rejectedDealAssignSigner(error));
    return;
  }
}

function* dealUnassignSignerSaga(action: HandleDealUnassignSigner) {
  yield put(pendingDealUnassignSigner());
  const { dealId, signerId } = action.payload;
  try {
    const { error } = yield unassignSigner(dealId, signerId);
    if (error) {
      return;
    }
    yield put(resolvedDealUnassignSigner());
    yield call(callBackgroundGetDeals);
  } catch (error) {
    yield put(rejectedDealUnassignSigner(error));
    return;
  }
}

function* getDealSettingsSaga(action: HandleGetDealSettings) {
  yield put(switchGlobalLoader(true));

  const { data, error } = yield getDealSettings();

  if (error) {
    yield put(switchGlobalLoader(false));
  }

  for (const d in data) {
    if (data.hasOwnProperty(d)) {
      data[d] = data[d] / 100;
    }
  }

  yield put(resolvedGetDealSettings(data));
  yield put(switchGlobalLoader(false));
}

function* updateDealSettingsSaga(action: HandleUpdateDealSettings) {
  yield put(pendingUpdateDealSettings());

  const values = {};
  for (const value in action.payload) {
    
    if (action.payload.hasOwnProperty(value)) {
      if (typeof action.payload[value] === "number") {
        values[value] = action.payload[value] * 100;
      } else {
        values[value] = parseFloat(action.payload[value]) * 100;
      }
      values[value] = parseInt(values[value]);
    }
  }

  console.log(values);
  
  

  const { data, error } = yield updateDealSettings(values);
  if (error) {
    yield put(rejectedUpdateDealSettings(error));
    return null;
  }
  yield put(resolvedUpdateDealSettings(data));
}

export default function* DealsSaga() {
  yield takeLeading(HANDLE_GET_DEALS, getDealsSaga);
  yield takeLeading(HANDLE_CREATE_DEAL, createDealSaga);
  yield takeLeading(HANDLE_VIEW_DEAL, viewDealSaga);
  yield takeEvery(HANDLE_DELETE_DEALS, deleteDealSaga);
  yield takeEvery(HANDLE_UPDATE_DEALS, updateDealSaga);
  yield takeLeading(HANDLE_DEAL_ASSIGN_SIGNER, dealAssignSignerSaga);
  yield takeLeading(HANDLE_DEAL_UNASSIGN_SIGNER, dealUnassignSignerSaga);
  yield takeLeading(HANDLE_GET_DEAL_SETTINGS, getDealSettingsSaga);
  yield takeLeading(HANDLE_UPDATE_DEAL_SETTINGS, updateDealSettingsSaga);
}
