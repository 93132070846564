import React, { useEffect} from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    //DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
//import { handleGetCustomers } from "store/customers/customers.action";
import { HandleGetPostVacation } from "store/locations/locations.action";
//import { handlePutSalesFinanceList } from "store/dealTracker/dealTracker.action";
//import { useParams } from "react-router-dom";
// import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { Calendar } from 'primereact/calendar';
import moment from "moment";

interface IProps {
    //isOpen: boolean;
    isOpen?: any;
    empName?: any;
    onDismiss: (res) => void; 
    selectedData: any;
    empID?: any;
}

const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
    return { key: item, text: item };
});

export const AddVaccationDaysDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    empName,
    onDismiss,
    selectedData,
    empID
}) => {

    const loading = useSelector((state: AppState) => state.employees.loading);
    //const [createDealError, setCreateDealError] = useState("");
    const [hireDate, setHireDate] = React.useState<any>(undefined);
    const dispatch = useDispatch();
    const yearRange = "1980:" + (new Date().getFullYear()+1);

    useEffect(() => {
        // dispatch<any>(handleGetLocations());
        console.log('dropDownsValues.yearValues', dropDownsValues.yearValues);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            month: (new Date().getUTCMonth()) +1,
            year: "",
            days: "",
            hireDate:""
        },
        validationSchema: Yup.object({
            month: Yup.string().required(),
            year: Yup.string().required(),
            days: Yup.string().required(),
            hireDate:Yup.string()
        }),
        onSubmit: (values) => {
        
            let obj = {
                numberOfDays: values.days ? Number(values.days) : 0,
                startingMonth: values.month,
                year: values.year ? Number(values.year) : 0,
                hireDate: values.hireDate ? values.hireDate:""
            }
            dispatch<any>(HandleGetPostVacation(empID, 'PUT', obj, (data) => {
                console.log('PUT VACATION LIST, ', data)
                if (data) {
                    onDismiss(data);
                }
            }));
        }
    });

    useEffect(() => {
        if (selectedData) {
            formik.setValues(
              {
                ...formik.values,
                'days': selectedData.numberOfDays,
                'year': selectedData.year ? String(selectedData.year) : '',
                'month': selectedData.startingMonth,
              },
            );
            setHireDate(new Date(selectedData.hireDate ));
        }
    }, [selectedData]);// eslint-disable-line react-hooks/exhaustive-deps

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDropdownChange = (e, item) => {
        formik.setValues(
          {
            ...formik.values,
            [e.target.id]: item.key,
          },
        );
    };

    const handleSaveVacation = () => {
        console.log('empName', empName)
        formik.handleSubmit();
    }


    const handleHireDateChange = (val) => {
      

       formik.setValues(
           {
               ...formik.values,
               "hireDate": val ? moment(val).format("YYYY-MM-DD") : "",
       
           }
       );
        setHireDate(val ? val :"");

    };
    return (
        
        <Modal
            isOpen={isOpen}
            onDismiss={()=> onDismiss(null)}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord createCustomerRecordWidth  updateEmployeeDialogHeight"
        >

            <div className={contentStyles.header+" inventory-popup"}>

                <CustomFontText className="formTitle">Vacation Days</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={()=> onDismiss(null)}
                />
            </div>
            <div className={contentStyles.body +" deal-popup-body"} style={{paddingBottom:"60px"}}>
                
                
                <Stack>

                        <Row>
                              <Col md={6} className="columnBtwSpace">
                                   <span  className=" vacationDialogTitleName pageName ">
                                       {empName}
                                   </span>
                              </Col>

     
                              <Col  md={6} style={{paddingLeft:"9px",paddingRight:"9px"}} >
                                    <div className="ms-TextField">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text ">Hire Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup p-datepicker-right">
                                                <Calendar
                                                    id="hireDate"
                                                    //className="modal-calendar"
                                                    value={hireDate}
                                                    style={{ width: "100%" }}
                                                    showIcon
                                                    onChange={(e) => handleHireDateChange(e.value)}
                                                    //onBlur={handleDateBlur}
                                                    monthNavigator
                                                    //required={true}
                                                    appendTo="self"
                                                    yearRange={yearRange}
                                                    yearNavigator />
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </Col>
                        </Row>
         
                        <Row>
                             <Col md={6} className="columnBtwSpace">  
                                {/* <label className="lbl-text">Starting Month</label> */}
                                  {/* <PRDropdown
                                    id="month"
                                    placeholder=""
                                    //value={formik.values.month}
                                    //options={cityDealerList}
                                    //onChange={onLocationChange}
                                    //optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown .customInputField"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />   */}    
                                <Dropdown
                                    id="month"
                                    label="Starting Month"
                                    //required={true}
                                    selectedKey={formik.values.month}
                                    //options={yearValues}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleDropdownChange}
                                    placeholder=""
                                    required={true}
                                    // styles={dropdownStyles}
                                    //onBlur={formik.handleBlur}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched.month && formik.errors.month
                                        ? formik.errors.month.toString()
                                        : null
                                    }
                                />                      
                             </Col>
                             <Col md={6} className="columnBtwSpace">
                                {/* <label className="lbl-text ">Year</label> */}
                                  {/* <PRDropdown
                                    id="year"
                                    placeholder=""
                                    //value={formik.values.locationId}
                                    //options={cityDealerList}
                                    //onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown .customInputField"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                /> */}
                                <Dropdown
                                  id="year"
                                  label="Year"
                                  required={true}
                                  selectedKey={formik.values.year}
                                  onChange={handleDropdownChange}
                                  placeholder=""
                                  options={yearValues}
                                 // styles={dropdownStyles}
                                  onBlur={formik.handleBlur}
                                  errorMessage={
                                    formik.touched.year && formik.errors.year
                                      ? formik.errors.year.toString()
                                      : null
                                  }
                                />
                             </Col>
                        </Row>
                        <Row> 
                             <Col md={6} className="deal-cust-number columnBtwSpace" >
                                  <TextField
										id="days"
										name="days"
										label="Days"
										placeholder=""
										autoComplete="off"
										value={formik.values.days}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required={true}
                                        errorMessage={
                                          formik.touched.days &&
                                          formik.errors.days &&
                                          formik.errors.days.toString()
                                        }
									/>                               
                             </Col>
                        </Row>    
                        <Row> 

                             <Col  className="deal-cust-number columnBtwSpace createCusFootBtn" style={{ marginTop:"25px",textAlign: "center"}}>
     
                                <PrimaryButton
                                    disabled={!formik.dirty || !formik.isValid}
                                    onRenderIcon={renderSpinner}
                                    text="Save"
                                    onClick={handleSaveVacation}
                                    //className="btnHoverColor"
                                /> 

                             </Col>  
                        </Row>  
                </Stack>
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 480,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 80,
        paddingRight: 80,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
