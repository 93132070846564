import { put, call, takeLeading} from "redux-saga/effects";

import {
  pendingGetNotifications,
  resolvedGetNotifications,
  rejectedGetNotifications,
  pendingGetNotificationsTypes,
  resolvedGetNotificationsTypes,
  rejectedGetNotificationsTypes,
  pendingUpdateNotification,
  rejectedUpdateNotification,
  resolvedUpdateNotification,
} from "store/notifications/notifications.action";

import {
  getNotificationListApi,
  getNotificationsTypesApi,
  updateNotificationApi,
} from "store/notifications/notifications.api";

import {
  HANDLE_GET_NOTIFICATIONS_TYPES,
  HANDLE_GET_NOTIFICATIONS,
  HANDLE_UPDATE_NOTIFICATION,
  HandleUpdateNotification,
} from "store/notifications/notifications.types";

function* getNotificationsTypesSaga() {
  yield put(pendingGetNotificationsTypes());

  const { error, data } = yield getNotificationsTypesApi();

  if (error) {
    yield put(rejectedGetNotificationsTypes(error));
    return null;
  }

  yield put(resolvedGetNotificationsTypes(data));
}

function* getNotificationsSaga() {
  
  yield put(pendingGetNotifications());

  const { error, data } = yield getNotificationListApi();
  

  if (error) {
    yield put(rejectedGetNotifications(error));
    return null;
  }

  yield put(resolvedGetNotifications(data));
}

function* updateNotificationSaga(action: HandleUpdateNotification) {
  yield put(pendingUpdateNotification());

  const { id, status } = action.payload;

  const { error } = yield updateNotificationApi(id, status);

  if (error) {
    yield put(rejectedUpdateNotification(error));
    return null;
  }

  yield put(resolvedUpdateNotification());

  yield call(getNotificationsSaga);
}

export default function* NotificationsSaga() {
  yield takeLeading(HANDLE_GET_NOTIFICATIONS_TYPES, getNotificationsTypesSaga);
  yield takeLeading(HANDLE_GET_NOTIFICATIONS, getNotificationsSaga);
  yield takeLeading(HANDLE_UPDATE_NOTIFICATION, updateNotificationSaga);
}
