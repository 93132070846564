import React from "react";
import { Stack, DefaultButton } from "@fluentui/react";

interface IProps {
  onDelete?: (string) => void;
  cart: any
}

const CreditCartItem:React.FC<IProps> = ({onDelete, cart}) => {
  const cartNumber = "**** **** **** " + cart.last4;


  return (
    <Stack
      style={{
        borderBottom: "2px solid #eee",
        paddingBottom: 10,
        marginBottom: 10,
      }}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="end"
    >

      <Stack
        tokens={{ childrenGap: 10 }}
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <div
          style={{ fontWeight: "bold", position: "relative", paddingLeft: 60 }}
        >
          {cart.brand === "Visa" ?

          <div className="visa" /> : <div className="masterCart"/>
          }
          {cartNumber}
        </div>
        <div>
          VALID THRU: <span style={{ fontWeight: "bold" }}>{`${cart.expMonth}/${cart.expYear}`}</span>
        </div>
      </Stack>
      {Boolean(onDelete) &&

        <Stack
            tokens={{childrenGap: 10}}
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
        >
          <DefaultButton
              // onRenderIcon={renderSpinner}
              text="Delete"
              onClick={() => onDelete(cart.stripeId)}
          />
        </Stack>
      }
    </Stack>
  );
};

export default CreditCartItem;
