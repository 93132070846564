import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { DefaultButton } from "@fluentui/react";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: () => void;
    handleRoute: (val) => void
}

export const DealPageSaveNotification: FunctionComponent<IProps> = ({
    onDismiss,
    handleRoute
}) => {

    const handleRouteTab = () => {
        handleRoute('yes');
    };

    const handleCancelRouting = () => {
        handleRoute('no');
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog changesNotSaved"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle">Notification</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className="body-auto-hight body-min-height-50" style={{ textAlign: "center", paddingTop: "10px"}}>
                <div style={{ marginLeft: "25px" }}>
                    <div>
                        <label className="lbl-text">Do you want to save the changes you made to this page?</label>
                    </div>
                </div>
            </div>
            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                        <DefaultButton
                            text="Discard"
                            onClick={handleRouteTab}
                        />
                    </div>
                    <div>
                        <PrimaryButton
                            className="proceedBtn"
                            style={{ marginLeft: "25px"}}
                            onClick={handleCancelRouting}
                            text="Save"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};



