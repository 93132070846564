import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  FontIcon,
  Toggle,
  mergeStyles,
} from "@fluentui/react";
import { colors, permissionsModules } from "constants/constants";
import { Pagination } from "components/table/pagination/Pagination";
import {
  HANDLE_GET_GROUPS_PERMISSIONS,
  HANDLE_GET_PERMISSIONS,
  HANDLE_UPDATE_PERMISSIONS,
  SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS,
  //   HANDLE_DELETE_LOCATION,
} from "store/groupsPermissions/groupsPermissions.types";
import { connect } from "react-redux";
// import { EditGroupDialog } from "components/dialogs/editGroupDialog/EditGroupDialog";
import { NewEditGroupDialog } from "components/dialogs/editGroupDialog/NewEditGroupDialog";
import { GroupsTableDropdown } from "components/table/groups/dropdown/GroupsTableDropdown";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { IPagination } from "types/paginationTypes";
import {
  IGroupPermissionUpdate,
  IPermission,
} from "types/groupPermissionTypes";
import { IGroup } from "types/groupTypes";
import { HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE } from "store/employees/employees.types";
import { eTypes, WithCredentials } from "hoc/withCredentials";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
// import { IUserProfile } from "../../../types/userProfileTypes";
// import { getUserProfile } from "../../../store/userProfile/userProfile.api";

const filterGroups = (groups, userProfile) => {
  if (userProfile.group) {
    return groups.filter((group) => group.key !== userProfile.group);
  } else {
    return groups;
  }
};

const iconStyles = mergeStyles({
  fontSize: 16,
  color: DefaultPalette.themePrimary,
});

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: "auto",
  },
  contentWrapper: {},
};

interface ITogglePartials {
  value: boolean;
  id?: number;
  type: number;
}

export interface IDocument {
  key: string;
  alias: string;
  groups: string;
  view: ITogglePartials;
  add: ITogglePartials;
  update: ITogglePartials;
  delete: ITogglePartials;
  employees: any; //FIXME: what type?
  type?: string;
  employeesCount?: number;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 20,
    paddingTop: 20,
  },
};

const cityCellStyles: IStackStyles = {
  root: {
    selectors: {
      ":hover": {
        color: DefaultPalette.themePrimary,
        cursor: "pointer",
      },
    },
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

const cellTokens: IStackTokens = {
  childrenGap: 10,
};

interface IProps {
  rowsPerPageAddGroupEmployees: number;
  groups: any[]; //groups.groups
  totalItems: number;
  currentPage: number;
  rowsPerPage: number;
  items: IGroup[];
  permissions: IPermission[];
  sort: string;
  column: string;
  handleGetEmployees: Function;
  handleGetPermissions: Function;
  handleGetGroups: Function;
  handleUpdatePermissions: Function;
  setRowsPerPage: Function;
  handleDeleteGroup: Function;
  loading: boolean;
  user: any;
}
interface IState {
  columns: IColumn[];
  selectionDetails: any;
  hovered: number;
  selectedInHeaderDropDown: any;
  rowsPerPage: number;
  isEditDialogOpen: boolean;
  groupId: number;
  activeId: number;
  loading: boolean;
  isConfirmBoxOpen: boolean;
  showEditConfig: boolean;
  shouldRerender: boolean;
  settingDependentGroupInformation: any;
  temporaryData: any;
}

class GroupsTable extends React.Component<IProps, IState> {
  private readonly _selection: Selection;

  // private _contextMenuRef: any;

  constructor(props: IProps) {
    super(props);

    const columns: any[] = [
      {
        key: "groups",
        name: "Groups",
        fieldName: "groups",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
            <Stack
              styles={cityCellStyles}
              tokens={cellTokens}
              horizontal
              verticalAlign="center"
            >
              {item.alias}
            </Stack>
          );
        },
      },
      {
        key: "view",
        name: "View",
        fieldName: "view",
        minWidth: 120,
        maxWidth: 120,
        isResizable: false,
        data: "number",
        onRender: (item: IDocument) => {
          if (item.view !== undefined) {
            if (item.type === "element")
              return (
                <Stack
                  styles={cityCellStyles}
                  tokens={cellTokens}
                  horizontal
                  verticalAlign="center"
                >
                  {/* <span style={{ fontSize: 12 }}>{item.view}</span> */}
                  <Toggle
                    id="view"
                    checked={item.view.value}
                    label=""
                    inlineLabel
                    onChange={() =>
                      this.handleToggle(item, item.view, !item.view.value)
                    }
                    // onBlur={formik.handleBlur}
                    onText="Active"
                    offText="Inactive"
                  />
                </Stack>
              );
          }
        },

        isPadded: true,
      },
      {
        key: "add",
        name: "Add",
        fieldName: "add",
        minWidth: 120,
        maxWidth: 120,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: any) => {
          if (item.add !== undefined) {
            if (item.type === "element")
              return (
                <Stack
                  styles={cityCellStyles}
                  tokens={cellTokens}
                  horizontal
                  verticalAlign="center"
                >
                  <Toggle
                    id="add"
                    checked={item.add.value}
                    label=""
                    inlineLabel
                    onChange={() =>
                      this.handleToggle(item, item.add, !item.add.value)
                    }
                    // onBlur={formik.handleBlur}
                    onText="Active"
                    offText="Inactive"
                  />
                </Stack>
              );
          }
        },
        isPadded: true,
      },
      {
        key: "update",
        name: "Update",
        fieldName: "update",
        minWidth: 120,
        maxWidth: 120,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          if (item.update !== undefined) {
            if (item.type === "element")
              return (
                <Stack
                  styles={cityCellStyles}
                  tokens={cellTokens}
                  horizontal
                  verticalAlign="center"
                >
                  <Toggle
                    id="update"
                    checked={item?.update?.value}
                    label=""
                    inlineLabel
                    onChange={() =>
                      this.handleToggle(item, item.update, !item.update.value)
                    }
                    // onBlur={formik.handleBlur}
                    onText="Active"
                    offText="Inactive"
                  />
                </Stack>
              );
          }
        },
        isPadded: true,
      },
      {
        key: "delete",
        name: "Delete",
        fieldName: "delete",
        minWidth: 120,
        maxWidth: 120,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          if (item.delete !== undefined) {
            if (item.type === "element")
              return (
                <Stack
                  styles={cityCellStyles}
                  tokens={cellTokens}
                  horizontal
                  verticalAlign="center"
                >
                  <Toggle
                    id="delete"
                    checked={item?.delete?.value}
                    label=""
                    inlineLabel
                    onChange={() =>
                      this.handleToggle(item, item.delete, !item.delete.value)
                    }
                    // onBlur={formik.handleBlur}
                    onText="Active"
                    offText="Inactive"
                  />
                </Stack>
              );
          }
        },
        isPadded: true,
      },
      {
        key: "employees",
        name: "Assigned Employees",
        fieldName: "employees",
        minWidth: 130,
        maxWidth: 300,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          if (item.type === "group-configure") {
            return (
              <Stack
                onClick={() => {}}
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
                style={{ marginLeft: 50 }}
              >
                <FontIcon
                  style={{ color: colors.black, fontSize: 16 }}
                  iconName="ReminderPerson"
                />
                <span
                  style={{
                    color: colors.black,
                    fontSize: 16,
                  }}
                >
                  {item.employeesCount}
                </span>
              </Stack>
            );
          }
        },
        isPadded: true,
      },
      {
        key: "groupActions",
        name: "Group Actions",
        fieldName: "groupActions",
        minWidth: 100,
        maxWidth: 300,
        isResizable: false,

        onRender: (item: any) => {
          if (item.type === "group-configure") {
            return (
              <WithCredentials
                groupAuths={
                  isUserMaster(this.props.user.roles)
                    ? []
                    : getModulePermissions(
                        this.props.user.auths,
                        permissionsModules.Auth
                      )
                }
                alias={"Auth"}
                type={eTypes.UPDATE}
                roles={this.props.user.roles}
              >
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 5 }}
                  style={{ marginLeft: 40, paddingTop: 0 }}
                  className="action-cell"
                >
                  <FontIcon className={iconStyles} iconName="Edit" />
                  <span
                    style={{
                      color: DefaultPalette.themePrimary,
                      paddingTop: 0,
                    }}
                    onClick={() => this._handleEditViewForm(this.state.hovered)}
                  >
                    Configure!
                  </span>
                </Stack>
              </WithCredentials>
            );
          }
        },
        isPadded: true,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      isEditDialogOpen: false,
      groupId: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      showEditConfig: false,
      shouldRerender: false,
      settingDependentGroupInformation: null,
      temporaryData: null,
    };
  }

  private _handleConfigureClick(groupId) {
    this.setState({
      showEditConfig: true,
    });
  }

  public componentDidMount() {
    this.props.handleGetPermissions();
    console.log(this.props.sort);
    this.props.handleGetGroups({
      page: 1,
      limit: this.props.rowsPerPage,
      sort: this.props.sort,
    });

    console.log("items", this.props.items);
    console.log("groups", this.props.groups);
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }
    
    
    if(this.props.items !== prevProps.items){
      this.setState({
        columns: [...this.state.columns]
      })
    }

    console.log("groups", this.props.groups);
  }

  private _handleEditViewForm(id: number) {
    // TODO
    console.log("ID", id);


    // FETCH_ALL_EMPLOYEES_WITHOUT_SELECTED_GROUP
    this.props.handleGetEmployees({
      page: parseInt("1"),
      limit: this.props.rowsPerPageAddGroupEmployees,
      withoutGroup: id,
    });

    this.setState({
      groupId: id,
      isEditDialogOpen: true,
    });
  }

  public handleToggle(item: any, toggle: ITogglePartials, value: boolean) {
    this.setState(
      {
        temporaryData: {
          item,
          toggle,
          value,
        },
      },
      () => {
        if (toggle.type === 2 || toggle.type === 3) {
          const elements = [item.module];
          item.module === "Location" && elements.push("Prospects", "Inventory");

          this.setState({
            isConfirmBoxOpen: true,
            settingDependentGroupInformation: (
              <div>
                Full functionality of this action requires additional
                permissions in:
                <br />
                {elements.map((element: string) => (
                  <div key={element}>
                    View access in <strong>{element}</strong>
                  </div>
                ))}
              </div>
            ),
          });
        } else this._handleUpdatePermissions(item, toggle, value);
      }
    );
  }

  private _handleUpdatePermissions(
    it?: any,
    togg?: ITogglePartials,
    val?: boolean
  ) {
    console.log(it);
    console.log(togg);
    console.log(val);


    const item = it ? it : this.state.temporaryData.item,
      toggle = togg ? togg : this.state.temporaryData.toggle,
      value = val ? val : this.state.temporaryData.value;
    // value = val

    const method = value ? "add" : "remove";
    // const action = this._getItemTypeName(toggle.type)
    const pagination: IPagination = {
      sort: this.props.sort,
      page: this.props.currentPage,
      limit: this.state.rowsPerPage,
    };
    this.setState({
      temporaryData: null,
    });

    this.props.handleUpdatePermissions([
      {
        permissionId: toggle.id,
        groupId: item.groupId,
        method,
        pag: pagination,
      },
    ]);
  }

  private _closeEditGroupDialog() {
    this.props.handleGetGroups({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
      sort: this.props.sort,
    });
    this.setState({
      isEditDialogOpen: false,
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    this.props.handleGetGroups({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      column: currColumn.fieldName,
    });

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState({
      columns: newColumns,
    });
  }

  //                     =============ACTIONS!! ========================================

  private _handleView(id: number) {
    this._closeEditGroupDialog();
  }

  private _handleDelete(id?: number) {
    // get all items from selection and then delete each separately
    this._selection
      .getSelection()
      .forEach((element: any) => this.props.handleDeleteGroup(element.id));

    //TODO: errors need to be managed
    this._selection.setAllSelected(false);
    this.setState({ isConfirmBoxOpen: false });
    this._closeEditGroupDialog();
  }

  private _handleGroupAction(action: string, state: boolean) {
    const pagination: IPagination = {
      sort: this.props.sort,
      page: this.props.currentPage,
      limit: this.state.rowsPerPage,
    };
    const items = this._selection
      .getSelection()
      .map((item: any) => {
        if (item[action].value !== state)
          return {
            permissionId: item[action].id,
            groupId: item.groupId,
            method: state ? "add" : "remove",
            pag: pagination,
          };

        return undefined;
      })
      .filter((item: IGroupPermissionUpdate) => item !== undefined);

    if (items.length > 0) this.props.handleUpdatePermissions(items);
  }

  //                     =============ACTIONS END!! ========================================
  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    this.props.setRowsPerPage(rowsOnPage);
    this.props.handleGetGroups({
      limit: rowsOnPage,
    });
    this.setState({ shouldRerender: true });
  }

  private _handlePageChange(page: number) {
    this.props.handleGetGroups({
      page: page,
      limit: this.props.rowsPerPage,
    });
    this.setState({ shouldRerender: true });
  }

  private _calculateSelectedItems(items: any) {
    return items.filter((i: any) => i.type === "element").length;
  }

  //               ===Pagination sector END =====
  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <>
        <Fabric>
          <div
            style={{
              paddingLeft: 25,
              paddingRight: 25,
              backgroundColor: "white",
            }}
          >
            {this.state.isEditDialogOpen && this.state.groupId && (
              // <EditGroupDialog
              //   groupId={this.state.groupId}
              //   isOpen={this.state.isEditDialogOpen}
              //   onDismiss={this._closeEditGroupDialog.bind(this)}
              // />
              <NewEditGroupDialog
              groupId={this.state.groupId}
              isOpen={this.state.isEditDialogOpen}
              onDismiss={this._closeEditGroupDialog.bind(this)}
              />
            )}
            <DetailsList
              styles={tableStyles}
              items={this.props.items}
              columns={columns}
              groups={this.props.groups}
              className="permissions-table"
              onRenderDetailsHeader={(props, defaultRender) => (
                <>
                  <Stack
                    tokens={stackTokens}
                    styles={stackStyles}
                    horizontal
                    verticalAlign="center"
                  >
                    <p
                      style={{ color: DefaultPalette.themePrimary, width: 100 }}
                    >
                      {selectionDetails.length === 0
                        ? "No items selected"
                        : this._calculateSelectedItems(selectionDetails) +
                          " items selected"}
                    </p>
                    {selectionDetails.length > 0 && (
                      <GroupsTableDropdown
                        onSet={(action, state) =>
                          this._handleGroupAction(action, state)
                        }
                        modulePermissions={this.props.permissions}
                      />
                    )}
                  </Stack>
                  <span>{defaultRender(props)}</span>
                </>
              )}
              onRenderRow={(props, defaultRender) => {
                if (
                  props.item.type === "group-configure" ||
                  props.item.type === "header"
                ) {
                  props.selectionMode = 0;
                }
                if (props.item.type === "header") return;
                return (
                  <div
                    onMouseEnter={() => {
                      props.item.type === "group-configure" &&
                        this.setState({ hovered: props.item.id });
                    }}
                    onMouseLeave={() =>
                      props.item.type !== "group-configure" &&
                      this.setState({ hovered: null })
                    }
                    style={
                      props.item.type === "header"
                        ? { display: "none", maxHeight: 0 }
                        : {}
                    }
                  >
                    {props.item.type === "header" ? (
                      <div
                        style={{
                          fontWeight: "bold",
                          marginLeft: "95px",
                          paddingTop: 10,
                          fontSize: 13,
                        }}
                      >
                        {props.item.module}
                      </div>
                    ) : props.item.type === "group-configure" ? (
                      <div
                        style={{
                          display: "flex",
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        <span style={{ width: "170px", textAlign: "left" }}>
                          {props.columns[5].onRender(props.item)}
                        </span>
                        <span
                          style={{
                            width: "170px",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        >
                          {props.columns[6].onRender(props.item)}
                        </span>
                      </div>
                    ) : (
                      defaultRender(props)
                    )}
                  </div>
                );
              }}
              onRenderCheckbox={(props, defaultRender) => {
                return <span>{defaultRender(props)}</span>;
              }}
              selection={this._selection}
              selectionMode={SelectionMode.multiple}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onColumnHeaderClick={(ev, column) =>
                this._onColumnClick(ev, column)
              }
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={() => {}}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              compact={true}
              onRenderDetailsFooter={(props, defaultRender) => {
                return (
                  <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <Pagination
                      rowsPerPage={this.props.rowsPerPage}
                      currentPage={this.props.currentPage.toString()}
                      onRowsNumberChange={(rows: number) => {
                        this._handleRowsNumberChange(rows);
                      }}
                      totalItems={this.props.totalItems}
                      onPageChange={(page: number) => {
                        this._handlePageChange(page);
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </Fabric>

        {this.state.isConfirmBoxOpen &&
          !this.state.settingDependentGroupInformation && (
            <ConfirmDialog
              loading={this.props.loading}
              description="Please confirm deletion of group"
              title="Confirm delete group"
              isOpen={this.state.isConfirmBoxOpen}
              onAction={() => this._handleDelete()}
              onDismiss={() => this.setState({ isConfirmBoxOpen: false })}
            />
          )}
        {/** SETTING DEPENDENT GROUP CONFIRMATION*/}
        {this.state.isConfirmBoxOpen &&
          this.state.settingDependentGroupInformation && (
            <ConfirmDialog
              loading={this.props.loading}
              description={this.state.settingDependentGroupInformation}
              title="Dependency information"
              isOpen={this.state.isConfirmBoxOpen}
              onAction={() => {
                this._handleUpdatePermissions();
                this.setState({
                  isConfirmBoxOpen: false,
                  settingDependentGroupInformation: null,
                });
              }}
              onDismiss={() =>
                this.setState({
                  settingDependentGroupInformation: null,
                  isConfirmBoxOpen: false,
                })
              }
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rowsPerPageAddGroupEmployees:
      state.employees.rowsPerPageForAddGroupAssignEmployee,
    groups: filterGroups(
      state.groupsPermissions.groups,
      state.userProfile.userProfile
    ), //groups.groups
    totalItems: state.groupsPermissions.items.totalItems,
    currentPage: state.groupsPermissions.items.currentPage,
    rowsPerPage: state.groupsPermissions.rowsPerPage,
    items: state.groupsPermissions.items.results,
    // permissions: state.groupsPermissions.permissions,
    sort: state.groupsPermissions.sort,
    loading: state.groups.loading,
    user: state.userProfile.userProfile,
    permissions: getModulePermissions(
      state.userProfile?.userProfile.auths
        ? state.userProfile?.userProfile.auths
        : [],
      permissionsModules.Auth
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetEmployees: (payload) =>
      dispatch({
        type: HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
        payload,
      }),
    handleGetPermissions: (payload) => {
      dispatch({ type: HANDLE_GET_PERMISSIONS, payload });
    },
    handleGetGroups: (payload) => {
      dispatch({ type: HANDLE_GET_GROUPS_PERMISSIONS, payload });
    },
    handleUpdatePermissions: (items: IGroupPermissionUpdate[]) => {
      dispatch({ type: HANDLE_UPDATE_PERMISSIONS, payload: items });
    },
    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
