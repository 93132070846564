import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
// import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { DefaultButton } from "@fluentui/react";

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};

interface IProps {
    modulePermissions: IPermission[];
    onEdit: (itemId: number) => void;
    onActivateDeactivate: (itemId: number, isActive:boolean) => void;
    itemId: number;
    isActive: boolean;
    updatePermission: boolean;
    deletePermission: boolean;
}

export const EditActivateDeactivateActionsSubmenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    onEdit,
    onActivateDeactivate,
    itemId,
    isActive,
    updatePermission,
    deletePermission
}) => {
  const [menuProps, setMenuProps] = useState(null);
  // const dispatch = useDispatch();

    useEffect(() => {
      const menuItems: IContextualMenuItem[] = [
          {
              key: "edit", //with submenu
              name: "Edit",
              iconProps: { iconName: "Edit", styles: iconMenuStyles },
              onClick: () => onEdit(itemId),
              style: !updatePermission ? hiddenStyle : null,
          },
          {
            key: "delete", //with submenu
              iconProps: { iconName: isActive ? "Cancel" : "Accept", styles: iconMenuStyles },
            name: isActive?"Deactivate":"Activate",
              style: !deletePermission ? hiddenStyle : null,
              onClick: () => onActivateDeactivate(itemId, isActive),
          }
      ];    

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
      <>
          <IconButton
              className="iconButton icon-ellipse-h deskDisplay"
              styles={buttonStyles}
              text="Group Action"
              menuProps={menuProps}
          />
          <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" menuProps={menuProps} />
      </>
    
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
