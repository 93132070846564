// import { IRegister, ILoginResponse, ILogin } from "types/authTypes";
import {
  ICreateEmployee,
  IEmployee,
  IEmployeesProspect,
} from "types/employeesTypes";
import { IPagination, IPaginationResponse } from "types/paginationTypes";

import * as actions from "./employees.types";
import { SET_SORT_EMPLOYEES } from "./employees.types";
import { IFormError } from "types/errorTypes";
import { ITasks } from "types/taskTypes";

// export const handleGetEmployees = ({
//   email,
//   password,
//   firstName,
//   lastName,
// }: IRegister): actions.HandleGetEmployees => {
//
//   return {
//     type: actions.HANDLE_GET_EMPLOYEES,
//     // payload: { email, password, firstName, lastName },
//   };
// };
//export const CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED

export const clearCurrPageInGroupsAssignAndAssigned = () => {
  return {
    type: actions.CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED,
  };
};

export const setRowsPerPage = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE_EMPLOYEES,
    payload: rows,
  };
};

export const setRowsPerPageForAddGroupAssignEmployee = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE_EMPLOYEES,
    payload: rows,
  };
};

export const setRowsPerPageForAddGroupAssignedEmployee = (rows: number) => {
  return {
    type: actions.SET_ROWS_PER_PAGE_EMPLOYEES,
    payload: rows,
  };
};

// GET_EMPLOYEES
export const pendingGetEmployees = (): actions.GetEmployeesPending => {
  return {
    type: actions.GET_EMPLOYEES_PENDING,
  };
};

export const resolvedGetEmployees = (
  employees: IPaginationResponse<IEmployee>
): actions.GetEmployeesResolved => {
  return {
    type: actions.GET_EMPLOYEES_RESOLVED,
    payload: employees,
  };
};

export const rejectedGetEmployees = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetEmployeesRejected => {
  return {
    type: actions.GET_EMPLOYEES_REJECTED,
    payload: errors,
  };
};

//GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE
export const pendingGetEmployeesForAddGroupAssignEmployee = (): actions.GetEmployeesForAddGroupAssignEmployeePending => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_PENDING,
  };
};

export const resolvedGetEmployeesForAddGroupAssignEmployee = (
  employees: IPaginationResponse<IEmployee>
): actions.GetEmployeesForAddGroupAssignEmployeeResolved => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_RESOLVED,
    payload: employees,
  };
};

export const rejectedGetEmployeesForAddGroupAssignEmployee = (
  errors: IFormError[]
): actions.GetEmployeesForAddGroupAssignEmployeeRejected => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

//GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE
export const pendingGetEmployeesForAddGroupAssignedEmployee = (): actions.GetEmployeesForAddGroupAssignedEmployeePending => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_PENDING,
  };
};

export const resolvedGetEmployeesForAddGroupAssignedEmployee = (
  employees: IPaginationResponse<IEmployee>
): actions.GetEmployeesForAddGroupAssignedEmployeeResolved => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_RESOLVED,
    payload: employees,
  };
};

export const rejectedGetEmployeesForAddGroupAssignedEmployee = (
  errors: IFormError[]
): actions.GetEmployeesForAddGroupAssignedEmployeeRejected => {
  return {
    type: actions.GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

// CREATE EMPLOYEE
export const handleCreateEmployee = (
  {
    email,
    firstName,
    lastName,
    jobTitle,
    workPhone,
    workPhoneUsage,
    alternatePhone,
    alternatePhoneUsage,
    textConsent,
    isActive,
    avatar,
    defaultGroupIds,
    managerId,
    departmentId,
    primaryLocationId,
    workPhoneExtension
  }: Partial<ICreateEmployee>,
  assignTo: string,
  callback: () => void
): actions.HandleCreateEmployee => {
  return {
    type: actions.HANDLE_CREATE_EMPLOYEE,
    payload: {
      employee: {
        email,
        firstName,
        lastName,
        jobTitle,
        workPhone,
        workPhoneUsage,
        alternatePhone,
        alternatePhoneUsage,
        textConsent,
        isActive,
        avatar,
        defaultGroupIds,
        managerId,
        departmentId,
        primaryLocationId,
        workPhoneExtension
      },
      assignTo,
      callback,
    },
  };
};

export const pendingCreateEmployee = (): actions.CreateEmployeePending => {
  return {
    type: actions.CREATE_EMPLOYEE_PENDING,
  };
};

export const resolvedCreateEmployee = (
  employee: IEmployee
): actions.CreateEmployeeResolved => {
  return {
    type: actions.CREATE_EMPLOYEE_RESOLVED,
    payload: employee,
  };
};

export const rejectedCreateEmployee = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.CreateEmployeeRejected => {
  return {
    type: actions.CREATE_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

/** DELETE_EMPLOYEE */
export const handleDeleteEmployee = (
  ids,
  callback?: () => void
): actions.HandleDeleteEmployee => {
  return {
    type: actions.HANDLE_DELETE_EMPLOYEE,
    payload: { ids, callback },
  };
};

export const pendingDeleteEmployee = (): actions.DeleteEmployeePending => {
  return {
    type: actions.DELETE_EMPLOYEE_PENDING,
  };
};

export const resolvedDeleteEmployee = (): actions.DeleteEmployeeResolved => {
  return {
    type: actions.DELETE_EMPLOYEE_RESOLVED,
  };
};

/** VIEW_EMPLOYEE */
export const handleViewEmployee = (
  id: number | string,isKarlProfile:boolean
): actions.HandleViewEmployee => {
  return {
    type: actions.HANDLE_VIEW_EMPLOYEE,
      payload: { id, isKarlProfile },
  };
};

export const pendingViewEmployee = (): actions.ViewEmployeePending => {
  return {
    type: actions.VIEW_EMPLOYEE_PENDING,
  };
};

export const resolvedViewEmployee = (
  employeeInfo: any
): actions.ViewEmployeeResolved => {
  return {
    type: actions.VIEW_EMPLOYEE_RESOLVED,
      payload: employeeInfo,
  };
};

export const rejectedViewEmployee = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewEmployeeRejected => {
  return {
    type: actions.VIEW_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

export const rejectedDeleteEmployee = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.DeleteEmployeeRejected => {
  return {
    type: actions.DELETE_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

/** UPDATE_EMPLOYEE */
export const handleUpdateEmployee = (
  employees: IEmployee[],
  callback: () => void
): actions.HandleUpdateEmployee => {
  return {
    type: actions.HANDLE_UPDATE_EMPLOYEE,
    payload: { employees, callback },
  };
};

export const pendingUpdateEmployee = (): actions.UpdateEmployeePending => {
  return {
    type: actions.UPDATE_EMPLOYEE_PENDING,
  };
};

export const resolvedUpdateEmployee = (employee: IEmployee): actions.UpdateEmployeeResolved => {
  return {
      type: actions.UPDATE_EMPLOYEE_RESOLVED,
      payload: employee
  };
};

export const rejectedUpdateEmployee = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateEmployeeRejected => {
  return {
    type: actions.UPDATE_EMPLOYEE_REJECTED,
    payload: errors,
  };
};

export const setEmployeePasswordError = (
  error: string
): actions.SetEmployeePasswordError => {
  return {
    type: actions.SET_EMPLOYEE_PASSWORD_ERROR,
    payload: error,
  };
};

export const setSortEmployees = (sort: string): actions.SetSortEmployees => {
  return {
    type: SET_SORT_EMPLOYEES,
    payload: sort,
  };
};

/** GET EMPLOYEE TASKS */
export const handleGetEmployeeTasks = (
  employeeId: number,
  isUserMaster?: boolean
): actions.HandleGetEmployeeTasks => {
  
  return {
    type: actions.HANDLE_GET_EMPLOYEE_TASKS,
    payload: {employeeId, isUserMaster},
  };
};

export const pendingGetEmployeeTasks = (): actions.GetEmployeeTasksPending => {
  return {
    type: actions.GET_EMPLOYEE_TASKS_PENDING,
  };
};

export const resolvedGetEmployeeTasks = (
  tasks: ITasks
): actions.GetEmployeeTasksResolved => {
  return {
    type: actions.GET_EMPLOYEE_TASKS_RESOLVED,
    payload: tasks,
  };
};

export const rejectedGetEmployeeTasks = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetEmployeeTasksRejected => {
  return {
    type: actions.GET_EMPLOYEE_TASKS_REJECTED,
    payload: errors,
  };
};

/** GET EMPLOYEE S ASSIGNED TO PROSPECT */
export const handleGetEmployeesAssignedProspect = (
  prospectId: number,
  assignedPagination: IPagination,
  unassignedPagination: IPagination
): actions.HandleGetEmployeesAssignedProspect => {
  return {
    type: actions.HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT,
    payload: { prospectId, assignedPagination, unassignedPagination },
  };
};

export const pendingGetEmployeesAssignedProspect = (): actions.EmployeesAssignedProspectPending => {
  return {
    type: actions.EMPLOYEES_ASSIGNED_PROSPECT_PENDING,
  };
};

export const resolvedGetEmployeesAssignedProspect = (
  employees: IEmployeesProspect
): actions.EmployeesAssignedProspectResolved => {
  return {
    type: actions.EMPLOYEES_ASSIGNED_PROSPECT_RESOLVED,
    payload: employees,
  };
};

export const rejectedGetEmployeesAssignedProspect = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.EmployeesAssignedProspectRejected => {
  return {
    type: actions.EMPLOYEES_ASSIGNED_PROSPECT_REJECTED,
    payload: errors,
  };
};

/** ACTIVATE EMPLOYEE */
export const handleEmployeeActivate = (
    { id, isActive }
): actions.HandleEmployeeActivate => {
    return {
        type: actions.HANDLE_EMPLOYEE_ACTIVATE,
        payload: { id, isActive },
    };
};

export const pendingEmployeeActivate = (): actions.EmployeeActivatePending => {
    return {
        type: actions.EMPLOYEE_ACTIVATE_PENDING,
    };
};

export const resolvedEmployeeActivate = (): actions.EmployeeActivateResolved => {
    return {
        type: actions.EMPLOYEE_ACTIVATE_RESOLVED,
    };
};

export const rejectedEmployeeActivate = (errors): actions.EmployeeActivateRejected => {
    return {
        type: actions.EMPLOYEE_ACTIVATE_REJECTED,
        payload: errors,
    };
};


/** SEND INVITE */
export const handleSendInvite = (
    { id }
): actions.HandleSendInvite => {
    return {
        type: actions.HANDLE_SEND_INVITE,
        payload: { id },
    };
};

export const pendingSendInvite = (): actions.SendInvitePending => {
    return {
        type: actions.SEND_INVITE_PENDING,
    };
};

export const resolvedSendInvite = (): actions.SendInviteResolved => {
    return {
        type: actions.SEND_INVITE_RESOLVED,
    };
};

export const rejectedSendInvite = (errors): actions.SendInviteRejected => {
    return {
        type: actions.SEND_INVITE_REJECTED,
        payload: errors,
    };
};

export const handleSaveEmployeePermissions = (id,permissions,callback): actions.HandleSaveEmployeePermissions => {
    return {
        type: actions.HANDLE_SAVE_EMPLOYEE_PERMISSIONS,
        payload: { id, permissions, callback}
    };
};

export const handleGeteEmployeeManagers = (callback): actions.HandleGetEmployeeManagers => {
    return {
        type: actions.HANDLE_GET_EMPLOYEE_MANAGERS,
        payload: callback
    };
};

/***Get sales and Finance Employee ***/


export const handleGetEmployeeSaleFinance = ({isActive,role,callback } ): actions.HandleGetEmployeeSaleFinance => {
    return {
        type: actions.HANDLE_GET_EMPLOYEES_SALE_FINANCE,
        payload: {
            isActive,
             role,
             callback,
        }
    };
};

// GET_EMPLOYEES
export const pendingGetEmployeesFinance = (): actions.GetEmployeesFinancePending => {
  return {
    type: actions.GET_EMPLOYEES_FINANCE_PENDING,
  };
};

export const resolvedGetEmployeesFinance = (
  employees: IPaginationResponse<IEmployee>
): actions.GetEmployeesFinanceResolved => {
  return {
    type: actions.GET_EMPLOYEES_FINANCE_RESOLVED,
    payload: employees,
  };
};

export const rejectedGetEmployeesFinance = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetEmployeesFinanceRejected => {
  return {
    type: actions.GET_EMPLOYEES_FINANCE_REJECTED,
    payload: errors,
  };
};

/*** Weekly Hours ***/
export const handleSaveEmployeeWeeklyHours = ({id,weeklyHours,callback}): actions.HandleSaveEmployeeWeeklyHours => {
    return {
        type: actions.HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS,
        payload: {id, weeklyHours, callback}
    };
};

export const handleViewEmployeeWeeklyHours = (
  id: number | string,isKarlProfile:boolean
): actions.HandleViewEmployeeWeeklyHours => {
  return {
    type: actions.HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS,
      payload: { id, isKarlProfile },
  };
};

export const pendingViewEmployeeWeeklyHours = (): actions.ViewEmployeeWeeklyHoursPending => {
  return {
    type: actions.VIEW_EMPLOYEE_WEEKLY_HOURS_PENDING,
  };
};

export const resolvedViewEmployeeWeeklyHours = (
  employeeWeeklyHours: any
): actions.ViewEmployeeWeeklyHoursResolved => {
  return {
    type: actions.VIEW_EMPLOYEE_WEEKLY_HOURS_RESOLVED,
      payload: employeeWeeklyHours,
  };
};

export const rejectedViewEmployeeWeeklyHours = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewEmployeeWeeklyHoursRejected => {
  return {
    type: actions.VIEW_EMPLOYEE_WEEKLY_HOURS_REJECTED,
    payload: errors,
  };
};

/* Monthly calendar */
export const HandleEmployeeTimeOff = ({id,method,data,callback}): actions.HandleEmployeeTimeOff => {
  return {
      type: actions.HANDLE_EMPLOYEE_TIME_OFF,
      payload: {id, method, data, callback}
  };
};

export const HandleEmployeeTmpAvail = ({id,method,data,callback}): actions.HandleEmployeeTmpAvail => {
  return {
      type: actions.HANDLE_EMPLOYEE_TMP_AVAIL,
      payload: {id, method, data, callback}
  };
};

export const HandleEmployeeDeleteEvents = ({id,eventId,callback}): actions.HandleEmployeeDeleteEvents => {
  return {
      type: actions.HANDLE_EMPLOYEE_DELETE_EVENTS,
      payload: {id, eventId, callback}
  };
};

export const HandleEmployeeGetMonthCal = ({
  data,
  callback
}): actions.HandleEmployeeGetMonthCal => {
  return {
      type: actions.HANDLE_EMPLOYEE_GET_MONTH_CAL,
      payload: { data, callback }
  };
};