import {
  IProspects,
  IProspect,
  ICreateProspect,
  IProspectsFilterSettings,
} from "types/prospectTypes";
import {
    ITask,
    //ITasks,
    ITaskToUpdate,
    ITaskToDelete
} from "types/taskTypes";

import { IPagination } from "types/paginationTypes";
import { CheckIfProspectExistState } from "constants/constants";

export const SET_SORT_PROSPECTS = "SET_SORT_PROSPECTS";

export interface SetSortProspects {
  type: typeof SET_SORT_PROSPECTS;
  payload: string;
}

export const HANDLE_GET_PROSPECTS = "HANDLE_GET_PROSPECTS";

export interface HandleGetProspects {
  type: typeof HANDLE_GET_PROSPECTS;
  payload: { pagination: IPagination; type: number };
}

export const GET_PROSPECTS_PENDING = "GET_PROSPECTS_PENDING";

export interface GetProspectsPending {
  type: typeof GET_PROSPECTS_PENDING;
}

export const GET_PROSPECTS_RESOLVED = "GET_PROSPECTS_RESOLVED";

export interface GetProspectsResolved {
  type: typeof GET_PROSPECTS_RESOLVED;
  payload: IProspects;
}

export const GET_PROSPECTS_REJECTED = "GET_PROSPECTS_REJECTED";

export interface GetProspectsRejected {
  type: typeof GET_PROSPECTS_REJECTED;
  payload: object[];
}

export const HANDLE_CREATE_PROSPECT = "HANDLE_CREATE_PROSPECT";

export interface HandleCreateProspect {
  type: typeof HANDLE_CREATE_PROSPECT;
  payload: { prospect: ICreateProspect; callback?: Function };
}

export const CREATE_PROSPECT_PENDING = "CREATE_PROSPECT_PENDING";

export interface CreateProspectPending {
  type: typeof CREATE_PROSPECT_PENDING;
}
export const CREATE_PROSPECT_RESOLVED = "CREATE_PROSPECT_RESOLVED";

export interface CreateProspectResolved {
  type: typeof CREATE_PROSPECT_RESOLVED;
  payload: IProspect;
}

export const HANDLE_CHECK_IF_PROSPECT_EXIST = "HANDLE_CHECK_IF_PROSPECT_EXIST";

export interface HandleCheckIfProspectExist {
  type: typeof HANDLE_CHECK_IF_PROSPECT_EXIST;
  payload: string;
}

export const CHECK_IF_PROSPECT_EXIST = "CHECK_IF_PROSPECT_EXIST";

export interface CheckIfProspectExist {
  type: typeof CHECK_IF_PROSPECT_EXIST;
  payload: keyof typeof CheckIfProspectExistState;
}
// export const CHECK_IF_PROSPECT_EXIST_PENDING =
//   "CHECK_IF_PROSPECT_EXIST_PENDING";
//
// export interface CheckIfProspectExistPending {
//   type: typeof CHECK_IF_PROSPECT_EXIST_PENDING;
// }
// export const CHECK_IF_PROSPECT_EXIST_RESOLVED =
//   "CHECK_IF_PROSPECT_EXIST_RESOLVED";
//
// export interface CheckIfProspectExistResolved {
//   type: typeof CHECK_IF_PROSPECT_EXIST_RESOLVED;
// }

export const SET_ROWS_PER_PAGE_PROSPECTS = "SET_ROWS_PER_PAGE_PROSPECTS";

export interface SetRowsPerPageProspects {
  type: typeof SET_ROWS_PER_PAGE_PROSPECTS;
  payload: number;
}

/** VIEW_Prospect */
export const HANDLE_VIEW_PROSPECT = "HANDLE_VIEW_PROSPECT";

export interface HandleViewProspect {
  type: typeof HANDLE_VIEW_PROSPECT;
  payload: string | number;
}

export const VIEW_PROSPECT_PENDING = "VIEW_PROSPECT_PENDING";

export interface ViewProspectPending {
  type: typeof VIEW_PROSPECT_PENDING;
}

export const VIEW_PROSPECT_RESOLVED = "VIEW_PROSPECT_RESOLVED";

export interface ViewProspectResolved {
  type: typeof VIEW_PROSPECT_RESOLVED;
  payload: IProspect;
}

export const VIEW_PROSPECT_REJECTED = "VIEW_PROSPECT_REJECTED";

export interface ViewProspectRejected {
  type: typeof VIEW_PROSPECT_REJECTED;
  payload: object;
}

export const CREATE_PROSPECT_REJECTED = "CREATE_PROSPECT_REJECTED";

export interface CreateProspectRejected {
  type: typeof CREATE_PROSPECT_REJECTED;
  payload: object[];
}

export const HANDLE_UPDATE_PROSPECT = "HANDLE_UPDATE_PROSPECT";

export interface HandleUpdateProspect {
  type: typeof HANDLE_UPDATE_PROSPECT;
  payload: { prospects: IProspect[]; callback?: Function };
}

export const UPDATE_PROSPECT_PENDING = "UPDATE_PROSPECT_PENDING";

export interface UpdateProspectPending {
  type: typeof UPDATE_PROSPECT_PENDING;
}

export const UPDATE_PROSPECT_RESOLVED = "UPDATE_PROSPECT_RESOLVED";

export interface UpdateProspectResolved {
  type: typeof UPDATE_PROSPECT_RESOLVED;
}

export const UPDATE_PROSPECT_REJECTED = "UPDATE_PROSPECT_REJECTED";

export interface UpdateProspectRejected {
  type: typeof UPDATE_PROSPECT_REJECTED;
  payload: object;
}

/** DELETE_Prospect */
export const HANDLE_DELETE_PROSPECT = "HANDLE_DELETE_PROSPECT";

export interface HandleDeleteProspect {
  type: typeof HANDLE_DELETE_PROSPECT;
  payload: { ids: string[] | number[]; callback?: Function };
}

export const DELETE_PROSPECT_PENDING = "DELETE_PROSPECT_PENDING";

export interface DeleteProspectPending {
  type: typeof DELETE_PROSPECT_PENDING;
}

export const DELETE_PROSPECT_RESOLVED = "DELETE_PROSPECT_RESOLVED";

export interface DeleteProspectResolved {
  type: typeof DELETE_PROSPECT_RESOLVED;
}

export const DELETE_PROSPECT_REJECTED = "DELETE_PROSPECT_REJECTED";

export interface DeleteProspectRejected {
  type: typeof DELETE_PROSPECT_REJECTED;
  payload: object;
}

/** ASSIGN EMPLOYEE*/

export const HANDLE_PROSPECT_ASSIGN_EMPLOYEE =
  "HANDLE_PROSPECT_ASSIGN_EMPLOYEE";

export interface HandleProspectAssignEmployee {
  type: typeof HANDLE_PROSPECT_ASSIGN_EMPLOYEE;
  payload: { prospectId: number; employeeId: number; callback?: Function };
}

export const PROSPECT_ASSIGN_EMPLOYEE_PENDING =
  "PROSPECT_ASSIGN_EMPLOYEE_PENDING";

export interface ProspectAssignEmployeePending {
  type: typeof PROSPECT_ASSIGN_EMPLOYEE_PENDING;
}

export const PROSPECT_ASSIGN_EMPLOYEE_RESOLVED =
  "PROSPECT_ASSIGN_EMPLOYEE_RESOLVED";

export interface ProspectAssignEmployeeResolved {
  type: typeof PROSPECT_ASSIGN_EMPLOYEE_RESOLVED;
}

export const PROSPECT_ASSIGN_EMPLOYEE_REJECTED =
  "PROSPECT_ASSIGN_EMPLOYEE_REJECTED";

export interface ProspectAssignEmployeeRejected {
  type: typeof PROSPECT_ASSIGN_EMPLOYEE_REJECTED;
  payload: object;
}

/** UNASSIGN EMPLOYEE*/

export const HANDLE_PROSPECT_UNASSIGN_EMPLOYEE =
  "HANDLE_PROSPECT_UNASSIGN_EMPLOYEE";

export interface HandleProspectUnassignEmployee {
  type: typeof HANDLE_PROSPECT_UNASSIGN_EMPLOYEE;
  payload: { prospectId: number; employeeId: number; callback?: Function };
}

export const PROSPECT_UNASSIGN_EMPLOYEE_PENDING =
  "PROSPECT_UNASSIGN_EMPLOYEE_PENDING";

export interface ProspectUnassignEmployeePending {
  type: typeof PROSPECT_UNASSIGN_EMPLOYEE_PENDING;
}

export const PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED =
  "PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED";

export interface ProspectUnassignEmployeeResolved {
  type: typeof PROSPECT_UNASSIGN_EMPLOYEE_RESOLVED;
}

export const PROSPECT_UNASSIGN_EMPLOYEE_REJECTED =
  "PROSPECT_UNASSIGN_EMPLOYEE_REJECTED";

export interface ProspectUnassignEmployeeRejected {
  type: typeof PROSPECT_UNASSIGN_EMPLOYEE_REJECTED;
  payload: object;
}

/** ADD TASK*/

export const HANDLE_ADD_TASK = "HANDLE_ADD_TASK";

export interface HandleAddTask {
  type: typeof HANDLE_ADD_TASK;
  payload: { prospectId: number; task: ITask; callback?: () => void };
}

export const ADD_TASK_PENDING = "ADD_TASK_PENDING";

export interface AddTaskPending {
  type: typeof ADD_TASK_PENDING;
}

export const ADD_TASK_RESOLVED = "ADD_TASK_RESOLVED";

export interface AddTaskResolved {
  type: typeof ADD_TASK_RESOLVED;
}

export const ADD_TASK_REJECTED = "ADD_TASK_REJECTED";

export interface AddTaskRejected {
  type: typeof ADD_TASK_REJECTED;
  payload: object;
}

/** REMOVE TASK*/

export const HANDLE_REMOVE_TASK = "HANDLE_REMOVE_TASK";

/** Remove this task*/
export interface HandleRemoveTask {
  type: typeof HANDLE_REMOVE_TASK;
  payload: {
    tasksToRemove: ITaskToDelete[];
    callback: Function;
    isFromScheduler?: boolean;
  };
}

export const REMOVE_TASK_PENDING = "REMOVE_TASK_PENDING";

export interface RemoveTaskPending {
  type: typeof REMOVE_TASK_PENDING;
}

export const REMOVE_TASK_RESOLVED = "REMOVE_TASK_RESOLVED";

export interface RemoveTaskResolved {
  type: typeof REMOVE_TASK_RESOLVED;
}

export const REMOVE_TASK_REJECTED = "REMOVE_TASK_REJECTED";

export interface RemoveTaskRejected {
  type: typeof REMOVE_TASK_REJECTED;
  payload: object;
}

/** GET LIST OF TASK */

//export const HANDLE_GET_TASKS = "HANDLE_GET_TASKS";

//export interface HandleGetTasks {
//  type: typeof HANDLE_GET_TASKS;
//  /** pagination and IProspect id*/
//  payload: { request: IPagination; prospectId: number };
//}

//export const GET_TASKS_PENDING = "GET_TASKS_PENDING";

//export interface GetTasksPending {
//  type: typeof GET_TASKS_PENDING;
//}

//export const GET_TASKS_RESOLVED = "GET_TASKS_RESOLVED";

//export interface GetTasksResolved {
//  type: typeof GET_TASKS_RESOLVED;
//  payload: ITasks;
//}

//export const GET_TASKS_REJECTED = "GET_TASKS_REJECTED";

//export interface GetTasksRejected {
//  type: typeof GET_TASKS_REJECTED;
//  payload: object;
//}

/** UPDATE TASK */

export const HANDLE_UPDATE_TASK = "HANDLE_UPDATE_TASK";

export interface HandleUpdateTask {
  type: typeof HANDLE_UPDATE_TASK;
  /** pagination and IProspect id*/
  payload: { tasks: ITaskToUpdate[]; callback: Function };
}

export const UPDATE_TASK_PENDING = "UPDATE_TASK_PENDING";

export interface UpdateTaskPending {
  type: typeof UPDATE_TASK_PENDING;
}

export const UPDATE_TASK_RESOLVED = "UPDATE_TASK_RESOLVED";

export interface UpdateTaskResolved {
  type: typeof UPDATE_TASK_RESOLVED;
  payload: ITask;
}

export const UPDATE_TASK_REJECTED = "UPDATE_TASK_REJECTED";

export interface UpdateTaskRejected {
  type: typeof UPDATE_TASK_REJECTED;
  payload: object;
}

// FILTERS
export const CLEAN_PROSPECT_FILTERS = "CLEAN_PROSPECT_FILTERS";
export interface CleanProspectFilters {
  type: typeof CLEAN_PROSPECT_FILTERS;
}

export const SET_PROSPECT_FILTERS = "SET_PROSPECT_FILTERS";
export interface SetProspectFilters {
  type: typeof SET_PROSPECT_FILTERS;
  payload: IProspectsFilterSettings;
}

export const HANDLE_DOCUMENT_READER = "HANDLE_DOCUMENT_READER";
export interface HandleDocumentReader {
  type: typeof HANDLE_DOCUMENT_READER;
  payload: any;
}

export const DOCUMENT_READER_PENDING = "DOCUMENT_READER_PENDING";
export interface DocumentReaderPending {
  type: typeof DOCUMENT_READER_PENDING;
}

export const DOCUMENT_READER_RESOLVED = "DOCUMENT_READER_RESOLVED";
export interface DocumentReaderResolved {
  type: typeof DOCUMENT_READER_RESOLVED;
  payload: string;
}

export const DOCUMENT_READER_REJECTED = "DOCUMENT_READER_REJECTED";
export interface DocumentReaderRejected {
  type: typeof DOCUMENT_READER_REJECTED;
  payload: string | object;
}

export const CLEAR_DOCUMENT_AND_ERROR_READER = "CLEAR_DOCUMENT_AND_ERROR_READER";
export interface ClearDocumentAndErrorReaderDocument {
  type: typeof CLEAR_DOCUMENT_AND_ERROR_READER;
}


export type ProspectsActions =
  | HandleCheckIfProspectExist
  | CheckIfProspectExist
  | HandleGetProspects
  | GetProspectsPending
  | GetProspectsResolved
  | GetProspectsRejected
  | HandleCreateProspect
  | CreateProspectPending
  | CreateProspectResolved
  | CreateProspectRejected
  | SetRowsPerPageProspects
  | HandleUpdateProspect
  | UpdateProspectPending
  | UpdateProspectResolved
  | UpdateProspectRejected
  | HandleViewProspect
  | ViewProspectPending
  | ViewProspectResolved
  | ViewProspectRejected
  | HandleDeleteProspect
  | DeleteProspectPending
  | DeleteProspectResolved
  | DeleteProspectRejected
  | SetSortProspects
  | HandleProspectAssignEmployee
  | ProspectAssignEmployeePending
  | ProspectAssignEmployeeResolved
  | ProspectAssignEmployeeRejected
  | HandleProspectUnassignEmployee
  | ProspectUnassignEmployeePending
  | ProspectUnassignEmployeeResolved
  | ProspectUnassignEmployeeRejected
  | HandleAddTask
  | AddTaskPending
  | AddTaskResolved
  | AddTaskRejected
  | HandleRemoveTask
  | RemoveTaskPending
  | RemoveTaskResolved
  | RemoveTaskRejected
  //| HandleGetTasks
  //| GetTasksPending
  //| GetTasksResolved
  //| GetTasksRejected
  | HandleUpdateTask
  | UpdateTaskPending
  | UpdateTaskResolved
  | UpdateTaskRejected
  | CleanProspectFilters
  | SetProspectFilters
  | HandleDocumentReader
  | DocumentReaderPending
  | DocumentReaderRejected
  | DocumentReaderResolved
| ClearDocumentAndErrorReaderDocument;
