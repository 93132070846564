import {
  ComboBox,
  DefaultButton,
  IconButton,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Stack } from "@fluentui/react/lib/Stack";
import ListItem from "components/ListItem/ListItem";
import { CustomFontText } from "components/customFontText/CustomFontText";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import AddInventoryDropzone from "components/dialogs/addInventoryDialog/AddInventoryDropzone";
import AddInventoryVideoDropzone from "components/dialogs/addInventoryDialog/AddInventoryVideoDropzone";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import WysiwygEditor from "components/dialogs/addInventoryDialog/WysiwygEditor";
import {
  PRICE_PREFIX,
  engineAndDriveItemList,
  financialInformationItemList,
  inventoryTypes,
} from "constants/constants";
import dropDownsValues from "constants/inventoryDropdownsValues";
import {
  cancelIcon,
  contentStyles as content,
  dropdownStyles,
  iconButtonStyles,
} from "constants/styles";
import { ContentState, EditorState } from "draft-js";
import { useFormik } from "formik";
import htmlToDraft from "html-to-draftjs";
import { styles } from "pages/accountSettingsPage/styles";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  handleCreateInventory,
  handleGetFromVin,
} from "store/inventory/inventory.action";
import { getLocationsForDropdown } from "store/locations/locations.api";
import { handleGetMakes, handleGetModels } from "store/shared/shared.action";
import { ICreateInventory } from "types/inventoryTypes";
import { IPickerItem } from "types/sharedTypes";
import * as Yup from "yup";
import { ConfirmDialog } from "../confirmDialog/ConfirmDialog";
import BodyAndColor from "./secondaryDialogs/BodyAndColor";
import EngineAndDrive from "./secondaryDialogs/EngineAndDrive";
import FinancialInformation from "./secondaryDialogs/FinancialInformation";
import { NewOptionsDialog } from "./secondaryDialogs/NewOptionsDialog";

import CurrencyInput from 'react-currency-input-field';

import { stringFromCurrencyFormat } from "utils/helperFunctions";

const contentStyles = content();
// OPTIONS FOR DROPDOWNS
// const statusValues = inventoryTypes.statuses.map((item) => {
//   return { key: item.id, text: item.name };
// });

const newStatusValues = inventoryTypes.statuses.map((item) => {
  return { key: item.name, text: item.name };
});

const conditionValues = dropDownsValues.conditionValues.map((item) => {
  return { key: item, text: item };
});

// const makeValues = ["Nissan", "BMW", "Audi", "Ford", "Yamaha", "DODGE"].map(
//   (item) => {
//     return { key: item, text: item };
//   }
// );

// const modelValues = ["350Z", "360Z", "370Z", "Ram"].map((item) => {
//   return { key: item, text: item };
// });

const yearValues = dropDownsValues.yearValues.map((item) => {
  return { key: item, text: item };
});

const historyValues = ["No Accidents", "Single Owner"].map((item) => {
  return { key: item, text: item };
});

export enum FormStep {
  Main,
  Financial,
  Engine,
  Body,
  Options,
}

// interface IMake {
//   key: number;
//   text: string;
// }

interface IProps {
  onDismiss: () => void;
}

export const AddInventoryDialog: FunctionComponent<IProps> = ({
  // isOpen,
  onDismiss,
}) => {
  // const comboBoxRef = React.useRef<IComboBox>(null);
  const [locations, setLocations] = useState([]);
  const [modelFromVin, setModelFromVin] = useState("");
  const showFooter = true;
  const [formStep, setFormStep] = useState(FormStep.Main);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    optionsAudio: [],
    optionsPower: [],
    optionsInterior: [],
    optionsExterior: [],
    //optionsSafety: [],
    optionsSafteyFeatures: [],
    optionsLuxury: [],
    optionsOther: [],
  });
  const [isConfirmVinModalOn, setIsConfirmVinModalOn] = useState(false);
  const [isIndeterminateInvoice, setIsIndeterminateInvoice] = useState(true);
  const [isIndeterminateLeasing, setIsIndeterminateLeasing] = useState(true);
  const [inventory, setInventory] = useState({
    body: "",
    exteriorColor: "",
    interiorColor: "",
    doors: undefined,
  });
  const [filesToSend, setFilesToSend] = useState([]);
  const [videoFilesToSend, setVideoFilesToSend] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState([]);
  const [htmlToSend, setHtmlToSend] = useState("");
  const [secondaryDialogTitle, setSecondaryDialogTitle] = useState("");
  const [secondaryDialogContent, setSecondaryDialogContent] = useState(null);
  const [vinData, setVinData] = useState(null);
  const [wysiwygEditorState, setWysiwygEditorState] = useState(
    EditorState.createEmpty()
  );

  const [makeValues, setMakeValues] = useState([]);
  const [modelValues, setModelValues] = useState([]);

  const [showModels, setShowModels] = useState(false);

  const loadingMakes = useSelector(
    (state: AppState) => state.shared.loadingMakes
  );
  const loadingModels = useSelector(
    (state: AppState) => state.shared.loadingModels
  );
  const models: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.models
  );

  const makes: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.makes
  );

  const loading = useSelector((state: AppState) => state.inventory.loading);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      // setStatus: null,
      setStatus: "Available",
      condition: ["Used"],
      // make: "Nissan",
      make: { key: 0, text: "unknown" },
      model: { key: 0, text: "" },
      trim: "trim",
      price: "",
      //year: null,
      year: 2000,
      mileage: "",
      vin: "",
      location: "",
      vehicleHistory: ["No Accidents"],
      isInvoice: false, // bool
      isLeasing: false, // bool
      msrp: "", // string
      bookValue: "", // string
      driveType: "", //number
      fuelType: "",
      // fuelEconomy: "",
      engineCylinders: "",
      engineDescription: "",
      transmissionDescription: "",
    },
    validationSchema: Yup.object({
      setStatus: Yup.string().required("Required"),
      condition: Yup.array().of(Yup.string()).required("Required"),
      make: Yup.object().required("Required"),
      // model: Yup.object().required("Required"),
      model: Yup.object().shape({
        key: Yup.number().required("Required").min(1),
        text: Yup.string().required("Required"),
      }),
      trim: Yup.string(),
      price: Yup.string().required("Required"),
      year: Yup.number().required("Required"),
      mileage: Yup.string().max(7).required("Required"),
      vin: Yup.string().matches(
        /^[A-Z0-9]{17}$/,
        "Must Contain at 17 Characters, Uppercase letters and numbers"
      ),
      location: Yup.string().required("Required"),
      vehicleHistory: Yup.array().of(Yup.string()).required("Required"),
      isInvoice: Yup.boolean().required("Required"),
      isLeasing: Yup.boolean().required("Required"),
      msrp: Yup.string().required("Required"),
      bookValue: Yup.string().required("Required"),
      driveType: Yup.string(), //number
      fuelType: Yup.string(),
      // fuelEconomy: Yup.string().required("Required"),
      engineCylinders: Yup.string(),
      engineDescription: Yup.string(),
      transmissionDescription: Yup.string(),
    }),
    onSubmit: (values) => {
      const inventoryData: ICreateInventory = {
        vehCondition: values.condition,
        vehHistory: values.vehicleHistory,
        status: values.setStatus,
        vin: values.vin,
        year: values.year,
        make: values.make.text,
        model: values.model.text,
        body: inventory.body,
        trim: values.trim,
        doors: parseFloat(inventory.doors),
        exteriorColor: inventory.exteriorColor,
        extColorGeneric: inventory.exteriorColor,
        intColorGeneric: inventory.interiorColor,
        miles: parseFloat(values.mileage),
        driveType: values.driveType,
        fuelType: values.fuelType,
        fuelEconomy: "1",
        engineCylinders: parseFloat(values.engineCylinders),
        engineDescription: values.engineDescription,
        transmissionDescription: values.transmissionDescription,
        sellingPrice: parseInt(stringFromCurrencyFormat(values.price)),
        //photoUrls: photos,
        photoUrls: filesToSend,
        videoUrl: videoFilesToSend,
        description: htmlToSend,
        isInvoice: values.isInvoice,
        isLeasing: values.isLeasing,
        msrp: parseInt(stringFromCurrencyFormat(values.msrp)),
        bookValue: parseInt(stringFromCurrencyFormat(values.bookValue)),
        optionsAudio: selectedOptions.optionsAudio,
        optionsPower: selectedOptions.optionsPower,
        optionsInterior: selectedOptions.optionsInterior,
        optionsExterior: selectedOptions.optionsExterior,
        optionsSafteyFeatures: selectedOptions.optionsSafteyFeatures,
        optionsLuxury: selectedOptions.optionsLuxury,
        optionsOther: selectedOptions.optionsOther,
        locationId: values.location,
        // default values ??
        stock: "STOCK_DEFAULT",
        drivetrain: "DRIVER_TRAIN_DEFAULT",
        category: "CATEGORY_DEFAULT",
        isFavourite: false,
        vehTypeName: "TYPE_DEFAULT",
        type: 0,
      };

      dispatch<any>(handleCreateInventory(inventoryData, onDismiss));
    },
  });

  useEffect(() => {
    if (makes.length > 0) {
      const values = makes.map((item) => {
        return { key: item.id, text: item.label };
      });
      setMakeValues(values);
    }
  }, [makes]);

  useEffect(() => {
    if (models.length > 0) {
      const values = models.map((item) => {
        return { key: item.id, text: item.label };
      });
      setModelValues(values);

      if (modelFromVin !== "") {
        console.log(modelFromVin);

        const vinModel = values.find((item: any) => {
          return item.text === modelFromVin;
        }) as any;

        if (Boolean(vinModel)) {

          formik.setFieldValue("model", {
            key: vinModel.key,
            text: vinModel.text,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  useEffect(() => {
    dispatch<any>(handleGetMakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.make.text !== "unknown") {
      setShowModels(true);

      formik.setFieldValue("model", { key: 0, text: "" });
      dispatch<any>(handleGetModels(formik.values.make?.key?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.make]);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data } = await getLocationsForDropdown();
      const options = data?.results.map((item) => {
        return { key: item.id, text: item.legalName };
      });
      setLocations(options);
    };
    fetchLocations().then();
  }, []);

  useEffect(() => {
    switch (formStep) {
      case FormStep.Main:
        // setShowFooter(true);
        setSecondaryDialogTitle("");
        setSecondaryDialogContent(null);
        break;
      case FormStep.Financial:
        // setShowFooter(false);
        setSecondaryDialogTitle("Financial information");
        setSecondaryDialogContent(
          <FinancialInformation
            isIndeterminateInvoice={isIndeterminateInvoice}
            isIndeterminateLeasing={isIndeterminateLeasing}
            setIsIndeterminateInvoice={setIsIndeterminateInvoice}
            setIsIndeterminateLeasing={setIsIndeterminateLeasing}
            formik={formik}
            inventory={inventory}
            setInventory={setInventory}
          />
        );
        break;
      case FormStep.Engine:
        //setShowFooter(false);
        setSecondaryDialogTitle("Engine and drive");
        setSecondaryDialogContent(
          <EngineAndDrive
            formik={formik}
            inventory={inventory}
            setInventory={setInventory}
          />
        );
        break;
      case FormStep.Body:
        //setShowFooter(false);
        setSecondaryDialogTitle("Body and color");
        setSecondaryDialogContent(
          <BodyAndColor inventory={inventory} setInventory={setInventory} />
        );

        break;
      case FormStep.Options:
        setIsOptionsOpen(true);
        break;
      default:
        console.log(`default switch`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep, inventory, formik.values, formik.errors]);

  const onOptionsDialogClose = () => {
    setIsOptionsOpen(false);
    setSecondaryDialogTitle("");
    setSecondaryDialogContent(null);
  };

  const handleOptionsClick = () => {
    setIsOptionsOpen(true);
  };

  const handleAddInventory = () => {
    formik.handleSubmit();
  };

  const isError = (arr: any[]): boolean => {
    console.log(arr);
    return !!arr.find((a: any) => a.id === "error" && a.value);
  };

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
      //   false
    );
  };
  const handleMakeChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        make: item,
      },
      true
      //   false
    );
  };

  const handleModelChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        model: item,
      },
      true
      //   false
    );
  };

  const handleDropdownMultiChange = (e, item) => {
    const id = e.target.id;
    let newValues = [...formik.values[id]];

    if (formik.values[id].includes(item.text)) {
      newValues = newValues.filter((element) => element !== item.text);
    } else {
      newValues.push(item.text);
    }

    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: newValues,
      },
      true
    );
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleOptionsChange = (e: any, category) => {
    let newOptions = [...selectedOptions[category]];

    if (newOptions.includes(e)) {
      newOptions = newOptions.filter((item) => item !== e);
    } else {
      newOptions.push(e);
    }

    setSelectedOptions((s) => {
      return { ...s, [category]: newOptions };
    });
  };

  const getOptions = (options) => {
    setSelectedOptions((o) => {
      return { ...o, ...options };
    });
  };
  const calcFilledFields = (array: any[]): number => {
    let count = 0;
    if (array.length > 0) {
      array.forEach((item) => {
        if (Array.isArray(formik.values[item])) {
          if (formik.values[item].length > 0) {
            count += 1;
          }
        } else {
          if (formik.values[item]) {
            count += 1;
          }
        }
      });
    }
    return count;
  };

  const handleClear = () => {

    formik.resetForm();
    setInventory({
      body: "",
      exteriorColor: "",
      interiorColor: "",
      doors: undefined,
    });
    setIsIndeterminateInvoice(true);
    setIsIndeterminateLeasing(true);
    setFilesToSend([]);
    setVideoFilesToSend([]);
    setPhotos([]);
    setVideo([]);
    setHtmlToSend("");
    setSelectedOptions({
      optionsAudio: [],
      optionsPower: [],
      optionsInterior: [],
      optionsExterior: [],
      //optionsSafety: [],
      optionsSafteyFeatures: [],
      optionsLuxury: [],
      optionsOther: [],
    });

    setWysiwygEditorState(EditorState.createEmpty());
  };

  const handleCheckVin = () => {
    // console.log(formik.values.vin);
    dispatch<any>(
      handleGetFromVin({
        vin: formik.values.vin,
        callback: (dataFromVin) => {
          setVinData(dataFromVin);
          // setIsConfirmVinModalOn(true);
        },
      })
    );
  };

  useEffect(() => {
    vinData && handleUpdateDataFromVin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinData]);

  const handleUpdateDataFromVin = () => {
    // TODO
    let description = "The Vehicle is available with ";

    vinData.forEach((item: any) => {
      if (item.id === "make" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " Make ";
      } else if (item.id === "model" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " Model ";
      } else if (item.id === "year" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " Year ";
      } else if (item.id === "trim" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " Trim ";
      } else if (item.id === "type" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " Type ";
      } else if (item.id === "driveType" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " DriveType ";
      } else if (item.id === "fuelType" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " FuelType ";
      } else if (item.id === "engineCylinders" && (item.value && (item.value !== ""))) {
        description = description + ", " + item.value + " EngineCylinders ";
      }
    });

    const contentBlock = htmlToDraft(description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setWysiwygEditorState(editorState);
    }

    vinData.forEach((item: any) => {
      if (item.id === "make") {
        const selectedMake = makes.find((make) => {
          return make.label === item.value;
        });

        if (selectedMake) {
          dispatch<any>(handleGetModels(selectedMake.id.toString()));
        }

        formik.setFieldValue("make", {
          key: selectedMake.id,
          text: selectedMake.label,
        });
        return;
      }

      if (item.id === "model") {
        // const selectedModel = models.find((model) => {
        //   return model.label === item.value;
        // });



        setModelFromVin(item.value);

        // if (selectedModel) {
        //   formik.setFieldValue("model", {
        //     key: selectedModel.id,
        //     text: selectedModel.label,
        //   });
        // }

        return;
      }

      formik.setFieldValue(
        item.id,
        typeof formik.values[item.id] === "number" ? +item.value : item.value
      );
    });

    setIsConfirmVinModalOn(false);
  };

  const displayError = (arr: any[]): string => {
    const error = arr.find((a: any) => a.id === "error").value;
    const errorMessage = arr.find((a: any) => a.id === "additionalErrorMessage")
      .value;
    return `${error}\n \n ${errorMessage}`;
  };

  const changeToUppercase = (e: any) => {
    formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
  };

  const createConfirmDescription = (data: any[]) => {
    if (!data || data.length === 0) return;
    return data && data.length > 0 && !isError(data)
      ? "Update data according to data get from VIN?"
      : data && data.length > 0 && isError(data)
        ? displayError(data)
        : "";
  };

  const handleDismiss = () => {
    setIsConfirmVinModalOn(true);
  };

  const handlePriceChange = (e) => {
    formik.setValues(
      {
        ...formik.values,
        "price": e
      }
    );
  };

  return (
    <>
      <Modal
        //isOpen={isOpen}
        isOpen={true}
        // onDismiss={handleDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        {/*<Loader show={loading} />*/}
        <NewOptionsDialog
          actionButtonLabel="Add Inventory"
          loading={loading}
          handleOnActionClick={handleAddInventory}
          formik={formik}
          handleClear={handleClear}
          isOpen={isOptionsOpen}
          onDismiss={onOptionsDialogClose}
          getOptions={getOptions}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionsChange}
        />
        {/*<div>{JSON.stringify(formik.errors, null, 8)}</div>*/}

        <div className={contentStyles.header}>
          {formStep === FormStep.Main ? (
            <CustomFontText>Add New Inventory</CustomFontText>
          ) : (
            <GoBackToMainDialogBtn
              label={secondaryDialogTitle}
              onClick={() => setFormStep(FormStep.Main)}
            />
          )}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={handleDismiss}
          />
        </div>

        <div>
          <div className={`noScrollbar ${contentStyles.body}`}>
            <Stack>
              {formStep === FormStep.Main ? (
                <Stack tokens={styles.textFields}>
                  <Dropdown
                    id="setStatus"
                    label="Set Status"
                    required={true}
                    selectedKey={
                      formik.values.setStatus
                        ? formik.values.setStatus
                        : undefined
                    }
                    onChange={handleDropdownChange}
                    placeholder=""
                    // options={statusValues}
                    options={newStatusValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.setStatus && formik.errors.setStatus
                        ? formik.errors.setStatus.toString()
                        : null
                    }
                  />
                  <WithOptionalLabel>
                    <TextField
                      id="vin"
                      name="vin"
                      label="VIN"
                      value={formik.values.vin}
                      onChange={changeToUppercase}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.vin &&
                        formik.errors.vin &&
                        formik.errors.vin.toString()
                      }
                      style={{ width: "calc(100% - 130px)" }}
                    />
                    <PrimaryButton
                      onRenderIcon={renderSpinner}
                      text="Get from VIN"
                      onClick={handleCheckVin}
                      style={{ position: "absolute", right: 0, top: 29 }}
                      disabled={!!formik.errors.vin || !formik.values.vin}
                    />
                  </WithOptionalLabel>
                  <Dropdown
                    id="condition"
                    label="Condition"
                    required={true}
                    multiSelect
                    selectedKeys={
                      formik.values.condition
                        ? formik.values.condition
                        : undefined
                    }
                    // onChange={handleDropdownMultiChange}
                    onChange={handleDropdownMultiChange}
                    placeholder=""
                    options={conditionValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.condition && formik.errors.condition
                        ? formik.errors.condition.toString()
                        : null
                    }
                  />
                  <Dropdown
                    id="year"
                    label="Year"
                    required={true}
                    selectedKey={
                      formik.values.year ? formik.values.year : undefined
                    }
                    onChange={handleDropdownChange}
                    placeholder=""
                    options={yearValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.year && formik.errors.year
                        ? formik.errors.year.toString()
                        : null
                    }
                  />

                  {loadingMakes ? (
                    <Spinner
                      styles={styles.spinnerStyles}
                      size={SpinnerSize.medium}
                    />
                  ) : (
                    <>
                      <ComboBox
                        id="make"
                        // componentRef={comboBoxRef}
                        // defaultSelectedKey="C"
                        label="Make"
                        required={true}
                        allowFreeform
                        autoComplete="on"
                        options={makeValues}
                        onChange={handleMakeChange}
                        selectedKey={formik.values.make.key}
                      />
                    </>
                  )}
                  {showModels ? (
                    loadingModels ? (
                      <Spinner
                        styles={styles.spinnerStyles}
                        size={SpinnerSize.medium}
                      />
                    ) : (
                      <Dropdown
                        id="model"
                        label="Model"
                        required={true}
                        // selectedKeys={
                        //   formik.values.model ? formik.values.model : undefined
                        // }

                        selectedKey={formik.values.model.key}
                        // multiSelect
                        onChange={handleModelChange}
                        placeholder=""
                        options={modelValues}
                        styles={dropdownStyles}
                        onBlur={formik.handleBlur}
                        errorMessage={
                          formik.touched.model && formik.errors.model
                            ? formik.errors.model.text.toString()
                            : null
                        }
                      />
                    )
                  ) : null}

                  <WithOptionalLabel>
                    <TextField
                      id="trim"
                      name="trim"
                      label="Trim"
                      value={formik.values.trim}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.trim &&
                        formik.errors.trim &&
                        formik.errors.trim.toString()
                      }
                    />
                  </WithOptionalLabel>
                  {/*<TextField*/}
                  {/*  id="price"*/}
                  {/*  name="price"*/}
                  {/*  label="Price"*/}
                  {/*  prefix="$"*/}
                  {/*  value={formik.values.price}*/}
                  {/*  onChange={formik.handleChange}*/}
                  {/*  onBlur={formik.handleBlur}*/}
                  {/*  errorMessage={*/}
                  {/*    formik.touched.price &&*/}
                  {/*    formik.errors.price &&*/}
                  {/*    formik.errors.price.toString()*/}
                  {/*  }*/}
                  {/*/>*/}
                  <label className="lbl-text required-Label">Price</label>
                  <CurrencyInput
                    customInput={TextField}
                    //thousandSeparator={true}
                    id="price"
                    name="price"
                    //label="Price"
                    //required={true}
                    prefix={PRICE_PREFIX}
                    value={formik.values.price?formik.values.price.replace(/,/g, ''):""}
                    onValueChange={(e) => handlePriceChange(e)}
                    onBlur={formik.handleBlur}

                  />
                  <span className="error-message">
                    {
                      formik.errors.price &&
                      formik.errors.price.toString()}
                  </span>

                  <TextField
                    id="mileage"
                    name="mileage"
                    label="Mileage"
                    required={true}
                    value={formik.values.mileage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.mileage &&
                      formik.errors.mileage &&
                      formik.errors.mileage.toString()
                    }
                  />

                  {/*<Loader show={locations.length === 0} />*/}
                  <Dropdown
                    id="location"
                    label="Location"
                    required={true}
                    selectedKey={
                      formik.values.location
                        ? formik.values.location
                        : undefined
                    }
                    //options={yearValues}
                    options={locations}
                    onChange={handleDropdownChange}
                    placeholder="any..."
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.location &&
                      formik.errors.location &&
                      formik.errors.location.toString()
                    }
                  />
                  {/*Set Status(Available), */}
                  {/*VIN, */}
                  {/*Condition, */}
                  {/*Year, */}
                  {/*Make */}
                  {/*Model, */}
                  {/*Trim, */}
                  {/*Price, */}
                  {/*Mileage,*/}
                  {/*Location, */}
                  {/*Body Type, */}
                  {/*Door, */}
                  {/*Drive Train,*/}
                  {/*Cylinder, */}
                  {/*Fuel Type*/}

                  <Dropdown
                    id="vehicleHistory"
                    label="Vehicle History"
                    required={true}
                    selectedKeys={
                      formik.values.vehicleHistory
                        ? formik.values.vehicleHistory
                        : undefined
                    }
                    onChange={handleDropdownMultiChange}
                    multiSelect
                    placeholder=""
                    options={historyValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.vehicleHistory &&
                        formik.errors.vehicleHistory
                        ? formik.errors.vehicleHistory.toString()
                        : null
                    }
                  />

                  <div style={{ marginTop: 30 }}>
                    <ListItem
                      onClick={() => setFormStep(FormStep.Body)}
                      title="Body and color"
                      subTitles={[
                        "Body Style",
                        "Exterior Color",
                        "Interior Color",
                        "Doors",
                      ]}
                    />
                    <ListItem
                      errorMessage={
                        (formik.touched.driveType && formik.errors.driveType) ||
                          (formik.touched.fuelType && formik.errors.fuelType) ||
                          // (formik.touched.fuelEconomy &&
                          //   formik.errors.fuelEconomy) ||
                          (formik.touched.engineCylinders &&
                            formik.errors.engineCylinders) ||
                          (formik.touched.engineDescription &&
                            formik.errors.engineDescription) ||
                          (formik.touched.transmissionDescription &&
                            formik.errors.transmissionDescription)
                          ? "Required"
                          : null
                      }
                      onClick={() => setFormStep(FormStep.Engine)}
                      title="Engine and drive"
                      subTitles={[
                        "Driver Type",
                        "Fuel Type",
                        "Transmission",
                        "Cylinders",
                      ]}
                      count={
                        calcFilledFields(engineAndDriveItemList) > 0 &&
                        calcFilledFields(engineAndDriveItemList).toString()
                      }
                      required={true}
                    />
                    <ListItem
                      errorMessage={
                        (formik.touched.isInvoice && formik.errors.isInvoice) ||
                          (formik.touched.isLeasing && formik.errors.isLeasing) ||
                          (formik.touched.msrp && formik.errors.msrp) ||
                          (formik.touched.bookValue && formik.errors.bookValue)
                          ? "Required"
                          : null
                      }
                      //errorMessage={'test message'}
                      onClick={() => setFormStep(FormStep.Financial)}
                      title="Financial information"
                      subTitles={["Invoice", "Leasing"]}
                      count={
                        calcFilledFields(financialInformationItemList) > 0 &&
                        calcFilledFields(
                          financialInformationItemList
                        ).toString()
                      }
                      // optional={
                      //   calcFilledFields(financialInformationItemList) === 0
                      // }
                      required={true}
                    />

                    <ListItem
                      onClick={handleOptionsClick}
                      title="Options"
                      subTitles={[
                        "Audio/Video",
                        "Power Equipment",
                        "Interior",
                        "Exterior",
                        "Safety Features",
                      ]}
                    />
                  </div>

                  <AddInventoryDropzone
                    setFiles={setPhotos}
                    initFiles={photos}
                    filesToAdd={filesToSend}
                    setFilesToAdd={setFilesToSend}
                  //     files: any[];
                  // setFiles: (frriles) => void;
                  // onFilesAdded={(array) => {
                  //   setPhotos(array);
                  // }}
                  />

                  <AddInventoryVideoDropzone
                    setFiles={setVideo}
                    initFiles={video}
                    filesToAdd={videoFilesToSend}
                    setFilesToAdd={setVideoFilesToSend}
                  />

                  <WysiwygEditor
                    editorState={wysiwygEditorState}
                    setEditorState={setWysiwygEditorState}
                    htmlToSend={htmlToSend}
                    setHtmlToSend={setHtmlToSend}
                  // onWysiwygAdded={(html) => {
                  //   setHtmlToSend(html);
                  // }}
                  />
                </Stack>
              ) : (
                <Stack tokens={styles.textFields}>
                  {secondaryDialogContent}
                </Stack>
              )}
            </Stack>
          </div>
          {showFooter && (
            <div className={contentStyles.footer}>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <DefaultButton text="Clear" onClick={handleClear} />
                </Stack>
                <Stack
                  tokens={styles.buttonTokens}
                  horizontal
                  horizontalAlign="end"
                >
                  <DefaultButton text="Close" onClick={handleDismiss} />
                  <PrimaryButton
                    disabled={!formik.isValid || !formik.dirty}
                    onRenderIcon={renderSpinner}
                    text="Add Inventory"
                    onClick={handleAddInventory}
                  />
                </Stack>
              </Stack>
            </div>
          )}
        </div>
      </Modal>
      <ConfirmDialog
        confirmBtnLabel="Close"
        title={"Are you sure you want to close inventory"}
        isOpen={isConfirmVinModalOn}
        onDismiss={() => setIsConfirmVinModalOn(false)}
        onAction={onDismiss}
        description={createConfirmDescription(vinData)}
        loading={false}
        onlyDismiss={vinData && vinData.length > 0 && isError(vinData)}
      />
    </>
  );
};
