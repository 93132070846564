import React, { useEffect, useState } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  PrimaryButton,
  Checkbox,
  Toggle,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "pages/accountSettingsPage/styles";
import { AppState } from "store/index";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as constantStyle,
} from "constants/styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  handleGetTemplates,
  handleGetWebsite,
  handleUpdateWebsite,
} from "store/website/website.action";

import { useFormik } from "formik";
import * as Yup from "yup";
import ListItem from "components/ListItem/ListItem";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";

import {
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize,
} from "@fluentui/react/lib/Persona";

const contentStyles = constantStyle(500, "auto");

interface IProps {
  onDismiss: () => void;
  shouldDisabledButton: Function;
  templateId: number;
}

export const ConfigurationServiceDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  shouldDisabledButton,
  templateId,
}) => {
  const [showCustomConfigSection, setShowCustomConfigSection] = useState(false);
  const loading = useSelector((state: AppState) => state.website.loading);
  const website = useSelector((state: AppState) => state.website.website);
  const error = useSelector((state: AppState) => state.website.error)
  const templates = useSelector(({ website }: AppState) => website.templates);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      domain: "",
      configuration: "",
      status: false,
      logo: null,
      socialMedia: {
        facebook: "",
        twitter: "",
        google: "",
        instagram: "",
      },
      workingHours: {
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
      },
    },
    validationSchema: Yup.object({
      domain: Yup.string().required("Required"),
      configuration: Yup.string().required("Required"),
      status: Yup.boolean(),
      logo: Yup.object().nullable(),
      socialMedia: Yup.object({
        facebook: Yup.string(),
        twitter: Yup.string(),
        google: Yup.string(),
        instagram: Yup.string(),
      }),
      workingHours: Yup.object({
        monday: Yup.string(),
        tuesday: Yup.string(),
        wednesday: Yup.string(),
        thursday: Yup.string(),
        friday: Yup.string(),
        saturday: Yup.string(),
        sunday: Yup.string(),
      }),
    }),
    onSubmit: (values) => {
      const ws = { ...values, configuration: parseInt(values.configuration) };

      dispatch<any>(
        handleUpdateWebsite(ws, () => {
          dispatch<any>(handleGetTemplates());
          dispatch<any>(handleGetWebsite());
          onDismiss();
        })
      );

      // dispatch<any>(handleUpdateWebsite());
    },
  });

  useEffect(() => {
    if (templateId) {
      formik.setFieldValue("configuration", templateId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  useEffect(() => {
    dispatch<any>(handleGetWebsite());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (website) {
      // setDomain(website.domain);
      formik.setValues({
        ...formik.values,
        domain: website.domain,
        configuration: website.configuration.toString(),
        status: website.status,
        socialMedia: website.socialMedia,
        workingHours: website.workingHours,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [website]);

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleListItemClick = () => {
    setShowCustomConfigSection(true);
  };

  const onStatusChange = () => {
    formik.setFieldValue("status", !formik.values.status);
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const fileSelectionHandler = (e) => {
    const uploadedFile = e.target.files[0];
    formik.setFieldValue("logo", uploadedFile);
  };

  const handleConfigurationOptionChange = (option: number) => {
    
    formik.setValues({ ...formik.values, configuration: option.toString() });
  };

  const examplePersona: IPersonaSharedProps = {
    // imageUrl: website.logo
    //   ? `${process.env.REACT_APP_IMAGE_SERVER}/${website.logo}`
    //   : "",
    imageUrl: formik.values.logo
      ? URL.createObjectURL(formik.values.logo)
      : website?.logo
      ? `${process.env.REACT_APP_IMAGE_SERVER}/${website.logo}`
      : "",
    imageInitials: "NN",
    // text: userProfile.firstName
    //     ? `${userProfile.firstName} ${
    //         userProfile.lastName ? userProfile.lastName : ""
    //     }`
    //     : "",
    // secondaryText: userProfile.email ? userProfile.email : "",
    // tertiaryText: "dealer account",
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      // containerClassName={contentStyles.container}
    >
      {showCustomConfigSection ? (
        <div className={contentStyles.headerNoShadow}>
          <GoBackToMainDialogBtn
            label="Back"
            onClick={() => setShowCustomConfigSection(false)}
          />

          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
      ) : (
        <div className={contentStyles.headerNoShadow}>
          <CustomFontText>Configuration Service</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
      )}
      {showCustomConfigSection ? (
        <div className={contentStyles.body}>
          <h3 style={{ marginTop: 0, marginBottom: 10 }}>Social Media</h3>
          <TextField
            id="socialMedia.facebook"
            name="socialMedia.facebook"
            label="Facebook"
            value={formik.values.socialMedia.facebook}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="socialMedia.twitter"
            name="socialMedia.twitter"
            label="Twitter"
            value={formik.values.socialMedia.twitter}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="socialMedia.google"
            name="socialMedia.google"
            label="Google"
            value={formik.values.socialMedia.google}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="socialMedia.instagram"
            name="socialMedia.instagram"
            label="Instagram"
            value={formik.values.socialMedia.instagram}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <h3 style={{ marginBottom: 0 }}>Working Hours</h3>
          <TextField
            id="workingHours.monday"
            name="workingHours.monday"
            label="Monday"
            value={formik.values.workingHours.monday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.tuesday"
            name="workingHours.tuesday"
            label="Tuesday"
            value={formik.values.workingHours.tuesday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.wednesday"
            name="workingHours.wednesday"
            label="Wednesday"
            value={formik.values.workingHours.wednesday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.thursday"
            name="workingHours.thursday"
            label="Thursday"
            value={formik.values.workingHours.thursday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.friday"
            name="workingHours.friday"
            label="Friday"
            value={formik.values.workingHours.friday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.saturday"
            name="workingHours.saturday"
            label="Saturday"
            value={formik.values.workingHours.saturday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            id="workingHours.sunday"
            name="workingHours.sunday"
            label="Sunday"
            value={formik.values.workingHours.sunday}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      ) : (
        <div className={contentStyles.body}>
          <Stack tokens={{ childrenGap: 20 }} style={{ marginBottom: 10 }}>
            <Persona
              {...examplePersona}
              styles={styles.personStyles}
              size={PersonaSize.size100}
              presence={PersonaPresence.none}
              hidePersonaDetails={false}
              imageAlt="Annie Lindqvist, status is dnd"
            />

            <DefaultButton
              styles={styles.uploadButton}
              text="Upload photo"
              type="file"
              iconProps={{ iconName: "Upload" }}
            >
              <input
                style={{
                  padding: 20,
                  // border: '1px solid red',
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: 107,
                  height: 1,
                  overflow: "hidden",
                  opacity: 0,
                  marginLeft: -10,
                }}
                type="file"
                onChange={fileSelectionHandler}
              />
            </DefaultButton>
          </Stack>

          <TextField
            id="domain"
            name="domain"
            label="Domain Name"
            value={formik.values.domain}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={Boolean(error) ? (error as {code:number, message: string}).message !== "Website not found." ?(error as {code:number, message: string}).message : "":"" }
          />

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {templates?.map((template) => {
              return shouldDisabledButton(template.type) ? null : (
                <Checkbox
                  key={template.id}
                  id={template.id.toString()}
                  // name="configuration"
                  className={contentStyles.padding}
                  label={template.name}
                  checked={
                    template.id.toString() === formik.values.configuration
                  }
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  onChange={() => {
                    handleConfigurationOptionChange(template.id);
                  }}
                />
              );
            })}
          </div>

          <ListItem
            onClick={handleListItemClick}
            title="Custom Configuration Section"
            subTitles={["Social Media", "Working Hours"]}
          />

          <div style={{ paddingTop: 20 }}>
            <Toggle
              label="Service Status"
              inlineLabel
              onText="active"
              offText="inactive"
              onChange={onStatusChange}
              checked={formik.values.status}
            />
          </div>
        </div>
      )}
      <div className={contentStyles.footerNoShadow}>
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{ childrenGap: 10 }}

          // styles={contentStyles.buttonsStyles}
        >
          <DefaultButton text="Close" onClick={onDismiss} />
          <PrimaryButton
            onRenderIcon={renderSpinner}
            text="Save Change"
            onClick={handleSubmit}
            disabled={!formik.dirty || !formik.isValid}
          />
        </Stack>
      </div>
    </Modal>
  );
};
