import React, { useEffect, useState } from "react";
import { //FontIcon,
    Stack, PrimaryButton
} from "@fluentui/react";
import Styles from "../dealsPage/styles";
import { Fabric } from "@fluentui/react/lib/Fabric";
import GoBack from "../../components/buttons/goBack/GoBack";
import { formatPathname } from "utils/helperFunctions";
import { Col, Row } from "react-grid-system";
import { styles } from "../marketingPage/styles";
import { useLocation /**useParams*/} from "react-router-dom";
import SubscriptionListItem from "components/ListItem/SubscriptionListItem";
import { SubscriptionDialog } from "components/dialogs/subscriptions/SubscriptionDialog";
import {
  handleGetSubscriptions,
  handleGetCreditCarts,
  handleRemoveCreditCart,
} from "store/subscriptions/subscriptions.action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useParams } from "react-router-dom";
import store from "store";
import { handleLoginByToken, handleRefreshToken } from "store/auth/auth.action";
import { CustomFontText } from "components/customFontText/CustomFontText";
import CreditCartItem from "pages/subscriptionsPaga/CreditCartItem";
import { AddCreditCartDialog } from "components/dialogs/subscriptions/AddCreditCartDialog";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";

interface ParamTypes {
  subscriptionState: string;
}

const SubscriptionsPage = () => {
  const [pathname, setPathname] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showAddCreditCartDialog, setShowAddCreditCartDialog] = useState(false);

  const params = useParams<ParamTypes |any >();

  const subscriptions = useSelector(
    (state: AppState) => state.subscriptions.subscriptions
  );

  const creditCarts = useSelector(
    (state: AppState) => state.subscriptions.creditCarts
  );

  const deleteLoading = useSelector(
    (state: AppState) => state.subscriptions.removingCart
  );

  // const loading = useSelector((state: AppState) => state.subscriptions.loading);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch<any>(handleGetSubscriptions());

    dispatch<any>(handleGetCreditCarts());

    setShowAddCreditCartDialog(Boolean(location?.state));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (params.subscriptionState === "success") {
      store.dispatch<any>(handleRefreshToken());
      store.dispatch<any>(handleLoginByToken(""));
    }
  }, [params.subscriptionState]);


  const subscriptionCallback = () => {
    dispatch<any>(handleGetSubscriptions());
    dispatch<any>(handleRefreshToken());
    dispatch<any>(handleLoginByToken(""));
    setSelectedSubscription(null)
  }


  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleItemClick = (item) => {
    setSelectedSubscription(item);
  };
  const handleOnDeleteBtnClick = (id: string) => {
    setIdToDelete(id);
    setIsConfirmDialogOpen(true);
  };
  const deleteCart = () => {
    dispatch<any>(
      handleRemoveCreditCart(idToDelete, () => setIsConfirmDialogOpen(false))
    );
  };

  return (
    <>
      <SubscriptionDialog
          callback={subscriptionCallback}
        subscription={selectedSubscription}
        onDismiss={() => setSelectedSubscription(null)}
        isOpen={Boolean(selectedSubscription)}
      />

      <AddCreditCartDialog
        onDismiss={() => setShowAddCreditCartDialog(false)}
        isOpen={showAddCreditCartDialog}
      />

      <ConfirmDialog
        title={"Confirm delete cart"}
        isOpen={isConfirmDialogOpen}
        onDismiss={() => setIsConfirmDialogOpen(false)}
        onAction={deleteCart}
        description={"Please confirm deletion of Credit Cart"}
        loading={deleteLoading}
      />

      <Stack styles={Styles.container} tokens={{ childrenGap: 10 }}>
        <Fabric>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 30 }}
            >
              <GoBack label={formatPathname(pathname)} />
            </Stack>
          </Stack>

          <Row>
            <Col
              className="dealer-account"
              style={styles.col}
              xs={12}
              sm={12}
              md={12}
              xl={12}
              xxl={7}
            >
              {/*<div>*/}
              {/*  {message}*/}
              {/*</div>*/}
              <Stack
                styles={styles.headerStyles}
                horizontal
                verticalAlign="start"
                horizontalAlign="space-between"
              >
                <CustomFontText size={20}>
                  Available Subscriptions
                </CustomFontText>
                {/*<FontIcon iconName="Edit" className={styles.iconStyles} />*/}
              </Stack>
              <Stack
                styles={styles.headerStyles}
                verticalAlign="start"
                horizontalAlign="start"
              >
                {subscriptions &&
                  Object.values(subscriptions).map((sub) => {
                    return (
                      <SubscriptionListItem
                        item={{
                          active: Boolean(sub.subscription),
                          cancelId: sub.subscription
                            ? sub.subscription.id
                            : null,
                          currentPeriodEnd: sub.subscription
                            ? sub.subscription.current_period_end
                            : null,
                          id: sub.product.id,
                          title: sub.product.nickname,
                          description: sub.product.metadata?.description
                            ? sub.product.metadata?.description
                            : "",
                          price: sub.product.unit_amount,
                          interval: sub.product.recurring.interval,
                        }}
                        handleClick={handleItemClick}
                      />
                    );
                  })}
              </Stack>
            </Col>

            <Col
              // className="dealer-account"
              // style={styles.cardColumn}
              xs={12}
              sm={12}
              md={12}
              xl={12}
              xxl={4}
            >
              {/*/!*<div>*!/*/}
              {/*/!*  {message}*!/*/}
              {/*/!*</div>*!/*/}
              <div style={styles.cardColumn}>
                <Stack
                  styles={styles.headerStyles}
                  horizontal
                  verticalAlign="start"
                  horizontalAlign="space-between"
                >
                  <CustomFontText size={20}>
                    Credit Cart Details{" "}
                  </CustomFontText>
                  {/*<FontIcon iconName="Edit" className={styles.iconStyles} />*/}
                </Stack>
                {/*<Stack*/}
                {/*  styles={styles.headerStyles}*/}
                {/*  verticalAlign="start"*/}
                {/*  horizontalAlign="start"*/}
                {/*>*/}

                {/*<div>*/}
                {/*  {JSON.stringify(creditCarts)}*/}
                {/*</div>*/}

                {Boolean(creditCarts?.length) &&
                  creditCarts.map((cart) => {
                    return (
                      <CreditCartItem
                        cart={cart}
                        onDelete={handleOnDeleteBtnClick}
                      />
                    );
                  })}
                {!Boolean(creditCarts.length) &&

                <Stack
                  style={{ marginTop: 20 }}
                  horizontal
                  verticalAlign="start"
                  horizontalAlign="end"
                >
                  <PrimaryButton
                    // onRenderIcon={renderSpinner}
                    text="Add Cart"
                    onClick={() => setShowAddCreditCartDialog(true)}
                  />
                </Stack>
                }
              </div>
              {/*{subscriptions &&*/}
              {/*  Object.values(subscriptions).map((sub) => {*/}
              {/*    return (*/}
              {/*      <SubscriptionListItem*/}
              {/*        item={{*/}
              {/*          active: Boolean(sub.subscription),*/}
              {/*          cancelId: sub.subscription*/}
              {/*            ? sub.subscription.id*/}
              {/*            : null,*/}
              {/*          currentPeriodEnd: sub.subscription*/}
              {/*            ? sub.subscription.current_period_end*/}
              {/*            : null,*/}
              {/*          id: sub.product.id,*/}
              {/*          title: sub.product.nickname,*/}
              {/*          description: sub.product.metadata?.description*/}
              {/*            ? sub.product.metadata?.description*/}
              {/*            : "",*/}
              {/*          price: sub.product.unit_amount,*/}
              {/*          interval: sub.product.recurring.interval,*/}
              {/*        }}*/}
              {/*        handleClick={handleItemClick}*/}
              {/*      />*/}
              {/*    );*/}
              {/*  })}*/}
              {/*</Stack>*/}
            </Col>
          </Row>
        </Fabric>
      </Stack>
    </>
  );
};

export default SubscriptionsPage;
