import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
  Stack,
  IStackStyles,
  PrimaryButton,
  IPersonaSharedProps,
  Persona,
  PersonaPresence,
  PersonaSize,
  TextField,
  Link
} from "@fluentui/react";
// import { useLocation } from "react-router-dom";
//import LocationsTable from "components/table/locations/LocationsTable";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { AppState } from "store/index";
// import Loader from "components/loader/Loader";
import { EmployeeActionPermission } from "hoc/withCredentials";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EmployeeMoreActionsSubmenu } from "components/table/employees/dropdown/EmployeeMoreActionsSubmenu";
import { styles } from "components/dialogs/locations/styles";
// import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { NewEmployeeDialog } from "components/dialogs/employees/NewEmployeeDialog";
import CustomTable from "components/table/datatable/CustomTable";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { 
HANDLE_GET_EMPLOYEES, 
//HANDLE_UPDATE_EMPLOYEE
} from "store/employees/employees.types";
import { handleEmployeeActivate, handleSendInvite } from "store/employees/employees.action";
import moment from "moment";
import { EmployeeDetailPivot } from 'pages/employeesPage/EmployeeEditPivot';
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import employeeGroup from "store/employees/employeeGroup.json";


const container: IStackStyles = {
  root: {
    marginTop: 10,
  },
};

const credentialModuleName = "Employee";
export const EmployeesPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const serverError = useSelector((state: AppState) => state.employees.employeeActivateError) as any; 
  const [employeeId, setEmployeeId] = useState(0);
  const [employeeList, setemployeeList] = useState([]);
  const [activeFlag, setActiveFlag] = useState(1);
  const [commonServerError, setcommonServerError] = useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const employees = useSelector((state: AppState) => state.employees.employees) as any; 
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState('firstName');

    

  // const location = useLocation();

    const [credentials, setCredentials] = useState([]);
    const [glFilter, setGLFilter] = useState('');

  /* useEffect(() => {
    setPathname(location.pathname);
  }, [location]); */

//   const handleOnDismiss = () => {
//     //  setIsOpenAddDialog(false);
//   };

//   const handleOnEditDismiss = () => {
//     //  setIsEdit(false);
//   };
    const handleActiveInactiveUsers = (id) => {
        setEmployeeId(id);
        setActiveFlag(activeFlag === 1?0:1);
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: activeFlag === 1 ? 0 : 1 } })
    };


    const handleFirstNameFilter = (val: any) => {
        setGLFilter(val.target.value);
      //dt.current.filter(val, 'firstName', 'contains');
    };

    useEffect(() => {
        if (employees) {
            if (employees.results.length > 0 && user.profileType) {
                handlePopulateEmployees();
            }
            else {
                setemployeeList([]);
            }
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user && user.profileType && employees && employees.results.length > 0) {
            handlePopulateEmployees();
        }
        else {
            setemployeeList([]);
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateEmployees = () => {
        let results = employees.results;
        for (var i in results) {
            results[i].firstName = results[i].firstName + ' ' + results[i].lastName + ';' + results[i].email;
            results[i].status = results[i].status === 1 ? "Invited" :
                results[i].status === 2 ? "Active" :
                    results[i].status === 3 ? "Invite Expired" :
                        results[i].status === 4 ? "Inactive" : null;
            //
            if (results[i].employeeDefaultGroups?.length && !isKarlProfile(user.profileType)) {
                results[i]["role"] = results[i].employeeDefaultGroups.length === 2 ? " Manager, Setting Admin" :
                    results[i].employeeDefaultGroups[0].defaultGroup.id === 1 ? "None" :
                        results[i].employeeDefaultGroups[0].defaultGroup.id === 2 ? "Setting Admin" :
                            results[i].employeeDefaultGroups[0].defaultGroup.id === 3 ? "Manager" : "None";
            } else if (results[i].employeeDefaultGroups?.length && isKarlProfile(user.profileType)) {

                let groupIds = results[i].employeeDefaultGroups.map(a => a.defaultGroup.id);
                groupIds = groupIds.filter((val, index) => groupIds.indexOf(val) === index);

                results[i]["role"] = employeeGroup.groups.filter(x => groupIds.indexOf(x.id) !== -1).map(x => x.name).join(', ');

            } else {
                results[i]["role"] = "None";
            }
        }
        setemployeeList(results);
    };

  useEffect(() => {
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
      dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1 }})

      if (location?.state?.userId) {
        handleEditEmployee(location.state.userId);
      }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Datatable Templates
    const header = (
        <>
            <div className="table-header employeeeSearchLabel">
                <TextField
                    value={glFilter}
                    type="search"
                    label="Search Users"
                    className="ownerSearch"
                    onChange={(e) => handleFirstNameFilter(e)}
                />
                <div style={{ justifyContent:"flex-end"}} className="table-footer table-footer-action">
                    <Link onClick={handleActiveInactiveUsers} className="link-font-text"> {activeFlag === 1 ? "Inactive Users" : "Active Users"}</Link>
                </div>      
            </div>
        </>
    );

    const nameBodyTemplate = (rowData) => {
         let nameEmail = rowData.firstName.split(";");
        const examplePersona: IPersonaSharedProps = {
            imageUrl: rowData.avatar
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${rowData.avatar}` : "",
            imageInitials: rowData?.firstName ? `${rowData.firstName[0]} ${rowData?.lastName ? rowData.lastName[0] : ""}` : "",
            text: nameEmail[0],
            secondaryText: nameEmail[1]
        };

        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Name</span>
                <div>
                    <Persona
                        {...examplePersona}
                        styles={styles.personInGridStyles}
                        size={PersonaSize.size48}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is dnd"
                    />
                </div>
            </React.Fragment>
        );
    };

    
    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.status}
                {rowData.status === "Invite Expired" ? <Link onClick={() => handleResendInvite(rowData.id)} className="updateEmailTxt td-btn td-btn-txt">Resend Invite</Link> : null}
            </React.Fragment>
        );
    };

    const lastActivityBodyTemplate = (rowData) => {
        let local = null;
        if (rowData.lastActivity) {
            var date = moment.utc(rowData.lastActivity).format('YYYY-MM-DD HH:mm:ss');
            var stillUtc = moment.utc(date).toDate();
            local = moment(stillUtc).local().format('MM-DD-YYYY hh:mm A');

        }
        
        return (
            <React.Fragment>
                <span className="p-column-title">Last Activity</span>
                {local}
            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && (
                    <EmployeeMoreActionsSubmenu
                        modulePermissions={credentials}
                        employeeId={rowData.id}
                        isActive={rowData.isActive}
                        onActivateDeactivate={(employeeId, isActive) => handleActivateDeactivateUser(employeeId, isActive) }
                        onEdit={(employeeId) => handleEditEmployee(employeeId)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditEmployee = (id) => {
       setEmployeeId(id);
       setIsEdit(true);
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_VIEW?.toString())) : true; 
            if (cred || role) {
                handleEditEmployee(event.data.id);
            }
        }
    };

    const handleActivateDeactivateUser = (id, isActive) => {
        setEmployeeId(id);
        dispatch<any>(handleEmployeeActivate({ id, isActive: !isActive}));
   };

    const handleResendInvite = (id) => {
        dispatch<any>(handleSendInvite({ id }));
    }

    /* const footer = (
    ); */

    useEffect(() => {
        if (serverError) {
            console.log(serverError);
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    const table_columns = {
        "firstName": "Name",
        "role": "Role(s)",
        "status": "Status",
        "lastActivity": "Last Activity",
        "settings": "Actions"
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }


  return (
    <>
          {isEdit === true ? <>
              <EmployeeDetailPivot isEdit={isEdit} employeeId={employeeId} onDismiss={() => setIsEdit(false)} />
          </> :
              <>
                  {/*<Loader show={loading} />*/}
                    {/* <Stack className="goBackSettings">
                      <GoBackInfo mainPage="Settings" secondPage="Users" />
                    </Stack> */}

                    <Stack styles={container} tokens={{ childrenGap: 10 }}>

                      <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                      >

            <Row className="pageTitleRow">
                <Col className="alignHeaders">
                    <CustomFontText className=" pageTitleFont pageName pageHeaderFont">{activeFlag ? "Users - Active" :"Users - Inactive" }</CustomFontText>
                    {credentials && user.roles && (
                        <EmployeeActionPermission
                            permissionList={user.aggregatedDefaultPolicies}
                            alias={emplyeePermissionsAction.SETTING_USER_ADD}
                            roles={user.roles}
                        >
                            <PrimaryButton
                            style={{marginTop:"20px"}}
                            onClick={() => setIsOpen(true)}
                            text="Add User"
                            //className="addInventoryBtnDesk"
                            iconProps={{ iconName: "CircleAdditionSolid" }}
                            />
                        </EmployeeActionPermission>
                    )}
                </Col>
            </Row>

                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
                           {/* <GoBack label="Users" />  */}

                           {isOpen && (
                              <NewEmployeeDialog
                                  isOpen={isOpen}
                                  isKarlProfile={isKarlProfile(user.profileType)} 
                                  onDismiss={() => setIsOpen(false)}
                              />
                           )}

                          
                          {/* <ConfirmDialog
                              cancelBtnLabel="No"
                              confirmBtnLabel="Yes"
                              loading={false}
                              description={confirmContent}
                              title="Confirm delete location"
                              onlyDismiss={confirmContent !== "Are you sure you want to delete this location?"}
                              isOpen={isConfirmBoxOpen}
                              onAction={(locationId) => dispatch<any>(handleDeleteLocation(locationId, (msg) => {
                                  if (msg) {
                                      setConfirmContent(msg);
                                  }
                                  else {
                                      setIsConfirmBoxOpen(false);
                                  }
                                  
                              }))}
                              onDismiss={() => setIsConfirmBoxOpen(false)}
                          /> */}
                        </Stack>
                      </Stack>
                            {
                                commonServerError ?
                                    <div>
                                        <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                    </div> : null
                            }
                            <div className="datatable-filter-demo locations">
                                <div className={isKarlProfile(user.profileType) ? "card karl-users-tbl usersPage" :"card usersPage"}>
                                    <CustomTable 
                                          column_list={table_columns}
                                          data={employeeList} 
                                          header={header}
                                          sortField={sortField}
                                          sortOrder={sortOrder}
                                          onSort={handleColumnSort}
                                          customTemplates={{
                                              "firstName": nameBodyTemplate,
                                              "status": statusBodyTemplate,
                                              "lastActivity": lastActivityBodyTemplate,
                                              "settings": settingBodyTemplate,
                                          }}
                                          emptyMessage="No users found."
                                          onRowClick={(event)=> handleRowClick(event)}
                                          globalFilter={glFilter}/>
                                </div>
                            </div>
                    </Stack>
              </>
              }
      
    </>
  );
};
