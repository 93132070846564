import React, { FunctionComponent, useEffect } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    DefaultButton,
    IconButton,
    TextField,
    Stack,
    //Dropdown,
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
//import { dropdownStyles } from "constants/styles";
import CurrencyInput from 'react-currency-input-field'
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { ConfirmDeleteDialog} from "components/dialogs/confirmDeleteDialog/ConfirmDeleteDialog";
import {
    handleDeleteExpense,
} from "store/inventory/inventory.action";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from 'primereact/dropdown';

const contentStyles = content(600, "auto");

interface IProps {
    onSubmitFormik: (formikData: any, httpMethod) => void;
    onDismiss: () => void;
    categoryList: any;
    kbbStyles: any;
    bbStyles: any;
    pricingTableVal: any;
    qValueDetail: any;
    vehID: any;
    onDelete:any;
}

export const AddUpdateExpenseDialog: FunctionComponent<IProps> = ({
    onSubmitFormik,
    onDismiss,
    categoryList,
    kbbStyles,
    bbStyles,
    pricingTableVal,
    qValueDetail,
    vehID,
    onDelete
}) => {
    const dispatch = useDispatch();
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    const [expenseDate, setexpenseDate] = React.useState<Date | Date[] | undefined | any>(new Date(pricingTableVal.expenseDate || new Date()));
    //const digitPattern = /^\d+$/;
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const [editPricingDialog, seteditPricingDialog] = React.useState('PUT');
    const [isDDClosed, setDDClosed] = React.useState(false);
    const [bodyStyle, setBodyStyle] = React.useState({ minHeight: "105px"});
    //const yearRange = new Date().getFullYear() + ":" + (new Date().getFullYear() + 1);
    const yearRange = "1980:" + (new Date().getFullYear()+1); 

    const formik = useFormik({
        initialValues: {
            expenseDate: pricingTableVal?.expenseDate ? moment(pricingTableVal.expenseDate).format("YYYY-MM-DD"): new Date(),
            amount: pricingTableVal ? pricingTableVal.size.replace(/\$/g, ""): '',
            description: pricingTableVal ? pricingTableVal.description : '',
            category: pricingTableVal ? pricingTableVal.type: '',
            pricingID: pricingTableVal ? pricingTableVal.pricingID: '',
        },
        validationSchema: Yup.object({
            expenseDate: Yup.string().required("Required"),
            amount: Yup.string().required("Required"),
            category: Yup.string().required("Required"),
            description: Yup.string(),
            pricingID: Yup.string()
        }),
        onSubmit: (values) => {
            onSubmitFormik(values, editPricingDialog);
        },
    });

    useEffect(() => {
        let expendateDate: any = moment(new Date(pricingTableVal.expenseDate || new Date())).local();
        setexpenseDate(expendateDate._i);
        // setexpenseDate((new Date(pricingTableVal.expenseDate || new Date())));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingTableVal])

    useEffect(() => {
        if (pricingTableVal && pricingTableVal.type !== '') {
            seteditPricingDialog('PUT')
            formik.dirty = true;
        } else {
            seteditPricingDialog('POST')
        }
        formik.setValues(
            {
                ...formik.values,
                'amount': pricingTableVal.size.replace(/\$/g, "")
            }
        );
    }, [pricingTableVal, formik]);

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDropdownValue = (val) => {
        formik.setValues(
            {
                ...formik.values,
                'category': val
            }
        );
    };

    const handleUdateQValue = () => {
        formik.handleSubmit();
    }

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                'expenseDate': moment(val).format("YYYY-MM-DD")
            }
        );
        setexpenseDate(val);
    };
    const handleDelete = () => {
        //setConfirmContent(deleteContent);
        setIsConfirmBoxOpen(true);
    };
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);

    const onDropDownFocus = () => {
        if (isDDClosed) {
            setBodyStyle({minHeight: "365px" });
        }
    }

    const onDropDownClose = () => {
        setBodyStyle({minHeight: "105px" });
        setDDClosed(false);
    }

    const onMouseDown = (e) => {
        setDDClosed(true);
    }
    
    return (
        
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container +' inv-srch-mod expense-modal'}
        >
            <ConfirmDeleteDialog
                            loading={loading}
                            description="Confirm Delete?"
                            isOpen={isConfirmBoxOpen}
                            onAction={() => dispatch<any>(handleDeleteExpense(vehID, pricingTableVal.pricingID, (response) =>
                            {
                                setIsConfirmBoxOpen(false);
                                onDismiss();
                                onDelete(response);
                            }))}
                            onDismiss={() => setIsConfirmBoxOpen(false)}
            />
            <div className={contentStyles.header + " inventory-popup modal-header"} style={{ width: "auto" }}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle">Expense</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " expenseDialogPopup inv-srch-bdy body-auto-hight"} style={bodyStyle}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider pad-right-5" sm={5} md={5} xs={6}>
                            <label style={{ display: "block" }} className="lbl-text">Expense Date</label>
                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                            <Calendar
                                id="navigators"
                                //className="modal-calendar modalCalenderExpense"
                                value={expenseDate}
                                style={{ width: "100%" }}
                                showIcon
                                onChange={(e) => handleDateChange(e.value)}
                                required={true}
                                monthNavigator
                                yearNavigator
                                yearRange={yearRange} 
                                appendTo="self"
                                />
                            </div>
                        </Col>
                        <Col className="inventoryColDivider pad-left-5" sm={5} md={5} xs={6}>
                            <label className="lbl-text required-Label">Amount</label>
                            <CurrencyInput
                                customInput={TextField}
                               // thousandSeparator={true}
                                id="amount"
                                name="amount"
                                //label="Amount"
                                decimalScale={2}
                                value={formik.values.amount?formik.values.amount.replace(/,/g, ''):""}
                                onValueChange ={(e) => {
                                    formik.setValues(
                                        {
                                            ...formik.values,
                                            "amount": e
                                        }
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                //required={true}
                            />
                            <span className="error-message">
                                {
                                    formik.errors.amount &&
                                    formik.errors.amount.toString()}
                            </span>
                        </Col>
                    </Row>

                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                            {/*<Dropdown
                                id="category"
                                label="Category"
                                selectedKey={formik.values.category}
                                onChange={handleDropdownValue}
                                placeholder=""
                                options={categoryList}
                                styles={dropdownStyles}
                                required={true}
                                errorMessage={
                                    formik.touched.category &&
                                    formik.errors.category &&
                                    formik.errors.category.toString()
                                }
                            />*/}
                            <div className="locationDropPosition">
                                <label className="lbl-text required-Label">Category</label>
                                <Dropdown
                                    id="category"
                                    placeholder=""
                                    value={formik.values.category}
                                    options={categoryList}
                                    onChange={(e) => handleDropdownValue(e.value)}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                    onHide={onDropDownClose}
                                    onFocus={onDropDownFocus}
                                    onMouseDown={onMouseDown}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                placeholder=""
                                value={formik.values.description}
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                /* errorMessage={
                                    formik.touched.locationForm && locationFormik.touched.locationForm.legalName &&
                                    locationFormik.errors.locationForm && locationFormik.errors.locationForm.legalName &&
                                    locationFormik.errors.locationForm.legalName.toString()
                                } */
                            />
                        </Col>
                    </Row>

                    <Row className="inventoryRowSection footerBtn" justify="center">
                        {pricingTableVal.pricingID && pricingTableVal.pricingID > 0 ?   
                            <>
                                <Col sm={5} md={5} xs={6}>
                                    <DefaultButton
                                        className="expenseDelDialog"
                                        style={{ float: "left"}}
                                        onClick={handleDelete}
                                        //iconProps={{ iconName: "Delete" }}
                                        text="Delete"
                                    />
                                </Col>
                                <Col sm={5} md={5} xs={6}>
                                    <PrimaryButton
                                        text="Save"
                                        //className=" updateExpenseDiaSave"
                                        style={{ float: "right"}}
                                        onClick={handleUdateQValue}
                                        onRenderIcon={renderSpinner}
                                        disabled={((!formik.isValid || !formik.dirty) && (editPricingDialog === 'POST'))
                                            || ((!formik.isValid || Object.keys(formik.errors).length > 0) && (editPricingDialog === 'PUT'))}
                                    />

                                </Col>
                            </> :
                            <Col style={{ textAlign:"center"}}>
                                <PrimaryButton
                                    text="Save"
                                    onClick={handleUdateQValue}
                                    onRenderIcon={renderSpinner}
                                    disabled={((!formik.isValid || !formik.dirty) && (editPricingDialog === 'POST'))
                                        || ((!formik.isValid || Object.keys(formik.errors).length > 0) && (editPricingDialog === 'PUT'))}
                                />

                            </Col>  
                        }
                    </Row>
                </Stack>

            </div>

            {/* <div className={contentStyles.footer + " inventory-popup-footer"} style={{ textAlign: "center" }}>
                <div>
                    <PrimaryButton
                        text="Save"
                        // style={{ float: "right" }}
                        onClick={handleUdateQValue}
                        onRenderIcon={renderSpinner}
                        disabled={(!formik.isValid || Object.keys(formik.errors).length > 0) || (editPricingDialog === 'POST')}
                    />
                </div>
            </div> */}
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
