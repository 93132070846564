import React  from "react";
import { useEffect} from "react";
import { Col, Row } from "react-grid-system";
import {
    PrimaryButton,
    //IPersonaSharedProps,
   // Persona,
    //PersonaPresence,
    //PersonaSize,
    //Link,
    Stack,
    Icon,
    //Checkbox
} from "@fluentui/react";
//import TextField from '@material-ui/core/TextField';
import {useSelector } from "react-redux";
//import { colors } from "constants/constants";
import { AppState } from "store";
//import CustomTable from "components/table/datatable/CustomTable";
//import moment from "moment";
//import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import { useNavigate  } from "react-router-dom";
//import { useFormik } from "formik";
//import * as Yup from "yup";
import { useDispatch } from "react-redux";
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import { emplyeePermissionsAction } from "../../constants/constants";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { handleGetLocations, HandleGetPostVacation } from "store/locations/locations.action";
//mport { TimePicker } from 'antd';
import { useMediaQuery } from "react-responsive";
import { AddVaccationDaysDialog } from "components/dialogs/dealTrackerDialog/AddVaccationDaysDialog";
//import { TimeOffDialog } from "components/dialogs/dealTrackerDialog/TimeOffDialog";
import { handleSaveEmployeeWeeklyHours, handleViewEmployeeWeeklyHours } from "store/employees/employees.action";
import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//const credentialModuleName = "SelectList";
//import { convertLocalToUTCModified, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { emplyeePermissionsAction } from "../../constants/constants";
import { EmployeeActionPermission } from "hoc/withCredentials";

interface IProps {
    title?: any;
    employeesID?: any;
    isEdits: boolean;
    onDismiss: (msg) => void;
}
//export const SheduleRecurringCalender = () => {

export const SheduleRecurringCalender: React.FunctionComponent<IProps> = ({
    title,
    employeesID,
    isEdits,
    onDismiss
}) => {

    const isMobile = useMediaQuery({ maxWidth: 568});
    const [cityDealerList, setCityDealerList] = React.useState([]);
    //const navigate = useNavigate ();
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const dispatch = useDispatch();
    // const disabledSec = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
    //36,37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]

    const [isOpenVacationDaysDialog, setVacationDaysDialog] = React.useState(false);
    const employee = useSelector((state: AppState) => state.employees.selectedEmployee);
    const userProfile: any = useSelector((state: AppState) => state.userProfile.userProfile);
    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);
    const employeeWeeklyHours = useSelector((state: AppState) => state.employees.employeeWeeklyHours);
    const [onSubmit, setOnSubmit] = React.useState(false);
    const weekData = [
    {dayOfTheWeek:1,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:2,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:3,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:4,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:5,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:6,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''},
    {dayOfTheWeek:7,startsAt:'', startTime:null, endsAt:'',endTime:null,  locationId:''}
    ]
    const [weekHourInfo, setWeekHourInfo] = React.useState(weekData);
    const [vacationListData, setvacationListData] = React.useState(null);
    const [vacationDates, setVacationDates] = React.useState([]);
    const [vacationStats, setvacationStats] = React.useState(null);
    const [commonServerError, setcommonServerError] = React.useState(null);

    useEffect(() => {
        dispatch<any>(handleGetLocations());
        setOnSubmit(false);
        getVacationData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (locations && locations.results) {
            let locationVal = [...locations.results]
            locationVal.unshift({ id: 0 ,legalName: '---Please Select---'});
            setCityDealerList(locationVal);  
        } else {
            setCityDealerList(locations);
        }

    }, [locations]);

    useEffect(() => {
        if (isEdits && employeesID > 0) {
            dispatch<any>(handleViewEmployeeWeeklyHours(employeesID, isKarlProfileType));
            //setLoadingFlag(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdits]);


    useEffect(() => {
        if(employeeWeeklyHours && employeeWeeklyHours.length){
           let workInfo = [...weekHourInfo];
             for(let i in workInfo){
                    const data = employeeWeeklyHours.filter(x => x.dayOfTheWeek === weekData[i].dayOfTheWeek);      

                  // if(data && data.length === 1)        
                  // {
                  //    let utcLocalStartTime = convert24hourTo12HourFormat((data[0].startsAt));
                  //    //let utcLocalTime = convert24hourTo12HourFormat( data[0].startsAt);
                  //      let financeStartTimeRes: any = moment(utcLocalStartTime, 'h:mm a');
                  //      let utcLocalEndTime = convert24hourTo12HourFormat((data[0].endsAt));
                  //      let financeEndTimeRes: any = moment(utcLocalEndTime, 'h:mm a');
                  //      
                  //
                  //      workInfo[i].startTime = financeStartTimeRes; //key and value
                  //      workInfo[i].startsAt = data[0].startsAt; //key and value
                  //      workInfo[i].endTime = financeEndTimeRes; //key and value
                  //      workInfo[i].endsAt = data[0].endsAt; //key and value
                  //      workInfo[i].locationId = data[0].location.id; //key and value
                  //    
                  // }


                    if (data && data.length === 1) {


                          workInfo[i].startTime = moment(data[0].startsAt,'h:mm');
                          workInfo[i].startsAt = data[0].startsAt;
                          workInfo[i].endTime = moment(data[0].endsAt,'h:mm');
                          workInfo[i].endsAt = data[0].endsAt;
                          workInfo[i].locationId = data[0].location.id; //key and value
                      }
             }
             setWeekHourInfo(workInfo)
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeWeeklyHours])

    const getVacationData = () => {
        // get Vacation details
        dispatch<any>(HandleGetPostVacation(employeesID, 'GET', null, (data) => {
            console.log('get VACATION LIST, ', data)
            setvacationListData(null)
            setvacationStats(null)
            setVacationDates([])
            if (data) {
                setvacationListData(data.vacationList?.vacation)
                setvacationStats(data.vacationStats);
                setVacationDates(data.vacationStats?.vacationDates)
            }
        }));
    }

    const onLocationChange = (value, index) => {
        let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
        newWorkHourInfo[index].locationId = value; //key and value
        if (!value) {
            newWorkHourInfo[index].startTime = null; //key and value
            newWorkHourInfo[index].startsAt = ''; //key and value
            newWorkHourInfo[index].endTime = null; //key and value
            newWorkHourInfo[index].endsAt = ''; //key and value
        }
        setWeekHourInfo(newWorkHourInfo);
    };
 
    //const handleStartTimeChange = (e, index) => {
    //
    //    let startTime: any = moment(e, 'h:mm a');
    //    if (startTime) {
    //            
    //            let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
    //            newWorkHourInfo[index].startTime = startTime; //key and value
    //            newWorkHourInfo[index].startsAt = convertTime12To24( moment(startTime._i, "h:mm:ss A").format("HH:mm:ss")); //key and value
    //            //newWorkHourInfo[index].startsAt = convertTime12To24(startTime._i); //key and value
    //             console.log('handleStartTimeChange:',weekHourInfo);
    //           setWeekHourInfo(newWorkHourInfo);
    //
    //    } else {
    //        let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
    //        newWorkHourInfo[index].startTime = null; //key and value
    //        newWorkHourInfo[index].startsAt = ''; //key and value
    //        setWeekHourInfo(newWorkHourInfo);
    //    }
    //};

    const handleStartTimeChange = (e, index) => {
        if (e) {
                
                let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
                newWorkHourInfo[index].startTime =  e; //key and value
                newWorkHourInfo[index].startsAt =  e.format('HH:mm:ss'); //key and value
               setWeekHourInfo(newWorkHourInfo);

        } else {
            let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
            newWorkHourInfo[index].startTime = null; //key and value
            newWorkHourInfo[index].startsAt = ''; //key and value
            setWeekHourInfo(newWorkHourInfo);
        }
    };

    //const handleEndTimeChange = (timeString, index) => {
    //    let endTime: any = moment(timeString, 'h:mm a');
    //    if (endTime) {
    //            
    //            let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
    //            newWorkHourInfo[index].endTime = endTime; //key and value
    //            newWorkHourInfo[index].endsAt = convertTime12To24( moment(endTime._i, "h:mm:ss A").format("HH:mm:ss")); //key and value
    //            //newWorkHourInfo[index].startsAt = convertTime12To24(endTime._i); //key and value
    //            setWeekHourInfo(newWorkHourInfo);
    //
    //    } else {
    //        let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
    //        newWorkHourInfo[index].endTime = null; //key and value
    //        newWorkHourInfo[index].endsAt = ''; //key and value
    //        setWeekHourInfo(newWorkHourInfo);
    //    }
    //};

    const handleEndTimeChange = (e, index) => {
        if (e) {
                
                let newWorkHourInfo = [...weekHourInfo]; // copying the old datas arrayyy
                newWorkHourInfo[index].endTime = e; //key and value
                newWorkHourInfo[index].endsAt =  e.format('HH:mm:ss'); //key and value
                setWeekHourInfo(newWorkHourInfo);

        } else {
            let newWorkHourInfo = [...weekHourInfo]; // copying the old datas array
            newWorkHourInfo[index].endTime = null; //key and value
            newWorkHourInfo[index].endsAt = ''; //key and value
            setWeekHourInfo(newWorkHourInfo);
        }
    };

   /* const convertTime12To24 = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes,period] = time.split(':');
        if (hours === '12') {
        hours = '00';
        }
        if (modifier?.toUpperCase() === 'PM') {
        hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}:${period}`;
    };*/

    /*const convert24hourTo12HourFormat = (time) => {
       const time_part_array = time.split(":");
       let ampm = 'am';
       if (time_part_array[0] >= 12) {
           ampm = 'pm';
       }
       if (time_part_array[0] > 12) {
           time_part_array[0] = time_part_array[0] - 12;
       }
       const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' '+ ampm;
       return formatted_time;
    }*/

    const handleVacationDaysDialogDismiss = (res) => {
        console.log('res', res)
        setVacationDaysDialog(false);
        if (res) {
            setvacationListData(res.vacationList?.vacation)
            setvacationStats(res.vacationStats);
            setVacationDates(res.vacationStats?.vacationDates)
        }
   };

   const handleSubmit = () => {
      const isValidForm =  handleValidateFormData();
      console.log('condition',isValidForm);
      if(isValidForm){
          setOnSubmit(false);
            //const finalValue ={"weeklyHours": arrFilter.filter(x => x.locationId)};
           let data = [...weekHourInfo]
               const arrFilter = data.map(function(item) { 
                    delete item.startTime; 
                    delete item.endTime; 
                    return item; 
               });

           const finalValue ={"weeklyHours": arrFilter.filter(x => x.locationId)};
              dispatch<any>(
                     handleSaveEmployeeWeeklyHours(
                         {
                         id :employee.id,
                         weeklyHours:finalValue,
                            callback: (res) => {
                                if(res){
                                    onDismiss('');
                                } else{
                                    setcommonServerError('Failed to Save Data.')
                                }
                         }}));
      }else{
            setOnSubmit(true);
      }
    }

    const handleValidateFormData = () => {
    
          for(let i in weekHourInfo){
                if(!weekHourInfo[i].locationId && (weekHourInfo[i].startsAt || weekHourInfo[i].endsAt))
                    return false;
                else if(!weekHourInfo[i].startsAt && (weekHourInfo[i].locationId || weekHourInfo[i].endsAt))
                    return false;
                else if(!weekHourInfo[i].endsAt && (weekHourInfo[i].locationId || weekHourInfo[i].startsAt))
                    return false;
          }
          return true;
    }
 return (
    <>

        <Stack tokens={{ childrenGap: 10 }}>
              {isOpenVacationDaysDialog && (
                    <AddVaccationDaysDialog
                        isOpen={isOpenVacationDaysDialog}
                        onDismiss={(msg) => handleVacationDaysDialogDismiss(msg)}
                        selectedData={vacationListData}
                        empName={title}
                        empID={employeesID}
                    />
             )} 

           {/* {isOpenVacationDaysDialog && (
                    <TimeOffDialog
                        isOpen={isOpenVacationDaysDialog}
                        onDismiss={(msg) => handleVacationDaysDialogDismiss(msg)}
                    />
             )}*/}
            <Row>
            <Col>
               <div>
                    <Row>
                      <Col>

                      {isMobile ? <>
                           <div >  
                                    {
                                       commonServerError ?
                                           <div>
                                               <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                           </div> : null
                                    }
                                 <Row>
                                    <Col>
                                          <span style={{fontSize:"18px"}} className=" pageName">
                                              {title}
                                          </span>
                                    </Col>
                                 </Row>
                                 <Row>
                                     <Col>
                                         <span style={{fontSize:"20px"}} className=" pageName"> Weekly Recurring Hours </span>
                                     </Col>
                                 </Row>
                                 <div>
                                     <Row >
                                         <Col>
                                                 <div className="  scheduleCalScroll">
                                                     <div >
                                                         <table style={{width:"100%"}}>
                                                            <thead style={{overflowY:"scroll",width:"100%"}}>
                                                                <tr style={{width:"100%"}} className="sheduleCalHeader">
                                                                     <th> 
                                                                        <span> Monday </span>
                                                                     </th>
                                                                     <th> 
                                                                        <span>  Tuesday</span>
                                                                     </th>
                                                                     <th> 
                                                                        <span> Wednesday </span>
                                                                     </th>
                                                                     <th> 
                                                                        <span> Thursday </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Friday  </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Saturday </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Sunday </span>
                                                                     </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                 <tr style={{width:"100%"}}>
                                                                 {
                                                                    weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                                 <label className="lbl-text sheduleCalLocLabel" >Location</label>
                                                                                 <PRDropdown
                                                                                     id={"locationId"+index}
                                                                                     placeholder=""
                                                                                     value={weekHourInfo[index].locationId}
                                                                                     options={cityDealerList}
                                                                                     //onChange={onLocationChange}
                                                                                      onChange={(e) => onLocationChange(e.value, index)}
                                                                                     optionLabel="legalName"
                                                                                     optionValue="id"
                                                                                     appendTo="self"
                                                                                     className="locationDrpTextAlign custom-p-dropdown customInputField  sheduleLocOptionsAlign sheduleCalenderLocationWidth "
                                                                                     filter={ false}
                                                                                     resetFilterOnHide={true}
                                                                                 />
                                                                                <span className="sheduleRecLocReqLabel ">{onSubmit && !weekHourInfo[index].locationId  && (weekHourInfo[index].startsAt || weekHourInfo[index].endsAt) ? "Required":""}</span>
                                                                        </td>))
                                                                  }
                                                                 </tr>
                                                                 <tr style={{width:"100%"}}>
                                                                 {
                                                                    weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                                  <div id={"delivery-section"+index} className="date-time-group " style={{ width: "80px" , display:"inline-block",marginTop:"-30px"}}>
                                                                                                           
                                                                              <div className="auctionStartTime time-picker " style={{ width: "100%" }}>
                                                                                   <label className="lbl-text  registerSuccess sheduleCalLabelMob " >Start</label>
                                                                                   {/*<TimePicker minuteStep={15} showNow={false} 
                                                                                   
                                                                                       //disabled={sheduleCalFormik.values.finance === 'false'}
                                                                                       // use12Hours={true}
                                                                                       //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                                                                       hideDisabledOptions={true}
                                                                                       format="h:mm a"
                                                                                      value={weekHourInfo[index].startTime}
                                                                                       onChange={(time,timeString) => handleStartTimeChange(timeString, index)}
                                                                                       allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                                                                       getPopupContainer={() => document.getElementById("delivery-section"+index)}
                                                                                       disabledSeconds={() => disabledSec}
                                                                                       // value
                                                                                       // onChange function(time: moment, timeString: string):
                                                                                       // onSelect function(time: moment)
                                                                                   /> */}
                                                                                   
                                                                                   <TimePicker
                                                                                      id="startTime"
                                                                                      showSecond={false}
                                                                                      //defaultValue={isStartTime}
                                                                                      className="mask-FieldGroup rc-time-picker-input"
                                                                                      onChange={(e) => handleStartTimeChange(e, index)}
                                                                                      //onOpen={handleTimePanelOpen}
                                                                                      format="h:mm a"
                                                                                      use12Hours
                                                                                      //inputReadOnly
                                                                                      minuteStep={15}
                                                                                      popupClassName="appointment-time-panel"
                                                                                      inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                      placeholder="Time"
                                                                                  />
                                                                               </div>
                                                                            </div>
                                                                                                                                                         <span className=" sheduleRecCalRequiredLabel">  {onSubmit &&  !weekHourInfo[index].startsAt ? ((weekHourInfo[index].endsAt || weekHourInfo[index].locationId) ? "Required":" ") :""}</span>
                                                                        </td>))
                                                                  }   
                                                                 </tr>
                                                                 <tr style={{width:"100%"}}>
                                                                   {
                                                                    weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                                  <div id={"delivery-section"+index} className="date-time-group " style={{ width: "80px" , display:"inline-block",marginTop:"-30px"}}>
                                                                                                           
                                                                              <div className="auctionStartTime time-picker " style={{ width: "100%" }}>
                                                                                   <label className="lbl-text  registerSuccess sheduleCalLabelMob " >End</label>
                                                                                   {/*<TimePicker minuteStep={15} showNow={false} 
                                                                                   
                                                                                       //disabled={sheduleCalFormik.values.finance === 'false'}
                                                                                       // use12Hours={true}
                                                                                       //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                                                                       hideDisabledOptions={true}
                                                                                       format="h:mm a"
                                                                                      value={weekHourInfo[index].startTime}
                                                                                       onChange={(time,timeString) => handleStartTimeChange(timeString, index)}
                                                                                       allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                                                                       getPopupContainer={() => document.getElementById("delivery-section"+index)}
                                                                                       disabledSeconds={() => disabledSec}
                                                                                       // value
                                                                                       // onChange function(time: moment, timeString: string):
                                                                                       // onSelect function(time: moment)
                                                                                   /> */}
                                                                                   
                                                                                   <TimePicker
                                                                                      id="endTime"
                                                                                      showSecond={false}
                                                                                      //defaultValue={isEndTime}
                                                                                      className="mask-FieldGroup rc-time-picker-input"
                                                                                      onChange={(e) => handleEndTimeChange(e, index)}
                                                                                      //onOpen={handleTimePanelOpen}
                                                                                      format="h:mm a"
                                                                                      use12Hours
                                                                                      //inputReadOnly
                                                                                      minuteStep={15}
                                                                                      popupClassName="appointment-time-panel"
                                                                                      inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                      placeholder="Time"
                                                                                  />                             
                                                                               </div>
                                                                            </div> <span className=" sheduleRecCalRequiredLabel">
                                                                                 {
                                                                                  onSubmit && (weekHourInfo[index].startsAt || weekHourInfo[index].locationId) && !weekHourInfo[index].endsAt  && "Required"
                                                                                 }
                                                                               </span>
                                                                        </td>))
                                                                    }   
                                                                 </tr>
                                                            </tbody>              
                                                          </table>     
                                                    </div>
                                                 </div>
                                          </Col>
                                     </Row>                                    
                                 </div>
                                 <Row className="save-row">
				    	           <Col className="savebtn">
                                        {userProfile && userProfile.roles && (
                                           <EmployeeActionPermission
                                               permissionList={userProfile.aggregatedDefaultPolicies}
                                               alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                               roles={userProfile.roles}
                                           >
                                               <PrimaryButton                                                        
				    			                   text="Save Recurring Hours"
				    			                   type="submit"
                                                   className="scheduleSave-btn"
                                                   onClick={handleSubmit}
                                                   style={{marginTop:"20px"}}
				    		                   />
                                           </EmployeeActionPermission>
                                        )}
				    	           </Col>
				                 </Row>
                                 <div  >
                                    <span style={{fontSize:"20px"}} className=" pageName"> Vacation Summary </span><hr/>

                                 </div>
                                 <Row >
				    	           <Col>
			                             <span style={{fontSize:"17px"}} >
                                         { (vacationListData && vacationStats) ? 
                                            vacationListData?.startingMonth +'/'+ vacationListData?.year + ' through ' + Number(vacationListData?.startingMonth - 1)+'/'+Number(vacationListData?.year+1)
                                            + ' - ' + vacationStats.vacationTaken + ' of ' + vacationStats.totalNumberOfVacations +' days taken' : ''
                                        }
                                         </span>
				    	           </Col>
				                 </Row>
                                 <Row className="save-row">
				    	           <Col className="savebtn">
                                        {userProfile && userProfile.roles && (
                                            <EmployeeActionPermission
                                                permissionList={userProfile.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                roles={userProfile.roles}
                                            >
                                                <PrimaryButton
                                                    text="Set Vacation Hours"
                                                    type="submit"
                                                    className="scheduleSave-btn"
                                                    onClick={() => { setVacationDaysDialog(true) }}
                                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                                />
                                            </EmployeeActionPermission>
                                        )}
				    	           </Col>
				                 </Row>
                                 <Row >
				    	           <Col >
			                            <span style={{fontSize:"17px",wordBreak: "break-word"}} >
                                        { vacationDates?.map((data, index) => {
                                            let date = (moment(data).local().format("MM/DD/YYYY"));
                                            return date  + ' ' + ((index < vacationDates.length -1) ? ', ' : '')
                                            })
                                        }
                                         </span>
				    	           </Col>
				                 </Row>
                           </div>
                           </>:<>

                           <div  style={{marginBottom:"20px"}} >  
                                 {
                                       commonServerError ?
                                           <div>
                                               <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                           </div> : null
                                 }
                                 <Row>
                                    <Col id="test">
                                         <span style={{fontSize:"18px"}} className=" pageName">
                                               {title}
                                          </span>
                                    </Col>
                                 </Row>
                                 <Row >
                                     <Col>
                                         <span style={{fontSize:"20px"}} className=" pageName"> Weekly Recurring Hours </span>
                                     </Col>
                                 </Row>
                                 <div>
                                     <Row >
                                         <Col>
                                                 <div >
                                                     <div >
                                                         <table style={{width:"100%"}}>
                                                            <thead style={{overflowY:"scroll",width:"100%"}}>
                                                                <tr style={{width:"100%"}} className="sheduleCalHeader">
                                                                     <th> 
                                                                        <span> Monday </span>
                                                                     </th>
                                                                     <th> 
                                                                        <span>  Tuesday</span>
                                                                     </th>
                                                                     <th> 
                                                                        <span> Wednesday </span>
                                                                     </th>
                                                                     <th> 
                                                                        <span> Thursday </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Friday  </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Saturday </span>
                                                                     </th>
                                                                     <th> 
                                                                       <span> Sunday </span>
                                                                     </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr style={{width:"100%",textAlign:"center"}}>
                                                                     {
                                                                    weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                       <div className="sheduleLocationFormWidth" style={{marginBottom:"20px"}}  >
                                                                                 <label className="lbl-text sheduleCalLocLabel" >Location</label>
                                                                                 <PRDropdown
                                                                                     id={"locationId"+index}
                                                                                     placeholder=""
                                                                                     value={weekHourInfo[index].locationId}
                                                                                     options={cityDealerList}
                                                                                     //onChange={onLocationChange}
                                                                                      onChange={(e) => onLocationChange(e.value, index)}
                                                                                      required={true}
                                                                                     optionLabel="legalName"
                                                                                     optionValue="id"
                                                                                     appendTo="self"
                                                                                     className="locationDrpTextAlign custom-p-dropdown customInputField  sheduleLocOptionsAlign "
                                                                                     filter={ false}
                                                                                     resetFilterOnHide={true}                                                                                   
                                                                                     style={{textAlign:"center"}}  
                                                                                 />
                                                                                 <span className="sheduleRecLocReqLabel ">{onSubmit && !weekHourInfo[index].locationId  && (weekHourInfo[index].startsAt || weekHourInfo[index].endsAt) ? "Required":""}</span>
                                                                                 {/*<span> { ( (weekHourInfo[index].startTime || weekHourInfo[index].endTime) ? (!weekHourInfo[index].locationId ? " Required " :""):"")} </span> */}
                                                                       </div>
                                                                        </td>))
                                                                  }
                                                                 </tr>
                                                                 <tr style={{width:"100%",textAlign:"center"}}>
                                                                   {
                                                                        weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                                  <div id={"delivery-section"+index} className="date-time-group " style={{marginTop:"-30px"}}>
                                                                                                           
                                                                              <div style={{ width: "100%"}} className="auctionStartTime timePickerHeight time-picker sheduleTimePickWidth" >
                                                                                   <label className="lbl-text  registerSuccess  "   >Start </label>
                                                                                   {/*<TimePicker minuteStep={15} showNow={false} 
                                                                                   
                                                                                       //disabled={sheduleCalFormik.values.finance === 'false'}
                                                                                       // use12Hours={true}
                                                                                       //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                                                                       hideDisabledOptions={true}
                                                                                       format="h:mm a"
                                                                                      value={weekHourInfo[index].startTime}
                                                                                       onChange={(time,timeString) => handleStartTimeChange(timeString, index)}
                                                                                       allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                                                                       getPopupContainer={() => document.getElementById("delivery-section"+index)}
                                                                                       disabledSeconds={() => disabledSec}
                                                                                       // value
                                                                                       // onChange function(time: moment, timeString: string):
                                                                                       // onSelect function(time: moment)
                                                                                   /> */}
                                                                                   
                                                                                   <TimePicker
                                                                                      id="startTime"
                                                                                      showSecond={false}
                                                                                      value={weekHourInfo[index].startTime}
                                                                                      className="mask-FieldGroup rc-time-picker-input"   
                                                                                      onChange={(e) => handleStartTimeChange(e, index)}
                                                                                      //onOpen={handleTimePanelOpen}
                                                                                      format="h:mm a"
                                                                                      use12Hours
                                                                                      //inputReadOnly
                                                                                      minuteStep={15}
                                                                                      popupClassName="appointment-time-panel"
                                                                                      inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                      placeholder="Time"
                                                                                  />  

                                                                                 
                                                                               </div>
                                                                            </div>
                                                                             <span className=" sheduleRecCalRequiredLabel">  {onSubmit &&  !weekHourInfo[index].startsAt ? ((weekHourInfo[index].endsAt || weekHourInfo[index].locationId) ? "Required":" ") :""}</span>
                                                                        </td>))
                                                                    }   
                                                                 </tr>
                                                                 <tr style={{width:"100%",textAlign:"center"}}>
                                                                   {
                                                                    weekHourInfo.map((item, index) => (<td key={"boxes-" + index}>
                                                                                  <div id={"delivery-section"+index} className="date-time-group " style={{marginTop:"-30px"}}>
                                                                                                           
                                                                              <div  style={{ width: "100%"}} className="auctionStartTime timePickerHeight time-picker sheduleTimePickWidth" >
                                                                                   <label className="lbl-text  registerSuccess " >End</label>
                                                                                   {/*<TimePicker minuteStep={15} showNow={false} 
                                                                                   
                                                                                       //disabled={sheduleCalFormik.values.finance === 'false'}
                                                                                       // use12Hours={true}
                                                                                       //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                                                                       hideDisabledOptions={true}
                                                                                       format="h:mm a"
                                                                                      value={weekHourInfo[index].startTime}
                                                                                       onChange={(time,timeString) => handleStartTimeChange(timeString, index)}
                                                                                       allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                                                                       getPopupContainer={() => document.getElementById("delivery-section"+index)}
                                                                                       disabledSeconds={() => disabledSec}
                                                                                       // value
                                                                                       // onChange function(time: moment, timeString: string):
                                                                                       // onSelect function(time: moment)
                                                                                   /> */}
                                                                                   
                                                                                   <TimePicker
                                                                                      id="endTime"
                                                                                      showSecond={false}
                                                                                      value={weekHourInfo[index].endTime}
                                                                                      className="mask-FieldGroup rc-time-picker-input"
                                                                                      onChange={(e) => handleEndTimeChange(e, index)}
                                                                                      //onOpen={handleTimePanelOpen}
                                                                                      format="h:mm a"
                                                                                      use12Hours
                                                                                      //inputReadOnly
                                                                                      minuteStep={15}
                                                                                      popupClassName="appointment-time-panel"
                                                                                      inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                                      placeholder="Time"
                                                                                  /> 

                                                                               </div>
                                                                            </div>
                                                                               <span className=" sheduleRecCalRequiredLabel">  {onSubmit &&  !weekHourInfo[index].endsAt ? ((weekHourInfo[index].startsAt || weekHourInfo[index].locationId) ? "Required":" ") :""}</span>
                                                                        </td>))
                                                                    }   
                                                                 </tr>  
                                                            </tbody>              
                                                          </table>     
                                                    </div>
                                                 </div>
                                          </Col>
                                     </Row>                                    
                                 </div>
                                 <Row className="save-row">
				    	           <Col className="savebtn">
                                        {userProfile && userProfile.roles && (
                                            <EmployeeActionPermission
                                                permissionList={userProfile.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                roles={userProfile.roles}
                                            >
                                                <PrimaryButton
                                                    text="Save Recurring Hours"
                                                    type="submit"
                                                    className="scheduleSave-btn"
                                                    onClick={handleSubmit}
                                                    //disabled= { ((!weekHourInfo[0].startsAt || !weekHourInfo[0].locationId || !weekHourInfo[0].endsAt )&& (!weekHourInfo[1].startsAt || !weekHourInfo[1].locationId || !weekHourInfo[1].endsAt)) }
                                                    style={{ marginTop: "20px" }}
                                                />
                                            </EmployeeActionPermission>
                                        )}
				    	           </Col>
				                 </Row>
                                 <Row >
				    	           <Col >
			                            <span style={{fontSize:"20px"}} className=" pageName"> Vacation Summary </span><hr/>
				    	           </Col>
				                 </Row>
              

                                 <Row style={{marginBottom:"10px",marginTop:"10px"}}>
				    	           <Col>
			                             <span style={{fontSize:"17px"}} >
                                         { (vacationListData && vacationStats) ? 
                                            vacationListData?.startingMonth +'/'+ vacationListData?.year + ' through ' + 
                                            (Number((vacationListData?.startingMonth) === 1 ) ? 12 : Number((vacationListData?.startingMonth - 1))) +'/'+ 
                                            (Number((vacationListData?.startingMonth) === 1 ) ? Number(vacationListData?.year) : Number(vacationListData?.year+1))
                                            + ' - ' + vacationStats.vacationTaken + ' of ' + vacationStats.totalNumberOfVacations +' days taken' : ''
                                        }
                                         </span>
				    	           </Col>
				                 </Row>
                                 <Row >
				    	           <Col >
                                   {(vacationListData && vacationStats) ? 
			                           <div style={{marginTop:"-38px",marginBottom:"10px",textAlign:"center"}}>
                                            {userProfile && userProfile.roles && (
                                                <EmployeeActionPermission
                                                    permissionList={userProfile.aggregatedDefaultPolicies}
                                                    alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                    roles={userProfile.roles}
                                                >
                                                    <PrimaryButton
                                                        text="Set Vacation Hours"
                                                        type="submit"
                                                        //className="scheduleSave-btn"
                                                        onClick={() => { setVacationDaysDialog(true) }}
                                                    />
                                                </EmployeeActionPermission>
                                            )}
				    		                 
				    	                </div>
                                        :
                                        <div style={{marginBottom:"10px",textAlign:"center"}}>
                                                {userProfile && userProfile.roles && (
                                                    <EmployeeActionPermission
                                                        permissionList={userProfile.aggregatedDefaultPolicies}
                                                        alias={emplyeePermissionsAction.SETTING_USER_UPDATE}
                                                        roles={userProfile.roles}
                                                    >
                                                        <PrimaryButton
                                                            text="Set Vacation Hours"
                                                            type="submit"
                                                            //className="scheduleSave-btn"
                                                            onClick={() => { setVacationDaysDialog(true) }}
                                                        />
                                                    </EmployeeActionPermission>
                                                )}
				    	                </div>
                                    }
				    	           </Col>
				                 </Row>

                                 <Row >
				    	           <Col >
			                            <span style={{fontSize:"17px",wordBreak: "break-word"}} >
                                        { vacationDates?.map((data, index) => {
                                            let date = (moment(data).local().format("MM/DD/YYYY"));
                                            return date  + ' ' + ((index < vacationDates.length -1) ? ', ' : '')
                                            })
                                        }
                                         </span>
				    	           </Col>
				                 </Row>
                           </div>
                                              
                           </>}                                              
                                             

                      </Col>
                    </Row>        
                </div>
            </Col>
        </Row>

      </Stack>    
    </>)
};

