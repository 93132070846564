import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { colors } from "constants/constants";
// import ConfigureGroupAssignTable from "components/table/configureGroup/ConfigureGroupAssignTable";
import NewConfigureGroupAssignTable from "components/table/configureGroup/NewConfigureGroupAssignTable";
// import ConfigureGroupAssignedTable from "components/table/configureGroup/ConfigureGroupAssignedTable";
import NewConfigureGroupAssignedTable from "components/table/configureGroup/NewConfigureGroupAssignedTable";
import { useSelector } from "react-redux";
import { AppState } from "store";

const border = {
  borderTop: `1px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};

interface IProps {
  employeesToAdd?: (employees: any) => void;
  employeesToRemove?: (employees: any) => void;
}

export const ConfigureGroupPivot: React.FC<IProps> = ({ employeesToAdd, employeesToRemove }) => {
  const selectedGroupEmployees = useSelector(
    (state: AppState) => state.groups.selectedGroup?.employees
  );

  return (
    <Pivot style={{ padding: 0, margin: 0 }} onLinkClick={(item: any) => {}}>
      <PivotItem
        className="pivotItem"
        style={border}
        headerText="Assign Employees to Group"
        headerButtonProps={{
          "data-order": 2,
          "data-title": "Company information",
        }}
      >
        <NewConfigureGroupAssignTable onSelection={employeesToAdd}/>
      </PivotItem>
      <PivotItem
        className="pivotItem"
        style={border}
        headerText={`Assigned Employees (${selectedGroupEmployees?.length})`}
      >
        <NewConfigureGroupAssignedTable employeesToRemove={employeesToRemove} />
      </PivotItem>
    </Pivot>
  );
};
