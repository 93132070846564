import React, { useEffect, useState, FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
    //Checkbox,
    Dropdown
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
//import { useNavigate  } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { handleGetDTVehicleTrimUsingVin, handleGetDealerTradeVehDetails ,handleGetDealerTradeInfo } from "store/dealTracker/dealTracker.action";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { Link } from "@fluentui/react/lib/Link";
// import { handleGetdataOneMakes, handleGetdataOneModels } from "store/shared/shared.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import VinScanner from "components/dialogs/barCodeScanner/VinScanner.js";
import { trimTypes } from "constants/constants";
// import { VinCameraSelection} from "components/dialogs/vinCameraSelection/VinCameraSelection";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { useMediaQuery } from "react-responsive";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (vehicleId, id?, vin?) => void;
    onSave: (res?) => void;
    openTrimDialog: ({ vin, trim, ymm, id }) => void;
    vehicleAction: string;
    dealerTradeId: number;
    tradeType: number;
    addVehicleSection?: number;
    vehicleInfo:any;
    trimType?: number;//1-Non-Karl, 2-Karl, 3-TradeIn
}

const yearValues = dropDownsValues.yearNewValues.map((item) => {
    return { key: item, text: item };
});

export const AddVehicleSearchDialog: FunctionComponent<IProps> = ({
    onDismiss,
    onSave,
    openTrimDialog,
    dealerTradeId,
    tradeType,
    vehicleInfo,
    addVehicleSection,
    vehicleAction,
    trimType = trimTypes.karl
}) => {

    const [vehicleType, setvehicleType] = useState('vin');
    // const [makeValues, setMakeValues] = useState([]);
    // const [modelValues, setModelValues] = useState([]);
    const [isInventoryYMM, setisInventoryYMM] = useState(false);
    const [isVINScan, setisVINScan] = useState(false);
    // const [vinScanConfig, setvinScanConfig] = useState('barcode');
    const [vinDataError, setvinDataError] = useState(false);
    const [trimError, setTrimErrorr] = useState(false);
    const [ymmError, setYmmErrorr] = useState(false);
    const dispatch = useDispatch();
    //const navigate = useNavigate ();
    //const [showModels, setShowModels] = useState(false);
    // const [isOrderChecked, setIsOrderChecked] = React.useState(false);
    // const [vinCameraDialog, setvinCameraDialog] = React.useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [vehicleDetails, setVehicleDetails] = useState(null);

  //  const dataOneModels: any = useSelector(
  //      (state: AppState) => state.shared.dataOneModels
  //  );
  // 
  //  const dataOneMakes: any = useSelector(
  //      (state: AppState) => state.shared.dataOneMakes

    useEffect(()=>{
        dispatch<any>(handleGetDealerTradeInfo({
            id:dealerTradeId, callback: (res) => {
                if (res) {

                   if(vehicleAction === 'vehicleIn'){
                       const vehIn = res.vehicleIn ? res.vehicleIn : null;                   
                       setVehicleDetails(vehIn);
                   }else if(vehicleAction === 'vehicleOut'){

                      const vehOut = res.vehicleOut ? res.vehicleOut : null;
                      setVehicleDetails(vehOut);
                   }
    
                }
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  //  );

    useEffect(() => {
        if(addVehicleSection === 1 && isKarlProfileType ){
           setisInventoryYMM(true)
        } else if(addVehicleSection === 2 && isKarlProfileType ){
          setisInventoryYMM(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVehicleSection]);

    const userProfile: any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);

    Yup.addMethod(Yup.string, 'vinOnly', function (message) {
         return (this.max(50,'Must be less than 50 character'))
    });

    Yup.addMethod(Yup.object, 'modelOnly', function (message) {
        return (vehicleType === 'make');
    });
  
    Yup.addMethod(Yup.number, 'yearOnly', function (message) {
        return (vehicleType === 'make');
    });

    const formik = useFormik({
        initialValues: {
            make: "",
            model: "",
            //year: null,
            year: new Date().getFullYear(),
            mileage: "",
            vin: "",
            nickName: "",
            vehicletype: "vin",
            startTime: "",
            url: "",
            sourceType: "auction",
            searchCRM: "",
            dealerSourceName: "",
            privateSourceName: "",
            dealID: "",
            dealerhipsName: "",
            carID: "",
            shippingFrom: "",
            orderNumber: "",
            stockNumber: ""
        },
        validationSchema: Yup.object({
            make: Yup.string().required("Required"),
            model: Yup.string().required("Required"),
            nickName: Yup.string().required("Required"),
            year: Yup.number().yearOnly(),
            mileage: Yup.string(),
            vin: Yup.string(),
            stockNumber: Yup.string(),
            vehicletype: Yup.string().required("Required"),
            orderNumber:  Yup.string(),
        }),
        onSubmit: (values) => {
        },
    });

    useEffect(() => {
        
        if(!vehicleDetails?.isAddedByVin && isInventoryYMM ){
            formik.setValues(
                {
                    ...formik.values,
                    make: vehicleInfo?.make ? vehicleInfo?.make: '',
                    model: vehicleInfo?.model ? vehicleInfo?.model : '',
                    year: vehicleInfo?.year ? vehicleInfo?.year : new Date().getFullYear(),
                    vin: vehicleInfo?.vin ? vehicleInfo?.vin: '',
                    orderNumber: vehicleInfo?.orderNumber ? vehicleInfo?.orderNumber: '',
                    stockNumber: vehicleInfo?.stockNumber ? vehicleInfo?.stockNumber: '',
                }
            );
           //if(vehicleInfo?.orderNumber){
           //    setIsOrderChecked(true)
           //}
           
        } else{
            formik.setValues(
            {
               ...formik.initialValues
            
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleInfo,vehicleDetails,isInventoryYMM]);

   // useEffect(() => {
   //     if (dataOneMakes.length > 0) {
   //         const values = dataOneMakes.map((item) => {
   //             return { key: item.label, text: item.label };
   //         });
   //         setMakeValues(values);
   //     } else {
   //         setMakeValues([]);
   //     }
   //
   // }, [dataOneMakes]);

   // useEffect(() => {
   //
   //     if (dataOneModels.length > 0) {
   //         const values = dataOneModels.map((item) => {
   //             return { key: item.label, text: item.label };
   //         });
   //         setModelValues(values);
   //     } else {
   //         setModelValues([]);
   //     }
   //     // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [dataOneModels]);

   //  useEffect(() => {
   //      if (formik.values.year) {
   //          dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
   //      }
   //      // eslint-disable-next-line react-hooks/exhaustive-deps
   //  }, [formik.values.year]);

   // useEffect(() => {
   //     if (formik.values.make !== "") {
   //         //setShowModels(true);
   //
   //         //formik.setFieldValue("model", null);
   //         // dispatch<any>(handleGetdataOneModels({
   //         //     make: formik.values.make?.toString(),
   //         //     year: formik.values.year
   //         // }));
   //     }
   //     // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [formik.values.make]);

   useEffect(() => {
        setvehicleType('vin');
        // dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddVehicle = () => {

        setvinDataError(false);
        setTrimErrorr(false);
        setYmmErrorr(false)
        dispatch<any>(handleGetDTVehicleTrimUsingVin({
            vin: formik.values.vin,
            trimType,
            id: dealerTradeId,
            tradeType,
            callback: (trimData) => {
                if (trimData.status) {
                    setvinDataError(true);
                }
                else if (!trimData.status) {
                    if (trimData.style.length === 0) {
                        setvinDataError(true);
                    } else if (trimData.style.length > 1 || (isKarlProfileType && trimType === trimTypes.karl)) {
                        const values = trimData.style.map((item, index) => {
                            return { key: item, text: item };
                        });
                        // settrimValues(values);
                        openTrimDialog({
                            vin: formik.values.vin,
                            trim: values,
                            ymm: null,
                            id: dealerTradeId ? dealerTradeId : trimData.id
                        });

                    } else if (trimData.style.length === 1) {
                        let vinTrim = {
                            style: trimData.style[0],
                            vin: formik.values.vin
                        }
                        dispatch<any>(handleGetDealerTradeVehDetails({
                            vehDet: vinTrim,
                            trimType,
                            tradeType,
                            id: dealerTradeId ? dealerTradeId : trimData.id,
                            action: vehicleAction,
                            callback: (success) => {
                                if (success) {
                                    if (!dealerTradeId)
                                        success["dealerTradeId"] = trimData.id;            
                                    onSave(success);
                                } else {
                                    setvinDataError(true);
                                }
                            }
                        }));
                    }
                } else {
                    if (trimData.status === 400) {
                        onDismiss(trimData.data.data, dealerTradeId ? dealerTradeId : trimData.id, formik.values.vin);
                    } else if (trimData.status === 500) {
                        setTrimErrorr(true);
                    }
                    else {
                        setvinDataError(true);
                    }
                }
            }
        }));
    };

  // const handleAddYmmVehicle = () => {
  //     
  //     setvinDataError(false);
  //     setTrimErrorr(false);
  //     //let makerID = document.getElementById("make_addInventory");
  //     //let makerVal: any = makerID.getElementsByClassName('p-inputtext')[0]?.innerHTML;
  // 
  //     let ymm = {
  //         make: formik.values.make,
  //         model: formik.values.model,
  //         year: formik.values.year
  //     };
  //
  //     if (isKarlProfileType && trimType === trimTypes.karl) {
  //         ymm["orderNumber"] = formik.values.orderNumber
  //     }
  // 
  //     dispatch<any>(handleGetDTVehicleTrimUsingYmm({
  //         ymm: ymm,
  //         trimType,
  //         id: dealerTradeId,
  //         tradeType,
  //         callback: (trimData) => {
  //             // const id = dealerTradeId ? dealerTradeId : trimData.id;
  //             if (trimData) {
  //                 if (trimData.length === 0) {
  //                     setvinDataError(true);
  //                 } else if (trimData.length > 1) {
  //                     const values = trimData.map((item, index) => {
  //                         return { key: item, text: item };
  //                     });
  // 
  //                     // settrimValues(values);
  //                     openTrimDialog({
  //                         vin: null,
  //                         trim: values,
  //                         ymm: ymm,
  //                         id: dealerTradeId ? dealerTradeId : trimData.id
  //                     });
  //                 } else if (trimData.length === 1) {
  //                     let vinTrim = {
  //                         style: trimData[0],
  //                         ...ymm
  //                     }
  //                     dispatch<any>(handleGetDealerTradeVehDetails({
  //                         vehDet: vinTrim,
  //                         trimType,
  //                         tradeType,
  //                         id: dealerTradeId ? dealerTradeId : trimData.id,
  //                         action: vehicleAction,
  //                         callback: (success) => {
  //                             if (success) {
  //                                 if (!dealerTradeId)
  //                                     success["dealerTradeId"] = trimData.id;
  //                                 onSave(success);
  //                             } else {
  //                                 setvinDataError(true);
  //                             }
  //                         }
  //                     }));
  //                 }
  //             } else {
  //                 setvinDataError(true);
  //             }
  //         }
  //     }))
  // };


  const handleAddYmmVehicle = () => {
      setvinDataError(false);
      setTrimErrorr(false);
      setYmmErrorr(false)
  
      let ymm = {
          make: formik.values.make,
          model: formik.values.model,
          year: formik.values.year,
          vin: formik.values.vin
      };
  
      // if (makerVal) {
      //     ymm.make = makerVal;
      // }
      if (isKarlProfileType && trimType === trimTypes.karl) {
          ymm["orderNumber"] = formik.values.orderNumber;
          ymm["stockNumber"] = formik.values.stockNumber;
      }
      

      dispatch<any>(handleGetDealerTradeVehDetails({
          vehDet: ymm,
          trimType,
          tradeType,
          id: dealerTradeId,
          action: vehicleAction,
          callback: (success) => {
              if (success.id) {
                  if (!dealerTradeId)
                      success["dealerTradeId"] = success.dealerId;
                  onSave(success);
              } else {
                  setYmmErrorr(true);
              }
          }
      }));
                  
  
  };

    //const handleMakeChange = (e) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            make: e.value ? e.value : '',
    //        }
    //    );
    //};

  // const handleMakeChange = (value) => {
  //     formik.setValues(
  //         {
  //             ...formik.values,
  //             make: value ? value : '',
  //         }
  //     );
  // };

    const handleYearChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                year: value ? value : '',
                make: '',
                model: ''
            }
        );
        //setModelValues([]);
        //setShowModels(false);
    };

   //  const handleModelChange = (val) => {
   //
   //      formik.setValues(
   //          {
   //              ...formik.values,
   //              model: val ? val : '',
   //          }
   //      );
   //  };

    //  const handleYearChange = (e) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            year: e.value ? e.value : '',
    //            make: '',
    //            model: ''
    //        }
    //    );
    //    setModelValues([]);
    //    setShowModels(false);
    //};
    //
    //const handleModelChange = (e) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            model: e.value ? e.value : '',
    //        }
    //    );
    //};

    const handleVINYmm = (bool) => {
        setvinDataError(false);
        setTrimErrorr(false);
        setYmmErrorr(false)
        setisInventoryYMM(bool);
        if (bool) {
            formik.setValues(
                {
                    ...formik.values,
                    vin: '',
                }
            );
        } else {
            formik.setValues(
                {
                    ...formik.values,
                    year: new Date().getFullYear() + 1,
                    make: '',
                    model: '',
                    orderNumber: ''
                }
            );
            //setModelValues([]);
            //setShowModels(false);
            //setIsOrderChecked(false)
        }
    };

    const handleScanVIN = (bool, fromCamera = false) => {
        if (fromCamera) {
            // setvinCameraDialog(true);
        }
        setvinDataError(false);
        setTrimErrorr(false);
        setYmmErrorr(false)
        setisVINScan(bool)
    }

    const onScanSubmit = (vin) => {
        let capturedVIN = vin.split(',');
        // When scanned with QR Code, contains multiple options. so picking VIn data alone
        let truncatedVIn = capturedVIN[0];
        if (capturedVIN[0].length === 18) {
            // When contains LEADING letter I
            truncatedVIn = capturedVIN[0].substring(1);
        }
        formik.setFieldValue("vin", truncatedVIn.trim().toUpperCase());
        handleScanVIN(false);
    }

    const changeToUppercase = (e: any) => {
        const re = /^[a-zA-Z0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            //this.setState({ value: e.target.value })
            formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
        }
    };

    const onBackDismiss = () => {
        if (isVINScan) {
            handleScanVIN(false)
        }
        else {
            onDismiss('')
        }
    }

   // const handleOrderChange = React.useCallback(
   //     (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
   //         setIsOrderChecked(checked);
   //     },
   //     [],
   // );

    /* const vinCameraAction = (action) => {
        setvinCameraDialog(false);
        if (action === 'ocr') {
            setvinScanConfig(action);
            setisVINScan(true)
        } else {
            setvinScanConfig(action);
            setisVINScan(true)
        }
    } */

    return (
        <Modal
            isOpen={true}
            onDismiss={onBackDismiss}
            isBlocking={true}
            className={isKarlProfileType && trimType === trimTypes.karl ? "ymm-dialog-karl" : "ymm-dialog"}
            containerClassName={`${contentStyles.container} inv-srch-mod ${isVINScan ? 'scanner' : ''}`}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onBackDismiss} />*/}
                <CustomFontText className="formTitle">Add Vehicle</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onBackDismiss}
                />
            </div>

            {/* { vinCameraDialog ?
                <VinCameraSelection
                    loading={false}
                    description="Vin Camera"
                    isOpen={vinCameraDialog}
                    onAction={vinCameraAction}
                    onDismiss={() => setvinCameraDialog(false)}
                /> : null
            } */}
            <div className={`${contentStyles.body} inv-srch-bdy body-auto-hight`}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <div className={isInventoryYMM ? "ymm-body addInventoryDialog" : "vin-body-new addInventoryDialog"}>
                        {
                            vinDataError ?
                                <div>
                                    <Row>
                                        <Col>
                                            <p className="genericErrorColor"><span>VIN is not recognized, please validate and re-enter VIN.</span></p>
                                            <p className="genericErrorColor"> <span>   Note:  Heavy duty trucks, specialty vehicles, and other VINs may not decode. Click "Add vehicle manually" to add these vehicles. </span> </p>
                                        </Col>
                                    </Row>
                                </div> : null
                        }
                        {
                            ymmError ?
                                <div>
                                    <Row>
                                        <Col>
                                            <p className="genericErrorColor"><span>VIN is not recognized, please validate and re-enter VIN.</span></p>
                                        </Col>
                                    </Row>
                                </div> : null
                        }
                        {
                            trimError ?
                                <div>
                                    <Row>
                                        <Col>
                                            <p className="genericErrorColor"><span>Faild to fetch data.</span></p>
                                        </Col>
                                    </Row>
                                </div> : null
                        }
                        {isInventoryYMM ?
                             <div>
                             { (isVINScan && isKarlProfileType) ?
                                 <>
                                      <VinScanner
                                        vinSelection={"barcode"}
                                        onScanSubmit={(vin) => onScanSubmit(vin)} />
                                 </>
                                 :
                                 <>
                                    {isKarlProfileType && trimType === trimTypes.karl && (
                                       <Row className="inventoryRowSection" justify="center">
                                           
                                           <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                                               <TextField
                                                   id="stockNumber"
                                                   name="stockNumber"
                                                   label="Stock No"
                                                   className="employeeeSearchLabelText"
                                                   value={formik.values.stockNumber}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   errorMessage={
                                                       formik.touched.stockNumber &&
                                                       formik.errors.stockNumber &&
                                                       formik.errors.stockNumber.toString()
                                                   }
                                               />
                                           </Col>
                                       </Row>
                                    )}
                                    {isKarlProfileType && trimType === trimTypes.karl && (
                                       <Row className="inventoryRowSection" justify="center">
                                            {/*}<Col sm={10} md={isOrderChecked ? 4 : 10} xs={12} style={{ paddingRight: "0px" }}>
                                               <Checkbox className="padding-0" defaultChecked={(!vehicleDetails?.isAddedByVin && vehicleInfo?.orderNumber)?true:false} onChange={handleOrderChange} label={isOrderChecked ? "Order Out" : "Order Out - New Vehicle"} />
                                           </Col>*/}
                                            <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                                               <TextField
                                                   id="orderNumber"
                                                   name="orderNumber"
                                                   label="Order No"
                                                   className="employeeeSearchLabelText"
                                                   value={formik.values.orderNumber}
                                                   onChange={formik.handleChange}
                                                   onBlur={formik.handleBlur}
                                                   errorMessage={
                                                       formik.touched.orderNumber &&
                                                       formik.errors.orderNumber &&
                                                       formik.errors.orderNumber.toString()
                                                   }
                                               />
                                           </Col>
                                       </Row>
                                    )}
                                    {isMobile ?

                                    <div>

                                        <Row className="inventoryRowSection" justify="center">
                                            <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                <Dropdown
                                                    id="model_addInventory"
                                                    label="Year"
                                                    selectedKey={formik.values.year}
                                                    required={true}
                                                    onChange={(e, item) => handleYearChange(item.key)}
                                                    placeholder="Select a Year"
                                                    options={yearValues}

                                                />
                                            </Col>
                                        </Row>

                                        <Row className="inventoryRowSection" justify="center">
                                            <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                {/*}<Dropdown
                                                    id="make_addInventory"
                                                    label="Make"
                                                    selectedKey={formik.values.make}
                                                    required={true}
                                                    onChange={(e, item) => handleMakeChange(item.key)}
                                                    placeholder="Select a Make"
                                                    options={makeValues}
                                                //disabled={!showModels}
                                                />*/}
                                                <TextField
										            id="make"
								                	name="make"
								                	label="Make"
								                	placeholder=""
                                                    className="employeeeSearchLabelText"
								                	value={formik.values.make}
								                	required={true}
								                	onChange={formik.handleChange}
								                	onBlur={formik.handleBlur}
								                	
								                />
                                            </Col>
                                        </Row>
                                        <Row className="inventoryRowSection" justify="center">
                                            <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                {/*}<Dropdown
                                                    id="model_addInventory"
                                                    label="Model"
                                                    selectedKey={formik.values.model}
                                                    required={true}
                                                    onChange={(e, item) => handleModelChange(item.key)}
                                                    placeholder="Select a Model"
                                                    options={modelValues}
                                                    disabled={!showModels}
                                                />*/}
                                                <TextField
										            id="model"
								                	name="model"
								                	label="Model"
								                	placeholder=""
                                                    className="employeeeSearchLabelText"
								                	value={formik.values.model}
								                	required={true}
                                                    //disabled={!showModels}
								                	onChange={formik.handleChange}
								                	onBlur={formik.handleBlur}
								                	
								                />
                                            </Col>
                                        </Row>

                                    </div>

                                    :
                                    <div>
                                        <Row className="inventoryRowSection" justify="center">
                                            <Col sm={10} md={10} xs={12} >
                                                <div className="yearMake">
                                                    <h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Year</h5>
                                                    <PRDropdown
                                                        id="model_addInventory"
                                                        value={formik.values.year}
                                                        className="invMakeDropdown labelTextBorder full-width"
                                                        options={yearValues}
                                                        onChange={(e) => handleYearChange(e.value)}
                                                        optionLabel="text"
                                                        optionValue="text"
                                                        scrollHeight="150px"
                                                        virtualScrollerOptions={{ itemSize: 31 }}
                                                        placeholder="Select a Year"
                                                        appendTo="self"
                                                        filter
                                                        resetFilterOnHide={true}
                                                        required={true} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="inventoryRowSection" justify="center">
                                            <Col sm={10} md={10} xs={12}>
                                                <div>
                                                    {/* <ComboBox
                                                     id="make"
                                                     // componentRef={comboBoxRef}
                                                     // defaultSelectedKey="C"
                                                     label="Make"
                                                     required={true}
                                                     allowFreeform
                                                     autoComplete="on"
                                                     options={makeValues}
                                                     onChange={handleMakeChange}
                                                     selectedKey={formik.values.make.key}
                                                   /> */}
                                                    {/* }<h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Make</h5>
                                                    <PRDropdown
                                                        id="make_addInventory"
                                                        value={formik.values.make}
                                                        className="invMakeDropdown labelTextBorder full-width"
                                                        options={makeValues}
                                                        onChange={(e) => handleMakeChange(e.value)}
                                                        optionLabel="text"
                                                        optionValue="text"
                                                        scrollHeight="150px"
                                                        virtualScrollerOptions={{ itemSize: 31 }}
                                                        placeholder="Select a Make"
                                                        appendTo="self"
                                                        filter
                                                        panelClassName="dropdownCustHgt optionPlacementTop makeDropdown"
                                                        resetFilterOnHide={true}
                                                        required={true} />*/}
                                                   <TextField
										                id="make"
								                    	name="make"
								                    	label="Make"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.make}
								                    	required={true}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                    	
								                    />

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="inventoryRowSection" justify="center">
                                            <Col sm={10} md={10} xs={12}>
                                                <div>
                                                    {/* <Dropdown
                                                     id="model"
                                                     label="Model"
                                                     required={true}
                                                     disabled={!showModels}
                                                     // selectedKeys={
                                                     //   formik.values.model ? formik.values.model : undefined
                                                     // }
                                       
                                                     selectedKey={formik.values.model.key}
                                                     // multiSelect
                                                     onChange={handleModelChange}
                                                     placeholder=""
                                                     options={modelValues}
                                                     styles={dropdownStyles}
                                                     onBlur={formik.handleBlur}
                                                     errorMessage={
                                                       formik.touched.model && formik.errors.model
                                                         ? formik.errors.model.text.toString()
                                                         : null
                                                     }
                                                   /> */}
                                                   {/*} <h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Model</h5>
                                                    <PRDropdown
                                                        id="model_addInventory"
                                                        value={formik.values.model}
                                                        className="invMakeDropdown labelTextBorder full-width"
                                                        options={modelValues}
                                                        onChange={(e) => handleModelChange(e.value)}
                                                        optionLabel="text"
                                                        optionValue="text"
                                                        scrollHeight="150px"
                                                        virtualScrollerOptions={{ itemSize: 31 }}
                                                        placeholder="Select a Model"
                                                        appendTo="self"
                                                        disabled={!showModels}
                                                        required={true}
                                                        filter
                                                        resetFilterOnHide={true}
                                                        panelClassName="dropdownCustHgt optionPlacementTop"
                                                    />*/}
                                                    <TextField
										                id="model"
								                    	name="model"
								                    	label="Model"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.model}
								                    	required={true}
                                                        //disabled={!showModels}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                    	
								                    />
                                                    
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    
                                    }
                                    { isKarlProfileType ?  
                                         <div>
                                             <Row className="inventoryRowSection" justify="center">
                                                <Col sm={10} md={10} xs={12} >
                                                     <TextField
                                                         id="vin"
                                                         name="vin"
                                                         label="VIN"
                                                         iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true, true) }}
                                                         value={formik.values.vin}
                                                         className="employeeeSearchLabelText"
                                                         onChange={changeToUppercase}
                                                         onBlur={formik.handleBlur}
                                                         errorMessage={
                                                             formik.touched.vin &&
                                                             formik.errors.vin &&
                                                             formik.errors.vin.toString()
                                                         }
                                                     />
                                                 </Col>
                                             </Row>
                                             
                                         </div>
                                            
                                     :''}
                                    
                                    <Row className="noVin" justify="center">
                                        <Col sm={10} md={10} xs={12}>
                                            <Link onClick={() => handleVINYmm(false)}>
                                                Add vehicle by VIN
                                    </Link>
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection ymm" justify="center">
                                        <Col sm={10} md={10} xs={12} className="submitBtn">
                                            <PrimaryButton
                                                className="decodeBtn"
                                                disabled={!formik.values.make || !formik.values.model}
                                                // onRenderIcon={renderSpinner}
                                                text="Save"
                                                onClick={handleAddYmmVehicle}
                                            />
                                        </Col>
                                    </Row>
                                 </> }
                             </div>
                            : 
                            
                            (isVINScan ?
                                (<VinScanner
                                    vinSelection={"barcode"}
                                    onScanSubmit={(vin) => onScanSubmit(vin)} />)
                                :
                                <div>
                                    <Row className="inventoryRowSection inventorySplitRow autoWidthInput" justify="center">
                                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                            <TextField
                                                id="vin"
                                                name="vin"
                                                label="VIN"
                                                iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true, true) }}
                                                value={formik.values.vin}
                                                onChange={changeToUppercase}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched.vin &&
                                                    formik.errors.vin &&
                                                    formik.errors.vin.toString()
                                                }
                                            // style={{ width: "calc(100% - 130px)" }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="noVin" justify="center">
                                        <Col sm={11} md={11} xs={12}>
                                            <Link onClick={() => handleVINYmm(true)}>
                                                Add vehicle manually
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="inventoryRowSection ymm" justify="center">
                                        <Col sm={11} md={11} xs={12} className="submitBtn">
                                            <PrimaryButton
                                                disabled={formik.errors.vin || !formik.values.vin ? true : false}
                                                className="decodeBtn"
                                                // onRenderIcon={renderSpinner}
                                                text="Search"
                                                onClick={handleAddVehicle}
                                            />
                                        </Col>
                                    </Row>

                                </div>
                            )
                        }

                    </div>
                </Stack>
            </div>


        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
