import React, { Component } from "react";
import { Stack, DefaultButton } from "@fluentui/react";
import "./Dropzone.css";
import { colors } from "constants/constants";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import uploadPhoto from "assets/uploadPhoto.svg";

interface IProps {
  disabled?: boolean;
  // onFilesAdded: (array) => void;

  // files: any[];
  initFiles: string[];
  setFiles: (files) => void;

  filesToAdd: any[];
  setFilesToAdd: (files) => void;

  filesToRemove?: any[];
  setFilesToRemove?: (files) => void;
}

interface IState {
  hightlight: boolean;
  files: string[];
  videoFileError: boolean;
  fileErrorMsg: string;
}

class AddInventoryVideoDropzone extends Component<IProps, IState> {
  private readonly fileInputRef;

  constructor(props) {
    super(props);
    this.state = {
      hightlight: false,
      // files: [],
      files: [],
      videoFileError: false,
      fileErrorMsg: ""
    };

    this.fileInputRef = React.createRef<HTMLInputElement>();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
  }

  componentDidMount() {
    this.setState({
      files: [...this.props.initFiles],
    });
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    console.log('filesss', this.state.files);
    console.log('filesss 2', evt.target.files);
    
    var fileExt = files[0].type;
    if (fileExt.indexOf("video") < 0) {
      this.setState({
        videoFileError: true,
        fileErrorMsg: "Unsupported Video Format."
      });
      return;
    } else {
      this.setState({
        videoFileError: false,
        fileErrorMsg: ""
      });
    }
    
    if (this.state.files.length) {
      this.setState({
        videoFileError: true,
        fileErrorMsg: "Not more than 1 Video are allowed to upload."
      });
      return;
    } else {
      this.setState({
        videoFileError: false,
        fileErrorMsg: ""
      });
    }
    
    const newFiles = [];
    const filesToSend = [];

    for (const file of files) {
      // var validExts = Array.from(["video"]);
      
      filesToSend.push(file);
      newFiles.push(URL.createObjectURL(file));
    }

    console.log(newFiles);
    console.log(filesToSend);
    

    const filesToSet = [...this.state.files, ...newFiles];

    //this.props.setFiles(filesToSet);
    this.setState({
      files: filesToSet,
    });

    // console.log('event',evt.target.files)
    evt.target.files = undefined;

    //
    this.props.setFiles(filesToSet);

    this.props.setFilesToAdd(filesToSend);
  }

  onDragOver(evt) {
    evt.preventDefault();
    // console.log(evt)

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    //const formData = new FormData();
    // formData.append('avatar', uploadedFile, 'test.jpeg');
    console.log('dropd', event);
    const files = event.dataTransfer.files;

    var fileExt = files[0].type;
    if (fileExt.indexOf("video") < 0) {
      this.setState({
        videoFileError: true,
        fileErrorMsg: "Unsupported Video Format."
      });
      return;
    } else {
      this.setState({
        videoFileError: false,
        fileErrorMsg: ""
      });
    }
    
    if (this.state.files.length) {
      this.setState({
        videoFileError: true,
        fileErrorMsg: "Not more than 1 Video are allowed to upload."
      });
      return;
    } else {
      this.setState({
        videoFileError: false,
        fileErrorMsg: ""
      });
    }
    
    const newFiles = [];
    const filesToSend = [];

    for (const file of files) {
      filesToSend.push(file);
      newFiles.push(URL.createObjectURL(file));
    }
    if (this.props.disabled) return;

    const filesToSet = [...this.state.files, ...newFiles];

    //this.props.setFiles(filesToSet);
    this.setState({
      files: filesToSet,
    });


    this.props.setFiles(filesToSet);

    this.props.setFilesToAdd([...this.props.filesToAdd, ...filesToSend]);

    this.setState({ hightlight: false });
  }

  handleRemoveImage(e: any, file: any) {
    const { filesToRemove, setFilesToRemove } = this.props;
    
    this.setState({
      videoFileError: false,
      fileErrorMsg: ""
    });

    this.setState({
      files: [],
    });
    setFilesToRemove(true);

    if (filesToRemove) {
      let updatedFilesToRemove = [...filesToRemove];
      if (filesToRemove.includes(file)) {
        updatedFilesToRemove = updatedFilesToRemove.filter((f) => f !== file);
      } else {
        updatedFilesToRemove.push(file);
      }
      setFilesToRemove(true);

      (document.getElementById("multifile") as HTMLInputElement).value = "";
    }
  }

  private _checkImagePath(image: string) {
    const img = image.includes("blob")
      ? image
      : `${process.env.REACT_APP_IMAGE_SERVER}/${image}`;

    console.log("img", img);
    return img;
  }

  render() {
    return (
      <>
        <Stack
          style={{ paddingBottom: 5 }}
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <div style={{ fontWeight: "bold", fontSize: 16 }}>Video</div>
           {/*<CustomFontText
            size={"14px"}
            color={colors.grayText}
            className={"optionalLabel"}
            optional
          >
            optional
          </CustomFontText>*/}
        </Stack>
        <div
          className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? "default" : "pointer" }}
        >
          <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            id="multifile"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFilesAdded}
          />
          <Stack horizontalAlign="center" verticalAlign="center">
            <img
              // style={{ backgroundColor: colors.grayText }}
              alt="upload"
              className="Icon"
              src={uploadPhoto}
              // src="baseline-cloud_upload-24px.svg"
            />
            <div style={{ fontSize: 18, color: colors.grayText }}>
              Drag and drop or click here
            </div>
            <div style={{ fontSize: 14, color: colors.grayText }}>
              to upload your Video
            </div>
          </Stack>
        </div>
        <Stack horizontal wrap style={{ marginTop: 20 }}>
          {this.state.files.map((file, index) => {
            console.log("file", file);
            return (
              <Stack key={index} style={{ marginRight: 20 }}>
                <div
                  style={{
                    width: 100,
                    height: 80,
                    backgroundSize: "100% 100%",
                    //background: `#fcfcfc url(${file}) right bottom no-repeat`,
                    //  src={`${require("assets/video-PlayIcon.png")}`}
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${require("assets/video-PlayIcon.png")})`,
                  }}
                />
                {/*<img style={{ width: 100, height: 150 }} src={file} />*/}
                <DefaultButton
                  style={{ marginTop: 10, marginBottom: 10 }}
                  text="Remove"
                  onClick={(e) => this.handleRemoveImage(e, file)}
                />
              </Stack>
            );
          })}
        </Stack>
        {
          this.state.videoFileError ? <p className="genericErrorColor"><span>{this.state.fileErrorMsg}</span></p> : null
        }
      </>
    );
  }
}

export default AddInventoryVideoDropzone;
