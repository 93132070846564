import React  from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    PrimaryButton,
    //IPersonaSharedProps,
   // Persona,
    //PersonaPresence,
    //PersonaSize,
    Link,
    Stack,
    //Checkbox
} from "@fluentui/react";
//import { InputMask } from 'primereact/inputmask';
//import InputMask from "react-input-mask";
import { Pivot, PivotItem } from "@fluentui/react";
//import { useFormik } from "formik";
//import * as Yup from "yup";
//import { useDispatch, useSelector } from "react-redux";
import {useSelector } from "react-redux";
import { colors } from "constants/constants";
import { AppState } from "store";
import CustomTable from "components/table/datatable/CustomTable";
import moment from "moment";
import { AddEditListDialog } from "components/dialogs/selectListDialog/AddEditListDialog";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { useLocation, useNavigate  } from "react-router-dom";
import { 
HANDLE_GET_SELECT_LIST, 
//HANDLE_UPDATE_EMPLOYEE
} from "store/selectList/selectList.types";
import { useDispatch } from "react-redux";
import { EditActivateDeactivateActionsSubmenu } from "components/table/dropdowns/EditActivateDeactivateActionsSubmenu";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { handleActivateSelectListItem, handleDeactivateSelectListItem,handleViewSelectListItem } from "store/selectList/selectList.action";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";

const credentialModuleName = "SelectList";
export const SelectListPage = () => {

    //const dispatch = useDispatch();
    const navigate = useNavigate ();
    const location = useLocation ();
    const serverError = useSelector((state: AppState) => state.selectList.error) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const selectList = useSelector((state: AppState) => state.selectList.selectList) as any;
    const [pageTitle, setPageTitle] = useState('');
    const [addPermission, setAddPermission] = useState('');
    const [viewPermission, setViewPermission] = useState('');
    const [updatePermission, setUpdatePermission] = useState('');
    const [deletePermission, setDeletePermission] = useState('');
    const [category, setCategory] = useState('');

    const border = {
        borderTop: `2px solid ${colors.separator}`,
        width: "100% !important",
        display: "block !important"
    };

    const emptyData = {selectionValue:''};
    const [commonServerError, setcommonServerError] = useState(null);
    const [isOpenListDialog, setIsOpenDialog] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState(emptyData);
    const [credentials, setCredentials] = useState([]);
    const dispatch = useDispatch();
    //const [itemId, setItemId] = useState(0);

    useEffect(() => {
    
       let categoryName;
       if(location.pathname === '/departments'){
       
           setPageTitle('Departments');
           categoryName = 'departments';
           setAddPermission((emplyeePermissionsAction.DEPARTMENT_ADD?.toString()));
           setViewPermission((emplyeePermissionsAction.DEPARTMENT_VIEW?.toString()));
           setUpdatePermission((emplyeePermissionsAction.DEPARTMENT_UPDATE?.toString()));
           setDeletePermission((emplyeePermissionsAction.DEPARTMENT_DELETE?.toString()));
       }
       else 
       if(location.pathname === '/finance-products'){
           setPageTitle('Finance Products');
           categoryName = 'financeProducts';
           setAddPermission((emplyeePermissionsAction.FINANCE_PRODUCT_ADD?.toString()));
           setViewPermission((emplyeePermissionsAction.FINANCE_PRODUCT_VIEW?.toString()));
           setUpdatePermission((emplyeePermissionsAction.FINANCE_PRODUCT_UPDATE?.toString()));
           setDeletePermission((emplyeePermissionsAction.FINANCE_PRODUCT_DELETE?.toString()));
       }else if(location.pathname === '/lenders'){
           setPageTitle('Lenders');
           categoryName = 'lenders';
           setAddPermission((emplyeePermissionsAction.LENDER_ADD?.toString()));
           setViewPermission((emplyeePermissionsAction.LENDER_VIEW?.toString()));
           setUpdatePermission((emplyeePermissionsAction.LENDER_UPDATE?.toString()));
           setDeletePermission((emplyeePermissionsAction.LENDER_DELETE?.toString()));
       }else if(location.pathname === '/appraisal-bidders'){
           setPageTitle('Appraisal Bidders');
           categoryName = 'appraisalBidders';
           setAddPermission((emplyeePermissionsAction.APPRAISAL_BIDDER_ADD?.toString()));
           setViewPermission((emplyeePermissionsAction.APPRAISAL_BIDDER_VIEW?.toString()));
           setUpdatePermission((emplyeePermissionsAction.APPRAISAL_BIDDER_UPDATE?.toString()));
           setDeletePermission((emplyeePermissionsAction.APPRAISAL_BIDDER_DELETE?.toString()));
       }

       if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
       setcommonServerError('');
       setCategory(categoryName);
       dispatch<any>({ type: HANDLE_GET_SELECT_LIST, payload: { isActive: 1,category:categoryName }})

    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    
       if(selectList && selectList.length){
            setTableData(selectList);
       }else{
       setTableData([]);
       }
    },[selectList]);

    useEffect(() => {
        if (user.profileType && !isKarlProfile(user.profileType)){
              navigate('/dashboard');
        }
    },[user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (serverError) {
            setcommonServerError('Failed to Save the Data');
            window.scrollTo(0, 0);
        }
        else {
            setcommonServerError(null);
        }
    }, [serverError]);

     //const handleEdit = (rowData) => {

        //dispatch<any>(
                //handleViewSelectListItem(
                //   {category,
                //   callback: (res) => {
                //       if(res){
                //
                //       }
                //   }})
           // );
        //useDispatch
        //setData(res);
        //Open popup
    //};

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Value</span>
                {rowData.name}          
            </React.Fragment>
        );
    };

    const dateAddedBodyTemplate = (rowData) => {
        let local = null;
        
        if (rowData.updatedAt) {
            var date = moment.utc(rowData.updatedAt).format('YYYY-MM-DD HH:mm:ss');
            var stillUtc = moment.utc(date).toDate();
            local = moment(stillUtc).local().format('MM-DD-YYYY hh:mm A');

        }
        
        return (
            <React.Fragment>
                <span className="p-column-title">Date Added</span>
                {local}
            </React.Fragment>
        );
    };

    const handleSelectListItemActivateDeactivate = (id,isActive) => {
    if(isActive === false){
                dispatch<any>(
                     handleActivateSelectListItem(
                         {category,
                         id,
                         callback: (res) => {
                             
                         }
                        })
                 );
           }else{
                dispatch<any>(
                     handleDeactivateSelectListItem(
                         {category,
                          id,
                          callback: (res) => {
                             
                         }
                         })
                 );
           }
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && (
                    
                    <EmployeeActionPermission
                        permissionList={user.aggregatedDefaultPolicies}
                        alias={viewPermission}
                        roles={user.roles}
                    >
                        <EditActivateDeactivateActionsSubmenu
                            modulePermissions={credentials}
                            itemId={rowData.id}
                            isActive={rowData.isActive}
                            onActivateDeactivate={(id, isActive) => handleSelectListItemActivateDeactivate(id, isActive)}
                            onEdit={(id) => handleEdit(id)}
                            updatePermission={user && isEmployeePermitted(user.aggregatedDefaultPolicie, updatePermission,user.roles)}
                            deletePermission={user && isEmployeePermitted(user.aggregatedDefaultPolicie, deletePermission, user.roles)}
                        />
                    </EmployeeActionPermission>
                )}

               
            </React.Fragment>
        );
    };

    const table_columns_list = {
        "name": "Value",
        "updatedAt": "Date Added",
        "settings": "Actions"
        };

    const navigatetoOpenListDialog  = () => {
        setData(emptyData);
        setcommonServerError('');
        setIsOpenDialog(true);
    }
    const handleEdit = (id) => {
       dispatch<any>(handleViewSelectListItem({category,id,callback:(res) => {
       setData(res);
       setIsOpenDialog(true);
       }}));

       
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            
            const cred = user && isEmployeePermitted(user.aggregatedDefaultPolicie, viewPermission, user.roles); 
            if (cred) {
                handleEdit(event.data.id);
            }
        }
    };
    const handleDialogDismiss  = (msg) => {
      
       if(msg && (typeof msg === 'string' || msg instanceof String)){
           setcommonServerError(msg);
       }
       setIsOpenDialog(false);
    };


    return (
    

    <>

            {
                isOpenListDialog && user
                    && ((user && isEmployeePermitted(user.aggregatedDefaultPolicie, addPermission, user.roles))
                    || (user && isEmployeePermitted(user.aggregatedDefaultPolicie, viewPermission, user.roles))) ?
                    <AddEditListDialog
                        formData={data}
                        title={pageTitle}
                        category={category}
                        addPermission={user && isEmployeePermitted(user.aggregatedDefaultPolicie, addPermission, user.roles)}
                        updatePermission={user && isEmployeePermitted(user.aggregatedDefaultPolicie, updatePermission, user.roles)}
                        onDismiss={(msg) => handleDialogDismiss(msg)}
                    />
                    : null
            }

        <Stack tokens={{ childrenGap: 10 }}>
            <Row className="user-edit-section">
            <Col>
                <Row style={{ marginTop: "25px" }}>
                    <Col>
                        <Link onClick={() => {navigate('/dashboard')}}><ArrowBackIcon /><span className="arrow-back-text">Back</span></Link>
                    </Col>
                </Row>

                <Row>
                    <Col className="alignHeaders">
                        <span className="pageTitleFont pageName">{pageTitle}</span>
                    </Col>
                    {
                        commonServerError ?
                            <Col>
                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                            </Col> : null
                    }
                </Row>
                
                
                <Row className="rowPaddingBtwSpace">
                    <Col>
                        <Pivot
                            style={{ padding: 0, margin: 0 }}
                            className="pivotItem delar-body"
                            defaultSelectedKey="2"
                            onLinkClick={(item?: any) => {
                                  setTableData([]);
                                  setcommonServerError('');
                                   dispatch<any>({ type: HANDLE_GET_SELECT_LIST, payload: { isActive: item.key === '.0'?1:0,category }})
                            }}
                        >
                            <PivotItem
                                className="pivotItem"
                                style={border}
                                headerText="Active Selections"
                                headerButtonProps={{
                                    "data-order": 1,
                                    "data-title": "User Details",
                                }}
                            >
                                <div>
                                    <Row className="btnSpaceAlign margin-12-0">
                                        <Col className="user-action">
                                            {credentials && user.roles && (
                                                <EmployeeActionPermission
                                                    permissionList={user.aggregatedDefaultPolicies}
                                                    alias={addPermission}
                                                    roles={user.roles}
                                                >
                                                    <PrimaryButton
                                                        // disabled={!formik.dirty || !formik.isValid}
                                                        text="Add Selection"
                                                        className="float-left"
                                                        //onClick={() => setIsOpenDialog(true)}
                                                        onClick={navigatetoOpenListDialog}
                                                    />
                                                </EmployeeActionPermission>
                                            )}
                                            
                                        </Col>
                                    </Row>
                                </div>
                                <div className="datatable-filter-demo locations">
                                       <div className="card">
                                           <CustomTable 
                                                 column_list={table_columns_list}
                                                 data={tableData} 
                                                 //header={header}
                                                 // footer={footer}
                                                 customTemplates={{
                                                     "name": nameBodyTemplate,
                                                     "updatedAt": dateAddedBodyTemplate,
                                                     "settings": settingBodyTemplate,
                                                 }}
                                                 emptyMessage="No data found."
                                                 onRowClick={(event)=> handleRowClick(event)}
                                                 //globalFilter={glFilter}
                                            />
                                       </div>
                                </div>
                            </PivotItem>
                            <PivotItem
                                className="pivotItem"
                                style={border}
                                headerText="Inactive"
                                headerButtonProps={{
                                    "data-order": 1,
                                    "data-title": "Inactive",
                                }}
                            > 
                               <div className="datatable-filter-demo locations">
                                       <div className="card">
                                           <CustomTable 
                                                 column_list={table_columns_list}
                                                 data={tableData} 
                                                 //header={header}
                                                 // footer={footer}
                                                 customTemplates={{
                                                     "name": nameBodyTemplate,
                                                     "updatedAt": dateAddedBodyTemplate,
                                                     "settings": settingBodyTemplate,
                                                 }}
                                                 emptyMessage="No data found."
                                                 onRowClick={(event)=> handleRowClick(event)}
                                                 //globalFilter={glFilter}
                                            />
                                       </div>
                                </div>
                                
                            </PivotItem>
                        </Pivot>
                    </Col>
                </Row>
            </Col>
        </Row>

      </Stack>    
    </>)
};

