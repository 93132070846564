import {
  IconButton,
  Modal,
  TextField,
  Toggle,
} from "@fluentui/react";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { Box, StepIconProps, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { themeStyles } from "constants/constants";
import { cancelIcon, contentStyles as content, dropdownStyles, iconButtonStyles } from "constants/styles";
import { useFormik } from 'formik';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { getBrandsForDropdown } from "store/brands/brands.action";
import { HandleUpdateDealerWebsite, handleCreateWebsite } from "store/dealerWebsite/dealerwebsite.action";
import { getDealerWebsiteForDropDown } from "store/dealerWebsite/dealerwebsite.api";
import { getLocationsForDropdown } from "store/locations/locations.api";
import { IBrand } from "types/brandsTypes";
import { IDealerWebsiteResponse } from "types/dealerWebsiteTypes";
import * as Yup from "yup";
import { CustomFontText } from "../../customFontText/CustomFontText";

const contentStyles = content();

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  dealerWebsite?: IDealerWebsiteResponse;
  editable?: boolean;
}


export const AddLaunchDialog: React.FunctionComponent<IProps> = ({
  isOpen,
  onDismiss,
  dealerWebsite,
  editable,
}) => {
  const [selectedWebsite, setSelectedWebsite] = useState("standard");
  const brandOptions: IBrand[] = useSelector(
    (state: AppState) => state.brands.brandOptions?.results
  );
  const [modelValues, setModelValues] = useState([]);
  const [locations, setLocations] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [websites, setWebsite] = useState([{
    key: "",
    text: ""
  }]);

  // const useStyles = makeStyles((theme: ThemeStyle) =>

  //   createStyles({
  //     root: {
  //       width: '100%',
  //     },
  //     button: {
  //       marginRight: theme?.spacing?.(1),
  //     },
  //     instructions: {
  //       marginTop: theme?.spacing?.(1),
  //       marginBottom: theme?.spacing?.(1),
  //     },
  //   }),
  // );

  //const  classes:any  = useStyles();
  const steps = getSteps();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getBrandsForDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (brandOptions?.length > 0) {
      const values = brandOptions.map((item) => {
        return { key: item.id, text: item.name };
      });
      setModelValues(values);
    }
  }, [brandOptions]);

  useEffect(() => {
    if (activeStep === 0) {
      editable === true ? setActiveStep(1) : setActiveStep(0);
    }
    if (typeof dealerWebsite != 'undefined') {
      setSelectedWebsite(dealerWebsite.type);
    }
  }, [activeStep, dealerWebsite, editable]);



  useEffect(() => {
    const fetchLocations = async () => {
      const { data } = await getLocationsForDropdown();
      const options = data?.results.map((item) => {
        return { key: item.id, text: item.legalName };
      });
      setLocations(options);
    };
    fetchLocations().then();
  }, []);

  useEffect(() => {
    const fetchWebsite = async () => {
      const { data } = await getDealerWebsiteForDropDown();
      const standardWeb = data?.results?.filter((web) => web.type === 'standard');
      const options = standardWeb?.map((item) => {
        return { key: item.id, text: item.name };
      });
      setWebsite(options);
    };
    fetchWebsite().then();
  }, []);


  const emptySiteForm = {
    id: editable ? dealerWebsite.id : "",
    name: editable ? dealerWebsite.name : "",
    type: editable ? dealerWebsite.type : "",
    active: editable ? dealerWebsite.isActive : true,
    location: editable ? (dealerWebsite.locations?.length > 0 ? dealerWebsite.locations.map(item => +item.id) : []) : [],
    website: editable ? (dealerWebsite.children?.length > 0 ? dealerWebsite.children.map(item => +item.id) : []) : [],
    domain: editable ? dealerWebsite.domain : "",
    brand_id: editable ? dealerWebsite.brand.id : [],
    canonicalName: editable ? dealerWebsite.canonicalName : "",
    facebook: editable ? dealerWebsite.socialMedia.facebook : "",
    twitter: editable ? dealerWebsite.socialMedia.twitter : "",
    instagram: editable ? dealerWebsite.socialMedia.instagram : "",
    isInventoryShared: editable ? dealerWebsite.isInventoryShared : false,
  };

  //eslint-disable-next-line
  const URL = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

  const formik = useFormik({
    initialValues: {
      ...emptySiteForm
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Website name is required"),
      brand_id: Yup.string().required("Brand is required"),
      location: selectedWebsite === 'standard' ? Yup.string().required("Location is required") : Yup.string(),
      website: selectedWebsite === 'landing' ? Yup.string().required("Website is required") : Yup.string(),
      canonicalName: Yup.string().matches(URL, 'Enter a valid CName!'),
      facebook: Yup.string().matches(URL, 'Enter a Facebook url'),
      twitter: Yup.string().matches(URL, 'Enter a Twitter url'),
      instagram: Yup.string().matches(URL, 'Enter a Instagram url'),
    }),
    onSubmit: (values) => {
      const {
        id,
        name,
        location,
        canonicalName,
      } = values;
      dispatch<any>(
        typeof editable != 'undefined' && editable === true ?
          HandleUpdateDealerWebsite({
            dealerwebsite: {
              id,
              name: values.name,
              type: selectedWebsite,
              location: values.location,
              website: values.website,
              domain: values.domain,
              active: values.active,
              brand_id: values.brand_id,
              canonicalName,
              socialMedia: {
                facebook: values.facebook,
                twitter: values.twitter,
                instagram: values.instagram,
              },
              isInventoryShared: values.isInventoryShared,
            },
            callback: () => {
              onDismiss();
              formik.setValues({ ...emptySiteForm });
              formik.resetForm()
            },
          })
          : handleCreateWebsite({
            dealerwebsite: {
              name,
              type: selectedWebsite,
              location,
              website: values.website,
              domain: values.domain,
              active: values.active,
              brand_id: values.brand_id,
              canonicalName,
              socialMedia: {
                facebook: values.facebook,
                twitter: values.twitter,
                instagram: values.instagram,
              },
              isInventoryShared: values.isInventoryShared,
            },
            callback: () => {
              onDismiss();
              formik.setValues({ ...emptySiteForm });
              formik.resetForm()
            },
          })
      );
      onDismiss();
      // to be removed and fire only when no errors
    },
  });


  // interface Styles {
  //   alternativeLabel: {},
  //   active: {},
  //   completed: {},
  //   line: {}
  // }



  const styles = {
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  };



  // // Define the Props interface for the styled component
  // interface ColorlibConnectorProps {
  //   // Add any props you need for ColorlibConnector
  // }

  // Create the styled component using withStyles
  //const ColorlibConnector = withStyles(styles)(StepConnector) as React.ComponentType<any>;


  function ColorlibStepIcon(props: StepIconProps) {
    // const classes = useColorlibStepIconStyles();
    //const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <SettingsIcon />,
      2: <VideoLabelIcon />,
    };

    return (

      <div style={{ ...styles.root, ...styles.active, ...styles.completed }} >
        {icons[String(props.icon)]}
      </div>

    );
  }

  // const handleOnToggle = (e,item) => {
  //   console.log(e.target.id);
  //   formik.setFieldValue(e.target.id, !formik.values.active);  
  // };


  const options: IChoiceGroupOption[] = [
    { key: 'standard', text: 'Standard' },
    { key: 'landing', text: 'Landing' }
  ];

  function getSteps() {
    return ['Select Website', 'Create a Website'];
  }

  function _onChange(ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption): void {
    console.dir('selection', option);
    setSelectedWebsite(option.key);
  }



  const handleBrandDropdownChange = (e, item) => {
    //    formik.setFieldValue("brand_id", + e.target.value + item.key);
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
      //   false
    );
  };


  const handleDropdownChange = (e, item) => {
    // formik.setFieldValue(e.target.id,typeof e.target.value != 'undefined' ? item.key : + e.target.value + item.key);
    const id = e.target.id;
    let newValues = [...formik.values[id]];

    if (formik.values[id].includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.key);
    } else {
      newValues.push(item.key);
    }

    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: newValues,
      },
      true
    );
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <>
            <div className="pickaWeb">
              <ChoiceGroup defaultSelectedKey={selectedWebsite} options={options} onChange={_onChange} label="Pick a Website" required={true} />
            </div>
          </>
        )
      case 1:
        return (
          <>
            <TextField
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              id="name"
              required={true}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name?.toString()
                  : null
              }
            />
            {selectedWebsite === 'standard' ?
              <Dropdown
                placeholder="Select an option"
                label="Location"
                id="location"
                options={locations}
                multiSelect
                required={true}
                // errorMessage={showError ? 'This dropdown has an error' : undefined}
                styles={dropdownStyles}
                selectedKeys={
                  formik.values.location
                    ? formik.values.location
                    : undefined
                }
                errorMessage={
                  formik.touched.location && formik.errors.location
                    ? formik.errors.location?.toString()
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={handleDropdownChange}
              /> :

              <Dropdown
                placeholder="Select an option"
                label="Websites"
                id="website"
                multiSelect
                options={websites}
                required={true}
                // errorMessage={showError ? 'This dropdown has an error' : undefined}
                styles={dropdownStyles}
                selectedKeys={
                  formik.values.website
                    ? formik.values.website
                    : undefined
                }
                errorMessage={
                  formik.touched.website && formik.errors.website
                    ? formik.errors.website?.toString()
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={handleDropdownChange}
              />
            }

            <Dropdown
              placeholder="Select an option"
              label="Brand"
              id="brand_id"
              options={modelValues}
              // errorMessage={showError ? 'This dropdown has an error' : undefined}
              styles={dropdownStyles}
              onBlur={formik.handleBlur}
              required={true}
              selectedKey={
                formik.values.brand_id
                  ? formik.values.brand_id
                  : undefined
              }
              errorMessage={
                formik.touched.brand_id && formik.errors.brand_id
                  ? formik.errors.brand_id?.toString()
                  : null
              }
              onChange={handleBrandDropdownChange}
            />

            <Toggle label="Set Active "
              // onText="Active" offText="InActive" 
              checked={formik.values.active} id="active"
              onChange={() => {
                formik.setFieldValue("active", !formik.values.active);
              }} />
            {editable ?
              <TextField
                label="Domain"
                prefix=""
                id="domain"
                onChange={formik.handleChange}
                ariaLabel="Example text field with https:// prefix"
                value={formik.values.domain}
                onBlur={formik.handleBlur}
                disabled
              />
              : null}

            <TextField
              label="CName"
              prefix=""
              id="canonicalName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              ariaLabel="Example text field with https:// prefix"
              value={formik.values.canonicalName}
              errorMessage={
                formik.touched.canonicalName && formik.errors.canonicalName
                  ? formik.errors.canonicalName?.toString()
                  : null
              }
            />

            <TextField
              label="Facebook"
              id="facebook"
              prefix="https://"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              ariaLabel="Example text field with https:// prefix"
              value={formik.values.facebook}
              errorMessage={
                formik.touched.facebook && formik.errors.facebook
                  ? formik.errors.facebook?.toString()
                  : null
              }
            />

            <TextField
              label="Twitter"
              id="twitter"
              prefix="https://"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              ariaLabel=""
              value={formik.values.twitter}
              errorMessage={
                formik.touched.twitter && formik.errors.twitter
                  ? formik.errors.twitter?.toString()
                  : null
              }
            />

            <TextField
              label="Instagram"
              id="instagram"
              prefix="https://"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              ariaLabel=""
              value={formik.values.instagram}
              errorMessage={
                formik.touched.instagram && formik.errors.instagram
                  ? formik.errors.instagram?.toString()
                  : null
              }
            />

            {selectedWebsite === 'standard' ?
              <>
                <Toggle label="Share inventory amongst locations? "
                  checked={formik.values.isInventoryShared} id="isInventoryShared"
                  onChange={() => {
                    formik.setFieldValue("isInventoryShared", !formik.values.isInventoryShared);
                  }} />

                {/* <Toggle label="Use different subdomain for each location? "
                  checked={formik.values.sameSubdomain} id="sameSubdomain" 
                  onChange={() => {
                    formik.setFieldValue("sameSubdomain", !formik.values.sameSubdomain);  
                  }} /> */}
              </>
              : null}
          </>
        );
      default:
        return 'Unknown step';
    }
  }

  const handleDismiss = () => {
    onDismiss();
  };




  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const resetModal = () => {
    formik.setValues({ ...emptySiteForm });
    formik.resetForm();
    setActiveStep(0);
    //examplePersona.imageUrl = `${require("assets/image-placeholder.jpg")}`;
    //setPersona(examplePersona);
    onDismiss();

  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={handleDismiss}
        onDismissed={resetModal}
        isBlocking={true}
        containerClassName={`${contentStyles.dealerWebsiteContainer} web-dtls-dgl`}
      >
        <div className={contentStyles.header}>
          <CustomFontText>{editable ? "Update Website" : "Add New Website"}</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={handleDismiss}
          />
        </div>
        <div className={`${contentStyles.launchBody} web-dtls-bdy`}>

          <Box
            sx={{
              flexGrow: 1,
              width: '100%',
              bgcolor: 'background.paper',
              display: 'block',
            }}
          >
            {!editable &&
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            }
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography style={themeStyles.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} style={themeStyles.button}>
                    Reset
                  </Button>
                </div>
              ) : (
                <div>
                  <div style={{ ...themeStyles.instructions, minHeight: "533px" }}>
                    {getStepContent(activeStep)}</div>
                  <div style={{
                    float: "right"
                  }}>
                    {!editable &&
                      <Button disabled={activeStep === 0} onClick={handleBack} style={themeStyles.button}>
                        Back
                      </Button>
                    }

                    {activeStep === steps.length - 1 ? <Button
                      disabled={!formik.isValid || !formik.dirty}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      style={themeStyles.button}
                    >{!editable ? "Finish" : "Update"} </Button> : <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={themeStyles.button}
                    >Next </Button>}

                  </div>
                </div>
              )}
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
};
