import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import {
  addFavourite,
  removeFavourite,
  sendEmailApi,
  sendSMSApi,
  updateFavourite,
  getSMTPSettingsApi,
  updateSMTPSettingsApi,
  testConnectionApi,
  getMakesApi,
  getMakesDataOneApi,
  getModelsDataOneApi,
  getModelsApi,
  getSMSSettingsApi,
  updateSMSSettingsApi
} from "store/shared/shared.api";
import {
  /** pendingAddFavourite */ rejectedAddFavourite,
  resolveAddFavourite,
  /** pendingRemoveFavourite */ resolveRemoveFavourite,
  rejectedRemoveFavourite,
  // handleSendEmail,
  pendingSendEmail,
  rejectedSendEmail,
  resolvedSendEmail,
  pendingSendSMS,
  rejectedSendSMS,
  resolvedSendSMS,
  // handleGetSMTPSettings,
  pendingGetSMTPSettings,
  rejectedGetSMTPSettings,
  resolvedGetSMTPSettings,
  pendingUpdateSMTPSettings,
  // rejectedUpdateSMTPSettings,
  // resolvedUpdateSMTPSettings,
  pendingTestConnection,
  resolvedTestConnection,
  rejectedTestConnection,
  pendingGetMakes,
  resolvedGetMakes,
  resolvedGetDataOneMakes,
  rejectedGetMakes,
  pendingGetModels,
  resolvedGetModels,
  resolvedGetDataOneModels,
  rejectedGetModels,
  pendingGetSMSSettings,
  resolvedGetSMSSettings,
  rejectedGetSMSSettings,
  pendingUpdateSMSSettings,
  resolvedUpdateSMSSettings,
  rejectedUpdateSMSSettings,
} from "store/shared/shared.action";
import * as actionTypes from "store/shared/shared.types";
import {
  HANDLE_ADD_FAVOURITE,
  HANDLE_ZIPCODE_FLATDATA,
  HANDLE_REMOVE_FAVOURITE,
  HANDLE_SEND_EMAIL,
  HANDLE_UPDATE_FAVOURITE,
  HANDLE_SEND_SMS,
  HANDLE_GET_SMTP_SETTINGS,
  HANDLE_UPDATE_SMTP_SETTINGS,
  HANDLE_TEST_CONNECTION,
  HANDLE_GET_MODELS,
  HANDLE_GET_DATAONE_MODELS,
  HANDLE_GET_MAKES,
  HANDLE_GET_DATAONE_MAKES,
  HANDLE_GET_SMS_SETTINGS,
  HANDLE_UPDATE_SMS_SETTINGS,
  HandleUpdateSMSSettings,
  // HandleGetMakes,
  HandleGetModels,
  HandleGetDataOneModels,
  HandleGetDataOneMakes,
  HandleUpdateSMTPSettings,
  // HandleGetSMTPSettings,
  HandleSendSMS,
  HandleSendEmail,
  HandleTestConnection,
  // HandleTestConnection,
} from "store/shared/shared.types";
import { FavouriteEndpoints } from "constants/constants";
import { callBackgroundGetProspects } from "store/prospects/prospects.saga";
import { callBackgroundGetDeals } from "store/deals/deals.saga";
import { callBackgroundGetInventory } from "store/inventory/inventory.saga";
import { IPickerItem } from "types/sharedTypes";
import {titleCase,titleCaseWithArray} from 'utils/helperFunctions'
// import { IHandleFavourite } from "types/sharedTypes";

import zipMapping from "./zipMapping.json";

import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";

function* handleZipCodeFlatData(action: actionTypes.HandleZipCodeFlatData) {
  yield put(switchGlobalLoader(true));
  
  const { zipCode, callback } = action.payload;

  let zipSelectedData = null;
  let zipList: any = zipMapping;
  
  if (zipList && zipList.zipcodes && (zipList.zipcodes.length)) {
    zipSelectedData =  zipList.zipcodes.filter((zipObj) => {
      return (zipObj.zip_code === zipCode);
    })
  }  
  console.log('BEFORE SEG  zipSelectedData', zipSelectedData);
  let zipDetails = null;

  if (zipSelectedData && zipSelectedData.length) {
    let cityList: [] = zipSelectedData.reduce((arr, item) => arr.concat(item['city_name']), []);
    let selectedCity = zipSelectedData[0].city_name;
    let countryList: [] = zipSelectedData.reduce((arr, item) => arr.concat(item['county_name']), []);
    let selectedCounty = zipSelectedData[0].county_name;
    let selectedState = zipSelectedData[0].state_abbrev;
    
    zipDetails = {
      Cities : titleCaseWithArray(Array.from(new Set(cityList))),
      DefaultCity: titleCase(selectedCity),
      Counties : titleCaseWithArray(Array.from(new Set(countryList))),
      DefaultCounty: titleCase(selectedCounty),
      State: selectedState
    };
  }

  console.log('AFTER SEG zipDetails', zipDetails)
  callback(zipDetails);

  yield put(switchGlobalLoader(false));
  return;
}

function* handleAddFavourite(action: actionTypes.HandleAddFavourite) {
  yield put(switchGlobalLoader(true));

  const { handleData, callback } = action.payload;
  console.log(action.payload);

  for (const item of handleData) {
    const { error } = yield addFavourite(item);
    if (error) {
      yield put(rejectedAddFavourite(error));
      yield put(switchGlobalLoader(false));
    }
  }

  yield call(callBackgroundGetProspects);

  callback();

  yield put(switchGlobalLoader(false));
  yield put(resolveAddFavourite());
}

function* handleRemoveFavourite(action: actionTypes.HandleRemoveFavourite) {
  yield put(switchGlobalLoader(true));
  const { handleData, callback } = action.payload;

  for (const item of handleData) {
    const { error } = yield removeFavourite(item);

    if (error) {
      yield put(rejectedRemoveFavourite(error));
      yield put(switchGlobalLoader(false));
    }
  }

  yield call(callBackgroundGetProspects);

  callback();
  yield put(switchGlobalLoader(false));
  yield put(resolveRemoveFavourite());
}

function* handleUpdateFavourite(actions: actionTypes.HandleUpdateFavourite) {
  yield put(switchGlobalLoader(true));
  const { handleData, callback, action } = actions.payload;

  for (const item of handleData) {
    const { error } = yield updateFavourite(item, action);

    if (error) {
      yield put(rejectedRemoveFavourite(error));
      yield put(switchGlobalLoader(false));
    }
  }
  switch (handleData[0].endpoint) {
    case FavouriteEndpoints.prospect:
      yield call(callBackgroundGetProspects);
      break;
    case FavouriteEndpoints.deal:
      yield call(callBackgroundGetDeals);
      break;
    case FavouriteEndpoints.inventory:
      yield call(callBackgroundGetInventory);
      break;
    default:
      console.log(`default from switch: no endpoint provided`);
  }

  callback();
  yield put(switchGlobalLoader(false));
  yield put(resolveRemoveFavourite());
}

function* handleSendEmailSaga(action: HandleSendEmail) {
  yield put(pendingSendEmail());

  const { email: emails, callback } = action.payload;
  //const { title, message } = action.payload;

  try {
    for (const email of emails.email) {
      const formData = new FormData();

      if (emails.attachments.length > 0) {
        for (const attachment of emails.attachments) {
          formData.append("files[]", attachment, "test.jpeg");
        }
      }

      formData.append("title", emails.title);
      formData.append("email", email);
      formData.append("message", emails.message);

      if (emails.inventories.length > 0) {
        for (const inventory of emails.inventories) {
          formData.append("inventories[]", inventory.toString());
        }
      }

      const { error } = yield sendEmailApi(formData);

      if (error) {
        yield put(rejectedSendEmail(error));
        return;
      }
    }
    callback();
    yield put(resolvedSendEmail());
  } catch (error) {
    console.log(error);
    yield put(rejectedSendEmail(error));
  }
}

function* handleSendSMSSaga(action: HandleSendSMS) {
  yield put(pendingSendSMS());

  const { sms, callback } = action.payload;
  try {
    for (const addressee of sms.arrayOfAddressees) {
      const { error } = yield sendSMSApi({
        to: "+" + addressee,
        message: sms.message,
      });
      if (error) {
        yield put(rejectedSendSMS(error));
        return;
      }
    }
    callback();
    yield put(resolvedSendSMS());
  } catch (error) {
    yield put(rejectedSendSMS(error));
  }
}

export function* handleGetSMTPSettingsSaga() {
  yield put(pendingGetSMTPSettings());

  const { data, error } = yield getSMTPSettingsApi();

  if (error) {
    yield put(rejectedGetSMTPSettings(error));
    return;
  }

  yield put(resolvedGetSMTPSettings(data));
}

function* handleUpdateSMTPSettingsSaga(action: HandleUpdateSMTPSettings) {
  yield put(pendingUpdateSMTPSettings());

  const { data, error } = yield updateSMTPSettingsApi(action.payload);
  if (error) {
    yield put(rejectedGetSMTPSettings(error));
    return;
  }

  yield put(resolvedGetSMTPSettings(data));
}

function* handleTestConnectionSaga(action: HandleTestConnection) {
  yield put(pendingTestConnection());

  const { data, error } = yield testConnectionApi(action.payload);

  if (error) {
    yield put(rejectedTestConnection());
    return;
  }

  yield put(resolvedTestConnection(data));
}

function* handleGetMakesSaga() {
  try {
    yield put(pendingGetMakes());
    const { data, error } = yield getMakesApi();
    if (error) {
      rejectedGetMakes(error);
      return;
    }
    const makes: IPickerItem[] = data.map((item) => {
      return {
        id: item.data.Make_ID,
        label: item.data.Make_Name,
        value: item.data.Make_Name,
      };
    });
    yield put(resolvedGetMakes(makes));
  } catch (error) {
    yield put(rejectedGetMakes(error));
  }
}

function* handleGetDataOneMakesSaga(actions: HandleGetDataOneMakes) {
  try {
    yield put(pendingGetMakes());
    const { data, error } = yield getMakesDataOneApi(actions.payload);
    if (error) {
      // rejectedGetMakes(error);
      return;
    }
    const makes = data.map((item, index) => {
      return {
        id: index,
        label: item,
        value: item,
      };
    });
    yield put(resolvedGetDataOneMakes(makes));
  } catch (error) {
    // yield put(rejectedGetMakes(error));
  }
}

function* handleGetModelsSaga(actions: HandleGetModels) {
  yield put(pendingGetModels());

  const { data, error } = yield getModelsApi(actions.payload);

  if (error) {
    yield put(rejectedGetModels(error));
    return;
  }

  const models: IPickerItem[] = data.map((item) => {
    return {
      id: item.data.Model_ID,
      label: item.data.Model_Name,
      value: item.data.Model_Name,
    };
  });

  yield put(resolvedGetModels(models));

  //
  // const makesFromApi: inventoryTypes.IMakeFromAPI[] = yield fromInventoryAPI.getMakesApi();
  //
  // const makes: IPickerItem[] = makesFromApi.map((item) => {
  //   return {
  //     id: item.data.Make_ID,
  //     label: item.data.Make_Name,
  //     value: item.data.Make_Name,
  //   };
  // });
  // for (let i = 0; i < makes.length; i++) {
  //   const make: IPickerItem = makes[i];
  //   if (make.label === payload) {
  //     id = make.id;
  //     break;
  //   }
  // }
  // if (!id) {
  //   createErrorAlert(`[ERROR SAGA HANDLE GET MODELS]: MODELS NOT FOUND`);
  //   yield put(actions.getModelsRejected('MODELS NOT FOUND'));
  //   return;
  // }
  // const response: inventoryTypes.IModelFromAPI[] = yield fromInventoryAPI.getModelsApi(
  //     id
  // );
  // const models: IPickerItem[] = response.map((item) => {
  //   return {
  //     id: item.data.Model_ID,
  //     label: item.data.Model_Name,
  //     value: item.data.Model_Name,
  //   };
  // });
  // yield put(actions.getModelsResolved(models));
  //
  // createErrorAlert(`[ERROR SAGA HANDLE GET MODELS]: ${error}`);
  // yield put(actions.getModelsRejected(error));
}


function* handleGetDataOneModelsSaga(actions: HandleGetDataOneModels) {
  yield put(pendingGetModels());

  const { data, error } = yield getModelsDataOneApi(actions.payload);

  if (error) {
    // yield put(rejectedGetModels(error));
    return;
  }

  const models = data.map((item, index) => {
    return {
      id: index,
      label: item,
      value: item,
    };
  });

  yield put(resolvedGetDataOneModels(models));
}

function* handleGetSMSSettingsSaga() {
  yield put(pendingGetSMSSettings());

  const { data, error } = yield getSMSSettingsApi();

  if (error) {
    yield put(rejectedGetSMSSettings(error));
    return;
  }

  yield put(resolvedGetSMSSettings(data));
}

function* handleUpdateSMSSettingsSaga(action: HandleUpdateSMSSettings){
  yield put(pendingUpdateSMSSettings());

  const settings = {...action.payload}
  console.log(settings.fromPhone)
  


  settings.fromPhone = '+1' + settings.fromPhone.replace(/[-_()]/g,'');

  const { error, data} = yield updateSMSSettingsApi(settings);

  
  

  if(error){
    yield put(rejectedUpdateSMSSettings(error))
    return
  }

  yield put(resolvedUpdateSMSSettings(data))


}

export default function* SharedSaga() {
  yield takeEvery(HANDLE_ADD_FAVOURITE, handleAddFavourite);
  yield takeEvery(HANDLE_ZIPCODE_FLATDATA, handleZipCodeFlatData);
  yield takeEvery(HANDLE_REMOVE_FAVOURITE, handleRemoveFavourite);
  yield takeEvery(HANDLE_UPDATE_FAVOURITE, handleUpdateFavourite);
  yield takeEvery(HANDLE_SEND_EMAIL, handleSendEmailSaga);
  yield takeEvery(HANDLE_SEND_SMS, handleSendSMSSaga);
  yield takeLeading(HANDLE_GET_SMTP_SETTINGS, handleGetSMTPSettingsSaga);
  yield takeLeading(HANDLE_UPDATE_SMTP_SETTINGS, handleUpdateSMTPSettingsSaga);
  yield takeLeading(HANDLE_TEST_CONNECTION, handleTestConnectionSaga);
  yield takeEvery(HANDLE_GET_MAKES, handleGetMakesSaga);
  yield takeEvery(HANDLE_GET_DATAONE_MAKES, handleGetDataOneMakesSaga);
  yield takeEvery(HANDLE_GET_MODELS, handleGetModelsSaga);
  yield takeEvery(HANDLE_GET_DATAONE_MODELS, handleGetDataOneModelsSaga);
  yield takeLeading(HANDLE_GET_SMS_SETTINGS, handleGetSMSSettingsSaga);
  yield takeLeading(HANDLE_UPDATE_SMS_SETTINGS, handleUpdateSMSSettingsSaga);
}
