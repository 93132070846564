import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { DefaultPalette, IIconStyles } from "@fluentui/react";
import {
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react/lib/ContextualMenu";
import {
  colors,
  FavouriteAction,
  permissionsModules,
} from "constants/constants";

import { useSelector } from "react-redux";
import { AppState } from "store";
import { IPermission } from "types/groupPermissionTypes";
import { isPermitted } from "utils/permissions/permissionsHelpers";
import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};
interface Props {
  onDelete?: () => void;
  onDeleteTasks?: () => void;
  amountOfDaysToMoveTask?: (days: number | string) => void;
  handleUpdateIsFavourite: (action: FavouriteAction) => void;
  modulePermissions: IPermission[];
  onSendEmail: () => void;
  onSetStatus: (status: string) => void;
  onSendSMS: () => void;
}

export const ProspectTableDropdown: React.FunctionComponent<Props> = ({
  onDelete,
  onDeleteTasks,
  amountOfDaysToMoveTask,
  handleUpdateIsFavourite,
  modulePermissions,
  onSendEmail,
  onSetStatus,
  onSendSMS
}) => {
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const handleDelete = () => {
    onDelete();
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "favourites", //with submenu
      name: "Favourites",
      iconProps: { iconName: "FavoriteList", styles: iconMenuStyles },
      // onClick: () => {},
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Prospects,
        user.roles
      )
        ? hiddenStyle
        : null,
      subMenuProps: {
        items: [
          {
            key: "addToFavorite",
            text: "Add",
            onClick: () => handleUpdateIsFavourite(FavouriteAction.add),
          },
          {
            key: "removeFromFavorite",
            text: "Remove",
            onClick: () => handleUpdateIsFavourite(FavouriteAction.remove),
          },
        ],
      },
    },
    {
      key: "setStatus", //with submenu
      name: "Set Status",
      iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Prospects,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => {},
      subMenuProps: {
        items: [
          {
            key: "new",
            text: "new",
            onClick: () => onSetStatus("new"),
          },
          {
            key: "active",
            text: "active",
            onClick: () => onSetStatus("active"),
          },
        ],
      },
    },

    {
      key: "schedule", //with submenu
      name: "Schedule",
      iconProps: { iconName: "Calendar", styles: iconMenuStyles },
      style: !isPermitted(
        modulePermissions,
        eTypes.UPDATE,
        permissionsModules.Prospects,
        user.roles
      )
        ? hiddenStyle
        : null,
      onClick: () => {},
      subMenuProps: {
        items: [
          {
            key: "MoveTomorrow",
            text: "Move to tomorrow",
            onClick: () => amountOfDaysToMoveTask("tomorrow"),
          },
          {
            key: "moveTwoDays",
            text: "Move by 2 days",
            onClick: () => amountOfDaysToMoveTask(2),
          },
          {
            key: "moveWeek",
            text: "Move it a week",
            onClick: () => amountOfDaysToMoveTask(7),
          },
          {
            key: "deleteAll",
            text: "Delete All Tasks",
            onClick: onDeleteTasks,
          },
        ],
      },
    },
    // {
    //   key: "sendEmail",
    //   name: "Send email",
    //   iconProps: { iconName: "Send", styles: iconMenuStyles },
    //   onClick: () => onSendEmail(),
    //   style: !isPermitted(
    //       modulePermissions,
    //       eTypes.WRITE,
    //       permissionsModules.Prospect,
    //       user.roles
    //   )
    //       ? hiddenStyle
    //       : null,
    // },
    // {
    //   key: "sendSMS", //with submenu
    //   name: "Send SMS",
    //   iconProps: { iconName: "Mail", styles: iconMenuStyles },
    //   onClick: () => onSendSMS(),
    //   style: !isPermitted(
    //       modulePermissions,
    //       eTypes.WRITE,
    //       permissionsModules.Prospect,
    //       user.roles
    //   )
    //       ? hiddenStyle
    //       : null,
    // },
    {
      key: "delete", //with submenu
      iconProps: { iconName: "Delete", styles: iconMenuStyles },
      name: "Delete",
      onClick: handleDelete,
      style: !isPermitted(
        modulePermissions,
        eTypes.DELETE,
        permissionsModules.Prospects,
        user.roles
      )
        ? hiddenStyle
        : null,
    },
  ];

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: menuItems,
    useTargetWidth: false,
  };

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};
