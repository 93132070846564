import React, { useState, FunctionComponent } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  PrimaryButton,
  TextField,
  Checkbox,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { useSelector } from "react-redux";
import { AppState } from "store";

import { dropdownStyles, cancelIcon } from "constants/styles";

import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
//import WysiwygEditor from "components/dialogs/addInventoryDialog/WysiwygEditor";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
// import { EditorState, ContentState } from "draft-js";
// import htmlToDraft from "html-to-draftjs";
// import Loader from "components/loader/Loader";
import { contentStyles as content, iconButtonStyles } from "constants/styles";
import { Editor } from "react-draft-wysiwyg";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";



const contentStyles = content();

const conditionValues = [
  { id: 1, text: "Used" },
  { id: 2, text: "Certified" },
  { id: 3, text: "New" },
].map((item) => {
  return { key: item.id, text: item.text };
});

const makeValues = [
  { id: 1, text: "Nissan" },
  { id: 2, text: "BMW" },
  { id: 3, text: "BMW" },
].map((item) => {
  return { key: item.id, text: item.text };
});

const modelValues = [
  { id: 1, text: "350" },
  { id: 2, text: "400" },
  { id: 3, text: "450" },
].map((item) => {
  return { key: item.id, text: item.text };
});

// const trimValues = [
//   { id: 1, text: "1" },
//   { id: 2, text: "2" },
//   { id: 3, text: "3" },
// ].map((item) => {
//   return { key: item.id, text: item.text };
// });


const bodyValues = [
  { id: 1, text: "Wagon" },
  { id: 2, text: "SUV" },
  { id: 3, text: "Hatchback" },
].map((item) => {
  return { key: item.id, text: item.text };
});

const colorValues = [
  { id: 1, text: "White" },
  { id: 2, text: "Blue" },
  { id: 3, text: "Red" },
].map((item) => {
  return { key: item.id, text: item.text };
});

// const yearValues = [
//   { key: 2000, value: "2000" },
//   { key: 2001, value: "2001" },
// ].map((item) => {
//   return { key: item.key, text: item.value };
// });

interface IProps {
  onDismiss: () => void;
  formik: any;
  handleEditProspect: () => void;
  setHtmlToSendForVehicleWish?: (e: any) => void;
  htmlToSendForVehicleWish?: any;
  wysiwygEditorStateVehicleWish?: any;
  setWysiwygEditorStateVehicleWish?: (state: any) => void;
}

export const VehicleWishDialog: FunctionComponent<IProps> = ({
  onDismiss,
  formik,
  handleEditProspect,
  htmlToSendForVehicleWish,
  setHtmlToSendForVehicleWish,
  setWysiwygEditorStateVehicleWish,

  wysiwygEditorStateVehicleWish,
}) => {
  const [showFooter] = useState(true);

  const [isIndeterminateInterested, setIsIndeterminateInterested] = useState(
    false
  );

  // const [htmlToSend, setHtmlToSend] = useState("");

  // const [wysiwygEditorState, setWysiwygEditorState] = useState(
  //   EditorState.createEmpty()
  // );
  const loading = useSelector((state: AppState) => state.inventory.loading);

  // React.useEffect(() => {
  //   const html = formik.values.vehicleNote ? formik.values.vehicleNote : "";
  //   const contentBlock = htmlToDraft(html);
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(
  //       contentBlock.contentBlocks
  //     );
  //     const editorState = EditorState.createWithContent(contentState);
  //     // setWysiwygEditorState(editorState);
  //   }
  // }, [formik.values.vehicleNote]);

  const onCheckboxChange = (
    ev: React.FormEvent<HTMLElement>,
    checked: boolean
  ): void => {
    if (isIndeterminateInterested) {
      setIsIndeterminateInterested(false);
      formik.setValues(
        {
          ...formik.values,
        },
        true
      );
    }
    formik.setValues(
      {
        ...formik.values,
        vehicleInterested: !!checked,
      },
      true
    );
  };

  // const handleDropdownChange = (e, item) => {
  //   formik.setValues(
  //     {
  //       ...formik.values,
  //       [e.target.id]: item.key,
  //     },
  //     true
  //   );
  // };

  const handleDropdownMultiChange = (e, item) => {
    const id = e.target.id;
    let newValues = [...formik.values[id]];

    console.log('e.tar.id', id)
    console.log('newVal', newValues)


    if (formik.values[id].includes(item.key)) {
      newValues = newValues.filter((element) => element !== item.key);
    } else {
      newValues.push(item.key);
    }

    console.log(formik.values);


    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: newValues,
      },
      true
    );

    // formik.setFieldValue(e.target.id, newValues, true);
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleClear = () => {
    formik.resetForm();
    setIsIndeterminateInterested(false);
    // setHtmlToSend("");
    // setWysiwygEditorState(EditorState.createEmpty());
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <GoBackToMainDialogBtn label="Vehicle Wish" onClick={onDismiss} />
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>

      <div>
        <div className={`noScrollbar ${contentStyles.body}`}>
          <Stack>
            <Stack tokens={styles.textFields}>
              <WithOptionalLabel>
                <Dropdown
                  id="vehicleCondition"
                  label="Condition"
                  multiSelect
                  selectedKeys={
                    formik.values.vehicleCondition
                      ? formik.values.vehicleCondition
                      : undefined
                  }
                  // onChange={handleDropdownMultiChange}
                  onChange={handleDropdownMultiChange}
                  placeholder=""
                  options={conditionValues}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleCondition &&
                      formik.errors.vehicleCondition
                      ? formik.errors.vehicleCondition.toString()
                      : null
                  }
                />
              </WithOptionalLabel>

              <WithOptionalLabel>
                <Dropdown
                  id="vehicleMake"
                  label="Make"
                  multiSelect
                  selectedKeys={
                    formik.values.vehicleMake
                      ? formik.values.vehicleMake
                      : undefined
                  }
                  onChange={handleDropdownMultiChange}
                  placeholder=""
                  options={makeValues}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleMake && formik.errors.vehicleMake
                      ? formik.errors.vehicleMake.toString()
                      : null
                  }
                />
              </WithOptionalLabel>
              <WithOptionalLabel>
                <Dropdown
                  id="vehicleModel"
                  label="Model"
                  multiSelect
                  selectedKeys={
                    formik.values.vehicleModel
                      ? formik.values.vehicleModel
                      : undefined
                  }
                  onChange={handleDropdownMultiChange}
                  placeholder=""
                  options={modelValues}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleModel && formik.errors.vehicleModel
                      ? formik.errors.vehicleModel.toString()
                      : null
                  }
                />
              </WithOptionalLabel>
              <WithOptionalLabel>
                <TextField
                  id="vehicleTrim"
                  name="vehicleTrim"
                  label="Trim"
                  type="number"
                  value={formik.values.vehicleTrim}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleTrim &&
                    formik.errors.vehicleTrim &&
                    formik.errors.vehicleTrim.toString()
                  }
                />




              </WithOptionalLabel>

              <WithOptionalLabel>
                <label className="lbl-text required-Label">Max Price</label>
                <CurrencyInput
                  customInput={TextField}
                  // thousandSeparator={true}
                  // decimalScale={2}
                  //fixedDecimalScale
                  prefix={PRICE_PREFIX}
                  id="vehicleMaxPrice"
                  name="vehicleMaxPrice"
                  //label="Max Price"
                  value={formik.values.vehicleMaxPrice?formik.values.vehicleMaxPrice.replace(/,/g, ''):""}
                  onValueChange ={(e) => {
                    formik.setValues(
                        {
                            ...formik.values,
                            "vehicleMaxPrice": e
                        }
                    );
                }}
                  onBlur={formik.handleBlur}
                />
                <span className="error-message">
                  {
                    formik.errors.vehicleMaxPrice &&
                    formik.errors.vehicleMaxPrice.toString()}
                </span>

                {/*<TextField*/}
                {/*  id="vehicleMaxPrice"*/}
                {/*  name="vehicleMaxPrice"*/}
                {/*  label="Max Price"*/}
                {/*  prefix="$"*/}
                {/*  type="number"*/}
                {/*  value={formik.values.vehicleMaxPrice}*/}
                {/*  onChange={formik.handleChange}*/}
                {/*  onBlur={formik.handleBlur}*/}
                {/*  errorMessage={*/}
                {/*    formik.touched.vehicleMaxPrice &&*/}
                {/*    formik.errors.vehicleMaxPrice &&*/}
                {/*    formik.errors.vehicleMaxPrice.toString()*/}
                {/*  }*/}
                {/*/>*/}
              </WithOptionalLabel>

              <WithOptionalLabel>

                <TextField
                  id="vehicleMinYear"
                  name="vehicleMinYear"
                  label="Min Year"
                  type="number"
                  value={formik.values.vehicleMinYear}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleMinYear &&
                    formik.errors.vehicleMinYear &&
                    formik.errors.vehicleMinYear.toString()
                  }
                />


                {/*<Dropdown*/}
                {/*  id="vehicleMinYear"*/}
                {/*  label="Min Year"*/}
                {/*  selectedKey={*/}
                {/*    formik.values.vehicleMinYear*/}
                {/*      ? formik.values.vehicleMinYear*/}
                {/*      : undefined*/}
                {/*  }*/}
                {/*  onChange={handleDropdownChange}*/}
                {/*  placeholder=""*/}
                {/*  options={yearValues}*/}
                {/*  styles={dropdownStyles}*/}
                {/*  onBlur={formik.handleBlur}*/}
                {/*  errorMessage={*/}
                {/*    formik.touched.vehicleMinYear &&*/}
                {/*    formik.errors.vehicleMinYear*/}
                {/*      ? formik.errors.vehicleMinYear.toString()*/}
                {/*      : null*/}
                {/*  }*/}
                {/*/>*/}
              </WithOptionalLabel>

              <WithOptionalLabel>
                <TextField
                  id="vehicleMileage"
                  name="vehicleMileage"
                  label="Mileage"
                  value={formik.values.vehicleMileage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleMileage &&
                    formik.errors.vehicleMileage &&
                    formik.errors.vehicleMileage.toString()
                  }
                />
              </WithOptionalLabel>

              <WithOptionalLabel>
                <Dropdown
                  id="vehicleBodyType"
                  label="Body Type"
                  selectedKeys={
                    formik.values.vehicleBodyType
                      ? formik.values.vehicleBodyType
                      : undefined
                  }
                  onChange={handleDropdownMultiChange}
                  multiSelect
                  placeholder=""
                  options={bodyValues}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleBodyType &&
                      formik.errors.vehicleBodyType
                      ? formik.errors.vehicleBodyType.toString()
                      : null
                  }
                />
              </WithOptionalLabel>

              <WithOptionalLabel>
                <Dropdown
                  id="vehicleExteriorColor"
                  label="Exterior Color"
                  multiSelect
                  selectedKeys={
                    formik.values.vehicleExteriorColor
                      ? formik.values.vehicleExteriorColor
                      : undefined
                  }
                  onChange={handleDropdownMultiChange}
                  placeholder=""
                  options={colorValues}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.vehicleExteriorColor &&
                      formik.errors.vehicleExteriorColor
                      ? formik.errors.vehicleExteriorColor.toString()
                      : null
                  }
                />
              </WithOptionalLabel>

              <Checkbox
                label="Vehicle Interested"
                indeterminate={isIndeterminateInterested}
                checked={formik.values.vehicleInterested}
                onChange={onCheckboxChange}
              />

              <Editor
                editorState={wysiwygEditorStateVehicleWish}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={setWysiwygEditorStateVehicleWish}
              />
            </Stack>
          </Stack>
        </div>
        {showFooter && (
          <div className={contentStyles.footer}>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Clear" onClick={handleClear} />

                {/* temporary hide */}
                {/*<DefaultButton text="Save Draft" onClick={() => {}} />*/}
              </Stack>
              <Stack
                tokens={styles.buttonTokens}
                horizontal
                horizontalAlign="end"
              >
                <DefaultButton text="Close" onClick={onDismiss} />
                <PrimaryButton
                  onRenderIcon={renderSpinner}
                  text="Add Inventory"
                  onClick={handleEditProspect}
                  disabled={!formik.isValid}
                />
              </Stack>
            </Stack>
          </div>
        )}
      </div>
    </Modal>
  );
};
