import React from "react";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
// import { Link, ILinkStyles } from '@fluentui/react/lib/Link';

const styles: IStackStyles = {
    root: {
        position: "absolute",
        left: 50,
        bottom: 30,
    },
};
/* const linkStyles: ILinkStyles = {
    root: {
        color: 'black',
        fontSize: 12,
        marginRight: 30
    }
} */

const AuthFooter = () => {
    return (
        <Stack horizontal verticalAlign="center" styles={styles}>
            {/* <Link styles={linkStyles} href="https://www.google.com/">
                Private policy
            </Link>
            <Link styles={linkStyles} href="">
            Cookies
        </Link> */}
        </Stack>
    );
};

export default AuthFooter;
