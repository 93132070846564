import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { styles } from "pages/dealSettingsPage/styles";
import PartnerSettingsListItem from "components/ListItem/PartnerSettingsListItem";
import { PartnerSettingsStatus } from "constants/constants";
import { ServiceConfigurationDialog } from "components/dialogs/serviceConfigurationDialog/ServiceConfigurationDialog";



const PartnerSettings = () => {
  const [openedConfigDialogName, setOpenedConfigDialogName] = useState(null);

  return (
    <Container style={styles.container} fluid>
      {openedConfigDialogName !== null && (
        <ServiceConfigurationDialog
          onDismiss={() => setOpenedConfigDialogName(null)}
          serviceName={openedConfigDialogName}
        />
      )}
      <Row gutterWidth={75}>
        <Col sm={12} md={12} xl={6}>
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.configured}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.configured}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.requiresConfiguration}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.configured}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
        </Col>

        <Col sm={12} md={12} xl={6}>
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.disabled}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.requiresConfiguration}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
          <PartnerSettingsListItem
            status={PartnerSettingsStatus.configured}
            title={"Configuration PartnerServiceName"}
            callback={() => setOpenedConfigDialogName("PartnerServiceName")}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PartnerSettings;
