import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    //Checkbox,
    //MaskedTextField,
    TextField,
    Dropdown,
} from "@fluentui/react";
import {
  Stack,
  IStackStyles,
  PrimaryButton,
} from "@fluentui/react";
import {
  handleGetUserProfile,
  handleUpdateDealerProfile,
} from "store/userProfile/userProfile.action";
import {
  dropdownStyles
} from "constants/styles";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { AppState } from "store";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
//import { dropdownStyles } from "constants/styles";
import * as Yup from "yup";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
//import { getAddreessByZip } from "services/api/smartyStreets";
import { HandleZipCodeFlatData } from "store/shared/shared.action";
import { useMediaQuery } from "react-responsive";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "constants/constants";


const AddressPage = () => { 
    const dispatch = useDispatch();
    const [cityList, setCityDealerList] = React.useState([]);
	  const [countyList, setCountyDealerList] = React.useState([]);
    const [locationZipApiMsg, setlocationZipApiMsg] = React.useState("");
    //const [zipFlag, setZipFlag] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState("");
    
    const user = useSelector((state: AppState) => state.userProfile.userProfile);

    const isMobile = useMediaQuery({ maxWidth: 576 });

     const dealerProfile = useSelector(
    (state: AppState) => state.userProfile.userProfile.dealerProfile
     );

      const serverError = useSelector(
    (state: AppState) => state.userProfile.einValidation
     );
    
const styles: IStackStyles = {
    root: {
    //boxShadow: Depths.depth8,
    background: DefaultPalette.white,
    padding: 25,
    paddingLeft: 80,
    paddingRight: 80,
    borderRadius: 0,
    width: 1040,
    //height: 330,
    zIndex: 1,
  },
};
    const mobileStyles: IStackStyles = {
        root: {
            //boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 25,
            paddingLeft: 0,
            paddingRight: 80,
            borderRadius: 0,
            //width: 1040,
            //height: 330,
            zIndex: 1,
        },
    };

    const [style, setStyle] = React.useState(styles);
    
    const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
    );
    };
    const handleZipCodeChangeForDealer = (e) => {
		if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
			formik.setValues(
				{
					...formik.values,
					zip: e.target.value
				}
			);
		}
	};
    const loggedUserId = useSelector(
    (state: AppState) => state.auth.loggedUserId);

    const handleSaveChanges = () => {
    formik.handleSubmit();
  };

  Yup.addMethod(Yup.string, 'digitsOnly', function () {
      return this.matches(/^\d+$/, 'Numerals only allowed')
  });
  
  const formik = useFormik({
    initialValues: {
      legalName: "",
      companyAlias: "",
      einNumber: "",
      physicalAddress1: "",
      zip: "",
      city: "",
      county:"",
      state: "",
    },
    validationSchema: Yup.object({
      legalName: Yup.string(),
      companyAlias: Yup.string(),
      einNumber: Yup.string()
           .test("len", "Invalid EIN.", (val) => {
               if (val !== undefined) {
                   const val_length_without_dashes = val.replace(/-|_/g, "").length;
                   if (val_length_without_dashes > 0)
                       return val_length_without_dashes >= 9;
               }
               return true;
           }),
      physicalAddress1: Yup.string().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5,"Zip must be 5 digits").required("Required"),
      city: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
      state: Yup.string().required("Required")
      
    }),
    onSubmit: (values) => {
        const profile = {
            legalName: values.legalName,
            companyAlias: values.companyAlias? values.companyAlias.split(','):[],
            einNumber: values.einNumber.replace(/-|_/g, ""),
            physicalAddress1: values.physicalAddress1,
            zip: values.zip,
            city: values.city,
            county:values.county,
            state: values.state,
        }
        dispatch<any>(handleUpdateDealerProfile(loggedUserId, profile));
    },
  });

    useEffect(() => {
        if (isMobile) {
            setStyle(mobileStyles);
        } else {
            setStyle(styles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

 React.useEffect(() => {
    formik.setValues({
      legalName: dealerProfile?.legalName,
      //companyAlias: dealerProfile?.companyAlias.toString(),
      companyAlias: dealerProfile?.companyAlias ? dealerProfile.companyAlias.toString() : '',
      einNumber: dealerProfile?.einNumber,
      physicalAddress1: dealerProfile?.physicalAddress1,
      zip: dealerProfile?.zip,
      city: dealerProfile?.city,
      county: dealerProfile?.county,
      state: dealerProfile?.state,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerProfile]);

  useEffect(() => {
  if(serverError){
    const msg = Array.isArray(serverError)?serverError[serverError.length-1].title:'Failed to save the data.';
    setErrorMsg(msg);
  }else{
  setErrorMsg('');
  }
  },[serverError]);

  useEffect(() => {
		let zipFields = formik.values.zip;
		if (zipFields.length === 5 && !isNaN(zipFields as any)) {
			dispatch<any>(switchGlobalLoader(true));
            //(getAddreessByZip(zipFields) as any).then((response) => {
            dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (response) => {
                        if(response){
                            setCityDealerList(response.Cities);
			                setCountyDealerList(response.Counties);
			                if (formik.values.city === '' &&
			                	formik.values.county === '' &&
			                	formik.values.state === '') {
			                	formik.setValues(
			                		{
			                			...formik.values,
			                			'county': response.DefaultCounty,
			                			'city': response.DefaultCity,
			                			'state': response.State
			                		}
			                	);
			                }
			                setlocationZipApiMsg("");                           
                        }else{
                            setlocationZipApiMsg('Invalid ZIP Code.');
			                formik.setValues(
			                	{
			                		...formik.values,
			                		'county': "",
			                		'city': "",
			                		'state': ""
			                	}
			                );
			                setCityDealerList([]);
			                setCountyDealerList([]);
                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }
                    
                }));
		} else {
			formik.setValues(
				{
					...formik.values,
					'county': "",
					'city': "",
					'state': ""
				}
			);
			setCityDealerList([]);
			setCountyDealerList([]);
			setlocationZipApiMsg("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.zip]);

  useEffect(() => {
    dispatch<any>(handleGetUserProfile(loggedUserId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (<>
    {formik.values ? <div className="tabRowSection">
           
            <Stack styles={style} className="tabRowSection" >
                 <div>
                     <div className="businessInfoInput">
                           <Row>
                               <Col>
                               {
                                  errorMsg ?
                                      <div>
                                          <Row>
                                              <Col>
                                                  <p className="genericErrorColor"><span>{errorMsg}</span></p>
                                              </Col>
                                          </Row>
                                      </div> : null
                               }
                               </Col>
                           </Row>
                           <Row>
                               <Col sm={3} xs={12} md={6} lg={4} className="businessInfoInputEINTextField">
                                   <TextField
                                        id="einNumber"
                                        name="einNumber"
                                        //mask="999999999"
                                        label="Federal Tax ID/EIN"
                                        placeholder=""
                                        autoComplete="off"
                                        readOnly={true}
                                        value={formik.values.einNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        //disabled={true}
                                        errorMessage={
                                        formik.touched.einNumber && formik.errors.einNumber
                                        ? formik.errors.einNumber.toString()
                                        : null
                                        }
                                    />
                               </Col>
                            </Row>
                     </div>
                     <div className="businessInfoInputTop">
                           <Row className="rowSpace">
                               <Col sm={3} xs={12} md={6} lg={4} className="businessInfoInputEINTextField">
                                   <TextField
                                        id="legalName"
                                        name="legalName"
                                        label="Business Legal Name"
                                        placeholder=""
                                        autoComplete="off"
                                        readOnly={true}
                                        value={formik.values.legalName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        //disabled={true}
                                        errorMessage={
                                        formik.touched.legalName && formik.errors.legalName
                                        ? formik.errors.legalName.toString()
                                        : null
                                        }
                                   />
                               </Col>
                               <Col sm={6} xs={12} md={6} lg={6}>
                                   <TextField
                                         id="companyAlias"
                                         name="companyAlias"
                                         //className="dbaEntries"
                                         label="DBA"
                                         placeholder=""
                                         autoComplete="off"
                                         value={formik.values.companyAlias}
                                         //required={true}
                                         onChange={formik.handleChange}
                                         onBlur={formik.handleBlur}
                                         //disabled={true}
                                         errorMessage={
                                         formik.touched.companyAlias && formik.errors.companyAlias
                                         ? formik.errors.companyAlias.toString()
                                         : null
                                         }
                                   />
                               </Col>
                           </Row>
                           <Row className="rowSpace" >
                               <Col sm={3} xs={12} md={6} lg={6}>
                                  <TextField
                                        id="physicalAddress1"
                                        name="physicalAddress1"
                                        label="Street Address"
                                        className="business-info-input"
                                        placeholder=""
                                        autoComplete="off"
                                        value={formik.values.physicalAddress1}
                                        // onChange={(e) => handleLegalNameChange(e,'firstName')}
                                        //onBlur={formik.handleBlur}
                                        required={true}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                        formik.touched.physicalAddress1 &&
                                        formik.errors.physicalAddress1 
                                        ?formik.errors.physicalAddress1.toString()
                                        : null
                                        }
                                  />
                               </Col>
                               <Col sm={2} xs={12} md={2} lg={2}>
                                  <TextField
					                   id="zip"
					                   name="zip"
					                   label="Zip"
					                   placeholder=""
					                   autoComplete="off"
                                       className="business-info-input-zipState"
					                   value={formik.values.zip}
					                   onChange={(e) => handleZipCodeChangeForDealer(e)}
					                   //onBlur={formik.handleBlur}
					                   required={true}
					                   // onChange={formik.handleChange}
					                   onBlur={formik.handleBlur}
					                   errorMessage={
					                   (formik.touched && formik.touched.zip &&
					                   formik.errors && formik.errors.zip) ?
					                   formik.errors.zip.toString()
					                   : locationZipApiMsg ? locationZipApiMsg : null
					                   }
					              />
                               </Col>
                           </Row>
                     </div>
                     <div className="businessInfoSubInput">
                           <Row className="businessInfoInput">
                              <Col sm={3} xs={12} md={6} lg={7}>
                                 <Dropdown
                                       id="city"
                                       label="City"
                                       placeholder=""
                                       //selectedKey={dealerFormik.values.dealerForm.city}
                                       //options={cityCompanyList}
                                       selectedKey={formik.values.city}
                                       onChange={handleDropdownChange}
                                       required={true}
                                       options={cityList}
                                       styles={dropdownStyles}
                                       className="verticalHeight-Line"
                                       onBlur={formik.handleBlur}
                                       errorMessage={
                                       formik.touched.city &&
                                       formik.errors.city &&
                                       formik.errors.city.toString()
                                       }
                                 />
                              </Col>
                              <Col sm={4} xs={12} md={4} lg={4} style={{right:"-64px"}} className="right-0">
                                 <TextField
                                       id="state"
                                       name="state"
                                       label="State"
                                       placeholder=""
                                       autoComplete="off"
                                       className="business-info-input-zipState"
                                       value={formik.values.state}
                                       required={true}
                                       onChange={handleDropdownChange}
                                       onBlur={formik.handleBlur}
                                       errorMessage={
                                       formik.touched.state &&
                                       formik.errors.state
                                       ?formik.errors.state.toString()
                                       : null
                                       }
                                 />
                              </Col>
                           </Row>
                           <Row>
                              <Col sm={3} xs={12} md={6} lg={7}>
                                 <Dropdown
                                       id="county"
                                       label="County"
                                       placeholder=""
                                       //selectedKey={dealerFormik.values.dealerForm.county}
                                       //options={countyCompanyList}
                                       selectedKey={formik.values.county}
                                       onChange={handleDropdownChange}
                                       required={true}
                                       options={countyList}
                                       styles={dropdownStyles}
                                       className="verticalHeight-Line"
                                       onBlur={formik.handleBlur}
                                       errorMessage={
                                       formik.touched.county &&
                                       formik.errors.county &&
                                       formik.errors.county.toString()
                                       }
                                 />
                              </Col>
                           </Row>
                     </div>
                     <div className="businessInfoSaveButton">
                           <Row className="rowSpace">
                               <Col>
                                  <PrimaryButton
                                      //styles={buttonStyle}
                                      //disabled={!formik.isValid || !formik.dirty}
                                      //onRenderIcon={renderSpinner}
                                      disabled={!isEmployeePermitted(
                                        user.aggregatedDefaultPolicies,
                                        emplyeePermissionsAction.SETTING_BUSINESS_INFO_UPDATE,
                                        user.roles
                                      )}
                                      text="Save"
                                      onClick={() => handleSaveChanges()}
                                   />
                               </Col>
                           </Row>
                     </div>
                 </div>   
              </Stack>   
         </div>: null
         }
        
     </>);
   };

export default AddressPage;