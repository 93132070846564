interface ITemp {
  groupName: string;
  startIndex: number;
  count: number;
  isShowingAll: boolean;
}

// const checkElementsStatus = (elements) => {
//     const e: any[] = [null, null, null, null];
//
//     for (let i = 1; i <= 4; i++) {
//         const partial = elements.find((el) => el.type === i);
//         e[i - 1] =
//             partial && partial.id
//                 ? {id: partial.id, value: true, type: i}
//                 : {value: false, type: i};
//     }
//
//     return e;
// };
//
// const groupBy = (array, key) => {
//     // Return the end result
//     return array.reduce((result, currentValue) => {
//         // If an array already present for key, push it to the array. Else create an array and push the object
//         (result[currentValue[key]] = result[currentValue[key]] || []).push(
//             currentValue
//         );
//         // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
//         return result;
//     }, {}); // empty object is the initial value for result object
// };

export const getGroupItems = (itemsArray: any[], permissions?: object) => {


  let _items = [];
  let tempTable: ITemp[] = [];

  let index = 0;
  if (permissions) {
    itemsArray.forEach((item: any) => {
      let count = 0;
      let localIndex = 0;

      _items.push({
        type: "group-configure",
        id: item.id, // <------
        employeesCount: item.employeesCount,
      });
      count++;
      localIndex++;

      Object.keys(permissions).forEach((permission: any) => {
        const per = permissions[permission];



        Object.keys(per).forEach((alias: any) => {

          // _items.push({
          //     type: "header",
          //     module: permission,
          // });
          // count++;
          // localIndex++;

          const elements = per[alias].map((a: any) => {
            return {
              ...a,
              value: item.userMasterGroupAuths.find(
                (i: any) => i.auth.id === a.id
              )
                ? true
                : false,
            };
          });


          // console.log(`${item.name} - ${permission} - ${alias} :ELEMENTS`, elements)

          // only for DealSettings
          if (alias === "DealSettings") {

            _items.push({
              groupId: item.id,
              type: "element",
              alias: alias,
              view: elements[0], // check for element in table
              add: undefined, // check for element in table
              update: elements[1], // check for element in table
              delete: undefined, // check for element in table
              module: permission,
            });
          } else {
            _items.push({
              groupId: item.id,
              type: "element",
              alias: alias,
              view: elements[0], // check for element in table
              add: elements[1], // check for element in table
              update: elements[2], // check for element in table
              delete: elements[3], // check for element in table
              module: permission,
            });
          }

          count++;
          localIndex++;
        });
      });
      tempTable.push({
        groupName: item.name,
        startIndex: index,
        count: count,
        isShowingAll: false,
      });

      index += localIndex;
    });
  }

  const result = {
    items: _items,
    calculatedIndexes: tempTable,
  };
  return result;
};

export const getGroupHeaders = (
  itemsArray: any[],
  calculatedIndexes: ITemp[]
) => {
  return itemsArray.map((item: any, index: number) => {
    return {
      key: item.id,
      name: item.name,
      startIndex: calculatedIndexes[index].startIndex,
      count: calculatedIndexes[index].count,
      level: 0,
    };
  });
};
