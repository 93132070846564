const DISPLAY_LOCATION = "DISPLAY_LOCATION";
const DISPLAY_DATE = "DISPLAY_DATE";


export const saveDisplayLocation = (data: string) => {
    localStorage.setItem(DISPLAY_LOCATION, data);
};

export const getDisplayLocation = () => {
    return localStorage.getItem(DISPLAY_LOCATION);
};


export const removeDisplayLocation = () => {
    localStorage.removeItem(DISPLAY_LOCATION);
};

export const saveDisplayDate = (data: string) => {
    localStorage.setItem(DISPLAY_DATE, data);
};

export const getDisplayDate = () => {
    return localStorage.getItem(DISPLAY_DATE);
};


export const removeDisplayDate = () => {
    localStorage.removeItem(DISPLAY_DATE);
};


