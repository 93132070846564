import { put, call, takeLeading, select } from "redux-saga/effects";
import { AppState } from "../index";
import {
    pendingLogin,
    resolvedLogin,
    rejectedLogin,
    // pendingRegister,
    // resolvedRegister,
    // successEmailRegister,
    // rejectedRegister,
    loginByTokenPending,
    loginByTokenResolved,
    confirmEmailSuccessStatus,
    ResetPasswordLinkExpired,
    reConfirmEmailSuccessStatus,
    logoutPending,
    logoutResolved,
    setIdForLoggedUser,
    setExpPasswordForLoggedUser,
    ResetPasswordRequestPending,
    ResetPasswordRequestResolved,
    ResetPasswordRequestRejected,
    resetPasswordConfirmPending,
    resetPasswordConfirmRejected,
    resetPasswordConfirmResolved,
    pendingConfirmAccount,
    resolvedConfirmAccount,
    rejectedConfirmAccount,
} from "store/auth/auth.action";
import { clearWebsite } from "store/website/website.action";
import {
    handleCleanUser,
    resolvedGetUserProfile
} from "store/userProfile/userProfile.action";
import {
    // HANDLE_REGISTER,
    HANDLE_LOGIN,
    HANDLE_LOGIN_BY_TOKEN,
    HANDLE_LOGOUT,
    HANDLE_TOKEN_REFRESH,
    HANDLE_RESET_PASSWORD_REQUEST,
    HandleResetPasswordRequest,
    HandleResetPasswordConfirm,
    HANDLE_RESET_PASSWORD_CONFIRM,
    HANDLE_CONFIRM_ACCOUNT,
    HANDLE_RESEND_EMAIL_CONFIRMATION,
    HandleConfirmAccount,
    HandleResendEmail,
    HANDLE_LOGOUT_LOGIN
} from "store/auth/auth.types";
import {
    // register,
    login,
    refreshTokenApi,
    resetPasswordRequest,
    resetPasswordConfirm,
    confirmAccountApi,
    validateEmailAccount,
    validateResetPswdLink,
    reValidateEmailAccount
} from "store/auth/auth.api";
import {
    saveUserToken,
    saveRefreshToken,
    getUserToken,
    getRefreshToken,
    removeUserToken,
    removeRefreshToken,
    saveRemember,
    getRemember,
    removeRemember,
} from "utils/localStorage/usersTokensLocalStorage";
import {
    removeTrackerStatus
} from "utils/localStorage/vehicleTrackerStatusLocalStorage";
import jwt_decode from "jwt-decode";
// import { HandleRegister, HandleLogin } from "store/auth/auth.types";
import { HandleLogin } from "store/auth/auth.types";
import { getUserProfileSaga } from "store/userProfile/userProfile.saga";
import { getGuidedSetupSaga } from "store/primarySetup/primarySetup.saga";
import { HandleGetUserProfile } from "store/userProfile/userProfile.types";
import { HandleCleanGuidedSetup } from "store/primarySetup/primarySetup.action";
//import { handleGetSMTPSettingsSaga } from "store/shared/shared.saga";
import { HANDLE_GET_USER_PROFILE } from "store/userProfile/userProfile.types";
import { clearSMTPSettings } from "store/shared/shared.action";
import { NotificationType } from "types/notificationType";
import { showSnackbar } from "store/notifier/notifier.action";
import queryString from 'query-string';
import history from "utils/history/history";

const authRoute = ["/register", "/privacy-policy", "/cookies-policy", "/terms-of-use"]
const adminRoute = ["/admin-accounts", "/add-account-detail", "/privacy-policy", "/cookies-policy", "/terms-of-use"]

// function* registerSaga(action: HandleRegister) {
//     yield put(pendingRegister());

//     try {

//         const { email, password, firstName, lastName, nickName, token, consentNewsOffersUpdates } = action.payload;

//         const { data, error } = yield register({
//             email,
//             password,
//             firstName,
//             lastName,
//             nickName,
//             token,
//             consentNewsOffersUpdates
//         });

//         if (error) {
//             if (error.code) {
//                 yield put(rejectedRegister(error as any));
//             } else {
//                 yield put(rejectedRegister(error.violations as any));
//             }
//             return;
//         }

//         if (data) {
//             yield put(successEmailRegister({ email }))
//         }
//         yield put(resolvedRegister());

//         history.push("/register-success");

//         //yield put(switchGlobalLoader(false));
//     } catch (error) {
//         yield put(rejectedRegister(error.violations as any));
//         return;
//     }
// }

function* setIdForLoggedUserSaga(token: string) {
    const decodedToken = yield jwt_decode(token);
    yield put(setIdForLoggedUser(decodedToken.id));
    //ROLE_ADMIN don't have expPassword
    if (decodedToken.expPassword) {
        yield put(setExpPasswordForLoggedUser(decodedToken.expPassword.date));
    }
}

function* loginSaga(action: HandleLogin) {
    yield put(pendingLogin());

    const { email, password, remember } = action.payload;

    try {
        const { data, error } = yield login({ email, password });

        if (error) {
            yield put(rejectedLogin(error.message));
            return;
        }

        const { token, refresh_token } = data;

        yield call(setIdForLoggedUserSaga, token);
        yield put(resolvedLogin({ token, refresh_token }));

        if (remember) {
            saveRemember({ "username": email, "remember": remember });
        }
        else {
            removeRemember();
        }

        yield saveUserToken(token);
        removeTrackerStatus();
        yield saveRefreshToken(refresh_token);

        const decodedToken = yield jwt_decode(token);

        yield call(updateTokenWhileLoginReset, decodedToken);

    } catch (error) {
        console.log("ERROR: ", error);

        yield put(rejectedLogin("Something went wrong"));
        return;
    }
}

function* updateTokenWhileLoginReset(decodedToken: any) {
    //TODO
    if (decodedToken?.roles.includes("ROLE_MASTER")) {
        const userProfileAction: HandleGetUserProfile = {
            payload: { id: decodedToken.id, isEmployee:false },
            type: HANDLE_GET_USER_PROFILE,
        };
       
        yield call(getUserProfileSaga, userProfileAction);
        const uP = yield select(
            (state: AppState) => state.userProfile.userProfile
        );
        yield put(
            resolvedGetUserProfile({
                ...decodedToken,
                email: uP.email,
                phone: uP.phone,
                employee: uP.employee,
                nickName: uP.nickName,
                profileType: decodedToken.profileType
            })
        );
        //yield call(handleGetSMTPSettingsSaga);
    
        // for guided setup call
        yield call(getGuidedSetupSaga);
        const guidedSetup = yield select(
            (state: AppState) => state.primarySetup.guidedSetup
        );
        if ((!guidedSetup || !guidedSetup.isCompleted) && window.location.pathname !=="/guided-setup") {
            //history.push("/guided-setup");
            if(window.location.pathname !=="/guided-setup"){
                window.location.href = "/guided-setup";
            }
        } else {
           // history.push("/dashboard");
          if(window.location.pathname !=="/dashboard"){
            window.location.href = "/dashboard";
          }
        }
        /* if (!uP.guidedInitialSetup || uP.guidedInitialSetup.Percentage !== 100) {
            history.push("/primary-setup");
        } */
    }
    else if (decodedToken?.roles.includes("ROLE_ADMIN")) {
        yield put(
            resolvedGetUserProfile({
                ...decodedToken,
                email: decodedToken.username,
                firstName: decodedToken.firstName,
                lastName: decodedToken.lastName,
            })
        );
        //yield call(handleGetSMTPSettingsSaga);
    
        if (!adminRoute.includes(window.location.pathname) && window.location.pathname.indexOf('/account-detail') === -1) {
            //history.push("/admin-accounts");
            if(window.location.pathname !=="/admin-accounts"){
                window.location.href = "/admin-accounts";
            }
        }   
    }
    else {
        
        const userProfileAction: HandleGetUserProfile = {
            payload: { id: decodedToken.id, isEmployee: true },
            type: HANDLE_GET_USER_PROFILE,
        };
    
        yield call(getUserProfileSaga, userProfileAction);
        const uP = yield select(
            (state: AppState) => state.userProfile.userProfile
        );
    
        // console.log(
        //   "up czy ma middle title workPhone contactEmail isActive userMasterGroup",
        //   uP
        // );
    
        yield put(
            resolvedGetUserProfile({
                ...decodedToken,
                ...uP,
                email: uP.email,
                middle: uP.middle,
                title: uP.title,
                workPhone: uP.workPhone,
                contactEmail: uP.contactEmail,
                isActive: uP.isActive,
                userMasterGroup: uP.userMasterGroup,
                employee: uP.employee ? uP.employee : ""
            })
        );
        //yield call(handleGetSMTPSettingsSaga);
    
           
         //for guided setup call
        yield call(getGuidedSetupSaga);
        //const guidedSetup = yield select(
        //    (state: AppState) => state.primarySetup.guidedSetup
        //);
        
        // If logged user is Employee as a first time login
        if (decodedToken.isFirstTimeLogin) {
            if(window.location.pathname !=="/account-settings"){
                window.location.href = "/account-settings";
            }
        } else {
            if(window.location.pathname !=="/dashboard"){
                window.location.href = "/dashboard";
            }
        }
            

        /* if (!guidedSetup || !guidedSetup.isCompleted) {
            history.push("/guided-setup");
        } else {
            history.push("/dashboard");
        } */
    }
}

function* loginByTokenSaga(/*action: HandleLoginByToken*/) {
    try {
        if (authRoute.includes(window.location.pathname)) {
            return null;
        } else if (window.location.pathname.includes("/reset-password-confirm")) {
            const parsed:any = queryString.parse(window.location.search);
            try {
                yield call(validateResetPasswordAccount, {
                    email: window.atob(parsed.email),
                    verificationToken: parsed.token
                });
            } catch (e) {
                yield put(confirmEmailSuccessStatus({ code: 404, data: 'Invalid Reset Password Link' }));
            }
            return null;
        }else if (window.location.pathname.includes("/initial-setup")) {
            const parsed:any = queryString.parse(window.location.search);
            try {
                yield call(validateResetPasswordAccount, {
                    email: window.atob(parsed.email),
                    verificationToken: parsed.token
                });
            } catch (e) {
                yield put(confirmEmailSuccessStatus({ code: 404, data: 'Invalid Link' }));
            }
            return null;
        } else if (window.location.pathname.includes("/confirmation")) {
            const parsed:any = queryString.parse(window.location.search);
            try {
                yield call(confirmEmailAccount, {
                    email: window.atob(parsed.email),
                    verificationToken: parsed.token
                });
            } catch (e) {
                yield put(confirmEmailSuccessStatus({ code: 404, data: 'Invalid User' }));
            }

            return null;
        }
        yield put(loginByTokenPending());

        const token = getUserToken();
        const refresh_token = getRefreshToken();
        if (!Boolean(getRemember())) {
            yield call(logoutSaga);
            return;
        }
        yield put(loginByTokenResolved({ token, refresh_token }));
        yield call(setIdForLoggedUserSaga, token);

        yield saveUserToken(token);
        removeTrackerStatus();
        yield saveRefreshToken(refresh_token);
        const decodedToken = yield jwt_decode(token);

        if (decodedToken?.roles.includes("ROLE_MASTER")) {

            const userProfileAction: HandleGetUserProfile = {
                payload: { id: decodedToken.id, isEmployee: false },
                type: HANDLE_GET_USER_PROFILE,
            };

            yield call(getUserProfileSaga, userProfileAction);
            const uP = yield select(
                (state: AppState) => state.userProfile.userProfile
            );

            yield put(
                resolvedGetUserProfile({
                    ...decodedToken,
                    email: uP.email,
                    phone: uP.phone,
                    employee: uP.employee,
                    nickName: uP.nickName,
                    profileType: decodedToken.profileType
                })
            );

            //yield call(handleGetSMTPSettingsSaga);

            // for guided setup call
            yield call(getGuidedSetupSaga);
            const guidedSetup = yield select(
                (state: AppState) => state.primarySetup.guidedSetup
            );
            if ((!guidedSetup || !guidedSetup.isCompleted) && window.location.pathname !=="/guided-setup") {
                window.location.href = "/guided-setup";
                return;
            } else if (guidedSetup && guidedSetup.isCompleted && window.location.pathname === '/guided-setup') {
                window.location.href = "/dashboard";
                return;
            }

            /* if (!uP.guidedInitialSetup || uP.guidedInitialSetup.Percentage !== 100) {
                
                history.push("/primary-setup");
                return;
            } */

            /* else if (uP.guidedInitialSetup.Percentage === 100 && history.location.pathname === '/primary-setup') {
                history.push("/dashboard");
            } */
        } else if (decodedToken?.roles.includes("ROLE_ADMIN")) {
            
            yield put(
                resolvedGetUserProfile({
                    ...decodedToken,
                    email: decodedToken.username,
                    firstName: decodedToken.firstName,
                    lastName: decodedToken.lastName,
                })
            );

            //yield call(handleGetSMTPSettingsSaga);
            if (!adminRoute.includes(window.location.pathname) && window.location.pathname.indexOf('/account-detail') === -1) {
                window.location.href = "/admin-accounts";
            }
            
            return;
        }
        else {

            const userProfileAction: HandleGetUserProfile = {
                payload: { id: decodedToken.id, isEmployee: true },
                type: HANDLE_GET_USER_PROFILE,
            };
            
            yield call(getUserProfileSaga, userProfileAction);
            const uP = yield select(
                (state: AppState) => state.userProfile.userProfile
            );
            
            yield put(
                resolvedGetUserProfile({
                    ...decodedToken,
                    ...uP,
                    email: uP.email,
                    middle: uP.middle,
                    title: uP.title,
                    workPhone: uP.workPhone,
                    contactEmail: uP.contactEmail,
                    isActive: uP.isActive,
                    userMasterGroup: uP.userMasterGroup,
                    employee: uP.employee ? uP.employee : ""
                })
            );
            //yield call(handleGetSMTPSettingsSaga);
            
            // for guided setup call
            yield call(getGuidedSetupSaga);
            const guidedSetup = yield select(
                (state: AppState) => state.primarySetup.guidedSetup
            );
            if ((!guidedSetup || !guidedSetup.isCompleted) && window.location.pathname !=="/guided-setup") {
                window.location.href = "/guided-setup";
                return;
            } else if (uP.guidedSetup && uP.guidedSetup.isCompleted && window.location.pathname === '/guided-setup') {
                window.location.href = "/dashboard";
                return;
            }
            /* if (!uP.guidedInitialSetup || uP.guidedInitialSetup.Percentage !== 100) {
                yield call(handleGetSMTPSettingsSaga);
                history.push("/primary-setup");
                return;
            } */
            
            /* else if (uP.guidedInitialSetup.Percentage === 100 && history.location.pathname === '/primary-setup') {
                history.push("/dashboard");
            } */
        }

        //yield call(handleGetSMTPSettingsSaga);

        history.push(window.location);
    } catch (e) {
        console.log(e);
    }
}

function* logoutSaga() {
    yield put(logoutPending());
    try {
        removeUserToken();
        removeTrackerStatus();
        removeRefreshToken();
        //removeRemember();

        yield put(clearSMTPSettings());

        yield put(handleCleanUser());
        yield put(HandleCleanGuidedSetup());
        yield put(logoutResolved());
        yield put(clearWebsite());
        //if (logoutFlag)
        //        yield put(handleLogout());
        if(window.location.pathname !=="/logout"){
            window.location.href = "/logout";
        }
    } catch (err) {
        console.log(err);
    }
}

function* logoutAndLoginSaga() {
    yield put(logoutPending());
    try {
        removeUserToken();
        removeTrackerStatus();
        removeRefreshToken();
        //removeRemember();

        yield put(clearSMTPSettings());

        yield put(handleCleanUser());
        yield put(HandleCleanGuidedSetup());
        yield put(logoutResolved());
        yield put(clearWebsite());
        if(window.location.pathname !=="/login"){
            window.location.href = "/login";
        } 
       } catch (err) {
        console.log(err);
    }
}

function* refreshTokenSaga() {
    try {
        const refreshToken: string = getRemember()
            ? getRefreshToken()
            : yield select((state: AppState) => state.auth.refreshToken);
        const { data, error } = yield refreshTokenApi(refreshToken);

        // yield put(loginByTokenResolved({ token, refresh_token }));
        // yield call(setIdForLoggedUserSaga, token);
        if (error) {
            // yield call(logoutSaga);
            // yield put(rejectedLogin(error.message));
            return;
        }
        yield saveUserToken(data.token);
        removeTrackerStatus();
        yield saveRefreshToken(data.refresh_token);

        // const decodedToken = yield jwt_decode(token);
        // const userProfileAction: HandleGetUserProfile = {
        //   payload: decodedToken.id,
        //   type: HANDLE_GET_USER_PROFILE,
        // };
        // yield call(getUserProfileSaga, userProfileAction);
        // yield put(resolvedGetUserProfile(decodedToken));
        // // yield call(getUserProfileSaga, userProfileAction);
        
        yield call(loginByTokenSaga);
        // yield put({
        //   type: HANDLE_LOGIN_BY_TOKEN
        // })
        // history.push(history.location);
        //
    } catch (error) {
        if (error.code === 401) yield call(logoutSaga);
        console.error(error);
    }
}

function* resetPasswordRequestSaga(action: HandleResetPasswordRequest) {
    yield put(ResetPasswordRequestPending());
    const { data, error } = yield resetPasswordRequest(action.payload);


    if (error) {
        yield put(ResetPasswordRequestRejected());
        return null;
    }

    if (data && (window.location.pathname.includes("/request-success-page"))) {
        //yield put(showSnackbar(NotificationType.warning, 'Successfully Sent'));
    }


    yield put(ResetPasswordRequestResolved(action.payload));

    if (!(window.location.pathname.includes("/request-success-page"))) {
        yield window.location.href = "/request-success-page";
    }
}

function* resetPasswordConfirmSaga(action: HandleResetPasswordConfirm) {
    yield put(resetPasswordConfirmPending());
    
    const { data, error } = yield resetPasswordConfirm(action.payload);
    if (error) {
        
        if (error.code === 400) {
            yield put(resetPasswordConfirmRejected(error));
            // history.push("/reset-password-success");
        }
        return null;
    }
    yield put(resetPasswordConfirmResolved());

    // todo for getting token and sending to dashboard
    const { token, refresh_token } = data;

    yield call(setIdForLoggedUserSaga, token);
    yield put(resolvedLogin({ token, refresh_token }));

    yield saveUserToken(token);
    removeTrackerStatus();
    yield saveRefreshToken(refresh_token);

    const decodedToken = yield jwt_decode(token);

    yield call(updateTokenWhileLoginReset, decodedToken);

    //history.push("/reset-password-success");
}

function* confirmAccountSaga(action: HandleConfirmAccount) {
    yield put(pendingConfirmAccount());

    const { error } = yield confirmAccountApi(action.payload);
    if (error) {
        yield put(rejectedConfirmAccount(error));
        return;
    }

    yield put(resolvedConfirmAccount());

      if(window.location.pathname !=="/login"){
        yield window.location.href = "/login";
      }
}

function* confirmEmailAccount({
    email,
    verificationToken
}) {
    const { data, error } = yield validateEmailAccount({
        email,
        verificationToken
    });
    if (data) {
        yield put(confirmEmailSuccessStatus({ code: 200, data: 'Success' }));
    }
    if (error) {
        yield put(confirmEmailSuccessStatus({ code: 404, data: 'Invalid User' }));
    }
}

function* validateResetPasswordAccount({
    email,
    verificationToken
}) {
    const { data, error } = yield validateResetPswdLink({
        email,
        verificationToken
    });
    if (data) {
        yield put(ResetPasswordLinkExpired({ code: 200, data: data }));
    }
    if (error) {
        yield put(ResetPasswordLinkExpired({ code: 404, data: 'Invalid Reset Password Link' }));
    }
}

function* resendEmailConfirmation(action: HandleResendEmail) {
    if (action.payload && action.payload.email) {
        const { data, error } = yield reValidateEmailAccount(action.payload);
        if (data) {
            yield put(reConfirmEmailSuccessStatus({ code: 200, data: 'Success' }));
            yield put(showSnackbar(NotificationType.success, 'Successfully Sent'));
        }
        if (error) {
            yield put(reConfirmEmailSuccessStatus({ code: 404, data: 'Invalid User' }));
            yield put(showSnackbar(NotificationType.warning, 'Invalid User'));
        }
    } else {
        yield put(reConfirmEmailSuccessStatus({ code: 404, data: 'Invalid User' }));
        yield put(showSnackbar(NotificationType.warning, 'Invalid User'));
    }

}

export default function* AuthSaga() {
    // yield takeLeading(HANDLE_REGISTER, registerSaga);
    yield takeLeading(HANDLE_LOGIN, loginSaga);
    yield takeLeading(HANDLE_LOGIN_BY_TOKEN, loginByTokenSaga);
    yield takeLeading(HANDLE_LOGOUT, logoutSaga);
    yield takeLeading(HANDLE_LOGOUT_LOGIN, logoutAndLoginSaga);
    yield takeLeading(HANDLE_TOKEN_REFRESH, refreshTokenSaga);
    yield takeLeading(HANDLE_RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);
    yield takeLeading(HANDLE_RESET_PASSWORD_CONFIRM, resetPasswordConfirmSaga);
    yield takeLeading(HANDLE_CONFIRM_ACCOUNT, confirmAccountSaga);
    yield takeLeading(HANDLE_RESEND_EMAIL_CONFIRMATION, resendEmailConfirmation);
}