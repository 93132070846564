import React, { useState } from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  MaskedTextField,
  PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { styles } from "pages/accountSettingsPage/styles";
import { AppState } from "store";
import { handleCreateProspect } from "store/prospects/prospects.action";
import { salutations } from "constants/constants";
import { cancelIcon, iconButtonStyles, dropdownStyles } from "constants/styles";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { handleCheckIfProspectExist } from "store/prospects/prospects.action";
import { CheckIfProspectExistState, LEAD_SOURCE } from "constants/constants";
import { EditProspectDialog } from "components/dialogs/prospects/EditProspectDialog";
import { useEffect } from "react";
import {
  documentReader,
  clearDocumentAndErrorReaderDocument,
} from "store/prospects/prospects.action";
import { colors } from "constants/constants";

const emptyForm = {
  salutation: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  phone: "",
  leadSource: "",
};

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const AddProspectDialog: React.FunctionComponent<IProps> = ({
  isOpen,
  onDismiss,
}) => {
  const [prospectAlreadyExist, setProspectAlreadyExist] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const selectedProspect = useSelector(
    (state: AppState) => state.prospects.selectedProspect
  );

  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) => state.prospects.loading);

  const loadingDocument = useSelector(
    (state: AppState) => state.prospects.loadingDocument
  );
  const document = useSelector(
    (state: AppState) => state.prospects.documentData
  );
  const documentError = useSelector(
    (state: AppState) => state.prospects.errorDocument
  );

  const checkingState = useSelector(
    (state: AppState) => state.prospects.checkingProspectState
  );

  const formik = useFormik({
    initialValues: {
      ...emptyForm,
    },

    validationSchema: Yup.object({
      salutation: Yup.number().required("Required"),
      phone: Yup.string().required("Required"),
      email: Yup.string()
        .email("This field must be valid email user")
        .required("Required"),
      firstName: Yup.string().required("Required"),
      middleName: Yup.string(),
      lastName: Yup.string().required("Required"),
      leadSource: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const {
        salutation,
        firstName,
        middleName,
        lastName,
        email,
        phone,
        leadSource,
      } = values;

      // driverLicense
      // dateOfBirth

      dispatch<any>(
        handleCreateProspect(
          {
            salutation,
            firstName,
            middleName,
            lastName,
            email,
            phone,
            leadSource,
            driverLicense: document?.driverLicense
              ? document?.driverLicense
              : "",
            dateOfBirth: document?.dateOfBirth ? document?.dateOfBirth : "",
          },
          callbackOnAddProspect
        )
      );
    },
  });

  useEffect(() => {
    if (checkingState === CheckIfProspectExistState.dontExist) {
      setProspectAlreadyExist(false);
    } else if (checkingState === CheckIfProspectExistState.exist) {
      setProspectAlreadyExist(true);
    }
  }, [checkingState]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      firstName: document?.firstName
        ? document.firstName
        : formik.values.firstName,
      lastName: document?.lastName ? document.lastName : formik.values.lastName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const callbackOnAddProspect = () => {
    onDismiss();
    formik.resetForm();
  };

  const handleAddProspect = () => {
    console.log("sending form: ", formik);
    formik.handleSubmit();
  };

  const handleEditProspect = () => {
    formik.setFieldValue("email", "");
    setProspectAlreadyExist(false);
    formik.resetForm();
    setShowEditDialog(true);
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const renderDocumentSpinner = () => {
    return loadingDocument ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleDismiss = () => {
    formik.setValues({ ...emptyForm });
    onDismiss();
    dispatch<any>(clearDocumentAndErrorReaderDocument());
  };

  const handleDropdownChange = (e, item) => {
    console.log(e, item);

    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.value,
      },
      true
    );
  };

  const handleEmailValueChange = (e) => {
    const { value, id } = e.target;
    formik.setValues(
      {
        ...formik.values,
        [id]: value,
      },
      true
    );

    // if (!formik.errors.email && formik.dirty) {
    if (!formik.errors.email) {
      dispatch<any>(handleCheckIfProspectExist(value));
    }
  };

  const handleCloseEditDialog = () => {
    setShowEditDialog(false);
    onDismiss();
    dispatch<any>(clearDocumentAndErrorReaderDocument());
  };

  const handleScan = (e) => {
    const uploadedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("id", uploadedFile, "test.jpeg");

    

    dispatch<any>(documentReader(formData));
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={handleDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      {/*<Loader show={loading}/>*/}

      {showEditDialog && selectedProspect && (
        <EditProspectDialog
          onDismiss={handleCloseEditDialog}
          prospect={selectedProspect}
        />
      )}

      <div className={contentStyles.header}>
        <CustomFontText>Add New Prospect</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={handleDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Row gutterWidth={0}>
          <Col
            style={{ width: 600, paddingLeft: 30, paddingRight: 30 }}
            sm={12}
            md={12}
            xl={12}
          >
            <Stack horizontalAlign="start">
              <PrimaryButton
                style={{ marginTop: 10, marginBottom: 10 }}
                text="Load From Document"
                iconProps={{ iconName: "Upload" }}
                onRenderIcon={renderDocumentSpinner}
              >
                <input
                  style={{
                    padding: 20,
                    // border: '1px solid red',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: 140,
                    height: 1,
                    overflow: "hidden",
                    opacity: 0,
                    marginLeft: -10,
                  }}
                  type="file"
                  onChange={handleScan}
                />
              </PrimaryButton>
              {documentError && (
                <span
                  style={{ marginTop: -5, fontSize: 12, color: colors.red }}
                >
                  Couldn't load data from scan!
                </span>
              )}
            </Stack>
            <div>
              <TextField
                autoComplete={"off"}
                id="email"
                name="email"
                label="Email address"
                required={true}
                value={formik.values.email}
                onChange={handleEmailValueChange}
                onBlur={handleEmailValueChange}
                errorMessage={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email.toString()
                    : null
                }
              />
              {checkingState === CheckIfProspectExistState.pending && (
                <Spinner style={{ position: "absolute", right: 40, top: 35 }} />
              )}
            </div>
            {prospectAlreadyExist && (
              <PrimaryButton
                style={{ marginTop: 10 }}
                text="Update Existing Prospect"
                onClick={() => handleEditProspect()}
                iconProps={{ iconName: "Edit" }}
              />
            )}
            {/* (515)-778-0116 */}
            <MaskedTextField
              mask="(999)999-9999"
              id="phone"
              name="phone"
              label="Mobile Phone"
              required={true}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone.toString()
                  : null
              }
            />

            <Row>
              <Col style={{ maxWidth: "120px" }}>
                <Dropdown
                  id="salutation"
                  label="Salutation"
                  selectedKey={
                    formik.values.salutation
                      ? formik.values.salutation
                      : undefined
                  }
                  onChange={handleDropdownChange}
                  required={true}
                  options={salutations}
                  styles={dropdownStyles}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.salutation && formik.errors.salutation
                      ? formik.errors.salutation.toString()
                      : null
                  }
                />
              </Col>
              <Col>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  required={true}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName.toString()
                      : null
                  }
                />
              </Col>
            </Row>
              <TextField
                id="middleName"
                name="middleName"
                label="Middle Name"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.middleName && formik.errors.middleName
                    ? formik.errors.middleName.toString()
                    : null
                }
              />

            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              required={true}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName.toString()
                  : null
              }
            />

            <Dropdown
              id="leadSource"
              label="Lead Source"
              selectedKey={
                formik.values.leadSource ? formik.values.leadSource : ""
              }
              onChange={handleDropdownChange}
              placeholder=""
              options={LEAD_SOURCE}
              required={true}
              styles={dropdownStyles}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.leadSource && formik.errors.leadSource
                  ? formik.errors.leadSource.toString()
                  : null
              }
            />
          </Col>
        </Row>
      </div>
      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{ childrenGap: 10 }}

          // styles={contentStyles.buttonsStyles}
        >
          <DefaultButton text="Close" onClick={handleDismiss} />
          <PrimaryButton
            onRenderIcon={renderSpinner}
            text="Add Prospect and Complete"
            onClick={() => handleAddProspect()}
            disabled={!formik.isValid || !formik.dirty || prospectAlreadyExist}
          />
        </Stack>
      </div>
    </Modal>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {},
  header: {
    flex: "1 1 auto",
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "20px 30px",
  },

  body: {
    // flex: "4 4 auto",
    // padding: "0 24px 24px 24px",
    //   padding: '75px',
    paddingBottom: "40px",
    // overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  footer: {
    padding: "0px 30px",
    paddingBottom: 40,
  },
});
