import React, { useState, FunctionComponent } from "react";
import {
  IStackStyles,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  IconButton,
  Button,
  Stack,
  DatePicker,
  IDatePickerStyles,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { ITask } from "types/taskTypes";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";

const contentStyles = content(600, "auto");
const wysiwygOptions = [
  "inline",
  "blockType",
  "fontSize",
  "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  "link",
  "remove",
];

interface IProps {
  onDismiss: () => void;
  onCreateNewTask: (newTask: ITask) => void;
}

export const AddTaskDialog: FunctionComponent<IProps> = ({
  onDismiss,
  onCreateNewTask,
}) => {
  const [chosenDate, setChosenDate] = useState();
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const loading = useSelector(
    (state: AppState) => state.prospects.loadingAddTask
  );

  const handleDateChange = (e) => {
    setChosenDate(e);
  };

  const handleChange = (e) => {
    setContent(e);
  };

  const handleCreateNewTask = () => {
    const newTask = {
      date: chosenDate,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
    };

    onCreateNewTask(newTask);
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={` ${contentStyles.header}`}>
        <GoBackToMainDialogBtn label="" onClick={onDismiss} />
        <CustomFontText>Add New Task</CustomFontText>

        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack
          styles={stackStyles}
          verticalAlign="start"
          horizontalAlign="space-between"
        >
          <span style={titleStyle}>Set Task Date</span>
          <DatePicker
            className={controlClass.control}
            style={{ marginBottom: 0, width: "100%" }}
            styles={datePickerStyles}
            // firstDayOfWeek={firstDayOfWeek}
            // strings={DayPickerStrings}
            placeholder="or select a date..."
            ariaLabel="Select a date"
            value={chosenDate}
            onSelectDate={(e) => handleDateChange(e)}
            //isMonthPickerVisible={false}
            showGoToToday={false}
          />
        </Stack>

        <Stack
          styles={stackStyles}
          verticalAlign="start"
          horizontalAlign="space-between"
        >
          <span style={titleStyle}>Task Content</span>

          <Editor
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            editorState={content}
            onEditorStateChange={handleChange}
            toolbar={{ options: wysiwygOptions }}
          />
        </Stack>
      </div>

      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div>
            <Button
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />
            <PrimaryButton
              text="Add New Task"
              onClick={() => handleCreateNewTask()}
              onRenderIcon={renderSpinner}
            />
          </div>
        </Stack>
      </div>
    </Modal>
  );
};

const datePickerStyles: IDatePickerStyles = {
  root: {
    width: "100%",
  },
  textField: { width: "100%" },
  callout: {},
  icon: {},
};

const stackStyles: IStackStyles = {
  root: {},
};

const controlClass = mergeStyleSets({
  control: {
    margin: "0 0 15px 0",
    //maxWidth: "300px",
  },
});

const titleStyle = {
  margin: "10px 0",
  fontWeight: 600,
};
