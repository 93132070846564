/* eslint-disable array-callback-return */
import {
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import React, { useEffect } from "react";
/* import {
  TextField as OffTextField
} from "@fluentui/react"; */
import { useFormik } from "formik";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import * as Yup from "yup";
//import { getAddreessByZip } from "services/api/smartyStreets";
//import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { AddCustomizedAdjustmentsDialog } from "components/dialogs/addInventoryDialog/AddCustomizedAdjustmentsDialog";
import { TreeTable } from 'primereact/treetable';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useLocation, useParams } from "react-router-dom";
import { AppState } from "store";
import { handleGetAdjusterList, handleGetSourceWorkBook, handleGetVehSellerDetails, handleGetVehicleUsingID, handlePutAdjusterList, handleSaveSourceWorkBook } from "store/inventory/inventory.action";
// import { DataTable } from 'primereact/datatable';
import { Box } from "@mui/material";
import { Column } from 'primereact/column';
import { classNames } from "primereact/utils";
import { useMediaQuery } from "react-responsive";
import { currencyNegValFormat, numberFormat } from "utils/helperFunctions";



interface ParamTypes {
  vehicleID: string;
}

// const useStyles = makeStyles((theme: ThemeStyle) =>

//   createStyles({
//     root: {
//       flexGrow: 1,
//       width: '100%',
//       backgroundColor: theme?.palette?.background.paper,
//       display: 'block',
//     },
//     heading: {
//       fontSize: theme.typography?.pxToRem(15),
//     },
//     secondaryHeading: {
//       fontSize: theme.typography?.pxToRem(15),
//       color: theme.palette?.text.secondary,
//     },
//     icon: {
//       verticalAlign: 'bottom',
//       height: 20,
//       width: 20,
//     },
//     details: {
//       alignItems: 'center',
//     },
//     column: {
//       flexBasis: '33.33%',
//     },
//     helper: {
//       borderLeft: `2px solid ${theme.palette?.divider}`,
//       padding: theme?.spacing?.(1, 2),
//     },
//     link: {
//       color: theme.palette?.primary.main,
//       textDecoration: 'none',
//       '&:hover': {
//         textDecoration: 'underline',
//       },
//     },
//     breadCrumblink: {
//       display: 'flex',
//     },
//     instructions: {
//       marginTop: theme?.spacing?.(1),
//       marginBottom: theme?.spacing?.(1),
//     },
//     breadCrumbicon: {
//       marginRight: theme?.spacing?.(0.5),
//       width: 20,
//       height: 20,
//     },
//     startTime: {
//     }
//   }),
// );

interface IProps {
  reportsData: any;
  reportsVehInfo: any;
  adjustedData: (items) => void;
}

export const ShopInventoryReportsBB: React.FunctionComponent<IProps> = ({ reportsData, reportsVehInfo, adjustedData }) => {
  //const  classes:any  = useStyles();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes |any>();
  const navigate = useNavigate ();
  const location = useLocation();
  const [auctionTableNode, setauctionTableNode] = React.useState([]);
  const [tradeInTableNode, settradeInTableNode] = React.useState([]);
  const [retailTableNode, setretailTableNode] = React.useState([]);

  // const [showVinField, setshowVinField] = React.useState(true);
  const [isCustomizedAdjustmentsDialog, setisCustomizedAdjustmentsDialog] = React.useState(false);
  const [adjusterList, setadjusterList] = React.useState(null);
  const [disableAdjuster, setdisableAdjuster] = React.useState(false);
  const [adjustedDataUpdated, setAdjustedDataUpdated] = React.useState(false);
  // const [customAdjustTable, setcustomAdjustTable] = React.useState(false);
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  const isMobile = useMediaQuery({ maxWidth: 576 });

  const shopVehicleList = useSelector((state: AppState) => state.inventory.shopVehicleList);

  const formik = useFormik({
    initialValues: {
      searchVin: '',
      make: "",
      model: '',
      year: '',
      mileage: "",
      vin: "",
      shippingFrom: ""
    },
    validationSchema: Yup.object({
      searchVin: Yup.string(),
      make: Yup.string(),
      model: Yup.string(),
      year: Yup.number(),
      mileage: Yup.string(),
      vin: Yup.string(),
      shippingFrom: Yup.string()
    }),
    onSubmit: (values) => {
    },
  });

  const sourceVehicleformik = useFormik({
    initialValues: {
      auctionDate: '',
      auctionStartTime: '',
      url: '',
      name: '',
      location: '',
      sourceType: 'auction',
      sellerType: "business",
      firstName: "",
      lastName: "",
      businessName: "",
      address: "",
      zip: "",
      city: "",
      county: "",
      state: "",
    },
    validationSchema: Yup.object({
      sellerType: Yup.string().required("Required"),
      sourceType: Yup.string().required("Required"),
      auctionDate: Yup.string(),
      auctionStartTime: Yup.string(),
      name: Yup.string(), //.required("Required")
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      ),
      //location: Yup.string(),
      location: Yup.string().matches(/^[aA-zZ0-9\s]+$/, 'Special Characters not allowed'),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      businessName: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
      city: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
      state: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      let startTime;
      if (values.auctionStartTime) {
        let utcStart = moment(values.auctionStartTime, "HH:mm").utc();
        startTime = utcStart.format()
      }
      const sourceData: any = {
        isIndividual: (values.sellerType !== 'business'), // values.setStatus,
        isBusiness: (values.sellerType === 'business'), // values.condition,
        firstName: values.firstName,
        lastName: values.lastName,
        businessName: values.businessName,
        address: values.address,
        zip: values.zip,
        city: values.city,
        county: values.county,
        state: values.state,
        phone: '',
        source: values.sourceType.toLowerCase(),
        sourceData: {
          name: values.name,
          auctionUrl: values.url,
          time: startTime,
          date: values.auctionDate
        },
        shippingFrom: values.location,
        id: params.vehicleID
      };

      dispatch<any>(handleSaveSourceWorkBook(sourceData));
    }
  });

  const getChildAdjustments = (adjustments, tableName, label, low, average, high, excellent, base = false) => {
    let adjustmentValues = []
    if (base) {
      // added for ODO (Mileage)
      adjustmentValues.push(adjustments?.mileageAdjustmentValue);
    } else {
      adjustmentValues = adjustments;
    }
    if (adjustmentValues && adjustmentValues.length > 0) {
      let result = [];
      adjustmentValues.map((data, index) => {
        if ((Array.isArray(data[tableName]) && data[tableName].length) || !(Array.isArray(data[tableName]))) {
          let obj = {
            key: `1-${index}`,
            data: {
              [label]: data.label,
              [low]: currencyNegValFormat(data[tableName].rough),
              [average]: currencyNegValFormat(data[tableName].average),
              [high]: currencyNegValFormat(data[tableName].clean),
              [excellent]: currencyNegValFormat(data[tableName].excellent)
            }
          };
          result.push(obj);
        }
      });
      return result;
    } else {
      return [];
    }
  };

  const getChildBaseAdjustments = (adjustments, tableName, label, low, average, high, excellent, base = false) => {
    let adjustmentValues = []
    if (base) {
      // added for ODO (Mileage)
      adjustmentValues.push(adjustments?.mileageAdjustmentValue);
      adjustmentValues.push(adjustments?.regionalAdjustmentValue);
    } else {
      adjustmentValues = adjustments;
    }

    if (adjustmentValues && adjustmentValues.length > 0) {
      let result = [];
      adjustmentValues.map((data, index) => {
        if ((Array.isArray(data) && data.length) || !(Array.isArray(data))) {
          let childName = 'Odo';
          if (base && index === 1) {
            childName = 'Region';
          }
          let obj = {
            key: `1-${index}`,
            data: {
              [label]: childName,
              [low]: currencyNegValFormat(data.rough),
              [average]: currencyNegValFormat(data.average),
              [high]: currencyNegValFormat(data.clean),
              [excellent]: currencyNegValFormat(data.excellent)
            }
          };
          result.push(obj);
          if (tableName !== '') {
            obj = {
              key: `99-99`,
              data: {
                [label]: tableName
                /*  [low]: '',
                 [average]: '',
                 [high]: '' */
              }
            };
            result.push(obj);
          }
        }
      });
      return result;
    } else {
      return [];
    }
  };

  // Map Table data once the Reports data are modified
  useEffect(() => {
    // auction table
    if (reportsData && reportsData.auction && reportsData.auction.adjustedValue) {
      console.log('reportsData BB', reportsData);
      setauctionTableNode([
        {
          key: "0",
          data: {
            auction: "Base Value",
            rough: currencyNegValFormat(reportsData.auction.baseValue.rough),
            average: currencyNegValFormat(reportsData.auction.baseValue.average),
            clean: currencyNegValFormat(reportsData.auction.baseValue.clean),
            extraClean: currencyNegValFormat(reportsData.auction.baseValue.excellent)
          },
          children: getChildBaseAdjustments(reportsData.auction, reportsData.auction.baseValueDescription, 'auction', 'rough', 'average', 'clean', 'extraClean', true)
        },
        {
          key: "1",
          data: {
            auction: "Adjustments",
            rough: currencyNegValFormat(reportsData.auction.adjustmentValue?.totalAdjustments?.auction.rough),
            average: currencyNegValFormat(reportsData.auction.adjustmentValue?.totalAdjustments?.auction.average),
            clean: currencyNegValFormat(reportsData.auction.adjustmentValue?.totalAdjustments?.auction.clean),
            extraClean: currencyNegValFormat(reportsData.auction.adjustmentValue?.totalAdjustments?.auction.excellent)
          },
          children: getChildAdjustments(reportsData.auction.adjustmentValue?.adjustments, 'auction', 'auction', 'rough', 'average', 'clean', 'extraClean')
        },
        {
          key: "2",
          data: {
            auction: "Vehicle Value",
            rough: currencyNegValFormat(reportsData.auction.adjustedValue.rough),
            average: currencyNegValFormat(reportsData.auction.adjustedValue.average),
            clean: currencyNegValFormat(reportsData.auction.adjustedValue.clean),
            extraClean: currencyNegValFormat(reportsData.auction.adjustedValue.excellent)
          },
          children: []
        },
        {
          key: "3",
          data: {
            auction: "History Adj",
            rough: currencyNegValFormat(reportsData.auction.historyAdj?.rough),
            average: currencyNegValFormat(reportsData.auction.historyAdj?.average),
            clean: currencyNegValFormat(reportsData.auction.historyAdj?.clean),
            extraClean: currencyNegValFormat(reportsData.auction.historyAdj?.excellent)
          },
          children: []
        },
        {
          key: "4",
          data: {
            auction: "History Adj Value",
            rough: currencyNegValFormat(reportsData.auction.historyAdjustVal?.rough),
            average: currencyNegValFormat(reportsData.auction.historyAdjustVal?.average),
            clean: currencyNegValFormat(reportsData.auction.historyAdjustVal?.clean),
            extraClean: currencyNegValFormat(reportsData.auction.historyAdjustVal?.excellent)
          },
          children: []
        }
      ]);
    }

    // retail Table
    if (reportsData && reportsData.retail && reportsData.retail.adjustedValue) {
      setretailTableNode([
        {
          key: "0",
          data: {
            retailLabel: "Base Value",
            rough: currencyNegValFormat(reportsData.retail.baseValue.rough),
            average: currencyNegValFormat(reportsData.retail.baseValue.average),
            clean: currencyNegValFormat(reportsData.retail.baseValue.clean),
            extraClean: currencyNegValFormat(reportsData.retail.baseValue.excellent)
          },
          children: getChildBaseAdjustments(reportsData.retail, reportsData.retail.baseValueDescription, 'retailLabel', 'rough', 'average', 'clean', 'extraClean', true)
        },
        {
          key: "1",
          data: {
            retailLabel: "Adjustments",
            rough: currencyNegValFormat(reportsData.retail.adjustmentValue?.totalAdjustments?.retail.rough),
            average: currencyNegValFormat(reportsData.retail.adjustmentValue?.totalAdjustments?.retail.average),
            clean: currencyNegValFormat(reportsData.retail.adjustmentValue?.totalAdjustments?.retail.clean),
            extraClean: currencyNegValFormat(reportsData.retail.adjustmentValue?.totalAdjustments?.retail.excellent)
          },
          children: getChildAdjustments(reportsData.retail.adjustmentValue?.adjustments, 'retail', 'retailLabel', 'rough', 'average', 'clean', 'extraClean')
        },
        {
          key: "2",
          data: {
            retailLabel: "Vehicle Value",
            rough: currencyNegValFormat(reportsData.retail.adjustedValue.rough),
            average: currencyNegValFormat(reportsData.retail.adjustedValue.average),
            clean: currencyNegValFormat(reportsData.retail.adjustedValue.clean),
            extraClean: currencyNegValFormat(reportsData.retail.adjustedValue.excellent)
          },
          children: []
        },
        {
          key: "3",
          data: {
            retailLabel: "History Adj",
            rough: currencyNegValFormat(reportsData.retail.historyAdj?.rough),
            average: currencyNegValFormat(reportsData.retail.historyAdj?.average),
            clean: currencyNegValFormat(reportsData.retail.historyAdj?.clean),
            extraClean: currencyNegValFormat(reportsData.retail.historyAdj?.excellent)
          },
          children: []
        },
        {
          key: "4",
          data: {
            retailLabel: "History Adj Value",
            rough: currencyNegValFormat(reportsData.retail.historyAdjustVal?.rough),
            average: currencyNegValFormat(reportsData.retail.historyAdjustVal?.average),
            clean: currencyNegValFormat(reportsData.retail.historyAdjustVal?.clean),
            extraClean: currencyNegValFormat(reportsData.retail.historyAdjustVal?.excellent)
          },
          children: []
        }
      ]);
    }

    // Trade-In Table
    if (reportsData && reportsData.tradeIn && reportsData.tradeIn.adjustedValue) {
      settradeInTableNode([
        {
          key: "0",
          data: {
            tradeIn: "Base Value",
            rough: currencyNegValFormat(reportsData.tradeIn.baseValue.rough),
            average: currencyNegValFormat(reportsData.tradeIn.baseValue.average),
            clean: currencyNegValFormat(reportsData.tradeIn.baseValue.clean),
            extraClean: currencyNegValFormat(reportsData.tradeIn.baseValue.excellent)
          },
          children: getChildBaseAdjustments(reportsData.tradeIn, reportsData.tradeIn.baseValueDescription, 'tradeIn', 'rough', 'average', 'clean', 'extraClean', true)
        },
        {
          key: "1",
          data: {
            tradeIn: "Adjustments",
            rough: currencyNegValFormat(reportsData.tradeIn.adjustmentValue?.totalAdjustments?.tradeIn.rough),
            average: currencyNegValFormat(reportsData.tradeIn.adjustmentValue?.totalAdjustments?.tradeIn.average),
            clean: currencyNegValFormat(reportsData.tradeIn.adjustmentValue?.totalAdjustments?.tradeIn.clean),
            extraClean: currencyNegValFormat(reportsData.tradeIn.adjustmentValue?.totalAdjustments?.tradeIn.excellent)
          },
          children: getChildAdjustments(reportsData.tradeIn.adjustmentValue?.adjustments, 'tradeIn', 'tradeIn', 'rough', 'average', 'clean', 'extraClean')
        },
        {
          key: "2",
          data: {
            tradeIn: "Vehicle Value",
            rough: currencyNegValFormat(reportsData.tradeIn.adjustedValue.rough),
            average: currencyNegValFormat(reportsData.tradeIn.adjustedValue.average),
            clean: currencyNegValFormat(reportsData.tradeIn.adjustedValue.clean),
            extraClean: currencyNegValFormat(reportsData.tradeIn.adjustedValue.excellent)
          },
          children: []
        },
        {
          key: "3",
          data: {
            tradeIn: "History Adj",
            rough: (currencyNegValFormat(reportsData.tradeIn.historyAdj?.rough)),
            average: (currencyNegValFormat(reportsData.tradeIn.historyAdj?.average)),
            clean: (currencyNegValFormat(reportsData.tradeIn.historyAdj?.clean)),
            extraClean: (currencyNegValFormat(reportsData.tradeIn.historyAdj?.excellent))
          },
          children: []
        },
        {
          key: "4",
          data: {
            tradeIn: "History Adj Value",
            rough: currencyNegValFormat(reportsData.tradeIn.historyAdjustVal?.rough),
            average: currencyNegValFormat(reportsData.tradeIn.historyAdjustVal?.average),
            clean: currencyNegValFormat(reportsData.tradeIn.historyAdjustVal?.clean),
            extraClean: currencyNegValFormat(reportsData.tradeIn.historyAdjustVal?.excellent)
          },
          children: []
        }
      ]);
    }
    // eslint-disable-next-line
  }, [reportsData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shopVehicleList && shopVehicleList.id) {
      dispatch<any>(handleGetAdjusterList({
        id: shopVehicleList.id,
        reportType: 'blackbook',
        callback: (rawList) => {
          let list = [];
          rawList.map((item) => {
            list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
          });
          setadjusterList(list);
          if (list.length === 0) {
            setdisableAdjuster(true);
          }
        }
      }));
    }

    dispatch<any>(handleGetVehSellerDetails({
      callback: (vehDetails) => {
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (adjustedDataUpdated) {
      if (shopVehicleList && shopVehicleList.id) {
        dispatch<any>(handleGetAdjusterList({
          id: shopVehicleList.id,
          reportType: 'blackbook',
          callback: (rawList) => {
            let list = [];
            rawList.map((item) => {
              list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
            });
            setadjusterList(list);
            if (list.length === 0) {
              setdisableAdjuster(true);
            }
            setAdjustedDataUpdated(false);
          }
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedDataUpdated]);

  useEffect(() => {
    if (shopVehicleList && shopVehicleList.id) {
      formik.setValues(
        {
          ...formik.values,
          'vin': shopVehicleList.vin,
          'make': shopVehicleList.make,
          'model': shopVehicleList.model,
          'year': shopVehicleList.year
        }
      );

      /* if (shopVehicleList.vin) {
        setshowVinField(true);
      } else {
        setshowVinField(false);
      } */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopVehicleList])

  useEffect(() => {
    if (params.vehicleID) {
      // if (!shopVehicleList) {
      dispatch<any>(handleGetVehicleUsingID({
        id: params.vehicleID,
        callback: (response) => {
          if (!response) {
            navigate(`/appraisal`);
          }
        }
      }));

      dispatch<any>(handleGetSourceWorkBook({
        id: params.vehicleID,
        callback: (response) => {
          if (response) {
            if (response.firstName && response.id) {
              let local = null;
              if (response.sourceData?.time) {

                var date = moment.utc(response.sourceData.time).format('YYYY-MM-DD HH:mm:ss');
                var stillUtc = moment.utc(date).toDate();
                local = moment(stillUtc).local().format('HH:mm');
              }

              sourceVehicleformik.setValues(
                {
                  ...sourceVehicleformik.values,
                  'sellerType': response.isBusiness ? 'business' : 'individual',
                  'firstName': response.firstName,
                  'lastName': response.lastName,
                  'businessName': response.businessName,
                  'address': response.address,
                  'zip': response.zip,
                  'city': response.city,
                  'county': response.county,
                  'state': response.state,
                  'sourceType': response.source,
                  'name': response.sourceData?.name,
                  'url': response.sourceData?.auctionUrl,
                  'auctionStartTime': local,
                  'auctionDate': response.sourceData?.date,
                  'location': response.shippingFrom,
                }
              );
            }
          }
        }
      }));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.vehicleID]);

  useEffect(() => {
    // setPathname(location.pathname);
  }, [location]);

  const handleCustomize = () => {
    setisCustomizedAdjustmentsDialog(true);
  }

  const handleUpdateTable = (items) => {
    let adjusters = {
      applied_adjusters: [],
      unapplied_adjusters: [],
    };
    items.map((item) => {
      if (item.checked) {
        adjusters.applied_adjusters.push(item.key);
      } else {
        adjusters.unapplied_adjusters.push(item.key);
      }
    });
    dispatch<any>(handlePutAdjusterList({
      id: shopVehicleList.id,
      reportType: 'blackbook',
      data: adjusters,
      callback: (list) => {
        setAdjustedDataUpdated(true);
        adjustedData(list);
      }
    }));
    // setcustomAdjustTable(true);
    setisCustomizedAdjustmentsDialog(false);
  }

  const rowClassName = (node) => {
    if (node.key && node.key.includes('99-99')) {
      return { 'childRow childBaseRow': (node.key && node.key.includes('-')) };
    } else {
      return { 'childRow': (node.key && node.key.includes('-')) };
    }
  }

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
      'pi-plus-circle': !expanded,
      'pi-minus-circle': expanded
    });

    return (
      <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{ color: '#ffffff', backgroundColor: '#0065cb' }} tabIndex={-1} onClick={options.onClick}>
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className="shopInventory shopInventoryEdit shopInventoryReport">

      {isCustomizedAdjustmentsDialog ?
        <AddCustomizedAdjustmentsDialog
          adjusterList={adjusterList}
          onDismiss={() => setisCustomizedAdjustmentsDialog(false)}
          handleUpdate={(items) => handleUpdateTable(items)}
        /> : null}

      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          bgcolor: 'background.paper',
          display: 'block',
        }}
      >

        <div className="tabSection qValueReport">
          {isMobile ? <>
            <Row className="readOnlyFieldRow">
              <Col xs={4}>
                <strong className="min-width-25">Odo</strong>
                <span>{reportsVehInfo ? numberFormat(reportsVehInfo.mileage) : null}</span>
              </Col>
              <Col xs={8}>
                <strong>Black Book Style</strong>
                <span>{reportsVehInfo?.series} {reportsVehInfo?.style}</span>
              </Col>

            </Row>
            <Row className="readOnlyFieldRow">
              <Col xs={4}>
                <strong className="min-width-25">Zip</strong>
                <span>{reportsVehInfo?.zip}</span>
              </Col>
              <Col xs={8}>
                <strong>Value as of</strong>
                <span>{reportsVehInfo?.valuationDate ? moment(reportsVehInfo?.valuationDate).format("MM/DD/YYYY") : null}</span>
              </Col>
            </Row>
            <Row className="readOnlyFieldRow">
              <Col className="dd-center">
                <PrimaryButton
                  className="select-customize-option"
                  disabled={disableAdjuster}
                  text="Edit Options"
                  style={{ float: "left" }}
                  onClick={() => handleCustomize()}
                // onRenderIcon={renderSpinner}
                />
              </Col>
            </Row>
          </> : <>
            <div className="rowCenterAlignItems" style={{ marginTop: "-25px" }}>
              <Row className="readOnlyFieldRow">
                <Col sm={5} md={5} lg={5}>
                  <strong style={{ minWidth: 0 }}>Odo</strong>
                  <span>{reportsVehInfo ? numberFormat(reportsVehInfo.mileage) : null}</span>
                </Col>
                {/* <Col sm={1}>
                                  </Col> */}
                <Col sm={6} md={6} lg={5}>
                  <strong style={{ minWidth: 0 }}>Zip</strong>
                  <span>{reportsVehInfo?.zip}</span>
                </Col>
              </Row>
              <Row className="readOnlyFieldRow">
                <Col sm={5} md={5} lg={4}>
                  <strong>Black Book Style</strong>
                  <span>{reportsVehInfo?.series} {reportsVehInfo?.style}</span>
                </Col>

                {/* <Col sm={6}>
                                <strong>Value date</strong>
                                <span>{reportsVehInfo?.valuationDate ? moment(reportsVehInfo?.valuationDate).format("MM/DD/YYYY") : null}</span>
                              </Col> */}
                {/* <Col sm={1}>
                                  </Col> */}
              </Row>
              <Row className="readOnlyFieldRow">
                <Col sm={5} md={5} lg={5}>
                  <strong>Value as of</strong>
                  <span>{reportsVehInfo?.valuationDate ? moment(reportsVehInfo?.valuationDate).format("MM/DD/YYYY") : null}</span>
                </Col>
                <Col sm={6} md={6} lg={5} className="dd-center">
                  <PrimaryButton
                    //className="select-customize-option"
                    className="selectOptionsButInventory"
                    disabled={disableAdjuster}
                    text="Edit Options"
                    style={{ float: "left" }}
                    onClick={() => handleCustomize()}
                  // onRenderIcon={renderSpinner}
                  />
                </Col>
              </Row>
            </div>
          </>
          }


          <div className="venderBookExpand  tablePadSizeWidth tableReport workBookReportRetail  workBookReport alignCenterBB">
            <div className="card reportsTableVal">
              <TreeTable value={auctionTableNode} selectionMode="single" togglerTemplate={togglerTemplate} rowClassName={rowClassName}
                /* selectionKeys={selectedNodeKey1} onSelectionChange={e => setSelectedNodeKey1(e.value)} */>
                <Column field="auction" header="Wholesale" expander></Column>
                <Column field="rough" header="Rough"></Column>
                <Column field="average" header="Average"></Column>
                <Column field="clean" header="Clean"></Column>
                <Column field="extraClean" header="Extra Clean"></Column>
              </TreeTable>
            </div>
          </div>

          <div className="venderBookExpand  tablePadSizeWidth tableReport workBookReportRetail  workBookReport alignCenterBB">
            <div className="card reportsTableVal">
              <TreeTable value={tradeInTableNode} selectionMode="single" togglerTemplate={togglerTemplate} rowClassName={rowClassName}
                /* selectionKeys={selectedNodeKey1} onSelectionChange={e => setSelectedNodeKey1(e.value)} */>
                <Column field="tradeIn" header="Trade In" expander></Column>
                <Column field="rough" header="Rough"></Column>
                <Column field="average" header="Average"></Column>
                <Column field="clean" header="Clean"></Column>
                {/*<Column field="extraClean" header="Extra Clean"></Column>*/}
              </TreeTable>
            </div>
          </div>


          <div className="venderBookExpand tablePadSizeWidth tableReport workBookReportRetail  workBookReport alignCenterBB">
            <div className="card reportsTableVal">
              <TreeTable value={retailTableNode} selectionMode="single" rowClassName={rowClassName}
                /* selectionKeys={selectedNodeKey1} onSelectionChange={e => setSelectedNodeKey1(e.value)} */>
                <Column field="retailLabel" header="Retail" expander></Column>
                <Column field="rough" header="Rough"></Column>
                <Column field="average" header="Average"></Column>
                <Column field="clean" header="Clean"></Column>
                <Column field="extraClean" header="Extra Clean"></Column>
              </TreeTable>
            </div>
          </div>

          <div className="  workBookReport rowCenterAlignItems">
            <div className="card reportsTableVal">
              <div>
                <img alt="BB Report" height="23.5" width="80" style={{ float: "left" }} src={`${require("assets/PB_bb.png")}`} />
              </div>
              <div style={{ fontSize: 10 }}>
                &copy;{new Date().getFullYear()} Hearst Business Media Corp. ALL RIGHTS RESERVED. Black Book&reg; is a registered trademark of Hearst Business Media Corp.
              </div>
            </div>
          </div>

        </div>
      </Box>
    </Stack>
  );
};
