import React, { useEffect } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  Spinner,
  SpinnerSize,
  ISpinnerStyles,
} from "@fluentui/react/lib/Spinner";
import { useMediaQuery } from "react-responsive";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton } from "@fluentui/react";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "./styles";
import { handleResetPasswordRequest, clearServerErrorMessage } from "store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppState } from "store";
import { useNavigate  } from "react-router-dom";
import * as Yup from "yup";
import { queries } from "constants/constants";
//import PasswordTextInput from "components/customInputs/passwordTextInput/PasswordTextInput";

const spinnerStyles: ISpinnerStyles = {
  root: {
    position: "absolute",
    right: 220,
  },
};

const RecoverMyAccountRequestPage: React.FC = () => {
  const loading = useSelector((state: AppState) => state.auth.loading);
  const serverErrors = useSelector(
    (state: AppState) => state.auth.resetPasswordReqError
  );

  const serverError = useSelector((state: AppState) => state.auth.error);
  const isTablet = useMediaQuery(queries.tablet);
  const dispatch = useDispatch();
  const navigate = useNavigate ();

  useEffect(() => {
      dispatch<any>(clearServerErrorMessage());
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

    const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(3, "Must be at least 3 characters")
        .email("Field must be valid email user")
        .required("Required"),
    }),
    onSubmit: (values) => {
      dispatch<any>(handleResetPasswordRequest(values.email));
    },
  })


  const handleClick = async () => {
      dispatch<any>(clearServerErrorMessage());
      formik.handleSubmit();
  };

  const handleBackToLogin = async () => {
      dispatch<any>(clearServerErrorMessage());
      navigate("/login");
  };


  useEffect(() => {
    console.log('server error');
    if (serverErrors !== null) {
      console.log('server error serverErrors', serverErrors);
    }
    // eslint-disable-next-line
  }, [serverErrors]);

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <AuthLayoutPage>
      <form noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick().then();
        }}
      >
        <Stack.Item
          className="loginPage-greenTop "
          styles={
            isTablet ? styles.stackTabletItemStyles : styles.stackItemStyles
          }
        >
          <Stack 
            verticalAlign="space-between"
            styles={styles.loginContentStyles}
            
          >
            <Stack.Item>
              <span className="formTitle loginFormRequestHeader">Reset my Password</span>
            </Stack.Item>
            <Stack.Item>
            <div className="loginFormRequestTest loginFormRequest ">Enter the email address used to setup your account</div>
            </Stack.Item>
            
            {
                serverErrors ? <p className="genericErrorColor"><span>{serverErrors}</span></p> : null
            }
            <Stack.Item>
              <Stack className="commonForm loginFormRequest loginFormRequestEmailField"tokens={styles.textFields}>
             
                <TextField
                  required={true}
                  id="email"
                  name="email"
                  label="Email"
                  placeholder=""
                  //iconProps={styles.usernameIcon}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={
                    serverError
                      ? serverError.toString()
                      : formik.touched.email && formik.errors.email
                      ? formik.errors.email.toString()
                      : null
                  }
                />
             {/*<PasswordTextInput
              id="password"
              name="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                  formik.touched.password && formik.errors.password
                  ? formik.errors.password.toString()
                  : null
              }
            />
             <PasswordTextInput
              id="confirmPassword"
              name="confirmPassword"
              label="Re-enter Password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword.toString()
                  : null
              }
            />*/}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack className="loginFormRequest loginFormRequestButton" tokens={styles.buttonTokens}>
                <PrimaryButton
                  //disabled={!formik.dirty || !formik.isValid}
                  type="submit"
                  onRenderIcon={renderSpinner}
                  text="Reset Password"
                  onClick={handleClick}
                  className="btn-btwSpace"
                />
                {/*<DefaultButton text="Register" onClick={handleRegisterClick} />*/}
                <PrimaryButton text="Back to Login"
                  onClick={handleBackToLogin}
                  className="btn-btwmarginSpace"
              />
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </form>
    </AuthLayoutPage>
  );
};

export default RecoverMyAccountRequestPage;
