import * as React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import {
  Stack,
  IStackStyles,
  IStackTokens
} from "@fluentui/react";
import { connect } from "react-redux";
import { colors } from "constants/constants";
import { Pagination } from "components/table/pagination/Pagination";
import { EditEmployeeDialog } from "components/dialogs/employees/EditEmployeeDialog";
import {
  HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
  SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
} from "store/employees/employees.types";
import { HANDLE_ADD_EMPLOYEE_TO_GROUP } from "store/groups/groups.types";
// import {
//
//   ISearchBoxStyles,
// } from "@fluentui/react/lib/SearchBox";
// import { ConfigureGroupDropdown } from "./dropdown/ConfigureGroupDropdown";
import { IEmployee } from "types/employeesTypes";

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: "auto",
  },
};

export interface IDocument {
  id: number | string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  group: string;
  phone: string;
  email: string;
  userMasterGroup: any;
  actions: object;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 20,
    paddingTop: 20,
  },
};

// const cityCellStyles: IStackStyles = {
//   root: {
//     selectors: {
//       ":hover": {
//         color: DefaultPalette.themePrimary,
//         cursor: "pointer",
//       },
//     },
//   },
// };

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

// const cellTokens: IStackTokens = {
//   childrenGap: 10,
// };
//
// const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 300 } };

interface IProps {
  employees: IEmployee[];
  totalItems: number;
  currentPage: number;
  loading: boolean;
  loadingRemoveFromAddToGroup: boolean;
  selectedGroupId: number;
  rowsPerPage: number;
  rowsPerPageAddGroupEmployees: number;
  createdGroupId: number;
  addEmployeeToGroup: Function;
  handleGetEmployees: Function;
  setRowsPerPageAddGroupEmployees: Function;
  onSelection: (selection: any) => void;
  noActions: boolean;
  employeesToAdd: (employees: any) => void;
}
interface IState {
  items: IEmployee[];
  columns: IColumn[];
  selectionDetails: any;
  hovered: number;
  selectedInHeaderDropDown: any;
  isEditDialogOpen: boolean;
  employeeId: number;
}

class NewConfigureGroupAssignTable extends React.Component<IProps, IState> {
  private readonly _selection: Selection;

  constructor(props: IProps) {
    super(props);

    const columns = [
      {
        key: "column1",
        name: "First name",
        fieldName: "firstName",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Last name",
        fieldName: "lastName",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Group",
        fieldName: "userMasterGroups",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IDocument) => {
          return this.props.noActions ? null : (
            <span
              style={{
                color:
                  item.userMasterGroup === undefined
                    ? colors.red
                    : colors.lighterBlack,
              }}
            >
              {item.userMasterGroup === undefined
                ? "unassigned"
                : item.userMasterGroup.text}
            </span>
          );
        },
      },
      // {
      //     key: "column4",
      //     name: "Actions",
      //     fieldName: "actions",
      //     minWidth: 150,
      //     maxWidth: 150,
      //     isRowHeader: true,
      //     isResizable: true,
      //     isSorted: true,
      //     isSortedDescending: false,
      //     sortAscendingAriaLabel: "Sorted A to Z",
      //     sortDescendingAriaLabel: "Sorted Z to A",
      //     data: "string",
      //     isPadded: true,
      //     onRender: (item: IDocument) => {
      //         return this.props.noActions ? null : (
      //             <div
      //                 style={{
      //                     display: this.state.hovered === item.id ? "block" : "none",
      //                 }}
      //             >
      //                 <Stack
      //                     styles={cityCellStyles}
      //                     tokens={cellTokens}
      //                     horizontal
      //                     verticalAlign="center"
      //                     horizontalAlign="space-between"
      //                 >
      //                     <Stack
      //                         onClick={() => this.handleAssign(item)}
      //                         horizontal
      //                         verticalAlign="center"
      //                         tokens={{ childrenGap: 5 }}
      //                     >
      //                         <FontIcon
      //                             style={{ color: DefaultPalette.themePrimary }}
      //                             iconName="CirclePlus"
      //                         />
      //                         <span style={{ color: DefaultPalette.themePrimary }}>
      //         Assign
      //       </span>
      //                     </Stack>
      //                 </Stack>
      //             </div>
      //         );
      //     },
      // },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: [],
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      selectedInHeaderDropDown: null,
      isEditDialogOpen: false,
      employeeId: null,
    };
  }

  // public resetSelection() {
  //   this._selection.setAllSelected(false);
  // }

  private callbackAddEmployeeToGroup() {
    this._selection.setAllSelected(false);
  }

  // private handleAssign(item) {
  //   this.props.addEmployeeToGroup({
  //     employeeIds: [item.id],
  //     groupId: this.props.selectedGroupId,
  //     callback: this.callbackAddEmployeeToGroup.bind(this),
  //   });
  //
  //   this._selection.setAllSelected(false);
  //
  //   // }
  // }

  public componentDidMount() {
    this.props.handleGetEmployees({
      page: this.props.currentPage,
      limit: this.props.rowsPerPageAddGroupEmployees,
      withoutGroup: this.props.selectedGroupId,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }

    if (this.state.employeeId !== prevState.employeeId) {
    }

    if (this.state.selectionDetails !== prevState.selectionDetails) {
      if (typeof this.props.onSelection === "function") {
        this.props.onSelection(this._selection.getSelection());
      }
    }
  }

  // private _resetColumns() {
  //   this.setState({
  //     columns: [...this.state.columns],
  //   });
  // }

  private _closeEditEmployeeDialog() {
    this.setState({
      isEditDialogOpen: false,
      columns: [...this.state.columns],
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState({
      columns: newColumns,
    });
  }

  private _onRowsNumberChange = (rowsPerPage: number) => {
    this.props.setRowsPerPageAddGroupEmployees(rowsPerPage);

    this.props.handleGetEmployees({
      page: this.props.currentPage,
      limit: rowsPerPage,
      withoutGroup: this.props.createdGroupId,
    });
  };

  private _handlePageChange = (page: number) => {
    this.props.handleGetEmployees({
      page: page,
      limit: this.props.rowsPerPageAddGroupEmployees,
      withoutGroup: this.props.createdGroupId,
    });
  };

  private _handleAssign() {
    const selectedItems = this._selection.getSelection() as IEmployee[];

    console.log(selectedItems, this.props.selectedGroupId);
    

    const selectedItemsIds = selectedItems?.map((item) => item.id);

    this.props.addEmployeeToGroup({
      employeeIds: selectedItemsIds,
      groupId: this.props.selectedGroupId as number,
      callback: () => this._selection.setAllSelected(false),
    });
  }

  render() {
    const { columns } = this.state;
    return (
      <Fabric>
        {this.state.isEditDialogOpen && (
          <EditEmployeeDialog
            // resetEmployeeId={this.resetEmployeeId.bind(this)}
            employeeId={this.state.employeeId}
            isOpen={this.state.isEditDialogOpen}
            onDismiss={this._closeEditEmployeeDialog.bind(this)}
          />
        )}

        <div>
          <DetailsList
            styles={tableStyles}
            items={this.props.employees}
            columns={columns}
            onRenderDetailsFooter={() => (
              <div style={{ marginTop: 30 }}>
                <Pagination
                  rowsPerPage={this.props.rowsPerPageAddGroupEmployees}
                  totalItems={this.props.totalItems}
                  currentPage={this.props.currentPage.toString()}
                  onRowsNumberChange={this._onRowsNumberChange.bind(this)}
                  onPageChange={this._handlePageChange.bind(this)}
                />
              </div>
            )}
            onRenderDetailsHeader={(props, defaultRender) => {
              return this.props.noActions ? null : (
                <>
                  <Stack
                    tokens={stackTokens}
                    styles={stackStyles}
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                  >
                    {/*<Stack*/}
                    {/*  horizontal*/}
                    {/*  verticalAlign="center"*/}
                    {/*  style={{ marginLeft: -10 }}*/}
                    {/*>*/}
                    {/*  <p*/}
                    {/*    style={{*/}
                    {/*      color: DefaultPalette.themePrimary,*/}
                    {/*      width: 100,*/}
                    {/*      paddingTop: 25,*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    {selectionDetails.length === 0*/}
                    {/*      ? "No items selected"*/}
                    {/*      : selectionDetails.length + " items selected"}*/}
                    {/*  </p>*/}
                    {/*  <p>*/}
                    {/*    {selectionDetails.length > 0 && (*/}
                    {/*      <ConfigureGroupDropdown*/}
                    {/*        onAssign={() => this._handleAssign()}*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*  </p>*/}
                    {/*</Stack>*/}
                    {/*<SearchBox*/}
                    {/*  styles={searchBoxStyles}*/}
                    {/*  // styles={searchBoxStyles}*/}
                    {/*  placeholder="search in the table..."*/}
                    {/*  onEscape={(ev) => {*/}
                    {/*    console.log("Custom onEscape Called");*/}
                    {/*  }}*/}
                    {/*  onClear={(ev) => {*/}
                    {/*    console.log("Custom onClear Called");*/}
                    {/*  }}*/}
                    {/*  onChange={(_, newValue) =>*/}
                    {/*    console.log("SearchBox onChange fired: " + newValue)*/}
                    {/*  }*/}
                    {/*  onSearch={(newValue) =>*/}
                    {/*    console.log("SearchBox onSearch fired: " + newValue)*/}
                    {/*  }*/}
                    {/*/>*/}
                  </Stack>

                  <span>{defaultRender(props)}</span>
                </>
              );
            }}
            onRenderRow={(props, defaultRender) => {
              return (
                <div
                  onMouseEnter={() => {
                    this.setState({ hovered: props.item.id });
                  }}
                  onMouseLeave={() => this.setState({ hovered: null })}
                >
                  {defaultRender(props)}
                </div>
              );
            }}
            onRenderCheckbox={(props, defaultRender) => {
              return <span>{defaultRender(props)}</span>;
            }}
            selection={this._selection}
            selectionMode={SelectionMode.multiple}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onColumnHeaderClick={(ev, column) =>
              this._onColumnClick(ev, column)
            }
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={() => {}}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
          />
        </div>
      </Fabric>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.employees?.employeesForAddGroupAssignEmployee?.results,
    totalItems: state.employees.employeesForAddGroupAssignEmployee?.totalItems,
    currentPage:
      state.employees.employeesForAddGroupAssignEmployee?.currentPage,
    loading: state.employees.loadingGetEmployeesForAddGroupAssignEmployee,
    loadingRemoveFromAddToGroup: state.groups.loadingRemoveFromAddToGroup,
    selectedGroupId: state.groups.selectedGroup?.id,
    rowsPerPage: state.employees.rowsPerPage,
    rowsPerPageAddGroupEmployees:
      state.employees.rowsPerPageForAddGroupAssignEmployee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEmployeeToGroup: (payload) => {
      return dispatch({
        type: HANDLE_ADD_EMPLOYEE_TO_GROUP,
        payload,
      });
    },
    handleGetEmployees: (payload) =>
      dispatch({
        type: HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
        payload,
      }),
    setRowsPerPageAddGroupEmployees: (payload) =>
      dispatch({
        type: SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE,
        payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewConfigureGroupAssignTable);
