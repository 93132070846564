import { ETypes, IPermission } from "types/groupPermissionTypes";
import { permissionsModules } from "constants/constants";

export const getModulePermissions = (
    permissionGroups: IPermission[],
    module: keyof typeof permissionsModules
): IPermission[] => {
    return permissionGroups?.filter(
        (permission: IPermission) => permission.moduleName === module
    );
};

export const getEmployeeScreenPermissions = (
    permissionScreenName: any[],
    module: keyof typeof permissionsModules
): IPermission[] => {
    return permissionScreenName?.filter(
        (permission: IPermission) => permission.moduleName === module
    );
};

export const isPermitted = (
    groupAuths: IPermission[],
    type: ETypes,
    alias: keyof typeof permissionsModules,
    roles: string[]
): boolean => {
    const role = isUserMaster(roles);
    if (role) return true;

    const cred = groupAuths?.find(
        (group: any) => group.type === type && group.aliasName === alias.toString()
    );
    return cred ? true : false;
};

export const isEmployeePermitted = (
    permissionList: any[],
    alias: any, // keyof typeof emplyeePermissionsAction || keyof typeof emplyeePermissionsScreen
    roles: string[]
): boolean => {
    const role = isUserMaster(roles);
    if (role) return true;
    const cred = (permissionList && (permissionList.length > 0)) ? permissionList.find(element => element === (alias?.toString())) : true;
    return cred ? true : false;
};

export const isUserMaster = (roles: string[]): boolean => {
    return roles && roles.find((role: string) => role === "ROLE_MASTER")
        ? true
        : false;
};

export const isKarlProfile = (profileType: string): boolean => {
    if (profileType)
        return profileType === "PROFILE_TYPE_KARL";
    return false;
};

export const isDefaultProfile = (profileType: string): boolean => {
    if (profileType)
        return profileType === "PROFILE_TYPE_DEFAULT";
    return false;
};


//export const getWriteUpsElementPermission = (locationId: number, user: any): any => {
//    if (user) {
//        if (user.roles && user.roles.some(x => x === "ROLE_MASTER")) {
//            return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: 2 };
//        }
//        if (user.defaultGroups) {
//            if (user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19)) {
//                return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: 2 };
//            }
//            if (user.defaultGroups.some(x => (x.defaultGroupId === 7 || x.defaultGroupId === 8) && x.locationId === locationId)) {
//                return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: 1 };
//            }
//            if (user.defaultGroups.some(x => (x.defaultGroupId === 5 || x.defaultGroupId === 6) && x.locationId === locationId)) {
//                return { status: 2, deliveryDate: 1, deliveryTime: 1, cdrDate: 1 };
//            }
//            if (user.defaultGroups.some(x =>
//                (x.defaultGroupId === 11 || x.defaultGroupId === 13 || x.defaultGroupId === 16 || x.defaultGroupId === 17)
//                && x.locationId === locationId)) {
//                return { status: 1, deliveryDate: 1, deliveryTime: 1, cdrDate: 1 };
//            }
//        }
//    }
//    return { status: 0, deliveryDate: 0, deliveryTime: 0, cdrDate: 0 };
//};

export const getWriteUpsElementPermission = (user: any): any => {
    if (user) {
        if (user.roles && user.roles.some(x => x === "ROLE_MASTER")) {
            return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: 2 };
        }
        if (user.defaultGroups) {
            const status = user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19 || x.defaultGroupId === 7 || x.defaultGroupId === 8 || x.defaultGroupId === 5 || x.defaultGroupId === 6) ? 2 : 1;
            const deliveryDate = user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19 || x.defaultGroupId === 7 || x.defaultGroupId === 8) ? 2 : 1;
            const deliveryTime = user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19 || x.defaultGroupId === 7 || x.defaultGroupId === 8) ? 2 : 1;
            const cdrDate = user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19 || x.defaultGroupId === 17) ? 2 : 1;
            return { status, deliveryDate, deliveryTime, cdrDate };
            //if (user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 19)) {
            //    return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: 2 };
            //}
            //if (user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8)) {
            //    return { status: 2, deliveryDate: 2, deliveryTime: 2, cdrDate: user.defaultGroups.some(x => x.defaultGroupId === 17) ? 2 : 1 };
            //}
            //if (user.defaultGroups.some(x => x.defaultGroupId === 5 || x.defaultGroupId === 6 || x.defaultGroupId === 17)) {
            //    return { status: user.defaultGroups.some(x => x.defaultGroupId === 5 || x.defaultGroupId === 6)? 2 : 1, deliveryDate: 1, deliveryTime: 1, cdrDate: user.defaultGroups.some(x => x.defaultGroupId === 17) ? 2 : 1 };
            //}
            //if (user.defaultGroups.some(x =>
            //    x.defaultGroupId === 11 || x.defaultGroupId === 13 || x.defaultGroupId === 16 || x.defaultGroupId === 18)) {
            //    return { status: 1, deliveryDate: 1, deliveryTime: 1, cdrDate: 1 };
            //}
        } else {
            return { status: 1, deliveryDate: 1, deliveryTime: 1, cdrDate: 1 };
        }
    }
    return { status: 0, deliveryDate: 0, deliveryTime: 0, cdrDate: 0 };
};

export const getTradeInElementPermission = (user: any, writeUpsId: number): any => {
    if (user) {
        if (user.roles && user.roles.some(x => x === "ROLE_MASTER")) {
            return { appraisedBy: 2, acv: 2, rollback: 2};
        }
        if (user.defaultGroups) {
            if (!writeUpsId) {
                if (user.defaultGroups.some(x => x.defaultGroupId === 13 || x.defaultGroupId === 19)) {
                    return { appraisedBy: 2, acv: 2, rollback: 2 };
                }
                //if (user.defaultGroups.some(x => x.defaultGroupId === 4)) {
                //    return { appraisedBy: 2, acv: 0, rollback: 2 };
                //}
            } else {
                if (user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 13 || x.defaultGroupId === 19)) {
                    return { appraisedBy: 2, acv: 2, rollback: 2 };
                }
                if (user.defaultGroups.some(x => x.defaultGroupId === 7 || x.defaultGroupId === 8)) {
                    return { appraisedBy: 1, acv: 0, rollback: 1 };
                }
            }
            
        } else {
            return { appraisedBy: 1, acv: 0, rollback: 1};
        }
    }
    return { appraisedBy: 0, acv: 0, rollback: 0 };
};

export const getVehicleVinEditPermission = (user: any): any => {
    if (user) {
        if (user.roles && user.roles.some(x => x === "ROLE_MASTER")) {
            return true;
        }
        if (user.defaultGroups) {
            if (user.defaultGroups.some(x => x.defaultGroupId === 4 || x.defaultGroupId === 5 || x.defaultGroupId === 19)) {
                return true;
            }
        }
    }
    return false;
};