import * as actions from "./website.types";
import { ITemplate, IWebsite } from "types/websiteTypes";

interface IState {
  loading: boolean;
  templates: ITemplate[];
  website: IWebsite;
  error: { code: number, message: string} ;
}

const initialState: IState = {
  loading: false,
  templates: [],
  website: null,
  error: null,
};

export const WebsiteReducer = (
  state: IState = initialState,
  action: actions.WebsiteActions
) => {
  switch (action.type) {
    case actions.GET_TEMPLATES_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_TEMPLATES_RESOLVED: {
      return {
        ...state,
        loading: false,
        templates: action.payload,
      };
    }
    case actions.GET_TEMPLATES_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.GET_WEBSITE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.GET_WEBSITE_RESOLVED: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    }
    case actions.GET_WEBSITE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload

      }
    }
    case actions.UPDATE_WEBSITE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.UPDATE_WEBSITE_RESOLVED: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    }
    case actions.UPDATE_WEBSITE_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }

    case actions.CLEAR_WEBSITE: {
      return {
        ...state,
        website: null,
        error: null
      }
    }
    default:
      return state;
  }
};
