import * as actions from "store/notifications/notifications.types";


export const handleGetNotifications = () => {
  return {
    type: actions.HANDLE_GET_NOTIFICATIONS,
  };
};

export const pendingGetNotifications = (): actions.GetNotificationsPending => {
  return {
    type: actions.GET_NOTIFICATIONS_PENDING,
  };
};

export const resolvedGetNotifications = (
  notifications: any[]
): actions.GetNotificationsResolved => {
  return {
    type: actions.GET_NOTIFICATIONS_RESOLVED,
    payload: notifications,
  };
};

export const rejectedGetNotifications = (
  error: any
): actions.GetNotificationsRejected => {
  return {
    type: actions.GET_NOTIFICATIONS_REJECTED,
    payload: error,
  };
};

export const handleGetNotificationsTypes = () => {
  
  return {
    type: actions.HANDLE_GET_NOTIFICATIONS_TYPES,
  };
};

export const pendingGetNotificationsTypes = (): actions.GetNotificationsTypesPending => {
  return {
    type: actions.GET_NOTIFICATIONS_TYPES_PENDING,
  };
};

export const resolvedGetNotificationsTypes = (
  notifications: any[]
): actions.GetNotificationsTypesResolved => {
  return {
    type: actions.GET_NOTIFICATIONS_TYPES_RESOLVED,
    payload: notifications,
  };
};

export const rejectedGetNotificationsTypes = (
  error: any
): actions.GetNotificationsTypesRejected => {
  return {
    type: actions.GET_NOTIFICATIONS_TYPES_REJECTED,
    payload: error,
  };
};

/** UPDATE_NOTIFICATION */
export const handleUpdateNotification = ({
  id,
  status,
}: {
  id: number | string;
  status: any;
}): actions.HandleUpdateNotification => {
  
  return {
    type: actions.HANDLE_UPDATE_NOTIFICATION,
    payload: { id, status },
  };
};

export const pendingUpdateNotification = (): actions.UpdateNotificationPending => {
  return {
    type: actions.UPDATE_NOTIFICATION_PENDING,
  };
};

export const resolvedUpdateNotification = (): actions.UpdateNotificationResolved => {
  return {
    type: actions.UPDATE_NOTIFICATION_RESOLVED,
  };
};

export const rejectedUpdateNotification = (
  error
): actions.UpdateNotificationRejected => {
  return {
    type: actions.UPDATE_NOTIFICATION_REJECTED,
    payload: error,
  };
};
