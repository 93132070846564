import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    IStackStyles,
    ITextFieldProps,
    Link,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
// import { useLocation } from "react-router-dom";
//import LocationsTable from "components/table/locations/LocationsTable";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { AccountMoreActionsSubmenu } from "components/table/accounts/dropdown/AccountMoreActionsSubmenu";
import { useDispatch } from "react-redux";
import { AppState } from "store/index";
//import { styles } from "components/dialogs/locations/styles";
// import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { CustomFontText } from "components/customFontText/CustomFontText";
import CustomTable from "components/table/datatable/CustomTable";
import moment from "moment";
import { useNavigate  } from "react-router-dom";
import { handleAccountActivate, handleExportCsvAccount, handleSendInvite } from "store/accounts/accounts.action";
import {
    HANDLE_GET_ACCOUNTS,
} from "store/accounts/accounts.types";


const container: IStackStyles = {
    root: {
        marginTop: 50,
    },
};

export const AdminAccountPage = () => {
    const navigate = useNavigate ();
    const dispatch = useDispatch();
    const activateDeactivateError = useSelector((state: AppState) => state.accounts.accountActivateError) as any;
    const serverError = useSelector((state: AppState) => state.accounts.serverError) as any;
    //const [accountId, setAccountId] = useState(0);
    const [accountList, setAccountList] = useState([]);
    const [activeFlag, setActiveFlag] = useState(1);
    const [commonServerError, setcommonServerError] = useState(null);

    const user = useSelector((state: AppState) => state.userProfile.userProfile);

    const accounts = useSelector((state: AppState) => state.accounts.accounts) as any;

    const [glFilter, setGLFilter] = useState('');

    const handleActiveInactiveUsers = (id) => {
        setActiveFlag(activeFlag === 1 ? 0 : 1);
        dispatch<any>({ type: HANDLE_GET_ACCOUNTS, payload: { isActive: activeFlag === 1 ? 0 : 1 } })
    };


    const handleFirstNameFilter = (val: any) => {
        setGLFilter(val.target.value);
    };

    useEffect(() => {
        if (accounts) {
            if (accounts.results.length > 0) {
                let results = accounts.results;
                for (var i in results) {
                    results[i].firstName = results[i].firstName + ' ' + results[i].lastName;
                    results[i].status = results[i].status === 1 ? "Invite Sent" :
                        results[i].status === 2 ? "Guided Setup" :
                            results[i].status === 3 ? "Active" :
                                results[i].status === 4 ? "Invite Expired" :
                                    results[i].status === 5 ? "Inactive" : null;
                }
                setAccountList(results);
            }
            else {
                setAccountList([]);
            }
        }
    }, [accounts]);

    useEffect(() => {
        dispatch<any>({ type: HANDLE_GET_ACCOUNTS, payload: { isActive: 1 } })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Datatable Templates
    const header = (
        <>
            <div className="table-header">
                <TextField
                    value={glFilter}
                    type="search"
                    className="account-search"
                    onChange={(e) => handleFirstNameFilter(e)}
                    onRenderPrefix={(props: ITextFieldProps) => (handleRenderPrefix(props))}
                />
                <div className="table-footer table-footer-action">
                    <Link onClick={handleActiveInactiveUsers} className="link-font-text"> {activeFlag === 1 ? "Inactive Accounts" : "Active Accounts"}</Link>
                </div>
            </div>
        </>
    );

    const handleRenderPrefix = (props: ITextFieldProps) => (
        <i className="pi pi-search" />
    );

    const accountBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <span className="p-column-title">Account</span>
                {rowData.nickName}
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Administrator</span>
                <span> {rowData.firstName}</span>
                {rowData.email ? <><br /> {rowData.email}</> : null}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.status}
                {rowData.status === "Invite Expired" ? <Link onClick={() => handleResendInvite(rowData.id)} className="updateEmailTxt td-btn">Resend Invite</Link> : null}
            </React.Fragment>
        );
    };

    const lastActivityBodyTemplate = (rowData) => {
        let local = null;
        if (rowData.lastActivity) {
            var date = moment.utc(rowData.lastActivity).format('YYYY-MM-DD HH:mm:ss');
            var stillUtc = moment.utc(date).toDate();
            local = moment(stillUtc).local().format('MM-DD-YYYY hh:mm A');

        }

        return (
            <React.Fragment>
                <span className="p-column-title">Last Activity</span>
                {local}
            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Actions</span>
                {user.roles && user.roles.includes('ROLE_ADMIN') && (
                    <AccountMoreActionsSubmenu
                        modulePermissions={user.roles && user.roles.includes('ROLE_ADMIN')}
                        accountId={rowData.id}
                        isActive={rowData.isActive}
                        onActivateDeactivate={(accountId, isActive) => handleActivateDeactivateUser(accountId, isActive)}
                        onEdit={(accountId) => handleEditAccount(accountId)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditAccount = (id) => {
        navigate(`/${id}/account-detail`);
    };

    const handleActivateDeactivateUser = (id, isActive) => {
        dispatch<any>(handleAccountActivate({ id, isActive: !isActive }));
    };

    const handleResendInvite = (id) => {
        dispatch<any>(handleSendInvite({ id }));
    }

    useEffect(() => {
        if (activateDeactivateError) {
            if (activateDeactivateError.detail) {
                setcommonServerError(activateDeactivateError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [activateDeactivateError]);

    useEffect(() => {
        if (serverError) {

            setcommonServerError(serverError.toString());
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    const table_columns = {
        "nickName": "Account",
        "firstName": "Administrator",
        "status": "Status",
        "lastActivity": "Last Activity",
        "settings": "Actions"
    }

    const exportExcel = () => {
        dispatch<any>(handleExportCsvAccount({ isActive: activeFlag }));
    }

    /*const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }*/

    return (
        <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >
                    {activeFlag ? <Row>
                        <Col>
                            <CustomFontText style={{ margin: "0px" }} className="pageName">Active Accounts</CustomFontText>
                            <Link disabled={accountList && accountList.length === 0} onClick={exportExcel}>Export account details to Excel</Link>
                        </Col>
                        <Col>
                            {user.roles && user.roles.includes('ROLE_ADMIN') && (
                                <PrimaryButton
                                    style={{ marginBottom: "0" }}
                                    onClick={() => { navigate(`/add-account-detail`); }}
                                    text="Add Account"
                                />
                            )}
                        </Col>
                    </Row> :
                        <Row>
                            <Col>
                                <CustomFontText style={{ margin: "0px", display: "block" }} className="pageName pingColor">Inactive Accounts</CustomFontText>
                                <Link disabled={accountList && accountList.length === 0} onClick={exportExcel}>Export account details to Excel</Link>
                            </Col>
                        </Row>}
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo admin-accounts">
                    <div className="card">
                        <CustomTable
                            sortField="nickName"
                            sortOrder={1}
                            column_list={table_columns}
                            data={accountList}
                            header={header}
                            customTemplates={{
                                "nickName": accountBodyTemplate,
                                "firstName": nameBodyTemplate,
                                "status": statusBodyTemplate,
                                "lastActivity": lastActivityBodyTemplate,
                                "settings": settingBodyTemplate,
                            }}
                            emptyMessage="No accounts found."
                            onRowClick={(event) => handleEditAccount(event.data.id)}
                            globalFilter={glFilter} />
                    </div>
                </div>
            </Stack>
        </>
    );
};
