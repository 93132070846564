import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { colors } from "constants/constants";
import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { AppState } from "store";
// import {
//   getModulePermissions,
//   isUserMaster,
// } from "utils/permissions/permissionsHelpers";
// import { eTypes, WithCredentials } from "hoc/withCredentials";
import BasicSetting from "pages/dealSettingsPage/BasicSetting/BasicSetting";
import GlobalFees from "pages/dealSettingsPage/GlobalFees/GlobalFees";
import GlobalTaxes from "pages/dealSettingsPage/GlobalTaxes/GlobalTaxes";
import PartnerSettings from "pages/dealSettingsPage/PartnerSettings/PartnerSettings";





const border = {
  borderTop: `1px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};

interface IProps {
  formik: any;
}
// const credentialModuleName = "Config";
export const DealSettingsPivot: React.FC<IProps> = ({ formik }) => {
  // const [credentials, setCredentials] = useState([]);
  // const user = useSelector((state: AppState) => state.userProfile.userProfile);

  useEffect(() => {
    // if (user && user.auths && !isUserMaster(user.roles)) {
    //   console.log(getModulePermissions(user.auths, credentialModuleName));
    //   setCredentials(getModulePermissions(user.auths, credentialModuleName));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Pivot
      style={{ padding: 0, margin: 0 }}
      className="pivotItem"
      // onLinkClick={(item?: any) => {
      //   if (item.key === ".2") {
      //     setHideButton(true);
      //   } else {
      //     setHideButton(false);
      //   }
      // }}
    >
      <PivotItem
        className="pivotItem"
        style={border}
        headerText="Basic Setting"
        headerButtonProps={{
          "data-order": 2,
          "data-title": "Company information",
        }}
      >
        <BasicSetting formik={formik} />
      </PivotItem>
      <PivotItem className="pivotItem" style={border} headerText="Global Taxes">
        <GlobalTaxes formik={formik} />
      </PivotItem>
      <PivotItem className="pivotItem" style={border} headerText="Global Fees">
        <GlobalFees formik={formik} />
      </PivotItem>
      <PivotItem
        className="pivotItem"
        style={border}
        headerText="Partner Settings"
      >
        <PartnerSettings/>
      </PivotItem>
    </Pivot>
  );
};
