import React from 'react'
import {
  Stack,
  IStackStyles,
} from "@fluentui/react";

import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
//const navigate = useNavigate ();
const styles: IStackStyles = {
  root: {
    boxShadow: Depths.depth8,
    background: DefaultPalette.white,
    padding: 50,
    paddingLeft: 80,
    paddingRight: 80,
    borderRadius: 2,
    width: 450,
    // height: 400,
    zIndex: 1,
  },
};
const TermsofUse = () => {
    return (
      <AuthLayoutPage>
      <Stack styles={styles} verticalAlign="space-between">
        <CustomFontText size={30}>Terms of Use</CustomFontText>
                <CustomFontText size={16}>
                    <br/>
          Terms of Use new content will be replaced here<br/>
          Terms of Use new content will be replaced here<br/>
          Terms of Use new content will be replaced here<br/>
        </CustomFontText>
       
      </Stack>
      </AuthLayoutPage>
    )
}


export default TermsofUse;
