import client from "services/api/client";

export const getSelectList = ({
    isActive,
    category
}: {
    isActive: number;
    category: string;
}) => {
    
    // console.log("query", query);
    return client(
        `user/master/${category}?active=${isActive}&page=1&limit=10000&column=${category.slice(0, -1)}.name&sort=asc`,
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const getSelectListItem = ({
    id,
    category
}: {
    id: number;
    category: string;
}) => {
    
    // console.log("query", query);
    return client(
        `user/master/${category}/${id}?`,
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const addSelectListItem = (category: string, formData: any) => {
    return client(`user/master/${category}`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const saveSelectListItem = (id:number,category: string, formData: any) => {
    return client(`user/master/${category}/${id}`, {
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const activateSelectListItem = (id:number,category: string) => {
    return client(`user/master/${category}/${id}/activate`, {
        body: null,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const deactivateSelectListItem = (id:number,category: string) => {
    return client(`user/master/${category}/${id}/deactivate`, {
        body: null,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};