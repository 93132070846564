import {
  ActionButton,
  DefaultPalette,
  IButtonStyles,
  IIconProps,
} from "@fluentui/react";
import * as React from "react";

export const buttonStyles: IButtonStyles = {
  root: {
    color: "white",
    fontSize: 18,
  },
  rootHovered: {
    color: DefaultPalette.themeLight,
  },
  icon: {
    color: "white",
    fontSize: 18,
    paddingTop: 2,
  },
  iconHovered: {
    color: DefaultPalette.themeLight,
  },
};
const collapseIcon: IIconProps = { iconName: "CollapseMenu" };
const closeIcon: IIconProps = { iconName: "ChromeClose" };

export interface IButtonProps {
  onClick: () => void;
  showLeftNav?: any;
}


const Hamburger: React.FunctionComponent<IButtonProps> = ({ onClick, showLeftNav }) => {
  return (
    <ActionButton
      onClick={onClick}
      styles={buttonStyles}
      iconProps={showLeftNav ? closeIcon : collapseIcon}
      allowDisabledFocus
      id="mob-menu"
    >
    </ActionButton>
  );
};
export default Hamburger;
