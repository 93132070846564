/* eslint-disable array-callback-return */
import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  mergeStyleSets,
} from "@fluentui/react";
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import React, { useEffect } from "react";
//import { DatePicker, IDatePickerStrings } from '@fluentui/react';
import {
  TextField as OffTextField
} from "@fluentui/react";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
//import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import masterDropdownValues from "constants/masterDropdownValues";
import { dropdownStyles, hiddenStyle } from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { LabelInfoTooltip } from "components/tooltip/LabelInfo";
import { Col, Row } from "react-grid-system";
// import { AutoComplete } from 'primereact/autocomplete';
//import { getAddreessByZip } from "services/api/smartyStreets";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
// import { handleGetVehSellerDetails } from "store/inventory/inventory.action";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AddCustomizedAdjustmentsDialog } from "components/dialogs/addInventoryDialog/AddCustomizedAdjustmentsDialog";
import { AddInventoryTrimDialog } from "components/dialogs/addInventoryDialog/AddInventoryTrimDialog";
import { AddLiveReportSettingsDialog } from "components/dialogs/addInventoryDialog/AddLiveReportSettingsDialog";
import { AddUpdateExpenseDialog } from "components/dialogs/addInventoryDialog/AddUpdateExpenseDialog";
import { AddUpdateQvalueDialog } from "components/dialogs/addInventoryDialog/AddUpdateQvalueDialog";
import { SaveSuccessDialog } from "components/dialogs/addInventoryDialog/SaveSuccessDialog";
import { UpdatePricingTabDialog } from "components/dialogs/addInventoryDialog/UpdatePricingTabDialog";
import { useParams,useNavigate } from "react-router-dom";
import {
  handleGetAdjusterList,
  handleGetFilterLiveRetailReports,
  handleGetLiveRetailReports,
  handleGetPricingUsingID,
  handleGetQValuations,
  handleGetQValueDropdownMaster,
  handleGetQValueNotesUsingID,
  handleGetQValuePlotUsingID,
  handleGetSourceWorkBook,
  handleGetTrimUsingVin,
  handleGetVehicleExpenses,
  handleGetVehicleUsingID,
  handlePricingCategoriesID,
  handlePurchaseVehicle,
  handlePutAdjusterList,
  handlePutVehDetails,
  handleSavePricingUsingID,
  handleSaveQValueNotesUsingID,
  handleSaveQValues,
  handleSaveSourceWorkBook,
  handleSaveVehicleExpenses,
  handleVehicleStatus
} from "store/inventory/inventory.action";
import { handleGetLocations } from "store/locations/locations.action";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
// import { Calendar } from 'primereact/calendar';
//import { handleGetTrimUsingVin, handleGetVehDetails } from "store/inventory/inventory.action";
// import ShopInventoryRetailReportsJD from "pages/inventoryPage/ShopInventoryRetailReportsJD";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { EditShopWorkBookMoreActionSubmenu } from "components/table/inventory/EditShopWorkBookMoreActionSubmenu";
import { ShopInventoryReportsJD } from "pages/inventoryPage/ShopInventoryReportsJD";
import { ShopInventoryReportsKBB } from "pages/inventoryPage/ShopInventoryReportsKBB";
import { ShopInventoryRetailReports } from "pages/inventoryPage/ShopInventoryRetailReports";
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ShopInventoryReportsBB } from "./ShopInventoryReportsBB";
//import { InputMask } from 'primereact/inputmask';
import { AddInventoryFoundDialog } from "components/dialogs/addInventoryDialog/AddInventoryFoundDialog";
import { NegativeBlackBookHistoryDialog } from "components/dialogs/addInventoryDialog/NegativeBlackBookHistoryDialog";
import { PRICE_PREFIX, emplyeePermissionsAction, themeStyles } from "constants/constants";
import { TreeTable } from 'primereact/treetable';
import CurrencyInput from 'react-currency-input-field';
import InputMask from "react-input-mask";
import { convertLocalToUTCModified, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import {
  currencyFormat,
  findClosest,
  get_chart_range_list,
  groupBy,
  groupByRange,
  numberFormat,
  validTenDigitPhone
} from "utils/helperFunctions";
//import editIcon from "assets/Icon-awesome-edit.svg";
import { EditVehicleDialog } from "components/dialogs/addInventoryDialog/EditVehicleDialog";
import { PurchaseTransitionDialog } from "components/dialogs/addInventoryDialog/PurchaseTransitionDialog";
import { SellInventoryDialog } from "components/dialogs/addInventoryDialog/SellInventoryDialog";
import { SoldVehicleInventoryDialog } from "components/dialogs/addInventoryDialog/SoldVehicleInventoryDialog";
import inventoryDropdownsValues from "constants/inventoryDropdownsValues";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { useMediaQuery } from "react-responsive";
import { HandleZipCodeFlatData } from "store/shared/shared.action";
import { isEmployeePermitted, isUserMaster } from "utils/permissions/permissionsHelpers";
import downloadImage from 'assets/downloadImg.svg';

import { classNames } from "primereact/utils";
import {
  // YAxis,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";


interface ParamTypes {
  vehicleID: string;
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }

function TabPanelView(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      //className="overflowXHidd"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
        //<Typography component={'div'}>{children}</Typography>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme: ThemeStyle) =>

//   createStyles({
//     root: {
//       flexGrow: 1,
//       width: '100%',
//       backgroundColor: theme?.palette?.background.paper,
//       display: 'block',
//     },
//     heading: {
//       fontSize: theme.typography?.pxToRem(15),
//     },
//     secondaryHeading: {
//       fontSize: theme.typography?.pxToRem(15),
//       color: theme.palette?.text.secondary,
//     },
//     icon: {
//       verticalAlign: 'bottom',
//       height: 20,
//       width: 20,
//     },
//     details: {
//       alignItems: 'center',
//     },
//     column: {
//       flexBasis: '33.33%',
//     },
//     helper: {
//       borderLeft: `2px solid ${theme.palette?.divider}`,
//       padding: theme?.spacing?.(1, 2),
//     },
//     link: {
//       color: theme.palette?.primary.main,
//       textDecoration: 'none',
//       '&:hover': {
//         textDecoration: 'underline',
//       },
//     },
//     breadCrumblink: {
//       display: 'flex',
//     },
//     instructions: {
//       marginTop: theme?.spacing?.(1),
//       marginBottom: theme?.spacing?.(1),
//     },
//     breadCrumbicon: {
//       marginRight: theme?.spacing?.(0.5),
//       width: 20,
//       height: 20,
//     },
//     startTime: {
//     }
//   }),
// );

const lightOptions = {
  plugins: {
    legend: {
      labels: {
        color: '#495057'
      }
    }
  }
};

const emptyStyleObj = {

  style: '',
  series: '',
  state: '',
  zip: '',
  mileage: '',
  roughCondition: {
    retail: 'NA',
    auction: 'NA',
    tradeIn: 'NA',
    retailText: '',
    auctionText: '',
    tradeInText: '',
  },
  excellentCondition: {
    retail: 'NA',
    auction: 'NA',
    tradeIn: 'NA',
    retailText: '',
    auctionText: '',
    tradeInText: '',
  },
  averageCondition: {
    retail: 'NA',
    auction: 'NA',
    tradeIn: 'NA',
    retailText: '',
    auctionText: '',
    tradeInText: '',
  },
  cleanCondition: {
    retail: 'NA',
    auction: 'NA',
    tradeIn: 'NA',
    retailText: '',
    auctionText: '',
    tradeInText: '',
  }
}
// const iconPropsCal = { iconName: 'Calendar' };

export const ShopInventoryEdit = ({ history, location }) => {
  //const  classes:any  = useStyles();
  const dispatch = useDispatch();
  const params = useParams<ParamTypes | any>();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isMobilePhone = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();  
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [showVinField, setshowVinField] = React.useState(true);
  const [kbbAttributionDetails, setkbbAttributionDetails] = React.useState(null);
  const [qValNotes, setqValNotes] = React.useState(null);
  //const [crmList, setcrmList] = React.useState([]);

  //const [selectedCRM, setselectedCRM] = React.useState(null);
  //const [filteredCRM, setfilteredCRM] = React.useState(null);
  //const [cityDealerList, setCityDealerList] = React.useState([]);
  //const [countyCompanyList, setCountyCompanyList] = React.useState([]);
  const [vinDataError, setvinDataError] = React.useState(false);
  const [trimValues, settrimValues] = React.useState([]);
  const [isInventoryTriDialog, setisInventoryTriDialog] = React.useState(false);
  const [isInventoryFoundDialog, setisInventoryFoundDialog] = React.useState(false);
  const [duplicatevehicleID, setduplicatevehicleID] = React.useState(null);
  const [isLiveReportDialog, setisLiveReportDialog] = React.useState(false);
  const [updateQvalueDialog, setupdateQvalueDialog] = React.useState(false);
  const [updateExpenseDialog, setupdateExpenseDialog] = React.useState(false);
  const [updatePricingTabDialog, setupdatePricingTabDialog] = React.useState(false);
  const [isCustomizedAdjustmentsDialog, setisCustomizedAdjustmentsDialog] = React.useState(false);
  const [saveSuccessDialog, setSaveSuccessDialog] = React.useState(false);
  const [auctionDate, setAuctionDate] = React.useState<Date | Date[] | undefined | any>(undefined);
  const [vehicleID, setvehicleID] = React.useState(null);
  const [updateQValueSection, setupdateQValueSection] = React.useState(null);
  const [retailHistorylist, setretailHistorylist] = React.useState([]);
  const [qValueScreen, setqValueScreen] = React.useState('main');
  const [liveRetailComp, setliveRetailComp] = React.useState(null);
  const [showLiveRetail, setshowLiveRetail] = React.useState(null);
  const [reportScreenName, setreportScreenName] = React.useState(null);
  const [mileageChart, setmileageChart] = React.useState(null);
  const [mileageChartNew, setmileageChartNew] = React.useState(null);
  const [priceChartNew, setpriceChartNew] = React.useState(null);
  const [priceChart, setpriceChart] = React.useState(null);
  const [expandedKeys, setExpandedKeys] = React.useState({});
  const [isNegativeFlagModal, setIsNegativeFlagModal] = React.useState(false);
  const [isNegativeFlagIcon, setIsNegativeFlagIcon] = React.useState(false);
  const [isNegativeValueAuction, setIsNegativeValueAuction] = React.useState(false);
  const [isNegativeValueTradeIn, setIsNegativeValueTradeIn] = React.useState(false);
  const [isNegativeValueRetail, setIsNegativeValueRetail] = React.useState(false);
  // const [caltotalExpenses, setcaltotalExpenses] = React.useState(null);

  const [reportlist, setreportlist] = React.useState([
    { name: 'Black Book', auction: '', tradeIn: '', retail: '', date: '', auctionText: '', tradeInText: '', retailText: '', isAdjusterSkipped: null },
    { name: 'J.D. Power', auction: '', tradeIn: '', retail: '', date: '', auctionText: '', tradeInText: '', retailText: '', isAdjusterSkipped: null },
    { name: 'Kelley Blue Book', auction: '', tradeIn: '', retail: '', date: '', auctionText: '', tradeInText: '', retailText: '', isAdjusterSkipped: null }
  ]);

  const [datePricelist, setdatePricelist] = React.useState([]);

  const [sourceTypeVal, setsourceTypeVal] = React.useState(null);

  const [qValues, setQValues] = React.useState([]);
  const [expenseQValues, setexpenseQValues] = React.useState([]);
  const [categoryList, setcategoryList] = React.useState([]);
  const [priceDetails, setpriceDetails] = React.useState(null);
  const [qvaluError, setqvaluError] = React.useState(false);
  const [notesList, setNotesList] = React.useState([]);
  const [expenseTableList, setExpenseTableList] = React.useState([]);
  const [nadaStyles, setNadaStyles] = React.useState([]);
  const [kbbStyles, setKBBStyles] = React.useState([]);
  const [bbStyles, setBBStyles] = React.useState([]);
  const [qValueDetail, setQValueDetail] = React.useState({ mileage: '', zip: '', state: '' });
  const [zipFlag, setZipFlag] = React.useState(false);
  const [updateQValueError, setUpdateQValueError] = React.useState(null);
  const [reportsJD, setreportsJD] = React.useState(null);
  const [reportsKBB, setreportsKBB] = React.useState(null);
  const [liveReports, setliveReports] = React.useState(null);
  const [filterLiveReports, setfilterLiveReports] = React.useState(null);
  const [vehicleCount, setvehicleCount] = React.useState(0);
  const [reportsBB, setreportsBB] = React.useState(null);
  const [reportsVehInfoJD, setreportsVehInfoJD] = React.useState(null);
  const [reportsVehInfoKBB, setreportsVehInfoKBB] = React.useState(null);
  const [reportsVehInfoBB, setreportsVehInfoBB] = React.useState(null);
  const [reportsType, setreportsType] = React.useState(null);
  const [kbbActionBtnDisable, setkbbActionBtnDisable] = React.useState(false);
  const [bbActionBtnDisable, setbbActionBtnDisable] = React.useState(false);
  const [liveRetailBtnDisable, setLiveRetailBtnDisable] = React.useState(false);
  const [nadaActionBtnDisable, setnadaActionBtnDisable] = React.useState(false);
  const [statusUpdateError, setStatusUpdateError] = React.useState('');
  // const [selectedLiveRetailKey, setSelectedLiveRetailKey] = React.useState('activeListings');
  const [nadaStylePlaceholder, setNadaStylePlaceholder] = React.useState('');
  const [kbbStylePlaceholder, setKBBStylePlaceholder] = React.useState('');
  const [bbStylePlaceholder, setBBStylePlaceholder] = React.useState('');
  const [qvalueZipApiMsg, setQValueZipApiMsg] = React.useState("");
  // const [dntChtData, setdntChtData] = React.useState({});
  /* const [vehPriceData, setvehPriceData] = React.useState({});
  const [vehMileData, setvehMileData] = React.useState({});
  const [vehPriceLabel, setVehPriceLabel] = React.useState([]);
  const [vehMileLabel, setVehMileLabel] = React.useState([]); */
  const yearRange = new Date().getFullYear() + ":" + (new Date().getFullYear() + 1);
  const [styleDropdownFlag, setStyleDropdownFlag] = React.useState(false);
  const [isPurchaseTransDialog, setIsPurchaseTransDialog] = React.useState(false);
  const [isEditVehicleDialog, setIsEditVehicleDialog] = React.useState(false);
  const [isSellDialog, setIsSellDialog] = React.useState(false);
  const [totalCostCal, setTotalCostCal] = React.useState(0);
  const [newVehicleFlag, setNewVehicleFlag] = React.useState(false);
  const [adjusterList, setAdjusterList] = React.useState(null);
  const [dummyReportName, setDummyReportName] = React.useState(null);
  const [vehConditionId, setVehConditionId] = React.useState(null);
  const [isSoldDialog, setIsSoldDialog] = React.useState(false);
  // chart 
  const [lineStylesData, setlineStylesData] = React.useState(null);
  const [selectedCalcTab, setselectedCalcTab] = React.useState('profit');
  const [basicData, setbasicData] = React.useState(null);
  // const [basicDataNew, setbasicDataNew] = React.useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const [avgPricePercent, setavgPricePercent] = React.useState('-');
  const [priceRank, setpriceRank] = React.useState('- of -');
  const [sortedPricePlot, setSortedPricePlot] = React.useState([]);
  const [sortedMileagePlot, setSortedMileagePlot] = React.useState([]);
  const [highlightListPrice, sethighlightListPrice] = React.useState(null);
  const [highlightMileage, sethighlightMileage] = React.useState(null);
  const [pieChartData, setpieChartData] = React.useState({
    labels: [],
    datasets: [
      {
        label: 'Total',
        data: [],
        // backgroundColor: ['#6087cfd6', '#8060cfd6', '#cf60b2d6', '#cf6084d6', '#cf6084d6',
        //  '#9fcf60d6', '#cfb760d6', '#cf8e60d6', '#cf6060d6', '#b4948dd6', '#662525d6', '#665825d6', '#663a25d6']
        backgroundColor: ['#0065CA', '#1C2E47', '#FF8000', '#4F9D3C', '#898D89', '#AF2B01', '#65BC50', '#FF9933', '#007FFD', '#0000CA', '#CA6500', '#336627', '#464646']

      }]
  });

  const tooltipStyle = {
    color: '#fff !important',
    backgroundColor: '#111 !important',
  };

  const tooltipStyleLabel = {
    color: '#fff !important',
    backgroundColor: '#111 !important',
  };

  const CustomTooltipMileage = ({ active, payload, label }) => {
    let labelRaw = label;
    if (payload && payload.length && payload[0].payload.xAxisRaw) {
      labelRaw = payload[0].payload.xAxisRaw;
    }
    let percent = (((sortedMileagePlot.indexOf(parseInt(toNumbersFromK(labelRaw))) + 1) / sortedMileagePlot.length) * 100);
    // create the label text...
    const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(1);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip chartTooltip" >
          <p >{`Mileage: ${numberFormat(toNumbersFromK(labelRaw))} `}</p>
          <p >{`${100 - Math.round(roundedValue)}% of vehicles having mileage above`}</p>
          <p >{`${Math.round(roundedValue)}% of vehicles having mileage below`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTooltipPrice = ({ active, payload, label }) => {
    let labelRaw = label;
    if (payload && payload.length && payload[0].payload.xAxisRaw) {
      labelRaw = payload[0].payload.xAxisRaw;
    }
    let percent = (((sortedPricePlot.indexOf(parseInt(toNumbersFromK(labelRaw))) + 1) / sortedPricePlot.length) * 100);
    // create the label text...
    const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(1);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip chartTooltip" >
          <p >{`List Price: ${currencyFormat(toNumbersFromK(labelRaw))} `}</p>
          <p >{`${100 - Math.round(roundedValue)}% of vehicles are priced above`}</p>
          <p >{`${Math.round(roundedValue)}% of vehicles are priced below`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;
    let value = payload.value;
    if (payload.value && payload.value.includes('K')) {
      value = payload.value.replace('K', '')
      value = parseFloat(value).toFixed(1);
      value = value + 'K'
    } else {
      value = parseFloat(value).toFixed(1);
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          // x={10}
          y={10}
          /*dy={6} */
          fontSize={12}
          textAnchor="middle"
          fill="#666"
        //transform="rotate(-35)"
        >
          {value}
        </text>
      </g>
    );
  };

  const toNumbersFromK = (label) => {
    let value = label;
    if ((typeof label === "string") && label.includes('K')) {
      label = label.replace('K', '');
      value = parseFloat(label) * 1000;
    }
    return value
  };

  const toKfromNumbersArry = (numbArray, isArray, check = false) => {
    if (isArray) {
      return numbArray.map((item: any) => {
        const itemCopy = { ...item };
        if (parseInt(itemCopy.xAxis) >= 1000) {
          itemCopy.xAxisRaw = parseInt(itemCopy.xAxis) > 999 ? (Math.sign(itemCopy.xAxis) * (parseInt(itemCopy.xAxis) / 1000)) + 'K' : (Math.sign(itemCopy.xAxis) * parseInt(itemCopy.xAxis));
          itemCopy.xAxisLabel = parseInt(itemCopy.xAxis) > 999 ? (Math.sign(itemCopy.xAxis) * (parseInt(itemCopy.xAxis) / 1000)).toFixed(2) + 'K' : (Math.sign(itemCopy.xAxis) * parseInt(itemCopy.xAxis));
          itemCopy.xAxis = parseInt(itemCopy.xAxis) > 999 ? (Math.sign(itemCopy.xAxis) * (parseInt(itemCopy.xAxis) / 1000)).toFixed(1) + 'K' : (Math.sign(itemCopy.xAxis) * parseInt(itemCopy.xAxis));
        } else {
          itemCopy.xAxisRaw = itemCopy.xAxis;
          itemCopy.xAxisLabel = itemCopy.xAxis.toFixed(2);
          itemCopy.xAxis = itemCopy.xAxis.toFixed(1);
        }
        // itemCopy.xAxis = parseInt(itemCopy.xAxis) > 999 ? (Math.sign(itemCopy.xAxis)*(parseInt(itemCopy.xAxis)/1000)) + 'K' : (Math.sign(itemCopy.xAxis)*parseInt(itemCopy.xAxis));
        return itemCopy;
      });
    } else {
      if (check) {
        return parseInt(numbArray) > 999 ? (Math.sign(numbArray) * (parseInt(numbArray) / 1000)).toFixed(2) + 'K' : (Math.sign(numbArray) * parseInt(numbArray));
      }
      // return parseFloat(numbArray) > 999 ? (Math.sign(numbArray)*(parseFloat(numbArray)/1000)).toFixed(1) + 'K' : (Math.sign(numbArray)*parseFloat(numbArray));
      return parseInt(numbArray) > 999 ? (Math.sign(numbArray) * (parseInt(numbArray) / 1000)).toFixed(1) + 'K' : (Math.sign(numbArray) * parseInt(numbArray));
    }
  }

  /* const getLightTheme = () => {

    let basicOptionsPrice = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
          legend: {
            display: false,
            labels: {
              color: '#495057'
            }
          },
          tooltip: {
            displayColors: false,
            // color settings
            backgroundColor: '#67635e',
            titleFontColor:  'rgb(255,255,255)',
            bodyFontColor:   'rgb(255,255,255)',
            footerFontColor: 'rgb(255,255,255)',
            footerFontStyle: 'normal',
            titleFontSize: 20,
            bodyFont: {
              size: 16
            },
            titleFont: {
              size: 16
            },
            footerFont: {
              size: 16,
              weight: 'normal'
            },
            callbacks: {
              title: function(item, everything) {
                let chartItem = item;
                if (Array.isArray(item)) {
                  chartItem = item[0]?.label;
                } else {
                  chartItem = item?.parsed;
                }
                
                return `List Price: ${currencyFormat(chartItem)}`;
              },
              label: function(item, everything) {
                let chartItem = item;

                if (Array.isArray(item)) {
                  chartItem = parseInt(item[0]?.label);
                } else {
                  chartItem = parseInt(item?.label);
                }
                let percent = (((sortedPricePlot.indexOf(chartItem)+1) / sortedPricePlot.length) * 100);
                
                // create the label text...
                const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(2);
                let label = `${100 - Math.round(roundedValue)}% of vehicles are priced above`;
                
                return label;
              },
              footer: function(item, everything) {
                let chartItem = item;

                if (Array.isArray(item)) {
                  chartItem = parseInt(item[0]?.label);
                } else {
                  chartItem = parseInt(item?.label);
                }
                let percent = ((sortedPricePlot.indexOf(chartItem)+1) / sortedPricePlot.length) * 100;
                
                const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(2);
                return `${ Math.round(roundedValue)}% of vehicles are priced below`;
              },
            }
          }
        },
        scales: {
          yAxes: {
            display: false
          },
          xAxes: {
            display: false
          },
          x: {
              ticks: {
                color: '#495057',
                min: 0,
                suggestedMin: 0
              },
              grid: {
                color: '#ebedef'
              }
          },
          y: {
              ticks: {
                color: '#495057',
                display: false,
              },
              grid: {
                color: '#ebedef'
              }
          },
        }
    };

    let basicOptionsMileage = {
      maintainAspectRatio: false,
      aspectRatio: .6,
      plugins: {
        legend: {
          display: false,
          labels: {
            color: '#495057'
          }
        },
        tooltip: {
          displayColors: false,
          
          // color settings
          backgroundColor: '#67635e',
          titleFontColor:  'rgb(255,255,255)',
          bodyFontColor:   'rgb(255,255,255)',
          footerFontColor: 'rgb(255,255,255)',
          footerFontStyle: 'normal',
          titleFontSize: 20,
          bodyFont: {
            size: 16
          },
          titleFont: {
            size: 16
          },
          footerFont: {
            size: 16,
            weight: 'normal'
          },
          callbacks: {
            title: function(item, everything) {
              let chartItem = item;
              if (Array.isArray(item)) {
                chartItem = item[0]?.label;
              } else {
                chartItem = item?.parsed;
              }
              
              return `Mileage: ${numberFormat(chartItem)}`;
            },
            label: function(item, everything) {
              let chartItem = item;

              if (Array.isArray(item)) {
                chartItem = parseInt(item[0]?.label);
              } else {
                chartItem = parseInt(item?.label);
              }
              let percent = (((sortedMileagePlot.indexOf(chartItem)+1) / sortedMileagePlot.length) * 100);
              
              // create the label text...
              const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(2);
              let label = `${100 - Math.round(roundedValue)}% of vehicles having mileage above`;
              
              // ...and set it
              return label;
            },
            footer: function(item, everything) {
              let chartItem = item;

              if (Array.isArray(item)) {
                chartItem = parseInt(item[0]?.label);
              } else {
                chartItem = parseInt(item?.label);
              }
              let percent = ((sortedMileagePlot.indexOf(chartItem)+1) / sortedMileagePlot.length) * 100;
              
              const roundedValue: any = (Math.round(percent * 100) / 100).toFixed(2);
              return `${ Math.round(roundedValue)}% of vehicles having mileage below`;
            },
          }
        }
      },
      scales: {
        yAxes: {
          display: false
        },
        xAxes: {
          display: false
        },
        x: {
          ticks: {
            color: '#495057',
            min: 0,
            suggestedMin: 0
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057',
            display: false,
          },
          grid: {
            color: '#ebedef'
          }
        },
      }
  };

    return {
        basicOptionsPrice,
        basicOptionsMileage
    }
  };

  const { basicOptionsPrice, basicOptionsMileage } = getLightTheme(); */

  // Pricing Tab Data
  const [nodes, setNodes] = React.useState([]);

  //const yearRange = "2020:" + (new Date().getFullYear() + 50);
  //const digitPattern = /^\d+$/;
  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  const locations = useSelector((state: AppState) => state.locations.locations) as any;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const shopVehicleList = useSelector((state: AppState) => state.inventory.shopVehicleList);
  const [logDate, setLogDate] = React.useState(null);
  const [purchaseDate, setPurchaseDate] = React.useState(null);
  const [soldDate, setSoldDate] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      searchVin: '',
      make: "",
      model: '',
      year: '',
      mileage: "",
      vin: "",
      shippingFrom: "",
      style: "",
      trim: ""
    },
    validationSchema: Yup.object({
      searchVin: Yup.string(),
      make: Yup.string(),
      model: Yup.string(),
      year: Yup.number(),
      mileage: Yup.string(),
      vin: Yup.string(),
      shippingFrom: Yup.string(),
      style: Yup.string(),
      trim: Yup.string()
    }),
    onSubmit: (values) => {
    },
  });

  const updateQValueformik = useFormik({
    initialValues: {
      miles: "",
      zip: "",
      state: "",
      blackbook: "",
      kbb: "",
      nada: "",
      defaultVehicleCondition: ""
    },
    validationSchema: Yup.object({
      miles: Yup.string(),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
      state: Yup.string(),
      blackbook: Yup.string().test("rules", "Required.", (val) => {
        return ((val !== undefined && val !== '') || (bbStyles && bbStyles.length === 0 && styleDropdownFlag));
      }),
      kbb: Yup.string().test("rules", "Required.", (val) => {
        return ((val !== undefined && val !== '') || (kbbStyles && kbbStyles.length === 0 && styleDropdownFlag));
      }),
      nada: Yup.string().test("rules", "Required.", (val) => {
        return ((val !== undefined && val !== '') || (nadaStyles && nadaStyles.length === 0 && styleDropdownFlag));
      }),
      defaultVehicleCondition: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSaveQValuesData(values);
    },
  });

  const retailMarketFormik = useFormik({
    initialValues: {
      sellerAsking: '',
      qValue: "",
      myOffer: '',
      reconditioning: '',
      transportation: "",
      otherExpense: "",
      profitTarget: "",
      targetListPrice: "",
      myOfferTarget: "-",
      myListPriceTarget: "-",
      totalExpenseTarget: "",
      ProfitList: "-",
      totalExpenseList: "-",
      myOfferList: "-",
      notes: ""

    },
    validationSchema: Yup.object({
      sellerAsking: Yup.string().max(8, "Must be 5 digits"),
      qValue: Yup.string().max(8, "Must be 5 digits"),
      myOffer: Yup.string().max(8, "Must be 5 digits"),
      reconditioning: Yup.string().max(8, "Must be 5 digits"),
      transportation: Yup.string().max(8, "Must be 5 digits"),
      otherExpense: Yup.string().max(8, "Must be 5 digits"),
      profitTarget: Yup.string().max(8, "Must be 5 digits"),
      targetListPrice: Yup.string().max(8, "Must be 5 digits"),
      myOfferTarget: Yup.string().max(8, "Must be 5 digits"),
      myListPriceTarget: Yup.string().max(8, "Must be 5 digits"),
      totalExpenseTarget: Yup.string().max(8, "Must be 5 digits"),
      myOfferList: Yup.string().max(8, "Must be 5 digits"),
      totalExpenseList: Yup.string().max(8, "Must be 5 digits"),
      ProfitList: Yup.string().max(8, "Must be 5 digits"),
      notes: Yup.string()
    }),
    onSubmit: (values) => {
      const expenses: any = {
        id: params.vehicleID,
        sellersAsking: values.sellerAsking?.replace(/\$|,/g, ''),
        qValue: values.qValue?.replace(/\$|,/g, ''),
        myOffer: values.myOffer?.replace(/\$|,/g, ''),
        reconditioning: values.reconditioning?.replace(/\$|,/g, ''),
        transportation: values.transportation?.replace(/\$|,/g, ''),
        otherExpenses: values.otherExpense?.replace(/\$|,/g, ''),
        totalExpenses: values.totalExpenseTarget?.replace(/\$|,/g, ''),
        targetListPrice: values.targetListPrice?.replace(/\$|,/g, ''),
        targetProfit: values.profitTarget?.replace(/\$|,/g, ''),
        profit: values.ProfitList?.replace(/\$|,/g, ''),
        listPrice: values.myListPriceTarget?.replace(/\$|,/g, ''),
        isCalcByProfit: selectedCalcTab === 'list' ? true : false,
        // notes: values.notes
      };
      dispatch<any>(handleSaveVehicleExpenses({
        expenses,
        callback: (response) => {
          if (!response.id) {
            setUpdateQValueError('Failed to Save the Data.');
            window.scrollTo(0, 0);
          } else {
            // setSaveSuccessDialog(true);
            // handle Pricing Tab data when saved Expenses only when Veh is in Appraisal or Sold
            getPricingTabData(params.vehicleID);
          }

        }
      }));

    },
  });

  const sourceVehicleformik = useFormik({
    initialValues: {
      auctionDate: '',
      auctionStartTime: '',
      url: '',
      businessName: '',
      location: '',
      sourceType: '',
      phone: '',
      email: '',
      notes: '',
    },
    validationSchema: Yup.object({
      sourceType: Yup.string(),
      auctionDate: Yup.string().nullable(),
      auctionStartTime: Yup.string(),
      businessName: Yup.string(), //.required("Required")
      url: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter a valid URL'
      ),
      location: Yup.string().matches(/^[aA-zZ0-9\s]+$/, 'Special Characters not allowed'),
      notes: Yup.string().max(225),
      email: Yup.string().email("Enter valid email"),
      phone: Yup.string().test("len", "Invalid phone number", (val) => {
        if (val === undefined)
          return true;
        return validTenDigitPhone(val)
      }),
    }),
    onSubmit: (values) => {
      let startTime;
      if (values.auctionStartTime) {
        let utcStart = moment(values.auctionStartTime, "HH:mm").utc();
        startTime = utcStart.format()
      }
      let source = {
        //name: values.name,
        auctionUrl: '',
        time: '',
        date: ''
      }
      if (values.sourceType === 'auction') {
        source.auctionUrl = values.url;
        source.time = startTime;
        source.date = convertLocalToUTCModified(values.auctionDate);
      }
      const sourceData: any = {
        //isIndividual: (values.sellerType !== 'business'), // values.setStatus,
        //isBusiness: (values.sellerType === 'business'), // values.condition,
        businessName: values.businessName,
        phone: values.phone.replace(/\D/g, '').slice(0, 10),
        email: values.email,
        source: values.sourceType,
        sourceData: source,
        shippingFrom: values.location,
        notes: values.notes,
        id: params.vehicleID
      };
      setValue(2);
      dispatch<any>(handleSaveSourceWorkBook({
        sourceData,
        callback: (response) => {
          if (!response.id) {
            setUpdateQValueError('Failed to Save the Data.');
            window.scrollTo(0, 0);
          } else {
            setSaveSuccessDialog(true);
          }

        }
      }));
    }
  });

  const handleSaveQValuesData = (values) => {
    setUpdateQValueError('');
    const bbVal = values.blackbook.split('|');

    const qValuesData: any = {
      miles: values.miles ? parseInt(values.miles.toString().replace(',', '')) : null,
      zip: values.zip,
      state: values.state,
      defaultVehicleCondition: values.defaultVehicleCondition,
      nadaStyle: values.nada,
      kbbStyle: values.kbb,
      bbStyle: bbVal[1],
      bbSeries: bbVal[0],
      id: params.vehicleID
    };

    dispatch<any>(handleSaveQValues({
      qValuesData,
      callback: (response) => {
        if (response) {
          if (response.errorInfo && response.errorInfo.length) {
            //if (response.nadaInfo.length || response.kbbInfo.length || response.bbInfo.length) {
            //  handleQvalueResponse(response);
            //  setupdateQValueSection(true);
            //  getQValuePageData(params.vehicleID);
            //}
            setUpdateQValueError(response.errorInfo.join(' | '))
          } else {
            setVehConditionId(response?.vehicles[0]?.defaultVehicleCondition)
            handleQvalueResponse(response);

            if (response?.vehicles[0]) {
              if (response?.vehicles[0]?.defaultVehicleCondition === 1) {
                handleReport(qValues[1], qValues[0], qValues[2], 'roughCondition');
              } else if (response?.vehicles[0]?.defaultVehicleCondition === 2) {
                handleReport(qValues[1], qValues[0], qValues[2], 'averageCondition');
              } else if (response?.vehicles[0]?.defaultVehicleCondition === 3) {
                handleReport(qValues[1], qValues[0], qValues[2], 'cleanCondition');
              } else if (response?.vehicles[0]?.defaultVehicleCondition === 4) {
                handleReport(qValues[1], qValues[0], qValues[2], 'excellentCondition');
              }
            }
            setupdateQValueSection(true);
            getQValuePageData(params.vehicleID);
            // hit Plot API
            getPlotDetails();
            // Hit for getting NOTEs
            getNotesDetails();
          }
          setupdateQvalueDialog(false);
        }
      }
    }));
  }

  const handleSaveExpenseData = (values, httpMethod) => {
    /* let expenseTableListArry = expenseTableList;
    expenseTableListArry.map((data, index) => {
      if (data.pricingID === values.pricingID) {
        data.amount = values.amount ? values.amount.toString().replace(',', '') : '';
      }
    });

    let totalExpenses = getTotalExpenseVal(expenseTableListArry)
    let estimatedGross = (parseInt(shopVehicleList?.initialListPrice) + parseInt(shopVehicleList?.purchasePrice)) - (totalExpenses); */
    const vehInfo: any = {
      expenseDate: moment(values.expenseDate).format("YYYY-MM-DD"),
      amount: values.amount ? values.amount.replace('$', '').replace(',', '') : '',
      category: values.category,
      description: values.description,
      id: params.vehicleID,
      pricingID: values.pricingID,
      method: httpMethod,
      totalExpenses: '',
      estimatedGross: ''
    };

    dispatch<any>(handleSavePricingUsingID({
      vehInfo,
      callback: (response) => {
        if (response && response.results) {
          setupdateExpenseDialog(false)
          // call get Pricing Table Data
          setExpenseTableList(response.results);
        }
      }
    }));
  }

  const setNotesValue = (e) => {
    setqValNotes(e.target.value)
  }

  const handleDeleteExpenseData = (response) => {
    if (response && response.results) {
      setupdateExpenseDialog(false)
      // call get Pricing Table Data
      setExpenseTableList(response.results);
    }
    else {
      setStatusUpdateError('Failed to delete')
    }
  }

  const handleUpdatePricingData = (values) => {
    setupdatePricingTabDialog(false)

    const purchaseInfo = {
      //dealerPack: values.dealerPack.replace('$', '').replace(',', ''),
      initialListPrice: values.newPrice.replace('$', '').replace(',', ''),
      id: params.vehicleID,
      httpMethod: 'PATCH'
    };
    dispatch<any>(handlePurchaseVehicle(purchaseInfo, (res) => {
      // console.log('after PATCH', res);
      // if it is QVal Tab, Navigate to Pricing Tab
      if (value === 0) {
        setValue(1);
      }
    }));
  }

  const handleQvalueResponse = (response, updateCustom = false) => {
    let data = [];
    if (updateCustom) {
      data = qValues;
    }
    let flag = false;
    let mapResponse = {
      adjuster: {
        kbbInfo: null,
        nadaInfo: null,
        bbInfo: null
      },
      baseValue: {
        kbbInfo: null,
        nadaInfo: null,
        bbInfo: null
      },
      adjustments: {

      }
    };
    if (response.kbbInfo && response.kbbInfo.length) {
      // Setting Adjuster Row Data
      mapResponse.adjuster.kbbInfo = mapReportsData(response.kbbInfo[0], 'adjustedValue', 'kbbInfo');
      setQValueDetail({ mileage: response.kbbInfo[0].mileage, zip: response.kbbInfo[0].zip, state: response.kbbInfo[0].state });
      flag = true;
      // setting BAseValue Row Data
      mapResponse.baseValue.kbbInfo = mapReportsData(response.kbbInfo[0], 'baseValue', 'kbbInfo');

      // set KBB Reports data for Individual Reports page data rendering
      let kbbReportsData = {
        auction: null,
        tradeIn: null,
        retail: null
      };
      kbbReportsData.auction = mapReportsDetailData(response.kbbInfo[0], 'auction');
      kbbReportsData.tradeIn = mapReportsDetailData(response.kbbInfo[0], 'tradeIn');
      kbbReportsData.retail = mapReportsDetailData(response.kbbInfo[0], 'retail');
      setreportsKBB(kbbReportsData);
      setreportsVehInfoKBB(response.kbbInfo[0]);

      if (updateCustom) {
        // remove if any existing row data is available
        data = data.filter(item => {
          if (((item.series !== response.kbbInfo[0].series) && (!item.series && !response.kbbInfo[0].series)) &&
            (item.style !== response.kbbInfo[0].style)) {
            return item;
          }
        });
      }
      setkbbActionBtnDisable(false);

      data.push(mapResponse.adjuster.kbbInfo);
    } else if (!updateCustom) {
      mapResponse.adjuster.kbbInfo = emptyStyleObj;
      setkbbActionBtnDisable(true);
      data.push(mapResponse.adjuster.kbbInfo);
    }

    if (response.nadaInfo && response.nadaInfo.length) {
      // Setting Adjuster Row Data
      mapResponse.adjuster.nadaInfo = mapReportsData(response.nadaInfo[0], 'adjustedValue', 'nadaInfo');
      if (!flag) {
        setQValueDetail({ mileage: response.nadaInfo[0].mileage, zip: response.nadaInfo[0].zip, state: response.nadaInfo[0].state });
        flag = true;
      }

      // setting BaseValue Row Data
      mapResponse.baseValue.nadaInfo = mapReportsData(response.nadaInfo[0], 'baseValue', 'nadaInfo');

      // set JD Reports data for Individual Reports page data rendering
      let jdReportsData = {
        auction: null,
        tradeIn: null,
        retail: null,
        loan: response.nadaInfo[0].loan,
        msrp: response.nadaInfo[0].msrp
      };
      jdReportsData.auction = mapReportsDetailData(response.nadaInfo[0], 'auction');
      jdReportsData.tradeIn = mapReportsDetailData(response.nadaInfo[0], 'tradeIn');
      jdReportsData.retail = mapReportsDetailData(response.nadaInfo[0], 'retail', 'retail');
      setreportsJD(jdReportsData);
      setreportsVehInfoJD(response.nadaInfo[0]);

      if (updateCustom) {
        // remove if any existing row data is available
        data = data.filter(item => {
          if (((item.series !== response.nadaInfo[0].series) && (!item.series && !response.nadaInfo[0].series)) &&
            (item.style !== response.nadaInfo[0].style)) {
            return item;
          }
        });
      }
      setnadaActionBtnDisable(false);

      data.push(mapResponse.adjuster.nadaInfo);
    } else if (!updateCustom) {
      mapResponse.adjuster.nadaInfo = emptyStyleObj;
      setnadaActionBtnDisable(true);
      data.push(mapResponse.adjuster.nadaInfo);
    }

    if (response.bbInfo && response.bbInfo.length) {
      const role = isUserMaster(user.roles);
      const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_UPDATE_LIVE_RETAIL?.toString())) : true;
      if (cred || role) {
        setLiveRetailBtnDisable(false);
      } else {
        setLiveRetailBtnDisable(true);
      }
      // Setting Adjuster Row Data
      mapResponse.adjuster.bbInfo = mapReportsData(response.bbInfo[0], 'adjustedValue', 'bbInfo');
      if (!flag) {
        setQValueDetail({ mileage: response.bbInfo[0].mileage, zip: response.bbInfo[0].zip, state: response.bbInfo[0].state });
      }

      // setting BaseValue Row Data
      mapResponse.baseValue.bbInfo = mapReportsData(response.bbInfo[0], 'baseValue', 'bbInfo');

      // set BB Reports data for Individual Reports page data rendering
      let bbReportsData = {
        auction: null,
        tradeIn: null,
        retail: null
      };
      bbReportsData.auction = mapReportsDetailData(response.bbInfo[0], 'auction', 'bb');
      bbReportsData.tradeIn = mapReportsDetailData(response.bbInfo[0], 'tradeIn', 'bb');
      bbReportsData.retail = mapReportsDetailData(response.bbInfo[0], 'retail', 'bb');

      setreportsBB(bbReportsData);

      setreportsVehInfoBB(response.bbInfo[0]);

      if (updateCustom) {
        // remove if any existing row data is available
        data = data.filter(item => {
          if (((item.series !== response.bbInfo[0].series) && (!item.series && !response.bbInfo[0].series)) &&
            (item.style !== response.bbInfo[0].style)) {
            return item;
          }
        });
      }
      setbbActionBtnDisable(false);

      data.push(mapResponse.adjuster.bbInfo);
    } else if (!updateCustom) {
      mapResponse.adjuster.bbInfo = emptyStyleObj;
      setbbActionBtnDisable(true);
      setLiveRetailBtnDisable(true);
      data.push(mapResponse.adjuster.bbInfo);
    }
    setQValues(data)

    //handleReport(mapResponse.adjuster.nadaInfo, mapResponse.adjuster.kbbInfo, mapResponse.adjuster.bbInfo,conditionName? conditionName:"averageCondition"); 
  }

  const handleCheckNegativeAuctionVal = (val) => {

    //const filteredByValue = Object.fromEntries( Object.entries(val).filter(([key, value]) => value < 0) )
    if (val.average < 0 || val.clean < 0 || val.excellent < 0 || val.rough < 0) {
      setIsNegativeValueAuction(true)
    } else {
      setIsNegativeValueAuction(false)
    }
  }
  const handleCheckNegativeTradeInVal = (val) => {
    //const filteredByValue = Object.fromEntries( Object.entries(val).filter(([key, value]) => value <  0 ) )
    if (val.average < 0 || val.clean < 0 || val.excellent < 0 || val.rough < 0) {
      setIsNegativeValueTradeIn(true)
    } else {
      setIsNegativeValueTradeIn(false)
    }
  }
  const handleCheckNegativeRetailVal = (val) => {
    //const filteredByValue = Object.fromEntries( Object.entries(val).filter(([key, value]) => value <  0 ) )
    if (val.average < 0 || val.clean < 0 || val.excellent < 0 || val.rough < 0) {
      setIsNegativeValueRetail(true)
    } else {
      setIsNegativeValueRetail(false)
    }
  }

  const handleShowModal = () => {

    setIsNegativeFlagModal(true)
    setreportsType(null);

  }


  useEffect(() => {
    if (reportsBB) {

      if (reportsBB.auction['historyAdj']) {

        handleCheckNegativeAuctionVal(reportsBB.auction['historyAdj'])
      }
      if (reportsBB.tradeIn['historyAdj']) {

        handleCheckNegativeTradeInVal(reportsBB.auction['historyAdj'])
      }
      if (reportsBB.retail['historyAdj']) {

        handleCheckNegativeRetailVal(reportsBB.auction['historyAdj'])
      }

    }

  }, [reportsBB])

  useEffect(() => {

    if (isNegativeValueAuction || isNegativeValueRetail || isNegativeValueTradeIn) {
      setIsNegativeFlagIcon(true)
    } else {
      setIsNegativeFlagIcon(false)
    }
  }, [isNegativeValueAuction, isNegativeValueRetail, isNegativeValueTradeIn])

  const updateAdjustedData = (data) => {
    if (data) {
      const nataItem = [];
      const kbbItem = [];
      const bbItem = [];

      if (data.vendorType === 'nada') {
        nataItem.push(data);
      } else if (data.vendorType === 'kbb') {
        kbbItem.push(data);
      } else if (data.vendorType === 'bb') {
        bbItem.push(data);
      }
      setupdateQValueSection(true);
      handleQvalueResponse({ nadaInfo: nataItem, kbbInfo: kbbItem, bbInfo: bbItem }, true);
    }
  }


  const handleRefreshData = () => {
    getPlotDetails();
    // call liveRetail
    callFilteredReportAPI(params.vehicleID);
    getPricingTabData(params.vehicleID);
    getQValueLiveRetailData(params.vehicleID);
  }

  // Map data for the QValue Main page Table Data
  const mapReportsData = (response, tableType, reportType) => {
    return {
      style: response.style,
      series: response.series,
      isAdjusterSkipped: response.isAdjusterSkipped,
      date: moment(response.valuationDate).format("MM/DD/YY"),
      averageCondition: {
        retail: currencyFormat(response[tableType].retail.average),
        auction: currencyFormat(response[tableType].auction.average),
        tradeIn: currencyFormat(response[tableType].tradeIn.average),
        auctionText: reportType === 'kbbInfo' ? 'Good' : reportType === 'bbInfo' ? 'Average' : 'Average',
        tradeInText: reportType === 'kbbInfo' ? 'Good' : reportType === 'bbInfo' ? 'Average' : 'Average',
        retailText: reportType === 'kbbInfo' ? 'Fair Purchase Price' : reportType === 'bbInfo' ? 'Average' : 'Retail',
      },
      roughCondition: {
        retail: currencyFormat(response[tableType].retail.rough),
        auction: currencyFormat(response[tableType].auction.rough),
        tradeIn: currencyFormat(response[tableType].tradeIn.rough),
        auctionText: reportType === 'kbbInfo' ? 'Fair' : reportType === 'bbInfo' ? 'Rough' : 'Low',
        tradeInText: reportType === 'kbbInfo' ? 'Fair' : reportType === 'bbInfo' ? 'Rough' : 'Rough',
        retailText: reportType === 'kbbInfo' ? 'Fair Market Low' : reportType === 'bbInfo' ? 'Rough' : 'Retail',
      },
      excellentCondition: {
        retail: currencyFormat(response[tableType].retail.excellent),
        auction: currencyFormat(response[tableType].auction.excellent),
        tradeIn: currencyFormat(response[tableType].tradeIn.excellent),
        auctionText: reportType === 'kbbInfo' ? 'Excellent' : reportType === 'bbInfo' ? 'Extra Clean' : 'High',
        tradeInText: reportType === 'kbbInfo' ? 'Excellent' : reportType === 'bbInfo' ? 'Clean' : 'Clean',
        retailText: reportType === 'kbbInfo' ? 'Fair Market High' : reportType === 'bbInfo' ? 'Extra Clean' : 'Retail',
      },
      cleanCondition: {
        retail: currencyFormat(response[tableType].retail.clean),
        auction: currencyFormat(response[tableType].auction.clean),
        tradeIn: currencyFormat(response[tableType].tradeIn.clean),
        auctionText: reportType === 'kbbInfo' ? 'Very Good' : reportType === 'bbInfo' ? 'Clean' : 'High',
        tradeInText: reportType === 'kbbInfo' ? 'Very Good' : reportType === 'bbInfo' ? 'Clean' : 'Clean',
        retailText: reportType === 'kbbInfo' ? 'Fair Market High' : reportType === 'bbInfo' ? 'Clean' : 'Retail',
      }
    };
  }

  // Map Reports Details screen Data
  const mapReportsDetailData = (response, tableType, ndRetail = null) => {
    let returnObj = {
      adjustedValue: response.adjustedValue[tableType],
      baseValue: response.baseValue[tableType],
      baseValueDescription: response.baseValue.defaultAdjustments,
      adjustmentValue: response.adjustmentValue,
    };

    if (ndRetail === 'bb') {
      returnObj['historyAdj'] = response.historyAdjustmentValue[tableType];
      returnObj['historyAdjustVal'] = response.historyAdjustedValue[tableType];
    }

    if (!Array.isArray(response.regionalAdjustmentValue)) {
      returnObj['regionalAdjustmentValue'] = response.regionalAdjustmentValue[tableType];
    }

    if (!Array.isArray(response.mileageAdjustmentValue)) {
      returnObj['mileageAdjustmentValue'] = response.mileageAdjustmentValue[tableType];
    }

    if (ndRetail === 'retail') {
      returnObj.adjustedValue['loan'] = response.adjustedValue.loan;
      returnObj.adjustedValue['msrp'] = response.adjustedValue.msrp;
      returnObj.baseValue['loan'] = response.baseValue.loan;
      returnObj.baseValue['msrp'] = response.baseValue.msrp;
    }

    return returnObj;
  }

  const handleDateChange = (val) => {
    sourceVehicleformik.setValues(
      {
        ...sourceVehicleformik.values,
        'auctionDate': val
      }
    );
    setAuctionDate(val);
  };

  const handleDropdownValue = (e, item) => {
    sourceVehicleformik.setValues(
      {
        ...sourceVehicleformik.values,
        [e.target.id]: item.key
      }
    );
    if (e.target.id === 'sourceType') {
      setsourceTypeVal(item.key);
    }
  };


  //const handleDateFormat = (e) => {
  //  return moment(e).format("MM/DD/YYYY");
  //};

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  //   const LiveRetailOptions: IChoiceGroupOption[] = [
  //     { key: 'activeListings', text: 'Active Listings' },
  //     { key: 'closed', text: 'Closed' },
  //   ];
  // 
  //   const LiveRetailOptionsMob: IChoiceGroupOption[] = [
  //     { key: 'activeListings', text: 'Active' },
  //     { key: 'closed', text: 'Sold' },
  //   ];

  useEffect(() => {
    let targetMileage = qValueDetail?.mileage;
    if (targetMileage && parseInt(targetMileage) > 0) {
      let arryYAxis = pickValueFromArrayObj(mileageChartNew, 'xAxisLabel');
      if (arryYAxis && arryYAxis.length) {
        const closest = arryYAxis.reduce((a, b) => {
          return Math.abs(parseInt(toNumbersFromK(b)) - parseInt(targetMileage)) < Math.abs(parseInt(toNumbersFromK(a)) - parseInt(targetMileage)) ? parseInt(toNumbersFromK(b)) : parseInt(toNumbersFromK(a));
        });
        sethighlightMileage(toKfromNumbersArry(closest, false, true));
      } else {
        sethighlightMileage(null);
      }
    } else {
      sethighlightMileage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qValueDetail?.mileage, mileageChartNew])

  useEffect(() => {
    let myOffer = removeCurrencySymbols(retailMarketFormik.values.myOffer);
    /* let test = (parseInt(retailMarketFormik.values.sellerAsking) ? parseInt(retailMarketFormik.values.sellerAsking) : 0)
      + (parseInt(retailMarketFormik.values.qValue) ? parseInt(retailMarketFormik.values.qValue) : 0) +
      + (parseInt(retailMarketFormik.values.myOffer) ? parseInt(retailMarketFormik.values.myOffer) : 0); */

    retailMarketFormik.setValues(
      {
        ...retailMarketFormik.values,
        'myOfferTarget': (parseInt(myOffer) ? parseInt(myOffer).toString() : '-'),
        'myOfferList': (parseInt(myOffer) ? parseInt(myOffer).toString() : '-'),
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailMarketFormik.values.sellerAsking, retailMarketFormik.values.qValue, retailMarketFormik.values.myOffer]);

  // calculation section
  useEffect(() => {
    let reconditioning = removeCurrencySymbols(retailMarketFormik.values.reconditioning);
    let transportation = removeCurrencySymbols(retailMarketFormik.values.transportation);
    let otherExpense = removeCurrencySymbols(retailMarketFormik.values.otherExpense);
    let targetListPrice = removeCurrencySymbols(retailMarketFormik.values.targetListPrice);
    let profitTarget = removeCurrencySymbols(retailMarketFormik.values.profitTarget);
    let myOfferTarget = removeCurrencySymbols(retailMarketFormik.values.myOfferTarget);
    let myListPriceTarget = removeCurrencySymbols(retailMarketFormik.values.myListPriceTarget);

    let test = (parseInt(reconditioning) ? parseInt(reconditioning) : 0)
      + (parseInt(transportation) ? parseInt(transportation) : 0)
      + (parseInt(otherExpense) ? parseInt(otherExpense) : 0);

    let profilt;
    if ((parseInt(targetListPrice) >= 0)) {
      let expense;
      if (shopVehicleList?.stage === 'shop') {
        expense = (parseInt(myOfferTarget) ? parseInt(myOfferTarget) : 0);
        profilt = (parseFloat(targetListPrice) ? parseFloat(targetListPrice) : 0) - expense - test;
      } else if (shopVehicleList?.stage === 'purchase' || shopVehicleList?.stage === 'sold') {
        expense = parseFloat(shopVehicleList?.totalCost);
        // profilt = (parseFloat(targetListPrice) ? parseFloat(targetListPrice) : 0) - expense;
        profilt = parseFloat(targetListPrice) - parseFloat(shopVehicleList?.totalCost);
      }
    }

    let listPrice;
    if ((parseInt(profitTarget)) >= 0 && (parseInt(myOfferTarget) >= 0)) {
      listPrice = (parseInt(profitTarget) ? parseInt(profitTarget) : 0)
        + (parseInt(myOfferTarget) ? parseInt(myOfferTarget) : 0) + test;
    }


    if (shopVehicleList?.stage === 'purchase' || shopVehicleList?.stage === 'sold') {
      // profilt = (parseFloat(targetListPrice) ? parseFloat(targetListPrice) : 0) - expense;
      listPrice = parseFloat(profitTarget) + parseFloat(shopVehicleList?.totalCost);
    }

    retailMarketFormik.setValues(
      {
        ...retailMarketFormik.values,
        'totalExpenseTarget': test ? test.toString() : '-',
        'totalExpenseList': test ? test.toString() : '-',
        'ProfitList': profilt ? profilt.toString() : '-',
        'myListPriceTarget': listPrice ? listPrice.toString() : '-',
      }
    );
    // set Price Rank,
    findrank('price');
    // set Price Avg
    find_percentage('price');
    if (priceChartNew && priceChartNew.length) {
      if (targetListPrice && parseInt(targetListPrice) > 0 && selectedCalcTab === 'profit') {
        let arryYAxis = pickValueFromArrayObj(priceChartNew, 'xAxisLabel')
        if (arryYAxis && arryYAxis.length) {
          const closest = arryYAxis.reduce((a, b) => {
            return Math.abs(toNumbersFromK(b) - parseInt(targetListPrice)) < Math.abs(toNumbersFromK(a) - parseInt(targetListPrice)) ? toNumbersFromK(b) : toNumbersFromK(a);
          });
          sethighlightListPrice(toKfromNumbersArry(closest, false, true));
        } else {
          sethighlightListPrice(null);
        }
      } else if (myListPriceTarget && parseInt(myListPriceTarget) > 0 && selectedCalcTab === 'list') {
        let arryYAxis = pickValueFromArrayObj(priceChartNew, 'xAxisLabel')
        if (arryYAxis && arryYAxis.length) {
          const closest = arryYAxis.reduce((a, b) => {
            return Math.abs(toNumbersFromK(b) - parseInt(myListPriceTarget)) < Math.abs(toNumbersFromK(a) - parseInt(myListPriceTarget)) ? toNumbersFromK(b) : toNumbersFromK(a);
          });
          sethighlightListPrice(toKfromNumbersArry(closest, false, true));
        } else {
          sethighlightListPrice(null);
        }
      } else {
        sethighlightListPrice(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailMarketFormik.values.reconditioning, retailMarketFormik.values.transportation, retailMarketFormik.values.otherExpense,
  retailMarketFormik.values.targetListPrice, retailMarketFormik.values.myListPriceTarget, retailMarketFormik.values.profitTarget, retailMarketFormik.values.myOfferTarget,
    selectedCalcTab, highlightListPrice]);

  useEffect(() => {

    if (filterLiveReports) {
      if (liveReports && liveReports?.savedFilters?.type === 1) {
        setvehicleCount(filterLiveReports.filteredListings.active_statistics.vehicle_count);
        mapLiveReportTable(filterLiveReports.filteredListings.active_statistics);
      } else if (liveReports?.savedFilters?.type === 2) {
        setvehicleCount(filterLiveReports.filteredListings.sold_statistics.vehicle_count);
        mapLiveReportTable(filterLiveReports.filteredListings.sold_statistics);
      } else {
        setvehicleCount(filterLiveReports.filteredListings.all_statistics.vehicle_count);
        mapLiveReportTable(filterLiveReports.filteredListings.all_statistics);
      }
    }

    // set Price Rank,
    findrank('price');
    // set Price Avg
    find_percentage('price');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveReports, filterLiveReports]);

  // Calculation section
  useEffect(() => {
    let reconditioning = removeCurrencySymbols(retailMarketFormik.values.reconditioning);
    let transportation = removeCurrencySymbols(retailMarketFormik.values.transportation);
    let otherExpense = removeCurrencySymbols(retailMarketFormik.values.otherExpense);

    let test = (parseInt(reconditioning) ? parseInt(reconditioning) : 0)
      + (parseInt(transportation) ? parseInt(transportation) : 0) +
      + (parseInt(otherExpense) ? parseInt(otherExpense) : 0);

    retailMarketFormik.setValues(
      {
        ...retailMarketFormik.values,
        'totalExpenseTarget': test ? test.toString() : '-',
        'totalExpenseList': test ? test.toString() : '-',
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //setcrmList([]);
    setUpdateQValueError('');
    setStatusUpdateError('');

    setVehConditionId(null)

  }, []);

  useEffect(() => {


    if (vehConditionId) {

      if (vehConditionId === 1) {
        handleReport(qValues[1], qValues[0], qValues[2], 'roughCondition');
      } else if (vehConditionId === 2) {
        handleReport(qValues[1], qValues[0], qValues[2], 'averageCondition');
      } else if (vehConditionId === 3) {
        handleReport(qValues[1], qValues[0], qValues[2], 'cleanCondition');
      } else if (vehConditionId === 4) {
        handleReport(qValues[1], qValues[0], qValues[2], 'excellentCondition');
      }
    } else {
      handleReport(qValues[1], qValues[0], qValues[2], 'averageCondition');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qValues, vehConditionId]);



  useEffect(() => {
    if (shopVehicleList && shopVehicleList.id && (shopVehicleList.id === parseInt(params.vehicleID))) {
      formik.setValues(
        {
          ...formik.values,
          'vin': shopVehicleList.vin,
          'make': shopVehicleList.make,
          'model': shopVehicleList.model,
          'year': shopVehicleList.year,
          'style': shopVehicleList.style,
          'trim': shopVehicleList.trim
        }
      );
      if (shopVehicleList.stage !== 'shop') {
        retailMarketFormik.setValues(
          {
            ...retailMarketFormik.values,
            'myOfferTarget': (parseInt(shopVehicleList.purchasePrice) ? parseInt(shopVehicleList.purchasePrice).toString() : '-'),
          }
        );
      }
      if (shopVehicleList.vin) {
        setshowVinField(true);
      } else {
        setshowVinField(false);
      }
      // get Pricing Tab
      getPricingTabData(shopVehicleList.id);
    }

    // Price history Table Data Changes
    if (shopVehicleList?.stage !== 'shop' && (Array.isArray(shopVehicleList?.listPriceHistory) && shopVehicleList?.listPriceHistory.length > 0)) {
      let pricehistoryTable = [];
      shopVehicleList.listPriceHistory.map((data, index) => {

        if (data.price && data.price.includes('.')) {
          data.price = Number(data.price).toFixed(1);
        }
        let obj = {
          date: moment(data.date ? data.date : new Date()).format("MM/DD/YYYY"),
          price: currencyNegValFractionalFormat(data.price)
        };
        pricehistoryTable.push(obj);
      });
      setdatePricelist(pricehistoryTable);
    }

    if (shopVehicleList) {
      if (shopVehicleList.vehicleExpense && shopVehicleList.vehicleExpense.isCalcByProfit) {
        setselectedCalcTab('list')
      } else {
        setselectedCalcTab('profit')
      }
    }
    getTotalCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopVehicleList])

  useEffect(() => {
    setValue(0);
    if (shopVehicleList?.stage === 'shop') {
      setLogDate(shopVehicleList?.createdAt);
      setPurchaseDate(null);
      setSoldDate(null);
    } else if (shopVehicleList?.stage === 'purchase') {
      setLogDate(shopVehicleList?.createdAt);
      setPurchaseDate(shopVehicleList?.acquisitionDate);
      setSoldDate(null);
    } else if (shopVehicleList?.stage === 'sold') {
      setLogDate(shopVehicleList?.createdAt);
      setPurchaseDate(shopVehicleList?.acquisitionDate);
      setSoldDate(shopVehicleList?.soldDate);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopVehicleList?.stage])

  useEffect(() => {
    if (locations && locations.results.length > 0) {
      const location = locations.results.sort(function (a, b) {
        if (a.id !== b.id) {
          return a.id - b.id
        }
      });
      setZipFlag(false);
      updateQValueformik.setValues(
        {
          ...updateQValueformik.values,
          'zip': location[0].zip,
          'state': location[0].state
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    dispatch<any>(handleGetVehicleUsingID({

      id: params.vehicleID,
      callback: (response) => {

        if (response) {
          setVehConditionId(response.defaultVehicleCondition)
        }
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.vehicleID) {
      // if (!shopVehicleList) {
      dispatch<any>(handleGetVehicleUsingID({
        id: params.vehicleID,
        callback: (response) => {
          if (!response) {
            navigate(`/appraisal`);
          }
        }
      }));
      // get QValue page data for Valuation , Styles Dropdown , Retail Data
      getQValuePageData(params.vehicleID);
      dispatch<any>(handleGetSourceWorkBook({
        id: params.vehicleID,
        callback: (response) => {
          if (response && response.id) {

            let local = '';
            if (response.sourceData?.time) {
              var date = moment.utc(response.sourceData.time).format('YYYY-MM-DD HH:mm:ss');
              var stillUtc = moment.utc(date).toDate();
              local = moment(stillUtc).local().format('HH:mm');
            }

            sourceVehicleformik.setValues(
              {
                ...sourceVehicleformik.values,
                'businessName': response.businessName,
                'sourceType': response.source,
                'url': response.sourceData?.auctionUrl,
                'auctionStartTime': local,
                'auctionDate': convertUTCToLocalModified(response.sourceData?.date),
                'location': response.shippingFrom,
                'phone': response.phone,
                'email': response.email,
                'notes': response.notes,
              }
            );

            setsourceTypeVal(response.source);

            if (response.sourceData?.date) {
              setAuctionDate(new Date(response.sourceData.date));
            }
          } else {
            sourceVehicleformik.setValues(
              {
                ...sourceVehicleformik.values,
                'businessName': '',
                'sourceType': '',
                'url': '',
                'auctionStartTime': '',
                'auctionDate': '',
                'location': '',
                'phone': '',
                'email': '',
                'notes': '',
              }
            );
          }
        }
      }));
      // get Category List - only when Vehicle moved to Purchase or Sold
      // getPricingTabData(params.vehicleID);
      // get Plot details
      getPlotDetails();
      // Hit for getting NOTEs
      getNotesDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.vehicleID]);

  useEffect(() => {
    if (priceChart && mileageChart) {
      setbasicData({
        labels: priceChart.xAxis,
        datasets: [
          {
            // label: 'Vehicle by Price',
            data: priceChart.yAxis,
            // fill: true,
            borderColor: '#279672',
            borderWidth: 4.5,
            //tension: .4,
            backgroundColor: '#27967233'
          }
        ]
      });

      setlineStylesData({
        labels: mileageChart.xAxis,
        datasets: [
          {
            // label: 'Vehicle by Mileage',
            data: mileageChart.yAxis,
            borderWidth: 4.5,
            // fill: true,
            borderColor: '#FFA726',
            //tension: .4,
            backgroundColor: 'rgba(255,167,38,0.2)'
          }
        ]
      });
    }

  }, [mileageChart, priceChart]);

  useEffect(() => {
    getTotalCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailMarketFormik.values.totalExpenseList, shopVehicleList?.purchasePrice, shopVehicleList?.dealerPack]);

  const pickValueFromArrayObj = (arrayObj, key) => {
    if (arrayObj && arrayObj.length) {
      return arrayObj.reduce((arr, item) => arr.concat(item[key]), []);
    } else {
      return [];
    }
  }

  const getPlotDetails = () => {
    setmileageChartNew(null);
    setSortedMileagePlot([]);
    setpriceChartNew(null);
    setSortedPricePlot([]);
    sethighlightMileage(null);
    sethighlightListPrice(null);
    dispatch<any>(handleGetQValuePlotUsingID({
      vehId: params.vehicleID,
      callback: (response) => {
        if (response && response.mileage) {
          let arryYAxis = pickValueFromArrayObj(response.mileage.plot, 'yAxis')
          let arryXAxis = pickValueFromArrayObj(response.mileage.plot, 'xAxis')
          setmileageChart({
            xAxis: arryXAxis,
            yAxis: arryYAxis
          });
          // Convert to 'K' if reaches Thousands
          let mileagePlot = response.mileage.plot;
          let mileageChart = toKfromNumbersArry(mileagePlot, true);
          console.log('mileageChart', mileageChart);
          setmileageChartNew([...mileageChart]);

          // arrange array in orders
          let pickMileage = pickValueFromArrayObj(response.mileage.plot, 'xAxis');
          let parsedVal = pickMileage.map((item) => {
            return parseInt(item);
          });
          parsedVal = parsedVal.sort(function (a, b) {
            return a - b;
          });
          console.log('mileageChart parsedVal', parsedVal);
          setSortedMileagePlot(parsedVal);


          /* let targetMileage = qValueDetail?.mileage;
          if (targetMileage && parseInt(targetMileage) > 0) {
            let arryYAxis = pickValueFromArrayObj(response.mileage.plot, 'xAxis');
            if (arryYAxis && arryYAxis.length) {
              const closest = arryYAxis.reduce((a, b) => {
                return Math.abs(b - parseInt(targetMileage)) < Math.abs(a - parseInt(targetMileage)) ? b : a;
              });
              sethighlightMileage(toKfromNumbersArry(closest, false));
            } else {
              sethighlightMileage(null);
            }
          } */
        }
        if (response && response.price) {
          let arryYAxis = pickValueFromArrayObj(response.price.plot, 'yAxis')
          let arryXAxis = pickValueFromArrayObj(response.price.plot, 'xAxis')
          setpriceChart({
            xAxis: arryXAxis,
            xAxisLabel: arryXAxis,
            yAxis: arryYAxis
          });
          // Convert to 'K' if reaches Thousands
          let pricePlot = response.price.plot;
          let priceChart = toKfromNumbersArry(pricePlot, true);

          /* let arryYAxis2 = pickValueFromArrayObj(response.price.plot, 'yAxis')
          let arryXAxis2 = pickValueFromArrayObj(response.price.plot, 'xAxis') */
          setpriceChartNew([...priceChart]);

          let targetListPrice = removeCurrencySymbols(retailMarketFormik.values.targetListPrice);
          if (targetListPrice && parseInt(targetListPrice) > 0) {
            let arryYAxis = pickValueFromArrayObj(response.price.plot, 'xAxis')
            if (arryYAxis && arryYAxis.length) {
              const closest = arryYAxis.reduce((a, b) => {
                return Math.abs(b - parseInt(targetListPrice)) < Math.abs(a - parseInt(targetListPrice)) ? b : a;
              });
              sethighlightListPrice(toKfromNumbersArry(closest, false));
            } else {
              sethighlightListPrice(null);
            }
          } else {
            sethighlightListPrice(null);
          }

          // arrange array in orders
          let pickPrice = pickValueFromArrayObj(response.price.plot, 'xAxis');
          let parsedVal = pickPrice.map((item) => {
            return parseInt(item);
          });
          parsedVal = parsedVal.sort(function (a, b) {
            return (a) - (b);
          });
          setSortedPricePlot(parsedVal);
        }
      }
    }));
  }

  const getNotesDetails = () => {
    dispatch<any>(handleGetQValueNotesUsingID({
      vehId: params.vehicleID,
      callback: (response) => {
        setNotesList(response.results);
      }
    }));
  }

  const saveNotesDetails = () => {
    if (qValNotes && qValNotes.length > 0) {
      setqvaluError(false);
      setqValNotes(null)
      dispatch<any>(handleSaveQValueNotesUsingID({
        vehId: params.vehicleID,
        details: {
          description: qValNotes
        },
        callback: (response) => {
          getNotesDetails();
        }
      }));
    } else {
      setqvaluError(true);
    }

  }

  const getPricingTabData = (id) => {
    if (shopVehicleList?.stage !== 'shop') {
      dispatch<any>(handlePricingCategoriesID({
        callback: (response) => {
          if (response && response.length) {
            const newCategoryList = response.map((item) => {
              return { key: item.key, text: item.value };
            });
            setcategoryList(newCategoryList);

            // get Expense Table data
            dispatch<any>(handleGetPricingUsingID({
              id,
              callback: (response) => {
                if (response && response.results) {
                  setExpenseTableList(response.results);
                }
              }
            }));
          }
        }
      }));
    }
  }

  useEffect(() => {
    if (expenseTableList && shopVehicleList) {
      const totalExpense = getTotalExpenseVal(expenseTableList);
      const initialListPrice = shopVehicleList?.initialListPrice ? Number(shopVehicleList.initialListPrice) : 0;
      const purchasePrice = shopVehicleList?.purchasePrice ? Number(shopVehicleList.purchasePrice) : 0;
      const dealerPack = shopVehicleList?.dealerPack ? Number(shopVehicleList.dealerPack) : 0;
      let listPriceName = "List Price";
      let priceVal = shopVehicleList?.initialListPrice;
      let profitVal = totalExpense + purchasePrice + dealerPack + initialListPrice;

      if (shopVehicleList?.stage === 'sold') {
        listPriceName = 'Sold Price';
        priceVal = shopVehicleList?.sellingPrice;
        profitVal = Number(shopVehicleList?.sellingPrice) - Number(shopVehicleList?.totalCost);
      } else if (shopVehicleList?.stage === 'shop') {
        profitVal = Number(shopVehicleList?.initialListPrice) - Number(shopVehicleList?.purchasePrice) - (totalExpense);
      } else {
        profitVal = Number(shopVehicleList?.initialListPrice) - (purchasePrice + dealerPack) - (totalExpense);
      }

      let newNodes = [
        {
          "key": "0",
          "data": {
            "name": listPriceName,
            "size": priceVal ? currencyNegValFractionalFormat(priceVal) : '$0.00',
            "type": "Folder"
          },
          "children": []
        }
      ];

      if (isEmployeePermitted(
        user.aggregatedDefaultPolicies,
        emplyeePermissionsAction.VEHICLE_VIEW_EXPENSE,
        user.roles
      )) {
        newNodes.push(
          {
            "key": "1",
            "data": {
              "name": "Purchase Price",
              "size": currencyNegValFractionalFormat(purchasePrice + dealerPack),
              "type": "Folder"
            },
            "children": []
          },
          {
            "key": "2",
            "data": {
              "name": "Expenses",
              "size": currencyNegValFractionalFormat(totalExpense),
              "type": "Folder"
            },
            "children": getChildAdjustments(expenseTableList)
          },
          {
            "key": "3",
            "data": {
              "name": "Total Cost",
              "size": currencyNegValFractionalFormat(totalExpense + purchasePrice + dealerPack),
              "type": "Test"
            },
            "children": []
          },
          {
            "key": "4",
            "data": {
              "name": "Front-end Profit ",
              "size": profitVal < 0 ? '-' + currencyNegValFractionalFormat(profitVal) : currencyNegValFractionalFormat(profitVal),
              "type": "Test"
            },
            "children": []
          }
        );
      } else {
        newNodes.push(
          {
            "key": "3",
            "data": {
              "name": "Total Cost",
              "size": currencyNegValFractionalFormat(totalExpense + purchasePrice + dealerPack),
              "type": "Test"
            },
            "children": []
          },
          {
            "key": "4",
            "data": {
              "name": "Front-end Profit",
              "size": profitVal < 0 ? '-' + currencyNegValFractionalFormat(profitVal) : currencyNegValFractionalFormat(profitVal),
              "type": "Test"
            },
            "children": []
          }
        );
      }

      setNodes(newNodes);

      let _expandedKeys = {};
      _expandedKeys['2'] = true;

      setExpandedKeys(_expandedKeys);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseTableList, shopVehicleList]);

  // get QValue page data for Valuation , Styles Dropdown , Retail Data
  const getQValuePageData = (vehicleID, getValuation = true) => {
    dispatch<any>(handleGetQValueDropdownMaster({
      id: vehicleID,
      callback: (response) => {
        if (response) {
          if (response.nadaStyle) {
            const styles = response.nadaStyle.map((item) => {
              return { key: item, text: item };
            });
            setNadaStyles(styles);
          } else {
            setNadaStyles([]);
          }
          if (response.kbbStyle) {
            const styles = response.kbbStyle.map((item) => {
              return { key: item, text: item };
            });
            setKBBStyles(styles);
          } else {
            setKBBStyles([]);
          }
          if (response.bbStyle) {
            const styles = response.bbStyle.map((item) => {
              return { key: item.series + '|' + item.style, text: item.series + ' ' + item.style };
            });
            setBBStyles(styles);

          } else {
            setBBStyles([]);
          }
          setStyleDropdownFlag(true);
        }
      }
    }));
    if (getValuation) {
      dispatch<any>(handleGetQValuations({
        id: vehicleID,
        callback: (response) => {
          if (response && response.length > 0) {
            const nataItem = response.filter(x => x.vendorType === 'nada');
            const kbbItem = response.filter(x => x.vendorType === 'kbb');
            const bbItem = response.filter(x => x.vendorType === 'bb');
            setupdateQValueSection(true);
            handleQvalueResponse({ nadaInfo: nataItem, kbbInfo: kbbItem, bbInfo: bbItem });

            getQValueLiveRetailData(vehicleID);
            getVehicleExpensesData(vehicleID)
          } else {
            if (!locations || locations.totalItems === 0) {
              dispatch<any>(handleGetLocations());
            }
            setupdateQValueSection(false);
          }
        }
      }));
    }
  }

  const getTotalExpenseVal = (expenseTableListArry) => {
    let totalExpense = 0;
    expenseTableListArry.map((data, index) => {
      totalExpense += Number(data.amount);
    });
    // setcaltotalExpenses(totalExpense.toString());
    return totalExpense;
  }

  //const getProfitExpenseValue = (expenseTableListArry) => {
  //  let totalExpense = 0;
  //  expenseTableListArry.map((data, index) => {
  //    totalExpense += parseInt(data.amount);
  //  });
  //  let profit;
  //  if (shopVehicleList?.stage === 'purchase') {
  //    profit = (parseInt(shopVehicleList?.initialListPrice) - parseInt(shopVehicleList?.purchasePrice)) - (totalExpense);
  //  } else {
  //    profit = parseInt(shopVehicleList?.sellingPrice) - totalExpense;
  //  }
  //  return profit;
  //}

  const getChildAdjustments = (expenseTableListArry) => {
    if (expenseTableListArry && expenseTableListArry.length > 0) {
      let result = [
        {
          key: `2-h`,
          data: {
            "name": 'Date',
            "description": 'Description',
            "size": '',
            "typeName": 'Category',
            "type": '',
            "expenseDate": 'Date',
            "pricingID": ''
          },
        }
      ];
      expenseTableListArry.map((data, index) => {

        //if(data.amount && data.amount.includes('.')){
        //     data.amount = Number(data.amount).toFixed(2);
        //}
        let subst = '';
        if (data.expenseDate) {
          let rawExpenseDate = data.expenseDate;
          subst = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0, rawExpenseDate.indexOf("T")) : rawExpenseDate;
        }

        let obj = {
          key: `2--${index}`,
          data: {
            "name": data.expenseDate ? moment(subst).format("MM/DD/YY") : null,
            "description": data.description,
            "size": data.amount ? currencyNegValFractionalFormat(data.amount) : '$0.00',
            "typeName": getPriceCategoryNameById(data.category),
            "type": data.category,
            "expenseDate": data.expenseDate ? moment(subst).format("MM/DD/YY") : null,
            "pricingID": data.id
          },
        };
        result.push(obj);
      });
      return result;
    } else {
      return [];
    }
  };

  const getPriceCategoryNameById = (id) => {
    if (categoryList && categoryList.length) {
      let categoryItem: any = categoryList.filter(x => x.key === id);
      if (categoryItem && categoryItem.length) {
        return categoryItem[0].text;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  const getQValueLiveRetailData = (vehicleID) => {
    // For Live Retail Data
    dispatch<any>(handleGetLiveRetailReports({
      vehID: vehicleID,
      callback: (response) => {
        if (response) {
          if (response) {
            response["mileage"] = qValueDetail.mileage;
            setliveReports(response);
            console.log('liveReports', response.savedFilters.type);
            buildChart(response)
            if (response.stats && response.stats.allTrims) {
              // mapLiveReportTable(response.stats.allTrims.active_statistics);
              // setvehicleCount(response.stats.allTrims.active_statistics.vehicle_count);
            }
          }
          callFilteredReportAPI(vehicleID);
        }
      }
    }));

  }

  const callFilteredReportAPI = (vehicleID) => {
    dispatch<any>(handleGetFilterLiveRetailReports({
      vehID: vehicleID,
      callback: (response) => {
        if (response) {
          if (response) {
            setfilterLiveReports(response);
            /* if (response.filteredListings && response.filteredListings) {
              mapLiveReportTable(response.filteredListings.active_statistics);
              setvehicleCount(response.filteredListings.active_statistics.vehicle_count);
            } */
          }
        }
      }
    }));
  }

  const getVehicleExpensesData = (vehicleID) => {
    // For Live Retail Data
    dispatch<any>(handleGetVehicleExpenses({
      id: vehicleID,
      callback: (response) => {
        if (response) {

          retailMarketFormik.setValues(
            {
              ...retailMarketFormik.values,
              'sellerAsking': response.sellersAsking,
              'qValue': response.qValue,
              'myOffer': response.myOffer,
              'myOfferList': response.myOffer,
              'myOfferTarget': response.myOffer,
              'reconditioning': response.reconditioning,
              'transportation': response.transportation,
              'otherExpense': response.otherExpenses,
              'totalExpenseTarget': response.totalExpenses,
              'targetListPrice': response.targetListPrice,
              'profitTarget': response.targetProfit,
              'myListPriceTarget': response.listPrice,
              'ProfitList': response.profit,
              'notes': response.notes,
            }
          );

        }
      }
    }));
  }

  /* const range_wise_label = (arr, func)=> {
    for(var i=0; i < arr.length; i++){
        func(arr[i], arr[i + 1]?arr[i + 1]:null, i)
    }
  } */

  /* const get_range_label = (data_set, range_list)=>{
  data_set = Object.keys(data_set)
  var result=[]
  range_wise_label(data_set, (current, next, index)=>{
    let end = Math.trunc(parseFloat(next?next:range_list[range_list.length-1])/1000)
    let val = `${Math.trunc((parseFloat(current))/1000)}-${end}` 
    if(index===0){
      val = `${parseFloat(range_list[0])/1000}-${Math.trunc(parseFloat(current)/1000)}`
    }
    result.push(val)
  })
  return result
  } */

  const buildChart = (data) => {
    let tableList = data.listings;
    if (tableList.length) {
      const d_char_data = groupBy(tableList, entries => entries.series)
      var data_set = {}
      d_char_data.forEach((values, key) => data_set[key] = values.length)
      // setdntChtData(data_set);
      setpieChartData({
        labels: Object.keys(data_set),
        datasets: [
          {
            label: 'Total',
            data: Object.values(data_set),
            // backgroundColor: ['#6087cfd6', '#8060cfd6', '#cf60b2d6', '#cf6084d6', '#cf6084d6',
            //  '#9fcf60d6', '#cfb760d6', '#cf8e60d6', '#cf6060d6', '#b4948dd6', '#662525d6', '#665825d6', '#663a25d6']
            backgroundColor: ['#0065CA', '#1C2E47', '#FF8000', '#4F9D3C', '#898D89', '#AF2B01', '#65BC50', '#FF9933', '#007FFD', '#0000CA', '#CA6500', '#336627', '#464646']

          }]
      });
      data_set = {}
      const price_ds = groupByRange(tableList,
        entries => entries.price,
        get_chart_range_list(tableList, 1000, 'price'))
      price_ds.forEach((values, key) => data_set[key] = values.length)
      //setvehPriceData(data_set);
      // let price_range_label = get_range_label(data_set, get_chart_range_list(tableList, 1000, 'price'))
      //setVehPriceLabel(price_range_label)
      data_set = {}
      const mile_ds = groupByRange(tableList,
        entries => numberFormat(entries.mileage),
        get_chart_range_list(tableList, 5000, 'mileage'))
      mile_ds.forEach((values, key) => data_set[key] = values.length)
      //setvehMileData(data_set);
      // let mile_range_label = get_range_label(data_set, get_chart_range_list(tableList, 5000, 'mileage')) 
      //setVehMileLabel(mile_range_label)
    }
  }

  const mapLiveReportTable = (response) => {
    setretailHistorylist([
      {
        retailName: 'List Price',
        low: currencyFormat(response.minimum_price),
        average: currencyFormat(response.mean_price),
        high: currencyFormat(response.maximum_price)
      },
      {
        retailName: 'Mileage',
        low: numberFormat(response.minimum_mileage),
        average: numberFormat(response.mean_mileage),
        high: numberFormat(response.maximum_mileage)
      }
    ]);
  }

  useEffect(() => {
    if (retailHistorylist && retailHistorylist.length)
      find_percentage('price');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailHistorylist]);

  useEffect(() => {
    if (zipFlag) {
      let zipFields = updateQValueformik.values.zip;
      if (zipFields.length === 5 && !isNaN(zipFields as any)) {
        dispatch<any>(switchGlobalLoader(true));
        dispatch<any>(HandleZipCodeFlatData({
          zipCode: zipFields,
          callback: (res) => {
            let state = '';
            let errorMsg = 'Invalid ZIP Code.';
            if (res) {
              state = res.State;
              errorMsg = ''
            }
            updateQValueformik.setValues(
              {
                ...updateQValueformik.values,
                'state': state
              });
            setQValueZipApiMsg(errorMsg);
          }

        }));

        //(getAddreessByZip(zipFields) as any).then((response) => {
        //
        //    if (response.Status) {
        //        if (updateQValueformik.values.state === '') {
        //            updateQValueformik.setValues(
        //                {
        //                    ...updateQValueformik.values,
        //                    'state': response.State
        //                }
        //            );
        //        }
        //        setQValueZipApiMsg("");
        //    }
        //    else {
        //        setQValueZipApiMsg(response.ErrorMessage);
        //        updateQValueformik.setValues(
        //            {
        //                ...updateQValueformik.values,
        //                'state': ""
        //            }
        //        );
        //    }
        //    dispatch<any>(switchGlobalLoader(false));
        //});
      }
      else {
        updateQValueformik.setValues(
          {
            ...updateQValueformik.values,
            'state': ""
          }
        );
        setQValueZipApiMsg("");
      }
    }

    /* setdatePricelist([
      { date: '9/11/2021', price: '$12,00'},
      { date: '9/21/2021', price: '$44,789'},
      { date: '8/16/2021', price: '$34,789'}
    ]); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQValueformik.values.zip]);

  const handleReport = (nada, kbb, bb, key) => {

    const nadaItem = nada ? nada[key] : null;
    const kbbItem = kbb ? kbb[key] : null;
    const bbItem = bb ? bb[key] : null;
    setkbbAttributionDetails({
      'date': kbb?.date
    });

    let reports = reportlist;
    if (bbItem) {
      reports[0].auction = bbItem.auction;
      reports[0].tradeIn = bbItem.tradeIn;
      reports[0].retail = bbItem.retail;
      reports[0].auctionText = bbItem.auctionText;
      reports[0].tradeInText = bbItem.tradeInText;
      reports[0].retailText = bbItem.retailText;
      reports[0].date = bb.date;
      reports[0].isAdjusterSkipped = bb.isAdjusterSkipped;
    }

    if (nadaItem) {
      reports[1].auction = nadaItem.auction;
      reports[1].tradeIn = nadaItem.tradeIn;
      reports[1].retail = nadaItem.retail;
      reports[1].auctionText = nadaItem.auctionText;
      reports[1].tradeInText = nadaItem.tradeInText;
      reports[1].retailText = nadaItem.retailText;
      reports[1].date = nada.date;
      reports[1].isAdjusterSkipped = nada.isAdjusterSkipped;
    }
    if (kbbItem) {
      reports[2].auction = kbbItem.auction;
      reports[2].tradeIn = kbbItem.tradeIn;
      reports[2].retail = kbbItem.retail;
      reports[2].auctionText = kbbItem.auctionText;
      reports[2].tradeInText = kbbItem.tradeInText;
      reports[2].retailText = kbbItem.retailText;
      reports[2].date = kbb.date;
      reports[2].isAdjusterSkipped = kbb.isAdjusterSkipped;
    }
    setreportlist(reports);
  }

  const handleAddInventory = () => {
    setvinDataError(false);

    dispatch<any>(handleGetTrimUsingVin({
      vin: formik.values.searchVin,
      callback: (trimData) => {
        if (!trimData.status) {
          if (trimData.style.length === 0) {
            setvinDataError(true);
          } else if (trimData.style.length > 1) {
            const values = trimData.style.map((item, index) => {
              return { key: item, text: item };
            });
            settrimValues(values);
            setisInventoryTriDialog(true);
            setvehicleID(params.vehicleID);
          } else if (trimData.style.length === 1) {
            let vinTrim = {
              style: trimData.style[0],
              vin: formik.values.searchVin
            }
            dispatch<any>(handlePutVehDetails({
              id: params.vehicleID,
              vehDet: vinTrim,
              callback: (success) => {
                if (success) {
                  navigate(`/${success.id}/shop-inventory`);
                  setupdateQValueSection(false);
                  getQValuePageData(success.id, false);
                }
              }
            }));
          }
        } else {
          if (trimData.status === 409) {
            if (trimData.data) {
              setduplicatevehicleID(trimData.data);
              setisInventoryFoundDialog(true);
            }
          } else {
            setvinDataError(true);
          }
        }
      }
    })
    );
  };

  const handleQValueDropdownValue = (e, item) => {

    updateQValueformik.setValues(
      {
        ...updateQValueformik.values,
        [e.target.id]: item.key
      });
  };

  const changeToUppercase = (e: any) => {
    formik.setFieldValue("searchVin", e.target.value.trim().toUpperCase());
  };

  const retailNameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        <span style={{ display: "block" }}>{rowData.retailName}</span>
      </React.Fragment>
    );
  };

  const lowBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        <span style={{ display: "block" }}>{rowData.low}</span>
      </React.Fragment>
    );
  };

  const averageBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        <span style={{ display: "block" }}>{rowData.average}</span>
      </React.Fragment>
    );
  };

  const highBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        <span style={{ display: "block" }}>{rowData.high}</span>
      </React.Fragment>
    );
  };


  const nameBodyTemplate = (rowData) => {


    if (rowData.name === 'Black Book' && isNegativeFlagIcon) {
      return (
        <React.Fragment>
          <div style={{ textAlign: "left", marginLeft: "20px" }}>
            <span className="p-column-title"></span>

            <div style={{ display: "flex" }}>
              <div>
                <span className="secondText"> {rowData.name} </span>
                <span className="secondText">{rowData.date}</span>
              </div>
              <div >
                <img
                  alt="downloadIm111g"
                  key={"downloadImg"}
                  style={{ height: "27px", width: "50px", marginTop: "7px" }}
                  onClick={handleShowModal}
                  src={downloadImage}
                />
                <span></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div style={{ textAlign: "left", marginLeft: "20px" }}>
            <span className="p-column-title"></span>
            {rowData.name}

            <span className="secondText">{rowData.date}</span>
          </div>
        </React.Fragment>
      );
    }

  };

  const auctionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        {rowData.auction}
        <span className="secondText">{rowData.auctionText}</span>
      </React.Fragment>
    );
  };

  const tradeInBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        {rowData.tradeIn}
        <span className="secondText">{rowData.tradeInText}</span>
      </React.Fragment>
    );
  };

  const retailBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title"></span>
        {rowData.retail}
        <span className="secondText">{rowData.retailText}</span>
      </React.Fragment>
    );
  };

  const disableActionBtn = (rowData) => {
    if (rowData && (rowData.auction !== 'NA') && (rowData.retail !== 'NA') && (rowData.tradeIn !== 'NA')) {
      if (rowData.name) {
        if (rowData.name.toLowerCase().includes('kbb') && kbbActionBtnDisable) {
          return true;
        } else if (((rowData.name.toLowerCase().includes('jdpower')) || (rowData.name.toLowerCase().includes('nada'))) && nadaActionBtnDisable) {
          return true;
        } else if (rowData.name.toLowerCase().includes('black') && bbActionBtnDisable) {
          return true;
        }
      }
    }
    return false;
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>

        <EditShopWorkBookMoreActionSubmenu
          disableIcon={disableActionBtn(rowData)}
          reportType={rowData}
          onEdit={(reportType) => generateReports(reportType)}
        />
      </React.Fragment>
    );
  };

  const generateReports = (e) => {
    setDummyReportName(null);
    if (e.data) {
      e = e.data;
    }
    if ((e.auction && e.auction !== 'NA') || (e.data && e.data.auction && e.data.auction !== 'NA')) {
      setStatusUpdateError('');
      if (e && !e.isAdjusterSkipped) {//newVehicleFlag
        setNewVehicleFlag(true);
        let name;
        if (e.name) {
          name = e.name;
        }
        else if (e.data) {
          name = e.data.name;
        }

        setreportsType('');
        setreportScreenName('');
        dispatch<any>(handleGetAdjusterList({
          id: shopVehicleList.id,
          reportType: name && (name === 'Black Book' ? 'blackbook' : (name === 'J.D. Power' ? 'nada' : 'kbb')),
          callback: (rawList) => {
            if (rawList && rawList.length) {
              let list = [];
              if (name === 'Black Book') {
                rawList.map((item) => {
                  list.push({ key: item.uoc, text: item.name, checked: item.isincluded ? true : false })
                });
              }
              else if (name === 'J.D. Power') {
                rawList.map((item) => {
                  list.push({ key: item.acccode, text: item.accdesc, checked: item.isincluded ? true : false })
                });
              }
              else {
                rawList.map((item) => {
                  list.push({ key: item.vehicleOptionId, text: item.optionName, checked: item.isincluded ? true : false })
                });
              }
              setAdjusterList(list);
              setDummyReportName(name);
              setisCustomizedAdjustmentsDialog(true);
            } else {
              setDummyReportName(name);
              handleUpdateTable([], name);
            }
          }
        }));

      } else {

        setqValueScreen('reports');
        if (e.name) {
          setreportsType(e.name);
          setreportScreenName(e.name);
        }
        else if (e.data) {
          setreportsType(e.data.name);
          setreportScreenName(e.data.name);
        }
      }
    }
  }

  const handleReportRowClick = (event) => {

    if (event && event.data && (event.data.auction !== 'NA') && (event.data.retail !== 'NA') && (event.data.tradeIn !== 'NA')) {
      let target = event.originalEvent.target;
      if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
        target.lastElementChild.lastElementChild.firstElementChild.click();
      } else {

        generateReports(event);


      }
    }
  }

  useEffect(() => {
    if (isNegativeFlagModal) {
      setqValueScreen('main');
      setreportsType(null);
      setreportScreenName(null);
    }
  }, [isNegativeFlagModal])

  const updateRetailListing = () => {
    setStatusUpdateError('');
    setqValueScreen('retail');
    setreportsType('retail');
    setreportScreenName('Live Retail Market');
    window.scroll(0, 0);
  }

  const handleSourceSubmit = () => {
    sourceVehicleformik.handleSubmit();
  };

  const handleUpdateTable = (items, rptName = '') => {
    let adjusters = {
      applied_adjusters: [],
      unapplied_adjusters: [],
    };
    items.map((item) => {
      if (item.checked) {
        adjusters.applied_adjusters.push(item.key);
      } else {
        adjusters.unapplied_adjusters.push(item.key);
      }
    });
    adjusters["isAdjusterSkipped"] = true;
    const tempRptName = rptName ? rptName : dummyReportName;
    dispatch<any>(handlePutAdjusterList({
      id: shopVehicleList.id,
      reportType: tempRptName && (tempRptName === 'Black Book' ? 'blackbook' : (tempRptName === 'J.D. Power' ? 'nada' : 'kbb')),
      data: adjusters,
      callback: (list) => {
        //setAdjustedDataUpdated(true);
        //adjustedData(list);
        updateAdjustedData(list);

        const reportData = [...reportlist];
        if (reportData) {
          if (tempRptName === 'Black Book')
            reportData[0].isAdjusterSkipped = true;
          else if (tempRptName === 'J.D. Power')
            reportData[1].isAdjusterSkipped = true;
          else
            reportData[2].isAdjusterSkipped = true;

          setreportlist(reportData);
        }
        setNewVehicleFlag(false);
        setqValueScreen('reports');
        if (tempRptName) {
          setreportsType(tempRptName);
          setreportScreenName(tempRptName);
        }

        setisCustomizedAdjustmentsDialog(false);
      }
    }));


  }

  const handleAuctionStartTimeChange = (e) => {
    if (e.target.value) {
      sourceVehicleformik.setValues(
        {
          ...sourceVehicleformik.values,
          'auctionStartTime': e.target.value
        }
      );
    }
  }

  const handleQvaluePriceChange = (e, name: any) => {
    let removedText = removeCurrencySymbols(e);
    if (!removedText || (removedText.length <= 5 && !isNaN(removedText))) {
      retailMarketFormik.setValues(
        {
          ...retailMarketFormik.values,
          [name]: e
        }
      );
    }
  };

  const removeCurrencySymbols = (value) => {
    return value ? value.replace(/\D+/g, '') : value;
  }

  const handleZipCodeChangeForDealer = (e) => {
    setZipFlag(true);
    if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
      updateQValueformik.setValues(
        {
          ...updateQValueformik.values,
          zip: e.target.value
        }
      );
    }
  };

  const handleOrderQValue = () => {
    updateQValueformik.handleSubmit();
  }

  const handleLocationChange = (e) => {
    const re = /^[a-zA-Z0-9 ]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      //this.setState({ value: e.target.value })
      sourceVehicleformik.setFieldValue("location", e.target.value);
    }
  }

  const rowClassName = (node) => {
    if (node.key && node.key === '2-h') {
      return { 'childRowExpense-header': (node.key && node.key.includes('2-h')) };
    }
    else if (node.key && node.key.includes('2-')) {
      return { 'childRowExpense': (node.key && node.key.includes('2-')) };
    }
  }

  const handleNavClick = (event) => {

    if ((reportScreenName && (reportScreenName.toLowerCase().includes('power')
      || reportScreenName.toLowerCase().includes('kelley')
      || reportScreenName.toLowerCase().includes('black')) && (qValueScreen === 'reports'))
      || (qValueScreen.toLowerCase() === 'retail' && !liveRetailComp)) {
      setqValueScreen(event);
      setUpdateQValueError('');
      setliveRetailComp(false);
    } else if (liveRetailComp) {
      setliveRetailComp(false);
      setshowLiveRetail({
        id: Math.random(),
        show: false
      });
    } else {
      const state =  location?.state
      navigate(location?.state?.pathfrom, {state});

    }
  }
  const handleLink = (url) => {
    window.open(url, "_blank")
  }

  const handleMaskPhoneInput = (e) => {
    sourceVehicleformik.setFieldValue("phone", e.target.value);
  };

  const handleInventoryStatusChange = () => {
    setStatusUpdateError('');
    if (params.vehicleID && shopVehicleList.status) {
      dispatch<any>(handleVehicleStatus(params.vehicleID, shopVehicleList.status, (res) => {
        if (res) {
          setStatusUpdateError(res.message);
        }
      }));
    }
  };

  // const handleDropdownListingChange = (e, option) => {
  //   setSelectedLiveRetailKey(option.key);
  //   if (option.key === 'closed') {
  //     mapLiveReportTable(filterLiveReports?.filteredListings.sold_statistics);
  //     setvehicleCount(filterLiveReports?.filteredListings.sold_statistics.vehicle_count);
  //   } else {
  //     mapLiveReportTable(filterLiveReports?.filteredListings.active_statistics);
  //     setvehicleCount(filterLiveReports?.filteredListings.active_statistics.vehicle_count);
  //   }
  // }

  const findrank = (type) => {
    let basevalue = 0;
    if (type === 'price' && retailMarketFormik.values.targetListPrice)
      basevalue = parseFloat(retailMarketFormik.values.targetListPrice.replace('$', '').replace(',', ''));
    else if (retailMarketFormik.values.profitTarget)
      basevalue = parseFloat(retailMarketFormik.values.profitTarget.replace('$', '').replace(',', ''));

    let data = liveReports?.filteredListings
    /* if (data) {
      data = data.filter((ele) => {
        return ele.listing_type.toLowerCase() === 'active';
      });
    } */
    if (data && basevalue && basevalue > 0) {
      let dataList = []
      data.forEach((e) => dataList.push(e.price));
      dataList.sort((a, b) => a < b ? 1 : a > b ? -1 : 0);
      let result_index = dataList.indexOf(findClosest(dataList, basevalue))

      if (dataList[result_index] > basevalue)
        result_index = result_index + 1;
      else
        result_index = result_index + 1;

      const setRankVal = `${result_index} of ${dataList.length}`;
      setpriceRank(setRankVal);
      return;
    }
    const setRank = `- of ${(data && data.length) ? (data.length) : '-'}`;
    setpriceRank(setRank);
  }

  const find_percentage = (type) => {
    let result;
    let n = 0;
    if (type === 'price' && retailMarketFormik.values.targetListPrice)
      n = parseFloat(retailMarketFormik.values.targetListPrice.replace('$', '').replace(',', ''));
    else if (retailMarketFormik.values.profitTarget)
      n = parseFloat(retailMarketFormik.values.profitTarget.replace('$', '').replace(',', ''));

    /* if (retailHistorylist.length && n && n > 0) {
        let d = retailHistorylist.find(o => o.retailName === "List Price");
        const avg = d && d.average && parseFloat(d.average.replace(/\$|,/g, ''));
        if (avg && avg !== 0)
            result = Math.trunc((n / avg) * 100)
    } */
    if (filterLiveReports && n && n > 0) {
      // let d = retailHistorylist.find(o => o.retailName === "List Price");
      const avg = (filterLiveReports && filterLiveReports.filteredListings?.active_statistics && filterLiveReports.filteredListings?.active_statistics?.mean_price) ? parseFloat(filterLiveReports.filteredListings?.active_statistics?.mean_price?.replace(/\$|,/g, '')) : 0;
      if (avg && avg !== 0)
        result = Math.trunc((n / avg) * 100)
    }

    const avgPerct = result ? result : '-';
    setavgPricePercent(avgPerct);
  }

  /* const barOptions = {
    scales: {
      xAxes: [{
          barPercentage: 0.4,
          categoryPercentage: 1.0
      }]
    },
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
  }; */

  /* const vechPriceChart = {
    labels: vehPriceLabel,
    datasets:[{
      label: 'Vehicle by Price ($000s)',
      data: Object.values(vehPriceData),
      backgroundColor: Object.keys(vehPriceData).map((k, i)=> i===1? '#FF8000':'#007FFD')
    }]
  }
  const vechMileChart = {
    labels: vehMileLabel,
    datasets:[{
      label: 'Vehicle by Mileage (000s)',
      data: Object.values(vehMileData),
      backgroundColor: Object.keys(vehMileData).map((k, i)=> i===1? '#FF8000':'#007FFD')
    }]
  } */

  useEffect(() => {
    if (updateQValueSection === false) {
      if (nadaStyles.length === 0) {
        setNadaStylePlaceholder('Vehicle Unavailable');
      } else {
        setNadaStylePlaceholder('');
        if (nadaStyles.length === 1) {
          updateQValueformik.setValues(
            {
              ...updateQValueformik.values,
              'nada': nadaStyles[0].key
            }
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nadaStyles]);

  useEffect(() => {
    if (updateQValueSection === false) {
      if (kbbStyles.length === 0) {
        setKBBStylePlaceholder('Vehicle Unavailable');
      } else {
        setKBBStylePlaceholder('');
        if (kbbStyles.length === 1) {
          updateQValueformik.setValues(
            {
              ...updateQValueformik.values,
              'kbb': kbbStyles[0].key
            }
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbStyles]);

  useEffect(() => {
    if (updateQValueSection === false) {
      if (bbStyles.length === 0) {
        setBBStylePlaceholder('Vehicle Unavailable')
      } else {
        setBBStylePlaceholder('')
        if (bbStyles.length === 1) {
          updateQValueformik.setValues(
            {
              ...updateQValueformik.values,
              'blackbook': bbStyles[0].key
            }
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbStyles]);


  const handleVehicleExpensesSubmit = () => {
    setUpdateQValueError('');
    // retailMarketFormik.handleSubmit();
    const expenses: any = {
      id: params.vehicleID,
      sellersAsking: retailMarketFormik.values.sellerAsking?.replace(/\$|,/g, ''),
      qValue: retailMarketFormik.values.qValue?.replace(/\$|,/g, ''),
      myOffer: retailMarketFormik.values.myOffer?.replace(/\$|,/g, ''),
      reconditioning: retailMarketFormik.values.reconditioning?.replace(/\$|,/g, ''),
      transportation: retailMarketFormik.values.transportation?.replace(/\$|,/g, ''),
      otherExpenses: retailMarketFormik.values.otherExpense?.replace(/\$|,/g, ''),
      totalExpenses: retailMarketFormik.values.totalExpenseTarget?.replace(/\$|,/g, ''),
      targetListPrice: retailMarketFormik.values.targetListPrice?.replace(/\$|,/g, ''),
      targetProfit: retailMarketFormik.values.profitTarget?.replace(/\$|,/g, ''),
      profit: retailMarketFormik.values.ProfitList?.replace(/\$|,/g, ''),
      listPrice: retailMarketFormik.values.myListPriceTarget?.replace(/\$|,/g, ''),
      isCalcByProfit: selectedCalcTab === 'list' ? true : false,
      // notes: values.notes
    };
    dispatch<any>(handleSaveVehicleExpenses({
      expenses,
      callback: (response) => {
        if (!response.id) {
          setUpdateQValueError('Failed to Save the Data.');
          window.scrollTo(0, 0);
        } else {
          setSaveSuccessDialog(true);
          // handle Pricing Tab data when saved Expenses only when Veh is in Appraisal or Sold
          getPricingTabData(params.vehicleID);
        }

      }
    }));
    find_percentage('price');
    findrank('price');
  };

  const openExpensePopup = (popupName, rowData) => {
    setupdateExpenseDialog(true);
    if (popupName === 'new') {
      rowData = {
        name: "",
        size: "",
        type: ""
      };
    }
    setexpenseQValues(rowData);
  };

  const openPricingTabUpdatePopup = (newPriceVal: any = null) => {
    setupdatePricingTabDialog(true);
    let newPrice = '';
    if (newPriceVal) {
      if (selectedCalcTab === 'profit') {
        newPrice = retailMarketFormik.values.targetListPrice;
      } else {
        newPrice = retailMarketFormik.values.myListPriceTarget;
      }
    }
    setpriceDetails({
      currentPrice: getValueFromArrayList(shopVehicleList?.listPriceHistory, 'price'),
      date: getValueFromArrayList(shopVehicleList?.listPriceHistory, 'date'),
      newPrice,
      dealerPack: shopVehicleList?.dealerPack
    })
  };

  const getValueFromArrayList = (arry, key) => {
    if ((Array.isArray(arry) && arry.length > 0)) {
      return arry[0][key];
    }
    return '';
  }

  const addExpenseTemplate = (node, column) => {
    if (node && node.key === '2') {
      return <div>
        {/* <Button type="button" icon="pi pi-search" className="p-button-success" style={{ marginRight: '.5em' }}></Button> */}
        <div className="addExpense" onClick={() => openExpensePopup('new', node.data)}

          style={!isEmployeePermitted(
            user.aggregatedDefaultPolicies,
            emplyeePermissionsAction.VEHICLE_ADD_EXPENSE,
            user.roles
          ) ? hiddenStyle : null}>
          Add Expense</div>
      </div>;
    } else if (node && node.key.includes('2-')) {
      if (node.key === '2-h') {
        return <div>Description</div>;
      }
      else {
        return <div>{node.data.description}</div>
      }
    }
    else {
      return null;
    }
  }

  const addEditTemplate = (node, column) => {
    if (node && node.key.includes('2--')) {
      return <div className="text-center"><Link className="addExpense" color="inherit">
        <EditIcon style={themeStyles.icon} />
      </Link></div>
    } else {
      return null;
    }
  }

  const handleRowClick = (evt) => {
    if (evt && evt.node && evt.node.key.includes('-') && !evt.node.key.includes('-h')) {

      const role = isUserMaster(user.roles);
      const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT_EXPENSE?.toString())) : true;
      if (cred || role) {
        openExpensePopup('edit', evt.node.data);
      }
    }
  };

  const handleInventoryEdit = () => {

    const role = user.roles;
    const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (shopVehicleList.stage === 'shop' ? emplyeePermissionsAction.VEHICLE_ACQUIRE : emplyeePermissionsAction.VEHICLE_SELL)) : true;
    if (cred || role) {
      setvehicleID(params.vehicleID);
      if (shopVehicleList.stage === 'shop') {
        setIsPurchaseTransDialog(true);
      } else if (shopVehicleList.stage === 'purchase') {
        setIsSellDialog(true);
      } else if (shopVehicleList.stage === 'sold') {
        setIsSoldDialog(true);
      }
    }
  }

  const calciTabChange = (tab) => {
    setselectedCalcTab(tab);
  }

  const getTotalCost = () => {
    if (shopVehicleList && retailMarketFormik) {
      const totalExp = retailMarketFormik?.values?.totalExpenseList.replace(/\D+/g, '');
      let totalCost: any = parseInt(totalExp) + parseInt(shopVehicleList?.purchasePrice) + parseInt(shopVehicleList?.dealerPack);
      if (shopVehicleList.stage !== 'shop') {
        totalCost = currencyNegValFractionalFormat(shopVehicleList.totalCost);
      } else {
        totalCost = currencyNegValFractionalFormat(totalCost);
      }
      setTotalCostCal(totalCost);
    }
  }

  const handleEditVehicle = () => {
    setIsEditVehicleDialog(true);
  }

  const qvalueDialogDismiss = () => {
    setupdateQvalueDialog(false);
  }

  const onRenderOptionForVehicleCondition = (option: IDropdownOption): JSX.Element => {
    return (
      <div className="vehicleConditionOption">
        <strong>{option.text}</strong> <span>{option.data}</span>
      </div>
    );
  };

  const handleVehicleConditionChange = (e, item) => {
    updateQValueformik.setValues(
      {
        ...updateQValueformik.values,
        [e.target.id]: item.key
      }
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
      'pi-plus-circle': !expanded,
      'pi-minus-circle': expanded
    });

    return (
      <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{ color: '#ffffff', backgroundColor: '#0065cb' }} tabIndex={-1} onClick={options.onClick}>
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  return (
    <Stack tokens={{ childrenGap: 10 }} className="accordianHeaderTxt shopInventoryEdit">
      {saveSuccessDialog ?
        <SaveSuccessDialog
          onDismiss={() => { setSaveSuccessDialog(false); }}
        /> : null}
      {isPurchaseTransDialog ?
        <PurchaseTransitionDialog
          onDismiss={(msg) => { setStatusUpdateError(msg); setIsPurchaseTransDialog(false); }}
          vehicleId={vehicleID}
        />
        : null}

      {isEditVehicleDialog ?
        <EditVehicleDialog
          onDismiss={(msg) => { setStatusUpdateError(msg); setIsEditVehicleDialog(false); }}
          vehicleData={shopVehicleList}
        />
        : null}

      {isSellDialog ?
        <SellInventoryDialog
          onDismiss={(msg) => { setStatusUpdateError(msg); setIsSellDialog(false); }}
          vehicleId={vehicleID}
          purchaseDates={shopVehicleList.acquisitionDate}
        />
        : null}

      {isSoldDialog ?

        <SoldVehicleInventoryDialog
          onDismiss={(msg) => { setIsSoldDialog(false); }}
          vehicleId={vehicleID}
          soldDate={shopVehicleList.soldDate}

        />
        : null}

      {isInventoryTriDialog ?
        <AddInventoryTrimDialog
          onDismiss={() => setisInventoryTriDialog(false)}
          trimValues={trimValues}
          vin={formik.values.searchVin}
          ymm={null}
          vehID={vehicleID}
          orderStock={null}
        />
        : null}

      {isInventoryFoundDialog ?
        <AddInventoryFoundDialog
          onDismiss={() => setisInventoryFoundDialog(false)}
          vehicleId={duplicatevehicleID}
        />
        : null}
      {isNegativeFlagModal ?
        <NegativeBlackBookHistoryDialog
          onDismiss={() => setIsNegativeFlagModal(false)}
        />
        : null}

      {isLiveReportDialog ?
        <AddLiveReportSettingsDialog
          onDismiss={() => setisLiveReportDialog(false)}
          trimValues={trimValues}
          vin={formik.values.searchVin}
          ymm={null}
          vehID={params.vehicleID}
        />
        : null}

      {updateQvalueDialog ?
        <AddUpdateQvalueDialog

          onDismiss={qvalueDialogDismiss}
          nadaStyles={nadaStyles}
          kbbStyles={kbbStyles}
          bbStyles={bbStyles}
          vehID={params.vehicleID}
          qValues={qValues}
          qValueDetail={qValueDetail}
          onSubmitFormik={handleSaveQValuesData}
          vehicleConditionId={vehConditionId}
        />
        : null}


      {updateExpenseDialog ?
        <AddUpdateExpenseDialog
          onDismiss={() => setupdateExpenseDialog(false)}
          categoryList={categoryList}
          kbbStyles={kbbStyles}
          bbStyles={bbStyles}
          vehID={params.vehicleID}
          pricingTableVal={expenseQValues}
          qValueDetail={qValueDetail}
          onSubmitFormik={handleSaveExpenseData}
          onDelete={handleDeleteExpenseData}
        />
        : null}

      {updatePricingTabDialog ?
        <UpdatePricingTabDialog
          onDismiss={() => setupdatePricingTabDialog(false)}
          priceDetails={priceDetails}
          vehID={params.vehicleID}
          onSubmitFormik={handleUpdatePricingData}
        />
        : null
      }

      {isCustomizedAdjustmentsDialog ?
        <AddCustomizedAdjustmentsDialog
          adjusterList={adjusterList}
          onDismiss={() => { setisCustomizedAdjustmentsDialog(false); }}
          handleUpdate={(items) => handleUpdateTable(items)}
          isFirstTime={newVehicleFlag}
        /> : null}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          bgcolor: 'background.paper',
          display: 'block',
        }}
      >
        <div className={"vehicle-container"} style={{ paddingTop: "30px" }}>
          {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
            <Row>
              <Col sm={12} md={12} xs={12} lg={12}>
                <div className="qvalueNavigation">
                  {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                              <Link color="inherit" onClick={() => handleNavClick('main')}>
                                  <HomeIcon className={classes.icon} />
                                    Vehicle Home
                              </Link>
                              {qValueScreen === 'reports' ?
                                  <Link color="inherit" onClick={() => handleNavClick('report')}>
                                      {(reportScreenName === 'Kelley Blue Book') ? reportScreenName : ((reportScreenName === 'J.D. Power') ? reportScreenName : reportScreenName)}
                                  </Link> : qValueScreen === 'retail' ?
                                      <Link color="inherit" onClick={() => handleNavClick('retail')}>
                                          {reportScreenName}
                                      </Link> : null}
                              {liveRetailComp ?
                                  <Link color="inherit" onClick={() => handleNavClick('comp')}>
                                      Competitive Vehicle Details
                                </Link> : null}
                          </Breadcrumbs> */}

                  <img
                    alt="Back"
                    key={"back"}
                    className={isMobile ? "backButton" : "backButton back-nav-btn"}
                    onClick={() => handleNavClick('main')}
                    src={`${require("assets/back_pagination.png")}`}
                  />
                  <span></span>
                </div>
              </Col>

            </Row>

            : null}

          {

            (qValueScreen === 'reports' || qValueScreen === 'retail') ?

              <div className={isMobilePhone ? " " : "rowCenterAlignItemsRetail "}>
                <Row>
                  <Col sm={12} md={12} xs={12} lg={12}>

                    <div className="qvalueNavigation">
                      <img
                        alt="Back"
                        key={"back"}
                        className={isMobile ? "backButton" : "backButton back-nav-btn"}
                        onClick={() => handleNavClick('main')}
                        src={`${require("assets/back_pagination.png")}`}
                      />
                      <span></span>
                    </div>
                  </Col>

                </Row>
                <Row className="pageTitleFont inventoryEditColRowSection inventoryEditColRowSectionEdit readField reportTitle fontWeightSection">
                  <Col sm={12}>
                    <strong className="pageTitleFont blueShadedText readField"> {reportScreenName} </strong>
                  </Col>
                </Row>

                <Row className="pageTitleGreyFont editWorkBookFirstRow">

                  <Col sm={1} style={{ paddingLeft: "37px" }} className="carImgCol">
                    <img
                      className="shopCarIcon"
                      alt="inventory video"
                      // key={"thumb" + index}
                      // style={thumbNailStyle}
                      src={`${require("assets/vehicle_details.png")}`}
                    />
                  </Col>
                  <Col sm={12} className="carContentCol">
                    <Row className="inventoryEditColRowSection readField fontWeightSection vehicleInfoHeadSection">
                      <Col sm={8} >
                        <span className="pageTitleGreyFont blueShadedText">{formik.values.vin}</span>
                      </Col>
                    </Row>
                    <Row className="inventoryEditColRowSection readField fontWeightSection vehicleInfoHeadSection">
                      <Col sm={8} >
                        <span className="pageTitleGreyFont greyShadedText">{formik.values.year}</span>
                        <span className="pageTitleGreyFont greyShadedText"> {formik.values.make}</span>
                        <span className=" pageTitleGreyFont greyShadedText"> {formik.values.model}</span>
                      </Col>
                    </Row>
                    <Row className="inventoryEditColRowSection readField fontWeightSection vehicleInfoHeadSection">
                      <Col sm={8} >
                        <span className="pageTitleGreyFont greyShadedText">{formik.values.style}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              : null}
          {
            vinDataError ?
              <div>
                <Row>
                  <Col>
                    <p className="genericErrorColor"><span>No Data found.</span></p>
                  </Col>
                </Row>
              </div> : null
          }
          {
            statusUpdateError ?
              <div>
                <p className="genericErrorColor"><span>{statusUpdateError}</span></p>
              </div> : null
          }

          <div className="vehicle-header-d">
            <Row className="pageTitleGreyFont">

              {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                <Col sm={2} >
                  <Link
                    onClick={() => handleEditVehicle()}
                    underline="none"
                    style={{ color: "rgb(0, 101, 202)" }}
                    className="hover-false"
                  >
                    <div className="box vehicle-edit">
                      <img
                        className="shopCarIcon"
                        alt="inventory video"
                        // key={"thumb" + index}
                        // style={thumbNailStyle}
                        src={`${require("assets/vehicle_details.png")}`}
                      />
                      <div style={{ textAlign: "center" }}>
                        <strong>Edit</strong>
                      </div>
                    </div>
                  </Link>
                </Col> : null}
              <Col sm={8} style={{ paddingLeft: "0", paddingRight: "0" }} >

                {
                  shopVehicleList && qValueScreen !== 'reports' && qValueScreen !== 'retail' && shopVehicleList.stage ? <>
                    <Row className="inventoryEditColRowSection readField vehicleInfoHeadSection inventory-status-link screenNamePad">
                      <Col sm={5} >
                        {/* <EmployeeActionPermission
                           permissionList={user.aggregatedDefaultPolicies}
                           alias={shopVehicleList.stage === 'shop' ? emplyeePermissionsAction.VEHICLE_ACQUIRE : emplyeePermissionsAction.VEHICLE_SELL}
                           roles={user.roles}
                         > */}

                        <EmployeeActionPermission
                          permissionList={user.aggregatedDefaultPolicies}
                          alias={shopVehicleList.stage === 'shop' ? emplyeePermissionsAction.VEHICLE_ACQUIRE : emplyeePermissionsAction.VEHICLE_SELL}
                          roles={user.roles}
                        >
                          <Link
                            //onClick={() => handleInventoryEdit()}
                            //underline={false}
                            className={shopVehicleList.status + " " + shopVehicleList.stage + " vehicle-status-txt"}
                          >

                          </Link>

                        </EmployeeActionPermission>

                        {/* </EmployeeActionPermission>     */}
                      </Col>
                    </Row>
                  </> : null
                }

                {(!showVinField && qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                  <Row className="inventoryEditColRowSection inventoryVinSearch vehicleInfoHeadSection" >
                    <Col sm={11}  >
                      <OffTextField
                        id="searchvin"
                        name="searchvin"
                        label="VIN"
                        //iconProps={{ iconName: "Camera" }}
                        value={formik.values.searchVin}
                        onChange={changeToUppercase}
                        onBlur={formik.handleBlur}
                        errorMessage={
                          formik.touched.searchVin &&
                          formik.errors.searchVin &&
                          formik.errors.searchVin.toString()
                        }
                      />
                      <PrimaryButton
                        // disabled={!formik.isValid || !formik.dirty}
                        // onRenderIcon={renderSpinner}
                        style={{ marginTop: '15px' }}
                        text="Search"
                        className="decodeBtn"
                        onClick={handleAddInventory}
                      />
                    </Col>
                  </Row> : null}

                {showVinField && qValueScreen !== 'reports' && qValueScreen !== 'retail' ?
                  <Row className="inventoryEditColRowSection readField vehicleInfoHeadSection">
                    <Col>
                      {/* <strong>Vin </strong> */}
                      <span className="vehicle-info-txt">{formik.values.vin}</span>
                      <span style={{ float: "right" }} className="vehicle-info-txt">Stock: {shopVehicleList?.stockNumber ? shopVehicleList.stockNumber : '--'}</span>
                    </Col>
                  </Row> : null}

                {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                  <Row className="inventoryEditColRowSection readField vehicleInfoHeadSection">
                    <Col sm={8} className="vehDetailPad">
                      {/* <strong>Make </strong> */}
                      <span className="vehicle-info-txt">{formik.values.year}</span>
                      <span className="vehicle-info-txt"> {formik.values.make}</span>
                      <span className="vehicle-info-txt"> {formik.values.model}</span>
                    </Col>
                  </Row> : null}

                {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                  <Row className=" inventoryEditColRowSection readField vehicleInfoHeadSection">
                    <Col sm={8} className="vehDetailPad"  >
                      {/* <strong>Model </strong> */}
                      {/* <span>{formik.values.model}</span> */}
                      <span className="pageTitleGreyFont greyShadedText vehicle-info-txt">{formik.values.style}</span>
                    </Col>
                  </Row> : null}

                {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                  <Row className=" inventoryEditColRowSection readField vehicleInfoHeadSection">
                    <Col className="vehDetailPad" >
                      <span className="pageTitleGreyFont greyShadedText vehicle-info-txt">Location: {shopVehicleList?.location?.legalName ? (shopVehicleList.location.legalName) : "--"}</span>
                      <span style={{ float: "right" }} className="vehicle-info-txt">Appr: {shopVehicleList?.appraisedBy ? (shopVehicleList.appraisedBy?.firstName + ' ' + shopVehicleList.appraisedBy?.lastName) : "--"}</span>
                    </Col>
                  </Row> : null}



              </Col>
              {shopVehicleList && qValueScreen !== 'reports' && qValueScreen !== 'retail' && shopVehicleList.stage ?
                <Col sm={2}>
                  {/* <EmployeeActionPermission
                        permissionList={user.aggregatedDefaultPolicies}
                        alias={shopVehicleList.stage === 'shop' ? emplyeePermissionsAction.VEHICLE_ACQUIRE : emplyeePermissionsAction.VEHICLE_SELL}
                        roles={user.roles}
                    >
                   </EmployeeActionPermission>*/}
                  <Link
                    onClick={() => handleInventoryEdit()}
                    underline="none"
                  //className="vehicleStatueLink"
                  >
                    <div className="box log-info">
                      <div style={{ padding: "10px 7px", color: "#0065ca" }}>
                        <div className="box-state">
                          <div><span>Log:</span></div>
                          <div><span>{logDate ? moment(new Date(logDate)).local().format("MM/DD/YY") : " "}</span></div>
                        </div>
                        <div className="box-state">
                          <div><span>Purch:</span></div>
                          <div><span> {purchaseDate ? moment(new Date(purchaseDate)).local().format("MM/DD/YY") : " "}</span></div>
                        </div>
                        <div className="box-state">
                          <div><span>Sold:</span></div>
                          <div><span>{soldDate ? moment(new Date(soldDate)).local().format("MM/DD/YY") : " "}</span></div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <strong>Edit</strong>
                        </div>
                      </div>
                    </div>
                  </Link>

                </Col> : null}
            </Row>
          </div>
          {liveRetailComp && !isMobile ?
            <div className="rowCenterAlignItemsRetail ">
              <Row className="inventoryEditColRowSection readField ">
                <Col sm={2} style={{ paddingLeft: "5px" }}>
                  <strong style={{ width: "70px" }} className="pageBlueShadedText vehicle-info-txt">Odo </strong>
                  <span className="pageTitleGreyFont greyShadedText vehicle-info-txt">{qValueDetail.mileage}</span>
                </Col>
                <Col sm={2}>
                  <strong style={{ width: "70px" }} className="pageBlueShadedText vehicle-info-txt">Zip </strong>
                  <span className="pageTitleGreyFont greyShadedText vehicle-info-txt">{qValueDetail.zip}</span>
                </Col>
              </Row>
            </div>
            : null}
          <Row>
            {/* <Col sm={3} md={6} xs={12} lg={6}> */}
            <Col className="readField">
              {
                shopVehicleList && qValueScreen !== 'reports' && qValueScreen !== 'retail' && updateQValueSection && shopVehicleList.stage === 'shop' ? <>

                  <EmployeeActionPermission
                    permissionList={user.aggregatedDefaultPolicies}
                    alias={shopVehicleList.status === 'active' ? emplyeePermissionsAction.VEHICLE_TRANSITION_TO_ARCHIVE_APPRAISAL : emplyeePermissionsAction.VEHICLE_TRANSITION_TO_ACTIVE_APPRAISAL}
                    roles={user.roles}
                  >
                    <Link className="vehicleStatueLink" onClick={handleInventoryStatusChange} >
                      {shopVehicleList.status === 'active' ? "Archive Appraisal" : "Move to Active Appraisals"}
                    </Link>
                  </EmployeeActionPermission>
                </> : null
              }
            </Col>
          </Row>
          {liveRetailComp && isMobile ?
            <Row className="readField" style={{ margin: "15px 0px 0px 30px" }}>
              <Col xs={12}>
                <strong style={{ width: "70px", fontSize: "18px" }} className="pageBlueShadedText">Odo </strong>
                <span className="pageTitleGreyFont greyShadedText">{qValueDetail.mileage}</span>
              </Col>
              <Col xs={12}>
                <strong style={{ width: "70px", fontSize: "18px" }} className="pageBlueShadedText">Zip </strong>
                <span className="pageTitleGreyFont greyShadedText">{qValueDetail.zip}</span>
              </Col>
            </Row>
            : null}
          {(shopVehicleList && (shopVehicleList.stage !== null)) ?
            <>
              {(qValueScreen !== 'reports' && qValueScreen !== 'retail') ?
                <AppBar position="static" color="default" className="shopEditWorkBook">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    // variant="scrollable"
                    variant="fullWidth"
                    // scrollButtons="on"
                    //scrollButtons="off"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="disabled force tabs "
                  >
                    <Tab label="QValue" icon={<TrendingUpIcon />} {...a11yProps(0)} />
                    {shopVehicleList?.stage !== 'shop' ?
                      <Tab label="Pricing" icon={<LocalOfferIcon />} {...a11yProps(1)} />
                      : <Tab style={{ display: "none" }} />}
                    <Tab label="Seller" icon={<DriveEtaIcon />} {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                : null}
              <SwipeableViews className="shopInventory"

                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanelView value={value} index={0}>
                  <div className=" rowCenterAlignItems ">
                    {updateQValueSection ?
                      <>
                        {qValueScreen === 'main' ?
                          <>
                            <div className="tabSection qValueMain" style={{ paddingTop: '22px' }}>
                              {
                                updateQValueError ?
                                  <div>
                                    <Row>
                                      <Col>
                                        <p className="genericErrorColor"><span style={{ marginLeft: "0px" }}>{updateQValueError}</span></p>
                                      </Col>
                                    </Row>
                                  </div> : null
                              }
                              <div className="qValReportTxt">
                                <div style={{ display: "flex", float: "left" }}> Book Values  </div>
                                <div style={{ display: "flex", float: "right" }}>
                                  <PrimaryButton
                                    style={{ marginTop: "-5px" }}
                                    className="editMarketBtn"
                                    onClick={() => { setStatusUpdateError(''); setUpdateQValueError(''); setupdateQvalueDialog(true); }}
                                    disabled={!isEmployeePermitted(
                                      user.aggregatedDefaultPolicies,
                                      emplyeePermissionsAction.VEHICLE_UPDATE_VALUES,
                                      user.roles
                                    )}
                                    // onClick={() => handleCompanySubmit()}
                                    text="Update Values"
                                  // type="submit"
                                  />
                                </div>

                              </div>
                              <div style={{ marginTop: "30px" }}>
                                <hr />
                              </div>

                              <Row className="readOnlyFieldRowHead mainQValuPageSec " style={{ margin: '13px 0px -7px -5px' }}>
                                <Col md={3} className=" mobileDisplayNone" >
                                  <Row style={{ maxWidth: "100%", flexWrap: "unset" }} >
                                    <Col >
                                      <span style={{ fontWeight: 700 }}> Odo</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>{qValueDetail.mileage ? numberFormat(qValueDetail.mileage) : null}</span>
                                    </Col>
                                    <Col >
                                      <span style={{ fontWeight: 700 }} >Zip</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>{qValueDetail.zip}</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </Col>

                                  </Row>
                                </Col>
                                <Col md={7} className=" mobileDisplayNone" >
                                  <Row >


                                    <Col >
                                      <PrimaryButton
                                        style={{ width: "200px", marginTop: "-5px" }}
                                        onClick={() => handleLink('https://www.autocheck.com/vehiclehistory/login')}
                                        text="AutoCheck"
                                        type="button"
                                        className="vh-prim"
                                      />
                                    </Col>
                                    <Col >
                                      <PrimaryButton
                                        style={{ width: "200px", marginTop: "-5px" }}
                                        onClick={() => handleLink('https://www.carfaxonline.com/login')}
                                        text="Carfax"
                                        type="button"
                                        className="vh-prim"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={2}>
                                </Col>

                              </Row>

                              <Row className="readOnlyFieldRowHead  mobileDisplyView autoCheckPadBtm" >
                                <Col sm={12} style={{ display: "flex" }}>
                                  <div style={{ width: "30%" }} >
                                    <span style={{ fontWeight: 700 }} > Odo</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>{qValueDetail.mileage ? numberFormat(qValueDetail.mileage) : null}</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </div>
                                  <div style={{ width: "30%", marginLeft: "30px" }}>
                                    <span style={{ fontWeight: 700 }} >Zip</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span>{qValueDetail.zip}</span>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </div>
                                  {/*<div >
                                                <PrimaryButton
                                                  
                                                  onClick={() => { setStatusUpdateError('');setUpdateQValueError(''); setupdateQvalueDialog(true); }}
                                                  className="editMarketBtn"
                                                  disabled={!isEmployeePermitted(
                                                    user.aggregatedDefaultPolicies,
                                                    emplyeePermissionsAction.VEHICLE_UPDATE_VALUES,
                                                    user.roles
                                                  )}
                                                  // onClick={() => handleCompanySubmit()}
                                                  text="Update Values"
                                                // type="submit"
                                                />
                                         </div>*/}
                                </Col>
                              </Row>

                              <Row >
                                <Col sm={12} xs={12} style={{ marginBottom: "-40px" }}>
                                  <div className="tableColWordBreak datatable-filter-mobTable mainReportTab">
                                    <div className=" card mouse-hover">
                                      <DataTable
                                        value={reportlist}
                                        className=" p-datatable-customers p-datatable-hoverable-rows"
                                        onRowClick={(e) => handleReportRowClick(e)}>

                                        <Column field="name" className="firstColWidth" header="" body={nameBodyTemplate} />
                                        <Column field="auction" header="Auction" body={auctionBodyTemplate} className="headerColorLime" style={{ backgroundColor: "#dbfedb" }} />
                                        <Column field="tradeIn" header="Trade In" body={tradeInBodyTemplate} />
                                        <Column field="retail" header="Retail" body={retailBodyTemplate} className="headerColorLime" style={{ backgroundColor: "#dbfedb" }} />
                                        <Column field="action" header="Action" body={actionBodyTemplate} />
                                      </DataTable>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} className="veh-his-sec mobileDisplyView autoFaxMob" style={{ marginBottom: "15px" }}>
                                  {/* <Row>
                                  <p>Vehicle History</p>
                                  </Row> */}
                                  <Row className="btn-tray" justify="center">
                                    <Col sm={4} xs={4} md={6}>
                                      <PrimaryButton
                                        style={{ width: "100%" }}
                                        onClick={() => handleLink('https://www.autocheck.com/vehiclehistory/login')}
                                        text="AutoCheck"
                                        type="button"
                                        className="vh-prim"
                                      />
                                    </Col>
                                    <Col sm={4} xs={4} md={6}>
                                      <PrimaryButton
                                        style={{ width: "100%" }}
                                        onClick={() => handleLink('https://www.carfaxonline.com/login')}
                                        text="Carfax"
                                        type="button"
                                        className="vh-prim"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <div className="qValReportTxt" style={{ marginTop: "10px" }}>
                                <div style={{ display: "flex", float: "left" }}> Live Retail Market  </div>
                                <div style={{ display: "flex", float: "right" }}>
                                  <PrimaryButton
                                    style={{ marginTop: "-5px" }}
                                    // onClick={() => nextStep(1)} VEHICLE_UPDATE_LIVE_RETAIL
                                    disabled={liveRetailBtnDisable}
                                    className="editMarketBtn"
                                    // onClick={() => handleCompanySubmit()}
                                    onClick={() => updateRetailListing()}
                                    text="Edit Market"
                                  // type="submit"
                                  />
                                </div>

                              </div>
                              <div style={{ marginTop: "40px" }}>
                                <hr />
                              </div>

                              <Row className=" qvalChartDisplay editShopRowSection mainQValuPageSec">
                                <Col sm={12} md={7} style={{ paddingLeft: "30px" }} className="qvalMobDispalyView qvalChartDisplay livSection">
                                  <h4 className="qValReportTxt"> </h4>
                                  {/* <Row className="readOnlyFieldRowHead">
                                    <Col sm={8}>
                                      <span style={{fontWeight:700}}>Trim</span>
                                      <span>{liveReports ? liveReports.style : ''}</span>
                                    </Col>
                                  </Row> */}
                                  <Row className="readOnlyFieldRowHead">
                                    <Col sm={8} xs={12}>
                                      <span style={{ fontWeight: 700 }}>Within</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span className="paddingSpace">{liveReports ? liveReports.miles : ''}</span>
                                      <span style={{ fontWeight: 700 }}>miles of</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span className="paddingSpace">{liveReports ? liveReports.zip : ''}</span>
                                    </Col>
                                    <Col sm={4} xs={12} className="liveRetailBtnCol">

                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col sm={5} xs={5} style={{ marginBottom: "10px" }}>
                                      <span style={{ fontWeight: 700 }}>Vehicles</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>{vehicleCount ? vehicleCount : '0'}</span>
                                    </Col>
                                    {/*} <Col sm={7} xs={8} className="mobileDisplayNone">
                                      <ChoiceGroup id="collateral" name="collateral"
                                        required={true}
                                        className="guidedRadio liveRetailRadio"
                                        options={LiveRetailOptions}
                                        onChange={handleDropdownListingChange}
                                        selectedKey={selectedLiveRetailKey}
                                        label="" />
                                    </Col>
                                    <Col sm={7} xs={7} className="mobileDisplay">
                                      <ChoiceGroup id="collateral" name="collateral"
                                        required={true}
                                        className="guidedRadio liveRetailRadio"
                                        options={LiveRetailOptionsMob}
                                        onChange={handleDropdownListingChange}
                                        selectedKey={selectedLiveRetailKey}
                                        label="" />
                                    </Col>*/}
                                  </Row>

                                  <Row>
                                    <Col>
                                      <div className="datatable-filter-mobTable">
                                        <div className="card" >
                                          <DataTable
                                            value={retailHistorylist}
                                            className="p-datatable-customers " >

                                            <Column field="retailName" header="" body={retailNameTemplate} />
                                            <Column field="low" header="Low" body={lowBodyTemplate} />
                                            <Column field="average" header="Average" body={averageBodyTemplate} />
                                            <Column field="high" header="High" body={highBodyTemplate} />
                                          </DataTable>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>


                                </Col>

                                <Col sm={12} md={4} className=" qvalMobDispalyView qvalChartDisplay sb-ovrd">
                                  <h4 className="qValReportTxt">Active Listings by Series - {filterLiveReports?.listings?.active_statistics?.vehicle_count ? filterLiveReports?.listings?.active_statistics.vehicle_count : '-'}</h4>
                                  <Row className="readOnlyFieldRowHead">
                                    <Col sm={6} xs={6}>
                                      <span style={{ fontWeight: 700 }}  >Days Supply</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>{liveReports ? liveReports.stats?.allTrims?.market_days_supply : ''}</span>
                                    </Col>
                                    <Col sm={6} xs={6}>
                                      <span style={{ fontWeight: 700 }} >Days to Turn</span>
                                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span>{liveReports ? liveReports.stats?.allTrims?.mean_days_to_turn : ''}</span>
                                    </Col>
                                  </Row>
                                  <Row justify="center">
                                    {liveReports?.listings.length ?

                                      <Chart type="doughnut" data={pieChartData} options={lightOptions} className="p-chartWidth" />
                                      : <p>No records found </p>}
                                  </Row>
                                </Col>
                              </Row>
                              <div className="qValReportTxt calciTitle">
                                Qvalue Calculator <hr />
                              </div>
                              <Row className="editShopRowSection mainQValuPageSec marginAddRight ">
                                <Col sm={12} md={12} xs={12} >
                                  {/* <Row justify="center">
                                    <Col style={{textAlign:"center"}}>
                                      <h4 className="qValReportTxt calciTitle"> QValue Calculator</h4>
                                    </Col>
                                  </Row>*/}

                                  <Row className={`${lineStylesData || shopVehicleList?.stage === 'shop' ? "qvalMobDispalyView editShopRowSection mainQValuPageSec qValueCalSection" : "editShopRowSection mainQValuPageSec qValueCalSectionChart"}`}>
                                    <Col xs={12} sm={12} md={6} >
                                      {shopVehicleList?.stage === 'shop' ?
                                        <Row className="qvalMobDispalyMarginView calRowSection askingPrice">
                                          <Col sm={6}>
                                            <label className="lbl-text">Seller's Asking</label>
                                            <CurrencyInput
                                              allowNegativeValue={false}
                                              customInput={OffTextField}
                                              // thousandSeparator={true}
                                              prefix={PRICE_PREFIX}
                                              className="currency-input"
                                              id="sellerAsking"
                                              name="sellerAsking"
                                              //label="Seller's Asking"
                                              decimalScale={0}
                                              value={retailMarketFormik.values.sellerAsking?retailMarketFormik.values.sellerAsking.replace(/,/g, ''):''}
                                              //onChange={retailMarketFormik.handleChange}
                                              onValueChange={(e) => handleQvaluePriceChange(e, 'sellerAsking')}
                                              onBlur={retailMarketFormik.handleBlur}
                                              disabled={!isEmployeePermitted(
                                                user.aggregatedDefaultPolicies,
                                                emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                user.roles
                                              )}
                                            />
                                            <span className="error-message">
                                              {retailMarketFormik.touched.sellerAsking &&
                                                retailMarketFormik.errors.sellerAsking &&
                                                retailMarketFormik.errors.sellerAsking.toString()}
                                            </span>
                                          </Col>
                                          <Col sm={6}>
                                            <label className="lbl-text">Purchase Price</label>
                                            <CurrencyInput
                                              allowNegativeValue={false}
                                              customInput={OffTextField}
                                              // thousandSeparator={true}
                                              prefix={PRICE_PREFIX}
                                              className="currency-input"
                                              id="myOffer"
                                              name="myOffer"
                                              //label="Purchase Price"
                                              decimalScale={0}
                                              value={retailMarketFormik.values.myOffer?retailMarketFormik.values.myOffer.replace(/,/g, ''):''}
                                              //onChange={retailMarketFormik.handleChange}
                                              onValueChange={(e) => handleQvaluePriceChange(e, 'myOffer')}
                                              onBlur={retailMarketFormik.handleBlur}
                                              disabled={!isEmployeePermitted(
                                                user.aggregatedDefaultPolicies,
                                                emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                user.roles
                                              )}
                                            />
                                            <span className="error-message">
                                              {retailMarketFormik.touched.myOffer &&
                                                retailMarketFormik.errors.myOffer &&
                                                retailMarketFormik.errors.myOffer.toString()}
                                            </span>
                                          </Col>
                                        </Row>
                                        :
                                        <Row className="qvalMobDispalyMarginViewChart">
                                          <Col>
                                            <div>
                                              <span style={{ display: "block", fontSize: "17px", marginBottom: 5, paddingLeft: 15 }} className="boldTxt">Current List Price</span>
                                            </div>
                                            <div style={{ color: "grey", fontSize: "17px", marginBottom: 15, paddingLeft: 15 }}>
                                              ${shopVehicleList?.initialListPrice} set on {moment((shopVehicleList?.listPriceHistory && shopVehicleList.listPriceHistory.length) ? shopVehicleList.listPriceHistory[0]['date'] : new Date()).format("MM/DD/YYYY")}
                                            </div>
                                          </Col>
                                        </Row>

                                      }

                                      {shopVehicleList?.stage === 'shop' ?
                                        <Row className={"qvalMobDispalyMarginViewChart calRowSection lstPriceBox " + selectedCalcTab} onClick={() => calciTabChange('profit')}>
                                          <Col sm={6} xs={6} className="priceBox">
                                            <div className="calciImgBox">
                                              {selectedCalcTab === 'list' ?
                                                <img
                                                  alt="listCalci"
                                                  key="1"
                                                  // style={thumbNailStyle}
                                                  src={`${require("assets/calciQval.png")}`}
                                                />
                                                :
                                                <img
                                                  alt="profitCalci"
                                                  key="1"
                                                  // style={thumbNailStyle}
                                                  src={`${require("assets/calciEdit.png")}`}
                                                />
                                              }
                                              <span className="headerTitle boldTxt">List Price</span>
                                            </div>
                                          </Col>
                                          <Col sm={6} xs={6}>
                                            {selectedCalcTab === 'list' ?
                                              <span className="headerValue boldTxt">{currencyFormat(retailMarketFormik.values.myListPriceTarget)}</span>
                                              :
                                              <>
                                                <CurrencyInput
                                                  style={{ width: "220px" }}
                                                  allowNegativeValue={false}
                                                  customInput={OffTextField}
                                                  // thousandSeparator={true}
                                                  prefix={PRICE_PREFIX}
                                                  className="currency-input"
                                                  id="targetListPrice"
                                                  name="targetListPrice"
                                                  //label=""  // Target List Price
                                                  decimalScale={0}
                                                  value={retailMarketFormik.values.targetListPrice?retailMarketFormik.values.targetListPrice.replace(/,/g, ''):''}
                                                  //onChange={retailMarketFormik.handleChange}
                                                  onValueChange={(e) => handleQvaluePriceChange(e, 'targetListPrice')}
                                                  onBlur={retailMarketFormik.handleBlur}
                                                  disabled={!isEmployeePermitted(
                                                    user.aggregatedDefaultPolicies,
                                                    emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                    user.roles
                                                  )}
                                                />
                                                <span className="error-message">
                                                  {retailMarketFormik.touched.targetListPrice &&
                                                    retailMarketFormik.errors.targetListPrice &&
                                                    retailMarketFormik.errors.targetListPrice.toString()}
                                                </span>
                                              </>


                                            } {/* targetListPrice */}
                                          </Col>
                                        </Row>
                                        :
                                        <>
                                          <Row className={"qvalMobDispalyMarginViewChart calRowSection lstPriceBox " + selectedCalcTab}>
                                            <Col className="priceBox">
                                              <Row onClick={() => calciTabChange('profit')}>
                                                <Col sm={6} xs={6} className="priceBoxSec">
                                                  <div className="calciImgBox">
                                                    {selectedCalcTab === 'list' ?
                                                      <img
                                                        alt="listCalci"
                                                        key="1"
                                                        // style={thumbNailStyle}
                                                        src={`${require("assets/calciQval.png")}`}
                                                      />
                                                      :
                                                      <img
                                                        alt="profitCalci"
                                                        key="1"
                                                        // style={thumbNailStyle}
                                                        src={`${require("assets/calciEdit.png")}`}
                                                      />
                                                    }
                                                    <span className="headerTitle boldTxt">Price Scenario</span>
                                                  </div>
                                                </Col>
                                                <Col sm={6} xs={6}>
                                                  {selectedCalcTab === 'list' ?
                                                    <span className="headerValue boldTxt">{currencyFormat(retailMarketFormik.values.myListPriceTarget)}</span>
                                                    :
                                                    <>
                                                      <CurrencyInput
                                                        style={{ width: "220px" }}
                                                        allowNegativeValue={false}
                                                        customInput={OffTextField}
                                                        // thousandSeparator={true}
                                                        prefix={PRICE_PREFIX}
                                                        className="currency-input"
                                                        id="targetListPrice"
                                                        name="targetListPrice"
                                                        //label=""  // Target List Price
                                                        decimalScale={0}
                                                        value={retailMarketFormik.values.targetListPrice?retailMarketFormik.values.targetListPrice.replace(/,/g, ''):''}
                                                        //onChange={retailMarketFormik.handleChange}
                                                        onValueChange={(e) => handleQvaluePriceChange(e, 'targetListPrice')}
                                                        onBlur={retailMarketFormik.handleBlur}
                                                        disabled={!isEmployeePermitted(
                                                          user.aggregatedDefaultPolicies,
                                                          emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                          user.roles
                                                        )}
                                                      />
                                                      <span className="error-message">
                                                        {retailMarketFormik.touched.targetListPrice &&
                                                          retailMarketFormik.errors.targetListPrice &&
                                                          retailMarketFormik.errors.targetListPrice.toString()}
                                                      </span>
                                                    </>
                                                  } {/* targetListPrice */}
                                                </Col>
                                              </Row>
                                              <Row justify="center" >
                                                <Col>
                                                  <div className="qValApplyPrice">
                                                    <Link color="inherit">
                                                      <span className="apply-price" onClick={() => openPricingTabUpdatePopup(true)}>Update List Price</span>
                                                    </Link>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </>
                                      }

                                      <Row className="qvalMobDispalyMarginProfit calRowSection">
                                        <Col sm={6} xs={6} className="rankCol">
                                          <span style={{ fontWeight: 700 }}>Price Rank</span>
                                          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                          <span className="boldTxtVal">{priceRank}</span>
                                        </Col>

                                        <Col sm={6} xs={6} className="rankCol">
                                          <span style={{ fontWeight: 700 }} >% of Avg Price</span>
                                          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                          <span className="boldTxtVal">{avgPricePercent}</span>
                                        </Col>
                                      </Row>

                                      <hr className="qvalMobDispalyMarginLine" style={{ marginBottom: "0px", marginTop: "0px" }} />

                                      {shopVehicleList?.stage === 'shop' ?
                                        <>
                                          <Row className="qvalMobDispalyMarginView calRowSection">
                                            <Col sm={2} xs={0}></Col>
                                            <Col sm={4} xs={5} className="pad-iventoryFont" > Purchase Price</Col>
                                            <Col sm={6} xs={5}>{currencyFormat(retailMarketFormik.values.myOffer)}</Col>

                                          </Row>

                                          <Row className="qvalMobDispalyMarginViewChart calRowSection  calciExpenseRow" style={{ padding: "8px" }}>
                                            <Col sm={2} xs={0}></Col>
                                            <Col sm={4} xs={5} className="pad-iventoryFont " >Expenses</Col>
                                            <Col sm={6} xs={5}>{currencyFormat(retailMarketFormik.values.totalExpenseList)}</Col>
                                          </Row>

                                          <Row className="qvalMobDispalyMargin-Border calRowSection subExpenseRow">
                                            <Col sm={2} xs={0}></Col>
                                            <Col sm={4} className="pad-iventoryFont pad-inventoryPage colValue" xs={5}>Reconditioning</Col>
                                            <Col sm={6} className="colValue" xs={5}>
                                              <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                className="currency-input"
                                                id="reconditioning"
                                                name="reconditioning"
                                                //label=""  //Reconditioning
                                                decimalScale={0}
                                                value={retailMarketFormik.values.reconditioning?retailMarketFormik.values.reconditioning.replace(/,/g, ''):''}
                                                //onChange={retailMarketFormik.handleChange}
                                                onValueChange={(e) => handleQvaluePriceChange(e, 'reconditioning')}
                                                onBlur={retailMarketFormik.handleBlur}

                                                disabled={!isEmployeePermitted(
                                                  user.aggregatedDefaultPolicies,
                                                  emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                  user.roles
                                                )}
                                              />
                                              <span className="error-message">
                                                {retailMarketFormik.touched.reconditioning &&
                                                  retailMarketFormik.errors.reconditioning &&
                                                  retailMarketFormik.errors.reconditioning.toString()}
                                              </span>
                                            </Col>
                                          </Row>

                                          <Row className="qvalMobDispalyMargin-Border calRowSection subExpenseRow">
                                            <Col sm={2} xs={0}></Col>
                                            <Col sm={4} xs={5} className="pad-iventoryFont pad-iventoryFont pad-inventoryPage colValue">Transportation</Col>
                                            <Col sm={6} xs={5} className="colValue">
                                              <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                className="currency-input"
                                                id="transportation"
                                                name="transportation"
                                                //label=""  //Transportation
                                                decimalScale={0}
                                                value={retailMarketFormik.values.transportation?retailMarketFormik.values.transportation.replace(/,/g, ''):''}
                                                //onChange={retailMarketFormik.handleChange}
                                                onValueChange={(e) => handleQvaluePriceChange(e, 'transportation')}
                                                onBlur={retailMarketFormik.handleBlur}
                                                disabled={!isEmployeePermitted(
                                                  user.aggregatedDefaultPolicies,
                                                  emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                  user.roles
                                                )}
                                              />
                                              <span className="error-message">
                                                {retailMarketFormik.touched.transportation &&
                                                  retailMarketFormik.errors.transportation &&
                                                  retailMarketFormik.errors.transportation.toString()}
                                              </span>
                                            </Col>
                                          </Row>

                                          <Row className="qvalMobDispalyMargin-Border calRowSection subExpenseRow">
                                            <Col sm={2} xs={0}></Col>
                                            <Col sm={4} xs={5} className="pad-iventoryFont pad-inventoryPage colValue">Other</Col>
                                            <Col sm={6} xs={5} className="colValue">
                                              <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                className="currency-input"
                                                id="otherExpense"
                                                name="otherExpense"
                                                //label="" //Other Est. Expense
                                                decimalScale={0}
                                                value={retailMarketFormik.values.otherExpense?retailMarketFormik.values.otherExpense.replace(/,/g, ''):''}
                                                //onChange={retailMarketFormik.handleChange}
                                                onValueChange={(e) => handleQvaluePriceChange(e, 'otherExpense')}
                                                onBlur={retailMarketFormik.handleBlur}
                                                disabled={!isEmployeePermitted(
                                                  user.aggregatedDefaultPolicies,
                                                  emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                  user.roles
                                                )}
                                              />
                                              <span className="error-message">
                                                {retailMarketFormik.touched.otherExpense &&
                                                  retailMarketFormik.errors.otherExpense &&
                                                  retailMarketFormik.errors.otherExpense.toString()}
                                              </span>

                                            </Col>
                                          </Row>
                                        </>

                                        :

                                        <Row className="qvalMobDispalyMarginViewChart calRowSection calciExpenseRow">
                                          <Col sm={6} xs={6}>
                                            <div className="calciImgBox">
                                              {selectedCalcTab === 'profit' ?
                                                <img
                                                  alt="listCalci"
                                                  key="1"
                                                  style={{ visibility: 'hidden' }}
                                                  // style={thumbNailStyle}
                                                  src={`${require("assets/calciQval.png")}`}
                                                />
                                                :
                                                <img
                                                  alt="profitCalci"
                                                  key="1"
                                                  style={{ visibility: 'hidden' }}
                                                  // style={thumbNailStyle}
                                                  src={`${require("assets/calciEdit.png")}`}
                                                />
                                              }
                                              <span className="headerTitle boldTxt">Total Cost</span>
                                            </div>
                                          </Col>
                                          <Col sm={6} xs={6}><span className="boldTxt totalCostRow"> {totalCostCal}</span></Col>
                                        </Row>
                                      }

                                      <hr className="qvalMobDispalyMarginLine" />

                                      <Row className={"qvalMobDispalyMarginViewChart calRowSection profitBox " + selectedCalcTab} onClick={() => calciTabChange('list')}>
                                        <Col sm={6} xs={6}>
                                          <div className="calciImgBox">
                                            {selectedCalcTab === 'profit' ?
                                              <img
                                                alt="listCalci"
                                                key="1"
                                                // style={thumbNailStyle}
                                                src={`${require("assets/calciQval.png")}`}
                                              />
                                              :
                                              <img
                                                alt="profitCalci"
                                                key="1"
                                                // style={thumbNailStyle}
                                                src={`${require("assets/calciEdit.png")}`}
                                              />
                                            }
                                            <span className="headerTitle boldTxt">Profit</span>
                                          </div>
                                        </Col>

                                        <Col sm={6} xs={6}>

                                          {selectedCalcTab === 'profit' ?
                                            <span className="headerValue boldTxt">{currencyFormat(retailMarketFormik.values.ProfitList)}</span>
                                            :
                                            <>
                                              <CurrencyInput
                                                style={{ width: "220px" }}
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                //thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                className="currency-input"
                                                id="profitTarget"
                                                name="profitTarget"
                                                //label=""
                                                decimalScale={0}
                                                value={retailMarketFormik.values.profitTarget?retailMarketFormik.values.profitTarget.replace(/,/g, ''):''}
                                                onValueChange={(e) => {
                                                  retailMarketFormik.setValues(
                                                    {
                                                      ...retailMarketFormik.values,
                                                      "profitTarget": e
                                                    }
                                                  );
                                                }}
                                                onBlur={retailMarketFormik.handleBlur}
                                                disabled={!isEmployeePermitted(
                                                  user.aggregatedDefaultPolicies,
                                                  emplyeePermissionsAction.VEHICLE_UPDATE_CALCULATOR,
                                                  user.roles
                                                )}
                                              />
                                              <span className="error-message">
                                                {retailMarketFormik.touched.profitTarget &&
                                                  retailMarketFormik.errors.profitTarget &&
                                                  retailMarketFormik.errors.profitTarget.toString()}
                                              </span>
                                            </>
                                          }
                                        </Col>
                                      </Row>


                                    </Col>

                                    <Col xs={12} sm={12} md={6} >
                                      <div className="calciChart">
                                        <h4 className="qValReportTxt listPricePadTop">List Price</h4>
                                        <div className="containerChart">
                                          {basicData ?
                                            <ResponsiveContainer >
                                              <LineChart
                                                data={priceChartNew}
                                                margin={{
                                                  top: 20,
                                                  right: 50,
                                                  left: 20,
                                                  bottom: 5
                                                }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                {/* allowDuplicatedCategory={false} allowDataOverflow={true} */}
                                                <XAxis dataKey="xAxisLabel" tick={CustomizedAxisTick} height={60} name="xAxisLabel" hide={false} tickSize={16} minTickGap={2} />
                                                {/* <ReferenceLine label="Segment" stroke="green" strokeDasharray="3 3" segment={[{ x: '9.4K', y: 0 }, { x: '10.4K', y: 0.00048257781438211121063236452855 }]} /> */}
                                                {/* <XAxis dataKey="xAxisRaw2" hide={false} allowDataOverflow={true} tickSize={16} minTickGap={2}/> */}
                                                <ReferenceLine x={highlightListPrice} stroke="red" label="" />
                                                <Line type="monotone" dataKey="yAxis" strokeWidth={3} stroke='#279672' dot={{ stroke: '#279672', strokeWidth: 6 }} >
                                                  {/* <LabelList dataKey="xAxis" content={CustomizedLabel} /> */}
                                                </Line>
                                                {/* <Line type="monotone" dataKey="xAxisRaw2" strokeWidth={3} stroke='#279672' dot={{ stroke: '#279672', strokeWidth: 6 }}>
                                                  <LabelList content={CustomizedLabel} />
                                                </Line> */}
                                                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                                                <Tooltip content={CustomTooltipPrice} itemStyle={tooltipStyle} labelStyle={tooltipStyleLabel} />
                                                <Tooltip />
                                                <Legend />
                                              </LineChart>
                                            </ResponsiveContainer>
                                            : <p>No records found </p>}
                                        </div>
                                      </div>
                                      <div className="calciChart">
                                        <h4 className="qValReportTxt">Mileage</h4>
                                        <div className="containerChart">
                                          {lineStylesData ?
                                            <ResponsiveContainer  >
                                              <LineChart
                                                data={mileageChartNew}
                                                margin={{
                                                  top: 20,
                                                  right: 50,
                                                  left: 20,
                                                  bottom: 5
                                                }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="xAxisLabel" tick={CustomizedAxisTick} height={60} allowDataOverflow={true} tickSize={13} minTickGap={12} />
                                                <ReferenceLine x={highlightMileage} stroke="red" label="" />
                                                <Line type="monotone" dataKey="yAxis" stroke='#ffa726' dot={{ stroke: '#ffa726', strokeWidth: 6 }} />
                                                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                                                <Tooltip content={CustomTooltipMileage} itemStyle={tooltipStyle} labelStyle={tooltipStyleLabel} />
                                                <Tooltip />
                                                <Legend />
                                              </LineChart>
                                            </ResponsiveContainer>
                                            : <p>No records found </p>}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12} style={{ textAlign: "center", marginTop: "-60px" }}>
                                  <PrimaryButton
                                    text="Save"
                                    onClick={() => handleVehicleExpensesSubmit()}
                                    type="button"
                                  />
                                </Col>
                              </Row>

                              <Row className="editShopRowSection mainQValuPageSec marginAddRight ">
                                <Col sm={12} md={12} xs={12} style={{ padding: 0 }}>
                                  <Accordion className="inventory-filter-accordion notes-accordion" >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1c-content"
                                      id="panel1c-header"
                                    >
                                      <div style={themeStyles.column} >
                                        <Typography component={'h3'} className="accordianHeaderTxt">Notes</Typography>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={themeStyles.details}>
                                      <Row className="editShopRowSection mainQValuPageSec">
                                        <Col className="notesSection">
                                          <OffTextField
                                            id="notes"
                                            name="notes"
                                            label=""
                                            multiline
                                            resizable={false}
                                            className="full-width"
                                            value={qValNotes}
                                            style={{ fontSize: "0.8rem", fontWeight: 400 }}
                                            onChange={(e) => setNotesValue(e)}
                                          />
                                        </Col>
                                      </Row>
                                      {
                                        qvaluError ?
                                          <div className="">
                                            <Row>
                                              <Col>
                                                <span className="genericErrorColor"><span>Please enter details</span></span>
                                              </Col>
                                            </Row>
                                          </div> : null
                                      }
                                      <Row style={{ margin: "5px 0px" }}>
                                        <Col className="text-right" style={{ padding: 0 }}>
                                          <PrimaryButton onClick={() => saveNotesDetails()} >Add Note</PrimaryButton>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <strong className="pad-iventoryFont " >History </strong>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <hr />
                                          {notesList.map((item) => (
                                            <div key={"boxes-" + item.id}>
                                              <Row className="rowSpace" style={{ padding: "0 20px" }}>
                                                <Col md={3} xs={4}>
                                                  <div>

                                                    <span>{moment(new Date(item.createdAt)).format("MM/DD/YYYY").toString()}</span>
                                                  </div>
                                                  <div>
                                                    <span>{user.firstName} {user.lastName}</span></div>
                                                </Col>
                                                <Col md={9} xs={8}>
                                                  <span> {item.description} </span>
                                                </Col>
                                              </Row>
                                              <hr />
                                            </div>
                                          ))}
                                        </Col>
                                      </Row>
                                    </AccordionDetails>
                                  </Accordion>
                                </Col>
                              </Row>
                            </div>

                            <div className="tableReport workBookReport">
                              <div className="card reportsTableVal">

                                {(reportlist[0].date && (reportlist[0].date !== 'NA'))
                                  ?
                                  <div className="card reportsTableVal">
                                    <div>
                                      <img alt="BB Report" height="23.5" width="80" style={{ float: "left" }} src={`${require("assets/PB_bb.png")}`} />
                                    </div>
                                    <div style={{ fontSize: 10, height: 30 }}>
                                      &copy;{new Date().getFullYear()} Hearst Business Media Corp. ALL RIGHTS RESERVED. Black Book&reg; is a registered trademark of Hearst Business Media Corp.
                                    </div>
                                  </div>
                                  : null}

                                {(reportlist[2].date && (reportlist[2].date !== 'NA'))
                                  ?
                                  <div className="bottomTextCopyRight">
                                    &copy;{(new Date().getFullYear())} Kelley Blue Book Co., Inc. All rights reserved. As of {kbbAttributionDetails?.date ? moment(kbbAttributionDetails?.date).format("MM/DD/YYYY") : null}  Edition for {updateQValueformik?.values?.zip}  . Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary. The value and pricing information displayed for a particular vehicle is based upon the specification, mileage and/or condition information provided by the person generating this report. Kelley Blue Book assumes no responsibility for errors or omissions.
                                  </div>
                                  : null
                                }
                              </div>
                            </div>

                          </>
                          :
                          <>
                            {(reportsType?.toLowerCase().includes('power')) ?
                              <ShopInventoryReportsJD reportsData={reportsJD}
                                reportsVehInfo={reportsVehInfoJD} adjustedData={updateAdjustedData} />
                              : (reportsType?.toLowerCase().includes('kelley')) ?
                                <ShopInventoryReportsKBB reportsData={reportsKBB}
                                  reportsVehInfo={reportsVehInfoKBB} adjustedData={updateAdjustedData} />
                                : (reportsType?.toLowerCase().includes('retail')) ?
                                  <ShopInventoryRetailReports reportsData={liveReports} filterReportsData={filterLiveReports} refreshData={handleRefreshData} onSubmitLive={(data) => setliveReports(data)}
                                    reportsVehInfo={qValueDetail} showLiveRetail={showLiveRetail} compVehicle={(bool) => setliveRetailComp(bool)} />
                                  : (reportsType?.toLowerCase().includes('black book')) ? <ShopInventoryReportsBB reportsData={reportsBB}
                                    reportsVehInfo={reportsVehInfoBB} adjustedData={updateAdjustedData} /> : null
                            }
                          </>

                        }
                      </>
                      : updateQValueSection === false ?
                        <div>
                          <CustomFontText>Order Values</CustomFontText>
                          <div className="qvalues-section">
                            {
                              updateQValueError ?
                                <div>
                                  <Row>
                                    <Col>
                                      <p className="genericErrorColor"><span>{updateQValueError}</span></p>
                                    </Col>
                                  </Row>
                                </div> : null
                            }
                            <Row className="inventoryRowSection autoWidthInput rowPaddingBtwSpace" >
                              <Col className="inventoryColDivider" style={{ paddingRight: "0px" }} sm={3} md={3} xs={3}>
                                <label className="lbl-text">Odo</label>
                                <CurrencyInput
                                  customInput={OffTextField}
                                  //thousandSeparator={true}
                                  id="miles"
                                  name="miles"
                                  //label="Odo"
                                  decimalScale={0}
                                  value={updateQValueformik.values.miles?updateQValueformik.values.miles.replace(/,/g, ''):''}
                                  onValueChange={(e) => {
                                    updateQValueformik.setValues(
                                      {
                                        ...updateQValueformik.values,
                                        "miles": e
                                      }
                                    );
                                  }}
                                  onBlur={updateQValueformik.handleBlur}

                                />
                                <span className="error-message">
                                  {updateQValueformik.touched.miles &&
                                    updateQValueformik.errors.miles &&
                                    updateQValueformik.errors.miles.toString()}
                                </span>
                                {/* <CurrencyInput
                                   id="miles"
                                   name="miles"
                                   label='Odo'
                                  // thousandSeparator={true}
                                   prefix="$"
                                   decimalsLimit={0} // Set the number of decimal places
                                   allowDecimals={false}
                                   value={updateQValueformik.values.miles}
                                   onValueChange={(value) => {
                                       updateQValueformik.setFieldValue('miles', value);
                                   }}
                                   onBlur={updateQValueformik.handleBlur}
                                   errormessage={
                                    updateQValueformik.touched.miles &&
                                    updateQValueformik.errors.miles &&
                                    updateQValueformik.errors.miles.toString()
                                }
                                /> */}

                              </Col>
                              <Col className=" vehicleDropdownWidth" sm={6} md={6} xs={6} >
                                <Dropdown
                                  id="defaultVehicleCondition"
                                  label="Vehicle Condition"
                                  required={true}
                                  className="vehicleDropdownWidth vehicleCondition"
                                  selectedKey={updateQValueformik.values.defaultVehicleCondition}
                                  //required={true}
                                  onChange={handleVehicleConditionChange}
                                  onRenderOption={onRenderOptionForVehicleCondition}
                                  options={inventoryDropdownsValues.VehicleCondition}
                                  styles={dropdownStyles}
                                />
                              </Col>
                              <Col className="inventoryColDivider" style={{ paddingLeft: "0px" }} sm={3} md={3} xs={3}>
                                <OffTextField
                                  id="zip"
                                  name="zip"
                                  label="Zip"
                                  // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                                  value={updateQValueformik.values.zip}
                                  required={true}
                                  onChange={(e) => handleZipCodeChangeForDealer(e)}
                                  onBlur={updateQValueformik.handleBlur}
                                  errorMessage={
                                    (updateQValueformik.touched.zip && updateQValueformik.errors.zip) ?
                                      updateQValueformik.errors.zip.toString()
                                      : qvalueZipApiMsg
                                  }
                                // style={{ width: "calc(100% - 130px)" }}
                                />
                              </Col>

                            </Row>

                            <Row className="inventoryRowSection rowPaddingBtwSpace" >
                              <Col className="inventoryColDivider" sm={12} md={12} xs={12}>
                                <Dropdown
                                  id="blackbook"
                                  label="Black Book Style"
                                  selectedKey={updateQValueformik.values.blackbook}
                                  required={bbStyles && bbStyles.length > 0}
                                  onChange={handleQValueDropdownValue}
                                  placeholder={bbStylePlaceholder}
                                  options={bbStyles}
                                  styles={dropdownStyles}
                                  errorMessage={
                                    updateQValueformik.touched.blackbook &&
                                    updateQValueformik.errors.blackbook &&
                                    updateQValueformik.errors.blackbook.toString()
                                  }
                                />
                              </Col>
                            </Row>

                            <Row className="inventoryRowSection rowPaddingBtwSpace"  >
                              <Col className="inventoryColDivider" sm={12} md={12} xs={12}>
                                <Dropdown
                                  id="nada"
                                  label="J.D. Power Style"
                                  selectedKey={updateQValueformik.values.nada}
                                  required={nadaStyles && nadaStyles.length > 0}
                                  onChange={handleQValueDropdownValue}
                                  placeholder={nadaStylePlaceholder}
                                  options={nadaStyles}
                                  styles={dropdownStyles}
                                  errorMessage={
                                    updateQValueformik.touched.nada &&
                                    updateQValueformik.errors.nada &&
                                    updateQValueformik.errors.nada.toString()
                                  }
                                />
                              </Col>
                            </Row>
                            <Row className="inventoryRowSection rowPaddingBtwSpace" style={{ marginBottom: '30px' }} >
                              <Col className="inventoryColDivider" sm={12} md={12} xs={12}>
                                <Dropdown
                                  id="kbb"
                                  label="Kelley Blue Book Style"
                                  selectedKey={updateQValueformik.values.kbb}
                                  required={kbbStyles && kbbStyles.length > 0}
                                  onChange={handleQValueDropdownValue}
                                  placeholder={kbbStylePlaceholder}
                                  options={kbbStyles}
                                  styles={dropdownStyles}
                                  errorMessage={
                                    updateQValueformik.touched.kbb &&
                                    updateQValueformik.errors.kbb &&
                                    updateQValueformik.errors.kbb.toString()
                                  }
                                />
                              </Col>
                            </Row>

                            <Row className="inventoryRowSection" >
                              <Col className="inventoryColDivider" style={{ textAlign: 'right' }} sm={12} md={12} xs={12}>
                                <PrimaryButton
                                  text="Get Values"
                                  // style={{ float: "right" }}
                                  onClick={() => handleOrderQValue()}
                                  disabled={(!updateQValueformik.isValid || !updateQValueformik.dirty || qvalueZipApiMsg !== '') && isEmployeePermitted(
                                    user.aggregatedDefaultPolicies,
                                    emplyeePermissionsAction.VEHICLE_UPDATE_VALUES,
                                    user.roles
                                  )}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div> : null
                    }
                  </div>
                </TabPanelView>

                <TabPanelView value={value} index={1} >
                  <div className="rowCenterAlignItems" >
                    <Row className={contentStyles.rowContainer + " btn-tray updatePrice"}>
                      <Col  >
                        {/* <label className="lbl-text">Vehicle Ledger</label> */}
                        <h4 className={contentStyles.vehLedger + " qValReportTxt"}>Pricing Ledger</h4>
                      </Col>
                      {shopVehicleList?.stage === 'sold' ?
                        null :
                        <Col className="priceBtnCol" >
                          <PrimaryButton
                            onClick={() => openPricingTabUpdatePopup()}
                            text="Update List Price"
                            type="button"
                            className="vh-prim"
                            style={{ float: "right" }}
                            disabled={!isEmployeePermitted(
                              user.aggregatedDefaultPolicies,
                              emplyeePermissionsAction.VEHICLE_UPDATE_LIST_PRICE,
                              user.roles
                            )}
                          />
                        </Col>
                      }
                    </Row>


                    <div className="rowCenterAlignItems expenseTable">
                      {/*<div className="tabSection expenseTable">*/}

                      <div className="card">
                        {isMobile ? <>
                          <TreeTable value={nodes} onRowClick={(event) => handleRowClick(event)} expandedKeys={expandedKeys}
                            onToggle={e => setExpandedKeys(e.value)} togglerTemplate={togglerTemplate} rowClassName={rowClassName}>
                            <Column body={addEditTemplate} header="" expander style={{ textAlign: 'center', width: '2em' }}></Column>
                            <Column field="name" header="" style={{ width: '5em' }}></Column>
                            <Column field="typeName" style={{ width: '7em' }} ></Column>
                            <Column field="description" body={addExpenseTemplate} header="" ></Column>
                            <Column field="size" header="" style={{ width: '6em' }}></Column>
                          </TreeTable>

                        </> : <>
                          <TreeTable value={nodes} onRowClick={(event) => handleRowClick(event)} expandedKeys={expandedKeys}
                            onToggle={e => setExpandedKeys(e.value)} togglerTemplate={togglerTemplate} rowClassName={rowClassName}>
                            <Column body={addEditTemplate} header="" expander style={{ textAlign: 'center', width: '2em' }}></Column>
                            <Column field="name" header="" style={{ width: '6em' }}></Column>
                            <Column field="typeName" style={{ width: '8em' }} ></Column>
                            <Column field="description" body={addExpenseTemplate} header="" ></Column>
                            <Column field="size" header="" style={{ width: '10em' }}></Column>
                          </TreeTable>
                        </>
                        }
                      </div>
                    </div>

                    <Row >
                      <Col sm={3} xs={6} md={3}>
                        {/* <label className="lbl-text">Price History</label> */}
                        <h4 className="qValReportTxt dateTableHeader">Price History</h4>
                      </Col>
                    </Row>
                    <div className="tableReport dateTable price-history">
                      <div className="card">
                        <DataTable value={currencyFormat(datePricelist)}>
                          <Column field="date" style={{ width: '6em' }} header="Date"></Column>
                          <Column field="price" header="Price"></Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </TabPanelView>

                <TabPanelView value={value} index={2} >
                  <div className=" tabSection sellerTab sellerFontTime">
                    <Row justify="center" className="auctionStartTimeDate ">
                      <Col sm={4} className=" shopInventorySellerTabSection">
                        <div className="tabRowSectionQVal ">
                          <Dropdown
                            id="sourceType"
                            label="Seller Source"
                            selectedKey={sourceVehicleformik.values.sourceType}

                            onChange={handleDropdownValue}
                            // onChange={locationFormik.handleChange}
                            options={masterDropdownValues.sourceTypeList}
                            styles={dropdownStyles}
                            errorMessage={
                              sourceVehicleformik.touched.sourceType &&
                              sourceVehicleformik.errors.sourceType &&
                              sourceVehicleformik.errors.sourceType.toString()
                            }
                          />
                          {
                            sourceTypeVal ?
                              <>
                                <OffTextField
                                  id="name"
                                  name="businessName"
                                  label="Name"
                                  placeholder=""
                                  value={sourceVehicleformik.values.businessName}
                                  onChange={sourceVehicleformik.handleChange}
                                  onBlur={sourceVehicleformik.handleBlur}
                                  errorMessage={
                                    sourceVehicleformik.touched.businessName &&
                                    sourceVehicleformik.errors.businessName &&
                                    sourceVehicleformik.errors.businessName.toString()
                                  }
                                />
                                <div className="ms-TextField">
                                  <div className="ms-TextField-wrapper">
                                    <label className="lbl-text">Phone</label>
                                    <div className={sourceVehicleformik.touched.phone &&
                                      sourceVehicleformik.errors.phone ? "mask-FieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                      <InputMask
                                        autoClear={false}
                                        //required={true}
                                        id="phone"
                                        name="phone"
                                        mask="(999)999-9999"
                                        placeholder=""
                                        value={sourceVehicleformik.values.phone}
                                        className="mask-TextField"
                                        onChange={(e) => handleMaskPhoneInput(e)}
                                        onBlur={sourceVehicleformik.handleBlur}>
                                      </InputMask>
                                    </div>
                                    <span className="error-message">{
                                      sourceVehicleformik.touched.phone &&
                                      sourceVehicleformik.errors.phone &&
                                      sourceVehicleformik.errors.phone.toString()
                                    }</span>
                                  </div>
                                </div>
                                <OffTextField
                                  id="email"
                                  name="email"
                                  label="Email"
                                  placeholder=""
                                  value={sourceVehicleformik.values.email}
                                  onChange={sourceVehicleformik.handleChange}
                                  onBlur={sourceVehicleformik.handleBlur}
                                  errorMessage={
                                    sourceVehicleformik.touched.email &&
                                    sourceVehicleformik.errors.email &&
                                    sourceVehicleformik.errors.email.toString()
                                  }
                                />
                              </> : null
                          }
                        </div>
                      </Col>
                      <Col sm={4} className=" shopInventorySellerTabSection">
                        <div className="tabRowSectionQVal sellerTabColHeight">
                          <OffTextField
                            id="location"
                            name="location"
                            label="Vehicle Location / Shipping From"
                            // iconProps={{ iconName: "Camera" }}
                            // value="LocationTest"
                            value={sourceVehicleformik.values.location}
                            onChange={(e) => handleLocationChange(e)}
                            onBlur={sourceVehicleformik.handleBlur}
                            errorMessage={sourceVehicleformik.touched.location &&
                              sourceVehicleformik.errors.location &&
                              sourceVehicleformik.errors.location.toString()
                            }
                          />
                          {
                            sourceTypeVal ? <>
                              {
                                sourceTypeVal === 'auction' ?
                                  <>
                                    <OffTextField
                                      id="url"
                                      name="url"
                                      label="Auction Website"
                                      placeholder=""
                                      ariaLabel="Example text field with https:// prefix"
                                      value={sourceVehicleformik.values.url}
                                      onChange={sourceVehicleformik.handleChange}
                                      onBlur={sourceVehicleformik.handleBlur}
                                      errorMessage={
                                        sourceVehicleformik.touched.url &&
                                        sourceVehicleformik.errors.url &&
                                        sourceVehicleformik.errors.url.toString()
                                      }
                                    />
                                    <div style={{ width: "auto", display: "flex" }}>
                                      <div className="auctionDate" style={{ width: "50%" }}>
                                        <label style={{ display: "block" }} className="lbl-text">Auction Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                          <Calendar
                                            id="navigators"
                                            value={auctionDate}
                                            style={{ width: "100%" }}
                                            showIcon
                                            onChange={(e) => handleDateChange(e.value)}
                                            monthNavigator
                                            yearNavigator
                                            yearRange={yearRange}
                                          />
                                        </div>
                                      </div>

                                      <div style={{fontSize:'13px'}} className="auctionStartTime SellerFontTimeDate">
                                      <label style={{ display: "block",marginTop:"-10px"}} className="lbl-text">Auction Start Time</label>

                                        <TextField
                                          id="auctionStartTime"
                                          //label="Auction Start Time"
                                          style={{ width: "100%", display: "flex" }}
                                          type="time"
                                          className="auctionTimeInput"
                                          value={sourceVehicleformik.values.auctionStartTime}
                                          onChange={(e) => handleAuctionStartTimeChange(e)}
                                          // defaultValue="07:30"           
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            step: 300, // 5 min
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </> : null
                              }
                              <OffTextField
                                id="note"
                                name="notes"
                                label="Seller Notes"
                                placeholder=""
                                multiline
                                className="sellerNotes"
                                resizable={false}
                                value={sourceVehicleformik.values.notes}
                                onChange={sourceVehicleformik.handleChange}
                                onBlur={sourceVehicleformik.handleBlur}
                                errorMessage={
                                  sourceVehicleformik.touched.notes &&
                                  sourceVehicleformik.errors.notes &&
                                  sourceVehicleformik.errors.notes.toString()
                                }
                              />
                            </> : null
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col sm={8} className="shopInventorySellerTabSection">
                        <PrimaryButton
                          style={{ float: "right" }}
                          className=" SellerFontSave"
                          // onClick={() => nextStep(1)}
                          disabled={!sourceVehicleformik.isValid || !sourceVehicleformik.dirty}
                          onClick={() => handleSourceSubmit()}
                          text="Save"
                          type="submit"
                        // iconProps={{ iconName: "Forward" }}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPanelView>
              </SwipeableViews>
            </>
            : null}
        </div>
      </Box>
    </Stack>
  );
};

const contentStyles = mergeStyleSets({
  rowContainer: {
    justifyContent: "space-between !important"
  },
  vehLedger: {
    marginBottom: "0"
  }
});
