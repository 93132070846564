import * as React from "react";
import { useState } from "react";
import {
  Modal,
  IconButton,
  PrimaryButton,
  Button,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { ViewAdditionalActionsDropdown } from "../../table/dropdowns/ViewAdditionalActionsDropdown";
import {
  contentStyles as content,
  dropdownStyles,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";

import { Dropdown } from "@fluentui/react/lib/Dropdown";
import WithOptionalLabel from "../../customInputs/withOptionalLabel/WithOptionalLabel";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { getModulePermissions } from "../../../utils/permissions/permissionsHelpers";

const contentStyles = content();

interface IProps {
  prospectId?: number;
  onDismiss: () => void;
  formik: any;
  handleEditProspect: () => void;
}

const makers = [
  { value: 0, key: 0, text: "Nissan" },
  { value: 1, key: 1, text: "Honda" },
  { value: 2, key: 2, text: "Toyota" },
  { value: 3, key: 3, text: "Maluch" },
];

const models = [
  { value: 0, key: 0, text: "350Z" },
  { value: 1, key: 1, text: "370Z" },
  { value: 2, key: 2, text: "220Z" },
  { value: 3, key: 3, text: "440P" },
];

const trims = [
  { value: 0, key: 0, text: "DX" },
  { value: 1, key: 1, text: "LX" },
  { value: 2, key: 2, text: "LS" },
  { value: 3, key: 3, text: "EX" },
];

const Mock = {
  make: 0,
  model: 0,
  trim: 0,
};

export const TradeInDialog: React.FunctionComponent<IProps> = ({
  prospectId,
  onDismiss,
}) => {
  const [item, setItem] = useState(Mock);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const handleDropdownChange = (e: any, element: any) => {
    setItem({
      ...item,
      [e.target.id]: element.key,
    });
  };

  return (
    <Modal
      className="configureGroup"
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <GoBackToMainDialogBtn label="" onClick={onDismiss} />
        <CustomFontText>Trade In</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>

      <div className={contentStyles.body}>
        <div style={{ paddingTop: 20 }}>
          <WithOptionalLabel>
            <Dropdown
              id="make"
              label="Make"
              selectedKey={
                // formik.values.salutation
                //     ? formik.values.salutation
                //     : undefined
                item.make
              }
              onChange={handleDropdownChange}
              placeholder="any..."
              options={makers}
              styles={dropdownStyles}
              // onBlur={formik.handleBlur}
              // errorMessage={
              //     formik.touched.salutation && formik.errors.salutation
              //         ? formik.errors.salutation.toString()
              //         : null
              // }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <Dropdown
              id="model"
              label="Model"
              selectedKey={
                // formik.values.salutation
                //     ? formik.values.salutation
                //     : undefined
                item.model
              }
              onChange={handleDropdownChange}
              placeholder="any..."
              options={models}
              styles={dropdownStyles}
              // onBlur={formik.handleBlur}
              // errorMessage={
              //     formik.touched.salutation && formik.errors.salutation
              //         ? formik.errors.salutation.toString()
              //         : null
              // }
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <Dropdown
              id="trim"
              label="Trim"
              selectedKey={
                // formik.values.salutation
                //     ? formik.values.salutation
                //     : undefined
                item.trim
              }
              onChange={handleDropdownChange}
              placeholder="any..."
              options={trims}
              styles={dropdownStyles}
              // onBlur={formik.handleBlur}
              // errorMessage={
              //     formik.touched.salutation && formik.errors.salutation
              //         ? formik.errors.salutation.toString()
              //         : null
              // }
            />
          </WithOptionalLabel>
        </div>
      </div>

      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}
        >
          <div>
            <ViewAdditionalActionsDropdown
              modulePermissions={getModulePermissions(
                user.auths || [],
                "Prospects"
              )}
            />
          </div>
          <div>
            <Button
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />
            <PrimaryButton text="Save Changes" onClick={() => onDismiss()} />
          </div>
        </Stack>
      </div>
    </Modal>
  );
};
