import React, { FunctionComponent,useEffect,useState} from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
 //   DefaultButton,
    IconButton,
    TextField,
    Stack,
    //Dropdown,
} from "@fluentui/react";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
//import { dropdownStyles } from "constants/styles";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
//import inventoryDropdownsValues from "constants/inventoryDropdownsValues";
import { AppState } from "store";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";
import { useDispatch } from "react-redux";
import { handleCreateAppraisalTableItem} from "store/dealTracker/dealTracker.action";
import { 
HANDLE_GET_EMPLOYEES, 
//HANDLE_UPDATE_EMPLOYEE
} from "store/employees/employees.types";

const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    formData?:any,
    dealId: any,
    elementPermission: any
}



export const AddUpdateAppraisalDialog: FunctionComponent<IProps> = ({
    onDismiss,
    formData,
    dealId,
    elementPermission
}) => {

  const dispatch = useDispatch();
  const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
 const employees = useSelector((state: AppState) => state.employees.employees) as any;
 const [employeeList, setemployeeList] = useState([]);

 Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });


 const formik = useFormik({
       initialValues: {
			 //id:'',
	         rollback: '',
             acv: '',
             employeeId:  '',
		},
       validationSchema: Yup.object({
          // id:Yup.string(),
           rollback: Yup.string().required("Required"),
           acv: Yup.string().required("Required"),
           employeeId: Yup.string().required("Required")
       }),  
       onSubmit: (values) => {

           values.rollback = values.rollback.replace('$', '').replace(',', '')
           values.acv = values.acv.replace('$', '').replace(',', '')
           dispatch<any>(
               handleCreateAppraisalTableItem(
                   {
                       dealId,
                       formData: values,
                       callback: (res) => {
                           if (res) {
                               onDismiss('');
                           } else {
                               onDismiss('Failed to Save Data.');
                           }
                       }
                   }));
              }
              });

 useEffect(() => {
    
  
       if(formData && formData.appraisalList){
             formik.setValues(
              {
                ...formik.values,
                'rollback': formData.rollback,
                'acv': formData.acv,
                'employeeId': formData.appraisalList.id,
                //'id':formData.id,
              });
        }
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"appraiser" } })
        //dispatch<any>(handleGetSelectList("appraisalBidders",1));
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (employees) {
            if (employees.results.length > 0) {
                handleFullnameEmployees();
            }
            else {
                setemployeeList([]);
            }
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleFullnameEmployees = () => {
        let results = employees.results;
        for (var i in results) {
            results[i]["fullName"] = results[i].firstName + ' ' + results[i].lastName ;

            } 
        
        setemployeeList(results);
    };


const handleformikDDChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
            ...formik.values,
            [e.target.id]: e.value,
            }
        );
   }

const renderSpinner = () => {
         return loading ? (
           <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
         ) : null;
    };
const handleAddAppraisalSubmit = () => {
        formik.handleSubmit();
    };

    return (
        
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup modal-header appraisalDialog"} >
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle valueHeader">Appraised Value</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " expenseDialogPopup inv-srch-bdy body-auto-hight searchAppraisalBox"} style={{width:"auto"}} >
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    {
                        elementPermission && elementPermission.appraisedBy > 0 ?
                            <Row className="inventoryRowSection" justify="center">
                                <Col className="inventoryColDivider" md={8}>

                                    <label className="lbl-text required-Label">Appraised By</label>
                                    <PRDropdown
                                        id="employeeId"
                                        placeholder=""
                                        value={formik.values.employeeId}
                                        options={employeeList}
                                        onChange={handleformikDDChange}
                                        optionLabel="fullName"
                                        optionValue="id"
                                        appendTo="self"
                                        className="custom-p-dropdown"
                                        filter={true}
                                        resetFilterOnHide={true}
                                        scrollHeight="200px"
                                        disabled={elementPermission && elementPermission.appraisedBy === 1}
                                    />
                                    <span className="error-message">
                                        {
                                            formik.touched && formik.touched.employeeId &&
                                            formik.errors && formik.errors.employeeId &&
                                            formik.errors.employeeId.toString()
                                        }
                                    </span>
                                </Col>
                            </Row> : null
                    }
                    {
                        elementPermission && elementPermission.rollback > 0 ?
                            <Row className="inventoryRowSection" justify="center">
                                <Col className="inventoryColDivider" md={8}>
                                    <label className="lbl-text required-Label">Rollback</label>
                                    <CurrencyInput
                                        customInput={TextField}
                                       // thousandSeparator={true}
                                        prefix={PRICE_PREFIX}
                                        id="rollback"
                                        name="rollback"
                                        //label="Rollback"
                                        decimalScale={2}
                                        disabled={elementPermission && elementPermission.rollback === 1}
                                        value={formik.values.rollback?formik.values.rollback.replace(/,/g, ''):""}
                                        onValueChange ={(e) => {
                                            formik.setValues(
                                                {
                                                    ...formik.values,
                                                    "rollback": e
                                                }
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        //required={true}
                                    />
                                    <span className="error-message">
                                        {
                                            formik.touched && formik.touched.rollback &&
                                            formik.errors && formik.errors.rollback &&
                                            formik.errors.rollback.toString()
                                        }
                                    </span>
                                </Col>
                            </Row>:null
                    }
                    {
                        elementPermission && elementPermission.acv > 0 ?
                            <Row className="inventoryRowSection" justify="center">
                                <Col className="inventoryColDivider" md={8}>
                                    <label className="lbl-text required-Label">ACV</label>
                                    <CurrencyInput
                                        customInput={TextField}
                                       // thousandSeparator={true}
                                        prefix={PRICE_PREFIX}
                                        id="acv"
                                        name="acv"
                                        //label="ACV"
                                        decimalScale={2}
                                        value={formik.values.acv?formik.values.acv.replace(/,/g, ''):""}
                                        onValueChange ={(e) => {
                                            formik.setValues(
                                                {
                                                    ...formik.values,
                                                    "acv": e
                                                }
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                       //required={true}
                                        disabled={elementPermission && elementPermission.acv === 1}
                                    />
                                     <span className="error-message">
                                        {
                                            formik.touched && formik.touched.acv &&
                                            formik.errors && formik.errors.acv &&
                                            formik.errors.acv.toString()
                                        }
                                    </span>
                                </Col>
                            </Row>:null
                    }
                    <Row className="inventoryRowSection footerBtn">
                       
                        <Col className="appraisalbtn-save">
                                    <PrimaryButton
                                        text="Save"
                                        style={{ float: "right"}}
                                        onClick={handleAddAppraisalSubmit}
                                        onRenderIcon={renderSpinner}
                                    />

                        </Col>
                    </Row>
                </Stack>

            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};