import React, { useEffect, useState } from "react";
import { PrimaryButton, Link } from "@fluentui/react";
import {
    TextField as OffTextField,
    Icon,
} from "@fluentui/react";
import { Col, Row } from "react-grid-system";
import { Calendar } from 'primereact/calendar';
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
    handleGetAppointmentsByDeal,
    handleSaveAppointment,
    handleGetAppointmentInfo,
    handleSaveDealsAppointmentsNotesUsingID,
    //handleGetDealsAppointmentsNotesUsingID,
    handleGetDealNotes
} from "store/dealTracker/dealTracker.action";
import masterDropdownValues from "constants/masterDropdownValues";
import { useMediaQuery } from "react-responsive";
import CustomTable from "components/table/datatable/CustomTable";
import AddCircle from '@mui/icons-material/AddCircle';

import {
    convertLocalToUTCModified,
    convertUTCToLocalDateOnlyModified
    //, convertUTCToLocalModified
} from "utils/dateTimeFunctions";
// import { convertLocalToUTC, convertUTCToLocal } from "utils/dateTimeFunctions";
//import TextField from '@material-ui/core/TextField';
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';
import { handleGetEmployeeSaleFinance } from "store/employees/employees.action";

const dealType =["","Write-Up","Trade-in","Appointments"]

interface IProps {
    dealId: string;
    onError: (msg) => void;
    onFormikChange: (initial, newData) => void;
    saveFormOnRoute: boolean;
    dismissSaveNot: (msg) => void;
    userCredentials: any;
    user: any;
    classes: any;
    updateSectionIndex: (id) => void;
    setSectionIndx: any;
    productId:any;
    tabRoute: boolean;
}

export const Appointment: React.FunctionComponent<IProps> = ({
    dealId, onError, onFormikChange,classes, saveFormOnRoute, dismissSaveNot, userCredentials, user, updateSectionIndex, setSectionIndx, productId, tabRoute
}) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const dispatch = useDispatch();
    const [appointmentData, setAppointmentData] = useState([]);
    const [sectionIndex, setSectionIndex] = React.useState(null);
    const [appointmentDate, setAppointmentDate] = React.useState<any>(undefined);
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const [salesManagers, setSalesManagers] = useState([]);
    const [blurFlag, setBlurFlag] = useState(false);
    const [blurFlagTime, setBlurFlagTime] = useState(false);
    const [appointmentTime, setAppointmentTime] = useState(null);
    const [appointmentNotesVal, setAppointmentNotesVal] = React.useState(null);
    const [noteFlag, setNoteFlag] = React.useState(false);
    const [noteError, setNoteError] = React.useState(false);
    const [notesList, setNotesList] = React.useState([]);

    useEffect(() => {
        if(productId && !tabRoute){
           handleGetAppointmentDetail(productId);
           //setSectionIndex(2);
           //updateSectionIndex(2);
         }else{  
            //dispatch<any>(handleGetAppointmentsByDeal({ id: dealId, callback: (res) => handlePopulateAppointments(res) }));
            setSectionIndex(1);
            updateSectionIndex(1);
        }
        handleGetSalesPerson();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleGetSalesPerson =()=>{
        dispatch<any>(handleGetEmployeeSaleFinance({
            isActive: 1, role: "salesManager", callback: (response) => {
                let salesManager = [];
                if (response && response.results) {
                    response.results.map((item: any) => {
                        return salesManager.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                    });
                    setSalesManagers(salesManager);
                }
                else {
                    setSalesManagers([]);
                }
            }
        }));
    }

    const handlePopulateAppointments = (res) => {
              if (res && res.results) {
                  let appointments = res.results;
                  for (var i in appointments) {
                      let deal = appointments[i].deal;
                      let customer = deal.customer;
              
                      appointments[i]["appointDateTime"] = convertUTCToLocalDateOnlyModified(appointments[i].appointmentDate); // + ' ' + extractTime(appointments[i].appointmentTime);
                      appointments[i]["dealNumber"] = deal.dealNumber;
                      appointments[i]["customerName"] = customer.firstName + ' ' + customer.lastName;
                      appointments[i]["customerEmail"] = customer.email;
                      appointments[i]["customerPhone"] = customer.phoneNumber;
                      appointments[i]["confirmedBy"] = appointments[i].employee ? appointments[i].employee.firstName + ' ' + appointments[i].employee.lastName : '';
                      appointments[i]["salesPerson1Name"] = getSalesPerson(deal.salesPerson1);
                      appointments[i]["appointResult"] = appointments[i].appointmentResult ? getResult(appointments[i].appointmentResult) : '';
                  }
                  setAppointmentData(appointments);
              } else {
                  onError('Failed to Fetch the Data.');
              }
    };

    /* const extractTime = (dateTime) => {
        var date = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format('hh:mm A')
    }; */

    const getResult = (id) => {
        let item = masterDropdownValues.appointmentResult.filter(x => x.key === id);
        if (item.length === 1) {
            return item[0].text;
        }
        return '';
    };

    const getSalesPerson = (salesPerson) => {
        if (salesPerson) {
            return salesPerson?.firstName + ' ' + salesPerson?.lastName;
        }
        return '';
    };

    const handleNoteChange = (e, newValue) => {
        setAppointmentNotesVal(newValue);
       // onFormikChange(1, 2);
    }
    const saveNotesDetails = () => {
        if (appointmentNotesVal && appointmentNotesVal.length > 0) {
            setNoteError(false);
            setAppointmentNotesVal(null)
           dispatch<any>(handleSaveDealsAppointmentsNotesUsingID({
               dealId,
               appointmentId:appointmentFormik.values.id,
               details: {
                   description: appointmentNotesVal
               },
               callback: (response) => {
                   if (response) {
                       getAppointmentsNotesDetails();
                       
                   }
                   else
                       onError('Failed to Save the Data.');                   
               }
           }));
           
        } else {
            setNoteError(true);
        }
    }

    const getAppointmentsNotesDetails = () => {
        dispatch<any>(handleGetDealNotes({
            dealId,
            callback: (response) => {
                
                if (response){
                    setNotesList(response);
                }
                      
            }
        }));
    }

    


    useEffect(() => {
        if (saveFormOnRoute) {
            appointmentFormik.handleSubmit();
            if (appointmentNotesVal && appointmentNotesVal.length > 0) {
                setNoteFlag(true);
            }
            if (!appointmentFormik.dirty || !appointmentFormik.isValid) {
                dismissSaveNot('no');
            }
        }
    }, [saveFormOnRoute]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSectionIndex(setSectionIndx);
        if (setSectionIndx === 1)
            dispatch<any>(handleGetAppointmentsByDeal({ id: dealId, callback: (res) => handlePopulateAppointments(res) }));
    }, [setSectionIndx]);// eslint-disable-line react-hooks/exhaustive-deps
   
    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appt Date</span>
                {rowData.appointDateTime}

            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person 1</span>
                {rowData.salesPerson1Name}

            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}

            </React.Fragment>
        );
    };

    const purposeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appointment Purpose</span>
                {rowData.appointmentPurpose}

            </React.Fragment>
        );
    };

    const custNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Name</span>
                {rowData.customerName}

            </React.Fragment>
        );
    };

    const custEmailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Email</span>
                {rowData.customerEmail}

            </React.Fragment>
        );
    };

    const custPhoneBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Phone</span>
                {rowData.customerPhone}

            </React.Fragment>
        );
    };

    const confirmedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Confimed By</span>
                {rowData.confirmedBy}

            </React.Fragment>
        );
    };

    const notesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mgr Notes</span>
                {rowData.appointmentNotes}

            </React.Fragment>
        );
    };

    const resultBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Result</span>
                {rowData.appointResult}

            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {userCredentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={userCredentials}
                        itemId={rowData.id}
                        onEdit={(appointmentId) => {
                            updateSectionIndex(2);
                            handleGetAppointmentDetail(appointmentId);
                        }}
                    />
                )}


            </React.Fragment>
        );
    };

    const table_columns = {
        "appointDateTime": "Appt Date",
        "salesPerson1Name": "Sales Person 1",
        "dealNumber": "Deal Number",
        "appointmentPurpose": "Comments",
        "customerName": "Customer Name",
        "customerEmail": "Customer Email",
        "customerPhone": "Customer Phone",
        "confirmedBy": "Confimed By",
        "appointmentNotes": "Mgr Notes",
        "appointResult": "Result",
        "settings": "Actions"
    }

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            updateSectionIndex(2);
            handleGetAppointmentDetail(event.data.id)
        }
    };

    const handleGetAppointmentDetail = (appointmentId) => {
        resetAppointmentFormik();
        dispatch<any>(handleGetAppointmentInfo({
            dealId, appointmentId, callback: (res) => {
                if (res) {
                    onFormikChange(1, 1);
                    setAppointmentFormik(res);
                 
                } else {
                    onError('Failed to Fetch the Data.');
                }

            }
        }))
    }

    const header = (
        <>
            <Link
                color="inherit"
                onClick={() => {
                    resetAppointmentFormik();
                    setSectionIndex(2);
                    setNoteFlag(false);
                    updateSectionIndex(2);
                }}
            >
                <AddCircle
                    style={{ color: "#70af60" }}
                    className="plus-icon svgPlus-icon" />
					Create Appointment
			</Link>

        </>
    );

    const handleShowGrid = () => {
        //dispatch<any>(handleGetAppointmentsByDeal({ id: dealId, callback: (res) => handlePopulateAppointments(res) }));
        setSectionIndex(1);
        dismissSaveNot('yes');
        updateSectionIndex(1);
    }

    const appointmentFormik = useFormik({
        initialValues: {
            id: 0,
            appointmentDate: "",
            appointmentTime: "",
            appointmentPurpose: "",
            appointmentResult: "",
            appointmentNotes: "",
            salesManagerId: ""
        },
        validationSchema: Yup.object({
            id: Yup.number(),
            appointmentDate: Yup.string().nullable().required('Required'),
            appointmentTime: Yup.string().required("Required"),
            appointmentPurpose: Yup.string().required("Required"),
            appointmentResult: Yup.string(),
            appointmentNotes: Yup.string(),
            salesManagerId: Yup.number(),
        }),
        onSubmit: (values) => {
            let appointTime;
            let appointDate;
            if (values.appointmentTime) {
                appointTime = moment(new Date(values.appointmentDate + ' ' + values.appointmentTime)).utc().format()//moment(values.appointmentTime, "HH:mm").utc();
                //appointTime = utcStart.format()
            }
            if (values.appointmentDate) {
                appointDate = moment(new Date(values.appointmentDate)).utc().format(); //convertLocalToUTC(values.appointmentDate);
            }

            const data = {
                "id": values.id,
                "appointmentDate": appointDate ? convertLocalToUTCModified(appointDate):'',
                "appointmentTime": appointTime,
                "appointmentPurpose": values.appointmentPurpose,
                "appointmentResult": values.appointmentResult,
                "appointmentNotes": values.appointmentNotes,
                "salesManagerId": values.salesManagerId
            };

            if (data.appointmentResult === '') {
                delete data.appointmentResult;
            }
            if (noteFlag) {
                saveNotesDetails();
            }

            dispatch<any>(handleSaveAppointment({
                appointment: data, dealId: dealId, callback: (res) => {
                    if (res) {
                        dismissSaveNot('yes');
                        getAppointmentsNotesDetails();
                        //dispatch<any>(handleGetAppointmentsByDeal({ id: dealId, callback: (res) => handlePopulateAppointments(res) }));
                         setSectionIndex(1);
                         updateSectionIndex(1);
                    } else {
                        onError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    }
                }
            }));
        }
    });

    const handleAppointmentDateChange = (val) => {
        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                "appointmentDate": val? moment(val).format("YYYY-MM-DD"):val

             }
        );
        setAppointmentDate(new Date(convertUTCToLocalDateOnlyModified(val)));
        onFormikChange(1, 2);
    };

    //const handleAppointmentTimeChange = (e) => {
    //    if (e.target.value) {
    //        appointmentFormik.setValues(
    //            {
    //                ...appointmentFormik.values,
    //                'appointmentTime': e.target.value
    //            }
    //        );
    //    }
    //    onFormikChange(1, 2);
    //}

    const handleAppointmentTimeChange = (value) => {
        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                'appointmentTime': value ? value.format('HH:mm') : ''
            }
        );
        setAppointmentTime(moment(value, 'HH:mm'));
        onFormikChange(1, 2);
    }

    const handleAppointmentFormikDDChange = (e: { value: any, target: any }) => {
        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                [e.target.id]: e.value,
            }
        );
        onFormikChange(1, 2);
    }

    const handleTextAreaChange = (e, newValue) => {
        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                [e.target.id]: newValue,
            }
        );
        onFormikChange(1, 2);
    };

    const handleAppointmentFormikSumbit = () => {
        onError('');
        appointmentFormik.handleSubmit();
    }

    const setAppointmentFormik = (data) => {
        let localTime = '';
        if (data.appointmentTime && Date.parse(data.appointmentTime) > 0) {
            var date = moment.utc(data.appointmentTime).format('YYYY-MM-DD HH:mm:ss');
            var stillUtc = moment.utc(date).toDate();
            localTime = moment(stillUtc).local().format('HH:mm');
            setAppointmentTime(moment(stillUtc).local());
        }

        let newObj = {
            "id": data.id,
            "appointmentDate": moment.utc(data.appointmentDate).format('YYYY-MM-DD'),//convertUTCToLocalModified(data.appointmentDate),
            "appointmentTime": localTime,
            "appointmentPurpose": data.appointmentPurpose,
            "appointmentResult": data.appointmentResult,
            "appointmentNotes": data.appointmentNotes,
            "salesManagerId": data.employee?.id,
        }

        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                ...newObj
            });

        //setinitialAppointVal(newObj);
        setAppointmentDate(data.appointmentDate ? new Date(convertUTCToLocalDateOnlyModified(data.appointmentDate)) : null);
        setSectionIndex(2);
        //updateSectionIndex(2);
    };

    const resetAppointmentFormik = () => {
        appointmentFormik.resetForm();
        appointmentFormik.setValues(
            {
                ...appointmentFormik.values,
                id: 0,
                appointmentDate: "",
                appointmentTime: "",
                appointmentPurpose: "",
                appointmentResult: "",
                appointmentNotes: "",
                salesManagerId: ""
            });
        setAppointmentDate(null);
        setAppointmentTime(null);
        setNoteFlag(false);
        setNoteError(null);
        setNotesList(null);
        onFormikChange(1, 1);
    };

    const handleDateBlur = () => {
        setBlurFlag(true);
    }
    const handleTimePanelOpen = () => {
        setBlurFlagTime(true);
    }

    useEffect(()=>{
        if(sectionIndex === 2){
            getAppointmentsNotesDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sectionIndex])

    return (
        <>
            {
                sectionIndex === 1 && (
                    <>
                        <div className="datatable-filter-demo noMaxWidthTable appointment-workbook"> {/* locations */}
                            <div className="card header-plusBtn">
                                <CustomTable
                                    column_list={table_columns}
                                    data={appointmentData}
                                    rows={25}
                                    rowsPerPageOptions={[25,50,100]}
                                    header={header}
                                    customTemplates={{
                                        "appointDateTime": dateBodyTemplate,
                                        "salesPerson1Name": salesPersonBodyTemplate,
                                        "dealNumber": dealNumberBodyTemplate,
                                        "appointmentPurpose": purposeBodyTemplate,
                                        "customerName": custNameBodyTemplate,
                                        "customerEmail": custEmailBodyTemplate,
                                        "customerPhone": custPhoneBodyTemplate,
                                        "confirmedBy": confirmedByBodyTemplate,
                                        "appointmentNotes": notesBodyTemplate,
                                        "appointResult": resultBodyTemplate,
                                        "settings": settingBodyTemplate,
                                    }}
                                    emptyMessage="No appointments found."
                                    onRowClick={(event) => handleRowClick(event)}
                                />
                            </div>
                        </div>

                    </>)
            }
            {
                sectionIndex === 2 && (<>
                    <div className="dealTracker-appointment">
                        <Row>
                            <Col>
                                <div className="delivery-section">
                                    <Row>
                                        {
                                            isMobile ?
                                                <>
                                                <div className="delivery-section" style={{padding:"0px 14px"}} > {/* className="main-section-body" */}
                                                  <Row >
                                                    <Col xs={7} className="request-delivery request-deliveryText">
                                                        <div className="date-time-group">
                                                            <>
                                                                <div className="auctionDate date-picker" style={{ width: "100%" }}>
                                                                    <label style={{ display: "block" }} className="lbl-text required-Label">Appointment Time</label>
                                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                        <Calendar
                                                                            id="appointmentDate"
                                                                            style={{ width: "100%" }}
                                                                            value={appointmentDate}
                                                                            showIcon
                                                                            onChange={(e) => handleAppointmentDateChange(e.value)}
                                                                            onBlur={handleDateBlur}
                                                                            required={true}
                                                                            monthNavigator
                                                                            yearNavigator
                                                                            yearRange={yearRange} />

                                                                    </div>
                                                                    <span className="error-message" style={{display:"flex",marginTop:"37px"}}>
                                                                        {blurFlag && appointmentFormik.errors && appointmentFormik.errors.appointmentDate &&
                                                                        appointmentFormik.errors.appointmentDate.toString()}
                                                                    </span>
                                                                </div>
                                                            </>
                                                            <>
                                                                 <div className="auctionStartTime time-picker" style={{ width: "100%" }}>
                                                                            {/*<TextField
                                                                         id="appointmentTime"
                                                                         label=""
                                                                         type="time"
                                                                         value={appointmentFormik.values.appointmentTime}
                                                                         onChange={(e) => handleAppointmentTimeChange(e)}
                                                                         onBlur={handleTimeBlur}
                                                                         required={true}
                                                                         // defaultValue="07:30"
                                                                         className="requestDeliveryTime requestDeliveryTimeAppointment float-left"
                                                                         InputLabelProps={{
                                                                             shrink: true,
                                                                         }}
                                                                         inputProps={{
                                                                             step: 300, // 5 min
                                                                         }}
                                                                     />*/}
                                                                            <TimePicker
                                                                                id="appointmentTime"
                                                                                showSecond={false}
                                                                                value={appointmentTime}
                                                                                className="mask-FieldGroup rc-time-picker-input requestDeliveryTime requestDeliveryTimeAppointment float-left"
                                                                                onChange={handleAppointmentTimeChange}
                                                                                format="h:mm a"
                                                                                use12Hours
                                                                                onOpen={handleTimePanelOpen}
                                                                                inputReadOnly
                                                                                minuteStep={15}
                                                                                popupClassName="req-del-time-panel"
                                                                                placeholder="Time"
                                                                                inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                            />
                                                                 </div>
                                                                 <span className="error-message" style={{display:"flex",marginTop:"68px",float:"left",position:"fixed",marginLeft:"28.5%"}}>
                                                                    {blurFlagTime && appointmentFormik.errors && appointmentFormik.errors.appointmentTime &&
                                                                    appointmentFormik.errors.appointmentTime.toString()}
                                                                 </span>
                                                            </>
                                                        </div>

                                                    </Col>
                                                    <Col xs={5}>
                                                        <label className="lbl-text">Result</label>
                                                        <PRDropdown
                                                            id="appointmentResult"
                                                            placeholder=""
                                                            value={appointmentFormik.values.appointmentResult}
                                                            options={masterDropdownValues.appointmentResult}
                                                            onChange={handleAppointmentFormikDDChange}
                                                            optionLabel="text"
                                                            optionValue="key"
                                                            // appendTo="self"
                                                            className="custom-p-dropdown"
                                                            filter={false}
                                                            resetFilterOnHide={true}
                                                        />
                                                    </Col>
                                                  </Row>                                     
                                                </div>
                                                </> :
                                                <>
                                                    <Col style={{display:"flex"}} className="request-delivery" md={6}>
                                                        <div className="auctionDate date-picker auctionDateAppointment">
                                                            <label style={{ display: "block" }} className="lbl-text required-Label">Appointment Time</label>
                                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">                        
                                                                <Calendar
                                                                   id="appointmentDate"
                                                                   //className="modal-calendar"
                                                                   value={appointmentDate}
                                                                   style={{ width: "100%" }}
                                                                   showIcon
                                                                   onChange={(e) => handleAppointmentDateChange(e.value)}
                                                                   onBlur={handleDateBlur}
                                                                   monthNavigator
                                                                   required={true}
                                                                   // appendTo="self"
                                                                   yearRange={yearRange}
                                                                   yearNavigator />

                                                            </div>
                                                            <span className="error-message error-message-text " >
                                                               {blurFlag && appointmentFormik.errors && appointmentFormik.errors.appointmentDate &&
                                                               appointmentFormik.errors.appointmentDate.toString()}
                                                            </span>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <label style={{ display: "block" }} className="lbl-text">&nbsp;</label>
                                                            <TimePicker
                                                                id="appointmentTime"
                                                                showSecond={false}
                                                                value={appointmentTime}
                                                                className="mask-FieldGroup rc-time-picker-input"
                                                                onChange={handleAppointmentTimeChange}
                                                                onOpen={handleTimePanelOpen}
                                                                format="h:mm a"
                                                                use12Hours
                                                                inputReadOnly
                                                                minuteStep={15}
                                                                popupClassName="appointment-time-panel"
                                                                inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                                placeholder="Time"
                                                            />
                                                            <span className="error-message" style={{ display: "flex" }}>
                                                                {blurFlagTime && appointmentFormik.errors && appointmentFormik.errors.appointmentTime &&
                                                                    appointmentFormik.errors.appointmentTime.toString()}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col  md={6} >
                                                        <label className="lbl-text">Result</label>
                                                        <PRDropdown
                                                            id="appointmentResult"
                                                            placeholder=""
                                                            value={appointmentFormik.values.appointmentResult}
                                                            options={masterDropdownValues.appointmentResult}
                                                            onChange={handleAppointmentFormikDDChange}
                                                            optionLabel="text"
                                                            optionValue="key"
                                                            // appendTo="self"
                                                            className="custom-p-dropdown"
                                                            filter={false}
                                                            resetFilterOnHide={true}
                                                        />
                                                    </Col>
                                                </>
                                        }

                                    </Row>
                                    <Row className="appointment-purpose">
                                        <Col>
                                            <OffTextField
                                                style={{ minHeight: "140px" }}
                                                id="appointmentPurpose"
                                                name="appointmentPurpose"
                                                label="Appointment Purpose"
                                                multiline
                                                required={true}
                                                resizable={false}
                                                className="full-width"
                                                value={appointmentFormik.values.appointmentPurpose}
                                                onChange={(e, newValue) => handleTextAreaChange(e, newValue)}
                                                onBlur={appointmentFormik.handleBlur}
                                                errorMessage={
                                                    appointmentFormik.touched.appointmentPurpose &&
                                                    appointmentFormik.errors.appointmentPurpose &&
                                                    appointmentFormik.errors.appointmentPurpose.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>                      
                                <Row>
                                    <Col>
                                        <label className="lbl-text">Mgr Confirmed</label>
                                        <PRDropdown
                                            id="salesManagerId"
                                            placeholder=""
                                            value={appointmentFormik.values.salesManagerId}
                                            options={salesManagers}
                                            onChange={handleAppointmentFormikDDChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            // appendTo="self"
                                            className="custom-p-dropdown manager-dd showAboveMgrList"
                                            filter={true}
                                            resetFilterOnHide={true}
                                        />
                                    </Col>
                                </Row>
                                
                      

                                {/*}<Row className="appointment-notes">
                                    <Col>
                                        <OffTextField
                                            style={{ minHeight: "70px" }}
                                            id="appointmentNotes"
                                            name="appointmentNotes"
                                            label="Mgr Notes"
                                            multiline={true}
                                            className="full-width"
                                            value={appointmentFormik.values.appointmentNotes}
                                            onChange={(e, newValue) => handleTextAreaChange(e, newValue)}
                                            onBlur={appointmentFormik.handleBlur}
                                            errorMessage={
                                                appointmentFormik.touched.appointmentNotes &&
                                                appointmentFormik.errors.appointmentNotes &&
                                                appointmentFormik.errors.appointmentNotes.toString()
                                            }
                                        />
                                    </Col>
                                </Row>*/}
                                <Row style={{ paddingTop: "20px" }}>
                                    <Col>
                                        <PrimaryButton onClick={handleShowGrid}>Cancel</PrimaryButton>
                                    </Col>
                                    <Col>
                                        <PrimaryButton style={{ float: "right" }}
                                            disabled={!appointmentFormik.dirty || !appointmentFormik.isValid}
                                            onClick={handleAppointmentFormikSumbit}>
                                            Save
                                                </PrimaryButton>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Accordion className="inventory-filter-accordion" defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                                style={{ marginTop: "20px" }}
                                            >
                                                <div className={classes.column + " notesdiv-pad writeUpsFont "} >
                                                    <Typography component={'h3'} className={classes.heading}>Notes</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.details}>
                                                {
                                                   appointmentFormik.values.id > 0 && 
                                                    <Row>
                                                        <Col className="notesSection">
                                                            <OffTextField
                                                                id="appointmentNotesVal"
                                                                name="appointmentNotesVal"
                                                                label=""
                                                                multiline
                                                                resizable={false}
                                                                className="full-width fontWeightSection"
                                                                style={{fontSize:"14px",fontWeight:600}}
                                                                value={appointmentNotesVal}
                                                                onChange={(e, newValue) => handleNoteChange(e, newValue)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    noteError ?
                                                        <div className="">
                                                            <Row>
                                                                <Col>
                                                                    <span className="genericErrorColor"><span>Please enter details</span></span>
                                                                </Col>
                                                            </Row>
                                                        </div> : null
                                                }
                                                {
                                                   appointmentFormik.values.id > 0 && 

                                                   <Row className="rowPaddingBtwSpace">
                                                       <Col className="text-right">
                                                           <PrimaryButton style={{ marginTop: 10 }} onClick={() => saveNotesDetails()}>Add Note</PrimaryButton>
                                                       </Col>
                                                   </Row>
                                                }
                                                <Row>
                                                    <Col>
                                                        <h3 style={{ border: "none" }} className="writeUpsFont">History</h3>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        {notesList?.map((item) => (
                                                            
                                                            <div key={"boxes-" + item.id}>
                                                                <Row className="rowSpaceNotes rowSpace" style={{ padding: "0 20px" }}>
                                                                    <Col md={3} xs={4}>
                                                                        <div className="writeUpsNoteText">{moment(new Date(item.createdAt)).local().format("MM/DD/YYYY").toString()}</div>
                                                                        <div className="writeUpsNoteText">{item?.firstName} {item?.lastName}</div>
                                                                    </Col> 
                                                                    <Col md={3} xs={4}>
                                                                        <div className="writeUpsNoteText">{dealType[item?.type]}</div>
                                                                       
                                                                    </Col>
                                                                    <Col md={6} xs={4} className="writeUpsNoteText">{item.note}</Col>
                                                                </Row>
                                                                <hr />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>
                </>)
            }

        </>



    );
};

