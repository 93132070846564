import React from "react";
import { Stack, IStackStyles, IStackTokens } from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "pages/auth/loginPage/styles";
import { PrimaryButton } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { handleLogoutAndLogin } from "store/auth/auth.action";

const stackStyles: IStackStyles = {
    root: {
        height: "100vh",
        // overflow: "hidden",
    },
};
const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};



const SuccessfullyAccessRequested: React.FC = () => {
   const dispatch = useDispatch();

   const handleRouting = () => {
       dispatch<any>(handleLogoutAndLogin());
  }; 

  return (
      <Stack
          styles={stackStyles}
          tokens={itemAlignmentsStackTokens}
          verticalAlign="center"
          horizontalAlign="center"
      >
        <Stack.Item
          styles={
            styles.stackItemStylesAutoHeight
          }
        >
          <Stack
            verticalAlign="space-between"
            styles={styles.loginContentStyles}>
            <Stack.Item>
              <CustomFontText>Successfully Requested</CustomFontText>
            </Stack.Item>

            <Stack.Item>
              <Stack tokens={styles.textFields}>
                 <div><p className="confirmationMsg">
                 Thank you for requesting access. You will recieve an email during business hours Monday-Friday once your request is approved.</p></div>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack tokens={styles.buttonTokens}>
                <PrimaryButton
                text="Back to Login"
                onClick={handleRouting}
              />
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
    </Stack> 
  );
};

export default SuccessfullyAccessRequested;
