import * as actions from "./auth.types";

interface IState {
  loggedUserId: number | null;
  loading: boolean;
  loginError: any;
  userToken: string | null;
  resetPasswordError: any | null;
  resetPasswordReqError: any| null,
  refreshToken: string | null;
  refreshTokenLoading: boolean;
  expPasswordDate: string;
  registerErrors:
    | null
    | {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
      }[];
  registerEmail: string;
  resetEmail: string;
  resetPasswordLinkError: any;
  error: object | null;
  confirmError: any,
  emailVerifyError: any,
  emailReVerifyStatus: any,
  logoutMessage: string,
  resendSuccessMessage: string
}

const initialState: IState = {
  loggedUserId: null,
  loading: false,
  loginError: null,
  userToken: null,
  error: null,
  resetPasswordError: null,
  resetPasswordReqError: null,
  refreshToken: null,
  refreshTokenLoading: false,
  registerErrors: null,
  registerEmail: null,
  resetEmail: null,
  resetPasswordLinkError: null,
  expPasswordDate: "",
  confirmError: null,
  emailVerifyError: null,
  emailReVerifyStatus: null,
  logoutMessage: "",
  resendSuccessMessage:""
};

export const AuthReducer = (
  state: IState = initialState,
  action: actions.AuthActions
) => {
  switch (action.type) {
    case actions.SET_ID_FOR_LOGGED_USER_RESOLVED: {
      return {
        ...state,
        loggedUserId: action.payload,
      };
    }

    case actions.SET_EXP_PASSWORD_FOR_LOGGED_USER_RESOLVED: {
      console.log();
      return {
        ...state,
        expPasswordDate: action.payload,
      };
    }

    // case actions.REGISTER_PENDING: {
    //   return {
    //     ...state,
    //     loading: true,
    //     registerErrors: null,
    //   };
    // }

    // case actions.REGISTER_RESOLVED: {
    //   return {
    //     ...state,
    //     loading: false,
    //     registerErrors: null,
    //   };
    // }

    // case actions.SUCCESS_REGISTER_EMAIL: {
    //   return {
    //     ...state,
    //     loading: false,
    //     registerEmail: action.payload,
    //   };
    // }

    // case actions.REGISTER_REJECTED: {
    //   return {
    //     ...state,
    //     loading: false,
    //     registerErrors: action.payload,
    //   } as IState;
    // }

    case actions.LOGIN_PENDING: {
      return {
        ...state,
        loading: true,
        loginError: null,
      };
    }

    case actions.CLEAR_SERVER_MESSAGE: {
        
      return {
        ...state,
        loading: false,
        loginError: null,
        resetPasswordError: null,
        registerErrors: null,
        confirmError: null,
        logoutMessage: null,
        resendSuccessMessage: null,
        resetPasswordReqError: null
      };
    }

      case actions.LOGIN_RESOLVED: {
      return {
        ...state,
        loading: false,
        userToken: action.payload.token,
        refreshToken: action.payload.refresh_token,
        loginError: null,
      };
    }

      case actions.LOGIN_REJECTED: {
          
      return {
        ...state,
        loading: false,
          loginError: action.payload,
          logoutMessage: null
      };
    }

    case actions.LOGIN_BY_TOKEN_PENDING: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case actions.LOGIN_BY_TOKEN_RESOLVED: {
      return {
        ...state,
        loading: false,
        userToken: action.payload.token,
        refreshToken: action.payload.refresh_token,
        error: null,
      };
    }

    case actions.HANDLE_LOGOUT: {
          
      return {
        ...state,
        loading: false,
        error: null,
        logoutMessage: action.payload?"Your session has ended. You need to log in again to access your QoreDMS account.":"You have successfully logged out"
      };
    }
    case actions.HANDLE_LOGOUT_LOGIN: {

          return {
              ...state,
              loading: false,
              error: null,
              logoutMessage: null
          };
      }
    case actions.LOGOUT_PENDING: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case actions.RESET_PASSWORD_REQUEST_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.RESET_PASSWORD_REQUEST_RESOLVED: {
      return {
        ...state,
        loading: false,
        resetEmail: action.payload,
        resendSuccessMessage: "Email sent successfully."
      };
    }
    case actions.RESET_PASSWORD_REQUEST_REJECTED: {
      return {
        ...state,
        loading: false,
        resetPasswordReqError: 'No account matching that email'
      };
    }
    case actions.RESET_PASSWORD_LINK_EXPIRED: {
      return {
        ...state,
        loading: false,
        resetPasswordLinkError: action.payload
      };
    }
    case actions.RESET_PASSWORD_CONFIRM_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.RESET_PASSWORD_CONFIRM_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.RESET_PASSWORD_CONFIRM_REJECTED: {
      return {
        ...state,
        loading: false,
        resetPasswordError: action.payload,
      };
    }
    case actions.CONFIRM_ACCOUNT_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CONFIRM_ACCOUNT_RESOLVED: {
      return {
        ...state,
        loading: false,
        confirmError: null

      };
    }
    case actions.CONFIRM_ACCOUNT_REJECTED: {
      return {
        ...state,
        loading: false,
        confirmError: action.payload
      };
    }
    case actions.LOGOUT_RESOLVED: {
          
          return {
        loggedUserId: null,
        loading: false,
        userToken: null,
        error: null,
        resetPasswordReqError: null,
        refreshToken: null,
        refreshTokenLoading: false,
        registerErrors: null,
        registerEmail: null,
        resetEmail: null,
        resetPasswordLinkError: null,
        emailReVerifyStatus: null,        
        logoutMessage: state.logoutMessage
      };
    }
    case actions.HANDLE_CONFIRM_EMAIL:
    case actions.HANDLE_CONFIRM_EMAIL_STATUS: {
      return {
        ...state,
        loading: false,
        emailVerifyError: action.payload
      };
    }
    case actions.HANDLE_RECONFIRM_EMAIL_STATUS: {
      return {
        ...state,
        loading: false,
        emailReVerifyStatus: action.payload
      };
    }
    default:
      return state;
  }
};
