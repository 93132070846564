import React, { FunctionComponent, useEffect } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack
} from "@fluentui/react";
//import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
import CurrencyInput from 'react-currency-input-field'
import moment from "moment";
import { PRICE_PREFIX } from "constants/constants";
import { currencyFormat } from "utils/helperFunctions";

const contentStyles = content(600, "auto");

interface IProps {
    onSubmitFormik: (formikData: any) => void;
    onDismiss: () => void;
    priceDetails: any;
    vehID: any;
}

export const UpdatePricingTabDialog: FunctionComponent<IProps> = ({
    onSubmitFormik,
    onDismiss,
    priceDetails,
    vehID
}) => {
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    //const digitPattern = /^\d+$/;
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const [editPricingDialog, seteditPricingDialog] = React.useState('PUT');

    const formik = useFormik({
        initialValues: {                  
            currentPrice: priceDetails ? priceDetails.currentPrice: '',
            currentDate: priceDetails?.currentDate ? moment(priceDetails.date).format("YYYY-MM-DD"): new Date(),
            newPrice: priceDetails ? priceDetails.newPrice: '',
            //dealerPack: priceDetails ? priceDetails.dealerPack : '',
        },
        validationSchema: Yup.object({
            currentPrice: Yup.string(),
            currentDate: Yup.string(),
            newPrice: Yup.string().required("Required"),
            //dealerPack: Yup.string()
        }),
        onSubmit: (values) => {
            onSubmitFormik(values);
        },
    });

    useEffect(() => {
        console.log('pricingTableVal', priceDetails)
        if (priceDetails && priceDetails.type !== '') {
            seteditPricingDialog('PUT')
            formik.dirty = true;
        } else {
            seteditPricingDialog('POST')
        }
        console.log('formik',formik)
    }, [priceDetails, formik]);

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleUdateQValue = () => {
        formik.handleSubmit();
    }
    
    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle">Update Price</span>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div style={{width:"auto"}}className={contentStyles.body + " expenseDialogPopup"}>
                <Stack 
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={8} md={8} xs={10}>
                            <label style={{ display: "block", fontSize: "16px" }} className="lbl-text">Current Price</label>
                            <label style={{ display: "block", color: "grey", fontSize: "15px" }} className="lbl-text">
                                {currencyFormat(formik.values.currentPrice)} set on {moment(formik?.values?.currentDate ? formik.values.currentDate : new Date()).format("MM/DD/YYYY")}
                                </label>                           
                        </Col>
                    </Row>

                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={8} md={8} xs={10}>
                           <label className="lbl-text">New Price</label>
                            <CurrencyInput
                              allowNegativeValue={false}
                              customInput={TextField}
                             // thousandSeparator={true}
                              id="newPrice"
                              name="newPrice"
                              //label="New Price"
                              decimalScale={2}
                              //required={true}
                              prefix={PRICE_PREFIX}
                              value={formik.values.newPrice?formik.values.newPrice.replace(/,/g, ''):''}
                              onValueChange ={(e) => {
                                formik.setValues(
                                    {
                                        ...formik.values,
                                        "newPrice": e
                                    }
                                );
                            }}
                              onBlur={formik.handleBlur}
                            />
                            <span className="error-message">
                                {formik.touched.newPrice &&
                                    formik.errors.newPrice &&
                                    formik.errors.newPrice.toString()}
                            </span>
                        </Col>
                    </Row>

                    {/*<Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={8} md={8} xs={10}>
                            <CurrencyInput
                              allowNegativeValue={false}
                              customInput={TextField}
                             // thousandSeparator={true}
                              id="dealerPack"
                              name="dealerPack"
                              label="Dealer Pack"
                              decimalScale={2}
                              prefix={PRICE_PREFIX}
                              value={formik.values.dealerPack}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                        </Col>
                    </Row>*/}

                    <Row className="inventoryRowSection footerBtn updatePriceQvalBtn" justify="center">
                        <PrimaryButton
                            text="Save"
                            // style={{ float: "right" }}                          
                            onClick={handleUdateQValue}
                            onRenderIcon={renderSpinner}
                            disabled={((!formik.isValid || !formik.dirty) && (editPricingDialog === 'POST'))
                        || ((!formik.isValid || Object.keys(formik.errors).length > 0) && (editPricingDialog === 'PUT'))}
                        />
                    </Row>
                </Stack>

            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
