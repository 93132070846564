import React, { useEffect, useState } from "react";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { AccountSettingsPivot } from "./AccountSettingsPivot";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "./styles";
import GoBack from "../../components/buttons/goBack/GoBack";
import { formatPathname } from "../../utils/helperFunctions";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-grid-system";

const AccountSettingsPivotPage = () => {
  const [pathname, setPathname] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={5} xl={12} xxl={12}>
          <Stack styles={styles.stackStyles}>
            {/*<Loader show={loading} />*/}
            {/*<div>{JSON.stringify(SMTPSettings, null, 4)}</div>*/}
            <Stack
              horizontal
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <div>
                <GoBack label={formatPathname(pathname)} />
              </div>
            </Stack>

            <div
              style={{
                boxShadow: Depths.depth8,
                background: DefaultPalette.white,
                borderRadius: 2,
                // width: 250,
                // height: 400,
                zIndex: 1,
                padding: 40,
                paddingTop: 10,
              }}
            >
              <AccountSettingsPivot />
            </div>
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export default AccountSettingsPivotPage;
