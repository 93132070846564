import React from "react";
import {
  Modal,
  IconButton,
  PrimaryButton,
  // DefaultPalette,
  // IMessageBarStyles,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { styles } from "pages/accountSettingsPage/styles";
import { AppState } from "store/index";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as constantStyle,
  // notificationColors,
} from "constants/styles";
// import { IOption } from "types/optionsTypes";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stringToCurrenyFormatForEditInputs } from "utils/helperFunctions";
import { handleCancelSubscription, handlePayment } from "store/subscriptions/subscriptions.action";
import { formatDateSub } from "utils/dateTimeFunctions";
import store from "store";
import { handleLoginByToken, handleRefreshToken } from "store/auth/auth.action";

import { CheckoutForm } from "pages/subscriptionsPaga/payWithCard/CheckoutForm";
import CreditCartItem from "pages/subscriptionsPaga/CreditCartItem";

const contentStyles = constantStyle(700, "auto");

interface IProps {
  onDismiss: () => void;
  isOpen: boolean;
  subscription: any;
  callback: Function
}

// const messageStyles: Partial<IMessageBarStyles> = {
//   root: {
//     margin: "5px 0",
//     ...notificationColors,
//   },
//   icon: {
//     color: DefaultPalette.themePrimary,
//   },
// };

export const SubscriptionDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  isOpen,
  subscription,
    callback
}) => {
  const dispatch = useDispatch();
  const loadingAdding = useSelector((state: AppState) => state.subscriptions.addingCart);
  const loading = useSelector((state: AppState) => state.subscriptions.loading);
  const creditCarts = useSelector(
    (state: AppState) => state.subscriptions.creditCarts
  );

  const handleSubscriptionClick = () => {
      console.log('subscriptions',subscription);
      

      dispatch<any>(handlePayment({product: subscription.id, shouldAddCreditCart: false, callback: callback}));

  }

  const handleCancelSubscriptionClick = () => {
    const cb = () => {
      onDismiss();
      setTimeout(() => {
        store.dispatch<any>(handleRefreshToken());
        store.dispatch<any>(handleLoginByToken(""));
      }, 2000);
    };
    dispatch<any>(handleCancelSubscription(subscription.cancelId, cb));
  };

  const renderSpinner = () => {
    return (loading || loadingAdding) ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.headerNoShadow}>
        <CustomFontText>{subscription?.title}</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      {/*{user && user.username && (*/}
      <div className={contentStyles.body}>
        <Stack style={{ marginBottom: 15 }}>
          <div
            style={{
              lineHeight: 1.5,
              color: subscription?.active ? "#4F9D3C" : undefined,
            }}
          >
            Subscription Status:{" "}
            {subscription?.active
              ? `Active (expires in: ${formatDateSub(
                  subscription?.currentPeriodEnd * 1000
                )})`
              : "Inactive"}
          </div>
        </Stack>
        <Stack style={{ marginBottom: 15 }}>
          <div style={{ lineHeight: 1.5 }}>{subscription?.description}</div>
        </Stack>

        <Stack
          style={{
            borderTop: "1px solid #eee",
            paddingTop: 15,
            marginBottom: 15,
          }}
          horizontal
          horizontalAlign="space-between"
        >
          <div>Total Cost:</div>
          <div style={{ fontWeight: "bold" }}>
            {`$ ${stringToCurrenyFormatForEditInputs(
              subscription?.price ?? ""
            )} / ${subscription?.interval}`}
          </div>
        </Stack>
        {/*<Stack horizontal horizontalAlign="center">*/}
        {/*  <PrimaryButton*/}
        {/*      text="Go To Payment"*/}
        {/*      onClick={() => {}}*/}
        {/*      iconProps={{ iconName: "PaymentCard" }}*/}
        {/*  />*/}
        {/*</Stack>*/}
      </div>

      {/*// )}*/}
      {subscription?.active && (
        <div className={contentStyles.footerNoShadow}>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{ marginBottom: 30 }}
          >
            <PrimaryButton
              text={"Cancel Subscription"}
              onClick={handleCancelSubscriptionClick}
              iconProps={{ iconName: "PaymentCard" }}
              onRenderIcon={renderSpinner}
            />
          </Stack>
        </div>
      )}

      {!subscription?.active && (
        <div className={contentStyles.footerSubscription}>
          {Boolean(creditCarts.length) ? (
            <>
              {/*<Dropdown*/}
              {/*    id="chooseCreditCart"*/}
              {/*    label="Choose Credit Cart"*/}
              {/*    // selectedKey={*/}
              {/*    //     formik.values.year ? formik.values.year : undefined*/}
              {/*    // }*/}
              {/*    // onChange={handleDropdownChange}*/}
              {/*    // placeholder=""*/}
              {/*    // options={yearValues}*/}
              {/*    options={[{key: '1', text: '12'}, {key: '2', text: '22'}]}*/}
              {/*    // styles={dropdownStyles}*/}
              {/*    // onBlur={formik.handleBlur}*/}
              {/*    // errorMessage={*/}
              {/*    //     formik.touched.year && formik.errors.year*/}
              {/*    //         ? formik.errors.year.toString()*/}
              {/*    //         : null*/}
              {/*    // }*/}
              {/*/>*/}
              {/*<div style={{marginBottom: 20}}>Current Cart:</div>*/}
              <Stack style={{marginTop: 15}}>
                <CreditCartItem cart={creditCarts[0]} />
                <PrimaryButton
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    width: "100%",
                    padding: 20,
                    fontSize: 16,
                  }}
                  onRenderIcon={renderSpinner}
                  type="submit"
                  text={Boolean(creditCarts.length) ? "Subscribe" : "Add Credit Cart"}
                  onClick={handleSubscriptionClick}
                />
              </Stack>
            </>
          ) : (
            <CheckoutForm add/>
          )}
        </div>
      )}
    </Modal>
  );
};
