import React, { useState, useEffect } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  IconButton,
  Button,
  Stack,
} from "@fluentui/react";

import { CustomFontText } from "components/customFontText/CustomFontText";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ViewAdditionalActionsDropdown } from "components/table/dropdowns/ViewAdditionalActionsDropdown";
import { ITask, ITasks } from "types/taskTypes";

import { AddTaskDialog } from "components/dialogs/addTaskDialog/AddTaskDialog";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import {
  iconButtonStyles,
  cancelIcon,
  contentStyles as contentStyle,
} from "constants/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  handleAddTask,
  //handleGetTasks,
} from "store/prospects/prospects.action";
//import { IPagination } from "types/paginationTypes";
import { AppState } from "store";

import Scheduler from "components/scheduler/Scheduler";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { handleGetEmployeeTasks } from "store/employees/employees.action";

const contentStyles = contentStyle();
const stackStyles: IStackStyles = {
  root: {},
};

interface IProps {
  onDismiss: () => void;
  prospectId?: number;
  /**
   * @description Check if this version should be without footer and back button
   * */
  view?: boolean;
  editable?: boolean;
}

export const EditProspectSchedulerDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  prospectId,
  view,
  editable,
}) => {
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const dispatch = useDispatch();

  const prospectTasks: ITasks = useSelector(
    (state: AppState) => state.prospects.tasks
  );
  const employeeTasks: ITasks = useSelector(
    (state: AppState) => state.employees.employeeTasks
  );
  const loading = useSelector(
    (state: AppState) => state.prospects.isTaskLoading
  );
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const selectedProspect = useSelector(
      (state: AppState) => state.prospects.selectedProspect
  );


  const [tasks, setTasks] = useState(null);

  console.log("ÉDITABLE", editable);

  useEffect(() => {
    if (user && user.roles) {
      if (isUserMaster(user.roles)) {
        //const request: IPagination = {
        //  page: 1,
        //  limit: 5,
        //};
        //dispatch<any>(handleGetTasks(request, prospectId));
      } else {
        dispatch<any>(handleGetEmployeeTasks(user.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    console.log(prospectTasks);
    console.log(employeeTasks);



    if(editable && selectedProspect && selectedProspect.tasks){
      setTasks([...selectedProspect.tasks]);
      return
    }

    if (isUserMaster(user.roles) && prospectTasks && prospectTasks.results) {
      setTasks([...prospectTasks.results]);
    } else if (
      !isUserMaster(user.roles) &&
      employeeTasks &&
      employeeTasks.results
    ) {
      setTasks([...employeeTasks.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeTasks, prospectTasks, selectedProspect]);

  const openNewTaskDialog = () => {
    setIsNewTaskModalOpen(true);
  };

  const handleCreateNewTask = (task: ITask) => {
    // const i = items;
    // i.push({date: formatDate(task.date), content: task.content});
    // setItems([...i]);
    task && dispatch<any>(handleAddTask({ prospectId, task }));
    setIsNewTaskModalOpen(false);
  };

  useEffect(() => {
    console.log('view', view)
    console.log('editable', editable)
  },[view, editable])

  return tasks ? (
    <>
      {isNewTaskModalOpen && (
        <AddTaskDialog
          onDismiss={() => setIsNewTaskModalOpen(false)}
          onCreateNewTask={handleCreateNewTask}
        />
      )}
      items &&{" "}
      <Modal
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={` ${contentStyles.header}`}>
          {!view && <GoBackToMainDialogBtn label="" onClick={onDismiss} />}
          <CustomFontText>Scheduler</CustomFontText>

          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack
            styles={stackStyles}
            horizontal
            verticalAlign="start"
            horizontalAlign="space-between"
          />

          {editable && (
            <PrimaryButton
              text="Add new task"
              onClick={() => openNewTaskDialog()}
              iconProps={{ iconName: "CircleAdditionSolid" }}
              style={{ marginBottom: 15 }}
            />
          )}
          <Scheduler
              dashboard={Boolean(view)}
            tasks={tasks}
            isLoading={loading}
            prospectId={prospectTasks && prospectId}
          />
        </div>
        {!view && (
          <div className={contentStyles.footer}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}

              // styles={contentStyles.buttonsStyles}
            >
              <div>
                <ViewAdditionalActionsDropdown
                  modulePermissions={getModulePermissions(
                    user.auths || [],
                    "Inventory"
                  )}
                />
              </div>
              <div>
                <Button
                  text="Close"
                  style={{ marginRight: "10px" }}
                  onClick={() => onDismiss()}
                />
                <PrimaryButton
                  text="Save Changes"
                  onClick={() => onDismiss()}
                />
              </div>
            </Stack>
          </div>
        )}
      </Modal>
    </>
  ) : null;
};
