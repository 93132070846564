import * as actions from "./groupsPermissions.types";

import {IGroupPermissionUpdate} from "types/groupPermissionTypes";
import {IFormError} from "types/errorTypes";
import {IPagination} from "types/paginationTypes";
import {
    GET_PERMISSIONS_RESOLVED,
    HANDLE_GET_PERMISSIONS,
    GET_PERMISSIONS_REJECTED,
    UPDATE_PERMISSIONS_PENDING,
    UPDATE_PERMISSIONS_REJECTED,
    UPDATE_PERMISSIONS_RESOLVED,
    HANDLE_UPDATE_PERMISSIONS,
    HandleUpdatePermissions,
} from "./groupsPermissions.types";


export const setSortGroupsPermissions = (sort: string): actions.SetSortGroupsPermissions => {
    return {
        type: actions.SET_SORT_GROUPS_PERMISSIONS,
        payload: sort
    }
}

// GET_GROUPS_PERMISSIONS
export const handleGetGroupsPermissions = (
    request: IPagination
): actions.HandleGetGroupsPermissions => {
    return {
        type: actions.HANDLE_GET_GROUPS_PERMISSIONS,
        payload: request,
    };
};

export const pendingGetGroupsPermissions = (): actions.GetGroupsPermissionsPending => {
    return {
        type: actions.GET_GROUPS_PERMISSIONS_PENDING,
    };
};

export const resolvedGetGroupsPermissions = (
    response: any
): actions.GetGroupPermissionsResolved => {
    return {
        type: actions.GET_GROUPS_PERMISSIONS_RESOLVED,
        payload: {...response},
    };
};

export const rejectedGetGroupsPermissions = (
    errors: IFormError[]
): actions.GetGroupPermissionsRejected => {
    return {
        type: actions.GET_GROUPS_PERMISSIONS_REJECTED,
        payload: errors,
    };
};

export const setRowsPerPage = (rows: number) => {
    return {
        type: actions.SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS,
        payload: rows,
    };
};

export const handleGetPermissions = () => {
    return {
        type: HANDLE_GET_PERMISSIONS,
    }
}

export const resolvedGetPermissions = (permissions: object) => {
    return {
        type: GET_PERMISSIONS_RESOLVED,
        payload: permissions
    }
}

export const rejectedGetPermissions = (error: any) => {
    return {
        type: GET_PERMISSIONS_REJECTED,
        payload: error
    }
}

export const handleUpdatePermissions = (items: IGroupPermissionUpdate[]): HandleUpdatePermissions => {
    return {
        type: HANDLE_UPDATE_PERMISSIONS,
        payload: items
    }
}

export const pendingUpdatePermissions = () => {
    return {
        type: UPDATE_PERMISSIONS_PENDING,
    }
}

export const resolvedUpdatePermissions = () => {
    return {
        type: UPDATE_PERMISSIONS_RESOLVED,
    }
}

export const rejectedUpdatePermissions = (error: any) => {
    return {
        type: UPDATE_PERMISSIONS_REJECTED,
        payload: error
    }
}

