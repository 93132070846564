import * as actions from "store/selectList/selectList.types";
import { ISelectList } from "types/selectListTypes";

interface IState {
  departments: ISelectList[];
  lenders: ISelectList[];
  appraisalBidders: ISelectList[];
  financeProducts: ISelectList[];
  selectList: ISelectList[];
  error: null | object;
}

const initialState: IState = {
  departments: [],
  lenders: [],
  appraisalBidders: [],
  financeProducts: [],
  selectList: [],
  error: null
};

export const SelectListReducer = (
  state: IState = initialState,
  action: actions.SelectListActions
) => {
  switch (action.type) {
    case actions.GET_SELECT_LIST_PENDING:
      return {
        ...state,
        error: null,
      };
      
    case actions.GET_SELECT_LIST_REJECTED:
      return { 
      ...state, 
      error: action.payload 
      };

    case actions.GET_SELECT_LIST_RESOLVED:
        if(action.payload.category === 'departments'){
            return {
                ...state,
                loading: false,
                departments: action.payload.results,
                selectList: action.payload.results
            };
        }else if(action.payload.category === 'lenders'){
            return {
                ...state,
                loading: false,
                lenders: action.payload.results,
                selectList: action.payload.results
            };
        }else if(action.payload.category === 'appraisalBidders'){
            return {
                ...state,
                loading: false,
                appraisalBidders: action.payload.results,
                selectList: action.payload.results
            };
        }else if(action.payload.category === 'financeProducts'){
            return {
                ...state,
                loading: false,
                financeProducts: action.payload.results,
                selectList: action.payload.results
            };
        }else{
        return {
                ...state,
                loading: false,
                financeProducts: [],
                appraisalBidders:[],
                departments: [],
                 lenders: [],
                selectList: []
            };
        }
        
    default:
      return state;
  }
};
