import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useNavigate  } from "react-router-dom";
import { CustomFontText } from "../../customFontText/CustomFontText";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: () => void;
    dealID: any;
    dealNumber?: string;
    existingData?: any;
}

export const VehicleRecordFoundDialog: FunctionComponent<IProps> = ({
    onDismiss,
    dealID,
    dealNumber,
    existingData
}) => {
    const navigate = useNavigate ();

    const handleVehicleDetails = () => {
        onDismiss();
        navigate(`/${dealID}/dealtracker`);
    };



    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Vehicle Record Found</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>


            <div className="dealRecFound body-auto-hight body-min-height-50" style={{ textAlign: "left" }}>
                <div style={{ marginLeft: "25px" }}>
                    <div>
                        <label className="lbl-text">This Vehicle has an active deal in progress.</label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <label className="lbl-text">Deal Number: {dealNumber} {existingData ? ' - '+existingData.location.legalName:''}</label>
                    </div>
                </div>
            </div>


            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center",marginTop:"-15px" }}>
                    <PrimaryButton
                        text="View Deal"
                        onClick={handleVehicleDetails}
                    />
                </div>
            </div>
        </Modal>
    );
};



