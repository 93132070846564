//import { IRegister, ILogin, ILoginResponse } from "types/authTypes";
import {
  ICreateEmployee,
  IEmployee,
  IEmployeesProspect,
} from "types/employeesTypes";
import { IPagination, IPaginationResponse } from "types/paginationTypes";
import { ITasks } from "types/taskTypes";

/** GET_EMPLOYEES */
export const HANDLE_GET_EMPLOYEES = "HANDLE_GET_EMPLOYEES";

export interface HandleGetEmployees {
  type: typeof HANDLE_GET_EMPLOYEES;
  payload: {
    isActive: number;
    limit: number;
    page: number;
    withoutGroup?: number;
    withGroup?: number;
    withProspect?: number;
    withoutProspect?: number;
    sort?: string;
    column?: string;
    role?: string;
    departmentId?: string;
    primaryLocationId?: string;
  };
}

export const SET_SORT_EMPLOYEES = "SET_SORT_EMPLOYEES";

export interface SetSortEmployees {
  type: typeof SET_SORT_EMPLOYEES;
  payload: string;
}

export const GET_EMPLOYEES_PENDING = "GET_EMPLOYEES_PENDING";

export interface GetEmployeesPending {
  type: typeof GET_EMPLOYEES_PENDING;
}

export const GET_EMPLOYEES_RESOLVED = "GET_EMPLOYEES_RESOLVED";

export interface GetEmployeesResolved {
  type: typeof GET_EMPLOYEES_RESOLVED;
  payload: IPaginationResponse<IEmployee>;
}

export const GET_EMPLOYEES_REJECTED = "GET_EMPLOYEES_REJECTED";

export interface GetEmployeesRejected {
  type: typeof GET_EMPLOYEES_REJECTED;
  payload: object[];
}

/** GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE*/
export const HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE =
  "HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE";

export interface HandleGetEmployeesForAddGroupAssignEmployee {
  type: typeof HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE;
  payload: { limit: number; page: number; withoutGroup?: number };
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_PENDING =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_PENDING";

export interface GetEmployeesForAddGroupAssignEmployeePending {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_PENDING;
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_RESOLVED =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_RESOLVED";

export interface GetEmployeesForAddGroupAssignEmployeeResolved {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_RESOLVED;
  payload: IPaginationResponse<IEmployee>;
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_REJECTED =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_REJECTED";

export interface GetEmployeesForAddGroupAssignEmployeeRejected {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGN_EMPLOYEE_REJECTED;
  payload: object[];
}

/** GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE*/
export const HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE =
  "HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE";

export interface HandleGetEmployeesForAddGroupAssignedEmployee {
  type: typeof HANDLE_GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE;
  payload: { limit: number; page: number; withGroup?: number };
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_PENDING =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_PENDING";

export interface GetEmployeesForAddGroupAssignedEmployeePending {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_PENDING;
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_RESOLVED =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_RESOLVED";

export interface GetEmployeesForAddGroupAssignedEmployeeResolved {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_RESOLVED;
  payload: IPaginationResponse<IEmployee>;
}

export const GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_REJECTED =
  "GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_REJECTED";

export interface GetEmployeesForAddGroupAssignedEmployeeRejected {
  type: typeof GET_EMPLOYEES_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE_REJECTED;
  payload: object[];
}

// SET_ROWS_PER_PAGE
export const SET_ROWS_PER_PAGE_EMPLOYEES = "SET_ROWS_PER_PAGE_EMPLOYEES";

export interface SetRowsPerPage {
  type: typeof SET_ROWS_PER_PAGE_EMPLOYEES;
  payload: number;
}

export const SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE =
  "SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE";

export interface SetRowsPerPageForAddGroupAssignEmployee {
  type: typeof SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGN_EMPLOYEE;
  payload: number;
}

export const SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE =
  "SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE";

export interface SetRowsPerPageForAddGroupAssignedEmployee {
  type: typeof SET_ROWS_PER_PAGE_FOR_ADD_GROUP_ASSIGNED_EMPLOYEE;
  payload: number;
}

/** CREATE_EMPLOYEE */
export const HANDLE_CREATE_EMPLOYEE = "HANDLE_CREATE_EMPLOYEE";

export interface HandleCreateEmployee {
  type: typeof HANDLE_CREATE_EMPLOYEE;
  payload: {
    employee: ICreateEmployee;
    assignTo: string;
    callback: () => void;
  };
}

export const CREATE_EMPLOYEE_PENDING = "CREATE_EMPLOYEE_PENDING";

export interface CreateEmployeePending {
  type: typeof CREATE_EMPLOYEE_PENDING;
}

export const CREATE_EMPLOYEE_RESOLVED = "CREATE_EMPLOYEE_RESOLVED";

export interface CreateEmployeeResolved {
  type: typeof CREATE_EMPLOYEE_RESOLVED;
  payload: IEmployee;
}

export const CREATE_EMPLOYEE_REJECTED = "CREATE_EMPLOYEE_REJECTED";

export interface CreateEmployeeRejected {
  type: typeof CREATE_EMPLOYEE_REJECTED;
  payload: object[];
}

/** DELETE_EMPLOYEE */
export const HANDLE_DELETE_EMPLOYEE = "HANDLE_DELETE_EMPLOYEE";

export interface HandleDeleteEmployee {
  type: typeof HANDLE_DELETE_EMPLOYEE;
  payload: { ids: string[] | number[]; callback?: () => void };
}

export const DELETE_EMPLOYEE_PENDING = "DELETE_EMPLOYEE_PENDING";

export interface DeleteEmployeePending {
  type: typeof DELETE_EMPLOYEE_PENDING;
}

export const DELETE_EMPLOYEE_RESOLVED = "DELETE_EMPLOYEE_RESOLVED";

export interface DeleteEmployeeResolved {
  type: typeof DELETE_EMPLOYEE_RESOLVED;
}

export const DELETE_EMPLOYEE_REJECTED = "DELETE_EMPLOYEE_REJECTED";

export interface DeleteEmployeeRejected {
  type: typeof DELETE_EMPLOYEE_REJECTED;
  payload: object;
}

/** VIEW_EMPLOYEE */
export const HANDLE_VIEW_EMPLOYEE = "HANDLE_VIEW_EMPLOYEE";

export interface HandleViewEmployee {
  type: typeof HANDLE_VIEW_EMPLOYEE;
  payload: any;
}

export const VIEW_EMPLOYEE_PENDING = "VIEW_EMPLOYEE_PENDING";

export interface ViewEmployeePending {
  type: typeof VIEW_EMPLOYEE_PENDING;
}

export const VIEW_EMPLOYEE_RESOLVED = "VIEW_EMPLOYEE_RESOLVED";

export interface ViewEmployeeResolved {
  type: typeof VIEW_EMPLOYEE_RESOLVED;
    payload: any;
}

export const VIEW_EMPLOYEE_REJECTED = "VIEW_EMPLOYEE_REJECTED";

export interface ViewEmployeeRejected {
  type: typeof VIEW_EMPLOYEE_REJECTED;
  payload: object;
}

/** UPDATE_EMPLOYEE */
export const HANDLE_UPDATE_EMPLOYEE = "HANDLE_UPDATE_EMPLOYEE";

export interface HandleUpdateEmployee {
  type: typeof HANDLE_UPDATE_EMPLOYEE;
  payload: { employees: IEmployee[]; callback: () => void };
}

export const UPDATE_EMPLOYEE_PENDING = "UPDATE_EMPLOYEE_PENDING";

export interface UpdateEmployeePending {
  type: typeof UPDATE_EMPLOYEE_PENDING;
}

export const UPDATE_EMPLOYEE_RESOLVED = "UPDATE_EMPLOYEE_RESOLVED";

export interface UpdateEmployeeResolved {
    type: typeof UPDATE_EMPLOYEE_RESOLVED;
    payload: IEmployee;
}

export const UPDATE_EMPLOYEE_REJECTED = "UPDATE_EMPLOYEE_REJECTED";

export interface UpdateEmployeeRejected {
  type: typeof UPDATE_EMPLOYEE_REJECTED;
  payload: object;
}

export const SET_EMPLOYEE_PASSWORD_ERROR = "SET_EMPLOYEE_PASSWORD_ERROR";

export interface SetEmployeePasswordError {
  type: typeof SET_EMPLOYEE_PASSWORD_ERROR;
  payload: string;
}

export const CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED =
  "CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED";

export interface ClearCurrPageInGroupsAssignAndAssigned {
  type: typeof CLEAR_CURR_PAGE_IN_GROUPS_ASSIGN_AND_ASSIGNED;
}

/** GET EMPLOYEE TASKS */
export const HANDLE_GET_EMPLOYEE_TASKS = "HANDLE_GET_EMPLOYEE_TASKS";

export interface HandleGetEmployeeTasks {
  type: typeof HANDLE_GET_EMPLOYEE_TASKS;
  payload: {employeeId: number, isUserMaster: boolean};
}

export const GET_EMPLOYEE_TASKS_PENDING = "GET_EMPLOYEE_TASKS_PENDING";

export interface GetEmployeeTasksPending {
  type: typeof GET_EMPLOYEE_TASKS_PENDING;
}

export const GET_EMPLOYEE_TASKS_RESOLVED = "GET_EMPLOYEE_TASKS_RESOLVED";

export interface GetEmployeeTasksResolved {
  type: typeof GET_EMPLOYEE_TASKS_RESOLVED;
  payload: ITasks;
}

export const GET_EMPLOYEE_TASKS_REJECTED = "GET_EMPLOYEE_TASKS_REJECTED";

export interface GetEmployeeTasksRejected {
  type: typeof GET_EMPLOYEE_TASKS_REJECTED;
  payload: object;
}

/** GET EMPLOYEE ASSIGNED TO PROSPECT */
export const HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT =
  "HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT";

export interface HandleGetEmployeesAssignedProspect {
  type: typeof HANDLE_GET_EMPLOYEES_ASSIGNED_PROSPECT;
  payload: {
    prospectId: number;
    assignedPagination: IPagination;
    unassignedPagination: IPagination;
  };
}

export const EMPLOYEES_ASSIGNED_PROSPECT_PENDING =
  "EMPLOYEES_ASSIGNED_PROSPECT_PENDING";

export interface EmployeesAssignedProspectPending {
  type: typeof EMPLOYEES_ASSIGNED_PROSPECT_PENDING;
}

export const EMPLOYEES_ASSIGNED_PROSPECT_RESOLVED =
  "EMPLOYEES_ASSIGNED_PROSPECT_RESOLVED";

export interface EmployeesAssignedProspectResolved {
  type: typeof EMPLOYEES_ASSIGNED_PROSPECT_RESOLVED;
  payload: IEmployeesProspect;
}

export const EMPLOYEES_ASSIGNED_PROSPECT_REJECTED =
  "EMPLOYEES_ASSIGNED_PROSPECT_REJECTED";

export interface EmployeesAssignedProspectRejected {
  type: typeof EMPLOYEES_ASSIGNED_PROSPECT_REJECTED;
  payload: object;
}

/** EMPLOYEE ACTIVATE **/
export const HANDLE_EMPLOYEE_ACTIVATE = "HANDLE_EMPLOYEE_ACTIVATE";

export interface HandleEmployeeActivate {
    type: typeof HANDLE_EMPLOYEE_ACTIVATE;
    payload: { id: number, isActive: boolean };
}

export const EMPLOYEE_ACTIVATE_PENDING = "EMPLOYEE_ACTIVATE_PENDING";

export interface EmployeeActivatePending {
    type: typeof EMPLOYEE_ACTIVATE_PENDING;
}

export const EMPLOYEE_ACTIVATE_REJECTED =
    "EMPLOYEE_ACTIVATE_REJECTED";

export interface EmployeeActivateRejected {
    type: typeof EMPLOYEE_ACTIVATE_REJECTED;
    payload: object;
}

export const EMPLOYEE_ACTIVATE_RESOLVED =
    "EMPLOYEE_ACTIVATE_RESOLVED";

export interface EmployeeActivateResolved {
    type: typeof EMPLOYEE_ACTIVATE_RESOLVED;
}

/** SEND INVITE **/
export const HANDLE_SEND_INVITE = "HANDLE_SEND_INVITE";

export interface HandleSendInvite {
    type: typeof HANDLE_SEND_INVITE;
    payload: { id: number};
}

export const SEND_INVITE_PENDING = "SEND_INVITE_PENDING";

export interface SendInvitePending {
    type: typeof SEND_INVITE_PENDING;
}

export const SEND_INVITE_REJECTED = "SEND_INVITE_REJECTED";

export interface SendInviteRejected {
    type: typeof SEND_INVITE_REJECTED;
    payload: object;
}

export const SEND_INVITE_RESOLVED =
    "SEND_INVITE_RESOLVED";

export interface SendInviteResolved {
    type: typeof SEND_INVITE_RESOLVED;
}

/** EMPLOYEE_PERMISSIONS */
export const HANDLE_SAVE_EMPLOYEE_PERMISSIONS = "HANDLE_SAVE_EMPLOYEE_PERMISSIONS";

export interface HandleSaveEmployeePermissions {
    type: typeof HANDLE_SAVE_EMPLOYEE_PERMISSIONS;
    payload: {
        id: string;
        permissions: any;
        callback: (res) => void;
    };
}

export const HANDLE_GET_EMPLOYEE_MANAGERS = "HANDLE_GET_EMPLOYEE_MANAGERS";

export interface HandleGetEmployeeManagers {
    type: typeof HANDLE_GET_EMPLOYEE_MANAGERS;
    payload: any;
}
/***Get sales and Finance Employee ***/
export const HANDLE_GET_EMPLOYEES_SALE_FINANCE = "HANDLE_GET_EMPLOYEES_SALE_FINANCE";

export interface HandleGetEmployeeSaleFinance {
  type: typeof HANDLE_GET_EMPLOYEES_SALE_FINANCE;
  payload: {
    isActive: number;
    role?: string;
    callback: (res) => void;
  };
}

export const HANDLE_GET_EMPLOYEES_FINANCE = "HANDLE_GET_EMPLOYEES_FINANCE";

export interface HandleGetEmployeeFinance {
  type: typeof HANDLE_GET_EMPLOYEES_FINANCE;
  payload: {
    isActive: number;
    limit: number;
    page: number;
    withoutGroup?: number;
    withGroup?: number;
    withProspect?: number;
    withoutProspect?: number;
    sort?: string;
    column?: string;
    role?: string;
    departmentId?: string;
  };
}

export const GET_EMPLOYEES_FINANCE_PENDING = "GET_EMPLOYEES_FINANCE_PENDING";

export interface GetEmployeesFinancePending {
  type: typeof GET_EMPLOYEES_FINANCE_PENDING;
}

export const GET_EMPLOYEES_FINANCE_RESOLVED = "GET_EMPLOYEES_FINANCE_RESOLVED";

export interface GetEmployeesFinanceResolved {
  type: typeof GET_EMPLOYEES_FINANCE_RESOLVED;
  payload: IPaginationResponse<IEmployee>;
}

export const GET_EMPLOYEES_FINANCE_REJECTED = "GET_EMPLOYEES_FINANCE_REJECTED";

export interface GetEmployeesFinanceRejected {
  type: typeof GET_EMPLOYEES_FINANCE_REJECTED;
  payload: object[];
}

/** Monthly Calendar */
export const HANDLE_EMPLOYEE_GET_MONTH_CAL = "HANDLE_EMPLOYEE_GET_MONTH_CAL";

export interface HandleEmployeeGetMonthCal {
    type: typeof HANDLE_EMPLOYEE_GET_MONTH_CAL;
    payload: {
        data: any;
        callback: (res) => void;
    };
}

export const HANDLE_EMPLOYEE_TMP_AVAIL = "HANDLE_EMPLOYEE_TMP_AVAIL";

export interface HandleEmployeeTmpAvail {
    type: typeof HANDLE_EMPLOYEE_TMP_AVAIL;
    payload: {
        id: string;
        method: string;
        data: any;
        callback: (res) => void;
    };
}

export const HANDLE_EMPLOYEE_TIME_OFF = "HANDLE_EMPLOYEE_TIME_OFF";

export interface HandleEmployeeTimeOff {
    type: typeof HANDLE_EMPLOYEE_TIME_OFF;
    payload: {
        id: string;
        method: string;
        data: any;
        callback: (res) => void;
    };
}

export const HANDLE_EMPLOYEE_DELETE_EVENTS = "HANDLE_EMPLOYEE_DELETE_EVENTS";

export interface HandleEmployeeDeleteEvents {
    type: typeof HANDLE_EMPLOYEE_DELETE_EVENTS;
    payload: {
        id: string;
        eventId: any;
        callback: (res) => void;
    };
}

/***  Weekly Hours ***/
export const HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS = "HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS";

export interface HandleSaveEmployeeWeeklyHours {
    type: typeof HANDLE_SAVE_EMPLOYEE_WEEKLY_HOURS;
    payload: {
        id: string;
        weeklyHours: string[];
        callback: (res) => void;
    };
}

export const HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS = "HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS";

export interface HandleViewEmployeeWeeklyHours {
  type: typeof HANDLE_VIEW_EMPLOYEE_WEEKLY_HOURS;
  payload: any;
}

export const VIEW_EMPLOYEE_WEEKLY_HOURS_PENDING = "VIEW_EMPLOYEE_WEEKLY_HOURS_PENDING";

export interface ViewEmployeeWeeklyHoursPending {
  type: typeof VIEW_EMPLOYEE_WEEKLY_HOURS_PENDING;
}

export const VIEW_EMPLOYEE_WEEKLY_HOURS_RESOLVED = "VIEW_EMPLOYEE_WEEKLY_HOURS_RESOLVED";

export interface ViewEmployeeWeeklyHoursResolved {
  type: typeof VIEW_EMPLOYEE_WEEKLY_HOURS_RESOLVED;
    payload: any;
}

export const VIEW_EMPLOYEE_WEEKLY_HOURS_REJECTED = "VIEW_EMPLOYEE_WEEKLY_HOURS_REJECTED";

export interface ViewEmployeeWeeklyHoursRejected {
  type: typeof VIEW_EMPLOYEE_WEEKLY_HOURS_REJECTED;
  payload: object;
}

export type EmployeesActions =
    | ClearCurrPageInGroupsAssignAndAssigned
    | HandleGetEmployees
    | GetEmployeesPending
    | GetEmployeesResolved
    | GetEmployeesRejected
    | HandleGetEmployeesForAddGroupAssignEmployee
    | GetEmployeesForAddGroupAssignEmployeeResolved
    | GetEmployeesForAddGroupAssignEmployeeRejected
    | GetEmployeesForAddGroupAssignEmployeePending
    | HandleGetEmployeesForAddGroupAssignedEmployee
    | GetEmployeesForAddGroupAssignedEmployeeRejected
    | GetEmployeesForAddGroupAssignedEmployeeResolved
    | GetEmployeesForAddGroupAssignedEmployeePending
    | HandleCreateEmployee
    | CreateEmployeePending
    | CreateEmployeeResolved
    | CreateEmployeeRejected
    | HandleDeleteEmployee
    | DeleteEmployeePending
    | DeleteEmployeeResolved
    | DeleteEmployeeRejected
    | HandleUpdateEmployee
    | UpdateEmployeePending
    | UpdateEmployeeRejected
    | UpdateEmployeeResolved
    | ViewEmployeePending
    | ViewEmployeeRejected
    | ViewEmployeeResolved
    | HandleViewEmployee
    | SetRowsPerPage
    | SetRowsPerPageForAddGroupAssignedEmployee
    | SetRowsPerPageForAddGroupAssignEmployee
    | SetEmployeePasswordError
    | SetSortEmployees
    | HandleGetEmployeeTasks
    | GetEmployeeTasksPending
    | GetEmployeeTasksResolved
    | GetEmployeeTasksRejected
    | HandleGetEmployeesAssignedProspect
    | EmployeesAssignedProspectPending
    | EmployeesAssignedProspectResolved
    | EmployeesAssignedProspectRejected
    | HandleEmployeeActivate
    | EmployeeActivatePending
    | EmployeeActivateRejected
    | EmployeeActivateResolved
    | SendInvitePending
    | HandleSendInvite
    | SendInviteRejected
    | SendInviteResolved
    | HandleSaveEmployeePermissions
    | HandleGetEmployeeManagers
    | HandleGetEmployeeSaleFinance
    | HandleGetEmployeeFinance
    | GetEmployeesFinancePending
    | GetEmployeesFinanceResolved
    | GetEmployeesFinanceRejected
    | HandleSaveEmployeeWeeklyHours
    | ViewEmployeeWeeklyHoursPending
    | ViewEmployeeWeeklyHoursRejected
    | ViewEmployeeWeeklyHoursResolved
    | HandleViewEmployeeWeeklyHours
    | HandleEmployeeGetMonthCal
    | HandleEmployeeTmpAvail
    | HandleEmployeeTimeOff
    | HandleEmployeeDeleteEvents

