import React, { useEffect, useState } from "react";
import { PrimaryButton, Checkbox, Link } from "@fluentui/react";
import {
    TextField as OffTextField
} from "@fluentui/react";
import { Col, Row } from "react-grid-system";
import { Calendar } from 'primereact/calendar';
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    handleGetTask,
    handleCreateTask,
    handleUpdateTaskStatus,
    handleSaveTaskNote,
    handleGetTaskNotes,
    handleGetTaskByDeal
} from "store/dealTracker/dealTracker.action";
import { HANDLE_GET_EMPLOYEES } from "store/employees/employees.types";
import { AppState } from "store";
import masterDropdownValues from "constants/masterDropdownValues";
import { useMediaQuery } from "react-responsive";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { EditShopWorkBookMoreActionSubmenu } from "components/table/inventory/EditShopWorkBookMoreActionSubmenu";
import { convertUTCToLocalDateOnlyModified, convertUTCToLocalFormatModified } from "utils/dateTimeFunctions";
import AddCircle from '@mui/icons-material/AddCircle';
import { isUserMaster } from "utils/permissions/permissionsHelpers";
import { dealTrackerOperationPermissions } from "constants/constants";
import { classNames } from "primereact/utils";

interface IProps {
    dealId: string;
    onError: (msg) => void;
    onFormikChange: (initial, newData) => void;
    saveFormOnRoute: boolean;
    dismissSaveNot: (msg) => void;
    updateSectionIndex: (id) => void;
    setSectionIndx: any;
    productId:any;
    tabRoute: boolean;
    userProfile: any;
}

export const Task: React.FunctionComponent<IProps> = ({
    dealId, onError, onFormikChange, saveFormOnRoute, dismissSaveNot, updateSectionIndex, setSectionIndx, productId, tabRoute, userProfile
}) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const dispatch = useDispatch();
    const employees = useSelector((state: AppState) => state.employees.employees) as any;

    const yearRange = new Date().getFullYear() + ":" + (new Date().getFullYear() + 1);
    const [startDate, setStartDate] = useState<Date | Date[] | undefined |any>(undefined);
    const [dueDate, setDueDate] = useState<Date | Date[] | undefined |any>(undefined);
    const [sectionIndex, setSectionIndex] = React.useState(null);
    const [taskInfo, setTaskInfo] = useState(null);
    const [isAssignedByDepartmentChange, setIsAssignedByDepartmentChange] = useState(null);
    const [notes, setNotes] = React.useState(null);
    const [taskStatus, setTaskStatus] = React.useState(null);
    const [taskActualStatus, setTaskActualStatus] = React.useState(null);
    const [notesList, setNotesList] = React.useState([]);
    const [byEmployees, setByEmployees] = React.useState([]);
    const [toEmployees, setToEmployees] = React.useState([]);
    const [isTaskUpdate, setTaskUpdate] = React.useState(false);
    //const [isByEmployeeList, setIsByEmployeeList] = React.useState(false);
    const [diasbleCompleteBtn, setDiasbleCompleteBtn] = React.useState(true);
    const [isUserHasPolicy, setIsUserHasPolicy] = React.useState(false);
    //const [isToEmployeeList, setIsToEmployeeList] = React.useState(false);

    useEffect(() => {
        if (isUserMaster(userProfile.roles)) {
            setIsUserHasPolicy(true);
        }
        else if (userProfile.aggregatedDefaultPolicies && userProfile.aggregatedDefaultPolicies.length) {
            const valuationFlag = userProfile.aggregatedDefaultPolicies.some(element => element === (dealTrackerOperationPermissions.DEAL_TASK_IS_COMPLETED?.toString()));
            setIsUserHasPolicy(valuationFlag);
        }

        if (productId && !tabRoute) {
            handleGetTaskDetails(productId);
            setTaskUpdate(true);
        } else {
            //dispatch<any>(handleGetTaskByDeal({ id: dealId, callback: (res) => handleGetTasksResponse(res) }));
            setSectionIndex(1);
            updateSectionIndex(1);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (taskInfo) {
            if ((taskInfo?.assignedBy?.id && userProfile.id === taskInfo?.assignedBy?.id) || isUserHasPolicy) {
                setDiasbleCompleteBtn(false);
            } else {
                setDiasbleCompleteBtn(true);
            }
        }

    }, [taskInfo]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSectionIndex(setSectionIndx);
        if (setSectionIndx === 1) {
            resetFormik();
            dispatch<any>(handleGetTaskByDeal({ id: dealId, callback: (res) => handleGetTasksResponse(res) }));
        }
            
    }, [setSectionIndx]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {        
        if (employees && employees.results && employees.results.length) {
            for (var i in employees.results) {
                employees.results[i]["fullName"] = employees.results[i].firstName + ' ' + employees.results[i].lastName;
            }
                if (isAssignedByDepartmentChange ) {
                   setByEmployees(employees.results);  
                } 
                else if (isAssignedByDepartmentChange === false){
                   setToEmployees(employees.results);
                }
        }else{
               if (isAssignedByDepartmentChange) {
                  setByEmployees(null);    
               } else if (isAssignedByDepartmentChange === false){
                  setToEmployees(null);
               }
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (saveFormOnRoute) {
            if (sectionIndex === 2) {
                taskFormik.handleSubmit();
                if (!taskFormik.dirty || !taskFormik.isValid) {
                    dismissSaveNot('no');
                }
            } else if (sectionIndex === 3) {
                handleSaveStatusAndNotes();
            }            
        }
    }, [saveFormOnRoute]);// eslint-disable-line react-hooks/exhaustive-deps

    const taskFormik = useFormik({
        initialValues: {
            startDate: new Date(),
            dueDate: "",
            assignedByDepartmentId: "",
            assignedByEmployeeId: "",
            assignedToDepartmentId: "",
            assignedToEmployeeId: "",
            description: ""
        },
        validationSchema: Yup.object({
            startDate: Yup.string().required("Required"),
            dueDate: Yup.string().required("Required"),
            assignedByDepartmentId: Yup.string().required("Required"),
            assignedByEmployeeId: Yup.string().required("Required"),
            assignedToDepartmentId: Yup.string().required("Required"),
            assignedToEmployeeId: Yup.string().required("Required"),
            description: Yup.string().required("Required")
        }),
        onSubmit: (values) => {
            dispatch<any>(handleCreateTask({
                task: values, id: dealId, callback: (res) => {
                    if (res) {
                        dismissSaveNot('yes');
                        onFormikChange(1, 1);
                        setTaskInfo(res);
                        setTaskUpdate(true);
                        setTaskStatus(res.isCompleted);
                        setTaskActualStatus(res.isCompleted);
                        setNotesList(null);
                        setSectionIndex(3);
                        //updateSectionIndex(3);
                    } else {
                        dismissSaveNot('no');
                        onError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    }
                }
            }));
        }
    });

    useEffect(() => {
        if (taskFormik.values?.description) {
            onFormikChange(1, 2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskFormik.values?.description]);

    const handleDateChange = (val, propName) => {
        taskFormik.setValues(
            {
                ...taskFormik.values,
                [propName]: moment(val).format("YYYY-MM-DD")
            }
        );

        onFormikChange(1, 2);

        if (propName === "startDate") {
            setStartDate(val)
        } else {
            setDueDate(val)
        }
    };

    const handleTaskFormikDDChange = (e: { value: any, target: any }) => {
        taskFormik.setValues(
            {
                ...taskFormik.values,
                [e.target.id]: e.value,
            }
        );
        onFormikChange(1, 2);
    }

    const handleDepartmentChange = (e: { value: any, target: any }) => {
        taskFormik.setValues(
            {
                ...taskFormik.values,
                [e.target.id]: e.value,
            }
        );
        onFormikChange(1, 2);

        setIsAssignedByDepartmentChange(e.target.id === 'assignedByDepartmentId');

        if(e.target.id === 'assignedByDepartmentId'){

             dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, departmentId: e.value } })
             //setIsByEmployeeList(true)

        }

        if(e.target.id === 'assignedToDepartmentId'){

             dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, departmentId: e.value } })
            //setIsToEmployeeList(true)
        }
        
    }

    const handleTaskFormikSumbit = () => {
        taskFormik.handleSubmit();
    };

    const handleSaveStatusAndNotes = () => {
        if (notes && notes.length > 0) {
            handleAddNotes();
        }
        if (taskStatus !== taskActualStatus) {
            dispatch<any>(handleUpdateTaskStatus({
                taskStatus: { isCompleted: taskStatus }, id: dealId, taskId: taskInfo.id, callback: (res) => {
                    if (!res) {
                        onError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    } else {
                        setTaskUpdate(true);
                        dismissSaveNot('yes');
                    }
                }
            }));
        }
    };

    const handleAddNotes = () => {
        dispatch<any>(handleSaveTaskNote({
            id: dealId,
            taskId: taskInfo.id,
            note: {
                notes: notes
            },
            callback: (response) => {
                if (response) {
                    setNotes(null);
                    onFormikChange(1, 1);
                    setTaskUpdate(true);
                    getNotes(taskInfo.id);
                    if (taskStatus === taskActualStatus)
                        dismissSaveNot('yes');
                } else {
                    onError('Failed to Save the Data.');
                }
            }
        }));
    }

    const getNotes = (taskId) => {
        dispatch<any>(handleGetTaskNotes({
            id: dealId,
            taskId: taskId,
            callback: (response) => {
                if (response)
                    setNotesList(response.results);
                else
                    onError('Failed to Save the Data.');
            }
        }));
    }

    const handleNoteChange = (e, newValue) => {
        onFormikChange(1, 2);
        setNotes(newValue);
    }

    const handleTaskStatusChange = (e, isChecked) => {
        setTaskStatus(isChecked);
        onFormikChange(1, 2);
    };

    //Task Workbook Block
    const [taskData, setTaskData] = useState([]);

    const handleGetTasksResponse = (res) => {
        if (res && res.results) {
            let taskWorkbook = []
            res.results.forEach(function (task, index) {
                let taskObj = {
                    key: '' + index,
                    data: {
                        startDate: convertUTCToLocalDateOnlyModified(task.startDate), //moment(task.startDate).format("MM/DD/YYYY"),
                        dueDate: convertUTCToLocalDateOnlyModified(task.dueDate), //moment(task.dueDate).format("MM/DD/YYYY"),
                        description: task.description,
                        assignedBy: task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
                        assignedTo: task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
                        status: task.isCompleted ? "Complete" : null,
                        assignedByDept: getDeptName(task.assignedByDepartmentId),
                        assignedToDept: getDeptName(task.assignedToDepartmentId),
                        taskId: task.id
                    },
                    children: getChildRow(task.dealTaskNotes, index, task.id)
                }
                taskWorkbook.push(taskObj);
            });
            setTaskData(taskWorkbook);
        } else {
            onError('Failed to Fetch The Data.');
        }
    };

    const getChildRow = (notes, index, id) => {
        if (notes && notes.length > 0) {
            let taskNotesWorkbook = []
            notes.forEach(function (item, i) {
                let taskObj = {
                    key: index + '-' + i,
                    data: {
                        startDate: null,
                        dueDate: convertUTCToLocalFormatModified(item.createdAt, "MM/DD hh:mm A"),
                        description: (item.employee?.firstName + ' ' + item.employee?.lastName) + ': ' + item.notes,
                        dealNumber: null,
                        customer: null,
                        assignedBy: null,
                        assignedTo: null,
                        status: null,
                        assignedByDept: null,
                        assignedToDept: null,
                        taskId: id
                    }
                }
                taskNotesWorkbook.push(taskObj);

            });
            return taskNotesWorkbook;
        } else
            return [];

    };

    const getDeptName = (id) => {
        let item = masterDropdownValues.departmentOptions.filter(x => x.key === id);
        if (item.length === 1) {
            return item[0].text;
        }
        return '';
    };

    const handleGetTaskDetails = (taskId) => {
        setTaskActualStatus(null);
        dispatch<any>(handleGetTask({
            dealId, taskId, callback: (res) => {
                if (res) {
                    if (res.noContentStatus && res.noContentStatus === 204) {
                        setTaskInfo(null)
                    } else {
                        setTaskInfo(res);
                        setTaskStatus(res.isCompleted);
                        setTaskActualStatus(res.isCompleted);
                        getNotes(res.id);                        
                        setSectionIndex(3);
                        //updateSectionIndex(3);
                    }
                } else {
                    onError('Failed to Fetch the Data.');
                    window.scrollTo(0, 0);
                    setTaskInfo(null);
                }

            }
        }));
    }

    const handleRowClick = (event) => {
        setTaskUpdate(false);
        let target = event.originalEvent.target;        
        if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
            (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
            target.lastElementChild.lastElementChild.firstElementChild.click();
        } else {
            updateSectionIndex(3);
            handleGetTaskDetails(event.node.data.taskId);
        }
    }

    const rowClassName = (node) => {
        return { 'childRow': (node.key && node.key.includes('-')) };
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="contextMenu">
                    <EditShopWorkBookMoreActionSubmenu
                        disableIcon={false}
                        reportType={rowData}
                        onEdit={(reportType) => {
                            updateSectionIndex(3);
                            handleGetTaskDetails(reportType.data.taskId);
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    const resetFormik = () => {
        taskFormik.setValues(
            {
                ...taskFormik.values,
                startDate: new Date(),
                dueDate: "",
                assignedByDepartmentId: "",
                assignedByEmployeeId: "",
                assignedToDepartmentId: "",
                assignedToEmployeeId: "",
                description: ""
            }
        );
        setNotesList(null);
    }

    const handleCreateNewTask = () => {
        setTaskUpdate(false);
        setStartDate(new Date());
        setDueDate(null);
        setTaskActualStatus(null);
        if (userProfile && userProfile.departmentId && masterDropdownValues.departmentOptions.some(x => x.key === userProfile.departmentId)) {
            taskFormik.setValues(
                {
                    ...taskFormik.values,
                    assignedByDepartmentId: userProfile.departmentId,
                    assignedByEmployeeId: userProfile.id,
                }
            );
            setIsAssignedByDepartmentChange(true);
            dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, departmentId: userProfile.departmentId } })
            //setIsByEmployeeList(true);
            //setIsToEmployeeList(true);
        }        
        setSectionIndex(2);
        updateSectionIndex(2);
    }

    const header1 = (
        <>
            <Link
                color="inherit"
                onClick={handleCreateNewTask}
                className="plusbutton-Svgicon"
            >
                <AddCircle
                    style={{ color: "#70af60" }}
                    className="plus-icon svgPlus-icon" />
					Create Task
			</Link>

        </>
    );

    const handleShowGrid = () => {
        if (isTaskUpdate) {
            //dispatch<any>(handleGetTaskByDeal({ id: dealId, callback: (res) => handleGetTasksResponse(res) }));
            setSectionIndex(1);
            updateSectionIndex(1);
        }else {
            setSectionIndex(1);
            dismissSaveNot('yes');
            updateSectionIndex(1);
        }   
        onFormikChange(1, 1);
    }

    const togglerTemplate = (node, options) => {
        if (!node) {
            return;
        }

        const expanded = options.expanded;
        const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
            'pi-plus-circle': !expanded,
            'pi-minus-circle': expanded
        });

        return (
            <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{color:'#ffffff',backgroundColor:'#0065cb'}} tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    return (   
        <>
            {
                sectionIndex === 1 && (
                    <>
                        <div className="taskWorkBookPagenator datatable-filter-demo noMaxWidthTable cust-tree-tbl task-workbook">
                            <div className="card reportsTableVal">
                                <TreeTable
                                    value={taskData}
                                    onRowClick={(event) => handleRowClick(event)}
                                    selectionMode="single"
                                    header={header1}
							        paginator rows={25}
							        rowsPerPageOptions={[25,50,100]}
                                    togglerTemplate={togglerTemplate}
                                    rowClassName={rowClassName} >
                                    <Column sortable field="startDate" style={{ width: '9em' }} header="Start Date" expander></Column>
                                    <Column sortable field="dueDate" style={{ width: '9em' }} header="Due Date"></Column>
                                    <Column sortable field="description" className="textAlignStart" style={{ width: '28em',textAlign:"left",paddingLeft:"5px" }} header="Description"></Column>
                                    <Column sortable field="assignedByDept" header="Assigned By Dept"></Column>
                                    <Column sortable field="assignedBy" header="Assigned By"></Column>
                                    <Column sortable field="assignedToDept" header="Assigned To Dept"></Column>
                                    <Column sortable field="assignedTo" header="Assigned to"></Column>
                                    <Column sortable field="status" style={{ width: '6em' }} header="Status"></Column>
                                    <Column field="actions" style={{ width: '5em' }} header="Actions" body={actionBodyTemplate}></Column>
                                </TreeTable>
                            </div>
                        </div>

                    </>)
            }
            {
                sectionIndex === 2 && (<>
                    <div className="deal-tracker-task-view">
                        {
                            isMobile ?
                                
                                <Row>
                                    <Col>
                                        <div className="date-picker">
                                            <label style={{ display: "block" }} className="lbl-text required-Label">Start Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                <Calendar
                                                    id="startDate"
                                                    value={startDate}
                                                    style={{ display: "flex" }}
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e.value, 'startDate')}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="date-picker">
                                            <label style={{ display: "block" }} className="lbl-text required-Label">Due Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                <Calendar
                                                    id="dueDate"
                                                    value={dueDate}
                                                    style={{ display: "flex" }}
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e.value, 'dueDate')}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col md={6}>
                                        <div className="date-group">
                                            <div className="date-picker">
                                                <label style={{ display: "block" }} className="lbl-text required-Label">Start Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                    <Calendar
                                                        id="startDate"
                                                        value={startDate}
                                                        style={{ display: "flex" }}
                                                        showIcon
                                                        onChange={(e) => handleDateChange(e.value, 'startDate')}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="date-picker">
                                                <label style={{ display: "block" }} className="lbl-text required-Label">Due Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                    <Calendar
                                                        id="dueDate"
                                                        value={dueDate}
                                                        style={{ display: "flex" }}
                                                        showIcon
                                                        onChange={(e) => handleDateChange(e.value, 'dueDate')}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col>
                                <label className="lbl-text required-Label">Assigned By Department</label>
                                <PRDropdown
                                    id="assignedByDepartmentId"
                                    placeholder=""
                                    value={taskFormik.values.assignedByDepartmentId}
                                    options={masterDropdownValues.departmentOptions}
                                    onChange={handleDepartmentChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />

                            </Col>
                            <Col>
                                <label className="lbl-text required-Label">Assigned By</label>
                                <PRDropdown
                                    id="assignedByEmployeeId"
                                    placeholder=""
                                    value={taskFormik.values.assignedByEmployeeId}
                                    options={byEmployees}
                                    onChange={handleTaskFormikDDChange}
                                    optionLabel="fullName"
                                    optionValue="id"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="lbl-text required-Label">Assigned To Department</label>
                                <PRDropdown
                                    id="assignedToDepartmentId"
                                    placeholder=""
                                    value={taskFormik.values.assignedToDepartmentId}
                                    options={masterDropdownValues.departmentOptions}
                                    onChange={handleDepartmentChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />

                            </Col>
                            <Col>
                                <label className="lbl-text required-Label">Assigned To</label>
                                <PRDropdown
                                    id="assignedToEmployeeId"
                                    placeholder=""
                                    value={taskFormik.values.assignedToEmployeeId}
                                    options={toEmployees}
                                    onChange={handleTaskFormikDDChange}
                                    optionLabel="fullName"
                                    optionValue="id"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                        </Row>
                        <Row className="rowPaddingBtwSpace">
                            <Col>
                                <OffTextField
                                    style={{ minHeight: "140px" }}
                                    id="description"
                                    name="description"
                                    label="Description"
                                    multiline
                                    required={true}
                                    resizable={false}
                                    className="full-width"
                                    value={taskFormik.values.description}
                                    onChange={taskFormik.handleChange}
                                    onBlur={taskFormik.handleBlur}
                                    errorMessage={
                                        taskFormik.touched.description &&
                                        taskFormik.errors.description &&
                                        taskFormik.errors.description.toString()
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <PrimaryButton onClick={handleShowGrid}>Cancel</PrimaryButton>
                            </Col>
                            <Col className="text-right">
                                <PrimaryButton style={{ float: "right" }}
                                    disabled={!startDate ||
                                        !dueDate ||
                                        !taskFormik.values.assignedByDepartmentId ||
                                        !taskFormik.values.assignedByEmployeeId ||
                                        !taskFormik.values.assignedToDepartmentId ||
                                        !taskFormik.values.assignedToEmployeeId ||
                                        !taskFormik.values.description}
                                    onClick={handleTaskFormikSumbit}
                                >
                                    Save
                         </PrimaryButton>
                            </Col>
                        </Row>
                    </div>
                </>)
            }

            {
                sectionIndex === 3 && (<>
                    <div className="deal-tracker-task-edit">
                        {
                            isMobile ?
                                <>
                                    <Row className="rowPaddingBtwSpace">
                                        <Col>
                                            <div className="task-info-row">
                                                <div>Start Date: { taskInfo?.startDate ? convertUTCToLocalDateOnlyModified(taskInfo.startDate) : undefined }</div>
                                                <div>Due Date: { taskInfo?.dueDate ? convertUTCToLocalDateOnlyModified(taskInfo.dueDate) : undefined }</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rowPaddingBtwSpace">
                                        <Col>
                                            <div>Assigned By: {taskInfo?.assignedBy?.firstName + ' ' + taskInfo?.assignedBy?.lastName}</div>
                                        </Col>
                                    </Row>
                                </> :
                                <Row className="rowPaddingBtwSpace">
                                    <Col>
                                        <div className="task-info-row">
                                                <div>Start Date: { taskInfo?.startDate ? convertUTCToLocalDateOnlyModified(taskInfo.startDate) : undefined }</div>
                                                <div>Due Date: { taskInfo?.dueDate ? convertUTCToLocalDateOnlyModified(taskInfo.dueDate) : undefined }</div>
                                            <div>Assigned By: {taskInfo?.assignedBy?.firstName + ' ' + taskInfo?.assignedBy?.lastName}</div>
                                        </div>
                                    </Col>
                                </Row>
                        }

                        <Row className="rowPaddingBtwSpace">
                            <Col>
                                <div className="task-info-row">
                                    <div className="task-comlete-box">
                                        <div>
                                            Complete?
                                            </div>
                                        <div>
                                            <Checkbox
                                                label=""
                                                id="taskStatus"
                                                onChange={handleTaskStatusChange}
                                                checked={taskStatus}
                                                disabled={diasbleCompleteBtn}
                                            />
                                        </div>
                                    </div>
                                    <div className="task-description"  style={{ wordBreak:"break-all"}}>
                                        {taskInfo?.description}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="rowPaddingBtwSpace">
                            <Col>
                                <div>Assigned To: {taskInfo?.assignedTo?.firstName + ' ' + taskInfo?.assignedTo?.lastName}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                                {notesList?.map((item) => (
                                    <div key={"boxes-" + item.id}>
                                        <Row style={{ padding: "0 20px" }}>
                                            <Col md={3}>{item.employee.firstName + ' ' + item.employee.lastName}</Col>
                                            <Col md={2}>{convertUTCToLocalDateOnlyModified(new Date(item.createdAt)).toString()}</Col>
                                            <Col md={7}>{item.notes}</Col>
                                        </Row>
                                        <hr />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <OffTextField
                                    style={{ minHeight: "140px" }}
                                    id="description"
                                    name="description"
                                    label=""
                                    multiline
                                    resizable={false}
                                    className="full-width"
                                    value={notes}
                                    onChange={(e, newValue) => handleNoteChange(e, newValue)}
                                />
                            </Col>
                        </Row>
                        <Row className="rowPaddingBtwSpace">
                            <Col>
                                <PrimaryButton onClick={handleShowGrid}>Cancel</PrimaryButton>
                            </Col>
                            <Col className="text-right">
                                <PrimaryButton style={{ float: "right" }}
                                    disabled={!notes && taskStatus === taskActualStatus}
                                    onClick={handleSaveStatusAndNotes}
                                >
                                    Save
                        </PrimaryButton>
                            </Col>
                        </Row>
                    </div>
                </>)
            }
        </>



    );
};

