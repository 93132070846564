import * as React from "react";
import { Callout } from "@fluentui/react/lib/Callout";
import { Stack, IStackTokens, IStackStyles, ITextFieldProps } from "@fluentui/react";
import { FontIcon } from "@fluentui/react";
import { colors } from "constants/constants";
import useId from "@mui/material/utils/useId";

const stackTokens: IStackTokens = {
	childrenGap: 4,
	// maxWidth: 300,
};

const labelCalloutStackStyles: Partial<IStackStyles> = {
	root: { padding: 10, paddingRight: 60 },
};
interface IProps {
	props: ITextFieldProps;
	content: string;
}
export const LabelInfoTooltip: React.FunctionComponent<IProps> = ({props, content}) => {

	const [isCalloutVisible, toggleIsCalloutVisible] = React.useState(false);

	const descriptionId: string = useId("description");
	const iconButtonId: string = useId("iconButton");
	return (
		<>
			<Stack
				horizontal
				verticalAlign="center"
				tokens={stackTokens}
			>
				<label className={props.required ? "lbl-text required-Label" : "lbl-text"}>{props.label}</label>
				<FontIcon
					style={{
						cursor: "pointer",
						marginLeft: 10,
						color: colors.primaryBlue,
					}}
					color={colors.primaryBlue}
					id={iconButtonId}
					iconName="Info"
					onMouseEnter={() => toggleIsCalloutVisible(true)}
					onMouseLeave={() => toggleIsCalloutVisible(false)}
				/>
			</Stack>
			{isCalloutVisible && (
				<Callout
					target={"#" + iconButtonId}
					setInitialFocus
					onDismiss={() => toggleIsCalloutVisible(false)}
					ariaDescribedBy={descriptionId}
					role="alertdialog"
					className="inputFieldTooltip"
				>
					<Stack
						tokens={stackTokens}
						horizontalAlign="start"
						styles={labelCalloutStackStyles}
					>
						<span>{content}</span>
					</Stack>
				</Callout>
			)}
		</>
	);
};