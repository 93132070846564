import * as actions from "store/deskADeal/deskADeal.types";
import { IDeskADeal } from "types/deskADealTypes";

/** CREATE_DESK_A_DEAL */
export const handleCreateDeskADeal = (
  deskADeal: IDeskADeal,
  prospectId: number | string,
  inventoryId: number | string
): actions.HandleCreateDeskADeal => {
  
  return {
    type: actions.HANDLE_CREATE_DESK_A_DEAL,
    payload: { deskADeal, prospectId, inventoryId },
  };
};

export const pendingCreateDeskADeal = (): actions.CreateDeskADealPending => {
  return {
    type: actions.CREATE_DESK_A_DEAL_PENDING,
  };
};

export const resolvedCreateDeskADeal = (
  deskADeal: IDeskADeal
): actions.CreateDeskADealResolved => {
  return {
    type: actions.CREATE_DESK_A_DEAL_RESOLVED,
    payload: deskADeal,
  };
};

export const rejectedCreateDeskADeal = (
  errors: any
): actions.CreateDeskADealRejected => {
  return {
    type: actions.CREATE_DESK_A_DEAL_REJECTED,
    payload: errors,
  };
};

/** GET_SAVED_DESK_A_DEALS */
export const handleGetSavedDeskADeals = (): actions.HandleGetSavedDeskADeals => {
  return {
    type: actions.HANDLE_GET_SAVED_DESK_A_DEALS,
  };
};

export const pendingGetSavedDeskADeals = (): actions.GetSavedDeskADealsPending => {
  return {
    type: actions.GET_SAVED_DESK_A_DEALS_PENDING,
  };
};

export const resolvedGetSavedDeskADeals = (
  deskADeals: IDeskADeal[]
): actions.GetSavedDeskADealsResolved => {
  return {
    type: actions.GET_SAVED_DESK_A_DEALS_RESOLVED,
    payload: deskADeals,
  };
};

export const rejectedGetSavedDeskADeals = (
  errors: any
): actions.GetSavedDeskADealsRejected => {
  return {
    type: actions.GET_SAVED_DESK_A_DEALS_REJECTED,
    payload: errors,
  };
};

/** DELETE_DESK_A_DEAL */
export const handleDeleteDeskADeal = (
  id: number,
  callback: () => void
): actions.HandleDeleteDeskADeal => {
  return {
    type: actions.HANDLE_DELETE_DESK_A_DEAL,
    payload: { id, callback },
  };
};

export const pendingDeleteDeskADeal = (): actions.DeleteDeskADealPending => {
  return {
    type: actions.DELETE_DESK_A_DEAL_PENDING,
  };
};

export const resolvedDeleteDeskADeal = (): actions.DeleteDeskADealResolved => {
  return {
    type: actions.DELETE_DESK_A_DEAL_RESOLVED,
  };
};

export const rejectedDeleteDeskADeal = (
  errors: any
): actions.DeleteDeskADealRejected => {
  return {
    type: actions.DELETE_DESK_A_DEAL_REJECTED,
    payload: errors,
  };
};

/** UPDATE_DESK_A_DEAL */
export const handleUpdateDeskADeal = (
  deskADeal: IDeskADeal,
  deskADealId: number | string,
  prospectId: number | string,
  inventoryId: number | string
): actions.HandleUpdateDeskADeal => {
  
  return {
    type: actions.HANDLE_UPDATE_DESK_A_DEAL,
    payload: { deskADeal, deskADealId, prospectId, inventoryId },
  };
};

export const pendingUpdateDeskADeal = (): actions.UpdateDeskADealPending => {
  return {
    type: actions.UPDATE_DESK_A_DEAL_PENDING,
  };
};

export const resolvedUpdateDeskADeal = (
  deskADeal: IDeskADeal
): actions.UpdateDeskADealResolved => {
  return {
    type: actions.UPDATE_DESK_A_DEAL_RESOLVED,
    payload: deskADeal,
  };
};

export const rejectedUpdateDeskADeal = (
  errors: any
): actions.UpdateDeskADealRejected => {
  return {
    type: actions.UPDATE_DESK_A_DEAL_REJECTED,
    payload: errors,
  };
};
