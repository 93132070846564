import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  FontIcon,
  ActionButton,
  IButtonStyles,
} from "@fluentui/react";
import { Pagination } from "components/table/pagination/Pagination";
import { connect } from "react-redux";
import { EditDealDialog } from "components/dialogs/deals/EditDealDialog";
import { DealTableDropdown } from "components/table/deals/dropdown/DealTableDropdown";
import { DealMoreActionsSubmenu } from "components/table/deals/dropdown/DealMoreActionsSubmenu";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { /**Image, ImageFit, */ mergeStyles } from "@fluentui/react";
import { formatDateInventory } from "utils/dateTimeFunctions";
import {
  HANDLE_GET_DEALS,
  SET_ROWS_PER_PAGE_DEALS,
  HANDLE_DELETE_DEALS,
  HANDLE_UPDATE_DEALS,
} from "store/deals/deals.types";
import { IDeal } from "types/dealTypes";
import { colourRed, colourBlack, cellLineHeight } from "constants/styles";
import {
  FavouriteAction,
  FavouriteEndpoints,
  getTypesStatuses,
  permissionsModules,
} from "constants/constants";
import { IInventory } from "types/inventoryTypes";
import { IProspect } from "types/prospectTypes";
import { HANDLE_UPDATE_FAVOURITE } from "store/shared/shared.types";
import { sortColumns } from "utils/helperFunctions";
import {
  getModulePermissions,
  isPermitted,
} from "utils/permissions/permissionsHelpers";
// import { ProspectTableDropdown } from "../prospects/dropdown/ProspectTableDropdown";
import { eTypes, WithCredentials } from "hoc/withCredentials";
import { IUserProfile } from "types/userProfileTypes";
import { IPermission } from "types/groupPermissionTypes";
//import { DealsStatuses } from "constants/constants";

const cellFontSize = 13;
const cellMaxHeight = "35px";
const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: "auto",
  },
};

const textStyles = mergeStyles({
  fontSize: 16,
});

export interface IDocument {
  id: number;
  key: number;
  createdAt: string;
  status: string;
  type: number;
  isSent: boolean;
  inventory: IInventory;
  prospect: IProspect;
  isFavourite: boolean;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 20,
    paddingTop: 20,
  },
};

const cellStyles: IStackStyles = {
  root: {
    //height: height,
    lineHeight: cellLineHeight,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

const iconStyle = {
  color: DefaultPalette.themePrimary,
  marginLeft: 7,
  fontSize: 16,
  height: "16px",
};

const cellStyle = {
  fontSize: cellFontSize,
};

interface IProps {
  items: IDeal[];
  totalItems: number;
  currentPage: number;
  rowsPerPage: number;
  column: string;
  sort: string;
  loading: boolean;
  permissions: IPermission[];
  user: IUserProfile;
  handleGetDeals: Function;
  setRowsPerPage: Function;
  handleDeleteDeals: Function;
  handleUpdateDeal: Function;
  handleUpdateFavourites: Function;
}

interface IState {
  items: IDeal[];
  columns: IColumn[];
  selectionDetails: any;
  hovered: number;
  iconHovered: any;
  selectedInHeaderDropDown: boolean;
  rowsPerPage: number;
  currentPage: number;
  isCreateDialogOpen: boolean;
  isEditDialogOpen: boolean;
  selectedDeal: IDeal;
  activeId: number;
  loading: boolean;
  isConfirmBoxOpen: boolean;
  selectedDeals: any;
  isDealsDialogOpen: boolean;
  isSchedulerOpen: boolean;
}

class DealsTable extends React.Component<IProps, IState> {
  private readonly _items: any;
  private readonly _selection: Selection;

  constructor(props: IProps) {
    super(props);

    this._items = [];

    const columns: any[] = [
      {
        key: "date",
        name: "Date",
        fieldName: "deal.createdAt",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: false,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {formatDateInventory(item.createdAt)}
            </span>
          );
        },
      },
      {
        key: "id",
        name: "Number",
        fieldName: "deal.id",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        data: "number",
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.id}
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "status",
        name: "Status",
        fieldName: "deal.status",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "string",
        onRender: (item: IDocument) => {
          return (
            <span
              className={textStyles}
              style={{
                ...(item.status === "active" ? colourBlack : colourRed),
                ...cellStyle,
              }}
            >
              {item.status}
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "type",
        name: "Type",
        fieldName: "deal.type",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        data: "number",
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {getTypesStatuses(item.type)}
            </span>
          );
        },
        isPadded: false,
      },

      {
        key: "isSent",
        name: "Offer",
        fieldName: "deal.isSent",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IDocument) => {
          return (
            <div style={{ justifyContent: "start" }}>
              {item.isSent ? "sent" : "not send"}
            </div>
          );
        },

        isPadded: false,
      },
      {
        key: "inventory",
        name: "Vehicle Model",
        fieldName: "inventory.model",
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.inventory.model}
              <FontIcon style={iconStyle} iconName="Car" />
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "customerName",
        name: "Customer Name",
        fieldName: "prospect.firstName",
        minWidth: 120,
        maxWidth: 170,
        isResizable: true,

        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.prospect &&
              item.prospect.firstName &&
              item.prospect.lastName ? (
                <>
                  {`${item.prospect.firstName} ${item.prospect.lastName}`}
                  <FontIcon style={iconStyle} iconName="ReminderPerson" />{" "}
                </>
              ) : (
                ""
              )}
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "customerPhone",
        name: "Phone",
        fieldName: "prospect.phone",
        minWidth: 140,
        maxWidth: 190,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.prospect && item.prospect.phone ? (
                <>
                  {item.prospect.phone}{" "}
                  <FontIcon style={iconStyle} iconName="Phone" />
                </>
              ) : (
                ""
              )}
            </span>
          );
        },
      },
      {
        key: "favorites",
        name: "Favorites",
        fieldName: "deal.isFavourite",
        minWidth: 60,
        maxWidth: 110,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          let iconName;
          let iconHoveredName;

          if (item.isFavourite) {
            iconName = "FavoriteStarFill";
            iconHoveredName = "Unfavorite";
          } else {
            iconName = "AddFavorite";
            iconHoveredName = "FavoriteStarFill";
          }

          return (
            <FontIcon
              onClick={() =>
                isPermitted(
                  this.props.permissions,
                  eTypes.WRITE,
                  permissionsModules.Deals,
                  this.props.user.roles
                )
                  ? this._handleUpdateIsFavourite(item)
                  : {}
              }
              onMouseEnter={() => {
                this.setState({
                  iconHovered: item.id,
                  columns: [...this.state.columns],
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  iconHovered: null,
                  columns: [...this.state.columns],
                });
              }}
              style={{
                fontSize: 22,
                color: DefaultPalette.themePrimary,
                display:
                  this.state.hovered === item.id || item.isFavourite
                    ? "flex"
                    : "none",
              }}
              iconName={
                this.state.iconHovered === item.id ? iconHoveredName : iconName
              }
            />
          );

          //return <span className={textStyles}>ICON</span>;
        },
        isPadded: false,
      },

      {
        key: "action",
        name: "Actions",
        fieldName: "action",
        minWidth: 100,
        maxWidth: 150,

        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: any, index: number) => {
          return (
            <>
              {/*<div id={`item_${item.id}`} />*/}
              <Stack
                horizontal
                horizontalAlign="start"
                style={{
                  width: "100%",
                  display: this.state.hovered === item.id ? "flex" : "none",
                  maxHeight: cellMaxHeight,
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  //tokens={{ childrenGap: 10 }}
                >
                  <WithCredentials
                    groupAuths={this.props.permissions}
                    type={eTypes.UPDATE}
                    alias={"Deals"}
                    roles={this.props.user.roles}
                  >
                    {/*// this.props.permissions, // eTypes.WRITE, //*/}
                    {/*permissionsModules.Deals, // this.props.user.roles>*/}
                    <Stack
                      horizontal
                      //tokens={{ childrenGap: 5 }}
                      onClick={() => this._handleEdit(item.id)}
                    >
                      <ActionButton
                        style={{
                          padding: 0,
                          margin: 0,
                          maxHeight: cellMaxHeight,
                        }}
                        styles={actionButton}
                        // onClick={() => this._handleView(item.id)}
                        iconProps={{ iconName: "Edit" }}
                      >
                        Edit
                      </ActionButton>
                    </Stack>
                  </WithCredentials>

                  <DealMoreActionsSubmenu
                    onDelete={() => this.setState({ isConfirmBoxOpen: true })}
                    setStatuses={(status) => this.handleSetStatuses(status)}
                    modulePermissions={this.props.permissions}
                    onEdit={() => {
                      console.log(item.id, index);
                      this._handleEdit(this.state.hovered);
                    }}
                  />
                </Stack>
              </Stack>
            </>
          );
        },
        isPadded: false,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._items,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      iconHovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      currentPage: 1,
      isCreateDialogOpen: false,
      isEditDialogOpen: false,
      selectedDeal: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      selectedDeals: null,
      isDealsDialogOpen: false,
      isSchedulerOpen: false,
    };

    console.log("PROSPECTS", this.props.items);
  }

  public componentDidMount() {
    // console.log('this.props.rowsPerPage', this.props.rowsPerPage);

    this.props.handleGetDeals({
      page: 1,
      limit: this.props.rowsPerPage,

      sort: this.props.sort,
      column: this.props.column,
    });
    
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }
  }

  private _handleUpdateFavourites(item) {
    const newItem = { ...item, isFavourite: !item.isFavourite };
    this.props.handleUpdateDeal([newItem]);
  }

  private _handleUpdateIsFavourite(item) {
    this.props.handleUpdateFavourites(
      [{ endpoint: FavouriteEndpoints.deal, id: item.id }],
      () => this._selection.setAllSelected(false),
      item.isFavourite ? FavouriteAction.remove : FavouriteAction.add
    );
  }

  private _handleUpdateIsFavouriteArray(action: FavouriteAction) {
    const selectedArray = this._selection.getSelection();

    this.props.handleUpdateFavourites(
      selectedArray.map((item: any) => {
        return { endpoint: FavouriteEndpoints.deal, id: item.id };
      }),
      () => this._selection.setAllSelected(false),
      action
    );
  }

  private _closeEditDealsDialog() {
    this.setState({
      selectedDeals: null,
      isDealsDialogOpen: false,
    });
  }

  private _openDealsDialog(e: any, deals: IDeal[]) {
    this.setState({
      selectedDeals: deals,
      isDealsDialogOpen: true,
    });
  }

  private _calculateDealStatus(deals: any) {
    let pending = 0;
    let other = 0;

    deals.forEach((deal: any) => {
      deal.status === 1 ? pending++ : other++;
    });
    console.log(deals);
    return { pending, other };
  }

  private _closeEditDealDialog() {
    this.props.handleGetDeals({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
    });
    this.setState({
      isEditDialogOpen: false,
    });
  }

  private _closeSchedulerDialog() {
    this.setState({
      isSchedulerOpen: false,
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    if (column.key === "action") {
      return;
    }

    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    this.props.handleGetDeals({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      column: currColumn.fieldName,
    });

    this.setState({
      columns: [...sortColumns(newColumns, currColumn)],
    });
  }

  //                     =============ACTIONS!! ========================================

  private _handleEdit(id: number) {
    console.log("================================", id);
    const deal = this.props.items.find((item: IDeal) => item.id === id);
    this.setState({
      isEditDialogOpen: true,
      selectedDeal: deal,
    });
  }

  private _handleCustomAction(id: number) {
    console.log("CUSTOM ACTION", id);

    this._closeEditDealDialog();
  }

  private _handleFavouritesList(id: number) {
    console.log(id);

    this._closeEditDealDialog();
  }

  private _handleSetStatus(id: number) {
    console.log(id);
    this._closeEditDealDialog();
  }

  handleDeleteCallback() {
    this._selection.setAllSelected(false);
    this._selection.setItems([], true);

    this.setState({
      columns: [...this.state.columns],
      isConfirmBoxOpen: false,
    });
  }

  private async _handleDelete(id?: number) {
    const ids = [];
    this._selection
      .getSelection()
      .forEach((element: any) => ids.push(element.id));

    
    this.props.handleDeleteDeals(ids, this.handleDeleteCallback.bind(this));

    // // console.log(this._selection.getSelection());
    // // get all items from selection and then delete each separately
    // await this._selection
    //   .getSelection()
    //   .forEach((element: any) => this.props.handleDeleteDeal(element.id));
    //
    // //TODO: errors need to be managed
    // this._selection.setAllSelected(false);
    // this.setState({ isConfirmBoxOpen: false });
    // this._closeEditDealDialog();
  }

  //                     =============ACTIONS END!! ========================================

  // private handleHeaderDropDownChange(e, item) {
  //   this.setState({
  //     selectedInHeaderDropDown: item,
  //   });
  // }
  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    this.props.setRowsPerPage(rowsOnPage);
    this.props.handleGetDeals({
      limit: rowsOnPage,

      sort: this.props.sort,
      column: this.props.column,
    });
  }

  private _handlePageChange(page: number) {
    this.props.handleGetDeals({
      page: page,
      limit: this.props.rowsPerPage,

      sort: this.props.sort,
      column: this.props.column,
    });
  }

  public handleSetStatuses(status: string) {
    const dealsArray = this._selection.getSelection().map((deal) => {
      return { ...deal, status: status };
    });

    this.props.handleUpdateDeal(dealsArray, () =>
      this._selection.setAllSelected(false)
    );
  }

  //               ===Pagination sector END =====
  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <>
        <Fabric>
          <div
            style={{
              paddingLeft: 25,
              paddingRight: 25,
              backgroundColor: "white",
            }}
          >
            {/*{this.state.isCreateDialogOpen && (*/}
            {/*  <AddDealDialog onDismiss={() => {}} isOpen={false} />*/}
            {/*)}*/}

            {this.state.isEditDialogOpen && this.state.selectedDeal && (
              <EditDealDialog
                selectedDeal={this.state.selectedDeal}
                onDismiss={this._closeEditDealDialog.bind(this)}
              />
            )}

            <DetailsList
              // compact={true}
              styles={tableStyles}
              items={this.props.items ? this.props.items.slice() : []}
              columns={columns}
              onRenderDetailsFooter={() => (
                <Pagination
                  rowsPerPage={this.props.rowsPerPage}
                  currentPage={
                    this.props.currentPage && this.props.currentPage.toString()
                  }
                  // currentPage={this.props.currentPage && this.props.currentPage.toString()}
                  totalItems={this.props.totalItems}
                  onRowsNumberChange={this._handleRowsNumberChange.bind(this)}
                  onPageChange={this._handlePageChange.bind(this)}
                />
              )}
              onRenderDetailsHeader={(props, defaultRender) => (
                <>
                  <Stack
                    tokens={stackTokens}
                    styles={stackStyles}
                    horizontal
                    verticalAlign="center"
                  >
                    <p
                      style={{ color: DefaultPalette.themePrimary, width: 100 }}
                    >
                      {selectionDetails.length === 0
                        ? "No items selected"
                        : selectionDetails.length + " items selected"}
                    </p>
                    {selectionDetails.length > 0 && (
                      <DealTableDropdown
                        onDelete={() =>
                          this.setState({ isConfirmBoxOpen: true })
                        }
                        handleUpdateIsFavourite={(action) =>
                          this._handleUpdateIsFavouriteArray(action)
                        }
                        setStatuses={(status) => this.handleSetStatuses(status)}
                        modulePermissions={this.props.permissions}
                      />
                    )}
                  </Stack>
                  <span>{defaultRender(props)}</span>
                </>
              )}
              onRenderRow={(props, defaultRender) => {
                return (
                  <Stack
                    styles={cellStyles}
                    className="centered"
                    verticalAlign="center"
                  >
                    <div
                      onMouseEnter={() => {
                        this.setState({ hovered: props.item.id });
                        console.log("+++++++++++++++++++++", props.item.id);
                      }}
                      onMouseLeave={() => this.setState({ hovered: null })}
                    >
                      {defaultRender({ ...props })}
                    </div>
                  </Stack>
                );
              }}
              // onRenderCheckbox={(props, defaultRender) => {
              //     return <span>{defaultRender(props)}</span>;
              // }}
              selection={this._selection}
              selectionMode={SelectionMode.multiple}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onColumnHeaderClick={(ev, column) =>
                this._onColumnClick(ev, column)
              }
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={() => this._handleEdit(this.state.hovered)}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              enableUpdateAnimations={true}
            />
          </div>
        </Fabric>

        <ConfirmDialog
          loading={this.props.loading}
          description="Please confirm deletion of deal"
          title="Confirm delete deal"
          isOpen={this.state.isConfirmBoxOpen}
          onAction={() => this._handleDelete()}
          onDismiss={() => this.setState({ isConfirmBoxOpen: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.deals?.items?.results,
    totalItems: state.deals?.items?.totalItems,
    currentPage: state.deals?.items?.currentPage,
    rowsPerPage: state.deals?.rowsPerPageDeals,
    column: state.deals?.column,
    sort: state.deals?.sort,
    loading: state.deals?.loading,
    permissions: getModulePermissions(
      state.userProfile?.userProfile.auths
        ? state.userProfile?.userProfile.auths
        : [],
      permissionsModules.Deals
    ),
    user: state.userProfile?.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetDeals: (payload) => dispatch({ type: HANDLE_GET_DEALS, payload }),
    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_DEALS, payload }),
    handleDeleteDeals: (ids, callback?) =>
      dispatch({ type: HANDLE_DELETE_DEALS, payload: { ids, callback } }),
    handleUpdateDeal: (deals, callback) =>
      dispatch({
        type: HANDLE_UPDATE_DEALS,
        payload: { deals, callback },
      }),

    handleUpdateFavourites: (
      handleData: any[],
      callback: () => void,
      action: FavouriteAction
    ) =>
      dispatch({
        type: HANDLE_UPDATE_FAVOURITE,
        payload: { handleData, callback, action },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsTable);
