 const stateCodes = [
    { key: 'AK', text: 'AK' },
    { key: 'AL', text: 'AL' },
    { key: 'AR', text: 'AR' },
    { key: 'AZ', text: 'AZ' },
    { key: 'CA', text: 'CA' },
    { key: 'CO', text: 'CO' },
    { key: 'CT', text: 'CT' },
    { key: 'DC', text: 'DC' },
    { key: 'DE', text: 'DE' },
    { key: 'FL', text: 'FL' },
    { key: 'GA', text: 'GA' },
    { key: 'HI', text: 'HI' },
    { key: 'IA', text: 'IA' },
    { key: 'ID', text: 'ID' },
    { key: 'IL', text: 'IL' },
    { key: 'IN', text: 'IN' },
    { key: 'KS', text: 'KS' },
    { key: 'KY', text: 'KY' },
    { key: 'LA', text: 'LA' },
    { key: 'MA', text: 'MA' },
    { key: 'MD', text: 'MD' },
    { key: 'ME', text: 'ME' },
    { key: 'MI', text: 'MI' },
    { key: 'MN', text: 'MN' },
    { key: 'MO', text: 'MO' },
    { key: 'MS', text: 'MS' },
    { key: 'MT', text: 'MT' },
    { key: 'NC', text: 'NC' },
    { key: 'ND', text: 'ND' },
    { key: 'NE', text: 'NE' },
    { key: 'NH', text: 'NH' },
    { key: 'NJ', text: 'NJ' },
    { key: 'NM', text: 'NM' },
    { key: 'NV', text: 'NV' },
    { key: 'NY', text: 'NY' },
    { key: 'OH', text: 'OH' },
    { key: 'OK', text: 'OK' },
    { key: 'OR', text: 'OR' },
    { key: 'PA', text: 'PA' },
    { key: 'PR', text: 'PR' },
    { key: 'RI', text: 'RI' },
    { key: 'SC', text: 'SC' },
    { key: 'SD', text: 'SD' },
    { key: 'TN', text: 'TN' },
    { key: 'TX', text: 'TX' },
    { key: 'UT', text: 'UT' },
    { key: 'VA', text: 'VA' },
    { key: 'VT', text: 'VT' },
    { key: 'WA', text: 'WA' },
    { key: 'WI', text: 'WI' },
    { key: 'WV', text: 'WV' },
    { key: 'WY', text: 'WY' }
];

const states = [
    { code: 'AK', name: 'AK' },
    { code: 'AL', name: 'AL' },
    { code: 'AR', name: 'AR' },
    { code: 'AZ', name: 'AZ' },
    { code: 'CA', name: 'CA' },
    { code: 'CO', name: 'CO' },
    { code: 'CT', name: 'CT' },
    { code: 'DC', name: 'DC' },
    { code: 'DE', name: 'DE' },
    { code: 'FL', name: 'FL' },
    { code: 'GA', name: 'GA' },
    { code: 'HI', name: 'HI' },
    { code: 'IA', name: 'IA' },
    { code: 'ID', name: 'ID' },
    { code: 'IL', name: 'IL' },
    { code: 'IN', name: 'IN' },
    { code: 'KS', name: 'KS' },
    { code: 'KY', name: 'KY' },
    { code: 'LA', name: 'LA' },
    { code: 'MA', name: 'MA' },
    { code: 'MD', name: 'MD' },
    { code: 'ME', name: 'ME' },
    { code: 'MI', name: 'MI' },
    { code: 'MN', name: 'MN' },
    { code: 'MO', name: 'MO' },
    { code: 'MS', name: 'MS' },
    { code: 'MT', name: 'MT' },
    { code: 'NC', name: 'NC' },
    { code: 'ND', name: 'ND' },
    { code: 'NE', name: 'NE' },
    { code: 'NH', name: 'NH' },
    { code: 'NJ', name: 'NJ' },
    { code: 'NM', name: 'NM' },
    { code: 'NV', name: 'NV' },
    { code: 'NY', name: 'NY' },
    { code: 'OH', name: 'OH' },
    { code: 'OK', name: 'OK' },
    { code: 'OR', name: 'OR' },
    { code: 'PA', name: 'PA' },
    { code: 'PR', name: 'PR' },
    { code: 'RI', name: 'RI' },
    { code: 'SC', name: 'SC' },
    { code: 'SD', name: 'SD' },
    { code: 'TN', name: 'TN' },
    { code: 'TX', name: 'TX' },
    { code: 'UT', name: 'UT' },
    { code: 'VA', name: 'VA' },
    { code: 'VT', name: 'VT' },
    { code: 'WA', name: 'WA' },
    { code: 'WI', name: 'WI' },
    { code: 'WV', name: 'WV' },
    { code: 'WY', name: 'WY' }
];

const fontFamilyList = [
    { key: 'serif', text: 'serif' },
    { key: 'Times, serif', text: 'Times, serif' },
    { key: 'Times New Roman, serif', text: 'Times New Roman, serif' },
    { key: 'Palatino, serif', text: 'Palatino, serif' },
    { key: 'Bookman, serif', text: 'Bookman, serif' },
    { key: 'New Century Schoolbook, serif', text: 'New Century Schoolbook, serif' },
    { key: 'sans-serif', text: 'sans-serif' },
    { key: 'Arial, sans-serif', text: 'Arial, sans-serif' },
    { key: 'Helvetica, sans-serif', text: 'Helvetica, sans-serif' },
    { key: 'Gill Sans, sans-serif', text: 'Gill Sans, sans-serif' },
    { key: 'Lucida, sans-serif', text: 'Lucida, sans-serif' },
    { key: 'Helvetica Narrow, sans-serif', text: 'Helvetica Narrow, sans-serif' },
    { key: 'monospace', text: 'monospace' },
    { key: 'Andale Mono, monospace', text: 'Andale Mono, monospace' },
    { key: 'Courier New, monospace', text: 'Courier New, monospace' },
    { key: 'Courier, monospace', text: 'Courier, monospace' },
    { key: 'Lucidatypewriter, monospace', text: 'Lucidatypewriter, monospace' },
    { key: 'Fixed, monospace', text: 'Fixed, monospace' },
    { key: 'cursive', text: 'cursive' },
    { key: 'Comic Sans, Comic Sans MS, cursive', text: 'Comic Sans, Comic Sans MS, cursive' },
    { key: 'Zapf Chancery, cursive', text: 'Zapf Chancery, cursive' },
    { key: 'Coronetscript, cursive', text: 'Coronetscript, cursive' },
    { key: 'Florence, cursive', text: 'Florence, cursive' },
    { key: 'Parkavenue, cursive', text: 'Parkavenue, cursive' },
    { key: 'fantasy', text: 'fantasy' },
    { key: 'Impact, fantasy', text: 'Impact, fantasy' },
    { key: 'Arnoldboecklin, fantasy', text: 'Arnoldboecklin, fantasy' },
    { key: 'Oldtown, fantasy', text: 'Oldtown, fantasy' },
    { key: 'Blippo, fantasy', text: 'Blippo, fantasy' },
    { key: 'Brushstroke, fantasy', text: 'Brushstroke, fantasy' },
    { key: 'system-ui', text: 'system-ui' },
    { key: 'ui-serif', text: 'ui-serif' },
    { key: 'ui-sans-serif', text: 'ui-sans-serif' },
    { key: 'ui-monospace', text: 'ui-monospace' },
    { key: 'ui-rounded', text: 'ui-rounded' },
    { key: 'emoji', text: 'emoji' },
    { key: 'math', text: 'math' },
    { key: 'fangsong', text: 'fangsong' },
    { key: 'inherit', text: 'inherit' },
    { key: 'initial', text: 'initial' },
    { key: 'unset', text: 'unset' } 
];

const dealerTypeList = [
    { key: 'independent', text: 'Independent' },
    { key: 'franchise', text: 'Franchise' },
    { key: 'other', text: 'Other' },
];

//As per QD20-811 sourceTypeList order should be - Auction, Dealer Trade, Private Party, Trade-in
const sourceTypeList = [
    { key: 'auction', text: 'Auction' },
    { key: 'dealer-trade', text: 'Dealer Trade' },
    { key: 'privaty-party', text: 'Private Party'},
    { key: 'trade-in', text: 'Trade-in' },
     
];

const odometerRange = [
    { key: 'test', text: '10k ranges' },
    { key: 'test2', text: '20k ranges' },
    { key: 'test3', text: 'All (50k ranges)' }
];

const blackbook = [
    { key: '1', text: '3.8L Grand Prix Premium' },
    { key: '2', text: 'Premium' },
    { key: '3', text: '3.0L' }
];

const kbb = [
    { key: '1', text: 'GB Premium' },
    { key: '2', text: 'Premium' }
];

const nada = [
    { key: '1', text: 'LB Test' },
];

const expenseCategoryList = [
    { key: 'acquisition', text: 'Acquisition' },
    { key: 'marketing', text: 'Marketing/Ad' },
    { key: 'recon', text: 'Recon' },
    { key: 'service', text: 'Service' },
    { key: 'transport', text: 'Transport' },
    { key: 'other', text: 'Other' }
];

const dealTrackerStatus = [
    { key: 1, text: 'Pending' },
    { key: 2, text: 'Order Out' }, 
    { key: 5, text: 'Shipped' },
    { key: 3, text: 'Closed' },
    { key: 4, text: 'Void' }
];

const dealDeliveryTypes = [
    { key: 1, text: 'Lease' },
    { key: 2, text: 'Retail' }
];

const dealFinanceStatus = [
    { key: 1, text: 'Pending' },
    { key: 2, text: 'Qualified' },
    { key: 3, text: 'Approved' },
    { key: 4, text: 'Cash' }
];

const appointmentResult = [
    { key: 1, text: 'Sold' },
    { key: 2, text: 'No Show' },
    { key: 3, text: 'Cancelled' },
    { key: 4, text: 'Rescheduling' },
    { key: 5, text: 'Showed - still working' },
    { key: 6, text: 'Showed - End no deal' },
    { key: 7, text: 'Bought elsewhere' }
];

const tradeInStatus = [
    { key: 1, text: 'Still Working' },
    { key: 2, text: 'Sold: Traded' },
    { key: 7, text: 'Sold: Not Traded' },
    { key: 3, text: 'End No Deal' },
    { key: 4, text: 'Bought Else Where' },
    { key: 5, text: 'Order Out - Sold' },
    { key: 6, text: 'New' },
];

const subjectToSeeOptions = [
    { key: false, text: 'No' },
    { key: true, text: 'Yes' }
];

const departmentOptions = [
    { key: 1, text: 'Accounting' },
    { key: 2, text: 'Desking' },
    { key: 3, text: 'Finance' },
    { key: 4, text: 'Sales' }
];


const financeProductValue = [
    { key: 1 , text: 'GM PP' }
];

const managerReason = [
    { key: 1 , text: 'Lunch' },
    { key: 2 , text: 'UnAvailable' }
];

const financeDuration = [
    { key: '15 min' , text: '15 min' },
    { key: '30 min' , text: '30 min' },
    { key: '45 min' , text: '45 min' },
    { key: '60 min' , text: '60 min' },
    { key: '2 hrs' , text: '2 hrs' },
    { key: '3 hrs' , text: '3 hrs' },
    { key: '4 hrs' , text: '4 hrs' },
    { key: '5 hrs' , text: '5 hrs' },
    { key: '6 hrs' , text: '6 hrs' },
    { key: '7 hrs' , text: '7 hrs' },
    { key: '8 hrs' , text: '8 hrs' },
    { key: '9 hrs' , text: '9 hrs' },
    { key: 'All day' , text: 'All day' },
];

const deliveryDuration = [
    { key: '15 min' , text: '15 min' },
    { key: '30 min' , text: '30 min' },
    { key: '45 min' , text: '45 min' },
    { key: '60 min' , text: '60 min' },
    { key: '75 min' , text: '75 min' },
    { key: '90 min' , text: '90 min' },
    { key: '2 hrs' , text: '2 hrs' },
    { key: '4 hrs' , text: '4 hrs' },
    { key: '6 hrs' , text: '6 hrs' },
    { key: 'All day' , text: 'All day' },
];

const _getYearsUntilNow = () => {
    const years = [];
    const endYear = new Date().getFullYear();

    let startYear = 1960;

    while (startYear <= endYear) {
        years.push({ key: startYear, text: startYear });
        startYear++;
    }
    years.push({key: startYear, text: startYear});
    return years;
};

export const years = [..._getYearsUntilNow().reverse()];


const _getYears2022UntilNow = () => {
    const years = [];
    const endYear = new Date().getFullYear();

    let startYear = 2022;

    while (startYear <= endYear) {
        years.push({ key: startYear, text: startYear });
        startYear++;
    }
    years.push({key: startYear, text: startYear});
    return years;
};

export const from2022years = [..._getYears2022UntilNow()];

const proofLoanPaidOff = [
    { key: true, text: 'Yes' },
    { key: false, text: 'No' }
];

const refundMethod1 = [
    { key: '1', text: 'Sent directly to Dealer from Product Company' },
    { key: '2', text: 'Sent directly to Customer from Product Company' },
    { key: '3', text: 'Sent directly to Lender from Product Company' },
    { key: '4', text: 'Lender Debits Dealer (check sent from lender direct to customer)' }
];

const refundMethod2 = [
    { key: '1', text: 'Applied to Deal' },
    { key: '2', text: 'Send to Customer' },
    { key: '3', text: 'Send to Lender' }
];

const deliveryCodes = [
    { key: 1, text: 'GM - 010 - Individual Purchase', shortText: 'GM - 010' },
    { key: 2, text: 'GM - 014 - Fleet Lease', shortText: 'GM - 014' },
    { key: 3, text: 'GM - 015 - Individual Lease', shortText: 'GM - 015' },
    { key: 22, text: 'GM - 016 - Supplier', shortText: 'GM - 016' },
    { key: 4, text: 'GM - 018 - Small Business', shortText: 'GM - 018' },
    { key: 23, text: 'GM - 021 - GM Employee', shortText: 'GM - 021' },
    { key: 24, text: 'GM - 022 - GM Employee QRD', shortText: 'GM - 022' },
    { key: 21, text: 'GM - 023 - Dealership Employee', shortText: 'GM - 023' },
    { key: 5, text: 'GM - 029 - Business Lease', shortText: 'GM - 029' },
    { key: 25, text: 'GM - 032 - GM Employee Lease', shortText: 'GM - 032' },
    { key: 26, text: 'GM - 034 - Dealership Employee Lease', shortText: 'GM - 034' },
    { key: 6, text: 'GM - 035 - Fleet Purchase', shortText: 'GM - 035' },
    { key: 27, text: 'GM - 037 - Supplier Lease', shortText: 'GM - 037' },
    { key: 7, text: 'GM - 038 - Government', shortText: 'GM - 038' },
    { key: 8, text: 'Ford - 0 - Retail Sales ', shortText: 'Ford - 0' },
    { key: 9, text: 'Ford - 3 - Government', shortText: 'Ford - 3' },
    { key: 10, text: 'Ford - 6 - Fleet Lease', shortText: 'Ford - 6' },
    { key: 11, text: 'Ford - 7 - Fleet Purchase', shortText: 'Ford - 7' },
    { key: 12, text: 'Ford - E - Commercial Retail', shortText: 'Ford - E' },
    { key: 13, text: 'Ford - R - Individual Lease', shortText: 'Ford - R' },
    { key: 14, text: 'CDJR - 1 - Retail Purchase', shortText: 'CDJR - 1' },
    { key: 15, text: 'CDJR - B - Retail Commercial', shortText: 'CDJR - B' },
    { key: 16, text: 'CDJR - L - Retail Lease', shortText: 'CDJR - L' },
    { key: 17, text: 'CDJR - E - Retail Commerical Lease', shortText: 'CDJR - E' },
    { key: 18, text: 'CDJR - 3 - Fleet Purchase', shortText: 'CDJR - 3' },
    { key: 19, text: 'CDJR - 5 - Fleet Lease', shortText: 'CDJR - 5' },
    { key: 20, text: 'CDJR - 2 - Government', shortText: 'CDJR - 2' }
];

const gasOptions = [
    { key: 1, text: 'Full Tank' },
    { key: 2, text: 'Half Tank' },
    { key: 3, text: '5 Gallons' },
    { key: 4, text: '2 Gallons' },
    { key: 5, text: 'No Gas' }
];

const prepVehicleLocationOptions = [
    { key: 1, text: 'Accessories' },
    { key: 2, text: 'Behind Wash Bay' },
    { key: 3, text: 'Body Shop' },
    { key: 4, text: 'Commercial' },
    { key: 5, text: 'Cop Shop' },
    { key: 6, text: 'Lot' },
    { key: 7, text: 'Overflow' },
    { key: 8, text: 'PDI' },
    { key: 9, text: 'Race Shop' },
    { key: 10, text: 'Service' },
    { key: 11, text: 'Sold Row' },
    { key: 12, text: 'Shurline Lot' }
];

const prepKeyLocationOptions = [
    { key: 1, text: 'Accessories' },
    { key: 2, text: 'Commercial' },
    { key: 3, text: 'Cop Shop' },
    { key: 4, text: 'In Vehicle' },
    { key: 5, text: 'Keytrak' },
    { key: 6, text: 'PDI' },
    { key: 7, text: 'Other' },
    { key: 8, text: 'Service' },
    { key: 9, text: 'Wash Bay' },
];

const getMonth = [
    { key: 1, text: 'January' },
    { key: 2, text: 'February' },
    { key: 3, text: 'March' },
    { key: 4, text: 'April' },
    { key: 5, text: 'May' },
    { key: 6, text: 'June' },
    { key: 7, text: 'July' },
    { key: 8, text: 'August' },
    { key: 9, text: 'September' },
    { key: 10, text: 'October' },
    { key: 11, text: 'November' },
    { key: 12, text: 'December' },
];

const taskCompleteStatus = [
    { key: 'Y', text: 'Y' },
    { key: 'N', text: 'N' },
    { key: 'All', text: 'All' }
];

const cdrStatus = [
    { key: 'Yes', text: 'Y' },
    { key: 'No', text: 'N' },
    { key: '', text: 'All' }
];

const vehicleTrackerStatus = [
    { key: 1, text: 'In Transit' },
    { key: 2, text: 'Received, Out Location' },
    { key: 3, text: 'Still Working' },
    { key: 4, text: 'Sold' },
    { key: 5, text: 'No Sale, Returning' },
    { key: 6, text: 'Received Home' },
    { key: 7, text: 'Loaner' }
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    stateCodes,
    sourceTypeList,
    fontFamilyList,
    dealerTypeList,
    states,
    odometerRange,
    blackbook,
    kbb,
    nada,
    expenseCategoryList,
    dealTrackerStatus,
    dealDeliveryTypes,
    dealFinanceStatus,
    appointmentResult,
    tradeInStatus,
    subjectToSeeOptions,
    departmentOptions,
    financeProductValue,
    years,
    from2022years,
    managerReason,
    financeDuration,
    deliveryDuration,
    proofLoanPaidOff,
    refundMethod1,
    refundMethod2,
    deliveryCodes,
    gasOptions,
    prepVehicleLocationOptions,
    prepKeyLocationOptions,
    getMonth,
    taskCompleteStatus,
    cdrStatus,
    vehicleTrackerStatus
}