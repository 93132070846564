import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import masterDropdownValues from "constants/masterDropdownValues";
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { handlePrintPendingDeal } from "store/dealTracker/dealTracker.action";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const container: IStackStyles = {
    root: {
        marginTop: 10,
        minHeight: 500
    },
};

//const credentialModuleName = "DealTracker";
export const PendingReports = () => {
    const dispatch = useDispatch();

    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [commonServerError, setcommonServerError] = useState(null);
    const isMobile = useMediaQuery({ maxWidth: 960 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [departmentFilter, setDepartmentFilter] = useState(0);
    const [cdrFilter, setCDRFilter] = useState(0);
    const [sfeFilter, setSFEFilter] = useState(0);
    const [fleetFilter, setFleetFilter] = useState(0);
    const [cpoFilter, setCPOFilter] = useState(0);
    const [cdrDateFilterFrom, setCDRDateFilterFrom] = React.useState<Date | undefined>(undefined);
    const [cdrDateFilterTo, setCDRDateFilterTo] = React.useState<Date | undefined>(undefined);
    const [departmentList, setDepartmentList] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [locationFilter, setLocationFilter] = useState(0);
    const [selectedDelCodes, setSelectedDelCodes] = useState(null);
    const [financePersonList, setFinancePersonList] = React.useState(null);
    const [managerFilter, setManagerFilter] = React.useState(0);
  
    const filterStatus = [
        { key: 2, text: 'Y' },
        { key: 1, text: 'N' },
        { key: 0, text: 'All' }
    ];


    const onDepartmentChange = (e: { value: any }) => {
        setDepartmentFilter(e.value);
    }

    const onCDRChange = (e: { value: any }) => {
        setCDRFilter(e.value);
    }

    const onFleetChange = (e: { value: any }) => {
        setFleetFilter(e.value);
    }

    const onSFEChange = (e: { value: any }) => {
        setSFEFilter(e.value);
    }

    const onCPOChange = (e: { value: any }) => {
        setCPOFilter(e.value);
    }

    const handleCDRDateFromChange = (val: any) => {
        setCDRDateFilterFrom(val.target.value)
    };

    const handleCDRDateToChange = (val: any) => {
        setCDRDateFilterTo(val.target.value)
    };

    const handleManagerDropdownChange = (e) => {
     setManagerFilter(e.value)
    };

    useEffect(() => {
        if (departments) {
            const departList = [...departments];
            departList.unshift({ id: 0, name: 'All' });
            setDepartmentList(departList);
        } else {
            setDepartmentList([]);
        }
    }, [departments]);



    useEffect(() => {
        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    useEffect(()=> {
            let person = [];
            if (employees.results.length > 0) {
                employees.results.map((item: any) => {
                    return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                });
                person.unshift({ key: 0 ,text: 'All'});
                setFinancePersonList(person);
            }
    }, [employees])

    useEffect(() => {
        dispatch<any>(handleGetSelectList('departments', 1));
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"financePerson" } })
        dispatch<any>(handleGetLocations());
        setcommonServerError(null);
        setSelectedDelCodes(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                //setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const handleCreateReport = ()=>{
        const formData = {
            fromCdrDate: cdrDateFilterFrom ? moment(cdrDateFilterFrom).format('YYYY-MM-DD') : '',
            toCdrDate: cdrDateFilterTo ? moment(cdrDateFilterTo).format('YYYY-MM-DD') : '',
            cdr: cdrFilter ? cdrFilter : null,
            sfe: sfeFilter ? sfeFilter : null,
            fleet: fleetFilter ? fleetFilter : null,
            cpo: cpoFilter ? cpoFilter : null,
            departmentId: departmentFilter ? departmentFilter : null,
            locationId: locationFilter ? locationFilter : null,
            financePersonId :managerFilter ? managerFilter : null,
            deliveryCode: selectedDelCodes?.toString(),
        };
        dispatch<any>(handlePrintPendingDeal({
            formData,
            callback: (response) => {
                   if(response){
                         console.log("handlePrintPendingDeal",response)
                   }else{
                       setcommonServerError('Failed to print the pdf');
                   }
            }
        }));
    }

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row className="pageTitleRow">
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont task">Pending Report</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                


                
                


                {isMobile ?
                     <div>
                         <Row>
                             <Col>
                                 <Row>
                                     
                                     <Col>
                                         <div className="dd-control">
                                             <label className="lbl-text">Location</label>
                                             <Dropdown
                                                 id="locationId"
                                                 placeholder=""
                                                 value={locationFilter}
                                                 options={cityDealerList}
                                                 onChange={onLocationChange}
                                                 optionLabel="legalName"
                                                 optionValue="id"
                                                 appendTo="self"
                                                 className="custom-p-dropdown"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Col>
                                         <div className="locationDropPosition">
                                             <label className="lbl-text">Department</label>
                                             <Dropdown
                                                 id="locationId"
                                                 placeholder=""
                                                 value={departmentFilter}
                                                 options={departmentList}
                                                 onChange={onDepartmentChange}
                                                 optionLabel="name"
                                                 optionValue="id"
                                                 appendTo="self"
                                                 className="custom-p-dropdown"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                     <Col>
                                                       <div className="dd-control">
                                                           <label className="lbl-text">Finance Person</label>
                                                           <Dropdown
                                                               id="financePerson"
                                                               value={managerFilter}
                                                               options={financePersonList}
                                                               onChange={handleManagerDropdownChange}
                                                               optionLabel="text"
                                                               optionValue="key"
                                                               appendTo="self"
                                                               className="custom-p-dropdown write-ups-location-dd"
                                                               filter={true}
                                                           />
                                                       </div>
                                         </Col>
                                     <Col>
                                         <div className="dd-control">
                                             <label className="lbl-text">SFE</label>
                                             <Dropdown
                                                 id="sfeFilter"
                                                 placeholder=""
                                                 value={sfeFilter}
                                                 options={filterStatus}
                                                 onChange={onSFEChange}
                                                 optionLabel="text"
                                                 optionValue="key"
                                                 appendTo="self"
                                                 className="custom-p-dropdown write-ups-location-dd"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Col>
                                         <div className="dd-control">
                                             <label className="lbl-text">Fleet</label>
                                             <Dropdown
                                                 id="fleetFilter"
                                                 placeholder=""
                                                 value={fleetFilter}
                                                 options={filterStatus}
                                                 onChange={onFleetChange}
                                                 optionLabel="text"
                                                 optionValue="key"
                                                 appendTo="self"
                                                 className="custom-p-dropdown write-ups-location-dd"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                     <Col>
                                         <div className="dd-control cdr-cpo-dd-filter">
                                             <label className="lbl-text">Delivery Code</label>
                                             <MultiSelect
                                                 value={selectedDelCodes}
                                                 options={masterDropdownValues.deliveryCodes}
                                                 style={{ minWidth: "175px" }}
                                                 onChange={(e) => {
                                                     setSelectedDelCodes(e.value);
                                                 }}
                                                 placeholder="Select Del Code"
                                                 optionLabel="shortText"
                                                 optionValue="key"
                                                 className="custom-p-dropdown"
                                                 maxSelectedLabels={2}
                                             />
                                         </div>
                                     </Col>
                                 </Row>
                                 <Row>
                                     <Col>
                                         <div className="dd-control">
                                             <label className="lbl-text">CDR</label>
                                             <Dropdown
                                                 id="cdrFilter"
                                                 placeholder=""
                                                 value={cdrFilter}
                                                 options={filterStatus}
                                                 onChange={onCDRChange}
                                                 optionLabel="text"
                                                 optionValue="key"
                                                 appendTo="self"
                                                 className="custom-p-dropdown write-ups-location-dd"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                     <Col>
                                         <div className="dd-control">
                                             <label className="lbl-text">CPO</label>
                                             <Dropdown
                                                 id="cpoFilter"
                                                 placeholder=""
                                                 value={cpoFilter}
                                                 options={filterStatus}
                                                 onChange={onCPOChange}
                                                 optionLabel="text"
                                                 optionValue="key"
                                                 appendTo="self"
                                                 className="custom-p-dropdown write-ups-location-dd"
                                                 filter={false}
                                                 resetFilterOnHide={true}
                                             />
                                         </div>
                                     </Col>
                                 </Row>
                                 
                                 <Row style={{ width: "auto" }} >
                                     <Col>
                                         <div className="date-filter-section">
                                             <div className="table-header">
                                                 <div className="ms-TextField-wrapper">
                                                     <label className="lbl-text">CDR Date</label>
                                                     <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                         <Calendar
                                                             id="cdrDateFilterFrom"
                                                             style={{ width: "100%" }}
                                                             value={cdrDateFilterFrom}
                                                             showIcon
                                                             onChange={(e) => handleCDRDateFromChange(e)}
                                                             monthNavigator
                                                             yearNavigator
                                                             yearRange={yearRange}
                                                             maxDate={cdrDateFilterTo}
                                                         />
                                                     </div>
                                                 </div>
                                             </div>
                                             <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                             <div className="table-header">
                                                 <div className="ms-TextField-wrapper">
                                                     <label className="lbl-text">&nbsp;</label>
                                                     <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                         <Calendar
                                                             id="cdrDateFilterTo"
                                                             style={{ width: "100%" }}
                                                             value={cdrDateFilterTo}
                                                             showIcon
                                                             onChange={(e) => handleCDRDateToChange(e)}
                                                             monthNavigator
                                                             yearNavigator
                                                             yearRange={yearRange}
                                                             minDate={cdrDateFilterFrom}
                                                         />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </Col>
                                     <Col>
                                             <div >
                                                  <div className="table-header employeeeSearchLabelText" style={{ marginTop:"25px",float :"right"}}>
                                                           <PrimaryButton onClick={handleCreateReport}>Create Report</PrimaryButton>
                                                  </div>
                                              </div>
                                     </Col>
                                 </Row>
                                 
                             </Col>
                         </Row>
                     </div> :
                     <div>
                         <div>
                             <Row>
                                 <Col md={12}>
                                     <Row>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Location</label>
                                                 <Dropdown
                                                     id="locationId"
                                                     placeholder=""
                                                     value={locationFilter}
                                                     options={cityDealerList}
                                                     onChange={onLocationChange}
                                                     optionLabel="legalName"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}> 
                                             <div >
                                                 <label className="lbl-text">Department</label>
                                                 <Dropdown
                                                     id="departmentId"
                                                     placeholder=""
                                                     value={departmentFilter}
                                                     options={departmentList}
                                                     onChange={onDepartmentChange}
                                                     optionLabel="name"
                                                     optionValue="id"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                     
                                         <Col md={1.5}>
                                              <div>
                                                  <label className="lbl-text">Finance Person</label>
                                                  <Dropdown
                                                      id="financePerson"
                                                      value={managerFilter}
                                                      options={financePersonList}
                                                      onChange={handleManagerDropdownChange}
                                                      optionLabel="text"
                                                      optionValue="key"
                                                      appendTo="self"
                                                      className="custom-p-dropdown write-ups-location-dd"
                                                      filter={true}
                                                  />
                                              </div>
                                         </Col>
                                         <Col md={1.5}>
                                             <div>
                                                 <label className="lbl-text">CDR</label>
                                                 <Dropdown
                                                     id="cdrFilter"
                                                     placeholder=""
                                                     value={cdrFilter}
                                                     options={filterStatus}
                                                     onChange={onCDRChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         
                                         <Col md={3}>
                                             <div className="date-filter-section">
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">CDR Date</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="closeDateFilterFrom"
                                                                 style={{ width: "100%" }}
                                                                 value={cdrDateFilterFrom}
                                                                 showIcon
                                                                 onChange={(e) => handleCDRDateFromChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 maxDate={cdrDateFilterTo}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                                 <label className="lbl-text" style={{ margin: "36px 10px 0px" }}>To</label>
                                                 <div className="table-header">
                                                     <div className="ms-TextField-wrapper">
                                                         <label className="lbl-text">&nbsp;</label>
                                                         <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                             <Calendar
                                                                 id="cdrDateFilterTo"
                                                                 style={{ width: "100%" }}
                                                                 value={cdrDateFilterTo}
                                                                 showIcon
                                                                 onChange={(e) => handleCDRDateToChange(e)}
                                                                 monthNavigator
                                                                 yearNavigator
                                                                 yearRange={yearRange}
                                                                 minDate={cdrDateFilterFrom}
                                                             />
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </Col>
                                         <Col md={3}>
                     
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col md={1.5} style={{width:"100%"}}>
                                           
                                             <div>
                                                 <label className="lbl-text">SFE</label>
                                                 <Dropdown
                                                     id="sfeFilter"
                                                     placeholder=""
                                                     value={sfeFilter}
                                                     options={filterStatus}
                                                     onChange={onSFEChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className=" custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                          </Col>
                                          <Col md={1.5}>
                                             <div>
                                                 <label className="lbl-text">Fleet</label>
                                                 <Dropdown
                                                     id="fleetFilter"
                                                     placeholder=""
                                                     value={fleetFilter}
                                                     options={filterStatus}
                                                     onChange={onFleetChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className=" custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>                                     
                                         </Col>
                                         <Col md={1.5}>
                                             <div className= "reportMultiSelect cdr-cpo-dd-filter">
                                                 <label className="lbl-text">Delivery Code</label>
                                                 <MultiSelect
                                                     value={selectedDelCodes}
                                                     options={masterDropdownValues.deliveryCodes}
                                                     onChange={(e) => {
                                                         setSelectedDelCodes(e.value);
                                                     }}
                                                     placeholder="Select Del Code"
                                                     optionLabel="shortText"
                                                     optionValue="key"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     maxSelectedLabels={2}
                                                 />
                                             </div>
                                         </Col>
                                         <Col md={1.5}>
                                             <div >
                                                 <label className="lbl-text">CPO</label>
                                                 <Dropdown
                                                     id="cpoFilter"
                                                     placeholder=""
                                                     value={cpoFilter}
                                                     options={filterStatus}
                                                     onChange={onCPOChange}
                                                     optionLabel="text"
                                                     optionValue="key"
                                                     appendTo="self"
                                                     className="custom-p-dropdown write-ups-location-dd"
                                                     filter={false}
                                                     resetFilterOnHide={true}
                                                 />
                                             </div>
                                         </Col>
                                         
                                         
                                         <Col md={3}>
                                             <div >
                                                  <div className="table-header employeeeSearchLabelText" style={{ marginTop:"25px",float :"right"}}>
                                                           <PrimaryButton onClick={handleCreateReport}>Create Report</PrimaryButton>
                                                  </div>
                                              </div>
                                         </Col>
                                         <Col md={3}>
                     
                                         </Col>
                                       
                                     </Row>
                                 </Col>
                                 <Col>
                                 </Col>
                             </Row>
                     
                     
                         </div>                 
                     </div>
                    
                }


            </Stack>
        </> : null
    );
};
