import client, { clientFile } from "services/api/client";
import { IHandleFavourite, ISingleSMS, ISMTPSettings } from "types/sharedTypes";

export const updateFavourite = (
  { endpoint, id }: IHandleFavourite,
  action: string
) => {
  return client(`user/master/${endpoint}/${id}/favourite/${action}`, {
    body: null,
    method: "PATCH",
  }).then(
    (data) => {
      console.log("data:", data);
      
      return { data, error: null };
    },
    (error) => {
      console.log("error:", error);
      
      return { data: null, error };
    }
  );
};

export const addFavourite = ({ endpoint, id }: IHandleFavourite) => {
  return client(`user/master/${endpoint}/${id}/favourite/add`, {
    body: null,
    method: "PATCH",
  }).then(
    (data) => {
      console.log("data:", data);
      
      return { data, error: null };
    },
    (error) => {
      console.log("error:", error);
      
      return { data: null, error };
    }
  );
};

export const removeFavourite = ({ endpoint, id }: IHandleFavourite) => {
  return client(`user/master/${endpoint}/${id}/favourite/remove`, {
    body: null,
    method: "PATCH",
  }).then(
    (data) => {
      console.log("data:", data);
      
      return { data, error: null };
    },
    (error) => {
      console.log("error:", error);
      
      return { data: null, error };
    }
  );
};

export const getSMTPSettingsApi = () => {
  return client("user/master/email/smtp", { body: null, method: "GET" }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateSMTPSettingsApi = (settings: ISMTPSettings) => {
  return client("user/master/email/smtp", {
    body: settings,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const testConnectionApi = (payload) => {
  
  return client("user/master/email/connection", {
    body: payload,
    method: "POST",
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const sendEmailApi = (formData: any) => {
  return clientFile(`user/master/email/send`, { body: formData }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const sendSMSApi = (sms: ISingleSMS) => {
  
  return client(`user/master/sms`, {
    body: sms,
    method: "POST",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getMakesApi = () => {
  return client("user/master/inventory/make", {
    body: null,
    method: "GET",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
}; 

export const getMakesDataOneApi = (year: any) => {
    let url = 'user/master/vehicles/makes';
    if (year > 0) {//Get all make if year is negative value
        url = `user/master/vehicles/makes?year=${year}`;
    }
  return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getModelsDataOneApi = ({ make, year }) => {
    let url = `user/master/vehicles/models?make=${make}&year=${year}`;
  return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const getModelsApi = (id) => {
  return client(`user/master/inventory/make/${id}/models`, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};
export const getSMSSettingsApi = () => {
  return client(`user/master/sms`, {
    body: null,
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateSMSSettingsApi = (settings) => {
  return client(`user/master/sms`, {
    body: settings,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
