import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { themeStyles } from 'constants/constants';
import React, { FC, /*useEffect, useState */ } from "react";
import { useNavigate  } from "react-router-dom";

// const useStyles = styled('div')(({ theme }) => ({
//     root: {
//         flexGrow: 1,
//         width: '100%',
//         backgroundColor: theme.palette.background.paper,
//         display: 'block',
//     },
//     heading: {
//         fontSize: theme.typography.pxToRem(15),
//     },
//     secondaryHeading: {
//         fontSize: theme.typography.pxToRem(15),
//         color: theme.palette.text.secondary,
//     },
//     icon: {
//         verticalAlign: 'bottom',
//         height: 20,
//         width: 20,
//     },
//     details: {
//         alignItems: 'center',
//     },
//     column: {
//         flexBasis: '33.33%',
//     },
//     helper: {
//         borderLeft: `2px solid ${theme.palette.divider}`,
//         padding: theme.spacing(1, 2),
//     },
//     link: {
//         color: theme.palette.primary.main,
//         textDecoration: 'none',
//         '&:hover': {
//             textDecoration: 'underline',
//         },
//     },
//     breadCrumblink: {
//         display: 'flex',
//     },
//     instructions: {
//         marginTop: theme.spacing(1),
//         marginBottom: theme.spacing(1),
//     },
//     breadCrumbicon: {
//         marginRight: theme.spacing(0.5),
//         width: 20,
//         height: 20,
//     },
//     startTime: {
//         // Add your styles for startTime here
//     }
// }));

interface IProps {
    mainPage: string;
    secondPage: string;
    thirdPage?: string;
    secondPageUrl?: string;
}

const GoBackInfo: FC<IProps> = ({ mainPage, secondPage, thirdPage, secondPageUrl }) => {
    //const [hovered, setHovered] = useState();
    const navigate = useNavigate ();
    //const location = useLocation();
    //const  classes:any  = useStyles();

    const handleNavigateSecondPage = (url) => {
        if (url) {
            navigate(url);
        }
    }

    return (
        <>
            <Breadcrumbs style={{ marginTop: "20px" }} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link color="inherit">
                    <HomeIcon style={{ ...themeStyles.icon, verticalAlign: "top" }} className={"breadcrumbs-home-icon"} />
                    <span className="home-page-name">{mainPage}</span>
                </Link>
                {secondPage ?
                    <Link color="inherit" className={secondPageUrl ? "link" : null} onClick={() => handleNavigateSecondPage(secondPageUrl)}>
                        {secondPage}
                    </Link> : null}
                {thirdPage ?
                    <Link color="inherit">
                        {thirdPage}
                    </Link> : null}
            </Breadcrumbs>
        </>
    );
};

export default GoBackInfo;
