import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";


const fontSize = 14;
// Tell Material-UI what's the font-size on the html element.
// 16px is the default font-size used by browsers.
const htmlFontSize = 16;
const coef = fontSize / 14;

const muiThemeStyle = createTheme({
    typography: {
        pxToRem: (size: any) => `${(size / htmlFontSize) * coef}rem`,
    } as TypographyOptions, 
    spacing: (factor) => `${0.25 * factor}rem`, // (Bootstrap strategy)
});

export default muiThemeStyle


