import React, { useState, useEffect } from "react";
import { AppState } from "store";
import { useSelector } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { Link } from "@fluentui/react";
import { emplyeePermissionsAction } from "constants/constants";
import { PrimaryButton } from "@fluentui/react";
import AddCircle from '@mui/icons-material/AddCircle';

import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { NewEmployeeDialog } from "components/dialogs/employees/NewEmployeeDialog";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";



const SettingsPage = () => {
    const userProfile = useSelector((state: AppState) => state.userProfile.userProfile);
    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);
    const navigate = useNavigate ();
    const [isAddUserDialogOpen, setAddUserDialogOpen] = useState(false);

    const handleAddUser = () => {
        setAddUserDialogOpen(true)
    };
    const handleUserDialogDismiss = (res) => {
        setAddUserDialogOpen(false);
        if (res === 'Success') {
            navigate(`/users`);
        }
    };

    const [locationCredentials, setLocationCredentials] = useState([]);
    const [userCredentials, setUserCredentials] = useState([]);

    useEffect(() => {
        if (userProfile && userProfile.auths && !isUserMaster(userProfile.roles)) {
            setLocationCredentials(getModulePermissions(userProfile.auths, 'Location'));
            setUserCredentials(getModulePermissions(userProfile.auths, 'Employee'));
        }
        setAddUserDialogOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <>
            {isAddUserDialogOpen && (
                <NewEmployeeDialog
                    isOpen={isAddUserDialogOpen}
                    isKarlProfile={isKarlProfileType}
                    onDismiss={(res) => handleUserDialogDismiss(res)}
                />
            )}
            <div className="setting-board">
                <Row className="settings-title">
                    <Col>
                        <CustomFontText className="pageName">Settings</CustomFontText>
                    </Col>
                </Row>
                <Row className="settings-area">
                    <Col style={{ textAlign: "center", bottom: "15px" }} className="settings-area-inside">

                        <Row className="rowPaddingBtwSpace">
                            <Col className="shadowBox-radius" style={{ margin: "5px 15px", height: "auto%", paddingBottom:"10px" }}>
                                <div style={{ height: "75%" }}>
                                    <div className="box-header-row box-header-row-align">
                                        <h4 className="blueShadedText section-title  text-left">Users</h4>
                                        {userCredentials && userProfile.roles && (
                                            <EmployeeActionPermission
                                                permissionList={userProfile.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsAction.SETTING_USER_ADD}
                                                roles={userProfile.roles}
                                            >
                                                <Link color="inherit" className="font-b-500" style={{ fontSize: "15px", marginLeft: "0px" }} onClick={handleAddUser}>
                                                    <AddCircle style={{ color: "#70af60", margin: "-2px 0px" }} className="plus-icon" />
                                            &nbsp; Add User
                                        </Link>
                                            </EmployeeActionPermission>
                                        )}
                                    </div>
                                    <div className="box-body-content">
                                        <strong className="blueShadedText">Create and manage users, teams and permission.</strong>
                                    </div>
                                </div>
                                <div style={{ height: "25%" }} className="box-but-view-all">
                                    <div >
                                        <PrimaryButton onClick={() => { navigate(`/users`) }}>
                                            View All
                                </PrimaryButton>
                                    </div>
                                </div>
                            </Col>
                            <Col className="shadowBox-radius" style={{ margin: "5px 0px 5px 15px", height: "auto", paddingBottom: "10px" }}>
                                <div style={{ height: "75%" }}>
                                    <div className=" box-header-row box-header-row-align">
                                        <h4 className="blueShadedText section-title  text-left">Locations</h4>
                                        {locationCredentials && userProfile.roles && (
                                            <EmployeeActionPermission
                                                permissionList={userProfile.aggregatedDefaultPolicies}
                                                alias={emplyeePermissionsAction.SETTING_LOCATION_ADD}
                                                roles={userProfile.roles}
                                            >
                                                <Link color="inherit" className="font-b-500" style={{ fontSize: "15px" }} onClick={() => { navigate('/add-location-detail') }}>
                                                    <AddCircle style={{ color: "#70af60", margin: "-2px 0px" }} className="plus-icon" />
                                            &nbsp; Add Location
                                        </Link>
                                            </EmployeeActionPermission>
                                        )}
                                    </div>
                                    <div className="box-body-content">
                                        <strong className="blueShadedText">Maintain leadership location information.</strong>
                                    </div>
                                </div>
                                <div style={{ height: "25% " }} className="box-but-view-all">
                                    <PrimaryButton onClick={() => { navigate(`/locations`) }}>
                                        View All
                                    </PrimaryButton>
                                </div>
                            </Col>
                        </Row>
                        {
                            isKarlProfileType && (<Row className="rowPaddingBtwSpace">
                                <Col className="shadowBox-radius" style={{ margin: "5px 0px 5px 15px" }}>
                                    <h4 style={{ marginBottom: "0px" }} className="blueShadedText section-title text-left">List Selections</h4>
                                    <div className="box-body-content">
                                        <Row className="rowPaddingBtwSpace">
                                            <Col className="text-left">
                                                <strong className="blueShadedText">Manage drop-down list selections for DealTracker</strong>
                                            </Col>
                                        </Row>
                                        <Row className="button-grid">
                                            <Col>
                                                <PrimaryButton onClick={() => { navigate(`/departments`) }}>
                                                    Departments
                                            </PrimaryButton>
                                            </Col>
                                            <Col>
                                                <PrimaryButton onClick={() => { navigate(`/lenders`) }}>
                                                    Lenders
                                            </PrimaryButton>
                                            </Col>
                                        </Row>
                                        <Row className="button-grid">
                                            <Col>
                                                <PrimaryButton onClick={() => { navigate(`/appraisal-bidders`) }}>
                                                    Appraisal Bidders
                                            </PrimaryButton>
                                            </Col>
                                            <Col>
                                                <PrimaryButton onClick={() => { navigate(`/finance-products`) }}>
                                                    Finance Products
                                            </PrimaryButton>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>)
                        }
                    </Col>

                </Row>
            </div>
        </>
    );
};

export default SettingsPage;