import {
    call,
    put,
    takeEvery,
    takeLeading
} from "redux-saga/effects";

import {
    pendingCreateAccount,
    pendingGetAccounts,
    pendingUpdateAccount,
    pendingViewAccount,
    rejectedCreateAccount,
    rejectedGetAccounts,
    rejectedUpdateAccount,
    resolvedCreateAccount,
    resolvedGetAccounts,
    resolvedUpdateAccount,
    resolvedViewAccount,
    pendingAccountActivate,
    rejectedAccountActivate,
    rejectedSendInvite,
    resolvedSendInvite,
    //handleExportCsvAccount
} from "store/accounts/accounts.action";

import {
    createAccount,
    getAccounts,
    updateAccount,
    viewAccount,
    activateAccount,
    sendInvite,
    exportCsv,
    //csvAccount
} from "store/accounts/accounts.api";
import {
    HANDLE_CREATE_ACCOUNT,
    HANDLE_GET_ACCOUNTS,
    HANDLE_UPDATE_ACCOUNT,
    HANDLE_VIEW_ACCOUNT,
    HandleCreateAccount,
    HandleGetAccounts,
    HandleUpdateAccount,
    HandleViewAccount,
    HandleAccountActivate,
    HANDLE_ACCOUNT_ACTIVATE,
    HandleAccountSendInvite,
    HANDLE_ACCOUNT_SEND_INVITE,
    HandleActiveExportCsv,
    HANDLE_ACTIVE_EXPORT_CSV,
    //HANDLE_CSV_ACCOUNT,
    //HandleCsvAccount
} from "store/accounts/accounts.types";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";


export function* getAccountsSaga(actions: HandleGetAccounts) {
    yield put(switchGlobalLoader(true));
    yield put(pendingGetAccounts());
    const {
        isActive
    } = actions.payload;

    const { error, data } = yield getAccounts({
        isActive
    });
    
    if (error) {
        yield put(rejectedGetAccounts(error.violations as any));
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedGetAccounts(data));
    yield put(switchGlobalLoader(false));
}


function* createAccountSaga(actions: HandleCreateAccount) {
    yield put(pendingCreateAccount());
    yield put(switchGlobalLoader(true));
    try {
        const { data, error } = yield createAccount(actions.payload.account);
        if (error) {
            if (error.code) {
                yield put(rejectedCreateAccount(error as any));
            } else {
                yield put(rejectedCreateAccount(error.violations as any));
            }
            yield put(switchGlobalLoader(false));
            return;
        }

        const getAccountsAction: HandleGetAccounts = {
            payload: {
                isActive: 1                
            },
            type: HANDLE_GET_ACCOUNTS,
        };
        yield put(resolvedCreateAccount(data));
        yield call(getAccountsSaga, getAccountsAction);
        yield put(switchGlobalLoader(false));
        yield call(actions.payload.callback);
    } catch (error) {
        yield put(switchGlobalLoader(false));
        yield put(rejectedCreateAccount(error.violations));
        return;
    }
}

function* viewAccountSaga(action: HandleViewAccount) {
    yield put(switchGlobalLoader(true));
    yield put(pendingViewAccount());
    const { error, data } = yield viewAccount(action.payload);

    if (error) {
        yield put(switchGlobalLoader(false));
        return;
    }

    yield put(resolvedViewAccount(data));
    yield put(switchGlobalLoader(false));
}

function* updateAccountSaga(action: HandleUpdateAccount) {
    yield put(switchGlobalLoader(true));
    const { account } = action.payload;
    yield put(pendingUpdateAccount());
    try {
        const { error, data } = yield updateAccount(account.id, account);
        if (error) {
            yield put(rejectedUpdateAccount(error));
            yield put(switchGlobalLoader(false));
            return;
        }

        const getAccountsAction: HandleGetAccounts = {
            payload: {
                isActive: 1,
            },
            type: HANDLE_GET_ACCOUNTS,
        };
        yield call(getAccountsSaga, getAccountsAction);
        yield put(resolvedUpdateAccount(data));
    } catch (error) {
        yield put(rejectedUpdateAccount(error));
        yield put(switchGlobalLoader(false));
        return;
    }

    if (typeof action.payload.callback === "function") {
        action.payload.callback();
    }

}

function* activateAccountSaga(action: HandleAccountActivate) {
    yield put(switchGlobalLoader(true));
    try {
        const { id, isActive } = action.payload;
        yield put(pendingAccountActivate());

        const { error } = yield activateAccount(id, isActive);
        if (error) {
            yield put(rejectedAccountActivate(error));
            yield put(switchGlobalLoader(false));
            return;
        }


        const getAccountsAction: HandleGetAccounts = {
            payload: {
                isActive: 1,
            },
            type: HANDLE_GET_ACCOUNTS,
        };
        yield call(getAccountsSaga, getAccountsAction);
        if(window.location.pathname !=="/admin-accounts"){
            window.location.href = "/admin-accounts";
        }
    } catch (error) {
        yield put(rejectedAccountActivate(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}

function* sendInviteSaga(action: HandleAccountSendInvite) {
    yield put(switchGlobalLoader(true));
    try {
        const { id } = action.payload;
        yield put(pendingAccountActivate());

        const { error } = yield sendInvite(id);
        if (error) {
            yield put(rejectedSendInvite(error));
            yield put(switchGlobalLoader(false));
            return;
        }
        // todo refresh

        yield put(resolvedSendInvite());

        const getAccountsAction: HandleGetAccounts = {
            payload: {
                isActive: 1,
            },
            type: HANDLE_GET_ACCOUNTS,
        };
        yield call(getAccountsSaga, getAccountsAction);
    } catch (error) {
        yield put(rejectedSendInvite(error));
        yield put(switchGlobalLoader(false));
        return;
    }

}

function* exportCsvSaga(action: HandleActiveExportCsv) {
    yield put(switchGlobalLoader(true));
    try {
    const { isActive } = action.payload;
        yield exportCsv(isActive);
        yield put(switchGlobalLoader(false));
        
    } catch (error) {
        yield put(switchGlobalLoader(false));
        return;
    }

}

export default function* AccountsSaga() {
    yield takeLeading(HANDLE_GET_ACCOUNTS, getAccountsSaga);
    yield takeLeading(HANDLE_VIEW_ACCOUNT, viewAccountSaga);
    yield takeLeading(HANDLE_CREATE_ACCOUNT, createAccountSaga);
    yield takeEvery(HANDLE_UPDATE_ACCOUNT, updateAccountSaga);
    yield takeEvery(HANDLE_ACCOUNT_ACTIVATE, activateAccountSaga);
    yield takeEvery(HANDLE_ACCOUNT_SEND_INVITE, sendInviteSaga);
    yield takeEvery(HANDLE_ACTIVE_EXPORT_CSV, exportCsvSaga);
    //yield takeEvery(HANDLE_CSV_ACCOUNT, csvAccountSaga);
}


