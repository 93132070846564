import React, { useEffect, useState } from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Checkbox,
    Modal,
    IconButton,
    TextField as OffTextField,
    PrimaryButton
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
import { handleSavePrepsByDeal, handleSavePreps, handleGetPrepsInfo, handleDeletePrepsInfo } from "store/dealTracker/dealTracker.action";
import { Dropdown } from 'primereact/dropdown';
import { HANDLE_GET_EMPLOYEES } from "store/employees/employees.types";
import { Calendar } from 'primereact/calendar';
import { convertLocalToUTCModified, convertUTCToLocalDateOnlyModified } from "utils/dateTimeFunctions";
import moment from "moment";
import masterDropdownValues from "constants/masterDropdownValues";
//import { TimePicker } from 'antd';
import { convertUTCToLocalFormatModified } from "utils/dateTimeFunctions";
import { TimePicker } from "components/timePicker/TimePicker"
import {  isUserMaster } from "utils/permissions/permissionsHelpers";

interface IProps {
    isOpen: boolean;
    needByDate: string;
    needByTime: string;
    carType: number;
    onDismiss: (res) => void;
    deal?: any;
    prepId?: number;
    locationId?: number;
}


//const stackTokens = { childrenGap: 7 };

export const VehiclePrepDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    needByDate,
    needByTime,
    carType,
    onDismiss,
    deal,
    prepId,
    locationId
}) => {
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const dispatch = useDispatch();
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const loading = useSelector((state: AppState) => state.employees.loading);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [sales, setSales] = useState([]);
    //const [timeValidationMsg, setTimeValidationMsg] = useState('');
    const [customerArrivalDate, setArrivalDate] = useState<any>(undefined);
    const [diasbleDeleteTradeInBtn, setDiasbleDeleteTradeInBtn] = useState(null);
    //const [customerArrivalTime, setArrivalTime] = React.useState(null);
    //const disabledSec = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
    //    36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]



    useEffect(() => {
        if (user) {

            if (isUserMaster(user.roles)) {
                setDiasbleDeleteTradeInBtn(false);
            }
            else if (user.defaultGroups && user.defaultGroups.some(x => x.defaultGroupId === 19)) {//Leadership Role
                setDiasbleDeleteTradeInBtn(false);
            }
            else {
                setDiasbleDeleteTradeInBtn(true);
            }

        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setSales([]);
        let sales = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return sales.push({ id: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            if (employees.results.some(x => x.id === user.id)) {
                formik.setValues(
                    {
                        ...formik.values,
                        'salesPersonId': user.id
                    }
                );
            }
            setSales(sales)
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            id: prepId,
            customerArrivalDate: "",
            customerArrivalTime: "",
            salesPersonId: "",
            model: "",
            stock: "",
            vehicleLocation: "",
            keyLocation: "",
            color: "",
            gas: "",
            salesNotes: "",
            washNotes: "",
            isCompleted: false,
            carType: carType,

        },
        validationSchema: Yup.object({
            customerArrivalDate: Yup.string().required('Customer arrival date required.'),
            customerArrivalTime: Yup.string().required('Customer arrival time required.'),
            salesPersonId: Yup.string().required('Sales person required.'),
            model: Yup.string().required('Model required.'),
            stock: Yup.string().required('Stock # required.'),
            vehicleLocation: Yup.string().required('Vehicle location required.'),
            keyLocation: Yup.string().required('Key location required.'),
            color: Yup.string().required('Color required.'),
            gas: Yup.string().required('Gas required.'),
            salesNotes: Yup.string(),
            washNotes: Yup.string(),
            isCompleted: Yup.boolean()
        }),
        onSubmit: (values) => {
            if (values.customerArrivalTime) {
                let utcStart = moment(convertTime12To24(values.customerArrivalTime), "HH:mm").utc();
                values.customerArrivalTime = utcStart.format()
            }
            if (needByTime) {
                let utcStart = moment(convertTime12To24(needByTime), "HH:mm").utc();
                values["needByTime"] = utcStart.format();
            }
            if (needByDate) {
                let dateValue = moment(new Date(moment(needByDate).format('MM-DD-YYYY') + ' ' + needByTime)).utc().format();
                values["needByDate"] = dateValue ? convertLocalToUTCModified(dateValue):''
            }
            //if (values.customerArrivalDate) {
            //    values.customerArrivalDate = moment(new Date(moment(values.customerArrivalDate).format('MM-DD-YYYY') + ' ' + values.customerArrivalTime)).utc().format(); 
            //}

            values.carType = carType;
            values.customerArrivalDate = values["needByDate"];
            if (deal) {
                dispatch<any>(handleSavePrepsByDeal({
                    formData: values,
                    dealId: deal.id,
                    callback: (response) => {
                        if (response) {
                            resetForm(response);
                        } else {
                            resetForm('Failed to Save the Data.');
                        }

                    }
                }));
            } else {
                values["locationId"] = locationId;
                dispatch<any>(handleSavePreps({
                    formData: values,
                    callback: (response) => {
                        if (response) {
                            resetForm(response);
                        } else {
                            resetForm('Failed to Save the Data.');
                        }

                    }
                }));
            }
        }
    });

    useEffect(() => {
        if (isOpen) {

            dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "salesPerson" } });

            if (prepId === 0) {
                let needTime = needByTime && Number(needByTime.substring(0, 2)) + (needByTime === '06:00 PM' ? 0 : 1);
                
                if (needByTime.includes('PM') && needByTime !== '12:00 PM') {
                    needTime = needTime + 12;
                }

                const arrivalTime = formatTime(needTime.toString());
               
                formik.setValues(
                    {
                        ...formik.values,
                        'stock': deal?.vehicle?.stockNumber,
                        'model': deal?.vehicle?.model,
                        'customerArrivalDate': moment(needByDate).format("YYYY-MM-DD"),
                        'customerArrivalTime': arrivalTime,
                        'salesPersonId': deal?.salesPerson1?.id
                    }
                );
                //setArrivalTime(moment(arrivalTime, 'h:mm a'));
                setArrivalDate(new Date(needByDate));
            } else if (prepId > 0) {
                dispatch<any>(handleGetPrepsInfo({
                    id: prepId, callback: (res) => {
                        if (res) {
                            formik.setValues(
                                {
                                    ...formik.values,
                                    'id': res.id,
                                    'stock': res.stock,
                                    'model': res.model,
                                    'color': res.color,
                                    'vehicleLocation': res.vehicleLocation,
                                    'keyLocation': res.keyLocation,
                                    'gas': res.gas,
                                    'isCompleted': res.isCompleted,
                                    'salesNotes': res.salesNotes,
                                    'washNotes': res.washNotes,
                                    'salesPersonId': res.salesPerson?.id,
                                    'customerArrivalDate': convertUTCToLocalFormatModified(res.customerArrivalDate, "YYYY-MM-DD"),
                                    'customerArrivalTime': extractTime(res.customerArrivalTime),
                                }
                            );
                            //setArrivalTime(moment(extractTime(res.customerArrivalTime), 'h:mm a'));
                            setArrivalDate(new Date(convertUTCToLocalFormatModified(res.customerArrivalDate, "YYYY-MM-DD")));
                        }


                    }
                }));
            }
        }
    }, [isOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    const formatTime = (hourString) => {
        const hour = +hourString % 24;
        return (hour % 12 || 12).toLocaleString('en-US',
                    {
                        minimumIntegerDigits: 2,
                        useGrouping: false
            }) + ":00 " + (hour < 12 ? "AM" : "PM");
    }


    const resetForm = (res) => {
        formik.setValues(
            {
                ...formik.values,
                'id': 0,
                'stock': '',
                'model': '',
                'color': '',
                'vehicleLocation': '',
                'keyLocation': '',
                'gas': '',
                'isCompleted': false,
                'salesNotes': '',
                'washNotes': '',
                'salesPersonId': ''
            }
        );
        //setTimeValidationMsg('');
        onDismiss(res);
    }



    const extractTime = (dateTime) => {
        var date = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format('hh:mm A')
    };

    const handleFormSubmit = () => {
        formik.handleSubmit();
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDropdownChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                "customerArrivalDate": val ? moment(val).format("YYYY-MM-DD") : ''
            }
        );
        setArrivalDate(new Date(convertUTCToLocalDateOnlyModified(val)));
    };

    const handleCheckBoxChange = () => {
        formik.setValues(
            {
                ...formik.values,
                'isCompleted': !formik.values.isCompleted
            }
        );
    }

    const handleArrivalTimeChange = (e: { value: any, target: any }) => {
        //setTimeValidationMsg('');
        formik.setValues(
            {
                ...formik.values,
                'customerArrivalTime': e.value
            }
        );
        //let customerArrivalTime: any = moment(timeString, 'h:mm a');
        //if (customerArrivalTime) {
        //    if (customerArrivalTime._d.getHours() > 6 && customerArrivalTime._d.getHours() < 19) {

        //        formik.setValues(
        //            {
        //                ...formik.values,
        //                'customerArrivalTime': convertTime12To24(customerArrivalTime._i)
        //            }
        //        );
        //        setArrivalTime(customerArrivalTime)
        //    } else {
        //        setTimeValidationMsg('Time range should from 7 am to 6 pm');
        //        resetArrivalTime();
        //    }

        //} else {
        //    resetArrivalTime();
        //}
    }

    //const resetArrivalTime = () => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            'customerArrivalTime': ''
    //        }
    //    );
    //    setArrivalTime('')
    //}

    const convertTime12To24 = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier?.toUpperCase() === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    const handleDeletePrep = () => {
        dispatch<any>(handleDeletePrepsInfo({
            id: prepId, callback: (res) => {
                if (res) {
                    resetForm({ id: prepId });
                } else {
                    resetForm('Failed to Delete the Data.');
                }
            }
        }));
    }

    return (

        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord dealRecordHeights  updateEmployeeDialogHeight"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <CustomFontText className="formTitle">Prep</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => resetForm('')}
                />
            </div>
            <div id="prep-section" className={contentStyles.body + " prep-popup-body"} >


                <Stack>
                    <Row>
                        <Col md={4}>
                            <label className="lbl-text">Need By</label>
                            <label className="lbl-text text-no-wrap">{moment(needByDate).format("MM/DD/YY") + ' ' + needByTime}</label>

                        </Col>
                        <Col md={8}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "120px" }}>
                                    <label style={{ display: "block" }} className="lbl-text required-Label">Customer Arrival</label>
                                    <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                        <Calendar
                                            id="customerArrivalDate"
                                            style={{ width: "100%" }}
                                            value={customerArrivalDate}
                                            showIcon
                                            onChange={(e) => handleDateChange(e.value)}
                                            monthNavigator
                                            yearNavigator
                                            disabled={true}
                                            yearRange={yearRange}
                                            appendTo="self"
                                        />

                                    </div>

                                </div>
                                <div className="arrival-time">
                                    <TimePicker
                                        beginLimit="07:00 AM"
                                        endLimit="06:00 PM"
                                        step={60}
                                        value={formik.values.customerArrivalTime}
                                        onTimeChange={(e) => handleArrivalTimeChange(e)}
                                    />

                                    {/*<TimePicker
                                        minuteStep={15}
                                        showNow={false}
                                        // use12Hours={true}
                                        //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                        hideDisabledOptions={true}
                                        format="h:mm a"
                                        value={customerArrivalTime}
                                        onChange={handleArrivalTimeChange}
                                        allowClear={false}
                                        disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 19, 20, 21, 22, 23, 24]}
                                        getPopupContainer={() => document.getElementById("prep-section")}
                                        disabledSeconds={() => disabledSec}
                                        disabledMinutes={() => disabledSec}
                                        placeholder=""
                                    />*/}
                                    <span className="error-message text-no-wrap">
                                        {
                                            formik.touched.customerArrivalTime &&
                                            formik.errors.customerArrivalTime &&
                                            formik.errors.customerArrivalTime.toString()
                                        }
                                    </span>
                                    {/*<TextField
                                        id="customerArrivalTime"
                                        label=""
                                        type="time"
                                        value={formik.values.customerArrivalTime}
                                        onChange={(e) => handleTimeChange(e)}
                                        // defaultValue="07:30"
                                        className=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            //step: 3600, // 5 min
                                            list:"times"
                                        }}
                                    />
                                    <datalist id="times">
                                        <option value="07:00:00" />
                                        <option value="08:00:00" />
                                        <option value="09:00:00" />
                                        <option value="10:00:00" />
                                        <option value="11:00:00" />
                                        <option value="12:00:00" />
                                        <option value="13:00:00" />
                                        <option value="14:00:00" />
                                        <option value="15:00:00" />
                                        <option value="16:00:00" />
                                        <option value="17:00:00" />
                                        <option value="18:00:00" />
                                    </datalist>*/}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text required-Label">Sales Person</label>
                                <Dropdown
                                    id="salesPersonId"
                                    placeholder=""
                                    value={formik.values.salesPersonId}
                                    options={sales}
                                    onChange={handleDropdownChange}
                                    optionLabel="text"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={true}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OffTextField
                                id="model"
                                name="model"
                                label="Model"
                                placeholder=""
                                autoComplete="off"
                                value={formik.values.model}
                                required={true}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            //errorMessage={
                            //    formik.touched && formik.touched.model &&
                            //    formik.errors && formik.errors.model &&
                            //    formik.errors.model.toString()
                            //}
                            />
                        </Col>
                        <Col>
                            <OffTextField
                                id="stock"
                                name="stock"
                                label="Stock #"
                                placeholder=""
                                autoComplete="off"
                                value={formik.values.stock}
                                required={true}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            //errorMessage={
                            //    formik.touched && formik.touched.stock &&
                            //    formik.errors && formik.errors.stock &&
                            //    formik.errors.stock.toString()
                            //}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text required-Label">Vehicle Location</label>
                                <Dropdown
                                    id="vehicleLocation"
                                    placeholder=""
                                    value={formik.values.vehicleLocation}
                                    options={masterDropdownValues.prepVehicleLocationOptions}
                                    onChange={handleDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <OffTextField
                                id="color"
                                name="color"
                                label="Color"
                                placeholder=""
                                autoComplete="off"
                                value={formik.values.color}
                                required={true}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            //errorMessage={
                            //    formik.touched && formik.touched.color &&
                            //    formik.errors && formik.errors.color &&
                            //    formik.errors.color.toString()
                            //}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text required-Label">Key Location</label>
                                <Dropdown
                                    id="keyLocation"
                                    placeholder=""
                                    value={formik.values.keyLocation}
                                    options={masterDropdownValues.prepKeyLocationOptions}
                                    onChange={handleDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dd-control">
                                <label className="lbl-text required-Label">Gas</label>
                                <Dropdown
                                    id="gas"
                                    placeholder=""
                                    value={formik.values.gas}
                                    options={masterDropdownValues.gasOptions}
                                    onChange={handleDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OffTextField
                                id="salesNotes"
                                name="salesNotes"
                                label="Sales Notes"
                                placeholder=""
                                autoComplete="off"
                                value={formik.values.salesNotes}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                multiline={true}
                            //errorMessage={
                            //    formik.touched && formik.touched.salesNotes &&
                            //    formik.errors && formik.errors.salesNotes &&
                            //    formik.errors.salesNotes.toString()
                            //}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <OffTextField
                                id="washNotes"
                                name="washNotes"
                                label="Wash Notes"
                                placeholder=""
                                autoComplete="off"
                                value={formik.values.washNotes}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                multiline={true}
                                errorMessage={
                                    formik.touched && formik.touched.washNotes &&
                                    formik.errors && formik.errors.washNotes &&
                                    formik.errors.washNotes.toString()
                                }
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "5px" }}>
                        <Col>
                            <Checkbox
                                label="Complete:"
                                id="isCompleted"
                                onChange={handleCheckBoxChange}
                                checked={formik.values.isCompleted}
                                boxSide="end"
                            />
                        </Col>
                    </Row>
                </Stack>
            </div>
            <div className={contentStyles.footer}>
                {
                    prepId ? <Stack tokens={styles.buttonTokens} className={contentStyles.footerBtns} horizontal horizontalAlign="center">
                        {
                            prepId ?
                                <PrimaryButton
                                    className="deleteDialogDelBut"
                                    disabled={diasbleDeleteTradeInBtn}
                                    onClick={handleDeletePrep}
                                >Delete
                            </PrimaryButton>
                                : null
                        }
                        <PrimaryButton
                            disabled={!formik.dirty || !formik.isValid}
                            onRenderIcon={renderSpinner}
                            text="Save"
                            onClick={handleFormSubmit}
                        />
                    </Stack> :
                        <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">
                            <PrimaryButton
                                disabled={!formik.dirty || !formik.isValid}
                                onRenderIcon={renderSpinner}
                                text="Save"
                                onClick={handleFormSubmit}
                            />
                        </Stack>
                }
            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 600,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 95,
        paddingRight: 95,
        paddingBottom: 15,
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        paddingLeft: 95,
        paddingRight: 95,
        paddingBottom: 15,
    },
    footerBtns: {
        display: "flex",
        justifyContent: "space-between",
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
