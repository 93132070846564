import React, { useEffect, useState } from "react";
import {
    IPersonaSharedProps,
    IPersonaStyles,
    Persona,
    PersonaSize,
    PersonaPresence,
} from "@fluentui/react/lib/Persona";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import PersonCalloutListItem from "components/navigation/person/personCalloutListItem/PersonCalloutListItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "store/auth/auth.action";
import { AppState } from "store";
import { Only } from "components/layouts/only/Only";

const personaStyles: IPersonaStyles = {
    details: {
        display: "flex",
        alignItems: "center",
    },
    optionalText: undefined,
    primaryText: {
        paddingTop: 15,
        // paddingBottom: 10,
        fontSize: 20,
    },
    root: {
        height: "100%",
        marginTop: 35,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    secondaryText: {
        fontSize: 18,
        paddingTop: 2,
        paddingBottom: 2,


    },

    tertiaryText: undefined,
    textContent: undefined,
};

const styles: IStackStyles = {
    root: {
        paddingBottom: 20,
    },
};

interface IProps {
    setCalloutVisible: (boolean) => void;
}

export const PersonaCalloutContent: React.FC<IProps> = ({
    setCalloutVisible,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userProfile = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );
    const guidedSetup = useSelector(
        (state: AppState) => state.primarySetup.guidedSetup
    );
    const [showMenu, setShowMenu] = useState(guidedSetup && guidedSetup.isCompleted);
    useEffect(() => {
            if (guidedSetup && guidedSetup.isCompleted) {
                setShowMenu(location.pathname.indexOf("accessRequested") === -1);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        setShowMenu(guidedSetup && guidedSetup.isCompleted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidedSetup])

    const examplePersona: IPersonaSharedProps = {
        imageUrl: userProfile.avatar
            ? `${process.env.REACT_APP_IMAGE_SERVER}/${userProfile.avatar}`
            : "",
        imageInitials: userProfile?.firstName ? `${userProfile.firstName[0]} ${userProfile?.lastName ? userProfile.lastName[0] : ""}` : "",
        text: userProfile?.firstName ? `${userProfile.firstName} ${userProfile?.lastName ? userProfile.lastName : ""}` : "",

        secondaryText: userProfile.email ? userProfile.email : "",
    };

    // const handleDealerProfileClick = () => {
    //     history.push("/dealer-profile");
    //     setCalloutVisible(false);
    // };

    const handleAccountSettingsClick = () => {
        navigate("/account-settings");
        setCalloutVisible(false);
    };

    return (
        <Stack styles={styles} tokens={{ childrenGap: 10 }}>
           
            <Persona
                {...examplePersona}
                styles={personaStyles}
                size={PersonaSize.size100}
                presence={PersonaPresence.none}
                imageAlt="Annie Lindqvist, status is online"
                showSecondaryText
            />
            
            <Only when={showMenu}>
                <PersonCalloutListItem
                    iconName="Settings"
                    label="My Profile"
                    onClick={handleAccountSettingsClick}
                />
            </Only>
            {/* <Only when={Boolean(userProfile.roles?.includes("ROLE_MASTER")) && showMenu}>
                <PersonCalloutListItem
                    iconName="PageEdit"
                    label="My Dealerships"
                    onClick={handleDealerProfileClick}
                />
            </Only> */}

            <PersonCalloutListItem
                iconName="PlugDisconnected"
                label="Log Out"
                onClick={() => dispatch<any>(handleLogout())}
            />
        </Stack>
    );
};
