import React, { FunctionComponent, useEffect, useState } from "react";
import {
  DefaultButton,
  IconButton,
  Modal,
  PrimaryButton,
  TextField,
  Toggle,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  cancelIcon,
  contentStyles as contentStyle,
  iconButtonStyles,
} from "constants/styles";
import { dealStatuses, dealTypes, ProspectType } from "constants/constants";
import ListItem from "components/ListItem/ListItem";
import { EditProspectDialog } from "components/dialogs/prospects/EditProspectDialog";
import { CosignersDialog } from "components/dialogs/deals/CosignersDialog";
import AddProspectToDealDialog from "components/dialogs/deals/AddProspectToDealDialog";
import AddVehicleToDealDialog from "components/dialogs/deals/AddVehicleToDealDialog";
import { EditInventoryDialog } from "components/dialogs/editInventoryDialog/EditInventoryDialog";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { AppState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { handleCreateDeal } from "store/deals/deals.action";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IProspect } from "types/prospectTypes";
import { IInventory } from "types/inventoryTypes";
import { ICreateDeal } from "types/dealTypes";
import { useNavigate , useLocation } from "react-router-dom";
import { EditCosignerDialog } from "components/dialogs/deals/EditCosignerDialog";
import { EditProspectFromCosignersDialog } from "components/dialogs/deals/EditProspectFromCosignersDialog";

const contentStyles = contentStyle(600, "auto");

enum FormStep {
  None,
  EditProspect,
  ChangeAssignedProspect,
  AssignVehicleToDeal,
  Cosigners,
  EditInventory,
  CosignersFromProspect,
  EditCosignerFromCosigners,
  EditProspectFromCosigners,
}

interface IProps {
  onDismiss: () => void;
  /**
   * The prospect object from which Deal must be created
   * */
  createFromProspect?: IProspect;
  createFromInventory?: IInventory;
}

export const AddDealDialog: FunctionComponent<IProps> = ({
  onDismiss,
  createFromProspect,
  createFromInventory,
}) => {
  const loading = useSelector((state: AppState) => state.deals.loading);
  const [selectedProspect, setSelectedProspect] = useState<IProspect | null>(
    null
  );

  const [index, setIndex] = useState(null);

  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null
  );
  // const [selectedCosigners, setSelectedCosigners] = useState<
  //   IProspect[] | null
  // >(null); /*@TODO: change type to Cosigner*/
  const dispatch = useDispatch();
  const navigate = useNavigate ();
  const location = useLocation();

  const [selectedType, setSelectedType] = useState(1);

  useEffect(() => {
    console.log(location);
    if (createFromProspect && (location.state as any).action === "add") {
      setSelectedProspect({ ...createFromProspect });
      // formik.setFieldValue('assignedProspect', {...createFromProspect});
      // formik.setFieldTouched('assignedProspect', true)
    }
    if (createFromInventory && (location.state as any).action === "add")
      setSelectedInventory({ ...createFromInventory });

    return () => {
      formik.resetForm();
      setSelectedProspect(null);
      setSelectedInventory(null);
      navigate("/deals", {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      setDealType: 2,
      dealStatus: "Pending",
      hasOfferBeenSent: false,
      assignedProspect: createFromProspect ? { ...createFromProspect } : null,
      assignedVehicle: createFromInventory ? { ...createFromInventory } : null,
      cosigners: [null, null],
    },
    validationSchema: Yup.object({
      setDealType: Yup.number().required("Required"),
      dealStatus: Yup.string().required("Required"),
      hasOfferBeenSent: Yup.boolean(),
      assignedProspect: Yup.object().required("Required").nullable(),
      assignedVehicle: Yup.object().required("Required").nullable(),
      cosigners: Yup.array().of(Yup.object().nullable()),
    }),
    onSubmit: (values) => {
      const dealToSend: ICreateDeal = {
        type: +values.setDealType,
        status: values.dealStatus,
        isSent: values.hasOfferBeenSent,
        prospect: selectedProspect ? values.assignedProspect.id : null,
        inventory: selectedInventory ? values.assignedVehicle.id : null,
        cosigners: values.cosigners,
      };

      dispatch<any>(
        handleCreateDeal({
          deal: dealToSend,
          callback: () => {
            onDismiss();
          },
        })
      );
    },
  });

  const [stepForm, setStepForm] = React.useState(FormStep.None);

  // const handleClose = () => {
  //   setStepForm(FormStep.None);
  //   onDismiss();
  // };

  const handleAddDeal = () => {
    // dispatch<any>(handleCreateDeal());
    formik.handleSubmit();
  };

  const updateProspect = (prospect: IProspect) => {
    setSelectedProspect(prospect);
    setStepForm(FormStep.None);
    formik.setFieldValue("assignedProspect", prospect);
  };

  const updateInventory = (inventory: IInventory) => {
    inventory.id = selectedInventory?.id;

    setSelectedInventory(inventory);
    setStepForm(FormStep.None);
    formik.setFieldValue("assignedInventory", inventory.id);
  };

  // const updateCosigners = (cosigners: IProspect[]) => {
  //   setSelectedCosigners([...cosigners]);
  // };

  const renderSpinner = () => {
    return loading ? <Spinner styles={{}} size={SpinnerSize.medium} /> : null;
  };

  const _renderDialog = () => {
    switch (stepForm) {
      case FormStep.None:
        return null;
      case FormStep.EditProspect:
        return (
          <EditProspectDialog
            onDismiss={() => setStepForm(FormStep.None)}
            prospect={selectedProspect ? selectedProspect : null}
            returnProspect={(prospect: IProspect) => updateProspect(prospect)}
          />
        );
      case FormStep.EditInventory:
        return (
          <EditInventoryDialog
            onDismiss={() => setStepForm(FormStep.None)}
            selectedInventory={selectedInventory ? selectedInventory : null}
            returnInventory={(inventory: any) => updateInventory(inventory)}
          />
        );

      case FormStep.ChangeAssignedProspect:
        return (
          <AddProspectToDealDialog
            onDismiss={() => setStepForm(FormStep.None)}
            // onDismiss={() => setStepForm(FormStep.None)}
            onProspectSelection={(prospect: IProspect) => {
              updateProspect(prospect);
            }}
            selectedProspect={selectedProspect ? selectedProspect : null}
            type={selectedType}
          />
        );
      case FormStep.AssignVehicleToDeal:
        return (
          <AddVehicleToDealDialog
            onDismiss={() => setStepForm(FormStep.None)}
            onInventorySelection={(inventory: IInventory) => {
              // console.log(inventory);
              setSelectedInventory(inventory);
              formik.setFieldValue("assignedVehicle", inventory);
              setStepForm(FormStep.None);
            }}
            selectedInventory={selectedInventory ? selectedInventory : null}
          />
        );

      case FormStep.EditCosignerFromCosigners:
        return (
          <EditCosignerDialog
            index={index}
            cosigners={formik.values.cosigners}
            onDismiss={() => setStepForm(FormStep.Cosigners)}
            setCosigner={(cosigner) => {
              formik.setFieldValue(
                "cosigners",
                index === 0
                  ? [cosigner, formik.values.cosigners[1]]
                  : [formik.values.cosigners[0], cosigner]
              );
            }}
          />
        );
      case FormStep.EditProspectFromCosigners:
        return (
          <EditProspectFromCosignersDialog
            onDismiss={() => setStepForm(FormStep.Cosigners)}
            prospect={formik.values.cosigners[index]}
            returnProspect={(prospect) => {
              formik.setFieldValue(
                "cosigners",
                index === 0
                  ? [prospect, formik.values.cosigners[1]]
                  : [formik.values.cosigners[0], prospect]
              );
            }}
          />
        );

      case FormStep.Cosigners:
        return (
          <CosignersDialog
            formik={formik}
            onShowAddFromProspect={() =>
              setStepForm(FormStep.ChangeAssignedProspect)
            }
            onCosignersUpdate={
              (cosigners: IProspect[]) => {}
              // updateCosigners(cosigners)
            }
            onShowEditProspect={(prospectType, idx) => {
              setIndex(idx);

              if (prospectType === ProspectType.Prospect) {
                setStepForm(FormStep.EditProspectFromCosigners);
              } else if (prospectType === ProspectType.Cosigner) {
                setStepForm(FormStep.EditCosignerFromCosigners);
              } else {
                console.log("nigdy");
              }
            }}
            onClose={onDismiss}
            onDismiss={() => setStepForm(FormStep.None)}
          />
        );
      default:
        console.log(`default switch`);
    }
  };

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
    );
  };

  const handleToggle = () => {
    formik.setFieldValue("hasOfferBeenSent", !formik.values.hasOfferBeenSent);
  };

  return (
    <>
      {_renderDialog()}

      <Modal
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <CustomFontText>Create New Deal</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack>
            <Dropdown
              id="setDealType"
              label="Set Deal Type"
              required={true}
              selectedKey={formik.values.setDealType}
              onChange={handleDropdownChange}
              placeholder="any..."
              options={dealTypes}
              // styles={dropdownStyles}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.setDealType && formik.errors.setDealType
                  ? formik.errors.setDealType.toString()
                  : null
              }
            />
            <Dropdown
              id="dealStatus"
              label="Deal Status"
              required={true}
              selectedKey={formik.values.dealStatus}
              onChange={handleDropdownChange}
              placeholder="any..."
              options={dealStatuses}
              // styles={dropdownStyles}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.dealStatus && formik.errors.dealStatus
                  ? formik.errors.dealStatus.toString()
                  : null
              }
            />

            <span className={contentStyles.label+" required-Label"}>Assigned Prospect</span>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              {/*<Stack.Item grow={3}>*/}
              <div style={{ flex: 4 }}>
                <TextField
                  id="assignedProspect"
                  name="assignedProspect"
                  // label="Assigned Prospect"
                  value={
                    selectedProspect
                      ? `${selectedProspect?.firstName} ${selectedProspect?.middleName} ${selectedProspect?.lastName}`
                      : ""
                  }
                  disabled
                  errorMessage={
                    formik.touched.assignedProspect &&
                    formik.errors.assignedProspect
                      ? formik.errors.assignedProspect.toString()
                      : null
                  }
                />
              </div>
              {/*</Stack.Item>*/}
              {/*<Stack.Item grow={1}>*/}
              {selectedProspect && (
                <DefaultButton
                  style={{ flex: 2 }}
                  disabled={!selectedProspect}
                  iconProps={{ iconName: "RedEye" }}
                  text="View Detail"
                  onClick={() => setStepForm(FormStep.EditProspect)}
                />
              )}
              {/*</Stack.Item>*/}
            </Stack>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              {selectedProspect ? (
                <PrimaryButton
                  style={{
                    marginTop: 10,
                  }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "ReminderPerson" }}
                  text={"Change Assigned Prospect"}
                  onClick={() => {
                    setStepForm(FormStep.ChangeAssignedProspect);
                    setSelectedType(1);
                  }}
                />
              ) : (
                <DefaultButton
                  text={"Assign Assigned Prospect"}
                  style={{
                    marginTop: 10,
                  }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "ReminderPerson" }}
                  onClick={() => {
                    setStepForm(FormStep.ChangeAssignedProspect);
                    setSelectedType(1);
                  }}
                />
              )}
            </Stack>
            <span className={contentStyles.label+" required-Label"}>Assigned Vehicle</span>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              <div style={{ flex: 4 }}>
                <TextField
                  id="assignedVehicle"
                  name="assignedVehicle"
                  // label="Assigned Vehicle"
                  value={
                    selectedInventory
                      ? `${selectedInventory.year} ${selectedInventory.make} ${selectedInventory.model}`
                      : ""
                  }
                  disabled
                  errorMessage={
                    formik.touched.assignedVehicle &&
                    formik.errors.assignedVehicle
                      ? formik.errors.assignedVehicle.toString()
                      : null
                  }
                />
              </div>
              {selectedInventory && (
                <DefaultButton
                  style={{ flex: 2 }}
                  disabled={!selectedInventory}
                  iconProps={{ iconName: "RedEye" }}
                  text="View Detail"
                  onClick={() => setStepForm(FormStep.EditInventory)}
                />
              )}
            </Stack>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              {selectedInventory ? (
                <PrimaryButton
                  style={{
                    marginTop: 10,
                  }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "Car" }}
                  text={"Change Vehicle"}
                  onClick={() => setStepForm(FormStep.AssignVehicleToDeal)}
                />
              ) : (
                <DefaultButton
                  text="Assign Vehicle to Deal"
                  style={{
                    marginTop: 10,
                  }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "Car" }}
                  onClick={() => setStepForm(FormStep.AssignVehicleToDeal)}
                />
              )}
            </Stack>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <ListItem
                onClick={() => {
                  setStepForm(FormStep.Cosigners);
                  setSelectedType(2);
                }}
                subTitles={
                  formik.values.cosigners[0] === null
                    ? ["No co-signatories assigned"]
                    : [
                        `${formik.values.cosigners[0].firstName} ${formik.values.cosigners[0].middleName} ${formik.values.cosigners[0].lastName}`,
                      ]
                }
                title="Co-Signers"
              />
            </div>
            <Stack.Item>
              <Stack>
                <Toggle
                  id="hasOfferBeenSent"
                  checked={formik.values.hasOfferBeenSent}
                  onBlur={formik.handleBlur}
                  onChange={handleToggle}
                  label="The offer has been sent"
                  inlineLabel
                  onText="send"
                  offText="not send"
                />
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
        <div className={contentStyles.footer}>
          <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
            <DefaultButton text="Close" onClick={onDismiss} />
            <PrimaryButton
              disabled={!formik.dirty || !formik.isValid}
              onRenderIcon={renderSpinner}
              text="Add Deal"
              onClick={handleAddDeal}
              // loading={loading}
            />
          </Stack>
        </div>
      </Modal>
    </>
  );
};
