import client from "services/api/client";

export const getGroups = () => {
  return client(`user/master/auth/groups?limit=${100}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const viewGroup = (id) => {
  return client(`user/master/auth/group/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateGroup = (id: number, name: string) => {
  return client(`user/master/auth/group/${id}`, {
    body: {
      name: name,
      text: name,
    },
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const createGroup = (name: string) => {
  return client(`user/master/auth/group`, {
    body: {
      name: name,
      text: name,
    },
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteGroupApi = (id: string) => {
  return client(`user/master/auth/group/${id}`, {
    body: null,
    method: "DELETE"
  }).then(
      (data) => {
        return { data, error: null };
      },
      (error) => {
        return { data: null, error };
      }
  );
};

export const addEmployeeToGroup = (employeeId: number, groupId: number) => {
  return client(`user/master/auth/employee/${employeeId}/group/${groupId}`, {
    body: null,
    method: "POST",
  }).then(
    () => {
      return { error: null };
    },
    (error) => {
      return { error };
    }
  );
};

export const removeEmployeeFromGroup = (employeeId: number) => {
  return client(`user/master/auth/employee/${employeeId}`, {
    body: null,
    method: "DELETE",
  }).then(
    () => {
      return { error: null };
    },
    (error) => {
      return { error };
    }
  );
};
