import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";

const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: () => void;
    isOpen: boolean;
    openEditDialog: () => void;
    openSearchDialog: () => void;
}

export const CustomerNotFoundDialog: FunctionComponent<IProps> = ({
    onDismiss,
    isOpen,
    openEditDialog,
    openSearchDialog,
}) => {

    const handleSearchAgain = () => {
        openSearchDialog();
    };
    const handleCreateCustomer = () => {
         openEditDialog();
    };
    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Customer # Not Found</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text"> This Customer Number was not found. </label>
                    </div>

                    <div>
                        <label className="lbl-text"> Return to customer search or create a new customer. </label>
                    </div>
                </div>
            </div>
            <div className={contentStyles.footer + " inventory-popup-footer"} style={{ padding: "40px 60px " }}>
                 <Row className="inventoryRowSection footerBtn cusNoNotFoundFoot">
                      <Col>
                          <PrimaryButton
                              style={{ float: "left",backgroundColor:"#af2b01",width:'75%'}}
                              onClick={handleSearchAgain}
                              text="Search Again"
                              className="btnHoverColor cusNoNotFoundDiaBtnHeight"
                          />
                      </Col>
                      <Col>
                          <PrimaryButton
                              text="Create New Customer"
                              style={{ float: "right" }}
                              onClick={handleCreateCustomer}
                              className=" cusNoNotFoundDiaBtnHeight"
                          />

                      </Col>
                    </Row>
            </div>
        </Modal>
    );
};



