import React, { useEffect, useState } from "react";
import { removeSnackbar } from "store/notifier/notifier.action";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { INotification, NotificationType } from "types/notificationType";
import { MessageBar, MessageBarType} from '@fluentui/react';

const Notifier = () => {
    const [messageBarType, setMessageBarType] = useState(MessageBarType.info);
    const dispatch = useDispatch();
    const notification: INotification = useSelector(
        (state: AppState) => state.notifier.notification
    );

    useEffect(() => {
        if (Boolean(notification)) {
            console.log('Notify Type:' + notification.type)
            switch (notification.type) {
                case NotificationType.success:
                    setMessageBarType(MessageBarType.success);
                    break;
                case NotificationType.warning:
                    setMessageBarType(MessageBarType.warning);
                    break;
                case NotificationType.error:
                    setMessageBarType(MessageBarType.error);
                    break;
            }
           
            setTimeout(() => {
                dispatch<any>(removeSnackbar());
            }, 5000);
        }
    }, [notification, dispatch]);

    const closeNotify = () => {
        dispatch<any>(removeSnackbar());
    }

    return (
        Boolean(notification) && (
            <MessageBar
                messageBarType={messageBarType}
                isMultiline={false}
                onDismiss={closeNotify}
                dismissButtonAriaLabel="Close"
            >
                {notification.message}

            </MessageBar>
        )
    );
};

export default Notifier;
