import { put, call, takeLeading, takeEvery } from "redux-saga/effects";

import {
  pendingGetLocations,
  resolvedGetLocations,
  rejectedGetLocations,
  pendingCreateLocation,
  resolvedCreateLocation,
  rejectedCreateLocation,
  pendingViewLocation,
  resolvedViewLocation,
  pendingDeleteLocation,
  resolvedDeleteLocation,
  rejectedDeleteLocation,
  handleGetLocations,
  pendingUpdateLocation,
  rejectedUpdateLocation
} from "store/locations/locations.action";

import {
  getLocations,
  createLocations,
  viewLocation,
  deleteLocation,
  updateLocation,
  addLocationLogo,
  getPostVacation,
  getVacationStats
} from "store/locations/locations.api";

import {
  HANDLE_CREATE_LOCATION,
  HANDLE_GET_LOCATIONS,
  HandleCreateLocation,
  HANDLE_VIEW_LOCATION,
  HandleViewLocation,
  HandleDeleteLocation,
  HandleUpdateLocation,
  HANDLE_UPDATE_LOCATION,
  HANDLE_DELETE_LOCATION,
  HandleGetPostVacation,
  HANDLE_GET_POST_VACATION,
} from "store/locations/locations.types";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

function* getLocationsSaga(action: any) {
  
console.log('======SAGA!!!!!!!!!!!!!!')

  yield put(switchGlobalLoader(true));

  yield put(pendingGetLocations());
  try {
    const { data } = yield getLocations();
    // console.log(data)

    yield put(resolvedGetLocations(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetLocations(error.violations as any));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* getPostVacationSaga(action: HandleGetPostVacation) {  
    yield put(switchGlobalLoader(true));
    try {
      let vacation = {
        vacationList: null,
        vacationStats: null
      }
      const { data } = yield getPostVacation(action.payload.id, action.payload.method, action.payload.data);
      vacation['vacationList'] = data;
      const { stats } = yield getVacationStats(action.payload.id);
      vacation['vacationStats'] = stats;
      yield call(action.payload.callback(vacation));
      
      yield put(switchGlobalLoader(false));
    } catch (error) {
      yield put(switchGlobalLoader(false));
      return;
    }
}

function* createLocationSaga(action: HandleCreateLocation) {
  yield put(switchGlobalLoader(true));
  yield put(pendingCreateLocation());
  try {
      const { data, error } = yield createLocations(action.payload.location);
      if (error) {
          yield put(rejectedCreateLocation(error));
          yield put(switchGlobalLoader(false));
          return;
      }
      const locationLogo: any = action.payload.location.img;
      if (locationLogo && typeof locationLogo.name == 'string') {
          const formData = new FormData();
          formData.append("logo", locationLogo, (locationLogo as any).name);
          const { error } = yield addLocationLogo(data.id, formData);
          if (error) {
              yield put(rejectedCreateLocation(error));
              yield put(switchGlobalLoader(false));
              return;
          }
      }
    yield put(resolvedCreateLocation(data));
    yield call(action.payload.callback);
    yield put(handleGetLocations());

    yield put(switchGlobalLoader(false));
  } catch (error) {
      yield put(rejectedCreateLocation(error.violations as any));
      yield put(switchGlobalLoader(false));
    return;
  }
}

function* viewLocationSaga(action: HandleViewLocation) {
  yield put(switchGlobalLoader(true));

  yield put(pendingViewLocation());
  try {
    const { data } = yield viewLocation(action.payload);

    yield put(resolvedViewLocation(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* deleteLocationSaga(action: HandleDeleteLocation) {
  yield put(switchGlobalLoader(true));
  const { id, callback } = action.payload;
  // console.log(action.payload);
  yield put(pendingDeleteLocation());
    const { error } = yield deleteLocation(id);
    if (error) {
        yield put(rejectedDeleteLocation(error));
        typeof callback === "function" && callback(error.code === 400 ? error.message : 'Something went wrong');
        yield put(switchGlobalLoader(false));
        return;
    }
  typeof callback === "function" && callback()
  yield put(resolvedDeleteLocation());
  yield put(handleGetLocations());
  yield put(switchGlobalLoader(false));
}

function* updateLocationSaga(action: HandleUpdateLocation) {
  yield put(pendingUpdateLocation());
  yield put(switchGlobalLoader(true));

  const { location, callback } = action.payload;
    
    const { error } = yield updateLocation(location.id, location);
    if (error) {
        yield put(rejectedCreateLocation(error));
        yield put(switchGlobalLoader(false));
        return;
    }
    const locationLogo: any = location.img;
    if (locationLogo && typeof locationLogo.name == 'string') {
        const formData = new FormData();
        formData.append("logo", locationLogo, (locationLogo as any).name);
        const { error } = yield addLocationLogo(location.id, formData);
        if (error) {
            yield put(rejectedUpdateLocation(error));
            yield put(switchGlobalLoader(false));
            return;
        }
    }
  typeof callback === "function" && callback()
  
  yield put(handleGetLocations());

  yield put(switchGlobalLoader(false));
}

export default function* LocationsSaga() {
  yield takeLeading(HANDLE_GET_LOCATIONS, getLocationsSaga);
  yield takeLeading(HANDLE_GET_POST_VACATION, getPostVacationSaga);
  yield takeLeading(HANDLE_CREATE_LOCATION, createLocationSaga);
  yield takeLeading(HANDLE_VIEW_LOCATION, viewLocationSaga);
  yield takeEvery(HANDLE_DELETE_LOCATION, deleteLocationSaga);
  yield takeEvery(HANDLE_UPDATE_LOCATION, updateLocationSaga);
}
