import React from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const contentStyles = content(750, "auto");

interface IProps {
    onDismiss: () => void;
    conflictData: any;
    onSave: () => void;
}

export const DealerTradeFoundDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    conflictData,
    onSave
}) => {
    
    const tradeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Date</span>
                <span>{rowData.tradeDate} </span>
            </React.Fragment>
        );
    };
    const managerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Mgr</span>
                <span>{rowData.tradeMgr} </span>
            </React.Fragment>
        );
    };
    const typeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Type</span>
                <span>{rowData.tradeType} </span>
            </React.Fragment>
        );
    };
    const matchedOnBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Matched On</span>
                <div style={{display:"grid"}}>
                    {rowData.vin && <span>Vin: {rowData.vin} </span>}
                    {rowData.stockNumber && <span>Stock: {rowData.stockNumber} </span>}
                    {rowData.orderNumber && <span>Order: {rowData.orderNumber} </span>}
                </div>
            </React.Fragment>
        );
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
            className="vehicleFoundDialog"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Dealer Trade Found</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body} style={{ paddingLeft: "20px" }}>
                <div style={{ marginLeft: "25px" }}>
                    <div className="datatable-filter-demo noMaxWidthTable">
                        <div className="card cdr-cpo-tbl">
                            <DataTable
                                value={conflictData}
                                className="p-datatable-customers"
                                paginator={false}
                                emptyMessage="No data found.">
                                <Column sortable field="tradeDate" header="Trade Date" body={tradeDateBodyTemplate} />
                                <Column sortable style={{ width: '9em' }} field="tradeMgr" header="Trade Mgr" body={managerNameBodyTemplate} />
                                <Column sortable field="tradeType" header="Type" body={typeBodyTemplate} />
                                <Column sortable style={{ width: '15em' }} field="matchedOn" header="Matched On" body={matchedOnBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center" }}>
                    <PrimaryButton
                        text="OK"
                        onClick={onSave}
                    />
                </div>
            </div>
        </Modal>
    );
};



