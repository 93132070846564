import React, { FunctionComponent,useEffect} from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    TextField,
    IconButton,
    Stack,
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleCreateSelectListItem, handleUpdateSelectListItem } from "store/selectList/selectList.action";
import { useDispatch } from "react-redux";


const contentStyles = content(600, "auto");

interface IProps {  
    category: string;
    title: string;
    onDismiss: (errorMsg) => void;
    formData: any;
    updatePermission: boolean;
    addPermission: boolean;
}

const emptyForm = {
	name: "", newVehicleAccountCode: "", usedVehicleAccountCode: ""
};
export const AddEditListDialog: FunctionComponent<IProps> = ({
    category,
    title,
    onDismiss,
    formData,
    updatePermission,
    addPermission
}) => {
  
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
         return this.matches(/^\d+$/, 'Numerals only allowed')
    });

   const formik = useFormik({
       initialValues: {
			...emptyForm,
		},
       validationSchema: Yup.object({
           name: Yup.string().required("Required"),
           newVehicleAccountCode: Yup.string(),
           usedVehicleAccountCode: Yup.string()
           }),
           onSubmit: (values) => {
               
               if (category !== 'financeProducts') {
                   delete values.newVehicleAccountCode;
                   delete values.usedVehicleAccountCode;
               }
           if(formData && formData.id > 0){

                dispatch<any>(
                     handleUpdateSelectListItem(
                         {category,
                         id:formData.id,
                         formData:values,
                         callback: (res) => {
                             if(res){
                                onDismiss('');
                             }else{
                              onDismiss('Failed to Save Data.');
                             }
                         }})
                 );
           }else{
                dispatch<any>(
                     handleCreateSelectListItem(
                         {category,
                         formData:values,
                         callback: (res) => {
                             if(res){
                                onDismiss('');
                             }else{
                              onDismiss('Failed to Save Data.');
                             }
                         }})
                 );
           }
       }
   });
   const dispatch = useDispatch();

   const handleSelectionValueChange = (e) => {
         formik.setValues(
             {
             ...formik.values,
             name:e.target.value.replace(/[^\w\s]/gi, "")
             }
         );
    };

    const handleVehChange = (e,val) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: val
            }
        );
    };


   useEffect(() => {
        if(formData){
             formik.setValues(
              {
                ...formik.values,
                     'name': formData.name,
                     'newVehicleAccountCode': formData.newVehicleAccountCode ? formData.newVehicleAccountCode:"",
                     'usedVehicleAccountCode': formData.usedVehicleAccountCode ? formData.usedVehicleAccountCode:""
              }
            );
        }
   },[]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleAddList = () => {
        formik.handleSubmit();
    };
     
    return (
        
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container }
        >
            
            <div className={contentStyles.header + " inventory-popup modal-header"} style={{width:"auto"}}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle">{formData.id && formData.id > 0?"Edit ":"Add "}{title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " expenseDialogPopup inv-srch-bdy body-auto-hight list-dialog-body"} >
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={7} md={7} xs={7}>
                            <TextField
                              id="name"
                              name="name"
                              label={category === 'financeProducts' ? "Finance Product" : "Selection Value"}
                              //className="order-no-field order-no-field-box"
                              value={formik.values.name}
                              onChange={handleSelectionValueChange}
                              onBlur={formik.handleBlur}
                              errorMessage={
                                  formik.touched.name &&
                                  formik.errors.name &&
                                   formik.errors.name.toString()
                              }
                         /> 
                        </Col>
                    </Row>
                    {
                        category === 'financeProducts' && (
                            <Row className="inventoryRowSection" style={{marginTop:"15px"}} justify="center">
                                <Col className="inventoryColDivider" sm={7} md={7} xs={7}>
                                    <label className="lbl-text">Charge Accounts</label>
                                    <Row>
                                        <Col>
                                            <TextField
                                                id="newVehicleAccountCode"
                                                name="newVehicleAccountCode"
                                                label="New Veh"
                                                //className="order-no-field order-no-field-box"
                                                value={formik.values.newVehicleAccountCode}
                                                onChange={(e, val) => handleVehChange(e, val)}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched.newVehicleAccountCode &&
                                                    formik.errors.newVehicleAccountCode &&
                                                    formik.errors.newVehicleAccountCode.toString()
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                id="usedVehicleAccountCode"
                                                name="usedVehicleAccountCode"
                                                label="Used Veh"
                                                //className="order-no-field order-no-field-box"
                                                value={formik.values.usedVehicleAccountCode}
                                                onChange={(e, val) => handleVehChange(e, val)}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched.usedVehicleAccountCode &&
                                                    formik.errors.usedVehicleAccountCode &&
                                                    formik.errors.usedVehicleAccountCode.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            )
                    }
                   <Row className="inventoryRowSection footerBtn">
                        <Col style={{ textAlign: "center" }}>
                            {
                                (formData.id > 0 && updatePermission) || (!formData.id && addPermission) ? 
                                    <PrimaryButton
                                        text="Save"
                                        onClick={handleAddList}
                                    /> : null
                            }
                            </Col>  
                    </Row>
                   
                </Stack>

            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
