import React, { useState, useEffect, FunctionComponent } from "react";
import {
    DefaultButton,
    Modal,
    IconButton,
    TextField,
    Toggle,
    PrimaryButton,
    MaskedTextField,
    ITextFieldProps
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
import PasswordTextInput from "components/customInputs/passwordTextInput/PasswordTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
    handleDeleteEmployee,
    //handleUpdateEmployee,
    handleViewEmployee,
} from "store/employees/employees.action";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
// import Loader from "../../loader/Loader";
import { setEmployeePasswordError } from "store/employees/employees.action";
import { handleGetGroups } from "store/groups/groups.action";
import {
    cancelIcon,
    iconButtonStyles,
    contentStyles as contentStyle,
    dropdownStyles,
} from "constants/styles";
import { PasswordWithRequirementInfo } from "components/textFieldWithLabelAndInfoIcon/PasswordWithRequirementInfo";




const contentStyles = contentStyle();

interface IProps {
    employeeId: number | string;
    isOpen: boolean;
    onDismiss: () => void;
    // resetEmployeeId: () => void
}

export const EditEmployeeDialog: FunctionComponent<IProps> = ({
    employeeId,
    isOpen,
    onDismiss,
}) => {
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);

    const updateloading = useSelector(
        (state: AppState) => state.employees.updateLoading
    );

    console.log(employeeId);
    const loading = useSelector((state: AppState) => state.employees.loading);
    const employee = useSelector(
        (state: AppState) => state.employees.selectedEmployee
    );
    const groups = useSelector((state: AppState) => {
        const options = state.groups.groups.map((group) => {
            // console.log("Groups", group);
            return { key: group.id, text: group.text };
        });
        options.push({ key: undefined, text: "unassigned" });
        return options;
    });

    const employeePasswordError = useSelector(
        (state: AppState) => state.employees.employeePasswordError
    );

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            assignTo: "",
            password: "",
            confirmPassword: "",
            isActive: false,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            email: Yup.string()
                .email("This field must be valid email user")
                .required("Required"),
            phone: Yup.string().required("Required"),
            assignTo: Yup.string().required("Required"),
            isActive: Yup.boolean(),
            password: Yup.string()
                .test("rules", "Must contain 10-30 characters, upper and lowercase letter, one number, one special character.", (val) => {
                    if (val) {
                        var allRules = new RegExp(/^(?=^.{10,30}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]+)(?![.\n]).*$/);
                        return allRules.test(val);
                    }
                    return true;     
                }),
            confirmPassword: Yup.string().oneOf(
                [Yup.ref("password"), null],
                "Passwords must match"
            ),
        }),
        onSubmit: (values) => {
            //const newValues = {
            //    ...values,
            //    id: employee.id,
            //    roles: [...employee.roles],
            //    middle: employee.middle,
            //    title: employee.title,
            //    workPhone: values.phone,
            //    contactEmail: employee.contactEmail,
            //    createdAt: employee.createdAt,
            //    userMasterGroup: values.assignTo,
            //    isActive: values.isActive,
            //    password: values.password,
            //};

            //dispatch<any>(handleUpdateEmployee([newValues], editEmployeeCallback));
        },
    });

    useEffect(() => {
        dispatch<any>(handleGetGroups());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
		dispatch<any>(handleViewEmployee(employeeId,true));
        //@TODO eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeId]);

    useEffect(() => {
        // handleAssignToChange(null, { key: undefined });
    }, []);
    useEffect(() => {
        console.log(employee);
        

        //formik.setValues({
        //    firstName: employee?.firstName,
        //    lastName: employee?.lastName,
        //    email: employee?.email,
        //    phone: employee?.workPhone,
        //    assignTo: employee?.userMasterGroup?.id ?? "undefined",
        //    // assignTo: employee?.userMasterGroups[0]?.id,
        //    isActive: employee?.isActive,
        //    password: "",
        //    confirmPassword: "",
        //});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee]);

    //const editEmployeeCallback = () => {
    //    formik.resetForm({ values: formik.initialValues });
    //    onDismiss();
    //};

    const handleDelete = () => {
        setIsConfirmBoxOpen(true);
    };

    const handleEditEmployee = () => {
        formik.handleSubmit();
    };

    const handleToggle = () => {
        console.log("====================================", formik.values.isActive);
        formik.setValues(
            { ...formik.values, isActive: !formik.values.isActive },
            true
        );
    };

    const handleAssignToChange = (e, item) => {
        formik.setValues({ ...formik.values, assignTo: item.key }, true);
    };

    const renderSpinner = () => {
        return updateloading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const deleteCallback = () => {
        setIsConfirmBoxOpen(false);

        onDismiss();
    };

    const handleCloseEditDialog = () => {
        onDismiss();
        dispatch<any>(setEmployeePasswordError(""));
    };

    const onRenderLabel = (props: ITextFieldProps) => (
        <PasswordWithRequirementInfo {...props} />
    );

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={handleCloseEditDialog}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <ConfirmDialog
                loading={loading}
                description="Please confirm deletion of employee"
                title="Confirm delete employee"
                isOpen={isConfirmBoxOpen}
                onAction={() =>
                    dispatch<any>(handleDeleteEmployee(employeeId, deleteCallback))
                }
                onDismiss={() => setIsConfirmBoxOpen(false)}
            />
            {/*<Loader show={loading}/>*/}
            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText>Edit Employee</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleCloseEditDialog}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack>
                    <Stack tokens={styles.textFields}>
                        <TextField
                            id="firstName"
                            name="firstName"
                            label="First name"
                            required={true}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                formik.touched.firstName && formik.errors.firstName
                                    ? formik.errors.firstName.toString()
                                    : null
                            }
                        />
                        <TextField
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            required={true}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                formik.touched.lastName && formik.errors.lastName
                                    ? formik.errors.lastName.toString()
                                    : null
                            }
                        />
                        <TextField
                            id="email"
                            name="email"
                            label="Email address"
                            required={true}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                formik.touched.email && formik.errors.email
                                    ? formik.errors.email.toString()
                                    : null
                            }
                        />
                        <MaskedTextField
                            mask="(999)999-9999"
                            id="phone"
                            name="phone"
                            label="Phone"
                            required={true}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                formik.touched.phone && formik.errors.phone
                                    ? formik.errors.phone.toString()
                                    : null
                            }
                        />


                        {/*<TextField*/}
                        {/*  id="phone"*/}
                        {/*  name="phone"*/}
                        {/*  label="Phone"*/}
                        {/*  value={formik.values.phone}*/}
                        {/*  onChange={formik.handleChange}*/}
                        {/*  onBlur={formik.handleBlur}*/}
                        {/*  errorMessage={*/}
                        {/*    formik.touched.phone && formik.errors.phone*/}
                        {/*      ? formik.errors.phone.toString()*/}
                        {/*      : null*/}
                        {/*  }*/}
                        {/*/>*/}
                        <Dropdown
                            id="assignTo"
                            label="Assign to"
                            required={true}
                            selectedKey={
                                formik.values.assignTo ? formik.values.assignTo : undefined
                            }
                            onChange={handleAssignToChange}
                            placeholder="Employees Group"
                            options={groups}
                            styles={dropdownStyles}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                formik.touched.assignTo && formik.errors.assignTo
                                    ? formik.errors.assignTo.toString()
                                    : null
                            }
                        />

                        <PasswordTextInput
                            id="password"
                            name="password"
                            label="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onRenderLabel={onRenderLabel}
                            errorMessage={
                                employeePasswordError !== ""
                                    ? employeePasswordError
                                    : formik.touched.password && formik.errors.password
                                        ? formik.errors.password.toString()
                                        : null
                            }
                        />
                        <PasswordTextInput
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Re-enter Password"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={
                                employeePasswordError !== ""
                                    ? employeePasswordError
                                    : formik.touched.confirmPassword &&
                                        formik.errors.confirmPassword
                                        ? formik.errors.confirmPassword.toString()
                                        : null
                            }
                        />

                        <Toggle
                            id="isActive"
                            checked={formik.values.isActive}
                            label="Set Employees Status"
                            inlineLabel
                            onChange={handleToggle}
                            onBlur={formik.handleBlur}
                            onText="active"
                            offText="inactive"
                        />
                    </Stack>
                </Stack>
            </div>
            <div className={contentStyles.footer}>
                <Stack
                    tokens={styles.buttonTokens}
                    horizontal
                    horizontalAlign="space-between"
                >
                    <div>
                        <DefaultButton
                            onClick={handleDelete}
                            iconProps={{ iconName: "Delete" }}
                            text="Delete Employee"
                        />
                    </div>
                    <Stack tokens={{ childrenGap: 5 }} horizontal>
                        <DefaultButton text="Close" onClick={handleCloseEditDialog} />
                        <PrimaryButton
                            disabled={!formik.dirty || !formik.isValid}
                            onRenderIcon={renderSpinner}
                            text="Save Changes"
                            onClick={handleEditEmployee}
                        />
                    </Stack>
                </Stack>
            </div>
        </Modal>
    );
};
