import React from "react";
import { useEffect, useState } from "react";
import {
  Stack,
  //getTheme,
  //mergeStyleSets,
  //FontWeights,
  IStackStyles,
  Link,
  //Dropdown,
  PrimaryButton
} from "@fluentui/react";
 
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { useLocation } from "react-router-dom";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux"; 
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import moment from "moment"; 
import { handleGetKarlDocReports, handleGetPerCarTotals } from "store/dealTracker/dealTracker.action";
import { getLocationsForDropdown } from "store/locations/locations.api";
import { Calendar } from 'primereact/calendar';
import XLSX from "xlsx";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { currencyNegValFormat, numberFormat } from "utils/helperFunctions";
import  KarlDocReportsDialog  from "components/dialogs/dealTrackerDialog/KarlDocReportsDialog";

const container: IStackStyles = {
	root: {
		marginTop: 10,
	},
}; 
export const KarlDocReports = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  //const locations = useSelector((state: AppState) => state.locations.locations) as any; 
  const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
  const [cityDealerList, setCityDealerList] = React.useState([]);
  const [locationFilter, setLocationFilter] = React.useState();
  const yearRange = "1980:" + (new Date().getFullYear()+1);
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const today =new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'});
  const todayDate=new Date(today);
  const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(firstDay);
  const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(lastDay);
  const [dateThroughFilter, setDateThroughFilter] = React.useState<Date | undefined>(todayDate);
  const [resValue, setResValue] = useState(null);
  const [statesFlag, setStatesFlag] = useState(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log('api res Value',resValue)
  const [perCarTotalsData, setPerCarTotalsData] = useState([]);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
    if (!isModalOpen) {
      const formData = {
        startDate: convertDateModified(logDateFromFilter),
        endDate: convertDateModified(logDateToFilter),
        dataThruDate: convertDateModified(dateThroughFilter)
      };  

      dispatch(handleGetPerCarTotals({
        formData,
        callback: (response) => {
           if (response) {
            const sortedData = response.sort((a, b) => a.legalName.localeCompare(b.legalName));
            setPerCarTotalsData(sortedData);
        }
        }
      }));
    }
  };

const convertDateModified = (dateString) => {
  if (dateString) {
      return moment(dateString).format('YYYY-MM-DD');
  }
  return '';
};
    const handleLocationDropdownChange = (e) => {
      setLocationFilter(e.value);
       //handleFilters(item.key,logDateFromFilter,logDateToFilter ,dateThroughFilter);
    };
    const handleFromDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
		//handleFilters(locationFilter, val.target.value ,logDateToFilter,dateThroughFilter);
    };

    const handleToDateChange = (val: any) => {
        setlogDateToFilter  (val.target.value)
        //handleFilters(locationFilter,logDateFromFilter,val.target.value,dateThroughFilter );
    };
    const handleThroughDateChange = (val: any) => {
        setDateThroughFilter  (val.target.value)
        //handleFilters(locationFilter,logDateFromFilter,logDateToFilter ,val.target.value);
    };


    const handleFilters = (locationFilter, logDateFromFilter,logDateToFilter,dateThroughFilter) => {

        if (locationFilter > 0 && logDateFromFilter > 0 &&  logDateToFilter > 0 &&  dateThroughFilter > 0 ) {

        let startingDate=  convertDateModified(logDateFromFilter);
        let endingDate =   convertDateModified(logDateToFilter);
        let todayDate =   convertDateModified(dateThroughFilter);

        const formData = {
                locationId:locationFilter? locationFilter:"",  
                startDate: startingDate? startingDate:"",
                endDate: endingDate ? endingDate: "" ,
                dataThruDate: todayDate? todayDate: ""
        
               };
               
               dispatch<any>(handleGetKarlDocReports({   
               formData,
                   callback: (response) => {
                           if(response){                     
                                   handlePopulateReports(response)
                           }else{
                                setResValue(null)
                           }
               
                       } 
               }));
            
        } 
    }   

    const handlePopulateReports = (response) => {
      
      if (response) {
         let val =response
         setResValue(val)
      } 
  };

  useEffect(() => {

    dispatch<any>(handleGetLocations());
    //handleFilters(locationFilter,logDateFromFilter,logDateToFilter ,dateThroughFilter);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const today =new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'});
    const todayDate=new Date(today);
    setLogDateFromFilter(firstDay);
    setlogDateToFilter(lastDay);
    setDateThroughFilter(todayDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    handleFilters(locationFilter,logDateFromFilter,logDateToFilter ,dateThroughFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilter,logDateFromFilter,logDateToFilter ,dateThroughFilter]);

  function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
}
    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal?.locationId) {
            setLocationFilter(stateVal?.locationId);
        }
        setStatesFlag(true);
    }, [location]);
    

    useEffect(() => {
        if (user?.roles && statesFlag) {
            const fetchLocations = async () => {
              const { data } = await getLocationsForDropdown();  
          
              const options = data?.results.map((item) => {
                return { key: item.id, text: item.legalName };
              });
              var list = options.sort(dynamicSort("text"));
              setCityDealerList(list);
                if (!locationFilter) {
                    if (user?.roles?.some(item => item === "ROLE_EMPLOYEE") && user?.primaryLocationId) {
                        setLocationFilter(user.primaryLocationId)
                    } else {
                        setLocationFilter(options ? options[0]?.key : null);
                    }
                }
             
            };
            fetchLocations().then();
        }


    }, [user, statesFlag]); // eslint-disable-line react-hooks/exhaustive-deps


const exportExcel = () => {
        if(resValue){
            let data =resValue
            console.log('dtattaaa',data.totalWorkDays)

            var wb = XLSX.utils.book_new(),
            ws= XLSX.utils.json_to_sheet([{}], {header: ['Doc Reports']});
           

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Work Days"],origin:'A2'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [data.totalWorkDays], origin: 'B2' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Completed"],origin:'D2'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [data.completedWorkDays], origin: 'E2' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Remaining"],origin:'F2'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin: 'G2' });

            /*** table values***/

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["New"],origin:'B4'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Lease"], origin:'C4' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["New Fleet"],origin:'D4'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Comm"], origin:'E4' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Comm Fleet"],origin:'F4'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Used Certified"], origin:'G4' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Used Karl Care"],origin:'H4'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Wsale"], origin:'I4' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Finance Only"],origin:'J4'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Total"], origin:'K4' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Units"], origin:'A5' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Delivered"],origin:'A6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.new],origin:'B6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.lease], origin:'C6' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.newFleet],origin:'D6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.comm], origin:'E6' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.commFleet],origin:'F6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.usedCertified], origin:'G6' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.usedKarlCare],origin:'H6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.wholeSale], origin:'I6' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J6'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.delivered.total], origin:'K6' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Written"],origin:'A7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.new],origin:'B7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.lease], origin:'C7' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.newFleet],origin:'D7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.comm], origin:'E7' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.commFleet],origin:'F7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.usedCertified], origin:'G7' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.usedKarlCare],origin:'H7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.wholeSale], origin:'I7' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J7'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.written.total], origin:'K7' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Pending"],origin:'A8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.new],origin:'B8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.lease], origin:'C8' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.newFleet],origin:'D8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.comm], origin:'E8' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.commFleet],origin:'F8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.usedCertified], origin:'G8' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.usedKarlCare],origin:'H8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.wholeSale], origin:'I8' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J8'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pending.total], origin:'K8' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Actual Pace"],origin:'A9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.new],origin:'B9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.lease], origin:'C9' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.newFleet],origin:'D9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.comm], origin:'E9' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.commFleet],origin:'F9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.usedCertified], origin:'G9' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.usedKarlCare],origin:'H9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.wholeSale], origin:'I9' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J9'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceUnits.total], origin:'K9' });
            
            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Project Pace"],origin:'A10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.new],origin:'B10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.lease], origin:'C10' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.newFleet],origin:'D10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.comm], origin:'E10' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.commFleet],origin:'F10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.usedCertified], origin:'G10' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.usedKarlCare],origin:'H10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.wholeSale], origin:'I10' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J10'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceUnits.total], origin:'K10' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Dollars"],origin:'A11'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["F&I Income"],origin:'A12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.new],origin:'B12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.lease], origin:'C12' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.newFleet],origin:'D12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.comm], origin:'E12' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.commFleet],origin:'F12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.usedCertified], origin:'G12' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.usedKarlCare],origin:'H12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.wholeSale], origin:'I12' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J12'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.financeIncome.total], origin:'K12' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Actual Pace Dollars"],origin:'A13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'B13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'C13' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'D13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'E13' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'F13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'G13' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'H13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'I13' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J13'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.actualPaceDollars.total], origin:'K13' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Project Pace Dollars"],origin:'A14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'B14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'C14' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'D14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'E14' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'F14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'G14' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'H14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'I14' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J14'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.projectPaceDollars.total], origin:'K14' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Per Car"],origin:'A15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.new],origin:'B15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.lease], origin:'C15' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'D15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.comm], origin:'E15' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'F15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.usedCertified], origin:'G15' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.usedKarlCare],origin:'H15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''], origin:'I15' });
            XLSX.utils.sheet_add_json(ws, [{}], {header: [''],origin:'J15'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.perCar.total], origin:'K15' });

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Pending Summary"],origin:'A16'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Cash Deals"],origin:'B17'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pendingUnitSummary.cashDeals],origin:'C17'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Pending Finance"],origin:'B18'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pendingUnitSummary.pendingFinance],origin:'C18'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Approved Finance"],origin:'B19'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pendingUnitSummary.approvedFinance],origin:'C19'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Qualified Finance"],origin:'B20'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pendingUnitSummary.qualifiedFinance],origin:'C20'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Void Deals"],origin:'B22'});
            XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.pendingUnitSummary.voidDeals],origin:'C22'});

            XLSX.utils.sheet_add_json(ws, [{}], {header: ["Delivery / Written Ratio"],origin:'F16'});
             XLSX.utils.sheet_add_json(ws, [{}], {header: [resValue.DeliveryWrittenRatio.value],origin:'F17'});

            XLSX.utils.book_append_sheet(wb,ws,"Mysheet1");

            XLSX.writeFile(wb,"MyExcel.xlsx");
        }
}

    const handleResetWorkbook = () => {   
    
       setLocationFilter(null);
       setLogDateFromFilter(firstDay);
       setlogDateToFilter(lastDay);
       setDateThroughFilter(todayDate)
       handleFilters(null,firstDay,lastDay,todayDate);
    };

  return (

    	isKarlProfileType? <>
         <KarlDocReportsDialog
          isOpen={isModalOpen}
          toggleDialog={toggleModal}
          data={perCarTotalsData}
          locationsList={cityDealerList}
        />
          <div className="reportFinanceProductWidth">
			<Stack styles={container} tokens={{ childrenGap: 10 }}>

				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>
  
					<Row>
						<Col className="alignHeaders">
							 <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Doc Report </CustomFontText>
						</Col>
					</Row>
                </Stack>
                    <Row>
                        <Col>
                               <div className=" table-header "  style={{marginBottom:"10px",display:"block"}} > 
                                 <Row>
		                            <Col md={10}>
		                    	         <Row>
		                    	         	<Col  md={3}>
		                    				  <div className="dd-control">
                                                  {/*<Dropdown
                                                             id="location"
                                                             label="Location"
                                                             selectedKey={locationFilter}
                                                             options={cityDealerList}
                                                             onChange={handleLocationDropdownChange}
                                                             placeholder=""
                                                         /> */}
                                                  <label className="lbl-text">Location</label>
                                                  <PRDropdown
                                                      id="location"
                                                      placeholder=""
                                                      value={locationFilter}
                                                      options={cityDealerList}
                                                      onChange={handleLocationDropdownChange}
                                                      optionLabel="text"
                                                      optionValue="key"
                                                      appendTo="self"
                                                      className="custom-p-dropdown write-ups-location-dd"
                                                      filter={false}
                                                      resetFilterOnHide={true}
                                                  />
		                    					</div>
		                    	         	</Col>
                                            <Col md={4}>
                                                <div className="date-filter-section">
		                    	                    <div className="table-header">
		                    				            <div className="ms-TextField-wrapper">
		                    				                 <label className="lbl-text">Date Range</label>
		                    				                 <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                                <Calendar
                                                                    id="fromLogDate"
                                                                    style={{ width: "100%" }}
                                                                    value={logDateFromFilter}
                                                                    showIcon
                                                                    onChange={(e) => handleFromDateChange(e)}
                                                                    monthNavigator
                                                                    yearNavigator
                                                                    yearRange={yearRange}
                                                                    maxDate={logDateToFilter }
		                    				                    />
		                    				                 </div>
		                    				            </div>
                                                    </div>
                                                    <label className="lbl-text" style={{margin:"30px 10px 0px"}}>To</label>
                                                    <div className="table-header">
                                                        <div className="ms-TextField-wrapper">
                                                        <label className="lbl-text">&nbsp;</label>
                                                            <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                                <Calendar
                                                                    id="toLogDate"
                                                                    style={{ width: "100%" }}
                                                                    value={logDateToFilter}
                                                                    showIcon
                                                                    onChange={(e) => handleToDateChange(e)}
                                                                    monthNavigator
                                                                    yearNavigator
                                                                    yearRange={yearRange}
                                                                    minDate={logDateFromFilter}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
		                    	         	</Col>
                                            <Col md={2}>
                                                <div className="date-filter-section">
		                    	                    <div className="table-header">
		                    				            <div className="ms-TextField-wrapper">
		                    				                 <label className="lbl-text">Data Thru</label>
		                    				                 <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                                <Calendar
                                                                    id="dataThroughDate"
                                                                    style={{ width: "100%" }}
                                                                    value={dateThroughFilter}
                                                                    showIcon
                                                                    onChange={(e) => handleThroughDateChange(e)}
                                                                    monthNavigator
                                                                    yearNavigator
                                                                    yearRange={yearRange}
                                                                    minDate={logDateFromFilter}
		                    				                    />
		                    				                 </div>
		                    				            </div>
                                                    </div> 
                                                </div>
		                    	         	</Col>
                                            <Col  md={1} >
		                    				   <div className="table-header employeeeSearchLabelText" style={{marginTop:"32px"}}>
		                    	                   <PrimaryButton
		                    					      onClick={handleResetWorkbook}
		                    					   >
                                                       Reset
                                                   </PrimaryButton>
		                    				   </div> 
		                    	         	</Col>
		                    	         </Row>
		                    		</Col>
		                        </Row>
                            </div>
                        </Col>
                    </Row>

                  {resValue &&(
                  <Row>
                     <Col>
                        <Row>
                            <Col> Work Days &nbsp;&nbsp;{resValue.totalWorkDays} &nbsp; &nbsp;&nbsp; &nbsp; Completed &nbsp; &nbsp; {resValue.completedWorkDays} &nbsp; &nbsp;&nbsp; &nbsp;Remaining &nbsp; &nbsp; {resValue.totalWorkDays-resValue.completedWorkDays}</Col>
                            <Col> 
                                  <div style={{float:"right",fontSize:"16px"}}>
                                      <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
		                                     Export to Excel
		                              </Link>   
                                 </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className="tabledisplay" style={{width:"100%"}}>
                                    <thead style={{backgroundColor:"#dde0e3",wordBreak:"break-all"}}>
                                        <tr className="docReportTextAlignCenter">
                                            <th>  </th>
                                            <th> New </th>
                                            <th> Lease </th>
                                            <th> New Fleet </th>
                                            <th> Comm </th>
                                            <th> Comm Fleet  </th>
                                            <th> <div>Used</div> <div> Certified </div></th>
                                            <th> <div>Used</div> <div> Karl Care </div></th>
                                            <th> Wsale </th>
                                            <th> Finance Only </th>
                                            <th> Total </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                           <td> <div className="docReportTextBold">Units </div></td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Delivered </td>
                                           <td> {numberFormat(Math.round(resValue.delivered.new))} </td>
                                           <td> {numberFormat(Math.round(resValue.delivered.lease))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.newFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.comm))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.commFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.usedCertified))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.usedKarlCare))}</td>
                                           <td> {numberFormat(Math.round(resValue.delivered.wholeSale))}</td>
                                           <td> </td>
                                           <td> {numberFormat(Math.round(resValue.delivered.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Written </td>
                                           <td>{numberFormat(Math.round(resValue.written.new))} </td>
                                           <td>{numberFormat(Math.round(resValue.written.lease))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.newFleet))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.comm))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.commFleet))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.usedCertified))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.usedKarlCare))}</td>
                                           <td>{numberFormat(Math.round(resValue.written.wholeSale))}</td>
                                           <td> </td>
                                           <td>{numberFormat(Math.round(resValue.written.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Pending </td>
                                           <td> {numberFormat(Math.round(resValue.pending.new))} </td>
                                           <td> {numberFormat(Math.round(resValue.pending.lease))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.newFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.comm))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.commFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.usedCertified))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.usedKarlCare))}</td>
                                           <td> {numberFormat(Math.round(resValue.pending.wholeSale))}</td>
                                           <td> </td>
                                           <td> {numberFormat(Math.round(resValue.pending.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Actual Pace </td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.new))} </td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.lease))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.newFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.comm))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.commFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.usedCertified))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.usedKarlCare))}</td>
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.wholeSale))}</td>
                                           <td> </td>     
                                           <td> {numberFormat(Math.round(resValue.actualPaceUnits.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Project Pace </td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.new))} </td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.lease))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.newFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.comm))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.commFleet))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.usedCertified))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.usedKarlCare))}</td>
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.wholeSale))}</td>
                                           <td> </td>     
                                           <td> {numberFormat(Math.round(resValue.projectPaceUnits.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr>
                                           <td> <div className="docReportTextBold">Dollars </div></td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                           <td> </td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> F&I Income </td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.new))}   </td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.lease))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.newFleet))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.comm))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.commFleet))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.usedCertified))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.usedKarlCare))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.wholeSale))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.financeOnly))}</td>
                                           <td> {currencyNegValFormat(Math.round(resValue.financeIncome.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Actual Pace </td>
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.actualPaceDollars.total) ? "":resValue.actualPaceDollars.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td> 
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Project Pace </td>
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> </td> 
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.projectPaceDollars.total) ? "":resValue.projectPaceDollars.total))}</td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                                        <tr className="docReportTextAlignCenter">
                                           <td> Per Car </td>
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.perCar.new) ? "":resValue.perCar.new))}</td>
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.perCar.lease) ? "":resValue.perCar.lease))}</td>
                                           <td> </td> 
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.perCar.comm) ? "":resValue.perCar.comm))}</td>
                                           <td> </td> 
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.perCar.usedCertified) ? "":resValue.perCar.usedCertified))}</td>
                                           <td> {currencyNegValFormat(Math.round(isNaN(resValue.perCar.usedKarlCare) ? "":resValue.perCar.usedKarlCare))}</td>
                                           <td> </td> 
                                           <td> </td> 
                                           <td>
                                               <Link onClick={toggleModal}>
                                                   {currencyNegValFormat(Math.round(isNaN(resValue.perCar.total) ? "":resValue.perCar.total))}
                                               </Link>

                                           </td>
                                        </tr>
                                        <tr>
                                           <td  colSpan={11}><hr className="dashboardMikeReportsHr" /></td>
                                        </tr>
                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col md={3}>
                                       <div className="docReportTextBold"> Pending Summary </div>
                                       <div className="docReportTextAlignRight"> Cash Deals &nbsp;&nbsp;{resValue.pendingUnitSummary.cashDeals}</div>
                                       <div className="docReportTextAlignRight"> Pending Finance &nbsp;&nbsp;{resValue.pendingUnitSummary.pendingFinance}</div>
                                       <div className="docReportTextAlignRight"> Approved Finance &nbsp;&nbsp;{resValue.pendingUnitSummary.approvedFinance}</div>
                                       <div className="docReportTextAlignRight"> Qualified Finance &nbsp;&nbsp;{resValue.pendingUnitSummary.qualifiedFinance}</div>
                                       <div> </div>
                                       <div className="docReportTextAlignRight"> Void Deals &nbsp;&nbsp;{resValue.pendingUnitSummary.voidDeals}</div>
                                    </Col>
                                    <Col md={2}>
                        
                                    </Col>
                                    <Col md={3}>
                                       <div className="docReportTextBold"> Delivery /Written Ratio </div>
                                       <div style={{textAlign:"center"}}> {(resValue.DeliveryWrittenRatio.value.toFixed(1))+'%'} </div>
                                       <div> </div>
                                       <div> </div>
                                       <div></div>
                                       <div> </div>
                                       <div> </div>
                                    </Col>
                        
                                </Row>
                            </Col>
                        </Row>
                     </Col>
                   </Row>
                    )}                  
			</Stack>
        </div>       
        </> : null
  );
};
