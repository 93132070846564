import React from "react";

interface ICustomHeaderProps {
  size?: number | string;
  bold?: boolean;
  style?: object;
  color?: string;
  className?: string;
  optional?: boolean;
  children?:any
}

export const CustomFontText: React.FC<ICustomHeaderProps> = ({
  children,
  size,
  bold,
  style,
  color,
  className,
  optional,
}) => {
  return (
    <div
      className={`${bold ? "customFontBold" : "customFont"} ${
        className ? className : ""
      }`}
      style={{
        ...style,
        fontSize: size ? size : 30,
        color: color,
        // fontFamily: optional ? 'SegoeUI' : 'CenturyGothic',
      }}
    >
      {children}
    </div>
  );
};
