//import {IPrimarySetup} from "types/primarySetupTypes";
import * as actions from "store/primarySetup/primarySetup.types";

interface IState {
  loading: boolean;
  error: null | object;
  guidedSetup: {} | any;
}



const initialState: IState = {
  loading: false,
  error: null,
  guidedSetup: {}
};

export const PrimarySetupReducer = (
    state: IState = initialState,
    action: actions.PrimarySetupActions
) => {
  switch (action.type) {

      case actions.SAVE_PRIMARY_SETUP_PENDING:
      return {
        ...state,
        loading: true,
          };
      case actions.SAVE_PRIMARY_SETUP_REJECTED:
          return { ...state, loading: false, error: action.payload };
      case actions.SAVE_GUIDED_SETUP_RESOLVED:
          return { ...state, loading: false };
      case actions.SAVE_GUIDED_SETUP_REJECTED:
          return { ...state, loading: false, error: action.error };
      case actions.SAVE_PRIMARY_SETUP_RESOLVED:
          return {
            ...state,
            loading: false,
            //prospects: action.payload
          };
      case actions.GET_GUIDED_SETUP_CHANGES:
            return {
                ...state,
                guidedSetup: {
                    ...state.guidedSetup,
                    ...action.payload,
                }
            };
      case actions.HANDLE_CLEAN_GUIDEDSETUP:
          return {
              ...state,
              guidedSetup: {}
          };
    default:
      return state;
  }
};
