import client, { clientV3, clientPrint, clientV2} from "services/api/client";
import { ICreateDealRecord } from "types/dealTypes";

export const salesManager = (details: any, method: any) => {
  let body = null;
  if (method === 'PUT') {
    body = details;
  }
  return client(`user/master/employees/managers?role=sales`, { body, method }).then(
    (salesData) => {
      return { salesData, error: null };
    },
    (error) => {
      return { salesData: null, error };
    }
  );
};

export const financeManager = (details: any, method: any) => {
  let body = null;
  if (method === 'PUT') {
    body = details;
  }
  return client(`user/master/employees/managers?role=finance`, { body, method }).then(
    (financeData) => {
      return { financeData, error: null };
    },
    (error) => {
      return { financeData: null, error };
    }
  );
};

export const selectedSalesManager = (details: any, method: any, id) => {
  let body = null;
  if (method === 'PUT') {
    body = details;
  }
  return client(`user/master/deals/${id}/salesManagers`, { body, method }).then(
    (salesData) => {
      return { salesData, error: null };
    },
    (error) => {
      return { salesData: null, error };
    }
  );
};

export const selectedFinanceManager = (details: any, method: any, id) => {
  let body = null;
  if (method === 'PUT') {
    body = details;
  }
  return client(`user/master/deals/${id}/financeManagers`, { body, method }).then(
    (financeData) => {
      return { financeData, error: null };
    },
    (error) => {
      return { financeData: null, error };
    }
  );
};

 /*** Search Customer Number ****/

 export const searchDealRecord = (deal: any) => {
    return client(
        `user/master/deals/search`,
        { body: deal, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const createDealRecord = (deal: ICreateDealRecord) => {
    return client(
        `user/master/deals`,
        { body: deal, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveDealRecord = (deal: any,id) => {
    return client(
        `user/master/deals/${id}`,
        { body: deal, method: "PATCH" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getDelivery = (id) => {
    return clientV3(
        `user/master/deals/${id}/delivery`,
        { body: null, method: 'GET' }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveDelivery = (deal: any, id) => {
    return client(
        `user/master/deals/${id}/delivery`,
        { body: deal, method: deal.id?'PUT':'POST' }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewDealRecord = (id: number | string) => {
    return client(`user/master/deals/${id}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewDealNoRecord = (id: number | string, locationId: number | string) => {
    return client(`user/master/deal?dealNumber=${id}&locationId=${locationId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getDealRecordList = () => {
    return client(`user/master/deals`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getDealsSummary = () => {
    return client(`user/master/deals/summary`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getKarlDealNotes = async (dealId: any,deliveryId: any) => {
    const query = `user/master/deals/${dealId}/delivery/${deliveryId}/notes`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveKarlDealNotes = async (dealId: any, deliveryId: any, details: any) => {
    const query = `user/master/deals/${dealId}/delivery/${deliveryId}/notes`;
  return client(query, { body: details, method: 'POST' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getKarlDealTradeInNotes = async (dealId, tradeInId) => {
    const query = `user/master/deals/${dealId}/tradeIns/${tradeInId}/notes`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveKarlDealTradeInNotes = async (dealId: any, tradeInId: any, details: any) => {
    const query = `user/master/deals/${dealId}/tradeIns/${tradeInId}/notes`;
  return client(query, { body: details, method: 'POST' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getDealNotes = async (dealId) => {
    const query = `user/master/deals/${dealId}/notes`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const getKarlDealsAppointmentNotes = async (dealId, appointmentId) => {
    const query = `user/master/deals/${dealId}/appointments/${appointmentId}/notes`;
  return client(query, { body: null }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};

export const saveKarlDealsAppointmentNotes = async (dealId: any, appointmentId: any, details: any) => {
    const query = `user/master/deals/${dealId}/appointments/${appointmentId}/notes`;
  return client(query, { body: details, method: 'POST' }).then(
    (dataFilter) => {
      return { dataFilter, errorFilter: null };
    },
    (errorFilter) => {
      return { dataFilter: null, errorFilter };
    }
  );
};


export const getFinanceProduct = ({
    id,
    }: {
    id:number | string;
    }) => {
    
    // console.log("query", query);
    return client(
        `user/master/deals/${id}/dealFinanceProducts`,       
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const getFinanceProductItem = (id:number | string,productID:number | string, formData: any) => {    
    // console.log("query", query);
    return client(
        `user/master/deals/${id}/dealFinanceProducts/${productID}`,
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const addFinanceProductItem = (id:number | string, formData: any) => {
   
    return client(`user/master/deals/${id}/dealFinanceProducts`,{
        body: formData,
        method: "POST"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const saveFinanceProductItem = (id:number | string,productID: number | string, formData: any) => {
    return client(`user/master/deals/${id}/dealFinanceProducts/${productID}`, {
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};



export const deleteFinanceProductItem= (id: number, productID: number, formData: any) => {
    return client(`user/master/deals/${id}/dealFinanceProducts/${productID}`, {
        body: null,
        method: "DELETE"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

/***Display Board ***/
export const getDisplayboard = (data) => {
    // return client(`user/master/schedules/financeDisplayBoard?locationId=${data.locationId}&date=${data.date}`, { body: null }).then(
    return client(`user/master/schedules/financeDisplayBoard/v2?locationId=${data.locationId}&date=${data.date}`, { body: null }).then(        
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Appointments ***/
export const getAppointments = () => {
    return client(`user/master/deals/appointments?limit=100000&page=1`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getAppointmentsByDeal = (dealId: number | string) => {
    return client(`user/master/deals/${dealId}/appointments`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveAppointment = (appointment: any, dealId) => {
    if (appointment.id) {
        return client(
            `user/master/deals/${dealId}/appointments/${appointment.id}`,
            { body: appointment, method: "PATCH" }
        ).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    } else {
        return client(
            `user/master/deals/${dealId}/appointments`,
            { body: appointment, method: "POST" }
        ).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }
    
};

export const viewAppointment = (delaId: number | string, appointmentId: number | string) => {
    return clientV3(`user/master/deals/${delaId}/appointments/${appointmentId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Appraisal***/
export const getAppraisals = () => {
    return client(`user/master/deals/tradeIns?limit=100000&page=1&column=dealTradeIn.createdAt`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getAppraisalsByDeal = (dealId) => {
    return client(`user/master/deals/${dealId}/tradeIns`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveAppraisal = (appraisal: any, dealId, tradeInId = 0) => {
    if (tradeInId) {
        return client(
            `user/master/deals/${dealId}/tradeIns/${tradeInId}`,
            { body: appraisal, method: "PATCH" }
        ).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }
    return client(
        `user/master/deals/${dealId}/tradeIns`,
        { body: appraisal, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewAppraisal = (dealId: number | string, tradeInId: number | string) => {
    return clientV3(`user/master/deals/${dealId}/tradeIns/${tradeInId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const deleteAppraisalItem= (dealId: number, appraisalId: number) => {
    return client(`user/master/deals/${dealId}/tradeIns/${appraisalId}`, {
        body: null,
        method: "DELETE"
    }).then(
        (data) => {

            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const addVehicleStockItem = (id: number | string, formData: any) => {
    return client(`user/master/deals/${id}/vehicles/stockNumber`,{
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};


export const addVehicleOrderItem = (id: number | string, formData: any) => {
    return client(`user/master/deals/${id}/vehicles/orderNumber`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const updateVehicleStockItem = (id: number | string, vehicleId: number, formData: any) => {
    return client(`user/master/deals/${id}/vehicles/${vehicleId}`,{
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

/** Appraisal Table**/

export const getAppraisalTable = ({
    dealId, tradeInId
    }: {
        dealId: number | string;
        tradeInId: number | string;
    }) => {
    
    // console.log("query", query);
    return client(
        `user/master/deals/${dealId}/tradeIns/${tradeInId}/appraisals`,      
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const addAppraisalTableItem = (dealId: number | string, appraisalId: number | string, formData: any) => {
   
    return client(`user/master/deals/${dealId}/tradeIns/${appraisalId}/appraisals`,{
        body: formData,
        method: "POST"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

/***ServiceContract***/
export const getServiceContract = () => {
    return client(`user/master/deals/contracts?limit=100000&page=1`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveServiceContract = (serviceContract: any, id) => {
    return client(
        `user/master/deals/${id}/contracts`,
        { body: serviceContract, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};



export const getServiceContractItem = ({
    id,
    }: {
    id:number | string;
    }) => {
    
    // console.log("query", query);
    return client(
        `user/master/deals/${id}/contracts`,       
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const viewServiceContractItem = (id:number | string,productID:number | string, formData: any) => {    
    // console.log("query", query);
    return clientV3(
        `user/master/deals/${id}/contracts/${productID}`,
        { body: null },false
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const updateServiceContractItem = (id:number | string,productID: number | string, formData: any) => {
    return client(`user/master/deals/${id}/contracts/${productID}`, {
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

/*** Task ***/
export const getTasks = () => {
    return client(`user/master/deals/tasks?limit=100000&page=1`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getTasksByDeal = (dealId: number | string) => {
    return client(`user/master/deals/${dealId}/tasks?limit=100000&page=1`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewTask = (dealId: number | string, taskId: number | string) => {
    return clientV3(`user/master/deals/${dealId}/tasks/${taskId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveTask = (task : any,id) => {
    return client(
        `user/master/deals/${id}/tasks`,
        { body: task, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateTaskStatus = (id,taskId,status) => {
    return client(
        `user/master/deals/${id}/tasks/${taskId}/status`,
        { body: status, method: "PATCH" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/*** Task Note***/
export const getTaskNotes = (id,taskId) => {
    return client(`user/master/deals/${id}/tasks/${taskId}/notes?limit=100000&page=1`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveTaskNote = (note: any, id, taskId) => {
    return client(
        `user/master/deals/${id}/tasks/${taskId}/notes`,
        { body: note, method: "POST" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/*** Sales Hierarchy***/
export const getSalesHierarchy = () => {
    return client(`user/master/salesGoals/salesHierarchy`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Get Sales Target***/
export const getSalesTarget = (year) => {
    return client(`user/master/salesGoals?year=${year}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Get Sales Target***/
export const saveSalesTarget = (salesTarget) => {
    return client(
        `user/master/salesGoals`,
        { body: salesTarget, method: "PUT" }
    ).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Cancellations***/
export const getCancellations = () => {
    return client(`user/master/deals/cancellations`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const getCancellationsByDeal = (dealId: number | string) => {
    return client(`user/master/deals/${dealId}/cancellations`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getCancellationInfo = (dealId: number | string, cancellationId: number | string) => {
    return clientV3(`user/master/deals/${dealId}/cancellations/${cancellationId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Schedule***/
export const getFinDelPersonApptByDate = (data: any) => {
    return client(`user/master/schedules?role=${data.role}&date=${data.date}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const postFinDelPersonApptByDate = (scheduleData) => {
    return client(`user/master/schedules`, { body: scheduleData, method: "POST" }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getFinDelPersonAppByDeal = (dealId, type) => {
    return client(`user/master/deals/${dealId}/schedules?type=${type}`, { body: null, method: "GET" }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const postFinDelPersonApptDeal = (dealId, scheduleData) => {
    return client(`user/master/deals/${dealId}/schedules`, { body: scheduleData, method: "PUT" }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const patchDelFinDelPersonApptDeal = (dealId, role, method, scheduleData) => {
    let url = `user/master/schedules/${dealId}`;
    if (role !== 'manager') {
        url = `user/master/deals/${dealId}/schedules`;
    }
    return client(url, { body: (method === 'PATCH') ? scheduleData : null, method: method }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getFinDelPersonSchedule = (role, date) => {
    return client(`user/master/schedules?role=${role}&date=${date}`, { body: null, method: "GET" }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getAllFinDelPerson = (role: any) => {
    return client(`user/master/employees?column=employee.firstName&sort=asc&role=${role}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};
export const saveCancellation = (cancellations: any, dealId) => {
    if (cancellations.id) {
        return client(`user/master/deals/${dealId}/cancellations/${cancellations.id}`, {
            body: cancellations,
            method: "PATCH"
        }).then(
            (data) => {
                return { data: data, error: null };
            },
            (error) => {

                return { data: null, error };
            }
        );
    } else {
        return client(
            `user/master/deals/${dealId}/cancellations`,
            { body: cancellations, method: "POST" }
        ).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }
};

/*** Print Cancellation***/

export const printCancellation = (dealId:number | string) => {
   
    return clientPrint(`user/master/deals/${dealId}/cancellations/pdf`,{
        body: null,
        method: "POST"
    })
   // .then(
   //     (data) => {
   //
   //         return { result: data, error: null };
   //     },
   //     (error) => {
   //
   //         return { result: null, error };
   //     }
   // );
};

export const printCancellationListItem = (dealId:number | string,cancellationId:number | string) => {
   
    return clientPrint(`user/master/deals/${dealId}/cancellations/${cancellationId}/pdf`,{
        body: null,
        method: "POST"
    })

};

/***Vehicle Prep***/
export const getPreps = (date: string, carType: number, locationId: number) => {
    return client(`user/master/preps?date=${date}&carType=${carType}&locationId=${locationId}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const getPrepsByDeal = (dealId: number | string, date: string, carType: number) => {
    return client(`user/master/deals/${dealId}/preps?date=${date}&carType=${carType}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getPrepsInfo = (id: number | string) => {
    return client(`user/master/preps/${id}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const savePreps = (formData) => {

    if (formData.id) {
        return clientV3(`user/master/preps/${formData.id}`, { body: formData, method: "PATCH" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    } else {
        return clientV3(`user/master/preps`, { body: formData, method: "POST" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }
    
};

export const savePrepsByDeal = (dealId, formData) => {

    if (formData.id) {
        return clientV3(`user/master/deals/${dealId}/preps/${formData.id}`, { body: formData, method: "PATCH" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    } else {
        return clientV3(`user/master/deals/${dealId}/preps`, { body: formData, method: "POST" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }

};

export const deletePrepsInfo = (id: number | string) => {
    return client(`user/master/preps/${id}`, { body: null, method: "DELETE" }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/***Dealer Trades***/
export const getDealerTrades = (tradeType: number) => {
    return client(`user/master/dealerTrades?type=${tradeType}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getDealerTradesV2 = (data: any) => {
    let url = `user/master/dealerTrades/v2`;
    const formData = data.formData;
    let queryStr = `?limit=${formData.limit}&search=${formData.search}`;
    
    if (formData.sort) {
        queryStr = queryStr + `&sort=${formData.sort}`;
    }
    if (formData.column) {
        queryStr = queryStr + `&sort=${formData.column}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { dealerData: data, error: null };
        },
        (error) => {
            return { dealerData: null, error };
        }
    );
};

export const getDealerTradeInfo = (id: number | string) => {
    return client(`user/master/dealerTrades/${id}`, { body: null }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const saveDealerTrade = (formData) => {

    if (formData.id) {
        return clientV3(`user/master/dealerTrades/${formData.id}`, { body: formData, method: "PATCH" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    } else {
        return clientV3(`user/master/dealerTrades`, { body: formData, method: "POST" }).then(
            (data) => {
                return { data, error: null };
            },
            (error) => {
                return { data: null, error };
            }
        );
    }

};

export const getDTVehicleTrimVIN = async (vin: string, id: number) => {
    let body = { vin };
    return clientV2(`user/master/dealerTrades/${id}/vehicles/search/vin`, { body, method: 'POST' }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getDTVehicleTrimYMM = async (ymm: any, id: number) => {
    return client(`user/master/dealerTrades/${id}/vehicles/search/ymm`, { body: ymm, method: 'POST' }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const getDTVehDetails = async (ymm: any, id: number,action: string) => {
    console.log('getInventoryVehDetails')
    let query;
    if (ymm && ymm.year) {
        query = `user/master/dealerTrades/${id}/${action}/ymm`;
    } else {
        query = `user/master/dealerTrades/${id}/${action}/vin`;
    }
    return client(query, { body: ymm, method: 'POST' }).then(
        (dataFilter) => {
            return { dataFilter, errorFilter: null };
        },
        (errorFilter) => {
            return { dataFilter: null, errorFilter };
        }
    );
};

export const addDTVehicleStockItem = (id: number | string, formData: any, action: string) => {
    return client(`user/master/dealerTrades/${id}/${action}/stockNumber`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const updateDTVehicleStockItem = (id: number | string, vehicleId: number, formData: any, action: string) => {
    return client(`user/master/dealerTrades/${id}/${action}/${vehicleId}`, {
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );  
};

export const dealerTradeCheck = (id: number | string, data: any) => {
    return client(`user/master/deals/${id}/vehicles/dealerTradeCheck`, {
        body: data,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const vehicleStockCheck = (id: number | string, data: any) => {
    return client(`user/master/deals/${id}/vehicles/stockNumberCheck`, {  
        body: data,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const vehicleTrackerCheckStock = (id: number | string, formData: any) => {
    return client(`user/master/deals/${id}/vehicles/vehicleTrackerCheck`, {  
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const vehicleOrderCheck = (id: number | string, data: any) => {
    return client(`user/master/deals/${id}/vehicles/orderNumberCheck`, {
        body: data,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const dealerTradeVehicleStockCheck = (id: number | string, data: any) => {
    return client(`user/master/dealerTrades/${id}/vehicles/stockNumberCheck`, {
        body: data,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const dealerTradeVehicleOrderCheck = (id: number | string, data: any) => {
    return client(`user/master/dealerTrades/${id}/vehicles/orderNumberCheck`, {
        body: data,
        method: "POST"
    }).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};


export const financeDeskingReports = (formData: any) => { 

    let url = `user/master/reports/financeDesking?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}&salesManagerId=${formData.formData.salesManagerid}&financeProductId=${formData.formData.financeProductid}`;

    
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const getDealsKPIStats = (locationId) => {
    let url = `user/master/deals/kpi/stats`;
    if (locationId)
        url = url + `?locationId=${locationId}`
    return client(url, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };   
        }
    );
};
export const lenderCountReports = (formData: any) => { 
    let url = `user/master/reports/lenderCount?startMonth=${formData.formData.startMonth}&endMonth=${formData.formData.endMonth}&year=${formData.formData.year}`;

    
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const financeProductSalesReports = (formData: any) => { 

    let url = `user/master/reports/financeProductSales?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}&financePersonId=${formData.formData.financePersonId}`;

    
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const financeReserveSplitsReports = (formData: any) => {   

    let url = `user/master/reports/financePersonReserveProductSplit?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}`;

    
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };  
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const financeProductSplitsReports = (formData: any) => {    

    let url = `user/master/reports/financePersonProductSplit?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}`;
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const karlDocReports = (formData: any) => {     
    let url = `user/master/reports/doc?startDate=${formData.formData.startDate}&endDate=${formData.formData.endDate}&dataThruDate=${formData.formData.dataThruDate}&locationId=${formData.formData.locationId}`;
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const getPerCarTotals = (formData: any) => {
     let url = `user/master/reports/doc/percar?startDate=${formData.formData.startDate}&endDate=${formData.formData.endDate}&dataThruDate=${formData.formData.dataThruDate}`;
     return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
}

export const dashboardMikeReports = () => {
    return client(`user/master/reports/dashboardMike?limit=100000&page=1`, { body: null }).then(  
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const financePersonStackRankReports = (formData: any) => {     

    let url = `user/master/reports/financePersonStackRank?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}`;   
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};


export const financePersonMonthEndReports = (formData: any) => {     

    let url = `user/master/reports/financePersonMonthEnd?month=${formData.formData.month}&year=${formData.formData.year}&financePersonPrimaryLocationId=${formData.formData.financePersonPrimaryLocationId}`;
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const vacationReports = (formData: any) => {

    let url = `user/master/reports/financePersonVacation?locationId=${formData.formData.locationId}`;
    return client(url, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const salesPersonFinanceReports = (formData: any) => {     

    let url = `user/master/reports/salesPersonFinanceReport?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}&primaryLocationId=${formData.formData.locationId}`;
    return client(url, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const salesPersonMonthEndReports = (formData: any) => {     
    return client(`user/master/reports/salesPersonMonthEnd?startMonth=${formData.formData.startMonth}&year=${formData.formData.year}&endMonth=${formData.formData.endMonth}&primaryLocationId=${formData.formData.locationId}`, {
    body: null,
    method: "GET",
  }).then(
    (data) => {      
      return { data, error: null };
    },
    (error) => {      
      return { data: null, error };
    }
  );
};

export const cdrCPOReports = (data: any) => {
    const formData = data.formData;
    let url = `user/master/reports/cdrCpoReport?`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.fromClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `fromClosedDate=${formData.fromClosedDate}`;
    }
    if (formData.toClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `toClosedDate=${formData.toClosedDate}`;
    }
    if (formData.fromCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `toCdrDate=${formData.toCdrDate}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + (queryStr && '&') + `cdr=${formData.cdr}`;
    }
    if (formData.sfe) {
        queryStr = queryStr + (queryStr && '&') + `sfe=${formData.sfe}`;
    }
    if (formData.fleet) {
        queryStr = queryStr + (queryStr && '&') + `fleet=${formData.fleet}`;
    }
    if (formData.cpo) {
        queryStr = queryStr + (queryStr && '&') + `cpo=${formData.cpo}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.deliveryCode) {
        queryStr = queryStr + (queryStr && '&') + `deliveryCode=${formData.deliveryCode}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const printPendingDeals = (data: any) => {
    const formData = data.formData;

    let url = `user/master/reports/pendingDeals/pdf`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?timezoneOffset=${timezoneOffset}`;


    if (formData.fromCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `toCdrDate=${formData.toCdrDate}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + (queryStr && '&') + `cdr=${formData.cdr}`;
    }
    if (formData.sfe) {
        queryStr = queryStr + (queryStr && '&') + `sfe=${formData.sfe}`;
    }
    if (formData.fleet) {
        queryStr = queryStr + (queryStr && '&') + `fleet=${formData.fleet}`;
    }
    if (formData.cpo) {
        queryStr = queryStr + (queryStr && '&') + `cpo=${formData.cpo}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.financePersonId) {
        queryStr = queryStr + (queryStr && '&') + `financePersonId=${formData.financePersonId}`;
    }
    if (formData.deliveryCode) {
        queryStr = queryStr + (queryStr && '&') + `deliveryCode=${formData.deliveryCode}`;
    }
    
    return clientPrint(url + queryStr, {
       body: null,
    //responseType: 'application/pdf'
    method: "GET",

   });
};

export const getWriteUpsList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/v2`;
    
    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.salesPerson1Id) {
        queryStr = queryStr + `&salesPerson1Id=${formData.salesPerson1Id}`;
    }
    if (formData.salesPerson2Id) {
        queryStr = queryStr + `&salesPerson2Id=${formData.salesPerson2Id}`;
    }
    if (formData.financePerson1Id) {
        queryStr = queryStr + `&financePerson1Id=${formData.financePerson1Id}`;
    }
    if (formData.financePerson2Id) {
        queryStr = queryStr + `&financePerson2Id=${formData.financePerson2Id}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + `&departmentId=${formData.departmentId}`;
    }
    if (formData.cdrId) {
        queryStr = queryStr + `&cdrId=${formData.cdrId}`;
    }
    if (formData.statusId) {
        queryStr = queryStr + `&statusId=${formData.statusId}`;
    }
    if (formData.fromStatusDate) {
        queryStr = queryStr + `&fromStatusDate=${formData.fromStatusDate}`;
    }
    if (formData.toStatusDate) {
        queryStr = queryStr + `&toStatusDate=${formData.toStatusDate}`;
    }
    if (formData.search) {
        queryStr = queryStr + `&search=${formData.search}`;
    }
    if (formData.deliverycode.length) {
        queryStr = queryStr + `&deliveryCode=${formData.deliverycode}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getAppraisalList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/tradeIns/v2`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();
    
    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.fromLogDate) {
        queryStr = queryStr + `&fromLogDate=${formData.fromLogDate}`;
    }
    if (formData.toLogDate) {
        queryStr = queryStr + `&toLogDate=${formData.toLogDate}`;
    }
    if (formData.search) {
        queryStr = queryStr + `&search=${formData.search}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getAppointmentList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/appointments/v2`;
    
    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.fromAppointmentDate) {
        queryStr = queryStr + `&fromAppointmentDate=${formData.fromAppointmentDate}`;
    }
    if (formData.toAppointmentDate) {
        queryStr = queryStr + `&toAppointmentDate=${formData.toAppointmentDate}`;
    }
    if (formData.search) {
        queryStr = queryStr + `&search=${formData.search}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getServiceContractList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/contracts/v2`;
    
    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;

     if (formData.locationId) {
         queryStr = queryStr + `&locationId=${formData.locationId}`;
     }
     if (formData.fromLogDate) {
         queryStr = queryStr + `&fromLogDate=${formData.fromLogDate}`;
     }
     if (formData.toLogDate) {
         queryStr = queryStr + `&toLogDate=${formData.toLogDate}`;
     }
     if (formData.search) {
         queryStr = queryStr + `&search=${formData.search}`;
     }

    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};



export const getCancellationList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/cancellations/v2`;
    
    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
   if (formData.locationId) {
       queryStr = queryStr + `&locationId=${formData.locationId}`;
   }
   if (formData.fromCancelDate) {
       queryStr = queryStr + `&fromCancelDate=${formData.fromCancelDate}`;
   }
   if (formData.toCancelDate) {
       queryStr = queryStr + `&toCancelDate=${formData.toCancelDate}`;
   }
   if (formData.search) {
       queryStr = queryStr + `&search=${formData.search}`;
   }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getSalesPending = (data: any) => {
    const formData = data.formData;
    let url = `user/master/reports/salesPending?`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
 
    if (formData.fromCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `toCdrDate=${formData.toCdrDate}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + (queryStr && '&') + `cdr=${formData.cdr}`;
    }
    if (formData.sfe) {
        queryStr = queryStr + (queryStr && '&') + `sfe=${formData.sfe}`;
    }
    if (formData.fleet) {
        queryStr = queryStr + (queryStr && '&') + `fleet=${formData.fleet}`;
    }
    if (formData.cpo) {
        queryStr = queryStr + (queryStr && '&') + `cpo=${formData.cpo}`;
    }
    if (formData.cda) {
        queryStr = queryStr + (queryStr && '&') + `cda=${formData.cda}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.salesPersonId) {
        queryStr = queryStr + (queryStr && '&') + `salesPersonId=${formData.salesPersonId}`;
    }
    if (formData.deliveryCode) {
        queryStr = queryStr + (queryStr && '&') + `deliveryCode=${formData.deliveryCode}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const printSalesPendingReport = (data: any) => {
    const formData = data.formData;

    let url = `user/master/reports/salesPending/pdf`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?timezoneOffset=${timezoneOffset}`;


    if (formData.fromCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + (queryStr && '&') + `toCdrDate=${formData.toCdrDate}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + (queryStr && '&') + `cdr=${formData.cdr}`;
    }
    if (formData.sfe) {
        queryStr = queryStr + (queryStr && '&') + `sfe=${formData.sfe}`;
    }
    if (formData.fleet) {
        queryStr = queryStr + (queryStr && '&') + `fleet=${formData.fleet}`;
    }
    if (formData.cpo) {
        queryStr = queryStr + (queryStr && '&') + `cpo=${formData.cpo}`;
    }
    if (formData.cda) {
        queryStr = queryStr + (queryStr && '&') + `cda=${formData.cda}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.salesPersonId) {
        queryStr = queryStr + (queryStr && '&') + `salesPersonId=${formData.salesPersonId}`;
    }
    if (formData.deliveryCode) {
        queryStr = queryStr + (queryStr && '&') + `deliveryCode=${formData.deliveryCode}`;
    }
    
    return clientPrint(url + queryStr, {
       body: null,
    //responseType: 'application/pdf'
    method: "GET",

   });
};

export const getFinanceDetailReports = (data: any) => {
    const formData = data.formData;
    let url = `user/master/reports/financeDetail`;
    
    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;

    if (formData.financePersonId) {
        queryStr = queryStr + (queryStr && '&') + `financePersonId=${formData.financePersonId}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.financeProductId) {
        queryStr = queryStr + (queryStr && '&') + `financeProductId=${formData.financeProductId}`;
    }
    if (formData.fromClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `fromClosedDate=${formData.fromClosedDate}`;
    }
    if (formData.toClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `toClosedDate=${formData.toClosedDate}`;
    }


    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const printfinanceDetailReport = (data: any) => {
    const formData = data.formData;

    let url = `user/master/reports/financeDetail/pdf`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?timezoneOffset=${timezoneOffset}`;


    if (formData.financePersonId) {
        queryStr = queryStr + (queryStr && '&') + `financePersonId=${formData.financePersonId}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + (queryStr && '&') + `departmentId=${formData.departmentId}`;
    }
    if (formData.locationId) {
        queryStr = queryStr + (queryStr && '&') + `locationId=${formData.locationId}`;
    }
    if (formData.financeProductId) {
        queryStr = queryStr + (queryStr && '&') + `financeProductId=${formData.financeProductId}`;
    }
    if (formData.fromClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `fromClosedDate=${formData.fromClosedDate}`;
    }
    if (formData.toClosedDate) {
        queryStr = queryStr + (queryStr && '&') + `toClosedDate=${formData.toClosedDate}`;
    }
    
    return clientPrint(url + queryStr, {
       body: null,
    //responseType: 'application/pdf'
    method: "GET",

   });
};

export const getTaskList = (data: any) => {
    const formData = data.formData;
    let url = `user/master/deals/tasks/v2`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.fromStartDate) {
        queryStr = queryStr + `&fromStartDate=${formData.fromStartDate}`;
    }
    if (formData.toStartDate) {
        queryStr = queryStr + `&toStartDate=${formData.toStartDate}`;
    }
    if (formData.complete) {
        queryStr = queryStr + `&complete=${formData.complete}`;
    }
    if (formData.search) {
        queryStr = queryStr + `&search=${formData.search}`;
    }
    if (formData.isCompleted === 0 || formData.isCompleted === 1) {
        queryStr = queryStr + `&isCompleted=${formData.isCompleted}`;
    }
    return client(url + queryStr, {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


export const getDealTrackerSummary = (data: any) => { 
    const formData = data.formData;
    let url = `user/master/deals/summary/v2`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.fromLogDate) {
        queryStr = queryStr + `&fromLogDate=${formData.fromLogDate}`;
    }
    if (formData.toLogDate) {
        queryStr = queryStr + `&toLogDate=${formData.toLogDate}`;
    }
    if (formData.search) {
        queryStr = queryStr + `&search=${formData.search}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getFinancePending = (data: any) => { 
    const formData = data.formData;
    let url = `user/master/reports/financePending`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + `&departmentId=${formData.departmentId}`;
    }
    if (formData.financePersonId) {
        queryStr = queryStr + `&financePersonId=${formData.financePersonId}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + `&cdr=${formData.cdr}`;
    }
    if (formData.fromCdrDate) {
        queryStr = queryStr + `&fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + `&toCdrDate=${formData.toCdrDate}`;
    }
    return client(url + queryStr, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const printFinancePending = (data: any) => {

    const formData = data.formData;

    let url = `user/master/reports/financePending/pdf`;

    const local = new Date();
    let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
    let timezoneOffset =minutes.toFixed();

    let queryStr = `?timezoneOffset=${timezoneOffset}`;


    if (formData.locationId) {
        queryStr = queryStr + `&locationId=${formData.locationId}`;
    }
    if (formData.departmentId) {
        queryStr = queryStr + `&departmentId=${formData.departmentId}`;
    }
    if (formData.financePersonId) {
        queryStr = queryStr + `&financePersonId=${formData.financePersonId}`;
    }
    if (formData.cdr) {
        queryStr = queryStr + `&cdr=${formData.cdr}`;
    }
    if (formData.fromCdrDate) {
        queryStr = queryStr + `&fromCdrDate=${formData.fromCdrDate}`;
    }
    if (formData.toCdrDate) {
        queryStr = queryStr + `&toCdrDate=${formData.toCdrDate}`;
    }
    return clientPrint(url + queryStr, {
       body: null,
    //responseType: 'application/pdf'
    method: "GET",

   });
};






export const getVehicleTrackerList = (data: any) => {
     const formData = data.formData;
     let url = `user/master/vehicleTrackers/transfers`;
     const local = new Date();
     let minutes = (local.getTime() -  new Date(local.toUTCString().slice(0, -3)).getTime())/60000
     let timezoneOffset =minutes.toFixed();
     
    //let queryStr = `?limit=${formData.limit}&page=${formData.page}&sort=${formData.sort}&column=${formData.column}&timezoneOffset=${timezoneOffset}`;
    let queryStr = `?limit=${formData.limit}&timezoneOffset=${timezoneOffset}`;


   if (formData.sort) {
        queryStr = queryStr + `&sort=${formData.sort}`;
   }
   if (formData.page) {
        queryStr = queryStr + `&page=${formData.page}`;
   }
   if (formData.column) {
       queryStr = queryStr + `&column=${formData.column}`;
   }
   if (formData.locationId) {
       queryStr = queryStr + `&locationId=${formData.locationId}`;
   }
   if (formData.search) {
       queryStr = queryStr + `&search=${formData.search}`;
   } if (formData.status) {
       queryStr = queryStr + `&status=${formData.status}`;
   }

   if (formData.fromSentDate) {
        queryStr = queryStr + `&fromSentDate=${formData.fromSentDate}`;
   }
   if (formData.toSentDate) {
        queryStr = queryStr + `&toSentDate=${formData.toSentDate}`;
   }
   if (formData.transferStatus) {
        queryStr = queryStr + `&transferStatus=${formData.transferStatus}`;
   }
   if (formData.searchStockOnly) {
        queryStr = queryStr + `&searchStockOnly=${formData.searchStockOnly}`;
   }
  

    return client(url + queryStr, {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

/** VEhicle Tracker ***/

export const saveVehicleTrackerTrimYMM = async (formData: any) => {
    return client(`user/master/vehicleTrackers`, { body: formData, method: 'POST' }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const createVehicleTransferList = (id: number | string, formData: any) => {
    return client(`user/master/vehicleTrackers/${id}/transfers`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { data: data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};



export const viewVehicleTransfer = (id: number | string,formData: any) => {

    //const formData = data.formData;
     let url = `user/master/vehicleTrackers/${id}/transfers`;

    let queryStr = `?limit=${formData.limit}&page=${formData.page}`;

    return client(url + queryStr, {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateVehicleTransferList = (id: number | string,vehTransferId: number | string,formData: any) => {
    return client(`user/master/vehicleTrackers/${id}/transfers/${vehTransferId}`, {
        body: formData,
        method: "PATCH"
    }).then(
        (data) => {
            return { data: data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};


export const viewVehicleTransferList = (id: number | string,vehTransferId: number | string) => {

    //const formData = data.formData;
     let url = `user/master/vehicleTrackers/${id}/transfers/${vehTransferId}`

    return client(url, {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const createVehicleTransferNote = (id: number | string,vehTransferId: number | string,formData: any) => {
    return client(`user/master/vehicleTrackers/${id}/transfers/${vehTransferId}/notes`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { data: data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const getVehicleTransferNote = (id: number | string,vehTransferId: number | string) => {

    //const formData = data.formData;
     let url = `user/master/vehicleTrackers/${id}/transfers/${vehTransferId}/notes`


    return client(url , {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const viewVehicleTracker = (id: number | string) => {

    //const formData = data.formData;
     let url = `user/master/vehicleTrackers/${id}`

    return client(url , {   
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const soldVehicleTransfer = (id: number | string,vehTransferId: number | string,formData: any) => {
    return client(`user/master/vehicleTrackers/${id}/transfers/${vehTransferId}/sold`, {
        body: formData,
        method: "POST"
    }).then(
        (data) => {
            return { data: data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};

export const vehicleTrackerWriteUpCheck = (id: number | string)  => {
    return client(`user/master/vehicleTrackers/${id}/transfers/checkForWriteUps`, {
        body: null,
        method: "POST"
    }).then(
        (data) => {
            return { data: data, error: null };
        },
        (error) => {

            return { data: null, error };
        }
    );
};