import { put, takeLeading, call } from "redux-saga/effects";
import {
  pendingGetSubscriptions,
  resolvedGetSubscriptions,
  rejectedGetSubscriptions,
  pendingPayment,
  resolvedPayment,
  rejectedPayment,
  pendingCancelSubscription,
  resolvedCancelSubscription,
  rejectedCancelSubscription,
  pendingAddCreditCart,
  resolveAddCreditCart,
  rejectedAddCreditCart,
  pendingGetCreditCarts,
  resolvedGetCreditCarts,
  rejectedGetCreditCarts,
  pendingRemoveCreditCart,
  rejectedRemoveCreditCart,
  resolveRemoveCreditCart,
} from "store/subscriptions/subscriptions.action";
import { clearWebsite } from "store/website/website.action";
import {
  getSubscriptionsApi,
  paymentApi,
  cancelSubscriptionApi,
  addCreditCartApi,
  getCreditCartsApi,
  removeCreditCartApi,
} from "store/subscriptions/subscriptions.api";
import {
  HANDLE_GET_SUBSCRIPTIONS,
  HANDLE_PAYMENT,
  HANDLE_CANCEL_SUBSCRIPTION,
  HANDLE_ADD_CREDIT_CART,
  HANDLE_GET_CREDIT_CARTS,
  HANDLE_REMOVE_CREDIT_CART,
  HandleCancelSubscription,
  HandlePayment,
  HandleAddCreditCart,
  HandleRemoveCreditCart,
} from "store/subscriptions/subscriptions.types";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

function* getSubscriptionsSaga() {
  yield put(pendingGetSubscriptions());
  yield put(switchGlobalLoader(true));

  const { data, error } = yield getSubscriptionsApi();
  if (error) {
    yield put(switchGlobalLoader(false));
    yield put(rejectedGetSubscriptions(error));
    return;
  }
  yield put(switchGlobalLoader(false));
  yield put(resolvedGetSubscriptions(data));
}

function* paymentSaga(action: HandlePayment) {
  yield put(pendingPayment());

  console.log(action.payload);
  
  


  if(action.payload.shouldAddCreditCart){

  }


  const { data, error } = yield paymentApi(action.payload.product);

  if (error) {
    yield put(rejectedPayment(error));
    return;
  }

  if(action.payload.callback){
    action.payload.callback();
  }
  //
  // const stripePromise = yield loadStripe(process.env.REACT_APP_STRIPE);
  // const stripe = yield stripePromise;
  //
  // const result = yield stripe.redirectToCheckout({
  //   sessionId: data.session,
  // });

  // if (result.error) {
  //   // If `redirectToCheckout` fails due to a browser or network
  //   // error, display the localized error message to your customer
  //   // using `result.error.message`.
  // }

  yield put(resolvedPayment(data.session));
}

function* cancelSubscriptionSaga(action: HandleCancelSubscription) {
  yield put(pendingCancelSubscription());

  const { error } = yield cancelSubscriptionApi(action.payload.id);

  if (error) {
    yield put(rejectedCancelSubscription(error));
    return;
  }

  yield call(getSubscriptionsSaga);
  yield put(clearWebsite());

  action.payload.callback();
  yield put(resolvedCancelSubscription());
}

function* addCreditCartSaga(action: HandleAddCreditCart) {
  
  yield put(pendingAddCreditCart());

  const { data, error } = yield addCreditCartApi(action.payload.cart.id);

  if (error) {
    yield put(rejectedAddCreditCart(error));
    return;
  }

  if (data) {
    action.payload.callback();
    


    yield put(resolveAddCreditCart({
      ...data,
      expYear: data.exp_year,
      expMonth: data.exp_month,
      stripeId: data.id
    }));
  }
}

function* getCreditCartsSaga() {
  yield put(pendingGetCreditCarts());

  const { data, error } = yield getCreditCartsApi();

  if (error) {
    yield put(rejectedGetCreditCarts(error));
    return;
  }

  yield put(resolvedGetCreditCarts(data));
}

function* removeCreditCartSaga(action: HandleRemoveCreditCart) {
  yield put(pendingRemoveCreditCart());

  const { error, data } = yield removeCreditCartApi(action.payload.cartId);

  if (error) {
    yield put(rejectedRemoveCreditCart(error));
    return;
  }
  if (data) {



    yield put(resolveRemoveCreditCart(action.payload.cartId.toString()));
    action.payload.callback();
  }
}

export default function* SubscriptionSaga() {
  yield takeLeading(HANDLE_GET_SUBSCRIPTIONS, getSubscriptionsSaga);
  yield takeLeading(HANDLE_PAYMENT, paymentSaga);
  yield takeLeading(HANDLE_CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  // CREDIT CART CRUD
  yield takeLeading(HANDLE_GET_CREDIT_CARTS, getCreditCartsSaga);
  yield takeLeading(HANDLE_ADD_CREDIT_CART, addCreditCartSaga);
  yield takeLeading(HANDLE_REMOVE_CREDIT_CART, removeCreditCartSaga);
}
