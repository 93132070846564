import React from "react";
import {
  Modal,
  IconButton
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";

// import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
// import { useSelector, useDispatch } from "react-redux";
// import { AppState } from "store/index";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as constantStyle,
} from "constants/styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


import { CheckoutForm } from "pages/subscriptionsPaga/payWithCard/CheckoutForm";

const contentStyles = constantStyle(700, "auto");

interface IProps {
  onDismiss: () => void;
  isOpen: boolean;
}

export const AddCreditCartDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  isOpen,
}) => {
  // const dispatch = useDispatch();
  // const loading = useSelector((state: AppState) => state.subscriptions.loading);
  // const renderSpinner = () => {
  //   return loading ? (
  //     <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
  //   ) : null;
  // };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.headerNoShadow}>
        <CustomFontText>Add Credit Cart</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>

      <div className={contentStyles.body}>
        <CheckoutForm add onDismiss={onDismiss}/>
      </div>
    </Modal>
  );
};
