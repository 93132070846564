import { IDealerWebsite } from "types/dealerWebsiteTypes";
import client from "services/api/client";
import { IPagination } from "types/paginationTypes";

export const createDealerWebsite = (dealerwebsite: IDealerWebsite) => {
 // const linkSite: number[] = dealerwebsite.website?.split(',').map(x=>+x);
 const type= dealerwebsite.type;
  const linkSite = (type === 'standard' ? dealerwebsite.location : dealerwebsite.website);
  //const linkSite = dealerwebsite.location;
  return client("user/master/websites", {
    body: dealerwebsite,
    method: "POST",
  }).then(
    (data) => {
      const toBeLinkedWith = (data.id).toString();
      if(type === 'landing'){
      return client("user/master/websites/"+data.id+"/links", {
        body: {
         "website_ids":linkSite,
         "id" : data.id
        },
        method: "PUT",
      }).then(
        (data) => {
          return { data, error: null };
        },
        (error) => {
          return { data: null, error };
        }
      );
    }else{
      const responseDetails = client("user/master/websites/"+toBeLinkedWith+"/locations", {
        body: {
         "location_ids":linkSite,
         "id" : toBeLinkedWith
        },
        method: "PUT",
      }).then(
        (data) => {
          console.log(data);
          return { data, error: null };
        },
        (error) => {
          return { data: null, error };
        }
      );
      //return { data, error: null };
      return responseDetails
     // return { data, error: null };
    }

    },
    (error) => {
      return { data: null, error };
    }
  );
};



export const handleUpdateDealerWebsite = (dealerwebsite: IDealerWebsite) => {
  
  const type= dealerwebsite.type;
  const linkSite = type === 'standard' ? dealerwebsite.location : dealerwebsite.website;
  return client("user/master/websites/"+dealerwebsite.id, {
    body: dealerwebsite,
    method: "PATCH",
  }).then(
    (data) => {
     const toBeLinkedWith = data.id;
      if(type === 'landing'){
        return client("user/master/websites/"+toBeLinkedWith+"/links", {
          body: {
           "website_ids":linkSite,
           "id" : data.id
          },
          method: "PUT",
        }).then(
          (data) => {
            return { data, error: null };
          },
          (error) => {
            return { data: null, error };
          }
        );
      }else{
        const responseDetails = client("user/master/websites/"+toBeLinkedWith+"/locations", {
          body: {
           "location_ids":linkSite,
           "id" : toBeLinkedWith
          },
          method: "PUT",
        }).then(
          (data) => {
            return { data, error: null };
          },
          (error) => {
            return { data: null, error };
          }
        );
        //return { data, error: null };
        return responseDetails
      }
     // return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  )
}

export const getDealerWebsiteForDropDown = async () => {
  return client("user/master/websites", { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getDealerWebsiteList = async (request: IPagination) => {
  const { sort, page, type } = request;
  const query = `user/master/websites?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=10000&type=${type}`;
  return client(query, { body: null }, false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteDealerWebsite = (id: number | string) => {
  return client(`user/master/websites/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateBrand = (id: number | string, data: IDealerWebsite) => {
  return client(`user/master/brands/${id}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};