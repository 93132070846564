import { put, call, takeLeading, select } from "redux-saga/effects";
import { AppState } from "store";
import {
  CreateWebsitePending,
  CreateWebsiteRejected,
  pendingViewDealerWebsite,
  rejectedFetchDealerWebsite,
  handleGetDealerWebsiteList,
  handleGetLandDealerWebsiteList,
  HandleUpdateDealerWebsitePending,
  HandleUpdateDealerWebsiteResolved,
  HandleUpdateDealerWebsiteRejected,
  setSortDealerWebsite,
} from "store/dealerWebsite/dealerwebsite.action";

import {
  createDealerWebsite,
  getDealerWebsiteList,
  handleUpdateDealerWebsite,
} from "store/dealerWebsite/dealerwebsite.api";

import {
  CreateWebsiteResolved,
  CREATE_WEBSITE_RESOLVED,
  HANDLE_GET_DEALERWEBSITE,
  HandleGetDealerWebsite,
  HandleUpdateDealerWebsite,
  HANDLE_UPDATE_DEALERWEBSITE,
  HANDLE_GET_LAND_DEALERWEBSITE,
} from "store/dealerWebsite/dealerwebsite.types";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

function* createDealerWebsiteSaga(action: CreateWebsiteResolved) {
  yield put(CreateWebsitePending());
  try {
    const type = action.payload.dealerwebsite.type;
    const { error } = yield createDealerWebsite(
      action.payload.dealerwebsite
    );
    const rowsPerPage = yield select(
      (state: AppState) => type === 'standard' ? state.dealerWebsite.rowsPerPage : state.dealerWebsite.landingRowsPerPage
  );
  const page = yield select(
      (state: any) => state.dealerWebsite.currentPage
  );

  const request = { page: page, limit: rowsPerPage, type: type };
    if(type === 'standard'){
      yield put(handleGetDealerWebsiteList(request));
    }else{
      yield put(handleGetLandDealerWebsiteList(request));
    }  
    if (error) {
      yield put(CreateWebsiteRejected(error));
      //yield put(switchGlobalLoader(false));
      return;
    }


    yield call(action.payload.callback);
  } catch (error) {
    yield put(CreateWebsiteRejected(error.violations as any));
    return;
  }
  
}

function* updateDealerWebsiteSaga(action: HandleUpdateDealerWebsite) {
  yield put(HandleUpdateDealerWebsitePending());
  try {
    const type = action.payload.dealerwebsite.type;
    const { data, error } = yield handleUpdateDealerWebsite(
      action.payload.dealerwebsite
    );
    const rowsPerPage = yield select(
      (state: AppState) => type === 'standard' ? state.dealerWebsite.rowsPerPage : state.dealerWebsite.landingRowsPerPage
  );
  const page = yield select(
      (state: any) => state.dealerWebsite.currentPage
  );

  const request = { page: page, limit: rowsPerPage, type: type };
    if(type === 'standard'){
      yield put(handleGetDealerWebsiteList(request));
    }else{
      yield put(handleGetLandDealerWebsiteList(request));
    }  
    if (error) {
      yield put(HandleUpdateDealerWebsiteRejected(error));
      yield put(switchGlobalLoader(false));
      return;
    }
    yield put(HandleUpdateDealerWebsiteResolved(data));

    yield call(action.payload.callback);
  } catch (error) {
    yield put(CreateWebsiteRejected(error.violations as any));
    return;
  }
}


function* getDealerWebsiteSaga(action: HandleGetDealerWebsite) {
  yield put(switchGlobalLoader(true));
  const { page, limit, sort, column, type } = action.payload;
  const rowsPerPage = yield select(
    (state: AppState) => {if(type === 'standard'){
      return state.dealerWebsite.rowsPerPage; 
    }else if(type === 'landing'){
      return state.dealerWebsite.landingRowsPerPage;
    }
    },
);
const sortStore = yield select((state: AppState) => state.dealerWebsite.sort);
const sortColumn = yield select((state: AppState) => state.dealerWebsite.sortColumn);
const dealerType = yield select((state: AppState) => state.dealerWebsite.type);

yield put(setSortDealerWebsite(sort ? sort : sortStore));
  yield put(pendingViewDealerWebsite());

  try {
    const { data, error } = yield getDealerWebsiteList({
      page,
      limit: limit ? limit : rowsPerPage,
      sort: sort ? sort : sortStore,
      column: column ? column : sortColumn,
      type: type ? type : dealerType,
    });
    if (error) {
      yield put(rejectedFetchDealerWebsite(error));
      yield put(switchGlobalLoader(false));
      return;
    }
    if(type === 'standard'){
      yield put(handleGetDealerWebsiteList(data));   
    }else if(type === 'landing'){
      yield put(handleGetLandDealerWebsiteList(data));
    }
   
  } catch (error) {
    yield put(rejectedFetchDealerWebsite(error));
    yield put(switchGlobalLoader(false));
    return;
  }
  yield put(switchGlobalLoader(false));
}

function* getlandingDealerWebsiteSaga(action: HandleGetDealerWebsite) {
  yield put(switchGlobalLoader(true));
  const { page, limit, sort, column, type } = action.payload;
  const rowsPerPage = yield select(
    (state: AppState) => state.dealerWebsite.landingRowsPerPage,
);
const sortStore = yield select((state: AppState) => state.dealerWebsite.sort);
const sortColumn = yield select((state: AppState) => state.dealerWebsite.sortColumn);
const dealerType = yield select((state: AppState) => state.dealerWebsite.type);

yield put(setSortDealerWebsite(sort ? sort : sortStore));
  yield put(pendingViewDealerWebsite());

  try {
    const { data, error } = yield getDealerWebsiteList({
      page,
      limit: limit ? limit : rowsPerPage,
      sort: sort ? sort : sortStore,
      column: column ? column : sortColumn,
      type: type ? type : dealerType,
    });
    if (error) {
      yield put(rejectedFetchDealerWebsite(error));
      return;
    }
    if(type === 'standard'){
      yield put(handleGetDealerWebsiteList(data));   
    }else if(type === 'landing'){
      yield put(handleGetLandDealerWebsiteList(data));
    }
   
  } catch (error) {
    yield put(rejectedFetchDealerWebsite(error));
    return;
  }
  yield put(switchGlobalLoader(false));
}

export default function* DealerWebsiteSaga() {
  yield takeLeading(CREATE_WEBSITE_RESOLVED, createDealerWebsiteSaga);
  yield takeLeading(HANDLE_UPDATE_DEALERWEBSITE, updateDealerWebsiteSaga);
  yield takeLeading(HANDLE_GET_DEALERWEBSITE, getDealerWebsiteSaga);
  yield takeLeading(HANDLE_GET_LAND_DEALERWEBSITE, getlandingDealerWebsiteSaga);
}
