import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
	Stack,
	IStackStyles,
	TextField,
	PrimaryButton,
	Link
} from "@fluentui/react";
import {
	//useDispatch,
	useSelector
} from "react-redux";
import { AppState } from "store/index";
//import {
//	getModulePermissions,
//	isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetTasks } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
	useNavigate , 
	useLocation
} from "react-router-dom";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import masterDropdownValues from "constants/masterDropdownValues";
import { EditShopWorkBookMoreActionSubmenu } from "components/table/inventory/EditShopWorkBookMoreActionSubmenu";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import {
	convertUTCToLocalFormatModified,
	//convertUTCToLocalModified,
	convertUTCToLocalMoment
} from "utils/dateTimeFunctions";

const container: IStackStyles = {
	root: {
		marginTop: 10,
	},
};

//const credentialModuleName = "DealTracker";
export const TaskWorkbookPage = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
	//const [appointmentId, setAppointmentId] = useState(0);
	const [taskData, setTaskData] = useState([]);
	const [commonServerError, setcommonServerError] = useState(null);
	const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
	const tasks = useSelector((state: AppState) => state.dealTracker.tasks) as any;
	const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

	const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

	//const [credentials, setCredentials] = useState([]);
	const [glFilter, setGLFilter] = useState('');
	const [locationFilter, setLocationFilter] = useState(0);
	const [statusFilter, setStatusFilter] = useState('N');
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
	const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
	const [primaryLocation, setPrimaryLocation] = useState(0);
	const [sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState('dueDate');
	const [statesFlag, setStatesFlag] = useState(null);
	const [totalRecords, setTotalRecords] = useState(null);

    const handleFirstNameFilter = (val: any) => {
		setGLFilter(val.target.value);
        //handleFilters(val.target.value,locationFilter,logDateFromFilter,logDateToFilter);
		//dt.current.filter(val, 'firstName', 'contains');
	};


	const onLocationChange = (e: { value: any }) => {
		setLocationFilter(e.value);
		handleFilters(e.value, logDateFromFilter, logDateToFilter, statusFilter);
	}

	const onStatusChange = (e: { value: any }) => {
		setStatusFilter(e.value);
		handleFilters(locationFilter, logDateFromFilter, logDateToFilter,e.value);
	}


    const handleFromLogDateChange = (val: any) => {
		setLogDateFromFilter(val.target.value)
		handleFilters(locationFilter, val.target.value, logDateToFilter, statusFilter);
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
		handleFilters(locationFilter, logDateFromFilter, val.target.value, statusFilter);
    };



    const handleFilters = (locationFilt,logDateFromFilter,logDateToFilter, stsFilter) => {
	
		let taskWorkbook = [];
		let hiddenStr = '';
		tasks.forEach(function (task, index) {
			hiddenStr = '';

			if (task.deal) {
				const deal = task.deal
				if (deal.vehicle) {
					const vehicle = deal.vehicle;
					if (vehicle?.orderNumber) {
						hiddenStr = vehicle?.orderNumber;
					}
					if (vehicle?.stockNumber) {
						hiddenStr = hiddenStr + ' ' + vehicle?.stockNumber;
					}
					if (vehicle?.vin) {
						hiddenStr = hiddenStr + ' ' + vehicle?.vin;
					}
                }
				if (deal.salesPerson1)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
				if (deal.salesPerson2)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
				if (deal.financePerson1)
					hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
				if (deal.financePerson2)
					hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
				hiddenStr = hiddenStr + ' ' + deal.customer?.customerNumber;
			}

			let taskObj = {
				key: ''+index,
				data: {
					startDate: convertUTCToLocalFormatModified(task.startDate, "MM/DD/YYYY"), //moment(task.startDate).format("MM/DD/YYYY"),
					dueDate: convertUTCToLocalFormatModified(task.dueDate, "MM/DD/YYYY"),//moment(task.dueDate).format("MM/DD/YYYY"),
					description: task.description,
					dealNumber: task.deal.dealNumber,
					customer: task.deal.customer.businessName ? task.deal.customer.businessName : (task.deal.customer.firstName + ' ' + task.deal.customer.lastName),
					assignedBy: task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
					assignedTo: task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
					status: task.isCompleted ? "Complete" : null,
					assignedByDept: getDeptName(task.assignedByDepartmentId),
					assignedToDept: getDeptName(task.assignedToDepartmentId),
					dealId: task.deal.id,
					location: task.deal.location?.id,
					logDate:task.createdAt,
					id: task.id,
					hiddenCol: hiddenStr
				},
				children: getNotes(task.dealTaskNotes, index, task.deal.id)
			}
			taskWorkbook.push(taskObj);
		});
		if (locationFilt || logDateFromFilter || logDateToFilter || stsFilter) {
		//setTaskData(taskWorkbook);

            let results = taskWorkbook;

            if (results) {
				if (locationFilt > 0) {
					results = results.filter(x => x.data.location === locationFilt);
				}

				if (logDateFromFilter) {
					//results = results.filter(x => new Date(x.data.startDate) >= new Date(logDateFromFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.data.startDate)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
					results = results.filter(x => convertUTCToLocalMoment(x.data.startDate) >= convertUTCToLocalMoment(logDateFromFilter));
				}

				if (logDateToFilter) {
					//results = results.filter(x => new Date(x.data.startDate) <= new Date(logDateToFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.data.startDate)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
					results = results.filter(x => convertUTCToLocalMoment(x.data.startDate) <= convertUTCToLocalMoment(logDateToFilter));
				}
				if (stsFilter === 'Y') {
					results = results.filter(x => x.data.status === 'Complete');
				} else if (stsFilter === 'N') {
					results = results.filter(x => x.data.status === null);
                }
                // expandableTableRows(results);
				setTaskData(results);
            }
            
        } else {
            // expandableTableRows(vehicleList);
			setTaskData(taskWorkbook);
        }
    }



	//useEffect(() => {
	//	if (tasks && statesFlag && user.profileType) {
	//		if (tasks.length > 0 && user.profileType) {
	//			populateTaskData();
	//		}
	//		else {
	//			setTaskData([]);
	//		}
	//	}
	//}, [tasks, statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);


	useEffect(() => {
		if (user && user.profileType && tasks && tasks.length > 0 && statesFlag) {
			populateTaskData();
		}
		else {
			setTaskData([]);
		}

		if (user && user.roles) {
			if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
				setPrimaryLocation(user.primaryLocationId);
				setLocationFilter(user.primaryLocationId);
			}
		}

	}, [user, tasks, statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps



	useEffect(() => {
		setSortOrder(1);
		setSortField('dueDate');
		setStatesFlag(null);
		//if (user && user.auths && !isUserMaster(user.roles)) {
		//	setCredentials(getModulePermissions(user.auths, credentialModuleName));
		//}
		dispatch<any>(handleGetTasks())
		dispatch<any>(handleGetLocations())
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		computeTotalRecords();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData, glFilter]);

	const populateTaskData = () => {
		let taskWorkbook = [];
		let hiddenStr;
		tasks.forEach(function (task, index) {
			hiddenStr = '';
			if (task.deal) {
				const deal = task.deal
				if (deal.vehicle) {
					const vehicle = deal.vehicle;
					if (vehicle?.orderNumber) {
						hiddenStr = vehicle?.orderNumber;
					}
					if (vehicle?.stockNumber) {
						hiddenStr = hiddenStr + ' ' + vehicle?.stockNumber;
					}
					if (vehicle?.vin) {
						hiddenStr = hiddenStr + ' ' + vehicle?.vin;
					}
				}
				if (deal.salesPerson1)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson1?.firstName + ' ' + deal.salesPerson1?.lastName;
				if (deal.salesPerson2)
					hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
				if (deal.financePerson1)
					hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
				if (deal.financePerson2)
					hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
				hiddenStr = hiddenStr + ' ' + deal.customer?.customerNumber;
			}
			let taskObj = {
				key: ''+index,
				data: {
					startDate: convertUTCToLocalFormatModified(task.startDate, "MM/DD/YYYY"), //moment(task.startDate).format("MM/DD/YYYY"),
					dueDate: convertUTCToLocalFormatModified(task.dueDate, "MM/DD/YYYY"),//moment(task.dueDate).format("MM/DD/YYYY"),
					description: task.description,
					dealNumber: task.deal.dealNumber,
					customer: task.deal.customer.firstName + ' ' + task.deal.customer.lastName,
					assignedBy: task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
					assignedTo: task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
					status: task.isCompleted ? "Complete" : null,
					assignedByDept: getDeptName(task.assignedByDepartmentId),
					assignedToDept: getDeptName(task.assignedToDepartmentId),
					dealId: task.deal.id,
					location: task.deal.location?.id,
					logDate: task.createdAt,	
					id: task.id,
					hiddenCol: hiddenStr
				},
				children: getNotes(task.dealTaskNotes, index, task.deal.id)
			}
			taskWorkbook.push(taskObj);
		});

		handleFilters(locationFilter, logDateFromFilter, logDateToFilter, statusFilter);

		//let results = taskWorkbook;

		//if (results) {
		//	if (locationFilter) {
		//		results = results.filter(x => x.data.location === locationFilter);
		//	}
		//	if (statusFilter === 'Y') {
		//		results = results.filter(x => x.data.status === 'Complete');
		//	} else if (statusFilter === 'N') {
		//		results = results.filter(x => x.data.status === null);
		//	}

		//	// expandableTableRows(results);
		//	setTaskData(results);
		//}
	};

	const getNotes = (notes, index,id) => {
		if (notes && notes.length > 0) {
			let taskNotesWorkbook = []
			notes.forEach(function (item, i) {
				let taskObj = {
					key: index+'-'+i,
					data: {
						startDate: null,
						dueDate: convertUTCToLocalFormatModified(item.createdAt, "MM/DD hh:mm A"),
                        description: (item.employee?.firstName + ' ' + item.employee?.lastName) + ': ' + item.notes,
						dealNumber: null,
						customer: null,
						assignedBy: null,
						assignedTo: null,
						status: null,
						assignedByDept: null,
						assignedToDept: null,
						dealId: id,
						hiddenCol:''
					}
				}
				taskNotesWorkbook.push(taskObj);

			});
			return taskNotesWorkbook;
		} else
			return [];
		
	};

	const getDeptName = (id) => {
		let item = masterDropdownValues.departmentOptions.filter(x => x.key === id);
		if (item.length === 1) {
			return item[0].text;
		}
		return '';
	};

	const handleResetWorkbook = () => {   
		setGLFilter(null);
		setLocationFilter(primaryLocation);
		setStatusFilter('N');
		setLogDateFromFilter(null);
		setlogDateToFilter(null);
		setSortField('dueDate');
		setSortOrder(1);
		handleFilters(primaryLocation, null, null, 'N');
		//	let taskWorkbook = []
		//    tasks.forEach(function (task, index) {
		//	let taskObj = {
		//		key: ''+index,
		//		data: {
		//			startDate: convertUTCToLocalFormatModified(task.startDate, "MM/DD/YYYY"), //moment(task.startDate).format("MM/DD/YYYY"),
		//			dueDate: convertUTCToLocalFormatModified(task.dueDate, "MM/DD/YYYY"),//moment(task.dueDate).format("MM/DD/YYYY"),
		//			description: task.description,
		//			dealNumber: task.deal.dealNumber,
		//			customer: task.deal.customer.firstName + ' ' + task.deal.customer.lastName,
		//			assignedBy: task.assignedBy.firstName + ' ' + task.assignedBy.lastName,
		//			assignedTo: task.assignedTo.firstName + ' ' + task.assignedTo.lastName,
		//			status: task.isCompleted ? "Complete" : null,
		//			assignedByDept: getDeptName(task.assignedByDepartmentId),
		//			assignedToDept: getDeptName(task.assignedToDepartmentId),
		//			dealId: task.deal.id,
		//			location: task.deal.location ?.id,
		//			logDate:task.createdAt,
		//		},
		//		children: getNotes(task.dealTaskNotes, index, task.deal.id)
		//	}
		//	taskWorkbook.push(taskObj);
		//});

		//let results = taskWorkbook;

		//if (results) {
		//	if (primaryLocation) {
		//		results = results.filter(x => x.data.location === primaryLocation);
		//	}
		//	results = results.filter(x => x.data.status === null);
		//	// expandableTableRows(results);
		//	setTaskData(results);
		//}
	};

	const computeTotalRecords = () => {
		if (taskData && taskData.length) {
			let data = [...taskData]
			let exportData = [];

			
			if (glFilter) {
				const filterKeys = ['startDate', 'dueDate', 'description', 'dealNumber', 'customer', 'assignedBy', 'assignedTo', 'assignedByDept', 'assignedToDept', 'status', 'hiddenCol'];
				const multiFilter = (data = [], filterKeys = [], value = '') => data.filter((item) => filterKeys.some(key => item.data[key]?.toString().toLowerCase().includes(value) && item.data[key]));
				exportData = multiFilter(data, filterKeys, glFilter?.toLowerCase());
				setTotalRecords(exportData.length);
			}
			else {
				setTotalRecords(data.length);
            }
		}
		else
			setTotalRecords(0);
	}

	const exportExcel = () => {
		if (taskData && taskData.length) {
			let data = [...taskData]

			let exportData = [];
			
			data.forEach(function (task, index) {
				const parent = getExportData([task]);
				exportData.push(...parent);
				//exportData.concat(parent);
				if (task.children) {
					const children = getExportData(task.children);
					exportData.push(...children);
					//exportData.concat(children);
                }
			});
			if(glFilter){
			        const filterKeys = ['Start Date', 'Due Date','Description','Deal#', 'Customer','Assigned By Dept', 'Assigned By','Assigned To Dept','Assigned To', 'Status','hiddenCol'];
                    const multiFilter = (data = [], filterKeys = [], value = '') => data.filter((item) => filterKeys.some(key => item[key]?.toString().toLowerCase().includes(value) && item[key]));
                    exportData = multiFilter(exportData, filterKeys, glFilter?.toLowerCase());
			}
			exportData = exportData.map(({ hiddenCol, ...keepAttrs }) => keepAttrs);

			import('xlsx').then(xlsx => {
				const worksheet = xlsx.utils.json_to_sheet(exportData);
				const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
				const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
				saveAsExcelFile(excelBuffer, 'task-workbook');
			});
		}
	}

	const getExportData = (data) => {
		return data.map((task) =>
			({
				"Start Date": task.data.startDate,
				"Due Date": task.data.dueDate,
				"Description": task.data.description,
				"Deal#": task.data.dealNumber,
				"Customer": task.data.customer,
				"Assigned By Dept": task.data.assignedByDept,
				"Assigned By": task.data.assignedBy,
				"Assigned To Dept": task.data.assignedToDept,
				"Assigned to": task.data.assignedTo,
				"Status": task.data.status,
				"hiddenCol": task.data.hiddenCol
			}));
    }

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
			}
		});
	}

	//Datatable Templates
const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			             <Col>
			                  <div className=" employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Tasks"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div> 
						</Col>
						<Col>
							<div className="dd-control">
								<label className="lbl-text">Task Complete</label>
								<Dropdown
									id="status"
									placeholder=""
									value={statusFilter}
									options={masterDropdownValues.taskCompleteStatus}
									onChange={onStatusChange}
									optionLabel="text"
									optionValue="key"
									appendTo="self"
									className="custom-p-dropdown write-ups-location-dd"
									filter={false}
									resetFilterOnHide={true}
								/>
							</div>
						</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"32px"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
						 
			         	
						</Row>
				   <Row>
				   		<Col className="display-row" style={{ marginTop: "15px" }}>
				   			{
				   				totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
				   			}
				   			<Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
				   				Export to Excel
						   </Link>
				   		</Col>
				   </Row>
				</Col>
		    </Row>

		</div>:
		<div>
			<div>
				<Row>
					<Col md={11}>
			         <Row>
			             <Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Tasks"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
					    <Col md={3}>
					    	<div className="dd-control">
					    		<label className="lbl-text">Location</label>
					    		<Dropdown
					    			id="locationId"
					    			placeholder=""
					    			value={locationFilter}
					    			options={cityDealerList}
					    			onChange={onLocationChange}
					    			optionLabel="legalName"
					    			optionValue="id"
					    			appendTo="self"
					    			className="custom-p-dropdown write-ups-location-dd"
					    			filter={false}
					    			resetFilterOnHide={true}
					    		/>
					    	</div>
					    </Col>
			         	<Col  md={2}>
						  <div className="dd-control">
			                  <label className="lbl-text">Task Complete</label>
                              <Dropdown
                                    id="status"
                                    placeholder=""
                                    value={statusFilter}
                                    options={masterDropdownValues.taskCompleteStatus}
                                    onChange={onStatusChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
			         	<Col md={3}>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
                        <Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"32px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>

			         </Row>
				</Col>
					<Col>
					</Col>
				</Row>
			</div>
			<div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);


	useEffect(() => {
		const stateVal: any = location?.state
		if (stateVal && stateVal.searchInput) {
			setGLFilter(stateVal.searchInput);
		}
		if (stateVal?.fromKeymetrics) {
			setLocationFilter(stateVal?.locationId);
		}

		if (stateVal?.locationFilter) {
			setLocationFilter(stateVal?.locationFilter);
		}

		if (stateVal?.logDateFromFilter) {
			setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
		}

		if (stateVal?.logDateToFilter) {
			setlogDateToFilter(new Date(stateVal.logDateToFilter));
		}

		if (stateVal?.sortField) {
			setSortField(stateVal.sortField);
			setSortOrder(stateVal.sortOrder);
		}

		if (stateVal?.statusFilter) {
			setStatusFilter(stateVal?.statusFilter);
        }

		setStatesFlag(true);
	}, [location]);

	useEffect(() => {
		if (serverError) {
			if (serverError.detail) {
				setcommonServerError(serverError.detail);
			} else {
				setcommonServerError('Something went wrong');
			}
			window.scrollTo(0, 0);
		} else {
			setcommonServerError(null);
		}
	}, [serverError]);

	const rowClassName = (node) => {
		return { 'childRow': (node.key && node.key.includes('-')) };
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<div className="contextMenu">
					<EditShopWorkBookMoreActionSubmenu
						disableIcon={false}
						reportType={rowData}
						onEdit={(reportType) => mainTableAction(reportType)}
					/>
				</div>
			</React.Fragment>
		);
	};

	const mainTableAction = (reportType) => {
		const state = {
			searchInput: glFilter,
			pathfrom: '/tasks',
			tabIndex: 5,
			productId:`${reportType.data.id}`,
			workbookName: 'task',
			sortOrder,
			sortField,
			logDateFromFilter,
			logDateToFilter,
			locationFilter,
			statusFilter
		}
		navigate(`/${reportType.data.dealId}/dealtracker`,{state});
	}

	const handleRowClick = (event) => {
		let target = event.originalEvent.target;
		if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
			(target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
			target.lastElementChild.lastElementChild.firstElementChild.click();
		} else {
			mainTableAction(event.node);
		}
	}

	const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	return (
		isKarlProfileType ? <>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row className="pageTitleRow">
						<Col className="alignHeaders">
							<CustomFontText className="pageTitleFont pageName pageHeaderFont task">Task</CustomFontText>
						</Col>
					</Row>
				</Stack>
				{
					commonServerError ?
						<div>
							<p className="genericErrorColor"><span>{commonServerError}</span></p>
						</div> : null
				}
				<div className="taskWorkBookPagenator datatable-filter-demo noMaxWidthTable cust-tree-tbl task-workbook">
					<div className="card reportsTableVal">
						<TreeTable
							value={taskData}
							onRowClick={(event) => handleRowClick(event)}
							paginator rows={25}
							rowsPerPageOptions={[25,50,100]}
							selectionMode="single"
							header={header}
							sortField={sortField}
							sortOrder={sortOrder}
							onSort={handleColumnSort}
							globalFilter={glFilter}
							rowClassName={rowClassName} >
							<Column sortable field="startDate" className="startDate-Picker" style={{ width: '9em' }} header="Start Date" expander></Column>
							<Column sortable sortField="dueDate" field="dueDate" style={{ width: '8em' }} header="Due Date"></Column>
							<Column sortable field="description" className="textAlignStart" style={{ width: '23em',textAlign:"left",paddingLeft:"5px" }} header="Description"></Column>
							<Column sortable field="dealNumber" header="Deal#"></Column>
							<Column sortable field="customer" header="Customer"></Column>
							<Column sortable field="assignedByDept" header="Assigned By Dept"></Column>
							<Column sortable field="assignedBy" header="Assigned By"></Column>
							<Column sortable field="assignedToDept" header="Assigned To Dept"></Column>
							<Column sortable field="assignedTo" header="Assigned to"></Column>
							<Column sortable field="status" style={{ width: '6em' }} header="Status"></Column>
							<Column field="actions" style={{ width: '5em' }} header="Actions" body={actionBodyTemplate}></Column>
							{
								totalRecords > 0 && <Column style={{ display: "none" }} field="hiddenCol"></Column>
							}
						</TreeTable>
					</div>
				</div>
			</Stack>
		</> : null
	);
};
