import React from "react";
import { useEffect, useState } from "react";
import GoBack from "components/buttons/goBack/GoBack";
import { formatPathname } from "utils/helperFunctions";
import { Stack, PrimaryButton, SearchBox } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import { AddDealDialog } from "components/dialogs/deals/AddDealDialog";
import DealsTable from "components/table/deals/DealsTable";
import Styles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { eTypes, WithCredentials } from "hoc/withCredentials";
import { IProspect } from "types/prospectTypes";
import { IInventory } from "types/inventoryTypes";
import { handleGetDeals } from "store/deals/deals.action";
import DealsFilterPanel from "components/panels/DealsFilterPanel";

const credentialModuleName = "Deals";

export const DealsPage = () => {
  const [pathname, setPathname] = useState("");
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isOpenAddDealsDialog, setIsOpenAddDealsDialog] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const items = useSelector((state: AppState) => state.deals.items);

  const location = useLocation();
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const [credentials, setCredentials] = useState([]);
  // const loading = useSelector((state: AppState) => state.locations.loading);
  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null
  );
  const [selectedProspect, setSelectedProspect] = useState<IProspect | null>(
    null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setPathname(location.pathname);
    if (location.state && (location.state as any).action === "add") {
      if ((location.state as any).prospect) {
        setSelectedProspect({
          ...(location.state as any).prospect,
        } as IProspect);
      }
      if ((location.state as any).inventory) {
        setSelectedInventory({
          ...(location.state as any).inventory,
        } as IInventory);
      }
      setIsOpenAddDealsDialog(true);
    }
  }, [location]);

  const onDismiss = () => {
    setIsOpenAddDealsDialog(false);
  };

  useEffect(() => {
    items && setTotalItems(items.totalItems);
  }, [items]);

  useEffect(() => {
    if (user && user.auths && !isUserMaster(user.roles)) {
      // console.log(getModulePermissions(user.auths, credentialModuleName));
      setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (phrase: string) => {
    console.log("phrase", phrase);
    dispatch<any>(
      handleGetDeals({
        page: 1,
        phrase,
      })
    );
  };

  // SEARCHING WHEN WRITING IN SEARCH FIELD
  // const handleSearchEvent = (e) => {
  //   dispatch<any>(
  //     handleGetDeals({
  //       page: 1,
  //       phrase: Boolean(e) ? e.target.value : "",
  //     })
  //   );
  // };

  return (
    <>
      {isOpenAddDealsDialog ? (
        <AddDealDialog
          onDismiss={onDismiss}
          createFromProspect={selectedProspect}
          createFromInventory={selectedInventory}
        />
      ) : null}

      <Stack styles={Styles.container} tokens={{ childrenGap: 10 }}>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
            <GoBack label={formatPathname(pathname)} count={totalItems} />
            {credentials && user.roles && (
              <WithCredentials
                groupAuths={credentials}
                alias={"Deals"}
                type={eTypes.WRITE}
                roles={user.roles}
              >
                <PrimaryButton
                  onClick={() => setIsOpenAddDealsDialog(true)}
                  text="Create new deal"
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                />
              </WithCredentials>
            )}
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              onClick={() => setIsPanelOpen(true)}
              text="Show Filters"
              iconProps={{ iconName: "FilterSolid" }}
            />
            {/*  */}
            {/*<DefaultButton*/}
            {/*  styles={Styles.buttonStyles}*/}
            {/*  text={`selected filters (${11})`}*/}
            {/*  menuProps={menuProps}*/}
            {/*/>*/}

            <div>
              <SearchBox
                className={Styles.searchStyle}
                placeholder="Search"
                // onChange={handleSearchEvent}
                onSearch={handleSearch}
                style={{
                  width: 100,
                }}
              />
            </div>
            <DealsFilterPanel
              isOpen={isPanelOpen}
              onClose={() => setIsPanelOpen(false)}
            />
          </Stack>
        </Stack>
        <DealsTable />
      </Stack>
    </>
  );
};
