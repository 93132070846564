import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "pages/auth/loginPage/styles";
import { PrimaryButton } from "@fluentui/react";
import { useSelector } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { AppState } from "store";

const RegisterCompletePage: React.FC = () => {
  const navigate = useNavigate ();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [buttonText, setButtonText] = useState('');
  const serverErrors = useSelector(
    (state: AppState) => (state.auth as any).emailVerifyError
  );

  const [emailVerifyError, setemailVerifyError] = useState(false);

  useEffect(() => {
    if (serverErrors !== null) {
      if (serverErrors.code === 404) {
        setemailVerifyError(true);
        setTitle('Please Try Again');
        setContent('This link is invalid. Please register again and use the new link you receive via email.');
        setButtonText('Register Again');
      } else {
        setemailVerifyError(true);
        setTitle('Account Verified');
          setContent("Your account is now ready to use. The first time you log in, we'll guide you through basic setup.");
        setButtonText('Go to Login Page');
      }
    } 
  }, [serverErrors]);

  const handleRouting = () => {
      if (buttonText === 'Go to Login Page')
          navigate('/login');
      else
          navigate('/register');
  };

  return (
    <AuthLayoutPage>
    { emailVerifyError ?
        <Stack.Item
          styles={
            styles.stackItemStylesAutoHeight
          }
        >
          <Stack
            verticalAlign="space-between"
            styles={styles.loginContentStyles}>
            <Stack.Item>
              <CustomFontText>{title}</CustomFontText>
            </Stack.Item>

            <Stack.Item>
              <Stack tokens={styles.textFields}>
                 <div><p className="confirmationMsg">
                   {content}</p></div>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack tokens={styles.buttonTokens}>
                <PrimaryButton
                text={buttonText}
                onClick={handleRouting}
              />
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item> : 
        <Stack.Item>
            <Stack>
                {""}
            </Stack>
        </Stack.Item> }
    </AuthLayoutPage> 
  );
};

export default RegisterCompletePage;
