//import {IConfig} from 'types/configTypes'

/** GET_CONFIG */
export const HANDLE_GET_CONFIG = "HANDLE_GET_CONFIG ";

export interface HandleGetConfig {
  type: typeof HANDLE_GET_CONFIG ;
}

export const GET_CONFIG_PENDING = "GET_CONFIG_PENDING";

export interface GetConfigPending {
  type: typeof GET_CONFIG_PENDING;
}

export const GET_CONFIG_RESOLVED = "GET_CONFIG_RESOLVED";

export interface GetConfigResolved {
  type: typeof GET_CONFIG_RESOLVED;
  payload: any;
}

export const GET_CONFIG_REJECTED = "GET_CONFIG_REJECTED";

export interface GetConfigRejected {
  type: typeof GET_CONFIG_REJECTED;
  payload: object[];
}


/** UPDATE CONFIG */
export const HANDLE_UPDATE_CONFIG = "HANDLE_UPDATE_CONFIG ";

export interface HandleUpdateConfig {
  type: typeof HANDLE_UPDATE_CONFIG ;
  payload: any
}

export const UPDATE_CONFIG_PENDING = "UPDATE_CONFIG_PENDING";

export interface UpdateConfigPending {
  type: typeof UPDATE_CONFIG_PENDING;
}

export const UPDATE_CONFIG_RESOLVED = "UPDATE_CONFIG_RESOLVED";

export interface UpdateConfigResolved {
  type: typeof UPDATE_CONFIG_RESOLVED;
  payload: any;
}

export const UPDATE_CONFIG_REJECTED = "UPDATE_CONFIG_REJECTED";

export interface UpdateConfigRejected {
  type: typeof UPDATE_CONFIG_REJECTED;
  payload: object[];
}

export type ConfigActions =
  | HandleGetConfig
  | GetConfigPending
  | GetConfigResolved
  | GetConfigRejected
  | HandleUpdateConfig
  | UpdateConfigPending
  | UpdateConfigResolved
  | UpdateConfigRejected
