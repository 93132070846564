import { ICosigner } from "types/cosignerTypes";
import client from "services/api/client";

export const createCosigner = (cosigner: ICosigner) => {

  cosigner.leadType = "Cosigner";


  return client("user/master/co-signer", { body: cosigner }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const editCosigner = (cosigner: ICosigner, cosignerId: number) => {
  return client(`user/master/co-signer/${cosignerId}`, {
    body: cosigner,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
