import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "../../customFontText/CustomFontText";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: () => void;
}

export const NegativeBlackBookHistoryDialog: FunctionComponent<IProps> = ({
    onDismiss
}) => {

    const handleClose = () => {
        onDismiss();
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog dealFoundDiaHeight"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Negative History Adjuster</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "25px",marginTop: "-25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text">The Black Book valuation shows a negative adjustment based on this vehicles history.</label>
                    </div>
                </div>
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "25px",marginTop: "-25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text">History adjustments are calculated for events on the Experian AutoCheck (R) report.  For example, if a vehicle was involved in an accident, the values my be reduced.</label>
                    </div>
                </div>
            </div>
            <div className="dealRecFound body-auto-hight body-min-height-50">
                <div style={{ marginLeft: "25px",marginTop: "-25px",textAlign:"left"}}>
                    <div>
                        <label className="lbl-text">Additional research on this vehicle's history is recommended.</label>
                    </div>
                </div>
            </div>
            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center",marginTop:"-15px" }}>
                    <PrimaryButton
                        text="OK"
                        onClick={handleClose}
                    />
                </div>
            </div>
        </Modal>
    );
};



