import React, { useEffect, useState } from "react";
import moment from "moment";
import { Dropdown } from 'primereact/dropdown';

interface IProps {
    value: string,
    onTimeChange?(e: any): void;
    name?: string,
    beginLimit?: string,
    endLimit?: string,
    step?: number
}

export const TimePicker: React.FunctionComponent<IProps> = ({
    value,
    onTimeChange,
    name,
    beginLimit,
    endLimit,
    step
}) => {
    const [options, setOptions] = useState([]);

    const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
        var timeValueIsEarlier = moment(timeValue, 'hh:mmA').diff(moment(endLimit, 'hh:mmA')) < 0
        var timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'hh:mmA').diff(moment(timeValue, 'hh:mmA')) < 0
        return timeValueIsEarlier && timeValueIsLaterThanLastValue;
    }

   

    useEffect(() => {
        let timeValue = beginLimit || "12:00 AM";
        let lastValue;
        let maxLimit = endLimit || "11:59 PM";
        let stepCount = step || 1;

        let data = [];
        data.push({ key: timeValue, text: timeValue });

        while (isEarlierThanEndLimit(timeValue, maxLimit, lastValue)) {
            lastValue = timeValue;
            timeValue = moment(timeValue, 'hh:mm A').add(stepCount, 'minutes').format('hh:mm A');
            data.push({ key: timeValue, text: timeValue })
        }
        setOptions(data);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    

    return (
        <>
            <Dropdown
                id="time"
                placeholder=""
                value={value}
                options={options}
                onChange={onTimeChange}
                optionLabel="text"
                optionValue="key"
                appendTo="self"
                className="fiqore-time-picker custom-p-dropdown"
                filter={false}
                dropdownIcon="pi pi-clock"
                resetFilterOnHide={true}
            />
        </>
        
	);
};


