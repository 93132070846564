/* eslint-disable array-callback-return */
import {
    Checkbox,
    ChoiceGroup,
    DefaultButton,
    Dropdown,
    IChoiceGroupOption, ITextFieldProps,
    Link,
    MaskedTextField,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import React, { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { AppState } from "store";
//import { getAddreessByZip } from "services/api/smartyStreets";
import { useFormik } from "formik";
import { useNavigate  } from "react-router-dom";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { handleRequestEinAccess, handleSaveGuidedSetupForm, handleVerifyEinNumber } from "store/primarySetup/primarySetup.action";
//import { dropdownStyles } from "constants/styles";
import AdditionalOwnerShip from "pages/primarySetupPage/AdditionalOwnerShip";
import * as Yup from "yup";
//import AdditionalLocationShip from "pages/primarySetupPage/AdditionalLocationShip";
import masterDropdownValues from "constants/masterDropdownValues";
import { validTenDigitPhone, validURL } from "utils/helperFunctions";
//import { OwnershipInfo } from "components/tooltip/OwnershipInfo";
import { WarningDialog } from "components/dialogs/warningDialog/WarningDialog";
import { LabelInfoTooltip } from "components/tooltip/LabelInfo";
//import { InputMask } from 'primereact/inputmask';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import LocationAccordion from "pages/primarySetupPage/LocationAccordion";
import { Calendar } from 'primereact/calendar';
import InputMask from "react-input-mask";
import { HandleZipCodeFlatData } from "store/shared/shared.action";

const iconClass = mergeStyles({
    fontSize: 15,
    height: 10,
    width: 10,
    margin: '0 25px',
});

//import { Dropdown as PRDropdown} from 'primereact/dropdown';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
                //<Typography component={'div'}>{children}</Typography>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

// const useStyles = makeStyles((theme: any) =>
//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme.palette?.background?.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography?.pxToRem(15),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography?.pxToRem(15),
//             color: theme.palette?.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 20,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette?.divider}`,
//             padding: theme?.spacing?.(1, 2),
//         },
//         link: {
//             color: theme.palette?.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//     }),
// );

export const GuidedSetUpPage = () => {
    // const  classes:any  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const userProfile = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const guidedSetup = useSelector(
        (state: AppState) => state.primarySetup.guidedSetup
    );

    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const [dealerZipApiMsg, setdealerZipApiMsg] = React.useState("");
    const [locationZipApiMsg, setlocationZipApiMsg] = React.useState("");
    const [ownerShip, setOwnerShip] = React.useState([]);
    const [locationShip, setlocationShip] = React.useState([]);
    const [dealerTab, setdealerTab] = React.useState(true);
    const [ownerTab, setownerTab] = React.useState(true);
    const [contactTab, setcontactTab] = React.useState(true);
    const [duplicateEIN, setduplicateEIN] = React.useState(false);
    const [verifiedEIN, setverifiedEIN] = React.useState(false);
    const [aggrement, setAggrement] = React.useState(false);
    const [cityCompanyList, setCityCompanyList] = React.useState([]);
    const [countyCompanyList, setCountyCompanyList] = React.useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [countyDealerList, setcountyDealerList] = React.useState([]);
    const [dealerChildFormValid, setDealerChildFormValid] = React.useState(true);
    // const [ownerChildFormValid, setOwnerChildFormValid] = React.useState(true);
    const [locationIssueDate, setlocationIssueDate] = React.useState<Date | Date[] | undefined | any>(undefined);
    const [defaultIssueDate] = React.useState<Date | Date[] | undefined>(undefined);
    //const defaultIssueDate = React.useState<Date | Date[] | undefined>(undefined);
    const [ownershipPercentError, setOwnershipPercentError] = React.useState(null);
    const [commonServerError, setcommonServerError] = React.useState({
        screen: null, error: null
    });
    const [aboutPageError, setaboutPageError] = React.useState({
        field: null, errMsg: null
    });
    const [showGuidedSteps, setshowGuidedSteps] = React.useState(false);
    const [businessAcc, setbusinessAcc] = React.useState(null);
    const [disableEIN, setdisableEIN] = React.useState(false);
    const [isOwner, setIsOwner] = React.useState(false);
    const [isWarningModalOn, setIsWarningModalOn] = React.useState(false);
    const [zipFlag, setZipFlag] = React.useState(true);
    const [warningContent, setWarningContent] = React.useState('');
    const [blurFlag, setBlurFlag] = React.useState(false);

    const yearRange = "1980:" + (new Date().getFullYear());

    const hasNumber = (str) => {
        return /\d/.test(str);
    }

    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        // setValue(newValue);
    };

    const addNewOwnerShip = () => {
        const ownersCount = ownerShip.length;
        if (ownersCount < 49) {
            let index = 1;
            if (ownersCount) {
                index = ownerShip[ownerShip.length - 1].index
            }

            let form = {
                'name': 'Owner ' + (index + 1),
                'index': index + 1,
                'form': null
            };

            setOwnerShip(state => [...state, form]);
        }
        else {
            setIsWarningModalOn(true);
            setWarningContent("You cannot have more than 50 owners.");
        }
    };

    const addNewDealerShip = () => {
        const locationsCount = locationShip.length;
        if (locationsCount < 49) {
            dispatch<any>(switchGlobalLoader(true));
            let index = 1;
            if (locationsCount) {
                index = locationShip[locationShip.length - 1].index + 1;
            }

            let form = {
                'name': 'Location ' + (index),
                'index': index,
                'form': locationFormik.values.locationForm,
            };
            setlocationIssueDate(defaultIssueDate);
            setlocationShip(state => [...state, form]);
            locationFormik.setValues(
                {
                    locationForm: {
                        ...initialLocationForm.locationForm
                    }
                }, true
            );
            setBlurFlag(false);
            locationFormik.resetForm();
        } else {
            setIsWarningModalOn(true);
            setWarningContent("You cannot have more than 50 locations.");
        }
    };

    let initialDealerForm = {
        dealerForm: {
            einNumber: "",
            legalName: "",
            companyAlias: "",
            street: "",
            zip: "",
            city: "",
            county: "",
            state: "",
        }
    };

    const dealerFormik = useFormik({
        initialValues: initialDealerForm,
        validationSchema: Yup.object({
            dealerForm: Yup.object({
                einNumber: Yup.string()
                    .test("len", "EIN must be 9 digits", (val) => {
                        if (val === undefined)
                            return true;
                        let tempVal = val?.replace(/[_-]/g, "");
                        if (tempVal.length > 0 && isNaN(tempVal)) {
                            return !isNaN(tempVal)
                        }
                        const val_length_without_dashes = val?.replace(/[^0-9]/g, '').length;
                        return val_length_without_dashes === 0 || val_length_without_dashes >= 9;
                    }).test("validEIN", "Invalid EIN", (val) => {
                        if (val === undefined)
                            return true;
                        let tempVal = val?.replace(/[_-]/g, "");
                        if (tempVal.length === 0 || isNaN(tempVal)) {
                            return true;
                        }
                        return parseInt(tempVal) > 0;
                    }).test("required", "Required", (val) => {
                        if (val === undefined)
                            return true;
                        return val?.replace(/[_-]/g, "").length > 0
                    }),
                companyAlias: Yup.string(),
                legalName: Yup.string().required("Required").min(3, "Legal Name must be Minimum 3 characters"),
                street: Yup.string().required("Required"),
                zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
                city: Yup.string(),
                county: Yup.string(),
                state: Yup.string()
            })
        }),
        onSubmit: (values) => {
        },
    });

    let initialLocationForm = {
        locationForm: {
            legalName: "",
            //companyAlias: "",
            dealerLicenseNumber: "",
            dealerLicenseDateOfIssue: '',
            dealerLicenseState: "US",
            dealerType: "",
            collateral: "auto",
            // service:"yes",
            inventoryCondition: [],
            isMaintenanceFacilityAvailable: true,
            street: "",
            city: "",
            state: "",
            zip: "",
            county: "",
            phoneNumber: "",
            faxNumber: "",
            website: "",
            // email: ""
        }
        // countries: []      
    };

    const locationFormik = useFormik({
        initialValues: initialLocationForm,
        validationSchema: Yup.object({
            locationForm: Yup.object({
                legalName: Yup.string().required("Required"),
                //companyAlias: Yup.string(),
                dealerLicenseNumber: Yup.string().required("Required").min(1, "Dealer License Number must be Minimum 1 Character").max(50, "Dealer License Number must be Maximum 50 Character"),
                dealerLicenseDateOfIssue: Yup.string().required("Required"),
                dealerLicenseState: Yup.string(),//.required("Required"),
                dealerType: Yup.string().required("Required"),
                collateral: Yup.string().required("Required"),
                isMaintenanceFacilityAvailable: Yup.boolean(),
                // service: Yup.string().required("Required"),
                inventoryCondition: Yup.array().nullable().min(1, "Required"),
                street: Yup.string().required("Required"),
                city: Yup.string(),
                state: Yup.string(),
                zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
                county: Yup.string(),
                faxNumber: Yup.string()
                    .test("len", "Invalid fax number", (val) => {
                        if (val === undefined)
                            return true;
                        return validTenDigitPhone(val)
                    }),
                website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),//optional 
                // email: Yup.string().required("Required").email("Enter valid email"),
                phoneNumber: Yup.string()
                    .test("required", "Required", (val) => {
                        if (val === undefined)
                            return true;

                        return val?.replace(/[^0-9]/g, '').length > 0
                    })
                    .test("len", "Invalid phone number", (val) => {
                        if (val === undefined)
                            return true;
                        return validTenDigitPhone(val)
                    }).required("Required"),
                // role: Yup.string().required("Required"),
                //email: Yup.string()
                //    .required("Required")
                //    .email("Enter valid email")
            }),
        }),
        onSubmit: (values) => {

        },
    });

    let initialOwnerForm = {
        ownerForm: {
            firstName: "",
            lastName: "",
            // role: "",
            ownershipPercentage: "",
            phoneNumber: "",
            email: ""
        }
    };

    const ownerFormik = useFormik({
        initialValues: initialOwnerForm,
        validationSchema: Yup.object({
            ownerForm: Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                email: Yup.string().required("Required").email("Enter valid email"),
                phoneNumber: Yup.string()
                    .test("required", "Required", (val) => {
                        if (val === undefined)
                            return true;
                        return val?.replace(/[^0-9]/g, '').length > 0
                    })
                    .test("len", "Invalid phone number", (val) => {
                        if (val === undefined)
                            return true;
                        return validTenDigitPhone(val)
                    }).required("Required"),
                ownershipPercentage: Yup.string().digitsOnly().test("range", "Value between 1 to 100", (val) => {
                    if (val === undefined)
                        return true;
                    return parseInt(val) > 0 && parseInt(val) <= 100
                }).required("Required").typeError("Value must be a number"),
            }),
        }),
        onSubmit: (values) => {
        },
    });

    let initialContactForm = {
        contactForm: {
            workPhone: "",
            //alternatePhone: "",
            roles: [],
        }
    };

    const contactFormik = useFormik({
        initialValues: initialContactForm,
        validationSchema: Yup.object({
            contactForm: Yup.object({
                workPhone: Yup.string()
                    .test("required", "Required", (val) => {
                        if (val === undefined)
                            return true;

                        return val?.replace(/[^0-9]/g, '').length > 0
                    })
                    .test("len", "Invalid phone", (val) => {
                        if (val === undefined)
                            return true;
                        return validTenDigitPhone(val)
                    }).required("Required"),
                //alternatePhone: Yup.string()
                //    .test("len", "Invalid alt phone", (val) => {
                //        if (val === undefined)
                //            return true;
                //        return validTenDigitPhone(val)
                //    }),
                roles: Yup.array().nullable().min(1, "Required")
            }),
        }),
        onSubmit: (values) => {
        },
    });

    const resetCommonError = (ErrScren, errMsg) => {
        setcommonServerError({
            screen: ErrScren, error: errMsg
        });
    };

    const checkOwnerForms = (onlyValidate = false, callBck) => {
        setOwnershipPercentError(null);
        ownerFormik.handleSubmit();
        let ownershipPercentage = 0;
        ownershipPercentage += parseInt(ownerFormik.values.ownerForm.ownershipPercentage);
        let childForms = true;
        if (ownerShip.length) {
            ownerShip.map((item, index) => {
                if (item.form) {
                    if (item.form.values) {
                        item.form.handleSubmit();
                        ownershipPercentage += parseInt(item.form.values.ownershipPercentage);
                        if (!item.form.isValid) {
                            childForms = false;
                        }
                    } else {
                        ownershipPercentage += parseInt(item.form.ownershipPercentage);
                    }
                    if (ownerShip.length === (index + 1)) {
                        let validate = ischildFormvalidateOwner(onlyValidate, childForms, ownershipPercentage);
                        callBck(validate);
                    }
                }
            });
        } else {
            childForms = true;
            if (ownerFormik.isValid && ownerFormik.dirty && childForms) {
                // setlocationShip
                if (!onlyValidate) {
                    nextStep(2);
                }
                // check for ownershipPercentage
                if (ownershipPercentage < 51) {
                    setOwnershipPercentError("Sum of Ownership Percentage should not be less than 51%");
                    nextStep(1);
                    callBck(false);
                    return;
                } else if (ownershipPercentage > 100) {
                    setOwnershipPercentError("Sum of Ownership Percentage should not exceed more than 100%");
                    nextStep(1);
                    callBck(false);
                    return;
                } else {
                    setOwnershipPercentError(null);
                }
            } else {
                if (onlyValidate) {
                    nextStep(1);
                    callBck(false);
                    return;
                }
            }

            callBck(true);
            return;
        }

    };

    const handleOwnerFormSubmit = () => {
        dispatch<any>(switchGlobalLoader(true));
        setOwnershipPercentError(null);
        resetCommonError(null, null);
        let guidedSetup = {
            isCompleted: false,
            isOwnerChecked: isOwner,
            owners: [],
            locations: [],
            dealer: null,
            contact: {}
        };
        dealerFormik.handleSubmit();

        if (!dealerFormik.isValid || !dealerFormik.dirty) {
            nextStep(0);
            dispatch<any>(switchGlobalLoader(false));
            return;
        }
        // added dealer
        guidedSetup.dealer = dealerFormik.values.dealerForm;
        // added split company alias
        if (typeof (dealerFormik.values.dealerForm.companyAlias) === 'string') {
            guidedSetup.dealer.companyAlias = dealerFormik.values.dealerForm.companyAlias.split(",");
        }
        // stripped special characters
        let strippedEinNumber = guidedSetup.dealer.einNumber?.replace(/\D/g, '').slice(0, 10);
        guidedSetup.dealer.einNumber = strippedEinNumber;


        checkOwnerForms(true, (res) => {
            if (res) {
                // adding owners
                ownerFormik.values.ownerForm.phoneNumber = ownerFormik.values.ownerForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                guidedSetup.owners.push(ownerFormik.values.ownerForm);
                ownerShip.map((item) => {
                    let childForm;
                    if (item.form.values) {
                        childForm = item.form.values;
                    } else {
                        childForm = item.form;
                    }
                    childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                    guidedSetup.owners.push(childForm);
                });
                if (!dealerTab) {
                    checkLocationForms(true, (res) => {
                        if (res) {
                            // stripped special characters
                            locationFormik.values.locationForm.phoneNumber = locationFormik.values.locationForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                            locationFormik.values.locationForm.isMaintenanceFacilityAvailable = locationFormik.values.locationForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                            // adding locations
                            guidedSetup.locations.push(locationFormik.values.locationForm);
                            locationShip.map((item) => {
                                let childForm;
                                if (item.form.values) {
                                    childForm = item.form.values;
                                } else {
                                    childForm = item.form;
                                }
                                // stripped special characters
                                childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                                childForm.faxNumber = (childForm.faxNumber && childForm.faxNumber.length > 0) ? childForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                                childForm.isMaintenanceFacilityAvailable = childForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                                guidedSetup.locations.push(childForm);
                            });
                            if (!contactTab) {
                                contactFormik.values.contactForm.workPhone = contactFormik.values.contactForm.workPhone?.replace(/\D/g, '').slice(0, 10);
                                //contactFormik.values.contactForm.alternatePhone = (contactFormik.values.contactForm.alternatePhone && contactFormik.values.contactForm.alternatePhone.length > 0) ? contactFormik.values.contactForm.alternatePhone?.replace(/\D/g, '').slice(0, 10) : '';
                                contactFormik.handleSubmit();
                                if (!contactFormik.isValid || !contactFormik.dirty) {
                                    nextStep(2);
                                    dispatch<any>(switchGlobalLoader(false));
                                    return;
                                }

                                if (contactFormik.values.contactForm.roles.length === 0) {
                                    setaboutPageError({
                                        field: 'roles', errMsg: 'Please select the roles'
                                    });
                                    nextStep(2);
                                    dispatch<any>(switchGlobalLoader(false));
                                    return;
                                } else if (!aggrement) {
                                    setaboutPageError({
                                        field: 'terms', errMsg: 'Required'
                                    });
                                    nextStep(2);
                                    dispatch<any>(switchGlobalLoader(false));
                                    return;
                                } else {
                                    setaboutPageError({
                                        field: null, errMsg: null
                                    });
                                }

                                guidedSetup.contact = contactFormik.values.contactForm;
                                dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                                    if (!res) {
                                        if (value === 1) {
                                            nextStep(2);
                                        }
                                    } else {
                                        if (res.code === 409) {
                                            setduplicateEIN(true);
                                            disableTabs();
                                            nextStep(0);
                                        }
                                        else {
                                            resetCommonError('owner', 'Failed to Save the Data');
                                        }
                                    }
                                    dispatch<any>(switchGlobalLoader(false));
                                }));
                            } else {
                                dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                                    if (!res) {
                                        if (value === 1) {
                                            setContacts();
                                            nextStep(2);
                                        }
                                    } else {
                                        if (res.code === 409) {
                                            setduplicateEIN(true);
                                            disableTabs();
                                            nextStep(0);
                                        }
                                        else {
                                            resetCommonError('owner', 'Failed to Save the Data');
                                        }
                                    }
                                    dispatch<any>(switchGlobalLoader(false));
                                }));
                            }
                        } else {
                            nextStep(2);
                            dispatch<any>(switchGlobalLoader(false));
                            return;
                        }
                    });

                }
                else {
                    dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                        if (!res) {
                            if (value === 1) {
                                nextStep(2);
                            }
                        } else {
                            if (res.code === 409) {
                                setduplicateEIN(true);
                                disableTabs();
                                nextStep(0);
                            }
                            else {
                                resetCommonError('owner', 'Failed to Save the Data');
                            }
                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }));
                }

            } else {
                nextStep(1);
                dispatch<any>(switchGlobalLoader(false));
                return;
            }
        });
    };

    const handleCompanySubmit = () => {
        dispatch<any>(switchGlobalLoader(true));
        resetCommonError(null, null);
        let index = 1;
        dealerFormik.handleSubmit();

        if (dealerFormik.isValid && dealerFormik.dirty) {
            let guidedSetup = {
                isCompleted: false,
                isOwnerChecked: isOwner,
                owners: [],
                locations: [],
                dealer: null,
                contact: {}
            };

            // added dealer
            guidedSetup.dealer = dealerFormik.values.dealerForm;

            // added split company alias
            if (typeof (dealerFormik.values.dealerForm.companyAlias) === 'string') {
                guidedSetup.dealer.companyAlias = dealerFormik.values.dealerForm.companyAlias.split(",");
            }

            // stripped special characters
            let strippedEinNumber = guidedSetup.dealer.einNumber?.replace(/\D/g, '').slice(0, 9);
            guidedSetup.dealer.einNumber = strippedEinNumber;
            if (!ownerTab) {
                checkOwnerForms(true, (res) => {
                    if (res) {
                        // adding owners
                        ownerFormik.values.ownerForm.phoneNumber = ownerFormik.values.ownerForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                        guidedSetup.owners.push(ownerFormik.values.ownerForm);
                        ownerShip.map((item) => {
                            let childForm;
                            if (item.form.values) {
                                childForm = item.form.values;
                            } else {
                                childForm = item.form;
                            }
                            childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                            guidedSetup.owners.push(childForm);
                        });
                        if (!dealerTab) {
                            checkLocationForms(true, (res) => {
                                if (res) {

                                    // stripped special characters
                                    locationFormik.values.locationForm.phoneNumber = locationFormik.values.locationForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                                    locationFormik.values.locationForm.faxNumber = (locationFormik.values.locationForm.faxNumber && locationFormik.values.locationForm.faxNumber.length > 0) ? locationFormik.values.locationForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                                    locationFormik.values.locationForm.isMaintenanceFacilityAvailable = locationFormik.values.locationForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                                    // guidedSetup.dealer.einNumber = strippedEinNumber;

                                    guidedSetup.locations.push(locationFormik.values.locationForm);
                                    locationShip.map((item) => {
                                        let childForm;
                                        if (item.form.values) {
                                            childForm = item.form.values;
                                        } else {
                                            childForm = item.form;
                                        }
                                        // stripped special characters
                                        childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                                        childForm.faxNumber = (childForm.faxNumber && childForm.faxNumber.length > 0) ? childForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                                        childForm.isMaintenanceFacilityAvailable = childForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                                        guidedSetup.locations.push(childForm);
                                    });
                                    if (!contactTab) {
                                        contactFormik.values.contactForm.workPhone = contactFormik.values.contactForm.workPhone?.replace(/\D/g, '').slice(0, 10);
                                        //contactFormik.values.contactForm.alternatePhone = (contactFormik.values.contactForm.alternatePhone && contactFormik.values.contactForm.alternatePhone.length > 0) ? contactFormik.values.contactForm.alternatePhone?.replace(/\D/g, '').slice(0, 10) : '';
                                        contactFormik.handleSubmit();
                                        if (!contactFormik.isValid || !contactFormik.dirty) {
                                            nextStep(1);
                                            dispatch<any>(switchGlobalLoader(false));
                                            return;
                                        }

                                        if (contactFormik.values.contactForm.roles.length === 0) {
                                            setaboutPageError({
                                                field: 'roles', errMsg: 'Please select the roles'
                                            });
                                            nextStep(1);
                                            dispatch<any>(switchGlobalLoader(false));
                                            return;
                                        } else if (!aggrement) {
                                            setaboutPageError({
                                                field: 'terms', errMsg: 'Required'
                                            });
                                            nextStep(1);
                                            dispatch<any>(switchGlobalLoader(false));
                                            return;
                                        } else {
                                            setaboutPageError({
                                                field: null, errMsg: null
                                            });
                                        }

                                        guidedSetup.contact = contactFormik.values.contactForm;
                                        dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                                            if (!res) {
                                                if (value === 0) {
                                                    nextStep(1);
                                                }
                                            } else {
                                                if (res.code === 409) {
                                                    setduplicateEIN(true);
                                                    disableTabs();
                                                    nextStep(0);
                                                }
                                                else {
                                                    resetCommonError('company', 'Failed to Save the Data');
                                                }
                                            }
                                            dispatch<any>(switchGlobalLoader(false));
                                        }));
                                    }
                                    else {
                                        dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                                            if (!res) {
                                                if (value === 0) {
                                                    setContacts();
                                                    nextStep(1);
                                                }
                                            } else {
                                                if (res.code === 409) {
                                                    setduplicateEIN(true);
                                                    disableTabs();
                                                    nextStep(0);
                                                }
                                                else {
                                                    resetCommonError('company', 'Failed to Save the Data');
                                                }
                                            }
                                            dispatch<any>(switchGlobalLoader(false));
                                        }));
                                    }
                                } else {
                                    nextStep(1);
                                    dispatch<any>(switchGlobalLoader(false));
                                    return;
                                }
                            });

                        }
                        else {
                            dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                                if (!res) {
                                    if (value === 0) {
                                        nextStep(1);
                                    }
                                } else {
                                    if (res.code === 409) {
                                        setduplicateEIN(true);
                                        disableTabs();
                                        nextStep(0);
                                    }
                                    else {
                                        resetCommonError('company', 'Failed to Save the Data');
                                    }
                                }
                                dispatch<any>(switchGlobalLoader(false));
                            }));
                        }


                    } else {
                        nextStep(1);
                        dispatch<any>(switchGlobalLoader(false));
                    }
                });


            } else {
                dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                    if (!res) {
                        if (value === 0) {
                            nextStep(index);
                        }
                    } else {
                        if (res.code === 409) {
                            setduplicateEIN(true);
                            disableTabs();
                            nextStep(0);
                        }
                        else {
                            resetCommonError('company', 'Failed to Save the Data');
                        }
                    }
                    dispatch<any>(switchGlobalLoader(false));
                }));
            }
        } else {
            dispatch<any>(switchGlobalLoader(false));
        }
    };

    const handlelocationFormSubmit = () => {
        dispatch<any>(switchGlobalLoader(true));
        resetCommonError(null, null);
        let guidedSetup = {
            isCompleted: false,
            isOwnerChecked: isOwner,
            owners: [],
            locations: [],
            dealer: null,
            contact: {}
        };
        dealerFormik.handleSubmit();

        if (!dealerFormik.isValid || !dealerFormik.dirty) {
            nextStep(0);
            dispatch<any>(switchGlobalLoader(false));
            return;
        }
        // added dealer
        guidedSetup.dealer = dealerFormik.values.dealerForm;
        // added split company alias
        if (typeof (dealerFormik.values.dealerForm.companyAlias) === 'string') {
            guidedSetup.dealer.companyAlias = dealerFormik.values.dealerForm.companyAlias.split(",");
        }

        // stripped special characters
        let strippedEinNumber = guidedSetup.dealer.einNumber?.replace(/\D/g, '').slice(0, 10);
        guidedSetup.dealer.einNumber = strippedEinNumber;

        checkOwnerForms(true, (res) => {
            if (res) {
                // adding owners
                ownerFormik.values.ownerForm.phoneNumber = ownerFormik.values.ownerForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                guidedSetup.owners.push(ownerFormik.values.ownerForm);
                ownerShip.map((item) => {
                    let childForm;
                    if (item.form.values) {
                        childForm = item.form.values;
                    } else {
                        childForm = item.form;
                    }
                    childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                    guidedSetup.owners.push(childForm);
                });

            } else {
                nextStep(1);
                dispatch<any>(switchGlobalLoader(false));
                return;
            }
        });

        checkLocationForms(true, (res) => {
            if (res) {
                // stripped special characters
                locationFormik.values.locationForm.phoneNumber = locationFormik.values.locationForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                locationFormik.values.locationForm.faxNumber = (locationFormik.values.locationForm.faxNumber && locationFormik.values.locationForm.faxNumber.length > 0) ? locationFormik.values.locationForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                locationFormik.values.locationForm.isMaintenanceFacilityAvailable = locationFormik.values.locationForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                // adding locations
                guidedSetup.locations.push(locationFormik.values.locationForm);
                locationShip.map((item) => {
                    let childForm;
                    if (item.form.values) {
                        childForm = item.form.values;
                    } else {
                        childForm = item.form;
                    }
                    childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                    childForm.faxNumber = (childForm.faxNumber && childForm.faxNumber.length > 0) ? childForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                    childForm.isMaintenanceFacilityAvailable = childForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                    guidedSetup.locations.push(childForm);
                });
                if (!contactTab) {
                    //if (userProfile && contactFormik && contactFormik.values && contactFormik.values.contactForm && contactTab) {
                    //    contactFormik.setValues(
                    //        {
                    //            ...contactFormik.values,
                    //            contactForm: {
                    //                ...contactFormik.values.contactForm,
                    //                'firstName': userProfile.firstName,
                    //                'lastName': userProfile.lastName,
                    //            }
                    //        }
                    //    );
                    //}
                    contactFormik.values.contactForm.workPhone = contactFormik.values.contactForm.workPhone?.replace(/\D/g, '').slice(0, 10);
                    //contactFormik.values.contactForm.alternatePhone = (contactFormik.values.contactForm.alternatePhone && contactFormik.values.contactForm.alternatePhone.length > 0) ? contactFormik.values.contactForm.alternatePhone?.replace(/\D/g, '').slice(0, 10) : '';
                    contactFormik.handleSubmit();
                    if (!contactFormik.isValid || !contactFormik.dirty) {
                        nextStep(3);
                        dispatch<any>(switchGlobalLoader(false));
                        return;
                    }

                    if (contactFormik.values.contactForm.roles.length === 0) {
                        setaboutPageError({
                            field: 'roles', errMsg: 'Please select the roles'
                        });
                        nextStep(3);
                        dispatch<any>(switchGlobalLoader(false));
                        return;
                    } else if (!aggrement) {
                        setaboutPageError({
                            field: 'terms', errMsg: 'Required'
                        });
                        nextStep(3);
                        dispatch<any>(switchGlobalLoader(false));
                        return;
                    } else {
                        setaboutPageError({
                            field: null, errMsg: null
                        });
                    }

                    guidedSetup.contact = contactFormik.values.contactForm;
                    dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                        if (!res) {
                            if (value === 2) {
                                nextStep(3);
                            }
                        } else {
                            if (res.code === 409) {
                                setduplicateEIN(true);
                                disableTabs();
                                nextStep(0);
                            }
                            else {
                                resetCommonError('location', 'Failed to Save the Data');
                            }
                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }));
                } else {
                    dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                        if (!res) {
                            if (value === 2) {
                                setContacts();
                                nextStep(3);
                            }
                        } else {
                            if (res.code === 409) {
                                setduplicateEIN(true);
                                disableTabs();
                                nextStep(0);
                            }
                            else {
                                resetCommonError('location', 'Failed to Save the Data');
                                nextStep(2);
                            }

                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }));
                }

            } else {
                nextStep(2);
                dispatch<any>(switchGlobalLoader(false));
            }
        });

        if (!contactTab) {
            if (contactFormik.values.contactForm.roles.length === 0) {
                setaboutPageError({
                    field: 'roles', errMsg: 'Please select the roles'
                });
                nextStep(3);
                return;
            } else if (!aggrement) {
                setaboutPageError({
                    field: 'terms', errMsg: 'Required'
                });
                nextStep(3);
                dispatch<any>(switchGlobalLoader(false));
                return;
            } else {
                setaboutPageError({
                    field: null, errMsg: null
                });
                nextStep(3);
                dispatch<any>(switchGlobalLoader(false));
                return;
            }
        }
    };

    const handleContactFormSubmit = () => {
        dispatch<any>(switchGlobalLoader(true));
        resetCommonError(null, null);
        setaboutPageError({
            field: null, errMsg: null
        });
        if (contactFormik.values.contactForm.roles.length === 0) {
            setaboutPageError({
                field: 'roles', errMsg: 'Please select the roles'
            });
            dispatch<any>(switchGlobalLoader(false));
            return;
        } else if (!aggrement) {
            setaboutPageError({
                field: 'terms', errMsg: 'Please confirm you have read and understand.'
            });
            dispatch<any>(switchGlobalLoader(false));
            return;
        } else {
            setaboutPageError({
                field: null, errMsg: null
            });
        }

        let guidedSetup = {
            isCompleted: false,
            isOwnerChecked: isOwner,
            owners: [],
            locations: [],
            dealer: null,
            contact: {}
        };
        dealerFormik.handleSubmit();

        if (!dealerFormik.isValid || !dealerFormik.dirty) {
            nextStep(0);
            dispatch<any>(switchGlobalLoader(false));
            return;
        }
        // added dealer
        guidedSetup.dealer = dealerFormik.values.dealerForm;
        // added split company alias
        if (typeof (dealerFormik.values.dealerForm.companyAlias) === 'string') {
            guidedSetup.dealer.companyAlias = dealerFormik.values.dealerForm.companyAlias.split(",");
        }

        // stripped special characters
        let strippedEinNumber = guidedSetup.dealer.einNumber?.replace(/\D/g, '').slice(0, 10);
        guidedSetup.dealer.einNumber = strippedEinNumber;

        checkOwnerForms(true, (res) => {
            if (res) {
                // adding owners
                ownerFormik.values.ownerForm.phoneNumber = ownerFormik.values.ownerForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                guidedSetup.owners.push(ownerFormik.values.ownerForm);
                ownerShip.map((item) => {
                    let childForm;
                    if (item.form.values) {
                        childForm = item.form.values;
                    } else {
                        childForm = item.form;
                    }
                    childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                    guidedSetup.owners.push(childForm);
                });
            } else {
                nextStep(1);
                dispatch<any>(switchGlobalLoader(false));
                return;
            }
        });

        checkLocationForms(true, (res) => {
            if (res) {
                // stripped special characters
                locationFormik.values.locationForm.phoneNumber = locationFormik.values.locationForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                locationFormik.values.locationForm.faxNumber = (locationFormik.values.locationForm.faxNumber && locationFormik.values.locationForm.faxNumber.length > 0) ? locationFormik.values.locationForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                locationFormik.values.locationForm.isMaintenanceFacilityAvailable = locationFormik.values.locationForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                // adding locations
                guidedSetup.locations.push(locationFormik.values.locationForm);
                locationShip.map((item) => {
                    let childForm;
                    if (item.form.values) {
                        childForm = item.form.values;
                    } else {
                        childForm = item.form;
                    }
                    childForm.phoneNumber = childForm.phoneNumber?.replace(/\D/g, '').slice(0, 10);
                    childForm.faxNumber = (childForm.faxNumber && childForm.faxNumber.length > 0) ? childForm.faxNumber?.replace(/\D/g, '').slice(0, 10) : '';
                    childForm.isMaintenanceFacilityAvailable = childForm.isMaintenanceFacilityAvailable.toString().toLowerCase() === 'true' ? true : false;
                    guidedSetup.locations.push(childForm);
                });

                contactFormik.values.contactForm.workPhone = contactFormik.values.contactForm.workPhone?.replace(/\D/g, '').slice(0, 10);
                //contactFormik.values.contactForm.alternatePhone = (contactFormik.values.contactForm.alternatePhone && contactFormik.values.contactForm.alternatePhone.length > 0) ? contactFormik.values.contactForm.alternatePhone?.replace(/\D/g, '').slice(0, 10) : '';
                contactFormik.handleSubmit();
                if (!contactFormik.isValid || !contactFormik.dirty) {
                    nextStep(3);
                    dispatch<any>(switchGlobalLoader(false));
                    return;
                }
                guidedSetup.contact = contactFormik.values.contactForm;
                guidedSetup.isCompleted = true;
                dispatch<any>(handleSaveGuidedSetupForm(guidedSetup, (res) => {
                    if (!res) {
                        dispatch<any>(switchGlobalLoader(false));
                        navigate('/dashboard');
                    } else {
                        if (res.code === 409) {
                            setduplicateEIN(true);
                            disableTabs();
                            nextStep(0);
                        }
                        else {
                            resetCommonError('contact', 'Failed to Save the Data');
                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }
                }));

            } else {
                nextStep(2);
                dispatch<any>(switchGlobalLoader(false));
                return;
            }
        });
    };

    const checkLocationForms = (onlyValidate = false, callBck) => {
        locationFormik.handleSubmit();
        let childForms = true;
        if (locationShip.length) {
            locationShip.map((item, index) => {
                if (item.form) {
                    if (item.form.values) {
                        item.form.handleSubmit();
                        if (!item.form.isValid) {
                            childForms = false;
                        }
                    }

                    if (locationShip.length === (index + 1)) {
                        let validate = ischildFormvalidateLocation(onlyValidate, childForms);
                        callBck(validate);
                    }
                }
            });
        } else {
            childForms = true;
            if (locationFormik.isValid && locationFormik.dirty && childForms) {
                // setlocationShip
                if (!onlyValidate) {
                    nextStep(2);
                    callBck(false);
                    return;
                }
            } else {
                if (onlyValidate) {
                    nextStep(1);
                    callBck(false);
                    return;
                }
            }
            callBck(true);
            return;
        }
    };

    const ischildFormvalidateLocation = (onlyValidate, childForms) => {
        if (locationFormik.isValid && childForms) {
            if (!onlyValidate) {
                nextStep(2);
            }
        } else {
            if (onlyValidate) {
                nextStep(1);
                return false;
            }
        }

        return true;
    };

    const ischildFormvalidateOwner = (onlyValidate, childForms, ownershipPercentage) => {
        if (ownerFormik.isValid && childForms) {
            if (!onlyValidate) {
                nextStep(2);
            }
        } else {
            if (onlyValidate) {
                nextStep(1);
                return false;
            }
        }

        // check for ownershipPercentage
        if (ownershipPercentage < 51) {
            setOwnershipPercentError("Sum of Ownership Percentage should not be less than 51%");
            nextStep(2);
            window.scrollTo(0, 0)
            return false;
        } else if (ownershipPercentage > 100) {
            setOwnershipPercentError("Sum of Ownership Percentage should not exceed more than 100%");
            nextStep(2);
            window.scrollTo(0, 0)
            return false;
        } else {
            setOwnershipPercentError(null);
        }

        return true;
    };

    const [dealerForm, setdealerForm] = React.useState({
        form: initialDealerForm,
        transitions: {
            enterRight: `animated enterRight`,
            enterLeft: `animated enterLeft`,
            exitRight: `animated exitRight`,
            exitLeft: `animated exitLeft`,
            intro: `animated intro`,
        },
    });

    const handleChildOwnerForm = (ev, index) => {
        let childForm: any = (ownerShip.filter(item => item.index === index));
        if (childForm.length) {
            childForm[0].form = ev;
        }

        setOwnerShip(
            ownerShip.map((item, index) => item.index === index ? childForm : item)
        );
    }

    const handleChildlocationForm = (ev, index) => {
        let childForm: any = (locationShip.filter(item => item.index === index));
        if (childForm.length) {
            childForm[0].form = ev;
        }

        setlocationShip(
            locationShip.map((item, index) => item.index === index ? childForm : item)
        );
    };

    const validatelocationFormSubmit = () => {
        let dealerChildForms = true;

        locationShip.map(e => {
            if (e.form.values) {
                e.form.handleSubmit();
                setTimeout(() => {
                    if ((e.form.isValid === false) || (e.form.dirty === false)) {
                        dealerChildForms = false;
                    }
                }, 1000);
            }
        });
        setDealerChildFormValid(dealerChildForms);
    };

    const validateOwnerFormSubmit = () => {
        // let ownerChildForms = true;

        ownerShip.map(e => {
            if (e.form.values) {
                e.form.handleSubmit();
                setTimeout(() => {
                    if ((e.form.isValid === false) || (e.form.dirty === false)) {
                        // ownerChildForms = false;
                    }
                }, 1000);
            }
        });

        // setOwnerChildFormValid(ownerChildForms);
    };

    const setContacts = () => {
        if (userProfile && contactFormik && contactFormik.values && contactFormik.values.contactForm && contactTab) {
            let roleArr = [];
            if (isOwner)
                roleArr = ['owner'];
            contactFormik.setValues(
                {
                    ...contactFormik.values,
                    contactForm: {
                        ...contactFormik.values.contactForm,
                        'workPhone': userProfile.workPhone,
                        //'alternatePhone': userProfile.alternatePhone,
                        'roles': roleArr
                    }
                }
            );
        }
    };

    const handleDateChange = (val) => {
        locationFormik.setValues(
            {
                ...locationFormik.values,
                locationForm: {
                    ...locationFormik.values.locationForm,
                    'dealerLicenseDateOfIssue': moment(val).format("YYYY-MM-DD")
                }
            }
        );

        setlocationIssueDate(val);
        //updateForm("IssuedDate", issuedDate);
    };

    const handleDateBlur = () => {
        setBlurFlag(true);
    }

    const handleDealerDropdownValue = (e, item) => {
        dealerFormik.setValues(
            {
                ...dealerFormik.values,
                dealerForm: {
                    ...dealerFormik.values.dealerForm,
                    [e.target.id]: item.key
                }
            }
        );
    };

    const handleDropdownValue = (e, item) => {
        locationFormik.setValues(
            {
                ...locationFormik.values,
                locationForm: {
                    ...locationFormik.values.locationForm,
                    [e.target.id]: item.key
                }
            }
        );
    };

    const handleDropdownRadioChange = (e, item) => {
        let field = e.target.name ? e.target.name : e.target.id
        /* formik.setValues(
            {
                ...formik.values,
                [field]: item.key,
            },
            true
        ); */
        locationFormik.setValues(
            {
                ...locationFormik.values,
                locationForm: {
                    ...locationFormik.values.locationForm,
                    [field]: item.key,
                }
            }
        );
    };

    const onChangeCheckBox = (e, isChecked) => {
        if (isChecked) {
            if (!locationFormik.values.locationForm.inventoryCondition.includes(e.target.id)) {
                let arr = locationFormik.values.locationForm.inventoryCondition;
                arr.push(e.target.id);

                locationFormik.setValues(
                    {
                        ...locationFormik.values,
                        locationForm: {
                            ...locationFormik.values.locationForm,
                            inventoryCondition: arr
                        }
                    }
                );
            }
        } else {
            if (locationFormik.values.locationForm.inventoryCondition.includes(e.target.id)) {

                locationFormik.setValues(
                    {
                        ...locationFormik.values,
                        locationForm: {
                            ...locationFormik.values.locationForm,
                            inventoryCondition: locationFormik.values.locationForm.inventoryCondition.filter(item => item !== e.target.id) as any,
                        }
                    }
                );
            }
        }
    };

    const onChangeRoleCheckBox = (e, isChecked) => {
        if (isChecked) {
            if (!contactFormik.values.contactForm.roles.includes(e.target.id)) {
                let arr = contactFormik.values.contactForm.roles;
                arr.push(e.target.id);

                contactFormik.setValues(
                    {
                        ...contactFormik.values,
                        contactForm: {
                            ...contactFormik.values.contactForm,
                            roles: arr
                        }
                    }
                );
            }
        } else {
            if (contactFormik.values.contactForm.roles.includes(e.target.id)) {

                contactFormik.setValues(
                    {
                        ...contactFormik.values,
                        contactForm: {
                            ...contactFormik.values.contactForm,
                            roles: contactFormik.values.contactForm.roles.filter(item => item !== e.target.id) as any,
                        }
                    }
                );
            }
        }
    };

    // const handleDateFormat = (e) => {
    //     return moment(e).format("MM/DD/YYYY");
    // };

    const handleRemoveOwner = (name) => {

        setOwnerShip(ownerShip.filter(item => name.includes(item.name) === false));
        validateOwnerFormSubmit();
    };

    const handleRemoveDealer = (name) => {
        if (!name) {
            const lastItem = locationShip[locationShip.length - 1];
            setlocationIssueDate(new Date(lastItem.form.values.dealerLicenseDateOfIssue));
            locationFormik.setValues(
                {
                    locationForm: {
                        ...lastItem.form.values
                    }
                }, true
            );
            name = lastItem.name;
        }
        setlocationShip(locationShip.filter(item => name.includes(item.name) === false));
        validatelocationFormSubmit();
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const handleZipCodeChangeForCompany = (e) => {
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            setZipFlag(true);
            dealerFormik.setValues(
                {
                    ...dealerFormik.values,
                    dealerForm: {
                        ...dealerFormik.values.dealerForm,
                        'zip': e.target.value
                    }
                }
            );
        }


        // handledealerFormChange(e, "zip");


        /* else if (e.target.value.length === 5) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {
    
                if (response.Status) {
                    //setCities(response.Cities);
                    //setCounties(response.Counties);
                    // updateForm("Cities", response.Cities);
                    
                    const { form } = dealerForm;
    
                    // form.list.cities = response.Cities;
                    // setCountyCompanyList(response.Counties);
                    // setCityCompanyList(response.Cities);
                    // // form.list.countries = response.Counties;
                    // form['county'] = response.DefaultCounty;
                    // form['city'] = response.DefaultCity;
                    // form['state'] = response.State;
    
                   // handledealerFormChange(response.Cities, "list.cities");
                    // updateForm("Counties", response.Counties);
                    //handledealerFormChange(response.Counties, "list.counties");
                    //handledealerFormChange(response.DefaultCounty, "county");
                    // updateForm("County", response.DefaultCounty);
                    // updateForm("City", response.DefaultCity);
                    //handledealerFormChange(response.DefaultCity, "city");
                    //handledealerFormChange(response.State, "state");
                    
                    // setdealerForm({
                    //     ...dealerForm,
                    //     form,
                    // });
    
                    setCityCompanyList(response.Cities);
                  setCountyCompanyList(response.Counties);
                  // form['county'] = response.DefaultCounty;
                  // form['city'] = response.DefaultCity;
                  // form['state'] = response.State;
                  
                  dealerFormik.setValues(
                  {
                    ...dealerFormik.values,
                    dealerForm: {
                      ...dealerFormik.values.dealerForm,
                      'county': response.DefaultCounty,
                      'city': response.DefaultCity,
                      'state': response.State
                    }
                  }
                );
                // setdealerFormik({
                //     ...dealerForm,
                //     form,
                // });
    
                    // updateForm("State", response.State);
                }
                else {
                    setZipApiMsg(response.ErrorMessage);
                    resetCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
            setZipApiMsg("");
        } */
    };

    const handleFirstNameChangeForCompany = (e) => {
        if (!e.target.value || !hasNumber(e.target.value)) {
            ownerFormik.setValues(
                {
                    ...ownerFormik.values,
                    ownerForm: {
                        ...ownerFormik.values.ownerForm,
                        'firstName': e.target.value
                    }
                }
            );
        }

    };

    const handleLastNameChangeForCompany = (e) => {
        if (!e.target.value || !hasNumber(e.target.value)) {
            ownerFormik.setValues(
                {
                    ...ownerFormik.values,
                    ownerForm: {
                        ...ownerFormik.values.ownerForm,
                        'lastName': e.target.value
                    }
                }
            );
        }

    };

    const handleZipCodeChangeForDealer = (e) => {
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            locationFormik.setValues(
                {
                    ...locationFormik.values,
                    locationForm: {
                        ...locationFormik.values.locationForm,
                        'zip': e.target.value
                    }
                }
            );
        }


        /* else if (e.target.value.length === 5) {
            dispatch<any>(switchGlobalLoader(true));
            (getAddreessByZip(e.target.value) as any).then((response) => {
    
                if (response.Status) {
                    const { form } = dealerForm;
    
                    setCityDealerList(response.Cities);
                    //setcountyDealerList(response.Counties);
                    
                    locationFormik.setValues(
                    {
                      ...locationFormik.values,
                      locationForm: {
                        ...locationFormik.values.locationForm,
                        'county': response.DefaultCounty,
                        'city': response.DefaultCity,
                        'state': response.State
                        //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
                      }
                    }
                  );
                  setdealerForm({
                      ...dealerForm,
                      form,
                  });
                    // updateForm("State", response.State);
                }
                else {
                    setZipApiMsg(response.ErrorMessage);
                    resetCityCountyState();
                }
                dispatch<any>(switchGlobalLoader(false));
            });
            setZipApiMsg("");
        } */
    };

    const nextStep = (e) => {
        setValue(e);
        if (e === 1) {
            setownerTab(false);
        } else if (e === 2) {
            setdealerTab(false);
        } else if (e === 3) {
            setcontactTab(false);
        }
    };

    const disableTabs = () => {
        setValue(0);
        setownerTab(true);
        setdealerTab(true);
        setcontactTab(true);
    };

    const previousStep = (e) => {
        resetCommonError(null, null);
        setOwnershipPercentError(null);
        setValue(e);
    };

    const options: IChoiceGroupOption[] = [
        { key: 'true', text: 'Yes' },
        { key: 'false', text: 'No' },
    ];

    //const collateralOptions: IChoiceGroupOption[] = [
    //    { key: 'auto', text: 'Auto' },
    //    { key: 'rv', text: 'RV' },
    //];

    const verifyEIN = () => {
        let einNumber = dealerFormik.values.dealerForm.einNumber?.replace(/\D/g, '').slice(0, 9);
        if (einNumber && (einNumber.length === 9)) {
            dispatch<any>(handleVerifyEinNumber(einNumber, (res) => {
                setdisableEIN(true);
                if (res && res.code && res.code === 409) {
                    setverifiedEIN(true);
                    setduplicateEIN(true);
                    setbusinessAcc(res.message);
                }
                else if (res.violations) {
                    // setbusinessAcc(null);
                    //setdisableEIN(false);
                    setIsWarningModalOn(true);
                    setWarningContent('You have entered the invalid EIN');
                }
                else {
                    setZipFlag(true);
                    setverifiedEIN(true);
                    setduplicateEIN(false);
                    setbusinessAcc(null);
                    dealerFormik.setValues(
                        {
                            ...dealerFormik.values,
                            dealerForm: {
                                ...dealerFormik.values.dealerForm,
                                'legalName': res.legalName ? res.legalName : '',
                                'street': res.street ? res.street : '',
                                'city': res.city ? res.city : '',
                                'state': res.state ? res.state : '',
                                'zip': res.zip ? res.zip : ''
                            }
                        }
                    );

                    if (res.isVerified) {
                        setCityCompanyList([{ key: res.city, text: res.city }]);
                    }
                    else {
                        setIsWarningModalOn(true);
                        setWarningContent('Tax ID could not be verified. Please confirm you entered it correctly or manually enter your business information.');
                    }

                }
            }));
        }
    };

    const handleResetEIN = () => {
        setverifiedEIN(false);
        setduplicateEIN(false);
        setdisableEIN(false);
        dealerFormik.setValues(
            {
                ...dealerFormik.values,
                dealerForm: {
                    ...initialDealerForm.dealerForm
                }
            }
        );
        dealerFormik.resetForm();
    };

    //const useMyInformation = (e, isChecked) => {
    //    setIsOwner(isChecked);
    //    if (userProfile && ownerFormik && ownerFormik.values && ownerFormik.values.ownerForm && isChecked) {

    //        ownerFormik.setValues(
    //            {
    //                ...ownerFormik.values,
    //                ownerForm: {
    //                    ...ownerFormik.values.ownerForm,
    //                    'firstName': userProfile.firstName,
    //                    'lastName': userProfile.lastName,
    //                    'email': userProfile.email
    //                }
    //            }
    //        );
    //    }
    //};

    const requestAccess = (e) => {
        let einNumber = dealerFormik.values.dealerForm.einNumber?.replace(/\D/g, '').slice(0, 10)
        dispatch<any>(handleRequestEinAccess(einNumber, (res) => {
            if (res.einStatus) {
                navigate('/accessRequested');
            } else {
                resetCommonError('company', 'Failed to Save the Data');
            }
        }));
    };

    /** user guided setup initial data */
    useEffect(() => {

        if (guidedSetup && guidedSetup.isCompleted === false) {
            setIsOwner(guidedSetup.isOwnerChecked);
            // dealer tab
            if ((typeof guidedSetup === 'object') &&
                (typeof guidedSetup.dealer === 'object')) {
                if (Array.isArray(guidedSetup.dealer.companyAlias)) {
                    guidedSetup.dealer.companyAlias = guidedSetup.dealer.companyAlias.join();
                }
                dealerFormik.setValues(
                    {
                        dealerForm: {
                            ...dealerFormik.values.dealerForm,
                            ...guidedSetup.dealer
                        }
                    }
                );
                if (guidedSetup.dealer.einNumber) {
                    setverifiedEIN(true);
                    setduplicateEIN(false);
                    setdisableEIN(true);
                } else {
                    setverifiedEIN(false);
                    setduplicateEIN(false);
                    setdisableEIN(false);
                }
            }

            // owner tab
            if ((typeof guidedSetup === 'object') && (guidedSetup.owners) &&
                (guidedSetup.owners.length)) {
                setownerTab(false);
                nextStep(1);
                setOwnerShip([]);
                guidedSetup.owners.map((form, index) => {
                    if (index === 0) {
                        ownerFormik.setValues(
                            {
                                ownerForm: {
                                    ...form
                                }
                            }
                        );
                    } else {
                        let dealerChildForms = {
                            'name': 'Owner ' + (index + 1),
                            'index': index + 1,
                            'form': form
                        };

                        setOwnerShip(state => [...state, dealerChildForms]);
                    }
                });
            }
            else if (userProfile && ownerFormik && ownerFormik.values && ownerFormik.values.ownerForm) {
                ownerFormik.setValues(
                    {
                        ...ownerFormik.values,
                        ownerForm: {
                            ...ownerFormik.values.ownerForm,
                            'firstName': userProfile.firstName,
                            'lastName': userProfile.lastName,
                            'email': userProfile.username,
                            'phoneNumber': userProfile.workPhone
                        }
                    }
                );
                console.log('userProfile', userProfile);
            }

            // location tab
            if ((typeof guidedSetup === 'object') && (guidedSetup.locations) &&
                (guidedSetup.locations.length)) {
                setdealerTab(false);
                nextStep(2);

                setlocationShip([]);
                //const totalLocation = guidedSetup.locations.length;

                guidedSetup.locations.map((form, index) => {
                    //if (index+1 === totalLocation) {
                    if (index === 0) {
                        locationFormik.setValues(
                            {
                                locationForm: {
                                    ...form
                                }
                            }
                        );
                        setlocationIssueDate(new Date(form.dealerLicenseDateOfIssue));
                        //let state = { code: form.dealerLicenseState, name: form.dealerLicenseState };
                        //setSelectedState(state);
                    } else {
                        let forms: any = {
                            'name': 'Location ' + (index),
                            'index': index,
                            'form': form
                        };
                        setlocationShip(state => [...state, forms]);
                    }
                });
            }
            // About You tab
            if ((typeof guidedSetup === 'object') && guidedSetup.contact && guidedSetup.contact.roles && guidedSetup.contact.roles.length) {

                setcontactTab(false);
                nextStep(3);
                contactFormik.setValues(
                    {
                        contactForm: {
                            ...contactFormik.values.contactForm,
                            ...guidedSetup.contact
                        }
                    }
                );
            }

            setshowGuidedSteps(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidedSetup]);

    useEffect(() => {
        let zipFields = locationFormik.values.locationForm.zip;
        if (zipFields && zipFields.length === 5 && !isNaN(zipFields as any)) {
            dispatch<any>(switchGlobalLoader(true));
            //(getAddreessByZip(zipFields) as any).then((response) => {

            dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (response) => {
                    if (response) {
                        const { form } = dealerForm;

                        setCityDealerList(response.Cities);
                        setcountyDealerList(response.Counties);
                        if (locationFormik.values.locationForm.city === '' &&
                            locationFormik.values.locationForm.county === '' &&
                            locationFormik.values.locationForm.state === '') {
                            locationFormik.setValues(
                                {
                                    ...locationFormik.values,
                                    locationForm: {
                                        ...locationFormik.values.locationForm,
                                        'county': response.DefaultCounty,
                                        'city': response.DefaultCity,
                                        'state': response.State
                                        //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
                                    }
                                }
                            );
                            setdealerForm({
                                ...dealerForm,
                                form,
                            });
                        }
                        setlocationZipApiMsg("");
                        // updateForm("State", response.State);
                    }
                    else {
                        // setZipApiMsg(response.ErrorMessage);
                        setlocationZipApiMsg('Invalid ZIP Code.');
                        locationFormik.setValues(
                            {
                                ...locationFormik.values,
                                locationForm: {
                                    ...locationFormik.values.locationForm,
                                    'county': "",
                                    'city': "",
                                    'state': ""
                                    //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
                                }
                            }
                        );
                        setCityDealerList([]);
                        setcountyDealerList([]);
                        // resetCityCountyState();
                    }
                    dispatch<any>(switchGlobalLoader(false));


                }
            }));

        }
        else if (showGuidedSteps) {
            locationFormik.setValues(
                {
                    ...locationFormik.values,
                    locationForm: {
                        ...locationFormik.values.locationForm,
                        'county': "",
                        'city': "",
                        'state': ""
                        //'dealerLicenseState': locationFormik.values.locationForm.dealerLicenseState ? locationFormik.values.locationForm.dealerLicenseState : response.State
                    }
                }
            );
            setCityDealerList([]);
            setcountyDealerList([]);
            setlocationZipApiMsg("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationFormik.values.locationForm.zip]);

    useEffect(() => {
        if (zipFlag) {
            let zipFields = dealerFormik.values.dealerForm.zip;
            if (zipFields.length === 5 && !isNaN(zipFields as any)) {
                dispatch<any>(switchGlobalLoader(true));
                //(getAddreessByZip(zipFields) as any).then((response) => {

                dispatch<any>(HandleZipCodeFlatData({
                    zipCode: zipFields,
                    callback: (response) => {
                        if (response) {

                            setCityCompanyList(response.Cities);
                            setCountyCompanyList(response.Counties);

                            dealerFormik.setValues(
                                {
                                    ...dealerFormik.values,
                                    dealerForm: {
                                        ...dealerFormik.values.dealerForm,
                                        'county': dealerFormik.values.dealerForm.county ? dealerFormik.values.dealerForm.county : response.DefaultCounty,
                                        'city': dealerFormik.values.dealerForm.city ? dealerFormik.values.dealerForm.city : response.DefaultCity,
                                        'state': dealerFormik.values.dealerForm.state ? dealerFormik.values.dealerForm.state : response.State
                                    }
                                }
                            );
                            setdealerZipApiMsg("");
                        }
                        else {
                            setdealerZipApiMsg('Invalid ZIP Code.')
                            dealerFormik.setValues(
                                {
                                    ...dealerFormik.values,
                                    dealerForm: {
                                        ...dealerFormik.values.dealerForm,
                                        'county': "",
                                        'city': "",
                                        'state': ""
                                    }
                                }
                            );
                            setCityCompanyList([]);
                            setCountyCompanyList([]);
                            //  setZipApiMsg(response.ErrorMessage);
                            //  resetCityCountyState();
                        }
                        dispatch<any>(switchGlobalLoader(false));
                    }
                }));
            } else if (showGuidedSteps) {
                dealerFormik.setValues(
                    {
                        ...dealerFormik.values,
                        dealerForm: {
                            ...dealerFormik.values.dealerForm,
                            'county': "",
                            'city': "",
                            'state': ""
                        }
                    }
                );
                setCityCompanyList([]);
                setCountyCompanyList([]);
                setdealerZipApiMsg("");
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealerFormik.values.dealerForm.zip]);

    const handleTooltip = (props: ITextFieldProps, toolTipContent) => (
        <LabelInfoTooltip props={props} content={toolTipContent} />
    );

    const handleMaskInputOwner = (e) => {
        ownerFormik.setValues(
            {
                ...ownerFormik.values,
                ownerForm: {
                    ...ownerFormik.values.ownerForm,
                    [e.target.id]: e.target.value
                }
            }
        );

    };

    const handleMaskInputLocation = (e) => {

        locationFormik.setValues(
            {
                ...locationFormik.values,
                locationForm: {
                    ...locationFormik.values.locationForm,
                    [e.target.id]: e.target.value
                }
            }
        );
    };

    //const handleMaskInputContact = (e) => {

    //    contactFormik.setValues(
    //        {
    //            ...contactFormik.values,
    //            contactForm: {
    //                ...contactFormik.values.contactForm,
    //                [e.target.id]: e.target.value
    //            }
    //        }
    //    );
    //};

    //const [dlStateError, setdDlStateError] = useState("");

    //const [selectedState, setSelectedState] = useState<any>(null);

    //const handleStateChange = (e: { value: any }) => {
    //    setSelectedState(e.value);
    //    locationFormik.setValues(
    //        {
    //            ...locationFormik.values,
    //            locationForm: {
    //                ...locationFormik.values.locationForm,
    //                'dealerLicenseState': e.value ? e.value.name:""
    //            }
    //        }
    //    );
    //    setdDlStateError(e.value ? "" : "Required");
    //}
    //const selectedStateTemplate = (option: { name: string, code: string }, props: { placeholder: string }) => {
    //    if (option) {
    //        return (
    //            <div className="state-item state-item-value">
    //                <div>{option.name}</div>
    //            </div>
    //        );
    //    }

    //    return (
    //        <span>
    //            {props.placeholder}
    //        </span>
    //    );
    //}

    //const stateOptionTemplate = (option: any) => {
    //    return (
    //        <div className="state-item">
    //            <div>{option.name}</div>
    //        </div>
    //    );
    //}

    return (
        <Stack className="guidedSetupContainer" tokens={{ childrenGap: 10 }}>
            {showGuidedSteps ?
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        bgcolor: 'background.paper',
                        display: 'block',
                    }}
                >
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            //variant="fullWidth"
                            //scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable force tabs example"
                        >
                            <Tab label="Business Info" icon={<BusinessIcon />} {...a11yProps(0)} />
                            <Tab label="Owners" icon={<PeopleIcon />} {...a11yProps(1)} disabled={ownerTab} />
                            <Tab label="Locations" icon={<StoreMallDirectoryIcon />} {...a11yProps(2)} disabled={dealerTab} />
                            <Tab label="About You" icon={<PersonIcon />} {...a11yProps(3)} disabled={contactTab} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} >
                            <div>
                                <h2 className="tab-inst">To get started, enter your Tax ID:</h2>
                                {
                                    commonServerError.screen === 'company' ?
                                        <div className="tabRowSection">
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{commonServerError.error}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                            </div>
                            <div style={{ padding: "0px 18%" }}>
                                <div className="tabRowSection" style={{ maxWidth: "1000px" }}>
                                    <Row style={{ padding: "10px 0px" }}>
                                        <Col sm={8} xs={12} md={8} lg={8}>
                                            <MaskedTextField
                                                id="dealerForm.einNumber"
                                                name="dealerForm.einNumber"
                                                mask="99-9999999"
                                                label="Federal Tax ID/EIN"
                                                placeholder=""
                                                value={dealerFormik.values.dealerForm.einNumber}
                                                autoComplete="off"
                                                required={true}
                                                disabled={disableEIN}
                                                onChange={dealerFormik.handleChange}
                                                onBlur={dealerFormik.handleBlur}
                                                errorMessage={
                                                    dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                    dealerFormik.touched.dealerForm.einNumber && dealerFormik.errors.dealerForm &&
                                                    dealerFormik.errors.dealerForm.einNumber &&
                                                    dealerFormik.errors.dealerForm.einNumber.toString()
                                                }
                                                onRenderLabel={(props: ITextFieldProps) => (handleTooltip(props, "Employer Identification Number"))}
                                            />
                                        </Col>
                                        <Col xs={12} sm={4} md={4} lg={4}>
                                            <div style={{ float: "right" }}>
                                                <PrimaryButton
                                                    disabled={(dealerFormik.errors.dealerForm && dealerFormik.errors.dealerForm.einNumber && dealerFormik.errors.dealerForm.einNumber.toString() !== '') || disableEIN || dealerFormik.values.dealerForm.einNumber === ''}
                                                    style={{ marginTop: "30px", marginBottom: "15px", marginRight: "15px" }}
                                                    onClick={() => verifyEIN()}
                                                    text="Verify"
                                                />
                                                <DefaultButton style={{ padding: "0px 30px" }} text="Reset" onClick={handleResetEIN} />
                                            </div>
                                        </Col>
                                    </Row>
                                    {verifiedEIN && !duplicateEIN ?
                                        <>
                                            <Row>
                                                <Col sm={7} xs={12} md={7} lg={7}>
                                                    <TextField
                                                        id="dealerForm.legalName"
                                                        name="dealerForm.legalName"
                                                        label="Business Legal Name"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        value={dealerFormik.values.dealerForm.legalName}
                                                        // onChange={(e) => handledealerFormChange(e, 'legalName')}
                                                        //onBlur={formik.handleBlur}
                                                        required={true}
                                                        onChange={dealerFormik.handleChange}
                                                        onBlur={dealerFormik.handleBlur}
                                                        errorMessage={
                                                            dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                            dealerFormik.touched.dealerForm.legalName && dealerFormik.errors.dealerForm &&
                                                            dealerFormik.errors.dealerForm.legalName && dealerFormik.errors.dealerForm.legalName &&
                                                            dealerFormik.errors.dealerForm.legalName.toString()
                                                        }
                                                        onRenderLabel={(props: ITextFieldProps) => (handleTooltip(props, "As listed on your tax return"))}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={4} md={4} lg={4}>
                                                    <TextField
                                                        id="dealerForm.companyAlias"
                                                        name="dealerForm.companyAlias"
                                                        label="DBA"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        value={dealerFormik.values.dealerForm.companyAlias}
                                                        // onChange={(e) => handledealerFormChange(e, 'legalName')}
                                                        //onBlur={formik.handleBlur}
                                                        onChange={dealerFormik.handleChange}
                                                        onBlur={dealerFormik.handleBlur}
                                                        errorMessage={
                                                            dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                            dealerFormik.touched.dealerForm.companyAlias && dealerFormik.errors.dealerForm &&
                                                            dealerFormik.errors.dealerForm.companyAlias && dealerFormik.errors.dealerForm.companyAlias &&
                                                            dealerFormik.errors.dealerForm.companyAlias.toString()
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={7} xs={12} md={7} lg={7} >
                                                    <TextField
                                                        id="dealerForm.street"
                                                        name="dealerForm.street"
                                                        label="Street Address"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        className="gs-business-input"
                                                        value={dealerFormik.values.dealerForm.street}
                                                        // ={(e) => handledealerFormChange(e, 'street')}
                                                        required={true}
                                                        onChange={dealerFormik.handleChange}
                                                        onBlur={dealerFormik.handleBlur}
                                                        errorMessage={
                                                            dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                            dealerFormik.touched.dealerForm.street && dealerFormik.errors.dealerForm &&
                                                            dealerFormik.errors.dealerForm.street &&
                                                            dealerFormik.errors.dealerForm.street.toString()
                                                        }
                                                        onRenderLabel={(props: ITextFieldProps) => (handleTooltip(props, "Your primary location or corporate headquarters"))}
                                                    />
                                                </Col>
                                                <Col sm={4} xs={12} md={4} lg={4}>
                                                    <TextField
                                                        id="dealerForm.zip"
                                                        name="dealerForm.zip"
                                                        label="Zip"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        className="gs-business-input-zip"
                                                        value={dealerFormik.values.dealerForm.zip}
                                                        onChange={(e) => handleZipCodeChangeForCompany(e)}
                                                        // errorMessage={zipApiMsg ? zipApiMsg : null}
                                                        required={true}
                                                        // onChange={dealerFormik.handleChange}
                                                        onBlur={dealerFormik.handleBlur}
                                                        errorMessage={
                                                            (dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                                dealerFormik.touched.dealerForm.zip && dealerFormik.errors.dealerForm &&
                                                                dealerFormik.errors.dealerForm.zip) ?
                                                                dealerFormik.errors.dealerForm.zip.toString()
                                                                : dealerZipApiMsg ? dealerZipApiMsg : null
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <div className="address-zip businessInfoTab-UI-GuidedSetup">
                                                <Row>
                                                    <Col sm={9} xs={12} md={9} lg={9}>
                                                        <Dropdown
                                                            id="city"
                                                            label="City"
                                                            placeholder=""
                                                            // selectedKey={dealerForm.form.city}
                                                            selectedKey={dealerFormik.values.dealerForm.city}
                                                            options={cityCompanyList}
                                                            // options={dropdownVal}
                                                            onChange={handleDealerDropdownValue}
                                                            required={true}
                                                            //onChange={dealerFormik.handleChange}
                                                            //onBlur={dealerFormik.handleBlur}
                                                            errorMessage={
                                                                dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                                dealerFormik.touched.dealerForm.city && dealerFormik.errors.dealerForm &&
                                                                dealerFormik.errors.dealerForm.city &&
                                                                dealerFormik.errors.dealerForm.city.toString()
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm={3} xs={12} md={3} lg={3}>
                                                        <TextField
                                                            id="dealerForm.state"
                                                            name="dealerForm.state"
                                                            label="State"
                                                            placeholder=""
                                                            autoComplete="off"
                                                            className="gs-business-input"
                                                            value={dealerFormik.values.dealerForm.state}
                                                            // onChange={(e) => handledealerFormChange(e, 'state')}
                                                            readOnly={true}
                                                            required={true}
                                                            onChange={dealerFormik.handleChange}
                                                            onBlur={dealerFormik.handleBlur}
                                                            errorMessage={
                                                                dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                                dealerFormik.touched.dealerForm.state && dealerFormik.errors.dealerForm &&
                                                                dealerFormik.errors.dealerForm.state &&
                                                                dealerFormik.errors.dealerForm.state.toString()
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={9} xs={12} md={9} lg={9}>

                                                        <Dropdown
                                                            id="county"
                                                            label="County"
                                                            placeholder=""
                                                            selectedKey={dealerFormik.values.dealerForm.county}
                                                            options={countyCompanyList}
                                                            onChange={handleDealerDropdownValue}
                                                            required={true}
                                                            errorMessage={
                                                                dealerFormik.touched && dealerFormik.touched.dealerForm &&
                                                                dealerFormik.touched.dealerForm.county && dealerFormik.errors.dealerForm &&
                                                                dealerFormik.errors.dealerForm.county &&
                                                                dealerFormik.errors.dealerForm.county.toString()
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </> : null}
                                </div>
                                {verifiedEIN && duplicateEIN ?
                                    <div className="tabRowSection">
                                        <Row>
                                            <Col>
                                                <div className="requestAccess">
                                                    It looks like a company with this Tax ID already has a QoreDMS account: <span>{businessAcc}.</span> </div>
                                                <div>
                                                    If this is your company, submit a request to access the account.
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <PrimaryButton
                                                    style={{ marginTop: "25px" }}
                                                    onClick={requestAccess}
                                                    text="Request Access"
                                                // iconProps={{ iconName: "Add" }}
                                                />
                                            </Col>
                                        </Row>
                                    </div> : null}
                            </div>

                            {verifiedEIN && !duplicateEIN ?
                                <div className="footerBtn">
                                    <hr />
                                    <Row>
                                        <Col>
                                            <PrimaryButton
                                                style={{ float: "right" }}
                                                // onClick={() => nextStep(1)}
                                                disabled={!dealerFormik.isValid || !dealerFormik.dirty}
                                                onClick={() => handleCompanySubmit()}
                                                text="Save & Continue"
                                                // type="submit"
                                                iconProps={{ iconName: "Forward" }}
                                            />
                                        </Col>
                                    </Row>
                                </div> : null}

                            {/* </form> */}


                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <div>
                                <h2 className="tab-inst">Who owns this company?</h2>
                                <h4 className="secondTitle">Please account for more than 50% of ownership.</h4>
                                {
                                    commonServerError.screen === 'owner' ?

                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{commonServerError.error}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                                {
                                    ownershipPercentError ?

                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{ownershipPercentError}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                            </div>
                            <div style={{ padding: "0px 20%" }}>
                                <div className="tabRowSection">
                                    <Row>
                                        <Col>
                                            {/* <label className="lbl-text">Dealership Name</label> */}
                                            <h3 className="sectionTitle">Owner 1</h3>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={6} md={6} lg={6}>
                                            <TextField
                                                id="ownerForm.firstName"
                                                name="ownerForm.firstName"
                                                label="First Name"
                                                placeholder=""
                                                autoComplete="off"
                                                value={ownerFormik.values.ownerForm.firstName}
                                                // onChange={(e) => handleLegalNameChange(e,'firstName')}
                                                //onBlur={formik.handleBlur}
                                                required={true}
                                                onChange={(e) => handleFirstNameChangeForCompany(e)}
                                                onBlur={ownerFormik.handleBlur}
                                                errorMessage={
                                                    ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.firstName &&
                                                    ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.firstName &&
                                                    ownerFormik.errors.ownerForm.firstName.toString()
                                                }
                                            />
                                        </Col>
                                        <Col sm={6} xs={6} md={6} lg={6}>
                                            <TextField
                                                id="ownerForm.lastName"
                                                name="ownerForm.lastName"
                                                label="Last Name"
                                                placeholder=""
                                                autoComplete="off"
                                                value={ownerFormik.values.ownerForm.lastName}
                                                // onChange={(e) => handleLegalNameChange(e, 'lastName')}
                                                //onBlur={formik.handleBlur}
                                                required={true}
                                                onChange={(e) => handleLastNameChangeForCompany(e)}
                                                onBlur={ownerFormik.handleBlur}
                                                errorMessage={
                                                    ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.lastName &&
                                                    ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.lastName &&
                                                    ownerFormik.errors.ownerForm.lastName.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={6} md={6} lg={6}>
                                            <div className="ms-TextField root-155">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text required-Label">Phone</label>
                                                    <div className={ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.phoneNumber &&
                                                        ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
                                                        <InputMask
                                                            autoClear={false}
                                                            required={true}
                                                            id="phoneNumber"
                                                            name="ownerForm.phoneNumber"
                                                            mask="(999)999-9999"
                                                            placeholder=""
                                                            value={ownerFormik.values.ownerForm.phoneNumber}
                                                            className="mask-TextField"
                                                            onChange={(e) => handleMaskInputOwner(e)}
                                                            onBlur={ownerFormik.handleBlur}>
                                                        </InputMask>
                                                    </div>
                                                    <span className="error-message">{
                                                        ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.phoneNumber &&
                                                        ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.phoneNumber &&
                                                        ownerFormik.errors.ownerForm.phoneNumber.toString()
                                                    }</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6} xs={6} md={6} lg={6}>
                                            <TextField
                                                id="ownerForm.email"
                                                name="ownerForm.email"
                                                label="Email"
                                                required={true}
                                                autoComplete="off"
                                                value={ownerFormik.values.ownerForm.email}
                                                onChange={ownerFormik.handleChange}
                                                onBlur={ownerFormik.handleBlur}
                                                errorMessage={
                                                    ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.email &&
                                                    ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.email &&
                                                    ownerFormik.errors.ownerForm.email.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={6} md={6} lg={6}>
                                            <TextField
                                                id="ownerForm.ownershipPercentage"
                                                name="ownerForm.ownershipPercentage"
                                                label="Ownership %"
                                                required={true}
                                                autoComplete="off"
                                                value={ownerFormik.values.ownerForm.ownershipPercentage}
                                                onChange={ownerFormik.handleChange}
                                                onBlur={ownerFormik.handleBlur}
                                                errorMessage={
                                                    ownerFormik.touched.ownerForm && ownerFormik.touched.ownerForm.ownershipPercentage &&
                                                    ownerFormik.errors.ownerForm && ownerFormik.errors.ownerForm.ownershipPercentage &&
                                                    ownerFormik.errors.ownerForm.ownershipPercentage.toString()
                                                }
                                            />
                                        </Col>

                                    </Row>
                                </div>
                                <Row>
                                    <Col>
                                        {ownerShip.map((item, index) => (
                                            <div key={"boxes-" + index}>
                                                <AdditionalOwnerShip owner={(e) => handleChildOwnerForm(e, item.index)}
                                                    ownerForm={item.form} index={item.index} onClickRemove={() => handleRemoveOwner(item.name)} />
                                            </div>
                                        ))}
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "center" }}>
                                    <Col>
                                        <PrimaryButton
                                            style={{ marginTop: "25px" }}
                                            onClick={addNewOwnerShip}
                                            text="Add Owner"
                                            type="submit"
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="footerBtn">
                                <hr />
                                <Row>
                                    <Col>
                                        <PrimaryButton
                                            // onClick={previousStep}
                                            onClick={() => previousStep(0)}
                                            text="Go Back"
                                            iconProps={{ iconName: "Back" }}
                                        />
                                    </Col>

                                    <Col>
                                        <PrimaryButton
                                            style={{ float: "right" }}
                                            onClick={handleOwnerFormSubmit}
                                            // nextStep(3)
                                            text="Save & Continue"
                                            disabled={(!ownerFormik.isValid || !ownerFormik.dirty)}
                                            iconProps={{ iconName: "Forward" }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {/* </form> */}
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <div>
                                <h2 className="tab-inst">Where are your dealerships?</h2>
                                <h4 className="secondTitle">Dealership names should be the names you use to market on your website, in advertising, etc.</h4>
                                {
                                    commonServerError.screen === 'location' ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{commonServerError.error}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                            </div>
                            <div>
                                <Row>
                                    <Col>
                                        {locationShip.map((item, index) => (<div key={"boxes-" + index}>
                                            <LocationAccordion owner={(e) => handleChildlocationForm(e, item.index)} index={item.index}
                                                locationForm={item.form} onClickRemove={() => handleRemoveDealer(item.name)} />
                                        </div>))}
                                    </Col>
                                </Row>
                                <div className="tabRowSection">
                                    <Row>
                                        <Col>
                                            <h3 className="sectionTitle">Location {locationShip && locationShip.length > 0 ? locationShip.length + 1 : 1}</h3>
                                            {locationShip && locationShip.length > 0 && (<Link
                                                onClick={() => handleRemoveDealer('')}
                                                underline={false}
                                                className="deleteLink"
                                            ><FontIcon aria-label="Delete" iconName="Delete" className={iconClass} /> Delete Location</Link>)
                                            }
                                        </Col>
                                    </Row>
                                    <div className="tabRowSection">
                                        <Row>
                                            <Col sm={6} xs={12} md={6} lg={6}>
                                                <TextField
                                                    id="locationForm.legalName"
                                                    name="locationForm.legalName"
                                                    label="Location Name"
                                                    placeholder=""
                                                    value={locationFormik.values.locationForm.legalName}
                                                    autoComplete="off"
                                                    required={true}
                                                    onChange={locationFormik.handleChange}
                                                    onBlur={locationFormik.handleBlur}
                                                    errorMessage={
                                                        locationFormik.touched.locationForm && locationFormik.touched.locationForm.legalName &&
                                                        locationFormik.errors.locationForm && locationFormik.errors.locationForm.legalName &&
                                                        locationFormik.errors.locationForm.legalName.toString()
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ padding: "0px 250px" }} className="tabRowSection dealership-info">
                                        <Row>
                                            <Col sm={9} xs={12} md={9} lg={9} >
                                                <TextField
                                                    id="locationForm.street"
                                                    name="locationForm.street"
                                                    label="Street Address"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    className="gs-business-input"
                                                    value={locationFormik.values.locationForm.street}
                                                    // onChange={(e) => handleLegalNameChange(e,'firstName')}
                                                    //onBlur={formik.handleBlur}
                                                    required={true}
                                                    onChange={locationFormik.handleChange}
                                                    onBlur={locationFormik.handleBlur}
                                                    errorMessage={
                                                        locationFormik.touched.locationForm && locationFormik.touched.locationForm.street &&
                                                        locationFormik.errors.locationForm && locationFormik.errors.locationForm.street &&
                                                        locationFormik.errors.locationForm.street.toString()
                                                    }
                                                />
                                            </Col>
                                            <Col sm={3} xs={12} md={3} lg={3}>
                                                <TextField
                                                    id="locationForm.zip"
                                                    name="locationForm.zip"
                                                    label="Zip"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    className="gs-business-input-zip"
                                                    value={locationFormik.values.locationForm.zip}
                                                    onChange={(e) => handleZipCodeChangeForDealer(e)}
                                                    //onBlur={formik.handleBlur}
                                                    required={true}
                                                    // onChange={locationFormik.handleChange}
                                                    onBlur={locationFormik.handleBlur}
                                                    errorMessage={
                                                        (locationFormik.touched.locationForm && locationFormik.touched.locationForm.zip &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.zip) ?
                                                            locationFormik.errors.locationForm.zip.toString()
                                                            : locationZipApiMsg ? locationZipApiMsg : null
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <div className="address-zip address-UI-GuidedSetup">
                                            <Row>
                                                <Col sm={9} xs={12} md={9} lg={9}>
                                                    <Dropdown
                                                        id="city"
                                                        label="City"
                                                        placeholder=""
                                                        selectedKey={locationFormik.values.locationForm.city}
                                                        options={cityDealerList}
                                                        onChange={handleDropdownValue}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col sm={3} xs={12} md={3} lg={3}>
                                                    <TextField
                                                        id="locationForm.state"
                                                        name="locationForm.state"
                                                        className="gs-business-input"
                                                        label="State"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        value={locationFormik.values.locationForm.state}
                                                        required={true}
                                                        readOnly={true}
                                                        onChange={locationFormik.handleChange}
                                                        onBlur={locationFormik.handleBlur}
                                                        errorMessage={
                                                            locationFormik.touched.locationForm && locationFormik.touched.locationForm.state &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.state &&
                                                            locationFormik.errors.locationForm.state.toString()
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={9} xs={12} md={9} lg={9}>
                                                    <Dropdown
                                                        id="county"
                                                        label="County"
                                                        placeholder=""
                                                        selectedKey={locationFormik.values.locationForm.county}
                                                        options={countyDealerList}
                                                        onChange={handleDropdownValue}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col sm={7} xs={12} md={7} lg={7}>
                                                <div className="ms-TextField root-155">
                                                    <div className="ms-TextField-wrapper">
                                                        <label className="lbl-text required-Label">Business Phone</label>
                                                        <div className={locationFormik.touched.locationForm && locationFormik.touched.locationForm.phoneNumber &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                            <InputMask
                                                                autoClear={false}
                                                                required={true}
                                                                id="phoneNumber"
                                                                name="locationForm.phoneNumber"
                                                                mask="(999)999-9999"
                                                                placeholder=""
                                                                value={locationFormik.values.locationForm.phoneNumber}
                                                                className="mask-TextField"
                                                                onChange={(e) => handleMaskInputLocation(e)}
                                                                onBlur={locationFormik.handleBlur}>
                                                            </InputMask>
                                                        </div>
                                                        <span className="error-message">{
                                                            locationFormik.touched.locationForm && locationFormik.touched.locationForm.phoneNumber &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.phoneNumber &&
                                                            locationFormik.errors.locationForm.phoneNumber.toString()
                                                        }</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12} md={4} lg={4}>
                                                <div className="ms-TextField root-155">
                                                    <div className="ms-TextField-wrapper">
                                                        <label className="lbl-text">Fax</label>
                                                        <div className={locationFormik.touched.locationForm && locationFormik.touched.locationForm.faxNumber &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.faxNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                            <InputMask
                                                                autoClear={false}
                                                                required={true}
                                                                id="faxNumber"
                                                                name="locationForm.faxNumber"
                                                                mask="(999)999-9999"
                                                                placeholder=""
                                                                value={locationFormik.values.locationForm.faxNumber}
                                                                className="mask-TextField"
                                                                onChange={(e) => handleMaskInputLocation(e)}
                                                                onBlur={locationFormik.handleBlur}>
                                                            </InputMask>
                                                        </div>
                                                        <span className="error-message">{
                                                            locationFormik.touched.locationForm && locationFormik.touched.locationForm.faxNumber &&
                                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.faxNumber &&
                                                            locationFormik.errors.locationForm.faxNumber.toString()
                                                        }</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} xs={12} md={12} lg={12}>
                                                <TextField
                                                    id="locationForm.website"
                                                    name="locationForm.website"
                                                    label="Website"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    value={locationFormik.values.locationForm.website}
                                                    onChange={locationFormik.handleChange}
                                                    onBlur={locationFormik.handleBlur}
                                                    errorMessage={
                                                        locationFormik.touched.locationForm && locationFormik.touched.locationForm.website &&
                                                        locationFormik.errors.locationForm && locationFormik.errors.locationForm.website &&
                                                        locationFormik.errors.locationForm.website.toString()
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div style={{ padding: "0px 250px" }} className="tabRowSection">
                                        <Row>
                                            <Col>
                                                {/* <label className="lbl-text">Dealership Name</label> */}
                                                <span className="locationDetail">Location Details</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={7} xs={12} md={7} lg={7}>
                                                <TextField
                                                    id="locationForm.dealerLicenseNumber"
                                                    name="locationForm.dealerLicenseNumber"
                                                    label="Dealer License Number"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    value={locationFormik.values.locationForm.dealerLicenseNumber}
                                                    required={true}
                                                    onChange={locationFormik.handleChange}
                                                    onBlur={locationFormik.handleBlur}
                                                    errorMessage={
                                                        locationFormik.touched.locationForm && locationFormik.touched.locationForm.dealerLicenseNumber &&
                                                        locationFormik.errors.locationForm && locationFormik.errors.locationForm.dealerLicenseNumber &&
                                                        locationFormik.errors.locationForm.dealerLicenseNumber.toString()
                                                    }
                                                />
                                            </Col>
                                            <Col sm={4} xs={12} md={4} lg={4}>
                                                <div className="ms-TextField gs-business-input">
                                                    <div className="ms-TextField-wrapper">
                                                        <label className="lbl-text required-Label">Date of Issue</label>
                                                        <div className="ms-TextField-fieldGroup mask-FieldGroup guidedCalendar">
                                                            <Calendar
                                                                id="navigators"
                                                                style={{ marginBottom: 0 }}
                                                                showIcon
                                                                placeholder=""
                                                                value={locationIssueDate}
                                                                maxDate={new Date()}
                                                                //ariaLabelledBy="Select a date"
                                                                //onSelect={(e) => handleDateChange(e)}
                                                                onChange={(e) => handleDateChange(e.value)}
                                                                required={true}
                                                                onBlur={handleDateBlur}
                                                                monthNavigator
                                                                yearRange={yearRange}
                                                                yearNavigator
                                                            />
                                                        </div>
                                                        <span className="error-message">
                                                            {blurFlag && locationFormik.errors.locationForm && locationFormik.errors.locationForm.dealerLicenseDateOfIssue &&
                                                                locationFormik.errors.locationForm.dealerLicenseDateOfIssue.toString()}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={7} xs={12} md={7} lg={7}>
                                                <Dropdown
                                                    id="dealerType"
                                                    label="Dealership Type"
                                                    placeholder=""
                                                    className="gs-business-input"
                                                    options={masterDropdownValues.dealerTypeList}
                                                    selectedKey={locationFormik.values.locationForm.dealerType}
                                                    onChange={handleDropdownValue}
                                                    required={true}
                                                    errorMessage={
                                                        locationFormik.touched.locationForm && locationFormik.touched.locationForm.dealerType &&
                                                        locationFormik.errors.locationForm && locationFormik.errors.locationForm.dealerType &&
                                                        locationFormik.errors.locationForm.dealerType.toString()
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="rowPaddingBtwSpace">
                                            <Col>
                                                <div style={{ display: "flex" }}>
                                                    <label className="width-420 lbl-text choice-lbl labelArea check-box-label required-Label">Is your inventory new or used?</label>
                                                    <label className="firstInputEvt checkboxArea">
                                                        <Checkbox
                                                            label="New"
                                                            id="new"
                                                            onChange={onChangeCheckBox}
                                                            checked={locationFormik.values.locationForm.inventoryCondition && locationFormik.values.locationForm.inventoryCondition.includes("new")}
                                                        />
                                                    </label>

                                                    <label className="firstInputEvt checkboxArea">
                                                        <Checkbox
                                                            label="Used"
                                                            id="used"
                                                            onChange={onChangeCheckBox}
                                                            checked={locationFormik.values.locationForm.inventoryCondition && locationFormik.values.locationForm.inventoryCondition.includes("used")}
                                                        />
                                                    </label>
                                                </div>
                                                {/*<span style={{ paddingLeft: "15px" }} className="error-message">{
                                                            locationFormik.touched.locationForm.inventoryCondition && locationFormik.errors.locationForm.inventoryCondition
                                                                ? locationFormik.errors.locationForm.inventoryCondition.toString()
                                                                : null
                                                        }</span>*/}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ChoiceGroup
                                                    id="isMaintenanceFacilityAvailable"
                                                    className="guidedRadio choice-lbl"
                                                    name="isMaintenanceFacilityAvailable"
                                                    defaultSelectedKey="true"
                                                    options={options}
                                                    required={true}
                                                    selectedKey={locationFormik.values.locationForm.isMaintenanceFacilityAvailable && locationFormik.values.locationForm.isMaintenanceFacilityAvailable.toString()}
                                                    onChange={handleDropdownRadioChange}
                                                    label="Does this dealership have a service department or repair faciliity?" />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <Row>
                                    <Col style={{ textAlign: "right" }}>
                                        <PrimaryButton
                                            onClick={addNewDealerShip}
                                            text="Add Location"
                                            disabled={!locationFormik.isValid || !locationFormik.dirty}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="footerBtn">
                                <hr />
                                <Row>
                                    <Col>
                                        <PrimaryButton
                                            // onClick={previousStep}
                                            onClick={() => previousStep(1)}
                                            text="Go Back"
                                            iconProps={{ iconName: "Back" }}
                                        />
                                    </Col>

                                    <Col>
                                        <PrimaryButton
                                            style={{ float: "right" }}
                                            // onClick={() => nextStep(2)}
                                            disabled={!locationFormik.isValid || !locationFormik.dirty || !dealerChildFormValid}
                                            onClick={handlelocationFormSubmit}
                                            text="Save & Continue"
                                            iconProps={{ iconName: "Forward" }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <div>
                                <h2 className="tab-inst">Finally, tell us a little more about you...</h2>
                                {
                                    commonServerError.screen === 'contact' ?

                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>{commonServerError.error}</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                            </div>
                            <div style={{ padding: "0px 250px" }}>
                                {/*<div className="tabRowSection">
                                    <Row>
                                        <Col>
                                            <PrimaryButton
                                                style={{ marginTop: "25px" }}
                                                onClick={useMyInformationContact}
                                                text="Use My Information"
                                                type="submit"
                                            // iconProps={{ iconName: "Add" }}
                                            />
                                        </Col>
                                    </Row>
                                </div>*/}

                                <div className="tabRowSection">
                                    {/*<Row className="emptySection"><span></span></Row>
                                    <Row>
                                        <Col sm={6} xs={12} md={6} lg={6}>
                                            <div className="ms-TextField root-155">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text required-Label">Phone</label>
                                                    <div className={contactFormik.touched.contactForm && contactFormik.touched.contactForm.workPhone &&
                                                        contactFormik.errors.contactForm && contactFormik.errors.contactForm.workPhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                        <InputMask
                                                            autoClear={false}
                                                            required={true}
                                                            id="workPhone"
                                                            name="contactForm.workPhone"
                                                            mask="(999)999-9999"
                                                            placeholder=""
                                                            value={contactFormik.values.contactForm.workPhone}
                                                            className="mask-TextField"
                                                            onChange={(e) => handleMaskInputContact(e)}
                                                            onBlur={contactFormik.handleBlur}>
                                                        </InputMask>
                                                    </div>
                                                    <span className="error-message">{
                                                        contactFormik.touched.contactForm && contactFormik.touched.contactForm.workPhone &&
                                                        contactFormik.errors.contactForm && contactFormik.errors.contactForm.workPhone &&
                                                        contactFormik.errors.contactForm.workPhone.toString()
                                                    }</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={6} xs={12} md={6} lg={6}>
                                            <div className="ms-TextField root-155">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">Alt Phone</label>
                                                    <div className={contactFormik.touched.contactForm && contactFormik.touched.contactForm.alternatePhone &&
                                                        contactFormik.errors.contactForm && contactFormik.errors.contactForm.alternatePhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "mask-FieldGroup ms-TextField-fieldGroup"}>
                                                        <InputMask
                                                            autoClear={false}
                                                            id="alternatePhone"
                                                            name="contactForm.alternatePhone"
                                                            mask="(999)999-9999"
                                                            placeholder=""
                                                            value={contactFormik.values.contactForm.alternatePhone}
                                                            className="mask-TextField"
                                                            onChange={(e) => handleMaskInputContact(e)}
                                                            onBlur={contactFormik.handleBlur}>
                                                        </InputMask>
                                                    </div>
                                                    <span className="error-message">{
                                                        contactFormik.touched.contactForm && contactFormik.touched.contactForm.alternatePhone &&
                                                        contactFormik.errors.contactForm && contactFormik.errors.contactForm.alternatePhone &&
                                                        contactFormik.errors.contactForm.alternatePhone.toString()
                                                    }</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>*/}
                                    <Row className="emptySection"><span></span></Row>
                                    {
                                        aboutPageError.field === 'roles' ?
                                            <div className="">
                                                <Row>
                                                    <Col>
                                                        <span className="genericErrorColor"><span>{aboutPageError.errMsg}</span></span>
                                                    </Col>
                                                </Row>
                                            </div> : null
                                    }
                                    <Row>
                                        <label className="lbl-text labelArea inventoryLabel required-Label">What role(s) does this user play?  Select all that apply</label>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="Owner"
                                                    id="owner"
                                                    onChange={onChangeRoleCheckBox}
                                                    checked={contactFormik.values.contactForm.roles.includes("owner")}
                                                />
                                            </label>
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="General Manager"
                                                    id="general-manager"
                                                    onChange={onChangeRoleCheckBox}
                                                    checked={contactFormik.values.contactForm.roles.includes("general-manager")}
                                                />
                                            </label>
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="Office Manager"
                                                    id="office-manager"
                                                    onChange={onChangeRoleCheckBox}
                                                    checked={contactFormik.values.contactForm.roles.includes("office-manager")}
                                                />
                                            </label>
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="Sales"
                                                    id="sales"
                                                    onChange={onChangeRoleCheckBox}
                                                    checked={contactFormik.values.contactForm.roles.includes("sales")}
                                                />
                                            </label>
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="F&I"
                                                    id="f-and-i"
                                                    onChange={onChangeRoleCheckBox}
                                                    checked={contactFormik.values.contactForm.roles.includes("f-and-i")}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="tabRowSection">
                                    <Row>
                                        <Col>
                                            <div className="requestAccess">
                                                <div>This account login (email address and password) automatically has full administration permission for this QoreDMS account.</div>

                                                <div>This includes activities like adding and/or removing product subscriptions, creating and managing additional user accounts, and managing users’ permissions.</div>

                                                {/* <div>You will be shown how to add additional user accounts next.</div> */}
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="readAndAccept">
                                            {
                                                aboutPageError.field === 'terms' ?
                                                    <div className="">
                                                        <Row>
                                                            <Col>
                                                                <span className="genericErrorColor"><span>{aboutPageError.errMsg}</span></span>
                                                            </Col>
                                                        </Row>
                                                    </div> : null
                                            }
                                            <label className="firstInputEvt checkboxArea">
                                                <Checkbox
                                                    label="I have read and understand the information above."
                                                    // indeterminate={isIndeterminateInvoice}
                                                    checked={aggrement}
                                                    onChange={() => setAggrement(!aggrement)}
                                                />
                                            </label>
                                        </Col>

                                    </Row>
                                </div>

                            </div>
                            <div className="footerBtn">
                                <hr />
                                <Row>
                                    <Col>
                                        <PrimaryButton
                                            // onClick={previousStep}
                                            onClick={() => previousStep(2)}
                                            text="Go Back"
                                            iconProps={{ iconName: "Back" }}
                                        />
                                    </Col>

                                    <Col>
                                        <PrimaryButton
                                            style={{ float: "right" }}
                                            onClick={handleContactFormSubmit}
                                            text="Finish"
                                            //disabled={!aggrement || !contactFormik.isValid || !contactFormik.dirty}
                                            iconProps={{ iconName: "Send" }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </TabPanel>

                    </SwipeableViews>
                    <WarningDialog
                        title="Warning"
                        isOpen={isWarningModalOn}
                        onDismiss={() => setIsWarningModalOn(false)}
                        description={warningContent}
                        loading={false}
                    />
                </Box> : null}
        </Stack>
    );
};
