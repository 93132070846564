import React, { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
    IStackStyles,
    Link,
    //Dropdown,
    PrimaryButton,
    Stack
} from "@fluentui/react";
//import { useNavigate  } from "react-router-dom";
import {
    useDispatch,
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { handleGetLocations } from "store/locations/locations.action";
import {
    //getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
//import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
//import { Dropdown } from "@fluentui/react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import {
    HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { handleGetFinancePersonMonthEndReports } from "store/dealTracker/dealTracker.action";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
//import XLSX from "xlsx";
import { Dropdown as PRDropdown } from 'primereact/dropdown';

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
//const credentialModuleName = "Location";
export const FinancePersonMonthEndReports = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
    const [yearFilter, setYearFilter] = React.useState(currentYear);
    const [isPrimaryLocation, setIsPrimaryLocation] = React.useState(null);
    const [locationFilter, setLocationFilter] = React.useState(isPrimaryLocation);
    const [isTotalFinalData, setIsTotalFinalData] = useState([]);
    //const [tables,setTables] = useState([])
    const [tab, setTab] = useState([])
    const [productList, setProductList] = useState(null);
    const [productRes, setProductRes] = useState(null);
    const [totalRes, setTotalRes] = useState(null);
    const [financePersonList, setFinancePersonList] = React.useState([]);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('closedUnits');
    const ExcelJS = require('exceljs');
    const { saveAs } = require('file-saver');

    // const workbook = new ExcelJS.Workbook();

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    //  const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
    //    return { key: item, text: item };
    //  });

    useEffect(() => {
        let person = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName), primaryLoc: (item?.primaryLocation?.id) })
            });
            setFinancePersonList(person);
        }
    }, [employees])

    useEffect(() => {
        handlePopulateFinanceProductReports(productRes);
        handlePopulateTotalsReports(totalRes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financePersonList, productRes, totalRes]);

    const handleMonthDropdownChange = (e) => {

        setStartMonthFilter(e.value)
        handleFilters(e.value, yearFilter, locationFilter);
    };

    const handleYearDropdownChange = (e) => {

        setYearFilter(e.value)
        handleFilters(startMonthFilter, e.value, locationFilter);
    };

    const handleLocationDropdownChange = (e) => {

        setLocationFilter(e.value)
        handleFilters(startMonthFilter, yearFilter, e.value);
    };


    const handleFilters = (startMonthFilter, yearFilter, locationFilter) => {
        if (startMonthFilter > 0 || yearFilter > 0 || locationFilter > 0) {

            const formData = {
                month: startMonthFilter,
                year: yearFilter,
                financePersonPrimaryLocationId: locationFilter ? locationFilter : "",
            };
            dispatch<any>(handleGetFinancePersonMonthEndReports({

                formData,
                callback: (response) => {
                    console.log("resresres", response)
                    if (response) {
                        setTotalRes(response.totals)
                        setProductRes(response.financeProducts)

                    } else {
                        setIsTotalFinalData(null)

                    }

                }
            }));

        }
    }

    const handlePopulateTotalsReports = (response) => {

        if (response && response.length) {
            let monthEndReports = []

            let result = [...financePersonList]

            if (locationFilter) {
                let arr = financePersonList?.filter(x => {
                    return x.primaryLoc === locationFilter
                })

                result = [...arr]
            }

            result?.forEach(function (item, index) {

                let Obj = {
                    financePerson: item.text,
                }

                response?.forEach(function (val, index) {

                    const data = response?.find(x => { return x.financePersonId === item.key });


                    Obj["closedUnits"] = data ? Number(data.closedUnits.toFixed(1)) : 0.0;
                    Obj["allUnits"] = data ? Number(data.allUnits) : Number('');
                    Obj["split1"] = data ? Number(data.split1) : 0.0;
                    Obj["split2"] = data ? Number(data.split2) : 0.0;
                    Obj["totalSales"] = data ? Number(data.total) : 0.0;


                });

                monthEndReports.push(Obj);
            });

            setIsTotalFinalData(monthEndReports);
        } else {
            setIsTotalFinalData([])
        }
    };



    //const handlePopulateFinanceProductReports = (response) => {
    //
    //    if (response && response.length) {
    //        let tbls = [];
    //        let name=[];
    //        response.forEach(function (response, index) {
    //              name.push( response.name)
    //              let val= [...response.financePersons] 
    //              let monthEndReports = []
    //              val.forEach(function (val, index) {
    //                let Obj = {
    //                    financePerson:  val.financePersonFirstName + ' '+ val.financePersonLastName,
    //                    closedUnits :  val.closedUnits,
    //                    split1 :  val.split1 ,
    //                    split2 :  val.split2,
    //                    totalSales :  val.total
    //                }
    //                monthEndReports.push(Obj);
    //              });             
    //              tbls.push(monthEndReports);    
    //        });
    //        setTables(tbls);
    //        setTab(name);
    //        
    //    } 
    //    else{
    //        setTables([])
    //    }
    //  };


    const handlePopulateFinanceProductReports = (response) => {

        if (response && response.length) {
            let tbls = [];
            let name = [];

            response.sort(function (x, y) { return x.name?.toLowerCase() === 'reserve' ? -1 : y.name?.toLowerCase() === 'reserve' ? 1 : 0; });

            response?.forEach(function (response, index) {
                name.push(response.name)
                let productData = []

                let result = [...financePersonList];

                if (locationFilter) {
                    let arr = financePersonList?.filter(x => {
                        return x.primaryLoc === locationFilter
                    })

                    result = [...arr]
                }

                result?.forEach(function (item, index) {

                    let Obj = {
                        financePerson: item.text,
                    }

                    response.financePersons?.forEach(function (val, index) {

                        const data = response.financePersons.find(x => { return x.financePersonId === item.key });

                        Obj["closedUnits"] = data ? Number(data.closedUnits.toFixed(1)) : 0.0;
                        Obj["split1"] = data ? Number(data.split1) : 0.0;
                        Obj["split2"] = data ? Number(data.split2) : 0.0;
                        Obj["totalSales"] = data ? Number(data.total) : 0.0;
                        Obj["financeProductName"] = data ? data.financeProductName : "";

                    });


                    productData.push(Obj);
                });

                tbls.push(productData);

            });
            setProductList(tbls);
            setTab(name)
        }
        else {
            setProductList([])
        }

    };


    useEffect(() => {

        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "financePerson" } })

        dispatch<any>(handleGetLocations());

        handleFilters(startMonthFilter, yearFilter, locationFilter)
        setSortOrder(-1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setIsPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let list = [];
        if (locations.results.length > 0) {
            locations.results.map((item: any) => {
                return list.push({ key: item.id, text: (item.legalName) })
            });
            list.unshift({ key: 0, text: 'All' });
            setCityDealerList(list);
        }
    }, [locations])

    //    const exportExcel = () => {
    //        if(isTotalFinalData){
    //            let flatData = tables.reduce((acc, curVal) => { 
    //               return acc.concat(curVal)
    //            }, []);
    //
    //            var wb = XLSX.utils.book_new(),
    //
    //            ws=XLSX.utils.sheet_add_json(ws, isTotalFinalData, {
    //              origin: 2
    //            });
    //            XLSX.utils.sheet_add_json(ws, [{}], {
    //              header: ["finance product"],
    //              origin: 'H2'
    //            });
    //            XLSX.utils.sheet_add_json(ws, flatData, { origin: 'H3'});
    //            XLSX.utils.book_append_sheet(wb,ws,"Mysheet1");
    //            XLSX.writeFile(wb,"MyExcel.xlsx");
    //        }
    //}

    // function getSheetData(data, header) {

    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName] === "" ? "" : row[fieldName]

    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    const exportExcel = () => {
        if (isTotalFinalData && isTotalFinalData.length) {
            let firstTableData = [...isTotalFinalData];
            let header = ["Finance Person", "Closed Units", "All Units", "Split 1", "Split 2", "Total Sales"];

            let arr = []

            const add = (val) => {
                let data = arr.slice(-1)

                let value = Number(data) + val

                return parseInt(value)
            }

            if (firstTableData) {
                firstTableData.sort(descOrdeByCar);
                const exportData = firstTableData.map((item) =>
                ({
                    "financePerson": item.financePerson,
                    "closedUnits": item.closedUnits,
                    "allUnits": item.allUnits ? item.allUnits : "",
                    "Split1": item.split1,
                    "Split2": item.split2,
                    "totalSales": item.totalSales,
                }));

                arr.push(exportData.length + 3)
                arr.push(exportData.length + 4)

                if (productList && productList.length) {
                    productList.forEach(function (item, index) {
                        let d = add(item.length);
                        arr.push(d + 2)
                        arr.push(d + 3)

                        exportData.push({ "financePerson": '', "closedUnits": '', "allUnits": '', "Split1": '', "Split2": '', "totalSales": '' });
                        exportData.push({ "financePerson": tab[index], "closedUnits": '', "allUnits": '', "Split1": '', "Split2": '', "totalSales": '' });
                        exportData.push({ "financePerson": 'Finance Person', "closedUnits": 'Closed Units', "allUnits": 'All units', "Split1": 'Split 1', "Split2": 'Split 2', "totalSales": 'Total Sales' });
                        item.sort(descOrdeByPenetration);
                        const dynamicData = item.map((detail) =>
                        ({
                            "financePerson": detail.financePerson,
                            "closedUnits": detail.closedUnits,
                            "allUnits": "",
                            "Split1": detail.split1,
                            "Split2": detail.split2,
                            "totalSales": detail.totalSales,
                        }));
                        exportData.push(...dynamicData);
                    });
                }

                exportData.push({ "financePerson": '', "closedUnits": '', "allUnits": '', "Split1": '', "Split2": '', "totalSales": '' });

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Finance Person Month End Reports');

                // Add header row
                worksheet.addRow(header);

                // Add data rows
                exportData.forEach((row) => {
                    worksheet.addRow([row.financePerson, row.closedUnits, row.allUnits, row.Split1, row.Split2, row.totalSales]);
                });

                // Generate buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'Finance Person Month End Reports.xlsx');
                });


            }
        }
    }

    //const saveAsExcelFile = (buffer, fileName) => {
    //    import('file-saver').then(module => {
    //        if (module && module.default) {
    //            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //            let EXCEL_EXTENSION = '.xlsx';
    //            const data = new Blob([buffer], {
    //                type: EXCEL_TYPE
    //            });
    //            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //        }
    //    });
    //}

    function descOrdeByPenetration(a, b) {
        if (a.penetration > b.penetration) {
            return -1;
        }
        if (a.penetration < b.penetration) {
            return 1;
        }
        return 0;
    }

    function descOrdeByCar(a, b) {
        if (a.perCar > b.perCar) {
            return -1;
        }
        if (a.perCar < b.perCar) {
            return 1;
        }
        return 0;
    }


    const handleResetWorkbook = () => {
        ///let primarylocation=[... isPrimaryLocation ]
        setStartMonthFilter(currentMonth);
        setYearFilter(currentYear);
        setLocationFilter(isPrimaryLocation)
        handleFilters(currentMonth, currentYear, isPrimaryLocation)
    };

    const header = (
        <div >
            <div className=" table-header " style={{ marginBottom: "10px", display: "block" }} >
                <Row  >
                    <Col md={12}  >
                        <Row  >
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="location"
                                         label="Location"
                                         selectedKey={locationFilter}
                                         options={cityDealerList}
                                         onChange={handleLocationDropdownChange}
                                         placeholder=""
           
                                     />*/}
                                <label className="lbl-text">Location</label>
                                <PRDropdown
                                    id="location"
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace"  >
                                {/*<Dropdown
                                         id="month"
                                         label="Month"
                                         selectedKey={startMonthFilter}
                                         options={masterDropdownValues.getMonth}
                                         onChange={handleMonthDropdownChange}
                                         placeholder=""
                                         className=" dropDownBorderColor" 
                                     />*/}
                                <label className="lbl-text">Month</label>
                                <PRDropdown
                                    id="month"
                                    value={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="year"
                                         label="Year"
                                         //required={true}
                                         selectedKey={yearFilter}
                                         //value={yearFilter}
                                         options={yearValues}
                                         onChange={handleYearDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">Year</label>
                                <PRDropdown
                                    id="year"
                                    value={yearFilter}
                                    options={masterDropdownValues.from2022years}
                                    onChange={handleYearDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>

                            <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                <PrimaryButton onClick={handleResetWorkbook} >
                                    Reset
                                </PrimaryButton>
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </div>
            <div style={{ float: "right", marginRight: "-15px", fontSize: "16px", marginTop: "-15px" }}>
                <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                    Export to Excel
                </Link>
            </div>
        </div>
    );

    const handleEditLocation = (id) => {


    };

    const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                handleEditLocation(data.value[0].rowData.id);
            }
        }
    };


    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person</span>
                {rowData.financePerson}
            </React.Fragment>
        );
    };
    const closedUnitsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Year</span>
                {rowData.closedUnits}
            </React.Fragment>
        );
    };

    const allUnitsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Month</span>
                {rowData.allUnits ? rowData.allUnits : ""}
            </React.Fragment>
        );
    };

    const splitFirstBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Split 1</span>
                {currencyNegValFractionalFormat(rowData.split1)}
            </React.Fragment>
        );
    };
    const splitSecondBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Split 2</span>
                {currencyNegValFractionalFormat(rowData.split2)}
            </React.Fragment>
        );
    };
    const totalSalesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total Sales</span>
                {currencyNegValFractionalFormat(rowData.totalSales)}
            </React.Fragment>
        );
    };



    return (

        isKarlProfileType ? <>
            <div style={{ minHeight: "500px" }} className="reportFinanceProductWidth">
                <Stack styles={container} tokens={{ childrenGap: 10 }}>

                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Finance Person Month End</CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    <div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                        <div >
                            <DataTable
                                value={isTotalFinalData}
                                header={header}
                                className=" p-datatable-customers "
                                paginator={false}
                                rows={100}
                                sortField={sortField}
                                sortOrder={sortOrder}
                                onSort={handleColumnSort}
                                //cellSelection
                                onSelectionChange={e => captureCellSelection(e)}>
                                <Column field="financePerson" header="Finance Person" body={financePersonBodyTemplate} sortable />
                                <Column field="closedUnits" header="Closed Units" body={closedUnitsBodyTemplate} sortable />
                                <Column field="allUnits" header="All units" body={allUnitsBodyTemplate} sortable />
                                <Column field="split1" header="Split 1" body={splitFirstBodyTemplate} sortable />
                                <Column field="split2" header="Split 2" body={splitSecondBodyTemplate} sortable />
                                <Column field="totalSales" header="Total Sales" body={totalSalesBodyTemplate} sortable />
                            </DataTable>
                        </div>
                    </div>
                    <div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">



                        {productList && productList.map((item, index) => (

                            <Row key={"boxes-" + index}>
                                <Col id={"delivery-section" + index}>
                                    <div  >
                                        <div className="monthEndProductTitle"> {tab[index]}</div>
                                        <ProductTable tableData={item} />
                                    </div>
                                </Col>
                            </Row>

                        ))}
                    </div>
                </Stack>
            </div>
        </> : null
    );
};

interface IProps {
    tableData: any;
}

export const ProductTable: React.FunctionComponent<IProps> = ({ tableData }) => {
    useEffect(() => {
        setSortOrder(-1);

    }, []);

    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('closedUnits');

    const handleColumnSort = (e) => {

        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const split1BodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total Sales</span>
                {currencyNegValFractionalFormat(rowData.split1)}
            </React.Fragment>
        );
    };

    const split2BodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total Sales</span>
                {currencyNegValFractionalFormat(rowData.split2)}
            </React.Fragment>
        );
    };

    const salesTotalBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Total Sales</span>
                {currencyNegValFractionalFormat(rowData.totalSales)}
            </React.Fragment>
        );
    };

    return (
        <>
            <DataTable
                value={tableData}
                className=" p-datatable-customers "
                paginator={false}
                rows={100}
                //cellSelection
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={handleColumnSort}>
                <Column field="financePerson" header="Finance Person" sortable />
                <Column field="closedUnits" header="Closed Units" sortable />
                <Column />
                <Column field="split1" header="Split 1" body={split1BodyTemplate} sortable />
                <Column field="split2" header="Split 2" body={split2BodyTemplate} sortable />
                <Column field="totalSales" header="Total Sales" body={salesTotalBodyTemplate} sortable />
            </DataTable>
        </>
    );
};
