import { initializeIcons } from "@uifabric/icons";
import 'assets/fonts/century-gothic-font/CenturyGothic.ttf';
import React from "react";
import 'react-big-scheduler/lib/css/style.css';
import ReactDOM from 'react-dom/client';
import '../node_modules/@fluentui/react/dist/css/fabric.css';
import '../node_modules/antd/dist/antd.min.css';
//import 'antd/dist/antd.min.css' 
import '../node_modules/react-big-scheduler/lib/css/style.css';
import App from "./App";
// import '../node_modules/react-big-calendar/lib/sass/styles.scss';
import './index.css';
import './scheduler.css';
// import 'react-notifications/lib/notifications.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from "reportWebVitals";
import store from "./store";

initializeIcons();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

