import { put, takeLeading } from "redux-saga/effects";
import {
  pendingGetTemplates,
  resolvedGetTemplates,
  rejectedGetTemplates,
  pendingGetWebsite,
  pendingUpdateWebsite,
  resolvedGetWebsite,
  rejectedGetWebsite,
  rejectedUpdateWebsite,
} from "store/website/website.action";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import {
  getTemplatesApi,
  getWebsiteApi,
  updateWebsiteApi,
  uploadLogoApi,
} from "store/website/website.api";
import {
  HANDLE_GET_TEMPLATES,
  HANDLE_GET_WEBSITE,
  HANDLE_UPDATE_WEBSITE,
  HandleUpdateWebsite,
} from "store/website/website.types";

export function* getTemplatesSaga() {
  yield put(pendingGetTemplates());
  yield put(switchGlobalLoader(true));

  const { error, data } = yield getTemplatesApi();
  if (error) {
    yield put(switchGlobalLoader(false))
    yield put(rejectedGetTemplates(error));
    return;
  }
  yield put(switchGlobalLoader(false))
  yield put(resolvedGetTemplates(data));
}

function* getWebsiteSaga() {
  yield put(pendingGetWebsite());

  const { error, data } = yield getWebsiteApi();

  if (error) {
    yield put(rejectedGetWebsite(error));
    return;
  }

  yield put(resolvedGetWebsite(data));
}

function* updateWebsiteSaga(action: HandleUpdateWebsite) {
  yield put(pendingUpdateWebsite());

  let logo;
  if (!action.payload.website.logo) {
    delete action.payload.website.logo;
  } else {
    logo = action.payload.website.logo
  }

  console.log("payload w update website saga: ", action.payload);

  
  const { data, error } = yield updateWebsiteApi(action.payload.website);
  
  if (error) {
    yield put(rejectedUpdateWebsite(error));
    return;
  }



  if (typeof logo === 'object') {
    const formData = new FormData();
    formData.append("logo", logo, "test.jpeg");

    const {  error } = yield uploadLogoApi(formData);

    
    
    if(error){

      return;
    }

  }

  action.payload.callback();
  yield put(rejectedUpdateWebsite(data));
}

export default function* WebsiteSaga() {
  yield takeLeading(HANDLE_GET_TEMPLATES, getTemplatesSaga);
  yield takeLeading(HANDLE_GET_WEBSITE, getWebsiteSaga);
  yield takeLeading(HANDLE_UPDATE_WEBSITE, updateWebsiteSaga);
}
