import React, { useState, useEffect } from "react";
import {
  DefaultButton,
  //FontIcon,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import GoBack from "components/buttons/goBack/GoBack";
import Card from "components/card/Card";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "../dealSettingsPage/styles";
import { CalculationValuesPivot } from "pages/deskADealPage/pivot/CalculationValuesPivot";
import AddProspectToDealDialog from "components/dialogs/deals/AddProspectToDealDialog";
import { IProspect } from "types/prospectTypes";
import { EditProspectDialog } from "components/dialogs/prospects/EditProspectDialog";
import { EditInventoryDialog } from "components/dialogs/editInventoryDialog/EditInventoryDialog";
import AddVehicleToDealDialog from "components/dialogs/deals/AddVehicleToDealDialog";
import { IInventory } from "types/inventoryTypes";
import OpenSavedDeskADealDialog from "pages/deskADealPage/dialogs/OpenSavedDeskADealDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCreateDeskADeal,
  handleUpdateDeskADeal,
} from "store/deskADeal/deskADeal.action";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IDeskADeal } from "types/deskADealTypes";
import {
  stringFromCurrencyFormat,
  stringToCurrenyFormatForEditInputs,
} from "utils/helperFunctions";
import { handleGetDealSettings } from "store/deals/deals.action";
import { handleDeleteDeskADeal } from "store/deskADeal/deskADeal.action";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import Summary from "./Summary";
import RateAndTermOptions from "./RateAndTermOptions";

const DeskADealPage = () => {
  const dispatch = useDispatch();

  const [selection, setSelection] = useState([]);
  const [showOpenSavedDeskADealDialog, setOpenSavedDeskADealDialog] = useState(
    false
  );
  const [showAddProspectDialog, setShowAddProspectDialog] = useState(false);
  const [showEditProspectDialog, setShowEditProspectDialog] = useState(false);
  const [showAddInventoryDialog, setShowAddInventoryDialog] = useState(false);
  const [showEditInventoryDialog, setShowEditInventoryDialog] = useState(false);

  const [selectedProspect, setSelectedProspect] = useState(null);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [selectedDeskADeal, setSelectedDeskADeal] = useState(null);
  const [total, setTotal] = useState<number | null>(null);
  const [totalTax, setTotalTax] = useState<number | null>(null);
  const [grandTotal, setGrandTotal] = useState<number | null>(null);

  const [productTaxRate, setProductTaxRate] = useState(null);
  const [productTaxTotal, setProductTaxTotal] = useState<number | null>(null);
  const [vehicleTaxRate, setVehicleTaxRate] = useState<number | null>(null);
  const [vehicleTaxTotal, setVehicleTaxTotal] = useState<number | null>(null);

  const [monthlyPaymentVariants, setMonthlyPaymentVariants] = useState([
    { key: 0, value: 0 },
    { key: 1, value: 0 },
    { key: 2, value: 0 },
  ]);

  const dealSettings = useSelector(
    (state: AppState) => state.deals.dealSettings
  );

  const loading = useSelector((state: AppState) => state.deskADeal.loading);
  const deleteLoading = useSelector(
    (state: AppState) => state.deskADeal.deleteLoading
  );

  const deskADeal = useSelector((state: AppState) => state.deskADeal.deskADeal);

  const formik = useFormik({
    initialValues: {
      name: "",
      prospectId: "",
      inventoryId: "",
      vehiclePrice: "",
      discounts: "",
      cashDownPayment: "",
      tradeInValue: "",
      payoff: "",
      docFee: "",
      titleFilingFee: "",
      registrationFee: "",
      otherFees: "",
      vehicleServiceContract: "",
      GAPFee: "",
      tireAndWheel: "",
      otherProducts: "",
      rate: "",
      term: "",
      variants: [
        { key: 0, term: "12", rate: "3" },
        { key: 1, term: "24", rate: "3" },
        { key: 2, term: "36", rate: "3" },
      ],
      lienFee: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      prospectId: Yup.string().required("Required"),
      inventoryId: Yup.string().required("Required"),
      vehiclePrice: Yup.string(),
      discounts: Yup.string(),
      cashDownPayment: Yup.string(),
      tradeInValue: Yup.string(),
      payoff: Yup.string(),
      docFee: Yup.string(),
      titleFilingFee: Yup.string(),
      registrationFee: Yup.string(),
      otherFees: Yup.string(),
      lienFee: Yup.string(),
      vehicleServiceContract: Yup.string(),
      GAPFee: Yup.string(),
      tireAndWheel: Yup.string(),
      otherProducts: Yup.string(),
      rate: Yup.string(),
      term: Yup.string(),
      variants: Yup.object().nullable(),
    }),

    onSubmit: (values) => {
      // const valuesToSend = {
      //   ...values,
      // };
      //
      // delete valuesToSend.name;

      const deskADeal: IDeskADeal = {
        name: formik.values.name,
        options: {
          json: { ...values },
        },
      };

      if (selectedDeskADeal !== null) {
        dispatch<any>(
          handleUpdateDeskADeal(
            deskADeal,
            selectedDeskADeal.id,
            formik.values.prospectId,
            formik.values.inventoryId
          )
        );
      } else {
        dispatch<any>(
          handleCreateDeskADeal(
            deskADeal,
            formik.values.prospectId,
            formik.values.inventoryId
          )
        );
      }
    },
  });

  useEffect(() => {
    dispatch<any>(handleGetDealSettings());
  }, [dispatch]);

  useEffect(() => {
    if (selection.length === 0) {
      setMonthlyPaymentVariants((variants) => {
        return variants.map((variant) => {
          variant.value = 0;

          return variant;
        });
      });

      return;
    }

    selection.forEach((s) => {
      const contain = formik.values.variants.some((variant) => {
        return variant.key === s.key;
      });

      if (!contain) {
        setMonthlyPaymentVariants((variants) => {
          return variants.map((variant) => {
            variant.value = 0;

            return variant;
          });
        });
      }
    });
    handleCalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection, formik.values.variants]);

  useEffect(() => {
    if (selectedInventory) {
      formik.setFieldValue(
        "vehiclePrice",
        stringToCurrenyFormatForEditInputs(
          selectedInventory.sellingPrice.toString()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventory]);

  useEffect(() => {
    if (deskADeal) {
      setSelectedDeskADeal(deskADeal);
    }
  }, [deskADeal]);

  useEffect(() => {
    const _transformValue = (val) => {
      return Boolean(val) ? (val / 100).toString() : "";
    };

    if (Boolean(dealSettings)) {
      formik.setValues({
        ...formik.values,
        docFee: _transformValue(dealSettings?.docFee),
        titleFilingFee: _transformValue(dealSettings?.titleFee),
        registrationFee: _transformValue(dealSettings?.registrationFee),
        otherFees: _transformValue(dealSettings?.otherFee),
        lienFee: _transformValue(dealSettings?.lienFee),
      });

      setProductTaxRate(dealSettings?.contractStateTax);
      setVehicleTaxRate(dealSettings?.stateTax);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealSettings]);

  // useEffect(() => {
  //   if (selectedDeskADeal) {
  //     
  //     // formik.setFieldValue("vehiclePrice", selectedDeskADeal.sellingPrice);
  //   }
  // }, [selectedDeskADeal]);

  useEffect(() => {
    if (formik.values.vehiclePrice) {
      handleCalculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const updateProspect = (prospect: IProspect) => {
    setSelectedProspect(prospect);
    setShowAddProspectDialog(false);
    formik.setFieldValue("prospectId", prospect.id);
  };

  const updateInventory = (inventory: IInventory) => {
    setSelectedInventory(inventory);
    formik.setFieldValue("inventoryId", inventory.id);
  };

  const handleOnSaveClick = () => {
    formik.handleSubmit();
  };

  const handleClickOnDeleteDeskADeal = () => {
    dispatch<any>(
      handleDeleteDeskADeal(selectedDeskADeal.id, () => {
        setSelectedDeskADeal(null);
        setSelectedInventory(null);
        setSelectedProspect(null);
        setTotalTax(null);
        setTotal(null);
        setGrandTotal(null);
        // setMonthlyPayment(null);
        setSelectedDeskADeal(null);
        formik.resetForm();
      })
    );
  };

  const _formatValue = (value) => {
    return !isNaN(parseInt(stringFromCurrencyFormat(value)) / 100)
      ? parseInt(stringFromCurrencyFormat(value)) / 100
      : 0;
  };

  const handleCalculate = () => {
    setTotal(null);
    setTotalTax(null);
    setGrandTotal(null);

    const v = formik.values;

    const vehPrice = parseFloat(v.vehiclePrice);

    const discountsVal = _formatValue(v.discounts);
    const cashDownPay = _formatValue(v.cashDownPayment);
    const tradeInValue = _formatValue(v.tradeInValue);
    const payoff = _formatValue(v.payoff);
    const docFee = _formatValue(v.docFee);
    const titleFilingFee = _formatValue(v.titleFilingFee);
    const registrationFee = _formatValue(v.registrationFee);
    const otherFees = _formatValue(v.otherFees);
    const lienFee = _formatValue(v.lienFee);
    const vehicleServiceContract = _formatValue(v.vehicleServiceContract);
    const GAPFee = _formatValue(v.GAPFee);
    const tireAndWheel = _formatValue(v.tireAndWheel);
    const otherProducts = _formatValue(v.otherProducts);

    const totalAdjusted =
      vehPrice -
      (discountsVal + cashDownPay - (payoff + tradeInValue)) +
      (docFee +
        titleFilingFee +
        registrationFee +
        lienFee +
        otherFees +
        (vehicleServiceContract + GAPFee + tireAndWheel + otherProducts));

    const productTaxTotal = productTaxRate * vehicleServiceContract;

    const vehicleTax =
      (vehPrice - (discountsVal + cashDownPay) - (payoff + tradeInValue)) *
      vehicleTaxRate;

    const totalTaxValue = productTaxTotal + vehicleTax;

    setProductTaxTotal(productTaxTotal);
    setVehicleTaxTotal(vehicleTax);
    setTotalTax(totalTaxValue);
    setTotal(totalAdjusted * 100);
    setGrandTotal(totalTaxValue + totalAdjusted * 100);

    for (const s of selection) {
      if (s.key === 0) {
        calculateAndSetMonthlyPayment(s.key);
        continue;
      }
      if (s.key === 1) {
        calculateAndSetMonthlyPayment(s.key);
        continue;
      }
      if (s.key === 2) {
        calculateAndSetMonthlyPayment(s.key);
      }
    }

    function calculateAndSetMonthlyPayment(key: number) {
      const rate = v.variants[key]?.rate ?? "0";
      const term = v.variants[key]?.term ?? "0";

      const C23 = (totalTaxValue + totalAdjusted * 100) / 100;
      const C25 = parseFloat(rate) / 100;
      const C26 = parseFloat(term);
      const monthly = C23 * (C25 / 12 / (1 - Math.pow(1 + C25 / 12, -C26)));

      setMonthlyPaymentVariants((variants) => {
        return variants.map((variant) => {
          if (variant.key === key) {
            variant.value = monthly;
          }

          return variant;
        });
      });
    }
  };

  const handleClear = () => {
    setSelectedProspect(null);
    setSelectedInventory(null);
    setSelectedDeskADeal(null);
    setTotal(null);
    setTotalTax(null);
    setGrandTotal(null);
    // setMonthlyPayment(null);
    formik.resetForm();
  };

  const setRate = ({ rate, index }: { rate: string; index: number }) => {
    formik.setFieldValue(
      "variants",
      formik.values.variants.map((value, i) => {
        if (index === i) {
          value.rate = rate;
        }
        return value;
      })
    );
  };

  const setTerm = ({ term, index }: { term: string; index: number }) => {
    formik.setFieldValue(
      "variants",
      formik.values.variants.map((value, i) => {
        if (index === i) {
          value.term = term;
        }
        return value;
      })
    );
  };

  const renderDeleteSpinner = () => {
    return deleteLoading ? <Spinner size={SpinnerSize.medium} /> : null;
  };

  const renderSpinner = () => {
    return loading ? <Spinner size={SpinnerSize.medium} /> : null;
  };

  return (
    <Stack tokens={{ childrenGap: 30 }} style={{ marginTop: 10 }}>
      {showOpenSavedDeskADealDialog && (
        <OpenSavedDeskADealDialog
          setSelectedDeskADeal={setSelectedDeskADeal}
          handleCalculate={handleCalculate}
          setSelectedProspect={setSelectedProspect}
          setSelectedInventory={setSelectedInventory}
          formik={formik}
          onDismiss={() => setOpenSavedDeskADealDialog(false)}
        />
      )}
      {showAddProspectDialog && (
        <AddProspectToDealDialog
          label="Add Prospect to Desk a Deal"
          onDismiss={() => setShowAddProspectDialog(false)}
          // onDismiss={() => setStepForm(FormStep.None)}
          onProspectSelection={(prospect: IProspect) => {
            updateProspect(prospect);
          }}
          selectedProspect={selectedProspect ? selectedProspect : null}
          type={1}
        />
      )}
      {showEditProspectDialog && (
        <EditProspectDialog
          onDismiss={() => setShowEditProspectDialog(false)}
          prospect={selectedProspect ? selectedProspect : null}
          returnProspect={(prospect: IProspect) => updateProspect(prospect)}
        />
      )}
      {showAddInventoryDialog && (
        <AddVehicleToDealDialog
          label="Add Vehicle to Desk a Deal"
          onDismiss={() => setShowAddInventoryDialog(false)}
          onInventorySelection={(inventory: IInventory) => {
            setSelectedInventory(inventory);
            setSelectedDeskADeal(null);
            formik.setFieldValue("inventoryId", inventory.id);
            setShowAddInventoryDialog(false);
          }}
          selectedInventory={selectedInventory ? selectedInventory : null}
        />
      )}
      {showEditInventoryDialog && (
        <EditInventoryDialog
          onDismiss={() => setShowEditInventoryDialog(false)}
          selectedInventory={selectedInventory ? selectedInventory : null}
          returnInventory={(inventory: any) => updateInventory(inventory)}
        />
      )}
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        tokens={{ childrenGap: 30 }}
      >
        <GoBack label="Desk a Deal" />
        <PrimaryButton
          onClick={() => setOpenSavedDeskADealDialog(true)}
          text="Open Saved Desk a Deal"
          iconProps={{ iconName: "OpenFolderHorizontal" }}
        />
      </Stack>

      <Stack horizontal wrap tokens={{ childrenGap: 30 }}>
        <Card>
          <CustomFontText size={20} style={{ marginBottom: 20 }}>
            Prospect
          </CustomFontText>

          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
            {selectedProspect && (
              <div style={{ flex: 4 }}>
                <p className="labelDeskADeal">Assigned Prospect</p>
                <TextField
                  // styles={textFieldStyles}
                  id="assignedVehicle"
                  name="assignedVehicle"
                  // label="Assigned Prospect"
                  value={
                    selectedProspect
                      ? `${selectedProspect?.firstName} ${selectedProspect?.lastName}, ${selectedProspect?.email} ,${selectedProspect?.phone}`
                      : ""
                  }
                />
              </div>
            )}
            {selectedProspect && (
              <DefaultButton
                style={{ flex: 2 }}
                // disabled={!selectedInventory}
                iconProps={{ iconName: "RedEye" }}
                text="View Detail"
                onClick={() => setShowEditProspectDialog(true)}
              />
            )}
          </Stack>
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
            {selectedProspect ? (
              <>
                <DefaultButton
                  text="Clear"
                  onClick={() => {
                    setSelectedProspect(null);
                    formik.setFieldValue("prospectId", "");
                  }}
                />
                <PrimaryButton
                  style={{
                    marginTop: 10,
                  }}
                  iconProps={{ iconName: "ReminderPerson" }}
                  text={"Change Assign Prospect"}
                  onClick={() => setShowAddProspectDialog(true)}
                />
              </>
            ) : (
              <PrimaryButton
                text="Assign Prospect to Desk a Deal"
                style={{
                  marginTop: 10,
                }}
                iconProps={{ iconName: "ReminderPerson" }}
                onClick={() => setShowAddProspectDialog(true)}
              />
            )}
          </Stack>
        </Card>

        <Card>
          <CustomFontText size={20} style={{ marginBottom: 20 }}>
            Inventory
          </CustomFontText>

          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
            {selectedInventory && (
              <div style={{ flex: 4 }}>
                <p className="labelDeskADeal">Assigned Vehicle</p>
                <TextField
                  // styles={textFieldStyles}
                  id="assignedVehicle"
                  name="assignedVehicle"
                  // label="Assigned Prospect"
                  value={
                    selectedInventory
                      ? `${selectedInventory.make} ${selectedInventory.model}`
                      : ""
                  }
                />
              </div>
            )}
            {selectedInventory && (
              <DefaultButton
                style={{ flex: 2 }}
                disabled={!selectedInventory}
                iconProps={{ iconName: "RedEye" }}
                text="View Detail"
                onClick={() => setShowEditInventoryDialog(true)}
              />
            )}
          </Stack>
          <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
            {selectedInventory ? (
              <>
                <DefaultButton
                  text="Clear"
                  // onClick={() => {
                  //   setSelectedProspect(null);
                  //   formik.setFieldValue("prospectId", "");
                  // }}
                  onClick={() => {
                    setSelectedInventory(null);
                    formik.setFieldValue("inventoryId", "");
                  }}
                />
                <PrimaryButton
                  style={{
                    marginTop: 10,
                  }}
                  iconProps={{ iconName: "Car" }}
                  text={"Change Assigned Vehicle"}
                  onClick={() => setShowAddInventoryDialog(true)}
                />
              </>
            ) : (
              <PrimaryButton
                text="Assign Inventory to Desk a Deal"
                style={{
                  marginTop: 10,
                }}
                iconProps={{ iconName: "Car" }}
                onClick={() => setShowAddInventoryDialog(true)}
              />
            )}
          </Stack>
        </Card>

        <Card>
          <CustomFontText size={20} style={{ marginBottom: 25 }}>
            Options
          </CustomFontText>

          <div style={{ marginBottom: 10 }}>
            <TextField
              id="name"
              name="name"
              label="Desk a Deal Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
          </div>
          <Stack horizontal horizontalAlign={"space-between"}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              {selectedDeskADeal !== null && (
                <DefaultButton
                  onRenderIcon={renderDeleteSpinner}
                  onClick={handleClickOnDeleteDeskADeal}
                  text="Delete"
                />
              )}
              <DefaultButton onClick={handleClear} text="Clear" />
            </Stack>
            <PrimaryButton
              onRenderIcon={renderSpinner}
              disabled={!formik.isValid || !formik.dirty}
              onClick={handleOnSaveClick}
              text="Save Desk a Deal"
              iconProps={{ iconName: "ReminderPerson" }}
            />
          </Stack>
        </Card>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 30 }} wrap>
        <Card style={{ flex: 2, minWidth: 700, paddingRight: 130 }}>
          <Stack
            // styles={styles.headerStyles}
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <CustomFontText size={20}>Calculation Values</CustomFontText>
            {/*<FontIcon iconName="Edit" className={styles.iconStyles} />*/}
          </Stack>
          <CalculationValuesPivot
            formik={formik}
            calculateAndSetMonthlyPayment={() => {}}
          />
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={styles.buttonsTokens}
            styles={styles.buttonsStyles}
          >
            <PrimaryButton
              styles={{}}
              disabled={!formik.dirty || !formik.isValid}
              // onRenderIcon={renderSpinner}
              text="Save Changes"
              onClick={handleOnSaveClick}
            />
          </Stack>
        </Card>
        <Stack tokens={{ childrenGap: 30 }}>
          <RateAndTermOptions
            setSelection={setSelection}
            formik={formik}
            setTerm={setTerm}
            setRate={setRate}
          />
          <Summary
            formik={formik}
            total={total}
            grandTotal={grandTotal}
            totalTax={totalTax}
            monthlyPaymentVariants={monthlyPaymentVariants}
            selection={selection}
            productTaxRate={productTaxRate}
            productTaxTotal={productTaxTotal}
            vehicleTaxRate={vehicleTaxRate}
            vehicleTaxTotal={vehicleTaxTotal}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DeskADealPage;
