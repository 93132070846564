import React from "react";
import { useEffect, useState } from "react";
import GoBack from "components/buttons/goBack/GoBack";
import { formatPathname } from "utils/helperFunctions";
import {
  Stack,
  IStackStyles,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import { useLocation} from "react-router-dom";
import GroupsTable from "components/table/groups/GroupsTable";
// import {  useSelector } from "react-redux";
// import { AppState } from "store/index";
// import Loader from "components/loader/Loader";
// import { AddNewGroup } from "components/dialogs/addNewGroup/AddNewGroup";
import { eTypes, WithCredentials } from "hoc/withCredentials";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { permissionsModules } from "constants/constants";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { AddGroup} from "components/dialogs/configureGroup/AddGroup";

const container: IStackStyles = {
  root: {
    marginTop: 10,
  },
};

export const GroupsPage = () => {
  const [pathname, setPathname] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  // const dispatch = useDispatch();
  //   const rowsPerPage = useSelector(
  //     // (state: AppState) => state.groups.rowsPerPage
  //   );

  // const loading = useSelector((state: AppState) => state.groups.loading);
  // const loadingPermissions = useSelector((state: AppState) => state.groupsPermissions.loading);

  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  useEffect(() => {
    setIsOpen(Boolean(location?.state))
  },[location])

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleOnDismiss = () => {
    setIsOpen(false);
    // dispatch<any>(
    //   handleGetGroups({
    //     page: 1,
    //     limit: rowsPerPage,
    //   })
    // );
  };

  return (
    <>
      {/*<Loader show={loading || loadingPermissions} />*/}
      {/*<AddNewGroup isOpen={isOpen} onDismiss={() => handleOnDismiss()} />*/}
      {/*<ConfigureGroup*/}
      {/*    groupName={""}*/}
      {/*    isOpen={isOpen}*/}
      {/*    onDismiss={() => handleOnDismiss()}*/}
      {/*/>*/}


      <AddGroup
          noActions
        isOpen={isOpen}
        onDismiss={() => handleOnDismiss()}
      />
      <Stack styles={container} tokens={{ childrenGap: 10 }}>
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
            <GoBack label={formatPathname(pathname)} />

            <WithCredentials
              groupAuths={
                isUserMaster(user.roles)
                  ? []
                  : getModulePermissions(user.auths, permissionsModules.Auth)
              }
              alias={"Auth"}
              type={eTypes.WRITE}
              roles={user.roles}
            >
              <PrimaryButton
                onClick={() => setIsOpen(true)}
                text="Add new group"
                iconProps={{ iconName: "CircleAdditionSolid" }}
              />
            </WithCredentials>
          </Stack>
          <div>
            <SearchBox
              placeholder="Search"
              onSearch={(newValue) => console.log("value is: " + newValue)}
            />
          </div>
        </Stack>
        <GroupsTable />
      </Stack>
    </>
  );
};
