import * as React from "react";
import { useEffect, useState } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
//import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
//import { TextField } from "@fluentui/react/lib/TextField";
import {
    //DefaultButton,
    //FontIcon,
    //MaskedTextField,
    PrimaryButton,
    //ITextFieldProps,
    Link,
    Checkbox,
} from "@fluentui/react";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppState } from "store";
import * as Yup from "yup";
import {
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
} from "@fluentui/react/lib/Persona";
import { handleUpdateUserProfile } from "store/userProfile/userProfile.action";
import { handleUploadUserPhoto } from "store/userProfile/userProfile.action";
import { Col, Row } from "react-grid-system";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { validTenDigitPhone } from "utils/helperFunctions";

const stackTokens = { childrenGap: 7 };

const AccountSettings: React.FC = () => {
    const serverErrors = useSelector(
        (state: AppState) => state.auth.registerErrors
    );
    const loggedUserId = useSelector(
        (state: AppState) => state.auth.loggedUserId
    );

    const userProfile = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const examplePersona: IPersonaSharedProps = {
        imageUrl: userProfile.avatar? `${process.env.REACT_APP_IMAGE_SERVER}/${userProfile.avatar}` : "",
        imageInitials: userProfile?.firstName ? `${userProfile.firstName[0]} ${userProfile?.lastName ? userProfile.lastName[0] : ""}` : "",
        text: userProfile?.firstName ? `${userProfile.firstName} ${userProfile?.lastName ? userProfile.lastName : ""}` : "",
        secondaryText: userProfile.email ? userProfile.email : "",
        // tertiaryText: "dealer account",
    };

    let imgFileRef: any = React.createRef();
    const [uploadError, setUploadError] = useState("");
    const [commonServerError, setcommonServerError] = useState(null);
   
    const [persona, setPersona] = useState(examplePersona);


    // const loading = useSelector((state: AppState) => state.userProfile.loading);
    //const updateLoading = useSelector(
    //    (state: AppState) => state.userProfile.updateLoading
    //);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            nickName: "",
            email: "",
            workPhone: "",
            alternatePhone: "",
            workPhoneUsage: [],
            alternatePhoneUsage: [],
            textConsent: false,
            jobTitle: [],
            avatar: "",
            isActive: true
        },
        validationSchema: Yup.object({
            firstName: Yup.string(),
            lastName: Yup.string(),
            nickName: Yup.string(),
            email: Yup.string(),
            workPhone: Yup.string()
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Required"),
            alternatePhone: Yup.string()
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }),
            workPhoneUsage: Yup.array().nullable(),
            alternatePhoneUsage: Yup.array().nullable(),
            textConsent: Yup.boolean(),
            jobTitle: Yup.array().nullable(),//.min(1, "Required"),
            avatar: Yup.string().nullable(),
            isActive: Yup.boolean()
        }),
        onSubmit: (values) => {
            dispatch<any>(
                handleUpdateUserProfile(
                    loggedUserId,
                    {
                        //id: userProfile.id,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        nickName: values.nickName,
                        email: values.email,
                        jobTitle: values.jobTitle,
                        workPhone: values.workPhone.replace(/\D/g, '').slice(0, 10),
                        workPhoneUsage: values.workPhoneUsage,
                        alternatePhone: values.alternatePhone?.replace(/\D/g, '').slice(0, 10),
                        alternatePhoneUsage: values.alternatePhoneUsage,
                        textConsent: values.textConsent,
                        isActive: values.isActive
                    }
                )
            );
        },
    });
    useEffect(() => {
        if (userProfile) {
            formik.setValues({
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                nickName: userProfile.nickName ? userProfile.nickName : userProfile.firstName,
                email: userProfile.email,
                jobTitle: userProfile.jobTitle ? userProfile.jobTitle : [],
                workPhone: userProfile.workPhone,
                workPhoneUsage: userProfile.workPhoneUsage ? userProfile.workPhoneUsage : [],
                alternatePhone: userProfile.alternatePhone,
                alternatePhoneUsage: userProfile.alternatePhoneUsage ? userProfile.alternatePhoneUsage : [],
                textConsent: userProfile.textConsent,
                avatar: userProfile.avatar,
                isActive: userProfile.isActive
            }, true);

            examplePersona.imageUrl = userProfile.avatar
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${userProfile.avatar}` : "";
            examplePersona.imageInitials = userProfile?.firstName ? `${userProfile.firstName[0]} ${userProfile?.lastName ? userProfile.lastName[0] : ""}` : "";
            examplePersona.text = userProfile.firstName + ' ' + userProfile.lastName;
            examplePersona.secondaryText = userProfile.email;
            setPersona(examplePersona);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    //useEffect(() => { }, [formik.isValid, formik]);

    useEffect(() => {
        if (serverErrors !== null) {
            setcommonServerError(serverErrors);
        }
    }, [serverErrors]);

    const fileSelectionHandler = (e) => {

        e.preventDefault();
        setUploadError('');
        //setShowUnknownPersonaCoin(false);
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }

        const uploadedFile = e.target.files[0];

        const formData = new FormData();
        formData.append("avatar", uploadedFile, "test.jpeg");

        dispatch<any>(handleUploadUserPhoto(loggedUserId, formData));
    };

    const handleUpdateClick = async () => {
        formik.handleSubmit();
    };

    //const renderSpinner = () => {
    //    return updateLoading ? (
    //        <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    //    ) : null;
    //};

    const handleUploadLink = () => {
        imgFileRef.current.click();
    }

    const handleMaskInput = (e) => {

        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    //const handleCheckBoxChange = (e, isChecked) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            [e.target.id]: isChecked
    //        }
    //    );
    //};

    const handleRoleCheckBoxChange = (e, isChecked) => {
        if (isChecked) {
            if (!formik.values.jobTitle.includes(e.target.id)) {
                let arr = formik.values.jobTitle;
                arr.push(e.target.id);

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: arr
                    }
                );
            }
        } else {
            if (formik.values.jobTitle.includes(e.target.id)) {

                formik.setValues(
                    {
                        ...formik.values,
                        jobTitle: formik.values.jobTitle.filter(item => item !== e.target.id) as any,
                    }
                );
            }
        }
    };

    //const handleWorkPhoneCheckBoxChange = (e, isChecked) => {
    //    if (isChecked) {
    //        if (!formik.values.workPhoneUsage.includes(e.target.id)) {
    //            let arr = formik.values.workPhoneUsage;
    //            arr.push(e.target.id);

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    workPhoneUsage: arr
    //                }
    //            );
    //        }
    //    } else {
    //        if (formik.values.workPhoneUsage.includes(e.target.id)) {

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    workPhoneUsage: formik.values.workPhoneUsage.filter(item => item !== e.target.id) as any,
    //                }
    //            );
    //        }
    //    }
    //};

    //const handleAlternatePhoneCheckBoxChange = (e, isChecked) => {
    //    let usage = e.target.id.replace("alternatePhone-", "");
    //    if (isChecked) {
    //        if (!formik.values.alternatePhoneUsage.includes(usage)) {
    //            let arr = formik.values.alternatePhoneUsage;
    //            arr.push(usage);

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    alternatePhoneUsage: arr
    //                }
    //            );
    //        }
    //    } else {
    //        if (formik.values.alternatePhoneUsage.includes(usage)) {

    //            formik.setValues(
    //                {
    //                    ...formik.values,
    //                    alternatePhoneUsage: formik.values.alternatePhoneUsage.filter(item => item !== usage) as any,
    //                }
    //            );
    //        }
    //    }
    //};

    return (
        <>
            <Row style={{ marginTop: "15px" }}>
                <Col>
                    <Persona
                        {...persona}
                        styles={styles.personStyles}
                        size={PersonaSize.size100}
                        presence={PersonaPresence.none}
                        //showUnknownPersonaCoin={isShowUnknownPersonaCoin ? true : false}
                        imageAlt="Maor Sharett, status unknown"
                        className="persona-square"
                    />
                    <Link onClick={handleUploadLink}>Upload photo
                            <input
                            style={{
                                padding: 20,
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: 107,
                                height: 1,
                                overflow: "hidden",
                                opacity: 0,
                                marginLeft: -10,
                            }}
                            type="file"
                            ref={imgFileRef}
                            onChange={fileSelectionHandler}
                        /></Link>
                    {
                        uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                    }
                </Col>
                {/*<Col style={{ textAlign: "right" }}>
                    <Link onClick={handleActivateUser}>{formik.values.isActive ? "Inactivate User" : "Activate User"}</Link>
                </Col>*/}
            </Row>
            <hr />
            <Stack>
                {
                    commonServerError ?
                        <div>
                            <Row>
                                <Col>
                                    <p className="genericErrorColor"><span>{commonServerError}</span></p>
                                </Col>
                            </Row>
                        </div> : null
                }
                <Row>
                    <Col sm={6} xs={12} md={6} lg={6}>
						  <div className="ms-TextField root-155">
						  	 <div className="ms-TextField-wrapper">
						  	 	<label className="lbl-text required-Label">Phone</label>
						  	 	<div className={formik.touched && formik.touched.workPhone &&
						  	 		formik.errors && formik.errors.workPhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
						  	 		<InputMask
						  	 			autoClear={false}
						  	 			required={true}
						  	 			id="workPhone"
						  	 			name="workPhone"
						  	 			mask="(999)999-9999"
						  	 			placeholder=""
						  	 			value={formik.values.workPhone}
						  	 			className="mask-TextField"
						  	 			onChange={(e) =>handleMaskInput(e)}
						  	 			onBlur={formik.handleBlur}>
						  	 		</InputMask>
						  	 	</div>
						  	 	<span className="error-message">{
						  	 		formik.touched && formik.touched.workPhone &&
						  	 		formik.errors && formik.errors.workPhone &&
						  	 		formik.errors.workPhone.toString()
						  	 	}</span>
						  	 </div>
						  </div>
					</Col>
                    <Col sm={6} xs={12} md={6} lg={6}>
						 <div className="ms-TextField root-155">
						 	<div className="ms-TextField-wrapper">
						 		<label className="lbl-text required-Label">Alt phone</label>
						 		<div className={formik.touched && formik.touched.alternatePhone &&
						 			formik.errors && formik.errors.alternatePhone ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
						 			<InputMask
						 				autoClear={false}
						 				required={true}
						 				id="alternatePhone"
						 				name="alternatePhone"
						 				mask="(999)999-9999"
						 				placeholder=""
						 				value={formik.values.alternatePhone}
						 				className="mask-TextField"
						 				onChange={(e) =>handleMaskInput(e)}
						 				onBlur={formik.handleBlur}>
						 			</InputMask>
						 		</div>
						 		<span className="error-message">{
						 			formik.touched && formik.touched.alternatePhone &&
						 			formik.errors && formik.errors.alternatePhone &&
						 			formik.errors.alternatePhone.toString()
						 		}</span>
						 	</div>
						 </div>
					</Col>
                </Row>
                {/*<Row style={{ marginTop: "10px" }}>
                    <Col>
                        <label className="lbl-text" style={{ display: "block" }}>Communication</label>
                        <label className="lbl-text" style={{ display: "block" }}>Websites</label>
                        <label className="lbl-text" style={{ display: "block" }}>Text</label>
                    </Col>
                    <Col>
                        <label className="lbl-text">Primary phone number</label>
                        <label className="firstInputEvt checkboxArea">
                            <Checkbox
                                label=""
                                id="website"
                                onChange={handleWorkPhoneCheckBoxChange}
                                checked={formik.values.alternatePhoneUsage.includes("website")}
                            />
                        </label>
                        <label className="firstInputEvt checkboxArea">
                            <Checkbox
                                label=""
                                id="text"
                                onChange={handleWorkPhoneCheckBoxChange}
                                checked={formik.values.workPhoneUsage.includes("text")}
                            />
                        </label>
                    </Col>
                    <Col>
                        <label className="lbl-text">Alternate phone number</label>
                        <label className="firstInputEvt checkboxArea">
                            <Checkbox
                                label=""
                                id="alternatePhone-website"
                                onChange={handleAlternatePhoneCheckBoxChange}
                                checked={formik.values.alternatePhoneUsage.includes("website")}
                            />
                        </label>
                        <label className="firstInputEvt checkboxArea">
                            <Checkbox
                                label=""
                                id="alternatePhone-text"
                                onChange={handleAlternatePhoneCheckBoxChange}
                                checked={formik.values.alternatePhoneUsage.includes("text")}
                            />
                        </label>
                    </Col>
                </Row>*/}
                
                <Stack tokens={stackTokens} className="checkbox-list">
                    <label style={{ fontSize: "14px" }} className="lbl-text labelArea">What role(s) does this user play? Select all that apply.</label>
                    <Checkbox
                        label="Owner"
                        id="owner"
                        onChange={handleRoleCheckBoxChange}
                        checked={formik.values.jobTitle.includes("owner")}
                    />
                    <Checkbox
                        label="General Manager"
                        id="general-manager"
                        onChange={handleRoleCheckBoxChange}
                        checked={formik.values.jobTitle.includes("general-manager")}
                    />
                    <Checkbox
                        label="Office Manager"
                        id="office-manager"
                        onChange={handleRoleCheckBoxChange}
                        checked={formik.values.jobTitle.includes("office-manager")}
                    />
                    <Checkbox
                        label="Sales"
                        id="sales"
                        onChange={handleRoleCheckBoxChange}
                        checked={formik.values.jobTitle.includes("sales")}
                    />
                    <Checkbox
                        label="F&I"
                        id="f-and-i"
                        onChange={handleRoleCheckBoxChange}
                        checked={formik.values.jobTitle.includes("f-and-i")}
                    />
                </Stack>
                {/*<Row>
                    <Col>
                        <Stack>
                            <strong>Text Alerts</strong>
                            <span>My Agreeing to alerts, you agree too...</span>
                        </Stack>
                        <br />
                        <Stack tokens={stackTokens}>
                            <label className="checkboxArea">
                                <Checkbox id="textConsent" label="I consent" onChange={handleCheckBoxChange} checked={formik.values.textConsent} />
                            </label>

                        </Stack>
                        <br />
                        <Stack>
                            <Toggle label="Alert 1" inlineLabel />
                            <Toggle label="Alert 2" inlineLabel />
                        </Stack>
                    </Col>
                    <Col>
                        <Stack>
                            <strong>User Permissions</strong>
                            <span>user permissions descriptions</span>
                        </Stack>
                        <br />
                        <Stack tokens={stackTokens}>
                            <label className="checkboxArea">
                                <Checkbox label="Dealership Location 1" checked={true} />
                            </label>
                            <label className="checkboxArea">
                                <Checkbox label="Dealership Location 2" />
                            </label>
                        </Stack>
                        <br />
                        <Stack>
                            <Toggle label="Permission 1" inlineLabel />
                            <Toggle label="Permission 2" inlineLabel />
                        </Stack>
                    </Col>
                </Row>*/}
            </Stack>
            <br />
            <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="end">
                <PrimaryButton
                   disabled={!formik.dirty || !formik.isValid}
                    //onRenderIcon={renderSpinner}
                    text="Save Changes"
                    onClick={handleUpdateClick}
                />
            </Stack>
            {/*  </Col>*/}
            {/*</Row>*/}
        </>
    );
};

export default AccountSettings;
