import * as actions from "store/locations/locations.types";
import { ILocations, ILocation } from "types/locationsTypes";

interface IState {
  deleting: boolean;
  loading: boolean;
  locations: ILocations | null;
  error: null | object;
  rowsPerPage: number;
  selectedLocation: null | ILocation;
  updateLoading: boolean;
  column: string;
  sort: string;
}

const initialState: IState = {
  deleting: false,
  loading: false,
  locations: {
    results: [],
    totalItems: 0,
    currentPage: "1",
  },
  error: null,
  rowsPerPage: 15,
  selectedLocation: null,
  updateLoading: false,
  column: 'legalName',
  sort: 'ASC'
};

export const LocationsReducer = (
  state: IState = initialState,
  action: actions.LocationsActions
) => {
  switch (action.type) {
    case actions.CREATE_LOCATION_PENDING:
      return {
        ...state,
          loading: true,
          error: null
      };
    case actions.CREATE_LOCATION_REJECTED:
      return { ...state, loading: false, error: action.payload };
    case actions.CREATE_LOCATION_RESOLVED:
      return {
        ...state,
        loading: false,
        //locations: action.payload
      };
    case actions.GET_LOCATIONS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_LOCATIONS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.GET_LOCATIONS_RESOLVED:
      return {
        ...state,
        loading: false,
        locations: action.payload,


      };
    case actions.SET_ROWS_PER_PAGE_LOCATIONS:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case actions.VIEW_LOCATION_PENDING: {
      return {
        ...state,
          loading: true,
          error:null
      };
    }

    case actions.VIEW_LOCATION_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedLocation: action.payload,
      };
    }

    case actions.VIEW_LOCATION_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_LOCATION_PENDING: {
      return {
        ...state,
        deleting: true,
        //loading: true,
      };
    }

    case actions.DELETE_LOCATION_RESOLVED: {
      return {
        ...state,
        deleting: false,
        //loading: false,
      };
    }

    case actions.DELETE_LOCATION_REJECTED: {
      return {
        ...state,
        deleting: false,
        //loading: false,
      };
    }
    case actions.UPDATE_LOCATION_PENDING: {
      return {
        ...state,
          loading: true,
          error: null
      };
    }

    case actions.UPDATE_LOCATION_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_LOCATION_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
