import moment from "moment";
import { ITask, IDates } from "types/taskTypes";

export const getDate = (date: string) => {
  const d = new Date(date);
  const day = d.getDate() > 10 ? d.getDate() : `0${d.getDate()}`;
  const month =
    d.getUTCMonth() + 1 > 10 ? d.getUTCMonth() + 1 : `0${d.getUTCMonth() + 1}`;

  return date ? `${month}.${day}.${d.getFullYear()}` : "";
};

export const formatDate = (date: string) => {
  return moment(date).format("ll");
};

export const formatDateInventory = (date: string) => {
  return moment(date).format("L");
}

export const formatDateSub = (date: number) => {
  const d = new Date(date)
  return moment(d).format("L");
}

/** TASK DATES FUNCTIONS*/

export const getDay = (date: string): string => {
  return date.split("T")[0];
};

const dateDiff = (date: string): number => {
  const dt2 = new Date(); // today
  const dt1 = new Date(date);

  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

const getGroupIndex = (group: any[], groupDateName: string) => {
  return group.indexOf(group.find((g: any) => g.date === groupDateName));
};

export const sortTasksByDate = (tasks: ITask[]) => {
  return tasks.sort((a: ITask, b: ITask) => {
    const ad: any = new Date(a.date);
    const bd: any = new Date(b.date);
    return ad - bd;
  });
};

export const groupByDate = (dateArray: ITask[]) => {
  const array = sortTasksByDate(dateArray);

  if (array.length < 1) return [];

  const grouped: IDates[] = [];
  let previousDate = ""; // need to be without T

  let today: Date = new Date();
  let tomorrow = new Date();

  tomorrow.setDate(today.getDate() + 1);
  tomorrow = new Date(getDay(tomorrow.toISOString()));
  today = new Date(getDay(today.toISOString()));

  for (let i = 0; i < array.length; i++) {
    const task = array[i];
    const taskDate = new Date(getDay(task.date));

    if (taskDate < today) {
      if (getGroupIndex(grouped, "Overdue") === -1) {
        grouped.push({
          date: "Overdue",
          tasks: [],
        });
      }
      const diff = dateDiff(task.date);

      grouped[getGroupIndex(grouped, "Overdue")].tasks.push({
        ...task,
        additionalData: `${diff} ${diff === 1 ? "day" : "days"} left`,
      });
      previousDate = "Overdue";
    } else if (getDay(taskDate.toISOString()) === getDay(today.toISOString())) {
      if (getGroupIndex(grouped, "Today") === -1) {
        grouped.push({
          date: "Today",
          tasks: [],
        });
      }

      grouped[getGroupIndex(grouped, "Today")].tasks.push(task);
      previousDate = "Today";
    } else if (
      getDay(taskDate.toISOString()) === getDay(tomorrow.toISOString())
    ) {
      if (getGroupIndex(grouped, "Tomorrow") === -1) {
        grouped.push({
          date: "Tomorrow",
          tasks: [],
        });
      }
      grouped[getGroupIndex(grouped, "Tomorrow")].tasks.push(task);
      previousDate = "Tomorrow";
    } else {
      if (task.date === previousDate) {
        grouped[getGroupIndex(grouped, task.date)].tasks.push(task);
      } else {
        grouped.push({
          date: getDay(task.date),
          tasks: [{ ...task }],
        });
      }
    }
  }
  return grouped;
};

export const convertLocalToUTC = (dateString) => {
  if (dateString) {
      let utcStart = moment(new Date(dateString), "YYYY-MM-DDTHH:mm").utc();
      return utcStart.format();
  }
};

export const convertLocalToUTCModified = (dateString) => {
  if (dateString) {
      let utcStart = moment(new Date(dateString)).utc();
      return utcStart.format('MM/DD/YY');
  }
};

export const convertUTCToLocal = (dateString) => {
    if (dateString) {
        var date = moment.utc(dateString).format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format('MM-DD-YYYY HH:mm');
    }
    return '';
};

export const convertUTCToLocalModified = (dateString) => {
  if (dateString) {
      return moment.utc(dateString).format('MM/DD/YY');
  }
  return '';
};

export const convertUTCToLocalMoment = (dateString) => {
    if (dateString) {
        return moment.utc(dateString);
    }
    return null;
};

export const convertUTCToLocalDateOnly = (dateString) => {
  if (dateString) {
      var date = moment.utc(dateString).format('YYYY-MM-DD HH:mm:ss');
      var stillUtc = moment.utc(date).toDate();
      return moment(stillUtc).local().format('MM/DD/YY');
  }
  return '';
};

export const convertUTCToLocalDateOnlyModified = (dateString) => {
  if (dateString) {
      return moment.utc(dateString).format('MM/DD/YY');
  }
  return '';
};

export const convertUTCToLocalTime0nlyModified = (dateString) => {
  if (dateString) {
      return moment.utc(dateString).format('HH:mm');
  }
  return '';
};

export const convertUTCToLocalTime0nlyModified12Hr = (dateString) => {
  if (dateString) {
      return moment.utc(dateString).format('hh:mm A');
  }
  return '';
};

export const convertUTCToLocalDate = (dateString) => {
    if (dateString) {
        var date = moment.utc(dateString).format('YYYY-MM-DD');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format('MM-DD-YYYY');
    }
    return '';
};

export const convertUTCToLocalDateModified = (dateString) => {
  if (dateString) {
      return moment.utc(dateString).format('MM/DD/YY');
  }
  return '';
};

export const convertUTCToLocalFormat = (dateString,pattern) => {
    if (dateString) {
        var date = moment.utc(dateString).format('YYYY-MM-DD HH:mm:ss');
        var stillUtc = moment.utc(date).toDate();
        return moment(stillUtc).local().format(pattern);
    }
    return '';
};

export const convertUTCToLocalFormatModified = (dateString,pattern) => {
  if (dateString) {
      return moment.utc(dateString).format(pattern);
  }
  return '';
};
