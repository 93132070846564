import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    PrimaryButton,
    TextField,
    Link
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleSaveDealerTrade, handleGetDealerTrades, handleGetDealerTradeInfo } from "store/dealTracker/dealTracker.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import {
//	useNavigate ,
//	useLocation
//} from "react-router-dom";
//import CustomTable from "components/table/datatable/CustomTable";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { useMediaQuery } from "react-responsive";
//import moment from "moment";
import AddCircle from '@mui/icons-material/AddCircle';

import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import * as Yup from "yup";
import { HANDLE_GET_EMPLOYEES } from "store/employees/employees.types";
import { convertUTCToLocalFormatModified } from "utils/dateTimeFunctions";
import { AddVehicleSearchDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/AddVehicleSearchDialog";
import { AddVehicleTrimDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/AddVehicleTrimDialog";
import { AddVehicleFoundDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/AddVehicleFoundDialog";
import { EditDTVehicleInfoDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/EditDTVehicleInfoDialog";
import { trimTypes } from "constants/constants";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "DealTracker";
export const DealerTradeWorkbookPage = () => {
    const dispatch = useDispatch();
    //const location = useLocation();


    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    //const navigate = useNavigate ();
    const isMobile = useMediaQuery({ maxWidth: 769 });
    const isSM = useMediaQuery({ maxWidth: 435 });

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [tableData, setTableData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const [credentials, setCredentials] = useState([]);
    const [tradeType, setTradeType] = useState(1);
    const [globalFilter, setGlobalFilter] = useState('');
    const [dealerTradeId, setDealerTradeId] = useState(0);
    const [sectionIndex, setSectionIndex] = React.useState(null);
    const [salesPersonList, setSalesPersonList] = useState([]);
    //const [tblMessage, setTblMessage] = useState('Please wait');
    const [isVehiclesearchDialog, setVehicleSearchDialog] = useState(false);
    const [trimType, setTrimType] = useState(2);
    const [trimValues, settrimValues] = React.useState([]);
    const [vinforTrim, setvinforTrim] = React.useState(null);
    const [ymmforTrim, setymmforTrim] = React.useState(null);
    const [isVehicleTrimDialog, setisVehicleTrimDialog] = useState(false);
    const [orderStock, setorderStock] = useState(null);
    const [vehicleAction, setVehicleAction] = useState('');
    const [vehicleIn, setVehicleIn] = useState(null);
    const [vehicleOut, setVehicleOut] = useState(null);
    const [isVehicleFoundDialog, setVehicleFoundDialog] = useState(false);
    const [foundVehicleId, setFoundVehicleId] = useState(null);
    //const [isVehiclesChange, setVehiclesChange] = useState(false);
    const [isOpenVehicleDia, setIsOpenVehicleDia] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('tradeDate');
    const [addVehicleSection, setAddVehicleSection] = useState(null);

    useEffect(() => {
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "salesPerson" } });
        dispatch<any>(handleGetDealerTrades({ tradeType, callback: (res) => populateTableData(res) }));
        setAddVehicleSection(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSalesPersonList([]);
        let sales = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return sales.push({ id: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            setSalesPersonList(sales)
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps

    //useEffect(() => {
    //	const stateVal: any = location?.state
    //	if (stateVal && stateVal.searchInput) {
    //		setGLFilter(stateVal.searchInput);
    //	}
    //	if (stateVal?.sortField) {
    //		// setSortField(stateVal.sortField);
    //		// setSortOrder(stateVal.sortOrder);
    //	}
    //}, [location]);

    const populateTableData = (res) => {
        setSectionIndex(1);
        if (res && res.results.length) {
            const type = res.results[0].type;
            res.results.forEach(function (time, index) {
                res.results[index]["tradeDate"] = convertUTCToLocalFormatModified(res.results[index].createdAt, "MM/DD/YYYY");
                res.results[index]["tradeMgr"] = res.results[index].createdBy ? res.results[index].createdBy.firstName + ' ' + res.results[index].createdBy?.lastName : null;
                if (res.results[index].vehicleIn) {
                    const data = res.results[index].vehicleIn;
                    res.results[index]["vehicleInInfo"] = (data.year + ' ' + data.make + ' ' + data.model + ' ' + (data.vin ? data.vin : '')) + ' ' + (data.stockNumber ? data.stockNumber : 'NA') + ' ' + (data.orderNumber ? data.orderNumber : 'NA');
                }
                if (res.results[index].vehicleOut) {
                    const data = res.results[index].vehicleOut;
                    res.results[index]["vehicleOutInfo"] = (data.year + ' ' + data.make + ' ' + data.model + ' ' + (data.vin ? data.vin : '')) + ' ' + (data.stockNumber ? data.stockNumber : 'NA') + ' ' + (data.orderNumber ? data.orderNumber : 'NA');
                }
                if (type === 1)
                    res.results[index]["salesPersonName"] = res.results[index].salesPerson ?
                        (res.results[index].salesPerson.firstName + ' ' + res.results[index].salesPerson.lastName) : null;
            });
            setTableData(res.results);
        }
        else {
            //setTblMessage('No data found');
            setTableData([]);
        }
    };

    const handleToggleChange = (tradeType) => {
        setTradeType(tradeType);
        //setTblMessage('Please wait');
        setGlobalFilter('');
        dispatch<any>(handleGetDealerTrades({ tradeType, callback: (res) => populateTableData(res) }));
    };

    const handleGlobalFilter = (e) => {
        setGlobalFilter(e.target.value);
    };

    const handleAddDealerTrade = () => {
        formik.setValues(
            {
                ...formik.values,
                type: tradeType,
                id: 0,
                salesPersonId: "",
                dealNumber: "",
                customerName: "",
                customerCity: "",
                dealerName: "",
                dealerCode: "",
                dealerCity: "",
                comments: ""
            }
        );
        setVehicleIn(null);
        setVehicleOut(null);
        setDealerTradeId(0);
        setSectionIndex(2);
    }

    const exportExcel = () => {
        if (tableData && tableData.length) {
            let data = [...tableData];
            if (globalFilter) {
                data = [...filteredData];
            }
            let exportData;
            let fileName;
            if (tradeType === 1) {
                exportData = data.map((item) =>
                    ({
                        "Trade Date": item.tradeDate,
                        "Trade Mgr": item.tradeMgr,
                        "Sales Person": item.salesPersonName,
                        "Customer": item.customerName,
                        "Customer City": item.customerCity,
                        "Dealer": item.dealerName,
                        "Dealer City": item.dealerCity,
                        "D-Code": item.dealerCode,
                        "Vehicle In(Year-Make-Model)": item.vehicleIn && (item.vehicleIn.year + ' ' + item.vehicleIn.make + ' ' + item.vehicleIn.model + ' ' + (item.vehicleIn.vin ? item.vehicleIn.vin : '')),
                        "Vehicle In(Stock)": item.vehicleIn && ((item.vehicleIn.stockNumber ? item.vehicleIn.stockNumber : 'NA')),
                        "Vehicle In(Order)": item.vehicleIn && ((item.vehicleIn.orderNumber ? item.vehicleIn.orderNumber : 'NA')),
                        "Vehicle Out(Year-Make-Model)": item.vehicleOut && (item.vehicleOut.year + ' ' + item.vehicleOut.make + ' ' + item.vehicleOut.model + ' ' + (item.vehicleOut.vin ? item.vehicleOut.vin : '')),
                        "Vehicle Out(Stock)": item.vehicleOut && ((item.vehicleOut.stockNumber ? item.vehicleOut.stockNumber : 'NA')),
                        "Vehicle Out(Order)": item.vehicleOut && ((item.vehicleOut.orderNumber ? item.vehicleOut.orderNumber : 'NA')),
                        "Comments": item.comments
                    }));
                fileName = 'dealer-trade-ourtrade-workbook';
            } else {
                exportData = data.map((item) =>
                    ({
                        "Trade Date": item.tradeDate,
                        "Trade Mgr": item.tradeMgr,
                        "Dealer": item.dealerName,
                        "Dealer City": item.dealerCity,
                        "D-Code": item.dealerCode,
                        "Vehicle In(Year-Make-Model)": item.vehicleIn && (item.vehicleIn.year + ' ' + item.vehicleIn.make + ' ' + item.vehicleIn.model + ' ' + (item.vehicleIn.vin ? item.vehicleIn.vin : '')),
                        "Vehicle In(Stock)": item.vehicleIn && ((item.vehicleIn.stockNumber ? item.vehicleIn.stockNumber : 'NA')),
                        "Vehicle In(Order)": item.vehicleIn && ((item.vehicleIn.orderNumber ? item.vehicleIn.orderNumber : 'NA')),
                        "Vehicle Out(Year-Make-Model)": item.vehicleOut && (item.vehicleOut.year + ' ' + item.vehicleOut.make + ' ' + item.vehicleOut.model + ' ' + (item.vehicleOut.vin ? item.vehicleOut.vin : '')),
                        "Vehicle Out(Stock)": item.vehicleOut && ((item.vehicleOut.stockNumber ? item.vehicleOut.stockNumber : 'NA')),
                        "Vehicle Out(Order)": item.vehicleOut && ((item.vehicleOut.orderNumber ? item.vehicleOut.orderNumber : 'NA')),
                        "Comments": item.comments
                    }));
                fileName = 'dealer-trade-their-workbook';
            }


            import('xlsx').then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(exportData);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, fileName);
            });
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    //Datatable Templates
    const header = (
        <>
            <div style={{ width: "425px" }}>
                <Row>
                    <Col>
                        <PrimaryButton
                            text="Our Trades"
                            onClick={() => handleToggleChange(1)}
                            className={tradeType === 1 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                            style={{ marginRight: "15px" }}
                        />
                        <PrimaryButton
                            text="Their Trades"
                            onClick={() => handleToggleChange(2)}
                            className={tradeType === 2 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            value={globalFilter}
                            type="search"
                            label="Search"
                            className={isSM ? "full-width" : ""}
                            onChange={(e) => handleGlobalFilter(e)}
                        />
                    </Col>
                    <Col className={isSM ? "vertical-bottom-start" : "vertical-bottom"}>
                        <Link
                            color="inherit"
                            onClick={handleAddDealerTrade}
                            style={{ float: "left" }}>
                            <AddCircle
                                style={{ color: "#70af60", fontSize: "1.7rem", height: "1.3em" }}
                                className="plus-icon svgPlus-icon" />
					Add Trade
			        </Link>
                    </Col>
                </Row>
            </div>
            <div style={{ marginRight: "-15px"}}>
                <Link style={{marginTop:'-15px'}} className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                    Export to Excel
		        </Link>
            </div>
        </>
    );

    const tradeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Date</span>
                {rowData.tradeDate}
            </React.Fragment>
        );
    };

    const tradeMgrBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade Mgr</span>
                {rowData.tradeMgr}
            </React.Fragment>
        );
    };

    const dealerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Dealer</span>
                {rowData.dealerName}
            </React.Fragment>
        );
    };

    const dealerCityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Dealer City</span>
                {rowData.dealerCity}
            </React.Fragment>
        );
    };

    const dealerCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">D-Code</span>
                {rowData.dealerCode}
            </React.Fragment>
        );
    };

    const vehicleInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle In</span>
                {
                    rowData.vehicleIn ? <>
                        <div className="display-row">
                            <div>
                                {
                                    rowData.vehicleIn.year + ' ' + rowData.vehicleIn.make + ' ' + rowData.vehicleIn.model
                                }
                                {
                                    rowData.vehicleIn.vin ? <> <br /> {rowData.vehicleIn.vin}</> : null
                                }
                                <br />
                                Stock/Order: {
                                    (rowData.vehicleIn.stockNumber ? rowData.vehicleIn.stockNumber : 'NA') + ' / ' +
                                    (rowData.vehicleIn.orderNumber ? rowData.vehicleIn.orderNumber : 'NA')
                                }
                            </div>
                        </div>
                    </> : null

                }
            </React.Fragment>
        );
    };

    const vehicleOutBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle Out</span>
                {
                    rowData.vehicleOut ? <>
                        <div className="display-row">
                            <div>
                                {
                                    rowData.vehicleOut.year + ' ' + rowData.vehicleOut.make + ' ' + rowData.vehicleOut.model
                                }
                                {
                                    rowData.vehicleOut.vin ? <><br /> {rowData.vehicleOut.vin} </> : null
                                }
                                <br />
                                 Stock/Order : {
                                    (rowData.vehicleOut.stockNumber ? rowData.vehicleOut.stockNumber : 'NA') + ' / ' +
                                    (rowData.vehicleOut.orderNumber ? rowData.vehicleOut.orderNumber : 'NA')
                                }
                            </div>
                        </div>
                    </> : null

                }
            </React.Fragment>
        );
    };

    const commentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Comments</span>
                {rowData.comments}
            </React.Fragment>
        );
    };

    const salesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person</span>
                {rowData.salesPersonName}
            </React.Fragment>
        );
    };

    const customerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customerName}
            </React.Fragment>
        );
    };

    const customerCityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer City</span>
                {rowData.customerCity}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {credentials && user.roles && rowData.id > 0 && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(id) => handleEditDealerTrade(id)}
                    />
                )}


            </React.Fragment>
        );
    };

    const handleEditDealerTrade = (id) => {
        setDealerTradeId(id);
        //setVehiclesChange(false);
        dispatch<any>(handleGetDealerTradeInfo({
            id, callback: (res) => {
                if (res) {
                    formik.setValues(
                        {
                            ...formik.values,
                            type: tradeType,
                            id: res.id,
                            salesPersonId: res.salesPerson?.id,
                            dealNumber: res.dealNumber,
                            customerName: res.customerName,
                            customerCity: res.customerCity,
                            dealerName: res.dealerName,
                            dealerCode: res.dealerCode,
                            dealerCity: res.dealerCity,
                            comments: res.comments
                        }
                    );
                    const vehIn = res.vehicleIn ? res.vehicleIn : null;                   
                    setVehicleIn(vehIn);

                    const vehOut = res.vehicleOut ? res.vehicleOut : null;
                    setVehicleOut(vehOut);

                    setSectionIndex(2);
                } else {
                    setcommonServerError('Failed to Fetch the Data.');
                    window.scrollTo(0, 0);
                }

            }
        }));

        //const selectedItem = tableData.filter(x => x.id === id)
        //if (selectedItem && selectedItem.length) {
        //    setDealerTradeId(id);
        //    setNeedByTime(selectedItem[0].needByTime);
        //    setPrepDialog(true);
        //}
    }

    //const our_type_table_columns = {
    //    "tradeDate": "Trade Date",
    //    "tradeMgr": "Trade Mgr",
    //    "salesPersonName": "Sales Person",
    //    "customerName": "Customer",
    //    "customerCity": "Customer City",
    //    "dealerName": "Dealer",
    //    "dealerCity": "Dealer City",
    //    "dealerCode": "D-Code",
    //    "vehicleIn": "Vehicle In",
    //    "vehicleOut": "Vehicle Out",
    //    "comments": "Comments",
    //    "settings": "Actions"
    //}

    //const their_type_table_columns = {
    //    "tradeDate": "Trade Date",
    //    "tradeMgr": "Trade Mgr",
    //    "dealerName": "Dealer",
    //    "dealerCity": "Dealer City",
    //    "dealerCode": "D-Code",
    //    "vehicleIn": "Vehicle In",
    //    "vehicleOut": "Vehicle Out",
    //    "comments": "Comments",
    //    "settings": "Actions"
    //}

    //const our_type_table_templates = {
    //    "tradeDate": tradeDateBodyTemplate,
    //    "tradeMgr": tradeMgrBodyTemplate,
    //    "salesPersonName": salesBodyTemplate,
    //    "customerName": customerBodyTemplate,
    //    "customerCity": customerCityBodyTemplate,
    //    "dealerName": dealerNameBodyTemplate,
    //    "dealerCity": dealerCityBodyTemplate,
    //    "dealerCode": dealerCodeBodyTemplate,
    //    "vehicleIn": vehicleInBodyTemplate,
    //    "vehicleOut": vehicleOutBodyTemplate,
    //    "comments": commentsBodyTemplate,
    //    "settings": actionBodyTemplate
    //}

    //const their_type_table_templates = {
    //    "tradeDate": tradeDateBodyTemplate,
    //    "tradeMgr": tradeMgrBodyTemplate,
    //    "dealerName": dealerNameBodyTemplate,
    //    "dealerCity": dealerCityBodyTemplate,
    //    "dealerCode": dealerCodeBodyTemplate,
    //    "vehicleIn": vehicleInBodyTemplate,
    //    "vehicleOut": vehicleOutBodyTemplate,
    //    "comments": commentsBodyTemplate,
    //    "settings": actionBodyTemplate
    //}

    //const handleRowClick = (event) => {
    //    let target = event.originalEvent.target;
    //    if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
    //        (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
    //        target.lastElementChild.lastElementChild.firstElementChild.click();
    //    }
    //    else {
    //        handleEditDealerTrade(event.data.id);
    //    }
    //}

    const handleRowClick = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            handleEditDealerTrade(data.value[0].rowData.id);
        }

    }

    const formik = useFormik({
        initialValues: {
            id: 0,
            type: 1,
            salesPersonId: "",
            dealNumber: "",
            customerName: "",
            customerCity: "",
            dealerName: "",
            dealerCode: "",
            dealerCity: "",
            comments: ""
        },
        validationSchema: Yup.object({
            id: Yup.number(),
            type: Yup.number(),
            salesPersonId: Yup.string().when('type',
                {
                    is: 1,
                    then: Yup.string().required('Sales person required.'),
                    otherwise: schema => schema
                }),
            dealNumber: Yup.string(),
            customerName: Yup.string(),
            customerCity: Yup.string(),
            dealerName: Yup.string(),
            dealerCode: Yup.string(),
            dealerCity: Yup.string(),
            comments: Yup.string(),
        }),
        onSubmit: (values) => {


            const data = {
                "id": dealerTradeId,
                "dealerName": values.dealerName,
                "dealerCode": values.dealerCode,
                "dealerCity": values.dealerCity,
                "comments": values.comments,
                "type": values.type,
                "salesPersonId": values.salesPersonId,
                "customerName": values.customerName,
                "customerCity": values.customerCity,
                "dealNumber": values.dealNumber
            };

            dispatch<any>(handleSaveDealerTrade({
                formData: data, callback: (res) => {
                    if (res) {
                        //setTblMessage('Please wait');
                        dispatch<any>(handleGetDealerTrades({ tradeType, callback: (res) => populateTableData(res) }));
                    } else {
                        setcommonServerError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    }
                }
            }));
        }
    });

    const handleDDChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    const handleTextAreaChange = (e, newValue) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: newValue,
            }
        );
    };

    const handleShowGrid = () => {
        dispatch<any>(handleGetDealerTrades({ tradeType, callback: (res) => populateTableData(res) }));
    }

    const handleFormikSumbit = () => {
        formik.handleSubmit();
    }

    const resetVinYmm = () => {
        setvinforTrim(null);
        setymmforTrim(null);
    }

    const handleAddInventoryDialogDismiss = (vehicleId, id, vin) => {
        console.log(vehicleId, id, vin)
        console.log(dealerTradeId)
        if (!dealerTradeId && id)
            setDealerTradeId(id);
        if (vehicleId) {
            setcommonServerError('');
            if (trimType === trimTypes.editDelivery && vehicleId) {
                //setdealID(vehicleId);
                //setvehicleFoundDialogOpen(true)
            } else if (trimType === trimTypes.karl) {
                setFoundVehicleId(vehicleId);
                setVehicleFoundDialog(false);
                //dispatch<any>(handleGetDealTracker({
                //    id: vehicleId, callback: (res) => {
                //        setVehicleSearchDialog(false);
                //        if (res) {
                //            dispatch<any>(handleGetVehDetailsSuccess(res.vehicle));
                //            setVehicleID(vehicleId);
                //            setVehicleFoundDialog(true);
                //        } else {
                //            setcommonServerError('Failed to Fetch Data.');
                //        }
                //    }
                //}));
            } else {
                //setVehicleID(vehicleId);
                // setVehicleFoundDialog(true);
            }
        } else {
            setVehicleSearchDialog(false);
        }
    }

    const handleAddVehicle = (action) => {
        setVehicleSearchDialog(true);
        setVehicleAction(action);
        setAddVehicleSection(null)
    }

    const handleEditVehicle = (action) => {
        setIsOpenVehicleDia(true);
        setVehicleAction(action);
    }

    const handleFoundDialogDismiss = (msg) => {

        if (msg && (typeof msg === 'string' || msg instanceof String)) {
            setcommonServerError(msg);
        }
        setVehicleFoundDialog(false);
    };

    const handleAddVehicleTrimDialogDismiss = (res) => {
        setVehicleData(res);
        setisVehicleTrimDialog(false);
    }

    const handleEditVehicleInfoDialogDismiss = (res) => {
        setVehicleData(res);
        setIsOpenVehicleDia(false);
    }

    const handleOnSaveVehicle = (res) => {
        if (!dealerTradeId && res)
            setDealerTradeId(res.dealerTradeId);
        setVehicleData(res);
        setVehicleSearchDialog(false);
    }

    const setVehicleData = (res) => {
        if (res && res.id) {
            //setVehiclesChange(true);
            if (vehicleAction === 'vehicleIn')
                setVehicleIn(res);
            else if (vehicleAction === 'vehicleOut')
                setVehicleOut(res);
        }
    }

    const openTrimDialog = ({ vin, trim, ymm, id }) => {
        if (!dealerTradeId)
            setDealerTradeId(id);
        resetVinYmm();
        setVehicleSearchDialog(false);
        settrimValues(trim);
        setisVehicleTrimDialog(true);
        if (vin) {
            setvinforTrim(vin);
        } else {
            setymmforTrim(ymm);
        }
    };

    const openTrimDialogEditVeh = ({ vin, trim, ymm, order, stock, vehicleId }) => {
        if (vin) {
            setvinforTrim(vin);
        } else {
            setymmforTrim(ymm);
        }

        setorderStock({
            orderNumber: order,
            stockNumber: stock,
            dealerTradeId: dealerTradeId,
            vehicleId,
            vin: vin
        });

        setTrimType(trimTypes.editDelivery)
        resetVinYmm();
        setVehicleSearchDialog(false);
        settrimValues(trim);
        setIsOpenVehicleDia(false);
        setisVehicleTrimDialog(true);
    };

    const openVehDialogFound = (veh) => {
        setFoundVehicleId(veh);
        setVehicleFoundDialog(true);
        setIsOpenVehicleDia(false);
    }

    const handleVinEdit = () => {
        setVehicleSearchDialog(true);
        setIsOpenVehicleDia(false);
        setAddVehicleSection(null);
    }

    const handleAddVehicleSectionIndex = (value:any) => {
        if(value === 1){
            setAddVehicleSection(1)
        }else if(value === 2){
            setAddVehicleSection(2)
        }
        setVehicleSearchDialog(true);
        setIsOpenVehicleDia(false);
    }

    const handleBackToUsers = () => {
        dispatch<any>(handleGetDealerTrades({ tradeType, callback: (res) => populateTableData(res) }));
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    return (
        isKarlProfileType ? <>
            {
                isVehiclesearchDialog ?
                    <AddVehicleSearchDialog
                        onDismiss={(vehicleId, id, vin) => handleAddInventoryDialogDismiss(vehicleId, id, vin)}
                        onSave={handleOnSaveVehicle}
                        openTrimDialog={openTrimDialog}
                        trimType={trimType}
                        tradeType={tradeType}
                        dealerTradeId={dealerTradeId}
                        vehicleAction={vehicleAction}
                        vehicleInfo={vehicleAction === 'vehicleIn' ? vehicleIn : vehicleOut}
                        addVehicleSection ={addVehicleSection}
                    /> : null
            }
            {
                isVehicleTrimDialog ?
                    <AddVehicleTrimDialog
                        onDismiss={(res) => { handleAddVehicleTrimDialogDismiss(res) }}
                        trimValues={trimValues}
                        vin={vinforTrim}
                        ymm={ymmforTrim}
                        dealerTradeId={dealerTradeId}
                        trimType={trimType}
                        tradeType={tradeType}
                        vehicleAction={vehicleAction}
                        orderStock={orderStock}
                    />
                    : null
            }
            {
                isVehicleFoundDialog ?
                    <AddVehicleFoundDialog
                        onDismiss={(msg) => handleFoundDialogDismiss(msg)}
                        vehicleAction={vehicleAction}
                        id={dealerTradeId}
                        foundVehicleId={foundVehicleId}
                    />
                    : null
            }
            {
                isOpenVehicleDia ?
                    <EditDTVehicleInfoDialog
                        isOpen={isOpenVehicleDia}
                        onDismiss={(res) => handleEditVehicleInfoDialogDismiss(res)}
                        openTrimDialog={openTrimDialogEditVeh}
                        vehFound={openVehDialogFound}
                        dealerTradeId={dealerTradeId}
                        tradeType={tradeType}
                        vehicleInfo={vehicleAction === 'vehicleIn' ? vehicleIn : vehicleOut}
                        vehicleAction={vehicleAction}
                        onVinEdit={handleVinEdit}
                        addVehicleSectionIndex={handleAddVehicleSectionIndex}
                    />
                    : null
            }
            {

                sectionIndex === 1 ? <Stack styles={container} tokens={{ childrenGap: 10 }}>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row className="pageTitleRow">
                            <Col className="alignHeaders">
                                <CustomFontText style={{ margin: "0px" }} className="pageName pageHeaderFont task">Dealer Trades</CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    {
                        commonServerError ?
                            <div>
                                <p className="genericErrorColor"><span>{commonServerError}</span></p>
                            </div> : null
                    }
                    <div className="datatable-filter-demo noMaxWidthTable dealer-trade-workbook">
                        <div className="card reportsTableVal">
                            {
                                tradeType === 1 ?
                                    <>
                                        <DataTable
                                            value={tableData}
                                            className="p-datatable-customers"
                                            paginator
                                            header={header}
                                            ////cellSelection
                                            rows={10}
                                            emptyMessage="No data found."
                                            globalFilter={globalFilter}
                                            sortField={sortField}
                                            sortOrder={sortOrder}
                                            onSort={handleColumnSort}
                                            onValueChange={filteredData => setFilteredData(filteredData)}
                                            onSelectionChange={e => handleRowClick(e)}>
                                            <Column sortable field="tradeDate" header="Trade Date" body={tradeDateBodyTemplate} />
                                            <Column sortable field="tradeMgr" header="Trade Mgr" body={tradeMgrBodyTemplate} />
                                            <Column sortable field="salesPersonName" header="Sales Person" body={salesBodyTemplate} />
                                            <Column sortable field="customerName" header="Customer" body={customerBodyTemplate} />
                                            <Column sortable field="customerCity" header="Customer City" body={customerCityBodyTemplate} />
                                            <Column sortable field="dealerName" header="Dealer" body={dealerNameBodyTemplate} />
                                            <Column sortable field="dealerCity" header="Dealer City" body={dealerCityBodyTemplate} />
                                            <Column sortable field="dealerCode" header="D-Code" body={dealerCodeBodyTemplate} />
                                            <Column sortable style={{ width: "12em" }} filterField="vehicleInInfo" field="vehicleIn" header="Vehicle In" body={vehicleInBodyTemplate} />
                                            <Column sortable style={{ width: "12em" }} filterField="vehicleOutInfo" field="vehicleOut" header="Vehicle Out" body={vehicleOutBodyTemplate} />
                                            <Column sortable field="comments" header="Comments" body={commentsBodyTemplate} />
                                            <Column field="Settings" header="Actions" body={actionBodyTemplate} />
                                        </DataTable>
                                    </>
                                    :
                                    <>
                                        <DataTable
                                            value={tableData}
                                            className="p-datatable-customers"
                                            paginator
                                            header={header}
                                            // //cellSelection
                                            rows={10}
                                            emptyMessage="No data found."
                                            globalFilter={globalFilter}
                                            onValueChange={filteredData => setFilteredData(filteredData)}
                                            onSelectionChange={e => handleRowClick(e)}>
                                            <Column sortable field="tradeDate" header="Trade Date" body={tradeDateBodyTemplate} />
                                            <Column sortable field="tradeMgr" header="Trade Mgr" body={tradeMgrBodyTemplate} />
                                            <Column sortable field="dealerName" header="Dealer" body={dealerNameBodyTemplate} />
                                            <Column sortable field="dealerCity" header="Dealer City" body={dealerCityBodyTemplate} />
                                            <Column sortable field="dealerCode" header="D-Code" body={dealerCodeBodyTemplate} />
                                            <Column sortable style={{ width: "12em" }} filterField="vehicleInInfo" field="vehicleIn" header="Vehicle In" body={vehicleInBodyTemplate} />
                                            <Column sortable style={{ width: "12em" }} filterField="vehicleOutInfo" field="vehicleOut" header="Vehicle Out" body={vehicleOutBodyTemplate} />
                                            <Column sortable field="comments" header="Comments" body={commentsBodyTemplate} />
                                            <Column field="Settings" header="Actions" body={actionBodyTemplate} />
                                        </DataTable>
                                    </>
                            }


                            {/* <CustomTable
                                column_list={tradeType === 1 ? our_type_table_columns : their_type_table_columns}
                                data={tableData}
                                header={header}
                                paginator={false}
                                customTemplates={tradeType === 1 ? our_type_table_templates : their_type_table_templates}
                                emptyMessage={tblMessage}
                                onRowClick={(event) => handleRowClick(event)}
                                globalFilter={globalFilter}
                            />*/}
                        </div>
                    </div>
                </Stack> : null
            }
            {
                sectionIndex === 2 ?
                    <>
                        <Row>
                            <Col sm={12} md={12} xs={12} lg={12}>
                                <img
                                    alt="Back"
                                    key={"back"}
                                    className={isMobile ? "backButton settings" : "backButton settings back-nav-btn"}
                                    onClick={handleBackToUsers}
                                    src={`${require("assets/back_pagination.png")}`}
                                />
                            </Col>
                        </Row>
                        <div className={!isMobile ? "trade-info-section" : ""}>
                            <Row className="pageTitleRow">
                                <Col className="alignHeaders">
                                    <CustomFontText style={{ margin: "20px 0 0" }} className="pageName pageHeaderFont task">{tradeType === 1 ? "Our Trade" : "Their Trade"}</CustomFontText>
                                </Col>
                            </Row>
                            <div>
                                {
                                    tradeType === 1 ?
                                        <>
                                            <Row>
                                                <Col md={4}>
                                                    <label className="lbl-text required-Label">Sales Person</label>
                                                    <PRDropdown
                                                        id="salesPersonId"
                                                        placeholder=""
                                                        value={formik.values.salesPersonId}
                                                        options={salesPersonList}
                                                        onChange={handleDDChange}
                                                        optionLabel="text"
                                                        optionValue="id"
                                                        appendTo="self"
                                                        className="custom-p-dropdown"
                                                        filter={true}
                                                        resetFilterOnHide={true}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <TextField
                                                        id="dealNumber"
                                                        className={isMobile ? "full-width" : ""}
                                                        name="dealNumber"
                                                        label="Deal Number"
                                                        value={formik.values.dealNumber}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        errorMessage={
                                                            formik.touched.dealNumber &&
                                                            formik.errors.dealNumber &&
                                                            formik.errors.dealNumber.toString()
                                                        }
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <TextField
                                                        id="customerName"
                                                        name="customerName"
                                                        label="Customer"
                                                        className={isMobile ? "full-width" : ""}
                                                        value={formik.values.customerName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        errorMessage={
                                                            formik.touched.customerName &&
                                                            formik.errors.customerName &&
                                                            formik.errors.customerName.toString()
                                                        }
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <TextField
                                                        id="customerCity"
                                                        name="customerCity"
                                                        label="Customer City"
                                                        className={isMobile ? "full-width" : ""}
                                                        value={formik.values.customerCity}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        errorMessage={
                                                            formik.touched.customerCity &&
                                                            formik.errors.customerCity &&
                                                            formik.errors.customerCity.toString()
                                                        }
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                </Col>
                                            </Row>
                                        </> : null
                                }
                                <Row>
                                    <Col md={4}>
                                        <TextField
                                            id="dealerName"
                                            name="dealerName"
                                            label="Dealer Name"
                                            className={isMobile ? "full-width" : ""}
                                            value={formik.values.dealerName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.dealerName &&
                                                formik.errors.dealerName &&
                                                formik.errors.dealerName.toString()
                                            }
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <TextField
                                            id="dealerCode"
                                            name="dealerCode"
                                            label="Dealer Code"
                                            className={isMobile ? "full-width" : ""}
                                            value={formik.values.dealerCode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.dealerCode &&
                                                formik.errors.dealerCode &&
                                                formik.errors.dealerCode.toString()
                                            }
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <TextField
                                            id="dealerCity"
                                            name="dealerCity"
                                            label="Dealer City"
                                            className={isMobile ? "full-width" : ""}
                                            value={formik.values.dealerCity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.dealerCity &&
                                                formik.errors.dealerCity &&
                                                formik.errors.dealerCity.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                {
                                    vehicleIn ?
                                        <>
                                            <Row className="rowPaddingBtwSpace">
                                                <Col style={{ display: "flex", marginTop: "10px" }}>
                                                    <PrimaryButton className="edit-vehicle-in-out-btn" onClick={() => handleEditVehicle('vehicleIn')}>Edit Vehicle In</PrimaryButton>
                                                    <div className="dt-vehicle-info">
                                                        <Row style={{ width: "100%" }}>
                                                            <Col>
                                                                <div className="display-row">
                                                                    <strong style={{ color: "#1c2e47" }}>Vehicle In</strong>
                                                                    <strong style={{ color: "#1c2e47" }}>Stock: {vehicleIn.stockNumber ? vehicleIn.stockNumber : 'NA'}</strong>
                                                                    <strong style={{ color: "#1c2e47" }}>Order: {vehicleIn.orderNumber ? vehicleIn.orderNumber : 'NA'}</strong>
                                                                </div>
                                                                <div>
                                                                    <strong style={{ color: "#1c2e47" }}>{vehicleIn.vin}</strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{ paddingTop: "10px" }}>
                                                                <strong style={{ color: "#1c2e47" }}>{vehicleIn.year + ' ' + vehicleIn.make + ' ' + vehicleIn.model + ' ' +(vehicleIn.style ? vehicleIn.style :" " )}</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </> :
                                        <Row className="rowPaddingBtwSpace">
                                            <Col>
                                                <strong style={{ color: "#1c2e47" }}>Vehicle In</strong>
                                                <PrimaryButton onClick={() => handleAddVehicle('vehicleIn')} className="vehicle-in-out-btn">Add Vehicle In</PrimaryButton>
                                            </Col>
                                        </Row>
                                }
                                {
                                    vehicleOut ?
                                        <>
                                            <Row className="rowPaddingBtwSpace">
                                                <Col style={{ display: "flex" }}>
                                                    <PrimaryButton className="edit-vehicle-in-out-btn" onClick={() => handleEditVehicle('vehicleOut')}>Edit Vehicle Out</PrimaryButton>
                                                    <div className="dt-vehicle-info">
                                                        <Row style={{ width: "100%" }}>
                                                            <Col>
                                                                <div className="display-row">
                                                                    <strong style={{ color: "#1c2e47" }}>Vehicle Out</strong>
                                                                    <strong style={{ color: "#1c2e47" }}>Stock: {vehicleOut.stockNumber ? vehicleOut.stockNumber : 'NA'}</strong>
                                                                    <strong style={{ color: "#1c2e47" }}>Order: {vehicleOut.orderNumber ? vehicleOut.orderNumber : 'NA'}</strong>
                                                                </div>
                                                                <div>
                                                                    <strong style={{ color: "#1c2e47" }}>{vehicleOut.vin}</strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{ paddingTop: "10px" }}>
                                                                <strong style={{ color: "#1c2e47" }}>{vehicleOut.year + ' ' + vehicleOut.make + ' ' + vehicleOut.model + ' ' + (vehicleOut.style ? vehicleOut.style :" " )}</strong>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </> :
                                        <Row className="rowPaddingBtwSpace">
                                            <Col>
                                                <strong style={{ color: "#1c2e47" }}>Vehicle Out</strong>
                                                <PrimaryButton onClick={() => handleAddVehicle('vehicleOut')} className="vehicle-in-out-btn">Add Vehicle Out</PrimaryButton>
                                            </Col>
                                        </Row>
                                }
                                <Row>
                                    <Col>
                                        <TextField
                                            style={{ minHeight: "140px" }}
                                            id="comments"
                                            name="comments"
                                            label="Comments"
                                            multiline
                                            resizable={false}
                                            className="full-width"
                                            value={formik.values.comments}
                                            onChange={(e, newValue) => handleTextAreaChange(e, newValue)}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.comments &&
                                                formik.errors.comments &&
                                                formik.errors.comments.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="rowPaddingBtwSpace">
                                    <Col>
                                        <PrimaryButton onClick={handleShowGrid}>Cancel</PrimaryButton>
                                    </Col>
                                    <Col>
                                        <PrimaryButton style={{ float: "right" }}
                                            disabled={!formik.dirty || !formik.isValid}
                                            onClick={handleFormikSumbit}>
                                            Save
                                                </PrimaryButton>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </> : null
            }

        </> : null
    );
};
