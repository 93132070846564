import React, { FunctionComponent } from "react";
import {
    Modal,
    PrimaryButton,
    IconButton,
    ChoiceGroup,
    IChoiceGroupOption
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
// import moment from "moment";
import { useDispatch } from "react-redux";
import { HandleEmployeeDeleteEvents, HandleEmployeeTimeOff } from "store/employees/employees.action";
import { convertUTCToLocalFormatModified } from "utils/dateTimeFunctions";
const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (msg) => void;
    isOpen: boolean;
    selectedData: any;
}

export const TimeOffDialog: FunctionComponent<IProps> = ({
    onDismiss,
    selectedData,
    isOpen
}) => {
    const dispatch = useDispatch();
    const [timeValidationError, settimeValidationError] = React.useState(null);

    const options: IChoiceGroupOption[] = [
        { key: 'true', text: 'Vacation' },
        { key: 'false', text: 'Sat./Others' },
    ];
    
    const handleDelete = () => {
        // delete event
        if (selectedData?.eventId) {
            // Delete selected time off
            dispatch<any>(HandleEmployeeDeleteEvents({
                id: selectedData?.id,
                eventId: selectedData?.eventId,
                callback: (res) =>  {
                console.log('DELETE TIME OFF res', res)
                if (res) {
                    // setcommonMngServerError(false);
                    onDismiss('reloadCalendar');
                } else {
                    // setcommonMngServerError(true);
                }
            }}));
            
        }
    };

    const handleSubmit = () => {
        formik.handleSubmit()
    };

    const formik = useFormik({
        initialValues: {
            offType: selectedData?.type === 2 ? 'false' : 'true',
        },
        validationSchema: Yup.object({
            offType: Yup.string(),//.min(1, "Required")
        }),
        onSubmit: (values) => {
            if (values) {
                let rawBody = {
                    locationId: selectedData?.locationId,
                    date: selectedData?.date,
                    type: values.offType === 'true' ? 1 : 2,
                    startsAt: "00:00:00",
                    endsAt: "23:45:00",
                    eventId: selectedData?.eventId
                };
                // call time off
                dispatch<any>(HandleEmployeeTimeOff({
                    id: selectedData?.id,
                    method: selectedData.method,
                    data: rawBody,
                    callback: (res) =>  {
                    if (res) {
                        if (res.status === 404) {
                            settimeValidationError('Vacation not available');
                        } else {
                            onDismiss('reloadCalendar');
                        }
                    } else if (!res || (res.status === 404)) {
                        settimeValidationError('Vacation not available');
                    } 
                }}));
                
            }
        }
    });

    const handleRoleCheckBoxChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                offType: item.key
            }
        );
    };

    return (
        <Modal
            isOpen={true}
            onDismiss={() => onDismiss(null)}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog timeOffDialog"
        >
            <div className={contentStyles.header + " inventory-popup"} >
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Time Off</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => onDismiss(null)}
                />
            </div>
            
            {
                (timeValidationError) ?
                <div>
                    <Row>
                        <Col>
                            <p className="genericErrorColor" style={{paddingLeft: "35px", marginBottom: '0', marginTop: '10px'}}><span>{timeValidationError}</span></p>
                        </Col>
                    </Row>
                </div> : null
            }

            <div className={contentStyles.body+" deal-popup-body sheduleTimeOfOverflow"} >
                <div className="body-auto-hight sheduleTimeOfwidthDia ">
                   <Row style={{fontSize:"18px",textAlign:"left"}} className="dialogTitleName pageName">
                        <Col>
                             {selectedData?.name} 
                        </Col>
                   </Row>
                
                   <Row style={{fontSize:"18px",textAlign:"left"}} className=" dialogTitleName pageName">
                        <Col>
                             {selectedData?.location} 
                        </Col>
                   </Row>
                
                   <Row style={{fontSize:"18px",textAlign:"left"}} className=" dialogTitleName pageName">
                        <Col>
                             {convertUTCToLocalFormatModified(selectedData?.date, "YYYY-MM-DD")} 
                        </Col>
                   </Row>
                </div>

                <div className="body-auto-hight">
                   <Row>
                        <Col>
                              {/* <Checkbox
                                   label="Vaccation"
                                   id="vaccation"
                                   onChange={handleRoleCheckBoxChange}
                                   checked={formik.values.checkBox.includes("vaccation")}
                                   className="timeCheckBox"
                
                               />
                        </Col>
                        <Col>
                              <Checkbox
                                   label="Sat./Other"
                                   id="satAndOTher"
                                   onChange={handleRoleCheckBoxChange}
                                   checked={formik.values.checkBox.includes("satAndOTher")}
                                   className="timeCheckBox"
                               /> */}
                
                                <ChoiceGroup
                                    id="offType"
                                    className="timeOffradioGrp"
                                    name="offType"
                                    defaultSelectedKey={selectedData?.type === 2 ? 'false': 'true'}
                                    options={options}
                                    required={true}
                                    selectedKey={formik.values.offType.toString()}
                                    onChange={handleRoleCheckBoxChange}
                                    // label="Does this dealership have a service department or repair faciliity?" 
                                />

                        </Col>
                   </Row>
                </div>
            </div>
            <div className={contentStyles.footer + " inventory-popup-footer scheduleTimeOffFooter"}>
                 <Row className="inventoryRowSection  cusNoNotFoundFoot sheduleTimeOffButt">
                      <Col>
                          <PrimaryButton
                              style={{ float: "left",backgroundColor:"#af2b01",width:'50%'}}
                              onClick={handleDelete}
                              text="Delete"
                              className="btnHoverColor cusNoNotFoundDiaBtnHeight"
                          />
                      </Col>
                      <Col>
                          <PrimaryButton
                              text="Save"
                              style={{ float: "right",width:'50%' }}
                              onClick={handleSubmit}
                              className=" cusNoNotFoundDiaBtnHeight"
                          />

                      </Col>
                    </Row>
            </div>
        </Modal>
    );
};



