import React from "react";
import { Col, Container, Row } from "react-grid-system";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import { TextFieldWithLabelAndInfoIcon } from "components/textFieldWithLabelAndInfoIcon/TextFieldWithLabelAndInfoIcon";
import { styles } from "pages/dealSettingsPage/styles";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";

interface IProps {
  formik: any;
  calculateAndSetMonthlyPayment: () => void;
}

const BasicData: React.FC<IProps> = ({ formik }) => {
  // const handleVehiclePrice = (val) => {
  //   console.log("val val val val val val", val);
  //   // console.log()

  //   formik.setFieldValue("vehiclePrice", val.value);
  // };

  return (
    <Container style={styles.container} fluid>
      <Row gutterWidth={75}>
        <Col sm={12} md={6} xl={6}>
          <WithOptionalLabel>
            <label className="lbl-text">Vehicle Price</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="vehiclePrice"
              name="vehiclePrice"
              //label="Vehicle Price"
              prefix={PRICE_PREFIX}
              value={formik.values.vehiclePrice?formik.values.vehiclePrice.replace(/,/g, ''):''}
              // onChange={formik.handleChange}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "vehiclePrice": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
              // onValueChange={handleVehiclePrice}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Rebate/Discounts</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="discounts"
              name="discounts"
              //label="Rebate/Discounts"
              prefix={PRICE_PREFIX}
              value={formik.values.discounts?formik.values.discounts.replace(/,/g, ''):""}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "discounts": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Cash Down Payment</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="cashDownPayment"
              name="cashDownPayment"
              //label="Cash Down Payment"
              prefix={PRICE_PREFIX}
              value={formik.values.cashDownPayment?formik.values.cashDownPayment.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "cashDownPayment": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Trade-in Value</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="tradeInValue"
              name="tradeInValue"
              //label="Trade-in Value"
              prefix={PRICE_PREFIX}
              value={formik.values.tradeInValue?formik.values.tradeInValue.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "tradeInValue": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Payoff (Lien)</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="payoff"
              name="payoff"
             // label="Payoff (Lien)"
              prefix={PRICE_PREFIX}
              value={formik.values.payoff?formik.values.payoff.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "payoff": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Doc Fee</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="docFee"
              name="docFee"
              //label="Doc Fee"
              prefix={PRICE_PREFIX}
              value={formik.values.docFee?formik.values.docFee.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "docFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Title Filing Fee</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="titleFilingFee"
              name="titleFilingFee"
              //label="Title Filing Fee"
              prefix={PRICE_PREFIX}
              value={formik.values.titleFilingFee?formik.values.titleFilingFee.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "titleFilingFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
        </Col>
        <Col sm={12} md={6} xl={6}>
          <WithOptionalLabel>
            <label className="lbl-text">Registration/Plate Fee</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="registrationFee"
              name="registrationFee"
              //label="Registration/Plate Fee"
              prefix={PRICE_PREFIX}
              value={formik.values.registrationFee?formik.values.registrationFee.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "registrationFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Other Fees</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="otherFees"
              name="otherFees"
              //label="Other Fees"
              prefix={PRICE_PREFIX}
              value={formik.values.otherFees?formik.values.otherFees.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "otherFees": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Lien Fees</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="lienFee"
              name="lienFee"
              //label="Lien Fees"
              prefix={PRICE_PREFIX}
              value={formik.values.lienFee?formik.values.lienFee.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "lienFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>

          <WithOptionalLabel>
            <label className="lbl-text">Vehicle Service Contract</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="vehicleServiceContract"
              name="vehicleServiceContract"
              //label="Vehicle Service Contract"
              prefix={PRICE_PREFIX}
              value={formik.values.vehicleServiceContract?formik.values.vehicleServiceContract.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "vehicleServiceContract": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">GAP Fee</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="GAPFee"
              name="GAPFee"
              //label="GAP Fee"
              prefix={PRICE_PREFIX}
              value={formik.values.GAPFee?formik.values.GAPFee.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "GAPFee": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Tire & Wheel</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="tireAndWheel"
              name="tireAndWheel"
              //label="Tire & Wheel"
              prefix={PRICE_PREFIX}
              value={formik.values.tireAndWheel?formik.values.tireAndWheel.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "tireAndWheel": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
          <WithOptionalLabel>
            <label className="lbl-text">Other Products</label>
            <CurrencyInput
              customInput={TextFieldWithLabelAndInfoIcon}
              id="otherProducts"
              name="otherProducts"
              //label="Other Products"
              prefix={PRICE_PREFIX}
              value={formik.values.otherProducts? formik.values.otherProducts.replace(/,/g, ''):''}
              onValueChange ={(e) => {
                formik.setValues(
                    {
                        ...formik.values,
                        "otherProducts": e
                    }
                );
            }}
              onBlur={formik.handleBlur}
             // thousandSeparator={true}
            />
          </WithOptionalLabel>
        </Col>
      </Row>
    </Container>
  );
};

export default BasicData;
