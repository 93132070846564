import { IOption } from "../types/optionsTypes";
import muiThemeStyle from "./muiThemeStyle";
export const sizes = {
  DRAWER: 200,
};

export const colors = {
  grayText: "#A19F9D",
  lighterBlack: "#323130",
  black: "#000000",
  red: "#A80000",
  separator: "#EEEEEE",
  primaryBlue: "#0065CA"
};

export const rowsPerPageOptions = [
  { key: "5", value: "5", text: "5" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
];

export const rowsPerPageArray = [5,10,15,20,25];

export const queries = {
  tablet: { maxWidth: 1024 },
};

export const inventoryTypes = {
  statuses: [
    { id: 1, name: "Available" },
    { id: 2, name: "Reservation" },
    { id: 3, name: "In progress" },
    { id: 4, name: "Not available" },
  ],
  fuelTypes: [
    { id: 1, name: "diesel" },
    { id: 2, name: "gasoline" },
    { id: 3, name: "gas" },
    { id: 4, name: "hybrid" },
  ],
  fuelEconomyType: [
    { id: 1, name: "0 - 20 MPG" },
    { id: 2, name: "20 - 29 MPG" },
    { id: 3, name: "30 - 49 MPG" },
    { id: 4, name: "50 - 99 MPG" },
  ],
  getStatusName: (type) => {
    return type;
    // return inventoryTypes.statuses.find((status: any) => status.id === type)
    //   .name;
  },
  getFuelTypesName: (type) => {
    return type;
    // return inventoryTypes.fuelTypes.find((status: any) => status.id === type)
    //   .name;
  },
  getFuelEconomyName: (type) => {
    return type;
    // return inventoryTypes.fuelEconomyType.find(
    //   (status: any) => status.id === type
    // ).name;
  },
};

export const salutations: IOption[] = [
  { key: 1, value: 1, text: "Male" },
  { key: 2, value: 2, text: "Female" },
  // { key: 3, value: 3, text: "Other" },
];

export const LEAD_SOURCE = [
  {
    key: "Print Ad",
    text: "Print Ad",
    value: "Print Ad",
  },
  {
    key: "Our website",
    text: "Our website",
    value: "Our website",
  },
  {
    key: "Web Advertising",
    text: "Web Advertising",
    value: "Web Advertising",
  },
];

export const dealStatuses: IOption[] = [
  { key: "Inactive", value: 1, text: "Inactive" },
  { key: "Pending", value: 2, text: "Pending" },
  { key: "Active", value: 3, text: "Active" },
  { key: "Dead", value: 4, text: "Dead" },
];

export const dealTypes: IOption[] = [
  { key: 1, value: 1, text: "Lease" },
  { key: 2, value: 2, text: "Finance" },
  { key: 3, value: 3, text: "Cash Deal" },
];
export const getDealStatuses = (status: number) => {
  // return dealStatuses.find((stat: IOption) => stat.value === status).text;
};

export const getTypesStatuses = (type: number) => {
  return dealTypes.find((stat: IOption) => stat.value === type).text;
};

export const prospectStatuses: IOption[] = [
  { key: "new", value: 1, text: "new" },
  { key: "active", value: 2, text: "active" },
];

//Walk-In, Internet, Phone, SMS, Service

export const prospectLeadType: IOption[] = [
  { key: "Walk-In", value: "Walk-In", text: "Walk-In" },
  { key: "Internet", value: "Internet", text: "Internet" },
  { key: "Phone", value: "Phone", text: "Phone" },
  { key: "SMS", value: "SMS", text: "SMS" },
  { key: "Service", value: "Service", text: "Service" },
];

export const prospectLeadTypeFilters = [
  { key: "Walk-In", text: "Walk-In" },
  { key: "Internet", text: "Internet" },
  { key: "Phone", text: "Phone" },
  { key: "SMS", text: "SMS" },
  { key: "Service", text: "Service" },
];

export enum FavouriteEndpoints {
  prospect = "prospect",
  deal = "deal",
  inventory = "locations/inventory",
}

export enum FavouriteAction {
  add = "add",
  remove = "remove",
}

export enum DealStatusesEnum {
  inActive = "inActive",
  pending = "pending",
  active = "active",
  dead = "dead",
}

//public const TYPE_LEASEN = 1;
//public const TYPE_FINANCE = 2;
//public const TYPE_CASH_DEAL = 3;
export enum DealTypesEnum {
  lease = 1,
  finance,
  cashDeal,
}

export enum CheckIfProspectExistState {
  pending = "pending",
  exist = "exist",
  dontExist = "dontExist",
}

export const getDealsStatusText = (status: number) => {
  switch (status) {
    case 1:
      return "inActive";
    case 2:
      return "pending";
    case 3:
      return "active";
    case 4:
      return "dead";
  }
};

export enum permissionsModules {
  Inventory = "Inventory",
  Deals = "Deals",
  Prospects = "Prospects",
  Marketing = "Marketing",
  Location = "Location",
  Employee = "Employee",
  Auth = "Auth",
  Config = "Config",
  DealSettings = "DealSettings",
  DealerProfile = "DealerProfile",
  Brands = "Brands",
  Accounts = "Accounts",
  Settings = "Settings",
  SelectList = "SelectList",
  DealTracker = "DealTracker"
}

export enum emplyeePermissionsScreen {
  WORKBOOK_HOME = "workbook_home",
  WORKBOOK_SHOP = "workbook_shop",
  WORKBOOK_PURCHASE = "workbook_purchase",
  WORKBOOK_SOLD = "workbook_sold",
  SETTING_LOCATION_LIST = "setting_location_list",
  SELECTION_LIST_PAGE = "selection_list_page",
  SETTING_USER_LIST = "setting_user_list",
  SETTING_BUSINESS_INFO = "setting_business_info",
  SETTING_USER_VIEW = "setting_user_view",
  SETTING_LOCATION_VIEW = "setting_location_view",
  SETTING_BUSINESS_INFO_VIEW = "setting_business_info_view",
  DEPARTMENT_LIST = "department_list",
  LENDER_LIST = "lender_list",
  FINANCE_PRODUCT_LIST = "financeProduct_list",
  APPRAISAL_BIDDER_LIST = "appraisalBidder_list",
  SALES_TARGET = "sales_target"
}

export enum emplyeePermissionsAction {
  VEHICLE_ADD = "vehicle_add",
  VEHICLE_EDIT = "vehicle_edit",
  VEHICLE_ACQUIRE = "vehicle_acquire",
  VEHICLE_SELL = "vehicle_sell",
  VEHICLE_SEARCH = "vehicle_search",
  VEHICLE_TRANSITION_TO_ARCHIVE_APPRAISAL = "vehicle_transition_to_archive_appraisal",
  VEHICLE_TRANSITION_TO_ACTIVE_APPRAISAL = "vehicle_transition_to_active_appraisal",
  VEHICLE_ADD_EXPENSE = "vehicle_add_expense",
  VEHICLE_EDIT_EXPENSE = "vehicle_edit_expense",
  VEHICLE_DELETE_EXPENSE = "vehicle_delete_expense",
  VEHICLE_VIEW_EXPENSE = "vehicle_view_expense",
  VEHICLE_PRICE_HISTORY = "vehicle_price_history",
  VEHICLE_ADD_VIN = "vehicle_add_vin",
  VEHICLE_UPDATE_VALUES = "vehicle_update_values",
  VEHICLE_UPDATE_LIVE_RETAIL = "vehicle_update_live_retail",
  VEHICLE_UPDATE_CALCULATOR = "vehicle_update_calculator",
  VEHICLE_UPDATE_LIST_PRICE = "vehicle_update_list_price",
  VEHICLE_UPDATE_NOTE = "vehicle_update_note",
  VEHICLE_ADD_OPTION = "vehicle_add_option",
  VEHICLE_EDIT_OPTION = "vehicle_edit_option",
  SETTING_USER_ADD = "setting_user_add",
  SETTING_USER_VIEW = "setting_user_view",
  SETTING_USER_UPDATE = "setting_user_update",
  SETTING_USER_DELETE = "setting_user_delete",
  SETTING_LOCATION_ADD = "setting_location_add",
  SETTING_LOCATION_VIEW = "setting_location_view",
  SETTING_LOCATION_UPDATE = "setting_location_update",
  SETTING_LOCATION_DELETE = "setting_location_delete",
  SETTING_BUSINESS_INFO_UPDATE = "setting_business_info_update",
  DEPARTMENT_ADD = "department_add",
  DEPARTMENT_UPDATE = "department_update",
  DEPARTMENT_DELETE = "department_delete",
  DEPARTMENT_VIEW = "department_view",
  LENDER_ADD = "lender_add",
  LENDER_UPDATE = "lender_update",
  LENDER_DELETE = "lender_delete",
  LENDER_VIEW = "lender_view",
  FINANCE_PRODUCT_ADD = "financeProduct_add",
  FINANCE_PRODUCT_UPDATE = "financeProduct_update",
  FINANCE_PRODUCT_DELETE = "financeProduct_delete",
  FINANCE_PRODUCT_VIEW = "financeProduct_view",
  APPRAISAL_BIDDER_ADD = "appraisalBidder_add",
  APPRAISAL_BIDDER_UPDATE = "appraisalBidder_update",
  APPRAISAL_BIDDER_DELETE = "appraisalBidder_delete",
  APPRAISAL_BIDDER_VIEW = "appraisalBidder_view"
  
}

export enum sheduleDisplayPermissions {
  DEAL_SCHEDULE_DISPLAY_BOARD = "display_board",
}

export enum emplyeePermissionsTabs {
  VEHICLE_QVALUE  = "vehicle_qvalue",
  VEHICLE_SELLER  = "vehicle_seller",
  VEHICLE_PRICING = "vehicle_pricing",
  VEHICLE_VALUATION  = "vehicle_valuation",
  VEHICLE_OPTIONS = "vehicle_options",
}

export enum dealTrackerOperationPermissions {
    /***Write Ups***/
    DEAL_WRITE_UPS_CREATE = "deal_write_ups_create",
    DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD = "deal_write_ups_finance_product_add",
    DEAL_WRITE_UPS_FINANCE_PRODUCT_EDIT = "deal_write_ups_finance_product_edit",
    DEAL_WRITE_UPS_FINANCE_PRODUCT_DELETE = "deal_write_ups_finance_product_delete",
    DEAL_WRITE_UPS_FINANCE_PRODUCT_LIST = "deal_write_ups_finance_product_list",
    DEAL_WRITE_UPS_FINANCE_PRODUCT_VIEW = "deal_write_ups_finance_product_view",
    DEAL_WRITE_UPS_UPDATE = "deal_write_ups_update",

/***Trade-In***/
    DEAL_TRADE_IN_ADD_VEHICLE = "deal_trade_in_add_vehicle",
    DEAL_TRADE_IN_ADD_VALUATION = "deal_trade_in_add_valuation",
    DEAL_TRADE_IN_DELETE = "deal_trade_in_delete",

/****Task*****/
    DEAL_TASK_IS_COMPLETED = "deal_task_is_completed"
}

export enum scheduleOperationPermissions {
  DEAL_SCHEDULE_FINANCE_UPDATE = "deal_schedule_finance_update",
  DEAL_SCHEDULE_FINANCE_DELETE = "deal_schedule_finance_delete",
  DEAL_SCHEDULE_DC_UPDATE = "deal_schedule_dc_update",
  DEAL_SCHEDULE_DC_DELETE = "deal_schedule_dc_delete",
  DEAL_SCHEDULE_INITIATE = "deal_schedule_initiate",
  DEAL_SCHEDULE_MONTHLY_CALENDAR = "monthly_calender",
  DEAL_SCHEDULE_MONTHLY_CALENDAR_UPDATE = "monthly_calender_update",
  DEAL_SCHEDULE_TIME_DISABLED = "deal_schedule_time_disabled",

}
  


export enum ProspectType {
  Prospect = 1,
  Cosigner,
}

export enum PartnerSettingsStatus {
  configured,
  requiresConfiguration,
  disabled,
}

// export const getPermissionModuleName = (module: permissionsModules) => {
//
//
//   return
// }

export const stateNames = [
  {
    text: "Eastern",
    key: "Eastern",
    value: "Eastern",
  },
  {
    text: "Pacific Northwest",
    key: "Pacific_Northwest",
    value: "Pacific_Northwest",
  },
  {
    text: "Southwestern",
    key: "Southwestern",
    value: "Southwestern",
  },
  {
    text: "Midwest",
    key: "Midwest",
    value: "Midwest",
  },
  {
    text: "Central",
    key: "Central",
    value: "Central",
  },
  {
    text: "Southeastern",
    key: "Southeastern",
    value: "Southeastern",
  },
  {
    text: "New England",
    key: "New_England",
    value: "New_England",
  },
  {
    text: "Desert Southwest",
    key: "Desert_Southwest",
    value: "Desert_Southwest",
  },
  {
    text: "Mountain",
    key: "Mountain",
    value: "Mountain",
  },
  {
    text: "California",
    key: "California",
    value: "California",
  },
  {
    text: "Connecticut",
    key: "Connecticut",
    value: "Connecticut",
  },
  {
    text: "Delaware",
    key: "Delaware",
    value: "Delaware",
  },
  {
    text: "District Of Columbia",
    key: "District_Of_Columbia",
    value: "District_Of_Columbia",
  },
  {
    text: "Maryland",
    key: "Maryland",
    value: "Maryland",
  },
  {
    text: "New Jersey",
    key: "New_Jersey",
    value: "New_Jersey",
  },
  {
    text: "New York",
    key: "New_York",
    value: "New_York",
  },
  {
    text: "Pennsylvania",
    key: "Pennsylvania",
    value: "Pennsylvania",
  },
  {
    text: "Virginia",
    key: "Virginia",
    value: "Virginia",
  },
  {
    text: "West Virginia",
    key: "West_Virginia",
    value: "West_Virginia",
  },
  {
    text: "Alaska",
    key: "Alaska",
    value: "Alaska",
  },
  {
    text: "Idaho",
    key: "Idaho",
    value: "Idaho",
  },
  {
    text: "Oregon",
    key: "Oregon",
    value: "Oregon",
  },
  {
    text: "Utah",
    key: "Utah",
    value: "Utah",
  },
  {
    text: "Washington",
    key: "Washington",
    value: "Washington",
  },
  {
    text: "Arkansas",
    key: "Arkansas",
    value: "Arkansas",
  },
  {
    text: "Louisiana",
    key: "Louisiana",
    value: "Louisiana",
  },
  {
    text: "Oklahoma",
    key: "Oklahoma",
    value: "Oklahoma",
  },
  {
    text: "Texas",
    key: "Texas",
    value: "Texas",
  },
  {
    text: "Iowa",
    key: "Iowa",
    value: "Iowa",
  },
  {
    text: "Kansas",
    key: "Kansas",
    value: "Kansas",
  },
  {
    text: "Minnesota",
    key: "Minnesota",
    value: "Minnesota",
  },
  {
    text: "Nebraska",
    key: "Nebraska",
    value: "Nebraska",
  },
  {
    text: "North Dakota",
    key: "North_Dakota",
    value: "North_Dakota",
  },
  {
    text: "South Dakota",
    key: "South_Dakota",
    value: "South_Dakota",
  },
  {
    text: "Illinois",
    key: "Illinois",
    value: "Illinois",
  },
  {
    text: "Indiana",
    key: "Indiana",
    value: "Indiana",
  },
  {
    text: "Kentucky",
    key: "Kentucky",
    value: "Kentucky",
  },
  {
    text: "Michigan",
    key: "Michigan",
    value: "Michigan",
  },
  {
    text: "Missouri",
    key: "Missouri",
    value: "Missouri",
  },
  {
    text: "Ohio",
    key: "Ohio",
    value: "Ohio",
  },
  {
    text: "Wisconsin",
    key: "Wisconsin",
    value: "Wisconsin",
  },
  {
    text: "Alabama",
    key: "Alabama",
    value: "Alabama",
  },
  {
    text: "Florida",
    key: "Florida",
    value: "Florida",
  },
  {
    text: "Georgia",
    key: "Georgia",
    value: "Georgia",
  },
  {
    text: "Mississippi",
    key: "Mississippi",
    value: "Mississippi",
  },
  {
    text: "North Carolina",
    key: "North_Carolina",
    value: "North_Carolina",
  },
  {
    text: "South Carolina",
    key: "South_Carolina",
    value: "South_Carolina",
  },
  {
    text: "Tennessee",
    key: "Tennessee",
    value: "Tennessee",
  },
  {
    text: "Maine",
    key: "Maine",
    value: "Maine",
  },
  {
    text: "Massachusetts",
    key: "Massachusetts",
    value: "Massachusetts",
  },
  {
    text: "New Hampshire",
    key: "New_Hampshire",
    value: "New_Hampshire",
  },
  {
    text: "Rhode Island",
    key: "Rhode_Island",
    value: "Rhode_Island",
  },
  {
    text: "Vermont",
    key: "Vermont",
    value: "Vermont",
  },
  {
    text: "Arizona",
    key: "Arizona",
    value: "Arizona",
  },
  {
    text: "Nevada",
    key: "Nevada",
    value: "Nevada",
  },
  {
    text: "Colorado",
    key: "Colorado",
    value: "Colorado",
  },
  {
    text: "Montana",
    key: "Montana",
    value: "Montana",
  },
  {
    text: "New Mexico",
    key: "New_Mexico",
    value: "New_Mexico",
  },
  {
    text: "Wyoming",
    key: "Wyoming",
    value: "Wyoming",
  },
  {
    text: "Hawaii",
    key: "Hawaii",
    value: "Hawaii",
  },
];

export const financialInformationItemList = [
  "isInvoice",
  "isLeasing",
  "msrp",
  "bookValue",
];

export const engineAndDriveItemList = [
  "driveType",
  "fuelType",
  "fuelEconomy",
  "engineCylinders",
  "engineDescription",
  "transmissionDescription",
];

export const personalInformationItemList = [
  "homePhoneNumber",
  "workPhoneNumber",
  "gender",
  "currentAddress1",
  "currentAddress2",
  "city",
  "zip",
  "state",
  "suffix",
  "driverLicense",
  "socialSecurityNumber",
];

export const vehicleItemList = [
  "vehicleCondition",
  "vehicleMake",
  "vehicleModel",
  "monthlyIncome",
  "vehicleTrim",
  "vehicleMaxPrice",
  "vehicleMinYear",
  "vehicleMileage",
  "vehicleBodyType",
  "vehicleExteriorColor",
  "vehicleInterested",
  "vehicleNote",
];
export const tradeItemList = [
  "tradeMake",
  "tradeModel",
  "tradeTrim",
  "tradeYear",
  "tradeVin",
];
export const employmentItemList = [
  "employerName",
  "lenthAtJob",
  "gender",
  "monthlyIncome",
  "employerAddress1",
  "employerAddress2",
  "employerCity",
  "employerZip",
  "state",
];

export const schedulerValues = ["Overdue", "Today", "Tomorrow", "Incoming"].map(
  (item) => {
    return { key: item, text: item };
  }
);

/** it's important for stringFromCurrencyFormat function that  price prefix has empty sign after $ */
export const PRICE_PREFIX = "$";

export enum profileTypes {
    PROFILE_TYPE_DEFAULT = "PROFILE_TYPE_DEFAULT",
    PROFILE_TYPE_KARL = "PROFILE_TYPE_KARL"
}

export const karlProfileURLKeys = ['business-info', 'users', 'locations', 'dashboard', 'dealtracker',
    ':dealID/dealtracker', 'departments', 'finance-products', 'lenders', 'appraisal-bidders',
    'add-location-detail', ':locationID/location-detail', 'appointments', 'appraisals','appraisalsV2','tasks', 'service-contract',
    'sales-goal', 'scheduling', 'cancellations', 'search-inventory', 'search-inventory-v1', 'vehicle-prep', 'dealer-trade', 'write-ups',
    'finance-product-sales-reports','finance-desking-reports','finance-person-lender-count-reports',
    'finance-reserve-splits-reports','finance-product-splits-reports','finance-person-month-end-reports','pending-reports',
    'finance-stack-rank', 'doc-reports', 'sales-finance-reports', 'sales-month-end-reports', 'cdr-cpo-reports','DisplayBoardWorkbook'
    ,'vacation-reports','sales-pending-reports','finance-detail-reports','Finance-Pending-Report','vehicle-tracker-workbook-page',':vehId/vehicle-tracker-page'];
export const defaultProfileURLKeys = ['dashboard', 'appraisal', 'acquired', 'sold', 'business-info', 'users', 'locations', 'inventory', 'settings'];


export const trimTypes = {
    nonKarl: 1,
    karl: 2,
    tradeIn: 3,
    editDelivery: 4,
};

export const themeStyles = {
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: muiThemeStyle.typography?.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: muiThemeStyle.typography?.pxToRem(15),
    color: muiThemeStyle.palette?.text.secondary,
  },
  textFieldSmall: {
    "& .ms-TextField-fieldGroup,& .auctionStartTime .MuiInput-formControl .shopInventoryEdit ": {
      width: "125px !important",
    },
  },
  shopInventoryEdit: {
    paddingTop: "0px !important",
  },
  button: {
    marginRight: muiThemeStyle?.spacing?.(1),
  },
  instructions: {
    marginTop: muiThemeStyle?.spacing?.(1),
    marginBottom: muiThemeStyle?.spacing?.(1),
  },
};