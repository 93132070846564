import React, { useState, FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack
} from "@fluentui/react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-grid-system";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handlePurchaseVehicle } from "store/inventory/inventory.action";
import { Calendar } from 'primereact/calendar';
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
import { convertLocalToUTCModified } from "utils/dateTimeFunctions";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    vehicleId: number | string;
}

const yearRange = "1980:" + (new Date().getFullYear()+25);
export const PurchaseTransitionDialog: FunctionComponent<IProps> = ({
    onDismiss,
    vehicleId
}) => {

    const [purchaseDate, setPurchaseDate] = useState<Date | Date[] | undefined |any>(new Date());
    const [blurFlag, setBlurFlag] = useState(false);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            acquisitionDate: "",
            purchasePrice: "",
            initialListPrice: "",
            //dealerPackPrice: "",
            stockNumber: ""
        },
        validationSchema: Yup.object({
            acquisitionDate: Yup.string().nullable().test("rules", "Required.", (val) => {
                return val !== null;
            }),
            purchasePrice: Yup.string().required("Required"),
            initialListPrice: Yup.string().required("Required"),
            //dealerPackPrice: Yup.string()
        }),
        onSubmit: (values) => {
            const purchaseInfo = {
                acquisitionDate: purchaseDate ? convertLocalToUTCModified(purchaseDate) : convertLocalToUTCModified(new Date()),
                purchasePrice: values.purchasePrice.replace('$', '').replace(',', ''),
                //dealerPack: values.dealerPackPrice.replace('$', '').replace(',', ''),
                initialListPrice: values.initialListPrice.replace('$', '').replace(',', ''),
                stockNumber: values.stockNumber.replace(/\D+/g, ''),
                id: vehicleId,
            };
            dispatch<any>(handlePurchaseVehicle(purchaseInfo, (res) => {
                onDismiss(res);
            }));
        },
    });


    const handleSavePurchase = () => {
        formik.handleSubmit();
    };

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                'acquisitionDate': val
            }
        );
        setPurchaseDate(val);
    };

    const handleDateBlur = () => {
        setBlurFlag(true);
    }

    // const handlePriceChange = (e) => {
    //     formik.setValues(
    //         {
    //             ...formik.values,
    //             [e.target.id]: e.target.value
    //         }, true
    //     ); 
    // };

    const handleClosePopup = () => {
        onDismiss('');
    }

    return (
        <Modal
            isOpen={true}
            onDismiss={handleClosePopup}
            isBlocking={true}
            containerClassName={contentStyles.container +' inv-srch-mod expense-modal'}
        >
            <div className={contentStyles.header + " inventory-popup  modal-header"}>
                <span className="formTitle">Purchase Vehicle</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleClosePopup}
                />
            </div>
            <div style={{overflow:"hidden"}} className={`${contentStyles.body} inv-srch-bdy body-auto-hight`}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                    gap="row gap"
                >
                   {/*  <div> */}
                        {/* <div style={{ margin: "0px 70px" }}> */}
                            <Row className="purchase-row" justify="center">
                                {/*<Col sm={5} md={5} xs={6} className="pad-right-5" >
                                    <TextField
                                        id="stockNumber"
                                        name="stockNumber"
                                        label="Stock No"
                                        value={formik.values.stockNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Col>*/}

                            <Col sm={10} md={10} xs={12}>
                                    <div className="ms-TextField">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Purchase Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup p-datepicker-right">
                                                <Calendar
                                                    id="navigatorstemplate"
                                                    //className="modal-calendar"
                                                    value={purchaseDate}
                                                    style={{ width: "100%" }}
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e.value)}
                                                    onBlur={handleDateBlur}
                                                    monthNavigator
                                                    required={true}
                                                    appendTo="self"
                                                    yearRange={yearRange}
                                                    yearNavigator />
                                            </div>
                                            <span className="error-message">
                                                {blurFlag && formik.errors && formik.errors.acquisitionDate &&
                                                    formik.errors.acquisitionDate.toString()}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="purchase-row" justify="center">
                                <Col sm={10} md={10} xs={12}>
                                    <label className="lbl-text required-Label">Purchase Price</label>
                                    <CurrencyInput
                                        allowNegativeValue={false}
                                        customInput={TextField}
                                       // thousandSeparator={true}
                                        prefix={PRICE_PREFIX}
                                        id="purchasePrice"
                                        name="purchasePrice"
                                        //label="Purchase Price"
                                        decimalScale={2}
                                        //required={true}
                                        value={formik.values.purchasePrice?formik.values.purchasePrice.replace(/,/g, ''):''}
                                        onValueChange ={(e) => {
                                            formik.setValues(
                                                {
                                                    ...formik.values,
                                                    "purchasePrice": e
                                                }
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="error-message">
                                        {formik.touched.purchasePrice &&
                                            formik.errors.purchasePrice &&
                                            formik.errors.purchasePrice.toString()}
                                    </span>
                                </Col>
                            </Row>
                    {/*<Row className="purchase-row" justify="center">
                                <Col sm={10} md={10} xs={12}>
                                    <CurrencyInput
                                        allowNegativeValue={false}
                                        customInput={TextField}
                                       // thousandSeparator={true}
                                        prefix={PRICE_PREFIX}
                                        id="dealerPackPrice"
                                        name="dealerPackPrice"
                                        label="Dealer Pack"
                                        decimalScale={2}
                                        //required={true}
                                        value={formik.values.dealerPackPrice}
                                        onChange={(e) => handlePriceChange(e)}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="error-message">
                                        {formik.touched.dealerPackPrice &&
                                            formik.errors.dealerPackPrice &&
                                            formik.errors.dealerPackPrice.toString()}
                                    </span>
                                </Col>
                            </Row>*/}
                            <Row className="purchase-row"  justify="center">
                                <Col sm={10} md={10} xs={12}>
                                   <label className="lbl-text required-Label">Initial List Price</label>
                                    <CurrencyInput
                                        allowNegativeValue={false}
                                        customInput={TextField}
                                       // thousandSeparator={true}
                                        prefix={PRICE_PREFIX}
                                        id="initialListPrice"
                                        name="initialListPrice"
                                        //label="Initial List Price"
                                        decimalScale={2}
                                        //required={true}
                                        value={formik.values.initialListPrice?formik.values.initialListPrice.replace(/,/g, ''):""}
                                        onValueChange ={(e) => {
                                            formik.setValues(
                                                {
                                                    ...formik.values,
                                                    "initialListPrice": e
                                                }
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="error-message">
                                        {formik.touched.initialListPrice &&
                                            formik.errors.initialListPrice &&
                                            formik.errors.initialListPrice.toString()}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="purchase-row">
                                <Col style={{ textAlign:"center" }} sm={12} md={12} xs={12}>
                                    <PrimaryButton
                                        className="decodeBtn"
                                        disabled={!formik.dirty || !formik.isValid}
                                        text="Save"
                                        onClick={handleSavePurchase}
                                    />
                                </Col>
                            </Row>
                        {/* </div> */}

                   {/*  </div> */}
                </Stack>
            </div>


        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
