import React, { useState, useEffect } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  DefaultPalette,
  IconButton,
  Button,
  Stack,
  ActionButton,
  mergeStyles,
  IButtonStyles,
  SearchBox,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ViewAdditionalActionsDropdown } from "../../table/dropdowns/ViewAdditionalActionsDropdown";
import { IDeal } from "types/dealTypes";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { dealStatuses, dealTypes } from "constants/constants";
// import { Pagination } from "components/table/pagination/Pagination";

import {
  Dropdown /*, IDropdownStyles */,
} from "@fluentui/react/lib/Dropdown";
import { IOption } from "types/optionsTypes";
import { formatDate } from "utils/dateTimeFunctions";
import { fullLengthDropdownStyles, cellLineHeight } from "constants/styles";
import {
  DetailsList,
  DetailsListLayoutMode,
  IDetailsListStyles,
  // SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
  searchStyle as searchStyles,
} from "constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
// import { IDeals } from "types/dealTypes";
import { handleGetDeals } from "store/deals/deals.action";
import { IPagination } from "types/paginationTypes";
import { getModulePermissions } from "utils/permissions/permissionsHelpers";
import { IProspect } from "types/prospectTypes";

const searchStyle = searchStyles();
const contentStyles = content();

interface IDocument {
  id: number;
  key: string;
  createdAt: string;
  type: number;
  status: number;
}

const cellMaxHeight: string = "35px";

const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: 540,
  },
};

const stackStyles: IStackStyles = {
  root: {},
};

const cellStyles: IStackStyles = {
  root: {
    //height: height,
    lineHeight: cellLineHeight,
  },
};

const textStyles = mergeStyles({
  fontSize: 12,
});

interface IProps {
  onDismiss: () => void;
  // dealsData: IDeal[];
  view?: boolean;
  prospectId: number;
  formik?: any;
  handleEditProspect?: () => void;
  prospect?: IProspect;
}

export const EditProspectDealsDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  // dealsData,
  view,
  prospectId,
  formik,
  handleEditProspect,
  prospect,
}) => {
  const [items, setItems] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [hovered, setHovered] = useState(null);
  // const deals: IDeals = useSelector((state: AppState) => state.deals.items);
  const [deals, setDeals] = useState([]);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const dispatch = useDispatch();

  /**@FIXME: change into global?*/
  const [localLimit, setLocalLimit] = useState(5);

  useEffect(() => {
    setLocalLimit(5);
  }, []);

  // useEffect(() => {
  //   if (deals) {
  //     console.log(deals);
  //     setItems([...deals.results]);
  //   } else setItems([]);
  // }, [deals]);

  useEffect(() => {
    setItems(prospect.deals);
    setDeals(prospect.deals);
  }, [prospect]);

  useEffect(() => {
    if (prospectId) {
      const request: IPagination = {
        page: 1,
        limit: localLimit,
        parentId: prospectId,
      };
      dispatch<any>(handleGetDeals(request));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectId]);

  const handleChangeStatus = (e, status) => {
    setSelectedStatus(status.value);

    // const lDeals = deals.results.filter(
    //   (deal: IDeal) => deal.status === status.value
    // );

    const lDeals = deals?.filter((deal: IDeal) => deal.status === status.value);

    setItems([...lDeals]);
  };

  const getLabel = (array, value) => {
    const arr = array.find((item: IOption) => item.key === value);

    return arr ? arr.text : "none";
  };

  // const handleRowsNumberChange = (rows) => {
  //   const request: IPagination = {
  //     page: 1,
  //     limit: rows,
  //     parentId: prospectId,
  //   };
  //   setLocalLimit(rows);
  //   dispatch<any>(handleGetDeals(request));
  // };
  //
  // const handlePageChange = (page) => {
  //   const request: IPagination = {
  //     page,
  //     limit: localLimit,
  //     parentId: prospectId,
  //   };
  //   dispatch<any>(handleGetDeals(request));
  // };

  /**===========================COLUMNS==========================================================*/

  const columns: any[] = [
    {
      key: "createdAt",
      name: "Date",
      fieldName: "createdAt",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: false,
      onRender: (item: IDocument) => {
        return <span className={textStyles}>{formatDate(item.createdAt)}</span>;
      },
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      onRender: (item: IDocument) => {
        return (
          <span className={textStyles}>{getLabel(dealTypes, item.type)}</span>
        );
      },
      isPadded: false,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      // onColumnClick: _onColumnClick,
      data: "string",
      onRender: (item: IDocument) => {
        return <span>{getLabel(dealStatuses, item.status)}</span>;
      },
      isPadded: false,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "action",
      minWidth: 50,
      maxWidth: 50,

      isResizable: false,
      // onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        return (
          <>
            {hovered === item.id ? (
              <ActionButton
                style={{ padding: 0, margin: 0, maxHeight: cellMaxHeight }}
                styles={actionButton}
                // onClick={() => this._handleView(item.id)}
                iconProps={{ iconName: "RedEye" }}
              >
                View
              </ActionButton>
            ) : null}
          </>
        );
      },
      isPadded: false,
    },
  ];

  /**============================================================================================================*/
  const onColumnClick = (e, column) => {
    console.log(e, column);
  };
  return (
    <>
      items &&{" "}
      <Modal
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={` ${contentStyles.header}`}>
          {!view && <GoBackToMainDialogBtn label="" onClick={onDismiss} />}
          <CustomFontText>Deals</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack
            styles={stackStyles}
            horizontal
            verticalAlign="start"
            horizontalAlign="space-between"
          >
            <Dropdown
              selectedKey={selectedStatus}
              onChange={handleChangeStatus}
              options={dealStatuses}
              placeHolder="any..."
              styles={fullLengthDropdownStyles}
            />
          </Stack>
          <Stack horizontalAlign="end">
            <SearchBox
              className={searchStyle}
              placeholder="search in table ..."
              iconProps={{ styles: { root: { color: DefaultPalette.black } } }}
              onSearch={(newValue) => console.log("value is: " + newValue)}
            />
          </Stack>
          <DetailsList
            compact={true}
            styles={tableStyles}
            items={items}
            columns={columns}
            // onRenderDetailsFooter={() => (
            //   <Pagination
            //     rowsPerPage={localLimit}
            //     currentPage={deals.currentPage.toString()}
            //     totalItems={deals.totalItems}
            //     onRowsNumberChange={handleRowsNumberChange}
            //     onPageChange={handlePageChange}
            //     showPageInput={false}
            //   />
            // )}
            onRenderRow={(props, defaultRender) => {
              return (
                <Stack
                  styles={cellStyles}
                  className="centered"
                  verticalAlign="center"
                >
                  <div
                    onMouseEnter={() => setHovered(props.item.id)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    {defaultRender(props)}
                  </div>
                </Stack>
              );
            }}
            // onRenderCheckbox={(props, defaultRender) => {
            //     return <span>{defaultRender(props)}</span>;
            // }}
            selectionMode={0}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onColumnHeaderClick={(ev, column) => onColumnClick(ev, column)}
            selectionPreservedOnEmptyClick={true}
            onItemInvoked={() => {}}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
          />
        </div>
        {!view && (
          <div className={contentStyles.footer}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
            >
              <div>
                <ViewAdditionalActionsDropdown
                  modulePermissions={getModulePermissions(
                    user.auths || [],
                    "Inventory"
                  )}
                />
              </div>
              <div>
                <Button
                  text="Close"
                  style={{ marginRight: "10px" }}
                  onClick={() => onDismiss()}
                />
                <PrimaryButton
                  text="Save Changes"
                  disabled={!formik.isValid}
                  onClick={handleEditProspect}
                />
              </div>
            </Stack>
          </div>
        )}
      </Modal>
    </>
  );
};
