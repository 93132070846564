import {
  IHandleFavourite,
  IEmail,
  ISMS,
  ISMTPSettings,
  ISMSSettings,
  IConnection,
  IPickerItem,
} from "types/sharedTypes";
import { FavouriteAction } from "constants/constants";

/** ADD_FAVOURITE */
export const HANDLE_ZIPCODE_FLATDATA = "HANDLE_ZIPCODE_FLATDATA";

export interface HandleZipCodeFlatData {
  type: typeof HANDLE_ZIPCODE_FLATDATA;
  payload: {
    zipCode: any;
    callback: Function;
  };
}

/** UPDATE_FAVOURITE */
export const HANDLE_UPDATE_FAVOURITE = "HANDLE_UPDATE_FAVOURITE";

export interface HandleUpdateFavourite {
  type: typeof HANDLE_UPDATE_FAVOURITE;
  payload: {
    handleData: IHandleFavourite[];
    callback: Function;
    action: FavouriteAction;
  };
}

export const UPDATE_FAVOURITE_PENDING = "UPDATE_FAVOURITE_PENDING";

export interface UpdateFavouritePending {
  type: typeof UPDATE_FAVOURITE_PENDING;
}

export const UPDATE_FAVOURITE_RESOLVED = "UPDATE_FAVOURITE_RESOLVED";

export interface UpdateFavouriteResolved {
  type: typeof UPDATE_FAVOURITE_RESOLVED;
}

export const UPDATE_FAVOURITE_REJECTED = "UPDATE_FAVOURITE_REJECTED";

export interface UpdateFavouriteRejected {
  type: typeof UPDATE_FAVOURITE_REJECTED;
  payload: object[];
}

/** ADD_FAVOURITE */
export const HANDLE_ADD_FAVOURITE = "HANDLE_ADD_FAVOURITE";

export interface HandleAddFavourite {
  type: typeof HANDLE_ADD_FAVOURITE;
  payload: { handleData: IHandleFavourite[]; callback: Function };
}

export const ADD_FAVOURITE_PENDING = "ADD_FAVOURITE_PENDING";

export interface AddFavouritePending {
  type: typeof ADD_FAVOURITE_PENDING;
}

export const ADD_FAVOURITE_RESOLVED = "ADD_FAVOURITE_RESOLVED";

export interface AddFavouriteResolved {
  type: typeof ADD_FAVOURITE_RESOLVED;
}

export const ADD_FAVOURITE_REJECTED = "ADD_FAVOURITE_REJECTED";

export interface AddFavouriteRejected {
  type: typeof ADD_FAVOURITE_REJECTED;
  payload: object[];
}

/** REMOVE_FAVOURITE */
export const HANDLE_REMOVE_FAVOURITE = "HANDLE_REMOVE_FAVOURITE";

export interface HandleRemoveFavourite {
  type: typeof HANDLE_REMOVE_FAVOURITE;
  payload: { handleData: IHandleFavourite[]; callback: Function };
}

export const REMOVE_FAVOURITE_PENDING = "REMOVE_FAVOURITE_PENDING";

export interface RemoveFavouritePending {
  type: typeof REMOVE_FAVOURITE_PENDING;
}

export const REMOVE_FAVOURITE_RESOLVED = "REMOVE_FAVOURITE_RESOLVED";

export interface RemoveFavouriteResolved {
  type: typeof REMOVE_FAVOURITE_RESOLVED;
}

export const REMOVE_FAVOURITE_REJECTED = "REMOVE_FAVOURITE_REJECTED";

export interface RemoveFavouriteRejected {
  type: typeof REMOVE_FAVOURITE_REJECTED;
  payload: object[];
}
/** GET_SMTP_SETTINGS */
export const HANDLE_GET_SMTP_SETTINGS = "HANDLE_GET_SMTP_SETTINGS";
export interface HandleGetSMTPSettings {
  type: typeof HANDLE_GET_SMTP_SETTINGS;
}

export const GET_SMTP_SETTINGS_PENDING = "GET_SMTP_SETTINGS_PENDING";

export interface GetSMTPSettingsPending {
  type: typeof GET_SMTP_SETTINGS_PENDING;
}

export const GET_SMTP_SETTINGS_RESOLVED = "GET_SMTP_SETTINGS_RESOLVED";

export interface GetSMTPSettingsResolved {
  type: typeof GET_SMTP_SETTINGS_RESOLVED;
  payload: ISMTPSettings;
}

export const GET_SMTP_SETTINGS_REJECTED = "GET_SMTP_SETTINGS_REJECTED";

export interface GetSMTPSettingsRejected {
  type: typeof GET_SMTP_SETTINGS_REJECTED;
  payload: object[];
}

/** UPDATE_SMTP_SETTINGS */
export const HANDLE_UPDATE_SMTP_SETTINGS = "HANDLE_UPDATE_SMTP_SETTINGS";
export interface HandleUpdateSMTPSettings {
  type: typeof HANDLE_UPDATE_SMTP_SETTINGS;
  payload: ISMTPSettings;
}

export const UPDATE_SMTP_SETTINGS_PENDING = "UPDATE_SMTP_SETTINGS_PENDING";

export interface UpdateSMTPSettingsPending {
  type: typeof UPDATE_SMTP_SETTINGS_PENDING;
}

export const UPDATE_SMTP_SETTINGS_RESOLVED = "UPDATE_SMTP_SETTINGS_RESOLVED";

export interface UpdateSMTPSettingsResolved {
  type: typeof UPDATE_SMTP_SETTINGS_RESOLVED;
  payload: ISMTPSettings;
}

export const UPDATE_SMTP_SETTINGS_REJECTED = "UPDATE_SMTP_SETTINGS_REJECTED";

export interface UpdateSMTPSettingsRejected {
  type: typeof UPDATE_SMTP_SETTINGS_REJECTED;
  payload: object[];
}

/** SEND_EMAIL */
export const HANDLE_SEND_EMAIL = "HANDLE_SEND_EMAIL";

export interface HandleSendEmail {
  type: typeof HANDLE_SEND_EMAIL;
  payload: { email: IEmail; callback: () => void };
}

export const SEND_EMAIL_PENDING = "SEND_EMAIL_PENDING";

export interface SendEmailPending {
  type: typeof SEND_EMAIL_PENDING;
}

export const SEND_EMAIL_RESOLVED = "SEND_EMAIL_RESOLVED";

export interface SendEmailResolved {
  type: typeof SEND_EMAIL_RESOLVED;
}

export const SEND_EMAIL_REJECTED = "SEND_EMAIL_REJECTED";

export interface SendEmailRejected {
  type: typeof SEND_EMAIL_REJECTED;
  payload: object[];
}

/** Test Connection */
export const HANDLE_TEST_CONNECTION = "HANDLE_TEST_CONNECTION";
export interface HandleTestConnection {
  type: typeof HANDLE_TEST_CONNECTION;
  payload: any;
}

export const TEST_CONNECTION_PENDING = "TEST_CONNECTION_PENDING";
export interface TestConnectionPending {
  type: typeof TEST_CONNECTION_PENDING;
}
export const TEST_CONNECTION_RESOLVED = "TEST_CONNECTION_RESOLVED";
export interface TestConnectionResolved {
  type: typeof TEST_CONNECTION_RESOLVED;
  payload: IConnection;
}
export const TEST_CONNECTION_REJECTED = "TEST_CONNECTION_REJECTED";
export interface TestConnectionRejected {
  type: typeof TEST_CONNECTION_REJECTED;
}

/** SEND_SMS */
export const HANDLE_SEND_SMS = "HANDLE_SEND_SMS";

export interface HandleSendSMS {
  type: typeof HANDLE_SEND_SMS;
  payload: { sms: ISMS; callback: () => void };
}

export const SEND_SMS_PENDING = "SEND_SMS_PENDING";

export interface SendSMSPending {
  type: typeof SEND_SMS_PENDING;
}

export const SEND_SMS_RESOLVED = "SEND_SMS_RESOLVED";

export interface SendSMSResolved {
  type: typeof SEND_SMS_RESOLVED;
}

export const SEND_SMS_REJECTED = "SEND_SMS_REJECTED";

export interface SendSMSRejected {
  type: typeof SEND_SMS_REJECTED;
  payload: object[];
}

// ---------- GET MAKES ----------
export const HANDLE_GET_MAKES = "HANDLE_GET_MAKES";
export interface HandleGetMakes {
  type: typeof HANDLE_GET_MAKES;
}

export const HANDLE_GET_DATAONE_MAKES = "HANDLE_GET_DATAONE_MAKES";
export interface HandleGetDataOneMakes {
  type: typeof HANDLE_GET_DATAONE_MAKES;
  payload: any;
}

export const HANDLE_GET_DATAONE_MAKES_RESOLVED = "HANDLE_GET_DATAONE_MAKES_RESOLVED";
export interface HandleGetDataOneMakesResolved {
  type: typeof HANDLE_GET_DATAONE_MAKES_RESOLVED;
  payload: any;
}

export const GET_MAKES_PENDING = "GET_MAKES_PENDING";
export interface GetMakesPending {
  type: typeof GET_MAKES_PENDING;
}
export const GET_MAKES_RESOLVED = "GET_MAKES_RESOLVED";
export interface GetMakesResolved {
  type: typeof GET_MAKES_RESOLVED;
  payload: IPickerItem[];
}
export const GET_MAKES_REJECTED = "GET_MAKES_REJECTED";
export interface GetMakesRejected {
  type: typeof GET_MAKES_REJECTED;
  payload: string | object;
}

// ---------- GET MODELS ----------
export const HANDLE_GET_MODELS = "HANDLE_GET_MODELS";
export interface HandleGetModels {
  type: typeof HANDLE_GET_MODELS;
  payload: string;
}

export const HANDLE_GET_DATAONE_MODELS = "HANDLE_GET_DATAONE_MODELS";
export interface HandleGetDataOneModels {
  type: typeof HANDLE_GET_DATAONE_MODELS;
  payload: any;
}

export const HANDLE_GET_DATAONE_MODELS_RESOLVED = "HANDLE_GET_DATAONE_MODELS_RESOLVED";
export interface HandleGetDataOneModelsResolved {
  type: typeof HANDLE_GET_DATAONE_MODELS_RESOLVED;
  payload: any;
}

export const GET_MODELS_PENDING = "GET_MODELS_PENDING";
export interface GetModelsPending {
  type: typeof GET_MODELS_PENDING;
}

export const GET_MODELS_RESOLVED = "GET_MODELS_RESOLVED";
export interface GetModelsResolved {
  type: typeof GET_MODELS_RESOLVED;
  payload: IPickerItem[];
}

export const GET_MODELS_REJECTED = "GET_MODELS_REJECTED";
export interface GetModelsRejected {
  type: typeof GET_MODELS_REJECTED;
  payload: string | object;
}

// ---------- CLEAR MODELS ----------
export const CLEAR_MODELS = "CLEAR_MODELS";
export interface ClearModels {
  type: typeof CLEAR_MODELS;
}

// ----------  CLEAR SMTP SETTINGS ----------
export const CLEAR_SMTP_SETTINGS = "CLEAR_SMTP_SETTINGS";

export interface ClearSMTPSettings {
  type: typeof CLEAR_SMTP_SETTINGS;
}

/** GET_SMS_SETTINGS */
export const HANDLE_GET_SMS_SETTINGS = "HANDLE_GET_SMS_SETTINGS";
export interface HandleGetSMSSettings {
  type: typeof HANDLE_GET_SMS_SETTINGS;
}

export const GET_SMS_SETTINGS_PENDING = "GET_SMS_SETTINGS_PENDING";

export interface GetSMSSettingsPending {
  type: typeof GET_SMS_SETTINGS_PENDING;
}

export const GET_SMS_SETTINGS_RESOLVED = "GET_SMS_SETTINGS_RESOLVED";

export interface GetSMSSettingsResolved {
  type: typeof GET_SMS_SETTINGS_RESOLVED;
  payload: ISMSSettings;
}

export const GET_SMS_SETTINGS_REJECTED = "GET_SMS_SETTINGS_REJECTED";

export interface GetSMSSettingsRejected {
  type: typeof GET_SMS_SETTINGS_REJECTED;
  payload: object[];
}

/** UPDATE_SMS_SETTINGS */
export const HANDLE_UPDATE_SMS_SETTINGS = "HANDLE_UPDATE_SMS_SETTINGS";
export interface HandleUpdateSMSSettings {
  type: typeof HANDLE_UPDATE_SMS_SETTINGS;
  payload: ISMSSettings;
}

export const UPDATE_SMS_SETTINGS_PENDING = "UPDATE_SMS_SETTINGS_PENDING";

export interface UpdateSMSSettingsPending {
  type: typeof UPDATE_SMS_SETTINGS_PENDING;
}

export const UPDATE_SMS_SETTINGS_RESOLVED = "UPDATE_SMS_SETTINGS_RESOLVED";

export interface UpdateSMSSettingsResolved {
  type: typeof UPDATE_SMS_SETTINGS_RESOLVED;
  payload: ISMSSettings;
}

export const UPDATE_SMS_SETTINGS_REJECTED = "UPDATE_SMS_SETTINGS_REJECTED";

export interface UpdateSMSSettingsRejected {
  type: typeof UPDATE_SMS_SETTINGS_REJECTED;
  payload: object[];
}

export type SharedActions =
  | HandleAddFavourite
  | AddFavouritePending
  | AddFavouriteResolved
  | AddFavouriteRejected
  | HandleRemoveFavourite
  | RemoveFavouriteRejected
  | RemoveFavouriteResolved
  | RemoveFavouritePending
  | HandleZipCodeFlatData
  | HandleUpdateFavourite
  | UpdateFavouriteRejected
  | UpdateFavouriteResolved
  | UpdateFavouritePending
  | HandleSendEmail
  | SendEmailPending
  | SendEmailResolved
  | SendEmailRejected
  | HandleSendSMS
  | SendSMSPending
  | SendSMSRejected
  | SendSMSResolved
  | HandleGetSMTPSettings
  | GetSMTPSettingsPending
  | GetSMTPSettingsRejected
  | GetSMTPSettingsResolved
  | HandleUpdateSMTPSettings
  | UpdateSMTPSettingsPending
  | UpdateSMTPSettingsRejected
  | UpdateSMTPSettingsResolved
  | HandleTestConnection
  | TestConnectionPending
  | TestConnectionRejected
  | TestConnectionResolved
  | HandleGetMakes
  | HandleGetDataOneMakes
  | HandleGetDataOneMakesResolved
  | HandleGetModels
  | HandleGetDataOneModels
  | HandleGetDataOneModelsResolved
  | GetModelsPending
  | GetModelsRejected
  | GetModelsResolved
  | GetMakesPending
  | GetMakesRejected
  | GetMakesResolved
  | ClearModels
  | ClearSMTPSettings
  | HandleGetSMSSettings
  | GetSMSSettingsPending
  | GetSMSSettingsResolved
  | GetSMSSettingsRejected
  | HandleUpdateSMSSettings
  | UpdateSMSSettingsPending
  | UpdateSMSSettingsRejected
  | UpdateSMSSettingsResolved;
