import client from "services/api/client";

export const getNotificationsTypesApi = async () => {
  const query = `user/master/notification/types`;
  return client(query, { body: null }).then(
    (data) => {
      // const data = [];
      // for (const [key, value] of Object.entries(d)) {
      //   data.push({ type: key, value });
      // }
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getNotificationListApi = async () => {
  const query = `user/master/notifications`;
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateNotificationApi = (id: number | string, status: number) => {
  return client(`user/master/notification/${id}`, {
    body: {
      status:  status
    },
    method: "PUT",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
