import React from "react";
import {
    ITextFieldProps,
} from "@fluentui/react/lib/TextField";
import { mergeStyles } from "@fluentui/react/lib/Styling";
//import { colors } from 'constants/constants'

const wrapper = mergeStyles({
    position: "relative",
});

//const label: CSSProperties = {
//    position: "absolute",
//    top: 5,
//    right: 0,
//    color: colors.grayText
//};

const WithOptionalLabel: React.FC<ITextFieldProps> = (props) => {
    return (
        <div className={wrapper}>
            {props.children}
            {/*<div
                className="optionalLabel" style={label}>optional</div>*/}
        </div>
    );
};

export default WithOptionalLabel;
