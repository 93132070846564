import React, { useEffect} from "react";
import {
  Stack,
  IStackStyles,
  PrimaryButton
} from "@fluentui/react";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { useNavigate  } from "react-router-dom";

const styles: IStackStyles = {
  root: {
    boxShadow: Depths.depth8,
    background: DefaultPalette.white,
    padding: 50,
    paddingLeft: 80,
    paddingRight: 80,
    borderRadius: 2,
    width: 450,
    height: 200,
    zIndex: 1,
  },
};

const RecoverAccountSuccessPage: React.FC = () => {
    const navigate = useNavigate ();
    const myWindow = window as any;
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        myWindow.navigateState(null, null, window.location.pathname);
    }

    useEffect(() => {
      myWindow.navigateState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <AuthLayoutPage>
      <Stack styles={styles} verticalAlign="space-between">
        <CustomFontText size={30}>Password Is Reset</CustomFontText>
        <PrimaryButton
          className="loginBack"  
          text="Back to Login"
          onClick={() => navigate("/login")}
        />
      </Stack>
    </AuthLayoutPage>
  );
};

export default RecoverAccountSuccessPage;
