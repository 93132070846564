import React from "react";
import { getTheme, Modal, IconButton, PrimaryButton } from "@fluentui/react";
import { contentStyles as content, cancelIcon } from "constants/styles";
import { Col, Row } from "react-grid-system";

const contentStyles = content(460, "auto");

interface IFinanceStatusWarningDialogProps {
  displayDialog: boolean;
  onDismiss: () => void;
}

export const FinanceStatusWarningDialog: React.FunctionComponent<IFinanceStatusWarningDialogProps> = ({
  displayDialog,
  onDismiss,
}) => {

   const handleClosePopup = () => {
        onDismiss();
   }

   return (
    <Modal
      isOpen={displayDialog}
      onDismiss={handleClosePopup}
      isBlocking={true}
      containerClassName={contentStyles.container}
      styles={{ root: { zIndex: 10 } }}
      className="createDealRecord dealRecordHeights  updateEmployeeDialogHeight"
    >
       <div className={contentStyles.header + " inventory-popup success-dialog-header"}>
           <IconButton
               styles={iconButtonStyles}
               iconProps={cancelIcon}
               ariaLabel="Close popup modal"
               onClick={handleClosePopup}
           />
       </div>
       <div style={{width:"auto"}} className={`${contentStyles.body} body-auto-hight success-dialog-body`}>
           <div className="dealRecFound body-auto-hight body-min-height-50">
               <div style={{ marginLeft: "25px",marginTop: "-25px",}}>
                   <div style={{textAlign:"left"}}>
                       <label className="lbl-text">Cannot close write-up unless Finance Status is set to CASH or APPROVED.</label>
                   </div>
               </div>
           </div> 
           <Row className="purchase-row" >
               <Col style={{ textAlign: "center" }}>
                   <PrimaryButton
                       text="Ok"
                       onClick={handleClosePopup}
                   />
               </Col>
           </Row>
       </div>
    </Modal>
  );
};

const theme = getTheme();
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
