import React, { FunctionComponent } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  IconButton,
  TextField,
  Stack,
  Dropdown,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
  contentStyles as content,
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
import { dropdownStyles } from "constants/styles";
import masterDropdownValues from "constants/masterDropdownValues";

const contentStyles = content(600, "auto");

interface IProps {
  onDismiss: () => void;
  vin: any;
  trimValues: any[];
  ymm: any;
  vehID: any;
}

export const AddLiveReportSettingsDialog: FunctionComponent<IProps> = ({
  onDismiss,
  vin,
  trimValues,
  ymm,
  vehID
}) => {
  const loading = useSelector(
    (state: AppState) => state.prospects.loadingAddTask
  );

  Yup.addMethod(Yup.string, 'digitsOnly', function () {
    return this.matches(/^\d+$/, 'Numerals only allowed')
  });

  const formik = useFormik({
    initialValues: {
      trim: "",
      miles: "20",
      zip: "",
      odometer: "",
      soldDate: "",
    },
    validationSchema: Yup.object({
      trim: Yup.string().required("Required"),
      miles: Yup.number().required("Required"),
      zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits"),
      odometer: Yup.string(),
      soldDate: Yup.string(),
    }),
    onSubmit: (values) => {
    },
  });

  const handleCreateNewTask = () => {
    window.open(`/${vehID}/shop-inventory/retailReports`, "_blank");
    onDismiss();
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleDropdownValue = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: e.target.id === 'trim' ? item.text : item.key
      }
    );
  };

  const handleZipCodeChangeForDealer = (e) => {
    if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
      formik.setValues(
        {
          ...formik.values,
          zip: e.target.value
        }
      );
    }
  };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={` ${contentStyles.header}`}>
        <GoBackToMainDialogBtn label="" onClick={onDismiss} />
        <CustomFontText>Generate Live Market</CustomFontText>

        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack
          styles={stackStyles}
          verticalAlign="start"
          horizontalAlign="space-between"
        >
          <Row className="inventoryRowSection" justify="center">
            <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
              <Dropdown
                id="trim"
                label="Style"
                selectedKey={formik.values.trim}
                required={true}
                onChange={handleDropdownValue}
                placeholder="Select a Style"
                options={trimValues}
                // options={masterDropdownValues.odometerRange}
                styles={dropdownStyles}
                errorMessage={
                  formik.touched.trim &&
                  formik.errors.trim &&
                  formik.errors.trim.toString()
                }
              />
            </Col>
          </Row>

          <Row className="inventoryRowSection" justify="center">
            <Col className="inventoryColDivider" sm={5} md={5} xs={6}>
              <TextField
                id="miles"
                name="miles"
                label="Search Within"
                suffix="miles"
                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                value={formik.values.miles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.miles &&
                  formik.errors.miles &&
                  formik.errors.miles.toString()
                }
              // style={{ width: "calc(100% - 130px)" }}
              />
            </Col>
            <Col className="inventoryColDivider" sm={5} md={5} xs={6}>
              <TextField
                id="zip"
                name="zip"
                label="Zip"
                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                value={formik.values.zip}
                // onChange={changeToUppercase}
                onChange={(e) => handleZipCodeChangeForDealer(e)}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.zip &&
                  formik.errors.zip &&
                  formik.errors.zip.toString()
                }
              // style={{ width: "calc(100% - 130px)" }}
              />
            </Col>
          </Row>

          <Row className="inventoryRowSection" justify="center">
            <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
              <Dropdown
                id="odometer"
                label="Odometer range"
                selectedKey={formik.values.odometer}
                required={true}
                onChange={handleDropdownValue}
                placeholder="Select a Range"
                options={masterDropdownValues.odometerRange}
                styles={dropdownStyles}
                errorMessage={
                  formik.touched.odometer &&
                  formik.errors.odometer &&
                  formik.errors.odometer.toString()
                }
              />
            </Col>
          </Row>

          <Row className="inventoryRowSection" justify="center">
            <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
              <TextField
                id="soldDate"
                name="soldDate"
                label="Sold within last"
                suffix="days"
                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                value={formik.values.soldDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={
                  formik.touched.soldDate &&
                  formik.errors.soldDate &&
                  formik.errors.soldDate.toString()
                }
              // style={{ width: "calc(100% - 130px)" }}
              />
            </Col>
          </Row>

        </Stack>

      </div>

      <div className={contentStyles.footer} style={{ textAlign: "center" }}>
        <div>
          <PrimaryButton
            text="Get Live Market"
            // style={{ float: "right" }}
            onClick={() => handleCreateNewTask()}
            onRenderIcon={renderSpinner}
          />
        </div>
        {/* </Stack> */}
      </div>
    </Modal>
  );
};


const stackStyles: IStackStyles = {
  root: {},
};
