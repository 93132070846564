import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
    handleGetOwners
} from "store/userProfile/userProfile.action";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rowsPerPageArray } from "constants/constants";
import "react-sweet-progress/lib/style.css";

const Ownership = () => {

    const dispatch = useDispatch();

    const ownerships = useSelector(
        (state: AppState) => state.userProfile.ownerships
    );

    const dt = useRef(null);

    useEffect(() => {
        dispatch<any>(handleGetOwners());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ownershipsList, setOwnershipsList] = useState([]);

    /** initial data */
    useEffect(() => {
        if (ownerships && ownerships.length > 0) {
            let results = ownerships;
            for (var i in results) {
                results[i].firstName = results[i].firstName + ' ' + results[i].lastName;
            }
            setOwnershipsList(results);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerships]);

    //const header = (
    //    <div className="table-header">
    //        {/*<Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />*/}
    //        <span className="p-input-icon-left">
    //            <i className="pi pi-search" />
    //            {/*<InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />*/}
    //            <InputText type="search" onChange={(e) => handleLocationNameFilter(e.target.value)} placeholder="Search by name" />
    //        </span>
    //    </div>
    //);

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                {rowData.firstName }
            </React.Fragment>
        );
    };

    const phoneBodyTemplate = (rowData) => {
        let phoneNo;
        if (rowData.phoneNumber && (rowData.phoneNumber.length === 10)) {
          const phoneCode = rowData.phoneNumber.substring(0, 3);
          const midPhoneNo = rowData.phoneNumber.substring(3, 6);
          const lastPhoneNo = rowData.phoneNumber.substring(6);
          phoneNo = '('+phoneCode+')'+midPhoneNo+'-'+lastPhoneNo;
        } else {
          phoneNo = rowData.phoneNumber;
        }
        return (
            <React.Fragment>
                <span className="p-column-title">Phone</span>
                {phoneNo}
            </React.Fragment>
        );
    };
    const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Email</span>
                {rowData.email}
            </React.Fragment>
        );
    };

    const percentageBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Percent Ownership</span>
                {rowData.ownershipPercentage+"%"}
            </React.Fragment>
        );
    };

    return (
        <>
            <div className="datatable-filter-demo" style={{marginTop:"30px"}}>
                <div className="card">
                    <DataTable ref={dt}
                        value={ownershipsList}
                        paginator
                        rows={10}
                        rowsPerPageOptions={rowsPerPageArray}
                        totalRecords={ownerships.length}
                        //header={header}
                        className="p-datatable-customers"
                        emptyMessage="No owners found." >

                        <Column field="firstName" header="Owner" body={nameBodyTemplate} sortable />
                        <Column field="phoneNumber" header="Phone" body={phoneBodyTemplate} sortable />
                        <Column field="email" header="Email" body={emailBodyTemplate} sortable />
                        <Column field="ownershipPercentage" header="Ownership" body={percentageBodyTemplate} sortable />
                    </DataTable>
                </div>
            </div>
        </>
    )
};

export default Ownership;