import { IDeskADeal } from "types/deskADealTypes";
import * as actions from "store/deskADeal/deskADeal.types";

interface IState {
  loading: boolean;
  deleteLoading: boolean;
  deskADeal: IDeskADeal;
  deskADeals: IDeskADeal[];
  error: any;
}

const initialState: IState = {
  loading: false,
  deleteLoading: false,
  deskADeal: null,
  deskADeals: [],
  error: null,
};

export const DeskADealReducer = (
  state: IState = initialState,
  action: actions.DeskADealActions
) => {
  switch (action.type) {


    case actions.GET_SAVED_DESK_A_DEALS_PENDING:{
      return {
        ...state,
        loading: true
      }
    }
    case actions.GET_SAVED_DESK_A_DEALS_RESOLVED:{
      return {
        ...state,
        loading: false,
        deskADeals: action.payload

      }
    }
    case actions.GET_SAVED_DESK_A_DEALS_REJECTED:{
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    case actions.CREATE_DESK_A_DEAL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_DESK_A_DEAL_RESOLVED:
      return {
        ...state,
        loading: false,
        deskADeal: action.payload
      };
    case actions.CREATE_DESK_A_DEAL_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.DELETE_DESK_A_DEAL_PENDING:
      return {
        ...state,
        deleteLoading: true,
      };
    case actions.DELETE_DESK_A_DEAL_RESOLVED:
      return {
        ...state,
        deleteLoading: false,
      };
    case actions.DELETE_DESK_A_DEAL_REJECTED:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      };
    case actions.UPDATE_DESK_A_DEAL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_DESK_A_DEAL_RESOLVED:
      return {
        ...state,
        loading: false,
        deskADeal: action.payload
      };
    case actions.UPDATE_DESK_A_DEAL_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};
