import React from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { styles } from "pages/accountSettingsPage/styles";
import { AppState } from "store/index";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as constantStyle,
} from "constants/styles";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { handleSendSMS } from "store/shared/shared.action";

const contentStyles = constantStyle(700, "auto");

interface IProps {
  onDismiss: () => void;
  addressees: string[];
}

export const SendSMSDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  addressees,
}) => {
  const loading = useSelector((state: AppState) => state.shared.loading);
  // const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const emptyForm = {
    addressees: addressees.length > 0 ? addressees : [],
    message: "",
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...emptyForm,
    },

    validationSchema: Yup.object({
      addressees: Yup.array().of(Yup.string()).required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      dispatch<any>(
        handleSendSMS(
          {
            arrayOfAddressees: formik.values.addressees,
            message: formik.values.message,
          },
          onDismiss
        )
      );
    },
  });

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const handleEmailChange = (e: any) => {
    const emails: string[] = e.target.value.split(",");
    console.log(emails);

    formik.setFieldValue(
      "receiverEmail",
      emails.map((email: string) => email.trim())
    );
  };

  // const checkFormik = () => {
  //   console.log(formik.errors);
  //   console.log(formik.values.message);
  // };

  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      {/*<Loader show={loading}/>*/}
      <div className={contentStyles.headerNoShadow}>
        <CustomFontText>Send SMS</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <TextField
          id="receiverEmail"
          name="receiverEmail"
          label="To"
          disabled={true}
          value={formik.values.addressees.join(", ")}
          onChange={handleEmailChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.touched.addressees && formik.errors.addressees
              ? formik.errors.addressees.toString()
              : null
          }
        />

        <TextField
          multiline
          rows={8}
          label="SMS Content"
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorMessage={
            formik.touched.message &&
            formik.errors.message &&
            formik.errors.message.toString()
          }
        />
      </div>
      <div className={contentStyles.footerNoShadow}>
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
          <DefaultButton text="Close" onClick={onDismiss} />
          <PrimaryButton
            onRenderIcon={renderSpinner}
            text="Send SMS"
            onClick={() => formik.handleSubmit()}
            disabled={!formik.isValid || !formik.dirty}
          />
        </Stack>
      </div>
    </Modal>
  );
};
