import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
} from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";

interface IProps {
  onAssign;
}

export const ConfigureGroupDropdown: React.FunctionComponent<IProps> = ({
  onAssign,
}) => {
  const [menuProps, setMenuProps] = React.useState(null);

  React.useEffect(() => {
    const menuItems: IContextualMenuItem[] = [
      {
        key: "assign",
        text: "Assign",
        onClick: () => onAssign(),
      },
    ];

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
      useTargetWidth: true,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultButton
      styles={buttonStyles}
      text="Group Action"
      menuProps={menuProps}
    />
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "inherit",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
