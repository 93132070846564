import { IBrand} from "types/brandsTypes";
import client, { clientFile } from "services/api/client";

import { IPagination } from "types/paginationTypes";


export const getBrands = async (request: IPagination) => {
  const { sort, page, limit } = request;

  const query = `user/master/brands?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=${limit}`;
    return client(query, { body: null }, false).then(
    (data) => {
      return { data, error: null };
    },
      (error) => {
      return { data: null, error };
    }
  );
};

export const createBrands = (brand: IBrand) => {
    return client("user/master/brands", { body: brand, method: "POST", }, false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const viewBrand = (id: number | string) => {
    return client(`user/master/brands/${id}`, { body: null }, false).then(
    (data) => {
      return { data, error: null };
    },
      (error) => {
      return { data: null, error };
    }
  );
};

export const deleteBrand = (id: number | string) => {
    return client(`user/master/brands/${id}`, {
    body: null,
    method: "DELETE",
  },false).then(
      (data) => {
      return { data, error: null };
    },
      (error) => {
      return { data: null, error };
    }
  );
};

export const updateBrand = (id: number | string, data: IBrand) => {
  return client(`user/master/brands/${id}`, {
    body: data,
    method: "PATCH",
  }, false).then(
    (data) => {
      return { data, error: null };
    },
      (error) => {
      return { data: null, error };
    }
  );
};

export const addBrandLogo = (id: number, fileData: any) => {
    return clientFile(`user/master/brands/${id}/logo`, {
        body: fileData,
        method: "POST"
    }).then(
        (data) => {
            
            return { result: data, error: null };
        },
        (error) => {
            
            return { result: null, error };
        }
    );
};

export const deleteBrandLogo = (id: number) => {
    
    return client(`user/master/brands/${id}/logo`, {
        body: null,
        method: "DELETE",
    }, false).then(
        (data) => {
            return { result: data, error: null };
        },
        (error) => {
            return { result: null, error };
        }
    );
};

export const getBrandsForDropdown = async () => {
  const page = 1;
  const limit = 1000;

  const query = `user/master/brands?page=${page}&limit=${limit}`;
  return client(query, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};
