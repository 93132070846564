import React, { useState, useEffect } from "react";
import {
  IPersonaSharedProps,
  Persona,
  PersonaSize,
  PersonaPresence,
  IPersonaStyles,
} from "@fluentui/react/lib/Persona";
import { Stack, DefaultPalette } from "@fluentui/react";
import {
  Callout,
  mergeStyleSets,
  ICalloutContentStyles,
} from "@fluentui/react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { PersonaCalloutContent } from "components/navigation/person/personCalloutContent/PersonCalloutContent";
import { Hidden } from "react-grid-system";
import { useSelector } from "react-redux";
import { AppState } from "store";
//import IdelTimerContainer from 'components/IdleTimer/IdleTimerContainer'
import { useDispatch } from "react-redux";
import { handleLogout } from "store/auth/auth.action";

const calloutStyles: Partial<ICalloutContentStyles> = {
  root: {
    backgroundColor: DefaultPalette.white,
    marginRight: 30,
    paddingLeft: 20,
    paddingRight: 30,
    width: 300,
  },
};

const iconStyleSet = mergeStyleSets({
  iconClass: {
    fontSize: 16,
    color: DefaultPalette.white,
    paddingLeft: 15,
  },
  iconClassHover: {
    fontSize: 16,
    color: DefaultPalette.themeLight,
    paddingLeft: 15,
    cursor: "pointer",
  },
});

const personStyles: Partial<IPersonaStyles> = {
  primaryText: {
    color: DefaultPalette.white,
    selectors: {
      ":hover": {
        color: DefaultPalette.white,
      },
    },
  },
  secondaryText: {
    color: DefaultPalette.white,
  },
};

const personStylesHovered: Partial<IPersonaStyles> = {
  primaryText: {
    color: DefaultPalette.themeLight,
    selectors: {
      ":hover": {
        color: DefaultPalette.themeLight,
      },
    },
  },
  secondaryText: {
    color: DefaultPalette.themeLight,
  },
  root: {
    selectors: {
      ":hover": {
        cursor: "pointer",
      },
    },
  },
};

const styles = mergeStyleSets({
  position: {},
});

export const Person: React.FunctionComponent = () => {
  const [hovered, setHovered] = useState(false);
  const [calloutVisible, setCalloutVisible] = useState(false);
  const userProfile = useSelector(
    (state: AppState) => state.userProfile.userProfile
    );
  const dispatch = useDispatch();
  const examplePersona: IPersonaSharedProps = {

    imageUrl: userProfile.avatar
      ? `${process.env.REACT_APP_IMAGE_SERVER}/${userProfile.avatar}`
          : "",
    imageInitials: userProfile?.firstName ? `${userProfile.firstName[0]} ${userProfile?.lastName ? userProfile.lastName[0] : ""}` : "",
    text: userProfile?.firstName ? `${userProfile.firstName} ${userProfile?.lastName ? userProfile.lastName : ""}` : "",
    // secondaryText: userProfile.email ? userProfile.email : '',
    // secondaryText:
    //   userProfile.roles && userProfile?.roles[0] === "ROLE_MASTER"
    //     ? "dealer account"
    //     : "",
    // tertiaryText: "In a meeting",
    // optionalText: "Available at 4:00pm",
    // showSecondaryText: true,
    };

    const [seconds, setSeconds] = useState(43200);//12 Hours 

  const handleDismiss = (e: any) => {
    e.stopPropagation();
    setCalloutVisible(false);
    };

    useEffect(() => {
        const interval = setInterval(() =>
        {
           //console.log('testing timer');
            let currentSeconds = seconds - 1;
            setSeconds(currentSeconds);
            if (currentSeconds < 300) {
                clearInterval(interval);
                dispatch<any>(handleLogout(true));
            }
        }, 1000);
        return () => clearInterval(interval);
    });

    return (
      
    <Stack
      className={styles.position}
      horizontal
      verticalAlign="center"
      horizontalAlign={"space-between"}
      onClick={() => setCalloutVisible((calloutVisible) => !calloutVisible)}
      onMouseLeave={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}
    >
            {/*<IdelTimerContainer />*/}
      <Hidden xs sm md>
        <Persona
          styles={hovered ? personStylesHovered : personStyles}
          {...examplePersona}
          size={PersonaSize.size32}
          presence={PersonaPresence.none}
          imageAlt="Annie Ried, status is unknown"
        />
      </Hidden>
      <FontIcon
        className={
          hovered ? iconStyleSet.iconClassHover : iconStyleSet.iconClass
        }
        iconName="ChevronDown"
      />
      <div className={styles.position} />
      {calloutVisible && (
        <Callout
          styles={calloutStyles}
          target={`.${styles.position}`}
          gapSpace={30}
          onDismiss={handleDismiss}
          setInitialFocus
          isBeakVisible={false}
        >
          <PersonaCalloutContent setCalloutVisible={setCalloutVisible} />
        </Callout>
      )}
    </Stack>
  );
};
