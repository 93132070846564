const TRACKER_STATUS = "TRACKER_STATUS";

export const saveTrackerStatus = (value) => {
    localStorage.setItem(TRACKER_STATUS, JSON.stringify(value));
};

export const getTrackerStatus = () => {
    return localStorage.getItem(TRACKER_STATUS);
};


export const removeTrackerStatus = () => {
    localStorage.removeItem(TRACKER_STATUS);
};
