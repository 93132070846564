import React from 'react';
import { DataTable } from 'primereact/datatable';
import { rowsPerPageArray } from "constants/constants";
import { Column } from 'primereact/column';
// import { Button } from 'primereact/button';

class CustomTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: props.data
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.data !== this.props.data){
            this.setState({value: this.props.data})
        }
    }
    getTemplate=(rowData, field_name, title)=>{
        if(field_name in this.props.customTemplates){
            var template = this.props.customTemplates[field_name]
            return template(rowData)
        }else{
            return (
                <React.Fragment>
                    <span className="p-column-title">{title}</span>
                    {rowData[field_name]}
                </React.Fragment>
            );
        }
    }
    generateColumns=()=>{
        const clm_kv = this.props.column_list
        var result =  Object.entries(clm_kv).map((each)=> {
            const [k, v] = each;
            if (this.props.sortingNotReq) {
                return <Column field={k} key={k} header={v} body={(rowData)=>this.getTemplate(rowData, k, v)} />
            } else {
                return <Column field={k} key={k} header={v} body={(rowData)=>this.getTemplate(rowData, k, v)} sortable />
            }
        })
        return result;
    }

    render() {
        // const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
        // const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
        return(
            <>
           
            <div className="card mouse-hover">
                <DataTable 
                  sortField={this.props.sortField ? this.props.sortField : ""}
                  sortOrder={this.props.sortOrder ? this.props.sortOrder : ""}
                  onSort={this.props.onSort ? this.props.onSort : ""}
                  value={this.state?.value?this.state?.value: []}
                  paginator={this.props.paginator === false ? false : true}
                  rows={this.props.rows === 25 ? 25 : (this.props.paginator === false? 100:10)}
                  selectionMode={this.props.onRowClick? "single": ""}
                  rowsPerPageOptions={this.props.rowsPerPageOptions ? this.props.rowsPerPageOptions  : rowsPerPageArray}
                  totalRecords={this.state.value?.length || ""}
                  header={this.props.header?this.props.header: ""}
                  footer={this.props.footer?this.props.footer: ""}
                  className="p-datatable-customers"
                  globalFilter={this.props.globalFilter?this.props.globalFilter:""}
                  emptyMessage={this.props.emptyMessage?this.props.emptyMessage:"No data found."}
                  onRowSelect={this.props.onRowClick ? this.props.onRowClick : ""}
                  onRowDoubleClick={this.props.onRowDoubleClick ? this.props.onRowDoubleClick : ""}
                  onValueChange={this.props.onValueChange ? this.props.onValueChange : ""}
                  rowClassName={this.props.rowClassName ? this.props.rowClassName : ""}
                  lazy={this.props.lazy ? true : false}
                //   paginatorLeft={paginatorLeft} 
                //   paginatorRight={paginatorRight}
                  >
                    {this.generateColumns()}
                </DataTable>
            </div>
            </>
        )
    }
}

export default CustomTable;