import * as actions from "store/groupsPermissions/groupsPermissions.types";
import { IGroupResponse } from "types/groupPermissionTypes";

interface IState {
  loading: boolean;
  items: IGroupResponse;
  groups: any[];
  error: null | object;
  rowsPerPage: number;
  permissions: object;
  sort: string;
  sortColumn: string;
}

const initialState: IState = {
  loading: false,
  items: {
    totalItems: null,
    currentPage: 1,
    results: [],
  },
  groups: [],
  permissions: null,
  error: null,
  rowsPerPage: 15,
  sort: "ASC",
  sortColumn: 'name'
};

export const GroupsPermissionsReducer = (
  state: IState = initialState,
  action: actions.GroupsPermissionsActions
) => {
  switch (action.type) {
    case actions.GET_GROUPS_PERMISSIONS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_GROUPS_PERMISSIONS_RESOLVED: {
      return {
        ...state,
        loading: false,
        items: { ...action.payload.items },
        groups: [...action.payload.groups]
      };
    }
    case actions.GET_GROUPS_PERMISSIONS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.SET_ROWS_PER_PAGE_GROUPS_PERMISSIONS:
      return {
        ...state,
        rowsPerPage: action.payload,
      };
    case actions.GET_PERMISSIONS_RESOLVED:
      return {
        ...state,
        permissions: action.payload
      }
    case actions.GET_PERMISSIONS_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case actions.UPDATE_PERMISSIONS_PENDING:
      return {
        ...state,
        loading: true,
      }
    case actions.UPDATE_PERMISSIONS_REJECTED:
      return {
        ...state,
        loading: false,
      }
    case actions.UPDATE_PERMISSIONS_RESOLVED:
      return {
        ...state,
        loading: false,
      }
    case actions.SET_SORT_GROUPS_PERMISSIONS:
      return {
        ...state,
        sort: action.payload
      }
    default:
      return state;
  }
};
