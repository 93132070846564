import React from "react";
import { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
    Stack,
    IStackStyles,
    Link,
    //Dropdown,
    PrimaryButton
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import masterDropdownValues from "constants/masterDropdownValues";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetSalesPersonMonthEndReports } from "store/dealTracker/dealTracker.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { currencyNegValFractionalFormat } from "utils/helperFunctions";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
//const credentialModuleName = "Location";
export const SalesPersonMonthEndReports = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate ();

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
    const [endMonthFilter, setEndMonthFilter] = React.useState(null);
    const [yearFilter, setYearFilter] = React.useState(currentYear);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [isPrimaryLocation, setIsPrimaryLocation] = React.useState([]);
    const [locationFilter, setLocationFilter] = React.useState(!isPrimaryLocation ? isPrimaryLocation : 0);
    const [reportData, setReportData] = useState([]);
    const [endMonthOption, setEndMonthOption] = useState([]);
    const [totalItems, setTotalItems] = useState(null);

    const monthsOptions = masterDropdownValues.getMonth;

    const ExcelJS = require('exceljs');
    const { saveAs } = require('file-saver');

   // const workbook = new ExcelJS.Workbook();

    //  const yearValues = [String(new Date(2022).getFullYear()), String(new Date().getFullYear() + 1)].map((item) => {
    //      return { key: item, text: item };
    //  });

    const handleStartMonthDropdownChange = (e) => {
        handleEndMonthOptions(e.value);
    };

    const handleEndMonthDropdownChange = (e) => {
        setEndMonthFilter(e.value)
    };

    const handleYearDropdownChange = (e) => {
        setYearFilter(e.value)
    };

    const handleLocationDropdownChange = (e) => {
        setLocationFilter(e.value)
    };

    const handleEndMonthOptions = (startMonthFilter) => {
        if (startMonthFilter > 0) {
            if (monthsOptions) {
                const results = monthsOptions.filter(x => x.key > startMonthFilter);
                setEndMonthOption(results);
            }
            setStartMonthFilter(startMonthFilter)
            if (endMonthFilter && startMonthFilter >= endMonthFilter) {
                setEndMonthFilter(null);
            }
        }
    }

    const handleFilters = () => {
        if (startMonthFilter || endMonthFilter || yearFilter || locationFilter) {
            const formData = {
                startMonth: startMonthFilter,
                endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
                year: yearFilter,
                locationId: locationFilter
            };
            dispatch<any>(handleGetSalesPersonMonthEndReports({
                formData,
                callback: (response) => {
                    if (response) {
                        handlePopulateReports(response)
                        setTotalItems(response.totalClosedRecords)
                    } else {
                        setReportData(null)
                    }

                }
            }));
        }
    }

    const handlePopulateReports = (response) => {

        if (response) {
            let val = [...response.salesPersons]
            const data = val.map((item) =>
            ({
                "Month": item.month,
                "Sales Person": item.firstName + ' ' + item.lastName,
                "Manager": item.manager ? item.manager.firstName + ' ' + item.manager.lastName : '',
                "Target": item.salesgoals,
                "Closed": item.closed ? Number(item.closed).toFixed(1) : '0.0',
                "Prior": item.prior ? Number(item.prior).toFixed(1) : '0.0',
                "3 Month Avg": item["3MonthAvg"] ? Number(item["3MonthAvg"]).toFixed(1) : '0.0',
                "12 Month Avg": item["12MonthAvg"] ? Number(item["12MonthAvg"]).toFixed(1) : '0.0',
                "Avg Gross": item.avgGross ? Number(item.avgGross).toFixed(2) : '0.00',
                "Appointments": item.appointments,
                "New Deals": item.newDeals,
                "New Orders": item.newOrders,
                "Pending": item.pending ? Number(item.pending).toFixed(1) : '0.0'
            }));
            const sortedData = sortByKey(data, 'Manager');
            setReportData(sortedData);
        }
        else {
            setReportData(null)
        }
    };

    const sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    useEffect(() => {
        dispatch<any>(handleGetLocations());
        handleEndMonthOptions(startMonthFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startMonthFilter, endMonthFilter, yearFilter, locationFilter]);

    useEffect(() => {
        let list = [];
        if (locations.results.length > 0) {
            locations.results.map((item: any) => {
                return list.push({ key: item.id, text: (item.legalName) })
            });
            list.unshift({ key: 0, text: 'All' });
            setCityDealerList(list);
        } else {
            setCityDealerList(null);
        }
    }, [locations]);

    useEffect(() => {
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setIsPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    // function getSheetData(data, header) {
    //     var fields = Object.keys(data[0]);
    //     var sheetData = data.map(function (row) {
    //         return fields.map(function (fieldName) {
    //             return row[fieldName];
    //         });
    //     });
    //     sheetData.unshift(header);
    //     return sheetData;
    // }

    const exportExcel = async () => {

        if (reportData && reportData.length) {
            let firstTableData = [...reportData];
            let header = ["Month", "Sales Person", "Manager", "Target", "Closed", "Prior", "3 Month Avg", "12 Month Avg", "Avg Gross", "Appointments", "New Deals", "New Orders", "Pending"];
            if (firstTableData) {
                console.log("firstTableDatafirstTableDatafirstTableData", firstTableData)

                const exportData = firstTableData.map((item) =>
                ({
                    "Month": Number(item.Month),
                    "Sales Person": item["Sales Person"],
                    "Manager": item.Manager,
                    "Target": item.Target,
                    "Closed": Number(item.Closed),
                    "Prior": Number(item.Prior),
                    "3 Month Avg": Number(item["3 Month Avg"]),
                    "12 Month Avg": Number(item["12 Month Avg"]),
                    "Avg Gross": Number(item["Avg Gross"]),
                    "Appointments": Number(item.Appointments),
                    "New Deals": item["New Deals"],
                    "New Orders": item["New Orders"],
                    "Pending": Number(item.Pending)
                }));


                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sales Person Month End Reports');

                // Add header row
                worksheet.addRow(header);

                // Add data rows
                exportData.forEach((row) => {
                    worksheet.addRow(Object.values(row));
                });

                // Generate buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'Sales Person Month End Reports.xlsx');
                });
            }
        }
    }

    //const saveAsExcelFile = (buffer, fileName) => {
    //    import('file-saver').then(module => {
    //        if (module && module.default) {
    //            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //            let EXCEL_EXTENSION = '.xlsx';
    //            const data = new Blob([buffer], {
    //                type: EXCEL_TYPE
    //            });
    //
    //            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //        }
    //    });
    //}

    const handleResetWorkbook = () => {
        setStartMonthFilter(currentMonth);
        setEndMonthFilter(null);
        setYearFilter(currentYear);
        setLocationFilter(!isPrimaryLocation ? isPrimaryLocation : 0)
    };

    const monthBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Month</span>
                {rowData.Month}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person</span>
                {rowData["Sales Person"]}
            </React.Fragment>
        );
    };

    const managerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Manager</span>
                {rowData.Manager}
            </React.Fragment>
        );
    };

    const targetBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Target</span>
                {rowData.Target}
            </React.Fragment>
        );
    };

    const closedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Closed</span>
                {rowData.Closed}
            </React.Fragment>
        );
    };

    const priorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Prior</span>
                {rowData.Prior}
            </React.Fragment>
        );
    };

    const threeMonthAvgBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">3 Month Avg</span>
                {rowData["3 Month Avg"]}
            </React.Fragment>
        );
    };

    const twelveMonthAvgBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">12 Month Avg</span>
                {rowData["12 Month Avg"]}
            </React.Fragment>
        );
    };

    const avgGrossBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Avg Gross</span>
                {currencyNegValFractionalFormat(rowData["Avg Gross"])}
            </React.Fragment>
        );
    };

    const appointmentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appointments</span>
                {rowData.Appointments}
            </React.Fragment>
        );
    };

    const newDealsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">New  Deals</span>
                {rowData["New Deals"]}
            </React.Fragment>
        );
    };

    const newOrdersBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">New Orders</span>
                {rowData["New Orders"]}
            </React.Fragment>
        );
    };

    const pendingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Pending</span>
                {rowData.Pending}
            </React.Fragment>
        );
    };

    const header = (
        <div >
            <div className=" table-header " style={{ display: "block" }} >
                <Row style={{ marginLeft: "-8px" }}>
                    <Col md={12} >
                        <Row>
                            <Col md={2} className="columnBtwSpace"  >
                                {/*<Dropdown
                                    id="startMonth"
                                    label="Start Month"
                                    selectedKey={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleStartMonthDropdownChange}
                                    placeholder=""
                                    className=" dropDownBorderColor"
                                />*/}
                                <label className="lbl-text">Start Month</label>
                                <PRDropdown
                                    id="startMonth"
                                    value={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleStartMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                    id="endMonth"
                                    label="End Month"
                                    selectedKey={endMonthFilter}
                                    options={endMonthOption}
                                    onChange={handleEndMonthDropdownChange}
                                    placeholder=""
                                />*/}
                                <label className="lbl-text">End Month</label>
                                <PRDropdown
                                    id="endMonth"
                                    value={endMonthFilter}
                                    options={endMonthOption}
                                    onChange={handleEndMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                    id="year"
                                    label="Year"
                                    //required={true}
                                    selectedKey={yearFilter}
                                    //value={yearFilter}
                                    options={yearValues}
                                    onChange={handleYearDropdownChange}
                                    placeholder=""
                                />*/}
                                <label className="lbl-text">Year</label>
                                <PRDropdown
                                    id="year"
                                    placeholder=""
                                    value={yearFilter}
                                    options={masterDropdownValues.from2022years}
                                    onChange={handleYearDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                    id="location"
                                    label="Location"
                                    selectedKey={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    placeholder=""

                                />*/}
                                <label className="lbl-text">Location</label>
                                <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </Col>
                            <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                <PrimaryButton onClick={handleResetWorkbook} >
                                    Reset
                                </PrimaryButton>
                            </Col>

                        </Row>

                    </Col>
                </Row>

            </div>
            <div style={{ marginBottom: "-15px" }}>
                <Row>
                    <Col>
                        <div >
                            <strong className="blueShadedText readField"> For single month, only select start month </strong>

                        </div>
                        <div >

                            {
                                <strong className="blueShadedText readField"> Closed Deals: {totalItems}</strong>
                            }
                        </div>
                    </Col>
                    <Col>

                        <div style={{ float: "right", fontSize: "16px", marginRight: "-13px", marginTop: "45px" }}>
                            <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
                            </Link>
                        </div>
                    </Col>
                </Row>

            </div>


        </div>
    );

    return (

        isKarlProfileType ? <>
            <div className="reportFinanceProductWidth">
                <Stack styles={container} tokens={{ childrenGap: 10 }}>

                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Sales Person Month End Report </CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    <div className="deskReport reportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                        <div>
                            <DataTable
                                value={reportData}
                                header={header}
                                className="reportsTable p-datatable-customers "
                                paginator={false}
                                //multiSortMeta={[{ field: 'Manager', order: 1 }, { field: 'Sales Person',order: 1}]}
                                sortField="Sales Person" sortOrder={1}
                                rows={10000}>
                                <Column field="Month" header="Month" style={{ width: "4em" }} body={monthBodyTemplate} sortable />
                                <Column field="Sales Person" header="Sales Person" body={salesPersonBodyTemplate} sortable />
                                <Column field="Manager" header="Manager" body={managerBodyTemplate} sortable />
                                <Column field="Target" header="Target" style={{ width: "4em" }} body={targetBodyTemplate} sortable />
                                <Column field="Closed" header="Closed" style={{ width: "4em" }} body={closedBodyTemplate} sortable />
                                <Column field="Prior" header="Prior" style={{ width: "4em" }} body={priorBodyTemplate} sortable />
                                <Column field="3 Month Avg" header="3 Month Avg" style={{ width: "4em" }} body={threeMonthAvgBodyTemplate} sortable />
                                <Column field="12 Month Avg" header="12 Month Avg" style={{ width: "4em" }} body={twelveMonthAvgBodyTemplate} sortable />
                                <Column field="Avg Gross" header="Avg Gross" style={{ width: "4em" }} body={avgGrossBodyTemplate} sortable />
                                <Column field="Appointments" style={{ width: "7em" }} header="Appointments" body={appointmentsBodyTemplate} sortable />
                                <Column field="New Deals" style={{ width: "4em" }} header="New  Deals" body={newDealsBodyTemplate} sortable />
                                <Column field="New Orders" style={{ width: "4em" }} header="New Orders" body={newOrdersBodyTemplate} sortable />
                                <Column field="Pending" style={{ width: "4em" }} header="Pending" body={pendingBodyTemplate} sortable />
                            </DataTable>
                        </div>
                    </div>
                </Stack>
            </div>
        </> : null
    );
};
