import * as React from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  DefaultButton,
  Modal,
  IconButton,
  IModalStyles,
  PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { cancelIcon, iconButtonStyles } from "constants/styles";

interface IProps {
  title: string;
  isOpen: boolean;
  onDismiss: () => void;
  onAction: (task) => void;
  description: string;
  loading: boolean;
  /**Should confirm box display only 'Cancel' button*/
  onlyDismiss?: boolean;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
}

const modal: IModalStyles = {
  keyboardMoveIcon: undefined,
  keyboardMoveIconContainer: undefined,
  layer: undefined,
  main: {
    height: "auto",
    minHeight: 0,
  },
  scrollableContent: {
    height: "100%",
    margin: 0,
    minHeight: 0,
    padding: 0,
  },
  root: {
    height: "100%",
    margin: 0,
    minHeight: 0,
    padding: 0,
  },
};

export const ConfirmDialog: React.FunctionComponent<IProps> = ({
  onAction,
  onDismiss,
  isOpen,
  title,
  description,
  loading,
  onlyDismiss,
  confirmBtnLabel,
  cancelBtnLabel
}) => {
  const renderSpinner = () => {
    return loading ? <Spinner styles={{}} size={SpinnerSize.medium} /> : null;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        // onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
        styles={modal}
      >
        <div className={contentStyles.header+" inventory-popup"}>
          <CustomFontText>{title}</CustomFontText>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack verticalAlign="space-between">
            <CustomFontText
              style={{ marginTop: 0, paddingBottom: 25, paddingTop: 25 }}
              size={16}
            >
              {description}
            </CustomFontText>
            <Stack
              tokens={styles.buttonTokens}
              horizontal
              horizontalAlign="end"
            >
            <DefaultButton text={cancelBtnLabel ? cancelBtnLabel : "Cancel"} onClick={onDismiss} />
              {!onlyDismiss && (
                <PrimaryButton
                  onRenderIcon={renderSpinner}
                  text={confirmBtnLabel ? confirmBtnLabel : "Confirm Action"}
                  onClick={onAction}
                />
              )}
            </Stack>
          </Stack>
        </div>
      </Modal>
    </div>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexFlow: "column nowrap",
    // alignItems: "stretch",
    width: 600,
  },
  header: [
    {
      flex: "1",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
