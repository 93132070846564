import React, { FC } from "react";
import { colors } from "constants/constants";
import { Stack, IStackStyles, FontIcon } from "@fluentui/react";
import { stringToCurrenyFormatForEditInputs } from "utils/helperFunctions";
import { formatDateSub} from "utils/dateTimeFunctions";


const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    borderBottom: "1px solid #eee",
    paddingBottom: 10,
    marginBottom: 10,
    cursor: "pointer",
  },
};

const span = {
  fontSize: 12,
};

interface IProps {
  item: {
    id: string;
    active: boolean;
    title: string;
    description: string;
    price: string;
    interval: string;
    cancelId: string | null;
    currentPeriodEnd: number | null;
  };
  handleClick: (item) => void;
}

const SubscriptionListItem: FC<IProps> = ({ item, handleClick }) => {
  const renderStatusLabel = () => {
    let status = "inactive";

    if (item.active) {
      status = `active (expires in: ${ formatDateSub(item.currentPeriodEnd * 1000)})`;
    }

    return status;
  };

  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      styles={stackStyles}
      onClick={() => handleClick(item)}
    >
      <div
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: colors.lighterBlack,
          flex: 1,
        }}
        className="segoeFont"
      >
        {item.title}
      </div>

      <div style={{ flex: 1 }}>
        <span style={span}>{`price: ${"$"}${stringToCurrenyFormatForEditInputs(
          item.price
        )} / ${item.interval}`}</span>
      </div>
      <div style={{ flex: 1 }}>
        <span style={{ ...span, color: item.active ? "#4F9D3C" : undefined }}>
          {renderStatusLabel()}
        </span>
      </div>
      <FontIcon iconName="ChevronRight" />
    </Stack>
  );
};

export default SubscriptionListItem;
