import {
    put,
    takeLeading
} from "redux-saga/effects";
import {
    HANDLE_GET_CUSTOMERS,
    HandleGetCustomers
} from "store/customers/customers.types";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import {
    getCustomers
} from "store/customers/customers.api";




function* getCustomerssSaga(action: HandleGetCustomers) {
    yield put(switchGlobalLoader(true));
    try {
       const {locationId, customerNumber,callback } = action.payload;
        const { data } = yield getCustomers({locationId, customerNumber });

        callback(data);
        yield put(switchGlobalLoader(false));
        return;
    } catch (error) {
        return;
    }
}

export default function* CustomersSaga() {
    yield takeLeading(HANDLE_GET_CUSTOMERS, getCustomerssSaga);
}