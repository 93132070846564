import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate , useLocation } from "react-router-dom";
import { Stack } from "@fluentui/react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { validURL } from "utils/helperFunctions";
import { handleUpdateLocation, handleViewLocation, handleCreateLocation } from "store/locations/locations.action";
import { AppState } from "store";
//import { getAddreessByZip } from "services/api/smartyStreets";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import masterDropdownValues from "constants/masterDropdownValues";
import './style.css';
import { validTenDigitPhone } from "utils/helperFunctions";
//import { InputMask } from 'primereact/inputmask';
import InputMask from "react-input-mask";
import { Calendar } from 'primereact/calendar';
import {
	Link,
	TextField,
	//MaskedTextField,
	PrimaryButton,
	//Dropdown,
	Checkbox,
	ChoiceGroup,
	IChoiceGroupOption,
	IPersonaSharedProps,
	Persona,
	PersonaPresence,
	PersonaSize
} from "@fluentui/react";
import { styles } from "./styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { HandleZipCodeFlatData } from "store/shared/shared.action";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { emplyeePermissionsAction } from "../../constants/constants";
import { useMediaQuery } from "react-responsive";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { convertLocalToUTCModified } from "utils/dateTimeFunctions";

interface ParamTypes {
	locationID: string;
}

const options: IChoiceGroupOption[] = [
	{ key: 'true', text: 'Yes' },
	{ key: 'false', text: 'No' },
];

const emptyForm = {
	legalName: "",
	dealerLicenseNumber: "",
	dealerLicenseDateOfIssue: '',
	dealerLicenseState: "US",
	dealerType: "",
	collateral: "auto",
	inventoryCondition: [],
	isMaintenanceFacilityAvailable: '',
	street: "",
	city: "",
	state: "",
	zip: "",
	county: "",
	phoneNumber: "",
	faxNumber: "",
	website: "",
	img: "",
	logo: ""
};

export const LocationDetailPage = () => {
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const dispatch = useDispatch();
	const navigate = useNavigate ();
	const location = useLocation();
	const params = useParams<ParamTypes | any>();

	const serverError = useSelector((state: AppState) => state.locations.error) as any;
	const selectedLocation = useSelector((state: AppState) => state.locations.selectedLocation);
	const user = useSelector((state: AppState) => state.userProfile.userProfile);
	const examplePersona: IPersonaSharedProps = {
		imageUrl: `${require("assets/building-image-placeholder.jpg")}`,
		text: "",
		secondaryText: "",
	};

	let imgFileRef: any = React.createRef();

	const [pageName, setPageName] = useState('Add Location');
	const [commonServerError, setcommonServerError] = React.useState(null);
	const [cityDealerList, setCityDealerList] = React.useState([]);
	const [countyDealerList, setCountyDealerList] = React.useState([]);
	const [locationZipApiMsg, setlocationZipApiMsg] = React.useState("");
	const [persona, setPersona] = React.useState(null);
	const [uploadError, setUploadError] = useState("");
	const [issuedDate, setIssuedDate] = useState<Date | Date[] | undefined |any>(undefined);
	const [blurFlag, setBlurFlag] = useState(false);
	const [updateFlag, setUpdateFlag] = useState(false);

	const yearRange = "1980:" + (new Date().getFullYear() );
	//const yearRange = "1980:" + (new Date().getFullYear() + 1);
	Yup.addMethod(Yup.string, 'digitsOnly', function () {
		return this.matches(/^\d+$/, 'Numerals only allowed')
	});

	const formik = useFormik({
		initialValues: {
			...emptyForm,
		},

		validationSchema: Yup.object({
			legalName: Yup.string().required("Required"),
			dealerLicenseNumber: Yup.string().required("Required").min(1, "Dealer License Number must be Minimum 1 Character").max(50, "Dealer License Number must be Maximum 50 Character"),
			dealerLicenseDateOfIssue: Yup.string().nullable().required("Required"),
			dealerLicenseState: Yup.string(),//.required("Required"),
			dealerType: Yup.string().required("Required"),
			collateral: Yup.string().required("Required"),
			isMaintenanceFacilityAvailable: Yup.boolean().required("Required"),
			inventoryCondition: Yup.array().nullable().min(1, "Required"),
			street: Yup.string().required("Required"),
			city: Yup.string(),
			state: Yup.string(),
			zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
			county: Yup.string(),
			faxNumber: Yup.string()
				.test("len", "Invalid fax number", (val) => {
					if (val === undefined)
						return true;
					return validTenDigitPhone(val)
				}),
			website: Yup.string().test("rules", "Invalid URL.", (val) => validURL(val)),//optional 
			phoneNumber: Yup.string()
				.test("required", "Required", (val) => {
					if (val === undefined)
						return true;
					return val.replace(/[^0-9]/g, '').length > 0
				})
				.test("len", "Invalid phone number", (val) => {
					if (val === undefined)
						return true;
					return validTenDigitPhone(val)
				}).required("Required"),
			img: Yup.string()
		}),
		onSubmit: (values) => {
			values.phoneNumber = values.phoneNumber.replace(/[^\d]/g, "").slice(0, 10);
			const {
				legalName,
				street,
				zip,
				city,
				county,
				state,
				phoneNumber,
				faxNumber,
				website,
				dealerLicenseNumber,
				dealerLicenseDateOfIssue,
				dealerLicenseState,
				dealerType,
				collateral,
				inventoryCondition,
				isMaintenanceFacilityAvailable,
				img
			} = values;

			let locationData = {
				legalName,
				street,
				zip,
				city,
				county,
				state,
				phoneNumber: phoneNumber.replace(/\D/g, '').slice(0, 10),
				faxNumber: (faxNumber && faxNumber.length > 0) ? faxNumber.replace(/\D/g, '').slice(0, 10) : '',
				website,
				dealerLicenseNumber,
				dealerLicenseDateOfIssue: dealerLicenseDateOfIssue ? convertLocalToUTCModified(dealerLicenseDateOfIssue):"",
				dealerLicenseState,
				dealerType,
				collateral,
				inventoryCondition,
				isMaintenanceFacilityAvailable: isMaintenanceFacilityAvailable.toString() === 'true',
				img,
				physicalAddress1: street
			};
			if (params.locationID) {
				locationData["id"] = params.locationID;
				dispatch<any>(
					handleUpdateLocation({
						location: locationData,
						callback: () => { if (!commonServerError) { navigate('/locations') }; },
					})
				);
			} else {
				dispatch<any>(
					handleCreateLocation({
						location: locationData,
						callback: () => { if (!commonServerError) { navigate('/locations') }; },
					})
				);
			}


			// to be removed and fire only when no errors
		},
	});

	/** initial data */
	useEffect(() => {
		window.scrollTo(0, 0);
		if (params && params.locationID) {
			setPageName('Edit Location');
			dispatch<any>(handleViewLocation(params.locationID));
			setPersona(null);
		} else {
			setPersona(examplePersona);
        }
		formik.setValues({
			...formik.values,
			dealerLicenseNumber: "",
			dealerLicenseDateOfIssue: "",
			dealerLicenseState: "",
			dealerType: "",
			collateral: "",
			inventoryCondition: [],
			isMaintenanceFacilityAvailable: '',
			street: "",
			city: "",
			state: "",
			zip: "",
			county: "",
			phoneNumber: "",
			faxNumber: "",
			website: "",
			img: "",
			logo: ""
		}, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDateChange = (val) => {
		formik.setValues(
			{
				...formik.values,
				'dealerLicenseDateOfIssue': val
			}
		);
		setIssuedDate(val);
	};

	const handleLogoChange = e => {
		e.preventDefault();
		setUploadError('');
		let reader = new FileReader();
		let file = e.target.files[0];
		var t = file.type.split('/').pop().toLowerCase();
		if (t !== "jpeg" && t !== "jpg" && t !== "png") {
			setUploadError('Please select a valid image file');
			e.target.value = null;
			return false;
		}
		if (file.size > 1024000) {
			setUploadError('Max Upload size is 1MB only');
			e.target.value = null;
			return false;
		}
		//setError('');
		reader.onloadend = () => {
			examplePersona.imageUrl = reader.result.toString();
			setPersona(examplePersona);
			formik.setValues(
				{
					...formik.values,
					"img": file,
				},
				true
			);
		}
		reader.readAsDataURL(file)
	}

	const handleZipCodeChangeForDealer = (e) => {
		if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
			formik.setValues(
				{
					...formik.values,
					zip: e.target.value
				}
			);
		}
	};

	const handleDropdownValue = (e) => {
		formik.setValues(
			{
				...formik.values,
				[e.target.id]: e.value
			}
		);
	};

	const onChangeCheckBox = (e, isChecked) => {
		if (isChecked) {
			if (!formik.values.inventoryCondition.includes(e.target.id)) {
				let arr = formik.values.inventoryCondition;
				arr.push(e.target.id);
				formik.setValues(
					{
						...formik.values,
						inventoryCondition: arr,
					},
					true
				);
			}
		} else {
			if (formik.values.inventoryCondition.includes(e.target.id)) {
				formik.setValues(
					{
						...formik.values,
						inventoryCondition: formik.values.inventoryCondition.filter(item => item !== e.target.id) as any,
					},
					true
				);
			}
		}
	}

	const handleDropdownRadioChange = (e, item) => {
		let field = e.target.name ? e.target.name : e.target.id
		formik.setValues(
			{
				...formik.values,
				[field]: item.key
			}
		);
	};

	useEffect(() => {
		let zipFields = formik.values.zip;
		if (zipFields.length === 5 && !isNaN(zipFields as any)) {
			dispatch<any>(switchGlobalLoader(true));
			//(getAddreessByZip(zipFields) as any).then((response) => {			
                dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (response) => {
                    if (response) {
					setCityDealerList(response.Cities);
					setCountyDealerList(response.Counties);
					if (formik.values.city === '' &&
						formik.values.county === '' &&
						formik.values.state === '') {
						formik.setValues(
							{
								...formik.values,
								'county': response.DefaultCounty,
								'city': response.DefaultCity,
								'state': response.State
							}
						);
					}
					setlocationZipApiMsg("");
					// updateForm("State", response.State);
				}
				else {
					// setZipApiMsg(response.ErrorMessage);
					setlocationZipApiMsg('Invalid ZIP Code.');
					formik.setValues(
						{
							...formik.values,
							'county': "",
							'city': "",
							'state': ""
						}
					);
					setCityDealerList([]);
					setCountyDealerList([]);
					// resetCityCountyState();
				}
				dispatch<any>(switchGlobalLoader(false));
			}
            })); 				
		} else {
			formik.setValues(
				{
					...formik.values,
					'county': "",
					'city': "",
					'state': ""
				}
			);
			setCityDealerList([]);
			setCountyDealerList([]);
			setlocationZipApiMsg("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.zip]);

	useEffect(() => {

		if (params.locationID && parseInt(params.locationID) > 0 && selectedLocation && selectedLocation.id === parseInt(params.locationID)) {
			formik.setValues({
				legalName: selectedLocation.legalName,
				street: selectedLocation.physicalAddress1,
				zip: selectedLocation.zip,
				city: selectedLocation.city,
				county: selectedLocation.county,
				state: selectedLocation.state,
				phoneNumber: selectedLocation.phoneNumber,
				faxNumber: selectedLocation.faxNumber,
				website: selectedLocation.website,
				dealerLicenseNumber: selectedLocation.dealerLicenseNumber,
				dealerLicenseDateOfIssue: selectedLocation.dealerLicenseDateOfIssue as any,
				dealerLicenseState: selectedLocation.dealerLicenseState,
				dealerType: selectedLocation.dealerType,
				collateral: selectedLocation.collateral,
				inventoryCondition: selectedLocation.inventoryCondition,
				isMaintenanceFacilityAvailable: selectedLocation.isMaintenanceFacilityAvailable as any,
				logo: selectedLocation.logo,
				img: selectedLocation.logo
			}, true);
			examplePersona.imageUrl = selectedLocation.logo
				? `${process.env.REACT_APP_IMAGE_SERVER}/${selectedLocation.logo}`
				: `${require("assets/building-image-placeholder.jpg")}`;
			setPersona(examplePersona);

			//if (selectedLocation.zip)
			//    getPrimaryZipDetails(selectedLocation.zip, false, selectedLocation.mailingZip);
			//if (selectedLocation.zip !== selectedLocation.mailingZip)
			//    getMailingZipDetails(selectedLocation.mailingZip, false);
			setIssuedDate(new Date(selectedLocation.dealerLicenseDateOfIssue));
			//setSelectedState({ code: selectedLocation.dealerLicenseState, name: selectedLocation.dealerLicenseState});
		}
		else {

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLocation]);

	useEffect(() => {
		if (updateFlag && serverError) {
			if (serverError.detail) {
				setcommonServerError('Validation Failed');
			} else {
				setcommonServerError('Failed to Save the Data');
			}
			window.scrollTo(0, 0);
		} else {
			setcommonServerError('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverError]);

	const handleSaveLocation = () => {
		setUpdateFlag(true);
		formik.handleSubmit();
	};

	const handleMaskInputLocation = (e) => {

		formik.setValues(
			{
				...formik.values,
				[e.target.id]: e.target.value
			}
		);
	};

	const handleUploadLink = () => {
		imgFileRef.current.click();
	}
	const handleDateBlur = () => {
		setBlurFlag(true);
    }

	const handleNavClick = () => {
		navigate('/locations',{state: location?.state});
	}



	return (
		<>
			<Stack horizontalAlign="start" className="locationDetails">
				<div className="locationNavigation">
					{/* <GoBackInfo mainPage="Settings" secondPage="Locations" thirdPage="Location Detail" secondPageUrl="/locations" /> */}
					<img
						alt="Back"
						key={"back"}
						className={isMobile ? "location backButton" : "location backButton back-nav-btn"}
						onClick={() => handleNavClick()}
						src={`${require("assets/back_pagination.png")}`}
					/>
				</div>

				<Row style={{marginLeft:"0px"}} className="locationWidth">
					<Col className="locationContainer">
						<CustomFontText className="pageName pageNameLocationHead locationRowSection">{pageName}</CustomFontText>
						{
							commonServerError ?
								<div>
									<Row>
										<Col>
											<p className="genericErrorColor"><span>{commonServerError}</span></p>
										</Col>
									</Row>
								</div> : null
						}
						<div className="locationRowSection">
							<Row className="rowSpace">
								<Col sm={8}>
									<TextField
										id="legalName"
										name="legalName"
										label="Location Name"
										placeholder=""
										value={formik.values.legalName}
										autoComplete="off"
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
											formik.touched && formik.touched.legalName &&
											formik.errors && formik.errors.legalName &&
											formik.errors.legalName.toString()
										}
									/>
								</Col>
								<Col sm={4} className="ms-PersonaArea">
									{
										persona ? <>
											<Persona
												{...persona}
												styles={styles.personStyles}
												size={PersonaSize.size100}
												presence={PersonaPresence.none}
												hidePersonaDetails={false}
												imageAlt="Annie Lindqvist, status is dnd"
											/>
											<Link onClick={handleUploadLink} style={{ display: "block" }}>Upload photo
											<input
													style={{
														padding: 20,
														position: "absolute",
														left: 0,
														top: 0,
														width: 107,
														height: 1,
														overflow: "hidden",
														opacity: 0,
														marginLeft: -10,
													}}
													type="file"
													ref={imgFileRef}
													onChange={handleLogoChange}
												/></Link>
										</> : null
									}
									{
										uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
									}
								</Col>
							</Row>
						</div>
						<div className="locationRowSection">
							<Row className="pageNameLocation">
								<Col>
									{/* <label className="lbl-text">Physical Address</label> */}
									<h3 className="readfield">Contact Information</h3>
								</Col>
							</Row>
							<Row className="rowSpace" style={{ height: "85px" }}>
								<Col sm={9} xs={12} md={9} lg={9}  className="bottomSpace">
									<TextField
										id="street"
										name="street"
										label="Street Address"
										placeholder=""
										autoComplete="off"
										value={formik.values.street}
										// onChange={(e) => handleLegalNameChange(e,'firstName')}
										//onBlur={formik.handleBlur}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
											formik.touched && formik.touched.street &&
											formik.errors && formik.errors.street &&
											formik.errors.street.toString()
										}
									/>
								</Col>
								<Col sm={3} xs={12} md={3} lg={3} className="bottomSpace">
									<TextField
										id="zip"
										name="zip"
										label="Zip"
										placeholder=""
										autoComplete="off"
										value={formik.values.zip}
										onChange={(e) => handleZipCodeChangeForDealer(e)}
										//onBlur={formik.handleBlur}
										required={true}
										// onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
											(formik.touched && formik.touched.zip &&
												formik.errors && formik.errors.zip) ?
												formik.errors.zip.toString()
												: locationZipApiMsg ? locationZipApiMsg : null
										}
									/>
								</Col>
							</Row>
							<div className="darkSection">
								<Row className="rowSpace rowSpaceDark">
									<Col sm={8} xs={12} md={8} lg={8}>
										{/*<Dropdown
											id="city"
											label="City"
											placeholder=""
											selectedKey={formik.values.city}
											options={cityDealerList}
											onChange={handleDropdownValue}
											required={true}
										/>*/}
										<label className="lbl-text  required-Label">City</label>
										<PRDropdown
											id="city"
											value={formik.values.city}
											options={cityDealerList}
											onChange={handleDropdownValue}
											optionLabel="text"
											optionValue="key"
											appendTo="self"
											className="custom-p-dropdown write-ups-location-dd"
											filter={false}
										/>
									</Col>
									<Col sm={4} xs={12} md={4} lg={4}>
										<TextField
											id="state"
											name="state"
											label="State"
											placeholder=""
											autoComplete="off"
											value={formik.values.state}
											required={true}
											readOnly={true}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											errorMessage={
												formik.touched && formik.touched.state &&
												formik.errors && formik.errors.state &&
												formik.errors.state.toString()
											}
										/>
									</Col>
								</Row>
								<Row className="rowSpace rowSpaceDark">
									<Col sm={8} xs={12} md={8} lg={8}>
										{/*<Dropdown
											id="county"
											label="County"
											placeholder=""
											selectedKey={formik.values.county}
											options={countyDealerList}
											onChange={handleDropdownValue}
											required={true}
										/>*/}
										<label className="lbl-text  required-Label">County</label>
										<PRDropdown
											id="county"
											value={formik.values.county}
											options={countyDealerList}
											onChange={handleDropdownValue}
											optionLabel="text"
											optionValue="key"
											appendTo="self"
											className="custom-p-dropdown write-ups-location-dd"
											filter={false}
										/>
									</Col>
								</Row>
							</div>

							<Row className="rowSpace business-phoneSpace">
								<Col sm={8} xs={12} md={8} lg={8}>
									<div className="ms-TextField root-155">
										<div className="ms-TextField-wrapper">
											<label className="lbl-text required-Label">Business Phone</label>
											<div className={formik.touched && formik.touched.phoneNumber &&
												formik.errors && formik.errors.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
												<InputMask
													autoClear={false}
													required={true}
													id="phoneNumber"
													name="phoneNumber"
													mask="(999)999-9999"
													placeholder=""
													value={formik.values.phoneNumber}
													className="mask-TextField full-width"
													onChange={(e) => handleMaskInputLocation(e)}
													onBlur={formik.handleBlur}>
												</InputMask>
											</div>
											<span className="error-message">{
												formik.touched && formik.touched.phoneNumber &&
												formik.errors && formik.errors.phoneNumber &&
												formik.errors.phoneNumber.toString()
											}</span>
										</div>
									</div>
								</Col>
								<Col sm={4} xs={12} md={4} lg={4}>
									<div className="ms-TextField root-155">
										<div className="ms-TextField-wrapper">
											<label className="lbl-text">Fax</label>
											<div className={formik.touched && formik.touched.faxNumber &&
												formik.errors && formik.errors.faxNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
												<InputMask
													autoClear={false}
													required={true}
													id="faxNumber"
													name="faxNumber"
													mask="(999)999-9999"
													placeholder=""
													value={formik.values.faxNumber}
													className="mask-TextField"
													onChange={(e) => handleMaskInputLocation(e)}
													onBlur={formik.handleBlur}>
												</InputMask>
											</div>
											<span className="error-message">{
												formik.touched && formik.touched.faxNumber &&
												formik.errors && formik.errors.faxNumber &&
												formik.errors.faxNumber.toString()
											}</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="rowSpace">
								<Col>
									<TextField
										id="website"
										name="website"
										label="Website"
										placeholder=""
										prefix="https://"
										ariaLabel="Example text field with https:// prefix"
										autoComplete="off"
										value={formik.values.website}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
											formik.touched && formik.touched.website &&
											formik.errors && formik.errors.website &&
											formik.errors.website.toString()
										}
									/>
								</Col>
							</Row>
						</div>
						<div className="locationRowSection">
							<Row className="pageNameLocation">
								<Col>
									<h3>Dealership Details</h3>
								</Col>
							</Row>
							<Row className="rowSpace">
								<Col sm={6} xs={12} md={6} lg={6}>
									<TextField
										id="dealerLicenseNumber"
										name="dealerLicenseNumber"
										label="Dealer License Number"
										placeholder=""
										autoComplete="off"
										value={formik.values.dealerLicenseNumber}
										required={true}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={
											formik.touched && formik.touched.dealerLicenseNumber &&
											formik.errors && formik.errors.dealerLicenseNumber &&
											formik.errors.dealerLicenseNumber.toString()
										}
									/>
								</Col>
								<Col sm={6} xs={12} md={6} lg={6}>
									<div className="ms-TextField">
										<div className="ms-TextField-wrapper">
											<label className="lbl-text required-Label">Date of Issue</label>
											<div className="ms-TextField-fieldGroup mask-FieldGroup">
												<Calendar
													id="navigators"
													value={issuedDate}
													style={{ width: "100%" }}
													showIcon
													onChange={(e) => handleDateChange(e.value)}
													onBlur={handleDateBlur}
													monthNavigator
													required={true}
													maxDate={new Date()}
													yearRange={yearRange}
													yearNavigator />
											</div>
											<span className="error-message">
												{blurFlag && formik.errors && formik.errors.dealerLicenseDateOfIssue &&
													formik.errors.dealerLicenseDateOfIssue.toString()}
											</span>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="rowSpace">
								<Col sm={7} xs={12} md={7} lg={7}>
									{/*<Dropdown
										id="dealerType"
										label="Dealership Type"
										placeholder=""
										options={masterDropdownValues.dealerTypeList}
										selectedKey={formik.values.dealerType}
										onChange={handleDropdownValue}
										required={true}
										errorMessage={
											formik.touched && formik.touched.dealerType &&
											formik.errors && formik.errors.dealerType &&
											formik.errors.dealerType.toString()
										}
									/>*/}
									<label className="lbl-text required-Label">Dealership Type</label>
									<PRDropdown
										id="dealerType"
										value={formik.values.dealerType}
										options={masterDropdownValues.dealerTypeList}
										onChange={handleDropdownValue}
										optionLabel="text"
										optionValue="key"
										appendTo="self"
										className="custom-p-dropdown write-ups-location-dd"
										filter={false}
									/>
								</Col>
							</Row>
							<Row className="rowSpace">
								<Col style={{ paddingTop: "15px",display:"flex" }}>
									<label className="width-420 lbl-text choice-lbl labelArea check-box-label required-Label">Is the inventory new or used?</label>
									<label className="firstInputEvt checkboxArea choice-lbl inventoryLabel ">
										<Checkbox
											label="New"
											id="new"
											onChange={onChangeCheckBox}
											checked={formik.values.inventoryCondition.includes("new")}
										/>
									</label>

									<label className="firstInputEvt checkboxArea choice-lbl inventoryLabel ">
										<Checkbox
											label="Used"
											id="used"
											onChange={onChangeCheckBox}
											checked={formik.values.inventoryCondition.includes("used")}
										/>
									</label>
								</Col>
							</Row>

							<Row className="rowSpace bottomSpace">
								<Col>
									<ChoiceGroup
										id="isMaintenanceFacilityAvailable"
										className="guidedRadio choice-lbl choice-check-box"
										name="isMaintenanceFacilityAvailable"
										options={options}
										required={true}
										selectedKey={formik.values.isMaintenanceFacilityAvailable.toString()}
										onChange={handleDropdownRadioChange}
										label="Does this location have a service department or body shop?" />
								</Col>
							</Row>
						</div>
						<div className="locationRowSection locationSaveButton">
							<Row className="rowSpace rowSpaceButton">
								<Col>
									{user && user.roles && (
										<EmployeeActionPermission
											permissionList={user.aggregatedDefaultPolicies}
											alias={emplyeePermissionsAction.SETTING_LOCATION_UPDATE}
											roles={user.roles}
										>
											<PrimaryButton
												style={{ float: "right" }}
												// onClick={() => nextStep(1)}
												disabled={!formik.isValid || !formik.dirty}
												onClick={() => handleSaveLocation()}
												text="Save"
												type="submit"
											/>
										</EmployeeActionPermission>
									)}
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				
			</Stack>
		</>
	)
};

