import React from "react";
import { TextField, Checkbox, Stack } from "@fluentui/react";
import { colors, PRICE_PREFIX } from "constants/constants";
import CurrencyInput from 'react-currency-input-field'


const errorMessageStyles = {
  color: colors.red,
  marginTop: 5,
  marginBottom: 10,
  fontSize: 12,
};

interface IProps {
  inventory: any;
  setInventory: (inventory) => void;
  formik: any;
  isIndeterminateInvoice: boolean;
  isIndeterminateLeasing: boolean;
  setIsIndeterminateInvoice: (boolean) => void;
  setIsIndeterminateLeasing: (boolean) => void;
}

const FinancialInformation: React.FC<IProps> = ({
  inventory,
  setInventory,
  isIndeterminateInvoice,
  isIndeterminateLeasing,
  setIsIndeterminateInvoice,
  setIsIndeterminateLeasing,

  formik,
}) => {
  const onChangeInvoice = (
    ev: React.FormEvent<HTMLElement>,
    checked: boolean
  ): void => {
    if (isIndeterminateInvoice) {
      setIsIndeterminateInvoice(false);
      formik.setValues(
        {
          ...formik.values,
        },
        true
      );
    }

    formik.setValues(
      {
        ...formik.values,
        isInvoice: !!checked,
      },
      true
    );

    // setInventory((i) => {
    //   return { ...i, isInvoice: !!checked };
    // });
  };

  const onChangeLeasing = (
    ev: React.FormEvent<HTMLElement>,
    checked: boolean
  ): void => {
    if (isIndeterminateLeasing) {
      setIsIndeterminateLeasing(false);
      formik.setValues(
        {
          ...formik.values,
        },
        true
      );
    }

    formik.setValues(
      {
        ...formik.values,
        isLeasing: !!checked,
      },
      true
    );

    // setInventory((i) => {
    //   return { ...i, isLeasing: !!checked };
    // });
  };

  // const handleChange = (e) => {
  //   formik.setValues({
  //     ...formik.values,
  //     [e.target.name]: e.target.value,
  //   });

  //   // if (e.target.name === "msrp") {
  //   //   setInventory((i) => {
  //   //     return { ...i, msrp: e.target.value };
  //   //   });
  //   // } else {
  //   //   setInventory((i) => {
  //   //     return { ...i, bookValue: e.target.value };
  //   //   });
  //   // }
  // };


  return (
    <div>
      <Stack tokens={{ childrenGap: 10 }} style={{ marginBottom: 10 }}>
        {/*<div style={formik.errors.isInvoice  ? errorBorder : null}>*/}
        <Checkbox
          label="Invoice"
          // indeterminate={isIndeterminateInvoice}
          checked={formik.values.isInvoice}
          onChange={onChangeInvoice}
        />
        {/*</div>*/}
        {formik.errors.isInvoice && formik.touched.isInvoice && (
          <div style={errorMessageStyles}>{"Required"}</div>
        )}
        {/*<div style={formik.errors.isLeasing ? errorBorder : null}>*/}
        <Checkbox
          label="Leasing"
          // indeterminate={isIndeterminateLeasing}
          checked={formik.values.isLeasing}
          onChange={onChangeLeasing}
        />
        {/*</div>*/}
        {formik.errors.isLeasing && formik.touched.isLeasing && (
          <div style={errorMessageStyles}>{"Required"}</div>
        )}
      </Stack>
      <label className="lbl-text required-Label">MSRP</label>
      <CurrencyInput
        customInput={TextField}
        prefix={PRICE_PREFIX}
        // thousandSeparator={true}
        id="msrp"
        name="msrp"
        // label="MSRP"
        value={formik.values.msrp?formik.values.msrp.replace(/,/g, ''):""}
        onValueChange ={(e) => {
          formik.setValues(
              {
                  ...formik.values,
                  "msrp": e
              }
          );
      }}
      />
      <span className="error-message">
        {formik.touched.msrp &&
          formik.errors.msrp &&
          formik.errors.msrp.toString()}
      </span>
      <label className="lbl-text required-Label">Book Value</label>
      <CurrencyInput
        customInput={TextField}
        prefix={PRICE_PREFIX}
        // thousandSeparator={true}
        id="bookValue"
        //value={inventory.bookValue}
        value={formik.values.bookValue?formik.values.bookValue.replace(/,/g, ''):''}
        onValueChange ={(e) => {
          formik.setValues(
              {
                  ...formik.values,
                  "bookValue": e
              }
          );
      }}
        name="bookValue"
        //label="Book Value"

      />
      <span className="error-message">
        {formik.touched.bookValue &&
          formik.errors.bookValue &&
          formik.errors.bookValue.toString()}
      </span>
    </div>
  );
};

export default FinancialInformation;
