import React, { FunctionComponent, useEffect } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
    IDropdownOption,
    Dropdown,
} from "@fluentui/react";
//import { CustomFontText } from "../../customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { Col, Row } from "react-grid-system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "../../../pages/accountSettingsPage/styles";
import { dropdownStyles } from "constants/styles";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
//import { getAddreessByZip } from "services/api/smartyStreets";
import CurrencyInput from 'react-currency-input-field'
import { HandleZipCodeFlatData } from "store/shared/shared.action";
import inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const contentStyles = content(600, "auto");

interface IProps {
    onSubmitFormik: (formikData: any) => void;
    onDismiss: () => void;
    nadaStyles: any;
    kbbStyles: any;
    bbStyles: any;
    qValues: any;
    qValueDetail: any;
    vehID: any;
    vehicleConditionId?:any;
}

export const AddUpdateQvalueDialog: FunctionComponent<IProps> = ({
    onSubmitFormik,
    onDismiss,
    nadaStyles,
    kbbStyles,
    bbStyles,
    qValues,
    qValueDetail,
    vehID,
    vehicleConditionId
}) => {
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    const dispatch = useDispatch();
    //const digitPattern = /^\d+$/;
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    const [zipFlag, setZipFlag] = React.useState(false);
    const [qvalueZipApiMsg, setQValueZipApiMsg] = React.useState("");
    const formik = useFormik({
        initialValues: {
            miles: qValueDetail.mileage,
            zip: qValueDetail.zip,
            state: qValueDetail.state,
            defaultVehicleCondition:  vehicleConditionId ? vehicleConditionId :2,
            blackbook: qValues[2].series + '|' + qValues[2].style,
            kbb: qValues[0].style,
            nada: qValues[1].style
        },
        validationSchema: Yup.object({
            miles: Yup.string(),
            zip: Yup.string().digitsOnly().min(5, "Zip must be 5 digits").max(5, "Zip must be 5 digits").required("Required"),
            state: Yup.string(),
            defaultVehicleCondition:Yup.string(),
            blackbook: Yup.string(),
            kbb: Yup.string(),
            nada: Yup.string(),
        }),
        onSubmit: (values) => {
            onSubmitFormik(values);
        },
    });

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const handleDropdownValue = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key
            }
        );
    };

    const handleZipCodeChangeForDealer = (e) => {
        setZipFlag(true);
        if (!e.target.value || (e.target.value.length <= 5 && !isNaN(e.target.value))) {
            formik.setValues(
                {
                    ...formik.values,
                    zip: e.target.value
                }
            );
        }
    };

    const handleUdateQValue = () => {
        formik.handleSubmit();
    }

    useEffect(() => {
        if (zipFlag) {
            let zipFields = formik.values.zip;
            if (zipFields.length === 5 && !isNaN(zipFields as any)) {
                dispatch<any>(switchGlobalLoader(true));
                dispatch<any>(HandleZipCodeFlatData({
                zipCode: zipFields,
                callback: (res) => {
                        let state = '';
                        let errorMsg = 'Invalid ZIP Code.';
                        if(res){
                            state = res.State;
                            errorMsg = ''
                        }
                        formik.setValues(
                         {
                             ...formik.values,
                             'state': state
                         });
                         setQValueZipApiMsg(errorMsg);
                    }
                    
                }));
               
                //(getAddreessByZip(zipFields) as any).then((response) => {
                //
                //    if (response.Status) {
                //        if (formik.values.state === '') {
                //            formik.setValues(
                //                {
                //                    ...formik.values,
                //                    'state': response.State
                //                }
                //            );
                //        }
                //        setQValueZipApiMsg("");
                //        // updateForm("State", response.State);
                //    }
                //    else {
                //        // setZipApiMsg(response.ErrorMessage);
                //        setQValueZipApiMsg(response.ErrorMessage);
                //        formik.setValues(
                //            {
                //                ...formik.values,
                //                'state': ""
                //            }
                //        );
                //        //setCityDealerList([]);
                //        //setCountyCompanyList([]);
                //        // resetCityCountyState();
                //    }
                //    dispatch<any>(switchGlobalLoader(false));
                //});
            }
            else {
                formik.setValues(
                    {
                        ...formik.values,
                        'state': ""
                    }
                );
                setQValueZipApiMsg("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.zip]);

    //const handleMileageChange = (e) => {
    //    if (e.target.value === '' || (digitPattern.test(e.target.value) && parseInt(e.target.value) > 0)) {
    //        formik.setValues(
    //            {
    //                ...formik.values,
    //                'miles': e.target.value
    //            }
    //        );
    //    }
    //}

  const onRenderOptionForVehicleCondition = (option: IDropdownOption): JSX.Element => {
      return (
          <div className="vehicleConditionOption">
              <strong>{option.text}</strong> <span>{option.data}</span>
          </div>
      );
  };

  const handleVehicleConditionChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key
            }
        );
    };


    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup "}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle">Order Qvalues</span>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " orderQPopup"}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="business-info-input inventoryColDivider odo" sm={3} md={3} xs={12} >
                            <label className="lbl-text required-Label">Odo</label>
                            <CurrencyInput
                                customInput={TextField}
                               // thousandSeparator={true}
                                id="miles"
                                name="miles"
                                //label="Odo"
                                decimalScale={0}
                                value={formik.values.miles }
                                onValueChange ={(e) => {
                                    formik.setValues(
                                        {
                                            ...formik.values,
                                            "miles": e
                                        }
                                    );
                                }}
                                onBlur={formik.handleBlur}
                            />
                            <span className="error-message">
                                {
                                    formik.errors.miles &&
                                    formik.errors.miles.toString()}
                            </span>
                        </Col>
                        <Col className=" vehicleDropdownWidth" sm={5} md={5} xs={12} >
                            <Dropdown
                                      id="defaultVehicleCondition"
                                      label="Vehicle Condition"
                                      className="vehicleDropdownWidth vehicleCondition"
                                      selectedKey={formik.values.defaultVehicleCondition}
                                      required={true}
                                      onChange={handleVehicleConditionChange}
                                      onRenderOption={onRenderOptionForVehicleCondition}
                                      options={inventoryDropdownsValues.VehicleCondition}
                                      styles={dropdownStyles}
                            />
                        </Col>
                        <Col className="business-info-input inventoryColDivider "  sm={3} md={3} xs={12} >
                            <TextField
                                id="zip"
                                name="zip"
                                label="Zip"
                                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                                value={formik.values.zip}
                                required={true}
                                onChange={(e) => handleZipCodeChangeForDealer(e)}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.zip && formik.errors.zip ?
                                        formik.errors.zip.toString() : qvalueZipApiMsg
                                }
                            />
                        </Col>
                        {/*<Col className="inventoryColDivider" sm={4} md={4} xs={4}>
                            <TextField
                                id="state"
                                name="state"
                                label="State"
                                // iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () =>handleScanVIN(true)}}
                                value={formik.values.state}
                                disabled={true}
                                // onChange={changeToUppercase}
                                onChange={(e) => handleZipCodeChangeForDealer(e)}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.state &&
                                    formik.errors.state &&
                                    formik.errors.state.toString()
                                }
                            // style={{ width: "calc(100% - 130px)" }}
                            />
                        </Col>*/}
                    </Row>
                    <Row className="inventoryRowSection" justify="center">
                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                            <Dropdown
                                id="blackbook"
                                label="Black Book Style"
                                selectedKey={formik.values.blackbook}
                                required={bbStyles && bbStyles.length > 0}
                                onChange={handleDropdownValue}
                                placeholder={bbStyles && bbStyles.legnth > 0 ? null : "Vehicle Unavailable"}
                                options={bbStyles}
                                styles={dropdownStyles}
                                errorMessage={
                                    formik.touched.blackbook &&
                                    formik.errors.blackbook &&
                                    formik.errors.blackbook.toString()
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="inventoryRowSection" justify="center" >
                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                            <Dropdown
                                id="nada"
                                label="J.D. Power Style"
                                selectedKey={formik.values.nada}
                                required={nadaStyles && nadaStyles.length > 0}
                                onChange={handleDropdownValue}
                                placeholder={nadaStyles && nadaStyles.legnth > 0 ? null : "Vehicle Unavailable"}
                                options={nadaStyles}
                                styles={dropdownStyles}
                                errorMessage={
                                    formik.touched.nada &&
                                    formik.errors.nada &&
                                    formik.errors.nada.toString()
                                }
                            />
                        </Col>
                    </Row>
                    <Row className="inventoryRowSection" justify="center" style={{ marginBottom: '30px' }}>
                        <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                            <Dropdown
                                id="kbb"
                                label="Kelley Blue Book Style"
                                selectedKey={formik.values.kbb}
                                required={kbbStyles && kbbStyles.length > 0}
                                onChange={handleDropdownValue}
                                placeholder={kbbStyles && kbbStyles.legnth > 0 ? null : "Vehicle Unavailable"}
                                options={kbbStyles}
                                styles={dropdownStyles}
                                errorMessage={
                                    formik.touched.kbb &&
                                    formik.errors.kbb &&
                                    formik.errors.kbb.toString()
                                }
                            />
                        </Col>
                    </Row>
                    

                </Stack>

            </div>

            <div className={contentStyles.footer + " inventory-popup-footer orderQPopupFooter"} style={{ textAlign: "center" }}>
                <div>
                    <PrimaryButton
                        text="Get Values"
                        // style={{ float: "right" }}
                        onClick={handleUdateQValue}
                        onRenderIcon={renderSpinner}
                        disabled={!formik.isValid || qvalueZipApiMsg !== ''}
                    />
                </div>
                {/* </Stack> */}
            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
