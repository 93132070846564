import client, { clientFile } from "services/api/client";
import { IOwner, IUserProfileData } from "types/userProfileTypes";

export const getEmployeeProfile = (id: number | string) => {
  return client(`user/master/employees/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getUserProfile = (id: number | string) => {
  return client(`user/master/${id}`, { body: null }).then(
    (data) => {
		
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateUserProfile = (id: number | string, data: IUserProfileData) => {
  // console.log(id, data);

  return client(`user/master/${id}`, { body: data, method: "PATCH" }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateEmployeeProfile = (id: number | string, data: IUserProfileData) => {
  // console.log(id, data);

  return client(`user/master/employees/${id}`, { body: data, method: "PATCH" }).then(
      (data) => {
        return { data, error: null };
      },
      (error) => {
        return { data: null, error };
      }
  );
};

export const getDealerProfile = () => {
  return client(`user/master/profile`, {
    body: null,
    method: "GET",
  }).then(
    (data) => {
      return { dealerData: data, dealerError: null };
    },
    (error) => {
      return { dealerData: null, dealerError: error };
    }
  );
};

export const updateDealerProfile = (data: any) => {
  return client(`user/master/profile`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updatePassword = (id: number | string, password: string) => {
  return client(`user/master/password/${id}`, {
    body: { password },
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateEmployeePassword = (id: number | string, password: string) => {
    return client(`user/master/employee/${id}/password`, {
        body: { password },
        method: "PATCH",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const uploadAvatar = (id: number | string, formData: any) => {
  // const token = getUserToken();
  //
  // console.log(formData);
  //
  // console.log(formData.get("avatar"));
  //
  // let headers = new Headers();
  // // headers.append("Content-Type", "multipart/form-data");
  //
  // headers.append("Authorization", `Bearer ${token}`);
  //
  // return fetch(`http://back-dev.fiqore.com/api/user/master/avatar/${id}`, {
  //   method: "POST", // 'GET', 'PUT', 'DELETE', etc.
  //   headers: headers,
  //   body: formData, // Coordinate the body type with 'Content-Type'
  // }).then((response) => console.log("RESPONSE", response.json()));
    return clientFile(`user/master/${id}/avatar`, { body: formData }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const uploadEmployeeAvatar = (id: number | string, formData: any) => {
  // const token = getUserToken();
  //
  // console.log(formData);
  //
  // console.log(formData.get("avatar"));
  //
  // let headers = new Headers();
  // // headers.append("Content-Type", "multipart/form-data");
  //
  // headers.append("Authorization", `Bearer ${token}`);
  //
  // return fetch(`http://back-dev.fiqore.com/api/user/master/avatar/${id}`, {
  //   method: "POST", // 'GET', 'PUT', 'DELETE', etc.
  //   headers: headers,
  //   body: formData, // Coordinate the body type with 'Content-Type'
  // }).then((response) => console.log("RESPONSE", response.json()));
    return clientFile(`user/master/employees/${id}/avatar`, { body: formData }).then(
      (data) => {
        return { data, error: null };
      },
      (error) => {
        return { data: null, error };
      }
  );
};

//Owner
export const getOwners = async () => {

    const query = `user/master/owners?limit=50`;
    return client(query, { body: null }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const createOwner = (owner: IOwner) => {
    return client("user/master/owners", { body: owner, method: "POST", }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const deleteOwner = (id: number | string) => {
    return client(`user/master/owners/${id}`, {
        body: null,
        method: "DELETE",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateOwner = (data: IOwner[]) => {
    return client(`user/master/owners`, {
        body: data,
        method: "PUT",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};


