import { takeEvery, takeLeading, put, call, select } from "redux-saga/effects";
import {
  HANDLE_CREATE_PROSPECT,
  HANDLE_DELETE_PROSPECT,
  HANDLE_GET_PROSPECTS,
  HANDLE_PROSPECT_ASSIGN_EMPLOYEE,
  HANDLE_PROSPECT_UNASSIGN_EMPLOYEE,
  HANDLE_UPDATE_PROSPECT,
  HANDLE_VIEW_PROSPECT,
  HANDLE_DOCUMENT_READER,
  HandleDocumentReader,
  HandleProspectAssignEmployee,
  HandleCreateProspect,
  HandleDeleteProspect,
  HandleGetProspects,
  HandleProspectUnassignEmployee,
  HandleUpdateProspect,
  HandleViewProspect,
  HANDLE_REMOVE_TASK,
  HANDLE_ADD_TASK,
  HandleAddTask,
  HandleRemoveTask,
  //HANDLE_GET_TASKS,
 // HandleGetTasks,
  HANDLE_UPDATE_TASK,
  HandleUpdateTask,
  HandleCheckIfProspectExist,
  HANDLE_CHECK_IF_PROSPECT_EXIST,
  VIEW_PROSPECT_RESOLVED,
} from "store/prospects/prospects.types";
import {
  rejectedCreateProspect,
  rejectedDeleteProspect,
  rejectedGetProspects,
  rejectedProspectAssignEmployee,
  rejectedProspectUnassignEmployee,
  rejectedUpdateProspect,
  rejectedViewProspect,
  pendingProspectAssignEmployee,
  pendingCreateProspect,
  pendingDeleteProspect,
  pendingGetProspects,
  pendingProspectUnassignEmployee,
  // pendingUpdateProspect,
  pendingViewProspect,
  setSortProspects,
  // setRowsPerPage,
  resolvedCreateProspect,
  resolvedDeleteProspect,
  resolvedGetProspects,
  resolvedProspectAssignEmployee,
  resolvedProspectUnassignEmployee,
  resolvedUpdateProspect,
  resolvedViewProspect,
  resolvedAddTask,
  resolvedRemoveTask,
  rejectedAddTask,
  rejectedRemoveTask,
  pendingAddTask,
  pendingRemoveTask,
  //pendingGetTasks,
  //resolvedGetTasks,
  //rejectedGetTasks,
  resolvedUpdateTask,
  rejectedUpdateTask,
  pendingUpdateTask,
  checkIfProspectExist,
  documentReaderPending,
  documentReaderRejected,
  documentReaderResolved,
} from "store/prospects/prospects.action";
import {
  getProspects,
  assignEmployee,
  unassignEmployee,
  createProspects,
  deleteProspect,
  updateProspect,
  viewProspect,
  addTask,
  removeTask,
  getTasks,
  updateTask,
  checkProspect,
  getCosigners,
  documentReaderApi,
} from "store/prospects/prospects.api";
import { AppState } from "store";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import { CheckIfProspectExistState } from "constants/constants";
import { isUserMaster } from "utils/permissions/permissionsHelpers";
import { HANDLE_GET_EMPLOYEE_TASKS } from "store/employees/employees.types";
import { IProspect } from "types/prospectTypes";
import { stringFromCurrencyFormat } from "../../utils/helperFunctions";

/**SHARED FUNCTIONS*/
export function* callBackgroundGetProspects() {
  const rowsPerPage = yield select(
    (state: AppState) => state.prospects.rowsPerPageProspects
  );
  const page: number = yield select(
    (state: AppState) => state.prospects.items.currentPage
  );
  const sort = yield select((state: AppState) => state.prospects.sort);
  const sortColumn = yield select((state: AppState) => state.prospects.column);

  const getProspectsAction: HandleGetProspects = {
    payload: {
      pagination: {
        limit: rowsPerPage,
        page,
        sort,
        column: sortColumn,
      },
      type: 1,
    },
    type: HANDLE_GET_PROSPECTS,
  };
  yield call(getProspectsSaga, getProspectsAction);
}

function* callBackgroundGetTasks(prospectId: number) {
  const request = { page: 1, limit: 15 };

  const { data } = yield getTasks(request, prospectId);
  console.log("DATA GET TASKS", data);

  //yield put(resolvedGetTasks(data));
}

/*** expect email string as an argument **/
function* checkIfProspectExistSaga(action: HandleCheckIfProspectExist) {
  const email = action.payload;

  yield put(checkIfProspectExist(CheckIfProspectExistState.pending));

  try {
    const { data } = yield checkProspect(email);
    
    if (data?.results?.length > 0) {
      yield put(checkIfProspectExist(CheckIfProspectExistState.exist));

      yield put(resolvedViewProspect(data?.results[0]));
    } else {
      yield put(checkIfProspectExist(CheckIfProspectExistState.dontExist));
    }
  } catch (e) {
    console.log(e);
  }
}

/***MAIN FUNCTIONS*/
function* getProspectsSaga(action: HandleGetProspects) {
  yield put(switchGlobalLoader(true));

  const {
    pagination: { page, limit, sort, column, phrase },
    type,
  } = action.payload;

  yield put(pendingGetProspects());

  const rowsPerPage = yield select(
    (state: AppState) => state.prospects.rowsPerPageProspects
  );

  const sortStore = yield select((state: AppState) => state.prospects.sort);
  const sortColumn = yield select((state: AppState) => state.prospects.column);

  const p = yield select(
    (state: AppState) => state.prospects.items.currentPage
  );

  yield put(setSortProspects(sort ? sort : sortStore));

  const filters = yield select((state: AppState) => state.prospects.filters);

  const newFilters = { ...filters };

  newFilters.minMonthlyIncome = Boolean(
    stringFromCurrencyFormat(filters.minMonthlyIncome)
  )
    ? stringFromCurrencyFormat(filters.minMonthlyIncome)
    : null;
  newFilters.maxMonthlyIncome = Boolean(
    stringFromCurrencyFormat(filters.maxMonthlyIncome)
  )
    ? stringFromCurrencyFormat(filters.maxMonthlyIncome)
    : null;
  newFilters.vehicleMaxPrice = Boolean(
    stringFromCurrencyFormat(filters.vehicleMaxPrice)
  )
    ? stringFromCurrencyFormat(filters.vehicleMaxPrice)
    : null;

  try {
    const { data } =
      type === 2
        ? yield getCosigners({
            page: page ? page : p,
            limit: limit ? limit : rowsPerPage,
            sort: sort ? sort : sortStore,
            column: column ? column : sortColumn,
          })
        : yield getProspects(
            {
              page: page ? page : p,
              limit: limit ? limit : rowsPerPage,
              sort: sort ? sort : sortStore,
              column: column ? column : sortColumn,
              phrase,
            },
            newFilters
          );

    yield put(resolvedGetProspects(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetProspects(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* createProspectSaga(action: HandleCreateProspect) {
  yield put(switchGlobalLoader(true));
  const { prospect, callback } = action.payload;

  (prospect as any).leadType = "Service Customer";

  yield put(pendingCreateProspect());

  if (prospect.dateOfBirth === "") {
    delete prospect.dateOfBirth;
  }
  if (prospect.driverLicense === "") {
    delete prospect.driverLicense;
  }

  const { data, error } = yield createProspects(prospect);

  if (error) {
    yield put(rejectedCreateProspect(error));
    yield put(switchGlobalLoader(false));
    return;
  }

  if (data) {
    const user = yield select(
      (state: AppState) => state.userProfile.userProfile
    );

    
    
    if (Boolean(user.employee)) {
      yield assignEmployee(data.id, user.employee.id);
    } else {
      yield assignEmployee(data.id, user.id);
    }
  }

  yield put(resolvedCreateProspect(data));

  // getAll prospects
  yield call(callBackgroundGetProspects);
  callback();
}

function* viewProspectSaga(action: HandleViewProspect) {
  yield put(switchGlobalLoader(true));
  yield put(pendingViewProspect());
  const id = action.payload;

  try {
    const { data } = yield viewProspect(id);
    yield put(resolvedViewProspect(data));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedViewProspect(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* deleteProspectSaga(action: HandleDeleteProspect) {
  const { ids, callback } = action.payload;
  yield put(pendingDeleteProspect());
  try {
    for (const id of ids) {
      const { error } = yield deleteProspect(id);
      if (error) {
        yield put(rejectedDeleteProspect(error));
        callback();
        return;
      }
    }

    yield put(switchGlobalLoader(true));
    yield put(resolvedDeleteProspect());
    yield call(callBackgroundGetProspects);
    callback();
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedDeleteProspect(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* updateProspectSaga(action: HandleUpdateProspect) {
  yield put(switchGlobalLoader(true));

  const { prospects, callback } = action.payload;
  //yield put(pendingUpdateProspect());
  

  try {
    for (const item of prospects) {
      const id = item.id;
      delete item.deals;
      delete item.id;

      // item.leadType = "1";

      const itemToSend: any = { ...item };

      itemToSend.vehicleTrim = itemToSend.vehicleTrim.toString();
      itemToSend.vehicleMaxPrice = parseInt(
        stringFromCurrencyFormat(itemToSend.vehicleMaxPrice)
      );
      itemToSend.monthlyIncome = parseInt(
        stringFromCurrencyFormat(itemToSend.monthlyIncome)
      );

      

      
      const { error } = yield updateProspect(id, itemToSend);

      if (error) {
        yield put(switchGlobalLoader(false));
        return;
      }
    }
    yield call(callBackgroundGetProspects);
    yield put(resolvedUpdateProspect());

    callback();
  } catch (error) {
    console.log(error);
    
    yield put(rejectedUpdateProspect(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* prospectAssignEmployeeSaga(action: HandleProspectAssignEmployee) {
  yield put(switchGlobalLoader(true));
  yield put(pendingProspectAssignEmployee());
  const { prospectId, employeeId, callback } = action.payload;
  try {
    const data = assignEmployee(prospectId, employeeId);
    console.log(data);
    yield put(resolvedProspectAssignEmployee());
    // yield call(callBackgroundGetProspects);
    callback();
  } catch (error) {
    yield put(rejectedProspectAssignEmployee(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* prospectUnassignEmployeeSaga(action: HandleProspectUnassignEmployee) {
  yield put(switchGlobalLoader(true));
  yield put(pendingProspectUnassignEmployee());
  const { prospectId, employeeId, callback } = action.payload;
  try {
    const data = unassignEmployee(prospectId, employeeId);
    console.log(data);
    yield put(resolvedProspectUnassignEmployee());
    callback();
    // yield call(callBackgroundGetProspects);
  } catch (error) {
    yield put(rejectedProspectUnassignEmployee(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* addTaskSaga(action: HandleAddTask) {
  yield put(switchGlobalLoader(true));
  yield put(pendingAddTask());
  const { prospectId, task } = action.payload;

  try {
    const { data, error } = yield addTask(prospectId, task);

    console.log(data, error);
    yield put(resolvedAddTask());
    yield call(callBackgroundGetTasks, prospectId); // ??????????????
    
    const prospects = yield select(
      (state: AppState) => state.prospects.items.results
    );
    const prospect = prospects.find(
      (prospect: IProspect) => prospect.id === prospectId
    );
    
    prospect.tasks.push(data);

    
    console.log(prospect);
    yield put({
      type: VIEW_PROSPECT_RESOLVED,
      payload: prospect,
    });
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedAddTask(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* removeTaskSaga(action: HandleRemoveTask) {
  yield put(switchGlobalLoader(true));
  yield put(pendingRemoveTask());
  const { tasksToRemove, callback } = action.payload;
  const user = yield select((state: AppState) => state.userProfile.userProfile);
  
  try {
    for (const taskToRemove of tasksToRemove) {
      yield removeTask(taskToRemove.prospectId, taskToRemove.taskId);

      yield put(resolvedRemoveTask());
    }

    // isUserMaster(user.roles)
    //   ? yield call(callBackgroundGetTasks, tasksToRemove[0].prospectId)
    //   : yield put({ type: HANDLE_GET_EMPLOYEE_TASKS,payload: {employeeId: user.id, isUserMaster: false} }); // ??????????????

    yield put({
      type: HANDLE_GET_EMPLOYEE_TASKS,
      payload: { employeeId: user.id, isUserMaster: isUserMaster(user.roles) },
    });

    // if (isFromScheduler) {
    //   console.log(tasksToRemove[0].prospectId);
    //   
    //   isUserMaster(user.roles)
    //     ? yield call(callBackgroundGetTasks, tasksToRemove[0].prospectId)
    //
    //       {employeeId: number, isUserMaster: boolean}
    //     : yield put({ type: HANDLE_GET_EMPLOYEE_TASKS, payload: user.id }); // ??????????????
    // } else {
    //   
    //   isUserMaster(user.roles)
    //     ? yield call(callBackgroundGetProspects)
    //     : yield put({ type: HANDLE_GET_EMPLOYEE_TASKS, payload: user.id });
    // }

    callback();
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedRemoveTask(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

//function* getTasksSaga(action: HandleGetTasks) {
//  yield put(switchGlobalLoader(true));

//  yield put(pendingGetTasks());
//  const { request, prospectId } = action.payload;

//  try {
//    const { data } = yield getTasks(request, prospectId);

//    yield put(resolvedGetTasks(data));
//    yield put(switchGlobalLoader(false));
//  } catch (error) {
//    yield put(rejectedGetTasks(error));
//    yield put(switchGlobalLoader(false));
//    return;
//  }
//}

function* updateTaskSaga(action: HandleUpdateTask) {
  const { tasks, callback } = action.payload;
  yield put(switchGlobalLoader(true));
  callback();
  yield put(pendingUpdateTask());
  // const { prospectId, tasks } = action.payload;
  
  
  // const { prospectId, task } = action.payload;

  try {
    for (const task of tasks) {
      const { data, error } = yield updateTask(task.prospectId, task.task);
      if (error) {
        yield put(rejectedUpdateTask(error));
        return;
      }

      yield put(resolvedUpdateTask(data));
    }

    yield call(callBackgroundGetProspects);
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedUpdateTask(error));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* documentReaderSaga({ payload }: HandleDocumentReader) {
  
  // const photo = payload;
  yield put(documentReaderPending());
  // const formItem = yield call(generateFormDataItem, photo);
  // console.log(formItem);
  const formData = new FormData();
  formData.append("id", payload);

  const { data, error } = yield documentReaderApi(payload);

  if (error) {
    yield put(documentReaderRejected(error));
    return;
  }

  yield put(documentReaderResolved(data));

  // try {
  //   const photo = payload;
  //   yield put(actions.documentReaderPending());
  //   const formItem = yield call(generateFormDataItem, photo);
  //   console.log(formItem);
  //   const formData = new FormData();
  //   formData.append('id', formItem);
  //   const documentReaderResponse = yield prospectApi.documentReaderApi(
  //       formData
  //   );
  //   console.log('vinReaderResponse', documentReaderResponse);
  //   const countLoadedProperties = () => {
  //     if (documentReaderResponse?.code) return 0;
  //     let counter = 0;
  //     documentReaderResponse?.driverLicense === '' ? null : counter++;
  //     documentReaderResponse?.expire === '' ? null : counter++;
  //     documentReaderResponse?.firstName === '' ? null : counter++;
  //     documentReaderResponse?.lastName === '' ? null : counter++;
  //     documentReaderResponse?.dateOfBirth === '' ? null : counter++;
  //     return counter;
  //   };
  //   console.log(countLoadedProperties());
  //   if (countLoadedProperties() > 0) {
  //     documentScannerAlert(`Data has been loaded!`);
  //     yield put(actions.documentReaderResolved(documentReaderResponse));
  //     return;
  //   }
  //   documentScannerAlert(`Something went wrong. Try again!`);
  //   yield put(actions.documentReaderRejected(documentReaderResponse));
  // } catch (error) {
  //   createErrorAlert(`[ERROR SAGA HANDLE DOCUMENT READER]: ${error}`);
  // }
}

export default function* ProspectsSaga() {
  yield takeLeading(HANDLE_GET_PROSPECTS, getProspectsSaga);
  yield takeLeading(HANDLE_CREATE_PROSPECT, createProspectSaga);
  yield takeLeading(HANDLE_VIEW_PROSPECT, viewProspectSaga);
  yield takeEvery(HANDLE_DELETE_PROSPECT, deleteProspectSaga);
  yield takeEvery(HANDLE_UPDATE_PROSPECT, updateProspectSaga);
  yield takeLeading(
    HANDLE_PROSPECT_ASSIGN_EMPLOYEE,
    prospectAssignEmployeeSaga
  );
  yield takeLeading(
    HANDLE_PROSPECT_UNASSIGN_EMPLOYEE,
    prospectUnassignEmployeeSaga
  );
  yield takeLeading(HANDLE_ADD_TASK, addTaskSaga);
  yield takeEvery(HANDLE_REMOVE_TASK, removeTaskSaga);
  //yield takeLeading(HANDLE_GET_TASKS, getTasksSaga);
  yield takeEvery(HANDLE_UPDATE_TASK, updateTaskSaga);
  yield takeEvery(HANDLE_CHECK_IF_PROSPECT_EXIST, checkIfProspectExistSaga);
  yield takeEvery(HANDLE_DOCUMENT_READER, documentReaderSaga);
}
