import React, { FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
    Dropdown,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
//import { useNavigate  } from "react-router-dom";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";
//import { dropdownStyles } from "constants/styles";
import { handleGetDealerTradeVehDetails, handleUpdateDTVehicleStockItem } from "store/dealTracker/dealTracker.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { trimTypes } from "constants/constants";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss: (res?) => void;
    vin: any;
    trimValues: any[];
    ymm: any;
    dealerTradeId: any;
    orderStock: any;
    vehicleAction: string;
    trimType?: number;
    tradeType?: number;
}

export const AddVehicleTrimDialog: FunctionComponent<IProps> = ({
    onDismiss,
    vin,
    trimValues,
    ymm,
    dealerTradeId,
    tradeType,
    orderStock,
    vehicleAction,
    trimType = trimTypes.karl
}) => {
    //const navigate = useNavigate ();
    const dispatch = useDispatch();
    const loading = useSelector(
        (state: AppState) => state.prospects.loadingAddTask
    );
    let initialTrimForm = {
        trim: trimValues && trimValues.length === 1 ? trimValues[0].key : "",
        stockNumber: ""
    };

    const userProfile: any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const [bodyStyle, setBodyStyle] = React.useState({ minHeight: "105px" });

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const trimFormik = useFormik({
        initialValues: initialTrimForm,
        validationSchema: Yup.object({
            trim: Yup.string(),
            stockNumber: Yup.string()
        }),
        onSubmit: (values) => {
        },
    });

    const handleCreateNewTask = () => {  
        if (trimFormik.values.trim) {
            if (trimType === trimTypes.editDelivery) {
                // onDismiss();
                //orderStock.orderNumber, orderStock.stockNumber
                const formData = {
                    orderNumber: orderStock.orderNumber,
                    style: trimFormik.values.trim,
                    vin: orderStock.vin,
                    stockNumber: orderStock.stockNumber,
                };

                dispatch<any>(handleUpdateDTVehicleStockItem({
                    id: dealerTradeId,
                    vehicleAction,
                    vehicleId: orderStock.vehicleId,
                    formData,
                    callback: (res) => {
                        if (res) {
                            onDismiss(res);
                        } else {
                            onDismiss();
                        }
                    }
                }));
            } else {
                let vinTrim;
                if (vin) {
                    vinTrim = {
                        style: trimFormik.values.trim,
                        vin: vin
                    };
                } else {
                    vinTrim = {
                        style: trimFormik.values.trim,
                        ...ymm
                    };
                }

                if (isKarlProfileType && trimType === trimTypes.karl) {
                    vinTrim["stockNumber"] = trimFormik.values.stockNumber;
                }

                dispatch<any>(handleGetDealerTradeVehDetails({        
                    vehDet: vinTrim,
                    trimType,
                    tradeType,
                    id: dealerTradeId,
                    action: vehicleAction,
                    callback: (success) => {
                        if (success) {
                            onDismiss(success);
                        }
                    }
                }));
            }
        }
    };

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };
    //  const handleDropdownValue = (e: { value: any }) => {
    //      trimFormik.setValues(
    //        {
    //          ...trimFormik.values,
    //              'trim': e.value
    //        }
    //      );
    //  };

    const handleDropdownValue = (val) => {

        trimFormik.setValues(
            {
                ...trimFormik.values,
                'trim': val ? val : '',
            }
        );
    };

    const onDropDownClose = () => {
        setBodyStyle({ minHeight: "105px" });
    }

    const onDropDownFocus = () => {
        if (trimValues && trimValues.length > 4)
            setBodyStyle({ minHeight: "310px" });
        else
            setBodyStyle({ minHeight: "210px" });
    }

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            className={isKarlProfileType && trimType === trimTypes.karl ? "style-dialog-karl" : "style-dialog style-dialog-view "}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <CustomFontText className="formTitle">Add Vehicle</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            <div className={contentStyles.body + " inventory-style-popup-body"} style={bodyStyle}>
                <Stack
                    styles={stackStyles}
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    {ymm ?
                        // <CustomFontText size="15px"><strong style={{ marginRight: "15px" }}>VIN Decode</strong>{ymm.year + " " + ymm.make + " " + ymm.model}</CustomFontText>
                        <CustomFontText color="#1C2E47" size="15px">{ymm.year + " " + ymm.make + " " + ymm.model}</CustomFontText>
                        : null}

                    {isKarlProfileType && trimType === trimTypes.karl && vin && (<TextField
                        id="stockNumber"
                        name="stockNumber"
                        label="Stock No"
                        className="stock-no-field contentFieldSpace"
                        value={trimFormik.values.stockNumber}
                        onChange={trimFormik.handleChange}
                        onBlur={trimFormik.handleBlur}
                        errorMessage={
                            trimFormik.touched.stockNumber &&
                            trimFormik.errors.stockNumber &&
                            trimFormik.errors.stockNumber.toString()
                        }
                    />)}
                    {/*<Dropdown
                        id="trim"
                        label="Style"
                        className="contentFieldSpace"
                        selectedKey={trimFormik.values.trim}
                        required={true}
                        onChange={handleDropdownValue}
                        placeholder="Select a Style"
                        options={trimValues}
                        styles={dropdownStyles}
                        errorMessage={
                            trimFormik.touched.trim &&
                            trimFormik.errors.trim &&
                            trimFormik.errors.trim.toString()
                        }
                    />*/}
                    {isMobile ?

                        <div>
                            <Dropdown
                                id="trim"
                                label="Style"
                                selectedKey={trimFormik.values.trim}
                                required={true}
                                onChange={(e, item) => handleDropdownValue(item.key)}
                                placeholder="Select a Style"
                                options={trimValues}
                            />
                        </div>

                        :
                        <div>
                            <label className="lbl-text required-Label">Style</label>
                            <PRDropdown
                                id="trim"
                                placeholder="Select a Style"
                                value={trimFormik.values.trim}
                                options={trimValues}
                                onChange={(e) => handleDropdownValue(e.value)}
                                optionLabel="text"
                                optionValue="key"
                                appendTo="self"
                                className="invMakeDropdown labelTextBorder full-width"
                                filter={false}
                                resetFilterOnHide={true}
                                onHide={onDropDownClose}
                                onFocus={onDropDownFocus}
                            //onMouseDown={onMouseDown}
                            />
                            <span className="error-message error-message-text" >
                                {trimFormik.touched.trim &&
                                    trimFormik.errors.trim &&
                                    trimFormik.errors.trim.toString()}
                            </span>
                        </div>

                    }
                </Stack>
            </div>

            <div className={contentStyles.footer + " inventory-popup-footer  style-dialog-foot"}>
                <div style={{ textAlign: "center", marginTop: "-10px" }}>
                    {/*<Button
              text="Close"
              style={{ marginRight: "10px" }}
              onClick={() => onDismiss()}
            />*/}
                    <PrimaryButton
                        text="Save"
                        disabled={(!trimFormik.values.trim) || !(isKarlProfileType && trimType === trimTypes.karl && vin)}
                        onClick={() => handleCreateNewTask()}
                        onRenderIcon={renderSpinner}
                    />
                </div>
            </div>
        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
