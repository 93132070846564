import React, { useEffect, useState } from "react";
import { Stack, IStackStyles } from "@fluentui/react/lib/Stack";
import { useMediaQuery } from "react-responsive";
import { PrimaryButton, ITextFieldProps } from "@fluentui/react";
import { styles } from "./styles";
import PasswordTextInput from "components/customInputs/passwordTextInput/PasswordTextInput";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppState } from "store";
import * as Yup from "yup";
import { queries } from "constants/constants";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { useLocation, useNavigate  } from "react-router-dom";
import { handleResetPasswordConfirm, clearServerErrorMessage } from "store/auth/auth.action";
import { PasswordWithRequirementInfo } from "components/textFieldWithLabelAndInfoIcon/PasswordWithRequirementInfo";
import { CustomFontText } from "components/customFontText/CustomFontText";
import {
    Spinner,
    SpinnerSize,
    ISpinnerStyles,
} from "@fluentui/react/lib/Spinner";
import { Link } from "@fluentui/react/lib/Link";

const spinnerStyles: ISpinnerStyles = {
    root: {
        position: "absolute",
        right: 220,
    },
};

const stackStyles: IStackStyles = {
    root: {
        height: "100vh",
    },
};
const queryString = require('query-string');
export const PasswordSetUpPage = () => {
    const isTablet = useMediaQuery(queries.tablet);
    const navigate = useNavigate ();
    const location = useLocation ();
    const [title, setTitle] = useState('');
    const [fullName, setFullName] = useState('');
    const [content, setContent] = useState('');
    const [resetPasswordVerify, setresetPasswordVerify] = useState(false);
    const [resetLink, setresetLink] = useState(false);
    const [agree, setAgree] = useState(false);
    const parsed = queryString.parse(location.search);

    const loading = useSelector((state: AppState) => state.auth.loading);

    const resetPasswordError = useSelector(
        (state: AppState) => (state.auth as any).resetPasswordError
    );


    const resetLinkExpiration = useSelector(
        (state: AppState) => state.auth.resetPasswordLinkError
    );


    useEffect(() => {
        if (resetLinkExpiration !== null) {
            if (resetLinkExpiration.code === 404) {
                setresetPasswordVerify(false);
                setresetLink(true);
                setTitle('Invalid Link');
                setContent('Please try again and check your email for a new link.');
            } else if (resetLinkExpiration.code === 200){
                setFullName(resetLinkExpiration.data.firstName + ' ' + resetLinkExpiration.data.lastName);
                setresetPasswordVerify(true);
                setresetLink(false);
            }
        }
    }, [resetLinkExpiration]);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: parsed?.email ? window.atob(parsed.email) : '',
            confirmCode: parsed?.token,
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .min(3, "Must be at least 3 characters")
                .email("Field must be valid email user")
                .required("Required"),
            // confirmCode: Yup.string().required("Required"),
            password: Yup.string()
                .required("Required")
                .test("rules", "Must be 8 characters, upper and lowercase letter, one number, one special character. Cannot reuse previous passwords.", (val) => {
                    var allRules = new RegExp(/^(?=^.{8,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]+)(?![.\n]).*$/);
                    return allRules.test(val);
                }),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: (values) => {
            dispatch<any>(
                handleResetPasswordConfirm({
                    email: values.email,
                    password: values.password,
                    verificationToken: parsed?.token,
                })
            );
        },
    });

    const handleClick = async () => {
        dispatch<any>(clearServerErrorMessage());
        formik.handleSubmit();
    };


    const handleResetPassword = () => {
        navigate('/reset-password');
    };

    const onRenderLabel = (props: ITextFieldProps) => (
        <PasswordWithRequirementInfo {...props} />
    );

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const renderLabelWithLinks = () => {
        return (
            <span className={styles.checkboxLabel}>
                By creating this account, I agree to FiQore's{" "}
                <Link className="ln-nw-link">                   
                <a  className="ln-nw-link-test" href={`${require("assets/termConditions/privacy-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Privacy Policy</a>
        </Link>
                {", "}
                <Link className="ln-nw-link">
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/cookie-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Cookie Policy</a>
                  
        </Link>
                {" and "}
                <Link className="ln-nw-link">
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/Term.pdf")}`} target="_blank" rel='noopener noreferrer'>Terms of Use.</a>
        </Link>
            </span>
        );
    };

    //const handlePageRouting = (screen, newTab = false) => {
    //    dispatch<any>(clearServerErrorMessage());
    //    window.open(screen, "_blank");
    //};

    const handleCheckboxChange = () => {
        setAgree(!agree);
    };

    return (
        <Stack styles={stackStyles}
            verticalAlign="center"
            horizontalAlign="center">
            {resetPasswordVerify ? <form noValidate>
                <Stack.Item styles={styles.stackItemHederStyles}>
                    <span className="passwordSetupTitle"> Welcome! Let's get started...</span>
                </Stack.Item>
                <Stack.Item
                    className="passwordSetupPage-greenTop"
                    styles={
                        isTablet ? styles.stackTabletItemStyles : styles.stackItemStyles
                    }
                >
                    <Stack
                        verticalAlign="space-between"

                    >
                        <Stack.Item>
                            <span className="formTitle">Create your account</span>
                        </Stack.Item>
                        {
                            resetPasswordError && resetPasswordError.message ? <p className="genericErrorColor"><span>{resetPasswordError.message}</span></p> : null
                        }
                        <Stack.Item>
                            <Stack className="passwordSetupForm commonForm" tokens={styles.textFields}>
                                <span className="nameSpan">{ fullName }</span>
                                <PasswordTextInput
                                    id="resetpassword"
                                    name="password"
                                    label="Password"
                                    description=""
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onRenderLabel={onRenderLabel}
                                    errorMessage={
                                        formik.touched.password && formik.errors.password && formik.errors.password.toString() !== "Invalid"
                                            ? formik.errors.password.toString()
                                            : null
                                    }
                                />
                                <PasswordTextInput
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label="Re-enter Password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMessage={
                                        formik.touched.confirmPassword &&
                                            formik.errors.confirmPassword
                                            ? formik.errors.confirmPassword.toString()
                                            : null
                                    }
                                />
                                <p>{renderLabelWithLinks()}</p>
                                <Checkbox
                                    id="isRulesCheck"
                                    name="isRulesCheck"
                                    //checked={formik.values.isRulesCheck}
                                    onChange={handleCheckboxChange}
                                    label="I Agree"
                                    className="checkboxText agreeCheck"
                                />
                            </Stack>
                        </Stack.Item>
                        <Stack.Item className="passwordSetAction formAction">
                            <PrimaryButton
                                text="Create"
                                // type="submit"
                                onRenderIcon={renderSpinner}
                                disabled={!formik.dirty || !formik.isValid || !agree}
                                onClick={() => handleClick()}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </form> :
                <Stack.Item
                    styles={
                        resetLink ? styles.stackItemStylesAutoHeight : styles.none
                    }
                >
                    <Stack
                        verticalAlign="space-between"
                        styles={styles.loginContentStyles}>
                        <Stack.Item>
                            <CustomFontText>{title}</CustomFontText>
                        </Stack.Item>

                        <Stack.Item>
                            <Stack tokens={styles.textFields}>
                                <div><p className="confirmationMsg">
                                    {content}</p></div>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack tokens={styles.buttonTokens}>
                                {resetLink ? <PrimaryButton
                                    onRenderIcon={renderSpinner}
                                    text="Reset Password"
                                    onClick={() => handleResetPassword()}
                                /> : ''}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>}
            
        </Stack>
    );
};

