import { SHOW_SNACKBAR, REMOVE_SNACKBAR } from "store/notifier/notifier.action";
import {INotification} from "types/notificationType";


interface IState {
  notification: INotification
}

const defaultState = {
  notification: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IState = defaultState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      console.log(action.payload);
      
      return {
        ...state,
        notification: action.payload,
      }
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notification: null,
      };
    default:
      return state;
  }
};
