import {
    FontWeights,
    IconButton,
    //DefaultButton,
    Modal,
    PrimaryButton,
    TextField,
    getTheme,
    mergeStyleSets,
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "components/dialogs/employees/styles";
import { trimTypes } from "constants/constants";
import { cancelIcon } from "constants/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { handleCheckVehicleOrder, handleCheckVehicleStock, handleDealerTradeCheck, handleUpdateVehicleStockItem, handleVehicleTrackerCheckStock, handleViewDealTracker } from "store/dealTracker/dealTracker.action";
import { handleGetTrimUsingVin } from "store/inventory/inventory.action";
import * as Yup from "yup";
import { CustomFontText } from "../../customFontText/CustomFontText";
import VinScanner from "../barCodeScanner/VinScanner.js";
//import { getVehicleVinEditPermission } from "utils/permissions/permissionsHelpers";
import { Link } from "@fluentui/react/lib/Link";
import { DealerTradeFoundDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/DealerTradeFoundDialog";
import dropDownsValues from "constants/inventoryDropdownsValues";
import moment from "moment";
import { Dropdown as PRDropdown } from 'primereact/dropdown';

interface IProps {
    onDismiss: (res) => void;
    isOpen,
    openTrimDialog: (any) => void;
    vehFound: (any) => void;
    stockData: (any) => void;
    addVehicleSectionIndex?: (any) => void;
    dealId: any;
    vehicleVinYmmFlow: boolean;
    onVinEdit: () => void;
    stockConflictData?: (res) => void;
    stockFoundDealData?: (res) => void;
    orderConflictData?: (res) => void;
    orderFoundDealData?: (res) => void;
    vtStockFoundDealData?: (res) => void;
}

const yearValues = dropDownsValues.yearNewValues.map((item) => {
    return { key: item, text: item };
});

export const EditVehicleInfoDialog: React.FunctionComponent<IProps> = ({
    onDismiss,
    isOpen,
    openTrimDialog,
    vehFound,
    stockData,
    dealId,
    onVinEdit,
    vehicleVinYmmFlow,
    stockConflictData,
    stockFoundDealData,
    orderConflictData,
    orderFoundDealData,
    addVehicleSectionIndex,
    vtStockFoundDealData
}) => {
    const loading = useSelector((state: AppState) => state.employees.loading);
    //const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const [isVINScan, setisVINScan] = useState(false);
    const dealTrackerData = useSelector((state: AppState) => state.dealTracker.selectedDealRecord);
    const dispatch = useDispatch();
    const [vinDataError, setvinDataError] = useState(false);
    const [conflictData, setConflictData] = useState([]);
    const [trimData, setTrimData] = useState(null);
    const [modalIndex, setModalIndex] = useState(1);

    useEffect(() => {
        stockConflictData(null);
        stockFoundDealData(null);
        orderConflictData(null);
        orderFoundDealData(null);
        setTrimData(null);
        dispatch<any>(handleViewDealTracker(dealId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            orderNumber: dealTrackerData?.orderNumber,
            vin: dealTrackerData.vehicle.vin,
            stockNumber: dealTrackerData?.stockNumber,
            year: dealTrackerData.vehicle.year,
            make: dealTrackerData.vehicle.make,
            model: dealTrackerData.vehicle.model
        },
        validationSchema: Yup.object({
            orderNumber: Yup.string(),
            //vin: Yup.string().vinOnly(),
            stockNumber: Yup.string(),
            year: Yup.string(),
            make: Yup.string(),
            model: Yup.string()

        }),
        onSubmit: (values) => {
            const formData = {
                orderNumber: values.orderNumber,
                style: dealTrackerData.vehicle.style,
                vin: values.vin,
                stockNumber: values.stockNumber,
                year: values.year,
                make: values.make,
                model: values.model,
            };

            const trimType = trimTypes.karl;
            if (dealTrackerData.vehicle.vin && dealTrackerData?.vehicle?.isAddedByVin) {
                formData['vin'] = dealTrackerData.vehicle.vin;
                if (values.stockNumber || values.orderNumber) {

                    validateDealerTradeCheck();
                }
                else {
                    updateVehicleDetails(formData);
                }

            }
            else {
                if (values.vin && dealTrackerData?.vehicle?.isAddedByVin) {

                    dispatch<any>(handleGetTrimUsingVin({
                        vin: values.vin, trimType,
                        callback: (trimData) => {
                            if (!trimData.status) {
                                if (trimData.style.length === 0) {
                                    setvinDataError(true);
                                }
                                else {
                                    setTrimData(trimData);
                                    if (values.stockNumber || values.orderNumber || (values.vin && !dealTrackerData?.vehicle?.vin)) {
                                        validateDealerTradeCheck(trimData);
                                    }
                                    else {
                                        handleSaveData(trimData);
                                    }
                                }

                            } else if (trimData.status === 409) {
                                // onDismiss('Failed to Save Data.');
                                vehFound(trimData.data);
                            }
                        }
                    }));

                } else if (values.vin && !dealTrackerData?.vehicle?.isAddedByVin) {
                    validateDealerTradeCheck();
                } else {
                    if (values.stockNumber || values.orderNumber || (values.vin && !dealTrackerData?.vehicle?.vin)) {
                        validateDealerTradeCheck();
                    }
                    else {
                        updateVehicleDetails(formData);
                    }
                }
            }
        }
    });

    const validateDealerTradeCheck = (trimData = null) => {
        const data = {
            stockNumber: formik.values.stockNumber,
            orderNumber: formik.values.orderNumber,
            vin: dealTrackerData && dealTrackerData.vehicle.vin ? null : formik.values.vin
        };
        dispatch<any>(handleDealerTradeCheck({
            id: dealId,
            data,
            callback: (res) => {
                if (res && res.conflicts) {
                    res.conflicts.forEach(function (item, index) {

                        let obj = {
                            tradeDate: moment(item.createdAt.date).local().format('MM/DD/YYYY'),
                            tradeMgr: item.createdBy?.firstName + ' ' + item.createdBy?.lastName,
                            tradeType: item.type === 1 ? "Our Trade" : "Their Trade",
                            vin: null,
                            stockNumber: null,
                            orderNumber: null
                        };

                        if (data.vin === item.vehicleIn?.vin || data.vin === item.vehicleOut?.vin) {
                            obj.vin = data.vin;
                        }
                        if (data.stockNumber === item.vehicleIn?.stockNumber || data.stockNumber === item.vehicleOut?.stockNumber) {
                            obj.stockNumber = data.stockNumber;
                        }
                        if (data.orderNumber === item.vehicleIn?.orderNumber || data.orderNumber === item.vehicleOut?.orderNumber) {
                            obj.orderNumber = data.orderNumber;
                        }
                        setConflictData(x => [...x, obj]);

                    });
                    setModalIndex(2);
                }
                else {
                    handleSaveData(trimData)
                }
            }
        }));
    }

    const handleYearChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                year: value ? value : '',
            }
        );
    };
    const handleEditVehicleFlow = () => {
        if (dealTrackerData?.vehicle?.isAddedByVin) {
            addVehicleSectionIndex(1)
        } else {
            addVehicleSectionIndex(2)
        }
    };

    const handleSaveData = (trimData) => {

        const formData = {
            orderNumber: formik.values.orderNumber,
            style: dealTrackerData.vehicle.style,
            vin: dealTrackerData && dealTrackerData.vehicle.vin ? dealTrackerData.vehicle.vin : formik.values.vin,
            stockNumber: formik.values.stockNumber,
            year: formik.values.year,
            make: formik.values.make,
            model: formik.values.model,
        };
        if (trimData) {
            if (trimData.style.length > 1) {
                const values = trimData.style.map((item, index) => {
                    return { key: item, text: item };
                });
                openTrimDialog({
                    vin: formData.vin,
                    trim: values,
                    ymm: null,
                    order: formData.orderNumber,
                    stock: formData.stockNumber
                });

            }
            else if (trimData.style.length === 1) {
                formData['style'] = trimData.style[0];
                updateVehicleDetails(formData);
            }
        }
        else {
            updateVehicleDetails(formData);
        }
    }

    useEffect(() => {
        formik.setValues(
            {
                ...formik.values,
                "vin": dealTrackerData.vehicle.vin,
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealTrackerData])

    const updateVehicleDetails = (formData) => {

        if (formik.values.stockNumber) {
            //  checkVehicleStockExist(formData); 
            checkVehicleTrackerStockNumberExist(formData);
        } else if (formik.values.orderNumber) {
            checkVehicleOrderExist(formData);
        }
        else {
            updateVehicleStockItem(formData);
        }

    }

    //  const checkVehicleTrackerStockNumberExist = (data) => {
    //     let formData = { stockNumber: formik.values.stockNumber };
    //      dispatch<any>(handleVehicleTrackerCheckStock({
    //          id: dealId,
    //          formData,
    //          callback: (res) => {
    //              if (res.id) {
    //                  checkVehicleStockExist(data); 
    //              } else if (res.conflicts) {
    //                  vtStockFoundDealData(res)  
    //                  onDismiss('Edit vehicle tracker stock Number found');   
    //              }
    // 
    //          }
    //      }))
    //  }
    // 
    const checkVehicleTrackerStockNumberExist = (data) => {
        let formData = { stockNumber: formik.values.stockNumber };
        dispatch<any>(handleVehicleTrackerCheckStock({
            id: dealId,
            formData,
            callback: (res) => {
                if (res.id) {
                    if (formik.values.stockNumber) {
                        checkVehicleStockExist(data);
                    }

                } else if (res) {
                    vtStockFoundDealData(res.conflicts)
                    onDismiss('Edit Existing Vin');
                    if (formik.values.stockNumber) {
                        checkVehicleStockExist(data);

                    }

                } else {
                    onDismiss('Failed to Save Data.');
                }

            }
        }));
    }

    const checkVehicleStockExist = (formData) => {
        let data = { stockNumber: formik.values.stockNumber };
        dispatch<any>(handleCheckVehicleStock({
            id: dealId,
            data,
            callback: (res) => {
                if (res.id) {
                    if (formik.values.orderNumber) {
                        checkVehicleOrderExist(formData);
                    } else {
                        updateVehicleStockItem(formData);
                    }

                } else if (res) {
                    stockFoundDealData(res.conflicts)
                    stockConflictData(formData)
                    if (formik.values.orderNumber) {
                        checkVehicleOrderExist(formData, true);
                    } else {
                        onDismiss('Edit Existing Vin');
                    }
                } else {
                    onDismiss('Failed to Save Data.');
                }

            }
        }));
    }

    const checkVehicleOrderExist = (formData, isStockExist = false) => {

        let data = { orderNumber: formik.values.orderNumber };
        dispatch<any>(handleCheckVehicleOrder({
            id: dealId,
            data,
            callback: (res) => {
                if (res.id) {
                    if (isStockExist) {
                        onDismiss('Edit Existing Vin');
                    } else {
                        updateVehicleStockItem(formData);
                    }
                } else if (res) {
                    orderFoundDealData(res.conflicts)
                    orderConflictData(formData)
                    onDismiss('Edit Existing Vin');
                } else {
                    onDismiss('Failed to Save Data.');
                }

            }
        }));
    }

    const updateVehicleStockItem = (formData) => {

        dispatch<any>(handleUpdateVehicleStockItem({
            id: dealId,
            vehicleId: dealTrackerData.vehicle.id,
            formData,
            callback: (res) => {
                if (res.id) {
                    onDismiss('');
                } else if (res.status === 409) {
                    // onDismiss('Failed to Save Data.');
                    vehFound(res.data);
                } else {
                    onDismiss('Failed to Save Data.');
                }
            }
        }));
    }

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    const changeToUppercase = (e: any) => {
        const re = /^[a-zA-Z0-9]*$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            //this.setState({ value: e.target.value })
            formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
        }
    };

    const handleUpdateDeal = () => {
        formik.handleSubmit()
    }

    const handleScanVIN = (bool) => {
        if (dealTrackerData?.vehicle?.vin && dealTrackerData?.vehicle?.vin !== '') {
            // On disabled scenario
        } else {
            setvinDataError(false);
            setisVINScan(bool)
        }
    }

    const onDismissModal = () => {
        if (isVINScan) {
            handleScanVIN(false);
        }
        else {
            onDismiss('')
        }
    }

    const onScanSubmit = (vin) => {
        let capturedVIN = vin.split(',');
        // When scanned with QR Code, contains multiple options. so picking VIn data alone
        let truncatedVIn = capturedVIN[0];
        if (capturedVIN[0].length === 18) {
            // When contains LEADING letter I
            truncatedVIn = capturedVIN[0].substring(1);
        }
        formik.setFieldValue("vin", truncatedVIn.trim().toUpperCase());
        handleScanVIN(false);
    }

    return (
        <>
            {
                modalIndex === 1 && (<Modal
                    isOpen={isOpen}
                    onDismiss={onDismissModal}
                    isBlocking={true}
                    styles={{ root: { zIndex: 10 } }}
                    className="createDealRecord vehicleInfoHeights updateEmployeeDialogHeight"
                    containerClassName={`${contentStyles.container} inv-srch-mod ${isVINScan ? 'scanner' : ''}`}
                >
                    <div className={contentStyles.header + " inventory-popup"}>
                        <CustomFontText className="formTitle">Edit Vehicle Info</CustomFontText>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={onDismiss}
                        />
                    </div>
                    <div>{
                        vinDataError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>VIN is not recognized, please re-enter VIN.</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                    </div>
                    {isVINScan ?
                        (<VinScanner onScanSubmit={(vin) => onScanSubmit(vin)} />)
                        :
                        <>
                            <div className={contentStyles.body} >
                                <Stack>
                                    <Row justify="center">
                                        <Col md={9}>
                                            <TextField
                                                id="stockNumber"
                                                name="stockNumber"
                                                label="Stock No"
                                                placeholder=""
                                                autoComplete="off"
                                                value={formik.values.stockNumber}
                                                //required={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched && formik.touched.stockNumber &&
                                                    formik.errors && formik.errors.stockNumber &&
                                                    formik.errors.stockNumber.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row justify="center">
                                        <Col md={9} >
                                            <TextField
                                                id="orderNumber"
                                                name="orderNumber"
                                                label="Order No"
                                                placeholder=""
                                                autoComplete="off"
                                                value={formik.values.orderNumber}
                                                //required={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                errorMessage={
                                                    formik.touched && formik.touched.orderNumber &&
                                                    formik.errors && formik.errors.orderNumber &&
                                                    formik.errors.orderNumber.toString()
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="inventoryRowSection" justify="center">
                                        <Col md={9} >
                                            <div className="yearMake">
                                                <h5 className="primeDropdownLabel" style={dealTrackerData?.vehicle?.isAddedByVin ? { color: 'gray', margin: "5px 0" } : { margin: "5px 0" }} >Year</h5>
                                                <PRDropdown
                                                    id="model_addInventory"
                                                    value={formik.values.year}
                                                    className="invMakeDropdown labelTextBorder full-width"
                                                    options={yearValues}
                                                    onChange={(e) => handleYearChange(e.value)}
                                                    optionLabel="text"
                                                    optionValue="text"
                                                    scrollHeight="150px"
                                                    virtualScrollerOptions={{ itemSize: 31 }}
                                                    disabled={dealTrackerData?.vehicle?.isAddedByVin}
                                                    placeholder="Select a Year"
                                                    appendTo="self"
                                                    filter
                                                    resetFilterOnHide={true}
                                                    required={true} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row justify="center">
                                        <Col md={9} >
                                            <TextField
                                                id="make"
                                                name="make"
                                                label="Make"
                                                placeholder=""
                                                autoComplete="off"
                                                value={formik.values.make}
                                                //required={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={dealTrackerData?.vehicle?.isAddedByVin}
                                            /* errorMessage={
                                                formik.touched && formik.touched.make &&
                                                formik.errors && formik.errors.make &&
                                                formik.errors.make.toString()
                                            } */
                                            />
                                        </Col>
                                    </Row><Row justify="center">
                                        <Col md={9} >
                                            <TextField
                                                id="model"
                                                name="model"
                                                label="Model"
                                                placeholder=""
                                                autoComplete="off"
                                                value={formik.values.model}
                                                //required={true}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={dealTrackerData?.vehicle?.isAddedByVin}
                                            /* errorMessage={
                                                formik.touched && formik.touched.model &&
                                                formik.errors && formik.errors.model &&
                                                formik.errors.model.toString()
                                            } */
                                            />
                                        </Col>
                                    </Row>
                                    <Row justify="center">
                                        <Col md={9} >
                                            {
                                                dealTrackerData?.vehicle?.isAddedByVin ?
                                                    <>
                                                        <div>
                                                            <div style={{ width: "180px" }}>
                                                                <TextField
                                                                    id="vin"
                                                                    name="vin"
                                                                    label="VIN"
                                                                    iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true) }}
                                                                    value={formik.values.vin}
                                                                    disabled={true}
                                                                    onChange={changeToUppercase}
                                                                    onBlur={formik.handleBlur}
                                                                    errorMessage={
                                                                        formik.touched.vin &&
                                                                        formik.errors.vin &&
                                                                        formik.errors.vin.toString()
                                                                    }

                                                                />
                                                            </div>
                                                            <PrimaryButton
                                                                //disabled={!formik.dirty || !formik.isValid}
                                                                text="Edit"
                                                                onClick={onVinEdit}
                                                                className="vehicle-vin-edit-btn"
                                                            />
                                                        </div>
                                                    </> :
                                                    <>
                                                        <TextField
                                                            id="vin"
                                                            name="vin"
                                                            label="VIN"
                                                            iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true) }}
                                                            value={formik.values.vin}
                                                            //disabled={(dealTrackerData?.vehicle?.vin) ? dealTrackerData.vehicle.vin !== '' : false}
                                                            onChange={changeToUppercase}
                                                            onBlur={formik.handleBlur}
                                                            errorMessage={
                                                                formik.touched.vin &&
                                                                formik.errors.vin &&
                                                                formik.errors.vin.toString()
                                                            }
                                                        // style={{ width: "calc(100% - 130px)" }}
                                                        />
                                                    </>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="noVin" justify="center">
                                        <Col md={9}>
                                            <Link onClick={handleEditVehicleFlow} style={{ float: "right", marginTop: "10px" }}>
                                                {dealTrackerData?.vehicle?.isAddedByVin ? "Add vehicle manually" : "Add vehicle by VIN"}
                                            </Link>
                                        </Col>
                                    </Row>
                                </Stack>
                            </div>
                            <div className={contentStyles.footer}>
                                <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="center">

                                    <PrimaryButton
                                        //disabled={!formik.dirty || !formik.isValid}
                                        onRenderIcon={renderSpinner}
                                        text="Save"
                                        onClick={handleUpdateDeal}
                                    />
                                </Stack>
                            </div>
                        </>
                    }
                </Modal>)
            }
            {
                modalIndex === 2 ?
                    <DealerTradeFoundDialog
                        conflictData={conflictData}
                        onDismiss={() => { onDismiss(''); }}
                        onSave={() => { handleSaveData(trimData) }}
                    />
                    : null
            }
        </>

    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 400,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "5px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: "34px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 15,
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
