import React, { useState, FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    //DefaultButton,
    IconButton,
    TextField,
    Stack
} from "@fluentui/react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-grid-system";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
//import { AppState } from "store";
import { handleSellVehicle,handlePurchaseVehicleUndo } from "store/inventory/inventory.action";
import { Calendar } from 'primereact/calendar';
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
import { convertLocalToUTCModified } from "utils/dateTimeFunctions";
//import { useNavigate  } from "react-router-dom";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    vehicleId: number | string;
    purchaseDates?:any;
}

//const yearValues = dropDownsValues.yearValues.map((item) => {
//    return { key: item, text: item };
//});
const yearRange = "1980:" + (new Date().getFullYear()+25);
export const SellInventoryDialog: FunctionComponent<IProps> = ({
    onDismiss,
    vehicleId,
    purchaseDates
}) => {
     

    const [purchaseDate, setPurchaseDate] = useState<Date | Date[] | undefined |any>(new Date());
    const [blurFlag, setBlurFlag] = useState(false);
    //const navigate = useNavigate ();
    const dispatch = useDispatch();
    const purchaseDateVal = new Date(purchaseDates).toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
    const formik = useFormik({
        initialValues: {
            soldDate: "",
            sellingPrice: "",
        },
        validationSchema: Yup.object({
            soldDate: Yup.string().nullable().test("rules", "Required.", (val) => {
                return val !== null;
            }),
            sellingPrice: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const sellInfo = {
                soldDate: values.soldDate ? convertLocalToUTCModified (values.soldDate) : convertLocalToUTCModified(purchaseDate),
                sellingPrice: values.sellingPrice.replace('$', '').replace(',', ''),
                id: vehicleId,
            };
            dispatch<any>(handleSellVehicle(sellInfo, (res) => {
                onDismiss(res);
            }));
        },
    });

    const handleSaveSell = () => {
        formik.handleSubmit();
    };

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                'soldDate': val
            }
        );
        setPurchaseDate(val);
    };

    const handleDateBlur = () => {
        setBlurFlag(true);
    }

    // const handlePriceChange = (e) => {
        
    //     formik.setValues(
    //         {
    //             ...formik.values,
    //             "sellingPrice": e
    //         }
    //     );
    // };

    const handleClosePopup = () => {
        onDismiss('');
    }

    const handleUndo =() => {
            
            dispatch<any>(handlePurchaseVehicleUndo({id:vehicleId, callback: (res) => {
                onDismiss('');
            }}));
    }

    return (
        <Modal
            isOpen={true}
            onDismiss={handleClosePopup}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <span className="formTitle">Sell Vehicle</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleClosePopup}
                />
            </div>
            <div style={{ overflow: "hidden",width:"auto",height:"auto"}} className={`${contentStyles.body}`} >
                <Stack
                    styles={stackStyles}
                    className="sell-vehicle-dialog-width"
                    verticalAlign="start"
                    horizontalAlign="space-between"
                >
                    <div >
                        <div style={{margin:"0px 50px"}}>
                        <Row className="purchase-row" justify="center">
                                
                                         <Col md={6}>
                                                <div> <label className="lbl-text">Purchase Date</label> </div> <div> {purchaseDateVal} </div>
                                         </Col>
                                         <Col md={6}>
                                                   
                                                   <PrimaryButton
                                                          className="sellDiaUndoBut"
                                                          style={{ float: "right" }}
                                                          //disabled={!formik.dirty || !formik.isValid}
                                                          text="Undo"
                                                          onClick={handleUndo}
                                                   />
                                         </Col>
                            </Row>
                            <Row className="purchase-row" justify="center">
                                <Col sm={10} md={10} xs={12} >
                                    <div className="ms-TextField">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Sold Date</label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                <Calendar
                                                    id="navigatorstemplate"
                                                    className="modal-calendar"
                                                    value={purchaseDate}
                                                    style={{ width: "100%" }}
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e.value)}
                                                    onBlur={handleDateBlur}
                                                    monthNavigator
                                                    required={true}
                                                    appendTo="self"
                                                    yearRange={yearRange}
                                                    yearNavigator />
                                            </div>
                                            <span className="error-message">
                                                {blurFlag && formik.errors && formik.errors.soldDate &&
                                                    formik.errors.soldDate.toString()}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="purchase-row" justify="center">
                                <Col sm={10} md={10} xs={12}>
                                <label className="lbl-text required-Label">Selling Price</label>
                                    <CurrencyInput
                                        customInput={TextField}
                                        allowNegativeValue={false}
                                        prefix={PRICE_PREFIX}
                                        id="sellingPrice"
                                        name="sellingPrice"
                                        //label="Selling Price"
                                        decimalScale={2}
                                        //required={true}
                                        value={formik.values.sellingPrice?formik.values.sellingPrice.replace(/,/g, ''):''}
                                        onValueChange ={(e) => {
                                            formik.setValues(
                                                {
                                                    ...formik.values,
                                                    "sellingPrice": e
                                                }
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="error-message">
                                        {
                                            formik.errors.sellingPrice &&
                                            formik.errors.sellingPrice.toString()}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="purchase-row" >
                                <Col style={{ textAlign:"center",marginBottom:"10px" }} sm={12} md={12} xs={12}>
                                    <PrimaryButton
                                        className="decodeBtn"
                                        disabled={!formik.dirty || !formik.isValid}
                                        text="Save"
                                        onClick={handleSaveSell}
                                    />
                                </Col>
                            </Row>
                        </div>

                    </div>
                </Stack>
            </div>


        </Modal>
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
