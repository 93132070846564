import React from "react";
import {
  Panel,
  PanelType,
  IPanelStyles,
} from "@fluentui/react/lib/Panel";
import {
  DefaultButton,
  getTheme,
  IconButton,
  PrimaryButton,
  Stack,
  IStackStyles,
  IButtonStyles,
  Dropdown,
  TextField,
} from "@fluentui/react";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { dropdownStyles, filterPanelStyles as content } from "constants/styles";
import { useSelector, useDispatch } from "react-redux";
import { setInventoryFilters } from "store/inventory/inventory.action";
import { AppState } from "store";
import inventoryDropdownsValues from "constants/inventoryDropdownsValues";

const driveTypeValues = inventoryDropdownsValues.driveTypeValues.map((item) => {
  return { key: item, text: item };
});
const fuelTypeValues = inventoryDropdownsValues.fuelTypeValues.map((item) => {
  return { key: item, text: item };
});
const engineCylindersValues = inventoryDropdownsValues.engineCylindersValues.map(
  (item) => {
    return { key: item.name, text: item.name };
  }
);
const transmissionValues = inventoryDropdownsValues.transmissionValues.map(
  (item) => {
    return { key: item, value: item, text: item };
  }
);

const contentStyles = content();
const theme = getTheme();
const panelStyles: Partial<IPanelStyles> = {
  commands: {
    marginTop: 0,
  },
};

const closeBtnStyles: IButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const stackTokens = { childrenGap: 10 };

const stackStyles: IStackStyles = {
  root: {
    marginTop: 20,
  },
};

interface IProps {
  isOpen: boolean;
  label: string;
  onClose: () => void;
  cleanFilters: () => void;
  handleFilter: () => void;
}

const EngineAndDrivePanel: React.FC<IProps> = ({
  isOpen,
  label,
  onClose,
  cleanFilters,
  handleFilter,
}) => {
  const filters = useSelector((state: AppState) => state.inventory.filters);
  const dispatch = useDispatch();

  const handleChange = (e, item) => {
    dispatch<any>(
      setInventoryFilters({
        ...filters,
        [e.target.id]: item.text,
      })
    );
  };

  const handleChangeEngineDescription = (e, item) => {
    dispatch<any>(setInventoryFilters({...filters, [e.target.id]: item}))
  }

  const handleChangeEngineCylinders = (e, item) => {
    dispatch<any>(setInventoryFilters({
      ...filters, 'engineCylinders': item.text
    }))
  }

  return (
    <Panel
      styles={panelStyles}
      type={PanelType.medium}
      isOpen={isOpen}
      onDismiss={onClose}
      hasCloseButton={false}
      onRenderHeader={() => (
        <div className={contentStyles.bottomShadow}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ width: "100%" }}
          >
            <GoBackToMainDialogBtn label={label} onClick={onClose} />

            <div className={contentStyles.marginRight}>
              <IconButton
                styles={closeBtnStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={onClose}
              />
            </div>
          </Stack>
        </div>
      )}
      onRenderFooter={() => (
        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">
            <DefaultButton text="Clear" onClick={cleanFilters} />
            <PrimaryButton text="View results" onClick={handleFilter} />
          </Stack>
        </div>
      )}
    >

      <Stack tokens={stackTokens} styles={stackStyles}>
        <Dropdown
          id="driveType"
          label="Drive Type"
          options={driveTypeValues}
          styles={dropdownStyles}
          placeholder="any..."
          onChange={handleChange}
          selectedKey={filters.driveType}
        />
        <Dropdown
          id="fuelType"
          label="Fuel Type"
          placeholder="any..."
          options={fuelTypeValues}
          styles={dropdownStyles}
          selectedKey={filters.fuelType}
          onChange={handleChange}
        />

        <Dropdown
          id="engineCylinders"
          label="Engine Cylinders"
          placeholder=""
          options={engineCylindersValues}
          styles={dropdownStyles}
          selectedKey={filters.engineCylinders}
          onChange={handleChangeEngineCylinders}
        />

        <TextField
          id="engineDescription"
          name="engineDescription"
          label="Engine Description"
          suffix="cm3"
          value={filters.engineDescription}
          onChange={handleChangeEngineDescription}
        />

        <Dropdown
          id="transmission"
          label="Transmission"
          placeholder=""
          options={transmissionValues}
          styles={dropdownStyles}
          selectedKey={filters.transmission}
          onChange={handleChange}
        />
      </Stack>
    </Panel>
  );
};

export default EngineAndDrivePanel;
