import React from "react";
import { Stack, IStackStyles } from "@fluentui/react";
// import { CustomFontText } from "components/customFontText/CustomFontText";
//import { ReactComponent as Logo } from "assets/FiQorelogo.svg";
import { Link, ILinkStyles } from '@fluentui/react/lib/Link';

import Logo from "components/svg/logo/Logo";
import { useMediaQuery } from "react-responsive";
import { queries } from "constants/constants";

const styles: IStackStyles = {
  root: {
    position: "absolute",
    left: 50,
    top: 30,
  },
};

const tabletStyles: IStackStyles = {
  root: {
    position: "absolute",
    left: 20,
    top: 30,
  },
};

const linkStyles: ILinkStyles = {
  root: {
      color: 'black',
      fontSize: 12,
      marginRight: 30
  }
};

const AuthHeader = () => {
  const isTablet = useMediaQuery(queries.tablet);

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={isTablet ? tabletStyles : styles}
    >
      <Link className="ln-nw-link" styles={linkStyles} href="https://www.fiqore.com/" target="_blank"><Logo /></Link>
      {/*<CustomFontText style={{ marginLeft: 50 }} size={14} bold>*/}
      {/*  APPLICATION NAME*/}
      {/*</CustomFontText>*/}
    </Stack>
  );
};

export default AuthHeader;
