import React, { FC } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { createPortal } from "react-dom";
interface IProps {
  show?: boolean;
}
const Loader: FC<IProps> = ({ show }) => {
  const loading = useSelector((state: AppState) => state.globalLoading.loading);
  return loading
    ? createPortal(
        <div className="loader">
          <Spinner id="spinner" size={SpinnerSize.large} />
        </div>,
        document.body
      )
    : null;
};

export default Loader;
