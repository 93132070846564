import React, {Component} from 'react'
//import moment from 'moment'
//import 'moment/locale/zh-cn';
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'; // DATE_FORMAT
import withDragDropContext from './withDnDContext';
import { Col, Row } from "react-grid-system";
import 'react-big-scheduler/lib/css/style.css';
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { getLocationsForDropdown } from "store/locations/locations.api";
import moment from "moment";
import dropDownsValues from "constants/inventoryDropdownsValues";
import {
    PrimaryButton
} from "@fluentui/react";
import { connect } from "react-redux";
import masterDropdownValues from 'constants/masterDropdownValues';
// import { handleGetAllFinanceDeliveryPerson } from 'store/dealTracker/dealTracker.action';
import { HANDLE_GET_ALL_FIN_Del_PERSON } from 'store/dealTracker/dealTracker.types';
import { TimeOffDialog } from 'components/dialogs/dealTrackerDialog/TimeOffDialog';
import { HANDLE_EMPLOYEE_GET_MONTH_CAL } from 'store/employees/employees.types';
import { TempAvailableDialog } from 'components/dialogs/dealTrackerDialog/TempAvailableDialog';
import { switchGlobalLoader } from 'store/globalLoading/globalLoading.action';
import { withRouter } from 'utils/withRouter';
 
interface IProps {
    handleGetResources?: Function;
    handleGetMonthlyCal?: Function;
    switchGlobalLoader?: Function;
    changeToDaily?:any;
    navigate: any;
    setLocationId: any;
    userRole: any;
}

interface IState {
    viewModel: any;
    resourcetype: any;
    locations: any;
    selectedLocation: any;
    selectedMonth: any;
    selectedYear: any;
    yearList: any;
    financeResource: any;
    deliveryResource: any;
    resouceData: any;
    isOpenVacationDaysDialog: boolean;
    isOpenTemAvailDialog: boolean;
    selectedData: any;
    editableAction: boolean;
}

enum dayOfTheWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

class BasicSchedule extends Component<IProps, IState> {
    constructor(props){
        super(props);
        //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
        let schedulerData = new SchedulerData(new Date(), ViewTypes.Month, false, false, {
            // minuteStep: 15
        });
        let events = [
            {
                //bgColor: "#D9D9D9",
                end: "2022-04-05 23:30:00",
                id: 1,
                resourceId: 151,
                showPopover: false,
                start: "2022-04-05 12:30:00",
                title: "OOO"
            },
            {
                end: "2022-04-12 23:30:00",
                id: 2,
                resizable: false,
                resourceId: 127,
                start: "2022-04-11 12:30:00",
                title: "Booked"
            },
            {
                end: "2022-04-15 23:30:00",
                id: 3,
                movable: false,
                resourceId: 108,
                start: "2022-04-14 12:30:00",
                title: "Not Available"
            },
            {
                end: "2022-04-15 12:30:00",
                id: 4,
                resourceId: 106,
                start: "2022-04-14 23:30:00",
                startResizable: false,
                title: "Booked"
            },
            {
                bgColor: "red",
                end: "2022-04-01 14:30:00",
                id: 8,
                movable: false,
                resizable: false,
                resourceId: 87,
                start: "2022-04-01 11:50:00",
                title: "Booked"
            }
        ];
        
        // schedulerData.setEvents(events);
        let selectedMonth: any = moment.utc(new Date().getMonth()+1);
        let selectedYear: any = moment.utc(new Date().getFullYear());
        this.state = {
            viewModel: schedulerData,
            resourcetype: 1,
            locations: [],
            selectedLocation: null,
            selectedMonth: selectedMonth?._i,
            selectedYear: selectedYear?._i,
            yearList: [],
            financeResource: [],
            deliveryResource: [],
            resouceData: events,
            isOpenVacationDaysDialog: false,
            isOpenTemAvailDialog: false,
            selectedData: null,
            editableAction: false
        }
    }

    componentDidMount() {        
        const yearValues = dropDownsValues.yearValues.map((item) => {
            return { key: item, text: item };
        });
        if (this.props.userRole) {
            this.setState({
                editableAction: true,
            });
        }

        // get locations
        const fetchLocations = async () => {
          const { data } = await getLocationsForDropdown();
          const options = data?.results.map((item) => {
            return { key: item.id, text: item.legalName };
          });
          console.log('locationss', options)
          let locationId = this.props.setLocationId ? this.props?.setLocationId:  (options?.length) ? options[0]?.key : null;
          this.setState({
              locations: options,
              selectedLocation: locationId,
              yearList: yearValues
          })
          // fetch resource List
          this.updateMonthlyCalendar(locationId, () => {
            this.getMonthlyCalendarList(locationId, this.state.selectedMonth,this.state.selectedYear, 1);
          });

          // get Monthly calendar list
          
        };
        // setIssuedDate(null);
        fetchLocations().then();
    }

    getMonthlyCalendarList(locationId, month, year, resourcetype) {
        let monthlyData = {
            locationId : Number(locationId),
            month : Number(month),
            year : Number(year),
            role : resourcetype === 1 ? 'financePerson': 'deliveryCoordinator'
        };
        // get Monthly Calendar events
        let events = [];
        this.props.handleGetMonthlyCal({ data: monthlyData , callback: (res) =>  {
            if (res?.length) {
                this.props.switchGlobalLoader(true);
                let index = 0;
                res.map((empl) => {
                    let emplEve = Object.keys(empl.employeeEvents).map(key => {
                        return empl.employeeEvents[key];
                    })
                    let emplRevEve = Object.keys(empl.employeeRecurringPatterns).map(key => {
                        return empl.employeeRecurringPatterns[key];
                    })

                    // emplEve.push(empl.employeeEvents);
                    emplRevEve.map((eve) => { //dayOfTheWeek
                        let selectedDate: any = this.state.selectedYear + '-' + this.state.selectedMonth + '-' + eve.dayOfTheWeek;
                        // let dayWeek: any =  moment.utc(new Date(selectedDate)).format('MM/DD/YY')
                        let dayWeek: any = moment(new Date(selectedDate)).local().startOf('month').day(dayOfTheWeek[eve.dayOfTheWeek]);

                        if (dayWeek.date() > 7) dayWeek.add(7,'d');
                        // let month = dayWeek.month();
                        index++;
                        while(((month > 0) ? (month-1) : month) === dayWeek.month()){
                            let objEvt = {
                                id: index,
                                end: moment(new Date(dayWeek)).local().format('YYYY-MM-DD') + " 23:30:00",
                                resourceId: empl.id,
                                showPopover: false,
                                start: moment(new Date(dayWeek)).local().format('YYYY-MM-DD') + " 12:30:00",
                                title: '', // Recurring for green
                                bgColor: "#70af60",
                                eventId: eve.id,
                            }
                            let duplicate = false;
                            /* emplEve.map((eve) => {
                                let subst = eve.date;
                                if (eve.date) {
                                    let rawExpenseDate = eve.date;
                                    subst = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0,rawExpenseDate.indexOf("T")) : rawExpenseDate;
                                }
                                if (moment(new Date(subst)).format("YYYY-MM-DD") === moment(new Date(dayWeek)).format("YYYY-MM-DD")) {
                                    duplicate = true;
                                }
                                return duplicate
                            }); */
                            if (!duplicate) {
                                if (eve?.location?.id === Number(locationId)) {
                                    events.push(objEvt);
                                }
                            }
                            dayWeek.add(7,'d');
                            index++;
                        }
                        // schedulerData.setEvents(events);
                        return events;
                    })
                    emplEve.map((eve) => {
                        index++;
                        let objEvt = {
                            id: index,
                            end: moment.utc(new Date(eve.date)).format("YYYY-MM-DD") + " 23:30:00",
                            resourceId: empl.id,
                            type: eve.type,
                            showPopover: false,
                            start: moment.utc(new Date(eve.date)).format("YYYY-MM-DD") + " 12:30:00",
                            title: eve.type === 1 ? 'Vacation' : eve.type === 2 ? 'Sat./Others' : '',
                            bgColor: (eve.type === 1 || eve.type === 2) ? "#ff8000" : "#0065ca",
                            eventId: eve.id,
                        }
                        if (eve.type === 3) {
                            objEvt['startsAt'] = eve.startsAt;
                            objEvt['endsAt'] = eve.endsAt;
                        }
                        //if (eve?.location?.id === Number(locationId)) {
                            return events.push(objEvt);
                        /* } else {
                            return events;
                        } */
                    })
                    return true;
                });
                /* this.setState({
                    viewModel: null
                }); */
                
                setTimeout(() => {
                    let schedulerData = this.state.viewModel;
                    let monthRaw = this.state.selectedMonth;
                    if (Number(monthRaw) < 10) {
                        monthRaw = '0'+ Number(monthRaw)
                    }
                    let selectedDate: any = this.state.selectedYear + '-' + monthRaw + '-01';
                    
                    schedulerData.setDate(moment.utc(new Date(selectedDate)).format("YYYY-MM-DD"));
                    if (resourcetype === 1) {
                        schedulerData.setResources(this.state.financeResource)
                    } else {
                        schedulerData.setResources(this.state.deliveryResource)
                    }
                    schedulerData.setEvents(events);
                    this.setState({
                        viewModel: schedulerData,
                        resouceData: events,
                    })
                    this.props.switchGlobalLoader(false);
                }, 1200)
                // map to events
            }
        }})
    }

    handleToggleChange(flag) {
        this.setState({
            resourcetype: flag
        })
        if (flag === 1) {
            /* let schedulerData = this.state.viewModel; 
            let selectedDate: any = this.state.selectedYear + '-' + this.state.selectedMonth + '-01';
            schedulerData.setDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
            schedulerData.setResources(this.state.financeResource)
            schedulerData.setEvents(this.state.resouceData);
            this.setState({
                viewModel: schedulerData
            }) */
            this.getMonthlyCalendarList(this.state.selectedLocation, this.state.selectedMonth,this.state.selectedYear, 1)
        } else {
            /* let schedulerData = this.state.viewModel; 
            let selectedDate: any = this.state.selectedYear + '-' + this.state.selectedMonth + '-01';
            schedulerData.setDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
            schedulerData.setResources(this.state.deliveryResource)
            schedulerData.setEvents(this.state.resouceData);
            this.setState({
                viewModel: schedulerData
            }) */
            this.getMonthlyCalendarList(this.state.selectedLocation, this.state.selectedMonth,this.state.selectedYear, 2)
        }
    }

    handleLocationChange(e, item) {
        this.setState({
            selectedLocation: item.key
        })
        setTimeout(() => {
            // fetch resource List
            this.updateMonthlyCalendar(item.key, () => {
              this.getMonthlyCalendarList(item.key, this.state.selectedMonth,this.state.selectedYear, this.state.resourcetype);
            });
        }, 500)
    }

    updateMonthlyCalendar(locationId, callBk = null) {
        this.props.handleGetResources({ locationId: locationId , callback: (res) =>  {
            if (res) {
                // finance persons
                let financeResource = [];
                if (res.finance && res?.finance?.length) {
                    res.finance.map((data) => {
                        return financeResource.push({
                            id: data.id,
                            name: data.firstName + ' ' + data.lastName
                        });
                    });
                }
                
                // Delivery Person
                let deliveryResource = [];
                if (res.delivery && res?.delivery?.length) {
                    res.delivery.map((data) => {
                        return deliveryResource.push({
                            id: data.id,
                            name: data.firstName + ' ' + data.lastName
                        });
                    });
                }
                this.setState({
                    financeResource: financeResource,
                    deliveryResource: deliveryResource
                });
                if (callBk) {
                    callBk();
                }
                /* let schedulerData = this.state.viewModel; 
                let selectedDate: any = this.state.selectedYear + '-' + this.state.selectedMonth + '-01';
                schedulerData.setDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
                if (this.state.resourcetype === 2) {
                    schedulerData.setResources(deliveryResource)
                } else {
                    schedulerData.setResources(financeResource)
                }
                
                schedulerData.setEvents(this.state.resouceData);

                this.setState({
                    viewModel: schedulerData
                }) */
            }
        }});
    }

    handleMonthChange(e, item, props) {
        this.setState({
            selectedMonth: item.key
        })
        /* let selectedDate: any = this.state.selectedYear + '-' + item.key + '-01';
        let schedulerData = this.state.viewModel; 
        // moment(new Date(selectedDate)).format("YYYY-MM-DD")
        schedulerData.setDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
        schedulerData.setEvents(this.state.resouceData);
        this.setState({
            viewModel: schedulerData,
            selectedMonth: item.key
        }) */
        setTimeout(() => {
            this.getMonthlyCalendarList(this.state.selectedLocation, item.key,this.state.selectedYear, this.state.resourcetype)
        }, 2000)
    }

    handleYearChange(e, item, props) {
        this.setState({
            selectedYear: item.key
        })
        /* let selectedDate = item.key + '-' + this.state.selectedMonth + '-01'
        // moment(new Date(selectedDate)).format("YYYY-MM-DD")
        let schedulerData = this.state.viewModel; 
        schedulerData.setDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"));
        schedulerData.setEvents(this.state.resouceData);
        this.setState({
            viewModel: schedulerData,
            selectedYear: item.key
        }) */
        setTimeout(() => {
            this.getMonthlyCalendarList(this.state.selectedLocation, this.state.selectedMonth, item.key, this.state.resourcetype)
        }, 2000)
    }
    
    handleMonthlyCalendar(val) {
       this.props.changeToDaily(true);
    }

    handleVacationDaysDialogDismiss(msg) {
        this.setState({
            isOpenVacationDaysDialog: false
        });
        if (msg) {
            this.getMonthlyCalendarList(this.state.selectedLocation, this.state.selectedMonth,this.state.selectedYear, this.state.resourcetype)
        }
    }

    handleTempAvailsDialogDismiss(msg) {
        this.setState({
            isOpenTemAvailDialog: false
        });
        if (msg) {
            this.getMonthlyCalendarList(this.state.selectedLocation, this.state.selectedMonth,this.state.selectedYear, this.state.resourcetype)
        }
    }

    render(){
        const {viewModel} = this.state;
        return (
            <div>
            {this.state.isOpenVacationDaysDialog && (
                <TimeOffDialog
                    isOpen={this.state.isOpenVacationDaysDialog}
                    selectedData= {this.state.selectedData}
                    onDismiss={(msg) => this.handleVacationDaysDialogDismiss(msg)}
                />
            )}
 
            {this.state.isOpenTemAvailDialog && (
                <TempAvailableDialog
                    isOpen={this.state.isOpenTemAvailDialog}
                    selectedData= {this.state.selectedData}
                    selectionDetails= {null}
                    onDismiss={(msg) => this.handleTempAvailsDialogDismiss(msg)}
                />
            )}

            <Row>
                <Col>
                    <PrimaryButton
                        text="Finance"
                        onClick={() => this.handleToggleChange(1)}
                        className={this.state.resourcetype === 1 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                        style={{ marginRight: "3px" }}
                    />
                    <PrimaryButton
                        text="Delivery"
                        onClick={() => this.handleToggleChange(2)}
                        className={this.state.resourcetype === 2 ? "greenShadedText btnHoverColor" : "greenShadedTextColor btnHoverColor"}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={2} className="monthYearDropDown">
                    <div className="locationDropPosition">
                        <Dropdown
                            id="month"
                            label="Month"
                            //required={true}
                            selectedKey={this.state.selectedMonth}
                            //options={yearValues}
                            options={masterDropdownValues.getMonth}
                            onChange={(e, item) => this.handleMonthChange(e, item, this.props)}
                            placeholder=""
                            // styles={dropdownStyles}
                            //onBlur={formik.handleBlur}
                        />
                    </div>
                </Col>
                <Col md={2} className="monthYearDropDown">
                    <div className="locationDropPosition">
                        <Dropdown
                            id="year"
                            label="Year"
                            //required={true}
                            selectedKey={this.state.selectedYear}
                            //options={yearValues}
                            options={this.state.yearList}
                            onChange={(e, item) => this.handleYearChange(e, item, this.props)}
                            placeholder=""
                            //styles={dropdownStyles}
                            //onBlur={formik.handleBlur}
                        />
                    </div>
                </Col>
                <Col md={2}>
                    <div className="locationDropPosition">
                        <Dropdown
                            id="location"
                            label="Location"
                            //required={true}
                            selectedKey={this.state.selectedLocation}
                            //options={yearValues}
                            options={this.state.locations}
                            onChange={(e, item)=> this.handleLocationChange(e, item)}
                            placeholder=""
                            //styles={dropdownStyles}
                            //onBlur={formik.handleBlur}
                        />
                    </div>
                </Col>
                <Col md={3} className="legendsRow">
                    <div className="legends">
                        <span className="recurring"></span>
                        <span className='label'>Recurring</span>
                        
                        <span className="tempAvail"></span>
                        <span className='label'>Temp Avail</span>
                        
                        <span className="timeOff"></span>
                        <span className='label'>Time Off</span>
                    </div>
                </Col>
                <Col>
                        <PrimaryButton style={{ float: "right", margin: "30px 0 0" }}
                        onClick={() => this.handleMonthlyCalendar(this.props)}
                        >
                            Daily Calendar
                        </PrimaryButton>
                </Col>
            </Row>
            <div>
                <Scheduler schedulerData={viewModel}
                            prevClick={this.prevClick}
                            nextClick={this.nextClick}
                            onSelectDate={this.onSelectDate}
                            onViewChange={this.onViewChange}
                            eventItemClick={this.eventClicked}
                            viewEventClick={this.ops1}
                            //viewEventText="Ops 1"
                            //viewEvent2Text="Ops 2"
                            //viewEvent2Click={this.ops2}
                            //updateEventStart={this.updateEventStart}
                            //updateEventEnd={this.updateEventEnd}
                            moveEvent={this.moveEvent}
                            newEvent={this.newEvent}
                            slotClickedFunc={this.slotClickedFunc}
                            eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                />
            </div>
            </div>
        )
    }

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, slotId, slotName, start, end, type, item) => {
        if (this.state.editableAction) {            
            let resourceList = JSON.parse(JSON.stringify(schedulerData.resources));
            let resourceName = '';
            let resourceId = '';
            resourceList.forEach((item) => {
                if(item.id === slotId.resourceId) {
                    resourceName = item.name;
                    resourceId = item.id;
                }
            });
            
            let locationList = this.state.locations;
            let location = '';
            locationList.forEach((item) => {
                if(item.key === this.state.selectedLocation) {
                    location = item.text;
                }
            });

            // open TimeOff Popup to change from Recurring
            if (slotId?.bgColor === '#70af60') {
                this.setState({
                    selectedData:{
                        name: resourceName,
                        id: resourceId,
                        locationId: this.state.selectedLocation,
                        method: 'POST',
                        location,
                        locationList,
                        date: slotId.start
                    },
                    isOpenVacationDaysDialog: true
                })
            } else if (slotId?.bgColor === '#ff8000') {  // open TimeOff Popup to update timeoff
                this.setState({
                    selectedData:{
                        name: resourceName,
                        id: resourceId,
                        locationId: this.state.selectedLocation,
                        method: 'PATCH',
                        location,
                        locationList,
                        type: slotId.type,
                        date: slotId.start,
                        eventId: slotId?.eventId
                    },
                    isOpenVacationDaysDialog: true
                })
            } else if (slotId?.bgColor === '#0065ca') {  // open TimeAvailab Popup to update TimeAvailab
                this.setState({
                        selectedData:{                    
                        name: resourceName,
                        id: resourceId,
                        locationId: this.state.selectedLocation,
                        method: 'PATCH',
                        location,
                        locationList,
                        date: slotId.startsAt,
                        startsAt: slotId.startsAt,
                        endsAt: slotId.endsAt,
                        eventId: slotId?.eventId
                    },
                    isOpenTemAvailDialog: true
                })
            }
        }
    };

    ops1 = (schedulerData, event) => {
        alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    /* ops2 = (schedulerData, event) => {
        alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
    }; */

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        console.log('HandleEmployeeTmpAvail start', start)
        console.log('end', end)
        console.log('slotId', slotId)
        console.log('schedulerData', schedulerData)
        console.log('item', item)
        /* let newFreshId = 0;
        schedulerData.events.forEach((item) => {
            if(item.id >= newFreshId)
                newFreshId = item.id + 1;
        }); */
        if (this.state.editableAction) {
            let resourceList = JSON.parse(JSON.stringify(schedulerData.resources));
            let resourceName = '';
            let resourceId = '';
            resourceList.forEach((item) => {
                if(item.id === slotId) {
                    resourceName = item.name;
                    resourceId = item.id;
                }
            });
            
            let locationList = this.state.locations;
            let location = '';
            locationList.forEach((item) => {
                if(item.key === this.state.selectedLocation) {
                    location = item.text;
                }
            });

            this.setState({
                selectedData:{
                    name: resourceName,
                    id: resourceId,
                    locationId: this.state.selectedLocation,
                    method: 'POST',
                    location,
                    locationList,
                    date: start
                },
                isOpenTemAvailDialog: true
            })

            /* let newEvent = {
                id: newFreshId,
                title: 'New event you just created',
                start: start,
                end: end,
                resourceId: slotId,
                bgColor: 'purple'
            }
            schedulerData.addEvent(newEvent);
            this.setState({
                viewModel: schedulerData
            }) */
        }
    }

    slotClickedFunc = (schedulerData, slot) => {
        console.log('schedulerData', schedulerData)
        if (this.state.editableAction) {
            // alert(`You just clicked a ${schedulerData.isEventPerspective ? 'task':'resource'}.{id: ${slot.slotId}, name: ${slot.slotName}}`);
            if (!schedulerData.isEventPerspective) {
                this.props.navigate({
                    pathname: `/users`,
                    state: {
                        userId: slot.slotId
                    }
                });
            }
        }
    }

    /* updateEventStart = (schedulerData, event, newStart) => {
        schedulerData.updateEventStart(event, newStart);
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
            viewModel: schedulerData
        })
    } */

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        if (this.state.editableAction) {
            schedulerData.moveEvent(event, slotId, slotName, start, end);
            this.setState({
                viewModel: schedulerData
            })
        }
    }

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        return (
            // <React.Fragment>
            //     <h3>{title}</h3>
            //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
            //     <img src="./icons8-ticket-96.png" />
            // </React.Fragment>
            <div style={{width: '300px'}}>
                <Row >
                    <Col>
                        <div className="status-dot" style={{backgroundColor: statusColor}} />
                    </Col>
                    <Col className="overflow-text">
                        <span className="header2-text" title={title}>{title}</span>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <div />
                    </Col>
                    <Col>
                        <span className="header1-text">{start.format("HH:mm")} - {end.format("HH:mm")}</span>
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <div />
                    </Col>
                    <Col>
                        
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      handleGetResources: ({
        locationId,
        callback
      }) =>
        dispatch({
            type: HANDLE_GET_ALL_FIN_Del_PERSON,
            payload: { locationId, callback }
        }),
    handleGetMonthlyCal: ({
        data,
        callback
      }) =>
        dispatch({ 
            type: HANDLE_EMPLOYEE_GET_MONTH_CAL,
            payload: {data, callback }
        }),
    switchGlobalLoader: (boolean) =>
        dispatch(switchGlobalLoader(boolean))
    };
};

export default connect<{}, {}, IProps>(
    null,
    mapDispatchToProps
  )(withRouter(withDragDropContext(BasicSchedule)));
  // export default withDragDropContext(BasicSchedule)