import React, { useState, useEffect } from "react";
import {
  IStackStyles,
  DefaultPalette,
  Stack,
  ActionButton,
  mergeStyles,
  IButtonStyles,
  SearchBox,
} from "@fluentui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Pagination } from "components/table/pagination/Pagination";

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsListStyles,
} from "@fluentui/react/lib/DetailsList";
import { searchStyle as searchStyles, cellLineHeight } from "constants/styles";

import {
  // ISpinnerStyles,
  Spinner,
  SpinnerSize,
} from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";
import { IPagination } from "types/paginationTypes";

const searchStyle = searchStyles();
interface IDocument {
  id: number;
  key: string;
  firstName: string;
  lastName: string;
  phone: string;
}

const cellMaxHeight: string = "35px";

const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};

const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: 540,
  },
};


const cellStyles: IStackStyles = {
  root: {
    //height: height,
    lineHeight: cellLineHeight,
  },
};

const textStyles = mergeStyles({
  fontSize: 12,
});

interface ISort {
  column: string;
  sort: string;
}
interface IProps {
  employees?: any[];
  pagination?: IPagination;
  onPageChange?: (page: number) => void;
  onLimitChange?: (limit: number) => void;
  type: string;
  onAction: (employeeId: number) => void;
  isLoading: boolean;
  totalItems: number;
  onSort?: (sortObj: ISort) => void;
}

export const EmployeeTable: React.FunctionComponent<IProps> = ({
  employees,
  onPageChange,
  onLimitChange,
  type,
  onAction,
  isLoading,
  pagination,
  totalItems,
  onSort,
}) => {
  const [items, setItems] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [selection, setSelection] = useState(null);


  useEffect(() => {
    if (employees) setItems(employees);
  }, [employees]);




  /**===========================COLUMNS==========================================================*/

  const initialColumns: any[] = [
    {
      key: "firstName",
      name: "First name",
      fieldName: "employee.firstName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      data: "string",
      isPadded: false,
      onRender: (item: IDocument) => {
        return <span className={textStyles}>{item.firstName}</span>;
      },
    },
    {
      key: "lastName",
      name: "Last Name",
      fieldName: "employee.lastName",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: "string",
      onRender: (item: IDocument) => {
        return <span className={textStyles}>{item.lastName}</span>;
      },
      isPadded: false,
    },
    {
      key: "phone",
      name: "Phone",
      fieldName: "employee.phone",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      // onColumnClick: _onColumnClick,
      data: "string",
      onRender: (item: IDocument) => {
        return <span>{item.phone}</span>;
      },
      isPadded: false,
    },
    {
      key: "action",
      name: "Action",
      fieldName: "action",
      minWidth: 50,
      maxWidth: 50,

      isResizable: false,
      // onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        console.log('item.id', item.id)
        console.log('hovered', hovered)

        return isLoading && item.id === selectedItem ? (
          <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : (
          <>
            {/*{hovered === item.id ? (*/}
            {/*  <ActionButton*/}
            {/*    style={{ padding: 0, margin: 0, maxHeight: cellMaxHeight }}*/}
            {/*    styles={actionButton}*/}
            {/*    // onClick={() => this._handleView(item.id)}*/}
            {/*    iconProps={{ iconName: "AddTo" }}*/}
            {/*    onClick={() => {*/}
            {/*      setSelectedItem(item.id);*/}
            {/*      onAction(item.id);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {type}*/}
            {/*  </ActionButton>*/}
            {/*) : null}*/}

            <ActionButton
                style={{ padding: 0, margin: 0, maxHeight: cellMaxHeight }}
                styles={actionButton}
                // onClick={() => this._handleView(item.id)}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  setSelectedItem(item.id);
                  onAction(item.id);
                }}
            >
              {type}
            </ActionButton>




          </>
        );
      },
      isPadded: false,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  /**============================================================================================================*/
  const onColumnClick = (e, column) => {
    onSort({
      column: column.fieldName,
      sort:
        pagination.column === column.fieldName
          ? pagination.sort === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    setColumns([...newColumns]);
  };

  return items ? (
    <div>
      {/*<Stack*/}
      {/*  styles={stackStyles}*/}
      {/*  horizontal*/}
      {/*  verticalAlign="start"*/}
      {/*  horizontalAlign="space-between"*/}
      {/*></Stack>*/}
      <Stack horizontalAlign="end">
        <SearchBox
          className={searchStyle}
          placeholder="search in table ..."
          iconProps={{ styles: { root: { color: DefaultPalette.black } } }}
          onSearch={(newValue) => console.log("value is: " + newValue)}
        />
      </Stack>

      <DetailsList
        compact={true}
        styles={tableStyles}
        items={items}
        columns={columns}
        onRenderDetailsFooter={() => (
          <Pagination
            rowsPerPage={pagination.limit}
            currentPage={pagination.page.toString()}
            totalItems={totalItems}
            onRowsNumberChange={onLimitChange}
            onPageChange={onPageChange}
            showPageInput={false}
          />
        )}
        onRenderRow={(props, defaultRender) => {
          console.log("props", props)
          return (
            <Stack
              styles={cellStyles}
              className="centered"
              verticalAlign="center"
            >
              <div
                onMouseEnter={() => setHovered(props.item.id)}
                onMouseLeave={() => setHovered(null)}
              >
                {defaultRender(props)}
              </div>
            </Stack>
          );
        }}
        // onRenderCheckbox={(props, defaultRender) => {
        //     return <span>{defaultRender(props)}</span>;
        // }}
        // selection={selection}
        selectionMode={0}
        setKey="multiple"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onColumnHeaderClick={(ev, column) => onColumnClick(ev, column)}
        selectionPreservedOnEmptyClick={true}
        onItemInvoked={() => {}}
        enterModalSelectionOnTouch={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Row checkbox"
      />
    </div>
  ) : null;
};
