import client, { clientFile } from "services/api/client";
import { ICreateEmployee } from "types/employeesTypes";

//export const getEmployees = ({
//  limit,
//  page,
//  withoutGroup,
//  withGroup,
//  sort,
//  column,
//  withProspect,
//  withoutProspect,
//}: {
//  limit: number | string;
//  page: number | string;
//  withoutGroup?: number;
//  withGroup?: number;
//  sort: string;
//  column: string;
//  withProspect?: number;
//  withoutProspect?: number;
//}) => {
//  // const page = 1;
//  // const limit = 10;
//  // console.log(
//  //     "url",
//  //     `user/master/employees?sort=${sort ? sort : "DESC"}&page=${page}&limit=${limit}&withoutGroup=${withoutGroup}&withGroup=${withGroup}&column=${column ? column : "id"}`
//  // );

//  const query = `${withoutGroup ? `&withoutGroup=${withoutGroup}` : ""}${
//    withGroup ? `&withGroup=${withGroup}}` : ""
//  }`;
//  const prospect = `${withProspect ? `&withProspect=${withProspect}` : ""}${
//    withoutProspect ? `&withoutProspect=${withoutProspect}` : ""
//  }`;
//  // console.log("query", query);
//  return client(
//    `user/master/employees?sort=${
//      sort ? sort : "DESC"
//    }&page=${page}&limit=${limit}${query}&column=${column}${prospect}`,
//    { body: null }
//  ).then(
//    (data) => {
      
//      return { data, error: null };
//    },
//    (error) => {
      
//      return { data: null, error };
//    }
//  );
//};


export const getEmployees = ({
    isActive, role, departmentId
}: {
     isActive: number;
     role?: string;
     departmentId?: string;
    }) => {
       let url = `user/master/employees?active=${isActive}&page=1&limit=10000&column=employee.firstName&sort=asc`;
       if (role) {
            url = url + '&role=' + role;
       }
        if (departmentId) {
            url = url + '&departmentId=' + departmentId;
        }
       // console.log("query", query);
       return client(
           url,
           { body: null },false
       ).then(
       (data) => {
           return { data, error: null };
       },
       (error) => {
           return { data: null, error };
       }
       );
};

export const getEmployeesForAddGroupAssignEmployee = ({
  limit,
  page,
  withoutGroup,
}: {
  limit: number | string;
  page: number | string;
  withoutGroup: number;
}) => {
  const sort = "ASC";

  return client(
    `user/master/employees?sort=${sort}&page=${page}&limit=${limit}&withoutGroup=${withoutGroup}`,
    { body: null }
  ).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

//export const getEmployeesForAddGroupAssignEmployee = ({ isActive }: { isActive: boolean;}) => {
//    return client(
//        `user/master/employees?isActive=${isActive}&page=1&limit=1000`,
//        { body: null }
//    ).then(
//        (data) => {
//            return { data, error: null };
//        },
//        (error) => {
//            return { data: null, error };
//        }
//    );
//};

export const getEmployeesForAddGroupAssignedEmployee = ({
  limit,
  page,
  withGroup,
}: {
  limit: number | string;
  page: number | string;
  withGroup: number;
}) => {
  const sort = "ASC";

  return client(
    `user/master/employees?sort=${sort}&page=${page}&limit=${limit}&withGroup=${withGroup}`,
    { body: null }
  ).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const createEmployee = (employee: ICreateEmployee) => {
    return client("user/master/employees", { body: employee, method: "POST", },false).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteEmployee = (id: number | string) => {
  return client(`user/master/employee/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const viewEmployee = (id: number | string) => {
  return client(`user/master/employees/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateEmployee = (id: number | string, data: any) => {
  console.log("API ================", data);
  return client(`user/master/employees/${id}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const removeEmployeeFromGroup = (id: number | string) => {
  return client(`user/master/auth/employee/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updatePassword = (id: number | string, password: string) => {
  return client(`user/master/employee/${id}/password`, {
    body: { password },
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getEmployeeTasks = (id: number) => {
  return client(`user/master/employee/${id}/tasks`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getUserMasterTasks = (id: number) => {
  return client(`user/master/${id}/tasks`, {body: null}).then(
      (data) => {
        
        return {data, error: null};
      },
      (error) => {
        return {data: null, error}
      }
  )
}

export const addEmployeeProfile = (id: number, fileData: any) => {
    return clientFile(`user/master/employees/${id}/avatar`, {
        body: fileData,
        method: "POST"
    }).then(
        (data) => {

            return { result: data, error: null };
        },
        (error) => {

            return { result: null, error };
        }
    );
};

export const activateEmployee = (id: number | string, isActive: boolean) => {

    const url = isActive ? `user/master/employees/${id}/activate` : `user/master/employees/${id}/deactivate`;
    return client(url, {
        body: null,
        method: "PATCH",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const deactivateEmployee = (id: number | string) => {
    return client(`user/master/employees/${id}/deactivate`, {
        body: null,
        method: "PATCH",
    }, false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const sendInvite = (id: number | string) => {
    return client(`user/master/employees/${id}/invite`, {
        body: null,
        method: "PATCH",
    },false).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateEmployeePermissions = (id: string, permissions: any) => {

    return client(`user/master/employees/${id}/permissions`, {
        body: permissions,
        method: "PUT",
    }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getEmployeePermissions = (id: string) => {

    return client(`user/master/employees/${id}/permissions?page=1&limit=1000`, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { dataFilter: data, errorFilter: null };
        },
        (error) => {
            return { dataFilter: null, errorFilter: error };
        }
    );
};

export const getEmployeeWeeklyHours = (id: string) => {


    return client(`user/master/employees/${id}/weeklyHours?page=1&limit=1000`, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { dataFilter: data, errorFilter: null };
        },
        (error) => {
            return { dataFilter: null, errorFilter: error };
        }
    );
};

export const getEmployeeManagers = () => {

    return client(`user/master/employees/managers?page=1&limit=1000`, {
        body: null,
        method: "GET",
    }).then(
        (data) => {
            return { dataFilter: data, errorFilter: null };
        },
        (error) => {
            return { dataFilter: null, errorFilter: error };
        }
    );
};

/***Get sales and Finance Employee ***/

export const getEmployeesSaleFinance = ({
    isActive, role, departmentId
}: {
     isActive: number;
     role?: string;
     departmentId?: string;
    }) => {
       let url = `user/master/employees?active=${isActive}&page=1&limit=10000&column=employee.firstName&sort=asc`;
       if (role) {
            url = url + '&role=' + role;
       }
        if (departmentId) {
            url = url + '&departmentId=' + departmentId;
        }
       // console.log("query", query);
       return client(
           url,
           { body: null },false
       ).then(
       (data) => {
           return { data, error: null };
       },
       (error) => {
           return { data: null, error };
       }
       );
};

/* Monthly Calendar */
export const getEmployeeMonthlyCalendar = (payload: any) => {
  let url = `user/master/employees/monthlyCalender?month=${payload.month}&year=${payload.year}&role=${payload.role}&locationId=${payload.locationId}`; // ?limit=1000&page=1&sort=DESC&column=87&month=4&year=2022&role=financePerson&locationId=64`
  return client(url, {
      body: null,
      method: 'GET',
  }).then(
      (data) => {
          return { data, error: null };
      },
      (error) => {
          return { data: null, error };
      }
  );
};

export const updateEmployeeTmpAvail = (id: any, payload: any, method: any, employeeEvent: any = null) => {
  let url = `user/master/employees/${id}/tmpAvailability`;
  if (method === 'PATCH' && employeeEvent) {
    url = `user/master/employees/${id}/tmpAvailability/${employeeEvent}`;
  }
  return client(url, {
      body: payload,
      method: method,
  }).then(
      (data) => {
          return { data, error: null };
      },
      (error) => {
          return { data: null, error };
      }
  );
};

export const updateEmployeeTimeOff = (id: any, payload: any, method: any, employeeEvent: any = null) => {
  let url = `user/master/employees/${id}/timeOff`;
  if (method === 'PATCH' && employeeEvent) {
    url = `user/master/employees/${id}/timeOff/${employeeEvent}`;
  }
  return client(url, {
      body: payload,
      method: method,
  }).then(
      (data) => {
          return { data, error: null };
      },
      (error) => {
          return { data: null, error };
      }
  );
};

export const deleteEmployeeMonthlyEvents = (id: any, employeeEvent: any) => {
  return client(`user/master/employees/${id}/events/${employeeEvent}`, {
      body: null,
      method: 'DELETE',
  }).then(
      (data) => {
          return { data, error: null };
      },
      (error) => {
          return { data: null, error };
      }
  );
};

/*** Weekly Hours ***/
export const createEmployeeWeeklyHours = (id: string, weeklyHours: any) => {
  return client(`user/master/employees/${id}/weeklyHours`, {
      body: weeklyHours,
      method: "PUT",
  }).then(
      (data) => {
          return { data, error: null };
      },
      (error) => {
          return { data: null, error };
      }
  );
};