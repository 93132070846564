import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    // TextField,
    PrimaryButton,
    // DatePicker,
} from "@fluentui/react";
import {
    saveDisplayLocation,
    saveDisplayDate
} from "utils/localStorage/sheduleDisplayBoardLocalStorage";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
//import {
//	getModulePermissions,
//	isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
// import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import {
    // handleGetSalesTarget,
    //handleSaveSalesTarget
} from "store/dealTracker/dealTracker.action";
import { isKarlProfile, isUserMaster } from "utils/permissions/permissionsHelpers";
import {
    useNavigate ,
} from "react-router-dom";
// import masterDropdownValues from "constants/masterDropdownValues";
// import { Dropdown } from 'primereact/dropdown';
import { Dropdown } from "@fluentui/react/lib/Dropdown";
/* import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Calendar as SDCalendar, momentLocalizer, Views } from 'react-big-calendar'; */
import { getLocationsForDropdown } from "store/locations/locations.api";
import { Calendar } from 'primereact/calendar';
import { ScheduleManager } from "components/tabView/dealTracker/Schedule";

/* import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT, DemoData} from 'react-big-scheduler';
import HTML5Backend from "react-dnd-html5-backend";
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment' */
import BasicSchedule from "./BasicSchedule";
import { useLocation } from "react-router-dom";
import { scheduleOperationPermissions } from "constants/constants";
import { DisplayBoardWorkbookPage } from "./DisplayBoardWorkbookPage";


const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

/* const resourceMap = [
    { resourceId: 1, resourceTitle: 'Troy' },
    { resourceId: 2, resourceTitle: 'Peter' },
    { resourceId: 3, resourceTitle: 'Dan' },
    { resourceId: 4, resourceTitle: 'Brett' },
    { resourceId: 5, resourceTitle: 'John' },
    { resourceId: 6, resourceTitle: 'Paul' },
    { resourceId: 7, resourceTitle: 'Tom' },
    { resourceId: 8, resourceTitle: 'Ray' }
]; */

//const credentialModuleName = "DealTracker";
export const SchedulingWorkbook = () => {
    // const dispatch = useDispatch();
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    // const [tableData, setTableData] = useState([]);
    // const [salesTargetData, setSalesTargetData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
     const navigate = useNavigate ();
    // const [year, setYear] = useState([]);
    // const isMobile = useMediaQuery({ maxWidth: 960 });

    // const localizer = momentLocalizer(moment);
    // const [issuedDate, setIssuedDate] = useState(null);
    const [locations, setLocations] = useState([]);
    const [scheduleDate, setscheduleDate] = useState(new Date());
    // const [onDateChange, setonDateChange] = useState(scheduleDate);
    const [selectedLocations, setselectedLocations] = useState(null);
    const [showMonthlyView, setshowMonthlyView] = useState(false);
    const [showDisplayBoard, setshowDisplayBoard] = useState(false);
    // const [schedulerData1, setschedulerData1] = useState(undefined);
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const [statesFlag, setStatesFlag] = useState(null);

    const onPageChange = (val) => {
        console.log('val', val)
        if (val) {
            if (new Date(val) !== new Date(scheduleDate)) {
                setscheduleDate(new Date(val));
            }
        }
    }

    /* const handleYearFilter = (val: any) => {
        setYear([]);
        // dispatch<any>(handleGetSalesTarget({ year: val.target.value, isFirstTime: false, callback: (res) => handleSalesTargetResponse(res) }));
    }; */

    function dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }

    useEffect(() => {

        if (selectedLocations || scheduleDate) {
            saveDisplayLocation(selectedLocations)
            saveDisplayDate(scheduleDate.toString())
        }
    }, [selectedLocations, scheduleDate])


    useEffect(() => {
        if (user?.roles && statesFlag) {
            const fetchLocations = async () => {
                const { data } = await getLocationsForDropdown();

                const options = data?.results.map((item) => {
                    return { key: item.id, text: item.legalName };
                });
                var list = options ? options.sort(dynamicSort("text")) : '';
                setLocations(list);
                if (!selectedLocations) {
                    if (user?.roles?.some(item => item === "ROLE_EMPLOYEE") && user?.primaryLocationId) {
                        //setPrimaryLocation(user.primaryLocation.id); 
                        setselectedLocations(user.primaryLocationId)
                    } else {
                        setselectedLocations(options ? options[0]?.key : null);
                    }
                }
                //setselectedLocations(primaryLocations? primaryLocations: options ? options[0] ?.key : null)
            };
            // setIssuedDate(null);
            fetchLocations().then();
        }
    }, [user, statesFlag]); // eslint-disable-line react-hooks/exhaustive-deps



    /* const handleSelectSlot = ({ start, end, resourceId }) => {
        const title = window.prompt('New Event name')
        var currentDate = new Date(start);
        var endTime = new Date(currentDate.getTime() + 45*60000);

        const obj = {
            title,
            start,
            end: endTime,
            resourceId: resourceId
        };

        let newScheduleSlots: any = [
            ...scheduleSlotEvents,
            obj
        ];

        let newDeliverySlots: any = [
            ...deliverySlotEvents,
            obj
        ];
        if (title) {
            setScheduleSlotEvents(newScheduleSlots);
        }

        setdeliverySlotEvents(newDeliverySlots);
    }
    
    const eventColors = (event) => {
        var backgroundColor = "event-";
        event.class
          ? (backgroundColor = backgroundColor + event.class)
          : (backgroundColor = backgroundColor + "default");
        return {
          className: backgroundColor,
          display: 'background',
          backgroundColor: '#123123'
        };
    }; */

    /* const handleDateFormat = (e) => {
        return moment(e).format("MM/DD/YYYY");
    };

    const handleDateChange = (e) => {
        setIssuedDate(new Date(e));
    };

    const footer = (
        <>
            <PrimaryButton style={{ float: "right" }}>Save</PrimaryButton>
        </>
    ); */

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    const handleLocationChange = (e, item) => {
        setselectedLocations(item.key);
    }

    const handleDateChange = (val) => {
        setscheduleDate(val);
        // setonDateChange(val);
    };

    /* const rowClassName = (node) => {
        if (node.key && node.key.includes('total')) {
            return { 'salesTargetChildRow childTotalRow': (node.key && node.key.includes('-')) };
        } else {
            return { 'salesTargetChildRow': (node.key && node.key.includes('-')) };
        }
    } */

    const handleMonthlyCalendar = () => {
        setshowMonthlyView(true);
    }

    const handleDisplayBoard = () => {
        window.open("/DisplayBoardWorkbook", "_blank");

    }

    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal?.fromKeymetrics && stateVal?.locationId) {
            setselectedLocations(stateVal?.locationId);
        }
        setStatesFlag(true);
    }, [location]);

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >
                    {!showMonthlyView ?
                        <Row className="pageTitleRow">
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont task">
                                    {showDisplayBoard ? 'Finance Display Board' : 'Delivery Schedule'}</CustomFontText>
                            </Col>
                        </Row>
                        :
                        <Row className="pageTitleRow">
                            <Col className="alignHeaders">
                                <CustomFontText className="pageName pageHeaderFont task">Monthly Calendar</CustomFontText>
                            </Col>
                        </Row>}
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div>
                    {!showMonthlyView ?
                        <Row>
                            {!showDisplayBoard ?
                                <Col md={9}>
                                    <Row>
                                        <Col md={2}>
                                            <div className="locationDropPosition">
                                                <Dropdown
                                                    id="location"
                                                    label="Location"
                                                    //required={true}
                                                    selectedKey={selectedLocations}
                                                    //options={yearValues}
                                                    options={locations}
                                                    onChange={handleLocationChange}
                                                    placeholder=""
                                                //styles={dropdownStyles}
                                                //onBlur={formik.handleBlur}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="auctionDate date-picker" style={{ width: "100%" }}>
                                                <label style={{ display: "block", marginTop: "-3px" }} className="lbl-text">Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                    <Calendar
                                                        id="appointmentDate"
                                                        style={{ width: "100%" }}
                                                        value={scheduleDate}
                                                        showIcon
                                                        onChange={(e) => handleDateChange(e.value)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange} />

                                                </div>
                                            </div>
                                            {/* <div className="dateSelection">
                                        <DatePicker
                                            style={{ marginBottom: 0 }}
                                            placeholder=""
                                            value={issuedDate}
                                            allowTextInput
                                            maxDate={new Date()}
                                            ariaLabel="Date"
                                            onSelectDate={(e) => handleDateChange(e)}
                                            //isMonthPickerVisible={false}
                                            formatDate={(date) => handleDateFormat(date)}
                                            showGoToToday={true}
                                            //isRequired={true}
                                            label="Date"
                                        />
                                    </div> */}
                                        </Col>
                                    </Row>
                                </Col>
                                : null}
                            <Col>

                                {showDisplayBoard ?
                                    <>
                                        <PrimaryButton style={{ float: "right", margin: "30px 0 0" }}
                                            onClick={() => setshowDisplayBoard(false)}
                                        // className={`${!(user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (scheduleOperationPermissions.DEAL_SCHEDULE_MONTHLY_CALENDAR?.toString())) : isUserMaster(user.roles)) ? "hideMonthlyCal" : ""}`}
                                        >
                                            Daily Calendar
                                        </PrimaryButton>
                                    </>
                                    :
                                    <>
                                        <PrimaryButton style={{ margin: "30px 0px 0 25px", float: "right" }}
                                            onClick={handleDisplayBoard}
                                        // className={`${!(user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (scheduleOperationPermissions.DEAL_SCHEDULE_MONTHLY_CALENDAR?.toString())) : isUserMaster(user.roles)) ? "hideMonthlyCal" : ""}`}
                                        >
                                            Display Board
                                        </PrimaryButton>

                                        <PrimaryButton style={{ float: "right", margin: "30px 0 0" }}
                                            onClick={handleMonthlyCalendar}
                                            className={`${!(user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (scheduleOperationPermissions.DEAL_SCHEDULE_MONTHLY_CALENDAR?.toString())) : isUserMaster(user.roles)) ? "" : ""}`}
                                        // hideMonthlyCal
                                        >
                                            Monthly Calendar
                                        </PrimaryButton>
                                    </>
                                }

                            </Col>
                        </Row>

                        : null}
                </div>
                {showMonthlyView ?

                    <div className="main-section calendarContainer workBook monthlyCalendar">
                   <BasicSchedule
                        changeToDaily={()=>{ setshowMonthlyView(false)}}
                        setLocationId={selectedLocations}
                        navigate={navigate}
                        userRole={(user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (scheduleOperationPermissions.DEAL_SCHEDULE_MONTHLY_CALENDAR_UPDATE?.toString())) : isUserMaster(user.roles))}
                        /> 
                    </div>
                    :
                    showDisplayBoard ?
                        <div>
                            <DisplayBoardWorkbookPage
                                locationId={selectedLocations}
                                date={scheduleDate}> </DisplayBoardWorkbookPage>
                        </div>

                        :

                        <div className="main-section calendarContainer workBook">
                            {selectedLocations ?
                                <ScheduleManager
                                    title="Create Request"
                                    selectedEv={null}
                                    role="manager"
                                    dealId={null}
                                    locationId={selectedLocations}
                                    selectedDate={scheduleDate}
                                    onPageChange={onPageChange}
                                /> : null}
                        </div>}
            </Stack>
        </> : null
    );
};
