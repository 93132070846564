import React, { useState, useEffect, useRef } from "react";
import GoBack from "components/buttons/goBack/GoBack";
//import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    PrimaryButton,
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
} from "@fluentui/react";

import { AddBrandDialog } from "components/dialogs/brands/AddBrandDialog";
//import BrandTable from "components/table/brand/BrandTable";
import { useSelector } from "react-redux";
import { AppState } from "store/index";
import { WithCredentials, eTypes } from "hoc/withCredentials";

import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { permissionsModules } from "../../constants/constants";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
//import Card from "components/card/Card";
import { InputText } from 'primereact/inputtext';
import { rowsPerPageArray } from "constants/constants";
import { handleGetBrands, handleDeleteBrand } from "store/brands/brands.action";
import { useDispatch } from "react-redux";
import { BrandsMoreActionsSubmenu } from "components/table/brand/dropdown/BrandsMoreActionsSubmenu";
import { styles } from "components/dialogs/brands/styles";
//import { EditBrandDialog } from "components/dialogs/brands/EditBrandsDialog";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { EditBrandDialog } from "components/dialogs/brands/EditBrandDialog";


const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "Brands";

const BrandsPage = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate ();
    // const [pathname, setPathname] = useState("");
    const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [brandId, setBrandId] = useState(0);
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
    const [brandList, setBrandList] = useState([]);
    const [confirmContent, setConfirmContent] = useState("");
    //const [showAddBrandDialog, setShowAddBrandDialog] = useState(false);

    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const brands = useSelector((state: AppState) => state.brands.brands) as any;
    const dt = useRef(null);
    const [credentials, setCredentials] = useState([]);

    // useEffect(() => {
    //     setIsOpenAddDialog(Boolean(history.location?.state));
    // },[history.location])

    /* useEffect(() => {
        setPathname(brands.pathname);
    }, [brands]); */

    const handleOnDismiss = () => {
        setIsOpenAddDialog(false);
    };

    const handleOnEditDismiss = () => {
        setIsOpenEditDialog(false);
    };

    const handleBrandNameFilter = (val) => {
        dt.current.filter(val, 'name', 'contains');
    };

    useEffect(() => {
        if (user && user.auths && !isUserMaster(user.roles)) {
            // console.log(getModulePermissions(user.auths, credentialModuleName));
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // [user]

    useEffect(() => {
        if (brands && brands.results.length > 0) {
            let results = brands.results;
            // for (var i in results) {
            //     results[i].fontFamily= ;
            // }
            setBrandList(results);
        }
    }, [brands]);

    useEffect(() => {
        dispatch<any>(handleGetBrands(user));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const header = (
        <div className="table-header">
            {/*<Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />*/}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                {/*<InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />*/}
                <InputText type="search" onChange={(e) => handleBrandNameFilter(e.target.value)} placeholder="Search by brand" />
            </span>
        </div>
    );

    const brandNameBodyTemplate = (rowData) => {

        const examplePersona: IPersonaSharedProps = {
            imageUrl: rowData.logo
                ? `${process.env.REACT_APP_IMAGE_SERVER}/${rowData.logo}`
                : `${require("assets/building-image-placeholder.jpg")}`,
            text: rowData.name,
        };


        return (
            <React.Fragment>

                <span className="p-column-title" style={{ float: "left" }}>Brand Name</span>
                <div>
                    <Persona
                        {...examplePersona}
                        styles={styles.personInGridStyles}
                        size={PersonaSize.size48}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is dnd"
                    />

                </div>
            </React.Fragment>
        );
    };

    const fontFamilyBodytemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Font Family </span>
                {rowData.fontFamily}
            </React.Fragment>
        );
    };

    const colorBodytemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Color </span>
              {rowData.color} */}
                <span className="css-ColorBox-Out">
                    <span className="css-ColorBox" style={{ backgroundColor: rowData.color, width: "100%" }}>
                    </span>
                </span>

            </React.Fragment>
        );
    };

    const handleEditBrand = (id) => {
        setBrandId(id);
        setIsOpenEditDialog(true);
    };

    const handleRemoveBrand = (id) => {
        setBrandId(id);
        setConfirmContent("Are you sure you want to delete this brand?");
        setIsConfirmBoxOpen(true);
    };

    const handleRowClick = (e) => {
        setBrandId(e.data.id);
        setIsOpenEditDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <WithCredentials
                        groupAuths={credentials}
                        alias={permissionsModules.Brands}
                        type={eTypes.UPDATE}
                        roles={user.roles}
                    >
                        <BrandsMoreActionsSubmenu
                            modulePermissions={credentials}
                            brandId={rowData.id}
                            onEdit={(brandId) => handleEditBrand(rowData.id)}
                            onDelete={(brandId) => handleRemoveBrand(brandId)}
                        />
                    </WithCredentials>
                )}

            </React.Fragment>
        );
    };

    return (
        <>
            {/*<Loader show={loading} />*/}
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="bra_btn_par_con"
                >
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 30 }}>
                        <GoBack label="Brands" />

                        {credentials && user.roles && (
                            <WithCredentials
                                groupAuths={credentials}
                                alias={permissionsModules.Brands}
                                type={eTypes.WRITE}
                                roles={user.roles}
                            >
                                <PrimaryButton
                                    onClick={() => setIsOpenAddDialog(true)}
                                    text="Add new brand"
                                    iconProps={{ iconName: "CircleAdditionSolid" }}
                                />
                            </WithCredentials>
                        )}

                        <AddBrandDialog
                            isOpen={isOpenAddDialog}
                            onDismiss={() => handleOnDismiss()}
                        />
                        <EditBrandDialog
                            id={brandId}
                            isOpen={isOpenEditDialog}
                            onDismiss={handleOnEditDismiss}
                        />
                        <ConfirmDialog
                            cancelBtnLabel={confirmContent === "Are you sure you want to delete this brand?"?"No":"Close"}
                            confirmBtnLabel="Yes"
                            loading={false}
                            description={confirmContent}
                            title="Confirm delete brand"
                            onlyDismiss={confirmContent !== "Are you sure you want to delete this brand?"}
                            isOpen={isConfirmBoxOpen}
                            onAction={() => dispatch<any>(handleDeleteBrand(brandId, (msg) => {
                                if (msg) {
                                    setConfirmContent(msg);
                                }
                                else {
                                    setIsConfirmBoxOpen(false);
                                }

                            }))}
                            onDismiss={() => setIsConfirmBoxOpen(false)}
                        />
                    </Stack>
                </Stack>
                <div className="datatable-filter-demo">
                    <div className="card">
                        <DataTable ref={dt}
                            value={brandList}
                            paginator
                            rows={10}
                            rowsPerPageOptions={rowsPerPageArray}
                            totalRecords={brands.totalItems}
                            header={header}
                            onRowClick={(e) => handleRowClick(e)}
                            className="p-datatable-customers"
                            emptyMessage="No brands found.">

                            <Column field="name" header="Brand" body={brandNameBodyTemplate} sortable />
                            {/* <Column field="logo" header="Logo" body={logoBodyTemplate} /> */}
                            <Column field="fontFamily" header="Font Family" body={fontFamilyBodytemplate} sortable />
                            <Column field="color" header="Color" body={colorBodytemplate} sortable />
                            <Column field="action" header="Actions" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </Stack>
        </>
    );
};

//         <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 10 }}>
//             {showAddBrandDialog && (
//                 <AddBrandDialog isOpen={showAddBrandDialog} onDismiss={() => setShowAddBrandDialog(false)} />
//             )}
//             <Stack
//                 horizontal
//                 verticalAlign="center"
//                 horizontalAlign="space-between"
//             >
//                 <Stack
//                     horizontal
//                     verticalAlign="center"
//                     horizontalAlign="space-between"
//                     tokens={{ childrenGap: 30 }}
//                 >
//                     <GoBack label="Brands" />

//                 </Stack>
//                 <div>
//                     {credentials && user.roles && (
//                         <WithCredentials
//                             groupAuths={credentials}
//                             alias={permissionsModules.Brands}
//                             type={eTypes.WRITE}
//                             roles={user.roles}
//                         >
//                             <PrimaryButton
//                                 text="Add Brand"
//                                 style={{
//                                     marginTop: 10,
//                                 }}
//                                 iconProps={{ iconName: "VerifiedBrand" }}
//                                 onClick={() => setShowAddBrandDialog(true)}
//                             />
//                         </WithCredentials>
//                     )}
//                 </div>
//             </Stack>
//             <Stack>
//                 <Card>
//                     <BrandTable history={history} />
//                 </Card>
//             </Stack>

//         </Stack>
//     );
// };


export default BrandsPage;
