import {IBrand,IBrands} from "types/brandsTypes";
import {IPagination} from "types/paginationTypes";


export const SET_SORT_BRANDS = 'SET_SORT_BRANDS';

export interface SetSortBrands {
    type: typeof SET_SORT_BRANDS,
    payload: string
}

export const HANDLE_GET_BRANDS = "HANDLE_GET_BRANDS";

export interface HandleGetBrands {
    type: typeof HANDLE_GET_BRANDS;
    payload: IPagination;
}


export const GET_BRANDS_DROPDOWN = "GET_BRANDS_DROPDOWN";

export interface HandleGetBrandsForDropdown {
    type: typeof GET_BRANDS_DROPDOWN;
    payload: IPagination;
}

export const GET_BRANDS_DROPDOWN_PENDING = "GET_BRANDS_DROPDOWN_PENDING";

export interface GetBrandsDropdownPending {
    type: typeof GET_BRANDS_DROPDOWN_PENDING;
}

export const GET_BRANDS_DROPDOWN_REJECTED = "GET_BRANDS_DROPDOWN_REJECTED";

export interface GetBrandsDropdownRejected {
    type: typeof GET_BRANDS_DROPDOWN_REJECTED;
}

export const GET_BRANDS_DROPDOWN_RESOLVED = "GET_BRANDS_DROPDOWN_RESOLVED";

export interface GetBrandsDropdownResolved {
    type: typeof GET_BRANDS_DROPDOWN_RESOLVED;
    payload: IBrands;
}

export const GET_BRANDS_PENDING = "GET_BRANDS_PENDING";

export interface GetBrandsPending {
    type: typeof GET_BRANDS_PENDING;
}

export const GET_BRANDS_RESOLVED = "GET_BRANDS_RESOLVED";

export interface GetBrandsResolved {
    type: typeof GET_BRANDS_RESOLVED;
    payload: IBrand[];
}

export const GET_BRANDS_REJECTED = "GET_BRANDS_REJECTED";

export interface GetBrandsRejected {
    type: typeof GET_BRANDS_REJECTED;
    payload: object[];
}

export const HANDLE_CREATE_BRAND = "HANDLE_CREATE_BRAND";

export interface HandleCreateBrand {
    type: typeof HANDLE_CREATE_BRAND;
    payload: { brand: IBrand; callback: () => void };
}

export const CREATE_BRAND_PENDING = "CREATE_BRAND_PENDING";

export interface CreateBrandPending {
    type: typeof CREATE_BRAND_PENDING;
}

export const CREATE_BRAND_RESOLVED = "CREATE_BRAND_RESOLVED";

export interface CreateBrandResolved {
    type: typeof CREATE_BRAND_RESOLVED;
    payload: IBrand;
}

export const SET_ROWS_PER_PAGE_BRANDS = "SET_ROWS_PER_PAGE_BRANDS";

export interface SetRowsPerPage {
    type: typeof SET_ROWS_PER_PAGE_BRANDS;
    payload: number;
}

/** VIEW_Brand */
export const HANDLE_VIEW_BRAND = "HANDLE_VIEW_BRAND";

export interface HandleViewBrand {
    type: typeof HANDLE_VIEW_BRAND;
    payload: string | number;
}

export const VIEW_BRAND_PENDING = "VIEW_BRAND_PENDING";

export interface ViewBrandPending {
    type: typeof VIEW_BRAND_PENDING;
}

export const VIEW_BRAND_RESOLVED = "VIEW_BRAND_RESOLVED";

export interface ViewBrandResolved {
    type: typeof VIEW_BRAND_RESOLVED;
    payload: IBrand;
}

export const VIEW_BRAND_REJECTED = "VIEW_BRAND_REJECTED";

export interface ViewBrandRejected {
    type: typeof VIEW_BRAND_REJECTED;
    payload: object;
}

export const CREATE_BRAND_REJECTED = "CREATE_BRAND_REJECTED";

export interface CreateBrandRejected {
    type: typeof CREATE_BRAND_REJECTED;
    payload: object[];
}

export const HANDLE_UPDATE_BRAND = "HANDLE_UPDATE_BRAND";

export interface HandleUpdateBrand {
    type: typeof HANDLE_UPDATE_BRAND;
    payload: { brand: IBrand; callback: () => void };
}

export const UPDATE_BRAND_PENDING = "UPDATE_BRAND_PENDING";

export interface UpdateBrandPending {
    type: typeof UPDATE_BRAND_PENDING;
}

export const UPDATE_BRAND_RESOLVED = "UPDATE_BRAND_RESOLVED";

export interface UpdateBrandResolved {
    type: typeof UPDATE_BRAND_RESOLVED;
}

export const UPDATE_BRAND_REJECTED = "UPDATE_BRAND_REJECTED";

export interface UpdateBrandRejected {
    type: typeof UPDATE_BRAND_REJECTED;
    payload: object;
}

/** DELETE_Brand */
export const HANDLE_DELETE_BRAND = "HANDLE_DELETE_BRAND";

export interface HandleDeleteBrand {
    type: typeof HANDLE_DELETE_BRAND;
    payload: {id: number, callback: Function} ;
}

export const DELETE_BRAND_PENDING = "DELETE_BRAND_PENDING";

export interface DeleteBrandPending {
    type: typeof DELETE_BRAND_PENDING;
}

export const DELETE_BRAND_RESOLVED = "DELETE_BRAND_RESOLVED";

export interface DeleteBrandResolved {
    type: typeof DELETE_BRAND_RESOLVED;
}

export const DELETE_BRAND_REJECTED = "DELETE_BRAND_REJECTED";

export interface DeleteBrandRejected {
    type: typeof DELETE_BRAND_REJECTED;
    payload: object;
}

export const HANDLE_SORT_BRAND_TABLE = "HANDLE_SORT_BRAND_TABLE";

export interface HandleSortBrandTable {
    type: typeof HANDLE_SORT_BRAND_TABLE;
    payload: { sort: string; sortColumn: string };
}
export type BrandsActions =
    | HandleGetBrands
    | GetBrandsPending
    | GetBrandsResolved
    | GetBrandsRejected
    | GetBrandsDropdownPending
    | GetBrandsDropdownRejected
    | GetBrandsDropdownResolved
    | HandleCreateBrand
    | CreateBrandPending
    | CreateBrandResolved
    | CreateBrandRejected
    | SetRowsPerPage
    | HandleUpdateBrand
    | UpdateBrandPending
    | UpdateBrandResolved
    | UpdateBrandRejected
    | HandleViewBrand
    | ViewBrandPending
    | ViewBrandResolved
    | ViewBrandRejected
    | HandleDeleteBrand
    | DeleteBrandPending
    | DeleteBrandResolved
    | DeleteBrandRejected
    | HandleSortBrandTable
    | SetSortBrands;
