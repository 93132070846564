import {
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
} from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import {IIconProps, mergeStyles} from "@fluentui/react";
import {colors} from "../../../constants/constants";
import { ILinkStyles } from "@fluentui/react";

interface IStyles {
    stackItemStyles: IStackItemStyles;
    stackTabletItemStyles: IStackItemStyles;
    stackItemConfirmChangePasswordStyles: IStackItemStyles;
    stackItemStylesAutoHeight: IStackItemStyles;
    loginContentStyles: IStackStyles;
    buttonTokens: IStackTokens;
    textFields: IStackTokens;
    usernameIcon: IIconProps;
    passwordIcon: IIconProps;
    linkStyles: ILinkStyles;
    rulesList: any;
    rulesListItem: any;
    none: any;
    checkboxLabel: any;
}

export const styles: IStyles = {
    rulesList: mergeStyles({
        color: colors.grayText,
        listStyle: "none",
        paddingLeft: 0,
    }),
    none : {},
    checkboxLabel: mergeStyles({
        paddingTop: 10,
    }),
    rulesListItem: mergeStyles({}),
    linkStyles: {
        root: {
            fontSize: 12,
            marginRight: 30,
            textDecoration: 'underline',
        }
    },
    stackItemStyles: {
    root: {
      boxShadow: Depths.depth8,
      background: DefaultPalette.white,
      padding: 15,
      borderRadius: 2,
      width: 300,
      height: 400,
      zIndex: 1,
      borderTop: "10px solid #65BC50"
    },
  },
    stackItemStylesAutoHeight: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 50,
            borderRadius: 2,
            width: 250,
            zIndex: 1,
        },
    },
    stackTabletItemStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: "5vw",
            borderRadius: 2,
            width: '90vw',
            // width: "70vw",
            height: 300,
            zIndex: 1,
            marginTop: '40px !important',
           /*selectors: {
                ':focus-within': {
                    // maxWidth: "90vw",
                    // marginTop: "400px !important",
                    //
                    // paddingBottom: '400px !important'
                    paddingBottom: 300
                }
            }*/
        },
    },

    stackItemConfirmChangePasswordStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 15,
            borderRadius: 2,
            zIndex: 1,
            //marginTop: '40px !important',
            width: 340,
            height: "auto",
            borderTop: "10px solid #65BC50"

        },
    },
    loginContentStyles: {
        root: {
            height: "80%",
        },
    },
    buttonTokens: {
        childrenGap: '26px'
    },
    textFields: {
        childrenGap: "26px",
    },
    usernameIcon: { iconName: "Signin" },
    passwordIcon: { iconName: "Hide" },
};
