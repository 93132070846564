import { IDealerWebsite,
    IDealerTable } from "types/dealerWebsiteTypes";

import { IPagination } from "types/paginationTypes";



export const HANDLE_VIEW_DEALERWEBSITE = "HANDLE_VIEW_DEALERWEBSITE";
export interface handleViewDealerWebsite {
  type: typeof HANDLE_VIEW_DEALERWEBSITE;
  payload: string | number;
}

export const HANDLE_GET_DEALERWEBSITE = "HANDLE_GET_DEALERWEBSITE";

export interface HandleGetDealerWebsite {
  type: typeof HANDLE_GET_DEALERWEBSITE;
  payload: IPagination;
}

export const HANDLE_GET_LAND_DEALERWEBSITE = "HANDLE_GET_LAND_DEALERWEBSITE";

export interface HandleGetLandDealerWebsite {
  type: typeof HANDLE_GET_LAND_DEALERWEBSITE;
  payload: IPagination;
}

export const HANDLE_FILTER_DEALERWEBSITE = "HANDLE_FILTER_DEALERWEBSITE";

export interface HandleFilterDealerWebsite {
  type: typeof HANDLE_FILTER_DEALERWEBSITE;
  payload: IPagination;
}

export const VIEW_DEALERWEBSITE_PENDING = "VIEW_DEALERWEBSITE_PENDING";
export interface ViewDealerWebsitePending {
    type: typeof VIEW_DEALERWEBSITE_PENDING;
}

export const VIEW_DEALERWEBSITE_RESOLVED = "VIEW_DEALERWEBSITE_RESOLVED";

export interface ViewDealerWebsiteResolved {
    type: typeof VIEW_DEALERWEBSITE_RESOLVED;
    payload: IDealerTable;
}


export const GET_DEALER_WEBSITE_RESOLVED = "GET_DEALERWEBSITE_RESOLVED";
export interface GetDealerWebsiteResolved {
    type: typeof GET_DEALER_WEBSITE_RESOLVED;
    payload: IDealerWebsite[];
}

export const GET_DEALERWEBSITE_REJECTED = "GET_DEALERWEBSITE_REJECTED";
export interface GetDealerWebsiteRejected {
    type: typeof GET_DEALERWEBSITE_REJECTED;
    payload: object[];
}

export const CREATE_WEBSITE_RESOLVED = "CREATE_WEBSITE_RESOLVED";
export interface CreateWebsiteResolved {
  type: typeof CREATE_WEBSITE_RESOLVED;
  payload: { dealerwebsite: IDealerWebsite; callback: () => void };
}

export const CREATE_WEBSITE_REJECT = "CREATE_WEBSITE_REJECT";
export interface CreateWebsiteRejected {
    type: typeof CREATE_WEBSITE_REJECT;
    payload: { dealerwebsite: IDealerWebsite; callback: () => void };
}

export const CREATE_WEBSITE_PENDING = "CREATE_WEBSITE_PENDING";
export interface CreateWebsitePending {
    type: typeof CREATE_WEBSITE_PENDING;
}

export const HANDLE_UPDATE_DEALERWEBSITE = "HANDLE_UPDATE_DEALERWEBSITE";
export interface HandleUpdateDealerWebsite{
  type: typeof HANDLE_UPDATE_DEALERWEBSITE;
  payload: {dealerwebsite: IDealerWebsite; callback: () => void };
}

export const HANDLE_UPDATE_DEALERWEBSITE_PENDING = "HANDLE_UPDATE_DEALERWEBSITE_PENDING";
export interface HandleUpdateDealerWebsitePending{
  type: typeof HANDLE_UPDATE_DEALERWEBSITE_PENDING;
}

export const HANDLE_UPDATE_DEALERWEBSITE_REJECTED = "HANDLE_UPDATE_DEALERWEBSITE_REJECTED";
export interface HandleUpdateDealerWebsiteRejected{
  type: typeof HANDLE_UPDATE_DEALERWEBSITE_REJECTED;
  payload : object[];
}
export const HANDLE_UPDATE_DEALERWEBSITE_RESOLVED = "HANDLE_UPDATE_DEALERWEBSITE_RESOLVED";
export interface HandleUpdateDealerWebsiteResolved{
  type: typeof HANDLE_UPDATE_DEALERWEBSITE_RESOLVED;
  payload: {dealerwebsite: IDealerWebsite; callback: () => void };
}

export const HANDLE_SORT_DEALERWEBSITE_TABLE = "HANDLE_SORT_DEALERWEBSITE_TABLE";
export interface HandleSortDealerWebsiteTable{
  type: typeof HANDLE_SORT_DEALERWEBSITE_TABLE;
  payload: { sort: string; sortColumn: string };
}

export const SET_SORT_DEALERWEBSITE = 'SET_SORT_DEALERWEBSITE';

export interface SetSortDealerWebsite {
    type: typeof SET_SORT_DEALERWEBSITE,
    payload: string
}

export const SET_ROWS_PER_PAGE_DEALERWEBSITE = "SET_ROWS_PER_PAGE_DEALERWEBSITE";

export interface SetRowsPerPage {
    type: typeof SET_ROWS_PER_PAGE_DEALERWEBSITE;
    payload: number;
}

export const SET_ROWS_PER_PAGE_LAND_DEALERWEBSITE = "SET_ROWSET_ROWS_PER_PAGE_LAND_DEALERWEBSITES_PER_PAGE_DEALERWEBSITE";

export interface SetRowsPerPageLand {
    type: typeof SET_ROWS_PER_PAGE_LAND_DEALERWEBSITE;
    payload: number;
}


export type WebsiteActions =
  | CreateWebsiteResolved
  | CreateWebsiteRejected
  | CreateWebsitePending
  | ViewDealerWebsitePending
  | HandleGetDealerWebsite
  | GetDealerWebsiteRejected
  | HandleFilterDealerWebsite
  | HandleUpdateDealerWebsite
  | HandleUpdateDealerWebsitePending
  | HandleUpdateDealerWebsiteRejected
  | HandleUpdateDealerWebsiteResolved
  | HandleSortDealerWebsiteTable
  | HandleGetLandDealerWebsite
  | SetSortDealerWebsite
  | SetRowsPerPage
  | SetRowsPerPageLand;

