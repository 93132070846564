import {
    IAccount
} from "types/accountsTypes";
import { IPaginationResponse } from "types/paginationTypes";

import * as actions from "./accounts.types";


// GET_ACCOUNTS
export const pendingGetAccounts = (): actions.GetAccountsPending => {
    return {
        type: actions.GET_ACCOUNTS_PENDING,
    };
};

export const resolvedGetAccounts = (
    accounts: IPaginationResponse<IAccount>
): actions.GetAccountsResolved => {
    return {
        type: actions.GET_ACCOUNTS_RESOLVED,
        payload: accounts,
    };
};

export const rejectedGetAccounts = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.GetAccountsRejected => {
    return {
        type: actions.GET_ACCOUNTS_REJECTED,
        payload: errors,
    };
};


// CREATE ACCOUNT
export const handleCreateAccount = ({
    account,
    callback,
}): actions.HandleCreateAccount => {
    return {
        type: actions.HANDLE_CREATE_ACCOUNT,
        payload: { account, callback },
    };
};


export const pendingCreateAccount = (): actions.CreateAccountPending => {
    return {
        type: actions.CREATE_ACCOUNT_PENDING,
    };
};

export const resolvedCreateAccount = (
    account: IAccount
): actions.CreateAccountResolved => {
    return {
        type: actions.CREATE_ACCOUNT_RESOLVED,
        payload: account,
    };
};

export const rejectedCreateAccount = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.CreateAccountRejected => {
    return {
        type: actions.CREATE_ACCOUNT_REJECTED,
        payload: errors,
    };
};

/** VIEW_ACCOUNT */
export const handleViewAccount = (
    id: number | string
): actions.HandleViewAccount => {
    return {
        type: actions.HANDLE_VIEW_ACCOUNT,
        payload: id,
    };
};

export const pendingViewAccount = (): actions.ViewAccountPending => {
    return {
        type: actions.VIEW_ACCOUNT_PENDING,
    };
};

export const resolvedViewAccount = (
    account: IAccount
): actions.ViewAccountResolved => {
    return {
        type: actions.VIEW_ACCOUNT_RESOLVED,
        payload: account,
    };
};

export const rejectedViewAccount = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.ViewAccountRejected => {
    return {
        type: actions.VIEW_ACCOUNT_REJECTED,
        payload: errors,
    };
};

/** UPDATE_ACCOUNT */
export const handleUpdateAccount = ({
    account,
    callback,
}): actions.HandleUpdateAccount => {

    return {
        type: actions.HANDLE_UPDATE_ACCOUNT,
        payload: { account, callback },
    };
};

export const pendingUpdateAccount = (): actions.UpdateAccountPending => {
    return {
        type: actions.UPDATE_ACCOUNT_PENDING,
    };
};

export const resolvedUpdateAccount = (account: IAccount): actions.UpdateAccountResolved => {
    return {
        type: actions.UPDATE_ACCOUNT_RESOLVED,
        payload: account
    };
};

export const rejectedUpdateAccount = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.UpdateAccountRejected => {
    return {
        type: actions.UPDATE_ACCOUNT_REJECTED,
        payload: errors,
    };
};


/** ACTIVATE ACCOUNT */
export const handleAccountActivate = (
    { id, isActive }
): actions.HandleAccountActivate => {
    return {
        type: actions.HANDLE_ACCOUNT_ACTIVATE,
        payload: { id, isActive },
    };
};

export const pendingAccountActivate = (): actions.AccountActivatePending => {
    return {
        type: actions.ACCOUNT_ACTIVATE_PENDING,
    };
};

export const resolvedAccountActivate = (): actions.AccountActivateResolved => {
    return {
        type: actions.ACCOUNT_ACTIVATE_RESOLVED,
    };
};

export const rejectedAccountActivate = (errors): actions.AccountActivateRejected => {
    return {
        type: actions.ACCOUNT_ACTIVATE_REJECTED,
        payload: errors,
    };
};

/** SEND INVITE */
export const handleSendInvite = (
    { id }
): actions.HandleAccountSendInvite => {
    return {
        type: actions.HANDLE_ACCOUNT_SEND_INVITE,
        payload: { id },
    };
};

export const pendingSendInvite = (): actions.AccountSendInvitePending => {
    return {
        type: actions.ACCOUNT_SEND_INVITE_PENDING,
    };
};

export const resolvedSendInvite = (): actions.AccountSendInviteResolved => {
    return {
        type: actions.ACCOUNT_SEND_INVITE_RESOLVED,
    };
};

export const rejectedSendInvite = (errors): actions.AccountSendInviteRejected => {
    return {
        type: actions.ACCOUNT_SEND_INVITE_REJECTED,
        payload: errors,
    };
};

/** EXPORT CSV */
export const handleExportCsvAccount = ({ isActive }): actions.HandleActiveExportCsv => {
    return {
        type: actions.HANDLE_ACTIVE_EXPORT_CSV,
        payload: { isActive }
    };
};