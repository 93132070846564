import { IStackStyles, IStackTokens } from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { mergeStyles } from "@fluentui/react";
import { colors } from "constants/constants";
import Group from "assets/marketingHeader/Group.svg";

interface ICustomStyles {
    container: any;
    headerStyles: IStackStyles;
    col: any;
    subscriptionListColumn: any;
    cardColumn: any;
    cardColumnContent: any;
    buttonsTokens: IStackTokens;
    buttonsStyles: IStackStyles;
    iconStyles: any;
    headerContainer: any;
    headerImage: any;
    itemImage: any;
    itemContainer: any;
    headerContent: any;
    activeAccountHeaderContainer: any
}

export const styles: ICustomStyles = {
    container: {
        padding: 0,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    headerContainer: {
        marginRight: 60,
        marginLeft: -10
    },
    activeAccountHeaderContainer: {
        marginLeft: -10,
        marginRight: 10,
        backgroundColor: 'white',
        paddingBottom: 30,
        paddingTop: 30,
        paddingLeft:10,
        paddingRight: 10

    },
    headerImage: {
        height: 410,

        background: `#F6F9FC no-repeat`,
        // width: "30%",
        backgroundSize: "contain",
        // width: '100vh',
        backgroundPosition: "top right",
        backgroundImage: `url(${Group})`
    },

    headerContent: {
        paddingTop: 80,
        marginLeft: 150,
        width: 600,
        height: '60%',
      display: 'flex',
      flexDirection: 'column',
     justifyContent: 'space-around',
    },
    itemContainer: {
        height: 400,
        width: 448,
        marginBottom: 40,
        marginLeft: 1,
        marginRight: 1,
        //backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    itemImage: {
       // flex: 1,
        height: 250,
        width: 350,
        backgroundImage: `url('https://via.placeholder.com/350x300.png')`,
        backgroundSize: "contain",

        // backgroundImage: `url(${b})`,
        // background: 'no-repeat'
    },

    headerStyles: {
        root: {
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30,
        },
    },
    col: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        paddingBottom: 25,
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 2,
        zIndex: 1,

    },
    subscriptionListColumn: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        paddingBottom: 25,
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 2,
        zIndex: 1,
        // marginRight: 25,
        // marginBottom: 25
    },
    cardColumn: {
        boxShadow: Depths.depth8,
        background: DefaultPalette.white,
        paddingBottom: 25,
        paddingLeft: 25,
        paddingRight: 25,
        borderRadius: 2,
        zIndex: 1,

        // margin: 15
    },
    cardColumnContent: {

    },


    iconStyles: mergeStyles({
        borderRadius: 5,
        padding: 5,
        color: "white",
        backgroundColor: colors.grayText,
    }),

    buttonsTokens: {
        childrenGap: 10,
    },

    buttonsStyles: {
        root: {
            paddingTop: 40,
        },
    },
};
