import './VinScanner.css';
import DBR from "./dbr";
import React from 'react';
import BarcodeScanner from './BarcodeScanner';
import {
    PrimaryButton,
    Button
  } from "@fluentui/react";
import { Col, Row } from "react-grid-system";

class VinScanner extends React.Component {
    constructor(props) {
        super(props);
        this.refDivMessage = React.createRef();
        this.state = {
            libLoaded: false,
            resultValue: "",
            resultItems: [],
            bShowScanner: false,
            vinSelection: props.vinSelection,
            showCamera: true
        };
        // window.Dynamsoft.DLR.LabelRecognizer.initLicense("DLS2eyJvcmdhbml6YXRpb25JRCI6IjEwMDU0NDI5NyJ9");
    }

    async componentDidMount() {
        try {
            await DBR.BarcodeScanner.loadWasm();

            this.setState(state => {
                state.libLoaded = true;
                return state;
            }, () => {
                this.showScanner();
            });
            
        } catch (ex) {
            alert(ex.message);
            throw ex;
        }
    }

    scrollToBottom = () => {
        this.refDivMessage.current.scrollTop = this.refDivMessage.current.scrollHeight;
    }

    componentDidUpdate() {
        // this.scrollToBottom();
    }

    appendMessage = (message) => {
        switch (message.type) {
            case "result":
                this.setState(prevState => {
                    prevState.resultValue = message.format + ": " + message.text;
                    prevState.resultItems = prevState.resultItems.concat([{ type: message.format + ": ", text: message.text }]);
                    return prevState;
                });
                this.props.onScanSubmit(message.text)
                break;
            case "error":
                this.setState(prevState => {
                    prevState.resultValue = "Error Occurred! Check the error message in 'All results'!";
                    prevState.resultItems = prevState.resultItems.concat([{ type: "Error: ", text: message.msg }]);
                    return prevState;
                });
                break;
            default: break;
        }
    }
    showScanner = () => {
        this.setState({
            bShowScanner: true
        });
    }
    handleCancel = () => {
        this.props.onScanSubmit(this.state.resultValue)
    }
    onCameraChange = (val) => {
        this.setState({
            showCamera: false
        });
        setTimeout(() => {
            this.setState({
                vinSelection: val,
                showCamera: true
            });
        }, 1500)
    }

    render() {
        return (
            <div className="vinScanner">
                <input type="text" value={this.state.resultValue} readOnly={true} className="latest-result" placeholder="Scanned VIN appear here" />
                {/*<input id="ipt-file" type="file" accept="image/png,image/jpeg,image/bmp,image/gif" />
                <div id="results"></div> */}
                <div id="UIElement">
                    {!this.state.libLoaded ? (<span style={{ fontSize: "x-large" }}>Loading Library...</span>) : ""}
                    { this.state.bShowScanner && this.state.showCamera ? (
                    <BarcodeScanner 
                        vinConfig={this.state.vinSelection}
                        appendMessage={this.appendMessage}>
                    </BarcodeScanner>) : "" }
                </div>
                <div>
                    {/* <span style={{ float: "left" }}>All Results:</span><br />
                    <div id="results" ref={this.refDivMessage}>
                        <ul>
                            {this.state.resultItems.map((item, index) => (
                                <li key={100000 + index} ><span>{item.type}</span><span className="resultText">{item.text}</span>                                </li>
                            ))}
                        </ul>
                    </div> */}
                    <Row className="inventoryRowSection" justify="center">
                        <PrimaryButton
                            className={`submitCamera selectedCamera ${this.state.vinSelection === 'ocr' ? 'selected' : '' }`}
                            // onRenderIcon={renderSpinner}
                            style={{marginRight: "15px", width: "120px"}}
                            text={"Text Scan"}
                            onClick={() => this.onCameraChange('ocr')}
                        /> 
                        <PrimaryButton
                            className={`submitCamera selectedCamera ${this.state.vinSelection !== 'ocr' ? 'selected' : '' }`}
                            // onRenderIcon={renderSpinner}
                            style={{marginLeft: "15px", width: "120px"}}
                            text={"Barcode"}
                            onClick={() => this.onCameraChange('barcode')}
                        />
                    </Row>
                    <Row className="inventoryRowSection" justify="center">
                        <Col sm={10} md={10} xs={12} className="submitBtn">
                            <Button
                            className="decodeBtn"
                            text="Cancel"
                            onClick={this.handleCancel}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
export default VinScanner;
