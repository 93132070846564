import React, { useState, useEffect, FunctionComponent } from "react";
import {
    //IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { HANDLE_GET_EMPLOYEES } from "store/employees/employees.types";
import { handleGetLocations } from "store/locations/locations.action";
import { handleVehicleUpdate } from "store/inventory/inventory.action";
import { AppState } from "store/index";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (errorMsg) => void;
    vehicleData: any;
}

export const EditVehicleDialog: FunctionComponent<IProps> = ({
    onDismiss,
    vehicleData
}) => {
    const employees = useSelector((state: AppState) => state.employees.employees?.results) as any; 
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 

    const [cityDealerList, setCityDealerList] = useState(null);
    const [employeeList, setEmployeeList] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch<any>(handleGetLocations());
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1 } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        formik.setValues(
            {
                ...formik.values,
                'appraiserId': vehicleData?.appraisedBy?.id,
                'locationId': vehicleData?.location?.id,
                'stockNumber': vehicleData?.stockNumber,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleData]);

    useEffect(() => {
        if (employees) {
            let arr = [];
            employees.map((item: any) => {
                return arr.push({ id: item.id, name: (item.firstName + ' ' + item.lastName) })
            });
            setEmployeeList(arr);
        } else {
            setEmployeeList([]);
        }
    }, [employees]);

    useEffect(() => {

        if (locations && locations.results) {
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    const formik = useFormik({
        initialValues: {
            locationId: "",
            appraiserId: "",
            stockNumber: ""
        },
        validationSchema: Yup.object({
            locationId: Yup.string(),
            appraiserId: Yup.string(),
            stockNumber: Yup.string()
        }),
        onSubmit: (values) => {
            dispatch<any>(handleVehicleUpdate(vehicleData.id, values, (res) => {
                onDismiss(!res ? 'Failed to the Save Data' : '');
            }));
        },
    });


    const handleSavePurchase = () => {
        formik.handleSubmit();
    };

    const onDropDownChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    const handleClosePopup = () => {
        onDismiss('');
    }

    return (
        <Modal
            isOpen={true}
            onDismiss={handleClosePopup}
            isBlocking={true}
            containerClassName={contentStyles.container + ' inv-srch-mod expense-modal'}
        >
            <div className={contentStyles.header + " inventory-popup  modal-header"}>
                <span className="formTitle">Edit Vehicle</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={handleClosePopup}
                />
            </div>
            <div style={{ overflow: "hidden" }} className={`${contentStyles.body} inv-srch-bdy body-auto-hight`}>
                <Stack
                    verticalAlign="start"
                    horizontalAlign="space-between"
                    gap="row gap"
                >
                    <Row>
                        <Col className="vehicle-edit-d" style={{ lineHeight: "1.5" }}>
                            {
                                vehicleData.vin && (<><span className="vehicle-info-txt">{vehicleData.vin}</span><br /></>)
                            }
                            <span className="vehicle-info-txt">{vehicleData?.year + ' ' + vehicleData?.make + ' ' + vehicleData?.model + ' ' + vehicleData?.style}</span>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div>
                                <Row className="purchase-row" justify="center">
                                    <Col sm={10} md={10} xs={12}>
                                        <label className="lbl-text">Location</label>
                                        <PRDropdown
                                            id="locationId"
                                            placeholder=""
                                            value={formik.values.locationId}
                                            options={cityDealerList}
                                            onChange={onDropDownChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown .customInputField"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                        <span className="error-message">
                                            {
                                                formik.touched && formik.touched.locationId &&
                                                formik.errors && formik.errors.locationId &&
                                                formik.errors.locationId.toString()
                                            }
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="purchase-row" justify="center">
                                    <Col sm={10} md={10} xs={12}>
                                        <label className="lbl-text">Appraiser</label>
                                        <PRDropdown
                                            id="appraiserId"
                                            placeholder=""
                                            value={formik.values.appraiserId}
                                            options={employeeList}
                                            onChange={onDropDownChange}
                                            optionLabel="name"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown .customInputField"
                                            filter={false}
                                            scrollHeight="140px"
                                            resetFilterOnHide={true}
                                        />
                                        <span className="error-message">
                                            {
                                                formik.touched && formik.touched.appraiserId &&
                                                formik.errors && formik.errors.appraiserId &&
                                                formik.errors.appraiserId.toString()
                                            }
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="purchase-row" justify="center">
                                    <Col sm={10} md={10} xs={12}>
                                        <TextField
                                            id="stockNumber"
                                            name="stockNumber"
                                            label="Stock"
                                            placeholder=""
                                            autoComplete="off"
                                            value={formik.values.stockNumber}
                                            //required={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched && formik.touched.stockNumber &&
                                                formik.errors && formik.errors.stockNumber &&
                                                formik.errors.stockNumber.toString()
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="purchase-row">
                                    <Col style={{ textAlign: "center" }} sm={12} md={12} xs={12}>
                                        <PrimaryButton
                                            className="decodeBtn"
                                            disabled={!formik.dirty || !formik.isValid}
                                            text="Save"
                                            onClick={handleSavePurchase}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Stack>
            </div>


        </Modal>
    );
};


