import {/**ICreateDeal,*/ IDeal, IDeals, IDealSettings,} from "types/dealTypes";
import * as actions from "store/deals/deals.types";
import {DealStatusesEnum, DealTypesEnum} from "constants/constants";

interface IState {
  loading: boolean;
  dealSettingsLoading: boolean;
  items: any;
  error: null | object;
  rowsPerPageDeals: number;
  selectedDeal: null | IDeal;
  selectedDealRecord: null;
  updateLoading: boolean;
  column: string;
  sort: string;
  loadingVehicle: boolean;
  dealSettings: IDealSettings;
  filters: {
    haveCoSigner: boolean;
    isFavourite: boolean;
    dealStatuses: null | DealStatusesEnum[];
    dealTypes: null | DealTypesEnum[];
    dateFrom: null | string;
    dateTo: null | string;
  };
}

const prospectInit: IDeals = {
  results: [],
  totalItems: 0,
  currentPage: 1,
};

const initialState: IState = {
  loading: false,
  dealSettingsLoading: false,
  items: prospectInit,
  error: null,
  rowsPerPageDeals: 15,
  selectedDeal: null,
  selectedDealRecord:null,
  updateLoading: false,
  column: "deal.id",
  sort: "ASC",
  loadingVehicle: false,
  dealSettings: null,
  filters: {
    haveCoSigner: false,
    isFavourite: false,
    dealStatuses: [],
    dealTypes: [],
    dateFrom: null,
    dateTo: null,
  },
};

export const DealsReducer = (
  state: IState = initialState,
  action: actions.DealsActions
) => {
  switch (action.type) {

    case actions.CREATE_DEAL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_DEAL_REJECTED:
      return { ...state, loading: false, error: action.payload };
    case actions.CREATE_DEAL_RESOLVED:
      return {
        ...state,
        loading: false,
        selectedDealRecord: action.payload
      };
    case actions.GET_DEALS_PENDING:
      return {
        ...state,
      };
    case actions.GET_DEALS_REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case actions.GET_DEALS_RESOLVED:
      return {
        ...state,
        items: { ...action.payload },
      };
    case actions.SET_ROWS_PER_PAGE_DEALS:
      return {
        ...state,
        rowsPerPageDeals: action.payload,
      };
    case actions.VIEW_DEAL_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.VIEW_DEAL_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedDeal: action.payload,
      };
    }

    case actions.VIEW_DEAL_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_DEAL_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.DELETE_DEAL_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_DEAL_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_DEAL_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.UPDATE_DEAL_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_DEAL_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DEAL_ASSIGN_SIGNER_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.DEAL_ASSIGN_SIGNER_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DEAL_ASSIGN_SIGNER_REJECTED: {
      return {
        ...state,
        loading: false,
        error: { ...action.payload },
      };
    }
    case actions.DEAL_UNASSIGN_SIGNER_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.DEAL_UNASSIGN_SIGNER_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DEAL_UNASSIGN_SIGNER_REJECTED: {
      return {
        ...state,
        loading: false,
        error: { ...action.payload },
      };
    }

    case actions.GET_DEAL_SETTINGS_PENDING: {
      return {
        ...state,
        dealSettingsLoading: true,
      };
    }
    case actions.GET_DEAL_SETTINGS_RESOLVED: {
      console.log(action.payload);
      

      return {
        ...state,
        dealSettingsLoading: false,
        dealSettings: action.payload,
      };
    }
    case actions.GET_DEAL_SETTINGS_REJECTED: {
      return {
        ...state,
        dealSettingsLoading: false,
      };
    }
    case actions.UPDATE_DEAL_SETTINGS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.UPDATE_DEAL_SETTINGS_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_DEAL_SETTINGS_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }


    case actions.CLEAN_DEAL_FILTERS: {
      return {
        ...state,
        filters: initialState.filters
      }
    }

    case actions.SET_DEAL_FILTERS: {

      console.log(action.payload);
      
      return {
        ...state,
        filters: action.payload
      }
    }

    default:
      return state;
  }
};
