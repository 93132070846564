import {
  ICreateProspect,
  IProspect,
  IProspectsFilterSettings,
} from "types/prospectTypes";
import client, {clientFile} from "services/api/client";

import { IPagination } from "types/paginationTypes";
import { ITask, ITasks } from "types/taskTypes";

export const checkProspect = async (email: string) => {
  return client(`user/master/prospects?sort=ASC&email=${email}`, {
    body: null,
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const getProspects = async (
  request: IPagination,
  filters: IProspectsFilterSettings
) => {
  const { sort, page, limit, column, phrase } = request;

  console.log(filters);
  

  let scheduler = "";
  filters.scheduler.forEach((item) => {
    scheduler = scheduler + "," + item;
  });
  scheduler = scheduler.substring(1);

  const query = `user/master/prospects?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=${limit}&column=${column ? column : "id"}&filter=${
    phrase ? phrase : ""
  }&haveDeals=${filters.haveDeals ? 1 : 0}&assignToMe=${
    filters.assignToMe ? 1 : 0
  }&isFavourite=${filters.isFavourite ? 1 : 0}&tradeIn=${
    filters.tradeIn ? 1 : 0
  }&vehicleInterested=${filters.vehicleInterested ? 1 : 0}&availableEmail=${
    filters.availableEmail ? 1 : 0
  }&availablePhone=${
    filters.availablePhone ? 1 : 0
  }&scheduler=${scheduler}&minMonthlyIncome=${
    filters.minMonthlyIncome ? filters.minMonthlyIncome : ""
  }&maxMonthlyIncome=${
    filters.maxMonthlyIncome ? filters.maxMonthlyIncome : ""
  }&leadType=${
    Array.isArray(filters.leadType) ? filters.leadType.join(",") : ""
  }&zip=${filters.zip ? filters.zip : ""}&city=${
    filters.city ? filters.city : ""
  }&state=${filters.state ? filters.state : ""}&vehicleCondition=${
    filters.vehicleCondition
  }&vehicleMake=${
    filters.vehicleMake ? filters.vehicleMake.text : ""
  }&vehicleModel=${
    filters.vehicleModel ? filters.vehicleModel.text : ""
  }&vehicleMaxPrice=${
    filters.vehicleMaxPrice ? filters.vehicleMaxPrice : ""
  }&vehicleMinYear=${
    filters.vehicleMinYear ? filters.vehicleMinYear : ""
  }&vehicleMaxYear=${
    filters.vehicleMaxYear ? filters.vehicleMaxYear : ""
  }&vehicleMaxMileage=${
    filters.vehicleMaxMileage ? filters.vehicleMaxMileage : ""
  }&vehicleBodyType=${
    filters.vehicleBodyType ? filters.vehicleBodyType : ""
  }&vehicleExteriorColor=${
    filters.vehicleExteriorColor ? filters.vehicleExteriorColor : ""
  }&leadType=${filters.leadType}
`;

  //vehicleMaxMileage
  //vehicleBodyType
  //vehicleExteriorColor

  // console.log(query);
  return client(query, { body: null }).then(
    (data) => {
      //
      // console.log(data)
      return { data, error: null };
    },
    (error) => {
      //
      return { data: null, error };
    }
  );
};

export const getCosigners = async (request: IPagination) => {
  const { sort, page, limit, column } = request;

  const query = `user/master/co-signers?sort=${
    sort ? sort : "DESC"
  }&page=${page}&limit=${limit}&column=${column ? column : "id"}
`;
  // console.log(query);
  return client(query, { body: null }).then(
    (data) => {
      //
      // console.log(data)
      return { data, error: null };
    },
    (error) => {
      //

      return { data: null, error };
    }
  );
};

export const createProspects = (prospect: ICreateProspect) => {
  return client("user/master/prospect", { body: prospect }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      //
      return { data: null, error };
    }
  );
};

export const viewProspect = (id: number | string) => {
  return client(`user/master/prospect/${id}`, { body: null }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const deleteProspect = (id: number | string) => {
  return client(`user/master/prospect/${id}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updateProspect = (id: number | string, data: IProspect) => {
  return client(`user/master/prospect/${id}`, {
    body: data,
    method: "PATCH",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const assignEmployee = (prospectId: number, employeeId: number) => {
  return client(`user/master/prospect/${prospectId}/employee/${employeeId}`, {
    body: null,
    method: "POST",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const unassignEmployee = (prospectId: number, employeeId: number) => {
  return client(`user/master/prospect/${prospectId}/employee/${employeeId}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const addTask = (prospectId: number, task: ITask) => {
  return client(`user/master/prospect/${prospectId}/task`, {
    body: task,
    method: "POST",
  }).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const removeTask = (prospectId: number, taskId: number) => {
  
  return client(`user/master/prospect/${prospectId}/task/${taskId}`, {
    body: null,
    method: "DELETE",
  }).then(
    (data) => {
      
      return { data, error: null };
    },
    (error) => {
      
      return { data: null, error };
    }
  );
};

export const getTasks = (request: IPagination, prospectId: number) => {
  return client(`user/master/prospect/${prospectId}/tasks?limit=100`, {
    body: null,
    method: "GET",
  }).then(
    (data: ITasks) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};


export const updateTask = (prospectId: number, task: ITask) => {
  return client(`user/master/prospect/${prospectId}/task/${task.id}`, {
    body: task,
    method: "PATCH",
  }).then(
    (data: ITasks) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const documentReaderApi = (formData) => {
  return clientFile(`user/master/aws/id`, { body: formData }).then(
      (data) => {
        return { data, error: null };
      },
      (error) => {
        return { data: null, error };
      }
  );
}
