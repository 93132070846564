/* import { IDeal, IDeals, IDealSettings,} from "types/dealTypes"; */
import * as actions from "./dealTracker.types";
import {IFinanceProduct, IAppraisalTable} from "types/dealTypes";

interface IState {
  loading: boolean;
  items: any;
  dealRecords: any;
  appointments: any;
  tasks: any;
  appraisals: any;
  salesFinanceList: any;
  selectedSalesFinanceList: any;
  selectedDealRecord: any;
  selectedTradeIn: any;
  error: any;
  financeProductList: IFinanceProduct[];
  appraisalTableList: IAppraisalTable[];
  serviceContract: any;
  salesHierarchyList: any;
  serviceContractList: any;
  cancellations: any;
  DRLoading: boolean;
}

const initialState: IState = {
  loading: false,
  items: null,
  dealRecords:[],
  appointments: [],
  tasks: [],
  appraisals:[],
  salesFinanceList: null,
  selectedSalesFinanceList: null,
  selectedDealRecord: null,
  selectedTradeIn: null,
  error:null,
  financeProductList:[],
  appraisalTableList:[],
  serviceContract: [],
  salesHierarchyList:[],
  serviceContractList: [],
  cancellations: [],
  DRLoading:true,
};

export const DealTrackerReducer = (
  state: IState = initialState,
  action: actions.DealTrackerActions
) => {
  switch (action.type) {
    case actions.HANDLE_GET_SALES_FINANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        salesFinanceList: action.payload
      };
  
    case actions.HANDLE_GET_SELECTED_SALES_FINANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedSalesFinanceList: action.payload
      };

    case actions.VIEW_DEAL_TRACKER_PENDING:
        return {
            ...state,
            loading: false,
            error: null
        };

    case actions.VIEW_DEAL_TRACKER_RESOLVED:
        return {
            ...state,
            loading: false,
            selectedDealRecord: action.payload
        };
    case actions.UPDATE_DEL_VEH_TRACKER_RESOLVED:
      return {
          ...state,
          loading: false,
          selectedDealRecord: {
            ...state.selectedDealRecord,
            vehicle: action.payload
          }
      };
    case actions.VIEW_DEAL_TRACKER_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
      case actions.GET_DEALS_TRACKER_PENDING:
        return {
            ...state,
            loading: false,
            DRLoading: true,
            error: null,
            dealRecords:[]
        };
    case actions.GET_DEALS_TRACKER_RESOLVED:
      return {
          ...state,
          loading: false,
          DRLoading: false,
          dealRecords: action.payload
      };
      case actions.GET_DEALS_TRACKER_REJECTED:
        return {
            ...state,
            loading: false,
            DRLoading: false,
            error: action.payload
        };

    case actions.GET_FINANCEPRODUCT_LIST_PENDING: {
        return {
            ...state,
            loading: false,
            financeProductList: null,
            error:null
        };
    }
    case actions.GET_FINANCEPRODUCT_LIST_RESOLVED: {
       return {
         ...state,
         loading: false,
         financeProductList: action.payload
       };
    }
    case actions.GET_FINANCEPRODUCT_LIST_REJECTED: {
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    }
    case actions.GET_APPOINTMENTS_PENDING:
        return {
            ...state,
            loading: false,
            error: null,
            appointments: []
        };
    case actions.GET_APPOINTMENTS_RESOLVED:
        return {
            ...state,
            loading: false,
            appointments: action.payload
        };
    case actions.GET_APPOINTMENTS_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
      case actions.GET_APPRAISALS_PENDING:
        return {
            ...state,
            loading: false,
            error: null,
            appraisals: []
        };
      case actions.GET_APPRAISALS_RESOLVED:
        return {
            ...state,
            loading: false,
            appraisals: action.payload
        };
      case actions.GET_APPRAISALS_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
          };
      case actions.HANDLE_SELECTED_TRADEIN:
          return {
              ...state,
              selectedTradeIn: action.payload
          };

    case actions.GET_APPRAISAL_TABLE_LIST_PENDING: {
        return {
            ...state,
            loading: false,
            appraisalTableList: null,
            error:null
        };
    }
    case actions.GET_APPRAISAL_TABLE_LIST_RESOLVED: {
       return {
         ...state,
         loading: false,
         appraisalTableList: action.payload
       };
    }
    case actions.GET_APPRAISAL_TABLE_LIST_REJECTED: {
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    }
    
    case actions.GET_SERVICE_CONTRACT_PENDING: {
        return {
            ...state,
            loading: false,
            serviceContract: null,
            error:null
        };
    }
    case actions.GET_SERVICE_CONTRACT_RESOLVED: {
       return {
         ...state,
         loading: false,
         serviceContract: action.payload
       };
    }
    case actions.GET_SERVICE_CONTRACT_REJECTED: {
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    }

    case actions.GET_TASKS_PENDING:
        return {
            ...state,
            loading: false,
            error: null,
            tasks: []
        };
    case actions.GET_TASKS_RESOLVED:
        return {
            ...state,
            loading: false,
            tasks: action.payload.results
        };
    case actions.GET_TASKS_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    case actions.GET_SALES_HIERARCHY_PENDING:
        return {
            ...state,
            loading: false,
            error: null,
            salesHierarchyList: []
        };
    case actions.GET_SALES_HIERARCHY_RESOLVED:
        return {
            ...state,
            loading: false,
            salesHierarchyList: action.payload
        };
    case actions.GET_SALES_HIERARCHY_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    case actions.GET_SERVICE_CONTRACT_LIST_PENDING: {
        return {
            ...state,
            loading: false,
            serviceContractList: null,
            error:null
        };
    }
    case actions.GET_SERVICE_CONTRACT_LIST_RESOLVED: {
       return {
         ...state,
         loading: false,
         serviceContractList: action.payload
       };
    }
    case actions.GET_SERVICE_CONTRACT_LIST_REJECTED: {
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    }

    case actions.GET_CANCELLATIONS_PENDING:
        return {
            ...state,
            loading: false,
            error: null,
            cancellations: []
        };
    case actions.GET_CANCELLATIONS_RESOLVED:
        return {
            ...state,
            loading: false,
            cancellations: action.payload.results
        };
    case actions.GET_CANCELLATIONS_REJECTED:
        return {
            ...state,
            loading: false,
            error: action.payload
        };

    default:
      return state;
  }
};
