import {DefaultPalette, IStackStyles, mergeStyles} from "@fluentui/react";
import {IButtonStyles} from "@fluentui/react/lib/Button";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const searchStyle = mergeStyles({
    marginLeft: 30,
});

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    container: container,
    searchStyle: searchStyle,
    buttonStyles: buttonStyles
}

