import React, { FunctionComponent } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  PrimaryButton,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import { contentStyles as content } from "components/dialogs/websiteDetails/styles";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { iconButtonStyles, cancelIcon } from "constants/styles";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
  handleGetTemplates,
  handleGetWebsite,
  handleUpdateWebsite,
} from "store/website/website.action";

const contentStyles = content();

export enum FormStep {
  Main,
  Financial,
  Engine,
  Body,
  Options,
}

interface IProps {
  onDismiss: () => void;
  details: any;
  shouldDisabledButton: Function;
  openConfigureDialog: Function;
}
export const WebsiteDetailsDialog: FunctionComponent<IProps> = ({
  // isOpen,
  onDismiss,
  details,
  shouldDisabledButton,
  openConfigureDialog,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ website }: AppState) => website.loading);
  const website = useSelector(({ website }: AppState) => website.website);

  const handleActivateWebsite = () => {
    if (website) {
      const ws = { ...website, configuration: details.id };
      dispatch<any>(
        handleUpdateWebsite(ws, () => {
          dispatch<any>(handleGetTemplates());
          dispatch<any>(handleGetWebsite());
          onDismiss();
        })
      );
    } else {
      onDismiss();
      openConfigureDialog();
    }
  };

  const renderSpinner = () => {
    return loading ? <Spinner size={SpinnerSize.medium} /> : null;
  };

  return (
    <>
      <Modal
        //isOpen={isOpen}
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <GoBackToMainDialogBtn label={details.name} onClick={onDismiss} />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </Stack>

        <div className={`noScrollbar ${contentStyles.body}`}>
          <div className={contentStyles.imageContainer}>
            <div className={contentStyles.image} />
          </div>
        </div>

        <Stack
          tokens={{ childrenGap: 10 }}
          horizontal
          horizontalAlign="end"
          style={{ paddingBottom: 40 }}
        >
          <DefaultButton text="Close" onClick={onDismiss} />

          <PrimaryButton
            onRenderIcon={renderSpinner}
            iconProps={{ iconName: "Globe" }}
            text="Activate Website Now "
            onClick={handleActivateWebsite}
            disabled={shouldDisabledButton(details.type)}
          />
        </Stack>
      </Modal>
    </>
  );
};
