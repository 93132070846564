import React from "react";

const Logo = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="29.653"
        viewBox="0 0 96 29.653"
      >
        <path
          d="M427.8,399.974H415.938a4.444,4.444,0,0,0,3.452,4.206,5.192,5.192,0,0,0,5.688-1.994c.326-.434.58-.39.988-.192,1.524.74,1.589.879.45,2.143-2.114,2.348-4.839,2.841-7.759,2.179a6.759,6.759,0,0,1-5.286-5.421,7.4,7.4,0,0,1,2.7-7.909,7.85,7.85,0,0,1,8.467-.228,7.224,7.224,0,0,1,3.16,4.837Zm-11.829-2.319a.751.751,0,0,0,.216.07c2.818.007,5.636.005,8.454.015.621,0,.608-.3.446-.764a4.849,4.849,0,0,0-5.713-2.967A4.169,4.169,0,0,0,415.971,397.655Z"
          transform="translate(-331.801 -381.58)"
        />
        <path
          d="M243.541,409.55l-1.6-.42a14.846,14.846,0,0,1-10.982-13,15,15,0,0,1,.783-6.307,6.044,6.044,0,0,1,.243-.582c.018-.037.081-.054.192-.121a4.547,4.547,0,0,1,.082.611c-.032,7.643,3.484,13.417,9.445,17.891.9.678,1.858,1.288,2.788,1.93Z"
          transform="translate(-209.701 -379.897)"
          fill="#0065c9"
        />
        <path
          d="M253.2,361.207c.9.206,1.81.361,2.69.626a14.118,14.118,0,0,1,7.6,5.447c-7.953-4.1-15.87-4.758-23.859,0,.23-.3.446-.606.693-.889a14.9,14.9,0,0,1,9.169-5.052,5.462,5.462,0,0,0,.53-.137Z"
          transform="translate(-215.554 -361.207)"
          fill="#fe8000"
        />
        <path
          d="M262.631,384.828a12.55,12.55,0,0,1,2.606-2.019,10.256,10.256,0,0,0-2.829-3.7,9.708,9.708,0,0,0-13.286,14.1,11.147,11.147,0,0,0,1.791,1.244c-.073-.539-.383-1.053-.259-1.6a3.649,3.649,0,0,1,.955-1.735,6.378,6.378,0,0,1-1.848-4.94,6.512,6.512,0,0,1,6.326-6.031A6.761,6.761,0,0,1,262.631,384.828Z"
          transform="translate(-220.152 -371.712)"
        />
        <path
          d="M337.427,406.486a7.084,7.084,0,0,1-7.38-7.461,7.419,7.419,0,1,1,14.836.089A7.061,7.061,0,0,1,337.427,406.486Zm4.995-7.425c0-3.093-2-5.193-4.949-5.19a4.873,4.873,0,0,0-4.979,5.195,4.985,4.985,0,0,0,4.984,5.244A4.935,4.935,0,0,0,342.422,399.061Z"
          transform="translate(-276.097 -381.523)"
        />
        <path
          d="M172.93,376.887c1.716,0,3.434.034,5.149-.015.779-.022,1,.266.982,1.01-.036,1.414,0,1.416-1.45,1.416-2.456,0-4.912.016-7.368-.01-.642-.007-.891.185-.869.856.044,1.345.04,2.694,0,4.04-.018.621.183.833.809.825,2.192-.029,4.383-.012,6.575-.01,1.2,0,1.2,0,1.2,1.238,0,1.157,0,1.16-1.126,1.161-2.139,0-4.278,0-6.417,0-1.028,0-1.03,0-1.03,1.061,0,2.244-.021,4.49.012,6.734.01.693-.159,1-.921.978-2.05-.059-1.716.178-1.721-1.735-.016-5.44-.006-10.88-.005-16.32,0-1.229,0-1.23,1.267-1.231Z"
          transform="translate(-166.739 -371.696)"
        />
        <path
          d="M387.217,394.464a4.623,4.623,0,0,1,4.428-1.952c.789.094,1.2.433,1.086,1.288a9.842,9.842,0,0,0-.009,1.252c-.535-.1-.94-.178-1.346-.253a3.127,3.127,0,0,0-3.7,1.986,7.36,7.36,0,0,0-.472,2.381c-.063,2.188-.04,4.38-.011,6.57.009.643-.192.891-.855.879-1.825-.033-1.606.1-1.609-1.546-.008-3.773.018-7.547-.019-11.32-.008-.808.254-1.125,1.043-1.019a1.874,1.874,0,0,0,.474,0c.727-.089,1.028.225.942.949A5.239,5.239,0,0,0,387.217,394.464Z"
          transform="translate(-312.702 -382.143)"
        />
        <path
          d="M215.334,400.212c0,2.008-.018,4.016.01,6.024.009.651-.218.889-.873.877-1.825-.033-1.592.107-1.6-1.559-.009-3.778.015-7.557-.017-11.335-.006-.756.221-1.021.989-1,1.487.035,1.488,0,1.488,1.448Q215.335,397.437,215.334,400.212Z"
          transform="translate(-197.622 -382.641)"
        />
        <path
          d="M317.621,391.239a9.515,9.515,0,0,0-.435-1.013c-.022.256-.04.513-.059.769A3.957,3.957,0,0,1,317.621,391.239Z"
          transform="translate(-267.445 -380.639)"
          fill="#4f9d3c"
        />
        <path
          d="M313.953,414.382c-.6.285-1.165.612-1.738.952l-.078.045-.041.042c-.132.131-.268.3-.411.427.086.086.172.171.254.261a4.55,4.55,0,0,1,1.113,2.181,13.426,13.426,0,0,0,1.44-4.244A3.042,3.042,0,0,1,313.953,414.382Z"
          transform="translate(-263.801 -396.59)"
          fill="#4f9d3c"
        />
        <path
          d="M211.478,377.507a1.707,1.707,0,0,1,3.41.024,1.705,1.705,0,1,1-3.41-.024Z"
          transform="translate(-196.698 -371.045)"
        />
        <path
          d="M288.207,440.635a3.508,3.508,0,0,1-1.406.674c-.153.033-.306.061-.459.089-.449.416-.912.818-1.376,1.218s-.985.751-1.48,1.124a12.211,12.211,0,0,0,5.768-2.128c-.325-.341-.6-.6-.833-.871C288.348,440.709,288.279,440.67,288.207,440.635Z"
          transform="translate(-244.919 -414.395)"
          fill="#4f9d3c"
        />
        <path
          d="M291.373,389.86a.124.124,0,0,1-.158-.12c-.013-.106-.013-.207-.164-.2-.17-.478.327-.628.481-.947a1.8,1.8,0,0,0,.8-.79.85.85,0,0,1,.74-.2c.41.2.186.638.469.833a.606.606,0,0,1,.123.159.323.323,0,0,1-.016.185.541.541,0,0,0-.085.246c.041.113.124.219.073.35-.029.066-.087.122-.071.218.384-.183.768-.4,1.173-.057a.722.722,0,0,1,.321.632.807.807,0,0,1-.365.512c-.161.006-.258-.186-.48-.124a2.7,2.7,0,0,0-.033,1.235c.4-.012.776-.08,1.161-.056.218.087.212.342.359.485v.158c-.159.139-.013.365-.148.511a.355.355,0,0,1-.31.03c-.093-.045-.151-.143-.254-.175-.089,0-.133.076-.194.125a.386.386,0,0,1-.281.074.422.422,0,0,1-.174-.093.817.817,0,0,0-.116-.094c-.124.007-.135.118-.184.2-.147.352-.623.339-.776.683a1.151,1.151,0,0,1-.26.34c-.185.07-.263-.066-.353-.178v-.79c.3-.265-.067-.511-.024-.769a.384.384,0,0,1,.505-.082c.108.076.151.213.334.321a2.556,2.556,0,0,0,.074-1.228c-.2-.031-.248.077-.319.144a.2.2,0,0,1-.278.024,2.04,2.04,0,0,0-.449-.4.366.366,0,0,1-.094-.182.978.978,0,0,0-.31-.954.389.389,0,0,0-.172-.113,1.2,1.2,0,0,0-.248.144A.3.3,0,0,1,291.373,389.86Z"
          transform="translate(-249.961 -378.879)"
          fill="#0b100b"
        />
        <path
          d="M276.391,407.167a2.1,2.1,0,0,1,2.164-1.269c.216,0,.339.238.564.164.221.377-.3.586-.215.934a1.793,1.793,0,0,1-.152.421.48.48,0,0,1-.167.162.653.653,0,0,1-.572-.019c-.167-.106-.189-.368-.468-.424-.106.382.217.554.351.8a.3.3,0,0,1-.021.3.936.936,0,0,1-.08.081l.092-.084a.7.7,0,0,1,.6-.093.622.622,0,0,1,.185.093.961.961,0,0,0,.524.42.5.5,0,0,1,.207.226,2.76,2.76,0,0,1,.133.514.923.923,0,0,1,0,.215c-.187.458.117.811.264,1.2a.65.65,0,0,1-.5.265,1.164,1.164,0,0,1-.662-.209.33.33,0,0,1-.07-.3c.014-.06.083-.082.111-.135a.108.108,0,0,0-.106-.079.693.693,0,0,1-.222-.289c-.058-.214-.242-.273-.426-.332-.317-.237-.726.032-1.045-.187-.111,0-.165-.048-.161-.158a1.8,1.8,0,0,1-.16-1.072.387.387,0,0,1,.233-.273c-.081-.078-.167-.01-.235-.058A.662.662,0,0,1,276.391,407.167Z"
          transform="translate(-240.115 -391.134)"
          fill="#12160e"
        />
        <path
          d="M259.844,419.133c.24-.014.136-.2.161-.316.111,0,.165.049.161.158.118.144.148.333.256.483.149.159.383.172.547.307q.167-.006.161.158c.048.086.049.192.122.266.111.077.249-.036.359.05l.482.315a.172.172,0,0,1,.055.172c-.127.219.066.329.168.471a.272.272,0,0,1-.036.275c-.028.029-.054.057-.074.079a.972.972,0,0,1,.092-.088.275.275,0,0,1,.283,0,.487.487,0,0,1,.117.34c.109-.147.226-.246.41-.122a.426.426,0,0,1,.024.428.536.536,0,0,1-.162.15c-.077.054-.181.072-.23.166.016.128.127.116.215.136.4.077.539-.24.745-.46a.256.256,0,0,1,.218.054.683.683,0,0,1,.085.576.533.533,0,0,1-.2.278c-.308.095-.417.361-.541.615-.214.314-.565.175-.851.25h-.16c-.082-.1-.237-.061-.321-.158h-.161c-.1-.168.112-.26.1-.41-.108.066-.162.182-.262.252h-.321c-.085-.264-.478-.377-.377-.726a.07.07,0,0,1,.1-.056c-.042-.043-.074-.032-.1.038-.3.1-.509-.293-.82-.146a.585.585,0,0,1-.343-.541,1.845,1.845,0,0,1,.181-.776c.209-.227.752-.178.6-.7a.286.286,0,0,0-.064-.114c-.079-.011-.1.06-.141.1-.124.094-.248.148-.384.023a.718.718,0,0,1-.126-.327A3.276,3.276,0,0,1,259.844,419.133Z"
          transform="translate(-229.025 -399.785)"
          fill="#0b120e"
        />
        <path
          d="M295.323,421.686a7.572,7.572,0,0,1,1.445,1.421.353.353,0,0,1-.207.259c-.383.133-.646-.05-.891-.45-.017.72.5.571.813.7a.414.414,0,0,1,.167.142.235.235,0,0,1-.058.2c-.211.117-.443.213-.5.484a.521.521,0,0,1-.143.169.669.669,0,0,1-.449.061.614.614,0,0,1-.2-.1c-.1-.1-.137-.25-.256-.323-.167.02-.185.161-.26.248a.413.413,0,0,1-.169.129c-.708.1-.495-.577-.755-.87-.227-.1-.277.164-.432.217a.737.737,0,0,1-.592.029.369.369,0,0,1-.176-.168.619.619,0,0,1,.042-.575,1.44,1.44,0,0,0,.669-.792.571.571,0,0,1,.625.119.775.775,0,0,1,.145.172c-.01-.078-.072-.079-.1-.12a.144.144,0,0,1-.05-.11c0-.04.007-.06.016-.062a5.633,5.633,0,0,0,.668-.457c.139-.06.237.059.358.078.1-.047.117-.152.159-.24A.4.4,0,0,1,295.323,421.686Z"
          transform="translate(-251.022 -401.706)"
          fill="#4f843d"
        />
        <path
          d="M299.534,411.547a4.479,4.479,0,0,1-1.124-1.105,1.616,1.616,0,0,0,.367-.7.452.452,0,0,1,.137-.162.951.951,0,0,1,.412-.1c.224-.019.459.04.664-.1a1.058,1.058,0,0,0,.111-.157c.1-.379-.049-.794.178-1.152a.4.4,0,0,1,.327-.108.469.469,0,0,1,.211.112,6.146,6.146,0,0,0-.18,1.168,5.359,5.359,0,0,1-.622,1.832c-.252.2-.327-.015-.425-.194a2.866,2.866,0,0,1,.085.38A.373.373,0,0,1,299.534,411.547Z"
          transform="translate(-254.912 -392.515)"
          fill="#0a5cac"
        />
        <path
          d="M301.616,397.442c-.262-.044-.294-.291-.425-.482l-.112.309h-.367l-.132-.316c-.135.284-.241.592-.695.46a4.107,4.107,0,0,1-.033-.947c-.01-.338,0-.676,0-1,.183-.134.374-.045.551-.071.231.082-.086.446.251.474a.351.351,0,0,1,.481,0c.332.032.15.324.21.475A2.685,2.685,0,0,1,301.616,397.442Z"
          transform="translate(-255.87 -384.094)"
          fill="#4c933b"
        />
        <path
          d="M276.149,428.987h-.161c-.286-.119-.575.015-.863-.01-.237-.372-.022-.949-.558-1.184a.2.2,0,0,1-.045-.2,3.077,3.077,0,0,1,.769-.536.4.4,0,0,1,.088.073.861.861,0,0,0-.084-.085.293.293,0,0,1-.008-.27c.052-.078.123-.142.176-.218-.015-.1-.092-.16-.143-.238-.058-.176.074-.25.185-.335h.321c.359.191.378.538.414.876a.988.988,0,0,0,.02.361c.074.174.28.191.4.318a.151.151,0,0,1-.028.183c-.08.09-.2.14-.27.257.127-.051.169-.169.255-.244a.168.168,0,0,1,.186-.049c.1.082.149.212.253.3.171.033.2-.125.281-.212a.241.241,0,0,1,.181-.092,1.49,1.49,0,0,1,.559.828,1.643,1.643,0,0,0-.16.158h-.321c-.256.081-.578-.107-.783.184C276.591,428.9,276.32,428.7,276.149,428.987Z"
          transform="translate(-238.91 -404.587)"
          fill="#152118"
        />
        <path
          d="M248.4,415.793c0-.126.028-.255-.107-.345-.107-.072-.034-.191-.053-.286-.137-.213-.049-.344.175-.372a1.173,1.173,0,0,1,.948.214,1.958,1.958,0,0,1,.523,1.535.583.583,0,0,0-.072.08.763.763,0,0,1,.1-.094.44.44,0,0,1,.417-.013,2.723,2.723,0,0,1,.744.771.457.457,0,0,1-.112.472.991.991,0,0,1-.883.046,3.05,3.05,0,0,0-.873-.586v-.158c-.123-.147-.158-.353-.321-.474-.152-.189.067-.559-.321-.632A2.189,2.189,0,0,0,248.4,415.793Z"
          transform="translate(-221.271 -397.076)"
          fill="#081417"
        />
        <path
          d="M295.639,405.9c.024-.12-.08-.3.161-.316a.6.6,0,0,1,.887.067,1.123,1.123,0,0,1,.1.676,1.2,1.2,0,0,1-.058.259c-.2.5-.486.715-1,.379a.725.725,0,0,1-.069.815.359.359,0,0,1-.5.016c-.194-.009-.189-.138-.133-.255.039-.083.161-.13.132-.248-.079-.329.243-.589.161-.918C295.489,406.254,295.464,406.01,295.639,405.9Z"
          transform="translate(-252.622 -390.811)"
          fill="#0c63b7"
        />
        <path
          d="M283.619,429.793c-.038-.292-.286-.1-.417-.173-.223-.153.148-.516-.225-.616a.732.732,0,0,1-.174-.672.781.781,0,0,1,.072-.215l.069-.084.1-.042c.188-.023.383.056.569-.028a1,1,0,0,1,.17-.064.39.39,0,0,1,.321.315c-.036.5.727.835.321,1.421C284.138,429.609,283.913,429.875,283.619,429.793Z"
          transform="translate(-244.454 -405.867)"
          fill="#0e64b0"
        />
        <path
          d="M257.254,422.545v.316l-.063.078a.584.584,0,0,0-.381.6,1.047,1.047,0,0,0,.283.739c.165.12.049.425.321.474.026.131.015.225-.161.158a6.072,6.072,0,0,1-1.5-1.077c-.044-.058-.133-.09-.108-.186a5.279,5.279,0,0,1,.242-.931c.268-.139.353-.419.509-.645a.253.253,0,0,1,.2-.042A1.254,1.254,0,0,0,257.254,422.545Z"
          transform="translate(-226.275 -401.934)"
          fill="#11170d"
        />
        <path
          d="M270.049,430.462v.158a.588.588,0,0,1,.089.7c-.259.345-.688.266-.892.559-.243-.042-.483.05-.74-.133-.16-.114-.49-.011-.7-.182-.01-.137.08-.167.189-.156a.877.877,0,0,0,.614-.16c.229-.16.275-.455.481-.632q.166.006.161-.158a.561.561,0,0,1,.157-.116.945.945,0,0,1,.394-.025.584.584,0,0,1,.187.066Z"
          transform="translate(-234.414 -407.482)"
          fill="#488e40"
        />
        <path
          d="M280.1,426.583v.79a.474.474,0,0,1-.642,0h-.16v-.158c-.179-.2-.317-.424-.482-.632-.023-.168-.239-.272-.161-.473s-.137-.306-.16-.474c.283.026.508-.239.8-.158.2.156.509.089.674.318.037.176-.142.275-.162.438C279.83,426.4,280,426.465,280.1,426.583Z"
          transform="translate(-241.574 -404.235)"
          fill="#508e45"
        />
        <path
          d="M295.934,429.683h.161a.393.393,0,0,0,.256.157c.092-.011.155-.089.225-.157v-.158a.513.513,0,0,1,.567-.1.246.246,0,0,1,.128.168c-.01.131-.142.2-.178.316.03.1.118.154.172.235a.242.242,0,0,1-.01.194c-.193.141-.338.332-.523.481-.283-.006-.418-.346-.718-.305a.47.47,0,0,0-.138.031c-.052.108.054.158.09.236a.243.243,0,0,1-.054.194.568.568,0,0,1-.529.076.448.448,0,0,1-.17-.126c-.117-.243-.258-.455-.583-.421-.275-.05-.5.2-.781.121a.391.391,0,0,1-.321-.316c-.067-.3.151-.469.3-.671a.433.433,0,0,1,.217-.041c.4.228.8.592,1.249.08a.143.143,0,0,1,.172-.039c.054.039.093.1.163.122.083,0,.1-.083.15-.124A.121.121,0,0,1,295.934,429.683Z"
          transform="translate(-251.633 -406.862)"
          fill="#223943"
        />
        <path
          d="M274.641,425.987l-.161.316a.336.336,0,0,1,0,.474v.315a.813.813,0,0,0-.2.474h-.606a.83.83,0,0,1-.213-.322c-.029-.058-.054-.118-.079-.178a2.715,2.715,0,0,1-.147-.365.7.7,0,0,1,.022-.4.975.975,0,0,1,.256-.315Z"
          transform="translate(-238.044 -404.587)"
          fill="#4d9840"
        />
        <path
          d="M278.006,416.569c.359-.035.692-.291,1.114-.091.052.048-.008.155.009.249.152.119.361.152.482.315l.161.316c.048.035.064.07.048.105s-.032.053-.048.053v.316c.106.184.11.317-.161.316A9.04,9.04,0,0,1,278.006,416.569Z"
          transform="translate(-241.248 -398.168)"
          fill="#3f6f35"
        />
        <path
          d="M272.176,432.507v-.4c.23-.16.508-.008.8-.118v-.743h.642v1.263c.055.035.074.07.055.105s-.037.052-.055.052A5.728,5.728,0,0,1,272.176,432.507Z"
          transform="translate(-237.344 -408.107)"
          fill="#1761a3"
        />
        <path
          d="M297.92,390.041v-.158q-.185-.5.321-.316a2.531,2.531,0,0,1,.8,1.263h-.88c-.168.108-.02.3-.113.425-.535.022-.538.024-.612-.492.082-.231.454.084.481-.248A1.192,1.192,0,0,1,297.92,390.041Z"
          transform="translate(-254.261 -380.165)"
          fill="#0560b9"
        />
        <path
          d="M285.372,408.782a5.936,5.936,0,0,1,1.284,1.263.532.532,0,0,1-.156.167.635.635,0,0,1-.662.041.575.575,0,0,1-.123-.186c-.047-.113-.057-.244-.162-.331-.141-.107-.289-.047-.435-.017-.285,0-.275.4-.558.4-.408-.143-.33-.49-.348-.8a.8.8,0,0,1,.1-.327C284.629,408.766,285.024,408.888,285.372,408.782Z"
          transform="translate(-245.404 -393.065)"
          fill="#1a3237"
        />
        <path
          d="M296.614,386.466h-.321v.316h-.419a.686.686,0,0,1,.076-.732l-.189-.185c-.218.185-.491.119-.752.128q0-.271,0-.544c.11-.2.363.015.485-.172l-.324-.145v-.244c.347.177.712.306.858.743.065.2.305.342.483.491C296.633,386.223,296.613,386.342,296.614,386.466Z"
          transform="translate(-252.634 -377.064)"
          fill="#477e37"
        />
        <path
          d="M248.843,413.3c-.357-.057-.744.139-1.055-.146-.111.1-.051.209-.068.3-.044-.017-.127-.037-.126-.05.032-.321-.207-.583-.2-.9a.87.87,0,0,0,.642-.158.473.473,0,0,1,.642,0C248.919,412.637,248.83,412.976,248.843,413.3Z"
          transform="translate(-220.752 -395.372)"
          fill="#0760b5"
        />
        <path
          d="M295.495,409.956h.477v-1.307c.252.152.107.336.2.471.161.1.349.024.523.047.226-.087-.088-.444.25-.474a.963.963,0,0,1,.216.779.886.886,0,0,1-.216.643,1.066,1.066,0,0,1-1.284,0C295.544,410.118,295.491,410.065,295.495,409.956Z"
          transform="translate(-252.959 -392.976)"
          fill="#162f23"
        />
        <path
          d="M289.317,389.333q0,.273,0,.547c-.107.169-.3.026-.445.123-.086.068-.016.181-.037.277a2.883,2.883,0,0,0-1.012-.922.16.16,0,0,1-.112-.183h.237c.167-.107.028-.291.111-.422.108-.106.247-.024.372-.053.2.144-.085.512.274.632Z"
          transform="translate(-247.745 -379.616)"
          fill="#4e933d"
        />
        <path
          d="M292.9,394.4h.233c.169.1.043.278.122.449.043.086.245-.043.306.2.038.161-.163.129-.293.174-.089.082-.029.189-.046.283a.9.9,0,0,1-.085.567l-.075.064c-.337-.032-.106-.326-.227-.488a11.391,11.391,0,0,1-.736-1.249h.321A1.23,1.23,0,0,1,292.9,394.4Z"
          transform="translate(-250.683 -383.416)"
          fill="#559a45"
        />
        <path
          d="M284.794,407.173c-.27.047-.292.3-.407.473-.294.077-.211-.214-.279-.315h-.278a.227.227,0,0,1-.321,0c.076-.22-.2-.342-.155-.576a1.927,1.927,0,0,1,.2-.053,2.051,2.051,0,0,1,.211-.016c.113-.094.047-.205.066-.3.1.02.212-.049.294.052C284.335,406.694,284.656,406.853,284.794,407.173Z"
          transform="translate(-244.827 -391.456)"
          fill="#57933c"
        />
        <path
          d="M288.2,425.045a.863.863,0,0,1-.482-.474,7.575,7.575,0,0,0,.446-.821.585.585,0,0,1,.207-.1.681.681,0,0,1,.362.032.5.5,0,0,1,.248.277,1.545,1.545,0,0,1,.066.515.855.855,0,0,1-.2.627A.579.579,0,0,1,288.2,425.045Z"
          transform="translate(-247.755 -403.012)"
          fill="#508c39"
        />
        <path
          d="M254.213,423.755v.867c-.116.123-.225.063-.321.08a2.287,2.287,0,0,1-.8-.79c-.105-.183-.117-.32.161-.316h.963c.041.035.054.07.041.105S254.226,423.755,254.213,423.755Z"
          transform="translate(-224.517 -402.986)"
          fill="#0361bd"
        />
        <path
          d="M285.6,422.671a2.285,2.285,0,0,1-.8-.79.675.675,0,0,1,.114-.505c.167-.13.364-.213.538-.334a.255.255,0,0,1,.2.036c.151.2.367.331.518.528a.432.432,0,0,1-.024.417C285.948,422.227,285.8,422.467,285.6,422.671Z"
          transform="translate(-245.792 -401.27)"
          fill="#559042"
        />
        <path
          d="M294.524,432.516c.215.016.124-.179.229-.316h.574a6.618,6.618,0,0,0,.642.631.336.336,0,0,1,0,.474A1.68,1.68,0,0,1,294.524,432.516Z"
          transform="translate(-252.309 -408.747)"
          fill="#498c46"
        />
        <path
          d="M286.714,430.275v-.545c-.105-.17-.293-.032-.441-.12a.773.773,0,0,1,.12-.757c.4.21.632.6.973.869C287.339,430.147,286.921,430.089,286.714,430.275Z"
          transform="translate(-246.747 -406.506)"
          fill="#579d4c"
        />
        <path
          d="M296.685,418.273a1.614,1.614,0,0,1,.642.632.645.645,0,0,1-.141.679.7.7,0,0,1-.243.093c-.671-.219-.752-.443-.495-1.362A.164.164,0,0,1,296.685,418.273Z"
          transform="translate(-253.507 -399.399)"
          fill="#0667c4"
        />
        <path
          d="M266.9,425.022v-.316c-.126-.134-.284-.258-.161-.474v-.158a2.034,2.034,0,0,1,1.124.474.372.372,0,0,1,.1.339.3.3,0,0,1-.273.213c-.165.026-.331.062-.466-.079Z"
          transform="translate(-233.675 -403.306)"
          fill="#1062b4"
        />
        <path
          d="M295.981,413.084h1.284v.789h-.482A2.287,2.287,0,0,1,295.981,413.084Z"
          transform="translate(-253.285 -395.946)"
          fill="#589135"
        />
        <path
          d="M270.233,425.771v-.316c.449-.114.847.176,1.284.158-.024.12.08.3-.161.316a.276.276,0,0,0,0,.474c.24.013.136.2.161.316a2.571,2.571,0,0,0-.481.474h-.161v-.632A2.722,2.722,0,0,0,270.233,425.771Z"
          transform="translate(-236.043 -404.213)"
          fill="#2a493b"
        />
        <path
          d="M290.737,412.606h.161a1.612,1.612,0,0,1,.642.631.662.662,0,0,1-.192.609C290.552,413.95,290.351,413.542,290.737,412.606Z"
          transform="translate(-249.646 -395.626)"
          fill="#de7e12"
        />
        <path
          d="M276.544,410.684v1.263c-.32-.126-.191-.542-.482-.688,0-.447,0-.885,0-1.323,0-.107.007-.211.16-.2a.766.766,0,0,0,.321.789c.041.035.054.07.041.105S276.557,410.684,276.544,410.684Z"
          transform="translate(-239.946 -393.704)"
          fill="#53965f"
        />
        <path
          d="M260.516,429.792q.166.006.161-.158V429.4c.113-.152.272-.068.412-.075.152.114,0,.294.107.423.079.083.188.024.284.041v.229c.058.116.185.064.271.115.1.139.03.3.05.445A4.22,4.22,0,0,1,260.516,429.792Z"
          transform="translate(-229.536 -406.813)"
          fill="#4e8e3e"
        />
        <path
          d="M289.18,427.856h.642a.656.656,0,0,1,.642.158.5.5,0,0,1,.044.646.417.417,0,0,1-.525-.015A2.286,2.286,0,0,1,289.18,427.856Z"
          transform="translate(-248.731 -405.824)"
          fill="#1167b5"
        />
        <path
          d="M246.638,403.525v1.091c.222-.078.382-.221.595-.115.1.163.026.346.048.519-.049.072-.126.068-.2.088-.15.042-.389-.152-.442.153-.045-.018-.13-.036-.13-.055q-.01-.813,0-1.627C246.508,403.561,246.593,403.543,246.638,403.525Z"
          transform="translate(-220.152 -389.545)"
          fill="#3a732c"
        />
        <path
          d="M254.5,406.222a.949.949,0,0,0-.728-.152.834.834,0,0,1-.055-.585c.176-.1.362-.032.536-.052.223-.089-.09-.443.247-.474Z"
          transform="translate(-224.968 -390.505)"
          fill="#488639"
        />
        <path
          d="M283.666,421.193c-.061.155.149.4-.161.473a1.613,1.613,0,0,1-.642-.631c.24-.013.136-.2.161-.316a.869.869,0,0,1,.642.158A.209.209,0,0,1,283.666,421.193Z"
          transform="translate(-244.501 -401.055)"
          fill="#1665a8"
        />
        <path
          d="M300.641,432.2h.553c.227.086-.087.444.25.473a.189.189,0,0,1,.16.158c-.013.236-.2.134-.321.158a.985.985,0,0,1-.577-.084l-.065-.074v-.158A.335.335,0,0,1,300.641,432.2Z"
          transform="translate(-256.34 -408.747)"
          fill="#d97b1c"
        />
        <path
          d="M287.236,424.623v-.632h.482a.1.1,0,0,1,.161,0v.525a.439.439,0,0,1-.482.265Q287.23,424.787,287.236,424.623Z"
          transform="translate(-247.429 -403.222)"
          fill="#0b64b2"
        />
        <path
          d="M295.809,400.03c-.158.06-.406-.146-.482.158a.1.1,0,0,1-.161,0c.083-.29-.187-.511-.16-.789a.7.7,0,0,1,.824-.03A.475.475,0,0,1,295.809,400.03Z"
          transform="translate(-252.631 -386.682)"
          fill="#f07e0d"
        />
        <path
          d="M292.946,414.991c.061-.155-.149-.4.161-.474a1.614,1.614,0,0,1,.642.632c.062.139-.029.2-.132.263a.688.688,0,0,1-.456,0,.526.526,0,0,1-.259-.22A.179.179,0,0,1,292.946,414.991Z"
          transform="translate(-251.214 -396.906)"
          fill="#173c59"
        />
        <path
          d="M304.528,429.536v-.158a.336.336,0,0,1,0-.474v-.158a.335.335,0,0,1,0-.473.143.143,0,0,1,.179-.034c.163.133.12.385.3.507a1.47,1.47,0,0,1-.481,1.105A.217.217,0,0,1,304.528,429.536Z"
          transform="translate(-258.943 -406.083)"
          fill="#518145"
        />
        <path
          d="M247.719,396.835c-.013.2.165.133.321.207,0,.187.007.407,0,.625a.122.122,0,0,1-.157.115.351.351,0,0,1-.481,0c-.011-.314.228-.577.2-.9C247.592,396.872,247.675,396.852,247.719,396.835Z"
          transform="translate(-220.752 -385.065)"
          fill="#4f9443"
        />
        <path
          d="M265.374,431.849V431.3c.177-.186.393-.011.558-.094.154.2.031.388.094.531-.1.248-.462-.06-.492.271C265.423,432.011,265.37,431.959,265.374,431.849Z"
          transform="translate(-232.789 -408.081)"
          fill="#054b93"
        />
        <path
          d="M291.609,430.089h.481a.344.344,0,0,1,.482,0v.384c-.043.168-.35-.007-.321.248A1.613,1.613,0,0,1,291.609,430.089Z"
          transform="translate(-250.358 -407.267)"
          fill="#4a803d"
        />
        <path
          d="M252.088,422.637c-.24.014-.136.2-.161.316-.357-.15-.4-.545-.642-.789h.8Z"
          transform="translate(-223.355 -402.026)"
          fill="#569447"
        />
        <path
          d="M261.8,420.086H261v-.79A2.288,2.288,0,0,1,261.8,420.086Z"
          transform="translate(-229.862 -400.106)"
          fill="#2f78ba"
        />
        <path
          d="M281.563,425.486h-.642v-.316c.5-.253.63-.233.963.158a.281.281,0,0,1-.161.214A.14.14,0,0,1,281.563,425.486Z"
          transform="translate(-243.2 -403.927)"
          fill="#f48b1b"
        />
        <path
          d="M276.548,434.257V434.1a.607.607,0,0,0,.187-.422c.111-.1.249-.025.37-.052.221.089-.091.443.245.473.011.14-.082.158-.192.158Z"
          transform="translate(-240.272 -409.699)"
          fill="#4b9538"
        />
        <path
          d="M304.377,402.565c.061-.155-.149-.4.161-.473.015.478.27.929.161,1.421-.213.068-.333-.047-.431-.209A.673.673,0,0,1,304.377,402.565Z"
          transform="translate(-258.793 -388.585)"
          fill="#4c933b"
        />
        <path
          d="M247.069,399.7h.481v.632h-.642c0-.126-.027-.255.107-.345C247.122,399.916,247.049,399.8,247.069,399.7Z"
          transform="translate(-220.422 -386.985)"
          fill="#075aab"
        />
        <path
          d="M294.792,416.587q.167.006.16-.158a1.615,1.615,0,0,1,.642.631h-.161a.523.523,0,0,1-.659.042A.387.387,0,0,1,294.792,416.587Z"
          transform="translate(-252.417 -398.186)"
          fill="#dc8627"
        />
        <path
          d="M249.34,418.029c.154.035.175-.2.373-.145a.434.434,0,0,1,.269.293c.033.258-.269.1-.321.248v.236A.721.721,0,0,1,249.34,418.029Z"
          transform="translate(-222.052 -399.154)"
          fill="#a96115"
        />
        <path
          d="M284.244,426.939v.158a.645.645,0,0,1-.642,0c-.186-.27-.157-.486.16-.632A.863.863,0,0,1,284.244,426.939Z"
          transform="translate(-244.918 -404.907)"
          fill="#e88117"
        />
        <path
          d="M298.885,420.884a.836.836,0,0,1,.161-.632.862.862,0,0,1,.481.474v.158A.485.485,0,0,1,298.885,420.884Z"
          transform="translate(-255.226 -400.746)"
          fill="#e37f14"
        />
        <path
          d="M304.69,426.774h-.161v-.158a.338.338,0,0,1,0-.474c0-.109.05-.162.161-.158a.443.443,0,0,1,.072,0c.042.276.27.495.248.789A.219.219,0,0,1,304.69,426.774Z"
          transform="translate(-258.945 -404.584)"
          fill="#da8428"
        />
        <path
          d="M298.9,434.585v-.474h.642a.337.337,0,0,1,0,.474Z"
          transform="translate(-255.237 -410.027)"
          fill="#e37a06"
        />
        <path
          d="M303.75,432.2v.316a.39.39,0,0,1-.321.315c-.111,0-.165-.049-.16-.158V432.2Z"
          transform="translate(-258.165 -408.747)"
          fill="#0c62b9"
        />
        <path
          d="M300.839,434.585v-.474h.453c.11,0,.2.021.189.158C301.391,434.618,301.113,434.6,300.839,434.585Z"
          transform="translate(-256.538 -410.027)"
          fill="#296fb7"
        />
        <path
          d="M264.038,422.48c-.232.006-.261.328-.539.314-.274-.221,0-.422.058-.63C263.678,422.327,263.988,422.212,264.038,422.48Z"
          transform="translate(-231.453 -402.026)"
          fill="#4f8542"
        />
        <path
          d="M281.28,433.929v-.26a.807.807,0,0,1,.8.1c.01.137-.08.168-.189.156a.876.876,0,0,0-.614.16c-.055-.035-.074-.07-.055-.1S281.261,433.929,281.28,433.929Z"
          transform="translate(-243.399 -409.687)"
          fill="#0e64b0"
        />
        <path
          d="M302.778,395.875H302.3V395.4h.321C302.927,395.475,302.717,395.719,302.778,395.875Z"
          transform="translate(-257.515 -384.105)"
          fill="#cc7a17"
        />
        <path
          d="M280.1,433.974v.158h-.642v-.419C279.823,433.532,279.857,433.981,280.1,433.974Z"
          transform="translate(-242.224 -409.731)"
          fill="#a6590b"
        />
        <path
          d="M295.491,402.092h.412a.393.393,0,0,1-.251.631c-.02-.1.054-.214-.053-.286C295.463,402.346,295.491,402.217,295.491,402.092Z"
          transform="translate(-252.955 -388.585)"
          fill="#1c62ae"
        />
        <path
          d="M283.412,426.145a.834.834,0,0,0-.16.632h-.161a.457.457,0,0,1,0-.632q-.007-.164.161-.158T283.412,426.145Z"
          transform="translate(-244.568 -404.586)"
          fill="#508e45"
        />
        <path
          d="M296.3,406.551v.473h-.321v-.631Z"
          transform="translate(-253.285 -391.466)"
          fill="#c6711a"
        />
        <path
          d="M301.811,418.01v-.316a.39.39,0,0,1,.482,0l-.218.453C301.948,418.176,301.821,418.207,301.811,418.01Z"
          transform="translate(-257.189 -398.977)"
          fill="#b66e27"
        />
        <path
          d="M302.293,416.65h-.482v-.6c.3.053.045.3.158.358.119.125.228.066.324.083Z"
          transform="translate(-257.189 -397.934)"
          fill="#201f1e"
        />
        <path
          d="M305.212,428.377h.321v.473h-.321Z"
          transform="translate(-259.467 -406.187)"
          fill="#0f63bc"
        />
        <path
          d="M304.869,407.182h-.16c-.116-.089-.253-.164-.248-.339a.313.313,0,0,1,.1-.189.263.263,0,0,1,.306.055Z"
          transform="translate(-258.964 -391.623)"
          fill="#4c933b"
        />
        <path
          d="M250.306,420.252h.321v.474A.389.389,0,0,1,250.306,420.252Z"
          transform="translate(-222.696 -400.746)"
          fill="#2864a1"
        />
        <path
          d="M304.92,406.231H304.6a.23.23,0,0,1,0-.316l.321.158Z"
          transform="translate(-259.014 -391.145)"
          fill="#44401c"
        />
        <path
          d="M266.346,433.628c.122.024.307-.078.321.158C266.545,433.763,266.36,433.865,266.346,433.628Z"
          transform="translate(-233.44 -409.702)"
          fill="#488e40"
        />
        <path
          d="M248.856,417.863c.111,0,.165.049.161.158C248.905,418.024,248.852,417.972,248.856,417.863Z"
          transform="translate(-221.728 -399.146)"
          fill="#a96115"
        />
        <path
          d="M284.807,433.79c0-.109.05-.162.161-.158C284.971,433.742,284.918,433.795,284.807,433.79Z"
          transform="translate(-245.802 -409.706)"
          fill="#0e64b0"
        />
        <path
          d="M252.251,417.052v-.232c-.113-.188-.363.017-.48-.16-.036-.346.295-.342.474-.483-.113-.155-.3-.009-.476-.141v-.564h.553c.227.086-.087.444.249.474a1.22,1.22,0,0,1,.482,0,.389.389,0,0,1,.324.372c.012.182.339.005.317.25.064.27-.375.063-.321.325.058.134-.025.2-.127.258a.559.559,0,0,1-.331.022.587.587,0,0,1-.184-.122Z"
          transform="translate(-223.679 -397.546)"
          fill="#49923a"
        />
        <path
          d="M258.892,421.037c-.194.081-.365.273-.642.088v-.562a.569.569,0,0,1,0-.79h.481a.919.919,0,0,1,.168.561A4.735,4.735,0,0,0,258.892,421.037Z"
          transform="translate(-227.912 -400.426)"
          fill="#3c7931"
        />
        <path
          d="M255.951,419.774v.79h-.161a.349.349,0,0,1-.481,0c-.076,0-.208.023-.22,0a.759.759,0,0,0-.418-.412c-.028-.012,0-.141,0-.216h.161a.209.209,0,0,0,.321,0c.111,0,.164-.049.161-.158Z"
          transform="translate(-225.613 -400.426)"
          fill="#0265c7"
        />
        <path
          d="M289,384.721h-.718c-.136-.094-.075-.234-.083-.356s0-.254,0-.434h.8a.454.454,0,0,1,0,.632Z"
          transform="translate(-248.069 -376.424)"
          fill="#0260ba"
        />
        <path
          d="M293.383,388.232v.79h-.8v-.79Z"
          transform="translate(-251.008 -379.304)"
          fill="#0055ab"
        />
        <path
          d="M290.637,384.563v-.632h.564c.181.177,0,.389.1.569A1.8,1.8,0,0,1,290.637,384.563Z"
          transform="translate(-249.707 -376.424)"
          fill="#4c983d"
        />
        <ellipse
          cx="0.454"
          cy="0.447"
          rx="0.454"
          ry="0.447"
          transform="translate(40.797 8.166)"
          fill="#499037"
        />
        <path
          d="M279.786,382.168h-.644c-.04-.493.03-.628.377-.611C279.962,381.579,279.6,381.933,279.786,382.168Z"
          transform="translate(-242.003 -374.833)"
          fill="#00509f"
        />
        <path
          d="M249.984,411.631h-.642v-.4c.184-.13.389-.064.585-.054C250.031,411.323,249.964,411.482,249.984,411.631Z"
          transform="translate(-222.054 -394.651)"
          fill="#4a9135"
        />
        <path
          d="M254.682,415.93c-.162.216-.32.216-.482,0v-.392c.125-.15.281-.072.429-.057C254.729,415.622,254.662,415.781,254.682,415.93Z"
          transform="translate(-225.307 -397.529)"
          fill="#005ab8"
        />
        <path
          d="M284.3,384.577h-.44c-.117-.2-.018-.4-.068-.6h.44C284.353,384.177,284.254,384.37,284.3,384.577Z"
          transform="translate(-245.126 -376.451)"
          fill="#305f24"
        />
        <path
          d="M283.836,380.013V379.7c.122-.153.279-.07.425-.06.112.11.028.247.058.373A.362.362,0,0,1,283.836,380.013Z"
          transform="translate(-245.152 -373.527)"
          fill="#3f7e30"
        />
        <path
          d="M254.152,411.211h.432c.12.122.038.259.066.379-.123.168-.287.021-.4.089C254.114,411.537,254.2,411.4,254.152,411.211Z"
          transform="translate(-225.275 -394.692)"
          fill="#703900"
        />
        <path
          d="M286.716,386.277v.425c-.126.115-.264.037-.387.063-.165-.123-.021-.283-.088-.391C286.389,386.24,286.527,386.318,286.716,386.277Z"
          transform="translate(-246.763 -377.995)"
          fill="#b25c00"
        />
        <path
          d="M293.066,386.321h.378c.155.12.071.274.061.418-.111.11-.251.027-.374.056C292.948,386.638,293.094,386.479,293.066,386.321Z"
          transform="translate(-251.314 -378.024)"
          fill="#3f7c2e"
        />
        <path
          d="M252.223,397.747v.425c-.126.115-.264.037-.387.063-.165-.123-.021-.283-.088-.391C251.9,397.71,252.034,397.788,252.223,397.747Z"
          transform="translate(-223.665 -385.676)"
          fill="#3e7c2f"
        />
        <path
          d="M252.244,408.668v.45a.435.435,0,0,1-.463,0C251.746,408.835,251.933,408.747,252.244,408.668Z"
          transform="translate(-223.684 -392.989)"
          fill="#b75e00"
        />
        <ellipse
          cx="0.18"
          cy="0.269"
          rx="0.18"
          ry="0.269"
          transform="translate(26.627 13.474)"
          fill="#914900"
        />
        <ellipse
          cx="0.273"
          cy="0.177"
          rx="0.273"
          ry="0.177"
          transform="translate(28.861 17.277)"
          fill="#894500"
        />
        <path
          d="M286.326,384.725c-.094-.263-.01-.341.195-.322a.19.19,0,0,1,.057.256C286.519,384.752,286.433,384.709,286.326,384.725Z"
          transform="translate(-246.794 -376.738)"
          fill="#3c782e"
        />
        <path
          d="M282.21,384.469c.029.221-.071.278-.226.243-.129-.072-.083-.158-.095-.23A.2.2,0,0,1,282.21,384.469Z"
          transform="translate(-243.849 -376.735)"
          fill="#3d792e"
        />
        <path
          d="M249.82,398.018c-.028-.213.073-.27.227-.235.129.072.083.158.095.23A.192.192,0,0,1,249.82,398.018Z"
          transform="translate(-222.37 -385.693)"
          fill="#3d792f"
        />
        <path
          d="M297.311,398.719v-.632c-.056-.035-.075-.07-.056-.105s.037-.053.056-.053v-.56c.185-.129.389-.065.616-.051V399.3c-.152.167-.369-.016-.554.074C297.257,399.13,297.339,398.92,297.311,398.719Z"
          transform="translate(-254.134 -385.37)"
          fill="#024e9c"
        />
        <path
          d="M302.029,404.12v.316a.982.982,0,0,1-.957.138.539.539,0,0,1-.3-.3.9.9,0,0,1,.137-.947h.321a.349.349,0,0,1,.481,0h.321Z"
          transform="translate(-256.444 -389.35)"
          fill="#0662bb"
        />
        <path
          d="M298.723,403.331v.948a.346.346,0,0,1-.481,0c.01-.14-.083-.158-.192-.158h-.611v-.394c.166-.177.4-.017.588-.1.055-.1.222-.133.214-.291A.351.351,0,0,1,298.723,403.331Z"
          transform="translate(-254.261 -389.351)"
          fill="#488a35"
        />
        <path
          d="M293.548,393.467c-.162.216-.32.216-.482,0v-.392c.125-.15.281-.072.429-.057C293.595,393.16,293.528,393.319,293.548,393.467Z"
          transform="translate(-251.333 -382.487)"
          fill="#0866b2"
        />
        <path
          d="M295.812,398.263v.158h-.8c0-.149,0-.3,0-.446,0-.108.021-.2.161-.185a1.627,1.627,0,0,0,.482.316Q295.645,398.269,295.812,398.263Z"
          transform="translate(-252.634 -385.704)"
          fill="#633905"
        />
        <path
          d="M295.495,391.483v-.318c.123-.153.279-.07.425-.06.112.11.028.247.058.372A.363.363,0,0,1,295.495,391.483Z"
          transform="translate(-252.96 -381.207)"
          fill="#bf6200"
        />
        <path
          d="M302.778,402.547H302.3v-.392c.125-.15.28-.072.429-.056C302.826,402.24,302.759,402.4,302.778,402.547Z"
          transform="translate(-257.515 -388.568)"
          fill="#c56605"
        />
        <path
          d="M300.349,402.547h-.481v-.392c.125-.15.28-.072.429-.056C300.4,402.24,300.33,402.4,300.349,402.547Z"
          transform="translate(-255.888 -388.568)"
          fill="#b55c00"
        />
        <path
          d="M295.958,398.106c-.124-.019-.155.1-.235.149a.437.437,0,0,1-.247-.465h.482Z"
          transform="translate(-252.941 -385.705)"
          fill="#dc7001"
        />
        <path
          d="M298.142,395.375l.232.229c-.094.139-.178.289-.394.246-.126-.127-.068-.273-.039-.437Z"
          transform="translate(-254.572 -384.087)"
          fill="#cf6b00"
        />
        <path
          d="M298.245,391.1c.22.159.22.315,0,.474-.122-.024-.307.078-.321-.158Q298.087,391.26,298.245,391.1Z"
          transform="translate(-254.586 -381.225)"
          fill="#477e37"
        />
        <path
          d="M298.245,391.1v.316h-.321V391.1Z"
          transform="translate(-254.586 -381.225)"
          fill="#f57d00"
        />
        <path
          d="M283.508,410.02v.71c.117.121.225.062.321.08.121-.149.154-.355.321-.474a.987.987,0,0,1,.8-.138v.611c-.608.315-.272,1.035-.645,1.451a.376.376,0,0,1-.479-.03v-.158c.1-.209,0-.35-.161-.474q.006-.164-.161-.158c-.157-.161-.318-.318-.481-.473a.569.569,0,0,1,0-.79v-.158A.348.348,0,0,1,283.508,410.02Z"
          transform="translate(-244.504 -393.83)"
          fill="#4c7b31"
        />
        <path
          d="M285.451,416.9h.481l.091.053c.163.24.569.341.449.746a2.9,2.9,0,0,1-.539.622h-.16c-.041-.035-.055-.07-.041-.105s.027-.053.041-.053c-.038-.294-.293-.091-.482-.218,0-.28,0-.6,0-.927A.121.121,0,0,1,285.451,416.9Z"
          transform="translate(-246.125 -398.5)"
          fill="#023b72"
        />
        <path
          d="M279.609,409.918h-.248c-.031-.248-.23-.383-.389-.547a1.1,1.1,0,0,1,.049-.543h.7c.1.2.017.415.045.616C279.876,409.655,279.774,409.8,279.609,409.918Z"
          transform="translate(-241.889 -393.096)"
          fill="#49923a"
        />
        <path
          d="M280.886,411.168c-.079-.2.137-.306.16-.474h.642v.79h-.8Z"
          transform="translate(-243.165 -394.346)"
          fill="#0461b8"
        />
        <path
          d="M277.52,411.615v-.387c.111-.177.281-.025.391-.1.138.161.1.3.042.489Z"
          transform="translate(-240.923 -394.635)"
          fill="#824e06"
        />
        <path
          d="M283.843,415.438h-.432c-.116-.124-.037-.26-.065-.382.128-.141.282-.072.429-.053C283.88,415.116,283.8,415.252,283.843,415.438Z"
          transform="translate(-244.823 -397.209)"
          fill="#004b9e"
        />
        <path
          d="M285.265,415h.4c.175.119.021.283.09.384-.114.138-.229.07-.326.09C285.117,415.395,285.327,415.15,285.265,415Z"
          transform="translate(-246.1 -397.226)"
          fill="#df7602"
        />
        <ellipse
          cx="0.34"
          cy="0.335"
          rx="0.34"
          ry="0.335"
          transform="translate(38.423 17.04)"
          fill="#bc5d02"
        />
        <path
          d="M279.827,413.151v.434h-.4c-.143-.124.005-.274-.106-.434Z"
          transform="translate(-242.13 -395.991)"
          fill="#499238"
        />
        <path
          d="M283.843,417.35h-.432c-.116-.124-.037-.26-.065-.382.128-.141.282-.072.429-.053C283.88,417.028,283.8,417.164,283.843,417.35Z"
          transform="translate(-244.823 -398.49)"
          fill="#438431"
        />
        <path
          d="M281.888,417.357h-.482v-.316c.162.026.2-.246.406-.148C281.975,417.012,281.851,417.2,281.888,417.357Z"
          transform="translate(-243.525 -398.482)"
          fill="#04386a"
        />
        <path
          d="M285.775,420.7v.474h-.482v-.316C285.464,420.831,285.57,420.619,285.775,420.7Z"
          transform="translate(-246.128 -401.032)"
          fill="#ba5d03"
        />
        <path
          d="M281.808,414.949a.684.684,0,0,1,0,.486c-.3.025-.364-.175-.459-.306C281.448,414.852,281.641,415.015,281.808,414.949Z"
          transform="translate(-243.488 -397.195)"
          fill="#9c5100"
        />
        <path
          d="M283.35,419.1v-.158c.165.024.206-.257.412-.151a.349.349,0,0,1,0,.46C283.556,419.356,283.515,419.076,283.35,419.1Z"
          transform="translate(-244.827 -399.751)"
          fill="#9c4e01"
        />
        <path
          d="M283.827,409.576h-.481c.024-.12-.08-.3.16-.316h.321Z"
          transform="translate(-244.822 -393.385)"
          fill="#f47e00"
        />
        <path
          d="M260.873,423.93c.349.193.044.5.235.79h-.877a.378.378,0,0,1-.129-.2.458.458,0,0,1,.129-.436l.321-.158A.216.216,0,0,1,260.873,423.93Z"
          transform="translate(-229.252 -403.161)"
          fill="#0254a8"
        />
        <path
          d="M269.781,426.465h.642v.789h-.161a.459.459,0,0,1-.642,0v-.473c-.056-.035-.074-.07-.056-.105s.037-.052.056-.052Q269.787,426.629,269.781,426.465Z"
          transform="translate(-235.591 -404.907)"
          fill="#4b963b"
        />
        <path
          d="M259.215,425.882v.158c0,.2,0,.4,0,.6,0,.108-.018.2-.16.189h-.481v-.789A.545.545,0,0,1,259.215,425.882Z"
          transform="translate(-228.235 -404.481)"
          fill="#437a2d"
        />
        <path
          d="M269.163,428.854h.642v.632H269a.455.455,0,0,1,0-.632Z"
          transform="translate(-235.134 -406.506)"
          fill="#2a493b"
        />
        <path
          d="M262.927,427.308c.183-.078.149-.228.2-.339.114-.087.246-.02.378-.037a.86.86,0,0,1,.046.574C263.3,427.639,263.109,427.553,262.927,427.308Z"
          transform="translate(-231.151 -405.214)"
          fill="#488e36"
        />
        <path
          d="M268.277,428.852v.632h-.4c-.019-.018-.053-.034-.055-.053-.014-.174-.023-.349-.033-.507C267.964,428.805,268.126,428.871,268.277,428.852Z"
          transform="translate(-234.409 -406.504)"
          fill="#4e9b3b"
        />
        <path
          d="M270.714,431.244v.632h-.481v-.632Z"
          transform="translate(-236.043 -408.107)"
          fill="#0056b2"
        />
        <path
          d="M266.058,429.335v.4c-.254.153-.435.043-.6-.15C265.55,429.339,265.747,429.29,266.058,429.335Z"
          transform="translate(-232.845 -406.819)"
          fill="#994e00"
        />
        <path
          d="M263.5,429.818v-.434h.4c.113.126.069.269.022.434Z"
          transform="translate(-231.535 -406.861)"
          fill="#004f9f"
        />
        <path
          d="M261,426.943h.376c.176.118.022.281.094.387-.143.141-.282.059-.406.087C260.884,427.26,261.029,427.1,261,426.943Z"
          transform="translate(-229.842 -405.227)"
          fill="#c56300"
        />
        <path
          d="M266.3,427.011v.434h-.4c-.113-.126-.069-.269-.022-.434Z"
          transform="translate(-233.099 -405.272)"
          fill="#1062b4"
        />
        <path
          d="M266.163,424.545c.023.168.239.273.161.474h-.4c-.153-.122-.073-.276-.059-.42C265.951,424.5,266.065,424.564,266.163,424.545Z"
          transform="translate(-233.097 -403.618)"
          fill="#df7502"
        />
        <path
          d="M268.195,432.18c-.282.021-.384-.146-.425-.382.146-.145.3-.086.442-.061A.406.406,0,0,1,268.195,432.18Z"
          transform="translate(-234.394 -408.415)"
          fill="#004489"
        />
        <path
          d="M261.443,423.095h-.321c.051-.172-.317-.184-.124-.411.1-.121.247-.059.4-.034C261.488,422.79,261.424,422.947,261.443,423.095Z"
          transform="translate(-229.821 -402.326)"
          fill="#b05900"
        />
        <path
          d="M268.747,426.943v.158c-.2.02-.242.232-.418.3-.146-.117-.028-.3-.064-.454Z"
          transform="translate(-234.718 -405.227)"
          fill="#2a493b"
        />
        <path
          d="M296.035,427.743v.43a5.747,5.747,0,0,1-1.029.048c-.226-.1.08-.446-.255-.478a.454.454,0,0,1,0-.631c.172-.171.13-.418.219-.624h.373a2.794,2.794,0,0,1,.2,1.18C295.72,427.792,295.883,427.723,296.035,427.743Z"
          transform="translate(-252.376 -404.922)"
          fill="#0461bb"
        />
        <path
          d="M302.122,425.179v.474h-1.1c.069-.367-.219-.674-.183-1.043.194-.112.381-.041.559-.062.184.167.014.4.126.632Z"
          transform="translate(-256.537 -403.62)"
          fill="#0362be"
        />
        <path
          d="M294.1,422.708v.158a.869.869,0,0,1-.642-.158.521.521,0,0,1-.6.082.4.4,0,0,1-.145-.459.359.359,0,0,1,.163-.194,1.5,1.5,0,0,1,.644-.1.956.956,0,0,1,.627.185A.392.392,0,0,1,294.1,422.708Z"
          transform="translate(-251.087 -401.939)"
          fill="#03509d"
        />
        <path
          d="M292.742,424.075h.642v.79h-.642a.419.419,0,0,1-.129-.176.794.794,0,0,1,0-.438A.4.4,0,0,1,292.742,424.075Z"
          transform="translate(-251.009 -403.306)"
          fill="#f37e04"
        />
        <path
          d="M299.538,428.957h-.16a.217.217,0,0,1-.321,0H298.9V428.4c.18-.178.4,0,.578-.1C299.591,428.546,299.51,428.757,299.538,428.957Z"
          transform="translate(-255.237 -406.136)"
          fill="#065cb1"
        />
        <path
          d="M293.708,428.377v.631h-.642v-.631Z"
          transform="translate(-251.333 -406.187)"
          fill="#cf7010"
        />
        <path
          d="M291.763,425.733v.79h-.453c-.109,0-.2-.021-.189-.158v-.632A.474.474,0,0,1,291.763,425.733Z"
          transform="translate(-250.03 -404.332)"
          fill="#352c16"
        />
        <path
          d="M298.774,422.164h.642v.612h-.434c-.175-.083.125-.422-.208-.454l-.06-.079Z"
          transform="translate(-255.115 -402.026)"
          fill="#d06800"
        />
        <path
          d="M296.951,424.619h.621v.4a.985.985,0,0,1-.571.027C296.9,424.9,296.966,424.744,296.951,424.619Z"
          transform="translate(-253.931 -403.67)"
          fill="#0560bd"
        />
        <path
          d="M296.953,422.94v-.473a.64.64,0,0,1,.642,0v.473Z"
          transform="translate(-253.936 -402.172)"
          fill="#eb7b09"
        />
        <path
          d="M303.425,428.377v.473h-.642v-.473Z"
          transform="translate(-257.84 -406.187)"
          fill="#dd7d08"
        />
        <path
          d="M299.581,426.582v.368c-.206.117-.4.017-.6.063C298.978,426.536,298.978,426.536,299.581,426.582Z"
          transform="translate(-255.295 -404.972)"
          fill="#f98101"
        />
        <path
          d="M301.8,430.288v.392c-.125.15-.281.072-.429.057-.1-.141-.032-.3-.052-.449Z"
          transform="translate(-256.856 -407.467)"
          fill="#0264c8"
        />
        <path
          d="M299.868,408.821v-.947a.933.933,0,0,1,.652-.212.991.991,0,0,1,.792.212V409.1c0,.107-.012.206-.161.2.011.14-.082.158-.192.158h-.611A1.314,1.314,0,0,0,299.868,408.821Z"
          transform="translate(-255.888 -392.315)"
          fill="#528631"
        />
        <path
          d="M302.412,407.344h-.8a.332.332,0,0,1,0-.473h.963c-.079.2.137.306.161.473Z"
          transform="translate(-256.988 -391.786)"
          fill="#44401c"
        />
        <path
          d="M299.868,411.172h.574c.181.272-.008.442-.093.632h-.481Z"
          transform="translate(-255.888 -394.666)"
          fill="#db6e02"
        />
        <path
          d="M279.1,428.854h.482v.632h-.608C278.958,429.264,278.816,429.035,279.1,428.854Z"
          transform="translate(-241.864 -406.506)"
          fill="#e07803"
        />
        <path
          d="M279.586,431.312v.592h-.568a.984.984,0,0,1-.022-.592Z"
          transform="translate(-241.888 -408.152)"
          fill="#005cbb"
        />
        <path
          d="M281.4,431.244h.642v.55c-.179.188-.394.015-.581.1C281.349,431.655,281.429,431.445,281.4,431.244Z"
          transform="translate(-243.521 -408.107)"
          fill="#c76a03"
        />
        <path
          d="M277.034,427.417v-.474h.4c.152.123.073.276.058.422C277.347,427.463,277.185,427.4,277.034,427.417Z"
          transform="translate(-240.598 -405.227)"
          fill="#cc7508"
        />
        <path
          d="M277.1,429.4h.441v.389c-.128.111-.274.067-.441.022Z"
          transform="translate(-240.644 -406.872)"
          fill="#014c98"
        />
        <path
          d="M256.63,422.164h.481v.631h-.481v-.631Z"
          transform="translate(-226.934 -402.026)"
          fill="#3c7931"
        />
        <path
          d="M252.135,420.32v.434h-.4c-.144-.124,0-.275-.106-.434Z"
          transform="translate(-223.586 -400.792)"
          fill="#005dba"
        />
        <path
          d="M300.51,406.551v.473h-.642v-.631h.481Q300.343,406.557,300.51,406.551Z"
          transform="translate(-255.888 -391.466)"
          fill="#d47008"
        />
        <path
          d="M282.088,426.465v.789h-.623v-.586c.082-.173.429.125.462-.2Z"
          transform="translate(-243.565 -404.907)"
          fill="#0263c3"
        />
        <path
          d="M283.836,428.377h.642A.4.4,0,0,1,283.836,428.377Z"
          transform="translate(-245.152 -406.187)"
          fill="#508e45"
        />
        <path
          d="M259.215,425.027a.869.869,0,0,1-.642.158v-.632h.642Z"
          transform="translate(-228.235 -403.626)"
          fill="#d36d05"
        />
        <path
          d="M272.662,429.471v-.552c.247-.246.314.113.481.079a.382.382,0,0,1,0,.474Z"
          transform="translate(-237.67 -406.491)"
          fill="#0159b7"
        />
        <path
          d="M274.119,429.806v-.474c.309.074.1.318.161.474Z"
          transform="translate(-238.645 -406.826)"
          fill="#152118"
        />
        <path
          d="M297.595,432.831c-.618-.02-.621-.023-.642-.631h.642Z"
          transform="translate(-253.936 -408.747)"
          fill="#cd7208"
        />
        <path
          d="M303.732,430.28v.474h-.4c-.152-.123-.073-.276-.058-.422C303.419,430.234,303.581,430.3,303.732,430.28Z"
          transform="translate(-258.147 -407.459)"
          fill="#e77400"
        />
        <path
          d="M299.845,432.192v.473h-.4c-.152-.123-.073-.276-.058-.422C299.533,432.146,299.694,432.212,299.845,432.192Z"
          transform="translate(-255.545 -408.739)"
          fill="#0160c0"
        />
        <path
          d="M299.375,430.288h.321c-.051.172.317.184.124.411-.1.121-.247.059-.4.034C299.33,430.593,299.394,430.436,299.375,430.288Z"
          transform="translate(-255.555 -407.467)"
          fill="#a35305"
        />
        <path
          d="M272.965,427.408h-.239c-.15-.123-.073-.276-.058-.421.085-.1.2-.034.3-.053Z"
          transform="translate(-237.652 -405.218)"
          fill="#0163c8"
        />
        <path
          d="M287.236,414.027v-1.421h.642v.158a4.283,4.283,0,0,0,.781,1.322.626.626,0,0,1-.04.353.362.362,0,0,1-.173.163C287.855,414.793,287.646,414.206,287.236,414.027Z"
          transform="translate(-247.429 -395.626)"
          fill="#1c588a"
        />
        <path
          d="M289.822,414.185v-.241c-.17-.173-.406,0-.642-.131v-1.049a.871.871,0,0,0,.642-.158v.986a.487.487,0,0,0,.341.279l.3-.161v.158a.76.76,0,0,1,.12.685.4.4,0,0,1-.155.186.6.6,0,0,1-.593-.026.146.146,0,0,1-.014-.213A.2.2,0,0,0,289.822,414.185Z"
          transform="translate(-248.731 -395.626)"
          fill="#4d943f"
        />
        <path
          d="M285.751,411.15v.474h-.321c.027-.16-.25-.2-.151-.4C285.4,411.064,285.591,411.186,285.751,411.15Z"
          transform="translate(-246.104 -394.644)"
          fill="#b45a01"
        />
        <path
          d="M290.138,422.472v.158a.474.474,0,0,1-.642,0q.006-.164-.161-.158a.187.187,0,0,0-.321,0h-.321V422a.369.369,0,0,1,.218-.265,1.037,1.037,0,0,1,.592-.017,1.171,1.171,0,0,1,.634.283Z"
          transform="translate(-248.405 -401.703)"
          fill="#354c1d"
        />
        <ellipse
          cx="0.454"
          cy="0.335"
          rx="0.454"
          ry="0.335"
          transform="translate(40.958 20.829)"
          fill="#135e96"
        />
        <path
          d="M289.665,423.142v-.46h.261c.107.153.04.312.06.46Z"
          transform="translate(-249.056 -402.373)"
          fill="#dd7705"
        />
        <path
          d="M287.859,420.243q.006.164-.161.158h-.482v-.474c-.06-.237.186-.375.2-.632h.282A.68.68,0,0,1,287.859,420.243Z"
          transform="translate(-247.41 -400.106)"
          fill="#0263c3"
        />
        <path
          d="M295.94,419.761h-1.284a.642.642,0,0,1-.169-.547,6.182,6.182,0,0,1,.169-.874h.642V419.6h.642Z"
          transform="translate(-252.281 -399.466)"
          fill="#4f843d"
        />
        <path
          d="M292.115,417.38a.581.581,0,0,1,0,.79.947.947,0,0,1-.141.119,2.728,2.728,0,0,0-.241.13,1.784,1.784,0,0,1-.26.383h-.453c-.109,0-.2-.021-.189-.158a.513.513,0,0,1-.1-.573c.167-.283.562-.261.74-.533.181-.179-.014-.451.16-.631h.482Z"
          transform="translate(-249.74 -398.506)"
          fill="#0660b7"
        />
        <path
          d="M293.227,416.586c0,.149,0,.3,0,.446,0,.108-.022.2-.161.186v-.789Q293.232,416.423,293.227,416.586Z"
          transform="translate(-251.333 -398.186)"
          fill="#499442"
        />
        <path
          d="M255.487,420.252c.012.148-.092.167-.2.155a.123.123,0,0,1-.122-.155Z"
          transform="translate(-225.951 -400.746)"
          fill="#081417"
        />
        <path
          d="M288.512,418.012v.632h-.642c.082-.338-.243-.61-.161-.948v-.234c-.109-.164-.3-.023-.432-.111-.1-.137-.029-.3-.049-.444h.557c.169.106.026.293.124.44.185.085.4.006.6.033v.158A.337.337,0,0,1,288.512,418.012Z"
          transform="translate(-247.421 -398.506)"
          fill="#499442"
        />
        <path
          d="M293.548,420.729h.161v.632h-.642c0-.149,0-.3,0-.446,0-.108.021-.2.161-.186A.187.187,0,0,0,293.548,420.729Z"
          transform="translate(-251.333 -401.065)"
          fill="#302d23"
        />
        <path
          d="M291.559,427.009c.278-.1.357-.013.337.188a.213.213,0,0,1-.293.04Z"
          transform="translate(-250.324 -405.244)"
          fill="#dd6d00"
        />
        <path
          d="M302.79,411.607h-.441v-.389c.128-.111.274-.067.441-.022Z"
          transform="translate(-257.549 -394.65)"
          fill="#f17900"
        />
        <path
          d="M291.123,417.383c.151-.012.169.09.157.2a.124.124,0,0,1-.157.12Z"
          transform="translate(-250.032 -398.824)"
          fill="#499442"
        />
        <path
          d="M291.123,419.292v-.474h.642v.474Z"
          transform="translate(-250.032 -399.786)"
          fill="#ba7314"
        />
        <path
          d="M293.868,420.73v.46h-.261c-.107-.153-.04-.311-.06-.46Z"
          transform="translate(-251.653 -401.066)"
          fill="#c2660e"
        />
        <path
          d="M310.639,390.14a13.675,13.675,0,0,1-1.426,13.509c-.6-.59-1.214-1.155-1.764-1.778-.1-.115.008-.488.108-.7a31.793,31.793,0,0,0,2.932-9.326C310.564,391.279,310.59,390.708,310.639,390.14Z"
          transform="translate(-260.937 -380.582)"
          fill="#4f9d3c"
        />
        <path
          d="M289.139,440.729a12.21,12.21,0,0,1-5.768,2.129c.495-.373,1.011-.72,1.479-1.124.758-.654,1.521-1.309,2.21-2.032.449-.471.748-.443,1.13.025C288.454,440.048,288.761,440.333,289.139,440.729Z"
          transform="translate(-244.841 -413.543)"
          fill="#4f9d3c"
        />
      </svg>
    </div>
  );
};

export default Logo;
