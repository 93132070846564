import { takeLeading, put } from "redux-saga/effects";
import {
  pendingCreateCosigner,
  resolvedCreateCosigner,
  rejectedCreateCosigner,
  pendingEditCosigner,
  resolvedEditCosigner,
  rejectedEditCosigner,
} from "store/cosigners/cosigners.action";
import { createCosigner, editCosigner } from "store/cosigners/cosigners.api";
import {
  HANDLE_CREATE_COSIGNER,
  HANDLE_EDIT_COSIGNER,
  HandleCreateCosigner,
  HandleEditCosigner,
} from "store/cosigners/cosigners.types";

function* createCosignerSaga(action: HandleCreateCosigner) {
  const { cosigner, callback } = action.payload;

  yield put(pendingCreateCosigner());

  try {
    cosigner.leadType = "";
    cosigner.leadSource = "";

    const { data, error } = yield createCosigner(cosigner);

    if (error) {
      return;
    }

    yield put(resolvedCreateCosigner(data));
    typeof callback === "function" && callback({ createdCosigner: data });
  } catch (error) {
    yield put(rejectedCreateCosigner(error));
    return;
  }
}

function* editCosignerSaga(action: HandleEditCosigner) {
  const { cosigner, callback, id } = action.payload;

  yield put(pendingEditCosigner());

  try {
    const { data, error } = yield editCosigner(cosigner, id);

    if (error) {
      return;
    }

    yield put(resolvedEditCosigner(data));
    typeof callback === "function" && callback({ createdCosigner: data });
  } catch (error) {
    yield put(rejectedEditCosigner(error));
    return;
  }
}

export default function* CosignersSaga() {
  yield takeLeading(HANDLE_CREATE_COSIGNER, createCosignerSaga);
  yield takeLeading(HANDLE_EDIT_COSIGNER, editCosignerSaga);
}
