import React from "react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    //DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
    // Dropdown
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { cancelIcon } from "constants/styles";
import { styles } from "components/dialogs/employees/styles";
import { Col, Row } from "react-grid-system";
//import { makeStyles, Theme,  createStyles } from '@mui/material/styles';
//import { mergeStyles } from '@fluentui/react/lib/Styling';
/* import masterDropdownValues from "constants/masterDropdownValues";
import { Dropdown as PRDropdown } from 'primereact/dropdown'; */
import { handlePostAllFinanceDeliveryApptDate, handleViewDealNoTracker } from "store/dealTracker/dealTracker.action";
// import { convertLocalToUTC } from "utils/dateTimeFunctions";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";

interface IProps {
    isOpen: boolean;
    selectedScheduleData: any;
    calendarViewName: any;
    locationId: any;
    onDismiss: (isDeal) => void;
}

export const SheduleCreateRequestDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    calendarViewName,
    locationId,
    selectedScheduleData,
    onDismiss,
}) => {
    const loading = useSelector((state: AppState) => state.employees.loading);
    // const [requestReason, setrequestReason] = React.useState(null);
    // const [dealNo, setdealNo] = React.useState(null);
    const [commonDealServerError, setcommonDealServerError] = React.useState(null);
    const [commonMngServerError, setcommonMngServerError] = React.useState(null);
    const dispatch = useDispatch();
    /* const dealTrackerSuccess = useSelector((state: AppState) => state.deals.selectedDealRecord);
    const dealTrackerError = useSelector((state: AppState) => state.deals.error); */

    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };

    
  const formik = useFormik({
    initialValues: {
        reason: "",
        dealNo: ""
    },
    validationSchema: Yup.object({
      reason: Yup.number(),
      dealNo: Yup.string()
    }),
    onSubmit: (values) => {}
  });

    const onAddManagerReq = () => {
        let scheduleData = {
            'date': moment(selectedScheduleData.start).format("YYYY-MM-DD"),
            'startsAt': addZeroIfLess(new Date(selectedScheduleData.start).getHours()) +':'+ addZeroIfLess(new Date(selectedScheduleData.start).getMinutes()) +":00",
            'endsAt': addZeroIfLess(new Date(selectedScheduleData.end).getHours()) +':'+ addZeroIfLess(new Date(selectedScheduleData.end).getMinutes()) +":00",
            'reason': formik.values.reason,
            'assignedToEmployeeId': selectedScheduleData.resourceId
        };
        dispatch<any>(handlePostAllFinanceDeliveryApptDate({scheduleData, callback: (res) =>  {
            if (res && res.reason) {
                setcommonMngServerError(false);
                onDismiss('reloadCalendar');
            } else {
                setcommonMngServerError(true);
            }
        }}));
    }

    const addZeroIfLess = (val) => {
        if (Number(val) < 10) {
            console.log('val LESS', Number(val))
            return '0'+Number(val)
        }
        return val;
    }

    /* const handleReasonChange = (e) => {
        console.log('e', e);
        console.log('e.target.value', e.target.value);
        formik.setValues({
            ...formik.values,
            "reason": e.target.value
        })
        setrequestReason(e.target.value);
    } */

    /* const handleDealNoChange = (e) => {
        console.log('e', e);
        setdealNo(e.target.value);
    } */

    const validateDealNo = () => {
        dispatch<any>(handleViewDealNoTracker(formik.values.dealNo, locationId, (res) => {
            if (res && res.id) {
                setcommonDealServerError(false);
                onDismiss({
                    dealId: res.id
                });
            } else {
                setcommonDealServerError(true);
            }
        }));
    }

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={() => onDismiss(false)}
            isBlocking={true}
            containerClassName={contentStyles.container}
            styles={{ root: { zIndex: 10 } }}
            className="createDealRecord dealRecordHeight updateEmployeeDialogHeight"
        >
            <div className={contentStyles.header+" inventory-popup"}>
                <CustomFontText className="formTitle">Meeting Request</CustomFontText>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => onDismiss(false)}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack>
                   {
                        commonDealServerError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>Deal number not found for this location.</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                    {
                        commonMngServerError ?
                            <div>
                                <Row>
                                    <Col>
                                        <p className="genericErrorColor"><span>Conflict in scheduling.</span></p>
                                    </Col>
                                </Row>
                            </div> : null
                    }
                        <Row>                           
                             <Col md={12}>
                                    <div> Schedule unavailable time slots or go to a Deal Record</div>
                             </Col>
                        </Row>
                                
                        <Row  style={{marginTop:"5px"}}>
                             <Col md={7}>
                                  {/* <Dropdown
                                    id="reasonUnavailable"
                                    label="Reason Unavailable"
                                    placeholder=""
                                    //selectedKey={formik.values.finance1}
                                    options={masterDropdownValues.dealDeliveryTypes}
                                    className="addTeamWidth"
                                   // onChange={handleDropdownValue}
                                    // required={true} 
                                   /> */}
                                    <TextField
                                        id="reason"
                                        name="reason"
                                        label="Reason"
                                        // placeholder=""
                                        value={formik.values.reason}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        /* errorMessage={
                                            formik.touched.locationForm && locationFormik.touched.locationForm.legalName &&
                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.legalName &&
                                            locationFormik.errors.locationForm.legalName.toString()
                                        } */
                                    />
                                   {/* <PRDropdown  
                                       id="deliveryReason"
                                       name="deliveryReason"
                                       value={requestReason}
                                       options={masterDropdownValues.managerReason}
                                       optionLabel="text"
                                       optionValue="key"
                                       onChange={handleDropdownChange}
                                       appendTo="self"
                                       className="custom-p-dropdown dropdown-Width arrowWidth-picker"
                                       filter={false}
                                       resetFilterOnHide={true}
                                   /> */}
                             </Col>
                             <Col md={5} className="scheduleModalButWidth scheduleButTopMob">
                                   <PrimaryButton
                                       disabled={!formik.values.reason}
                                       onRenderIcon={renderSpinner}
                                       text="Add"
                                       style={{marginTop: "3px"}}
                                       onClick={() => onAddManagerReq()}
                                   />
                             </Col>
                        </Row>
                        <Row  justify="center"style={{paddingBottom:"15px"}} >
                             <Col md={12}>
                                   <div  style={{textAlign:"center", marginTop: "20px"}}> - OR - </div> 
                              </Col>

                        </Row>                        
                        <Row  style={{marginTop:"-15px"}}>
                             <Col md={7}>
                                  <TextField
										id="dealNo"
										name="dealNo"
										label="Deal Number"
                                        value={formik.values.dealNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
										//required={true}
										// onChange={handleDealNoChange}
										//onBlur={formik.handleBlur}
										//errorMessage={
                                        //    formik.touched && formik.touched.dealNumber &&
                                        //    formik.errors && formik.errors.dealNumber &&
                                        //    formik.errors.dealNumber.toString()
										//}
									/> 
                             </Col>
                             <Col md={5}  className="scheduleModalButWidth scheduleButTopMob">
                                   <PrimaryButton
                                       disabled={!formik.values.dealNo}
                                       onRenderIcon={renderSpinner}
                                       text="Go to Deal"
                                       style={{marginTop: "3px"}}
                                       onClick={validateDealNo}
                                   />
                             </Col>
                        </Row>

                        
                </Stack>
            </div>
            <div className={contentStyles.footer}>

            </div>
        </Modal>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width:450,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "10px 30px 5px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: "15px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "13px 30px 15px"
    },
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
