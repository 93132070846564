import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { colors } from "constants/constants";
import BasicData from "pages/deskADealPage/basicData/BasicData";

const border = {
  borderTop: `1px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};

interface IProps {
  formik: any;
  calculateAndSetMonthlyPayment: () => void;
}

export const CalculationValuesPivot: React.FC<IProps> = ({
  formik,
  calculateAndSetMonthlyPayment,
}) => {
  return (
    <Pivot
      style={{ padding: 0, marginTop: 10 }}
      className="pivotItem"
      // onLinkClick={(item?: any) => {
      //   if (item.key === ".2") {
      //     setHideButton(true);
      //   } else {
      //     setHideButton(false);
      //   }
      // }}
    >
      <PivotItem
        className="pivotItem"
        style={border}
        headerText="Basic Data"
        headerButtonProps={{
          "data-order": 2,
          "data-title": "Company information",
        }}
      >
        <BasicData formik={formik} calculateAndSetMonthlyPayment={calculateAndSetMonthlyPayment}/>
      </PivotItem>
      {/*<PivotItem className="pivotItem" style={border} headerText="Taxes">*/}
      {/*  <div>2222</div>*/}
      {/*</PivotItem>*/}
      {/*<PivotItem className="pivotItem" style={border} headerText="Rate">*/}
      {/*  <div>3333</div>*/}
      {/*</PivotItem>*/}
      {/*<PivotItem className="pivotItem" style={border} headerText="Terms">*/}
      {/*  <PartnerSettings />*/}
      {/*</PivotItem>*/}
    </Pivot>
  );
};
