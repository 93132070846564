import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import AddCircle from '@mui/icons-material/AddCircle';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    IStackStyles,
    //TextField,
    Link,
    PrimaryButton,
    Stack
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
//import CustomTable from "components/table/datatable/CustomTable";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomFontText } from "components/customFontText/CustomFontText";
//import { handleGetAppointments } from "store/dealTracker/dealTracker.action";
import { handleGetVehicleTrackerList } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//  import {
//       convertLocalToUTCModified
//      // convertUTCToLocalMoment
//  } from "utils/dateTimeFunctions";
import { useNavigate  , useLocation } from "react-router-dom";
import masterDropdownValues from "../../constants/masterDropdownValues";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Paginator } from 'primereact/paginator';
import { DebounceInput } from 'react-debounce-input'
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import  {AddVehicleTrackerDialog}  from  'components/dialogs/dealTrackerDialog/vehicleTrackerDialog/AddVehicleTrackerDialog';
import { MultiSelect } from 'primereact/multiselect';
import {
    saveTrackerStatus,
    getTrackerStatus,
} from "utils/localStorage/vehicleTrackerStatusLocalStorage";
import * as _ from "lodash";
import { themeStyles } from "constants/constants";



const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme.palette.background.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography.pxToRem(15),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography.pxToRem(15),
//             color: theme.palette.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 25,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette.divider}`,
//             padding: theme.spacing(1, 2),
//         },
//         link: {
//             color: theme.palette.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//         breadCrumblink: {
//             display: 'flex',
//         },
//         instructions: {
//             marginTop: theme.spacing(1),
//             marginBottom: theme.spacing(1),
//         },
//         breadCrumbicon: {
//             marginRight: theme.spacing(0.5),
//             width: 20,
//             height: 20,
//         },
//         startTime: {
//         }
//     }),
// );

const credentialModuleName = "DealTracker";
export const VehicleTrackerWorkbookPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    //const [appointmentId, setAppointmentId] = useState(0);
    const [finalData, setFinalData] = useState(null);
    //const [vehicleData, setVehicleData] = useState(null);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([0]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);


    const [search, setGLFilter] = useState(null);
    const [locationFilter, setLocationFilter] = useState(null);
    const [homeLocation, setHomeLocation] = useState(null);
   // const todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
    //const today =new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'});
    //const todaydate=new Date(today);
    const [sentDateFromFilter, setSentDateFromFilter] =React.useState<Date | undefined>(undefined);
    const [sentDateToFilter, setSentDateToFilter] = React.useState<Date | undefined>(undefined);
    // const [primaryLocation, setPrimaryLocation] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('sentDateTime');
    const [statesFlag, setStatesFlag] = useState(false);
    const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState(null);
    const [statusFilter, setStatusFilter] = useState([]);
     const [statePageNumber, setStatePageNumber] = useState(1);
    const [isAddVehicleDialog, setIsAddVehicleDialog] = useState(false);
    const [settingPermission, setSettingPermission] = React.useState(null);
    const [statusList, setStatusList] = React.useState(null);
    const [choiceKey, setChoiceKeyKey] = React.useState('3');

   const storedBlogs = JSON.parse(getTrackerStatus());


   const statusInitialVal =[1,2,3,5,7];

   useEffect(()=>{
       if (_.isEqual(statusFilter ,statusInitialVal ) !== true ){

           let arr =[...statusFilter]
           
           saveTrackerStatus(arr)
       } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[statusFilter])
    
    useEffect(()=>{
      
        let val = storedBlogs ? storedBlogs : statusInitialVal
       setStatusFilter(val)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const transferOptions: IChoiceGroupOption[] = [
      { key:'3', text: 'All Transfers' },
      { key:'2', text: 'Transferred Out' },
      { key:'1', text: 'Transferred In' }
     ];

    const handleSearchChange = (event: any) => {
        const inputValue = event.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }
        if(event.target.value === ''){
			setGLFilter('');
	    }
    };


	const onLocationChange = (e: { value: any }) => {   
        setLocationFilter(e.value);
       
    }

    
    const onStatusChange = (e: { value: any }) => {
        setStatusFilter([...e.value]);

    }


    const handleFromSentDateChange = (val: any) => {
         
        setSentDateFromFilter(val.target.value)

    };

    const handleToSentDateChange = (val: any) => {
        setSentDateToFilter  (val.target.value)

    };


    function dynamicSort(property) {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }

    useEffect(() => {
      
        let list = [];
        if (locations?.results.length > 0) {
            locations.results.map((item: any) => {
                return list.push({ key: item.id, text: (item.legalName) })
            });
            list.sort(dynamicSort("text"));
            setCityDealerList(list);
        } else{
           setCityDealerList(null);
        }
       const stateVal: any = location?.state

       if(!isAddVehicleDialog){
             if(stateVal){
                    const keys = Object.values(stateVal);
                    setLocationFilter(keys[0])
                    setHomeLocation(keys[0])
             }else{
                if (user.primaryLocation ) {
                   // setPrimaryLocation(user.primaryLocation.id); 
                    setLocationFilter(user.primaryLocation.id)
                    setHomeLocation(user.primaryLocation.id)
                } else {
                   
                    setLocationFilter(list ? list[0]?.key : null);
                    setHomeLocation(list ? list[0]?.key : null)
                }
             
             }
       }



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    useEffect(() => {

       const stateVal: any = location?.state
       if(stateVal?.statusFilter){
           setStatusFilter(stateVal?.statusFilter)
       }
       
        if (stateVal?.formData?.search) {
          setGLFilter(stateVal?.formData?.search);
        }
        if (stateVal?.formData?.transferStatus) {
          setChoiceKeyKey(stateVal?.formData?.transferStatus);
        }
        if (stateVal?.formData?.sentDateFromFilter) {
          setSentDateFromFilter(new Date(stateVal?.formData?.sentDateFromFilter));
        }
        if (stateVal?.formData?.sentDateToFilter) {
          setSentDateToFilter (new Date(stateVal?.formData?.sentDateToFilter));
        }
       

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
   
    useEffect(()=> { 

            if (masterDropdownValues.vehicleTrackerStatus) {
                let list = [...masterDropdownValues.vehicleTrackerStatus];
                list.unshift({ key: 0, text: 'ALL' });
                setStatusList(list)
            } 
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterDropdownValues]);

    useEffect(() => {
       
        //setCustomerList(null)
        dispatch({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1 } })

       // setStatusFilter([1,2,3,5]);


    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

      
        if (user && user.roles) {
            setUserProfileLoadingFlag(true)
            setStatesFlag(true)
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    

    const getStatus = (x) => {
    
        if (x.status !== 4) {
            return  masterDropdownValues.vehicleTrackerStatus.find(item => item.key === x.status).text;

        }else {
            if(x?.deal?.dealDelivery?.status){
                let val = masterDropdownValues.dealTrackerStatus.filter(item => item.key === x.deal?.dealDelivery?.status)[0].text;


               return  `Sold - ${val}`
            }else if(x.status === 4){
                return "Sold -"
            }
             
        }
    }


    const inOut =(locVal)=>{
       if(locationFilter !== locVal.vehicleTracker.location.id){
            return "In"
        }else{
             return "Out"
        }
    }

    const vehicleLocation =(locVal)=>{

       if(locationFilter !== locVal.vehicleTracker.location.id){
          
           return locVal.vehicleTracker.location.legalName
       }else{
            if(locVal?.transferToLocation?.legalName){
                return  locVal?.transferToLocation?.legalName
            }else{
                return 'Loaner'
            }
       }
    }   

    const addFiveDays =(locVal)=>{
        if(locVal.sentDateTime){
            let  recent_date = new Date(locVal.sentDateTime);
              recent_date.setDate(recent_date.getDate() + 5);

            let current_date =new Date();
           if(recent_date > current_date){   

                return true
            }else{
                return false
            }
        }
    }
    const handlePopulate = (response) => {



          if(response.length){
              for (var i in response) {
                      


                   response[i]["sentDate_Time"] = moment(response[i].sentDateTime).local().format('MM/DD/YY hh:mm a');
                   response[i]["whole_sale_date"] = response[i].wholeSaleCompleteDate && moment(response[i].wholeSaleCompleteDate).local().format('MM/DD/YY');
                   response[i]["isRecentDate"] =  addFiveDays(response[i])
                   //response[i]["sentDateTime"] = convertUTCToLocalDateOnlyModified(response[i].sentDate);
                   response[i]["locationName"] = vehicleLocation(response[i]);
                   response[i]["statusName"] = response[i].status &&  getStatus(response[i]);
                   response[i]["vehicle"] = (response[i].vehicleTracker.vehicle.year +' '+ response[i].vehicleTracker.vehicle.model) ; 
                   response[i]["stockNumber"] = response[i].vehicleTracker.vehicle.stockNumber;
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["requestedByName"] = response[i].requestedBy ? response[i].requestedBy.firstName + " " + response[i].requestedBy.lastName : '';
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["customerName"] = (response[i].customer && response[i].customer) ;
                   response[i]["inOut"] = inOut(response[i]) ;
                   response[i]["vehicleId"] = response[i].vehicleTracker && response[i].vehicleTracker.id ;
                   response[i]["vehicleTransferID"] = response[i].id;
              }
              	 return response;                
          }
          return []
	      
	};
 

    const vehicleTrackerList =(isFilterApplied)=>{   
  
	        const formData = {
                 page: isFilterApplied ? statePageNumber : pages,
                 limit,
                 sort: sortOrder === 1 ? 'ASC' : 'DESC',
                 column: sortField,
                 locationId:locationFilter?locationFilter:0,
                 status:statusFilter?statusFilter:[],
                // fromSentDate: sentDateFromFilter ? convertLocalToUTCModified(sentDateFromFilter) :'',
                 fromSentDate: sentDateFromFilter ? moment(sentDateFromFilter).format('YYYY-MM-DD') : '',
                 toSentDate: sentDateToFilter ? moment(sentDateToFilter).format('YYYY-MM-DD') : '',
                // toSentDate: sentDateToFilter ? convertLocalToUTCModified(sentDateToFilter) : '', 
                 transferStatus:choiceKey,
                 search: search
            };
            setFilters(formData);
            if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch(handleGetVehicleTrackerList({
                formData,
                callback: (response) => {
               
                    if (response) {
                        setTotalRecords(response.totalItems);
                        const data = handlePopulate(response.results);
                        setFinalData(data);
                    } else {
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}


    


    useEffect(() => {
        setSortOrder(1);

        dispatch(handleGetLocations())
    }, []); // eslint-disable-line react-hooks/exhaustive-deps    
    


    const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );

    

   const handleColumnSort = (e) => {
	 setSortField(e.sortField);
	 setSortOrder(e.sortOrder);
   }

	const getVehicleTracker = (isFilterApplied = false) => {
        setcommonServerError('');
        if (userProfileLoadingFlag) {
             vehicleTrackerList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
             }
        getVehicleTracker(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search,locationFilter,sentDateFromFilter,sentDateToFilter,choiceKey,statusFilter, statesFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getVehicleTracker();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages,sortOrder, sortField]);

	const handleResetWorkbook = () => {   
        setGLFilter("");
        setLocationFilter(homeLocation);
        setSentDateFromFilter(null);
        setSentDateToFilter(null);
        setStatusFilter([1,2,3,5,7])
        setLimit(25);
        setPages(1);
         setChoiceKeyKey('3');
    };

    const exportExcel = () => {
        const formData = { ...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch(handleGetVehicleTrackerList({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
                                   
				                     ({
									 "Sent Date": moment(item.sentDateTime).local().format('MM/DD/YY hh:mm a'),
                                     'Stock Number': item.vehicleTracker.vehicle.stockNumber,
                                     'Vehicle ': item.vehicleTracker.vehicle.year +' '+ item.vehicleTracker.vehicle.model,
                                     'In / Out': inOut(item),
                                     'Location': vehicleLocation(item),
                                     'Status': getStatus(item), 
                                     'Requested By':  item.requestedBy ? item.requestedBy.firstName + ' ' + item.requestedBy.lastName : '',
                                     'Customer': item.customer &&  item.customer,
                                     'Notes': item.lastNote &&  item.lastNote,
				                   }));
				                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'Vehicle-tracker-workbook');
                                   });   
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


    const navigateToTransferVehicle=()=>{
        
        setLocationFilter(locationFilter)
        setIsAddVehicleDialog(true)
        setLocationFilter(locationFilter)

        
    }
   

   const handleChoiceDropdownChange = (e, option) => {
     setChoiceKeyKey(option.key);
     
   }


    //Datatable Templates
	const header = (
		<>  

		{isMobile ?
		<div>   
                <Row>
		            <Col >
			             <Row>
			                 
			             	<Col  >
			        		  <div className="dd-control">
			                      <label className="lbl-text">Location</label>
                                  <Dropdown
                                        id="locationId"
                                        placeholder=""
                                        value={locationFilter}
                                        options={cityDealerList}
                                        onChange={onLocationChange}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="custom-p-dropdown write-ups-location-dd"
                                        filter={ false}
                                        resetFilterOnHide={true}
                                    />
			        			</div>
			             	</Col>
                          { settingPermission === 1 && 
                            <Col  >
			        		  <div style={{marginTop:"25px"}}>
                                 <Link
                                     //disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                     color="inherit"
                                     style={{fontSize:"19px"}}
                                     onClick={navigateToTransferVehicle}>
                                      <AddCircle style={{ ...themeStyles.icon, color: "#70af60", height: "29px" }} />							           &nbsp;Transfer Vehicle 
							     </Link>
                              </div>
			             	</Col>
                          }
                    
			             </Row>
			        </Col>
		           
		        </Row>
                <Row>
		            <Col >
			             <Row>
			                 
			             	<Col  style={{marginTop:"15px",paddingLeft:"unset"}}>
                                      <ChoiceGroup id="collateral" name="collateral"
                                        required={true}
                                        className="guidedRadio"
                                        options={transferOptions}
                                        onChange={handleChoiceDropdownChange}
                                        selectedKey={choiceKey}
                                        label="" />
                                </Col>
                            
                            
                    
			             </Row>
			        </Col>
		            
		        </Row>
                    <Row>
                        <Col>
                              <div className="employeeeSearchLabelText locationDropPosition">
			                  	
								  <label className="lbl-text">Search</label>
                                  <div className="employeeeSearchLabelText ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="employeeeSearchLabelText ms-TextField-field"
                                          value={search}
                                          debounceTimeout={750}
                                          onChange={(e) => handleSearchChange(e)} />
                                  </div>
			                  </div>
			         	</Col>
                        <Col>
                            <div className="locationDropPosition">
                                <label className="lbl-text" style={{ paddingBottom: "0px" }}>Status</label>
                                <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={statusFilter}
                                    options={statusList}
                                    onChange={onStatusChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row  >
                        <Col md={12}>
                            <div className="employeeeSearchLabelText date-filter-section" style={{ width: "100%" }} >
                                <div>
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Sent Date</label>
                                        <div className="employeeeSearchLabelText ms-TextField-fieldGroup mask-FieldGroup ">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ display: "flex" }}
                                                value={sentDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromSentDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                //maxDate={sentDateToFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                <div >
                                    <div className=" employeeeSearchLabelTextms-TextField-wrapper">
                                        <label className="lbl-text">&nbsp;</label>
                                        <div className=" employeeeSearchLabelText ms-TextField-fieldGroup mask-FieldGroup">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ display: "flex" }}
                                                value={sentDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToSentDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                //minDate={sentDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                       
                    </Row>
                   
                    
                    <Row>
                         <Col  style={{ marginTop: "15px"}} >
                            <PrimaryButton style={{ textAlign: "center" }}
                                onClick={handleResetWorkbook}
                            >
                                Reset
                               </PrimaryButton>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className="display-row" style={{ marginTop: "15px" }}>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                            <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
						    </Link>
                        </Col>
                    </Row>
		</div>
        :
		<div>
            <div>
                <Row>
		            <Col md={10}>
			             <Row>
			                 
			             	<Col  md={3}>
			        		  <div className="dd-control">
			                      <label className="lbl-text">Location</label>
                                  <Dropdown
                                        id="locationId"
                                        placeholder=""
                                        value={locationFilter}
                                        options={cityDealerList}
                                        onChange={onLocationChange}
                                        optionLabel="text"
                                        optionValue="key"
                                        appendTo="self"
                                        className="custom-p-dropdown write-ups-location-dd"
                                        filter={ false}
                                        resetFilterOnHide={true}
                                    />
			        			</div>
			             	</Col>

                          { settingPermission === 1 && 
                            <Col  md={3}>
			        		  <div style={{marginTop:"25px"}}>
                                 <Link
                                     //disabled={!(aggregatedDefaultPolicies ? aggregatedDefaultPolicies.some(x => x === dealTrackerOperationPermissions.DEAL_WRITE_UPS_FINANCE_PRODUCT_ADD) : isMaster)}
                                     color="inherit"
                                     style={{fontSize:"19px"}}
                                     onClick={navigateToTransferVehicle}>
                                    <AddCircle style={{ ...themeStyles.icon, color: "#70af60", height: "29px" }} />							           &nbsp;Transfer Vehicle
							     </Link>
                              </div>
			             	</Col>
                           }
                    
			             </Row>
			        </Col>
		            <Col>
			        </Col>
		        </Row>
                <Row>
		            <Col md={10}>
			             <Row>
			                 
			             	<Col  className="mobileDisplayNone" style={{marginTop:"15px",paddingLeft:"unset"}}>
                                      <ChoiceGroup id="collateral" name="collateral"
                                        required={true}
                                        className="guidedRadioField guidedRadio "
                                        options={transferOptions}
                                        onChange={handleChoiceDropdownChange}
                                        selectedKey={choiceKey}
                                        label="" />
                                </Col>
                            
                            
                    
			             </Row>
			        </Col>
		            <Col>
			        </Col>
		        </Row>
		        <Row>
		            <Col md={10}>
			         <Row>
			             <Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={search}
                                          debounceTimeout={750}
                                          onChange={(e) => handleSearchChange(e)} />
                                  </div>
			                  </div>
			         	</Col>
                       
                        <Col  md={3}>
                                    <div className="dd-control cdr-cpo-dd-filter">
                                        <label className="lbl-text">Status</label>
                                        <MultiSelect
                                            value={statusFilter}
                                            options={masterDropdownValues.vehicleTrackerStatus}
                                            style={{ minWidth: "175px" }}
                                            onChange={onStatusChange}
                                            placeholder="Select Status"
                                            optionLabel="text"
                                            optionValue="key"
                                            className="custom-p-dropdown"
                                            maxSelectedLabels={2}
                                        />
                                    </div>
                        </Col>
                        <Col md={4}>
                            <div className="date-filter-section">
			                    <div className="table-header">
						            <div className="ms-TextField-wrapper">
						                 <label className="lbl-text">Sent Date</label>
						                 <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={sentDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromSentDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                //maxDate={logDateToFilter}
						                    />
						                 </div>
						            </div>
                                </div>
                                <label className="lbl-text" style={{margin:"30px 10px 0px"}}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                    <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={sentDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToSentDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                //minDate={logDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
			         	</Col>
                        <Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"24px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>

			         </Row>
				</Col>
		            <Col>
				    </Col>
		        </Row>
            </div>
            <div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords} </label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);





    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                {rowData.isRecentDate ? rowData.sentDate_Time : <span style={{color:"#AF2B01"}}> { rowData.sentDate_Time} </span>}
            </React.Fragment>
        );
    };
    
    const wholesaleCompleteBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                {rowData.isRecentDate ? rowData.whole_sale_date : <span style={{color:"#AF2B01"}}> { rowData.whole_sale_date} </span>}  
            </React.Fragment>
        );
    };
    
    const stockBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
          
                {rowData.isRecentDate ? rowData.stockNumber : <span style={{color:"#AF2B01"}}> { rowData.stockNumber} </span>}
            </React.Fragment>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
  
                {rowData.isRecentDate ? rowData.locationName : <span style={{color:"#AF2B01"}}> { rowData.locationName} </span>}

            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.isRecentDate ? rowData.statusName : <span style={{color:"#AF2B01"}}> { rowData.statusName} </span>}
            </React.Fragment>
        );
    };

    const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
     
                {rowData.isRecentDate ? rowData.vehicle : <span style={{color:"#AF2B01"}}> { rowData.vehicle} </span>}
            </React.Fragment>
        );
    };

    const customerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               

                {rowData.isRecentDate ? rowData.customerName : <span style={{color:"#AF2B01"}}> { rowData.customerName} </span>}
            </React.Fragment>
        );
    };

    const requestedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.isRecentDate ? rowData.requestedByName : <span style={{color:"#AF2B01"}}> { rowData.requestedByName} </span>}
            </React.Fragment>
        );
    };

    const inoutBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                {rowData.isRecentDate ? rowData.inOut : <span style={{color:"#AF2B01"}}> { rowData.inOut} </span>}
            </React.Fragment>
        );
    };

    const commentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {rowData.isRecentDate ? rowData.lastNote : <span style={{color:"#AF2B01"}}> { rowData.lastNote} </span>}
            </React.Fragment>
        );
    };



    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.vehicleId}
                        onEdit={(vehicleId) => handleEdit(vehicleId,rowData)}
                       
                    />
                )}
            </React.Fragment>
        );
    };


    const handleEdit = (id,data) => {
     
            handleNavigate(id,data);
        
    };

    const handleRowClick = (event) => {
    
          // let target = event.originalEvent.target;
          // if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
          //     target?.lastElementChild?.lastElementChild?.firstElementChild.click();
          // } else {
          //       
          //     
          //         handleNavigate(event.data.vehicleId,event.data);
          // 
          // }

          handleNavigate(event.data.vehicleId,event.data);
    };

    const formData = {
          locationFilter:locationFilter,
          transferStatus:choiceKey,
          search:search,
          status:statusFilter,
          sentDateFromFilter: sentDateFromFilter,
          sentDateToFilter:sentDateToFilter
      }

    const handleNavigate = (id,data) => {

      const state = {
        pathfrom: '/vehicle-tracker-workbook-page',
        val:data,
        locationFilter,
        formData,
        vehicle:true,
        statusFilter,
    }
     
        navigate(`/${id}/vehicle-tracker-page`,{state});
    }

    useEffect(() => {
       
        if ((user.roles?.some(x => x === "ROLE_MASTER"))||( user.defaultGroups && user.defaultGroups.some(x => x.defaultGroupId === 5 || x.defaultGroupId === 19))){
            setSettingPermission(1);
        }
        else {
            setSettingPermission(2);
        }
        
    }, [user]);

   

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

   useEffect(() => {
        
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        setcommonServerError('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    


    const handleAddInventoryDialogDismiss=()=>{
        setIsAddVehicleDialog(false)
        //setIsVehFoundDialog(true)
    }


    const handleViewvehicleRecord=(data)=>{
      const  state = {
        pathfrom: '/vehicle-tracker-workbook-page',
        val:data,
        locationFilter,
        formData,
        statusFilter,
    }

        navigate(`/${data.id}/vehicle-tracker-page`,{state});
    }




  
    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                {
                  isAddVehicleDialog ?
                    <AddVehicleTrackerDialog
                        onDismiss={ handleAddInventoryDialogDismiss}
                        vehicleRecordUi= {handleViewvehicleRecord}
                        defaultLocation={locationFilter}
                        
                    /> : null
                }

                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Vehicle Tracker</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
               <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                    <div className="lazy-loading-workbook card mouse-hover workbookLocationPlaceHolder">
                        <DataTable
                            value={finalData}
                            paginator={false}
                            header={header}
                            footer={footer}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={handleColumnSort}
                            lazy
                            //rows={25}
                            className=" p-datatable-customers reportHeaderPart"
                            //rowsPerPageOptions={[25, 50, 100]}
                            emptyMessage="No Data found."
                            onRowClick={(event) => handleRowClick(event)}>
                            <Column sortable field="sentDateTime" header="Sent Date" body={dateBodyTemplate} />
                            <Column sortable field="stockNumber" header="Stock" body={stockBodyTemplate} />
                            <Column sortable field="vehicle" header="Vehicle" body={vehicleBodyTemplate} />
                            <Column sortable field="transferStatus" header="In/Out" body={inoutBodyTemplate} />
                            <Column sortable field="locationName" header="Location" body={locationBodyTemplate} />
                            <Column sortable field="status" header="Status" body={statusBodyTemplate} />
                            <Column sortable field="requestedBy" header="Requested By" body={requestedByBodyTemplate} />
                            <Column sortable field="customer" header="Customer" body={customerBodyTemplate} />
                            <Column sortable field="wholeSaleCompleteDate" header="Wholesale Complete" body={wholesaleCompleteBodyTemplate} />
                            <Column sortable field="lastNote" header="Notes" body={commentsBodyTemplate} />
                            <Column field="Settings" style={{ width: '5em' }} header="Actions" body={settingBodyTemplate} />
                         </DataTable>
                        
                    </div>
                </div>
            </Stack>
        </> : null
    );
};