
import React, { useState, useEffect } from 'react';
import {
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton
} from "@fluentui/react";
import { useNavigate  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import {
    handleGetPurchaseBookList
} from "store/inventory/inventory.action";
import 'primeicons/primeicons.css';
import { useLocation } from "react-router-dom";
import { currencyNegValFormat, numberFormat } from "utils/helperFunctions";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { PurchaseBookMoreActionsSubmenu } from "components/table/inventory/dropdown/PurchaseWorkbookMoreActionsSubmenu";
import { Col, Row } from "react-grid-system";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SellInventoryDialog } from "components/dialogs/addInventoryDialog/SellInventoryDialog";
import { convertUTCToLocalModified } from "utils/dateTimeFunctions";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { handleGetLocations } from "store/locations/locations.action";
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};


const credentialModuleName = "Inventory";
export const PurchaseInventoryPage = () => {
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const [credentials, setCredentials] = useState([]);
    const location = useLocation();
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [globalFilter, setGlobalFilter] = useState('');
    const [statusUpdateError, setStatusUpdateError] = useState('');
    const [vehicleID, setVehicleID] = useState('');
    const [purchasDate, setPurchasDate] = useState('');
    const [isSellDialog, setIsSellDialog] = useState(false);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [fromDateFilter, setFromDateFilter] = React.useState<Date | undefined>(undefined);
    const [toDateFilter, setToDateFilter] = React.useState<Date | undefined>(undefined);
    const [locationFilter, setLocationFilter] = useState(0);
    const [cityDealerList, setCityDealerList] = React.useState(null);
    const [purchaseList, setPurchaseList] = React.useState(null);
    const isMobileWorkbook = useMediaQuery({ maxWidth:960});
    // added for new Table resposneive 
    const [products, setProducts] = useState([]);
    // const [expandedRows, setExpandedRows] = useState(null);


    useEffect(() => {
        dispatch<any>(handleGetLocations());
        dispatch<any>(handleGetPurchaseBookList());
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.roles?.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
            setLocationFilter(user.primaryLocationId);
        }
    }, [user]);

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
        //console.log('locationslocationslocations',locations)   
    }, [locations]);

    const purchaseWorkBookList = useSelector((state: AppState) => state.inventory.purchaseWorkBookList);

    useEffect(() => {
        if (purchaseWorkBookList && purchaseWorkBookList.length > 0) {        
            let results = purchaseWorkBookList.map(function (item) {
                const purchaseDate = new Date(convertUTCToLocalModified(item.acquisitionDate));
                const today = new Date();
                let Difference_In_Time;
                //if (today > purchaseDate) {
                    Difference_In_Time = today.getTime() - purchaseDate.getTime();
                //} else {
                //    Difference_In_Time = (purchaseDate.getTime() - today.getTime()) * (-1);
                //}
                const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                item["days"] = Difference_In_Days;
                item["purchaseDate"] = moment(item.acquisitionDate).local().format('MM/DD/YY');
                item.initialListPrice = currencyNegValFormat(item.initialListPrice);
                //item.totalExpenses = item.totalExpenses && currencyNegValFormat(item.totalExpenses)
                item.totalCost = item.totalCost && currencyNegValFormat(item.totalCost);
                item.estimatedGross = item.estimatedGross && currencyNegValFormat(item.estimatedGross);
                item.mileage = item.mileage === 0 ? '' : numberFormat(item.mileage);
                return item;
            })
            setPurchaseList(results);
        } else {
            setPurchaseList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseWorkBookList]);

    /* const expandableTableRows = (data) => {
        let _expandedRows = {};
        data.forEach(p => {
            if (p.vin && p.vin !== '') {
                _expandedRows[`${p.id}`] = true;
            }
        });
        setExpandedRows(_expandedRows);
    } */

    const handleSearchChange = (e) => {
        setGlobalFilter(e.target.value);
    }

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    useEffect(() => {        
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGlobalFilter(stateVal.searchInput);
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }

        if (stateVal?.fromDateFilter) {
            setFromDateFilter(new Date(stateVal?.fromDateFilter));
        }

        if (stateVal?.toDateFilter) {
            setToDateFilter(new Date(stateVal?.toDateFilter));
        }

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }
    }, [location]);

    useEffect(() => {
        handleVehicleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter, fromDateFilter, toDateFilter, locationFilter, purchaseList]);

    const handleVehicleSearch = () => {
        if (globalFilter || fromDateFilter || toDateFilter || locationFilter) {
            let results = purchaseList;
            if (globalFilter && globalFilter.length > 2) {
                const lowerCaseVal = globalFilter.toLowerCase().split(" ");

                lowerCaseVal.forEach(function (item) {
                    if (item !== '') {
                        results = results.filter(x => x.make.toLowerCase().includes(item) ||
                            x.model.toLowerCase().includes(item) ||
                            x.style?.toLowerCase().includes(item) ||
                            x.year.toString().toLowerCase().includes(item) ||
                            (x.vin && x.vin.toLowerCase().includes(item)));
                    }
                });

            }

            if (locationFilter > 0) {
                results = results.filter(x => x.location?.id === locationFilter);
            }

            if (fromDateFilter) {
                const rawFromLogDate = moment(fromDateFilter).local().format('MM/DD/YYYY')
                results = results.filter(x => {
                    let rawApprDate = moment(new Date(x.acquisitionDate)).local().format("MM/DD/YYYY");
                    if (new Date(rawApprDate) >= new Date(rawFromLogDate)) {
                        return true;
                    }
                    return false
                });
            }

            /** sold Date To Filter **/
            if (toDateFilter) {
                let rawToLogDate = moment(new Date(toDateFilter)).format("MM/DD/YYYY");
                results = results.filter(x => {
                    let rawApprDate = moment(new Date(x.acquisitionDate)).local().format("MM/DD/YYYY");
                    if (new Date(rawApprDate) <= new Date(rawToLogDate)) {
                        return true;
                    }
                    return false
                });
            }

            setProducts(results);
        }
        else {
            setProducts(purchaseList);
        }
    }

    const handleFromDateChange = (val: any) => {
        setFromDateFilter(val.target.value)
    };

    const handleToDateChange = (val: any) => {
        setToDateFilter(val.target.value)
    };

    const handleResetWorkbook = () => {
        setGlobalFilter(null);
        setFromDateFilter(null);
        setToDateFilter(null);
        setLocationFilter(user.primaryLocationId ? user.primaryLocationId : 0);
    };

    const header = (
        <>
            <div>
                <div>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={3}>
                                    <div className="table-header employeeeSearchLabelText">
                                        <TextField
                                            value={globalFilter}
                                            type="search"
                                            label="Search "
                                            className="ownerSearchText"
                                            onChange={(e) => handleSearchChange(e)}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="dd-control">
                                        <label className="lbl-text">Location</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={locationFilter}
                                            options={cityDealerList}
                                            onChange={onLocationChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Purch Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="fromLogDate"
                                                        style={{ width: "100%" }}
                                                        value={fromDateFilter}
                                                        showIcon
                                                        onChange={(e) => handleFromDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={toDateFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="toLogDate"
                                                        style={{ width: "100%" }}
                                                        value={toDateFilter}
                                                        showIcon
                                                        onChange={(e) => handleToDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={fromDateFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className=" employeeeSearchLabelText" style={{ marginTop: "25px", maxWidth: "40%" }}>
                                    <PrimaryButton onClick={handleResetWorkbook}>
                                        Reset
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </div>

            </div>
        </>
    );
    const mileageBodyTemplate = (rowData) => {
        let truncatedVin = rowData.vin ? rowData.vin.substr(-8) : '';
        return (
            <React.Fragment>
            <div className="vinMobSec">
              <span className="p-column-title">Stock <span>{rowData.stockNumber}</span></span>
              <span className="p-column-title">Days <span>{rowData.days}</span></span>
            </div>
            {true ?
            <>
              <div className="vinMobSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">VIN <span>{truncatedVin}</span></span>
                <span className="p-column-title">Odo <span>{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span></span>
              </div>
            </>
            : 
            <div className="vinOdoSec" style={{paddingBottom: '0'}}>
                <span className="p-column-title">Odo</span>
                <span className="mobileDisplay">{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span>
            </div> 
            }
            <div className="vinOdoSec mobileDisplayNone">
                <span className="p-column-title">Odo</span>
                {rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}
            </div>
            </React.Fragment>
        );
    };

    const purchaseDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Purch Date</span>
                 {rowData.purchaseDate}
            </React.Fragment>
        );
    };

    const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.year}
                <span className="vinDisplayPos">{rowData.vin}</span>
            </React.Fragment>
        );
    };

    const stockBodyTemplate = (rowData) => {
        return (
            <React.Fragment >
                <div>
                    {rowData.stockNumber}
                </div>
            </React.Fragment>
        );
    };

    const daysBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData.days}
                </div>
            </React.Fragment>
        );
    };

    const myListPriceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">My List Price</span>
                {rowData.initialListPrice}
            </React.Fragment>
        );
    };

    const expTemplate = (rowData) => {

        return (
            <React.Fragment>
                <span className="p-column-title">Total Cost</span>
                {rowData.totalCost}
            </React.Fragment>
        );
    };

    const estimatedGrossBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Profit</span>
                {rowData.estimatedGross}
            </React.Fragment>
        );
    };

    const handleEditPurchase = (vehicleID) => {
        const state = {
            searchInput: globalFilter,
            pathfrom: '/acquired',
            sortOrder,
            sortField,
            fromDateFilter,
            toDateFilter,
            locationFilter
          }
        navigate( `/${vehicleID}/shop-inventory`,  {state});
    };

    const rowClassName = (node) => {
        return { 'vinRow': (node.vin && node.vin !== '') };
    }

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                
                    <PurchaseBookMoreActionsSubmenu
                        modulePermissions={credentials}
                        vehicleID={rowData.id}
                        purchaseDate ={rowData.acquisitionDate}
                        onSell={(vehicleID,purchaseDate) => openSellDialog(vehicleID,purchaseDate)}
                        onEdit={(vehicleID) => handleEditPurchase(vehicleID)}
                    />
                )}

               
            </React.Fragment>
        );
    };

    /* const rowExpansionTemplate = (data) => {
        if (data.vin && (data.vin !== '')) {
            return (
                <div className="vin-subtable" onClick={() => vinRowSelection(data)}>
                    <span>{data.vin}</span>
                </div>
            );
        } else {
            return false;
        }
    }; */

    /* const vinRowSelection = (row) => {
        if (row && row)
        navigate(`/${row.id}/shop-inventory`);
    } */

    // const captureCellSelection = (data) => {
    //     if (data && data.value.length && (data.value[0].field === 'Settings')) {
    //         data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
    //     } else if (data && data.value.length) {
    //         const role = isUserMaster(user.roles);
    //         const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true; 
    //         if (cred || role) {
    //             navigate({
    //                 pathname: `/${data.value[0].rowData.id}/shop-inventory`,
    //                 state: {
    //                   searchInput: globalFilter,
    //                   pathfrom: '/acquired',
    //                   sortOrder,
    //                   sortField,
    //                   fromDateFilter,
    //                   toDateFilter,
    //                   locationFilter
    //                 }
    //             });
    //         }
    //     }
    // };

    const captureCellSelection = (event) => {
        let target = event.originalEvent.target;
        if (target?.nodeName === 'TD' && target?.hasChildNodes() && target.lastElementChild?.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {

            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true; 
            if (cred || role) {
                const  state = {
                    searchInput: globalFilter,
                    pathfrom: '/acquired',
                    sortOrder,
                    sortField,
                    fromDateFilter,
                    toDateFilter,
                    locationFilter
              }
                navigate(`/${event.data.id}/shop-inventory`,{state});
            }
        }
    };

    const openSellDialog = (vehicleID,purchaseDate) => {
        setVehicleID(vehicleID);
        setPurchasDate(purchaseDate)
        setIsSellDialog(true);
    };

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
    }

    return (
        <>
            {isSellDialog ?
                <SellInventoryDialog
                    onDismiss={(msg) => { setStatusUpdateError(msg); setIsSellDialog(false);setPurchasDate(null); }}
                    vehicleId={vehicleID}
                    purchaseDates={purchasDate}
                />
                /*<SoldVehicleInventoryDialog
                    onDismiss={(msg) => { setStatusUpdateError(msg); setIsSellDialog(false);setPurchasDate(null); }}
                    
                />*/
                : null}
            <Stack styles={container} tokens={{ childrenGap: 10 }} className="shopWorkBookPg"> 
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                >
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText style={{ marginLeft: "0px", marginBottom: "0px" }} className=" pageTitleFont pageName inventoryTitle fontColorChangeActive">Active Inventory</CustomFontText>

                        </Col>
                    </Row>
                </Stack>
                {
                    statusUpdateError ?
                        <div>
                            <p className="genericErrorColor"><span>{statusUpdateError}</span></p>
                        </div> : null
                }
                <Row>
                    <Col>
                        <div className="tableColumnTitle datatable-filter-demo noMaxWidthTable shop-inventory-tbl">
                            <div className="card mouse-hover">
                                <DataTable
                                    rowClassName={rowClassName}
                                    value={products}
                                    header={header}
                                    paginator={isMobileWorkbook ? false :true }
                                    rows={isMobileWorkbook ? null :25}
                                    className="p-datatable-customers p-datatable-hoverable-rows"
                                    onSort={onSort}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    // expandedRows={expandedRows}
                                    // onRowToggle={(e) => setExpandedRows(e.data)}
                                    // rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
                                    ////cellSelection
                                    onRowClick={(event) => captureCellSelection(event)}>
                                    <Column field="stockNumber" header="Stock" body={stockBodyTemplate} sortable className="mobileDisplayNone stockCol"/>
                                    <Column field="year" header="Year" body={yearBodyTemplate} className="yrMakeModel yrOly" sortable />
                                    <Column field="make" header="Make" sortable className="yrMakeModel" />
                                    <Column field="model" header="Model" sortable className="yrMakeModel" />
                                    <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable />
                                    <Column field="purchaseDate" header="Purch Date" body={purchaseDateBodyTemplate} sortable />
                                    <Column field="days" header="Days" body={daysBodyTemplate} sortable className="mobileDisplayNone"/>
                                    <Column field="initialListPrice" header="My List" body={myListPriceBodyTemplate} sortable />
                                    <Column field="totalExpenses" header="Total Cost" body={expTemplate} sortable />
                                    <Column field="estimatedGross" header="Profit" body={estimatedGrossBodyTemplate} sortable />
                                    <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                                </DataTable>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Stack>
        </>
    );
};
