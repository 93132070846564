import React from "react";
import { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
    Stack,
    IStackStyles,
    Link,
    PrimaryButton,
} from "@fluentui/react";
//import { useNavigate  } from "react-router-dom";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { AppState } from "store/index";
//import {
//  getModulePermissions,
//  isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
//import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
//import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { handleGetSelectList } from "store/selectList/selectList.action";
import { handleGetsFinnaceDeskingReports } from "store/dealTracker/dealTracker.action";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { classNames } from "primereact/utils";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
//const credentialModuleName = "Location";
export const FinanceDeskingReports = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate ();

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const financeProducts = useSelector((state: AppState) => state.selectList.financeProducts) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    //const locations = useSelector((state: AppState) => state.locations.locations) as any; 
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
    const [financePersonList, setFinancePersonList] = React.useState([]);
    const [financeProductList, setFinanceProductList] = React.useState([]);
    const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
    const [endMonthFilter, setEndMonthFilter] = React.useState(null);
    const [yearFilter, setYearFilter] = React.useState(currentYear);
    const [managerFilter, setManagerFilter] = React.useState(0);
    const [productFilter, setProductFilter] = React.useState(0);
    const [isFinalData, setIsFinalData] = useState([]);
    const [endMonthOption, setEndMonthOption] = useState([]);

    const monthsOptions = masterDropdownValues.getMonth;

    const ExcelJS = require('exceljs');
    const { saveAs } = require('file-saver');

    // const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
    //   return { key: item, text: item };
    // });

    const rowClassName = (node) => {
        return { 'childRow': (node.key && node.key.includes('-')) };
    }

    const handleStartMonthDropdownChange = (e) => {
        handleEndMonthOptions(e.value);
    };

    const handleEndMonthDropdownChange = (e) => {
        setEndMonthFilter(e.value)
        //handleFilters(startMonthFilter,item.key ,yearFilter,managerFilter,productFilter);
    };

    const handleYearDropdownChange = (e) => {
        setYearFilter(e.value)
        //handleFilters(startMonthFilter,endMonthFilter ,item.key,managerFilter,productFilter);
    };

    const handleManagerDropdownChange = (e) => {
        setManagerFilter(e.value)
        //handleFilters(startMonthFilter,endMonthFilter,yearFilter,item.key,productFilter);
    };

    const handleProductDropdownChange = (e) => {
        setProductFilter(e.value)
        //handleFilters(startMonthFilter,endMonthFilter,yearFilter,managerFilter,item.key);
    };

    const handleEndMonthOptions = (startMonthFilter) => {
        if (startMonthFilter > 0) {
            if (monthsOptions) {
                const results = monthsOptions.filter(x => x.key > startMonthFilter);
                setEndMonthOption(results);
            }
            setStartMonthFilter(startMonthFilter)
            if (endMonthFilter && startMonthFilter >= endMonthFilter) {
                setEndMonthFilter(null);
            }
        }
    }

    const handleResetWorkbook = () => {
        setStartMonthFilter(currentMonth);
        setEndMonthFilter(null);
        setYearFilter(currentYear);
        setManagerFilter(0);
        setProductFilter(0);
        //handleFilters(currentMonth,endMonthFilter ,currentYear,managerFilter,productFilter)
    };

    //const handleFilters = (startMonthFilter, endMonthFilter,yearFilter,managerFilter,productFilter) => {

    //    if (startMonthFilter > 0 || endMonthFilter > 0|| yearFilter > 0 || managerFilter > 0 || productFilter > 0) {

    //    const formData = {
    //            startMonth:startMonthFilter,
    //            endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
    //            year: yearFilter,
    //            salesManagerid: managerFilter ? managerFilter: "" ,
    //            financeProductid: productFilter ? productFilter: "" ,
    //    };
    //        dispatch(handleGetsFinnaceDeskingReports({
    //            formData,
    //            callback: (response) => {
    //                    if(response.length > 0){
    //                            handlePopulateReports(response)
    //                            console.log("have res",response);
    //                    }else{
    //                         setIsFinalData(null)
    //                    }

    //                } 
    //        }));
    //    } 
    //}

    const handleFilters = () => {
        if (startMonthFilter > 0 || endMonthFilter > 0 || yearFilter || managerFilter > 0 || productFilter > 0) {

            const formData = {
                startMonth: startMonthFilter,
                endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
                year: yearFilter,
                salesManagerid: managerFilter ? managerFilter : "",
                financeProductid: productFilter ? productFilter : "",
            };
            dispatch(handleGetsFinnaceDeskingReports({
                formData,
                callback: (response) => {
                    if (response.length > 0) {
                        handlePopulateReports(response)
                    } else {
                        setIsFinalData(null)
                    }

                }
            }));
        }
    }

    const handlePopulateReports = (response) => {

        if (response && response.length) {
            let deskWorkbook = []
            response.forEach(function (response, index) {

                let deskObj = {
                    key: '' + index,
                    data: {
                        year: response.year,
                        month: response.month,
                        manager: response.firstName + ' ' + response.lastName,
                        dollars: (response.totalAmount),
                        units: response.totalUnits,
                        perUnit: (response.amountPerUnit),
                    },
                    children: getChildRow(response.financeProducts, index)
                }

                deskWorkbook.push(deskObj);

            });
            //setIsResetData(deskWorkbook)
            setIsFinalData(deskWorkbook);
        }
    };

    const getChildRow = (product, index) => {

        if (product && product.length > 0) {
            let deskingChildWorkbook = []
            product.forEach(function (item, i) {

                let deskObj = {
                    key: index + '-' + i,
                    data: {
                        year: null, //moment(task.startDate).format("MM/DD/YYYY"),
                        month: null,
                        manager: item.financeProductName,
                        dollars: (item.totalAmount),
                        units: item.totalUnits,
                        perUnit: (item.amountPerUnit),
                    }
                }
                deskingChildWorkbook.push(deskObj);

            });
            return deskingChildWorkbook;
        } else
            return [];

    };


    useEffect(() => {

        dispatch({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "salesManager" } })
        dispatch(handleGetSelectList("financeProducts", 1));
        dispatch(handleGetLocations());
        //handleFilters (startMonthFilter, endMonthFilter,yearFilter,managerFilter,productFilter)
        handleEndMonthOptions(startMonthFilter)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startMonthFilter, endMonthFilter, yearFilter, managerFilter, productFilter]);

    useEffect(() => {

        let person = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            person.unshift({ key: 0, text: 'All' });
            setFinancePersonList(person);
        }

    }, [employees])

    useEffect(() => {

        let finProduct = [];
        if (financeProducts.length > 0) {
            financeProducts.map((item: any) => {
                return finProduct.push({ key: item.id, text: item.name })
            });
            finProduct.unshift({ key: 0, text: 'All' });
            setFinanceProductList(finProduct);
        }
    }, [financeProducts])



    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }


    const exportExcel = () => {

        if (isFinalData && isFinalData.length) {
            let data = [...isFinalData]
            let header = ["Year", "Month", "Manager", "Dollars", "Units", "Per Unit"];

            let exportData = [];

            data.forEach(function (desk, index) {
                const parent = getExportData([desk]);
                exportData.push(...parent);
                //exportData.concat(parent);
                if (desk.children) {
                    const children = getExportData(desk.children);
                    exportData.push(...children);
                    //exportData.concat(children);
                }
            });
            const excelListData = getSheetData(exportData, header)
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Finance Desking Reports');
            // Add data rows
            excelListData.forEach((row) => {
                worksheet.addRow(row);
            });
            // Generate buffer
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'Finance Desking Reports.xlsx');
            });

        }
    }

    const getExportData = (item) => {

        return item.map((desk) =>
        ({
            "Year": Number(desk.data.year),
            "Month": Number(desk.data.month),
            "Manager": desk.data.manager,
            "Dollars": Number(desk.data.dollars),
            "Units": desk.data.units,
            "perUnit": Number(desk.data.dollars)
        }));
    }





    const header = (
        <div >
            <div className=" table-header " style={{ marginBottom: "10px", display: "block" }} >
                <Row  >
                    <Col md={12}  >
                        <Row  >
                            <Col md={2} className="columnBtwSpace"  >
                                {/*<Dropdown
                                         id="startMonth"
                                         label="Start Month"
                                         selectedKey={startMonthFilter}
                                         options={masterDropdownValues.getMonth}
                                         onChange={handleStartMonthDropdownChange}
                                         placeholder=""
                                         className=" dropDownBorderColor" 
                                     />  */}
                                <label className="lbl-text">Start Month</label>
                                <PRDropdown
                                    id="startMonth"
                                    value={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleStartMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="endMonth"
                                         label="End Month"
                                         selectedKey={endMonthFilter }
                                         options={endMonthOption}
                                         onChange={handleEndMonthDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">End Month</label>
                                <PRDropdown
                                    id="endMonth"
                                    value={endMonthFilter}
                                    options={endMonthOption}
                                    onChange={handleEndMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="year"
                                         label="Year"
                                         selectedKey={yearFilter}
                                         options={yearValues}
                                         onChange={handleYearDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">Year</label>
                                <PRDropdown
                                    id="year"
                                    value={yearFilter}
                                    options={masterDropdownValues.from2022years}
                                    onChange={handleYearDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="salesManager"
                                         label="Sales Manager"
                                         selectedKey={managerFilter}
                                         options={financePersonList}
                                         onChange={handleManagerDropdownChange}
                                         placeholder=""
           
                                     /> */}
                                <label className="lbl-text">Sales Manager</label>
                                <PRDropdown
                                    id="salesManager"
                                    value={managerFilter}
                                    options={financePersonList}
                                    onChange={handleManagerDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                />
                            </Col>
                            <Col md={2} className="columnBtwSpace">
                                {/*<Dropdown
                                         id="product"
                                         label="Product"
                                         selectedKey={productFilter}
                                         options={financeProductList}
                                         onChange={handleProductDropdownChange}  
                                         placeholder=""
           
                                     /> */}
                                <label className="lbl-text">Product</label>
                                <PRDropdown
                                    id="product"
                                    value={productFilter}
                                    options={financeProductList}
                                    onChange={handleProductDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                            </Col>
                            <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                <PrimaryButton onClick={handleResetWorkbook} >
                                    Reset
                                </PrimaryButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
            <div style={{ marginLeft: "-8px"}}>
                <strong className="blueShadedText readField"> For single month, only select start month </strong>
            </div>

            <div style={{ float: "right", fontSize: "16px", marginRight: "-13px", marginTop: "-30px" }}>


                <Link color="inherit" data-pr-tooltip="XLS" onClick={() => exportExcel()} > Export to Excel </Link>

            </div>
        </div>
    );


    const handleEditLocation = (id) => {


    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if ((target.nodeName === 'DIV' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'BUTTON') ||
            (target.nodeName === 'TD' && target.childElementCount > 0 && target.lastElementChild.nodeName === 'DIV')) {
            target.lastElementChild.lastElementChild.firstElementChild.click();
        } else {
            handleEditLocation(event.node);
        }
    }

    const dollarBodyTemplate = (rowData) => {
        
        return (
            <React.Fragment>
                {currencyNegValFractionalFormat(rowData.data.dollars)}
            </React.Fragment>
        );
    };

    const perUnitBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {currencyNegValFractionalFormat(rowData.data.perUnit)}
            </React.Fragment>
        );
    };

	const togglerTemplate = (node, options) => {
        if (!node) {
            return;
        }

        const expanded = options.expanded;
        const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
            'pi-plus-circle': !expanded,
            'pi-minus-circle': expanded
        });

        return (
            <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{color:'#ffffff',backgroundColor:'#0065cb'}} tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    return (

        isKarlProfileType ? <>
            <div className="reportFinanceProductWidth">
                <Stack styles={container} tokens={{ childrenGap: 10 }}>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                        className="loc_btn_par_con"
                    >

                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Finance Desking Report </CustomFontText>
                            </Col>
                        </Row>
                    </Stack>
                    <div className=" deskReport reportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                        <div >
                            <TreeTable
                                value={isFinalData}
                                onRowClick={(event) => handleRowClick(event)}
                                selectionMode="single"
                                header={header}
                                paginator={false}
                                className="reportHeaderPart "
                                togglerTemplate={togglerTemplate}
                                rowClassName={rowClassName} >
                                <Column sortable field="year" style={{ width: '9em' }} header="Year" expander></Column>
                                <Column sortable field="month" style={{ width: '9em' }} header="Month" ></Column>
                                <Column sortable field="manager" header="Manager" ></Column>
                                <Column sortable field="dollars" header="Dollars" body={dollarBodyTemplate} ></Column>
                                <Column sortable field="units" header="Units" ></Column>
                                <Column sortable field="perUnit" header="Per Unit" body={perUnitBodyTemplate} ></Column>
                            </TreeTable>
                        </div>
                    </div>
                </Stack>
            </div>
        </> : null
    );
};
