import React, { useState, FC } from "react";
import { colors } from "constants/constants";
import {
  DefaultPalette,
  FontIcon,
  Stack,
  IStackStyles,
} from "@fluentui/react";

interface IProps {
  title: string;
  subTitles: string[];
  count?: string;
  optional?: boolean;
  required?: boolean;
  onClick: (e) => void;
  errorMessage?: string;
  backgroundColor?: string;
  error?: boolean;
  partner? :boolean;
}

const containerStyle = {
  //borderBottom: `1px solid ${colors.separator}`,

  paddingBottom: 10,
};

const hoveredContainerStyle = {
  //borderBottom: `1px solid ${colors.separator}`,
  paddingBottom: 10,
  cursor: "pointer",
};

const countStyle = {
  borderRadius: "50%",
  width: 15,
  height: 15,
  color: "white",
  // marginRight: 15,
  // fontSize: 14,
  padding: "3px 4px 5px 4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const border: IStackStyles = {
  root: {
    border: "1px solid red",
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: -5,
    marginRight: -5,
    paddingLeft: 10,
    paddingRight: 10,
    // padding: 20
  },
};

const ListItem: FC<IProps> = ({
  onClick,
  title,
  subTitles,
  count,
  optional,
  required,
  errorMessage,
  backgroundColor,
  error,
  partner
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <Stack
        onClick={onClick}
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={hovered ? hoveredContainerStyle : containerStyle}
        styles={errorMessage && border}
      >
        <div>
          <div
            style={{
                fontSize: 16,
                fontWeight: partner ? null : "bold",
                color: colors.lighterBlack,
            }}
            className={required ? "segoeFont required-Label" :"segoeFont"}
          >
            {title}
          </div>
          <div className='segoeFont'>
            {subTitles?.map((subTitle, index) => {
              return (
                <span key={index} style={{ fontSize: 12, color: error && colors.red }}>{`${subTitle} ${
                  index + 1 === subTitles.length ? "" : ","
                } `}</span>
              );
            })}
          </div>
        </div>
        <Stack tokens={{ childrenGap: 15 }} horizontal verticalAlign="center">
          {count && (
            <div
              style={{
                ...countStyle,
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : DefaultPalette.themePrimary,
              }}
            >
              <span>{count}</span>
            </div>
          )}
          <FontIcon iconName="ChevronRight" />
        </Stack>
      </Stack>
      {errorMessage && (
        <div
          style={{
            color: colors.red,
            marginTop: -5,
            marginBottom: 10,
            fontSize: 12,
          }}
        >
          {errorMessage}
        </div>
      )}
      <div
        style={{ marginTop: 2, borderBottom: `1px solid ${colors.separator}` }}
      />
    </>
  );
};

export default ListItem;
