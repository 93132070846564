import React, { useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import {
    PrimaryButton,
    ChoiceGroup,
    IChoiceGroupOption,
    IconButton,
    Icon,
    Modal
} from "@fluentui/react";
import {
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import {
    TextField as OffTextField
} from "@fluentui/react";
// import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
// import { ConfirmDeleteDialog} from "components/dialogs/confirmDeleteDialog/ConfirmDeleteDialog";
import { Col, Row } from "react-grid-system";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
/* import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; */
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { convertLocalToUTC } from 'utils/dateTimeFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetAllFinanceDeliveryByDeal, handlePatchDelAllFinanceDeliveryDeal, handlePostAllFinanceDeliveryDeal } from 'store/dealTracker/dealTracker.action';
//handleGetAllFinanceDeliveryApptDate, handleGetAllFinanceDeliveryPerson
import masterDropdownValues from 'constants/masterDropdownValues';
// import { TimePicker } from 'antd';
import TimePicker from 'rc-time-picker';

import { useParams } from 'react-router-dom';
import { scheduleOperationPermissions } from 'constants/constants';
import { isUserMaster } from 'utils/permissions/permissionsHelpers';
import { AppState } from 'store';
// import employeeGroup from "store/employees/employeeGroup.json";

interface IProps {
    isOpen: boolean;
    title: any;
    role: any;
    financeDrp: any;
    deliveryDrp: any;
    selectionDetails: any
    isEditFlow: any;
    onDismiss: (val) => void;
}

interface ParamTypes {
    dealID: string;
}

export const AddScheduleDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    title,
    role,
    financeDrp,
    deliveryDrp,
    isEditFlow,
    selectionDetails,
    onDismiss,
}) => {
    const yearRange = "1980:" + (new Date().getFullYear()+1);
    // const [date, setDate] = React.useState<Date | Date[] | undefined>(undefined);
    // const [dates, setDates] = React.useState<Date | Date[] | undefined>(undefined);
    const [deliveryDate, setdeliveryDate] = React.useState<Date | Date[] | undefined |any>(undefined);
    const [financeDate, setfinanceDate] = React.useState<Date | Date[] | undefined |any>(undefined)
    // const [dealNotes, setDealNotes] = React.useState(null);
    const [financeStatus, setfinanceStatus] = React.useState(1);
    const [deliveryStatus, setdeliveryStatus] = React.useState(1);
    // const [dealNoteError, setDealNoteError] = React.useState(false);
    const [financeTime, setfinanceTime] = React.useState(null);
    const [timePickerOpen, settimePickerOpen] = React.useState(true);
    const [timePickerOpenDel, settimePickerOpenDel] = React.useState(true);
    const [deliveryTime, setdeliveryTime] = React.useState(null);
    const [commonServerError, setcommonServerError] = React.useState(null);
    const [dropdownSelectionError, setdropdownSelectionError] = React.useState(null);
    const [disabledSaveBtn, setdisabledSaveBtn] = React.useState(false);
    const [reqDeleteDelMng, setreqDeleteDelMng] = React.useState(false);
    const [reqDeleteDel, setreqDeleteDel] = React.useState(false);
    const [deleteSaveNonDeal, setdeleteSaveNonDeal] = React.useState(null);
    const [deleteSaveDeal, setdeleteSaveDeal] = React.useState(null);
    const [disableFinanceRadio, setdisableFinanceRadio] = React.useState(false);
    const [disableDCRadio, setdisableDCRadio] = React.useState(false);
    const [financePerMng, setfinancePerMng] = React.useState(false);
    const [salesPerMng, setSalesPerMng] = React.useState(null);
    
    const [dcPerson, setdcPerson] = React.useState(false);
    const [dcPersonDeal, setdcPersonDeal] = React.useState(null);
    const [isfullAccess, setisfullAccess] = React.useState(null);
    const [showDisabledProperty, setShowDisabledProperty] = React.useState(null);
    const [showDisabledPropertyAggr, setShowDisabledPropertyAggr] = React.useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const params = useParams<ParamTypes|any>();
    // const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);
    const dispatch = useDispatch();
    // const groupList: any = employeeGroup.groups;

    //const disabledSec = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
    //36,37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59]

    useEffect(() => {
        if (user) {
            if (user?.defaultGroups) {
                if (user?.defaultGroups.some(x => x.defaultGroupId === 19)) {
                    setShowDisabledProperty(false);
                } else {
                    setShowDisabledProperty(true);
                }
            } else {
                setShowDisabledProperty(false)
            }

            if (user.aggregatedDefaultPolicies) {
                setShowDisabledPropertyAggr(user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_TIME_DISABLED?.toString())))
            } else {
                setShowDisabledPropertyAggr(false)
            }
        }

        
        console.log('222groupList 00116', user.employeeDefaultGroups);
        if (user && user.employeeDefaultGroups && user.employeeDefaultGroups.length) {
            let grpUserFin = false;
            let grpUserDc = false;
            
            user.employeeDefaultGroups.map((item, index) => {
                // selectionDetails?.financeOnly
                // selectedFinance.noFinance
                // sales Per/Mng
                if (item.defaultGroup.id === 5 || item.defaultGroup.id === 6 || item.defaultGroup.id === 7 || item.defaultGroup.id === 8 || item.defaultGroup.id === 16) {
                    setdisableFinanceRadio(true)
                    setdisableDCRadio(false)
                }

                if ((item.defaultGroup.id === 5 || item.defaultGroup.id === 6) && (selectionDetails?.deliveryOnly || selectionDetails?.noFinance)) {
                    setSalesPerMng(true);
                }

                // Finance Per/Mng
                if (item.defaultGroup.id === 7 || item.defaultGroup.id === 8) {
                    grpUserFin = true;
                    setfinancePerMng(true);
                }

                if (item.defaultGroup.id === 16) {  
                    grpUserDc = true;           
                    setShowDisabledPropertyAggr(false);
                    setShowDisabledProperty(false);
                    setdcPerson(true);
                    setdcPersonDeal(selectionDetails?.isEditable ? 'editable' : (selectionDetails?.financeOnly || selectionDetails?.financeCalendar) ? 'finance' : 'delivery');
                }
                return true;
            })
            if (grpUserDc && grpUserFin) {
                // grp user like admin
                setisfullAccess(true);
                setdcPerson(null)
                setdcPersonDeal(null)
            }
        } else {
            setisfullAccess(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    
    const options: IChoiceGroupOption[] = [
	    { key: 'true', text: 'Yes' },
	    { key: 'false', text: 'No' },
    ];

    const scheduleFormik = useFormik({
        initialValues: {
            finance: "true",
            financePerson:"",
            financeReason:"",
            financeDate: "",
            financeTime: "",
            financeDuration: "45 min",
            financestatus: 1,
            delivery:"true",
            deliveryCoordinator:"",
            deliveryDate: "",
            deliveryTime: "",
            deliveryDuration: "75 min",
            deliveryReason:"",
            deliverystatus: 1,
        },
        validationSchema: Yup.object({
            finance:Yup.string(),
            financePerson:Yup.string(),
            /* .when('finance',
            {
                is: 'true',
                then: schema => schema,
                otherwise: Yup.string().required('Finance Person required.')
            }), */
            financeDate: Yup.string(),
            financeTime: Yup.string(),
            financeReason: Yup.string(),
            financeDuration:Yup.string(),
            financestatus:Yup.number(),
            delivery:Yup.string(),
            deliveryCoordinator:Yup.string(),
            /* .when('delivery',
            {
                is: 'true',
                then: schema => schema,
                otherwise: Yup.string().required('Delivery Coordinator required.')
            }), */
            deliveryDate: Yup.string(),
            deliveryTime: Yup.string(),
            deliveryDuration:Yup.string(),
            deliveryReason:Yup.string(),
            deliverystatus:Yup.number(),
        }),
        
        onSubmit: (values) => {
            console.log('deleteSaveNonDea', deleteSaveNonDeal);
            // let rawValues =  Object.assign({}, values);
            var splittedFin = values.financeDuration.split(" ");
            var splittedDel = values.deliveryDuration.split(" ");

            console.log('values', values);
            //let scheduleTime;

            let data = {
                finance: {},
                deliveryCoordinator: {}
            };
            
            if (financeTime?._i) {
                // console.log('utcStart FINAN oment(values.financeTime, "HH:mm")', moment(values.financeTime, "HH:mm"))
                // let utcStart: any = moment(values.financeTime, "HH:mm");
                // console.log('utcStart FINAN', utcStart)
                if (splittedFin[1] === 'hrs') {
                    let isPM = ((financeTime._i.split(" ")[1] === 'PM') || (financeTime._i.split(" ")[1] === 'pm')) ? true : false;
                    let startVal: any = financeTime._i.split(" ")[0].split(":");
                    let startHrs = startVal[0];
                    if (isPM) {
                        startHrs = Number(startVal[0]) === 12 ? 12 : Number(12 + Number(startVal[0]));
                    }
                    data["finance"]["startsAt"] = addZeroIfLess(startHrs) + ':' + addZeroIfLess(startVal[1]) + ':00';
                    
                    let endVal: any = moment.duration(data["finance"]["startsAt"]).add(Number(splittedFin[0]), 'hours');
                    data["finance"]["endsAt"] = addZeroIfLess(endVal._data.hours) + ':' + addZeroIfLess(endVal._data.minutes) + ':00';
                } else if (splittedFin[1] === 'day') {
                    data["finance"]["startsAt"] = '00:00:00';
                    data["finance"]["endsAt"] = "23:45:00"
                } else {
                    let isPM = ((financeTime._i.split(" ")[1] === 'PM') || (financeTime._i.split(" ")[1] === 'pm')) ? true : false;
                    let startVal: any = financeTime._i.split(" ")[0].split(":");
                    let startHrs = startVal[0];
                    if (isPM) {
                        startHrs = Number(startVal[0]) === 12 ? 12 : Number(12 + Number(startVal[0]));
                    }
                    data["finance"]["startsAt"] = addZeroIfLess(startHrs) + ':' + addZeroIfLess(startVal[1]) + ':00';
                    
                    let endVal: any = moment.duration(data["finance"]["startsAt"]).add(Number(splittedFin[0]), 'minutes');
                    data["finance"]["endsAt"] = addZeroIfLess(endVal._data.hours) + ':' + addZeroIfLess(endVal._data.minutes) + ':00';
                }
                //scheduleTime = utcStart.format();
            }
            if (values.financeDate) {
                data["finance"]["date"] = (values.financeDate);
            }

            //let scheduleDeliveryTime;
            if (deliveryTime?._i) {
                // console.log('values.deliveryTime',values.deliveryTime)
                // let utcStart = moment(values.deliveryTime, "HH:mm").utc();
                // console.log('utcStart',utcStart)
                if (splittedDel[1] === 'hrs') {
                    let isPM = ((deliveryTime._i.split(" ")[1] === 'PM') || (deliveryTime._i.split(" ")[1] === 'pm')) ? true : false;
                    let startVal: any = deliveryTime._i.split(" ")[0].split(":");
                    
                    let startHrs = startVal[0];
                    if (isPM) {
                        startHrs = Number(startVal[0]) === 12 ? 12 : Number(12 + Number(startVal[0]));
                    }
                    data["deliveryCoordinator"]["startsAt"] = addZeroIfLess(startHrs) + ':' + addZeroIfLess(startVal[1]) + ':00';
                    
                    let endVal: any = moment.duration(data["deliveryCoordinator"]["startsAt"]).add(Number(splittedDel[0]), 'hours');
                    data["deliveryCoordinator"]["endsAt"] = addZeroIfLess(endVal._data.hours) + ':' + addZeroIfLess(endVal._data.minutes) + ':00';
                } else if (splittedDel[1] === 'day') {
                    data["deliveryCoordinator"]["startsAt"] = '00:00:00';
                    data["deliveryCoordinator"]["endsAt"] = "23:45:00";
                } else {
                    let isPM = ((deliveryTime._i.split(" ")[1] === 'PM') || (deliveryTime._i.split(" ")[1] === 'pm')) ? true : false;
                    let startVal: any = deliveryTime._i.split(" ")[0].split(":");

                    let startHrs = startVal[0];
                    if (isPM) {
                        startHrs = Number(startVal[0]) === 12 ? 12 : Number(12 + Number(startVal[0]));
                    }
                    data["deliveryCoordinator"]["startsAt"] = addZeroIfLess(startHrs) + ':' + addZeroIfLess(startVal[1]) + ':00';

                    let endVal: any = moment.duration(data["deliveryCoordinator"]["startsAt"]).add(Number(splittedDel[0]), 'minutes');
                    data["deliveryCoordinator"]["endsAt"] = addZeroIfLess(endVal._data.hours) + ':' + addZeroIfLess(endVal._data.minutes) + ':00';
                }
                //scheduleDeliveryTime = utcStart.format()
            }

            if (values.deliveryDate) {
                data["deliveryCoordinator"]["date"] = (values.deliveryDate);
            }

            // data["finance"] = values.finance;
            data["finance"]["assignedToEmployeeId"] = values.financePerson;

            // data["financeTime"] = scheduleTime;
            // data["financeDuration"] = values.financeDuration;
            // data["delivery"] = values.delivery;
            data["deliveryCoordinator"]["assignedToEmployeeId"] = values.deliveryCoordinator;

            if (values.finance === 'false') {
                data["finance"] = null;
            }
            if (values.delivery === 'false') {
                data["deliveryCoordinator"] = null;
            }

            if (data["finance"] && data["finance"]["assignedToEmployeeId"] === "") {
                delete data["finance"]["assignedToEmployeeId"];
            } else if (data["deliveryCoordinator"] && (data["deliveryCoordinator"]["assignedToEmployeeId"] === "" || data["deliveryCoordinator"]["assignedToEmployeeId"] === -1 || data["deliveryCoordinator"]["assignedToEmployeeId"] === -2)) {
                delete data["deliveryCoordinator"]["assignedToEmployeeId"];
            }

            if (role !=='manager') {
                if (deleteSaveDeal) {
                    let method = 'DELETE';
                    dispatch<any>(handlePatchDelAllFinanceDeliveryDeal({ dealId: selectionDetails.dealId, scheduleData: {}, role, method, callback: (res) =>  {
                        if (res) {
                            setcommonServerError(false);
                            onDismiss('reloadCalendar');
                        } else {
                            setcommonServerError(true);
                        }
                    }
                    }));
                } else {
                    dispatch<any>(handlePostAllFinanceDeliveryDeal({ dealId: selectionDetails.dealId, scheduleData: data, callback: (res) =>  {
                        if (res) {
                            setcommonServerError(false);
                            onDismiss('reloadCalendar');
                        } else {
                            setcommonServerError(true);
                        }
                    }
                    }));
                }
            } else {
                // patch 
                // delete
                if (deleteSaveNonDeal) {
                    let val = {};
                    if (!data.deliveryCoordinator) {
                        val = {
                            ...data.finance,
                            "reason": values.financeReason
                        }
                    } else {
                        val = {
                            ...data.deliveryCoordinator,
                            "reason": values.deliveryReason
                        }
                    }
                    let method = deleteSaveNonDeal === 'update' ? 'PATCH' : 'DELETE';
                    dispatch<any>(handlePatchDelAllFinanceDeliveryDeal({ dealId: selectionDetails.scheduleId, scheduleData: val, role, method, callback: (res) =>  {
                        if (res) {
                            setcommonServerError(false);
                            onDismiss('reloadCalendar');
                        } else {
                            setcommonServerError(true);
                        }
                    }
                    }));
                }
            }
            
        }
    });

    useEffect(() => {
        if (selectionDetails?.start) {
            setScheduleFinanceForm(selectionDetails);
        }

        if (selectionDetails?.financeCalendar) {
            setScheduleFinanceForm(selectionDetails.financeCalendar);
        }

        if (selectionDetails?.deliveryCalendar) {
            setScheduleDeliveryForm(selectionDetails.deliveryCalendar);
        }

        if(selectionDetails?.isEditable) {
            getSpecificDealSchedules(selectionDetails.dealId);
        }

        if (role ==='sales') {
            let disabledState = (selectionDetails?.dealId && Number(selectionDetails.dealId) !==  Number(params.dealID)) ? true : false;
            setdisabledSaveBtn(disabledState);
            // If editable enable DELETE
            if (selectionDetails?.isEditable) {
                setreqDeleteDel(true)
            }
        } else if (role ==='manager') {
            // setdisabledSaveBtn(true);
            if (selectionDetails?.financeOnly) {
                /* scheduleFormik.setValues(
                    {
                        ...scheduleFormik.values,
                        "delivery": "false"
                    }
                ); */
            }
            if (selectionDetails?.deliveryOnly) {
                /* scheduleFormik.setValues(
                    {
                        ...scheduleFormik.values,
                        "finance": "false"
                    }
                ); */
            }
            setreqDeleteDelMng(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionDetails, isEditFlow]);

    useEffect(() => {
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            let childTwo: any = document.getElementsByClassName('req-del-time-panel')[1];
            child?.parentNode?.parentNode?.classList.add("activeTEST")
            if (childTwo) {
                childTwo?.parentNode?.parentNode?.classList.add("activeTESTDel")
            }
            
            const check = document.querySelector(".req-del-time-panel");
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTEST')[0];
                    let child2Del: any = document.getElementsByClassName('activeTESTDel')[1] ? document.getElementsByClassName('activeTESTDel')[1] : document.getElementsByClassName('activeTESTDel')[0];
                            
                    if (child2) {
                        const parentEle = document.getElementById("dealTracker-schedulePopup");
                        let li = document.createElement('div');
                        li.appendChild(child2);
                        if (child2Del) {
                            li.appendChild(child2Del);
                        }
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                    settimePickerOpen(false)
                    settimePickerOpenDel(false)
                }
            }, 500)
        }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setScheduleFinanceForm = (selectedFinance) => {
        console.log('moment(selectionDetails.start',moment(new Date(selectedFinance.start).getTime(), 'h:mm'))

        let endDate: any = new Date(selectedFinance.end);
        let startDate: any = new Date(selectedFinance.start);
        let diffMs: any = endDate - startDate; // milliseconds between now & Christmas
        // var diffDays = Math.floor(diffMs / 86400000); // days
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        let financeDuration = '';
        if (diffHrs > 0) {
            if (diffHrs === 1) {
                financeDuration = '60 min'
            } else {
                financeDuration = diffHrs + ' hrs'
            }
        } else if (diffMins > 0) {
            financeDuration = diffMins + ' min'
        }

        // default to 60 mins
        // if (selectedFinance?.salesPerson) {
        financeDuration = '60 min';
        // }

        let rawDate =  Object.assign({}, selectedFinance);
        let selectedRawDate: any = new Date(rawDate.start);
        // let selectedDate = new Date(selectedFinance.start);

        // let deliveryTime = selectedDate.setMinutes(selectedDate.getMinutes() + 30);
        let financeTime: any = moment(selectedRawDate.toLocaleTimeString(), 'h:mm a');
        setfinanceTime(financeTime);

        // setDeliveryTimeOnchange(financeDuration, false, null)
        // let deliveryTime: any = moment(selectedRawDate.toLocaleTimeString(), 'h:mm a');
        // setdeliveryTime(moment(new Date(deliveryTime).toLocaleTimeString(), 'h:mm a'));
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                "finance": selectedFinance.noFinance ? "false" : "true",
                "financePerson": selectedFinance.resourceId,
                "financeDate": moment(selectedRawDate).format("YYYY-MM-DD"),
                "financeTime": financeTime._i,
                "financeReason": selectionDetails?.reason ? selectionDetails?.reason : "",
                "financeDuration": financeDuration,
                "delivery": "true",
                "deliveryDate": moment(selectedRawDate).format("YYYY-MM-DD"),
                "deliveryTime": setDeliveryTimeOnchange(financeDuration, true, selectedFinance)
            }
        );
        setdeliveryDate(selectedRawDate);
        console.log('scheduleFormik', scheduleFormik);

        setfinanceDate(selectedRawDate);
    }

    const setScheduleDeliveryForm = (selectedDelivery) => {
        console.log('moment(selectionDetails.start',moment(new Date(selectedDelivery.start).getTime(), 'h:mm'))

        let endDate: any = new Date(selectedDelivery.end);
        let startDate: any = new Date(selectedDelivery.start);
        let diffMs: any = endDate - startDate; // milliseconds between now & Christmas
        // let diffMs: any = new Date(new Date(rawDateDelivery.endsAt).toUTCString()) - new Date(new Date(rawDateDelivery.startsAt).toUTCString()); // milliseconds between now & Christmas
        // var diffDays = Math.floor(diffMs / 86400000); // days
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        let deliveryDuration = '';
        if (diffHrs > 0) {
            if (diffHrs === 1 && diffMins === 0) {
                deliveryDuration = '60 min'
            } else if (diffHrs === 1 && diffMins === 15) {
                deliveryDuration = '75 min'
            } else if (diffHrs === 1 && diffMins === 30) {
                deliveryDuration = '90 min'
            } else {
                deliveryDuration = diffHrs + ' hrs'
            }
        } else if (diffMins > 0) {
            deliveryDuration = diffMins + ' min'
        }

        // default to 75 mins
        // if (selectedDelivery?.salesPerson) {
        deliveryDuration = '75 min';
        // }

        let rawDate =  Object.assign({}, selectedDelivery);
        let selectedRawDate: any = new Date(rawDate.start);
        // let selectedDate = new Date(selectedDelivery.start);

        // let deliveryTime = selectedDate.setMinutes(selectedDate.getMinutes() + 30);
        let deliveryTime: any = moment(selectedRawDate.toLocaleTimeString(), 'h:mm a');
        setdeliveryTime(null);
        setTimeout(()=> {
            setdeliveryTime(deliveryTime);
        }, 10)

        // let deliveryTime: any = moment(selectedRawDate.toLocaleTimeString(), 'h:mm a');
        // setdeliveryTime(moment(new Date(deliveryTime).toLocaleTimeString(), 'h:mm a'));
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                "deliveryCoordinator": selectedDelivery.resourceId,
                "deliveryDate": moment(selectedRawDate).format("YYYY-MM-DD"),
                "deliveryTime": deliveryTime._i,
                "deliveryReason": selectionDetails?.reason ? selectionDetails?.reason : "",
                "deliveryDuration": deliveryDuration,
                "finance": (selectionDetails?.deliveryOnly || selectionDetails?.noFinance) ? "false" : "true",
                "financeDate": moment(selectedRawDate).format("YYYY-MM-DD"),
                "financeTime": setFinanceTimeOnchange("60 min", true, selectedDelivery)
            }
        );
        if (selectionDetails?.deliveryOnly || selectionDetails?.noFinance) {
            setdisableDCRadio(true);
        }
        setdeliveryDate(selectedRawDate);
        setfinanceDate(selectedRawDate);
    }

    const getSpecificDealSchedules = (deal) => {
        // call API to get deal specific calendar
        dispatch<any>(handleGetAllFinanceDeliveryByDeal({ dealId: deal, callback: (res) => {
            console.log('GET SPECIFIC DEAL SCHEDULE', res);
            let financeForm = {};
            let deliveryForm = {};
            if (res.financeSchedule) {
                let rawDateFinance =  Object.assign({}, res.financeSchedule);
                let selectedRawDateFinance: any = new Date(rawDateFinance.startsAt);
                let endDate: any = new Date(rawDateFinance.endsAt);
                let startDate: any = new Date(rawDateFinance.startsAt);
                let diffMs: any = endDate - startDate; // milliseconds between now & Christmas
                // var diffDays = Math.floor(diffMs / 86400000); // days
                let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                let financeDuration = '';
                if (diffHrs > 0) {
                    if (diffHrs === 1) {
                        financeDuration = '60 min'
                    } else {
                        financeDuration = diffHrs + ' hrs'
                    }
                } else if (diffMins > 0) {
                    financeDuration = diffMins + ' min'
                }
                setfinanceStatus(res.financeSchedule.status);
                let utcLocalTime = convert24To12Hours(addZeroIfLess(selectedRawDateFinance.getUTCHours()) + ':' + addZeroIfLess(selectedRawDateFinance.getUTCMinutes()) + ':00');
                // selectedRawDateFinance.toLocaleTimeString()
                let financeTimeRes: any = moment(utcLocalTime, 'h:mm a');
                setfinanceTime(financeTimeRes);
                financeForm = {
                    "financePerson": rawDateFinance?.assignedTo?.id,
                    "financeDate": moment(selectedRawDateFinance).format("YYYY-MM-DD"),
                    "deliveryDate": moment(selectedRawDateFinance).format("YYYY-MM-DD"),
                    "financeTime": financeTimeRes._i,
                    "financeDuration": financeDuration,
                    "financestatus": rawDateFinance.status
                }
            } else {
                financeForm = {
                    "finance": "false",
                    "financeDate": null,
                }
            }
            if (res.deliverySchedule) {
                let rawDateDelivery =  Object.assign({}, res.deliverySchedule);
                let selectedRawDateDelivery: any = new Date(rawDateDelivery.startsAt);
                let endDate: any = new Date(rawDateDelivery.endsAt);
                let startDate: any = new Date(rawDateDelivery.startsAt);
                let diffMs: any = endDate - startDate; // milliseconds between now & Christmas
                // let diffMs: any = new Date(new Date(rawDateDelivery.endsAt).toUTCString()) - new Date(new Date(rawDateDelivery.startsAt).toUTCString()); // milliseconds between now & Christmas
                // var diffDays = Math.floor(diffMs / 86400000); // days
                let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                let deliveryDuration = '';
                if (diffHrs > 0) {
                    if (diffHrs === 1 && diffMins === 0) {
                        deliveryDuration = '60 min'
                    } else if (diffHrs === 1 && diffMins === 15) {
                        deliveryDuration = '75 min'
                    } else if (diffHrs === 1 && diffMins === 30) {
                        deliveryDuration = '90 min'
                    } else {
                        deliveryDuration = diffHrs + ' hrs'
                    }
                } else if (diffMins > 0) {
                    deliveryDuration = diffMins + ' min'
                }
                setdeliveryStatus(res.deliverySchedule.status);
                let utcLocalTime = convert24To12Hours(addZeroIfLess(selectedRawDateDelivery.getUTCHours()) + ':' + addZeroIfLess(selectedRawDateDelivery.getUTCMinutes()) + ':00');
                // selectedRawDateDelivery.toLocaleTimeString()
                let delvieryTimeRes: any = moment(utcLocalTime, 'h:mm a');
                setdeliveryTime(null);
                setTimeout(()=> {
                    setdeliveryTime(delvieryTimeRes);
                }, 10)
                deliveryForm = {
                    "deliveryCoordinator": rawDateDelivery?.assignedTo?.id,
                    "deliveryDate": moment(selectedRawDateDelivery).format("YYYY-MM-DD"),
                    "financeDate": (financeForm["financeDate"] === null) ? moment(selectedRawDateDelivery).format("YYYY-MM-DD") : financeForm["financeDate"],
                    "deliveryTime": delvieryTimeRes._i,
                    "deliveryDuration": deliveryDuration,
                    "deliverystatus": rawDateDelivery.status
                }
            } else {
                deliveryForm = {
                    "delivery": "false"
                }
            }
            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    ...financeForm,
                    ...deliveryForm
                }
            );
            }
        }));
    }

    const addZeroIfLess = (val) => {
        if (Number(val) < 10) {
            return '0'+ Number(val)
        }
        return val;
    }

    const convert24To12Hours = (time) => {
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        
        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }

    /* const saveNotesDetails = () => {
          if (dealNotes && dealNotes.length > 0) {
            setDealNoteError(false);
            setDealNotes(null)
          } else {
            setDealNoteError(true);
          }
    } */

    const handleOptionChange = (e, item) => {
		let field = e.target.name ? e.target.name : e.target.id
		scheduleFormik.setValues(
			{
				...scheduleFormik.values,
				[field]: item.key
			}
		);
	}; 

    /* const handleFinancePersonOnChange = (e: { value: any }) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                financePerson: e.value,
            }
        );
    } */

    const handleDropdownChange = (e: { value: any, target: any }) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                [e.target.id]: e.value
            }
        );

        setTimeout(() => {
            if (e.target.id === 'financeDuration') {
                /* if (selectionDetails?.start) {
                    setDeliveryTimeOnchange(e.value, false, selectionDetails);
                } else if (selectionDetails?.financeCalendar) {
                    setDeliveryTimeOnchange(e.value, false, selectionDetails.financeCalendar);
                } else if (selectionDetails?.deliveryCalendar) {
                    setDeliveryTimeOnchange(e.value, false, selectionDetails.deliveryCalendar);
                } */
                // setTimeout(()=> {
                setDeliveryTimeOnchange(e.value, false, null);
                // }, 2000)
            } else if (e.target.id === 'deliveryDuration') {
                // setFinanceTimeOnchange(e.value, false, null);
            }
        }, 100);
    }

    const handleTextAreaChange = (e) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                [e.target.id]: e.target.value
            }
        );
    }

    const setDeliveryTimeOnchange = (val: any, returnVal: any, selectedData) => {
        var splitted = val.split(" ");
        let rawDate;
        if (selectedData) {
            rawDate =  Object.assign({}, selectedData);
        }
        let deliveryTimeVal;
        let updatedDeltime: any;
        if (splitted[1] === 'min') {
            if (rawDate) {
                deliveryTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() + Number(splitted[0] -15)); 
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                deliveryTimeVal = new Date(financeTime._d).setMinutes(new Date(financeTime._d).getMinutes() + Number(splitted[0] -15)); 
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            }
            setdeliveryTime(null);
            setTimeout(() => {
                setdeliveryTime(updatedDeltime);
            }, 10)
            if (returnVal) {
                return updatedDeltime._i;
            }
            if (rawDate?.start) {
                setdeliveryDate(new Date(rawDate.start));
            }

        } else if (splitted[1] === 'hrs') {
            const getMin = Number(splitted[0] * 60);
            if (rawDate) {
                deliveryTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() + getMin - 15);
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                deliveryTimeVal = new Date(financeTime._d).setMinutes(new Date(financeTime._d).getMinutes() + getMin - 15);
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            }
            setdeliveryTime(null);
            setTimeout(() => {
                setdeliveryTime(updatedDeltime);
            }, 10)
            if (returnVal) {
                return updatedDeltime._i;
            }
            if (rawDate?.start) {
                setdeliveryDate(new Date(rawDate.start));
            }
        } else {
            // const getMin = Number(splitted[0] * 60 * 24);
            if (rawDate) {
                deliveryTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() - 15);
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                deliveryTimeVal = new Date(financeTime._d).setMinutes(new Date(financeTime._d).getMinutes() - 15);
                updatedDeltime = moment(new Date(deliveryTimeVal).toLocaleTimeString(), 'h:mm a');
            }
            // set Delivery Date
            if (rawDate) {
                let newDelDate = new Date(rawDate.start).setDate(new Date(rawDate.start).getDate() + 1);
                setdeliveryDate(new Date(newDelDate));
            }
            setdeliveryTime(null);
            setTimeout(() => {
                setdeliveryTime(updatedDeltime);
            }, 10)
            if (returnVal) {
                return updatedDeltime._i;
            }
        }
    }

    const setFinanceTimeOnchange = (val: any, returnVal: any, selectedData) => {
        var splitted = val.split(" ");
        let rawDate;
        if (selectedData) {
            rawDate =  Object.assign({}, selectedData);
        }
        let financeTimeVal;
        let updatedDeltime: any;
        if (splitted[1] === 'min') {
            if (rawDate) {
                financeTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() - Number(splitted[0] -15)); 
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                financeTimeVal = new Date(deliveryTime._d).setMinutes(new Date(deliveryTime._d).getMinutes() - Number(splitted[0] -15)); 
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            }

            setfinanceTime(updatedDeltime);
            if (returnVal) {
                return updatedDeltime._i;
            }
            if (rawDate?.start) {
                setfinanceDate(new Date(rawDate.start));
            }

        } else if (splitted[1] === 'hrs') {
            const getMin = Number(splitted[0] * 60);
            if (rawDate) {
                financeTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() - (getMin - 15));
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                financeTimeVal = new Date(deliveryTime._d).setMinutes(new Date(deliveryTime._d).getMinutes() - (getMin - 15));
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            }
            setfinanceTime(updatedDeltime);
            if (returnVal) {
                return updatedDeltime._i;
            }
            if (rawDate?.start) {
                setfinanceDate(new Date(rawDate.start));
            }
        } else {
            // const getMin = Number(splitted[0] * 60 * 24);
            if (rawDate) {
                financeTimeVal = new Date(rawDate.start).setMinutes(new Date(rawDate.start).getMinutes() - 15);
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            } else {
                financeTimeVal = new Date(deliveryTime._d).setMinutes(new Date(deliveryTime._d).getMinutes() - 15);
                updatedDeltime = moment(new Date(financeTimeVal).toLocaleTimeString(), 'h:mm a');
            }
            // set Delivery Date
            let newDelDate;
            if (rawDate) {
                newDelDate = new Date(rawDate.start).setDate(new Date(rawDate.start).getDate() - 1);
            } else {
                newDelDate = new Date(deliveryTime._d).setDate(new Date(deliveryTime._d).getDate() - 1);
            }
            setfinanceDate(new Date(newDelDate));
            setfinanceTime(updatedDeltime);
            if (returnVal) {
                return updatedDeltime._i;
            }
        }
    }

    const handleDateChange = (val) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                "financeDate": moment(val).format("YYYY-MM-DD"),
                "deliveryDate": moment(val).format("YYYY-MM-DD") 
            }
        );
        setfinanceDate(val);
        // reflect Delivery Date
        setdeliveryDate(val);

    };
  
    /*
    const handleFinanceTimeChange = (time: any, timeString: string) => {
        console.log('timeString', timeString)
        console.log('time', time)
      */

    const testOpen = (test) => {
        settimePickerOpen(true);
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            child?.parentNode?.parentNode?.classList.add("activeTEST")
            const check = document.querySelector(".req-del-time-panel");
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTEST')[0];
                    const parentEle = document.getElementById("dealTracker-schedulePopup");
                        
                    if (child2) {
                        let li = document.createElement('div');
                        li.appendChild(child2);  
                
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                }
            }, 500)
        }, 500)
    }

    const testClose = () => {
        settimePickerOpen(false);
       /*  setTimeout(() => {
            handleFinanceTimeChange(financeTime)
        }, 3000) */
    };
   
    const testOpenDel = (test) => {
        settimePickerOpenDel(true);
        setTimeout(() => {
            let child: any = document.getElementsByClassName('req-del-time-panel')[0];
            child?.parentNode?.parentNode?.classList.add("activeTESTDel")
            
            const check = document.querySelector(".req-del-time-panel");
            //settimePickerOpen(false);
            setTimeout(() => {
                if (check) {
                    let child2: any = document.getElementsByClassName('activeTESTDel')[1] ? document.getElementsByClassName('activeTESTDel')[1] : document.getElementsByClassName('activeTESTDel')[0];
                    const parentEle = document.getElementById("dealTracker-schedulePopup");
                        
                    if (child2) {
                        let li = document.createElement('div');
                        li.appendChild(child2);               
                        parentEle.insertBefore(li, parentEle.children[0]);
                    }
                }
            }, 500)
        }, 500)
    }

    const testCloseDel = () => {
        settimePickerOpenDel(false);
    };

    const handleFinanceTimeChange = (value) => {
        let financeTimeRaw: any = moment(value.format('h:mm a'));
        let financeTime: any = moment(financeTimeRaw._i, 'h:mm a');
        /* deliveryFormik.setValues(
            {
                ...deliveryFormik.values,
                'requestDeliveryTime': value ? value.format('HH:mm') : ''
            }
        ); */

       // let financeTime: any = moment(timeString, 'h:mm a');
        if (financeTime) {
            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    'financeTime': convertTime12To24(financeTime._i)
                }
            );
            setfinanceTime(financeTime)
        }

        // reflect delivery Section Time
        let financeDuration = scheduleFormik.values.financeDuration;
        let splitted: any = financeDuration.split(" ");
        let updatedDelTime: any;
        // let updatedDelTime2;
        if (splitted[1] === 'min') {
            updatedDelTime = moment(financeTime).add(Number(splitted[0] - 15), 'minutes').format('h:mm a');
            // updatedDelTime2 = moment(financeTime).add(Number(splitted[0] - 15), 'minutes');
            //setdeliveryTime(updatedDelTime2)
        } else if (splitted[1] === 'hrs') {
            const getMin = Number(splitted[0] * 60);
            updatedDelTime = moment(financeTime).add(getMin - 15, 'minutes').format('h:mm a');
            // updatedDelTime2 = moment(financeTime).add(getMin - 15, 'minutes');
            //setdeliveryTime(updatedDelTime2)
        } else {
            const getMin = Number(splitted[0] * 60 * 24);
            updatedDelTime = moment(financeTime).add(getMin - 15, 'minutes').format('h:mm a');
            // updatedDelTime2 = moment(financeTime).add(getMin - 15, 'minutes');
           // setdeliveryTime(updatedDelTime2)
        }
        
        // let selectedRawDate: any = new Date(updatedDelTime2);
        // let selectedDate = new Date(selectedDelivery.start);

        // let deliveryTime = selectedDate.setMinutes(selectedDate.getMinutes() + 30);
        // let deliveryTime: any = moment(selectedRawDate.toLocaleTimeString(), 'h:mm a');

        let deliveryTimeUpdated: any = moment(updatedDelTime, 'h:mm a');
        /* let deliveryTimeRaw: any = moment(updatedDelTime.format('h:mm a'));
        let deliveryTimeUpdated: any = moment(deliveryTimeRaw._i, 'h:mm a'); */
        
        if (updatedDelTime) {      // deliveryTimeUpdated
            // setdeliveryTime(null)
            // setTimeout(()=> {
                /* let selectedData = {
                    start: financeTime
                } */
                scheduleFormik.setValues(
                    {
                        ...scheduleFormik.values,
                        'deliveryTime': convertTime12To24(deliveryTimeUpdated._i)   // setDeliveryTimeOnchange(financeDuration, true, selectedData)
                    }
                );
                setdeliveryTime(null)
                setTimeout(() => {
                    setdeliveryTime(deliveryTimeUpdated)
                }, 200)
            // }, 5000);
        }
    }

    const handleDatesOnChange = (val) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                "deliveryDate": moment(val).format("YYYY-MM-DD") 
            }
        );
        setdeliveryDate(val)
    };

    //const handleDeliveryTimeChange = (time: any, timeString: string) => {
    const handleDeliveryTimeChange = (value: any) => {
        let deliveryTimeRaw: any = moment(value.format('h:mm a'));
        let deliveryTime: any = moment(deliveryTimeRaw._i, 'h:mm a');

        // let delvieryTime: any = moment(timeString, 'h:mm a');
        if (deliveryTime) {
            scheduleFormik.setValues(
                {
                    ...scheduleFormik.values,
                    'deliveryTime': convertTime12To24(deliveryTime._i)
                }
            );
            setdeliveryTime(deliveryTime)
        }
    }

    const convertTime12To24 = (timeStr) => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
           hours = '00';
        }
        if (modifier?.toUpperCase() === 'PM') {
           hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    /* const handleDeliveryDurationOnChange = (e: { value: any }) => {
        scheduleFormik.setValues(
            {
                ...scheduleFormik.values,
                'deliveryDuration': e.value,
            }
        );
    }  */

    /* const setNotesValue = (e) => {
         setDealNotes(e.target.value)
    } */

    const handleScheduleFormikSumbit = () => {
        if (scheduleFormik.values.deliveryCoordinator === '' && scheduleFormik.values.delivery === 'true' && selectionDetails?.deliveryReq && selectionDetails.deliveryCalendar) {
            setdropdownSelectionError('delivery');
        } else if (scheduleFormik.values.financePerson === '' && scheduleFormik.values.finance === 'true' && selectionDetails?.financeReq && selectionDetails.financeCalendar) {
            setdropdownSelectionError('finance');
        } else {
            setdropdownSelectionError(null);
            scheduleFormik.handleSubmit();
        }
    }

    const handleUpdateNonDeal = () => {
        setdeleteSaveNonDeal('update');
        scheduleFormik.handleSubmit();
    }

    const handleDelNonDeal = () => {
        setdeleteSaveNonDeal('delete');
        scheduleFormik.handleSubmit();
    }

    const handleDelDeal = () => {
        setdeleteSaveDeal('delete');
        handleScheduleFormikSumbit();
    }

    const handleSavDeal = () => {
        setdeleteSaveDeal(null);
        handleScheduleFormikSumbit();
    }

    return (<>
        <Modal
            isOpen={isOpen}
            onDismiss={() => onDismiss(false)}
            isBlocking={true}
            styles={{ root: { zIndex: 10 } }}
            className=" dealRecordHeight updateEmployeeDialogHeight scrollView"
        >
            <div  className="editDiv" style={{width:"auto"}}>
                {/*<GoBackToMainDialogBtn label="" onClick={onDismiss} />*/}
                <span className="formTitle" style={{width:"480px"}}>{title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => onDismiss(false)}
                    className="close-Crossbtn"
                />
            </div>

            {
                commonServerError ?
                <div>
                    <Row>
                        <Col>
                            <p className="genericErrorColor" style={{paddingLeft: "30px"}}><span>Finance Person and/or Delivery Coordinator is not available for requested time.</span></p>
                        </Col>
                    </Row>
                </div> : null
            }
            
            {
                (dropdownSelectionError) ?
                <div>
                    <Row>
                        <Col>
                            {dropdownSelectionError === 'finance' ? <p className="genericErrorColor" style={{paddingLeft: "30px"}}><span>Finance Person required.</span></p> : null }
                            {dropdownSelectionError === 'delivery' ? <p className="genericErrorColor" style={{paddingLeft: "30px"}}><span>Delivery Coordinator required.</span></p> : null }
                        </Col>
                    </Row>
                </div> : null
            }

            <div  className="dealTracker-schedulePopup" id="dealTracker-schedulePopup">
                { ((selectionDetails?.isManager && selectionDetails?.financeOnly) || (!selectionDetails?.isManager)) ?
                <>
                    <div className={"financeSection " + (selectionDetails?.financeReq || !(showDisabledProperty && showDisabledPropertyAggr))}>
                        <div >
                            <Row className="optionGroup">
                                <Col className="radio-options">
                                    <label className="lbl-text lbl-txts">Finance:</label> 
                                    <ChoiceGroup
                                        id="finance"
                                        className={"guidedRadio optionRadio" + (disableFinanceRadio ? " disabledRadio" : "") }
                                        name="finance"
                                        options={options}
                                        onChange={handleOptionChange}
                                        selectedKey={scheduleFormik.values.finance.toString()}
                                        disabled={isfullAccess ? false : (((!selectionDetails?.financeReq) && showDisabledProperty) || disableFinanceRadio)}
                                    />
                                </Col>
                                
                            </Row>
                        </div>
                        <div id="delivery-section" className="delivery-section sectionDivWidth sectionWidth" style={{ padding: "0px 0px" }} > 
                            <div>
                                { role==='sales' ?
                                    <Row className="rowSpace rowfield rowSpaceHeight" >
                                        <Col md={7} className="widthSpecify">
                                            <label className="lbl-text">Finance Person</label>
                                            <PRDropdown  
                                                id="financePerson"
                                                name="financePerson"
                                                value={scheduleFormik.values.financePerson}
                                                options={financeDrp}
                                                optionLabel="resourceTitle"
                                                optionValue="resourceId"
                                                onChange={handleDropdownChange}
                                                appendTo="self"
                                                className="custom-p-dropdown dropdown-Width arrowWidth-picker"
                                                filter={false}
                                                resetFilterOnHide={true}
                                                disabled={isfullAccess ? false : dcPersonDeal ? (dcPersonDeal === 'editable' ? false : dcPersonDeal === 'finance' ? false : true) : 
                                                ((scheduleFormik.values.finance === 'false' || !selectionDetails?.financeReq) &&
                                                showDisabledProperty)}
                                            />
                                        </Col>
                                    {/*   <Col className="widthSpecify newstatus-Label">
                                            <label className="lbl-text"><strong>Status:</strong> New</label>
                                        </Col>*/}
                                    </Row> :
                                    <Row className="rowSpace rowfield rowSpaceHeight" >
                                        <Col md={7} className="widthSpecify">
                                            <OffTextField
                                                id="financeReason"
                                                name="description"
                                                label="Reason"
                                                placeholder=""
                                                // value={selectionDetails?.reason}
                                                value={scheduleFormik.values.financeReason}
                                                onChange={handleTextAreaChange}
                                                autoComplete="off"
                                                // disabled={true}
                                                /* errorMessage={
                                                    formik.touched.locationForm && locationFormik.touched.locationForm.legalName &&
                                                    locationFormik.errors.locationForm && locationFormik.errors.locationForm.legalName &&
                                                    locationFormik.errors.locationForm.legalName.toString()
                                                } */
                                            />
                                        </Col>
                                        <Col md={5} className="widthSpecify" style={{paddingLeft: "3px"}}>
                                            <label className="lbl-text">Finance Person</label>
                                            <PRDropdown  
                                                id="financePerson"
                                                name="financePerson"
                                                value={scheduleFormik.values.financePerson}
                                                options={financeDrp}
                                                optionLabel="resourceTitle"
                                                optionValue="resourceId"
                                                onChange={handleDropdownChange}
                                                appendTo="self"
                                                className="custom-p-dropdown dropdown-Width arrowWidth-picker showAboveList"
                                                filter={false}
                                                resetFilterOnHide={true}
                                                disabled={isfullAccess ? false : dcPersonDeal ? (dcPersonDeal === 'editable' ? false : dcPersonDeal === 'finance' ? false : true) :
                                                !selectionDetails?.financeReq && showDisabledProperty}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </div>
                            <Row className="rowSpace rowfield rowSpaceHeight colDateTime" justify="center" id="test">
                                <Col md={7} className={"request-delivery request-deliveryText " + role}>
                                    <div className="date-time-group">
                                        <div className="auctionDate date-picker datePick" style={{ width: "100%" }}>
                                            <label style={{ display: "block" }} className="lbl-text">Fin.Date & Time </label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup dateTimeField pickDate">
                                                <Calendar
                                                    id="financeDate"
                                                    //label=" Requested Delivery"
                                                    style={{ display: "flex" }}
                                                    value={financeDate}
                                                    appendTo="self"
                                                    showIcon
                                                    onChange={(e) => handleDateChange(e.value)}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                    className="requiredSize fieldWidth"
                                                    disabled={isfullAccess ? false : dcPersonDeal ? (dcPersonDeal === 'editable' ? false : dcPersonDeal === 'finance' ? false : true) :
                                                    ((scheduleFormik.values.finance === 'false' || !selectionDetails?.financeReq) &&
                                                    showDisabledProperty)}

                                                />
                                            </div>
                                        </div>
                                        <div className="auctionStartTime time-picker time-Schedule " style={{ width: "100%" }}>
                                            {/* <TimePicker minuteStep={15} showNow={false} 
                                            
                                                disabled={scheduleFormik.values.finance === 'false' || !selectionDetails?.financeReq}
                                                // use12Hours={true}
                                                //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                                hideDisabledOptions={true}
                                                format="h:mm a"
                                                value={financeTime}
                                                onChange={handleFinanceTimeChange}
                                                allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                                getPopupContainer={() => document.getElementById("delivery-section")}
                                                disabledSeconds={() => disabledSec}
                                                // value
                                                // onChange function(time: moment, timeString: string):
                                                // onSelect function(time: moment)
                                            /> */}

                                            {/* var date = moment.utc(data.requestDeliveryTime).format('YYYY-MM-DD HH:mm:ss');
                                            var stillUtc = moment.utc(date).toDate();
                                            local = moment(stillUtc).local().format('HH:mm');
                                            setReqDeliveryTime(moment(stillUtc).local()); */}

                                            <TimePicker
                                                //className="test"
                                                // prefixCls="testttt"
                                                showSecond={false}
                                                hideDisabledOptions={true}
                                                defaultValue={financeTime}
                                                className="mask-FieldGroup rc-time-picker-input"
                                                onChange={handleFinanceTimeChange}
                                                placement="bottomLeft"
                                                focusOnOpen={true}
                                                onOpen={testOpen}
                                                onClose={testClose}
                                                open={timePickerOpen}
                                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23]}
                                                format="h:mm a"
                                                use12Hours
                                                inputReadOnly
                                                minuteStep={15}
                                                popupClassName="req-del-time-panel"
                                                placeholder="Time"
                                                inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                disabled={isfullAccess ? false : dcPersonDeal ? (dcPersonDeal === 'editable' ? false : dcPersonDeal === 'finance' ? false : true) : 
                                                ((scheduleFormik.values.finance === 'false') && 
                                                showDisabledProperty)}
                                                /*  || 
                                                (showDisabledProperty && showDisabledPropertyAggr)} */
                                            />
                                            
                                        </div>
                                    </div>

                                </Col>
                                <Col md={5} className={role + " duration"}>
                                    <label className="lbl-text" style={{ paddingTop:"3px" }}>Duration</label>
                                    <PRDropdown
                                        id="financeDuration"
                                        name="financeDuration"
                                        value={scheduleFormik.values.financeDuration}
                                        options={masterDropdownValues.financeDuration}
                                        optionLabel="key"
                                        optionValue="key"
                                        onChange={handleDropdownChange}
                                        placeholder=""                                                           
                                        appendTo="self"
                                        // className="custom-p-dropdown arrowWidth-picker"
                                        className={role==='sales' ? "custom-p-dropdown arrowWidth-picker" : "custom-p-dropdown arrowWidth-picker showAboveList"}
                                        disabled={isfullAccess ? false : (dcPerson ? true: ((scheduleFormik.values.finance === 'false') &&
                                        showDisabledProperty) || 
                                        (showDisabledProperty && showDisabledPropertyAggr))}
                                        //   filter={false}
                                        //   resetFilterOnHide={true}
                                    />
                                </Col>
                            </Row>
                            <div className="widthSpecify labelStatus">
                                <label className="lbl-text"><strong>Status: </strong>
                                {financeStatus === 1 ? ' Pending' : financeStatus === 2 ? ' Accepted' : financeStatus === 3 ? ' Declined' : ' New'}
                                </label>
                            </div>
                        </div>
                    </div>
                </>
                : null }
                
                { ((selectionDetails?.isManager && selectionDetails?.deliveryOnly) || (!selectionDetails?.isManager)) ?
                <>
                    <div className={"deliverySection " + (selectionDetails?.deliveryReq || !(showDisabledProperty && showDisabledPropertyAggr))}> 
                        <div>
                            <Row className="optionGroup">
                                <Col className="radio-options choicegroup-Space">
                                    <label className="lbl-text lbl-txts">Delivery:</label>
                                    <ChoiceGroup
                                        id="delivery"
                                        className={"guidedRadio optionRadio" + (disableDCRadio ? " disabledRadio": "") }
                                        name="delivery"
                                        options={options}
                                        selectedKey={scheduleFormik.values.delivery.toString()}
                                        onChange={handleOptionChange}   
                                        disabled={isfullAccess ? false : ((selectionDetails?.noFinance ? true : false) || disableDCRadio)}                                                 
                                    />
                                </Col>  
                            </Row>                                    
                        </div>
                        <div className="delivery-section sectionDivWidth sectionWidth" style={{ padding: "0px 0px" }} > 
                            { role==='sales' ?
                            <Row className="rowSpace rowfield rowSpaceHeight" >
                                <Col md={7} className="widthSpecify">
                                    <label className="lbl-text">Delivery Coordinator</label>
                                    <PRDropdown  
                                        id="deliveryCoordinator"
                                        name="deliveryCoordinator"
                                        value={scheduleFormik.values.deliveryCoordinator}
                                        options={deliveryDrp}
                                        optionLabel="resourceTitle"
                                        optionValue="resourceId"
                                        onChange={handleDropdownChange}
                                        appendTo="self"
                                        className="custom-p-dropdown dropdown-Width arrowWidth-picker"
                                        filter={false}
                                        resetFilterOnHide={true}
                                        required={true}
                                        disabled={isfullAccess ? false : dcPerson ? (scheduleFormik.values.delivery.toString() === 'false') : (((scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq ) &&
                                        showDisabledProperty) || 
                                        (showDisabledProperty && showDisabledPropertyAggr) ||
                                        financePerMng)}
                                    />
                                </Col>
                            {/* <Col className="widthSpecify newstatus-Label">
                                    <label className="lbl-text"><strong>Status:</strong> New</label>
                                </Col> */}
                            </Row> :
                            <Row className="rowSpace rowfield rowSpaceHeight" >
                                <Col md={7} className="widthSpecify">
                                    <OffTextField
                                        id="deliveryReason"
                                        name="description"
                                        label="Reason"
                                        placeholder=""
                                        // value={selectionDetails?.reason}
                                        value={scheduleFormik.values.deliveryReason}
                                        onChange={handleTextAreaChange}
                                        autoComplete="off"
                                        // disabled={true}
                                        /* errorMessage={
                                            formik.touched.locationForm && locationFormik.touched.locationForm.legalName &&
                                            locationFormik.errors.locationForm && locationFormik.errors.locationForm.legalName &&
                                            locationFormik.errors.locationForm.legalName.toString()
                                        } */
                                    />
                                </Col>
                                <Col md={5} className="widthSpecify" style={{paddingLeft: "3px"}}>
                                    <label className="lbl-text">Delivery Coordinator</label>
                                    <PRDropdown  
                                        id="deliveryCoordinator"
                                        name="deliveryCoordinator"
                                        value={scheduleFormik.values.deliveryCoordinator}
                                        options={deliveryDrp}
                                        optionLabel="resourceTitle"
                                        optionValue="resourceId"
                                        onChange={handleDropdownChange}
                                        appendTo="self"
                                        className="custom-p-dropdown dropdown-Width arrowWidth-picker showAboveList"
                                        filter={false}
                                        resetFilterOnHide={true}
                                        required={true} 
                                        disabled={isfullAccess ? false : dcPerson ? (scheduleFormik.values.delivery.toString() === 'false') : (((scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq) && 
                                        showDisabledProperty) || 
                                        (showDisabledProperty && showDisabledPropertyAggr) ||
                                        financePerMng)}
                                    />
                                </Col>
                            </Row>
                            }


                            <Row className="rowSpace rowfield rowSpaceHeight colDateTime" justify="center" >
                                <Col md={7} className={"request-delivery request-deliveryText " + role}>
                                    <div className="date-time-group">
                                        <div className="auctionDate date-picker datePick" style={{ width: "100%" }}>
                                            <label style={{ display: "block" }} className="lbl-text">Del. Date & Time </label>
                                            <div className="ms-TextField-fieldGroup mask-FieldGroup dateTimeField pickDate">
                                                <Calendar
                                                    id="deliveryDate"
                                                    value={deliveryDate}
                                                    onChange={(e) => handleDatesOnChange(e.value)}
                                                    //label=" Requested Delivery"
                                                    style={{ display: "flex" }}  
                                                    appendTo="self"
                                                    showIcon
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange={yearRange}
                                                    className="requiredSize fieldWidth"
                                                    disabled={salesPerMng ? false : isfullAccess ? false : dcPerson ? (scheduleFormik.values.delivery.toString() === 'false') : (((scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq))
                                                    //|| !selectionDetails?.noFinance) }
                                                    // scheduleFormik.values.finance === 'false' || !selectionDetails?.financeReq
                                                    && showDisabledProperty) || 
                                                    (showDisabledProperty && showDisabledPropertyAggr)}
                                                />
                                            </div>
                                        </div>
                                        <div className="auctionStartTime time-picker field-time time-Schedule" style={{ width: "100%" }}>
                                            {/* <TextField
                                                id="deliveryTime"
                                                name="deliveryTime"
                                                label=""
                                                type="time"
                                                style={{ display: "flex" }}
                                                value={scheduleFormik.values.deliveryTime}
                                                onChange={(e) => handleDeliveryTimeChange(e)}
                                                // defaultValue="07:30"
                                                className="requestDeliveryTime"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, 
                                                    underline: {
                                                        "&&&:before": {
                                                            borderBottom: "none"
                                                        },
                                                        "&&:after": {
                                                            borderBottom: "none"
                                                        }
                                                    }
                                                }}
                                            /> */}
                                            {/* <TimePicker minuteStep={15} showNow={false} 
                                            
                                            disabled={scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq}
                                            // use12Hours={true}
                                            //defaultOpenValue={moment(new Date(selectionDetails.start).getTime(), 'h:mm')}
                                            hideDisabledOptions={true}
                                            format="h:mm a"
                                            value={deliveryTime}
                                            onChange={handleDeliveryTimeChange}
                                            allowClear={false}	disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24]}
                                            getPopupContainer={() => document.getElementById("delivery-section")}
                                            disabledSeconds={() => disabledSec}
                                            // value
                                            // onChange function(time: moment, timeString: string):
                                            // onSelect function(time: moment)
                                        /> */}
                                        {deliveryTime ? 
                                            <TimePicker
                                                //className="test"
                                                // prefixCls="testttt"
                                                showSecond={false}
                                                hideDisabledOptions={true}
                                                defaultValue={deliveryTime}
                                                className="mask-FieldGroup rc-time-picker-input"
                                                onChange={handleDeliveryTimeChange}
                                                // placement="bottomLeft"
                                                focusOnOpen={true}
                                                onOpen={testOpenDel}
                                                onClose={testCloseDel}
                                                open={timePickerOpenDel}
                                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23]}
                                                format="h:mm a"
                                                use12Hours
                                                inputReadOnly
                                                minuteStep={15}
                                                popupClassName="req-del-time-panel"
                                                placeholder="Time"
                                                inputIcon={<Icon iconName="Clock" className="clock_btn_customize" />}
                                                disabled={salesPerMng ? false : isfullAccess ? false : dcPerson ? (scheduleFormik.values.delivery.toString() === 'false') : (((scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq) &&
                                                showDisabledProperty) || 
                                                (showDisabledProperty && showDisabledPropertyAggr))} // ||
                                                // !selectionDetails?.noFinance)}
                                            /> : null }
                                        </div>
                                    </div>
                                </Col>
                                <Col md={5} className={role + " duration"}>
                                    <label className="lbl-text" style={{ paddingTop:"3px" }}>Duration</label>
                                    <PRDropdown
                                        id="deliveryDuration"
                                        name="deliveryDuration"
                                        value={scheduleFormik.values.deliveryDuration}
                                        options={masterDropdownValues.deliveryDuration}
                                        onChange={handleDropdownChange}
                                        optionLabel="key"
                                        optionValue="key"
                                        placeholder=""                                                           
                                        appendTo="self"
                                        // className="custom-p-dropdown arrowWidth-picker"
                                        className={role==='sales' ? "custom-p-dropdown arrowWidth-picker" : "custom-p-dropdown arrowWidth-picker showAboveList"}
                                        scrollHeight="138px"
                                        disabled={isfullAccess ? false : dcPerson ? (scheduleFormik.values.delivery.toString() === 'false') : (((scheduleFormik.values.delivery === 'false' || !selectionDetails?.deliveryReq) &&
                                        showDisabledProperty) || 
                                        (showDisabledProperty && showDisabledPropertyAggr) ||
                                        financePerMng)}
                                        // virtualScrollerOptions={{ itemSize: 31 }}
                                        //   filter={false}
                                        //   resetFilterOnHide={true}
                                    />
                                </Col>
                            </Row>
                            <div className="widthSpecify labelStatus">
                                <label className="lbl-text"><strong>Statuss: {dcPerson}</strong> 
                                {deliveryStatus === 1 ? ' Pending' : deliveryStatus === 2 ? ' Accepted' : deliveryStatus === 3 ? ' Declined' : ' New'}
                                </label>
                            </div>
                        </div>
                    </div>
                </> : null }
                {/* { role ==='manager' ?
                    <div>
                        <Row style={{marginTop: '5px'}}>
                            <Col className="note_space">
                                <OffTextField
                                    id="notes"
                                    name="notes"
                                    label="Scheduling Comments"
                                    multiline
                                    resizable={false}
                                    className="full-width fontWeightSection"
                                    value={dealNotes}
                                    onChange={(e) => setNotesValue(e)}
                                />
                            </Col>
                        </Row>
                    </div>
                    : null
                } */}
                { reqDeleteDelMng  ?
                    <div className="scheduleSaveButton delbtn-save">
                        <Row className="save-row">
					        <Col className="savebtn">
						        <PrimaryButton                                                        
							        text="Cancel Meeting"
							        type="submit"
                                    className="delete-btn"
                                    disabled={!(user?.aggregatedDefaultPolicies ? (selectionDetails?.deliveryReq ? user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_DC_DELETE?.toString())) : user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_FINANCE_DELETE?.toString()))) : isUserMaster(user.roles))}
                                    onClick={handleDelNonDeal}
						        />
					        </Col>
				        </Row> 
                        <Row className="save-row">
					        <Col className="savebtn">
						        <PrimaryButton                                                        
							        text="Save"
							        type="submit"
                                    className="scheduleSave-btn"
                                    disabled={!(user?.aggregatedDefaultPolicies ? (selectionDetails?.deliveryReq ? user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_DC_UPDATE?.toString())) : user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_FINANCE_UPDATE?.toString()))) : isUserMaster(user.roles))}
                                    onClick={handleUpdateNonDeal}
						        />
					        </Col>
				        </Row>
			        </div> :
                    reqDeleteDel ?
                    <>
                        <div className="scheduleSaveButton delbtn-save">
                            <Row className="save-row">
                                <Col className="savebtn">
                                    <PrimaryButton                                                        
                                        text="Delete Delivery"
                                        type="submit"
                                        className="delete-btn"
                                        disabled={dcPerson ? false : !(user?.aggregatedDefaultPolicies ? (selectionDetails?.deliveryReq ? user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_DC_DELETE?.toString())) : user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_FINANCE_DELETE?.toString()))) : isUserMaster(user.roles))}
                                        onClick={handleDelDeal}
                                    />
                                </Col>
                            </Row> 
                            <Row className="save-row">
                                <Col className="savebtn">
                                    <PrimaryButton                                                        
                                        text="Save"
                                        type="submit"
                                        className="scheduleSave-btn"
                                        onClick={handleSavDeal}
                                        disabled={dcPerson ? false : !(user?.aggregatedDefaultPolicies ? (selectionDetails?.deliveryReq ? user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_DC_UPDATE?.toString())) : user.aggregatedDefaultPolicies.find(element => (element === scheduleOperationPermissions.DEAL_SCHEDULE_FINANCE_UPDATE?.toString()))) : isUserMaster(user.roles))}
                                        // disabled={disabledSaveBtn}
                                    />
                                </Col>
                            </Row>
                        </div> 
                    </>
                    :
                    <Row className="save-row">
                        <Col className="savebtn">
                            <PrimaryButton                                                        
                                text="Save"
                                type="submit"
                                className="scheduleSave-btn"
                                style={{ marginTop: "15px"}}
                                onClick={handleScheduleFormikSumbit}
                                disabled={disabledSaveBtn}
                            />
                        </Col>
                    </Row> 
                }

                { role !=='manager' ?
                <Row >
                    <Col>
                        {/* <Accordion className="inventory-filter-accordion note-popup" defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"                                                   
                                className="notes-arrow"
                            >
                                <div  className="fontWeight" style={{ paddingTop: "0px"}}>
                                    <Typography component={'h3'}>Notes</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className="reduceSpace" >
                                <Row>
                                    <Col className="note_space">
                                        <OffTextField
                                            id="notes"
                                            name="notes"
                                            label=""
                                            multiline
                                            resizable={false}
                                            className="full-width fontWeightSection"
                                            value={dealNotes}
                                            onChange={(e) => setNotesValue(e)}
                                        />
                                    </Col>
                                </Row>
                                {
                                    dealNoteError ?
                                        <div className="">
                                            <Row>
                                                <Col>
                                                    <span className="genericErrorColor"><span>Please enter details</span></span>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                                <Row >
                                    <Col className="text-right ">
                                        <PrimaryButton style={{ marginTop: 10 }} onClick={() => saveNotesDetails()} className="rmv-space" >Add Note</PrimaryButton>
                                    </Col>
                                </Row>                                                   
                            </AccordionDetails>
                        </Accordion> */}
                    </Col>
                </Row>
                : null
                }
            </div>
        </Modal>
    </>)
}