import React, { useEffect, useState } from "react";
import { PrimaryButton, Link } from "@fluentui/react";
import {
    TextField as OffTextField
} from "@fluentui/react";
import {
    TextField
} from "@fluentui/react";
import { Col, Row } from "react-grid-system";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    handleGetAppraisalsByDeal,
    handleSaveAppraisal,
    handleGetAppraisalInfo,
    HandleSaveDealsTradeInNotesUsingID,
    //HandleGetDealsTradeInNotesUsingID,
    handleGetDealNotes,
    handleGetAppraisalTable,
    handleDeleteAppraisalItem
} from "store/dealTracker/dealTracker.action";
import { AppState } from "store";
import masterDropdownValues from "constants/masterDropdownValues";
import { useMediaQuery } from "react-responsive";
import CustomTable from "components/table/datatable/CustomTable";
import AddCircle from '@mui/icons-material/AddCircle';
import { convertUTCToLocalDateOnlyModified } from "utils/dateTimeFunctions";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import { AddUpdateAppraisalDialog } from "components/dialogs/dealTrackerDialog/AddUpdateAppraisalDialog";
import { Typography } from '@mui/material';
import CurrencyInput from 'react-currency-input-field'
import {
    isUserMaster,
    getTradeInElementPermission
} from "utils/permissions/permissionsHelpers";
import { dealTrackerOperationPermissions } from "constants/constants";
import { ConfirmDeleteDialog } from "components/dialogs/confirmDeleteDialog/ConfirmDeleteDialog";

interface IProps {
    dealId: string;
    onError: (msg) => void;
    onFormikChange: (initial, newData) => void;
    saveFormOnRoute: boolean;
    dismissSaveNot: (msg) => void;
    userCredentials: any;
    user: any;
    onCreateTardeIn: (flag) => void;
    classes: any;
    updateSectionIndex: (id) => void;
    setSectionIndx: any;
    writeUpsId: number;
    productId: any;
    tabRoute: boolean;
}

export const TradeIns: React.FunctionComponent<IProps> = ({
    dealId,
    onError,
    onFormikChange,
    saveFormOnRoute,
    dismissSaveNot,
    userCredentials,
    user,
    onCreateTardeIn,
    classes,
    updateSectionIndex,
    setSectionIndx,
    writeUpsId,
    productId,
    tabRoute
}) => {

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const dispatch = useDispatch();
    const appraisalBidders = useSelector((state: AppState) => state.selectList.appraisalBidders) as any;
    const appraisalTableList = useSelector((state: AppState) => state.dealTracker.appraisalTableList) as any;
    const selectedTradeIn = useSelector((state: AppState) => state.dealTracker.selectedTradeIn);
    const emptyAppraisalData = { id: null, employeeId: "", date: "", rollback: "", acv: "" };

    const [appraisalData, setAppraisalData] = useState([]);
    const [sectionIndex, setSectionIndex] = React.useState(null);
    const [tradeInsNoteError, setTradeInsNoteError] = React.useState(false);
    const [tradeInsNote, setTradeInsNote] = React.useState(null);
    const [tradeInVehicleData, setTradeInVehicleData] = React.useState(null);
    const [appraisedtTableData, setAppraisedTableData] = useState([]);
    const [isAddAppraisalDialog, setisAddAppraisalDialog] = useState(false);
    const [appraisedData, setAppraisedData] = useState(emptyAppraisalData);
    const [notesList, setNotesList] = React.useState([]);
    // const [isNewTradeIn, setNewTradeIn] = React.useState(false);
    const [noteFlag, setNoteFlag] = React.useState(false);
    const [tradeInElePermission, setTradeInElePermission] = useState(null);
    const [diasbleValuationBtn, setDiasbleValuationBtn] = useState(null);
    const [diasbleDeleteTradeInBtn, setDiasbleDeleteTradeInBtn] = useState(null);
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = React.useState(false);
    const [appraisalItemId, setAppraisalItemId] = React.useState(null);
    const dealType = ["", "Write-Up", "Trade-in", "Appointments"]

    useEffect(() => {
        const tradeInPermissions = getTradeInElementPermission(user, writeUpsId);
        setTradeInElePermission(tradeInPermissions);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (productId && !tabRoute) {
            handleGetTradeInsDetail(productId);
            setSectionIndex(2);
            //updateSectionIndex(2);
        } else {
            //dispatch<any>(handleGetAppraisalsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
            setSectionIndex(1);
            updateSectionIndex(1);
        }
    }, [productId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user) {

            if (isUserMaster(user.roles)) {
                setDiasbleValuationBtn(false);
                setDiasbleDeleteTradeInBtn(false);
            }
            else if (user.aggregatedDefaultPolicies && user.aggregatedDefaultPolicies.length) {
                const valuationFlag = !user.aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_TRADE_IN_ADD_VALUATION?.toString()));
                setDiasbleValuationBtn(valuationFlag);
                const deleteTradeInFlag = !user.aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_TRADE_IN_DELETE?.toString()));
                setDiasbleDeleteTradeInBtn(deleteTradeInFlag);
            }
            else {
                setDiasbleValuationBtn(true);
                setDiasbleDeleteTradeInBtn(true);
            }

        }
    }, [writeUpsId, user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSectionIndex(setSectionIndx);
        if (setSectionIndx === 1)
            dispatch<any>(handleGetAppraisalsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
    }, [setSectionIndx]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTradeIn) {
            if (selectedTradeIn.vehicle) {
                setTradeInVehicleData(selectedTradeIn.vehicle);
            } else {
                setTradeInVehicleData(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTradeIn]);

    useEffect(() => {
        if (appraisalTableList) {
            for (var i in appraisalTableList.results) {
                appraisalTableList.results[i]["empName"] = appraisalTableList.results[i].employee ? appraisalTableList.results[i].employee.firstName + ' ' + appraisalTableList.results[i].employee.lastName : '';
            }
            setAppraisedTableData(appraisalTableList.results);
        } else {
            setAppraisedTableData([]);
        }
    }, [appraisalTableList]);

    const handlePopulateTableData = (res) => {
        if (res && res.results) {
            let appraisals = res.results;
            for (var i in appraisals) {
                let dealTradeInAppraisalsInfo = appraisals[i].dealTradeInAppraisals ? appraisals[i].dealTradeInAppraisals.at(-1) : null;
                appraisals[i]["appraisalDate"] = appraisals[i].createdAt;
                appraisals[i]["appraiser"] = dealTradeInAppraisalsInfo ? (dealTradeInAppraisalsInfo.employee.firstName + ' ' + dealTradeInAppraisalsInfo.employee.lastName) : null;
                appraisals[i]["rollBack"] = dealTradeInAppraisalsInfo && currencyNegValFractionalFormat(dealTradeInAppraisalsInfo.rollback);
                appraisals[i]["year"] = appraisals[i].vehicle && appraisals[i].vehicle?.year;
                appraisals[i]["model"] = appraisals[i].vehicle && appraisals[i].vehicle?.model;
                appraisals[i]["higherBidder"] = appraisals[i].appraisalBidder?.name;
                appraisals[i]["subject"] = appraisals[i].subjectToSee ? "Yes" : appraisals[i].subjectToSee === false ? "No" : "";
                appraisals[i]["appraisalStatus"] = getStatus(appraisals[i].status);
                appraisals[i]["salesManager"] = getName(appraisals[i].deal.salesManager);
                if (appraisals[i].vehicle && appraisals[i].vehicle.vin)
                    appraisals[i]["vin"] = appraisals[i].vehicle.vin;
            }
            setAppraisalData(appraisals);
        } else {
            onError('Failed to Fetch the Data.');
        }
    };

    const getName = (person) => {
        if (person) {
            return person.firstName + ' ' + person.lastName;
        }
        return '';
    };

    const getStatus = (id) => {
        let item = masterDropdownValues.tradeInStatus.filter(x => x.key === id);
        if (item.length === 1) {
            return item[0].text;
        }
        return '';
    };

    useEffect(() => {
        if (saveFormOnRoute) {
            appraisalFormik.handleSubmit();
            if (tradeInsNote && tradeInsNote.length > 0) {
                setNoteFlag(true);
            }
            if (!appraisalFormik.dirty || !appraisalFormik.isValid) {
                dismissSaveNot('no');
            }
        }
    }, [saveFormOnRoute]);// eslint-disable-line react-hooks/exhaustive-deps

    const bidderBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Higher Bidder</span>
                {rowData.appraiser ? rowData.higherBidder : <span style={{ color: "#AF2B01" }}> {rowData.higherBidder} </span>}
            </React.Fragment>
        );
    };

    const subjectBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Subject to See</span>
                {rowData.appraiser ? rowData.subject : <span style={{ color: "#AF2B01" }}> {rowData.subject} </span>}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.appraiser ? rowData.appraisalStatus : <span style={{ color: "#AF2B01" }}> {rowData.appraisalStatus} </span>}
            </React.Fragment>
        );
    };

    const appraisalDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appraisal Date</span>
                {rowData.appraiser ?
                    moment(new Date(rowData.appraisalDate)).local().format("MM/DD/YYYY") :
                    <span style={{ color: "#AF2B01" }}> {moment(new Date(rowData.appraisalDate)).local().format("MM/DD/YYYY")} </span>}
            </React.Fragment>
        );
    };

    const appraiserBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appraiser</span>
                {rowData.appraiser}
            </React.Fragment>
        );
    };

    const vinBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vin</span>
                {rowData.vin}
            </React.Fragment>
        );
    };

    const salesManagerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Mgr</span>
                {rowData.appraiser ? rowData.salesManager : <span style={{ color: "#AF2B01" }}> {rowData.salesManager} </span>}
            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {userCredentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={userCredentials}
                        itemId={rowData.id}
                        onEdit={(tradeInId) => {
                            updateSectionIndex(2);
                            handleGetTradeInsDetail(tradeInId);
                        }}
                    />
                )}


            </React.Fragment>
        );
    };

    const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Year</span>
                {rowData.year}
            </React.Fragment>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Model</span>
                {rowData.model}
            </React.Fragment>
        );
    };

    const rollBackBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Rollback</span>
                {rowData.rollBack}
            </React.Fragment>
        );
    };

    const table_columns = {
        "appraisalDate": "Appraisal Date",
        "appraiser": "Appraiser",
        "vin": "Vin",
        "year": "Year",
        "model": "Model",
        "rollBack": "Rollback",
        "subject": "Subject to See",
        "salesManager": "Sales Mgr",
        "higherBidder": "Higher Bidder",
        "appraisalStatus": "Status",
        "settings": "Actions"
    }

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            updateSectionIndex(2);
            handleGetTradeInsDetail(event.data.id);
        }
    };

    const handleGetTradeInsDetail = (tradeInId) => {
        resetState();
        setAppraisalItemId(tradeInId)
        dispatch<any>(handleGetAppraisalInfo({
            dealId, appraisalId: tradeInId, callback: (res) => {
                if (!res) {
                    onError('Failed to Fetch the Data.');
                    window.scrollTo(0, 0);
                } else if (!res.noContentStatus) {
                    getTradeInNotesDetails();
                    dispatch<any>(handleGetAppraisalTable(dealId));
                    onFormikChange(1, 1);
                    setAppraisalFormik(res);
                }
            }
        }));
    }

    const header = (
        <>
            <Link
                color="inherit"
                onClick={() => {
                    resetAppointmentFormik();
                    setAppraisalItemId(null);
                    resetState();
                    setNoteFlag(false);
                    setSectionIndex(2);
                    updateSectionIndex(2);
                }}
                style={{fontSize: "16px" }}
            >
                <AddCircle
                    style={{ color: "#70af60" }}
                    className="plus-icon svgPlus-icon" />
                Create Trade-in
            </Link>

        </>
    );

    const handleShowGrid = () => {
        //dispatch<any>(handleGetAppraisalsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
        setSectionIndex(1);
        dismissSaveNot('yes');
        updateSectionIndex(1);
    }

    const handleDeleteGrid = () => {
        setIsConfirmBoxOpen(true)
    }

    const resetState = () => {
        setTradeInVehicleData(null);
        setTradeInsNote(null);
        setAppraisedTableData(null);
        setTradeInsNoteError(null);
        setNotesList(null);
        setNoteFlag(false);
    }

    const appraisalFormik = useFormik({
        initialValues: {
            appraisalConditions: "",
            subjectToSee: "",
            status: 6,
            appraisalBidderId: "",
            color: "",
            odo: ""
        },
        validationSchema: Yup.object({
            appraisalConditions: Yup.string(),
            subjectToSee: Yup.string(),
            status: Yup.string().required("Required"),
            appraisalBidderId: Yup.string(),
            color: Yup.string(),
            odo: Yup.string()
        }),
        onSubmit: (values) => {
            if (values.subjectToSee === '') {
                delete values.subjectToSee;
            }
            dispatch<any>(handleSaveAppraisal({
                appraisal: values, dealId, callback: (res) => {
                    if (res) {
                        dismissSaveNot('yes');
                        if (noteFlag) {
                            saveTradeInNotesDetails();
                        }
                        //dispatch<any>(handleGetAppraisalsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
                        setSectionIndex(1);
                        updateSectionIndex(1);
                    } else {
                        onError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    }
                }
            }));
        }
    });


    const handleAppraisalFormikSumbit = () => {
        onError('');
        appraisalFormik.handleSubmit();
    }

    const setAppraisalFormik = (data) => {
        let newObj = {
            "appraisalConditions": data.appraisalConditions,
            "subjectToSee": data.subjectToSee,
            "status": data.status,
            "appraisalBidderId": data.appraisalBidder?.id,
            "odo": data.odo,
            "color": data.color
        }
        appraisalFormik.setValues(
            {
                ...appraisalFormik.values,
                ...newObj
            });
        setSectionIndex(2);
        //updateSectionIndex(2);
        // setNewTradeIn(false);
    };

    const handleAppraisalFormikDDChange = (e: { value: any, target: any }) => {
        appraisalFormik.setValues(
            {
                ...appraisalFormik.values,
                [e.target.id]: e.value,
            }
        );
        onFormikChange(1, 2);
    }

    const resetAppointmentFormik = () => {
        appraisalFormik.setValues(
            {
                ...appraisalFormik.values,
                appraisalConditions: "",
                subjectToSee: "",
                status: 6,
                appraisalBidderId: "",
                odo: "",
                color: "",
            });
    };

    const saveTradeInNotesDetails = () => {
        if (tradeInsNote && tradeInsNote.length > 0) {
            setTradeInsNoteError(false);
            setTradeInsNote(null)
            dispatch<any>(HandleSaveDealsTradeInNotesUsingID({
                dealId,
                details: {
                    notes: tradeInsNote
                },
                callback: (response) => {
                    if (response) {
                        getTradeInNotesDetails();
                        onFormikChange(1, 1);
                    }
                    else
                        onError('Failed to Save the Data.');
                }
            }));
        } else {
            setTradeInsNoteError(true);
        }
    }

    const dateTradeInBodyTemplate = (rowData) => {
        const tradeInDate = rowData.createdAt ? convertUTCToLocalDateOnlyModified(rowData.createdAt) : '';

        return (
            <React.Fragment>
                <span className="p-column-title">Log</span>
                {tradeInDate}
            </React.Fragment>
        );
    };

    const rollbackTradeInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Roll Back</span>
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                    {currencyNegValFractionalFormat(rowData.rollback)}
                </div>

            </React.Fragment>
        );
    };

    const acrTradeInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">AVC</span>
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                    {currencyNegValFractionalFormat(rowData.acv)}
                </div>

            </React.Fragment>
        );
    };

    const appraisalByTradeInBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appraised By</span>
                <div style={{ textAlign: "left", marginLeft: "20px" }}>
                    {rowData.empName}
                </div>

            </React.Fragment>
        );
    };

    const appraisal_columns_with_acv = {
        "createdAt": "Date",
        "rollback": "Roll Back",
        "acv": "ACV",
        "empName": "Adjusted By",
    };
    const appraisal_columns_without_acv = {
        "createdAt": "Date",
        "rollback": "Roll Back",
        "empName": "Adjusted By",
    };

    const navtoOpenAppraisedValueDialog = () => {
        setAppraisedData(emptyAppraisalData);
        setisAddAppraisalDialog(true)
    };

    const handleAppraisalDialogDismiss = (msg) => {

        if (msg && (typeof msg === 'string' || msg instanceof String)) {
            onError(msg);
        }
        setisAddAppraisalDialog(false);
    };

    const handleNoteChange = (e, newValue) => {
        setTradeInsNote(newValue);
        onFormikChange(1, 2);
    }

    const handleConditionsChange = (e, newValue) => {
        appraisalFormik.setValues(
            {
                ...appraisalFormik.values,
                appraisalConditions: newValue,
            });
        onFormikChange(1, 2);
    }

    const getTradeInNotesDetails = () => {
        dispatch<any>(handleGetDealNotes({
            dealId,
            callback: (response) => {
                if (response)
                    setNotesList(response);
                else
                    onError('Failed to Save the Data.');
            }
        }));
    }

    useEffect(() => {
        if (sectionIndex === 2) {
            getTradeInNotesDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionIndex])

    return (
        <>
            {isAddAppraisalDialog ?
                <AddUpdateAppraisalDialog
                    //onDismiss={() => setisAddAppraisalDialog(false)}
                    onDismiss={(msg) => handleAppraisalDialogDismiss(msg)}
                    formData={appraisedData}
                    dealId={dealId}
                    elementPermission={tradeInElePermission}
                />
                : null}

            {isConfirmBoxOpen ? <ConfirmDeleteDialog
                //loading={loading}
                description="Confirm Delete?"
                isOpen={isConfirmBoxOpen}
                onAction={() => dispatch<any>(handleDeleteAppraisalItem({
                    dealId, appraisalId: appraisalItemId, callback: (res) => {
                        if (!res) {
                            onError('Failed to delete the Trade-Ins.');
                        }
                        setIsConfirmBoxOpen(false);
                        setSectionIndex(1);
                        dismissSaveNot('yes');
                        updateSectionIndex(1)
                        setAppraisalItemId(null);
                    }
                }))}
                onDismiss={() => setIsConfirmBoxOpen(false)}
            /> : null}

            {
                sectionIndex === 1 && (
                    <>
                        <div className="datatable-filter-demo noMaxWidthTable trade-in-workboook"> {/* locations */}
                            <div className="card">
                                <CustomTable
                                    column_list={table_columns}
                                    data={appraisalData}
                                    rows={25}
                                    rowsPerPageOptions={[25, 50, 100]}
                                    header={header}
                                    customTemplates={{
                                        "appraisalDate": appraisalDateBodyTemplate,
                                        "appraiser": appraiserBodyTemplate,
                                        "vin": vinBodyTemplate,
                                        "year": yearBodyTemplate,
                                        "model": modelBodyTemplate,
                                        "rollBack": rollBackBodyTemplate,
                                        "appraisalStatus": statusBodyTemplate,
                                        "higherBidder": bidderBodyTemplate,
                                        "subject": subjectBodyTemplate,
                                        "salesManager": salesManagerBodyTemplate,
                                        "settings": settingBodyTemplate,
                                    }}
                                    emptyMessage="No data found."
                                    onRowClick={(event) => handleRowClick(event)} />
                            </div>
                        </div>
                    </>)
            }
            {
                sectionIndex === 2 && (<>
                    <div className="dealTracker-tradein">
                        <Row>
                            <Col>
                                {
                                    tradeInVehicleData ? <>
                                        <Row className="readOnlyFieldRowHead">
                                            <Col>
                                                {
                                                    tradeInVehicleData.vin ? <>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <div>
                                                                <strong>{tradeInVehicleData.vin}</strong>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                                <div className="font-color-1">
                                                    {tradeInVehicleData.year} &nbsp;&nbsp; {tradeInVehicleData.make} &nbsp;&nbsp; {tradeInVehicleData.model} &nbsp;&nbsp; {tradeInVehicleData.style}
                                                </div>
                                            </Col>
                                        </Row>
                                    </> :
                                        <>
                                            <Row className="readOnlyFieldRowHead rowHeadPadding" >
                                                <Col>
                                                    <strong className="tradeVehicle">Trade-in Vehicle</strong>
                                                    <PrimaryButton
                                                        disabled={!(user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (dealTrackerOperationPermissions.DEAL_TRADE_IN_ADD_VEHICLE?.toString())) : isUserMaster(user.roles))}
                                                        onClick={() => { onCreateTardeIn(true) }}>Add Trade-in Vehicle</PrimaryButton>
                                                </Col>
                                            </Row>
                                        </>
                                }
                                <div>
                                    <Row justify="center">
                                        <Col>
                                            <div>
                                                {
                                                    isMobile ?
                                                        <>

                                                            <Row className="rowSpace btw-space">
                                                                <Col xs={12}>
                                                                    <label className="lbl-text required-Label">Status</label>
                                                                    <PRDropdown
                                                                        id="status"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.status}
                                                                        options={masterDropdownValues.tradeInStatus}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="text"
                                                                        optionValue="key"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.touched && appraisalFormik.touched.status &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.status &&
                                                                            appraisalFormik.errors.status.toString()
                                                                        }
                                                                    </span>
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                            <Row className="rowSpace" justify="center">
                                                                <Col xs={12}>
                                                                    <label className="lbl-text">Subject to See</label>
                                                                    <PRDropdown
                                                                        id="subjectToSee"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.subjectToSee}
                                                                        options={masterDropdownValues.subjectToSeeOptions}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="text"
                                                                        optionValue="key"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.touched && appraisalFormik.touched.subjectToSee &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.subjectToSee &&
                                                                            appraisalFormik.errors.subjectToSee.toString()
                                                                        }
                                                                    </span>
                                                                </Col>
                                                                {/*<Col xs={12} className="appraisal-box">
                                                                    <OffTextField
                                                                        id="appraisalConditions"
                                                                        name="appraisalConditions"
                                                                        label="Appraisal Conditions"
                                                                        multiline
                                                                        resizable={false}
                                                                        className="full-width appraisal-conditions fullHeight"
                                                                        value={appraisalFormik.values.appraisalConditions}
                                                                        onChange={(e,val) => handleConditionsChange(e,val)}
                                                                        onBlur={appraisalFormik.handleBlur}
                                                                        errorMessage={
                                                                            appraisalFormik.touched.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions.toString()
                                                                        }
                                                                    />
                                                                </Col>*/}
                                                                <Col></Col>
                                                            </Row>
                                                            <Row className="rowSpace btw-space">
                                                                <Col xs={12}>
                                                                    <label className="lbl-text">Higher Bidder</label>
                                                                    <PRDropdown
                                                                        id="appraisalBidderId"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.appraisalBidderId}
                                                                        options={appraisalBidders}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="name"
                                                                        optionValue="id"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown textAlign-Vertical"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                            <Row className="rowSpace btw-space">
                                                                <Col xs={12}>
                                                                    <label className="lbl-text">Odo</label>
                                                                    <CurrencyInput
                                                                        customInput={TextField}
                                                                        // thousandSeparator={true}
                                                                        id="odo"
                                                                        name="odo"
                                                                        //label="Odo"
                                                                        className=" mainQValuPageSec"
                                                                        decimalScale={0}
                                                                        value={appraisalFormik.values.odo?appraisalFormik.values.odo.replace(/,/g, ''):""}
                                                                        onValueChange ={(e) => {
                                                                            appraisalFormik.setValues(
                                                                                {
                                                                                    ...appraisalFormik.values,
                                                                                    "odo": e
                                                                                }
                                                                            );
                                                                        }}
                                                                        onBlur={appraisalFormik.handleBlur}

                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.errors.odo &&
                                                                            appraisalFormik.errors.odo.toString()}
                                                                    </span>
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                            <Row className="rowSpace btw-space">
                                                                <Col xs={12}>
                                                                    <TextField
                                                                        id="color"
                                                                        name="color"
                                                                        label="Color"
                                                                        placeholder=""
                                                                        autoComplete="off"
                                                                        className="mainQValuPageSec"
                                                                        value={appraisalFormik.values.color}
                                                                        //required={true}
                                                                        onChange={appraisalFormik.handleChange}
                                                                        onBlur={appraisalFormik.handleBlur}
                                                                        errorMessage={
                                                                            appraisalFormik.touched && appraisalFormik.touched.color &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.color &&
                                                                            appraisalFormik.errors.color.toString()
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                            <Row className="rowSpace btw-space">
                                                                <Col xs={12} className="appraisal-box">
                                                                    <OffTextField
                                                                        id="appraisalConditions"
                                                                        name="appraisalConditions"
                                                                        label="Appraisal Conditions"
                                                                        multiline
                                                                        resizable={false}
                                                                        className="full-width appraisal-conditions fullHeight"
                                                                        value={appraisalFormik.values.appraisalConditions}
                                                                        onChange={(e, val) => handleConditionsChange(e, val)}
                                                                        onBlur={appraisalFormik.handleBlur}
                                                                        errorMessage={
                                                                            appraisalFormik.touched.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions.toString()
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col></Col>
                                                            </Row>
                                                        </> :
                                                        <>
                                                            <Row justify="center">
                                                                <Col>

                                                                    <label className="lbl-text required-Label">Status</label>
                                                                    <PRDropdown
                                                                        id="status"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.status}
                                                                        options={masterDropdownValues.tradeInStatus}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="text"
                                                                        optionValue="key"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.touched && appraisalFormik.touched.status &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.status &&
                                                                            appraisalFormik.errors.status.toString()
                                                                        }
                                                                    </span>
                                                                    <label className="lbl-text ">Subject to See</label>
                                                                    <PRDropdown
                                                                        id="subjectToSee"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.subjectToSee}
                                                                        options={masterDropdownValues.subjectToSeeOptions}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="text"
                                                                        optionValue="key"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.touched && appraisalFormik.touched.subjectToSee &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.subjectToSee &&
                                                                            appraisalFormik.errors.subjectToSee.toString()
                                                                        }
                                                                    </span>
                                                                    <label className="lbl-text">Higher Bidder</label>
                                                                    <PRDropdown
                                                                        id="appraisalBidderId"
                                                                        placeholder=""
                                                                        value={appraisalFormik.values.appraisalBidderId}
                                                                        options={appraisalBidders}
                                                                        onChange={handleAppraisalFormikDDChange}
                                                                        optionLabel="name"
                                                                        optionValue="id"
                                                                        // appendTo="self"
                                                                        className="custom-p-dropdown textAlign-Vertical"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                    />
                                                                    <label className="lbl-text">Odo</label>
                                                                    <CurrencyInput
                                                                        customInput={TextField}
                                                                        // thousandSeparator={true}
                                                                        id="odo"
                                                                        name="odo"
                                                                        //label="Odo"
                                                                        className=" mainQValuPageSec"
                                                                        decimalScale={0}
                                                                        value={appraisalFormik.values.odo?appraisalFormik.values.odo.replace(/,/g, ''):""}
                                                                        onValueChange ={(e) => {
                                                                            appraisalFormik.setValues(
                                                                                {
                                                                                    ...appraisalFormik.values,
                                                                                    "odo": e
                                                                                }
                                                                            );
                                                                        }}
                                                                        onBlur={appraisalFormik.handleBlur}

                                                                    />
                                                                    <span className="error-message">
                                                                        {
                                                                            appraisalFormik.errors.odo &&
                                                                            appraisalFormik.errors.odo.toString()}
                                                                    </span>
                                                                    <TextField
                                                                        id="color"
                                                                        name="color"
                                                                        label="Color"
                                                                        placeholder=""
                                                                        autoComplete="off"
                                                                        className="mainQValuPageSec"
                                                                        value={appraisalFormik.values.color}
                                                                        //required={true}
                                                                        onChange={appraisalFormik.handleChange}
                                                                        onBlur={appraisalFormik.handleBlur}
                                                                        errorMessage={
                                                                            appraisalFormik.touched && appraisalFormik.touched.color &&
                                                                            appraisalFormik.errors && appraisalFormik.errors.color &&
                                                                            appraisalFormik.errors.color.toString()
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col className="appraisal-box">
                                                                    <OffTextField
                                                                        id="appraisalConditions"
                                                                        name="appraisalConditions"
                                                                        label="Appraisal Conditions"
                                                                        multiline
                                                                        resizable={false}
                                                                        className="full-width full-width-height fullHeight"
                                                                        value={appraisalFormik.values.appraisalConditions}
                                                                        onChange={(e, val) => handleConditionsChange(e, val)}
                                                                        onBlur={appraisalFormik.handleBlur}
                                                                        errorMessage={
                                                                            appraisalFormik.touched.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions &&
                                                                            appraisalFormik.errors.appraisalConditions.toString()
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                                <Row justify="center">
                                                    <Col>
                                                        <div className="datatable-filter-mobTable table-section" >
                                                            <div className="card  p-datatable-customers-list" >
                                                                <CustomTable
                                                                    column_list={(writeUpsId && writeUpsId > 0) || tradeInElePermission.acv >= 1 ? appraisal_columns_with_acv : appraisal_columns_without_acv}
                                                                    //paginator={false}
                                                                    data={appraisedtTableData}
                                                                    customTemplates={{
                                                                        "createdAt": dateTradeInBodyTemplate,
                                                                        "rollback": rollbackTradeInBodyTemplate,
                                                                        "acv": acrTradeInBodyTemplate,
                                                                        "empName": appraisalByTradeInBodyTemplate,
                                                                    }}
                                                                    emptyMessage="No data found."
                                                                //onRowClick={(event)=> handleRowClick(event)}
                                                                />
                                                            </div>
                                                            <div style={{ textAlign: "right", margin: "-20px 15px 10px" }}>
                                                                <Link
                                                                    disabled={diasbleValuationBtn}
                                                                    color="inherit"
                                                                    onClick={navtoOpenAppraisedValueDialog}>
                                                                    <AddCircle className={classes.icon + " circle-icon "} />
                                                                    Appraised Value
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div style={{ width: "auto", display: "flex" }}>
                                                    <div className="deleteBtnTradeIn" style={{ margin: " 20px 0px 20px 0px" }}>
                                                        {
                                                            appraisalItemId ?
                                                                <PrimaryButton
                                                                    className="deleteDialogDelBut"
                                                                    disabled={diasbleDeleteTradeInBtn}
                                                                    onClick={handleDeleteGrid}>Delete Trade-in
                                                                </PrimaryButton>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="cancelBtnTradeIn" style={{ margin: " 20px 0px 20px 0px" }}>

                                                        <PrimaryButton style={{ float: "left" }} className="tradeInCancelBtn" onClick={handleShowGrid}>Cancel</PrimaryButton>

                                                        <PrimaryButton
                                                            // disabled={!appraisalFormik.dirty || !appraisalFormik.isValid}
                                                            onClick={handleAppraisalFormikSumbit}
                                                            style={{ float: "right" }}
                                                        >
                                                            Save
                                                        </PrimaryButton>



                                                    </div>
                                                </div>



                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                <div>
                                    <Row>
                                        <Col>
                                            <Accordion className="inventory-filter-accordion" defaultExpanded>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1c-content"
                                                    id="panel1c-header"
                                                //style={{ paddingTop: "0px" }}
                                                >
                                                    <div className={classes.column + " notesdiv-pad writeUpsFont "} >
                                                        <Typography component={'h3'} className={classes.heading}>Notes</Typography>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.details}>
                                                    <Row>
                                                        <Col className="notesSection">
                                                            <OffTextField
                                                                id="tradeInsNote"
                                                                name="tradeInsNote"
                                                                label=""
                                                                multiline
                                                                resizable={false}
                                                                className="full-width fontWeightSection"
                                                                style={{ fontSize: "14px", fontWeight: 600 }}
                                                                value={tradeInsNote}
                                                                onChange={(e, newValue) => handleNoteChange(e, newValue)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {
                                                        tradeInsNoteError ?
                                                            <div className="">
                                                                <Row>
                                                                    <Col>
                                                                        <span className="genericErrorColor"><span>Please enter details</span></span>
                                                                    </Col>
                                                                </Row>
                                                            </div> : null
                                                    }

                                                    <Row className="rowPaddingBtwSpace">
                                                        <Col className="text-right">
                                                            <PrimaryButton style={{ marginTop: 10 }} onClick={() => saveTradeInNotesDetails()}>Add Note</PrimaryButton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h3 style={{ border: "none" }} className="writeUpsFont">History</h3>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <hr />
                                                            {notesList?.map((item) => (

                                                                <div key={"boxes-" + item.id}>
                                                                    <Row className="rowSpaceNotes rowSpace" style={{ padding: "0 20px" }}>
                                                                        <Col md={3} xs={4}>
                                                                            <div className="writeUpsNoteText">{moment(new Date(item.createdAt)).local().format("MM/DD/YYYY").toString()}</div>
                                                                            <div className="writeUpsNoteText">{item?.firstName} {item?.lastName}</div>
                                                                        </Col>
                                                                        <Col md={3} xs={4}>
                                                                            <div className="writeUpsNoteText">{dealType[item?.type]}</div>

                                                                        </Col>
                                                                        <Col md={6} xs={4} className="writeUpsNoteText">{item.note}</Col>
                                                                    </Row>
                                                                    <hr />
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>)
            }

        </>
    );
};

