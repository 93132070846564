import React, { useState, useEffect } from 'react';
import {
    PrimaryButton,
    TextField,
    Link,
    TextField as OffTextField
} from "@fluentui/react";
import { Col, Row } from "react-grid-system";
import CurrencyInput from 'react-currency-input-field'
import { PRICE_PREFIX } from "constants/constants";
import { Calendar } from 'primereact/calendar';
import masterDropdownValues from "constants/masterDropdownValues";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { convertLocalToUTCModified, convertUTCToLocalDateModified, convertUTCToLocalDateOnlyModified, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store";
import { HANDLE_GET_EMPLOYEES_FINANCE } from "store/employees/employees.types";
import {
    handleSaveCancellation,
    handleGetCancellationsByDeal,
    handleGetCancellationInfo,
    handlePrintDealCancellation,
    handlePrintDealCancellationListItem
} from "store/dealTracker/dealTracker.action";
//import { handleGetSelectList } from "store/selectList/selectList.action";
import CustomTable from "components/table/datatable/CustomTable";
import { EditPrintDocsSubmenu } from "components/table/dropdowns/EditPrintDocsSubmenu";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import AddCircle from '@mui/icons-material/AddCircle';

interface IProps {
    onFormikChange: (initial, newData) => void;
    saveFormOnRoute: boolean;
    dismissSaveNot: (msg) => void;
    formData?: any;
    userCredentials: any;
    user: any;
    dealId: string;
    onError: (msg) => void;
    updateSectionIndex: (id) => void;
    setSectionIndx: any;
    productId:any;
    tabRoute: boolean;
}

export const Cancellation: React.FunctionComponent<IProps> = ({
    onFormikChange,
    saveFormOnRoute,
    dismissSaveNot,
    userCredentials,
    user,
    dealId,
    onError,
    updateSectionIndex,
    setSectionIndx,
    productId,
    tabRoute

}) => {

    //const dealTrackerData = useSelector((state: AppState) => state.dealTracker.selectedDealRecord);

    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const [refundDate, setRefundDate] = React.useState<Date | Date[] | undefined |any>(undefined);
    const [cancelDate, setCancelDate] = React.useState<Date | Date[] | undefined |any>(undefined);
    const financeProducts = useSelector((state: AppState) => state.selectList.financeProducts) as any;
    const employeesFinance = useSelector((state: AppState) => state.employees.employeesFinance) as any;
    const [finance1, setfinance1] = React.useState([]);
    const lenders = useSelector((state: AppState) => state.selectList.lenders) as any;
    const [sectionIndex, setSectionIndex] = React.useState(null);
    const [tableData, setTableData] = useState([]);
    const [financeProductsList, setfinanceProductsList] = React.useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
         if(productId && ! tabRoute){
             handleGetCancellationDetail(productId);

         }else{  
             //dispatch<any>(handleGetCancellationsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
             setSectionIndex(1);
             updateSectionIndex(1);
         }
        
        //dispatch<any>(handleGetSelectList("financeProducts,lenders", ""));
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES_FINANCE, payload: { isActive: 1, role: "financePerson" } });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSectionIndex(setSectionIndx);
        if (setSectionIndx === 1)
            dispatch<any>(handleGetCancellationsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
            
    }, [setSectionIndx]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(financeProducts) {
            setfinanceProductsList(financeProducts);
        }
    }, [financeProducts]);

    useEffect(() => {
        if (saveFormOnRoute) {
            cancellationFormik.handleSubmit();
            if (!cancellationFormik.dirty || !cancellationFormik.isValid) {
                dismissSaveNot('no');
            }
        }
    }, [saveFormOnRoute]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateTableData = (res) => {
        if (res && res.results) {
            let workbook = []
            res.results.forEach(function (item, index) {
                workbook.push({
                    logDate: item.createdAt,
                    productName: item.financeProduct?.name,
                    cancelDate: convertUTCToLocalDateModified(item.cancelDate),
                    method: getMethodName(item.refundMethod1, item.refundMethod2),
                    financePersonCancelling: getFinancePersonName(item.financePersonCancelling),
                    refundAmount: item.refundAmount ? currencyNegValFractionalFormat(item.refundAmount) : null,
                    refundDate: item.refundDate ? convertUTCToLocalDateModified(item.refundDate) : null,
                    id: item.id
                });
            });
            setTableData(workbook);

        } else {
            onError('Failed to Fetch The Data.');
        }
    };


    const getMethodName = (method1, method2) => {
        if (method1 === 1) {
            if (method2 === 1)
                return 'Applied to Deal';
            if (method2 === 2)
                return 'Karl to Customer';
            if (method2 === 3)
                return 'Karl to Lender';
        }
        else if (method1 === 2){
            return 'Product Co. to Customer';
        }
        else if (method1 === 3) {
            return 'Product Co. to Lender';
        }
        else if (method1 === 4) {
            return 'Lender Debits Dealer';
        }
        return '';
    };

    const getFinancePersonName = (person) => {
        if (person) {
            return person?.firstName + ' ' + person?.lastName;
        }
        return '';
    }

    const cancellationFormik = useFormik({
        initialValues: {
            id: '',
            financeProductId: '',
            financePersonCancellingId: "",    
            cancelDate: "",
            cancellationMiles: "",
            refundDate: "",
            refundAmount: "",
            refundMethod1: '',
            refundMethod2: '',
            lenderId: "",
            lenderCancellationAddressLine1: "",
            lenderCancellationAddressLine2: "",
            loanNumber: "",
            customerAddressLine1: "",
            customerAddressLine2: "",
            proofLoanPaidOff: ""
        },
        validationSchema: Yup.object({

            financeProductId: Yup.string().required("Required"),
            financePersonCancellingId: Yup.string().required("Required"),
            cancelDate: Yup.string().required("Required"),
            cancellationMiles: Yup.string().matches(/^\d+$/, 'The field should have digits only').max(6, 'cancellationMiles field should have 6 digits only').required("Required"),
            refundDate: Yup.string(),
            refundAmount: Yup.string(),
            refundMethod1: Yup.string().required("Required"),
            refundMethod2: Yup.string(),
            lenderId: Yup.string().when('refundMethod2',
                {
                    is: '3',
                    then: Yup.string().required("Required"),
                    otherwise: Yup.string()
                }),
            lenderCancellationAddressLine1: Yup.string()
                .when('refundMethod2',
                    {
                        is: '3',
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string()
                    }),
            lenderCancellationAddressLine2: Yup.string()
                .when('refundMethod2',
                    {
                        is: '3',
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string()
                    }),
            loanNumber: Yup.string()
                .when('refundMethod2',
                    {
                        is: '3',
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string()
                    }),
            customerAddressLine1: Yup.string()
                .when('refundMethod2',
                    {
                        is: '2',
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string()
                    }),
            customerAddressLine2: Yup.string()
                .when('refundMethod2',
                    {
                        is: '2',
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string()
                    }),
            proofLoanPaidOff: Yup.string(),
            id: Yup.string()

        }),
        onSubmit: (values) => {
            console.log("values",values)
            const data = {
                "id": values.id,
                "financeProductId": values.financeProductId,
                "financePersonCancellingId": values.financePersonCancellingId,
                "cancelDate": values.cancelDate ? convertLocalToUTCModified(values.cancelDate) : '',
                "cancellationMiles": values.cancellationMiles,
                "refundDate": values.refundDate ? convertLocalToUTCModified(values.refundDate) : '',
                "refundAmount": values.refundAmount?.replace('$', '').replace(',', ''),
                "refundMethod1": parseInt(values.refundMethod1),
                "refundMethod2": parseInt(values.refundMethod2),
                "lenderId": values.lenderId,
                "lenderCancellationAddressLine1": values.refundMethod2 && values.refundMethod2 === '3' ? values.lenderCancellationAddressLine1 : '',
                "lenderCancellationAddressLine2": values.refundMethod2 && values.refundMethod2 === '3' ? values.lenderCancellationAddressLine2 : '',
                "loanNumber": values.loanNumber,
                "customerAddressLine1": values.refundMethod2 && values.refundMethod2 === '2' ? values.customerAddressLine1 : '',
                "customerAddressLine2": values.refundMethod2 && values.refundMethod2 === '2' ? values.customerAddressLine2 : '',
                "proofLoanPaidOff": values.proofLoanPaidOff
            };
            if (data.refundDate === "") {
                delete data.refundDate
            }
            if (data.refundAmount === "") {
                delete data.refundAmount
            }
            if (data.proofLoanPaidOff === "") {
                delete data.proofLoanPaidOff
            }
            dispatch<any>(handleSaveCancellation({
                formData: data, dealId, callback: (res) => {
                    if (res) {
                        //dispatch<any>(handleGetCancellationsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
                        dismissSaveNot('yes');
                        onFormikChange(1, 1);
                        setSectionIndex(1);
                        updateSectionIndex(1);
                    } else {
                        dismissSaveNot('no');
                        onError('Failed to Save the Data.');
                        window.scrollTo(0, 0);
                    }
                }
            }));
        }
    });

    useEffect(() => {
        setfinance1([]);

        let finance = [];
        if (employeesFinance.results.length > 0) {
            employeesFinance.results.map((item: any) => {
                return finance.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            setfinance1(finance)

        }

    }, [employeesFinance]);  // eslint-disable-line react-hooks/exhaustive-deps

    //useEffect(() => {
    //    if (dealTrackerData && sectionIndex === 2) {
    //        if ((dealTrackerData.financePerson1 && dealTrackerData.financePerson1.id === user.id) ||
    //            (dealTrackerData.financePerson2 && dealTrackerData.financePerson2.id === user.id)) {
    //            cancellationFormik.setValues(
    //                {
    //                    ...cancellationFormik.values,
    //                    'financePersonCancellingId': user.id,
    //                }
    //            );
    //        } else {
    //            cancellationFormik.setValues(
    //                {
    //                    ...cancellationFormik.values,
    //                    'financePersonCancellingId': '',
    //                }
    //            );
    //        }
    //    }
    //}, [dealTrackerData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDDChange = (e: { value: any, target: any }) => {
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                [e.target.id]: e.value,
            }
        );
        onFormikChange(1, 2);
    }

    const handleCancelDateChange = (val) => {
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                "cancelDate": moment(val).format("YYYY-MM-DD")
            }
        );
        setCancelDate(val);
        onFormikChange(1, 2);
    };

    const handleRefundDateChange = (val) => {
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                "refundDate": moment(val).format("YYYY-MM-DD")
            }
        );
        setRefundDate(val);
        onFormikChange(1, 2);
    };

    const handleTextFieldChange = (e, newValue) => {
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                [e.target.id]: newValue,
            }
        );
        onFormikChange(1, 2);
    };

    const handleFormikSumbit = () => {
        onError('');
        cancellationFormik.handleSubmit();
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
                {userCredentials && user.roles && (
                    <EditPrintDocsSubmenu
                        modulePermissions={userCredentials}
                        itemId={rowData.id}
                        onEdit={(id) => {
                            updateSectionIndex(2);
                            handleGetCancellationDetail(id);
                        }}
                        onPrint={(id) => handlePrintCancellationListItem(id)}
                    />
                )}


            </React.Fragment>
        );
    };

    const logDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log Date</span>
                {moment(new Date(rowData.logDate)).local().format("MM/DD/YYYY")}
            </React.Fragment>
        );
    };

    const productNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product Name</span>
                {rowData.productName}
            </React.Fragment>
        );
    };

    const personCancellingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person Cancelling</span>
                {rowData.financePersonCancelling}
            </React.Fragment>
        );
    };

    const methodBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Method</span>
                {rowData.method}
            </React.Fragment>
        );
    };

    const cancelDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cancel Date</span>
                {rowData.cancelDate}
            </React.Fragment>
        );
    };

    const refundDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Refund Date</span>
                {rowData.refundDate}
            </React.Fragment>
        );
    };

    const refundAmountBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Refund Amount</span>
                {rowData.refundAmount}
            </React.Fragment>
        );
    };

/*
    const header1 = (
        <>
            <Link
                color="inherit"
                onClick={() => {
                    resetCancellationFormik();
                    setSectionIndex(2);
                }}
                style={{ float: "left" }}
            >
                <AddCircle
                    style={{ color: "#70af60", }}
                    className="plus-icon svgPlus-icon" />
					Create Cancellation
			</Link>

        </>
    ); */

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            updateSectionIndex(2);
            handleGetCancellationDetail(event.data.id);
        }
    }

    const handleGetCancellationDetail = (cancellationId) => {
        dispatch<any>(handleGetCancellationInfo({
            dealId, cancellationId, callback: (res) => {
                if (res) {
                    onFormikChange(1, 1);
                    setCancellationFormik(res);
                } else {
                    onError('Failed to Fetch the Data.');
                }

            }
        }))
        setSectionIndex(2);
        //updateSectionIndex(2);
    }

    const handlePrintCancellationListItem = (cancellationId) => {
       dispatch<any>(handlePrintDealCancellationListItem({
                dealId,cancellationId, callback: (response) => {
       //            const file = new Blob([response], { type: "application/pdf" });
       // //Build a URL from the file
       // const fileURL = URL.createObjectURL(file);
       // //Open the URL on new Window
       // window.open(fileURL);
               }
            }));
    }

    const setCancellationFormik = (formData) => {
        console.log("formData",formData)
        const newObj = {
            'id': formData.id,
            "financeProductId": formData.financeProduct?.id,
            "financePersonCancellingId": formData.financePersonCancelling?.id,
            "cancelDate": convertUTCToLocalModified(formData.cancelDate),
            "cancellationMiles": formData.cancellationMiles,
            "refundDate":formData.refundDate && convertUTCToLocalModified(formData.refundDate),
            "refundAmount": formData.refundAmount,
            "refundMethod1": '' + formData.refundMethod1,
            "refundMethod2": '' + formData.refundMethod2,
            "lenderId": formData.lender?.id,
            "lenderCancellationAddressLine1": formData.lenderCancellationAddressLine1,
            "lenderCancellationAddressLine2": formData.lenderCancellationAddressLine2,
            "loanNumber": formData.loanNumber,
            "customerAddressLine1": formData.customerAddressLine1,
            "customerAddressLine2": formData.customerAddressLine2,
            "proofLoanPaidOff": formData.proofLoanPaidOff,
        };
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                ...newObj
            });
        setCancelDate(new Date(convertUTCToLocalDateOnlyModified(formData.cancelDate)));
        setRefundDate(new Date(convertUTCToLocalDateOnlyModified(formData.refundDate)));
        setSectionIndex(2);
        //updateSectionIndex(2);
    };

    const resetCancellationFormik = () => {
        cancellationFormik.resetForm();
        const newObj = {
            'id': '',
            "financeProductId": '',
            "financePersonCancellingId": user && user.defaultGroups
                && user.defaultGroups.some(x => x.defaultGroupId === 8)//Finance Person
                && finance1 && finance1.some(x => x.key === user.id) && user.id,//setDefaultFinancePerson(),
            "cancelDate": '',
            "cancellationMiles": '',
            "refundDate": '',
            "refundAmount": '',
            "refundMethod1": '',
            "refundMethod2": '',
            "lenderId": '',
            "lenderCancellationAddressLine1": '',
            "lenderCancellationAddressLine2": '',
            "loanNumber": '',
            "customerAddressLine1": '',
            "customerAddressLine2": '',
            "proofLoanPaidOff": '',
        };
        cancellationFormik.setValues(
            {
                ...cancellationFormik.values,
                ...newObj
            });
        setCancelDate(null);
        setRefundDate(null);
        onFormikChange(1, 1);
    };

    //const setDefaultFinancePerson = () => {
    //    if (user && user.defaultGroups
    //        && user.defaultGroups.some(x => x.defaultGroupId === 8)//Finance Person
    //        && finance1 && finance1.some(x => x.key === user.id)) {
    //        console.log('testing');
    //        return user.id;
    //    }
    //    return '';
    //};

    //const rowClassName = (node) => {
    //    return { 'childRow': (node.key && node.key.includes('-')) };
    //}

    const handleShowGrid = () => {
        //dispatch<any>(handleGetCancellationsByDeal({ dealId, callback: (res) => handlePopulateTableData(res) }));
        setSectionIndex(1);
        dismissSaveNot('yes');
        updateSectionIndex(1);
    }

    const navigatetoPrintDealCancellationDocs = () => {
       dispatch<any>(handlePrintDealCancellation({
                 dealId, callback: (response) => {
       //            const file = new Blob([response], { type: "application/pdf" });
       // //Build a URL from the file
       // const fileURL = URL.createObjectURL(file);
       // //Open the URL on new Window
       // window.open(fileURL);
                }
            }));
    };

    const table_columns = {
        "logDate": "Log Date",
        "productName": "Product Name",
        "financePersonCancelling": "Finance Person Cancelling",
        "method": "Method",
        "cancelDate": "Cancel Date",
        "refundDate": "Refund Date",
        "refundAmount": "Refund Amount",
        "settings": "Actions"
    }

    const hanledCreateCancellation = () => {
        resetCancellationFormik();
        setSectionIndex(2);
        updateSectionIndex(2);
    }


    return (
        <>
            {
                sectionIndex === 1 && (
                    <>
                        <div>
                        {
                                <div style={{ paddingBottom: "30px" }}>
                                    {
                                        tableData && tableData.length > 0 ?
                                            <>
                                                <div>
                                                    <Link color="inherit"
                                                        onClick={hanledCreateCancellation}
                                                        style={{ float: "left" }}
                                                    >
                                                        <AddCircle
                                                            style={{ color: "#70af60", }}
                                                            className="plus-icon svgPlus-icon" />
					                                    Create Cancellation
			                                        </Link>
                                                </div>
                                                <div className="print-Link">
                                                    <Link color="inherit" style={{ float: "right" }} className="font-b-500 add-link add-Printlink" onClick={navigatetoPrintDealCancellationDocs}>
                                                        Print All Docs
                                                    </Link>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    <Link color="inherit"
                                                        onClick={hanledCreateCancellation}>
                                                        <AddCircle
                                                            style={{ color: "#70af60", }}
                                                            className="plus-icon svgPlus-icon" />
					                                        Create Cancellation
			                                        </Link>
                                                </div>
                                            </>
                                    }
                                </div>
                            
                        }

                            <div className="datatable-filter-demo noMaxWidthTable appointment-workbook">
                                <div className="card mouse-hover" >
                                    <CustomTable
                                        column_list={table_columns}
                                        data={tableData}
                                        rows={25}
                                        rowsPerPageOptions={[25, 50, 100]}
                                        customTemplates={{
                                            "logDate": logDateBodyTemplate,
                                            "productName": productNameBodyTemplate,
                                            "financePersonCancelling": personCancellingBodyTemplate,
                                            "method": methodBodyTemplate,
                                            "cancelDate": cancelDateBodyTemplate,
                                            "refundDate": refundDateBodyTemplate,
                                            "refundAmount": refundAmountBodyTemplate,
                                            "settings": settingBodyTemplate
                                        }}
                                        emptyMessage="No cancellation found."
                                        onRowClick={(event) => handleRowClick(event)}
                                    />

                                </div>
                            </div>
                        </div>
                    </>)
            }
            {
                sectionIndex === 2 && (
                    <>
                        <div className=" serviceContractUIWidth cancellationUIWidth">
                            <Row>
                                <Col>
                                    <div>
                                        <div>
                                            <Row className="rowSpace rowSpaceHeight " >
                                                <Col md={6}>
                                                    <label className="lbl-text required-Label">Product Name</label>
                                                    <PRDropdown
                                                        id="financeProductId"
                                                        placeholder=""
                                                        value={cancellationFormik.values.financeProductId}
                                                        onChange={handleDDChange}
                                                        options={financeProductsList}
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        // appendTo="self"
                                                        className="custom-p-dropdown"
                                                        filter={false}
                                                        resetFilterOnHide={true}
                                                        scrollHeight="200px"
                                                    />
                                                    
                                                </Col>
                                                <Col md={6}>
                                                    <label className="lbl-text required-Label">Finance Person Cancelling</label>
                                                    <PRDropdown
                                                        id="financePersonCancellingId"
                                                        placeholder=""
                                                        value={cancellationFormik.values.financePersonCancellingId}
                                                        options={finance1}
                                                        onChange={handleDDChange}
                                                        optionLabel="text"
                                                        optionValue="key"
                                                        // appendTo="self"
                                                        className="custom-p-dropdown"
                                                        filter={true}
                                                        resetFilterOnHide={true}
                                                        scrollHeight="200px"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="rowSpace  rowSpaceHeight   mainQValuPageSec" justify="center"  >
                                                <Col md={6}>
                                                    <div className="ms-TextField">
                                                        <div className="ms-TextField-wrapper">
                                                            <label className="lbl-text required-Label">Cancel Date</label>
                                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                <Calendar
                                                                    id="cancelDate"
                                                                    style={{ width: "100%" }}
                                                                    className="p-datepicker-today"
                                                                    value={cancelDate}
                                                                    showIcon
                                                                    onChange={(e) => handleCancelDateChange(e.value)}
                                                                    // maxDate={new Date()}
                                                                    monthNavigator
                                                                    yearNavigator
                                                                    yearRange={yearRange} />
                                                            </div>
                                                            {/*<span className="error-message">
									                                 			{blurFlag && cancellationFormik.errors && cancellationFormik.errors.dealerLicenseDateOfIssue &&
									                                 				cancellationFormik.errors.dealerLicenseDateOfIssue.toString()}
									                                 		</span>*/}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <label className="lbl-text required-Label">Cancellation Miles</label>
                                                    <TextField
                                                        id="cancellationMiles"
                                                        name="cancellationMiles"
                                                        //label="Cancellation Miles"
                                                        value={cancellationFormik.values.cancellationMiles}
                                                        onChange={(e,val) => handleTextFieldChange(e,val)}
                                                        onBlur={cancellationFormik.handleBlur}
                                                        errorMessage={
                                                            cancellationFormik.touched.cancellationMiles &&
                                                            cancellationFormik.errors.cancellationMiles &&
                                                            cancellationFormik.errors.cancellationMiles.toString()
                                                        } 
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="rowSpace  rowSpaceHeight   mainQValuPageSec" justify="center"  >
                                                <Col md={6}>
                                                    <div className="ms-TextField">
                                                        <div className="ms-TextField-wrapper">
                                                            <label className="lbl-text ">Refund Date</label>
                                                            <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                                                <Calendar
                                                                    id="refundDate"
                                                                    style={{ width: "100%" }}
                                                                    value={refundDate}
                                                                    showIcon
                                                                    onChange={(e) => handleRefundDateChange(e.value)}
                                                                    monthNavigator
                                                                    yearNavigator
                                                                    yearRange={yearRange} />

                                                            </div>
                                                            {/*<span className="error-message">
									                                 			{blurFlag && cancellationFormik.errors && cancellationFormik.errors.dealerLicenseDateOfIssue &&
									                                 				cancellationFormik.errors.dealerLicenseDateOfIssue.toString()}
									                                 		</span>*/}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                   <label className="lbl-text">Refund Amount</label>
                                                    <CurrencyInput
                                                        allowNegativeValue={false}
                                                        customInput={OffTextField}
                                                       // thousandSeparator={true}
                                                        prefix={PRICE_PREFIX}
                                                        id="refundAmount"
                                                        name="refundAmount"
                                                        //label="Refund Amount"
                                                        decimalScale={2}
                                                        value={cancellationFormik.values.refundAmount?cancellationFormik.values.refundAmount.replace(/,/g, ''):""}
                                                        onValueChange ={(e) => {
                                                            cancellationFormik.setValues(
                                                                {
                                                                    ...cancellationFormik.values,
                                                                    "refundAmount": e
                                                                }
                                                            );
                                                        }}
                                                        onBlur={cancellationFormik.handleBlur}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="rowSpace rowSpaceHeight mainQValuPageSec refunddropdown-list" >
                                                <Col md={12}>

                                                    <label className="lbl-text required-Label" >Refund Method 1</label>
                                                    <PRDropdown
                                                        id="refundMethod1"
                                                        placeholder=""
                                                        value={cancellationFormik.values.refundMethod1}
                                                        options={masterDropdownValues.refundMethod1}
                                                        onChange={handleDDChange}
                                                        optionLabel="text"
                                                        optionValue="key"
                                                        // appendTo="self"
                                                        className="custom-p-dropdown showAbove "
                                                        filter={false}
                                                        resetFilterOnHide={true}
                                                        scrollHeight="200px" 
                                                    />
                                                </Col>
                                            </Row>
                                            {(cancellationFormik.values.refundMethod1 === '1') ? <div className="rowSpace rowSpaceHeight mainQValuPageSec refundSection">
                                                <Row className="align-Margin">
                                                    <Col md={6} className="align-colWidth">
                                                        <label className="lbl-text ">Refund Method 2</label>
                                                        <PRDropdown
                                                            id="refundMethod2"
                                                            placeholder=""
                                                            value={cancellationFormik.values.refundMethod2}
                                                            options={masterDropdownValues.refundMethod2}
                                                            onChange={handleDDChange}
                                                            optionLabel="text"
                                                            optionValue="key"
                                                            // appendTo="self"
                                                            className="custom-p-dropdown showAboveList "
                                                            filter={false}
                                                            resetFilterOnHide={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                {
                                                    cancellationFormik.values.refundMethod2 !== '1' && (
                                                        <Row className="align-Margin">
                                                            {
                                                                cancellationFormik.values.refundMethod2 === '3' &&
                                                                (<>
                                                                    <Col md={6} className="align-colWidth">
                                                                        <label className="lbl-text required-Label">Lender</label>
                                                                        <PRDropdown
                                                                            id="lenderId"
                                                                            placeholder=""
                                                                            value={cancellationFormik.values.lenderId}
                                                                            onChange={handleDDChange}
                                                                            options={lenders}
                                                                            optionLabel="name"
                                                                            optionValue="id"
                                                                            // appendTo="self"
                                                                            className="custom-p-dropdown"
                                                                            filter={false}
                                                                            resetFilterOnHide={true}
                                                                        />
                                                                    </Col>
                                                                    <Col md={6} className="align-colWidth">
                                                                        <label className="lbl-text required-Label">Address</label>
                                                                        <TextField
                                                                            id="lenderCancellationAddressLine1"
                                                                            name="lenderCancellationAddressLine1"
                                                                            //label="Address"
                                                                            value={cancellationFormik.values.lenderCancellationAddressLine1}
                                                                            onChange={(e, val) => handleTextFieldChange(e, val)}
                                                                            onBlur={cancellationFormik.handleBlur}
                                                                            errorMessage={
                                                                                cancellationFormik.touched.lenderCancellationAddressLine1 &&
                                                                                    cancellationFormik.errors.lenderCancellationAddressLine1
                                                                                    ? cancellationFormik.errors.lenderCancellationAddressLine1.toString()
                                                                                    : null
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md={6} className="subAddress-Row align-colWidth" style={{ paddingTop: "5px" }}>
                                                                        <TextField
                                                                            id="lenderCancellationAddressLine2"
                                                                            name="lenderCancellationAddressLine2"
                                                                            label=""
                                                                            value={cancellationFormik.values.lenderCancellationAddressLine2}
                                                                            onChange={(e, val) => handleTextFieldChange(e, val)}
                                                                            onBlur={cancellationFormik.handleBlur}
                                                                            errorMessage={
                                                                                cancellationFormik.touched.lenderCancellationAddressLine2 &&
                                                                                    cancellationFormik.errors.lenderCancellationAddressLine2
                                                                                    ? cancellationFormik.errors.lenderCancellationAddressLine2.toString()
                                                                                    : null
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md={6} style={{ paddingTop: "5px" }} className="align-colWidth ">
                                                                        <label className="lbl-text required-Label">Loan #</label>
                                                                        <TextField
                                                                            id="loanNumber"
                                                                            name="loanNumber"
                                                                            //label="Loan #"
                                                                            value={cancellationFormik.values.loanNumber}
                                                                            onChange={(e, val) => handleTextFieldChange(e, val)}
                                                                            onBlur={cancellationFormik.handleBlur}
                                                                            errorMessage={
                                                                                cancellationFormik.touched.loanNumber &&
                                                                                    cancellationFormik.errors.loanNumber
                                                                                    ? cancellationFormik.errors.loanNumber.toString()
                                                                                    : null
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </>)
                                                            }
                                                            {
                                                                cancellationFormik.values.refundMethod2 === '2' &&
                                                                    (<>
                                                                        <Col md={6} className="align-colWidth">
                                                                            <label className="lbl-text required-Label" > Customer's Address </label>
                                                                            <TextField
                                                                                id="customerAddressLine1"
                                                                                name="customerAddressLine1"
                                                                                //label="Customer's Address"
                                                                                value={cancellationFormik.values.customerAddressLine1}
                                                                                onChange={(e, val) => handleTextFieldChange(e, val)}
                                                                                onBlur={cancellationFormik.handleBlur}
                                                                                errorMessage={
                                                                                    cancellationFormik.touched.customerAddressLine1 &&
                                                                                        cancellationFormik.errors.customerAddressLine1
                                                                                        ? cancellationFormik.errors.customerAddressLine1.toString()
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col md={6} className="subAddress-Row align-colWidth" style={{ paddingTop: "5px" }}>
                                                                            <TextField
                                                                                id="customerAddressLine2"
                                                                                name="customerAddressLine2"
                                                                                label=""
                                                                                value={cancellationFormik.values.customerAddressLine2}
                                                                                onChange={(e, val) => handleTextFieldChange(e, val)}
                                                                                onBlur={cancellationFormik.handleBlur}
                                                                                errorMessage={
                                                                                    cancellationFormik.touched.customerAddressLine2 &&
                                                                                        cancellationFormik.errors.customerAddressLine2
                                                                                        ? cancellationFormik.errors.customerAddressLine2.toString()
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col md={6} className="align-colWidth">
                                                                            <label className="lbl-text">Proof loan is paid off</label>
                                                                            <PRDropdown
                                                                                id="proofLoanPaidOff"
                                                                                placeholder=""
                                                                                value={cancellationFormik.values.proofLoanPaidOff}
                                                                                options={masterDropdownValues.proofLoanPaidOff}
                                                                                onChange={handleDDChange}
                                                                                optionLabel="text"
                                                                                optionValue="key"
                                                                                // appendTo="self"
                                                                                className="custom-p-dropdown"
                                                                                filter={false}
                                                                                resetFilterOnHide={true}
                                                                            />
                                                                        </Col>
                                                                    </>)
                                                            }
                                                        </Row>
                                                    )
                                                }

                                            </div> : ""
                                            }
                                        </div>
                                        <Row className="rowSpace cancel-SaveBtn cancellation-SaveBtn" >
                                            <Col md={6} style={{ float: "left", width: "auto" }}>
                                                <PrimaryButton onClick={handleShowGrid}>
                                                    Cancel
                                                </PrimaryButton>
                                            </Col>
                                            <Col md={6}>
                                                <PrimaryButton
                                                    //disabled={!cancellationFormik.dirty || !cancellationFormik.isValid}
                                                    disabled={((!cancellationFormik.isValid)) }
                                                    onClick={handleFormikSumbit}
                                                    style={{ float: "right" }} >
                                                    Save
                                                </PrimaryButton>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>)
            }

        </>);
};



