import { ISubscription } from "types/subscriptionTypes";

// GET SUBSCRIPTIONS
export const HANDLE_GET_SUBSCRIPTIONS = "HANDLE_GET_SUBSCRIPTIONS";

export interface HandleGetSubscriptions {
  type: typeof HANDLE_GET_SUBSCRIPTIONS;
}

export const GET_SUBSCRIPTIONS_PENDING = "GET_SUBSCRIPTIONS_PENDING";

export interface GetSubscriptionsPending {
  type: typeof GET_SUBSCRIPTIONS_PENDING;
}

export const GET_SUBSCRIPTIONS_RESOLVED = "GET_SUBSCRIPTIONS_RESOLVED";

export interface GetSubscriptionsResolved {
  type: typeof GET_SUBSCRIPTIONS_RESOLVED;
  payload: ISubscription;
}

export const GET_SUBSCRIPTIONS_REJECTED = "GET_SUBSCRIPTIONS_REJECTED";

export interface GetSubscriptionsRejected {
  type: typeof GET_SUBSCRIPTIONS_REJECTED;
  payload: any;
}

// PAYMENT
export const HANDLE_PAYMENT = "HANDLE_PAYMENT";

export interface HandlePayment {
  type: typeof HANDLE_PAYMENT;
  payload: {product: string, shouldAddCreditCart: boolean, callback: Function};
}

export const PAYMENT_PENDING = "PAYMENT_PENDING";

export interface PaymentPending {
  type: typeof PAYMENT_PENDING;
}

export const PAYMENT_RESOLVED = "PAYMENT_RESOLVED";

export interface PaymentResolved {
  type: typeof PAYMENT_RESOLVED;
  payload: string;
}

export const PAYMENT_REJECTED = "PAYMENT_REJECTED";

export interface PaymentRejected {
  type: typeof PAYMENT_REJECTED;
  payload: any;
}

// CANCEL SUBSCRIPTION
export const HANDLE_CANCEL_SUBSCRIPTION = "HANDLE_CANCEL_SUBSCRIPTION";

export interface HandleCancelSubscription {
  type: typeof HANDLE_CANCEL_SUBSCRIPTION;
  payload: { id: string; callback: () => void };
}

export const CANCEL_SUBSCRIPTION_PENDING = "CANCEL_SUBSCRIPTION_PENDING";

export interface CancelSubscriptionPending {
  type: typeof CANCEL_SUBSCRIPTION_PENDING;
}

export const CANCEL_SUBSCRIPTION_RESOLVED = "CANCEL_SUBSCRIPTION_RESOLVED";

export interface CancelSubscriptionResolved {
  type: typeof CANCEL_SUBSCRIPTION_RESOLVED;
}

export const CANCEL_SUBSCRIPTION_REJECTED = "CANCEL_SUBSCRIPTION_REJECTED";

export interface CancelSubscriptionRejected {
  type: typeof CANCEL_SUBSCRIPTION_REJECTED;
  payload: any;
}

/** GET_CREDIT_CARTS */
export const HANDLE_GET_CREDIT_CARTS = "HANDLE_GET_CREDIT_CARTS";
export interface HandleGetCreditCarts {
  type: typeof HANDLE_GET_CREDIT_CARTS;
}

export const GET_CREDIT_CARTS_PENDING = "GET_CREDIT_CARTS_PENDING";
export interface GetCreditCartsPending {
  type: typeof GET_CREDIT_CARTS_PENDING;
}

export const GET_CREDIT_CARTS_RESOLVED = "GET_CREDIT_CARTS_RESOLVED";
export interface GetCreditCartsResolved {
  type: typeof GET_CREDIT_CARTS_RESOLVED;
  payload: any[]
}

export const GET_CREDIT_CARTS_REJECTED = "GET_CREDIT_CARTS_REJECTED";
export interface GetCreditCartsRejected {
  type: typeof GET_CREDIT_CARTS_REJECTED;
  payload: object[];
}

/** ADD_CREDIT_CART */
export const HANDLE_ADD_CREDIT_CART = "HANDLE_ADD_CREDIT_CART";
export interface HandleAddCreditCart {
  type: typeof HANDLE_ADD_CREDIT_CART;
  payload: { cart: any; callback: Function };
}

export const ADD_CREDIT_CART_PENDING = "ADD_CREDIT_CART_PENDING";
export interface AddCreditCartPending {
  type: typeof ADD_CREDIT_CART_PENDING;
}

export const ADD_CREDIT_CART_RESOLVED = "ADD_CREDIT_CART_RESOLVED";
export interface AddCreditCartResolved {
  type: typeof ADD_CREDIT_CART_RESOLVED;
  payload: any
}

export const ADD_CREDIT_CART_REJECTED = "ADD_CREDIT_CART_REJECTED";
export interface AddCreditCartRejected {
  type: typeof ADD_CREDIT_CART_REJECTED;
  payload: object[];
}

/** REMOVE_CREDIT_CART */
export const HANDLE_REMOVE_CREDIT_CART = "HANDLE_REMOVE_CREDIT_CART";

export interface HandleRemoveCreditCart {
  type: typeof HANDLE_REMOVE_CREDIT_CART;
  payload: { cartId: number | string; callback: Function };
}

export const REMOVE_CREDIT_CART_PENDING = "REMOVE_CREDIT_CART_PENDING";
export interface RemoveCreditCartPending {
  type: typeof REMOVE_CREDIT_CART_PENDING;
}

export const REMOVE_CREDIT_CART_RESOLVED = "REMOVE_CREDIT_CART_RESOLVED";
export interface RemoveCreditCartResolved {
  type: typeof REMOVE_CREDIT_CART_RESOLVED;
  payload: string
}

export const REMOVE_CREDIT_CART_REJECTED = "REMOVE_CREDIT_CART_REJECTED";
export interface RemoveCreditCartRejected {
  type: typeof REMOVE_CREDIT_CART_REJECTED;
  payload: object[];
}

export type SubscriptionsActions =
  | HandleGetSubscriptions
  | GetSubscriptionsPending
  | GetSubscriptionsResolved
  | GetSubscriptionsRejected
  | HandlePayment
  | PaymentPending
  | PaymentResolved
  | PaymentRejected
  | HandleCancelSubscription
  | CancelSubscriptionPending
  | CancelSubscriptionRejected
  | CancelSubscriptionResolved
  | HandleAddCreditCart
  | HandleGetCreditCarts
  | HandleRemoveCreditCart
  | GetCreditCartsPending
  | GetCreditCartsRejected
  | GetCreditCartsResolved
  | AddCreditCartPending
  | AddCreditCartRejected
  | AddCreditCartResolved
  | RemoveCreditCartPending
  | RemoveCreditCartResolved
  | RemoveCreditCartRejected;
