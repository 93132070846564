import {NotificationType} from "types/notificationType";


export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const showSnackbar = (type: NotificationType, message: string) => {
    
    if (message !== '' && message !== undefined) {
        return {
            type: SHOW_SNACKBAR,
            payload: { type, message },
        };
    }

    return {
        type: SHOW_SNACKBAR,
    };
  
};

export const removeSnackbar = () => ({
  type: REMOVE_SNACKBAR,
});
