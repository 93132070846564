import React, { useEffect, useState, FunctionComponent } from "react";
import {
  DefaultButton,
  Modal,
  IconButton,
  PrimaryButton,
  TextField,
  ComboBox,
} from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import WithOptionalLabel from "components/customInputs/withOptionalLabel/WithOptionalLabel";
import ListItem from "components/ListItem/ListItem";
import AddInventoryDropzone from "components/dialogs/addInventoryDialog/AddInventoryDropzone";
import AddInventoryVideoDropzone from "components/dialogs/addInventoryDialog/AddInventoryVideoDropzone";
import WysiwygEditor from "components/dialogs/addInventoryDialog/WysiwygEditor";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import FinancialInformation from "components/dialogs/addInventoryDialog/secondaryDialogs/FinancialInformation";
import BodyAndColor from "components/dialogs/addInventoryDialog/secondaryDialogs/BodyAndColor";
import EngineAndDrive from "components/dialogs/addInventoryDialog/secondaryDialogs/EngineAndDrive";
import { NewOptionsDialog } from "components/dialogs/addInventoryDialog/secondaryDialogs/NewOptionsDialog";
import {
  handleEditInventory,
  handleGetFromVin,
} from "store/inventory/inventory.action";
import { IUpdateInventory, IInventory } from "types/inventoryTypes";
import {
  inventoryTypes,
  engineAndDriveItemList,
  financialInformationItemList, PRICE_PREFIX,
} from "constants/constants";
import { getLocationsForDropdown } from "store/locations/locations.api";

import {
  iconButtonStyles,
  dropdownStyles,
  contentStyles as content,
  cancelIcon,
} from "constants/styles";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { handleDeleteInventory } from "store/inventory/inventory.action";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { IPickerItem } from "../../../types/sharedTypes";
import {
  handleGetMakes,
  handleGetModels,
} from "store/shared/shared.action";
import CurrencyInput from 'react-currency-input-field'

import { stringFromCurrencyFormat, stringToCurrenyFormatForEditInputs} from "utils/helperFunctions";




const contentStyles = content();
// OPTIONS FOR DROPDOWNS
const statusValues = inventoryTypes.statuses.map((item) => {
  return { key: item.name, text: item.name };
});

const conditionValues = dropDownsValues.conditionValues.map((item) => {
  return { key: item, text: item };
});

const yearValues = dropDownsValues.yearValues.map((item) => {
  return { key: item, text: item };
});

const historyValues = ["No Accidents", "Single Owner"].map((item) => {
  return { key: item, text: item };
});

export enum FormStep {
  Main,
  Financial,
  Engine,
  Body,
  Options,
}

interface IProps {
  onDismiss: () => void;
  selectedInventory: IInventory;
  returnInventory?: (inventory: any) => void;
}

export const EditInventoryDialog: FunctionComponent<IProps> = ({
  onDismiss,
  selectedInventory,
  returnInventory,
}) => {
  const [locations, setLocations] = useState([]);
  const [modelFromVin, setModelFromVin] = useState("");
  const showFooter = true;
  const [formStep, setFormStep] = useState(FormStep.Main);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isConfirmVinModalOn, setIsConfirmVinModalOn] = useState(false);
  const [vinData, setVinData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({
    optionsAudio: [],
    optionsPower: [],
    optionsInterior: [],
    optionsExterior: [],
    //optionsSafety: [],
    optionsSafteyFeatures: [],
    optionsLuxury: [],
    optionsOther: [],
  });
  const [isIndeterminateInvoice, setIsIndeterminateInvoice] = useState(false);
  const [isIndeterminateLeasing, setIsIndeterminateLeasing] = useState(false);
  const [inventory, setInventory] = useState({
    body: "",
    exteriorColor: "",
    interiorColor: "",
    doors: undefined,
  });

  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState([]);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [videoFilesToSend, setVideoFilesToSend] = useState([]);
  const [videoFilesToRemove, setVideoFilesToRemove] = useState(false);

  const [secondaryDialogTitle, setSecondaryDialogTitle] = useState("");
  const [secondaryDialogContent, setSecondaryDialogContent] = useState(null);

  const [wysiwygEditorState, setWysiwygEditorState] = useState(
    EditorState.createEmpty()
  );

  const [confirmBoxOpen, setConfirmBoxOpen] = useState(false);
  const [modelValues, setModelValues] = useState([]);
  const [makeValues, setMakeValues] = useState([]);

  const loadingMakes = useSelector(
    (state: AppState) => state.shared.loadingMakes
  );
  const loadingModels = useSelector(
    (state: AppState) => state.shared.loadingModels
  );
  const models: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.models
  );
  const makes: IPickerItem[] = useSelector(
    (state: AppState) => state.shared.makes
  );

  const loading = useSelector((state: AppState) => state.inventory.loading);

  const dispatch = useDispatch();

  // const selectedInventory = useSelector(
  //   (state: AppState) => state.inventory.selectedInventory
  // );

  const formik = useFormik({
    initialValues: {
      // setStatus: null,
      setStatus: "",
      condition: ["Used"],
      make: { key: 0, text: "unknown" },
      model: { key: 0, text: "" },
      // model: "350ZZZ",
      trim: "trim",
      price: "",
      //year: null,
      year: 2000,
      mileage: "123",
      vin: "1D7HU18297J554876",
      location: null,
      vehicleHistory: ["No Accidents"],

      isInvoice: false, // bool
      isLeasing: false, // bool
      msrp: "", // string
      bookValue: "", // string

      driveType: null, //number
      fuelType: null,
      // fuelEconomy: null,
      engineCylinders: null,
      engineDescription: null,
      transmissionDescription: null,
    },

    validationSchema: Yup.object({
      setStatus: Yup.string().required("Required"),
      condition: Yup.array().of(Yup.string()).required("Required"),
      make: Yup.object().required("Required"),
      // model: Yup.array().of(Yup.string()).required("Required"),
      model: Yup.object().shape({
        key: Yup.number().required("Required").min(1),
        text: Yup.string().required("Required"),
      }),
      trim: Yup.string(),
      price: Yup.string().required("Required"),
      year: Yup.number().required("Required"),
      mileage: Yup.string().required("Required"),
      vin: Yup.string().matches(
        /^[A-Z0-9]{17}$/,
        "Must Contain at 17 Characters, Uppercase letters and numbers"
      ),
      location: Yup.number().required("Required"),
      vehicleHistory: Yup.array().of(Yup.string()).required("Required"),

      isInvoice: Yup.boolean().required("Required!!!").oneOf([true, false]),
      isLeasing: Yup.boolean().required("Required"),
      msrp: Yup.string().required("Required"),
      bookValue: Yup.string().required("Required"),


      driveType: Yup.string(), //number
      fuelType: Yup.string(),
      // fuelEconomy: Yup.string().required("Required"),
      engineCylinders: Yup.number(),
      engineDescription: Yup.string(),
      transmissionDescription: Yup.string(),
    }),
    onSubmit: (values) => {
      const inventoryData: IUpdateInventory = {

        id: selectedInventory.id,

        status: values.setStatus,
        vehCondition: values.condition,
        make: values.make.text,
        // model: values.model.join(),
        model: values.model.text,
        trim: values.trim,
        sellingPrice: parseInt(stringFromCurrencyFormat(values.price)),
        year: values.year,
        miles: parseFloat(values.mileage),
        vin: values.vin,

        vehHistory: values.vehicleHistory,

        body: inventory.body,
        doors: parseFloat(inventory.doors),
        exteriorColor: inventory.exteriorColor,
        extColorGeneric: inventory.exteriorColor,
        intColorGeneric: inventory.interiorColor,
        driveType: values.driveType,
        fuelType: values.fuelType,
        fuelEconomy: "1",
        engineCylinders: parseFloat(values.engineCylinders),
        engineDescription: values.engineDescription,
        transmissionDescription: values.transmissionDescription,
        //photoUrls: photos,
        description: draftToHtml(
          convertToRaw(wysiwygEditorState.getCurrentContent())
        ),
        isInvoice: values.isInvoice,
        isLeasing: values.isLeasing,
        msrp: parseInt(stringFromCurrencyFormat(values.msrp)),
        bookValue: parseInt(stringFromCurrencyFormat(values.bookValue)),
        optionsAudio: selectedOptions.optionsAudio,
        optionsPower: selectedOptions.optionsPower,
        optionsInterior: selectedOptions.optionsInterior,
        optionsExterior: selectedOptions.optionsExterior,
        optionsSafteyFeatures: selectedOptions.optionsSafteyFeatures,
        optionsLuxury: selectedOptions.optionsLuxury,
        optionsOther: selectedOptions.optionsOther,
        photoUrls: filesToAdd,
        videoUrl: videoFilesToSend,
        // default values ??
        stock: "STOCK_DEFAULT",
        drivetrain: "DRIVER_TRAIN_DEFAULT",
        category: "CATEGORY_DEFAULT",
        isFavourite: false,
        vehTypeName: "TYPE_DEFAULT",
        type: 0,
      };

      dispatch<any>(
        handleEditInventory(
          [inventoryData],
          {
            locationId: values.location,
            inventoryId: selectedInventory?.id,
            filesToRemove,
            videoFilesToRemove
          },
          () => {
            returnInventory && returnInventory(inventoryData);
            onDismiss();
          }
        )
      );
    },
  });

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await getLocationsForDropdown();
      if (error) {
        return;
      }
      const options = data.results.map((item) => {
        return { key: item.id, text: item.legalName };
      });

      setLocations(options);
    };

    fetchLocations().then();
  }, []);

  useEffect(() => {
    let selectedMake = null;

    if (makes.length > 0) {
      const values = makes.map((item) => {
        if (item.label === selectedInventory?.make) {
          selectedMake = { key: item.id, text: item.label };
        }

        return { key: item.id, text: item.label };
      });
      setMakeValues(values);

      formik.setFieldValue("make", selectedMake);

      dispatch<any>(handleGetModels(selectedMake?.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makes]);

  // useEffect(() => {
  //   let selectedModel = null;
  //
  //   if (models.length > 0) {
  //     const values = models.map((item) => {
  //       if (item.label === selectedInventory?.model) {
  //         selectedModel = { key: item.id, text: item.label };
  //       }
  //
  //       return { key: item.id, text: item.label };
  //     });
  //     setModelValues(values);
  //
  //     formik.setFieldValue("model", selectedModel);
  //   }
  // }, [models]);

  useEffect(() => {
    let selectedModel = null;

    if (models.length > 0) {
      const values = models.map((item) => {
        if (item.label === selectedInventory?.model) {
          selectedModel = { key: item.id, text: item.label };
        }

        return { key: item.id, text: item.label };
      });
      setModelValues(values);

      if (modelFromVin !== "") {
        console.log(modelFromVin);

        const vinModel = values.find((item: any) => {
          return item.text === modelFromVin;
        }) as any;
        
        if (Boolean(vinModel)) {
          
          formik.setFieldValue("model", {
            key: vinModel.key,
            text: vinModel.text,
          });
        }
      } else {
        formik.setFieldValue("model", selectedModel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  useEffect(() => {
    dispatch<any>(handleGetMakes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values?.make?.text !== "unknown") {
      //setShowModels(true);
      formik.setFieldValue("model", { key: 0, text: "" });
      dispatch<any>(handleGetModels(formik.values.make?.key?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.make]);

  useEffect(() => {
    /**  BASIC INFORMATION */
    if (selectedInventory && selectedInventory.id) {
      // formik.setValues({
      //   ...formik.values,
      //   msrp: selectedInventory?.msrp,
      //   location: selectedInventory?.location?.id,
      //   bookValue: selectedInventory?.bookValue,
      //   driveType: selectedInventory?.driveType,
      //   fuelType: selectedInventory?.fuelType,
      //   fuelEconomy: selectedInventory?.fuelEconomy,
      //   engineCylinders: selectedInventory?.engineCylinders,
      //   transmissionDescription: selectedInventory?.transmissionDescription,
      //   engineDescription: selectedInventory?.engineDescription,
      // });
      console.log(selectedInventory)
      

      formik.setValues({
        // ...formik.values,
        setStatus: selectedInventory?.status,
        condition: selectedInventory?.vehCondition,
        // make: selectedInventory?.make,
        make: { key: 0, text: "unknown" },
        // model: selectedInventory?.model,
        model: { key: 0, text: "unknown" },
        trim: selectedInventory?.trim,
       price: stringToCurrenyFormatForEditInputs(selectedInventory?.sellingPrice),
        year: selectedInventory?.year,
        mileage: selectedInventory?.miles.toString(),
        vin: selectedInventory?.vin,
        location: selectedInventory?.location?.id,
        vehicleHistory: selectedInventory?.vehHistory,

        isInvoice: selectedInventory?.isInvoice,
        isLeasing: selectedInventory?.isLeasing,
        msrp: stringToCurrenyFormatForEditInputs(selectedInventory?.msrp),
        bookValue: stringToCurrenyFormatForEditInputs(selectedInventory?.bookValue),

        driveType: selectedInventory?.driveType,
        fuelType: selectedInventory?.fuelType,
        // fuelEconomy: selectedInventory?.fuelEconomy,
        engineCylinders: selectedInventory?.engineCylinders,
        engineDescription: selectedInventory?.engineDescription,
        transmissionDescription: selectedInventory?.transmissionDescription,
      });

      /**  BODY AND COLOR INFORMATION */
      setInventory({
        body: selectedInventory?.body ? selectedInventory?.body : "",
        // exteriorColor?
        exteriorColor: selectedInventory?.extColorGeneric
          ? selectedInventory?.extColorGeneric
          : "",
        interiorColor: selectedInventory?.intColorGeneric
          ? selectedInventory?.intColorGeneric
          : "",
        doors: selectedInventory?.doors
          ? selectedInventory?.doors.toString()
          : undefined,
      });

      /**  OPTIONS INFORMATION */
      setSelectedOptions({
        optionsAudio: selectedInventory?.optionsAudio,
        optionsPower: selectedInventory?.optionsPower,
        optionsInterior: selectedInventory?.optionsInterior,
        optionsExterior: selectedInventory?.optionsExterior,
        optionsSafteyFeatures: selectedInventory?.optionsSafteyFeatures,
        optionsLuxury: selectedInventory?.optionsLuxury,
        optionsOther: selectedInventory?.optionsOther,
      });

      /**  PHOTOS */

      setPhotos(
        selectedInventory?.photoUrls ? selectedInventory?.photoUrls : []
      );

      setVideo(
        selectedInventory?.videoUrl ? [selectedInventory?.videoUrl] : []
      );

      /**  WYSIWYG NOTE */
      const html = selectedInventory?.description
        ? selectedInventory?.description
        : "";
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setWysiwygEditorState(editorState);
      }
      // formik.setErrors({});
      // console.log(formik.errors);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventory]);

  useEffect(() => {
    switch (formStep) {
      case FormStep.Main:
        // setShowFooter(true);
        setSecondaryDialogTitle("");
        setSecondaryDialogContent(null);
        break;
      case FormStep.Financial:
        // setShowFooter(false);
        setSecondaryDialogTitle("Financial information");
        setSecondaryDialogContent(
          <FinancialInformation
            isIndeterminateInvoice={isIndeterminateInvoice}
            isIndeterminateLeasing={isIndeterminateLeasing}
            setIsIndeterminateInvoice={setIsIndeterminateInvoice}
            setIsIndeterminateLeasing={setIsIndeterminateLeasing}
            formik={formik}
            inventory={inventory}
            setInventory={setInventory}
          />
        );

        break;
      case FormStep.Engine:
        // setShowFooter(false);
        setSecondaryDialogTitle("Engine and drive");
        setSecondaryDialogContent(
          <EngineAndDrive
            formik={formik}
            inventory={inventory}
            setInventory={setInventory}
          />
        );
        break;
      case FormStep.Body:
        // setShowFooter(false);
        setSecondaryDialogTitle("Body and color");
        setSecondaryDialogContent(
          <BodyAndColor inventory={inventory} setInventory={setInventory} />
        );

        break;
      case FormStep.Options:
        setIsOptionsOpen(true);
        break;
      default:
        console.log(`default switch`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStep, inventory, formik.values, formik.errors]);



  const onOptionsDialogClose = () => {
    setIsOptionsOpen(false);
    setSecondaryDialogTitle("");
    setSecondaryDialogContent(null);
  };

  const handleOptionsClick = () => {
    setIsOptionsOpen(true);
  };

  const editInventory = () => {
    formik.handleSubmit();
  };

  const handleDropdownChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: item.key,
      },
      true
    );
  };

  const handleMakeChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        make: item,
      },
      true
      //   false
    );
  };

  const handleModelChange = (e, item) => {
    formik.setValues(
      {
        ...formik.values,
        model: item,
      },
      true
      //   false
    );
  };

  const handleDropdownMultiChange = (e, item) => {
    const id = e.target.id;
    let newValues = [...formik.values[id]];

    if (formik.values[id].includes(item.text)) {
      newValues = newValues.filter((element) => element !== item.text);
    } else {
      newValues.push(item.text);
    }

    formik.setValues(
      {
        ...formik.values,
        [e.target.id]: newValues,
      },
      true
    );
  };

  const renderSpinner = () => {
    return loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  const isError = (arr: any[]): boolean => {
    console.log(arr);
    return !!arr.find((a: any) => a.id === "error" && a.value);
  };

  const handleUpdateDataFromVin = () => {
    // TODO
    console.log(vinData);
    

    vinData.forEach((item: any) => {
      if (item.id === "make") {
        const selectedMake = makes.find((make) => {
          return make.label === item.value;
        });

        if (selectedMake) {
          dispatch<any>(handleGetModels(selectedMake.id.toString()));
        }

        formik.setFieldValue("make", {
          key: selectedMake.id,
          text: selectedMake.label,
        });
        return;
      }

      if (item.id === "model") {
        // const selectedModel = models.find((model) => {
        //   return model.label === item.value;
        // });

        

        setModelFromVin(item.value);

        // if (selectedModel) {
        //   formik.setFieldValue("model", {
        //     key: selectedModel.id,
        //     text: selectedModel.label,
        //   });
        // }

        return;
      }

      formik.setFieldValue(
        item.id,
        typeof formik.values[item.id] === "number" ? +item.value : item.value
      );
    });

    setIsConfirmVinModalOn(false);
  };
  // const handleUpdateDataFromVin = () => {
  //   vinData.forEach((item: any) => {
  //     formik.setFieldValue(
  //       item.id,
  //       typeof formik.values[item.id] === "number" ? +item.value : item.value
  //     );
  //   });
  //   setIsConfirmVinModalOn(false);
  // };

  const createConfirmDescription = (data: any[]) => {
    if (!data || data.length === 0) return;
    return data && data.length > 0 && !isError(data)
      ? "Update data according to data get from VIN?"
      : data && data.length > 0 && isError(data)
      ? _displayError(data)
      : "";
  };
  const _displayError = (arr: any[]): string => {
    const error = arr.find((a: any) => a.id === "error").value;
    const errorMessage = arr.find((a: any) => a.id === "additionalErrorMessage")
      .value;
    return `${error}\n \n ${errorMessage}`;
  };

  const handleOptionsChange = (e: any, category) => {
    let newOptions = [...selectedOptions[category]];

    if (newOptions.includes(e)) {
      newOptions = newOptions.filter((item) => item !== e);
    } else {
      newOptions.push(e);
    }

    setSelectedOptions((s) => {
      return { ...s, [category]: newOptions };
    });
  };

  const getOptions = (options) => {
    setSelectedOptions((o) => {
      return { ...o, ...options };
    });
  };

  const handleOpenConfirmDeletionBox = () => {
    setConfirmBoxOpen(true);
  };

  const handleDelete = () => {
    //todo delete
    console.log("delete - delete");

    const callback = () => {
      onDismiss();
      setConfirmBoxOpen(false);
    };

    dispatch<any>(
      handleDeleteInventory({
        ids: [selectedInventory.id],
        callback,
      })
    );
  };

  const calcFilledFields = (array: any[]): number => {
    let count = 0;
    if (array.length > 0) {
      array.forEach((item) => {
        if (Array.isArray(formik.values[item])) {
          if (formik.values[item].length > 0) {
            count += 1;
          }
        } else {
          if (formik.values[item]) {
            count += 1;
          }
        }
      });
    }
    return count;
  };

  const handleCheckVin = () => {
    console.log(formik.values.vin);
    dispatch<any>(
      handleGetFromVin({
        vin: formik.values.vin,
        callback: (dataFromVin) => {
          setVinData(dataFromVin);
          setIsConfirmVinModalOn(true);
        },
      })
    );
  };

  const handleRemoveFile = (filesToRemove) => {
    setFilesToRemove(filesToRemove);
    setPhotos(
      photos.filter((photo: any) => !filesToRemove.includes(photo) && photo)
    );
  };

  const handleRemoveVideoFile = () => {
    setVideoFilesToRemove(true);
    setVideoFilesToSend([]);
    setVideo([]);
  }

  return (
    <>
      <Modal
        //isOpen={isOpen}
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        {/*<div>{JSON.stringify(formik.errors)}*/}
        {/*</div>*/}

        <ConfirmDialog
          // loading={this.props.deleteLoading}
          loading={false}
          description="Please confirm deletion of Inventory"
          title="Confirm delete Inventory"
          isOpen={confirmBoxOpen}
          onAction={handleDelete}
          onDismiss={() => setConfirmBoxOpen(false)}
        />

        <NewOptionsDialog
          actionButtonLabel="Save Changes"
          loading={loading}
          handleOnActionClick={editInventory}
          formik={formik}
          isOpen={isOptionsOpen}
          onDismiss={onOptionsDialogClose}
          getOptions={getOptions}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionsChange}
        />
        <div className={contentStyles.header}>
          {formStep === FormStep.Main ? (
            <CustomFontText>Edit Inventory</CustomFontText>
          ) : (
            <GoBackToMainDialogBtn
              label={secondaryDialogTitle}
              onClick={() => setFormStep(FormStep.Main)}
            />
          )}
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>

        <div>
          <div className={`noScrollbar ${contentStyles.body}`}>
            <Stack>
              {formStep === FormStep.Main ? (
                <Stack tokens={styles.textFields}>
                  <TextField
                    label="Stock"
                    disabled
                    value={selectedInventory?.id?.toString()}
                  />
                  <Dropdown
                    id="setStatus"
                    label="Set Status"
                    required={true}
                    selectedKey={
                      formik.values.setStatus
                        ? formik.values.setStatus
                        : undefined
                    }
                    onChange={handleDropdownChange}
                    placeholder=""
                    options={statusValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.setStatus && formik.errors.setStatus
                        ? formik.errors.setStatus.toString()
                        : null
                    }
                  />
                  <WithOptionalLabel>
                    <TextField
                      id="vin"
                      name="vin"
                      label="VIN"
                      value={formik.values.vin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.vin &&
                        formik.errors.vin &&
                        formik.errors.vin.toString()
                      }
                    />
                    <PrimaryButton
                      disabled={!!formik.errors.vin || !formik.values.vin}
                      onRenderIcon={renderSpinner}
                      text="Get from VIN"
                      onClick={handleCheckVin}
                      style={{ position: "absolute", right: 0, top: 29 }}
                    />
                  </WithOptionalLabel>

                  <Dropdown
                    id="condition"
                    label="Condition"
                    required={true}
                    multiSelect
                    selectedKeys={
                      formik.values.condition
                        ? formik.values.condition
                        : undefined
                    }
                    // onChange={handleDropdownMultiChange}
                    onChange={handleDropdownMultiChange}
                    placeholder=""
                    options={conditionValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.condition && formik.errors.condition
                        ? formik.errors.condition.toString()
                        : null
                    }
                  />
                  {/*<Dropdown*/}
                  {/*  id="make"*/}
                  {/*  label="Make"*/}
                  {/*  selectedKey={*/}
                  {/*    formik.values.make ? formik.values.make : undefined*/}
                  {/*  }*/}
                  {/*  onChange={handleDropdownChange}*/}
                  {/*  placeholder=""*/}
                  {/*  options={makeValues}*/}
                  {/*  styles={dropdownStyles}*/}
                  {/*  onBlur={formik.handleBlur}*/}
                  {/*  errorMessage={*/}
                  {/*    formik.touched.make && formik.errors.make*/}
                  {/*      ? formik.errors.make.toString()*/}
                  {/*      : null*/}
                  {/*  }*/}
                  {/*/>*/}
                  {loadingMakes ? (
                    <Spinner
                      styles={styles.spinnerStyles}
                      size={SpinnerSize.medium}
                    />
                  ) : (
                    <>
                      <ComboBox
                        id="make"
                        label="Make"
                        required={true}
                        allowFreeform
                        autoComplete="on"
                        options={makeValues}
                        // selectedKey={formik.values.make}
                        // selectedKey={452}
                        onChange={handleMakeChange}
                        selectedKey={
                          formik.values.make && formik.values.make.key
                        }
                      />
                    </>
                  )}
                  {loadingModels ? (
                    <Spinner
                      styles={styles.spinnerStyles}
                      size={SpinnerSize.medium}
                    />
                  ) : (
                    <Dropdown
                      id="model"
                      label="Model"
                      required={true}
                      // selectedKey={
                      //   formik.values.model ? formik.values.model : undefined
                      // }
                      // multiSelect
                      selectedKey={
                        formik.values.model && formik.values.model.key
                      }
                      onChange={handleModelChange}
                      placeholder=""
                      options={modelValues}
                      styles={dropdownStyles}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.model && formik.errors.model
                          ? "Required"
                          : null
                      }
                    />
                  )}

                    <TextField
                      id="trim"
                      name="trim"
                      label="Trim"
                      value={formik.values.trim}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched.trim &&
                        formik.errors.trim &&
                        formik.errors.trim.toString()
                      }
                    />
                    <label className="lbl-text required-Label">Price</label>
                  <CurrencyInput
                      customInput={TextField}
                      id="price"
                      name="price"
                      //label="Price"
                      //required={true}
                      prefix={PRICE_PREFIX}
                      value={formik.values.price?formik.values.price.replace(/,/g, ''):""}
                      onValueChange ={(e) => {
                        formik.setValues(
                            {
                                ...formik.values,
                                "price": e
                            }
                        );
                    }}
                      onBlur={formik.handleBlur}
                      // errorMessage={
                      //   formik.touched.price &&
                      //   formik.errors.price &&
                      //   formik.errors.price.toString()
                      // }
                     // thousandSeparator={true}
                  />
                  <span className="error-message">
                    {
                      formik.errors.price &&
                      formik.errors.price.toString()}
                  </span>
                  {/*<TextField*/}
                  {/*  id="price"*/}
                  {/*  name="price"*/}
                  {/*  label="Price"*/}
                  {/*  prefix="$"*/}
                  {/*  type="number"*/}
                  {/*  value={formik.values.price}*/}
                  {/*  onChange={formik.handleChange}*/}
                  {/*  onBlur={formik.handleBlur}*/}
                  {/*  errorMessage={*/}
                  {/*    formik.touched.price &&*/}
                  {/*    formik.errors.price &&*/}
                  {/*    formik.errors.price.toString()*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Dropdown
                    id="year"
                    label="Year"
                    required={true}
                    selectedKey={
                      formik.values.year ? formik.values.year : undefined
                    }
                    onChange={handleDropdownChange}
                    placeholder=""
                    options={yearValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.year && formik.errors.year
                        ? formik.errors.year.toString()
                        : null
                    }
                  />
                  <TextField
                    id="mileage"
                    name="mileage"
                    label="Mileage"
                    required={true}
                    value={formik.values.mileage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.mileage &&
                      formik.errors.mileage &&
                      formik.errors.mileage.toString()
                    }
                  />

                  {/*<Loader show={locations.length === 0} />*/}
                  <Dropdown
                    id="location"
                    label="Location"
                    required={true}
                    selectedKey={
                      formik.values.location
                        ? formik.values.location
                        : undefined
                    }
                    //options={yearValues}
                    options={locations}
                    onChange={handleDropdownChange}
                    placeholder="any..."
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.location &&
                      formik.errors.location &&
                      formik.errors.location.toString()
                    }
                  />

                  <Dropdown
                    id="vehicleHistory"
                    label="Vehicle History"
                    required={true}
                    selectedKeys={
                      formik.values.vehicleHistory
                        ? formik.values.vehicleHistory
                        : undefined
                    }
                    onChange={handleDropdownMultiChange}
                    multiSelect
                    placeholder=""
                    options={historyValues}
                    styles={dropdownStyles}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.vehicleHistory &&
                      formik.errors.vehicleHistory
                        ? formik.errors.vehicleHistory.toString()
                        : null
                    }
                  />

                  <div style={{ marginTop: 30 }}>
                    <ListItem
                      errorMessage={
                        (formik.touched.isInvoice && formik.errors.isInvoice) ||
                        (formik.touched.isLeasing && formik.errors.isLeasing) ||
                        (formik.touched.msrp && formik.errors.msrp) ||
                        (formik.touched.bookValue && formik.errors.bookValue)
                          ? "Required"
                          : null
                      }
                      //errorMessage={'test message'}
                      onClick={() => setFormStep(FormStep.Financial)}
                      title="Financial information"
                      subTitles={["Invoice", "Leasing"]}
                      count={
                        calcFilledFields(financialInformationItemList) > 0 &&
                        calcFilledFields(
                          financialInformationItemList
                        ).toString()
                      }
                      required={
                        !(calcFilledFields(financialInformationItemList) === 0)
                      }
                    />
                    <ListItem
                      errorMessage={
                        (formik.touched.driveType && formik.errors.driveType) ||
                        (formik.touched.fuelType && formik.errors.fuelType) ||
                        // (formik.touched.fuelEconomy &&
                        //   formik.errors.fuelEconomy) ||
                        (formik.touched.engineCylinders &&
                          formik.errors.engineCylinders) ||
                        (formik.touched.engineDescription &&
                          formik.errors.engineDescription) ||
                        (formik.touched.transmissionDescription &&
                          formik.errors.transmissionDescription)
                          ? "Required"
                          : null
                      }
                      onClick={() => setFormStep(FormStep.Engine)}
                      title="Engine and drive"
                      subTitles={[
                        "Driver Type",
                        "Fuel Type",
                        "Transmission",
                        "Cylinders",
                      ]}
                      count={
                        calcFilledFields(engineAndDriveItemList) > 0 &&
                        calcFilledFields(engineAndDriveItemList).toString()
                      }
                      required={!(calcFilledFields(engineAndDriveItemList) === 0)}
                    />
                    <ListItem
                      onClick={() => setFormStep(FormStep.Body)}
                      title="Body and color"
                      subTitles={[
                        "Body Style",
                        "Exterior Color",
                        "Interior Color",
                        "Doors",
                      ]}
                    />
                    <ListItem
                      onClick={handleOptionsClick}
                      title="Options"
                      subTitles={[
                        "Audio/Video",
                        "Power Equipment",
                        "Interior",
                        "Exterior",
                        "Safety Features",
                      ]}
                    />
                  </div>

                  <AddInventoryDropzone
                    setFiles={setPhotos}
                    //files={photos}
                    initFiles={photos}
                    filesToAdd={filesToAdd}
                    setFilesToAdd={setFilesToAdd}
                    filesToRemove={filesToRemove}
                    setFilesToRemove={handleRemoveFile}
                    //     files: any[];
                    // setFiles: (frriles) => void;
                    // onFilesAdded={(array) => {
                    //   setPhotos(array);
                    // }}
                  />

                  <AddInventoryVideoDropzone
                    setFiles={setVideo}
                    initFiles={video}
                    filesToAdd={videoFilesToSend}
                    setFilesToAdd={setVideoFilesToSend}
                    // filesToRemove={videoFilesToRemove}
                    setFilesToRemove={handleRemoveVideoFile}
                    //     files: any[];
                    // setFiles: (frriles) => void;
                    // onFilesAdded={(array) => {
                    //   setPhotos(array);
                    // }}
                  />
                  <WysiwygEditor
                    editorState={wysiwygEditorState}
                    setEditorState={setWysiwygEditorState}
                    htmlToSend={""}
                    setHtmlToSend={() => {}}
                    // htmlToSend={htmlToSend}
                    // setHtmlToSend={setHtmlToSend}
                    // onWysiwygAdded={(html) => {
                    //   setHtmlToSend(html);
                    // }}
                  />
                </Stack>
              ) : (
                <Stack tokens={styles.textFields}>
                  {secondaryDialogContent}
                </Stack>
              )}
            </Stack>
          </div>
          {showFooter && (
            <div className={contentStyles.footer}>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <DefaultButton
                    iconProps={{ iconName: "Trash" }}
                    text="Delete Inventory"
                    onClick={handleOpenConfirmDeletionBox}
                  />

                  {/* temporary hide */}
                  {/*<DefaultButton text="Save Draft" onClick={() => {}} />*/}
                </Stack>
                <Stack
                  tokens={styles.buttonTokens}
                  horizontal
                  horizontalAlign="end"
                >
                  <DefaultButton text="Close" onClick={onDismiss} />
                  <PrimaryButton
                    disabled={!formik.isValid}
                    onRenderIcon={renderSpinner}
                    text="Save Changes"
                    onClick={editInventory}
                  />
                </Stack>
              </Stack>
            </div>
          )}
        </div>
      </Modal>
      <ConfirmDialog
        title={"Update data with VIN"}
        isOpen={isConfirmVinModalOn}
        onDismiss={() => setIsConfirmVinModalOn(false)}
        onAction={() =>
          vinData && vinData.length > 0 && !isError(vinData)
            ? handleUpdateDataFromVin()
            : {}
        }
        description={createConfirmDescription(vinData)}
        loading={false}
        onlyDismiss={vinData && vinData.length > 0 && isError(vinData)}
      />
    </>
  );
};
