
import React, { useState, useEffect, useRef } from "react";
import { AppState } from "store/index";
import {
    PrimaryButton,
    Stack, IStackStyles
} from "@fluentui/react";
import GoBack from "components/buttons/goBack/GoBack";
//import Card from "components/card/Card";
//import { CustomFontText } from "components/customFontText/CustomFontText";
import { AddLaunchDialog } from "components/dialogs/websites/AddLaunchDialog";
///import StandardWebsiteTable from "components/table/standardWebsite/StandardWebsiteTable";
//import LandingScreenTable from "components/table/landingScreenTable/LandingScreenTable";
//import {
//  IStackTokens
//} from "@fluentui/react/lib/Stack";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { rowsPerPageArray } from "constants/constants";
import { WithCredentials, eTypes } from "hoc/withCredentials";
//import { useNavigate , useLocation } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { permissionsModules } from "../../constants/constants";
import { handleGetDealerWebsiteList, handleGetLandDealerWebsiteList } from "store/dealerWebsite/dealerwebsite.action";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { WebsitesMoreActionsSubmenu } from "components/table/websites/dropdown/WebsitesMoreActionsSubmenu"
import { deleteDealerWebsite } from "store/dealerWebsite/dealerwebsite.api"
import "./style.css"
import { IDealerWebsiteResponse } from "types/dealerWebsiteTypes";


const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
const credentialModuleName = "Location";

const WebsitesPage = () => {
    //const [showAddLaunchDialog, setShowAddLaunchDialog] = useState(false);
    //const navigate = useNavigate ();
    const dispatch = useDispatch();
    const [websiteId, setWebsiteId] = useState(0);
    const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [selectedWebSite, setWebsite] = useState({} as IDealerWebsiteResponse);
    const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
    const dtl = useRef(null);
    const dts = useRef(null);
    const [standardWebsiteList, setStandardWebsiteList] = useState([]);
    const [landingWebsiteList, setLandingWebsiteList] = useState([]);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    //  const website = useSelector((state: AppState) => state.website.website) as any; 
    //  const dealerWebsite= useSelector((state: AppState ) => state.dealerWebsite.landingItems)

    const standard = useSelector((state: AppState) => state.dealerWebsite.items) as any;
    const landing = useSelector((state: AppState) => state.dealerWebsite.landingItems) as any;
    const [confirmContent, setConfirmContent] = useState("");

    const [credentials, setCredentials] = useState([]);
    const [stFilter, setSTFilter] = useState('');
    const [laFilter, setLAFilter] = useState('');


    //   const location = useLocation();
    //   useEffect(() => {
    //     setIsOpenAddDialog(Boolean(history.location?.state));
    // },[history.location])

    const handleOnDismiss = () => {
        setIsOpenAddDialog(false);
    };

   
    // const handleOnEditDismiss = () => {
    //     setIsOpenEditDialog(false);
    // };

    // const handleWebsiteNameFilterStandard = (val) => {
    // //     console.log('standard');
    // //     console.log(dts.current);
    //   // dts.current.filter(val, 'name', 'contains');
         //   setSTFilter(val);
    // };

    // const handleWebsiteNameFilterLanding = (val) => {
    //     console.log('landing');
    //     console.log(dtl.current);
      
    //     dtl.current.filter(val, 'name', 'contains');
    //   //  setGlobalFilter(val);
       
    // };

    useEffect(() => {
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetDealerWebsiteList({ page: 1, limit: 1000, type: 'standard' }));
        dispatch<any>(handleGetLandDealerWebsiteList({ page: 1, limit: 1000, type: 'landing' }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tableFlag, setTableFlag] = useState(false);

    const handleOnDeleteWebsite = async () => {
        const { error } = await deleteDealerWebsite(websiteId);
        if (error?.message != null) {

        }
        else {
            if (tableFlag) {
                dispatch<any>(handleGetDealerWebsiteList({ page: 1, limit: 1000, type: 'standard' }));
            }
            else {
                dispatch<any>(handleGetLandDealerWebsiteList({ page: 1, limit: 1000, type: 'landing' }));
            }

            setIsConfirmBoxOpen(false);
        }
    }
    useEffect(() => {
        console.log('standard')
        console.log(standard)
        if (standard && standard.results && standard.results.length > 0) {
            console.log('st', standard.results);

            let results = standard.results.map(function (item) {
                item["locationsName"] = Array.prototype.map.call(item.locations, function (item) { return item.legalName; }).join(", ");
                return item;
            })
            setStandardWebsiteList(results);
        }
    }, [standard]);

    useEffect(() => {
        console.log('landing')
        console.log(landing)
        if (landing && landing.results && landing.results.length > 0) {
            //let results = landing.results;
            let results = landing.results.map(function (item) {
                item["websiteBody"] = Array.prototype.map.call(item.children, function (item) { return item.name; }).join(", ");
                return item;
            })

            setLandingWebsiteList(results);
        }
    }, [landing]);


    //  useEffect(() => {
    //   dispatch<any>(HANDLE_GET_LAND_DEALERWEBSITE()); //check this
    //   dispatch<any>(handleGetLandDealerWebsiteList);
    //  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const headerLanding = (
        <div className="table-header">
            {/*<Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />*/}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                {/* <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" /> */}
                <InputText type="search" value={laFilter} onChange={(e) => setLAFilter(e.target.value)} placeholder="Search by website" />
            </span>
        </div>
    );

    const headerStandard = (
        <div className="table-header">
            {/*<Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />*/}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                {/* <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" /> */}
                <InputText type="search" value={stFilter} onChange={(e) => setSTFilter(e.target.value)} placeholder="Search by website" />
            </span>
        </div>
    );
    // const handleRowClick = (e) => {
    //     // setWebsiteId(e.data.id);
    //     // setIsOpenEditDialog(true);
    // };

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                <span className="p-column-title">Name</span>
                {rowData.name}
             
            </React.Fragment>
        );
    };

    const brandBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Brand</span>
                {rowData.brand.name}
            </React.Fragment>
        );
    };

   
    const domainBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                
                <span className="p-column-title">Domain</span>
                {rowData.domain}
                {/* {rowData.inventory.make} */}
               
            </React.Fragment>
        );
    };

    const cNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CName</span>
                {rowData.canonicalName}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {rowData.isActive ? 'Active' : 'InActive'}
            </React.Fragment>
        );
    };

    const locationNameBodyTemplate = (rowData) => {

        // const webs: string[] = new Array(0);
        // rowData.locations.forEach((web) => {
        //     webs.push(web.legalName);
        // });
        // const loc = webs.join(",");

        return (
            <React.Fragment>
                <span className="p-column-title">Location</span>
                {rowData.locationsName}
            </React.Fragment>
        );
    };

    const websiteBodyTemplate = (rowData) => {

        // const webs: string[] = [];
        // rowData.children.forEach((web) => {
        //     webs.push(web.name);
        // });
        // const websiteBody = webs.join(",");
        return (
            <React.Fragment>
                <span className="p-column-title">Website</span>
                {rowData.websiteBody}
            </React.Fragment>
        );
    };

    const handleEditWebsite = (id, flag) => {
        setWebsiteId(id);
        //get specific data using dispatch 
        //setWebsite()
        if (flag)
        {
            let selected = standardWebsiteList.filter(x => x.id === id)[0] as IDealerWebsiteResponse
            setWebsite(selected);
        }
        else
        {
            let selected = landingWebsiteList.filter(x => x.id === id)[0] as IDealerWebsiteResponse  
            setWebsite(selected);
        }
        
        setIsOpenEditDialog(true);

    };

    const handleRemoveWebsite = (id, flag) => {

        setWebsiteId(id);
        setConfirmContent("Are you sure you want to delete this website?");
        setIsConfirmBoxOpen(true);
        setTableFlag(flag);
    };

    const handleRowClick = (e, flag) => {
        setWebsiteId(e.data.id);
        if (flag)
        {
            let selected = standardWebsiteList.filter(x => x.id === e.data.id)[0] as IDealerWebsiteResponse
            setWebsite(selected);
        }
        else
        {
            let selected = landingWebsiteList.filter(x => x.id === e.data.id)[0] as IDealerWebsiteResponse  
            setWebsite(selected);
        }
        
        setIsOpenEditDialog(true);
    };



    const actionBodyTemplateForStandard = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <WithCredentials
                        groupAuths={credentials}
                        alias={permissionsModules.Location}
                        type={eTypes.UPDATE}
                        roles={user.roles}
                    >
                        <WebsitesMoreActionsSubmenu
                            modulePermissions={credentials}
                            websiteId={rowData.id}
                            onEdit={(websiteId) => handleEditWebsite(websiteId, true)}
                            onDelete={(websiteId) => handleRemoveWebsite(websiteId, true)}
                        />
                    </WithCredentials>
                )}


            </React.Fragment>
        );
    };
    const actionBodyTemplateLand = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <WithCredentials
                        groupAuths={credentials}
                        alias={permissionsModules.Location}
                        type={eTypes.UPDATE}
                        roles={user.roles}
                    >
                        <WebsitesMoreActionsSubmenu
                            modulePermissions={credentials}
                            websiteId={rowData.id}
                            onEdit={(websiteId) => handleEditWebsite(websiteId, false)}
                            onDelete={(websiteId) => handleRemoveWebsite(websiteId,false)}
                        />
                    </WithCredentials>
                )}


            </React.Fragment>
        );
    };


    return (
        <>
            {/*<Loader show={loading} />*/}
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="web_btn_par_con"
                >

                    <Stack horizontal
                        verticalAlign="center"
                        tokens={{ childrenGap: 30 }}>
                        <GoBack label={"Websites"} />

                        {credentials && user.roles && (
                            <WithCredentials
                                groupAuths={credentials}
                                alias={permissionsModules.Location}
                                type={eTypes.WRITE}
                                roles={user.roles}
                            >
                                <PrimaryButton
                                    onClick={() => setIsOpenAddDialog(true)}
                                    text="Add new website"
                                    iconProps={{ iconName: "CircleAdditionSolid" }}
                                />
                            </WithCredentials>
                        )}


                        {/* 
                        <Stack tokens={{ childrenGap: 30 }} style={{ marginTop: 10 }}>
                            {showAddLaunchDialog && (
                                <AddLaunchDialog
                                    isOpen={showAddLaunchDialog}
                                    onDismiss={() => setShowAddLaunchDialog(false)}
                                />
                            )} */}

                        <AddLaunchDialog
                            isOpen={isOpenAddDialog}
                            onDismiss={() => handleOnDismiss()}
                            
                            
                        />
                         {isOpenEditDialog&& (
                        <AddLaunchDialog 
                            isOpen= {isOpenEditDialog}
                            dealerWebsite={selectedWebSite}
                            editable={true} 
                            onDismiss={() => setIsOpenEditDialog(false)}
                            //  onDismiss={() => handleOnEditDismiss()}
                         /> 
                         )}
                     
                         <ConfirmDialog
                            cancelBtnLabel="No"
                            confirmBtnLabel="Yes"
                            loading={false}
                            description={confirmContent}
                            title="Confirm delete website"
                            onlyDismiss={confirmContent !== "Are you sure you want to delete this website?"}
                            isOpen={isConfirmBoxOpen}
                            onAction= {handleOnDeleteWebsite}
                                       
                        
                            onDismiss={() => setIsConfirmBoxOpen(false)}
                        /> 
                    </Stack>
                </Stack>
                <h3>Standard Website</h3>
                <div className="datatable-filter-demo">
                    <div className="card" >

                        <DataTable ref={dts}
                            value={standardWebsiteList}
                            paginator
                            rows={10}
                            globalFilter={stFilter}

                            rowsPerPageOptions={rowsPerPageArray}
                            totalRecords={standard.totalItems}
                            rowHover={true}
                            header={headerStandard}
                            onRowClick={(e) => handleRowClick(e,true)}
                            className="p-datatable-customers"
                            emptyMessage="No Websites found.">

                            <Column field="name" header="Name" body={nameBodyTemplate}  sortable />
                            <Column field="brand.name" header="Brand" body={brandBodyTemplate} sortable />
                            <Column field="domain" header="Domain" body={domainBodyTemplate} sortable />
                            <Column field="canonicalName" header="CName" body={cNameBodyTemplate} sortable />
                            <Column field="isActive" header="Status" body={statusBodyTemplate} sortable />
                            <Column field="locationsName" header="Location" body={locationNameBodyTemplate} sortable />
                            <Column field="action" header="Actions" body={actionBodyTemplateForStandard} />
                        </DataTable>
                    </div>
                    <h3>Landing Website</h3>
                    <div className="card">
                        <DataTable ref={dtl}
                            value={landingWebsiteList}
                            paginator
                            rows={10}
                            globalFilter={laFilter}

                            rowsPerPageOptions={rowsPerPageArray}
                            totalRecords={landing.totalItems}
                            rowHover={true}
                            header={headerLanding}
                            onRowClick={(e) => handleRowClick(e,false)}
                            className="p-datatable-customers"
                            emptyMessage="No Websites found.">

                            <Column field="name" header="Name" body={nameBodyTemplate} sortable />
                            <Column field="brand.name" header="Brand" body={brandBodyTemplate} sortable />
                            <Column field="websiteBody" header="Websites" body={websiteBodyTemplate} sortable />
                            <Column field="domain" header="Domain" body={domainBodyTemplate} sortable />
                            <Column field="canonicalName" header="CName" body={cNameBodyTemplate} sortable />
                            <Column field="action" header="Actions" body={actionBodyTemplateLand} />

                        </DataTable>
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default WebsitesPage;




