import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import GoBack from "components/buttons/goBack/GoBack";
import { formatPathname } from "utils/helperFunctions";
import { Col, Row } from "react-grid-system";
import { useLocation } from "react-router-dom";
import { //FontIcon, 
    PrimaryButton
} from "@fluentui/react";
import { DealSettingsPivot } from "pages/dealSettingsPage/pivot/DealSettingsPivot";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import * as Yup from "yup";
import { styles } from "./styles";
import { useFormik } from "formik";
import {
  handleGetDealSettings,
  handleUpdateDealSettings,
} from "store/deals/deals.action";
import { useDispatch, useSelector } from "react-redux";
// import { IDealSettings } from "types/dealTypes";
import { AppState } from "store";
import { WithCredentials, eTypes } from "hoc/withCredentials";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { permissionsModules } from "constants/constants";
import { stringFromCurrencyFormat } from "utils/helperFunctions";

const DealSettingsPage = () => {
  const [pathname, setPathname] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();

  const loading = useSelector((state: AppState) => state.deals.loading);
  const dealSettings = useSelector(
    (state: AppState) => state.deals.dealSettings
  );
  const userProfile = useSelector(
    (state: AppState) => state.userProfile.userProfile
  );

  const formik = useFormik({
    initialValues: {
      dealsActivity: 0,
      dealsInactivity: 0,
      stateTax: 0,
      stateTaxMax: "",
      countryTax: 0,
      countryTaxMax: "",
      contractStateTax: 0,
      contractStateTaxMax: "",
      contractLocalTax: 0,
      contractLocalTaxMax: "",

      licenseFee: "",
      titleFee: "",
      docFee: "",
      tireFee: "",
      registrationFee: "",
      lenderProcessing: "",

      otherFee: "",
      lienFee: "",
    },
    validationSchema: Yup.object({
      dealsActivity: Yup.number().typeError("Value must be a number"),
      dealsInactivity: Yup.number().typeError("Value must be a number"),
      stateTax: Yup.number().typeError("Value must be a number"),
      stateTaxMax: Yup.string(),
      countryTax: Yup.number().typeError("Value must be a number"),
      countryTaxMax: Yup.string(),
      contractStateTax: Yup.number().typeError("Value must be a number"),
      contractStateTaxMax: Yup.string(),
      contractLocalTax: Yup.number().typeError("Value must be a number"),
      contractLocalTaxMax: Yup.string(),

      licenseFee: Yup.string(),
      docFee: Yup.string(),
      titleFee: Yup.string(),
      tireFee: Yup.string(),
      registrationFee: Yup.string(),
      lenderProcessing: Yup.string(),
      otherFee: Yup.string(),
      lienFee: Yup.string(),
    }),
    onSubmit: (values) => {
      dispatch<any>(
        handleUpdateDealSettings({
          ...values,
          stateTaxMax: parseInt(stringFromCurrencyFormat(values.stateTaxMax)),
          countryTaxMax: parseInt(stringFromCurrencyFormat(values.countryTaxMax)),
          contractStateTaxMax: parseInt(stringFromCurrencyFormat(values.contractStateTaxMax)),
          contractLocalTaxMax: parseInt(stringFromCurrencyFormat(values.contractLocalTaxMax)),

          licenseFee: parseInt(stringFromCurrencyFormat(values.licenseFee)),
          docFee: parseInt(stringFromCurrencyFormat(values.docFee)),
          titleFee: parseInt(stringFromCurrencyFormat(values.titleFee)),
          tireFee: parseInt(stringFromCurrencyFormat(values.tireFee)),
          registrationFee: parseInt(stringFromCurrencyFormat(values.registrationFee)),
          lenderProcessing: parseInt(stringFromCurrencyFormat(values.lenderProcessing)),

          otherFee: parseInt(stringFromCurrencyFormat(values.otherFee)),
          lienFee: parseInt(stringFromCurrencyFormat(values.lienFee)),

        })
      );
    },
  });

  useEffect(() => {
    dispatch<any>(handleGetDealSettings());
  }, [dispatch]);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (dealSettings) {

      
      

      formik.setValues({
        dealsActivity: dealSettings.dealsActivity,
        dealsInactivity: dealSettings.dealsInactivity,
        stateTax: dealSettings.stateTax,
        stateTaxMax: Boolean(dealSettings.stateTaxMax/100) ? (dealSettings.stateTaxMax/100).toString() : "",
       // stateTaxMax: dealSettings.stateTaxMax,
        countryTax: dealSettings.countryTax,
        // countryTaxMax: dealSettings.countryTaxMax,
         countryTaxMax: Boolean(dealSettings.countryTaxMax/100) ? (dealSettings.countryTaxMax/100).toString() : "",
        contractStateTax: dealSettings.contractStateTax,
        // contractStateTaxMax: dealSettings.contractStateTaxMax,
         contractStateTaxMax: Boolean(dealSettings.contractStateTaxMax/100) ? (dealSettings.contractStateTaxMax/100).toString() : "",

        contractLocalTax: dealSettings.contractLocalTax,
        //contractLocalTaxMax: dealSettings.contractLocalTaxMax,
        contractLocalTaxMax: Boolean(dealSettings.contractLocalTaxMax/100) ? (dealSettings.contractLocalTaxMax/100).toString() : "",
        licenseFee: Boolean(dealSettings.licenseFee/100) ? (dealSettings.licenseFee/100).toString() : "",
        titleFee: Boolean(dealSettings.titleFee/100) ? (dealSettings.titleFee/100).toString() : "",
        docFee: Boolean(dealSettings.docFee/100) ? (dealSettings.docFee/100).toString() : "",
        tireFee: Boolean(dealSettings.tireFee/100) ? (dealSettings.tireFee/100).toString() : "",
        registrationFee: Boolean(dealSettings.registrationFee/100) ? (dealSettings.registrationFee/100).toString() : "",
        lenderProcessing: Boolean(dealSettings.lenderProcessing/100) ? (dealSettings.lenderProcessing/100).toString() : "",
        otherFee: Boolean(dealSettings.otherFee/100) ? (dealSettings.otherFee/100).toString() : "",
        lienFee: Boolean(dealSettings.lienFee/100) ? (dealSettings.lienFee/100).toString() : "",

      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealSettings]);

  const renderSpinner = () => {
    return loading ? (
      <Spinner
        styles={{
          root: {
            paddingRight: 5,
          },
        }}
        size={SpinnerSize.medium}
      />
    ) : null;
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <div>
          <GoBack label={formatPathname(pathname)} />
        </div>
      </Stack>
      <Row>
        <Col style={styles.col} xs={12} sm={12} md={12} xl={8} xxl={8}>
          <Stack
            styles={styles.headerStyles}
            horizontal
            verticalAlign="start"
            horizontalAlign="end"
          >
            {/*<FontIcon iconName="Edit" className={styles.iconStyles} />*/}
          </Stack>

          <DealSettingsPivot formik={formik} />

          <WithCredentials
            groupAuths={
              isUserMaster(userProfile.roles)
                ? []
                : getModulePermissions(
                    userProfile.auths,
                    permissionsModules.DealSettings
                  )
            }
            type={eTypes.UPDATE}
            alias={"DealSettings"}
            roles={userProfile.roles}
          >
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={styles.buttonsTokens}
              styles={styles.buttonsStyles}
            >
              <PrimaryButton
                styles={{}}
                // disabled={!formik.dirty || !formik.isValid}
                onRenderIcon={renderSpinner}
                text="Save Changes"
                onClick={() => formik.handleSubmit()}
              />
            </Stack>
          </WithCredentials>
        </Col>
      </Row>
    </Stack>
  );
};

export default DealSettingsPage;
