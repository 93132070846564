// //import { IRegister, ILogin, ILoginResponse } from "types/authTypes";
// import { ICreateEmployee, IEmployee } from "types/employeesTypes";
import { IUserProfile, IOwner, IDealerProfile, IUserProfileData} from "types/userProfileTypes";

/** VIEW_EMPLOYEE */
export const HANDLE_GET_USER_PROFILE = "HANDLE_GET_USER_PROFILE";

export interface HandleGetUserProfile {
    type: typeof HANDLE_GET_USER_PROFILE;
    payload: {id: string | number, isEmployee?: boolean};
}

export const GET_USER_PROFILE_PENDING = "GET_USER_PROFILE_PENDING";

export interface GetUserProfilePending {
    type: typeof GET_USER_PROFILE_PENDING;
}

export const GET_USER_PROFILE_RESOLVED = "GET_USER_PROFILE_RESOLVED";

export interface GetUserProfileResolved {
    type: typeof GET_USER_PROFILE_RESOLVED;
    payload: any;
}

export const GET_USER_PROFILE_REJECTED = "GET_USER_PROFILE_REJECTED";

export interface GetUserProfileRejected {
    type: typeof GET_USER_PROFILE_REJECTED;
    payload: object;
}

/** UPDATE_UserProfile */
export const HANDLE_UPDATE_USER_PROFILE = "HANDLE_UPDATE_UserProfile";

export interface HandleUpdateUserProfile {
    type: typeof HANDLE_UPDATE_USER_PROFILE;
    payload: { id: string | number; data: IUserProfileData};
}

export const UPDATE_USER_PROFILE_PENDING = "UPDATE_USER_PROFILE_PENDING";

export interface UpdateUserProfilePending {
    type: typeof UPDATE_USER_PROFILE_PENDING;
}

export const UPDATE_USER_PROFILE_RESOLVED = "UPDATE_USER_PROFILE_RESOLVED";

export interface UpdateUserProfileResolved {
    type: typeof UPDATE_USER_PROFILE_RESOLVED;
    payload: IUserProfile;
}

export const UPDATE_USER_PROFILE_REJECTED = "UPDATE_USER_PROFILE_REJECTED";

export interface UpdateUserProfileRejected {
    type: typeof UPDATE_USER_PROFILE_REJECTED;
    payload: object;
}

// UPDATE_DEALER_PROFILE
export const HANDLE_UPDATE_DEALER_PROFILE = "HANDLE_UPDATE_DEALER_PROFILE";

export interface HandleUpdateDealerProfile {
    type: typeof HANDLE_UPDATE_DEALER_PROFILE;
    payload: { id: string | number; data: any };
}

export const UPDATE_DEALER_PROFILE_PENDING = "UPDATE_DEALER_PROFILE_PENDING";

export interface UpdateDealerProfilePending {
    type: typeof UPDATE_DEALER_PROFILE_PENDING;
}

export const UPDATE_DEALER_PROFILE_RESOLVED = "UPDATE_DEALER_PROFILE_RESOLVED";

export interface UpdateDealerProfileResolved {
    type: typeof UPDATE_DEALER_PROFILE_RESOLVED;
    payload: IDealerProfile;
}

export const UPDATE_DEALER_PROFILE_REJECTED = "UPDATE_DEALER_PROFILE_REJECTED";

export interface UpdateDealerProfileRejected {
    type: typeof UPDATE_DEALER_PROFILE_REJECTED;
    payload: object;
}

// UPLOAD_USER_PHOTO
export const HANDLE_UPLOAD_USER_PHOTO = "HANDLE_UPLOAD_USER_PHOTO";

export interface HandleUploadUserPhoto {
    type: typeof HANDLE_UPLOAD_USER_PHOTO;
    payload: { id: string | number; formData: any };
}

export const UPLOAD_USER_PHOTO_PENDING = "UPLOAD_USER_PHOTO_PENDING";

export interface UploadUserPhotoPending {
    type: typeof UPLOAD_USER_PHOTO_PENDING;
}

export const UPLOAD_USER_PHOTO_RESOLVED = "UPLOAD_USER_PHOTO_RESOLVED";

export interface UploadUserPhotoResolved {
    type: typeof UPLOAD_USER_PHOTO_RESOLVED;
    payload: any;
}

export const UPLOAD_USER_PHOTO_REJECTED = "UPLOAD_USER_PHOTO_REJECTED";

export interface UploadUserPhotoRejected {
    type: typeof UPLOAD_USER_PHOTO_REJECTED;
    payload: object;
};

export const HANDLE_CLEAN_USER = "HANDLE_CLEAN_USER";

export interface HandleCleanUser {
    type: typeof HANDLE_CLEAN_USER;
}

/* Get Owners */
export const HANDLE_GET_OWNERS = "HANDLE_GET_OWNERS";

export interface HandleGetOwners {
    type: typeof HANDLE_GET_OWNERS;
}

export const GET_OWNERS_PENDING = "GET_OWNERS_PENDING";

export interface GetOwnersPending {
    type: typeof GET_OWNERS_PENDING;
}

export const GET_OWNERS_RESOLVED = "GET_OWNERS_RESOLVED";

export interface GetOwnersResolved {
    type: typeof GET_OWNERS_RESOLVED;
    payload: IOwner[];
}

export const GET_OWNERS_REJECTED = "GET_OWNERS_REJECTED";

export interface GetOwnersRejected {
    type: typeof GET_OWNERS_REJECTED;
    payload: object[];
}

/* Update Owners */
export const HANDLE_UPDATE_OWNER = "HANDLE_UPDATE_OWNER";

export interface HandleUpdateOwner {
    type: typeof HANDLE_UPDATE_OWNER;
    payload: { owners: IOwner[]};
}

export const UPDATE_OWNER_PENDING = "UPDATE_OWNER_PENDING";

export interface UpdateOwnerPending {
    type: typeof UPDATE_OWNER_PENDING;
}

export const UPDATE_OWNER_RESOLVED = "UPDATE_OWNER_RESOLVED";

export interface UpdateOwnerResolved {
    type: typeof UPDATE_OWNER_RESOLVED;
    payload: IOwner[];
}

export const UPDATE_OWNER_REJECTED = "UPDATE_OWNER_REJECTED";

export interface UpdateOwnerRejected {
    type: typeof UPDATE_OWNER_REJECTED;
    payload: object;
}

export type UserProfileActions =
    | HandleGetUserProfile
    | GetUserProfileRejected
    | GetUserProfileResolved
    | GetUserProfilePending
    | HandleUpdateUserProfile
    | UpdateUserProfilePending
    | UpdateUserProfileRejected
    | UpdateUserProfileResolved
    | HandleUpdateDealerProfile
    | UpdateDealerProfilePending
    | UpdateDealerProfileRejected
    | UpdateDealerProfileResolved
    | HandleUploadUserPhoto
    | UploadUserPhotoPending
    | UploadUserPhotoRejected
    | UploadUserPhotoResolved
    | HandleCleanUser
    | HandleGetOwners
    | GetOwnersPending
    | GetOwnersResolved
    | GetOwnersRejected
    | HandleUpdateOwner
    | UpdateOwnerPending
    | UpdateOwnerResolved
    | UpdateOwnerRejected;
