import React from "react";
import { useEffect, useState } from "react";
// import GoBackInfo from "components/buttons/goBack/GoBackInfo";
import {
  Stack,
  IStackStyles,
  Link,
  //Dropdown,
  PrimaryButton
} from "@fluentui/react";
//import { useNavigate  } from "react-router-dom";
import {
    //useDispatch,
    useSelector
} from "react-redux";
//import { handleGetLocations } from "store/locations/locations.action";
import { AppState } from "store/index";
import {
  //getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { emplyeePermissionsAction } from "../../constants/constants";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
//import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
//import { Dropdown } from "@fluentui/react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { 
HANDLE_GET_EMPLOYEES
} from "store/employees/employees.types";
//import { TreeTable } from 'primereact/treetable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetSelectList} from "store/selectList/selectList.action";
import { handleGetLenderCountReports } from "store/dealTracker/dealTracker.action";
import { Dropdown as PRDropdown } from 'primereact/dropdown';

const container: IStackStyles = {
	root: {
		marginTop: 10,
	},
};
//const credentialModuleName = "Location";
export const FinnacePersonLenderCountReport = () => {
  const dispatch = useDispatch();
  //const navigate = useNavigate ();

  const currentMonth = new Date().getMonth() +1
  const currentYear = new Date().getFullYear()
  const employees = useSelector((state: AppState) => state.employees.employees) as any;
  const lenders = useSelector((state: AppState) => state.selectList.lenders) as any;
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  //const locations = useSelector((state: AppState) => state.locations.locations) as any; 
  const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);
  const [financePersonList, setFinancePersonList] = React.useState([]);
  const [lenderList, setLenderList] = React.useState([]);
  const [startMonthFilter, setStartMonthFilter] = useState(currentMonth);
  const [endMonthFilter, setEndMonthFilter] = React.useState(null);
  const [yearFilter, setYearFilter] = React.useState(currentYear);
  const [managerFilter, setManagerFilter] = React.useState(null);
  const [lenderFilter, setLenderFilter] = React.useState(0);

  const [isVal, setIsVal] = useState([]);
  const [isFinalData, setIsFinalData] = useState([]);
  const [endMonthOption, setEndMonthOption] = useState([]);
  
  const monthsOptions = masterDropdownValues.getMonth;
  const ExcelJS = require('exceljs');
const { saveAs } = require('file-saver');

// const workbook = new ExcelJS.Workbook();

 // const yearValues = [String(new Date().getFullYear()), String(new Date().getFullYear() +1)].map((item) => {
 //   return { key: item, text: item };
 // });

   const handleStartMonthDropdownChange = (e) => {
     //setStartMonthFilter(e.value);
     //handleFilters(item.key,endMonthFilter ,yearFilter,managerFilter,lenderFilter);
     handleEndMonthOptions(e.value);
    };

    const handleEndMonthDropdownChange = (e) => {
     setEndMonthFilter(e.value)
     //handleFilters(startMonthFilter,item.key ,yearFilter,managerFilter,lenderFilter);
    };

    const handleYearDropdownChange = (e) => {
     setYearFilter(e.value)
     //handleFilters(startMonthFilter,endMonthFilter ,item.key,managerFilter,lenderFilter);
    };

    const handleManagerDropdownChange = (e) => {
        setManagerFilter(e.value)
     //handleFilters(startMonthFilter,endMonthFilter,yearFilter,item.key,lenderFilter);
    };

    const handleLendersDropdownChange = (e) => {
        setLenderFilter (e.value)
     //handleFilters(startMonthFilter,endMonthFilter,yearFilter,managerFilter,item.key);
    }

  useEffect(() => {

    dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1,role:"financePerson" } })

    dispatch<any>(handleGetLocations());
    dispatch<any>(handleGetSelectList("lenders",1));
    handleEndMonthOptions(startMonthFilter);
    //handleFilters(startMonthFilter,endMonthFilter ,yearFilter,managerFilter,lenderFilter)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleEndMonthOptions = (startMonthFilter) => {
        setStartMonthFilter(startMonthFilter);
        if (startMonthFilter > 0 ) {
            if (monthsOptions) {
               const results = monthsOptions.filter(x => x.key > startMonthFilter);
                setEndMonthOption(results);
            }
            if (endMonthFilter && startMonthFilter >= endMonthFilter) {
                
                setEndMonthFilter(null);
            }
        } 
    }

    const handleResetWorkbook = () => {   
    
       setStartMonthFilter(currentMonth);
       setEndMonthFilter(null);
       setYearFilter(currentYear);
       setManagerFilter(null);
       setLenderFilter(0);
       //handleFilters(currentMonth,null ,currentYear,null,0)
       
    };
    //const handleFilters = (startMonthFilter, endMonthFilter,yearFilter,managerFilter,lenderFilter) => {
	    
    //    if (startMonthFilter > 0 || endMonthFilter > 0|| yearFilter > 0 || managerFilter > 0 || lenderFilter > 0) {

    //    const formData = {
    //            startMonth:startMonthFilter,
    //            endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
    //            year: yearFilter,
    //            financePersonId: managerFilter ? managerFilter: "" ,
    //            lenderId: lenderFilter ? lenderFilter: "" ,
    //           };
    //        dispatch<any>(handleGetLenderCountReports({
            
    //            formData,
    //            callback: (response) => {
    //            
    //                    if(response.length > 0){

    //                        handlePopulateReports(response)
    //                            console.log("have res",response);
    //                             if( managerFilter > 0 || lenderFilter > 0 || endMonthFilter > 0) {
    //                                 let result=  isVal

    //                                       if (result && endMonthFilter >0) {
    //                                          result = result.filter(x =>x.month <= endMonthFilter);

    //                                       }

    //                                       if (result && managerFilter >0 ) {
                                         
    //                                          result = result.filter(x => x.financePersonId === managerFilter);
                                          
    //                                       } 

    //                                       if (result && lenderFilter >0 ) {
                                         
    //                                          result = result.filter(x => x.lenderId === lenderFilter);
                                          
    //                                       } 
    //                                  setIsFinalData(result);
    //                             }
    //                    }else{
    //                         setIsFinalData(null)
    //                    }

    //                } 
    //        }));
            
    //    } 
    //}

    const handleFilters = () => {

        if (startMonthFilter > 0 || endMonthFilter > 0|| yearFilter || managerFilter || lenderFilter > 0) {

        const formData = {
                startMonth:startMonthFilter,
                endMonth: endMonthFilter ? endMonthFilter : startMonthFilter,
                year: yearFilter,
                financePersonId: managerFilter ? managerFilter: "" ,
                lenderId: lenderFilter ? lenderFilter: "" ,
            };
            dispatch<any>(handleGetLenderCountReports({

                formData,
                callback: (response) => {
                
                        if(response.length > 0){

                            handlePopulateReports(response)
                                 if( managerFilter > 0 || lenderFilter > 0 ) {
                                     let result=  isVal
                                     //if (result && formData.endMonth >0) {
                                     //         result = result.filter(x => Number(x.month) <= formData.endMonth);
                                     //}

                                     if (result && managerFilter >0 ) {
                                        result = result.filter(x => x.financePersonId === managerFilter);
                                     } 

                                     if (result && lenderFilter >0 ) {
                                        result = result.filter(x => x.lenderId === lenderFilter);
                                     } 
                                     setIsFinalData(result);
                                 }
                        }else{
                             setIsFinalData(null)
                        }

                    } 
            }));

        } 
    }

     const handlePopulateReports = (response) => {
      if (response && response.length) {
          let deskWorkbook = []
          response.forEach(function (response, index) {
              let taskObj = {
                       key: '' + index,
                       year: response.year, 
                       month:  response.month,
                       manager:  response.financePersonFirstName + ' '+ response.financePersonLastName,
                       financePersonId:  response.financePersonId,
                       lender:  response.lenderName,
                       lenderId:  response.lenderId,
                       units:  response.units,
               
              }
              deskWorkbook.push(taskObj);
              
          });
          setIsVal(deskWorkbook)
          setIsFinalData(deskWorkbook); 
      } 
  };

    useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startMonthFilter, endMonthFilter, yearFilter, managerFilter, lenderFilter]);

  useEffect(()=> {
    
            let person = [];
            if (employees.results.length > 0) {
                employees.results.map((item: any) => {
                    return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
                });
                person.unshift({ key: 0 ,text: 'All'});
                setFinancePersonList(person);
            }   
   }, [employees])

   useEffect(()=> {
   
            let lenderlist = [];
          if (lenders.length > 0) {
              lenders.map((item: any) => {
                  return lenderlist.push({ key: item.id, text: item.name })
              });
              lenderlist.unshift({ key: 0 ,text: 'All'});
              setLenderList(lenderlist);
          }   
   }, [lenders])

//    function getSheetData(data, header) {

//       var fields = Object.keys(data[0]);
//       var sheetData = data.map(function (row) {
//         return fields.map(function (fieldName) {
//           return row[fieldName]

//         });
//       });
//       sheetData.unshift(header);
//       return sheetData;
//    }

 const exportExcel = () => {
     if(isFinalData){
        let data = [...isFinalData]
        let header = ["Year", "Month","Finance Person", "Lender","Units"];
         const excelData = data.map((item)=> ({ 
                     "year":Number(item.year), 
                     "month":Number(item.month),
                     "financePerson": item.manager,
                     "Lender":item.lender,
                     "Units":Number(item.units),
          }));

        //import('xlsx').then(xlsx => {
        //    const worksheet = xlsx.utils.json_to_sheet(excelData);
        //    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        //    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        //    saveAsExcelFile(excelBuffer, 'excelData');
        //});

        const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Finance Person Lender Count Reports');

          // Add header row
          worksheet.addRow(header);

          // Add data rows
          excelData.forEach((row) => {
              worksheet.addRow([row.year, row.month, row.financePerson, row.Lender, row.Units]);
          });

          // Generate buffer
          workbook.xlsx.writeBuffer().then((buffer) => {
              const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              saveAs(blob, 'Finance Person Lender Count Reports.xlsx');
          });
      }
    }

    //const saveAsExcelFile = (buffer, fileName) => {
    //    import('file-saver').then(module => {
    //        if (module && module.default) {
    //            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //            let EXCEL_EXTENSION = '.xlsx';
    //            const data = new Blob([buffer], {
    //                type: EXCEL_TYPE
    //            });
    //
    //            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    //        }
    //    });
    //}

    const header = (
      <div >
        <div className=" table-header "  style={{marginBottom:"10px",display:"block"}} > 
            <Row  >
                 <Col md={12}>
                       <Row >
                               <Col md={2}  className="columnBtwSpace"  >
                                {/*<Dropdown
                                         id="startMonth"
                                         label="Start Month"
                                         selectedKey={startMonthFilter}
                                         options={masterDropdownValues.getMonth }
                                         onChange={handleStartMonthDropdownChange}
                                         placeholder=""
                                         className=" dropDownBorderColor" 
                                     />*/}
                                <label className="lbl-text">Start Month</label>
                                <PRDropdown
                                    id="startMonth"
                                    value={startMonthFilter}
                                    options={masterDropdownValues.getMonth}
                                    onChange={handleStartMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                               </Col>
                               <Col md={2}  className="columnBtwSpace">
                                {/*<Dropdown
                                         id="endMonth"
                                         label="End Month"
                                         selectedKey={endMonthFilter }
                                         options={endMonthOption}
                                         onChange={handleEndMonthDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">End Month</label>
                                <PRDropdown
                                    id="endMonth"
                                    value={endMonthFilter}
                                    options={endMonthOption}
                                    onChange={handleEndMonthDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                               </Col>
                               <Col md={2}  className="columnBtwSpace">
                                {/*<Dropdown
                                         id="year"
                                         label="Year"
                                         //required={true}
                                         selectedKey={yearFilter}
                                         //value={yearFilter}
                                         options={yearValues}
                                         onChange={handleYearDropdownChange}
                                         placeholder=""
                                     />*/}
                                <label className="lbl-text">Year</label>
                                <PRDropdown
                                    id="year"
                                    value={yearFilter}
                                    options={masterDropdownValues.from2022years}
                                    onChange={handleYearDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                               </Col>
                               <Col md={2}  className="columnBtwSpace">
                                {/*<Dropdown
                                         id="financePerson"
                                         label="Finance Person"
                                         selectedKey={managerFilter}
                                         options={financePersonList}
                                         onChange={handleManagerDropdownChange}  
                                         placeholder=""
           
                                     />*/}
                                <label className="lbl-text">Finance Person</label>
                                <PRDropdown
                                    id="financePerson"
                                    value={managerFilter}
                                    options={financePersonList}
                                    onChange={handleManagerDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={true}
                                />
                               </Col>
                               <Col md={2}  className="columnBtwSpace">
                                {/*<Dropdown
                                         id="lenders"
                                         label="Lender"
                                         selectedKey={lenderFilter}
                                         options={lenderList}
                                         onChange={handleLendersDropdownChange}  
                                         placeholder=""
           
                                     />*/}
                                <label className="lbl-text">Lender</label>
                                <PRDropdown
                                    id="lenders"
                                    value={lenderFilter}
                                    options={lenderList}
                                    onChange={handleLendersDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                />
                               </Col>
                               <Col md={2} className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                                     <PrimaryButton onClick={handleResetWorkbook} >
                                        Reset
                                     </PrimaryButton>
                               </Col>
                       </Row>
                 </Col>
            </Row>

        </div>
        <div  style={{marginLeft:"-8px",marginTop:"-20px",marginBottom:"-10px"}}>  
              <strong className="blueShadedText readField"> For single month, only select start month </strong>
        </div>

         <div style={{float:"right",marginRight:"-15px",fontSize:"16px",marginTop:"-15px"}}>
             <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
		            Export to Excel
		     </Link>    
             
        </div>
    </div>
    );
   

   const handleEditLocation = (id) => {
        
      
   };

   const captureCellSelection = (data) => {
        if (data && data.value.length && (data.value[0].field === 'Settings')) {
            data?.originalEvent?.target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else if (data && data.value.length) {
            const role = isUserMaster(user.roles);
            const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.VEHICLE_EDIT?.toString())) : true;
            if (cred || role) {
                handleEditLocation(data.value[0].rowData.id);
            }
        }
   };

   const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Year</span>
                {rowData.year}
            </React.Fragment>
        );
    };

    const startMonthBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Month</span>
                {rowData.month}
            </React.Fragment>
        );
    };
    const managerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Manager</span>
                {rowData.manager}
            </React.Fragment>
        );
    };
    const lenderBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Product</span>
                {rowData.lender}
            </React.Fragment>
        );
    };
    const unitsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Units</span>
                {rowData.units}
            </React.Fragment>
        );
    };

  return (

    	isKarlProfileType ? <>
          <div className="reportFinanceProductWidth">
			<Stack styles={container} tokens={{ childrenGap: 10 }}>

				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							 <CustomFontText  className="pageTitleFont pageName pageHeaderFont">Finance Person - Lender Count </CustomFontText>
						</Col>
					</Row>
				</Stack>
				<div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
					<div>

                                <DataTable
                                            value={isFinalData}
                                            header={header}
                                            className="p-datatable-customers reportHeaderPart"
                                            paginator={false}
                                            rows={100}
                                            ////cellSelection
                                            onSelectionChange={e => captureCellSelection(e)}>
                                            <Column field="year" header="Year" body={yearBodyTemplate} sortable/>
                                            <Column field="month" header="Month"  body={startMonthBodyTemplate} sortable/>
                                            <Column field="manager" header="Finance Person" body={managerBodyTemplate} sortable />
                                            <Column field="lender" header="Lender" body={lenderBodyTemplate} sortable/>                                       
                                            <Column field="units" header="Units"  body={unitsBodyTemplate} sortable/>
                                </DataTable>
					</div>
				</div>
			</Stack>
        </div>
		</> : null
  );
};
