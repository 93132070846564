import React, { FC, useState } from "react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import {
  Stack,
  IStackStyles,
  Icon,
  DefaultPalette,
} from "@fluentui/react";

const stackStyles: IStackStyles = {
  root: {
    // height: 50,
    // paddingTop: 40,
    // paddingBottom: 40,
    cursor: "pointer",
  },
};

const iconWrapper: IStackStyles = {
  root: {
    backgroundColor: DefaultPalette.themePrimary,
    borderRadius: "50%",
    color: "white",
    marginRight: 15,
    fontSize: 14,
    padding: 8,
  },
};

const iconWrapperHovered: IStackStyles = {
  root: {
    backgroundColor: DefaultPalette.themeDark,
    borderRadius: "50%",
    color: "white",
    marginRight: 15,
    fontSize: 14,
    padding: 8,
  },
};

interface IProps {
  label: string;
  onClick: () => void;
}

const GoBackToMainDialogBtn: FC<IProps> = ({ label, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Stack
      styles={stackStyles}
      // tokens={stackTokens}
      horizontal
      verticalAlign="center"
    >
      <Stack
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        styles={hovered ? iconWrapperHovered : iconWrapper}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <Icon iconName="Back" />
      </Stack>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <CustomFontText>{label}</CustomFontText>
      </Stack>
    </Stack>
  );
};

export default GoBackToMainDialogBtn;
