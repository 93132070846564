import React, { useState, FunctionComponent, useEffect } from "react";
import { IDates, ITask } from "types/taskTypes";
import {
  Button,
  DatePicker,
  DefaultPalette,
  mergeStyleSets,
  MessageBar,
  Stack,
} from "@fluentui/react";
import moment from "moment";
import {
  cancelIcon,
  colourBlack,
  colourDefault,
  colourGrey,
  colourRed,
  messageBarStyles,
} from "constants/styles";
import {
  formatDate,
  groupByDate,
  sortTasksByDate,
} from "utils/dateTimeFunctions";
import { ConfirmDialog } from "components/dialogs/confirmDialog/ConfirmDialog";
import { handleRemoveTask } from "store/prospects/prospects.action";
import { useDispatch } from "react-redux";
import { HANDLE_VIEW_PROSPECT } from "store/prospects/prospects.types";

interface IProps {
  tasks: ITask[];
  isLoading: boolean;
  more?: boolean;
  style?: any;
  /**
   * number of items to be shown if more is true
   * */
  maxShortItems?: number;
  title?: any;
  prospectId?: number;
  dashboard?: boolean;
}

const controlClass = mergeStyleSets({
  control: {
    margin: "0 0 15px 0",
    maxWidth: "300px",
  },
});

const messageBarStyle = {
  marginTop: 5,
};

const Scheduler: FunctionComponent<IProps> = ({
  tasks,
  isLoading,
  more,
  style,
  maxShortItems = 5,
  title,
  prospectId,
  dashboard,
}) => {
  const dispatch = useDispatch();

  const [items, setItems] = useState(null);
  const [chosenDate, setChosenDate] = useState(null);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<ITask | null>(null);

  useEffect(() => {
    if (tasks && tasks) {
      manageSetItems(tasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const handleDeleteItem = () => {
    console.log(taskToDelete);
    console.log(prospectId);
    

    taskToDelete &&
      dispatch<any>(
        handleRemoveTask({
          tasksToRemove: [
            // { prospectId: taskToDelete.prospect?.id, taskId: taskToDelete?.id },
            {
              prospectId: dashboard ? taskToDelete.prospect?.id : prospectId,
              taskId: taskToDelete?.id,
            },
          ],
          callback: dashboard ? () => {} : () => {
            dispatch<any>({
              type: HANDLE_VIEW_PROSPECT,
              payload: prospectId,
            });
          },
          isFromScheduler: !dashboard,
        })
      );
    setTaskToDelete(null);
    setIsConfirmBoxOpen(false);
  };

  const handleDateChange = (e) => {
    setChosenDate(e);
    // console.log("DATECHANGE", e);
    manageSetItems(getFilteredTasks(e));
  };

  const handleCleanDate = () => {
    setChosenDate(null);
    manageSetItems(tasks);
  };

  const manageSetItems = (elements: ITask[]) => {
    setItems(
      more
        ? [
            ...groupByDate(
              [...sortTasksByDate(elements)].slice(0, maxShortItems)
            ),
          ]
        : [...groupByDate(elements)]
    );
  };

  const getFilteredTasks = (date: string) => {
    return tasks.filter(
      (task: ITask) =>
        moment(task.date).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
    );
  };

  const openDeleteDialog = (task: ITask) => {
    setTaskToDelete(task);
    setIsConfirmBoxOpen(true);
  };

  const calculateColour = (type: string): string => {
    switch (type) {
      case "Overdue":
        return colourRed.color;
      case "Today":
        return colourDefault.color;
      case "Tomorrow":
        return colourGrey.color;
      default:
        return colourBlack.color;
    }
  };
  return (
    <>
      <Stack style={style}>
        <Stack horizontal horizontalAlign={"end"} verticalAlign={"center"} wrap>
          {!chosenDate && (
            <div
              style={{
                color: DefaultPalette.blackTranslucent40,
                fontSize: "0.9em",
                marginRight: 10,
                lineHeight: "20px",
                width: 320,
                textAlign: "right",
              }}
            >
              today: {moment().format("LLL")}
            </div>
          )}
          <div>
            <DatePicker
              className={controlClass.control}
              style={{ marginBottom: 0 }}
              placeholder="or select a date..."
              ariaLabel="Select a date"
              onSelectDate={(e) => handleDateChange(e)}
              isMonthPickerVisible={false}
              value={chosenDate}
              showGoToToday={false}
            />
          </div>
          {chosenDate ? (
            <Button
              text="Clean"
              ariaLabel="Clean"
              style={{ marginLeft: "10px", padding: "auto" }}
              iconProps={cancelIcon}
              onClick={handleCleanDate}
            />
          ) : null}
        </Stack>
        <div style={{ width: "100%" }}>
          {items && items.length > 0 ? (
            items.map((item: IDates, index: number) => (
              <div key={index} style={messageBarStyle}>
                <div
                  style={{
                    fontSize: "1.2 em",
                    fontWeight: 600,
                    marginBottom: 5,
                    marginTop: 15,
                    color:
                      item.date === "Overdue"
                        ? colourRed.color
                        : colourBlack.color,
                  }}
                >
                  {
                    /*formatDate(task.date)*/
                    item.date !== "Overdue" &&
                    item.date !== "Today" &&
                    item.date !== "Tomorrow"
                      ? formatDate(item.date)
                      : item.date === "Overdue" && item.tasks.length === 0
                      ? ""
                      : item.date
                  }
                </div>
                {item.tasks.map((
                  task: ITask /*@TODO: if more: show only first 5*/
                ) => (
                  <MessageBar
                    isMultiline={true}
                    onDismiss={() => openDeleteDialog(task)}
                    dismissButtonAriaLabel="Close"
                    messageBarIconProps={{ iconName: "" }}
                    styles={{
                      ...messageBarStyles,
                      root: {
                        borderLeft: `4px solid ${calculateColour(item.date)}`,
                        margin: "6px auto",
                      },
                    }}
                    key={task.id}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: task.content }}
                      className="message-table"
                    />
                    {task.additionalData && (
                      <span style={{ ...colourRed, fontWeight: 600 }}>
                        {task.additionalData}
                      </span>
                    )}
                  </MessageBar>
                ))}
              </div>
            ))
          ) : (
            <div
              style={{
                fontStyle: "italic",
                marginTop: "100px",
                width: "100%",
                textAlign: "center",
              }}
            >
              {"...no tasks to display"}
            </div>
          )}
        </div>
      </Stack>
      <ConfirmDialog
        loading={isLoading}
        description="Please confirm deletion of task"
        title="Confirm delete task"
        isOpen={isConfirmBoxOpen}
        onAction={handleDeleteItem}
        onDismiss={() => setIsConfirmBoxOpen(false)}
      />
    </>
  );
};

export default Scheduler;
