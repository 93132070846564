import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { colors } from "constants/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import {
  getModulePermissions,
  isUserMaster,
} from "utils/permissions/permissionsHelpers";
import AccountSettings from "./AccountSettings";
//import SMTPSettings from "./SMTPSettings";
import { useLocation, useNavigate  } from "react-router-dom";
//import TwilioSettings from "./TwilioSettings";


const border = {
  borderTop: `1px solid ${colors.separator}`,
  width: "100% !important",
  display: "block !important",
};

const credentialModuleName = "Config";
export const AccountSettingsPivot: React.FC = () => {
  // const [credentials, setCredentials] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState("0");

  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const navigate = useNavigate ();
  const location = useLocation();

  useEffect(() => {
    if (user && user.auths && !isUserMaster(user.roles)) {
      console.log(getModulePermissions(user.auths, credentialModuleName));
      // setCredentials(getModulePermissions(user.auths, credentialModuleName));
    }
    setSelectedItem(Boolean(location?.state) ? ".1" : ".0");

    // setSelectedItem(".1");


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Pivot
      style={{ padding: 0, margin: 0 }}
      className="pivotItem"
      onLinkClick={(item?: any) => {
        if (item.key === ".2") {
          console.log("item key", item.key);
          if (user.roles.includes("ROLE_SMS_TEXT")) {
            setSelectedItem(item.key);
            return;
          } else {
            navigate("/subscriptions");
            return;
          }
        }
        setSelectedItem(item.key);
      }}
      selectedKey={selectedItem}
    >
      <PivotItem
        itemKey=".0"
        className="pivotItem"
        style={border}
        headerText="Profile Settings"
        // headerButtonProps={{
        //   "data-order": 2,
        //   "data-title": "Company information",
        // }}
      >
        {/*<div>test profile settings</div>*/}
        <AccountSettings />
      </PivotItem>
          {/*<PivotItem
        itemKey=".1"
        className="pivotItem"
        style={border}
        headerText="SMTP Settings"
      >
        <SMTPSettings />
      </PivotItem>*/}

          {/*{isUserMaster(user.roles) && (
        <PivotItem
          itemKey=".2"
          className="pivotItem"
          style={border}
          headerText="Twilio Settings"
        >
          <TwilioSettings />
        </PivotItem>
      )}*/}
    </Pivot>
  );
};
