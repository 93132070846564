let i = 0;
export const getAddreessByZip = (zipCode) => {
    i++;
    console.log("Smarty Street Call #:" + i)
    return window.fetch(`${process.env.REACT_APP_SMARTYSTREETS_URL}&zipcode=` + zipCode)
        .then(res => res.json())
        .then(
            (result) => {
                let address = {
                    State: "",
                    DefaultCity: "",
                    DefaultCounty: "",
                    IsMultipleCity: false,
                    IsMultipleCounty: false,
                    Cities: [],
                    Counties: [],
                    Zip: "",
                    Status: true,
                    ErrorMessage: "",
                };
                if (result.id) {
                    address.ErrorMessage = "Please contact admin";
                    return address;
                }
                if (result && result.length && result[0].status && result[0].status === 'invalid_zipcode') {
                    address.Status = false;
                    address.ErrorMessage = result[0].reason;
                    return address;
                }

                if (result && result.length) {
                    address.State = result[0].zipcodes[0].state_abbreviation;
                    address.DefaultCity = result[0].zipcodes[0].default_city;
                    address.DefaultCounty = result[0].zipcodes[0].county_name;
                }

                if (result && result.length && (result[0].city_states.length >= 1)) {
                    address.IsMultipleCity = true;
                    address.Cities = result[0].city_states.map((item) => {
                        return { key: item.city, text: item.city };
                    });
                }
                if (result && result.length && result[0].zipcodes[0].alternate_counties && result[0].zipcodes[0].alternate_counties.length >= 1) {
                    address.IsMultipleCounty = true;
                    address.Counties = result[0].zipcodes[0].alternate_counties.map((item) => {
                        return { key: item.county_name, text: item.county_name };
                    });
                }
                address.Counties.push({ key: address.DefaultCounty, text: address.DefaultCounty })
                return address;
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                return {};
            }
        )
};