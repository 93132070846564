import {
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
} from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { IIconProps } from "@fluentui/react";
import { ILinkStyles } from '@fluentui/react/lib/Link';

interface IStyles {
  stackItemStyles: IStackItemStyles;
  stackTabletItemStyles: IStackItemStyles;
  stackItemStylesAutoHeight: IStackItemStyles;
  loginContentStyles: IStackStyles;
  buttonTokens: IStackTokens;
  textFields: IStackTokens;
  usernameIcon: IIconProps;
  passwordIcon: IIconProps;
  linkStyles: ILinkStyles;
}

export const styles: IStyles = {
    linkStyles: {
        root: {
            fontSize: 12,
            marginRight: 30,
            textDecoration: 'underline',
        }
    },
  stackItemStyles: {
    root: {
      boxShadow: Depths.depth8,
      background: DefaultPalette.white,
      padding: 15,
      borderRadius: 2,
      width: 350,
      height: 450,
      zIndex: 1,
      borderTop: "10px solid #65BC50"
    },
  },
  stackItemStylesAutoHeight: {
      root: {
          boxShadow: Depths.depth8,
          background: DefaultPalette.white,
          padding: 50,
          borderRadius: 2,
          width: 280,
          zIndex: 1,
      },
  },
  stackTabletItemStyles: {
    root: {
      boxShadow: Depths.depth8,
      background: DefaultPalette.white,
      padding: "5vw",
      borderRadius: 2,
      width: '90vw',
      // width: "70vw",
      height: 400,
      zIndex: 1,
      marginTop: '40px !important',
     /* selectors: {
        ':focus-within': {
          // maxWidth: "90vw",
          // marginTop: "400px !important",
          //
          // paddingBottom: '400px !important'
          paddingBottom: 300
        }
      }*/
    },
  },
  loginContentStyles: {
    root: {
      height: "100%",
    },
  },
  buttonTokens: {
    childrenGap: "5",
  },
  textFields: {
    childrenGap: "15",
  },
  usernameIcon: { iconName: "Signin" },
  passwordIcon: { iconName: "Hide" },
};
