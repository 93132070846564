import * as React from "react";
import { TextField, Selection, SelectionMode } from "@fluentui/react";
import { DetailsList, IColumn } from "@fluentui/react/lib/DetailsList";

interface IProps {
  formik: any;
  setRate: Function;
  setTerm: Function;
  setSelection: Function;
}

interface IState {
  sortedItems: any[];
  columns: IColumn[];
  selectionDetails: any;
}

export default class DetailsListCustomColumnsExample extends React.Component<
  IProps,
  IState
> {
  private readonly _selection: Selection;

  constructor(props) {
    super(props);

    const items = [
      { key: 0, rate: "", term: "" },
      { key: 1, rate: "", term: "" },
      { key: 2, rate: "", term: "" },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.props.setSelection(this._selection.getSelection());

        this.setState({
          selectionDetails: this._selection.getSelection(),
        });
      },
    });

    this.state = {
      selectionDetails: this._selection.getSelection(),
      sortedItems: items,
      columns: [
        {
          key: "variant",
          name: "Variant",
          fieldName: "",
          minWidth: 70,
          // maxWidth: 130,
          isResizable: true,
          isRowHeader: true,
          data: "string",
          isPadded: false,
        },
        {
          key: "terms",
          name: "Terms",
          fieldName: "",
          minWidth: 170,
          maxWidth: 200,
          isResizable: true,
          isRowHeader: true,
          data: "string",
          isPadded: false,
        },

        {
          key: "rates",
          name: "Rates",
          fieldName: "",
          minWidth: 170,
          maxWidth: 200,
          isResizable: true,
          isRowHeader: true,
          data: "string",
          isPadded: false,
        },
        {
          key: "",
          name: "",
          fieldName: "",
          minWidth: 10,
          maxWidth: 20,
          isResizable: false,
          isRowHeader: true,
          data: "string",
          isPadded: false,
        },
      ],
    };
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (
      prevProps.formik.values.variants !== this.props.formik.values.variants
    ) {
      this.setState({
        columns: [...this.state.columns],
      });
    }
  }

  public render() {
    const { sortedItems, columns } = this.state;

    return (
      <DetailsList
        items={sortedItems}
        setKey="set"
        columns={columns}
        onRenderItemColumn={_renderItemColumn.bind(this)}
        onColumnHeaderClick={this._onColumnClick}
        onItemInvoked={this._onItemInvoked}
        onColumnHeaderContextMenu={this._onColumnHeaderContextMenu}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="Row checkbox"
        selection={this._selection}
        selectionMode={SelectionMode.multiple}
      />
    );
  }

  private _onColumnClick = (
    event: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    let { sortedItems } = this.state;
    let isSortedDescending = column.isSortedDescending;

    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Sort the items.
    sortedItems = _copyAndSort(
      sortedItems,
      column.fieldName!,
      isSortedDescending
    );

    // Reset the items and columns to match the state.
    this.setState({
      sortedItems: sortedItems,
      columns: columns.map((col) => {
        col.isSorted = col.key === column.key;

        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }

        return col;
      }),
    });
  };

  private _onColumnHeaderContextMenu(
    column: IColumn | undefined,
    ev: React.MouseEvent<HTMLElement> | undefined
  ): void {
    console.log(`column ${column!.key} contextmenu opened.`);
  }

  private _onItemInvoked(item: any, index: number | undefined): void {
    alert(`Item ${item.name} at index ${index} has been invoked.`);
  }
}

function _renderItemColumn(item, index: number, column: IColumn) {
  const { formik } = this.props;

  switch (column.key) {
    case "variant":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span>{index + 1}</span>
        </div>
      );

    case "terms":
      return (
        <TextField
          style={{ flex: 1 }}
          value={formik.values.variants[index].term}
          onChange={(e: any) => {
            this.props.setTerm({ term: e.target.value, index });
          }}
        />
      );

    case "rates":
      return (
        <TextField
          style={{ flex: 1 }}
          value={formik.values.variants[index].rate}
          onChange={(e: any) => {
            this.props.setRate({ rate: e.target.value, index });
          }}
        />
      );

    case "":
      return null;
    default:
      return <span>default value</span>;
  }
}

function _copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T;
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
}
