import * as React from "react";
import { useState, useEffect } from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  DefaultButton,
  Modal,
  IconButton,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "pages/accountSettingsPage/styles";
import { ConfigureGroupPivot } from "components/pivots/configureGroup/ConfigureGroupPivot";
import { useDispatch, useSelector } from "react-redux";
import {
  handleUpdateGroup,
  handleViewGroup,
  handleDeleteGroup,
} from "store/groups/groups.action";
import { AppState } from "store";
import {
  ISpinnerStyles,
  Spinner,
  SpinnerSize,
} from "@fluentui/react/lib/Spinner";
import { clearCurrPageInGroupsAssignAndAssigned } from "store/employees/employees.action";
import { cancelIcon, iconButtonStyles } from "constants/styles";


const spinnerStyles: ISpinnerStyles = {
  root: {
    //position: "absolute",
    //right: 150,
  },
};

interface IProps {
  groupId: number;
  isOpen: boolean;
  onDismiss: () => void;
}

export const NewEditGroupDialog: React.FunctionComponent<IProps> = ({
  groupId,
  isOpen,
  onDismiss,
}) => {
  const [configureGroupName, setConfigureGroupName] = useState("");
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesToRemove, setEmployeesToRemove] = useState([]);

  const updateLoading = useSelector(
    (state: AppState) => state.groups.loadingUpdate
  );
  const selectedGroup = useSelector(
    (state: AppState) => state.groups.selectedGroup
  );

  const loadingDelete = useSelector(
    (state: AppState) => state.groups.loadingDelete
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId) {
      console.log("groupId", groupId);
      dispatch<any>(handleViewGroup(groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    setConfigureGroupName(selectedGroup?.name);
  }, [selectedGroup]);

  const onHandleDeleteClick = () => {
    dispatch<any>(handleDeleteGroup(groupId?.toString(), onDismiss));
  };

  const handleGroupNameChange = (e: any) => {
    setConfigureGroupName(e.target.value);
  };
  const clearCurrentPage = () => {
    console.log("test-clear-current-page");
    // TODO clear currentPage Assign/Assigned

    dispatch<any>(clearCurrPageInGroupsAssignAndAssigned());
  };
  const handleSaveChanges = () => {
    dispatch<any>(
      handleUpdateGroup(selectedGroup?.id, configureGroupName, {
        toAdd: employeesToAdd,
        toRemove: employeesToRemove,
        callback: onDismiss
      })
    );
    //if save btn will close modal uncomment the line below
    // clearCurrentPage();
  };
  const closeDialogHandle = () => {
    onDismiss();
    console.log("test-test-test");
    clearCurrentPage();
  };
  const renderSpinner = () => {
    return updateLoading ? (
      <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };
  const renderDeleteSpinner = () => {
    return loadingDelete ? (
      <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };
  return (
    <Modal
      className="configureGroup"
      isOpen={isOpen}
      onDismiss={closeDialogHandle}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <CustomFontText>Configure Group</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={closeDialogHandle}
        />
      </div>
      <div className={contentStyles.body}>
        <TextField
          label="Group Name"
          value={configureGroupName}
          onChange={handleGroupNameChange}
        />
        <div style={{ paddingTop: 20 }}>
          <ConfigureGroupPivot
            employeesToAdd={(employees) => setEmployeesToAdd(employees)}
            employeesToRemove={(employees) => setEmployeesToRemove(employees)}
          />
        </div>
      </div>
      <div className={contentStyles.footer}>
        <Stack
          tokens={styles.buttonTokens}
          horizontal
          horizontalAlign="space-between"
        >
          <div>
            <DefaultButton
              onClick={onHandleDeleteClick}
              iconProps={{ iconName: "Delete" }}
              text="Delete Group"
              onRenderIcon={renderDeleteSpinner}
            />
          </div>
          <Stack tokens={{ childrenGap: 5 }} horizontal>
            <DefaultButton text="Close" onClick={closeDialogHandle} />
            <PrimaryButton
              // disabled={!formik.dirty || !formik.isValid}
              onRenderIcon={renderSpinner}
              text="Save Changes"
              onClick={handleSaveChanges}
            />
          </Stack>
        </Stack>
      </div>
    </Modal>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    // width: "50%",
    // width: 2000,
    // display: 'flex',
    // flexFlow: 'column nowrap',
    // alignItems: 'stretch',
  },
  header: {
    // width: '800px',
    flex: "1 1 auto",
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "20px 30px",
  },

  body: {
    // width: 600,
    // flex: "4 4 auto",
    // padding: "0 24px 24px 24px",
    //   padding: '75px',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: "40px",
    // overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  footer: {
    padding: "0px 30px",
    paddingBottom: 40,
  },
});
