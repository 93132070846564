import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  IDetailsListStyles,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import {
  DefaultPalette,
  Stack,
  IStackStyles,
  IStackTokens,
  FontIcon,
  ActionButton,
  IButtonStyles,
  Modal,
  IconButton,
  DefaultButton,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import { Pagination } from "components/table/pagination/Pagination";
import { connect } from "react-redux";
import { /**Image, ImageFit, */ mergeStyles } from "@fluentui/react";
import { CreateEmailDialog } from "components/dialogs/createEmailDialog/createEmailDialog";

import {
  HANDLE_GET_PROSPECTS,
  SET_ROWS_PER_PAGE_PROSPECTS,
  HANDLE_UPDATE_PROSPECT,
} from "store/prospects/prospects.types";
import { IDeal } from "types/dealTypes";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as contentStyle,
  searchStyle as searchStyles,
  cellLineHeight,
} from "constants/styles";
import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import { sortColumns } from "../../../utils/helperFunctions";

const searchStyle = searchStyles(0);

const contentStyles = contentStyle(1080, "auto");
const cellFontSize: number = 13;
const cellMaxHeight: string = "35px";
const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};
const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
    width: "auto",
  },
};

const Types = {
  1: "Prospect",
  2: "Co-signer",
};

const textStyles = mergeStyles({
  fontSize: 16,
});

export interface IDocument {
  id: number;
  key: string;
  firstName: string;
  lastName: string;
  city: string;
  zip: string;
  phone: string;
  email: string;
  isFavourite: boolean;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 20,
    paddingTop: 20,
  },
};

const cellStyles: IStackStyles = {
  root: {
    //height: height,
    lineHeight: cellLineHeight,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

const iconStyle = {
  color: DefaultPalette.themePrimary,
  marginLeft: 7,
  fontSize: 16,
  height: "16px",
};

const cellStyle = {
  fontSize: cellFontSize,
  display: "flex",
  // justifyContent: 'center',
  // alignItems:'center'
};

class AddProspectToDealDialog extends React.Component<any, any> {
  private readonly _items: any;
  private readonly _selection: Selection;

  // private _contextMenuRef: any;

  constructor(props: {}) {
    super(props);

    this._items = [];

    const columns: any[] = [
      {
        key: "firstName",
        name: "First name",
        fieldName: "firstName",
        minWidth: 100,
        maxWidth: 180,
        isResizable: true,
        isRowHeader: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: false,

        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.firstName}</span>;
        },
      },
      {
        key: "lastName",
        name: "Last name",
        fieldName: "lastName",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        data: "string",
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.lastName}</span>;
        },
        isPadded: false,
      },
      {
        key: "place",
        name: "City, zip code ",
        fieldName: "place",
        minWidth: 100,
        maxWidth: 170,
        isResizable: true,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.city}, {item.zip}
              <FontIcon style={iconStyle} iconName="MapDirections" />
            </span>
          );
        },
        // onColumnClick: _onColumnClick,
        isPadded: false,
      },
      {
        key: "phone",
        name: "Cell phone",
        fieldName: "phone",
        minWidth: 120,
        maxWidth: 170,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.phone}
              <FontIcon style={iconStyle} iconName="Phone" />
            </span>
          );
        },
        isPadded: false,
      },
      {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return (
            <span className={textStyles} style={cellStyle}>
              {item.email}
              <span onClick={() => this._handleSendEmail(item.email)}>
                <FontIcon style={iconStyle} iconName="Send" />
              </span>
            </span>
          );
        },
      },
      {
        key: "favorites",
        name: "Favorites",
        fieldName: "isFavourite",
        minWidth: 60,
        maxWidth: 110,
        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: IDocument) => {
          let iconName;
          let iconHoveredName;

          if (item.isFavourite) {
            iconName = "FavoriteStarFill";
            iconHoveredName = "Unfavorite";
          } else {
            iconName = "AddFavorite";
            iconHoveredName = "FavoriteStarFill";
          }

          return (
            <FontIcon
              onClick={() => this._handleUpdateFavourites(item)}
              onMouseEnter={() => {
                this.setState({
                  iconHovered: item.id,
                  columns: [...this.state.columns],
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  iconHovered: null,
                  columns: [...this.state.columns],
                });
              }}
              style={{ fontSize: 22, color: DefaultPalette.themePrimary }}
              iconName={
                this.state.iconHovered === item.id ? iconHoveredName : iconName
              }
            />
          );

          //return <span className={textStyles}>ICON</span>;
        },
        isPadded: false,
      },

      {
        key: "action",
        name: "Actions",
        fieldName: "action",
        minWidth: 100,
        maxWidth: 100,

        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: any) => {
          return (
            <>
              {/*<div id={`item_${item.id}`} />*/}
              <Stack
                horizontal
                horizontalAlign="start"
                style={{
                  width: "100%",
                  display: this.state.hovered === item.id ? "flex" : "none",
                  maxHeight: cellMaxHeight,
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  //tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    horizontal
                    //tokens={{ childrenGap: 5 }}
                    onClick={() => this._handleView(item.id)}
                  >
                    <ActionButton
                      style={{
                        padding: 0,
                        margin: 0,
                        maxHeight: cellMaxHeight,
                      }}
                      styles={actionButton}
                      // onClick={() => this._handleView(item.id)}
                      iconProps={{ iconName: "Car" }}
                    >
                      View
                    </ActionButton>
                  </Stack>
                </Stack>
              </Stack>
            </>
          );
        },
        isPadded: false,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._items,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      iconHovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      currentPage: 1,
      isViewDialogOpen: false,
      prospectId: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      isSchedulerOpen: false,
      schedulerData: [],
      selectedDeals: null,
      isDealsDialogOpen: false,
      isEmailOpen: false,
      selectedEmail: null,
      mounted: false,
    };

    console.log("PROSPECTS", this.props.items);
  }

  public componentDidMount() {
    // console.log('this.props.rowsPerPage', this.props.rowsPerPage)
    this.props.handleGetProspects({
      pagination: {
        page: 1,
        limit: this.props.rowsPerPage,
      },
      type: this.props.type ? this.props.type : 1,
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }
    if (
      !this.state.mounted &&
      this.props.selectedProspect &&
      this.props.items
    ) {
      this._selection.setIndexSelected(
        this.props.items.findIndex(
          (item: any) => this.props.selectedProspect.id === item.id
        ),
        true,
        false
      );
      this.setState({ mounted: true });
    }
  }

  private _handleUpdateFavourites(item) {
    const newItem = { ...item, isFavourite: !item.isFavourite };
    this.props.handleUpdateProspect([newItem]);
  }

  private _openSchedulerModal(e: any, scheduler: any) {
    console.log(scheduler);

    this.setState({
      isSchedulerOpen: true,
      schedulerData: scheduler,
    });
  }

  private _closeEditDealsDialog() {
    this.setState({
      selectedDeals: null,
      isDealsDialogOpen: false,
    });
  }

  private _openDealsDialog(e: any, deals: IDeal[]) {
    this.setState({
      selectedDeals: deals,
      isDealsDialogOpen: true,
    });
  }

  private _calculateDealStatus(deals: any) {
    let active = 0;
    let other = 0;

    deals.forEach((deal: any) => {
      deal.status === 0 ? active++ : other++;
    });
    return { active, other };
  }

  private _closeEditProspectDialog() {
    this.props.handleGetProspects({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
    });
    this.setState({
      isEditDialogOpen: false,
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    this.setState({
      columns: [...sortColumns(newColumns, currColumn)],
    });
  }

  //                     =============ACTIONS!! ========================================

  private _handleView(id: number) {
    this.setState({
      isViewDialogOpen: true,
      inventoryId: id,
    });
    // this._closeEditProspectDialog();
  }

  private _handleCustomAction(id: number) {
    console.log("CUSTOM ACTION", id);

    this._closeEditProspectDialog();
  }

  private _handleFavouritesList(id: number) {
    console.log(id);

    this._closeEditProspectDialog();
  }

  //                     =============ACTIONS END!! ========================================

  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    
    this.props.setRowsPerPage(rowsOnPage);
    this.props.handleGetProspects({
      pagination: {
        page: this.props.currentPage,
        limit: rowsOnPage,
        // sort: this.props.sort,
        // column: this.props.column
      },
    });
  }

  private _handlePageChange(page: number) {
    this.props.handleGetProspects({
      pagination: {
        page: page,
        limit: this.props.rowsPerPage,
        // sort: this.props.sort,
        // column: this.props.column
      },
    });
  }

  private _handleCloseEmail() {
    this.setState({
      isEmailOpen: false,
      selectedEmail: null,
    });
  }

  private _handleSendEmail(email: string) {
    console.log(email);
    this.setState({
      isEmailOpen: true,
      selectedEmail: email,
    });
  }

  //               ===Pagination sector END =====

  public clearSelection = () => {
    this._selection.setAllSelected(false);
  };

  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <Modal
        isOpen={true}
        onDismiss={this.props.onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
        styles={{ root: null }}
      >
        {this.state.isEmailOpen && (
          <CreateEmailDialog
            onDismiss={this._handleCloseEmail}
            receiversEmails={[this.state.selectedEmail]}
          />
        )}

        <div className={contentStyles.header}>
          {/*<CustomFontText>Add Prospect To Deal</CustomFontText>*/}
          <GoBackToMainDialogBtn
            label={this.props.label ? this.props.label :`Add ${Types[this.props.type]} to Deal`}
            onClick={this.props.onDismiss}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={this.props.onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Fabric>
            <div style={{ backgroundColor: "white" }}>
              <DetailsList
                compact={true}
                styles={tableStyles}
                items={this.props.items}
                columns={columns}
                onRenderDetailsFooter={() => (
                  <Pagination
                    rowsPerPage={this.props.rowsPerPage}
                    currentPage={this.props.currentPage}
                    totalItems={this.props.totalItems}
                    onRowsNumberChange={this._handleRowsNumberChange.bind(this)}
                    onPageChange={this._handlePageChange.bind(this)}
                  />
                )}
                onRenderDetailsHeader={(props, defaultRender) => (
                  <>
                    <Stack
                      tokens={stackTokens}
                      styles={stackStyles}
                      horizontal
                      horizontalAlign="space-between"
                      verticalAlign="center"
                    >
                      <p
                        style={{
                          color: DefaultPalette.themePrimary,
                          width: 100,
                        }}
                      >
                        {selectionDetails.length === 0
                          ? "No items selected"
                          : selectionDetails.length + " items selected"}
                      </p>

                      <SearchBox
                        className={searchStyle}
                        placeholder="search in table ..."
                        iconProps={{
                          styles: { root: { color: DefaultPalette.black } },
                        }}
                        onSearch={(newValue) =>
                          console.log("value is: " + newValue)
                        }
                      />
                    </Stack>
                    <span>{defaultRender(props)}</span>
                  </>
                )}
                onRenderRow={(props, defaultRender) => {
                  return (
                    <Stack
                      styles={cellStyles}
                      className="centered"
                      verticalAlign="center"
                    >
                      <div
                        onMouseEnter={() => {
                          this.setState({ hovered: props.item.id });
                        }}
                        onMouseLeave={() => this.setState({ hovered: null })}
                      >
                        {defaultRender(props)}
                      </div>
                    </Stack>
                  );
                }}
                selection={this._selection}
                selectionMode={SelectionMode.single}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onColumnHeaderClick={(ev, column) =>
                  this._onColumnClick(ev, column)
                }
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={() => {}}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
              />
            </div>
          </Fabric>
        </div>

        <div className={contentStyles.footer}>
          <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
            <DefaultButton text="Close" onClick={this.props.onDismiss} />
            <PrimaryButton
              disabled={this._selection.getSelection().length === 0}
              // onRenderIcon={renderSpinner}
              text="Assign Selected Prospect"
              onClick={() => {
                console.log("COsigner:", this._selection.getSelection()[0]);
                this.props.onProspectSelection(
                  this._selection.getSelection()[0]
                );
                this._selection.setAllSelected(false);
              }}
            />
          </Stack>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.prospects.items?.results ? state.prospects.items?.results : [],
    totalItems: state.prospects.items?.totalItems,
    currentPage: state.prospects.items?.currentPage,
    rowsPerPage: state.prospects?.rowsPerPageProspects,
    sortColumn: state.prospects?.column,
    sort: state.prospects?.sort,
    loading: state.deal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetProspects: (payload) =>
      dispatch({ type: HANDLE_GET_PROSPECTS, payload }),
    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_PROSPECTS, payload }),
    handleUpdateProspect: (payload) =>
      dispatch({
        type: HANDLE_UPDATE_PROSPECT,
        payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProspectToDealDialog);
