import * as React from "react";
import {
  Stack,
  IStackStyles,
  IStackTokens
} from "@fluentui/react";
import AuthHeader from "components/layouts/authHeader/AuthHeader";
import AuthFooter from "components/layouts/authFooter/AuthFooter";
// import {colors} from "constants/constants";
import CookieConsent from "react-cookie-consent";
//import {version} from '../../../../package.json'
//import { Link, ILinkStyles } from '@fluentui/react/lib/Link';
import { Link } from "@fluentui/react/lib/Link";

import { useLocation } from "react-router-dom";


const stackStyles: IStackStyles = {
  root: {
    height: "100vh",
    // overflow: "hidden",
  },
};

/*
const linkStyles: ILinkStyles = {
  root: {
      color: 'black',
      fontSize: 12,
      marginRight: 30
  }
}
*/

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

/* const versionStyle = mergeStyles({
  marginLeft: 300,
  paddingTop: 10,
  color: colors.grayText
}); */
export const ahrefStyle = {
    color: '#001833',
    textDecoration: 'underline'
};

const authRoute = ["/privacy-policy", "/cookies-policy", "/terms-of-use"];

interface IProps {
  children?: any;
}

const AuthLayoutPage: React.FC<IProps> = ({
  children
}) => {
  const location = useLocation();
  return (
    <Stack
          className={"authLayoutBackground " + (authRoute.includes(location.pathname) ? 'policyPgHdr' : '')}
      styles={ stackStyles}
      tokens={itemAlignmentsStackTokens}
      verticalAlign="center"
      horizontalAlign="center"
    >
     
      <AuthHeader />
      {children}
      <AuthFooter />
          {/* <Stack.Item className={versionStyle} >{`v${version}`}</Stack.Item> */}
          {authRoute.includes(location.pathname) ? "" :
       <CookieConsent
       location="bottom"
       buttonText="Got it"
       cookieName="QoreDMSCookie"
                  style={{ background: "rgb(102 159 224)", fontSize: "0.825em", }}
                  buttonStyle={{ color: "#ffffff", fontSize: "0.9em", fontWeight: "bold", padding: "5px 10px", border: "1px solid rgb(0 120 212)", backgroundColor: "rgb(0, 120, 212)"}}
       expires={150}
       >
      
              <div className="segoeFont">QoreDMS uses cookies to understand how you use our website and to improve your experience. This includes personalizing content and advertising. By continuing to use our site, you accept our use of {""}
                  <Link className="ln-nw-link" style= {{cursor:"pointer"}}>                   
                <a  className="ln-nw-link-test" href={`${require("assets/termConditions/privacy-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Privacy Policy</a>
        </Link>
                {", "}
                <Link className="ln-nw-link" style= {{cursor:"pointer"}}>
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/cookie-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Cookie Policy</a>
                  
        </Link>
                {" and "}
                <Link className="ln-nw-link" style= {{cursor:"pointer"}}>
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/Term.pdf")}`} target="_blank" rel='noopener noreferrer'>Terms of Use.</a>
        </Link>
       </div>
    </CookieConsent>}
    </Stack>

  );
};

export default AuthLayoutPage;
