import * as actions from "./dealerwebsite.types";
import { IDealerTable, IDealerWebsite} from "types/dealerWebsiteTypes";

interface IState {
  loading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  loadingNada: boolean;
  loadingKelley: boolean;
  loadingBlack: boolean;
  nadaValuation: any;
  kelleyValuation: any;
  blackValuation: any;
  items: IDealerTable;
  landingItems: IDealerTable;
  error: null | object;
  rowsPerPage: number;
  landingRowsPerPage: number;
  sort: string;
  sortColumn: string;
  type:string;
  selectedInventory: IDealerWebsite;
  dataFromVin: Partial<IDealerWebsite>;
  filters: {
    location: any;
    condition: string[];
    bodyStyle: string;
    minPrice: string;
    maxPrice: string;
    minYear: number;
    maxYear: number;
    vehicleHistory: string[];
    mileage: string;
    make: {key: number, text: string};
    model: {key: number, text: string};
    invoice: boolean;
    leasing: boolean;
    driveType: string;
    fuelType: string;
    engineCylinders: string;
    engineDescription: string;
    transmission: string;
    exteriorColor: string;
    intColorGeneric: string;
    doors: string;
    body: string;
    audioOptions: string[];
    powerOptions: string[];
    interiorOptions: string[];
    exteriorOptions: string[];
    safetyOptions: string[];
    luxuryOptions: string[];
    otherOptions: string[];
  },
  filterList: any
}
export const initialFilterState = {
  location: null,
  condition: [],
  bodyStyle: null,
  minPrice: null,
  maxPrice: null,
  minYear: null,
  maxYear: null,
  vehicleHistory: [],
  mileage: null,
  make: {key: 0, text: ""},
  model: {key: 0, text: ""},
  invoice: false,
  leasing: false,
  driveType: null,
  fuelType: null,
  engineCylinders: null,
  engineDescription: null,
  transmission: null,
  exteriorColor: null,
  intColorGeneric: null,
  doors: null,
  body: null,
  audioOptions: ["MP3"],
  powerOptions: [],
  interiorOptions: [],
  exteriorOptions: [],
  safetyOptions: [],
  luxuryOptions: [],
  otherOptions: [],
};

const initialState: IState = {
  loading: false,
  loadingNada: false,
  loadingKelley: false,
  loadingBlack: false,
  nadaValuation: "",
  kelleyValuation: "",
  blackValuation: "",
  updateLoading: false,
  deleteLoading: false,
  items: {
    totalItems: null,
    currentPage: 1,
    dealerWebsites: [],
 },
  landingItems:{
    totalItems: null,
    currentPage: 1,
    dealerWebsites: [],
  },
  error: null,
  rowsPerPage: 5,
  landingRowsPerPage: 5,
  sort: "ASC",
  sortColumn: "inventory.id",
  type:"",
  selectedInventory: null,
  dataFromVin: null,
  filters: initialFilterState,
  filterList: []
};


export const DealerWebsiteReducer = (
  state: IState = initialState,
  action: actions.WebsiteActions
) => {
  switch (action.type) {
    case actions.CREATE_WEBSITE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.CREATE_WEBSITE_REJECT: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    }
    case actions.CREATE_WEBSITE_RESOLVED: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    }
    case actions.HANDLE_GET_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
        items: { ...action.payload },
      };
    }
    case actions.HANDLE_FILTER_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
        items: { ...action.payload },
      };
    }
    case actions.HANDLE_UPDATE_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    }   
    case actions.HANDLE_UPDATE_DEALERWEBSITE_PENDING: {
      return {
        ...state,
        loading: true,
      }
    } 
    case actions.HANDLE_UPDATE_DEALERWEBSITE_REJECTED: {
      return {
        ...state,
        loading: false,
      }
    } 
    case actions.GET_DEALERWEBSITE_REJECTED: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.HANDLE_UPDATE_DEALERWEBSITE_RESOLVED: {
      return {
        ...state,
        loading: false,
        website: action.payload
      }
    } 
    case actions.HANDLE_GET_LAND_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
        landingItems: action.payload
      }
    } 
    case actions.SET_ROWS_PER_PAGE_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
          rowsPerPage: action.payload
      }
    } 
    case actions.SET_ROWS_PER_PAGE_LAND_DEALERWEBSITE: {
      return {
        ...state,
        loading: false,
        landingRowsPerPage: action.payload
      }
    } 
    default:
      return state;
  }
};
