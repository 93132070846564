import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
  IContextualMenuProps,
  IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, emplyeePermissionsAction } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";
// import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { DefaultButton } from "@fluentui/react";

const iconMenuStyles: IIconStyles = {
  root: {
    color: colors.lighterBlack,
  },
};

interface IProps {
    modulePermissions: IPermission[];
    onEdit: (employeeId: number) => void;
    onActivateDeactivate: (employeeId: number, isActive:boolean) => void;
    employeeId: number;
    isActive: boolean;
}

export const EmployeeMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    onEdit,
    onActivateDeactivate,
    employeeId,
    isActive
}) => {
  const [menuProps, setMenuProps] = useState(null);
  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  // const dispatch = useDispatch();

  useEffect(() => {
    //const menuItems: IContextualMenuItem[] = [
    //  {
    //    key: "view",
    //    name: "View",
    //    iconProps: { iconName: "Car", styles: iconMenuStyles },
    //    onClick: () => {},
    //    style: !isPermitted(
    //      modulePermissions,
    //      eTypes.READ,
    //      permissionsModules.Employee,
    //      user.roles
    //    )
    //      ? hiddenStyle
    //      : null,
    //  },
    //  {
    //    key: "favourites", //with submenu
    //    name: "Favourites",
    //    iconProps: { iconName: "FavoriteList", styles: iconMenuStyles },
    //    onClick: () => {},
    //    style: !isPermitted(
    //      modulePermissions,
    //      eTypes.UPDATE,
    //      permissionsModules.Employee,
    //      user.roles
    //    )
    //      ? hiddenStyle
    //      : null,
    //    subMenuProps: {
    //      items: [
    //        {
    //          key: "addToFavorite",
    //          text: "Add",
    //          onClick: () => {},
    //        },
    //        {
    //          key: "removeFromFavorite",
    //          text: "Remove",
    //          onClick: () => {},
    //        },
    //      ],
    //    },
    //  },
    //  {
    //    key: "setStatus", //with submenu
    //    name: "Set Status",
    //    iconProps: { iconName: "LocationCircle", styles: iconMenuStyles },
    //    onClick: () => {},
    //    style: !isPermitted(
    //      modulePermissions,
    //      eTypes.UPDATE,
    //      permissionsModules.Employee,
    //      user.roles
    //    )
    //      ? hiddenStyle
    //      : null,
    //    subMenuProps: {
    //      items: [
    //        {
    //          key: "inProgress",
    //          text: "In progress",
    //          onClick: () => {},
    //        },
    //        {
    //          key: "available",
    //          text: "Available",
    //          onClick: () => {},
    //        },
    //        {
    //          key: "reservation",
    //          text: "Reservation",
    //          onClick: () => {},
    //        },
    //        {
    //          key: "notAvailable",
    //          text: "Not available",
    //          onClick: () => {},
    //        },
    //      ],
    //    },
    //  },

    //  {
    //    key: "saveAsPDF", //with submenu
    //    name: "Save as PDF",
    //    iconProps: { iconName: "PDF", styles: iconMenuStyles },
    //    onClick: () => {},
    //  },
    //  {
    //    key: "edit", //with submenu
    //    name: "Edit",
    //    iconProps: { iconName: "Edit", styles: iconMenuStyles },
    //    onClick: () => {},
    //    style: !isPermitted(
    //      modulePermissions,
    //      eTypes.UPDATE,
    //      permissionsModules.Employee,
    //      user.roles
    //    )
    //      ? hiddenStyle
    //      : null,
    //  },

    //  {
    //    key: "delete", //with submenu
    //    iconProps: { iconName: "Delete", styles: iconMenuStyles },
    //    name: "Delete",
    //    style: !isPermitted(
    //      modulePermissions,
    //      eTypes.DELETE,
    //      permissionsModules.Employee,
    //      user.roles
    //    )
    //      ? hiddenStyle
    //      : null,
    //    onClick: () => onDelete(),
    //  },
    //];

      const menuItems: IContextualMenuItem[] = [
          {
              key: "edit", //with submenu
              name: "Edit",
              iconProps: { iconName: "Edit", styles: iconMenuStyles },
              onClick: () => onEdit(employeeId),
              style: !isEmployeePermitted(
                      user.aggregatedDefaultPolicies,
                      emplyeePermissionsAction.SETTING_USER_UPDATE,
                      user.roles
                  ) ? hiddenStyle
                  : null,
          },
          {
            key: "delete", //with submenu
              iconProps: { iconName: isActive ? "Cancel" : "Accept", styles: iconMenuStyles },
            name: isActive?"Deactivate User":"Activate User",
            style: !isEmployeePermitted(
                  user.aggregatedDefaultPolicies,
                  emplyeePermissionsAction.SETTING_USER_DELETE,
                  user.roles
              ) ? hiddenStyle
              : null,
              onClick: () => onActivateDeactivate(employeeId, isActive),
          }
      ];    

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems,
    };

    setMenuProps(menuProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

    return (
      <>
        <IconButton
            className="iconButton icon-ellipse-h deskDisplay"
            styles={buttonStyles}
            text="Group Action"
            menuProps={menuProps}
        />
        <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" menuProps={menuProps} />
      </>
  );
};

const buttonStyles: IButtonStyles = {
  root: {
    width: "auto",
    color: DefaultPalette.themePrimary,
    borderColor: DefaultPalette.themePrimary,
  },
  rootHovered: {
    borderColor: DefaultPalette.themePrimary,
    color: DefaultPalette.themePrimary,
  },
};
