import React from "react";
import { Checkbox, ICheckboxStyles } from "@fluentui/react/lib/Checkbox";
import { Stack } from "@fluentui/react/lib/Stack";

const checkbox: ICheckboxStyles = {
  label: {
    fontWeight: "bold",
  },
};

interface IProps {
  values: string[];
  category: string;
  selectedOptions: string[];
  onOptionChange: (options, category) => void;
}

const Options: React.FC<IProps> = ({
  values,
  category,
  selectedOptions,
  onOptionChange,
}) => {
  const onChange = (ev: React.FormEvent<HTMLElement>): void => {
    const label = ev.currentTarget.id;

    onOptionChange(label, category);
  };

  const _isChecked = (label) => {
    return selectedOptions?.includes(label);
  };

  return (
    <Stack style={{marginBottom: 20}} tokens={{ childrenGap: 15 }}>
      {values.map((item) => {
        return (
          <Checkbox
            key={item}
            styles={checkbox}
            id={item}
            label={item}
            checked={_isChecked(item)}
            onChange={onChange}
          />
        );
      })}
    </Stack>
  );
};

export default Options;
