// import {IConfig} from 'types/configTypes'
import client from "services/api/client";

export const getConfig = () => {

    return client(`user/master/config/types`,{body: null}).then(
        (data) => {
            console.log(data)


            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const getDealerConfig = () => {

    return client(`user/master/config?sort=ASC`,{body: null}).then(
        (data) => {


            console.log(data)
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
};

export const updateDealerConfig = (data: any) =>{
    console.log(data)
    const {id, value} = data;
    const v = Array.isArray(value) ? value : parseInt(value)

    return client(`user/master/config/${id}`,{body: {value: v}, method: "PATCH"}).then(
        (data) => {
            console.log(data)
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
}

