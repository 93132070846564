import {
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
} from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { ILinkStyles } from '@fluentui/react/lib/Link';
import { IIconProps, mergeStyles } from "@fluentui/react";

interface IStyles {
    stackItemStyles: IStackItemStyles;
    stackItemHederStyles: IStackItemStyles;
    stackItem1Styles: IStackItemStyles;
    stackTabletItemStyles: IStackItemStyles;
    stackItemStylesAutoHeight: IStackItemStyles;
    loginContentStyles: IStackStyles;
    buttonTokens: IStackTokens;
    textFields: IStackTokens;
    usernameIcon: IIconProps;
    passwordIcon: IIconProps;
    linkStyles: ILinkStyles;
    none: any;
    checkboxLabel: any;
}

export const styles: IStyles = {
    none: {},
    checkboxLabel: mergeStyles({
        paddingLeft: 5,
        paddingTop: 10,
    }),
    linkStyles: {
        root: {
            fontSize: 12,
            marginRight: 30,
            textDecoration: 'underline',
        }
    },
    stackItemHederStyles: {
        root: {
            margin: "30px 0px",
        },
    },
    stackItem1Styles: {
        root: {
            fontFamily: "soleil",
            fontWeight: "semibold",
            fontSize: 36,
            color: "#898D89",
            lineHeight: 46,
            letterSpacing: "0"
        },
    },
    stackItemStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 10,
            borderRadius: 2,
            width: 420,
            height: 470,
            zIndex: 1,
            borderTop: "10px solid #65BC50",
            marginLeft:110
        },
    },
    //stackItemStylesAutoHeight: {
    //    root: {
    //        boxShadow: Depths.depth8,
    //        background: DefaultPalette.white,
    //        padding: 50,
    //        borderRadius: 2,
    //        width: 280,
    //        zIndex: 1,
    //    },
    //},
    stackItemStylesAutoHeight: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: 50,
            borderRadius: 2,
            width: 250,
            zIndex: 1,
        },
    },
    stackTabletItemStyles: {
        root: {
            boxShadow: Depths.depth8,
            background: DefaultPalette.white,
            padding: "5vw",
            borderRadius: 2,
            width: '90vw',
            // width: "70vw",
            height: 400,
            zIndex: 1,
            marginTop: '40px !important',
            /*selectors: {
                ':focus-within': {
                    // maxWidth: "90vw",
                    // marginTop: "400px !important",
                    //
                    // paddingBottom: '400px !important'
                    paddingBottom: 300
                }
            }*/
        },
    },
    loginContentStyles: {
        root: {
            height: "100%",
        },
    },
    buttonTokens: {
        childrenGap: "10",
    },
    textFields: {
        childrenGap: "10",
    },
    usernameIcon: { iconName: "Signin" },
    passwordIcon: { iconName: "Hide" },
};
