import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RouteProps } from "react-router-dom";
import { AppState } from "store";
import Dashboard from "pages/dashboard/Dashboard";
import { GuidedSetUpPage } from "pages/primarySetupPage/GuidedSetUpPage";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { useNavigate  } from "react-router-dom";

const AuthRoute: React.FC<RouteProps> = ({ component, ...options }:any) => {
    const user = useSelector((state: AppState) => state.auth.userToken);
    const u = useSelector((state: AppState) => state.userProfile.userProfile);
    const guidedSetup = useSelector(
        (state: AppState) => state.primarySetup.guidedSetup
    );
    const navigate = useNavigate ();
    if (u && u.profileType && isKarlProfile(u.profileType)) {
        
        const finalComponent = user ? Dashboard : component;
        if (user) {
            navigate('/dashboard', { replace: true });
        }
        return <Route {...options} component={finalComponent} />;
    } else {
        const finalComponent = user ? !guidedSetup || !guidedSetup.isCompleted ? GuidedSetUpPage : Dashboard : component;
        if (user) {
            navigate('/dashboard', { replace: true });
        }
        return <Route {...options} element={finalComponent} />;
    }
    
};

export default AuthRoute;
