// import { ICosigner } from "types/cosignerTypes";
import * as actions from "store/cosigners/cosigners.types";

interface IState {
  loading: boolean;
}

const initialState: IState = {
  loading: false,
};

export const CosignersReducer = (
  state: IState = initialState,
  action: actions.CosignersActions
) => {
  switch (action.type) {
    case actions.CREATE_COSIGNER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_COSIGNER_RESOLVED:
      return {
        ...state,
        loading: false,
      };
    case actions.CREATE_COSIGNER_REJECTED:
      return {
        ...state,
        loading: false,
      };
    case actions.EDIT_COSIGNER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_COSIGNER_RESOLVED:
      return {
        ...state,
        loading: false,
      };
    case actions.EDIT_COSIGNER_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
