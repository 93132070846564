import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
	Stack,
	IStackStyles,
	//TextField,
	Link
} from "@fluentui/react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
	//useDispatch,
	useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
	getModulePermissions,
	isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu"; 
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
//import { handleGetAppraisals } from "store/dealTracker/dealTracker.action";
import { handleGetAppraisalList } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { useNavigate , useLocation } from "react-router-dom";
import masterDropdownValues from "../../constants/masterDropdownValues";
// import { convertUTCToLocalDateOnlyModified } from "utils/dateTimeFunctions";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import { currencyNegValFractionalFormat } from "utils/helperFunctions";
import moment from "moment";
import { Paginator } from 'primereact/paginator';
import { DebounceInput } from 'react-debounce-input';

const container: IStackStyles = {
	root: {
		marginTop: 10,
		minHeight:500
	},
};

const credentialModuleName = "DealTracker";
export const AppraisalWorkbookV2Page = () => {
	const dispatch = useDispatch();
    const location = useLocation();

	const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
	//const [appointmentId, setAppointmentId] = useState(0);
	const [appraisalData, setAppraisalData] = useState(null);
	const [commonServerError, setcommonServerError] = useState(null);
	const [userProfileLoadingFlag, setUserProfileLoadingFlag] = useState(false);
	const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
	//const appraisals = useSelector((state: AppState) => state.dealTracker.appraisals) as any;
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const navigate = useNavigate ();
	const [cityDealerList, setCityDealerList] = React.useState();

	const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

	const [credentials, setCredentials] = useState([]);
	const yearRange = "1980:" + (new Date().getFullYear()+1);
	const isMobile = useMediaQuery({ maxWidth:960 });

	const [search, setGLFilter] = useState(null);
    const [locationFilter, setLocationFilter] = useState(0);
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
	const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
	const todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
	const [primaryLocation, setPrimaryLocation] = useState(0);
	//const [statesFlag, setStatesFlag] = useState(null);
	const[sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState('appraisalDate');
	const [totalRecords, setTotalRecords] = useState(null);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(false);
	const [refreshList, setRefreshList] = useState(false);
    const [filters, setFilters] = useState(null);
	const [statePageNumber, setStatePageNumber] = useState(1);


    const handleSearchChange = (event: any) => {
		const inputValue = event.target.value.trim();
        if (inputValue !== '') {
            setGLFilter(inputValue);
        }

		if(event.target.value === ''){
			setGLFilter('');
	    }
    };


	const onLocationChange = (e: { value: any }) => {
		setLocationFilter(e.value);

    }


    const handleFromLogDateChange = (val: any) => {
		setLogDateFromFilter(val.target.value)

    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)

    };


    useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
 
    }, [locations]);

    useEffect(() => {
 
        if (user && user.roles) {
            setUserProfileLoadingFlag(true);
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

	const handlePopulateAppraisals = (appraisals) => {
	        if (appraisals.length){
	        	for (var i in appraisals) {
	        		let deal = appraisals[i].deal;
					let vehicle = appraisals[i].vehicle;
	        		let customer = deal.customer;
	        		//let hiddenStr = '';
	        		let dealTradeInAppraisalsInfo = appraisals[i].dealTradeInAppraisals ? appraisals[i].dealTradeInAppraisals.at(-1) : null;
					appraisals[i]["appraisalDate"] = moment(appraisals[i].createdAt).local().format('MM/DD/YY hh:mm a');
	        		appraisals[i]["appraiser"] = dealTradeInAppraisalsInfo ? (dealTradeInAppraisalsInfo.employee.firstName + ' ' + dealTradeInAppraisalsInfo.employee.lastName) : null;
	        		appraisals[i]["rollBack"] = dealTradeInAppraisalsInfo && currencyNegValFractionalFormat(dealTradeInAppraisalsInfo.rollback);
					//appraisals[i]["vehicle"] = appraisals[i].vehicle && appraisals[i].vehicle?.year + ' ' + appraisals[i].vehicle?.model;
					appraisals[i]["year"] = vehicle && vehicle?.year;
					appraisals[i]["model"] = vehicle && vehicle?.model;
	        		appraisals[i]["appraiserFlag"] = dealTradeInAppraisalsInfo ? 1 : 0;
	        		appraisals[i]["bidder"] = appraisals[i].appraisalBidder?.name;
	        		appraisals[i]["subjectToSee"] = appraisals[i].subjectToSee ? "Yes" : appraisals[i].subjectToSee === false ? "No" : "";
	        		appraisals[i]["appraisalStatus"] = getStatus(appraisals[i].status);
	        		appraisals[i]["dealNumber"] = deal.dealNumber;
	        		appraisals[i]["customer"] = customer.businessName ? customer.businessName : (customer.firstName + ' ' + customer.lastName);
	        		appraisals[i]["sales"] = getName(deal.salesPerson1);
	        		appraisals[i]["salesManager"] = getName(deal.salesManager);
					appraisals[i]["vin"] = vehicle && vehicle?.vin;

	        
	        		//if (deal.vehicle)
	        		//	hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
	        		//if (deal.salesPerson2)
	        		//	hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
	        		//if (deal.financePerson1)
	        		//	hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
	        		//if (deal.financePerson2)
	        		//	hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
	        		//hiddenStr = hiddenStr + ' ' + customer?.customerNumber;
	        		//appraisals[i]["hiddenCol"] = hiddenStr;
	        	}
	        	 return appraisals;
            }
	        return []

	};



	const getStatus = (id) => {
		let item = masterDropdownValues.tradeInStatus.filter(x => x.key === id);
		if (item.length === 1) {
			return item[0].text;
		}
		return '';
	};

	const getName = (person) => {
		if (person) {
			return person.firstName + ' ' + person.lastName;
		}
		return '';
	};

	const appraisalList =(isFilterApplied)=>{
	     const formData = {
                page: isFilterApplied ? statePageNumber : pages,
                limit,
				sort: sortOrder === 1 ? 'ASC' : 'DESC',
				column: sortField,
                locationId:locationFilter?locationFilter:"",
                fromLogDate: logDateFromFilter ? moment(logDateFromFilter).format('YYYY-MM-DD'):todaydate,
                toLogDate: logDateToFilter ? moment(logDateToFilter).format('YYYY-MM-DD') : '',
                search: search
            };
            setFilters(formData);
			if (isFilterApplied && statePageNumber > 1) {
                 setStatePageNumber(1);
            }
            dispatch<any>(handleGetAppraisalList({
                formData,
                callback: (response) => {
                    if (response) {
                        setTotalRecords(response.totalItems);
                        const appraisalData = handlePopulateAppraisals(response.results);
                        setAppraisalData(appraisalData);
                    } else {
                        setTotalRecords(0);
                        setcommonServerError('Failed to Fetch the Data.');
                    }
                }
            }));
	}

    const MINUTE_MS = 300000;

	useEffect(() => {
		setSortOrder(1);
		setSortField('appraisalDate');
		let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
		//setLocationFilter(user.primaryLocation?.id)
		if (user && user.auths && !isUserMaster(user.roles)) {
			setCredentials(getModulePermissions(user.auths, credentialModuleName));
		}
		dispatch<any>(handleGetLocations());
		const interval = setInterval(() => {
			 setRefreshList(true);
			 //handleResetWorkbook();
		}, MINUTE_MS);
		return () => clearInterval(interval);
		
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleResetWorkbook = () => {   
		setGLFilter("");
		setLocationFilter(primaryLocation);
		setlogDateToFilter(null);
		let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
		setSortOrder(-1);
		setLimit(25);
        setPages(1);
		setSortField('appraisalDate');
	};

	useEffect(()=>{
		if(refreshList){
			appraisalList(true)
			setRefreshList(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[refreshList])



 const exportExcel = () => {
        const getStatus = (id) => {
  	     	let item = masterDropdownValues.tradeInStatus.filter(x => x.key === id);
  	     	if (item.length === 1) {
  	     		return item[0].text;
  	     	}
  	     	return '';
        } 
        const formData = {...filters };
        formData.page = 1;
        formData.limit = 10000;
        dispatch<any>(handleGetAppraisalList({
            formData,
            callback: (response) => {
                if (response) {
                    if (response && response.results) {
                        let list = response.results
 
                                   const exportData = list.map((item) =>
				                     ({
									 "Appraiser Date": moment(item.createdAt).local().format('MM/DD/YY hh:mm a'),
				                   	 "Appraiser": item.dealTradeInAppraisals[0] ? item.dealTradeInAppraisals[0].employee.firstName + ' ' + item.dealTradeInAppraisals[0].employee.lastName : "",
                                     "Vin": item.vehicle? item.vehicle.vin :"",
									 "Year": item.vehicle?.year,
									 "Model": item.vehicle?.model,
                                     "Rollback": item.dealTradeInAppraisals[0] ? currencyNegValFractionalFormat(item.dealTradeInAppraisals[0].rollback) :"",
                                    "Subject To See": item.subjectToSee ? "Yes" : item.subjectToSee === false ? "No" : "",
                                    "Sales Mgr": item.deal.salesManager? item.deal.salesManager.firstName+""+item.deal.salesManager.lastName:" ",
                                    "Sales Person 1": item.deal.salesPerson1? item.deal.salesPerson1.firstName+" "+item.deal.salesPerson1.lastName:" ",
                                    "Deal#": item.deal? item.deal.dealNumber:"",
                                    "Customer": item.deal.customer.businessName ? item.deal.customer.businessName :item.deal.customer.firstName +" "+ item.deal.customer.lastName,
                                    "Higher Bidder": item.appraisalBidder ? item.appraisalBidder.name:"",
									"status":getStatus(item.status?item.status:"")
				                   }));
				                   
			                       import('xlsx').then(xlsx => {
                                       const worksheet = xlsx.utils.json_to_sheet(exportData);
                                       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                                       const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                                       saveAsExcelFile(excelBuffer, 'trade-in-workbook');
                                   });
                        
                    }
                } else {
                    setTotalRecords(0);
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));


    }


	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
			}
		});
	}

	//Datatable Templates
	const header = (
		<>  

		{isMobile ?
		<div>
		    <Row>
		        <Col>
			       <Row>
			             <Col>
							<div className="locationDropPosition">
			                  	{/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={search}
                                          debounceTimeout={750}
                                          onChange={(e) => handleSearchChange(e)} />
                                  </div>
			                </div>
			         	</Col>
			         	<Col>
						  <div className="locationDropPosition">
			                  <label className="lbl-text"  style={{ paddingBottom: "0px" }}>Location</label>
                              <Dropdown
                                    id="legalName"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>

				   </Row>
				   <Row style={{width:"auto"}} >
			         	<Col>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
			         	</Col>
					    <Col className=" employeeeSearchLabelText" style={{marginTop:"32px",maxWidth:"40%"}} >
			                   <PrimaryButton  style={{float:"right"}}
							       onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
			         	</Col>
			        </Row>
					<Row>
						<Col className="display-row" style={{ marginTop: "15px" }}>
							{
								totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
							}
							<Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
								Export to Excel
							</Link>
						</Col>
					</Row>
				</Col>
		    </Row>
		</div>:
		<div>
			<div>
				<Row>
					<Col md={10}>
						<Row>
							<Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	{/*<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appraisals"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>*/}
								  <label className="lbl-text">Search</label>
                                  <div className="ms-TextField-fieldGroup debounce-input-group">
                                      <DebounceInput
                                          minLength={1}
                                          className="ms-TextField-field"
                                          value={search}
                                          debounceTimeout={750}
                                          onChange={(e) => handleSearchChange(e)} />
                                  </div>
			                  </div>
			         	</Col>
			         		<Col  md={3}>
						  <div className="dd-control">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
							<Col md={4}>
							<div className="date-filter-section">
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">Log Date</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="fromLogDate"
												style={{ width: "100%" }}
												value={logDateFromFilter}
												showIcon
												onChange={(e) => handleFromLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												maxDate={logDateToFilter}
											/>
										</div>
									</div>
								</div>
								<label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
								<div className="table-header">
									<div className="ms-TextField-wrapper">
										<label className="lbl-text">&nbsp;</label>
										<div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
											<Calendar
												id="toLogDate"
												style={{ width: "100%" }}
												value={logDateToFilter}
												showIcon
												onChange={(e) => handleToLogDateChange(e)}
												monthNavigator
												yearNavigator
												yearRange={yearRange}
												minDate={logDateFromFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</Col>
							<Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"24px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>
						</Row>
					</Col>
					<Col>
					</Col>
				</Row>
			</div>
			<div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);

	const onPageChange = (event) => {
        setCurrentPage(event.first);
        setPages(event.page + 1);
        setLimit(event.rows);
    }

    const footer = (
        <>
            <Paginator first={currentPage} rows={limit} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange}></Paginator>
        </>
    );


	const bidderBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Higher Bidder</span>
				 {rowData.appraiser ? rowData.higherBidder : <span style={{color:"#AF2B01"}}> { rowData.bidder} </span>}
			</React.Fragment>
		);
	};

	const subjectBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Subject to See</span>
				 {rowData.appraiser ? rowData.subject : <span style={{color:"#AF2B01"}}> { rowData.subjectToSee} </span>}
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Status</span>
				 {rowData.appraiser ? rowData.appraisalStatus : <span style={{color:"#AF2B01"}}> { rowData.appraisalStatus} </span>}
			</React.Fragment>
		);
	};

	const appraisalDateBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Appraisal Date</span>
				 {rowData.appraiser ? 
					rowData.appraisalDate : 
					<span style={{ color: "#AF2B01" }}> {rowData.appraisalDate} </span>}
			</React.Fragment>
		);
	};

	const appraiserBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Appraiser</span>
				{rowData.appraiser}
			</React.Fragment>
		);
	};

	const salesManagerBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Sales Mgr</span>
				 {rowData.appraiser ? rowData.salesManager : <span style={{color:"#AF2B01"}}> { rowData.salesManager} </span>}
			</React.Fragment>
		);
	};

	const salesPersonBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Sales Person 1</span>
				 {rowData.appraiser ? rowData.salesPerson1Name : <span style={{color:"#AF2B01"}}> { rowData.sales} </span>}
			</React.Fragment>
		);
	};

	const dealNumberBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Deal #</span>
				 {rowData.appraiser ? rowData.dealNumber : <span style={{color:"#AF2B01"}}> { rowData.dealNumber} </span>}
			</React.Fragment>
		);
	};

	const customerBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Customer</span>
				 {rowData.appraiser ? rowData.customerName : <span style={{color:"#AF2B01"}}> { rowData.customer} </span>}
			</React.Fragment>
		);
	};

	const settingBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				{/*<span className="p-column-title" style={{ float: "left" }}>Actions</span>*/}
				{credentials && user.roles && (
					<EditMoreActionsSubmenu
						modulePermissions={credentials}
						itemId={rowData.id}
						onEdit={(appointmentId) => handleEditAppointment(appointmentId)}
					/>
				)}
			</React.Fragment>
		);
	};

	const vinBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Vin</span>
				{rowData.vin}
			</React.Fragment>
		);
	};

	const yearBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Year</span>
				{rowData.year}
			</React.Fragment>
		);
	};

	const modelBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Model</span>
				{rowData.model}
			</React.Fragment>
		);
	};

	const rollBackBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<span className="p-column-title">Rollback</span>
				{rowData.rollBack}
			</React.Fragment>
		);
	};

	const handleEditAppointment = (id) => {
		const selectedTradeIns = appraisalData.find(x => x.id === id);
		if (selectedTradeIns) {
			handleNavigateToTradeIns(selectedTradeIns.deal.id, id);
		}
	};

	const handleRowClick = (event) => {
		let target = event.originalEvent.target;
		if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
			target?.lastElementChild?.lastElementChild?.firstElementChild.click();
		} else {
				handleNavigateToTradeIns(event.data.deal.id,event.data.id);
		}
	};

	const handleNavigateToTradeIns = (dealId, id) => {
		const 	state = {
			search,
			pathfrom: '/appraisals',
			tabIndex: 2,
			productId: `${id}`,
			workbookName: 'tradeIN',
			sortOrder,
			sortField,
			pages,
			limit,
			logDateFromFilter,
			logDateToFilter,
			locationFilter
		}
		navigate(`/${dealId}/dealtracker`, {state});
	}

    useEffect(() => {        
        const stateVal: any = location?.state
		console.log("locationlocationlocation",location)
        if (stateVal?.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
		if (stateVal?.fromKeymetrics) {
			setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
		}

		if (stateVal?.locationFilter) {
			setLocationFilter(stateVal?.locationFilter);
		}

		if(primaryLocation === 0 && stateVal?.locationFilter === 0){
            setLocationFilter(0);
        }

		if (stateVal?.logDateFromFilter) {
			setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
		}

		if (stateVal?.logDateToFilter) {
			setlogDateToFilter(new Date(stateVal.logDateToFilter));
		}


		if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
            setPages(stateVal.pages);
            setLimit(stateVal.limit);
            if (stateVal.pages > 1) {
                setStatePageNumber(stateVal.pages);
                setCurrentPage((stateVal.pages - 1) * stateVal.limit);
            }
        }
	    if (stateVal?.search) {
            setGLFilter(stateVal.search)
        }

		//setStatesFlag(true);
		setLoadingFlag(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

	useEffect(() => {
		if (serverError) {
			if (serverError.detail) {
				setcommonServerError(serverError.detail);
			} else {
				setcommonServerError('Something went wrong');
			}
			window.scrollTo(0, 0);
		} else {
			setcommonServerError(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverError]);

	const table_columns = {
		"appraisalDate": "Appraisal Date",
		"appraiser": "Appraiser",
		"vin":"Vin",
		"year": "Year",
		"model": "Model",
		"rollBack": "Rollback",
		"subjectToSee": "Subject to See",
		"salesManager":"Sales Mgr",
		"sales": "Sales Person 1",
		"dealNumber": "Deal #",
		"customer":"Customer",
		"bidder": "Higher Bidder",
		"appraisalStatus": "Status",
		"settings": "Actions"
	}

	const handleColumnSort = (e) => {
		setSortField(e.sortField);
		setSortOrder(e.sortOrder);
	}

	const getAppraisal = (isFilterApplied = false) => {
        setcommonServerError('');
        if (loadingFlag && userProfileLoadingFlag) {
             appraisalList(isFilterApplied)
        }
    }

    useEffect(() => {
            if (statePageNumber > 1) {
                setPages(statePageNumber);
            } else {
                setPages(1);
                setCurrentPage(0);
            }
        getAppraisal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search,locationFilter,logDateFromFilter,logDateToFilter,loadingFlag,userProfileLoadingFlag]);

    useEffect(() => {
        getAppraisal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, pages, sortOrder, sortField]);


	return (
		isKarlProfileType ? <>
			<Stack styles={container} tokens={{ childrenGap: 10 }}>
				<Stack
					horizontal
					verticalAlign="center"
					horizontalAlign="space-between"
					className="loc_btn_par_con"
				>

					<Row>
						<Col className="alignHeaders">
							<CustomFontText className="pageTitleFont pageName pageHeaderFont">Trade-ins</CustomFontText>
						</Col>
					</Row>
				</Stack>
				{
					commonServerError ?
						<div>
							<p className="genericErrorColor"><span>{commonServerError}</span></p>
						</div> : null
				}
				<div className="datatable-filter-demo noMaxWidthTable trade-in-workbook">
					<div className="lazy-loading-workbook card">
						<CustomTable
							column_list={table_columns}
							data={appraisalData}
							header={header}
							footer={footer}
							lazy
							//rows={25}
                            //rowsPerPageOptions={[25,50,100]}
							sortField={sortField}
							paginator={false}
							sortOrder={sortOrder}
							onSort={handleColumnSort}
							customTemplates={{
								"appraisalDate": appraisalDateBodyTemplate,
								"appraiser": appraiserBodyTemplate,
								"vin": vinBodyTemplate,
								//"vehicleInfo": vehicleInfoBodyTemplate,
								"year": yearBodyTemplate,
								"model": modelBodyTemplate,
								"rollBack": rollBackBodyTemplate,
								"appraisalStatus": statusBodyTemplate,
								"higherBidder": bidderBodyTemplate,
								"subjectTosee": subjectBodyTemplate,
								"salesManager": salesManagerBodyTemplate,
								"salesPerson1Name": salesPersonBodyTemplate,
								"dealNumber": dealNumberBodyTemplate,
								"customerName": customerBodyTemplate,
								"settings": settingBodyTemplate,
							}}
							emptyMessage="No appraisals found."
							onRowClick={(event) => handleRowClick(event)}
							//globalFilter={glFilter} 
							/>
					</div>
				</div>
			</Stack>
		</> : null
	);
};
