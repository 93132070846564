import React from "react";
import { Fabric } from "@fluentui/react/lib/Fabric";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsListStyles,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  ActionButton,
  DefaultPalette,
  IButtonStyles,
  IconButton,
  IStackStyles,
  IStackTokens,
  /**Image, ImageFit, */ mergeStyles,
  Modal,
  PrimaryButton,
  SearchBox,
  Stack,
} from "@fluentui/react";
import { Pagination } from "components/table/pagination/Pagination";
import { connect } from "react-redux";
// import Loader from "../../loader/Loader";
// import { EditLocationDialog } from "../locations/EditLocationDialog";
// import { ViewInventoryDialog } from "../viewInventoryDialog/ViewInventoryDialog";
import {
  HANDLE_DELETE_INVENTORY,
  HANDLE_SORT_INVENTORY_TABLE,
  HANDLE_UPDATE_INVENTORY,
  SET_ROWS_PER_PAGE_INVENTORY,
} from "store/inventory/inventory.types";

import { HANDLE_GET_SAVED_DESK_A_DEALS } from "store/deskADeal/deskADeal.types";
import {
  cancelIcon,
  cellLineHeight,
  contentStyles as contentStyle,
  iconButtonStyles,
  searchStyle as searchStyles,
} from "constants/styles";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import {sortColumns, stringToCurrenyFormatForEditInputs} from "utils/helperFunctions";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { styles } from "pages/accountSettingsPage/styles";

const searchStyle = searchStyles();
const contentStyles = contentStyle(1080, "auto");

const cellMaxHeight: string = "35px";
const actionButton: IButtonStyles = {
  icon: {
    paddingLeft: 0,
    marginLeft: 0,
    maxHeight: cellMaxHeight,
  },
  label: {
    color: DefaultPalette.themePrimary,
  },
};
const tableStyles: Partial<IDetailsListStyles> = {
  root: {
    backgroundColor: "white",
  },
};

const textStyles = mergeStyles({
  fontSize: 14,
});

//
// const statuses = [
//     "active",
//     "pending",
//     "new",
//     "unassigned",
//     "dead"
// ]

export interface IDocument {
  id: number;
  name: string;
}

const stackStyles: IStackStyles = {
  root: {
    paddingLeft: 5,
  },
};

const cellStyles: IStackStyles = {
  root: {
    lineHeight: cellLineHeight,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

class OpenSavedDeskADealDialog extends React.Component<any, any> {
  private readonly _items: any;
  private readonly _selection: Selection;

  // private _contextMenuRef: any;

  constructor(props) {
    super(props);

    this._items = [];

    const columns: any[] = [
      // {
      //   key: "createdAt",
      //   name: "Date",
      //   fieldName: "inventory.createdAt",
      //   minWidth: 150,
      //   maxWidth: 150,
      //   isResizable: true,
      //   isRowHeader: true,
      //   isSorted: true,
      //   isSortedDescending: false,
      //   sortAscendingAriaLabel: "Sorted A to Z",
      //   sortDescendingAriaLabel: "Sorted Z to A",
      //   data: "string",
      //   isPadded: false,
      //   // onRender: (item: IDocument) => {
      //   //   return <span className={textStyles}>{getDate(item.createdAt)}</span>;
      //   // },
      //   onRender: (item: IDocument) => {
      //     return (
      //       <span className={textStyles}>
      //         {formatDateInventory(item.createdAt)}
      //       </span>
      //     );
      //   },
      // },

      {
        key: "name",
        name: "Name",
        fieldName: "name",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        // onColumnClick: _onColumnClick,
        isPadded: false,
        onRender: (item: IDocument) => {
          return <span className={textStyles}>{item.name}</span>;
        },
      },

      {
        key: "place",
        name: "Assigned Prospect",
        fieldName: "prospect.id",
        // minWidth: 200,
        // maxWidth: 300,
        isResizable: true,
        onRender: (item: any) => {
          return (
            <span
              className={textStyles}
              style={{ fontSize: 13, display: "flex" }}
            >
              {item.prospect.firstName} {item.prospect.lastName}
              {/*<FontIcon style={iconStyle} iconName="MapDirections" />*/}
            </span>
          );
        },
        // onColumnClick: _onColumnClick,
        isPadded: false,
      },

      {
        key: "action",
        name: "Actions",
        fieldName: "action",
        minWidth: 200,
        maxWidth: 250,

        isResizable: false,
        // onColumnClick: _onColumnClick,
        onRender: (item: any) => {
          return (
            <>
              {/*<div id={`item_${item.id}`} />*/}
              <Stack
                horizontal
                horizontalAlign="start"
                style={{
                  width: "100%",
                  display: this.state.hovered === item.id ? "flex" : "none",
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  verticalAlign="center"
                  //tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    horizontal
                    //tokens={{ childrenGap: 5 }}
                    onClick={() => this._handleView(item)}
                  >
                    <ActionButton
                      style={{ padding: 0 }}
                      styles={actionButton}
                      // onClick={() => this._handleView(item.id)}
                      iconProps={{ iconName: "Download" }}
                    >
                      Open Desk a Deal
                    </ActionButton>
                  </Stack>
                </Stack>
              </Stack>
            </>
          );
        },
        isPadded: false,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._items,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      hovered: null,
      iconHovered: null,
      selectedInHeaderDropDown: null,
      rowsPerPage: this.props.rowsPerPage,
      currentPage: 1,
      isEditDialogOpen: false,
      isViewDialogOpen: false,
      inventoryId: null,
      activeId: null,
      loading: false,
      isConfirmBoxOpen: false,
      itemData: null,
      mounted: false,
    };
  }

  public componentDidMount() {
    // this.props.handleGetInventory({
    //   page: 1,
    //   limit: this.props.rowsPerPage,
    //   column: this.props.sortColumn,
    //   sort: this.props.sort,
    // });

    this.props.handleGetSavedDeskADeals();
  }

  public componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (prevState.hovered !== this.state.hovered) {
      this.setState({
        columns: [...this.state.columns],
      });
    }

    if (
      !this.state.mounted &&
      this.props.selectedInventory &&
      this.props.items
    ) {
      this._selection.setIndexSelected(
        this.props.items.findIndex(
          (item: any) => this.props.selectedInventory.id === item.id
        ),
        true,
        false
      );
      this.setState({ mounted: true });
    }
  }

  public clearSelection = () => {
    this._selection.setAllSelected(false);
  };

  private _handleUpdateFavourites(item) {
    const newItem = { ...item, isFavourite: !item.isFavourite };
    this.props.handleUpdateInventory([newItem]);
  }

  private _closeEditLocationDialog() {
    this.props.handleGetLocations({
      page: this.props.currentPage,
      limit: this.props.rowsPerPage,
    });
    this.setState({
      isEditDialogOpen: false,
    });
  }

  private _handleViewDismiss() {
    this.setState({
      isViewDialogOpen: false,
      inventoryId: null,
      itemData: null,
    });
  }

  private _getSelectionDetails() {
    return this._selection.getSelection();
  }

  private _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    const newColumns: IColumn[] = this.state.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    this.props.handleGetInventoryList({
      page: parseInt(this.props.currentPage),
      limit: this.props.rowsPerPage,
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      column: currColumn.fieldName,
    });

    // set sort
    this.props.handleSortTable({
      sort: currColumn.isSortedDescending ? "DESC" : "ASC",
      sortColumn: currColumn.fieldName,
    });

    this.setState({
      columns: [...sortColumns(newColumns, currColumn)],
    });
  }

  //                     =============ACTIONS!! ========================================
private _transformPrice(price){
  console.log('price in transformed price',price)
  console.log('for edit ', stringToCurrenyFormatForEditInputs(price))
    return stringToCurrenyFormatForEditInputs(price)
}

  private _handleView(item) {
    console.log(item);

    


    this.props.setSelectedProspect(item?.prospect);
    this.props.setSelectedInventory(item?.inventory);

    this.props.setSelectedDeskADeal(item);



    // todo set all values
    this.props.formik.setValues({
      ...this.props.formik.values,
      name: item?.name,
      prospectId: item?.prospect?.id,
      inventoryId: item?.inventory?.id,

      vehiclePrice: item?.options.json.vehiclePrice ? this._transformPrice(item?.options.json.vehiclePrice): "",
      discounts: item?.options.json.discounts ?? "",
      cashDownPayment: item?.options.json.cashDownPayment ?? "",
      tradeInValue: item?.options.json.tradeInValue ?? "",
      payoff: item?.options.json.payoff ?? "",
      docFee: item?.options.json.docFee ?? "",
      titleFilingFee: item?.options.json.titleFilingFee ?? "",
      registrationFee: item?.options.json.registrationFee ?? "",
      otherFees: item?.options.json.otherFees ?? "",
      vehicleServiceContract: item?.options.json.vehicleServiceContract ?? "",
      GAPFee: item?.options.json.GAPFee ?? "",
      tireAndWheel: item?.options.json.tireAndWheel ?? "",
      otherProducts: item?.options.json.otherProducts ?? "",
      rate: item?.options.json.rate ?? "",
      term: item?.options.json.term ?? "",
      variants: item?.options.json.variants ?? null
    });



    // this.props.handleCalculate();

    this.props.onDismiss();
  }

  private _handleEdit(id: number) {
    this.setState({
      inventoryId: id,
      isEditDialogOpen: true,
    });
  }

  private _handleCustomAction(id: number) {
    console.log("CUSTOM ACTION", id);

    this._closeEditLocationDialog();
  }

  private _handleFavouritesList(id: number) {
    console.log(id);

    this._closeEditLocationDialog();
  }

  private _handleSetStatus(id: number) {
    console.log(id);
    this._closeEditLocationDialog();
  }

  private async _handleDelete(id?: number) {
    // console.log(this._selection.getSelection());
    // get all items from selection and then delete each separately
    await this._selection
      .getSelection()
      .forEach((element: any) => this.props.handleDeleteLocation(element.id));

    //TODO: errors need to be managed
    this._selection.setAllSelected(false);
    this.setState({ isConfirmBoxOpen: false });
    this._closeEditLocationDialog();
  }

  //                     =============ACTIONS END!! ========================================

  // private handleHeaderDropDownChange(e, item) {
  //   this.setState({
  //     selectedInHeaderDropDown: item,
  //   });
  // }
  //               ===Pagination sector =====
  private _handleRowsNumberChange(rowsOnPage: number) {
    this.props.setRowsPerPage(rowsOnPage);
    // this.props.handleGetInventory({
    //   page: this.props.currentPage,
    //   limit: rowsOnPage,
    // });
  }

  private _handlePageChange(page: number) {
    // this.props.handleGetInventory({
    //   page: page,
    //   limit: this.props.rowsPerPage,
    // });
  }

  private _renderSpinner = () => {
    return this.props.loading ? (
      <Spinner styles={styles.spinnerStyles} size={SpinnerSize.medium} />
    ) : null;
  };

  //               ===Pagination sector END =====
  render() {
    const { columns, selectionDetails } = this.state;
    return (
      <Modal
        isOpen={true}
        onDismiss={this.props.onDismiss}
        isBlocking={true}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          {/*<CustomFontText>Add Vehicle To Deal</CustomFontText>*/}
          <GoBackToMainDialogBtn
            label="Open Saved Desk a Deal"
            onClick={this.props.onDismiss}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={this.props.onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Fabric>
            <div style={{ backgroundColor: "white" }}>
              {this.props.loading ? (
                <div
                  style={{
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner size={SpinnerSize.large} />
                </div>
              ) : (
                <DetailsList
                  styles={tableStyles}
                  items={this.props.items ? this.props.items : []}
                  columns={columns}
                  onRenderDetailsFooter={() => (
                    <Pagination
                      rowsPerPage={this.props.rowsPerPage}
                      currentPage={this.props.currentPage}
                      totalItems={this.props.totalItems}
                      onRowsNumberChange={this._handleRowsNumberChange.bind(
                        this
                      )}
                      onPageChange={this._handlePageChange.bind(this)}
                    />
                  )}
                  onRenderDetailsHeader={(props, defaultRender) => (
                    <>
                      <Stack
                        tokens={stackTokens}
                        styles={stackStyles}
                        horizontal
                        verticalAlign="center"
                        horizontalAlign="space-between"
                      >
                        <p
                          style={{
                            color: DefaultPalette.themePrimary,
                            width: 100,
                          }}
                        >
                          {selectionDetails.length === 0
                            ? "No items selected"
                            : selectionDetails.length + " items selected"}
                        </p>
                        <SearchBox
                          className={searchStyle}
                          placeholder="search in table ..."
                          iconProps={{
                            styles: { root: { color: DefaultPalette.black } },
                          }}
                          onSearch={(newValue) =>
                            console.log("value is: " + newValue)
                          }
                        />
                      </Stack>
                      <span>{defaultRender(props)}</span>
                    </>
                  )}
                  // onRenderCheckbox={(props, defaultRender) => {
                  //     console.log(props)
                  //     console.log("propsporps")
                  //     return (
                  //         <Stack styles={cellStyles} verticalAlign='center' >
                  //             {defaultRender(props)}
                  //         </Stack>
                  //     );
                  // }}

                  onRenderRow={(props, defaultRender) => {
                    return (
                      <Stack
                        styles={cellStyles}
                        className="centered"
                        verticalAlign="center"
                      >
                        <div
                          onMouseEnter={() => {
                            this.setState({ hovered: props.item.id });
                          }}
                          onMouseLeave={() => this.setState({ hovered: null })}
                        >
                          {defaultRender(props)}
                        </div>
                      </Stack>
                    );
                  }}
                  // onRenderCheckbox={(props, defaultRender) => {
                  //     return <span>{defaultRender(props)}</span>;
                  // }}
                  selection={this._selection}
                  selectionMode={SelectionMode.single}
                  setKey="multiple"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onColumnHeaderClick={(ev, column) =>
                    this._onColumnClick(ev, column)
                  }
                  selectionPreservedOnEmptyClick={true}
                  onItemInvoked={() => {}}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                />
              )}
            </div>
          </Fabric>
        </div>

        <div className={contentStyles.footer}>
          <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
            {/*<DefaultButton text="Close" onClick={this.props.onDismiss} />*/}
            <PrimaryButton
              // disabled={this._selection.getSelection().length === 0}
              onRenderIcon={this._renderSpinner}
              text="Close"
              onClick={() => this.props.onDismiss()}
            />
          </Stack>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    // items: state.inventory.items?.results,
    items: state.deskADeal.deskADeals.results,
    loading: state.deskADeal.loading,

    totalItems: state.inventory.items?.totalItems,
    currentPage: state.inventory.items?.currentPage,
    rowsPerPage: state.inventory?.rowsPerPageInventory,
    sortColumn: state.inventory?.sortColumn,
    sort: state.inventory?.sort,
    // updateLoading: state.inventory.updateLoading,
    // deleteLoading: state.inventory.deleteLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetSavedDeskADeals: (payload) =>
      dispatch({ type: HANDLE_GET_SAVED_DESK_A_DEALS, payload }),

    setRowsPerPage: (payload) =>
      dispatch({ type: SET_ROWS_PER_PAGE_INVENTORY, payload }),
    handleDeleteInventory: (ids, callback?) =>
      dispatch({ type: HANDLE_DELETE_INVENTORY, payload: { ids, callback } }),
    handleUpdateInventory: (payload) =>
      dispatch({
        type: HANDLE_UPDATE_INVENTORY,
        payload,
      }),
    handleSortTable: (payload) =>
      dispatch({
        type: HANDLE_SORT_INVENTORY_TABLE,
        payload,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenSavedDeskADealDialog);
