import * as actions from "store/groups/groups.types";
import { IGroup } from "types/groupTypes";

interface IState {
  loadingDelete: boolean;
  loading: boolean;
  loadingRemoveFromAddToGroup: boolean;
  loadingUpdate: boolean;
  createdGroup: IGroup;
  createGroupLoading: boolean;
  createGroupError: any;
  groups: IGroup[];
  selectedGroup: IGroup;
  selectedGroupError: null;
  error: null | object;
  rowsPerPage: number;
  rowsPerPageAddGroupEmployees: number;
  rowsPerPageEditGroupEmployees: number;
}

const initialState: IState = {
  loadingDelete: false,
  loading: false,
  loadingRemoveFromAddToGroup: false,
  loadingUpdate: false,
  groups: [],
  selectedGroup: null,
  selectedGroupError: null,
  error: null,
  createdGroup: null,
  createGroupLoading: false,
  createGroupError: null,
  rowsPerPage: 10,
  rowsPerPageAddGroupEmployees: 10,
  rowsPerPageEditGroupEmployees: 10,
};

export const GroupsReducer = (
  state: IState = initialState,
  action: actions.GroupActions
) => {
  switch (action.type) {
    case actions.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case actions.SET_ROWS_PER_PAGE_ADD_GROUP_EMPLOYEES: {
      return {
        ...state,
        rowsPerPageAddGroupEmployees: action.payload,
      };
    }

    case actions.SET_ROWS_PER_PAGE_EDIT_GROUP_EMPLOYEES: {
      return {
        ...state,
        rowsPerPageEditGroupEmployees: action.payload,
      };
    }

    case actions.ADD_EMPLOYEE_TO_GROUP_PENDING: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: true,
      };
    }
    case actions.ADD_EMPLOYEE_TO_GROUP_RESOLVED: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: false,
      };
    }
    case actions.ADD_EMPLOYEE_TO_GROUP_REJECTED: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: false,
      };
    }

    case actions.UPDATE_GROUP_PENDING: {
      return {
        ...state,
        loadingUpdate: true,
      };
    }
    case actions.UPDATE_GROUP_RESOLVED: {
      return {
        ...state,
        loadingUpdate: false,
      };
    }
    case actions.UPDATE_GROUP_REJECTED: {
      return {
        ...state,
        loadingUpdate: false,
      };
    }

    case actions.REMOVE_EMPLOYEE_FROM_GROUP_PENDING: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: true,
      };
    }
    case actions.REMOVE_EMPLOYEE_FROM_GROUP_RESOLVED: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: false,
      };
    }

    case actions.REMOVE_EMPLOYEE_FROM_GROUP_REJECTED: {
      return {
        ...state,
        loadingRemoveFromAddToGroup: false,
      };
    }

    case actions.VIEW_GROUP_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.VIEW_GROUP_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedGroup: action.payload,
      };
    }
    case actions.VIEW_GROUP_REJECTED: {
      return {
        ...state,
        loading: false,
        selectedGroupError: action.payload,
      };
    }

    case actions.CREATE_GROUP_PENDING: {
      return {
        ...state,
        createGroupLoading: true,
      };
    }

    case actions.CREATE_GROUP_RESOLVED: {
      return {
        ...state,
        createGroupLoading: false,
        //createdGroup: action.payload,
        selectedGroup: action.payload,
        groups: [...state.groups, action.payload]
      };
    }
    case actions.CREATE_GROUP_REJECTED: {
      return {
        ...state,
        createGroupLoading: false,
        createGroupError: action.payload,
      };
    }

    case actions.GET_GROUPS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_GROUPS_RESOLVED: {
      return {
        ...state,
        loading: false,
        groups: action.payload,
      };
    }
    case actions.GET_GROUPS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.DELETE_GROUP_PENDING: {
      return {
        ...state,
        loadingDelete: true
      }
    }
    case actions.DELETE_GROUP_RESOLVED: {
      return {
        ...state,
        loadingDelete: false
      }
    }
    case actions.DELETE_GROUP_REJECTED: {
      return {
        ...state,
        loadingDelete: false
      }
    }

    default:
      return state;
  }
};
