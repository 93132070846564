import * as actionTypes from "store/shared/shared.types";
import {
  IHandleFavourite,
  IEmail,
  ISMS,
  ISMTPSettings,
  IConnection,
  IPickerItem,
  ISMSSettings,
} from "types/sharedTypes";
import { FavouriteAction } from "constants/constants";

export const HandleZipCodeFlatData = (
  data: any
): actionTypes.HandleZipCodeFlatData => {
  return {
    type: actionTypes.HANDLE_ZIPCODE_FLATDATA,
    payload: data,
  };
};

/** UPDATE_FAVOURITE */
export const handleUpdateFavourite = (
  handleData: IHandleFavourite[],
  callback: Function,
  action: FavouriteAction
): actionTypes.HandleUpdateFavourite => {
  return {
    type: actionTypes.HANDLE_UPDATE_FAVOURITE,
    payload: { handleData, callback, action },
  };
};

export const pendingUpdateFavourite = (): actionTypes.UpdateFavouritePending => {
  
  return {
    type: actionTypes.UPDATE_FAVOURITE_PENDING,
  };
};

export const resolveUpdateFavourite = (): actionTypes.UpdateFavouriteResolved => {
  return {
    type: actionTypes.UPDATE_FAVOURITE_RESOLVED,
  };
};

export const rejectedUpdateFavourite = (
  error
): actionTypes.UpdateFavouriteRejected => {
  return {
    type: actionTypes.UPDATE_FAVOURITE_REJECTED,
    payload: error,
  };
};

/** ADD_FAVOURITE */
export const handleAddFavourite = (
  handleData: IHandleFavourite[],
  callback: Function
): actionTypes.HandleAddFavourite => {
  return {
    type: actionTypes.HANDLE_ADD_FAVOURITE,
    payload: { handleData, callback },
  };
};

export const pendingAddFavourite = (): actionTypes.AddFavouritePending => {
  
  return {
    type: actionTypes.ADD_FAVOURITE_PENDING,
  };
};

export const resolveAddFavourite = (): actionTypes.AddFavouriteResolved => {
  return {
    type: actionTypes.ADD_FAVOURITE_RESOLVED,
  };
};

export const rejectedAddFavourite = (
  error
): actionTypes.AddFavouriteRejected => {
  return {
    type: actionTypes.ADD_FAVOURITE_REJECTED,
    payload: error,
  };
};

/** REMOVE_FAVOURITE */
export const handleRemoveFavourite = (
  handleData: IHandleFavourite[],
  callback: Function
): actionTypes.HandleRemoveFavourite => {
  return {
    type: actionTypes.HANDLE_REMOVE_FAVOURITE,
    payload: { handleData, callback },
  };
};

export const pendingRemoveFavourite = (): actionTypes.RemoveFavouritePending => {
  
  return {
    type: actionTypes.REMOVE_FAVOURITE_PENDING,
  };
};

export const resolveRemoveFavourite = (): actionTypes.RemoveFavouriteResolved => {
  return {
    type: actionTypes.REMOVE_FAVOURITE_RESOLVED,
  };
};

export const rejectedRemoveFavourite = (
  error
): actionTypes.RemoveFavouriteRejected => {
  return {
    type: actionTypes.REMOVE_FAVOURITE_REJECTED,
    payload: error,
  };
};

/** GET SMTP SETTINGS */

export const handleGetSMTPSettings = (): actionTypes.HandleGetSMTPSettings => {
  
  return {
    type: actionTypes.HANDLE_GET_SMTP_SETTINGS,
  };
};

export const pendingGetSMTPSettings = (): actionTypes.GetSMTPSettingsPending => {
  return {
    type: actionTypes.GET_SMTP_SETTINGS_PENDING,
  };
};
export const resolvedGetSMTPSettings = (
  settings: ISMTPSettings
): actionTypes.GetSMTPSettingsResolved => {
  return {
    type: actionTypes.GET_SMTP_SETTINGS_RESOLVED,
    payload: settings,
  };
};
export const rejectedGetSMTPSettings = (
  error: any
): actionTypes.GetSMTPSettingsRejected => {
  return {
    type: actionTypes.GET_SMTP_SETTINGS_REJECTED,
    payload: error,
  };
};
/** UPDATE_SMTP_SETTINGS */
export const handleUpdateSMTPSettings = (
  settings: ISMTPSettings
): actionTypes.HandleUpdateSMTPSettings => {
  return {
    type: actionTypes.HANDLE_UPDATE_SMTP_SETTINGS,
    payload: settings,
  };
};

export const pendingUpdateSMTPSettings = (): actionTypes.UpdateSMTPSettingsPending => {
  return {
    type: actionTypes.UPDATE_SMTP_SETTINGS_PENDING,
  };
};
export const resolvedUpdateSMTPSettings = (
  settings: ISMTPSettings
): actionTypes.UpdateSMTPSettingsResolved => {
  return {
    type: actionTypes.UPDATE_SMTP_SETTINGS_RESOLVED,
    payload: settings,
  };
};
export const rejectedUpdateSMTPSettings = (
  error: any
): actionTypes.UpdateSMTPSettingsRejected => {
  return {
    type: actionTypes.UPDATE_SMTP_SETTINGS_REJECTED,
    payload: error,
  };
};
/** CLEAR_SMTP_SETTINGS */
export const clearSMTPSettings = (): actionTypes.ClearSMTPSettings => {
  return {
    type: actionTypes.CLEAR_SMTP_SETTINGS,
  };
};

/** TEST CONNECTION */
export const handleTestConnection = (
  object
): actionTypes.HandleTestConnection => {
  return {
    type: actionTypes.HANDLE_TEST_CONNECTION,
    payload: object,
  };
};

export const pendingTestConnection = (): actionTypes.TestConnectionPending => {
  return {
    type: actionTypes.TEST_CONNECTION_PENDING,
  };
};
export const resolvedTestConnection = (
  connection: IConnection
): actionTypes.TestConnectionResolved => {
  return {
    type: actionTypes.TEST_CONNECTION_RESOLVED,
    payload: connection,
  };
};
export const rejectedTestConnection = (): actionTypes.TestConnectionRejected => {
  return {
    type: actionTypes.TEST_CONNECTION_REJECTED,
  };
};

/** SEND EMAIL */
export const handleSendEmail = (
  email: IEmail,
  callback: () => void
): actionTypes.HandleSendEmail => {
  return {
    type: actionTypes.HANDLE_SEND_EMAIL,
    payload: { email, callback },
  };
};

export const pendingSendEmail = (): actionTypes.SendEmailPending => {
  
  return {
    type: actionTypes.SEND_EMAIL_PENDING,
  };
};

export const resolvedSendEmail = (): actionTypes.SendEmailResolved => {
  return {
    type: actionTypes.SEND_EMAIL_RESOLVED,
  };
};

export const rejectedSendEmail = (error): actionTypes.SendEmailRejected => {
  return {
    type: actionTypes.SEND_EMAIL_REJECTED,
    payload: error,
  };
};

/** SEND SMS */
export const handleSendSMS = (
  sms: ISMS,
  callback: () => void
): actionTypes.HandleSendSMS => {
  return {
    type: actionTypes.HANDLE_SEND_SMS,
    payload: { sms, callback },
  };
};

export const pendingSendSMS = (): actionTypes.SendSMSPending => {
  return {
    type: actionTypes.SEND_SMS_PENDING,
  };
};

export const resolvedSendSMS = (): actionTypes.SendSMSResolved => {
  return {
    type: actionTypes.SEND_SMS_RESOLVED,
  };
};

export const rejectedSendSMS = (error): actionTypes.SendSMSRejected => {
  return {
    type: actionTypes.SEND_SMS_REJECTED,
    payload: error,
  };
};

/** GET_MAKES */
export const handleGetMakes = (): actionTypes.HandleGetMakes => {
  return {
    type: actionTypes.HANDLE_GET_MAKES,
  };
};

export const handleGetdataOneMakes = (year: any): actionTypes.HandleGetDataOneMakes => {
  return {
    type: actionTypes.HANDLE_GET_DATAONE_MAKES,
    payload: year
  };
};

export const resolvedGetDataOneMakes = (
  payload: any
): actionTypes.HandleGetDataOneMakesResolved => {
  return {
    type: actionTypes.HANDLE_GET_DATAONE_MAKES_RESOLVED,
    payload: payload,
  };
};

export const pendingGetMakes = (): actionTypes.GetMakesPending => {
  return {
    type: actionTypes.GET_MAKES_PENDING,
  };
};

export const resolvedGetMakes = (
  payload: IPickerItem[]
): actionTypes.GetMakesResolved => {
  return {
    type: actionTypes.GET_MAKES_RESOLVED,
    payload: payload,
  };
};

export const rejectedGetMakes = (error): actionTypes.GetMakesRejected => {
  return {
    type: actionTypes.GET_MAKES_REJECTED,
    payload: error,
  };
};

/** GET_MODELS */
export const handleGetModels = (make: string): actionTypes.HandleGetModels => {
  return {
    type: actionTypes.HANDLE_GET_MODELS,
    payload: make,
  };
};

export const handleGetdataOneModels = (payload: any): actionTypes.HandleGetDataOneModels => {
  return {
    type: actionTypes.HANDLE_GET_DATAONE_MODELS,
    payload: payload
  };
};

export const resolvedGetDataOneModels = (
  payload: any
): actionTypes.HandleGetDataOneModelsResolved => {
  return {
    type: actionTypes.HANDLE_GET_DATAONE_MODELS_RESOLVED,
    payload: payload,
  };
};

export const pendingGetModels = (): actionTypes.GetModelsPending => {
  return {
    type: actionTypes.GET_MODELS_PENDING,
  };
};

export const resolvedGetModels = (
  payload: IPickerItem[]
): actionTypes.GetModelsResolved => {
  return {
    type: actionTypes.GET_MODELS_RESOLVED,
    payload: payload,
  };
};

export const rejectedGetModels = (
  error: string | object
): actionTypes.GetModelsRejected => {
  return {
    type: actionTypes.GET_MODELS_REJECTED,
    payload: error,
  };
};

/** GET SMS SETTINGS */
export const handleGetSMSSettings = (): actionTypes.HandleGetSMSSettings => {
  return {
    type: actionTypes.HANDLE_GET_SMS_SETTINGS,
  };
};

export const pendingGetSMSSettings = (): actionTypes.GetSMSSettingsPending => {
  return {
    type: actionTypes.GET_SMS_SETTINGS_PENDING,
  };
};
export const resolvedGetSMSSettings = (
  settings: ISMSSettings
): actionTypes.GetSMSSettingsResolved => {
  return {
    type: actionTypes.GET_SMS_SETTINGS_RESOLVED,
    payload: settings,
  };
};
export const rejectedGetSMSSettings = (
  error: any
): actionTypes.GetSMSSettingsRejected => {
  return {
    type: actionTypes.GET_SMS_SETTINGS_REJECTED,
    payload: error,
  };
};


/** UPDATE_SMS_SETTINGS */
export const handleUpdateSMSSettings = (
    settings: ISMSSettings
): actionTypes.HandleUpdateSMSSettings => {
  return {
    type: actionTypes.HANDLE_UPDATE_SMS_SETTINGS,
    payload: settings,
  };
};

export const pendingUpdateSMSSettings = (): actionTypes.UpdateSMSSettingsPending => {
  return {
    type: actionTypes.UPDATE_SMS_SETTINGS_PENDING,
  };
};
export const resolvedUpdateSMSSettings = (
    settings: ISMSSettings
): actionTypes.UpdateSMSSettingsResolved => {
  return {
    type: actionTypes.UPDATE_SMS_SETTINGS_RESOLVED,
    payload: settings,
  };
};
export const rejectedUpdateSMSSettings = (
    error: any
): actionTypes.UpdateSMSSettingsRejected => {
  return {
    type: actionTypes.UPDATE_SMS_SETTINGS_REJECTED,
    payload: error,
  };
};

