import React from "react";
import { useEffect, useState } from "react";
import {
    Stack,
    IStackStyles,
    Link
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { handleGetVacationReports } from "store/dealTracker/dealTracker.action";
import { AppState } from "store/index";
import { handleGetLocations } from "store/locations/locations.action";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { Col, Row } from "react-grid-system";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { HANDLE_GET_EMPLOYEES } from "store/employees/employees.types";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { DaysTakenDialog } from "components/dialogs/dealTrackerDialog/DaysTakenDialog";
import moment from "moment";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};
//const credentialModuleName = "Location";
export const VacationReports = () => {
    const dispatch = useDispatch();

    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [vacationReportData, setVacationReportData] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [locationFilter, setLocationFilter] = React.useState(null);
    const [selectedData, setSelectedData] = React.useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let list = [];
        if (locations.results.length > 0) {
            locations.results.map((item: any) => {
                return list.push({ key: item.id, text: (item.legalName) })
            });
            list.unshift({ key: 0, text: 'All' });
            setCityDealerList(list);
        } else {
            setCityDealerList(null);
        }
    }, [locations]);

    useEffect(() => {
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setLocationFilter(user.primaryLocationId);
            } else {
                setLocationFilter(0);
            }

        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "financePerson" } })
        dispatch<any>(handleGetLocations());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationFilter]);

    const handleFilters = () => {
        const formData = {
            locationId: locationFilter ? locationFilter : "",
        };
        dispatch<any>(handleGetVacationReports({

            formData,
            callback: (response) => {
                if (response) {
                    response = response.map(obj => ({ ...obj, financePerson: obj.firstName + ' ' + obj.lastName, hireDateStr: obj.hireDate && moment(obj.hireDate).local().format('MM/DD/YYYY') }));
                    setVacationReportData(response);
                    console.log('response', response);
                } else {
                    setVacationReportData(null);

                }
            }
        }));
    }


    const exportExcel = () => {
        if (vacationReportData) {
            let data = [...vacationReportData]

            const exportData = data.map((item) =>
                ({
                    'Finance Person': item.financePerson,
                    'Primary Location': item.primaryLocation,
                    'Hire Date': item.hireDateStr,
                    'Days Allowed': item.numberOfdaysAllowed,
                    'Days Taken': item.numberOfDaysTaken
                }));


            import('xlsx').then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(exportData);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'data');
            });
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    const handleLocationDropdownChange = (e) => {
        setLocationFilter(e.value)
    };

    const header = (
        <div >
            <div className=" table-header " style={{ marginBottom: "10px", display: "block" }} >
                <Row  >
                    <Col md={12}  >
                        <Row  >

                            <Col md={2} className="columnBtwSpace">

                                <label className="lbl-text">Location</label>
                                <PRDropdown
                                    id="location"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>

            </div>

            <div style={{ float: "right", marginRight: "-15px", fontSize: "16px",marginTop:'-15px' }}>
                <Link color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                    Export to Excel
		     </Link>

            </div>
        </div>
    );

    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person</span>
                {rowData.financePerson}
            </React.Fragment>
        );
    };

    const primaryLocationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Primary Location</span>
                {rowData.financePersonPrimaryLocation}
            </React.Fragment>
        );
    };

    const hireDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Hire Date</span>
                {rowData.hireDateStr}
            </React.Fragment>
        );
    };

    const daysAllowedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Days Allowed</span>
                {rowData.numberOfdaysAllowed}
            </React.Fragment>
        );
    };

    const daysTakenBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Days Taken</span>
                <Link color="inherit" onClick={() => openDaysTakenDialog(rowData)} > <u>{rowData.numberOfDaysTaken} </u></Link>
            </React.Fragment>
        );
    };

    const openDaysTakenDialog = (data) => {
        setSelectedData(data);
        setIsOpen(true);
    }

    return (

        isKarlProfileType ?

            <>
                <div className="reportFinanceProductWidth">
                    <Stack styles={container} tokens={{ childrenGap: 10 }}>

                        <Stack
                            horizontal
                            verticalAlign="center"
                            horizontalAlign="space-between"
                            className="loc_btn_par_con"
                        >
                            {isOpen && (
                                <DaysTakenDialog
                                    isOpen={isOpen}
                                    data={selectedData}
                                    onDismiss={() => setIsOpen(false)}
                                />
                            )}

                        </Stack>
                        <Row>
                            <Col className="alignHeaders">
                                <CustomFontText className="pageTitleFont pageName pageHeaderFont">Vacation Report</CustomFontText>
                            </Col>
                        </Row>
                        <div className="finnaceSalesReportHeaderPart tablePadSizeWidth datatable-filter-demo trade-in-workboook dropDownBorderColor">
                            <div >
                                <DataTable
                                    value={vacationReportData}
                                    header={header}
                                    className=" p-datatable-customers "
                                    paginator={false}
                                    rows={10000}>
                                    <Column field="financePerson" header="Finance Person" body={financePersonBodyTemplate} sortable />
                                    <Column field="financePersonPrimaryLocation" header="Primary Location" body={primaryLocationBodyTemplate} sortable />
                                    <Column field="hireDateStr" header="Hire Date" body={hireDateBodyTemplate} sortable />
                                    <Column field="numberOfdaysAllowed" header="Days Allowed" body={daysAllowedBodyTemplate} sortable />
                                    <Column field="numberOfDaysTaken" header="Days Taken" body={daysTakenBodyTemplate} sortable />
                                </DataTable>
                            </div>
                        </div>
                    </Stack>
                </div>
            </> : null
    );
};
