import React, { useEffect, useState } from "react";
import {
  Stack,
  PrimaryButton,
  DefaultButton,
  Link,
} from "@fluentui/react";
import Styles from "../dealsPage/styles";
import GoBack from "components/buttons/goBack/GoBack";
import { Fabric } from "@fluentui/react/lib/Fabric";
import { useLocation, useNavigate  } from "react-router-dom";
import { formatPathname } from "utils/helperFunctions";
import { Col, Container, Row } from "react-grid-system";
import { styles } from "pages/marketingPage/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { WebsiteDetailsDialog } from "components/dialogs/websiteDetails/WebsiteDetailsDialog";
import { ConfigurationServiceDialog } from "components/dialogs/configurationServiceDialog/ConfigurationServiceDialog";
import { AppState } from "store";
import {
  handleGetTemplates,
  handleGetWebsite,
  handleUpdateWebsite,
} from "store/website/website.action";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

enum Roles {
  RoleWebsiteStandard = "ROLE_WEBSITE_STANDARD",
  RoleWebsitePremium = "ROLE_WEBSITE_PREMIUM",
}

export const MarketingPage = () => {
  const [pathname, setPathname] = useState("");
  const [details, setDetails] = useState(null);
  const [selectedActive, setSelectedActive] = useState(null);
  const [
    isConfigurationServiceDialogOpen,
    setIsConfigurationServiceDialogOpen,
  ] = useState(false);

  // const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const location = useLocation();
  const navigate = useNavigate ();
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.userProfile.userProfile);
  const templates = useSelector((state: AppState) => state.website.templates);
  const website = useSelector(({ website }: AppState) => website.website);
  const loading = useSelector(({ website }: AppState) => website.loading);

  useEffect(() => {
    dispatch<any>(handleGetTemplates());
    dispatch<any>(handleGetWebsite());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const handleItemClick = (item) => {
    setDetails(item);
  };

  const handleActivateWebsite = (templateId: number) => {
    setSelectedActive(templateId);

    // console.log(website);
    //
    // let updatedWebsite: IWebsite = {
    //   configuration: templateId,
    //   socialMedia: { facebook: "", google: "", instagram: "", twitter: "" },
    //   workingHours: {
    //     friday: "",
    //     monday: "",
    //     saturday: "",
    //     sunday: "",
    //     thursday: "",
    //     tuesday: "",
    //     wednesday: "",
    //   },
    //   domain: "",
    //   status: false,
    // };

    if (website) {
      dispatch<any>(
        handleUpdateWebsite({ ...website, configuration: templateId }, () => {
          dispatch<any>(handleGetTemplates());
          dispatch<any>(handleGetWebsite());
        })
      );
    } else {



      setIsConfigurationServiceDialogOpen(true);
    }


  };

  const onActivateWebsiteClicked = () => {
    navigate("/subscriptions");
  };

  const shouldDisabledButton = (type): boolean => {
    if (
      user.roles?.includes(Roles.RoleWebsitePremium) &&
      type.includes(Roles.RoleWebsitePremium)
    ) {
      return false;
    }
    return !(
      user.roles?.includes(Roles.RoleWebsiteStandard) &&
      type.includes(Roles.RoleWebsiteStandard)
    );
  };

  const isActive = (id) => {
    return id === website?.configuration;
  };

  const renderSpinner = (id) => {
    return loading && id === selectedActive ? (
      <Spinner size={SpinnerSize.medium} />
    ) : null;
  };

  return (
    <>
      {details !== null && (
        <WebsiteDetailsDialog
            openConfigureDialog={() => {
              setSelectedActive(details.id)
              setIsConfigurationServiceDialogOpen(true)
            }}
          shouldDisabledButton={shouldDisabledButton}
          onDismiss={() => setDetails(null)}
          details={details}
        />
      )}

      {isConfigurationServiceDialogOpen && (
        <ConfigurationServiceDialog
            templateId={selectedActive}
          shouldDisabledButton={shouldDisabledButton}
          onDismiss={() => setIsConfigurationServiceDialogOpen(false)}
        />
      )}

      <Stack styles={Styles.container} tokens={{ childrenGap: 10 }}>
        <Fabric>
          <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="space-between"
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 30 }}
            >
              <GoBack label={formatPathname(pathname)} />
            </Stack>
          </Stack>
          <Container fluid style={styles.container}>
            {user?.roles?.includes(Roles.RoleWebsiteStandard) ||
            user?.roles?.includes(Roles.RoleWebsitePremium) ? (
              <Row style={styles.activeAccountHeaderContainer}>
                <Col>
                  <Stack
                    horizontal
                    horizontalAlign={
                      Boolean(website?.domain) ? "space-between" : "end"
                    }
                    verticalAlign="center"
                  >
                    {Boolean(website?.domain) ? (
                      <div>
                        {" "}
                        The service works correctly at{" "}
                        <Link className="ln-nw-link" href="">{website?.domain}</Link>
                      </div>
                    ) : (
                      <></>
                    )}

                    <Stack
                      horizontal
                      horizontalAlign="space-between"
                      verticalAlign="center"
                      tokens={{ childrenGap: 10 }}
                    >
                      <DefaultButton
                        iconProps={{ iconName: "Settings" }}
                        text="Configuration Service"
                        onClick={() =>
                          setIsConfigurationServiceDialogOpen(true)
                        }
                      />
                      {/*<PrimaryButton*/}
                      {/*  iconProps={{ iconName: "Settings" }}*/}
                      {/*  text="Edit Your Website Content"*/}
                      {/*  onClick={() => {}}*/}
                      {/*/>*/}
                    </Stack>
                  </Stack>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col
                  style={styles.headerContainer}
                  sm={12}
                  md={6}
                  xl={12}
                  lg={12}
                  xxl={12}
                >
                  <div style={styles.headerImage}>
                    <div style={styles.headerContent}>
                      <div>
                        <CustomFontText size={40} color={"#0065CA"} bold>
                          start automatic website
                        </CustomFontText>
                        <CustomFontText size={24} color={"#0065CA"} bold>
                          simple way to show your offer
                        </CustomFontText>
                      </div>
                      <div>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Aliquid amet commodi cum deserunt dolorum eos
                        laudantium mollitia quibusdam sapiente voluptates?
                      </div>
                      <Stack
                        horizontal
                        verticalAlign="center"
                        tokens={{ childrenGap: 10 }}
                      >
                        <PrimaryButton
                          // onRenderIcon={{iconName: 'Globe'} }
                          iconProps={{ iconName: "Globe" }}
                          text="Activate Website Now "
                          onClick={onActivateWebsiteClicked}
                        />

                        <CustomFontText size={16} color={"#0065CA"} bold>
                          only $39.89 / month
                        </CustomFontText>
                      </Stack>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 20 }}>
              <Col
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  // marginLeft: -10,
                  // marginRight: -10,
                  width: 2350,
                }}
                sm={3}
                md={6}
                xl={6}
                lg={12}
                xxl={12}
              >
                {templates?.map((template) => {
                  return (
                    <div style={styles.itemContainer} key={template.id}>
                      <div
                        style={{
                          width: 350,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <CustomFontText
                          size={20}
                          style={{
                            marginBottom: 20,
                            marginTop: 20,
                            alignSelf: "flex-start",
                          }}
                        >
                          {template.name}
                        </CustomFontText>
                        <div style={styles.itemImage} />
                        <div
                          style={{
                            alignSelf: "flex-end",
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          {isActive(template.id) ? (
                            <span style={{ color: "#0065CA" }}>
                              Currently used
                            </span>
                          ) : (
                            <DefaultButton
                              onClick={() => handleItemClick(template)}
                              text="Preview"
                              // disabled={shouldDisabledButton(template.type)}
                            />
                          )}
                          <PrimaryButton
                            style={{ marginLeft: 10 }}
                            onClick={() => handleActivateWebsite(template.id)}
                            text="Activate template"
                            // disabled={isActive(template.id) || shouldDisabledButton(template.type)}
                            disabled={
                              isActive(template.id) ||
                              shouldDisabledButton(template.type)
                            }
                            onRenderIcon={() => renderSpinner(template.id)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Fabric>
      </Stack>
    </>
  );
};
