import { ICosigner } from "types/cosignerTypes";

// CREATE COSIGNER
export const HANDLE_CREATE_COSIGNER = "HANDLE_CREATE_COSIGNER";
export interface HandleCreateCosigner {
  type: typeof HANDLE_CREATE_COSIGNER;
  payload: { cosigner: ICosigner; callback?: Function };
}

export const CREATE_COSIGNER_PENDING = "CREATE_COSIGNER_PENDING";
export interface CreateCosignerPending {
  type: typeof CREATE_COSIGNER_PENDING;
}

export const CREATE_COSIGNER_RESOLVED = "CREATE_COSIGNER_RESOLVED";
export interface CreateCosignerResolved {
  type: typeof CREATE_COSIGNER_RESOLVED;
  payload: ICosigner;
}

export const CREATE_COSIGNER_REJECTED = "CREATE_COSIGNER_REJECTED";
export interface CreateCosignerRejected {
  type: typeof CREATE_COSIGNER_REJECTED;
  payload: any;
}

// EDIT COSIGNER
export const HANDLE_EDIT_COSIGNER = "HANDLE_EDIT_COSIGNER";
export interface HandleEditCosigner {
  type: typeof HANDLE_EDIT_COSIGNER;
  payload: { cosigner: ICosigner; callback?: Function, id: number };
}

export const EDIT_COSIGNER_PENDING = "EDIT_COSIGNER_PENDING";
export interface EditCosignerPending {
  type: typeof EDIT_COSIGNER_PENDING;
}

export const EDIT_COSIGNER_RESOLVED = "EDIT_COSIGNER_RESOLVED";
export interface EditCosignerResolved {
  type: typeof EDIT_COSIGNER_RESOLVED;
  payload: ICosigner;
}

export const EDIT_COSIGNER_REJECTED = "EDIT_COSIGNER_REJECTED";
export interface EditCosignerRejected {
  type: typeof EDIT_COSIGNER_REJECTED;
  payload: any;
}

export type CosignersActions =
  | HandleCreateCosigner
  | CreateCosignerPending
  | CreateCosignerResolved
  | CreateCosignerRejected
  | HandleEditCosigner
  | EditCosignerPending
  | EditCosignerRejected
  | EditCosignerResolved;
