import { IStackStyles, IStackTokens } from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { mergeStyles } from "@fluentui/react";
import { colors } from "constants/constants";

interface ICustomStyles {
  container: any;
  headerStyles: IStackStyles;
  col: any;
  buttonsTokens: IStackTokens;
  buttonsStyles: IStackStyles;
  iconStyles: any;
}

export const styles: ICustomStyles = {
  container: {
    padding: 0,
    margin: 0,
    paddingTop: 25,
    paddingBottom: 0,
  },
  headerStyles: {
    root: {
      width: "100%",
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  col: {
    boxShadow: Depths.depth8,
    background: DefaultPalette.white,
    paddingBottom: 25,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 2,
    zIndex: 1,
  },

  iconStyles: mergeStyles({
    borderRadius: 5,
    padding: 5,
    color: "white",
    backgroundColor: colors.grayText,
  }),

  buttonsTokens: {
    childrenGap: 10,
  },

  buttonsStyles: {
    root: {
      paddingTop: 40,
    },
  },
};
