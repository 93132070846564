import {IDeal, IDealFilters, IDeals, IDealSettings} from "types/dealTypes";
import * as actions from "store/deals/deals.types";
import {
  DEAL_ASSIGN_SIGNER_PENDING,
  DEAL_ASSIGN_SIGNER_REJECTED,
  DEAL_ASSIGN_SIGNER_RESOLVED,
  DEAL_UNASSIGN_SIGNER_PENDING,
  DEAL_UNASSIGN_SIGNER_REJECTED,
  DEAL_UNASSIGN_SIGNER_RESOLVED,
  HANDLE_DEAL_ASSIGN_SIGNER,
  HANDLE_DEAL_UNASSIGN_SIGNER,
  HANDLE_GET_DEAL_SETTINGS,
  SET_SORT_DEALS
} from "store/deals/deals.types";
import {IPagination} from "../../types/paginationTypes";

export const handleCreateDeal = ({
  deal,
  callback,
}): actions.HandleCreateDeal => {
  return {
    type: actions.HANDLE_CREATE_DEAL,
    payload: { deal, callback },
  };
};

export const pendingCreateDeal = (): actions.CreateDealPending => {
  return {
    type: actions.CREATE_DEAL_PENDING,
  };
};

export const resolvedCreateDeal = (deal: any): actions.CreateDealResolved => {
  return {
    type: actions.CREATE_DEAL_RESOLVED,
    payload: deal,
  };
};

export const rejectedCreateDeal = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.CreateDealRejected => {
  return {
    type: actions.CREATE_DEAL_REJECTED,
    payload: errors,
  };
};



export const handleGetDeals = (request: IPagination) => {
  return {
    type: actions.HANDLE_GET_DEALS,
    payload: request,
  };
};

export const pendingGetDeals = (): actions.GetDealsPending => {
  return {
    type: actions.GET_DEALS_PENDING,
  };
};

export const resolvedGetDeals = (deals: IDeals): actions.GetDealsResolved => {
  return {
    type: actions.GET_DEALS_RESOLVED,
    payload: deals,
  };
};

export const rejectedGetDeals = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetDealsRejected => {
  return {
    type: actions.GET_DEALS_REJECTED,
    payload: errors,
  };
};
export const setRowsPerPage = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_DEALS,
    payload: rows,
  };
};

/** UPDATE_DEAL */
export const handleUpdateDeal = (deals, callback): actions.HandleUpdateDeal => {
  return {
    type: actions.HANDLE_UPDATE_DEALS,
    payload: { deals, callback },
  };
};

export const pendingUpdateDeal = (): actions.UpdateDealPending => {
  return {
    type: actions.UPDATE_DEAL_PENDING,
  };
};

export const resolvedUpdateDeal = (): actions.UpdateDealResolved => {
  return {
    type: actions.UPDATE_DEAL_RESOLVED,
  };
};

export const rejectedUpdateDeal = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateDealRejected => {
  return {
    type: actions.UPDATE_DEAL_REJECTED,
    payload: errors,
  };
};

/** VIEW_DEAL */
export const handleViewDeal = (id: number | string): actions.HandleViewDeal => {
  return {
    type: actions.HANDLE_VIEW_DEAL,
    payload: id,
  };
};

export const pendingViewDeal = (): actions.ViewDealPending => {
  return {
    type: actions.VIEW_DEAL_PENDING,
  };
};

export const resolvedViewDeal = (deal: IDeal): actions.ViewDealResolved => {
  return {
    type: actions.VIEW_DEAL_RESOLVED,
    payload: deal,
  };
};

export const rejectedViewDeal = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.ViewDealRejected => {
  return {
    type: actions.VIEW_DEAL_REJECTED,
    payload: errors,
  };
};

/** DELETE_Deal */
export const handleDeleteDeal = (
  ids: number[] | string[],
  callback?: Function
): actions.HandleDeleteDeal => {
  return {
    type: actions.HANDLE_DELETE_DEALS,
    payload: { ids, callback },
  };
};

export const pendingDeleteDeal = (): actions.DeleteDealPending => {
  return {
    type: actions.DELETE_DEAL_PENDING,
  };
};

export const resolvedDeleteDeal = (): actions.DeleteDealResolved => {
  return {
    type: actions.DELETE_DEAL_RESOLVED,
  };
};

export const rejectedDeleteDeal = (
  errors: object
): actions.DeleteDealRejected => {
  return {
    type: actions.DELETE_DEAL_REJECTED,
    payload: errors,
  };
};

export const setSortDeals = (sort: string): actions.SetSortDeals => {
  return {
    type: SET_SORT_DEALS,
    payload: sort,
  };
};

export const handleDealAssignSigner = ({
  dealId,
  signerId,
}): actions.HandleDealAssignSigner => {
  return {
    type: HANDLE_DEAL_ASSIGN_SIGNER,
    payload: { dealId, signerId },
  };
};

export const pendingDealAssignSigner = (): actions.DealAssignSignerPending => {
  return {
    type: DEAL_ASSIGN_SIGNER_PENDING,
  };
};

export const resolvedDealAssignSigner = (): actions.DealAssignSignerResolved => {
  return {
    type: DEAL_ASSIGN_SIGNER_RESOLVED,
  };
};

export const rejectedDealAssignSigner = (
  error
): actions.DealAssignSignerRejected => {
  return {
    type: DEAL_ASSIGN_SIGNER_REJECTED,
    payload: error,
  };
};

export const handleDealUnassignSigner = ({
  dealId,
  signerId,
}): actions.HandleDealUnassignSigner => {
  return {
    type: HANDLE_DEAL_UNASSIGN_SIGNER,
    payload: { dealId, signerId },
  };
};

export const pendingDealUnassignSigner = (): actions.DealUnassignSignerPending => {
  return {
    type: DEAL_UNASSIGN_SIGNER_PENDING,
  };
};

export const resolvedDealUnassignSigner = (): actions.DealUnassignSignerResolved => {
  return {
    type: DEAL_UNASSIGN_SIGNER_RESOLVED,
  };
};

export const rejectedDealUnassignSigner = (
  error
): actions.DealUnassignSignerRejected => {
  return {
    type: DEAL_UNASSIGN_SIGNER_REJECTED,
    payload: error,
  };
};


export const handleGetDealSettings = (): actions.HandleGetDealSettings => {
  return {
    type: HANDLE_GET_DEAL_SETTINGS,
  };
};

export const pendingGetDealSettings = (): actions.GetDealSettingsPending => {
  return {
    type: actions.GET_DEAL_SETTINGS_PENDING,
  };
};

export const resolvedGetDealSettings = (dealSettings:IDealSettings ): actions.GetDealSettingsResolved => {
  return {
    type: actions.GET_DEAL_SETTINGS_RESOLVED,
    payload: dealSettings
  };
};

export const rejectedGetDealSettings = (
    error
): actions.GetDealSettingsRejected => {
  return {
    type: actions.GET_DEAL_SETTINGS_REJECTED,
    payload: error,
  };
};

export const handleUpdateDealSettings = (dealSettings: IDealSettings): actions.HandleUpdateDealSettings => {
  
  
  return {
    type: actions.HANDLE_UPDATE_DEAL_SETTINGS,
    payload: dealSettings
  };
};

export const pendingUpdateDealSettings = (): actions.UpdateDealSettingsPending => {
  return {
    type: actions.UPDATE_DEAL_SETTINGS_PENDING,
  };
};

export const resolvedUpdateDealSettings = (dealSettings:IDealSettings ): actions.UpdateDealSettingsResolved => {
  return {
    type: actions.UPDATE_DEAL_SETTINGS_RESOLVED,
    payload: dealSettings
  };
};

export const rejectedUpdateDealSettings = (
    error
): actions.UpdateDealSettingsRejected => {
  return {
    type: actions.UPDATE_DEAL_SETTINGS_REJECTED,
    payload: error,
  };
};

// FILTERS
export const cleanDealFilters = (): actions.CleanDealFilters => {
  return {
    type: actions.CLEAN_DEAL_FILTERS
  }
}

export const setDealFilters = (filters: IDealFilters): actions.SetDealFilters => {
  return {
    type: actions.SET_DEAL_FILTERS,
    payload: filters
  }
}


