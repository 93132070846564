import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
//import {
//	getModulePermissions,
//	isUserMaster,
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import {
    handleGetSalesTarget,
    handleSaveSalesTarget
} from "store/dealTracker/dealTracker.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
//import {
//    useNavigate ,
//} from "react-router-dom";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import masterDropdownValues from "constants/masterDropdownValues";
import { Dropdown } from 'primereact/dropdown';
//import { useMediaQuery } from "react-responsive";
import { emplyeePermissionsScreen } from "../../constants/constants";
import { EmployeeActionPermission } from "hoc/withCredentials";
import { classNames } from "primereact/utils";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

//const credentialModuleName = "DealTracker";
export const SalesTargetPage = () => {
    const dispatch = useDispatch();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const [tableData, setTableData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    //const navigate = useNavigate ();
    const [year, setYear] = useState(new Date().getFullYear());
    //const isMobile = useMediaQuery({ maxWidth: 960 });

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);


    const handleYearFilter = (val: any) => {
        setYear(val.target.value);
        dispatch<any>(handleGetSalesTarget({ year: val.target.value, isFirstTime: false, callback: (res) => handleSalesTargetResponse(res) }));
    };

    useEffect(() => {
        //if (user && user.auths && !isUserMaster(user.roles)) {
        //	setCredentials(getModulePermissions(user.auths, credentialModuleName));
        //}
        //dispatch<any>(handleGetSalesHierarchy());
        dispatch<any>(handleGetSalesTarget({ year, isFirstTime: true, callback: (res) => handleSalesTargetResponse(res) }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSalesTargetResponse = (res) => {
        if (res && res.salesHierarchy && res.salesHierarchy.length) {
            let salesTargetWorkbook = [];
            res.salesHierarchy.forEach(function (item, index) {
                let salesTargetObj = {
                    key: '' + index,
                    data: {
                        id: item.id,
                        groupId: 0,
                        team: item.firstName + ' ' + item.lastName,
                        jan: null,
                        feb: null,
                        mar: null,
                        apr: null,
                        may: null,
                        jun: null,
                        jul: null,
                        aug: null,
                        sep: null,
                        oct: null,
                        nov: null,
                        dec: null,
                    },
                    children: getTarget(item.employees, index, item.id, res.salesTarget)
                }
                salesTargetWorkbook.push(salesTargetObj);
            });
            setTableData(salesTargetWorkbook);
        } else if (!res) {
            setTableData([]);
        }
    }


    const getTarget = (employees, index, groupId, targets) => {
        if (employees && employees.length > 0) {
            let targetWorkbook = []
            employees.forEach(function (item, i) {
                const empSalesTarget = targets.find(function (e) {
                    return e.id === item.id;
                });
                if (empSalesTarget && empSalesTarget.salesGoals) {
                    const targetObj = {
                        key: index + '-' + i,
                        data: {
                            id: item.id,
                            groupId: groupId,
                            team: item.firstName + ' ' + item.lastName,
                            jan: empSalesTarget.salesGoals.find(function (x) { return x.month === 1 }).value,
                            feb: empSalesTarget.salesGoals.find(function (x) { return x.month === 2 }).value,
                            mar: empSalesTarget.salesGoals.find(function (x) { return x.month === 3 }).value,
                            apr: empSalesTarget.salesGoals.find(function (x) { return x.month === 4 }).value,
                            may: empSalesTarget.salesGoals.find(function (x) { return x.month === 5 }).value,
                            jun: empSalesTarget.salesGoals.find(function (x) { return x.month === 6 }).value,
                            jul: empSalesTarget.salesGoals.find(function (x) { return x.month === 7 }).value,
                            aug: empSalesTarget.salesGoals.find(function (x) { return x.month === 8 }).value,
                            sep: empSalesTarget.salesGoals.find(function (x) { return x.month === 9 }).value,
                            oct: empSalesTarget.salesGoals.find(function (x) { return x.month === 10 }).value,
                            nov: empSalesTarget.salesGoals.find(function (x) { return x.month === 11 }).value,
                            dec: empSalesTarget.salesGoals.find(function (x) { return x.month === 12 }).value,
                        }
                    }
                    targetWorkbook.push(targetObj);
                } else {
                    const targetObj = {
                        key: index + '-' + i,
                        data: {
                            id: item.id,
                            groupId: groupId,
                            team: item.firstName + ' ' + item.lastName,
                            jan: 0,
                            feb: 0,
                            mar: 0,
                            apr: 0,
                            may: 0,
                            jun: 0,
                            jul: 0,
                            aug: 0,
                            sep: 0,
                            oct: 0,
                            nov: 0,
                            dec: 0,
                        }
                    }
                    targetWorkbook.push(targetObj);
                }  
            });

            let total = {
                key: index + '-total',
                data: {
                    team: 'Team Total',
                    jan: getSumTarget(targetWorkbook, 'jan'),
                    feb: getSumTarget(targetWorkbook, 'feb'),
                    mar: getSumTarget(targetWorkbook, 'mar'),
                    apr: getSumTarget(targetWorkbook, 'apr'),
                    may: getSumTarget(targetWorkbook, 'may'),
                    jun: getSumTarget(targetWorkbook, 'jun'),
                    jul: getSumTarget(targetWorkbook, 'jul'),
                    aug: getSumTarget(targetWorkbook, 'aug'),
                    sep: getSumTarget(targetWorkbook, 'sep'),
                    oct: getSumTarget(targetWorkbook, 'oct'),
                    nov: getSumTarget(targetWorkbook, 'nov'),
                    dec: getSumTarget(targetWorkbook, 'dec'),
                }
            }
            targetWorkbook.push(total);
            return targetWorkbook;
        } else
            return [];

    };

    const getSumTarget = (arr, property) => {
        if (arr && arr.length) {
            return arr.map(item => item.data[property]).reduce((prev, curr) => prev + curr, 0)
        }
        return 0;
    };

    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    const handleSave = () => {
        setcommonServerError('');
        let dataRaw = [];
        tableData.forEach(function (item, index) {

            if (item.children) {

                item.children.forEach(function (item, index) {
                    if (!item.key.includes('total')) {
                        for (let key in item.data) {
                            if (key.length === 3) {
                                let obj = {
                                    "salesPersonEmployeeId": item.data.id,
                                    "month": months.indexOf(key)+1,
                                    "value": item.data[key] ? item.data[key]:0
                                }
                                dataRaw.push(obj);
                            }
                        }
                    }

                });
            }
        });

        let salesTarget = {
            year: year,
            goals: dataRaw
        }
        dispatch<any>(handleSaveSalesTarget({
            salesTarget, callback: (res) => {
                
                if (!res) {
                    setcommonServerError('Failed to Save the Data.');
                }

            }
        }));
    };

    //Datatable Templates
    const header = (
        <div>
            <Row>
                <Col md={10}>
                    <div className="locationDropPosition">
                        <label className="lbl-text">Year</label>
                        <Dropdown
                            id="salesTagetYear"
                            placeholder=""
                            value={year}
                            options={masterDropdownValues.years}
                            onChange={(e) => handleYearFilter(e)}
                            optionLabel="text"
                            optionValue="key"
                            appendTo="self"
                            className="custom-p-dropdown year-dropdown"
                            filter={false}
                            resetFilterOnHide={true}
                        />
                    </div>
                </Col>
                <Col>
                    <EmployeeActionPermission
                        permissionList={user.aggregatedDefaultPolicies}
                        alias={emplyeePermissionsScreen.SALES_TARGET?.toString()}
                        roles={user.roles}>
                        <PrimaryButton onClick={handleSave} style={{ float: "right" }}>Save</PrimaryButton>
                    </EmployeeActionPermission>
                </Col>

            </Row>

        </div>
    );

    const footer = (
        <>
            <EmployeeActionPermission
                permissionList={user.aggregatedDefaultPolicies}
                alias={emplyeePermissionsScreen.SALES_TARGET?.toString()}
                roles={user.roles}>
                <PrimaryButton onClick={handleSave} style={{ float: "right" }}>Save</PrimaryButton>
            </EmployeeActionPermission>
        </>
    );

    const handleTargetChange = (e, groupId, id, month) => {
        setcommonServerError('');
        if (!e.target.value || !isNaN(e.target.value)) {
            let dataRaw = [];
            let tempData = tableData;
            const groupIndex = tableData.findIndex((x) => x.data.id === groupId);

            const employeeIndex = tableData[groupIndex].children.findIndex((x => x.data.id === id));
            tempData[groupIndex].children[employeeIndex].data[month] = e.target.value ? Number(e.target.value) : '';

            tempData[groupIndex].children[tempData[groupIndex].children.length - 1].data[month] = tempData[groupIndex].children
                .filter(x => x.data[month] !== '' && !x.key.includes('total')).map(item => item.data[month]).reduce((prev, curr) => prev + curr, 0)

            tempData.forEach(function (item, index) {
                dataRaw.push(item);
            });
            setTableData(dataRaw);
        }

    }

    const janBodyTemplate = (rowData, column) => {
        if (rowData.key && rowData.key.includes('-')) {
            if (rowData.key.includes('-total')) {
                return (
                    <React.Fragment>
                        <span>{rowData.data[column]}</span>
                    </React.Fragment>
                )
            }
            return (
                <React.Fragment>
                    <TextField
                        value={rowData.data[column]}
                        onChange={(e) => handleTargetChange(e, rowData.data.groupId, rowData.data.id, column)}
                    />
                </React.Fragment>
            );
        }
        return null;
    };

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    const rowClassName = (node) => {
        if (node.key && node.key.includes('total')) {
            return { 'salesTargetChildRow childTotalRow': (node.key && node.key.includes('-')) };
        } else {
            return { 'salesTargetChildRow': (node.key && node.key.includes('-')) };
        }
    }

    const togglerTemplate = (node, options) => {
        if (!node) {
            return;
        }

        const expanded = options.expanded;
        const iconClassName = classNames('p-treetable-toggler-icon pi pi-fw', {
            'pi-plus-circle': !expanded,
            'pi-minus-circle': expanded
        });

        return (
            <button type="button" className="treetablebuttonStyle p-treetable-toggler p-link " style={{color:'#ffffff',backgroundColor:'#0065cb'}} tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row className="pageTitleRow">
                        <Col className="alignHeaders">
                            <CustomFontText className="pageName pageHeaderFont task">Sales Target</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable cust-tree-tbl sales-target-workbook">
                    <div className="card reportsTableVal">
                        <TreeTable
                            value={tableData}
                            //onRowClick={(event) => handleRowClick(event)}
                            selectionMode="single"
                            header={header}
                            footer={footer}
                            togglerTemplate={togglerTemplate}
                            rowClassName={rowClassName} >
                            <Column field="team" style={{ width: '15em' }} header="" expander></Column>
                            <Column field="jan" body={(rowData) => janBodyTemplate(rowData, 'jan')} header="Jan"></Column>
                            <Column field="feb" body={(rowData) => janBodyTemplate(rowData, 'feb')} header="Feb"></Column>
                            <Column field="mar" body={(rowData) => janBodyTemplate(rowData, 'mar')} header="Mar"></Column>
                            <Column field="apr" body={(rowData) => janBodyTemplate(rowData, 'apr')} header="Apr"></Column>
                            <Column field="may" body={(rowData) => janBodyTemplate(rowData, 'may')} header="May"></Column>
                            <Column field="jun" body={(rowData) => janBodyTemplate(rowData, 'jun')} header="Jun"></Column>
                            <Column field="jul" body={(rowData) => janBodyTemplate(rowData, 'jul')} header="Jul"></Column>
                            <Column field="aug" body={(rowData) => janBodyTemplate(rowData, 'aug')} header="Aug"></Column>
                            <Column field="sep" body={(rowData) => janBodyTemplate(rowData, 'sep')} header="Sep"></Column>
                            <Column field="oct" body={(rowData) => janBodyTemplate(rowData, 'oct')} header="Oct"></Column>
                            <Column field="nov" body={(rowData) => janBodyTemplate(rowData, 'nov')} header="Nov"></Column>
                            <Column field="dec" body={(rowData) => janBodyTemplate(rowData, 'dec')} header="Dec"></Column>
                        </TreeTable>
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
