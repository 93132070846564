import React, { FunctionComponent } from "react";
import { useEffect, useState } from "react";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { Col, Row } from "react-grid-system";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { useDispatch } from "react-redux";
import CustomTable from "components/table/datatable/CustomTable";
import { handleGetAppointments, handleGetDisplayboard } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import { convertUTCToLocalTime0nlyModified12Hr } from "utils/dateTimeFunctions";
/* import masterDropdownValues from "../../constants/masterDropdownValues";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive"; */
import moment from "moment";
import {getDisplayLocation} from "utils/localStorage/sheduleDisplayBoardLocalStorage";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

interface IProps {
   locationId?: any;
   date?: any;
   children: string;
}

// const credentialModuleName = "DealTracker";
export const DisplayBoardWorkbookPage: FunctionComponent<IProps> = ({
    // isOpen,
    locationId, date
  }) => {
    const dispatch = useDispatch();
    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    //const [appointmentId, setAppointmentId] = useState(0);
    const [appointmentData, setAppointmentData] = useState(null);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    //const appointments = useSelector((state: AppState) => state.dealTracker.appointments) as any;
    /* const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([0]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1); */
    //const [value, setValue] = useState(null);
    const propsLocation = getDisplayLocation();
    //const [statesFlag, setStatesFlag] = useState(null);


    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);







    useEffect(() => {

        dispatch<any>(handleGetDisplayboard({
            data: {
                locationId: propsLocation,
                date: moment(new Date()).format('YYYY-MM-DD')
            },
            callback: (res) => {
               
                console.log('res DISPLAY BOARD', res)
                if (res && res.length) {
                    handlePopulateAppointments(res)     
                }  
            }
          }))

        const interval = setInterval(() => {
          console.log('This will run every 15mins!');
          callApiForDisplayBoard();
        }, 900000); // 900000

        return () => clearInterval(interval);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //useEffect(() => {
    //    if (appointments && statesFlag) {
    //        if (appointments.length > 0) {
    //            // handlePopulateAppointments();

    //        }
    //        else {
    //            setAppointmentData([]);
    //        }
    //    }
    //}, [appointments,statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps

    /*  useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]); */

    //useEffect(() => {
    //    if (user && user.profileType && appointments && appointments.length > 0) {
    //        // handlePopulateAppointments();     
    //    }
    //    else {
    //        setAppointmentData([]);
    //    }
      
    //    if (user && user.roles) {
    //        if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
    //            // setPrimaryLocation(user.primaryLocationId);
    //            // setLocationFilter(user.primaryLocationId);
    //        }
    //    }
    //}, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const callApiForDisplayBoard = () => {
        dispatch<any>(handleGetDisplayboard({
            data: {
                locationId: propsLocation,
                date: moment(new Date()).format('YYYY-MM-DD')
            },
            callback: (res) => {
                console.log('res DISPLAY BOARD', res)
                if (res && res.length) {
                    handlePopulateAppointments(res)     
                }  
            }
        }))
    }
    const handlePopulateAppointments = (appointments) => {
        var startdate = moment();
        startdate = startdate.subtract(15, "minutes");
        /* let checkMinAfter2 = startdate.diff(schedulestart, 'minutes');
        console.log('checkMinAfter22', checkMinAfter2) */

        //setAppointmentData([]);
        let scheduleList = [];
        for (var i in appointments) {
            let scheduleListItems = appointments[i].schedules;
            let customer = appointments[i].customer;

            
            // let deal = appointments[i].deal;
            // let sales = appointments[i].assignedTo;
            let obj: any = null;
            
            for (var s in scheduleListItems) {
                console.log('scheduleList', s)
                var scheduleends = moment(moment.utc(scheduleListItems[0].endsAt).format('YYYY-MM-DD HH:mm:ss'));
                let checkMinAfter = scheduleends.diff(startdate, 'minutes');

                if (checkMinAfter >= 0) {
                    if (obj === null) {
                        obj = {
                            'time': '',
                            'customer': customer ? (customer.customerType === 1 ? customer.firstName + ' ' + customer.lastName : customer.businessName):'',
                            'salesPerson': '',
                            // 'vehicle': (deal && deal.vehicle) ? deal.vehicle.year + ' ' + deal.vehicle.make: '',// + ' ' + deal.vehicle.model : '',
                            'deliveryCoordinator': ''
                            //'dealNumber':deal ? deal.id : ''
                        }
                    }
                    
                    if (scheduleListItems[s].type === 1) {
                        obj['time'] = convertUTCToLocalTime0nlyModified12Hr(scheduleListItems[s].startsAt);
                        obj['salesPerson'] = scheduleListItems[s].type === 1 ? (scheduleListItems[s].assignedTo ? scheduleListItems[s].assignedTo.firstName + ' ' + scheduleListItems[s].assignedTo.lastName : '') : '';
                    } else if (scheduleListItems[s].type === 2) {
                        obj['deliveryCoordinator'] = scheduleListItems[s].type === 2 ? (scheduleListItems[s].assignedTo ? scheduleListItems[s].assignedTo.firstName + ' ' + scheduleListItems[s].assignedTo.lastName : '') : '';
                    }
                }
            }
            
            if (obj) {
                scheduleList.push(obj);
            }
        }
        setAppointmentData(scheduleList);
        console.log('scheduleList', scheduleList);
       // handleFilters(locationFilter, logDateFromFilter, logDateToFilter);
    };

        useEffect(() => {
        // setSortOrder(1);
        // setSortField('time');
        // let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        // setLogDateFromFilter(new Date(todaydate));
        
        //setStatesFlag(null);
        /* if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        } */
        dispatch<any>(handleGetAppointments())
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //Datatable Templates
	//const header = (
	//	<>  
 //           <div>
 //               {/* <Row>
 //                   <Col>
 //                       <div className="locationDropPosition">
 //                           <label className="lbl-text">Location</label>
 //                           <Dropdown
 //                               id="locationId"
 //                               placeholder=""
 //                               value={locationFilter}
 //                               options={cityDealerList}
 //                               onChange={onLocationChange}
 //                               optionLabel="legalName"
 //                               optionValue="id"
 //                               appendTo="self"
 //                               className="custom-p-dropdown"
 //                               filter={false}
 //                               resetFilterOnHide={true}
 //                           />
 //                       </div>
 //                   </Col>

 //               </Row> */}
 //               {/* <Row style={{ width: "auto" }} >
 //                   <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
 //                       <PrimaryButton style={{ float: "right" }}
 //                           onClick={handleResetWorkbook}
 //                       >
 //                           Reset
 //                           </PrimaryButton>
 //                   </Col>
 //               </Row> */}
 //           </div>
	//	</>
	//);


    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Time</span>
                {rowData.time}

            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Manager</span>
                {rowData.salesPerson}

            </React.Fragment>
        );
    };

    const customerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer</span>
                {rowData.customer}

            </React.Fragment>
        );
    };

    /* const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}

            </React.Fragment>
        );
    }; */

    /* const custNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle</span>
                {rowData.vehicle}

            </React.Fragment>
        );
    }; */

    const deliveryCoordinatorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Delivery Coordinator</span>
                {rowData.deliveryCoordinator}

            </React.Fragment>
        );
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
           // target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role || isKarlProfile(user.profileType)) {
                //handleEditAppointment(event.data.deal.id);
                // navigate(`/${event.data.deal.id}/dealtracker`, { tabIndex: 1 });
               /*  navigate({
                    pathname: `/${event.data.deal.id}/dealtracker`,
                    state: {
                      searchInput: glFilter,
                      pathfrom: '/appointments',
                      tabIndex: 1,
                      productId:`${event.data.id}`,
                      workbookName:'appointments',
                      // sortOrder,
                      // sortField
                    }
                }); */
            //}
        }
    };

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);
    
    /* useEffect(() => {        
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
        }
        setStatesFlag(true);
        
    }, [location]); */

    const table_columns = {
        "time": "Time",
        "customer":"Customer",
        "salesPerson":"Finance Manager",
        // "dealNumber": "Deal Number",
        // "vehicle": "Vehicle",
        "deliveryCoordinator": "Delivery Coordinator"
    }

      /*  const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    } */

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                 <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                 >
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Finance Display Board</CustomFontText>
                        </Col>
                    </Row>
                </Stack> 
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable" > {/* locations */}
                    <div className="card workbookLocationPlaceHolder">
                        <CustomTable
                            column_list={table_columns}
                            data={appointmentData}
                            //header={header}
                            // sortField={sortField}
                            // sortOrder={sortOrder}
                            // onSort={handleColumnSort}
                            sortingNotReq={true}
                            rows={25}
                            rowsPerPageOptions={[25, 50, 100]}
                            customTemplates={{
                                "time": dateBodyTemplate,
                                "customer": customerBodyTemplate,
                                "salesPerson": salesPersonBodyTemplate,
                                //"dealNumber": dealNumberBodyTemplate,
                                // "vehicle": custNameBodyTemplate,
                                "deliveryCoordinator": deliveryCoordinatorBodyTemplate
                            }}
                            emptyMessage="No Schedule found."
                            onRowClick={(event) => handleRowClick(event)}
                        // globalFilter={glFilter}
                        />
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
