import {
    Link,
    TextField
} from "@fluentui/react";
import React, { useEffect } from "react";
import * as Yup from "yup";
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row } from "react-grid-system";
//import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import AccordionActions from '@material-ui/core/AccordionActions';
//import Typography from '@material-ui/core/Typography';
//import Divider from '@material-ui/core/Divider';
import { useFormik } from "formik";
//import { OwnershipInfo } from "components/tooltip/OwnershipInfo";
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import Box from "@mui/material/Box";
import { InputMask } from 'primereact/inputmask';
import { validTenDigitPhone } from "utils/helperFunctions";

const iconClass = mergeStyles({
    fontSize: 15,
    height: 10,
    width: 10,
    margin: '0 25px',
});
// const useStyles = makeStyles((theme: ThemeStyle) =>

//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme?.palette?.background.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography?.pxToRem(15),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography?.pxToRem(15),
//             color: theme.palette?.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 20,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette?.divider}`,
//             padding: theme?.spacing?.(1, 2),
//         },
//         link: {
//             color: theme.palette?.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//     }),
// );

const AdditionalOwnerShip = ({ owner, onClickRemove, index, ownerForm }) => {

    let initialOwnerForm = {
        firstName: "",
        lastName: "",
        // role: "",
        ownershipPercentage: "",
        phoneNumber: "",
        email: ""
    };
    const hasNumber = (str) => {
        return /\d/.test(str);
    }
    Yup.addMethod(Yup.string, 'digitsOnly', function () {
        return this.matches(/^\d+$/, 'Numerals only allowed')
    });

    // const  classes:any  = useStyles();

    const formik = useFormik({
        initialValues: ownerForm ? (ownerForm.values ? ownerForm.values : ownerForm) : initialOwnerForm,
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            ownershipPercentage: Yup.string().digitsOnly().test("range", "Value between 1 to 100", (val) => {
                if (val === undefined)
                    return true;
                return parseInt(val) > 0 && parseInt(val) <= 100
            }).required("Required").typeError("Value must be a number"),
            email: Yup.string().required("Required").email("Enter valid email"),
            phoneNumber: Yup.string()
                .test("required", "Required", (val) => {
                    if (val === undefined)
                        return true;
                    return val.replace(/[^0-9]/g, '').length > 0
                })
                .test("len", "Invalid phone number", (val) => {
                    if (val === undefined)
                        return true;
                    return validTenDigitPhone(val)
                }).required("Required")
        }),
        onSubmit: () => {
        },
        validateOnMount: true
    });

    const handleFirstNameChange = (e) => {
        if (!e.target.value || !hasNumber(e.target.value)) {
            formik.setValues(
                {
                    ...formik.values,
                    'firstName': e.target.value
                }
            );
        }

    };

    const handleLastNameChange = (e) => {
        if (!e.target.value || !hasNumber(e.target.value)) {
            formik.setValues(
                {
                    ...formik.values,
                    'lastName': e.target.value
                }
            );
        }

    };

    useEffect(() => {

        if (ownerForm && (!ownerForm.isValid || !ownerForm.dirty)) {
            formik.handleSubmit()
        }
        owner(formik, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        owner(formik, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, formik.errors, formik.touched]);

    const handleMaskInputOwner = (e) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.target.value
            }
        );
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                bgcolor: 'background.paper',
                display: 'block',
            }}
        >
            <div>
                <div className="tabRowSection">
                    <Row>
                        <Col>
                            <h3 className="sectionTitle">Owner {index}</h3>
                            <Link
                                onClick={() => { onClickRemove(index) }}
                                underline={false}
                                className="deleteLink"
                            ><FontIcon aria-label="Delete" iconName="Delete" className={iconClass} /> Delete Owner</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12} md={6} lg={6}>
                            <TextField
                                id="firstName"
                                name="firstName"
                                label="First Name"
                                placeholder=""
                                required={true}
                                value={formik.values.firstName}
                                onChange={(e) => handleFirstNameChange(e)}
                                autoComplete="none"
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.firstName &&
                                    formik.errors.firstName &&
                                    formik.errors.firstName.toString()
                                }
                            />
                        </Col>
                        <Col sm={6} xs={12} md={6} lg={6}>
                            <TextField
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                placeholder=""
                                required={true}
                                value={formik.values.lastName}
                                onChange={(e) => handleLastNameChange(e)}
                                autoComplete="none"
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched.lastName &&
                                    formik.errors.lastName &&
                                    formik.errors.lastName.toString()
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12} md={6} lg={6}>
                            <div className="ms-TextField root-155">
                                <div className="ms-TextField-wrapper">
                                    <label className="lbl-text required-Label">Phone</label>
                                    <div className={formik.touched.phoneNumber &&
                                        formik.errors.phoneNumber ? "mask-FieldGroup ms-TextField-fieldGroup invalid-input" : "ms-TextField-fieldGroup mask-FieldGroup"}>
                                        <InputMask
                                            autoClear={false}
                                            required={true}
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            mask="(999)999-9999"
                                            placeholder=""
                                            value={formik.values.phoneNumber}
                                            className="mask-TextField"
                                            onChange={(e) => handleMaskInputOwner(e)}
                                            onBlur={formik.handleBlur}>
                                        </InputMask>
                                    </div>
                                    <span className="error-message">{
                                        formik.touched.phoneNumber &&
                                        formik.errors.phoneNumber &&
                                        formik.errors.phoneNumber.toString()
                                    }</span>
                                </div>
                            </div>
                        </Col>

                        <Col sm={6} xs={12} md={6} lg={6}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                required={true}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="none"
                                errorMessage={
                                    formik.touched.email &&
                                    formik.errors.email &&
                                    formik.errors.email.toString()
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12} md={6} lg={6}>
                            <TextField
                                id="ownershipPercentage"
                                name="ownershipPercentage"
                                label="Ownership %"
                                required={true}
                                autoComplete="none"
                                value={formik.values.ownershipPercentage}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMessage={
                                    formik.touched && formik.touched.ownershipPercentage &&
                                    formik.errors && formik.errors.ownershipPercentage &&
                                    formik.errors.ownershipPercentage.toString()
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.column}>
                        <Typography component={'h3'} className={classes.heading}>Additional Ownership {index}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div>
                        <div className="tabRowSection">
                            <Row>
                                <Col>
                                    <h3>Name</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12} md={6} lg={3}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        placeholder=""
                                        required={true}
                                        value={formik.values.firstName}
                                        onChange={(e) => handleFirstNameChange(e)}
                                        autoComplete="none"
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.firstName &&
                                            formik.errors.firstName &&
                                            formik.errors.firstName.toString()
                                        }
                                    />
                                </Col>
                                <Col sm={6} xs={12} md={6} lg={3}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        placeholder=""
                                        required={true}
                                        value={formik.values.lastName}
                                        onChange={(e) => handleLastNameChange(e)}
                                        autoComplete="none"
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.lastName &&
                                            formik.errors.lastName &&
                                            formik.errors.lastName.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="tabRowSection">
                            <Row>
                                <Col>
                                    <h3>Contact Information</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12} md={6} lg={3}>
                                    <div className="ms-TextField root-155">
                                        <div className="ms-TextField-wrapper">
                                            <label className="lbl-text required-Label">Phone</label>
                                            <div className={formik.touched.phoneNumber &&
                                                formik.errors.phoneNumber ? "mask-FieldGroup invalid-input" : "mask-FieldGroup"}>
                                                <InputMask
                                                    autoClear={false}
                                                    required={true}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    mask="(999)999-9999"
                                                    placeholder=""
                                                    value={formik.values.phoneNumber}
                                                    className="mask-TextField"
                                                    onChange={(e) => handleMaskInputOwner(e)}
                                                    onBlur={formik.handleBlur}>
                                                </InputMask>
                                            </div>
                                            <span className="error-message">{
                                                formik.touched.phoneNumber &&
                                                formik.errors.phoneNumber &&
                                                formik.errors.phoneNumber.toString()
                                            }</span>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6} xs={12} md={6} lg={3}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email (we don't share this)"
                                        required={true}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete="none"
                                        errorMessage={
                                            formik.touched.email &&
                                            formik.errors.email &&
                                            formik.errors.email.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="tabRowSection">
                            <Row>
                                <Col>
                                    <OwnershipInfo />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} xs={12} md={6} lg={3}>
                                    <TextField
                                        id="ownershipPercentage"
                                        name="ownershipPercentage"
                                        label="Percent Ownership"
                                        required={true}
                                        autoComplete="none"
                                        value={formik.values.ownershipPercentage}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched && formik.touched.ownershipPercentage &&
                                            formik.errors && formik.errors.ownershipPercentage &&
                                            formik.errors.ownershipPercentage.toString()
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>

                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <PrimaryButton
                        onClick={() => { onClickRemove(index) }}
                        style={{ float: "right" }}
                        // onClick={nextStep}
                        text="Delete"
                        iconProps={{ iconName: "Delete" }}
                    />
                </AccordionActions>
            </Accordion>*/}
        </Box>
    )
};

export default AdditionalOwnerShip;