import * as actions from "./inventory.types";
import { SET_SORT_INVENTORY } from "./inventory.types";
import { IPagination } from "types/paginationTypes";
import {
  ICreateInventory,
  IInventory,
  IInventoryFilterResponse,
  IInventoryFilters,
  IInventoryResponse,
  IUpdateInventory,
} from "types/inventoryTypes";
import { IFormError } from "types/errorTypes";
import { trimTypes } from "constants/constants";


export const setSortInventory = (sort: string): actions.SetSortInventory => {
  return {
    type: SET_SORT_INVENTORY,
    payload: sort,
  };
};
export const handleGetInventoryList = (
  request: IPagination
): actions.HandleGetInventory => {
  return {
    type: actions.HANDLE_GET_INVENTORY,
    payload: request,
  };
};

export const handleSaveFilterInventory = ( {
  page,
  filterId,
  filterName,
  callback
}): actions.HandleSaveFilterInventory => {
  return {
    type: actions.HANDLE_SAVE_INVENTORY_FILTER,
    payload: { page, filterId, filterName, callback },
  };
};

export const handleGetInventoryFilterList = (
  request: any
): actions.HandleGetInventoryFilterList => {
  return {
    type: actions.HANDLE_GET_INVENTORY_FILTER_LIST,
    payload: request,
  };
};

export const handleGetShopWorkBookList = (
  request: any
): actions.handleGetShopWorkBookList => {
  return {
    type: actions.HANDLE_GET_SHOP_WORKBOOK_LIST,
    payload: request,
  };
};

export const handleGetPurchaseBookList = (
): actions.handleGetPurchaseWorkBookList => {
    return {
        type: actions.HANDLE_GET_PURCHASE_WORKBOOK_LIST,
    };
};

export const handleGetSoldWorkBookList = (
): actions.handleGetSoldWorkBookList => {
    return {
        type: actions.HANDLE_GET_SOLD_WORKBOOK_LIST
    };
};

export const handleGetTrimUsingVin = ({
    vin,
    trimType = trimTypes.nonKarl,
    callback
}): actions.handleGetTrimUsingVin => {
  return {
    type: actions.HANDLE_GET_TRIM_VIN,
    payload: {
        vin,
        trimType,
        callback
    },
  };
};

export const handlePricingCategoriesID = (callBack): actions.handlePricingCategoriesID => {
  return {
    type: actions.HANDLE_Pricing_Categories_ID,
    payload: callBack
  }
};

export const handleGetPricingUsingID = (vehID): actions.handleGetPricingUsingID => {
  return {
    type: actions.HANDLE_GET_Pricing_ID,
    payload: vehID
  }
};

export const handleSavePricingUsingID = (vehInfo): actions.handleSavePricingUsingID => {
  return {
    type: actions.HANDLE_SAVE_Pricing_ID,
    payload: vehInfo
  }
};

export const handleGetQValuePlotUsingID = (vehID): actions.handleGetQValuePlotUsingID => {
  return {
    type: actions.HANDLE_GET_QVALUE_PLOT_ID,
    payload: vehID
  }
};

export const handleSaveQValueNotesUsingID = (vehDetails): actions.handleSaveQValueNotesUsingID => {
  return {
    type: actions.HANDLE_SAVE_QVALUE_NOTES_ID,
    payload: vehDetails
  }
};

export const handleGetQValueNotesUsingID = (vehID): actions.handleGetQValueNotesUsingID => {
  return {
    type: actions.HANDLE_GET_QVALUE_NOTES_ID,
    payload: vehID
  }
};

export const handleGetVehicleUsingID = (vehID): actions.handleGetVehicleUsingID => {
  return {
    type: actions.HANDLE_GET_Vehicle_ID,
    payload: vehID
  }
};

export const handleSaveSourceWorkBook = (vehdetails: any): actions.handleSaveSourceWorkBook => {
  return {
    type: actions.HANDLE_SAVE_SOURCE_WORKBOOK,
      payload: vehdetails
  }
};

export const handleGetSourceWorkBook = (vehID: any): actions.handleGetSourceWorkBook => {
  return {
    type: actions.HANDLE_GET_SOURCE_WORKBOOK,
    payload: vehID
  }
};

export const handleSaveVehicleExpenses = (expenses: any): actions.handleSaveVehicleExpenses => {
    return {
        type: actions.HANDLE_SAVE_VEHICLE_EXPENSES,
        payload: expenses
    }
};

export const handleGetVehicleExpenses = ({
    id,
    callback
}): actions.handleGetVehicleExpenses => {
    return {
        type: actions.HANDLE_GET_VEHICLE_EXPENSES,
        payload: {
            id,
            callback
        }
    }
};

export const handleGetLiveRetailReports = ({
  vehID,
  callback
}): actions.handleGetLiveRetailReports => {
  return {
    type: actions.HANDLE_GET_LIVE_RETAIL_REPORTS,
    payload: {
      vehID,
      callback
    }
  }
};

export const handleGetFilterLiveRetailReports = ({
  vehID,
  callback
}): actions.handleGetFilterLiveRetailReports => {
  return {
    type: actions.HANDLE_GET_FILTER_LIVE_RETAIL_REPORTS,
    payload: {
      vehID,
      callback
    }
  }
};

export const handlePutLiveRetailReports = ({
  vehInfo,
  callback
}): actions.handlePutLiveRetailReports => {
  return {
    type: actions.HANDLE_PUT_LIVE_RETAIL_REPORTS,
    payload: {
      vehInfo,
      callback
    }
  }
};

export const handleVehicleLiveRetailFilters = ({id,
  formData,
  callback
}): actions.HandleVehicleLiveRetailFilters => {
  return {
    type: actions.HANDLE_VEHICLE_LIVE_RETAIL_FILTERS,
    payload: {
        id,
      formData,
      callback
    }
  }
};

export const handleGetQValueDropdownMaster = (vehID: any): actions.handleGetQValueDropdownMaster => {
    return {
        type: actions.HANDLE_GET_QVALUE_DROPDOWN_MASTER,
        payload: vehID
    }
};

export const handleGetQValuations = (vehID: any): actions.handleGetQValuations => {
    return {
        type: actions.HANDLE_GET_QVALUATIONS,
        payload: vehID
    }
};

export const handleSaveQValues = (qValues: any): actions.handleSaveQValues => {
    return {
        type: actions.HANDLE_SAVE_QVALUES,
        payload: qValues
    }
};

export const handleGetTrimUsingYmm = ({
    ymm,
    trimType = trimTypes.nonKarl,
    callback
}): actions.handleGetTrimUsingYmm => {
  return {
    type: actions.HANDLE_GET_TRIM_YMM,
    payload: {
        ymm,
        trimType,
        callback
    },
  };
};

export const handleGetVehDetails = ({
    vehDet,
    trimType = trimTypes.nonKarl,
    callback
}): actions.handleGetVehDetails => {
  return {
    type: actions.HANDLE_GET_VEH_DETAILS,
    payload: {
        vehDet,
        trimType,
        callback
    },
  };
};

export const handleVehicleStatus = (id: string | number,status: string, callback): actions.HandleVehicleStatus => {
    return {
        type: actions.HANDLE_VEHICLE_STATUS,
        payload: { id, status, callback },
    };
};

export const handlePurchaseVehicle = (purchaseVehicleInfo: any, callback): actions.HandlePurchaseVehicle => {
    return {
        type: actions.HANDLE_PURCHASE_VEHICLE,
        payload: { purchaseVehicleInfo, callback },
    };
};

export const handleSellVehicle = (sellVehicleInfo: any, callback): actions.HandleSellVehicle => {
    return {
        type: actions.HANDLE_SELL_VEHICLE,
        payload: { sellVehicleInfo, callback },
    };
};

export const handlePurchaseVehicleUndo = ({id, callback}): actions.HandlePurchaseVehicleUndo => {

    return {
        type: actions.HANDLE_PURCHASE_VEHICLE_UNDO,
        payload: { id, callback },
    };
};

export const handleSellVehicleUndo = ({id, callback}): actions.HandleSellVehicleUndo => {
    return {
        type: actions.HANDLE_SELL_VEHICLE_UNDO,
        payload: { id, callback },
    };
};

export const handlePutVehDetails = ({
  id,
  vehDet,
  callback
}): actions.handlePutVehDetails => {
  return {
    type: actions.HANDLE_PUT_VEH_DETAILS,
    payload: {
      id,
      vehDet,
      callback
    },
  };
};

export const handleGetVehDetailsSuccess = (vehDet): actions.handleGetVehDetailsSuccess => {
  return {
    type: actions.HANDLE_GET_VEH_DETAILS_SUCCESS,
    payload: vehDet,
  };
};

export const handleGetVehSellerDetails = ({
  callback
}): actions.handleGetVehSellerDetails => {
  return {
    type: actions.HANDLE_GET_VEH_SELLER_DETAILS,
    payload: {
      callback
    },
  };
};

export const handleGetAdjusterList = ({
  id, reportType, callback
}): actions.handleGetAdjusterList => {
  return {
    type: actions.HANDLE_GET_ADJUSTER_LIST,
    payload: {
      id, reportType, callback
    },
  };
};

export const handlePutAdjusterList = ({
  id, reportType, data, callback
}): actions.handlePutAdjusterList => {
  return {
    type: actions.HANDLE_PUT_ADJUSTER_LIST,
    payload: {
      id, reportType, data, callback
    },
  };
};

export const handleGetSelectedInventoryFilter = (
  request: any
): actions.HandleGetSelectedFilterData => {
  return {
    type: actions.HANDLE_GET_SELECTED_FILTER_DATA,
    payload: request,
  };
};

export const handleDeleteFilterInventory = (
  request: any
): actions.HandleDeleteFilterInventory => {
  return {
    type: actions.HANDLE_DELETE_INVENTORY_FILTER,
    payload: request,
  };
};

export const pendingGetInventoryList = (): actions.GetInventoryPending => {
  return {
    type: actions.GET_INVENTORY_PENDING,
  };
};

export const resolvedGetInventoryList = (
  inventory: IInventoryResponse
): actions.GetInventoryResolved => {
  return {
    type: actions.GET_INVENTORY_RESOLVED,
    payload: inventory,
  };
};

export const resolvedGetInventoryFilterList = (
  inventory: IInventoryFilterResponse
): actions.GetInventoryFilterResolved => {
  return {
    type: actions.GET_INVENTORY_FILTER_RESOLVED,
    payload: inventory,
  };
};

export const resolvedShopWorkbookList = (
  inventory: any
): actions.GetShopWorkBookResolved => {
  return {
    type: actions.GET_SHOP_WORKBOOK_RESOLVED,
    payload: inventory,
  };
};

export const resolvedPurchaseWorkbookList = (
    purchaseInventory: any
): actions.GetPurchaseWorkBookResolved => {
    return {
        type: actions.GET_PURCHASE_WORKBOOK_RESOLVED,
        payload: purchaseInventory,
    };
};

export const resolvedSoldWorkbookList = (
    soldInventory: any
): actions.GetSoldWorkBookResolved => {
    return {
        type: actions.GET_SOLD_WORKBOOK_RESOLVED,
        payload: soldInventory,
    };
};

export const resolvedGetTrimValue = (
  inventory: any
): actions.GetTrimResolved => {
  return {
    type: actions.GET_TRIM_RESOLVED,
    payload: inventory,
  };
};

export const rejectedGetInventoryList = (
  errors: IFormError[]
): actions.GetInventoryRejected => {
  return {
    type: actions.GET_INVENTORY_REJECTED,
    payload: errors,
  };
};

export const rejectedSaveInventoryList = (
  errors: IFormError[]
): actions.SaveInventoryRejected => {
  return {
    type: actions.SAVE_INVENTORY_REJECTED,
    payload: errors,
  };
};

export const handleCreateInventory = (
  inventory: ICreateInventory,
  callback
): actions.HandleCreateInventory => {
  return {
    type: actions.HANDLE_CREATE_INVENTORY,
    payload: { inventory, callback },
  };
};

export const handleUploadFileInventory = ({
  formData,
  location,
  callback }): actions.HandleUploadInventories => {
  return {
    type: actions.HANDLE_UPLOAD_INVENTORY,
    payload: { formData, location, callback }
  }
}

export const pendingCreateInventory = (): actions.CreateInventoryPending => {
  return {
    type: actions.CREATE_INVENTORY_PENDING,
  };
};

export const resolvedCreateInventory = (
  inventory: IInventory
): actions.CreateInventoryResolved => {
  return {
    type: actions.CREATE_INVENTORY_RESOLVED,
    payload: inventory,
  };
};

export const rejectedCreateInventory = (
  error: object[]
): actions.CreateInventoryRejected => {
  return {
    type: actions.CREATE_INVENTORY_REJECTED,
    payload: error,
  };
};
/** START EDIT */

export const handleEditInventory = (
  arrayOfInventories: IUpdateInventory[],
  additionalData: {
    locationId: number | string;
    inventoryId: number | string;
    filesToRemove: string[];
    videoFilesToRemove: boolean;
  },
  callback?: () => void
): actions.HandleEditInventory => {
  return {
    type: actions.HANDLE_EDIT_INVENTORY,
    payload: { arrayOfInventories, additionalData, callback },
  };
};

export const handleExportInventory = (
  ids: any
): actions.HandleExportInventory => {
  return {
    type: actions.HANDLE_EXPORT_INVENTORY,
    payload: ids ,
  };
};

export const pendingEditInventory = (): actions.EditInventoryPending => {
  return {
    type: actions.EDIT_INVENTORY_PENDING,
  };
};

export const resolvedEditInventory = (): actions.EditInventoryResolved => {
  return {
    type: actions.EDIT_INVENTORY_RESOLVED,
  };
};

export const rejectedEditInventory = (
  errors: object
): actions.EditInventoryRejected => {
  return {
    type: actions.EDIT_INVENTORY_REJECTED,
    payload: errors,
  };
};
/** END EDIT */

/** UPDATE */

export const handleUpdateInventory = (
  items: IInventory[]
): actions.HandleUpdateInventory => {
  return {
    type: actions.HANDLE_UPDATE_INVENTORY,
    payload: items,
  };
};

export const pendingUpdateInventory = (): actions.UpdateInventoryPending => {
  return {
    type: actions.UPDATE_INVENTORY_PENDING,
  };
};

export const resolvedUpdateInventory = (): actions.UpdateInventoryResolved => {
  return {
    type: actions.UPDATE_INVENTORY_RESOLVED,
  };
};

export const rejectedUpdateInventory = (
  errors: object
): actions.UpdateInventoryRejected => {
  return {
    type: actions.UPDATE_INVENTORY_REJECTED,
    payload: errors,
  };
};

/** VIEW*/

export const handleViewInventory = (
  id: number | string
): actions.HandleViewInventory => {
  return {
    type: actions.HANDLE_VIEW_INVENTORY,
    payload: id,
  };
};

export const handlePDFInfoInventory = (
  id: number | string
): actions.HandlePDFInfoInventory => {
  return {
    type: actions.HANDLE_PDF_INFO_INVENTORY,
    payload: id,
  };
};

export const handlePDFGuideInventory = (
  id: number | string
): actions.HandlePDFGuideInventory => {
  return {
    type: actions.HANDLE_PDF_GUIDE_INVENTORY,
    payload: id,
  };
};

export const pendingViewInventory = (): actions.ViewInventoryPending => {
  return {
    type: actions.VIEW_INVENTORY_PENDING,
  };
};

export const resolvedViewInventory = (
  item: IInventory
): actions.ViewInventoryResolved => {
  return {
    type: actions.VIEW_INVENTORY_RESOLVED,
    payload: item,
  };
};

export const rejectedViewInventory = (
  errors: object
): actions.ViewInventoryRejected => {
  return {
    type: actions.VIEW_INVENTORY_REJECTED,
    payload: errors,
  };
};

export const rejectedPDFInfoInventory = (
  errors: object
): actions.PdfInfoInventoryRejected => {
  return {
    type: actions.PDF_INFO_INVENTORY_REJECTED,
    payload: errors,
  };
};

export const rejectedPDFGuideInventory = (
  errors: object
): actions.PdfGuideInventoryRejected => {
  return {
    type: actions.PDF_GUIDE_INVENTORY_REJECTED,
    payload: errors,
  };
};

/** DELETE */

export const handleDeleteInventory = ({
  ids,
  callback,
}: {
  ids: number[];
  callback?: () => void;
}): actions.HandleDeleteInventory => {
  
  return {
    type: actions.HANDLE_DELETE_INVENTORY,
    payload: { ids, callback },
  };
};

export const pendingDeleteInventory = (): actions.DeleteInventoryPending => {
  return {
    type: actions.DELETE_INVENTORY_PENDING,
  };
};

export const resolvedDeleteInventory = (): actions.DeleteInventoryResolved => {
  return {
    type: actions.DELETE_INVENTORY_RESOLVED,
  };
};

export const rejectedDeleteInventory = (
  errors: object
): actions.DeleteInventoryRejected => {
  return {
    type: actions.DELETE_INVENTORY_REJECTED,
    payload: errors,
  };
};

/** ADD INVENTORY PHOTO */

export const handleAddPhotoInventory = (
  inventoryId: string | number,
  fileData: any
): actions.HandleAddInventoryPhoto => {
  return {
    type: actions.HANDLE_ADD_INVENTORY_PHOTO,
    payload: { inventoryId, fileData },
  };
};

export const pendingAddPhotoInventory = (): actions.AddInventoryPhotoPending => {
  return {
    type: actions.ADD_INVENTORY_PHOTO_PENDING,
  };
};

export const resolvedAddPhotoInventory = (
  data: any
): actions.AddInventoryPhotoResolved => {
  return {
    type: actions.ADD_INVENTORY_PHOTO_RESOLVED,
    payload: data,
  };
};

export const rejectedAddPhotoInventory = (
  errors: object
): actions.AddInventoryPhotoRejected => {
  return {
    type: actions.ADD_INVENTORY_PHOTO_REJECTED,
    payload: errors,
  };
};

/** DELETE INVENTORY PHOTO */

export const handleDeletePhotoInventory = (
  inventoryId: string | number,
  photo: string
): actions.HandleDeleteInventoryPhoto => {
  return {
    type: actions.HANDLE_DELETE_INVENTORY_PHOTO,
    payload: { inventoryId, photo },
  };
};

export const pendingDeletePhotoInventory = (): actions.DeleteInventoryPhotoPending => {
  return {
    type: actions.DELETE_INVENTORY_PHOTO_PENDING,
  };
};

export const resolvedDeletePhotoInventory = (
  data: any
): actions.DeleteInventoryPhotoResolved => {
  return {
    type: actions.DELETE_INVENTORY_PHOTO_RESOLVED,
    payload: data,
  };
};

export const rejectedDeletePhotoInventory = (
  errors: object
): actions.DeleteInventoryPhotoRejected => {
  return {
    type: actions.DELETE_INVENTORY_PHOTO_REJECTED,
    payload: errors,
  };
};

/** GET DATA FROM VIN */

export const handleGetFromVin = ({
  vin,
  callback,
}): actions.HandleGetFromVin => {
  return {
    type: actions.HANDLE_GET_FROM_VIN,
    payload: { vin, callback },
  };
};

export const pendingGetFromVin = (): actions.GetFromVinPending => {
  return {
    type: actions.GET_FROM_VIN_PENDING,
  };
};

export const resolvedGetFromVin = (): actions.GetFromVinResolved => {
  return {
    type: actions.GET_FROM_VIN_RESOLVED,
  };
};

export const rejectedGetFromVin = (
  errors: object
): actions.GetFromVinRejected => {
  return {
    type: actions.GET_FROM_VIN_REJECTED,
    payload: errors,
  };
};

// GET VALUATION INFORMATION
export const handleGetValuationInformation = ({
  inventoryId,
  source,
}): actions.HandleGetValuationInformation => {
  return {
    type: actions.HANDLE_GET_VALUATION_INFORMATION,
    payload: { inventoryId, source },
  };
};

export const pendingGetValuationInformation = (
  source
): actions.GetValuationInformationPending => {
  return {
    type: actions.GET_VALUATION_INFORMATION_PENDING,
    payload: source,
  };
};

export const resolvedGetValuationInformationn = (
  payload
): actions.GetValuationInformationResolved => {
  return {
    type: actions.GET_VALUATION_INFORMATION_RESOLVED,
    payload: payload,
  };
};

export const rejectedGetValuationInformation = (
  errors: object
): actions.GetValuationInformationRejected => {
  return {
    type: actions.GET_VALUATION_INFORMATION_REJECTED,
    payload: errors,
  };
};

export const cleanValuationInformation = (): actions.CleanValuationInformation => {
  return {
    type: actions.CLEAN_VALUATION_INFORMATION
  }
}


// FILTERS
export const cleanInventoryFilters = (): actions.CleanInventoryFilters => {
  return {
    type: actions.CLEAN_INVENTORY_FILTERS,
  };
};

// SAVE FILTERS
export const saveInventoryFilters = (
  filters: IInventoryFilters
): actions.SaveInventoryFilters => {
  return {
    type: actions.SAVE_INVENTORY_FILTERS,
    payload: filters,
  };
};

// DELETE FILTERS
export const deleteInventoryFilters = (
  filterName: string
): actions.DeleteInventoryFilters => {
  return {
    type: actions.DELETE_INVENTORY_FILTERS,
    payload: filterName,
  };
};

export const setInventoryFilters = (
  filters: IInventoryFilters
): actions.SetInventoryFilters => {

  console.log(filters)
  

  return {
    type: actions.SET_INVENTORY_FILTERS,
    payload: filters,
  };
};

export const pendingGetVehicles = (): actions.GetVehiclesPending => {
    return {
        type: actions.GET_VEHICLES_PENDING,
    };
};

export const handleGetVehicles = (): actions.HandleGetVehicles => {
    return {
        type: actions.HANDLE_GET_VEHICLES
    };
};

export const resolvedGetVehicles = (
    inventory: any
): actions.GetVehiclesResolved => {
    return {
        type: actions.GET_VEHICLES_RESOLVED,
        payload: inventory,
    };
};

export const rejectedGetVehicles = (
    error: any
): actions.GetVehiclesRejected => {
    return {
        type: actions.GET_VEHICLES_REJECTED,
        payload: error,
    };
};


/** MANAGE FAVORITE VEHICLE */
export const pendingManageFavoriteVehicle = (): actions.ManageFavoriteVehiclePending => {
    return {
        type: actions.MANAGE_FAVORITE_VEHICLE_PENDING,
    };
};

export const handleManageFavoriteVehicle = (
  inventoryId: any,
  isFavorite: boolean,
  status: string,
  callback): actions.HandleManageFavoriteVehicle => {
    return {
        type: actions.HANDLE_MANAGE_FAVORITE_VEHICLE,
        payload: { inventoryId, isFavorite, status, callback}
    };
};

export const rejectedManageFavoriteVehicle = (
    error: any
): actions.ManageFavoriteVehicleRejected => {
    return {
        type: actions.MANAGE_FAVORITE_VEHICLE_REJECTED,
        payload: error,
    };
};




/** DELETE_Expense */

export const handleDeleteExpense = (id: number, vehiclePricing: number, callback): actions.HandleDeleteExpense => {
  return {
    type: actions.HANDLE_DELETE_EXPENSE,
    payload: {id, vehiclePricing, callback},
  };
};

/* Get Active Appraisal,Active and Sold MTD*/

export const getVehiclesStats = ( callback): actions.GetVehiclesStats => {
  return {
    type: actions.GET_VEHICLES_STATS,
    payload: { callback},
  };
};


/** Update Vehicle - stockAppLoc */
export const handleVehicleUpdate = (id: number, stockAppLoc: object, callback): actions.HandleVehicleUpdate => {
    return {
        type: actions.HANDLE_VEHICLE_UPDATE,
        payload: { id, stockAppLoc, callback },
    };
};