import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";


const useGaTracker = () => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            // Set Property ID from the Google Analytics Profile 
            // ReactGA.initialize("UA-210782629-1");
            ReactGA.initialize(`${process.env.REACT_APP_G_ANALYTICS}`)
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        // Set Page details to Google Analytics
        if (initialized) {
            ReactGA.pageview(window.location.pathname);
        }
    }, [location, initialized]);
};

export default useGaTracker;