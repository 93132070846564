import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
// import { formatPathname } from "utils/helperFunctions";
import {
    Stack,
    IStackStyles,
    TextField,
    Link
} from "@fluentui/react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import { EditMoreActionsSubmenu } from "components/table/dropdowns/EditMoreActionsSubmenu";
//import CustomTable from "components/table/datatable/CustomTable";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetAppointments } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
//import moment from "moment";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    convertUTCToLocalDateOnlyModified,
    // convertUTCToLocalFormat
    // convertUTCToLocalMoment
} from "utils/dateTimeFunctions";
import { useNavigate , useLocation } from "react-router-dom";
import masterDropdownValues from "../../constants/masterDropdownValues";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

const credentialModuleName = "DealTracker";
export const AppointmentWorkbookPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    //const [appointmentId, setAppointmentId] = useState(0);
    const [appointmentData, setAppointmentData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const appointments = useSelector((state: AppState) => state.dealTracker.appointments) as any;
    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([0]);
	const locations = useSelector((state: AppState) => state.locations.locations) as any; 
	const isMobile = useMediaQuery({ maxWidth:960 });
    const yearRange = "1980:" + (new Date().getFullYear()+1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [credentials, setCredentials] = useState([]);

    const [glFilter, setGLFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    //const [logDateFromFilter, setLogDateFromFilter] = useState('');
    //const [logDateToFilter, setlogDateToFilter] = useState('');
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('appointmentTime');
    const [statesFlag, setStatesFlag] = useState(null);
    const [totalRecords, setTotalRecords] = useState(null);

     const handleFirstNameFilter = (e) => {
		setGLFilter(e.target.value);
        handleFilters(locationFilter, logDateFromFilter ,logDateToFilter,e.target.value);
		//dt.current.filter(val, 'firstName', 'contains');
	};


	const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
		handleFilters(e.value ,logDateFromFilter,logDateToFilter,glFilter);
    }


    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
		handleFilters(locationFilter, val.target.value ,logDateToFilter,glFilter);
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter  (val.target.value)
        handleFilters(locationFilter,logDateFromFilter,val.target.value,glFilter );
    };



    const handleFilters = (locationFilt,logDateFromFilter,logDateToFilter,glFilter) => {
	    
        if (locationFilt || logDateFromFilter || logDateToFilter) {
            
            let results = appointments;
            
            if (results) {

                /** location **/

                if (locationFilt > 0) {
                    results = results.filter(x => x.deal.location.id === locationFilt);
                }

                if (logDateFromFilter) {
                    //results = results.filter(x => new Date(x.appointDateTime) >= new Date(logDateFromFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.appointDateTime)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
                    /* const date = moment(logDateFromFilter).format('MM/DD/YY')
                    results = results.filter(x => convertUTCToLocalMoment(x.appointDate) >= convertUTCToLocalMoment(date)); */

                    const rawFromLogDate = moment(logDateFromFilter).local().format('MM/DD/YYYY')
                    // let rawApprDate = moment(new Date(x.appointDate)).local().format("MM/DD/YY");
                    results = results.filter(x =>  {
						let rawApprDate = moment(new Date(x.appointmentTime)).local().format("MM/DD/YYYY");
						if (new Date(rawApprDate) >= new Date(rawFromLogDate)) {
							return true;
						}
						return false 
					});
                    // results = results.filter(x => moment(new Date(x.appointDate)).local().format("MM/DD/YY") >= new Date(logDateFromFilter));
                }

                if (logDateToFilter) {
                    //results = results.filter(x => new Date(x.appointDateTime) <= new Date(logDateToFilter));
					//results = results.filter(x => convertUTCToLocalModified(new Date(x.appointDateTime)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
                    /* const date = moment(logDateToFilter).format('MM/DD/YY')
                    results = results.filter(x => convertUTCToLocalMoment(x.appointDate) <= convertUTCToLocalMoment(date)); */

					let rawToLogDate = moment(new Date(logDateToFilter)).format("MM/DD/YYYY");
					results = results.filter(x =>  {
						let rawApprDate = moment(new Date(x.appointmentTime)).local().format("MM/DD/YYYY");
						if (new Date(rawApprDate) <= new Date(rawToLogDate)) {
							return true;
						}
						return false 
					});
                }

                if (glFilter && glFilter.length > 1) {

                    const filterKeys = ['appointDateTime', 'appointmentPurpose', 'dealNumber', 'customerName', 'customerEmail', 'customerPhone', 'confirmedBy', 'salesPerson1Name', 'appointmentNotes', 'appointResult','hiddenCol'];
                    const multiFilter = (data = [], filterKeys = [], value = '') => data.filter((item) => filterKeys.some(key => item[key]?.toString().toLowerCase().includes(value) && item[key]));
                    results = multiFilter(results, filterKeys, glFilter?.toLowerCase());
                }
                setAppointmentData(results);
                setTotalRecords(results.length);
            }
            
        } else {
            // expandableTableRows(vehicleList);
            setAppointmentData(appointments);
            setTotalRecords(appointments.length);
        }
    }

    useEffect(() => {
        if (appointments && statesFlag) {
            if (appointments.length > 0) {
                handlePopulateAppointments();

            }
            else {
                setAppointmentData([]);
                setTotalRecords(0);
            }
        }
    }, [appointments, statesFlag]);// eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {

        if (locations && locations.results) {
        locations.results.unshift({ id: 0 ,legalName: 'All'});
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
     //console.log('locationslocationslocations',locations)   
    }, [locations]);

    useEffect(() => {
        if (user && user.profileType && appointments && appointments.length > 0) {
            handlePopulateAppointments();     
        }
        else {
            setAppointmentData([]);
        }
      
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePopulateAppointments = () => {
        for (var i in appointments) {
            let deal = appointments[i].deal;
            let customer = deal.customer;
            let hiddenStr = '';
            
            appointments[i]["appointDateTime"] = moment(appointments[i].appointmentTime).local().format('MM/DD/YY hh:mm a');
            appointments[i]["appointDate"] = convertUTCToLocalDateOnlyModified(appointments[i].appointmentDate);
            appointments[i]["dealNumber"] = deal.dealNumber;
            appointments[i]["customerName"] = customer.businessName ? customer.businessName:(customer.firstName + ' ' + customer.lastName);
            appointments[i]["customerEmail"] = customer.email;
            appointments[i]["customerPhone"] = customer.phoneNumber;
            appointments[i]["confirmedBy"] = appointments[i].employee ? appointments[i].employee.firstName + ' ' + appointments[i].employee.lastName : '';
            appointments[i]["salesPerson1Name"] = getSalesPerson(deal.salesPerson1);
            appointments[i]["appointResult"] = appointments[i].appointmentResult ? getResult(appointments[i].appointmentResult) : '';

            if (deal.vehicle)
                hiddenStr = deal.vehicle?.orderNumber + ' ' + deal.vehicle?.stockNumber + ' ' + deal.vehicle?.vin;
            if (deal.salesPerson2)
                hiddenStr = hiddenStr + ' ' + deal.salesPerson2?.firstName + ' ' + deal.salesPerson2?.lastName;
            if (deal.financePerson1)
                hiddenStr = hiddenStr + ' ' + deal.financePerson1?.firstName + ' ' + deal.financePerson1?.lastName;
            if (deal.financePerson2)
                hiddenStr = hiddenStr + ' ' + deal.financePerson2?.firstName + ' ' + deal.financePerson2?.lastName;
            hiddenStr = hiddenStr + ' ' + customer?.customerNumber;
            appointments[i]["hiddenCol"] = hiddenStr;

        }

        handleFilters(locationFilter, logDateFromFilter, logDateToFilter,glFilter);

        //let results = appointments;

        //if (results) {
        //    /** location **/
        //    
        //    if (locationFilter || stateLocation) {
        //        results = results.filter(x => x.deal.location.id === (stateLocation ?stateLocation:locationFilter));
        //    }

        //    if (logDateFromFilter) {
        //        results = results.filter(x => new Date(x.appointDateTime) >= new Date(logDateFromFilter));

        //    }
        //    setAppointmentData(results);
        //}
    };

    //const extractTime = (dateTime) => {
    //    var date = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
    //    var stillUtc = moment.utc(date).toDate();
    //    return moment(stillUtc).local().format('hh:mm A')
    //};

    const getResult = (id) => {
        let item = masterDropdownValues.appointmentResult.filter(x => x.key === id);
        if (item.length === 1) {
            return item[0].text;
        }
        return '';
    };

    const getSalesPerson = (salesPerson) => {
        if (salesPerson) {
            return salesPerson?.firstName + ' ' + salesPerson?.lastName;
        }
        return '';
    };

    useEffect(() => {
        setSortOrder(1);
        setSortField('appointmentTime');
        let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
        setlogDateToFilter(new Date(todaydate));
        setAppointmentData([]);
        setStatesFlag(null);
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetAppointments())
        dispatch<any>(handleGetLocations())
        //setLocationFilter(user.primaryLocation.id)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleResetWorkbook = () => {   
        setGLFilter(null);
        setLocationFilter(primaryLocation);
        let todaydate =  new Date().toLocaleDateString('en-US', {year: '2-digit', month: '2-digit', day: '2-digit'})
        setLogDateFromFilter(new Date(todaydate));
        setlogDateToFilter(new Date(todaydate));
        setSortField('appointmentTime');
        setSortOrder(1);
        handleFilters(primaryLocation, new Date(todaydate), null,null);
        //if (primaryLocation) {
        //    const results = appointments.filter(x => x.deal.location.id === primaryLocation);
        //    setAppointmentData(results);
        //} else {
        //    setAppointmentData(appointments);
        //}
    };

    const exportExcel = () => {
        if (appointmentData && appointmentData.length) {
            let data = [...appointmentData]

            //const exportData = data.map(({ id, appointmentDate, appointmentResult, appointmentTime, createdAt, employee, deal, ...keepAttrs }) => keepAttrs);
            const exportData = data.map((item) =>
                ({
                    'Appointment Date Time': item.appointDateTime,
                    'Sales Person 1': item.salesPerson1Name,
                    'Deal Number': item.dealNumber,
                    'Comments': item.appointmentPurpose,
                    'Customer Name': item.customerName,
                    'Customer Email': item.customerEmail,
                    'Customer Phone': item.customerPhone,
                    'Confimed By': item.confirmedBy,
                    'Mgr Notes': item.appointmentNotes,
                    'Result': item.appointResult
                }));

            import('xlsx').then(xlsx => {
                const worksheet = xlsx.utils.json_to_sheet(exportData);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'appointment-workbook');
            });
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    //Datatable Templates
	const header = (
		<>  

		{isMobile ?
		<div>
                    <Row>
                        <Col style={{marginTop:"2px"}}>
                            <div className=" employeeeSearchLabelText">
                                <TextField
                                    value={glFilter}
                                    type="search"
                                    label="Search Appointments"
                                    className="ownerSearchText"
                                    onChange={(e) => handleFirstNameFilter(e)}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="locationDropPosition">
                                <label className="lbl-text">Location</label>
                                <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: "auto" }} >
                        <Col>
                            <div className="date-filter-section">
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Appointment Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                maxDate={logDateToFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                minDate={logDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                            <PrimaryButton style={{ float: "right" }}
                                onClick={handleResetWorkbook}
                            >
                                Reset
                               </PrimaryButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="display-row" style={{ marginTop: "15px" }}>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                            <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
						    </Link>
                        </Col>
                    </Row>
		</div>:
		<div>
            <div>
		        <Row>
		            <Col md={10}>
			         <Row>
			             <Col  md={3}>
			                  <div className="table-header employeeeSearchLabelText">
			                  	<TextField
			                  		value={glFilter}
			                  		type="search"
			                  		label="Search Appointments"
			                  		className="ownerSearchText"
			                  		onChange={(e) => handleFirstNameFilter(e)}
			                  	/>
			                  </div>
			         	</Col>
			         	<Col  md={3}>
						  <div className="dd-control">
			                  <label className="lbl-text">Location</label>
                              <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown write-ups-location-dd"
                                    filter={ false}
                                    resetFilterOnHide={true}
                                />
							</div>
			         	</Col>
                        <Col md={4}>
                            <div className="date-filter-section">
			                    <div className="table-header">
						            <div className="ms-TextField-wrapper">
						                 <label className="lbl-text">Appointment Date</label>
						                 <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                maxDate={logDateToFilter}
						                    />
						                 </div>
						            </div>
                                </div>
                                <label className="lbl-text" style={{margin:"30px 10px 0px"}}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                    <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                minDate={logDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
			         	</Col>
                        <Col  md={1} >
						   <div className="table-header employeeeSearchLabelText" style={{marginTop:"24px"}}>
			                   <PrimaryButton
							      onClick={handleResetWorkbook}
							   >
                                   Reset
                               </PrimaryButton>
						   </div>
							  
			         	</Col>

			         </Row>
				</Col>
		            <Col>
				    </Col>
		        </Row>
            </div>
            <div className="display-row total-export-row">
                <div>
                    {
                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                    }
                </div>
                <div>
                    <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                        Export to Excel
		            </Link>
                </div>
            </div>
		</div>}

		</>
	);


    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appointment Date Time</span>
                {rowData.appointDateTime}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person 1</span>
                {rowData.salesPerson1Name}

            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}

            </React.Fragment>
        );
    };

    const purposeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appointment Purpose</span>
                {rowData.appointmentPurpose}

            </React.Fragment>
        );
    };

    const custNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Name</span>
                {rowData.customerName}

            </React.Fragment>
        );
    };

    const custEmailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Email</span>
                {rowData.customerEmail}

            </React.Fragment>
        );
    };

    const custPhoneBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Phone</span>
                {rowData.customerPhone}

            </React.Fragment>
        );
    };

    const confirmedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Confimed By</span>
                {rowData.confirmedBy}

            </React.Fragment>
        );
    };

    const notesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mgr Notes</span>
                {rowData.appointmentNotes}

            </React.Fragment>
        );
    };    

    const resultBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Result</span>
                {rowData.appointResult}

            </React.Fragment>
        );
    };    

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title" style={{ float: "left" }}>Actions</span>
                {credentials && user.roles && (
                    <EditMoreActionsSubmenu
                        modulePermissions={credentials}
                        itemId={rowData.id}
                        onEdit={(appointmentId) => handleEditAppointment(appointmentId)}
                    />
                )}
            </React.Fragment>
        );
    };

    const handleEditAppointment = (id) => {
        const selectedApp = appointmentData.find(x => x.id === id);
        if (selectedApp) {
            handleNavigateToAppointment(selectedApp.deal.id,id);
        }
    };

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role || isKarlProfile(user.profileType)) {
                handleNavigateToAppointment(event.data.deal.id, event.data.id);
                // navigate(`/${event.data.deal.id}/dealtracker`, { tabIndex: 1 });
                //navigate({
                //    pathname: `/${event.data.deal.id}/dealtracker`,
                //    state: {
                //      searchInput: glFilter,
                //      pathfrom: '/appointments',
                //      tabIndex: 1,
                //      productId:`${event.data.id}`,
                //      workbookName:'appointments',
                //      // sortOrder,
                //      // sortField
                //    }
                //});
            //}
        }
    };

    const handleNavigateToAppointment = (dealId,id) => {
        const state = {
            searchInput: glFilter,
            pathfrom: '/appointments',
            tabIndex: 1,
            productId: `${id}`,
            workbookName: 'appointments',
            sortOrder,
            sortField,
            logDateFromFilter,
            logDateToFilter,
            locationFilter
        }
        navigate(`/${dealId}/dealtracker`,{state});
    }

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);
    
    useEffect(() => {        
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
        if (stateVal?.fromKeymetrics) {
            setLocationFilter(stateVal?.locationId);
            setLogDateFromFilter(new Date(stateVal?.filterDate));
            setlogDateToFilter(new Date(stateVal?.filterDate));
        } 

        if (stateVal?.locationFilter) {
            setLocationFilter(stateVal?.locationFilter);
        }

        if (stateVal?.logDateFromFilter) {
            setLogDateFromFilter(new Date(stateVal.logDateFromFilter));
        }

        if (stateVal?.logDateToFilter) {
            setlogDateToFilter(new Date(stateVal.logDateToFilter));
        }

        if (stateVal?.sortField) {
            setSortField(stateVal.sortField);
            setSortOrder(stateVal.sortOrder);
        }

        setStatesFlag(true);
        
    }, [location]);

    //const table_columns = {
    //    "appointDateTime": "Appointment Date Time",
    //    "salesPerson1Name":"Sales Person 1",
    //    "dealNumber": "Deal Number",
    //    "appointmentPurpose": "Comments",
    //    "customerName": "Customer Name",
    //    "customerEmail": "Customer Email",
    //    "customerPhone": "Customer Phone",
    //    "confirmedBy":"Confimed By",
    //    "appointmentNotes": "Mgr Notes",
    //    "appointResult": "Result",
    //    "settings": "Actions",
    //    "hiddenCol": "Hiddedn Col"
    //}

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Appointments</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable"> {/* locations */}
                    <div className="card mouse-hover workbookLocationPlaceHolder">
                        <DataTable
                            value={appointmentData}
                            paginator={true}
                            header={header}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={handleColumnSort}
                            rows={25}
                            className="p-datatable-customers"
                            rowsPerPageOptions={[25, 50, 100]}
                            emptyMessage="No appointments found."
                            onRowClick={(event) => handleRowClick(event)}>
                            <Column sortable field="appointDateTime" sortField="appointmentTime" header="Appointment Date Time" body={dateBodyTemplate} />
                            <Column sortable field="salesPerson1Name" header="Sales Person 1" body={salesPersonBodyTemplate} />
                            <Column sortable field="dealNumber" header="Deal Number" body={dealNumberBodyTemplate} />
                            <Column sortable field="appointmentPurpose" header="Comments" body={purposeBodyTemplate} />
                            <Column sortable field="customerName" header="Customer Name" body={custNameBodyTemplate} />
                            <Column sortable field="customerEmail" header="Customer Email" body={custEmailBodyTemplate} />
                            <Column sortable field="customerPhone" header="Customer Phone" body={custPhoneBodyTemplate} />
                            <Column sortable field="confirmedBy" header="Confimed By" body={confirmedByBodyTemplate} />
                            <Column sortable field="appointmentNotes" header="Mgr Notes" body={notesBodyTemplate} />
                            <Column sortable field="appointResult" header="Result" body={resultBodyTemplate} />
                            <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                        </DataTable>
                        {/*<CustomTable
                            column_list={table_columns}
                            data={appointmentData}
                            header={header}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={handleColumnSort}
                            rows={25}
                            rowsPerPageOptions={[25,50,100]}
                            customTemplates={{
                                "appointDateTime": dateBodyTemplate,
                                "salesPerson1Name": salesPersonBodyTemplate,
                                "dealNumber": dealNumberBodyTemplate,
                                "appointmentPurpose": purposeBodyTemplate,
                                "customerName": custNameBodyTemplate,
                                "customerEmail": custEmailBodyTemplate,
                                "customerPhone": custPhoneBodyTemplate,
                                "confirmedBy": confirmedByBodyTemplate,
                                "appointmentNotes": notesBodyTemplate,
                                "appointResult": resultBodyTemplate,
                                "settings": settingBodyTemplate,
                            }}
                            emptyMessage="No appointments found."
                            onRowClick={(event) => handleRowClick(event)}
                         />*/}
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
