import {
    IPrimarySetup
} from "types/primarySetupTypes";

export const SET_SORT_DEALS = "SET_SORT_DEALS";

export const HANDLE_SAVE_PRIMARY_SETUP = "HANDLE_SAVE_PRIMARY_SETUP";

export interface HandleSavePrimarySetup {
    type: typeof HANDLE_SAVE_PRIMARY_SETUP;
    payload: { data: IPrimarySetup,userId:number };
}

/* Guided setup Form */
export const HANDLE_GUIDED_SETUP_FORM = "HANDLE_GUIDED_SETUP_FORM";

export interface HandleGuidedSetupForm {
    type: typeof HANDLE_GUIDED_SETUP_FORM;
    payload: { data: any };
}

export const HANDLE_SAVE_GUIDED_SETUP_FORM = "HANDLE_SAVE_GUIDED_SETUP_FORM";

export interface HandleSaveGuidedSetupForm {
    type: typeof HANDLE_SAVE_GUIDED_SETUP_FORM;
    payload: { data: any, callback: Function };
}

export const HANDLE_VERIFY_EIN = "HANDLE_VERIFY_EIN";

export interface HandleVerifyEIN {
  type: typeof HANDLE_VERIFY_EIN;
  payload: { data: any, callback: Function };
}

export const HANDLE_REQUEST_EIN_ACCESS = "HANDLE_REQUEST_EIN_ACCESS";
export interface HandleRequestEINAccess {
  type: typeof HANDLE_REQUEST_EIN_ACCESS;
  payload: { data: any, callback: Function };
}

export const SAVE_GUIDED_SETUP_RESOLVED = "SAVE_GUIDED_SETUP_RESOLVED";

export interface SaveGuidedSetupResolved {
  type: typeof SAVE_GUIDED_SETUP_RESOLVED;
}

export const SAVE_GUIDED_SETUP_REJECTED = "SAVE_GUIDED_SETUP_REJECTED";

export interface SaveGuidedSetupRejected {
  type: typeof SAVE_GUIDED_SETUP_REJECTED;
  error : any;
}

export const SAVE_PRIMARY_SETUP_PENDING = "SAVE_PRIMARY_SETUP_PENDING";

export interface SavePrimarySetupPending {
  type: typeof SAVE_PRIMARY_SETUP_PENDING;
}

export const SAVE_PRIMARY_SETUP_RESOLVED = "SAVE_PRIMARY_SETUP_RESOLVED";

export interface SavePrimarySetupResolved {
  type: typeof SAVE_PRIMARY_SETUP_RESOLVED;
}

export const SAVE_PRIMARY_SETUP_REJECTED = "SAVE_PRIMARY_SETUP_REJECTED";

export interface SavePrimarySetupRejected {
  type: typeof SAVE_PRIMARY_SETUP_REJECTED;
  payload: object[];
}

/** guided setup changes */
export const GET_GUIDED_SETUP_CHANGES = "GET_GUIDED_SETUP_CHANGES";

export interface GetGuidedSetupChanges {
    type: typeof GET_GUIDED_SETUP_CHANGES;
    payload: any;
}

export const HANDLE_CLEAN_GUIDEDSETUP = "HANDLE_CLEAN_GUIDEDSETUP";

export interface HandleCleanGuidedSetup {
    type: typeof HANDLE_CLEAN_GUIDEDSETUP;
}

export type PrimarySetupActions =
    | HandleSavePrimarySetup
    | SavePrimarySetupPending
    | SavePrimarySetupResolved
    | SavePrimarySetupRejected
    | HandleSaveGuidedSetupForm
    | HandleVerifyEIN
    | HandleRequestEINAccess
    | SaveGuidedSetupResolved
    | SaveGuidedSetupRejected
    | GetGuidedSetupChanges
    | HandleCleanGuidedSetup;
  