import {
  // IRegister,
  ILogin,
  ILoginResponse,
  IResetPassword,
  IConfirmAccount,
  IConfirmEmail,
  IResendEmail
} from "types/authTypes";

/** REGISTER */
// export const HANDLE_REGISTER = "HANDLE_REGISTER";

// export interface HandleRegister {
//   type: typeof HANDLE_REGISTER;
//   payload: IRegister;
// }

// export const REGISTER_PENDING = "REGISTER_PENDING";

// export interface RegisterPending {
//   type: typeof REGISTER_PENDING;
// }

// export const REGISTER_RESOLVED = "REGISTER_RESOLVED";

// export interface RegisterResolved {
//   type: typeof REGISTER_RESOLVED;
//   // payload: ILoginResponse;
// }

// export const SUCCESS_REGISTER_EMAIL = "SUCCESS_REGISTER_EMAIL";

// export interface RegisterEmailSuccess {
//   type: typeof SUCCESS_REGISTER_EMAIL;
//   payload: any;
// }

// export const REGISTER_REJECTED = "REGISTER_REJECTED";

// export interface RegisterRejected {
//   type: typeof REGISTER_REJECTED;
//   payload: object[];
// }

/** LOGIN */
export const HANDLE_LOGIN = "HANDLE_LOGIN";

export interface HandleLogin {
  type: typeof HANDLE_LOGIN;
  payload: ILogin;
}

export const LOGIN_PENDING = "LOGIN_PENDING";

export interface LoginPending {
  type: typeof LOGIN_PENDING;
}

export const CLEAR_SERVER_MESSAGE = "CLEAR_SERVER_MESSAGE";

export interface ClearServerMessage {
    type: typeof CLEAR_SERVER_MESSAGE;
}

export const LOGIN_RESOLVED = "LOGIN_RESOLVED";

export interface LoginResolved {
  type: typeof LOGIN_RESOLVED;
  payload: ILoginResponse;
}

export const LOGIN_REJECTED = "LOGIN_REJECTED";

export interface LoginRejected {
  type: typeof LOGIN_REJECTED;
  payload: Error | string;
}

/** LOGIN BY TOKEN */
export const HANDLE_LOGIN_BY_TOKEN = "HANDLE_LOGIN_BY_TOKEN";

export interface HandleLoginByToken {
  type: typeof HANDLE_LOGIN_BY_TOKEN;
  payload: string;
}

export const LOGIN_BY_TOKEN_PENDING = "LOGIN_BY_TOKEN_PENDING";

export interface LoginByTokenPending {
  type: typeof LOGIN_BY_TOKEN_PENDING;
}

export const LOGIN_BY_TOKEN_RESOLVED = "LOGIN_BY_TOKEN_RESOLVED";

export interface LoginByTokenResolved {
  type: typeof LOGIN_BY_TOKEN_RESOLVED;
  payload: ILoginResponse;
}

export const LOGIN_BY_TOKEN_REJECTED = "LOGIN_BY_TOKEN_REJECTED";

export interface LoginByTokenRejected {
  type: typeof LOGIN_BY_TOKEN_REJECTED;
  payload: Error | string;
}

/** LOGOUT */
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";

export interface HandleLogout {
    type: typeof HANDLE_LOGOUT;
    payload: boolean
}

export const LOGOUT_PENDING = "LOGOUT_PENDING";

export interface LogoutPending {
  type: typeof LOGOUT_PENDING;
}

export const LOGOUT_RESOLVED = "LOGOUT_RESOLVED";

export interface LogoutResolved {
  type: typeof LOGOUT_RESOLVED;
}

/** Logout and Login*/
export const HANDLE_LOGOUT_LOGIN = "HANDLE_LOGOUT_LOGIN";

export interface HandleLogoutAndLogin {
    type: typeof HANDLE_LOGOUT_LOGIN;
}

export interface LogoutResolved {
    type: typeof LOGOUT_RESOLVED;
}


export const HANDLE_SET_ID_FOR_LOGGED_USER = "HANDLE_SET_ID_FOR_LOGGED_USER";

export interface HandleSetIdForLoggedUser {
  type: typeof HANDLE_SET_ID_FOR_LOGGED_USER;
  payload: number;
}

export const SET_ID_FOR_LOGGED_USER_RESOLVED =
  "SET_ID_FOR_LOGGED_USER_RESOLVED";

export interface setIdForLoggedUserResolved {
  type: typeof SET_ID_FOR_LOGGED_USER_RESOLVED;
  payload: number;
}

export const SET_EXP_PASSWORD_FOR_LOGGED_USER_RESOLVED =
  "SET_EXP_PASSWORD_FOR_LOGGED_USER_RESOLVED";

export interface setExpPasswordForLoggedUser {
  type: typeof SET_EXP_PASSWORD_FOR_LOGGED_USER_RESOLVED;
  payload: string;
}

/** HANDLE TOKEN REFRESH*/
export const HANDLE_TOKEN_REFRESH = "HANDLE_TOKEN_REFRESH";

export interface HandleTokenRefresh {
  type: typeof HANDLE_TOKEN_REFRESH;
}

/** RESET PASSWORD REQUEST  */
export const HANDLE_RESET_PASSWORD_REQUEST = "HANDLE_RESET_PASSWORD_REQUEST";

export interface HandleResetPasswordRequest {
  type: typeof HANDLE_RESET_PASSWORD_REQUEST;
  payload: string;
}

export const RESET_PASSWORD_REQUEST_PENDING = "RESET_PASSWORD_REQUEST_PENDING";

export interface ResetPasswordRequestPending {
  type: typeof RESET_PASSWORD_REQUEST_PENDING;
}

export const RESET_PASSWORD_REQUEST_RESOLVED =
  "RESET_PASSWORD_REQUEST_RESOLVED";

export interface ResetPasswordRequestResolved {
  type: typeof RESET_PASSWORD_REQUEST_RESOLVED;
  payload: any;
}


export const RESET_PASSWORD_REQUEST_REJECTED =
  "RESET_PASSWORD_REQUEST_REJECTED";

export interface ResetPasswordRequestRejected {
  type: typeof RESET_PASSWORD_REQUEST_REJECTED;
}

export const RESET_PASSWORD_LINK_EXPIRED =
  "RESET_PASSWORD_LINK_EXPIRED";

export interface ResetPasswordLinkExpired {
  type: typeof RESET_PASSWORD_LINK_EXPIRED;
  payload: any;
}


/** RESET PASSWORD CONFIRM  */
export const HANDLE_RESET_PASSWORD_CONFIRM = "HANDLE_RESET_PASSWORD_CONFIRM";

export interface HandleResetPasswordConfirm {
  type: typeof HANDLE_RESET_PASSWORD_CONFIRM;
  payload: IResetPassword;
}

export const RESET_PASSWORD_CONFIRM_PENDING = "RESET_PASSWORD_CONFIRM_PENDING";

export interface ResetPasswordConfirmPending {
  type: typeof RESET_PASSWORD_CONFIRM_PENDING;
}

export const RESET_PASSWORD_CONFIRM_RESOLVED =
  "RESET_PASSWORD_CONFIRM_RESOLVED";

export interface ResetPasswordConfirmResolved {
  type: typeof RESET_PASSWORD_CONFIRM_RESOLVED;
}

export const RESET_PASSWORD_CONFIRM_REJECTED =
  "RESET_PASSWORD_CONFIRM_REJECTED";

export interface ResetPasswordConfirmRejected {
  type: typeof RESET_PASSWORD_CONFIRM_REJECTED;
  payload: any;
}

/** CONFIRM_ACCOUNT */
export const HANDLE_CONFIRM_ACCOUNT = "HANDLE_CONFIRM_ACCOUNT";

export interface HandleConfirmAccount {
  type: typeof HANDLE_CONFIRM_ACCOUNT;
  payload: IConfirmAccount;
}

/** CONFIRM_EMAIL */
export const HANDLE_CONFIRM_EMAIL = "HANDLE_CONFIRM_EMAIL";

export interface HandleConfirmEmail {
  type: typeof HANDLE_CONFIRM_EMAIL;
  payload: IConfirmEmail;
}

/** RESEND */
export const HANDLE_RESEND_EMAIL_CONFIRMATION = "HANDLE_RESEND_EMAIL_CONFIRMATION";

export interface HandleResendEmail {
  type: typeof HANDLE_RESEND_EMAIL_CONFIRMATION;
  payload: IResendEmail;
}

export const HANDLE_CONFIRM_EMAIL_STATUS = "HANDLE_CONFIRM_EMAIL_STATUS";

export interface HandleConfirmEmailStatus {
  type: typeof HANDLE_CONFIRM_EMAIL_STATUS;
  payload: any;
}

export const HANDLE_RECONFIRM_EMAIL_STATUS = "HANDLE_RECONFIRM_EMAIL_STATUS";

export interface HandleReConfirmEmailStatus {
  type: typeof HANDLE_RECONFIRM_EMAIL_STATUS;
  payload: any;
}

export const CONFIRM_ACCOUNT_PENDING = "CONFIRM_ACCOUNT_PENDING";

export interface ConfirmAccountPending {
  type: typeof CONFIRM_ACCOUNT_PENDING;
}

export const CONFIRM_ACCOUNT_RESOLVED = "CONFIRM_ACCOUNT_RESOLVED";

export interface ConfirmAccountResolved {
  type: typeof CONFIRM_ACCOUNT_RESOLVED;
}

export const CONFIRM_ACCOUNT_REJECTED = "CONFIRM_ACCOUNT_REJECTED";

export interface ConfirmAccountRejected {
  type: typeof CONFIRM_ACCOUNT_REJECTED;
  payload: Error | string;
}

export type AuthActions =
  | HandleLogin
  | LoginPending
  | LoginResolved
  | LoginRejected
  | ClearServerMessage
  // | HandleRegister
  // | RegisterPending
  // | RegisterResolved
  // | RegisterEmailSuccess
  // | RegisterRejected
  | HandleLoginByToken
  | LoginByTokenPending
  | LoginByTokenResolved
  | LoginByTokenRejected
  | HandleLogout
  | LogoutPending
  | LogoutResolved
  | HandleSetIdForLoggedUser
  | setIdForLoggedUserResolved
  | setExpPasswordForLoggedUser
  | HandleTokenRefresh
  | HandleResetPasswordRequest
  | ResetPasswordRequestPending
  | ResetPasswordRequestRejected
  | ResetPasswordLinkExpired
  | ResetPasswordRequestResolved
  | HandleResetPasswordConfirm
  | ResetPasswordConfirmPending
  | ResetPasswordConfirmRejected
  | ResetPasswordConfirmResolved
  | HandleConfirmAccount
  | HandleConfirmEmail
  | HandleResendEmail
  | HandleConfirmEmailStatus
  | HandleReConfirmEmailStatus
  | ConfirmAccountPending
  | ConfirmAccountRejected
  | ConfirmAccountResolved
  | HandleLogoutAndLogin;
