import React from "react";
import { Depths } from "@uifabric/fluent-theme";

export const windowStyle = {
  padding: 35,
  backgroundColor: "white",
  boxShadow: Depths.depth8,
  flex: 1,
  minWidth: 400,
};

interface IProps {
  children: any;
  style?: object;
}

const Card: React.FC<IProps> = ({ children, style }) => {
  const extraStyles = style ? style : null;

  return <div style={{ ...windowStyle, ...extraStyles }}>{children}</div>;
};

export default Card;
