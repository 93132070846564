import client from "services/api/client";
import { IPagination } from "types/paginationTypes";

export const getGroupsPermissions = (request: IPagination) => {
  const { sort, page, limit, column } = request;
  return client(
    `user/master/auth/groups?sort=${
      sort ? sort : "ASC"
    }&page=${page}&limit=${limit}&column=${column ? column : 'id'}`,
    { body: null }
  ).then(
    (data) => {
      return { data, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const getPermissions = () => {
  return client("user/master/auth/permissions?limit=100", { body: null }).then(
    (perData) => {
      return { perData, error: null };
    },
    (error) => {
      return { data: null, error };
    }
  );
};

export const updatePermission = (permissionId: number, groupId: number, method: string) => {
    const methodType = method === 'add' ? 'POST' : 'DELETE'
    const query = `user/master/auth/permission/${permissionId}/group/${groupId}`;
    return client(query, { body: null, method: methodType }).then(
        (data) => {
            return { data, error: null };
        },
        (error) => {
            return { data: null, error };
        }
    );
}

