import React, { useState, useEffect} from "react";
import {
    DefaultButton,
    Modal,
    IconButton,
    TextField,
    PrimaryButton,
    getTheme,
    mergeStyleSets,
    FontWeights
} from "@fluentui/react";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { styles } from "../../../pages/accountSettingsPage/styles";
import { colrDropdownStyles } from 'constants/styles'
import {
    iconButtonStyles,
    //contentStyles as content,
    cancelIcon
} from 'constants/styles';
import {
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
} from "@fluentui/react/lib/Persona";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import masterDropdownValues from "constants/masterDropdownValues";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { handleCreateBrand } from "store/brands/brands.action";
// import InputColor from 'react-input-color';
//const contentStyles = content();

interface IProps {
    isOpen: boolean;
    onDismiss: () => void;
}

const emptyForm = {
    name: "",
    color: "#0867ccff",
    fontFamily: "",
    img: "",
};

export const AddBrandDialog: React.FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
}) => {
    const dispatch = useDispatch();

    const examplePersona: IPersonaSharedProps = {
        imageUrl: `${require("assets/image-placeholder.jpg")}`,
        //imageInitials: "TT",
        text: "",
        secondaryText: "",
        // tertiaryText: "dealer account",
    };
    const [uploadError, setUploadError] = useState("");
    useEffect(() => {
        if (!isOpen) {
            setUploadError('');
        }
    }, [isOpen]);

    const [persona, setPersona] = React.useState(examplePersona);

    const formik = useFormik({
        initialValues: {
            ...emptyForm
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            color: Yup.string().required("Required"),
            fontFamily: Yup.string().required("Required"),
            img: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const {
                name,
                color,
                fontFamily,
                img
            } = values;
            dispatch<any>(
                handleCreateBrand({
                    brand: {
                        name,
                        color,
                        fontFamily,
                        img
                    },
                    callback: () => {
                        onDismiss();
                        formik.setValues({ ...emptyForm });
                        formik.resetForm()
                    },
                })
            );

            // to be removed and fire only when no errors
        },
    });
    // const handleColorChange = (color) => {
    //     formik.setValues(
    //         {
    //             ...formik.values,
    //             color: color.hex,
    //         },
    //         true
    //     );
    // };
    const handleDropdownChange = (e, item) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: item.key,
            },
            true
        );
    };

    const handleAddBrand = () => {
        formik.handleSubmit();
    };


    const handleLogoChange = e => {
        e.preventDefault();
        setUploadError('');
        let reader = new FileReader();
        let file = e.target.files[0];
        var t = file.type.split('/').pop().toLowerCase();
        if (t !== "jpeg" && t !== "jpg" && t !== "png") {
            setUploadError('Please select a valid image file');
            e.target.value = null;
            return false;
        }
        if (file.size > 1024000) {
            setUploadError('Max Upload size is 1MB only');
            e.target.value = null;
            return false;
        }
        //setError('');
        reader.onloadend = () => {
            examplePersona.imageUrl = reader.result.toString();
            setPersona(examplePersona);
            formik.setValues(
                {
                    ...formik.values,
                    "img": file,
                },
                true
            );
        }
        reader.readAsDataURL(file)
    }

    const resetModal = () => {
        formik.setValues({ ...emptyForm });
        formik.resetForm();
        examplePersona.imageUrl = `${require("assets/image-placeholder.jpg")}`;
        setPersona(examplePersona);
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onDismiss={onDismiss}
                onDismissed={resetModal}
                isBlocking={true}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header+" inventory-popup"}>
                    <CustomFontText>Add New Brand</CustomFontText>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onDismiss}
                    />
                </div>
                <div className={contentStyles.body}>
                    <TextField
                        id="name"
                        name="name"
                        label="Name"
                        required={true}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={
                            formik.touched.name &&
                                formik.errors.name
                                ? formik.errors.name.toString()
                                : null
                        }
                    />
                    
                    
                    <Dropdown
                        style={{ fontFamily: formik.values.fontFamily}}
                        id="fontFamily"
                        label="Font Family"
                        required={true}
                        options={masterDropdownValues.fontFamilyList}
                        styles={colrDropdownStyles}
                        selectedKey={formik.values.fontFamily}
                        onChange={handleDropdownChange}
                        errorMessage={
                            formik.touched.fontFamily && formik.errors.fontFamily
                                ? formik.errors.fontFamily.toString()
                                : null
                        }
                    />
                    <label className="lbl-text required-Label">
                            Color
                    </label>
                    {/* <InputColor initialValue={formik.values.color} placement="right" onChange={handleColorChange} /> */}
                    {/*<div style={{ width: "2rem", padding: "2rem" }}>
                        <ColorList colors={colors} onChange={handleColorChange} disableAlpha />
                    </div>*/}
                    <label className="lbl-text required-Label">
                        Logo
                    </label>
                    <Persona
                        {...persona}
                        styles={styles.personBrandStyles}
                        size={PersonaSize.size100}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={false}
                        imageAlt="Brand Logo"
                    />
                    <DefaultButton
                        styles={styles.brandUpload}
                        text="Upload logo"
                        type="file"
                        iconProps={{ iconName: "Upload" }}
                    >
                        <input
                            style={{
                                padding: 20,
                                position: "absolute",
                                left: 0,
                                top: 0,
                                width: 107,
                                height: 1,
                                overflow: "hidden",
                                opacity: 0,
                                marginLeft: -10,
                            }}
                            type="file"
                            onChange={handleLogoChange}
                        />
                    </DefaultButton>
                    {
                        uploadError ? <p className="genericErrorColor"><span>{uploadError}</span></p> : null
                    }
                </div>

                <div className={contentStyles.footer}>
                    <Stack tokens={styles.buttonTokens} horizontal horizontalAlign="end">
                        <DefaultButton text="Close" onClick={onDismiss} />
                        <PrimaryButton
                            disabled={!formik.isValid || !formik.dirty}
                            text="Add Brand"
                            onClick={handleAddBrand}
                        />
                    </Stack>
                </div>
            </Modal>
        </>
    );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        // width: "50%",
        width: 500,
        // display: 'flex',
        // flexFlow: 'column nowrap',
        // alignItems: 'stretch',
        zIndex: 100,
    },
    header: {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "20px 30px",
    },

    body: {
        // flex: "4 4 auto",
        // padding: "0 24px 24px 24px",
        //   padding: '75px',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: "40px",
        // overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    footer: {
        padding: "0px 30px",
        paddingBottom: 40,
    },
});