import * as actions from "./inventory.types";
import { IInventoryResponse, IInventory } from "types/inventoryTypes";

interface IState {
  loading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  loadingNada: boolean;
  loadingKelley: boolean;
  loadingBlack: boolean;
  nadaValuation: any;
  kelleyValuation: any;
  blackValuation: any;
  items: IInventoryResponse;
  error: null | object;
  rowsPerPageInventory: number;
  sort: string;
  sortColumn: string;
  selectedInventory: IInventory;
  dataFromVin: Partial<IInventory>;
  filters: {
    location: any;
    condition: string[];
    bodyStyle: string;
    minPrice: string;
    maxPrice: string;
    minYear: number;
    maxYear: number;
    vehicleHistory: string[];
    mileage: string;
    make: {key: number, text: string};
    model: {key: number, text: string};
    invoice: boolean;
    leasing: boolean;
    driveType: string;
    fuelType: string;
    engineCylinders: string;
    engineDescription: string;
    transmission: string;
    exteriorColor: string;
    intColorGeneric: string;
    doors: string;
    body: string;
    audioOptions: string[];
    powerOptions: string[];
    interiorOptions: string[];
    exteriorOptions: string[];
    safetyOptions: string[];
    luxuryOptions: string[];
    otherOptions: string[];
  },
  filterList: any,
  shopVehicleList: any,
  shopWorkBookList: any,
  purchaseWorkBookList: any,
  soldWorkBookList: any,
  vehicleList: any
}

export const initialFilterState = {
  location: null,
  condition: [],
  bodyStyle: null,
  minPrice: null,
  maxPrice: null,
  minYear: null,
  maxYear: null,
  vehicleHistory: [],
  mileage: null,
  make: {key: 0, text: ""},
  model: {key: 0, text: ""},
  invoice: false,
  leasing: false,
  driveType: null,
  fuelType: null,
  engineCylinders: null,
  engineDescription: null,
  transmission: null,
  exteriorColor: null,
  intColorGeneric: null,
  doors: null,
  body: null,
  audioOptions: ["MP3"],
  powerOptions: [],
  interiorOptions: [],
  exteriorOptions: [],
  safetyOptions: [],
  luxuryOptions: [],
  otherOptions: [],
};

const initialState: IState = {
  loading: false,
  loadingNada: false,
  loadingKelley: false,
  loadingBlack: false,
  nadaValuation: "",
  kelleyValuation: "",
  blackValuation: "",
  updateLoading: false,
  deleteLoading: false,
  items: {
    totalItems: null,
    currentPage: 1,
    results: [],
  },
  error: null,
  rowsPerPageInventory: 15,
  sort: "ASC",
  sortColumn: "inventory.id",
  selectedInventory: null,
  dataFromVin: null,
  filters: initialFilterState,
  filterList: [],
  shopVehicleList: null,
  shopWorkBookList: [],
  purchaseWorkBookList: [],
  soldWorkBookList: [],
  vehicleList:[]
};

export const InventoryReducer = (
  state: IState = initialState,
  action: actions.InventoryActions
) => {
  switch (action.type) {
    case actions.HANDLE_SORT_INVENTORY_TABLE: {
      return {
        ...state,
        sort: action.payload.sort,
        sortColumn: action.payload.sortColumn,
      };
    }

    case actions.GET_INVENTORY_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.GET_INVENTORY_FILTER_RESOLVED: {
      return {
        ...state,
        loading: false,
        filterList: action.payload
      };
    }
    case actions.HANDLE_GET_VEH_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        shopVehicleList: {...state.shopVehicleList, ...action.payload}
      };
      }
    case actions.GET_VEHICLES_PENDING: {
        return {
            ...state,
            loading: false,
            vehicleList: null,
            error:null
        };
    }
    case actions.GET_VEHICLES_RESOLVED: {
    return {
      ...state,
      loading: false,
      vehicleList: action.payload
    };
    }
    case actions.GET_VEHICLES_REJECTED: {
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    }
    case actions.GET_SHOP_WORKBOOK_RESOLVED: {
      return {
        ...state,
        loading: false,
        shopWorkBookList: action.payload
      };
    }
    case actions.GET_PURCHASE_WORKBOOK_RESOLVED: {
        return {
            ...state,
            loading: false,
            purchaseWorkBookList: action.payload
        };
    }
    case actions.GET_SOLD_WORKBOOK_RESOLVED: {
        return {
            ...state,
            loading: false,
            soldWorkBookList: action.payload
        };
    }
    case actions.GET_INVENTORY_RESOLVED: {
      return {
        ...state,
        loading: false,
        items: { ...action.payload },
      };
    }
    case actions.GET_INVENTORY_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.SAVE_INVENTORY_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.CREATE_INVENTORY_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CREATE_INVENTORY_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CREATE_INVENTORY_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.SET_ROWS_PER_PAGE_INVENTORY:
      return {
        ...state,
        rowsPerPageInventory: action.payload,
      };
    case actions.VIEW_INVENTORY_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.VIEW_INVENTORY_RESOLVED: {
      return {
        ...state,
        loading: false,
        selectedInventory: action.payload,
      };
    }

    case actions.VIEW_INVENTORY_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_INVENTORY_PENDING: {
      return {
        ...state,
        deleteLoading: true,
      };
    }

    case actions.DELETE_INVENTORY_RESOLVED: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case actions.DELETE_INVENTORY_REJECTED: {
      return {
        ...state,
        deleteLoading: false,
      };
    }

    case actions.EDIT_INVENTORY_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.EDIT_INVENTORY_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.EDIT_INVENTORY_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_INVENTORY_PENDING: {
      return {
        ...state,
        updateLoading: true,
        //loading: true,
      };
    }

    case actions.UPDATE_INVENTORY_RESOLVED: {
      return {
        ...state,
        updateLoading: false,
        //loading: false,
      };
    }

    case actions.UPDATE_INVENTORY_REJECTED: {
      return {
        ...state,
        updateLoading: false,
        //loading: false,
      };
    }
    case actions.ADD_INVENTORY_PHOTO_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.ADD_INVENTORY_PHOTO_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.DELETE_INVENTORY_PHOTO_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.DELETE_INVENTORY_PHOTO_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_FROM_VIN_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case actions.GET_FROM_VIN_REJECTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_FROM_VIN_RESOLVED: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_VALUATION_INFORMATION_PENDING: {
      return {
        ...state,
        [getLoading(action.payload)]: true,
      };
    }

    case actions.GET_VALUATION_INFORMATION_RESOLVED: {

      console.log(action.payload)
      
      return {
        ...state,
        [getLoading(action.payload.source)]: false,
        [getValuation(action.payload.source)]: action.payload.data,
      };
    }

    case actions.GET_VALUATION_INFORMATION_REJECTED: {
      return {
        ...state,
        loadingNada: false,
        loadingKelley: false,
        loadingBlack: false,
        nadaValuation: "",
        kelleyValuation: "",
        blackValuation: "",
      };
    }

    case actions.CLEAN_VALUATION_INFORMATION: {
      return {
        ...state,
        loadingNada: false,
        loadingKelley: false,
        loadingBlack: false,
        nadaValuation: "",
        kelleyValuation: "",
        blackValuation: "",
      }
    }

    case actions.CLEAN_INVENTORY_FILTERS: {
      return {
        ...state,
        filters: initialState.filters
      }
    }

    case actions.SAVE_INVENTORY_FILTERS: {
      return {
        ...state,
        filters: action.payload
      }
    }

    case actions.SET_INVENTORY_FILTERS: {
      return {
        ...state,
        filters: action.payload
      }
    }
    case actions.MANAGE_FAVORITE_VEHICLE_PENDING: {
        return {
            ...state,
            error: null
        };
    }
    case actions.MANAGE_FAVORITE_VEHICLE_REJECTED: {
        return {
            ...state,
            error: action.payload
        };
    }
    default:
      return state;
  }
};

const getLoading = (source) => {
  if (source === "nada") {
    return "loadingNada";
  }
  if (source === "kelley") {
    return "loadingKelley";
  }
  if (source === "black") {
    return "loadingBlack";
  }
};

const getValuation = (source) => {
  if (source === "nada") {
    return "nadaValuation";
  }
  if (source === "kelley") {
    return "kelleyValuation";
  }
  if (source === "black") {
    return "blackValuation";
  }
};
