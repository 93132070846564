import React, { useEffect, useState } from "react";
import LoginPage from "pages/auth/loginPage/LoginPage";
//import RegisterPage from "pages/auth/registerPage/RegisterPage";
import {  Route, Routes} from "react-router-dom";
import PrivacyComponent from "pages/auth/privacyPages/PrivacyPolicy";
import CookiesPolicy from "pages/auth/privacyPages/CookiesPolicy";
import TermsOfUse from "pages/auth/privacyPages/TermsOfUse";

import PrivateRoute from "components/routes/privateRoute/PrivateRoute";
import AuthRoute from "components/routes/authRoute/AuthRoute";
import ProtectedRoute from "components/routes/protectedRoute/ProtectedRoute";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import MainSection from "components/layouts/mainSection/MainSection";
import AppBar from "components/navigation/appBar/AppBar";
import Dashboard from "pages/dashboard/Dashboard";
import { ScreenClassProvider } from "react-grid-system";
import { handleLoginByToken } from "store/auth/auth.action";
import DealerProfilePage from "pages/dealerProfilePage/DealerProfilePage";
import { LocationsPage } from "pages/locationsPage/LocationsPage";
import { SelectListPage } from "pages/selectListPage/SelectListPage";
import { LocationDetailPage } from "pages/locationsPage/LocationDetailPage";
import { EmployeesPage } from "pages/employeesPage/EmployeesPage";
import { AdminAccountPage } from "pages/adminAccountPage/AdminAccountPage";
import { AccountDetailPage } from "pages/adminAccountPage/AccountDetailPage";
//import { NewEmployeesPage } from "pages/employeesPage/NewEmployeesPage";
// import { InventoryPage } from "pages/inventoryPage/InventoryPageOld";
import { ShopInventoryPage } from "pages/inventoryPage/ShopInventoryPage";
import { ProspectsPage } from "pages/prospectsPage/ProspectsPage";
import { GroupsPage } from "pages/groupsPage/GroupsPage";
//import RegisterSuccessPage from "pages/auth/registerSuccess/RegisterSuccessPage";
import RegisterCompletePage from "pages/auth/registerComplete/RegisterCompletePage";
//import AccountSettingsPage from "pages/accountSettingsPage/AccountSettingsPage";
import SubscriptionsPage from "pages/subscriptionsPaga/SubscriptionsPage";
import WebsitesPage from "pages/websitesPage/WebsitesPage";
import BrandsPage from "pages/brandsPage/BrandsPage";
import AccountSettingsPivotPage from "pages/accountSettingsPage/AccountSettingsPivotPage";
import { DealsPage } from "pages/dealsPage/DealsPage";
import { MarketingPage } from "pages/marketingPage/MarketingPage";
import Loader from "components/loader/Loader";
import { emplyeePermissionsAction, emplyeePermissionsScreen, permissionsModules } from "./constants/constants";
import DealSettingsPage from "pages/dealSettingsPage/DealSettingsPage";
import RecoverMyAccountRequestPage from "pages/auth/recoverMyAccount/RecoverMyAccountRequestPage";
import RecoverMyAccountConfirmPage from "pages/auth/recoverMyAccount/RecoverMyAccountConfirmPage";
import RequestSuccessPage from "pages/auth/recoverMyAccount/RequestSuccesPage";
import RecoverAccountSuccessPage from "pages/auth/recoverMyAccount/RecoverAccountSuccessPage";
import DeskADealPage from "./pages/deskADealPage/DeskADealPage";
import EmployeeAccountSettingsPivotPage from "./pages/accountSettingsPage/EmployeeAccountSettingsPivotPage";
import Notifier from "components/notifier/Notifier";
//import NoMatch from "components/noMatch/NoMatch";
// import PrimarySetupPage from "./pages/primarySetupPage/PrimarySetupPage";
import LoggedOutPage from "pages/auth/loggedOutPage/LoggedOutPage";
import { GuidedSetUpPage } from "./pages/primarySetupPage/GuidedSetUpPage";
import { PasswordSetUpPage } from "./pages/primarySetupPage/PasswordSetUpPage";
import SuccessfullyAccessRequested from "./pages/primarySetupPage/SuccessfullyAccessRequested";
import { ShopInventoryEdit } from "pages/inventoryPage/ShopInventoryEdit";
import { SoldInventoryPage } from "pages/inventoryPage/SoldInventoryPage";
import { InventoryPage } from "pages/inventoryPage/InventoryPage";
import { PurchaseInventoryPage } from "pages/inventoryPage/PurchaseInventoryPage";
import { AddInventoryPage } from "pages/inventoryPage/AddInventoryPage";
import { DealTrackerPage } from "pages/dealTrackerPage/DealTrackerPage";
import { AppointmentWorkbookPage } from "pages/dealTrackerPage/AppointmentWorkbookPage";
import { AppointmentWorkbookV2Page } from "pages/dealTrackerPage/AppointmentWorkbookV2Page";
import { AppraisalWorkbookPage } from "pages/dealTrackerPage/AppraisalWorkbookPage";
import { AppraisalWorkbookV2Page } from "pages/dealTrackerPage/AppraisalWorkbookV2Page";
import { ServiceContractWorkbookPage } from "pages/dealTrackerPage/ServiceContractWorkbookPage";
import { ServiceContractWorkbookV2Page } from "pages/dealTrackerPage/ServiceContractWorkbookV2Page";
import { TaskWorkbookPage } from "pages/dealTrackerPage/TaskWorkbookPage";
import { TaskWorkbookV2Page } from "pages/dealTrackerPage/TaskWorkbookV2Page";
import { SalesTargetPage } from "pages/dealTrackerPage/SalesTargetPage";
import { SchedulingWorkbook } from "pages/dealTrackerPage/SchedulingWorkbook";
import { CancellationWorkbookPage } from "pages/dealTrackerPage/CancellationWorkbookPage";
import { CancellationWorkbookV2Page } from "pages/dealTrackerPage/CancellationWorkbookV2Page";
import { UniversalSearchWorkbookPage } from "pages/dealTrackerPage/UniversalSearchWorkbookPage";
import { UniversalSearchWorkbookV2Page } from "pages/dealTrackerPage/UniversalSearchWorkbookV2Page";
import { VehiclePrepWorkbookPage } from "pages/dealTrackerPage/VehiclePrepWorkbookPage";
import { DealerTradeWorkbookPage } from "pages/dealTrackerPage/DealerTradeWorkbookPage";
//import { WriteUpsWorkbookPage } from "pages/dealTrackerPage/WriteUpsWorkbookPage";
import { WriteUpsWorkbookV2Page } from "pages/dealTrackerPage/WriteUpsWorkbookV2Page";
import { FinanceProductSalesReport } from "pages/dealTrackerPage/FinanceProductSalesReport";
import { FinanceDeskingReports } from "pages/dealTrackerPage/FinanceDeskingReports";
import { FinnacePersonLenderCountReport } from "pages/dealTrackerPage/FinnacePersonLenderCountReport";
import { FinanceReserveSplitsReports } from "pages/dealTrackerPage/FinanceReserveSplitsReports";
import { FinanceProductSplitsReports } from "pages/dealTrackerPage/FinanceProductSplitsReports";
import { FinancePersonMonthEndReports } from "pages/dealTrackerPage/FinancePersonMonthEndReports";
import { FinancePersonStackRankReports } from "pages/dealTrackerPage/FinancePersonStackRankReports";
import { KarlDocReports } from "pages/dealTrackerPage/KarlDocReports";
import { SalesPersonFinanceReports } from "pages/dealTrackerPage/SalesPersonFinanceReports";
import { SalesPersonMonthEndReports } from "pages/dealTrackerPage/SalesPersonMonthEndReports";
import { FinanceDetailReports } from "pages/dealTrackerPage/FinanceDetailReports";
//import { CDRCPOReportWorkbookPage } from "pages/dealTrackerPage/CDRCPOReport";
import { CDRCPOReportWorkbookV2Page } from "pages/dealTrackerPage/CDRCPOReportV2";
import { PendingReports } from "pages/dealTrackerPage/PendingReports";
import { SalesPendingReport } from "pages/dealTrackerPage/SalesPendingReport";
import { FinancePendingReport } from "pages/dealTrackerPage/FinancePendingReport";
import { DisplayBoardWorkbookPage } from "pages/dealTrackerPage/DisplayBoardWorkbookPage";
import { VacationReports } from "pages/dealTrackerPage/VacationReports";
import { VehicleTrackerWorkbookPage } from "pages/dealTrackerPage/VehicleTrackerWorkbookPage";
import { VehicleTrackerPage } from "pages/dealTrackerPage/VehicleTrackerPage";
import SettingsPage from "pages/settingsPage/SettingsPage";
import { Stack } from "@fluentui/react";
import { useLocation } from "react-router-dom";
// import { ShopInventoryRetailReports } from "pages/inventoryPage/ShopInventoryRetailReports";
import useGaTracker from './useGaTracker';
// external Library
import CacheBuster from 'react-cache-buster';
//import { version } from '../package.json';
import packageInfo from '../package.json';
import { Helmet } from 'react-helmet';
import 'antd/dist/antd.min.css'


function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const userProfile = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );
    useGaTracker();
    const [showLeftNav, setshowLeftNav] = useState(false)

    function hideOrShowNav(status: any = null) {
        if (status == null) {
            setshowLeftNav(!showLeftNav);
        }
        else {
            setshowLeftNav(false);
        }

    }
    useEffect(() => {
        /* const script = document.createElement("script");
        const script2 = document.createElement("script");
        script.src = "./assets/js/dlr.js";
        script2.src = "./assets/js/dce.js";
        script.async = true;
        script2.async = true;
      
        document.body.appendChild(script);
        document.body.appendChild(script2); */
    }, [])

    useEffect(() => {
        dispatch<any>(handleLoginByToken(""));
    }, [dispatch]);

    return (
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={true} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
        >
          
            <ScreenClassProvider>
                <Loader />
                <Helmet>
                    <script
                        src="https://cdn.jsdelivr.net/npm/dynamsoft-label-recognizer@2.2.0/dist/dlr.js"
                        type="text/javascript"
                    />
                    <script
                        src="https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@2.0.3/dist/dce.js"
                        type="text/javascript"
                    />
                </Helmet>
                <AppBar handleNavButton={() => hideOrShowNav()} showLeftNav={showLeftNav} showHide={location?.pathname === `/DisplayBoardWorkbook` ? true : false} />
                <MainSection handleNavButton={(status) => hideOrShowNav(status)}
                    showLeftNav={showLeftNav} showHide={location?.pathname === `/DisplayBoardWorkbook` ? true : false}>
                    <Notifier />
                    <Routes>
                        {/* <AuthRoute  path="/register" element={RegisterPage} />
                        <AuthRoute  path="/register-success" element={RegisterSuccessPage} /> */}
                        <AuthRoute  path="/logout" element={<LoggedOutPage/>} />
                        <AuthRoute  path="/privacy-policy" element={<PrivacyComponent/>} />
                        <AuthRoute  path="/cookies-policy" element={<CookiesPolicy/>} />
                        <AuthRoute  path="/terms-of-use" element={<TermsOfUse/>} />

                        <Route path="/confirmation" element={<RegisterCompletePage/>} />
                        <AuthRoute  path="/login" element={<LoginPage/>} />
                        <AuthRoute
                            
                            path="/reset-password"
                            element={<RecoverMyAccountRequestPage/>}
                        />

                        <AuthRoute
                            
                            path="/request-success-page"
                            element={<RequestSuccessPage/>}
                        />

                        <Route
                            
                            path="/reset-password-confirm"
                            element={<RecoverMyAccountConfirmPage/>}
                        />
                        {/* <AuthRoute
                             
                             path="/recover-my-account-request"
                             element={RecoverMyAccountConfirmPage}
                           /> */}
                        <AuthRoute
                            
                            path="/reset-password-success"
                            element={<RecoverAccountSuccessPage/>}
                        />
                        {/* <PrivateRoute
                            path="/primary-setup"
                            element={PrimarySetupPage}
                        /> */}
                        <PrivateRoute
                            path="/guided-setup"
                            element={<GuidedSetUpPage/>}
                        />
                        <Route
                            path="/initial-setup"
                            element={<PasswordSetUpPage/>}
                        />
                        <PrivateRoute path="/accessRequested" element={<SuccessfullyAccessRequested/>} />
                        <PrivateRoute  path="/dashboard" element={<Dashboard/>} />
                        <PrivateRoute  path="/marketing" element={<MarketingPage/>} />
                        <PrivateRoute
                            
                            path="/subscriptions/:subscriptionState/subscriptions"
                            element={<SubscriptionsPage/>}
                        />
                        <PrivateRoute
                            
                            path="/subscriptions"
                            element={<SubscriptionsPage/>}
                        />
                        <PrivateRoute
                            
                            path="/websites"
                            element={<WebsitesPage/>}
                        />
                        <PrivateRoute
                            
                            path="/brands"
                            element={<BrandsPage/>}
                        />
                        <ProtectedRoute
                            path="/business-info"
                            element={<DealerProfilePage/>}
                            // permissionModule={permissionsModules.DealerProfile}
                            permissionModule={emplyeePermissionsScreen.SETTING_BUSINESS_INFO_VIEW}
                        />
                      <Route
                            
                            path="/account-settings"
                            element={
                                userProfile.roles?.includes("ROLE_MASTER")
                                    ? <AccountSettingsPivotPage/>
                                    : <EmployeeAccountSettingsPivotPage/>
                            }
                        // permissionModule={permissionsModules.Config}
                        />
                        <ProtectedRoute
                            path="/inventory"
                            element={<InventoryPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_HOME}
                        />
                        <ProtectedRoute
                            path="/appraisal"
                            element={<ShopInventoryPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_SHOP}
                        />
                        <ProtectedRoute
                            path="/add-inventory"
                            element={<AddInventoryPage/>}
                            permissionModule={permissionsModules.Inventory}
                        />
                        <ProtectedRoute
                            // path="/shop-inventory"
                            path="/:vehicleID/shop-inventory"
                            element={<ShopInventoryEdit history={undefined} location={location}/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsAction.VEHICLE_EDIT}
                        />
                        <ProtectedRoute
                            path="/acquired"
                            element={<PurchaseInventoryPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/sold"
                            element={<SoldInventoryPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_SOLD}
                        />

                        <ProtectedRoute
                            path="/:dealID/dealtracker"
                            element={<DealTrackerPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/appointmentsV2"
                            element={<AppointmentWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        < ProtectedRoute
                            path="/write-ups"
                            element={<WriteUpsWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                         <ProtectedRoute
                            path="/appointments"
                            element={<AppointmentWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/appraisalsV2"
                            element={<AppraisalWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/appraisals"
                            element={<AppraisalWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/tasksV2"
                            element={<TaskWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/tasks"
                            element={<TaskWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/service-contract-v2"
                            element={<ServiceContractWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/service-contract"
                            element={<ServiceContractWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/sales-goal"
                            element={<SalesTargetPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.SALES_TARGET}
                        />
                        <ProtectedRoute
                            path="/scheduling"
                            element={<SchedulingWorkbook/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/cancellationsV2"
                            element={<CancellationWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/cancellations"
                            element={<CancellationWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/search-inventory-v1"
                            element={<UniversalSearchWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/search-inventory"
                            element={<UniversalSearchWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/vehicle-prep"
                            element={<VehiclePrepWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/dealer-trade"
                            element={<DealerTradeWorkbookPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        {/*<ProtectedRoute
                            path="/write-ups-v2"
                            element={WriteUpsWorkbookPage}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />*/}
                        
                        {/*<ProtectedRoute
                            path="/cdr-cpo-reports"
                            element={CDRCPOReportWorkbookPage}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />*/}
                        <ProtectedRoute
                            path="/cdr-cpo-reports"
                            element={<CDRCPOReportWorkbookV2Page/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                       <Route
                            path="/vacation-reports"
                            element={<VacationReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            // permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-product-sales-reports"
                            element={<FinanceProductSalesReport/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />

                        <ProtectedRoute
                            path="/pending-reports"
                            element={<PendingReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-desking-reports"
                            element={<FinanceDeskingReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-person-lender-count-reports"
                            element={<FinnacePersonLenderCountReport/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-reserve-splits-reports"
                            element={<FinanceReserveSplitsReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-product-splits-reports"
                            element={<FinanceProductSplitsReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-person-month-end-reports"
                            element={<FinancePersonMonthEndReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-stack-rank"
                            element={<FinancePersonStackRankReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/doc-reports"
                            element={<KarlDocReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/sales-finance-reports"
                            element={<SalesPersonFinanceReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/sales-pending-reports"
                            element={<SalesPendingReport/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/sales-month-end-reports"
                            element={<SalesPersonMonthEndReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            path="/finance-detail-reports"
                            element={<FinanceDetailReports/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            // path="/shop-inventory"
                            
                            path="/DisplayBoardWorkbook"
                            element={<DisplayBoardWorkbookPage children={""}/>}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute
                            // path="/shop-inventory"
                            
                            path="/Finance-Pending-Report"
                            element={<FinancePendingReport/>}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        <ProtectedRoute

                            path="/vehicle-tracker-workbook-page"
                            element={<VehicleTrackerWorkbookPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />

                        <ProtectedRoute
                            path="/:vehId/vehicle-tracker-page"
                            element={<VehicleTrackerPage/>}
                            //permissionModule={permissionsModules.Inventory}
                            permissionModule={emplyeePermissionsScreen.WORKBOOK_PURCHASE}
                        />
                        {/* <ProtectedRoute   
                            // path="/shop-inventory"   
                            
                            path="/:vehicleID/shop-inventory/reports"
                            element={ShopInventoryReportsJD}
                            permissionModule={permissionsModules.Inventory}   
                        /> */}
                        {/* <ProtectedRoute
                            // path="/shop-inventory"
                            
                            path="/:vehicleID/shop-inventory/retailReports"
                            element={ShopInventoryRetailReports}
                            permissionModule={permissionsModules.Inventory}
                        /> */}
                        <ProtectedRoute
                            
                            path="/departments"
                            permissionRequired={true}
                            element={<SelectListPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.DEPARTMENT_LIST}
                        />
                        <ProtectedRoute
                            
                            path="/lenders"
                            permissionRequired={true}
                            element={<SelectListPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.LENDER_LIST}
                        />
                        <ProtectedRoute
                            
                            path="/finance-products"
                            permissionRequired={true}
                            element={<SelectListPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.FINANCE_PRODUCT_LIST}
                        />
                        <ProtectedRoute
                            
                            path="/appraisal-bidders"
                            permissionRequired={true}
                            element={<SelectListPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.APPRAISAL_BIDDER_LIST}
                        />

                        <ProtectedRoute
                            
                            path="/locations"
                            element={<LocationsPage/>}
                            permissionRequired={true}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsScreen.SETTING_LOCATION_LIST}
                        />
                        <ProtectedRoute
                            
                            path="/:locationID/location-detail"
                            element={<LocationDetailPage/>}
                            // permissionModule={permissionsModules.Location}
                            permissionModule={emplyeePermissionsAction.SETTING_LOCATION_UPDATE}
                        />
                        <ProtectedRoute
                            
                            path="/add-location-detail"
                            element={<LocationDetailPage/>}
                            // permissionModule="setting_location_add"
                            permissionModule={emplyeePermissionsAction.SETTING_LOCATION_ADD}
                        />
                        <ProtectedRoute
                            path="/users"
                            element={<EmployeesPage history={undefined} location={location}/>}
                            permissionRequired={true}
                            //permissionModule={permissionsModules.Employee}
                            permissionModule={emplyeePermissionsScreen.SETTING_USER_LIST}
                        />
                        <ProtectedRoute
                            path="/admin-accounts"
                            element={<AdminAccountPage/>}
                            permissionModule={permissionsModules.Accounts}
                        />
                        <ProtectedRoute
                            
                            path="/:accountID/account-detail"
                            element={<AccountDetailPage/>}
                            permissionModule={permissionsModules.Accounts}
                        />
                        <ProtectedRoute
                            
                            path="/add-account-detail"
                            element={<AccountDetailPage/>}
                            permissionModule={permissionsModules.Accounts}
                        />
                        <ProtectedRoute
                            path="/permissions"
                            element={<GroupsPage/>}
                            permissionModule={permissionsModules.Auth}
                        />
                        <ProtectedRoute
                            path="/prospects"
                            element={<ProspectsPage/>}
                            permissionModule={permissionsModules.Prospects}
                        />
                        <ProtectedRoute
                            path="/desk-a-deal"
                            element={<DeskADealPage/>}
                            permissionModule={permissionsModules.Deals}
                        />
                        <ProtectedRoute
                            path="/deals"
                            element={<DealsPage/>}
                            permissionModule={permissionsModules.Deals}
                        />
                        <ProtectedRoute
                            path="/deal-settings"
                            element={<DealSettingsPage/>}
                            permissionModule={permissionsModules.DealSettings}
                        />
                        <ProtectedRoute
                            path="/settings"
                            element={<SettingsPage/>}
                            permissionModule={permissionsModules.Settings}
                        />
                         <Route path="/" element={<Dashboard/>} />
                        <Route path="*" element={<Dashboard/>} />
                    </Routes>
                </MainSection>
                <Stack className={`${location?.pathname === `/DisplayBoardWorkbook` ? "displayNone" : "dms-footer readField"}`}>
                    <ul>
                        <li><img alt="FiQore-White-Logo" className="footerLogo" height="17" width="17" src={`${require("assets/whitelogo.png")}`}></img><span>QoreDMS</span></li>
                        <li><span>Powered by FiQore</span></li>
                        <li><span>515-498-4550</span></li>
                        <li><span>QoreSupport@FiQore.com</span></li>
                    </ul>
                </Stack>
            </ScreenClassProvider>
        </CacheBuster>

    );
}

export default App;
