import {
    TextField as OffTextField,
    PrimaryButton
} from "@fluentui/react";
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-grid-system";
//import { makeStyles, Theme, createStyles } from '@mui/material/styles';
import { PRICE_PREFIX } from "constants/constants";
import { Calendar } from 'primereact/calendar';
import CurrencyInput from 'react-currency-input-field';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "store";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
//import masterDropdownValues from "constants/masterDropdownValues";
import { SaveSuccessDialog } from "components/dialogs/addInventoryDialog/SaveSuccessDialog";
import { useFormik } from "formik";
import moment from "moment";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { handleGetServiceContractList, handleSaveServiceContract, handleUpdateServiceContractItem } from "store/dealTracker/dealTracker.action";
import {
    HANDLE_GET_EMPLOYEES,
} from "store/employees/employees.types";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { convertLocalToUTCModified, convertUTCToLocalDateOnlyModified, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import * as Yup from "yup";
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ParamTypes {
    dealID: string;
}

interface IProps {
    onFormikChange: (initial, newData) => void;
    onDismiss: () => void;
    saveFormOnRoute: boolean;
    dismissSaveNot: (msg) => void;
    formData?: any;
}

const credentialModuleName = "SelectList";
export const ServiceContract: React.FunctionComponent<IProps> = ({
    onFormikChange,
    onDismiss,
    formData,
    saveFormOnRoute,
    dismissSaveNot
}) => {

    const dispatch = useDispatch();
    const params = useParams<ParamTypes|any>();

    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const [saveSuccessDialog, setSaveSuccessDialog] = React.useState(false);
    const [commonServerError, setcommonServerError] = useState(null);
    const financeProducts = useSelector((state: AppState) => state.selectList.financeProducts) as any;
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [employeeList, setemployeeList] = useState([]);
    const [financeProductList, setFinanceProductList] = React.useState([]);
    const [credentials, setCredentials] = useState([]);
    const [initialVal, setinitialVal] = useState(null);
    const [date, setDate] = React.useState<Date | Date[] | undefined | any>(undefined);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);

    useEffect(() => {
        if (saveFormOnRoute) {
            formik.handleSubmit();
            if (!formik.dirty || !formik.isValid) {
                dismissSaveNot('no');
            }
        }
    }, [saveFormOnRoute]);// eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            id: '',
            financeProductId: '',
            closeDate: "",
            referralEmployeeId: "",
            price: "",
            cost: "",
            profit: "",
            deductable: "",
            term: "",
            mileage: "",
            comments: "",
        },
        validationSchema: Yup.object({
            financeProductId: Yup.number().required("Required"),
            closeDate: Yup.string(),
            referralEmployeeId: Yup.string(),
            price: Yup.string(),
            cost: Yup.string(),
            profit: Yup.string(),
            deductable: Yup.string(),
            term: Yup.string(), appraisalConditions: Yup.string(),
            mileage: Yup.string(),
            comments: Yup.string(),
            id: Yup.number()

        }),
        onSubmit: (values) => {
            const data = {
                "id": values.id,
                "financeProductId": values.financeProductId,
                "closeDate": values.closeDate ? convertLocalToUTCModified(values.closeDate) : '',
                "referralEmployeeId": values.referralEmployeeId,
                "price": values.price?.replace(/\$|,/g, ''),
                "cost": values.cost?.replace(/\$|,/g, ''),
                "profit": values.profit?.replace(/\$|,/g, ''),
                "deductable": values.deductable?.replace(/\$|,/g, ''),
                "term": values.term?.replace(/\$|,/g, ''),
                "mileage": values.mileage?.replace(/\$|,/g, ''),
                "comments": values.comments,

            };
            if (data.closeDate === '') {
                delete data.closeDate;
            }

            if (!data.financeProductId) {
                delete data.financeProductId;
            }
            if (formData && formData.id) {
                dispatch<any>(handleUpdateServiceContractItem({
                    formData: data, id: params.dealID, callback: (res) => {
                        if (res) {
                            dismissSaveNot('yes');
                            setSaveSuccessDialog(true);
                            setinitialVal(formik.values);
                            onFormikChange(1, 1);
                        } else {
                            dismissSaveNot('no');
                            setcommonServerError('Failed to Save the Data.');
                            window.scrollTo(0, 0);
                        }
                    }
                }));

            } else {

                dispatch<any>(handleSaveServiceContract({
                    serviceContract: data, id: params.dealID, callback: (res) => {
                        if (res) {
                            dismissSaveNot('yes');
                            setSaveSuccessDialog(true);
                            setinitialVal(formik.values);
                        } else {
                            dismissSaveNot('no');
                            setcommonServerError('Failed to Save the Data.');
                            window.scrollTo(0, 0);
                        }
                    }
                }));
            }
        }
    });

    //const setFormik = (data) => {
    //    let newObj = {
    //        'financeProduct': data.financeProduct,
    //        "closeDate": convertUTCToLocal(data.closeDate),
    //        "referralEmployeeId": data.referral?.id,
    //        "price": data.price,
    //        "cost": data.cost,
    //        "profit": data.profit,
    //        "deductable": data.deductable,
    //        "term": data.term,
    //        "mileage": data.mileage,
    //        "comments": data.comments
    //    }
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            ...newObj
    //        });
    //    setinitialVal(newObj);
    //    setDate(data.closeDate ? new Date(data.closeDate): null);
    //};

    //function dynamicSort(property) {
    //  var sortOrder = 1;

    //  if(property[0] === "-") {
    //      sortOrder = -1;
    //      property = property.substr(1);
    //  }

    //  return function (a,b) {
    //      if(sortOrder === -1){
    //          return b[property].localeCompare(a[property]);
    //      }else{
    //          return a[property].localeCompare(b[property]);
    //      }        
    //  }
    //}

    useEffect(() => {
        if (credentials && user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }

        dispatch<any>(handleGetServiceContractList(params.dealID));

        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1, role: "financePerson" } })
        dispatch<any>(handleGetSelectList("financeProducts", 1));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        setFinanceProductList(financeProducts);

    }, [financeProducts]);


    useEffect(() => {

        if (formData) {

            let newObj = {
                'id': formData.id,
                'financeProductId': formData.financeProduct?.id,
                "closeDate": convertUTCToLocalModified(formData.closeDate),
                "referralEmployeeId": formData.referral?.id,
                "price": formData.price,
                "cost": formData.cost,
                "profit": formData.profit,
                "deductable": formData.deductable,
                "term": formData.term,
                "mileage": formData.mileage,
                "comments": formData.comments,
            };

            //
            formik.setValues(
                {
                    ...formik.values,
                    ...newObj
                });
            setinitialVal(newObj);
            setDate(formData.closeDate ? new Date(convertUTCToLocalDateOnlyModified(formData.closeDate)) : null);
        }
    }, [formData]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formik.values) {
            onFormikChange(initialVal, formik.values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values, initialVal]);

    useEffect(() => {
        console.log(' Employeee', employees)
        if (employees) {
            //
            if (employees.results.length > 0) {
                console.log('Appraisal Employeee', employees.results)
                handleFullnameEmployees();
            }
            else {
                setemployeeList([]);
            }
        }
    }, [employees]);// eslint-disable-line react-hooks/exhaustive-deps



    const handleFullnameEmployees = () => {
        let results = employees.results;
        for (var i in results) {
            //
            results[i]["fullName"] = results[i].firstName + ' ' + results[i].lastName;

        }
        if (!formData.id) {
            const salesPersonId = user && user.defaultGroups
                && user.defaultGroups.some(x => x.defaultGroupId === 8)//Finance Person
                && results && results.some(x => x.id === user.id) && user.id;
            formik.setValues(
                {
                    ...formik.values,
                    'referralEmployeeId': salesPersonId
                });

            setinitialVal(previousState => {
                return { ...previousState, referralEmployeeId: salesPersonId }
            });
        }

        setemployeeList(results);
    };

    const handleformikDDChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    const handleformikRefferalDDChange = (e: { value: any, target: any }) => {
        formik.setValues(
            {
                ...formik.values,
                [e.target.id]: e.value,
            }
        );
    }

    const handleDateChange = (val) => {
        formik.setValues(
            {
                ...formik.values,
                "closeDate": moment(val).format("YYYY-MM-DD")
            }
        );
        setDate(val)
    };
    const handleFormikSumbit = () => {
        setcommonServerError('');
        formik.handleSubmit();
        onDismiss();
    }

    const handleFormikDismiss = () => {
        onDismiss();
        dismissSaveNot('yes');
    }

    return (<>

        {saveSuccessDialog ?
            <SaveSuccessDialog
                onDismiss={() => { setSaveSuccessDialog(false); }}
            /> : null}


        <div className=" serviceContractUIWidth">


            {
                commonServerError ?
                    <Row><Col>
                        <p className="genericErrorColor text-left"><span>{commonServerError}</span></p>
                    </Col> </Row> : null
            }
            {/*<Row className="rowSpace rowSpaceHeight "  >
                                       <Col>
                                               <Link onClick={onDismiss} ><ArrowBackIcon /><span className="arrow-back-text">Back</span></Link>
                                       </Col>
                                </Row>*/}

            <Row>

                <Col>

                    <div>
                        <Row className="rowSpace rowSpaceHeight ">
                            <Col md={6}>

                                {/*    <label className="lbl-text required-Label">Finance Product</label>
                                                                  <PRDropdown
                                                                        id="financeProduct"
                                                                        placeholder=""
                                                                        value={formik.values.financeProduct}
                                                                        options={masterDropdownValues.financeProductValue}
                                                                        onChange={handleformikDDChange}
                                                                        optionLabel="text"
                                                                        optionValue="key"
                                                                        appendTo="self"
                                                                        className="custom-p-dropdown"
                                                                        filter={false}
                                                                        resetFilterOnHide={true}
                                                                        scrollHeight="200px"
                                                                  /> */}

                                <label className="lbl-text">Finance Person</label>
                                <PRDropdown
                                    id="referralEmployeeId"
                                    placeholder=""
                                    value={formik.values.referralEmployeeId}
                                    options={employeeList}
                                    onChange={handleformikRefferalDDChange}
                                    optionLabel="fullName"
                                    optionValue="id"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={true}
                                    resetFilterOnHide={true}
                                    scrollHeight="200px"
                                />
                            </Col>


                        </Row>

                        <Row className="rowSpace  rowSpaceHeight   mainQValuPageSec" >
                            <Col md={6}>
                                {/*  <label className="lbl-text "> Refferal</label>
                                                                        <PRDropdown
                                                                              id="referralEmployeeId"
                                                                              placeholder=""
                                                                              value={formik.values.referralEmployeeId}
                                                                              options={employeeList}
                                                                              onChange={handleformikRefferalDDChange}
                                                                              optionLabel="fullName"
                                                                              optionValue="id"
                                                                              appendTo="self"
                                                                              className="custom-p-dropdown"
                                                                              filter={false}
                                                                              resetFilterOnHide={true}
                                                                              scrollHeight="200px"
                                                                        /> */}
                                <label className="lbl-text required-Label">Finance Product</label>
                                <PRDropdown
                                    id="financeProductId"
                                    placeholder=""
                                    value={formik.values.financeProductId}
                                    options={financeProductList}
                                    onChange={handleformikDDChange}
                                    optionLabel="name"
                                    optionValue="id"
                                    // appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                    scrollHeight="200px"
                                />
                            </Col>
                            <Col md={6}>
                                <div className="ms-TextField">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text ">Close Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup">
                                            <Calendar
                                                id="closeDate"
                                                style={{ width: "100%" }}
                                                value={date}
                                                showIcon
                                                onChange={(e) => handleDateChange(e.value)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange} />
                                        </div>
                                        {/*<span className="error-message">
									                                 			{blurFlag && formik.errors && formik.errors.dealerLicenseDateOfIssue &&
									                                 				formik.errors.dealerLicenseDateOfIssue.toString()}
									                                 		</span>*/}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>

                    <div style={{ paddingTop: "14px", paddingBottom: "8px" }} >
                        <Row className="rowSpace  rowSpaceHeight   mainQValuPageSec" justify="center" >
                            <Col md={6} >
                                <div className="serviceContractSubHeadingalign">
                                    <Row >
                                        <Col>
                                            <span> Economics</span>
                                        </Col>

                                    </Row>

                                </div>
                                <div className="serviceConSubLeftRight">
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text required-Label">Price</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                id="price"
                                                name="price"
                                                //label="Price"
                                                decimalScale={2}
                                                value={formik.values.price?formik.values.price.replace(/,/g, ''):''}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "price": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text required-Label">Cost</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                id="cost"
                                                name="cost"
                                                //label="Cost"
                                                decimalScale={2}
                                                value={formik.values.cost?formik.values.cost.replace(/,/g, ''):''}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "cost": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text required-Label">Profit</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                id="profit"
                                                name="profit"
                                                //label="Profit"
                                                decimalScale={2}
                                                value={formik.values.profit?formik.values.profit.replace(/,/g, ''):""}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "profit": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>

                                </div>
                            </Col>
                            <Col md={6} className="serviceContractSubTopHeight">
                                <div className="serviceContractSubHeadingalign">
                                    <Row >
                                        <Col>
                                            <span> Details</span>
                                        </Col>

                                    </Row>

                                </div>
                                <div className="serviceConSubLeftRight">
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text required-Label">Deductible</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                prefix={PRICE_PREFIX}
                                                id="deductable"
                                                name="deductable"
                                                //label="Deductible"
                                                decimalScale={2}
                                                value={formik.values.deductable?formik.values.deductable.replace(/,/g, ''):""}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "deductable": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text required-Label">Term</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                //prefix={PRICE_PREFIX}
                                                id="term"
                                                name="term"
                                                //label="Term"
                                                decimalScale={2}
                                                value={formik.values.term?formik.values.term.replace(/,/g, ''):""}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "term": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <label className="lbl-text">Mileage</label>
                                            <CurrencyInput
                                                allowNegativeValue={false}
                                                customInput={OffTextField}
                                                // thousandSeparator={true}
                                                //prefix={PRICE_PREFIX}
                                                id="mileage"
                                                name="mileage"
                                                //label="Mileage"
                                                decimalScale={2}
                                                value={formik.values.mileage?formik.values.mileage.replace(/,/g, ''):""}
                                                onValueChange={(e) => {
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            "mileage": e
                                                        }
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />

                                        </Col>

                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div >
                        <Row className="rowSpace  rowSpaceHeight   mainQValuPageSec" justify="center" >
                            <Col md={12} className="appraisal-box" style={{ paddingTop: "15px", minHeight: "140px" }}>
                                <OffTextField
                                    id="comments"
                                    name="comments"
                                    label="Comments"
                                    multiline
                                    resizable={false}
                                    className="full-width appraisal-conditions fullHeight"
                                    value={formik.values.comments}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Col>
                        </Row>

                    </div>

                    <Row className="rowSpace cancel-SaveBtn cancellation-SaveBtn" >
                        <Col style={{ float: "left", width: "auto" }}>
                            <PrimaryButton
                                //disabled={!formik.dirty || !formik.isValid}
                                onClick={handleFormikDismiss}
                            >
                                Cancel
                            </PrimaryButton>
                        </Col>

                        <Col>
                            <PrimaryButton
                                disabled={!formik.dirty || !formik.isValid}
                                onClick={handleFormikSumbit}
                                style={{ float: "right" }} >
                                Save
                            </PrimaryButton>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>

    </>);
};

