import React, { FunctionComponent,useEffect}from "react";
import {
    Modal,
    PrimaryButton,
    IconButton
} from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { CustomFontText } from "components/customFontText/CustomFontText";


const contentStyles = content(600, "auto");

interface IProps {
    onDismiss?: () => void;
    isOpen?: boolean;
    transferDetails?: any;
    warningClose?: () => void;
}

export const TransferWarningDialog: FunctionComponent<IProps> = ({
    isOpen,
    onDismiss,
    transferDetails,
    warningClose

}) => {

const [isType, setIsType] = React.useState(false);

useEffect(()=>{
    if(transferDetails?.dealNumber){
        setIsType(false);
    }else{
        setIsType(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

const handleClose =()=>{
    //onDismiss();
    warningClose();
}

    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            isBlocking={true}
            containerClassName={contentStyles.container + " custom-modal"}
            className="vehicleFoundDialog dealFoundDiaHeight"
        >
            <div className={contentStyles.header + " inventory-popup"}>
                <CustomFontText className="formTitle">Vehicle Transfer Warning</CustomFontText>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={onDismiss}
                />
            </div>
            {isType ?
                <div className="dealRecFound body-auto-hight body-min-height-50">
                    <div style={{ marginLeft: "25px",marginTop: "-25px",}}>
                        <div style={{textAlign:"left"}}>
                            <label className="lbl-text">This Vehicle is currently transferred to another location.</label>
                        </div>
                        <div style={{textAlign:"left"}}>
                            <label className="lbl-text">Please check Vehicle Tracker to confirm availability.</label>
                        </div>
                    </div>
                </div>  :

                <div className="dealRecFound body-auto-hight body-min-height-50">
                    <div style={{ marginLeft: "25px",marginTop: "-25px",}}>
                        <div style={{textAlign:"left"}}>
                            <label className="lbl-text">This Vehicle is currently has an Pending or recently Closed Write-up </label>
                        </div>
                        <div style={{textAlign:"left"}} >
                            <label className="lbl-text"> Deal : {transferDetails && transferDetails.locationName } - {transferDetails && transferDetails.dealNumber }</label>
                        </div>
                        <div style={{textAlign:"left"}} >
                            <label className="lbl-text">Please check Deal Tracker to confirm availability.</label>
                        </div>
                    </div>
                </div>
            }
            
            <div className={contentStyles.footer + " inventory-popup-footer"}>
                <div style={{ textAlign: "center",marginTop:"-15px" }}>
                    <PrimaryButton
                        text="Close"
                        onClick={handleClose}
                    />
                </div>
            </div>
        </Modal>
    );
};



