import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    TextField,
} from "@fluentui/react";
import {
    PrimaryButton
} from "@fluentui/react";
import {
    //useDispatch,
    useSelector
} from "react-redux";
import { AppState } from "store/index";
//import {
//    getModulePermissions,
//    isUserMaster
//} from "utils/permissions/permissionsHelpers";
//import { emplyeePermissionsAction } from "../../constants/constants";
import { useDispatch } from "react-redux";
import CustomTable from "components/table/datatable/CustomTable";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetDealsSummary } from "store/dealTracker/dealTracker.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import {
    useNavigate ,
    useLocation
} from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useMediaQuery } from "react-responsive";
import { convertUTCToLocalDateOnly, convertUTCToLocalModified } from "utils/dateTimeFunctions";
import moment from "moment";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

//const credentialModuleName = "DealTracker";
export const UniversalSearchWorkbookPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const [tableData, setTableData] = useState([]);
    const [deals, setDeals] = useState([]);
    const [commonServerError, setCommonServerError] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;
    const navigate = useNavigate ();
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const isMobile = useMediaQuery({ maxWidth: 960 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    //const [credentials, setCredentials] = useState([]);

    const [glFilter, setGLFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState(0);
    const [logDateFromFilter, setLogDateFromFilter] = React.useState<Date | undefined>(undefined);
    const [logDateToFilter, setlogDateToFilter] = React.useState<Date | undefined>(undefined);
    const [tblEmptyMsg, setTableStatus] = useState('Data loading...');

    const handleSearchChange = (val: any) => {
        setGLFilter(val.target.value);
        handleFilters(val.target.value, locationFilter, logDateFromFilter, logDateToFilter);
    };

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
        handleFilters(glFilter, e.value, logDateFromFilter, logDateToFilter);
    }

    const handleFromLogDateChange = (val: any) => {
        setLogDateFromFilter(val.target.value)
        handleFilters(glFilter, locationFilter, val.target.value, logDateToFilter);
    };

    const handleToLogDateChange = (val: any) => {
        setlogDateToFilter(val.target.value)
        handleFilters(glFilter, locationFilter, logDateFromFilter, val.target.value);
    };

    const handleFilters = (glFilter, locationFilt, logDateFromFilter, logDateToFilter) => {

        if (glFilter || locationFilt || logDateFromFilter || logDateToFilter) {

            let results = deals;
            if (results) {

                if (glFilter) {
                    const filterVal = glFilter.toLowerCase();
                    results = results.filter(x =>
                        x.dealNumber.toLowerCase().includes(filterVal) ||
                        (x.customerName &&
                            (
                                x.customer.customerNumber.toLowerCase().includes(filterVal) ||
                                x.customerName.toLowerCase().includes(filterVal)
                            )
                        ) ||
                        (x.vehicle &&
                            (
                                (x.vehicle.orderNumber && x.vehicle.orderNumber.toLowerCase().includes(filterVal)) ||
                                (x.vehicle.stockNumber && x.vehicle.stockNumber.toLowerCase().includes(filterVal)) ||
                                (x.vehicle.vin && x.vehicle.vin.toLowerCase().includes(filterVal))
                            )
                        ) ||
                        (x.sales1 && x.sales1.toLowerCase().includes(filterVal)) ||
                        (x.sales2 && x.sales2.toLowerCase().includes(filterVal)) ||
                        (x.finance1 && x.finance1.toLowerCase().includes(filterVal)) ||
                        (x.finance2 && x.finance2.toLowerCase().includes(filterVal))
                    );
                }

                /** location **/
                if (locationFilt > 0) {
                    results = results.filter(x => x.location.id === locationFilt);
                }

                /** Log Filter **/
                if (logDateFromFilter) {
                    //results = results.filter(x => new Date(x.logDate) >= new Date(logDateFromFilter));
					results = results.filter(x => convertUTCToLocalModified(new Date(x.logDate)) >= convertUTCToLocalModified(new Date(logDateFromFilter)));
                }
                if (logDateToFilter) {
                    //results = results.filter(x => new Date(x.logDate) <= new Date(logDateToFilter));
					results = results.filter(x => convertUTCToLocalModified(new Date(x.logDate)) <= convertUTCToLocalModified(new Date(logDateToFilter)));
                }

                // expandableTableRows(results);
                setTableData(results);
            }

        } else {
            // expandableTableRows(vehicleList);
            setTableData(deals);
        }
    }

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
        //console.log('locationslocationslocations',locations)   
    }, [locations]);

    useEffect(() => {
        if (deals) {
            handlePopulateData();
        }  
    }, [deals]);// eslint-disable-line react-hooks/exhaustive-deps


    const handlePopulateData = () => {
        
        for (var i in deals) {
            let closedDate = '';
            if (deals[i].dealDelivery && deals[i].dealDelivery.closedDate) {
                let rawClosedDate = deals[i].dealDelivery.closedDate;
                closedDate = rawClosedDate.indexOf("T") ? rawClosedDate.substr(0,rawClosedDate.indexOf("T")) : rawClosedDate;
            }

            deals[i]["logDate"] = convertUTCToLocalDateOnly(deals[i].createdAt);
            deals[i]["closedDate"] = closedDate ? moment(closedDate).format("MM/DD/YY") :'';
            deals[i]["locationName"] = deals[i].location?.legalName;
            deals[i]["customerName"] = deals[i].customer ?
                deals[i].customer.customerType === 1 ?
                    deals[i].customer.firstName + ' ' + deals[i].customer.lastName :
                    deals[i].customer.businessName
                : null;
            if (deals[i].vehicle) {

                deals[i]["yearModel"] = deals[i].vehicle.year + ' ' + deals[i].vehicle.model;
                if (deals[i].vehicle.stockNumber || deals[i].vehicle.orderNumber) {
                    deals[i]["stockOrder"] = (deals[i].vehicle.stockNumber ? deals[i].vehicle.stockNumber : '--')
                        + ' / ' +
                        (deals[i].vehicle.orderNumber ? deals[i].vehicle.orderNumber : '--');
                }

            }
            deals[i]["sales1"] = getPersonName(deals[i].salesPerson1);
            deals[i]["sales2"] = getPersonName(deals[i].salesPerson2);
            deals[i]["finance1"] = getPersonName(deals[i].financePerson1);
            deals[i]["finance2"] = getPersonName(deals[i].financePerson2);
            deals[i]["haveAppointments"] = deals[i].dealAppointments && deals[i].dealAppointments.length > 0;
            deals[i]["haveTradeIns"] = deals[i].dealTradeIns && deals[i].dealTradeIns.length > 0;
            deals[i]["haveCancels"] = deals[i].dealCancellations && deals[i].dealCancellations.length > 0;
            deals[i]["haveTasks"] = deals[i].dealTasks && deals[i].dealTasks.length > 0;
            deals[i]["haveWriteups"] = deals[i].dealDelivery && deals[i].dealDelivery.id > 0;
            deals[i]["haveContractService"] = deals[i].dealServiceContracts && deals[i].dealServiceContracts.length > 0;
        }
        setTableData(deals);
    };

    const getPersonName = (salesPerson) => {
        if (salesPerson) {
            return salesPerson?.firstName + ' ' + salesPerson?.lastName;
        }
        return '';
    };

    useEffect(() => {
        //if (user && user.auths && !isUserMaster(user.roles)) {
        //    setCredentials(getModulePermissions(user.auths, credentialModuleName));
        //}
        dispatch<any>(handleGetDealsSummary({
            callback: (res) => {
                setTableStatus('No deals found.');
                if (res) {
                    setDeals(res.results);
                } else {
                    setTableData([]);
                    setCommonServerError('Failed to Fetch the Data.');
                }
            }
        }));
        dispatch<any>(handleGetLocations());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleResetWorkbook = () => {
        setGLFilter(null);
        setLocationFilter(0);
        setLogDateFromFilter(null);
        setlogDateToFilter(null);
        setTableData(deals);
    };

    //Datatable Templates
    const header = (
        <>
            {isMobile ?
                <div>
                    <Row>
                        <Col style={{ marginTop: "2px" }}>
                            <div className="employeeeSearchLabelText">
                                <TextField
                                    value={glFilter}
                                    type="search"
                                    label="Search Deals"
                                    className="ownerSearchText"
                                    onChange={(e) => handleSearchChange(e)}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="locationDropPosition">
                                <label className="lbl-text">Location</label>
                                <Dropdown
                                    id="locationId"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={onLocationChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ width: "auto" }} >
                        <Col>
                            <div className="date-filter-section">
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Log Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateFromFilter}
                                                showIcon
                                                onChange={(e) => handleFromLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                maxDate={logDateToFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                <div className="table-header">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">&nbsp;</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                            <Calendar
                                                id="toLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateToFilter}
                                                showIcon
                                                onChange={(e) => handleToLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                                minDate={logDateFromFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px", maxWidth: "40%" }} >
                            <PrimaryButton style={{ float: "right" }}
                                onClick={handleResetWorkbook}
                            >
                                Reset
                               </PrimaryButton>
                        </Col>
                    </Row>
                </div> :
                <div>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={3}>
                                    <div className="table-header employeeeSearchLabelText">
                                        <TextField
                                            value={glFilter}
                                            type="search"
                                            label="Search Deals"
                                            className="ownerSearchText"
                                            onChange={(e) => handleSearchChange(e)}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="dd-control">
                                        <label className="lbl-text">Location</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={locationFilter}
                                            options={cityDealerList}
                                            onChange={onLocationChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Log Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="fromLogDate"
                                                        style={{ width: "100%" }}
                                                        value={logDateFromFilter}
                                                        showIcon
                                                        onChange={(e) => handleFromLogDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={logDateToFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="toLogDate"
                                                        style={{ width: "100%" }}
                                                        value={logDateToFilter}
                                                        showIcon
                                                        onChange={(e) => handleToLogDateChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={logDateFromFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={1} >
                                    <div className="table-header employeeeSearchLabelText" style={{ marginTop: "24px" }}>
                                        <PrimaryButton
                                            onClick={handleResetWorkbook}
                                        >
                                            Reset
                               </PrimaryButton>
                                    </div>

                                </Col>

                            </Row>
                        </Col>
                        <Col>

                        </Col>

                    </Row>

                </div>}

        </>
    );


    const logDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Log Date</span>
                {moment(new Date(rowData.logDate)).local().format('MM/DD/YY')}
            </React.Fragment>
        );
    };

    const closedDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Closed Date</span>
                {rowData.closedDate}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales</span>
                {rowData.sales1}
                <br />
                {rowData.sales2}
            </React.Fragment>
        );
    };

    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance</span>
                {rowData.finance1}
                <br />
                {rowData.finance2}
            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData.dealNumber}
            </React.Fragment>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Location</span>
                {rowData.locationName}
            </React.Fragment>
        );
    };

    const custNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Name</span>
                {rowData.customerName}
            </React.Fragment>
        );
    };

    const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle<br />Stock # / Order #</span>
                {rowData.yearModel}
                <br />
                {rowData.stockOrder}
            </React.Fragment>
        );
    }

    const tasksBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Tasks</span>
                {
                    rowData.haveTasks && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const cancellationsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Cancels</span>
                {
                    rowData.haveCancels && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const tradeInsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trade-ins</span>
                {
                    rowData.haveTradeIns && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const appointmentsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Appt</span>
                {
                    rowData.haveAppointments && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const writesUpsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Write-ups</span>
                {
                    rowData.haveWriteups && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };

    const afterSaleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">After Sale</span>
                {
                    rowData.haveContractService && (<i className="pi pi-check"></i>)
                }
            </React.Fragment>
        );
    };


    //const handleEditAppointment = (id) => {
    //    // navigate(`/${id}/dealtracker`, { tabIndex: 1 });
    //    navigate({
    //        pathname: `/${id}/dealtracker`,
    //        state: {
    //            searchInput: glFilter,
    //            pathfrom: '/appointments',
    //            tabIndex: 1,
    //            // sortOrder,
    //            // sortField
    //        }
    //    });
    //};

    const handleRowClick = (event) => {
        let target = event.originalEvent.target;
        if (target.nodeName === 'TD' && target.hasChildNodes() && target.lastElementChild.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
            //const role = isUserMaster(user.roles);
            //const cred = user?.aggregatedDefaultPolicies ? user.aggregatedDefaultPolicies.find(element => element === (emplyeePermissionsAction.SETTING_USER_UPDATE?.toString())) : true;
            //if (cred || role) {
                const  state = {
                    searchInput: glFilter,
                    pathfrom: '/search-inventory',
                    tabIndex: 0,
                    // sortOrder,
                    // sortField
                }
                navigate(`/${event.data.id}/dealtracker`,{state});
            //}
        }
    };

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setCommonServerError(serverError.detail);
            } else {
                setCommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setCommonServerError(null);
        }
    }, [serverError]);

    useEffect(() => {
        const stateVal: any = location?.state
        if (stateVal && stateVal.searchInput) {
            setGLFilter(stateVal.searchInput);
        }
        if (stateVal?.sortField) {
            // setSortField(stateVal.sortField);
            // setSortOrder(stateVal.sortOrder);
        }
    }, [location]);

    const table_columns = {
        "logDate": "Log Date",
        "dealNumber": "Deal Number",
        "locationName": "Location",
        "customerName": "Customer Name",
        "vehicleInfo": "Vehicle \nStock #/Order #",
        "closedDate": "Closed Date",
        "salesPerson": "Sales",
        "financePerson": "Finance",
        "writeUps": "Write-ups",
        "appointments": "Appt",
        "tradeIns": "Trade-ins",
        "cancellations": "Cancels",
        "tasks": "Tasks",
        "afterSale": "After Sale",
    }

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont">Search All</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable universal-search-workbook"> {/* locations */}
                    <div className="card ">
                        <CustomTable
                            column_list={table_columns}
                            data={tableData}
                            header={header}
                            rows={25}
                            rowsPerPageOptions={[25,50,100]}
                            customTemplates={{
                                "logDate": logDateBodyTemplate,
                                "dealNumber": dealNumberBodyTemplate,
                                "locationName": locationBodyTemplate,
                                "customerName": custNameBodyTemplate,
                                "vehicleInfo": vehicleBodyTemplate,
                                "closedDate": closedDateBodyTemplate,
                                "salesPerson": salesPersonBodyTemplate,
                                "financePerson": financePersonBodyTemplate,
                                "writeUps": writesUpsBodyTemplate,
                                "appointments": appointmentsBodyTemplate,
                                "tradeIns": tradeInsBodyTemplate,
                                "cancellations": cancellationsBodyTemplate,
                                "tasks": tasksBodyTemplate,
                                "afterSale": afterSaleBodyTemplate,
                            }}
                            emptyMessage={ tblEmptyMsg}
                            onRowClick={(event) => handleRowClick(event)}
                        />
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
