import React, {FunctionComponent, useState} from "react";
import {
  DefaultButton,
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  TextField,
  IModalStyles,
} from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import AddProspectToDealDialog from "components/dialogs/deals/AddProspectToDealDialog";
import GoBackToMainDialogBtn from "components/dialogs/addInventoryDialog/GoBackToMainDialogBtn";
import { AddNewCosignersToDeal } from "components/dialogs/deals/AddNewCosignersToDeal";
import { IProspect } from "../../../types/prospectTypes";
import { FormikValues } from "formik";
import { ProspectType } from 'constants/constants'



const cancelIcon: IIconProps = { iconName: "Cancel" };



const modalStyles: Partial<IModalStyles> = {
  keyboardMoveIcon: undefined,
  keyboardMoveIconContainer: undefined,
  layer: undefined,
  main: undefined,
  scrollableContent: undefined,
};

interface IProps {
  onDismiss: () => void;
  onClose: () => void;
  onShowEditProspect: (prospectType: ProspectType, index:  0 | 1) => void;
  onShowAddFromProspect: () => void;
  onCosignersUpdate: (cosigners: IProspect[]) => void;
  formik?: FormikValues;
}

export const CosignersDialog: FunctionComponent<IProps> = ({
  onDismiss,
  onClose,
  onShowEditProspect,
  formik,
}) => {
  const [showAddFromCosigner, setShowAddFromCosigner] = useState(false);
  const [currentCosigner, setCurrentCosigner] = useState(null);

  const [
    showAddProspectToDealDialog,
    setShowAddProspectToDealDialog,
  ] = useState(false);

  const [isVisibleAddNewCosignersToDeal, setIsAddNewCosignersToDeal] = useState(
    false
  );


  const handleShowAddNewCosignersToDeal = (currCosigner) => {
    setIsAddNewCosignersToDeal(true);
    setCurrentCosigner(currCosigner);
  };

  const handleShowEditDialog = (order) => {
    console.log("order", order);
    
    console.log(formik.values.cosigners);
    
    const index = order === "first" ? 0 : 1;

    if (formik.values.cosigners[index].type === ProspectType.Prospect) {
      onShowEditProspect(ProspectType.Prospect, index);
    } else if (formik.values.cosigners[index].type === ProspectType.Cosigner) {
      onShowEditProspect(ProspectType.Cosigner, index);
    }
  };

  return (
    <>
      {isVisibleAddNewCosignersToDeal && (
        <AddNewCosignersToDeal
          onDismiss={() => setIsAddNewCosignersToDeal(false)}
          setCosigner={(prospect: IProspect) => {
            console.log("SELECTED Prospect", prospect, formik.values);
            setShowAddProspectToDealDialog(false);
            // updateProspect(prospect);

            formik.setFieldValue("cosigners", [
              {
                ...(currentCosigner === 0
                  ? prospect
                  : formik.values.cosigners[0]),
              },
              currentCosigner === 1 && !formik.values.cosigners[1]
                ? {
                    ...(currentCosigner === 1
                      ? prospect
                      : formik.values.cosigners[1]),
                  }
                : null,
            ]);
            //se
            //setSecondCosigner(prospect);
          }}
        />
      )}

      {showAddProspectToDealDialog && (
        <AddProspectToDealDialog
          onDismiss={() => setShowAddProspectToDealDialog(false)}
          // onDismiss={() => setStepForm(FormStep.None)}
          onProspectSelection={(prospect: IProspect) => {
            console.log("SELECTED Prospect", prospect, formik.values);
            setShowAddProspectToDealDialog(false);
            // updateProspect(prospect);

            formik.setFieldValue("cosigners", [
              {
                ...(currentCosigner === 0
                  ? prospect
                  : formik.values.cosigners[0]),
              },
              currentCosigner === 1 && !formik.values.cosigners[1]
                ? {
                    ...(currentCosigner === 1
                      ? prospect
                      : formik.values.cosigners[1]),
                  }
                : null,
            ]);
            //se
            //setSecondCosigner(prospect);
          }}
          type={1}
        />
      )}

      {showAddFromCosigner && (
        <AddProspectToDealDialog
          onDismiss={() => setShowAddFromCosigner(false)}
          // onDismiss={() => setStepForm(FormStep.None)}
          onProspectSelection={(cosigner: IProspect) => {
            console.log("SELECTED COSIGNER", cosigner, formik.values);
            console.log("current cosigner", currentCosigner);
            setShowAddFromCosigner(false);
            // updateProspect(prospect);

            formik.setFieldValue("cosigners", [
              {
                ...(currentCosigner === 0
                  ? cosigner
                  : formik.values.cosigners[0]),
              },
              currentCosigner === 1 && !formik.values.cosigners[1]
                ? {
                    ...(currentCosigner === 1
                      ? cosigner
                      : formik.values.cosigners[1]),
                  }
                : null,
            ]);
            //setSecondCosigner(prospect);
          }}
          type={2}
        />
      )}

      <Modal
        isOpen={true}
        onDismiss={onDismiss}
        isBlocking={true}
        styles={modalStyles}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          {/*<CustomFontText>Assign Co-Signers to Deal</CustomFontText>*/}
          <GoBackToMainDialogBtn
            label="Assign Co-Signers to Deal"
            onClick={onDismiss}
          />
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <div className={contentStyles.body}>
          <Stack>
            <span className={contentStyles.label}>
              Assigned First Co-Signer
            </span>
            <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
              {/*<Stack.Item grow={3}>*/}
              <div style={{ flex: 4 }}>
                <TextField
                  id="assignedProspect"
                  name="assignedProspect"
                  // label="Assigned Prospect"
                  value={
                    formik.values.cosigners[0] !== null &&
                    formik.values.cosigners[0].firstName &&
                    (formik.values.cosigners[0] as any).firstName
                  }
                  disabled
                />
              </div>
              {/*</Stack.Item>*/}
              {/*<Stack.Item grow={1}>*/}

              {formik.values.cosigners[0] !== null && (
                <DefaultButton
                  style={{ flex: 2 }}
                  // disabled={!formik.dirty || !formik.isValid}
                  iconProps={{ iconName: "RedEye" }}
                  text="View Detail"
                  onClick={() => handleShowEditDialog("first")}
                />
              )}

              {/*</Stack.Item>*/}
            </Stack>

            {formik.values.cosigners[0] !== null && (
              <Stack.Item>
                <DefaultButton
                  style={{ marginTop: 10 }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "Delete" }}
                  text="Remove"
                  onClick={() => {
                    formik.setFieldValue("cosigners", [
                      null,
                      formik.values.cosigners[1],
                    ]);
                    setCurrentCosigner(null);
                    if (formik.values.cosigners[1]) {
                      formik.setFieldValue("cosigners", [
                        formik.values.cosigners[1],
                        null,
                      ]);
                    }
                  }}
                />
              </Stack.Item>
            )}

            {formik.values.cosigners[0] === null && (
              <>
                <PrimaryButton
                  style={{ marginTop: 10 }}
                  // disabled={!formik.dirty || !formik.isValid}
                  // onRenderIcon={renderSpinner}
                  iconProps={{ iconName: "CircleAdditionSolid" }}
                  text="Add New Co-Signer"
                  onClick={() => handleShowAddNewCosignersToDeal(0)}
                />
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <DefaultButton
                    className={contentStyles.buttonStyles}
                    // disabled={!formik.dirty || !formik.isValid}
                    // onRenderIcon={renderSpinner}
                    iconProps={{ iconName: "CircleAdditionSolid" }}
                    text="Add From Co-Signer"
                    // onClick={handleAddEmployee}
                    onClick={() => {
                      setShowAddFromCosigner(true);
                      setCurrentCosigner(0);
                    }}
                  />
                  <DefaultButton
                    className={contentStyles.buttonStyles}
                    // disabled={!formik.dirty || !formik.isValid}
                    // onRenderIcon={renderSpinner}
                    iconProps={{ iconName: "CircleAdditionSolid" }}
                    text="Add From Prospects"
                    // onClick={handleAddEmployee}
                    onClick={() => {
                      setShowAddProspectToDealDialog(true);
                      setCurrentCosigner(0);
                    }}
                  />
                </Stack>
              </>
            )}

            {formik.values.cosigners[0] !== null && (
              <>
                <span className={contentStyles.label}>
                  Assigned Second Co-Signer
                </span>
                <Stack
                  horizontal
                  verticalAlign="end"
                  tokens={{ childrenGap: 10 }}
                >
                  {/*<Stack.Item grow={3}>*/}
                  <div style={{ flex: 4 }}>
                    <TextField
                      id="assignedVehicle"
                      name="assignedVehicle"
                      // label="Assigned Vehicle"
                      value={
                        formik.values.cosigners[1]
                          ? formik.values.cosigners[1].firstName
                          : ""
                      }
                      disabled
                    />
                  </div>
                  {Boolean(formik.values.cosigners[1]) && (
                    <DefaultButton
                      style={{ flex: 2 }}
                      // disabled={!formik.dirty || !formik.isValid}
                      iconProps={{ iconName: "RedEye" }}
                      text="View Detail"
                      onClick={() => handleShowEditDialog("second")}
                    />
                  )}
                </Stack>

                {Boolean(formik.values.cosigners[1]) && (
                  <Stack.Item>
                    <DefaultButton
                      style={{ marginTop: 10 }}
                      // disabled={!formik.dirty || !formik.isValid}
                      // onRenderIcon={renderSpinner}
                      iconProps={{ iconName: "Delete" }}
                      text="Remove"
                      onClick={() => {
                        formik.setFieldValue("cosigners", [
                          formik.values.cosigners[0],
                          null,
                        ]);
                        setCurrentCosigner(0);
                      }}
                    />
                  </Stack.Item>
                )}
                {!Boolean(formik.values.cosigners[1]) && (
                  <>
                    <PrimaryButton
                      style={{ marginTop: 10 }}
                      // disabled={!formik.dirty || !formik.isValid}
                      // onRenderIcon={renderSpinner}
                      iconProps={{ iconName: "CircleAdditionSolid" }}
                      text="Add New Co-Signer"
                      onClick={() => handleShowAddNewCosignersToDeal(1)}
                    />
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <DefaultButton
                        className={contentStyles.buttonStyles}
                        // disabled={!formik.dirty || !formik.isValid}
                        // onRenderIcon={renderSpinner}
                        iconProps={{ iconName: "CircleAdditionSolid" }}
                        text="Add From Co-Signer"
                        // onClick={handleAddEmployee}
                        onClick={() => {
                          setShowAddFromCosigner(true);
                          setCurrentCosigner(1);
                        }}
                      />
                      <DefaultButton
                        className={contentStyles.buttonStyles}
                        // disabled={!formik.dirty || !formik.isValid}
                        // onRenderIcon={renderSpinner}
                        iconProps={{ iconName: "CircleAdditionSolid" }}
                        text="Add From Prospects"
                        // onClick={handleAddEmployee}
                        onClick={() => {
                          setShowAddProspectToDealDialog(true);
                          setCurrentCosigner(1);
                        }}
                      />
                    </Stack>
                  </>
                )}
              </>
            )}
          </Stack>
        </div>

        <div className={contentStyles.footer}>
          <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
            <DefaultButton text="Close" onClick={onClose} />
            <PrimaryButton
              // disabled={!formik.dirty || !formik.isValid}
              // onRenderIcon={renderSpinner}

              text="Back to Deal"
              onClick={onDismiss}
            />
          </Stack>
        </div>
      </Modal>
    </>
  );
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    width: 600,
  },
  buttonStyles: {
    marginTop: 10,
    width: "100%",
  },
  header: {
    flex: "1 1 auto",
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "20px 30px",
  },

  body: {
    height: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: "40px",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  label: {
    fontWeight: 600,
    paddingTop: 15,
    paddingBottom: 5,
  },
  footer: {
    padding: "0px 30px",
    paddingBottom: 40,
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
