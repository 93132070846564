// import {IConfig} from 'types/configTypes'
import * as actions from "./config.types";
//GET CONFIG

export const handleGetConfig = (): actions.HandleGetConfig => {
  return {
    type: actions.HANDLE_GET_CONFIG,
    // payload: { email, password, firstName, lastName },
  };
};

export const pendingGetConfig = (): actions.GetConfigPending => {
  return {
    type: actions.GET_CONFIG_PENDING,
  };
};

export const resolvedGetConfig = (config: any): actions.GetConfigResolved => {
  return {
    type: actions.GET_CONFIG_RESOLVED,
    payload: config,
  };
};

export const rejectedGetConfig = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetConfigRejected => {
  return {
    type: actions.GET_CONFIG_REJECTED,
    payload: errors,
  };
};

// UPDATE CONFIG
export const handleUpdateConfig = (data: any): actions.HandleUpdateConfig => {
  return {
    type: actions.HANDLE_UPDATE_CONFIG,
    payload: data,
  };
};

export const pendingUpdateConfig = (): actions.UpdateConfigPending => {
  return {
    type: actions.UPDATE_CONFIG_PENDING,
  };
};

export const resolvedUpdateConfig = (
  config: any
): actions.UpdateConfigResolved => {
  return {
    type: actions.UPDATE_CONFIG_RESOLVED,
    payload: config,
  };
};

export const rejectedUpdateConfig = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.UpdateConfigRejected => {
  return {
    type: actions.UPDATE_CONFIG_REJECTED,
    payload: errors,
  };
};
