
import {
    IStackStyles,
    Link,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { CustomFontText } from "components/customFontText/CustomFontText";
import { SoldWorkbookMoreActionsSubmenu } from "components/table/inventory/dropdown/SoldWorkbookMoreActionsSubmenu";
import { WithCredentials, eTypes } from "hoc/withCredentials";
import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useLocation } from "react-router-dom";
import { AppState } from "store";
import {
    handleGetVehicles
} from "store/inventory/inventory.action";
import { numberFormat } from "utils/helperFunctions";
import {
    getModulePermissions,
    isUserMaster,
} from "utils/permissions/permissionsHelpers";
import { permissionsModules, themeStyles } from "../../constants/constants";
//import AccordionActions from '@material-ui/core/AccordionActions';
import { Checkbox } from "@fluentui/react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { useFormik } from "formik";
import { parseInt } from 'lodash';
import moment from "moment";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import CurrencyInput from 'react-currency-input-field';
import { useMediaQuery } from "react-responsive";
import {
    HANDLE_GET_EMPLOYEES,
} from "store/employees/employees.types";
import { handleGetLocations } from "store/locations/locations.action";
import { handleGetdataOneMakes, handleGetdataOneModels } from "store/shared/shared.action";
import * as Yup from "yup";

const container: IStackStyles = {
    root: {
        marginTop: 10,
    },
};

// const useStyles = makeStyles((theme: ThemeStyle) =>

//     createStyles({
//         root: {
//             flexGrow: 1,
//             width: '100%',
//             backgroundColor: theme?.palette?.background.paper,
//             display: 'block',
//         },
//         heading: {
//             fontSize: theme.typography?.pxToRem(20),
//         },
//         secondaryHeading: {
//             fontSize: theme.typography?.pxToRem(15),
//             color: theme.palette?.text.secondary,
//         },
//         icon: {
//             verticalAlign: 'bottom',
//             height: 20,
//             width: 20,
//         },
//         details: {
//             alignItems: 'center',
//         },
//         column: {
//             flexBasis: '33.33%',
//         },
//         helper: {
//             borderLeft: `2px solid ${theme.palette?.divider}`,
//             padding: theme?.spacing?.(1, 2),
//         },
//         link: {
//             color: theme.palette?.primary.main,
//             textDecoration: 'none',
//             '&:hover': {
//                 textDecoration: 'underline',
//             },
//         },
//     }),
// );


const credentialModuleName = "Inventory";
export const InventoryPage = () => {
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const serverError = useSelector((state: AppState) => state.inventory.error);
    const makes: any = useSelector(
        (state: AppState) => state.shared.dataOneMakes
    );
    const models: any = useSelector(
        (state: AppState) => state.shared.dataOneModels
    );

    // const error = useSelector((state: AppState) => state.inventory.error);

    const [credentials, setCredentials] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const location = useLocation();
    const [globalFilter, setGlobalFilter] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [makeValues, setMakeValues] = React.useState([]);
    const [modelValues, setModelValues] = React.useState([]);
    const [selectedMakes, setSelectedMakes] = useState(null);
    //const [selectMakeAll, setSelectMakeAll] = useState(false);
    const [selectedModels, setSelectedModels] = useState(null);
    //const [selectModelAll, setSelectModelAll] = useState(false);

    const [cityDealerList, setCityDealerList] = React.useState([]);
    const [appraisal, setAppraisal] = useState(true);
    const [active, setActive] = useState(true);
    const [sold, setSold] = useState(true);
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const [fromYearMsg, setFromYearMsg] = useState('');
    const [toYearMsg, setToYearMsg] = useState('');
    const [toMileageMsg, setToMileageMsg] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const employees = useSelector((state: AppState) => state.employees.employees) as any;
    const [appraisalList, setAppraisalList] = React.useState(null);
    const [primaryLocation, setPrimaryLocation] = useState(0);
    const [locationFilter, setLocationFilter] = React.useState(0);
    const [appraisalFilter, setAppraisalFilter] = React.useState(null);
    const [logDateFilter, setLogDateFilter] = React.useState<Date | undefined>(undefined);
    const yearRange = "1980:" + (new Date().getFullYear() + 1);
    const isMobile = useMediaQuery({ maxWidth: 900 });
    const isMobileWorkbook = useMediaQuery({ maxWidth: 960 });
    //const isSmallMobile = useMediaQuery({ maxWidth: 475 });
    // added for new Table resposneive 
    const [products, setProducts] = useState([]);
    // const [expandedRows, setExpandedRows] = useState(null);

    // const  classes:any  = useStyles();

    const formik = useFormik({
        initialValues: {
            fromYear: '',
            toYear: '',
            fromMileage: "",
            toMileage: "",
        },
        validationSchema: Yup.object({
            fromYear: Yup.number(),
            toYear: Yup.number(),
            fromMileage: Yup.string(),
            toMileage: Yup.string()
        }),
        onSubmit: (values) => {

        },
    });

    useEffect(() => {

        let person = [];
        if (employees.results.length > 0) {
            employees.results.map((item: any) => {
                return person.push({ key: item.id, text: (item.firstName + ' ' + item.lastName) })
            });
            person.unshift({ key: 0, text: '--Please Select--' });
            setAppraisalList(person);
        }
    }, [employees])

    useEffect(() => {
        dispatch<any>(handleGetVehicles());
        if (user && user.auths && !isUserMaster(user.roles)) {
            setCredentials(getModulePermissions(user.auths, credentialModuleName));
        }
        dispatch<any>(handleGetdataOneMakes(-1));
        dispatch<any>(handleGetLocations());
        setModelValues([]);
        dispatch<any>({ type: HANDLE_GET_EMPLOYEES, payload: { isActive: 1 } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.roles?.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
            setPrimaryLocation(user.primaryLocationId);
            setLocationFilter(user.primaryLocationId);
        }
    }, [user])

    useEffect(() => {
        if (selectedMakes && selectedMakes.length > 0) {
            dispatch<any>(handleGetdataOneModels({ make: selectedMakes.toString(), year: '' }));
        } else {
            setModelValues([]);
        }
        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMakes]);

    useEffect(() => {
        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModels]);

    useEffect(() => {
        if (serverError) {
            setErrorMsg('Failed to get the Data');
        } else {
            setErrorMsg('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError]);

    useEffect(() => {
        if (makes.length > 0) {
            setMakeValues(makes);
        }

    }, [makes]);

    useEffect(() => {
        if (models.length > 0) {
            if (selectedModels) {
                const selectedItems = selectedModels;
                let results = [];
                selectedItems.forEach((item) => {
                    if (models.some(x => x.label === item)) {
                        results.push(item);
                    }
                });
                setSelectedModels(results);
            }
            setModelValues(models);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [models]);

    useEffect(() => {

        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
        //console.log('locationslocationslocations',locations)   
    }, [locations]);

    const vehicleList = useSelector((state: AppState) => state.inventory.vehicleList);

    useEffect(() => {
        if (vehicleList && vehicleList.length > 0) {
            let results = vehicleList.map(function (item) {
                item['odo'] = item.mileage;
                item.mileage = item.mileage === 0 ? '' : numberFormat(item.mileage);
                return item;
            })
            // expandableTableRows(results);
            const stateVal: any = location?.state;
            if (stateVal && (stateVal.fromYear || stateVal.toYear || stateVal.fromMileage || stateVal.toMileage
                || stateVal.fromMileage || stateVal.selectedMakes || stateVal.selectedModels || stateVal.searchInput
                || (stateVal.appraisal !== null || stateVal.active !== null || stateVal.sold !== null))) {
            } else {
                setProducts(results);
            }
        } else {
            // expandableTableRows(vehicleList);
            if (vehicleList && vehicleList.length === 0) location.state = null;
            setProducts(vehicleList);
        }
        // set location if available
        if (vehicleList && vehicleList.length > 0) {
            if (location && location.state) {
                // setStateFielterData();
            } else {
                handleVehicleSearch(globalFilter);
                handleLocationSearch(locationFilter);
                handleppraisalSearch(appraisalFilter);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [vehicleList]);

    /* const expandableTableRows = (data) => {
        if (data) {
            let _expandedRows = {};
            data.forEach(p => {
                if (p.vin && p.vin !== '') {
                    _expandedRows[`${p.id}`] = true;
                }
            });
            setExpandedRows(_expandedRows);
        }
    } */

    const handleSearchChange = (e) => {
        setGlobalFilter(e.target.value);
        handleVehicleSearch(e.target.value);
    }

    const handleLocationDropdownChange = (e) => {
        setLocationFilter(e.target.value);
        handleLocationSearch(e.target.value);
    }

    const handleAppraisalDropdownChange = (e) => {
        setAppraisalFilter(e.target.value);
        handleppraisalSearch(e.target.value);
    }

    const handleLogDateChange = (val: any) => {
        setLogDateFilter(val.target.value)
        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, val.target.value);
    };

    const handleVehicleSearch = (val) => {
        handleFilters(val, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
    }

    const handleLocationSearch = (val) => {
        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, val, appraisalFilter, logDateFilter);
    }

    const handleppraisalSearch = (val) => {
        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, val, logDateFilter);
    }

    useEffect(() => {
        handleVehicleSearch(globalFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter]);

    useEffect(() => {
        if (location && location.state) {
            setStateFielterData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [location]);

    const setStateFielterData = () => {

        const stateVal: any = location?.state;
        console.log('state value', stateVal)
        const newAppraisal = stateVal?.appraisal === false ? false : true;
        const newActive = stateVal?.active === false ? false : true;
        const newSold = stateVal?.sold === false ? false : true;
        const stateMake = stateVal?.selectedMakes ? stateVal?.selectedMakes : [];
        const stateModel = stateVal?.selectedModels ? stateVal?.selectedModels : [];
        if (stateVal) {
            setAppraisal(newAppraisal);
            setActive(newActive);
            setSold(newSold);
        }
        // setForm Data 
        if (stateVal) {
            formik.setValues(
                {
                    ...formik.values,
                    'fromYear': stateVal.fromYear,
                    'toYear': stateVal.toYear,
                    'fromMileage': stateVal.fromMileage,
                    'toMileage': stateVal.toMileage
                }
            );
        }
        if (stateVal) {

            setSelectedMakes(stateVal.selectedMakes);
        }
        if (stateVal && stateVal.selectedModels) {
            setSelectedModels(stateVal.selectedModels);
        }
        if (stateVal && stateVal.searchInput) {
            setGlobalFilter(stateVal.searchInput);
        }
        // set Inventory stattus
        setTimeout(() => {
            if (stateVal) {
                handleFilters(stateVal.searchInput, stateVal.fromYear, stateVal.toYear, stateVal.fromMileage, stateVal.toMileage, newAppraisal, newActive, newSold, stateMake, stateModel, locationFilter, appraisalFilter, logDateFilter);
            }
            if (stateVal.sortField) {
                setSortField(stateVal.sortField);
                setSortOrder(stateVal.sortOrder);
            }
        }, 1000)
    }

    useEffect(() => {
        if (locationFilter || appraisalFilter) {
            handleLocationSearch(locationFilter);
            handleppraisalSearch(appraisalFilter);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [locationFilter, appraisalFilter])

    const handleFilters = (globalFilter, fromYear, toYear, fromOdo, toOdo, shop, purchase, soldStatus, makeData, modelData, locationFilter, appraisalFilter, logDateFilter) => {
        if (globalFilter.length > 2 || fromYear.length === 4 || toYear.length === 4 || fromOdo !== '' || toOdo !== '' || !shop || !purchase || !soldStatus || (makeData && makeData.length > 0) || locationFilter || appraisalFilter || logDateFilter) {

            let results = vehicleList;
            if (results) {
                if (globalFilter && globalFilter.length > 2) {
                    const lowerCaseVal = globalFilter.toLowerCase().split(" ");

                    lowerCaseVal.forEach(function (item) {
                        if (item !== '') {
                            results = results.filter(x => x.make.toLowerCase().includes(item) ||
                                x.model.toLowerCase().includes(item) ||
                                x.style?.toLowerCase().includes(item) ||
                                x.year.toString().toLowerCase().includes(item) ||
                                (x.vin && x.vin.toLowerCase().includes(item)));
                        }
                    });
                }
                // Location Filter 
                if (locationFilter > 0) {

                    results = results.filter(x => {
                        return x?.location?.id === locationFilter

                    }
                    )

                }

                // Appraisal filter
                if (appraisalFilter > 0) {
                    //results = results?.appraisedBy.filter(x => x?.id === appraisalFilter );
                    results = results.filter(x => {
                        return x?.appraisedBy?.id === appraisalFilter

                    }
                    )
                }
                // logdate filter
                if (logDateFilter) {


                    const rawFromLogDate = moment(logDateFilter).local().format('MM/DD/YYYY')

                    results = results.filter(x => {
                        let rawApprDate = moment(new Date(x.createdAt)).local().format("MM/DD/YYYY");
                        if (new Date(rawApprDate) >= new Date(rawFromLogDate)) {
                            return true;
                        }
                        return false
                    });
                    // results = results.filter(x => moment(new Date(x.appointDate)).local().format("MM/DD/YY") >= new Date(logDateFromFilter));
                }


                //Year Filter
                if (fromYear && fromYear.length === 4 && toYear.length === 4) {
                    results = results.filter(x => x.year >= parseInt(fromYear) && x.year <= parseInt(toYear));
                } else if (fromYear && fromYear.length === 4) {
                    results = results.filter(x => x.year >= parseInt(fromYear) && x.year <= new Date().getFullYear());
                }
                else if (fromYear && fromYear.length === 4) {
                    results = results.filter(x => x.year >= 1960 && x.year <= parseInt(toYear));
                }

                //Odo Filter
                if (fromOdo && fromOdo.length > 0 && toOdo.length > 0) {
                    results = results.filter(x => x.odo >= parseInt(fromOdo) && x.odo <= parseInt(toOdo));
                } else if (fromOdo && fromOdo.length > 0) {
                    results = results.filter(x => x.odo >= parseInt(fromOdo));
                }
                else if (toOdo && toOdo.length > 0) {
                    results = results.filter(x => x.odo <= parseInt(toOdo));
                }

                //Status Filter
                if (!shop) {
                    results = results.filter(x => x.stage !== 'shop');
                }
                if (!purchase) {
                    results = results.filter(x => x.stage !== 'purchase');
                }
                if (!soldStatus) {
                    results = results.filter(x => x.stage !== 'sold');
                }

                //Make Filter
                if (makeData && makeData.length > 0) {
                    results = results.filter(obj => {
                        return makeData.includes(obj.make)
                    });
                    if (modelData && modelData.length > 0) {
                        results = results.filter(obj => {
                            return modelData.includes(obj.model)
                        });
                    }
                }
                // expandableTableRows(results);
                setProducts(results);
            }

        } else {
            // expandableTableRows(vehicleList);
            setProducts(vehicleList);
        }
    }



    const handleFromYearChange = (event) => {
        const year = event.target.value.replace(/\D/, '');
        if (year.length <= 4) {

            if (year.length === 4) {

                if (year < 1960) {
                    setFromYearMsg('Year should be minimum 1960');
                }
                else if (new Date().getFullYear() < year) {
                    setFromYearMsg('Year should be maximum ' + new Date().getFullYear());
                }
                else {
                    setFromYearMsg('');
                    handleFilters(globalFilter, year, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
                }

            } else {
                setFromYearMsg('');
                handleFilters(globalFilter, year, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            }

            formik.setValues(
                {
                    ...formik.values,
                    [event.target.id]: year
                }
            );
        }
    };

    const handleToYearChange = (event) => {
        const year = event.target.value.replace(/\D/, '');
        if (year.length <= 4) {

            if (year.length === 4) {

                if (year < 1960) {
                    setToYearMsg('Year should be minimum 1960');
                }
                else if (year < parseInt(formik.values.fromYear)) {
                    setToYearMsg('Year should be greater than or equal to ' + formik.values.fromYear);
                }
                else if (new Date().getFullYear() < year) {
                    setToYearMsg('Year should be maximum ' + new Date().getFullYear());
                }
                else {
                    setToYearMsg('');
                    handleFilters(globalFilter, formik.values.fromYear, year, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
                }

            } else {
                setToYearMsg('');
                handleFilters(globalFilter, formik.values.fromYear, year, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            }

            formik.setValues(
                {
                    ...formik.values,
                    [event.target.id]: year
                }
            );
        }
    };

    const handleFromMileageChange = (event) => {
        const mileage = event.replace(/\D/, '');
        if (mileage.length <= 6) {
            formik.setValues(
                {
                    ...formik.values,
                    "fromMileage": event
                }
            );
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, mileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);

        }

    };

    const handleToMileageChange = (event) => {
        const mileage = event.replace(/\D/, '');
        if (mileage.length <= 6) {
            if (mileage.length > 0 && parseInt(mileage) < parseInt(formik.values.fromMileage)) {
                setToMileageMsg('Year should be greater than or equal to ' + formik.values.fromMileage);
            }
            else {
                setToMileageMsg('');

                handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, mileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            }
            formik.setValues(
                {
                    ...formik.values,
                    "toMileage": event
                }
            );
        }

    };

    const handleActionButtonClick = (index) => {

        if (index === 1) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, !appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setAppraisal(!appraisal);
        } else if (index === 2) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, !active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setActive(!active);
        } else if (index === 3) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, !sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setSold(!sold);
        }
    };

    const handleCheckBtnAction = (index) => {

        if (index === 1) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setAppraisal(appraisal);
        } else if (index === 2) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setActive(active);
        } else if (index === 3) {
            handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
            setSold(sold);
        }
    };

    const handleRemoveMake = (make) => {
        const selectedItems = selectedMakes.filter(x => x !== make);
        setSelectedMakes(selectedItems);
    }

    const handleRemoveModel = (model) => {
        const selectedItems = selectedModels.filter(x => x !== model);
        setSelectedModels(selectedItems);
    }

    const handleReset = () => {
        setGlobalFilter('')
        setLocationFilter(primaryLocation)
        setAppraisalFilter(null)
        setLogDateFilter(null)
        setAppraisal(true)
        setActive(true)
        setSold(true)
        setSelectedModels(null)
        setSelectedMakes(null)
        formik.setValues(
            {
                ...formik.values,
                fromYear: "",
                toYear: "",
                fromMileage: "",
                toMileage: "",
            }
        )
    }

    useEffect(() => {

        handleFilters(globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [globalFilter, formik.values.fromYear, formik.values.toYear, formik.values.fromMileage, formik.values.toMileage, appraisal, active, sold, selectedMakes, selectedModels, locationFilter, appraisalFilter, logDateFilter])

    const header = (
        <>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={2} >
                            <div className="inventory-home-date-filter">
                                <TextField
                                    value={globalFilter}
                                    label="Search for Vehicle"
                                    onChange={(e) => handleSearchChange(e)}
                                />
                            </div>
                        </Col>
                        <Col md={2} >

                            <div className="invSearchDrop">
                                <label className="lbl-text" style={{ marginBottom: "-2px" }}>Location</label>
                                <Dropdown
                                    id="locationFilter"
                                    placeholder=""
                                    value={locationFilter}
                                    options={cityDealerList}
                                    onChange={handleLocationDropdownChange}
                                    optionLabel="legalName"
                                    optionValue="id"
                                    appendTo="self"
                                    className="drop-options-pad custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col md={2}  >
                            <div className="invSearchDrop">
                                <label className="lbl-text" style={{ marginBottom: "-2px" }}>Appraiser</label>
                                <Dropdown
                                    id="appraisalFilter"
                                    placeholder=""
                                    value={appraisalFilter}
                                    options={appraisalList}
                                    onChange={handleAppraisalDropdownChange}
                                    optionLabel="text"
                                    optionValue="key"
                                    appendTo="self"
                                    className="drop-options-pad custom-p-dropdown"
                                    filter={false}
                                    resetFilterOnHide={true}
                                />
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className=" inventory-home-date-filter">
                                <div className="inventory-home-date-filter">
                                    <div className="ms-TextField-wrapper">
                                        <label className="lbl-text">Log Date</label>
                                        <div className="ms-TextField-fieldGroup mask-FieldGroup inventory-home-date-filter">
                                            <Calendar
                                                id="fromLogDate"
                                                style={{ width: "100%" }}
                                                value={logDateFilter}
                                                showIcon
                                                onChange={(e) => handleLogDateChange(e)}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={yearRange}
                                            //classNmae="inventory-home-filter"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className=" rstbtnInvSearch employeeeSearchLabelText" style={{ marginTop: "28px" }} >
                            <PrimaryButton
                                onClick={handleReset}
                                className="rstBtnInventory"
                            >
                                Reset
                            </PrimaryButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                isMobile ?
                    <Row>
                        <Col md={12} style={{ display: "flex", width: "100%" }}>
                            <div onClick={() => handleActionButtonClick(1)} style={{ cursor: "pointer", width: "30%" }} className={` ${!appraisal ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`} >

                                <Checkbox
                                    label="Appraisals"
                                    checked={appraisal}
                                    className={`btnCheck ${!appraisal ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                    onChange={() => handleCheckBtnAction(1)}
                                />
                            </div>
                            <div style={{ width: "5%" }}>

                            </div>
                            <div onClick={() => handleActionButtonClick(2)} style={{ textAlign: "right", cursor: "pointer", width: "30%" }} className={` ${!active ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`} >

                                <Checkbox
                                    label="Inventory"
                                    checked={active}
                                    className={`btnCheck ${!active ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                    onChange={() => handleCheckBtnAction(2)}
                                //style={{textAlign:"center"}}
                                />
                            </div>
                            <div style={{ width: "5%" }}>

                            </div>
                            <div onClick={() => handleActionButtonClick(3)} style={{ textAlign: "right", cursor: "pointer", width: "30%" }} className={` ${!sold ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`} >

                                <Checkbox
                                    label="Sold"
                                    checked={sold}
                                    className={`btnCheck ${!sold ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                    onChange={() => handleCheckBtnAction(3)}
                                //style={{textAlign:"right"}}
                                />
                            </div>
                        </Col>
                    </Row>
                    : <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={2} >
                                    <div onClick={() => handleActionButtonClick(1)} style={{ cursor: "pointer" }} className={` ${!appraisal ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`}>

                                        <Checkbox
                                            label="Appraisals"
                                            checked={appraisal}
                                            className={`btnCheck ${!appraisal ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                            onChange={() => handleCheckBtnAction(1)}
                                        />
                                    </div>
                                </Col>
                                <Col md={2} >
                                    <div onClick={() => handleActionButtonClick(2)} style={{ cursor: "pointer" }} className={` ${!active ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`}>

                                        <Checkbox
                                            label="Inventory"
                                            checked={active}
                                            className={`btnCheck ${!active ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                            onChange={() => handleCheckBtnAction(2)}
                                        />
                                    </div>

                                </Col>
                                <Col md={2}  >
                                    <div onClick={() => handleActionButtonClick(3)} style={{ cursor: "pointer" }} className={` ${!sold ? " btnColorNonCheck inventorySearchBtnNoCheckBox" : " inventorySearchBtnCheckBox btnColorCheck"}`}>

                                        <Checkbox
                                            label="Sold"
                                            checked={sold}
                                            className={`btnCheck ${!sold ? "btnColorNonCheck inventorySearchBtnNoChecked " : "inventorySearchBtnCheck btnColorCheck "}`}
                                            onChange={() => handleCheckBtnAction(3)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>}

            <Row>
                <Col>
                    <Accordion className="accordianTable inventory-filter-accordion" >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1c-content"
                            id="panel1c-header"
                        >
                            <div style={themeStyles.column}>
                                <Typography component={'h3'} style={themeStyles.heading}>Vehicle Filters</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={themeStyles.details}>
                            <Row>
                                <Col sm={12} md={8} lg={8}>
                                    <Row>
                                        <Col>
                                            <label className="lbl-text" style={{ marginBottom: "-2px" }}>Make</label>
                                            <MultiSelect
                                                value={selectedMakes}
                                                options={makeValues}
                                                filter
                                                onChange={(e) => {
                                                    setSelectedMakes(e.value);
                                                    //setSelectMakeAll(e.value.length === makeValues.length);
                                                }}
                                                showSelectAll={false}
                                                //selectAll={selectMakeAll}
                                                //onSelectAll={(e) => {
                                                //    setSelectedMakes(e.checked ? [] : makeValues.map(item => item.label));
                                                //    setSelectMakeAll(!e.checked)
                                                //}}
                                                virtualScrollerOptions={{ itemSize: 34 }}
                                                placeholder="Select Make"
                                                appendTo="self"
                                                optionLabel="label"
                                                optionValue="label"
                                                className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                                fixedPlaceholder={true}
                                                resetFilterOnHide={true}
                                            //showSelectAll={false}
                                            />
                                            <div className="p-multiselect-selected-item">
                                                {selectedMakes && selectedMakes.map((item, index) => (
                                                    <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                                        <Link onClick={() => handleRemoveMake(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col>
                                            <label className="lbl-text" style={{ marginBottom: "-2px" }}>Model</label>
                                            <MultiSelect
                                                value={selectedModels}
                                                options={modelValues}
                                                filter
                                                onChange={(e) => {
                                                    setSelectedModels(e.value);
                                                    //setSelectModelAll(e.value.length === modelValues.length)
                                                }}
                                                showSelectAll={false}
                                                //selectAll={selectModelAll}
                                                //onSelectAll={(e) => {
                                                //    setSelectedModels(e.checked ? [] : modelValues.map(item => item.label));
                                                //    setSelectModelAll(!e.checked)
                                                //}}
                                                virtualScrollerOptions={{ itemSize: 34 }}
                                                placeholder="Select Model"
                                                appendTo="self"
                                                optionLabel="label"
                                                optionValue="label"
                                                disabled={!selectedMakes || selectedMakes.length === 0}
                                                className="multiInputText inventoryFilt inventory-filter-multi-select  labelTextBorder"
                                                //fixedPlaceholder={true}
                                                resetFilterOnHide={true}
                                            />
                                            <div className="p-multiselect-selected-item">
                                                {selectedModels && selectedModels.map((item, index) => (
                                                    <div key={"boxes-" + index} className="multiSelectTokenlab p-multiselect-token">
                                                        <span className="multiSelectTokenlab p-multiselect-token-label">{item}</span>
                                                        <Link onClick={() => handleRemoveModel(item)}><span className="p-multiselect-token-icon pi pi-times"></span></Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} md={4} lg={4} className="year-odo-filter-area">
                                    <Row>
                                        <Col>
                                            <TextField
                                                className="inventory-home-filter"
                                                value={formik.values.fromYear}
                                                onChange={(e) => handleFromYearChange(e)}
                                                onBlur={formik.handleBlur}
                                                label="Year"
                                                id="fromYear"
                                                name="fromYear"
                                                errorMessage={fromYearMsg}
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                className="inventory-home-filter to-range"
                                                value={formik.values.toYear}
                                                onChange={(e) => handleToYearChange(e)}
                                                onBlur={formik.handleBlur}
                                                label="Year"
                                                id="toYear"
                                                name="toYear"
                                                errorMessage={toYearMsg}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="lbl-text">Odo</label>
                                            <CurrencyInput
                                                className="inventory-home-filter"
                                                customInput={TextField}
                                                // thousandSeparator={true}
                                                id="fromMileage"
                                                name="fromMileage"
                                                //label="Odo"
                                                decimalScale={0}
                                                value={formik.values.fromMileage?formik.values.fromMileage.replace(/,/g, ''):''}
                                                onValueChange={handleFromMileageChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <span className="error-message">
                                                {formik.touched.fromMileage &&
                                                    formik.errors.fromMileage &&
                                                    formik.errors.fromMileage.toString()}
                                            </span>
                                        </Col>
                                        <Col>
                                            <label className="lbl-text">Odo</label>
                                            <CurrencyInput
                                                className="inventory-home-filter to-range"
                                                customInput={TextField}
                                                // thousandSeparator={true}
                                                id="toMileage"
                                                name="toMileage"
                                                //label="Odo"
                                                decimalScale={0}
                                                value={formik.values.toMileage?formik.values.toMileage.replace(/,/g, ''):''}
                                                onValueChange={handleToMileageChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <span className="error-message">
                                                {toMileageMsg}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </AccordionDetails>


                    </Accordion>
                </Col>
            </Row>
        </>
    );

    const mileageBodyTemplate = (rowData) => {
        let truncatedVin = rowData.vin ? rowData.vin.substr(-8) : '';
        return (
            <React.Fragment>
                {isMobile && (
                    <>
                        <div className="vinMobSec" >
                            <span className="p-column-title">VIN <span>{truncatedVin}</span></span>
                            <span className="p-column-title">Odo <span>{rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}</span></span>
                        </div>
                        <div className="vinMobSec" style={{ paddingBottom: '0' }}>
                            <span className="p-column-title">Status</span>
                            <span className="mobileDisplay">
                                {(rowData.stage === 'shop') ? 'Appraisal' : (rowData.stage === 'purchase') ? 'Active' : 'Sold'}
                            </span>
                        </div>
                    </>)

                }
                <div className="vinOdoSec mobileDisplayNone">
                    <span className="p-column-title">Odo</span>
                    {rowData.mileage === 0 ? '' : numberFormat(rowData.mileage)}
                </div>
            </React.Fragment>
        );
    };


    const yearBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.year}
                <span className="vinDisplayPos">{rowData.vin}</span>
            </React.Fragment>
        );
    };

    const makeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.make}
            </React.Fragment>
        );
    };

    const modelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.model}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Year</span> */}
                {(rowData.stage === 'shop') ? 'Appraisal' : (rowData.stage === 'purchase') ? 'Active' : 'Sold'}
            </React.Fragment>
        );
    };

    const settingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title" style={{ float: "left" }}>Actions</span> */}
                {credentials && user.roles && (
                    <WithCredentials
                        groupAuths={credentials}
                        alias={permissionsModules.Inventory}
                        type={eTypes.UPDATE}
                        roles={user.roles}
                    >
                        <SoldWorkbookMoreActionsSubmenu
                            modulePermissions={credentials}
                            vehicleID={rowData.id}
                            onEdit={(vehicleID) => handleEditPurchase(vehicleID)}
                        />
                    </WithCredentials>
                )}


            </React.Fragment>
        );
    };

    /* const rowExpansionTemplate = (data) => {
        if (data.vin && (data.vin !== '')) {
            return (
                <div className="vin-subtable" onClick={() => vinRowSelection(data)}>
                    <span>{data.vin}</span>
                </div>
            );
        } else {
            return false;
        }
    }; */

    /* const vinRowSelection = (row) => {
        if (row && row)
            navigate(`/${row.id}/shop-inventory`);
    } */

    const captureCellSelection = (event) => {
        let target = event.originalEvent.target;
        if (target?.nodeName === 'TD' && target?.hasChildNodes() && target.lastElementChild?.nodeName === 'BUTTON') {
            target?.lastElementChild?.lastElementChild?.firstElementChild.click();
        } else {
           const state = {
            selectedMakes,
            selectedModels,
            pathfrom: '/inventory',
            searchInput: globalFilter,
            fromYear: formik.values.fromYear,
            toYear: formik.values.toYear,
            fromMileage: formik.values.fromMileage,
            toMileage: formik.values.toMileage,
            appraisal,
            active,
            sold,
            sortOrder,
            sortField
        }
        navigate(`/${event.data.id}/shop-inventory`,{state});
        }
    };


    const handleEditPurchase = (vehicleID) => {
        const state = {
            selectedMakes,
            selectedModels,
            pathfrom: '/inventory',
            searchInput: globalFilter,
            fromYear: formik.values.fromYear,
            toYear: formik.values.toYear,
            fromMileage: formik.values.fromMileage,
            toMileage: formik.values.toMileage,
            appraisal,
            active,
            sold,
            sortOrder,
            sortField
        }
        navigate( `/${vehicleID}/shop-inventory`,{state});
    };

    const rowClassName = (node) => {
        return { 'vinRow': (node.vin && node.vin !== '') };
    }

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
    }

    return (
        <>
            <Stack styles={container} tokens={{ childrenGap: 10 }} className="shopWorkBookPg appraisalWorkBook">
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                >
                    <Row>
                        <Col className="alignHeaders">
                            <CustomFontText style={{ marginLeft: "0px", marginBottom: "0px" }} className="pageTitleFont pageName inventoryTitle fontColorChange">Inventory Search</CustomFontText>

                        </Col>
                    </Row>
                </Stack>
                {
                    errorMsg ?
                        <div>
                            <p className="genericErrorColor"><span>{errorMsg}</span></p>
                        </div> : null
                }
                <Row>
                    <Col sm={12} md={12} lg={12} xs={12}>

                        <div className="tableColumnTitle datatable-filter-demo largeTable shop-inventory-tbl">
                            <div className="invSearchDrop card mouse-hover">
                                <DataTable
                                    rowClassName={rowClassName}
                                    value={products}
                                    header={header}
                                    paginator={isMobileWorkbook ? false : true}
                                    rows={isMobileWorkbook ? null : 25}
                                    onSort={onSort}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    className="p-datatable-customers p-datatable-hoverable-rows"
                                    // expandedRows={expandedRows}
                                    // onRowToggle={(e) => setExpandedRows(e.data)}
                                    // rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
                                    ////cellSelection
                                    onRowClick={e => captureCellSelection(e)}>
                                    <Column field="status" header="Status" sortable body={statusBodyTemplate} className="statusCol mobileDisplayNone" />
                                    <Column field="year" header="Year" body={yearBodyTemplate} sortable className="yrMakeModel yrOly" />
                                    <Column field="make" header="Make" body={makeBodyTemplate} sortable className="yrMakeModel" />
                                    <Column field="model" header="Model" body={modelBodyTemplate} sortable className="yrMakeModel" />
                                    <Column field="mileage" header="Odo" body={mileageBodyTemplate} sortable />
                                    <Column field="Settings" header="Actions" body={settingBodyTemplate} />
                                </DataTable>

                            </div>
                        </div>
                    </Col>
                </Row>

            </Stack>
        </>
    );
};
