import React, { useState, useEffect } from "react";
import {
  IStackStyles,
  Modal,
  PrimaryButton,
  DefaultPalette,
  IconButton,
  IStackItemStyles,
  FontIcon,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import ReactPlayer from 'react-player'

import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import { Depths } from "@uifabric/fluent-theme";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// import {mock, statuses, fuelTypes, fuelEconomyType} from './inventoryMock.json';
import { ViewAdditionalActionsDropdown } from "../../table/dropdowns/ViewAdditionalActionsDropdown";
import { IInventory } from "types/inventoryTypes";
import { inventoryTypes } from "constants/constants";
import {
  cancelIcon,
  iconButtonStyles,
  contentStyles as contentStyle,
} from "constants/styles";
import { getModulePermissions } from "utils/permissions/permissionsHelpers";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store";
import {
  handleGetValuationInformation,
  cleanValuationInformation,
} from "store/inventory/inventory.action";
import { stringToCurrencyFormatForLabels } from "utils/helperFunctions";
import CurrencyInput from 'react-currency-input-field'

const contentStyles = contentStyle(1080);

const accordionElements: IAccordionElement[] = [
  {
    label: "Audio/Video",
    optionsName: "optionsAudio",
  },
  { label: "Power Equipment", optionsName: "optionsPower" },
  { label: "Interior", optionsName: "optionsInterior" },
  {
    label: "Exterior",
    optionsName: "optionsExterior",
  },
  { label: "Safety Features", optionsName: "optionsSafteyFeatures" },
  {
    label: "Luxury",
    optionsName: "optionsLuxury",
  },
  {
    label: "Other",
    optionsName: "optionsOther",
  },
];

const accordionStyles: IStackItemStyles = {
  root: {
    boxShadow: Depths.depth8,
    padding: 15,
    cursor: "pointer",
    position: "relative",
    margin: "10px auto",
  },
};

interface IAccordionElement {
  label: string;
  optionsName: string;
}

const checkIcon = {
  fontSize: 10,
  marginRight: 5,
  color: DefaultPalette.themePrimary,
};

const bolder = {
  fontWeight: 500,
};

const thumbNailStyle = {
  width: "100px !important",
  height: 50,
};

const underCarouselInfo = {
  color: DefaultPalette.blackTranslucent40,
  fontSize: 12,
};

const stackStyles: IStackStyles = {
  root: {},
};

enum ValuationInfoApiSource {
  kelley = "kelley",
  black = "black",
  nada = "nada",
}

interface IProps {
  onDismiss: () => void;
  itemData: IInventory;
}

export const ViewInventoryDialog: React.FunctionComponent<IProps> = ({
  onDismiss,
  itemData,
}) => {
  const [item, setItem] = useState(null);
  const [carouselItem, setcarouselItem] = useState([])
  const [activeAccordion, setActiveAccordion] = useState("");
  const user = useSelector((state: AppState) => state.userProfile.userProfile);

  const loadingNada = useSelector(
    (state: AppState) => state.inventory.loadingNada
  );
  // const loadingKelley = useSelector(
  //   (state: AppState) => state.inventory.loadingKelley
  // );
  const loadingBlack = useSelector(
    (state: AppState) => state.inventory.loadingBlack
  );

  const valuationNada = useSelector(
    (state: AppState) => state.inventory.nadaValuation
  );
  // const valuationKelley = useSelector(
  //   (state: AppState) => state.inventory.kelleyValuation
  // );
  const valuationBlack = useSelector(
    (state: AppState) => state.inventory.blackValuation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(itemData)
    if (itemData) {
      setItem({ ...itemData });
      if (itemData.photoUrls.length > 0) {
        setcarouselItem([
          ...itemData.photoUrls
        ]);
      } else if (itemData.videoUrl) {
        setcarouselItem(oldArray => [...oldArray, itemData.videoUrl]);
      }

      console.log('carouselItem', carouselItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemData]);

  const getStatusName = (status: number) => {
    return inventoryTypes.getStatusName(status);
  };

  const getFuelTypeName = (type: number) => {
    return inventoryTypes.getFuelTypesName(type);
  };

  const setAccordionActive = (id: string) => {
    setActiveAccordion(id === activeAccordion ? "" : id);
  };

  const handleValuation = (source: ValuationInfoApiSource) => {
    console.log(source);
    console.log("itemID", item.id);
    
    dispatch<any>(
      handleGetValuationInformation({ inventoryId: item.id, source: source })
    );
  };

  const handleOnDismiss = () => {
    onDismiss();
    //clearing the valuations state
    dispatch<any>(cleanValuationInformation());
  };

  return item ? (
    <Modal
      isOpen={true}
      onDismiss={handleOnDismiss}
      isBlocking={true}
      containerClassName={contentStyles.container}
    >
      <div className={` ${contentStyles.header}`}>
        <CustomFontText>
          {item.model}{" "}
          <span
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: item.status === 0 ? "black" : "red",
            }}
          >
            {getStatusName(item.status)}
          </span>
        </CustomFontText>

        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={handleOnDismiss}
        />
      </div>
      <div className={contentStyles.body}>
        <Stack
          styles={stackStyles}
          horizontal
          verticalAlign="start"
          horizontalAlign="space-between"
        >
          <div style={{ fontSize: "18px" }}>
            {item.year ? `${item.year}` : ""}{" "}
            {item.miles ? `\u2022 ${item.miles}` : ""}{" "}
            {item.fuelType ? `\u2022 ${getFuelTypeName(item.fuelType)}` : ""}{" "}
            {item.body ? `\u2022 ${item.body}` : ""}
          </div>
          <Stack style={{ textAlign: "right" }}>
            <div
              style={{ fontSize: "32px", color: DefaultPalette.themePrimary }}
            >
              <span
                style={{
                  verticalAlign: "super",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                $
              </span>
              {/*<span style={bolder}>{stringToCurrencyFormatForLabels(item.sellingPrice)}</span>*/}

              <CurrencyInput
                customInput={(value) => (
                  <span style={bolder}>{value.value}</span>
                )}
                value={item.sellingPrice?item.sellingPrice.replace(/,/g, '') / 100:""}
               // thousandSeparator={true}
                decimalScale={2}
                //fixedDecimalScale
                //displayType={"text"}
              />
            </div>
            <div>
              <span style={bolder}>{item.isInvoice ? "Invoice, " : ""}</span>
              <span style={bolder}>{item.isLeasing ? "Leasing, " : ""}</span>
              MSRP: ${stringToCurrencyFormatForLabels(item.msrp)}
            </div>
          </Stack>
        </Stack>

        <Carousel
          className="inventoryCarousel"
          showStatus={false}
          showIndicators={false}
          renderThumbs={(thumbs) =>
            thumbs?.map((thumb: any, index: number) => {
                return (
                  thumb?.props?.children[0]?.props?.src ?
                    <div key={index}>
                      <img
                        alt="inventory"
                        key={"thumb" + index}
                        style={thumbNailStyle}
                        src={`${thumb?.props?.children[0]?.props?.src ? thumb?.props?.children[0]?.props?.src : ''}`}
                      />
                    </div>
                  : thumb?.props?.children[0]?.props?.url ?
                      <div key={index}>
                        <img
                          alt="inventory video"
                          key={"thumb" + index}
                          style={thumbNailStyle}
                          src={`${require("assets/video-PlayIcon.png")}`}
                        />
                      </div> : thumb?.props?.children?.props?.url ?
                      <div key={index}>
                      <img
                        alt="inventory video"
                        key={"thumb" + index}
                        style={thumbNailStyle}
                        src={`${require("assets/video-PlayIcon.png")}`}
                      />
                    </div>
                      : thumb[0]?.props?.children[0]?.props?.src ?
                        <div key={index}>
                          <img
                            alt="inventory"
                            key={"thumb" + index}
                            style={thumbNailStyle}
                            src={`${thumb[0]?.props?.children[0]?.props?.src ? thumb[0]?.props?.children[0]?.props?.src : ''}`}
                          />
                        </div> 
                        : ""
                );
            
            })
          }
        >

          {carouselItem.map((image: string, index: number) => {
            console.log('image', image)
            console.log('itemData.videoUrl', itemData.videoUrl)
            return ((image === itemData.videoUrl && image !== undefined) ?
              <div key={index}>
                <ReactPlayer
                  url={`${process.env.REACT_APP_IMAGE_SERVER}/${item.videoUrl}`}
                  // volume='1'
                  // muted
                  width='100%'
                  controls={true}
                  playing={true}
                />
            </div> :
            <div key={index}>
              <img
                src={`${process.env.REACT_APP_IMAGE_SERVER}/${image}`}
                alt="inventory"
              />
              <p className="legend">{index}</p>
            </div>
          )})}
          {/* {item.photoUrls.map((image: string, index: number) => (
            <div key={index}>
              <img
                src={`${process.env.REACT_APP_IMAGE_SERVER}/${image}`}
                alt="inventory"
              />
              <p className="legend">{index}</p>
            </div>
          ))} 
          
          {item.videoUrl ?
            <div>
              <ReactPlayer
                url={`${process.env.REACT_APP_IMAGE_SERVER}/${item.videoUrl}`}
                // volume='1'
                // muted
                width='100%'
                controls={true}
                playing={true}
              />
            </div> : ""
          }  */}
        </Carousel>
        <Stack>
          <Stack style={underCarouselInfo}>
            date: 12.12.202, stock: {item.stock}, location: Dealer Care
            Dealership Name 1 (State, City)
          </Stack>
        </Stack>
        <h3>Details</h3>
        <Stack horizontal style={{}}>
          <Stack className="inventoryDetailsTable">
            <div>
              <span>MAKE</span>
              <span>{item.make}</span>
            </div>
            <div>
              <span>MODEL</span>
              <span>{item.model}</span>
            </div>
            <div>
              <span>TRIM</span>
              <span>{item.trim}</span>
            </div>
            <div>
              <span>YEAR</span>
              <span>{item.year}</span>
            </div>
            <div className="spacer" />
            <div>
              <span>DRIVER TYPE</span>
              <span>{item.driveType}</span>
            </div>
            <div>
              <span>FUEL TYPE</span>
              <span>{getFuelTypeName(item.fuelType)}</span>
            </div>
            <div>
              <span>FUEL ECONOMY</span>
              <span>{inventoryTypes.getFuelEconomyName(item.fuelEconomy)}</span>
            </div>
            <div className="spacer" />
            <div>
              <span>BODY STYLE</span>
              <span>{item.body}</span>
            </div>
            <div>
              <span>DOORS</span>
              <span>{item.doors}</span>
            </div>
          </Stack>

          <Stack className="inventoryDetailsTable">
            <div>
              <span>CONDITION</span>
              <span>{item.vehCondition.join(", ")}</span>
            </div>
            <div>
              <span>VEHICLE HISTORY</span>
              <span>{item.vehHistory.join(", ")}</span>
            </div>
            <div>
              <span>MILEAGE</span>
              <span>{item.miles}</span>
            </div>
            <div>
              <span>VIN</span>
              <span>{item.vin}</span>
            </div>
            <div className="spacer" />
            <div>
              <span>ENGINE CYLINDERS</span>
              <span>{item.engineCylinders}</span>
            </div>
            <div>
              <span>ENGINE DISPLACEMENT</span>
              <span>{item.engineDescription}</span>
            </div>
            <div>
              <span>TRANSMISSION</span>
              <span>{item.transmissionDescription}</span>
            </div>
            <div className="spacer" />
            <div>
              <span>EXTERIOR COLOR</span>
              <span>{item.exteriorColor}</span>
            </div>
            <div>
              <span>INTERIOR COLOR</span>
              <span>{item.intColorGeneric}</span>
            </div>
          </Stack>
        </Stack>

        <h3>Description</h3>
        <div dangerouslySetInnerHTML={{ __html: item.description }} />

        <h3>Vehicle Features</h3>
        {accordionElements.map((element: IAccordionElement) => (
          <Stack
            styles={accordionStyles}
            key={element.optionsName}
            className={`accordion ${
              activeAccordion === element.optionsName ? "active" : ""
            }`}
          >
            <h4>{element.label}</h4>
            <span
              className="accordion-arrow"
              onClick={() => {
                setAccordionActive(element.optionsName);
              }}
            />

            <Stack
              className="accordion-content"
              horizontal
              style={{ flexWrap: "wrap" }}
            >
              {item[element.optionsName]?.map((option: string) => (
                <span key={option} style={{ width: "25%" }}>
                  <FontIcon iconName="Accept" style={checkIcon} />
                  {option}
                </span>
              ))}
            </Stack>
          </Stack>
        ))}

        <Stack
          styles={accordionStyles}
          key="estimatedValuation"
          className={`accordion ${
            activeAccordion === "estimatedValuation" ? "active" : ""
          }`}
        >
          <h4>Estimated Valuation</h4>
          <span
            className="accordion-arrow"
            onClick={() => {
              setAccordionActive("estimatedValuation");
            }}
          />
          {activeAccordion === "estimatedValuation" && (
            <Stack
              horizontal
              horizontalAlign="space-around"
              style={{ height: 170 }}
            >
              {/*<Stack verticalAlign="space-around" horizontalAlign="center">*/}
              {/*  <div className="imageKelleyBlueBook" />*/}

              {/*  {valuationKelley !== "" ? (*/}
              {/*    <div style={{ position: "relative" }}>*/}
              {/*      <span*/}
              {/*        style={{*/}
              {/*          fontWeight: "bold",*/}
              {/*          position: "absolute",*/}
              {/*          top: 0,*/}
              {/*          left: -8,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        $*/}
              {/*      </span>*/}
              {/*      <CustomFontText size={24} bold>*/}
              {/*        {valuationKelley}*/}
              {/*      </CustomFontText>*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    <PrimaryButton*/}
              {/*      onRenderIcon={() =>*/}
              {/*        loadingKelley ? (*/}
              {/*          <Spinner styles={{}} size={SpinnerSize.medium} />*/}
              {/*        ) : null*/}
              {/*      }*/}
              {/*      text="Check Now"*/}
              {/*      onClick={() =>*/}
              {/*        handleValuation(ValuationInfoApiSource.kelley)*/}
              {/*      }*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Stack>*/}
              <Stack verticalAlign="space-around" horizontalAlign="center">
                <div className="imageNadaLogo" />

                {valuationNada !== "" ? (
                  <div style={{ position: "relative" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        position: "absolute",
                        top: 0,
                        left: -8,
                      }}
                    >
                      $
                    </span>
                    <CustomFontText size={24} bold>
                      {valuationNada}
                    </CustomFontText>
                  </div>
                ) : (
                  <PrimaryButton
                    onRenderIcon={() =>
                      loadingNada ? (
                        <Spinner styles={{}} size={SpinnerSize.medium} />
                      ) : null
                    }
                    text="Check Now"
                    onClick={() => handleValuation(ValuationInfoApiSource.nada)}
                  />
                )}
              </Stack>

              <Stack verticalAlign="space-around" horizontalAlign="center">
                <div className="imageBlackBook" />

                {valuationBlack !== "" ? (
                  <div style={{ position: "relative" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        position: "absolute",
                        top: 0,
                        left: -8,
                      }}
                    >
                      $
                    </span>
                    <CustomFontText size={24} bold>
                      {valuationBlack}
                    </CustomFontText>
                  </div>
                ) : (
                  <PrimaryButton
                    onRenderIcon={() =>
                      loadingBlack ? (
                        <Spinner styles={{}} size={SpinnerSize.medium} />
                      ) : null
                    }
                    text="Check Now"
                    onClick={() =>
                      handleValuation(ValuationInfoApiSource.black)
                    }
                  />
                )}
              </Stack>
            </Stack>
          )}

          {/*<Stack*/}
          {/*    className="accordion-content"*/}
          {/*    horizontal*/}
          {/*    style={{ flexWrap: "wrap" }}*/}
          {/*>*/}
          {/*  {item[element.optionsName].map((option: string) => (*/}
          {/*      <span key={option} style={{ width: "25%" }}>*/}
          {/*        <FontIcon iconName="Accept" style={checkIcon} />*/}
          {/*        {option}*/}
          {/*      </span>*/}
          {/*  ))}*/}
          {/*</Stack>*/}
        </Stack>
      </div>

      <div className={contentStyles.footer}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10 }}

          // styles={contentStyles.buttonsStyles}
        >
          <ViewAdditionalActionsDropdown
            modulePermissions={getModulePermissions(
              user.auths || [],
              "Inventory"
            )}
          />
          <PrimaryButton text="Close" onClick={() => handleOnDismiss()} />
        </Stack>
      </div>
    </Modal>
  ) : null;
};
