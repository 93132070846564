/* import {IDeal, IDealFilters, IDeals, IDealSettings} from "types/dealTypes"; */
import * as actions from "./dealTracker.types";
import { IFinanceProduct, IAppraisalTable, IServiceContract } from "types/dealTypes";
import { trimTypes } from "constants/constants";

export const handleGetSalesFinanceList = ({ id, callBck }: any): actions.handleGetSalesFinanceList => {
    return {
        type: actions.HANDLE_GET_SALES_FINANCE_LIST,
        payload: {
            id,
            callBck
        }
    }
};

export const handleGetSelectedSalesFinanceList = ({ id }: any): actions.handleGetSelectedSalesFinanceList => {
    return {
        type: actions.HANDLE_GET_SELECTED_SALES_FINANCE_LIST,
        payload: {
            id
        }
    }
};

export const handlePutSalesFinanceList = ({ id, salesInfo, financeInfo }: any): actions.handlePutSalesFinanceList => {
    return {
        type: actions.HANDLE_PUT_SALES_FINANCE_LIST,
        payload: {
            id, salesInfo, financeInfo
        }
    }
};

export const handleGetFinanceList = (vehID: any): actions.handleGetFinanceList => {
    return {
        type: actions.HANDLE_GET_FINANCE_LIST,
        payload: vehID
    }
};

export const handlePutFinanceList = (vehID: any): actions.handlePutFinanceList => {
    return {
        type: actions.HANDLE_PUT_FINANCE_LIST,
        payload: vehID
    }
};

export const handleGetSalesFinanceSuccess = (vehDet): actions.handleGetSalesFinanceSuccess => {
    return {
        type: actions.HANDLE_GET_SALES_FINANCE_SUCCESS,
        payload: vehDet,
    };
};

export const handleGetSelectedSalesFinanceSuccess = (vehDet): actions.handleGetSelectedSalesFinanceSuccess => {
    return {
        type: actions.HANDLE_GET_SELECTED_SALES_FINANCE_SUCCESS,
        payload: vehDet,
    };
};

/*** Search Customer Number ****/

export const handleSearchDealRecord = ({
    deal,
    callback,
}): actions.HandleSearchDealRecord => {
    return {
        type: actions.HANDLE_SEARCH_DEAL_RECORD,
        payload: { deal, callback },
    };
};

export const handleCreateDealRecord = ({
    deal,
    callback,
}): actions.HandleCreateDealRecord => {
    return {
        type: actions.HANDLE_CREATE_DEAL_RECORD,
        payload: { deal, callback },
    };
};

export const handleSaveDealRecord = ({
    id,
    deal,
    callback
}): actions.HandleSaveDealRecord => {
    return {
        type: actions.HANDLE_SAVE_DEAL_RECORD,
        payload: { id, deal, callback },
    };
};

/**View Appointment **/
export const handleGetDelivery = ({
    id,
    callback
}): actions.handleGetDelivery => {
    return {
        type: actions.HANDLE_GET_DELIVERY,
        payload: { id, callback },
    };
};

/**Create and Save Delivery **/
export const handleSaveDelivery = ({
    id,
    deal,
    callback
}): actions.HandleSaveDelivery => {
    return {
        type: actions.HANDLE_SAVE_DELIVERY,
        payload: { id, deal, callback },
    };
};

/** VIEW_DEAL */
export const handleViewDealTracker = (id: number | string, callBck = null): actions.HandleViewDealTracker => {
    return {
        type: actions.HANDLE_VIEW_DEAL_TRACKER,
        payload: { id, callBck },
    };
};

export const handleViewDealNoTracker = (id: number | string, locationId: number | string, callBck = null): actions.HandleViewDealNoTracker => {
    return {
        type: actions.HANDLE_VIEW_DEAL_NO_TRACKER,
        payload: { id, locationId, callBck },
    };
};

export const pendingViewDealTracker = (): actions.ViewDealTrackerPending => {
    return {
        type: actions.VIEW_DEAL_TRACKER_PENDING,
    };
};

export const resolvedViewDealTracker = (deal: any): actions.ViewDealTrackerResolved => {
    return {
        type: actions.VIEW_DEAL_TRACKER_RESOLVED,
        payload: deal,
    };
};

export const resolvedUpdateDelVehTracker = (deal: any): actions.UpdateDelVehTrackerResolved => {
    return {
        type: actions.UPDATE_DEL_VEH_TRACKER_RESOLVED,
        payload: deal,
    };
};

export const rejectedViewDealTracker = (
    errors: {
        propertyPath: string;
        title: string;
        parameters: object;
        type: string;
    }[]
): actions.ViewDealTrackerRejected => {
    return {
        type: actions.VIEW_DEAL_TRACKER_REJECTED,
        payload: errors,
    };
};

/** Deal List */
export const handleGetDealRecords = (): actions.HandleGetDealsTracker => {
    return {
        type: actions.HANDLE_GET_DEALS_TRACKER
    };
};

export const pendingGetDealsTracker = (): actions.GetDealsTrackerPending => {
    return {
        type: actions.GET_DEALS_TRACKER_PENDING,
    };
};

export const resolvedGetDealsTracker = (deal: any): actions.GetDealsTrackerResolved => {
    return {
        type: actions.GET_DEALS_TRACKER_RESOLVED,
        payload: deal,
    };
};

export const rejectedGetDealsTracker = (
    errors: any
): actions.GetDealsTrackerRejected => {
    return {
        type: actions.GET_DEALS_TRACKER_REJECTED,
        payload: errors,
    };
};

/* Delivery Notes*/

export const HandleSaveDeliveryNote = (note): actions.handleSaveDeliveryNote => {
    return {
        type: actions.HANDLE_SAVE_DELIVERY_NOTE,
        payload: note
    }
};

export const HandleGetDeliveryNotes = (obj): actions.handleGetDeliveryNotes => {
    return {
        type: actions.HANDLE_GET_DELIVERY_NOTES,
        payload: obj
    }
};

/* Deals TradeIN Notes*/

export const HandleSaveDealsTradeInNotesUsingID = (details): actions.handleSaveDealsTradeInNotesUsingID => {
    return {
        type: actions.HANDLE_SAVE_DEALS_TRADEIN_NOTES_ID,
        payload: details
    }
};

export const HandleGetDealsTradeInNotesUsingID = (params): actions.handleGetDealsTradeInNotesUsingID => {
    return {
        type: actions.HANDLE_GET_DEALS_TRADEIN_NOTES_ID,
        payload: params
    }
};

/* Deals Appointments Notes*/

export const handleSaveDealsAppointmentsNotesUsingID = (details): actions.HandleSaveDealsAppointmentsNotesUsingID => {
    return {
        type: actions.HANDLE_SAVE_DEALS_APPOINTMENTS_NOTES_ID,
        payload: details
    }
};

export const handleGetDealsAppointmentsNotesUsingID = (params): actions.HandleGetDealsAppointmentsNotesUsingID => {
    return {
        type: actions.HANDLE_GET_DEALS_APPOINTMENTS_NOTES_ID,
        payload: params
    }
};


export const handleGetDealNotes = (params): actions.HandleGetDealNotes => {
    return {
        type: actions.HANDLE_GET_DEAL_NOTES,
        payload: params
    }
};



/* Finance Product */

// GET_FINANCEPRODUCT_LIST
export const pendingGetFinanceProduct = (): actions.GetFinanceProductPending => {
    return {
        type: actions.GET_FINANCEPRODUCT_LIST_PENDING,
    };
};

export const handleGetFinanceProduct = (id) => {
    return {
        type: actions.HANDLE_GET_FINANCEPRODUCT_LIST,
        payload: { id },
    };
};

export const resolvedGetFinanceProduct = (
    results: IFinanceProduct[]
): actions.GetFinanceProductResolved => {
    return {
        type: actions.GET_FINANCEPRODUCT_LIST_RESOLVED,
        payload: { results },
    };
};

export const rejectedGetFinanceProduct = (
    errors: any
): actions.GetFinanceProductRejected => {
    return {
        type: actions.GET_FINANCEPRODUCT_LIST_REJECTED,
        payload: errors,
    };
};

/** VIEW_FINANCEPRODUCT_LIST */
export const handleViewFinanceProductItem = (
    formData: any
): actions.HandleViewFinanceProductItem => {
    return {
        type: actions.HANDLE_VIEW_FINANCEPRODUCT_LIST_ITEM,
        payload: formData,
    };
};



/** UPDATE_FINANCEPRODUCT_LIST */
export const handleUpdateFinanceProductItem = (formData: any
): actions.HandleUpdateFinanceProductItem => {
    return {
        type: actions.HANDLE_UPDATE_FINANCEPRODUCT_LIST_ITEM,
        payload: formData,
    };
};



// CREATE FINANCEPRODUCT_LIST
export const handleCreateFinanceProductItem = (
    formData: any
): actions.HandleCreateFinanceProductItem => {
    return {
        type: actions.HANDLE_CREATE_FINANCEPRODUCT_LIST_ITEM,
        payload: formData
    };
};

/** DELETE_FINANCEPRODUCT_LIST */
export const handleDeleteFinanceProductItem = (
    formData: any,
): actions.HandleDeleteFinanceProductItem => {
    return {
        type: actions.HANDLE_DELETE_FINANCEPRODUCT_LIST_ITEM,
        payload: formData
    };
};

/** Appointment List **/
export const handleGetAppointments = (): actions.HandleGetAppointments => {
    return {
        type: actions.HANDLE_GET_APPOINTMENTS
    };
};

export const pendingGetAppointments = (): actions.GetAppointmentsPending => {
    return {
        type: actions.GET_APPOINTMENTS_PENDING,
    };
};

export const resolvedGetAppointments = (deal: any): actions.GetAppointmentsResolved => {
    return {
        type: actions.GET_APPOINTMENTS_RESOLVED,
        payload: deal,
    };
};

export const rejectedGetAppointments = (errors: any): actions.GetAppointmentsRejected => {
    return {
        type: actions.GET_APPOINTMENTS_REJECTED,
        payload: errors,
    };
};

/** Get Appointment By Deal **/
export const handleGetAppointmentsByDeal = ({
    id,
    callback
}): actions.HandleGetAppointmentsByDeal => {
    return {
        type: actions.HANDLE_GET_APPOINTMENTS_BY_DEAL,
        payload: { id, callback },
    };
};

/**Save Appointment **/
export const handleSaveAppointment = ({
    dealId,
    appointment,
    callback
}): actions.HandleSaveAppointment => {
    return {
        type: actions.HANDLE_SAVE_APPOINTMENT,
        payload: { dealId, appointment, callback },
    };
};

/**View Appointment **/
export const handleGetAppointmentInfo = ({
    dealId,
    appointmentId,
    callback
}): actions.handleGetAppointmentInfo => {
    return {
        type: actions.HANDLE_GET_APPOINTMENT_INFO,
        payload: { dealId, appointmentId, callback },
    };
};

/** Appraisal List **/
export const handleGetAppraisals = (): actions.HandleGetAppraisals => {
    return {
        type: actions.HANDLE_GET_APPRAISALS
    };
};

export const pendingGetAppraisals = (): actions.GetAppraisalsPending => {
    return {
        type: actions.GET_APPRAISALS_PENDING,
    };
};

export const resolvedGetAppraisals = (deal: any): actions.GetAppraisalsResolved => {
    return {
        type: actions.GET_APPRAISALS_RESOLVED,
        payload: deal,
    };
};

export const rejectedGetAppraisals = (errors: any): actions.GetAppraisalsRejected => {
    return {
        type: actions.GET_APPRAISALS_REJECTED,
        payload: errors,
    };
};

export const handleSelectedTradeIn = (tradeInId: any): actions.HandleSelectedTradeIn => {
    return {
        type: actions.HANDLE_SELECTED_TRADEIN,
        payload: tradeInId,
    };
};

/** Get Appraisal By Deal **/
export const handleGetAppraisalsByDeal = ({
    dealId,
    callback
}): actions.HandleGetAppraisalsByDeal => {
    return {
        type: actions.HANDLE_GET_APPRAISALS_BY_DEAL,
        payload: { dealId, callback },
    };
};

/**Save Appraisal **/
export const handleSaveAppraisal = ({
    dealId,
    appraisal,
    callback
}): actions.HandleSaveAppraisal => {
    return {
        type: actions.HANDLE_SAVE_APPRAISAL,
        payload: { dealId, appraisal, callback },
    };
};

/**View Appraisal **/
export const handleGetAppraisalInfo = ({
    dealId,
    appraisalId,
    callback
}): actions.handleGetAppraisalInfo => {
    return {
        type: actions.HANDLE_GET_APPRAISAL_INFO,
        payload: { dealId, appraisalId, callback },
    };
};

/**Delete Appraisal **/
export const handleDeleteAppraisalItem = ({
    dealId,
    appraisalId,
    callback
}): actions.HandleDeleteAppraisalItem => {
    return {
        type: actions.HANDLE_DELETE_APPRAISAL_ITEM,
        payload: { dealId, appraisalId, callback },
    };
};

/** Existing Vehicle Found**/
export const handleCreateVehicleStockItem = ({
    id,
    formData,
    callback,
}): actions.HandleCreateVehicleStockItem => {
    return {
        type: actions.HANDLE_CREATE_VEHICLE_STOCK_ITEM,
        payload: { id, formData, callback }
    };
};

export const handleUpdateVehicleStockItem = ({
    id,
    vehicleId,
    formData,
    callback,
}): actions.HandleUpdateVehicleStockItem => {
    return {
        type: actions.HANDLE_UPDATE_VEHICLE_STOCK_ITEM,
        payload: { id, vehicleId, formData, callback }
    };
};

export const handleCreateVehicleOrderItem = ({
    id,
    formData,
    callback,
}): actions.HandleCreateVehicleOrderItem => {
    return {
        type: actions.HANDLE_CREATE_VEHICLE_ORDER_ITEM,
        payload: { id, formData, callback }
    };
};


/* Appraisal TAble*/

// GET_APPRAISAL_TABLE_LIST
export const pendingGetAppraisalTable = (): actions.GetAppraisalTablePending => {
    return {
        type: actions.GET_APPRAISAL_TABLE_LIST_PENDING,
    };
};

export const handleGetAppraisalTable = (dealId) => {
    return {
        type: actions.HANDLE_GET_APPRAISAL_TABLE_LIST,
        payload: { dealId },
    };
};

export const resolvedGetAppraisalTable = (
    results: IAppraisalTable[]
): actions.GetAppraisalTableResolved => {
    return {
        type: actions.GET_APPRAISAL_TABLE_LIST_RESOLVED,
        payload: { results },
    };
};

export const rejectedGetAppraisalTable = (
    errors: any
): actions.GetAppraisalTableRejected => {
    return {
        type: actions.GET_APPRAISAL_TABLE_LIST_REJECTED,
        payload: errors,
    };
};



// CREATE APPRAISAL_TABLE_LIST
export const handleCreateAppraisalTableItem = (
    formData: any
): actions.HandleCreateAppraisalTableItem => {
    return {
        type: actions.HANDLE_CREATE_APPRAISAL_TABLE_LIST_ITEM,
        payload: formData
    };
};



/**Get Deal Tracker **/
export const handleGetDealTracker = ({
    id,
    callback
}): actions.handleGetDealTracker => {
    return {
        type: actions.HANDLE_GET_DEAL_TRACKER,
        payload: { id, callback },
    };
};


/** ServiceContract List **/
export const handleGetServiceContract = (): actions.HandleGetServiceContract => {
    return {
        type: actions.HANDLE_GET_SERVICE_CONTRACT
    };
};

export const pendingGetServiceContract = (): actions.GetServiceContractPending => {
    return {
        type: actions.GET_SERVICE_CONTRACT_PENDING,
    };
};

export const resolvedGetServiceContract = (deal: any): actions.GetServiceContractResolved => {
    return {
        type: actions.GET_SERVICE_CONTRACT_RESOLVED,
        payload: deal,
    };
};

export const rejectedGetServiceContract = (errors: any): actions.GetServiceContractRejected => {
    return {
        type: actions.GET_SERVICE_CONTRACT_REJECTED,
        payload: errors,
    };
};


/**Save ServiceContract **/
export const handleSaveServiceContract = ({
    id,
    serviceContract,
    callback
}): actions.HandleSaveServiceContract => {
    return {
        type: actions.HANDLE_SAVE_SERVICE_CONTRACT,
        payload: { id, serviceContract, callback },
    };
};

/**deal get ServiceContract **/

export const pendingGetServiceContractList = (): actions.GetServiceContractListPending => {
    return {
        type: actions.GET_SERVICE_CONTRACT_LIST_PENDING,
    };
};

export const handleGetServiceContractList = (id) => {
    return {
        type: actions.HANDLE_GET_SERVICE_CONTRACT_LIST,
        payload: { id },
    };
};

export const resolvedGetServiceContractList = (
    results: IServiceContract[]
): actions.GetServiceContractListResolved => {
    return {
        type: actions.GET_SERVICE_CONTRACT_LIST_RESOLVED,
        payload: { results },
    };
};

export const rejectedGetServiceContractList = (
    errors: any
): actions.GetServiceContractListRejected => {
    return {
        type: actions.GET_SERVICE_CONTRACT_LIST_REJECTED,
        payload: errors,
    };
};

/** DEAL VIEW_SERVICE_CONTRACT_LIST */
export const handleViewServiceContractItem = (
    formData: any
): actions.HandleViewServiceContractItem => {
    return {
        type: actions.HANDLE_VIEW_SERVICE_CONTRACT_LIST_ITEM,
        payload: formData,
    };
};


/**DEAL  UPDATE_SERVICE_CONTRACT_LIST */
export const handleUpdateServiceContractItem = (formData: any
): actions.HandleUpdateServiceContractItem => {
    return {
        type: actions.HANDLE_UPDATE_SERVICE_CONTRACT_LIST_ITEM,
        payload: formData,
    };
};

/** Task List */
export const handleGetTasks = (): actions.HandleGetTasks => {
    return {
        type: actions.HANDLE_GET_TASKS
    };
};

export const pendingGetTasks = (): actions.GetTasksPending => {
    return {
        type: actions.GET_TASKS_PENDING,
    };
};

export const resolvedGetTasks = (tasks: any): actions.GetTasksResolved => {
    return {
        type: actions.GET_TASKS_RESOLVED,
        payload: tasks,
    };
};

export const rejectedGetTasks = (
    errors: any
): actions.GetTasksRejected => {
    return {
        type: actions.GET_TASKS_REJECTED,
        payload: errors,
    };
};

/**Get Task By Deal**/
export const handleGetTaskByDeal = ({
    id,
    callback
}): actions.HandleGetTasksByDeal => {
    return {
        type: actions.HANDLE_GET_TASKS_BY_DEAL,
        payload: { id, callback },
    };
};

/**View Task**/
export const handleGetTask = ({
    dealId,
    taskId,
    callback
}): actions.HandleGetTask => {
    return {
        type: actions.HANDLE_GET_TASK,
        payload: { dealId, taskId, callback },
    };
};

/**Create Task**/
export const handleCreateTask = ({
    id,
    task,
    callback
}): actions.HandleCreateTask => {
    return {
        type: actions.HANDLE_CREATE_TASK,
        payload: { id, task, callback },
    };
};

/**Update Task Status **/
export const handleUpdateTaskStatus = ({
    id,
    taskId,
    taskStatus,
    callback
}): actions.HandleUpdateTaskStatus => {
    return {
        type: actions.HANDLE_UPDATE_TASK_STATUS,
        payload: { id, taskId, taskStatus, callback },
    };
};

/**Get Task Notes**/
export const handleGetTaskNotes = ({
    id,
    taskId,
    callback
}): actions.HandleGetTaskNotes => {
    return {
        type: actions.HANDLE_GET_TASK_NOTES,
        payload: { id, taskId, callback },
    };
};

/**Create Task**/
export const handleSaveTaskNote = ({
    id,
    taskId,
    note,
    callback
}): actions.HandleSaveTaskNote => {
    return {
        type: actions.HANDLE_SAVE_TASK_NOTE,
        payload: { id, taskId, note, callback },
    };
};

/** Sales Hierarchy List */
export const handleGetSalesHierarchy = (): actions.HandleGetSalesHierarchy => {
    return {
        type: actions.HANDLE_GET_SALES_HIERARCHY
    };
};

export const pendingGetSalesHierarchy = (): actions.GetSalesHierarchyPending => {
    return {
        type: actions.GET_SALES_HIERARCHY_PENDING,
    };
};

export const resolvedGetSalesHierarchy = (tasks: any): actions.GetSalesHierarchyResolved => {
    return {
        type: actions.GET_SALES_HIERARCHY_RESOLVED,
        payload: tasks,
    };
};

export const rejectedGetSalesHierarchy = (
    errors: any
): actions.GetSalesHierarchyRejected => {
    return {
        type: actions.GET_SALES_HIERARCHY_REJECTED,
        payload: errors,
    };
};

/**Get Sales Target**/
export const handleGetSalesTarget = ({
    year,
    isFirstTime,
    callback
}): actions.HandleGetSalesTarget => {
    return {
        type: actions.HANDLE_GET_SALES_TARGET,
        payload: { year, isFirstTime, callback },
    };
};

/**Save Sales Target**/
export const handleSaveSalesTarget = ({
    salesTarget,
    callback
}): actions.HandleSaveSalesTarget => {
    return {
        type: actions.HANDLE_SAVE_SALES_TARGET,
        payload: { salesTarget, callback },
    };
};


/** Cancellation List */
export const handleGetCancellations = (): actions.HandleGetCancellations => {
    return {
        type: actions.HANDLE_GET_CANCELLATIONS
    };
};

export const pendingGetCancellations = (): actions.GetCancellationsPending => {
    return {
        type: actions.GET_CANCELLATIONS_PENDING,
    };
};

export const resolvedGetCancellations = (tasks: any): actions.GetCancellationsResolved => {
    return {
        type: actions.GET_CANCELLATIONS_RESOLVED,
        payload: tasks,
    };
};

export const rejectedGetCancellations = (
    errors: any
): actions.GetCancellationsRejected => {
    return {
        type: actions.GET_CANCELLATIONS_REJECTED,
        payload: errors,
    };
};

/**Schedule**/
export const handleGetAllFinanceDeliveryPerson = ({
    locationId,
    callback
}): actions.handleGetAllFinDelPerson => {
    return {
        type: actions.HANDLE_GET_ALL_FIN_Del_PERSON,
        payload: { locationId, callback }
    };
};

export const handleGetAllFinanceDeliveryApptDate = ({
    role,
    date,
    callback
}): actions.handleGetAllFinDelApptDate => {
    return {
        type: actions.HANDLE_GET_ALL_FIN_Del_APPT_DATE,
        payload: { role, date, callback }
    };
};

export const handlePostAllFinanceDeliveryApptDate = ({
    scheduleData,
    callback
}): actions.handlePostAllFinDelApptDate => {
    return {
        type: actions.HANDLE_POST_ALL_FIN_Del_APPT_DATE,
        payload: { scheduleData, callback }
    };
};

export const handleGetAllFinanceDeliverySchedule = ({
    date,
    callback
}): actions.handleGetAllFinanceDeliverySchedule => {
    return {
        type: actions.HANDLE_GET_ALL_FIN_Del_SCHEDULE,
        payload: { date, callback }
    };
};

export const handlePostAllFinanceDeliveryDeal = ({
    dealId,
    scheduleData,
    callback
}): actions.handlePostAllFinanceDeliveryDeal => {
    return {
        type: actions.HANDLE_POST_ALL_FIN_Del_DEAL,
        payload: { dealId, scheduleData, callback }
    };
};

export const handleDelAllFinanceDeliveryDeal = ({
    scheduleId,
    callback
}): actions.handleDelAllFinanceDeliveryDeal => {
    return {
        type: actions.HANDLE_DEL_ALL_FIN_Del_DEAL,
        payload: { scheduleId, callback }
    };
};

export const handlePatchDelAllFinanceDeliveryDeal = ({
    dealId,
    scheduleData,
    role,
    method,
    callback
}): actions.handlePatchDelAllFinanceDeliveryDeal => {
    return {
        type: actions.HANDLE_PATCH_DEL_ALL_FIN_Del_DEAL,
        payload: { dealId, scheduleData, role, method, callback }
    };
};

export const handleGetAllFinanceDeliveryByDeal = ({
    dealId,
    callback
}): actions.handleGetAllFinanceDeliveryByDeal => {
    return {
        type: actions.HANDLE_GET_ALL_FIN_Del_BY_DEAL,
        payload: { dealId, callback }
    };
};

/** END of Schedule**/

/**Get Cancellations By Deal**/
export const handleGetCancellationsByDeal = ({
    dealId,
    callback
}): actions.HandleGetCancellationsByDeal => {
    return {
        type: actions.HANDLE_GET_CANCELLATIONS_BY_DEAL,
        payload: { dealId, callback },
    };
};

/**Get Cancellation Detail**/
export const handleGetCancellationInfo = ({
    dealId,
    cancellationId,
    callback
}): actions.HandleGetCancellationInfo => {
    return {
        type: actions.HANDLE_GET_CANCELLATION_INFO,
        payload: { dealId, cancellationId, callback },
    };
};

/**Save Cancellation **/
export const handleSaveCancellation = ({
    dealId,
    formData,
    callback
}): actions.HandleSaveCancellation => {
    return {
        type: actions.HANDLE_SAVE_CANCELLATION,
        payload: { dealId, formData, callback },
    };
};

/**Print Cancellation **/
export const handlePrintDealCancellation = ({
    dealId,
    callback
}): actions.HandlePrintDealCancellation => {
    return {
        type: actions.HANDLE_PRINT_DEAL_CANCELLATION,
        payload: { dealId, callback },
    };
};

export const handlePrintDealCancellationListItem = ({
    dealId,
    cancellationId,
    callback
}): actions.HandlePrintDealCancellationListItem => {
    return {
        type: actions.HANDLE_PRINT_DEAL_CANCELLATION_LIST_ITEM,
        payload: { dealId, cancellationId, callback },
    };
};

/**Get Deals Summary**/
export const handleGetDealsSummary = ({
    callback
}): actions.HandleGetDealsSummary => {
    return {
        type: actions.HANDLE_GET_DEALS_SUMMARY,
        payload: { callback },
    };
};

/**Vehicle Preps**/
export const handleGetPreps = ({
    date,
    carType,
    locationId,
    callback
}): actions.HandleGetPreps => {
    return {
        type: actions.HANDLE_GET_PREPS,
        payload: { date, carType, locationId, callback },
    };
};

export const handleGetPrepsByDeal = ({
    dealId,
    date,
    carType,
    callback
}): actions.HandleGetPrepsByDeal => {
    return {
        type: actions.HANDLE_GET_PREPS_BY_DEAL,
        payload: { dealId, date, carType, callback },
    };
};

export const handleGetPrepsInfo = ({
    id,
    callback
}): actions.HandleGetPrepsInfo => {
    return {
        type: actions.HANDLE_GET_PREPS_INFO,
        payload: { id, callback },
    };
};

export const handleSavePreps = ({
    formData,
    callback
}): actions.HandleSavePreps => {
    return {
        type: actions.HANDLE_SAVE_PREPS,
        payload: { formData, callback },
    };
};

export const handleSavePrepsByDeal = ({
    formData,
    dealId,
    callback
}): actions.HandleSavePrepsByDeal => {
    return {
        type: actions.HANDLE_SAVE_PREPS_BY_DEAL,
        payload: { formData, dealId, callback },
    };
};

export const handleDeletePrepsInfo = ({
    id,
    callback
}): actions.HandleDeletePrepsInfo => {
    return {
        type: actions.HANDLE_DELETE_PREPS_INFO,
        payload: { id, callback },
    };
};
/**Enf of Vehicle Preps**/

/**Dealer Trade**/
export const handleGetDealerTrades = ({
    tradeType,
    callback
}): actions.HandleGetDealerTrades => {
    return {
        type: actions.HANDLE_GET_DEALER_TRADES,
        payload: { tradeType, callback },
    };
};

export const handleGetDealerTradesV2 = ({
    formData,
    callback
}): actions.HandleGetDealerTradesV2 => {
    return {
        type: actions.HANDLE_GET_DEALER_TRADES_V2,
        payload: { formData, callback },
    };
};

export const handleGetDealerTradeInfo = ({
    id,
    callback
}): actions.HandleGetDealerTradeInfo => {
    return {
        type: actions.HANDLE_GET__DEALER_TRADE_INFO,
        payload: { id, callback },
    };
};

export const handleSaveDealerTrade = ({
    formData,
    callback
}): actions.HandleSaveDealerTrade => {
    return {
        type: actions.HANDLE_SAVE_DEALER_TRADE,
        payload: { formData, callback },
    };
};
/**Enf of Vehicle Preps**/

/***Dealer Trade Vehicle***/
export const handleGetDTVehicleTrimUsingVin = ({
    vin,
    id,
    tradeType,
    trimType = trimTypes.karl,
    callback
}): actions.HandleGetDTVehicleTrimUsingVin => {
    return {
        type: actions.HANDLE_GET_DT_VEHICLE_TRIM_VIN,
        payload: {
            vin,
            trimType,
            id,
            tradeType,
            callback
        },
    };
};

export const handleGetDTVehicleTrimUsingYmm = ({
    ymm,
    id,
    tradeType,
    trimType = trimTypes.nonKarl,
    callback
}): actions.HandleGetDTVehicleTrimUsingYmm => {
    return {
        type: actions.HANDLE_GET_DT_VEHICLE_TRIM_YMM,
        payload: {
            ymm,
            trimType,
            id,
            tradeType,
            callback
        },
    };
};

export const handleGetDealerTradeVehDetails = ({
    vehDet,
    id,
    action,
    tradeType,
    trimType = trimTypes.karl,
    callback
}): actions.HandleGetDTVehicleDetails => {
    return {
        type: actions.HANDLE_GET_DT_VEHICLE_DETAILS,
        payload: {
            vehDet,
            id,
            action,
            tradeType,
            trimType,
            callback
        },
    };
};

export const handleCreateDealerTradeVehicleStock = ({
    id,
    formData,
    vehicleAction,
    callback,
}): actions.HandleCreateDTVehicleStock => {
    return {
        type: actions.HANDLE_CREATE_DT_VEHICLE_STOCK,
        payload: { id, formData, vehicleAction, callback }
    };
};


export const handleUpdateDTVehicleStockItem = ({
    id,
    vehicleId,
    formData,
    vehicleAction,
    callback,
}): actions.HandleUpdateDTVehicleStockItem => {
    return {
        type: actions.HANDLE_UPDATE_DT_VEHICLE_STOCK_ITEM,
        payload: { id, vehicleId, formData, vehicleAction, callback }
    };
};

export const handleCheckVehicleStock = ({
    id,
    data,
    callback,
}): actions.HandleCheckVehicleStock => {
    return {
        type: actions.HANDLE_CHECK_VEHICLE_STOCK,
        payload: { id, data, callback }
    };
};

export const handleVehicleTrackerCheckStock = ({
    id,
    formData,
    callback,
}): actions.HandleVehicleTrackerCheckStock => {
    return {
        type: actions.HANDLE_VEHICLE_TRACKER_STOCK_CHECK,
        payload: { id, formData, callback }
    };
};

export const handleCheckVehicleOrder = ({
    id,
    data,
    callback,
}): actions.HandleCheckVehicleOrder => {
    return {
        type: actions.HANDLE_CHECK_VEHICLE_ORDER,
        payload: { id, data, callback }
    };
};

export const handleDealerTradeCheck = ({
    id,
    data,
    callback,
}): actions.HandleDealerTradeCheck => {
    return {
        type: actions.HANDLE_DEALER_TRADE_CHECK,
        payload: { id, data, callback }
    };
};

export const handleCheckDTVehicleStock = ({
    id,
    data,
    callback,
}): actions.HandleCheckDTVehicleStock => {
    return {
        type: actions.HANDLE_CHECK_DT_VEHICLE_STOCK,
        payload: { id, data, callback }
    };
};

export const handleCheckDTVehicleOrder = ({
    id,
    data,
    callback,
}): actions.HandleCheckDTVehicleOrder => {
    return {
        type: actions.HANDLE_CHECK_DT_VEHICLE_ORDER,
        payload: { id, data, callback }
    };
};
/***Reports***/

export const handleGetsFinnaceDeskingReports = ({

    formData,callback
}): actions.HandleGetFinnaceDeskingReports => {

    return {
        type: actions.HANDLE_GET_FINNANCE_DESKING_REPORTS,
        payload: { formData,callback}
    };    
};

export const handleGetLenderCountReports = ({

    formData,callback
}): actions.HandleGetLenderCountReports => {

    return {
        type: actions.HANDLE_GET_LENDER_COUNT_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetFinanceProductSalesReports = ({

    formData,callback
}): actions.HandleGetFinanceProductSalesReports => {

    return {
        type: actions.HANDLE_GET_FINANCE_PRODUCT_SALES_REPORTS,
        payload: { formData,callback}
    };
};


export const handleGetFinanceReserveSplitsReports = ({

    formData,callback
}): actions.HandleGetFinanceReserveSplitsReports => {
    return {
        type: actions.HANDLE_GET_FINANCE_RESERVE_SPLITS_REPORTS,
        payload: { formData,callback}  
    };
};

export const handleGetFinanceProductSplitsReports = ({

    formData,callback
}): actions.HandleGetFinanceProductSplitsReports => {

    return {
        type: actions.HANDLE_GET_FINANCE_PRODUCT_SPLITS_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetKarlDocReports = ({
    formData,callback
}): actions.HandleGetKarlDocReports => {
    return {
        type: actions.HANDLE_GET_KARL_DOC_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetPerCarTotals = ({
    formData,callback
}): actions.HandleGetPerCarTotals => {
    return {
        type: actions.HANDLE_GET_PER_CAR_TOTALS,
        payload: { formData,callback}
    };
};

export const handleGetDashboardMikeReports = ({  
    callback
}): actions.HandleGetDashboardMikeReports => {

    return {
        type: actions.HANDLE_GET_DASHBOARD_MIKE_REPORTS, 
        payload: { callback}
    };
};

export const handleGetFinancePersonStackRankReports = ({

    formData,callback
}): actions.HandleGetFinancePersonStackRankReports => {

    return {
        type: actions.HANDLE_GET_FINANCE_PERSON_STACK_RANK_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetFinancePersonMonthEndReports = ({

    formData,callback
}): actions.HandleGetFinancePersonMonthEndReports => {

    return {
        type: actions.HANDLE_GET_FINANCE_PERSON_MONTH_END_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetVacationReports = ({
    formData, callback
}): actions.HandleGetVacationReports => {

    return {
        type: actions.HANDLE_GET_VACATION_REPORTS,
        payload: { formData, callback }
    };
};

export const handleGetSalesPersonFinanceReports = ({

    formData,callback
}): actions.HandleGetSalesPersonFinanceReports => {

    return {
        type: actions.HANDLE_GET_SALES_PERSON_FINANCE_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetSalesPersonMonthEndReports = ({

    formData,callback
}): actions.HandleGetSalesPersonMonthEndReports => {

    return {
        type: actions.HANDLE_GET_SALES_PERSON_MONTH_END_REPORTS,
        payload: { formData,callback}
    };
};

export const handleGetCdrCpoReports = ({
    formData, callback
}): actions.HandleGetCdrCpoReports => {

    return {
        type: actions.HANDLE_GET_CDR_CPO_REPORTS,
        payload: { formData, callback }
    };
};

/***KPI Stats***/
export const handleGetDealsKPIStats = ({locationId,callback}): actions.HandleGetDealsKPIStats => {        
    return {
        type: actions.HANDLE_GET_DEALS_KPI_STATS,    
        payload: { locationId, callback }
    };
};

/** Display Board */
export const handleGetDisplayboard = ({
    data,callback
}): actions.HandleGetDisplayboard => {
    return {
        type: actions.HANDLE_GET_DISPLAYBOARD,
        payload: { data, callback}
    };
};

/** Pending Deals Print */



export const handlePrintPendingDeal = ({
    formData,
    callback
}): actions.HandlePrintPendingDeal => {
    return {
        type: actions.HANDLE_PRINT_PENDING_DEAL,
        payload: {formData,callback },
    };
};

/** WriteUps List **/
export const handleGetWriteUpsList = ({
    formData, callback
}): actions.HandleGetWriteUpsList => {

    return {
        type: actions.HANDLE_GET_WRITEUPS_LIST,
        payload: { formData, callback }
    };
};


/** Appraisal List **/
export const handleGetAppraisalList = ({
    formData, callback
}): actions.HandleGetAppraisalList => {

    return {
        type: actions.HANDLE_GET_APPRAISALS_LIST,
        payload: { formData, callback }
    };
};

/** Appointments List **/
export const handleGetAppointmentList = ({
    formData, callback
}): actions.HandleGetAppointmentList => {

    return {
        type: actions.HANDLE_GET_APPOINTMENT_LIST,
        payload: { formData, callback }
    };
};

/** Service Contract List **/
export const handleGetServiceContractLists = ({
    formData, callback
}): actions.HandleGetServiceContractLists => {

    return {
        type: actions.HANDLE_GET_SERVICE_CONTRACT_LISTS,
        payload: { formData, callback }
    };
};

/** Cancellation List **/
export const handleGetCancellationLists = ({
    formData, callback
}): actions.HandleGetCancellationLists => {

    return {
        type: actions.HANDLE_GET_CANCELLATION_LISTS,
        payload: { formData, callback }
    };
};

/** Finance Detail Reports **/
export const handleGetFinanceDetailReports = ({
    formData, callback
}): actions.HandleGetFinanceDetailReports => {

    return {
        type: actions.HANDLE_GET_FINANCE_DETAIL_REPORTS,
        payload: { formData, callback }
    };
};

/** Finnace Detail Pdf **/

export const handlePrintFinanceDetailReports = ({
    formData,
    callback
}): actions.HandlePrintFinanceDetailReports => {
    return {
        type: actions.HANDLE_PRINT_FINANCE_DETAIL_REPORTS,
        payload: {formData,callback },
    };
};



/** Task List **/
export const handleGetTaskList = ({
    formData, callback
}): actions.HandleGetTaskList => {

    return {
        type: actions.HANDLE_GET_TASK_LIST,
        payload: { formData, callback }
    };
};

/** Sales Pending Report**/
export const handleGetSalesPendingReport = ({
    formData, callback
}): actions.HandleGetSalesPendingReport => {

    return {
        type: actions.HANDLE_GET_SALES_PENDING_REPORT,
        payload: { formData, callback }
    };
};

/** Sales Pending PDF **/

export const handlePrintSalesPendingReports = ({
    formData,
    callback
}): actions.HandlePrintSalesPendingReports => {
    return {
        type: actions.HANDLE_PRINT_SALES_PENDING_REPORTS,
        payload: {formData,callback },
    };
};

/** Deal Tracker Summary **/ 
export const handleGetDealTrackerSummary = ({
    formData, callback
}): actions.HandleGetDealTrackerSummary => {

    return {
        type: actions.HANDLE_GET_DEAL_TRACKER_SUMMARY,
        payload: { formData, callback }
    };
};

/** Finance Pending  **/ 
export const handleGetFinancePendingReport = ({
    formData, callback
}): actions.HandleGetFinancePendingReport => {

    return {
        type: actions.HANDLE_GET_FINANCE_PENDING_REPORT,
        payload: { formData, callback }
    };
};

export const handlePrintFinancePendingReport = ({
    formData, callback
}): actions.HandlePrintFinancePendingReport => {

    return {
        type: actions.HANDLE_PRINT_FINANCE_PENDING_REPORT,
        payload: { formData, callback }
    };
};

export const handleCreateVehicleTrackerList = ({
    formData,
    callback
}): actions.HandleCreateVehicleTrackerList => {
    return {
        type: actions.HANDLE_CREATE_VEHICLE_TRACKER_LIST,
        payload: { formData, callback },
    };
};

export const handleGetVehicleTrackerList = ({
    formData, callback
}): actions.HandleGetVehicleTrackerList => {

    return {
        type: actions.HANDLE_GET_VEHICLE_TRACKER_LIST,
        payload: { formData, callback }
    };
};

export const handleViewVehicleTrackerTransfer = ({
    id, formData,callback
}): actions.HandleViewVehicleTrackerTransfer => {

    return {
        type: actions.HANDLE_VIEW_VEHICLE_TRACKER_TRANSFER,
        payload: { id,formData,callback }
    };
};

export const handleCreateVehicleTransferList = ({
    id,formData, callback
}): actions.HandleCreateVehicleTransferList => {

    return {
        type: actions.HANDLE_CREATE_VEHICLE_TRANSFER_LIST,
        payload: { id,formData, callback }
    };
};

export const handleViewVehicleTransferList = ({
    id,vehTransferId,callback
}): actions.HandleViewVehicleTransferList => {

    return {
        type: actions.HANDLE_VIEW_VEHICLE_TRANSFER_UI_LIST,
        payload: { id,vehTransferId,callback }
    };
};

export const handleUpdateVehicleTransferList = ({
    id,vehTransferId,formData,callback
}): actions.HandleUpdateVehicleTransferList => {

    return {
        type: actions.HANDLE_UPDATE_VEHICLE_TRANSFER_LIST,
        payload: { id,vehTransferId,formData,callback }
    };
};

export const handleCreateVehicleTransferNote = ({
    id,vehTransferId,formData,callback
}): actions.HandleCreateVehicleTransferNote => {

    return {
        type: actions.HANDLE_CREATE_VEHICLE_TRANSFER_NOTE,
        payload: { id,vehTransferId,formData,callback }
    };
};

export const handleGetVehicleTransferNote = ({
    id,vehTransferId,callback
}): actions.HandleGetVehicleTransferNote => {

    return {
        type: actions.HANDLE_GET_VEHICLE_TRANSFER_NOTE,
        payload: { id,vehTransferId,callback }
    };
};

export const handleViewVehicleTracker = ({
    id,callback
}): actions.HandleViewVehicleTracker => {

    return {
        type: actions.HANDLE_VIEW_VEHICLE_TRACKER,
        payload: { id,callback }
    };
};

export const handleSoldVehicleTransfer = ({
    id,vehTransferId,formData,callback
}): actions.HandleSoldVehicleTransfer => {

    return {
        type: actions.HANDLE_SOLD_VEHICLE_TRANSFER,
        payload: { id,vehTransferId,formData,callback }
    };
};

export const handleVehicleTrackerWriteUpCheck = ({
    id,callback
}): actions.HandleVehicleTrackerWriteUpCheck => {

    return {
        type: actions.HANDLE_VEHICLE_TRACKER_WRITE_UP_CHECK,
        payload: { id,callback }
    };
};