import * as actions from "./dealerwebsite.types";
import { IPagination } from "types/paginationTypes";

export const pendingViewDealerWebsite =
  (): actions.ViewDealerWebsitePending => {
    return {
      type: actions.VIEW_DEALERWEBSITE_PENDING,
    };
  };

export const handleGetDealerWebsiteList = (
  request: IPagination
): actions.HandleGetDealerWebsite => {
  return {
    type: actions.HANDLE_GET_DEALERWEBSITE,
    payload: request,
  };
};

export const handleGetLandDealerWebsiteList = (
  request: IPagination
): actions.HandleGetLandDealerWebsite => {
  return {
    type: actions.HANDLE_GET_LAND_DEALERWEBSITE,
    payload: request,
  };
};


export const rejectedFetchDealerWebsite = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.GetDealerWebsiteRejected => {
  return {
    type: actions.GET_DEALERWEBSITE_REJECTED,
    payload: errors,
  };
};

export const handleCreateWebsite = ({
  dealerwebsite,
  callback,
}): actions.CreateWebsiteResolved => {
  return {
    type: actions.CREATE_WEBSITE_RESOLVED,
    payload: { dealerwebsite, callback },
  };
};

export const CreateWebsitePending = (): actions.CreateWebsitePending => {
  return {
    type: actions.CREATE_WEBSITE_PENDING,
  };
};

export const CreateWebsiteRejected = ({
  dealerwebsite,
  callback,
}): actions.CreateWebsiteRejected => {
  return {
    type: actions.CREATE_WEBSITE_REJECT,
    payload: { dealerwebsite, callback },
  };
};

export const HandleUpdateDealerWebsite = ({
  dealerwebsite,
  callback,
}): actions.HandleUpdateDealerWebsite => {
  return {
    type: actions.HANDLE_UPDATE_DEALERWEBSITE,
    payload: { dealerwebsite, callback },
  };
};

export const HandleUpdateDealerWebsitePending = (): actions.HandleUpdateDealerWebsitePending => {
  return {
    type: actions.HANDLE_UPDATE_DEALERWEBSITE_PENDING,
  };
};

export const HandleUpdateDealerWebsiteRejected = (
  errors: {
    propertyPath: string;
    title: string;
    parameters: object;
    type: string;
  }[]
): actions.HandleUpdateDealerWebsiteRejected => {
  return {
    type: actions.HANDLE_UPDATE_DEALERWEBSITE_REJECTED,
    payload: errors,
  };
};

export const HandleUpdateDealerWebsiteResolved = ({  
  dealerwebsite,
  callback,
}): actions.HandleUpdateDealerWebsiteResolved => {
  return {
    type: actions.HANDLE_UPDATE_DEALERWEBSITE_RESOLVED,
    payload: { dealerwebsite, callback },
  };
};

export const setSortDealerWebsite = (sort: string): actions.SetSortDealerWebsite => {
  return {
    type: actions.SET_SORT_DEALERWEBSITE,
    payload: sort
  };
};

export const setRowsPerPage = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_DEALERWEBSITE,
    payload: rows,
  };
};

export const setRowsPerPageLand = (rows: number) => {
  console.log(rows);
  return {
    type: actions.SET_ROWS_PER_PAGE_LAND_DEALERWEBSITE,
    payload: rows,
  };
};