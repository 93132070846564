import { IDeskADeal } from "types/deskADealTypes";

/** CREATE_DESK_A_DEAL */
export const HANDLE_CREATE_DESK_A_DEAL = "HANDLE_CREATE_DESK_A_DEAL";

export interface HandleCreateDeskADeal {
  type: typeof HANDLE_CREATE_DESK_A_DEAL;
  payload: {
    deskADeal: IDeskADeal;
    prospectId: number | string;
    inventoryId: number | string;
  };
}

export const CREATE_DESK_A_DEAL_PENDING = "CREATE_DESK_A_DEAL_PENDING";

export interface CreateDeskADealPending {
  type: typeof CREATE_DESK_A_DEAL_PENDING;
}

export const CREATE_DESK_A_DEAL_RESOLVED = "CREATE_DESK_A_DEAL_RESOLVED";

export interface CreateDeskADealResolved {
  type: typeof CREATE_DESK_A_DEAL_RESOLVED;
  payload: IDeskADeal;
}

export const CREATE_DESK_A_DEAL_REJECTED = "CREATE_DESK_A_DEAL_REJECTED";

export interface CreateDeskADealRejected {
  type: typeof CREATE_DESK_A_DEAL_REJECTED;
  payload: object;
}

/** GET_SAVED_DESK_A_DEALS */
export const HANDLE_GET_SAVED_DESK_A_DEALS = "HANDLE_GET_SAVED_DESK_A_DEALS";
export interface HandleGetSavedDeskADeals {
  type: typeof HANDLE_GET_SAVED_DESK_A_DEALS;
}

export const GET_SAVED_DESK_A_DEALS_PENDING = "GET_SAVED_DESK_A_DEALS_PENDING";
export interface GetSavedDeskADealsPending {
  type: typeof GET_SAVED_DESK_A_DEALS_PENDING;
}

export const GET_SAVED_DESK_A_DEALS_RESOLVED =
  "GET_SAVED_DESK_A_DEALS_RESOLVED";
export interface GetSavedDeskADealsResolved {
  type: typeof GET_SAVED_DESK_A_DEALS_RESOLVED;
  payload: IDeskADeal[];
}

export const GET_SAVED_DESK_A_DEALS_REJECTED =
  "GET_SAVED_DESK_A_DEALS_REJECTED";

export interface GetSavedDeskADealsRejected {
  type: typeof GET_SAVED_DESK_A_DEALS_REJECTED;
  payload: object;
}

/** DELETE_DESK_A_DEALS */
export const HANDLE_DELETE_DESK_A_DEAL = "HANDLE_DELETE_DESK_A_DEAL";
export interface HandleDeleteDeskADeal {
  type: typeof HANDLE_DELETE_DESK_A_DEAL;
  payload: { id: number; callback: () => void };
}

export const DELETE_DESK_A_DEAL_PENDING = "DELETE_DESK_A_DEAL_PENDING";
export interface DeleteDeskADealPending {
  type: typeof DELETE_DESK_A_DEAL_PENDING;
}

export const DELETE_DESK_A_DEAL_RESOLVED = "DELETE_DESK_A_DEAL_RESOLVED";
export interface DeleteDeskADealResolved {
  type: typeof DELETE_DESK_A_DEAL_RESOLVED;
}

export const DELETE_DESK_A_DEAL_REJECTED = "DELETE_DESK_A_DEAL_REJECTED";

export interface DeleteDeskADealRejected {
  type: typeof DELETE_DESK_A_DEAL_REJECTED;
  payload: object;
}

/** UPDATE_DESK_A_DEAL */
export const HANDLE_UPDATE_DESK_A_DEAL = "HANDLE_UPDATE_DESK_A_DEAL";

export interface HandleUpdateDeskADeal {
  type: typeof HANDLE_UPDATE_DESK_A_DEAL;
  payload: {
    deskADeal: IDeskADeal;
    deskADealId: number | string;
    prospectId: number | string;
    inventoryId: number | string;
  };
}

export const UPDATE_DESK_A_DEAL_PENDING = "UPDATE_DESK_A_DEAL_PENDING";

export interface UpdateDeskADealPending {
  type: typeof UPDATE_DESK_A_DEAL_PENDING;
}

export const UPDATE_DESK_A_DEAL_RESOLVED = "UPDATE_DESK_A_DEAL_RESOLVED";

export interface UpdateDeskADealResolved {
  type: typeof UPDATE_DESK_A_DEAL_RESOLVED;
  payload: IDeskADeal;
}

export const UPDATE_DESK_A_DEAL_REJECTED = "UPDATE_DESK_A_DEAL_REJECTED";

export interface UpdateDeskADealRejected {
  type: typeof UPDATE_DESK_A_DEAL_REJECTED;
  payload: object;
}

export type DeskADealActions =
  | HandleCreateDeskADeal
  | CreateDeskADealPending
  | CreateDeskADealRejected
  | CreateDeskADealResolved
  | HandleGetSavedDeskADeals
  | GetSavedDeskADealsPending
  | GetSavedDeskADealsResolved
  | GetSavedDeskADealsRejected
  | HandleDeleteDeskADeal
  | DeleteDeskADealPending
  | DeleteDeskADealRejected
  | DeleteDeskADealResolved
  | HandleUpdateDeskADeal
  | UpdateDeskADealPending
  | UpdateDeskADealRejected
  | UpdateDeskADealResolved;
