
import React, { useState } from "react";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  DefaultButton,
  Modal,
  IconButton,
  IModalStyles,
  PrimaryButton,
  TextField
} from "@fluentui/react";
import { Configuration } from "@react-md/layout";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { Stack } from "@fluentui/react/lib/Stack";
import {
  iconButtonStyles,
  cancelIcon,
} from "constants/styles";
import { styles } from "pages/accountSettingsPage/styles";


interface IProps {
  title: string;
  isOpen: boolean;
  onDismissSavePopup: () => void;
  onAction: (id, name) => void;
  filterId: any;
  description: string;
  loading: boolean;
  filterName: string;
  /**Should confirm box display only 'Cancel' button*/
  onlyDismiss?: boolean;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
}

const modal: IModalStyles = {
  keyboardMoveIcon: undefined,
  keyboardMoveIconContainer: undefined,
  layer: undefined,
  main: {
    height: "auto",
    minHeight: 0,
  },
  scrollableContent: {
    height: "100%",
    margin: 0,
    minHeight: 0,
    padding: 0,
  },
  root: {
    height: "100%",
    margin: 0,
    minHeight: 0,
    padding: 0,
  },
};

export const InventorySaveDialog: React.FunctionComponent<IProps> = ({
  onAction,
  onDismissSavePopup,
  isOpen,
  title,
  filterId,
  description,
  loading,
  filterName,
  confirmBtnLabel,
  cancelBtnLabel
}) => {

  const[selectedFilterName, setselectedFilterName] = useState(filterName);
  
  const onHandleChangeName = (e: any) => {
    if (e.target.value.length > 0) {
      setselectedFilterName(e.target.value)
    } else {
      setselectedFilterName('');
    }
  };

  const handleSaveInventories = () => {
    
    if (selectedFilterName === filterName) {
      
      onAction(filterId, selectedFilterName);
    } else {
      
      onAction(0, selectedFilterName);
    }
  };

  return (
    <Configuration>
    <div>
    <Modal
      isOpen={isOpen}
      onDismiss={onDismissSavePopup}
      isBlocking={true}
      containerClassName={contentStyles.container}
      styles={modal}
    >
      <div className={contentStyles.header}>
        <CustomFontText>{title}</CustomFontText>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={onDismissSavePopup} />
      </div>
      <div className={contentStyles.body}>
        
        <TextField
          type="string"
          id="filterName"
          name="filterName"
          label="Filter Name"
          value={selectedFilterName}
          onChange={onHandleChangeName}
        />
      </div>

        <div className={contentStyles.footer}>
          <Stack horizontal horizontalAlign="space-between">   
            <Stack tokens={styles.buttonTokens}
              horizontal
              horizontalAlign="end"
            >
              <DefaultButton text="Cancel" 
              onClick={onDismissSavePopup} 
              />
              <PrimaryButton
                disabled={!selectedFilterName}
                //={renderSpinner}
                text="Submit"
                onClick={handleSaveInventories}
              />
            </Stack>
          </Stack>
        </div>
        
      </Modal>
    </div>
  </Configuration>
  );
};

const theme1 = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexFlow: "column nowrap",
    // alignItems: "stretch",
    width: 600,
  },
  header: [
    {
      flex: "1",
      borderTop: `4px solid ${theme1.palette.themePrimary}`,
      color: theme1.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
  footer: {
    bottom: 0,
    alignItems: "right",
    fontWeight: FontWeights.semibold,
    padding: "20px 20px 20px 30px",
    position: "relative",
    // backgroundColor: "white",
    zIndex: 10,
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse"
  },
  buttonTokens: {
    childrenGap: "10",
  }
});
