import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import {
    Stack,
    IStackStyles,
    TextField,
    PrimaryButton,
    Link
} from "@fluentui/react";
import {
    useSelector
} from "react-redux";
import { AppState } from "store/index";
import { useDispatch } from "react-redux";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { handleGetCdrCpoReports } from "store/dealTracker/dealTracker.action";
import { handleGetSelectList } from "store/selectList/selectList.action";
import { handleGetLocations } from "store/locations/locations.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import CustomTable from "components/table/datatable/CustomTable";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import masterDropdownValues from "constants/masterDropdownValues";

const container: IStackStyles = {
    root: {
        marginTop: 10,
        minHeight: 500
    },
};

//const credentialModuleName = "DealTracker";
export const CDRCPOReportWorkbookV2Page = () => {
    const dispatch = useDispatch();

    const serverError = useSelector((state: AppState) => state.dealTracker.error) as any;
    const departments = useSelector((state: AppState) => state.selectList.departments) as any;
    const locations = useSelector((state: AppState) => state.locations.locations) as any;
    const user = useSelector((state: AppState) => state.userProfile.userProfile) as any;

    const [tableData, setTableData] = useState([]);
    const [commonServerError, setcommonServerError] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 960 });
    const yearRange = "1980:" + (new Date().getFullYear() + 1);

    const isKarlProfileType = user.profileType && isKarlProfile(user.profileType);

    const [glFilter, setGLFilter] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState(0);
    const [cdrFilter, setCDRFilter] = useState(0);
    const [sfeFilter, setSFEFilter] = useState(0);
    const [fleetFilter, setFleetFilter] = useState(0);
    const [cpoFilter, setCPOFilter] = useState(0);
    const [closeDateFilterFrom, setCloseDateFilterFrom] = React.useState<Date | undefined>(undefined);
    const [closeDateFilterTo, setCloseDateFilterTo] = React.useState<Date | undefined>(undefined);
    const [cdrDateFilterFrom, setCDRDateFilterFrom] = React.useState<Date | undefined>(undefined);
    const [cdrDateFilterTo, setCDRDateFilterTo] = React.useState<Date | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState('_closedDate');
    const [departmentList, setDepartmentList] = useState([]);
    const [cityDealerList, setCityDealerList] = React.useState([0]);
    const [locationFilter, setLocationFilter] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [primaryLocation, setPrimaryLocation] = React.useState(null);
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [selectedDelCodes, setSelectedDelCodes] = useState(null);

    const previousDate = new Date((new Date()).valueOf() - 1000 * 60 * 60 * 24);
    //const allCodes = masterDropdownValues.deliveryCodes.map(a => a.key);
    const filterStatus = [
        { key: 2, text: 'Y' },
        { key: 1, text: 'N' },
        { key: 0, text: 'All' }
    ];

    const handleGlobalSearch = (val: any) => {
        setGLFilter(val.target.value);
    };

    const onDepartmentChange = (e: { value: any }) => {
        setDepartmentFilter(e.value);
    }

    const onCDRChange = (e: { value: any }) => {
        setCDRFilter(e.value);
    }

    const onFleetChange = (e: { value: any }) => {
        setFleetFilter(e.value);
    }

    const onSFEChange = (e: { value: any }) => {
        setSFEFilter(e.value);
    }

    const onCPOChange = (e: { value: any }) => {
        setCPOFilter(e.value);
    }

    const handleCloseDateFromChange = (val: any) => {
        setCloseDateFilterFrom(val.target.value)
    };

    const handleCloseDateToChange = (val: any) => {
        setCloseDateFilterTo(val.target.value)
    };

    const handleCDRDateFromChange = (val: any) => {
        setCDRDateFilterFrom(val.target.value)
    };

    const handleCDRDateToChange = (val: any) => {
        setCDRDateFilterTo(val.target.value)
    };

    useEffect(() => {
        if (departments) {
            const departList = [...departments];
            departList.unshift({ id: 0, name: 'All' });
            setDepartmentList(departList);
        } else {
            setDepartmentList([]);
        }
    }, [departments]);

    useEffect(() => {
        if (loadingFlag) {
            handleFilters();
        }
    }, [departmentFilter// eslint-disable-line react-hooks/exhaustive-deps
        , locationFilter
        , cdrFilter
        , sfeFilter
        , fleetFilter
        , cpoFilter
        , closeDateFilterFrom
        , closeDateFilterTo
        , cdrDateFilterFrom
        , cdrDateFilterTo
        , selectedDelCodes
        , loadingFlag]);

    useEffect(() => {
        if (loadingFlag) {
            handleFilters();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder, sortField]);

    useEffect(() => {
        if (locations && locations.results) {
            locations.results.unshift({ id: 0, legalName: 'All' });
            setCityDealerList(locations.results);
        } else {
            setCityDealerList(locations);
        }
    }, [locations]);

    useEffect(() => {
        dispatch<any>(handleGetSelectList('departments', 1));
        dispatch<any>(handleGetLocations());
        setSortOrder(-1);
        setSortField('_closedDate');
        setcommonServerError(null);
        setCloseDateFilterTo(previousDate);
        setCloseDateFilterFrom(previousDate);
        setTableData([]);
        setLoadingFlag(true);
        setSelectedDelCodes(null);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (user && user.roles) {
            if (user.roles.some(item => item === "ROLE_EMPLOYEE") && user.primaryLocationId) {
                setPrimaryLocation(user.primaryLocationId);
                setLocationFilter(user.primaryLocationId);
            }
        }
    }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleResetWorkbook = () => {
        setGLFilter(null);
        setDepartmentFilter(0);
        setCDRFilter(0);
        setSFEFilter(0);
        setFleetFilter(0);
        setCPOFilter(0);
        setCloseDateFilterFrom(previousDate);
        setCDRDateFilterFrom(null);
        setCloseDateFilterTo(previousDate);
        setCDRDateFilterTo(null);
        setSortField('_closedDate');
        setSortOrder(-1);
        setLocationFilter(primaryLocation ? primaryLocation : 0);
        setcommonServerError(null);
        setSelectedDelCodes(null);
    };

    const onLocationChange = (e: { value: any }) => {
        setLocationFilter(e.value);
    }

    const exportExcel = () => {
        if (tableData && tableData.length) {
            let data = [];
            if (glFilter)
                data = [...filteredData];
            else
                data = [...tableData];

            if (data) {
                const exportData = data.map((item) =>
                    ({
                        "Close Date": item._closedDate,
                        "Location": item._locationName,
                        "Deal Number": item._dealNumber,
                        "Customer Name": item._customer,
                        "Stock Number": item._vehicleStockNumber,
                        "Vehicle": item._vehicle,
                        "Department": item._department,
                        "Sales Person": item._sales,
                        "Manager": item._salesManager,
                        "Finance Person": item._finance,
                        "SFE": item._sfe,
                        "Fleet": item._fleet,
                        "Del. Code": item._deliveryCode,
                        "CDR": item._cdr,
                        "CDR Date": item._cdrDate
                    }));

                import('xlsx').then(xlsx => {
                    const worksheet = xlsx.utils.json_to_sheet(exportData);
                    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                    saveAsExcelFile(excelBuffer, 'cdr-cpo-reports');
                });
            }
        }
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    //Datatable Templates

    const closeDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Close Date</span>
                {rowData._closedDate}
            </React.Fragment>
        );
    };

    const locationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Location</span>
                {rowData._locationName}
            </React.Fragment>
        );
    };

    const dealNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deal Number</span>
                {rowData._dealNumber}
            </React.Fragment>
        );
    };

    const customerNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Customer Name</span>
                {rowData._customer}
            </React.Fragment>
        );
    };

    const stockNumberBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Stock Number</span>
                {rowData._vehicleStockNumber}
            </React.Fragment>
        );
    };

    const vehicleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Vehicle</span>
                {rowData._vehicle}
            </React.Fragment>
        );
    };

    const departmentBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Department</span>
                {rowData._department}
            </React.Fragment>
        );
    };

    const salesPersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Sales Person</span>
                {rowData._sales}
            </React.Fragment>
        );
    };

    const financePersonBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Finance Person</span>
                {rowData._finance}
            </React.Fragment>
        );
    };

    const managerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Manager</span>
                {rowData._salesManager}
            </React.Fragment>
        );
    };

    const sfeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">SFE</span>
                {rowData._sfe}
            </React.Fragment>
        );
    };

    const fleetBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Fleet</span>
                {rowData._fleet}
            </React.Fragment>
        );
    };

    const delCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Del. Code</span>
                {rowData._deliveryCode}
            </React.Fragment>
        );
    };

    const cdrBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR</span>
                {rowData._cdr}
            </React.Fragment>
        );
    };

    const cdrDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">CDR Date</span>
                {rowData._cdrDate}
            </React.Fragment>
        );
    };

    const header = (
        <>
            {isMobile ?
                <div>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className=" employeeeSearchLabelText">
                                        <TextField
                                            value={glFilter}
                                            type="search"
                                            label="Search"
                                            className="ownerSearchText"
                                            onChange={(e) => handleGlobalSearch(e)}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dd-control">
                                        <label className="lbl-text">Location</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={locationFilter}
                                            options={cityDealerList}
                                            onChange={onLocationChange}
                                            optionLabel="legalName"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="locationDropPosition">
                                        <label className="lbl-text">Department</label>
                                        <Dropdown
                                            id="locationId"
                                            placeholder=""
                                            value={departmentFilter}
                                            options={departmentList}
                                            onChange={onDepartmentChange}
                                            optionLabel="name"
                                            optionValue="id"
                                            appendTo="self"
                                            className="custom-p-dropdown"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dd-control">
                                        <label className="lbl-text">SFE</label>
                                        <Dropdown
                                            id="sfeFilter"
                                            placeholder=""
                                            value={sfeFilter}
                                            options={filterStatus}
                                            onChange={onSFEChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="dd-control">
                                        <label className="lbl-text">Fleet</label>
                                        <Dropdown
                                            id="fleetFilter"
                                            placeholder=""
                                            value={fleetFilter}
                                            options={filterStatus}
                                            onChange={onFleetChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dd-control cdr-cpo-dd-filter">
                                        <label className="lbl-text">Delivery Code</label>
                                        <MultiSelect
                                            value={selectedDelCodes}
                                            options={masterDropdownValues.deliveryCodes}
                                            style={{ minWidth: "175px" }}
                                            onChange={(e) => {
                                                setSelectedDelCodes(e.value);
                                            }}
                                            placeholder="Select Del Code"
                                            optionLabel="shortText"
                                            optionValue="key"
                                            className="custom-p-dropdown"
                                            maxSelectedLabels={2}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="dd-control">
                                        <label className="lbl-text">CDR</label>
                                        <Dropdown
                                            id="cdrFilter"
                                            placeholder=""
                                            value={cdrFilter}
                                            options={filterStatus}
                                            onChange={onCDRChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dd-control">
                                        <label className="lbl-text">CPO</label>
                                        <Dropdown
                                            id="cpoFilter"
                                            placeholder=""
                                            value={cpoFilter}
                                            options={filterStatus}
                                            onChange={onCPOChange}
                                            optionLabel="text"
                                            optionValue="key"
                                            appendTo="self"
                                            className="custom-p-dropdown write-ups-location-dd"
                                            filter={false}
                                            resetFilterOnHide={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ width: "auto" }} >
                                <Col>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">Close Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="closeDateFilterFrom"
                                                        style={{ width: "100%" }}
                                                        value={closeDateFilterFrom}
                                                        showIcon
                                                        onChange={(e) => handleCloseDateFromChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={closeDateFilterTo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="closeDateFilterTo"
                                                        style={{ width: "100%" }}
                                                        value={closeDateFilterTo}
                                                        showIcon
                                                        onChange={(e) => handleCloseDateToChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={closeDateFilterFrom}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ width: "auto" }} >
                                <Col>
                                    <div className="date-filter-section">
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">CDR Date</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="cdrDateFilterFrom"
                                                        style={{ width: "100%" }}
                                                        value={cdrDateFilterFrom}
                                                        showIcon
                                                        onChange={(e) => handleCDRDateFromChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        maxDate={cdrDateFilterTo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <label className="lbl-text" style={{ margin: "30px 10px 0px" }}>To</label>
                                        <div className="table-header">
                                            <div className="ms-TextField-wrapper">
                                                <label className="lbl-text">&nbsp;</label>
                                                <div className="ms-TextField-fieldGroup mask-FieldGroup date-filter">
                                                    <Calendar
                                                        id="cdrDateFilterTo"
                                                        style={{ width: "100%" }}
                                                        value={cdrDateFilterTo}
                                                        showIcon
                                                        onChange={(e) => handleCDRDateToChange(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange={yearRange}
                                                        minDate={cdrDateFilterFrom}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className=" employeeeSearchLabelText" style={{ marginTop: "32px" }} >
                                    <PrimaryButton style={{ float: "right" }} onClick={handleResetWorkbook}>
                                        Reset
                                    </PrimaryButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="display-row" style={{ marginTop: "15px" }}>
                                    {
                                        totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                                    }
                                    <Link className="workbook-export-btn-mob" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                        Export to Excel
						            </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div> :
                <div>
                    <div>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={3}>
                                        <div className=" dd-control employeeeSearchLabelText" style={{marginTop:"6px"}}>
                                            <TextField
                                                value={glFilter}
                                                type="search"
                                                label="Search"
                                                className="ownerSearchText"
                                                onChange={(e) => handleGlobalSearch(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1.5}> 
                                        <div >
                                            <label className="lbl-text">Location</label>
                                            <Dropdown
                                                id="locationId"
                                                placeholder=""
                                                value={locationFilter}
                                                options={cityDealerList}
                                                onChange={onLocationChange}
                                                optionLabel="legalName"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1.5}> 
                                        <div >
                                            <label className="lbl-text">Department</label>
                                            <Dropdown
                                                id="locationId"
                                                placeholder=""
                                                value={departmentFilter}
                                                options={departmentList}
                                                onChange={onDepartmentChange}
                                                optionLabel="name"
                                                optionValue="id"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={3} >
                                        <div className="date-filter-section">
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">Close Date</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                        <Calendar
                                                            id="closeDateFilterFrom"
                                                            style={{ width: "100%" }}
                                                            value={closeDateFilterFrom}
                                                            showIcon
                                                            onChange={(e) => handleCloseDateFromChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            maxDate={closeDateFilterTo}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="lbl-text" style={{ margin: "36px 10px 0px" }}>To</label>
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">&nbsp;</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                        <Calendar
                                                            id="closeDateFilterTo"
                                                            style={{ width: "100%" }}
                                                            value={closeDateFilterTo}
                                                            showIcon
                                                            onChange={(e) => handleCloseDateToChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            minDate={closeDateFilterFrom}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={1.5}>
                                        <div>
                                            <label className="lbl-text">CDR</label>
                                            <Dropdown
                                                id="cdrFilter"
                                                placeholder=""
                                                value={cdrFilter}
                                                options={filterStatus}
                                                onChange={onCDRChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={2.5}>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3} style={{width:"100%"}}>
                                      <div>
                                        <div  className="sfeWidth"  style={{width:"50%",float:"left"}}>
                                            <label className="lbl-text">SFE</label>
                                            <Dropdown
                                                id="sfeFilter"
                                                placeholder=""
                                                value={sfeFilter}
                                                options={filterStatus}
                                                onChange={onSFEChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className=" custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                        <div className="fleetWidth"  style={{width:"50%",float:"right"}}>
                                            <label className="lbl-text">Fleet</label>
                                            <Dropdown
                                                id="fleetFilter"
                                                placeholder=""
                                                value={fleetFilter}
                                                options={filterStatus}
                                                onChange={onFleetChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className=" custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={1.5}>
                                        <div className= "reportMultiSelect cdr-cpo-dd-filter">
                                            <label className="lbl-text">Delivery Code</label>
                                            <MultiSelect
                                                value={selectedDelCodes}
                                                options={masterDropdownValues.deliveryCodes}
                                                onChange={(e) => {
                                                    setSelectedDelCodes(e.value);
                                                }}
                                                placeholder="Select Del Code"
                                                optionLabel="shortText"
                                                optionValue="key"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                maxSelectedLabels={2}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1.5}>
                                        <div >
                                            <label className="lbl-text">CPO</label>
                                            <Dropdown
                                                id="cpoFilter"
                                                placeholder=""
                                                value={cpoFilter}
                                                options={filterStatus}
                                                onChange={onCPOChange}
                                                optionLabel="text"
                                                optionValue="key"
                                                appendTo="self"
                                                className="custom-p-dropdown write-ups-location-dd"
                                                filter={false}
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={3}>
                                        <div className="date-filter-section">
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">CDR Date</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                        <Calendar
                                                            id="closeDateFilterFrom"
                                                            style={{ width: "100%" }}
                                                            value={cdrDateFilterFrom}
                                                            showIcon
                                                            onChange={(e) => handleCDRDateFromChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            maxDate={cdrDateFilterTo}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="lbl-text" style={{ margin: "36px 10px 0px" }}>To</label>
                                            <div className="table-header">
                                                <div className="ms-TextField-wrapper">
                                                    <label className="lbl-text">&nbsp;</label>
                                                    <div className="ms-TextField-fieldGroup mask-FieldGroup date-filt date-filter">
                                                        <Calendar
                                                            id="cdrDateFilterTo"
                                                            style={{ width: "100%" }}
                                                            value={cdrDateFilterTo}
                                                            showIcon
                                                            onChange={(e) => handleCDRDateToChange(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange={yearRange}
                                                            minDate={cdrDateFilterFrom}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={1.5}>
                                        <div className="table-header employeeeSearchLabelText" style={{ marginTop: "25px",float:"right" }}>
                                            <PrimaryButton onClick={handleResetWorkbook}>Reset</PrimaryButton>
                                        </div>
                                    </Col>
                                    <Col md={1.5}>

                                    </Col>
                                  
                                </Row>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                    <div className="display-row total-export-row">
                        <div>
                            {
                                totalRecords !== null && <label className="lbl-text">Record Count: {totalRecords}</label>
                            }
                        </div>
                        <div>
                            <Link className="workbook-export-btn" color="inherit" onClick={exportExcel} data-pr-tooltip="XLS"  >
                                Export to Excel
		            </Link>
                        </div>
                    </div>
                </div>}
        </>
    );


    const handleFilters = () => {
        setcommonServerError(null);

        const formData = {
            fromClosedDate: closeDateFilterFrom ? moment(closeDateFilterFrom).format('YYYY-MM-DD') : '',
            toClosedDate: closeDateFilterTo ? moment(closeDateFilterTo).format('YYYY-MM-DD') : '',
            fromCdrDate: cdrDateFilterFrom ? moment(cdrDateFilterFrom).format('YYYY-MM-DD') : '',
            toCdrDate: cdrDateFilterTo ? moment(cdrDateFilterTo).format('YYYY-MM-DD') : '',
            cdr: cdrFilter ? cdrFilter : null,
            sfe: sfeFilter ? sfeFilter : null,
            fleet: fleetFilter ? fleetFilter : null,
            cpo: cpoFilter ? cpoFilter : null,
            departmentId: departmentFilter ? departmentFilter : null,
            locationId: locationFilter ? locationFilter : null,
            deliveryCode: selectedDelCodes?.toString(),
            sort: sortOrder === 1 ? 'ASC' : 'DESC',
            column: sortField.replace('_',''),
        };
        dispatch<any>(handleGetCdrCpoReports({
            formData,
            callback: (response) => {
                if (response) {
                    handlePopulateTableData(response);
                } else {
                    setcommonServerError('Failed to Fetch the Data.');
                }
            }
        }));
    }

    useEffect(() => {
        if (serverError) {
            if (serverError.detail) {
                setcommonServerError(serverError.detail);
            } else {
                setcommonServerError('Something went wrong');
            }
            window.scrollTo(0, 0);
        } else {
            setcommonServerError(null);
        }
    }, [serverError]);

    const handlePopulateTableData = (response) => {

        for (var i in response.results) {
            const deal = response.results[i].deal;
            const customer = deal.customer;

            let substCdrDate = '';
            let substClosedDate = '';
            if (response.results[i].closedDate) {
                let rawExpenseDate = response.results[i].closedDate;
                substClosedDate = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0, rawExpenseDate.indexOf("T")) : rawExpenseDate;
            }
            if (response.results[i].cdrDate) {
                let rawExpenseDate = response.results[i].cdrDate;
                substCdrDate = rawExpenseDate.indexOf("T") ? rawExpenseDate.substr(0, rawExpenseDate.indexOf("T")) : rawExpenseDate;
            }

            response.results[i]["_closedDate"] = substClosedDate ? moment(substClosedDate).format('MM/DD/YYYY') : ''; //convertUTCToLocalFormat(response.results[i].closedDate, 'MM/DD/YYYY');
            response.results[i]["_cdrDate"] = substCdrDate ? moment(substCdrDate).format('MM/DD/YYYY') : '';
            response.results[i]["_locationName"] = deal && deal.location && deal.location?.legalName;
            response.results[i]["_dealNumber"] = deal.dealNumber;
            response.results[i]["_department"] = response.results[i].department ? response.results[i].department.name : '';
            if (deal && deal.vehicle) {
                response.results[i]["_vehicleStockNumber"] = deal.vehicle.stockNumber ? deal.vehicle.stockNumber : '';
                response.results[i]["_vehicle"] = deal.vehicle.year + ' ' + deal.vehicle.model;
            }
            response.results[i]["_sales"] = deal && deal.salesPerson1 ? deal.salesPerson1.firstName + ' ' + deal.salesPerson1.lastName : '';
            response.results[i]["_salesManager"] = deal && deal.salesManager ? deal.salesManager.firstName + ' ' + deal.salesManager.lastName : '';
            response.results[i]["_finance"] = deal && deal.financePerson1 ? deal.financePerson1.firstName + ' ' + deal.financePerson1.lastName : '';
            response.results[i]["_customer"] = customer.businessName ? customer.businessName : (customer.firstName + ' ' + customer.lastName);
            response.results[i]["_cdr"] = response.results[i]?.cdr === 2 ? 'Y' : 'N';
            response.results[i]["_sfe"] = response.results[i]?.sfe === 2 ? 'Y' : 'N';
            response.results[i]["_fleet"] = response.results[i]?.fleet === 2 ? 'Y' : 'N';
            response.results[i]["_deliveryCode"] = getDeliveryCode(response.results[i]?.deliveryCode);
            //response.results[i]["hiddenCol"] = deal && deal.vehicle && deal.vehicle.vin && deal.vehicle.vin;

        }

        setTableData(response.results);
    };

    const getDeliveryCode = (codeId) => {
        if (codeId) {
            return masterDropdownValues.deliveryCodes.filter(x => x.key === codeId)[0].shortText;
        }
        return '';
    }

    const handleColumnSort = (e) => {
        setSortField(e.sortField);
        setSortOrder(e.sortOrder);
    }

    const table_columns = {
        "_closedDate": "Close Date",
        "_locationName": "Location",
        "_dealNumber": "Deal Number",
        "_customer": "Customer Name",
        "_vehicleStockNumber": "Stock Number",
        "_vehicle": "Vehicle",
        "_department": "Department",
        "_sales": "Sales Person",
        "_salesManager": "Manager",
        "_finance": "Finance Person",
        "_sfe": "SFE",
        "_fleet": "Fleet",
        "_deliveryCode": "Del. Code",
        "_cdr": "CDR",
        "_cdrDate": "CDR Date",
    }

    useEffect(() => {
        computeTotalRecords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, filteredData]);

    const computeTotalRecords = () => {
        if (tableData && tableData.length) {
            let data = [];
            if (glFilter) {
                data = [...filteredData];
            }
            else
                data = [...tableData];
            setTotalRecords(data.length);
        }
        else
            setTotalRecords(0);
    }

    //const footer = (
    //    <>
    //        {
    //            !totalRecords && <div className="msg-tbl-footer">No data found.</div>
    //        }
    //    </>
    //);

    return (
        isKarlProfileType ? <>
            <Stack styles={container} tokens={{ childrenGap: 10 }}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                    className="loc_btn_par_con"
                >

                    <Row className="pageTitleRow">
                        <Col className="alignHeaders">
                            <CustomFontText className="pageTitleFont pageName pageHeaderFont task">CDR/CPO Report</CustomFontText>
                        </Col>
                    </Row>
                </Stack>
                {
                    commonServerError ?
                        <div>
                            <p className="genericErrorColor"><span>{commonServerError}</span></p>
                        </div> : null
                }
                <div className="datatable-filter-demo noMaxWidthTable">
                    <div className="card cdr-cpo-tbl">
                        <CustomTable
                            column_list={table_columns}
                            data={tableData}
                            lazy
                            header={header}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={handleColumnSort}
                            paginator={false}
                            globalFilter={glFilter}
                            //footer={footer}
                            rows={10000}
                            onValueChange={filteredData => setFilteredData(filteredData)}
                            customTemplates={{
                                "_closedDate": closeDateBodyTemplate,
                                "_locationName": locationBodyTemplate,
                                "_dealNumber": dealNumberBodyTemplate,
                                "_customer": customerNameBodyTemplate,
                                "_vehicleStockNumber": stockNumberBodyTemplate,
                                "_vehicle": vehicleBodyTemplate,
                                "_department": departmentBodyTemplate,
                                "_sales": salesPersonBodyTemplate,
                                "_salesManager": managerBodyTemplate,
                                "_finance": financePersonBodyTemplate,
                                "_sfe": sfeBodyTemplate,
                                "_fleet": fleetBodyTemplate,
                                "_deliveryCode": delCodeBodyTemplate,
                                "_cdr": cdrBodyTemplate,
                                "_cdrDate": cdrDateBodyTemplate
                            }}
                        />
                    </div>
                </div>
            </Stack>
        </> : null
    );
};
