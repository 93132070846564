import React from 'react';
import {
  Modal,
  IconButton,
  PrimaryButton
} from '@fluentui/react';
import { CustomFontText } from 'components/customFontText/CustomFontText';
import { getTheme, mergeStyleSets, FontWeights } from "@fluentui/react";
import PrintImage from '../../../assets/printing.png';

const theme = getTheme();

const contentStyles = mergeStyleSets({
  container: {
    width: 600,
    zIndex: 100,
  },
  header: {
    flex: "1 1 auto",
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: "flex",
    alignItems: "center",
    fontWeight: FontWeights.semibold,
    padding: "5px 30px",
  },
  body: {
    paddingLeft: 95,
    paddingRight: 95,
    paddingBottom: 15,
  },
  closebutton: {
    paddingLeft: 95,
    paddingRight: 95,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const iconButtonStyles = {
  root: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
  },
  rootHovered: {
      color: theme.palette.neutralDark,
  },
};

interface KarlDocReportsDialogProps {
  isOpen: boolean;
  toggleDialog: () => void;
  data: any[];
  locationsList: any[];
}

const LocationsModal: React.FC<{ data: any[]; locationsList: any[] }> = ({ data = [], locationsList }) => {
  return (
    <div>
      {data.map(location => (
        <div key={location.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ textAlign: 'left', fontWeight: 400, fontSize: '16px' }}>
            {location.legalName}
          </span>
          <span style={{ textAlign: 'right', fontWeight: 400, fontSize: '16px' }}>
            ${location.perCarTotal}
          </span>
        </div>
      ))}
    </div>
  );
};

const exportToExcel = async (data, filename) => {
  try {
    const xlsx = await import('xlsx');

    const titleRow = ['Per Car Totals', new Date().toLocaleDateString()];
    
    const headerRow = ['id', 'legalName', 'perCarTotal'];

    const exportData = data.map((location) => [
      location.id,
      location.legalName,
      location.perCarTotal,
    ]);

    const sheetData = [titleRow, headerRow, ...exportData];

    const worksheet = xlsx.utils.aoa_to_sheet(sheetData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileSaver = await import('file-saver');
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });

    fileSaver.default.saveAs(dataBlob, filename + EXCEL_EXTENSION);
  } catch (error) {
    console.error('Error exporting to Excel:', error);
  }
};

const KarlDocReportsDialog: React.FC<KarlDocReportsDialogProps> = ({ isOpen, toggleDialog, data, locationsList }) => {

  const handlePrint = async () => {
    const filename = 'PerCarTotals_export_' + new Date().getTime();
    await exportToExcel(data, filename);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={toggleDialog}
      isBlocking={true}
      containerClassName={contentStyles.container}
      styles={{ root: { zIndex: 10 } }}
      className="createDealRecord  dealRecordHeights updateEmployeeDialogHeight"
    >
      <div className={contentStyles.header + " inventory-popup"}>
        <CustomFontText className="formTitle">Per Car Totals</CustomFontText>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginBottom:'15px' }}>
          <img
            src={PrintImage} 
            alt="Print"
            style={{ cursor: 'pointer', marginRight: '35px', width: '24px', height: '24px' }}
            onClick={handlePrint}
          />
        <IconButton
          styles={iconButtonStyles}
            iconProps={{ iconName: 'Cancel' }} 
          ariaLabel="Close popup modal"
          onClick={toggleDialog}
        />
      </div>
      </div>

      <div className={contentStyles.body}>
        {data && <LocationsModal data={data} locationsList={locationsList} />}
      </div>

      <div className={contentStyles.closebutton}>
        <PrimaryButton onClick={toggleDialog} text="Close" style={{ marginTop: '20px' }} />
      </div>
    </Modal>
  );
}

export default KarlDocReportsDialog;
