import React, { useEffect, useState } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import {
    Spinner,
    SpinnerSize,
    ISpinnerStyles,
} from "@fluentui/react/lib/Spinner";
import AuthLayoutPage from "components/layouts/authLayout/AuthLayoutPage";
//import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton, ITextFieldProps } from "@fluentui/react";
import { CustomFontText } from "components/customFontText/CustomFontText";
import { styles } from "./styles";
import PasswordTextInput from "components/customInputs/passwordTextInput/PasswordTextInput";
import { handleResetPasswordConfirm, clearServerErrorMessage, handleLoginByToken } from "store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AppState } from "store";
import * as Yup from "yup";
import { useLocation, useNavigate  } from "react-router-dom";
import { PasswordWithRequirementInfo } from "components/textFieldWithLabelAndInfoIcon/PasswordWithRequirementInfo";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Link } from "@fluentui/react/lib/Link";
import queryString from 'query-string';
const spinnerStyles: ISpinnerStyles = {
    root: {
        position: "absolute",
        right: 220,
    },
};
const RecoverMyAccountConfirmPage = () => {
    const navigate = useNavigate ();
    const location = useLocation ();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [resetPasswordVerify, setresetPasswordVerify] = useState(false);
    const [resetLink, setresetLink] = useState(false);
    const parsed:any = queryString.parse(location.search);
    const [fullName, setFullName] = useState('');
    const [agree, setAgree] = useState(false);
    const dispatch = useDispatch();
    // let token;
    // var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    // pattern match for EMAIL
    /* if (parsed && parsed.email) {
      let parsedEmail = window.atob(parsed.email);
      if (!emailPattern.test(parsedEmail)) {
        navigate('/login');
      } else {
        token = parsed.token;
      }
    } else {
      navigate('/login');
    } */
    const loading = useSelector((state: AppState) => state.auth.loading);
    const resetPasswordError = useSelector(
        (state: AppState) => (state.auth as any).resetPasswordError
    );
    
    const resetLinkExpiration = useSelector(
        (state: AppState) => state.auth.resetPasswordLinkError
    );
    useEffect(() => {
        dispatch<any>(handleLoginByToken(""));
    }, [dispatch]);
    useEffect(() => {
        console.log('resetLinkExpiration reconfirm', resetLinkExpiration);
        if (resetLinkExpiration !== null) {
            if (resetLinkExpiration.code === 404) {
                setresetPasswordVerify(false);
                setresetLink(true);
                setTitle('Invalid Link');
                setContent('Please try again and check your email for a new link.');
            } else if (resetLinkExpiration.code === 200) {
                setFullName(resetLinkExpiration.data.firstName + ' ' + resetLinkExpiration.data.lastName);
                setresetPasswordVerify(true);
                setresetLink(false);
            }
        }
    }, [resetLinkExpiration]);
    const formik = useFormik({
        initialValues: {
            email: parsed?.email ? window.atob(parsed.email) : '',
            confirmCode: parsed?.token,
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .min(3, "Must be at least 3 characters")
                .email("Field must be valid email user")
                .required("Required"),
            // confirmCode: Yup.string().required("Required"),
            password: Yup.string()
                .required("Required")
                .test("rules", "Must be 8 characters, upper and lowercase letter, one number, one special character. Cannot reuse previous passwords.", (val) => {
                    var allRules = new RegExp(/^(?=^.{8,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()]+)(?![.\n]).*$/);
                    return allRules.test(val);
                }),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: (values) => {
            dispatch<any>(
                handleResetPasswordConfirm({
                    email: values.email,
                    password: values.password,
                    verificationToken: parsed?.token,
                })
            );
        },
    });
    const handleClick = async () => {
        dispatch<any>(clearServerErrorMessage());
        formik.handleSubmit();
    };
    const renderSpinner = () => {
        return loading ? (
            <Spinner styles={spinnerStyles} size={SpinnerSize.medium} />
        ) : null;
    };
    const handleCheckboxChange = () => {
        setAgree(!agree);
    };
    const renderLabelWithLinks = () => {
        return (
            <span className={styles.checkboxLabel}>
                By creating this account, I agree to FiQore's{" "}
                <Link className="ln-nw-link">                   
                <a  className="ln-nw-link-test" href={`${require("assets/termConditions/privacy-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Privacy Policy</a>
        </Link>
                {", "}
                <Link className="ln-nw-link">
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/cookie-Policy.pdf")}`} target="_blank" rel='noopener noreferrer'>Cookie Policy</a>
                  
        </Link>
                {" and "}
                <Link className="ln-nw-link">
                <a className="ln-nw-link-test" href={`${require("assets/termConditions/Term.pdf")}`} target="_blank" rel='noopener noreferrer'>Terms of Use.</a>
        </Link>
            </span>
        );
    };
    const handleResetPassword = () => {
        navigate('/reset-password');
    };
    const onRenderLabel = (props: ITextFieldProps) => (
    <PasswordWithRequirementInfo {...props} />
    );
    return (
        
        <AuthLayoutPage>
            {resetPasswordVerify ?
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleClick().then();
                    }}
                >
                    <Stack.Item styles={styles.stackItemConfirmChangePasswordStyles}>
                        <Stack
                            verticalAlign="space-between"
                            styles={styles.loginContentStyles}
                        >
                            <Stack.Item>
                                <CustomFontText  className="formTitle loginFormRequestHeader">Reset My Password</CustomFontText>
                            </Stack.Item>
                            <Stack.Item>
                                <span className="loginFormConfirm loginFormConfirmTest">{fullName}</span>
                            </Stack.Item>
                            {
                                resetPasswordError && resetPasswordError.message ? <p className="genericErrorColor"><span>{resetPasswordError.message}</span></p> : null
                            }
                                <Stack.Item>
                                    <Stack tokens={styles.textFields}>
                                        {/*<TextField
                                            id="email"
                                            name="email"
                                            disabled={true}
                                            label="Email"
                                            placeholder=""
                                            iconProps={styles.usernameIcon}
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMessage={
                                                formik.touched.email && formik.errors.email
                                                    ? formik.errors.email.toString()
                                                    : null
                                            }
                                        />*/}
                                    </Stack>
                                </Stack.Item>
                            <Stack.Item>
                                <Stack tokens={styles.textFields}>
                                    {/* <MaskedTextField
                                           id="confirmCode"
                                           name="confirmCode"
                                           label="Enter The 6-Character Code"
                                           mask="999 - 999"
                                           value={formik.values.confirmCode}
                                           onChange={handleConfirmCodeClick}
                                           onBlur={formik.handleBlur}
                                           errorMessage={
                                           changePasswordError && changePasswordError.message
                                    }
                                    /> */}
                                </Stack >
                            </Stack.Item>
                            <Stack.Item className="loginFormConfirm">
                                <Stack tokens={styles.textFields}>
                                    <PasswordTextInput
                                        id="resetpassword"
                                        name="password"
                                        label="Password"
                                        description=""
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onRenderLabel={onRenderLabel}
                                        errorMessage={
                                            formik.touched.password && formik.errors.password && formik.errors.password.toString() !== "Invalid"
                                                ? formik.errors.password.toString()
                                                : null
                                        }
                                    />
                                </Stack>
                            </Stack.Item>
                            <Stack.Item className="loginFormConfirm">
                                <Stack tokens={styles.textFields}>
                                    <PasswordTextInput
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label="Re-enter Password"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMessage={
                                            formik.touched.confirmPassword &&
                                                formik.errors.confirmPassword
                                                ? formik.errors.confirmPassword.toString()
                                                : null
                                        }
                                    />
                                </Stack>
                            </Stack.Item>
                            <Stack.Item className="loginFormConfirm">
                                <Stack>
                                    <p>{renderLabelWithLinks()}</p>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item className="loginFormConfirm">
                                <Stack>
                                    <Checkbox
                                    id="isRulesCheck"
                                    name="isRulesCheck"
                                    //checked={formik.values.isRulesCheck}
                                    onChange={handleCheckboxChange}
                                    label="I Agree"
                                    className="checkboxText agreeCheck"
                                   />
                                </Stack>
                            </Stack.Item>
                                <br />
                            <Stack.Item>
                                 <Stack className=" loginFormConfirm loginFormConfirmButton pswdResetBtn" tokens={styles.buttonTokens}>
                                     <PrimaryButton
                                     //disabled={!formik.dirty || !formik.isValid}
                                      type="submit"
                                      onRenderIcon={renderSpinner}
                                      text="Reset Password"
                                      onClick={handleClick}
                                      disabled={!formik.dirty || !formik.isValid || !agree}
                                      />
                                    {/*<DefaultButton text="Register" onClick={handleRegisterClick} />*/}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </form> :
                    <Stack.Item
                        styles={
                            resetLink ? styles.stackItemStylesAutoHeight : styles.none
                        }
                    >
                        <Stack
                            verticalAlign="space-between"
                            styles={styles.loginContentStyles}>
                            <Stack.Item>
                                <CustomFontText>{title}</CustomFontText>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack tokens={styles.textFields}>
                                    <div><p className="confirmationMsg">
                                        {content}</p></div>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item >
                                <Stack  tokens={styles.buttonTokens}>
                                {resetLink ? <PrimaryButton
                                    onRenderIcon={renderSpinner}
                                    text="Reset Password"
                                    onClick={() => handleResetPassword()}
                                /> : ''}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
        }
        </AuthLayoutPage>
    );
};
export default RecoverMyAccountConfirmPage;