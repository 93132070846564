//import {IConfig} from 'types/configTypes';
import { put, takeLeading, takeEvery } from "redux-saga/effects";
import {
  pendingGetConfig,
  resolvedGetConfig,
  rejectedGetConfig,
  pendingUpdateConfig,
  resolvedUpdateConfig,
  rejectedUpdateConfig,
} from "store/config/config.action";
import {
  handleRefreshToken
} from "store/auth/auth.action";
import {
  getConfig,
  getDealerConfig,
  updateDealerConfig,
} from "store/config/config.api";
import {
  HANDLE_GET_CONFIG,
  HANDLE_UPDATE_CONFIG,
} from "store/config/config.types";
import { switchGlobalLoader } from "../globalLoading/globalLoading.action";

function* getConfigSaga() {
  yield put(switchGlobalLoader(true));
  yield put(pendingGetConfig());
  let { data } = yield getConfig();
  // const types = {
  //   error,
  //   data,
  // };
  try {
    const config = yield getDealerConfig();
    console.log(config);
    const composedData = {
      types: data,
      config: config.data.results,
    };

    yield put(resolvedGetConfig(composedData));
    yield put(switchGlobalLoader(false));
  } catch (error) {
    yield put(rejectedGetConfig(error.violations as any));
    yield put(switchGlobalLoader(false));
    return;
  }
}

function* updateConfigSaga(action: any) {
  yield put(pendingUpdateConfig());

  try {
    const { data } = yield updateDealerConfig({
      id: action.payload.id,
      value: action.payload.value,
    });

   yield put(handleRefreshToken());

    yield put(resolvedUpdateConfig(data));
  } catch (error) {
    yield put(rejectedUpdateConfig(error.violations as any));
    return;
  }
}

export default function* ConfigSaga() {
  yield takeLeading(HANDLE_GET_CONFIG, getConfigSaga);
  yield takeEvery(HANDLE_UPDATE_CONFIG, updateConfigSaga);
}
