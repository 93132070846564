import { ITemplate, IWebsite } from "types/websiteTypes";
import * as actions from "./website.types";

// GET_TEMPLATES
export const handleGetTemplates = (): actions.HandleGetTemplates => {
  return {
    type: actions.HANDLE_GET_TEMPLATES,
  };
};

export const pendingGetTemplates = (): actions.GetTemplatesPending => {
  return {
    type: actions.GET_TEMPLATES_PENDING,
  };
};

export const resolvedGetTemplates = (
  templates: ITemplate[]
): actions.GetTemplatesResolved => {
  return {
    type: actions.GET_TEMPLATES_RESOLVED,
    payload: templates,
  };
};

export const rejectedGetTemplates = (errors): actions.GetTemplatesRejected => {
  return {
    type: actions.GET_TEMPLATES_REJECTED,
    payload: errors,
  };
};

// GET_WEBSITE
export const handleGetWebsite = (): actions.HandleGetWebsite => {
  
  return {
    type: actions.HANDLE_GET_WEBSITE,
  };
};

export const pendingGetWebsite = (): actions.GetWebsitePending => {
  return {
    type: actions.GET_WEBSITE_PENDING,
  };
};

export const resolvedGetWebsite = (
  website: IWebsite
): actions.GetWebsiteResolved => {
  return {
    type: actions.GET_WEBSITE_RESOLVED,
    payload: website,
  };
};

export const rejectedGetWebsite = (errors: {code: number, message: string}): actions.GetWebsiteRejected => {
  return {
    type: actions.GET_WEBSITE_REJECTED,
    payload: errors,
  };
};

// UPDATE_WEBSITE
export const handleUpdateWebsite = (
  website: IWebsite,
  callback: () => void
): actions.HandleUpdateWebsite => {
  return {
    type: actions.HANDLE_UPDATE_WEBSITE,
    payload: {website, callback},
  };
};

export const pendingUpdateWebsite = (): actions.UpdateWebsitePending => {
  return {
    type: actions.UPDATE_WEBSITE_PENDING,
  };
};

export const resolvedUpdateWebsite = (
  website: IWebsite
): actions.UpdateWebsiteResolved => {
  return {
    type: actions.UPDATE_WEBSITE_RESOLVED,
    payload: website,
  };
};

export const rejectedUpdateWebsite = (
  errors: {code: number, message: string}
): actions.UpdateWebsiteRejected => {
  return {
    type: actions.UPDATE_WEBSITE_REJECTED,
    payload: errors,
  };
};


// CLEAR_WEBSITE
export const clearWebsite = (): actions.ClearWebsite => {
  return {
    type: actions.CLEAR_WEBSITE,
  };
};
