import {IPagination} from 'types/paginationTypes'

export interface IGroupPermission {
    id: number;
    name: string;
    text: string;
    userMasterGroupAuths: IPermission[];
}

export interface IGroupResponse {
    totalItems: number;
    currentPage: number;
    results: IGroupPermission[];
}

export interface IGroupPermissionUpdate {
    permissionId: number;
    groupId: number;
    method: string;
    pag: IPagination;
}

export interface IPermission {
    id: number;
    aliasName: string;
    moduleName: string;
    text: string;
    type: number;
}

export enum ETypes {
    READ = 1,
    WRITE,
    UPDATE,
    DELETE,
}
