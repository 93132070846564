import React, { useState } from "react";
import {
  TextField,
  ITextFieldProps,
} from "@fluentui/react/lib/TextField";
import { mergeStyles } from "@fluentui/react/lib/Styling";

const wrapper = mergeStyles({
  position: "relative",
});

const clickableHiddenOverlay = mergeStyles({
  position: "absolute",
  bottom: 0,
  right: 36,
  width: 37,
  height: 33,
});

const PasswordTextInput: React.FC<ITextFieldProps> = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className={wrapper}>
     <TextField {...props} type={!show ? "password" : undefined} iconProps={{iconName: show ? 'RedEye' : 'Hide'}}/>
      <div
        className={clickableHiddenOverlay}
        onClick={() => setShow((show) => !show)} 
      />
    </div>
  );
};

export default PasswordTextInput;
