import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isDefaultProfile, isKarlProfile } from "utils/permissions/permissionsHelpers";
import DefaultProfileDashboard from "pages/dashboard/DefaultProfileDashboard";
import KarlProfileDashboard from "pages/dashboard/KarlProfileDashboard";
import LoginPage from "pages/auth/loginPage/LoginPage";


const Dashboard = () => {

    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    const u = useSelector((state: AppState) => state.auth.userToken);

    return (
        <>
            {
                user && user.profileType && isDefaultProfile(user.profileType) && (<DefaultProfileDashboard />)
            }
            {
                user && user.profileType && isKarlProfile(user.profileType) && (<KarlProfileDashboard />)
            }
            {
                !user.id && !u  && (<LoginPage/>)
            } 
        </>
    );
};

export default Dashboard;

