import React, { useEffect, useState, FunctionComponent } from "react";
import {
    IStackStyles,
    Modal,
    PrimaryButton,
    IconButton,
    TextField,
    Stack,
    // Checkbox,
    Dropdown
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import GoBackToMainDialogBtn from "../addInventoryDialog/GoBackToMainDialogBtn";
import {
    contentStyles as content,
    iconButtonStyles,
    cancelIcon,
} from "constants/styles";
import { useNavigate  } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppState } from "store";
import { Dropdown as PRDropdown } from 'primereact/dropdown';
import { handleGetTrimUsingVin, handleGetTrimUsingYmm, handleGetVehDetails } from "store/inventory/inventory.action";
import dropDownsValues from "constants/inventoryDropdownsValues";
import { Link } from "@fluentui/react/lib/Link";
import { handleGetdataOneMakes, handleGetdataOneModels } from "store/shared/shared.action";
import { isKarlProfile } from "utils/permissions/permissionsHelpers";
import VinScanner from "../barCodeScanner/VinScanner.js";
import { trimTypes } from "constants/constants";
// import { VinCameraSelection} from "components/dialogs/vinCameraSelection/VinCameraSelection";
import { CustomFontText } from "../../customFontText/CustomFontText";
import { useMediaQuery } from "react-responsive";
import { handleCheckVehicleOrder, handleDealerTradeCheck } from "store/dealTracker/dealTracker.action";
import moment from "moment";
import { DealerTradeFoundDialog } from "components/dialogs/dealTrackerDialog/dealerTradesDialog/DealerTradeFoundDialog";

const contentStyles = content(460, "auto");

interface IProps {
    onDismiss: (vehicleId,vin?) => void;
    openTrimDialog: ({ vin, trim, ymm }) => void;
    trimType?: number;//1-Non-Karl, 2-Karl, 3-TradeIn
    addVehicleSection?: number;
    orderConflictData?: (res) => void;
    orderFoundDealData?: (res) => void;
}


const yearValues = dropDownsValues.yearNewValues.map((item) => {
    return { key: item, text: item };
});

export const AddInventorySearchDialog: FunctionComponent<IProps> = ({
    onDismiss,
    openTrimDialog,
    trimType = trimTypes.nonKarl,
    orderConflictData,
    orderFoundDealData,
    addVehicleSection
}) => {

    const [vehicleType , setvehicleType] = useState('vin');
    const [makeValues, setMakeValues] = useState([]);
    const [modelValues, setModelValues] = useState([]);
    const [isInventoryYMM, setisInventoryYMM] = useState(false);
    const [isVINScan, setisVINScan] = useState(false);
    // const [vinScanConfig, setvinScanConfig] = useState('barcode');
    const [vinDataError, setvinDataError] = useState(false);
    const [trimError, setTrimErrorr] = useState(false);
    const [vinDecodeError, setVinDecodeError] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate ();
    const [showModels, setShowModels] = useState(false);
    // const [isOrderChecked, setIsOrderChecked] = React.useState(false);
    //const [makeData, setMakeData] = React.useState(null);
    //const [modelData, setModelData] = React.useState(null);
    // const [vinCameraDialog, setvinCameraDialog] = React.useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [conflictData, setConflictData] = useState([]);
    const [modalIndex, setModalIndex] = useState(1);

    const dataOneModels: any = useSelector(
        (state: AppState) => state.shared.dataOneModels
    );

    const dataOneMakes: any = useSelector(
        (state: AppState) => state.shared.dataOneMakes
    );

    const userProfile : any = useSelector(
        (state: AppState) => state.userProfile.userProfile
    );

    const selectedDeal = useSelector(
        (state: AppState) => state.dealTracker.selectedDealRecord
    );

    const isKarlProfileType = userProfile.profileType && isKarlProfile(userProfile.profileType);

    const dealTrackerData = useSelector((state: AppState) => state.dealTracker.selectedDealRecord);
    Yup.addMethod(Yup.string, 'vinOnly', function (message) {
        if(isKarlProfileType){
            return (this.max(50,'Must be less than 50 character'))
        }else{
           return (this.matches(/^[A-Z0-9]{17}$/,
             "Must Contain at 17 Characters, Uppercase letters and numbers"))
        }
    });

    Yup.addMethod(Yup.object, 'modelOnly', function (message) {
        return (vehicleType === 'make');
    });

    Yup.addMethod(Yup.number, 'yearOnly', function (message) {
        return (vehicleType === 'make');
    });

    useEffect(() => {
        if(isKarlProfileType ){
           orderConflictData(null);
           orderFoundDealData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if(addVehicleSection === 1 && isKarlProfileType ){
           setisInventoryYMM(true)
        } else if(addVehicleSection === 2 && isKarlProfileType ){
          setisInventoryYMM(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addVehicleSection]);

    const formik = useFormik({
        initialValues: {
            make: "",
            model: "",
            //year: null,
            year: new Date().getFullYear(),
            mileage: "",
            vin: "",
            nickName: "",
            vehicletype: "vin",
            startTime: "",
            url: "",
            sourceType: "auction",
            searchCRM: "",
            dealerSourceName: "",
            privateSourceName: "",
            dealID: "",
            dealerhipsName: "",
            carID: "",
            shippingFrom: "",
            orderNumber:"",
            stockNumber:""
        },
        validationSchema: Yup.object({
            make: Yup.string().max(50, "Make must be at most 50 characters").required("Required"),
            model: Yup.string().max(50, "Model must be at most 50 characters").required("Required"),
            nickName: Yup.string().required("Required"),
            year: Yup.number().yearOnly(),
            mileage: Yup.string(),
            stockNumber: Yup.string(),
            vin: Yup.string().vinOnly(),
            vehicletype: Yup.string().required("Required"),
            orderNumber: Yup.string(),
        }),
        onSubmit: (values) => {
        },
    });



    useEffect(() => {
        
        if(isKarlProfileType && isInventoryYMM && ! dealTrackerData?.vehicle?.isAddedByVin && trimType === 2){
            formik.setValues(
                {
                    ...formik.values,
                    make: dealTrackerData?.vehicle?.make ? dealTrackerData?.vehicle?.make: '',
                    model: dealTrackerData?.vehicle?.model ? dealTrackerData?.vehicle?.model : '',
                    year: dealTrackerData?.vehicle?.year ? dealTrackerData?.vehicle?.year : new Date().getFullYear(),
                    vin: dealTrackerData?.vehicle?.vin ? dealTrackerData?.vehicle?.vin: '',
                    orderNumber: dealTrackerData?.vehicle?.orderNumber ? dealTrackerData?.vehicle?.orderNumber: '',
                    stockNumber: dealTrackerData?.vehicle?.stockNumber ? dealTrackerData?.vehicle?.stockNumber: '',
                }
            );
            // if(dealTrackerData?.vehicle?.orderNumber){
            //     setIsOrderChecked(true)
            // }
           
        } else{
            formik.setValues(
            {
               ...formik.initialValues
            
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealTrackerData,isInventoryYMM]);
    
    useEffect(() => {
        if (dataOneMakes.length > 0) {
            const values = dataOneMakes.map((item) => {
                return { key: item.label, text: item.label };
            });
            setMakeValues(values);
        } else {
            setMakeValues([]);
        }

    }, [dataOneMakes]);
    
    useEffect(() => {

        if (dataOneModels.length > 0) {
            const values = dataOneModels.map((item) => {
                return { key: item.label, text: item.label };
            });
            setModelValues(values);
        } else {
            setModelValues([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOneModels]);

    useEffect(() => {
        if (formik.values.year) {
            dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.year]);

    useEffect(() => {
        if (formik.values.make !== "") {
            setShowModels(true);

            if(!isKarlProfileType){
                formik.setFieldValue("model", null);
            }
            dispatch<any>(handleGetdataOneModels({
                make: formik.values.make?.toString(),
                year: formik.values.year
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.make]);

    useEffect(() => {
        setvehicleType('vin');
        dispatch<any>(handleGetdataOneMakes(formik.values.year?.toString()));
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, []);

    const handleAddInventory = () => {
        setvinDataError(false);
        setVinDecodeError(false);
        setTrimErrorr(false);
        dispatch<any>(handleGetTrimUsingVin({
            vin: formik.values.vin, trimType,
            callback: (trimData) => {
                if (!trimData.status) {
                    if (trimData.style.length === 0) {
                        setvinDataError(true);
                           setVinDecodeError(true);
                    } else if (trimData.style.length > 1 || (isKarlProfileType && trimType === trimTypes.karl)) {
                        const values = trimData.style.map((item, index) => {
                            return { key: item, text: item };
                        });
                        // settrimValues(values);
                        openTrimDialog({
                            vin: formik.values.vin,
                            trim: values,
                            ymm: null
                        });

                    } else if (trimData.style.length === 1) {
                        let vinTrim = {
                            style: trimData.style[0],
                            vin: formik.values.vin
                        }
                        dispatch<any>(handleGetVehDetails({
                            vehDet: vinTrim,trimType,
                            callback: (success) => {
                                if (success) {
                                    if (!isKarlProfileType) {
                                        navigate(`/${success.id}/shop-inventory`);
                                    } else {
                                        onDismiss(null);
                                    }
                                } else {
                                     setvinDataError(true);
                                     setVinDecodeError(true)
                                }
                            }
                        }));
                    }
                } else {
                    if (trimData.status === 409) {
                        onDismiss(trimData.data, formik.values.vin);
                    } else if (trimData.status === 500) {
                        setTrimErrorr(true);
                    }
                    else {
                        setvinDataError(true);
                        setVinDecodeError(true)
                    }
                }
            }
        }));
    };

    const handleAddYmmInventory = () => {
        setvinDataError(false);
        setTrimErrorr(false);
        setVinDecodeError(false);
        if (formik.values.orderNumber && formik.values.stockNumber) {
            const data = {
                orderNumber: formik.values.orderNumber,
                stockNumber: null,
                vin: null
            };
            dispatch<any>(handleDealerTradeCheck({
                id: selectedDeal.id,
                data,
                callback: (res) => {
                    if (res && res.conflicts) {
                        res.conflicts.forEach(function (item, index) {

                            if (data.orderNumber === item.vehicleIn?.orderNumber || data.orderNumber === item.vehicleOut?.orderNumber) {
                                let obj = {
                                    tradeDate: moment(item.createdAt.date).local().format('MM/DD/YYYY'),
                                    tradeMgr: item.createdBy?.firstName + ' ' + item.createdBy?.lastName,
                                    tradeType: item.type === 1 ? "Our Trade" : "Their Trade",
                                    vin: null,
                                    stockNumber: null,
                                    orderNumber: data.orderNumber
                                };
                                setConflictData(x => [...x, obj]);
                            }
                            
                        });
                        setModalIndex(2);
                    }
                    else {
                        addVehicleUsingYMM()
                    }
                }
            }));
        }
        else
        {
            addVehicleUsingYMM();            
        }  
        
    };

    const addVehicleUsingYMM = () => {
        let makerID = document.getElementById("make_addInventory");
        let makerVal: any = makerID?.getElementsByClassName('p-inputtext')[0]?.innerHTML;

        let ymm = {
            make: formik.values.make,
            model: formik.values.model,
            year: formik.values.year,
        };

        if((formik.values.vin || formik.values.stockNumber ) && isKarlProfileType ){
           ymm['vin'] = formik.values.vin;
           ymm['stockNumber'] = formik.values.stockNumber ;  
        }
        if (makerVal && ! isKarlProfileType) {
            ymm.make = makerVal;
        }
        if (isKarlProfileType && trimType === trimTypes.karl) {
            ymm["orderNumber"] = formik.values.orderNumber
            if (formik.values.orderNumber) {
                let data = { orderNumber: formik.values.orderNumber };
                dispatch<any>(handleCheckVehicleOrder({
                    id: selectedDeal.id,
                    data,
                    callback: (res) => {
                        if (res.id) {
                            getTrimUsingYmm(ymm);

                        } else if (res) {
                            orderFoundDealData(res.conflicts);
                            orderConflictData(ymm);
                            onDismiss('YMM order Number');
                        }

                    }
                }));
            } else {
                getTrimUsingYmm(ymm);
            }
        }
        else {
            getTrimUsingYmm(ymm);
        }
    }

    const getTrimUsingYmm = (ymm) => {

         if(!isKarlProfileType){
            dispatch<any>(handleGetTrimUsingYmm({
                ymm: ymm,
                trimType,
                callback: (trimData) => {
                    if (trimData) {
                        if (trimData.length === 0) {
                            setvinDataError(true);
                        } else if (trimData.length > 1) {
                            const values = trimData.map((item, index) => {
                                return { key: item, text: item };
                            });
                            // settrimValues(values);
                            openTrimDialog({
                                vin: null,
                                trim: values,
                                ymm: ymm
                            });
                        } else if (trimData.length === 1) {
                            let vinTrim = {
                                style: trimData[0],
                                ...ymm
                            }
                            dispatch<any>(handleGetVehDetails({
                                vehDet: vinTrim, trimType,
                                callback: (success) => {
                                    if (success) {
                                        if (!isKarlProfileType) {
                                            navigate(`/${success.id}/shop-inventory`);
                                        } else {
                                            onDismiss(null);
                                        }
                                    } else {
                                        setvinDataError(true);
                                    }
                                }
                            }));
                        }
                    } else {
                        setvinDataError(true);
                    }
                }
            }));
         }else{
            dispatch<any>(handleGetVehDetails({    
                 vehDet:ymm, trimType,
                 callback: (success) => {
                     if (success.id) {
                           onDismiss(null);
                     } else {
                         setvinDataError(true);
                      }
                 }
             }));
         }
    }

    const handleMakeChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                make: value ? value : '',
            }
        );
    };
    //const handleMobMakeChange = (e, item) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            make: item.text,
    //        }
    //    );
    //    setMakeData(item.key);
    //    setModelData(null);
    //};

    const handleYearChange = (value) => {
        formik.setValues(
            {
                ...formik.values,
                year: value ? value : '',
                make: '',
                model:''
            }
        );
        setModelValues([]);
        setShowModels(false);
    };

    //const handleMobYearChange = (e, item) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            year: item.key,
    //            make: '',
    //            model:''
    //        }
    //    );
    //    setModelValues([]);
    //    setShowModels(false);
    //    setMakeData(null);
    //    setModelData(null);
    //};

    const handleModelChange = (val) => {
        
        formik.setValues(
            {
                ...formik.values,
                model: val ? val : '',
            }
        );
    };

    //const handleMobModelChange = (e, item) => {
    //    formik.setValues(
    //        {
    //            ...formik.values,
    //            model: item.text,
    //        }
    //    );
    //    setModelData(item.key);

    //};

    const handleVINYmm = (bool) => {
        setvinDataError(false);
        setTrimErrorr(false);
        setVinDecodeError(false);
        setisInventoryYMM(bool);
        if (bool) {
            formik.setValues(
                {
                    ...formik.values,
                    vin: '',
                }
            );
        } else {
            formik.setValues(
                {
                    ...formik.values,
                    year: new Date().getFullYear() + 1,
                    make: '',
                    model: '',
                    orderNumber:''
                }
            );
            setModelValues([]);
            setShowModels(false);
            // setIsOrderChecked(false)
        }
    };

    const handleScanVIN = (bool, fromCamera = false) => {
        if (fromCamera) {
            // setvinCameraDialog(true);
        }
        setvinDataError(false);
        setVinDecodeError(false);
        setTrimErrorr(false);
        setisVINScan(bool)
    }

    const onScanSubmit = (vin) =>{
        let capturedVIN = vin.split(',');
        // When scanned with QR Code, contains multiple options. so picking VIn data alone
        let truncatedVIn = capturedVIN[0];
        if (capturedVIN[0].length === 18) {
            // When contains LEADING letter I
            truncatedVIn = capturedVIN[0].substring(1);
        }
        formik.setFieldValue("vin", truncatedVIn.trim().toUpperCase());
        handleScanVIN(false);
    }

    const changeToUppercase = (e: any) => {
        const re = /^[a-zA-Z0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            //this.setState({ value: e.target.value })
            formik.setFieldValue("vin", e.target.value.trim().toUpperCase());
        }
    };

    const onBackDismiss = () =>{
        if(isVINScan){
            handleScanVIN(false)
        }
        else {
            onDismiss('')
        }
    }

    // const handleOrderChange = React.useCallback(
    //     (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    //         setIsOrderChecked(checked);
    //     },
    //     [],
    // );

    /* const vinCameraAction = (action) => {
        setvinCameraDialog(false);
        if (action === 'ocr') {
            setvinScanConfig(action);
            setisVINScan(true)
        } else {
            setvinScanConfig(action);
            setisVINScan(true)
        }
    } */

    return (
        <>
            {
                modalIndex === 1 && (<Modal
                    isOpen={true}
                    onDismiss={onBackDismiss}
                    isBlocking={true}
                    className={isKarlProfileType && trimType === trimTypes.karl ? "ymm-dialog-karl" : "ymm-dialog"}
                    containerClassName={`${contentStyles.container} inv-srch-mod ${isVINScan ? 'scanner' : ''}`}
                >
                    <div className={contentStyles.header + " inventory-popup"}>
                        {/*<GoBackToMainDialogBtn label="" onClick={onBackDismiss} />*/}
                        <CustomFontText className="formTitle">Add Vehicle</CustomFontText>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={onBackDismiss}
                        />
                    </div>

                    {/* { vinCameraDialog ?
                <VinCameraSelection
                    loading={false}
                    description="Vin Camera"
                    isOpen={vinCameraDialog}
                    onAction={vinCameraAction}
                    onDismiss={() => setvinCameraDialog(false)}
                /> : null
            } */}
                    <div className={`${contentStyles.body} inv-srch-bdy body-auto-hight`}>
                        <Stack
                            styles={stackStyles}
                            verticalAlign="start"
                            horizontalAlign="space-between"
                        >
                            <div className={isInventoryYMM ? "ymm-body addInventoryDialog" : " vin-body-new addInventoryDialog"}>
                                {
                                    vinDecodeError && isKarlProfileType && isInventoryYMM === false  ?
                                        <div>
                                            <Row>
                                                  <Col>
                                                      <p className="genericErrorColor"><span>VIN is not recognized, please validate and re-enter VIN.</span></p>
                                                      <p className="genericErrorColor"><span>Note:  Heavy duty trucks, specialty vehicles, and other VINs may not decode. Click "Add vehicle manually" to add these vehicles. </span></p>
                                                  </Col>
                                            </Row>
                                        </div> : null
                                }
                                {
                                    vinDataError && isKarlProfileType && isInventoryYMM  ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>VIN is not recognized, please re-enter VIN.</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                                {
                                    vinDataError && !isKarlProfileType  ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>VIN is not recognized, please re-enter VIN.</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                                {
                                    trimError ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <p className="genericErrorColor"><span>Faild to fetch data.</span></p>
                                                </Col>
                                            </Row>
                                        </div> : null
                                }
                                {isInventoryYMM ?
                                    <div>
                                       { (isVINScan && isKarlProfileType) ?
                                       <>
                                            <VinScanner
                                              vinSelection={"barcode"}
                                              onScanSubmit={(vin) => onScanSubmit(vin)} />
                                       </>
                                       :
                                       <>
                                         {isKarlProfileType && trimType === trimTypes.karl && (
                                             <Row className="inventoryRowSection" justify="center">
                                          
                                                 <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                                                     <TextField
                                                         id="stockNumber"
                                                         name="stockNumber"
                                                         label="Stock No"
                                                         className="employeeeSearchLabelText"
                                                         value={formik.values.stockNumber}
                                                         onChange={formik.handleChange}
                                                         onBlur={formik.handleBlur}
                                                         errorMessage={
                                                             formik.touched.stockNumber &&
                                                             formik.errors.stockNumber &&
                                                             formik.errors.stockNumber.toString()
                                                         }
                                                     />
                                                 </Col>
                                             </Row>
                                         )}
                                         {isKarlProfileType && trimType === trimTypes.karl && (
                                             <Row className="inventoryRowSection" justify="center">
                                                 {/*}<Col sm={10} md={isOrderChecked ? 4 : 10} xs={12} style={{ paddingRight: "0px" }}>
                                                     <Checkbox className="padding-0" defaultChecked={(!dealTrackerData?.vehicle?.isAddedByVin && dealTrackerData?.vehicle?.orderNumber)?true:false} onChange={handleOrderChange} label={isOrderChecked ? "Order Out" : "Order Out - New Vehicle"} />
                                                 </Col>*/}
                                                 <Col className="inventoryColDivider" sm={10} md={10} xs={12}>
                                                     <TextField
                                                         id="orderNumber"
                                                         name="orderNumber"
                                                         label="Order No"
                                                         className="employeeeSearchLabelText"
                                                         value={formik.values.orderNumber}
                                                         onChange={formik.handleChange}
                                                         onBlur={formik.handleBlur}
                                                         errorMessage={
                                                             formik.touched.orderNumber &&
                                                             formik.errors.orderNumber &&
                                                             formik.errors.orderNumber.toString()
                                                         }
                                                     />
                                                 </Col>
                                             </Row>
                                         )}

                                         {isMobile ?

                                            <div>

                                                <Row className="inventoryRowSection" justify="center">
                                                    <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                        <Dropdown
                                                            id="model_addInventory"
                                                            label="Year"
                                                            selectedKey={formik.values.year}
                                                            required={true}
                                                            onChange={(e, item) => handleYearChange(item.key)}
                                                            placeholder="Select a Year"
                                                            options={yearValues}

                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="inventoryRowSection" justify="center">
                                                    <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                     {
                                                      isKarlProfileType ?

                                                        <TextField
										                id="make"
								                    	name="make"
								                    	label="Make"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.make}
								                    	required={true}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                      />
                                             
                                                    
                                                    :
                                                        <Dropdown
                                                            id="make_addInventory"
                                                            label="Make"
                                                            selectedKey={formik.values.make}
                                                            required={true}
                                                            onChange={(e, item) => handleMakeChange(item.key)}
                                                            placeholder="Select a Make"
                                                            options={makeValues}
                                                        //disabled={!showModels}
                                                        />
                                                     }
                                                    </Col>
                                                </Row>
                                                <Row className="inventoryRowSection" justify="center">
                                                    <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                    {
                                                      isKarlProfileType ?

                                                        <TextField
										                id="model"
								                    	name="model"
								                    	label="Model"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.model}
								                    	required={true}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                      />
                                                    :
                                                        <Dropdown
                                                            id="model_addInventory"
                                                            label="Model"
                                                            selectedKey={formik.values.model}
                                                            required={true}
                                                            onChange={(e, item) => handleModelChange(item.key)}
                                                            placeholder="Select a Model"
                                                            options={modelValues}
                                                            disabled={!showModels}
                                                        />
                                                    }
                                                    </Col>
                                                </Row>

                                            </div>

                                            :
                                            <div>
                                                <Row className="inventoryRowSection" justify="center">
                                                    <Col sm={10} md={10} xs={12} >
                                                        <div className="yearMake">
                                                            <h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Year</h5>
                                                            <PRDropdown
                                                                id="model_addInventory"
                                                                value={formik.values.year}
                                                                className="invMakeDropdown labelTextBorder full-width"
                                                                options={yearValues}
                                                                onChange={(e) => handleYearChange(e.value)}
                                                                optionLabel="text"
                                                                optionValue="text"
                                                                scrollHeight="150px"
                                                                virtualScrollerOptions={{ itemSize: 31 }}
                                                                placeholder="Select a Year"
                                                                appendTo="self"
                                                                filter
                                                                resetFilterOnHide={true}
                                                                required={true} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="inventoryRowSection" justify="center">
                                                  {
                                                      isKarlProfileType ?

                                                    <Col sm={10} md={10} xs={12}>
                                                        <TextField
										                id="make"
								                    	name="make"
								                    	label="Make"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.make}
								                    	required={true}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                    	
								                    />
                                                    </Col>
                                                    :
                                                    <Col sm={10} md={10} xs={12}>
                                                        <div>
                                                            {/* <ComboBox
                                                     id="make"
                                                     // componentRef={comboBoxRef}
                                                     // defaultSelectedKey="C"
                                                     label="Make"
                                                     required={true}
                                                     allowFreeform
                                                     autoComplete="on"
                                                     options={makeValues}
                                                     onChange={handleMakeChange}
                                                     selectedKey={formik.values.make.key}
                                                   /> */}
                                                            <h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Make</h5>
                                                            <PRDropdown
                                                                id="make_addInventory"
                                                                value={formik.values.make}
                                                                className="invMakeDropdown labelTextBorder full-width"
                                                                options={makeValues}
                                                                onChange={(e) => handleMakeChange(e.value)}
                                                                optionLabel="text"
                                                                optionValue="text"
                                                                scrollHeight="150px"
                                                                virtualScrollerOptions={{ itemSize: 31 }}
                                                                placeholder="Select a Make"
                                                                appendTo="self"
                                                                filter
                                                                panelClassName="dropdownCustHgt optionPlacementTop makeDropdown"
                                                                resetFilterOnHide={true}
                                                                required={true} />
                                                        </div>
                                                    </Col>

                                                  }
                                                </Row>
                                                <Row className="inventoryRowSection" justify="center">
                                                 {
                                                      isKarlProfileType ?

                                                    <Col sm={10} md={10} xs={12}>
                                                        <TextField
										                id="model"
								                    	name="model"
								                    	label="Model"
								                    	placeholder=""
                                                        className="employeeeSearchLabelText"
								                    	value={formik.values.model}
								                    	required={true}
								                    	onChange={formik.handleChange}
								                    	onBlur={formik.handleBlur}
								                    	
								                    />
                                                    </Col>
                                                    
                                                    :
                                                    <Col sm={10} md={10} xs={12}>
                                                        <div>
                                                            {/* <Dropdown
                                                     id="model"
                                                     label="Model"
                                                     required={true}
                                                     disabled={!showModels}
                                                     // selectedKeys={
                                                     //   formik.values.model ? formik.values.model : undefined
                                                     // }
                                       
                                                     selectedKey={formik.values.model.key}
                                                     // multiSelect
                                                     onChange={handleModelChange}
                                                     placeholder=""
                                                     options={modelValues}
                                                     styles={dropdownStyles}
                                                     onBlur={formik.handleBlur}
                                                     errorMessage={
                                                       formik.touched.model && formik.errors.model
                                                         ? formik.errors.model.text.toString()
                                                         : null
                                                     }
                                                   /> */}
                                                            <h5 className="primeDropdownLabel" style={{ margin: "5px 0" }}>Model</h5>
                                                            <PRDropdown
                                                                id="model_addInventory"
                                                                value={formik.values.model}
                                                                className="invMakeDropdown labelTextBorder full-width"
                                                                options={modelValues}
                                                                onChange={(e) => handleModelChange(e.value)}
                                                                optionLabel="text"
                                                                optionValue="text"
                                                                scrollHeight="150px"
                                                                virtualScrollerOptions={{ itemSize: 31 }}
                                                                placeholder="Select a Model"
                                                                appendTo="self"
                                                                disabled={!showModels}
                                                                required={true}
                                                                filter
                                                                resetFilterOnHide={true}
                                                                panelClassName="dropdownCustHgt optionPlacementTop"
                                                            />
                                                            {/* */}
                                                        </div>
                                                    </Col>
                                                 }
                                                </Row>
                                            </div>
                                         
                                         }
                                         { isKarlProfileType ?  
                                            
                                                  <div>
                                                      <Row className="inventoryRowSection" justify="center">
                                                         <Col sm={10} md={10} xs={12} >
                                                              <TextField
                                                                  id="vin"
                                                                  name="vin"
                                                                  label="VIN"
                                                                  iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true, true) }}
                                                                  value={formik.values.vin}
                                                                  className="employeeeSearchLabelText"
                                                                  onChange={changeToUppercase}
                                                                  onBlur={formik.handleBlur}
                                                                  errorMessage={
                                                                      formik.touched.vin &&
                                                                      formik.errors.vin &&
                                                                      formik.errors.vin.toString()
                                                                  }
                                                              />
                                                          </Col>
                                                      </Row>
                                                      
                                                  </div>
                                         
                                         :''}
                                         <Row className="noVin" justify="center">
                                             <Col sm={10} md={10} xs={12}>
                                                 <Link onClick={() => handleVINYmm(false)}>
                                                    { isKarlProfileType ? "Add vehicle by VIN":  "I have a VIN"}
                                                 </Link>
                                             </Col>
                                         </Row>
                                         <Row className="inventoryRowSection ymm" justify="center">
                                             <Col sm={10} md={10} xs={12} className="submitBtn">
                                                 <PrimaryButton
                                                     className="decodeBtn"
                                                     disabled={!formik.values.make || !formik.values.model }
                                                     // onRenderIcon={renderSpinner}
                                                     text={isKarlProfileType ?"Save":"Search"}
                                                     onClick={handleAddYmmInventory}
                                                 />
                                             </Col>
                                         </Row>
                                       </>}
                                    </div>
                                    :
                                    <div>
                                       { isVINScan ? 
                                       
                                           <VinScanner
                                               vinSelection={"barcode"}
                                               onScanSubmit={(vin) => onScanSubmit(vin)} 
                                           />
                                           :
                                           <div>
                                              <Row className="inventoryRowSection inventorySplitRow autoWidthInput" justify="center">
                                                  <Col className="inventoryColDivider" sm={11} md={11} xs={12}>
                                                      <TextField
                                                          id="vin"
                                                          name="vin"
                                                          label="VIN"
                                                          iconProps={{ iconName: "Camera", style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => handleScanVIN(true, true) }}
                                                          value={formik.values.vin}
                                                          onChange={changeToUppercase}
                                                          onBlur={formik.handleBlur}
                                                          errorMessage={
                                                              formik.touched.vin &&
                                                              formik.errors.vin &&
                                                              formik.errors.vin.toString()
                                                          }
                                                      // style={{ width: "calc(100% - 130px)" }}
                                                      />
                                                  </Col>
                                              </Row>
                                              <Row className="noVin" justify="center">
                                                  <Col sm={11} md={11} xs={12}>
                                                      <Link onClick={() => handleVINYmm(true)}>
                                                          { isKarlProfileType ? "Add vehicle manually":  "I don't have the VIN"}
                                                      </Link>
                                                  </Col>
                                              </Row>
                                           
                                              <Row className="inventoryRowSection ymm" justify="center">
                                                  <Col sm={10} md={10} xs={12} className="submitBtn">
                                                      <PrimaryButton
                                                          disabled={formik.errors.vin || !formik.values.vin ? true : false}
                                                          className="decodeBtn"
                                                          // onRenderIcon={renderSpinner}
                                                          text="Search"
                                                          onClick={handleAddInventory}
                                                      />
                                                  </Col>
                                              </Row>
                                            </div>
                                       }
                                    </div>
                                }

                            </div>
                        </Stack>
                    </div>
                </Modal>)
            }
            {
                modalIndex === 2 ?
                    <DealerTradeFoundDialog
                        conflictData={conflictData}
                        onDismiss={() => { onDismiss(''); }}
                        onSave={() => { addVehicleUsingYMM() }}
                    />
                    : null
            }
        </>
        
    );
};


const stackStyles: IStackStyles = {
    root: {},
};
