import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
    IContextualMenuProps,
    IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import { colors, emplyeePermissionsAction } from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { isEmployeePermitted } from "utils/permissions/permissionsHelpers";
// import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";
import { DefaultButton } from "@fluentui/react";

const iconMenuStyles: IIconStyles = {
    root: {
        color: colors.lighterBlack,
    },
};

interface IProps {
    modulePermissions: IPermission[];
    onEdit: (vehicleID: any) => void;
    onPurchase: (vehicleID: any) => void;
    onStatusChange: (vehicleID: any,status : any) => void;
    vehicleID: any;
    status: any;
}

export const ShopWorkBookMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    onEdit,
    onPurchase,
    onStatusChange,
    vehicleID,
    status
}) => {
    const [menuProps, setMenuProps] = useState(null);
    const user = useSelector((state: AppState) => state.userProfile.userProfile);
    // const dispatch = useDispatch();

    useEffect(() => {
        setMenuProps(null);
        
        let menuItems: IContextualMenuItem[] = [
            {
                key: "edit", //with submenu
                name: "Edit",
                iconProps: { iconName: "Edit", styles: iconMenuStyles },
                onClick: () => onEdit(vehicleID),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_EDIT,
                    user.roles
                )
                    ? hiddenStyle
                    : null,
            }
        ];

        if (status === "archive") {
            menuItems.push({
                key: "status", //with submenu
                name: "Move to Active Appraisals",
                iconProps: { iconName: "Accept", styles: iconMenuStyles },
                onClick: () => onStatusChange(vehicleID, status),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_TRANSITION_TO_ACTIVE_APPRAISAL,
                    user.roles
                )
                    ? hiddenStyle
                    : null,
            });

            menuItems.push({
                key: "puchase", //with submenu
                name: "Purchase Vehicle",
                iconProps: { iconName: "EditNote", styles: iconMenuStyles },
                onClick: () => onPurchase(vehicleID),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_ACQUIRE,
                    user.roles
                    )
                    ? hiddenStyle
                    : null,
            });

        } else {
            menuItems.push({
                key: "status", //with submenu
                name: "Archive Appraisal",
                iconProps: { iconName: "Archive", styles: iconMenuStyles },
                onClick: () => onStatusChange(vehicleID, status),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_TRANSITION_TO_ARCHIVE_APPRAISAL,
                    user.roles
                    )
                    ? hiddenStyle
                    : null,
            });

            menuItems.push({
                key: "puchase", //with submenu
                name: "Purchase Vehicle",
                iconProps: { iconName: "EditNote", styles: iconMenuStyles },
                onClick: () => onPurchase(vehicleID),
                style: !isEmployeePermitted(
                    user.aggregatedDefaultPolicies,
                    emplyeePermissionsAction.VEHICLE_ACQUIRE,
                    user.roles
                    )
                    ? hiddenStyle
                    : null,
            });
        }
        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: menuItems,
        };

        setMenuProps(menuProps);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleID, user]);

    return (
        <>
            <IconButton
                className="iconButton icon-ellipse-h deskDisplay"
                /* className="iconButton moreIcon"
                iconProps={{
                    iconName: "More",
                }} */
                styles={buttonStyles}
                text="Group Action"
                menuProps={menuProps}
            />
            <DefaultButton text="Actions" className="mobileActionVisibility actionMobData" menuProps={menuProps} />
        </>
    );
};

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};
