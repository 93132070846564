import { takeLeading, put, call, select } from "redux-saga/effects";
import {
    HANDLE_SAVE_PRIMARY_SETUP,
    HandleSavePrimarySetup,
    HANDLE_SAVE_GUIDED_SETUP_FORM,
    HandleVerifyEIN,
    HandleRequestEINAccess,
    HandleSaveGuidedSetupForm,
    HANDLE_VERIFY_EIN,
    HANDLE_REQUEST_EIN_ACCESS
} from "store/primarySetup/primarySetup.types";
import {
    pendingSavePrimarySetup,
    resolvedSavePrimarySetup,
    rejectedSavePrimarySetup,
    resolvedSaveGuidedSetupForm,
    rejectedSaveGuidedSetupForm,
    resolvedGetGuidedSetup
} from "store/primarySetup/primarySetup.action";

import {
    savePrimarySetup,
    addCompanyLogo,
    addDealerProfilePhoto,
    getGuidedSetupDetails,
    updateGuidedSetupDetails,
    verifyEINNumber,
    requestEINProfile,
    requestEINAccess
} from "store/primarySetup/primarySetup.api";
import { switchGlobalLoader } from "store/globalLoading/globalLoading.action";
import {
    resolvedGetUserProfile,
} from "store/userProfile/userProfile.action";
import { HandleGetUserProfile } from "store/userProfile/userProfile.types";
import { HANDLE_GET_USER_PROFILE } from "store/userProfile/userProfile.types";
import { getUserProfileSaga } from "store/userProfile/userProfile.saga";
import { AppState } from "store";


function* savePrimarySetupSaga(action: HandleSavePrimarySetup) {
    const { data, userId } = action.payload;
    
    yield put(switchGlobalLoader(true));
    yield put(pendingSavePrimarySetup());

    try {
        //const { data, error } = yield savePrimarySetup(setup);
        const { error, result } = yield savePrimarySetup(data, userId);
        if (error) {
            yield put(rejectedSavePrimarySetup(error));
            yield put(switchGlobalLoader(false));
            return;
        }
        const userPhoto: any = data.DealerProfilePhotoUrl;
        const companyPhoto: any = data.CompanyLogoUrl;

        if ((userPhoto && typeof userPhoto.name == 'string') || (companyPhoto && typeof companyPhoto.name == 'string')) {
            const formData = new FormData();
            if (userPhoto && typeof userPhoto.name == 'string') {
                formData.append("dealerProfilePhoto", userPhoto, (userPhoto as any).name);
                const { error, result } = yield addDealerProfilePhoto(userId, formData);
                if (error) {
                    yield put(rejectedSavePrimarySetup(error));
                    yield put(switchGlobalLoader(false));
                    return;
                }
                yield put(resolvedGetUserProfile(result));
            }

            if (companyPhoto && typeof companyPhoto.name == 'string') {
                formData.append("companyLogo", companyPhoto, (companyPhoto as any).name);
                const { error, result } = yield addCompanyLogo(userId, formData);
                if (error) {
                    yield put(rejectedSavePrimarySetup(error));
                    yield put(switchGlobalLoader(false));
                    return;
                }
                yield put(resolvedGetUserProfile(result));
            }
        }
        else
            yield put(resolvedGetUserProfile(result));

        yield put(resolvedSavePrimarySetup());

        yield put(switchGlobalLoader(false));
    } catch (error) {
        yield put(rejectedSavePrimarySetup(error));
        yield put(switchGlobalLoader(false));
        return;
    }
}


export function* getGuidedSetupSaga() {
      
    const { error, result } = yield getGuidedSetupDetails();
    
    if (error) {
        return;
    }

    yield put(resolvedGetGuidedSetup(result));
    return;
}

export function* updateGuidedSetupSaga(action: HandleSaveGuidedSetupForm) {
    const { data, callback } = action.payload;    
      
    const { error } = yield updateGuidedSetupDetails(data);
    
    if (error) {
        console.log(error)
        yield put(rejectedSaveGuidedSetupForm(error));
        callback(error);
        return;
    }
    if (data && data.isCompleted) {
        yield call(getGuidedSetupSaga);

        
        const loggedUserId = yield select((state: AppState) => state.auth.loggedUserId);
        const userProfileAction: HandleGetUserProfile = {
            payload: { id: loggedUserId, isEmployee: false },
            type: HANDLE_GET_USER_PROFILE,
        };

        yield call(getUserProfileSaga, userProfileAction);
        
    }
    
    callback(null);
    yield put(resolvedSaveGuidedSetupForm());
    return;
}

function* verifyEINNumberSaga(action: HandleVerifyEIN) {
    
    yield put(switchGlobalLoader(true));
    const { data, callback } = action.payload;    
    
    const { result,error } =  yield verifyEINNumber(data);

    if (error && (error.status === 409)) {
        const { resultProfile, errorProfile } =  yield requestEINProfile(error.data);
        if (errorProfile) {
            yield put(switchGlobalLoader(false));
            typeof callback === "function" && callback(error);
            return;
        }
        let res = {
            code: 409,
            message: resultProfile.legalName
        }
        yield put(switchGlobalLoader(false));
        typeof callback === "function" && callback(res);
        return;
    }

    if (error) {
        yield put(switchGlobalLoader(false));
        typeof callback === "function" && callback(error);
        
        return;
    }
    yield put(switchGlobalLoader(false));
    typeof callback === "function" && callback(result);
    
}

function* requestEINAccessSaga(action: HandleRequestEINAccess) {
    const { data, callback } = action.payload;    

    const { error } =  yield requestEINAccess(data);

    if (error) {
        typeof callback === "function" && callback({ einStatus: error });
        return;
    }

    typeof callback === "function" && callback({ einStatus: data });
}

export default function* PrimarySetupSaga() {
    yield takeLeading(HANDLE_SAVE_PRIMARY_SETUP, savePrimarySetupSaga);
    yield takeLeading(HANDLE_SAVE_GUIDED_SETUP_FORM, updateGuidedSetupSaga);
    yield takeLeading(HANDLE_VERIFY_EIN, verifyEINNumberSaga);
    yield takeLeading(HANDLE_REQUEST_EIN_ACCESS, requestEINAccessSaga);
}
