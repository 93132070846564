import React, { useEffect, useState } from "react";
import { IButtonStyles, IconButton } from "@fluentui/react/lib/Button";
import { DefaultPalette } from "@fluentui/react";
import {
    IContextualMenuProps,
    IContextualMenuItem,
} from "@fluentui/react/lib/ContextualMenu";
import { IIconStyles } from "@fluentui/react";
import {
    colors
    //permissionsModules
} from "constants/constants";
import { IPermission } from "types/groupPermissionTypes";
//import { useSelector } from "react-redux";
//import { AppState } from "store";
//import { isPermitted } from "utils/permissions/permissionsHelpers";
//import { eTypes } from "hoc/withCredentials";
import { hiddenStyle } from "constants/styles";

const iconMenuStyles: IIconStyles = {
    root: {
        color: colors.lighterBlack,
    },
};

interface IProps {
    modulePermissions: IPermission[];
    onEdit: (accountId: number) => void;
    onActivateDeactivate: (accountId: number, isActive: boolean) => void;
    accountId: number;
    isActive: boolean;
}

export const AccountMoreActionsSubmenu: React.FunctionComponent<IProps> = ({
    modulePermissions,
    onEdit,
    onActivateDeactivate,
    accountId,
    isActive
}) => {
    const [menuProps, setMenuProps] = useState(null);
    //const user = useSelector((state: AppState) => state.userProfile.userProfile);
    // const dispatch = useDispatch();

    useEffect(() => {

        const menuItems: IContextualMenuItem[] = [
            {
                key: "edit", //with submenu
                name: "Edit",
                iconProps: { iconName: "Edit", styles: iconMenuStyles },
                onClick: () => onEdit(accountId),
                style: !modulePermissions
                    ? hiddenStyle
                    : null,
            },
            {
                key: "delete", //with submenu
                iconProps: { iconName: isActive ? "Cancel" : "Accept", styles: iconMenuStyles },
                name: isActive ? "Deactivate" : "Activate",
                style: !modulePermissions? hiddenStyle
                    : null,
                onClick: () => onActivateDeactivate(accountId, isActive),
            }
        ];

        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: menuItems,
        };

        setMenuProps(menuProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);

    return (
        <IconButton
            className="iconButton icon-ellipse-h"
            styles={buttonStyles}
            text="Group Action"
            menuProps={menuProps}
        />
    );
};

const buttonStyles: IButtonStyles = {
    root: {
        width: "auto",
        color: DefaultPalette.themePrimary,
        borderColor: DefaultPalette.themePrimary,
    },
    rootHovered: {
        borderColor: DefaultPalette.themePrimary,
        color: DefaultPalette.themePrimary,
    },
};
